import { forwardRef, useMemo } from "react";
import clsx from "clsx";

import { BadgeProps } from "../types";
import { Icon, getHexOrCssVariableForColorKey } from "@salesdesk/daisy-ui";

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(({ size = "lg", text, icon, color, textColor }, ref) => {
	const hexBgColor = useMemo(() => (color ? getHexOrCssVariableForColorKey(color) : undefined), [color]);
	const hexTextColor = useMemo(() => (textColor ? getHexOrCssVariableForColorKey(textColor) : undefined), [textColor]);

	return (
		<div
			ref={ref}
			className={clsx(
				"bg-c_bg_03 flex items-center truncate",
				size === "sm"
					? "text-label-xs gap-1 rounded-full px-1.5 py-0.5"
					: "rounded-minimal text-c_text_secondary gap-2 p-1",
				size === "lg" && "text-label-sm",
				size === "md" && "text-label-xs"
			)}
			style={{
				backgroundColor: hexBgColor,
				color: hexTextColor,
			}}
		>
			<Icon icon={icon} size="sm" />
			{text}
		</div>
	);
});
