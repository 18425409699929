import { ReactNode } from "react";
import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";

import { tw } from "../../../../../../utils/tailwind-helpers";
import { Checkmark } from "../../../../../../components/Checkmark/Checkmark";
import { Pill } from "../../../../../../components/Pill/Pill";
import { SelectOption } from "../../../../types";
import { SelectOptionItemVariant } from "../../types";
import { RecordPreviewPopover } from "../../../../../records";
import { UserPill } from "../../../../../users";
import { SYSTEM_USER_ID } from "@salesdesk/salesdesk-schemas";

interface SelectOptionItemProps {
	option: SelectOption;
	active?: boolean;
	selected?: boolean;
	disabled?: boolean;
	variant?: SelectOptionItemVariant;
	isMultiselect?: boolean;
	withinPopover?: boolean;
	onClose?: () => void;
}

export function SelectOptionItem({
	option,
	active,
	selected,
	disabled,
	withinPopover,
	isMultiselect,
	onClose,
	variant = "default",
}: SelectOptionItemProps) {
	let optionContents;

	const withinMultiselectPopover = isMultiselect && withinPopover;
	const isPillVariant = variant === "pill" || variant === "outline";
	const isUserPill = variant === "user_pill";

	const wrapWithPopover = (children: ReactNode) => {
		if (option.sdRecord) {
			return (
				<RecordPreviewPopover recordId={option.sdRecord._id} isWithinDropdown={withinPopover}>
					{children}
				</RecordPreviewPopover>
			);
		}

		return children;
	};

	const getUserPill = (option: SelectOption) => {
		return wrapWithPopover(
			typeof option.id === "string" ? (
				<UserPill user={option.id} isSystemUser={true} disabled={disabled} onClose={onClose} active={!!onClose} />
			) : (
				<UserPill
					user={{
						id: option.id,
						fullName: option.name,
						avatarFileId: option.imageFileId,
					}}
					isSystemUser={option.id === SYSTEM_USER_ID}
					disabled={disabled}
					onClose={onClose}
					active={!!onClose}
				/>
			)
		);
	};

	if (withinMultiselectPopover) {
		optionContents = (
			<div
				className={clsx(
					"text-label flex items-center gap-2 truncate",
					disabled && !option.textColor ? "text-c_text_disabled" : "text-c_text_primary"
				)}
				style={{
					color: !disabled ? option.textColor : undefined,
				}}
			>
				<Checkmark value={selected} disabled={option.disabled} />
				{option.icon ? <Icon icon={option.icon} /> : null}
				<div className="truncate">{isUserPill ? getUserPill(option) : wrapWithPopover(<span>{option.name}</span>)}</div>
			</div>
		);
	} else if (isPillVariant) {
		optionContents = wrapWithPopover(
			<Pill
				icon={option.icon}
				disabled={disabled}
				color={option.color}
				variant={variant === "outline" ? "outlined" : "primary"}
				active={active}
				onClose={onClose}
				ignorePopOver
			>
				{option.name}
			</Pill>
		);
	} else if (isUserPill) {
		optionContents = getUserPill(option);
	} else {
		optionContents = (
			<div
				className={clsx(
					"flex shrink-0 items-center gap-3 overflow-hidden",
					disabled ? "text-c_text_disabled" : "text-c_text_primary"
				)}
			>
				<Icon icon={option.icon} className={clsx("flex", disabled ? "text-c_icon_disabled" : "text-c_icon_regular")} />
				{wrapWithPopover(<div className="truncate">{option.name}</div>)}
			</div>
		);
	}

	if (!withinPopover) {
		return optionContents;
	}

	let bgColor = "";
	if (active && !disabled) {
		bgColor = tw`bg-c_bg_02`;
	} else if (selected && !withinMultiselectPopover && !disabled) {
		bgColor = tw`bg-c_bg_05`;
	}

	let label = null;
	if (option.label) {
		const { text, icon } = typeof option.label === "string" ? { text: option.label, icon: undefined } : option.label;
		label = (
			<div className="text-label-xs text-c_text_disabled ml-auto flex shrink-0 items-center gap-2 pl-4">
				<Icon size="sm" icon={icon} className="text-c_icon_disabled flex" />
				{text}
			</div>
		);
	}

	return (
		<div
			className={clsx(
				"flex w-full select-none flex-col gap-1.5 transition",
				(isPillVariant || isUserPill) && !withinMultiselectPopover ? "px-4 py-2" : "px-6 py-3",
				bgColor
			)}
		>
			<div className="flex w-full truncate">
				{optionContents}
				{label}
			</div>
			{option.description ? (
				<div className={clsx(disabled ? "text-c_text_disabled" : "text-c_text_secondary", "text-body-sm")}>
					{option.description}
				</div>
			) : null}
		</div>
	);
}
