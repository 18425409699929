import { SDRecord } from "../records";

export const ChannelMessageType = {
	Standard: "Standard",
	Huddle: "Huddle",
} as const;

export type ChannelMessageType = (typeof ChannelMessageType)[keyof typeof ChannelMessageType];

interface ChannelMessageMetadataBase {
	messageType: ChannelMessageType;
}

export interface StandardMetadata extends ChannelMessageMetadataBase {
	messageType: "Standard";
	attachments?: {
		recordIds?: SDRecord["_id"][];
	};
}

export interface HuddleMetadata extends ChannelMessageMetadataBase {
	messageType: "Huddle";
	meetingRecordId: SDRecord["_id"];
}

export type ChannelMessageMetadata = StandardMetadata | HuddleMetadata;
