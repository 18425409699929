import clsx from "clsx";
import { useAppSelector } from "../../../../../store/store";
import { VideoCallToast } from "./VideoCallToast";

export function VideoCallToastHandler() {
	const videoCallEvents = useAppSelector((state) => state.videoCallEvents.events);

	return (
		<div className={clsx("bottom-22 absolute left-10 z-20 flex flex-col")}>
			{videoCallEvents.map((event) => (
				<VideoCallToast key={event.id} event={event} />
			))}
		</div>
	);
}
