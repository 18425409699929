import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mFileFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.FILE.name];
	static defaultComponentType: FieldComponentName = mFileFieldDef.supportedComponentTypes[0];

	_mimeTypesStr?: string;
	_mimeTypes: string[] | null;
	_fileExtensions: string[] | null;

	constructor(id: string | number) {
		super(id);

		this._componentType = mFileFieldDef.defaultComponentType;

		// Mime-type specified as attribute
		// I.e. mime-types="image/jpeg:jpe,jpeg,jpg;image/png:png;"
		this._mimeTypesStr;

		// Only used internally to keep track of valid mime-types
		this._mimeTypes = null;
		this._fileExtensions = null;
		this._icon = FIELD_TYPES.FILE.icon;
		this._previewEnabled = true;

		this._supportsDefaultValue = false;
	}

	override get type(): string {
		return FIELD_TYPES.FILE.name;
	}

	// Mime-type specified as attribute
	// e.g. "image/jpeg:jpe,jpeg,jpg; image/png:png"

	get mimeTypes(): string[] | null {
		return this._mimeTypes;
	}

	set mimeTypes(mimeTypes: string) {
		this._mimeTypesStr = mimeTypes;
		this.loadMimeTypes(mimeTypes);
	}

	hasMimeTypes() {
		return !isEmpty(this._mimeTypes) && (this._mimeTypes || []).length > 0;
	}

	mimeTypeStr() {
		return this._mimeTypesStr;
	}

	override supportsDefaultValue() {
		return false;
	}

	override supportsSearch() {
		return false;
	}

	override isFileStore() {
		return true;
	}

	// Requires processing implies a file needs to be
	// processed in some way following uploading. i.e.
	// a media file that requires transcoding.

	requiresProcessing() {
		return false;
	}

	override supportsTableCellView() {
		return false;
	}

	override supportsCardView() {
		return false;
	}

	override supportsPreview() {
		return true;
	}

	// Load up file extensions and mime types...
	// e.g.
	//  jpe: "image/jpeg"
	//	jpeg: "image/jpeg"
	//	jpg: "image/jpeg"
	//	png: " image/png"

	loadMimeTypes(mimeTypes: string) {
		if (isEmpty(mimeTypes)) {
			return;
		}

		this._mimeTypes = [];
		this._fileExtensions = [];

		const mimeTypeDefinitions = mimeTypes.split(";");

		mimeTypeDefinitions.forEach((mimeTypeDefinition) => {
			const mimeTypeValues = mimeTypeDefinition.split(":");

			this._mimeTypes?.push(mimeTypeValues[0].trim());

			const extensions = mimeTypeValues[1].split(",");

			extensions.forEach((extension) => {
				this._fileExtensions?.push(extension.trim());
			});
		});

		this._toolTip = `File extensions supported: "${this._fileExtensions.join(", ")}"`;
	}

	// File that requires processing first should eventually have a name that corresponds with the transcode output file format ending (MP4)
	resolveName(name: string) {
		if (this.requiresProcessing()) {
			const mediaFileFormat = "mp4";
			return `${name.substring(0, name.indexOf("."))}.${mediaFileFormat}`;
		} else {
			return name;
		}
	}

	// Return the file name without the file ending

	baseName(path: string) {
		let base = String(path).substring(path.lastIndexOf("/") + 1);

		if (base.lastIndexOf(".") !== -1) {
			base = base.substring(0, base.lastIndexOf("."));
		}

		return base;
	}

	// Return the file name without the forward slash

	fileName(path: string) {
		return String(path).substring(path.lastIndexOf("/") + 1);
	}

	override get supportedComponentTypes() {
		return mFileFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		const error = super.validate(value, isTemplate);
		if (error) {
			return error;
		}

		if (!isEmpty(value) && (Number.isNaN(value) || !Number.isInteger(value))) {
			return "FileId must be a number";
		}

		return undefined;
	}

	override marshall(data: any, parent: any) {
		return super.marshall(data, parent);
	}

	override unmarshall(skipValidation?: boolean) {
		const clone = super.unmarshall(skipValidation) as any;

		delete clone._mimeTypesStr;
		delete clone._mimeTypes;
		delete clone._fileExtensions;

		return clone;
	}

	override supportsFormatDescription() {
		return false;
	}
}
