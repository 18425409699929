import clsx from "clsx";
import { PropsWithChildren, ReactNode, useMemo } from "react";

import { Tooltip } from "@salesdesk/daisy-ui";
import { getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

interface CompactRecordPreviewProps {
	sdRecord: SDRecord;
	sdObject: SDObject;
	icon?: ReactNode;
	variant?: "sm" | "xs";
}

export function CompactRecordPreviewContainer({
	icon,
	sdRecord,
	sdObject,
	variant = "sm",
	children,
}: PropsWithChildren<CompactRecordPreviewProps>) {
	const recordName = useMemo(() => getSDRecordName(sdObject, sdRecord), [sdObject, sdRecord]);

	return (
		<div className={clsx("flex w-full items-center", variant === "xs" ? "text-label-xs gap-2" : "text-body-sm gap-4")}>
			{icon}
			<div className="flex flex-col truncate">
				<Tooltip text={recordName} showOnTruncated>
					<div className="truncate">{recordName}</div>
				</Tooltip>
				{children}
			</div>
		</div>
	);
}
