import { isAssetObject, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mDocDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { PATHS } from "../../../../routes";
import { useWebPrincipal } from "../../../../auth";
import { useWorkspaceContext, WORKSPACE_OBJECT_BOARDS } from "../../../workspaces";
import { FieldIdRecordCard, getCurrentPathWithRecordIdParam } from "../../../records";
import { RecordAssociationOpeningVariant } from "../../types";
import { useGetAssociationContextMenuItems } from "../AssociationActions";
import { CompactCard } from "./CompactCard";

interface RecordAssociationCardProps {
	sdRecord: SDRecord;
	sdObject: SDObject;
	displayFieldIds: number[];
	openingVariant?: RecordAssociationOpeningVariant;
	selected?: boolean;
	hideContextMenu?: boolean;
	onToggleSelect?: () => void;
}

export function RecordAssociationCard({
	sdRecord,
	sdObject,
	displayFieldIds,
	openingVariant = "recordDetailView",
	selected,
	onToggleSelect,
	hideContextMenu,
}: RecordAssociationCardProps) {
	const principal = useWebPrincipal();
	const { workspaceRecord } = useWorkspaceContext();

	const getAssociationContextMenuItems = useGetAssociationContextMenuItems();

	const getRecordLink = (recordId: number) => {
		if (openingVariant === "currentPageSidePanel") {
			return getCurrentPathWithRecordIdParam(recordId);
		}

		if (openingVariant === "workspace") {
			if (WORKSPACE_OBJECT_BOARDS.includes(sdObject._id) && workspaceRecord) {
				return PATHS.WORKSPACE_RECORD_BOARD(workspaceRecord._id, sdObject, { recordId });
			} else if (sdObject._id === mWorkspaceDef.ID) {
				return PATHS.WORKSPACE_OVERVIEW(recordId);
			}
		}
		return principal.IsSalesDeskUser ? PATHS.RECORD_DETAIL_VIEW(recordId, sdObject) : "";
	};

	const sharedCardProps = {
		sdRecord,
		sdObject,
		getRecordLink,
		getCustomContextMenuItems: getAssociationContextMenuItems,
		selected,
		onToggleSelect,
		allowDelete: false,
		hideContextMenu,
	};

	const fieldIdCardProps = {
		...sharedCardProps,
		fieldIdsToDisplay: displayFieldIds,
	};

	if (isAssetObject(sdObject) || sdObject._id === mDocDef.ID) {
		return (
			<div className="h-full w-full truncate">
				<div className="@[576px]/association-list:hidden w-full truncate">
					<CompactCard {...sharedCardProps} />
				</div>
				<div className="@[576px]/association-list:block hidden h-full w-full truncate">
					<FieldIdRecordCard {...fieldIdCardProps} vertical={true} />
				</div>
			</div>
		);
	}

	return <FieldIdRecordCard {...fieldIdCardProps} />;
}
