import React, { HTMLProps } from "react";
import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs } from "@floating-ui/react";

import { useDialogContext } from "./Dialog";
import clsx from "clsx";

interface DialogContentProps {
	noPadding?: boolean;
	roundedSize?: "md" | "lg";
}

export const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps & HTMLProps<HTMLDivElement>>(
	function DialogContent({ noPadding = false, roundedSize = "lg", children, ...divProps }, propRef) {
		const { context: floatingContext, ...context } = useDialogContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		if (!floatingContext.open) return null;

		return (
			<FloatingPortal>
				<FloatingOverlay className="bg-c_brand_dark z-[100] flex h-full overflow-hidden bg-opacity-[0.24]" lockScroll>
					<FloatingFocusManager context={floatingContext}>
						<div
							ref={ref}
							className={clsx(
								"border-c_border_regular bg-c_bg_01 shadow-popover m-auto flex max-h-[95vh] max-w-[95vw] overflow-hidden border",
								{
									"px-8 pb-4 pt-8": !noPadding,
									"rounded-md": roundedSize === "md",
									"rounded-lg": roundedSize === "lg",
								}
							)}
							{...context.getFloatingProps(divProps)}
						>
							{children}
						</div>
					</FloatingFocusManager>
				</FloatingOverlay>
			</FloatingPortal>
		);
	}
);
