import { SelectOption } from "../../../inputs";
import { NotificationRule } from "@salesdesk/salesdesk-schemas";

export enum RuleElementType {
	when = "When",
	by = "by",
	does = "does",
	in = "in",
	channel = "notify me via",
}

export interface RuleValue {
	value?: string;
	placeholder?: string;
	onClick?: () => void;
	active?: boolean;
	disabled?: boolean;
}

export type SelectionType = "eventType" | "field" | "users" | "user" | "record" | "channels";

export const inAppDisabledChannelOption: SelectOption = {
	id: "inApp",
	name: "In-app notification",
	disabled: true,
};

export interface NotificationRuleDescriptionProps {
	rule: NotificationRule;
}
