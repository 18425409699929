import { Skeleton } from "../../../../../components/Skeleton/Skeleton";

import clsx from "clsx";

interface SkeletonCompactFilePreviewProps {
	variant?: "xs" | "sm";
}

export function SkeletonCompactFilePreview({ variant = "sm" }: SkeletonCompactFilePreviewProps) {
	return (
		<div className={clsx("text-label flex w-full items-center", variant === "xs" ? "gap-2" : "gap-4")}>
			<Skeleton className="rounded-xs box-content h-9 w-[32px] shrink-0 p-0.5" />
			<Skeleton className={clsx("w-full max-w-[300px]", variant === "xs" ? "h-3" : "h-4")} />
		</div>
	);
}
