import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mNumberField extends mField {

	get numberValue(){
		return parseFloat(this._value.replace(/,/g, ''));
	}
		
	displayValue(){

		if(!isEmpty(this._value)){

			let value = this._value;

			if(isNaN(value)){
				value = parseFloat(value.replace(/,/g, ''));
			}

			return Number(value).toLocaleString(undefined, {minimumFractionDigits: 2});
		}
		return "";		
	}
}