import { useState, useEffect } from "react";
import { Editor } from "@tiptap/react";

import { SelectOptionId } from "../../../types";
import {
	TEXT_FORMAT_OPTIONS_DETAILS,
	DEFAULT_OPTION_ID,
	TEXT_FORMAT_OPTIONS,
	TextFormattingOption,
	TOOLBAR_ACTIONS_CONFIG,
} from "../utils";
import { BaseSelect } from "../../Select";
import { TextOptionSelectButton } from "./TextOptionSelectButton";
import { Button } from "@salesdesk/daisy-ui";
import clsx from "clsx";

const { selectOptions, formatOptionsMapById } = TEXT_FORMAT_OPTIONS_DETAILS;

interface ToolbarProps {
	editor: Editor;
	disabled: boolean;
}

export function Toolbar({ editor, disabled }: ToolbarProps) {
	const [currentOptionId, setCurrentOptionId] = useState<SelectOptionId | undefined>(DEFAULT_OPTION_ID);

	const onSelectChange = (optionId?: SelectOptionId) => {
		if (!optionId) {
			setCurrentOptionId(optionId);
			return;
		}

		formatOptionsMapById[optionId].action(editor);
		setCurrentOptionId(optionId);
	};

	const activeFormattingOption = TEXT_FORMAT_OPTIONS.find((option: TextFormattingOption) => option.isActive(editor));

	useEffect(() => {
		if (!disabled) {
			setCurrentOptionId(activeFormattingOption ? activeFormattingOption.id : DEFAULT_OPTION_ID);
		}
	}, [activeFormattingOption, disabled]);

	return (
		<div
			className={clsx(
				disabled ? "bg-c_bg_disabled_02" : "bg-c_bg_01 group-focus-within:bg-c_bg_04",
				"border-c_border_regular sticky top-0 z-[1] flex items-center justify-between border-b px-4 py-1.5"
			)}
		>
			<BaseSelect
				disabled={disabled}
				onChange={onSelectChange}
				value={currentOptionId}
				options={selectOptions}
				selectButton={<TextOptionSelectButton />}
				selectPopoverOptions={{ placement: "bottom-start" }}
			/>
			<div className="flex gap-0.5">
				{TOOLBAR_ACTIONS_CONFIG.map((menuItem) => (
					<Button
						disabled={disabled}
						key={menuItem.id}
						size="sm"
						variant="text"
						active={!disabled && menuItem.isActive(editor)}
						onClick={menuItem.action(editor)}
						startIcon={menuItem.icon}
					/>
				))}
			</div>
		</div>
	);
}
