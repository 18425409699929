import { cloneElement, forwardRef, Fragment, HTMLProps, ReactElement, useMemo } from "react";
import {
	FloatingFocusManager,
	FloatingList,
	FloatingNode,
	FloatingPortal,
	FloatingTree,
	Placement,
	useFloatingParentNodeId,
	useMergeRefs,
} from "@floating-ui/react";
import { Transition } from "@headlessui/react";

import { Tooltip } from "@salesdesk/daisy-ui";

import { tw } from "../../../../utils/tailwind-helpers";
import { PopoverContainer } from "../../../../components/Popover";
import { generateMenuContentComponents, generateMenuSections, getPopoverMenuWidthStyling } from "../../utils";
import { MenuContext, useFloatingMenu } from "../../hooks";
import { MenuContents, PopoverMenuVariant, PopoverMenuWidthVariant } from "../../types";

interface PopoverMenuProps {
	menuContents: MenuContents;
	children: ReactElement;
	tooltipText?: string;
	tooltipPlacement?: Placement;
	placement?: Placement;
	variant?: PopoverMenuVariant;
	widthVariant?: PopoverMenuWidthVariant;
	rootElementId?: string;
	onOpenChange?: (isOpen: boolean) => void;
}

export const PopoverMenu = forwardRef<HTMLButtonElement, PopoverMenuProps & HTMLProps<HTMLButtonElement>>(
	(props, ref) => {
		const parentId = useFloatingParentNodeId();

		if (parentId === null) {
			return (
				<FloatingTree>
					<PopoverMenuComponent {...props} ref={ref} />
				</FloatingTree>
			);
		}

		return <PopoverMenuComponent {...props} ref={ref} />;
	}
);

const PopoverMenuComponent = forwardRef<HTMLButtonElement, PopoverMenuProps>(
	(
		{
			children,
			menuContents,
			tooltipText,
			tooltipPlacement,
			placement,
			widthVariant = "sm",
			variant = "regular",
			rootElementId,
			onOpenChange,
		},
		forwardedRef
	) => {
		const {
			nodeId,
			isOpen,
			isNested,
			listItem,
			parent,
			getFloatingProps,
			setHasFocusInside,
			activeIndex,
			setActiveIndex,
			getReferenceProps,
			getItemProps,
			floatingContext,
			floatingListRefs,
			returnFocus,
		} = useFloatingMenu(placement, onOpenChange);

		const { refs, floatingStyles } = floatingContext;
		const triggerRef = useMergeRefs([refs.setReference, listItem.ref, forwardedRef]);

		const menuSections = useMemo(() => generateMenuSections(menuContents), [menuContents]);

		const menuButton = useMemo(
			() => (
				<Tooltip text={isOpen ? "" : tooltipText} placement={tooltipPlacement}>
					{cloneElement(children, {
						ref: triggerRef,
						tabIndex: !isNested ? undefined : parent.activeIndex === listItem.index ? 0 : -1,
						role: isNested ? "menuitem" : undefined,
						...getReferenceProps({
							...parent.getItemProps({
								...children.props,
								onFocus() {
									setHasFocusInside(false);
									parent.setHasFocusInside(true);
								},
							}),
							onClick: (e) => {
								e.stopPropagation();
								e.preventDefault();
							},
						}),
						// Can be used for CSS styling based on whether the menu is open or closed
						"data-state": isOpen ? "open" : "closed",
					})}
				</Tooltip>
			),
			[
				isOpen,
				tooltipText,
				tooltipPlacement,
				children,
				triggerRef,
				isNested,
				parent,
				listItem,
				getReferenceProps,
				setHasFocusInside,
			]
		);

		if (!isOpen) {
			return menuButton;
		}

		return (
			<>
				{menuButton}
				<FloatingNode id={nodeId}>
					<MenuContext.Provider
						value={{
							activeIndex,
							setActiveIndex,
							getItemProps,
							setHasFocusInside,
							isOpen,
						}}
					>
						<FloatingList {...floatingListRefs}>
							<FloatingPortal id={rootElementId}>
								<FloatingFocusManager
									context={floatingContext}
									initialFocus={isNested ? -1 : 0}
									returnFocus={returnFocus}
								>
									<Transition
										as={Fragment}
										show={isOpen}
										appear={true}
										enter={tw`transition-opacity duration-200 ease-out`}
										enterFrom={tw`scale-95 opacity-0`}
										enterTo={tw`scale-100 opacity-100`}
										leave={tw`transition duration-150 ease-in`}
										leaveFrom={tw`scale-100 opacity-100`}
										leaveTo={tw`scale-95 opacity-0`}
									>
										<PopoverContainer
											ref={refs.setFloating}
											style={{ ...floatingStyles, zIndex: 100, ...getPopoverMenuWidthStyling(widthVariant) }}
											{...getFloatingProps()}
										>
											{generateMenuContentComponents(activeIndex, menuSections, variant)}
										</PopoverContainer>
									</Transition>
								</FloatingFocusManager>
							</FloatingPortal>
						</FloatingList>
					</MenuContext.Provider>
				</FloatingNode>
			</>
		);
	}
);
