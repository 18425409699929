import { Field } from "@salesdesk/salesdesk-schemas";
import { USER_IDS, mContactDef, mLeadDef, mObjectsFieldDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { AssociationsSummaryData } from "../../../../../../../recordAssociations";
import { AssociationRecordsToLinkMap } from "../../../../types";

export function getAssociationInfo(associationRecordsToLinkMap: AssociationRecordsToLinkMap) {
	const recordsPerObject: { [objectId: number]: number } = {};

	const customerRecordIds: number[] = [];
	const internalTeamMemberRecordIds: number[] = [];

	Object.values(associationRecordsToLinkMap).forEach(
		({ association, records }: AssociationRecordsToLinkMap[number]) => {
			const objectId = association.connectedObject.id;
			const recordCount = records.length;

			// Get user records for the Customers/Internal team members sections
			if (Object.values(USER_IDS).includes(objectId)) {
				records.forEach((record) => {
					const recordId = record._id;

					if (objectId === mSalesDeskUserDef.ID) {
						internalTeamMemberRecordIds.push(recordId);
					}
					// Push leads to the start of the customerRecordIds array
					else if (objectId === mLeadDef.ID) {
						customerRecordIds.unshift(recordId);
					} else if (objectId === mContactDef.ID) {
						customerRecordIds.push(recordId);
					}
				});

				return;
			}

			if (recordsPerObject[objectId]) {
				recordsPerObject[objectId] += recordCount;
			} else {
				recordsPerObject[objectId] = recordCount;
			}
		}
	);

	const associationsSummary: AssociationsSummaryData = Object.entries(recordsPerObject)
		.map(([objectId, count]) => ({
			objectId: Number(objectId),
			count,
		}))
		.filter(({ count }) => count > 0);

	return { associationsSummary, customerRecordIds, internalTeamMemberRecordIds };
}

export function createCustomerField(): Field {
	const customersField = new mObjectsFieldDef("customers");
	customersField.displayName = "Customers";
	customersField.pluralName = "Customers";
	customersField.icon = ICONS.users;
	customersField.objectDefId = USER_IDS.SALESDESK_CONTACT;
	return customersField.unmarshall() as Field;
}

export function createInternalTeamField(): Field {
	const internalTeamField = new mObjectsFieldDef("internalTeam");
	internalTeamField.displayName = "Internal Team";
	internalTeamField.pluralName = "Internal Team";
	internalTeamField.icon = ICONS.users;
	internalTeamField.objectDefId = USER_IDS.SALESDESK_USER;
	return internalTeamField.unmarshall() as Field;
}
