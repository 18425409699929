import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { SdEventType } from "@salesdesk/salesdesk-model";

export function AssetObject() {
	const { event, target } = useEventContext();
	return (
		<>
			{event.event_type === SdEventType.FILE_PRESENTATION_PAGE_CHANGED ? "of" : null} an asset{" "}
			{target === "NOTIFICATION" ? (
				<>
					in <LinkObject />
				</>
			) : null}
		</>
	);
}
