import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mDateTimeField extends mField {
	
	setNow(){
		this.value = new Date().getTime();
	}

	getDateTime(){

		if(!isEmpty(this.value)){
			return new Date(this.value);
		}
		else {
			throw Error(`No date value has been set in field instance with field ${this.field.name}, so cannot generate date object`);
		}
	}

	get group(){

		if(this.hasValue()){

			const groupInfo = this.field.groupInfo;

			for(let i = 0; groupInfo.length > i; i++){
				const group = groupInfo[i];
				if(group.contains(new Date(this.value))){
					return group;
				}
			}

			return null;
		}
		throw Error(`This field instance does not belong to a group as it has no value set`);
	}
		
	displayValue(){
		
		if(isEmpty(this._value)){ 
			return "";
		}
		
		return new Date(this._value).toLocaleString();
	}

}