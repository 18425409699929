import { useState, useCallback, useRef, useEffect } from "react";
import { Room } from "twilio-video";

import { SdEventType } from "@salesdesk/salesdesk-model";
import { getMeetingRecordIdFromRoomName } from "@salesdesk/salesdesk-utils";

import { useToast } from "../../../../../../Toasts";
import { usePostEvent } from "../../../../../../events/hooks/usePostEvent";

export function useScreenShareToggle(room: Room | null) {
	const [isSharingScreen, setIsSharingScreen] = useState(false);
	const stopScreenShareRef = useRef<(() => void) | null>(null);
	const toast = useToast();

	const postEvent = usePostEvent();

	// Stops sharing screen if the user leaves the room
	useEffect(() => {
		return () => {
			if (stopScreenShareRef.current) {
				stopScreenShareRef.current();
			}
		};
	}, [room]);

	const shareScreen = useCallback(async () => {
		if (!room) {
			toast.triggerMessage({ type: "error", messageKey: "screen_shared" });
			return;
		}

		try {
			const mediaStream = await navigator.mediaDevices.getDisplayMedia({
				audio: false,
				video: true,

				// Allow screen sharing of current tab
				selfBrowserSurface: "include",
			} as any);

			const track = mediaStream.getTracks()[0];

			try {
				const trackPublication = room.localParticipant.publishTrack(track, {
					name: `screen_${Date.now()}`,
					priority: "high",
				});

				stopScreenShareRef.current = () => {
					room.localParticipant.unpublishTrack(track);
					room.localParticipant.emit("trackUnpublished", trackPublication);
					track.stop();
					setIsSharingScreen(false);
					postEvent({
						event_type: SdEventType.SCREEN_SHARE_ENDED,
						record_id: getMeetingRecordIdFromRoomName(room.name),
						params: {},
					});
				};

				track.onended = stopScreenShareRef.current;
				setIsSharingScreen(true);
				postEvent({
					event_type: SdEventType.SCREEN_SHARE_STARTED,
					record_id: getMeetingRecordIdFromRoomName(room.name),
					params: {},
				});
			} catch (error) {
				console.error("Error occured when sharing screen", error);
			}
		} catch (error: any) {
			// Don't display an error if the user closes the screen share dialog
			if (
				error.message === "Permission denied by system" ||
				(error.name !== "AbortError" && error.name !== "NotAllowedError")
			) {
				toast.triggerMessage({ type: "error", messageKey: "screen_shared" });
				console.error(error);
			}
		}
	}, [room, postEvent, toast]);

	const toggleScreenShare = useCallback(() => {
		if (!room) {
			return;
		}

		if (!isSharingScreen) {
			shareScreen();
		} else if (stopScreenShareRef.current) {
			stopScreenShareRef.current();
		}
	}, [isSharingScreen, shareScreen, room]);

	return { isSharingScreen, toggleScreenShare };
}
