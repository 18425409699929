import { useCallback, useState } from "react";
import { Transition } from "@headlessui/react";
import clsx from "clsx";

import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { WORKSPACE_INTRO_VIDEO_ID, mMediaAssetDef } from "@salesdesk/salesdesk-model";

import { useWebPrincipal } from "../../../../../../../../auth";
import { tw } from "../../../../../../../../utils/tailwind-helpers";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { useUnshareRecordMutation } from "../../../../../../api/workspacesApi";
import { EmptySection } from "../../EmptySection";
import { useGetContextWorkspaceId } from "../../../../../../hooks/useGetContextWorkspaceId";
import { AlertDialog } from "../../../../../../../Dialog";
import { useToast } from "../../../../../../../Toasts";

import { WorkspaceFileFieldPreview } from "../../WorkspaceFileFieldPreview";
import { useDeleteRecordAssociations } from "../../../../../../../recordAssociations";

const MEDIA_ASSET_ID = [mMediaAssetDef.ID];

export function WorkspaceWelcomeVideo() {
	const principal = useWebPrincipal();

	const workspaceId = useGetContextWorkspaceId();
	const { welcomeVideo, welcomeVideoRecord, isLoadingWelcomeVideo, workspaceRecord, workspaceObject } =
		useWorkspaceContext();

	const [unshareRecord, { isLoading: isUnsharingRecord }] = useUnshareRecordMutation();
	const { deleteRecordAssociation, isLoading: isDeletingAssociation } = useDeleteRecordAssociations();

	const isRemoving = isUnsharingRecord || isDeletingAssociation;
	const [showRemoveModal, setShowRemoveModal] = useState(false);

	const toast = useToast();

	const canClearWelcomeVideo =
		principal.IsSalesDeskUser &&
		principal.can(AbilityAction.Delete, AbilitySubject.RecordAssociation) &&
		principal.can(AbilityAction.UnshareWithWorkspace, AbilitySubject.Record);

	const onClear = useCallback(() => {
		if (!welcomeVideoRecord || !workspaceRecord) return;

		unshareRecord({ record: welcomeVideoRecord, workspace: workspaceRecord })
			.unwrap()
			.then(() => {
				deleteRecordAssociation({
					record1Id: String(workspaceId),
					record2Id: String(welcomeVideoRecord._id),
					objectAssociationId: String(WORKSPACE_INTRO_VIDEO_ID),
				}).then(() => {
					toast.trigger("success", "Welcome video removed successfully");
					setShowRemoveModal(false);
				});
			})
			.catch(() => {
				toast.trigger("error", "An error occured when removing the welcome video");
			});
	}, [welcomeVideoRecord, workspaceId, unshareRecord, workspaceRecord, deleteRecordAssociation, toast]);

	const workspaceDisplayName = workspaceObject?._displayName || "Workspace";

	return (
		<div className="ml-auto w-[30%] min-w-[440px] shrink-0">
			<Transition
				className={clsx(welcomeVideo && "z-50 -mt-[80px] aspect-video", "relative w-full transition-all")}
				show={Boolean(
					(principal.IsCustomerUser && welcomeVideo) || (principal.IsSalesDeskUser && !isLoadingWelcomeVideo)
				)}
				enterFrom={tw`opacity-0 scale-95`}
				enterTo={tw`opacity-100 scale-100`}
			>
				{welcomeVideo ? (
					<WorkspaceFileFieldPreview
						file={welcomeVideo}
						variant={"welcomeVideo"}
						onRemoveClick={canClearWelcomeVideo ? () => setShowRemoveModal(true) : undefined}
					/>
				) : principal.IsSalesDeskUser ? (
					<EmptySection
						messageText="Please select a video asset to welcome your customers"
						buttonText="Add a welcome video"
						resourcesTitle="Add welcome video from resources"
						sdObjectIds={MEDIA_ASSET_ID}
						resourcesOnly
					/>
				) : null}
			</Transition>
			<AlertDialog
				open={showRemoveModal}
				onOpenChange={setShowRemoveModal}
				title={`Remove welcome video from ${workspaceDisplayName}`}
				message={
					<p>
						Are you sure you want to remove this welcome video from this {workspaceDisplayName}? You will be able to add
						it back in any time from the {workspaceDisplayName} Resource area.
					</p>
				}
			>
				<div className="flex items-center justify-end gap-3">
					<Button size="lg" variant="secondary" onClick={() => setShowRemoveModal(false)}>
						Cancel
					</Button>
					<Button isLoading={isRemoving} onClick={onClear} variant="primary" size="lg">
						Remove
					</Button>
				</div>
			</AlertDialog>
		</div>
	);
}
