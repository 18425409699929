import { asConst } from "json-schema-to-ts";
import { objectIdSchema } from "../../../object";

export const baseObjectIdClauseSchema = asConst({
	title: "Base Object Id",
	description: "Will match any record who's object has the given baseObjectId",
	type: "object",
	additionalProperties: false,
	required: ["baseObjectId"],
	properties: {
		baseObjectId: objectIdSchema,
	},
	nullable: false,
});
