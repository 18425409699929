import { FieldDisclosure } from "./FieldsDisclosure";

import { FieldTypeGroup } from "@salesdesk/salesdesk-model";

interface FieldPickerProps {
	fields: FieldTypeGroup[];
}

export function FieldPicker({ fields }: FieldPickerProps) {
	return (
		<div className="bg-c_bg_03 shadow-panel_left border-c_border_regular rounded-tr-panel relative flex w-full flex-col gap-4 border border-l-0 py-4 pl-5 pr-6">
			<p className="text-body-sm">Drag new fields to the form</p>
			<div className="flex flex-col gap-4 overflow-auto pl-1">
				{fields.map((FieldTypeGroup) => (
					<FieldDisclosure key={FieldTypeGroup.label} {...FieldTypeGroup} />
				))}
			</div>
		</div>
	);
}
