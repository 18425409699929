import { APP_CONFIG } from "../../../../../../../app/app_config";
import { PanelTabPanel } from "../../../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation } from "../../../../../../recordAssociations";
import { AssociationGroup } from "../../../../../../records";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";

interface ResourcesAssociationPanelProps {
	onUpdateCount: (associationId: number, count: number) => void;
	objectAssociation: DirectedSDObjectAssociation;
}

export function ResourcesAssociationPanel({ onUpdateCount, objectAssociation }: ResourcesAssociationPanelProps) {
	const { workspaceRecord } = useWorkspaceContext();

	return (
		<PanelTabPanel className="flex w-full flex-col px-px pt-4" unmount={false}>
			<AssociationGroup
				sourceRecord={workspaceRecord}
				objectAssociation={objectAssociation}
				onUpdateCount={onUpdateCount}
				isCollapsible={false}
				associationOpeningVariant="currentPageSidePanel"
				showTitleBar={false}
				limit={APP_CONFIG.maxLocalSearchResults}
			/>
		</PanelTabPanel>
	);
}
