import { ImportJobStatus } from "@salesdesk/salesdesk-schemas";
import { ProgressIndicator } from "../../../../components/ProgressIndicator/ProgressIndicator";
import { useImportContext } from "../../hooks/useImportContext";
import { usePollImportJob } from "../../hooks/usePollImportJob";
import { getTotalProcessedRows, getTotalRows } from "../../utils";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";

export function ImportActualProgress() {
	const { actualImportJob, setActualImportJob } = useImportContext();

	const { percentageDone } = usePollImportJob(actualImportJob, setActualImportJob);

	const isCompleted = actualImportJob?.status === ImportJobStatus.Completed;

	if (!actualImportJob) {
		return null;
	}

	const totalRows = getTotalRows(actualImportJob);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<div className="text-label-sm">{isCompleted ? "Importing completed!" : "Importing records..."}</div>
				<div className="text-body-sm">
					<span className="text-label-sm">{getTotalProcessedRows(actualImportJob)}</span> / {totalRows}{" "}
					{pluralizeWithS("record", totalRows)}
				</div>
			</div>
			{!isCompleted ? <ProgressIndicator percentage={percentageDone} /> : null}
		</div>
	);
}
