import { useEffect, useRef } from "react";

import { TextInput, TextInputComponentProps } from "@salesdesk/daisy-ui";

type OptionTextInputProps = {
	isFocused?: boolean;
} & TextInputComponentProps;

export function OptionTextInput({ isFocused, ...props }: OptionTextInputProps) {
	const optionInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (optionInputRef.current && isFocused) {
			optionInputRef.current.focus();
		}
	}, [isFocused]);

	return <TextInput ref={optionInputRef} {...props} />;
}
