import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { AccessibleButtonDiv } from "../../../../../../../../components/AccessibleButtonDiv";
import { FieldLabelPosition } from "../types";

interface RecordDetailsFieldSetProps {
	icon: string;
	label: string;
	error?: string;
	onClick?: () => void;
	fieldWrapperClasses?: string;
	labelPosition?: FieldLabelPosition;
	disabled?: boolean;
	tooltipText?: string;
}

export function RecordDetailsFieldSet({
	icon,
	label,
	children,
	error,
	onClick,
	fieldWrapperClasses,
	labelPosition = "LEFT",
	disabled,
	tooltipText,
}: PropsWithChildren<RecordDetailsFieldSetProps>) {
	const fieldContainerClasses = clsx(
		labelPosition === "LEFT" ? "col-span-7" : "col-span-10 ml-2",
		"flex flex-col items-baseline justify-center rounded-sm",
		fieldWrapperClasses
	);

	return (
		<div>
			<div className={clsx("grid min-h-[42px] grid-cols-10", labelPosition === "LEFT" ? "gap-4" : "gap-2")}>
				{labelPosition !== "NONE" ? (
					<div
						className={clsx(
							"text-label-sm text-c_text_secondary pl-2",
							labelPosition === "LEFT" ? "col-span-3" : "col-span-10",
							labelPosition === "TOP" && "pt-2"
						)}
					>
						<div className={clsx("flex items-center gap-1.5", labelPosition === "LEFT" && "h-10")}>
							<Icon icon={icon} className="flex" size="sm" />
							<Tooltip text={label} placement="top" showOnTruncated>
								<span className="truncate">{label}</span>
							</Tooltip>
						</div>
					</div>
				) : null}
				<Tooltip ignoreDisabled text={tooltipText} placement="top">
					<AccessibleButtonDiv onClick={onClick} disabled={disabled} className={fieldContainerClasses}>
						{children}
					</AccessibleButtonDiv>
				</Tooltip>
			</div>
			{error ? (
				<div className="grid grid-cols-10 gap-4">
					<p className="text-c_danger_02 col-span-7 col-start-4"> {error}</p>
				</div>
			) : null}
		</div>
	);
}
