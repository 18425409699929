import { mObjectDef } from "./object_def";

export class mSystemObjectDef extends mObjectDef {
	constructor(id, ownerId) {
		super(id, ownerId);
	}

	get id() {
		return super.id;
	}

	set id(id) {
		throw Error(`Id field is immutable in system types`);
	}
}
