import { mSystemObjectDef } from "../system_object_def";

export class mDocDef extends mSystemObjectDef {
	static CLASS_NAME = "mDocDef";
	static NAME = "Doc";
	static PLURAL_NAME = "Docs";
	static ICON = "ph-file";

	static ID = 7;

	static DOCUMENT_FIELD_NAME = "document";
	static DOC_TYPE_FIELD_NAME = "docType";
	static MEETING_MINUTES_TYPE_NAME = "Meeting Minutes";
	static STATUS_FIELD_NAME = "status";
	static PROPOSAL_TYPE_NAME = "Proposal";

	constructor(ownerId) {
		super(mDocDef.ID, ownerId);
	}
}
