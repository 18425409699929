export const ICONS = {
	arrowSquareOut: "ph-arrow-square-out",
	arrowLineLeft: "ph-arrow-line-left",
	arrowLineRight: "ph-arrow-line-right",
	arrowRight: "ph-arrow-right",
	arrowCounterClockwise: "ph-arrow-counter-clockwise",
	backspace: "ph-backspace",
	bookmark: "ph-bookmark-simple",
	cards: "ph-cards",
	caretDown: "ph-caret-down",
	caretRight: "ph-caret-right",
	caretLeft: "ph-caret-left",
	caretUp: "ph-caret-up",
	calendar: "ph-calendar-blank",
	copy: "ph-copy-simple",
	chatsCircle: "ph-chats-circle",
	chatText: "ph-chat-text",
	check: "ph-check",
	checkCircle: "ph-check-circle",
	checks: "ph-checks",
	checkSquare: "ph-check-square",
	caretUpDown: "ph-caret-up-down",
	checkDone: "CheckDone",
	checkEmpty: "CheckEmpty",
	circlesThreePlus: "ph-circles-three-plus",
	clock: "ph-clock",
	command: "ph-command",
	cornersIn: "ph-corners-in",
	cornersOut: "ph-corners-out",
	cross: "ph-x",
	crossCircle: "ph-x-circle",
	crosshairSimple: "ph-crosshair-simple",
	crown: "ph-crown",
	customObject: "ph-cube",
	currencyCircleDollar: "ph-currency-circle-dollar",
	currencyDollar: "ph-currency-dollar",
	dashboard: "ph-squares-four",
	diceFive: "ph-dice-five",
	download: "ph-download-simple",
	export: "ph-export",
	envelope: "ph-envelope",
	empty_envelope: "empty_envelope.svg",
	eye: "ph-eye",
	eyeSlash: "ph-eye-slash",
	filter: "ph-funnel-simple",
	flag: "ph-flag",
	fullscreen: "ph-frame-corners",
	grabbable: "ph-dots-six-vertical",
	group: "ph-stack",
	hidden: "ph-eye-slash",
	image: "ph-image",
	info: "ph-info",
	kanban: "ph-kanban",
	list: "ph-list-dashes",
	link: "ph-link",
	listChecks: "ph-list-checks",
	lock: "ph-lock",
	lockOpen: "ph-lock-open",
	microphoneOn: "ph-microphone",
	microphoneOff: "ph-microphone-slash",
	notePencil: "ph-note-pencil",
	pause: "ph-pause",
	pip: "ph-picture-in-picture",
	floatBack: "float_back.svg",
	play: "ph-play",
	skipForward: "ph-skip-forward",
	plus: "ph-plus",
	percent: "ph-percent",
	paragraph: "ph-paragraph",
	phone: "ph-phone",
	paperPlaneTile: "ph-paper-plane-tilt",
	palette: "ph-palette",
	progress: "ph-circle-notch",
	callPresent: "call_present.svg",
	stopCallPresent: "call_stop_presenting.svg",
	record: "ph-record",
	screen: "ph-monitor",
	sort: "ph-arrows-down-up",
	sortAsc: "ph-sort-ascending",
	sortDesc: "ph-sort-descending",
	search: "ph-magnifying-glass",
	zoomIn: "ph-magnifying-glass-plus",
	zoomOut: "ph-magnifying-glass-minus",
	settings: "ph-gear",
	speakerHigh: "ph-speaker-high",
	speakerLow: "ph-speaker-low",
	speakerSlash: "ph-speaker-slash",
	star: "ph-star",
	stop: "ph-square",
	simplePencil: "ph-pencil-simple",
	square: "ph-square",
	task: "ph-check-square-offset",
	table: "ph-table",
	tag: "ph-tag",
	trash: "ph-trash",
	text: "ph-text-t",
	textHeading1: "ph-text-h-one",
	textHeading2: "ph-text-h-two",
	textHeading3: "ph-text-h-three",
	textBold: "ph-text-b",
	textItalic: "ph-text-italic",
	textStrikethrough: "ph-text-strikethrough",
	textUnderline: "ph-text-underline",
	textAlignLeft: "ph-text-align-left",
	textAlignCenter: "ph-text-align-center",
	textAlignRight: "ph-text-align-right",
	textAlignJustify: "ph-text-align-justify",
	toggleRight: "ph-toggle-right",
	timer: "ph-timer",
	upload: "ph-upload-simple",
	listDashes: "ph-list-dashes",
	listNumbers: "ph-list-numbers",
	listBullet: "ph-list-bullets",
	linkFile: "link_file.svg",
	notification: "ph-bell-simple",
	notificationOff: "ph-bell-slash",
	folders: "ph-folders",
	reports: "ph-chart-line",
	verticalDots: "ph-dots-three-vertical",
	horizontalDots: "ph-dots-three",
	visible: "ph-eye",
	warning: "ph-warning",
	warningCircle: "ph-warning-circle",
	key: "ph-key",
	globeWest: "ph-globe-hemisphere-west",
	file: "ph-file",
	pdf: "ph-file-pdf",
	csv: "ph-file-csv",
	video: "ph-video",
	videoCamera: "ph-video-camera",
	videoCameraOff: "ph-video-camera-slash",
	hardDrive: "ph-hard-drive",
	hardDrives: "ph-hard-drives",
	user: "ph-user",
	userBlur: "UserBlur",
	users: "ph-users",
	usersThree: "ph-users-three",
	hourglass: "ph-hourglass",
	fileImage: "ph-file-image",
	fileText: "ph-file-text",
	sticker: "ph-sticker",
	numberCircleFour: "ph-number-circle-four",
	password: "ph-password",
	cube: "ph-cube",
	shapes: "ph-shapes",
	userCircle: "ph-user-circle",
	signIn: "ph-sign-in",
	sentimentHappy: "sentiments/happy.svg",
	sentimentConfused: "sentiments/confused.svg",
	sentimentCalm: "sentiments/calm.svg",
	sentimentSad: "sentiments/sad.svg",
	sentimentAngry: "sentiments/angry.svg",
	sentimentFear: "sentiments/fear.svg",
	sentimentSurprised: "sentiments/unknown.svg",
	sentimentDisgusted: "sentiments/unknown.svg",
	videoJumpForward5: "VideoJumpForward5",
	videoJumpBackward5: "VideoJumpBackward5",
	linkedIn: "ph-linkedin-logo",
	customArrowDown: "CustomArrowDown",
	minus: "ph-minus",
	smiley: "ph-smiley",
	magicWand: "ph-magic-wand",
	clipboardText: "ph-clipboard-text",
	ear: "ph-ear",
	sealQuestion: "ph-seal-question",
	hand: "ph-hand",
	coins: "ph-coins",
	handshake: "ph-handshake",
	database: "ph-database",
	sparkle: "ph-sparkle",
	googleLogo: "google_logo.svg",
	at: "ph-at",
	wrench: "ph-wrench",
};

// TODO: Legacy error object, can be refactored/changed
export type ErrorDetails = {
	code: string;
	icon: string;
	title: string;
	body: string;
};

export const ERRORS: Record<string, ErrorDetails> = {
	UNKNOWN_ERROR: {
		code: "UNKNOWN_ERROR",
		icon: ICONS.warningCircle,
		title: "An unknown error has occurred.",
		body: "An unknown error has occurred",
	},
	OBJECT_NOT_FOUND: {
		code: "OBJECT_NOT_FOUND",
		icon: ICONS.warningCircle,
		title: "Object not found.",
		body: "Object not found.",
	},
	SERVER_ERROR: {
		code: "SERVER_ERROR",
		icon: ICONS.warningCircle,
		title: "Server error.",
		body: "Server error.",
	},
	RESOURCE_NOT_FOUND: {
		code: "RESOURCE_NOT_FOUND",
		icon: ICONS.warningCircle,
		title: "Resource not found.",
		body: "The location you are looking for cannot be found.",
	},
	ACCESS_DENIED: {
		code: "ACCESS_DENIED",
		icon: ICONS.warningCircle,
		title: "Access denied.",
		body: "You do not have permission to access this resource.",
	},
};
