import clsx from "clsx";

import { formatDateTime, formatTime } from "@salesdesk/salesdesk-utils";

import { User, InlineUser } from "../../../../users";

import { ChannelMessageGroupMessage } from "../../../types";
import { ChimeChannelMessage } from "./ChimeChannelMessages";
import { getChimeMessageTime } from "@salesdesk/salesdesk-schemas";

interface ChatMessageGroupProps {
	isLocalParticipant: boolean;
	user?: User;
	messages: ChannelMessageGroupMessage[];
	displayTimestamp?: boolean;
	onDeleteMessage?: (messageId: string) => void;
}

export function ChimeMessageGroup({
	isLocalParticipant,
	user,
	messages,
	displayTimestamp,
	onDeleteMessage,
}: ChatMessageGroupProps) {
	return (
		<>
			{displayTimestamp ? <MessageTimestamp time={getChimeMessageTime(messages[0].CreatedTimestamp)} /> : null}
			<div className="flex flex-col gap-1">
				{!isLocalParticipant ? (
					<div className="flex justify-start">{user ? <InlineUser user={user} /> : "Unknown"}</div>
				) : null}
				<div className="flex flex-col gap-2">
					{messages.map((message) => (
						<ChimeChannelMessage
							key={message.MessageId}
							message={message}
							isLocalParticipant={isLocalParticipant}
							onDeleteMessage={onDeleteMessage}
						/>
					))}
				</div>
			</div>
		</>
	);
}

export function MessageTimestamp({ time }: { time?: number }) {
	const buildElement = (text: string) => (
		<div className={clsx("text-label-xs text-c_text_placeholder flex justify-center")}>{text}</div>
	);

	if (!time) return buildElement("Unknown");
	const date = new Date(time);
	return buildElement(date.toDateString() === new Date().toDateString() ? formatTime(date) : formatDateTime(date));
}
