import { FieldIdRecordCard } from "../../../../../../../records";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";
import { MEETING_DATE_ATTRIBUTE_NAME } from "../types";
import clsx from "clsx";
import { TimelineItem } from "./TimelineItem";
import { getIsoDay } from "@salesdesk/salesdesk-utils";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { PATHS } from "../../../../../../../../routes";

interface WorkspaceUpcomingMeetingProps {
	meetingRecord: SDRecord;
	meetingStartDateFieldId: number;
	meetingParticipantsFieldId: number;
	meetingStartDate: Date;
	dateLabel?: string;
	isLast?: boolean;
}

export function WorkspaceUpcomingMeeting({
	meetingRecord,
	meetingStartDateFieldId,
	meetingParticipantsFieldId,
	meetingStartDate,
	dateLabel,
	isLast = false,
}: WorkspaceUpcomingMeetingProps) {
	const { workspaceRecord } = useWorkspaceContext();

	const displayFieldIds = useMemo(
		() => [meetingStartDateFieldId, meetingParticipantsFieldId],
		[meetingStartDateFieldId, meetingParticipantsFieldId]
	);

	return (
		<div className="flex w-full snap-start" {...{ [MEETING_DATE_ATTRIBUTE_NAME]: getIsoDay(meetingStartDate) }}>
			<TimelineItem dateLabel={dateLabel} />
			<div className={clsx("grow overflow-hidden p-1", isLast ? "mb-0" : "mb-2")}>
				<FieldIdRecordCard
					key={meetingRecord._id}
					fieldIdsToDisplay={displayFieldIds}
					sdRecord={meetingRecord}
					getRecordLink={(recordId) =>
						workspaceRecord ? PATHS.WORKSPACE_OVERVIEW(workspaceRecord._id, recordId) : null
					}
				/>
			</div>
		</div>
	);
}
