import { asConst } from "json-schema-to-ts";
import { ObjectAssociationEndpointType } from "@salesdesk/salesdesk-model";
import { sdObjectAssociationSchema } from "./sdObjectAssociationSchema";

export const orientedObjectAssociationSchema = asConst({
	...sdObjectAssociationSchema,
	required: [...sdObjectAssociationSchema.required, "originObject"],
	title: "Oriented Association Definition",
	description: "Definition of an association, plus information about identity of requested object",
	properties: {
		...sdObjectAssociationSchema.properties,
		originObject: {
			title: "Origin Object",
			description:
				"Which object in the association definition corresponds to the input of the GetObjectAssociations query",
			type: "string",
			enum: Object.values(ObjectAssociationEndpointType),
		},
	},
});
