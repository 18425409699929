import { createContext, useContext } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { DirectedSDObjectAssociation } from "../../../hooks";

interface AssociationActionsContextProps {
	sourceRecord?: SDRecord;
	objectAssociation?: DirectedSDObjectAssociation;

	setAssociationRecordToPreview?: (record?: SDRecord) => void;
	setAssociationRecordToRemove?: (record?: SDRecord) => void;
}

const initialValues: AssociationActionsContextProps = {
	sourceRecord: undefined,
	objectAssociation: undefined,
};

export const AssociationActionsContext = createContext<AssociationActionsContextProps>(initialValues);

export function useAssociationActionsContext() {
	const context = useContext(AssociationActionsContext);

	if (context == null) {
		return initialValues;
	}

	return context;
}
