import { PropsWithChildren, useCallback } from "react";

import { useLazyGetMyChimeCredentialsQuery } from "../../../../../auth/api/authApi";
import { ChimeMessagingProvider } from "./ChimeMessagingProvider";

export function MyCredentialsChimeMessagingProvider({ children }: PropsWithChildren) {
	const [getMyChimeCredentials] = useLazyGetMyChimeCredentialsQuery();
	const credentialsProvider = useCallback(() => getMyChimeCredentials().unwrap(), [getMyChimeCredentials]);

	return <ChimeMessagingProvider credentialsProvider={credentialsProvider}>{children}</ChimeMessagingProvider>;
}
