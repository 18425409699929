import { forwardRef, useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { InputValidationState, Icon, InputContainer, Button } from "@salesdesk/daisy-ui";

import { BaseSelectButtonProps } from "../../types";
import { debounce } from "lodash";

interface SelectButtonProps extends BaseSelectButtonProps {
	currentValidationState?: InputValidationState;
	open?: boolean;
	onClearClick?: () => void;
}

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
	(
		{
			selectedOption,
			placeholder = "Select option",
			currentValidationState = InputValidationState.initial,
			disabled,
			onClearClick,
			open,
			...restProps
		},
		ref
	) => {
		const [isFocused, setIsFocused] = useState(false);

		// isClearable needs to be debounced to prevent flickering when the popover opens, and for the clearable button click event to be called
		const [debouncedIsClearable, setDebouncedIsClearable] = useState(false);
		const debounceIsClearable = useMemo(() => debounce((isClearable) => setDebouncedIsClearable(isClearable), 120), []);
		useEffect(() => {
			debounceIsClearable(selectedOption && placeholder && (open || isFocused));
		}, [selectedOption, placeholder, open, debounceIsClearable, isFocused]);

		const buttonContents = (
			<button
				ref={ref}
				disabled={disabled}
				className={clsx(disabled && "cursor-not-allowed", "group relative w-full")}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				{...restProps}
			>
				<InputContainer disabled={disabled} validationState={currentValidationState}>
					<div className="flex min-h-10 w-full items-center justify-between py-2 pl-4 pr-2">
						{selectedOption ? (
							<div
								className={clsx(
									selectedOption.descriptionVariant === "inline_button" &&
										clsx("flex w-full items-center justify-between", debouncedIsClearable && "pr-9")
								)}
							>
								<div className="flex">
									{selectedOption?.icon ? (
										<Icon
											className={clsx(
												disabled ? "text-c_icon_disabled" : "text-c_icon_regular",
												"mr-2 flex items-center"
											)}
											icon={selectedOption.icon}
										/>
									) : null}
									<div>{selectedOption.name}</div>
								</div>
								{selectedOption?.description ? (
									<div
										className={clsx(
											"text-c_text_secondary",
											selectedOption.descriptionVariant === "inline_button" ? "text-body" : "text-body-sm"
										)}
									>
										{selectedOption.description}
									</div>
								) : null}
							</div>
						) : (
							<div className="text-c_text_placeholder">{placeholder}</div>
						)}
						{!debouncedIsClearable ? (
							<Icon
								className={clsx(disabled ? "text-c_icon_disabled" : "text-c_icon_regular", "mx-2 flex items-center")}
								icon={ICONS.caretUpDown}
							/>
						) : null}
					</div>
				</InputContainer>
			</button>
		);

		return (
			<div className="relative flex w-full">
				{buttonContents}
				{debouncedIsClearable ? (
					<div className="absolute right-1.5 top-0 flex h-full w-10 items-center justify-center">
						<Button
							variant="text"
							size="xs"
							disablePopoverOpen
							onClick={(e) => {
								e.preventDefault();
								onClearClick?.();
							}}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
						>
							<Icon className="text-c_icon_regular flex items-center" icon={ICONS.crossCircle} variant="fill" />
						</Button>
					</div>
				) : null}
			</div>
		);
	}
);
