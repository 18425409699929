import { useMemo } from "react";
import { RuleValue } from "../../types";
import { useNotificationRuleDialogContext } from "../useNotificationRuleDialogContext";

export function useUserRuleValues() {
	const { selectedUsers, currentSelection, setCurrentSelection } = useNotificationRuleDialogContext();

	return useMemo(() => {
		const values: RuleValue[] = [];
		if (!selectedUsers?.length) {
			values.push({
				placeholder: "this person",
				onClick: () => setCurrentSelection("user"),
				active: currentSelection === "user",
			});
		} else {
			values.push({
				value: selectedUsers[0].name,
				onClick: () => setCurrentSelection("user"),
				active: currentSelection === "user",
			});
		}
		return values;
	}, [selectedUsers, currentSelection, setCurrentSelection]);
}
