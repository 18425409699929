import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mProbabilityFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TEXT.name];
	static defaultComponentType: FieldComponentName = mProbabilityFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._toolTip = `Choose a number between 0 and 100`;
		this._maxLength = 3;
		this._formatDescription = `Number between 0 and 100`;
		this._icon = FIELD_TYPES.PROBABILITY.icon;
		this._supportsDefaultValue = true;
		this._componentType = mProbabilityFieldDef.defaultComponentType;
	}

	override get type(): string {
		return FIELD_TYPES.PROBABILITY.name;
	}

	override get supportedComponentTypes() {
		return mProbabilityFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value)) {
			if (Number.isNaN(value) || !Number.isInteger(value) || value < 0 || value > 100) {
				return this.toolTip || "Invalid value";
			}
		}

		return super.validate(value, isTemplate);
	}

	override supportsMaxLength() {
		return false;
	}
}
