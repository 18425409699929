import { useEffect } from "react";

import { useAppDispatch } from "../../../../store/store";

import { useChannelSelector } from "./useChannelSelector";
import { fetchInitialChannelMemberships } from "../thunks";
import { useChimeMessagingContext } from "../../components";

// This selector is used to get the memberships of a channel from the store and fetches it if it doesn't exist.
export function useChannelMembershipsSelector(channelArn: string) {
	const { chime } = useChimeMessagingContext();

	const dispatch = useAppDispatch();
	const channel = useChannelSelector(channelArn);

	useEffect(() => {
		if (!chime || !channelArn) {
			return;
		}

		dispatch(fetchInitialChannelMemberships({ channelArn, chime }));
	}, [channelArn, chime, dispatch]);

	return channel?.memberships?.members;
}
