export function LoadingPage() {
	return (
		<div className="bg-c_bg_04 flex h-screen w-full items-center justify-center">
			<div className="text-body-lg text-c_text_primary flex flex-col items-center justify-center gap-3">
				<div className="animate-spin-slow size-8">
					<img src="/static/images/loading-spinner.webp" alt="Loading Spinner" className="h-full w-full" />
				</div>
				Loading...
			</div>
		</div>
	);
}
