import { NotificationsContext } from "../hooks";
import { PropsWithChildren } from "react";
import { useNotifications } from "../hooks/useNotifications";
import { SDNotification } from "@salesdesk/salesdesk-schemas";

interface NotificationsProviderProps {
	onNotificationClicked: (notification: SDNotification) => void;
	resetNewNotificationsCounter: () => void;
}

export function NotificationsProvider({
	onNotificationClicked,
	resetNewNotificationsCounter,
	children,
}: PropsWithChildren<NotificationsProviderProps>) {
	const {
		notifications,
		unreadOnly,
		setUnreadOnly,
		notificationType,
		setNotificationType,
		isLoading,
		loadNext,
		markRead,
		markUnread,
	} = useNotifications(resetNewNotificationsCounter);

	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				unreadOnly,
				setUnreadOnly,
				notificationType,
				setNotificationType,
				isLoading,
				loadNext,
				markRead,
				markUnread,
				onNotificationClicked,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
}
