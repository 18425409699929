import { NumberParam, StringParam, withDefault } from "use-query-params";
import { RecordDetailViewState } from "../types";
import { useURLState } from "../../../../../../../hooks";

export const DEFAULT_RECORD_DETAIL_VIEW_STATE: RecordDetailViewState = {
	topLevelTab: 0,
	tab: 0,
	recordId: undefined,
	transcriptQuery: undefined,
};

// Functions to determine if a given value for a record detail view state property is valid
const RECORD_DETAIL_VIEW_STATE_VALUE_VALIDITY: Record<keyof RecordDetailViewState, (value: unknown) => boolean> = {
	topLevelTab: (value: unknown) => typeof value === "number",
	tab: (value: unknown) => typeof value === "number",
	recordId: (value: unknown) => typeof value === "number",
	transcriptQuery: (value: unknown) => typeof value === "string",
};

export function useRecordDetailViewState() {
	const {
		urlState: recordDetailViewState,
		propOnChange: recordDetailPropOnChange,
		resetState: resetRecordDetailViewState,
	} = useURLState({
		defaultState: DEFAULT_RECORD_DETAIL_VIEW_STATE,
		valueValidityMap: RECORD_DETAIL_VIEW_STATE_VALUE_VALIDITY,
		paramConfigMap: {
			topLevelTab: withDefault(NumberParam, DEFAULT_RECORD_DETAIL_VIEW_STATE.topLevelTab),
			tab: withDefault(NumberParam, DEFAULT_RECORD_DETAIL_VIEW_STATE.tab),
			recordId: withDefault(NumberParam, DEFAULT_RECORD_DETAIL_VIEW_STATE.recordId),
			transcriptQuery: withDefault(StringParam, DEFAULT_RECORD_DETAIL_VIEW_STATE.transcriptQuery),
		},
	});

	return { recordDetailViewState, recordDetailPropOnChange, resetRecordDetailViewState };
}
