import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { useGetObjectById } from "../../../../../../hooks/useGetObjectById";
import { EditMultiOptionField } from "../../../../../fields/components/Fields/OptionField/components/EditMultiOptionField";
import { getFullRecordFormFieldId, getRecordFormFieldId } from "../../../RecordForm";
import { Accordion } from "../../../../../../components/Accordion";
import { Button } from "@salesdesk/daisy-ui";
import { DirectedSDObjectAssociation } from "../../../../../recordAssociations";
import { RECORD_ASSOCIATION_KEY } from "./RecordCreateForm";

interface AssociationFieldProps {
	associationKey: string;
	association: DirectedSDObjectAssociation;
}

export function AssociationField({ association, associationKey }: AssociationFieldProps) {
	const {
		control,
		formState: { defaultValues },
	} = useFormContext();

	const associationId = String(association.id);

	const [showSelect, setShowSelect] = useState(
		Boolean(defaultValues?.[RECORD_ASSOCIATION_KEY]?.[getRecordFormFieldId(associationId)])
	);

	const { sdObject: associationObject } = useGetObjectById(association.connectedObject.id);

	if (!associationObject) return null;

	return (
		<Accordion title={capitalizeString(association.connectedObject.label)} unmount={false} defaultOpen={showSelect}>
			<div className="mt-4">
				{showSelect ? (
					<Controller
						control={control}
						name={getFullRecordFormFieldId(associationKey, associationId)}
						render={({ field: { onChange, onBlur, value = [], ref }, fieldState: { error } }) => (
							<EditMultiOptionField
								ref={ref}
								id={associationId}
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								hasError={Boolean(error?.message)}
								optionFieldSettings={{
									variant: "records",
									baseObjectId: associationObject._id,
								}}
							/>
						)}
					/>
				) : (
					<div className="flex w-full justify-center">
						<Button size="sm" variant="secondary" startIcon={ICONS.plus} onClick={() => setShowSelect(true)}>
							Connect {associationObject._pluralName.toLowerCase()}
						</Button>
					</div>
				)}
			</div>
		</Accordion>
	);
}
