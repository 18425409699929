import { useCallback, useEffect, useState } from "react";
import { subMinutes } from "date-fns";

import { getSDObjectFieldsByNameMap, rsr } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { mapLocalDateTimeToUtcTimestamp } from "@salesdesk/salesdesk-utils";

import { useGetObjectById } from "../../../../hooks";
import { FILTERS, InfiniteRecordSearchParams, useInfiniteRecordSearch } from "../../../records";
import { SCHEDULED_HUDDLE_EXPIRY_MINUTES } from "../../../meetings/utils";
import { ACTIVE_HUDDLE_POLLING } from "../../utils";

export function useGetActiveHuddle(channelArn?: string) {
	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const [initialLoad, setInitialLoad] = useState(true);

	const {
		sdRecords: activeHuddleRecords,
		isLoadingNewSearchParams,
		updateSearchParams,
	} = useInfiniteRecordSearch({
		limit: 1,
		sdObjectFilter: mInteractionMeetingDef.ID,
	});

	const generateSearchParams = useCallback((): InfiniteRecordSearchParams | undefined => {
		if (!meetingObject || !channelArn) return;

		const fieldMap = getSDObjectFieldsByNameMap(meetingObject);

		const meetingTypeField = fieldMap[mInteractionMeetingDef.MEETING_TYPE_FIELD_NAME];
		const meetingStatusField = fieldMap[mInteractionMeetingDef.STATUS_FIELD_NAME];
		const chatChannelArnField = fieldMap[mInteractionMeetingDef.CHAT_CHANNEL_ARN_FIELD_NAME];

		if (!meetingTypeField || !meetingStatusField || !chatChannelArnField) {
			return;
		}

		const huddleTypeId = meetingTypeField._optionValues?.find(
			(option) => option.name === mInteractionMeetingDef.MEETING_TYPES.HUDDLE
		)?.id;

		let endedMeetingStatusId: number | undefined;
		let inProgressMeetingStatusId: number | undefined;

		meetingStatusField._optionValues?.forEach((option) => {
			if (option.name === mInteractionMeetingDef.STATUSES.ENDED) {
				endedMeetingStatusId = option.id;
			}

			if (option.name === mInteractionMeetingDef.STATUSES.IN_PROGRESS) {
				inProgressMeetingStatusId = option.id;
			}
		});

		if (huddleTypeId == null || endedMeetingStatusId == null) {
			return;
		}

		const query = rsr
			.query()
			.and(rsr.equals("_deleted", false))
			.not(rsr.equals("isTemplate", true))
			.and(FILTERS.ss_is.generateSearchQuery(String(meetingTypeField._id), [huddleTypeId]))
			.and(FILTERS.ss_not_is.generateSearchQuery(String(meetingStatusField._id), [endedMeetingStatusId]))
			.and(FILTERS.is.generateSearchQuery(String(chatChannelArnField._id), channelArn));

		const dateCreatedSubQuery = FILTERS.dt_is_after.generateSearchQuery(
			"createdAt",
			mapLocalDateTimeToUtcTimestamp(subMinutes(new Date(), SCHEDULED_HUDDLE_EXPIRY_MINUTES))
		);

		if (dateCreatedSubQuery && inProgressMeetingStatusId != null) {
			query.and(
				rsr
					.subQuery()
					.or(dateCreatedSubQuery)
					.or(FILTERS.ss_is.generateSearchQuery(String(meetingStatusField._id), [inProgressMeetingStatusId]))
					.buildSubQuery()
			);
		}

		return {
			query: query.buildQuery(),
			sort: [
				{
					createdAt: {
						order: "desc",
					},
				},
			],
		};
	}, [channelArn, meetingObject]);

	useEffect(() => {
		const initialSearchParams = generateSearchParams();
		if (initialSearchParams) {
			updateSearchParams(initialSearchParams);
		}
	}, [generateSearchParams, updateSearchParams]);

	// Timeout to poll for new active huddle, initiates the timeout once the previous
	// request has finished fetching.
	useEffect(() => {
		if (isLoadingNewSearchParams || initialLoad) {
			return;
		}

		const timeoutId = setTimeout(() => {
			const initialSearchParams = generateSearchParams();
			if (initialSearchParams) {
				updateSearchParams(initialSearchParams);
			}
		}, ACTIVE_HUDDLE_POLLING);

		return () => clearTimeout(timeoutId);
	}, [updateSearchParams, isLoadingNewSearchParams, initialLoad, generateSearchParams]);

	useEffect(() => {
		if (isLoadingNewSearchParams) {
			return () => {
				setInitialLoad(false);
			};
		}
	}, [isLoadingNewSearchParams]);

	return { activeHuddle: activeHuddleRecords.length ? activeHuddleRecords[0] : undefined, initialLoad };
}
