import { useEffect, useRef } from "react";
import { getBoundingRect, isWithinRect } from "../utils";
import { useMediaAnalysisContext } from "./useMediaAnalisysContext";
import { FaceSentimentType } from "../types";

export function useGraphPanning(setActiveSentiment: (type: FaceSentimentType | null) => void) {
	const {
		faceSentimentGraphRef,
		faceSentimentGraphHolderRef,
		videoDuration,
		zoomFactor,
		setViewStartTime,
		isPanning,
		setIsPanning,
	} = useMediaAnalysisContext();
	const startX = useRef<number>();

	const graphContainerElement = faceSentimentGraphHolderRef.current;
	const graphContainerWidth = faceSentimentGraphHolderRef.current?.clientWidth ?? 0;
	useEffect(() => {
		if (!faceSentimentGraphRef.current || !graphContainerElement) {
			return;
		}
		const onScroll = () => {
			const scrollX = graphContainerElement.scrollLeft;
			if (graphContainerWidth === 0) {
				// Prevents division by zero when toggling collapsible group
				return;
			}
			setViewStartTime((scrollX / graphContainerWidth / zoomFactor) * videoDuration);
			setActiveSentiment(null);
		};
		graphContainerElement.addEventListener("scroll", onScroll);
		return () => {
			graphContainerElement.addEventListener("scroll", onScroll);
		};
	}, [
		faceSentimentGraphRef,
		graphContainerElement,
		graphContainerWidth,
		setActiveSentiment,
		setViewStartTime,
		videoDuration,
		zoomFactor,
	]);

	useEffect(() => {
		if (zoomFactor === 1) {
			return;
		}

		const onScrollStart = (e: MouseEvent) => {
			// This isn't handled using React events for UX reasons.
			// This allows to continue panning even if the mouse leaves the graph container.
			if (!graphContainerElement) {
				return;
			}
			if (!isWithinRect(getBoundingRect(graphContainerElement), e.clientX, e.clientY)) {
				return;
			}
			e.preventDefault();
			setIsPanning(true);
			setActiveSentiment(null);
			startX.current = e.pageX - graphContainerElement.offsetLeft;
		};

		const onScrollMove = (e: MouseEvent) => {
			if (!isPanning || !graphContainerElement || startX.current === undefined) {
				return;
			}
			e.preventDefault();
			const x = e.pageX - graphContainerElement.offsetLeft;
			const delta = x - startX.current;
			graphContainerElement.scrollLeft -= delta;
			startX.current = e.pageX - graphContainerElement.offsetLeft;
		};

		const onScrollEnd = (e: MouseEvent) => {
			if (!isPanning || !graphContainerElement) {
				return;
			}
			e.preventDefault();
			setIsPanning(false);
		};

		window.addEventListener("mousedown", onScrollStart);
		window.addEventListener("mousemove", onScrollMove);
		window.addEventListener("mouseup", onScrollEnd);
		window.addEventListener("mouseleave", onScrollEnd);
		return () => {
			window.removeEventListener("mousedown", onScrollStart);
			window.removeEventListener("mousemove", onScrollMove);
			window.removeEventListener("mouseup", onScrollEnd);
			window.removeEventListener("mouseleave", onScrollEnd);
		};
	}, [isPanning, graphContainerElement, zoomFactor, setIsPanning, setActiveSentiment]);
}
