import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { MenuItem } from "../../../../../menu";
import { PATHS } from "../../../../../../routes";

export function useWorkspaceRecordActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	if (mWorkspaceDef.ID !== sdObject?._id || !sdRecord || !sdObject) return [];

	return [
		{
			icon: sdObject._icon,
			text: "Go to workspace",
			link: PATHS.WORKSPACE_OVERVIEW(sdRecord?._id),
			type: "link",
		},
	];
}
