import { DatePickerStateProvider } from "@rehookify/datepicker";
import { DateTimePicker } from "../DateTimeInput";
import { useCallback, useEffect, useState } from "react";
import { TimeRange } from "@salesdesk/salesdesk-schemas";
import { mapLocalDateTimeToUtcTimestamp, mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";
import { DateTimeFieldVariant } from "../../../fields";
import { DateTimePickerControllerProps } from "../../types";
import { add, isAfter, isBefore, isSameDay } from "date-fns";
import { setCurrentTimeIncrement, setSameTime } from "./utils";

export const TimeRangePickerController = ({
	value,
	variant,
	min,
	max,
	onChange = () => undefined,
	onComplete = () => undefined,
	...pickerProps
}: DateTimePickerControllerProps<TimeRange>) => {
	const isDateOnly = variant === DateTimeFieldVariant.DATE;

	const [selectedStart, setSelectedStart] = useState<Date | null>(
		value?.start ? mapUtcTimeStampToLocalDateTime(value.start, isDateOnly) : null
	);

	const [selectedEnd, setSelectedEnd] = useState<Date | null>(
		value?.end ? mapUtcTimeStampToLocalDateTime(value.end, isDateOnly) : null
	);

	const startSelected = Boolean(selectedStart);
	const endSelected = Boolean(selectedEnd);

	// Updates the selected start/end dates as long as the user is not
	// currently inputting a time range into the calendar component
	// (Allows the range to update as the user types in the text input)
	useEffect(() => {
		// If only one of the selected start/end dates are set the user
		// is inputting a time range through the calendar component
		const selectedDateXOR = startSelected ? !endSelected : endSelected;

		if (selectedDateXOR || !value) return;

		const start = value?.start ? mapUtcTimeStampToLocalDateTime(value.start, isDateOnly) : null;
		const end = value?.end ? mapUtcTimeStampToLocalDateTime(value.end, isDateOnly) : null;

		// Don't set invalid ranges in the calendar component
		if (!start || !end || isAfter(start, end)) return;

		setSelectedStart(start);
		setSelectedEnd(end);
	}, [value, startSelected, endSelected, isDateOnly]);

	const onDatesChange = useCallback(
		(dates: Date[]) => {
			// Date range selection logic
			let isComplete = false;
			let newValue: { start: Date; end: Date } | null = null;
			if (dates.length === 0) {
				onChange(null);
			} else if (dates.length === 1) {
				setSelectedStart(dates[0]);
				setSelectedEnd(null);

				if (value == null) {
					// Set default time logic when selecting the first date if value was null
					const start = setCurrentTimeIncrement(dates[0]);
					newValue = { start, end: add(start, { hours: 1 }) };
				} else {
					newValue = { start: dates[0], end: dates[0] };
				}
			} else if (dates.length === 2 && selectedStart != null) {
				const end = dates.filter((date) => !isSameDay(date, selectedStart))[0];
				if (!end) {
					setSelectedEnd(selectedStart);
					newValue = { start: selectedStart, end: selectedStart };
				} else if (isBefore(end, selectedStart)) {
					setSelectedStart(end);
					setSelectedEnd(null);
					newValue = { start: end, end: end };
				} else {
					setSelectedEnd(end);
					newValue = { start: selectedStart, end: end };
					isComplete = isDateOnly;
				}
			}

			if (!newValue) {
				return;
			}
			if (!isDateOnly) {
				// Preserve selected time when changing date
				if (value?.start) {
					const start = mapUtcTimeStampToLocalDateTime(value.start, isDateOnly);
					newValue.start = setSameTime(newValue.start, start);
				}
				if (value?.end) {
					const end = mapUtcTimeStampToLocalDateTime(value.end, isDateOnly);
					newValue.end = setSameTime(newValue.end, end);
				}
			}
			const val = {
				start: mapLocalDateTimeToUtcTimestamp(newValue.start, isDateOnly),
				end: mapLocalDateTimeToUtcTimestamp(newValue.end, isDateOnly),
			};

			onChange(val);
			if (isComplete) {
				onComplete();
			}
		},
		[isDateOnly, onChange, onComplete, selectedStart, value]
	);

	const selectedDates = [selectedStart ?? [], selectedEnd ?? []].flat();

	return (
		<DatePickerStateProvider
			config={{
				selectedDates,
				onDatesChange,
				dates: {
					mode: "range",
					minDate: min ? mapUtcTimeStampToLocalDateTime(min, isDateOnly) : undefined,
					maxDate: max ? mapUtcTimeStampToLocalDateTime(max, isDateOnly) : undefined,
					selectSameDate: true,
				},
				calendar: {
					mode: "fluid",
				},
				locale: {
					options: {
						day: "2-digit",
						month: "long",
						year: "numeric",
						hour12: false,
						hour: "2-digit",
						minute: "2-digit",
					},
				},
				time: {
					interval: 15,
				},
			}}
		>
			<DateTimePicker
				variant={variant}
				value={value}
				isTimeRange={true}
				onChange={(newValue) => {
					if (typeof newValue === "object") {
						onChange(newValue);
					}
				}}
				{...pickerProps}
			/>
		</DatePickerStateProvider>
	);
};
