import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

// JF - The instance currently takes an array of string values in the form
// of objects, each with an id and a name (string)
// For more power, we can add backingstore support to this field (SAL-311)

export class mOptionsBuilderFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.OPTIONS_BUILDER.name];
	static defaultComponentType: FieldComponentName = mOptionsBuilderFieldDef.supportedComponentTypes[0];

	_outcomeIds: string[] | undefined;

	constructor(id: string | number) {
		super(id);
		this._componentType = mOptionsBuilderFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.OPTIONS_BUILDER.icon;
		this._outcomeIds = undefined;

		// TODO: I've commented out related items for default value because Jon said its no longer required.  Delete these if that is still the case.
		// super._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.OPTIONS_BUILDER.name;
	}

	override get supportedComponentTypes() {
		return mOptionsBuilderFieldDef.supportedComponentTypes;
	}

	get outcomeIds() {
		return this._outcomeIds;
	}

	set outcomeIds(outcomeIds) {
		this._outcomeIds = outcomeIds;
	}

	// get defaultValue() {
	// 	return this._defaultValue;
	// }
	//
	// set defaultValue(value) {
	// 	let valueObjs = [];
	//
	// 	for (let i = 0; value.length > i; i++) {
	// 		valueObjs.push(formatOptionFieldValue(value[i]));
	// 	}
	//
	// 	super.defaultValue = valueObjs;
	// }

	override validate(value: any, isTemplate: boolean | undefined) {
		if (Array.isArray(value)) {
			for (let i = 0; i < value.length; i++) {
				if (isEmpty(value[i]?.name)) {
					return "All values must have a name set.";
				}
			}
		}

		return super.validate(value, isTemplate);
	}
}
