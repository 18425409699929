import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { formatSince } from "@salesdesk/salesdesk-utils";
import { isUserObject } from "@salesdesk/salesdesk-model";
import clsx from "clsx";
import { useUserLoginStatus } from "../../../users/hooks/useUserLoginStatus";

interface UserLoginStatusProps {
	userObject?: SDObject;
	userRecord?: SDRecord;
	usePolling?: boolean;
	size?: "sm" | "xs";
}

export function UserLoginStatus({ userObject, userRecord, usePolling = true, size = "sm" }: UserLoginStatusProps) {
	const { isNowActive, lastActive, isDeactivated, isInvited } = useUserLoginStatus(userRecord, userObject, usePolling);

	if (!userRecord || !userObject || !isUserObject(userRecord._objectDefId)) {
		return null;
	}

	return (
		<div
			className={clsx(
				size === "sm" ? "text-body-sm" : "text-label-xs",
				"min-w-fit",
				isNowActive ? "text-c_success_02" : "text-c_text_secondary"
			)}
		>
			{isInvited ? (
				"Invited"
			) : isDeactivated ? (
				"Deactivated"
			) : lastActive ? (
				<>
					{isNowActive ? "Active " : "Last active "}
					<span>{isNowActive ? "now" : formatSince(lastActive)}</span>
				</>
			) : (
				"Not Invited"
			)}
		</div>
	);
}
