import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Button } from "@salesdesk/daisy-ui";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";

import { SkeletonTransition } from "../../../../../../../components/Skeleton/SkeletonTransition";
import { countPromiseResults } from "../../../../../../../utils";
import { ObjectSortingPopover, ObjectFilteringPopover, RecordModalTable } from "../../../../../../records";
import { useToast } from "../../../../../../Toasts";
import { DebouncedSearchbar } from "../../../../../../inputs";
import { useBatchCreateRecordAssociationsMutation } from "../../../../../api/recordAssociationsApi";
import { MAX_BATCH_ASSOCIATION_SIZE, getRecordAssociationRequestData } from "../../../../../utils";
import { DirectedSDObjectAssociation } from "../../../../../hooks";
import { usePotentialAssociationRecordSearch } from "../hooks";

interface LinkableRecordsListProps {
	workspaceId?: number;
	objectAssociation: DirectedSDObjectAssociation;
	sourceRecord: SDRecord;
	connectedAssociationObject: SDObject;
	availableRecordCount?: number;
	setAvailableRecordCount: (count?: number) => void;
	onLinkingAssociationSuccess: () => void;
	closeModal: () => void;
}

export function LinkableRecordsList({
	workspaceId,
	objectAssociation,
	sourceRecord,
	connectedAssociationObject,
	availableRecordCount,
	setAvailableRecordCount,
	onLinkingAssociationSuccess,
	closeModal,
}: LinkableRecordsListProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [validationError, setValidationError] = useState<string>();

	const {
		records,
		loadNextPage,
		hitCount,
		isLoadingNewSearchParams,
		isLoadingNextPage,
		filter,
		setFilter,
		sorting,
		setSorting,
		setSearchQuery,
	} = usePotentialAssociationRecordSearch({ sourceRecord, connectedAssociationObject, objectAssociation, workspaceId });

	const [batchLinkAssociations, { isLoading: isLoadingLinkCreation }] = useBatchCreateRecordAssociationsMutation();
	const toast = useToast();

	const [selectedRecords, setSelectedRecords] = useState<SDRecord[]>([]);

	useEffect(() => {
		if (isLoadingLinkCreation) {
			setIsLoading(true);
		}
	}, [isLoadingLinkCreation]);

	useEffect(() => {
		if (!availableRecordCount) {
			setAvailableRecordCount(hitCount);
		}
	}, [setAvailableRecordCount, hitCount, availableRecordCount]);

	useEffect(() => {
		if (validationError?.length && selectedRecords.length) {
			setValidationError(undefined);
		}
	}, [validationError, selectedRecords]);

	const handleLinkAssociations = useCallback(async () => {
		if (isLoadingLinkCreation) return;

		if (!selectedRecords?.length) {
			setValidationError("Please select at least one record to link");

			return;
		}

		const chunks = chunk(selectedRecords, MAX_BATCH_ASSOCIATION_SIZE);
		Promise.allSettled(
			chunks.map((chunk) =>
				batchLinkAssociations({
					workspaceId,
					associations: chunk.map((selectedRecord) =>
						getRecordAssociationRequestData(sourceRecord, selectedRecord._id, objectAssociation)
					),
				}).unwrap()
			)
		).then((results) => {
			const [successes, fails] = countPromiseResults(results, chunks);
			toast.triggerMessage({
				type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
				messageKey: "batch_record_associations_created",
				messageParams: {
					associations: `${successes} ${pluralizeWithS("association", successes)}`,
					total: String(fails + successes),
				},
			});

			onLinkingAssociationSuccess();
			setIsLoading(false);
		});
	}, [
		isLoadingLinkCreation,
		selectedRecords,
		batchLinkAssociations,
		workspaceId,
		sourceRecord,
		objectAssociation,
		toast,
		onLinkingAssociationSuccess,
	]);

	const isNoAvailableAssociations = records.length === 0 && !isLoading;
	const isFilterAndSortingDisabled = isNoAvailableAssociations && !filter.filters.length && !sorting.length;

	return (
		<div className="flex h-full w-full flex-grow flex-col">
			<div className="mb-6 flex justify-between">
				<div className="flex flex-row gap-2">
					<ObjectFilteringPopover
						disabled={isFilterAndSortingDisabled}
						value={filter}
						onChange={setFilter}
						sdObject={connectedAssociationObject}
					/>
					<ObjectSortingPopover
						disabled={isFilterAndSortingDisabled}
						value={sorting}
						onChange={setSorting}
						sdObject={connectedAssociationObject}
					/>
				</div>
				<DebouncedSearchbar onChange={setSearchQuery} />
			</div>
			<div className="flex flex-grow flex-col justify-between">
				<SkeletonTransition>
					<div className="flex h-[300px] w-full flex-col overflow-hidden">
						<RecordModalTable
							records={records}
							sdObject={connectedAssociationObject}
							selectedRecords={selectedRecords}
							setSelectedRecords={setSelectedRecords}
							singleSelect={objectAssociation.connectedObject.multiplicity === AssociationMultiplicity.ONE}
							onBottomReached={loadNextPage}
							isLoadingNextPage={isLoadingNextPage}
							isLoadingRecords={isLoadingNewSearchParams}
						/>
					</div>
				</SkeletonTransition>

				<div className="flex justify-end gap-3 pt-4 align-middle">
					{validationError ? (
						<p className="text-c_danger_01 text-label flex items-center justify-center gap-2 align-middle">
							<Icon icon={ICONS.warningCircle} className="flex" />
							<span>{validationError}</span>
						</p>
					) : (
						<p className="flex flex-col justify-center">
							<span className="text-body-sm text-c_text_secondary">
								Selected{" "}
								<span className={clsx(selectedRecords.length && "text-label-sm")}>{selectedRecords.length}</span>/
								{availableRecordCount || 0}
							</span>
						</p>
					)}
					<Button
						variant="secondary"
						disabled={isLoading}
						onClick={() => {
							setSelectedRecords([]);
							closeModal();
						}}
					>
						Cancel
					</Button>
					<Button isLoading={isLoading} onClick={handleLinkAssociations}>
						Link
					</Button>
				</div>
			</div>
		</div>
	);
}
