import { SdEventType } from "@salesdesk/salesdesk-model";

import { store } from "../../store";
import { SDApi } from "../../features/api";

import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import { APP_CONFIG } from "../../app/app_config";
import { BASE_ROUTES, SDRecord } from "@salesdesk/salesdesk-schemas";
import { WebPrincipal } from "../types";

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: APP_CONFIG.cognitoClientId,
			userPoolId: APP_CONFIG.userPoolId,
		},
	},
});

export const LOGIN_EVENT_PENDING_MARKER = "loginEventPending";

export class Auth {
	private static accessTokenPromise: Promise<string | null>;
	private static user: SDRecord;
	private static webPrincipal: WebPrincipal;

	private static apiKeyId: string;
	private static apiSecret: string;

	static setApiKey(apiKeyId: string, apiSecret: string) {
		Auth.apiKeyId = apiKeyId;
		Auth.apiSecret = apiSecret;
	}

	static setPrincipal(principal: WebPrincipal) {
		Auth.webPrincipal = principal;
	}

	static getPrincipal() {
		return Auth.webPrincipal;
	}

	static getUser() {
		return Auth.user;
	}

	static setUser(user: SDRecord) {
		Auth.user = user;
	}

	static isAuthenticated() {
		return Auth.getUser() != null;
	}

	static isAuthenticatedViaApiKey() {
		return Auth.getUser() != null && Auth.apiKeyId != null && Auth.apiSecret != null;
	}

	private static async getAccessToken() {
		if (Auth.accessTokenPromise) {
			const currentToken = await Auth.accessTokenPromise;
			if (currentToken == null) {
				throw Error("Current token missing");
			}
			const currentTokenExpiryTime = Auth.parseAccessToken(currentToken).exp;
			const now = new Date().getTime();
			// Only use access token if it will not have expired in 60s
			if (currentTokenExpiryTime > now / 1000 + 60) {
				return currentToken;
			}
		}

		const refreshToken = Auth.getRefreshToken(localStorage.getItem("jwt"));
		Auth.accessTokenPromise = new Promise((resolve, reject) => {
			Auth.fetchNewAccessToken(refreshToken, (err, accessToken) => {
				if (err) {
					reject(err);
				} else {
					resolve(accessToken);
				}
			});
		});
		return Auth.accessTokenPromise;
	}

	private static getApiAuthHeader(): string | undefined {
		if (!Auth.apiKeyId || !Auth.apiSecret) {
			return undefined;
		}
		const token = window.btoa(`${Auth.apiKeyId}:${Auth.apiSecret}`);
		return `Basic ${token}`;
	}

	private static async getAccessTokenAuthHeader() {
		const accessToken = await Auth.getAccessToken();
		return `Bearer ${accessToken}`;
	}

	static async getAuthHeader() {
		const authHeader = Auth.getApiAuthHeader();
		if (authHeader != null) {
			return authHeader;
		}
		return await Auth.getAccessTokenAuthHeader();
	}

	private static setAccessToken(accessToken: string) {
		Auth.accessTokenPromise = Promise.resolve(accessToken);
	}

	private static getAccessTokenFromJwt(jwt: string | undefined): string {
		const accessToken = JSON.parse(jwt || "{}").access_token;
		if (accessToken == null) {
			throw Error("Access token missing");
		}
		return accessToken;
	}

	private static getRefreshToken(jwt: string | undefined | null): string {
		const refreshToken = JSON.parse(jwt || "{}").refresh_token;
		if (refreshToken == null) {
			throw Error("Refresh token missing");
		}
		return refreshToken;
	}

	private static parseAccessToken(jwt: string) {
		return JSON.parse(window.atob(jwt.split(".")[1]));
	}

	private static fetchNewAccessToken(
		refreshToken: string,
		callback: (error: string | null, accessToken: string | null) => void
	) {
		const requestInfo = encodeURI(
			["grant_type=refresh_token", "client_id=" + APP_CONFIG.cognitoClientId, "refresh_token=" + refreshToken].join("&")
		);

		fetch(APP_CONFIG.authTokenUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: requestInfo,
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Request failed");
				}
				return response.json();
			})
			.then((data) => {
				const accessToken = data.access_token;
				if (accessToken == null) {
					throw new Error("Access token missing");
				}
				Auth.setAccessToken(accessToken);
				localStorage.setItem(
					"jwt",
					JSON.stringify({
						access_token: accessToken,
						refresh_token: refreshToken,
					})
				);
				callback(null, accessToken);
			})
			.catch((error) => {
				callback(error.message, null);
			});
	}

	private static getRedirectUri() {
		const locationParts = window.location.href.split("/");
		return locationParts[0] + "//" + locationParts[2];
	}

	private static redirectToAuthUrl(url: string) {
		const redirectUri = Auth.getRedirectUri();
		url += "&redirect_uri=" + encodeURIComponent(redirectUri);
		url += "&state=" + encodeURIComponent(window.location.href);
		localStorage.setItem("originalUri", window.location.href);
		window.location.replace(url);
	}

	static redirectToLoginPage() {
		Auth.redirectToAuthUrl(APP_CONFIG.baseAuthRedirectUrl);
	}

	static logout() {
		localStorage.removeItem("jwt");
		store.dispatch(
			(SDApi.endpoints as any).postEvent.initiate({
				event_type: SdEventType.USER_LOGGED_OUT,
				params: {},
			})
		);
		signOut();
		Auth.redirectToAuthUrl(APP_CONFIG.baseAuthLogoutUrl);
	}

	static ensureLogin(callback: () => void) {
		const urlSearchParams = new URLSearchParams(window.location.href.split("?")[1]);
		const params = Object.fromEntries(urlSearchParams.entries());

		// Users logging in for the first time may incorrectly not have been redirected to the page they originally tried to view
		if (params.error_description && params.error_description.startsWith("Already found an entry for username")) {
			const originalUri = localStorage.getItem("originalUri") || Auth.getRedirectUri();
			window.location.replace(originalUri);
			return;
		}

		const knownJwt = localStorage.getItem("jwt");

		if (params.apiToken && !knownJwt) {
			const [apiKeyId, apiSecret] = params.apiToken.split(":");
			if (!apiKeyId) throw new Error("API key ID missing");
			if (!apiSecret) throw new Error("API secret missing");
			Auth.setApiKey(apiKeyId, apiSecret);
			callback();
			return;
		}

		if (params.code) {
			const authorizationCode = params.code;
			const requestInfo = encodeURI(
				[
					"grant_type=authorization_code",
					"client_id=" + APP_CONFIG.cognitoClientId,
					"redirect_uri=" + Auth.getRedirectUri(),
					"code=" + authorizationCode,
				].join("&")
			);

			fetch(APP_CONFIG.authTokenUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: requestInfo,
			}).then((tokenResponse) => {
				if (!tokenResponse.ok) {
					Auth.redirectToLoginPage();
					return;
				}
				tokenResponse.json().then((tokenResponseBody) => {
					Auth.setAccessToken(tokenResponseBody.access_token);
					localStorage.setItem("jwt", JSON.stringify(tokenResponseBody));
					if (params.state) {
						localStorage.setItem(LOGIN_EVENT_PENDING_MARKER, "true");

						const url = new URL(params.state);

						// Avoids having to refresh the whole app if the user is already on the start page
						if (
							url.pathname === BASE_ROUTES.START &&
							`${url.origin}/${url.pathname}` === `${window.location.origin}/${window.location.pathname}`
						) {
							window.history.pushState({}, "", params.state);
							Auth.ensureLogin(callback);
						} else {
							window.location.replace(params.state);
						}
					} else {
						callback();
					}
				});
			});
			return;
		}

		if (knownJwt) {
			// If JWT has expired or will within one minute, refresh it
			const accessToken = Auth.getAccessTokenFromJwt(knownJwt);
			const jwtExpiryTime = Auth.parseAccessToken(accessToken).exp;
			if (jwtExpiryTime < new Date().getTime() / 1000 + 60) {
				console.log("Refreshing token");
				Auth.fetchNewAccessToken(Auth.getRefreshToken(knownJwt), (err) => {
					if (err) {
						// Refresh token has failed so user must login again
						console.error("Error refreshing token", err);
						localStorage.removeItem("jwt");
						Auth.redirectToLoginPage();
					} else {
						Auth.ensureLogin(callback);
					}
				});
				return;
			}
			Auth.setAccessToken(Auth.getAccessTokenFromJwt(knownJwt));
			if (params.state) {
				window.location.replace(params.state);
			} else {
				if (localStorage.getItem(LOGIN_EVENT_PENDING_MARKER)) {
					store.dispatch(
						(SDApi.endpoints as any).postEvent.initiate({
							event_type: SdEventType.USER_LOGGED_IN,
							params: {},
						})
					);
					localStorage.removeItem(LOGIN_EVENT_PENDING_MARKER);
				}
				callback();
			}
			return;
		}

		Auth.redirectToLoginPage();
	}
}
