import { DisplayRichTextField } from "../../../../fields";
import { useEventContext } from "../../../hooks/useEventContext";

export function ChatMessagePayload() {
	const { event } = useEventContext();
	if (!event.params?.message) {
		return null;
	}
	const jsonContent = JSON.parse(event.params.message as string);
	return (
		<div className="bg-c_bg_03 text-body-sm w-fit max-w-full rounded-md px-3 py-1">
			<DisplayRichTextField value={jsonContent} />
		</div>
	);
}
