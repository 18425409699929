import { useMediaAnalysisContext } from "./useMediaAnalisysContext";
import { getBoundingRect } from "../utils";
import { RefObject } from "react";

export function useGraphDimensions(holderRef: RefObject<HTMLDivElement>) {
	const {
		faceSentimentGraphRef,
		transcriptSentimentGraphRef,
		transcriptSentimentGraphOpen,
		faceSentimentGraphOpen,
		onlyTotalTalkingTime,
	} = useMediaAnalysisContext();

	const faceRect = getBoundingRect(faceSentimentGraphRef.current);
	const transcriptRect = getBoundingRect(transcriptSentimentGraphRef.current);
	const holderRect = getBoundingRect(holderRef.current);

	const cursorHeight =
		onlyTotalTalkingTime || !transcriptSentimentGraphOpen
			? faceRect.bottom - faceRect.top + 10
			: transcriptRect.bottom - (faceSentimentGraphOpen ? faceRect.top : transcriptRect.top) + 10;

	const containerWidth = faceSentimentGraphOpen
		? faceRect.right - faceRect.left
		: transcriptRect.right - transcriptRect.left;

	return {
		cursorTop: (faceSentimentGraphOpen ? faceRect.top : transcriptRect.top) - holderRect.top,
		cursorHeight,
		containerWidth,
		holderRect,
		faceRect,
		transcriptRect,
	};
}
