import { useChannelOptions } from "../../../hooks/useChannelOptions";
import { RuleValueSelect } from "./RuleValueSelect";
import { useNotificationRuleDialogContext } from "../../../hooks/useNotificationRuleDialogContext";

export function ChannelValueSelector() {
	const { selectedChannels, setSelectedChannels } = useNotificationRuleDialogContext();
	const channelOptions = useChannelOptions();

	return (
		<RuleValueSelect
			title="Channel selection"
			subtitle="You will always receive in-app notifications"
			value={selectedChannels}
			onChange={(options) => {
				const sortedOptions = [...options];
				sortedOptions.sort(
					(a, b) =>
						channelOptions.findIndex((option) => option.id === a.id) -
						channelOptions.findIndex((option) => option.id === b.id)
				);
				setSelectedChannels(sortedOptions);
			}}
			selectOptions={channelOptions}
			multiple={true}
			hideSearchbar
		/>
	);
}
