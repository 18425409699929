import { useEffect, useState } from "react";
import clsx from "clsx";

import { AbilityAction, AbilitySubject, SDObject, sdSubject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { BOARD_VIEW } from "@salesdesk/salesdesk-model";
import { Button, Spinner } from "@salesdesk/daisy-ui";

import { triggerDataBoardStateSync } from "../../../store/appStateSlice";
import { useAppDispatch } from "../../../store/store";
import { RecordCreateButton } from "../../records";
import { BoardPropOnChange } from "../types";
import { getCreateRecordTitleText, getRecordSearchNoResultMessage } from "../../records";
import { DisplayIf } from "../../../auth";
import { useBoardFiltersSelector, useBoardQuerySelector, useBoardViewSelector } from "../store";

interface ObjectBoardFooter {
	sdObject: SDObject;
	boardPropOnChange: BoardPropOnChange;
	isLoading?: boolean;
	noRecords?: boolean;
}

export function ObjectBoardFooter({ sdObject, boardPropOnChange, isLoading, noRecords }: ObjectBoardFooter) {
	const boardQuery = useBoardQuerySelector();
	const boardFilters = useBoardFiltersSelector();
	const boardView = useBoardViewSelector();

	const hasFilters = Boolean(boardFilters?.filters?.length);
	const hasSearch = Boolean(boardQuery);

	const [isSearch, setIsSearch] = useState(hasSearch);
	const [isFiltered, setIsFiltered] = useState(hasFilters);

	const dispatch = useAppDispatch();

	// Timeout as there is a delay between the board state updating and the search/filter being
	// triggered which can cause the 'no results' footer to be displayed before the search/filter
	// has begun for an object with no records
	useEffect(() => {
		if (boardQuery) {
			setTimeout(() => setIsSearch(true), 150);
		} else {
			setIsSearch(false);
		}

		const filters = boardFilters?.filters || [];
		if (filters.length) {
			setTimeout(() => setIsFiltered(true), 150);
		} else {
			setIsFiltered(false);
		}
	}, [boardQuery, boardFilters]);

	if (isLoading) {
		return (
			<div className="my-6 flex w-full items-center justify-center">
				<Spinner size="md" />
			</div>
		);
	}

	if (!noRecords) {
		return null;
	}

	const showSearchMessage = isSearch && hasSearch;
	const showFilterMessage = isFiltered && hasFilters;

	if (showSearchMessage || showFilterMessage) {
		const { noResultsText, clearButtonText } = getRecordSearchNoResultMessage(
			sdObject,
			showSearchMessage,
			showFilterMessage,
			boardQuery
		);

		return (
			<div
				className={clsx(
					boardView === BOARD_VIEW.CARD ? "mt-2" : "mt-8",
					"mb-6 flex w-full flex-col items-center gap-6"
				)}
			>
				<span className="text-body text-c_text_secondary">{noResultsText}</span>
				<Button
					variant="primary"
					size="sm"
					startIcon={ICONS.backspace}
					onClick={() => {
						boardPropOnChange("query", "");
						boardPropOnChange("filter", undefined);

						dispatch(triggerDataBoardStateSync());
					}}
				>
					{clearButtonText}
				</Button>
			</div>
		);
	}

	return (
		<div className={clsx(boardView === BOARD_VIEW.CARD ? "-ml-1" : "mt-3", "mb-3")}>
			<DisplayIf can={[AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id })]}>
				<RecordCreateButton
					key={`${sdObject._id}-create`}
					sdObject={sdObject}
					startIcon={ICONS.plus}
					size="sm"
					variant="primary_text"
				>
					{getCreateRecordTitleText(sdObject)}
				</RecordCreateButton>
			</DisplayIf>
		</div>
	);
}
