import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { JSONContent } from "@tiptap/core";

import { Comment } from "@salesdesk/salesdesk-schemas";

import { AccessibleButtonDiv } from "../../../../../../../../components/AccessibleButtonDiv";

import { useGetCurrentUserRecord, UserRecordAvatar } from "../../../../../../../users";
import { useGetContextWorkspaceId } from "../../../../../../../workspaces";
import { useDocumentCommentsContext } from "../../../../hooks/useDocumentCommentsContext";
import { COMMENT_PANEL_COMMENT_ID_PREFIX } from "../../../../utils";
import { DocumentCommentThread } from "../../../../types";
import { SubmitCommentInput } from "../../../SubmitCommentInput";
import { SingleComment } from "./SingleComment";

interface CommentThreadProps {
	thread: DocumentCommentThread;
	editingCommentId?: string;
	onEditComment?: (comment: Comment) => void;
	onEditSubmit?: (editedComment: JSONContent) => void;
	onDeleteComment?: (comment: Comment, inDocumentText?: string) => void;
	onResolveComment?: (comment: Comment, inDocumentText?: string) => void;
	onToggleCommentReaction?: (comment: Comment, emoji: string) => void;
}

export function CommentThread({
	thread,
	editingCommentId,
	onEditComment,
	onEditSubmit,
	onDeleteComment,
	onResolveComment,
	onToggleCommentReaction,
}: CommentThreadProps) {
	const workspaceId = useGetContextWorkspaceId();
	const isWorkspaceThreadInCRM = workspaceId == null && thread.comments[0].workspaceId != null;

	const isResolved = thread.comments[0].resolvedAt != null;
	const canReply = !isResolved && !isWorkspaceThreadInCRM;

	const currentUserRecord = useGetCurrentUserRecord();

	const [isReplying, setIsReplying] = useState(false);

	const { documentRecord, activeCommentId, onActiveCommentsChangeRef } = useDocumentCommentsContext();
	const { inDocumentText } = thread;

	const onReplySubmit = useCallback(() => {
		setIsReplying(false);
	}, []);

	const inputRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!isReplying) {
			return;
		}

		// Have to delay focusing on the RTF input to prevent the popover from closing
		setTimeout(() => {
			inputRef.current?.click();
		}, 50);
	}, [isReplying]);

	return (
		<AccessibleButtonDiv
			id={`${COMMENT_PANEL_COMMENT_ID_PREFIX}${thread.id}`}
			className={clsx(
				"bg-c_bg_01 flex flex-col items-start gap-1 py-2 transition-all",
				inDocumentText && "hover:bg-c_bg_04 cursor-pointer",
				activeCommentId === thread.id && "bg-c_bg_03"
			)}
			// If no inDocumentText, disable the button since there is no text to scroll to/highlight
			disabled={!inDocumentText}
			onClick={() => {
				onActiveCommentsChangeRef?.current([thread.id]);
			}}
		>
			<div className="relative flex w-full flex-col gap-5">
				{thread.comments.length > 1 ? (
					<div
						className={clsx(
							"bg-c_bg_disabled_02 absolute left-[11px] top-3 z-0 w-0.5",
							isReplying ? "-bottom-5" : "bottom-0"
						)}
					/>
				) : null}
				{thread.comments.map((comment, index) => (
					<SingleComment
						key={comment.id}
						comment={comment}
						inDocumentText={index === 0 ? thread.inDocumentText : undefined}
						onResolve={index === 0 ? onResolveComment : undefined}
						onDelete={onDeleteComment}
						showControls={!isResolved}
						isEditing={comment.id === editingCommentId}
						onEdit={onEditComment}
						submitEdit={onEditSubmit}
						onToggleReaction={onToggleCommentReaction}
					/>
				))}
			</div>

			{isReplying ? (
				<div className="mt-4 flex w-full gap-2">
					<div className="shrink-0">
						<UserRecordAvatar userRecord={currentUserRecord} withBorder size="xs" />
					</div>
					<SubmitCommentInput
						ref={inputRef}
						documentRecordId={documentRecord?._id}
						rootCommentId={thread.id}
						onSubmitSuccess={onReplySubmit}
					/>
				</div>
			) : canReply ? (
				<button
					onClick={() => setIsReplying(true)}
					className="text-link-sm text-c_text_link ring-c_action_focus ml-8 underline hover:no-underline focus-visible:no-underline focus-visible:ring"
				>
					Reply...
				</button>
			) : null}
		</AccessibleButtonDiv>
	);
}
