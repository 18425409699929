import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useCallback, useMemo } from "react";
import { RecordChangeEvent } from "../types";
import { getSDRecordsAndIdsFromChangeEvent } from "../utils";
import { useGetObjectMap } from "../../../objects/hooks";

export function useGetRelevantRecordsFromChangeEvent(sdObjectFilter?: SDObject["_id"] | SDObject["_id"][]) {
	const objectMap = useGetObjectMap();

	const objectFilterArray = useMemo(
		() => (Array.isArray(sdObjectFilter) ? sdObjectFilter : sdObjectFilter ? [sdObjectFilter] : []),
		[sdObjectFilter]
	);

	return useCallback(
		(event: CustomEvent<RecordChangeEvent>) => {
			const { sdRecords, recordIds, isIDArray } = getSDRecordsAndIdsFromChangeEvent(event);

			// Return all records in the event if no object filter is provided, if the event contains no records,
			// or if the event contains only record IDs (which can't be filtered by object type)
			if (!objectFilterArray.length || !recordIds.length || isIDArray || !sdRecords.length) {
				return { sdRecords, recordIds, isIDArray };
			}

			const filteredRecords = sdRecords.filter((record) => {
				const recordObject = objectMap.get(record._objectDefId);

				// If we can't find the object for the record, it's safer to assume the record is relevant
				if (!recordObject) {
					return true;
				}

				return objectFilterArray.includes(recordObject._id) || objectFilterArray.includes(recordObject._baseType);
			});

			return { sdRecords: filteredRecords, recordIds: filteredRecords.map((record) => record._id), isIDArray: false };
		},
		[objectFilterArray, objectMap]
	);
}
