import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../utils";
import { mFileFieldDef } from "./file_field_def";

export class mPdfFieldDef extends mFileFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.PDF_FILE.name];
	static override defaultComponentType: FieldComponentName = mPdfFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mPdfFieldDef.defaultComponentType;
		this._mimeTypesStr = "application/pdf:pdf";
		super.loadMimeTypes(this._mimeTypesStr);
		this._icon = FIELD_TYPES.PDF.icon;
	}

	override get type() {
		return FIELD_TYPES.PDF.name;
	}

	override get supportedComponentTypes() {
		return mPdfFieldDef.supportedComponentTypes;
	}

	override set mimeTypes(mimeTypes) {
		throw Error(`This method is not supported in "${FIELD_TYPES.PDF.name}" type.`);
	}

	override get mimeTypes() {
		return this._mimeTypes;
	}

	override supportsCardView() {
		return false;
	}

	override supportsPreview() {
		return true;
	}

	override supportsFormatDescription() {
		return false;
	}
}
