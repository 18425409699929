import { useMemo } from "react";
import { useChannelMembershipsSelector, useChannelSelector } from "../../store";
import { ChannelNames } from "@salesdesk/salesdesk-schemas";
import { getFirstName } from "../../../users";

const HUDDLE_PREFIX = "Huddle";

export function useChannelHuddleName(channelArn?: string) {
	const channel = useChannelSelector(channelArn);
	const channelMembership = useChannelMembershipsSelector(channelArn ?? "");

	return useMemo(() => {
		if (!channel) {
			return HUDDLE_PREFIX;
		}

		if (channel.channelSummary.Name !== ChannelNames.NotSet) {
			return `${HUDDLE_PREFIX} - ${channel.channelSummary.Name}`;
		}

		if (!channelMembership || channelMembership.length === 0) {
			return HUDDLE_PREFIX;
		}

		const memberNames = channelMembership
			.filter(({ Member: member }) => member?.Arn && member?.Name)
			.map(({ Member: member }) => getFirstName(member?.Name))
			.sort((a, b) => a.localeCompare(b));

		/*
			For the huddle we format the names to be first name only and in alphabetical order
			- 1 Participant: Name 1
			- 2 Participants: Name 1 / Name 2
			- 3 Participants: Name 1, Name 2, Name 3
			- 4+ Participants: Name 1, Name 2, Name 3 (+N)
		*/
		const additionalNamesCount = memberNames.length - 3;
		const formattedNames =
			memberNames.length <= 2
				? memberNames.join(" / ")
				: `${memberNames.slice(0, 3).join(", ")}${additionalNamesCount ? ` (+${additionalNamesCount})` : ""}`;

		return `${HUDDLE_PREFIX} - ${formattedNames}`;
	}, [channel, channelMembership]);
}
