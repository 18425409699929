import { matchPath, Navigate, Outlet } from "react-router-dom";

import { ERRORS } from "@salesdesk/salesdesk-ui";

import { Auth, useWebPrincipal } from "../../auth";
import { PATHS } from "../utils";

export function SalesDeskUserProtectedRoute() {
	const principal = useWebPrincipal();

	if (matchPath(window.location.pathname, PATHS.ROOT()) && principal.IsCustomerUser) {
		return <Navigate replace to={PATHS.WORKSPACES()} />;
	}

	return Auth.isAuthenticated() && principal.IsCustomerUser ? (
		<Navigate replace to={PATHS.ERROR(ERRORS.ACCESS_DENIED.code.toLowerCase())} />
	) : (
		<Outlet />
	);
}
