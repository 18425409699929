import { add, sub } from "date-fns";

export const exactDateOption = { id: "EXACT_DATE", name: "Exact date" } as const;
export type ExactDateOption = (typeof exactDateOption)["id"];

export const pseudoDateRangeOptions = [
	{ id: "TODAY", name: "Today" },
	{ id: "TOMORROW", name: "Tomorrow" },
	{ id: "YESTERDAY", name: "Yesterday" },
	{ id: "ONE_WEEK_AGO", name: "One week ago" },
	{ id: "ONE_WEEK_FROM_NOW", name: "One week from now" },
	{ id: "ONE_MONTH_AGO", name: "One month ago" },
	{ id: "ONE_MONTH_FROM_NOW", name: "One month from now" },
] as const;
export type PseudoDateRangeOption = (typeof pseudoDateRangeOptions)[number]["id"];

export const pseudoDateRangesByDay = [
	{ id: "DAYS_AGO", name: "Number of days ago" },
	{ id: "DAYS_FROM_NOW", name: "Number of days from now" },
] as const;
export type PseudoDateRangeByDayOption = (typeof pseudoDateRangesByDay)[number]["id"];

export type DateFilterOption = ExactDateOption | PseudoDateRangeOption | PseudoDateRangeByDayOption;

export interface DateFilterValue {
	option: DateFilterOption;
	value?: number;
}

// Expected value:
// (number) {tsValue}
// (string) "{ExactDateOption|PseudoDateRangeOption}"
// (string) "{PseudoDateRangeByDayOption}:{days}"
export function parseDateFilterValue(value: string | number | undefined): DateFilterValue | undefined {
	if (typeof value === "number") {
		return { option: "EXACT_DATE", value };
	}
	if (!value) {
		return undefined;
	}
	const [, id, val] = /^([A-Z_]+):?(\d+)?/.exec(value) ?? [];
	if (id && pseudoDateRangesByDay.some((option) => option.id === id)) {
		return { option: id as PseudoDateRangeByDayOption, value: val ? Number(val) : undefined };
	} else if (pseudoDateRangeOptions.some((option) => option.id === value)) {
		return { option: value as PseudoDateRangeOption };
	} else {
		return { option: "EXACT_DATE", value: val ? Number(val) : undefined };
	}
}

export function stringifyDateFilterValue(value: DateFilterValue): string | undefined {
	if (!value) {
		return undefined;
	}
	if (value.option === "EXACT_DATE") {
		return `EXACT_DATE:${String(value.value)}`;
	}
	if (value.value && pseudoDateRangesByDay.some((option) => option.id === value.option)) {
		return `${value.option}:${value.value}`;
	}
	return value.option;
}

export function getRelativeDateFromDateFilterValue(value: DateFilterValue): Date | undefined {
	const today = new Date();
	switch (value.option) {
		case "TODAY":
			return today;
		case "TOMORROW":
			return add(today, { days: 1 });
		case "YESTERDAY":
			return sub(today, { days: 1 });
		case "ONE_MONTH_FROM_NOW":
			return add(today, { months: 1 });
		case "ONE_MONTH_AGO":
			return sub(today, { months: 1 });
		case "ONE_WEEK_FROM_NOW":
			return add(today, { weeks: 1 });
		case "ONE_WEEK_AGO":
			return sub(today, { weeks: 1 });
		case "DAYS_FROM_NOW":
			if (!value.value) return undefined;
			return add(today, { days: value.value });
		case "DAYS_AGO":
			if (!value.value) return undefined;
			return sub(today, { days: value.value });
		case "EXACT_DATE":
			if (value.value === undefined) return undefined;
			return new Date(value.value);
	}
}

export function parseDateFilterValueAndGetDateValue(value: string | number | undefined): Date | undefined {
	const parsedValue = parseDateFilterValue(value);
	if (!parsedValue) return undefined;
	return getRelativeDateFromDateFilterValue(parsedValue);
}
