import { isAfter, isSameDay } from "date-fns";

import { CalendarCardDetails, CalendarEntry } from "../types";
import { BoardRecordDetails, FULL_RECORD_DATA_FIELD_ID } from "../../../../types";

const DATE_TOP_MARGIN = 24;

const DATE_CARD_X_MARGIN = 8;
const DATE_CARD_HEIGHT = 32;
const DATE_CARD_GAP = 8;

/**
 * Calculates the top position of the card based on its position index and the
 * height of the above cards and the gaps between them
 */
export function getDateCardAbsolutePosition(positionIndex: number) {
	return positionIndex * (DATE_CARD_HEIGHT + DATE_CARD_GAP) + DATE_TOP_MARGIN;
}

// TODO: Handle entries that start or end outside the range/boundaries of the specific week
export function generateDateCalendarCards(calendarEntries: CalendarEntry[], dateRange: Date[]) {
	const calendarCardsPerDay: CalendarCardDetails[][] = Array.from(new Array(dateRange.length), () => []);

	calendarEntries.forEach((entry) => {
		const { id, name, start, end, recordDetails, getRecordLink } = entry;

		let startDateIndex = dateRange.findIndex((date) => isSameDay(date, start));

		const startsOutsideRange = startDateIndex === -1;
		if (startsOutsideRange) {
			// If it starts before the week, then start it on the first day of the week,
			// otherwise we skip the entry as it doesn't fit in the range
			if (isAfter(start, dateRange[dateRange.length - 1])) {
				return;
			}

			startDateIndex = 0;
		}

		const endDate = end == null ? new Date(start) : end;
		let endDateIndex = dateRange.findIndex((date) => isSameDay(date, endDate));
		const endsOutsideRange = endDateIndex === -1;
		if (endsOutsideRange) {
			// If it ends after the week, then end it on the last day of the week,
			// otherwise we skip the entry as it doesn't fit in the range
			if (isAfter(dateRange[0], endDate)) {
				return;
			}

			endDateIndex = dateRange.length - 1;
		}

		// Length of entry can't be outside the boundaries of the specific week
		const lengthOfEntryInDays = Math.max(
			Math.min(endDateIndex - startDateIndex + 1, dateRange.length - startDateIndex),
			1
		);

		// Each card is within a day column, so 100% is equivalent to the full width of a day column
		const percentageWidth = lengthOfEntryInDays * 100;

		// Accounts for margin on both sides of date card which is affected by how many day columns
		// the entry passes through
		const marginOffset = `${DATE_CARD_X_MARGIN * 2 - (lengthOfEntryInDays - 1)}`;
		const width = `calc(${percentageWidth}% - ${marginOffset}px)`;

		const positionIndex = findDateCardPositionIndex(calendarCardsPerDay, startDateIndex, lengthOfEntryInDays);

		const entryCard = {
			id,
			name,
			getRecordLink,
			start,
			top: getDateCardAbsolutePosition(positionIndex),
			height: DATE_CARD_HEIGHT,
			width,
			left: `${DATE_CARD_X_MARGIN}px`,
			zIndex: startDateIndex + 1,
			sdRecord: recordDetails?.[FULL_RECORD_DATA_FIELD_ID],
			startsOutsideRange,
			endsOutsideRange,
		} satisfies CalendarCardDetails;

		// Insert the entry card at its position index for its start date. For the rest of the days
		// in its length we insert 'null' at the same position index to indicate that the position
		// is filled.
		for (let i = 0; i < lengthOfEntryInDays; i++) {
			calendarCardsPerDay[startDateIndex + i][positionIndex] = i === 0 ? entryCard : null;
		}
	});

	return calendarCardsPerDay;
}

function findDateCardPositionIndex(
	calendarCardsPerDay: CalendarCardDetails[][],
	startDateIndex: number,
	lengthOfEntryInDays: number
) {
	let index = 0;

	// Increments through all indexes in the current start date & beyond until a free position is found
	// for the card with the given length of days, where it doesn't collide with any other entry
	while (true) {
		if (isValidDateCardPosition(calendarCardsPerDay, startDateIndex, index, lengthOfEntryInDays)) {
			return index;
		}

		index++;
	}
}

/**
 * Recursive function which returns 'true' if a date card starting at a given date index and
 * has a given length of days doesn't collide with any already existing calendar card entry in the
 * 2D array at a given position index.
 */
function isValidDateCardPosition(
	calendarCardsPerDay: CalendarCardDetails[][],
	startDateIndex: number,
	positionIndex: number,
	lengthOfEntryInDays: number
) {
	// If the entry has a length of 0 days or its startDateIndex is outside the calendarCardsPerDay range
	if (lengthOfEntryInDays === 0 || startDateIndex >= calendarCardsPerDay.length) {
		return true;
	}

	const entriesOnStartDate = calendarCardsPerDay[startDateIndex];

	// 'undefined' represents a free space in a card details day array (as opposed to 'null' which
	// represents a space taken by a card with an earlier start date)
	while (positionIndex >= entriesOnStartDate.length) {
		entriesOnStartDate.push(undefined);
	}

	// If an entry is alrady at that position, we return false
	if (entriesOnStartDate[positionIndex] || entriesOnStartDate[positionIndex] === null) {
		return false;
	}

	if (lengthOfEntryInDays === 1) {
		return true;
	}

	return isValidDateCardPosition(calendarCardsPerDay, startDateIndex + 1, positionIndex, lengthOfEntryInDays - 1);
}
