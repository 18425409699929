import { ICONS } from "@salesdesk/salesdesk-ui";

import { mInteractionDef } from "../interaction_def";
import { INTERACTION_IDS } from "../interaction_constants";

export class mInteractionMeetingDef extends mInteractionDef {
	static CLASS_NAME = "mInteractionMeetingDef";
	static NAME = "Meeting";
	static PLURAL_NAME = "Meetings";
	static ICON = ICONS.calendar;

	static ID = INTERACTION_IDS.MEETING;

	static PARTICIPANTS_FIELD_NAME = "participants";
	static AGENDA_FIELD_NAME = "agenda";
	static TITLE_FIELD_NAME = "title";
	static TIME_FIELD_NAME = "time";
	static MEETING_TYPE_FIELD_NAME = "meetingType";
	static STATUS_FIELD_NAME = "status";
	static LOCATION_FIELD_NAME = "location";
	static SHOULD_GENERATE_TASKS_FIELD_NAME = "shouldGenerateTasks";
	static SHOULD_SEND_RECAP_EMAIL_FIELD_NAME = "shouldSendRecapEmail";
	static CHAT_CHANNEL_ARN_FIELD_NAME = "chatChannelArn";

	static STATUSES = {
		ENDED: "Ended",
		SCHEDULED: "Scheduled",
		IN_PROGRESS: "In Progress",
	};

	static MEETING_TYPES = {
		VIDEO_CALL: "Video call",
		IN_PERSON_MEETING: "In-person meeting",
		HYBRID: "Hybrid",
		HUDDLE: "Huddle",
	};

	static MEETING_TYPE_IDS = {
		VIDEO_CALL: 1,
		IN_PERSON_MEETING: 2,
		HYBRID: 3,
		HUDDLE: 4,
	};

	constructor(ownerId) {
		super(mInteractionMeetingDef.ID, ownerId);
	}

	get chatSupported() {
		return true;
	}
}
