import { mSingleOptionField } from "../single_option_field_inst";
import { mPriorityFieldDef } from "./priority_field_def";

export class mPriorityField extends mSingleOptionField {
	
	constructor(id, field) {		
		super(id, field);
		
		// Should always have a value set.
		if(field && !this.field.hasDefaultValue()){
			this._value = this.field.getOptionValueByName("Medium").id;
		}
	}
	
	displayValue(){
		return this.value;
	}
 
	// Used to support ordering functionality. So we order by the priority, 
	// not the string value of each priorty name.

	getOrder(){
        return mPriorityFieldDef.getOrder(this.value);
    }
}