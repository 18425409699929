import { useEffect, useMemo, useRef, useState } from "react";
import { ConnectOptions, LocalTrack, Room, connect } from "twilio-video";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetConferenceRoomQuery } from "../../../../../api/meetingsApi";

export function useConnectToRoom(localTracks: LocalTrack[], meetingId?: number) {
	const { currentData: roomInfo, isFetching: isFetchingConferenceRoom } = useGetConferenceRoomQuery(
		meetingId || skipToken
	);
	const [room, setRoom] = useState<Room | null>(null);

	const [isConnecting, setIsConnecting] = useState(false);
	const localTracksRef = useRef(localTracks);

	const connectionOptions: ConnectOptions | undefined = useMemo(() => {
		if (isFetchingConferenceRoom || roomInfo == null) {
			return undefined;
		}

		return {
			name: roomInfo.roomId,
		};
	}, [roomInfo, isFetchingConferenceRoom]);

	useEffect(() => {
		localTracksRef.current = localTracks;
	}, [localTracks]);

	useEffect(() => {
		if (!connectionOptions || isFetchingConferenceRoom || !roomInfo) {
			return;
		}

		setIsConnecting(true);

		// TODO: Handle disconnect
		let disconnect = () => undefined;

		connect(roomInfo.token, { ...connectionOptions, tracks: localTracksRef.current })
			.then(
				(newRoom) => {
					setRoom(newRoom);

					disconnect = () => {
						newRoom.disconnect();
					};
				},
				(error) => {
					// TODO: Improve error handling
					console.error(error);
				}
			)
			.finally(() => {
				setIsConnecting(true);
			});

		window.addEventListener("beforeunload", disconnect);

		return () => {
			disconnect();
			setTimeout(() => setRoom(null));
			window.removeEventListener("beforeunload", disconnect);
		};
	}, [roomInfo, connectionOptions, isFetchingConferenceRoom]);

	return {
		room,
		isConnecting,
	};
}
