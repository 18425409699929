import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mFileField extends mField {

	constructor(id, field) {	
		super(id, field);
		this._value = null;
	}

	// All file values are stored in a hashmap / JSON structure
	// pointed to by this._value

	// Name of the file (i.e. test.mp4)
	get name(){
		return this._value["name"];
	}

	set name(name){
		this._value["name"] = this.field.resolveName(name);			
	}	

	getDefaultName(fileSuffix){
		return `${this.id}_${this.field.name}.${fileSuffix}`;
	}

	// Type of the file (in MIME type format, i.e. video/mp4)

	get type(){
		return this._value["type"];
	}
	
	set type(type){
		this._value["type"] = type;			
	}	

	// Size of the file (in MIME type format, i.e. video/mp4)

	get size(){
		return this._value["size"];
	}
	
	set size(size){
		this._value["size"] = size;			
	}	

	// The Javascript File object (this is not stored / is transient)

	get fileObject(){
		return this._value["fileObject"];
	}
	
	set fileObject(fileObject){
		this._value["fileObject"] = fileObject;			
	}

	clearFileObject(){					
		this._value["fileObject"] = null;
	}

	// The Javascript binary object that represents the file

	get fileBlob(){
		return this._value["fileBlob"];
	}
	
	set fileBlob(blob){
		this._value["fileBlob"] = blob;			
	}

	clearFileBlob(){					
		this._value["fileBlob"] = null;
	}

	// Path to the file on the remote server

	get location(){
		return this._value["location"];
	}

	set location(location){
		this._value["location"] = location;
	}

	// Location of a poster image file

	get posterPath(){
		return this._value["poster"];
	}

	set posterPath(posterPath){
		this._value["poster"] = posterPath;
	}

	clearProcessingDetails() {
		this._value['processingDetails'] = undefined;
	}

	hasPosterImage(){
		return !isEmpty(this._value?.poster);
	}

	clearPosterPath(){				
		this._value["poster"] = null;
	}

	// The Javascript binary object that represents a poster image file

	get posterFileBlob(){
		return this._value["posterBlob"];
	}
	
	set posterFileBlob(blob){
		this._value["posterBlob"] = blob;			
	}
	
	clearPosterFileBlob(){					
		this._value["posterBlob"] = null;
	}

	getURL(){

		if(!this.hasFile()){
			throw Error("This file field instance doesn't have a file");
		}

		if(this.isStored()){
			return this.location;
		}

		else if(this.isCached()){
			return URL.createObjectURL(this.fileBlob);
		}
	}

	hasValue(){
		return this.hasFile() || !isEmpty(this._value?.name);
	}
	
	isStored(){		
		return !isEmpty(this._value?.location);
	}
	
	isCached(){
		return !isEmpty(this?._value?.fileBlob);
	}

	hasFileObject(){
		return !isEmpty(this?._value?.fileObject);
	}	
	
	hasFile(){
		return this.isStored() || this.isCached() || this.hasFileObject()
	}	

	unmarshall(){
		
		let unboundObj = super.unmarshall();

		if(unboundObj._value){
			delete unboundObj._value["fileBlob"];
			delete unboundObj._value["posterBlob"];
			delete unboundObj._value["fileObject"];
		}
				
		return unboundObj;
	}
}