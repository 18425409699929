import { SD_WEBSOCKET_API_HOST } from "../../../app_config";
import { Auth } from "../../../auth";
import { SDWebSocket } from "../types";

let webSocket: SDWebSocket;

export function getWebSocket() {
	if (webSocket == null) {
		console.debug(`Creating WebSocket: ${SD_WEBSOCKET_API_HOST}`);
		webSocket = new SDWebSocket(SD_WEBSOCKET_API_HOST, async () => {
			//TODO: This should never resolve to null.  Fix it when we re-wire auth
			return (await Auth.getAuthHeader()) ?? "";
		});
	}
	return webSocket;
}

// TODO: To prevent the HMR reloading issue described in 'ConnectWebSocket' I tried storing the SDWebSocket on the window
//  which did prevent HMR re-instantiating the SDWebSocket but there then seem to be some side effects of the
//  underlying webSocket not being disconnected property.  Needs further investigation.
// export function getWebSocket() {
// 	if ((window as any).SDWebSocket == null) {
// 		console.debug(`Creating WebSocket: ${SD_WEBSOCKET_API_HOST}`);
// 		(window as any).SDWebSocket = new SDWebSocket(SD_WEBSOCKET_API_HOST, async () => {
// 			//TODO: This should never resolve to null.  Fix it when we re-wire auth
// 			return (await Auth.getAccessToken()) ?? "";
// 		});
// 	}
// 	return (window as any).SDWebSocket as SDWebSocket;
// }
