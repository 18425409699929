import { useMemo } from "react";

import { SDObject, USER_OBJECT_DEF_IDS } from "@salesdesk/salesdesk-schemas";

import { useGetObjectsQuery } from "../../objects/api/objectsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export function useUserObjects(skip = false) {
	const { data, isLoading, isUninitialized } = useGetObjectsQuery(skip ? skipToken : undefined);

	return useMemo(() => {
		if (!data || isLoading || isUninitialized) {
			return undefined;
		}

		const filteredUserObjects: SDObject[] = data.filter((object) => USER_OBJECT_DEF_IDS.includes(object._id));

		return filteredUserObjects.length ? filteredUserObjects : undefined;
	}, [data, isLoading, isUninitialized]);
}
