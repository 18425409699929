import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { RecordAssociationCreatedParams } from "@salesdesk/salesdesk-model";
import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { selectIndefiniteArticle } from "@salesdesk/salesdesk-utils";

export function RecordAssociationObject() {
	const { event, target } = useEventContext();
	const { sdObject: sdOtherObject } = useGetObjectById(
		(event.params as RecordAssociationCreatedParams)?.otherRecord?.objectId
	);
	const displayName = sdOtherObject?._displayName?.toLowerCase() ?? "object";

	return (
		<>
			{selectIndefiniteArticle(displayName)} {displayName}{" "}
			{target === "NOTIFICATION" ? (
				<>
					to <LinkObject />
				</>
			) : undefined}
		</>
	);
}
