import { FilePreview, SkeletonCompactFilePreview } from "../../../../../files";
import { DisplayFieldVariant } from "../../../../types";
import { DisplayPlaceholderField } from "../../DisplayPlaceholderField";
import { FileFieldType } from "../types";
import { useSdFile } from "../../../../../files/hooks/useSdFile";
import { DisplayTextField } from "../../TextField";
import clsx from "clsx";
import { DisplayFileFieldPlaceholder } from "./DisplayFileFieldPlaceholder";

interface DisplayFileFieldProps {
	value?: number;
	fileDisplayName?: string;
	variant?: DisplayFieldVariant;
	placeholderFileFieldType?: FileFieldType;
}

export function DisplayFileField({ value, variant, placeholderFileFieldType, fileDisplayName }: DisplayFileFieldProps) {
	const { sdFile, isLoading } = useSdFile(value);

	const hasFile = sdFile?.name;

	if (variant === DisplayFieldVariant.table) {
		if (hasFile) {
			return <FilePreview file={sdFile} compact fileDisplayName={fileDisplayName} />;
		} else if (isLoading) {
			return <SkeletonCompactFilePreview />;
		} else if (fileDisplayName) {
			return <DisplayTextField value={fileDisplayName} />;
		} else {
			return <DisplayPlaceholderField />;
		}
	}

	const cardVariant = variant === DisplayFieldVariant.card || variant === DisplayFieldVariant.cardTitle;

	if (hasFile) {
		return (
			<div
				className={clsx(
					"bg-c_bg_04 aspect-video w-full overflow-hidden",
					variant === DisplayFieldVariant.workspaceOverview ? "rounded-card" : "rounded-sm"
				)}
			>
				<FilePreview file={sdFile} variant={cardVariant ? "xs" : undefined} />
			</div>
		);
	}

	return <DisplayFileFieldPlaceholder variant={variant} placeholderFileFieldType={placeholderFileFieldType} />;
}
