import { Field, RecordSubQueryClauses } from "@salesdesk/salesdesk-schemas";

import { DirectedSDObjectAssociation } from "../../../../recordAssociations";
import { FilterFieldSize } from "../utils";

export type FilterTargetId = string;
export type FilterTargetMap = Record<FilterTargetId, Field | DirectedSDObjectAssociation>;

export const EMPTY_FILTER_IDS = ["is_empty", "is_not_empty"] as const;
type EmptyFilterId = (typeof EMPTY_FILTER_IDS)[number];
export interface EmptyFilterDetails {
	id: EmptyFilterId;
	generateSearchQuery: (fieldID: string) => RecordSubQueryClauses;
}

export const TEXT_FILTER_IDS = ["t_contains", "t_not_contains", "is", "is_not", ...EMPTY_FILTER_IDS] as const;
type TextFilterId = (typeof TEXT_FILTER_IDS)[number];
export interface TextFilterDetails {
	id: TextFilterId;
	generateSearchQuery: (fieldID: string, value: string) => RecordSubQueryClauses;
}

export const NUMERIC_FILTER_IDS = [
	"n_equals",
	"n_not_equals",
	"n_greater_than",
	"n_less_than",
	...EMPTY_FILTER_IDS,
] as const;
type NumberFilterId = (typeof NUMERIC_FILTER_IDS)[number];
export interface NumericFilterDetails {
	id: NumberFilterId;
	generateSearchQuery: (fieldID: string, value: number) => RecordSubQueryClauses;
}

export const NUMERIC_RANGE_FILTER_IDS = ["n_between", ...EMPTY_FILTER_IDS] as const;
type NumberRangeFilterId = (typeof NUMERIC_RANGE_FILTER_IDS)[number];
export interface NumericRangeFilterDetails {
	id: NumberRangeFilterId;
	generateSearchQuery: (
		fieldID: string,
		value1: number | undefined,
		value2: number | undefined
	) => RecordSubQueryClauses;
}

export const DATE_FILTER_IDS = ["d_is", "d_not_is", "d_is_before", "d_is_after", ...EMPTY_FILTER_IDS] as const;
type DateFilterId = (typeof DATE_FILTER_IDS)[number];
export interface DateFilterDetails {
	id: DateFilterId;
	generateSearchQuery: (fieldID: string, value: number | string) => RecordSubQueryClauses | undefined;
}

export const DATE_BETWEEN_FILTER_IDS = ["d_between", ...EMPTY_FILTER_IDS] as const;
type DateBetweenFilterId = (typeof DATE_BETWEEN_FILTER_IDS)[number];
export interface DateBetweenFilterDetails {
	id: DateBetweenFilterId;
	generateSearchQuery: (fieldID: string, tsRangeValue: string) => RecordSubQueryClauses;
}

export const DATETIME_FILTER_IDS = ["dt_is", "dt_not_is", "dt_is_before", "dt_is_after", ...EMPTY_FILTER_IDS] as const;
type DateTimeFilterId = (typeof DATETIME_FILTER_IDS)[number];
export interface DateTimeFilterDetails {
	id: DateTimeFilterId;
	generateSearchQuery: (fieldID: string, value: number | string) => RecordSubQueryClauses | undefined;
}

export const DATETIME_BETWEEN_FILTER_IDS = ["dt_between", ...EMPTY_FILTER_IDS] as const;
type DateTimeBetweenFilterId = (typeof DATETIME_BETWEEN_FILTER_IDS)[number];
export interface DateTimeBetweenFilterDetails {
	id: DateTimeBetweenFilterId;
	generateSearchQuery: (fieldID: string, tsRangeValue: string) => RecordSubQueryClauses;
}

export const TIME_RANGE_FILTER_IDS = ["tr_is_before", "tr_is_after", ...EMPTY_FILTER_IDS] as const;
type TimeRangeFilterId = (typeof TIME_RANGE_FILTER_IDS)[number];
export interface TimeRangeFilterDetails {
	id: TimeRangeFilterId;
	generateSearchQuery: (fieldID: string, tsRangeValue: number | string) => RecordSubQueryClauses | undefined;
}

export const TIME_RANGE_BETWEEN_FILTER_IDS = ["tr_between", ...EMPTY_FILTER_IDS] as const;
type TimeRangeBetweenFilterId = (typeof TIME_RANGE_BETWEEN_FILTER_IDS)[number];
export interface TimeRangeBetweenFilterDetails {
	id: TimeRangeBetweenFilterId;
	generateSearchQuery: (fieldID: string, tsRangeValue: string) => RecordSubQueryClauses[];
}

export const SINGLE_SELECT_FILTER_IDS = ["ss_is", "ss_not_is", ...EMPTY_FILTER_IDS] as const;
type SingleSelectFilterId = (typeof SINGLE_SELECT_FILTER_IDS)[number];
export interface SingleSelectFilterDetails {
	id: SingleSelectFilterId;
	generateSearchQuery: (fieldID: string, value: string[] | number[]) => RecordSubQueryClauses;
}

export const MULTI_SELECT_FILTER_IDS = ["ms_contains_any", "ms_not_contains_any", ...EMPTY_FILTER_IDS] as const;
type MultiSelectFilterId = (typeof MULTI_SELECT_FILTER_IDS)[number];
export interface MultiSelectFilterDetails {
	id: MultiSelectFilterId;
	generateSearchQuery: (fieldID: string, value: string[] | number[]) => RecordSubQueryClauses;
}

export const BOOLEAN_FILTER_IDS = ["b_is", ...EMPTY_FILTER_IDS] as const;
type BooleanFilterId = (typeof BOOLEAN_FILTER_IDS)[number];
export interface BooleanFilterDetails {
	id: BooleanFilterId;
	generateSearchQuery: (fieldID: string, value: boolean) => RecordSubQueryClauses;
}

export const RATINGS_FILTER_IDS = [
	"n_equals",
	"n_not_equals",
	"n_greater_than",
	"n_less_than",
	...EMPTY_FILTER_IDS,
] as const;
type RatingFilterId = (typeof RATINGS_FILTER_IDS)[number];

export const ASSOCIATION_FILTER_IDS = [
	"a_contains_any",
	"a_not_contains_any",
	"a_contains_all",
	"a_not_contains_all",
] as const;
type AssociationFilterId = (typeof ASSOCIATION_FILTER_IDS)[number];
export interface AssociationFilterDetails {
	id: AssociationFilterId;
	generateSearchQuery: (filterTargetId: string, value: string[] | number[]) => RecordSubQueryClauses;
}

export type FilterId =
	| EmptyFilterId
	| TextFilterId
	| NumberFilterId
	| NumberRangeFilterId
	| DateFilterId
	| DateBetweenFilterId
	| DateTimeFilterId
	| DateTimeBetweenFilterId
	| TimeRangeFilterId
	| TimeRangeBetweenFilterId
	| SingleSelectFilterId
	| MultiSelectFilterId
	| BooleanFilterId
	| RatingFilterId
	| AssociationFilterId;

export type FilterFieldType =
	| "none"
	| "text"
	| "number"
	| "date"
	| "date_between"
	| "date_time"
	| "date_time_between"
	| "time_range"
	| "time_range_between"
	| "single_select"
	| "multi_select"
	| "boolean"
	| "rating";

export interface FilterOption {
	id: FilterId;
	name: string;
	fieldType: FilterFieldType;
	field2Type?: FilterFieldType;
	size?: FilterFieldSize;
}

export type FilterOperator = "AND" | "OR";
export type FilterValue = string | number | number[] | string[] | boolean | undefined;

export interface Filter {
	filterId: FilterId;
	filterTarget: FilterTargetId;
	value: FilterValue;
	value2?: FilterValue;
}

export interface FilterData {
	type: FilterOperator;
	filters: Filter[];
}
