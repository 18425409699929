import { useEffect, useState, useRef } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { formatSince, MINUTE_IN_MS, formatDateTime } from "@salesdesk/salesdesk-utils";

import { useRecordDetailsContext } from "../../hooks/useRecordDetailsContext";
import { RecordPreviewPopover } from "../../../RecordPreview";
import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

interface RecordDetailsFooterProps {
	alignment?: "start" | "end";
}

export function RecordDetailsFooter({ alignment = "end" }: RecordDetailsFooterProps) {
	const intervalId = useRef<ReturnType<typeof setInterval>>();

	const { sdRecord, ownerObject, ownerRecord } = useRecordDetailsContext();
	const [lastModifiedValue, setLastModifiedValue] = useState<string>();

	const ownerId = sdRecord?._ownerId || undefined;

	useEffect(() => {
		if (!sdRecord) {
			return;
		}

		const lastModifiedDate = new Date(sdRecord.updatedAt);

		setLastModifiedValue(formatSince(lastModifiedDate, "full"));

		intervalId.current = setInterval(() => {
			setLastModifiedValue(formatSince(lastModifiedDate, "full"));
		}, MINUTE_IN_MS);

		return () => {
			clearInterval(intervalId.current);
		};
	}, [sdRecord]);

	if (!sdRecord) return null;

	const dateTimeCreated = formatDateTime(new Date(sdRecord.createdAt));
	const dateTimeLastModified = formatDateTime(new Date(sdRecord.updatedAt));

	const loadingOwnerDetails = !ownerObject || !ownerRecord;

	return (
		<div
			className={clsx(
				"bg-c_bg_01 border-t-c_border_regular flex w-full border-t px-4 py-1.5",
				alignment === "end" ? "justify-end" : "justify-start"
			)}
		>
			<p className="text-label-xs text-c_text_secondary flex w-fit items-center gap-1 truncate">
				<Icon icon={ICONS.info} className="mr-1 flex" />
				<Tooltip text={dateTimeCreated} showOnTruncated={true}>
					<span className="truncate">Date created {dateTimeCreated}</span>
				</Tooltip>
				<span>|</span>
				<Tooltip text={dateTimeLastModified}>
					<span className="truncate">Last modified {lastModifiedValue}</span>
				</Tooltip>
				{ownerId != null ? (
					<>
						<span>|</span>
						<RecordPreviewPopover recordId={ownerId}>
							<span className="truncate">
								Owner {loadingOwnerDetails ? "loading..." : getSDRecordName(ownerObject, ownerRecord)}
							</span>
						</RecordPreviewPopover>
					</>
				) : null}
			</p>
		</div>
	);
}
