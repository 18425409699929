import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";

export const hasAssociationToAnyRecordClauseSchema = asConst({
	title: "Has Association To Any Record",
	description: "Will match any record that is associated to at least one of the given record IDs",
	type: "object",
	additionalProperties: false,
	required: ["hasAssociationToAnyRecord"],
	properties: {
		hasAssociationToAnyRecord: {
			type: "array",
			// TODO: change to recordIdSchema when these get moved to /records
			items: idSchema,
		},
	},
	nullable: false,
});
