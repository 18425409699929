import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Button } from "@salesdesk/daisy-ui";
import { NotificationTab } from "../utils";

interface NoMessagesViewProps {
	unreadOnly: boolean;
	showAll: () => void;
	tab: NotificationTab;
}

export function NoMessagesView({ unreadOnly, showAll, tab }: NoMessagesViewProps) {
	return (
		<div className="flex flex-col items-center gap-4 py-4">
			<Icon icon={ICONS.empty_envelope} size="2xl" />
			{unreadOnly ? (
				<>
					<div className="flex flex-col items-center gap-3">
						<div className="text-label-sm text-c_text_primary">No unread notifications</div>
						<div className="text-body-sm text-c_text_primary">Looks like you're all caught up!</div>
					</div>
					<Button variant="primary_text" size="sm" onClick={showAll}>
						Show all notifications
					</Button>
				</>
			) : (
				<div className="flex flex-col items-center gap-3">
					<div className="text-label-sm text-c_text_primary">No notifications</div>
					<div className="text-body-sm text-c_text_primary w-[274px] text-center">{tab.emptyMessage}</div>
				</div>
			)}
		</div>
	);
}
