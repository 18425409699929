import { useMemo, useState } from "react";

import { SDObject } from "@salesdesk/salesdesk-schemas";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { mTaskDef, mIssueDef } from "@salesdesk/salesdesk-model";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { countPromiseResults } from "../../../../../utils";
import { useToast } from "../../../../Toasts";
import { useBulkUpdateRecords } from "../../../../records";
import {
	getCompletableRecordStatusOptionIds,
	getIsCompletableRecordDone,
	getUpdatedFieldsForCompletableRecordStatusAndProgress,
} from "../../../../completableRecord";
import { getCanEditParameters, MAX_RECORD_BATCH_SIZE } from "../utils";
import { usePrincipalCanFilteredRecords } from "../hooks/usePrincipalCanFilteredRecords";
import { useBulkEditContext } from "../hooks/useBulkEditContext";

interface BulkMarkAsDoneProps {
	sdObject: SDObject;
}

export function BulkMarkAsDone({ sdObject }: BulkMarkAsDoneProps) {
	const toast = useToast();
	const { bulkUpdateRecords } = useBulkUpdateRecords();
	const { selectedRecords, setSelectedRecords } = useBulkEditContext();

	const [isUpdating, setIsUpdating] = useState(false);

	const incompleteTasks = useMemo(
		() =>
			sdObject ? selectedRecords.filter((selectedRecord) => !getIsCompletableRecordDone(selectedRecord, sdObject)) : [],
		[sdObject, selectedRecords]
	);
	const [editableRecords] = usePrincipalCanFilteredRecords(incompleteTasks, getCanEditParameters);

	const { completedStatusId } = useMemo(() => {
		if (!sdObject) return { completedStatusId: null };
		const { doneStatusId } = getCompletableRecordStatusOptionIds(sdObject);

		return { completedStatusId: doneStatusId };
	}, [sdObject]);

	const cannotEditIncompleteTasks = editableRecords.length === 0;

	const markSelectedTasksAsDone = () => {
		if (completedStatusId == null || !sdObject) {
			toast.trigger("error", "Unable to mark selected records as done.");
			return;
		}

		try {
			const updatedFields = getUpdatedFieldsForCompletableRecordStatusAndProgress(sdObject, completedStatusId, 100);
			const chunks = chunk(editableRecords, MAX_RECORD_BATCH_SIZE);

			setIsUpdating(true);
			Promise.allSettled(
				chunks.map((records) =>
					bulkUpdateRecords({
						records,
						updatedFields,
					})
				)
			).then((results) => {
				const [successes, fails] = countPromiseResults(results, chunks);
				toast.triggerMessage({
					type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
					messageKey: "record_bulk_update",
					messageParams: {
						records: `${successes} ${pluralizeWithS("record", successes)}`,
						total: String(fails + successes),
					},
				});

				const updatedRecords = results.flatMap((result) => (result.status === "fulfilled" ? result.value : []));
				setSelectedRecords((prevRecords) => [
					...prevRecords.filter((record) => !updatedRecords.some((updated) => updated._id === record._id)),
					...updatedRecords,
				]);
				setIsUpdating(false);
			});
		} catch (error) {
			toast.trigger("error", "Unable to mark selected records as done.");
			setIsUpdating(false);
		}
	};

	const label = useMemo(() => {
		switch (sdObject?._id) {
			case mTaskDef.ID:
				return "Mark as done";
			case mIssueDef.ID:
				return "Mark as closed";
			default:
				return "Mark as done";
		}
	}, [sdObject?._id]);

	return (
		<Tooltip
			text={
				selectedRecords.length && incompleteTasks.length === 0
					? "All selected records are already marked as done"
					: selectedRecords.length && cannotEditIncompleteTasks
						? "You don't have permission to mark selected records as done"
						: undefined
			}
			ignoreDisabled={true}
			placement="top"
		>
			<Button
				disabled={!selectedRecords.length || cannotEditIncompleteTasks || incompleteTasks.length === 0}
				onClick={markSelectedTasksAsDone}
				startIcon={ICONS.check}
				size="sm"
				variant="text_inverted"
				isLoading={isUpdating}
			>
				{label}
			</Button>
		</Tooltip>
	);
}
