import { useMemo } from "react";
import { JSONContent } from "@tiptap/core";

import { DisplayRichTextField } from "../../../../fields";
import { useGetTenantSettingsQuery } from "../../../../tenant";

export function WorkspaceListPageWelcomeMessage() {
	const { data: tenantSettings, isLoading } = useGetTenantSettingsQuery();

	const workspaceListWelcomeTextJSONContent = useMemo(() => {
		if (!tenantSettings?.workspaceListWelcomeText) {
			return null;
		}
		return JSON.parse(tenantSettings.workspaceListWelcomeText) as JSONContent;
	}, [tenantSettings]);

	if (isLoading) {
		return null;
	}

	if (!workspaceListWelcomeTextJSONContent) {
		return (
			<>
				Welcome to your collaborative workspaces! Discover everything you need about our products and services, request
				help, and learn more! Please bookmark this page for easy access.
			</>
		);
	}

	return <DisplayRichTextField value={workspaceListWelcomeTextJSONContent} />;
}
