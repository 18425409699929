import clsx from "clsx";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

interface PasswordRuleProps {
	title: string;
	isPass: boolean;
}

export function PasswordRule({ title, isPass }: PasswordRuleProps) {
	return (
		<div className={clsx("text-body-sm flex gap-1", isPass && "text-c_success_02")}>
			{/* Scaling makes the icon smoother on non-retina display (Chrome bug) */}
			<div style={{ transform: "scale(.99)" }}>
				<Icon icon={isPass ? ICONS.checkDone : ICONS.checkEmpty} className="fill-c_success_02" />
			</div>
			{title}
		</div>
	);
}
