import { useMemo } from "react";

import { Select } from "../../../../../inputs";

interface DeviceSelectProps {
	devices: MediaDeviceInfo[];
	currentDeviceId: string | undefined | null;
	onDeviceChange: (deviceId: string) => void;
	name: "microphone" | "camera" | "speaker";
}

export function DeviceSelect({ devices, currentDeviceId, onDeviceChange, name }: DeviceSelectProps) {
	const options = useMemo(
		() =>
			devices.map((device) => {
				const openBracketIndex = device.label.lastIndexOf("(");
				return {
					id: device.deviceId,
					name: openBracketIndex === -1 ? device.label : device.label.substring(0, openBracketIndex),
				};
			}),
		[devices]
	);

	return (
		<Select
			onChange={(deviceId) => onDeviceChange(deviceId as string)}
			options={options}
			value={currentDeviceId || ""}
			placeholder={devices.length === 0 ? `No ${name}` : `Select ${name}...`}
			disabled={devices.length === 0}
		/>
	);
}
