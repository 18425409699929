import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Combobox } from "@headlessui/react";

import { getSDRecordName, RecordMatchType, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Icon } from "@salesdesk/daisy-ui";

import { useGetObjectById } from "../../../../../../hooks/useGetObjectById";
import { RecordSearchResultSkeleton } from "./RecordSearchResultSkeleton";
import { useGetRecordDefaultPath } from "../../../../hooks";

const RecordMatchTypeValues: Record<string, string> = {
	[RecordMatchType.TRANSCRIPT]: "Transcript",
	[RecordMatchType.NOTE]: "Notes",
};
interface RecordSearchResultProps {
	record: SDRecord;
	onRecordSelect: (urlPath: string, newSearchbarText?: string) => void;
	searchText: string;
	highlight?: { matchType: RecordMatchType; fragment: string };
	trimThreshold?: number;
}

const DEFAULT_TRIM_THRESHOLD = 35;

export function RecordSearchResult({
	record,
	searchText = "",
	highlight,
	trimThreshold = DEFAULT_TRIM_THRESHOLD,
	onRecordSelect,
}: RecordSearchResultProps) {
	const { sdObject } = useGetObjectById(record._objectDefId);

	const { fragment, matchType } = highlight || {};

	const getRecordPath = useGetRecordDefaultPath();

	const { highlightedResultText, resultPath, urlPath, newSearchbarText } = useMemo(
		() => {
			if (!sdObject) {
				return { urlPath: "" };
			}

			const recordName = getSDRecordName(sdObject, record);

			let searchResultText = recordName;
			let resultPath = sdObject._displayName;

			let urlPath: string = getRecordPath(record._id, sdObject);
			let newSearchbarText: string | undefined = undefined;

			if (fragment && matchType && matchType !== RecordMatchType.RECORD_FIELD) {
				searchResultText = fragment.startsWith(" ") ? `...${fragment.trimStart()}` : fragment;

				const recordMatchTypeResult = RecordMatchTypeValues[matchType];
				resultPath += ` / ${recordName}${recordMatchTypeResult ? ` / ${recordMatchTypeResult}` : ""}`;
				if (matchType === RecordMatchType.TRANSCRIPT) {
					urlPath = getRecordPath(record._id, sdObject, {
						detailViewParams: {
							topLevelTab: 1,
							transcriptQuery: searchText,
						},
					});
				}
			} else {
				newSearchbarText = recordName;
			}

			const searchTextIndex = searchResultText.toLowerCase().indexOf(searchText.toLowerCase());

			// If the number of characters up to and including the search text exceeds the TRIM_THRESHOLD,
			// we trim the result text. Tries to include as many words in front of the search text while keeping
			// the search text itself within the TRIM_THRESHOLD to provide context.
			if (searchTextIndex + searchText.length > trimThreshold) {
				let startIndex = searchResultText.lastIndexOf(" ", searchTextIndex) + 1;
				const endOfSearchTextIndex = startIndex + searchText.length;

				while (startIndex > 0) {
					const newStartIndex = searchResultText.lastIndexOf(" ", startIndex - 1);

					if (endOfSearchTextIndex - startIndex > trimThreshold) {
						break;
					}

					startIndex = newStartIndex;
				}

				searchResultText = `${startIndex !== 0 ? "..." : ""}${searchResultText.substring(startIndex)}`;
			}

			let highlightedMatch = false;
			const highlightedResultText = searchResultText.split(new RegExp(`(${searchText})`, "gi")).map((part, index) => {
				if (!highlightedMatch && part.toLowerCase() === searchText.toLowerCase()) {
					highlightedMatch = true;
					return (
						<strong key={index} className="text-c_text_primary">
							{part}
						</strong>
					);
				}

				return part;
			});

			return { highlightedResultText, resultPath, urlPath, newSearchbarText };
		},
		// Have fragment and matchType separately in the dependency array to prevent re-running
		// the memo if the highlight object reference changes but the fragment/matchType don't
		[sdObject, record, getRecordPath, fragment, matchType, searchText, trimThreshold]
	);

	if (!sdObject) {
		return <RecordSearchResultSkeleton />;
	}

	return (
		<Combobox.Option value={{ id: `${record._id}-${matchType}-${fragment}`, urlPath, newSearchbarText }}>
			<Link
				to={urlPath}
				onClick={() => onRecordSelect(urlPath, newSearchbarText)}
				className={`ui-active:bg-c_bg_03 text-c_text_placeholder flex select-none flex-col gap-2 px-6 py-3`}
			>
				<div className="w-full truncate">{highlightedResultText}</div>
				<div className="text-c_text_disabled text-label-xs flex items-center gap-2">
					<Icon icon={sdObject._icon} size="sm" className="text-c_icon_disabled flex" />
					{resultPath}
				</div>
			</Link>
		</Combobox.Option>
	);
}
