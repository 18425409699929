import { useMemo } from "react";

import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { getUserMeetingsSearchParams } from "../utils";
import { useGetContextWorkspaceId } from "../../workspaces";
import { useSearchRecordsQuery } from "../../records";
import { useGetObjectById } from "../../../hooks";

export function useUserHasMeetings(userRecordId: number) {
	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const workspaceId = useGetContextWorkspaceId();

	const searchParams = useMemo(
		() =>
			meetingObject ? { ...getUserMeetingsSearchParams(meetingObject, userRecordId, workspaceId), size: 1 } : undefined,
		[meetingObject, userRecordId, workspaceId]
	);

	const { data } = useSearchRecordsQuery(searchParams || skipToken, { refetchOnMountOrArgChange: true });

	return useMemo(() => (data ? data.hits.length > 0 : undefined), [data]);
}
