import {
	mSingleOptionFieldDef,
	mStringFieldDef,
	mBooleanFieldDef,
	mIntegerFieldDef,
	mFieldDef,
	mOptionsBuilderFieldDef,
	mCurrencyFieldDef,
} from "@salesdesk/salesdesk-model";

export enum OBJECT_DESIGNER_TABS {
	fields = "fields",
	settings = "settings",
	preview = "preview",
}

export type SettingFieldFieldIdMap = Record<
	string,
	{ settingsFieldGroups: FieldSettingsGroup[]; settingsFields: FieldSettingsField[] }
>;

export interface FieldSettingsGroup {
	id: number;
	groupName?: string;
	fields: FieldSettingsField[];
}

export type FieldSettingsField =
	| mSingleOptionFieldDef
	| mStringFieldDef
	| mBooleanFieldDef
	| mIntegerFieldDef
	| mOptionsBuilderFieldDef
	| mFieldDef
	| mCurrencyFieldDef;
