import { PropsWithChildren } from "react";
import clsx from "clsx";

import { getHexOrCssVariableForColorKey } from "@salesdesk/daisy-ui";

interface TextColorSwatchProps {
	onClick: () => void;
	isActive?: boolean;
	bgColor?: string;
}

export function TextColorSwatch({ isActive, onClick, bgColor, children }: PropsWithChildren<TextColorSwatchProps>) {
	const backgroundColor = bgColor || "transparent";

	return (
		<button
			className={clsx(
				"ring-c_action_focus hover:!border-c_bg_01 relative size-8 rounded-full border-2 transition-all hover:ring focus-visible:ring",
				isActive && "ring"
			)}
			onClick={onClick}
			style={{ backgroundColor, borderColor: getHexOrCssVariableForColorKey(isActive ? "c_bg_01" : "transparent") }}
		>
			{children}
		</button>
	);
}
