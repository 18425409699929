import { Icon } from "@salesdesk/daisy-ui";
import { useImportContext } from "../../../hooks/useImportContext";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { DataPreviewRow } from "./DataPreviewRow";
import { DataPreviewSkeleton } from "./DataPreviewSkeleton";

export function DataPreview() {
	const { selectedMappingEntry } = useImportContext();

	return (
		<div className="grow overflow-hidden">
			<div className="flex max-h-full w-full flex-col">
				<div className="bg-c_bg_02 rounded-t-card shrink-0 p-4">
					<div className="flex w-full items-center justify-between">
						<div className="flex min-w-32 items-center gap-2">
							<Icon icon={ICONS.table} className="text-c_icon_regular flex items-center" />
							{selectedMappingEntry ? (
								<div className="text-label-sm text-c_text_secondary">{selectedMappingEntry.columnName}</div>
							) : (
								<Skeleton variant="header" className="h-5 w-full" />
							)}
						</div>
						<div className="flex items-center gap-2">
							<Icon icon={ICONS.eye} className="text-c_icon_disabled flex items-center" />
							<div className="text-label-xs text-c_text_secondary">Data Preview</div>
						</div>
					</div>
				</div>
				<div className="bg-c_bg_04 grow overflow-y-auto px-4 pb-4">
					{selectedMappingEntry?.examples.map((example, index) => (
						<DataPreviewRow key={index} isFirstRow={index === 0} content={example} />
					)) ?? <DataPreviewSkeleton />}
				</div>
			</div>
		</div>
	);
}
