import { getObjectNameFromUrl } from "../../utils";
import { useMemo } from "react";
import { useGetObjectsQuery } from "../../../objects/api/objectsApi";

export function useSdObjectFromUrl(url: string) {
	const { data: sdObjects } = useGetObjectsQuery();
	const objectName = getObjectNameFromUrl(url);
	const sdObject = useMemo(() => {
		if (!sdObjects) return undefined;
		return sdObjects.find((object) => object._pluralName.toLowerCase() === objectName);
	}, [sdObjects, objectName]);
	return { sdObjects, sdObject };
}
