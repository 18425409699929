import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Button } from "@salesdesk/daisy-ui";
import { LinkingModalTabs } from "./AssociationLinkingModal";

interface NoAssociationsViewProps {
	customNoResultsText?: string;
	associationObject: SDObject;
	resultsName?: string;
	multiplicity?: AssociationMultiplicity;
	onLinkAssociationClick?: (modalTab?: LinkingModalTabs) => void;
}

export function NoAssociationsView({
	customNoResultsText,
	resultsName,
	associationObject,
	multiplicity = AssociationMultiplicity.MANY,
	onLinkAssociationClick,
}: NoAssociationsViewProps) {
	const objectName = (
		multiplicity === AssociationMultiplicity.ONE ? associationObject._displayName : associationObject._pluralName
	).toLowerCase();

	return (
		<div className="text-c_text_placeholder text-body-sm my-4 w-full text-center">
			{customNoResultsText ? (
				<div>{customNoResultsText}</div>
			) : (
				<div className="flex flex-col items-center gap-6">
					{multiplicity === AssociationMultiplicity.ONE
						? `There is no ${resultsName || objectName} yet`
						: `There are no ${resultsName || objectName} yet.`}
					{onLinkAssociationClick && (
						<Button
							size="sm"
							variant="secondary"
							startIcon={ICONS.plus}
							onClick={() => onLinkAssociationClick(LinkingModalTabs.linking)}
						>
							Link {objectName}
						</Button>
					)}
				</div>
			)}
		</div>
	);
}
