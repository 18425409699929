import { ObjectDesigner } from "./ObjectDesigner";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { createEmptyCustomSDObject } from "../utils/newObjectDesigner";

export function NewObjectDesignerPage() {
	const initialSDObject = createEmptyCustomSDObject();

	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<ObjectDesigner sdObject={initialSDObject} isNewObject />
		</QueryParamProvider>
	);
}
