import { forwardRef } from "react";

import { InputValidationState, InputContainer } from "@salesdesk/daisy-ui";

import { TimeRangePickerInputProps } from "../../types";
import { TimeRangePickerInput } from "./TimeRangePickerInput";

export type TimeRangeInputProps = TimeRangePickerInputProps;

export const TimeRangeInput = forwardRef<HTMLInputElement, TimeRangeInputProps>(
	({ disabled, hasError, ...inputProps }, ref) => {
		return (
			<InputContainer
				disabled={disabled}
				validationState={hasError ? InputValidationState.error : InputValidationState.initial}
			>
				<TimeRangePickerInput ref={ref} hasError={hasError} disabled={disabled} {...inputProps} />
			</InputContainer>
		);
	}
);
