import { RefObject, useCallback, useRef, useState } from "react";
import { MAX_WORKSPACE_NAV_HEIGHT, MIN_WORKSPACE_NAV_HEIGHT } from "../types";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";

export function useUpdateNavContainerStyling(
	navCollapsedRef: RefObject<boolean>,
	collapsibleSectionRef?: RefObject<HTMLDivElement>,
	minCollapsibleSectionHeight?: number
) {
	const { scrollContainerRef } = useWorkspaceContext();

	const navContainerRef = useRef<HTMLDivElement>(null);
	const navContentsRef = useRef<HTMLDivElement>(null);

	const [currentMinHeight, setCurrentMinHeight] = useState<number>(MAX_WORKSPACE_NAV_HEIGHT);

	const updateNavStyles = useCallback(() => {
		const scrollContainer = scrollContainerRef?.current;

		const navContainer = navContainerRef.current;
		const navContents = navContentsRef.current;

		if (!scrollContainer || !navContents || !navContainer) {
			return;
		}

		const scrollTop = scrollContainer.scrollTop;
		const isCollapsed = navCollapsedRef.current;

		const newMinHeight = isCollapsed
			? MIN_WORKSPACE_NAV_HEIGHT
			: Math.max(MIN_WORKSPACE_NAV_HEIGHT, MAX_WORKSPACE_NAV_HEIGHT - scrollTop);
		const newMarginBottom = isCollapsed ? 0 : Math.min(scrollTop, MAX_WORKSPACE_NAV_HEIGHT - MIN_WORKSPACE_NAV_HEIGHT);

		// Updates style directly to avoid delay from re-rendering the react component
		navContainer.style.marginBottom = `${newMarginBottom}px`;
		navContents.style.minHeight = `${newMinHeight}px`;

		if (collapsibleSectionRef?.current && minCollapsibleSectionHeight) {
			const collapsibleEl = collapsibleSectionRef.current;

			if (newMinHeight < minCollapsibleSectionHeight) {
				collapsibleEl.style.pointerEvents = "none";
				collapsibleEl.style.position = "absolute";
				collapsibleEl.style.opacity = "0";
			} else {
				collapsibleEl.style.position = "initial";
				collapsibleEl.style.opacity = "1";
				collapsibleEl.style.pointerEvents = "initial";
			}
		}

		setCurrentMinHeight(newMinHeight);
	}, [scrollContainerRef, navCollapsedRef, collapsibleSectionRef, minCollapsibleSectionHeight]);

	return { navContainerRef, navContentsRef, updateNavStyles, currentMinHeight };
}
