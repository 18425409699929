import { add } from "date-fns";
import { mapLocalDateTimeToUtcTimestamp, mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";

export function setSameTime(date1: Date, date2: Date): Date {
	const date = new Date(date1);
	date.setHours(date2.getHours());
	date.setMinutes(date2.getMinutes());
	return date;
}

export function setCurrentTimeIncrement(date: Date, refDate: Date = new Date()): Date {
	const dateCopy = new Date(date);
	const currentTime = refDate;
	dateCopy.setHours(currentTime.getHours());
	dateCopy.setMinutes(currentTime.getMinutes());
	const minutes = currentTime.getMinutes();
	const remainder = minutes % 15;
	const minutesToIncrement = remainder !== 0 ? 15 - remainder : 0;
	return add(dateCopy, { minutes: minutesToIncrement });
}

export function getFormattedTime(timestamp: number | undefined | null): string {
	if (!timestamp) return "00:00";
	const date = mapUtcTimeStampToLocalDateTime(timestamp, false);
	return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

export function parseTime(time: string): { hours: number; minutes: number } {
	const [hours, minutes] = time.split(":");
	return { hours: parseInt(hours), minutes: parseInt(minutes) };
}

export function setFormattedTime(dateTs: number, formattedTime: string): number {
	const date = mapUtcTimeStampToLocalDateTime(dateTs, false);
	const time = parseTime(formattedTime);
	date.setHours(time.hours);
	date.setMinutes(time.minutes);
	return mapLocalDateTimeToUtcTimestamp(date, false);
}

export function isValidFormattedTime(time: string): boolean {
	if (time.length < 5) return false;
	const parsedTime = parseTime(time);
	if (!parsedTime || isNaN(parsedTime.hours) || isNaN(parsedTime.minutes)) return false;
	return parsedTime.hours >= 0 && parsedTime.hours <= 23 && parsedTime.minutes >= 0 && parsedTime.minutes <= 59;
}

export function getApproxTime(date: Date): string {
	return setCurrentTimeIncrement(date, date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}
