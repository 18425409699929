import { Comment } from "@salesdesk/salesdesk-schemas";

import { DeleteConfirmationDialog } from "../../../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { CommentPreview } from "./CommentPreview";

interface DeleteCommentDialogProps {
	comment?: Comment;
	inDocumentText?: string;
	isDeleting?: boolean;
	onConfirm: () => void;
	onDismiss: () => void;
}

export function DeleteCommentDialog({
	comment,
	inDocumentText,
	isDeleting,
	onConfirm,
	onDismiss,
}: DeleteCommentDialogProps) {
	const isReply = comment?.rootCommentId != null;

	return (
		<DeleteConfirmationDialog
			title={`Delete ${isReply ? "reply" : "comment"}`}
			open={!!comment}
			isDeleting={isDeleting}
			onConfirm={onConfirm}
			onDismiss={onDismiss}
		>
			<div className="flex flex-col gap-4">
				<div>
					Are you sure you want to delete this {isReply ? "reply" : "comment"}?{" "}
					{isReply ? "This cannot be undone." : "Deleting it will permanently remove the comment and its replies."}
				</div>
				{comment ? <CommentPreview comment={comment} inDocumentText={inDocumentText} /> : null}
			</div>
		</DeleteConfirmationDialog>
	);
}
