import { buildApiSingleTag, SDApi } from "../../api";
import { getSDRecordFieldValueByFieldName, GrantUserAccessRequest } from "@salesdesk/salesdesk-schemas";
import { dispatchRecordChangeEvent, RecordChangeType, recordsApi } from "../../records";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { deepClone } from "@salesdesk/salesdesk-utils";
import { GrantUserAccessParams } from "../types";

export const userUserAccessApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			grantUserAccess: builder.mutation<void, GrantUserAccessParams>({
				query: (body) => ({
					url: `/users/grant-access`,
					method: "POST",
					body: {
						userId: body.userRecord._id,
						welcomeMessage: body.welcomeMessage,
					} satisfies GrantUserAccessRequest,
				}),
				invalidatesTags: (result, error, request) =>
					buildApiSingleTag(request, (request) => request.userRecord._id, "Record"),
				onQueryStarted: async ({ userRecord, userObject }, { dispatch, queryFulfilled }) => {
					const optimisticUpdatedRecord = deepClone(userRecord);
					const fieldValue = getSDRecordFieldValueByFieldName(
						userObject,
						optimisticUpdatedRecord,
						mUserDef.LOGIN_AUTHORIZED_FIELD_NAME
					);
					if (!fieldValue) return;
					fieldValue._value = true;

					dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecord, true);

					// Updates the cache entry for the individual record
					const cacheUpdate = dispatch(
						recordsApi.util.updateQueryData("getRecord", userRecord._id, (draft) => {
							Object.assign(draft, optimisticUpdatedRecord);
						})
					);

					try {
						await queryFulfilled;
						dispatchRecordChangeEvent(RecordChangeType.UPDATE, userRecord._id);
					} catch {
						dispatchRecordChangeEvent(RecordChangeType.UPDATE, userRecord, true);
						cacheUpdate.undo();
					}
				},
			}),
			resendInvite: builder.mutation<null, { username: string; welcomeMessage?: string }>({
				query: ({ username, welcomeMessage }) => ({
					url: `users/${username}/resend-invitation`,
					method: "POST",
					body: { welcomeMessage },
				}),
			}),
		};
	},
});

export const { useGrantUserAccessMutation, useResendInviteMutation } = userUserAccessApi;
