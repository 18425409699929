import { asConst } from "json-schema-to-ts";
import { associationEndpointSchema } from "./associationEndpointSchema";

export const sdObjectAssociationCreateRequestSchema = asConst({
	title: "Association Definition",
	description: "Definition of an association",
	type: "object",
	additionalProperties: false,
	required: ["name", "object1", "object2"],
	properties: {
		name: {
			type: "string",
		},
		object1: associationEndpointSchema,
		object2: associationEndpointSchema,
	},
});
