import { PropsWithChildren, useEffect, useState } from "react";
import { usePanelsContext } from "../hooks";
import clsx from "clsx";

interface PanelItemProps {
	panelItemId: number;
}

export function PanelItem({ children, panelItemId }: PropsWithChildren<PanelItemProps>) {
	const { activePanelItemId, addPanelItemId } = usePanelsContext();
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		addPanelItemId(panelItemId);
	}, [panelItemId, addPanelItemId]);

	useEffect(() => {
		if (activePanelItemId === panelItemId && !isMounted) {
			setIsMounted(true);
		}
	}, [activePanelItemId, panelItemId, isMounted]);

	return isMounted ? (
		<div className={clsx(activePanelItemId === panelItemId ? "block" : "hidden")}>{children}</div>
	) : null;
}
