export const USER_IDS = {
	USER: 12,
	SALESDESK_CUSTOMER: 14,
	SALESDESK_USER: 13,
	SALESDESK_CONTACT: 2,
	SALESDESK_LEAD: 51,
};

export const UserType = Object.freeze({
	SALESDESK_CUSTOMER: "SalesDesk_customer",
	SALESDESK_USER: "SalesDesk_user",
});

export type UserType = (typeof UserType)[keyof typeof UserType];

export function isUserObject(objectDefId: number | undefined): boolean {
	if (!objectDefId) return false;
	return Object.values(USER_IDS).includes(objectDefId);
}

export function isSalesDeskUserObject(objectDefId: number | undefined): boolean {
	return objectDefId === USER_IDS.SALESDESK_USER;
}

export function isCustomerUserObject(objectDefId: number | undefined): boolean {
	return objectDefId === USER_IDS.SALESDESK_CONTACT || objectDefId === USER_IDS.SALESDESK_LEAD;
}

export function isValidUserType(value: string): value is UserType {
	return Object.values(UserType).includes(value as UserType);
}
