import { asConst } from "json-schema-to-ts";
import { fileTypeSchema } from "./fileTypeSchema";

export const sdFileCreateRequestSchema = asConst({
	title: "Create File Request",
	type: "object",
	additionalProperties: false,
	required: ["name", "type"],
	properties: {
		name: {
			title: "File Name",
			description: "Name of file",
			type: "string",
		},
		type: fileTypeSchema,
		public: {
			title: "Public",
			description: "Whether anybody can access this file",
			type: "boolean",
		},
	},
});
