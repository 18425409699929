import { mField } from "../field_inst";

export class mPasswordField extends mField {
	displayValue() {
		return '<i class="fa-solid fa-asterisk"></i>&nbsp;'.repeat(8);
	}

	marshall(fields, data, parent) {
		let boundObj = super.marshall(fields, data, parent);

		if (boundObj.hasValue()) {
			boundObj._value = atob(JSON.stringify(boundObj._value)).toString();
		}

		return boundObj;
	}

	unmarshall() {
		let unboundObj = super.unmarshall();

		if (unboundObj.hasValue()) {
			console.warn("TO-DO: handling of password field instances data");
			unboundObj._value = btoa(JSON.stringify(unboundObj._value)).toString();
		}

		return unboundObj;
	}
}
