import { useMemo } from "react";

import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import {
	getSDRecordName,
	SDObject,
	SDRecord,
	TimeRange,
	getSDRecordSystemFieldValueByFieldName,
} from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS } from "../../../../../../../../routes";

import { DateTimeFieldVariant, DisplayTimeRangeField } from "../../../../../../../fields";
import { useWebPrincipal } from "../../../../../../../../auth";

interface TitleProps {
	meetingRecord: SDRecord;
	meetingObject: SDObject;
}

export function Title({ meetingRecord, meetingObject }: TitleProps) {
	const principal = useWebPrincipal();

	const { name, timeFieldValue } = useMemo(() => {
		const recordName = getSDRecordName(meetingObject, meetingRecord);

		const timeFieldValue = getSDRecordSystemFieldValueByFieldName(
			meetingObject,
			meetingRecord,
			mInteractionMeetingDef.TIME_FIELD_NAME
		)?._value as TimeRange | undefined;

		return { name: recordName, timeFieldValue };
	}, [meetingObject, meetingRecord]);

	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-center gap-4">
				{name}
				{principal.IsUser ? (
					<Button
						as="link"
						to={PATHS.RECORD_DETAIL_VIEW(meetingRecord._id, meetingObject)}
						variant="text"
						size="xs"
						target="_blank"
						startIcon={ICONS.arrowSquareOut}
					/>
				) : null}
			</div>
			{timeFieldValue ? (
				<div className="text-body-sm text-c_text_secondary">
					<DisplayTimeRangeField value={timeFieldValue} variant={DateTimeFieldVariant.DATE_TIME} />
				</div>
			) : null}
		</div>
	);
}
