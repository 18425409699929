import { asConst } from "json-schema-to-ts";
import { appendEditableForCustomAndEditableSystem } from "../../functions/textAppenders";
import { booleanSchema } from "../../components/booleanSchema";

export const objectSupportsActivitySchema = asConst({
	...booleanSchema,
	title: "Supports Activity",
	description: appendEditableForCustomAndEditableSystem(
		"Indicates if activity can be viewed for this object's records"
	),
});
