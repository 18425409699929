import {
	AssociationsSummaryRequest,
	AssociationsSummaryResponse,
	PutRecordAssociationPath,
	SDAssociationCreateRequest,
	SDObjectAssociations,
	DeleteRecordAssociationPath,
} from "@salesdesk/salesdesk-schemas";
import { ApiTag, SDApi, withWorkspaceContext } from "../../api";
import { RecordChangeType, dispatchRecordChangeEvent } from "../../records";
interface BatchCreateRecordAssociationsArgs {
	workspaceId?: number;
	associations: SDAssociationCreateRequest[];
}

export const recordAssociationsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getObjectAssociations: builder.query<SDObjectAssociations, number>({
				query: (objectId) => ({
					url: `/objects/${objectId}/associations`,
					method: "GET",
				}),
				providesTags: () => [{ type: "Record" }],
			}),
			getRecordsAssociationSummary: builder.query<
				AssociationsSummaryResponse,
				{ searchRequestBody: AssociationsSummaryRequest; workspaceId?: number }
			>({
				query: ({ searchRequestBody, workspaceId }) => ({
					url: withWorkspaceContext(`/records/associations/search`, workspaceId),
					method: "POST",
					body: searchRequestBody,
				}),
				providesTags: () => ["AssociationsSummary"],
			}),
			deleteRecordAssociation: builder.mutation<
				void,
				{ association: DeleteRecordAssociationPath; workspaceId?: number }
			>({
				query: ({ association: { record1Id, record2Id, objectAssociationId }, workspaceId }) => ({
					url: withWorkspaceContext(
						`/associations/${record1Id}/${record2Id}/associate/${objectAssociationId}`,
						workspaceId
					),
					method: "DELETE",
				}),
				onQueryStarted: async ({ association: { record1Id, record2Id } }, { queryFulfilled }) => {
					queryFulfilled.then(() => {
						dispatchAssociationChangeEvent([record1Id, record2Id]);
					});
				},
				invalidatesTags: (result, error, arg) => [
					{ type: "Record", id: arg.association.record1Id },
					{ type: "Record", id: arg.association.record2Id },
					"AssociationsSummary",
				],
			}),
			createRecordAssociation: builder.mutation<void, { association: PutRecordAssociationPath; workspaceId?: number }>({
				query: ({ association: { record1Id, record2Id, objectAssociationId }, workspaceId }) => ({
					url: withWorkspaceContext(
						`/associations/${record1Id}/${record2Id}/associate/${objectAssociationId}`,
						workspaceId
					),
					method: "PUT",
				}),
				onQueryStarted: async ({ association: { record1Id, record2Id } }, { queryFulfilled }) => {
					queryFulfilled.then(() => {
						dispatchAssociationChangeEvent([record1Id, record2Id]);
					});
				},
				invalidatesTags: (result, error, arg) => [
					{ type: "Record", id: arg.association.record1Id },
					{ type: "Record", id: arg.association.record2Id },
					"AssociationsSummary",
				],
			}),
			batchCreateRecordAssociations: builder.mutation<void, BatchCreateRecordAssociationsArgs>({
				query: ({ workspaceId, associations }) => ({
					url: withWorkspaceContext(`/associations`, workspaceId),
					method: "PUT",
					body: associations,
				}),
				onQueryStarted: async ({ associations }, { queryFulfilled }) => {
					const recordIds = getUniqueRecordIdsFromBatchAssociations(associations);
					queryFulfilled.then(() => {
						dispatchAssociationChangeEvent(recordIds);
					});
				},
				invalidatesTags: (result, error, { associations }) => {
					const recordIds = getUniqueRecordIdsFromBatchAssociations(associations);
					return ["AssociationsSummary", ...recordIds.map((id) => ({ type: "Record", id }) satisfies ApiTag)];
				},
			}),
		};
	},
});

function getUniqueRecordIdsFromBatchAssociations(associations: SDAssociationCreateRequest[]) {
	const recordIds = new Set<number>(associations.flatMap(({ record1Id, record2Id }) => [record1Id, record2Id]));
	return Array.from(recordIds);
}

function dispatchAssociationChangeEvent(recordIds: number[] | string[]) {
	const numericRecordIds = recordIds.map((id) => Number(id)).filter((id) => !isNaN(id));
	dispatchRecordChangeEvent(RecordChangeType.ASSOCIATION_CHANGE, numericRecordIds);
}

export const {
	useGetObjectAssociationsQuery,
	useDeleteRecordAssociationMutation,
	useCreateRecordAssociationMutation,
	useGetRecordsAssociationSummaryQuery,
	useLazyGetRecordsAssociationSummaryQuery,
	useBatchCreateRecordAssociationsMutation,
} = recordAssociationsApi;
