import { useCreateImportJobMutation } from "../api/importApi";
import { useImportContext } from "./useImportContext";
import { isSdErrorResponse } from "../../../utils/validator";
import { ImportJob } from "@salesdesk/salesdesk-schemas";
import { getErrorMessage } from "../utils";

export enum JobType {
	Preview = "preview",
	Import = "import",
}

interface createImportJobParams {
	jobType: JobType;
	onCompleted: (importJob: ImportJob | undefined, errorMessage?: string) => void;
}

export function useCreateImportJob({ jobType, onCompleted }: createImportJobParams) {
	const { sdImport, mappingEntries } = useImportContext();
	const [postImportJob, { isLoading }] = useCreateImportJobMutation();

	const createImportJob = async () => {
		if (!sdImport) return;
		try {
			const importJob = await postImportJob({
				importId: sdImport.id,
				dryRun: jobType === JobType.Preview,
				mappingEntries,
			}).unwrap();
			onCompleted(importJob);
		} catch (error) {
			const sdError = isSdErrorResponse(error) ? error.data : undefined;
			onCompleted(undefined, getErrorMessage(sdError));
		}
	};

	return {
		createImportJob,
		isLoading,
	};
}
