import { BulkChangeOwnerModal } from "./BulkChangeOwnerModal";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { PropsWithChildren, useCallback, useState } from "react";
import { useGetObjectById } from "../../../../../hooks";
import { BulkChangeOwnerContext } from "../hooks/useBulkChangeOwnerContext";

type UpdateRecordsCallback = (updatedRecords: SDRecord[]) => void;

export function BulkChangeOwnerProvider({ children }: PropsWithChildren) {
	const [records, setRecords] = useState<SDRecord[]>();

	const [onUpdateRecordsCallback, setOnUpdateRecordsCallback] = useState<
		((updatedRecords: SDRecord[]) => void) | undefined
	>();
	const { sdObject } = useGetObjectById(records?.[0]?._objectDefId);

	const setBulkChangeOwnerRecords = useCallback(
		(recordsToUpdate?: SDRecord[], onUpdateRecordsCallback?: UpdateRecordsCallback) => {
			setRecords(recordsToUpdate);
			setOnUpdateRecordsCallback(() => onUpdateRecordsCallback);
		},
		[]
	);

	const onModalClose = useCallback(() => {
		setBulkChangeOwnerRecords();
	}, [setBulkChangeOwnerRecords]);

	return (
		<BulkChangeOwnerContext.Provider value={{ setBulkChangeOwnerRecords }}>
			{children}
			{records?.length ? (
				<BulkChangeOwnerModal
					sdObject={sdObject}
					records={records}
					onUpdateRecords={onUpdateRecordsCallback}
					onClose={onModalClose}
				/>
			) : null}
		</BulkChangeOwnerContext.Provider>
	);
}
