import { useNotificationRuleOptions } from "../../../hooks/useNotificationRuleOptions";
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { NotifyMe } from "./NotifyMe";
import { Users } from "./Users";
import { RecordName } from "./RecordName";
import { FieldName } from "./FieldName";
import { ActionName } from "./ActionName";
import { NotificationRuleDescriptionProps } from "../../../types";

export function RecordNotificationRuleDescription({ rule }: NotificationRuleDescriptionProps) {
	const { actionOption, fieldOption, userOptions, recordOption, isLoading } = useNotificationRuleOptions({ rule });

	if (isLoading) {
		return (
			<div className="flex h-full flex-col gap-2">
				<Skeleton className="h-6 w-11/12" />
				<Skeleton className="h-6 w-2/3" />
			</div>
		);
	}

	return (
		<span className="text-c_text_secondary">
			{"When "}
			{rule.eventType === SdEventType.RECORD_UPDATED ? (
				<>
					<FieldName fieldOption={fieldOption} />
					{" is updated"}
				</>
			) : (
				<ActionName actionOption={actionOption} />
			)}
			{userOptions ? " by " : null}
			<Users userOptions={userOptions} />
			<RecordName recordOption={recordOption} />
			<NotifyMe rule={rule} />
		</span>
	);
}
