import { ImportPreviewRowData, ImportPreviewRow } from "../types";
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";
import { ROW_IS_LOADING_PLACEHOLDER } from "../../../../../Table";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../../fields";
import { Field } from "@salesdesk/salesdesk-schemas";

interface ImportPreviewRowCellProps {
	field: Field;
	value: ImportPreviewRowData;
	row: ImportPreviewRow;
}

export function ImportPreviewRowCell({ field, value, row }: ImportPreviewRowCellProps) {
	if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof value === "boolean") {
		return <Skeleton className="my-2 h-6 w-full" />;
	}

	if (!value || typeof value === "string" || !("_value" in value)) {
		return "-";
	}

	return <DisplayFieldFactory field={field} value={value._value} variant={DisplayFieldVariant.table} />;
}
