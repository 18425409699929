import { asConst } from "json-schema-to-ts";

export const chatMessageCreateRequestSchema = asConst({
	title: "Chat Message Creation Request",
	description: "Defines the body of a request to create a chat message",
	type: "object",
	additionalProperties: false,
	required: ["message"],
	properties: {
		message: {
			title: "Chat Message",
			description: "Content of message",
			type: "string",
		},
	},
});
