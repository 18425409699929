import { Auth } from "../utils/auth";
import { WebPrincipal } from "../types";
import { useMockPrincipalContext } from "./useMockPrincipalContext";

export function useWebPrincipal(): WebPrincipal {
	const { principal: mockPrincipal } = useMockPrincipalContext();

	// TODO: Replace this redux state
	return mockPrincipal ?? Auth.getPrincipal();
}
