import {
	RecordQueryClauses,
	SDRecord,
	rsr,
	PutRecordAssociationPath,
	SDAssociationCreateRequest,
} from "@salesdesk/salesdesk-schemas";
import { AssociationSide, ObjectAssociationEndpointType, mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { DirectedSDObjectAssociation } from "../hooks";
import { SortingDetails } from "../../records";

export const MAX_BATCH_ASSOCIATION_SIZE = 30;

interface AssociationSearchParamsProps {
	sourceRecordId?: SDRecord["_id"];
	sortingDetails?: SortingDetails[];
	searchQuery?: string;
	orQueries?: RecordQueryClauses[];
	workspaceId?: number;
}

export function getAssociationSearchParams(
	{ sourceRecordId, sortingDetails, searchQuery, orQueries, workspaceId }: AssociationSearchParamsProps,
	objectAssociations: DirectedSDObjectAssociation | DirectedSDObjectAssociation[]
) {
	const andQuery: RecordQueryClauses[] = [rsr.equals("_deleted", false)];
	const notQuery: RecordQueryClauses[] = [rsr.equals("isTemplate", true)];

	const objectAssociationsArray = Array.isArray(objectAssociations) ? objectAssociations : [objectAssociations];

	if (searchQuery != null && searchQuery.length > 0) {
		andQuery.push(rsr.matchAllPrefix(searchQuery));
	}

	const orQuery: RecordQueryClauses[] = orQueries ? orQueries : [];

	if (sourceRecordId != null) {
		objectAssociationsArray.forEach((objectAssociation) => {
			andQuery.push(
				// AssociationSide is opposite since it's from the point of view of the target NOT the source record
				rsr.hasAssociationToRecordForObjectAssociation(
					objectAssociation.id,
					sourceRecordId,
					objectAssociation.originObject === ObjectAssociationEndpointType.OBJECT_ONE
						? AssociationSide.RECORD_2
						: AssociationSide.RECORD_1
				)
			);
		});
	} else {
		// If no source record then we just want all the records that could match any of the association definitions (i.e. all records
		// of certain object types). Used in the video call asset panel to show 'all' assets.
		const uniqueObjectIds = Array.from(
			new Set(objectAssociationsArray.map(({ connectedObject }) => connectedObject.id))
		);

		uniqueObjectIds.forEach((associationObjectId) => {
			andQuery.push(
				rsr
					.subQuery()
					.or(rsr.equals("_objectDefId", associationObjectId))
					.or(rsr.baseObjectId(associationObjectId))
					.buildSubQuery()
			);
		});
	}

	// Only filter records that are shared to the workspace if we're not searching for workspace associations
	// as internal users can view the workspaces a record is associated to within the external workspace
	if (
		workspaceId != null &&
		(!objectAssociationsArray.length || objectAssociationsArray[0].connectedObject.id !== mWorkspaceDef.ID)
	) {
		andQuery.push(rsr.isSharedWithWorkspace(workspaceId));
	}

	return {
		sort: sortingDetails ? sortingDetails.map((value) => ({ [value.fieldId]: { order: value.order } })) : undefined,
		query: {
			and: andQuery,
			or: orQuery,
			not: notQuery,
		},
	};
}

export function getRecordAssociationRequestData(
	sourceSDRecord: SDRecord,
	targetRecordId: number,
	objectAssociation: DirectedSDObjectAssociation
): SDAssociationCreateRequest {
	const isSourceRecordObject1 = objectAssociation.originObject === ObjectAssociationEndpointType.OBJECT_ONE;
	return {
		objectAssociationId: objectAssociation.id,
		record1Id: isSourceRecordObject1 ? sourceSDRecord._id : targetRecordId,
		record2Id: isSourceRecordObject1 ? targetRecordId : sourceSDRecord._id,
	};
}

export function getRecordAssociationPathData(
	sourceSDRecord: SDRecord,
	targetRecordId: number,
	objectAssociation: DirectedSDObjectAssociation
): PutRecordAssociationPath {
	const requestData = getRecordAssociationRequestData(sourceSDRecord, targetRecordId, objectAssociation);
	return {
		objectAssociationId: String(requestData.objectAssociationId),
		record1Id: String(requestData.record1Id),
		record2Id: String(requestData.record2Id),
	};
}

export function reverseObjectAssociationDirection(
	association: DirectedSDObjectAssociation
): DirectedSDObjectAssociation {
	return {
		...association,
		sourceObject: association.connectedObject,
		connectedObject: association.sourceObject,
		originObject:
			association.originObject === ObjectAssociationEndpointType.OBJECT_ONE
				? ObjectAssociationEndpointType.OBJECT_TWO
				: ObjectAssociationEndpointType.OBJECT_ONE,
	};
}
