import { PropsWithChildren } from "react";
import clsx from "clsx";
import { tw } from "../../utils/tailwind-helpers";
import { JSONContent } from "@tiptap/core";
import { DisplayRichTextField } from "../../features/fields";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

type SpeechBubbleVariant = "light" | "dark";

const variantStyles: { [key in SpeechBubbleVariant]: string } = {
	light: tw`bg-c_bg_03 text-c_text_primary`,
	dark: tw`bg-c_action_02 text-c_text_inverted`,
};

const hoverStyles: { [key in SpeechBubbleVariant]: string } = {
	light: tw`hover:bg-c_bg_02 group-hover/message:bg-c_bg_02`,
	dark: tw`hover:bg-c_action_01 group-hover/message:bg-c_action_01`,
};

type SpeechBubbleProps = {
	variant?: SpeechBubbleVariant;
	jsonContent?: JSONContent;
	containsAttachments?: boolean;
	supportHover?: boolean;
	isRedacted?: boolean;
};

export function SpeechBubble({
	variant = "light",
	jsonContent,
	containsAttachments,
	supportHover = false,
	isRedacted = false,
	children,
}: PropsWithChildren<SpeechBubbleProps>) {
	const styleClasses = variantStyles[variant];
	const hoverClasses = supportHover ? hoverStyles[variant] : "";

	return (
		<div
			className={clsx(
				styleClasses,
				hoverClasses,
				containsAttachments ? "w-full rounded-md" : "w-fit rounded-lg",
				"text-body-sm flex max-w-full flex-col gap-1 px-1 py-1"
			)}
		>
			{isRedacted ? (
				<div
					className={clsx(
						variant === "light" ? "text-c_text_placeholder" : "text-c_text_inverted",
						"flex select-none items-center gap-1 px-2 italic"
					)}
				>
					<Icon icon={ICONS.info} size="sm" />
					This message was deleted.
				</div>
			) : (
				<>
					{jsonContent ? (
						<div className="px-2">
							<DisplayRichTextField
								variant="default"
								value={jsonContent}
								collapsible={false}
								enableMentions={{ theme: variant }}
								isDarkVariant={variant === "dark"}
							/>
						</div>
					) : null}
					{children}
				</>
			)}
		</div>
	);
}
