import { useEffect, useMemo, useState } from "react";
import { Area, AreaChart, YAxis } from "recharts";

import { Tooltip, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useMediaAnalysisContext } from "../../../../hooks/useMediaAnalisysContext";
import { faceSentimentColors, FaceSentimentDataPoint, FaceSentimentType, faceSentimentTypes } from "../../../../types";
import { UserSelect } from "../../../common/UserSelect";
import { FaceSentimentSelect } from "./FaceSentimentSelect";
import { FaceSentimentTooltip } from "./FaceSentimentTooltip";
import { useGraphPanning } from "../../../../hooks/useGraphPanning";
import { getKeyByUserIdOrPlaceholderName } from "../../../../utils";
import { getTopFaceSentiments, getUserIdsFromFaceSentiments, reduceDataPoints } from "../utils";
import { useChartWidth } from "../hooks/useChartWidth";
import { Accordion } from "../../../../../../components/Accordion";

const CHART_HEIGHT = 130;

export function FaceSentimentGraph() {
	const {
		userKeyToUserId,
		faceSentimentsPerUser,
		faceSentimentGraphHolderRef,
		faceSentimentGraphRef,
		zoomFactor,
		isPanning,
		setFaceSentimentGraphOpen,
	} = useMediaAnalysisContext();

	const userIds = useMemo(
		() => getUserIdsFromFaceSentiments(faceSentimentsPerUser, userKeyToUserId),
		[faceSentimentsPerUser, userKeyToUserId]
	);
	const [selectedUserId, setSelectedUserId] = useState<number | undefined>(userIds?.length ? userIds[0] : undefined);

	const data = useMemo(() => {
		if (!faceSentimentsPerUser) {
			return [];
		}
		const key = getKeyByUserIdOrPlaceholderName(userKeyToUserId, selectedUserId);
		if (!key) {
			return [];
		}
		const userFaceSentiment = faceSentimentsPerUser[key];
		if (!userFaceSentiment) {
			return [];
		}

		const fullData = userFaceSentiment.map((sentiment) => {
			const dataPoint: FaceSentimentDataPoint = {
				timestamp: sentiment.Timestamp,
			};
			sentiment.Face.Emotions.forEach((emotion) => {
				dataPoint[emotion.Type] = emotion.Confidence;
			});
			return dataPoint;
		});
		return reduceDataPoints(fullData, zoomFactor);
	}, [faceSentimentsPerUser, userKeyToUserId, selectedUserId, zoomFactor]);

	const [selectedFaceSentimentTypes, setSelectedFaceSentimentTypes] = useState<FaceSentimentType[]>(() =>
		getTopFaceSentiments(data, 3)
	);
	const [activeSentiment, setActiveSentiment] = useState<FaceSentimentType | null>(null);

	useGraphPanning(setActiveSentiment);
	const chartWidth = useChartWidth();

	useEffect(() => {
		setSelectedFaceSentimentTypes(getTopFaceSentiments(data, 3));
	}, [data]);

	if (!selectedFaceSentimentTypes) {
		return null;
	}

	const hide = !faceSentimentsPerUser || Object.entries(faceSentimentsPerUser).length === 0;
	if (hide) {
		// We need these refs as they are needed to display the VideoCursor
		return (
			<div ref={faceSentimentGraphHolderRef}>
				<div ref={faceSentimentGraphRef} />
			</div>
		);
	}

	const filteredSentimentTypes =
		selectedFaceSentimentTypes.length === 0 ? faceSentimentTypes : selectedFaceSentimentTypes;

	return (
		<div className="relative">
			<Accordion
				title="Sentiment"
				defaultOpen
				unmount={false}
				onOpenChange={setFaceSentimentGraphOpen}
				leftElement={
					<Tooltip text="Sentiment analysis indicates the confidence level of the face recognition. E.g. 50% confident that the speaker's facial expression is happy - at a specific time during the video.">
						<div>
							<Icon icon={ICONS.info} size="sm" className="text-c_icon_regular flex items-center" />
						</div>
					</Tooltip>
				}
				variant="secondary"
			>
				<div className="absolute right-0 top-0 flex items-center gap-2">
					<FaceSentimentSelect
						value={selectedFaceSentimentTypes}
						onChange={(value) => setSelectedFaceSentimentTypes(value as FaceSentimentType[])}
					/>
					{selectedUserId && userIds ? (
						<UserSelect userIds={userIds} value={selectedUserId} onChange={setSelectedUserId} />
					) : null}
				</div>
				<div ref={faceSentimentGraphHolderRef} className="mb-2 mt-4 overflow-hidden">
					<div ref={faceSentimentGraphRef} className="relative">
						{filteredSentimentTypes.length ? (
							<AreaChart
								width={chartWidth}
								height={CHART_HEIGHT}
								data={data}
								margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
								style={{
									cursor: zoomFactor === 1 ? "pointer" : isPanning ? "grabbing" : "grab",
								}}
							>
								<defs>
									{filteredSentimentTypes.map((sentimentType) => (
										<linearGradient key={sentimentType} id={`color${sentimentType}`} x1="0" y1="0" x2="0" y2="1">
											<stop offset="5%" stopColor={`${faceSentimentColors[sentimentType]}40`} stopOpacity={1} />
											<stop offset="95%" stopColor={`${faceSentimentColors[sentimentType]}00`} stopOpacity={0} />
										</linearGradient>
									))}
								</defs>
								{filteredSentimentTypes.map((sentimentType) => (
									<Area
										key={sentimentType}
										type="monotone"
										dataKey={sentimentType}
										stroke={faceSentimentColors[sentimentType]}
										strokeWidth={1.5}
										fill={`url(#color${sentimentType})`}
										animationDuration={200}
										dot={false}
										onMouseMove={() => {
											if (!isPanning) {
												setActiveSentiment(sentimentType);
											}
										}}
										onMouseLeave={() => setActiveSentiment(null)}
									/>
								))}
								<YAxis domain={[0, 100]} hide />
							</AreaChart>
						) : (
							<div
								className="text-c_text_placeholder flex w-full items-center justify-center"
								style={{ height: CHART_HEIGHT }}
							>
								No sentiments selected.
							</div>
						)}
					</div>
					{faceSentimentGraphRef.current ? (
						<FaceSentimentTooltip activeSentiment={activeSentiment} data={data} />
					) : null}
				</div>
			</Accordion>
		</div>
	);
}
