import { ReactNode } from "react";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";
import {
	Field,
	FieldValue,
	RECORD_SIDE_PANEL_RECORD_ID_PARAM,
	RecordQueryClauses,
	SDObject,
	SDRecord,
} from "@salesdesk/salesdesk-schemas";

import { FilterData } from "../../records/components/Filtering/types";
import { CALENDAR_VIEW } from "../components/ObjectBoardView/CalendarView";
import { SortingDetails } from "../../records";
import { GroupableFields } from "../../objects/types";
import { ROW_LINK_COLUMN_ID } from "../../Table";

export type AggregationKey = "sum" | "min" | "max" | "avg";

interface AggregationInfo {
	key: AggregationKey;
	name: string;
	shortenedName?: string;
}

export const AGGREGATIONS: AggregationInfo[] = [
	{ key: "sum", name: "Sum" },
	{ key: "min", name: "Min" },
	{ key: "max", name: "Max" },
	{ key: "avg", name: "Average", shortenedName: "Avg." },
];

export interface AggregationDetails {
	fieldId: BoardFieldId;
	agg: AggregationKey;
}
export interface AggregationResult {
	field: Field;
	aggregationName: string;
	value: number;
}

export const MAIN_BOARD_AGGREGATION = "ObjectBoardAggregation";

export interface ObjectBoardDetails {
	sdObject?: SDObject;
	boardFieldMap: BoardFieldMap;
	nameFieldIds: BoardFieldId[];
	mediaFieldIds: BoardFieldId[];
	groupableFields: GroupableFields;
	aggregationFields: Field[];
}

export interface BoardState {
	view?: BOARD_VIEW;
	query?: string;
	displayedFields?: string[];
	groupBy?: string;
	filter?: FilterData;
	sort?: SortingDetails[];
	agg?: AggregationDetails;
	[RECORD_SIDE_PANEL_RECORD_ID_PARAM]?: string;

	// Card based view specific
	mediaField?: string;

	// Calendar specific
	date?: number;
	calendarView?: CALENDAR_VIEW;
}

export type BoardPropOnChange = (
	propName: keyof BoardState,
	value: BoardState[keyof BoardState],
	isPushIn?: boolean
) => void;

export const FULL_RECORD_DATA_FIELD_ID = "FULL_RECORD_DATA_FIELD_MAP_ID";
export const RECORD_COMBINED_NAME_ID = "RECORD_COMBINED_NAME_ID";
export const NO_FIELD_ID = "NONE";

// Field which is a combination of the asset record name with the main asset file
export const ASSET_RECORD_COMBINED_NAME_FILE_ID = "asset_name";
export const ASSET_RECORD_COMBINED_NAME_FILE_NAME = "Name";

export type BoardFieldId = string;

export type BoardFieldMap = Map<BoardFieldId, Field>;

export type GetRecordLinkFn = (recordId: number) => string | null;

export type BoardRecordDetails = {
	[FULL_RECORD_DATA_FIELD_ID]?: SDRecord;
	[RECORD_COMBINED_NAME_ID]?: FieldValue;
	[ASSET_RECORD_COMBINED_NAME_FILE_ID]?: FieldValue;
	[ROW_LINK_COLUMN_ID]?: (() => string | null) | string;
} & {
	[id: BoardFieldId]: FieldValue;
};

export interface BoardViewProps {
	boardRecords: BoardRecordDetails[];
	footer?: ReactNode;
	onBottomReached?: () => void;
	isLoading?: boolean;
}

export type BoardRecordDelete = (boardRecord: BoardRecordDetails, index: number) => void;

export interface RecordGroupDetails {
	id: number;
	name: string;
	filterParams: RecordQueryClauses[];
	color?: string;
	icon?: string;
	hitCount?: number;
	isLoading?: boolean;
	aggregationResult?: AggregationResult;
}

export const DEFAULT_BOARD_STATE: BoardState = {
	view: undefined,
	query: "",
	displayedFields: undefined,
	groupBy: undefined,
	filter: undefined,
	sort: undefined,
	agg: undefined,

	mediaField: undefined,

	date: undefined,
	calendarView: undefined,
	recordId: undefined, // serves for open/close right hand panel
};
