import { forwardRef } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { InputValidationState } from "@salesdesk/daisy-ui";

import { RatingInput, RatingInputProps } from "../../../../../inputs";
import { EditFieldProps } from "../../../../types";
import { Icon, InputContainer } from "@salesdesk/daisy-ui";

type EditRatingFieldProps = RatingInputProps & Omit<EditFieldProps, "value" | "onChange">;

export const EditRatingField = forwardRef<HTMLDivElement, EditRatingFieldProps>(
	({ inputRef, value = 0, disabled, hasError, ...ratingInputProps }, ref) => (
		<InputContainer
			transparentBorder={!hasError}
			validationState={hasError ? InputValidationState.error : InputValidationState.initial}
		>
			<div className={clsx(!disabled && "hover:bg-c_bg_04", "flex justify-between py-2 pl-4 pr-2")}>
				<RatingInput ref={ref} disabled={disabled} {...ratingInputProps} value={value} />
				{hasError ? <Icon className="text-c_danger_02 flex items-center" icon={ICONS.warningCircle} /> : null}
			</div>
		</InputContainer>
	)
);
