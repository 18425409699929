import { useEffect, useState } from "react";
import userflow from "userflow.js";

import { formatTimePassed } from "@salesdesk/salesdesk-utils";

import { HoldingPageLogo } from "../../HoldingPage/components/HoldingPageLogo";
import { Button } from "@salesdesk/daisy-ui";
import { useMeetingPageContext } from "../../../hooks";
import { WorkspaceHeader, useWorkspaceContext } from "../../../../../../workspaces";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { useNavigateBack } from "../../../../../../../routes";
import { WorkspaceMeetingLogos } from "../../../../common/WorkspaceMeetingLogos";
import clsx from "clsx";
import { useIsGuest } from "../../../hooks/useIsGuest";

export function EndCallPage() {
	const { workspaceId, workspaceRecord, workspaceObject } = useWorkspaceContext();
	const inWorkspace = workspaceId != null;

	const navigateBack = useNavigateBack();
	const { rejoinMeeting, joinMeetingTimeStamp = 0 } = useMeetingPageContext();
	const [totalMeetingTime] = useState(() => formatTimePassed(joinMeetingTimeStamp, Date.now()));

	const isGuest = useIsGuest();

	useEffect(() => {
		userflow.track("end_meeting");
	}, []);

	return (
		<div className="flex w-full flex-col">
			{inWorkspace ? (
				<>
					{!isGuest ? <WorkspaceHeader isBackButtonExit={false} /> : null}
					<div
						className={clsx(
							"text-label text-c_text_placeholder mx-auto flex h-[65px] w-10/12 items-center justify-between",
							isGuest && "pt-6"
						)}
					>
						<WorkspaceMeetingLogos />
						<div className="ml-8 line-clamp-2 max-h-full max-w-[50%] text-right">
							{workspaceObject && workspaceRecord ? (
								getSDRecordName(workspaceObject, workspaceRecord)
							) : (
								<Skeleton className="h-6 w-40" />
							)}
						</div>
					</div>
				</>
			) : (
				<div className="flex w-full justify-between">
					<div className="ml-8 mt-2 flex h-12 shrink-0 items-center">
						<HoldingPageLogo />
					</div>
					<div className="mr-8 mt-4">
						<Button variant="secondary" onClick={navigateBack}>
							Close
						</Button>
					</div>
				</div>
			)}
			<div className="flex flex-col items-center gap-6">
				<h3 className="text-h3 mt-8">You’ve left the meeting</h3>
				<div className="flex justify-around gap-6">
					<Button variant="secondary" onClick={rejoinMeeting}>
						Rejoin
					</Button>
				</div>
				{totalMeetingTime.length ? (
					<p className="text-c_text_secondary text-body-sm text-center">Meeting time: {totalMeetingTime}</p>
				) : null}
				<div id="userflow-video-call-feedback" className="w-2" />
			</div>
		</div>
	);
}
