import { asConst } from "json-schema-to-ts";
import { RoleClaimType } from "./RoleClaimType";
import { PrincipalClaimType } from "./PrincipalClaimType";

export const claimTypeSchema = asConst({
	title: "Type",
	description: "The type of Claim that tells you what the value of the Claim means",
	type: "string",
	enum: Object.values(PrincipalClaimType).concat(Object.values(RoleClaimType)),
	nullable: false,
});
