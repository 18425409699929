import { PillSelectOption } from "../types";
import { Pill } from "../../Pill/Pill";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface PillSelectProps {
	value: string[];
	onChange: (values: string[]) => void;
	options: PillSelectOption[];
}

export function PillSelect({ value, onChange, options }: PillSelectProps) {
	return (
		<div className="flex gap-2">
			{options.map((option) => {
				const checked = value.includes(option.value);
				return (
					<Pill
						key={option.value}
						variant={option.variant ?? "primary"}
						color={option.color}
						onClick={() => {
							if (checked) {
								onChange(value.filter((v) => v !== option.value));
							} else {
								onChange([...value, option.value]);
							}
						}}
						icon={checked ? ICONS.check : undefined}
					>
						{option.label}
					</Pill>
				);
			})}
		</div>
	);
}
