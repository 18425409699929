import { useMemo } from "react";
import { ProfilePhotoFieldValue, ProfilePhotoInfo } from "../types";

export function useProfilePhotoInfo(value?: ProfilePhotoFieldValue): ProfilePhotoInfo {
	return useMemo(() => {
		if (value && typeof value !== "number" && "fullName" in value) {
			return value;
		}

		return {
			fileId: value ?? null,
			fullName: "",
		};
	}, [value]);
}
