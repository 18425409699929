import { RefObject, SetStateAction, useEffect, memo } from "react";
import { Droppable } from "@hello-pangea/dnd";
import clsx from "clsx";

import { Spinner } from "@salesdesk/daisy-ui";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { roundToTwoDecimalPlaces } from "@salesdesk/salesdesk-utils";

import { APP_CONFIG } from "../../../../../../../../app/app_config";
import { useInfiniteScrollContainer } from "../../../../../../../../hooks/ui";
import { SkeletonRecordCard, useInfiniteRecordSearch } from "../../../../../../../records";
import { RecordAssociationsSummaryMap } from "../../../../../../../recordAssociations";

import { BoardFieldId, FULL_RECORD_DATA_FIELD_ID, MAIN_BOARD_AGGREGATION, NO_FIELD_ID } from "../../../../../../types";
import { GroupData, onRecordGroupDetailsChangeFn } from "../../../types";
import { useObjectBoardSearchParams } from "../../../../../../hooks/useObjectBoardSearchParams";
import { useBoardMediaFieldSelector } from "../../../../../../store";
import { useDataboardDetailsContext } from "../../../../../../hooks/useDataboardDetailsContext";
import { useMemoizedBoardRecords } from "../../../../../../hooks/useMemoizedBoardRecords";
import { KanbanCard } from "./KanbanCard";

interface KanbanColumnProps {
	fieldsToDisplay: BoardFieldId[];
	viewContainerRef: RefObject<HTMLElement>;
	columnData: GroupData;
	onColumnDataChange: (groupId: number, updatedData: SetStateAction<SDRecord[]>) => void;
	onRecordGroupDetailsChange: onRecordGroupDetailsChangeFn;
	recordAssociationsSummaryMap: RecordAssociationsSummaryMap;
	columnWidth: number;
}

const INFINITE_SCROLL_BOTTOM_OFFSET = 1200;

function KanbanColumnComponent({
	columnData,
	onColumnDataChange,
	fieldsToDisplay,
	viewContainerRef,
	onRecordGroupDetailsChange,
	recordAssociationsSummaryMap,
	columnWidth,
}: KanbanColumnProps) {
	const { sdObject } = useDataboardDetailsContext();

	const mediaField = useBoardMediaFieldSelector();
	const mediaFieldId = (mediaField ?? NO_FIELD_ID) === NO_FIELD_ID ? undefined : mediaField;

	const recordGroup = columnData.groupDetails;

	const searchParams = useObjectBoardSearchParams(columnData.groupDetails.filterParams);

	const { updateSearchParams, loadNextPage, isLastPage, isLoadingNewSearchParams, isLoadingNextPage, resultHits } =
		useInfiniteRecordSearch({
			limit: APP_CONFIG.maxLocalSearchResults,
			sdRecords: columnData.data,
			onSDRecordsChange: (updatedData) => onColumnDataChange(recordGroup.id, updatedData),
			sdObjectFilter: sdObject?._id,
		});

	const { hitCount, aggregations } = resultHits || { hitCount: 0 };

	useEffect(() => {
		if (searchParams) {
			updateSearchParams(searchParams);
		}
	}, [searchParams, updateSearchParams]);

	useEffect(() => {
		const objectBoardAggregation = aggregations?.find((aggregation) => aggregation.name === MAIN_BOARD_AGGREGATION);
		const aggregationResultValue = roundToTwoDecimalPlaces(objectBoardAggregation?.value || 0);

		onRecordGroupDetailsChange(columnData.groupDetails.id, {
			hitCount,
			aggregationResultValue,
			isLoading: isLoadingNewSearchParams,
		});
	}, [hitCount, onRecordGroupDetailsChange, aggregations, columnData.groupDetails.id, isLoadingNewSearchParams]);

	const boardRecords = useMemoizedBoardRecords(columnData.data, sdObject);

	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: viewContainerRef,
		verticalOffset: INFINITE_SCROLL_BOTTOM_OFFSET,
		onBottomReached: () => {
			if (isLoadingNewSearchParams || isLoadingNextPage || isLastPage) {
				return;
			}

			loadNextPage();
		},
	});

	if (!sdObject) {
		return;
	}

	return (
		<div className="flex snap-start flex-col" style={{ width: `${columnWidth}px` }}>
			<Droppable key={recordGroup.id} droppableId={`${recordGroup.id}`}>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						className={clsx(
							"rounded-card mb-6 h-full w-full px-3 pb-2 pt-0 transition-colors",
							snapshot.isDraggingOver && "bg-c_brand_off_white"
						)}
					>
						<div className="relative -mb-6 flex flex-col gap-3">
							{isLoadingNewSearchParams
								? [...Array(2)].map((_, index) => (
										<SkeletonRecordCard key={`skeleton-card-${index}`} className="mb-3" />
									))
								: boardRecords.map((recordDetails, index) => {
										const sdRecord = recordDetails[FULL_RECORD_DATA_FIELD_ID];

										if (!sdRecord) {
											return null;
										}

										return (
											<KanbanCard
												key={sdRecord._id}
												index={index}
												recordDetails={recordDetails}
												fieldsToDisplay={fieldsToDisplay}
												mediaFieldId={mediaFieldId}
												associationsSummary={recordAssociationsSummaryMap[sdRecord._id]}
											/>
										);
									})}
							{provided.placeholder}
							{isLoadingNextPage ? (
								<div className="flex justify-center">
									<Spinner size="md" />
								</div>
							) : null}
							<div key={boardRecords.length} ref={containerBottomRef} />
						</div>
					</div>
				)}
			</Droppable>
		</div>
	);
}

export const KanbanColumn = memo(KanbanColumnComponent);
