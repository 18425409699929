import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	DataTrack,
	RemoteParticipant,
	RemoteTrack,
	RemoteTrackPublication,
	Room,
	Track,
	TrackPublication,
} from "twilio-video";

import { CallParticipantDetails, FILE_TRACK_NAME, FilePresentationDetails } from "../../../types";

/*
  Returns the participant that is sharing a file (if any).
*/
export function useFileSharingParticipant(
	room: Room | null,
	callParticipantDetails: CallParticipantDetails[],
	localSharedFileDetails: FilePresentationDetails | null
) {
	const remoteFileDetailsSharingTrackRef = useRef<DataTrack | null>(null);
	const remoteParticipantRef = useRef<RemoteParticipant | null>(null);

	const [remoteFileDetailsJSONString, setRemoteFileDetailsJSONString] = useState("");
	const [remoteFileDetails, setRemoteFileDetails] = useState<FilePresentationDetails | null>(null);

	const clearCurrentRemoteFileShareParticipant = useCallback((participant: RemoteParticipant) => {
		if (participant !== remoteParticipantRef.current) {
			return;
		}

		if (remoteFileDetailsSharingTrackRef.current) {
			remoteFileDetailsSharingTrackRef.current.off("message", setRemoteFileDetailsJSONString);
		}

		remoteParticipantRef.current = null;
		remoteFileDetailsSharingTrackRef.current = null;
		setRemoteFileDetailsJSONString("");
	}, []);

	const updateRemoteFileShareParticipant = useCallback((participant: RemoteParticipant, track: Track) => {
		if (!isFileShareTrack(track)) {
			return;
		}

		if (remoteFileDetailsSharingTrackRef.current) {
			remoteFileDetailsSharingTrackRef.current.off("message", setRemoteFileDetailsJSONString);
		}

		remoteFileDetailsSharingTrackRef.current = track as DataTrack;
		remoteParticipantRef.current = participant;
		track.on("message", setRemoteFileDetailsJSONString);
	}, []);

	useEffect(() => {
		let parsedRemoteFileDetails = null;

		try {
			parsedRemoteFileDetails = remoteFileDetailsJSONString ? JSON.parse(remoteFileDetailsJSONString) : null;
		} catch (error) {
			console.error("Failed to parse remote file");
		}

		setRemoteFileDetails(parsedRemoteFileDetails);
	}, [remoteFileDetailsJSONString]);

	useEffect(() => {
		if (!room) {
			setRemoteFileDetailsJSONString("");
			return;
		}

		const onTrackSubscribed = (
			track: RemoteTrack,
			publication: RemoteTrackPublication,
			participant: RemoteParticipant
		) => updateRemoteFileShareParticipant(participant, track);

		const onTrackUnsubscribed = (track: Track, publication: TrackPublication, participant: RemoteParticipant) => {
			if (!isFileShareTrack(track)) {
				return;
			}

			clearCurrentRemoteFileShareParticipant(participant);
		};

		room.on("trackSubscribed", onTrackSubscribed);
		room.on("trackUnsubscribed", onTrackUnsubscribed);
		room.on("participantDisconnected", clearCurrentRemoteFileShareParticipant);

		return () => {
			room.off("trackSubscribed", onTrackSubscribed);
			room.off("trackUnsubscribed", onTrackUnsubscribed);
			room.off("participantDisconnected", clearCurrentRemoteFileShareParticipant);
		};
	}, [room, updateRemoteFileShareParticipant, clearCurrentRemoteFileShareParticipant]);

	return useMemo(() => {
		// If the remote particpant is sharing a file
		if (remoteFileDetails && remoteParticipantRef.current) {
			const remoteParticipantSid = remoteParticipantRef.current.sid;

			const remoteParticipantDetails = callParticipantDetails.find(
				(details) => details.participant.sid === remoteParticipantSid
			);

			if (remoteParticipantDetails) {
				return {
					...remoteParticipantDetails,
					variant: "file",
					sharedFile: remoteFileDetails,
				} satisfies CallParticipantDetails;
			}
		}

		// If the local user is sharing a file
		if (localSharedFileDetails && room?.localParticipant) {
			const localParticipantDetails = callParticipantDetails.find(
				(details) => details.participant.sid === room.localParticipant.sid
			);

			if (localParticipantDetails) {
				return {
					...localParticipantDetails,
					sharedFile: localSharedFileDetails,
					variant: "file",
				} satisfies CallParticipantDetails;
			}
		}

		return null;
	}, [localSharedFileDetails, room, remoteFileDetails, callParticipantDetails]);
}

function isFileShareTrack(track: Track) {
	return track.kind === "data" && track.name.includes(FILE_TRACK_NAME);
}
