import { asConst } from "json-schema-to-ts";
import { eventCreateRequestSchema } from "./eventCreateRequestSchema";
import { idSchema } from "../components";

export const augmentedEventCreateRequestSchema = asConst({
	title: "Augmented Event",
	description: "Augmented event create request",
	type: "object",
	additionalProperties: false,
	required: [...eventCreateRequestSchema.required, "user_id", "tenant_id"],
	properties: {
		...eventCreateRequestSchema.properties,
		user_id: {
			...idSchema,
			title: "User ID",
			description: "ID of the user who caused the event",
		},
		workspace_id: {
			...idSchema,
			title: "Workspace ID",
			description: "ID of the workspace where the event occurred",
		},
		tenant_id: {
			// todo - this could be a separate schema for tenant id since it has its own regex
			title: "Event Tenant ID",
			description: "Tenant ID for the event",
			type: "string",
		},
	},
});
