import { useEffect, useState } from "react";
import { Editor } from "@tiptap/core";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { ButtonSelect, SelectOptionId } from "../../../../../../inputs";
import { TEXT_FORMAT_OPTIONS } from "../utils";

interface TextStyleSelectProps {
	editor: Editor;
}

const TEXT_STYLE_SELECT_OPTIONS = TEXT_FORMAT_OPTIONS.map((option) => ({
	id: option.id,
	icon: option.icon,
	name: option.name,
}));

export function TextStyleSelect({ editor }: TextStyleSelectProps) {
	const activeFormattingOption = TEXT_FORMAT_OPTIONS.find((option) => option.isActive(editor))?.id;

	const [currentStyleId, setCurrentStyleId] = useState<SelectOptionId>(
		activeFormattingOption ?? TEXT_STYLE_SELECT_OPTIONS[0].id
	);

	const onSelectChange = (optionId?: SelectOptionId) => {
		const styleId = optionId ?? TEXT_STYLE_SELECT_OPTIONS[0].id;
		setCurrentStyleId(styleId);

		TEXT_FORMAT_OPTIONS.find((option) => option.id === styleId)?.action(editor);
	};

	useEffect(() => {
		setCurrentStyleId(activeFormattingOption ?? TEXT_STYLE_SELECT_OPTIONS[0].id);
	}, [activeFormattingOption]);

	return (
		<ButtonSelect
			buttonVariant="text"
			options={TEXT_STYLE_SELECT_OPTIONS}
			value={currentStyleId}
			onChange={onSelectChange}
			endIcon={ICONS.caretDown}
			iconOnly
			size="xs"
			tooltip="Style"
			selectPopoverOptions={{
				placement: "bottom-start",
				tooltipProps: { noWrap: true, preventFlip: true, placement: "top" },
			}}
		/>
	);
}
