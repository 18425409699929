import { forwardRef, PropsWithChildren, RefObject, useContext, useMemo, useState } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import {
	darkenOrLightenCssColor,
	getHexOrCssVariableForColorKey,
	Icon,
	preferDarkTextOnBackgroundColor,
	resolveColorKeyToHex,
	PopoverTriggerContext,
} from "@salesdesk/daisy-ui";

import { tw } from "../../utils/tailwind-helpers";

export type PillVariant = "primary" | "outlined";

interface PillProps extends PropsWithChildren {
	variant?: PillVariant;
	icon?: string;
	color?: string;
	onClick?: () => void;
	onClose?: () => void;
	disabled?: boolean;
	active?: boolean;
	isClickable?: boolean;
	ignorePopOver?: boolean;
}

// TODO: Implement hover states for custom color pills when necessary in UI
export const Pill = forwardRef<HTMLElement, PillProps>(
	(
		{
			variant = "primary",
			icon,
			onClick,
			onClose,
			disabled,
			children,
			active,
			color,
			isClickable,
			ignorePopOver,
		}: PillProps,
		ref
	) => {
		const isOutlined = variant === "outlined";

		const currentColorToDisplay = useMemo(() => {
			return isOutlined
				? getHexOrCssVariableForColorKey("c_bg_01")
				: color ?? getHexOrCssVariableForColorKey("c_bg_02");
		}, [isOutlined, color]);

		const resolvedCurrentColour = useMemo(() => {
			return isOutlined ? resolveColorKeyToHex("c_bg_01") : color ?? resolveColorKeyToHex("c_bg_02");
		}, [isOutlined, color]);

		const activeColor = useMemo(() => darkenOrLightenCssColor(resolvedCurrentColour), [resolvedCurrentColour]);

		const [isHovered, setIsHovered] = useState(false);

		let iconColor, textColor;

		if (isOutlined || preferDarkTextOnBackgroundColor(resolvedCurrentColour)) {
			iconColor = tw`text-c_icon_regular`;
			textColor = tw`text-c_text_primary`;
		} else {
			iconColor = tw`text-c_icon_inverted`;
			textColor = tw`text-c_text_inverted`;
		}

		const { open: popoverOpen } = useContext(PopoverTriggerContext);

		const isActive = active || isHovered || (!ignorePopOver && Boolean(popoverOpen));
		let currentBackgroundColor, currentBorderColor;
		if (isOutlined) {
			if (disabled) {
				currentBackgroundColor = getHexOrCssVariableForColorKey("c_bg_disabled_01");
				currentBorderColor = getHexOrCssVariableForColorKey("c_border_regular");
			} else if (isActive) {
				const bg02Color = getHexOrCssVariableForColorKey("c_bg_02");

				currentBackgroundColor = bg02Color;
				currentBorderColor = bg02Color;
			} else {
				currentBackgroundColor = getHexOrCssVariableForColorKey("c_bg_01");
				currentBorderColor = getHexOrCssVariableForColorKey("c_border_regular");
			}
		} else {
			if (disabled) {
				currentBackgroundColor = getHexOrCssVariableForColorKey("c_bg_disabled_01");
			} else if (isActive) {
				currentBackgroundColor = activeColor;
			} else {
				currentBackgroundColor = currentColorToDisplay;
			}
		}

		const hasClickableStyle = !!onClick || isClickable || popoverOpen !== null;
		const wrapperProps = {
			className: clsx(
				isOutlined ? tw`border` : undefined,
				onClose ? tw`pl-3 pr-0.5` : tw`px-3`,
				disabled ? tw`text-c_text_placeholder select-none` : textColor,
				hasClickableStyle && tw`focus-visible:ring`,
				!onClick && isClickable && tw`cursor-pointer`,
				tw`text-label-sm flex h-7 w-fit min-w-[40px] items-center gap-2 rounded-full overflow-hidden truncate transition-colors`
			),
			style: { backgroundColor: currentBackgroundColor, borderColor: currentBorderColor },
			onMouseEnter: hasClickableStyle ? () => setIsHovered(true) : undefined,
			onMouseLeave: hasClickableStyle ? () => setIsHovered(false) : undefined,
		};

		const contents = (
			<>
				<Icon
					icon={icon}
					size="sm"
					className={clsx(disabled ? "text-c_icon_disabled" : iconColor, "flex transition-colors")}
				/>
				<span className="truncate">{children}</span>
				{onClose ? (
					<button
						disabled={disabled}
						className={clsx(
							disabled ? "text-c_icon_disabled" : iconColor,
							"flex w-fit flex-shrink-0 items-center truncate rounded-full p-1 focus-visible:ring"
						)}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							onClose();
						}}
					>
						<Icon icon={ICONS.cross} size="sm" />
					</button>
				) : null}
			</>
		);

		if (onClick) {
			return (
				<button {...wrapperProps} onClick={onClick} ref={ref as RefObject<HTMLButtonElement>}>
					{contents}
				</button>
			);
		}

		return (
			<div {...wrapperProps} ref={ref as RefObject<HTMLDivElement>}>
				{contents}
			</div>
		);
	}
);
