import {
	AbilityAction,
	AbilitySubject,
	Claim,
	ClaimSubjectType,
	filterClaimByPredicate,
	findClaimByPredicate,
	hasClaimByPredicate,
	PrincipalClaimType,
	RoleClaimType,
	RoleType,
} from "../../index";
import { AppAbility, CanParameters } from "./AppAbility";
import { defineAbilityFor } from "./defineAbilityFor";
import { ForbiddenError } from "@casl/ability";
import { ClaimTypes } from "./ClaimTypes";
import { isValidUserType, UserType } from "@salesdesk/salesdesk-model";

export class ClaimsPrincipal {
	private readonly claims: Claim[];
	private readonly isAdmin: boolean;
	private readonly ability: AppAbility;

	private username: string | null = null;
	private fullName: string | null = null;
	private userType: UserType | null = null;
	private userRecordId: number | null = null;
	private tenantId: string | null = null;
	private authorizedWorkspaceIds: number[] | null = null;

	constructor(claims: Claim[] = []) {
		this.claims = claims;
		this.isAdmin = this.HasRole(RoleType.Admin);
		this.ability = defineAbilityFor(claims);
	}

	public get Ability() {
		return this.ability;
	}

	public get Username() {
		if (this.username == null) this.username = this.FindByType(PrincipalClaimType.Username)?.value || null;
		return this.username;
	}

	public get Email() {
		return this.Username;
	}

	public get FullName() {
		if (this.fullName == null) this.fullName = this.FindByType(PrincipalClaimType.FullName)?.value || null;
		return this.fullName;
	}

	public get UserType(): UserType | null {
		if (this.userType == null) {
			const userTypeValue = this.FindByType(PrincipalClaimType.UserType)?.value || null;
			this.userType = userTypeValue !== null && isValidUserType(userTypeValue) ? userTypeValue : null;
		}

		return this.userType;
	}

	public get UserRecordId() {
		if (this.userRecordId == null) {
			const userRecordIdClaim = this.FindByType(PrincipalClaimType.UserRecordId);
			this.userRecordId = userRecordIdClaim != null ? parseInt(userRecordIdClaim.value, 10) : null;
			if (this.userRecordId == null) throw new Error("UserRecordId is null");
		}
		return this.userRecordId;
	}

	public get TenantId() {
		if (this.tenantId == null) {
			const tenantIdClaim = this.FindByType(PrincipalClaimType.Tenant);
			this.tenantId = tenantIdClaim != null ? tenantIdClaim.value : null;
			if (this.tenantId == null) throw new Error("TenantId is null");
		}
		return this.tenantId;
	}

	public get IsSystemAdmin() {
		return this.can(AbilityAction.Manage, AbilitySubject.All);
	}

	public get IsSalesDeskUser() {
		return this.UserType === UserType.SALESDESK_USER;
	}

	public get IsCustomerUser() {
		return this.UserType === UserType.SALESDESK_CUSTOMER || this.UserType == null;
	}

	public get AuthorizedWorkspaceIds() {
		if (this.authorizedWorkspaceIds == null) {
			const authorizedWorkspaceIdsClaim = this.FindByType(PrincipalClaimType.AuthorizedWorkspaceIds);
			this.authorizedWorkspaceIds =
				authorizedWorkspaceIdsClaim != null
					? authorizedWorkspaceIdsClaim.value
							.split(",")
							.filter((str) => str.length > 0)
							.map((workspaceId) => parseInt(workspaceId, 10))
					: [];
		}
		return this.authorizedWorkspaceIds;
	}

	public get IsCustomerWithSingleWorkspace() {
		return this.IsCustomerUser && this.AuthorizedWorkspaceIds.length === 1;
	}

	public HasRole = (roleType: RoleType) => this.HasClaim(RoleClaimType.Role, roleType);
	public GetClaims = (): Claim[] => this.claims.map((c) => ({ type: c.type, value: c.value }));
	public HasClaimByPredicate = (predicate: (claims: Claim) => boolean) =>
		this.isAdmin || hasClaimByPredicate(this.claims, predicate);
	/*
	 * Intended to be used for Role Admin purposes (i.e. to check if specific roles are present)
	 * @param value will default to @value {SubjectType.All} if no value is given
	 */
	public HasClaim = (type: ClaimTypes, value: string = ClaimSubjectType.All) =>
		this.HasClaimByPredicate((c) => c.type === type && (c.value === value || c.value === ClaimSubjectType.All));
	public FindByPredicate = (predicate: (claims: Claim) => boolean) => findClaimByPredicate(this.claims, predicate);
	public FindByType = (type: ClaimTypes) => this.FindByPredicate((c) => c.type === type);
	public FindAll = (predicate: (claims: Claim) => boolean) => filterClaimByPredicate(this.claims, predicate);
	public FilterByType = (type: ClaimTypes) => this.FindAll((c) => c.type === type);

	/* A check to see if the user "can" do an action on a subject (e.g. to decide whether to display a UI element or not) */
	public can = (...args: CanParameters) => this.ability.can(...args);
	// I considered changing the interface to this rather than use sdSubject().  It abstracts the Casl interface away
	// and would hide the use of sdSubject but it then lacks consistency with the canAny, canAll etc
	// AbilitySubject only:  can(AbilityAction.ChangeOwner).subject(AbilitySubject.Record).doIt()
	// With sdSubject:  can(AbilityAction.Edit).subject(AbilitySubject.Record).fields(["_createdBy"]).doIt()
	// With field names: can(AbilityAction.Edit).subject(AbilitySubject.Record, record).fields(["_createdBy"]).doIt()

	public cannot = (...args: CanParameters) => this.ability.cannot(...args);

	/* A check to see if the user "can" do any of the given subject/actions */
	public canAny = (args: CanParameters[]) => args.some((arg) => this.ability.can(...arg));

	/* A check to see if the user "can" do all of the given subject/actions */
	public canAll = (args: CanParameters[]) => args.every((arg) => this.ability.can(...arg));

	/* Same as "can" but throws an exception if the user doesn't have permission */
	public throwUnlessCan = (...args: CanParameters) => ForbiddenError.from(this.ability).throwUnlessCan(...args);

	/* Accepts an array of args and will throw the first one if all fail */
	public throwUnlessCanAny = (args: CanParameters[]) => {
		if (args.length === 0) return;
		if (args.every((arg) => !this.can(...arg))) ForbiddenError.from(this.ability).throwUnlessCan(...args[0]);
	};

	/* Accepts an array of args and will throw the first failure if any fail */
	public throwUnlessCanAll = (args: CanParameters[]) => {
		if (args.length === 0) return;
		args.filter((arg) => this.cannot(...arg)).forEach((arg) => this.throwUnlessCan(...arg));
	};
}
