import { useMediaAnalysisContext } from "../../../../hooks/useMediaAnalisysContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { throttle } from "../../../../../../utils";

export function useChartWidth() {
	const { faceSentimentGraphHolderRef, zoomFactor } = useMediaAnalysisContext();
	const [chartWidth, setChartWidth] = useState<number>(800);

	const holderElement = faceSentimentGraphHolderRef.current;

	const resizeScreen = useCallback(() => {
		const holderWidth = holderElement?.getBoundingClientRect()?.width;
		if (!holderWidth) {
			return;
		}
		setChartWidth(holderWidth * zoomFactor);
	}, [holderElement, zoomFactor]);

	useEffect(resizeScreen, [resizeScreen]);

	const throttledResizeScreen = useMemo(() => throttle(resizeScreen, 100), [resizeScreen]);

	useEffect(() => {
		window.addEventListener("resize", throttledResizeScreen);
		return () => window.removeEventListener("resize", throttledResizeScreen);
	}, [throttledResizeScreen]);

	return chartWidth;
}
