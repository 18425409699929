import { PropsWithChildren, useCallback, useMemo } from "react";
import { UsersUrlStateContext } from "../hooks/useUsersStateContext";
import { JsonParam, NumberParam, StringParam, withDefault } from "use-query-params";
import { UsersState } from "../types";
import { useURLState } from "../../../../../hooks";
import { RecordSidePanel } from "../../../../records";
import { isValidFilter, isValidSort } from "../../../../ObjectBoard/hooks/useBoardState";

const DEFAULT_USERS_STATE: UsersState = {
	recordId: undefined,
	filter: undefined,
	sort: undefined,
	search: "",
};

const USERS_STATE_VALUE_VALIDITY: Record<keyof UsersState, (value: any) => boolean> = {
	recordId: (value: unknown) => typeof value === "number",
	filter: isValidFilter,
	sort: isValidSort,
	search: (value: unknown) => typeof value === "string",
};

export function UsersStateProvider({ children }: PropsWithChildren) {
	const { urlState, propOnChange, resetState } = useURLState({
		defaultState: DEFAULT_USERS_STATE,
		valueValidityMap: USERS_STATE_VALUE_VALIDITY,
		paramConfigMap: {
			recordId: withDefault(NumberParam, DEFAULT_USERS_STATE.recordId),
			filter: withDefault(JsonParam, DEFAULT_USERS_STATE.filter),
			sort: withDefault(JsonParam, DEFAULT_USERS_STATE.sort),
			search: withDefault(StringParam, DEFAULT_USERS_STATE.search),
		},
	});

	const closeRecordPanel = useCallback(() => {
		propOnChange("recordId", undefined);
	}, [propOnChange]);

	const state = useMemo(
		() => ({
			urlState,
			propOnChange,
			resetState,
		}),
		[urlState, propOnChange, resetState]
	);

	return (
		<UsersUrlStateContext.Provider value={state}>
			<div className="relative h-full">
				{children}
				{urlState ? <RecordSidePanel recordId={urlState.recordId ?? undefined} onClose={closeRecordPanel} /> : null}
			</div>
		</UsersUrlStateContext.Provider>
	);
}
