import { useEffect, useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { RecordsSearchRequest, SDRecord } from "@salesdesk/salesdesk-schemas";

import { getAssociationSearchParams, useObjectAssociations } from "../../recordAssociations";
import { RECORD_CHANGE_EVENT, RecordChangeEvent, RecordChangeType, useSearchRecordsQuery } from "../../records";
import { APP_CONFIG } from "../../../app/app_config";

const WORKSPACE_OBJECT_ID = [mWorkspaceDef.ID];

export function useGetRecordWorkspaceIds(sdRecord?: SDRecord) {
	const {
		objectAssociations: { prioritizedAssociations: workspaceAssociations },
		isLoading: isWorkspaceAssociationsLoading,
	} = useObjectAssociations(sdRecord?._objectDefId, WORKSPACE_OBJECT_ID);

	const sourceRecordId = sdRecord?._id;
	const searchParams = useMemo<RecordsSearchRequest | undefined>(() => {
		if (!workspaceAssociations.length || sourceRecordId == null) return undefined;

		return {
			...getAssociationSearchParams({ sourceRecordId }, workspaceAssociations),
			from: 0,
			size: APP_CONFIG.maxLocalSearchResults,
		};
	}, [workspaceAssociations, sourceRecordId]);

	const {
		currentData: workspaceIdResults,
		isLoading: isLoadingWorkspaceIds,
		refetch,
	} = useSearchRecordsQuery(searchParams ?? skipToken);

	useEffect(() => {
		const onRecordChange = (event: CustomEvent<RecordChangeEvent>) => {
			if (event.detail.type === RecordChangeType.ASSOCIATION_CHANGE) {
				refetch();
			}
		};

		document.addEventListener(RECORD_CHANGE_EVENT, onRecordChange as EventListener);

		return () => {
			document.removeEventListener(RECORD_CHANGE_EVENT, onRecordChange as EventListener);
		};
	}, [refetch]);

	const workspaceIds = useMemo(() => {
		return workspaceIdResults?.hits.map((result) => result.source.id) ?? [];
	}, [workspaceIdResults]);

	return { workspaceIds, isLoading: isWorkspaceAssociationsLoading || isLoadingWorkspaceIds };
}
