import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
	AbilityAction,
	AbilitySubject,
	getSDRecordName,
	SDObject,
	SDRecord,
	sdSubject,
} from "@salesdesk/salesdesk-schemas";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { MenuItem } from "../../../../../menu";
import { useGetObjectById } from "../../../../../../hooks/useGetObjectById";
import {
	useCreateWorkspaceContext,
	useGetRecordWorkspaceIds,
	WORKSPACE_ORIGIN_OBJECTS,
} from "../../../../../workspaces";
import { useWebPrincipal } from "../../../../../../auth";
import { useGetRecordQuery } from "../../../../api/recordsApi";
import { PATHS } from "../../../../../../routes";

export function useWorkspaceOriginActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	const principal = useWebPrincipal();

	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);

	const canBeWorkspaceOrigin = WORKSPACE_ORIGIN_OBJECTS.includes(sdObject?._id ?? -1);

	const { openModal } = useCreateWorkspaceContext();

	const { workspaceIds, isLoading: isLoadingWorkspaceIds } = useGetRecordWorkspaceIds(
		canBeWorkspaceOrigin ? sdRecord : undefined
	);
	const { currentData: workspaceRecord, isLoading: isLoadingWorkspaceRecord } = useGetRecordQuery(
		canBeWorkspaceOrigin && workspaceIds.length ? workspaceIds[0] : skipToken
	);

	const isLoading = isLoadingWorkspaceIds || isLoadingWorkspaceRecord;

	if (!workspaceObject || !canBeWorkspaceOrigin || !sdRecord || !sdObject) return [];

	if (workspaceRecord && principal.can(AbilityAction.View, sdSubject(AbilitySubject.Record, workspaceRecord))) {
		return [
			{
				icon: workspaceObject._icon,
				text: "Enter workspace",
				subText: getSDRecordName(workspaceObject, workspaceRecord),
				type: "link",
				link: PATHS.WORKSPACE_OVERVIEW(workspaceRecord._id),
				variant: "primary",
				loading: isLoading,
			},
		];
	}

	if (
		openModal &&
		principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: mWorkspaceDef.ID }))
	) {
		return [
			{
				icon: workspaceObject._icon,
				text: "Create workspace",
				loading: isLoading,
				type: "button",
				onClick: () => {
					openModal({ originRecord: sdRecord, originRecordObject: sdObject });
				},
			},
		];
	}

	return [];
}
