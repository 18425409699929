import { KeyboardEvent } from "react";

export function applyAndCancelKeyCapturing<T>(
	event: KeyboardEvent<T>,
	onApply?: (event: KeyboardEvent<T>) => void,
	onCancel?: (event: KeyboardEvent<T>) => void
) {
	if (event.key === "Escape" && onCancel) {
		onCancel(event);
	}

	if (event.key === "Enter" && onApply) {
		onApply(event);
	}
}
