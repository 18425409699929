import { mInteractionDef } from "../interaction_def";
import { INTERACTION_IDS } from "../interaction_constants";

export class mInteractionShareDef extends mInteractionDef {
	static CLASS_NAME = "mInteractionShareDef";
	static NAME = "Message";
	static PLURAL_NAME = "Messages";
	static ICON = "fa-envelope";

	static ID = INTERACTION_IDS.SHARE;

	constructor(ownerId) {
		super(mInteractionShareDef.ID, ownerId);
	}
}
