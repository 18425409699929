import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInMonths,
	differenceInYears,
	format,
	formatDuration,
	intervalToDuration,
	isToday,
} from "date-fns";
import parseISO from "date-fns/parseISO/index.js";
import { isEmpty } from "./utils";

export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = SECOND_IN_MS * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;

export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const WEEK_IN_SECONDS = DAY_IN_SECONDS * 7;
export const HOUR_IN_MINUTES = 60;
export const DAY_IN_HOURS = 24;

export const DAY_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function addLeadingZeroes(num: number, expectedDigits = 2) {
	const numAsString = String(num);
	const amountOfLeadingZeroes = expectedDigits - numAsString.length;

	if (amountOfLeadingZeroes > 0) {
		return "0".repeat(amountOfLeadingZeroes) + numAsString;
	}

	return (num < 10 ? "0" : "") + num;
}

export function parseMilliseconds(totalMilliseconds: number) {
	return {
		hours: Math.floor((totalMilliseconds / (SECOND_IN_MS * MINUTE_IN_SECONDS * HOUR_IN_MINUTES)) % DAY_IN_HOURS),
		minutes: Math.floor((totalMilliseconds / (SECOND_IN_MS * HOUR_IN_MINUTES)) % HOUR_IN_MINUTES),
		seconds: Math.floor((totalMilliseconds / SECOND_IN_MS) % MINUTE_IN_SECONDS),
		milliseconds: Math.floor(totalMilliseconds % SECOND_IN_MS),
	};
}

export function formatMilliseconds(milliseconds: number) {
	const { hours, minutes, seconds } = parseMilliseconds(milliseconds);

	let formattedDuration = "";
	if (hours) {
		formattedDuration += `${hours}h `;
	}

	if (minutes) {
		formattedDuration += `${minutes}m `;
	}

	if (seconds || !formattedDuration) {
		formattedDuration += `${seconds}s`;
	}

	return formattedDuration;
}

export function formatMillisecondsToHHMMSS(milliseconds: number, addLeadingZeroesToHours = true) {
	const { hours, minutes, seconds } = parseMilliseconds(milliseconds);

	const hoursText = addLeadingZeroesToHours ? `${addLeadingZeroes(hours)}:` : hours > 0 ? `${hours}:` : "";
	return `${hoursText}${addLeadingZeroes(minutes)}:${addLeadingZeroes(seconds)}`;
}

export function convertHHMMSSToMilliseconds(durationString: string) {
	let totalMilliseconds = 0;
	if (!durationString) {
		return totalMilliseconds;
	}

	const splitDuration = durationString.split(":");
	for (let i = 0; i < splitDuration.length; i++) {
		try {
			const parsedInterval = parseInt(splitDuration[i]);

			// Hours
			if (i === 0) {
				totalMilliseconds += parsedInterval * HOUR_IN_MS;
			}
			// Minutes
			else if (i === 1) {
				totalMilliseconds += parsedInterval * MINUTE_IN_MS;
			}
			// Seconds
			else if (i === 2) {
				totalMilliseconds += parsedInterval * SECOND_IN_MS;
			} else {
				break;
			}
		} catch (error) {
			continue;
		}
	}

	return totalMilliseconds;
}

export function isBeforeNow(date: Date) {
	const now = new Date();
	return now.getTime() > date.getTime();
}

export function isBeforeToday(date: Date) {
	const now = new Date();
	return date.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0);
}

export function isThisWeek(date: Date) {
	const today = new Date();
	const todayDate = today.getDate();
	const todayDay = today.getDay();

	// get first date of week
	const firstDayOfWeek = new Date(today.setDate(todayDate - todayDay));

	// get last date of week
	const lastDayOfWeek = new Date(firstDayOfWeek);
	lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

	// if date is equal or within the first and last dates of the week
	return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

export function isThisMonth(date: Date) {
	const today = new Date();
	return today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear();
}

export function isLaterThanThisMonth(date: Date) {
	const now = new Date();
	const nextMonth = new Date();
	nextMonth.setMonth(now.getMonth() + 1);
	nextMonth.setDate(1);
	nextMonth.setHours(0);
	nextMonth.setMinutes(0);
	nextMonth.setSeconds(0);
	nextMonth.setMilliseconds(0);

	return date.getTime() > nextMonth.getTime();
}

export function getUnixTimestamp(date: Date, utcDateOnly = false) {
	return utcDateOnly ? parseISO(format(date, "yyyy-MM-dd'Z'")).getTime() : date.getTime();
}

export function getUnixTimestampNow(utcDateOnly: boolean) {
	return getUnixTimestamp(new Date(), utcDateOnly);
}

export const mapUtcTimeStampToLocalDateTime = (timestamp: number, fromUtcDateOnly: boolean) => {
	const date = new Date(timestamp);
	if (fromUtcDateOnly) return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
	return date;
};

export const mapLocalDateTimeToUtcTimestamp = (date: Date, toUtcDateOnly?: boolean) => {
	if (toUtcDateOnly) return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return date.getTime();
};

export function mapUnixTimestampToDateTimeLocalIsoString(
	unixTimestamp: number | null | undefined,
	fromUtcDateOnly: boolean
) {
	if (unixTimestamp == null) return "";
	const date = new Date(unixTimestamp);
	if (fromUtcDateOnly) return `${date.toISOString().substring(0, 10)}`;
	return format(date, "yyyy-MM-dd'T'HH:mm");
}

export function mapDateTimeLocalIsoStringToUnixTimestampOrNull(localString: string, toUtcDateOnly: boolean) {
	if (isEmpty(localString)) return null;
	if (toUtcDateOnly) return parseISO(localString + "Z").getTime();
	return parseISO(localString).getTime();
}

export function getDateAsMonthDayYearFormat(date: Date) {
	if (isNaN(date.getTime())) {
		return "";
	}

	return (
		addLeadingZeroes(date.getDate()) +
		"/" +
		addLeadingZeroes(date.getMonth() + 1) +
		"/" +
		addLeadingZeroes(date.getFullYear(), 4)
	);
}

export function formatTime(date: Date) {
	return format(date, "HH:mm");
}

export function formatDate(date: Date) {
	return format(date, "P");
}

export function formatDateTime(dateTime: Date) {
	return format(dateTime, "Pp");
}

export function formatTimeIfToday(date: Date) {
	if (isToday(date)) {
		return format(date, "HH:mm");
	}

	return format(date, "P");
}

export function longDateTime(dateTime: Date) {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	};
	return dateTime.toLocaleString("en-GB", options);
}

// If arguments are on the same day: return "dd/MM/yyyy HH:mm - HH:mm"
// Otherwise: return "dd/MM/yyyy HH:mm - dd/MM/yyyy HH:mm"
export function longDateTimeRange(startDateTime: Date, endDateTime: Date) {
	// dd/mm/yyyy, 24:00:00
	const startString = startDateTime.toLocaleString("en-GB", { timeZone: "Europe/London" });
	const endString = endDateTime.toLocaleString("en-GB", { timeZone: "Europe/London" });
	const startDateString = startString.substring(0, 10);
	const startTimeString = startString.substring(12, 17);
	const endDateString = endString.substring(0, 10);
	const endTimeString = endString.substring(12, 17);
	return startDateString === endDateString
		? `${startDateString} ${startTimeString} - ${endTimeString} (UK)`
		: `${startDateString} ${startTimeString} - ${endDateString} ${endTimeString} (UK)`;
}

export function durationStrLongFromHMStr(duration: number) {
	const { hours, minutes } = parseMilliseconds(duration);

	let durationString = "";

	if (hours > 0) {
		durationString += `${hours} hours`;

		if (minutes > 0) {
			durationString += `, `;
		}
	}

	if (minutes > 0) {
		durationString += `${minutes} minutes`;
	}

	return durationString;
}

// Based on https://acavalin.com/p/google_calendar_create_event_url
export const dateToGoogleCalendarString = (date: Date) => {
	return date.toISOString().replace(/-|:|\.\d\d\d/g, "");
};

export function formatSince(date: Date, messageFormat?: "short" | "full", fullDateThresholdDays?: number): string {
	messageFormat = messageFormat || "short";

	const now = new Date();

	if (fullDateThresholdDays !== undefined && differenceInDays(now, date) > fullDateThresholdDays) {
		return format(date, "yyyy/MM/dd");
	}

	const isShortFormat = messageFormat === "short";

	const minutes = differenceInMinutes(now, date);

	const hours = differenceInHours(now, date);
	if (hours < 1) {
		return `${minutes}${isShortFormat ? "m" : " minutes ago"}`;
	}

	const days = differenceInDays(now, date);
	if (days < 1) {
		return `${hours}${isShortFormat ? "h" : " hours ago"}`;
	}

	const months = differenceInMonths(now, date);
	if (months < 1) {
		return `${days}${isShortFormat ? "d" : " days ago"}`;
	}

	const years = differenceInYears(now, date);
	if (years < 1) {
		return `${months}${isShortFormat ? "mo" : " months ago"}`;
	}

	return `${years}${isShortFormat ? "y" : " years ago"}`;
}

// Formats date as YYYY-MM-dd
export const getIsoDay = (date: Date) => {
	return date.toISOString().split("T")[0];
};

export function formatTimePassed(startTimeStamp: number, endTimeStamp: number) {
	return formatDuration(intervalToDuration({ start: startTimeStamp, end: endTimeStamp }), {
		format: ["hours", "minutes"],
		zero: false,
	});
}
