import { ICONS } from "@salesdesk/salesdesk-ui";
import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { Button } from "@salesdesk/daisy-ui";

export const JUMP_TIME_SECONDS = 5;

export function VideoJumpControl() {
	const { variant, seekToVideoTime, currentVideoTime } = useVideoPlayerContext();

	return (
		<>
			<Button
				startIcon={ICONS.videoJumpBackward5}
				size={variant === "expanded" ? "sm" : "xs"}
				variant="text_inverted"
				onClick={() => seekToVideoTime(currentVideoTime - JUMP_TIME_SECONDS)}
			/>
			<Button
				startIcon={ICONS.videoJumpForward5}
				size={variant === "expanded" ? "sm" : "xs"}
				variant="text_inverted"
				onClick={() => seekToVideoTime(currentVideoTime + JUMP_TIME_SECONDS)}
			/>
		</>
	);
}
