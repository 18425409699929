import { useMemo } from "react";

import { notificationRuleConfig, NotificationRuleConfig, NotificationRuleType } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../auth";

export function useNotificationRuleConfigs(
	ruleType: NotificationRuleType,
	objectId: number | undefined | null
): NotificationRuleConfig[] {
	const principal = useWebPrincipal();

	return useMemo(() => {
		return notificationRuleConfig.filter(
			(config) =>
				(!config.allowedRuleTypes || config.allowedRuleTypes.includes(ruleType)) &&
				(!config.allowedObjectIds || (objectId && config.allowedObjectIds.includes(objectId))) &&
				(!config.objectIdNotRequired || objectId === undefined) &&
				(!config.fieldIdRequired || objectId != null) &&
				(!principal.IsCustomerUser || !config.notAuthorizedForCustomers)
		);
	}, [objectId, principal.IsCustomerUser, ruleType]);
}
