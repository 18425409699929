import { useMemo } from "react";
import { RuleValue } from "../types";
import { useNotificationRuleDialogContext } from "./useNotificationRuleDialogContext";
import { ANY } from "../types/Any";

export function useRecordRuleValues() {
	const { selectedRecord, currentSelection, setCurrentSelection } = useNotificationRuleDialogContext();

	return useMemo(() => {
		const values: RuleValue[] = [];
		if (!selectedRecord) {
			values.push({
				placeholder: "this record",
				onClick: () => setCurrentSelection("record"),
				active: currentSelection === "record",
			});
		} else {
			values.push({
				value: selectedRecord.id === ANY ? selectedRecord.name.toLowerCase() : selectedRecord.name,
				onClick: () => setCurrentSelection("record"),
				active: currentSelection === "record",
			});
		}
		return values;
	}, [selectedRecord, currentSelection, setCurrentSelection]);
}
