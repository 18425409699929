import { useGetRolesQuery } from "../api/rolesApi";
import { useMemo } from "react";
import { RoleName } from "@salesdesk/salesdesk-model";

export function useRoles() {
	const { isLoading: isLoadingRoles, data: roles } = useGetRolesQuery();

	const sortedRoles = useMemo(() => {
		if (!roles) return undefined;
		return [...roles].sort((r1, r2) => r1.createdAt - r2.createdAt).sort((a) => (a.name === RoleName.Admin ? -1 : 1));
	}, [roles]);

	return { roles: sortedRoles, isLoading: isLoadingRoles };
}
