import { Button } from "@salesdesk/daisy-ui";
import { capitalizeString, passwordPassesAllRules, passwordRules } from "@salesdesk/salesdesk-utils";
import { useForm } from "react-hook-form";
import { FormFieldSet } from "../../../forms";
import { EditPasswordField } from "../../../fields/components/Fields/TextField/components/EditPasswordField";
import { PasswordRule } from "./PasswordRule";
import { useState } from "react";
import { EditTextField } from "../../../fields";

interface SetPasswordFormData {
	username: string;
	password: string;
}

interface SetPasswordFormProps {
	email: string | null;
	firstName: string | null;
	onNewPasswordSubmit: (password: string) => void;
}

export function SetPasswordForm({ email, firstName, onNewPasswordSubmit }: SetPasswordFormProps) {
	const { control, handleSubmit, watch } = useForm<SetPasswordFormData>({
		mode: "onBlur",
		defaultValues: {
			username: email ?? "",
			password: "",
		},
	});

	const password = watch("password");

	const [isSigningIn, setIsSigningIn] = useState(false);
	const onSubmit = handleSubmit(({ password }) => {
		if (!password) {
			return;
		}
		setIsSigningIn(true);
		onNewPasswordSubmit(password);
	});

	return (
		<form className="flex flex-col gap-8" onSubmit={onSubmit}>
			<div className="text-h3 text-center">Almost there{firstName ? `, ${capitalizeString(firstName)}` : ""}!</div>
			<div className="flex flex-col gap-4">
				<FormFieldSet control={control} name="username" label="Email" required rules={{}}>
					{({ field }) => <EditTextField title="Email" {...field} disabled />}
				</FormFieldSet>
				<FormFieldSet
					control={control}
					name="password"
					label="Enter Password"
					required
					rules={{
						validate: (value) => {
							if (!passwordPassesAllRules(value)) {
								return "Password does not meet all the requirements";
							}
							return true;
						},
					}}
				>
					{({ field }) => (
						<EditPasswordField
							title="Password"
							{...field}
							tabIndex={1}
							autoFocus
							required
							placeholder="Enter a password"
						/>
					)}
				</FormFieldSet>
			</div>
			<div className="text-c_text_secondary flex flex-col gap-4">
				<div className="text-body-sm">Password must contain:</div>
				<div className="grid grid-cols-2 gap-1 text-left">
					{passwordRules.map((rule) => (
						<PasswordRule key={rule.title} title={rule.title} isPass={rule.validate(password)} />
					))}
				</div>
			</div>
			<Button variant="primary_dark" type="submit" fullWidth tabIndexOverride={2} isLoading={isSigningIn}>
				Let's go!
			</Button>
		</form>
	);
}
