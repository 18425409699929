import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { Button, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetRecordWithObjectByRecordId } from "../../../../../../../hooks";
import { FabContainer } from "../../../../../../../components/FabContainer";
import { getHuddleMeetingDetails, HuddleStatus } from "../../../../../../meetings/utils";
import { CompactRecordPreview, SkeletonCompactRecordPreview, useGetRecordDefaultPath } from "../../../../../../records";
import { PATHS } from "../../../../../../../routes";
import { ACTIVE_HUDDLE_POLLING } from "../../../../../utils";

interface RecordAttachmentProps {
	recordId: SDRecord["_id"];
	onRemove?: () => void;
}

export function EmbeddedRecord({ recordId, onRemove }: RecordAttachmentProps) {
	const [pollRecord, setPollRecord] = useState(false);
	const { sdRecord, sdObject, isLoading } = useGetRecordWithObjectByRecordId(
		recordId,
		pollRecord ? ACTIVE_HUDDLE_POLLING : undefined,
		true
	);

	const isActiveHuddle = useMemo(() => {
		if (!sdRecord || !sdObject) {
			return false;
		}

		const { isHuddle, huddleStatus } = getHuddleMeetingDetails(sdObject, sdRecord);
		return isHuddle && huddleStatus !== HuddleStatus.INACTIVE;
	}, [sdRecord, sdObject]);

	useEffect(() => {
		setPollRecord(isActiveHuddle);
	}, [isActiveHuddle]);

	const getRecordPath = useGetRecordDefaultPath();

	const hasOnRemove = Boolean(onRemove);

	const recordPath = useMemo(() => {
		if (!sdRecord?._id || !sdObject || hasOnRemove) {
			return "";
		}

		if (isActiveHuddle) {
			return PATHS.MEETING(sdRecord);
		}

		return getRecordPath(sdRecord._id, sdObject);
	}, [sdRecord, sdObject, hasOnRemove, isActiveHuddle, getRecordPath]);

	const cardClasses = tw`bg-c_bg_01 border-c_border_regular text-c_text_primary hover:bg-c_bg_03 flex min-h-12 w-full items-center rounded-[8px] border p-2 transition-colors focus-visible:ring ring-c_action_focus`;

	if (isLoading || !sdRecord || !sdObject) {
		return (
			<div className={cardClasses}>
				<SkeletonCompactRecordPreview />
			</div>
		);
	}

	const compactRecordPreview = <CompactRecordPreview sdRecord={sdRecord} sdObject={sdObject} variant="xs" />;

	return (
		<FabContainer
			hide={!hasOnRemove}
			buttons={
				<Button
					size="xs"
					variant="text"
					startIcon={ICONS.cross}
					aria-label="Remove attachment"
					onClick={(e) => {
						e.preventDefault();
						onRemove?.();
					}}
				/>
			}
			positionClasses={tw`top-1/2 transform -translate-y-1/2 right-2`}
		>
			{recordPath ? (
				<Link to={recordPath} className={clsx(cardClasses, "cursor-pointer")}>
					{compactRecordPreview}
				</Link>
			) : (
				<div className={cardClasses}>{compactRecordPreview}</div>
			)}
		</FabContainer>
	);
}
