import { ICONS } from "@salesdesk/salesdesk-ui";

import { GeneralPreviewFieldProps } from "../../../../types";
import { GeneralPreviewField } from "../../../GeneralPreviewField";
import { Icon } from "@salesdesk/daisy-ui";

export function PreviewOptionField({ placeholder }: GeneralPreviewFieldProps) {
	return (
		<GeneralPreviewField
			placeholder={placeholder}
			containedIcon={<Icon className="text-c_icon_disabled flex items-center" icon={ICONS.caretUpDown} />}
		/>
	);
}
