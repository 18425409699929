import { SDError } from "@salesdesk/salesdesk-schemas";

type SDErrorReponse = { status: number; data: SDError };

export function isSdErrorResponse(obj: unknown): obj is SDErrorReponse {
	if (!obj || typeof obj !== "object") return false;
	if (!("status" in obj) || typeof obj.status !== "number") return false;
	if (!("data" in obj) || !obj.data || typeof obj.data !== "object") return false;
	if (!("errorCode" in obj.data) || typeof obj.data.errorCode !== "string") return false;
	if (!("description" in obj.data) || typeof obj.data.description !== "string") return false;
	return true;
}
