import { NotificationsProvider } from "./NotificationsProvider";
import { Notifications } from "./Notifications";
import { SDNotification } from "@salesdesk/salesdesk-schemas";
import { useNotificationsCounterContext } from "../hooks/useNotificationsCounterContext";

interface NotificationsControllerProps {
	onNotificationClicked: (notification: SDNotification) => void;
}
export function NotificationsController({ onNotificationClicked }: NotificationsControllerProps) {
	const { reset } = useNotificationsCounterContext();

	return (
		<NotificationsProvider onNotificationClicked={onNotificationClicked} resetNewNotificationsCounter={reset}>
			<Notifications />
		</NotificationsProvider>
	);
}
