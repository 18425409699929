import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";
import { appendNotEditable } from "../../../functions";

export const fieldObjectDefIdSchema = asConst({
	...idSchema,
	title: "SDObject Id",
	description: appendNotEditable(
		"Required by the Object(s) Field types to indicate the SDObject id whose record can be referenced from this field"
	),
});
