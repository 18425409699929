import { asConst } from "json-schema-to-ts";
import { objectBaseTypeSchema } from "./objectBaseTypeSchema";
import { objectSubTypesSchema } from "./objectSubTypesSchema";
import { idSchema } from "../../components/idSchema";
import { stringSchema } from "../../components/stringSchema";
import { iconSchema } from "../../components/iconSchema";

export const objectSummaryInfoSchema = asConst({
	title: "SDObject Summary Info",
	description: "TODO:  Remove this from being sent into the API.  Should be populated by the API",
	type: "object",
	additionalProperties: true,
	required: ["id", "baseType", "subTypes", "name", "pluralName", "icon"],
	properties: {
		id: idSchema,
		baseType: objectBaseTypeSchema,
		subTypes: objectSubTypesSchema, // TODO: Rename to subTypes
		name: stringSchema, //TODO: Does it need a specific schema?
		pluralName: stringSchema, //TODO: Does it need a specific schema?
		icon: iconSchema,
	},
});
