import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import clone from "clone";

import { Field, getAllSDObjectFields, SDObject} from "@salesdesk/salesdesk-schemas";

import { SettingFieldFieldIdMap } from "../../types";
import { useGetSDObjectOptions } from "../useGetSDObjectOptions";
import {
	createSettingFieldsByField,
	createSettingFieldsByFieldIdMap,
	getFieldSettingsDefaultValues,
	updateSettingFieldsByObjectFieldIdAndField,
	getSettingsFieldValuesByField,
	getObjectDifference,
	onOptionValueFieldSettingsChange,
	onMaxLengthFieldSettingsChange,
	setDisabledToFieldSettingFieldByName,
} from "./helpers";
import { NULLABLE_FIELD_SETTINGS_FIELD_NAMES, EXCLUDED_SD_OBJECT_ID_OPTIONS } from "../../utils/fieldSettingsFields";

export function useFieldSettingsForm(
	sdObject: SDObject,
	onFormUpdate: (formValues: Record<string, any>) => void,
	isNewObject: boolean
) {
	const sdObjectId = sdObject._id;
	const sdObjectFields = getAllSDObjectFields(sdObject)

	// TODO: Improve how we handle loading the objects for object/objects fields when we
	// tackle object fetching in the app:  https://salesdesk101.atlassian.net/browse/SAL-1868
	const sdObjectOptions = useGetSDObjectOptions(EXCLUDED_SD_OBJECT_ID_OPTIONS);

	const [initialSDObjectFields] = useState(sdObjectFields);
	const [initialSDObjectOptions] = useState(sdObjectOptions);

	const [settingFieldsByObjectFieldId, setSettingFieldsByObjectFieldId] = useState<SettingFieldFieldIdMap>({});
	const [prevFormValues, setPrevFormValues] = useState({});

	const initialDefaultValues = useMemo(() => {
		const initialSettingFieldsByObjectFieldId = createSettingFieldsByFieldIdMap(
			sdObjectId,
			initialSDObjectFields,
			isNewObject,
			initialSDObjectOptions
		);
		setSettingFieldsByObjectFieldId(initialSettingFieldsByObjectFieldId);

		return getFieldSettingsDefaultValues(initialSDObjectFields, initialSettingFieldsByObjectFieldId);
	}, [sdObjectId, initialSDObjectFields, isNewObject, initialSDObjectOptions]);

	const [activeObjectFieldId, setActiveObjectFieldId] = useState<string>(() => Object.keys(initialDefaultValues)[0]);

	const formMethods = useForm({
		mode: "onChange",
		defaultValues: initialDefaultValues,
	});

	const settingFieldGroups = settingFieldsByObjectFieldId[activeObjectFieldId]?.settingsFieldGroups || [];

	const manageSettingsFieldGroups = useCallback(
		(formValues: Record<string, any>, prevFormValues: Record<string, any>) => {
			const formValuesDifference = getObjectDifference(formValues, prevFormValues);
			const [objectFieldId] = Object.keys(formValuesDifference);
			const fieldValueDifference = formValuesDifference[objectFieldId];

			let fieldToUpdate = null;
			let propertiesToUpdate = null;

			if (!fieldValueDifference || !objectFieldId) return;

			for (const key of Object.keys(fieldValueDifference)) {
				if (key === "_optionValues") {
					({ fieldToUpdate, propertiesToUpdate } = onOptionValueFieldSettingsChange(
						formMethods,
						formValues,
						objectFieldId,
						settingFieldsByObjectFieldId
					));
				}

				if (key === "_maxLength") {
					({ fieldToUpdate, propertiesToUpdate } = onMaxLengthFieldSettingsChange(
						formMethods,
						formValues,
						objectFieldId,
						settingFieldsByObjectFieldId
					));
				}

				if (key === "_hidden") {
					({ fieldToUpdate, propertiesToUpdate } = setDisabledToFieldSettingFieldByName(
						"_required",
						formValues[objectFieldId]._hidden,
						objectFieldId,
						settingFieldsByObjectFieldId
					));
				}

				if (key === "_required") {
					({ fieldToUpdate, propertiesToUpdate } = setDisabledToFieldSettingFieldByName(
						"_hidden",
						formValues[objectFieldId]._required,
						objectFieldId,
						settingFieldsByObjectFieldId
					));
				}

				if (!fieldToUpdate || !propertiesToUpdate) return;

				const updatedFieldToUpdate = Object.assign(fieldToUpdate, propertiesToUpdate);

				setSettingFieldsByObjectFieldId(
					updateSettingFieldsByObjectFieldIdAndField(objectFieldId, updatedFieldToUpdate, settingFieldsByObjectFieldId)
				);

				setTimeout(() => {
					formMethods.trigger(objectFieldId);
				}, 0);
			}
		},
		[formMethods, settingFieldsByObjectFieldId]
	);

	const formValues = formMethods.watch();

	/*
		We call 'onFormUpdate' in useEffect not as formMethods.watch callback
		to decrease amount of 'onFormUpdate' calls by comparing formValues, prevFormValues
		and to have an access to up to date settingFieldsByObjectFieldId
	*/
	useEffect(() => {
		if (isEqual(formValues, prevFormValues)) return;

		setPrevFormValues(clone(formValues));

		manageSettingsFieldGroups(formValues, prevFormValues);

		const formattedFormFields: Record<string, Field> = {};

		for (const [fieldId, fieldFormValues] of Object.entries(formValues)) {
			const formattedFieldValues = { ...fieldFormValues };

			settingFieldsByObjectFieldId[fieldId].settingsFields.forEach((fieldDef) => {
				const fieldName = fieldDef._name as keyof Field;
				const fieldValue = fieldFormValues[fieldName];

				if (fieldValue === undefined || fieldValue === "") {
					formattedFieldValues[fieldName] = NULLABLE_FIELD_SETTINGS_FIELD_NAMES.includes(fieldName) ? null : "";
				}
			});

			formattedFormFields[fieldId] = formattedFieldValues;
		}

		onFormUpdate(formattedFormFields);
	}, [formValues, onFormUpdate, settingFieldsByObjectFieldId, prevFormValues, manageSettingsFieldGroups]);

	const createObjectField = (field: Field) => {
		const settingFields = createSettingFieldsByField(sdObjectId, field, true, sdObjectOptions);
		const settingFieldValues = getSettingsFieldValuesByField(field, settingFields.settingsFields);
		const fieldId = String(field._id);

		setSettingFieldsByObjectFieldId((prevSettingFieldsByObjectFieldIdMap) => ({
			...prevSettingFieldsByObjectFieldIdMap,
			[fieldId]: settingFields,
		}));

		for (const [fieldName, fieldValue] of Object.entries(settingFieldValues)) {
			formMethods.setValue(`${fieldId}.${fieldName}`, fieldValue);
		}

		/* 
			We trigger validation on the next tick 
			to validate updated values which were set above 
			and immediately validate just added field 
		*/
		setTimeout(() => {
			formMethods.trigger(fieldId);
		}, 0);
		setActiveObjectFieldId(fieldId);
	};

	const deleteSettingFieldById = useCallback(
		(fieldId: number) => {
			const stringFieldId = String(fieldId);
			const updatedSettingFields = { ...settingFieldsByObjectFieldId };
			delete updatedSettingFields[stringFieldId];
			const nextActiveFields = sdObjectFields.find(({ _id }) => _id !== fieldId);

			setActiveObjectFieldId(String(nextActiveFields?._id));
			setSettingFieldsByObjectFieldId(updatedSettingFields);
			formMethods.unregister(stringFieldId);
		},
		[formMethods, sdObjectFields, settingFieldsByObjectFieldId]
	);

	const resetFieldSettings = useCallback(
		(sdObjectFields: Field[], newActiveObjectFieldId?: string) => {
			const settingFieldsByObjectIds = createSettingFieldsByFieldIdMap(sdObjectId, sdObjectFields, isNewObject, sdObjectOptions);
			const resetValues = getFieldSettingsDefaultValues(sdObjectFields, settingFieldsByObjectIds);
			const resetValueIds = Object.keys(resetValues);

			setSettingFieldsByObjectFieldId(settingFieldsByObjectIds);
			formMethods.reset(resetValues);

			if (newActiveObjectFieldId && resetValueIds.includes(newActiveObjectFieldId)) {
				setActiveObjectFieldId(newActiveObjectFieldId);
				return;
			}

			if (!resetValueIds.includes(activeObjectFieldId)) {
				setActiveObjectFieldId(resetValueIds[0]);
			}
		},
		[sdObjectId, formMethods, isNewObject, activeObjectFieldId, sdObjectOptions]
	);

	const setActiveField = (fieldId: number) => {
		setActiveObjectFieldId(String(fieldId));
	};

	const invalidObjectFieldIds = useMemo(
		() => Object.keys(formMethods.formState.errors).map((id) => Number(id)),
		[formMethods.formState]
	);

	return {
		formMethods,
		settingFieldGroups,
		createObjectField,
		deleteSettingFieldById,
		activeObjectFieldId: Number(activeObjectFieldId),
		setActiveField,
		invalidObjectFieldIds,
		areFieldsValid: invalidObjectFieldIds.length === 0,
		isFieldsFormChanged: formMethods.formState.isDirty,
		resetFieldSettings,
	};
}
