import { KeyboardEvent, useCallback, useMemo, useState } from "react";
import clsx from "clsx";

import { createUniqueId } from "@salesdesk/salesdesk-utils";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Button } from "@salesdesk/daisy-ui";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";

import { SelectOptionId } from "../../../../inputs";
import { OptionBuilderComponentProps, OptionBuilderOption } from "../../../types";
import { moveElementToIndex } from "../../../../../utils";

import { OptionBuilderItem } from "./OptionBuilderItem";

export function EditOptionBuilderField({
	id,
	value = [],
	onChange,
	onBlur,
	ariaAttributes,
	disabled,
	outcomeIds,
}: OptionBuilderComponentProps) {
	const [focusedOptionInputIndex, setFocusedOptionInputIndex] = useState(-1);

	const { options, outcomes } = useMemo(() => {
		if (!outcomeIds) {
			return { options: value, outcomes: [] };
		}

		const options: OptionBuilderOption[] = [];
		const outcomes: OptionBuilderOption[] = [];

		for (const option of value) {
			if (outcomeIds.includes(option.id)) {
				outcomes.push(option);
			} else {
				options.push(option);
			}
		}

		return { options, outcomes };
	}, [value, outcomeIds]);

	const updateOption = (newOptionDetails: OptionBuilderOption) => {
		onChange(value.map((option) => (option.id === newOptionDetails.id ? newOptionDetails : option)));
	};

	const removeOption = (optionId: SelectOptionId) => {
		onChange(value.filter((option) => option.id !== optionId));
	};

	const addOption = useCallback(() => {
		onChange([...value, { id: createUniqueId(), name: "", color: null, icon: null }]);
	}, [onChange, value]);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		onChange(moveElementToIndex(value, result.source.index, result.destination.index));
	};

	const onEnterPress = useCallback(
		(optionInputIndex: number) => (event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key !== "Enter") return;

			setFocusedOptionInputIndex(optionInputIndex + 1);

			if (optionInputIndex === options.length - 1) {
				addOption();
			}
		},
		[options, addOption]
	);

	return (
		<div className="flex w-full flex-col">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							className={clsx(options.length && "-mt-3", "flex w-full flex-col overflow-y-auto pb-1 pl-1 pr-1")}
						>
							{options.map((option, optionIndex) => {
								return (
									<Draggable key={option.id} draggableId={String(option.id)} index={optionIndex}>
										{(provided) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={provided.draggableProps.style}
												className="bg-c_bg_03 flex flex-row gap-2 rounded-md py-3"
											>
												<div className="flex max-h-[42px] flex-col justify-center">
													<Icon icon={ICONS.grabbable} className="text-c_icon_disabled flex" />
												</div>
												<OptionBuilderItem
													optionIndex={optionIndex}
													value={option}
													onChange={updateOption}
													onEnterPress={onEnterPress(optionIndex)}
													onRemoveOption={removeOption}
													focusedOptionInputIndex={focusedOptionInputIndex}
													setFocusedOptionInputIndex={setFocusedOptionInputIndex}
													ariaAttributes={ariaAttributes}
												/>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
							{!disabled && (
								<Button
									id={id}
									size="sm"
									variant="primary_text"
									startIcon={ICONS.plus}
									onClick={addOption}
									onBlur={onBlur}
								>
									Add Option
								</Button>
							)}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{outcomes.length ? (
				<div className="mt-6 flex flex-col gap-6">
					<div className="text-label-sm text-c_text_secondary flex w-full items-center justify-between">Outcomes</div>
					{outcomes.map((outcomeOption, outcomeOptionIndex) => (
						<OptionBuilderItem
							optionIndex={outcomeOptionIndex}
							value={outcomeOption}
							onChange={updateOption}
							onRemoveOption={removeOption}
							focusedOptionInputIndex={focusedOptionInputIndex}
							setFocusedOptionInputIndex={setFocusedOptionInputIndex}
							ariaAttributes={ariaAttributes}
						/>
					))}
				</div>
			) : null}
		</div>
	);
}
