import { asConst } from "json-schema-to-ts";
import { queryClausePropertyValuesSchema } from "./queryClausePropertyValuesSchema";

export const includeAnyOfClauseSchema = asConst({
	title: "Include Any Of",
	description: "Will match any record where the given field value is an array that includes any of the provided values",
	type: "object",
	additionalProperties: false,
	required: ["includeAnyOf"],
	properties: {
		includeAnyOf: queryClausePropertyValuesSchema,
	},
	nullable: false,
});
