import { useCallback } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { getDefaultObjectAssociationId } from "@salesdesk/salesdesk-model";

import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { useToast } from "../../../../../../../Toasts";
import { useBatchCreateRecordAssociationsMutation } from "../../../../../../../recordAssociations";
import { MAX_BATCH_ASSOCIATION_SIZE } from "../../../../../../../recordAssociations";
import { useBatchShareRecordsMutation } from "../../../../../../api/workspacesApi";
import { ToastType } from "../../../../../../../../components/Toast";

export function useShareSelectedRecords(
	objectIds: number[],
	closeResourcesPanel: () => void,
	associateWith?: SDRecord
) {
	const { workspaceRecord, workspaceObject } = useWorkspaceContext();
	const toast = useToast();
	const [batchLinkAssociations, { isLoading: isLoadingLinkCreation }] = useBatchCreateRecordAssociationsMutation();

	const [batchShareRecords, { isLoading: isShareBatchRecordsLoading }] = useBatchShareRecordsMutation();

	const onShareSelectedRecordsFinish = useCallback(
		(workspaceObjectName: string, toastType: ToastType, totalSharedRecords: number) => {
			toast.triggerMessage({
				type: toastType,
				messageKey: "batch_workspace_share_record",
				messageParams: {
					records: `${totalSharedRecords} ${pluralizeWithS("record", totalSharedRecords)}`,
					total: String(totalSharedRecords),
					workspaceObject: workspaceObjectName,
				},
			});
			closeResourcesPanel();
		},
		[closeResourcesPanel, toast]
	);

	const shareSelectedRecords = useCallback(
		(selectedRecords: SDRecord[]) => {
			const workspaceObjectName = workspaceObject?._displayName ?? "Workspace";

			if (!workspaceRecord) {
				toast.triggerMessage({
					type: "error",
					messageKey: "workspace_share_record",
					messageParams: { workspaceObject: workspaceObjectName },
				});
				return;
			}

			batchShareRecords({ workspace: workspaceRecord, records: selectedRecords }).then((result) => {
				if ("error" in result) {
					onShareSelectedRecordsFinish(workspaceObjectName, "error", selectedRecords.length);

					return;
				}

				if (associateWith) {
					const chunks = chunk(selectedRecords, MAX_BATCH_ASSOCIATION_SIZE);
					Promise.allSettled(
						chunks.map((chunk) =>
							batchLinkAssociations({
								associations: chunk.map((selectedRecord) => ({
									objectAssociationId: getDefaultObjectAssociationId(
										associateWith._objectDefId,
										selectedRecord._objectDefId
									),
									record1Id: associateWith._id,
									record2Id: selectedRecord._id,
								})),
							})
						)
					).then(() => {
						onShareSelectedRecordsFinish(workspaceObjectName, "success", selectedRecords.length);
					});

					return;
				}
				onShareSelectedRecordsFinish(workspaceObjectName, "success", selectedRecords.length);
			});
		},
		[
			workspaceObject?._displayName,
			workspaceRecord,
			toast,
			batchShareRecords,
			associateWith,
			batchLinkAssociations,
			onShareSelectedRecordsFinish,
		]
	);

	return { shareSelectedRecords, isLoading: isShareBatchRecordsLoading || isLoadingLinkCreation };
}
