import { NotificationRule, NotificationRuleConfig, NotificationRuleCreateRequest } from "@salesdesk/salesdesk-schemas";
import { useCallback } from "react";
import { SelectOption } from "../../../inputs";
import { useCreateNotificationRuleMutation, useUpdateNotificationRuleMutation } from "../api/notificationRuleApi";
import { useToast } from "../../../Toasts";
import { toChannels, toEventType, toFieldId, toRecordId, toUserIds } from "../utils";

export type onNotificationRuleFormSubmit = (
	type: "record" | "user",
	rule: NotificationRule | undefined,
	objectId: number | null | undefined,
	selectedWorkspaceId: number | undefined,
	selectedRuleConfig: NotificationRuleConfig | undefined,
	selectedAction: SelectOption,
	selectedUsers: SelectOption[],
	selectedRecord: SelectOption | undefined,
	selectedField: SelectOption | undefined,
	selectedChannels: SelectOption[]
) => void;

export function useNotificationRuleOnFormSubmit(onOpenChange: (open: boolean) => void) {
	const [createNotificationRule, { isLoading: isCreating }] = useCreateNotificationRuleMutation();
	const [updateNotificationRule, { isLoading: isUpdating }] = useUpdateNotificationRuleMutation();
	const toast = useToast();

	const onFormSubmit = useCallback<onNotificationRuleFormSubmit>(
		(
			type: "record" | "user",
			rule: NotificationRule | undefined,
			objectId: number | null | undefined,
			selectedWorkspaceId: number | undefined,
			selectedRuleConfig: NotificationRuleConfig | undefined,
			selectedAction: SelectOption,
			selectedUsers: SelectOption[],
			selectedRecord: SelectOption | undefined,
			selectedField: SelectOption | undefined,
			selectedChannels: SelectOption[]
		) => {
			const body: NotificationRuleCreateRequest = {
				ruleType: type,
				eventType: toEventType(selectedAction),
				channels: toChannels(selectedChannels),
				enabled: rule?.enabled ?? true,
			};
			if (!selectedRuleConfig?.userIdsNotRequired) body.userIds = toUserIds(selectedUsers);
			if (!selectedRuleConfig?.workspaceIdNotRequired) body.workspaceId = selectedWorkspaceId;
			if (!selectedRuleConfig?.objectIdNotRequired) body.objectId = objectId || null;
			if (selectedRuleConfig?.fieldIdRequired) body.fieldId = toFieldId(selectedField);
			if (selectedRecord && !selectedRuleConfig?.recordIdNotRequired) body.recordId = toRecordId(selectedRecord);
			if (rule) {
				updateNotificationRule([rule.id, body])
					.unwrap()
					.then(() => {
						toast.triggerMessage({ messageKey: "notification_rule_updated", type: "success" });
						onOpenChange(false);
					})
					.catch(() => {
						toast.triggerMessage({ messageKey: "notification_rule_updated", type: "error" });
					});
			} else {
				createNotificationRule(body)
					.unwrap()
					.then(() => {
						toast.triggerMessage({ messageKey: "notification_rule_created", type: "success" });
						onOpenChange(false);
					})
					.catch(() => {
						toast.triggerMessage({ messageKey: "notification_rule_created", type: "error" });
					});
			}
		},
		[updateNotificationRule, toast, onOpenChange, createNotificationRule]
	);

	return { onFormSubmit, isCreating, isUpdating };
}
