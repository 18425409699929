import { skipToken } from "@reduxjs/toolkit/query";
import { useGetRecordQuery } from "../../../../records";
import { useGetObjectById } from "../../../../../hooks";
import { ChannelMessageParams, getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { TipTapContentWrapper } from "./TipTapContentWrapper";
import { useChannelMessage } from "../../../../messaging";
import { useEventContext } from "../../../hooks/useEventContext";

export function LazyChannelMessagePayload() {
	const { event } = useEventContext();
	const { channelArn, messageId } = event.params as ChannelMessageParams;

	const { isLoading: isLoadingMessage, channelMessage } = useChannelMessage(channelArn, messageId);

	const { data: user, isLoading: isLoadingUser } = useGetRecordQuery(event.user_id ?? skipToken);
	const { sdObject: userObject } = useGetObjectById(user?._objectDefId);

	return (
		<TipTapContentWrapper
			isLoadingCreatedUserName={isLoadingUser}
			createdUserName={userObject && user ? getSDRecordName(userObject, user) : undefined}
			isLoadingContent={isLoadingMessage}
			tipTapContent={channelMessage?.Content}
		/>
	);
}
