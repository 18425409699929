import { useRef } from "react";
import clsx from "clsx";
import { JSONContent } from "@tiptap/core";

import { Button, Icon, Spinner } from "@salesdesk/daisy-ui";
import { ChannelMessageMetadata } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useInfiniteScrollContainer } from "../../../../../hooks/ui";
import { ChannelMessageGroup, LoadingStatus } from "../../../types";

import { ThemableSvgLoader } from "../../../../graphics/CustomSvgLoader";
import { ChimeChannelMessagesLoadingView } from "./ChimeChannelMessagesLoadingView";
import { ChimeMessageGroupContainer } from "./ChimeMessageGroupContainer";
import { ChimeChatInput } from "./ChimeChannelMessages";
import { ChimeChannelErrorView } from "./ChimeChannelErrorView";

interface ChatChannelViewProps {
	channelArn?: string;
	messageGroups: ChannelMessageGroup[];
	sendMessage: (message?: JSONContent, metadata?: ChannelMessageMetadata) => void;
	onDeleteMessage?: (messageId: string) => void;
	loadNext: () => void;
	onRetry: () => void;
	readonly?: boolean;
	autofocusInput?: boolean;

	chatStatus?: LoadingStatus;
	nextMessagesStatus?: LoadingStatus;
}

export function ChatChannelView({
	channelArn,
	messageGroups,
	sendMessage,
	onDeleteMessage,
	loadNext,
	onRetry,
	readonly,
	autofocusInput,

	chatStatus = LoadingStatus.Loading,
	nextMessagesStatus = LoadingStatus.Loaded,
}: ChatChannelViewProps) {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { containerBottomRef: scrollContainerTopRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: loadNext,
	});

	const isChatLoading = chatStatus === LoadingStatus.Loading;
	const isChatErrored = chatStatus === LoadingStatus.Error;
	const isChatLoaded = chatStatus === LoadingStatus.Loaded;

	return (
		<div className="relative flex h-full w-full flex-col overflow-hidden">
			<div className="flex h-full w-full flex-col justify-between overflow-hidden pb-4 pl-3">
				<div className="flex flex-col gap-4 pr-3">
					{messageGroups.length === 0 && isChatLoaded ? (
						<div className="mt-12 flex w-full flex-col items-center gap-3 p-4">
							<ThemableSvgLoader name="chat_graphic" />
							<div className="text-c_text_placeholder text-body-sm text-center">
								{readonly ? "There are no messages in this chat" : "Type a message below to start the conversation"}
							</div>
						</div>
					) : null}
				</div>

				<div
					ref={scrollContainerRef}
					className={clsx(
						"relative flex h-full flex-col-reverse gap-4 overflow-hidden pr-3",
						!isChatLoading && "overflow-y-auto"
					)}
				>
					{isChatLoading ? (
						<ChimeChannelMessagesLoadingView />
					) : isChatErrored ? (
						<ChimeChannelErrorView message="Failed to load messages" onRetry={onRetry} />
					) : (
						<div className="flex flex-col gap-4">
							<div ref={scrollContainerTopRef} />
							{nextMessagesStatus === LoadingStatus.Loading ? (
								<div className="flex h-12 items-center justify-center">
									<Spinner size="md" />
								</div>
							) : nextMessagesStatus === LoadingStatus.Error ? (
								<div className="text-label-xs text-c_danger_02 flex flex-col items-center justify-center gap-1">
									<div className="flex items-center gap-1">
										<Icon size="xs" className="flex" icon={ICONS.warningCircle} />
										Failed to load previous messages
									</div>
									<Button variant="danger_text" size="xs" startIcon={ICONS.arrowCounterClockwise} onClick={loadNext}>
										Try again
									</Button>
								</div>
							) : null}
							{messageGroups.map((messageGroup) => (
								<ChimeMessageGroupContainer
									key={messageGroup.messages[messageGroup.messages.length - 1].MessageId}
									messageGroup={messageGroup}
									onDeleteMessage={onDeleteMessage}
								/>
							))}
						</div>
					)}
				</div>
				{readonly ? null : (
					<ChimeChatInput
						channelArn={channelArn}
						sendMessage={sendMessage}
						disabled={!isChatLoaded}
						autofocus={autofocusInput}
					/>
				)}
			</div>
			{isChatLoaded && readonly ? (
				<div className="bg-c_bg_04 text-c_text_placeholder text-label-xs h-[65px] w-full shrink-0 pt-[18px] text-center">
					You are not a member of this workspace
				</div>
			) : null}
		</div>
	);
}
