import { transcriptSentimentColors, TranscriptSentimentType } from "../../../types";
import { capitalize } from "lodash";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";

interface TranscriptSentimentTooltipProps {
	activeSentiment: TranscriptSentimentType;
	transcriptBarElement: HTMLDivElement;
}

export function TranscriptSentimentTooltip({ activeSentiment, transcriptBarElement }: TranscriptSentimentTooltipProps) {
	const tooltipRef = useRef<HTMLDivElement>(null);
	const {
		faceSentimentGraphHolderRef,
		transcriptSentimentGraphRef,
		faceSentimentGraphOpen,
		videoDuration,
		viewStartTime,
		zoomFactor,
	} = useMediaAnalysisContext();
	const [tooltipLeft, setTooltipLeft] = useState<number>();

	const graphHolderRef = faceSentimentGraphOpen ? faceSentimentGraphHolderRef : transcriptSentimentGraphRef;
	const graphWidth = graphHolderRef.current?.getBoundingClientRect().width ?? 0;
	const graphOffsetLeft = (viewStartTime / videoDuration) * (graphWidth * zoomFactor);

	const tooltipElement = tooltipRef.current;
	useEffect(() => {
		const barLeft = transcriptBarElement.offsetLeft;
		const barWidth = transcriptBarElement.getBoundingClientRect().width;

		const graphViewEndX = graphOffsetLeft + graphWidth;
		const graphViewOffsetX = Math.max(barLeft - graphOffsetLeft, 0);

		const visibleBarLeft = Math.max(barLeft, graphOffsetLeft);
		const visibleBarRight = Math.min(barLeft + barWidth, graphViewEndX);
		const visibleBarWidth = visibleBarRight - visibleBarLeft;

		const tooltipWidth = tooltipElement?.getBoundingClientRect().width ?? 0;
		setTooltipLeft(Math.min(graphViewOffsetX + visibleBarWidth / 2 - tooltipWidth / 2, graphWidth - tooltipWidth - 10));
	}, [graphHolderRef, graphOffsetLeft, graphWidth, tooltipElement, transcriptBarElement]);

	return (
		<div
			ref={tooltipRef}
			className={clsx(
				"text-c_text_inverted text-label-xs shadow-tooltip absolute bottom-2 z-30 flex w-fit rounded-sm px-3 py-1",
				!tooltipElement && "hidden"
			)}
			onMouseMove={(e) => e.stopPropagation()}
			style={{
				left: tooltipLeft,
				backgroundColor: transcriptSentimentColors[activeSentiment],
			}}
		>
			{capitalize(activeSentiment.toLowerCase())}
		</div>
	);
}
