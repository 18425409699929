import { mInteractionMeetingDef, mTaskDef, mIssueDef } from "@salesdesk/salesdesk-model";
import {
	RecordQueryClauses,
	SDObject,
	getSDObjectSystemFieldByName,
	getSDObjectSystemSyncOptionFieldOptions,
} from "@salesdesk/salesdesk-schemas";
import { getUnixTimestamp } from "@salesdesk/salesdesk-utils";

import { FILTERS } from "../../../../../../../Filtering";

export const ASSOCIATION_CAROUSELS = [
	{
		titlePrefix: "Pending",
		associationObjectId: mTaskDef.ID,
		generateFilters: generatePendingTaskFilters,
	},
	{
		titlePrefix: "Pending",
		associationObjectId: mIssueDef.ID,
		generateFilters: generatePendingIssueFilters,
	},
	{
		titlePrefix: "Upcoming",
		associationObjectId: mInteractionMeetingDef.ID,
		generateFilters: generateUpcomingMeetingFilters,
	},
];

// Filters tasks by all status fields not set to complete
export function generatePendingTaskFilters(taskObject: SDObject): RecordQueryClauses[] {
	if (taskObject._id !== mTaskDef.ID) {
		return [];
	}

	const { field, options } = getSDObjectSystemSyncOptionFieldOptions(taskObject, mTaskDef.STATUS_FIELD_NAME);
	const completedStatusOption = options.find((option) => option.name === mTaskDef.COMPLETED_STATUS_NAME);

	if (!completedStatusOption || completedStatusOption.id === undefined) {
		return [];
	}

	return [FILTERS.ss_not_is.generateSearchQuery(String(field?._id), [completedStatusOption.id])];
}

// Filters issues by all status fields not set to complete
export function generatePendingIssueFilters(taskObject: SDObject): RecordQueryClauses[] {
	if (taskObject._id !== mIssueDef.ID) {
		return [];
	}

	const { field, options } = getSDObjectSystemSyncOptionFieldOptions(taskObject, mIssueDef.STATUS_FIELD_NAME);
	const completedStatusOption = options.find((option) => option.name === mIssueDef.CLOSED_STATUS_NAME);

	if (!completedStatusOption || completedStatusOption.id === undefined) {
		return [];
	}

	return [FILTERS.ss_not_is.generateSearchQuery(String(field?._id), [completedStatusOption.id])];
}

// Filters meetins that are end after the current time
export function generateUpcomingMeetingFilters(meetingObject: SDObject): RecordQueryClauses[] {
	if (meetingObject._id !== mInteractionMeetingDef.ID) {
		return [];
	}

	const timeField = getSDObjectSystemFieldByName(meetingObject, mInteractionMeetingDef.TIME_FIELD_NAME);
	if (!timeField) {
		return [];
	}

	const currentTime = getUnixTimestamp(new Date());
	const clause = FILTERS.tr_is_after.generateSearchQuery(String(timeField._id), currentTime);
	return clause ? [clause] : [];
}
