import { useCallback } from "react";

import { VideoEventParams } from "@salesdesk/salesdesk-model";

import { usePostEvent } from "../../../../events/hooks/usePostEvent";
import { VideoEvent } from "../types";

export function useVideoEvents(recordId: number | undefined, fileId: number | undefined) {
	const postEvent = usePostEvent();

	return useCallback(
		(eventType: VideoEvent, timecode?: number) => {
			if (recordId === undefined || fileId === undefined) {
				return;
			}
			postEvent({
				event_type: eventType,
				record_id: recordId,
				params: {
					fileId,
					timecode,
				} satisfies VideoEventParams,
			});
		},
		[fileId, postEvent, recordId]
	);
}
