import { ICONS } from "@salesdesk/salesdesk-ui";

import { useVideoCallMediaContext } from "../VideoCallProvider";
import { Tooltip, Button } from "@salesdesk/daisy-ui";

interface ToggleAudioButtonProps {
	disabled?: boolean;
}

export function ToggleBlurButton({ disabled = false }: ToggleAudioButtonProps) {
	const { backgroundSettings, setBackgroundSettings } = useVideoCallMediaContext();
	const hasBackgroundImage = backgroundSettings.type === "image";
	const isBlurEnabled = backgroundSettings.type === "blur";

	return (
		<Tooltip text={isBlurEnabled ? "Turn off background blur" : "Turn on background blur"} placement="top">
			<Button
				variant="secondary"
				active={isBlurEnabled}
				onClick={() => setBackgroundSettings({ type: isBlurEnabled ? "none" : "blur", index: 0 })}
				disabled={hasBackgroundImage || disabled}
				startIcon={ICONS.userBlur}
			/>
		</Tooltip>
	);
}
