import { Editor } from "@tiptap/core";
import { useState } from "react";

import { BASE_COLORS } from "@salesdesk/salesdesk-ui";

import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../../../components/Popover";
import { TEXT_COLOR_OPTIONS, TEXT_HIGHLIGHT_OPTIONS } from "../../utils";
import { TextColorSwatch } from "./TextColorSwatch";
import { TextColorButton } from "./TextColorButton";
import { Transition } from "@headlessui/react";
import { SELECT_POPOVER_TRANSITION } from "../../../../../../../inputs";

interface TextHighlightOption {
	editor: Editor;
}

export function TextColorPopover({ editor }: TextHighlightOption) {
	const [isOpen, setIsOpen] = useState(false);

	const currentTextColor = TEXT_COLOR_OPTIONS.find((option) => option.isActive(editor))?.color;
	const currentHighlightColor = TEXT_HIGHLIGHT_OPTIONS.find((option) => option.isActive(editor))?.color;

	return (
		<Popover
			placement="bottom-start"
			open={isOpen}
			onOpenChange={setIsOpen}
			keepPopoverMounted
			hideWhenClosedButMounted={false}
		>
			<PopoverTrigger>
				<TextColorButton
					tooltip={isOpen ? undefined : "Color and highlighting"}
					currentTextColor={currentTextColor}
					currentHighlightColor={currentHighlightColor}
				/>
			</PopoverTrigger>
			<PopoverContent initialFocus={-1}>
				<Transition {...SELECT_POPOVER_TRANSITION} show={isOpen}>
					<PopoverContainer>
						<div className="flex w-[224px] flex-col gap-2 px-2 py-1">
							<div>
								<div className="text-body-sm text-c_text_secondary mb-2">Text color</div>
								<div className="flex flex-wrap gap-3">
									{TEXT_COLOR_OPTIONS.map((option) => (
										<TextColorSwatch
											key={option.color}
											onClick={() => option.action(editor)}
											isActive={currentTextColor === option.color}
										>
											<span style={{ color: option.color ?? undefined }}>A</span>
										</TextColorSwatch>
									))}
								</div>
							</div>
							<div>
								<div className="text-body-sm text-c_text_secondary mb-2">Text Highlight</div>
								<div className="flex flex-wrap gap-3">
									{TEXT_HIGHLIGHT_OPTIONS.map((option) => (
										<TextColorSwatch
											key={option.color}
											onClick={() => option.action(editor)}
											isActive={currentHighlightColor === option.color}
											bgColor={option.color ?? BASE_COLORS.c_bg_disabled_01}
										>
											{option.color ? null : (
												<div className="bg-c_bg_01 absolute -left-0.5 -right-0.5 top-[46%] h-0.5 rotate-45" />
											)}
										</TextColorSwatch>
									))}
								</div>
							</div>
						</div>
					</PopoverContainer>
				</Transition>
			</PopoverContent>
		</Popover>
	);
}
