import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions/textAppenders";
import { idArraySchema } from "../../components/idArraySchema";

export const objectParentIdsSchema = asConst({
	...idArraySchema,
	title: "SDObject Parent SDObject Ids",
	description: appendNotEditable(
		"The ids of SDObjects whose records are allowed to be parents of this SDObject's records"
	),
});
