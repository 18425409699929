import { useCallback, useMemo } from "react";
import userflow from "userflow.js";

import { getSDRecordName, Field, SDObject, SDRecord, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";
import { mUserDef } from "@salesdesk/salesdesk-model";

import { getFormValuesFromRecord, useRecordForm } from "../../../records";
import { RECORD_FIELDS_KEY, getFormValuesByPrefix } from "../../../records";
import { useUpdateRecordMutation } from "../../../records";
import { useToast } from "../../../Toasts";
import { useUserback } from "@userback/react";
import { isEqual } from "lodash";

const hiddenFieldNames = [
	mUserDef.LOGIN_AUTHORIZED_FIELD_NAME,
	mUserDef.ROLES_FIELD_NAME,
	mUserDef.LAST_ACTIVE_FIELD_NAME,
	mUserDef.LOGIN_ENABLED_TIME_FIELD_NAME,
];

export function useUpdateUserProfile(userSDObject: SDObject, userSDRecord: SDRecord) {
	const [updateRecord, { isLoading }] = useUpdateRecordMutation();
	const toast = useToast();
	const { setName } = useUserback();

	const { formId, fields, onFormStateChange, uploadProgressStatus, updateUploadProgressStatus } = useRecordForm({
		sdObject: userSDObject,
	});

	const { avatarField, editableFields } = useMemo(() => {
		let avatarField: Field | null = null;
		const editableFields: Field[] = [];

		for (const field of fields) {
			const isAvatarField = field._name === mUserDef.PHOTO_FIELD_NAME;

			if (isAvatarField) {
				avatarField = field;
			} else if (!hiddenFieldNames.includes(field._name)) {
				editableFields.push(field);
			}
		}

		return { avatarField, editableFields };
	}, [fields]);

	const submitUpdateProfile = useCallback(
		(profileData: Record<string, any>, resetForm: (formValues?: Record<string, any>) => void) => {
			const recordData = getFormValuesByPrefix(profileData, RECORD_FIELDS_KEY);

			updateRecord({
				record: userSDRecord,
				updatedFields: fields
					.filter(
						(field) =>
							!hiddenFieldNames.includes(field._name) &&
							!isEqual(recordData[field._id], getSDRecordFieldValue(userSDRecord, field._id)?._value)
					)
					.map((field) => {
						const value = recordData[field._id];
						return { _fieldId: field._id, _value: value === undefined || value === "" ? null : (value as unknown) };
					}),
			})
				.unwrap()
				.then((updatedData) => {
					const newUserName = getSDRecordName(userSDObject, updatedData);

					// Updates the user's name in userflow and userback
					userflow.updateUser({ name: newUserName });
					setName(newUserName);

					toast.triggerMessage({ type: "success", messageKey: "record_updated" });
					resetForm(getFormValuesFromRecord(fields, updatedData));
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_updated" });
				});
		},
		[updateRecord, fields, userSDRecord, userSDObject, setName, toast]
	);

	return {
		formProps: {
			id: formId,
			fields: editableFields,
			onSubmit: submitUpdateProfile,
			onFormStateChange,
			updateUploadProgressStatus,
			defaultValues: getFormValuesFromRecord(fields, userSDRecord),
			sdRecord: userSDRecord,
			sdObject: userSDObject,
		},
		uploadProgressStatus,
		avatarField,
		isLoading,
	};
}
