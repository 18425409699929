import { mSystemObjectDef } from "../system_object_def";
import { ASSET_IDS } from "./asset_constants";

export class mAssetDef extends mSystemObjectDef {
	static CLASS_NAME = "mAssetDef";
	static NAME = "Asset";
	static PLURAL_NAME = "Assets";
	static ICON = "ph-folders";

	static ID = ASSET_IDS.ASSET;

	static FORMAT_DESCRIPTION = "File required";

	static TYPES = {
		SALES_MATERIAL: "Sales Material",
		RECORDED_VIDEO: "Recorded Video",
		RECORDED_MEETING: "Recorded Call",
		OTHER: "Other",
	};

	static FILE_FIELD_NAME = "file";
	static MEDIA_TYPE_FIELD_NAME = "mediaType";
	static MEDIA_TYPE_SALES_MATERIAL_ID = 1;
	static MEDIA_TYPE_RECORDED_VIDEO_ID = 2;
	static MEDIA_TYPE_RECORDED_MEETING_ID = 3;
	static MEDIA_TYPE_OTHER_ID = 4;
	static TAG_FIELD_NAME = "tag";
	static SIZE_FIELD_NAME = "size";
	static FILENAME_FIELD_NAME = "filename";

	constructor(ownerId) {
		super(mAssetDef.ID, ownerId);
	}

	isAsset() {
		return true;
	}

	//JF - we need this function here so that all subclasses of mAssetDef resolve to the same
	// asset path - /assets/. This means all asset types can exist in the same area of the system

	get pathName() {
		return mAssetDef.PLURAL_NAME.toLowerCase();
	}
}
