import { forwardRef, PropsWithChildren, ComponentProps } from "react";

import { BaseSelectButtonProps } from "../../types";
import { Button } from "@salesdesk/daisy-ui";

type FixedSelectButtonProps = BaseSelectButtonProps & ComponentProps<typeof Button>;

export const FixedSelectButton = forwardRef<HTMLButtonElement, PropsWithChildren<FixedSelectButtonProps>>(
	({ variant = "secondary", size = "sm", selectedOption, placeholder, children, ...restProps }, ref) => (
		<Button ref={ref} variant={variant} size={size} {...restProps}>
			{children}
		</Button>
	)
);
