import { asConst } from "json-schema-to-ts";
import { recordNameSchema, recordOwnerSchema } from "./properties";
import { fieldValueGroupSchema } from "./field";

export const sdRecordPatchRequestSchema = asConst({
	title: "SDRecordPatchRequest",
	description: "Defines a record schema used for patching requests",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	required: [],
	properties: {
		// TODO: Remove all _
		_name: recordNameSchema,
		_ownerId: recordOwnerSchema,
		_dataInst: fieldValueGroupSchema, //TODO: Rename fieldValues and should just be an array of fieldValues
	},
});
