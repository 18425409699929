import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";

export const sdApiKeyCreateRequestSchema = asConst({
	title: "sdApiKeyCreateRequestSchema",
	description: "The schema used for creating API keys",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	required: ["name"],
	properties: {
		name: {
			...stringSchema,
			title: "API Key Name",
			description: "Human-readable name of API Key",
			maxLength: 100,
		},
	},
});
