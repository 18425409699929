import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";
import { objectIdSchema } from "../object";

export const bookmarkCreateRequestSchema = asConst({
	title: "Bookmark Create Request Schema",
	description: "The schema used for creating bookmarks",
	type: "object",
	additionalProperties: false,
	required: ["name", "objectId", "value"],
	properties: {
		name: {
			...stringSchema,
			title: "Bookmark Name",
			description: "Name of bookmark",
		},
		objectId: {
			...objectIdSchema,
			title: "Bookmark object ID",
			description: "Object ID of bookmark",
		},
		value: {
			...stringSchema,
			title: "Bookmark value",
			description: "Value of bookmark",
		},
	},
});
