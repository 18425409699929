import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import {
	getSDRecordName,
	getSDRecordSystemFieldValueByFieldName,
	getUserRecordAvatarFileId,
} from "@salesdesk/salesdesk-schemas";

import { mUserDef } from "@salesdesk/salesdesk-model";
import { createUniqueId } from "@salesdesk/salesdesk-utils";

import { useUserObjectsMap } from "../../../../../../users";
import { useGetConferenceUsersQuery, useGetLiveParticipantsQuery } from "../../../../../api/meetingsApi";
import { useMeetingPageContext } from "../../../hooks";

import { useWebPrincipal, WebPrincipal } from "../../../../../../../auth";
import { useVideoCallLogicContext } from "../../../../VideoCallProvider";
import { ParticipantIdentityUserStatus, ParticipantUserAndStatus } from "../types";

export function useMeetingParticipants(): ParticipantUserAndStatus[] | undefined {
	const principal = useWebPrincipal();
	const userObjectsMap = useUserObjectsMap();

	const { meetingId, meetingRecord, participantRecords } = useMeetingPageContext();
	const { currentMeetingRecord, callParticipantDetails } = useVideoCallLogicContext();

	const inMeeting = currentMeetingRecord != null;

	const { data: liveParticipantIdentities } = useGetLiveParticipantsQuery(inMeeting ? skipToken : meetingId, {
		pollingInterval: 3000,
	});
	const { data: participantIdentityUsers } = useGetConferenceUsersQuery(
		liveParticipantIdentities != null && !inMeeting ? liveParticipantIdentities : skipToken
	);

	return useMemo(() => {
		if (participantRecords == null || userObjectsMap == null) return undefined;

		const participants: ParticipantIdentityUserStatus[] = [];

		const participantsOnCall = inMeeting
			? callParticipantDetails.map(({ participant, sdRecord }) => ({
					identity: participant.identity,
					userRecord: sdRecord,
			  }))
			: participantIdentityUsers;

		participantRecords.forEach((participantRecord) => {
			const participantObject = userObjectsMap.get(participantRecord._objectDefId);

			if (participantObject == null) return;

			const email = getSDRecordSystemFieldValueByFieldName(
				participantObject,
				participantRecord,
				mUserDef.EMAIL_FIELD_NAME
			)?._value as string;

			const onCall =
				Boolean(participantsOnCall?.find((onCallParticipant) => onCallParticipant.identity === email)) || false;

			participants.push({
				identity: email,
				userRecord: participantRecord,
				onCall,
				isOrganiser: meetingRecord?._ownerId === participantRecord._id,
			});
		});

		if (participantsOnCall != null) {
			for (const participant of participantsOnCall) {
				if (participants.some((p) => p.identity === participant.identity)) {
					continue;
				}

				participants.push({
					identity: participant.identity,
					userRecord: participant.userRecord || null,
					onCall: true,
					isOrganiser: meetingRecord?._ownerId === participant.userRecord?._id,
				});
			}
		}

		return participants
			.map((participant) => {
				let avatarFileId: number | undefined;
				let userRecordName: string | undefined;

				if (participant.userRecord != null) {
					const participantObject = userObjectsMap.get(participant.userRecord._objectDefId);

					avatarFileId = getUserRecordAvatarFileId(participantObject, participant.userRecord);
					userRecordName = participantObject ? getSDRecordName(participantObject, participant.userRecord) : "";
				}

				return {
					email: participant.identity,
					user: {
						id: participant.userRecord?._id || createUniqueId(),
						fullName: userRecordName || participant.identity,
						avatarFileId,
					},
					onCall: participant.onCall,
					isOrganiser: participant.isOrganiser,
				};
			})
			.sort(getParticipantSort(principal));
	}, [
		participantRecords,
		userObjectsMap,
		inMeeting,
		callParticipantDetails,
		participantIdentityUsers,
		meetingRecord,
		principal,
	]);
}

function getParticipantSort(principal: WebPrincipal) {
	return (p1: ParticipantUserAndStatus, p2: ParticipantUserAndStatus) => {
		// Current User Last
		if (p1.user?.id === principal.UserRecordId) return 1;
		if (p2.user?.id === principal.UserRecordId) return -1;

		// Organiser first
		if (p1.isOrganiser) return -1;
		if (p2.isOrganiser) return 1;

		// Then On Call Users
		if (p1.onCall !== p2.onCall) return p1.onCall ? -1 : 1;

		// Then by name (or email if not an participant)
		return p1.user.fullName.toLowerCase().localeCompare(p2.user.fullName.toLowerCase());
	};
}
