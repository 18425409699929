import { MutableRefObject, useEffect } from "react";
import { Editor, EditorContent, JSONContent } from "@tiptap/react";
import clsx from "clsx";

import { useSDEditor } from "../../../../inputs";
import { tw } from "../../../../../utils/tailwind-helpers";
import { PLACEHOLDER_COMMENT_ID, useDocumentCommentsContext } from "../../DocumentComments";
import { getDocumentExtensions } from "../utils";
import { DocumentBubbleMenu } from "./DocumentBubbleMenu";

interface DocumentEditorProps {
	value?: JSONContent;
	onChange?: (jsonValue: JSONContent) => void;
	disabled?: boolean;
	isSmallPreview?: boolean;
	editorRef: MutableRefObject<Editor | null>;
}

export function DocumentEditor({ value, onChange, disabled, isSmallPreview = false, editorRef }: DocumentEditorProps) {
	const isEditable = Boolean(!disabled && onChange);
	const { onActiveCommentsChangeRef } = useDocumentCommentsContext();

	const onActiveCommentChange = (commentIds: string[]) => {
		onActiveCommentsChangeRef?.current(commentIds);
	};

	const editor = useSDEditor(
		{
			extensions: getDocumentExtensions(isEditable, onActiveCommentChange),
			content: value,
			onUpdate: ({ editor }) => {
				if (onChange) {
					onChange(editor.getJSON());
				}
			},
			editorProps: {
				attributes: {
					class: clsx(tw`w-full`, isSmallPreview ? tw`zoom-50` : tw`highlight-comments`),
				},
			},
			editable: isEditable,
		},
		[]
	);

	editorRef.current = editor;

	useEffect(() => {
		if (editorRef.current && isEditable) {
			editorRef.current.commands.focus();
		}
	}, [editorRef, isEditable]);

	useEffect(() => {
		requestAnimationFrame(() => {
			editorRef.current?.commands.removeComment(PLACEHOLDER_COMMENT_ID);
		});
	}, [editorRef]);

	useEffect(() => {
		editorRef.current?.setOptions({ editable: isEditable });
	}, [disabled, editorRef, isEditable]);

	return (
		<>
			<DocumentBubbleMenu editor={editor} />
			<EditorContent className={clsx("flex", isEditable && "cursor-text")} editor={editor} />
		</>
	);
}
