import { getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

export function getWhoUserText(
	isLoadingMentionedUser: boolean,
	whoUserObject: SDObject | undefined,
	whoUserRecord: SDRecord | undefined,
	whoUserIsYou: boolean
) {
	if (whoUserObject && whoUserRecord) {
		return getSDRecordName(whoUserObject, whoUserRecord);
	}
	if (!isLoadingMentionedUser && whoUserIsYou) {
		return "you";
	}
	return "someone";
}
