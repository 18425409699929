import { useCallback, useEffect, useMemo, useState } from "react";

import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { SDRecord, getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { ProgressStepper } from "../../../../../../components/ProgressStepper/ProgressStepper";
import { Button } from "@salesdesk/daisy-ui";
import { useGetObjectById } from "../../../../../../hooks/useGetObjectById";
import { DirectedSDObjectAssociation, useObjectAssociations } from "../../../../../recordAssociations";
import { AlertDialog, Dialog, DialogContent, DialogHeader } from "../../../../../Dialog";
import { AssociationRecordsToLinkMap, OriginRecordDetails } from "../../types";
import { useCreateWorkspace } from "../../hooks/useCreateWorkspace";

import { BasicInfoPanel } from "./BasicInfoPanel/BasicInfoPanel";
import { AddRecordsPanel } from "./AddRecordsPanel/components/AddRecordsPanel";
import { AddTeamMembersPanel } from "./AddTeamMembersPanel/AddTeamMembersPanel";
import { ConfirmationPanel } from "./ConfirmationPanel";

interface CreateWorkspaceModalProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	originRecordDetails: OriginRecordDetails;
}

const STEPS = ["Step 1: Basic info", "Step 2: Add records", "Step 3: Team members", "Confirm"];

export function CreateWorkspaceFromOriginModal({ open, onOpenChange, originRecordDetails }: CreateWorkspaceModalProps) {
	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);
	const { originRecord, originRecordObject } = originRecordDetails;

	const originRecordObjectID = useMemo(() => [originRecordObject._baseType], [originRecordObject]);
	const {
		objectAssociations: { prioritizedAssociations: originRecordAssociations },
	} = useObjectAssociations(mWorkspaceDef.ID, originRecordObjectID);

	const { createWorkspace, isCreating } = useCreateWorkspace(() => onOpenChange(false));

	const [currentStep, setCurrentStep] = useState(0);

	const [isDirty, setIsDirty] = useState(false);

	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	const [alertDialogTitle, setAlertDialogTitle] = useState("");
	const [alertDialogMessage, setAlertDialogMessage] = useState("");

	const [workspaceRecordData, setWorkspaceRecordData] = useState<Record<string, any>>();

	const [associationRecordsToLinkMap, setAssociationRecordsToLinkMap] = useState<AssociationRecordsToLinkMap>({});

	const updateAssociationRecordsToLink = useCallback(
		(objectAssociation: DirectedSDObjectAssociation, records: SDRecord[]) => {
			const associationId = objectAssociation.id;

			setAssociationRecordsToLinkMap((prevRecords) => {
				const updatedRecords = { ...prevRecords };

				if (records.length > 0) {
					updatedRecords[associationId] = { association: objectAssociation, records };
				} else {
					delete updatedRecords[associationId];
				}

				return updatedRecords;
			});
		},
		[]
	);

	// Adds the origin record to the association records to link
	useEffect(() => {
		if (!originRecordAssociations.length) {
			return;
		}

		// TODO: If there's no origin record association, we should show an error message & not allow the user to proceed
		updateAssociationRecordsToLink(originRecordAssociations[0], [originRecord]);
	}, [updateAssociationRecordsToLink, originRecord, originRecordAssociations]);

	useEffect(() => {
		if (open) {
			return;
		}

		setCurrentStep(0);
		setIsDirty(false);
		setAlertDialogOpen(false);
		setWorkspaceRecordData(undefined);
	}, [open]);

	if (!open || !originRecordDetails || !workspaceObject) {
		return null;
	}

	const closeDialog = () => {
		if (!isDirty) {
			onOpenChange(false);
			return;
		}

		setAlertDialogTitle("Unsaved changes!");
		setAlertDialogMessage("You have unsaved changes, are you sure you want to close this form?");
		setAlertDialogOpen(true);
	};

	return (
		<>
			<Dialog
				open={open}
				onOpenChange={(open) => {
					if (!open) {
						closeDialog();
					}
				}}
			>
				<DialogContent>
					<div className="flex w-full">
						<div className="flex h-[800px] max-h-full w-[850px] max-w-full flex-col gap-8">
							<DialogHeader
								title={`New ${workspaceObject._displayName}`}
								description={
									<>
										Create a collaborative {workspaceObject._displayName} for "
										<b>{getSDRecordName(originRecordObject, originRecord)}</b>".
									</>
								}
								onClose={() => closeDialog()}
							/>
							<ProgressStepper currentStep={currentStep} steps={STEPS} />
							<BasicInfoPanel
								workspaceObject={workspaceObject}
								updateFormDirtyState={setIsDirty}
								updateRecordData={setWorkspaceRecordData}
								isVisible={currentStep === 0}
								previousStep={() => closeDialog()}
								nextStep={() => setCurrentStep(1)}
								originRecordDetails={originRecordDetails}
							/>
							<AddRecordsPanel
								workspaceObject={workspaceObject}
								associationRecordsToLinkMap={associationRecordsToLinkMap}
								updateAssociationRecordsToLink={updateAssociationRecordsToLink}
								isVisible={currentStep === 1}
								nextStep={() => setCurrentStep(2)}
								previousStep={() => setCurrentStep(0)}
								originRecordDetails={originRecordDetails}
							/>
							<AddTeamMembersPanel
								workspaceObject={workspaceObject}
								updateAssociationRecordsToLink={updateAssociationRecordsToLink}
								isVisible={currentStep === 2}
								previousStep={() => setCurrentStep(1)}
								nextStep={() => setCurrentStep(3)}
								originRecordDetails={originRecordDetails}
							/>
							<ConfirmationPanel
								workspaceObject={workspaceObject}
								workspaceRecordData={workspaceRecordData}
								associationRecordsToLinkMap={associationRecordsToLinkMap}
								isVisible={currentStep === 3}
								previousStep={() => setCurrentStep(2)}
								nextStep={() => {
									createWorkspace(associationRecordsToLinkMap, workspaceRecordData, workspaceObject);
								}}
								isCreating={isCreating}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
			<AlertDialog
				open={alertDialogOpen}
				onOpenChange={setAlertDialogOpen}
				title={alertDialogTitle}
				message={alertDialogMessage}
			>
				<div className="mt-8 flex justify-end gap-3">
					<Button variant="text" onClick={() => setAlertDialogOpen(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							onOpenChange(false);
							setAlertDialogOpen(false);
						}}
					>
						Discard changes
					</Button>
				</div>
			</AlertDialog>
		</>
	);
}
