import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useContext, createContext } from "react";

interface ToggleUserLoginContextProps {
	setUserRecordToInvite: (record?: SDRecord, isReinvite?: boolean) => void;
	setUserRecordToDeactivateLogin: (record?: SDRecord) => void;
}

const initialValues: ToggleUserLoginContextProps = {
	setUserRecordToInvite: () => undefined,
	setUserRecordToDeactivateLogin: () => undefined,
};

export const ToggleUserLoginContext = createContext<ToggleUserLoginContextProps>(initialValues);

export function useToggleUserLoginContext(): ToggleUserLoginContextProps {
	const context = useContext(ToggleUserLoginContext);

	if (context === undefined) {
		throw new Error("useToggleUserLoginContext must be used within a ToggleUserLoginContext.Provider");
	}

	return context;
}
