import { useState, useEffect, forwardRef } from "react";

import { LogoInput } from "../../../../../inputs";
import { UploadProgressStatus } from "../../FileField";
import { useStoreFile } from "../../../../../files";
import { EditFieldProps } from "../../../../types";
import { useLogoFieldValue } from "../hooks/useLogoFieldValue";
import { LogoFieldValue } from "../types";

interface EditLogoFieldProps extends Omit<EditFieldProps, "onChange" | "value"> {
	value?: LogoFieldValue;
	onChange: (newValue: number | null) => void;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
	isPublic?: boolean;
}

export const EditLogoField = forwardRef<HTMLInputElement, EditLogoFieldProps>(
	({ value, onChange, updateUploadProgressStatus, disabled, isPublic = false, ...fileInputProps }, ref) => {
		const { fileId, companyName } = useLogoFieldValue(value);
		const storeFile = useStoreFile(isPublic);

		const [uploadProgress, setUploadProgress] = useState<number | undefined>();

		const uploadProgressStatus = uploadProgress ? "in_progress" : "idle";

		useEffect(() => {
			if (updateUploadProgressStatus) {
				updateUploadProgressStatus(uploadProgressStatus);
			}
		}, [uploadProgressStatus, updateUploadProgressStatus]);

		return (
			<LogoInput
				ref={ref}
				value={fileId}
				companyName={companyName}
				onChange={(newFile) => {
					if (!newFile) {
						onChange(null);
						return;
					}

					// TODO: How do we handle file uploads failing?
					storeFile(newFile, (uploadProgress) => {
						setUploadProgress(uploadProgress);
					})
						.then((sdFileId) => {
							onChange(sdFileId);
						})
						.finally(() => {
							setUploadProgress(undefined);
						});
				}}
				{...fileInputProps}
				disabled={uploadProgressStatus === "in_progress" || disabled}
				uploadProgress={uploadProgress}
			/>
		);
	}
);
