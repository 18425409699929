import {
	FIELD_CREATION_TYPE,
	isNumericField,
	mAssetDef,
	mDocDef,
	mInteractionMeetingDef,
	mObjectDef,
	ObjectDefFactory,
	SORTABLE_FIELD_TYPES,
} from "@salesdesk/salesdesk-model";
import {
	Field,
	getAllSDObjectFields,
	getSDObjectFields,
	SDObject,
	SDObjectCreateRequest,
	sdObjectIsBaseType,
	SDObjectUpdateRequest,
} from "@salesdesk/salesdesk-schemas";

import { FieldMap, GroupableFields } from "../types";
import { isMediaField, isSingleOptionField, isTimeField } from "../../fields";

export function getSDObjectId(sdObject: SDObject) {
	return sdObject._id;
}

export function getSDObjectFieldMap(sdObject: SDObject) {
	const fieldMap: FieldMap = {};

	getSDObjectFields(sdObject).forEach((field) => {
		fieldMap[field._id] = field;
	});

	return fieldMap;
}

export function getSDObjectGroupableFields(sdObject: SDObject) {
	const groupableFields: GroupableFields = { timeFields: [], singleOptionFields: [] };

	getSDObjectFields(sdObject).forEach((field) => {
		if (field._hidden) {
			return;
		}

		if (isSingleOptionField(field)) {
			groupableFields.singleOptionFields.push(field);
		} else if (isTimeField(field)) {
			groupableFields.timeFields.push(field);
		}
	});

	return groupableFields;
}

export function getSDObjectAggregatableFields(sdObject: SDObject) {
	return getSDObjectFields(sdObject).filter((field) => isNumericField(field._type));
}

export function generateObjectDefFromSDObject(sdObject: SDObject) {
	return ObjectDefFactory.newDefinition(sdObject._className).marshall(sdObject) as mObjectDef;
}

export function isBaseTypeOrInteractionMeeting(sdObject: SDObject) {
	return sdObjectIsBaseType(sdObject) || sdObject._id === mInteractionMeetingDef.ID;
}

export function getSDObjectMediaFieldIds(sdObject: SDObject) {
	return getSDObjectFields(sdObject).reduce(
		(mediaFields, field) => {
			// Treat the Document field of the Document e as a media field
			if (isMediaField(field) || (sdObject._id === mDocDef.ID && field._name === mDocDef.DOCUMENT_FIELD_NAME)) {
				mediaFields.push(field._id);
			}

			return mediaFields;
		},
		[] as Field["_id"][]
	);
}

export function getSDObjectUpdateRequestData(sdObject: SDObject): SDObjectUpdateRequest {
	return {
		_displayName: sdObject._displayName,
		_pluralName: sdObject._pluralName,
		_description: sdObject._description,
		_color: sdObject._color,
		_icon: sdObject._icon,
		_commentsSupported: sdObject._commentsSupported,
		_historySupported: sdObject._historySupported,
		_activitySupported: sdObject._activitySupported,
		_searchable: sdObject._searchable,
		_defaultView: sdObject._defaultView,
		_dataDef: {
			...sdObject._dataDef,
			_children: getAllSDObjectFields(sdObject),
		},
	};
}

export function getSDObjectCreateRequestData(sdObject: SDObject): SDObjectCreateRequest {
	const CONTAINER_FIELD_POSTFIX = "Details";

	const name = sdObject._displayName.split(" ").join("");

	return {
		...sdObject,
		_name: name,

		_dataDef: {
			...sdObject._dataDef,
			_name: `${name}${CONTAINER_FIELD_POSTFIX}`,
			_pluralName: `${sdObject._pluralName}${CONTAINER_FIELD_POSTFIX}`,
			_displayName: `${sdObject._displayName} ${CONTAINER_FIELD_POSTFIX}`,

			_children: sdObject._dataDef._children.map((field) => ({ ...field, _creationType: FIELD_CREATION_TYPE.USER })),
		},
	};
}

export function getSortableSDObjectFields(sdObject: SDObject) {
	return getSDObjectFields(sdObject).filter((field: Field) =>
		SORTABLE_FIELD_TYPES.some((type) => type === field._type)
	);
}
