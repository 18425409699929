import { mSystemObjectDef } from "../system_object_def";

export class mTenantSettingsDef extends mSystemObjectDef {
	static CLASS_NAME = "mTenantSettingsDef";
	static NAME = "Tenant Settings";
	static PLURAL_NAME = "Tenant Settings";

	static SLACK_WEBHOOKS_NAME = "Slack webhook";

	static ID = 24;

	constructor(ownerId) {
		super(mTenantSettingsDef.ID, ownerId);
	}
}
