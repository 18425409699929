import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { BreadcrumbItem } from "../../../components/Breadcrumbs/Breadcrumbs";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { PATHS } from "../../../routes";
import { useWebPrincipal } from "../../../auth";
import { useWorkspaceContext } from "./useWorkspaceContext";

export function useGetWorkspaceBaseBreadcrumbs(isLoading?: boolean) {
	const principal = useWebPrincipal();
	const { workspaceRecord, workspaceObject } = useWorkspaceContext();
	const { pathname } = useLocation();

	return useMemo(() => {
		// Skeleton loading breadcrumbs
		if (!workspaceRecord || !workspaceObject || isLoading) {
			return [{ node: <Skeleton className="h-5 w-48" /> }, { node: <Skeleton className="h-5 w-28" /> }];
		}

		const crumbs: BreadcrumbItem[] = [
			{
				node: getSDRecordName(workspaceObject, workspaceRecord),
				link: PATHS.WORKSPACE_OVERVIEW(workspaceRecord._id),
			},
		];

		if (pathname.includes("/library/")) {
			crumbs.push({
				node: "Library",
				link: PATHS.WORKSPACE_LIBRARY(workspaceRecord._id),
			});
		}

		if (principal.IsSalesDeskUser) {
			crumbs.unshift({
				icon: workspaceObject._icon,
				node: workspaceObject._pluralName,
				link: PATHS.OBJECT_RECORD_BOARD(workspaceObject),
			});
		}

		return crumbs;
	}, [pathname, isLoading, principal.IsSalesDeskUser, workspaceObject, workspaceRecord]);
}
