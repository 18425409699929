import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { WorkspaceResources } from "./WorkspaceResources";

export function WorkspaceResourcesPage() {
	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<WorkspaceResources />
		</QueryParamProvider>
	);
}
