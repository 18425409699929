import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { MenuItem, PopoverMenu } from "../../../../menu";
import { useMemo, useState } from "react";
import {
	CreateRecordRuleDialogProps,
	CreateUserRuleDialogProps,
	NotificationRuleDialogContainer,
} from "../createNotificationRuleDialog/NotificationRuleDialogContainer";
import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { useNotificationRuleObjects } from "../../hooks/useNotificationRuleObjects";

export function CreateRuleButton() {
	const sdObjects = useNotificationRuleObjects();
	const [dialogProperties, setDialogProperties] = useState<
		Pick<CreateRecordRuleDialogProps, "type" | "objectId"> | Pick<CreateUserRuleDialogProps, "type">
	>();

	const objectMenuItems = useMemo(() => {
		if (!sdObjects) {
			return [];
		}
		return sdObjects.map(
			(object) =>
				({
					icon: object._icon,
					text: object._displayName,
					onClick: () => {
						setDialogProperties({ type: NotificationRuleType.Record, objectId: object._id });
					},
					type: "button",
				}) satisfies MenuItem
		);
	}, [sdObjects]);

	const menuItems: MenuItem[] = useMemo(
		() => [
			{
				badge: {
					text: "Record update",
					color: "c_warning_04",
					variant: "secondary",
					icon: ICONS.file,
				},
				type: "subMenu",
				subMenu: objectMenuItems,
			},
			{
				badge: {
					text: "User activity",
					color: "c_success_04",
					variant: "secondary",
					icon: ICONS.user,
				},
				type: "button",
				onClick: () => {
					setDialogProperties({ type: NotificationRuleType.User });
				},
			},
		],
		[objectMenuItems]
	);

	return (
		<>
			<PopoverMenu menuContents={menuItems}>
				<Button size="sm" startIcon={ICONS.plus}>
					Create rule
				</Button>
			</PopoverMenu>
			{dialogProperties ? (
				<NotificationRuleDialogContainer
					{...dialogProperties}
					onOpenChange={() => {
						setDialogProperties(undefined);
					}}
				/>
			) : null}
		</>
	);
}
