import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Divider } from "../../../../../../../../components/Divider/Divider";

interface HoldingPageDetailsSectionProps {
	title: ReactNode;
	hidden?: boolean;
	initialSection?: boolean;
}

export function HoldingPageDetailsSection({
	title,
	hidden = false,
	initialSection = false,
	children,
}: PropsWithChildren<HoldingPageDetailsSectionProps>) {
	return (
		<div className={clsx("flex flex-col gap-6", hidden && `hidden`)}>
			{initialSection ? null : <Divider className="mx-0" />}
			<div className="flex flex-col gap-4">
				<div className="text-label text-c_text_primary">{title}</div>
				<div>{children}</div>
			</div>
		</div>
	);
}
