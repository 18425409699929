import { asConst } from "json-schema-to-ts";
import { fieldValueValueSchema } from "..";
import { mPdfAssetDef } from "@salesdesk/salesdesk-model";
import { stringSchema } from "../../components";

export const pdfAssetFieldValueOverridesRequestSchema = asConst({
	title: "DocConvertToPdfRequestSchema",
	description: "The schema used for overriding Pdf Asset field values when creating a Pdf Asset from a Doc",
	type: "object",
	additionalProperties: false,
	nullable: true,
	required: [],
	properties: {
		nameValue: {
			...stringSchema,
			description: `Override for the PDF Asset ${mPdfAssetDef.NAME_FIELD_NAME} Field`,
		},
		mediaTypeValue: {
			...fieldValueValueSchema,
			description: `Override for the PDF Asset ${mPdfAssetDef.MEDIA_TYPE_FIELD_NAME} Field`,
		},
		tagValue: {
			...stringSchema,
			description: `Override for the PDF Asset ${mPdfAssetDef.TAG_FIELD_NAME} Field`,
		},
	},
});
