import {
	ConferenceRoomResponse,
	ParticipantIdentity,
	ParticipantIdentityUser,
	SDRecord,
	Username,
} from "@salesdesk/salesdesk-schemas";

import { SDApi } from "../../api";

export const meetingsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getLiveParticipants: builder.query<Username[], SDRecord["_id"]>({
				query: (meetingRecordId) => `/conferences/${meetingRecordId}/live-participants`,
				providesTags: (result, error, arg) => [{ type: "LiveParticipants", id: arg }],
			}),
			getConferenceUsers: builder.query<ParticipantIdentityUser[], ParticipantIdentity[]>({
				query: (identities) => `/conferences/users?identities=${encodeURIComponent(identities.join(","))}`,
			}),
			getConferenceRoom: builder.query<ConferenceRoomResponse, number>({
				query: (meetingRecordId: number) => `/conferences/${meetingRecordId}/room`,
			}),
			updateMeetingRecordingStatus: builder.mutation<void, { meetingRecordId: number; enabled: boolean }>({
				query: ({ meetingRecordId, enabled }) => ({
					url: `/conferences/${meetingRecordId}/recording-status/${enabled}`,
					method: "POST",
				}),
			}),
		};
	},
});

export const {
	useGetLiveParticipantsQuery,
	useGetConferenceUsersQuery,
	useGetConferenceRoomQuery,
	useUpdateMeetingRecordingStatusMutation,
} = meetingsApi;
