import { forwardRef } from "react";

import { InputValidationState, InputContainer } from "@salesdesk/daisy-ui";

import { DateTimePickerInputProps } from "../../types";
import { DateTimePickerInput } from "./DateTimePickerInput";

export type DateTimeInputProps = DateTimePickerInputProps;

export const DateTimeInput = forwardRef<HTMLInputElement, DateTimeInputProps>(
	({ disabled, hasError, ...inputProps }, ref) => {
		return (
			<InputContainer
				disabled={disabled}
				validationState={hasError ? InputValidationState.error : InputValidationState.initial}
				className="relative flex items-center"
			>
				<DateTimePickerInput ref={ref} hasError={hasError} disabled={disabled} {...inputProps} />
			</InputContainer>
		);
	}
);
