import { useMemo } from "react";

import { getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { mAssetDef } from "@salesdesk/salesdesk-model";
import { Tooltip } from "@salesdesk/daisy-ui";

import { useSdFileByFieldName } from "../../../../../../files/hooks/useSdFileByFieldName";
import { FilePreview, SkeletonCompactFilePreview } from "../../../../../../files";
import { BaseCompactRecordPreviewProps } from "../../types";

export function CompactAssetPreview({ sdRecord, sdObject, variant }: BaseCompactRecordPreviewProps) {
	const recordName = useMemo(() => getSDRecordName(sdObject, sdRecord), [sdObject, sdRecord]);

	const { sdFile: mainFile, isLoading } = useSdFileByFieldName(sdObject, sdRecord, mAssetDef.FILE_FIELD_NAME);

	if (isLoading) {
		return <SkeletonCompactFilePreview variant={variant} />;
	}

	if (mainFile) {
		return <FilePreview file={mainFile} compact fileDisplayName={recordName} variant={variant} />;
	}

	return (
		<Tooltip text={recordName} showOnTruncated>
			<div className="truncate">{recordName}</div>
		</Tooltip>
	);
}
