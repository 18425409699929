import clsx from "clsx";

import { Badge } from "../../../../../../../components/Badge";
import { UserWithDetailsController } from "../../../../../../users";
import { ParticipantUserAndStatus } from "../types";

interface ParticipantProps {
	participant: ParticipantUserAndStatus;
	compact?: boolean;
}

export function Participant({ participant, compact }: ParticipantProps) {
	const { user, onCall, isOrganiser, email } = participant;

	return (
		<div key={email} className={clsx("flex justify-between", compact && "flex-col gap-1")}>
			<UserWithDetailsController recordId={user.id} user={user} status={onCall ? "ACTIVE" : undefined} />
			<div className={clsx("flex items-start gap-1", compact ? "ml-[38px] justify-start" : "justify-end")}>
				{isOrganiser ? <Badge text="Organiser" /> : null}
			</div>
		</div>
	);
}
