import { PropsWithChildren } from "react";
import { useNotificationsCounter } from "../hooks/useNotificationsCounter";
import { NotificationsCounterContext } from "../hooks/useNotificationsCounterContext";

export function NotificationsCounterProvider({ children }: PropsWithChildren) {
	const { count, setPause, reset } = useNotificationsCounter();

	return (
		<NotificationsCounterContext.Provider
			value={{
				count,
				setPause,
				reset,
			}}
		>
			{children}
		</NotificationsCounterContext.Provider>
	);
}
