import { useState } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useCopyToClipboard } from "../../../../../../../../../hooks/useCopyToClipboard";
import { PATHS } from "../../../../../../../../../routes";
import { Divider } from "../../../../../../../../../components/Divider/Divider";

import { useGetMeetingInfoAssociations } from "../../../../../hooks";
import { useVideoCallLogicContext } from "../../../../../../VideoCallProvider";

import { InfoPanelMeetingFields } from "./InfoPanelMeetingFields";
import { MeetingInfoSection } from "./MeetingInfoSection";
import { MeetingAssociationSection } from "./MeetingAssociationSection";
import { InviteUserSection } from "./InviteUserSection";

export function MeetingInfoPanel() {
	const meetingInfoAssociationDefs = useGetMeetingInfoAssociations();

	const { currentMeetingRecord, meetingObject } = useVideoCallLogicContext();
	const copyToClipboard = useCopyToClipboard();

	const [inviteOtherUsersOpen, setInviteOtherUserOpen] = useState(false);

	if (!currentMeetingRecord) {
		return null;
	}

	if (inviteOtherUsersOpen && meetingObject && currentMeetingRecord) {
		return (
			<InviteUserSection
				onClose={() => setInviteOtherUserOpen(false)}
				meetingObject={meetingObject}
				meetingRecord={currentMeetingRecord}
			/>
		);
	}

	return (
		<div className="flex h-full max-h-full flex-col gap-4 overflow-x-clip pb-4">
			<h2 className="text-label">Meeting Information</h2>
			<div className="flex flex-col gap-4 overflow-y-auto">
				<MeetingInfoSection title="Invitation Link" variant="primary">
					<Button
						size="sm"
						variant="primary_text"
						startIcon={ICONS.copy}
						onClick={() => {
							copyToClipboard(`${window.location.origin}${PATHS.MEETING(currentMeetingRecord)}`);
						}}
					>
						Copy invitation link
					</Button>
				</MeetingInfoSection>
				<Divider className="mx-0" />
				<InfoPanelMeetingFields onInviteOtherUserClick={() => setInviteOtherUserOpen(true)} />
				{meetingInfoAssociationDefs.map((objectAssociation) => (
					<MeetingAssociationSection key={objectAssociation.id} objectAssociation={objectAssociation} />
				))}
				<div className="pb-2" />
			</div>
		</div>
	);
}
