import { HasClaimSectionRow, HasClaimsSectionRowProps } from "./HasClaimSectionRow";
import { useRoleFormContext } from "../../hooks/useRoleFormContext";
import { useMemo } from "react";
import { GetKeysOfPropertyType } from "../../../../../../../../utils/typescript-utils";
import { RoleFormData } from "../../types";

export interface HasClaimConfig extends HasClaimsSectionRowProps {
	dependsOn?: HasClaimsSectionRowProps["name"][];
}

interface HasClaimsSectionProps {
	title: string;
	description: string;
	claimConfig: HasClaimConfig[];
}

export function HasClaimsSection({ title, description, claimConfig }: HasClaimsSectionProps) {
	const { getValues, setValue } = useRoleFormContext();
	const handleHasClaimChange = useMemo(
		() => (name: GetKeysOfPropertyType<RoleFormData, boolean>, hasClaim: boolean) => {
			if (hasClaim) {
				const changedProp = claimConfig.find((p) => p.name === name);
				changedProp?.dependsOn?.forEach((dependsOnName) => {
					if (!getValues(dependsOnName)) setValue(dependsOnName, true);
				});
			} else {
				const dependentProps = claimConfig.filter((p) => p.dependsOn?.includes(name));
				dependentProps?.forEach((dependentProp) => {
					if (getValues(dependentProp.name)) setValue(dependentProp.name, false);
				});
			}
		},
		[claimConfig, getValues, setValue]
	);

	return (
		<div>
			<div className="text-c_text_primary text-label mb-3">{title}</div>
			<div className="text-body-sm text-c_text_secondary">{description}</div>
			<div className="mt-4 flex flex-col gap-4">
				{claimConfig.map((props) => {
					return <HasClaimSectionRow {...props} onChange={handleHasClaimChange} />;
				})}
			</div>
		</div>
	);
}
