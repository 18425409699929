import { formatMillisecondsToHHMMSS, isEmpty } from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mTimeRangeField extends mField {
	setNow() {
		this.value = { start: Date.now() };
	}

	getStartDateTime() {
		if (!isEmpty(this.value?.start)) {
			return new Date(this.value.start);
		} else {
			throw Error(
				`No start date value has been set in field instance with field ${this.field.name}, so cannot generate start date object`
			);
		}
	}

	getEndDateTime() {
		if (!isEmpty(this.value?.end)) {
			return new Date(this.value.end);
		} else {
			throw Error(
				`No end date value has been set in field instance with field ${this.field.name}, so cannot generate end date object`
			);
		}
	}

	// TODO: Is this required?
	// get group() {
	// 	if (this.hasValue()) {
	// 		const groupInfo = this.field.groupInfo;
	//
	// 		for (let i = 0; groupInfo.length > i; i++) {
	// 			const group = groupInfo[i];
	// 			if (group.contains(new Date(this.value))) {
	// 				return group;
	// 			}
	// 		}
	//
	// 		return null;
	// 	}
	// 	throw Error(`This field instance does not belong to a group as it has no value set`);
	// }

	displayValue() {
		return `${isEmpty(this._value?.start) ? "" : new Date(this._value.start).toLocaleString()} - ${
			isEmpty(this._value?.end) ? "" : new Date(this._value.end).toLocaleString()
		}`;
	}

	displayDurationValue() {
		if (isEmpty(this.value)) {
			return "";
		}
		return formatMillisecondsToHHMMSS(this.value.end - this.value.start);
	}
}
