import { useCallback, useState } from "react";
import clsx from "clsx";
import { FormProvider } from "react-hook-form";

import { Button } from "@salesdesk/daisy-ui";
import { Field, SDObject, getAllSDObjectFields } from "@salesdesk/salesdesk-schemas";

import { getSDObjectCreateRequestData, getSDObjectUpdateRequestData } from "../../../../objects/utils/objects";
import { useCreateObjectMutation, useUpdateObjectMutation } from "../../../../objects/api/objectsApi";
import { useFieldSettingsForm, useObjectDesignerTabParam, useObjectSettings, useSDObjectState } from "../hooks";
import { OBJECT_DESIGNER_TABS } from "../types";
import { ObjectPreview } from "./ObjectPreview";
import { ObjectSettings } from "./ObjectSettings";
import { ObjectFields } from "./ObjectFields";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "../../../../../components/Tabs";
import { useToast } from "../../../../Toasts";
import { PATHS, useStableNavigate } from "../../../../../routes";

interface ObjectDesignerProps {
	sdObject: SDObject;
	isNewObject?: boolean;
}

export function ObjectDesigner({ sdObject, isNewObject = false }: ObjectDesignerProps) {
	const navigate = useStableNavigate();

	const {
		sdObjectState,
		onObjectFieldsFormUpdate,
		onObjectSettingsFormUpdate,
		reorderSDObjectFields,
		insertNewSDObjectField,
		deleteSDObjectFieldById,
		hasChangedFields,
		resetSDObject,
	} = useSDObjectState(sdObject);

	const {
		formMethods: fieldSettingsFormMethods,
		settingFieldGroups,
		createObjectField,
		activeObjectFieldId,
		setActiveField,
		deleteSettingFieldById,
		invalidObjectFieldIds,
		areFieldsValid,
		isFieldsFormChanged,
		resetFieldSettings,
	} = useFieldSettingsForm(sdObjectState, onObjectFieldsFormUpdate, isNewObject);

	const createAndInsertNewField = (field: Field, destinationIndex: number) => {
		createObjectField(field);
		insertNewSDObjectField(field, destinationIndex);
	};

	const deleteFieldById = (fieldId: number) => {
		deleteSDObjectFieldById(fieldId);
		deleteSettingFieldById(fieldId);
	};

	const {
		availableGroupedFields,
		formMethods: objectSettingsFormMethods,
		isObjectSettingsValid,
		isObjectSettingsChanged,
		onSubmitSuccess,
		reset: resetObjectSettingsForm,
		formFields,
	} = useObjectSettings(sdObject, onObjectSettingsFormUpdate);

	const [hasSettingsBeenValidated, setHasSettingsBeenValidated] = useState(false);

	const onTabChangeHandler = useCallback(
		async (prevTab: string) => {
			if (!isNewObject || hasSettingsBeenValidated || OBJECT_DESIGNER_TABS.settings !== prevTab) return;

			const fieldsToValidate = formFields.map(({ _name }) => _name!);
			await objectSettingsFormMethods.trigger(fieldsToValidate);
			setHasSettingsBeenValidated(true);
		},
		[objectSettingsFormMethods, formFields, isNewObject, hasSettingsBeenValidated]
	);

	const { currentTabIndex, setCurrentTabIndex, currentTab, tabValues } = useObjectDesignerTabParam(
		isNewObject,
		onTabChangeHandler
	);

	const [updateObject, { isLoading: isUpdateObjectLoading }] = useUpdateObjectMutation();
	const toast = useToast();

	const updateSubmit = () => {
		const updateData = getSDObjectUpdateRequestData(sdObjectState);
		const activeObjectFieldIdIndex = getAllSDObjectFields(sdObjectState)
			.map(({ _id }) => _id)
			.indexOf(activeObjectFieldId);

		updateObject({
			sdObject,
			updateData,
		})
			.unwrap()
			.then((updatedSdObject: SDObject) => {
				toast.triggerMessage({
					type: "success",
					messageKey: "object_updated",
					messageParams: {
						name: {
							type: "node",
							node: updatedSdObject._displayName,
						},
					},
				});
				onSubmitSuccess(updatedSdObject);
				resetSDObject(updatedSdObject);
				/* 
					'activeObjectFieldId' will be missed as field id will be updated by backend after save. 
					So before reset fieldSettings form we also define actual activeObjectFieldId by index
				*/
				const fields = getAllSDObjectFields(updatedSdObject);
				const newActiveObjectFieldId = fields[activeObjectFieldIdIndex]?._id;
				resetFieldSettings(fields, newActiveObjectFieldId ? String(newActiveObjectFieldId) : undefined);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "object_updated" });
			});
	};

	const [createObject, { isLoading: isCreateObjectLoading }] = useCreateObjectMutation();

	const createSubmit = () => {
		const createData = getSDObjectCreateRequestData(sdObjectState);

		createObject(createData)
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "object_created" });
				navigate(PATHS.OBJECT_MANAGER());
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "object_created" });
			});
	};

	const handleSubmit = async () => {
		let objectSettingValid = true;

		if (!hasSettingsBeenValidated && isNewObject) {
			objectSettingValid = await objectSettingsFormMethods.trigger();
		}

		if (!objectSettingValid) {
			return;
		}

		if (isNewObject) {
			createSubmit();
		} else {
			updateSubmit();
		}
	};

	const resetObjectDesigner = () => {
		resetSDObject();
		resetObjectSettingsForm();
		resetFieldSettings(getAllSDObjectFields(sdObject));
		setHasSettingsBeenValidated(false);

		if (isNewObject) {
			setCurrentTabIndex(tabValues.indexOf(OBJECT_DESIGNER_TABS.settings));
		}
	};

	const isLoading = isUpdateObjectLoading || isCreateObjectLoading;
	const isObjectFieldsChanged = isFieldsFormChanged || hasChangedFields;
	const isDesignerSubmittable =
		areFieldsValid && isObjectSettingsValid && (isObjectFieldsChanged || isObjectSettingsChanged);
	const isSubmitDisabled = !isDesignerSubmittable || isLoading;

	return (
		<div className="flex h-full flex-col gap-5 pt-3">
			<Tabs selectedIndex={currentTabIndex} onChange={setCurrentTabIndex}>
				<div className="flex w-full justify-between px-6">
					<div className="w-1/4" />
					<div className="flex w-2/4 justify-around">
						<TabList>
							<Tab error={Boolean(invalidObjectFieldIds.length)}> Fields</Tab>
							<Tab error={!isObjectSettingsValid}>Settings</Tab>
							<Tab>Preview</Tab>
						</TabList>
					</div>
					<div className="flex w-1/4 justify-end">
						<div className="flex items-center gap-3">
							<Button variant="primary_text" size="sm" onClick={resetObjectDesigner} disabled={isSubmitDisabled}>
								Cancel
							</Button>
							<Button size="sm" onClick={handleSubmit} disabled={isSubmitDisabled} isLoading={isLoading}>
								Save Changes
							</Button>
						</div>
					</div>
				</div>
				<TabPanels
					className={clsx(currentTab === OBJECT_DESIGNER_TABS.settings ? "overflow-auto" : "h-full overflow-hidden")}
				>
					<TabPanel className="h-full" unmount={false}>
						<FormProvider {...fieldSettingsFormMethods}>
							<ObjectFields
								sdObject={sdObjectState}
								fieldSettingGroups={settingFieldGroups}
								activeFieldId={activeObjectFieldId}
								setActiveFieldId={setActiveField}
								insertNewField={createAndInsertNewField}
								reorderObjectFields={reorderSDObjectFields}
								deleteFieldById={deleteFieldById}
								invalidObjectFieldIds={invalidObjectFieldIds}
							/>
						</FormProvider>
					</TabPanel>
					<TabPanel>
						<FormProvider {...objectSettingsFormMethods}>
							<ObjectSettings fieldGroups={availableGroupedFields} />
						</FormProvider>
					</TabPanel>
					<TabPanel className="h-full overflow-auto pb-6">
						<ObjectPreview sdObject={sdObjectState} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</div>
	);
}
