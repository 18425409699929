import { asConst } from "json-schema-to-ts";
import { queryClausePropertyValuesSchema } from "./queryClausePropertyValuesSchema";

export const equalsAnyOfClauseSchema = asConst({
	title: "EqualsAnyOf",
	description: "Will match any record where the given field value equals any of the values in the provided array",
	type: "object",
	additionalProperties: false,
	required: ["equalsAnyOf"],
	properties: {
		equalsAnyOf: queryClausePropertyValuesSchema,
	},
	nullable: false,
});
