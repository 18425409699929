import { useCallback, useEffect, useMemo, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { BOARD_VIEW } from "@salesdesk/salesdesk-model";

import { APP_CONFIG } from "../../../../../app/app_config";
import { useInfiniteRecordSearch } from "../../../../records";
import { BoardViewProps } from "../../../types";
import { useObjectBoardSearchParams } from "../../../hooks/useObjectBoardSearchParams";
import { useBoardFieldsToDisplaySelector, useBoardViewSelector } from "../../../store";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import { useBoardPropOnChangeContext } from "../../../hooks/useBoardPropOnChangeContext";
import { useBulkEditContext } from "../../BulkEdit";
import { ObjectBoardFooter } from "../../ObjectBoardFooter";
import { TableView } from "./TableView/TableView";
import { CardView } from "./CardView/CardView";
import { useMemoizedBoardRecords } from "../../../hooks/useMemoizedBoardRecords";

export function UngroupedBoardView() {
	const boardPropOnChange = useBoardPropOnChangeContext();
	const fieldsToDisplay = useBoardFieldsToDisplaySelector();
	const { sdObject } = useDataboardDetailsContext();

	const boardView = useBoardViewSelector();

	const { selectedRecords, setAllSelected, setOnSelectAll } = useBulkEditContext();

	const [sdRecords, setSDRecords] = useState<SDRecord[]>([]);

	const searchParams = useObjectBoardSearchParams();

	const { updateSearchParams, loadNextPage, isLastPage, isLoadingNewSearchParams, isLoadingNextPage } =
		useInfiniteRecordSearch({
			limit: APP_CONFIG.maxLocalSearchResults,
			sdRecords,
			onSDRecordsChange: setSDRecords,
			sdObjectFilter: sdObject?._id,
		});

	useEffect(() => {
		if (searchParams) {
			updateSearchParams(searchParams);
		}
	}, [searchParams, updateSearchParams]);

	const onBottomReached = useCallback(() => {
		if (!isLoadingNewSearchParams && !isLoadingNextPage && !isLastPage) {
			loadNextPage();
		}
	}, [isLoadingNewSearchParams, isLoadingNextPage, isLastPage, loadNextPage]);

	const boardRecords = useMemoizedBoardRecords(sdRecords, sdObject);

	const recordIds = useMemo(() => sdRecords.map((record) => record._id), [sdRecords]);

	useEffect(() => {
		setAllSelected(selectedRecords.length >= sdRecords.length);
	}, [sdRecords.length, selectedRecords.length, setAllSelected]);

	useEffect(() => {
		setOnSelectAll(() => () => sdRecords);
	}, [sdRecords, setOnSelectAll]);

	const isLoading = !sdObject || isLoadingNewSearchParams || !fieldsToDisplay.length;

	const footer = sdObject ? (
		<ObjectBoardFooter
			sdObject={sdObject}
			isLoading={isLoadingNextPage}
			noRecords={!sdRecords.length && !isLoading}
			boardPropOnChange={boardPropOnChange}
		/>
	) : null;

	const dataViewProps: BoardViewProps = {
		boardRecords,
		footer,
		onBottomReached,
		isLoading,
	};

	if (boardView === BOARD_VIEW.TABLE) {
		return <TableView {...dataViewProps} />;
	} else if (boardView === BOARD_VIEW.CARD) {
		return <CardView {...dataViewProps} recordIds={recordIds} />;
	}
}
