import { useRoleFormContext } from "../../hooks/useRoleFormContext";
import { FormFieldSet } from "../../../../../../../forms";
import { BaseSelect, SelectButton } from "../../../../../../../inputs";
import { InputValidationState } from "@salesdesk/daisy-ui";
import { OwnerType, RoleFormData } from "../../types";
import { GetKeysOfPropertyType } from "../../../../../../../../utils/typescript-utils";

interface OwnerTypeClaimSelectOption {
	id: OwnerType;
	name: string;
	description: string;
}

export interface OwnerTypeClaimSectionRowProps {
	title: string;
	description?: string;
	icon: string;
	name: GetKeysOfPropertyType<RoleFormData, OwnerType>;
	options: OwnerTypeClaimSelectOption[];
	onChange?: (name: GetKeysOfPropertyType<RoleFormData, OwnerType>, value: OwnerType) => void;
}

export function OwnerTypeClaimSectionRow({ name, title, icon, description, options }: OwnerTypeClaimSectionRowProps) {
	const { readOnly, control } = useRoleFormContext();

	return (
		<FormFieldSet
			control={control}
			name={name}
			label={title}
			labelIcon={icon}
			description={description}
			required={false}
			rules={{}}
		>
			{({ field, fieldState: { error } }) => (
				<BaseSelect
					id={field.name}
					onChange={field.onChange}
					onBlur={field.onBlur}
					ref={field.ref}
					value={String(field.value)}
					disabled={readOnly}
					options={options.map((c) => ({
						id: c.id,
						name: c.name,
						description: c.description,
					}))}
					selectPopoverOptions={{ width: "match_button", placement: "bottom-start" }}
					selectButton={
						<SelectButton
							currentValidationState={error?.message ? InputValidationState.error : InputValidationState.initial}
						/>
					}
				/>
			)}
		</FormFieldSet>
	);
}
