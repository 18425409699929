import { ReactNode } from "react";

export interface HighlightedTextProps {
	children: string;
	search: string;
}

export function HighlightedText({ children: text, search }: HighlightedTextProps): ReactNode {
	if (!search) {
		return text;
	}
	const regex = new RegExp(`(${search})`, "gi");
	const parts = text.split(regex);
	return parts.map((part, index) => {
		if (regex.test(part)) {
			return (
				<span className="text-label" key={index}>
					{part}
				</span>
			);
		}
		return part;
	});
}
