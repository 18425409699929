import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mPasswordFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.PASSWORD.name];
	static defaultComponentType: FieldComponentName = mPasswordFieldDef.supportedComponentTypes[0];

	static MIN_PASSWORD_LENGTH = 10;

	static MAX_PASSWORD_LENGTH = 40;

	static STRENGTH_RULE = `Requires upper and lower case letters, a number and one or more of $,@,#,&,!.`;

	static LENGTH_RULE = `Must be between ${mPasswordFieldDef.MIN_PASSWORD_LENGTH} and ${mPasswordFieldDef.MAX_PASSWORD_LENGTH} characters.`;

	constructor(id: string | number) {
		super(id);
		this._componentType = mPasswordFieldDef.defaultComponentType;
		this._toolTip = `${mPasswordFieldDef.LENGTH_RULE} ${mPasswordFieldDef.STRENGTH_RULE}`;
		this.maxLength = mPasswordFieldDef.MAX_PASSWORD_LENGTH;
		this._icon = FIELD_TYPES.PASSWORD.icon;
		this._supportsDefaultValue = false;
	}

	override get type() {
		return FIELD_TYPES.PASSWORD.name;
	}

	override get supportedComponentTypes() {
		return mPasswordFieldDef.supportedComponentTypes;
	}

	override get maxLength() {
		return this._maxLength;
	}

	override set maxLength(length) {
		this._maxLength = mPasswordFieldDef.MAX_PASSWORD_LENGTH;
	}

	override supportsTableCellView() {
		return false;
	}

	override supportsCardView() {
		return false;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value)) {
			if (!this.passwordLengthOK(value)) {
				return mPasswordFieldDef.LENGTH_RULE;
			}
			if (!this.passwordStrengthOK(value)) {
				return mPasswordFieldDef.STRENGTH_RULE;
			}
		}

		return super.validate(value, isTemplate);
	}

	passwordLengthOK(password: string) {
		if (isEmpty(password)) {
			return false;
		}
		return (
			password.length >= mPasswordFieldDef.MIN_PASSWORD_LENGTH &&
			mPasswordFieldDef.MAX_PASSWORD_LENGTH >= password.length
		);
	}

	passwordStrengthOK(password: string) {
		// Password has a lower case, upper case, number and special character
		return (
			password.match(/[a-z]+/) && password.match(/[A-Z]+/) && password.match(/[0-9]+/) && password.match(/[$@#&!]+/)
		);
	}
}
