import { useCallback, useEffect, useState } from "react";

import { formatMillisecondsToHHMMSS } from "@salesdesk/salesdesk-utils";

import { useSyncedRecordingStartTime } from "./useSyncedRecordingStartTime";
import { useVideoCallLogicContext } from "../../../VideoCallProvider";
import { useUpdateMeetingRecordingStatusMutation } from "../../../../api/meetingsApi";

import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { useWebPrincipal } from "../../../../../../auth";

export function useRecordCall() {
	const { currentMeetingRecord, room } = useVideoCallLogicContext();

	const [isRecorder, setIsRecorder] = useState(false);

	const [isRecording, setIsRecording] = useState(room?.isRecording || false);
	const [recordingDuration, setRecordingDuration] = useState<null | string>(null);

	const recordingStartTimeRef = useSyncedRecordingStartTime(isRecording, isRecorder);

	const [updateMeetingRecordStatus, { isLoading }] = useUpdateMeetingRecordingStatusMutation();

	const principal = useWebPrincipal();

	// TODO: Handle permissions for whether a user can record a room
	const canRecord = Boolean(room && principal.can(AbilityAction.Record, AbilitySubject.VideoCall));

	useEffect(() => {
		if (!room) {
			return;
		}

		const recordingStarted = () => setIsRecording(true);
		const recordingStopped = () => setIsRecording(false);

		room.on("recordingStarted", recordingStarted);
		room.on("recordingStopped", recordingStopped);

		return () => {
			room.off("recordingStarted", recordingStarted);
			room.off("recordingStopped", recordingStopped);
		};
	}, [room]);

	useEffect(() => {
		setIsRecording(room?.isRecording || false);
	}, [room?.isRecording]);

	// TODO: Need to sync the recording duration through control signals for
	// users joining the call during a recording
	useEffect(() => {
		let interval: ReturnType<typeof setInterval> | undefined;

		if (isRecording) {
			const updateRecordingDuration = () => {
				setRecordingDuration(() => {
					if (!recordingStartTimeRef.current) {
						return null;
					}

					const currentTime = Date.now();
					const elapsedTime = Math.floor(currentTime - recordingStartTimeRef.current);
					return formatMillisecondsToHHMMSS(elapsedTime, false);
				});
			};

			updateRecordingDuration();
			interval = setInterval(updateRecordingDuration, 1000);
		} else {
			clearInterval(interval);
			setIsRecorder(false);
			setRecordingDuration(null);
		}

		return () => clearInterval(interval);
	}, [isRecording, recordingStartTimeRef]);

	const toggleRoomRecording = useCallback(
		(enabled: boolean) => {
			if (!currentMeetingRecord || !room || !canRecord) {
				return;
			}
			if (enabled) {
				setIsRecorder(true);
			}
			updateMeetingRecordStatus({ meetingRecordId: currentMeetingRecord._id, enabled });
		},
		[currentMeetingRecord, room, canRecord, updateMeetingRecordStatus]
	);

	return { canRecord, isRecording, isLoading, recordingDuration, toggleRoomRecording };
}
