import clsx from "clsx";
import { MouseEventHandler, PropsWithChildren } from "react";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";

import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { DragHandleVisibility } from "../../types";

interface TableCellWrapperProps {
	className?: string;
	dragHandleProps?: DraggableProvidedDragHandleProps | null;
	dragHandleVisibility?: DragHandleVisibility;
	onClick?: MouseEventHandler;
	fixedWidth?: number;
}

export function TableCellWrapper({
	className,
	dragHandleProps,
	dragHandleVisibility = "NONE",
	onClick,
	fixedWidth,
	children,
}: PropsWithChildren<TableCellWrapperProps>) {
	const showDragHandle = dragHandleVisibility !== "NONE";

	return (
		<td
			onClick={onClick}
			className={clsx(className, showDragHandle && "relative pl-6")}
			style={fixedWidth !== undefined ? { width: fixedWidth } : undefined}
		>
			{showDragHandle ? (
				<div
					className={clsx(
						"absolute left-0 top-0 z-20 h-full items-center",
						// Keep drag handle mounted with "HIDDEN" visibility to avoid breaking drag-and-drop functionality
						dragHandleVisibility === "ALWAYS" ? "flex" : "hidden",
						dragHandleVisibility === "HOVER" && "group-hover:flex"
					)}
					onClick={(e) => e.stopPropagation()}
				>
					<div {...dragHandleProps} className="flex h-full items-center">
						<Icon icon={ICONS.grabbable} className="flex items-center" />
					</div>
				</div>
			) : null}
			{children}
		</td>
	);
}
