import { useMemo } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { PATHS } from "../../../routes";
import { useWebPrincipal } from "../../../auth";
import { useGetContextWorkspaceId } from "../../workspaces";

export function useGetRecordDetailsPath(sdRecord?: SDRecord, sdObject?: SDObject) {
	const workspaceId = useGetContextWorkspaceId();

	const principal = useWebPrincipal();

	return useMemo(() => {
		if (!sdRecord || !sdObject) {
			return undefined;
		}

		if (workspaceId !== undefined) {
			return PATHS.WORKSPACE_RECORD_DETAILS(workspaceId, sdRecord._id, sdObject);
		}

		if (principal.IsSalesDeskUser) {
			return PATHS.RECORD_DETAIL_VIEW(sdRecord._id, sdObject);
		}

		return undefined;
	}, [sdRecord, sdObject, workspaceId, principal.IsSalesDeskUser]);
}
