import { useCallback, useEffect } from "react";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { ACTIVE_TRANSCRIPT_ITEM_ATTRIBUTE_NAME } from "../components/TranscriptItem";

export function useAutoScrolling(activeItems: Record<string, boolean>, autoScroll: boolean) {
	const { videoRef } = useMediaAnalysisContext();

	const scrollToFirstActiveItem = useCallback(() => {
		const videoElement = videoRef.current;
		if (!videoElement) {
			return;
		}
		const activeElement = document.querySelector(`[${ACTIVE_TRANSCRIPT_ITEM_ATTRIBUTE_NAME}='true']`);
		if (!activeElement) {
			return;
		}
		activeElement.scrollIntoView({ behavior: "smooth", block: "start" });
	}, [videoRef]);

	useEffect(() => {
		if (!autoScroll || Object.keys(activeItems).length === 0) {
			return;
		}
		scrollToFirstActiveItem();
	}, [autoScroll, scrollToFirstActiveItem, activeItems]);

	return { scrollToFirstActiveItem };
}
