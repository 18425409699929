import { asConst } from "json-schema-to-ts";
import { recordSubQuerySchema } from "../../index";
import { subQueryClauseSchema } from "../../../search";

export const recordSubQueryClauseSchema = asConst({
	...subQueryClauseSchema,
	properties: {
		query: recordSubQuerySchema,
	},
});
