import { asConst } from "json-schema-to-ts";
import { utcMillisecSchema } from "./utcMillisecSchema";
import { creatableSchema } from "./creatableSchema";
import { userIdSchema } from "./userIdSchema";

export const updatableSchema = asConst({
	title: "UpdatableSchema",
	description: "Provides consistent naming for created/updated user and date fields",
	type: "object",
	additionalProperties: false,
	required: [...creatableSchema.required, "updatedAt", "updatedBy"],
	properties: {
		...creatableSchema.properties,
		updatedAt: utcMillisecSchema,
		updatedBy: userIdSchema,
	},
});
