import { useState } from "react";

import { ImportJobStatus } from "@salesdesk/salesdesk-schemas";

import { PATHS, useStableNavigate } from "../../../../routes";
import { ProgressStepper } from "../../../../components/ProgressStepper/ProgressStepper";

import { useImportContext } from "../../hooks/useImportContext";
import { useRedirectIfNoImportMappings } from "../../hooks/useRedirectIfNoImportMappings";
import { JobType, useCreateImportJob } from "../../hooks/useCreateImportJob";
import { getValidImportRowCount, IMPORT_STEPS, ImportStep } from "../../utils";

import { StepControls } from "../common/StepControls";
import { CurrentFileName } from "../common/CurrentFileName";

import { ImportPreviewProgress } from "./ImportPreviewProgress";
import { ImportCounts } from "./ImportCounts";
import { ImportPreviewTable } from "./ImportPreviewTable";
import { ImportStartConfirmDialog } from "./ImportStartConfirmDialog";
import { ImportActualProgress } from "./ImportActualProgress";

export function ImportExcutionPage() {
	const isRedirecting = useRedirectIfNoImportMappings();

	const { sdObject, previewImportJob, actualImportJob, setActualImportJob } = useImportContext();
	const navigate = useStableNavigate();
	const [error, setError] = useState<string | undefined>();

	const [startConfirmOpen, setStartConfirmOpen] = useState(false);

	const isPreviewCompleted = previewImportJob?.status === ImportJobStatus.Completed || Boolean(actualImportJob);

	const { createImportJob: createActualImportJob, isLoading: isCreatingJob } = useCreateImportJob({
		jobType: JobType.Import,
		onCompleted: (importJob, errorMessage) => {
			if (errorMessage) {
				setError(errorMessage);
				return;
			}
			setActualImportJob(importJob);
		},
	});

	if (isRedirecting) return null;

	return (
		<>
			<div className="flex h-full w-full flex-col items-center overflow-y-auto px-6">
				<div className="flex min-h-[600px] w-full max-w-[1130px] grow flex-col justify-stretch gap-4">
					<ProgressStepper currentStep={ImportStep.execution} steps={IMPORT_STEPS(true)} />
					<div className="text-label">Import</div>
					{!isPreviewCompleted ? (
						<ImportPreviewProgress />
					) : !actualImportJob ? (
						<>
							<ImportCounts importJob={previewImportJob} />
							<ImportPreviewTable />
						</>
					) : (
						<div className="flex grow flex-col gap-6">
							<ImportActualProgress />
							<ImportCounts importJob={actualImportJob} />
						</div>
					)}
					<div className="w-full">
						<StepControls
							onSecondaryClick={() => navigate(PATHS.IMPORT_MAPPING(sdObject))}
							onPrimaryClick={() => {
								if (getValidImportRowCount(previewImportJob) === 0) {
									setError("No records to import.");
									return;
								}
								setStartConfirmOpen(true);
							}}
							primaryIsLoading={isCreatingJob}
							primaryButtonText="Import"
							hideControls={Boolean(actualImportJob)}
							error={error}
						>
							<CurrentFileName />
						</StepControls>
					</div>
				</div>
			</div>
			{startConfirmOpen ? (
				<ImportStartConfirmDialog
					onConfirm={() => {
						createActualImportJob();
						setStartConfirmOpen(false);
					}}
					onDismiss={() => setStartConfirmOpen(false)}
				/>
			) : null}
		</>
	);
}
