import { DisplayPlaceholderField } from "../DisplayPlaceholderField";

interface DisplayColorPickerFieldProps {
	value?: string;
}

export function DisplayColorPickerField({ value }: DisplayColorPickerFieldProps) {
	if (!value) {
		return <DisplayPlaceholderField />;
	}

	return <div style={{ background: value }} className="border-c_border_btn h-8 w-8 rounded-full border" />;
}
