import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { mTaskDef } from "@salesdesk/salesdesk-model";
import { getSDObjectFieldByName, getSDObjectSystemSyncOptionFieldOptions, rsr } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetObjectById } from "../../../../../../../hooks/useGetObjectById";
import { useWebPrincipal } from "../../../../../../../auth";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";
import { useWorkspaceSharedRecordSearch } from "../../../../../hooks/useWorkspaceSharedRecordSearch";
import { FILTERS, InfiniteRecordSearchParams } from "../../../../../../records";
import { ButtonSelect, SelectOptionId } from "../../../../../../inputs";
import { AddRecordButton } from "../../../WorkspaceResources";
import { EmptySection } from "../EmptySection";
import { WorkspaceTasksList } from "./WorkspaceTasksList";

const TASK_FILTER_ALL = "all_tasks";
const TASK_FILTER_MY = "my_tasks";

export function WorkspaceTasks() {
	const { isLoading } = useWorkspaceContext();

	const principal = useWebPrincipal();
	const { sdObject: taskObject } = useGetObjectById(mTaskDef.ID);

	const [taskFilter, setTaskFilter] = useState<SelectOptionId | undefined>(TASK_FILTER_ALL);

	const taskFilterOptions = useMemo(() => {
		const taskPluralName = (taskObject?._pluralName || "tasks").toLowerCase();

		return [
			{ id: TASK_FILTER_ALL, name: `All ${taskPluralName}` },
			{ id: TASK_FILTER_MY, name: `My ${taskPluralName}` },
		];
	}, [taskObject]);

	const [additionalSearchParams, setAdditionalSearchParams] = useState<InfiniteRecordSearchParams>();

	const objectIds = useMemo(() => [mTaskDef.ID], []);

	const { records, isLoadingRecords, isLoadingNextPage, loadNextPage } = useWorkspaceSharedRecordSearch({
		sdObjectFilter: objectIds,
		additionalSearchParams: additionalSearchParams ?? skipToken,
	});

	// Update search params to filter out all completed tasks
	useEffect(() => {
		if (!taskObject) {
			return;
		}

		const query = rsr.query();

		const { field, options } = getSDObjectSystemSyncOptionFieldOptions(taskObject, mTaskDef.STATUS_FIELD_NAME);
		const completedStatusOption = options.find((option) => option.name === mTaskDef.COMPLETED_STATUS_NAME);

		if (field && completedStatusOption?.id !== undefined) {
			query.and(FILTERS.ss_not_is.generateSearchQuery(String(field._id), [completedStatusOption.id]));
		}

		const userRecordId = principal.UserRecordId;

		if (taskFilter === TASK_FILTER_MY && userRecordId !== null) {
			const assigneeField = getSDObjectFieldByName(taskObject, mTaskDef.ASSIGNEE_FIELD_NAME);

			if (assigneeField) {
				query.and(FILTERS.ms_contains_any.generateSearchQuery(String(assigneeField._id), [userRecordId]));
			}
		}

		setAdditionalSearchParams({ query: query.buildQuery() });
	}, [taskObject, principal.UserRecordId, taskFilter]);

	const isFiltered = taskFilter !== TASK_FILTER_ALL;
	const showFilterButton = isFiltered || isLoadingRecords || (!isFiltered && records.length > 0);

	return (
		<div className="flex flex-col gap-4">
			{isLoading || !taskObject ? (
				<div className="flex w-full items-center justify-between">
					<Skeleton className="h-6 w-20" />
					<Skeleton className="h-[38px] w-28" />
				</div>
			) : (
				<div className="text-c_text_secondary text-h4 flex h-[38px] items-center justify-between">
					{taskObject?._pluralName}
					<div className="flex items-center gap-3">
						{showFilterButton ? (
							<ButtonSelect
								value={taskFilter}
								onChange={setTaskFilter}
								options={taskFilterOptions}
								startIcon={ICONS.filter}
							/>
						) : null}
						<AddRecordButton sdObjectIds={objectIds} />
					</div>
				</div>
			)}
			<div className="relative">
				{!isLoadingRecords && !records.length && taskObject ? (
					<div className="w-2/5 min-w-[440px]">
						{isFiltered ? (
							<p className="text-c_text_placeholder">No uncompleted {taskObject._pluralName} assigned to you.</p>
						) : (
							<EmptySection
								sdObjectIds={objectIds}
								messageText={`No uncompleted ${taskObject._pluralName} yet, add one using the button below`}
								buttonText={`Add ${taskObject._displayName}`}
							/>
						)}
					</div>
				) : (
					<WorkspaceTasksList
						records={records}
						taskObject={taskObject}
						isLoadingRecords={isLoadingRecords}
						isLoadingNextPage={isLoadingNextPage}
						loadNextPage={loadNextPage}
					/>
				)}
			</div>
		</div>
	);
}
