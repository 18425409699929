import { DeleteConfirmationDialog } from "../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { Bookmark } from "@salesdesk/salesdesk-schemas";
import { useState } from "react";
import { useDeleteMyBookmarkMutation } from "../../api/bookmarksApi";
import { useToast } from "../../../Toasts";

interface DeleteBookmarkDialogProps {
	open: boolean;
	onDismiss: () => void;
	bookmark: Bookmark;
	onDeleteComplete: () => void;
}

export function DeleteBookmarkDialog({ open, onDismiss, bookmark, onDeleteComplete }: DeleteBookmarkDialogProps) {
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteBookmark] = useDeleteMyBookmarkMutation();
	const toast = useToast();

	const onDelete = () => {
		setIsDeleting(true);

		deleteBookmark(bookmark.id)
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "bookmark_deleted" });
				if (onDeleteComplete) {
					onDeleteComplete();
				}
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "bookmark_deleted" });
			})
			.finally(() => {
				setIsDeleting(false);
			});
	};

	return (
		<DeleteConfirmationDialog
			title="Delete Bookmark"
			open={open}
			onDismiss={onDismiss}
			onConfirm={onDelete}
			isDeleting={isDeleting}
		>
			<div className="flex flex-col gap-6">
				<div>
					Are you sure you want to delete "
					<b>
						<i>{bookmark.name}</i>
					</b>
					"?
				</div>
				<div>This action will remove the bookmark from your list. Your records and data will remain unaffected.</div>
			</div>
		</DeleteConfirmationDialog>
	);
}
