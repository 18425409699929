import { useCallback, useEffect, useMemo } from "react";

import { mUserDef } from "@salesdesk/salesdesk-model";
import { SDRecord, SortBy, rsr } from "@salesdesk/salesdesk-schemas";

import { RecordPreviewPopover, useInfiniteRecordSearch } from "../../../records";
import { useConvertUserSDRecordsToAvatarUsers } from "../../hooks";
import { generateUsersFilterRsrQuery, getInitials } from "../../utils";
import { UserFilterAvatarButton } from "./UserFilterAvatarButton";
import { ButtonTypeaheadSelect, SelectOptionId } from "../../../inputs";
import { Avatar } from "../Avatar";
import { UserFilterLoading } from "./UserFilterLoading";
import { useGetRecordOptionsInWorkspace } from "../../../fields";

import { useGetContextWorkspaceId } from "../../../workspaces";

type RecordId = SDRecord["_id"];

interface UserFilterProps {
	title?: string;
	value: RecordId[];
	onChange: (newValue: RecordId[]) => void;
}

const PRIMARY_USERS = 4;

export function UserFilter({ title, value, onChange }: UserFilterProps) {
	const workspaceId = useGetContextWorkspaceId();

	const {
		sdRecords: primaryUsers,
		updateSearchParams,
		resultHits,
		isLoadingNewSearchParams: isLoading,
	} = useInfiniteRecordSearch({ limit: PRIMARY_USERS, addRecordsOnUpdateEvent: false });

	useEffect(() => {
		updateSearchParams(generatePrimaryUsersSearchParams(workspaceId));
	}, [updateSearchParams, workspaceId]);

	const primaryAvatarUsers = useConvertUserSDRecordsToAvatarUsers(primaryUsers);
	const primaryUserIds = useMemo(() => primaryUsers.map((userRecord) => userRecord._id), [primaryUsers]);

	const { getOptions } = useGetRecordOptionsInWorkspace({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
		excludeRecordIds: primaryUserIds,
	});

	const toggleUserSelection = (userId: RecordId) => {
		if (value.includes(userId)) {
			onChange(value.filter((id) => id !== userId));
		} else {
			onChange([...value, userId]);
		}
	};

	const handleOnChange = useCallback(
		(newValue: SelectOptionId[]) => {
			onChange(newValue.map(Number));
		},
		[onChange]
	);

	const hitCount = resultHits?.hitCount || 0;
	const remainingUsers = hitCount - PRIMARY_USERS;
	const selectedUsersInDropdown = remainingUsers > 0 && value.some((id) => !primaryUserIds.includes(id));

	if (!primaryAvatarUsers.length && isLoading) {
		return <UserFilterLoading primaryUserCount={PRIMARY_USERS} />;
	} else if (!primaryAvatarUsers.length && !isLoading) {
		return null;
	}

	return (
		<div className="mr-2 flex items-center">
			{title ? <span className="text-label-sm mr-2">{title}</span> : null}
			{primaryAvatarUsers.map((user, index) => {
				if (!user) {
					return null;
				}

				const isSelected = value.includes(user.id);
				return (
					<RecordPreviewPopover key={user.id} recordId={user.id}>
						<UserFilterAvatarButton
							isSelected={isSelected}
							style={{ zIndex: primaryAvatarUsers.length - index }}
							onClick={() => toggleUserSelection(user.id)}
						>
							<Avatar initials={getInitials(user.fullName)} size={"sm"} avatarFileId={user.avatarFileId} />
						</UserFilterAvatarButton>
					</RecordPreviewPopover>
				);
			})}
			{remainingUsers > 0 ? (
				<ButtonTypeaheadSelect
					getSelectOptions={getOptions}
					value={value}
					onChange={handleOnChange}
					optionDisplayVariant="user_pill"
				>
					<UserFilterAvatarButton isSelected={selectedUsersInDropdown}>
						<div className="text-c_text_primary text-label-xs bg-c_bg_02 flex h-8 w-8 items-center justify-center rounded-full pl-0.5 subpixel-antialiased">
							+{remainingUsers}
						</div>
					</UserFilterAvatarButton>
				</ButtonTypeaheadSelect>
			) : null}
		</div>
	);
}

function generatePrimaryUsersSearchParams(workspaceId?: number) {
	const query = generateUsersFilterRsrQuery();
	if (workspaceId) {
		query.and(rsr.isSharedWithWorkspace(workspaceId));
	}

	return { query: query.buildQuery(), sort: [{ _name: { order: "asc" } }] as SortBy[] };
}
