import { tw } from "../../../../../utils/tailwind-helpers";

export const SELECT_POPOVER_TRANSITION = {
	enter: tw`transition duration-200 ease-out`,
	enterFrom: tw`scale-95 opacity-0`,
	enterTo: tw`scale-100 opacity-100`,
	leave: tw`transition duration-100 ease-in`,
	leaveFrom: tw`scale-100 opacity-100`,
	leaveTo: tw`scale-95 opacity-0`,
};
