import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDebouncedActiveState } from "../../../hooks";
import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { formatVideoTimestamp, videoIsCurrentlyPlaying } from "../utils";
import { VideoProgressBar } from "./VideoProgressBar";
import { JUMP_TIME_SECONDS } from "./VideoJumpControl";
import { Icon, Spinner, Button } from "@salesdesk/daisy-ui";
import { debounce } from "../../../../../utils";

const AUTO_START_ON_HOVER_DELAY = 400;

export function VideoControls() {
	const [initialLoad, setInitialLoad] = useState(true);
	const { isActive, triggerActiveState } = useDebouncedActiveState();
	const [isHoveringControls, setIsHoveringControls] = useState(false);

	const {
		isPlaying,
		updateIsPlaying,
		currentVideoTime,
		videoDuration,
		seekToVideoTime,
		isLoading,
		errorMessage,
		onOpenPreviewClick,
		volume,
		toggleMute,
		variant,
		innerVideoRef,
	} = useVideoPlayerContext();

	useEffect(() => {
		if (isPlaying) {
			setInitialLoad(false);
		}
	}, [isPlaying]);

	const activeControls = isActive || !isPlaying || isLoading || isHoveringControls;

	const isHoveringRef = useRef(false);
	const startVideo = useMemo(
		() =>
			debounce(() => {
				if (!isHoveringRef.current) {
					return;
				}
				if (!videoIsCurrentlyPlaying(innerVideoRef.current)) {
					seekToVideoTime(0, true);
				}
				updateIsPlaying(true);
				triggerActiveState();
			}, AUTO_START_ON_HOVER_DELAY),
		[innerVideoRef, updateIsPlaying, triggerActiveState, seekToVideoTime]
	);

	const pauseVideo = () => {
		if (isHoveringRef.current) {
			return;
		}
		updateIsPlaying(false);
	};

	if (errorMessage) {
		return (
			<div
				className={clsx(
					"text-h4 absolute left-0 top-0 flex h-full w-full items-center justify-center",
					variant === "expanded" ? "text-c_text_inverted" : "text-c_text_primary"
				)}
			>
				{errorMessage}
			</div>
		);
	}
	return (
		<div
			tabIndex={0}
			className={clsx(
				"absolute left-0 top-0 flex h-full w-full flex-col transition-opacity ease-out",
				activeControls ? "opacity-100" : "opacity-0"
			)}
			onMouseMove={() => {
				triggerActiveState();
			}}
			onClick={(e) => {
				e.stopPropagation();
				if (onOpenPreviewClick) {
					onOpenPreviewClick();
				}
			}}
			onKeyDown={(e) => {
				const code = e.code;
				if (code === "Space") {
					updateIsPlaying(!isPlaying);
				} else if (code === "ArrowLeft") {
					seekToVideoTime(currentVideoTime - JUMP_TIME_SECONDS);
				} else if (code === "ArrowRight") {
					seekToVideoTime(currentVideoTime + JUMP_TIME_SECONDS);
				} else {
					return;
				}
				triggerActiveState();
				e.stopPropagation();
			}}
			onMouseEnter={() => {
				isHoveringRef.current = true;
				startVideo();
			}}
			onMouseLeave={() => {
				isHoveringRef.current = false;
				pauseVideo();
			}}
		>
			{onOpenPreviewClick ? <button className="sr-only">Open expanded view</button> : null}
			<div
				className={clsx(
					!initialLoad && "pt-6",
					activeControls ? "cursor-pointer" : "cursor-none",
					"flex h-full items-center justify-center"
				)}
			>
				{isLoading ? <Spinner darkMode size="md" /> : null}
				{!isLoading && !isPlaying ? (
					<div className="bg-c_bg_01 text-c_icon_regular flex h-10 w-10 items-center justify-center rounded-full">
						<Icon icon={ICONS.play} />
					</div>
				) : null}
			</div>
			{initialLoad ? null : (
				<div
					className="bg-c_bg_01 text-c_text_secondary text-label-xs flex h-8 w-full items-center justify-between bg-opacity-50 px-1"
					onMouseEnter={() => setIsHoveringControls(true)}
					onMouseLeave={() => setIsHoveringControls(false)}
				>
					<VideoTimestamp timestamp={currentVideoTime} />
					<VideoProgressBar />
					<VideoTimestamp timestamp={videoDuration} />
					<Button
						startIcon={volume === 0 ? ICONS.speakerSlash : ICONS.speakerHigh}
						variant="text"
						size="xs"
						onClick={(e) => {
							toggleMute();
							e.stopPropagation();
						}}
					/>
				</div>
			)}
		</div>
	);
}

function VideoTimestamp({ timestamp }: { timestamp: number }) {
	return <div className="w-12 flex-shrink-0 text-center">{formatVideoTimestamp(timestamp)}</div>;
}
