import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo, useState } from "react";

import { Button, Spinner, tw } from "@salesdesk/daisy-ui";

import { Auth } from "../../../../auth";
import { getTenantPrefix, useStableNavigate } from "../../../../routes";
import { BrandedLandingPageLayout } from "../../common/BrandedLandingPageLayout";
import { useGlobalAppInitialiser } from "../hooks/useGlobalAppInitialise";
import { TenantLink, useGetLoginChoicesQuery } from "../../../tenant";

export function StartPage() {
	const { isInitialised } = useGlobalAppInitialiser();

	const navigate = useStableNavigate();

	const {
		data: loginChoices,
		isLoading,
		isUninitialized,
	} = useGetLoginChoicesQuery(isInitialised ? undefined : skipToken);

	// Sorts the login choices alphabetically by account name
	const displayedLoginChoices = useMemo(() => {
		if (!loginChoices) return loginChoices;
		return loginChoices.slice().sort((a, b) => a.accountName.localeCompare(b.accountName));
	}, [loginChoices]);

	// Store isLoading in state so we can redirect if the user has a single tenant before
	// displaying the tenant list
	const [loadingState, setLoadingState] = useState(true);

	useEffect(() => {
		// If the user has only one tenant, redirect them to that tenant
		if (!isLoading && displayedLoginChoices?.length === 1) {
			navigate(getTenantPrefix(displayedLoginChoices[0].tenant));
			return;
		}

		if (isUninitialized) {
			return;
		}

		setLoadingState(isLoading);
	}, [isLoading, isUninitialized, displayedLoginChoices, navigate]);

	const hasLoaded = isInitialised && !loadingState;
	const hasNoAccounts = hasLoaded && !displayedLoginChoices?.length;

	// The username (i.e. the email) of the user is in the JWT token so we can read it from there
	// TODO: Is there a better approach for getting the user's email?
	const currentUsername = useMemo(() => {
		if (!isInitialised) {
			return null;
		}

		const jwt = localStorage.getItem("jwt");

		if (jwt) {
			const tokenPayload = JSON.parse(window.atob(JSON.parse(jwt).access_token.split(".")[1]));
			return tokenPayload?.username ?? null;
		}

		return null;
	}, [isInitialised]);

	return (
		<BrandedLandingPageLayout contentContainerClassName={tw`min-h-[500px]`}>
			<div className="text-c_text_secondary mx-auto flex max-h-full min-h-72 max-w-[440px] flex-col items-center gap-8 overflow-hidden text-center">
				<h2 className="text-h3 text-c_text_primary">
					{!hasLoaded ? "Hang tight!" : hasNoAccounts ? "No Accounts available" : "Choose an Account!"}
				</h2>
				{!hasLoaded ? (
					<>
						<Spinner size="sm" />
						Loading your accounts...
					</>
				) : hasNoAccounts ? (
					<>
						It looks like you don't have access to any accounts.
						<Button fullWidth variant="outlined" onClick={Auth.logout}>
							Return to Login
						</Button>
					</>
				) : (
					<>
						<span>
							Select an account{" "}
							{currentUsername ? (
								<>
									for <span className="text-label">{currentUsername}</span>{" "}
								</>
							) : null}
							to get working with your team
						</span>
						<div className="my-6 mt-5 flex max-h-full min-h-0 w-full flex-col gap-6 overflow-y-auto p-1">
							{displayedLoginChoices?.map(({ tenant, isInternalUser, accountName, accountLogoUrl }) => (
								<TenantLink
									key={tenant}
									tenant={tenant}
									accountLogoUrl={accountLogoUrl}
									accountName={accountName}
									isInternalUser={isInternalUser}
								/>
							))}
						</div>
					</>
				)}
			</div>
		</BrandedLandingPageLayout>
	);
}
