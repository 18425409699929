import { faceSentimentColors, FaceSentimentDataPoint, FaceSentimentType, faceSentimentTypes } from "../../../../types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMediaAnalysisContext } from "../../../../hooks/useMediaAnalisysContext";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

interface LineTooltipProps {
	activeSentiment: FaceSentimentType | null;
	data: Partial<FaceSentimentDataPoint>[];
}

export function FaceSentimentTooltip({ activeSentiment, data }: LineTooltipProps) {
	const { faceSentimentGraphHolderRef, faceSentimentGraphRef } = useMediaAnalysisContext();
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [xPosition, setXPosition] = useState<number>(0);
	const mouseXPosition = useRef<number>(0);

	const offsetX = faceSentimentGraphRef.current?.offsetLeft ?? 0;

	const maxValuePerSentimentType = useMemo(() => {
		if (!data) {
			return undefined;
		}
		const maxValues: Record<FaceSentimentType, number> = {} as Record<FaceSentimentType, number>;
		faceSentimentTypes.forEach((sentimentType) => {
			maxValues[sentimentType] = Math.max(...data.map((item) => item[sentimentType] ?? 0));
		});
		return maxValues;
	}, [data]);

	const graphRect = faceSentimentGraphHolderRef.current?.getBoundingClientRect();
	const graphLeft = graphRect?.left ?? 0;

	useEffect(() => {
		const trackMouseXPosition = (e: MouseEvent) => {
			mouseXPosition.current = e.clientX;
		};
		window.addEventListener("mousemove", trackMouseXPosition);
		return () => {
			window.removeEventListener("mousemove", trackMouseXPosition);
		};
	}, []);

	useEffect(() => {
		const relativeMouseX = mouseXPosition.current - graphLeft;
		const tooltipWidth = tooltipRef.current?.getBoundingClientRect().width ?? 0;
		setXPosition(relativeMouseX < tooltipWidth + 20 ? relativeMouseX + 20 : relativeMouseX - tooltipWidth - 20);
	}, [graphLeft, activeSentiment]);

	const yPosition =
		maxValuePerSentimentType && graphRect && activeSentiment
			? Math.min(Math.round(((maxValuePerSentimentType[activeSentiment] ?? 0) / 100) * graphRect.height), 110)
			: 0;

	if (!activeSentiment) {
		return null;
	}

	return (
		<div
			ref={tooltipRef}
			className="text-c_text_inverted text-label-xs shadow-tooltip absolute flex rounded-sm px-3 py-1"
			onMouseMove={(e) => e.stopPropagation()}
			style={{
				visibility: graphRect ? "visible" : "hidden",
				backgroundColor: faceSentimentColors[activeSentiment],
				left: offsetX + xPosition,
				bottom: yPosition,
			}}
		>
			{capitalizeString(activeSentiment.toLowerCase())}
		</div>
	);
}
