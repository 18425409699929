import { mLeadDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { SDRecord, SDObject, AbilitySubject, AbilityAction } from "@salesdesk/salesdesk-schemas";

import { useLeadToOpportunityContext } from "../../../../../leads";
import { useWebPrincipal } from "../../../../../../auth";
import { MenuItem } from "../../../../../menu";

export function useLeadRecordActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	const principal = useWebPrincipal();
	const { setLeadSDRecord } = useLeadToOpportunityContext();

	if (mLeadDef.ID !== sdObject?._id || !sdRecord) return [];

	const menuItems: MenuItem[] = [];

	if (setLeadSDRecord && principal.can(AbilityAction.Convert, AbilitySubject.Lead)) {
		menuItems.push({
			icon: ICONS.crown,
			text: "Convert to opportunity",
			type: "button",
			onClick: () => setLeadSDRecord(sdRecord),
		});
	}

	return menuItems;
}
