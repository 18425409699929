import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

interface PreviewRichTextFieldProps {
	placeholder?: string;
}

export function PreviewRichTextField({ placeholder }: PreviewRichTextFieldProps) {
	return (
		<div className="text-c_text_placeholder bg-c_bg_03 h-[136px] w-full select-none rounded-sm">
			<div className="text-c_text_primary border-c_border_regular flex items-center justify-between border-b px-4 py-1.5">
				<div className="flex items-center gap-1 p-1">
					<span className="text-label-sm">Normal</span>
					<Icon className="c_icon_regular flex" icon={ICONS.caretUpDown} size="sm" />
				</div>
				<div className="flex gap-0.5">
					<PreviewToolbarIcon icon={ICONS.textBold} />
					<PreviewToolbarIcon icon={ICONS.textItalic} />
					<PreviewToolbarIcon icon={ICONS.textUnderline} />
					<PreviewToolbarIcon icon={ICONS.listDashes} />
					<PreviewToolbarIcon icon={ICONS.listNumbers} />
				</div>
			</div>
			<div className="px-4 py-3">{placeholder}</div>
		</div>
	);
}

function PreviewToolbarIcon({ icon }: { icon: string }) {
	return (
		<div className="flex h-8 w-8 items-center justify-center">
			<Icon className="text-c_icon_regular flex" icon={icon} />
		</div>
	);
}
