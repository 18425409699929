import clsx from "clsx";
import { Badge } from "../../../../../../components/Badge";
import { ratings } from "../types";

interface CoachingAdviceRatingProps {
	ratingValue: string;
}

export function CoachingAdviceRating({ ratingValue }: CoachingAdviceRatingProps) {
	return (
		<div className="flex gap-px">
			{ratings.map((rating, index) => (
				<div key={rating.label} className="flex flex-1 flex-col items-center justify-start gap-2">
					<div
						className={clsx(
							"h-2 w-full",
							rating.barColorClass,
							index === 0 && "rounded-l-full",
							index === ratings.length - 1 && "rounded-r-full"
						)}
					/>
					{rating.label === ratingValue ? <Badge size="lg" color={rating.badgeColor} text={rating.label} /> : null}
				</div>
			))}
		</div>
	);
}
