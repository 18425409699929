import { forwardRef, useState } from "react";

import { InputValidationState } from "@salesdesk/daisy-ui";

import { SingleOptionSelectProps } from "../../types";
import { BaseSelect, SelectButton } from "../BaseSelect";

interface SelectProps extends SingleOptionSelectProps {
	validationState?: InputValidationState;
}

export const Select = forwardRef<HTMLElement, SelectProps>(
	({ validationState = InputValidationState.initial, onChange, ...selectProps }, ref) => {
		const [open, setOpen] = useState(false);
		return (
			<BaseSelect
				ref={ref}
				{...selectProps}
				onChange={onChange}
				onOpenChange={setOpen}
				selectPopoverOptions={{ width: "match_button" }}
				selectButton={
					<SelectButton currentValidationState={validationState} open={open} onClearClick={() => onChange(undefined)} />
				}
			/>
		);
	}
);
