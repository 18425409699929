import { SetStateAction, useCallback, useEffect, useRef, useState, useMemo } from "react";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import clsx from "clsx";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { useGetRecordAssociationsSummaryMap } from "../../../../../../../recordAssociations";
import { useUpdateRecord } from "../../../../../../../records";
import { useWorkspaceContext } from "../../../../../../../workspaces";
import { useToast } from "../../../../../../../Toasts";
import { insertElementAtIndex, removeElementFromIndex, throttle } from "../../../../../../../../utils";
import { RecordGroupDetails } from "../../../../../../types";
import { useDataboardDetailsContext } from "../../../../../../hooks/useDataboardDetailsContext";
import { useBoardFieldsToDisplaySelector, useBoardGroupBySelector } from "../../../../../../store";
import { useBulkEditContext } from "../../../../../BulkEdit";
import { GroupedData, onRecordGroupDetailsChangeFn } from "../../../types";
import { generateInitialGroupedData } from "../../../utils";
import { KANBAN_COLUMN_WIDTH, KANBAN_CONTAINER_PADDING_RIGHT, KANBAN_GAP_WIDTH } from "../utils";
import { KanbanColumn } from "./KanbanColumn";
import { KanbanColumnHeaders } from "./KanbanColumnHeaders";

interface KanbanViewProps {
	recordGroups: RecordGroupDetails[];
}

export function KanbanView({ recordGroups }: KanbanViewProps) {
	const { scrollContainerRef: workspaceScrollContainerRef } = useWorkspaceContext();

	const { sdObject, workspaceId } = useDataboardDetailsContext();
	const fieldsToDisplay = useBoardFieldsToDisplaySelector();
	const boardGroupBy = useBoardGroupBySelector();

	const isWorkspaceBoard = workspaceId != null;

	const { selectedRecords, setAllSelected, setOnSelectAll } = useBulkEditContext();

	const [spacerDivWidth, setSpacerDivWidth] = useState(0);

	const containerRef = useRef<HTMLDivElement>(null);

	const [kanbanData, setKanbanData] = useState<GroupedData>(() => generateInitialGroupedData(recordGroups));
	const [allSDRecords, setAllSDRecords] = useState<SDRecord[]>([]);
	const allSDRecordsRef = useRef<SDRecord[]>([]);

	const recordGroupIds = useMemo(() => recordGroups.map((group) => group.id), [recordGroups]);

	useEffect(() => {
		const updatedRecords = Object.values(kanbanData).flatMap(({ data }) => data);

		setAllSDRecords(updatedRecords);
		allSDRecordsRef.current = updatedRecords;
	}, [kanbanData]);

	useEffect(() => {
		setAllSelected(selectedRecords.length >= allSDRecords.length);
	}, [allSDRecords.length, selectedRecords.length, setAllSelected]);

	useEffect(() => {
		setOnSelectAll(() => () => allSDRecordsRef.current);
	}, [setOnSelectAll]);

	const recordSummaryIds = useMemo(() => allSDRecords.map((record) => record._id), [allSDRecords]);
	const { recordAssociationsSummaryMap, clearAssociationsSummary } =
		useGetRecordAssociationsSummaryMap(recordSummaryIds);

	// Clears the associations summary when the board is changed
	useEffect(() => {
		clearAssociationsSummary();
	}, [clearAssociationsSummary, sdObject]);

	useEffect(() => {
		setKanbanData(generateInitialGroupedData(recordGroups));
	}, [recordGroups]);

	const { updateRecord } = useUpdateRecord();
	const toast = useToast();

	const updateRecordColumnPosition = useCallback(
		(currentGroupId: number, currentIndex: number, destinationGroupId: number, destinationIndex: number) => {
			const currentGroup = kanbanData[currentGroupId];
			const record = currentGroup.data[currentIndex];

			const currentGroupNewHitCount = Math.max(0, (currentGroup.groupDetails.hitCount || 0) - 1);
			currentGroup.data = removeElementFromIndex(currentGroup.data, currentIndex);
			currentGroup.groupDetails = { ...currentGroup.groupDetails, hitCount: currentGroupNewHitCount };

			const destinationGroup = kanbanData[destinationGroupId];
			const destinationGroupNewHitCount = Math.max(0, (destinationGroup.groupDetails.hitCount || 0) + 1);
			destinationGroup.data = insertElementAtIndex(destinationGroup.data, destinationIndex, record);
			destinationGroup.groupDetails = {
				...destinationGroup.groupDetails,
				hitCount: destinationGroupNewHitCount,
			};

			setKanbanData({ ...kanbanData });

			return { record };
		},
		[setKanbanData, kanbanData]
	);

	const onDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			const { source, destination } = result;

			const currentGroupId = Number(source.droppableId);
			const recordIndex = source.index;

			const destinationGroupId = Number(destination.droppableId);
			const destinationIndex = destination.index;

			const { record } = updateRecordColumnPosition(currentGroupId, recordIndex, destinationGroupId, destinationIndex);

			const fieldId = boardGroupBy;

			// If groupBy is undefined (so all of the records are in an 'ungrouped' column and aren't being grouped by any field)
			// or the current & destination group ids are the same, the user has moved a card's postion within its current column,
			// so nothing on the backend has to be updated.
			if (!fieldId || currentGroupId === destinationGroupId) {
				return;
			}

			updateRecord({ record, updatedFields: [{ _fieldId: Number(fieldId), _value: destinationGroupId }] })
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "record_updated" });
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_updated" });

					// Swaps the record back to its original position if an error occurred
					updateRecordColumnPosition(destinationGroupId, destinationIndex, currentGroupId, recordIndex);
				});
		},
		[boardGroupBy, toast, updateRecord, updateRecordColumnPosition]
	);

	const onColumnDataChange = useCallback((groupId: number, updatedData: SetStateAction<SDRecord[]>) => {
		setKanbanData((currentKanbanData) => {
			const groupDetails = currentKanbanData[groupId].groupDetails;
			const columnData = currentKanbanData[groupId].data || [];

			return {
				...currentKanbanData,
				[groupId]: {
					groupDetails,
					data: typeof updatedData === "function" ? updatedData(columnData) : updatedData,
				},
			};
		});
	}, []);

	const onRecordGroupDetailsChange: onRecordGroupDetailsChangeFn = useCallback(
		(groupID, { aggregationResultValue, ...updatedDetails }) => {
			setKanbanData((currentKanbanData) => {
				const currentAggregationResults = currentKanbanData[groupID]?.groupDetails.aggregationResult;

				const updatedAggregationResults = currentAggregationResults
					? { ...currentAggregationResults, value: aggregationResultValue }
					: undefined;

				const newGroupDetails: RecordGroupDetails = {
					...currentKanbanData[groupID]?.groupDetails,
					...updatedDetails,
					aggregationResult: updatedAggregationResults,
				};

				return {
					...currentKanbanData,
					[groupID]: { groupDetails: newGroupDetails, data: currentKanbanData[groupID]?.data },
				};
			});
		},
		[]
	);

	useEffect(() => {
		const containerElement = containerRef.current;

		// Workspace board currently doesn't have snap scrolling
		if (!containerElement || isWorkspaceBoard) return;

		// This code adjusts the spacer div's width to ensure that scrolling to the end of the kanban board
		// displays a complete number of columns without any being partially cut off. Without this adjustment,
		// the snapping behavior breaks and at the end of the scroll the first column in the view becomes
		// partially obscured in order for the last column to be fully displayed.
		const adjustSpacerWidthForColumnSnap = () => {
			const numberOfColumns = recordGroupIds.length;

			const containerWidth = containerElement.clientWidth - KANBAN_CONTAINER_PADDING_RIGHT;

			// The maximum number of columns that can be fully displayed without being cut off/causing overflow
			const maxFullyVisibleColumns = Math.floor(
				(containerWidth + KANBAN_GAP_WIDTH) / (KANBAN_COLUMN_WIDTH + KANBAN_GAP_WIDTH)
			);

			// If all columns fit within the container without overflow, no spacer div is needed
			if (numberOfColumns <= maxFullyVisibleColumns) {
				setSpacerDivWidth(0);
				return;
			}

			// The width of the spacer div needed to ensure the first column in the view
			// is fully visible and snapped to at the end of the scroll
			setSpacerDivWidth(
				containerWidth - maxFullyVisibleColumns * (KANBAN_COLUMN_WIDTH + KANBAN_GAP_WIDTH) + KANBAN_GAP_WIDTH
			);
		};

		const observer = new ResizeObserver(throttle(adjustSpacerWidthForColumnSnap, 100));
		observer.observe(containerElement);

		adjustSpacerWidthForColumnSnap();

		return () => {
			observer.disconnect();
		};
	}, [isWorkspaceBoard, recordGroupIds.length]);

	const hasFields = Boolean(fieldsToDisplay?.length);
	const scrollContainerRef = isWorkspaceBoard ? (workspaceScrollContainerRef ?? containerRef) : containerRef;

	return (
		<div className={clsx(!isWorkspaceBoard && "h-full overflow-hidden")}>
			<div
				ref={containerRef}
				className={clsx(
					"h-full max-w-full snap-x snap-mandatory",
					hasFields ? "opacity-100" : "opacity-0",
					!isWorkspaceBoard && "relative overflow-auto"
				)}
			>
				<KanbanColumnHeaders
					recordGroupIds={recordGroupIds}
					kanbanData={kanbanData}
					fixedAggregationLabel={!isWorkspaceBoard}
				/>
				<DragDropContext onDragEnd={onDragEnd}>
					<div
						className="flex min-h-full w-fit"
						style={{ gap: `${KANBAN_GAP_WIDTH}px`, paddingRight: `${KANBAN_CONTAINER_PADDING_RIGHT}px` }}
					>
						{recordGroupIds.map((groupId) => {
							const columnData = kanbanData[groupId];
							if (!columnData) return null;
							return (
								<KanbanColumn
									key={groupId}
									columnWidth={KANBAN_COLUMN_WIDTH}
									columnData={columnData}
									onRecordGroupDetailsChange={onRecordGroupDetailsChange}
									onColumnDataChange={onColumnDataChange}
									fieldsToDisplay={fieldsToDisplay}
									viewContainerRef={scrollContainerRef}
									recordAssociationsSummaryMap={recordAssociationsSummaryMap}
								/>
							);
						})}
						{/* Negative margin so the spacer div is unaffected by the flex gap */}
						<div style={{ marginLeft: `-${KANBAN_GAP_WIDTH}px`, width: spacerDivWidth }} />
					</div>
				</DragDropContext>
			</div>
		</div>
	);
}
