import { RefObject, useMemo } from "react";
import { RecordTableRow, RecordTableRowData } from "../types";
import { ControlColumnDetails, ROW_IS_LOADING_PLACEHOLDER } from "../../../../../Table";
import { RECORD_ROW_CONTROLS_WIDTH, RecordRowControls } from "../../../../../RecordRowControls";
import { FULL_RECORD_DATA_FIELD_ID } from "../../../../types";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

export function useControlColumn(
	tableRef: RefObject<HTMLDivElement>,
	scrollContainerRef: RefObject<HTMLDivElement> | undefined
): ControlColumnDetails<RecordTableRowData> {
	return useMemo(
		() => ({
			width: RECORD_ROW_CONTROLS_WIDTH,
			render: (row: RecordTableRow) =>
				row[ROW_IS_LOADING_PLACEHOLDER] ? null : (
					<RecordRowControls
						sdRecord={row[FULL_RECORD_DATA_FIELD_ID] as SDRecord}
						scrollContainerRef={scrollContainerRef}
						tableRef={tableRef}
					/>
				),
		}),
		[tableRef, scrollContainerRef]
	);
}
