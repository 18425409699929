import { useState, useEffect, useCallback } from "react";

import { AssociationMultiplicity, mWorkspaceDef } from "@salesdesk/salesdesk-model";
import {
	AbilityAction,
	AbilitySubject,
	getSDRecordName,
	SDObject,
	SDRecord,
	sdSubject,
} from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";
import { pluralizeWithS, selectIndefiniteArticle } from "@salesdesk/salesdesk-utils";

import { useWebPrincipal } from "../../../../../auth";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { PanelTab, PanelTabList, PanelTabPanel, PanelTabPanels, PanelTabs } from "../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation } from "../../../hooks";
import { useAssociationLinkingContext } from "../hooks/useAssociationLinkingContext";
import { LinkingModalTabs } from "../types";
import { Dialog, DialogContent } from "../../../../Dialog";
import { LinkableRecordsList } from "./LinkableRecordsList";
import { CreateLinkedRecordTab } from "./CreateLinkedRecordTab";

interface AssociationLinkingModalProp {
	initialTab?: LinkingModalTabs;
	sourceLinkingRecord?: SDRecord;
	connectedAssociationObject?: SDObject;
	objectAssociation?: DirectedSDObjectAssociation;
	workspaceId?: number;
}

export function AssociationLinkingModal({
	objectAssociation,
	sourceLinkingRecord,
	connectedAssociationObject,
	workspaceId,
	initialTab = LinkingModalTabs.linking,
}: AssociationLinkingModalProp) {
	const principal = useWebPrincipal();
	const { setAssociationLinkingDetails } = useAssociationLinkingContext();

	const [currentTabIndex, setCurrentTabIndex] = useState<LinkingModalTabs>(initialTab);
	const [availableRecordCount, setAvailableRecordCount] = useState<number>();

	const { sdObject: sourceAssociationObject } = useGetObjectById(sourceLinkingRecord?._objectDefId);

	const open = connectedAssociationObject && sourceAssociationObject && sourceLinkingRecord && objectAssociation;

	useEffect(() => {
		if (!open) {
			setAvailableRecordCount(undefined);
		}
	}, [open]);

	useEffect(() => {
		setCurrentTabIndex(initialTab);
	}, [initialTab]);

	const closeModal = useCallback(() => {
		if (setAssociationLinkingDetails) setAssociationLinkingDetails(undefined, undefined);
	}, [setAssociationLinkingDetails]);

	const onLinkAssociationSuccess = useCallback(() => {
		closeModal();
	}, [closeModal]);

	if (!open) return null;

	const canCreateConnectedRecord =
		principal.can(
			AbilityAction.Create,
			sdSubject(AbilitySubject.Record, { _objectDefId: connectedAssociationObject._id })
		) && connectedAssociationObject._id !== mWorkspaceDef.ID;
	const sourceObjectName = sourceAssociationObject._displayName.toLowerCase();
	const connectedObjectDisplayName = connectedAssociationObject._displayName.toLowerCase();
	const connectedObjectPluralName = connectedAssociationObject._pluralName.toLowerCase();
	const multiplictyOne = objectAssociation.connectedObject.multiplicity === AssociationMultiplicity.ONE;
	const sourceRecordName = getSDRecordName(sourceAssociationObject, sourceLinkingRecord);

	return (
		<Dialog open={true} onOpenChange={() => closeModal()}>
			<DialogContent>
				<div className="min-w-[749px] max-w-[749px]">
					<div className="flex flex-col gap-8">
						<div className="flex flex-col gap-4">
							<div className="flex justify-between gap-4">
								<p className="text-h3 flex flex-col justify-center">
									{`Link this ${sourceObjectName} to ${
										multiplictyOne
											? `${selectIndefiniteArticle(connectedObjectDisplayName)} ${connectedObjectDisplayName}`
											: connectedObjectPluralName
									}`}
								</p>
								<Button startIcon={ICONS.cross} variant="text" onClick={closeModal} />
							</div>
							<p>
								{currentTabIndex === 0
									? `Select ${
											multiplictyOne ? selectIndefiniteArticle(connectedObjectDisplayName) : ""
									  } ${connectedObjectDisplayName} ${pluralizeWithS("record", multiplictyOne ? 1 : 2)}`
									: `Create a new ${connectedObjectDisplayName} record`}{" "}
								you'd like to link with {sourceObjectName} "<strong>{sourceRecordName}</strong>" under the{" "}
								<strong>{objectAssociation.connectedObject.label}</strong> association.
							</p>
						</div>
						<PanelTabs
							selectedIndex={currentTabIndex}
							onChange={(panelIndex) => {
								setCurrentTabIndex(panelIndex);
							}}
						>
							<PanelTabList>
								<PanelTab count={availableRecordCount}>Link {connectedObjectPluralName}</PanelTab>
								{canCreateConnectedRecord ? <PanelTab>Create new {connectedObjectDisplayName}</PanelTab> : null}
							</PanelTabList>
							<PanelTabPanels className="flex max-h-[449px] min-h-[449px]">
								<PanelTabPanel unmount={false} className="flex w-full flex-grow">
									<LinkableRecordsList
										workspaceId={workspaceId}
										objectAssociation={objectAssociation}
										sourceRecord={sourceLinkingRecord}
										connectedAssociationObject={connectedAssociationObject}
										availableRecordCount={availableRecordCount}
										setAvailableRecordCount={setAvailableRecordCount}
										closeModal={closeModal}
										onLinkingAssociationSuccess={onLinkAssociationSuccess}
									/>
								</PanelTabPanel>
								{canCreateConnectedRecord ? (
									<PanelTabPanel unmount={false} className="flex w-full flex-grow">
										<CreateLinkedRecordTab
											objectAssociation={objectAssociation}
											sourceRecord={sourceLinkingRecord}
											connectedAssociationObject={connectedAssociationObject}
											onCreateAssociationSuccess={onLinkAssociationSuccess}
											closeModal={closeModal}
										/>
									</PanelTabPanel>
								) : null}
							</PanelTabPanels>
						</PanelTabs>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
