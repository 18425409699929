import {
	BrowserNotificationNewWSMessage,
	BrowserNotificationWSMessageType,
	WebSocketMessage,
} from "@salesdesk/salesdesk-schemas";
import { AnyAction, Dispatch } from "redux";
import { WebSocketMessageHandler } from "../index";
import { triggerBrowserNotification } from "../../BrowserNotifications/store/browserNotificationsSlice";

export const webSocketMessageHandlerConstructor =
	(messageTypeHandleMessageMap: Map<string, WebSocketMessageHandler<any>>) => (webSocketMessage: WebSocketMessage) => {
		const handleMessage = messageTypeHandleMessageMap.get(webSocketMessage.type);
		if (handleMessage) handleMessage(webSocketMessage);
	};

export const defaultWebSocketMessageHandler = (dispatch: Dispatch<AnyAction>) =>
	webSocketMessageHandlerConstructor(
		new Map<string, WebSocketMessageHandler<any>>([
			[
				BrowserNotificationWSMessageType.New,
				(message: BrowserNotificationNewWSMessage) => {
					dispatch(triggerBrowserNotification(message));
				},
			],
		])
	);
