import { asConst } from "json-schema-to-ts";
import { RecordMatchType } from "./RecordMatchType";

export const searchHighlightSchema = asConst({
	title: "Search highlight",
	description: "Schema for a search highlight",
	type: "object",
	additionalProperties: false,
	required: ["matchType", "fragments"],
	properties: {
		matchType: {
			title: "Match type",
			description: "Type of match",
			type: "string",
			enum: Object.values(RecordMatchType),
		},
		fragments: {
			title: "Fragments",
			description: "Fragments containing searched-for phrase",
			type: "array",
			items: {
				type: "string",
			},
		},
	},
});
