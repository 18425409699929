import { useState } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { googleAuthLink, isGoogleSignInEnabled } from "../../landing/invitation/utils";
import { PoweredBySDFooter, TenantLogo } from "../components";
import { LoginForm } from "./LoginForm";
import { LoadingPage } from "../../../pages";

export function LoginPage() {
	const [isLoading, setIsLoading] = useState(true);

	return (
		<div className="flex h-screen w-full justify-center overflow-auto">
			{isLoading ? (
				<div className="absolute bottom-0 left-0 right-0 top-0 z-10">
					<LoadingPage />
				</div>
			) : null}
			<div className="flex min-w-[440px] max-w-[440px] flex-col justify-between gap-20">
				<div className="flex flex-col gap-10">
					<div className="flex h-[120px] flex-col justify-end">
						<TenantLogo />
					</div>
					<div className="flex w-full flex-col justify-between gap-8">
						<div className="flex flex-col items-center justify-center gap-2">
							<span className="text-body text-c_text_secondary">Welcome back!</span>
							<h3 className="text-h3">Login</h3>
						</div>
						<LoginForm onLoadFinish={() => setIsLoading(false)} />
						<div className="text-c_text_placeholder flex w-full items-center justify-center gap-2">
							<div className="bg-c_border_regular h-px w-full" />
							Or
							<div className="bg-c_border_regular h-px w-full" />
						</div>
						{isGoogleSignInEnabled() ? (
							<Button variant="outlined" fullWidth startIcon={ICONS.googleLogo} as="link" to={googleAuthLink()}>
								Continue with Google
							</Button>
						) : null}
					</div>
				</div>
				<div className="pb-10">
					<PoweredBySDFooter />
				</div>
			</div>
		</div>
	);
}
