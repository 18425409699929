export function uploadFileToSignedURL(file: File, signedUrl: string, onProgress?: (uploadProgress: number) => void) {
	return new Promise<void>((resolve, reject) => {
		const uploadRequest = new XMLHttpRequest();
		uploadRequest.open("PUT", signedUrl);

		if (onProgress) {
			uploadRequest.upload.onprogress = (e) => {
				const uploadProgress = Math.round((e.loaded / e.total) * 100);
				onProgress(uploadProgress);
			};
		}

		uploadRequest.onload = () => {
			if (uploadRequest.status !== 200) {
				onUploadFail(uploadRequest, reject);
				return;
			} else {
				resolve();
			}
		};

		uploadRequest.onerror = () => {
			onUploadFail(uploadRequest, reject);
		};

		uploadRequest.send(file);
	});
}

// TODO: Implement better error handling for file upload failure
function onUploadFail(req: XMLHttpRequest, reject: (reason?: any) => void) {
	console.error("File upload failed");
	reject({
		status: req.status,
		statusText: req.statusText,
	});
}
