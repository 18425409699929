import { ReactNode } from "react";
import { CanParameters } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../hooks/useWebPrincipal";

export type DisplayIfProps = {
	can?: CanParameters;
	canAny?: CanParameters[];
	canAll?: CanParameters[];
	children: ReactNode | ((can: boolean) => ReactNode);
};

/*
 *  can 'and' canAny 'and' canAll
 */
export function DisplayIf({ can, canAny, canAll, children }: DisplayIfProps) {
	const principal = useWebPrincipal();
	const allowed =
		!can && !canAny && !canAll
			? false
			: (can ? principal.can(...can) : true) &&
			  (canAny ? principal.canAny(canAny) : true) &&
			  (canAll ? principal.canAll(canAll) : true);

	return typeof children === "function" ? children(allowed) : allowed ? children : null;
}
