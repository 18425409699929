import { useState, useEffect } from "react";

import { SDObject, SDRecord, RecordQueryClauses, rsr } from "@salesdesk/salesdesk-schemas";
import {
	ObjectAssociationEndpointType,
	AssociationSide,
	mWorkspaceDef,
	AssociationMultiplicity,
} from "@salesdesk/salesdesk-model";

import {
	FilterData,
	mapToSearchQuery,
	EMPTY_FILTERS,
	SortingDetails,
	useInfiniteRecordSearch,
} from "../../../../../../records";
import { APP_CONFIG } from "../../../../../../../app/app_config";
import { DirectedSDObjectAssociation } from "../../../../../hooks";

interface UsePotentialAssociationRecordSearchArgs {
	sourceRecord: SDRecord;
	connectedAssociationObject: SDObject;
	objectAssociation: DirectedSDObjectAssociation;
	workspaceId?: number;
}

export function usePotentialAssociationRecordSearch({
	sourceRecord,
	connectedAssociationObject,
	objectAssociation,
	workspaceId,
}: UsePotentialAssociationRecordSearchArgs) {
	const [records, setRecords] = useState<SDRecord[]>([]);

	const [filter, setFilter] = useState<FilterData>(EMPTY_FILTERS);
	const [sorting, setSorting] = useState<SortingDetails[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>();

	const { updateSearchParams, loadNextPage, resultHits, isLoadingNewSearchParams, isLoadingNextPage } =
		useInfiniteRecordSearch({
			limit: APP_CONFIG.maxLocalSearchResults,
			sdRecords: records,
			onSDRecordsChange: setRecords,
			sdObjectFilter: connectedAssociationObject._id,
		});
	const hitCount = resultHits?.hitCount || 0;

	useEffect(() => {
		const andQuery: RecordQueryClauses[] = [
			{
				equals: {
					_deleted: false,
				},
			},
		];

		if (searchQuery != null && searchQuery.length > 0) {
			andQuery.push(rsr.matchAllPrefix(searchQuery));
		}

		const targetAssociationSide =
			objectAssociation.originObject === ObjectAssociationEndpointType.OBJECT_ONE
				? AssociationSide.RECORD_2
				: AssociationSide.RECORD_1;

		// AssociationSide is opposite since it's from the point of view of the target NOT the source record
		const notQuery: RecordQueryClauses[] = [
			rsr.equals("isTemplate", true),
			rsr.hasAssociationToRecordForObjectAssociation(objectAssociation.id, sourceRecord._id, targetAssociationSide),
		];

		// Filters out any records that already have an association if there's a multiplicity of one for the source object
		if (objectAssociation.sourceObject.multiplicity === AssociationMultiplicity.ONE) {
			notQuery.push(rsr.existsAssociationForObjectAssociation(objectAssociation.id, targetAssociationSide));
		}

		const associationFilters: RecordQueryClauses[] = filter?.filters.flatMap(mapToSearchQuery);

		let orQuery: RecordQueryClauses[] = [];

		if (associationFilters.length) {
			const filterType = filter.type;

			if (filterType === "AND") {
				andQuery.push(...associationFilters);
			} else if (filterType === "OR") {
				orQuery = associationFilters;
			}
		}

		// Filter by records shared with workspace if currently in a workspace context.
		// Exception for workspace records since they cannot be shared with workspaces
		if (workspaceId !== undefined && objectAssociation.connectedObject.id !== mWorkspaceDef.ID) {
			andQuery.push(rsr.isSharedWithWorkspace(workspaceId));
		}

		updateSearchParams({
			query: {
				and: andQuery,
				not: notQuery,
				or: orQuery,
			},
			sort: sorting.map((value) => ({ [value.fieldId]: { order: value.order } })),
		});
	}, [
		sourceRecord,
		updateSearchParams,
		filter,
		connectedAssociationObject,
		sorting,
		searchQuery,
		objectAssociation,
		workspaceId,
	]);

	return {
		records,
		loadNextPage,
		hitCount,
		isLoadingNewSearchParams,
		isLoadingNextPage,
		filter,
		setFilter,
		sorting,
		setSorting,
		setSearchQuery,
	};
}
