import { asConst } from "json-schema-to-ts";
import { rangeClausePropertyValueSchema } from "./rangeClausePropertyValueSchema";

export const rangeClauseSchema = asConst({
	title: "Range",
	description: "Will match any record where the given field value is in the provided range",
	type: "object",
	additionalProperties: false,
	required: ["range"],
	properties: {
		range: rangeClausePropertyValueSchema,
	},
	nullable: false,
});
