import { forwardRef } from "react";

import { EditFieldProps } from "../../../../types";
import { TypeaheadSelect, SelectOptionId } from "../../../../../inputs";
import { OptionFieldComponentSettings } from "../types";
import { useGetOptionFieldDetails } from "../hooks/useGetOptionFieldDetails";

interface EditSingleOptionFieldProps extends EditFieldProps {
	value?: SelectOptionId;
	onChange: (newValue?: SelectOptionId) => void;
	placeholder?: string;
	optionFieldSettings: OptionFieldComponentSettings;
}

export const EditSingleOptionField = forwardRef<HTMLButtonElement, EditSingleOptionFieldProps>(
	({ inputRef, optionFieldSettings, ...selectProps }, ref) => {
		const { getOptions, getOptionsFromIds, optionDisplayVariant } = useGetOptionFieldDetails(optionFieldSettings);

		return (
			<TypeaheadSelect
				{...selectProps}
				ref={ref}
				getOptionsFromIds={getOptionsFromIds}
				getOptions={getOptions}
				optionDisplayVariant={optionDisplayVariant}
			/>
		);
	}
);
