import { useEffect } from "react";

import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";
import { ChatMessageGroupContainer, useChatMessageGroups, useChatMessages } from "../../../../../../../chat";
import { PanelTabPanel } from "../../../../../../../../components/PanelTabs";
import { ChatLoadingView } from "../../../../../../../chat/components/ChatLoadingView";

interface ChatHistoryPanelProps {
	onUpdateCount?: (count: number) => void;
}

export function ChatHistoryPanel({ onUpdateCount }: ChatHistoryPanelProps) {
	const { sdRecord } = useRecordDetailsContext();

	const { sortedChatMessages, isLoading } = useChatMessages(sdRecord?._id, null, null, 1000);
	const messageGroups = useChatMessageGroups(sortedChatMessages);

	const totalMessages = sortedChatMessages.length;

	useEffect(() => {
		if (onUpdateCount) {
			onUpdateCount(totalMessages);
		}
	}, [totalMessages, onUpdateCount]);

	return (
		<PanelTabPanel className="h-full w-full pr-8" unmount={false}>
			<div className="flex h-full flex-col overflow-hidden">
				<div className="text-label py-4">Chat history</div>
				<div className="flex max-h-full w-full flex-col gap-4 overflow-auto pb-8">
					{isLoading ? (
						<ChatLoadingView />
					) : (
						<>
							{messageGroups.map((messageGroup) => (
								<ChatMessageGroupContainer
									key={`${messageGroup.userRecord?._id}-${messageGroup.messages[0].createdAt}`}
									messageGroup={messageGroup}
								/>
							))}
							{messageGroups.length === 0 ? (
								<div className="text-c_text_placeholder text-body-sm text-center">
									This record has no chat messages to display.
								</div>
							) : null}
						</>
					)}
				</div>
			</div>
		</PanelTabPanel>
	);
}
