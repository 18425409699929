import { ApiTag, ApiTagType } from "./SDApi";

export function buildApiTagId<TTagType extends ApiTagType>(tagType: TTagType, id: string | number): ApiTag {
	return { type: tagType, id: id };
}

export function buildApiSingleTag<TResult>(
	result: TResult | undefined,
	getId: (result: TResult) => string | number,
	tagType: ApiTagType
): ApiTag[] {
	return result == null ? [] : [buildApiTagId(tagType, getId(result))];
}

// TODO:  Should be able to use keyof here to pass in the key for the id property
export function buildApiTagsList<TResult>(
	results: TResult[] | undefined,
	getId: (result: TResult) => string | number,
	tagType: ApiTagType
): ApiTag[] {
	return results == null
		? [{ type: tagType, id: "LIST" }]
		: [{ type: tagType, id: "LIST" }, ...results.map((result) => buildApiTagId(tagType, getId(result)))];
}

export function buildApiInvalidatedTagList<TResult>(
	result: TResult | undefined,
	getId: (result: TResult) => string | number,
	tagType: ApiTagType
): ApiTag[] {
	return result == null ? [] : buildApiTagsList([result], getId, tagType);
}

export function buildApiInvalidatedTagsList<TResult>(
	results: TResult[] | undefined,
	getId: (result: TResult) => string | number,
	tagType: ApiTagType
): ApiTag[] {
	return results == null ? [] : buildApiTagsList(results, getId, tagType);
}
