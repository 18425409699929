import { useCallback, useMemo } from "react";

import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import {
	getSDObjectFieldByName,
	getSDRecordFieldValue,
	FieldValue,
	getStatusIndicatorFieldForObject,
} from "@salesdesk/salesdesk-schemas";
import { Icon } from "@salesdesk/daisy-ui";

import { useRecordDetailsContext } from "../../../../../../hooks/useRecordDetailsContext";
import { useUpdateRecord } from "../../../../../../../../hooks";
import { StatusIndicator } from "../../../../../../../../../../components/StatusIndicator";
import { SelectOption, SelectOptionId } from "../../../../../../../../../inputs";
import { useToast } from "../../../../../../../../../Toasts";

export function RecordStatusIndicator() {
	const { sdObject, sdRecord } = useRecordDetailsContext();
	const toast = useToast();

	const { value, statusIndicatorField, options, outcomeOptions } = useMemo(() => {
		if (!sdObject || !sdRecord) return {};

		let value: SelectOptionId | undefined = undefined;
		const options: SelectOption[] = [];
		const outcomeOptions: SelectOption[] = [];

		const { fieldName, outcomeNames } = getStatusIndicatorFieldForObject(sdObject._id);
		if (!fieldName || !outcomeNames) return {};

		const statusIndicatorField = getSDObjectFieldByName(sdObject, fieldName);
		if (!statusIndicatorField) return {};

		value = getSDRecordFieldValue(sdRecord, statusIndicatorField._id)?._value as SelectOptionId;

		(statusIndicatorField?._optionValues as SelectOption[])?.forEach((option) => {
			if (outcomeNames.includes(option.name)) {
				outcomeOptions.push(option);
			} else {
				options.push(option);
			}
		});

		return {
			value,
			statusIndicatorField,
			options,
			outcomeOptions,
		};
	}, [sdObject, sdRecord]);

	const { updateRecord } = useUpdateRecord();

	const patchFieldUpdate = useCallback(
		(updatedValue: SelectOptionId) => {
			if (!sdObject || !sdRecord || !statusIndicatorField) return;

			const updatedFields = [
				{
					_fieldId: statusIndicatorField._id,
					_value: updatedValue,
				},
			] satisfies FieldValue[];

			const messageParams = {
				record: sdRecord._objectDefId === mWorkspaceDef.ID ? sdObject._displayName : "Record",
			};

			updateRecord({
				record: sdRecord,
				updatedFields,
			})
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "record_updated", messageParams });
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_updated", messageParams });
				});
		},
		[sdObject, sdRecord, updateRecord, statusIndicatorField, toast]
	);

	if (!value || !options?.length || !outcomeOptions?.length || !statusIndicatorField) return null;

	return (
		<div className="mb-6">
			<div className="text-c_text_disabled text-label-xs mb-3 flex items-center gap-2">
				<Icon icon={statusIndicatorField._icon} className="text-c_icon_disabled flex" size="sm" />
				{statusIndicatorField?._displayName}
			</div>
			<StatusIndicator value={value} onChange={patchFieldUpdate} options={options} outcomes={outcomeOptions} />
		</div>
	);
}
