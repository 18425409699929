import { AbilityAction, AbilitySubject, CanParameters, SDRecord, sdSubject } from "@salesdesk/salesdesk-schemas";

export const getCanChangeRecordOwnerParameters = (record: SDRecord): CanParameters => [
	AbilityAction.ChangeOwner,
	sdSubject(AbilitySubject.Record, record),
];

export type UpdateRecordsCallback = (updatedRecords: SDRecord[]) => void;

export type SetBulkChangeOwnerModalRecords = (
	records: SDRecord[],
	onUpdateRecordsCallback?: UpdateRecordsCallback
) => void;
