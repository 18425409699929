import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { InternalGlobalSearchbar, RecordCreateMenu } from "../../records";
import { NotificationsButton } from "../../notifications";
import { Tooltip, TooltipDelayGroup } from "@salesdesk/daisy-ui";
import { Button } from "@salesdesk/daisy-ui";
import { UserMenu } from "../../UserMenu";
import { useGetObjectById } from "../../../hooks/useGetObjectById";
import { PATHS } from "../../../routes";

interface HeaderProps {
	navIsOpen?: boolean;
	onNavOpen: () => void;
}
export function Header({ navIsOpen, onNavOpen }: HeaderProps) {
	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);

	return (
		<TooltipDelayGroup>
			<div className="bg-c_bg_02 flex h-16 w-full shrink-0 items-center px-4">
				<div className={`transition-opacity ${navIsOpen ? "opacity-0 ease-out" : "opacity-100 delay-75 ease-out"}`}>
					<Tooltip key={`${navIsOpen}-openTooltip`} text="Open navbar" placement="right">
						<Button startIcon="ph-arrow-line-right" variant="secondary" onClick={onNavOpen} />
					</Tooltip>
				</div>
				<div className="absolute inset-x-0 z-50 mx-auto w-fit">
					<InternalGlobalSearchbar />
				</div>
				<div className="ml-auto flex items-center gap-6">
					{workspaceObject ? (
						<Button
							id="userflow-workspaces-link"
							as="link"
							to={PATHS.OBJECT_RECORD_BOARD(workspaceObject)}
							size="sm"
							variant="primary_text"
							startIcon={workspaceObject._icon}
						>
							{workspaceObject._pluralName}
						</Button>
					) : null}
					<RecordCreateMenu />
					<NotificationsButton />
					<UserMenu />
				</div>
			</div>
		</TooltipDelayGroup>
	);
}
