import { sdRecordSchema } from "./sdRecordSchema";
import { asConst } from "json-schema-to-ts";
import { SDRecord } from "./SDRecord";
import { nameof } from "@salesdesk/salesdesk-utils";
import { recordIdSchema, recordVersionSchema } from "./properties";

// TODO: _id and _version are default OpenSearchFields.  Can remove this when we remove _ prefixes
const { _id, _version, _dataInst, sharedWorkspaceIds, ...summaryProperties } = sdRecordSchema.properties;

export const sdRecordSummarySchema = asConst({
	title: "SDRecordSummarySchema",
	type: "object",
	additionalProperties: false,
	required: [
		// sdRecordSchema.required has been asConst so is strongly typed to (contents|of|array)[].  Need to remove the property
		// names that are filtered out from the typing otherwise the type derived from this schema does not get generated
		// correctly
		...(sdRecordSchema.required.filter(
			(p) =>
				![
					nameof<SDRecord>("_id"),
					nameof<SDRecord>("_version"),
					nameof<SDRecord>("_dataInst"),
					nameof<SDRecord>("sharedWorkspaceIds"),
				].includes(p)
		) as Exclude<(typeof sdRecordSchema.required)[number], "_id" | "_version" | "_dataInst" | "sharedWorkspaceIds">[]),
		// TODO: _id and _version are default OpenSearchFields.  Can remove this when we remove _ prefixes
		"id",
		"version",
	],
	properties: {
		id: recordIdSchema,
		version: recordVersionSchema,
		...summaryProperties,
	},
});
