import { LocalParticipant, RemoteParticipant } from "twilio-video";

import { ParticipantAudioTrack } from "./ParticipantAudioTrack";
import { useTracks, useVideoCallLogicContext } from "../../../../VideoCallProvider";

/*
  This ParticipantAudioTracks component will mount the audio track for all participants in the room.
  It is in a separate component so that the audio tracks will always be mounted, and that they will never be
  unnecessarily unmounted/mounted as the user switches between Gallery View and Speaker View.
*/
export function ParticipantAudioTracks() {
	const { room, remoteParticipants } = useVideoCallLogicContext();

	return (
		<>
			{room ? <ParticipantAudio participant={room?.localParticipant} mount={false} /> : null}
			{remoteParticipants.map((participant) => (
				<ParticipantAudio key={participant.sid} participant={participant} />
			))}
		</>
	);
}

interface ParticipantAudioProps {
	participant: RemoteParticipant | LocalParticipant;
	mount?: boolean;
}

function ParticipantAudio({ participant, mount = true }: ParticipantAudioProps) {
	const tracks = useTracks(participant);
	const audioTrack = tracks.find((track) => track.kind === "audio");

	if (audioTrack?.kind === "audio")
		return <ParticipantAudioTrack participantIdentity={participant.identity} track={audioTrack} mount={mount} />;

	return null;
}
