import { useCallback, useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { SDRecord, RecordsSearchRequest, SearchHighlight } from "@salesdesk/salesdesk-schemas";

import { useGetRecordsQuery, useSearchRecordsQuery } from "../api/recordsApi";

// Fixed reference to an empty array, otherwise each render will create a new
// empty array while the records query is loading
const EMPTY_ARRAY: SDRecord[] = [];
const EMPTY_HIGHLIGHT_MAP = {};

export const useRecordSearch = () => {
	const [searchParams, setSearchParams] = useState<RecordsSearchRequest>();
	const [previousSearchRequestID, setPreviousSearchRequestID] = useState<string>();

	const [highlightsMap, setHighlightsMap] = useState<Record<number, SearchHighlight[]>>({});

	const [cachedHighlightsMap, setCachedHighlightsMap] = useState<Record<number, SearchHighlight[]>>({});
	const [cachedRequestParams, setCachedRequestParams] = useState<RecordsSearchRequest>();

	const {
		data: searchHits,
		isFetching: fetchingSearch,
		isSuccess: searchSucess,
		requestId: searchRequestID,
		originalArgs,
		refetch,
	} = useSearchRecordsQuery(searchParams || skipToken, { refetchOnMountOrArgChange: true });

	// Fetches the records using the record ids fetched by the searchRecords query above
	const [recordIds, setRecordIds] = useState<number[]>([]);

	const noRecordsToFetch = !searchSucess || !recordIds.length;

	const {
		data: recordResults = EMPTY_ARRAY,
		currentData,
		isFetching: fetchingRecords,
	} = useGetRecordsQuery(noRecordsToFetch ? skipToken : recordIds);

	useEffect(() => {
		if (!searchHits) {
			setRecordIds([]);
			setHighlightsMap(EMPTY_HIGHLIGHT_MAP);
		}

		const hitIds: number[] = [];
		const highlightsMap: Record<number, SearchHighlight[]> = {};

		searchHits?.hits.forEach((hit) => {
			const { source, highlight } = hit;

			const id = source.id;
			hitIds.push(id);

			if (highlight) {
				highlightsMap[id] = highlight;
			}
		});

		setHighlightsMap(highlightsMap);
		setRecordIds(hitIds);
	}, [searchHits]);

	// Required to verify that the search request has just finished fetching but staggered via
	// a useEffect to ensure that if 'useGetRecordsQuery' begins fetching on the next render,
	// 'isLoading' remains true the entire time.
	//
	// Otherwise there is a state where 'fetchingSearch' is false as it has just fetched
	// and 'fetchingRecords' is false because it hasn't been triggered yet by the new search hits.
	// This causes a brief instance where the returned 'isLoading' becomes 'false' for a brief couple of milliseconds.
	useEffect(() => {
		if (!fetchingSearch) {
			setPreviousSearchRequestID(searchRequestID);
		}
	}, [fetchingSearch, searchRequestID]);

	const isLoading =
		!searchRequestID || searchRequestID !== previousSearchRequestID || (fetchingRecords && !currentData);

	const updateSearchParams = useCallback(
		(newSearchParms: RecordsSearchRequest) => {
			setSearchParams((oldSearchParams) => {
				// Triggers a refetch if the search params are being set to the same values
				if (JSON.stringify(oldSearchParams) === JSON.stringify(newSearchParms)) {
					setPreviousSearchRequestID(undefined);
					setTimeout(refetch, 100);
					return oldSearchParams;
				}
				return newSearchParms;
			});
		},
		[setSearchParams, refetch]
	);

	// This logic exists to keep the currentResultParams and currentResultHighlights in sync with the recordResults
	// being returned by this hook. While new results are being fetched, we use the cached data from the previous
	// search to prevent flickering in the UX where this data is used.
	//
	// Once the results for the current search are fetched we switch from the cached data to the current data.
	const { currentResultParams, currentResultHighlights } = useMemo(() => {
		return isLoading
			? { currentResultParams: cachedRequestParams, currentResultHighlights: cachedHighlightsMap }
			: { currentResultParams: originalArgs, currentResultHighlights: highlightsMap };
	}, [isLoading, cachedRequestParams, cachedHighlightsMap, originalArgs, highlightsMap]);

	// Caches the request params and the highlights map for a search once the search is complete.
	useEffect(() => {
		if (isLoading) {
			return;
		}

		setCachedHighlightsMap(highlightsMap);
		setCachedRequestParams(originalArgs);
	}, [isLoading, highlightsMap, originalArgs]);

	return {
		searchHits,
		searchResults: noRecordsToFetch ? EMPTY_ARRAY : recordResults,
		currentResultParams,
		currentResultHighlights,
		isLoading,
		updateSearchParams,
	};
};
