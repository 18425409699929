import { useEffect, useState, useCallback } from "react";
import clsx from "clsx";

import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";
import { RecordQueryClauses, SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectById } from "../../../hooks/useGetObjectById";
import { SortingDetails } from "../../records";
import { RecordAssociationList } from "./RecordAssociationList";
import { useOpenLinkingModal } from "./AssociationLinkingModal";
import { DirectedSDObjectAssociation } from "../hooks";
import { RecordAssociationsControls } from "./RecordAssociationsControls";
import { RecordAssociationControlsVariant, RecordAssociationOpeningVariant } from "../types";

interface RecordAssociationsProps {
	sourceRecord?: SDRecord;
	onUpdateCount: (count: number) => void;
	objectAssociation: DirectedSDObjectAssociation;
	onAssociationObjectLoad?: (associationObject: SDObject) => void;
	limit?: number;
	infiniteScroll?: boolean;
	controlsVariant?: RecordAssociationControlsVariant;
	openingVariant?: RecordAssociationOpeningVariant;
}

export function RecordAssociations({
	sourceRecord,
	onUpdateCount,
	objectAssociation,
	onAssociationObjectLoad,
	infiniteScroll,
	limit,
	controlsVariant,
	openingVariant,
}: RecordAssociationsProps) {
	const { sdObject: associationObject } = useGetObjectById(objectAssociation.connectedObject.id);

	const [sorting, setSorting] = useState<SortingDetails[]>([]);
	const [filters, setFilters] = useState<RecordQueryClauses[]>([]);

	const [searchQuery, setSearchQuery] = useState<string>();

	const [associationAmount, setAssociationAmount] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (onAssociationObjectLoad && associationObject) {
			onAssociationObjectLoad(associationObject);
		}
	}, [onAssociationObjectLoad, associationObject]);

	const openLinkingModal = useOpenLinkingModal(sourceRecord ?? undefined, associationObject, objectAssociation);

	const updateCount = useCallback(
		(count: number) => {
			setAssociationAmount(count);
			onUpdateCount(count);
		},
		[onUpdateCount]
	);

	if (!associationObject) {
		return null;
	}

	const multiplictyOne = objectAssociation.connectedObject.multiplicity === AssociationMultiplicity.ONE;

	const showFilterAndSortingControls = Boolean(sorting.length || searchQuery?.length || (associationAmount || 0) >= 2);
	const showLinkingControls = (associationAmount || 0) > 0 && !multiplictyOne && Boolean(openLinkingModal);
	const initialLoading = associationAmount === undefined;

	return (
		<div className={clsx("flex flex-col gap-4", infiniteScroll && "max-h-full overflow-hidden")}>
			<RecordAssociationsControls
				associationObject={associationObject}
				onFiltersChange={setFilters}
				onSortingChange={setSorting}
				onQueryChange={setSearchQuery}
				openLinkingModal={openLinkingModal}
				showFilterAndSortingControls={showFilterAndSortingControls}
				showLinkingControls={showLinkingControls}
				initialLoading={multiplictyOne ? false : initialLoading}
				variant={controlsVariant}
			/>
			<RecordAssociationList
				associationObject={associationObject}
				objectAssociation={objectAssociation}
				sourceRecord={sourceRecord}
				onUpdateCount={updateCount}
				extraFilters={filters}
				sorting={sorting}
				searchQuery={searchQuery}
				onLinkAssociationClick={openLinkingModal}
				limit={limit}
				infiniteScroll={infiniteScroll}
				openingVariant={openingVariant}
			/>
		</div>
	);
}
