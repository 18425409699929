import { useMemo } from "react";

import { Button, Icon, Tooltip } from "@salesdesk/daisy-ui";
import { ChannelType } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { PATHS } from "../../../../../../../routes";
import { RecordPreviewPopover } from "../../../../../../records";
import { MenuContents, MenuItem, PopoverMenu } from "../../../../../../menu";
import { useChannelDisplayNameSelector, useChimeChannelStatusSelector } from "../../../../../store";
import { useCreateHuddle, useGetActiveHuddle, useGetChannelRelatedRecordId } from "../../../../../hooks";
import { ChannelDetails, LoadingStatus } from "../../../../../types";
import { HuddleButton } from "../../../../HuddleButton";
import { ChannelIcon } from "./ChannelIcon";
import { ChannelActiveState } from "./ChannelActiveState";
import { ChannelBookMeetingButton } from "./ChannelBookMeetingButton";

interface ChannelBannerProps {
	channelDetails: ChannelDetails;
	onBack: () => void;
	onViewChannelInfo?: () => void;
}

export function ChannelBanner({ channelDetails, onViewChannelInfo, onBack }: ChannelBannerProps) {
	const { arn, channelMetadata } = channelDetails;
	const { channelDetailsStatus } = useChimeChannelStatusSelector(arn);

	const channelName = useChannelDisplayNameSelector(arn);

	const relatedRecordId = useGetChannelRelatedRecordId(channelDetails);

	const { createHuddle, isCreatingHuddle } = useCreateHuddle(arn);
	const { activeHuddle, initialLoad } = useGetActiveHuddle(arn);

	const popoverMenuContents: MenuContents = useMemo(() => {
		const { channelType } = channelMetadata;
		const menuItems: MenuItem[] = [];

		if (onViewChannelInfo) {
			menuItems.push({
				text: "Conversation info",
				icon: ICONS.info,
				onClick: onViewChannelInfo,
				type: "button",
			});
		}

		if (channelType === ChannelType.Workspace) {
			menuItems.push({
				text: "Enter workspace",
				icon: ICONS.signIn,
				link: PATHS.WORKSPACE_OVERVIEW(channelMetadata.workspaceId),
				type: "link",
			});
		}

		return menuItems;
	}, [channelMetadata, onViewChannelInfo]);

	const failedToLoadChannelDetails = channelDetailsStatus === LoadingStatus.Error;

	return (
		<div className="bg-c_bg_01 border-c_border_regular z-10 flex h-12 w-full shrink-0 items-center gap-1 overflow-hidden border-b px-4">
			<Button size="xs" variant="text" startIcon={ICONS.caretLeft} onClick={onBack} />
			<div className="flex w-full items-start justify-start gap-0.5 overflow-hidden">
				{failedToLoadChannelDetails ? (
					<div className="text-label-sm text-c_danger_01 flex items-center gap-2">
						<Icon size="sm" className="flex" icon={ICONS.warningCircle} />
						Failed to load channel
					</div>
				) : (
					<>
						<ChannelIcon channelDetails={channelDetails} />
						<div className={"text-label-sm flex w-full flex-col truncate"}>
							<RecordPreviewPopover recordId={relatedRecordId}>
								{channelName ? (
									<Tooltip placement="bottom" text={channelName} showOnTruncated noWrap>
										<div className="w-full truncate">{channelName}</div>
									</Tooltip>
								) : (
									<Skeleton className="mt-0.5 h-4 w-full" />
								)}
							</RecordPreviewPopover>
							<ChannelActiveState channelDetails={channelDetails} />
						</div>
					</>
				)}
			</div>
			{failedToLoadChannelDetails ? null : (
				<div className="ml-auto flex items-center gap-1">
					{popoverMenuContents.length ? (
						<PopoverMenu menuContents={popoverMenuContents} placement="bottom-end">
							<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
						</PopoverMenu>
					) : null}
					<HuddleButton
						variant="banner"
						activeHuddle={activeHuddle}
						createHuddle={createHuddle}
						isLoadingHuddle={isCreatingHuddle || initialLoad}
					/>
					{/* 
					
						TODO: https://salesdesk101.atlassian.net/browse/SAL-3351 
							- Implement channel book meeting button for workspace channels
					*/}
					{channelMetadata.channelType === ChannelType.Workspace ? null : <ChannelBookMeetingButton arn={arn} />}
				</div>
			)}
		</div>
	);
}
