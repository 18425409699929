import { Dispatch, SetStateAction, createContext, useContext } from "react";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

export interface BulkEditContext {
	inBulkEditMode: boolean;
	setInBulkEditMode: Dispatch<SetStateAction<boolean>>;
	toggleBulkEditMode: () => void;

	selectedRecords: SDRecord[];
	setSelectedRecords: Dispatch<SetStateAction<SDRecord[]>>;
	toggleRecordSelection: (recordId: SDRecord) => void;

	setOnSelectAll: Dispatch<SetStateAction<(() => SDRecord[]) | null>>;
	handleSelectAll: () => void;

	allSelected: boolean;
	setAllSelected: Dispatch<SetStateAction<boolean>>;
}

export const BulkEditContext = createContext<BulkEditContext | null>(null);

export function useBulkEditContext() {
	const context = useContext(BulkEditContext);

	if (context == null) {
		throw new Error("Bulk Edit components must be wrapped in <BulkEditProvider />");
	}

	return context;
}
