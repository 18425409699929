import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { ChannelType } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { ChannelDetails } from "../../../../../types";
import { useGetChannelRelatedRecordId, useGetDMChannelUserRecord } from "../../../../../hooks";
import { useGetObjectById } from "../../../../../../../hooks";
import { UserRecordAvatar } from "../../../../../../users";
import { RecordPreviewPopover } from "../../../../../../records";

interface ChannelIconProps {
	channelDetails: ChannelDetails;
}

export function ChannelIcon({ channelDetails }: ChannelIconProps) {
	const { channelMetadata } = channelDetails;
	const { channelType } = channelMetadata;

	const isInternalChannel = channelType === ChannelType.Internal;
	const isWorkspaceChannel = channelType === ChannelType.Workspace;
	const isDMChannel = channelType === ChannelType.DirectMessage;

	const relatedRecordId = useGetChannelRelatedRecordId(channelDetails);

	const { sdObject: workspaceObject } = useGetObjectById(isWorkspaceChannel ? mWorkspaceDef.ID : undefined);
	const { userRecord: dmUserRecord } = useGetDMChannelUserRecord(channelDetails);

	return (
		<RecordPreviewPopover recordId={relatedRecordId}>
			<div className={clsx("text-c_icon_inverted relative w-[38px] shrink-0", isWorkspaceChannel ? "h-[38px]" : "h-8")}>
				{isDMChannel ? (
					<UserRecordAvatar userRecord={dmUserRecord} withBorder size="sm" showStatus />
				) : (
					<div
						className={clsx(
							isInternalChannel ? "bg-c_warning_focus" : "bg-c_action_focus",
							"border-c_bg_01 flex size-8 shrink-0 items-center justify-center rounded-full border-2"
						)}
					>
						<Icon icon={ICONS.usersThree} className="flex" />
					</div>
				)}
				{isWorkspaceChannel ? (
					<div className="border-c_bg_01 bg-c_brand_primary absolute bottom-0 right-0 flex size-6 items-center justify-center rounded-full border-2">
						<Icon size="sm" icon={workspaceObject?._icon ?? ICONS.circlesThreePlus} className="flex" />
					</div>
				) : null}
			</div>
		</RecordPreviewPopover>
	);
}
