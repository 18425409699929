import { asConst } from "json-schema-to-ts";
import { subQuerySchema } from "./subQuerySchema";

export const subQueryClauseProperty = "query";

export const subQueryClauseSchema = asConst({
	title: "Sub Query Clause",
	type: "object",
	additionalProperties: false,
	required: [subQueryClauseProperty],
	properties: {
		query: subQuerySchema,
	},
	nullable: false,
});
