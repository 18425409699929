import { useCallback, useEffect, useRef, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { SelectOptionId, TypeaheadSelect } from "../../../../../../inputs";
import { useGetOptionFieldDetails } from "../../../../../../fields";

interface WorkspaceMemberSectionProps {
	title: string;
	description: string;
	memberObjectId: number;
	onMembersChange: (memberObjectId: number, selectedRecords: SDRecord[]) => void;
	initialValue?: SDRecord[];
}

export function WorkspaceMemberSection({
	title,
	description,
	memberObjectId,
	initialValue,
	onMembersChange,
}: WorkspaceMemberSectionProps) {
	const initialValueRef = useRef(initialValue);
	const [typeaheadValue, setTypeaheadValue] = useState<SelectOptionId[]>(
		initialValueRef.current?.map((record) => record._id) ?? []
	);

	const { getOptions, getOptionsFromIds, optionDisplayVariant } = useGetOptionFieldDetails({
		variant: "records",
		baseObjectId: memberObjectId,
	});

	const onChange = useCallback(
		async (selectedRecordIds: SelectOptionId[]) => {
			setTypeaheadValue(selectedRecordIds);

			const selectedRecordOptions = await getOptionsFromIds(selectedRecordIds);

			const selectedRecords = selectedRecordOptions
				.map((option) => option.sdRecord)
				.filter((record) => record !== undefined) as SDRecord[];

			onMembersChange(memberObjectId, selectedRecords);
		},
		[getOptionsFromIds, memberObjectId, onMembersChange]
	);

	// Update with the initial value on mount if one is set
	useEffect(() => {
		if (!initialValueRef.current) {
			return;
		}

		onMembersChange(memberObjectId, initialValueRef.current);
	}, [memberObjectId, onMembersChange]);

	return (
		<div className="flex flex-col gap-4">
			<div className="text-label">{title}</div>
			<p>{description}</p>
			<div className="flex max-w-[500px]">
				<TypeaheadSelect
					value={typeaheadValue}
					onChange={onChange}
					isMultiselect={true}
					getOptionsFromIds={getOptionsFromIds}
					getOptions={getOptions}
					optionDisplayVariant={optionDisplayVariant}
				/>
			</div>
		</div>
	);
}
