import { useNotificationRuleDialogContext } from "../../hooks/useNotificationRuleDialogContext";
import { useCallback, useState } from "react";
import { FieldValueSelector } from "./ruleValueSelectors/FieldValueSelector";
import { RecordValueSelector } from "./ruleValueSelectors/RecordValueSelector";
import { ChannelValueSelector } from "./ruleValueSelectors/ChannelValueSelector";
import { CreateNotificationRuleFormDialog } from "./CreateNotificationRuleFormDialog";
import { ActionRuleElement } from "./ruleElements/ActionRuleElement";
import { RecordRuleElement } from "./ruleElements/RecordRuleElement";
import { ChannelsRuleElement } from "./ruleElements/ChannelsRuleElement";
import { UserActivityBadge } from "../common/UserActivityBadge";
import { DownArrowIcon } from "./ruleElements/DownArrowIcon";
import { UserRuleElement } from "./ruleElements/user/UserRuleElement";
import { UserValueSelector } from "./ruleValueSelectors/user/UserValueSelector";
import { WorkspaceValueSelector } from "./ruleValueSelectors/WorkspaceValueSelector";
import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { SelectedPanel } from "./sections/SelectedPanel";
import { SelectorPanel } from "./sections/SelectorPanel";
import { SectionBreak } from "./sections/SectionBreak";
import { Section } from "./sections/Section";
import { Select, SelectOption, SelectOptionId } from "../../../../inputs";
import { UserActionValueSelector } from "./ruleValueSelectors/user/UserActionValueSelector";
import { RecordActionValueSelector } from "./ruleValueSelectors/user/RecordActionValueSelector";
import { useWebPrincipal } from "../../../../../auth";
import { useNotificationRuleConfigs } from "../../hooks/useNotificationRuleConfigs";

const userDisplayId = "user";
const recordDisplayId = "record";
type Display = "user" | "record";
const displayOptions: SelectOption[] = [
	{ id: recordDisplayId, name: "Record interaction" },
	{ id: userDisplayId, name: "Other interaction" },
];

export function CreateUserNotificationRuleDialog() {
	const principal = useWebPrincipal();
	const {
		rule,
		selectedRuleConfig,
		currentSelection,
		selectedObjectId,
		setSelectedObjectId,
		setSelectedField,
		selectedUsers,
		setSelectedRecord,
		setSelectedAction,
		setSelectedWorkspaceId,
		setSelectedUsers,
		setCurrentSelection,
	} = useNotificationRuleDialogContext();

	const getError = useCallback(() => {
		if (selectedUsers.length !== 1) return "Please select a user";
		if (selectedObjectId && selectedRuleConfig?.objectIdNotRequired) return "Please select an action";
		return undefined;
	}, [selectedObjectId, selectedRuleConfig, selectedUsers.length]);

	const userRuleConfigs = useNotificationRuleConfigs(NotificationRuleType.User, undefined).filter(
		(config) => config.objectIdNotRequired
	);

	const [selectedDisplay, setSelectedDisplay] = useState<Display>(
		rule && userRuleConfigs.some((config) => config.eventType === rule.eventType) ? "user" : "record"
	);

	const onDisplayChange = useCallback(
		(value: Display) => {
			setSelectedObjectId(undefined);
			setSelectedAction(undefined);
			setSelectedField(undefined);
			setSelectedRecord(undefined);
			setSelectedWorkspaceId(undefined);
			setSelectedDisplay(value);
			if (value === "user") setSelectedWorkspaceId(undefined);
			if (value === "record" && principal.IsCustomerWithSingleWorkspace)
				setSelectedWorkspaceId(principal.AuthorizedWorkspaceIds[0]);
		},
		[
			principal.AuthorizedWorkspaceIds,
			principal.IsCustomerWithSingleWorkspace,
			setSelectedAction,
			setSelectedField,
			setSelectedObjectId,
			setSelectedRecord,
			setSelectedWorkspaceId,
		]
	);

	const onWorkspaceChange = useCallback(
		(value: SelectOptionId | undefined) => {
			if (value != null) {
				setSelectedObjectId(undefined);
				setSelectedAction(undefined);
				setSelectedField(undefined);
				setSelectedUsers([]);
				setSelectedRecord(undefined);
				setCurrentSelection(undefined);
			}
			setSelectedWorkspaceId(value ? Number(value) : undefined);
		},
		[
			setSelectedWorkspaceId,
			setCurrentSelection,
			setSelectedObjectId,
			setSelectedAction,
			setSelectedField,
			setSelectedUsers,
			setSelectedRecord,
		]
	);

	let ruleValueSelector = undefined;
	if (currentSelection === "user") ruleValueSelector = <UserValueSelector key={currentSelection} />;
	if (currentSelection === "eventType")
		ruleValueSelector =
			selectedDisplay === "record" ? (
				<RecordActionValueSelector key="recordEventType" />
			) : (
				<UserActionValueSelector key="userEventType" />
			);
	if (currentSelection === "field") ruleValueSelector = <FieldValueSelector key={currentSelection} />;
	if (currentSelection === "record") ruleValueSelector = <RecordValueSelector key={currentSelection} />;
	if (currentSelection === "channels") ruleValueSelector = <ChannelValueSelector key={currentSelection} />;

	return (
		<CreateNotificationRuleFormDialog
			ruleType={NotificationRuleType.User}
			getError={getError}
			badge={<UserActivityBadge />}
			headerSelect={
				userRuleConfigs.length > 0 ? (
					<Select
						value={selectedDisplay}
						onChange={(value) => onDisplayChange(value as Display)}
						options={displayOptions}
					/>
				) : null
			}
		>
			{!selectedRuleConfig?.workspaceIdNotRequired ? (
				<>
					<Section>
						<WorkspaceValueSelector onChange={onWorkspaceChange} />
					</Section>
					<SectionBreak />
				</>
			) : null}
			<Section grow>
				<SelectedPanel>
					<UserRuleElement />
					<DownArrowIcon />
					<ActionRuleElement placeholder="does this" />
					<RecordRuleElement requiresSelectedAction={true} />
					<DownArrowIcon />
					<ChannelsRuleElement />
				</SelectedPanel>
				<SelectorPanel noSelectionText="this person">{ruleValueSelector}</SelectorPanel>
			</Section>
		</CreateNotificationRuleFormDialog>
	);
}
