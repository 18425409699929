import { WebSocketMessage } from "./WebSocketMessage";

export enum InAppNotificationWSMessageType {
	NewCount = "InAppNotification.NewCount",
}

export interface InAppNotificationNewCountWSMessage extends Omit<WebSocketMessage, "type"> {
	type: InAppNotificationWSMessageType.NewCount;
	count: number;
}
