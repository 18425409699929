import { SettingsPopover, ToggleAudioButton, ToggleBlurButton, ToggleVideoButton } from "../../../../../common";

export function VideoControls() {
	return (
		<div className="flex justify-between pb-0.5">
			<div></div>
			<div className="flex gap-2">
				<ToggleAudioButton />
				<ToggleVideoButton />
				<ToggleBlurButton />
			</div>
			<SettingsPopover />
		</div>
	);
}
