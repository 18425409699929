import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { PATHS } from "../../routes";

export function NavbarLogo({ isOpen }: { isOpen: boolean }) {
	return (
		<Link to={PATHS.DASHBOARD()}>
			<div className="relative h-5 w-full">
				<img className="absolute h-5 min-w-[20px]" src={"/static/images/sd_logo.svg"} alt="SalesDesk logo" />
				<img
					className={clsx(
						"absolute h-5 min-w-[121px] transition-opacity",
						isOpen ? "opacity-100" : "pointer-events-none opacity-0"
					)}
					src={"/static/images/sd_logo_full.svg"}
					alt="SalesDesk logo"
				/>
			</div>
		</Link>
	);
}
