import { useCallback } from "react";
import clsx from "clsx";

import { SDObject } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";

import {
	BasicFormState,
	RECORD_FIELDS_KEY,
	RecordForm,
	getFormValuesByPrefix,
	RecordFormLoading,
} from "../../../../../../records";
import { CreateWorkspaceStepProps, OriginRecordDetails } from "../../../types";
import { useBasicInfoPanelForm } from "./hooks";

interface BasicInfoPanelProps extends CreateWorkspaceStepProps {
	workspaceObject: SDObject;
	originRecordDetails: OriginRecordDetails;
	updateRecordData: (data: Record<string, any>) => void;
	updateFormDirtyState: (isDirty: boolean) => void;
}

export function BasicInfoPanel({
	workspaceObject,
	updateRecordData,
	updateFormDirtyState,
	isVisible,
	previousStep,
	nextStep,
	originRecordDetails,
}: BasicInfoPanelProps) {
	const { formId, fields, uploadProgressStatus, updateUploadProgressStatus, defaultValues } = useBasicInfoPanelForm(
		workspaceObject,
		originRecordDetails
	);

	const onFormStateChange = useCallback(
		(formState: BasicFormState) => updateFormDirtyState(formState.isDirty || false),
		[updateFormDirtyState]
	);

	const isPending = uploadProgressStatus === "in_progress";
	const isBasicInfoPanelLoading = defaultValues === null;

	return (
		<div className={clsx("flex h-full max-h-full flex-col gap-8 overflow-hidden", !isVisible && "hidden")}>
			<div className="flex flex-col gap-4">
				<h4 className="text-h4">Basic Info</h4>
				<p>Enter basic information about your {workspaceObject._displayName}. Remember to keep it simple!</p>
			</div>
			<div className="overflow-auto">
				{isBasicInfoPanelLoading ? (
					<RecordFormLoading />
				) : (
					<RecordForm
						id={formId}
						fields={fields}
						onFormStateChange={onFormStateChange}
						updateUploadProgressStatus={updateUploadProgressStatus}
						onSubmit={(formData) => {
							updateRecordData(getFormValuesByPrefix(formData, RECORD_FIELDS_KEY));
							nextStep();
						}}
						defaultValues={defaultValues}
					/>
				)}
			</div>
			<div className="mt-auto flex justify-end gap-3">
				<Button disabled={isPending || isBasicInfoPanelLoading} onClick={previousStep} variant="secondary" size="lg">
					Cancel
				</Button>
				<Button
					isLoading={isPending}
					disabled={isBasicInfoPanelLoading}
					size="lg"
					variant="primary"
					type="submit"
					form={formId}
				>
					Next
				</Button>
			</div>
		</div>
	);
}
