import { createContext, useContext } from "react";
import { ObjectBoardDetails } from "../types";

interface DataboardDetails extends ObjectBoardDetails {
	workspaceId?: number;
}

/**
 * Stored as a context since it contains unserializable data that only
 * needs to be generated once per object type and therefore does not change frequently.
 */
const INITIAL_BOARD_DETAILS: ObjectBoardDetails = {
	sdObject: undefined,
	boardFieldMap: new Map(),
	nameFieldIds: [],
	mediaFieldIds: [],
	groupableFields: { timeFields: [], singleOptionFields: [] },
	aggregationFields: [],
};

export const DataboardDetailsContext = createContext<DataboardDetails>(INITIAL_BOARD_DETAILS);

export function useDataboardDetailsContext() {
	const context = useContext(DataboardDetailsContext);

	if (context == null) {
		throw new Error("Databoard components must be used within a <DataboardDetailsProvider />");
	}

	return context;
}
