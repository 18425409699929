import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { HEADER_HEIGHT } from "../types";

const SCROLL_BUFFER = HEADER_HEIGHT;

export function useUpdateHeaderVisibility(navCollapsedRef: RefObject<boolean>) {
	const { scrollContainerRef } = useWorkspaceContext();

	const [isHeaderVisible, setIsHeaderVisible] = useState(!navCollapsedRef.current);
	const isHeaderVisibleRef = useRef(isHeaderVisible);
	const scrollTopAnchorPosition = useRef(0);

	const location = useLocation();

	useEffect(() => {
		scrollTopAnchorPosition.current = 0;
	}, [location, scrollContainerRef]);

	const updateHeaderVisibility = useCallback(() => {
		const scrollContainer = scrollContainerRef?.current;

		if (!scrollContainer) {
			return;
		}

		const scrollTop = scrollContainer.scrollTop;

		// Ignore scroll events caused by header visibility change
		// to prevent infinite loop of header showing and hiding.
		if (scrollTopAnchorPosition.current === -1) {
			scrollTopAnchorPosition.current = scrollTop;

			if (navCollapsedRef.current) {
				isHeaderVisibleRef.current = false;
			} else if (scrollTop === 0) {
				isHeaderVisibleRef.current = true;
			}

			setIsHeaderVisible(isHeaderVisibleRef.current);
			return;
		}

		let newIsHeaderVisible = isHeaderVisibleRef.current;

		if (navCollapsedRef.current) {
			newIsHeaderVisible = false;
		} else {
			const scrolledUp = scrollTop < scrollTopAnchorPosition.current - SCROLL_BUFFER;
			const scrolledDown = scrollTop > scrollTopAnchorPosition.current + SCROLL_BUFFER;

			if (scrollTop === 0 || scrolledUp) {
				newIsHeaderVisible = true;
			} else if (scrolledDown) {
				newIsHeaderVisible = false;
			}

			// Update scroll top anchor point
			if (scrolledUp || scrolledDown) {
				scrollTopAnchorPosition.current = scrollTop;
			}
		}

		if (isHeaderVisibleRef.current !== newIsHeaderVisible) {
			scrollTopAnchorPosition.current = -1;
		}

		isHeaderVisibleRef.current = newIsHeaderVisible;
		setIsHeaderVisible(newIsHeaderVisible);
	}, [navCollapsedRef, scrollContainerRef]);

	return { isHeaderVisible, updateHeaderVisibility };
}
