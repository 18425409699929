import { SDObject } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useCurrentObjectBookmark } from "../hooks/useCurrentObjectBookmark";

interface BookmarkSelectProps {
	sdObject: SDObject;
}

export function BookmarkSelectButton({ sdObject }: BookmarkSelectProps) {
	const currentBookmark = useCurrentObjectBookmark(sdObject);
	return (
		<Button variant="outlined" size="sm" endIcon={ICONS.caretDown}>
			<div className="max-w-48 truncate">{currentBookmark?.name ?? `All ${sdObject._pluralName.toLowerCase()}`}</div>
		</Button>
	);
}
