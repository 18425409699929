import { ReactNode, useRef } from "react";

type FileType = "image";

interface HeadlessFileInputChildrenProps {
	openFileSelect: () => void;
}

interface HeadlessFileInputProps {
	fileType: FileType;
	multiple?: boolean;
	onFileSelect: (files: FileList) => void;
	children: ({ openFileSelect }: HeadlessFileInputChildrenProps) => ReactNode;
}

export function HeadlessFileInput({ children, onFileSelect, fileType, multiple = false }: HeadlessFileInputProps) {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const openFileSelect = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<>
			{children({ openFileSelect })}
			<input
				type="file"
				ref={fileInputRef}
				className="hidden"
				accept={`${fileType}/*`}
				multiple={multiple}
				onChange={(e) => {
					if (e.target.files) {
						onFileSelect(e.target.files);
					}
				}}
			/>
		</>
	);
}
