import { useSdFile } from "../../features/files/hooks/useSdFile";
import { SDFile } from "@salesdesk/salesdesk-schemas";

interface S3ImageProps {
	file: SDFile | number;
	alt?: string;
	className?: string;
}

export function S3Image({ file, alt, className }: S3ImageProps) {
	const { sdFile, isLoading } = useSdFile(typeof file === "number" ? file : null);

	const classes = className || "";

	// TODO: Better error handling if a signed URL can't be retrieved for an image?
	if (isLoading || !sdFile?.signedUrl) {
		return <div className={classes} />;
	}

	return <img src={sdFile.signedUrl} alt={alt ?? sdFile?.name} className={classes} />;
}
