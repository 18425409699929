import { UserType } from "../user_constants";
import { mUser } from "../user_inst";

export class mSalesDeskCustomer extends mUser {
	static CLASS_NAME = UserType.SALESDESK_CUSTOMER;

	constructor(id, objectDef, ownerId) {
		super(id, objectDef, ownerId);
		super.className = mSalesDeskCustomer.CLASS_NAME;
	}
}
