import { useMemo } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { mAssetDef, mDocDef } from "@salesdesk/salesdesk-model";

import { useWebPrincipal } from "../../../../../../../../../auth";
import { useGetObjectById } from "../../../../../../../../../hooks";
import { Skeleton } from "../../../../../../../../../components/Skeleton/Skeleton";
import { PATHS } from "../../../../../../../../../routes";
import { CompactCard } from "../../../../../../../../recordAssociations/components/RecordAssociationCard/CompactCard";
import { useWorkspaceSharedRecordSearch } from "../../../../../../../hooks/useWorkspaceSharedRecordSearch";
import { useWorkspaceContext } from "../../../../../../../hooks/useWorkspaceContext";
import { AddRecordButton } from "../../../../../WorkspaceResources";
import { EmptySection } from "../../../EmptySection";
import { ProductAssetsAndDocsRow } from "./ProductAssetsAndDocsRow";
import { ProductAssetsAndDocsList } from "./ProductAssetsAndDocsList";
import { ProductAssetsOrDocsSkeleton } from "./ProductAssetsOrDocsSkeleton";

interface WorkspaceProductAssetsProps {
	productRecord: SDRecord;
}

export function ProductAssetsAndDocs({ productRecord }: WorkspaceProductAssetsProps) {
	const { workspaceRecord } = useWorkspaceContext();

	const libraryObjectIds = useMemo(() => [mAssetDef.ID, mDocDef.ID], []);
	const { records, isLoadingRecords } = useWorkspaceSharedRecordSearch({
		sdObjectFilter: libraryObjectIds,
		additionalSearchParams: useMemo(
			() => ({
				query: { and: [{ hasAssociationToRecord: productRecord._id }] },
			}),
			[productRecord._id]
		),
	});
	const { sdObject: assetObject } = useGetObjectById(mAssetDef.ID);
	const { sdObject: docObject } = useGetObjectById(mDocDef.ID);

	const assetsPluralName = assetObject?._pluralName || "Assets";
	const docsPluralName = docObject?._pluralName || "Docs";

	const principal = useWebPrincipal();

	if (!assetObject || !docObject) {
		return null;
	}

	if (!isLoadingRecords && !records.length) {
		if (!principal.IsSalesDeskUser) {
			return null;
		}
		return (
			<div className="mt-4">
				<EmptySection
					sdObjectIds={libraryObjectIds}
					messageText={`Share ${assetsPluralName} or ${docsPluralName} as part of this product information with your customer.`}
					buttonText={`Add ${assetsPluralName} or ${docsPluralName}`}
					associateWith={productRecord}
				/>
			</div>
		);
	}

	return (
		<div className="mt-4 flex flex-col gap-4">
			{principal.IsSalesDeskUser ? (
				<div className="flex justify-end">
					{isLoadingRecords ? (
						<Skeleton className="h-8 w-8" />
					) : (
						<AddRecordButton sdObjectIds={libraryObjectIds} associateWith={productRecord} />
					)}
				</div>
			) : null}
			{isLoadingRecords ? (
				<ProductAssetsOrDocsSkeleton />
			) : (
				<ProductAssetsAndDocsList>
					{records.map((record) => (
						<ProductAssetsAndDocsRow key={record._id}>
							<CompactCard
								sdObject={record._objectDefId === mDocDef.ID ? docObject : assetObject}
								sdRecord={record}
								getRecordLink={(recordId: number) =>
									workspaceRecord ? PATHS.WORKSPACE_OVERVIEW(workspaceRecord._id, recordId) : null
								}
								allowDelete={false}
							/>
						</ProductAssetsAndDocsRow>
					))}
				</ProductAssetsAndDocsList>
			)}
		</div>
	);
}
