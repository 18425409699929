import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import { WorkspaceLibraryStateProvider } from "./WorkspaceLibraryStateProvider";
import { WorkspaceLibrary } from "./WorkspaceLibrary";

export function WorkspaceLibraryPage() {
	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<WorkspaceLibraryStateProvider>
				<WorkspaceLibrary />
			</WorkspaceLibraryStateProvider>
		</QueryParamProvider>
	);
}
