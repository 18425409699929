import { createSlice } from "@reduxjs/toolkit";
import { BrowserNotificationNewWSMessage } from "@salesdesk/salesdesk-schemas";
import { createUniqueId } from "@salesdesk/salesdesk-utils";
import { BrowserNotification } from "../types/BrowserNotification";

export const browserNotificationsSlice = createSlice({
	name: "browserNotifications",
	initialState: {
		notifications: [] as BrowserNotification[],
	},
	reducers: {
		triggerBrowserNotification: (state, { payload }: { payload: BrowserNotificationNewWSMessage }) => {
			state.notifications.push({
				id: createUniqueId(),
				message: payload,
			});
		},
		deleteBrowserNotification: (state, { payload: notificationIds }: { payload: number[] }) => {
			for (const notificationId of notificationIds) {
				const index = state.notifications.findIndex((toast) => toast.id === notificationId);
				if (index !== -1) state.notifications.splice(index, 1);
			}
		},
	},
});

export const { triggerBrowserNotification, deleteBrowserNotification } = browserNotificationsSlice.actions;
export const browserNotificationsReducer = browserNotificationsSlice.reducer;
