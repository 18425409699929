import {
	getSDObjectFields,
	ImportPreviewRecord,
	ImportPreviewRecordType,
	SDObject,
} from "@salesdesk/salesdesk-schemas";
import { generateBoardRecord } from "../../../../../ObjectBoard/utils";
import { ImportPreviewRowCell } from "../components/ImportPreviewRowCell";
import { ImportPreviewColumn, ImportPreviewRow } from "../types";
import { HeaderCell } from "../../../../../Table";
import { ImportRecordTypeIcon } from "../../ImportRecordTypeIcon";

export const IMPORT_TYPE_COLUMN_ID = "IMPORT_TYPE";

export function generateColumns(sdObject: SDObject): ImportPreviewColumn[] {
	const fieldColumns: ImportPreviewColumn[] = [];
	if (!sdObject) return fieldColumns;
	const fields = getSDObjectFields(sdObject);
	fieldColumns.push({
		id: IMPORT_TYPE_COLUMN_ID,
		displayName: () => <HeaderCell columnId={IMPORT_TYPE_COLUMN_ID} columnName="" />,
		renderRowCell: (value) => <ImportRecordTypeIcon type={value as ImportPreviewRecordType} />,
	});
	for (const field of fields) {
		fieldColumns.push({
			id: String(field._id),
			displayName: () => <HeaderCell columnId={String(field._id)} columnName={field._displayName} />,
			renderRowCell: (recordField, row) => <ImportPreviewRowCell field={field} value={recordField} row={row} />,
		});
	}
	return fieldColumns;
}

export function generateRows(sdObject: SDObject, previewRecords: ImportPreviewRecord[]): ImportPreviewRow[] {
	return previewRecords.map(({ type, record }) => {
		return {
			[IMPORT_TYPE_COLUMN_ID]: type,
			...generateBoardRecord(record, sdObject, false),
		} as ImportPreviewRow;
	});
}
