import { PATHS } from "../../../../routes";
import { ERRORS, ICONS } from "@salesdesk/salesdesk-ui";
import { SettingsContainer } from "../../SettingsContainer";
import { useWebPrincipal } from "../../../../auth";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { Navigate } from "react-router-dom";
import { ApiKeys } from "./ApiKeys";
import { Divider } from "../../../../components/Divider/Divider";
import { SD_API_HOST } from "../../../../app_config";
import { Alert } from "../../../../components/Alert";

export function DeveloperPage() {
	const principal = useWebPrincipal();
	if (!principal.can(AbilityAction.Create, AbilitySubject.SDApiKey)) {
		return <Navigate to={PATHS.ERROR(ERRORS.ACCESS_DENIED.code.toLowerCase())} />;
	}

	const apiOrigin = SD_API_HOST.replace(/\/data$/, "");

	return (
		<SettingsContainer
			breadcrumbs={[
				{
					node: "Settings",
					icon: ICONS.settings,
				},
				{
					node: "Developer",
					link: PATHS.SETTINGS_DEVELOPER(),
				},
			]}
		>
			<div className="flex flex-col gap-6 px-6 py-11">
				<div className="flex flex-col gap-4">
					<div className="text-label">API endpoint</div>
					<div className="text-body-sm text-c_text_secondary">
						Your API endpoint is <span className="underline">{apiOrigin}</span>. Use this as the base URL for all your
						API requests.
					</div>
					<Alert
						variant="info"
						title="To call the SalesDesk API using cURL:"
						message={
							<>
								curl {apiOrigin}/data/me/claims -u{" "}
								<span className="text-c_text_placeholder italic">YOUR_API_KEY_ID:YOUR_API_SECRET</span> -H 'Accept:
								application/json'
							</>
						}
					/>
				</div>
				<Divider className="my-2" />
				<ApiKeys />
			</div>
		</SettingsContainer>
	);
}
