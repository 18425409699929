import { asConst } from "json-schema-to-ts";
import { booleanSchema, idSchema, updatableSchema } from "../components";
import { roleCreateRequestSchema } from "./roleCreateRequestSchema";

export const roleSchema = asConst({
	title: "Role",
	description: "A grouping of claims that can be given to a User",
	type: "object",
	additionalProperties: false,
	required: [...roleCreateRequestSchema.required, "id", "editable", "deleted", ...updatableSchema.required],
	properties: {
		...roleCreateRequestSchema.properties,

		id: idSchema,
		editable: booleanSchema,
		deleted: booleanSchema,

		...updatableSchema.properties,
	},
});
