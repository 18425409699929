import {
	formatMillisecondsToHHMMSS,
	isEmpty,
	parseMilliseconds,
} from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mDurationField extends mField {
	days() {
		return parseMilliseconds(this.value).hours;
	}

	hours() {
		return parseMilliseconds(this.value).hours;
	}

	minutes() {
		return parseMilliseconds(this.value).minutes;
	}

	displayValue() {
		if (isEmpty(this.value)) {
			return "";
		}
		return formatMillisecondsToHHMMSS(this.value);
	}
}
