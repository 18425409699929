import { useCallback } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mDocDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { useCreateWorkspaceContext } from "../../../../workspaces";
import { reverseObjectAssociationDirection, useObjectAssociations } from "../../../../recordAssociations";
import { AssociateRecordDetails } from "../../../../recordAssociations/types";
import { useDocumentCreateAction } from "./recordCreateActions";

export function useRecordCreateAction(
	defaultAction: (sdObject: SDObject, associateRecordDetails?: AssociateRecordDetails) => void,
	associateWith?: SDRecord
) {
	const { openModal: openCreateWorkspace, isOpen: isCreateWorkspaceOpen } = useCreateWorkspaceContext();

	const {
		objectAssociations: { allAssociations: associateWithAssociations },
		isLoading: isLoadingAssociations,
	} = useObjectAssociations(associateWith ? associateWith._objectDefId : undefined);

	const { documentCreateAction, isLoadingDocumentCreation } = useDocumentCreateAction();

	const recordCreateActionFn = useCallback(
		(sdObject: SDObject) => {
			let associateRecordDetails: undefined | AssociateRecordDetails;

			if (associateWithAssociations.length && associateWith) {
				const association = associateWithAssociations.find(
					(association) => association.connectedObject.id === sdObject._baseType
				);

				// Reverse association direction to be from the direction of SDObject as the source
				associateRecordDetails = association
					? { association: reverseObjectAssociationDirection(association), record: associateWith }
					: undefined;
			}

			if (sdObject._id === mWorkspaceDef.ID && openCreateWorkspace) {
				openCreateWorkspace();
			} else if (sdObject._id === mDocDef.ID) {
				documentCreateAction(sdObject, associateRecordDetails);
			} else {
				defaultAction(sdObject, associateRecordDetails);
			}
		},
		[associateWithAssociations, associateWith, openCreateWorkspace, documentCreateAction, defaultAction]
	);

	return {
		recordCreateActionFn,
		actionIsActive: isCreateWorkspaceOpen,
		actionIsLoading: isLoadingDocumentCreation || isLoadingAssociations,
	};
}
