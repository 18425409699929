import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";

export const hasAssociationsToAllRecordsClauseSchema = asConst({
	title: "Has Associations To All Records Clause",
	description: "Will match any record that is associated to all the given record IDs",
	type: "object",
	additionalProperties: false,
	required: ["hasAssociationsToAllRecords"],
	properties: {
		hasAssociationsToAllRecords: {
			type: "array",
			items: idSchema,
		}, // TODO: change to recordIdSchema when these get moved to /records
	},
	nullable: false,
});
