import { SentimentFilter } from "./SentimentFilter";
import { Searchbar } from "../../../../inputs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce, throttle } from "../../../../../utils";
import { UsersSelect } from "../../common/UsersSelect";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { ActiveItems, TranscriptSentimentType, UserIdOrPlaceholderName } from "../../../types";
import clsx from "clsx";
import { ScrollControls } from "./ScrollControls";
import { keyToPlaceholderName } from "../../../utils";
import { uniqueArray } from "@salesdesk/salesdesk-utils";

interface TranscriptFilterProps {
	sentimentTypes: TranscriptSentimentType[];
	onSentimentTypesChange: (value: TranscriptSentimentType[]) => void;
	search?: string;
	onSearchChange: (value: string) => void;
	selectedUsers: UserIdOrPlaceholderName[];
	onSelectedUsersChange: (userIds: UserIdOrPlaceholderName[]) => void;
	activeItems: ActiveItems;
}

export function TranscriptFilters({
	sentimentTypes,
	onSentimentTypesChange,
	search = "",
	onSearchChange,
	selectedUsers,
	onSelectedUsersChange,
	activeItems,
}: TranscriptFilterProps) {
	const { transcriptItems, transcriptSentimentsPerUser } = useMediaAnalysisContext();
	const [searchOpen, setSearchOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState(search);

	useEffect(() => {
		setSearchQuery(search);
	}, [search]);

	const onSearchDebounced = useMemo(() => debounce(onSearchChange, 200), [onSearchChange]);

	const onSearchCollapsedChange = (collapsed: boolean) => {
		if (!collapsed) {
			setSearchOpen(true);
		} else {
			// Prevent brief overflow issue during the animation
			const ANIMATION_DURATION = 75;
			setTimeout(() => {
				setSearchOpen(false);
				setSearchQuery("");
				onSearchChange("");
			}, ANIMATION_DURATION);
		}
	};

	const userIdsOrPlaceholderNames = useMemo<UserIdOrPlaceholderName[]>(() => {
		if (!transcriptItems) return [];

		return uniqueArray(transcriptItems.map((item) => item.userId ?? keyToPlaceholderName(item.speaker)));
	}, [transcriptItems]);

	const holderRef = useRef<HTMLDivElement>(null);
	const filtersRef = useRef<HTMLDivElement>(null);
	const scrollControlsRef = useRef<HTMLDivElement>(null);

	const [showScrollControlsUnder, setShowScrollControlsUnder] = useState<boolean>(true);

	const onFiltersResize = useCallback(() => {
		if (!scrollControlsRef.current || !filtersRef.current || !holderRef.current) return;
		setShowScrollControlsUnder(
			filtersRef.current.offsetWidth + 20 < holderRef.current.offsetWidth - scrollControlsRef.current.offsetWidth
		);
	}, []);

	useEffect(() => {
		if (!filtersRef.current || !holderRef.current) return;
		const observer = new ResizeObserver(throttle(onFiltersResize, 20));
		observer.observe(filtersRef.current);
		observer.observe(holderRef.current);
		return () => observer.disconnect();
	}, [onFiltersResize]);

	return (
		<div ref={holderRef} className="@container/transcriptFilters relative">
			<div ref={filtersRef} className="my-2 ml-auto flex h-10 w-fit items-center justify-end gap-2">
				{transcriptSentimentsPerUser ? (
					<div className={clsx("h-10 items-center", searchOpen ? "@[580px]/transcriptFilters:flex hidden" : "flex")}>
						<SentimentFilter value={sentimentTypes} onChange={onSentimentTypesChange} />
					</div>
				) : null}
				<div className="flex-shrink-0">
					<Searchbar
						onChange={(value) => {
							setSearchQuery(value);
							onSearchDebounced(value);
						}}
						isClearable
						value={searchQuery}
						onCollapsedChange={onSearchCollapsedChange}
						collapsesOnBlur={false} // fixes where you can fail clicking on a sentiment type if the search is empty (because it moves)
					/>
				</div>
				<div className="flex-shrink-0">
					<UsersSelect
						userIdsOrPlaceholderNames={userIdsOrPlaceholderNames}
						value={selectedUsers}
						onChange={onSelectedUsersChange}
					/>
				</div>
			</div>
			<div ref={scrollControlsRef} className={clsx("w-fit", showScrollControlsUnder && "absolute left-0 top-2")}>
				<ScrollControls activeItems={activeItems} />
			</div>
		</div>
	);
}
