import { PropsWithChildren, useEffect, useRef } from "react";
import clsx from "clsx";

import { THEMABLE_COLOR_VARIABLES, ThemableColorKey } from "@salesdesk/salesdesk-ui";

import { Sticky } from "../../../../../../components/Sticky";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { COVER_VIDEO_NAV_HEIGHT, HEADER_HEIGHT, MAX_WORKSPACE_NAV_HEIGHT, MIN_WORKSPACE_NAV_HEIGHT } from "../types";

import { useUpdateHeaderVisibility } from "../hooks/useUpdateHeaderVisibility";
import { useUpdateNavContainerStyling } from "../hooks/useUpdateNavContainerStyling";
import { WorkspaceHeader } from "../../../WorkspaceHeader";

interface WorkspaceNavContainerProps {
	collapsibleSectionRef: React.RefObject<HTMLDivElement>;
}

export function WorkspaceNavContainer({
	children,
	collapsibleSectionRef,
}: PropsWithChildren<WorkspaceNavContainerProps>) {
	const { scrollContainerRef, navCollapsed } = useWorkspaceContext();
	const navCollapsedRef = useRef(Boolean(navCollapsed));

	const { isHeaderVisible, updateHeaderVisibility } = useUpdateHeaderVisibility(navCollapsedRef);

	const { updateNavStyles, navContainerRef, navContentsRef, currentMinHeight } = useUpdateNavContainerStyling(
		navCollapsedRef,
		collapsibleSectionRef,
		isHeaderVisible ? 200 : MAX_WORKSPACE_NAV_HEIGHT + 140
	);

	const hasCollapsibleSection = Boolean(collapsibleSectionRef.current);
	useEffect(() => {
		navCollapsedRef.current = Boolean(navCollapsed);
		updateNavStyles();
		updateHeaderVisibility();
	}, [navCollapsed, updateHeaderVisibility, updateNavStyles, hasCollapsibleSection]);

	useEffect(() => {
		const scrollContainer = scrollContainerRef?.current;

		const onScroll = () => {
			updateHeaderVisibility();
			updateNavStyles();
		};

		scrollContainer?.addEventListener("scroll", onScroll);

		return () => {
			scrollContainer?.removeEventListener("scroll", onScroll);
		};
	}, [scrollContainerRef, updateHeaderVisibility, updateNavStyles]);

	const stickyContainerHeight = MIN_WORKSPACE_NAV_HEIGHT + (isHeaderVisible ? HEADER_HEIGHT : 0);

	return (
		<Sticky
			priority={1}
			ref={navContainerRef}
			className={clsx(
				"bg-c_bg_04 sticky left-0 flex w-full flex-shrink-0 flex-col overflow-clip transition-shadow",
				currentMinHeight <= MIN_WORKSPACE_NAV_HEIGHT && "shadow-card",
				currentMinHeight <= COVER_VIDEO_NAV_HEIGHT ? "z-[51]" : "z-[50]",
				navCollapsed && "!min-h-[100px]"
			)}
			height={stickyContainerHeight}
		>
			<WorkspaceHeader show={isHeaderVisible && !navCollapsed} />
			<div className="flex h-full w-full overflow-clip" ref={navContentsRef}>
				<div className="relative z-40 mx-auto flex h-full w-10/12 flex-col py-2.5 pt-1.5">{children}</div>
				<div className="absolute inset-0 z-0">
					<NavGradientDiv
						className="top-18 absolute -left-16 z-0 h-[309px] w-[412px] rotate-[24deg] blur-[150px]"
						startColorKey="c_workspace_nav_circle1_start"
						startRotation="36.12%"
						endColorKey="c_workspace_nav_circle1_end"
						endRotation="90.58%"
					/>
					<NavGradientDiv
						className="absolute right-[312px] top-8 z-0 h-[230px] w-[307px] -rotate-[24deg] blur-[150px]"
						startColorKey="c_workspace_nav_circle2_start"
						startRotation="26.45%"
						endColorKey="c_workspace_nav_circle2_end"
						endRotation="103.33%"
					/>
					<NavGradientDiv
						className="absolute -right-4 top-[52px] z-0 h-[390px] w-[522px] -rotate-[24deg] blur-[150px]"
						startColorKey="c_workspace_nav_circle3_start"
						startRotation="36.12%"
						endColorKey="c_workspace_nav_circle3_end"
						endRotation="90.58%"
					/>
				</div>
			</div>
		</Sticky>
	);
}

interface NavGradientDivProps {
	className?: string;
	startColorKey: ThemableColorKey;
	startRotation: string;
	endColorKey: ThemableColorKey;
	endRotation: string;
}

function NavGradientDiv({ className, startColorKey, startRotation, endRotation, endColorKey }: NavGradientDivProps) {
	const startColor = `rgba(var(${THEMABLE_COLOR_VARIABLES[startColorKey]}), 0.12)`;
	const endColor = `rgba(var(${THEMABLE_COLOR_VARIABLES[endColorKey]}), 0.12)`;

	// Have to use inline styles here because Tailwind doesn't support gradients using custom CSS RGB variables
	return (
		<div
			className={className}
			style={{
				background: `linear-gradient(128.62deg, ${startColor} ${startRotation}, ${endColor} ${endRotation})`,
			}}
		/>
	);
}
