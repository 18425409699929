import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mOptionField } from "../option_field_inst";
import { mFieldDataChangeEvent } from "../../utils";

export class mSingleOptionField extends mOptionField {
	constructor(id, field) {		
		super(id,field);
	}

	// If the option field associated with this option instance has 
	// had its option values updated, we need to check the value that 
	// references the option values to ensure that we are no longer referencing
	// an option that no longer exists. 

	update(event){

		const value = this._value;

		if(value){

			try{
				this.field.getOptionValuesById(value)
			}
			catch(error){
				this.value = null;
			}
		}

		this.fireEvent(mFieldDataChangeEvent.type.FIELD_OPTION_VALUES_UPDATED, event.message, this);
	}
	
	// Check whether this field value is selected.
	containsValue(value){
		if(isEmpty(this._value)){
			return false;
		}

		return this._value === value.id;
	}
	
	// Get the value in the form of option values, rather than 
	// simply an id.
	getOptionValue(callback){	
		return this.field.getOptionValueById(this._value, callback);
	}

	setValueByOptionId(id){

		// If we are not using a backingstore, we check the option exists

		if(!this.field.isBackingStore()){
			this.field.getOptionValueById(id);
		}

		this._value = id;
	}

	setValueByOptionName(name){

		// If we are not using a backingstore, we check the option exists

		if(!this.field.isBackingStore()){
			const optionValue = this.field.getOptionValueByName(name);
			this._value = optionValue.id;
		}
		else{
			throw Error(`This function is not supported for fields with backing stores`);
		}	
	}

	setValueByOption(option){

		// If we are not using a backingstore, we check the option exists

		if(!this.field.isBackingStore()){
			this.field.getOptionValueById(option.id);
		}

		this._value = option.id;
	}

	get group(){

		if(this.hasValue()){

			const groupInfo = this.field.groupInfo;

			for(let i = 0; groupInfo.length > i; i++){
				const group = groupInfo[i];
				if(group.id === this.value){
					return group;
				}
			}

			return null;
		}
		throw Error(`This field instance does not belong to a group as it has no value set`);
	}

	displayValue(){		
		return this.field.getOptionValueById(this._value).name;
	}
}