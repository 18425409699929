import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import { useCurrentUserFirstName } from "../../../../../../../users";

import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { WorkspaceTeam } from "./WorkspaceTeam";
import { WorkspaceWelcomeVideo } from "./WorkspaceWelcomeVideo";
import { WorkspaceTitle } from "./WorkspaceTitle";
import { WorkspaceIntroduction } from "./WorkspaceIntroduction";

export function WorkspaceIntroSection() {
	const { workspaceRecord, workspaceObject, isLoading } = useWorkspaceContext();

	const firstName = useCurrentUserFirstName();

	const isLoadingSection = isLoading || !workspaceRecord || !workspaceObject;

	return (
		<div className="flex flex-col gap-8 pb-5 pt-3">
			{isLoadingSection ? (
				<Skeleton className="ml-4 h-10 w-96" />
			) : (
				<div className="flex">
					<WorkspaceTitle workspaceObject={workspaceObject} workspaceRecord={workspaceRecord} />
					<WorkspaceWelcomeVideo />
				</div>
			)}
			<div className="flex gap-9">
				<div className="flex min-w-[330px] basis-2/5 flex-col gap-5">
					{isLoadingSection ? (
						<IntroFieldsLoading />
					) : (
						<>
							<h3 className="text-h3 flex h-12 items-center pl-4">Hi{firstName ? ` ${firstName}` : ""},</h3>
							<WorkspaceIntroduction workspaceObject={workspaceObject} workspaceRecord={workspaceRecord} />
						</>
					)}
				</div>
				<div className="flex w-full basis-3/5 overflow-hidden">
					<WorkspaceTeam isLoading={isLoadingSection} />
				</div>
			</div>
		</div>
	);
}

function IntroFieldsLoading() {
	return (
		<>
			<Skeleton className="my-2 ml-4 h-8 w-48" />
			<div className="ml-4 flex w-full flex-col gap-3">
				<Skeleton className="h-6 w-11/12" />
				<Skeleton className="h-6 w-2/3" />
				<Skeleton className="h-6 w-1/2" />
				<Skeleton className="h-6 w-2/3" />
			</div>
		</>
	);
}
