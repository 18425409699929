import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { getChimeUserId } from "@salesdesk/salesdesk-schemas";

import { useGetRecordsQuery } from "../../../../records";
import { ChannelMessageArray } from "../../../types";
import { useGetCurrentUserRecord } from "../../../../users";

export function useChimeUserIdUserRecordMap(chatMessages?: ChannelMessageArray, memberRecords?: SDRecord[]) {
	const currentUserRecord = useGetCurrentUserRecord();

	const preloadedUserRecords = useMemo(() => {
		if (!currentUserRecord) {
			return memberRecords;
		}

		const hasCurrentUser = memberRecords?.some((record) => record._id === currentUserRecord._id);
		return hasCurrentUser ? memberRecords : [...(memberRecords ?? []), currentUserRecord];
	}, [currentUserRecord, memberRecords]);

	const missingUserIds = useMemo(() => {
		const seedUserIdSet = new Set(preloadedUserRecords?.map((userRecord) => userRecord._id));
		const messageSenderIds = chatMessages?.map((cms) => getChimeUserId(cms.Sender?.Arn)).filter(Boolean) as number[];
		const messageUserIds = [...new Set(messageSenderIds)];
		return messageUserIds.filter((userId) => !seedUserIdSet.has(userId));
	}, [chatMessages, preloadedUserRecords]);

	const { data: missingUserRecords } = useGetRecordsQuery(missingUserIds.length > 0 ? missingUserIds : skipToken);

	return useMemo(() => {
		const combinedUserRecordsMap = new Map(preloadedUserRecords?.map((userRecord) => [userRecord._id, userRecord]));
		missingUserRecords?.forEach((userRecord) => combinedUserRecordsMap.set(userRecord._id, userRecord));

		return combinedUserRecordsMap;
	}, [missingUserRecords, preloadedUserRecords]);
}
