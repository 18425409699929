import { useMemo } from "react";

import { mDocDef } from "@salesdesk/salesdesk-model";
import { FieldInfo, getSDObjectNameFieldIds, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { getRecordFieldInfo, getSDRecordNameFieldInfo } from "../../../../../../utils/records";

export function useRecordFieldsAndTitle(sdRecord: SDRecord | undefined, sdObject: SDObject | undefined) {
	return useMemo(() => {
		// TODO: Ask UX to add some kind of error state for Record Side Panel
		if (!sdObject || !sdRecord) return { title: "", recordFields: [] satisfies FieldInfo[] };

		const nameFieldIds = getSDObjectNameFieldIds(sdObject);
		const nameFieldInfo = getSDRecordNameFieldInfo(sdObject, sdRecord);
		const recordFields = getRecordFieldInfo(sdObject, sdRecord);

		// If the record name is a combination of multiple fields, the title is a concatenated string
		// and can't be directly edited. All the fields are included so they can be edited separately.
		if (nameFieldIds.length > 1) {
			return {
				title: nameFieldInfo.value as string,
				recordFields,
			};
		}

		// If there is only a single name field, the title is that field and can be edited.
		// The name field is then filtered out out of the record fields to prevent duplication.
		return {
			title: nameFieldInfo,
			recordFields: recordFields.filter((recordField) => {
				if (nameFieldIds.includes(recordField.field._id)) {
					return false;
				}

				// Filters out the document contents for a document record as this should be displayed
				// as a document preview
				if (sdObject._id === mDocDef.ID && recordField.field._name === mDocDef.DOCUMENT_FIELD_NAME) {
					return false;
				}

				return true;
			}),
		};
	}, [sdObject, sdRecord]);
}
