import { useState, useEffect } from "react";

import {
	AssociationMultiplicity,
	AssociationSide,
	ObjectAssociationEndpointType,
	mWorkspaceDef,
} from "@salesdesk/salesdesk-model";
import { SDObject, SDRecord, RecordQueryClauses, rsr } from "@salesdesk/salesdesk-schemas";

import {
	EMPTY_FILTERS,
	FilterData,
	mapToSearchQuery,
	SortingDetails,
	useInfiniteRecordSearch,
} from "../../../../../../records";
import { APP_CONFIG } from "../../../../../../../app/app_config";
import { useObjectAssociations } from "../../../../../../recordAssociations";

const WORKSPACE_OBJECT_ID = [mWorkspaceDef.ID];

export function usePotentialOriginRecordSearch(sdObject: SDObject) {
	const [records, setRecords] = useState<SDRecord[]>([]);
	const [filter, setFilter] = useState<FilterData>(EMPTY_FILTERS);
	const [sorting, setSorting] = useState<SortingDetails[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>();

	const {
		objectAssociations: { prioritizedAssociations: workspaceAssociations },
		isLoading: isAssociationsLoading,
	} = useObjectAssociations(sdObject._id, WORKSPACE_OBJECT_ID);

	const { updateSearchParams, loadNextPage, resultHits, isLoadingNewSearchParams, isLoadingNextPage } =
		useInfiniteRecordSearch({
			limit: APP_CONFIG.maxLocalSearchResults,
			sdRecords: records,
			onSDRecordsChange: setRecords,
			sdObjectFilter: sdObject._id,
		});

	useEffect(() => {
		if (isAssociationsLoading) {
			return;
		}

		const query = rsr.query().and(rsr.equals("_deleted", false)).not(rsr.equals("isTemplate", true));

		if (searchQuery != null && searchQuery.length > 0) {
			query.and(rsr.matchAllPrefix(searchQuery));
		}

		const associationFilters: RecordQueryClauses[] = filter?.filters.flatMap(mapToSearchQuery);

		if (associationFilters.length) {
			const filterType = filter.type;

			associationFilters.forEach((filter) => {
				if (filterType === "AND") {
					query.and(filter);
				} else if (filterType === "OR") {
					query.or(filter);
				}
			});
		}

		// Filters out all of the records that are already associated with a workspace
		workspaceAssociations.forEach((association) => {
			if (association.connectedObject.multiplicity !== AssociationMultiplicity.ONE) {
				return;
			}

			query.not(
				rsr.existsAssociationForObjectAssociation(
					association.id,
					association.originObject === ObjectAssociationEndpointType.OBJECT_ONE
						? AssociationSide.RECORD_1
						: AssociationSide.RECORD_2
				)
			);
		});

		updateSearchParams({
			query: query.buildQuery(),
			sort: sorting.map((value) => ({ [value.fieldId]: { order: value.order } })),
		});
	}, [updateSearchParams, filter, sdObject, sorting, searchQuery, workspaceAssociations, isAssociationsLoading]);

	return {
		records,
		loadNextPage,
		hitCount: resultHits?.hitCount,
		isLoadingNewSearchParams,
		isLoadingNextPage,
		filter,
		setFilter,
		sorting,
		setSorting,
		searchQuery,
		setSearchQuery,
	};
}
