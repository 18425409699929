import { useMemo } from "react";
import { getSDObjectFieldById, isAssetObject, SDFile, SDObject } from "@salesdesk/salesdesk-schemas";
import { mAssetDef } from "@salesdesk/salesdesk-model";

export function useFileField(sdObject: SDObject | undefined, sdFile: SDFile | undefined) {
	return useMemo(() => {
		if (!sdObject || !sdFile?.fieldId) {
			return undefined;
		}
		const field = getSDObjectFieldById(sdObject, sdFile.fieldId);
		if (field && isAssetObject(sdObject) && field._name === mAssetDef.FILE_FIELD_NAME) {
			return undefined;
		}
		return field;
	}, [sdObject, sdFile]);
}
