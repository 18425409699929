import { WatchesBatchRequest, DeleteWatchesQuery, SDRecord } from "@salesdesk/salesdesk-schemas";

import { buildApiInvalidatedTagList, buildApiTagsList, SDApi } from "../../api";
import { addRecordIdsToIdList, filterRecordIdsFromIdList } from "../utils/records";

export const watchesApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			watchedRecords: builder.query<SDRecord["_id"][], void>({
				query: () => "/me/records/watches",
				providesTags: (recordIds) => buildApiTagsList(recordIds, (id) => id, "Watches"),
			}),
			watchRecord: builder.mutation<void, SDRecord["_id"]>({
				query: (recordID) => ({
					url: `me/records/watches/${recordID}`,
					method: "PUT",
				}),
				onQueryStarted: async (recordID, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						watchesApi.util.updateQueryData("watchedRecords", undefined, (draft) => {
							Object.assign(draft, addRecordIdsToIdList(draft, [recordID]));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList([arg], () => arg, "Watches"),
			}),
			unwatchRecord: builder.mutation<void, SDRecord["_id"]>({
				query: (recordID) => ({
					url: `me/records/watches/${recordID}`,
					method: "DELETE",
				}),
				onQueryStarted: async (recordID, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						watchesApi.util.updateQueryData("watchedRecords", undefined, (draft) => {
							Object.assign(draft, filterRecordIdsFromIdList(draft, [recordID]));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList([arg], () => arg, "Watches"),
			}),
			batchWatchRecords: builder.mutation<void, SDRecord["_id"][]>({
				query: (recordIDs) => ({
					url: `/me/records/watches/batch`,
					method: "PUT",
					body: recordIDs satisfies WatchesBatchRequest, // Max 40 IDs
				}),
				onQueryStarted: async (recordIDs, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						watchesApi.util.updateQueryData("watchedRecords", undefined, (draft) => {
							Object.assign(draft, filterRecordIdsFromIdList(draft, recordIDs));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: ["Watches"],
			}),
			batchUnwatchRecords: builder.mutation<void, SDRecord["_id"][]>({
				query: (recordIDs) => ({
					url: `/me/records/watches`,
					method: "DELETE",
					params: { ids: recordIDs.join(",") } satisfies DeleteWatchesQuery, // Max 40 IDs
				}),
				onQueryStarted: async (recordIDs, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						watchesApi.util.updateQueryData("watchedRecords", undefined, (draft) => {
							Object.assign(draft, addRecordIdsToIdList(draft, recordIDs));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: ["Watches"],
			}),
		};
	},
});

export const {
	useWatchedRecordsQuery,
	useWatchRecordMutation,
	useUnwatchRecordMutation,
	useBatchWatchRecordsMutation,
	useBatchUnwatchRecordsMutation,
} = watchesApi;
