import { SDFile, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { createContext, MutableRefObject, useContext } from "react";
import { ResourcesPanelDetails } from "../types";

export interface IBaseWorkspaceContext {
	scrollContainerRef?: React.RefObject<HTMLDivElement>;

	workspaceId?: number;
	workspaceObject?: SDObject;
	workspaceRecord?: SDRecord;
	isLoading?: boolean;

	isPreviewMode?: boolean;
	setIsPreviewMode?: (previewMode: boolean) => void;

	navCollapsed?: boolean;
	toggleNavCollapse?: (collapsed: boolean) => void;

	resourcesPanelDetails?: ResourcesPanelDetails | null;
	openResourcesPanel?: (newPanelDetails: ResourcesPanelDetails) => void;
	clearResourcesPanelDetails?: () => void;

	previousOutsideWorkspacePathRef?: MutableRefObject<string | undefined>;

	welcomeVideo?: SDFile;
	welcomeVideoRecord?: SDRecord;
	isLoadingWelcomeVideo?: boolean;
}

export interface IInternalWorkspaceContext {
	internalArea?: boolean;
}

export type IWorkspaceContext = IBaseWorkspaceContext & IInternalWorkspaceContext;

export const WorkspaceContext = createContext<IBaseWorkspaceContext | null>(null);
export const InternalWorkspaceContext = createContext<IInternalWorkspaceContext | null>(null);

export function useWorkspaceContext(): IWorkspaceContext {
	const workspaceContext = useContext(WorkspaceContext);
	const internalWorkspaceContext = useContext(InternalWorkspaceContext);

	if (workspaceContext == null) {
		return { isLoading: false };
	}

	return { ...workspaceContext, internalArea: internalWorkspaceContext?.internalArea };
}
