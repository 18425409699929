import { Button } from "@salesdesk/daisy-ui";
import { AlertDialog } from "../../../Dialog";
import { useImportContext } from "../../hooks/useImportContext";

interface ImportStartConfirmDialogProps {
	onConfirm: () => void;
	onDismiss: () => void;
}

export function ImportStartConfirmDialog({ onConfirm, onDismiss }: ImportStartConfirmDialogProps) {
	const { sdObject } = useImportContext();

	return (
		<AlertDialog
			open
			title="Start import"
			message={`This action will import these records into the ${sdObject._pluralName} board. Are you sure you want to start importing?`}
		>
			{() => (
				<div className="flex items-center justify-end gap-3">
					<Button size="lg" onClick={onDismiss} variant="secondary">
						Cancel
					</Button>
					<Button onClick={onConfirm} size="lg">
						Start import
					</Button>
				</div>
			)}
		</AlertDialog>
	);
}
