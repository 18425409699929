import { useObjectFieldOptions } from "../../../hooks/useObjectFieldOptions";
import { RuleValueSelect } from "./RuleValueSelect";
import { useNotificationRuleDialogContext } from "../../../hooks/useNotificationRuleDialogContext";

export function FieldValueSelector() {
	const { selectedSDObject, selectedField, setSelectedField } = useNotificationRuleDialogContext();
	const { fieldOptions } = useObjectFieldOptions(selectedSDObject);

	return (
		<RuleValueSelect
			title="Field selection"
			value={selectedField}
			onChange={setSelectedField}
			selectOptions={fieldOptions}
			multiple={false}
		/>
	);
}
