import {
	FIELD_COMPONENT_TYPES,
} from "@salesdesk/salesdesk-ui";
import { createUniqueId } from "@salesdesk/salesdesk-utils";

import {
	mBooleanFieldDef,
	mColorFieldDef,
	mIconChooserFieldDef,
	mRichTextFieldDef,
	mStringFieldDef,
} from "../../../fields";
import { mTransientDef } from "../transient_def";

export class mObjectDefinitionDef extends mTransientDef {
	static CLASS_NAME = "mObjectDefinitionDef";
	static NAME = "Edit Object Definition";
	static PLURAL_NAME = "Object Definitions";
	static ICON = "fa-plus";

	static ID = 103;

	constructor(ownerId, context) {
		super(mObjectDefinitionDef.ID, ownerId, context);

		super.className = mObjectDefinitionDef.CLASS_NAME;
		super.name = mObjectDefinitionDef.NAME;
		super.pluralName = mObjectDefinitionDef.PLURAL_NAME;
		super.displayName = "Create / Edit Object Definition";

		super.description = "Create / Edit Object Definition";
		super.icon = mObjectDefinitionDef.ICON;

		super.commentsSupported = false;

		super.data.name = "Create / Edit Object Definition";
		super.data.pluralName = "Create / Edit Object Definitions";
		super.data.displayName = "Create / Edit Object Definition";

		this._supportsTableCardView = false;

		let name = new mStringFieldDef(createUniqueId());
		name.name = "objectDefName";
		name.pluralName = "objectDefNames";
		name.displayName = "Name";
		name.toolTip = "The name the system uses for this object type.";
		name.required = true;

		let pluralName = new mStringFieldDef(createUniqueId());
		pluralName.name = "objectDefPluralName";
		pluralName.pluralName = "objectDefNames";
		pluralName.displayName = "Plural Name";
		pluralName.toolTip = "The name the system uses for this object type in its plural context.";
		pluralName.required = true;

		let displayName = new mStringFieldDef(createUniqueId());
		displayName.name = "objectDefDisplayName";
		displayName.pluralName = "objectDisplayNames";
		displayName.displayName = "Display Name";
		displayName.toolTip = "The name the user sees for this object type.";
		displayName.required = true;

		let color = new mColorFieldDef(createUniqueId());
		color.name = "objectDefColor";
		color.pluralName = "colors";
		color.displayName = "Color";
		color.componentType = FIELD_COMPONENT_TYPES.COLOR.name;
		color.toolTip = "Pick a color for this new object type.";
		color.required = true;

		let iconChooser = new mIconChooserFieldDef(createUniqueId());
		iconChooser.name = "objectDefIcon";
		iconChooser.pluralName = "icons";
		iconChooser.displayName = "Icon";
		iconChooser.componentType = FIELD_COMPONENT_TYPES.ICON.name;
		iconChooser.toolTip = "Pick an icon for this new object type.";
		iconChooser.required = true;

		let description = new mRichTextFieldDef(createUniqueId());
		description.name = "objectDefDescription";
		description.pluralName = "descriptions";
		description.displayName = "Description";
		description.toolTip = "Any additional information about this object and how it will be used.";

		let comments = new mBooleanFieldDef(createUniqueId());
		comments.name = "objectDefComments";
		comments.pluralName = "comments";
		comments.displayName = "Comments";
		comments.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
		comments.toolTip = "Whether or not this object supports user comments.";

		let history = new mBooleanFieldDef(createUniqueId());
		history.name = "objectDefHistory";
		history.pluralName = "history";
		history.displayName = "History";
		history.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
		history.toolTip = "Whether or not this object supports history.";

		let searchable = new mBooleanFieldDef(createUniqueId());
		searchable.name = "objectDefSearchable";
		searchable.pluralName = "searchables";
		searchable.displayName = "Searchable";
		searchable.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
		searchable.toolTip = "Whether or not this object type can be searched for buy users.";

		super.data.addChild(name);
		super.data.addChild(pluralName);
		super.data.addChild(displayName);
		super.data.addChild(iconChooser);
		super.data.addChild(color);
		super.data.addChild(description);
		super.data.addChild(comments);
		super.data.addChild(history);
		super.data.addChild(searchable);
	}
}
