import { useMemo } from "react";

import { SDObject, SDRecord, getSDObjectFieldByName, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";
import { mAccountDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { formatFERecordFieldValue } from "../../utils/records";
import { Logo } from "../../../../components/Logo";
import { useLogoFieldValue } from "../../../fields";
import { LogoFieldValue } from "../../../fields";

const OBJECTS_WITH_LOGO_PREVIEW = {
	[mWorkspaceDef.ID]: mWorkspaceDef.COMPANY_LOGO_FIELD_NAME,
	[mAccountDef.ID]: mAccountDef.LOGO_FIELD_NAME,
};

interface RecordLogoPreviewProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
}

export function RecordLogoPreview({ sdObject, sdRecord }: RecordLogoPreviewProps) {
	const fieldName = OBJECTS_WITH_LOGO_PREVIEW[sdObject._id];

	const logoFieldValue = useMemo(() => {
		if (!fieldName) return null;

		const logoField = getSDObjectFieldByName(sdObject, fieldName);

		if (!logoField) return null;

		const logoFieldValue = getSDRecordFieldValue(sdRecord, logoField._id)?._value ?? null;

		return formatFERecordFieldValue(sdObject, sdRecord, logoField, logoFieldValue) as LogoFieldValue | undefined;
	}, [sdObject, sdRecord, fieldName]);

	const { fileId, companyName } = useLogoFieldValue(logoFieldValue);

	return fieldName ? <Logo size="lg" image={fileId} companyName={companyName} /> : null;
}
