import {
	mContactDef,
	mGeneralAssetDef,
	mImageAssetDef,
	mInteractionMeetingDef,
	mIssueDef,
	mLeadDef,
	mMediaAssetDef,
	mOpportunityDef,
	mPdfAssetDef,
	mTaskDef,
	mWorkspaceDef,
	SdEventType,
} from "@salesdesk/salesdesk-model";
import { NotificationRuleType } from "../NotificationRuleType";
import { RECORD_SHARING_OBJECT_IDS, USER_OBJECT_DEF_IDS, USER_SHARING_OBJECT_IDS } from "../../../MOVE_TO_MODEL";

export type TitlesField = {
	[Property in keyof typeof NotificationRuleType as `${Lowercase<string & Property>}`]: string;
};

export type NotificationRuleConfig = {
	/* Rule will match events of this type */
	eventType: SdEventType;
	/* The title to use in the UI for each rule type */
	titles: TitlesField;
	/* Restricts the rule to the defined rule types.  Default: all rule types*/
	allowedRuleTypes?: NotificationRuleType[];
	/* Rule does not allow a userId to be specified. Default: false (i.e. for events generated by the SYSTEM_USER */
	userIdsNotRequired?: boolean;
	/*
	   Restricts the rule to the defined objects.  The rule can still be set to "null" which covers all defined objects.
     Default: all objects (i.e. for events specific to certain objects)
  */
	allowedObjectIds?: number[];
	/* Rule does not allow a workspace to be specified (i.e. for events where a workspace id is not set */
	workspaceIdNotRequired?: boolean;
	/* Rule does not allow an object to be specified, Default: false (i.e. for events where an object/record id is not set */
	objectIdNotRequired?: boolean;
	/* Rule does not allow a record be specified. Default: false (i.e. for events where the record cannot be known beforehand */
	recordIdNotRequired?: boolean;
	/* Rule requires a field be specified.  Default: false (i.e. for events where a field is not set */
	fieldIdRequired?: boolean;

	/* Hides the rules for Customers */
	notAuthorizedForCustomers?: boolean;
};

export const notificationRuleConfig: NotificationRuleConfig[] = [
	// Generic Record
	{
		eventType: SdEventType.RECORD_CREATED,
		titles: {
			record: "Record is created",
			user: "Creates a record",
		},
		recordIdNotRequired: true,
	},
	{ eventType: SdEventType.RECORD_DELETED, titles: { record: "Record is deleted", user: "Deletes a record" } },
	{
		eventType: SdEventType.RECORD_UPDATED,
		titles: {
			record: "Field is updated",
			user: "Updates a field",
		},
		fieldIdRequired: true,
	},
	{
		eventType: SdEventType.RECORD_OWNER_UPDATED,
		titles: { record: "Record owner is updated", user: "Updates record owner" },
	},
	{ eventType: SdEventType.RECORD_COMMENT_ADDED, titles: { record: "Comment is added", user: "Adds a comment" } },
	{ eventType: SdEventType.RECORD_COMMENT_UPDATED, titles: { record: "Comment is edited", user: "Edits a comment" } },
	{
		eventType: SdEventType.RECORD_COMMENT_DELETED,
		titles: { record: "Comment is deleted", user: "Deletes a comment" },
	},
	{ eventType: SdEventType.RECORD_NOTE_ADDED, titles: { record: "Note is added", user: "Adds a note" } },
	{ eventType: SdEventType.RECORD_NOTE_UPDATED, titles: { record: "Note is edited", user: "Edits a note" } },
	{ eventType: SdEventType.RECORD_NOTE_DELETED, titles: { record: "Note is deleted", user: "Deletes a note" } },
	{ eventType: SdEventType.RECORD_STARTED_WATCHING, titles: { record: "Started Watching", user: "Starts watching" } },
	{ eventType: SdEventType.RECORD_STOPPED_WATCHING, titles: { record: "Stopped Watching", user: "Stops watching" } },
	{ eventType: SdEventType.RECORD_SET_STARRED, titles: { record: "Set Starred", user: "Stars" } },
	{ eventType: SdEventType.RECORD_SET_UNSTARRED, titles: { record: "Unset Starred", user: "Unstars" } },
	{
		eventType: SdEventType.RECORD_SHARED_IN_WORKSPACE,
		titles: { record: "Shared in workspace", user: "Shares in workspace" },
		allowedObjectIds: RECORD_SHARING_OBJECT_IDS,
	},
	{
		eventType: SdEventType.RECORD_UNSHARED_IN_WORKSPACE,
		titles: { record: "Unshared in workspace", user: "Unshares in workspace" },
		allowedObjectIds: RECORD_SHARING_OBJECT_IDS,
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.USER_ADDED_TO_WORKSPACE,
		titles: { record: "Added to workspace", user: "Added to workspace" },
		allowedObjectIds: USER_SHARING_OBJECT_IDS,
	},
	{
		eventType: SdEventType.USER_REMOVED_FROM_WORKSPACE,
		titles: { record: "Removed from workspace", user: "Removed from workspace" },
		allowedObjectIds: USER_SHARING_OBJECT_IDS,
		notAuthorizedForCustomers: true,
	},
	// TODO: I have left these "Association" rules in for now but as there will only be one recordId in the rule to
	//  search on it will be only be able to search in the "to" association direction
	{
		eventType: SdEventType.RECORD_ASSOCIATION_CREATED,
		titles: { record: "Record is linked", user: "Links record" },
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.RECORD_ASSOCIATION_DELETED,
		titles: { record: "Record is unlinked", user: "Unlinks record" },
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.IMPORT_JOB_STARTED,
		titles: {
			record: "Import started",
			user: "Starts an import",
		},
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.IMPORT_JOB_COMPLETED,
		titles: {
			record: "Import completed",
			user: "Completes an import",
		},
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.IMPORT_JOB_FAILED,
		titles: {
			record: "Import failed",
			user: "Has an import fail",
		},
		recordIdNotRequired: true,
	},

	// Workspace
	{
		eventType: SdEventType.WORKSPACE_ENTERED,
		titles: { record: "Workspace is entered", user: "Enters workspace" },
		allowedObjectIds: [mWorkspaceDef.ID],
	},
	{
		eventType: SdEventType.WORKSPACE_RECORD_SHARED,
		titles: { record: "Record is shared in a workspace", user: "Shares a record in a workspace" },
		allowedObjectIds: [mWorkspaceDef.ID],
	},
	{
		eventType: SdEventType.WORKSPACE_RECORD_UNSHARED,
		titles: { record: "Record is unshared in a workspace", user: "Unshares a record in a workspace" },
		allowedObjectIds: [mWorkspaceDef.ID],
	},
	{
		eventType: SdEventType.WORKSPACE_USER_ADDED,
		titles: { record: "User is added", user: "Adds a user" },
		allowedObjectIds: [mWorkspaceDef.ID],
	},
	{
		eventType: SdEventType.WORKSPACE_USER_REMOVED,
		titles: { record: "User is removed", user: "Removes a user" },
		allowedObjectIds: [mWorkspaceDef.ID],
	},

	// Opportunity
	{
		eventType: SdEventType.OPPORTUNITY_WON,
		titles: { record: "Marked as won", user: "Marks as won " },
		allowedObjectIds: [mOpportunityDef.ID],
	},
	{
		eventType: SdEventType.OPPORTUNITY_LOST,
		titles: { record: "Marked as lost", user: "Marks as lost " },
		allowedObjectIds: [mOpportunityDef.ID],
	},

	// Tasks
	{
		eventType: SdEventType.TASK_COMPLETED,
		titles: { record: "Task is completed", user: "Completes task" },
		allowedObjectIds: [mTaskDef.ID],
	},
	{
		eventType: SdEventType.TASK_REOPENED,
		titles: { record: "Task is reopened", user: "Reopens task" },
		allowedObjectIds: [mTaskDef.ID],
	},
	{
		eventType: SdEventType.TASK_ASSIGNED,
		titles: { record: "Task is assigned", user: "Assigns task" },
		allowedObjectIds: [mTaskDef.ID],
	},
	{
		eventType: SdEventType.TASK_REASSIGNED,
		titles: { record: "Task is reassigned", user: "Reassigns task" },
		allowedObjectIds: [mTaskDef.ID],
	},
	{
		eventType: SdEventType.LEAD_CONVERTED_TO_CONTACT,
		titles: { record: "Lead converted to Contact", user: "Converts lead to contact" },
		allowedObjectIds: [mLeadDef.ID],
	},
	{
		eventType: SdEventType.LEAD_CONVERTED_TO_ACCOUNT,
		titles: { record: "Lead converted to Account", user: "Converts lead to account" },
		allowedObjectIds: [mLeadDef.ID],
	},
	{
		eventType: SdEventType.LEAD_CONVERTED_TO_OPPORTUNITY,
		titles: { record: "Lead converted to Opportunity", user: "Converts lead to opportunity" },
		allowedObjectIds: [mLeadDef.ID],
	},
	{
		eventType: SdEventType.CUSTOMER_USER_CREATED,
		titles: { record: "Customer access granted", user: "Grants customer access" },
		allowedObjectIds: [mLeadDef.ID, mContactDef.ID],
	},
	{
		eventType: SdEventType.USER_CREATED,
		titles: { record: "User access granted", user: "Grants user access" },
		allowedObjectIds: USER_OBJECT_DEF_IDS,
	},
	{
		eventType: SdEventType.USER_REVOKED,
		titles: { record: "User access revoked", user: "Revokes user access" },
		allowedObjectIds: USER_OBJECT_DEF_IDS,
	},

	// Comments
	{
		eventType: SdEventType.NOTE_REACTION_ADDED,
		titles: { record: "Note reaction", user: "Reacts to note" },
	},
	{
		eventType: SdEventType.NOTE_MENTION,
		titles: { record: "Note mention", user: "Mentions in note" },
	},

	// Assets
	{
		eventType: SdEventType.FILE_UPLOADED,
		titles: { record: "File is uploaded", user: "Uploads a file" },
		allowedObjectIds: [mGeneralAssetDef.ID, mMediaAssetDef.ID, mPdfAssetDef.ID, mImageAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_DELETED,
		titles: { record: "File is deleted", user: "Deletes a file" },
		allowedObjectIds: [mGeneralAssetDef.ID, mMediaAssetDef.ID, mPdfAssetDef.ID, mImageAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_DOWNLOADED,
		titles: { record: "File is downloaded", user: "Downloads a file" },
		allowedObjectIds: [mGeneralAssetDef.ID, mMediaAssetDef.ID, mPdfAssetDef.ID, mImageAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_PLAYED,
		titles: { record: "File played", user: "Plays file" },
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_PAUSED,
		titles: { record: "File paused", user: "Pauses file" },
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_SEEKED,
		titles: { record: "File seeked", user: "Seeks in file" },
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_ENDED,
		titles: { record: "File ended", user: "Ends playing file" },
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_PROCESSING_STARTED,
		titles: { record: "Processing started", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_PROCESSING_ENDED,
		titles: { record: "Processing finished", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_PROCESSING_FAILED,
		titles: { record: "Processing failed", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCODE_STARTED,
		titles: { record: "Transcoding started", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCODE_ENDED,
		titles: { record: "Transcoding finished", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCRIPTION_STARTED,
		titles: { record: "Transcribing started", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCRIPTION_ENDED,
		titles: { record: "Transcribing finished", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCRIPTION_ANALYSIS_STARTED,
		titles: { record: "Transcription analysis started", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCRIPTION_ANALYSIS_ENDED,
		titles: { record: "Transcription analysis finished", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_TRANSCRIPTION_ANALYSIS_MINUTES_EXTRACTED,
		titles: { record: "Minutes extracted", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_COMPOSITING_STARTED,
		titles: { record: "Compositing started", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.FILE_COMPOSITING_ENDED,
		titles: { record: "Compositing finished", user: "N/A" },
		allowedRuleTypes: [NotificationRuleType.Record],
		userIdsNotRequired: true,
		allowedObjectIds: [mMediaAssetDef.ID],
	},
	{
		eventType: SdEventType.PAGE_CHANGED,
		titles: { record: "Page changed", user: "Changes page" },
		allowedObjectIds: [mPdfAssetDef.ID],
	},

	// Meetings
	{
		eventType: SdEventType.MEETING_ORGANISED,
		titles: {
			record: "Meeting is organised",
			user: "Organised a meeting",
		},
		allowedObjectIds: [mInteractionMeetingDef.ID],
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.MEETING_UPDATED,
		titles: {
			record: "Meeting is updated",
			user: "Updated a meeting",
		},
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.MEETING_ENTERED,
		titles: { record: "Meeting entered", user: "Enters meeting" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.MEETING_EXITED,
		titles: { record: "Meeting exited", user: "Exits meeting" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.MEETING_STARTED,
		titles: { record: "Meeting started", user: "Starts meeting" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.MEETING_ENDED,
		titles: { record: "Meeting ended", user: "Ends meeting" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.RECORDING_STARTED,
		titles: { record: "Meeting recording started", user: "Starts recording meeting" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.RECORDING_STOPPED,
		titles: { record: "Meeting recording stopped", user: "Stops recording meeting" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.RECORDING_ANALYSIS_COMPLETE,
		titles: { record: "Meeting recording analysis completed", user: "N/A" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
		userIdsNotRequired: true,
		allowedRuleTypes: [NotificationRuleType.Record],
	},
	{
		eventType: SdEventType.FILE_PRESENTATION_STARTED,
		titles: { record: "Started presenting file", user: "Starts presenting file" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.CAMERA_ENABLED,
		titles: { record: "Meeting camera enabled", user: "Enables camera" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.CAMERA_DISABLED,
		titles: { record: "Meeting camera disabled", user: "Disables camera" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.MICROPHONE_ENABLED,
		titles: { record: "Meeting microphone enabled", user: "Enables microphone" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.MICROPHONE_DISABLED,
		titles: { record: "Meeting microphone disabled", user: "Disables microphone" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.SCREEN_SHARE_STARTED,
		titles: { record: "Start sharing screen", user: "Shares screen" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.SCREEN_SHARE_ENDED,
		titles: { record: "Stop sharing screen", user: "Stops sharing screen" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.FILE_PRESENTATION_STOPPED,
		titles: { record: "Stopped presenting file", user: "Stops presenting file" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},
	{
		eventType: SdEventType.CHAT_MESSAGE_SENT,
		titles: { record: "Send chat message", user: "Sends chat message" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
	},

	// Issues
	{
		eventType: SdEventType.ISSUE_CLOSED,
		titles: { record: "Issue is closed", user: "closed issue" },
		allowedObjectIds: [mIssueDef.ID],
	},
	{
		eventType: SdEventType.ISSUE_REOPENED,
		titles: { record: "Issue is reopened", user: "reopens issue" },
		allowedObjectIds: [mIssueDef.ID],
	},
	{
		eventType: SdEventType.ISSUE_ASSIGNED,
		titles: { record: "Issue is assigned", user: "assigns issue" },
		allowedObjectIds: [mIssueDef.ID],
	},
	{
		eventType: SdEventType.ISSUE_REASSIGNED,
		titles: { record: "Issue is reassigned", user: "reassigns issue" },
		allowedObjectIds: [mIssueDef.ID],
	},

	// User Related
	// TODO: Could do this for login if the user's record was set as the recordId which would
	// allow creating a rule for "all contacts"
	// {
	// 	eventType: SdEventType.USER_LOGGED_IN,
	// 	title: "User logged in",
	// 	recordIdNotRequired: true,
	// 	allowedObjectIds: [mSalesDeskUserDef.ID, mLeadDef.ID, mContactDef.ID],
	// },
	{
		eventType: SdEventType.USER_LOGGED_IN,
		titles: { record: "N/A", user: "Logs in" },
		allowedRuleTypes: [NotificationRuleType.User],
		workspaceIdNotRequired: true,
		objectIdNotRequired: true,
		recordIdNotRequired: true,
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.USER_LOGGED_OUT,
		titles: { record: "N/A", user: "Logs out" },
		allowedRuleTypes: [NotificationRuleType.User],
		workspaceIdNotRequired: true,
		objectIdNotRequired: true,
		recordIdNotRequired: true,
		notAuthorizedForCustomers: true,
	},

	// Messaging
	{
		eventType: SdEventType.MESSAGE_SENT,
		titles: { record: "N/A", user: "Sends a message" },
		allowedRuleTypes: [NotificationRuleType.User],
		objectIdNotRequired: true,
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.MESSAGE_UPDATED,
		titles: { record: "N/A", user: "Updates a message" },
		allowedRuleTypes: [NotificationRuleType.User],
		objectIdNotRequired: true,
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.MESSAGE_DELETED,
		titles: { record: "N/A", user: "Deletes a message" },
		allowedRuleTypes: [NotificationRuleType.User],
		objectIdNotRequired: true,
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.MESSAGE_RECORD_SHARED,
		titles: { record: "Shared in channel", user: "Shares in channel" },
	},
	{
		eventType: SdEventType.MESSAGE_HUDDLE_STARTED,
		titles: { record: "Huddle is started", user: "Starts a huddle" },
		allowedObjectIds: [mInteractionMeetingDef.ID],
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.MESSAGE_MENTION,
		titles: { record: "Message mention", user: "Mentions in message" },
		allowedObjectIds: [mWorkspaceDef.ID],
		recordIdNotRequired: true,
	},
	{
		eventType: SdEventType.MESSAGE_CHANNEL_CREATED,
		titles: { record: "N/A", user: "Creates a message channel" },
		allowedRuleTypes: [NotificationRuleType.User],
		workspaceIdNotRequired: true,
		objectIdNotRequired: true,
		recordIdNotRequired: true,
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.MESSAGE_CHANNEL_DELETED,
		titles: { record: "N/A", user: "Deletes a message channel" },
		allowedRuleTypes: [NotificationRuleType.User],
		workspaceIdNotRequired: true,
		objectIdNotRequired: true,
		recordIdNotRequired: true,
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.MESSAGE_CHANNEL_MEMBER_CREATED,
		titles: { record: "N/A", user: "Adds message channel member" },
		allowedRuleTypes: [NotificationRuleType.User],
		workspaceIdNotRequired: true,
		objectIdNotRequired: true,
		recordIdNotRequired: true,
		notAuthorizedForCustomers: true,
	},
	{
		eventType: SdEventType.MESSAGE_CHANNEL_MEMBER_DELETED,
		titles: { record: "N/A", user: "Removes message channel member" },
		allowedRuleTypes: [NotificationRuleType.User],
		workspaceIdNotRequired: true,
		objectIdNotRequired: true,
		recordIdNotRequired: true,
		notAuthorizedForCustomers: true,
	},
];
