import { isValidElement } from "react";
import clsx from "clsx";

import {
	MENU_VARIANT_PADDING_CLASSES,
	MenuContents,
	MenuElement,
	MenuItem,
	MenuSection,
	PopoverMenuVariant,
	PopoverMenuWidthVariant,
} from "../types";
import { PopoverMenuItem } from "../components/PopoverMenu/PopoverMenuItem";
import { Divider } from "../../../components/Divider/Divider";

export function getPopoverMenuWidthStyling(widthVariant: PopoverMenuWidthVariant) {
	const smallWidth = { maxWidth: "240px" };
	switch (widthVariant) {
		case "sm":
			return smallWidth;
		case "fit":
			return { minWidth: "175px" };
		default:
			return smallWidth;
	}
}

export function generateMenuSections(menuContents: MenuContents): MenuSection[] {
	if (!menuContents.length) {
		return menuContents as MenuSection[];
	}

	const sections: MenuSection[] = [];
	let currentSection: MenuElement[] = [];

	menuContents.forEach((item) => {
		if (Array.isArray(item) || "sectionTitle" in item) {
			if (currentSection.length > 0) {
				sections.push(currentSection);
				currentSection = [];
			}

			sections.push(item);
		} else {
			currentSection.push(item);
		}
	});

	if (currentSection.length > 0) {
		sections.push(currentSection);
	}

	return sections;
}

export function generateMenuContentComponents(
	activeIndex: number | null,
	menuSections: MenuSection[],
	variant: PopoverMenuVariant
) {
	let itemIndex = 0;

	return menuSections.map((section, sectionIndex) => {
		const isTitledSection = "sectionTitle" in section;
		const { items } = isTitledSection ? section : { items: section };

		const sectionOptions = items.map((item) => {
			if (isValidElement(item)) {
				return item;
			}

			const itemAsMenuItem = item as MenuItem;
			let key = itemAsMenuItem.text ? itemAsMenuItem.text + "-" + itemAsMenuItem.icon : undefined;
			if (!key && typeof itemAsMenuItem.badge === "string") key = itemAsMenuItem.badge;
			if (!key && typeof itemAsMenuItem.badge === "object") key = itemAsMenuItem.badge.text + "-" + itemAsMenuItem.icon;

			return (
				<PopoverMenuItem key={key} item={itemAsMenuItem} isActive={itemIndex++ === activeIndex} variant={variant} />
			);
		});

		if (isTitledSection && section.sectionTitle) {
			sectionOptions.unshift(
				<div
					key={`title-${sectionIndex}`}
					className={clsx(
						"text-c_text_secondary w-full select-none",
						MENU_VARIANT_PADDING_CLASSES[variant],
						section.boldTitle ? "text-label-sm" : "text-body-sm"
					)}
				>
					{section.sectionTitle}
				</div>
			);
		}

		if (sectionIndex < menuSections.length - 1) {
			sectionOptions.push(<Divider key={`divider-${sectionIndex}`} />);
		}

		return sectionOptions;
	});
}
