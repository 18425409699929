import {
	mAssetDef,
	mContactDef,
	mDocDef,
	mInteractionMeetingDef,
	mLeadDef,
	mMediaAssetDef,
	mOpportunityDef,
	mProductDef,
	mSalesDeskUserDef,
	mTaskDef,
	mWorkspaceDef,
	mAccountDef,
	mIssueDef,
} from "@salesdesk/salesdesk-model";
import { DirectedSDObjectAssociation } from "../../recordAssociations";
import { getSDRecordSingleOptionFieldValueByFieldName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ThemeName, UI_THEMES } from "@salesdesk/salesdesk-ui";

export const WORKSPACE_ORIGIN_OBJECTS = [mLeadDef.ID, mOpportunityDef.ID, mAccountDef.ID];

export const WORKSPACE_OBJECT_BOARDS = [mAssetDef.ID, mDocDef.ID, mTaskDef.ID, mIssueDef.ID, mInteractionMeetingDef.ID];

export const WORKSPACE_SHARABLE_RECORD_TYPES = [
	mSalesDeskUserDef.ID,
	mLeadDef.ID,
	mContactDef.ID,
	mTaskDef.ID,
	mIssueDef.ID,
	mInteractionMeetingDef.ID,
	mAssetDef.ID,
	mMediaAssetDef.ID,
	mDocDef.ID,
	mProductDef.ID,
];

export const WORKSPACE_CREATABLE_RECORD_TYPES = WORKSPACE_SHARABLE_RECORD_TYPES.filter(
	(id) => !WORKSPACE_ORIGIN_OBJECTS.includes(id)
);

// Filters out all associations where the connected object is not visible in the workspace
export const CUSTOMER_USER_VISIBLE_ASSOCIATIONS = WORKSPACE_SHARABLE_RECORD_TYPES.filter((id) =>
	WORKSPACE_OBJECT_BOARDS.includes(id)
);

export function filterVisibleWorkspaceAssociations(
	associations: DirectedSDObjectAssociation[],
	isSalesDeskUser: boolean,
	hideWorkspaceAssociation = false
) {
	const visibleAssociations = isSalesDeskUser
		? hideWorkspaceAssociation
			? WORKSPACE_SHARABLE_RECORD_TYPES
			: [...WORKSPACE_SHARABLE_RECORD_TYPES, mWorkspaceDef.ID]
		: CUSTOMER_USER_VISIBLE_ASSOCIATIONS;
	return associations.filter((association) => visibleAssociations.includes(association.connectedObject.id));
}

export function getWorkspaceTheme(workspaceObject?: SDObject, workspaceRecord?: SDRecord): ThemeName {
	if (!workspaceObject || !workspaceRecord) {
		return "grey";
	}

	const currentThemeName = getSDRecordSingleOptionFieldValueByFieldName(
		workspaceObject,
		workspaceRecord,
		mWorkspaceDef.THEME_FIELD_NAME
	);

	if (currentThemeName && currentThemeName.toLowerCase() in UI_THEMES) {
		return currentThemeName.toLowerCase() as ThemeName;
	}

	return "grey";
}

// Filter out specific system fields we don't want to display
// so that custom fields still appear in the form
export const BASIC_INFO_FIELD_NAMES_TO_FILTER = [
	mWorkspaceDef.PROJECT_OVERVIEW_FIELD_NAME,
	mWorkspaceDef.INTRODUCTION_FIELD_NAME,
];
