import { icons, IconEntry } from "@phosphor-icons/core";

const ICONS_PER_ROW = 6;
export interface IconInfo extends Omit<IconEntry, "tags"> {
	tags: string;
}

export const iconData: ReadonlyArray<IconInfo> = icons
	.map((entry) => ({
		...entry,
		tags: entry.tags.join(" "),
	}))
	.reverse();

export function getMatchedIconRows(searchValue: string) {
	const searchableValue = searchValue.toLowerCase().split(" ").join("-");
	let currentRowIndex = 0;
	return iconData.reduce((result, iconEntry) => {
		if (iconEntry.name.includes(searchableValue) || iconEntry.tags.includes(searchableValue)) {
			if (!Array.isArray(result[currentRowIndex])) {
				result.push([]);
			}

			if (result[currentRowIndex].length < ICONS_PER_ROW) {
				result[currentRowIndex].push(iconEntry);
			} else {
				currentRowIndex += 1;
				result.push([]);
				result[currentRowIndex].push(iconEntry);
			}
		}

		return result;
	}, [] as IconInfo[][]);
}
