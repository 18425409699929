import { SdEventSupertype } from "@salesdesk/salesdesk-model";
import { ButtonSelect } from "../../inputs";
import { useMemo } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";

export type FilterEventType = SdEventSupertype | "all";

// UI events excluded as they are currently never record-specific
const filterEventTypes: FilterEventType[] = [
	"all",
	SdEventSupertype.STATUS_UPDATE,
	SdEventSupertype.FIELD_UPDATES,
	SdEventSupertype.RECORD_CONNECTIONS,
	SdEventSupertype.NOTE_UPDATE,
	SdEventSupertype.RECORD_VIEW,
];
const filterDisplayNames: Record<FilterEventType, string> = {
	all: "Type",
	[SdEventSupertype.STATUS_UPDATE]: "Status updates",
	[SdEventSupertype.RECORD_VIEW]: "Record views",
	[SdEventSupertype.FIELD_UPDATES]: "Field updates",
	[SdEventSupertype.COMMENT_UPDATE]: "Comment updates",
	[SdEventSupertype.NOTE_UPDATE]: "Note updates",
	[SdEventSupertype.RECORD_CONNECTIONS]: "Record connections",
	[SdEventSupertype.UI]: "UI events",
	[SdEventSupertype.ACCESS]: "Access events",
	[SdEventSupertype.VIDEO_CALL_UPDATES]: "Video call events",
	[SdEventSupertype.MEDIA_UPDATE]: "Media update events",
	[SdEventSupertype.MESSAGING]: "Messaging events",
};

interface TypeFilterProps {
	value?: FilterEventType;
	onChange: (eventType: FilterEventType) => void;
}

export function TypeFilter({ value, onChange }: TypeFilterProps) {
	const options = useMemo(() => {
		return filterEventTypes.map((eventType) => ({
			id: eventType,
			name: filterDisplayNames[eventType],
		}));
	}, []);

	return (
		<ButtonSelect
			placeholder={filterDisplayNames["all"]}
			value={value ?? "all"}
			selectPopoverOptions={{
				placement: "bottom-start",
			}}
			onChange={(id) => onChange(id as FilterEventType)}
			options={options}
			startIcon={ICONS.filter}
		/>
	);
}
