import { useEffect, useMemo, useRef } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { BoardRecordDetails } from "../types";
import { generateBoardRecord } from "../utils";

/**
 * Caches board records to avoid re-generating them for unchanged sdRecord references,
 * improving performance and reducing re-renders when records are added/removed from
 * the list.
 */
export function useMemoizedBoardRecords(sdRecords: SDRecord[], sdObject?: SDObject) {
	const boardRecordDetailsMapRef = useRef(createBoardRecordDetailsMap());

	useEffect(() => {
		boardRecordDetailsMapRef.current = createBoardRecordDetailsMap();
	}, [sdObject]);

	return useMemo(() => {
		if (!sdObject) {
			return [];
		}

		return sdRecords.map((record) => {
			const memoizedRecordDetails = boardRecordDetailsMapRef.current.get(record._id);

			if (memoizedRecordDetails && memoizedRecordDetails.originalRecord === record) {
				return memoizedRecordDetails.boardRecord;
			}

			const boardRecord = generateBoardRecord(record, sdObject);
			boardRecordDetailsMapRef.current.set(record._id, { originalRecord: record, boardRecord });

			return boardRecord;
		});
	}, [sdRecords, sdObject]);
}

function createBoardRecordDetailsMap() {
	return new Map<SDRecord["_id"], { originalRecord: SDRecord; boardRecord: BoardRecordDetails }>();
}
