import { useMemo, useCallback } from "react";

import { Field, SDObject } from "@salesdesk/salesdesk-schemas";
import { SortingPopover } from "./SortingPopover";
import { getSortableMetadataFields } from "../../../../ObjectBoard/utils";
import { getSortableSDObjectFields } from "../../../../objects/utils/objects";
import { SortingDetails } from "../types";
import { generateNewSortingDetails } from "../utils";

interface ObjectSortingPopoverProps {
	value: SortingDetails[];
	onChange: (value: SortingDetails[]) => void;
	sdObject: SDObject;
	disabled?: boolean;
	iconOnly?: boolean;
}

export function ObjectSortingPopover({ sdObject, ...props }: ObjectSortingPopoverProps) {
	const getFieldOption = useCallback(
		(field: Field, isMetadata?: boolean) => ({
			id: isMetadata ? field._name : String(field._id),
			name: field._displayName,
			icon: field._icon,
		}),
		[]
	);

	const fieldOptions = useMemo(() => {
		return [
			getSortableSDObjectFields(sdObject).map((field) => getFieldOption(field)),
			getSortableMetadataFields().map((field) => getFieldOption(field, true)),
		];
	}, [sdObject, getFieldOption]);

	const defaultValues = useMemo(() => [generateNewSortingDetails(sdObject)], [sdObject]);

	return <SortingPopover {...props} fieldOptions={fieldOptions} defaultValues={defaultValues} />;
}
