import { useMemo } from "react";

import { SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectsQuery } from "../features/objects/api/objectsApi";

export function useGetObjectById(objectId?: SDObject["_id"]) {
	const { data, isLoading, isUninitialized } = useGetObjectsQuery();

	return useMemo(() => {
		return {
			sdObject: data?.find((object) => object._id === objectId),
			isLoading: isLoading || isUninitialized, // isLoading is false before the request is initialised
		};
	}, [data, isLoading, isUninitialized, objectId]);
}
