import { SDObject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { LinkingModalTabs } from "../../../../../../../recordAssociations";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { Button } from "@salesdesk/daisy-ui";

interface NoResourcesViewProps {
	associationObject?: SDObject;
	onLinkAssociationClick?: (modalTab?: LinkingModalTabs) => void;
	isFiltered?: boolean;
}

export function NoResourcesView({ associationObject, onLinkAssociationClick, isFiltered }: NoResourcesViewProps) {
	const { workspaceObject } = useWorkspaceContext();

	const objectName = associationObject?._pluralName || "records";
	const resourceTypeText = `non-shared ${objectName} linked to this ${workspaceObject?._displayName || "Workspace"}`;

	return (
		<div className="text-c_text_placeholder text-body-sm my-4 w-full text-center">
			<div className="flex flex-col items-center gap-6">
				{isFiltered ? `No ${resourceTypeText} match the current filters` : `There are no ${resourceTypeText} yet.`}
				{onLinkAssociationClick && (
					<Button
						size="sm"
						variant="secondary"
						startIcon={ICONS.plus}
						onClick={() => onLinkAssociationClick(LinkingModalTabs.linking)}
					>
						Link {objectName}
					</Button>
				)}
			</div>
		</div>
	);
}
