import { PropsWithChildren } from "react";
import { MockPrincipalContext } from "../hooks/useMockPrincipalContext";
import { WebPrincipal } from "../types";

interface MockPrincipalProviderProps {
	principal?: WebPrincipal;
}

export function MockPrincipalProvider({ principal, children }: PropsWithChildren<MockPrincipalProviderProps>) {
	return <MockPrincipalContext.Provider value={{ principal }}>{children}</MockPrincipalContext.Provider>;
}
