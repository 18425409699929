import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Video, {
	LocalVideoTrack,
	LocalAudioTrack,
	CreateLocalTrackOptions,
	NoiseCancellationOptions,
	CreateLocalAudioTrackOptions,
} from "twilio-video";

import {
	DEFAULT_VIDEO_CONSTRAINTS,
	getDeviceInfo,
	isPermissionDenied,
	SELECTED_AUDIO_INPUT_KEY,
	SELECTED_VIDEO_INPUT_KEY,
} from "../utils";

const noiseCancellationOptions: NoiseCancellationOptions = {
	// Manually copied from node_modules to public
	sdkAssetsPath: "/static/lib/twilio/krisp-audio-plugin/1.0.0/dist",
	vendor: "krisp",
};

export function useLocalTracks(
	setIsKrispEnabled: Dispatch<SetStateAction<boolean>>,
	setIsKrispInstalled: Dispatch<SetStateAction<boolean>>
) {
	const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
	const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
	const [isAcquiringLocalVideoTrack, setIsAcquiringLocalVideoTrack] = useState(false);
	const [isAcquiringLocalAudioTrack, setIsAcquiringLocalAudioTrack] = useState(false);

	const updateVideoTrack = useCallback((newTrack?: LocalVideoTrack) => {
		setVideoTrack(updateTrackStateFunction(newTrack));
	}, []);

	const removeLocalVideoTrack = useCallback(() => {
		updateVideoTrack(undefined);
	}, [updateVideoTrack]);

	const getLocalVideoTrack = useCallback(async () => {
		setIsAcquiringLocalVideoTrack(true);
		const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

		const { videoInputDevices, hasVideoInputDevices } = await getDeviceInfo();
		const isCameraPermissionDenied = await isPermissionDenied("camera");

		if (!hasVideoInputDevices || isCameraPermissionDenied) {
			updateVideoTrack(undefined);
			setIsAcquiringLocalVideoTrack(false);
			return;
		}

		const hasSelectedVideoDevice = videoInputDevices.some(
			(device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
		);

		const options: CreateLocalTrackOptions = {
			...(DEFAULT_VIDEO_CONSTRAINTS as NonNullable<unknown>),
			name: `camera-${Date.now()}`,
			...(hasSelectedVideoDevice && selectedVideoDeviceId ? { deviceId: { exact: selectedVideoDeviceId } } : {}),
		};

		return Video.createLocalVideoTrack(options)
			.then((newTrack) => {
				// Save the deviceId so it can be picked up by the VideoInputList component. This only matters
				// in cases where the user's video is disabled.
				if (newTrack) {
					window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newTrack.mediaStreamTrack.getSettings().deviceId ?? "");
				}

				updateVideoTrack(newTrack);
				return newTrack;
			})
			.finally(() => setIsAcquiringLocalVideoTrack(false));
	}, [updateVideoTrack]);

	const updateAudioTrack = useCallback((newTrack?: LocalAudioTrack) => {
		setAudioTrack(updateTrackStateFunction(newTrack));
	}, []);

	const removeLocalAudioTrack = useCallback(() => {
		updateAudioTrack(undefined);
	}, [updateAudioTrack]);

	const getLocalAudioTrack = useCallback(async () => {
		setIsAcquiringLocalAudioTrack(true);
		const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);

		const { audioInputDevices, hasAudioInputDevices } = await getDeviceInfo();
		const isMicrophonePermissionDenied = await isPermissionDenied("microphone");

		if (!hasAudioInputDevices || isMicrophonePermissionDenied) {
			updateAudioTrack(undefined);
			setIsAcquiringLocalAudioTrack(false);
			return;
		}

		const hasSelectedAudioDevice = audioInputDevices.some(
			(device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
		);

		const options: CreateLocalAudioTrackOptions = {
			noiseCancellationOptions,
			...(hasSelectedAudioDevice && selectedAudioDeviceId ? { deviceId: { exact: selectedAudioDeviceId } } : {}),
		};

		return Video.createLocalAudioTrack(options)
			.then((newTrack) => {
				if (newTrack?.noiseCancellation) {
					setIsKrispEnabled(true);
					setIsKrispInstalled(true);
				}

				updateAudioTrack(newTrack);
				return newTrack;
			})
			.finally(() => setIsAcquiringLocalVideoTrack(false));
	}, [updateAudioTrack, setIsKrispEnabled, setIsKrispInstalled]);

	const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined) as (
		| LocalAudioTrack
		| LocalVideoTrack
	)[];

	return {
		localTracks,
		getLocalVideoTrack,
		getLocalAudioTrack,
		isAcquiringLocalVideoTrack,
		isAcquiringLocalAudioTrack,
		removeLocalAudioTrack,
		removeLocalVideoTrack,
	};
}

function updateTrackStateFunction<T extends LocalAudioTrack | LocalVideoTrack>(newTrack?: T) {
	return (prevTrack?: T) => {
		if (prevTrack) {
			prevTrack.stop();
			prevTrack.mediaStreamTrack.stop();
		}
		return newTrack;
	};
}
