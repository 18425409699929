import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../../utils";
import { mOptionFieldDef } from "../option_field_def";

export class mSingleOptionFieldDef extends mOptionFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [
		FIELD_COMPONENT_TYPES.SELECT.name,
		FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name,
	];
	static defaultComponentType: FieldComponentName = mSingleOptionFieldDef.supportedComponentTypes[0];

	constructor(id: string | number, backingStoreName?: string) {
		super(id, backingStoreName);
		this._componentType = mSingleOptionFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.SINGLE_OPTION.icon;
	}

	override get type(): string {
		return FIELD_TYPES.SINGLE_OPTION.name;
	}

	override get supportedComponentTypes() {
		return mSingleOptionFieldDef.supportedComponentTypes;
	}

	override supportsGrouping() {
		return !this.isBackingStore() && !this.supportsMultiple() && !this.hidden && this.required;
	}

	supportsReGrouping() {
		return true;
	}

	override get formatDescription() {
		if (this._componentType === FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name) {
			let value = `Search`;

			if (this.pluralName) {
				value += ` ${this._pluralName}`;
			}

			return value;
		}
		return super.formatDescription;
	}

	override set formatDescription(formatDescription) {
		this._formatDescription = formatDescription;
	}

	override get defaultValue() {
		return this._defaultValue;
	}

	override set defaultValue(value) {
		if (this.isBackingStore()) {
			throw new Error(`Field ${this.displayName} has a backing store, so does not support default options`);
		}

		super.defaultValue = value;
	}

	override formatValue(value: any): string | undefined {
		if (this.isBackingStore()) {
			return value;
		}

		let optionValue = null;
		try {
			optionValue = this.getOptionValueById(value);
		} catch (error) {
			// ignore
		}

		if (!optionValue && typeof value == "string") {
			try {
				optionValue = this.getOptionValueByName(value);
			} catch (error) {
				// ignore
			}
		}

		return optionValue ? optionValue.id : undefined; // If option value doesn't exist (anymore), value returned as null
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isTemplate && this.required && isEmpty(value)) {
			return `An option must be selected in "${this.displayName}".`;
		}

		//Check this value is one of the options in the field
		if (!this.isBackingStore() && !isEmpty(value)) {
			try {
				this.getOptionValueById(value);
			} catch (error) {
				return error instanceof Error ? error.message : "Invalid option selected";
			}
		}

		return super.validate(value, isTemplate);
	}

	override supportsMaxLength() {
		return false;
	}

	override supportsFormatDescription() {
		return false;
	}
}
