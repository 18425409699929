import { createUniqueId, isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_CREATION_TYPE } from "./field_creation_types";

// This helper function takes a value and wraps it into an object if necessary
// If an existing object is passed in of format {id:1, name:"Medium", color: "#fff", icon: "fa-user"}
// then it is passed back unchanged. However if a single string value (say, "Medium") is passed in, it
// is wrapped into an object with the above format.
export function formatOptionFieldValue(value: any) {
	const id = !isEmpty(value?.id) ? value.id : createUniqueId();

	let name = null;
	if (value?.displayName) {
		name = value.displayName;
	} else if (value?.name) {
		name = value.name;
	} else if (typeof value != "object") {
		name = value;
	}

	const color = value?.color || null;
	const icon = value?.icon || null;
	const creationType = value?.creationType ? parseInt(value.creationType) : FIELD_CREATION_TYPE.SYSTEM;

	return {
		id,
		name,
		color,
		icon,
		creationType,
	};
}
