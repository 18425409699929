import { forwardRef } from "react";

import { DurationInput, DurationInputProps } from "../../../../../inputs";
import { EditFieldProps } from "../../../../types";

type EditDurationFieldProps = DurationInputProps & Omit<EditFieldProps, "onChange" | "value">;

export const EditDurationField = forwardRef<HTMLInputElement, EditDurationFieldProps>(
	({ inputRef, value = 0, ...durationInputProps }, ref) => {
		return <DurationInput value={value} {...durationInputProps} ref={ref} />;
	}
);
