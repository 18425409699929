import { forwardRef } from "react";
import { HexColorPicker } from "react-colorful";

import { TextInput } from "@salesdesk/daisy-ui";

import { ColorPickerInputProps } from "../../../types";
import { ColorSwatch } from "./ColorSwatch";

interface ColorInspectorProps extends ColorPickerInputProps {
	colorPalette: string[];
}

export const ColorInspector = forwardRef<HTMLInputElement, ColorInspectorProps>(
	({ value, onChange, colorPalette, ...inputProps }, ref) => {
		const isActive = Boolean(value) && colorPalette.every((color) => color !== value);
		const colorValue = value === null ? "" : value;
		return (
			<div className="color-inspector flex flex-col gap-4">
				<HexColorPicker color={colorValue} onChange={onChange} />
				<div className="flex items-center justify-between">
					<span className="text-label-sm">Hex</span>
					<div className="w-[130px]">
						<TextInput ref={ref} size="sm" textCenter value={colorValue} onChange={onChange} {...inputProps} />
					</div>
					<ColorSwatch active={isActive} size="xs" value={colorValue} />
				</div>
			</div>
		);
	}
);
