import { useCallback, useEffect, useRef } from "react";
import { ConfettiPiece } from "./utils";

interface ConfettiPieceProps {
	onConfettiFinish: () => void;
}

export function Confetti({ onConfettiFinish }: ConfettiPieceProps) {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const animationRequestId = useRef<number | null>(null);

	const createConfetti = useCallback((amount: number, canvasWidth: number) => {
		const confettiPieces: ConfettiPiece[] = [];
		for (let i = 0; i < amount; i++) {
			const confetti = new ConfettiPiece(canvasWidth);
			confettiPieces.push(confetti);
		}

		return confettiPieces;
	}, []);

	const cancelAnimation = useCallback(() => {
		if (animationRequestId.current) {
			cancelAnimationFrame(animationRequestId.current);
		}
	}, []);

	const animateConfetti = useCallback(
		(ctx: CanvasRenderingContext2D, canvasWidth: number, canvasHeight: number, confettis: ConfettiPiece[]) => {
			const areAllOutside = confettis.every((confetti) => confetti.isOutside);
			if (areAllOutside && animationRequestId.current) {
				cancelAnimation();
				onConfettiFinish();

				return;
			}

			ctx.clearRect(0, 0, canvasWidth, canvasHeight);
			for (let i = 0; i < confettis.length; i++) {
				const confetti = confettis[i];

				if (!confetti.isOutside) {
					confetti.update(canvasWidth, canvasHeight);
					confetti.draw(ctx);
				}
			}

			setTimeout(() => {
				animationRequestId.current = requestAnimationFrame(() =>
					animateConfetti(ctx, canvasWidth, canvasHeight, confettis)
				);
			}, 0);
		},
		[onConfettiFinish, cancelAnimation]
	);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");

		if (!ctx) return;

		const resizeCanvas = () => {
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
		};

		resizeCanvas();
		window.addEventListener("resize", resizeCanvas);

		const confettiPieces = createConfetti(300, canvas.width);

		animationRequestId.current = requestAnimationFrame(() =>
			animateConfetti(ctx, canvas.width, canvas.height, confettiPieces)
		);

		return () => {
			window.removeEventListener("resize", resizeCanvas);

			cancelAnimation();
		};
	}, [createConfetti, animateConfetti, cancelAnimation]);

	return <canvas ref={canvasRef} className="pointer-events-none absolute inset-0 left-0 top-0 z-50 opacity-70" />;
}
