import { Fragment, forwardRef, useEffect, useMemo, useRef, useState, FocusEvent, useCallback } from "react";
import { Combobox, Transition } from "@headlessui/react";

import { stringContainsMatch } from "@salesdesk/salesdesk-utils";
import { InputValidationState, combineRefs } from "@salesdesk/daisy-ui";

import { SelectOption } from "../../../../types";
import { debounce } from "../../../../../../utils";

import { useSelectCloseTrigger, useSelectPopoverWidth, useTypeaheadSelectDisplayValues } from "../../hooks";
import { TypeaheadSelectProps } from "../../types";
import { SelectPopover } from "../BaseSelect";
import { TypeaheadButton } from "./TypeaheadButton";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";

export const TypeaheadSelect = forwardRef<HTMLButtonElement, TypeaheadSelectProps>(
	(
		{
			id,
			value,
			onChange,
			onBlur,
			ariaAttributes,
			disabled,
			hasError,
			validationState,
			optionDisplayVariant,
			placeholder = "Select an option",
			isMultiselect,
			isClearable = true,
			...optionProps
		},
		ref
	) => {
		const isAsync = "getOptions" in optionProps;
		const options = isAsync ? optionProps.getOptions : optionProps.options;
		const getOptionsFromIds = isAsync ? optionProps.getOptionsFromIds : undefined;

		const innerSelectButtonRef = useRef<HTMLButtonElement>(null);
		const { selectKey, triggerSelectClose } = useSelectCloseTrigger(innerSelectButtonRef, disabled);
		const selectWidth = useSelectPopoverWidth(innerSelectButtonRef, "match_button", selectKey);

		const [query, setQuery] = useState("");
		const [onBlurEvent, setOnBlurEvent] = useState<FocusEvent | null>(null);
		const [selectOptions, setSelectOptions] = useState<SelectOption[]>(isAsync ? [] : optionProps.options);
		const [isLoadingOptions, setIsLoadingOptions] = useState(true);

		const [hasBeenOpened, setHasBeenOpened] = useState(false);
		const loadOptions = hasBeenOpened || !isAsync;
		const isSelectEmpty = (isMultiselect && value ? value && value?.length === 0 : !value) && query.length === 0;

		const { displayValues, isLoadingValue, resetDisplayValueCache } = useTypeaheadSelectDisplayValues(
			value,
			selectOptions,
			getOptionsFromIds
		);

		const clearSelect = useCallback(() => {
			if (isMultiselect) {
				onChange([]);
			} else {
				onChange(undefined);
			}

			setQuery("");
		}, [isMultiselect, onChange, setQuery]);

		const onQueryChange = useMemo(
			() =>
				debounce(async (query: string) => {
					setIsLoadingOptions(true);

					if (typeof options === "function") {
						try {
							return await options(query);
						} catch (error) {
							// TODO: Better handling for typeahead failing (need UX)
							console.error("Typeahead error occured");
							return null;
						}
					}

					return query ? options.filter((option) => stringContainsMatch(option.name, query)) : options;
				}, 300),
			[options]
		);

		// Reset cache if the options change
		useEffect(() => {
			resetDisplayValueCache();
		}, [options, resetDisplayValueCache]);

		useEffect(() => {
			let active = true;

			if (!loadOptions) {
				return;
			}

			onQueryChange(query)
				.then((debouncePromise) => debouncePromise)
				.then((selectOptions) => {
					if (!active) {
						return;
					}

					if (selectOptions) {
						setSelectOptions(selectOptions);
					}

					setIsLoadingOptions(false);
				});

			// Clean-up used to prevent race condition, we only want the results of
			// the most recent call of the `onQueryChange` function.
			// https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect#fixing-the-useeffect-race-condition
			return () => {
				active = false;
			};
		}, [onQueryChange, query, loadOptions]);

		const typeaheadContentsFn = ({ open }: { open: boolean }) => (
			<Popover
				keepPopoverMounted={true}
				hideWhenClosedButMounted={false}
				open={open}
				placement={"bottom-end"}
				onOpenChange={(popoverOpen) => {
					if (!popoverOpen && open) {
						triggerSelectClose();
					} else if (popoverOpen) {
						setHasBeenOpened(true);
					}

					/*
						We have to call onBlur only if typehead options are closed
					*/
					if (!popoverOpen && onBlurEvent && onBlur) {
						onBlur(onBlurEvent);
					}
				}}
			>
				<PopoverTrigger disabled={disabled}>
					<TypeaheadButton
						id={id}
						ref={combineRefs([ref, innerSelectButtonRef])}
						value={query}
						onChange={setQuery}
						onBlur={(event) => {
							if (!open && onBlur) {
								onBlur(event);
							} else {
								setOnBlurEvent(event);
							}
						}}
						displayValues={displayValues}
						disabled={disabled}
						placeholder={placeholder}
						validationState={hasError ? InputValidationState.error : validationState}
						isLoading={isLoadingValue}
						optionDisplayVariant={optionDisplayVariant}
						isMultiselect={isMultiselect}
						removeValue={(valueToRemove) => {
							if (isMultiselect) {
								onChange(value?.filter((id) => id !== valueToRemove) || []);
							}
						}}
						onApply={optionProps.onApply}
						onCancel={optionProps.onCancel}
						onClear={isSelectEmpty ? undefined : isClearable ? clearSelect : undefined}
					/>
				</PopoverTrigger>
				<PopoverContent initialFocus={-1}>
					<Transition as={Fragment}>
						<Combobox.Options style={{ width: selectWidth }}>
							<SelectPopover
								options={selectOptions}
								isTypeahead={true}
								isLoading={isLoadingOptions}
								optionDisplayVariant={optionDisplayVariant}
								isMultiselect={isMultiselect}
							/>
						</Combobox.Options>
					</Transition>
				</PopoverContent>
			</Popover>
		);

		const sharedComboboxProps = {
			disabled,
			...ariaAttributes,
		};

		// Have to separate combobox declarations due to TypeScript struggling with dynamic types
		// regarding `multiple={true/false}`
		if (isMultiselect) {
			return (
				<Combobox
					key={selectKey}
					{...sharedComboboxProps}
					value={value}
					onChange={(e) => {
						setQuery("");
						onChange(e);
					}}
					multiple={true}
				>
					{typeaheadContentsFn}
				</Combobox>
			);
		}

		return (
			<Combobox
				key={selectKey}
				{...sharedComboboxProps}
				value={value ?? ""}
				onChange={(e) => {
					setQuery("");
					onChange(e);
				}}
				multiple={false}
			>
				{typeaheadContentsFn}
			</Combobox>
		);
	}
);
