import { useState, useMemo, useEffect, useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { isEqual } from "lodash";
import clone from "clone";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { debounce } from "../../../../../utils";
import { FilterRow } from "./FilterRow";
import { FilterOperator, Filter, FilterData, EMPTY_FILTER_IDS, FilterTargetMap } from "../types";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { SelectOptionSection } from "../../../../inputs";
import { EMPTY_FILTERS } from "../utils";

const FILTERS_FIELD_ARRAY_NAME = "filters";

type FilterFormValues = { [FILTERS_FIELD_ARRAY_NAME]: Filter[] };

interface FilteringPopoverProps {
	value: FilterData;
	onChange: (value: FilterData) => void;
	filterTargetMap: FilterTargetMap;
	filterTargetOptions: SelectOptionSection[];
	defaultFilter: Filter;
	disabled?: boolean;
	iconOnly?: boolean;
}

// Fixed reference to an empty array, otherwise stateFilters will point
// to a new reference each render if no filters are set
const EMPTY_ARRAY: Filter[] = [];

export function FilteringPopover({
	value,
	onChange,
	filterTargetMap,
	filterTargetOptions,
	defaultFilter,
	disabled,
	iconOnly,
}: FilteringPopoverProps) {
	const [isOpen, setIsOpen] = useState(false);

	const isOpenRef = useRef(isOpen);

	useEffect(() => {
		isOpenRef.current = isOpen;
	}, [isOpen]);

	const previousFilterValuesRef = useRef<FilterFormValues | null>(null);
	const previousOperatorTypeRef = useRef<FilterOperator | null>(null);

	const stateFilters = value.filters || EMPTY_ARRAY;
	const filterOperator = value.type || "AND";

	const [operatorType, setOperatorType] = useState<FilterOperator>(filterOperator);

	const { control, watch, reset } = useForm<FilterFormValues>({
		mode: "onChange",
		defaultValues: { [FILTERS_FIELD_ARRAY_NAME]: [defaultFilter] },
	});

	const {
		fields: filterFields,
		append,
		remove,
	} = useFieldArray<FilterFormValues>({
		control,
		name: FILTERS_FIELD_ARRAY_NAME,
	});

	// Keeps the filter form state in sync with the data board unless the popover is open when the filter
	// state changes so that invalid filters can exist during the same session and while
	// filters are being edited
	useEffect(() => {
		if (isOpenRef.current) {
			return;
		}

		reset({
			[FILTERS_FIELD_ARRAY_NAME]: stateFilters.length ? stateFilters : [defaultFilter],
		});
		setOperatorType(filterOperator);
	}, [stateFilters, filterOperator, reset, defaultFilter]);

	const updateFilterState = useMemo(
		() =>
			debounce((operatorType: FilterOperator, newFilters: Filter[]) => {
				const validFilters: Filter[] = [];

				newFilters.forEach((filter) => {
					const hasValueField = !EMPTY_FILTER_IDS.some((id) => id === filter.filterId);
					if (
						hasValueField &&
						((typeof filter.value !== "boolean" && !filter.value) ||
							(Array.isArray(filter.value) && !filter.value.length))
					) {
						return;
					}

					validFilters.push(filter);
				});

				if (validFilters.length === 0) {
					onChange(EMPTY_FILTERS);
					return;
				}

				onChange({ type: operatorType, filters: validFilters });
			}, 250),
		[onChange]
	);

	const filterValues = watch();

	useEffect(() => {
		if (
			!isOpen ||
			(isEqual(filterValues, previousFilterValuesRef.current) && operatorType === previousOperatorTypeRef.current)
		) {
			return;
		}

		previousFilterValuesRef.current = clone(filterValues);
		previousOperatorTypeRef.current = operatorType;

		const newFilters = filterValues[FILTERS_FIELD_ARRAY_NAME] || [];

		updateFilterState(operatorType, newFilters);

		// Close popover if there are no more filter fields
		if (!newFilters.length) {
			append(defaultFilter);

			setIsOpen(false);
		}
	}, [isOpen, filterValues, operatorType, append, updateFilterState, defaultFilter]);

	const buttonText = "Filter";

	return (
		<Popover open={isOpen} placement="bottom-end" onOpenChange={setIsOpen}>
			<PopoverTrigger>
				<Tooltip text={iconOnly && !isOpen ? buttonText : undefined}>
					<Button
						startIcon={ICONS.filter}
						variant="secondary"
						size="sm"
						alertCount={stateFilters.length}
						disabled={disabled}
					>
						{iconOnly ? null : buttonText}
					</Button>
				</Tooltip>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverContainer>
					<div className="flex min-w-[480px] flex-col py-2 ">
						<div className="text-body-sm text-c_text_secondary flex items-center justify-between px-6 py-1">
							Filter using conditions
							<Button
								size="sm"
								variant="primary_text"
								onClick={() => {
									remove();
								}}
							>
								Clear all
							</Button>
						</div>
						<div className="flex max-h-[400px] min-h-[54px] flex-col overflow-auto px-6 pb-2">
							{filterFields.map((field, index) => {
								return (
									<Controller
										key={field.id}
										name={`${FILTERS_FIELD_ARRAY_NAME}.${index}`}
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<FilterRow
												key={field.id}
												index={index}
												value={value}
												onChange={onChange}
												filterTargetMap={filterTargetMap}
												filterTargetOptions={filterTargetOptions}
												onDelete={() => remove(index)}
												operatorType={operatorType}
												onOperatorTypeChange={setOperatorType}
											/>
										)}
									/>
								);
							})}
						</div>
						<div className="border-t-c_border_regular mx-6 border-t pb-2 pt-4">
							<Button
								variant="secondary"
								startIcon={ICONS.plus}
								size="sm"
								onClick={() => {
									append(defaultFilter);
								}}
							>
								New filter
							</Button>
						</div>
					</div>
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
