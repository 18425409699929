import { useMemo } from "react";
import { ProgressIndicator } from "../../../../../../components/ProgressIndicator/ProgressIndicator";

interface DisplayProgressFieldProps {
	value: number;
}

export function DisplayProgressField({ value }: DisplayProgressFieldProps) {
	const widthValue = useMemo(() => {
		if (value < 0 || !value) return 0;

		if (value > 100) return 100;

		return value;
	}, [value]);

	return (
		<div className="flex w-full items-center gap-2">
			<span className="min-w-10 shrink-0">{value}%</span>
			<ProgressIndicator percentage={widthValue} />
		</div>
	);
}
