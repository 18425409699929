import { setHours } from "date-fns";

import { formatTime } from "@salesdesk/salesdesk-utils";

import { HOUR_BOARD_SIZE, MIN_HOUR_SIZE, TIMELINE_HOURS } from "../../utils/dateTimeCalendar";

export function Timeline() {
	const hourGap = `${HOUR_BOARD_SIZE - MIN_HOUR_SIZE}px`;

	return (
		<div className="contents">
			<div className="bg-c_bg_01 sticky left-0 z-10 flex flex-col py-7" style={{ gap: hourGap }}>
				{TIMELINE_HOURS.map((hour) => (
					<div key={hour} className="text-label-xs text-c_text_secondary flex h-6 w-16 items-center">
						<div className="text-center">{formatTime(setHours(new Date().setMinutes(0), hour))}</div>
					</div>
				))}
			</div>
			<div className="flex flex-col py-7" style={{ gap: hourGap }}>
				{TIMELINE_HOURS.map((hour) => (
					<div key={hour} className="text-label-xs text-c_text_secondary flex h-6 items-center">
						<div className="bg-c_border_regular h-px w-[21px]"></div>
					</div>
				))}
			</div>
		</div>
	);
}
