import { forwardRef, InputHTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";

interface AutoScaleDateInputProps extends InputHTMLAttributes<HTMLInputElement> {
	type: "date" | "datetime-local";
}

const DEFAULT_INPUT_WIDTH = 100;

export const AutoScaleDateInput = forwardRef<HTMLInputElement, AutoScaleDateInputProps>((props, ref) => {
	const autoScaleRef = useRef<HTMLSpanElement>(null);
	const [inputWidth, setInputWidth] = useState<number>(DEFAULT_INPUT_WIDTH);

	const isDateOnly = props.type === "date";
	const date = useMemo(() => {
		if (!props.value || typeof props.value !== "string") return null;
		return parseISO(props.value);
	}, [props.value]);

	useEffect(() => {
		if (!autoScaleRef.current) return;
		const adjustmentWidth = isDateOnly ? 5 : 10; // Not sure why this adjustment is needed
		setInputWidth(autoScaleRef.current.clientWidth + adjustmentWidth);
	}, [isDateOnly, date]);

	const displayValue = useMemo(() => {
		// TODO: this will only be accurate on Chrome and with a matching locale, we'll need to expand support for this
		if (!date) {
			return isDateOnly ? "dd/mm/yyyy" : "dd/mm/yyyy --:--";
		}
		return format(date, isDateOnly ? "dd/MM/yyyy" : "dd/MM/yyyy, HH:mm");
	}, [date, isDateOnly]);

	return (
		<>
			<span ref={autoScaleRef} className="text-body-sm absolute -top-10 whitespace-nowrap opacity-0">
				{displayValue}
			</span>
			<input ref={ref} {...props} style={{ width: inputWidth }} />
		</>
	);
});
