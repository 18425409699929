import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useContext, createContext } from "react";

interface RecordDeleteContextProps {
	setRecordToDelete?: (record?: SDRecord) => void;
}

const initialValues: RecordDeleteContextProps = {
	setRecordToDelete: undefined,
};

export const RecordDeleteContext = createContext<RecordDeleteContextProps>(initialValues);

export function useRecordDeleteContext(): RecordDeleteContextProps {
	const context = useContext<RecordDeleteContextProps>(RecordDeleteContext);

	if (context === undefined) {
		throw new Error("useRecordDeleteContext must be used within a RecordDeleteContext.Provider");
	}

	return context;
}
