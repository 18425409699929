import { useCallback, useEffect, useState } from "react";
import {
	notificationsMarkRequestSchema,
	NotificationsQuery,
	NotificationsResponse,
	SDNotification,
	SDNotificationType,
} from "@salesdesk/salesdesk-schemas";
import {
	useLazyGetNotificationsQuery,
	useMarkNotificationsReadMutation,
	useMarkNotificationsUnreadMutation,
} from "../api/notificationApi";
import lodash from "lodash/fp";

const PAGE_SIZE = 50;

export function useNotifications(resetNewNotificationsCounter: () => void) {
	const [unreadOnly, setUnreadOnly] = useState(false);
	const [notificationType, setNotificationType] = useState<SDNotificationType>(SDNotificationType.ALL);
	const [searchAfter, setSearchAfter] = useState<NotificationsQuery["searchAfter"] | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(true);
	const [notifications, setNotifications] = useState<SDNotification[] | undefined>();
	// NB.  Do not use useGetNotificationsQuery.  The cache starts being used and refetches don't work as expected
	const [getNotificationsQuery] = useLazyGetNotificationsQuery();

	useEffect(() => {
		setIsLoading(true);
		setNotifications(undefined);
		getNotificationsQuery({
			notificationType,
			unreadOnly,
			size: PAGE_SIZE,
		})
			.unwrap()
			.then((response: NotificationsResponse) => {
				setNotifications(response.notifications);
				setSearchAfter(response.searchAfter);
				resetNewNotificationsCounter();
				setIsLoading(false);
			})
			.catch((error) => {
				// TODO: Handle Error
				console.log(error);
				setIsLoading(false);
			});
	}, [getNotificationsQuery, notificationType, resetNewNotificationsCounter, unreadOnly]);

	const loadNext = useCallback(() => {
		if (searchAfter) {
			setIsLoading(true);
			getNotificationsQuery({
				notificationType,
				unreadOnly,
				size: PAGE_SIZE,
				searchAfter,
			})
				.unwrap()
				.then((response: NotificationsResponse) => {
					setNotifications((previous) => {
						return [...(previous ?? []), ...response.notifications];
					});
					setSearchAfter(response.searchAfter);
					setIsLoading(false);
				})
				.catch((error) => {
					// TODO: Handle Error
					console.log(error);
					setIsLoading(false);
				});
		}
	}, [getNotificationsQuery, notificationType, searchAfter, unreadOnly]);

	// Mark as Read
	const [markNotificationsRead] = useMarkNotificationsReadMutation();
	const markRead = useCallback(
		async (toBeMarkedNotifications: SDNotification[]) => {
			const markedNotifications: SDNotification[] = [];
			const toBeMarkedChunks: SDNotification[][] = lodash.chunk(
				notificationsMarkRequestSchema.properties.messageIds.maxItems
			)(toBeMarkedNotifications);

			const markChunkPromises: Promise<void>[] = toBeMarkedChunks.map((toBeMarkedChunk) => {
				return markNotificationsRead({ messageIds: toBeMarkedChunk.map((notification) => notification.messageId) })
					.unwrap()
					.then(() => {
						markedNotifications.push(...toBeMarkedChunk);
					})
					.catch((error) => {
						// TODO: Handle error
						console.error(error);
					});
			});

			await Promise.allSettled(markChunkPromises);

			if (markedNotifications.length > 0) {
				setNotifications((previousNotifications) => {
					if (previousNotifications == null) return previousNotifications;
					if (unreadOnly)
						return previousNotifications.filter(
							(previousNotification) => !markedNotifications.includes(previousNotification)
						);
					return markPrevious(previousNotifications, markedNotifications, true);
				});
				// All visible notifications would have been filtered out so load next page in case there are more
				if (unreadOnly) loadNext();
			}
		},
		[loadNext, markNotificationsRead, unreadOnly]
	);

	// Mark as Unread
	const [markNotificationsUnread] = useMarkNotificationsUnreadMutation();
	const markUnread = useCallback(
		async (notification: SDNotification) => {
			try {
				await new Promise((resolve, reject) => {
					markNotificationsUnread({ messageIds: [notification.messageId] })
						.unwrap()
						.then(() => resolve(undefined))
						.catch((error) => reject(error));
				});

				if (!unreadOnly)
					setNotifications((previousNotifications) => {
						if (previousNotifications == null) return previousNotifications;
						return markPrevious(previousNotifications, [notification], false);
					});
			} catch (error) {
				// TODO: Handle error
				console.error(error);
			}
		},
		[markNotificationsUnread, unreadOnly]
	);

	return {
		unreadOnly,
		setUnreadOnly,
		notificationType,
		setNotificationType,
		loadNext,
		isLoading,
		notifications,
		markRead,
		markUnread,
	};
}

function markPrevious(previousNotifications: SDNotification[], markedNotifications: SDNotification[], read: boolean) {
	return previousNotifications.map((previousNotification) => {
		const markedNotification = markedNotifications.find(
			(markedNotification) => previousNotification === markedNotification
		);
		return markedNotification ? { ...markedNotification, read: read } : previousNotification;
	});
}
