import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";

export const sdApiKeyPatchRequestSchema = asConst({
	title: "sdApiKeyPatchRequestSchema",
	description: "The schema used for patching API keys",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	required: ["name"],
	properties: {
		name: {
			...stringSchema,
			title: "API Key Name",
			description: "Human-readable new name of API Key",
			maxLength: 100,
		},
	},
});
