import clsx from "clsx";
import { useMemo, useRef } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { Spinner } from "@salesdesk/daisy-ui";
import { useInfiniteScrollContainer } from "../../../../../../../hooks/ui";
import { FieldIdRecordCard, getDefaultDisplayFieldIds, SkeletonRecordCard } from "../../../../../../records";
import { useGetRecordAssociationsSummaryMap } from "../../../../../../recordAssociations";
import { GradientScrollContainer } from "../WorkspaceTasks/GradientScrollContainer";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";
import { PATHS } from "../../../../../../../routes";

interface WorkspaceIssuesListProps {
	isLoadingRecords: boolean;
	isLoadingNextPage: boolean;
	records: SDRecord[];
	issueObject?: SDObject;
	loadNextPage: () => void;
}

export function WorkspaceIssuesList({
	isLoadingRecords,
	records,
	issueObject,
	isLoadingNextPage,
	loadNextPage,
}: WorkspaceIssuesListProps) {
	const { workspaceRecord } = useWorkspaceContext();

	const issueDisplayFieldIds = useMemo(() => {
		return issueObject ? getDefaultDisplayFieldIds(issueObject, "PREVIEW") : [];
	}, [issueObject]);

	const recordIds = useMemo(() => records.map((record) => record._id), [records]);
	const { recordAssociationsSummaryMap } = useGetRecordAssociationsSummaryMap(recordIds);

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		horizontalOffset: 500,
		onBottomReached: () => {
			loadNextPage();
		},
	});

	return (
		<GradientScrollContainer
			ref={scrollContainerRef}
			offset={16}
			className={clsx("flex w-full max-w-full gap-4 pb-4", isLoadingRecords ? "overflow-hidden" : "overflow-auto")}
		>
			{isLoadingRecords ? (
				<>
					{[...Array(3)].map((_, index) => (
						<div className="w-[280px] shrink-0" key={`skeleton-card-${index}`}>
							<SkeletonRecordCard />
						</div>
					))}
				</>
			) : (
				<>
					{records.map((record) => (
						<div key={record._id} className="w-[280px] shrink-0">
							<FieldIdRecordCard
								vertical
								sdRecord={record}
								sdObject={issueObject}
								fieldIdsToDisplay={issueDisplayFieldIds}
								associationsSummary={recordAssociationsSummaryMap[record._id]}
								getRecordLink={(recordId) =>
									workspaceRecord ? PATHS.WORKSPACE_OVERVIEW(workspaceRecord._id, recordId) : null
								}
							/>
						</div>
					))}
					{isLoadingNextPage ? (
						<div className="my-auto flex h-full w-20 shrink-0 items-center justify-center">
							<Spinner size="md" />
						</div>
					) : (
						<div ref={containerBottomRef} key={records.length} />
					)}
				</>
			)}
		</GradientScrollContainer>
	);
}
