import { asConst } from "json-schema-to-ts";
import { sdRecordSummarySchema } from "../../sdRecordSummarySchema";
import { allowedTypesSchema, queryClausePropertyValueSchema } from "../../../search";
import { nonNegativeIntegerSchema } from "../../../components";

export const FieldIdRegexp = "^[0-9]+$";
export const TimeRangeFieldIdRegexp = "^[0-9]+(?:\\.start|\\.end)$";

export const recordQueryClausePropertyValueSchema = asConst({
	...queryClausePropertyValueSchema,
	additionalProperties: false,
	properties: { ...sdRecordSummarySchema.properties },
	patternProperties: {
		[FieldIdRegexp]: allowedTypesSchema,
		[TimeRangeFieldIdRegexp]: nonNegativeIntegerSchema,
	},
});
