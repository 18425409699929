import { asConst } from "json-schema-to-ts";
import { utcMillisecSchema } from "../components";

export const sdApiKeySchema = asConst({
	title: "SD API Key",
	description: "A SalesDesk API key",
	type: "object",
	required: ["apiKeyId", "createdAt"],
	additionalProperties: false,
	properties: {
		apiKeyId: {
			title: "API Key ID",
			description: "ID of API Key",
			type: "string",
		},
		name: {
			title: "API Key Name",
			description: "Human-readable name of API Key",
			type: "string",
		},
		createdAt: utcMillisecSchema,
	},
});
