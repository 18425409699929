import clsx from "clsx";

import { SkeletonRecordAssociationCard } from "../../../../../../../recordAssociations";
import { TimelineItem } from "./TimelineItem";

interface UpcomingMeetingSkeletonProps {
	isLast?: boolean;
}

export function UpcomingMeetingSkeleton({ isLast }: UpcomingMeetingSkeletonProps) {
	return (
		<div className="flex w-full">
			<TimelineItem isLoading />
			<div className={clsx("grow", isLast ? "mb-0" : "mb-2")}>
				<div className="p-1">
					<SkeletonRecordAssociationCard rows={2} />
				</div>
			</div>
		</div>
	);
}
