import { PropsWithChildren, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { LeadToOpportunityContext } from "../hooks";
import { LeadToOpportunityModal } from "./LeadToOpportunityModal";
import { useGetObjectById } from "../../../hooks";

export function LeadToOpportunityModalProvider({ children }: PropsWithChildren) {
	const [leadSDRecord, setLeadSDRecord] = useState<SDRecord | undefined>();
	const { sdObject: leadSDObject } = useGetObjectById(leadSDRecord?._objectDefId);

	return (
		<LeadToOpportunityContext.Provider
			value={{
				setLeadSDRecord,
			}}
		>
			{children}
			{leadSDRecord && leadSDObject ? (
				<LeadToOpportunityModal leadSDRecord={leadSDRecord} leadSDObject={leadSDObject} />
			) : null}
		</LeadToOpportunityContext.Provider>
	);
}
