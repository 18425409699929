import { Reaction } from "./Reaction";
import { AddReactionButton } from "./AddReactionButton";
import { ReactionType } from "../types";

interface ReactionsProps {
	reactions: ReactionType[];
	onReactionSelect: (emoji: string) => void;
	canReact: boolean;
}

export function Reactions({ reactions, canReact, onReactionSelect }: ReactionsProps) {
	if (reactions.length === 0) {
		return null;
	}

	return (
		<div className="mb-1 ml-8 flex flex-wrap gap-0.5">
			{reactions.map((reaction) => (
				<Reaction
					key={reaction.emoji}
					reaction={reaction}
					canReact={canReact}
					onClick={() => {
						onReactionSelect(reaction.emoji);
					}}
				/>
			))}
			{canReact && <AddReactionButton onReactionSelect={onReactionSelect} canReact={canReact} />}
		</div>
	);
}
