export enum OnboardingFlow {
	CREATE_A_WORKSPACE = "CREATE_A_WORKSPACE",
	ADD_FIRST_LEAD = "ADD_FIRST_LEAD",
	ADD_LEAD_FROM_DASHBOARD = "ADD_LEAD_FROM_DASHBOARD",
	START_WORKSPACE_ONBOARDING = "START_WORKSPACE_ONBOARDING",
}

export enum OnboardingFlowStep {
	OPEN_WORKSPACE_CREATION_MODAL = "OPEN_WORKSPACE_CREATION_MODAL",
}

export enum OnboardingAddLead {
	REDIRECT_TO_LEAD_AND_OPEN_MODAL_CREATION = "REDIRECT_TO_LEAD_AND_OPEN_MODAL_CREATION",
	LEAD_IS_CREATING = "LEAD_IS_CREATING",
	LEAD_CREATED_SUCCESS = "LEAD_CREATED_SUCCESS",
	LEAD_SHOW_CONFETTI = "LEAD_SHOW_CONFETTI",
}

export enum AddLeadFromDashboard {
	ADD_LEAD_DASHBOARD_PAGE = "ADD_LEAD_DASHBOARD_PAGE",
}

export enum WorkspaceOnboardingStep {
	SHOW_WORKSPACE_WELCOME_MODAL = "SHOW_WORKSPACE_WELCOME_MODAL",
}

export const ONBOARDING_FLOWS: Record<OnboardingFlow, string[]> = {
	[OnboardingFlow.CREATE_A_WORKSPACE]: [OnboardingFlowStep.OPEN_WORKSPACE_CREATION_MODAL],
	[OnboardingFlow.ADD_FIRST_LEAD]: [
		OnboardingAddLead.REDIRECT_TO_LEAD_AND_OPEN_MODAL_CREATION,
		OnboardingAddLead.LEAD_IS_CREATING,
		OnboardingAddLead.LEAD_SHOW_CONFETTI,
		OnboardingAddLead.LEAD_CREATED_SUCCESS,
	],
	[OnboardingFlow.ADD_LEAD_FROM_DASHBOARD]: [AddLeadFromDashboard.ADD_LEAD_DASHBOARD_PAGE],
	[OnboardingFlow.START_WORKSPACE_ONBOARDING]: [WorkspaceOnboardingStep.SHOW_WORKSPACE_WELCOME_MODAL],
};
