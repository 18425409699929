import { asConst } from "json-schema-to-ts";
import { userIdSchema } from "../../components";
import { channelParamsSchema } from "./channelParamsSchema";

export const channelMembershipParamsSchema = asConst({
	title: "Channel Membership Params",
	description: "When Channel Membership is created or deleted",
	type: "object",
	additionalProperties: false,
	required: [...channelParamsSchema.required, "memberUserRecordId"],
	properties: {
		...channelParamsSchema.properties,
		memberUserRecordId: userIdSchema,
	},
});
