import { createContext, useContext } from "react";
import { RecordCreateDialogProps } from "../types";

interface CreateRecordDialogContext {
	openModal: (props: Omit<RecordCreateDialogProps, "open"> | undefined) => void;
}

export const CreateRecordDialogContext = createContext<CreateRecordDialogContext | null>(null);

export function useCreateRecordDialogContext() {
	const context = useContext(CreateRecordDialogContext);
	if (!context) {
		throw new Error("useCreateRecordDialogContext must be used within a RecordCreateDialogProvider");
	}
	return context;
}
