import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { SelectOption, SelectOptionId, SelectOptionItem } from "../../../../../inputs";
import { ensureValueIsArray } from "../../../../../../utils";

import { DisplayPlaceholderField } from "../../DisplayPlaceholderField";
import { useGetOptionFieldDetails } from "../hooks/useGetOptionFieldDetails";
import { OptionFieldComponentSettings } from "../types";
import { DisplayFieldVariant } from "../../../../types";
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";

interface DisplayOptionFieldProps {
	value?: SelectOptionId | SelectOptionId[];
	placeholder?: string;
	optionFieldSettings: OptionFieldComponentSettings;
	variant?: DisplayFieldVariant;
}

export function DisplayOptionField({
	value,
	placeholder,
	optionFieldSettings,
	variant = DisplayFieldVariant.default,
}: DisplayOptionFieldProps) {
	const { getOptionsFromIds, optionDisplayVariant } = useGetOptionFieldDetails(optionFieldSettings);

	const [displayValues, setDisplayValues] = useState<SelectOption[]>();

	const valueArray = useMemo(() => ensureValueIsArray(value), [value]);
	const [isLoadingValue, setIsLoadingValue] = useState(Boolean(valueArray.length));

	useEffect(() => {
		const updateDisplayValues = async () => {
			if (!valueArray.length) {
				setDisplayValues([]);
				setIsLoadingValue(false);
				return;
			}

			setIsLoadingValue(true);

			const displayValues = await getOptionsFromIds(valueArray);

			setDisplayValues(displayValues);
			setIsLoadingValue(false);
		};

		updateDisplayValues();
	}, [valueArray, getOptionsFromIds]);

	if ((!displayValues || !displayValues.length) && !isLoadingValue) {
		return <DisplayPlaceholderField placeholder={placeholder} />;
	}

	return (
		<div
			className={clsx(
				variant === DisplayFieldVariant.rightHandPanel || variant === DisplayFieldVariant.default
					? "flex-wrap"
					: "truncate",
				"flex max-w-full gap-1"
			)}
		>
			{isLoadingValue || !displayValues
				? valueArray.map((id, index) => (
						<Skeleton key={id} className={clsx("h-7", index % 2 === 0 ? "w-28" : "w-32")} />
				  ))
				: displayValues.map((displayValue) => (
						<SelectOptionItem key={displayValue.id} option={displayValue} variant={optionDisplayVariant} />
				  ))}
		</div>
	);
}
