import { useMemo } from "react";
import { useWorkspaceContext } from "../../../../workspaces";
import { useMeetingPageContext } from "./useMeetingPageContext";
import { mLeadDef, mOpportunityDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { useObjectAssociations } from "../../../../recordAssociations";
import { useWebPrincipal } from "../../../../../auth";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

export function useGetMeetingInfoAssociations() {
	const principal = useWebPrincipal();
	const canViewFullMeetingUI = principal.can(AbilityAction.View, AbilitySubject.FullMeetingUI);

	const { workspaceId } = useWorkspaceContext();
	const inWorkspace = workspaceId != null;

	const associationObjectsToDisplay = useMemo(() => {
		return inWorkspace ? [mWorkspaceDef.ID] : [mOpportunityDef.ID, mLeadDef.ID];
	}, [inWorkspace]);

	const { meetingRecord } = useMeetingPageContext();
	const {
		objectAssociations: { prioritizedAssociations: meetingInfoAssociationDefs },
	} = useObjectAssociations(
		canViewFullMeetingUI ? meetingRecord?._objectDefId : undefined,
		associationObjectsToDisplay
	);

	return meetingInfoAssociationDefs;
}
