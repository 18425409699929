import { asConst } from "json-schema-to-ts";
import { SDErrorCode } from "./SDError";
import { stringSchema } from "../components/stringSchema";

// The original intention of this was to use it for each specific error for each endpoint and give it the specific error code that could be expected
// But this would have added the schema to every error response which was seemed to be a bit verbose
export const getErrorSchema = (errorCodes: SDErrorCode[] = Object.values(SDErrorCode)) => {
	return asConst({
		title: "Error",
		type: "object",
		required: ["errorCode"],
		properties: {
			errorCode: {
				type: "string",
				enum: Object.values(errorCodes),
			},
			description: stringSchema,
		},
		additionalProperties: false,
	});
};

export const sdErrorSchema = getErrorSchema();

export const validationErrorSchema = asConst({
	title: "Ajv JSON schema validator error",
	description: "https://ajv.js.org/",
	type: "object",
});
