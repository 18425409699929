import { useNotificationRuleDialogContext } from "../../../../hooks/useNotificationRuleDialogContext";
import { StaticTypeaheadSelect } from "../../../../../../inputs";
import { useUserActivityActionOptions } from "../../../../hooks/user/useUserActivityActionOptions";
import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";

export function UserActionValueSelector() {
	const { selectedAction, setSelectedAction } = useNotificationRuleDialogContext();
	const { actionOptions } = useUserActivityActionOptions(NotificationRuleType.User);

	return (
		<div className="flex h-full w-full flex-col justify-stretch">
			<div className="text-c_text_primary text-label-sm mx-4 flex items-center gap-2.5">Action selection</div>
			<div className="grow overflow-hidden">
				<StaticTypeaheadSelect
					value={selectedAction}
					onChange={setSelectedAction}
					selectOptions={actionOptions}
					multiple={false}
					hideSearchbar={true}
				/>
			</div>
		</div>
	);
}
