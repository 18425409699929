import { asConst } from "json-schema-to-ts";
import { subQueryClausesSchema } from "./subQueryClausesSchema";

export const subQuerySchema = asConst({
	title: "Search Sub Query",
	description: "A bool logic container for all sub query clauses",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	properties: {
		and: { type: "array", items: subQueryClausesSchema },
		not: { type: "array", items: subQueryClausesSchema },
		or: { type: "array", items: subQueryClausesSchema },
	},
	nullable: false,
});
