import { asConst } from "json-schema-to-ts";
import { sdRecordSummarySchema } from "../../sdRecordSummarySchema";
import { recordQueryClauseFieldOnlyValuesSchema } from "./recordQueryClauseFieldOnlyValuesSchema";

const summaryPropertiesWithArrayValues = sdRecordSummarySchema.required.map((propertyName) => ({
	[propertyName]: {
		type: "array",
		items: { type: sdRecordSummarySchema.properties[propertyName].type },
		minItems: 1,
	},
}));

export const recordQueryClausePropertyValuesSchema = asConst({
	...recordQueryClauseFieldOnlyValuesSchema,
	properties: { ...Object.assign({}, ...summaryPropertiesWithArrayValues) },
});
