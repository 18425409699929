import { useCallback, useEffect, useMemo, useState } from "react";

import { SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";

import { usePotentialOriginRecordSearch } from "../hooks/usePotentialOriginRecordSearch";
import { ObjectFilteringPopover, ObjectSortingPopover, RecordModalTable } from "../../../../../../records";
import { SkeletonTransition } from "../../../../../../../components/Skeleton/SkeletonTransition";
import { DebouncedSearchbar } from "../../../../../../inputs";

interface OriginRecordTableProps {
	sdObject: SDObject;
	updateRecordCount: (sdObjectId: number, count: number) => void;
	selectedRecord: SDRecord | null;
	setSelectedRecord: (record: SDRecord | null) => void;
	onNoResult?: (objectId: number) => void;
}

export function OriginRecordTable({
	sdObject,
	updateRecordCount,
	selectedRecord,
	setSelectedRecord,
	onNoResult,
}: OriginRecordTableProps) {
	const {
		records,
		loadNextPage,
		hitCount,
		isLoadingNewSearchParams,
		isLoadingNextPage,
		filter,
		setFilter,
		sorting,
		setSorting,
		searchQuery,
		setSearchQuery,
	} = usePotentialOriginRecordSearch(sdObject);

	const [isFiltered, setIsFiltered] = useState(false);

	const selectedRecords = useMemo(() => (selectedRecord ? [selectedRecord] : []), [selectedRecord]);
	const setSelectedRecords = useCallback(
		(records: SDRecord[]) => {
			setSelectedRecord(records[0] ?? null);
		},
		[setSelectedRecord]
	);

	useEffect(() => {
		setIsFiltered(filter.filters.length > 0 || Boolean(searchQuery));
	}, [filter.filters.length, searchQuery]);

	useEffect(() => {
		if (hitCount !== undefined && !isFiltered) {
			updateRecordCount(sdObject._id, hitCount);
		}
	}, [updateRecordCount, hitCount, isFiltered, sdObject._id]);

	const noResults = !isLoadingNewSearchParams && records.length === 0;
	const noValidOriginRecords = noResults && !isFiltered;

	useEffect(() => {
		if (onNoResult && noValidOriginRecords) {
			onNoResult(sdObject._id);
		}
	}, [sdObject, noValidOriginRecords, onNoResult]);

	return (
		<div className="flex h-full w-full flex-col overflow-hidden">
			<div className="mb-6 flex justify-between">
				<div className="flex w-full flex-row gap-2">
					<ObjectFilteringPopover
						disabled={noValidOriginRecords}
						value={filter}
						onChange={setFilter}
						sdObject={sdObject}
					/>
					<ObjectSortingPopover
						disabled={noValidOriginRecords}
						value={sorting}
						onChange={setSorting}
						sdObject={sdObject}
					/>
					<div className="ml-auto">
						<DebouncedSearchbar onChange={setSearchQuery} isCollapsible={false} disabled={noValidOriginRecords} />
					</div>
				</div>
			</div>
			<div className="flex flex-grow flex-col overflow-hidden">
				<SkeletonTransition className="flex flex-col overflow-hidden">
					<RecordModalTable
						records={records}
						sdObject={sdObject}
						selectedRecords={selectedRecords}
						setSelectedRecords={setSelectedRecords}
						singleSelect={true}
						onBottomReached={loadNextPage}
						isLoadingRecords={isLoadingNewSearchParams}
						isLoadingNextPage={isLoadingNextPage}
						numOfPlaceholderRows={6}
					/>
					{noResults ? (
						<span className="text-body text-c_text_secondary mb-6 text-center">
							{isFiltered
								? `No ${sdObject._displayName} origin records match the selected filters`
								: `There are no ${sdObject._displayName} origin records available`}
						</span>
					) : null}
				</SkeletonTransition>
			</div>
		</div>
	);
}
