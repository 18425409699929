import { SdEventType } from "@salesdesk/salesdesk-model";
import { useEventContext } from "../../../hooks/useEventContext";
import { TipTapContent } from "./TipTapContent";

export function NotePayload() {
	const { event } = useEventContext();
	if (!event.params?.content) {
		return null;
	}
	const content = event.params.content as string;
	return <TipTapContent content={content} deleted={event.event_type === SdEventType.RECORD_NOTE_DELETED} />;
}
