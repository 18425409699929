import { Events } from "../../../../../../../events";
import { useVideoCallLogicContext } from "../../../../../VideoCallProvider";

export function VideoHistoryPanel() {
	const { currentMeetingRecord } = useVideoCallLogicContext();

	return (
		<div className="flex h-full max-h-full flex-col gap-4 overflow-x-clip pb-4">
			<h2 className="text-label">History</h2>
			{currentMeetingRecord ? <Events sdRecord={currentMeetingRecord} /> : null}
		</div>
	);
}
