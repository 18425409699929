import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";
import { recordIdSchema } from "../../properties";
import { AssociationSide } from "@salesdesk/salesdesk-model";

export const hasAssociationToRecordForObjectAssociationClausePropertySchema = asConst({
	title: "Has Association To Record For Object Association",
	description: "Property name is the definition and the value is the recordId",
	type: "object",
	required: ["objectAssociationId", "recordId", "associationSide"],
	additionalProperties: false, //TODO: change to recordIdSchema when this gets move to /records
	properties: {
		objectAssociationId: idSchema,
		recordId: recordIdSchema,
		associationSide: {
			title: "Association side",
			description: "Search will return records on this side of the association",
			type: "string",
			enum: Object.values(AssociationSide),
		},
	},
});
