import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";

interface CarouselControlsProps {
	title: string;
	currentIndex: number;
	total: number;
	onIndexChange: (newIndex: number) => void;
	initialLoad?: boolean;
}

export function CarouselControls({ title, currentIndex, total, onIndexChange, initialLoad }: CarouselControlsProps) {
	const maxIndex = total - 1;

	const move = (direction: number) => () => {
		onIndexChange(Math.max(0, Math.min(currentIndex + direction, maxIndex)));
	};

	return (
		<div className="text-label-sm mb-3 flex items-center gap-2">
			<div className="text-c_text_secondary mr-auto">{title}</div>
			{initialLoad ? (
				<Skeleton className="h-6 w-28" />
			) : total > 0 ? (
				<>
					<Button
						startIcon={ICONS.caretLeft}
						variant="text"
						size="xs"
						onClick={move(-1)}
						disabled={currentIndex <= 0}
					/>
					<div className="text-c_text_primary min-w-14 text-center">
						{currentIndex + 1}
						<span className="text-c_text_placeholder">/{total}</span>
					</div>
					<Button
						startIcon={ICONS.caretRight}
						variant="text"
						size="xs"
						onClick={move(1)}
						disabled={currentIndex >= maxIndex}
					/>
				</>
			) : null}
		</div>
	);
}
