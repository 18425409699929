import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";

export function ChannelPreviewSkeleton() {
	return (
		<div className="border-c_border_regular flex h-[74px] w-full items-start gap-3 border-b p-4">
			<Skeleton className="border-c_bg_01 size-8 shrink-0 rounded-full border-2" />
			<div className="flex w-full max-w-full flex-col items-start gap-0.5 overflow-hidden">
				<Skeleton className="h-5 w-full" />
				<Skeleton className="h-5 w-3/4" />
			</div>
			<Skeleton className="h-5 w-9 shrink-0" />
		</div>
	);
}
