import { forwardRef, useContext } from "react";
import clsx from "clsx";

import { RecordPreviewPopover } from "../../../records";
import { User } from "../../types";
import { getInitials } from "../../utils";
import { Avatar } from "../Avatar";
import { PopoverTriggerContext } from "@salesdesk/daisy-ui";
import { InlineUserLoading } from "./InlineUserLoading";

interface InlineUserProps {
	user: User;
	disabled?: boolean;
	active?: boolean;
	isSystemUser?: boolean;
}

export const InlineUser = forwardRef<HTMLDivElement, InlineUserProps>(
	({ user, disabled, active = false, isSystemUser = false }: InlineUserProps, ref) => {
		const { open: popoverOpen } = useContext(PopoverTriggerContext);

		if (!user.fullName && !isSystemUser) {
			return <InlineUserLoading />;
		}

		return (
			<RecordPreviewPopover recordId={!isSystemUser ? user.id : undefined}>
				<div
					ref={ref}
					className={clsx(
						"flex w-fit max-w-full items-center gap-2 rounded-full indent-0 align-baseline transition-colors",
						{
							"bg-c_bg_02": active || popoverOpen,
							"hover:bg-c_bg_02": popoverOpen !== null,
						}
					)}
				>
					<Avatar
						initials={getInitials(user.fullName)}
						avatarFileId={user.avatarFileId}
						size="xs"
						isSystemUser={isSystemUser}
						withBorder
					/>
					{!isSystemUser && (
						<span className={clsx("text-label-sm truncate", disabled ? "text-c_text_disabled" : "text-c_text_primary")}>
							{user.fullName}
						</span>
					)}
				</div>
			</RecordPreviewPopover>
		);
	}
);
