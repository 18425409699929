import { useCallback, useEffect, useRef, useState } from "react";

export function useFullscreenToggle() {
	const elementRef = useRef<HTMLDivElement>(null);
	const [isFullscreen, setIsFullscreen] = useState(false);

	const exitFullscreen = useCallback(() => {
		const element = elementRef.current;
		if (!element) {
			return;
		}

		if (document.fullscreenElement === element) {
			document.exitFullscreen();
		}

		if (document.fullscreenElement !== element) {
			setIsFullscreen(false);
		}

		element.removeEventListener("fullscreenchange", exitFullscreen);
	}, []);

	const toggleFullscreen = useCallback(() => {
		const element = elementRef.current;

		if (!element) {
			return;
		}

		if (isFullscreen) {
			exitFullscreen();
		} else {
			element.requestFullscreen();

			// Adds the event listener after we've switched to fullscreen
			setTimeout(() => element.addEventListener("fullscreenchange", exitFullscreen), 1000);
		}

		setIsFullscreen(!isFullscreen);
	}, [isFullscreen, exitFullscreen]);

	useEffect(() => {
		return () => {
			exitFullscreen();
		};
	}, [exitFullscreen]);

	return { elementRef, isFullscreen, toggleFullscreen };
}
