import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

// TODO: Implement preview version of file field once UX team has created design
export function PreviewFileField() {
	return (
		<div className="text-c_text_disabled bg-c_bg_03 text-label-sm flex h-[200px] w-full select-none flex-col items-center justify-center gap-3 rounded-sm">
			<div className="flex flex-col items-center gap-2">
				<Icon icon={ICONS.upload} className="text-c_icon_disabled flex" />
				<span className="">Drag & drop a file here</span>
			</div>

			<div className="text-c_text_placeholder mt-1 flex w-full items-center justify-center gap-2">
				<div className="bg-c_border_regular h-px w-full max-w-[80px]" />
				or
				<div className="bg-c_border_regular h-px w-full max-w-[80px]" />
			</div>
			<div className="bg-c_bg_01 border-c_border_btn flex w-fit rounded-full border px-3 py-2">Browse files</div>
		</div>
	);
}
