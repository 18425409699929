import { useCallback, useEffect, useRef, useState } from "react";
import { LocalDataTrack, RemoteTrack, Room } from "twilio-video";
import { useToast } from "../../../../../../Toasts";

export const CONTROL_TRACK_MAX_PACKET_LIFETIME = 5000;

const CONTROL_TRACK_NAME = "control";

export interface ControlMessage {
	type: string;
}

export type SendControlMessage = <TMessage extends ControlMessage>(message: TMessage) => void;
type UnsubscribeFromControlMessages = () => void;
type ControlMessageHandler = (trackSid: string, controlMessage: ControlMessage) => void;
export type SubscribeToControlMessages = (onControlMessage: ControlMessageHandler) => UnsubscribeFromControlMessages;

export function useControlTrack(room: Room | null) {
	const toast = useToast();
	const [sendControlMessage, setSendControlMessage] = useState<SendControlMessage | null>(null);
	const subscribersRef = useRef<ControlMessageHandler[]>([]);
	const subscribeToControlMessages = useCallback<SubscribeToControlMessages>(
		(onControlMessage: ControlMessageHandler) => {
			subscribersRef.current.push(onControlMessage);

			return () => {
				subscribersRef.current.splice(subscribersRef.current.indexOf(onControlMessage), 1);
			};
		},
		[]
	);

	// Publish Local Data Track
	useEffect(() => {
		if (!room) return;
		const controlTrack = new LocalDataTrack({
			name: CONTROL_TRACK_NAME,
			maxPacketLifeTime: CONTROL_TRACK_MAX_PACKET_LIFETIME,
		});

		room.localParticipant.on("trackPublished", (publication) => {
			if (publication.track === controlTrack) {
				setSendControlMessage(() => (message: object) => controlTrack.send(JSON.stringify(message)));
			}
		});

		room.localParticipant.on("trackPublicationFailed", (error, track) => {
			if (track === controlTrack) {
				toast.trigger("error", "Failed to establish connection.");
				console.error("Failed to publish Local Control track", error);
			}
		});

		room.localParticipant.publishTrack(controlTrack);

		return () => {
			room.localParticipant.unpublishTrack(controlTrack);
			setSendControlMessage(null);
		};
	}, [room, toast]);

	// Subscribe to RemoteDataTracks
	useEffect(() => {
		if (!room) return;

		const onTrackSubscribed = (track: RemoteTrack) => {
			if (track.kind === "data" && track.name.includes(CONTROL_TRACK_NAME)) {
				// Not track.off these because the track will have been unsubscribed anyway
				track.on("message", (data) => {
					// Must be JSON string
					if (typeof data === "string") {
						try {
							const parsedData = JSON.parse(data);
							if (typeof parsedData === "object" && parsedData !== null && typeof parsedData.type === "string") {
								subscribersRef.current.forEach((subscriber) => {
									try {
										subscriber(track.sid, parsedData as ControlMessage);
									} catch (error) {
										console.error("Control track message subscriber failed handling Control message", error);
									}
								});
							} else {
								console.error(`Control track message was not a valid ControlMessage: ${data}`);
							}
						} catch (error) {
							console.error(`Control track message was not valid JSON: ${data}`, error);
						}
					} else {
						console.error(`Control track message was not a string`);
					}
				});
			}
		};

		room.on("trackSubscribed", onTrackSubscribed);

		return () => {
			room.off("trackSubscribed", onTrackSubscribed);
		};
	}, [room]);

	return { sendControlMessage, subscribeToControlMessages };
}
