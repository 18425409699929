import { useMemo } from "react";

import { getSDObjectSystemFieldByName, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { Logo, LogoSize, LogoSkeleton } from "../../../components/Logo";
import { LogoFieldValue, useLogoFieldValue } from "../../fields";
import { formatFERecordFieldValue } from "../../records";
import { useWorkspaceContext } from "../hooks/useWorkspaceContext";

interface WorkspaceCompanyLogoProps {
	size?: LogoSize;
	displayCompanyName?: boolean;
}

export function WorkspaceCompanyLogo({ size, displayCompanyName }: WorkspaceCompanyLogoProps) {
	const { workspaceObject, workspaceRecord } = useWorkspaceContext();

	const workspaceCompanyLogoFieldValue = useMemo(() => {
		if (!workspaceObject || !workspaceRecord) {
			return null;
		}

		const logoField = getSDObjectSystemFieldByName(workspaceObject, mWorkspaceDef.COMPANY_LOGO_FIELD_NAME);

		if (!logoField) {
			return null;
		}

		const logoFieldValue = getSDRecordFieldValue(workspaceRecord, logoField._id)?._value;
		return formatFERecordFieldValue(workspaceObject, workspaceRecord, logoField, logoFieldValue) as
			| LogoFieldValue
			| undefined;
	}, [workspaceObject, workspaceRecord]);

	const { companyName, fileId } = useLogoFieldValue(workspaceCompanyLogoFieldValue);

	if (!workspaceObject || !workspaceRecord) {
		return <LogoSkeleton size={size} />;
	}

	return <Logo size={size} companyName={companyName} image={fileId} displayCompanyName={displayCompanyName} />;
}
