import { APP_CONFIG } from "../../../../../../../../app/app_config";
import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";
import { AssociationGroup } from "../../../common";
import { PanelTabPanel } from "../../../../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";

interface LinkedRecordsPanelProps {
	objectAssociations: DirectedSDObjectAssociation[];
	onUpdateCount: (associationObjectId: number, count: number) => void;
}

export function LinkedRecordsPanel({ objectAssociations, onUpdateCount }: LinkedRecordsPanelProps) {
	const { sdRecord } = useRecordDetailsContext();

	return (
		<PanelTabPanel className="flex max-h-full w-full flex-col gap-6 overflow-auto pb-6 pr-8 pt-4" unmount={false}>
			{objectAssociations.map((objectAssociation) => (
				<AssociationGroup
					key={`${sdRecord?._id}${objectAssociation.id}`}
					sourceRecord={sdRecord}
					objectAssociation={objectAssociation}
					onUpdateCount={onUpdateCount}
					isCollapsible={true}
					associationOpeningVariant="currentPageSidePanel"
					limit={APP_CONFIG.maxLocalSearchResults}
				/>
			))}
		</PanelTabPanel>
	);
}
