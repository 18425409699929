import { DPCalendar, useContextCalendars } from "@rehookify/datepicker";
import { DateCell } from "./DateCell";
import { useMemo } from "react";
import { format } from "date-fns";
import clsx from "clsx";

interface CalendarProps {
	calendar: DPCalendar;
	markedDates?: Date[];
}

const ISO_DATE_FORMAT = "yyyy-MM-dd";

export const Calendar = ({ calendar, markedDates = [] }: CalendarProps) => {
	const { weekDays } = useContextCalendars();
	const { days } = calendar;

	const markedIsoDates = useMemo(() => {
		return markedDates?.reduce((acc, date) => {
			acc.add(format(date, ISO_DATE_FORMAT));
			return acc;
		}, new Set<string>());
	}, [markedDates]);

	return (
		<div className={clsx("grid grid-cols-7", markedDates === undefined ? "gap-y-2" : "gap-y-4")}>
			{weekDays.map((d) => (
				<div key={d} className="text-label-xs text-c_text_secondary mb-2 text-center">
					{d}
				</div>
			))}
			{days.map((d) => {
				const isoDate = format(d.$date, ISO_DATE_FORMAT);
				return <DateCell key={isoDate} value={d} isMarked={markedIsoDates?.has(isoDate)} />;
			})}
		</div>
	);
};
