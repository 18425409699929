import {
	Channel,
	ChannelMembershipForAppInstanceUserSummary,
	ChannelMessage,
	ChannelSummary,
} from "@aws-sdk/client-chime-sdk-messaging";

import { ChannelMetadata, ChannelNames } from "@salesdesk/salesdesk-schemas";
import { SECOND_IN_MS } from "@salesdesk/salesdesk-utils";

import { ChannelDetails, SerializableChannelDetails, SerializableChannelMessage } from "../types";

export const ACTIVE_HUDDLE_POLLING = 2 * SECOND_IN_MS;

export function getChannelMetadata(channel: Channel | ChannelSummary | undefined) {
	const metadata = channel?.Metadata;

	if (!metadata) {
		return null;
	}

	return JSON.parse(metadata) as ChannelMetadata;
}

export function convertChannelMembershipForUserSummaryToChannelDetails(
	channelMembershipForUserSummary: ChannelMembershipForAppInstanceUserSummary
): ChannelDetails | null {
	const { ChannelSummary, AppInstanceUserMembershipSummary } = channelMembershipForUserSummary;

	if (!ChannelSummary || !AppInstanceUserMembershipSummary || !ChannelSummary.ChannelArn) {
		return null;
	}

	const metadata = getChannelMetadata(ChannelSummary);

	if (!metadata) {
		return null;
	}

	const displayName = ChannelSummary.Name !== ChannelNames.NotSet ? ChannelSummary.Name : undefined;

	return {
		arn: ChannelSummary.ChannelArn,
		displayName: displayName,
		channelSummary: ChannelSummary,
		appInstanceUserMembershipSummary: AppInstanceUserMembershipSummary,
		channelMetadata: metadata,
		isReadOnly: AppInstanceUserMembershipSummary?.Type !== "DEFAULT",
	};
}

export function serializeChannelDetails(channelDetails: ChannelDetails): SerializableChannelDetails {
	return {
		...channelDetails,
		channelSummary: {
			...channelDetails.channelSummary,
			LastMessageTimestamp: channelDetails.channelSummary.LastMessageTimestamp?.getTime(),
		},
		appInstanceUserMembershipSummary: {
			...channelDetails.appInstanceUserMembershipSummary,
			ReadMarkerTimestamp: channelDetails.appInstanceUserMembershipSummary.ReadMarkerTimestamp?.getTime(),
		},
	};
}

export function unserializeChannelDetails(serializableChannelDetails: SerializableChannelDetails): ChannelDetails {
	return {
		...serializableChannelDetails,
		channelSummary: {
			...serializableChannelDetails.channelSummary,
			LastMessageTimestamp: convertTimeToDate(serializableChannelDetails.channelSummary.LastMessageTimestamp),
		},
		appInstanceUserMembershipSummary: {
			...serializableChannelDetails.appInstanceUserMembershipSummary,
			ReadMarkerTimestamp: convertTimeToDate(
				serializableChannelDetails.appInstanceUserMembershipSummary.ReadMarkerTimestamp
			),
		},
	};
}

export function serializeChannelMessage(channelMessage: ChannelMessage): SerializableChannelMessage {
	const { CreatedTimestamp, LastEditedTimestamp, LastUpdatedTimestamp, ...rest } = channelMessage;

	return {
		...rest,
		CreatedTimestamp: CreatedTimestamp?.getTime(),
		LastEditedTimestamp: LastEditedTimestamp?.getTime(),
		LastUpdatedTimestamp: LastUpdatedTimestamp?.getTime(),
	};
}

export function unserializeChannelMessage(serializableChannelMessage: SerializableChannelMessage): ChannelMessage {
	const { CreatedTimestamp, LastEditedTimestamp, LastUpdatedTimestamp, ...rest } = serializableChannelMessage;

	return {
		...rest,
		CreatedTimestamp: convertTimeToDate(CreatedTimestamp),
		LastEditedTimestamp: convertTimeToDate(LastEditedTimestamp),
		LastUpdatedTimestamp: convertTimeToDate(LastUpdatedTimestamp),
	};
}

function convertTimeToDate(date: number | undefined) {
	return date !== undefined ? new Date(date) : undefined;
}

export function channelIsUnread(channelDetails: ChannelDetails) {
	const lastMessageTimestamp = channelDetails.channelSummary.LastMessageTimestamp;
	const readMarkerTimestamp = channelDetails.appInstanceUserMembershipSummary.ReadMarkerTimestamp;

	// If there are no messages in the channel, it is not unread
	if (!lastMessageTimestamp) {
		return false;
	}

	if (!readMarkerTimestamp) {
		return true;
	}

	return lastMessageTimestamp > readMarkerTimestamp;
}

export function convertJSONMessagePayloadToChannelMessage(parsedPayload: any): ChannelMessage {
	return {
		...parsedPayload,
		CreatedTimestamp: parsedPayload.CreatedTimestamp ? new Date(parsedPayload.CreatedTimestamp) : undefined,
		LastUpdatedTimestamp: parsedPayload.LastUpdatedTimestamp ? new Date(parsedPayload.LastUpdatedTimestamp) : undefined,
		LastEditedTimestamp: parsedPayload.LastEditedTimestamp ? new Date(parsedPayload.LastEditedTimestamp) : undefined,
	};
}

// Sorts channels by last message timestamp.
// If no message has been sent in the channel we use the created timestamp of the channel instead.
export function sortChannelsByLastMessageTimestamp(channels: SerializableChannelDetails[]) {
	return channels.sort((a, b) => {
		const aTimestamp = a.channelSummary?.LastMessageTimestamp ?? a.channelMetadata?.createdTimestamp ?? 0;
		const bTimestamp = b.channelSummary?.LastMessageTimestamp ?? b.channelMetadata?.createdTimestamp ?? 0;
		return bTimestamp - aTimestamp;
	});
}
