import clsx from "clsx";

import { ChannelMessageType } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { SpeechBubble } from "../../../../../../../components/SpeechBubble/SpeechBubble";
import { FabContainer } from "../../../../../../../components/FabContainer";
import { ChannelMessageGroupMessage } from "../../../../../types";
import { EmbeddedRecord } from "./EmbeddedRecord";

interface ChimeChannelMessageProps {
	isLocalParticipant: boolean;
	message: ChannelMessageGroupMessage;
	onDeleteMessage?: (messageId: string) => void;
}

export function ChimeChannelMessage({ isLocalParticipant, message, onDeleteMessage }: ChimeChannelMessageProps) {
	const recordAttachments =
		message.metadata?.messageType === ChannelMessageType.Standard
			? (message.metadata?.attachments?.recordIds ?? [])
			: [];

	const huddleRecordId =
		message.metadata?.messageType === ChannelMessageType.Huddle ? message.metadata.meetingRecordId : null;

	const { MessageId: messageId, Redacted: redacted } = message;
	const containsAttachments = Boolean(recordAttachments?.length) || Boolean(huddleRecordId);
	const supportsDelete = isLocalParticipant && onDeleteMessage && messageId != null && !redacted;

	return (
		<div className={clsx("flex items-center", isLocalParticipant ? "justify-end" : "justify-start pl-8")}>
			<div className={clsx("max-w-[85%]", containsAttachments && "w-full")}>
				<FabContainer
					className="group/message"
					positionClasses="-top-4 left-0"
					buttons={
						supportsDelete ? (
							<Button
								startIcon={ICONS.trash}
								size="xs"
								variant="danger_text"
								onClick={() => onDeleteMessage(messageId)}
							/>
						) : null
					}
				>
					<SpeechBubble
						variant={isLocalParticipant ? "dark" : "light"}
						containsAttachments={containsAttachments}
						jsonContent={message.jsonContent}
						supportHover={supportsDelete}
						isRedacted={redacted}
					>
						{recordAttachments.map((recordId) => (
							<EmbeddedRecord key={recordId} recordId={recordId} />
						))}
						{huddleRecordId ? <EmbeddedRecord key={huddleRecordId} recordId={huddleRecordId} /> : null}
					</SpeechBubble>
				</FabContainer>
			</div>
		</div>
	);
}
