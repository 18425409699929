import { createContext, useContext } from "react";
import { RemoteParticipant, Room } from "twilio-video";

import { CallParticipantDetails, PresentedFileSettings } from "../../../types";
import { JSONContent } from "@tiptap/core";
import { ChatMessage, SDFile, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { SendControlMessage, SubscribeToControlMessages } from "./useControlTrack";

export interface IVideoCallLogicContext {
	connectToMeeting: (meetingRecord: SDRecord) => void;
	disconnect: () => void;
	currentMeetingRecord: SDRecord | null;
	meetingObject?: SDObject;

	room: Room | null;
	remoteParticipants: RemoteParticipant[];
	isConnecting: boolean;

	panelOpen?: boolean;
	setPanelOpen: (open: boolean) => void;
	currentPanelTab: number;
	updatePanelTab: (newTab: number) => void;

	participantVolumeMap: Record<string, number | undefined>;
	updateParticipantVolume: (identity: string, volume?: number) => void;
	currentDominantSpeakerIdentity: string | null;
	dominantSpeakerQueue: string[];

	isSharingScreen: boolean;
	toggleScreenShare: () => void;

	shareFile: (file: SDFile) => void;
	stopSharingFile: (() => void) | null;
	updateFileSettings: ((settings: PresentedFileSettings) => void) | null;

	callParticipantDetails: CallParticipantDetails[];
	screenSharingParticipantDetails: CallParticipantDetails[];
	fileSharingParticipant: CallParticipantDetails | null;

	sortedChatMessages: ChatMessage[];
	postChatMessage: (message: JSONContent) => void;
	isChatLoading?: boolean;

	sendControlMessage: SendControlMessage | null;
	subscribeToControlMessages: SubscribeToControlMessages;

	connectToMeetingTimeStamp: number;
	setConnectToMeetingTimeStamp: (connectMeetingTimeStamp: number) => void;
}

export const VideoCallLogicContext = createContext<IVideoCallLogicContext | null>(null);

export const useVideoCallLogicContext = () => {
	const context = useContext(VideoCallLogicContext);

	if (context == null) {
		throw new Error("Video call components must be wrapped in <VideoCallLogicProvider />");
	}

	return context;
};
