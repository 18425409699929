import { USER_IDS } from "../user_constants";
import { mUserDef } from "../user_def";

export class mSalesDeskCustomerDef extends mUserDef {
	static CLASS_NAME = "mSalesDeskCustomerDef";
	static NAME = "Customer User";
	static PLURAL_NAME = "SalesDesk Customers";
	static ICON = "fa-user-crown";

	static ROOT_ID = 0;

	static ID = USER_IDS.SALESDESK_CUSTOMER;

	constructor(ownerId) {
		super(mSalesDeskCustomerDef.ID, ownerId);
	}
}
