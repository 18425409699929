import { TextInput, TextInputComponentProps } from "@salesdesk/daisy-ui";
import { EditFieldProps } from "../../../../types";
import { forwardRef, useState } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";

type EditPasswordFieldProps = TextInputComponentProps & Omit<EditFieldProps, "onChange" | "value">;

export const EditPasswordField = forwardRef<HTMLInputElement, EditPasswordFieldProps>(
	({ inputRef, value, autoComplete, ...textInputProps }, ref) => {
		const [showPassword, setShowPassword] = useState(false);

		return (
			<TextInput
				value={value == null ? "" : value}
				type={showPassword ? "text" : "password"}
				autoComplete={autoComplete ?? "new-password"}
				icon={showPassword ? ICONS.eyeSlash : ICONS.eye}
				onIconClick={() => setShowPassword(!showPassword)}
				{...textInputProps}
				ref={ref}
			/>
		);
	}
);
