import clsx from "clsx";

import { useVideoCallLogicContext } from "../../../VideoCallProvider";
import { TogglePresentButton } from "./TogglePresentButton";
import { ToggleAudioButton } from "../../ToggleAudioButton";
import { ToggleVideoButton } from "../../ToggleVideoButton";
import { RecordingControls } from "./RecordingControls";
import { CallSettings } from "./CallSettings";
import { Button } from "@salesdesk/daisy-ui";

interface CallControlsProps {
	leaveMeeting: () => void;
	variant?: "default" | "minimised";
}

export function CallControls({ leaveMeeting, variant = "default" }: CallControlsProps) {
	const { room } = useVideoCallLogicContext();

	const isMinimised = variant === "minimised";
	return (
		<div className={clsx("relative flex", !isMinimised && "px-10 py-6")}>
			{isMinimised ? null : <RecordingControls />}
			{/* Controls are absolute positioned to remain in center when in video call page */}
			<div className={clsx(isMinimised ? "gap-2" : "absolute left-1/2 z-10 -translate-x-1/2 gap-6", "flex")}>
				<ToggleAudioButton room={room} />
				<ToggleVideoButton room={room} />
				<TogglePresentButton />
				<CallSettings />
			</div>
			<div className="ml-auto">
				<Button variant="danger" size="sm" onClick={leaveMeeting}>
					{isMinimised ? "End" : "Leave meeting"}
				</Button>
			</div>
		</div>
	);
}
