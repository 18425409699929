import { useState, useEffect, Dispatch, SetStateAction, useCallback } from "react";
import { RowSelectionState } from "@tanstack/react-table";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { RowSelectionDetails, TableRow } from "../../Table";

interface UseRecordTableSelection<T> {
	selectedRecords: SDRecord[];
	onSelectedRecordsChange: (selectedRecords: SDRecord[]) => void;
	rows: T[];
	sdRecordKeyInRow: string;
	singleSelect?: boolean;
	inSelectionMode?: boolean;
}

/**
 * Hook which transforms a given SDRecord array into a `RowSelectionState` for tanstack table
 * and will call `onSelectedRecordsChange` when the table's selection state changes (e.g. a user
 * selects a record on the table).
 *
 * Requires a table row where the column with the given `sdRecordKeyInRow` key contains the
 * corresponding SDRecord for the row.
 */
export function useRecordTableSelection<T extends TableRow<unknown>>({
	selectedRecords,
	onSelectedRecordsChange,
	rows,
	sdRecordKeyInRow,
	inSelectionMode = true,
	singleSelect = false,
}: UseRecordTableSelection<T>) {
	const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>({});

	// Convert the currently selected records into a RowSelectionState
	useEffect(() => {
		const selectionState: RowSelectionState = {};

		selectedRecords.forEach((record) => {
			selectionState[String(record._id)] = true;
		});

		setRowSelectionState(selectionState);
	}, [selectedRecords, rows, sdRecordKeyInRow]);

	const onRowSelectionChange: Dispatch<SetStateAction<RowSelectionState>> = (updaterOrValue) => {
		const newRowSelectionState =
			typeof updaterOrValue === "function" ? updaterOrValue(rowSelectionState) : updaterOrValue;

		const recordsInLoadedTable: Record<number, true> = {};
		const selectedRecordsInLoadedTable: SDRecord[] = [];

		rows.forEach((row) => {
			const sdRecord = getSDRecordFromRow(row, sdRecordKeyInRow);

			if (!sdRecord) {
				return;
			}

			const recordId = sdRecord._id;
			recordsInLoadedTable[recordId] = true;

			if (newRowSelectionState[String(recordId)]) {
				selectedRecordsInLoadedTable.push(sdRecord);
			}
		});

		// For single select we only want to have the most recently selected record in the table so we don't have
		// to keep track of the unloaded selected record.
		if (singleSelect) {
			onSelectedRecordsChange(
				selectedRecordsInLoadedTable
					.filter((record) => !selectedRecords.some((selectedRecord) => selectedRecord._id === record._id))
					.slice(0, 1)
			);

			return;
		}

		// This logic ensures that only the records currently loaded in the table & not selected are unselected.
		// Prevents the deselection of previously selected records that are no longer visible due to table filtering/searching.
		const unloadedSelectedRecords = selectedRecords.filter((record) => !recordsInLoadedTable[record._id]);
		onSelectedRecordsChange([...unloadedSelectedRecords, ...selectedRecordsInLoadedTable]);
	};

	const getRowId = useCallback(
		(row: T, index: number): string => {
			const sdRecord = getSDRecordFromRow(row, sdRecordKeyInRow);
			return sdRecord ? String(sdRecord._id) : `row-${index}`;
		},
		[sdRecordKeyInRow]
	);

	return {
		rowSelectionDetails: {
			value: rowSelectionState,
			onChange: onRowSelectionChange,
			inSelectionMode,
			singleSelect,
		} satisfies RowSelectionDetails,
		getRowId,
	};
}

function getSDRecordFromRow<T extends TableRow<unknown>>(row: T, sdRecordKeyInRow: string) {
	const sdRecord = row[sdRecordKeyInRow] as SDRecord;
	return sdRecord || null;
}
