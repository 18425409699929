import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { AlertDialog } from "../../../../Dialog";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useDeleteObjectMutation } from "../../../../objects/api/objectsApi";
import { useToast } from "../../../../Toasts";

interface DeleteObjectConfirmDialogProps {
	objectToDelete: SDObject | undefined;
	onClose: () => void;
}

export function DeleteObjectConfirmDialog({ objectToDelete, onClose }: DeleteObjectConfirmDialogProps) {
	const [deleteObject, { isLoading: isDeleteLoading }] = useDeleteObjectMutation();
	const toast = useToast();

	const onConfirmClick = () => {
		if (!objectToDelete) {
			return;
		}

		deleteObject(objectToDelete._id)
			.unwrap()
			.then(() => {
				toast.triggerMessage({
					type: "success",
					messageKey: "object_deleted",
					messageParams: {
						name: {
							type: "node",
							node: objectToDelete._displayName,
						},
					},
				});
				onClose();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "object_deleted" });
			});
	};

	return (
		<AlertDialog
			open={Boolean(objectToDelete)}
			title={`Delete '${objectToDelete?._displayName}'`}
			message="Are you sure you want to delete this object? All the records connected to it will be moved to bin."
		>
			<div className="flex justify-end gap-3">
				<Button startIcon={ICONS.trash} variant="secondary" onClick={onConfirmClick} isLoading={isDeleteLoading}>
					Delete
				</Button>
				<Button onClick={() => onClose()}>Cancel</Button>
			</div>
		</AlertDialog>
	);
}
