import { usePostEventMutation } from "../../events/api/eventsApi";
import { useEffect } from "react";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { useLocalStorageState } from "../../../hooks";

const DEBOUNCE_MS = 10000; //HOUR_IN_MS; (i.e. and not 0 because it will cause an endless loop)
const LOCALSTORAGE_KEY = "SalesDesk-sendWorkspaceEnteredEventWorkspaceIdExpiry";
type WorkspaceIdExpiryMap = { [workspaceId: number]: number };

export function useSendWorkspaceEnteredEvent(workspaceId: number | undefined) {
	const [postEvent] = usePostEventMutation();
	const [workspaceIdExpiryMap, setWorkspaceIdExpiryMap] = useLocalStorageState<WorkspaceIdExpiryMap>(
		LOCALSTORAGE_KEY,
		{}
	);

	useEffect(() => {
		if (workspaceId) {
			const expiry = workspaceIdExpiryMap[workspaceId];

			if (expiry == null || expiry < Date.now() - DEBOUNCE_MS) {
				postEvent({
					event_type: SdEventType.WORKSPACE_ENTERED,
					record_id: workspaceId,
					workspaceId: workspaceId,
					params: {},
				});
				setWorkspaceIdExpiryMap({ ...workspaceIdExpiryMap, [workspaceId]: Date.now() });
			}
		}
	}, [workspaceId, postEvent, workspaceIdExpiryMap, setWorkspaceIdExpiryMap]);
}
