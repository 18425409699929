import { JSONContent } from "@tiptap/core";

import { DisplayRichTextCompact } from "./DisplayRichTextCompact";
import { DisplayRichTextDefault } from "./DisplayRichTextDefault";
import { MentionOptions } from "../../../../../../mentions/types";

const DEFAULT_DISPLAY_RICH_TEXT_VALUE = {
	type: "doc",
	content: [],
};

type DisplayRichTextVariant = "default" | "compact";

interface DisplayRichTextProps {
	value?: JSONContent;
	variant?: DisplayRichTextVariant;
	placeholder?: string;
	collapsible?: boolean;
	enableMentions?: boolean | MentionOptions;
	isDarkVariant?: boolean;
}

export function DisplayRichTextField({
	value = DEFAULT_DISPLAY_RICH_TEXT_VALUE,
	variant = "default",
	placeholder,
	collapsible,
	enableMentions,
	isDarkVariant,
}: DisplayRichTextProps) {
	switch (variant) {
		case "default":
			return (
				<DisplayRichTextDefault
					value={value}
					placeholder={placeholder}
					collapsible={collapsible}
					enableMentions={enableMentions}
					isDarkVariant={isDarkVariant}
				/>
			);
		case "compact":
			return <DisplayRichTextCompact value={value} placeholder={placeholder} />;
		default:
			throw new Error("Invalid DisplayRichText variant");
	}
}
