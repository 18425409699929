import { asConst } from "json-schema-to-ts";
import { idSchema, updatableSchema, userIdSchema } from "../components";
import { objectIdSchema } from "../object";

export const bookmarkSchema = asConst({
	title: "Bookmark",
	description: "A bookmarked search",
	type: "object",
	required: ["id", "name", "objectId", "value", ...updatableSchema.required],
	additionalProperties: false,
	properties: {
		id: {
			...idSchema,
			title: "Bookmark ID",
			description: "ID of bookmark",
		},
		name: {
			title: "Bookmark Name",
			description: "Name of bookmark",
			type: "string",
		},
		value: {
			title: "Bookmark value",
			description: "value of bookmark",
			type: "string",
		},
		objectId: {
			...objectIdSchema,
			title: "Bookmark object ID",
			description: "Object ID of bookmark",
		},
		ownedBy: userIdSchema,
		...updatableSchema.properties,
	},
});
