import { useEffect } from "react";

import { SdEventType } from "@salesdesk/salesdesk-model";

import { usePostEvent } from "../../events/hooks/usePostEvent";

export function useRecordLoadedEvent(recordId: number | undefined) {
	const postEvent = usePostEvent();

	useEffect(() => {
		if (recordId) {
			postEvent({
				event_type: SdEventType.RECORD_LOADED,
				record_id: recordId,
			});
		}
	}, [postEvent, recordId]);
}
