import { mDbBackingStore } from "./db_backing_store";

export class mIconBackingStore extends mDbBackingStore {
	
	static TYPE = "icon";

    static MAX_RESULTS = 10;
	
	constructor() {
		super();	
		this._type = mIconBackingStore.TYPE;
	}
	
	get type(){		
		return this._type;
	}

    wrap(response){
        let responseObj = JSON.parse(response);
        let responseData = responseObj.data.search;

        let results = [];

        for(let i = 0; responseData.length > i; i++){

            let iconData = responseData[i];

            results.push({
                id: iconData.id,
                name: iconData.label,
                code: iconData.id,
                icon: `fa-${iconData.id}`
            });
        }

        return results;

    }
	
	getOptionValuesByQuery(filter, field, callback){	

        const data = `{search(version: "6.0.0", query: "${filter}", first: 5) { id, label }}`
            
        const xhr = new XMLHttpRequest();

        let self = this;
        
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === this.DONE) {
                callback(null, self.wrap(this.responseText))
            }
        });
        
        xhr.open("POST", "https://api.fontawesome.com/");        
        xhr.send(data);
	}
	
	getOptionValueById(id, callback){		

        // JF - Could probably load the icon details directly, but cannot currently work
        // out how to do that.

        this.getOptionValuesByQuery(id, null, function(error, data){
            callback(null, data[0]);
        });

	}
	
	getOptionValuesById(){			
        throw new Error(`getOptionValuesById() not implemented`);
	}	

}

