import {
	mAssetDef,
	mDateTimeFieldDef,
	mObjectFieldDef,
	mStringFieldDef,
	mUserDef,
	BOARD_VIEW,
	SORTABLE_FIELD_TYPES,
	FIELD_TYPES,
} from "@salesdesk/salesdesk-model";
import {
	Field,
	RecordQueryClauses,
	RecordsSearchRequest,
	SDObject,
	SDRecord,
	rsr,
	getSDObjectFields,
	getSDObjectNameFieldIds,
	getSDRecordFieldValues,
	getSDObjectSystemFieldByName,
	FieldInfo,
	isAssetObject,
} from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import {
	AggregationDetails,
	ASSET_RECORD_COMBINED_NAME_FILE_ID,
	ASSET_RECORD_COMBINED_NAME_FILE_NAME,
	BoardFieldMap,
	BoardRecordDetails,
	BoardState,
	FULL_RECORD_DATA_FIELD_ID,
	MAIN_BOARD_AGGREGATION,
	RECORD_COMBINED_NAME_ID,
} from "../types";
import { getSDObjectFieldMap, getSDObjectMediaFieldIds } from "../../objects/utils/objects";
import { AssetCombinedNameValue } from "../../fields";
import {
	FilterData,
	formatFERecordFieldValue,
	getCurrentPathWithRecordIdParam,
	mapToSearchQuery,
	SortingDetails,
	SortingOrder,
} from "../../records";
import { ROW_LINK_COLUMN_ID } from "../../Table";

export const GROUPED_VIEWS = [BOARD_VIEW.KANBAN, BOARD_VIEW.LIST];
export const CARD_BASED_VIEWS = [BOARD_VIEW.CARD, BOARD_VIEW.KANBAN];
export const TABLE_VIEWS = [BOARD_VIEW.TABLE, BOARD_VIEW.LIST];

export const OWNER_ID_METADATA_FIELD_ID = "_ownerId";

export function generateMetadataFields() {
	const owner = new mObjectFieldDef(OWNER_ID_METADATA_FIELD_ID);
	owner.displayName = "Owned by";
	owner.pluralName = "Owned by";
	owner.icon = ICONS.user;
	owner.objectDefId = mUserDef.ID;

	const createdBy = new mObjectFieldDef("createdBy");
	createdBy.displayName = "Created by";
	createdBy.objectDefId = mUserDef.ID;
	createdBy.pluralName = "Created by";
	createdBy.icon = ICONS.user;

	const lastModifiedUser = new mObjectFieldDef("updatedBy");
	lastModifiedUser.displayName = "Last updated by";
	lastModifiedUser.objectDefId = mUserDef.ID;
	lastModifiedUser.pluralName = "Last updated by";
	lastModifiedUser.icon = ICONS.user;

	const dateCreated = new mDateTimeFieldDef("createdAt");
	dateCreated.displayName = "Created";
	dateCreated.pluralName = "Created";
	dateCreated.icon = ICONS.calendar;

	const lastModified = new mDateTimeFieldDef("updatedAt");
	lastModified.displayName = "Last updated";
	lastModified.pluralName = "Last updated";
	lastModified.icon = ICONS.calendar;

	return {
		createdBy: createdBy.unmarshall() as Field,
		owner: owner.unmarshall() as Field,
		lastModifiedUser: lastModifiedUser.unmarshall() as Field,
		lastModified: lastModified.unmarshall() as Field,
		dateCreated: dateCreated.unmarshall() as Field,
	};
}

export const METADATA_FIELDS = generateMetadataFields();
export const METADATA_FIELDS_LIST = Object.values(METADATA_FIELDS);

// Uses a map to guarantee field order when iterating through it (e.g. for table columns)
export function generateObjectBoardFieldMap(sdObject: SDObject) {
	const fieldMap: BoardFieldMap = new Map();

	// Create a combined name field if the object has multiple name fields
	const nameFieldIds = getObjectNameFieldBoardIds(sdObject);
	if (nameFieldIds.length > 1) {
		const combinedNameField = new mStringFieldDef(RECORD_COMBINED_NAME_ID);
		combinedNameField.displayName = "Name";
		combinedNameField.pluralName = "Names";

		fieldMap.set(RECORD_COMBINED_NAME_ID, combinedNameField.unmarshall() as Field);
	}

	// Setting the combined name file field to use the same Field as the asset media field
	if (isAssetObject(sdObject)) {
		const assetMainFileField = getSDObjectSystemFieldByName(sdObject, mAssetDef.FILE_FIELD_NAME) as Field;
		fieldMap.set(ASSET_RECORD_COMBINED_NAME_FILE_ID, {
			...assetMainFileField,
			_displayName: ASSET_RECORD_COMBINED_NAME_FILE_NAME,
		});
	}

	getSDObjectFields(sdObject).forEach((field) => {
		fieldMap.set(String(field._id), field);
	});

	METADATA_FIELDS_LIST.forEach((field) => {
		fieldMap.set(field._name, field);
	});

	return fieldMap;
}

export function getSortableMetadataFields() {
	return METADATA_FIELDS_LIST.filter((field) => SORTABLE_FIELD_TYPES.some((type) => type === field._type));
}

export function generateBoardRecord(sdRecord: SDRecord, sdObject: SDObject, includeTableLink = true) {
	const recordDetails: BoardRecordDetails = {};
	recordDetails[FULL_RECORD_DATA_FIELD_ID] = sdRecord;
	const sdObjectFieldMap = getSDObjectFieldMap(sdObject);

	getSDRecordFieldValues(sdRecord).forEach((field) => {
		if (!field._fieldId || !sdObjectFieldMap[field._fieldId]) {
			return;
		}

		recordDetails[String(field._fieldId)] = {
			...field,
			_value: formatFERecordFieldValue(sdObject, sdRecord, sdObjectFieldMap[field._fieldId], field._value),
		};
	});

	METADATA_FIELDS_LIST.forEach((metadataField) => {
		const id = metadataField._name as keyof SDRecord;
		recordDetails[id] = {
			_id: -1,
			_fieldId: -1,
			_value: sdRecord[id],
		};
	});

	// Set the value for the combined name field (just the object name field or a combined string if the
	// opject has multiple name fields)
	const nameFieldIds = getObjectNameFieldBoardIds(sdObject);
	if (nameFieldIds.length) {
		let combinedName = "";
		nameFieldIds.forEach((nameFieldId) => {
			const fieldValue = recordDetails[nameFieldId]?._value;

			if (fieldValue) {
				combinedName += (combinedName ? " " : "") + fieldValue;
			}
		});

		recordDetails[RECORD_COMBINED_NAME_ID] = {
			_id: -1,
			_fieldId: -1,
			_value: combinedName,
		};
	}

	if (isAssetObject(sdObject)) {
		const assetMainFileField = getSDObjectSystemFieldByName(sdObject, mAssetDef.FILE_FIELD_NAME);
		const mainFileFieldValue = assetMainFileField?._id ? recordDetails[assetMainFileField._id]?._value : undefined;
		const fileDisplayName = recordDetails[RECORD_COMBINED_NAME_ID]?._value || undefined;
		const mainFileId = typeof mainFileFieldValue === "number" ? mainFileFieldValue : undefined;

		recordDetails[ASSET_RECORD_COMBINED_NAME_FILE_ID] = {
			_id: -1,
			_fieldId: -1,
			_value: {
				fileId: mainFileId,
				displayName: typeof fileDisplayName === "string" ? fileDisplayName : undefined,
			} satisfies AssetCombinedNameValue,
		};
	}

	if (includeTableLink) {
		// Add a row link to the record details for the table based views (table and list)
		recordDetails[ROW_LINK_COLUMN_ID] = () => {
			return getCurrentPathWithRecordIdParam(sdRecord._id);
		};
	}

	return recordDetails;
}

export function getObjectNameFieldBoardIds(sdObject: SDObject) {
	return getSDObjectNameFieldIds(sdObject).map((id) => String(id));
}

export function getObjectMediaFieldBoardIds(sdObject: SDObject) {
	return getSDObjectMediaFieldIds(sdObject).map((id) => String(id));
}

export function getFieldInfoArrayForBoardRecordDetails(
	fieldIds: string[],
	record: BoardRecordDetails,
	boardFieldMap: BoardFieldMap
) {
	return fieldIds
		.map((fieldId) => getFieldInfoForBoardRecordDetails(fieldId, record, boardFieldMap))
		.filter((value) => Boolean(value)) as FieldInfo[];
}

export function getFieldInfoForBoardRecordDetails(
	fieldId: string,
	record: BoardRecordDetails,
	boardFieldMap: BoardFieldMap
) {
	const field = boardFieldMap.get(fieldId);

	if (!field) {
		return undefined;
	}

	return {
		field,
		value: record[fieldId] ? record[fieldId]._value : null,
	};
}

interface GenerateBoardSearchParamsArgs {
	query?: string;
	filter?: FilterData;
	sort?: SortingDetails[];
	agg?: AggregationDetails;
	additionalFilters?: RecordQueryClauses[];
	workspaceId?: number;
}

export function generateBoardSearchParams({
	query,
	filter,
	sort,
	agg,
	additionalFilters,
	workspaceId,
}: GenerateBoardSearchParamsArgs): RecordsSearchRequest {
	const and: RecordQueryClauses[] = [rsr.equals("_deleted", false)];
	const not: RecordQueryClauses[] = [rsr.equals("isTemplate", true)];

	if (query != null && query.length > 0) {
		and.push(rsr.matchAllPrefix(query));
	}

	if (additionalFilters != null) {
		and.push(...additionalFilters);
	}

	if (workspaceId != null) {
		and.push(rsr.isSharedWithWorkspace(workspaceId));
	}

	const boardStateFilters: RecordQueryClauses[] = filter?.filters.flatMap(mapToSearchQuery) ?? [];

	let or: RecordQueryClauses[] | undefined = undefined;

	if (boardStateFilters.length) {
		const filterType = filter?.type;

		if (filterType === "AND") {
			and.push(...boardStateFilters);
		} else if (filterType === "OR") {
			or = boardStateFilters;
		}
	}

	const rsrRequest = rsr.create();

	sort?.forEach((sortValue) => {
		rsrRequest.sort({ [sortValue.fieldId]: { order: sortValue.order } });
	});

	if (!sort) {
		rsrRequest.sort({ updatedAt: { order: SortingOrder.desc } });
	}

	if (agg) {
		const { agg: aggregation, fieldId } = agg;
		rsrRequest.aggregate(MAIN_BOARD_AGGREGATION, { [aggregation]: fieldId });
	}

	// TODO: Introduce this one sorting by date fields is working for search API
	// if (boardState.view === BOARD_VIEW.CALENDAR && boardState.groupBy) {
	// 	sort = [{ [String(boardState.groupBy)]: { order: "desc" } }];
	// }

	return rsrRequest.query({ and, or, not }).buildRequest();
}

export function getProfilePhotoFields(sdObject: SDObject) {
	return getSDObjectFields(sdObject).filter((field) => field._type === FIELD_TYPES.PROFILE_PHOTO.name);
}
