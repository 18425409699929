import { createContext, useContext } from "react";

export interface INotificationsCounterContext {
	count: number;
	setPause: (pause: boolean) => void;
	reset: () => void;
}

export const NotificationsCounterContext = createContext<INotificationsCounterContext | null>(null);

export const useNotificationsCounterContext = () => {
	const context = useContext(NotificationsCounterContext);

	if (context == null) {
		throw new Error("Notification Counter components must be wrapped in <NotificationsCounterProvider />");
	}

	return context;
};
