import { PropsWithChildren, useMemo, useState } from "react";

import { THEMABLE_COLOR_VARIABLES, ThemableColorKey, ThemeName, UI_THEMES } from "@salesdesk/salesdesk-ui";

import { getRgbaFromCssColor } from "../../../utils";
import { ThemeProviderContext } from "../hooks/useThemeProviderContext";

export function ThemeProvider({ children }: PropsWithChildren) {
	const [currentTheme, setTheme] = useState<ThemeName>("grey");

	const styleContent = useMemo(() => {
		const theme = UI_THEMES[currentTheme];

		let styleString = ":root {";

		for (const [key, value] of Object.entries(theme)) {
			const rgb = getRgbaFromCssColor(value);

			if (!rgb) {
				continue;
			}

			const { r, g, b } = rgb;
			const variableName = THEMABLE_COLOR_VARIABLES[key as ThemableColorKey];

			styleString += `${variableName}: ${r}, ${g}, ${b};\n`;
		}

		styleString += "}";
		return styleString;
	}, [currentTheme]);

	return (
		<ThemeProviderContext.Provider value={{ currentTheme, setTheme }}>
			{children}
			<style>{styleContent}</style>
		</ThemeProviderContext.Provider>
	);
}
