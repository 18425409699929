import { HasClaimConfig } from "../components/sections";

export const recordAssociationClaimsSectionProps: HasClaimConfig[] = [
	// TODO: https://salesdesk101.atlassian.net/browse/SAL-2551
	// {
	// 	name: "objectRecordAssociationView",
	// 	label: "View",
	// 	descriptions: {
	// 		hasClaim: "Can view all record associations",
	// 		hasNotClaim: "Cannot view record associations",
	// 	},
	// },
	{
		name: "objectRecordAssociationCreate",
		label: "Add",
		// dependsOn: ["objectRecordAssociationView"],
		descriptions: {
			hasClaim: "Can add record associations",
			hasNotClaim: "Cannot add record associations",
		},
	},
	{
		name: "objectRecordAssociationDelete",
		label: "Delete",
		// dependsOn: ["objectRecordAssociationView"],
		descriptions: {
			hasClaim: "Can delete all record associations",
			hasNotClaim: "Cannot delete record associations",
		},
	},
];
