import { Middleware } from "@reduxjs/toolkit";
import { connectWebSocket, disconnectWebSocket, sendWebSocketMessage } from "./webSocketSlice";
import { defaultWebSocketMessageHandler, SDWebSocket } from "../../features/WebSocket";

export const webSocketMiddleware: Middleware = (store) => {
	const handleMessage = defaultWebSocketMessageHandler(store.dispatch);
	let webSocket: SDWebSocket | undefined;

	return (next) => (action) => {
		if (connectWebSocket.match(action)) {
			console.debug("Redux WebSocketMiddleware:  Connecting...");
			webSocket = action.payload;
			webSocket.subscribeToMessages(handleMessage);
		}
		if (sendWebSocketMessage.match(action) && webSocket) {
			console.debug("Redux WebSocketMiddleware:  Sending message");
			webSocket.send(action.payload);
		}
		if (disconnectWebSocket.match(action) && webSocket) {
			console.debug("Redux WebSocketMiddleware:  Disconnecting...");
			webSocket.unSubscribeFromMessages(handleMessage);
			webSocket = undefined;
		}

		next(action);
	};
};
