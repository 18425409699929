import { Field, SDApiKey } from "@salesdesk/salesdesk-schemas";
import { mDateFieldDef, mStringFieldDef } from "@salesdesk/salesdesk-model";

import { isFieldAndValue, RecordTableColumn, RecordTableRow, RowData } from "../types";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../fields";
import { HeaderCell } from "../../../Table";

export const SD_API_KEY_COLUMN_ID = "sdApiKey";
export const NAME_COLUMN_ID = "name";
export const KEY_ID_COLUMN_ID = "apiKeyId";
export const KEY_SECRET_COLUMN_ID = "apiKeySecret";
export const CREATED_DATE_COLUMN_ID = "createdAt";

export const DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID = {
	[NAME_COLUMN_ID]: 200,
	[KEY_ID_COLUMN_ID]: 400,
};

export function generateColumns(): RecordTableColumn[] {
	const renderRowCell = (cellContents: RowData) => {
		if (isFieldAndValue(cellContents)) {
			return (
				<DisplayFieldFactory
					field={cellContents.field}
					value={cellContents.value}
					variant={DisplayFieldVariant.table}
				/>
			);
		}
		return <Skeleton className="my-1 h-6 w-full" />;
	};

	return [
		{
			id: NAME_COLUMN_ID,
			displayName: () => <HeaderCell columnId={NAME_COLUMN_ID} columnName="Key name" />,
			renderRowCell,
		},
		{
			id: KEY_ID_COLUMN_ID,
			displayName: () => <HeaderCell columnId={KEY_ID_COLUMN_ID} columnName="Key ID" />,
			renderRowCell,
		},
		{
			id: CREATED_DATE_COLUMN_ID,
			displayName: () => <HeaderCell columnId={CREATED_DATE_COLUMN_ID} columnName="Created" />,
			renderRowCell,
		},
	];
}

export function generateRows(apiKeys: SDApiKey[]): RecordTableRow[] {
	const nameField = createNameCustomField();
	const keyIdField = createKeyIdCustomField();
	const createdAtField = createCreatedAtCustomField();
	return apiKeys.map((apiKey: SDApiKey) => {
		return {
			[SD_API_KEY_COLUMN_ID]: apiKey,
			[NAME_COLUMN_ID]: { field: nameField, value: apiKey.name },
			[KEY_ID_COLUMN_ID]: { field: keyIdField, value: apiKey.apiKeyId },
			[CREATED_DATE_COLUMN_ID]: { field: createdAtField, value: apiKey.createdAt },
		};
	});
}

export function createNameCustomField(): Field {
	const nameField = new mStringFieldDef(NAME_COLUMN_ID);
	nameField.pluralName = "Name";
	nameField.displayName = "Name";
	nameField.maxLength = 200;
	nameField.required = true;
	return nameField.unmarshall() as Field;
}

export function createKeyIdCustomField(): Field {
	const keyIdField = new mStringFieldDef(KEY_ID_COLUMN_ID);
	keyIdField.pluralName = "Key IDs";
	keyIdField.displayName = "Key ID";
	keyIdField.maxLength = 200;
	keyIdField.required = true;
	return keyIdField.unmarshall() as Field;
}

export function createCreatedAtCustomField(): Field {
	const createdAtField = new mDateFieldDef(CREATED_DATE_COLUMN_ID);
	createdAtField.pluralName = "Created At";
	createdAtField.displayName = "Created At";
	createdAtField.required = true;
	return createdAtField.unmarshall() as Field;
}
