import { createContext, useContext, Dispatch, SetStateAction } from "react";

import { ThemeName } from "@salesdesk/salesdesk-ui";

interface IThemeProviderContext {
	currentTheme: ThemeName;
	setTheme: Dispatch<SetStateAction<ThemeName>>;
}

export const ThemeProviderContext = createContext<IThemeProviderContext | undefined>(undefined);

export function useThemeProviderContext() {
	const context = useContext(ThemeProviderContext);

	if (!context) {
		throw new Error("useThemeProviderContext must be used within a ThemeProvider");
	}
	return context;
}
