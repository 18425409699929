import { mSystemObjectDef } from "../system_object_def";
import { USER_IDS } from "./user_constants";

export class mUserDef extends mSystemObjectDef {
	static CLASS_NAME = "mUserDef";
	static NAME = "User";
	static PLURAL_NAME = "Users";
	static ICON = "fa-user";

	static PHOTO_FIELD_NAME = "photo";
	static EMAIL_FIELD_NAME = "email";
	static FIRST_NAME_FIELD_NAME = "firstName";
	static SURNAME_FIELD_NAME = "surname";
	static TITLE_FIELD_NAME = "title";
	static COMPANY_FIELD_NAME = "company";
	static MOBILE_FIELD_NAME = "mobile";
	static LINKEDIN_FIELD_NAME = "linkedIn";
	static ROLES_FIELD_NAME = "roles";
	static LOGIN_AUTHORIZED_FIELD_NAME = "loginAuthorized";
	static WEBSITE_FIELD_NAME = "website";
	static DETAILS_FIELD_NAME = "details";
	static LAST_ACTIVE_FIELD_NAME = "lastActive";
	static LOGIN_ENABLED_TIME_FIELD_NAME = "loginEnabledTime";

	static ROOT_ID = 0;

	static ID = USER_IDS.USER;

	constructor(ownerId) {
		super(mUserDef.ID, ownerId);
	}
}
