import { useEffect, PropsWithChildren } from "react";
import { useFormContext, Message } from "react-hook-form";

import { useGetObjectById } from "../../../../hooks/useGetObjectById";
import { EditSingleOptionField, EditMultiOptionField } from "../../../fields/components/Fields/OptionField/components";
import { SelectOptionId } from "../../../inputs";
import { Accordion } from "../../../../components/Accordion";

type OpportunityAssociationOption = SelectOptionId[] | SelectOptionId;

interface OpportunityAssociationFieldProps {
	associationFieldId: number;
	formFieldsKey: string;
	disabled?: boolean;
	required?: boolean;
	isMultiple?: boolean;
}

export function OpportunityAssociationField({
	associationFieldId,
	formFieldsKey,
	disabled,
	required = false,
	isMultiple = false,
	children,
}: PropsWithChildren<OpportunityAssociationFieldProps>) {
	const {
		unregister,
		register,
		setValue,
		formState: { errors },
		watch,
	} = useFormContext();

	const fieldValue = watch(formFieldsKey);

	const { sdObject: associationObject } = useGetObjectById(associationFieldId);

	useEffect(() => {
		if (!associationObject) return;

		if (disabled) {
			unregister(formFieldsKey, { keepValue: false });
		} else {
			let options: Record<string, any> = {};
			if (required) {
				options = { required: "Value is required." };
			}
			register(formFieldsKey, options);
		}
	}, [disabled, formFieldsKey, unregister, register, associationObject, required]);

	const onChange = (newValue?: OpportunityAssociationOption) => {
		setValue(formFieldsKey, newValue, { shouldValidate: true });
	};

	if (!associationObject) return null;

	const errorMessage = errors[formFieldsKey]?.message as Message;
	const OptionSelect = isMultiple ? EditMultiOptionField : EditSingleOptionField;

	return (
		<Accordion title={`${associationObject._displayName}${required ? "*" : ""}`} unmount={false} defaultOpen>
			<div className="mt-4 flex flex-col gap-3">
				<OptionSelect
					disabled={disabled}
					value={fieldValue}
					onChange={onChange}
					hasError={Boolean(errorMessage)}
					optionFieldSettings={{
						variant: "records",
						baseObjectId: associationObject._id,
					}}
				/>
				{errorMessage ? <div className="text-c_danger_01 text-body-sm">{errorMessage}</div> : null}
			</div>
			{children}
		</Accordion>
	);
}
