import { ICONS } from "@salesdesk/salesdesk-ui";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";
import { Tooltip, Button } from "@salesdesk/daisy-ui";
import { Events } from "../../../../../events";

export function WorkspaceActivityButton() {
	return (
		<Popover placement="bottom-end" keepPopoverMounted={false}>
			<Tooltip text="Activity">
				<PopoverTrigger>
					<Button startIcon={ICONS.reports} variant={"text"} size="sm" />
				</PopoverTrigger>
			</Tooltip>
			<PopoverContent initialFocus={-1}>
				<PopoverContainer className="h-[440px] w-[540px]">
					<div className="flex h-full w-full flex-col gap-4 overflow-hidden px-[23px] py-2">
						<div className="text-label px-px">Activity</div>
						<div className="h-full overflow-hidden">
							<Events target="NOTIFICATION" />
						</div>
					</div>
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
