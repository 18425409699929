import { useEffect, useCallback, useState } from "react";
import { ControlMessage, useVideoCallLogicContext } from "../../../../../../../VideoCallProvider";

enum RecordControlMessageType {
	SYNC_TRIGGER = "Record.SyncTrigger",
}

export function useSyncParticipantMeetingTasks() {
	const [taskSearchKey, setTaskSearchKey] = useState(0);
	const { sendControlMessage, subscribeToControlMessages } = useVideoCallLogicContext();

	// Subscribe to control messages to listen for task sync messages
	useEffect(() => {
		return subscribeToControlMessages((_: string, message: ControlMessage) => {
			if (message.type === RecordControlMessageType.SYNC_TRIGGER) {
				setTaskSearchKey((prev) => prev + 1);
			}
		});
	}, [subscribeToControlMessages]);

	// Function to send a sync request to other participants
	const triggerParticipantTaskSync = useCallback(() => {
		sendControlMessage && sendControlMessage({ type: RecordControlMessageType.SYNC_TRIGGER });
	}, [sendControlMessage]);

	return { triggerParticipantTaskSync, taskSearchKey };
}
