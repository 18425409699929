import { useCallback } from "react";

import { ObjectFilteringPopover, FilterData } from "../../../../records";
import { useBoardPropOnChangeContext } from "../../../hooks/useBoardPropOnChangeContext";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import { useBoardFiltersSelector } from "../../../store";
import { BaseBoardControlProps } from "../types";

export function BoardFilteringPopover({ iconOnly }: BaseBoardControlProps) {
	const { sdObject } = useDataboardDetailsContext();
	const boardPropOnChange = useBoardPropOnChangeContext();

	const filters = useBoardFiltersSelector();

	const onChange = useCallback(
		(newFilters: FilterData) => {
			boardPropOnChange("filter", newFilters.filters.length ? newFilters : undefined);
		},
		[boardPropOnChange]
	);

	if (!sdObject) {
		return null;
	}

	return <ObjectFilteringPopover value={filters} onChange={onChange} sdObject={sdObject} iconOnly={iconOnly} />;
}
