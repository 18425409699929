import { useMemo, useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetRecordDetailsPath } from "../../../../hooks";
import { PATHS } from "../../../../../../routes";
import { MenuContents, MenuItem, PopoverMenu } from "../../../../../menu";
import { useCopyToClipboard } from "../../../../../../hooks/useCopyToClipboard";
import { useRecordDetailsContext } from "../../hooks/useRecordDetailsContext";
import { Button } from "@salesdesk/daisy-ui";
import { RecordFollowingButtons, useRecordActions, UserRecordDetailsButtons } from "../../../RecordActions";
import { useBulkChangeOwnerContext } from "../../../BulkChangeOwnerModal";
import { AbilityAction, AbilitySubject, getSDRecordName, sdSubject } from "@salesdesk/salesdesk-schemas";
import { useWebPrincipal } from "../../../../../../auth";
import { useGetContextWorkspaceId } from "../../../../../workspaces";

interface GeneralRecordActionsProps {
	inRightHandPanel?: boolean;
}

export function GeneralRecordActions({ inRightHandPanel }: GeneralRecordActionsProps) {
	const principal = useWebPrincipal();
	const { sdRecord, sdObject, ownerObject, ownerRecord } = useRecordDetailsContext();

	const recordActions = useRecordActions(sdRecord, sdObject, false);

	const copyToClipboard = useCopyToClipboard();

	const { setBulkChangeOwnerRecords } = useBulkChangeOwnerContext();
	const recordDetailPath = useGetRecordDetailsPath(sdRecord, sdObject);

	const menuContents = useMemo(() => {
		const contents: MenuContents = [];

		const primaryItems: MenuItem[] = [];

		if (inRightHandPanel && recordDetailPath) {
			primaryItems.unshift({
				icon: ICONS.listDashes,
				text: "Record detail",
				link: recordDetailPath,
				type: "link",
			});
		}

		if (sdRecord && sdObject && principal.can(AbilityAction.ChangeOwner, sdSubject(AbilitySubject.Record, sdRecord))) {
			primaryItems.unshift({
				icon: ICONS.user,
				text: "Change owner",
				subText: ownerRecord && ownerObject ? `Owner: ${getSDRecordName(ownerObject, ownerRecord)}` : undefined,
				onClick: () => {
					setBulkChangeOwnerRecords([sdRecord]);
				},
				type: "button",
			});
		}

		if (primaryItems.length > 0) contents.push(primaryItems);

		if (recordActions.length) {
			contents.push(recordActions);
		}

		if (sdRecord && principal.can(AbilityAction.Delete, sdSubject(AbilitySubject.Record, sdRecord))) {
			contents.push([{ icon: ICONS.trash, text: "Delete", variant: "destructive", type: "button" }]);
		}

		return contents;
	}, [
		inRightHandPanel,
		recordDetailPath,
		sdRecord,
		sdObject,
		principal,
		recordActions,
		ownerRecord,
		ownerObject,
		setBulkChangeOwnerRecords,
	]);

	const contextWorkspaceId = useGetContextWorkspaceId();

	return (
		<div className="flex items-center gap-2">
			<UserRecordDetailsButtons sdRecord={sdRecord} />
			<RecordFollowingButtons sdRecord={sdRecord} />
			{inRightHandPanel ? (
				<Button
					startIcon={ICONS.link}
					size="sm"
					variant="text"
					onClick={() => {
						if (!sdObject || !sdRecord) return;
						let recordUrl = window.location.origin;
						if (contextWorkspaceId !== undefined) {
							recordUrl += PATHS.WORKSPACE_RECORD_BOARD(contextWorkspaceId, sdObject, { recordId: sdRecord._id });
						} else {
							recordUrl += PATHS.OBJECT_RECORD_BOARD(sdObject, { recordId: sdRecord._id });
						}
						copyToClipboard(recordUrl);
					}}
				/>
			) : null}
			{menuContents.length > 0 ? (
				<PopoverMenu menuContents={menuContents} widthVariant="fit">
					<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
				</PopoverMenu>
			) : null}
		</div>
	);
}
