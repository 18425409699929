import { Button, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectById } from "../../../../../../../../hooks/useGetObjectById";
import { PATHS } from "../../../../../../../../routes";
import { useWebPrincipal } from "../../../../../../../../auth";
import { useUnfavoriteRecordMutation } from "../../../../../../../records/api/favoritesApi";
import { MenuContents, MenuItem, PopoverMenu } from "../../../../../../../menu";
import { useToast } from "../../../../../../../Toasts";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { useWorkspaceLibraryStateContext } from "../../../hooks/useWorkspaceLibraryStateContext";

interface StarredContextMenuProps {
	recordId: number;
	objectId: number;
}

export function FavoritedRecordsContextMenu({ recordId, objectId }: StarredContextMenuProps) {
	const { sdObject } = useGetObjectById(objectId);
	const { workspaceRecord } = useWorkspaceContext();
	const { urlState } = useWorkspaceLibraryStateContext();

	const principal = useWebPrincipal();

	const [unfavoriteRecord] = useUnfavoriteRecordMutation();
	const toast = useToast();

	if (!sdObject || !workspaceRecord) {
		return null;
	}

	const mainItems: MenuItem[] = [
		{
			icon: ICONS.eye,
			text: "Preview",
			link: PATHS.WORKSPACE_LIBRARY(workspaceRecord._id, { ...urlState, recordId }),
			type: "link",
		},
	];

	if (principal.IsSalesDeskUser) {
		mainItems.push({
			icon: ICONS.listDashes,
			text: "Record detail",
			link: PATHS.RECORD_DETAIL_VIEW(recordId, sdObject),
			type: "link",
		});
	}

	const menuContents: MenuContents = [mainItems];

	if (principal.can(AbilityAction.Delete, AbilitySubject.Favorite)) {
		menuContents.push([
			{
				icon: ICONS.star,
				variant: "destructive",
				text: "Removed starred",
				onClick: () => {
					unfavoriteRecord(recordId)
						.unwrap()
						.then(() => {
							toast.triggerMessage({ messageKey: "record_unfavorited", type: "success" });
						})
						.catch(() => {
							toast.triggerMessage({ messageKey: "record_unfavorited", type: "error" });
						});
				},
				type: "button",
			},
		]);
	}

	return (
		<div className="ml-auto flex gap-0.5">
			<Icon icon={ICONS.star} variant="fill" className="text-c_action_01 flex w-8 items-center justify-center" />
			<PopoverMenu menuContents={menuContents}>
				<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
			</PopoverMenu>
		</div>
	);
}
