import { useMemo } from "react";

import { getChimeChannelIdFromChannelArn, getUserIdsFromDirectMessageChannelId } from "@salesdesk/salesdesk-schemas";
import { ChannelType } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../auth";
import { ChannelDetails } from "../types";

/**
 * Returns the record id for the workspace associated with a workspace channel and
 * the record id of the other user in a direct message channel.
 */
export function useGetChannelRelatedRecordId(channelDetails?: ChannelDetails) {
	const principal = useWebPrincipal();

	const channelId = channelDetails?.channelSummary?.ChannelArn
		? getChimeChannelIdFromChannelArn(channelDetails.channelSummary.ChannelArn)
		: undefined;
	const channelType = channelDetails?.channelMetadata?.channelType;

	return useMemo(() => {
		if (channelType === ChannelType.DirectMessage && channelId) {
			const userIds = getUserIdsFromDirectMessageChannelId(channelId);
			return userIds?.find((id) => id !== principal.UserRecordId);
		} else if (channelType === ChannelType.Workspace) {
			return channelDetails?.channelMetadata?.workspaceId;
		}

		return undefined;
	}, [channelId, channelType, principal.UserRecordId, channelDetails]);
}
