import { JSONContent, generateText } from "@tiptap/core";

import { DisplayFieldVariant } from "../../../../types";
import { getFrontEndRichTextExtensions } from "../../../../../../utils/getFrontEndRichTextExtensions";

export function getRichTextFieldVariant(variant: DisplayFieldVariant) {
	switch (variant) {
		case DisplayFieldVariant.default:
		case DisplayFieldVariant.rightHandPanel:
			return "default";
		default:
			return "compact";
	}
}

export function convertRichTextJSONToText(value?: JSONContent) {
	if (!value) {
		return "";
	}

	try {
		return generateText(value, getFrontEndRichTextExtensions({ placeholder: "", enableMentions: true }));
	} catch (error) {
		console.error(error);
		return "";
	}
}
