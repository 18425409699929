import { RecordNotificationRuleDescription } from "./RecordNotificationRuleDescription";
import { UserNotificationRuleDescription } from "./UserNotificationRuleDescription";
import { NotificationRuleDescriptionProps } from "../../../types";

export function NotificationRuleDescription({ rule }: NotificationRuleDescriptionProps) {
	return rule.ruleType === "record" ? (
		<RecordNotificationRuleDescription rule={rule} />
	) : (
		<UserNotificationRuleDescription rule={rule} />
	);
}
