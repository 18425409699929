import { useContextCalendars, useContextDatePickerOffsetPropGetters } from "@rehookify/datepicker";
import { Calendar } from "./calendar/Calendar";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { CalendarHeader } from "./calendar/CalendarHeader";
import { DateTimeFieldVariant } from "../../../fields";
import { DateTimePickerProps } from "../../types";
import { Button } from "@salesdesk/daisy-ui";
import { Times } from "./time/Times";
import { TimeRange } from "@salesdesk/salesdesk-schemas";
import clsx from "clsx";
import { add, sub } from "date-fns";

export function DateTimePicker({
	value,
	onChange,
	variant,
	isTimeRange,
	markedDates,
	offsetDate,
	setOffsetDate,
}: DateTimePickerProps<TimeRange | number>) {
	const { calendars } = useContextCalendars();
	const { subtractOffset, addOffset, setOffset } = useContextDatePickerOffsetPropGetters();

	const isControlledOffset = !!offsetDate && !!setOffsetDate;

	return (
		<div className={clsx("flex w-max flex-col", markedDates === undefined ? "gap-y-4" : "gap-y-7")}>
			<CalendarHeader
				prevButton={
					<Button
						startIcon={ICONS.caretLeft}
						size="xs"
						variant="text"
						{...(isControlledOffset
							? {
									onClick: () => setOffsetDate(sub(offsetDate, { months: 1 })),
							  }
							: subtractOffset({ months: 1 }))}
					/>
				}
				nextButton={
					<Button
						startIcon={ICONS.caretRight}
						size="xs"
						variant="text"
						{...(isControlledOffset
							? {
									onClick: () => setOffsetDate(add(offsetDate, { months: 1 })),
							  }
							: addOffset({ months: 1 }))}
					/>
				}
				todayButton={
					<Button
						size="sm"
						variant="secondary"
						{...(isControlledOffset ? { onClick: () => setOffsetDate(new Date()) } : setOffset(new Date()))}
					>
						Today
					</Button>
				}
				calendar={calendars[0]}
			/>
			<div className="flex flex-col gap-6">
				<Calendar calendar={calendars[0]} markedDates={markedDates} />
				{variant === DateTimeFieldVariant.DATE_TIME ? (
					<Times
						isTimeRange={isTimeRange}
						value={value}
						onChange={(newValue) => {
							if (onChange && newValue) {
								onChange(newValue);
							}
						}}
					/>
				) : null}
			</div>
		</div>
	);
}
