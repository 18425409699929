import { ToastMessageProps, toastMessages } from "../types";
import { RenderMessageTemplate } from "./RenderMessageTemplate";

export function ToastMessage({ type, messageKey, messageParams }: ToastMessageProps) {
	const typeToastMessages = toastMessages[type ?? "info"];
	return (
		<RenderMessageTemplate
			message={typeToastMessages ? typeToastMessages[messageKey] ?? "" : ""}
			params={messageParams ?? {}}
		/>
	);
}
