import { Field } from "@salesdesk/salesdesk-schemas";
import { PropsWithChildren } from "react";
import { Icon, Tooltip } from "@salesdesk/daisy-ui";

interface RecordPreviewFieldProps extends PropsWithChildren {
	field: Field;
}

export function RecordPreviewField({ children, field }: RecordPreviewFieldProps) {
	return (
		<div className="flex min-h-7 gap-1">
			<div className="flex w-[100px] flex-shrink-0 flex-grow-0 items-center gap-2">
				<Icon icon={field._icon} size="sm" className="text-c_icon_regular flex items-center" />
				<Tooltip text={field._displayName} placement="top" showOnTruncated>
					<div className="text-label-sm text-c_text_secondary w-full truncate">{field._displayName}</div>
				</Tooltip>
			</div>
			<div className="flex flex-grow items-center truncate">{children}</div>
		</div>
	);
}
