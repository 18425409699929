import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useOpenDMChannel, useOpenDMHuddle } from "../../../messaging";
import { useWebPrincipal } from "../../../../auth";

interface RecordPreviewDMButtonsProps {
	recordId: number;
	onClose?: () => void;
}

export function RecordPreviewDMButtons({ recordId, onClose }: RecordPreviewDMButtonsProps) {
	const { openDMChannel, isLoading: isLoadingDMChannel } = useOpenDMChannel();
	const { openDMHuddle, isLoading: isLoadingDMHuddle } = useOpenDMHuddle();

	const principal = useWebPrincipal();

	if (recordId === principal.UserRecordId || (!openDMChannel && !openDMHuddle)) {
		return null;
	}

	return (
		<div className="ml-18 flex items-center gap-3">
			{openDMChannel ? (
				<Button
					variant="outlined"
					size="sm"
					startIcon={ICONS.chatsCircle}
					isLoading={isLoadingDMChannel}
					onClick={() => openDMChannel(recordId, onClose)}
				>
					Message
				</Button>
			) : null}
			{openDMHuddle ? (
				<Button
					variant="outlined"
					size="sm"
					isLoading={isLoadingDMHuddle}
					startIcon={ICONS.videoCamera}
					onClick={() => openDMHuddle(recordId)}
				>
					Huddle
				</Button>
			) : null}
		</div>
	);
}
