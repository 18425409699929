import { AriaAttributes, DetailedHTMLProps, InputHTMLAttributes, KeyboardEvent, ReactNode } from "react";

export enum TextInputVariant {
	TEXT,
	PASSWORD,
	EMAIL,
	URL,
	PHONE,
	PROBABILITY,
	PROGRESS,
	CURRENCY,
	NUMERIC,
}

export enum InputValidationState {
	initial = "initial",
	error = "error",
	success = "success",
}

export interface InputComponentProps {
	id?: string;
	value?: any;
	onChange: (event: React.ChangeEvent | any) => void;
	onBlur?: (event: React.FocusEvent) => void;
	ariaAttributes?: AriaAttributes;
	disabled?: boolean;
	onCancel?: (event: KeyboardEvent) => void;
	onApply?: (event: KeyboardEvent) => void;
}

export interface ErrorInputComponentProps {
	hasError?: boolean;
}

export interface TextInputProps
	extends Omit<
			DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
			"className" | "onBlur" | "onChange" | "type" | "size" | "ref"
		>,
		InputComponentProps,
		ErrorInputComponentProps {
	value?: string;
	placeholder?: string;
	variant?: TextInputVariant;
	validationState?: InputValidationState;
	icon?: string;
	textCenter?: boolean;
	size?: "sm" | "lg";
	suffixButton?: ReactNode;
}

interface NumericTextInputProps extends TextInputProps {
	type: "number";
	onChange: (value: number | undefined) => void;
	onIconClick?: () => void;
}

interface DefaultTextInputProps extends TextInputProps {
	type?: "text" | "time" | "password";
	onChange: (value: string) => void;
	onIconClick?: () => void;
}

export type TextInputComponentProps = DefaultTextInputProps | NumericTextInputProps;
