import { Column, Table } from "@tanstack/react-table";
import { Checkbox } from "../../../inputs";
import { TableRow } from "../../types";
import { LinkedTableSelectAllHeaderCell } from "./LinkedTableSelectAllHeaderCell";

interface SelectAllHeaderCellProps<T> {
	isSingleSelect: boolean;
	table: Table<TableRow<T>>;
	column: Column<TableRow<T>>;
	isPrimaryLinkedTable?: boolean;
}

export function SelectAllHeaderCell<T>({
	isPrimaryLinkedTable,
	isSingleSelect,
	table,
	column,
}: SelectAllHeaderCellProps<T>) {
	if (isSingleSelect) {
		return null;
	}

	if (isPrimaryLinkedTable) {
		return <LinkedTableSelectAllHeaderCell column={column} />;
	}

	return (
		<div key={`${column.id}-header-select`} className="flex">
			<Checkbox
				value={table.getIsAllRowsSelected()}
				isIndeterminate={table.getIsSomeRowsSelected()}
				onChange={(checked) => table.toggleAllRowsSelected(checked)}
			/>
		</div>
	);
}
