import { asConst } from "json-schema-to-ts";
import { appendEditableForCustomAndEditableSystem } from "../../functions/textAppenders";
import { booleanSchema } from "../../components/booleanSchema";

export const objectSupportsHistorySchema = asConst({
	...booleanSchema,
	title: "Supports History",
	description: appendEditableForCustomAndEditableSystem(
		"Indicates if history can be searched for this object's records"
	),
});
