import { Row } from "@tanstack/react-table";

import { Checkbox, RadioButton } from "../../../../../inputs";
import { TableRow } from "../../../../types";

interface SelectCellProps<T> {
	isSingleSelect: boolean;
	row: Row<TableRow<T>>;
}

export function SelectCell<T>({ isSingleSelect, row }: SelectCellProps<T>) {
	const onRowSelectionChange = row.getToggleSelectedHandler();

	return (
		<div key={`${row.id}-select`} className="flex">
			{isSingleSelect ? (
				<RadioButton
					value={row.getIsSelected()}
					onChange={(checked) => {
						onRowSelectionChange({ target: checked });
					}}
					disabled={!row.getCanSelect()}
				/>
			) : (
				<Checkbox
					value={row.getIsSelected()}
					disabled={!row.getCanSelect()}
					isIndeterminate={row.getIsSomeSelected()}
					onChange={(checked) => {
						onRowSelectionChange({ target: checked });
					}}
				/>
			)}
		</div>
	);
}
