import clsx from "clsx";
import { useMemo } from "react";

import { Field, isAssetObject, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { FIELD_TYPES, mAssetDef, mDocDef } from "@salesdesk/salesdesk-model";
import { Button, TextInputVariant, Tooltip } from "@salesdesk/daisy-ui";

import { FabContainer } from "../../../../../../../../components/FabContainer";
import { useCopyToClipboard } from "../../../../../../../../hooks/useCopyToClipboard";
import { generateLinkForTextInputVariant, getTextInputVariant, isFileField } from "../../../../../../../fields";
import { EditFieldVariant } from "../../../../../../../fields/components/FieldFactory/EditFieldFactory";
import { InlineEditRecordField, useInlineEditField } from "../../../../../RecordFields";
import { FieldLabelPosition } from "../types";
import { RecordDetailsFieldSet } from "./RecordDetailsFieldSet";

const OPACITY_FIELD_CLASSES = "cursor-default opacity-50";

interface RecordDetailsInlineFieldProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
	field: Field;
}

export function RecordDetailsInlineField({ sdObject, sdRecord, field }: RecordDetailsInlineFieldProps) {
	const copyToClipboard = useCopyToClipboard();

	const { inlineRecordFieldProps, updateEditMode, error, alwaysEditable } = useInlineEditField(
		sdObject,
		sdRecord,
		field
	);

	const {
		editMode: actualEditMode,
		canEditField,
		componentProps: { value: fieldValue },
	} = inlineRecordFieldProps;

	const fileFieldWithValue = isFileField(field) && fieldValue != null;
	const editMode = fileFieldWithValue ? true : actualEditMode;

	let fieldComponent = (
		<InlineEditRecordField
			{...inlineRecordFieldProps}
			editMode={editMode}
			editFieldVariant={
				sdObject && isAssetObject(sdObject) && field._name === mAssetDef.FILE_FIELD_NAME
					? EditFieldVariant.primary
					: EditFieldVariant.default
			}
		/>
	);

	let highlightOnHover = !editMode && canEditField;
	let fieldWrapperPadding = true;
	let additionalFieldClasses = canEditField || fileFieldWithValue ? "" : OPACITY_FIELD_CLASSES;

	switch (field._type) {
		case FIELD_TYPES.URL.name:
		case FIELD_TYPES.PHONE_NUMBER.name:
		case FIELD_TYPES.EMAIL.name: {
			if (editMode || !fieldValue) {
				break;
			}

			fieldWrapperPadding = false;
			additionalFieldClasses = "";
			const textFieldVariant = getTextInputVariant(field._type);

			let linkIcon = ICONS.arrowSquareOut;
			let tooltipText = "Open link";
			if (textFieldVariant === TextInputVariant.EMAIL) {
				linkIcon = ICONS.envelope;
				tooltipText = "Send email";
			} else if (textFieldVariant === TextInputVariant.PHONE) {
				linkIcon = ICONS.phone;
				tooltipText = "Call";
			}

			const urlValue = fieldValue as string;

			fieldComponent = (
				<FabContainer
					className={clsx("flex h-full w-full items-center px-4 py-1.5")}
					positionClasses="top-1.5 right-1.5"
					buttons={
						<>
							<Tooltip text="Copy to clipboard">
								<Button
									startIcon={ICONS.link}
									size="xs"
									variant="text"
									onClick={(e) => {
										e.stopPropagation();
										copyToClipboard(urlValue);
									}}
								/>
							</Tooltip>
							<Tooltip text={tooltipText}>
								<Button
									startIcon={linkIcon}
									size="xs"
									variant="text"
									as="link"
									to={generateLinkForTextInputVariant(textFieldVariant, urlValue)}
									onClick={(e) => e.stopPropagation()}
								/>
							</Tooltip>
						</>
					}
				>
					{!canEditField ? (
						<div className={clsx("w-full", OPACITY_FIELD_CLASSES)}>{fieldComponent}</div>
					) : (
						fieldComponent
					)}
				</FabContainer>
			);
			break;
		}
		case FIELD_TYPES.RATING.name: {
			highlightOnHover = canEditField;
		}
	}

	// Hide field label for the main file field of asset objects and the document field of Doc objects
	const fieldLabelPosition: FieldLabelPosition = useMemo(() => {
		if (!sdObject) {
			return "LEFT";
		}

		if (field._type === FIELD_TYPES.RICH_TEXT.name) {
			return "TOP";
		}

		if (isAssetObject(sdObject) && field._name === mAssetDef.FILE_FIELD_NAME) {
			return "NONE";
		}

		if (sdObject._id === mDocDef.ID && field._name === mDocDef.DOCUMENT_FIELD_NAME) {
			return "NONE";
		}

		return "LEFT";
	}, [field, sdObject]);

	return (
		<RecordDetailsFieldSet
			onClick={() => {
				if (editMode || !canEditField) return;
				updateEditMode(true);
			}}
			icon={field._icon}
			label={field._displayName}
			labelPosition={fieldLabelPosition}
			error={editMode ? error : undefined}
			disabled={!canEditField || editMode}
			tooltipText={
				canEditField || fileFieldWithValue
					? undefined
					: field._editable
						? "You don't have permission to edit this field"
						: "This field is read-only"
			}
			fieldWrapperClasses={clsx(
				"focus-visible:border-c_action_01 focus-visible:ring-c_action_focus focus-visible:ring",
				alwaysEditable && fieldWrapperPadding && fieldLabelPosition === "LEFT" && "pl-4",
				!editMode && fieldWrapperPadding && "px-4 py-1.5",
				highlightOnHover && canEditField && "hover:bg-c_bg_03",
				additionalFieldClasses
			)}
		>
			{fieldComponent}
		</RecordDetailsFieldSet>
	);
}
