import { SDApi } from "../../api";
import { AppState } from "../types";

export const userAppStateApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getMyAppState: builder.query<AppState, void>({
				query: () => ({
					url: `/me/app-state`,
					method: "GET",
				}),
				providesTags: () => ["UserAppState"],
			}),
			patchMyAppState: builder.mutation<void, Partial<AppState>>({
				query: (body) => ({
					url: `/me/app-state`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: () => ["UserAppState"],
			}),
		};
	},
});

export const { useGetMyAppStateQuery, usePatchMyAppStateMutation } = userAppStateApi;
