import { ToastLink } from "../../../components/Toast/components/ToastLink";
import { MessageTemplateParam } from "../types";

interface RenderMessageTemplateProps {
	message: string;
	params: Record<string, MessageTemplateParam>;
}

export function RenderMessageTemplate({ message, params }: RenderMessageTemplateProps) {
	return message.split(/[{}]/).map((part, index) => {
		if (index % 2 === 0) {
			// Render the text in between params
			return part;
		}

		const pipeIndex = part.indexOf("|");
		const param = pipeIndex >= 0 ? params[part.slice(0, pipeIndex)] : params[part];

		// Gets default value for param if no param is provided or returns the part as is
		if (!param) {
			return pipeIndex >= 0 ? part.slice(pipeIndex + 1) : `{${part}}`;
		}

		if (typeof param === "string") {
			return param;
		}

		// Render the content of the simple param
		if (param.type === "node") {
			return param.node;
		}
		// Render link param, priority is given to the label provided in the string template: {/link|Override text}
		// Otherwise render the label provided in the param object.
		const linkLabelOverride = pipeIndex >= 0 ? part.slice(pipeIndex + 1) : undefined;
		return <ToastLink key={param.to} to={param.to} text={linkLabelOverride || param.label || param.to} />;
	});
}
