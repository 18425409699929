import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import clsx from "clsx";

interface TimelineItemProps {
	dateLabel?: string;
	isLoading?: boolean;
}

export function TimelineItem({ dateLabel, isLoading }: TimelineItemProps) {
	return (
		<div className="relative shrink-0 grow-0" style={{ width: 130 }}>
			<div className="bg-c_border_regular absolute h-full w-px" style={{ marginLeft: 104 }} />
			{(dateLabel !== undefined || isLoading) && (
				<>
					<div
						className={clsx(
							"absolute ml-[100px] mt-6 size-[9px] rounded-full",
							isLoading ? "bg-c_bg_disabled_02" : "bg-c_action_02"
						)}
					/>
					<div className="text-label-sm text-c_action_02 flex justify-end" style={{ marginTop: 19, paddingRight: 44 }}>
						{isLoading ? <Skeleton className="h-6 w-1/2" /> : <div>{dateLabel}</div>}
					</div>
				</>
			)}
		</div>
	);
}
