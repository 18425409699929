import { useMemo, useState } from "react";
import { DragDropContext, DropResult, DragUpdate } from "@hello-pangea/dnd";

import { Field, getAllSDObjectFields, SDObject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { FieldDefFactory, FIELD_CREATION_TYPE, FIELD_TYPE_GROUPS } from "@salesdesk/salesdesk-model";

import { AlertDialog } from "../../../../../Dialog";
import { FieldSettingsGroup } from "../../types";
import { FieldPicker } from "./FieldPicker";
import { FieldBuilder } from "./FieldBuilder";
import { FieldSettings } from "./FieldSettings";
import { Button } from "@salesdesk/daisy-ui";

interface ObjectFieldsProps {
	sdObject: SDObject;
	fieldSettingGroups: FieldSettingsGroup[];
	activeFieldId: number;
	setActiveFieldId: (fieldId: number) => void;
	insertNewField: (field: Field, destinationIndex: number) => void;
	reorderObjectFields: (startIndex: number, endIndex: number) => void;
	deleteFieldById: (fieldId: number) => void;
	invalidObjectFieldIds: number[];
}

export function ObjectFields({
	sdObject,
	insertNewField,
	fieldSettingGroups,
	activeFieldId,
	setActiveFieldId,
	reorderObjectFields,
	deleteFieldById,
	invalidObjectFieldIds,
}: ObjectFieldsProps) {
	const [destinationDropIndex, setDestinationDropIndex] = useState<number | null>(null);
	const [draggingSourceId, setDraggingSourceId] = useState<null | string>(null);
	const [fieldToDelete, setFieldToDelete] = useState<Field | null>(null);

	const { sdObjectFields, sdObjectDisplayName } = useMemo(
		() => ({
			sdObjectFields: getAllSDObjectFields(sdObject),
			sdObjectDisplayName: sdObject._displayName,
		}),
		[sdObject]
	);

	const onDragEnd = (dropResult: DropResult) => {
		const { source, destination } = dropResult;

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			reorderObjectFields(source.index, destination.index);

			return;
		}

		const fieldGroup = FIELD_TYPE_GROUPS.find(({ label }) => source.droppableId === label);
		const fieldChip = fieldGroup?.fields[source.index];

		if (!fieldChip) {
			throw new Error("FieldChip was not found");
		}

		const newField: any = FieldDefFactory.newInstance(fieldChip.name);
		newField.creationType = FIELD_CREATION_TYPE.USER;
		newField.displayName = fieldChip.displayName;

		insertNewField(newField.unmarshall(true) as Field, destination.index);
		setDestinationDropIndex(null);
	};

	const onDragUpdate = (update: DragUpdate) => {
		const { source, destination } = update;

		setDraggingSourceId(source.droppableId);

		if (destination && destination.droppableId !== source.droppableId) {
			setDestinationDropIndex(destination.index);
		} else {
			setDestinationDropIndex(null);
		}
	};

	const closeDeleteDialog = () => {
		setFieldToDelete(null);
	};

	const deleteField = () => {
		if (!fieldToDelete) return;

		deleteFieldById(fieldToDelete._id);
		closeDeleteDialog();
	};

	const onFieldDelete = (fieldIdToDelete: number) => {
		const fieldToDelete = sdObjectFields.find(({ _id }) => fieldIdToDelete === _id);
		if (!fieldToDelete) {
			throw new Error(`Field for deleting with id ${fieldIdToDelete} was not found`);
		}
		setFieldToDelete(fieldToDelete);
	};

	const deletingFieldName = fieldToDelete?._displayName;

	return (
		<div className="flex h-full justify-between">
			<DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
				<div className="flex w-1/4 justify-start">
					<FieldPicker fields={FIELD_TYPE_GROUPS} />
				</div>
				<div className="flex w-2/4 justify-center">
					<FieldBuilder
						fields={sdObjectFields}
						dropIndex={destinationDropIndex}
						activeFieldId={activeFieldId}
						draggingSourceId={draggingSourceId}
						onFieldMouseDown={setActiveFieldId}
						onFieldDelete={onFieldDelete}
						invalidObjectFieldIds={invalidObjectFieldIds}
					/>
				</div>
			</DragDropContext>
			<div className="flex w-1/4 justify-end">
				<FieldSettings activeFieldId={activeFieldId} fieldSettingsGroups={fieldSettingGroups} />
			</div>
			<AlertDialog
				open={fieldToDelete !== null}
				title={`Delete '${deletingFieldName}'`}
				message={`Are you sure you want to delete this field? Data for '${deletingFieldName}' fields in all '${sdObjectDisplayName}' object records will be lost. This can’t be undone.`}
				destructiveLabel="Delete field"
			>
				{({ isConfirmDisabled }) => (
					<div className="flex justify-end gap-3">
						<Button startIcon={ICONS.trash} variant="secondary" onClick={deleteField} disabled={isConfirmDisabled}>
							Delete
						</Button>
						<Button onClick={closeDeleteDialog}>Cancel</Button>
					</div>
				)}
			</AlertDialog>
		</div>
	);
}
