import { FIELD_TYPES, FieldTypeName } from "@salesdesk/salesdesk-model";
import { DateTimeFieldVariant } from "../types";

export function getDateTimeFieldVariant(fieldType: FieldTypeName) {
	switch (fieldType) {
		case FIELD_TYPES.DATE_TIME.name:
			return DateTimeFieldVariant.DATE_TIME;
		default:
			return DateTimeFieldVariant.DATE;
	}
}
