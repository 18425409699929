import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";

import { Field, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mFieldDef } from "@salesdesk/salesdesk-model";

import { UploadProgressStatus, convertFieldToFieldDef } from "../../../../fields";
import { FieldComponentFactory } from "../../../../forms";
import { RecordFormControl } from "../types";
import { useCanEditRecordField } from "../../../hooks";
import { useUniqueCheckWithReactHookForm } from "../../../hooks/useUniqueCheckWithReactHookForm";

interface RecordFormFieldProps {
	field: Field;
	formControl: RecordFormControl;
	disabled?: boolean;
	setUploadProgressStatusPerField: Dispatch<SetStateAction<Record<string, UploadProgressStatus>>>;
	fieldName: string;

	sdRecord?: SDRecord;
	sdObject?: SDObject;
}

export function RecordFormField({
	field,
	formControl,
	disabled,
	fieldName,
	setUploadProgressStatusPerField,
	sdObject,
	sdRecord,
}: RecordFormFieldProps) {
	const { fieldDefId, fieldDef } = useMemo(
		() => ({
			fieldDefId: String(field._id),
			fieldDef: convertFieldToFieldDef(field) as mFieldDef,
		}),
		[field]
	);

	const canEditRecordField = useCanEditRecordField(field, sdObject, sdRecord);

	// If a specific sdObject and sdRecord are provided, we should disable the field if the user does not have permission to edit it
	const isFieldDisabled = disabled ? true : sdObject && sdRecord ? !canEditRecordField : !field._editable;

	const updateProgressStatusForField = useCallback(
		(status: UploadProgressStatus) => {
			setUploadProgressStatusPerField((prev) => ({ ...prev, [fieldDefId]: status }));
		},
		[fieldDefId, setUploadProgressStatusPerField]
	);

	const { uniqueError } = useUniqueCheckWithReactHookForm({ sdObject, sdRecord, field, fieldName });

	return (
		<div key={fieldName} className="mb-5">
			<Controller
				name={fieldName}
				control={formControl}
				render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
					<FieldComponentFactory
						fieldDef={fieldDef}
						componentProps={{
							id: fieldDefId,
							onBlur,
							onChange,
							inputRef: ref,
							value,
							hasError: Boolean(error?.message),
							disabled: isFieldDisabled,
						}}
						errorMessage={error?.message}
						updateUploadProgressStatus={updateProgressStatusForField}
					/>
				)}
				rules={{ validate: (value) => uniqueError || fieldDef.validate(value, sdRecord?.isTemplate) }}
			/>
		</div>
	);
}
