import { Transition } from "@headlessui/react";
import { tw } from "@salesdesk/daisy-ui";

interface DropIndicatorProps {
	columns: number;
}

export function DropIndicator({ columns }: DropIndicatorProps) {
	return (
		<tr>
			<td colSpan={columns} className="relative h-0 p-0">
				<Transition
					as="div"
					appear
					show
					enter={tw`transition-all`}
					enterFrom={tw`bg-c_border_regular h-0`}
					enterTo={tw`bg-c_action_01 h-1`}
					className="absolute left-0 top-0 z-10 w-full rounded-full"
				/>
			</td>
		</tr>
	);
}
