import { useEffect, useState } from "react";

import { rsr } from "@salesdesk/salesdesk-schemas";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

import { APP_CONFIG } from "../../../../../app/app_config";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";

import {
	RecordAssociationCard,
	SkeletonRecordAssociationCard,
	useGetAssociationFieldIds,
} from "../../../../recordAssociations";
import { useInfiniteRecordSearch } from "../../../../records";
import { DebouncedSearchbar } from "../../../../inputs";
import { useCurrentUserFirstName, useGetCurrentUserRecord } from "../../../../users";
import { WorkspaceListPageWelcomeMessage } from "./WorkspaceListPageWelcomeMessage";

export function WorkspacesListPage() {
	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);

	const userRecord = useGetCurrentUserRecord();
	const firstName = useCurrentUserFirstName();

	const [searchQuery, setSearchQuery] = useState<string>();

	const { sdRecords, updateSearchParams, isLoadingNewSearchParams } = useInfiniteRecordSearch({
		limit: APP_CONFIG.maxLocalSearchResults,
		sdObjectFilter: mWorkspaceDef.ID,
	});

	const displayFieldIds = useGetAssociationFieldIds(workspaceObject);

	useEffect(() => {
		if (!workspaceObject) {
			return;
		}

		const query = rsr.query().and(rsr.equals("_deleted", false));

		if (searchQuery) {
			query.and(rsr.matchAllPrefix(searchQuery));
		}

		// Fetches all workspaces the user has access to
		updateSearchParams(rsr.create().query(query.buildQuery()).buildRequest());
	}, [workspaceObject, updateSearchParams, searchQuery]);

	const numOfSkeletonCards = userRecord?.sharedWorkspaceIds.length ?? 2;

	const noWorkspaces = !isLoadingNewSearchParams && sdRecords.length === 0;
	const isFiltered = Boolean(searchQuery);

	const workspacePluralName = workspaceObject?._pluralName || "Workspaces";

	return (
		<>
			<div className="flex flex-col gap-4 px-0.5">
				<h1 className="text-h3 text-c_action_01">Welcome {firstName}!</h1>
				<p className="text-c_text_secondary">
					<WorkspaceListPageWelcomeMessage />
				</p>
			</div>
			<div className="flex w-full flex-col items-end gap-4 overflow-hidden">
				<div className="px-0.5">
					<DebouncedSearchbar onChange={setSearchQuery} disabled={noWorkspaces && !isFiltered} />
				</div>
				<div className="flex max-h-full min-h-0 w-full flex-col gap-4 overflow-y-auto p-0.5">
					{noWorkspaces ? (
						isFiltered ? (
							<div className="text-c_text_secondary flex flex-col items-center gap-6">
								No {workspacePluralName} match the search "{searchQuery}"
								<Button onClick={() => setSearchQuery("")} size="sm" startIcon={ICONS.backspace}>
									Clear search
								</Button>
							</div>
						) : (
							<p className="text-c_text_secondary flex flex-col items-center gap-6">
								You don't have access to any {workspacePluralName}
							</p>
						)
					) : null}
					{isLoadingNewSearchParams || !workspaceObject
						? [...Array(numOfSkeletonCards)].map((_, index) => <SkeletonRecordAssociationCard key={index} rows={2} />)
						: sdRecords.map((workspace) => (
								<div className="shrink-0">
									<RecordAssociationCard
										key={workspace._id}
										sdObject={workspaceObject}
										sdRecord={workspace}
										displayFieldIds={displayFieldIds}
										hideContextMenu
										openingVariant="workspace"
									/>
								</div>
							))}
				</div>
			</div>
		</>
	);
}
