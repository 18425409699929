import { useChimeMessagingContext } from "../components";
import { useEffect, useState } from "react";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

export function useChannelMessage(channelArn: string, messageId: string) {
	const { chime } = useChimeMessagingContext();
	const [isLoading, setIsLoading] = useState(true);
	const [channelMessage, setChannelMessage] = useState<ChannelMessage>();

	useEffect(() => {
		if (!chime) return;

		setIsLoading(true);
		chime
			.getChannelMessage(channelArn, messageId)
			.then((response) => setChannelMessage(response))
			.catch((error) => console.error("Error fetching channel message", error))
			.finally(() => setIsLoading(false));
	}, [chime, channelArn, messageId]);

	return { isLoading, channelMessage };
}
