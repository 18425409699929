import { createContext, useContext, useEffect, useRef } from "react";
import { AppState } from "../index";

export const AppStateContext = createContext<AppState | undefined>(undefined);

export function useAppStateContext() {
	const appState = useContext(AppStateContext);
	if (!appState) {
		throw new Error("useAppStateContext must be used within a AppStateContext.Provider");
	}
	const appStateRef = useRef(appState);
	useEffect(() => {
		appStateRef.current = appState;
	}, [appState]);
	return { appState, appStateRef };
}
