import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { DATE_GROUPING } from "@salesdesk/salesdesk-model";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mDateFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.DATE.name];
	static defaultComponentType: FieldComponentName = mDateFieldDef.supportedComponentTypes[0];

	_supportsPast = true;

	constructor(id: string | number) {
		super(id);

		this._componentType = mDateFieldDef.defaultComponentType;

		// How the data is stored internally (ISO8601 - YYYY-MM-DDTHH:mm:ss.sssZ)
		this._format = "YYYY-MM-DD";

		// The format to be used by the user to input the date
		this._formatDescription = "dd/mm/yyyy";

		this._icon = FIELD_TYPES.DATE.icon;

		this._supportsDefaultValue = true;
		this._supportsUniqueValue = true;
	}

	override get type() {
		return FIELD_TYPES.DATE.name;
	}

	override get supportedComponentTypes() {
		return mDateFieldDef.supportedComponentTypes;
	}

	get supportsPast() {
		return this._supportsPast;
	}

	set supportsPast(supportsPast) {
		this._supportsPast = supportsPast;
	}

	override supportsGrouping() {
		return !this.supportsMultiple() && !this.hidden && this.required;
	}

	supportsReGrouping() {
		return false;
	}

	get groupInfo() {
		return DATE_GROUPING;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value) && (Number.isNaN(value) || !Number.isInteger(value))) {
			return `'${this.displayName}' expects a valid date.`;
		}

		return super.validate(value, isTemplate);
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}
}
