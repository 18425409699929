import { Outlet } from "react-router-dom";

import { WorkspaceHeader } from "../WorkspaceHeader";
import { Link } from "../../../../components/Link";
import { SALESDESK_GET_STARTED_SITE } from "../../../../routes";
import { useIsGuest } from "../../../meetings/components/MeetingPage/hooks/useIsGuest";

export function WorkspacesPageContainer() {
	const isGuest = useIsGuest();

	return (
		<div className="bg-c_bg_01 h-full w-full">
			<div className="relative z-10 mx-auto flex h-full w-full max-w-screen-2xl flex-col overflow-hidden">
				{!isGuest ? <WorkspaceHeader outsideWorkspace /> : null}
				<div className="mx-auto flex h-full w-full max-w-screen-xl gap-8 overflow-hidden px-8 pt-10">
					<div className="w-full lg:w-[45%] lg:max-w-[550px]">
						<div className="flex h-full max-h-full flex-col gap-8 overflow-hidden">
							<Outlet />
							<div className="text-c_text_secondary mb-6 mt-auto flex items-center">
								Why not try <Link to={SALESDESK_GET_STARTED_SITE} text="SalesDesk" invertUnderline></Link> in your
								organisation?
							</div>
						</div>
					</div>
				</div>
				<div className="hidden lg:block lg:w-1/2">
					<img
						className="w-hidden absolute -top-16 right-8 w-1/2 lg:block"
						src="/static/images/graphics/workspaces-graphic.svg"
						alt="Workspaces Graphic"
					/>
				</div>
			</div>
		</div>
	);
}
