import { PropsWithChildren } from "react";

import { getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { Tooltip } from "@salesdesk/daisy-ui";

import { useGetRecordsQuery } from "../../../features/records";
import { useGetObjectById } from "../../../hooks/useGetObjectById";
import { ReactionType } from "../types";

interface ReactionTooltipProps extends PropsWithChildren {
	reaction: ReactionType;
}

export function ReactionTooltip({ reaction, children }: ReactionTooltipProps) {
	const { data: users } = useGetRecordsQuery(reaction.userRecordIds);
	const { sdObject: userObject } = useGetObjectById(users?.[0]?._objectDefId);

	return (
		<Tooltip
			text={users?.length && userObject ? users.map((user) => getSDRecordName(userObject, user)).join(", ") : ""}
			placement="top"
		>
			{children}
		</Tooltip>
	);
}
