import { asConst } from "json-schema-to-ts";
import { hasAssociationToRecordForObjectAssociationClausePropertySchema } from "./hasAssociationToRecordForObjectAssociationClausePropertySchema";

export const hasAssociationToRecordForObjectAssociationClauseSchema = asConst({
	title: "Has Association To Record For Object Association",
	description: "Will match any record that is associated with the given label to the given record Id",
	type: "object",
	additionalProperties: false,
	required: ["hasAssociationToRecordForObjectAssociation"],
	properties: {
		hasAssociationToRecordForObjectAssociation: hasAssociationToRecordForObjectAssociationClausePropertySchema,
	},
	nullable: false,
});
