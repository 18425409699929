import { DataService } from "@salesdesk/salesdesk-services";
import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mSingleOptionFieldDef } from "./single_option_field_def";
import { mObjectBackingStore } from "../backing_store";
import { FIELD_TYPES } from "../../utils";

export class mObjectFieldDef extends mSingleOptionFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name];
	static override defaultComponentType: FieldComponentName = mObjectFieldDef.supportedComponentTypes[0];

	_objectPluralName: string | null;
	_objectDefId: number | null;
	_objectDef: any;

	constructor(id: string | number, objectDef?: any) {
		super(id, mObjectBackingStore.TYPE);
		this._componentType = mObjectFieldDef.defaultComponentType;
		this._objectPluralName = null;
		this._icon = FIELD_TYPES.OBJECT.icon;
		this._objectDefId = null;

		if (!isEmpty(objectDef)) {
			this._objectDefId = objectDef.id;
		}

		this._supportsDefaultValue = false;
	}

	override get type() {
		return FIELD_TYPES.OBJECT.name;
	}

	override get supportedComponentTypes() {
		return mObjectFieldDef.supportedComponentTypes;
	}

	override isObjectType() {
		return true;
	}

	get objectDefId() {
		return this._objectDefId;
	}

	set objectDefId(objectDefId) {
		this._objectDefId = objectDefId;
	}

	get objectType() {
		if (this.hasObjectDef()) {
			return (DataService as any).getInstance().getObjectDef(this.objectDefId).name;
		}
		return null;
	}

	set objectType(objectType) {
		this.objectDefId = (DataService as any).getInstance().getObjectDefByName(objectType).id;
	}

	get objectDef() {
		if (this.objectDefLoaded()) {
			return this._objectDef;
		}

		return undefined;
	}

	set objectDef(objectDef) {
		this._objectDef = null;
		this._objectDefId = -1;

		if (objectDef) {
			this._objectDef = objectDef;
			this._objectDefId = objectDef.id;
		}
	}

	loadObjectDef(callback: any) {
		if (this.hasObjectDef()) {
			if (this.objectDefLoaded()) {
				callback(null, this._objectDef);
			} else {
				(DataService as any).getInstance().loadObjectDef(this._objectDefId, (err: any, objectDef: any) => {
					this._objectPluralName = objectDef.pluralName;
					callback(err, objectDef);
				});
			}
		} else {
			callback(Error(`This field does not have an object def id set`), null);
		}
	}

	override hasObjectDef() {
		return this._objectDefId !== -1;
	}

	objectDefLoaded() {
		return this._objectDefId !== -1 && !isEmpty(this._objectDef);
	}

	override hasFormatDescription() {
		return !isEmpty(this._objectPluralName);
	}

	override get formatDescription() {
		if (!isEmpty(this._objectPluralName)) {
			return `Search ${this._objectPluralName}`;
		}
		return super.formatDescription;
	}

	override set formatDescription(formatDescription) {
		this._formatDescription = formatDescription;
	}

	override validateFields() {
		super.validateFields();

		if (isEmpty(this._objectDefId)) {
			throw new Error(`No object type id has been set in field "${this.name}"`);
		}
	}

	override unmarshall(skipValidation?: boolean) {
		const clone = super.unmarshall(skipValidation) as any;
		delete clone._objectDef;
		delete clone._objectPluralName;
		return clone;
	}

	override supportsMaxLength() {
		return false;
	}
}
