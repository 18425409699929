import { RefObject, useEffect, useState } from "react";

export function useGetDivHeight(divElementRef: RefObject<HTMLDivElement>) {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const newHeight = entry.contentRect.height;

				if (height !== newHeight) {
					setHeight(newHeight);
				}
			}
		});

		if (divElementRef.current) {
			observer.observe(divElementRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [height, divElementRef]);

	return height;
}
