import { forwardRef, useContext } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { RecordPreviewPopover } from "../../../records";
import { User } from "../../types";
import { getInitials } from "../../utils";
import { Avatar } from "../Avatar";
import { Icon, PopoverTriggerContext } from "@salesdesk/daisy-ui";

interface UserPillPropsBase {
	onClick?: () => void;
	onClose?: () => void;
	disabled?: boolean;
	active?: boolean;
}

interface PlatformUserPillProps extends UserPillPropsBase {
	user: User;
	isSystemUser: false;
}

interface SystemUserPillProps extends UserPillPropsBase {
	user: User | string;
	isSystemUser: true;
}

type UserPillProps = PlatformUserPillProps | SystemUserPillProps;

export const UserPill = forwardRef<HTMLDivElement, UserPillProps>(
	({ user, onClick, onClose, disabled, active = false, isSystemUser = false }: UserPillProps, ref) => {
		const isClickable = onClick && !disabled;
		const isClosable = onClose && !disabled;

		const { open: popoverOpen } = useContext(PopoverTriggerContext);

		if (!isSystemUser && typeof user === "string") {
			return null;
		}

		const isUserObject = typeof user === "object";

		const pillContents = (
			<div
				ref={ref}
				className={clsx(
					"border-c_border_regular flex w-fit max-w-full items-center gap-1.5 rounded-full border transition-colors",
					typeof user === "string" ? "py-0.5 pl-0.5" : "min-h-7",
					isClosable ? "pr-8" : "pr-2",
					{
						"bg-c_bg_02": active || popoverOpen,
						"hover:bg-c_bg_02": popoverOpen !== null,
					}
				)}
			>
				<Avatar
					initials={isUserObject ? getInitials(user.fullName) : user}
					avatarFileId={isUserObject ? user.avatarFileId : undefined}
					size="xs"
					isSystemUser={isSystemUser}
					withBorder
				/>
				{(!isSystemUser || typeof user === "string") && (
					<span
						className={clsx("truncate", disabled ? "text-c_text_disabled" : "text-c_text_primary", "text-label-sm")}
					>
						{isUserObject ? user.fullName : user}
					</span>
				)}
			</div>
		);

		const wrappedPillContents =
			typeof user === "object" ? (
				<RecordPreviewPopover recordId={!isSystemUser ? user.id : undefined}>{pillContents}</RecordPreviewPopover>
			) : (
				pillContents
			);

		return (
			<div className="relative max-w-full truncate">
				{isClickable ? (
					<button type="button" className="rounded-full focus-visible:ring" onClick={onClick}>
						{wrappedPillContents}
					</button>
				) : (
					wrappedPillContents
				)}
				{isClosable && (
					<div className="absolute bottom-0 right-[3px] top-0 flex h-full items-center">
						<CloseButton onClose={onClose} disabled={disabled} />
					</div>
				)}
			</div>
		);
	}
);

interface CloseButtonProps {
	onClose: () => void;
	disabled?: boolean;
}

const CloseButton = ({ onClose, disabled }: CloseButtonProps) => {
	return (
		<button
			disabled={disabled}
			className={clsx(
				disabled ? "text-c_icon_disabled" : "text-c_icon_regular",
				"flex h-6 w-6 items-center rounded-full p-1 focus-visible:ring"
			)}
			type="button"
			onClick={() => {
				onClose();
			}}
		>
			<Icon icon={ICONS.cross} size="sm" />
		</button>
	);
};
