import { useState, useMemo } from "react";

import { mAccountDef, mContactDef } from "@salesdesk/salesdesk-model";
import { SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";

import { Divider } from "../../../../components/Divider/Divider";
import { RecordCard } from "../../../records";
import { Checkbox } from "../../../inputs";
import { UploadProgressStatus } from "../../../fields";
import { LINK_CONTACT_IDS_FIELD_KEY, EXISTING_ACCOUNT_ID } from "../../types";
import { getLeadContactDisplayedFields } from "../../utils";
import { CreateAccountFields } from "./CreateAccountFields";
import { OpportunityAssociationField } from "./OpportunityAssociationField";

interface LeadToOpportunitySubFormProps {
	leadSDObject: SDObject;
	leadSDRecord: SDRecord;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
}

export function LeadToOpportunitySubForm({
	leadSDObject,
	leadSDRecord,
	updateUploadProgressStatus,
}: LeadToOpportunitySubFormProps) {
	const [createNewAccount, setCreateNewAccount] = useState(false);

	const displayedFields = useMemo(
		() => getLeadContactDisplayedFields(leadSDObject, leadSDRecord),
		[leadSDObject, leadSDRecord]
	);

	return (
		<>
			<Divider className="my-0" />
			<p className="text-h4">Link opportunity with</p>
			<div>
				<OpportunityAssociationField
					associationFieldId={mAccountDef.ID}
					formFieldsKey={EXISTING_ACCOUNT_ID}
					required
					disabled={createNewAccount}
				>
					<div className="mt-4 flex flex-col gap-4">
						<label className="flex gap-2">
							<Checkbox value={createNewAccount} onChange={setCreateNewAccount} />
							<span className="text-label">Create new account</span>
						</label>
						{createNewAccount ? (
							<div className="grid grid-cols-2 gap-10">
								<CreateAccountFields updateUploadProgressStatus={updateUploadProgressStatus} />
							</div>
						) : null}
					</div>
				</OpportunityAssociationField>
			</div>
			<Divider className="my-0" />
			<OpportunityAssociationField
				associationFieldId={mContactDef.ID}
				formFieldsKey={LINK_CONTACT_IDS_FIELD_KEY}
				isMultiple
			>
				<div className="mt-6 grid grid-cols-2 gap-10">
					<div className="flex items-start gap-2">
						<Checkbox value disabled onChange={() => null} />
						<div className="flex flex-col gap-4">
							<RecordCard fieldInfoToDisplay={displayedFields} sdRecord={leadSDRecord} disabled vertical />
							<p className="text-body-sm text-c_text_secondary">
								We will add this lead as a contact record to your data board, and it will be linked to the opportunity.
							</p>
						</div>
					</div>
				</div>
			</OpportunityAssociationField>
		</>
	);
}
