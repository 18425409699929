import { useEventContext } from "../../../hooks/useEventContext";
import { RecordOwnerUpdatedParams } from "@salesdesk/salesdesk-schemas";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { UserPillController } from "../../../../users";

export function RecordOwnerUpdatedPayload() {
	const { event } = useEventContext();
	const { oldOwnerId, newOwnerId } = event.params as RecordOwnerUpdatedParams;

	return (
		<div className="mb-1 flex items-center gap-1.5">
			<div className="max-w-[70%] truncate">
				<UserPillController userId={oldOwnerId} />
			</div>
			<Icon icon={ICONS.caretRight} size="xs" className="flex items-center" />
			<div className="max-w-[70%] truncate">
				<UserPillController userId={newOwnerId} />
			</div>
		</div>
	);
}
