import { Switch, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useEffect } from "react";

import { tw, useHasMounted } from "@salesdesk/daisy-ui";
import { TENANT_ROUTES } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../../auth";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { RECORD_SIDE_PANEL_TRIGGER_CLASS } from "../../../../../records";
import { useTenantRouteMatch } from "../../../../../../routes";

export function WorkspacePreviewToggle() {
	const { isPreviewMode, setIsPreviewMode } = useWorkspaceContext();
	const hasMounted = useHasMounted();

	const principal = useWebPrincipal();

	const onInternalPage = useTenantRouteMatch(TENANT_ROUTES.WORKSPACE_INTERNAL_PAGE);

	useEffect(() => {
		setIsPreviewMode?.(false);
	}, [onInternalPage, principal.IsCustomerUser, setIsPreviewMode]);

	if (principal.IsCustomerUser) {
		return null;
	}

	return (
		<Transition
			show={!onInternalPage}
			enter={tw`transition-opacity duration-75`}
			enterFrom={tw`opacity-0`}
			enterTo={tw`opacity-100`}
			leave={tw`transition-opacity duration-75`}
			leaveFrom={tw`opacity-100`}
			leaveTo={tw`opacity-0`}
		>
			<Switch
				checked={isPreviewMode}
				onChange={setIsPreviewMode}
				className={clsx(
					hasMounted && "transition",
					RECORD_SIDE_PANEL_TRIGGER_CLASS,
					"bg-c_bg_02 relative z-10 inline-flex h-11 w-[175px] items-center rounded-full p-1"
				)}
			>
				<span
					className={clsx(
						isPreviewMode ? "translate-x-21" : "translate-x-0",
						hasMounted && "transition",
						"bg-c_brand_primary h-9 w-[83px] rounded-full"
					)}
				/>
				<PreviewToggleLabel isPreviewLabel={false} isSelected={isPreviewMode} />
				<PreviewToggleLabel isPreviewLabel isSelected={!isPreviewMode} />
			</Switch>
		</Transition>
	);
}

function PreviewToggleLabel({ isPreviewLabel, isSelected }: { isPreviewLabel: boolean; isSelected?: boolean }) {
	return (
		<span
			className={clsx(
				"text-label-sm w-21 absolute transition-colors",
				isPreviewLabel && "translate-x-21",
				isSelected ? "text-c_text_primary" : "text-c_text_inverted"
			)}
		>
			{isPreviewLabel ? "Preview" : "Edit"}
		</span>
	);
}
