import { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import { tw } from "../../utils/tailwind-helpers";
import clsx from "clsx";

interface SkeletonTransitionProps extends PropsWithChildren {
	className?: string;
}

export function SkeletonTransition({ children, className }: SkeletonTransitionProps) {
	return (
		<Transition
			show={true}
			appear={true}
			className={clsx("transition-all", className)}
			enterFrom={tw`opacity-0`}
			enterTo={tw`opacity-100`}
			leave={tw`duration-0`}
			leaveFrom={tw`opacity-100`}
			leaveTo={tw`opacity-0`}
		>
			{children}
		</Transition>
	);
}
