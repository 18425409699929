import { Transition } from "@headlessui/react";

import { Button, Tooltip, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS, SALESDESK_MAIN_SITE, useNavigateBack } from "../../../routes";
import { useWebPrincipal } from "../../../auth";
import { UserMenu } from "../../UserMenu";
import { NavbarLogoFull } from "../../../components/Navbar";
import { NotificationsButton } from "../../notifications";
import { useWorkspaceContext } from "../hooks/useWorkspaceContext";
import { CurrentAccountLogo, useGetTenantSettingsQuery } from "../../tenant";

interface WorkspaceHeaderProps {
	show?: boolean;
	outsideWorkspace?: boolean;
	isBackButtonExit?: boolean;
}

export function WorkspaceHeader({ show = true, outsideWorkspace, isBackButtonExit = true }: WorkspaceHeaderProps) {
	const navigateBack = useNavigateBack();
	const { workspaceObject, previousOutsideWorkspacePathRef } = useWorkspaceContext();

	const principal = useWebPrincipal();
	const { data: tenantSettings } = useGetTenantSettingsQuery();

	const showWorkspacesLink =
		workspaceObject &&
		(principal.IsSalesDeskUser || (principal.AuthorizedWorkspaceIds.length > 1 && !outsideWorkspace));

	const showExploreSalesDeskLink = !principal.IsSalesDeskUser && outsideWorkspace;
	const showBackButton = showWorkspacesLink || !isBackButtonExit;

	const workspacesUrl = principal.IsSalesDeskUser
		? workspaceObject
			? PATHS.OBJECT_RECORD_BOARD(workspaceObject)
			: PATHS.DASHBOARD()
		: PATHS.WORKSPACES();

	const backButtonUrl = previousOutsideWorkspacePathRef?.current || workspacesUrl;

	return (
		<Transition
			className="bg-c_bg_01 z-50 flex h-16 w-full shrink-0 items-center gap-6 px-4 transition-all ease-in-out"
			show={show}
			enterFrom={tw`!h-0 opacity-0`}
			enterTo={tw`opacity-100`}
			leave={tw`duration-75`}
			leaveFrom={tw`opacity-100`}
			leaveTo={tw`!h-0 opacity-0`}
		>
			{outsideWorkspace ? (
				<div className="text-c_text_secondary text-label flex items-center gap-3">
					{tenantSettings ? tenantSettings.accountLogo ? <CurrentAccountLogo size="sm" /> : <NavbarLogoFull /> : null}{" "}
					<div className="bg-c_border_regular h-6 w-px" /> <div className="pt-0.5">Workspaces</div>
				</div>
			) : showBackButton ? (
				<Tooltip text="Back">
					{isBackButtonExit ? (
						<Button as="link" to={backButtonUrl} size="sm" variant="text" startIcon={ICONS.caretLeft} />
					) : (
						<Button size="sm" variant="text" startIcon={ICONS.caretLeft} onClick={navigateBack} />
					)}
				</Tooltip>
			) : null}
			<div className="ml-auto flex items-center gap-6">
				{showWorkspacesLink ? (
					<Button as="link" to={workspacesUrl} size="sm" variant="primary_text" startIcon={workspaceObject._icon}>
						{workspaceObject._pluralName}
					</Button>
				) : showExploreSalesDeskLink ? (
					<Button as="link" to={SALESDESK_MAIN_SITE} target="_blank" size="sm" variant="primary_text">
						Explore SalesDesk
					</Button>
				) : null}
				<NotificationsButton buttonVariant="text" />
				<UserMenu />
			</div>
		</Transition>
	);
}
