import { useCallback } from "react";
import { To } from "react-router-dom";
import { RouterNavigateOptions } from "@remix-run/router";

import { router } from "../router";

/**
 * This is a custom hook that wraps the router.navigate function to provide a stable reference to it.
 *
 * useNavigate from react router actually returns a new function reference every time the url changes
 * and is reliant on the react router context causing unnecessary re-renders on url changes.
 *
 * SOURCE: https://github.com/remix-run/react-router/issues/7634#issuecomment-1306650156
 */
export function useStableNavigate() {
	return useCallback((to: To | null | number, opts?: RouterNavigateOptions) => {
		if (typeof to === "number") {
			return router.navigate(to);
		}

		return router.navigate(to, opts);
	}, []);
}
