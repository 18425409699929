import { prefixClaimsNS } from "./prefixClaimsNS";

/*
 * Claims that are used to hold information about the ClaimsPrincipal
 */
export const PrincipalClaimType = Object.freeze({
	Tenant: prefixClaimsNS("tenant"),
	Username: prefixClaimsNS("username"),
	FullName: prefixClaimsNS("full-name"),
	UserRecordId: prefixClaimsNS("user-record-id"),
	UserType: prefixClaimsNS("user-type"),
	AuthorizedWorkspaceIds: prefixClaimsNS("authorized-workspace-ids"),
});

export type PrincipalClaimType = (typeof PrincipalClaimType)[keyof typeof PrincipalClaimType];
