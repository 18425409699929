import clone from "clone";

// TODO: These functions should be moved to salesdek-web as they are browser specific...
// But it's not possible at the moment because they are used in salesdesk-model.
declare const window: { CurrentAuthDetails: any } | undefined;
const isBrowser = typeof window !== "undefined" && window != null;
export const inBrowser = () => isBrowser;
export const getCurrentUser = () => (isBrowser ? (window as any).CurrentAuthDetails?.getUser() : undefined);
export const getCurrentUserId = () => (isBrowser ? (window as any).CurrentAuthDetails?.getUserId() : undefined);

// Taken from https://stackoverflow.com/questions/6234773/can-i-escape-html-special-chars-in-javascript
export function escapeHTML(unsafeString: string) {
	return `${unsafeString}`
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
}

export function getRandomBetweenMinMax(min: number, max: number): number {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function createUniqueId() {
	const min = Math.ceil(1);
	// Maximum: 10^15. This is less than the least positive integer which can't be represented in 64 bit floating point.
	const max = Math.floor(1000000000000000);
	return getRandomBetweenMinMax(min, max);
}

// Taken from https://stackoverflow.com/a/52171480/3310775
export function createHashId(str: string, seed = 0) {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

	return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function deepClone<T>(value: T): T {
	return clone(value);
}

export function isEmpty(value: unknown) {
	return Boolean(
		value == null ||
			value === "null" ||
			value === "" ||
			(Array.isArray(value) && value.length <= 0) ||
			value?.toString()?.match(/^\s+$/)
	);
}

export function isEmptyObject(obj: object) {
	for (const prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
	}
	return true;
}

export function stringContainsMatch(string: string, match: string) {
	if (isEmpty(string) || isEmpty(match)) {
		return false;
	}

	return string.toUpperCase().includes(match.toUpperCase());
}

export type ID = string | number;
export function sortByIds<T extends { id: ID }>(elements: T[], ids: ID[]) {
	return elements.sort((a, b) => (ids.indexOf(a.id) > ids.indexOf(b.id) ? 1 : -1));
}

export function buildQueryString(params: { [key: string]: string | number | boolean }) {
	return Object.keys(params)
		.reduce((encodedKeys, key) => {
			if (params[key] !== undefined) {
				encodedKeys.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
			}

			return encodedKeys;
		}, [] as string[])
		.join("&");
}

export function orderByIdArray<T, K extends keyof T>(valueArray: T[], idPropertyName: K, idArray: T[K][]) {
	const idValueMap: Map<T[K], T> = new Map(valueArray.map((value) => [value[idPropertyName], value]));
	const orderedValues: T[] = [];
	idArray.forEach((id) => {
		const value = idValueMap.get(id);
		if (value !== undefined) {
			orderedValues.push(value);
		}
	});
	return orderedValues;
}

export function areEqualSets<T>(set1: Set<T>, set2: Set<T>): boolean {
	if (set1.size !== set2.size) {
		return false;
	}
	for (const item of set1) {
		if (!set2.has(item)) {
			return false;
		}
	}
	return true;
}

export const getMeetingRecordIdFromRoomName = (roomName: string) => parseInt(roomName.split("_")[1], 10);

export async function asyncPause(milliseconds: number) {
	await new Promise((resolve) => {
		setTimeout(resolve, milliseconds);
	});
}

export function chunk<T>(array: T[], size: number): T[][] {
	const results = [];
	const arrayCopy = [...array];
	while (arrayCopy.length) {
		results.push(arrayCopy.splice(0, size));
	}
	return results;
}

export function asObjectSet<T extends number | string>(array: T[] | undefined): Record<T, boolean> {
	if (!array) {
		return {} as Record<T, boolean>;
	}
	const obj: Record<T, boolean> = {} as Record<T, boolean>;
	for (const item of array) {
		obj[item] = true;
	}
	return obj;
}

export function uniqueArray<T>(array: T[]): T[] {
	return Array.from(new Set(array));
}

export function isInteger(value: unknown): boolean {
	if (typeof value === "number") return value % 1 === 0;
	if (typeof value !== "string") return false;
	return /^\d+$/.test(value);
}

export function timeoutPromise(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

/*
  Only returns the keys of T that have a type of V
 */
export type KeyofType<T, V> = keyof { [P in keyof T as T[P] extends V ? P : never]: P };

/*
  Only makes the specificed keys optional
 */
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/*
 Omit doesn't work with distributive conditional types, so we need to use this workaround
 */
export type OmitDistributive<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

/*
  Returns the Type of an arrays elements
 */
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
