import { useMemo } from "react";

import { useAppSelector } from "../../../../store/store";
import { unserializeChannelDetails } from "../../utils";

export function useChannelsSelector(includeReadOnly = false) {
	const channels = useAppSelector((state) => state.channels.channels);
	return useMemo(
		() => channels?.filter((channel) => (includeReadOnly ? true : !channel.isReadOnly))?.map(unserializeChannelDetails),
		[channels, includeReadOnly]
	);
}
