import { FIELD_TYPES } from "../utils";
import { mContainerFieldDef } from "./container_field_def";

export class mDataFieldDef extends mContainerFieldDef {
	override get type() {
		return FIELD_TYPES.DATA.name;
	}

	override unmarshall(skipValidation?: boolean) {
		const clone = super.unmarshall(skipValidation) as any;
		delete clone._parent;
		return clone;
	}

	override toJson() {
		return JSON.stringify(
			this,
			function replacer(key, value) {
				// We don't to show _parent at it creates a recursion issue. Also, it's implied
				// by the semantics of the nested JSON structure.

				if (key === "_parent") {
					return undefined;
				}
				return value;
			},
			2
		);
	}
}
