import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { NAME_FIELD_ID, RECORD_FIELD_ID, RecordTableCellContents, RecordTableColumn, RecordTableRow } from "../types";
import { ROW_IS_LOADING_PLACEHOLDER } from "../../../../Table";
import { RecordFieldDisplayType, getDefaultDisplayFieldIds } from "../../../utils/defaultDisplayFields";
import { getRecordFieldInfo, getSDRecordNameFieldInfo } from "../../../utils/records";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";

export function generateRecordTableColumns(
	sdObject: SDObject,
	displayType: RecordFieldDisplayType = "ASSOCIATION"
): RecordTableColumn[] {
	const renderRowCell = (cellContents: RecordTableCellContents, row: RecordTableRow) => {
		if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof cellContents === "boolean") {
			return <Skeleton className="my-0.5 h-6 w-full" />;
		}

		return "field" in cellContents ? (
			<DisplayFieldFactory field={cellContents.field} value={cellContents.value} variant={DisplayFieldVariant.table} />
		) : null;
	};

	return [
		{
			id: NAME_FIELD_ID,
			displayName: () => null,
			renderRowCell,
		},
		...getDefaultDisplayFieldIds(sdObject, displayType).map((id) => ({
			id: String(id),
			displayName: () => null,
			renderRowCell,
		})),
	];
}

export function generateRecordTableRows(sdRecords: SDRecord[], sdObject: SDObject): RecordTableRow[] {
	return sdRecords.map((record) => {
		const recordRow: RecordTableRow = {
			[NAME_FIELD_ID]: getSDRecordNameFieldInfo(sdObject, record),
			[RECORD_FIELD_ID]: record,
		};
		getRecordFieldInfo(sdObject, record).forEach((recordInfo) => {
			recordRow[recordInfo.field._id] = recordInfo;
		});
		return recordRow;
	});
}
