import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Button } from "@salesdesk/daisy-ui";

import { tw } from "../../../utils/tailwind-helpers";

type AlertVariant = "info" | "success" | "error" | "warning";

interface AlertProps {
	variant?: AlertVariant;
	title: string;
	message: ReactNode;
	onDismissClick?: () => void;
}

type VariantPropertiesType = {
	icon: string;
	iconClasses: string;
	containerClasses: string;
};

const VariantProperties: Record<AlertVariant, VariantPropertiesType> = {
	success: {
		icon: ICONS.checkCircle,
		iconClasses: tw`text-c_success_01 bg-c_success_04`,
		containerClasses: tw`border-c_success_02`,
	},
	info: {
		icon: ICONS.info,
		iconClasses: tw`text-c_action_01 bg-c_action_04`,
		containerClasses: "border-c_action_02",
	},
	warning: {
		icon: ICONS.warning,
		iconClasses: tw`text-c_warning_01 bg-c_warning_04`,
		containerClasses: tw`border-c_warning_02`,
	},
	error: {
		icon: ICONS.crossCircle,
		iconClasses: tw`text-c_danger_01 bg-c_danger_04`,
		containerClasses: tw`border-c_danger_02`,
	},
};

export function Alert({ variant = "info", title, message, onDismissClick, children }: PropsWithChildren<AlertProps>) {
	const vp = VariantProperties[variant];

	return (
		<div className={clsx("bg-c_bg_04 flex gap-3 rounded-md border p-3", vp.containerClasses)}>
			<div className={clsx("flex size-10 shrink-0 items-center justify-center rounded-full", vp.iconClasses)}>
				<Icon icon={vp.icon} className="flex" />
			</div>
			<div className="flex flex-auto flex-col">
				<div className="text-c_text_primary text-label-sm">{title}</div>
				<div className="text-c_text_secondary text-body-sm">{message}</div>
				{children ? <div className="mt-2">{children}</div> : null}
			</div>
			{onDismissClick ? (
				<div>
					<Button variant="primary_text" size="sm" onClick={onDismissClick}>
						Dismiss
					</Button>
				</div>
			) : null}
		</div>
	);
}
