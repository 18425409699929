import { useMemo } from "react";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { PATHS } from "../../routes";
import { useWebPrincipal } from "../../auth";
import { useGetRecordWithObjectByRecordId } from "../../hooks";

export function useEventRecordPathById(workspaceId: number | undefined, recordId: number | undefined) {
	const { sdRecord, sdObject } = useGetRecordWithObjectByRecordId(recordId);

	return useEventRecordPath(workspaceId, sdObject, sdRecord);
}

export function useEventRecordPath(
	workspaceId: number | undefined,
	sdObject: SDObject | undefined,
	sdRecord: SDRecord | undefined
) {
	const principal = useWebPrincipal();

	return useMemo(() => {
		if (!sdObject || !sdRecord) return "";

		return PATHS.EVENT_RECORD(workspaceId, sdObject, sdRecord, principal.IsSalesDeskUser);
	}, [sdObject, sdRecord, workspaceId, principal.IsSalesDeskUser]);
}
