// TODO: Refactor when we update how labels are handled in field forms
// TODO: Should build off of `FormFieldSet`

import { FIELD_TYPES, mFieldDef } from "@salesdesk/salesdesk-model";
import { InputValidationState, FieldSet } from "@salesdesk/daisy-ui";

import { FieldComponentProps } from "../../types";
import { EditFieldFactory, convertFieldDefToField, UploadProgressStatus } from "../../../fields";
import { useCallback, useMemo } from "react";

interface FieldComponentFactoryProps {
	fieldDef: mFieldDef;
	componentProps: FieldComponentProps;
	errorMessage?: string;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
}

export function FieldComponentFactory({
	fieldDef,
	componentProps,
	errorMessage,
	updateUploadProgressStatus,
}: FieldComponentFactoryProps) {
	const { displayName, toolTip, icon, componentType, required } = fieldDef;
	const { onChange, ...restComponentProps } = componentProps;

	// Convert empty values to null (react-hook-form doesn't support 'undefined' as a value)
	const onChangeWrapper = useCallback(
		(newValue: unknown) => {
			onChange(newValue === "" || newValue === undefined ? null : newValue);
		},
		[onChange]
	);

	const field = useMemo(() => convertFieldDefToField(fieldDef, true), [fieldDef]);

	if (!componentType) {
		return <div className="text-c_danger_01">Field has no Component Type</div>;
	}

	let component = EditFieldFactory({
		field,
		componentProps: { ...restComponentProps, onChange: onChangeWrapper },
		updateUploadProgressStatus,
	});

	const currentValidationState = errorMessage ? InputValidationState.error : InputValidationState.initial;

	if (!component) {
		component = <div>Unsupported Field ({componentType})</div>;
	}

	return (
		<FieldSet
			label={displayName!}
			labelIcon={icon}
			helperText={errorMessage || toolTip}
			required={required}
			validationState={currentValidationState}
			isBoolean={fieldDef.type === FIELD_TYPES.BOOLEAN.name}
		>
			{component}
		</FieldSet>
	);
}
