import { useRef, useState } from "react";

import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { formatVideoTimestamp } from "../utils";
import clsx from "clsx";
import { HoverPopover, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";

interface VideoProgressBarProps {
	expandedView?: boolean;
}

export function VideoProgressBar({ expandedView = false }: VideoProgressBarProps) {
	const { currentVideoTime, videoDuration, seekToVideoTime } = useVideoPlayerContext();
	const [hoverXTime, setHoverXTime] = useState<number>(0);
	const barElementRef = useRef<HTMLDivElement | null>(null);

	const videoProgressPercentage = Math.min(100, Math.max((currentVideoTime / videoDuration) * 100, 0));

	return (
		<HoverPopover
			floatingStrategy="absolute"
			disableFlip={true}
			enablePopoverHover={false}
			useFloatingPortal={false}
			clientPointAxis="x"
			placement="top"
		>
			<PopoverTrigger>
				<div
					className={clsx(`w-full cursor-pointer focus-visible:ring`, { "py-1": !expandedView })}
					role="slider"
					tabIndex={0}
					aria-label="video progress bar"
					aria-valuemax={100}
					aria-valuemin={0}
					aria-valuenow={videoProgressPercentage}
					aria-valuetext={formatVideoTimestamp(currentVideoTime)}
					onMouseMove={(e) => {
						if (!barElementRef.current) return;
						const { left, width } = barElementRef.current.getBoundingClientRect();
						const mouseX = Math.max(e.clientX - left, 0);
						const percentage = Math.min(Math.max(mouseX / width, 0), 1);

						setHoverXTime(percentage * videoDuration);
					}}
					onClick={(e) => {
						e.stopPropagation();
						seekToVideoTime(hoverXTime);
					}}
				>
					<div className="bg-c_bg_01 h-1 rounded-sm" ref={barElementRef}>
						<div
							style={{ width: `${Math.round(videoProgressPercentage)}%` }}
							className="bg-c_brand_primary h-1 rounded-sm"
						/>
					</div>
				</div>
			</PopoverTrigger>
			<PopoverContent>
				<div className="text-c_text_inverted bg-c_bg_tooltip text-label-xs shadow-tooltip pointer-events-none whitespace-pre-line rounded-sm p-1">
					{formatVideoTimestamp(hoverXTime)}
				</div>
			</PopoverContent>
		</HoverPopover>
	);
}
