import { Tab as HeadlessTab } from "@headlessui/react";
import { PropsWithChildren } from "react";

export function SidePanelTabPanel({ children }: PropsWithChildren) {
	return (
		<HeadlessTab.Panel className="w-full overflow-hidden" unmount={false}>
			{children}
		</HeadlessTab.Panel>
	);
}
