import clsx from "clsx";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { DataPreviewRow } from "./DataPreviewRow";

export function DataPreviewSkeleton() {
	return Array.from({ length: 4 }).map((_, index) => (
		<DataPreviewRow key={index} isFirstRow={index === 0}>
			<Skeleton className={clsx("h-6", index % 2 === 0 ? "w-1/3" : "w-1/2")} />
		</DataPreviewRow>
	));
}
