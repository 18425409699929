import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { DisplayFieldFactory, DisplayFieldVariant } from "../../../fields";
import { RecordPreviewField } from "./RecordPreviewField";
import { getDefaultDisplayFieldIds } from "../../utils/defaultDisplayFields";
import { getSDRecordNameFieldInfo, getRecordFieldInfo } from "../../utils/records";
import { PATHS } from "../../../../routes";
import { useGetRecordWithObjectByRecordId } from "../../../../hooks";
import { UserRecordAvatar } from "../../../users";
import { EventGraph } from "../../../events/components/EventGraph";
import { UserLoginStatus } from "../UserLastActive/UserLoginStatus";
import { RecordFollowingButtons, useGetLoginAuthorizationDetails } from "../..";
import { Icon, Button } from "@salesdesk/daisy-ui";
import { Accordion } from "../../../../components/Accordion";
import { RecordPreviewLoading } from "./RecordPreviewLoading";
import { UserAuthorisedLoginIcon } from "../../../users/components/UserAuthorisedLoginIcon";
import { useWebPrincipal } from "../../../../auth";
import { useInviteUser } from "../../../users";
import { RecordPreviewDMButtons } from "./RecordPreviewDMButtons";
import { RecordLogoPreview } from "./RecordLogoPreview";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { FIELD_TYPES } from "@salesdesk/salesdesk-model";

interface RecordPreviewPopoverProps {
	recordId: number;
	onClose?: () => void;
	showCloseButton?: boolean;
}

export function RecordPreviewContainer({ recordId, onClose, showCloseButton }: RecordPreviewPopoverProps) {
	const { sdObject, sdRecord } = useGetRecordWithObjectByRecordId(recordId);

	const principal = useWebPrincipal();
	const canViewEvents = principal.can(AbilityAction.Search, AbilitySubject.Event);
	const canViewRoles = principal.can(AbilityAction.View, AbilitySubject.Role);
	const canUseChimeChat = principal.can(AbilityAction.View, AbilitySubject.FullMeetingUI);

	const displayFieldIds = useMemo(() => {
		if (!sdObject) return [];
		return getDefaultDisplayFieldIds(sdObject, "PREVIEW");
	}, [sdObject]);

	const [primaryHeading, ...remainingFields] = useMemo(() => {
		if (!sdObject || !sdRecord) return [];
		return [getSDRecordNameFieldInfo(sdObject, sdRecord), ...getRecordFieldInfo(sdObject, sdRecord, displayFieldIds)];
	}, [sdObject, sdRecord, displayFieldIds]);

	const isLoading = !sdObject || !sdRecord;
	const viewDetailsUrl =
		!isLoading && principal.IsSalesDeskUser ? PATHS.RECORD_DETAIL_VIEW(sdRecord._id, sdObject) : "";

	const {
		isAuthorizedToLogIn: isRecordAuthorizedToLogin,
		isSalesDeskUser: isRecordSalesDeskUser,
		isUserRecord,
	} = useGetLoginAuthorizationDetails(sdObject, sdRecord);

	const graphUserFilter = useMemo(() => [recordId], [recordId]);

	const setUserRecordToInvite = useInviteUser(sdObject, sdRecord);

	return (
		<div
			className="m-3 flex w-full flex-col gap-3 overflow-hidden p-1"
			style={{ width: 414 }}
			onClick={(e) => e.stopPropagation()}
		>
			{isLoading ? (
				<RecordPreviewLoading isUserRecord={isUserRecord} />
			) : (
				<>
					<div className="flex w-full items-center justify-stretch gap-2">
						<div className="flex flex-grow items-center gap-2">
							<Icon icon={sdObject?._icon} size="sm" className="text-c_icon_disabled flex" />
							<div className="text-label-xs text-c_text_disabled">{sdObject?._displayName}</div>
						</div>
						{canViewEvents ? <UserLoginStatus userObject={sdObject} userRecord={sdRecord} /> : null}
						{onClose && showCloseButton ? (
							<Button startIcon={ICONS.cross} onClick={onClose} variant="text" />
						) : undefined}
					</div>
					<div className="flex gap-3">
						{isUserRecord ? <UserRecordAvatar userRecord={sdRecord} size="lg" /> : undefined}
						<RecordLogoPreview sdObject={sdObject} sdRecord={sdRecord} />
						<div className="flex w-full flex-col gap-2 truncate">
							{primaryHeading ? (
								<div className="text-label flex max-w-full items-center gap-2 text-start">
									<DisplayFieldFactory
										field={primaryHeading.field}
										value={primaryHeading.value}
										variant={DisplayFieldVariant.cardTitle}
									/>
									<UserAuthorisedLoginIcon sdRecord={sdRecord} />
								</div>
							) : null}
							{remainingFields.flatMap((fieldInfo) => {
								if (fieldInfo.field._name === FIELD_TYPES.ROLES.name && !canViewRoles) {
									return [];
								}
								return (
									<RecordPreviewField key={fieldInfo.field._id} field={fieldInfo.field}>
										<DisplayFieldFactory
											key={fieldInfo.field._id}
											field={fieldInfo.field}
											value={fieldInfo.value}
											variant={DisplayFieldVariant.card}
										/>
									</RecordPreviewField>
								);
							})}
						</div>
					</div>
					{isRecordSalesDeskUser && isRecordAuthorizedToLogin && canUseChimeChat ? (
						<RecordPreviewDMButtons recordId={sdRecord._id} onClose={onClose} />
					) : null}
					{(isRecordAuthorizedToLogin || isRecordSalesDeskUser) && canViewEvents ? (
						<Accordion title="Last 7 days activity" variant="secondary" defaultOpen>
							<EventGraph userFilter={graphUserFilter} lastSevenDays height={150} />
						</Accordion>
					) : null}
					{principal.IsSalesDeskUser ? (
						<div className="flex w-full items-center">
							{viewDetailsUrl ? (
								<Button as="link" variant="primary_text" size="sm" to={viewDetailsUrl}>
									View details
								</Button>
							) : null}
							<div className="ml-auto flex items-center">
								{setUserRecordToInvite ? (
									<div className="mr-2">
										<Button
											startIcon={ICONS.userCircle}
											size="sm"
											onClick={setUserRecordToInvite ? () => setUserRecordToInvite(sdRecord) : undefined}
										>
											Invite
										</Button>
									</div>
								) : null}
								<RecordFollowingButtons sdRecord={sdRecord} />
							</div>
						</div>
					) : null}
				</>
			)}
		</div>
	);
}
