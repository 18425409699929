import { PATHS, useStableNavigate } from "../../../routes";
import { isCompleteOrFailed } from "../utils";
import { useImportContext } from "./useImportContext";

export function useExitImportScreen() {
	const navigate = useStableNavigate();
	const { sdObject, sdImport, actualImportJob, setExitDialogOpen } = useImportContext();

	return () => {
		const isImporting = (sdImport || actualImportJob) && (!actualImportJob || !isCompleteOrFailed(actualImportJob));

		if (isImporting) {
			setExitDialogOpen(true);
		} else {
			navigate(PATHS.OBJECT_RECORD_BOARD(sdObject));
		}
	};
}
