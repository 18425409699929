import { createHashId, deepClone, isEmpty } from "@salesdesk/salesdesk-utils";

import {
	FIELD_CREATION_TYPE,
	FIELD_MULTIPLICITY,
	TemplateFieldType,
	mFieldDataChangeEvent,
	UniqueFieldType,
} from "./utils";
import { FieldComponentName } from "@salesdesk/salesdesk-ui";

export class FieldValidationError extends Error {
	constructor(message: string) {
		super(message);
	}
}

export class mFieldDef {
	static type = "mFieldDef";

	static icon = "fa-cube";

	_parent: any;
	_id: number;
	_name: string | null = null;
	_type: string;
	_pluralName: string | null;
	_displayName: string | null;
	_icon: string;
	_description: string;
	_toolTip: string | null;
	_componentType: any;
	_format: string | null;
	_formatDescription: string | null;
	_supportsDefaultValue: boolean;
	_defaultValue: any;
	_supportsUniqueValue: boolean;
	_searchable: boolean;
	_editable: boolean;
	_hidden: boolean;
	_cardViewEnabled: boolean;
	_tableViewEnabled: boolean;
	_previewEnabled: boolean;
	_multiplicity: number;
	_iconPhoto: boolean;
	_creationType: number;
	_filterTypes: string[];
	_listeners: Map<string, any[]>;
	_validation: any;
	_maxLength: number | null;
	_required: boolean;
	_unique: UniqueFieldType;
	templateType: TemplateFieldType;

	constructor(identifier: string | number) {
		if (typeof identifier === "string") {
			this._id = createHashId(identifier);
			this.name = identifier;
		} else {
			this._id = identifier;
			this._name = null;
		}
		this._parent = null;
		this._type = this.type; // Set the type by calling the type method of this field
		this._creationType = FIELD_CREATION_TYPE.SYSTEM;
		// TODO: Remove https://salesdesk101.atlassian.net/browse/SAL-2267
		this._filterTypes = [];
		this._pluralName = null; // Mandatory
		this._displayName = null; // Mandatory
		this._icon = mFieldDef.icon; // Default icon (subclasses should override)
		this._description = "";
		this._toolTip = null;
		this._componentType = null;
		this._format = null; // How the data should be formatted when presented
		this._formatDescription = null; // Description of formatting rules
		this._supportsDefaultValue = false; // Whether the field supports a default value
		this._defaultValue = null; // If this is set it's set as the value of the associated instance, once, when the instance is created
		this._supportsUniqueValue = false; // Whether the field supports unique values
		this._searchable = true; // Whether you can search for objects by this field
		this._editable = true; // Non-editable fields are always read-only
		this._hidden = false; // Whether the field is presented to the user or just accessible via the API
		this._cardViewEnabled = true; // Whether the field can be show in a card view
		this._tableViewEnabled = true; // Whether the field can be show in a table view
		this._previewEnabled = false; // Whether the field can be shown as a preview
		/** @type {number} */
		this._multiplicity = FIELD_MULTIPLICITY.SINGLE; // By default, one instance is allowed per field
		// This property is legacy and is not used anymore
		this._iconPhoto = false;

		// Validation fields
		this._maxLength = null;
		this._required = false;

		this._unique = UniqueFieldType.None;
		this._validation = null; // Validation handler (callback function that returns true / false and message)

		this._listeners = new Map();
		this.templateType = TemplateFieldType.None;
	}

	addEventListener(type: string, listener: any) {
		if (!this._listeners.has(type)) {
			this._listeners.set(type, []);
		}
		(this._listeners.get(type) as any[]).push(listener);
	}

	removeEventListener(listener: any) {
		const listenerGroups = this._listeners.values();
		let result = listenerGroups.next();

		while (!result.done) {
			const offset = result.value.indexOf(listener);

			if (offset >= 0) {
				result.value.splice(offset, 1);
			}
			result = listenerGroups.next();
		}
	}

	fireEventObject(eventObject: any) {
		if (this._listeners.has(eventObject.type)) {
			const listeners = this._listeners.get(eventObject.type);

			listeners?.forEach(function (listener) {
				listener(eventObject);
			});
		}
		if (this._listeners.has(mFieldDataChangeEvent.type.ALL)) {
			const listeners = this._listeners.get(mFieldDataChangeEvent.type.ALL);

			listeners?.forEach(function (listener) {
				listener(eventObject);
			});
		}
	}

	fireEvent(type: string, message: any, source: any) {
		this.fireEventObject(new mFieldDataChangeEvent(type, message, source));
	}

	get id() {
		return this._id;
	}

	set id(id) {
		this._id = id;
	}

	get parent() {
		return this._parent;
	}

	set parent(parent) {
		this._parent = parent;
	}

	hasParent() {
		return !isEmpty(this._parent);
	}

	getRoot() {
		let field = this as any;

		while (!isEmpty(field.parent) && field.parent instanceof mFieldDef) {
			field = field.parent;
		}

		return field;
	}

	// If there is an object that has been associated with this transaction,
	// it can be accessed from the surrounding object def.
	get context() {
		const root = this.getRoot();

		if (root.parent) {
			return root.parent.contextObject;
		}

		return null;
	}

	isRoot() {
		return this.getRoot() === this;
	}

	numOfAncestors() {
		let i = 0;
		for (let field = this.parent; field != null; field = field.parent) {
			i++;
		}
		return i;
	}

	// Only applies to subclasses of the container type

	hasChildren() {
		return false;
	}

	supportsChildren() {
		return false;
	}

	get type(): string {
		return mFieldDef.type;
	}

	get creationType() {
		return this._creationType;
	}

	set creationType(creationType) {
		this._creationType = creationType;
	}

	get filterTypes() {
		return this._filterTypes;
	}

	set filterTypes(filterTypes) {
		this._filterTypes = filterTypes;
	}

	isUserType() {
		return this._creationType == FIELD_CREATION_TYPE.USER;
	}

	isSystemType() {
		return this._creationType == FIELD_CREATION_TYPE.SYSTEM;
	}

	get name() {
		return this._name;
	}

	set name(name) {
		this._name = name;
	}

	hasName() {
		return !isEmpty(this._name);
	}

	get pluralName() {
		return this._pluralName;
	}

	set pluralName(pluralName) {
		this._pluralName = pluralName;
	}

	// Name must be unique. Display name does not need to be

	get displayName() {
		return this._displayName !== null ? this._displayName : this._name;
	}

	set displayName(displayName) {
		this._displayName = displayName;
	}

	get icon() {
		return this._icon;
	}

	set icon(icon) {
		this._icon = icon;
	}

	get description() {
		return this._description;
	}

	set description(description) {
		this._description = description;
	}

	get toolTip() {
		return this._toolTip;
	}

	set toolTip(toolTip) {
		this._toolTip = toolTip;
	}

	toolTipset() {
		return !isEmpty(this._toolTip);
	}

	get componentType() {
		return this._componentType;
	}

	set componentType(componentType: FieldComponentName) {
		if (!this.supportedComponentTypes.includes(componentType)) {
			throw new Error(`This field doesn't support the form type "${componentType}"`);
		}

		const before = this._componentType;

		const after = componentType;

		this._componentType = componentType;

		if (before != after) {
			this.fireEvent(
				mFieldDataChangeEvent.type.FIELD_COMPONENT_TYPE_UPDATED,
				{ before: before, after: after } as any,
				this
			);
		}
	}

	get format() {
		return this._format;
	}

	set format(format) {
		this._format = format;
	}

	supportsFormatDescription() {
		return true;
	}

	hasFormatDescription() {
		return !isEmpty(this._formatDescription);
	}

	get formatDescription() {
		return !isEmpty(this._formatDescription) ? this._formatDescription : "";
	}

	set formatDescription(formatDescription) {
		this._formatDescription = formatDescription;
	}

	get defaultValue() {
		return this._defaultValue;
	}

	set defaultValue(value) {
		if (!this.supportsDefaultValue()) {
			throw new Error(`Field ${this.name} does not support default values`);
		}

		this._defaultValue = this.formatAndValidate(value, undefined);
	}

	hasDefaultValue() {
		return !isEmpty(this._defaultValue);
	}

	supportsDefaultValue() {
		return this._supportsDefaultValue;
	}

	supportsUniqueValue() {
		return this._supportsUniqueValue;
	}

	get supportedComponentTypes() {
		return [] as FieldComponentName[];
	}

	supportsComponentType(formType: FieldComponentName) {
		return this.supportedComponentTypes.includes(formType);
	}

	supportsMaxLength() {
		return true;
	}

	get maxLength() {
		return this._maxLength;
	}

	set maxLength(maxLength) {
		this._maxLength = maxLength;
	}

	hasMaxLength() {
		return !isEmpty(this.maxLength);
	}

	get required() {
		return this._required;
	}

	set required(required) {
		this._required = required;
	}

	/** @type {UniqueFieldType} */
	get unique() {
		return this._unique;
	}

	set unique(/** @type {UniqueFieldType} */ unique) {
		this._unique = unique;
	}

	get editable() {
		return this._editable;
	}

	set editable(editable) {
		this._editable = editable;
	}

	get hidden() {
		return this._hidden;
	}

	set hidden(hidden) {
		this._hidden = hidden;
	}

	get cardViewEnabled() {
		return this._cardViewEnabled;
	}

	set cardViewEnabled(cardViewEnabled) {
		this._cardViewEnabled = cardViewEnabled;
	}

	get tableViewEnabled() {
		return this._tableViewEnabled;
	}

	set tableViewEnabled(tableViewEnabled) {
		this._tableViewEnabled = tableViewEnabled;
	}

	get previewEnabled() {
		return this._previewEnabled;
	}

	set previewEnabled(previewEnabled) {
		this._previewEnabled = previewEnabled;
	}

	get searchable() {
		return this._searchable;
	}

	set searchable(searchable) {
		this._searchable = searchable;
	}

	supportsSearch() {
		return true;
	}
	// This property is legacy and is not used anymore
	get iconPhoto() {
		return this._iconPhoto;
	}
	// This property is legacy and is not used anymore
	set iconPhoto(iconPhoto) {
		this._iconPhoto = iconPhoto;
	}
	// This property is legacy and is not used anymore
	supportsIconPhoto() {
		return false;
	}

	supportsDateOnly() {
		return false;
	}

	isContainer() {
		return false;
	}

	isOptionType() {
		return false;
	}

	isObjectType() {
		return false;
	}

	// Whether this field holds a reference to a stored file.
	// Default is false.

	isFileStore() {
		return false;
	}

	// Whether this field can be rendered as a data field in
	// a table cell

	supportsTableCellView() {
		return true;
	}

	showTableView() {
		return this.supportsTableCellView() && this._tableViewEnabled;
	}

	supportsCardView() {
		return true;
	}

	showCardView() {
		return this.supportsCardView() && this._cardViewEnabled;
	}

	supportsPreview() {
		return false;
	}

	showPreview() {
		return this.supportsPreview() && this._previewEnabled;
	}

	// Whether this field can be grouped by its field instances
	supportsGrouping() {
		return false;
	}

	// Whether this feel can be summed by its field instances
	supportsSummation() {
		return false;
	}

	// Whether this field loads value options from a storage system	(file, db, object store, etc);

	isBackingStore() {
		return false;
	}

	get multiplicity() {
		return this._multiplicity;
	}

	set multiplicity(multiplicity) {
		this._multiplicity = multiplicity;
	}

	supportsMultiple() {
		return (
			this._multiplicity === FIELD_MULTIPLICITY.SINGLE_MULTIPLE ||
			this._multiplicity === FIELD_MULTIPLICITY.ZERO_MULTIPLE
		);
	}

	supportsZero() {
		return (
			this._multiplicity === FIELD_MULTIPLICITY.ZERO_SINGLE || this._multiplicity === FIELD_MULTIPLICITY.ZERO_MULTIPLE
		);
	}

	supportsNoLessThanOne() {
		return (
			this._multiplicity === FIELD_MULTIPLICITY.SINGLE_MULTIPLE || this._multiplicity === FIELD_MULTIPLICITY.SINGLE
		);
	}

	supportsNoMoreThanOne() {
		return this._multiplicity === FIELD_MULTIPLICITY.SINGLE || this._multiplicity === FIELD_MULTIPLICITY.ZERO_SINGLE;
	}

	getParentIndex() {
		//Where this field sits in its parent's list of children

		let index = -1;

		if (this.parent) {
			const children = this.parent.children;

			for (let i = 0; children.length > i; i++) {
				if (children[i].id === this._id) {
					index = i;
				}
			}
		}
		return index;
	}

	move(toIndex: number) {
		// Change the position of this field relative to the parent's children

		const children = this.parent.children;

		if (toIndex > children.length - 1) {
			throw Error(`Index out of range - must be equal to or less than size of the children array (${children.length})`);
		}

		if (toIndex < 0) {
			throw Error(`Index out of range - must be zero of greater`);
		}

		children.splice(toIndex, 0, children.splice(this.index, 1)[0]);
	}

	remove() {
		if (!this.hasParent()) {
			throw Error(`Cannot remove as no parent exists`);
		}

		this.parent.removeChild(this);
	}

	/** start interface methods **/

	// Allows polymorphism in various UI libraries.
	// JS doesn't support interfaces ;-(

	get index() {
		return this.getParentIndex();
	}

	getAllInstances() {
		return [this];
	}

	getParentObjectByIndex(index: number) {
		const children = this.parent.children;

		if (index > children.length - 1 || index < 0) {
			throw Error(
				`index out of range - must be greater than zero and less than size of the children array (${children.length})`
			);
		}

		return this.parent.children[index];
	}

	getParentObjectsByType() {
		return this.parent.children;
	}

	// Formats the value given for the associated field and returns the value
	// correctly formatted.
	formatValue(value: any) {
		return value;
	}

	// This method validates the value given for the associated instance object.
	// If a field value is valid then undefined is returned, otherwise the appropriate
	// error message is returned.
	validate(value: any, isTemplate: boolean | undefined): string | undefined {
		if (isTemplate) {
			if (isEmpty(value)) {
				if (this.templateType === TemplateFieldType.Required) {
					return "Value is required.";
				}
			} else {
				if (this.templateType === TemplateFieldType.None) {
					return "This field is not templatable.";
				}
			}
			return undefined;
		}
		if (this.required && isEmpty(value)) {
			return `Value is required.`;
		}
		if (this.maxLength && value && value.toString().length > this.maxLength) {
			return `Maximum length is ${this.maxLength}.`;
		}
		return undefined;
	}

	formatAndValidate(value: any, isTemplate: boolean | undefined) {
		value = this.formatValue(value);
		const validationError = this.validate(value, isTemplate);

		if (validationError !== undefined) {
			throw new FieldValidationError(validationError);
		}

		return value;
	}

	// Validates the values in this class to check it can be stored and used
	validateFields() {
		if (isEmpty(this.id)) {
			throw new Error(`Id is not set in field "${this.name}"`);
		}
		if (isEmpty(this.type)) {
			throw new Error(`Field type is not set in field "${this.name}"`);
		}
		if (isEmpty(this.name)) {
			throw new Error(`Name is not set in field "${this.name}"`);
		}
		if (isEmpty(this.pluralName)) {
			throw new Error(`Plural name is not set in field "${this.name}"`);
		}
		if (isEmpty(this.displayName)) {
			throw new Error(`Display name is not set in field "${this.name}"`);
		}
		if (isEmpty(this.icon)) {
			throw new Error(`Icon is not set in field "${this.name}"`);
		}
	}

	isValid() {
		try {
			this.validateFields();
			return true;
		} catch (error) {
			return false;
		}
	}

	hasIconPhoto() {
		return false;
	}

	supportsActions() {
		return this.isUserType() && !this.isRoot() && !this.hasChildren();
	}

	// TODO: Remove in https://salesdesk101.atlassian.net/browse/SAL-2267
	getActions() {
		return [];
	}

	marshall(data: any, parent: any) {
		// Take the raw data object (from storage) and bind it to the fields in this JS class instance
		Object.assign(this, data);

		// Set the parent to enable programmatic tree navigation
		this.parent = parent;

		return this;
	}

	unmarshall(skipValidation = false) {
		// We need to make a copy of this field so that the original can
		// continue to be used at the application layer. We must delete
		// some fields before storing and that would render the original
		// field useless. Hence we make a copy and store data from that field.

		if (!skipValidation) {
			this.validateFields();
		}

		const clone = this.clone() as any;

		// Remove parent field before storing. We don't want to store loads of repeat
		// data about the parent object in JSON when it can be resolved when we call marshall()

		delete clone._parent;
		delete clone._listeners;
		delete clone._validation;
		delete clone._filterTypes;
		delete clone._format;
		delete clone._supportsDefaultValue;
		delete clone._supportsUniqueValue;

		return clone;
	}

	clone() {
		return deepClone(this);
	}

	toJson() {
		return JSON.stringify(
			this,
			function replacer(key, value) {
				// We don't to show _parent at it creates a recursion issue. Also, it's implied
				// by the semantics of the nested JSON structure.

				if (key === "_parent") {
					return undefined;
				}
				return value;
			},
			2
		);
	}
}
