import { PrivacyPolicyLink } from "./PrivacyPolicyLink";
import { TermsAndConditionsLink } from "./TermsAndConditionsLink";

export function PoweredBySDFooter() {
	return (
		<div className="flex flex-col items-center justify-center gap-4">
			<div className="flex gap-1">
				<a
					href="https://www.salesdesk.app/"
					target="_blank"
					rel="noreferrer"
					className="text-body text-c_text_primary underline"
				>
					Powered by
				</a>
				<img className="max-w-[97px]" src="/static/images/sd_logo_full.svg" alt="SalesDesk logo" />
			</div>
			<div className="text-c_text_placeholder flex gap-2">
				<TermsAndConditionsLink />
				<PrivacyPolicyLink />
			</div>
		</div>
	);
}
