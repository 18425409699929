import { forwardRef } from "react";

import { BaseSelectButtonProps } from "../../types";
import { Button, ButtonSize, ButtonVariant } from "@salesdesk/daisy-ui";

interface ButtonSelectButtonProps extends BaseSelectButtonProps {
	buttonVariant?: ButtonVariant;
	endIcon?: string;
	startIcon?: string;
	iconOnly?: boolean;
	size?: ButtonSize;
}

export const ButtonSelectButton = forwardRef<HTMLButtonElement, ButtonSelectButtonProps>(
	(
		{
			buttonVariant = "secondary",
			selectedOption,
			placeholder,
			startIcon,
			endIcon,
			iconOnly,
			size = "sm",
			...restProps
		},
		ref
	) => (
		<Button
			ref={ref}
			startIcon={startIcon ?? selectedOption?.icon}
			endIcon={endIcon}
			variant={buttonVariant}
			size={size}
			{...restProps}
		>
			{iconOnly ? null : (selectedOption?.name ?? placeholder)}
		</Button>
	)
);
