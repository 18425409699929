import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { RemoteParticipant, Room } from "twilio-video";

import { mUserDef } from "@salesdesk/salesdesk-model";
import {
	getSDRecordName,
	ParticipantIdentityUser,
	SDRecord,
	getSDRecordSystemFieldValueByFieldName,
} from "@salesdesk/salesdesk-schemas";

import { useUserObjectsMap } from "../../../../../../users";
import { useGetConferenceUsersQuery } from "../../../../../api/meetingsApi";
import { CallParticipantDetails } from "../../../types";

const EMPTY_PARTICIPANT_IDENTITY_USERS_ARRAY: ParticipantIdentityUser[] = [];

export function useCallParticipantDetails(room: Room | null, remoteParticipants: RemoteParticipant[]) {
	const userObjectMap = useUserObjectsMap();

	const [callParticipantDetails, setCallParticipantDetails] = useState<CallParticipantDetails[]>([]);

	const callParticipants = useMemo(() => {
		if (room?.localParticipant) {
			return [room?.localParticipant, ...remoteParticipants];
		}

		return remoteParticipants;
	}, [room, remoteParticipants]);

	const participantIdentities: string[] = useMemo(
		() => [...new Set(callParticipants.map((p) => p?.identity).filter((identity) => identity !== undefined)).values()],
		[callParticipants]
	);

	const { data: participantIdentityUsers = EMPTY_PARTICIPANT_IDENTITY_USERS_ARRAY } = useGetConferenceUsersQuery(
		callParticipants.length ? participantIdentities : skipToken
	);

	useEffect(() => {
		if (!userObjectMap) {
			return;
		}

		const userRecordMap = participantIdentityUsers.reduce((currentMap, participantIdentityUser) => {
			currentMap[participantIdentityUser.identity] = participantIdentityUser.userRecord;
			return currentMap;
		}, {} as Record<string, SDRecord | null>);

		const participantDetails: CallParticipantDetails[] = callParticipants.map((participant) => {
			const sdRecord = participant?.identity ? userRecordMap[participant.identity] || null : null;

			let avatarFileId: number | undefined;
			const participantSdObject = sdRecord ? userObjectMap.get(sdRecord._objectDefId) : null;

			let recordName;
			if (participantSdObject && sdRecord) {
				const value = getSDRecordSystemFieldValueByFieldName(participantSdObject, sdRecord, mUserDef.PHOTO_FIELD_NAME);
				avatarFileId = typeof value === "number" ? value : undefined;
				recordName = getSDRecordName(participantSdObject, sdRecord);
			}

			return {
				participant,
				sdRecord,
				name: recordName || participant.identity,
				avatarFileId,
				variant: "default",
			};
		});

		setCallParticipantDetails(participantDetails);
	}, [callParticipants, participantIdentityUsers, userObjectMap]);

	return callParticipantDetails;
}
