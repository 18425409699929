import { ICONS } from "@salesdesk/salesdesk-ui";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { ZOOM_FACTORS } from "../../../types";
import { Button } from "@salesdesk/daisy-ui";

export function ZoomButtons() {
	const { zoomIndex, setZoomIndex } = useMediaAnalysisContext();

	const zoomIn = () => {
		if (zoomIndex > 0) {
			setZoomIndex(zoomIndex - 1);
		}
	};

	const zoomOut = () => {
		if (zoomIndex < ZOOM_FACTORS.length - 1) {
			setZoomIndex(zoomIndex + 1);
		}
	};

	return (
		<div className="bg-c_bg_03 flex w-fit gap-2 rounded-full p-1">
			<Button startIcon={ICONS.zoomOut} variant="text" onClick={zoomIn} disabled={zoomIndex === 0} size="sm" />
			<Button
				startIcon={ICONS.zoomIn}
				variant="text"
				onClick={zoomOut}
				disabled={zoomIndex === ZOOM_FACTORS.length - 1}
				size="sm"
			/>
		</div>
	);
}
