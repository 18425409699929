export const CURRENCIES_DATA = [
	{
		symbol: "$",
		name: "US Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "USD",
		name_plural: "US dollars",
	},
	{
		symbol: "€",
		name: "Euro",
		icon: "€",
		decimal_digits: 2,
		rounding: 0,
		id: "EUR",
		name_plural: "euros",
	},
	{
		symbol: "£",
		name: "Pound Sterling",
		icon: "£",
		decimal_digits: 2,
		rounding: 0,
		id: "GBP",
		name_plural: "British pounds sterling",
	},
	{
		symbol: "CA$",
		name: "Canadian Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "CAD",
		name_plural: "Canadian dollars",
	},
	{
		symbol: "AU$",
		name: "Australian Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "AUD",
		name_plural: "Australian dollars",
	},
	{
		symbol: "AED",
		name: "UAE Dirham",
		icon: "د.إ.‏",
		decimal_digits: 2,
		rounding: 0,
		id: "AED",
		name_plural: "UAE dirhams",
	},
	{
		symbol: "Af",
		name: "Afghani",
		icon: "؋",
		decimal_digits: 0,
		rounding: 0,
		id: "AFN",
		name_plural: "Afghan Afghanis",
	},
	{
		symbol: "ALL",
		name: "Albanian Lek",
		icon: "Lek",
		decimal_digits: 0,
		rounding: 0,
		id: "ALL",
		name_plural: "Albanian lekë",
	},
	{
		symbol: "AMD",
		name: "Armenian Dram",
		icon: "դր.",
		decimal_digits: 0,
		rounding: 0,
		id: "AMD",
		name_plural: "Armenian drams",
	},
	{
		symbol: "AR$",
		name: "Argentine Peso",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "ARS",
		name_plural: "Argentine pesos",
	},
	{
		symbol: "man.",
		name: "Azerbaijani Manat",
		icon: "ман.",
		decimal_digits: 2,
		rounding: 0,
		id: "AZN",
		name_plural: "Azerbaijani manats",
	},
	{
		symbol: "KM",
		name: "Bosnia-Herzegovina Convertible Mark",
		icon: "KM",
		decimal_digits: 2,
		rounding: 0,
		id: "BAM",
		name_plural: "Bosnia-Herzegovina convertible marks",
	},
	{
		symbol: "Tk",
		name: "Bangladeshi Taka",
		icon: "৳",
		decimal_digits: 2,
		rounding: 0,
		id: "BDT",
		name_plural: "Bangladeshi takas",
	},
	{
		symbol: "BGN",
		name: "Bulgarian Lev",
		icon: "лв.",
		decimal_digits: 2,
		rounding: 0,
		id: "BGN",
		name_plural: "Bulgarian leva",
	},
	{
		symbol: "BD",
		name: "Bahraini Dinar",
		icon: "د.ب.‏",
		decimal_digits: 3,
		rounding: 0,
		id: "BHD",
		name_plural: "Bahraini dinars",
	},
	{
		symbol: "FBu",
		name: "Burundian Franc",
		icon: "FBu",
		decimal_digits: 0,
		rounding: 0,
		id: "BIF",
		name_plural: "Burundian francs",
	},
	{
		symbol: "BN$",
		name: "Brunei Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "BND",
		name_plural: "Brunei dollars",
	},
	{
		symbol: "Bs",
		name: "Bolivian Boliviano",
		icon: "Bs",
		decimal_digits: 2,
		rounding: 0,
		id: "BOB",
		name_plural: "Bolivian bolivianos",
	},
	{
		symbol: "R$",
		name: "Brazilian Real",
		icon: "R$",
		decimal_digits: 2,
		rounding: 0,
		id: "BRL",
		name_plural: "Brazilian reals",
	},
	{
		symbol: "BWP",
		name: "Botswanan Pula",
		icon: "P",
		decimal_digits: 2,
		rounding: 0,
		id: "BWP",
		name_plural: "Botswanan pulas",
	},
	{
		symbol: "Br",
		name: "Belarusian Ruble",
		icon: "руб.",
		decimal_digits: 2,
		rounding: 0,
		id: "BYN",
		name_plural: "Belarusian rubles",
	},
	{
		symbol: "BZ$",
		name: "Belize Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "BZD",
		name_plural: "Belize dollars",
	},
	{
		symbol: "CDF",
		name: "Congolese Franc",
		icon: "FrCD",
		decimal_digits: 2,
		rounding: 0,
		id: "CDF",
		name_plural: "Congolese francs",
	},
	{
		symbol: "CHF",
		name: "Swiss Franc",
		icon: "CHF",
		decimal_digits: 2,
		rounding: 0.05,
		id: "CHF",
		name_plural: "Swiss francs",
	},
	{
		symbol: "CL$",
		name: "Chilean Peso",
		icon: "$",
		decimal_digits: 0,
		rounding: 0,
		id: "CLP",
		name_plural: "Chilean pesos",
	},
	{
		symbol: "CN¥",
		name: "Chinese Yuan",
		icon: "CN¥",
		decimal_digits: 2,
		rounding: 0,
		id: "CNY",
		name_plural: "Chinese yuan",
	},
	{
		symbol: "CO$",
		name: "Colombian Peso",
		icon: "$",
		decimal_digits: 0,
		rounding: 0,
		id: "COP",
		name_plural: "Colombian pesos",
	},
	{
		symbol: "₡",
		name: "Costa Rican Colón",
		icon: "₡",
		decimal_digits: 0,
		rounding: 0,
		id: "CRC",
		name_plural: "Costa Rican colóns",
	},
	{
		symbol: "CV$",
		name: "Cape Verdean Escudo",
		icon: "CV$",
		decimal_digits: 2,
		rounding: 0,
		id: "CVE",
		name_plural: "Cape Verdean escudos",
	},
	{
		symbol: "Kč",
		name: "Czech Republic Koruna",
		icon: "Kč",
		decimal_digits: 2,
		rounding: 0,
		id: "CZK",
		name_plural: "Czech Republic korunas",
	},
	{
		symbol: "Fdj",
		name: "Djiboutian Franc",
		icon: "Fdj",
		decimal_digits: 0,
		rounding: 0,
		id: "DJF",
		name_plural: "Djiboutian francs",
	},
	{
		symbol: "Dkr",
		name: "Danish Krone",
		icon: "kr",
		decimal_digits: 2,
		rounding: 0,
		id: "DKK",
		name_plural: "Danish kroner",
	},
	{
		symbol: "RD$",
		name: "Dominican Peso",
		icon: "RD$",
		decimal_digits: 2,
		rounding: 0,
		id: "DOP",
		name_plural: "Dominican pesos",
	},
	{
		symbol: "DA",
		name: "Algerian Dinar",
		icon: "د.ج.‏",
		decimal_digits: 2,
		rounding: 0,
		id: "DZD",
		name_plural: "Algerian dinars",
	},
	{
		symbol: "Ekr",
		name: "Estonian Kroon",
		icon: "kr",
		decimal_digits: 2,
		rounding: 0,
		id: "EEK",
		name_plural: "Estonian kroons",
	},
	{
		symbol: "EGP",
		name: "Egyptian Pound",
		icon: "ج.م.‏",
		decimal_digits: 2,
		rounding: 0,
		id: "EGP",
		name_plural: "Egyptian pounds",
	},
	{
		symbol: "Nfk",
		name: "Eritrean Nakfa",
		icon: "Nfk",
		decimal_digits: 2,
		rounding: 0,
		id: "ERN",
		name_plural: "Eritrean nakfas",
	},
	{
		symbol: "Br",
		name: "Ethiopian Birr",
		icon: "Br",
		decimal_digits: 2,
		rounding: 0,
		id: "ETB",
		name_plural: "Ethiopian birrs",
	},
	{
		symbol: "GEL",
		name: "Georgian Lari",
		icon: "GEL",
		decimal_digits: 2,
		rounding: 0,
		id: "GEL",
		name_plural: "Georgian laris",
	},
	{
		symbol: "GH₵",
		name: "Ghanaian Cedi",
		icon: "GH₵",
		decimal_digits: 2,
		rounding: 0,
		id: "GHS",
		name_plural: "Ghanaian cedis",
	},
	{
		symbol: "FG",
		name: "Guinean Franc",
		icon: "FG",
		decimal_digits: 0,
		rounding: 0,
		id: "GNF",
		name_plural: "Guinean francs",
	},
	{
		symbol: "GTQ",
		name: "Guatemalan Quetzal",
		icon: "Q",
		decimal_digits: 2,
		rounding: 0,
		id: "GTQ",
		name_plural: "Guatemalan quetzals",
	},
	{
		symbol: "HK$",
		name: "Hong Kong Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "HKD",
		name_plural: "Hong Kong dollars",
	},
	{
		symbol: "HNL",
		name: "Honduran Lempira",
		icon: "L",
		decimal_digits: 2,
		rounding: 0,
		id: "HNL",
		name_plural: "Honduran lempiras",
	},
	{
		symbol: "kn",
		name: "Croatian Kuna",
		icon: "kn",
		decimal_digits: 2,
		rounding: 0,
		id: "HRK",
		name_plural: "Croatian kunas",
	},
	{
		symbol: "Ft",
		name: "Hungarian Forint",
		icon: "Ft",
		decimal_digits: 0,
		rounding: 0,
		id: "HUF",
		name_plural: "Hungarian forints",
	},
	{
		symbol: "Rp",
		name: "Indonesian Rupiah",
		icon: "Rp",
		decimal_digits: 0,
		rounding: 0,
		id: "IDR",
		name_plural: "Indonesian rupiahs",
	},
	{
		symbol: "₪",
		name: "Israeli New Sheqel",
		icon: "₪",
		decimal_digits: 2,
		rounding: 0,
		id: "ILS",
		name_plural: "Israeli new sheqels",
	},
	{
		symbol: "Rs",
		name: "Indian Rupee",
		icon: "টকা",
		decimal_digits: 2,
		rounding: 0,
		id: "INR",
		name_plural: "Indian rupees",
	},
	{
		symbol: "IQD",
		name: "Iraqi Dinar",
		icon: "د.ع.‏",
		decimal_digits: 0,
		rounding: 0,
		id: "IQD",
		name_plural: "Iraqi dinars",
	},
	{
		symbol: "IRR",
		name: "Iranian Rial",
		icon: "﷼",
		decimal_digits: 0,
		rounding: 0,
		id: "IRR",
		name_plural: "Iranian rials",
	},
	{
		symbol: "Ikr",
		name: "Icelandic Króna",
		icon: "kr",
		decimal_digits: 0,
		rounding: 0,
		id: "ISK",
		name_plural: "Icelandic krónur",
	},
	{
		symbol: "J$",
		name: "Jamaican Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "JMD",
		name_plural: "Jamaican dollars",
	},
	{
		symbol: "JD",
		name: "Jordanian Dinar",
		icon: "د.أ.‏",
		decimal_digits: 3,
		rounding: 0,
		id: "JOD",
		name_plural: "Jordanian dinars",
	},
	{
		symbol: "¥",
		name: "Japanese Yen",
		icon: "￥",
		decimal_digits: 0,
		rounding: 0,
		id: "JPY",
		name_plural: "Japanese yen",
	},
	{
		symbol: "Ksh",
		name: "Kenyan Shilling",
		icon: "Ksh",
		decimal_digits: 2,
		rounding: 0,
		id: "KES",
		name_plural: "Kenyan shillings",
	},
	{
		symbol: "KHR",
		name: "Cambodian Riel",
		icon: "៛",
		decimal_digits: 2,
		rounding: 0,
		id: "KHR",
		name_plural: "Cambodian riels",
	},
	{
		symbol: "CF",
		name: "Comorian Franc",
		icon: "FC",
		decimal_digits: 0,
		rounding: 0,
		id: "KMF",
		name_plural: "Comorian francs",
	},
	{
		symbol: "₩",
		name: "South Korean Won",
		icon: "₩",
		decimal_digits: 0,
		rounding: 0,
		id: "KRW",
		name_plural: "South Korean won",
	},
	{
		symbol: "KD",
		name: "Kuwaiti Dinar",
		icon: "د.ك.‏",
		decimal_digits: 3,
		rounding: 0,
		id: "KWD",
		name_plural: "Kuwaiti dinars",
	},
	{
		symbol: "KZT",
		name: "Kazakhstani Tenge",
		icon: "тңг.",
		decimal_digits: 2,
		rounding: 0,
		id: "KZT",
		name_plural: "Kazakhstani tenges",
	},
	{
		symbol: "LB£",
		name: "Lebanese Pound",
		icon: "ل.ل.‏",
		decimal_digits: 0,
		rounding: 0,
		id: "LBP",
		name_plural: "Lebanese pounds",
	},
	{
		symbol: "SLRs",
		name: "Sri Lankan Rupee",
		icon: "SL Re",
		decimal_digits: 2,
		rounding: 0,
		id: "LKR",
		name_plural: "Sri Lankan rupees",
	},
	{
		symbol: "Lt",
		name: "Lithuanian Litas",
		icon: "Lt",
		decimal_digits: 2,
		rounding: 0,
		id: "LTL",
		name_plural: "Lithuanian litai",
	},
	{
		symbol: "Ls",
		name: "Latvian Lats",
		icon: "Ls",
		decimal_digits: 2,
		rounding: 0,
		id: "LVL",
		name_plural: "Latvian lati",
	},
	{
		symbol: "LD",
		name: "Libyan Dinar",
		icon: "د.ل.‏",
		decimal_digits: 3,
		rounding: 0,
		id: "LYD",
		name_plural: "Libyan dinars",
	},
	{
		symbol: "MAD",
		name: "Moroccan Dirham",
		icon: "د.م.‏",
		decimal_digits: 2,
		rounding: 0,
		id: "MAD",
		name_plural: "Moroccan dirhams",
	},
	{
		symbol: "MDL",
		name: "Moldovan Leu",
		icon: "MDL",
		decimal_digits: 2,
		rounding: 0,
		id: "MDL",
		name_plural: "Moldovan lei",
	},
	{
		symbol: "MGA",
		name: "Malagasy Ariary",
		icon: "MGA",
		decimal_digits: 0,
		rounding: 0,
		id: "MGA",
		name_plural: "Malagasy Ariaries",
	},
	{
		symbol: "MKD",
		name: "Macedonian Denar",
		icon: "MKD",
		decimal_digits: 2,
		rounding: 0,
		id: "MKD",
		name_plural: "Macedonian denari",
	},
	{
		symbol: "MMK",
		name: "Myanma Kyat",
		icon: "K",
		decimal_digits: 0,
		rounding: 0,
		id: "MMK",
		name_plural: "Myanma kyats",
	},
	{
		symbol: "MOP$",
		name: "Macanese Pataca",
		icon: "MOP$",
		decimal_digits: 2,
		rounding: 0,
		id: "MOP",
		name_plural: "Macanese patacas",
	},
	{
		symbol: "MURs",
		name: "Mauritian Rupee",
		icon: "MURs",
		decimal_digits: 0,
		rounding: 0,
		id: "MUR",
		name_plural: "Mauritian rupees",
	},
	{
		symbol: "MX$",
		name: "Mexican Peso",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "MXN",
		name_plural: "Mexican pesos",
	},
	{
		symbol: "RM",
		name: "Malaysian Ringgit",
		icon: "RM",
		decimal_digits: 2,
		rounding: 0,
		id: "MYR",
		name_plural: "Malaysian ringgits",
	},
	{
		symbol: "MTn",
		name: "Mozambican Metical",
		icon: "MTn",
		decimal_digits: 2,
		rounding: 0,
		id: "MZN",
		name_plural: "Mozambican meticals",
	},
	{
		symbol: "N$",
		name: "Namibian Dollar",
		icon: "N$",
		decimal_digits: 2,
		rounding: 0,
		id: "NAD",
		name_plural: "Namibian dollars",
	},
	{
		symbol: "₦",
		name: "Nigerian Naira",
		icon: "₦",
		decimal_digits: 2,
		rounding: 0,
		id: "NGN",
		name_plural: "Nigerian nairas",
	},
	{
		symbol: "C$",
		name: "Nicaraguan Córdoba",
		icon: "C$",
		decimal_digits: 2,
		rounding: 0,
		id: "NIO",
		name_plural: "Nicaraguan córdobas",
	},
	{
		symbol: "Nkr",
		name: "Norwegian Krone",
		icon: "kr",
		decimal_digits: 2,
		rounding: 0,
		id: "NOK",
		name_plural: "Norwegian kroner",
	},
	{
		symbol: "NPRs",
		name: "Nepalese Rupee",
		icon: "नेरू",
		decimal_digits: 2,
		rounding: 0,
		id: "NPR",
		name_plural: "Nepalese rupees",
	},
	{
		symbol: "NZ$",
		name: "New Zealand Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "NZD",
		name_plural: "New Zealand dollars",
	},
	{
		symbol: "OMR",
		name: "Omani Rial",
		icon: "ر.ع.‏",
		decimal_digits: 3,
		rounding: 0,
		id: "OMR",
		name_plural: "Omani rials",
	},
	{
		symbol: "B/.",
		name: "Panamanian Balboa",
		icon: "B/.",
		decimal_digits: 2,
		rounding: 0,
		id: "PAB",
		name_plural: "Panamanian balboas",
	},
	{
		symbol: "S/.",
		name: "Peruvian Nuevo Sol",
		icon: "S/.",
		decimal_digits: 2,
		rounding: 0,
		id: "PEN",
		name_plural: "Peruvian nuevos soles",
	},
	{
		symbol: "₱",
		name: "Philippine Peso",
		icon: "₱",
		decimal_digits: 2,
		rounding: 0,
		id: "PHP",
		name_plural: "Philippine pesos",
	},
	{
		symbol: "PKRs",
		name: "Pakistani Rupee",
		icon: "₨",
		decimal_digits: 0,
		rounding: 0,
		id: "PKR",
		name_plural: "Pakistani rupees",
	},
	{
		symbol: "zł",
		name: "Polish Zloty",
		icon: "zł",
		decimal_digits: 2,
		rounding: 0,
		id: "PLN",
		name_plural: "Polish zlotys",
	},
	{
		symbol: "₲",
		name: "Paraguayan Guarani",
		icon: "₲",
		decimal_digits: 0,
		rounding: 0,
		id: "PYG",
		name_plural: "Paraguayan guaranis",
	},
	{
		symbol: "QR",
		name: "Qatari Rial",
		icon: "ر.ق.‏",
		decimal_digits: 2,
		rounding: 0,
		id: "QAR",
		name_plural: "Qatari rials",
	},
	{
		symbol: "RON",
		name: "Romanian Leu",
		icon: "RON",
		decimal_digits: 2,
		rounding: 0,
		id: "RON",
		name_plural: "Romanian lei",
	},
	{
		symbol: "din.",
		name: "Serbian Dinar",
		icon: "дин.",
		decimal_digits: 0,
		rounding: 0,
		id: "RSD",
		name_plural: "Serbian dinars",
	},
	{
		symbol: "RUB",
		name: "Russian Ruble",
		icon: "₽.",
		decimal_digits: 2,
		rounding: 0,
		id: "RUB",
		name_plural: "Russian rubles",
	},
	{
		symbol: "RWF",
		name: "Rwandan Franc",
		icon: "FR",
		decimal_digits: 0,
		rounding: 0,
		id: "RWF",
		name_plural: "Rwandan francs",
	},
	{
		symbol: "SR",
		name: "Saudi Riyal",
		icon: "ر.س.‏",
		decimal_digits: 2,
		rounding: 0,
		id: "SAR",
		name_plural: "Saudi riyals",
	},
	{
		symbol: "SDG",
		name: "Sudanese Pound",
		icon: "SDG",
		decimal_digits: 2,
		rounding: 0,
		id: "SDG",
		name_plural: "Sudanese pounds",
	},
	{
		symbol: "Skr",
		name: "Swedish Krona",
		icon: "kr",
		decimal_digits: 2,
		rounding: 0,
		id: "SEK",
		name_plural: "Swedish kronor",
	},
	{
		symbol: "S$",
		name: "Singapore Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "SGD",
		name_plural: "Singapore dollars",
	},
	{
		symbol: "Ssh",
		name: "Somali Shilling",
		icon: "Ssh",
		decimal_digits: 0,
		rounding: 0,
		id: "SOS",
		name_plural: "Somali shillings",
	},
	{
		symbol: "SY£",
		name: "Syrian Pound",
		icon: "ل.س.‏",
		decimal_digits: 0,
		rounding: 0,
		id: "SYP",
		name_plural: "Syrian pounds",
	},
	{
		symbol: "฿",
		name: "Thai Baht",
		icon: "฿",
		decimal_digits: 2,
		rounding: 0,
		id: "THB",
		name_plural: "Thai baht",
	},
	{
		symbol: "DT",
		name: "Tunisian Dinar",
		icon: "د.ت.‏",
		decimal_digits: 3,
		rounding: 0,
		id: "TND",
		name_plural: "Tunisian dinars",
	},
	{
		symbol: "T$",
		name: "Tongan Paʻanga",
		icon: "T$",
		decimal_digits: 2,
		rounding: 0,
		id: "TOP",
		name_plural: "Tongan paʻanga",
	},
	{
		symbol: "TL",
		name: "Turkish Lira",
		icon: "TL",
		decimal_digits: 2,
		rounding: 0,
		id: "TRY",
		name_plural: "Turkish Lira",
	},
	{
		symbol: "TT$",
		name: "Trinidad and Tobago Dollar",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "TTD",
		name_plural: "Trinidad and Tobago dollars",
	},
	{
		symbol: "NT$",
		name: "New Taiwan Dollar",
		icon: "NT$",
		decimal_digits: 2,
		rounding: 0,
		id: "TWD",
		name_plural: "New Taiwan dollars",
	},
	{
		symbol: "TSh",
		name: "Tanzanian Shilling",
		icon: "TSh",
		decimal_digits: 0,
		rounding: 0,
		id: "TZS",
		name_plural: "Tanzanian shillings",
	},
	{
		symbol: "₴",
		name: "Ukrainian Hryvnia",
		icon: "₴",
		decimal_digits: 2,
		rounding: 0,
		id: "UAH",
		name_plural: "Ukrainian hryvnias",
	},
	{
		symbol: "USh",
		name: "Ugandan Shilling",
		icon: "USh",
		decimal_digits: 0,
		rounding: 0,
		id: "UGX",
		name_plural: "Ugandan shillings",
	},
	{
		symbol: "$U",
		name: "Uruguayan Peso",
		icon: "$",
		decimal_digits: 2,
		rounding: 0,
		id: "UYU",
		name_plural: "Uruguayan pesos",
	},
	{
		symbol: "UZS",
		name: "Uzbekistan Som",
		icon: "UZS",
		decimal_digits: 0,
		rounding: 0,
		id: "UZS",
		name_plural: "Uzbekistan som",
	},
	{
		symbol: "Bs.F.",
		name: "Venezuelan Bolívar",
		icon: "Bs.F.",
		decimal_digits: 2,
		rounding: 0,
		id: "VEF",
		name_plural: "Venezuelan bolívars",
	},
	{
		symbol: "₫",
		name: "Vietnamese Dong",
		icon: "₫",
		decimal_digits: 0,
		rounding: 0,
		id: "VND",
		name_plural: "Vietnamese dong",
	},
	{
		symbol: "FCFA",
		name: "CFA Franc BEAC",
		icon: "FCFA",
		decimal_digits: 0,
		rounding: 0,
		id: "XAF",
		name_plural: "CFA francs BEAC",
	},
	{
		symbol: "CFA",
		name: "CFA Franc BCEAO",
		icon: "CFA",
		decimal_digits: 0,
		rounding: 0,
		id: "XOF",
		name_plural: "CFA francs BCEAO",
	},
	{
		symbol: "YR",
		name: "Yemeni Rial",
		icon: "ر.ي.‏",
		decimal_digits: 0,
		rounding: 0,
		id: "YER",
		name_plural: "Yemeni rials",
	},
	{
		symbol: "R",
		name: "South African Rand",
		icon: "R",
		decimal_digits: 2,
		rounding: 0,
		id: "ZAR",
		name_plural: "South African rand",
	},
	{
		symbol: "ZK",
		name: "Zambian Kwacha",
		icon: "ZK",
		decimal_digits: 0,
		rounding: 0,
		id: "ZMK",
		name_plural: "Zambian kwachas",
	},
	{
		symbol: "ZWL$",
		name: "Zimbabwean Dollar",
		icon: "ZWL$",
		decimal_digits: 0,
		rounding: 0,
		id: "ZWL",
		name_plural: "Zimbabwean Dollar",
	},
];
