import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";

import { Field } from "@salesdesk/salesdesk-schemas";
import { mFieldDef } from "@salesdesk/salesdesk-model";
import { ThemeName, UI_THEMES } from "@salesdesk/salesdesk-ui";

import { getFormValuesByPrefix, getFullRecordFormFieldId, RECORD_FIELDS_KEY } from "../../../../../records";
import { SelectOption } from "../../../../../inputs";
import { convertFieldToFieldDef } from "../../../../../fields";
import { ThemeSelector } from "./ThemeSelector";
import { useWorkspaceContext } from "../../../../../workspaces";

interface ThemeSelectorProps {
	themeField: Field;
	setThemeToPreview?: (themeName: ThemeName) => void;
}

const EMPTY_THEME_OPTIONS: SelectOption[] = [];

export function ThemeSelectorField({ themeField, setThemeToPreview }: ThemeSelectorProps) {
	const { control, watch } = useFormContext();
	const fieldValues = watch();

	const { fieldDef } = useMemo(
		() => ({
			fieldDefId: String(themeField._id),
			fieldDef: convertFieldToFieldDef(themeField) as mFieldDef,
		}),
		[themeField]
	);

	const fieldName = useMemo(
		() => getFullRecordFormFieldId(RECORD_FIELDS_KEY, String(themeField._id)),
		[themeField._id]
	);

	const selectedThemeOption = useMemo(
		() => getFormValuesByPrefix(fieldValues, RECORD_FIELDS_KEY)[themeField._id],
		[fieldValues, themeField._id]
	);

	useEffect(() => {
		if (!setThemeToPreview) {
			return;
		}
		if (selectedThemeOption == null) {
			setThemeToPreview("grey");
			return;
		}

		const themeOptionName = themeField._optionValues?.find((option) => option.id === selectedThemeOption)?.name;

		if (themeOptionName && themeOptionName.toLowerCase() in UI_THEMES) {
			setThemeToPreview(themeOptionName.toLowerCase() as ThemeName);
			return;
		}

		setThemeToPreview("grey");
	}, [selectedThemeOption, setThemeToPreview, themeField._id, themeField._optionValues]);

	const options = (themeField._optionValues as SelectOption[] | undefined) ?? EMPTY_THEME_OPTIONS;

	const { workspaceRecord } = useWorkspaceContext();

	return (
		<div key={fieldName} className="mb-5 max-w-[440px]">
			<Controller
				name={fieldName}
				control={control}
				render={({ field: { onChange, value } }) => {
					return <ThemeSelector options={options} value={value} onChange={onChange} />;
				}}
				rules={{ validate: (value) => fieldDef.validate(value, workspaceRecord?.isTemplate) }}
			/>
		</div>
	);
}
