import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { SkeletonTransition } from "../../../../../../../components/Skeleton/SkeletonTransition";

export function RecordDetailsFieldsLoading() {
	return (
		<div className="w-full py-4 pr-5">
			<SkeletonTransition>
				<div className="flex flex-col gap-6">
					<Skeleton className="h-6 w-full" />
					<Skeleton className="h-6 w-2/3" />
					{[...Array(7)].map((_, index) => (
						<div key={index} className="flex w-5/6 gap-6">
							<Skeleton className="h-6 basis-1/4" />
							<Skeleton className="h-6 basis-3/4" />
						</div>
					))}
				</div>
			</SkeletonTransition>
		</div>
	);
}
