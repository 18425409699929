import { DPDay, useContextDaysPropGetters } from "@rehookify/datepicker";
import clsx from "clsx";
import { tw } from "../../../../../utils/tailwind-helpers";
import { format } from "date-fns";

interface DateCellProps {
	value: DPDay;
	isMarked?: boolean;
}

export const DateCell = ({ value, isMarked }: DateCellProps) => {
	const { dayButton } = useContextDaysPropGetters();
	const { selected, disabled, inCurrentMonth, now, range } = value;

	return (
		<div
			className={clsx(
				"relative px-1",
				// These classes are added by the 'range' property
				"[&:has(.range-start,.will-be-range-start)]:rounded-l-full",
				"[&:has(.range-end,.will-be-range-end)]:rounded-r-full",
				"[&:has(.range-start,.will-be-range-start)]:bg-gradient-to-l",
				"[&:has(.range-end,.will-be-range-end)]:bg-gradient-to-r",
				"[&:has(.range-start):not(:has(.range-end))]:from-c_bg_05",
				"[&:has(.range-end):not(:has(.range-start))]:from-c_bg_05",
				"[&:has(.will-be-range-start,.will-be-range-end)]:from-c_bg_03",
				"[&:has(.in-range)]:bg-c_bg_05",
				"[&:has(.will-be-in-range)]:bg-c_bg_03"
			)}
		>
			<button
				className={clsx("text-label-nav p-y-1.5 p-x-2 h-8 w-8 rounded-full focus-visible:ring", range, {
					[clsx(tw`text-c_text_primary hover:bg-c_bg_03`, selected && tw`bg-c_bg_02`)]: inCurrentMonth && !now,
					[clsx(tw`text-c_text_disabled hover:bg-c_bg_03`, selected && tw`bg-c_bg_03`)]: !inCurrentMonth,
					[clsx(tw`text-c_text_inverted bg-c_action_01 hover:bg-c_action_02`, selected && tw`bg-c_action_03`)]: now,
					"cursor-not-allowed opacity-50": disabled,
				})}
				{...dayButton(value)}
			>
				{format(value.$date, "d")}
			</button>
			{isMarked !== undefined ? (
				<div className="absolute mt-0.5 flex h-1.5 w-8 justify-center">
					{isMarked ? <div className="bg-c_action_02 size-1.5 rounded-full" /> : null}
				</div>
			) : null}
		</div>
	);
};
