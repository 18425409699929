import { useImportContext } from "../../../hooks/useImportContext";
import { ColumnMappingSkeletonRow } from "./ColumnMappingSkeletonRow";
import { ColumnMappingHeaderRow } from "./ColumnMappingHeaderRow";
import { ColumnMappingRowController } from "./ColumnMappingRowController";

interface ColumnMappingTableProps {
	requiredFieldCount: number;
	selectedRequiredFieldCount: number;
}

export function ColumnMappingTable({ requiredFieldCount, selectedRequiredFieldCount }: ColumnMappingTableProps) {
	const { importMappings } = useImportContext();
	return (
		<div className="flex max-h-full w-3/5 shrink-0 flex-col">
			<ColumnMappingHeaderRow
				requiredFieldCount={requiredFieldCount}
				selectedRequiredFieldCount={selectedRequiredFieldCount}
			/>
			<div className="shrink overflow-y-scroll">
				{importMappings === undefined
					? Array.from({ length: 4 }).map((_, index) => <ColumnMappingSkeletonRow key={index} />)
					: importMappings.map((mapping) => <ColumnMappingRowController key={mapping.columnIndex} mapping={mapping} />)}
			</div>
		</div>
	);
}
