import { useCallback } from "react";

import { createUniqueId } from "@salesdesk/salesdesk-utils";

import { triggerEvent, dismissEventById, deleteEventById } from "../store/videoCallEventsSlice";
import { VIDEO_CALL_EVENT_TOAST_OUT_ANIMATION_DELAY, VIDEO_CALL_EVENT_TIMER, VideoCallEventType } from "../types";
import { store } from "../../../../../store";

export function useTriggerVideoCallEvent() {
	return useCallback((type: VideoCallEventType, participantName?: string) => {
		const eventId = createUniqueId();
		store.dispatch(
			triggerEvent({
				id: eventId,
				type,
				participantName,
				timeoutId: setTimeout(() => {
					store.dispatch(dismissEventById(eventId));

					setTimeout(() => {
						store.dispatch(deleteEventById(eventId));
					}, VIDEO_CALL_EVENT_TOAST_OUT_ANIMATION_DELAY);
				}, VIDEO_CALL_EVENT_TIMER),
			})
		);
	}, []);
}
