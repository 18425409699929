import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { InlineUserLoading } from "../../users";

export function NotesLoading() {
	return (
		<div className="@container/loading-notes h-full overflow-hidden">
			<div className="@xl/loading-notes:flex-row @xl/loading-notes:gap-5 flex w-full flex-col justify-stretch gap-4">
				<div className="flex grow flex-col gap-4">
					{[...Array(3)].map((_, index) => (
						<div key={index} className="flex flex-col gap-1 pt-0.5">
							<InlineUserLoading />
							<div className="flex flex-col gap-2 pl-8">
								<Skeleton className="h-6 w-10/12" />
								<Skeleton className="h-6 w-2/3" />
							</div>
						</div>
					))}
				</div>
				{/* Placeholder for the create note column in the record detail view */}
				<div className="@xl/loading-notes:basis-1/2 @xl/loading-notes:shrink-0 @xl/loading-notes:max-w-md" />
			</div>
		</div>
	);
}
