import { useMemo } from "react";

import { useGetRecordWithObjectByRecordId } from "../../../../../../../../../../hooks";

import { getDefaultDisplayFieldIds } from "../../../../../../../../utils/defaultDisplayFields";
import { SkeletonRecordAssociationCard } from "../../../../../../../../../recordAssociations";
import { getCurrentPathWithRecordIdParam } from "../../../../../RecordSidePanel";
import { FieldIdRecordCard } from "../../../../../../../RecordCard";
import { useGetContextWorkspaceId } from "../../../../../../../../../workspaces";
interface MeetingMinutesProps {
	minutesRecordId: number;
}

export function MeetingMinutes({ minutesRecordId }: MeetingMinutesProps) {
	const { sdObject: docObject, sdRecord: docRecord, isLoading } = useGetRecordWithObjectByRecordId(minutesRecordId);

	const fieldIdsToDisplay = useMemo(() => {
		if (!docObject) {
			return [];
		}
		return getDefaultDisplayFieldIds(docObject, "ASSOCIATION");
	}, [docObject]);

	const workspaceId = useGetContextWorkspaceId();

	if (
		!isLoading &&
		(!docObject || !docRecord || (workspaceId && !docRecord.sharedWorkspaceIds.includes(workspaceId)))
	) {
		return null;
	}

	return (
		<div className="flex flex-col gap-3">
			<div className="text-c_text_secondary text-label-sm">Meeting minutes</div>
			{isLoading || !docRecord ? (
				<SkeletonRecordAssociationCard />
			) : (
				<FieldIdRecordCard
					sdRecord={docRecord}
					sdObject={docObject}
					fieldIdsToDisplay={fieldIdsToDisplay}
					getRecordLink={getCurrentPathWithRecordIdParam}
				/>
			)}
		</div>
	);
}
