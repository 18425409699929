import { useCallback } from "react";

import { useStableNavigate } from "../../routes";

interface useAccessibleHTMLElementLinkProps {
	linkElementId?: string;
	onClick?: () => void;
	getLink?: () => string | undefined | null;
}

/**
 * Creates a onClick event for a HTML element with a given anchor/react router link
 * element ID which allows for a clickable HTML element that behaves exactly like
 * a link for mouse users (e.g. supporting cmd + click, etc.)
 *
 * For accessibility the HTML element should not be focusable and instead the anchor/link
 * element within the HTML element should be focusable. This means for keyboard focus/screen reader
 * users they interact directly with the link element.
 */
export function useAccessibleHTMLElementLink({ linkElementId, onClick, getLink }: useAccessibleHTMLElementLinkProps) {
	const navigate = useStableNavigate();

	/*
		Handles click events for any HTML element with modifier keys by passing the event to the link element.
		This is done for accessibility reasons so that all expected link mouse events work on the HTML element
		as if it were a link/anchor tag (e.g. cmd + click to open a new tab, shift + click to open it in a new window,
		middle mouse button, etc.)
	*/
	return useCallback(
		(e: React.MouseEvent) => {
			if (!linkElementId) {
				return;
			}

			/**
			 * id is used for selecting the link element instead of using `useRef` and
			 * passing the ref to the react router link as passing a ref to a react router
			 * link for some reason changes its behaviour and causes it to behave like an anchor tag.
			 */
			const linkElement = document.getElementById(linkElementId) as HTMLAnchorElement | null;

			if (!linkElement) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			const { altKey, shiftKey } = e;
			let { ctrlKey, metaKey } = e;

			// A middle mouse click is equivalent to a standard click with 'ctrl/cmd' pressed
			if (e.type === "auxclick") {
				ctrlKey = true;
				metaKey = true;
			}

			// If no modifier keys pressed we just use 'navigate' to get the benefits of SPA routing
			if (!altKey && !shiftKey && !ctrlKey && !metaKey && !linkElement.getAttribute("target")) {
				// Using `getAttribute` instead of .href to get the actual href value instead of the
				// fully qualified version (where relative links are expanded)
				const link = getLink?.() || linkElement.getAttribute("href") || "";
				navigate(link);
			} else linkElement.dispatchEvent(new MouseEvent("click", { altKey, shiftKey, metaKey, ctrlKey }));

			if (onClick) onClick();
		},
		[linkElementId, onClick, getLink, navigate]
	);
}
