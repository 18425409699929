export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
	width: 640,
	height: 480,
	frameRate: 24,
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
	width: 1280,
	height: 720,
	frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = "SalesDesk-selectedAudioInput";
export const SELECTED_AUDIO_OUTPUT_KEY = "SalesDesk-selectedAudioOutput";
export const SELECTED_VIDEO_INPUT_KEY = "SalesDesk-selectedVideoInput";

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = "SalesDesk-selectedBackgroundSettings";
