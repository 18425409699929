import { useMemo, useRef, useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { mProductDef } from "@salesdesk/salesdesk-model";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";

import { useWebPrincipal } from "../../../../../../../../../../auth";
import { useGetObjectById } from "../../../../../../../../../../hooks";
import { AddRecordButton } from "../../../../../../WorkspaceResources";
import { Product } from "./Product";
import { ProductPlaceholder } from "./ProductPlaceholder";

interface ProductCarouselProps {
	products: SDRecord[];
	isProductsEmpty?: boolean;
}

export function ProductCarousel({ products, isProductsEmpty }: ProductCarouselProps) {
	const sectionHolderRef = useRef<HTMLDivElement>(null);

	const objectIds = useMemo(() => [mProductDef.ID], []);

	const { sdObject: productObject } = useGetObjectById(mProductDef.ID);

	const [currentIndex, setCurrentIndex] = useState(0);
	const currentProduct = products[currentIndex];

	const principal = useWebPrincipal();

	if (!productObject) {
		return null;
	}

	return (
		<div ref={sectionHolderRef} className="relative flex grow pr-[8%]">
			<div className="flex w-full max-w-[800px] flex-col gap-4">
				<div className="flex h-8 items-center justify-between">
					<div id="userflow-product-label" className="text-h4 text-c_text_secondary">
						{products.length === 1 ? productObject._displayName : productObject._pluralName}
					</div>
					<div className="flex items-center gap-3">
						{products.length > 1 ? (
							<div className="text-label-sm flex items-center">
								<Button
									startIcon={ICONS.caretLeft}
									size="sm"
									variant="text"
									onClick={() => setCurrentIndex(currentIndex - 1)}
									disabled={currentIndex === 0}
								/>
								<div className="text-c_text_primary min-w-14 text-center">
									{currentIndex + 1}
									<span className="text-c_text_placeholder">/{products.length}</span>
								</div>
								<Button
									startIcon={ICONS.caretRight}
									size="sm"
									variant="text"
									onClick={() => setCurrentIndex(currentIndex + 1)}
									disabled={currentIndex >= products.length - 1}
								/>
							</div>
						) : null}
						{principal.IsSalesDeskUser ? (
							<AddRecordButton id={isProductsEmpty ? undefined : "userflow-add-product-here"} sdObjectIds={objectIds} />
						) : null}
					</div>
				</div>
				{isProductsEmpty ? (
					<ProductPlaceholder />
				) : (
					<Product productRecord={currentProduct} sectionHolderRef={sectionHolderRef} />
				)}
			</div>
		</div>
	);
}
