import clsx from "clsx";

import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { DisplayIf } from "../../../../../auth";

interface TranscriptViewLoadingProps {
	inWorkspace?: boolean;
}

export function TranscriptViewLoading({ inWorkspace = false }: TranscriptViewLoadingProps) {
	return (
		<div className={clsx("h-full w-full pt-3.5", !inWorkspace && "pr-4")}>
			<div className="ml-auto flex flex-row-reverse items-center gap-2">
				<DisplayIf
					canAny={[
						[AbilityAction.View, AbilitySubject.SentimentAnalysis],
						[AbilityAction.View, AbilitySubject.CoachingAdvice],
					]}
				>
					<Skeleton className="h-7 w-[67px]" />
					<Skeleton className="w-18 h-7" />
					<Skeleton className="h-7 w-16" />
					<Skeleton className="w-21 h-7" />
				</DisplayIf>
				<Skeleton className="w-18 h-7" />
			</div>
			<div className="mt-[30px] flex h-full w-full flex-col">
				{[...Array(15)].map((_, index) => (
					<div className="flex w-full" key={index}>
						<Skeleton className="ml-3.5 mr-2 mt-0.5 h-7 w-[60px]" />
						<div className="bg-c_border_regular mx-auto h-full w-px" />
						<div className="ml-6 flex flex-grow flex-col gap-2 pb-7">
							<Skeleton className="h-7 w-28" />
							<div className="flex flex-col gap-1">
								<Skeleton className="h-6 w-11/12" />
								<Skeleton className="h-6 w-2/3" />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
