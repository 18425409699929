import { useState, useEffect, useCallback, useRef } from "react";

import { getSDRecordsAndIdsFromChangeEvent } from "../../../../records/search/utils";
import { useLazyGetRecordsAssociationSummaryQuery } from "../../../api/recordAssociationsApi";
import { RECORD_CHANGE_EVENT, RecordChangeEvent, RecordChangeType } from "../../../../records";
import { RecordAssociationsSummaryMap } from "../types";
import { useGetContextWorkspaceId } from "../../../../workspaces";

export function useGetRecordAssociationsSummaryMap(recordIds?: number[]) {
	const workspaceId = useGetContextWorkspaceId();

	const [recordAssociationsSummaryMap, setRecordAssociationsSummaryMap] = useState<RecordAssociationsSummaryMap>({});
	const recordAssociationsSummaryMapRef = useRef<RecordAssociationsSummaryMap>(recordAssociationsSummaryMap);

	recordAssociationsSummaryMapRef.current = recordAssociationsSummaryMap;

	const [getRecordAssociationSummary] = useLazyGetRecordsAssociationSummaryQuery();

	const updateRecordAssociationsSummary = useCallback(
		(recordIds: number[]) => {
			getRecordAssociationSummary({ searchRequestBody: { recordIds }, workspaceId })
				.unwrap()
				.then((data) => {
					setRecordAssociationsSummaryMap((prevSummaryData) => {
						const newAssociationsSummary: RecordAssociationsSummaryMap = { ...prevSummaryData };
						data.associationSummariesByRecordId.forEach(({ recordId, associationCountByObjectType }) => {
							newAssociationsSummary[recordId] = associationCountByObjectType;
						});

						return newAssociationsSummary;
					});
				});
		},
		[getRecordAssociationSummary, workspaceId]
	);

	useEffect(() => {
		const onAssociationChangeEvent = (event: CustomEvent<RecordChangeEvent>) => {
			const { type, isOptimistic } = event.detail;
			const { recordIds } = getSDRecordsAndIdsFromChangeEvent(event);

			if (type !== RecordChangeType.ASSOCIATION_CHANGE || isOptimistic || !recordIds.length) {
				return;
			}

			const filteredRecordIds = recordIds.filter((recordId) => recordAssociationsSummaryMapRef.current[recordId]);
			if (filteredRecordIds.length) {
				updateRecordAssociationsSummary(filteredRecordIds);
			}
		};

		document.addEventListener(RECORD_CHANGE_EVENT, onAssociationChangeEvent as EventListener);

		return () => {
			document.removeEventListener(RECORD_CHANGE_EVENT, onAssociationChangeEvent as EventListener);
		};
	}, [updateRecordAssociationsSummary]);

	useEffect(() => {
		const newRecordIds = (recordIds || []).filter((recordId) => !recordAssociationsSummaryMapRef.current[recordId]);

		if (!newRecordIds.length) return;

		updateRecordAssociationsSummary(newRecordIds);
	}, [recordIds, updateRecordAssociationsSummary]);

	const clearAssociationsSummary = useCallback(() => {
		setRecordAssociationsSummaryMap({});
	}, []);

	return { recordAssociationsSummaryMap, clearAssociationsSummary };
}
