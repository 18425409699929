import { useMemo } from "react";

import { mAssetDef } from "@salesdesk/salesdesk-model";
import { isAssetObject } from "@salesdesk/salesdesk-schemas";

import { EventGraph } from "../../../../../../../../../events/components/EventGraph";
import { AssociationCarousel } from "../../../../../../../../../recordAssociations";
import { useRecordDetailsContext } from "../../../../../../hooks/useRecordDetailsContext";
import { ASSOCIATION_CAROUSELS } from "../utils";
import { PanelTabPanel } from "../../../../../../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation } from "../../../../../../../../../recordAssociations";
import { MeetingMinutes } from "./MeetingMinutes";
import { useSdFileByFieldName } from "../../../../../../../../../files/hooks/useSdFileByFieldName";
import { RecordStatusIndicator } from "./RecordStatusIndicator";

interface LinkedRecordsPanelProps {
	objectAssociations?: DirectedSDObjectAssociation[];
}

export function SummaryPanel({ objectAssociations }: LinkedRecordsPanelProps) {
	const { sdObject, sdRecord } = useRecordDetailsContext();

	const hasSDRecord = sdRecord !== undefined;

	const associationCarouselDetails = useMemo(() => {
		if (!hasSDRecord || !objectAssociations) return [];

		return ASSOCIATION_CAROUSELS.map(({ titlePrefix, associationObjectId, generateFilters }) => {
			const carouselsObjectAssociations = objectAssociations.filter(
				(objectAssociation) => objectAssociation.connectedObject.id === associationObjectId
			);

			return {
				titlePrefix,
				associationObjectId,
				generateFilters,
				carouselsObjectAssociations,
			};
		}).filter(({ carouselsObjectAssociations }) => carouselsObjectAssociations.length > 0);
	}, [hasSDRecord, objectAssociations]);

	const isAsset = sdObject ? isAssetObject(sdObject) : false;
	const { sdFile } = useSdFileByFieldName(
		isAsset ? sdObject : undefined,
		isAsset ? sdRecord : undefined,
		mAssetDef.FILE_FIELD_NAME
	);

	return (
		<PanelTabPanel className="flex max-h-full w-full max-w-full flex-col gap-6 overflow-auto py-6 pr-8" unmount={false}>
			<RecordStatusIndicator />
			<div className="flex w-full max-w-full gap-10">
				{associationCarouselDetails.length > 0 || sdFile?.minutesRecordId ? (
					<div className="flex w-1/2 min-w-60 max-w-[400px] flex-col gap-6">
						{associationCarouselDetails.map(
							({ titlePrefix, associationObjectId, generateFilters, carouselsObjectAssociations }) => (
								<AssociationCarousel
									key={`${sdRecord?._id}-${associationObjectId}`}
									sourceRecord={sdRecord}
									titlePrefix={titlePrefix}
									carouselObjectId={associationObjectId}
									objectAssociations={carouselsObjectAssociations}
									generateFilters={generateFilters}
								/>
							)
						)}
						{sdFile?.minutesRecordId !== undefined ? <MeetingMinutes minutesRecordId={sdFile.minutesRecordId} /> : null}
					</div>
				) : null}
				<div className="w-1/2 min-w-60 max-w-[500px]">
					<h2 className="text-c_text_secondary text-label-sm pb-3">Weekly activity</h2>
					<EventGraph sdRecord={sdRecord} />
				</div>
			</div>
		</PanelTabPanel>
	);
}
