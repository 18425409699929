import { useMemo, useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { getSDObjectFields, SDErrorCode } from "@salesdesk/salesdesk-schemas";

import { PATHS, useStableNavigate } from "../../../../routes";
import { ProgressStepper } from "../../../../components/ProgressStepper/ProgressStepper";
import { Badge } from "../../../../components/Badge";
import { useImportContext } from "../../hooks/useImportContext";
import { useRedirectIfNoImportMappings } from "../../hooks/useRedirectIfNoImportMappings";
import { JobType, useCreateImportJob } from "../../hooks/useCreateImportJob";
import { getErrorMessageFromCode, hasMappingDuplication, IMPORT_STEPS, ImportStep } from "../../utils";

import { StepControls } from "../common/StepControls";
import { CurrentFileName } from "../common/CurrentFileName";
import { ColumnMappingTable } from "./ColumnMapping/ColumnMappingTable";
import { DataPreview } from "./DataPreview/DataPreview";

export function ImportMappingPage() {
	const isRedirecting = useRedirectIfNoImportMappings();

	const navigate = useStableNavigate();
	const { sdObject, mappingEntries, setPreviewImportJob } = useImportContext();

	const requiredFields = useMemo(() => getSDObjectFields(sdObject).filter((field) => field._required), [sdObject]);
	const missingRequiredFields = useMemo(
		() => requiredFields.filter((field) => !mappingEntries.some((entry) => entry.fieldId === field._id)),
		[mappingEntries, requiredFields]
	);
	const selectedRequiredFieldCount = requiredFields.length - missingRequiredFields.length;

	const [error, setError] = useState<string | undefined>();

	const { createImportJob: createImportPreviewJob, isLoading: isCreatingJob } = useCreateImportJob({
		jobType: JobType.Preview,
		onCompleted: (importJob, errorMessage) => {
			if (errorMessage) {
				setError(errorMessage);
				return;
			}
			setPreviewImportJob(importJob);
			navigate(PATHS.IMPORT_EXECUTION(sdObject));
		},
	});

	if (isRedirecting) return null;

	return (
		<div className="flex h-full w-full flex-col items-center overflow-y-auto px-6">
			<div className="flex h-full min-h-[400px] w-full max-w-[1130px] flex-col justify-stretch gap-4">
				<ProgressStepper currentStep={ImportStep.mapping} steps={IMPORT_STEPS()} />
				<div className="text-label">Map CSV file columns to fields</div>
				<div className="flex w-full items-center gap-5">
					<div className="w-3/5">Map the columns in the csv file to {sdObject._pluralName} fields</div>
					<div className="grow">
						<Badge
							color="c_warning_04"
							icon={ICONS.warningCircle}
							text="Map a unique field to detect and update existing records."
						/>
					</div>
				</div>
				<div className="flex h-0 w-full grow gap-5">
					<ColumnMappingTable
						requiredFieldCount={requiredFields.length}
						selectedRequiredFieldCount={selectedRequiredFieldCount}
					/>
					<DataPreview />
				</div>
			</div>
			<div className="w-full">
				<StepControls
					onSecondaryClick={() => navigate(PATHS.IMPORT(sdObject))}
					primaryIsLoading={isCreatingJob}
					onPrimaryClick={() => {
						if (hasMappingDuplication(mappingEntries)) {
							setError(getErrorMessageFromCode(SDErrorCode.ImportMappingFieldDuplication));
							return;
						}
						if (selectedRequiredFieldCount < requiredFields.length) {
							const missingFieldNames = missingRequiredFields.map((field) => field._displayName).join(", ");
							setError(`Please map all remaining required fields: ${missingFieldNames}`);
							return;
						}
						createImportPreviewJob();
					}}
					error={error}
				>
					<CurrentFileName />
				</StepControls>
			</div>
		</div>
	);
}
