import { SDRecord, RecordsShareRequest } from "@salesdesk/salesdesk-schemas";

import { SDApi, buildApiInvalidatedTagList } from "../../api";
import { RecordChangeType, dispatchRecordChangeEvent, recordsApi } from "../../records";

import { buildApiInvalidatedTagsList } from "../../api";

interface ShareRecordMuatation {
	workspace: SDRecord;
	record: SDRecord;
}

interface ShareBatchRecordMutation {
	workspace: SDRecord;
	records: SDRecord[];
}

export const workspacesApi = SDApi.injectEndpoints({
	endpoints: (builder) => ({
		shareRecord: builder.mutation<void, ShareRecordMuatation>({
			query: ({ workspace, record }) => ({
				url: `/records/${record._id}/shared/workspaces/${workspace._id}`,
				method: "PUT",
			}),
			onQueryStarted: async ({ workspace, record }, { dispatch, queryFulfilled }) => {
				const updatedWorkspaceIds = [...record.sharedWorkspaceIds, workspace._id];
				const optimisticUpdatedRecord = { ...record, sharedWorkspaceIds: updatedWorkspaceIds };

				dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecord, true);

				// Updates the cache entry for the individual record
				const cacheUpdate = dispatch(
					recordsApi.util.updateQueryData("getRecord", record._id, (draft) => {
						Object.assign(draft, optimisticUpdatedRecord);
					})
				);

				try {
					await queryFulfilled;
					dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecord);
				} catch {
					dispatchRecordChangeEvent(RecordChangeType.UPDATE, record, true);
					cacheUpdate.undo();
				}
			},
			invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList(arg, (arg) => arg.record._id, "Record"),
		}),
		unshareRecord: builder.mutation<void, ShareRecordMuatation>({
			query: ({ workspace, record }) => ({
				url: `/records/${record._id}/shared/workspaces/${workspace._id}`,
				method: "DELETE",
			}),
			onQueryStarted: async ({ workspace, record }, { dispatch, queryFulfilled }) => {
				const updatedWorkspaceIds = record.sharedWorkspaceIds.filter((id) => id !== workspace._id);
				const optimisticUpdatedRecord = { ...record, sharedWorkspaceIds: updatedWorkspaceIds };

				dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecord, true);

				// Updates the cache entry for the individual record
				const cacheUpdate = dispatch(
					recordsApi.util.updateQueryData("getRecord", record._id, (draft) => {
						Object.assign(draft, optimisticUpdatedRecord);
					})
				);

				try {
					await queryFulfilled;
					dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecord);
				} catch {
					dispatchRecordChangeEvent(RecordChangeType.UPDATE, record, true);
					cacheUpdate.undo();
				}
			},
			invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList(arg, (arg) => arg.record._id, "Record"),
		}),
		batchShareRecords: builder.mutation<void, ShareBatchRecordMutation>({
			query: ({ workspace, records }) => ({
				url: `/records/batch/shared/workspaces/${workspace._id}`,
				method: "PUT",
				body: records.map(({ _id }) => _id),
			}),
			onQueryStarted: async ({ workspace, records }, { dispatch, queryFulfilled }) => {
				const optimisticUpdatedRecords = records.map((record) => ({
					...record,
					sharedWorkspaceIds: [...record.sharedWorkspaceIds, workspace._id],
				}));

				dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecords, true);

				const cacheUpdates = optimisticUpdatedRecords.map((updatedRecord) =>
					dispatch(
						recordsApi.util.updateQueryData("getRecord", updatedRecord._id, (draft) => {
							Object.assign(draft, updatedRecord);
						})
					)
				);

				try {
					await queryFulfilled;
					dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecords);

					// Update the RTK query cache for getRecord with the new updated record from the API
					optimisticUpdatedRecords.forEach((updatedRecord) => {
						dispatch(recordsApi.util.upsertQueryData("getRecord", updatedRecord._id, updatedRecord));
					});
				} catch {
					dispatchRecordChangeEvent(RecordChangeType.UPDATE, records, true);
					cacheUpdates.forEach((cacheUpdate) => cacheUpdate.undo());
				}
			},
			invalidatesTags: (result, error, arg) =>
				buildApiInvalidatedTagsList(arg.records, (record) => record._id, "Record"),
		}),
	}),
});

export const { useShareRecordMutation, useUnshareRecordMutation, useBatchShareRecordsMutation } = workspacesApi;
