import { asConst } from "json-schema-to-ts";
import { usernamePathParamSchema } from "./usernamePathParamSchema";

export const getUserPathSchema = asConst({
	title: "Get User Path Parameters",
	type: "object",
	additionalProperties: false,
	required: ["username"],
	properties: {
		username: usernamePathParamSchema,
	},
});
