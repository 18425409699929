import { ReactNode } from "react";
import clsx from "clsx";
import { Cell, flexRender } from "@tanstack/react-table";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";

import { genericMemo } from "../../../../../../utils";
import { ControlColumnDetails, ROW_LINK_COLUMN_ID, ROW_SELECTION_COLUMN_ID, TableRow } from "../../../../types";
import { DragHandleVisibility } from "../../types";
import { TableCellWrapper } from "./TableCellWrapper";

interface TableCellProps<T> {
	cell: Cell<TableRow<T>, unknown>;
	rowLinkElement?: ReactNode;
	rowIsSelected?: boolean;
	inSelectionMode?: boolean;
	isLoadingPlaceholder?: boolean;
	controlColumnToRender?: ControlColumnDetails<T>;

	isDraggable?: boolean;
	showDragHandle?: boolean;
	isDroppable?: boolean;
	isDragPlaceholder?: boolean;
	isDraggingRow?: boolean;
	dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

function TableCellComponent<T>({
	cell,
	rowLinkElement,
	rowIsSelected,
	inSelectionMode,
	isLoadingPlaceholder,
	controlColumnToRender,

	showDragHandle,
	isDroppable,
	isDragPlaceholder,
	isDraggingRow,
	dragHandleProps,
}: TableCellProps<T>) {
	const columnId = cell.column.id;

	let dragHandleVisibility: DragHandleVisibility = "NONE";

	if (showDragHandle) {
		if (isDraggingRow || isDragPlaceholder) {
			dragHandleVisibility = "ALWAYS";
		} else if (!isDroppable) {
			dragHandleVisibility = "HOVER";
		} else {
			// Keeps the drag handle mounted but just hides it from the user
			dragHandleVisibility = "HIDDEN";
		}
	}

	const sharedProps = {
		dragHandleProps,
		dragHandleVisibility,
		fixedWidth: isDraggingRow ? cell.column.getSize() : undefined,
	};

	const cellContents =
		controlColumnToRender && !isLoadingPlaceholder ? (
			<div className="flex items-center">
				{flexRender(cell.column.columnDef.cell, cell.getContext())}
				{controlColumnToRender.render(cell.row.original, cell.row.index)}
			</div>
		) : (
			flexRender(cell.column.columnDef.cell, cell.getContext())
		);

	if (columnId === ROW_LINK_COLUMN_ID) {
		return (
			<TableCellWrapper className="max-w-0 overflow-hidden p-0 opacity-0" {...sharedProps}>
				{rowLinkElement ? rowLinkElement : cellContents}
			</TableCellWrapper>
		);
	} else if (columnId === ROW_SELECTION_COLUMN_ID) {
		return (
			<TableCellWrapper
				className={clsx(
					"focus-visible-within:opacity-100 left-0 pl-3 transition-opacity",
					!isDroppable && "bg-gradient-to-r from-90% to-transparent group-hover/table-row:opacity-100",
					!isDraggingRow && "sticky",
					(isDragPlaceholder || isDraggingRow) && "opacity-100",
					!inSelectionMode && "opacity-0",
					!isDragPlaceholder &&
						!isDraggingRow &&
						!isDroppable &&
						(rowIsSelected
							? "from-c_bg_03"
							: "from-c_bg_01 group-hover/table-row:from-c_bg_03 group-focus-visible/table-row:bg-c_bg_03 group-focus-within/table-row:from-c_bg_03"),
					isLoadingPlaceholder && "!opacity-0",
					isDragPlaceholder && "!bg-c_bg_disabled_02"
				)}
				onClick={(event) => {
					if (isLoadingPlaceholder) {
						return;
					}
					event.preventDefault();
					event.stopPropagation();

					cell.row.toggleSelected();
				}}
				{...sharedProps}
			>
				{cellContents}
			</TableCellWrapper>
		);
	} else {
		return (
			<TableCellWrapper className={clsx("truncate p-4", !isDraggingRow && "max-w-0")} {...sharedProps}>
				{cellContents}
			</TableCellWrapper>
		);
	}
}

export const TableCell = genericMemo(TableCellComponent);
