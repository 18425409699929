import { NotificationRule, NotificationRuleConfig, SDObject } from "@salesdesk/salesdesk-schemas";
import { createContext, useContext } from "react";
import { SelectionType } from "../types";
import { SelectOption } from "../../../inputs";

export interface INotificationRuleDialogContext {
	rule: NotificationRule | undefined;

	currentSelection: SelectionType | undefined;
	setCurrentSelection: (value: SelectionType | undefined) => void;

	ruleConfigs: NotificationRuleConfig[];

	selectedRuleConfig: NotificationRuleConfig | undefined;
	selectedObjectId: number | null | undefined;
	setSelectedObjectId: (value: number | null | undefined) => void;
	selectedSDObject: SDObject | undefined;
	selectedWorkspaceId: number | undefined;
	setSelectedWorkspaceId: (value: number | undefined) => void;

	selectedAction: SelectOption | undefined;
	setSelectedAction: (value: SelectOption | undefined) => void;
	selectedField: SelectOption | undefined;
	setSelectedField: (value: SelectOption | undefined) => void;
	selectedUsers: SelectOption[];
	setSelectedUsers: (value: SelectOption[]) => void;
	selectedRecord: SelectOption | undefined;
	setSelectedRecord: (value: SelectOption | undefined) => void;
	selectedChannels: SelectOption[];
	setSelectedChannels: (value: SelectOption[]) => void;
	actualSelectedChannels: SelectOption[];

	onOpenChange: (open: boolean) => void;
}

export const NotificationRuleDialogContext = createContext<INotificationRuleDialogContext | null>(null);

export function useNotificationRuleDialogContext(): INotificationRuleDialogContext {
	const context = useContext(NotificationRuleDialogContext);

	if (context == null)
		throw new Error("Notification Rule Dialog components must within <CreateNotificationRuleDialogContainer />");

	return context;
}
