import { useMemo } from "react";
import { inAppDisabledChannelOption } from "../types";
import { capitalizeString } from "@salesdesk/salesdesk-utils";
import { SelectOption } from "../../../inputs";
import { NotificationChannelType } from "@salesdesk/salesdesk-schemas";

const disabledChannels: NotificationChannelType[] = [NotificationChannelType.Slack, NotificationChannelType.Whatsapp];

export function useChannelOptions() {
	return useMemo(() => {
		return [
			...Object.values(NotificationChannelType)
				// TODO: remove this filter when building frontend support for Zapier triggers
				.filter((channel) => channel !== NotificationChannelType.Zapier)
				.map(
					(channel) =>
						({
							id: channel,
							name: capitalizeString(channel),
							disabled: disabledChannels.includes(channel),
						}) as SelectOption
				),
			inAppDisabledChannelOption,
		];
	}, []);
}
