import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { mAssetDef, mTaskDef, mIssueDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useVideoCallLogicContext } from "../../../../../VideoCallProvider";
import {
	SidePanelDivider,
	SidePanelTab,
	SidePanelTabGroup,
	SidePanelTabList,
	SidePanelTabPanel,
	SidePanelTabPanels,
} from "./SidePanelTabs";
import { SidePanelButton } from "./SidePanelButton";
import { AssetPanel } from "./AssetPanel";
import { ChatPanelContainer } from "./ChatPanel";
import { useUnreadChatMessages } from "./ChatPanel/hooks/useUnreadChatMessages";
import { NotesPanel } from "./NotesPanel";
import { VideoHistoryPanel } from "./VideoHistoryPanel";
import { useGetObjectByIds } from "../../../../../../../../hooks/useGetObjectByIds";
import { CreateRecordPanel, AllowedRecordCreatePanel } from "./CreateRecordPanel";
import { AbilityAction, AbilitySubject, SDObject } from "@salesdesk/salesdesk-schemas";
import { MeetingInfoPanel } from "./MeetingInfoPanel/MeetingInfoPanel";
import { Toasts } from "../../../../../../../Toasts";
import { useWebPrincipal } from "../../../../../../../../auth";

const panelObjectIDs = [mTaskDef.ID, mIssueDef.ID, mAssetDef.ID];

export function VideoSidePanel() {
	const { panelOpen, setPanelOpen, currentPanelTab, updatePanelTab } = useVideoCallLogicContext();
	const { unreadMessageCount } = useUnreadChatMessages();

	const [taskObject, setTaskObject] = useState<SDObject | null>(null);
	const [issueObject, setIssueObject] = useState<SDObject | null>(null);
	const [assetObject, setAssetObject] = useState<SDObject | null>(null);

	const panelObjects = useGetObjectByIds(panelObjectIDs);

	useEffect(() => {
		let task = null;
		let asset = null;
		let issueObject = null;

		panelObjects.forEach((object) => {
			if (object._id === mTaskDef.ID) {
				task = object;
			} else if (object._id === mAssetDef.ID) {
				asset = object;
			} else if (object._id === mIssueDef.ID) {
				issueObject = object;
			}
		});

		setTaskObject(task);
		setAssetObject(asset);
		setIssueObject(issueObject);
	}, [panelObjects]);

	const handleTabSelected = useCallback(
		(alreadySelected: boolean) => {
			if (!panelOpen) setPanelOpen(true);
			else if (alreadySelected) setPanelOpen(false);
		},
		[panelOpen, setPanelOpen]
	);

	const tabVariant = panelOpen ? "light" : "dark";

	const principal = useWebPrincipal();
	const canViewNotes = principal.can(AbilityAction.View, AbilitySubject.Note);
	const canCreateRecords = principal.can(AbilityAction.Create, AbilitySubject.Record);
	const canSearchEvents = principal.can(AbilityAction.Search, AbilitySubject.Event);

	return (
		<div
			className={clsx(
				"relative z-10 mt-[20px] flex-shrink-0 overflow-hidden transition-all",
				panelOpen ? "bg-c_bg_01 rounded-tl-panel w-[380px]" : "bg-c_bg_07 w-[60px] rounded-tl-[32px]"
			)}
		>
			<Toasts position="side_panel" />
			<SidePanelTabGroup vertical manual selectedIndex={currentPanelTab} onChange={updatePanelTab}>
				<div className="flex h-full justify-between">
					<div
						className={clsx(
							"relative flex h-full overflow-hidden transition-opacity",
							panelOpen ? "w-full max-w-full opacity-100 delay-75" : "w-0 opacity-0"
						)}
					>
						<SidePanelTabPanels className="flex h-full w-full min-w-80 overflow-hidden pl-4 pt-4">
							{/*
							If you change the order of the these tabs then update the referenced tab indexes in
							apps/salesdesk-web/src/features/meetings/components/MeetingPage/components/VideoCallPage/VideoSidePanel/utils/index.ts
						*/}
							<SidePanelTabPanel>
								<MeetingInfoPanel />
							</SidePanelTabPanel>
							<SidePanelTabPanel>
								<ChatPanelContainer />
							</SidePanelTabPanel>
							{canViewNotes ? (
								<SidePanelTabPanel>
									<NotesPanel />
								</SidePanelTabPanel>
							) : null}
							{canCreateRecords ? (
								<SidePanelTabPanel>
									<AssetPanel />
								</SidePanelTabPanel>
							) : null}
							{canCreateRecords ? (
								<SidePanelTabPanel>
									<CreateRecordPanel recordPanel={AllowedRecordCreatePanel.Task} />
								</SidePanelTabPanel>
							) : null}
							{canCreateRecords ? (
								<SidePanelTabPanel>
									<CreateRecordPanel recordPanel={AllowedRecordCreatePanel.Issue} />
								</SidePanelTabPanel>
							) : null}
							{canSearchEvents ? (
								<SidePanelTabPanel>
									<VideoHistoryPanel />
								</SidePanelTabPanel>
							) : null}
						</SidePanelTabPanels>
					</div>

					<div className="z-10 flex h-full flex-shrink-0 flex-col items-center justify-between pb-6 pt-9">
						<div>
							<SidePanelTabList>
								<SidePanelTab
									key="meetingInfo"
									icon={ICONS.info}
									tooltip="Meeting Info"
									variant={tabVariant}
									onSelected={handleTabSelected}
								/>
								<SidePanelTab
									key="chat"
									icon={ICONS.chatsCircle}
									tooltip="Chat"
									variant={tabVariant}
									onSelected={handleTabSelected}
									alertCount={unreadMessageCount === 0 ? undefined : unreadMessageCount}
								/>
								{canViewNotes ? (
									<SidePanelTab
										key="notes"
										icon={ICONS.notePencil}
										tooltip="Notes"
										variant={tabVariant}
										onSelected={handleTabSelected}
									/>
								) : null}
								{canCreateRecords ? (
									<SidePanelTab
										key="assets"
										icon={assetObject?._icon || ICONS.folders}
										tooltip={assetObject?._pluralName || "Assets"}
										variant={tabVariant}
										onSelected={handleTabSelected}
									/>
								) : null}
								{canCreateRecords ? (
									<>
										<SidePanelDivider isOpen={panelOpen} />
										<SidePanelTab
											userflowId="userflow-tasks-side-panel-button"
											key="tasks"
											icon={taskObject?._icon || ICONS.task}
											tooltip={taskObject?._pluralName || "Tasks"}
											variant={tabVariant}
											onSelected={handleTabSelected}
										/>
									</>
								) : null}
								{canCreateRecords ? (
									<SidePanelTab
										userflowId="userflow-issues-side-panel-button"
										key="issues"
										icon={issueObject?._icon || ICONS.wrench}
										tooltip={issueObject?._pluralName || "Issues"}
										variant={tabVariant}
										onSelected={handleTabSelected}
									/>
								) : null}
								{canSearchEvents ? (
									<>
										<SidePanelDivider isOpen={panelOpen} />
										<SidePanelTab
											key="history"
											icon={ICONS.clock}
											tooltip="History"
											variant={tabVariant}
											onSelected={handleTabSelected}
										/>
									</>
								) : null}
							</SidePanelTabList>
						</div>
						<div className="flex items-center">
							<SidePanelButton
								tooltip={panelOpen ? "Collapse" : "Open"}
								icon={panelOpen ? ICONS.arrowLineRight : ICONS.arrowLineLeft}
								variant={tabVariant}
								selected={false}
								onClick={() => setPanelOpen(!panelOpen)}
							/>
						</div>
					</div>
				</div>
			</SidePanelTabGroup>
		</div>
	);
}
