import { useEffect, useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { mFieldDef, UniqueFieldType } from "@salesdesk/salesdesk-model";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

import { useBulkEditContext } from "../../../hooks/useBulkEditContext";
import { SDObject, getSDObjectFields } from "@salesdesk/salesdesk-schemas";
import { BulkEditFieldsModal } from "./BulkEditFieldsModal";
import { FormFieldDetailsMap } from "../types";
import { SelectOption } from "../../../../../../inputs";
import { convertFieldToFieldDef, isMediaField } from "../../../../../../fields";
import { usePrincipalCanFilteredRecords } from "../../../hooks/usePrincipalCanFilteredRecords";
import { getCanEditParameters } from "../../../utils";

interface BulkEditFieldsProps {
	sdObject?: SDObject;
}

export function BulkEditFields({ sdObject }: BulkEditFieldsProps) {
	const { inBulkEditMode, selectedRecords } = useBulkEditContext();
	const [editableRecords] = usePrincipalCanFilteredRecords(selectedRecords, getCanEditParameters);

	const [showModal, setShowModal] = useState(false);

	const [fieldDetailsMap, setFieldDetailsMap] = useState<FormFieldDetailsMap>({});
	const [fieldOptions, setFieldOptions] = useState<SelectOption[]>([]);

	// Generated outside of modal to reduce repeated work and ensure modal is ready to
	// use when opened.
	useEffect(() => {
		if (!sdObject) {
			setFieldDetailsMap({});
			setFieldOptions([]);
			return;
		}

		const newFieldOptions: SelectOption[] = [];
		const newFieldDetailsMap: FormFieldDetailsMap = {};

		getSDObjectFields(sdObject).forEach((field) => {
			const fieldId = String(field._id);

			let disabledReason: string | undefined;
			if (isMediaField(field)) disabledReason = "Bulk editing not enabled for media fields";
			else if (field._unique !== UniqueFieldType.None && selectedRecords.length > 1)
				disabledReason = "Bulk editing is not possible on unique fields";

			newFieldOptions.push({
				id: fieldId,
				name: field._displayName,
				icon: field._icon,
				description: disabledReason,
				disabled: Boolean(disabledReason),
			});

			// mFieldDef required for form validation
			newFieldDetailsMap[fieldId] = { field, fieldDef: convertFieldToFieldDef(field) as mFieldDef };
		});

		setFieldOptions(newFieldOptions);
		setFieldDetailsMap(newFieldDetailsMap);
	}, [editableRecords.length, sdObject, selectedRecords.length]);

	const cannotEditAnyOfSelectedRecords = editableRecords.length === 0;

	return (
		<>
			<Tooltip
				text={
					selectedRecords.length && cannotEditAnyOfSelectedRecords
						? "You don't have permission to edit selected records"
						: undefined
				}
				ignoreDisabled={true}
				placement="top"
			>
				<Button
					disabled={!selectedRecords.length || cannotEditAnyOfSelectedRecords}
					onClick={() => setShowModal(true)}
					startIcon={ICONS.simplePencil}
					size="sm"
					variant="text_inverted"
				>
					Edit Field
				</Button>
			</Tooltip>
			{showModal && inBulkEditMode && sdObject ? (
				<BulkEditFieldsModal
					sdObject={sdObject}
					fieldOptions={fieldOptions}
					fieldDetailsMap={fieldDetailsMap}
					onOpenChange={setShowModal}
				/>
			) : null}
		</>
	);
}
