import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { mWorkspaceDef, mLeadDef } from "@salesdesk/salesdesk-model";
import { Button } from "@salesdesk/daisy-ui";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { Dialog, DialogContent } from "../../../Dialog";
import { useGetObjectMap } from "../../../objects/hooks";
interface LeadCreationSuccessModalProps {
	open: boolean;
	onClose: () => void;
	onGrantAccessClick: () => void;
	onCreateWorkspaceClick: () => void;
}

export function LeadCreationSuccessModal({
	open,
	onGrantAccessClick,
	onCreateWorkspaceClick,
	onClose,
}: LeadCreationSuccessModalProps) {
	const objectMap = useGetObjectMap();
	const leadSDObject = objectMap.get(mLeadDef.ID);

	const doNextItems = useMemo(() => {
		const workspaceObject = objectMap.get(mWorkspaceDef.ID);

		if (!workspaceObject || !leadSDObject) return [];

		return [
			{
				icon: workspaceObject._icon,
				description: `Set up a ${capitalizeString(workspaceObject._displayName)} for your ${capitalizeString(
					leadSDObject._displayName
				)}.`,
				buttonText: `Create ${capitalizeString(workspaceObject._displayName)}`,
				onClick: onCreateWorkspaceClick,
			},
			{
				icon: ICONS.userCircle,
				description: `Give your ${capitalizeString(leadSDObject._displayName)} access to ${capitalizeString(
					workspaceObject._pluralName
				)}.`,
				buttonText: `Invite ${capitalizeString(leadSDObject._displayName)}`,
				onClick: onGrantAccessClick,
			},
		];
	}, [objectMap, leadSDObject, onGrantAccessClick, onCreateWorkspaceClick]);

	const leadDisplayName = leadSDObject ? capitalizeString(leadSDObject._displayName) : "Lead";

	return (
		<Dialog
			open={open}
			onOpenChange={(isOpen) => {
				if (!isOpen) {
					onClose();
				}
			}}
		>
			<DialogContent>
				<div className="flex w-[730px] flex-col gap-6 pb-4">
					<h3 className="text-h3 text-c_action_01 text-center">
						Congratulations! You've just created your first {leadDisplayName}!
					</h3>
					<div className="flex min-h-[360px] w-full justify-around">
						<video
							className="border-c_border_regular rounded-card shadow-card w-full max-w-[400px] border"
							src="https://salesdesk-onboarding-files.s3.eu-west-1.amazonaws.com/context-menu-for-creating-workspace-and-grant-access.mp4"
							autoPlay={true}
							controls={false}
							loop={true}
						/>
					</div>

					<div className="mx-auto flex w-full max-w-[569px] flex-col gap-6">
						<div className="flex gap-3">
							{doNextItems.map(({ icon, description, onClick, buttonText }) => (
								<div key={description} className="flex w-full flex-col items-center gap-3">
									<p className="text-label flex-grow">{description}</p>
									<Button startIcon={icon} onClick={onClick}>
										{buttonText}
									</Button>
								</div>
							))}
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
