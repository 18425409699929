import { getWorkspaceShareableAssociations, isUserObject, mAssetDef } from "@salesdesk/salesdesk-model";
import { ASSET_OBJECT_IDS } from "../../constants";

export const SHARE_WITH_WORKSPACE_OBJECT_IDS = getWorkspaceShareableAssociations()
	.map((config) => {
		switch (config.object2.id) {
			case mAssetDef.ID:
				return ASSET_OBJECT_IDS;
			default:
				return config.object2.id;
		}
	})
	.flat();

export const RECORD_SHARING_OBJECT_IDS = SHARE_WITH_WORKSPACE_OBJECT_IDS.filter((id) => !isUserObject(id));
export const USER_SHARING_OBJECT_IDS = SHARE_WITH_WORKSPACE_OBJECT_IDS.filter((id) => isUserObject(id));
