import { useGetObjectById } from "../../../hooks";
import { useGetLoginAuthorizationDetails } from "../../records";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface UserAuthorisedLoginIconProps {
	sdRecord: SDRecord;
}

export function UserAuthorisedLoginIcon({ sdRecord }: UserAuthorisedLoginIconProps) {
	const { sdObject } = useGetObjectById(sdRecord ? sdRecord._objectDefId : undefined);
	const { isAuthorizedToLogIn, isSalesDeskUser, isUserRecord } = useGetLoginAuthorizationDetails(sdObject, sdRecord);

	if (!isAuthorizedToLogIn || isSalesDeskUser || !isUserRecord) {
		return null;
	}

	return (
		<Tooltip text="Has been granted login access">
			<span>
				<Icon icon={ICONS.userCircle} className="text-c_action_01 flex" />
			</span>
		</Tooltip>
	);
}
