import { useMemo } from "react";

import { SDRecord, SDObject, AbilityAction, sdSubject, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { mIssueDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../../../auth";
import { MenuItem } from "../../../../../menu";
import { useToggleDoneCompletableRecord } from "../../../../../completableRecord";

export function useIssueRecordActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	const principal = useWebPrincipal();

	const { isLoading, markAsDone, markAsToDo, isCompletableRecordDone } = useToggleDoneCompletableRecord(
		sdRecord,
		sdObject
	);

	return useMemo(() => {
		if (mIssueDef.ID !== sdObject?._id) return [];

		const actions: MenuItem[] = [];

		if (sdRecord && principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Record, sdRecord)))
			actions.push({
				onClick: isCompletableRecordDone ? markAsToDo : markAsDone,
				icon: ICONS.check,
				text: `Mark as ${isCompletableRecordDone ? "open" : "closed"}`,
				loading: isLoading,
				type: "button",
			});

		return actions;
	}, [sdObject, sdRecord, isCompletableRecordDone, isLoading, markAsDone, markAsToDo, principal]);
}
