import { useMemo } from "react";
import clsx from "clsx";

import { mProductDef } from "@salesdesk/salesdesk-model";
import { useWorkspaceSharedRecordSearch } from "../../../../../../hooks/useWorkspaceSharedRecordSearch";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { useWebPrincipal } from "../../../../../../../../auth";

import { ProductCarousel } from "./Products";
import { WorkspaceProjectOverview } from "./WorkspaceProjectOverview";
import { useGetProjectOverview } from "../hooks/useGetProjectOverview";

export function WorkspaceProductsAndProjectSection() {
	const principal = useWebPrincipal();

	const { isLoading } = useWorkspaceContext();

	// Products
	const objectIds = useMemo(() => [mProductDef.ID], []);
	const { records: products, isLoadingRecords } = useWorkspaceSharedRecordSearch({ sdObjectFilter: objectIds });

	const isProductsEmpty = !isLoadingRecords && !products.length;
	const hideProducts = isProductsEmpty && principal.IsCustomerUser;

	// Project Overview
	const { projectOverviewFieldInfo, isEmpty } = useGetProjectOverview();
	const hideProjectOverview = !isLoading && (!projectOverviewFieldInfo || (isEmpty && principal.IsCustomerUser));

	if (hideProjectOverview && hideProducts) {
		return null;
	}

	return (
		<div className="flex w-full justify-stretch gap-6">
			{!hideProducts ? <ProductCarousel products={products} isProductsEmpty={isProductsEmpty} /> : null}
			{hideProjectOverview ? (
				// Placeholder to ensure carousel is correct size
				<div className="w-5/12 shrink-0 grow-0" />
			) : (
				<div className={clsx(hideProducts ? "max-h-[650px] w-3/5" : "w-5/12", "relative shrink-0 grow-0")}>
					<div className={clsx(!hideProducts && "absolute", "h-full w-full overflow-hidden")}>
						<WorkspaceProjectOverview projectOverviewFieldInfo={projectOverviewFieldInfo} isEmpty={isEmpty} />
					</div>
				</div>
			)}
		</div>
	);
}
