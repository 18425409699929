import { createContext, useContext } from "react";
import { URLStateHookReturnType } from "../../../../../../hooks/useURLState";
import { WorkspaceLibraryState } from "../types";

export const WorkspaceLibraryUrlStateContext = createContext<URLStateHookReturnType<WorkspaceLibraryState> | undefined>(
	undefined
);

export function useWorkspaceLibraryStateContext() {
	const context = useContext(WorkspaceLibraryUrlStateContext);
	if (!context) {
		throw new Error("useWorkspaceLibraryUrlStateContext must be used within WorkspaceLibraryUrlStateContext.Provider");
	}
	return context;
}
