import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, DragUpdate, DropResult } from "@hello-pangea/dnd";
import clsx from "clsx";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { Sticky } from "../../../../../../../../components/Sticky";
import { throttle } from "../../../../../../../../utils";
import { FULL_RECORD_DATA_FIELD_ID, RecordGroupDetails } from "../../../../../../types";
import { useDataboardDetailsContext } from "../../../../../../hooks/useDataboardDetailsContext";
import { useBoardFieldsToDisplaySelector, useBoardGroupBySelector } from "../../../../../../store";
import { useDefaultColumnWidths as useDefaultColumnWidthByColumnId } from "../../../../common/hooks/useDefaultColumnWidths";
import { useTableColumns } from "../../../../common/hooks/useTableColumns";
import { useControlColumn } from "../../../../common/hooks/useControlColumn";
import { RecordTableRow } from "../../../../common/types";
import { Table } from "../../../../../../../Table";
import { LinkedTablesProvider } from "../../../../../../../Table/components/LinkedTablesProvider";
import { useRecordTableSelection, useUpdateRecord } from "../../../../../../../records";
import { useLinkedTablesState } from "../../../../../../../Table/hooks/useLinkedTables";
import { useToast } from "../../../../../../../Toasts";
import { useBulkEditContext } from "../../../../../BulkEdit";
import { getDropIndex, isBodyDroppable } from "../utils";
import { ListGroup } from "./ListGroup";

interface ListViewProps {
	recordGroups: RecordGroupDetails[];
}

const EMPTY_ARRAY: RecordTableRow[] = [];

function ListViewComponent({ recordGroups }: ListViewProps) {
	const { sdObject, workspaceId } = useDataboardDetailsContext();
	const groupBy = useBoardGroupBySelector();
	const fieldsToDisplay = useBoardFieldsToDisplaySelector();

	const isWorkspaceBoard = Boolean(workspaceId);

	const columns = useTableColumns();
	const defaultColumnWidthByColumnId = useDefaultColumnWidthByColumnId(sdObject);
	const scrollableContainerRef = useRef<HTMLDivElement>(null);
	const tableRef = useRef<HTMLDivElement | null>(null);
	const controlColumn = useControlColumn(tableRef, scrollableContainerRef);

	const [recordsPerGroupId, setRecordsPerGroupId] = useState<Record<number, SDRecord[]>>({});

	const rowsPerGroupId = useRef<Record<number, RecordTableRow[]>>({});
	const allRows = useRef<RecordTableRow[]>([]);
	const updateRowsByGroupId = useCallback(
		(groupId: number, rows: RecordTableRow[]) => {
			rowsPerGroupId.current[groupId] = rows;
			allRows.current = Object.values(rowsPerGroupId.current).flat();
		},
		[rowsPerGroupId]
	);

	const { inBulkEditMode, selectedRecords, setSelectedRecords, setAllSelected, setOnSelectAll } = useBulkEditContext();

	const { rowSelectionDetails, getRowId } = useRecordTableSelection({
		selectedRecords,
		onSelectedRecordsChange: setSelectedRecords,
		rows: allRows.current,
		sdRecordKeyInRow: FULL_RECORD_DATA_FIELD_ID,
		inSelectionMode: inBulkEditMode,
	});

	// Force re-render the linked table context to make sure the select all checkbox stays synchronised
	const { refresh, toggleAllRowsSelected } = useLinkedTablesState() || {};
	useEffect(
		() => {
			refresh?.();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedRecords]
	);

	const toggleAllRowsSelectedFunction = useCallback(
		(checked: boolean) => {
			const allRecords = allRows.current.flatMap((row) => row[FULL_RECORD_DATA_FIELD_ID] as SDRecord);
			setSelectedRecords(checked ? allRecords : []);
		},
		[setSelectedRecords]
	);

	if (toggleAllRowsSelected) {
		toggleAllRowsSelected.current = toggleAllRowsSelectedFunction;
	}

	useEffect(() => {
		setAllSelected(selectedRecords.length === allRows.current.length);
	}, [selectedRecords, setAllSelected]);

	useEffect(() => {
		setOnSelectAll(() => () => allRows.current.flatMap((row) => row[FULL_RECORD_DATA_FIELD_ID] as SDRecord));
	}, [setOnSelectAll]);

	const updateRecordGroupPosition = useCallback(
		(sourceGroupId: number, sourceIndex: number, destinationGroupId: number, destinationIndex: number) => {
			const sourceGroupData = [...recordsPerGroupId[sourceGroupId]];
			const [record] = sourceGroupData.splice(sourceIndex, 1);
			const destinationGroupData = [
				...(sourceGroupId === destinationGroupId ? sourceGroupData : recordsPerGroupId[destinationGroupId]),
			];
			destinationGroupData.splice(destinationIndex, 0, record);
			setRecordsPerGroupId({
				...recordsPerGroupId,
				[sourceGroupId]: sourceGroupData,
				[destinationGroupId]: destinationGroupData,
			});
			return { record };
		},
		[recordsPerGroupId]
	);

	const { updateRecord } = useUpdateRecord();
	const toast = useToast();

	const onDragEnd = useCallback(
		(result: DropResult) => {
			setDropDestinationId(undefined);
			setDropIndex(undefined);

			if (!result.destination) {
				return;
			}

			const { source, destination } = result;

			const [, sourceDroppableId] = source.droppableId.split("-");
			const currentGroupId = Number(sourceDroppableId);
			const recordIndex = source.index;

			const [, destinationDroppableId] = destination.droppableId.split("-");
			const destinationGroupId = Number(destinationDroppableId);
			const destinationIndex = destination.index;

			const { record } = updateRecordGroupPosition(currentGroupId, recordIndex, destinationGroupId, destinationIndex);

			const fieldId = groupBy;

			// If groupBy is undefined (so all of the records are in an 'ungrouped' table and aren't being grouped by any field)
			// or the current & destination group ids are the same, the user has moved a row's postion within its current table,
			// so nothing on the backend has to be updated.
			if (!fieldId || currentGroupId === destinationGroupId) {
				return;
			}

			updateRecord({ record, updatedFields: [{ _fieldId: Number(fieldId), _value: destinationGroupId }] })
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "record_updated" });
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_updated" });

					// Swaps the record back to its original position if an error occurred
					updateRecordGroupPosition(destinationGroupId, destinationIndex, currentGroupId, recordIndex);
				});
		},
		[groupBy, toast, updateRecord, updateRecordGroupPosition]
	);

	const onTableDataChange = (groupId: number, updatedData: SetStateAction<SDRecord[]>) => {
		setRecordsPerGroupId((currentData) => {
			return {
				...currentData,
				[groupId]: typeof updatedData === "function" ? updatedData(currentData[groupId]) : updatedData,
			};
		});
	};

	const [dropIndex, setDropIndex] = useState<number>();
	const [dropDestinationId, setDropDestinationId] = useState<string>();

	const onDragUpdate = useMemo(
		() =>
			throttle((update: DragUpdate) => {
				const { source, destination } = update;
				if (destination) {
					setDropDestinationId(destination.droppableId);

					let dropIndex = isBodyDroppable(destination.droppableId) ? destination.index : undefined;
					if (
						dropIndex !== undefined &&
						source.droppableId === destination.droppableId &&
						source.index <= destination.index
					) {
						// Make up for the gap caused by the dragging element
						dropIndex++;
					}
					setDropIndex(dropIndex);
				} else {
					setDropDestinationId(undefined);
					setDropIndex(undefined);
				}
			}, 50),
		[]
	);

	if (columns.length === 0) {
		return null;
	}

	return (
		<DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
			<div ref={scrollableContainerRef} className={clsx("mt-2.5 w-full pb-8", !isWorkspaceBoard && "overflow-auto")}>
				<Sticky priority={3} height={40} className="z-10">
					<Table
						columns={columns}
						visibleColumns={fieldsToDisplay}
						rows={EMPTY_ARRAY}
						rowSelection={rowSelectionDetails}
						getRowId={getRowId}
						defaultColumnWidthByColumnId={defaultColumnWidthByColumnId}
						outsideScrollContainerRef={scrollableContainerRef}
						outsideRef={tableRef}
						controlColumn={controlColumn}
						stickyHeader={false}
						isPrimaryLinkedTable
					/>
				</Sticky>
				{recordGroups.map((recordGroup) => (
					<ListGroup
						key={recordGroup.id}
						columns={columns}
						recordsPerGroupId={recordsPerGroupId}
						onTableDataChange={onTableDataChange}
						rowSelectionDetails={rowSelectionDetails}
						getRowId={getRowId}
						updateRowsByGroupId={updateRowsByGroupId}
						recordGroup={recordGroup}
						defaultColumnWidthByColumnId={defaultColumnWidthByColumnId}
						scrollableContainerRef={scrollableContainerRef}
						dropIndex={getDropIndex(recordGroup.id, dropDestinationId, dropIndex)}
						isDroppable={dropDestinationId !== undefined}
					/>
				))}
			</div>
		</DragDropContext>
	);
}

export function ListView(props: ListViewProps) {
	return (
		<LinkedTablesProvider>
			<ListViewComponent {...props} />
		</LinkedTablesProvider>
	);
}
