import { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { JSONContent } from "@tiptap/core";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { RichTextInput } from "../../../../../../inputs";
import { useInputRecordAttachments } from "../hooks/useInputRecordAttachments";
import { EmbeddedRecord } from "./EmbeddedRecord";
import { AttachmentSelectRecordsModal } from "./AttachmentSelectRecordsModal";
import { ChannelMessageMetadata, ChannelMessageType, ChannelType, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useChannelSelector } from "../../../../../store";
import { useChannelMemberRecordIds } from "../../../../../hooks";

interface ChimeChatInputProps {
	channelArn?: string;
	sendMessage: (message?: JSONContent, metadata?: ChannelMessageMetadata) => void;
	disabled?: boolean;
	autofocus?: boolean;
}

export function ChimeChatInput({ channelArn, sendMessage, disabled, autofocus }: ChimeChatInputProps) {
	const inputRef = useRef<HTMLDivElement>(null);
	const [content, setContent] = useState<{ jsonContent: JSONContent; text: string | undefined }>();
	const {
		recordAttachmentIds,
		addRecordAttachments,
		updateRecordAttachments,
		clearRecordAttachments,
		removeRecordAttachments,
	} = useInputRecordAttachments();

	const isEmpty = useMemo(() => {
		return content?.text == null || content.text.trim() === "";
	}, [content?.text]);

	const canSend = useMemo(() => {
		return !isEmpty || recordAttachmentIds.length > 0;
	}, [isEmpty, recordAttachmentIds.length]);

	const handleSendMessage = useCallback(async () => {
		if (!canSend) {
			return;
		}

		await sendMessage(isEmpty ? undefined : content?.jsonContent, {
			messageType: ChannelMessageType.Standard,
			attachments: { recordIds: recordAttachmentIds },
		});

		setContent(undefined);
		clearRecordAttachments();
	}, [canSend, sendMessage, isEmpty, content?.jsonContent, recordAttachmentIds, clearRecordAttachments]);

	const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
		(event) => {
			if (event.code === "Enter" && !event.shiftKey) {
				handleSendMessage();
			}
		},
		[handleSendMessage]
	);

	const onChange = useCallback(
		(jsonContent: JSONContent, text?: string) => {
			setContent({ jsonContent, text });
			updateRecordAttachments(jsonContent);
		},
		[updateRecordAttachments]
	);

	const focusTextInput = useCallback(() => {
		const inputElement = inputRef.current?.querySelector("[contenteditable]") as HTMLElement | undefined;
		if (inputElement) {
			inputElement.focus();
		}
	}, []);

	const [attachmentModalOpen, setAttachmentModal] = useState(false);

	const handleSelectRecords = useCallback(
		(selectedRecords: SDRecord[]) => {
			addRecordAttachments(selectedRecords.map((record) => record._id));
			setAttachmentModal(false);

			setTimeout(() => {
				focusTextInput();
			});
		},
		[addRecordAttachments, focusTextInput]
	);

	useEffect(() => {
		if (disabled || !autofocus) {
			return;
		}

		focusTextInput();
	}, [focusTextInput, disabled, autofocus]);

	const channel = useChannelSelector(channelArn);
	const channelMembers = useChannelMemberRecordIds(channelArn);

	const channelWorkspaceId =
		channel?.channelMetadata?.channelType === ChannelType.Workspace ? channel.channelMetadata.workspaceId : undefined;

	return (
		<>
			<div className="text-body-sm max-h-[245px] pr-3 pt-4">
				<RichTextInput
					ref={inputRef}
					onChange={onChange}
					onKeyDown={handleOnKeyDown}
					hideToolbar={true}
					placeholder="Type a message..."
					value={content?.jsonContent}
					isCollapsible
					disabled={disabled}
					disableEnter={true}
					enableShiftEnterAsEnter={true}
					enableMentions={{
						chatChannelDetails: channelArn ? { chatChannelArn: channelArn, channelMembers: channelMembers } : undefined,
						workspaceId: channelWorkspaceId,
					}}
					bottomPanelOptions={{
						showMentions: true,
						showEmojis: true,
						leftComponent: (
							<Tooltip text="Attach a record">
								<Button
									startIcon={ICONS.plus}
									size="sm"
									variant="secondary"
									disabled={disabled}
									onClick={(event) => {
										event.stopPropagation();
										setAttachmentModal(true);
									}}
								/>
							</Tooltip>
						),
						rightComponent: (
							<Tooltip text="Send message">
								<Button startIcon={ICONS.paperPlaneTile} onClick={handleSendMessage} disabled={!canSend} size="sm" />
							</Tooltip>
						),
					}}
				>
					{recordAttachmentIds.length ? (
						<div className="-mt-1 flex max-h-32 flex-col gap-1 overflow-auto p-2 pt-0">
							{recordAttachmentIds.map((recordId) => (
								<EmbeddedRecord key={recordId} recordId={recordId} onRemove={() => removeRecordAttachments(recordId)} />
							))}
						</div>
					) : null}
				</RichTextInput>
			</div>
			{attachmentModalOpen ? (
				<AttachmentSelectRecordsModal
					onSelectRecords={handleSelectRecords}
					onClose={() => setAttachmentModal(false)}
					channelWorkspaceId={channelWorkspaceId}
				/>
			) : null}
		</>
	);
}
