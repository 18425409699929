import { useMemo } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { Icon, Spinner, Tooltip, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { useGetObjectById } from "../../../hooks";
import { PATHS } from "../../../routes";
import { getHuddleMeetingDetails, HuddleStatus } from "../../meetings/utils";

type HuddleButtonProps = {
	activeHuddle?: SDRecord;
	createHuddle?: () => void;
	isLoadingHuddle?: boolean;
	variant?: "banner" | "panel";
};

export function HuddleButton({ activeHuddle, createHuddle, isLoadingHuddle, variant = "panel" }: HuddleButtonProps) {
	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const huddleMeetingDetails = useMemo(() => {
		if (!meetingObject || !activeHuddle) {
			return null;
		}
		return getHuddleMeetingDetails(meetingObject, activeHuddle);
	}, [meetingObject, activeHuddle]);

	const huddleStatus = huddleMeetingDetails?.huddleStatus;
	const noActiveHuddle = !activeHuddle || huddleStatus === HuddleStatus.INACTIVE;

	const buttonClasses = clsx(
		noActiveHuddle && (variant === "banner" ? tw`hover:bg-c_bg_02` : tw`hover:bg-c_bg_01/15`),
		tw` focus-visible:ring-c_action_focus flex size-8 rounded-full justify-center shrink-0 items-center bg-transparent`
	);

	if (isLoadingHuddle) {
		return (
			<div className="flex size-8 items-center justify-center">
				<Spinner size="sm" darkMode={variant === "panel"} />
			</div>
		);
	}

	return (
		<div
			className={clsx(
				"size-8 rounded-full",
				huddleStatus === HuddleStatus.IN_PROGRESS && "bg-c_success_04 text-c_success_03",
				huddleStatus === HuddleStatus.ACTIVE && "bg-c_bg_02 text-c_action_01",
				noActiveHuddle && (variant === "banner" ? "text-c_icon_regular" : "text-c_icon_inverted")
			)}
		>
			{noActiveHuddle && !createHuddle ? null : (
				<Tooltip text={noActiveHuddle ? "Start huddle" : "Join huddle"}>
					{noActiveHuddle ? (
						<button
							className={buttonClasses}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								createHuddle?.();
							}}
						>
							<Icon icon={ICONS.videoCamera} />
						</button>
					) : (
						<Link className={buttonClasses} to={PATHS.MEETING(activeHuddle)}>
							<Icon icon={ICONS.videoCamera} />
						</Link>
					)}
				</Tooltip>
			)}
		</div>
	);
}
