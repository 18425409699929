import { useMemo } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { AbilityAction, AbilitySubject, RecordQueryClauses, SDObject, sdSubject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { AssociationSortingSelect } from "./AssociationSortingSelect";
import { LinkingModalTabs } from "../AssociationLinkingModal";
import { AssociationFilteringSelect } from "./AssociationFilteringSelect";
import { useWebPrincipal } from "../../../../auth";
import { MenuItem, PopoverMenu } from "../../../menu";
import { DebouncedSearchbar } from "../../../inputs";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { SortingDetails } from "../../../records";
import { RecordAssociationControlsVariant } from "../../types";

interface RecordAssociationsControlsProps {
	associationObject: SDObject;
	onFiltersChange: (filters: RecordQueryClauses[]) => void;
	onSortingChange: (sorting: SortingDetails[]) => void;
	onQueryChange: (query: string) => void;
	openLinkingModal?: (modalTab?: LinkingModalTabs) => void;
	showFilterAndSortingControls?: boolean;
	showLinkingControls?: boolean;
	initialLoading?: boolean;
	variant?: RecordAssociationControlsVariant;
}

export function RecordAssociationsControls({
	associationObject,
	onFiltersChange,
	onSortingChange,
	onQueryChange,
	openLinkingModal,
	showFilterAndSortingControls,
	showLinkingControls,
	initialLoading,
	variant = "default",
}: RecordAssociationsControlsProps) {
	const principal = useWebPrincipal();

	const menuItems = useMemo(() => {
		if (!openLinkingModal) {
			return [];
		}

		const pluralName = associationObject?._pluralName;
		const menuItems: MenuItem[] = [
			{
				type: "button",
				icon: ICONS.link,
				text: `Link ${pluralName}`,
				onClick: () => {
					openLinkingModal(LinkingModalTabs.linking);
				},
			},
		];

		if (
			associationObject &&
			principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: associationObject._id })) &&
			associationObject._id !== mWorkspaceDef.ID
		)
			menuItems.push({
				type: "button",
				icon: ICONS.plus,
				text: `Add new ${pluralName}`,
				onClick: () => {
					openLinkingModal(LinkingModalTabs.create);
				},
			});

		return menuItems;
	}, [openLinkingModal, associationObject, principal]);

	const showLinkingMenu = menuItems.length && showLinkingControls;
	const showHeader = showLinkingMenu || showFilterAndSortingControls;

	if (initialLoading) {
		return (
			<div className="flex h-[38px] items-center">
				<div className="flex gap-2">
					<Skeleton className="w-22 h-8" />
					<Skeleton className="w-22 h-8" />
				</div>
				<div className="ml-auto flex items-center gap-4">
					<Skeleton className="size-8" />
					<Skeleton className="size-8" />
				</div>
			</div>
		);
	}

	if (!showHeader) return null;

	const isSmallVariant = variant === "sm";

	return (
		<div className="flex items-center">
			{showFilterAndSortingControls ? (
				<div className="flex items-center gap-2">
					<AssociationFilteringSelect
						associationSDObject={associationObject}
						onChange={onFiltersChange}
						iconOnly={isSmallVariant}
					/>
					<AssociationSortingSelect sdObject={associationObject} onChange={onSortingChange} iconOnly={isSmallVariant} />
				</div>
			) : null}
			<div className="ml-auto flex items-center gap-4">
				{showFilterAndSortingControls ? (
					<DebouncedSearchbar onChange={onQueryChange} size={isSmallVariant ? 18 : undefined} />
				) : null}
				{showLinkingMenu ? (
					<PopoverMenu menuContents={menuItems}>
						<Button size="sm" startIcon={ICONS.plus} variant="secondary" />
					</PopoverMenu>
				) : null}
			</div>
		</div>
	);
}
