import { useEffect, useMemo, useRef, useState } from "react";
import { JSONContent } from "@tiptap/core";
import { isEqual } from "lodash";

import { SDObject, SDRecord, getSDObjectSystemFieldByName, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";
import { mDocDef } from "@salesdesk/salesdesk-model";

import { debounce } from "../../../../../utils";
import { useUpdateRecord } from "../../../../records";

export function useDocumentEditorState(documentObject?: SDObject, documentRecord?: SDRecord) {
	// Set to null when no editorContents set (to distinguish from empty contents)
	const [editorContents, setEditorContents] = useState<JSONContent | null | undefined>(null);

	const { updateRecord, isLoading } = useUpdateRecord();

	const documentContentsRecordField = useMemo(() => {
		if (!documentObject) {
			return null;
		}

		const contentsField = getSDObjectSystemFieldByName(documentObject, mDocDef.DOCUMENT_FIELD_NAME);
		if (!documentRecord || !contentsField) {
			return null;
		}

		return getSDRecordFieldValue(documentRecord, contentsField._id);
	}, [documentObject, documentRecord]);

	const fieldValueRef = useRef<JSONContent | undefined>(documentContentsRecordField?._value || undefined);
	fieldValueRef.current = documentContentsRecordField?._value || undefined;

	const haveField = Boolean(documentContentsRecordField);

	// Reset the editor contents when the document record changes
	useEffect(() => {
		if (haveField) {
			setEditorContents(fieldValueRef.current);
		}
	}, [documentRecord, haveField]);

	const debouncedUpdateRecord = useMemo(
		() =>
			debounce(async (updatedValue: JSONContent) => {
				if (!documentRecord || !documentContentsRecordField) return;

				updateRecord({
					record: documentRecord,
					updatedFields: [{ ...documentContentsRecordField, _value: updatedValue }],
					preventOptimisticUpdate: true,
				});
			}, 500),
		[documentRecord, documentContentsRecordField, updateRecord]
	);

	const [unsychronizedChanges, setUnsychronizedChanges] = useState<boolean>(false);

	useEffect(() => {
		setUnsychronizedChanges(
			!(editorContents == null && documentContentsRecordField?._value == null) &&
				!isEqual(editorContents, documentContentsRecordField?._value)
		);
	}, [editorContents, documentContentsRecordField]);

	return {
		editorContents,
		onEditorContentsChange: (updatedValue: JSONContent) => {
			setEditorContents(updatedValue);
			debouncedUpdateRecord(updatedValue);
		},
		isSaving: isLoading || unsychronizedChanges,
	};
}
