import { Avatar, getInitials } from "../../../../../users";
import { DisplayFieldVariant } from "../../../../types";
import { AvatarSize } from "../../../../../users";
import { useProfilePhotoInfo } from "../hooks";
import { ProfilePhotoFieldValue } from "../types";

interface DisplayProfilePhotoFieldProps {
	variant?: DisplayFieldVariant;
	value: ProfilePhotoFieldValue;
}

export function DisplayProfilePhotoField({ variant, value }: DisplayProfilePhotoFieldProps) {
	const { fileId, fullName } = useProfilePhotoInfo(value);

	const cardVariant = variant === DisplayFieldVariant.card || variant === DisplayFieldVariant.cardTitle;
	const avatarSize: AvatarSize = cardVariant ? "xl" : "sm";

	if (!value) {
		return null;
	}

	return (
		<Avatar initials={fullName ? getInitials(fullName) : ""} avatarFileId={fileId || undefined} size={avatarSize} />
	);
}
