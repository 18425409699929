import { BooleanInputProps, Checkbox, ToggleSwitch } from "../../../../../inputs";
import { EditFieldProps } from "../../../../types";
import { BooleanFieldVariant } from "../types";

interface EditBooleanFieldProps extends BooleanInputProps, Omit<EditFieldProps, "value" | "onChange"> {
	variant?: BooleanFieldVariant;
}

export function EditBooleanField({
	inputRef,
	variant = BooleanFieldVariant.CHECKBOX,
	hasError,
	...booleanComponentProps
}: EditBooleanFieldProps) {
	if (variant === BooleanFieldVariant.CHECKBOX) {
		return <Checkbox ref={inputRef} {...booleanComponentProps} />;
	}
	return <ToggleSwitch ref={inputRef} {...booleanComponentProps} />;
}
