import { PropsWithChildren } from "react";

import { Tab } from "@headlessui/react";
import clsx from "clsx";

export type TabVariant = "text" | "icon";

interface TabListProps {
	className?: string;
	variant?: TabVariant;
}

export function TabList({ children, variant, className }: PropsWithChildren<TabListProps>) {
	return (
		<Tab.List className={clsx("bg-c_bg_02 flex rounded-full p-1", variant === "icon" ? "gap-1" : "gap-2", className)}>
			{children}
		</Tab.List>
	);
}
