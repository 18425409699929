import { PropsWithChildren, useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { Sticky } from "../../../../../components/Sticky";
import { Breadcrumbs } from "../../../../../components/Breadcrumbs/Breadcrumbs";
import { Badge } from "../../../../../components/Badge";
import { useGetWorkspaceBaseBreadcrumbs } from "../../../hooks/useGetWorkspaceBaseBreadcrumbs";

interface InternalAreaHeaderProps {
	height: number;
	pageName?: string;
}

export function InternalAreaHeader({ height, pageName, children }: PropsWithChildren<InternalAreaHeaderProps>) {
	const baseBreadcrumbs = useGetWorkspaceBaseBreadcrumbs();

	const crumbs = useMemo(() => {
		const crumbs = [...baseBreadcrumbs];

		if (pageName) {
			crumbs.push({
				node: pageName,
			});
		}

		return crumbs;
	}, [baseBreadcrumbs, pageName]);

	return (
		<Sticky
			priority={2}
			height={height}
			style={{ height }}
			showCover
			className="bg-c_bg_01 z-20 flex flex-col gap-8 py-8 pb-10"
		>
			<div className="flex min-h-[38px] items-center justify-between">
				<Breadcrumbs crumbs={crumbs} canCopyLink={true} />
				<Badge text="This page is for internal use only." icon={ICONS.info} color="c_warning_04" size="lg" />
			</div>
			{children}
		</Sticky>
	);
}
