import { Spinner } from "@salesdesk/daisy-ui";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { getSDObjectFieldByName, SDRecord } from "@salesdesk/salesdesk-schemas";

import { RecordCreateButton } from "../../../../../../records";
import { useGetObjectById } from "../../../../../../../hooks";
import { useChannelMemberRecordIds } from "../../../../../hooks";
import { useMemo, useCallback } from "react";

import { useChimeMessagingContext } from "../../../../providers";
import { ChannelMessageType } from "@salesdesk/salesdesk-schemas";

interface ChannelBookMeetingButtonProps {
	arn: string;
}

export function ChannelBookMeetingButton({ arn }: ChannelBookMeetingButtonProps) {
	const { sendMessage } = useChimeMessagingContext();
	const { sdObject: meetingSDObject, isLoading: meetingSDObjectLoading } = useGetObjectById(mInteractionMeetingDef.ID);
	const memberRecordIds = useChannelMemberRecordIds(arn);

	const initialValues = useMemo(() => {
		if (!meetingSDObject || !memberRecordIds.length) return undefined;

		const participantField = getSDObjectFieldByName(meetingSDObject, mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME);

		if (!participantField) return undefined;

		return {
			[participantField._id]: memberRecordIds,
		};
	}, [meetingSDObject, memberRecordIds]);

	const sendMessageOnCreation = useCallback(
		async (meetingRecord: SDRecord) => {
			if (!sendMessage) return;

			await sendMessage(arn, undefined, {
				messageType: ChannelMessageType.Standard,
				attachments: { recordIds: [meetingRecord._id] },
			});
		},
		[arn, sendMessage]
	);

	return meetingSDObject && !meetingSDObjectLoading ? (
		<RecordCreateButton
			sdObject={meetingSDObject}
			initialValueMap={initialValues}
			tooltipText={`Create ${meetingSDObject._displayName}`}
			startIcon={ICONS.calendar}
			size="sm"
			variant="text"
			isLoading={meetingSDObjectLoading}
			onRecordCreated={sendMessageOnCreation}
		/>
	) : (
		<div className="flex size-8 items-center justify-center">
			<Spinner size="sm" />
		</div>
	);
}
