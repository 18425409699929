import { useGetObjectById } from "../../../../../../../../../../hooks";
import { mProductDef } from "@salesdesk/salesdesk-model";
import { AddRecordButton } from "../../../../../../WorkspaceResources";
import { useMemo } from "react";
import { ThemableSvgLoader } from "../../../../../../../../../graphics/ThemableSvgLoader";

export function ProductPlaceholder() {
	const objectIdArray = useMemo(() => [mProductDef.ID], []);
	const { sdObject: productObject } = useGetObjectById(mProductDef.ID);

	if (!productObject) {
		return null;
	}

	return (
		<div className="flex flex-col items-center gap-4">
			<div className="text-c_text_placeholder w-2/3 text-center">
				Select {productObject._pluralName.toLowerCase()} that you’d like to share with your customers here...
			</div>
			<AddRecordButton id="userflow-add-product-here" sdObjectIds={objectIdArray} variant="primary">
				Add {productObject._displayName.toLowerCase()}
			</AddRecordButton>
			<div className="h-[286px]">
				<ThemableSvgLoader name="products_graphic" />
			</div>
		</div>
	);
}
