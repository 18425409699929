import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { JSONContent } from "@tiptap/core";

import { RichTextInput } from "../../inputs";
import { NotesVariant } from "../types";
import clsx from "clsx";
import { Button } from "@salesdesk/daisy-ui";

export type OnCreateNote = (jsonContent: JSONContent, textContent: string, onSuccess: () => void) => void;

interface Props {
	onSubmit: OnCreateNote;
	isCreating?: boolean;
	value?: JSONContent;
	onChange?: (value: JSONContent) => void;
	variant?: NotesVariant;
}

export function CreateNote({ onSubmit, isCreating, variant = "default" }: Props) {
	const [jsonContent, setJsonContent] = useState<JSONContent>();
	const [textContent, setTextContent] = useState<string>();
	const [hasError, setHasError] = useState(false);

	const isEmpty = !textContent || textContent.trim() === "";

	const handleSubmit = useCallback(
		(e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
			e.preventDefault();
			if (!isEmpty && jsonContent) {
				setHasError(false);
				onSubmit(jsonContent, textContent, () => {
					setJsonContent(undefined);
					setTextContent(undefined);
				});
			} else {
				setHasError(true);
			}
		},
		[onSubmit, isEmpty, jsonContent, textContent]
	);

	const inputRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const inputElement = inputRef.current;
		if (!inputElement) return;
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Enter" && e.metaKey) {
				handleSubmit(e);
			}
		};
		inputElement.addEventListener("keydown", handleKeyDown);
		return () => {
			inputElement.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleSubmit]);

	const inRightPanel = variant === "RightPanel";
	return (
		<form
			className={clsx(
				!inRightPanel &&
					"@xl/notes:border-0 @xl/notes:basis-1/2 @xl/notes:shrink-0 @xl/notes:max-w-md @xl/notes:max-h-full border-c_border_regular h-fit border-t pb-4",
				"flex max-h-64 flex-col gap-4 pt-4"
			)}
			onSubmit={handleSubmit}
		>
			<RichTextInput
				ref={inputRef}
				onChange={(jsonContent, textContent) => {
					setJsonContent(jsonContent);
					setTextContent(textContent);
				}}
				hideToolbar
				placeholder="Add a note"
				hasError={hasError}
				disabled={isCreating}
				value={jsonContent}
				disableModEnter
				heightClassName="max-h-28"
				enableMentions
				bottomPanelOptions={{
					showEmojis: true,
					showMentions: true,
					rightComponent: (
						<Button type="submit" size="sm" isLoading={isCreating} disabled={isEmpty}>
							Submit
						</Button>
					),
				}}
				isCollapsible
			/>
		</form>
	);
}
