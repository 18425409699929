import { PropsWithChildren } from "react";
import { Icon, Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface ObjectPreviewFieldControlsProps {
	onRemove?: () => void;
	isDraggable?: boolean;
}

export function ObjectPreviewFieldControls({
	onRemove,
	isDraggable,
	children,
}: PropsWithChildren<ObjectPreviewFieldControlsProps>) {
	return (
		<div className="relative flex w-full items-center justify-center">
			<div className="absolute -left-[46px] flex w-full max-w-[46px] justify-center">
				{isDraggable ? <Icon className="text-c_icon_regular" icon={ICONS.grabbable} /> : null}
			</div>
			<div className="w-full">{children}</div>
			<div className="absolute -right-[42px] flex w-full max-w-[42px] justify-center px-1">
				{onRemove ? <Button startIcon={ICONS.trash} onClick={onRemove} variant="text" size="sm" /> : null}
			</div>
		</div>
	);
}
