import { SelectOption } from "../../../../../inputs";
import { Strong } from "./Strong";
import { ANY } from "../../../types/Any";

export function RecordName({ recordOption, anyPrefix = "for" }: { recordOption?: SelectOption; anyPrefix?: string }) {
	return recordOption ? (
		<>
			{recordOption.id === ANY ? ` ${anyPrefix} ` : " in "}
			<Strong>{recordOption.id === ANY ? recordOption.name.toLowerCase() : recordOption.name}</Strong>
		</>
	) : null;
}
