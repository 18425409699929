import { mSingleOptionField } from "../single_option_field_inst";
import { mBooleanFieldDef } from "./boolean_field_def";

export class mBooleanField extends mSingleOptionField {
	
	constructor(id, field) {		
		super(id, field);
		
		// Should always have a value set (true or false).
		if(field && !field.hasDefaultValue()){
			this._value = this.field.getOptionValueByName(mBooleanFieldDef.FALSE).id;
		}
	}

	displayValue() {
		return this.booleanValue ? "✔" : "X";
	}

	get booleanValue(){

		try {
			return this.field.getOptionValueById(this._value).name === mBooleanFieldDef.TRUE;
		}
		catch(error){

			// In case we have no value in this instance, get the false value and set it

			const optionValue = this.field.getOptionValueByName(mBooleanFieldDef.FALSE)
			this.value = optionValue.id;
			return false;
		}
	}

	containsValue(value){
		
		if(this._value && value){
			return this._value === value.id;
		}
		return false;
	}
}