import { useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { HoldingPageDetailsSection } from "./HoldingPageDetailsSection";
import { RecordAssociations, DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";
import { capitalizeString } from "@salesdesk/salesdesk-utils";
import { useGetContextWorkspaceId } from "../../../../../../../workspaces";

interface AssociationDetailsSectionProps {
	meetingRecord: SDRecord;
	objectAssociation: DirectedSDObjectAssociation;
}

export function AssociationDetailsSection({ meetingRecord, objectAssociation }: AssociationDetailsSectionProps) {
	const workspaceId = useGetContextWorkspaceId();
	const inWorkspace = workspaceId != null;

	const [associationsCount, setAssociationsCount] = useState(0);

	const title = capitalizeString(objectAssociation.connectedObject.label);

	return (
		<HoldingPageDetailsSection title={title} hidden={inWorkspace ? false : associationsCount < 1}>
			<RecordAssociations
				sourceRecord={meetingRecord}
				objectAssociation={objectAssociation}
				onUpdateCount={setAssociationsCount}
				openingVariant={inWorkspace ? "workspace" : "recordDetailView"}
			/>
		</HoldingPageDetailsSection>
	);
}
