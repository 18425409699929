import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
	autoUpdate,
	flip,
	offset,
	safePolygon,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFloatingNodeId,
	useFloatingParentNodeId,
	useFloatingTree,
	useHover,
	useInteractions,
	useListItem,
	useListNavigation,
	useRole,
	useTypeahead,
	Placement,
} from "@floating-ui/react";

import { MenuContext } from "./MenuContext";

const FLOATING_MENU_OFFSET = 8;
const FLOATING_MENU_NESTED_OFFSET = -FLOATING_MENU_OFFSET;
const FLOATING_MENU_SHIFT_PADDING = 4;

export const useFloatingMenu = (placement?: Placement, onOpenChange?: (open: boolean) => void) => {
	const [isOpen, setIsOpen] = useState(false);
	const [hasFocusInside, setHasFocusInside] = useState(false);
	const [returnFocus, setReturnFocus] = useState(true);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
	const labelsRef = useRef<Array<string | null>>([]);

	const parent = useContext(MenuContext);

	const tree = useFloatingTree();
	const nodeId = useFloatingNodeId();
	const parentId = useFloatingParentNodeId();
	const listItem = useListItem();

	const isNested = parentId != null;

	const { context: floatingContext } = useFloating<HTMLButtonElement>({
		nodeId,
		open: isOpen,
		onOpenChange: (open) => {
			setIsOpen(open);
			setReturnFocus(true);
			onOpenChange?.(open);
		},
		placement: isNested ? placement || "right-start" : placement || "bottom-end",
		middleware: [
			offset(isNested ? FLOATING_MENU_NESTED_OFFSET : FLOATING_MENU_OFFSET),
			flip({
				fallbackAxisSideDirection: "end",
				padding: FLOATING_MENU_SHIFT_PADDING,
			}),
			shift({ padding: FLOATING_MENU_SHIFT_PADDING }),
		],
		whileElementsMounted: autoUpdate,
	});

	const hover = useHover(floatingContext, {
		enabled: isNested,
		handleClose: safePolygon({ blockPointerEvents: true }),
	});
	const click = useClick(floatingContext, {
		event: "mousedown",
		toggle: !isNested,
		ignoreMouse: isNested,
	});
	const role = useRole(floatingContext, { role: "menu" });
	const dismiss = useDismiss(floatingContext, { bubbles: true });
	const listNavigation = useListNavigation(floatingContext, {
		listRef: elementsRef,
		activeIndex,
		nested: isNested,
		onNavigate: setActiveIndex,
	});
	const typeahead = useTypeahead(floatingContext, {
		listRef: labelsRef,
		onMatch: isOpen ? setActiveIndex : undefined,
		activeIndex,
	});

	const interactions = useInteractions([hover, click, role, dismiss, listNavigation, typeahead]);

	// Event emitter allows you to communicate across tree components.
	// This effect closes all menus when an item gets clicked anywhere
	// in the tree.
	useEffect(() => {
		if (!tree) return;

		function handleTreeClick() {
			setIsOpen(false);
			setReturnFocus(false);
		}

		function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
			if (event.nodeId !== nodeId && event.parentId === parentId) {
				setIsOpen(false);
			}
		}

		tree.events.on("click", handleTreeClick);
		tree.events.on("menuopen", onSubMenuOpen);

		return () => {
			tree.events.off("click", handleTreeClick);
			tree.events.off("menuopen", onSubMenuOpen);
		};
	}, [tree, nodeId, parentId]);

	useEffect(() => {
		if (isOpen && tree) {
			tree.events.emit("menuopen", { parentId, nodeId });
		}
	}, [tree, isOpen, nodeId, parentId]);

	return useMemo(
		() => ({
			nodeId,
			isOpen,
			listItem,
			parent,
			isNested,
			hasFocusInside,
			setHasFocusInside,
			activeIndex,
			setActiveIndex,
			...interactions,
			floatingListRefs: { elementsRef, labelsRef },
			floatingContext,
			returnFocus,
		}),
		[
			nodeId,
			isOpen,
			listItem,
			parent,
			isNested,
			hasFocusInside,
			setHasFocusInside,
			activeIndex,
			setActiveIndex,
			interactions,
			elementsRef,
			labelsRef,
			floatingContext,
			returnFocus,
		]
	);
};
