import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";
import { createUniqueId } from "@salesdesk/salesdesk-utils";

import {
	mIntegerFieldDef,
	mObjectFieldDef,
	mRichTextFieldDef,
	mSingleOptionFieldDef,
	mStringFieldDef,
} from "../../../fields";

import { mAssetDef } from "../../asset";
import { mTransientDef } from "../transient_def";

export class mMediaShareDef extends mTransientDef {
	static CLASS_NAME = "mMediaShareDef";
	static NAME = "Share Media";
	static PLURAL_NAME = "Share Media";
	static ICON = "fa-share-from-square";

	static ID = 102;

	constructor(ownerId, context) {
		super(mMediaShareDef.ID, ownerId, context);

		super.className = mMediaShareDef.CLASS_NAME;
		super.name = mMediaShareDef.NAME;
		super.pluralName = mMediaShareDef.PLURAL_NAME;
		super.displayName = "Media Share";

		super.description = "Media sharing data";
		super.icon = mMediaShareDef.ICON;

		super.commentsSupported = false;

		super.data.name = "mediaShareDetails";
		super.data.pluralName = "mediaShareDetails";
		super.data.displayName = "Media Share Details";

		this._supportsTableCardView = false;

		let media = new mObjectFieldDef(createUniqueId());
		media.objectDefId = mAssetDef.ID;
		media.name = "media";
		media.pluralName = "media";
		media.displayName = "Media";
		media.editable = false;
		media.hidden = true;

		let title = new mStringFieldDef(createUniqueId());
		title.name = "title";
		title.pluralName = "title";
		title.displayName = "Title";
		title.required = true;

		let subject = new mSingleOptionFieldDef(createUniqueId());
		subject.name = "subject";
		subject.pluralName = "subjects";
		subject.displayName = "Subject";
		subject.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		subject.required = true;
		subject.optionValues = [
			{
				id: 1,
				name: "Training",
				icon: "fa-chalkboard-user",
				color: "#037f4c",
			},
			{
				id: 2,
				name: "Product Demo",
				icon: "fa-laptop",
				color: "#cab641",
			},
			{
				id: 3,
				name: "Product Overview",
				icon: "fa-book",
				color: "#ffadad",
			},
			{
				id: 4,
				name: "Presentation",
				icon: "fa-presentation-screen",
				color: "#ff5ac4",
			},
			{
				id: 5,
				name: "Commercial",
				icon: "fa-money-check-dollar",
				color: "#784bd1",
			},
			{
				id: 6,
				name: "Legal",
				icon: "fa-file-contract",
				color: "#579bfc",
			},
		];

		let notes = new mRichTextFieldDef(createUniqueId());
		notes.name = "notes";
		notes.pluralName = "notes";
		notes.displayName = "Notes";
		notes.formatDescription = "Notes to recipients";
		notes.componentType = FIELD_COMPONENT_TYPES.RICH_TEXT.name;
		notes.maxLength = 1000;
		notes.required = true;

		let objectId = new mIntegerFieldDef(createUniqueId());
		objectId.name = "objectId";
		objectId.pluralName = "objectId";
		objectId.displayName = "Object Id";
		objectId.formatDescription = "Object Id";
		objectId.editable = false;
		objectId.hidden = true;

		super.data.addChild(title);
		super.data.addChild(media);
		super.data.addChild(subject);
		super.data.addChild(notes);
	}
}
