import { Role } from "@salesdesk/salesdesk-schemas";
import { NavButton } from "../../../../../components/NavButton";
import { useMemo } from "react";
import { MenuItem } from "../../../../menu";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { PATHS } from "../../../../../routes";
import { CreateRoleButton } from "./CreateRoleButton";
import clsx from "clsx";

interface RoleMenuProps {
	roles: Role[] | undefined;
	isLoading: boolean;
}

export function RoleMenu({ roles, isLoading }: RoleMenuProps) {
	const menuItems = useMemo(() => {
		return roles?.map((role) => {
			return {
				type: "link",
				text: role.name,
				link: PATHS.ACCESS_ROLE(role.id),
			} satisfies MenuItem;
		});
	}, [roles]);

	return (
		<div className="border-r-c_border_regular flex h-full w-48 shrink-0 flex-col gap-2 border-r">
			<div className="grow pr-4">
				{isLoading
					? Array.from({ length: 5 }).map((_, index) => (
							<div key={index} className="py-3">
								<Skeleton className={clsx("ml-6 h-5", index % 2 === 0 ? "w-2/3" : "w-1/2")} />
							</div>
						))
					: menuItems?.map((menuItem) => <NavButton key={menuItem.text} item={menuItem} variant="secondary" />)}
			</div>
			<CreateRoleButton />
		</div>
	);
}
