import clsx from "clsx";
import {
	mapDateTimeLocalIsoStringToUnixTimestampOrNull,
	mapUnixTimestampToDateTimeLocalIsoString,
} from "@salesdesk/salesdesk-utils";
import { PickerTextInputProps } from "../../types";
import {
	ChangeEvent,
	FocusEventHandler,
	forwardRef,
	KeyboardEventHandler,
	useCallback,
	useImperativeHandle,
	useRef,
} from "react";

import { applyAndCancelKeyCapturing } from "@salesdesk/daisy-ui";
import { TimeRange } from "@salesdesk/salesdesk-schemas";

import { DateTimeFieldVariant } from "../../../fields";
import { tw } from "../../../../utils/tailwind-helpers";
import { AutoScaleDateInput } from "./AutoScaleDateInput";

export const TimeRangeTextInput = forwardRef<HTMLInputElement, PickerTextInputProps<TimeRange>>(
	(
		{
			id,
			variant = DateTimeFieldVariant.DATE,
			value,
			onChange,
			onFocus = () => undefined,
			onBlur = () => undefined,
			onApply,
			onCancel,
			calenderIcon = null,
			min,
			max,
			disabled,
			ariaAttributes,
		},
		ref
	) => {
		const isDateOnly = variant === DateTimeFieldVariant.DATE;

		// Blurring handlers are to allow editing both inputs in the right hand panel by not sending blur events if you're
		// tabbing between the inputs (i.e. otherwise tabbing out of start input would cause the InlineEditFieldFactory to enter non-edit mode)
		const startRef = useRef<HTMLInputElement>(null);
		const endRef = useRef<HTMLInputElement>(null);
		useImperativeHandle(ref, () => startRef.current as HTMLInputElement);
		const onStartOrEndBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
			(event) => {
				// Timeout because onBlur happens before onFocus
				setTimeout(() => {
					if (
						document.activeElement !== (event.target === startRef.current ? endRef.current : startRef.current) &&
						onBlur != null
					)
						onBlur(event);
				}, 50);
			},
			[startRef, endRef, onBlur]
		);

		const onStartChange = useCallback(
			(start: number | null) => {
				const end = value?.end || null;
				if (start == null && end == null) onChange(null);
				else onChange({ start: start, end: end });
			},
			[onChange, value]
		);

		const onEndChange = useCallback(
			(end: number | null) => {
				const start = value?.start || null;
				if (start == null && end == null) onChange(null);
				else onChange({ start: start, end: end });
			},
			[onChange, value]
		);

		const onSpaceBarPreventDefaultPicker = useCallback<KeyboardEventHandler<HTMLDivElement>>((event) => {
			if (event.key === " ") event.preventDefault();
		}, []);

		const defaultMax = isDateOnly ? "9999-12-31" : "9999-12-31T23:59";
		const inputStyle = clsx(
			tw`max-h-10 text-body-sm bg-transparent py-2 box-content`,
			disabled && tw`cursor-not-allowed`
		);
		const inputType = isDateOnly ? "date" : "datetime-local";
		const minIsoString = mapUnixTimestampToDateTimeLocalIsoString(min, isDateOnly);
		const maxIsoString = max ? mapUnixTimestampToDateTimeLocalIsoString(max, isDateOnly) : defaultMax;

		const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
			(event) => {
				onSpaceBarPreventDefaultPicker(event);
				applyAndCancelKeyCapturing<HTMLDivElement>(event, onApply, onCancel);
			},
			[onApply, onCancel, onSpaceBarPreventDefaultPicker]
		);

		return (
			<div className="flex items-center justify-between">
				<div className="flex items-center pl-2.5">
					<AutoScaleDateInput
						ref={startRef}
						data-hide-picker-icon="true"
						id={id}
						className={inputStyle}
						type={inputType}
						min={minIsoString}
						max={maxIsoString}
						value={mapUnixTimestampToDateTimeLocalIsoString(value?.start, isDateOnly)}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							onStartChange(mapDateTimeLocalIsoStringToUnixTimestampOrNull(e.target.value, isDateOnly));
						}}
						onBlur={onStartOrEndBlur}
						onFocus={onFocus}
						disabled={disabled ?? false}
						onKeyDown={handleKeyDown}
						{...ariaAttributes}
						aria-label="Start"
					/>
					<span className="px-1.5">-</span>
					<AutoScaleDateInput
						ref={endRef}
						data-hide-picker-icon="true"
						className={inputStyle}
						type={inputType}
						min={minIsoString}
						max={maxIsoString}
						value={mapUnixTimestampToDateTimeLocalIsoString(value?.end, isDateOnly)}
						onChange={(e) => {
							onEndChange(mapDateTimeLocalIsoStringToUnixTimestampOrNull(e.target.value, isDateOnly));
						}}
						onBlur={onStartOrEndBlur}
						onFocus={onFocus}
						onKeyDown={handleKeyDown}
						disabled={disabled}
						{...ariaAttributes}
						aria-label="End"
					/>
				</div>
				{calenderIcon}
			</div>
		);
	}
);
