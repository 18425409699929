import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { ControlPopover } from "./ControlPopover";
import { useMemo, useRef, useState } from "react";
import { CorePopover } from "../../../../../components/Popover/Popover";
import { debounce } from "../../../../../utils";
import { PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { Button } from "@salesdesk/daisy-ui";

const VOLUME_BAR_HEIGHT = 100;

export function VideoVolumeControl() {
	const { variant, volume, updateVolume, toggleMute } = useVideoPlayerContext();
	const volumeBaseRef = useRef<HTMLDivElement | null>(null);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const currentBarHeight = volume * VOLUME_BAR_HEIGHT;

	const onMouseMove = useMemo(
		() =>
			debounce((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				if (volumeBaseRef.current === null) return;
				e.stopPropagation();
				e.preventDefault();
				const { top } = volumeBaseRef.current.getBoundingClientRect();
				const value = 1 - (e.clientY - top) / VOLUME_BAR_HEIGHT;
				const newVolume = Math.min(Math.max(value, 0), 1);
				if (e.buttons === 1) {
					setIsMouseDown(true);
					updateVolume(newVolume);
				} else {
					setIsMouseDown(false);
				}
			}, 5),
		[volumeBaseRef, updateVolume]
	);

	const getSpeakerIcon = () => {
		if (volume === 0) {
			return ICONS.speakerSlash;
		}
		if (volume < 0.5) {
			return ICONS.speakerLow;
		}
		return ICONS.speakerHigh;
	};

	const isExpanded = variant === "expanded";

	return (
		<CorePopover
			placement="top"
			isHoverPopover={!isOpen || !isMouseDown}
			onOpenChange={setIsOpen}
			useFloatingPortal={false}
		>
			<PopoverTrigger>
				<Button
					startIcon={getSpeakerIcon()}
					variant="text_inverted"
					size={isExpanded ? "sm" : "xs"}
					onClick={(e) => {
						toggleMute();
						e.stopPropagation();
					}}
				/>
			</PopoverTrigger>
			<PopoverContent>
				<ControlPopover customOffset={isExpanded ? "-0.5rem" : "-0.35rem"}>
					<div
						role="slider"
						tabIndex={0}
						aria-label="volume control"
						aria-valuemax={100}
						aria-valuemin={0}
						aria-valuenow={volume}
						aria-valuetext={`${Math.round(volume * 100)}%`}
						onMouseMove={onMouseMove}
						onMouseDown={(e) => {
							onMouseMove(e);
							e.preventDefault();
						}}
						className="relative flex w-8 cursor-pointer justify-center py-2"
					>
						{/* volume bar background */}
						<div className="bg-c_bg_03 w-1 rounded-t-md" ref={volumeBaseRef} style={{ height: VOLUME_BAR_HEIGHT }} />
						{/* volume bar */}
						<div
							className="bg-c_action_02 absolute bottom-0 w-1 rounded-b-md"
							style={{ height: `${currentBarHeight}px` }}
						/>
						{/* volume bar handle */}
						<div
							className="bg-c_action_04 absolute left-1/2 -ml-2 h-4 w-4 rounded-full"
							style={{ bottom: `${currentBarHeight}px` }}
						/>
					</div>
				</ControlPopover>
			</PopoverContent>
		</CorePopover>
	);
}
