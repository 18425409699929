import { useState } from "react";
import clsx from "clsx";

import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { DirectedSDObjectAssociation, RecordAssociations } from "../../../../../../../../recordAssociations";
import { Divider } from "../../../../../../../../../components/Divider/Divider";
import { useMeetingPageContext } from "../../../../../hooks";
import { MeetingInfoSection } from "./MeetingInfoSection";
import { useWorkspaceContext } from "../../../../../../../../workspaces";

interface MeetingAssociationSectionProps {
	objectAssociation: DirectedSDObjectAssociation;
}

export function MeetingAssociationSection({ objectAssociation }: MeetingAssociationSectionProps) {
	const { meetingRecord } = useMeetingPageContext();

	const { workspaceId } = useWorkspaceContext();
	const inWorkspace = workspaceId != null;

	const [associationsCount, setAssociationsCount] = useState(0);

	if (meetingRecord == null) return null;

	const title = capitalizeString(objectAssociation.connectedObject.label);

	const visible = inWorkspace || associationsCount;

	return (
		<>
			{visible ? <Divider className="mx-0" /> : null}
			<div className={clsx(!visible && "hidden")}>
				<MeetingInfoSection title={title} variant="primary">
					<RecordAssociations
						sourceRecord={meetingRecord}
						objectAssociation={objectAssociation}
						onUpdateCount={setAssociationsCount}
						openingVariant={inWorkspace ? "workspace" : "recordDetailView"}
					/>
				</MeetingInfoSection>
			</div>
		</>
	);
}
