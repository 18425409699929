import { asConst } from "json-schema-to-ts";

export const allowedTypesSchema = asConst({
	anyOf: [
		{
			type: "number",
		},
		{
			type: "string",
		},
		{
			type: "boolean",
		},
	],
});
