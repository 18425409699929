import { createUniqueId } from "@salesdesk/salesdesk-utils";

import { mContainerField } from "./container";
import { mDateField } from "./date";
import { mDateTimeField } from "./date_time";
import { mTimeRangeField } from "./time_range";
import { mDurationField } from "./duration";
import { mFileField, mVideoField } from "./file";
import { mOptionsBuilderField } from "./options_builder";
import { mNumberField } from "./number";
import { mBooleanField, mMultiOptionField, mPriorityField, mSingleOptionField } from "./option";
import { mPasswordField } from "./password";
import { mField } from "./field_inst";

import { FIELD_TYPES } from "./utils";

export class FieldInstanceFactory {
	static newInstance(field) {
		return FieldInstanceFactory.newInstanceByType(field.type, field);
	}

	static newInstanceByType(fieldType, field) {
		const id = createUniqueId();

		switch (fieldType) {
			case FIELD_TYPES.DATE.name: {
				return new mDateField(id, field);
			}
			case FIELD_TYPES.DATE_TIME.name: {
				return new mDateTimeField(id, field);
			}
			case FIELD_TYPES.TIME_RANGE.name: {
				return new mTimeRangeField(id, field);
			}
			case FIELD_TYPES.COLOR.name:
			case FIELD_TYPES.EMAIL.name:
			case FIELD_TYPES.ICON_CHOOSER.name:
			case FIELD_TYPES.PHONE_NUMBER.name:
			case FIELD_TYPES.PROBABILITY.name:
			case FIELD_TYPES.PROGRESS.name:
			case FIELD_TYPES.RATING.name:
			case FIELD_TYPES.RICH_TEXT.name:
			case FIELD_TYPES.STRING.name:
			case FIELD_TYPES.URL.name: {
				return new mField(id, field);
			}
			case FIELD_TYPES.DURATION.name: {
				return new mDurationField(id, field);
			}
			case FIELD_TYPES.FILE.name:
			case FIELD_TYPES.IMAGE.name:
			case FIELD_TYPES.PDF.name:
			case FIELD_TYPES.PROFILE_PHOTO.name:
			case FIELD_TYPES.LOGO.name: {
				return new mFileField(id, field);
			}
			case FIELD_TYPES.VIDEO.name: {
				return new mVideoField(id, field);
			}
			case FIELD_TYPES.OPTIONS_BUILDER.name: {
				return new mOptionsBuilderField(id, field);
			}
			case FIELD_TYPES.CURRENCY_VALUE.name:
			case FIELD_TYPES.FLOAT.name:
			case FIELD_TYPES.INTEGER.name: {
				return new mNumberField(id, field);
			}
			case FIELD_TYPES.BOOLEAN.name: {
				return new mBooleanField(id, field);
			}
			case FIELD_TYPES.PRIORITY.name: {
				return new mPriorityField(id, field);
			}
			case FIELD_TYPES.COUNTRY.name:
			case FIELD_TYPES.CURRENCY.name:
			case FIELD_TYPES.ICON.name:
			case FIELD_TYPES.OBJECT.name:
			case FIELD_TYPES.SINGLE_OPTION.name: {
				return new mSingleOptionField(id, field);
			}
			case FIELD_TYPES.MULTI_OPTION.name:
			case FIELD_TYPES.OBJECTS.name:
			case FIELD_TYPES.ROLES.name: {
				return new mMultiOptionField(id, field);
			}
			case FIELD_TYPES.PASSWORD.name: {
				return new mPasswordField(id, field);
			}
			case FIELD_TYPES.CONTAINER.name:
			case FIELD_TYPES.DATA.name: {
				return new mContainerField(id, field);
			}
			default: {
				throw new Error(`Field type "${fieldType}" not recognised!`);
			}
		}
	}
}
