import { Fragment, useEffect, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Editor } from "@tiptap/core";

import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../../components/Popover";
import { isMacOS } from "../../../../../../../utils";
import { SELECT_POPOVER_TRANSITION, SelectOptionId } from "../../../../../../inputs";
import { useSelectCloseTrigger } from "../../../../../../inputs/components/Select/hooks";
import { TEXT_ALIGNMENT_OPTIONS } from "../utils";

interface TextAlignmentSelectProps {
	editor: Editor;
}

export function TextAlignmentSelect({ editor }: TextAlignmentSelectProps) {
	const [textAlignmentOptions] = useState(() => TEXT_ALIGNMENT_OPTIONS(isMacOS() ? "⌘" : "Ctrl"));

	const activeAlignmentOption = textAlignmentOptions.find((option) => option.isActive(editor));
	const activeAlignmentOptionId = activeAlignmentOption?.id ?? textAlignmentOptions[0].id;

	const selectButtonRef = useRef<HTMLButtonElement>(null);
	const { selectKey, triggerSelectClose } = useSelectCloseTrigger(selectButtonRef);

	const [currentAlignmentId, setCurrentAlignmentId] = useState<SelectOptionId>(
		activeAlignmentOptionId ?? textAlignmentOptions[0].id
	);

	const onSelectChange = (optionId?: SelectOptionId) => {
		const alignmentId = optionId ?? textAlignmentOptions[0].id;
		setCurrentAlignmentId(alignmentId);

		textAlignmentOptions.find((option) => option.id === alignmentId)?.action(editor);
	};

	useEffect(() => {
		setCurrentAlignmentId(activeAlignmentOptionId ?? textAlignmentOptions[0].id);
	}, [activeAlignmentOptionId, textAlignmentOptions]);

	return (
		<Listbox key={selectKey} value={currentAlignmentId} onChange={onSelectChange}>
			{({ open }) => (
				<Popover
					placement="bottom-start"
					keepPopoverMounted
					hideWhenClosedButMounted={false}
					open={open}
					onOpenChange={(popoverOpen) => {
						if (!popoverOpen && open) {
							triggerSelectClose();
						}
					}}
				>
					<PopoverTrigger>
						<Tooltip placement="top" text={open ? undefined : "Alignment"} noWrap preventFlip>
							<Listbox.Button as={Fragment}>
								<Button variant="text" startIcon={activeAlignmentOption?.icon} endIcon={ICONS.caretDown} size="xs" />
							</Listbox.Button>
						</Tooltip>
					</PopoverTrigger>
					<PopoverContent initialFocus={-1}>
						<Transition {...SELECT_POPOVER_TRANSITION} as={Fragment}>
							<Listbox.Options>
								<PopoverContainer variant="sm">
									<div className="flex gap-2">
										{textAlignmentOptions.map((option) => (
											<Listbox.Option key={option.id} value={option.id}>
												{({ selected }) => (
													<Tooltip placement="bottom" text={option.tooltip} noWrap preventFlip>
														<Button
															variant="text"
															startIcon={option.icon}
															size="xs"
															active={selected}
															onClick={() => onSelectChange(option.id)}
														/>
													</Tooltip>
												)}
											</Listbox.Option>
										))}
									</div>
								</PopoverContainer>
							</Listbox.Options>
						</Transition>
					</PopoverContent>
				</Popover>
			)}
		</Listbox>
	);
}
