import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions/textAppenders";
import { userIdSchema } from "../../components/userIdSchema";

export const objectOwnerSchema = asConst({
	...userIdSchema,
	title: "SDObject Owner",
	description: appendNotEditable("The owner of this SDObject"),
	nullable: true,
});
