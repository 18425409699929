import { Field } from "@salesdesk/salesdesk-schemas";
import { Filter, FilterData } from "../../../../records";
import { SelectOptionSection } from "../../../../inputs";
import { useMemo } from "react";
import { FilteringPopover } from "../../../../records/components/Filtering/components/FilteringPopover";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { isMetadataFieldName } from "../utils";

interface UserFiltersProps {
	fields: Field[];
	value: FilterData;
	onChange: (newFilters: FilterData) => void;
	disabled?: boolean;
}

export function UserFilters({ fields, value: filters, onChange: onChangeFilters, disabled = false }: UserFiltersProps) {
	const filterTargetMap = useMemo(() => {
		const map: Record<string, Field> = {};
		for (const field of fields) {
			map[isMetadataFieldName(field._name) ? field._name : String(field._id)] = field;
		}
		return map;
	}, [fields]);

	const filterTargetOptions: SelectOptionSection[] = useMemo(
		() => [
			fields.flatMap((field: Field) => ({
				id: isMetadataFieldName(field._name) ? field._name : field._id,
				name: field._displayName,
				icon: field._icon,
			})),
		],
		[fields]
	);

	const defaultFilter: Filter = useMemo(() => {
		const targetField = fields.find((field) => field._name === mUserDef.TITLE_FIELD_NAME);
		return { filterId: "t_contains", filterTarget: String(targetField?._id ?? ""), value: undefined };
	}, [fields]);

	return (
		<FilteringPopover
			disabled={disabled}
			value={filters}
			onChange={onChangeFilters}
			filterTargetMap={filterTargetMap}
			filterTargetOptions={filterTargetOptions}
			defaultFilter={defaultFilter}
		/>
	);
}
