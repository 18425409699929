import { FileIcon } from "../../../../../../files";
import { BaseCompactRecordPreviewProps } from "../../types";
import { CompactRecordPreviewContainer } from "../common";

export function CompactDocPreview({ sdRecord, sdObject, variant }: BaseCompactRecordPreviewProps) {
	return (
		<CompactRecordPreviewContainer
			icon={
				<div className="px-0.5 py-0.5">
					<FileIcon customExtension="Doc" withDot={false} size="sm" />
				</div>
			}
			sdRecord={sdRecord}
			sdObject={sdObject}
			variant={variant}
		/>
	);
}
