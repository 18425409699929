import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { ReactNode } from "react";

interface ImportCountProps {
	icon?: ReactNode;
	count: number;
	description: string;
	isError?: boolean;
	downloadLink?: string;
}

export function ImportCount({ icon, count, description, isError, downloadLink }: ImportCountProps) {
	return (
		<div className={clsx("flex items-start gap-3", isError ? "text-c_danger_02" : "text-c_text_secondary")}>
			<div className="py-1">{icon}</div>
			<div className="flex flex-col gap-1">
				<div className="flex justify-between">
					<div className="text-h3">{count.toLocaleString()}</div>
					{downloadLink && count > 0 && (
						<Button
							size="sm"
							onClick={() => {
								window.location.href = downloadLink;
							}}
							startIcon={ICONS.download}
							variant="text"
						/>
					)}
				</div>
				<div>{description}</div>
			</div>
		</div>
	);
}
