import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useShareRecordMutation, useUnshareRecordMutation, useWorkspaceContext } from "../../../../workspaces";
import { useToast } from "../../../../Toasts";
import { MenuItem } from "../../../../menu";
import { mContactDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

export function useWorkspaceSharingActions(sdRecord: SDRecord) {
	const [shareRecord] = useShareRecordMutation();
	const [unshareRecord] = useUnshareRecordMutation();

	const toast = useToast();

	const { internalArea, workspaceRecord, workspaceObject } = useWorkspaceContext();

	const showWorkspaceSharingActions = internalArea && workspaceRecord && workspaceObject;

	if (!showWorkspaceSharingActions) {
		return { workspaceSharingAction: null };
	}

	const isSharedToWorkspace = showWorkspaceSharingActions && sdRecord.sharedWorkspaceIds.includes(workspaceRecord._id);
	const showingShareAction = !isSharedToWorkspace;

	const workspaceSharingAction: MenuItem = {
		icon: showingShareAction ? ICONS.plus : ICONS.cross,
		text: getSharingAction(showingShareAction, sdRecord, workspaceObject),
		type: "button",
		onClick: () => {
			const workspaceSharingMutation = showingShareAction ? shareRecord : unshareRecord;
			const toastKey = showingShareAction ? "workspace_share_record" : "workspace_unshare_record";

			workspaceSharingMutation({ workspace: workspaceRecord, record: sdRecord })
				.unwrap()
				.then(() => {
					toast.triggerMessage({
						type: "success",
						messageKey: toastKey,
						messageParams: { workspaceObject: workspaceObject._displayName },
					});
				})
				.catch(() =>
					toast.triggerMessage({
						type: "error",
						messageKey: toastKey,
						messageParams: { workspaceObject: workspaceObject._displayName },
					})
				);
		},
	};

	return {
		showingShareAction,
		workspaceSharingAction,
	};
}

export function getSharingAction(share: boolean, sdRecord: SDRecord, workspaceObject: SDObject) {
	switch (sdRecord._objectDefId) {
		case mSalesDeskUserDef.ID:
		case mContactDef.ID:
			return `${share ? "Add to this" : "Remove from this "} ${workspaceObject._displayName}`;
		default:
			return `${share ? "Share in this" : "Unshare in this"} ${workspaceObject._displayName}`;
	}
}
