import { Editor } from "@tiptap/react";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { SelectOption, SelectOptionId } from "../../../types";

export const TOOLBAR_ACTIONS_CONFIG = [
	{
		id: 1,
		icon: ICONS.textBold,
		action: (editor: Editor) => () => editor.chain().focus().toggleBold().run(),
		isActive: (editor: Editor) => editor.isActive("bold"),
	},
	{
		id: 2,
		icon: ICONS.textItalic,
		action: (editor: Editor) => () => editor.chain().focus().toggleItalic().run(),
		isActive: (editor: Editor) => editor.isActive("italic"),
	},
	{
		id: 3,
		icon: ICONS.textUnderline,
		action: (editor: Editor) => () => editor.chain().focus().toggleUnderline().run(),
		isActive: (editor: Editor) => editor.isActive("underline"),
	},
	{
		id: 4,
		icon: ICONS.listBullet,
		action: (editor: Editor) => () => editor.chain().focus().toggleBulletList().run(),
		isActive: (editor: Editor) => editor.isActive("bulletList"),
	},
	{
		id: 5,
		icon: ICONS.listNumbers,
		action: (editor: Editor) => () => editor.chain().focus().toggleOrderedList().run(),
		isActive: (editor: Editor) => editor.isActive("orderedList"),
	},
];

export type TextFormattingOption = SelectOption & {
	action: (editor: Editor) => void;
	isActive: (editor: Editor) => boolean;
};

export const DEFAULT_OPTION_ID = 4;

export const TEXT_FORMAT_OPTIONS = [
	{
		id: 1,
		name: "Heading 1",
		action: (editor: Editor) => editor.chain().toggleHeading({ level: 1 }).run(),
		isActive: (editor: Editor) => editor.isActive("heading", { level: 1 }),
	},
	{
		id: 2,
		name: "Heading 2",
		action: (editor: Editor) => editor.chain().toggleHeading({ level: 2 }).run(),
		isActive: (editor: Editor) => editor.isActive("heading", { level: 2 }),
	},
	{
		id: 3,
		name: "Heading 3",
		action: (editor: Editor) => editor.chain().toggleHeading({ level: 3 }).run(),
		isActive: (editor: Editor) => editor.isActive("heading", { level: 3 }),
	},
	{
		id: DEFAULT_OPTION_ID,
		name: "Normal",
		action: (editor: Editor) => editor.chain().setParagraph().run(),
		isActive: (editor: Editor) => editor.isActive("paragraph"),
	},
];

export const TEXT_FORMAT_OPTIONS_DETAILS = TEXT_FORMAT_OPTIONS.reduce(
	({ selectOptions, formatOptionsMapById }, textFormatOption) => {
		const { id, name } = textFormatOption;
		selectOptions.push({ id, name });

		formatOptionsMapById[id] = textFormatOption;

		return { selectOptions, formatOptionsMapById };
	},
	{ selectOptions: [], formatOptionsMapById: {} } as {
		selectOptions: SelectOption[];
		formatOptionsMapById: Record<SelectOptionId, TextFormattingOption>;
	}
);
