import { Skeleton } from "../../../../components/Skeleton/Skeleton";

export function InlineUserLoading() {
	return (
		<div className="flex items-center gap-2">
			<Skeleton className="size-6" />
			<Skeleton className="w-22 h-4" />
		</div>
	);
}
