import { PropsWithChildren } from "react";
import clsx from "clsx";
import { SelectorPlaceholder } from "../ruleValueSelectors/SelectorPlaceholder";

export function SelectorPanel({ noSelectionText, children }: PropsWithChildren<{ noSelectionText: string }>) {
	return (
		<div className={clsx("h-full grow overflow-hidden", !children && "flex items-center")}>
			{children ?? <SelectorPlaceholder placeHolderText={noSelectionText} />}
		</div>
	);
}
