import { useObjectBookmarks } from "./useObjectBookmarks";
import { getBookmarkIdFromLocation } from "../../utils";
import { useMemo } from "react";
import { Bookmark, SDObject } from "@salesdesk/salesdesk-schemas";

export function useCurrentObjectBookmark(sdObject: SDObject | undefined): Bookmark | undefined {
	const objectBookmarks = useObjectBookmarks(sdObject);
	const currentBookmarkId = getBookmarkIdFromLocation();
	return useMemo(() => {
		if (!currentBookmarkId) return undefined;
		return objectBookmarks?.find((bookmark) => bookmark.id === currentBookmarkId);
	}, [objectBookmarks, currentBookmarkId]);
}
