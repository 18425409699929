import { useCallback, useMemo } from "react";

import { SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";

import { useUpdateRecord } from "../../records";
import { useToast } from "../../Toasts";
import {
	getCompletableRecordStatusOptionIds,
	getUpdatedFieldsForCompletableRecordStatusAndProgress,
	getIsCompletableRecordDone,
} from "../utils";

export function useToggleDoneCompletableRecord(sdRecord?: SDRecord, sdObject?: SDObject) {
	const { updateRecord, isLoading } = useUpdateRecord();

	const toast = useToast();

	const { doneStatusId, toDoStatusId } = useMemo(
		() =>
			sdObject
				? getCompletableRecordStatusOptionIds(sdObject)
				: {
						doneStatusId: null,
						toDoStatusId: null,
					},
		[sdObject]
	);

	const updateTaskStatusAndProgress = useCallback(
		(statusId?: number | null, progress?: number | null) => {
			if (!sdObject || !sdRecord) return;

			const updatedFields = getUpdatedFieldsForCompletableRecordStatusAndProgress(sdObject, statusId, progress);

			updateRecord({
				record: sdRecord,
				updatedFields,
			})
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "record_updated" });
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_updated" });
				});
		},
		[sdObject, sdRecord, updateRecord, toast]
	);

	const markAsDone = useCallback(() => {
		updateTaskStatusAndProgress(doneStatusId, 100);
	}, [updateTaskStatusAndProgress, doneStatusId]);

	const markAsToDo = useCallback(() => {
		updateTaskStatusAndProgress(toDoStatusId, 0);
	}, [updateTaskStatusAndProgress, toDoStatusId]);

	const isCompletableRecordDone = useMemo(() => {
		if (!sdObject || !sdRecord) return false;
		return getIsCompletableRecordDone(sdRecord, sdObject);
	}, [sdObject, sdRecord]);

	return {
		isLoading,
		markAsDone,
		markAsToDo,
		isCompletableRecordDone,
	};
}
