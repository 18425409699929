import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../components";
import { sdRecordCreateRequestSchema } from "..";

export const leadConvertRequestSchema = asConst({
	title: "LeadConvertRequestSchema",
	description: "The schema used for converting Leads",
	type: "object",
	additionalProperties: false,
	required: ["contact", "opportunity"],
	properties: {
		contact: {
			...sdRecordCreateRequestSchema,
			title: "Contact",
			description: "Contact to which lead will be converted",
		},
		linkContactIds: {
			title: "Link Contact IDs",
			description: "IDs of contacts which will be linked to created opportunity",
			type: "array",
			items: idSchema,
		},
		opportunity: {
			...sdRecordCreateRequestSchema,
			title: "Opportunity",
			description: "Opportunity to create during lead conversion",
		},
		account: {
			...sdRecordCreateRequestSchema,
			title: "Account",
			description: "Optional account to create and link created opportunity to",
		},
		existingAccountId: {
			...idSchema,
			title: "Existing Account ID",
			description: "Optional ID of account to which created opportunity will be linked",
		},
	},
	oneOf: [{ required: ["account"] }, { required: ["existingAccountId"] }],
});
