import { MutableRefObject, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Editor, JSONContent } from "@tiptap/react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { BASE_COLORS } from "@salesdesk/salesdesk-ui";

import { PLACEHOLDER_COMMENT_ID } from "../../../utils";
import { DocumentCommentsContext } from "../../../hooks/useDocumentCommentsContext";
import { useGetDocumentComments } from "../../../hooks/useGetDocumentComments";
import { COMMENT_DATA_ATTRIBUTE } from "../../../../DocumentEditor";
import { useOnActiveCommentChange } from "../hook/useOnActiveCommentChange";

interface DocumentCommentsProviderProps {
	documentRecord?: SDRecord;
	editorContents?: JSONContent | null;
	editorRef: MutableRefObject<Editor | null>;
}

export function DocumentCommentsProvider({
	documentRecord,
	editorContents,
	editorRef,
	children,
}: PropsWithChildren<DocumentCommentsProviderProps>) {
	const [isCommentsOpen, setCommentsOpen] = useState(false);
	const [showResolvedComments, setShowResolvedComments] = useState(false);

	const { commentThreads, isLoadingComments } = useGetDocumentComments({ documentRecord, editorContents });

	const [unresolvedThreadIds, resolvedThreadIds] = useMemo(() => {
		const resolved: string[] = [];
		const unresolved: string[] = [PLACEHOLDER_COMMENT_ID];

		commentThreads.forEach((thread) => {
			if (thread.comments[0].resolvedAt != null) {
				resolved.push(thread.id);
			} else {
				unresolved.push(thread.id);
			}
		});

		return [unresolved, resolved];
	}, [commentThreads]);

	useEffect(() => {
		if (isCommentsOpen) {
			return () => {
				setShowResolvedComments(false);
			};
		}
	}, [isCommentsOpen]);

	const viewingResolvedComments = showResolvedComments && isCommentsOpen;
	const viewingUnresolvedComments = !showResolvedComments && isCommentsOpen;

	const { activeCommentId, onActiveCommentsChangeRef } = useOnActiveCommentChange({
		unresolvedThreadIds,
		viewingResolvedComments,
		resolvedThreadIds,
		viewingUnresolvedComments,
		setCommentsOpen,
		setShowResolvedComments,
		editorRef,
	});

	// Updates the styling for the visible comment threads in the document. Have to use id based selectors since
	// not all comment threads are visible to users depending on permissions/context (e.g. Can't see CRM comments in the workspace)
	// and we can't make any changes to the document content/HTML itself without updating the editor state.
	useEffect(() => {
		const styleElement = document.createElement("style");

		const selectors = unresolvedThreadIds.map((threadId) => `&[${COMMENT_DATA_ATTRIBUTE}="${threadId}"]`).join(", ");

		const baseStyles = `${selectors} {
		    border-bottom: 2px dashed ${BASE_COLORS.c_warning_02};
			background-color: #FCD34D60;
		}`;

		let activeCommentStyle = "";
		if (
			activeCommentId &&
			(unresolvedThreadIds.includes(activeCommentId) || resolvedThreadIds.includes(activeCommentId))
		) {
			const isResolved = resolvedThreadIds.includes(activeCommentId);

			if (!isResolved || viewingResolvedComments) {
				const backgroundColor = isResolved ? BASE_COLORS.c_bg_disabled_01 : "#FCD34D";
				const lineColour = isResolved ? BASE_COLORS.c_text_disabled : BASE_COLORS.c_warning_02;

				activeCommentStyle = `&[${COMMENT_DATA_ATTRIBUTE}="${activeCommentId}"] {
					background-color: ${backgroundColor} !important;
					border-bottom: 2px solid ${lineColour};
				}`;
			}
		}

		styleElement.textContent = `
			.tiptap-comment {
				${baseStyles}
                ${activeCommentStyle}
			}
		`;

		document.head.appendChild(styleElement);
		return () => {
			document.head.removeChild(styleElement);
		};
	}, [activeCommentId, unresolvedThreadIds, resolvedThreadIds, viewingResolvedComments]);

	return (
		<DocumentCommentsContext.Provider
			value={{
				documentRecord,
				documentCommentThreads: commentThreads,
				isLoadingComments,
				isCommentsOpen,
				setCommentsOpen,
				activeCommentId,
				onActiveCommentsChangeRef,
				showResolvedComments,
				setShowResolvedComments,
			}}
		>
			{children}
		</DocumentCommentsContext.Provider>
	);
}
