import { useLayoutEffect, PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useThemeProviderContext } from "@salesdesk/daisy-ui";
import { TenantSettings } from "@salesdesk/salesdesk-schemas";
import { ThemeName, UI_THEMES } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../auth";
import { getWorkspaceTheme, useWorkspaceContext } from "../workspaces";

import { getFrontendCrmThemeName } from "../settings/account/utils";
import { DEFAULT_CRM_THEME } from "../settings/account/constants";

interface AppThemeHandlerProps extends PropsWithChildren {
	tenantSettings: TenantSettings;
}

export function AppThemeHandler({ tenantSettings, children }: AppThemeHandlerProps) {
	const principal = useWebPrincipal();
	const [userflowIsMounted, setUserflowIsMounted] = useState(false);

	// Want to make sure the theme is always correct when the user navigates to a different page
	// (e.g. when leaving the workspace settings without saving the theme being previewed)
	const location = useLocation();

	const { workspaceId, workspaceObject, workspaceRecord } = useWorkspaceContext();
	const { currentTheme, setTheme } = useThemeProviderContext();

	const crmTheme = getFrontendCrmThemeName(tenantSettings);

	useLayoutEffect(() => {
		if (principal.IsCustomerUser || workspaceId != null) {
			setTheme(getWorkspaceTheme(workspaceObject, workspaceRecord));
		} else {
			setTheme(crmTheme ?? DEFAULT_CRM_THEME);
		}
	}, [workspaceId, principal.IsCustomerUser, setTheme, workspaceObject, workspaceRecord, location.pathname, crmTheme]);

	useEffect(() => {
		const observer = new MutationObserver(() => {
			const userflowResourceCenterRoot = getUserflowResourceCenterRoot();

			if (!userflowResourceCenterRoot) {
				return;
			}

			setUserflowIsMounted(true);
			observer.disconnect();
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		if (!userflowIsMounted) {
			return;
		}

		const userflowResourceCenterRoot = getUserflowResourceCenterRoot();
		userflowResourceCenterRoot?.setAttribute("style", generateUserflowStyleVariables(currentTheme));
	}, [currentTheme, userflowIsMounted]);

	return children;
}

function getUserflowResourceCenterRoot() {
	const userflowIframe = document.querySelector("iframe.userflowjs-resource-center-frame");

	if (!(userflowIframe instanceof HTMLIFrameElement)) {
		return null;
	}

	const iframeDocument = userflowIframe.contentDocument || userflowIframe.contentWindow?.document;

	if (!iframeDocument) {
		return null;
	}

	return iframeDocument.querySelector(".userflowjs-resource-center-frame-root");
}

function generateUserflowStyleVariables(theme: ThemeName): string {
	const themeColors = UI_THEMES[theme];

	const actionFocus = themeColors.c_action_focus;
	const brandPrimary = themeColors.c_brand_primary;
	const action04 = themeColors.c_action_04;
	const bg02 = themeColors.c_bg_02;
	const bg03 = themeColors.c_bg_03;

	return `
		--userflow-banner-background-color: ${actionFocus};
		--userflow-banner-hover-background-color: ${action04};
		--userflow-banner-active-background-color: ${actionFocus};
		--userflow-banner-hover-foreground-color: ${action04};
		--userflow-banner-active-foreground-color: ${action04};
		--userflow-brand-active-background-color: ${brandPrimary};
		--userflow-brand-background-color: ${actionFocus};
		--userflow-brand-hover-background-color: ${brandPrimary};
		--userflow-checklist-launcher-active-background-color: ${brandPrimary};
		--userflow-checklist-launcher-background-color: ${actionFocus};
		--userflow-checklist-launcher-counter-font-color: ${actionFocus};
		--userflow-default-button-background-color: ${bg03};
		--userflow-default-button-hover-background-color: ${bg02};
		--userflow-focus-color: ${actionFocus};
		--userflow-launcher-button-active-background-color: ${brandPrimary};
		--userflow-launcher-button-active-border-color: ${brandPrimary};
		--userflow-launcher-button-background-color: ${actionFocus};
		--userflow-launcher-button-border-color: ${actionFocus};
		--userflow-launcher-button-hover-background-color: ${brandPrimary};
		--userflow-launcher-button-hover-border-color: ${brandPrimary};
		--userflow-launcher-icon-active-color: ${brandPrimary};
		--userflow-launcher-icon-color: ${actionFocus};
		--userflow-launcher-icon-hover-color: ${brandPrimary};
		--userflow-link-color: ${actionFocus};
		--userflow-main-active-background-color: ${action04};
		--userflow-main-hover-background-color: ${action04};
		--userflow-primary-button-active-background-color: ${brandPrimary};
		--userflow-primary-button-active-border-color: ${brandPrimary};
		--userflow-primary-button-background-color: ${actionFocus};
		--userflow-primary-button-border-color: ${actionFocus};
		--userflow-primary-button-hover-background-color: ${brandPrimary};
		--userflow-primary-button-hover-border-color: ${brandPrimary};
		--userflow-progress-bar-color: ${actionFocus};
		--userflow-question-color-light: ${action04};
		--userflow-question-color-lighter: ${action04};
		--userflow-question-color-lightest: ${action04};
		--userflow-question-color-medium-light: ${action04};
		--userflow-question-color: ${actionFocus};
		--userflow-widget-active-background-color: ${brandPrimary};
		--userflow-widget-background-color: ${actionFocus};
		--userflow-widget-hover-background-color: ${brandPrimary};
	`;
}
