import {
	Field,
	SDObject,
	SDRecord,
	SDRecordCreateRequest,
	getSDObjectContainerFieldId,
	getSDObjectFields,
	getSDObjectNameFieldIds,
	getSDRecordFieldMap,
} from "@salesdesk/salesdesk-schemas";
import { RECORD_FIELDS_KEY } from "../types";

export function getRecordFormFieldId(fieldId: string | number) {
	return `_${fieldId}`;
}

export function getFullRecordFormFieldId(recordFieldsKey: string, fieldId: string) {
	return `${recordFieldsKey}.${getRecordFormFieldId(fieldId)}`;
}

export function getFormValuesByPrefix(data: Record<string, any>, prefix: string) {
	const formValuesByPrefix = data[prefix];
	const formValues: Record<string, any> = {};

	for (const keyValue in formValuesByPrefix) {
		const fieldKey = keyValue.slice(1);

		formValues[fieldKey] = formValuesByPrefix[keyValue];
	}

	return formValues;
}

export function updateRecordFieldValues(recordFields: Field[], fieldValuesMapById: Record<string, any>) {
	return recordFields.map((recordField) => {
		const recordValue = fieldValuesMapById[recordField._id];
		const fieldValue = recordValue === undefined ? recordField._defaultValue : recordValue;

		return {
			_fieldId: recordField._id,
			_value: fieldValue === "" || fieldValue === undefined ? null : fieldValue,
		};
	});
}

export function generateCreateRecordRequest(
	recordDataFromForm: Record<string, any>,
	sdObject: SDObject
): SDRecordCreateRequest {
	const fieldValues = updateRecordFieldValues(getSDObjectFields(sdObject, { includeHidden: true }), recordDataFromForm);

	let recordName = "";
	getSDObjectNameFieldIds(sdObject).forEach((nameFieldId) => {
		const fieldValue = recordDataFromForm[nameFieldId];

		if (fieldValue) {
			recordName += (recordName ? " " : "") + fieldValue;
		}
	});

	return {
		_name: recordName || sdObject._displayName,
		_objectDefId: sdObject._id,
		_dataInst: { _fieldId: getSDObjectContainerFieldId(sdObject) || 0, _value: null, _children: fieldValues },
	};
}

export function getFormValuesFromRecord(fields: Field[], sdRecord: SDRecord) {
	const sdRecordFieldMap = getSDRecordFieldMap(sdRecord);

	const fieldValueMap: Record<string, unknown> = {};

	for (const field of fields) {
		fieldValueMap[getRecordFormFieldId(field._id)] = sdRecordFieldMap[field._id]._value ?? null;
	}

	return { [RECORD_FIELDS_KEY]: fieldValueMap };
}
