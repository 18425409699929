import { ICONS } from "@salesdesk/salesdesk-ui";
import { tw } from "../../../utils/tailwind-helpers";

export const toastTypes = ["info", "success", "error", "warning"] as const;
export type ToastType = (typeof toastTypes)[number];

export type ToastVariant = "default" | "small";

export const LIGHT_BUTTON_BG_COLORS_BY_TYPE: Record<ToastType, string> = {
	info: tw`bg-c_action_01`,
	success: tw`bg-c_success_01`,
	error: tw`bg-c_danger_01`,
	warning: tw`bg-c_warning_01`,
};

export const DARK_BUTTON_BG_COLORS = tw`bg-c_bg_tooltip`;

export const LIGHT_TEXT_COLORS_BY_TYPE: Record<ToastType, string> = {
	info: tw`text-c_action_01`,
	success: tw`text-c_success_01`,
	error: tw`text-c_danger_01`,
	warning: tw`text-c_warning_01`,
};
export const LIGHT_BG_COLORS_BY_TYPE: Record<ToastType, string> = {
	info: tw`bg-c_action_04`,
	success: tw`bg-c_success_04`,
	error: tw`bg-c_danger_04`,
	warning: tw`bg-c_warning_04`,
};

export const DARK_TEXT_COLORS = tw`text-c_text_inverted`;

export const DARK_BG_COLORS = tw`bg-c_bg_tooltip`;

export const ICON_BY_TYPE: Record<ToastType, string> = {
	info: ICONS.info,
	success: ICONS.checkCircle,
	error: ICONS.crossCircle,
	warning: ICONS.warning,
};
