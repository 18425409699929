import { useMemo } from "react";

import { Breadcrumbs } from "../../../../../../../components/Breadcrumbs/Breadcrumbs";
import { TooltipDelayGroup } from "@salesdesk/daisy-ui";
import { useAppSelector } from "../../../../../../../store/store";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";
import {
	BoardFilteringPopover,
	BoardGroupByMenu,
	BoardSearchbar,
	BoardSortingPopover,
	useDataboardDetailsContext,
	BoardViewSelect,
	BoardUsersFilter,
} from "../../../../../../ObjectBoard";
import { Sticky } from "../../../../../../../components/Sticky";
import { useGetWorkspaceBaseBreadcrumbs } from "../../../../../hooks/useGetWorkspaceBaseBreadcrumbs";
import { AddRecordButton } from "../../../WorkspaceResources";

export function WorkspaceBoardTopBar() {
	const { workspaceRecord, workspaceObject } = useWorkspaceContext();
	const { sdObject: boardObject } = useDataboardDetailsContext();

	const currentDataBoardSyncState = useAppSelector((state) => state.appState.dataBoardStateSyncIteration);

	const baseBreadcrumbs = useGetWorkspaceBaseBreadcrumbs(!boardObject);

	const crumbs = useMemo(() => {
		// Skeleton loading breadcrumbs
		if (!boardObject || !workspaceRecord || !workspaceObject) {
			return baseBreadcrumbs;
		}

		return [...baseBreadcrumbs, { node: boardObject._pluralName, icon: boardObject._icon }];
	}, [baseBreadcrumbs, boardObject, workspaceObject, workspaceRecord]);

	const boardObjectIdArray = useMemo(() => (boardObject ? [boardObject._id] : []), [boardObject]);

	if (!boardObject) {
		return null;
	}

	return (
		<Sticky
			priority={2}
			height={86}
			className="bg-c_bg_01 sticky left-0 z-40 flex h-[86px] w-full justify-center py-4 pt-8"
			showCover
		>
			<div className="flex w-10/12 items-center">
				<TooltipDelayGroup>
					<Breadcrumbs crumbs={crumbs} canCopyLink={true} />
					<div className="ml-auto flex gap-6">
						<div className="flex items-center gap-2">
							<BoardUsersFilter />
							<BoardViewSelect iconOnly sdObject={boardObject} />
							<BoardGroupByMenu iconOnly />
							<BoardFilteringPopover key={`${boardObject._id}-${currentDataBoardSyncState}-filter`} iconOnly />
							<BoardSortingPopover key={`${boardObject._id}-${currentDataBoardSyncState}-sort`} iconOnly />
						</div>
						<div className="flex items-center gap-2">
							<BoardSearchbar />
							<AddRecordButton sdObjectIds={boardObjectIdArray} variant="primary" />
						</div>
					</div>
				</TooltipDelayGroup>
			</div>
		</Sticky>
	);
}
