import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../../components";

export const channelParamsSchema = asConst({
	title: "Channel Params",
	description: "For Message Channel actions",
	type: "object",
	additionalProperties: false,
	required: ["channelArn"],
	properties: {
		channelArn: stringSchema,
		// Older events may not have channelName
		channelName: stringSchema,
	},
});
