import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { mAssetDef, mProductDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";

import { Button } from "@salesdesk/daisy-ui";
import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../../../components/PanelTabs";
import { useObjectAssociations } from "../../../../../../../recordAssociations";
import {
	AssociationRecordsToLinkMap,
	CreateWorkspaceStepProps,
	OriginRecordDetails,
	UpdateAssociationRecordsToLinkFn,
} from "../../../../types";
import { AddWorkspaceAssociationTable } from "./AddWorkspaceAssociationTable";

const RECORD_TYPES_TO_ADD = [mAssetDef.ID, mProductDef.ID];

interface AddRecordsPanelProps extends CreateWorkspaceStepProps {
	originRecordDetails: OriginRecordDetails;
	associationRecordsToLinkMap: AssociationRecordsToLinkMap;
	updateAssociationRecordsToLink: UpdateAssociationRecordsToLinkFn;
}

const EMPTY_SELECTED_RECORDS: SDRecord[] = [];

export function AddRecordsPanel({
	workspaceObject,
	originRecordDetails,
	associationRecordsToLinkMap,
	updateAssociationRecordsToLink,
	isVisible,
	nextStep,
	previousStep,
}: AddRecordsPanelProps) {
	const {
		objectAssociations: { prioritizedAssociations },
	} = useObjectAssociations(mWorkspaceDef.ID, RECORD_TYPES_TO_ADD);

	const [tabCounts, setTabCounts] = useState<Record<number, number>>({});

	const updateTabCount = useCallback((associationId: number, count: number) => {
		setTabCounts((prevCounts) => ({ ...prevCounts, [associationId]: count }));
	}, []);

	const totalSelectedRecords = useMemo(() => {
		return prioritizedAssociations.reduce(
			(total, association) => total + (associationRecordsToLinkMap[association.id]?.records.length || 0),
			0
		);
	}, [associationRecordsToLinkMap, prioritizedAssociations]);

	const [hasRendered, setHasRendered] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setHasRendered(true);
		}
	}, [isVisible]);

	// Only return null if it hasn't been initially rendered (i.e. the step hasn't been reached)
	// Allows for more responsive behaviour when switching between steps and better performance
	if (!hasRendered) {
		return null;
	}

	return (
		<div className={clsx("flex h-full flex-col gap-6 overflow-hidden", !isVisible && "hidden")}>
			<div className="mb-2 flex flex-col gap-4">
				<h4 className="text-h4">Add records</h4>
				<p>
					Add records to the {workspaceObject._displayName}. You can modify your selections anytime within the{" "}
					{workspaceObject._displayName}
				</p>
			</div>
			<PanelTabs>
				<PanelTabList>
					{prioritizedAssociations.map((objectAssociation) => {
						const { id } = objectAssociation;
						return (
							<PanelTab key={id} count={tabCounts[id]}>
								{objectAssociation.connectedObject.label}
							</PanelTab>
						);
					})}
				</PanelTabList>
				<PanelTabPanels className="flex h-full overflow-hidden">
					{prioritizedAssociations.map((objectAssociation) => (
						<PanelTabPanel key={objectAssociation.id} unmount={false} className="flex w-full">
							<AddWorkspaceAssociationTable
								objectAssociation={objectAssociation}
								originRecordDetails={originRecordDetails}
								updateRecordCount={updateTabCount}
								selectedRecords={associationRecordsToLinkMap[objectAssociation.id]?.records ?? EMPTY_SELECTED_RECORDS}
								updateSelectedRecords={updateAssociationRecordsToLink}
							/>
						</PanelTabPanel>
					))}
				</PanelTabPanels>
			</PanelTabs>
			<div className="mt-auto flex justify-end gap-3">
				<p className="flex flex-col justify-center">
					<span className="text-body-sm text-c_text_secondary">
						Selected <span className={clsx(totalSelectedRecords && "text-label-sm")}>{totalSelectedRecords}</span>{" "}
						{pluralizeWithS("record", totalSelectedRecords)}
					</span>
				</p>
				<Button onClick={previousStep} variant="secondary" size="lg">
					Previous
				</Button>
				<Button onClick={nextStep} size="lg" variant="primary">
					Next
				</Button>
			</div>
		</div>
	);
}
