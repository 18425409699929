import { DataService } from "@salesdesk/salesdesk-services";

import { deepClone, isEmpty } from "@salesdesk/salesdesk-utils";

import { FieldInstanceFactory, mVideoField } from "../fields";

import { mObjectDef } from "./object_def";
import { RecordRelationshipType } from "./RecordRelationshipType";

export class mObjectInst {
	static CLASS_NAME = "mObjectInst";

	// Naming convention for fields of ids for each child object type
	static ID_FIELD_MATCH = "_ids";

	_id: number;
	_ownerId: number | null;
	_owner: any;
	_className: string;
	_type: number;
	_name: string;
	createdAt: number;
	updatedAt: number;
	updatedBy: number | null;
	_objectDefId: number;
	_objectDef: mObjectDef;
	_objectData: any;
	_dataInst: any;
	_parents: any[];
	_parentContext: any;
	_deleted: boolean;
	_persisted: boolean;
	_dirty: boolean;
	_definitionVersion: any;
	_version: number;
	_baseType: any;
	isTemplate?: boolean;
	_relationships: any[] | undefined;

	constructor(id: number, objectDef: mObjectDef, ownerId: number) {
		if (!objectDef) {
			throw Error(`Cannot instantiate "${this.className}". No object definition provided.`);
		}

		if (!objectDef.hasData()) {
			throw Error(`Cannot instantiate "${this.className}". Object def "${objectDef.name}" has no data.`);
		}

		this._id = id;

		this._ownerId = ownerId;

		this._className = objectDef.name as string;

		/** @type {number} */
		this._type = mObjectDef.CREATION_TYPE.USER;

		// Initially we take the name of the definition.
		// However we can override it by setting it
		// to something else.

		this._name = objectDef.name as string;

		this.createdAt = new Date().getTime();
		this.updatedAt = new Date().getTime();
		this.updatedBy = this._ownerId;

		// Object def
		this._objectDef = objectDef;
		this._objectDefId = objectDef.id;

		// Raw object data
		this._objectData = null;

		// References the fields framework
		this._dataInst = null;

		// Parent (lazy load)
		this._parents = [];

		this._parentContext = null;

		this._deleted = false;
		// Whether this object has been stored previously.

		this._persisted = false;
		// Whether this object has been stored previously and needs to be stored again.
		// i.e there has been a change to state locally.

		this._dirty = false;

		this._definitionVersion = null;

		this._version = 0;

		this._baseType = objectDef.baseType;

		this.isTemplate = undefined;

		// Set up data fields from data definition
		this.data = FieldInstanceFactory.newInstance(objectDef.data);

		this.createFields();
	}

	addDataEventListener(type: number, listener: any) {
		this.data.addEventListener(type, listener);
	}

	removeDataEventListener(type: number, listener: any) {
		this.data.removeEventListener(type, listener);
	}

	get id() {
		return this._id;
	}

	set id(id) {
		this._id = id;
	}

	get baseType() {
		return this._baseType;
	}

	get definitionVersion() {
		return this._definitionVersion;
	}

	set definitionVersion(value) {
		this._definitionVersion = value;
	}

	get owner() {
		if (this.ownerLoaded()) {
			return this._owner;
		}
		throw Error(`Owner isn't loaded`);
	}

	set owner(owner) {
		this._owner = null;
		this._ownerId = -1;

		if (owner) {
			this._owner = owner;
			this._ownerId = owner.id;
		}
	}

	loadOwner(callback: (err: any, owner: any) => void) {
		if (!this.hasOwner()) {
			callback(Error(`This object does not have an owner`), null);
			return;
		}

		if (this.ownerLoaded()) {
			callback(null, this.owner);
			return;
		}

		(DataService as any).getInstance().loadObject(this._ownerId, (err: any, owner: any) => {
			this.owner = owner;

			callback(err, owner);
		});
	}

	hasOwner() {
		return this._ownerId !== -1;
	}

	ownerLoaded() {
		return this._ownerId !== -1 && !isEmpty(this._owner);
	}

	isOwner(user: any) {
		return this._ownerId === user.id;
	}

	get commentsSupported() {
		return this._objectDef.commentsSupported;
	}

	get attachmentsSupported() {
		return this.objectDef.attachmentsSupported;
	}

	get historySupported() {
		return this._objectDef.historySupported;
	}

	get activitySupported() {
		return this._objectDef.activitySupported;
	}

	get chatSupported() {
		return this._objectDef.chatSupported;
	}

	get transcriptSupported() {
		return this.trancriptFieldInstances.length > 0;
	}

	get trancriptFieldInstances() {
		const instances = [];

		if (this._objectDef.transcriptSupported) {
			const videoFieldInstances = this.data.getInstancesByType(mVideoField);

			for (let i = 0; videoFieldInstances.length > i; i++) {
				const videoFieldInstance = videoFieldInstances[i];

				if (videoFieldInstance.hasTranscript()) {
					instances.push(videoFieldInstance);
				}
			}
		}

		return instances;
	}

	get extensionsSupported() {
		return this._objectDef.extensionsSupported;
	}

	get numOfExtensionsSupported() {
		return this._objectDef.numOfExtensionsSupported;
	}

	get shareSupported() {
		return this._objectDef.shareSupported;
	}

	get name() {
		return this._name;
	}

	set name(name) {
		this._name = name;
	}

	setUnderlyingName(name: string) {
		this._name = name;
	}

	get displayName() {
		return this.name;
	}

	get className() {
		return this._className;
	}

	set className(className) {
		this._className = className;
	}

	get dateCreated() {
		return this.createdAt;
	}

	set dateCreated(dateCreated) {
		this.createdAt = dateCreated;
	}

	get lastModified() {
		return this.updatedAt;
	}

	set lastModified(lastModified) {
		this.updatedAt = lastModified;
	}

	get lastModifiedUser() {
		return this.updatedBy;
	}

	set lastModifiedUser(lastModifiedUser) {
		this.updatedBy = lastModifiedUser;
	}

	isUserType() {
		return this._type === mObjectDef.CREATION_TYPE.USER;
	}

	isSystemType() {
		return this._type === mObjectDef.CREATION_TYPE.SYSTEM;
	}

	supportsTableCardView() {
		return this.objectDef.supportsTableCardView();
	}

	get type() {
		return this._type;
	}

	get color() {
		return this._objectDef.color;
	}

	get transient() {
		return this.objectDef.transient;
	}

	get objectDef() {
		return this._objectDef;
	}

	set objectDef(objectDef) {
		this._objectDefId = -1;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this._objectDef = null;

		if (objectDef) {
			this._objectDefId = objectDef.id;
			this._objectDef = objectDef;
		}
	}

	get objectData() {
		return this._objectData;
	}

	get deleted() {
		return this._deleted;
	}

	set deleted(deleted) {
		this._deleted = deleted;
	}

	get persisted() {
		return this._persisted;
	}

	set persisted(persisted) {
		this._persisted = persisted;
	}

	get dirty() {
		return this._dirty;
	}

	set dirty(dirty) {
		this._dirty = dirty;
	}

	get icon() {
		return this.objectDef.icon;
	}

	get data() {
		return this._dataInst;
	}

	set data(data) {
		this._dataInst = data;
	}

	hasData() {
		return this._dataInst !== null;
	}

	hasIconPhoto() {
		return false;
	}

	get pathName() {
		return this.objectDef.pathName;
	}

	get parentContext() {
		return this._parentContext;
	}

	set parentContext(parentContext) {
		this._parentContext = parentContext;
	}

	hasParentContext() {
		return !isEmpty(this._parentContext);
	}

	get parents() {
		if (this.parentsLoaded()) {
			return this._parents;
		}
		throw new Error(`Parents aren't loaded`);
	}

	set parents(parents) {
		this._parents = [];
		this._relationships = (this._relationships || []).filter((r) => r.type !== RecordRelationshipType.Parent);

		if (parents) {
			for (let i = 0; parents.length > i; i++) {
				this.addParent(parents[i]);
			}
		}
	}

	supportsParents() {
		return this.objectDef.hasParents();
	}

	addRelationship(recordRelationshipType: RecordRelationshipType, objectId: number, recordId: number) {
		this.removeRelationship(recordRelationshipType, recordId);
		if (!this._relationships) this._relationships = [];
		this._relationships.push({ type: recordRelationshipType, objectId: objectId, recordId: recordId });
	}

	addParentRelationship(record: mObjectInst) {
		this.addRelationship(RecordRelationshipType.Parent, record.objectDef.id, record.id);
	}

	addChildRelationship(record: mObjectInst) {
		this.addRelationship(RecordRelationshipType.Child, record.objectDef.id, record.id);
	}

	removeRelationship(recordRelationshipType: RecordRelationshipType, recordId: number) {
		if (!this._relationships) return;
		const index = this._relationships.findIndex((r) => r.type === recordRelationshipType && r.recordId === recordId);
		if (index > -1) this._relationships.splice(index, 1);
	}
	removeParentRelationship(record: mObjectInst) {
		this.removeRelationship(RecordRelationshipType.Parent, record.id);
	}
	removeChildRelationship(record: mObjectInst) {
		this.removeRelationship(RecordRelationshipType.Child, record.id);
	}

	clearChildRelationships(object: mObjectDef) {
		this._relationships = (this._relationships || []).filter(
			(r) => r.type === RecordRelationshipType.Child && (object == null || r.objectId === object.id)
		);
	}

	hasRelationship(recordRelationshipType: RecordRelationshipType, record: mObjectInst) {
		return (
			(this._relationships || []).find((r) => r.type === recordRelationshipType && r.recordId === record.id) != null
		);
	}
	hasParentRelationship(parent: any) {
		return this.hasRelationship(RecordRelationshipType.Parent, parent);
	}

	hasChildRelationship(child: any) {
		return this.hasRelationship(RecordRelationshipType.Child, child);
	}

	addParent(parent: any) {
		if (!parent) {
			return;
		}

		if (!this.hasParentRelationship(parent)) {
			this.addParentRelationship(parent);
		}
		if (!this._parents.includes(parent)) {
			this._parents.push(parent);
		}

		parent.addToField(this);
	}

	removeParent(parent: any) {
		if (!parent) {
			return;
		}

		if (!this.hasParentRelationship(parent)) {
			return;
		}

		this.removeParentRelationship(parent);
		this._parents.splice(this._parents.indexOf(parent.id), 1);
	}

	// In addition to loading the parent of this object instance,
	// we also need to load the def parent associated with object instance.
	// This is because the object def holds the parent type
	// that this object instance actually supports. i.e...
	//
	// Load -> ObjectInstance.parent
	// Load -> ObjectInstance.ObjectDefinition.parent
	//
	// Params:
	// * subType - only load parents of this type
	// * requireParents - throw if this object has no parents

	loadParents(
		callback: (err: Error | null, parents: any[] | null) => void,
		params: { subType?: number; requireParents?: boolean }
	) {
		const subType = params.subType;

		const filterFunction = subType
			? (parents: any[]) => parents.filter((x) => x.definitionId === subType)
			: (parents: any[]) => parents;

		if (this.hasParents()) {
			if (this.parentsLoaded()) {
				callback(null, filterFunction(this._parents));
			} else {
				(DataService as any).getInstance().loadObjects(this.parentIds, (err: Error | null, parents: any[]) => {
					// Add this as a child to each parent
					for (let i = 0; parents.length > i; i++) {
						parents[i].addToField(this);
					}

					this._parents = parents;

					callback(err, filterFunction(this._parents));
				});
			}
		} else if (!params.requireParents) {
			callback(null, []);
		} else {
			callback(Error("This object definition does not have parents"), null);
		}
	}

	getRelationships(recordRelationshipType: RecordRelationshipType, object: mObjectDef | null = null) {
		return (this._relationships || []).filter(
			(r) =>
				r.type === recordRelationshipType &&
				(object == null || r.objectId === object.id || object.subTypes.includes(r.objectId))
		);
	}

	getParentRelationships(object: any = null) {
		return this.getRelationships(RecordRelationshipType.Parent, object);
	}

	getChildRelationships(object: any = null) {
		return this.getRelationships(RecordRelationshipType.Child, object);
	}

	get parentIds() {
		return this.getParentRelationships().map((r) => r.recordId);
	}

	hasParents() {
		return this.getParentRelationships().length > 0;
	}

	parentsLoaded() {
		return this.getParentRelationships().length === this._parents.length;
	}

	remove() {
		if (!this.hasParents()) {
			throw Error(`Cannot remove from parent as it isn't loaded`);
		}
		this.parentContext.removeFromField(this);
	}

	// We add child objects to fields, grouped by their object
	// definition type. Each field is defined by a list of ids (stored)
	// and a list of objects (transient).

	// Reset clears existing field value(s).
	// This is useful where you have a field that only supports
	// one object and you want to set it with a new value

	addToField(child: mObjectInst, reset = false) {
		if (!this.childTypeSupported(child.objectDef)) {
			throw Error(`Field type "${child.name}" is not supported in this object.`);
		}

		if (this.childExists(child) && !reset) {
			return;
		}

		if (child.hasParentContext()) {
			console.log(`Child with id ${child.id} is already loaded to another parent. Cloning this.`);
		}

		if (reset) this.clearChildRelationships(child.objectDef);

		// list of field objects that are lazy loaded.
		const fieldName = this.getObjectFieldName(child.objectDef);

		if (!this[fieldName as keyof mObjectInst] || reset) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this[fieldName] = [];
		}

		// Check the multiplicity rule allows addition of this child
		if (!this.supportsNewInstance(child.objectDef)) {
			throw Error(
				`${this.name} does not support the addition of a new object of type "${
					child.objectDef.name
				}. Maximum is ${this.objectDef.getMaxInstances(child.objectDef)}""`
			);
		}

		if (!this[fieldName as keyof mObjectInst].includes(child)) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this[fieldName].push(child);
		}

		this.addChildRelationship(child);

		child.addParent(this);

		// JF - We need to set this to true to ensure that when the parent
		// object is saved this child object is saved too.
		child.dirty = true;

		child.parentContext = this;
	}

	removeFromField(child: mObjectInst) {
		if (!this.childExists(child)) {
			throw new Error(`Child with id ${child.id} doesn't exist within this object, so ignoring`);
		}

		if (!this.childTypeSupported(child.objectDef)) {
			throw new Error(`Field type "${child.name}" is not supported in this object.`);
		}

		if (!this.supportsRemove(child)) {
			throw new Error(`${this.name} does not support the removal of the object of type "${child.objectDef.name}"`);
		}

		// Remove object from object field
		const objectField = this.getObjectField(child.objectDef);

		if (objectField) {
			objectField.splice(objectField.indexOf(child), 1);
		}

		this.removeChildRelationship(child);

		child.removeParent(this);
		child.parentContext = null;
	}

	// Clear all values from a field
	clearField(childType: mObjectDef) {
		if (!this.childTypeSupported(childType)) {
			throw Error(`Field type "${childType.name}" is not supported in this object.`);
		}

		let field = this.getObjectField(childType);

		for (let i = 0; field.length > i; i++) {
			this.removeFromField(field[0]);
		}

		// JF: Isn't this just setting the local field to new array?
		field = [];

		this.clearChildRelationships(childType);
	}

	clearAllFields() {
		const childTypes = this.childInfo;

		for (let i = 0; childTypes.length > i; i++) {
			// JF: I changed this to use clearField to reuse code
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.clearField(childTypes[i]!);
		}
	}

	getObjectFieldName(childType: mObjectDef) {
		return `_field_${childType.baseType}`;
	}

	getObjectField(childType: mObjectDef) {
		const field = this[this.getObjectFieldName(childType) as keyof mObjectInst];

		if (!Array.isArray(field)) {
			throw new Error(`Object field for child "${childType.name}" does not exist.`);
		}

		return field;
	}

	childExists(child: any) {
		return this.hasChildRelationship(child);
	}

	containsObject(object: any) {
		return this.childExists(object);
	}

	childTypeSupported(childType: mObjectDef) {
		// Check this container supports children of this type
		const childTypes = this.childInfo;

		for (let i = 0; childTypes.length > i; i++) {
			if (childTypes[i].baseType === childType.baseType) {
				return true;
			}
		}
		return false;
	}

	supportsNewInstance(childType: mObjectDef) {
		const max = this.objectDef.getMaxInstances(childType);
		const count = this.getNumOfInstancesOfType(childType);

		return max > count;
	}

	supportsAdd(object: any) {
		return this.supportsNewInstance(object.objectDef);
	}

	maxFieldSize(childType: mObjectDef) {
		return this.objectDef.getMaxInstances(childType);
	}

	isSingleton(childType: mObjectDef) {
		return this.maxFieldSize(childType) === 1;
	}

	supportsRemove(object: any) {
		const min = this.objectDef.getMinInstances(object.objectDef);
		const count = this.getNumOfInstancesOfType(object.objectDef);

		return count > min;
	}

	minFieldSize(childType: mObjectDef) {
		return this.objectDef.getMinInstances(childType);
	}

	getNumOfInstancesOfType(childType: mObjectDef) {
		return this.getChildRelationships(childType).length;
	}

	loadFieldByName(
		params: { name: string; flush: boolean; subType?: number },
		callback: (err: Error | null, data: any) => void
	) {
		const { name, flush, subType } = params;

		const childType = this.childInfo.find((x) => x.name === name);

		if (!childType) {
			throw new Error(`Field of name ${name} does not exist`);
		}

		this.loadField({ childType: childType as mObjectDef, flush: flush, subType: subType }, callback);
	}

	// Lazy-load a single field
	loadField(
		params: { childType: mObjectDef; flush: boolean; subType?: number },
		callback: (err: Error | null, data: any) => void
	) {
		const { childType, flush, subType } = params;

		if (!this.childTypeSupported(childType)) {
			throw Error(`Field type "${childType.name}" is not supported in this object.`);
		}

		if (this.fieldLoaded(childType) && !flush) {
			callback(
				null,
				this.getObjectField(childType).filter((x) => !subType || x.objectDef._id === subType)
			);
		} else {
			console.warn("loading children from source with child type: " + childType.pluralName);

			// Need to reload the ids for this field and for this object
			// from the underlying data source
			(DataService as any).getInstance().loadObject(this.id, (err: Error | null, object: any) => {
				if (err) {
					console.log("Cannot load underlying object ids: " + err);
				}

				// We need to remove the original ids as we are refreshing
				// the object from the underlying data store
				this.clearChildRelationships(childType);

				// Now set the field ids in this instance to
				// the ones from the object that is loaded from the data store
				const ids = object.getChildRelationships(childType).map((r: any) => r.recordId);

				for (let i = 0; ids.length > i; i++) {
					this.addRelationship(RecordRelationshipType.Child, childType.id, ids[i]);
				}

				// Now load the children with the updated ids
				this.loadChildren(callback, { ids: ids, subType: subType });
			});
		}
	}

	reloadFieldByName(name: string, callback: (err: Error | null, data: any) => void) {
		const childType = (this as any).object.childInfo.find((x: any) => x.name === name);

		if (!childType) {
			throw new Error(`Field of name ${name} does not exist`);
		}

		this.reloadField(childType, callback);
	}

	reloadField(childType: mObjectDef, callback: (err: Error | null, data: any) => void) {
		//Force a reload of all field children
		this.loadField(
			{
				flush: true,
				childType: childType,
			},
			callback
		);
	}

	// Lazy-load all fields
	loadAllFields(callback: (err: any, data: any) => void) {
		if (this.fieldsLoaded()) {
			callback(null, this.getAllFieldValues());
		} else {
			let childRecordIds: number[] = [];

			const childTypes = this.childInfo;

			for (let i = 0; childTypes.length > i; i++) {
				childRecordIds = childRecordIds.concat(this.getChildRelationships(childTypes[i]).map((r) => r.recordId));
			}

			this.loadChildren(callback, { ids: childRecordIds });
		}
	}

	loadChildren(callback: (err: any, data: any) => void, params: { ids: number[]; subType?: number } = { ids: [] }) {
		const { ids, subType } = params;

		if (!this.supportsChildren()) {
			callback("This object does not support children", null);
		}

		(DataService as any).getInstance().loadObjects(ids, (err: any, objectChildren: any) => {
			if (err) {
				callback(err, null);
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-this-alias
			const self = this;

			function mapChildren(children: any[]) {
				for (let i = 0; children.length > i; i++) {
					self.addToField(children[i], i === 0); // Reset the field values on the first call (i === 0)
				}
			}

			// If def children, aren't loaded, then load them
			// We need them to verify what child types are permitted.
			if (this.objectDef.hasChildren() && !this.objectDef.childrenLoaded()) {
				this.objectDef.loadChildren((err, data) => {
					mapChildren(objectChildren);
					callback(err, objectChildren);
				});
			} else {
				mapChildren(objectChildren);
				if (subType) {
					callback(
						err,
						objectChildren.filter((x: any) => x.definitionId === subType)
					);
				} else {
					callback(err, objectChildren);
				}
			}
		});
	}

	//Check if any of the fields have an id set
	hasChildren() {
		const childTypes = this.childInfo;

		for (let i = 0; childTypes.length > i; i++) {
			const relationships = this.getChildRelationships(childTypes[i]);

			if (relationships.length > 0) {
				return true;
			}
		}
		return false;
	}

	// Get all children is a semantically convenient
	// method that simply calls getAllFieldValues();

	getChildren() {
		return this.getAllFieldValues();
	}

	// Get all children is a semantically convenient
	// method that simply calls fieldsLoaded();

	childrenLoaded() {
		return this.fieldsLoaded();
	}

	supportsChildren() {
		return this.objectDef.hasChildren();
	}

	fieldsLoaded() {
		let fieldsLoaded = true;

		const childTypes = this.childInfo;

		for (let i = 0; childTypes.length > i; i++) {
			if (!this.fieldLoaded(childTypes[i] as any)) {
				fieldsLoaded = false;
			}
		}

		return fieldsLoaded;
	}

	fieldLoaded(childType: mObjectDef) {
		const field = this.getObjectField(childType);
		const relationships = this.getChildRelationships(childType);

		if (!field) {
			return false;
		}

		return relationships.length === field.length;
	}

	getAllFieldValues() {
		if (!this.fieldsLoaded()) {
			throw Error(`Not all fields are loaded`);
		}

		let children: any[] = [];

		const objectChildInfos = this.childInfo;

		for (let i = 0; objectChildInfos.length > i; i++) {
			children = children.concat(this.getObjectField(objectChildInfos[i] as any));
		}

		return children;
	}

	get childInfo() {
		return this.objectDef.childInfo;
	}

	// Recursively get all object instances across all fields and children.

	getAllInstances() {
		const objectInsts: any[] = [];

		objectInsts.push(this);

		this.doGetAllInstances(this.getFields(this), objectInsts);

		return objectInsts;
	}

	doGetAllInstances(instances: any[], objectInsts: any[]) {
		for (let i = 0; instances.length > i; i++) {
			const thisInst = instances[i];

			// Some objects may exist in several places in the graph
			// structure. We need to make sure we only store each instance once.
			if (!objectInsts.includes(thisInst)) {
				objectInsts.push(thisInst);
			}

			thisInst.doGetAllInstances(thisInst.getFields(thisInst), objectInsts);
		}
	}

	// Recursively search for an object with a specific id across all fields

	getObjectInstanceById(id: number) {
		if (this.id === id) {
			return this;
		}

		const objectInstance = this.doGetObjectInstanceById(id, this.getFields(this));

		if (!objectInstance) {
			throw new Error(`Object instance with id ${id} not found!`);
		}
		return objectInstance;
	}

	doGetObjectInstanceById(id: any, children: any[]): any {
		for (let i = 0; children.length > i; i++) {
			const thisObjectInstance = children[i];

			if (thisObjectInstance.id === id) {
				return thisObjectInstance;
			}

			const objectInstance = this.doGetObjectInstanceById(id, this.getFields(thisObjectInstance));

			if (objectInstance !== null) {
				return objectInstance;
			}
		}
		return null;
	}

	getFileStoreInstances() {
		return this.data.getFileStoreInstances();
	}

	getFields(objectInst: any): any {
		let children: any[] = [];

		if (objectInst.hasChildren()) {
			const childTypes = objectInst.childInfo;

			for (let i = 0; childTypes.length > i; i++) {
				children = children.concat(objectInst.getObjectField(childTypes[i]));
			}
		}
		return children;
	}

	/** start interface methods **/

	// Allows polymorphism in various UI libraries.
	// JS doesn't support interfaces ;-(

	get definitionId() {
		return this.objectDef.id;
	}

	get index() {
		if (!this.hasParents()) {
			return;
		}

		const children = this.parentContext.getObjectField(this.objectDef);

		for (let i = 0; children.length > i; i++) {
			if (this.id === children[i].id) {
				return i;
			}
		}

		return -1;
	}

	getParentObjectsByType(id: number) {
		const childType = this.parentContext.objectDef.getChildById(id);
		return this.parentContext.getObjectField(childType);
	}

	getParentObjectByIndex(index: any, childType: any) {
		const children = this.parentContext.getObjectField(childType);

		if (index > children.length - 1 || index < 0) {
			throw Error(
				`index out of range - must be greater than zero and less than size of the children array (${children.length})`
			);
		}

		return children[index];
	}

	validate() {
		const instances = this.data.getAllInstances();

		const errors = [];

		// ignore the first object as this is the current container (data)

		for (let i = 1; instances.length > i; i++) {
			try {
				instances[i].validate();
			} catch (error) {
				errors.push({
					fieldInstance: instances[i],
					error: error,
				});
			}
		}
		return {
			isValid: errors.length === 0,
			errors: errors,
		};
	}

	isValid() {
		return this.validate().isValid;
	}

	// TODO: Remove in https://salesdesk101.atlassian.net/browse/SAL-2267
	getActions() {
		return [];
	}

	/** end interface methods **/
	createFields() {
		// If object def children exist and are loaded, then instantiate new objects fields and
		// instances in line with multiplicity rules.

		if (this.objectDef.hasChildren()) {
			const childTypes = this.childInfo;

			for (let i = 0; childTypes.length > i; i++) {
				const childType = childTypes[i] as mObjectDef;

				(this as any)[this.getObjectFieldName(childType)] = [];

				if (this.isSingleton(childType)) {
					// If we only allow onc child in this field allow for direct get and set of a single value.

					const fieldName = `${childType.name?.toLowerCase()}`;

					if (!this[fieldName as keyof mObjectInst]) {
						Object.defineProperty(this, fieldName, {
							get: () => {
								if (!this.fieldLoaded(childType)) {
									throw Error(`"${childType.name}" field not loaded`);
								}

								const objects = this.getObjectField(childType);
								if (objects && objects[0]) {
									return objects[0];
								}

								return null;
							},
							set: (object) => {
								if (!this.fieldLoaded(childType)) {
									throw Error(`"${childType.name}" field not loaded`);
								}

								this.addToField(object, true);
							},
						});
					}
				} else {
					const fieldName = `${childType.pluralName?.toLowerCase()}`;

					if (!this[fieldName as keyof mObjectInst]) {
						Object.defineProperty(this, fieldName, {
							get: () => {
								if (!this.fieldLoaded(childType)) {
									throw Error(`"${childType.name}" field not loaded`);
								}

								return this.getObjectField(childType);
							},
							set: (objects) => {
								if (!this.fieldLoaded(childType)) {
									throw Error(`"${childType.name}" field not loaded`);
								}

								if (!isEmpty(objects)) {
									for (let i = 0; objects.length > 0; i++) {
										this.addToField(objects[i], false);
									}
								}
							},
						});
					}
				}
			}
		}
	}

	storeConfigurable(force: boolean, callback: (err: any, object: any) => void) {
		if (force) {
			this.storeForce(callback);
		} else {
			this.store(callback);
		}
	}

	storeForce(callback: (err: any, object: any) => void) {
		(DataService as any).getInstance().storeObjectForce(this, callback);
	}

	store(callback: (err: any, object: any) => void) {
		(DataService as any).getInstance().storeObject(this, callback);
	}

	reload(callback: (err: any, object: any) => void) {
		(DataService as any).getInstance().loadObject(this.id, (error: any, object: any) => {
			this.marshall(object.objectData, this.objectDef);

			if (callback) {
				callback(error, object);
			}
		});
	}

	marshall(objectData: any, objectDef: any) {
		// Take the raw data object (from storage) and bind it to the fields in this JS class instance

		Object.assign(this, objectData);

		// Set the data instance data objects

		const cloneData = JSON.parse(JSON.stringify(this.data));

		this._objectData = objectData;

		const fieldData = objectDef.data.clone();

		this.data = FieldInstanceFactory.newInstanceByType(objectDef.data._type).marshall(fieldData, cloneData);
		this.data.dataDef = objectDef.data;

		return this;
	}

	unmarshall() {
		// Remove parent, children and data fields before storing. We don't want to store loads of repeat
		// data in JSON or in the DB when it can be resolved when we call marshall(), or loaded
		// lazily using the parent, data, owner, children, etc. ids.
		//
		// We create a clone and store that as we don't want to remove field data form an object
		// that we might continue to use after storing it.

		const clone = this.clone() as any;

		clone.data = clone.data.unmarshall();

		clone.setUnderlyingName(this.name);

		delete clone._baseType;
		delete clone._className;
		delete clone._owner;
		delete clone._objectDef;
		delete clone._objectData;
		delete clone._parents;
		delete clone._parentContext;
		delete clone._persisted;
		delete clone._dirty;
		delete clone._dataInst.dataDef;

		const childTypes = this.childInfo;

		for (let i = 0; childTypes.length > i; i++) {
			delete clone[clone.getObjectFieldName(childTypes[i] as mObjectDef)];
		}

		return clone;
	}

	clone() {
		const clone = deepClone(this);

		// JF - This is important. Otherwise the dynamically defined getters and setters
		// that map to underlying field instances no longer resolve in the cloned copy.
		clone.data.createFields();

		clone.parentContext = null;

		return clone;
	}

	// Copy the fields and properties of the incoming object over the
	// exsiting fields and propertis in this object
	// We do not currently override children or parent ids or fields

	copyFrom(objectInstance: mObjectInst) {
		if (objectInstance._type !== this._type) {
			throw Error(`The object instances are not of the same type and therefore cannot be merged`);
		}

		if (this._id !== objectInstance.id) {
			throw Error(`The object instances do not have the same id and therefore cannot be merged`);
		}

		this._ownerId = objectInstance._ownerId;
		(this as any).createdBy = (objectInstance as any).createdBy; // doesn't exist on mObjectInst?
		this._className = objectInstance._name;
		this._type = objectInstance._type;
		this._name = objectInstance._name;
		this.createdAt = objectInstance.createdAt;
		this.updatedAt = objectInstance.updatedAt;
		this.updatedBy = objectInstance.updatedBy;
		this._objectDef = objectInstance._objectDef;
		this._objectDefId = objectInstance._objectDefId;
		this._objectData = objectInstance._objectData;
		this._parentContext = null;
		this._deleted = objectInstance._deleted;
		this._persisted = objectInstance._persisted;
		this._dirty = objectInstance._dirty;
		this._definitionVersion = objectInstance._definitionVersion;
		this._version = objectInstance._version;
		this._baseType = objectInstance._baseType;

		// Get all field instances
		const fieldInstances = this.data.getAllInstances();

		// Copy all listeners from this object as they will be over-written
		const listeners = {};

		for (let i = 0; fieldInstances.length > i; i++) {
			const fieldInstance = fieldInstances[i];
			(listeners as any)[fieldInstance._id] = fieldInstance._listeners;
		}

		// Now set the field data to the data values in the object passed in
		this.data = objectInstance.data;

		const newFieldInstances = this.data.getAllInstances();

		// Attach event listeners originally attached to this object

		for (let i = 0; newFieldInstances.length > i; i++) {
			const newFieldInstance = newFieldInstances[i];
			newFieldInstance.removeEventListeners();

			//See if there are original event listeners to be attached to this new object

			if ((listeners as any)[newFieldInstance._id]) {
				newFieldInstance.setEventListeners((listeners as any)[newFieldInstance._id]);
			}

			//Now reset values to fire off any relevant events
			if (!newFieldInstance.isContainer()) {
				newFieldInstance.value = newFieldInstance._value;
			}
		}
	}

	equals(object: mObjectInst) {
		return this.id === object.id;
	}

	toJson() {
		return JSON.stringify(
			this,
			function replacer(key, value) {
				// We don't to show all of some fields as it causes a recursion issue

				if (key === "_dataDef") {
					if (value === null || value === undefined) {
						return null;
					} else {
						return {
							_id: value._id,
							_name: value._name,
							_type: value._type,
						};
					}
				} else if (key === "_dataInst") {
					if (value === null || value === undefined) {
						return null;
					} else {
						return {
							_id: value._id,
							_name: value._field._name,
							_type: value._fieldType,
						};
					}
				} else if (key === "_parent") {
					if (value === null || value === undefined) {
						return null;
					} else {
						return {
							_id: value._id,
							_name: value._objectDefName,
							_type: value._type,
						};
					}
				} else if (key === "_children") {
					if (value === null || value === undefined) {
						return null;
					} else {
						return {
							_num_of_children: value.length,
						};
					}
				}
				return value;
			},
			2
		);
	}
}
