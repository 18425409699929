import { asConst } from "json-schema-to-ts";
import { fieldCreateRequestSchema } from "./fieldCreateRequestSchema";

export const fieldSchema = asConst({
	title: "SDObject Field",
	description: "An SDObject Field schema which describes a field on a record for the SDObject",
	type: "object",
	additionalProperties: false,
	required: ["_id", ...fieldCreateRequestSchema.required],
	properties: { ...fieldCreateRequestSchema.properties },
});
