import { buildApiInvalidatedTagList, buildApiTagsList, SDApi } from "../../../api";
import { NotificationRule, NotificationRuleCreateRequest } from "@salesdesk/salesdesk-schemas";

export const notificationRuleApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getNotificationRules: builder.query<NotificationRule[], void>({
				query: () => ({
					url: `/me/notifications/settings/rules`,
					method: "GET",
				}),
				providesTags: (rules) => buildApiTagsList(rules, (rule) => rule.id, "NotificationRules"),
			}),
			createNotificationRule: builder.mutation<NotificationRule, NotificationRuleCreateRequest>({
				query: (request) => ({
					url: `/me/notifications/settings/rules`,
					method: "POST",
					body: request,
				}),
				invalidatesTags: (rule) => buildApiInvalidatedTagList(rule, (rule) => rule.id, "NotificationRules"),
			}),
			updateNotificationRule: builder.mutation<void, [NotificationRule["id"], NotificationRuleCreateRequest]>({
				query: ([id, request]) => ({
					url: `/me/notifications/settings/rules/${id}`,
					method: "PUT",
					body: request,
				}),
				invalidatesTags: (result, error, request) =>
					buildApiInvalidatedTagList(request, ([id]) => id, "NotificationRules"),
			}),
			deleteNotificationRule: builder.mutation<void, NotificationRule["id"]>({
				query: (id) => ({
					url: `/me/notifications/settings/rules/${id}`,
					method: "DELETE",
				}),
				invalidatesTags: (result, error, id) => buildApiInvalidatedTagList(id, (id) => id, "NotificationRules"),
			}),
		};
	},
});

export const {
	useGetNotificationRulesQuery,
	useCreateNotificationRuleMutation,
	useUpdateNotificationRuleMutation,
	useDeleteNotificationRuleMutation,
} = notificationRuleApi;
