import { useRef } from "react";

import { FaceSentimentGraph } from "./FaceAnalysis/components/FaceSentimentGraph";
import { TranscriptSentimentGraph } from "./TranscriptAnalysis/TranscriptSentimentGraph";
import { useMediaAnalysisContext } from "../../hooks/useMediaAnalisysContext";
import { VideoCursor } from "./VideoCursor";
import { VideoTimeCursor } from "./VideoTimeCursor";

export function SentimentAnalysis() {
	const { faceSentimentsPerUser, transcriptSentimentsPerUser } = useMediaAnalysisContext();
	const holderRef = useRef<HTMLDivElement>(null);

	if (!faceSentimentsPerUser || !transcriptSentimentsPerUser) {
		return <div className="text-c_text_placeholder flex w-full text-center">Sentiment analysis unavailable</div>;
	}

	return (
		<div className="flex max-h-full flex-col justify-stretch">
			<div className="relative flex-grow select-none overflow-x-hidden">
				<div ref={holderRef}>
					<VideoTimeCursor holderRef={holderRef} />
					<VideoCursor holderRef={holderRef} />
					<div className="mt-1 flex flex-col gap-4 pb-6">
						<FaceSentimentGraph />
						<TranscriptSentimentGraph />
					</div>
				</div>
			</div>
		</div>
	);
}
