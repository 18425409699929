import { asConst } from "json-schema-to-ts";
import { subQueryClauseSchema } from "./subQueryClauseSchema";
import { subQueryClausesSchema } from "./subQueryClausesSchema";

export const queryClausesSchema = asConst({
	title: "Allowed query clauses",
	type: "object",
	anyOf: [subQueryClauseSchema, ...subQueryClausesSchema.anyOf],
	nullable: false,
});
