import {
	ChannelMembershipParams,
	ChannelMessageMentionParams,
	CommentMentionParams,
	getEventActionText,
	NoteMentionParams,
	SDEvent,
} from "@salesdesk/salesdesk-schemas";
import { SdEventType } from "@salesdesk/salesdesk-model";

import { useEventContext } from "../../hooks/useEventContext";
import { useGetRecordWithObjectByRecordId } from "../../../../hooks";
import { useWebPrincipal } from "../../../../auth";
import { getWhoUserText } from "../../utils";

interface EventActionProps {
	isSystemUser?: boolean;
}

export function EventAction({ isSystemUser = false }: EventActionProps) {
	const { event } = useEventContext();
	const principal = useWebPrincipal();

	// Says who was mentioned in a note
	let additionalParams: Record<string, string> | undefined = undefined;

	const whoUserRecordId = getWhoUserRecordId(event);
	const whoUserIsYou = whoUserRecordId === principal.UserRecordId;
	const {
		sdRecord: mentionedUserRecord,
		sdObject: mentionedUserObject,
		isLoading: isLoadingMentionedUser,
	} = useGetRecordWithObjectByRecordId(whoUserRecordId != null && !whoUserIsYou ? whoUserRecordId : undefined);
	if (whoUserRecordId != null) {
		additionalParams = {
			who: getWhoUserText(isLoadingMentionedUser, mentionedUserObject, mentionedUserRecord, whoUserIsYou),
		};
	}

	const actionText = getEventActionText(event, isSystemUser, additionalParams);

	if (isSystemUser) {
		return actionText;
	}
	return <span className="text-label-sm">{actionText}</span>;
}

function getWhoUserRecordId(event: SDEvent): number | undefined {
	switch (event.event_type) {
		case SdEventType.NOTE_MENTION:
			return (event.params as NoteMentionParams)?.mentionedUserRecordId;
		case SdEventType.MESSAGE_MENTION:
			return (event.params as ChannelMessageMentionParams)?.mentionedUserRecordId;
		case SdEventType.COMMENT_MENTION:
			return (event.params as CommentMentionParams)?.mentionedUserRecordId;
		case SdEventType.MESSAGE_CHANNEL_MEMBER_CREATED:
		case SdEventType.MESSAGE_CHANNEL_MEMBER_DELETED:
			return (event.params as ChannelMembershipParams)?.memberUserRecordId;
		default:
			return undefined;
	}
}
