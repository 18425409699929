import { faceSentimentTypes, faceSentimentColors } from "../../../../types";
import { BaseSelect, BaseSelectButtonProps, SelectOption, SelectOptionId } from "../../../../../inputs";
import { forwardRef, useMemo } from "react";
import { capitalizeString } from "@salesdesk/salesdesk-utils";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useMediaAnalysisContext } from "../../../../hooks/useMediaAnalisysContext";
import { Button, AlertBubble } from "@salesdesk/daisy-ui";

interface FaceSentimentSelectProps {
	value: SelectOptionId[];
	onChange: (value?: SelectOptionId[]) => void;
}

export function FaceSentimentSelect({ value, onChange }: FaceSentimentSelectProps) {
	const { setAnyPopoverSelectOpen } = useMediaAnalysisContext();

	const options: SelectOption[] = useMemo(
		() =>
			faceSentimentTypes.map((faceSentimentType) => {
				const name = capitalizeString(faceSentimentType.toLowerCase());
				return {
					id: faceSentimentType as SelectOptionId,
					name: name,
					textColor: faceSentimentColors[faceSentimentType],
					icon: (ICONS as Record<string, string>)["sentiment" + name],
				};
			}),
		[]
	);

	return (
		<BaseSelect
			isMultiselect
			value={value}
			onChange={onChange}
			options={options}
			optionsTitle="Sentiment type"
			selectButton={<FilterButton count={value.length ?? undefined} />}
			onOpenChange={(isOpen) => {
				setAnyPopoverSelectOpen(isOpen);
			}}
		/>
	);
}

interface FilterButtonProps {
	count?: number;
}

const FilterButton = forwardRef<HTMLButtonElement, BaseSelectButtonProps & FilterButtonProps>(
	({ count, selectedOption, ...restProps }, ref) => {
		return (
			<div className="flex h-6 items-center">
				<Button ref={ref} {...restProps} startIcon={ICONS.filter} variant="text" size="xs">
					{count ? <AlertBubble alertCount={count} variant="secondary" /> : null}
				</Button>
			</div>
		);
	}
);
