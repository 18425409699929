import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../../functions";
import { FIELD_CREATION_TYPE } from "@salesdesk/salesdesk-model";

// TODO:  This should be consistent with creationTypeSchema.  They are opposite!
export const fieldCreationTypeSchema = asConst({
	title: "SDObject Field Creation Type",
	description: appendNotEditable(
		"The creation type of the Field.  Defaulted to User=1 for user created Fields.  There are restrictions on editing System field."
	),
	type: "integer",
	enum: Object.values(FIELD_CREATION_TYPE),
});
