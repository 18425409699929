import { RowData, Table } from "@tanstack/react-table";
import { createContext, MutableRefObject, RefObject, useContext } from "react";

export interface LinkedTableState {
	widthByColumnId: Record<string, string | number>;
	updateColumnWidth: (columnId: string, width: string | number) => void;
	trackerPosition?: number;
	setTrackerPosition: (position: number | undefined) => void;
	hasHorizontalOverflow?: boolean;
	setHasHorizontalOverflow: (hasHorizontalOverflow: boolean) => void;
	tableById: RefObject<Record<string, Table<RowData>>>;
	registerTable: (id: string, table: Table<RowData>) => void;
	refresh?: () => void;
	refreshKey: number;
	toggleAllRowsSelected?: MutableRefObject<(checked: boolean) => void>;
}

export const LinkedTablesContext = createContext<LinkedTableState | undefined>(undefined);

export function useLinkedTablesState(): LinkedTableState | undefined {
	return useContext(LinkedTablesContext);
}
