import { Claim, ClaimsPrincipal } from "@salesdesk/salesdesk-schemas";

import { Auth } from "../../auth";
import { APP_CONFIG } from "../app_config.js";
import { getTenantFromPath } from "../../utils";

export class App {
	static initialised = false;
	static accessTokenPromise = null;

	static config = {
		...APP_CONFIG,
	};

	static init(callback: () => void) {
		if (App.initialised) {
			callback();
			return;
		}

		try {
			Auth.ensureLogin(() => {
				App.initWithCredentials(callback);
			});
		} catch (err) {
			console.warn(err);
			Auth.logout();
		}
	}

	static initWithCredentials(callback: () => void) {
		App.initialised = true;
		callback();
		return App;
	}

	static currentLocation = "/";

	// Return value of the form {"userId": number, "lastActivityTime": number}

	static async getClaimsPrincipal() {
		const tenantId = getTenantFromPath();
		if (tenantId == null) {
			throw new Error("Tenant ID not found");
		}
		const response = await fetch(`${APP_CONFIG.sdApiHost}/me/claims`, {
			headers: {
				"X-Sd-Tenant": tenantId,
				Authorization: await Auth.getAuthHeader(),
				Accept: "application/json",
			},
		});
		if (response.ok) {
			const claims: Claim[] = await response.json();
			return new ClaimsPrincipal(claims);
		} else {
			if (response.status === 403) {
				const error = await response.json();
				if (error?.message === "TENANT_NOT_FOUND") {
					throw new Error("TENANT_NOT_FOUND");
				}
			}
			throw new Error("UNAUTHORIZED");
		}
	}
}
