import { SDObject, getSDObjectNameFieldIds } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { SortingDetails, SortingOrder } from "../types";
import { replaceElementAtIndex } from "../../../../../utils";

export function generateNewSortingDetails(sdObject: SDObject): SortingDetails {
	const nameFieldIds = getSDObjectNameFieldIds(sdObject);
	return { fieldId: String(nameFieldIds[0]), order: SortingOrder.desc };
}

export const SORTING_ORDER_OPTIONS = [
	{
		id: SortingOrder.asc,
		name: "Ascending",
		icon: ICONS.sortAsc,
	},
	{
		id: SortingOrder.desc,
		name: "Descending",
		icon: ICONS.sortDesc,
	},
];

export function updateSortingDetailsForField(
	currentSortingDetails: SortingDetails[],
	fieldId: string,
	sortingOrder?: SortingOrder
): SortingDetails[] {
	const sortIndex = currentSortingDetails.findIndex((sort) => sort.fieldId === fieldId);

	let updatedSortingDetails = currentSortingDetails;

	if (sortIndex === -1 && sortingOrder) {
		updatedSortingDetails = [...currentSortingDetails, { fieldId, order: sortingOrder }];
	} else if (sortIndex !== -1 && !sortingOrder) {
		updatedSortingDetails = currentSortingDetails.filter((sort) => sort.fieldId !== fieldId);
	} else if (sortingOrder) {
		updatedSortingDetails = replaceElementAtIndex(currentSortingDetails, sortIndex, {
			fieldId,
			order: sortingOrder,
		});
	}

	return updatedSortingDetails;
}
