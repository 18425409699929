import { getSDObjectNameFieldIds, SDObject } from "@salesdesk/salesdesk-schemas";
import { ASSET_RECORD_COMBINED_NAME_FILE_ID, RECORD_COMBINED_NAME_ID } from "../../../../types";
import { useMemo } from "react";

const DEFAULT_NAME_COLUMN_WIDTH = 300;

export function useDefaultColumnWidths(sdObject: SDObject | undefined) {
	return useMemo(() => {
		const sizes: Record<string, number> = {
			[RECORD_COMBINED_NAME_ID]: DEFAULT_NAME_COLUMN_WIDTH,
			[ASSET_RECORD_COMBINED_NAME_FILE_ID]: DEFAULT_NAME_COLUMN_WIDTH,
		};
		if (!sdObject) {
			return sizes;
		}
		for (const fieldId of getSDObjectNameFieldIds(sdObject)) {
			sizes[String(fieldId)] = DEFAULT_NAME_COLUMN_WIDTH;
		}
		return sizes;
	}, [sdObject]);
}
