import { clsx } from "clsx";

import { Transition } from "@headlessui/react";

import { tw } from "../../../utils/tailwind-helpers";
import { Icon } from "@salesdesk/daisy-ui";

interface NavItemContentProps {
	isSelected: boolean;
	icon: string;
	expanded?: boolean;
	text: string;
}

export function NavItemContent({ isSelected, icon, expanded, text }: NavItemContentProps) {
	return (
		<>
			<Icon
				className={clsx(
					"group-hover:text-c_icon_regular group-focus-visible:text-c_icon_regular flex h-5 w-5 items-center justify-center",
					isSelected ? "text-c_text_primary" : "text-c_icon_regular"
				)}
				icon={icon}
			/>
			<Transition
				show={expanded}
				className="ml-4 truncate transition-all"
				enterFrom={tw`opacity-0 max-w-0`}
				enterTo={tw`opacity-100 max-w-64`}
				leaveFrom={tw`opacity-100 max-w-64`}
				leaveTo={tw`opacity-0 ml-0 max-w-0`}
			>
				{text}
			</Transition>
		</>
	);
}
