import { EditableRecordTitleField } from "./EditableRecordTitleField";
import { DisplayRecordTitleField } from "./DisplayRecordTitleField";
import { FieldInfo, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { UserLoginStatus } from "../../../../../..";
import { UserAuthorisedLoginIcon } from "../../../../../../../../users/components/UserAuthorisedLoginIcon";

interface RecordTitleFieldProps {
	title: string | FieldInfo;
	sdObject?: SDObject;
	sdRecord?: SDRecord;
}

export function RecordTitleField({ title, sdObject, sdRecord }: RecordTitleFieldProps) {
	const titleString = typeof title === "string" ? title : ((title.value ?? "") as string);

	return (
		<div className="flex w-full items-center justify-stretch gap-2">
			<div className="flex-grow">
				{typeof title === "string" || !sdObject || !sdRecord ? (
					<DisplayRecordTitleField>
						<div className="flex items-center gap-3">
							{titleString}
							{sdRecord ? <UserAuthorisedLoginIcon sdRecord={sdRecord} /> : null}
						</div>
					</DisplayRecordTitleField>
				) : (
					<EditableRecordTitleField sdObject={sdObject} sdRecord={sdRecord} titleFieldInfo={title} />
				)}
			</div>
			{sdObject && sdRecord ? <UserLoginStatus userObject={sdObject} userRecord={sdRecord} /> : null}
		</div>
	);
}
