import { getSDRecordName, NotificationRule, NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { useMemo, useState } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { formatSince, mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";
import clsx from "clsx";
import { Button, Icon } from "@salesdesk/daisy-ui";
import { MenuContents, PopoverMenu } from "../../../../menu";
import { NotificationRuleDialogContainer } from "../createNotificationRuleDialog/NotificationRuleDialogContainer";
import { useUpdateNotificationRuleMutation } from "../../api/notificationRuleApi";
import { useToast } from "../../../../Toasts";
import { toCreateRequest } from "../../utils";
import { DeleteNotificationRuleDialog } from "./DeleteNotificationRuleDialog";
import { NotificationRuleDescription } from "./descriptions/NotificationRuleDescription";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { RecordUpdateBadge } from "../common/RecordUpdateBadge";
import { UserActivityBadge } from "../common/UserActivityBadge";
import { useGetRecordWithObjectByRecordId } from "../../../../../hooks";

interface NotificationRuleProps {
	rule: NotificationRule;
}

export function NotificationRuleCard({ rule }: NotificationRuleProps) {
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const { sdObject } = useGetObjectById(rule.objectId || undefined);
	const { sdRecord: workspaceRecord, sdObject: workspaceObject } = useGetRecordWithObjectByRecordId(
		rule.workspaceId ?? undefined
	);

	const [updateNotificationRule] = useUpdateNotificationRuleMutation();
	const toast = useToast();

	const menuContents: MenuContents = useMemo(() => {
		return [
			[
				{
					icon: ICONS.simplePencil,
					text: "Edit rule",
					onClick: () => setOpenEditDialog(true),
					type: "button",
				},
				{
					icon: rule.enabled ? ICONS.notificationOff : ICONS.notification,
					text: rule.enabled ? "Make inactive" : "Make active",
					onClick: () => {
						const messageKey = rule.enabled ? "notification_rule_disabled" : "notification_rule_enabled";
						updateNotificationRule([rule.id, { ...toCreateRequest(rule), enabled: !rule.enabled }])
							.unwrap()
							.then(() => toast.triggerMessage({ messageKey, type: "success" }))
							.catch(() => toast.triggerMessage({ messageKey, type: "error" }));
					},
					type: "button",
				},
			],
			[
				{
					icon: ICONS.trash,
					text: "Delete rule",
					variant: "destructive",
					onClick: () => setOpenDeleteDialog(true),
					type: "button",
				},
			],
		];
	}, [rule, toast, updateNotificationRule]);

	const displayObjectName = rule.ruleType === NotificationRuleType.Record && sdObject;
	const displayWorkspace = workspaceRecord && workspaceObject;
	return (
		<>
			{openEditDialog ? <NotificationRuleDialogContainer rule={rule} onOpenChange={setOpenEditDialog} /> : null}
			{openDeleteDialog ? <DeleteNotificationRuleDialog rule={rule} onOpenChange={setOpenDeleteDialog} /> : null}
			<div
				className={clsx(
					"rounded-minimal border-c_border_regular flex min-h-36 flex-col justify-between border p-4",
					"hover:bg-c_bg_03 focus-visible-within:bg-c_bg_03 group relative gap-4"
				)}
			>
				<div className="ui-open-within:opacity-100 absolute right-4 top-2.5 opacity-0 transition-opacity group-focus-within:opacity-100 group-hover:opacity-100">
					<PopoverMenu menuContents={menuContents}>
						<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
					</PopoverMenu>
				</div>
				<div className="flex justify-between">
					{rule.ruleType === NotificationRuleType.Record ? <RecordUpdateBadge /> : <UserActivityBadge />}
					<div className="text-label-xs text-c_text_secondary">
						{formatSince(mapUtcTimeStampToLocalDateTime(rule.updatedAt, false), "short", 6)}
					</div>
				</div>
				<div className="grow">
					<NotificationRuleDescription rule={rule} />
				</div>
				<div className="text-label-xs text-c_text_disabled flex w-full max-w-full grow items-center gap-2">
					{displayObjectName ? (
						<>
							<Icon icon={sdObject._icon} size="sm" className="flex items-center" />
							<div>{sdObject._displayName}</div>
						</>
					) : null}
					{displayObjectName && displayWorkspace ? <div>-</div> : null}
					{displayWorkspace ? (
						<div className="truncate">{getSDRecordName(workspaceObject, workspaceRecord)}</div>
					) : null}
				</div>
			</div>
		</>
	);
}
