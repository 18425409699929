import { asConst } from "json-schema-to-ts";
import { recordIdSchema } from "../../../records";

export const isSharedWithWorkspaceClauseSchema = asConst({
	title: "Is Shared with Workspace Clause",
	description: "Will match any record that is shared with the given workspace record Id.",
	type: "object",
	additionalProperties: false,
	required: ["isSharedWithWorkspace"],
	properties: {
		isSharedWithWorkspace: recordIdSchema,
	},
	nullable: false,
});
