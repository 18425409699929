export const FIELD_COMPONENT_TYPES = {
	CHECKBOX: {
		name: "checkbox",
		displayName: "Checkbox",
	},
	COLOR: {
		name: "color",
		displayName: "Color",
	},
	DATE: {
		name: "date",
		displayName: "Date",
	},
	DATE_TIME: {
		name: "date_time",
		displayName: "Date and Time",
	},
	TIME_RANGE: {
		name: "time_range",
		displayName: "Time Range",
	},
	DURATION: {
		name: "duration",
		displayName: "Duration",
	},
	FILE: {
		name: "file",
		displayName: "File",
	},
	ICON: {
		name: "icon",
		displayName: "Icon",
	},
	IMAGE_FILE: {
		name: "image_file",
		displayName: "Image",
	},
	LOGO: {
		name: "logo",
		displayName: "Logo",
	},
	OPTIONS_BUILDER: {
		name: "options_builder",
		displayName: "Options builder",
	},
	PASSWORD: {
		name: "password",
		displayName: "Password",
	},
	PDF_FILE: {
		name: "pdf_file",
		displayName: "PDF",
	},
	PROFILE_PHOTO_FILE: {
		name: "profile_photo",
		displayName: "Profile photo",
	},
	RATING: {
		name: "rating",
		displayName: "Rating",
	},
	RICH_TEXT: {
		name: "rich_text",
		displayName: "Rich Text",
	},
	SELECT: {
		name: "select",
		displayName: "Select",
	},
	TEXT: {
		name: "text",
		displayName: "Text Box",
	},
	TOGGLE_SWITCH: {
		name: "toggle_switch",
		displayName: "Toggle",
	},
	TYPEAHEAD_SINGLE_OPTION: {
		name: "typeahead_single_option",
		displayName: "Type-ahead Finder",
	},
	TYPEAHEAD_MULTI_OPTION: {
		name: "typeahead_multi_option",
		displayName: "Type-ahead Finder",
	},
	VIDEO_FILE: {
		name: "video_file",
		displayName: "Video",
	},
} as const;

export type FieldComponentName = (typeof FIELD_COMPONENT_TYPES)[keyof typeof FIELD_COMPONENT_TYPES]["name"];

export function getFieldComponentDisplayName(type: FieldComponentName) {
	const typeKey = type.toUpperCase() as keyof typeof FIELD_COMPONENT_TYPES;
	return FIELD_COMPONENT_TYPES[typeKey]?.displayName;
}
