import { asConst } from "json-schema-to-ts";
import { commaDelimitedIdsSchema } from "../components";

export const recordIdStringSchema = asConst({
	title: "SalesDesk Record String Id",
	description: "Unique system identifier for SalesDesk records, in string form",
	type: "string",
	pattern: "^[0-9]*$",
});

export const eventsQuerySchema = asConst({
	title: "Events Request",
	description: "Querystring parameters of a request to query events",
	type: "object",
	additionalProperties: false,
	properties: {
		limit: {
			// TODO: Impose upper bound on limit here
			title: "Events Limit",
			description: "Maximum number of events to return",
			type: "string",
		},
		offset: {
			title: "Events Offset",
			description: "Offset from which to start returning events",
			type: "string",
		},
		searchText: {
			title: "Search Text",
			description: "Only return events matching this text",
			type: "string",
		},
		recordId: {
			...recordIdStringSchema,
			title: "Record ID",
			description: "Only return events for this record",
		},
		objectIds: {
			...commaDelimitedIdsSchema,
			title: "Object IDs",
			description: "Comma-delimited object IDs to search on",
		},
		userIds: {
			...commaDelimitedIdsSchema,
			title: "User IDs",
			description: "Comma-delimited user IDs to search on",
		},
		eventTypes: {
			title: "Event type",
			description: "Comma-delimited event types to search on",
			// TODO: once events are enumerated in TypeScript, only allow valid event names
			type: "string",
		},
		eventSupertypes: {
			title: "Event type",
			description: "Comma-delimited event supertypes to search on",
			type: "string",
		},
	},
});
