import { asConst } from "json-schema-to-ts";
import { importIdPathParamSchema } from "./importIdPathParamSchema";

export const importPathSchema = asConst({
	title: "Import Path Parameters",
	type: "object",
	additionalProperties: false,
	required: ["importId"],
	properties: {
		importId: importIdPathParamSchema,
	},
});
