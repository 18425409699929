import { asConst } from "json-schema-to-ts";
import { booleanSchema, idSchema, nullableUtcMillisecSchema, updatableSchema } from "../components";
import { userBaseSchema } from "./userBaseSchema";

export const userSchema = asConst({
	title: "User",
	description: "A User on the SalesDesk platform",
	type: "object",
	additionalProperties: false,
	required: [...userBaseSchema.required, "userRecordId", "deleted", "lastActivity", ...updatableSchema.required],
	properties: {
		...userBaseSchema.properties,
		userRecordId: idSchema,
		deleted: booleanSchema,
		lastActivity: nullableUtcMillisecSchema,
		...updatableSchema.properties,
	},
});
