import { useCallback } from "react";

import { SDFile } from "@salesdesk/salesdesk-schemas";
import { SdEventType } from "@salesdesk/salesdesk-model";

import { useToast } from "../../Toasts";
import { usePostEvent } from "../../events/hooks/usePostEvent";
import { getFileDisplayName } from "../utils";

export function useDownloadFile(file?: SDFile) {
	const toast = useToast();
	const postEvent = usePostEvent();

	return useCallback(() => {
		const fileName = getFileDisplayName(file);

		if (!fileName || !file?.signedUrl) {
			toast.triggerMessage({ type: "error", messageKey: "file_downloaded" });
			return;
		}

		const anchor = document.createElement("a");
		anchor.href = file.signedUrl;
		anchor.target = "_blank";
		anchor.download = fileName;
		document.body.appendChild(anchor);
		anchor.click();
		anchor.remove();

		postEvent({
			event_type: SdEventType.FILE_DOWNLOADED,
			record_id: file.recordId,
			params: {
				file_id: file.fileId,
			},
		});
	}, [file, postEvent, toast]);
}
