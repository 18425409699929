import { Skeleton } from "../../../components/Skeleton/Skeleton";

export function NotificationsListLoading() {
	return (
		<div className="flex w-full shrink-0 flex-col gap-3">
			{[...Array(10)].map((_, index) => (
				<div key={index} className="flex flex-col gap-3 pt-0.5">
					<div className="ml-[24px] flex gap-2">
						<Skeleton className="size-6 shrink-0" />
						<div className="flex w-full flex-col gap-2">
							<Skeleton className="h-6 w-5/6" />
							<Skeleton className="h-6 w-1/4" />
							<Skeleton className="h-6 w-1/3" />
						</div>
					</div>
					<Skeleton className="h-px w-full" />
				</div>
			))}
		</div>
	);
}
