import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../utils";
import { mIconBackingStore } from "../backing_store";
import { mSingleOptionFieldDef } from "./single_option_field_def";

export class mIconFieldDef extends mSingleOptionFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name];
	static override defaultComponentType: FieldComponentName = mIconFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id, mIconBackingStore.TYPE);
		this._componentType = mIconFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.ICON.icon;
		this._formatDescription = "Search icons";
	}

	override get type(): string {
		return FIELD_TYPES.ICON.name;
	}

	override get supportedComponentTypes() {
		return mIconFieldDef.supportedComponentTypes;
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}
}
