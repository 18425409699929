import { useId } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { SelectOption } from "../../../../../../inputs";
import { Icon } from "@salesdesk/daisy-ui";

interface DisplayedFieldOptionProps {
	option: SelectOption;
	selected?: boolean;
	disabled?: boolean;
	onChange: (isSelected: boolean) => void;
}

export function DisplayedFieldOption({ option, selected = false, disabled, onChange }: DisplayedFieldOptionProps) {
	const optionLabelId = useId();

	const toggleCheckbox = () => {
		onChange(!selected);
	};

	return (
		<div
			className={clsx(
				selected ? " text-c_text_primary" : "text-c_text_disabled",
				!disabled && "focus-visible-within:bg-c_bg_02 hover:bg-c_bg_02",
				`text-label-nav flex w-full max-w-full select-none items-center truncate p-3 transition-colors`
			)}
			onClick={() => {
				if (disabled) {
					return;
				}
				toggleCheckbox();
			}}
		>
			<input
				type="checkbox"
				className="h-0 w-0 overflow-hidden opacity-0"
				disabled={disabled}
				aria-labelledby={optionLabelId}
				checked={selected}
				onChange={toggleCheckbox}
			/>
			<div className="flex w-full justify-between">
				<div id={optionLabelId} className="truncate">
					{option.name}
				</div>
				<Icon
					icon={selected ? ICONS.visible : ICONS.hidden}
					className={clsx(
						selected && !disabled ? "text-c_action_01" : "text-c_icon_disabled",
						"ml-8 flex transition-colors"
					)}
				/>
			</div>
		</div>
	);
}
