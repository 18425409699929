import { useEffect, useRef } from "react";
import clsx from "clsx";

import { useInfiniteScrollContainer } from "../../../../../../hooks/ui/useInfiniteScrollContainer";

import { useGetRecordAssociationsSummaryMap } from "../../../../../recordAssociations";
import { RecordCard, generatePreviewLinkMenuItem, getCurrentPathWithRecordIdParam } from "../../../../../records/";

import { BoardViewProps, FULL_RECORD_DATA_FIELD_ID, NO_FIELD_ID } from "../../../../types";
import { getFieldInfoArrayForBoardRecordDetails, getFieldInfoForBoardRecordDetails } from "../../../../utils";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";
import { useBoardFieldsToDisplaySelector, useBoardMediaFieldSelector } from "../../../../store";
import { useBulkEditContext } from "../../../BulkEdit";
import { CardViewLoading } from "./CardViewLoading";

interface CardViewProps extends BoardViewProps {
	recordIds: number[];
}

export function CardView({ boardRecords, recordIds, footer, onBottomReached, isLoading }: CardViewProps) {
	const fieldsToDisplay = useBoardFieldsToDisplaySelector();
	const { sdObject, boardFieldMap } = useDataboardDetailsContext();

	const mediaField = useBoardMediaFieldSelector();
	const mediaFieldId = (mediaField ?? NO_FIELD_ID) === NO_FIELD_ID ? null : mediaField;

	const { inBulkEditMode, selectedRecords, toggleRecordSelection } = useBulkEditContext();

	const containerRef = useRef(null);
	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef,
		verticalOffset: 650,
		onBottomReached,
	});

	const { recordAssociationsSummaryMap, clearAssociationsSummary } = useGetRecordAssociationsSummaryMap(recordIds);

	// Clears the associations summary when the board is changed
	useEffect(() => {
		clearAssociationsSummary();
	}, [clearAssociationsSummary, sdObject]);

	return (
		<div
			ref={containerRef}
			className={clsx("relative h-full max-w-full basis-full pb-6 pl-1 pr-7 pt-3.5", !isLoading && "overflow-auto")}
		>
			<div className="grid-cols-cards grid items-center gap-6">
				{isLoading ? (
					<CardViewLoading />
				) : (
					boardRecords.map((record) => {
						const sdRecord = record[FULL_RECORD_DATA_FIELD_ID];

						if (!sdRecord) {
							return null;
						}

						const getRecordLink = getCurrentPathWithRecordIdParam;
						const recordLink = getRecordLink(sdRecord._id);

						const isSelected = inBulkEditMode && selectedRecords.some((record) => record._id === sdRecord._id);

						return (
							<RecordCard
								key={`card-${sdRecord._id}`}
								sdRecord={sdRecord}
								fieldInfoToDisplay={
									fieldsToDisplay ? getFieldInfoArrayForBoardRecordDetails(fieldsToDisplay, record, boardFieldMap) : []
								}
								mediaFieldInfo={
									mediaFieldId ? getFieldInfoForBoardRecordDetails(mediaFieldId, record, boardFieldMap) : undefined
								}
								getRecordLink={getRecordLink}
								getCustomContextMenuItems={() => ({ primaryItems: generatePreviewLinkMenuItem(recordLink) })}
								vertical
								associationsSummary={recordAssociationsSummaryMap[sdRecord._id]}
								selected={isSelected}
								onToggleSelect={inBulkEditMode ? () => toggleRecordSelection(sdRecord) : undefined}
							/>
						);
					})
				)}
			</div>
			{/* Key used to update the intersection observer and trigger infinite scroll request
			if the bottom of the container is still in view after records have been loaded (Mainly affects larger displays) */}
			<div key={boardRecords.length} ref={containerBottomRef} />
			{footer}
		</div>
	);
}
