import { useCallback, useEffect, useState } from "react";

import { FilePreview, getFileTypeDetails } from "../../../../files";
import { PdfPreview } from "../../../../files/components/FilePreview/PdfPreview/PdfPreview";
import { FilePresentationDetails, useVideoCallLogicContext } from "../../VideoCallProvider";
import { SDFile } from "@salesdesk/salesdesk-schemas";

interface PresentedFileViewProps {
	presentedFileDetails: FilePresentationDetails;
	isLocal?: boolean;
}

export function PresentedFileView({ presentedFileDetails, isLocal }: PresentedFileViewProps) {
	const { file, settings } = presentedFileDetails;

	const { updateFileSettings } = useVideoCallLogicContext();

	// Current file stored in state to prevent file previewer to remount when
	// the page number changes but the SalesDesk file doesn't
	const [currentFile, setCurrentFile] = useState<SDFile>(file);
	const [currentPage, setCurrentPage] = useState(settings?.pageNumber || 1);

	const fileDetails = getFileTypeDetails(currentFile);

	const updateCurrentPage = useCallback(
		(newPageNumber: number) => {
			if (isLocal) {
				setCurrentPage(newPageNumber);
			}
		},
		[isLocal]
	);

	useEffect(() => {
		setCurrentFile((currentFile) => {
			if (JSON.stringify(file) === JSON.stringify(currentFile)) {
				return currentFile;
			}

			return file;
		});
	}, [file]);

	useEffect(() => {
		setCurrentPage(settings?.pageNumber || 1);
	}, [settings]);

	useEffect(() => {
		if (isLocal && updateFileSettings) {
			updateFileSettings({ pageNumber: currentPage });
		}
	}, [currentPage, isLocal, updateFileSettings]);

	return (
		<div className="relative h-full w-full">
			{fileDetails.type === "pdf" ? (
				<PdfPreview
					file={currentFile}
					variant="expanded"
					currentPage={currentPage}
					disabled={!isLocal}
					onPageChange={updateCurrentPage}
				/>
			) : (
				<FilePreview file={presentedFileDetails.file} variant="expanded" />
			)}
		</div>
	);
}
