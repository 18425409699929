import { createContext, useContext } from "react";
import { SDEvent } from "@salesdesk/salesdesk-schemas";
import { EventTarget } from "../types";

export const EventContext = createContext<{ event?: SDEvent; target: EventTarget }>({ target: "ACTIVITY" });

export function useEventContext() {
	const { event, target } = useContext(EventContext);
	if (!event) {
		throw new Error("EventContext is not set");
	}
	return { event, target };
}
