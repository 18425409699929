import { useRef, useEffect, useCallback } from "react";

function isClickInsidePopover(event: MouseEvent) {
	const popovers = Array.from(document.querySelectorAll("[data-floating-ui-portal]"));

	return popovers.some((popover) => popover.contains(event.target as Node));
}

function isClickInsideExcludedElement(event: MouseEvent, excludedElementClass: string) {
	return (event.target as HTMLElement).closest(`.${excludedElementClass} *`);
}

export function useOutsideClick<T extends HTMLElement = HTMLElement>(
	callback: (event?: MouseEvent) => void,
	excludedElementClass?: string
) {
	const ref = useRef<T>(null);

	const handleClick = useCallback(
		(event: MouseEvent) => {
			if (!ref.current || !event.target) return;

			const isClickOutsideContainer = ref.current && !ref.current.contains(event.target as Node);
			const isInsidePopoverClick = isClickInsidePopover(event);
			const isInsideExcludedElement = excludedElementClass
				? isClickInsideExcludedElement(event, excludedElementClass)
				: false;

			/* 
				isTrusted - property that distinguish a user-initiated click and a programmatically triggered click
				we want to do outside click handling only for user clicks
			*/
			if (!isInsidePopoverClick && !isInsideExcludedElement && isClickOutsideContainer && event.isTrusted) {
				callback(event);
			}
		},
		[callback, excludedElementClass]
	);

	useEffect(() => {
		document.addEventListener("click", handleClick, true);

		return () => {
			document.removeEventListener("click", handleClick, true);
		};
	}, [handleClick]);

	return ref;
}
