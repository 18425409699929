import { forwardRef, useCallback, useMemo } from "react";

import { Button, ButtonAsButton, Tooltip } from "@salesdesk/daisy-ui";
import { SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectByIds } from "../../../../../../hooks/useGetObjectByIds";
import { MenuItem, PopoverMenu } from "../../../../../menu";
import { useRecordCreateAction } from "../../hooks";
import { useCreateRecordDialogContext } from "../../hooks/useCreateRecordDialogContext";

import { RecordCreateDialogProps } from "../../types";

interface RecordCreateButton extends Omit<ButtonAsButton, "onClick" | "disabled"> {
	sdObject: SDObject;
	tooltipText?: string;
	initialValueMap?: RecordCreateDialogProps["initialValueMap"];
	onRecordCreated?: RecordCreateDialogProps["onRecordCreated"];
}

export const RecordCreateButton = forwardRef<HTMLButtonElement, RecordCreateButton>(
	({ sdObject, tooltipText, children, initialValueMap, onRecordCreated, ...buttonProps }, ref) => {
		const { openModal } = useCreateRecordDialogContext();

		const defaultAction = useCallback(
			(sdObject: SDObject) => {
				openModal({ sdObject, initialValueMap, onRecordCreated });
			},
			[openModal, initialValueMap, onRecordCreated]
		);

		const { recordCreateActionFn, actionIsActive, actionIsLoading } = useRecordCreateAction(defaultAction);

		const subTypeObjects = useGetObjectByIds(sdObject._subtypes);
		const menuItems: MenuItem[] = useMemo(
			() =>
				subTypeObjects
					.map(
						(subTypeObject) =>
							({
								icon: subTypeObject._icon,
								text: subTypeObject._displayName,
								onClick: () => recordCreateActionFn(subTypeObject),
								type: "button",
							}) satisfies MenuItem
					)
					.sort((a, b) => a.text.localeCompare(b.text)),
			[recordCreateActionFn, subTypeObjects]
		);

		const tooltipTextToDisplay = actionIsActive ? "" : tooltipText;
		const buttonPropsToUse = { ...buttonProps, isLoading: actionIsLoading };

		if (menuItems.length) {
			return (
				<PopoverMenu tooltipText={tooltipTextToDisplay} menuContents={menuItems} placement="bottom-start">
					<Button {...buttonPropsToUse} ref={ref}>
						{children}
					</Button>
				</PopoverMenu>
			);
		}

		return (
			<Tooltip text={tooltipTextToDisplay}>
				<Button
					{...buttonPropsToUse}
					ref={ref}
					disabled={!sdObject._supportsUserCreation}
					onClick={() => recordCreateActionFn(sdObject)}
				>
					{children}
				</Button>
			</Tooltip>
		);
	}
);
