import React, { PropsWithChildren, useEffect, useId, useMemo, useState } from "react";
import type { Placement } from "@floating-ui/react";
import {
	FloatingPortal,
	autoUpdate,
	flip,
	offset,
	shift,
	useDelayGroup,
	useDelayGroupContext,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useMergeRefs,
	useRole,
} from "@floating-ui/react";
import { Transition } from "@headlessui/react";
import clsx from "clsx";

import { tw } from "../../../utils";
import { isTextTruncated } from "../utils";
import { TooltipContainer } from "./TooltipContainer";

type TooltipProps = {
	placement?: Placement;
	text?: string;
	ignoreDisabled?: boolean;
	showOnTruncated?: boolean;
	noWrap?: boolean;
	preventFlip?: boolean;
	floatingPortal?: boolean;
};

export function Tooltip({
	children,
	placement,
	text,
	ignoreDisabled,
	showOnTruncated,
	noWrap = false,
	preventFlip = false,
	floatingPortal = false,
}: PropsWithChildren<TooltipProps>) {
	const [isOpen, setIsOpen] = useState(false);

	const middleware = [offset(8)];

	if (!preventFlip) {
		middleware.push(flip());
		middleware.push(shift({ padding: 8 }));
	}

	const { x, y, strategy, refs, context, update } = useFloating({
		strategy: "fixed",
		placement,
		open: isOpen,
		onOpenChange: (open) => {
			if (showOnTruncated && !isTextTruncated(refs.reference.current as HTMLElement)) return;

			setIsOpen(open);
			if (open) {
				update();
			}
		},
		whileElementsMounted: autoUpdate,
		middleware,
	});

	const { delay } = useDelayGroupContext();
	const id = useId();
	const hover = useHover(context, { move: false, delay });
	const focus = useFocus(context);
	const dismiss = useDismiss(context, { referencePress: true });
	const role = useRole(context, { role: "tooltip" });
	useDelayGroup(context, { id });

	useEffect(() => {
		update();
	}, [text, update]);

	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);
	const ref = useMergeRefs([refs.setReference, (children as any).ref]);

	const tooltip = useMemo(() => {
		if (!isOpen || x == null || !text) {
			return null;
		}

		let tooltipElement = (
			<Transition
				show={true}
				appear={true}
				ref={refs.setFloating}
				className={clsx("z-[150]")}
				enterFrom={tw`opacity-0`}
				enterTo={tw`opacity-100`}
				leaveFrom={tw`opacity-100`}
				leaveTo={tw`opacity-0`}
				style={{
					position: strategy,
					top: y ?? 0,
					left: x ?? 0,
				}}
				{...getFloatingProps()}
			>
				<TooltipContainer noWrap={noWrap} showOnTruncated={showOnTruncated} text={text} />
			</Transition>
		);

		if (floatingPortal) {
			tooltipElement = <FloatingPortal>{tooltipElement}</FloatingPortal>;
		}

		return tooltipElement;
	}, [isOpen, x, refs.setFloating, strategy, y, getFloatingProps, noWrap, showOnTruncated, text, floatingPortal]);

	if (!text) {
		return children;
	}

	if (React.isValidElement(children)) {
		const props = getReferenceProps({
			ref,
			...children.props,
			"data-state": isOpen ? "open" : "closed",
		});

		return (
			<>
				{React.cloneElement(children, props)}
				{children.props.disabled && !ignoreDisabled ? null : tooltip}
			</>
		);
	}

	throw new Error("Tooltip expects a valid element as a child");
}
