import { SDObject } from "../../object";
import { SDRecord } from "../../records";
import { UI_PATHS } from "./UI_PATHS";
import { buildQueryString } from "@salesdesk/salesdesk-utils";

// TODO: share logic for generating record URLs with the frontend (https://salesdesk101.atlassian.net/browse/SAL-2980)
export const URI_PATHS = {
	STACK_ROOT: getStackRootUri,
	APP_ROOT: getAppRootUri,
	RECORD: getRecordUri,
	MEETING: getMeetingUri,
	MEETING_RECORDING: getMeetingRecordingUri,
	MEETING_MINUTES: getMeetingMinutesUri,
	TASK: getTaskUri,
	ISSUE: getIssueUri,
	INVITATION: getInvitationUri,
};

function getStackRootUri(stackName: string) {
	return `https://${stackName}.salesdesk.app`;
}

function getAppRootUri(stackName: string, tenantId: string) {
	return `${getStackRootUri(stackName)}/app/${tenantId}`;
}

function getRecordUri(
	stackName: string,
	tenantId: string,
	workspaceId: number | undefined,
	sdObject: SDObject,
	sdRecord: SDRecord,
	isSalesDeskUser: boolean
) {
	return `${URI_PATHS.APP_ROOT(stackName, tenantId)}${UI_PATHS.EVENT_RECORD(workspaceId, sdObject, sdRecord, isSalesDeskUser)}`;
}

function getMeetingUri(stackName: string, tenantId: string, meetingRecord: SDRecord) {
	return `${URI_PATHS.APP_ROOT(stackName, tenantId)}${UI_PATHS.MEETING(meetingRecord)}`;
}

function getMeetingRecordingUri(
	stackName: string,
	tenantId: string,
	recordindRecordId: number,
	workspaceId: number | undefined | null
) {
	return workspaceId == null
		? `${getAppRootUri(stackName, tenantId)}/objects/assets/records/${recordindRecordId}`
		: `${getAppRootUri(stackName, tenantId)}/workspaces/${workspaceId}/library/assets/records/${recordindRecordId}`;
}

function getMeetingMinutesUri(
	stackName: string,
	tenantId: string,
	minutesRecordId: number,
	workspaceId: number | undefined | null
) {
	return workspaceId == null
		? `${getAppRootUri(stackName, tenantId)}/objects/docs/records/${minutesRecordId}`
		: `${getAppRootUri(stackName, tenantId)}/workspaces/${workspaceId}/library/docs/records/${minutesRecordId}`;
}

function getTaskUri(stackName: string, tenantId: string, taskRecordId: number, workspaceId: number | undefined | null) {
	return workspaceId == null
		? `${getAppRootUri(stackName, tenantId)}/objects/tasks/records/${taskRecordId}`
		: `${getAppRootUri(stackName, tenantId)}/workspaces/${workspaceId}/objects/tasks/records?recordId=${taskRecordId}`;
}

function getIssueUri(
	stackName: string,
	tenantId: string,
	issueRecordId: number,
	workspaceId: number | undefined | null
) {
	return workspaceId == null
		? `${getAppRootUri(stackName, tenantId)}/objects/issues/records/${issueRecordId}`
		: `${getAppRootUri(stackName, tenantId)}/workspaces/${workspaceId}/objects/issues/records?recordId=${issueRecordId}`;
}

function getInvitationUri(
	stackName: string,
	initialPassword: string | undefined,
	userEmailAddress: string,
	recipientFirstName: string
) {
	if (initialPassword != null) {
		const inviteQuerystring = buildQueryString({
			user: userEmailAddress,
			user_first_name: recipientFirstName,
			invite: initialPassword,
		});
		return `${getStackRootUri(stackName)}/invitation?${inviteQuerystring}`;
	} else {
		return `${getStackRootUri(stackName)}/start`;
	}
}
