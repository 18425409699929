import { ICONS } from "@salesdesk/salesdesk-ui";
import { MenuContents, PopoverMenu } from "../../../menu";
import { Button } from "@salesdesk/daisy-ui";

interface BookmarkNavPopoverItemProps {
	onEditClick: () => void;
	onDeleteClick: () => void;
}

export function BookmarkPopoverItemMenu({ onEditClick, onDeleteClick }: BookmarkNavPopoverItemProps) {
	const menuContents: MenuContents = [
		{
			type: "button",
			icon: ICONS.simplePencil,
			text: "Edit bookmark",
			onClick: onEditClick,
		},
		{
			type: "button",
			icon: ICONS.trash,
			text: "Delete bookmark",
			variant: "destructive",
			onClick: onDeleteClick,
		},
	];
	return (
		<PopoverMenu menuContents={menuContents} placement="bottom-start">
			<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
		</PopoverMenu>
	);
}
