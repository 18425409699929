import { forwardRef, useCallback } from "react";

import { BooleanInputProps } from "../../types";
import { Checkmark } from "../../../../components/Checkmark/Checkmark";

interface CheckboxProps extends BooleanInputProps {
	isIndeterminate?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	({ id, value, onChange, onBlur, disabled, ariaAttributes, isIndeterminate }, ref) => {
		const toggleCheckbox = useCallback(() => {
			onChange(!value);
		}, [value, onChange]);

		return (
			<label className="relative inline-flex items-center">
				<input
					id={id}
					type="checkbox"
					className="peer absolute top-0 h-0 w-0 opacity-0"
					ref={ref}
					checked={value}
					onChange={toggleCheckbox}
					onBlur={onBlur}
					disabled={disabled}
					{...ariaAttributes}
				/>
				<Checkmark value={value} isIndeterminate={isIndeterminate} disabled={disabled} />
			</label>
		);
	}
);
