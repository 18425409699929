import { forwardRef } from "react";

import { ButtonSize, ButtonVariant } from "@salesdesk/daisy-ui";
import { SingleOptionSelectProps } from "../../types";
import { ButtonSelectButton } from "./ButtonSelectButton";
import { BaseSelect } from "../BaseSelect";

interface ButtonSelectProps extends SingleOptionSelectProps {
	buttonVariant?: ButtonVariant;
	size?: ButtonSize;
	endIcon?: string;
	startIcon?: string;
	iconOnly?: boolean;
}

export const ButtonSelect = forwardRef<HTMLElement, ButtonSelectProps>(
	({ buttonVariant, endIcon, startIcon, iconOnly, size, ...props }, ref) => {
		return (
			<BaseSelect
				{...props}
				ref={ref}
				selectButton={
					<ButtonSelectButton
						buttonVariant={buttonVariant}
						endIcon={endIcon}
						startIcon={startIcon}
						iconOnly={iconOnly}
						size={size}
					/>
				}
			/>
		);
	}
);
