import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../utils";
import { mCurrenciesBackingStore } from "../backing_store";
import { mSingleOptionFieldDef } from "./single_option_field_def";

export class mCurrencyFieldDef extends mSingleOptionFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name];
	static override defaultComponentType: FieldComponentName = mCurrencyFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id, mCurrenciesBackingStore.TYPE);
		this._componentType = mCurrencyFieldDef.defaultComponentType;
		this._formatDescription = "Search currencies";
		this._icon = FIELD_TYPES.CURRENCY.icon;
		this._supportsDefaultValue = true;
	}

	override get type(): string {
		return FIELD_TYPES.CURRENCY.name;
	}

	override get supportedComponentTypes() {
		return mCurrencyFieldDef.supportedComponentTypes;
	}

	override supportsMaxLength() {
		return false;
	}
}
