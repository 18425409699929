import { asConst } from "json-schema-to-ts";
import { notClausesSchema } from "./notClausesSchema";

export const notClauseProperty = "not";

export const notClauseSchema = asConst({
	title: "Not",
	description: "Will 'not' the contained clause",
	type: "object",
	additionalProperties: false,
	required: [notClauseProperty],
	properties: {
		not: notClausesSchema,
	},
	nullable: false,
});
