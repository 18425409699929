import clsx from "clsx";
import { ComponentProps, ReactNode } from "react";

import { Field, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { AccessibleButtonDiv } from "../../../../../components/AccessibleButtonDiv";
import { useInlineEditField } from "../hooks/useInlineEditField";
import { InlineEditRecordField } from "./InlineEditRecordField";

interface InlineEditRecordFieldControllerProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
	field: Field;
	wrapperClasses?: string;
	includePadding?: boolean;
	// Render function
	children: (args: {
		inlineRecordFieldProps: ComponentProps<typeof InlineEditRecordField>;
		editMode: boolean;
		canEditField: boolean;
	}) => ReactNode;
}

export function InlineEditRecordFieldController({
	sdObject,
	sdRecord,
	field,
	wrapperClasses,
	includePadding = true,
	children,
}: InlineEditRecordFieldControllerProps) {
	const { inlineRecordFieldProps, updateEditMode, error } = useInlineEditField(sdObject, sdRecord, field);
	const { editMode, canEditField } = inlineRecordFieldProps;

	return (
		<div className="flex w-full flex-col gap-1 overflow-hidden">
			<AccessibleButtonDiv
				className={clsx(
					wrapperClasses,
					"h-fit w-full border-transparent",
					!editMode && includePadding && "px-4 py-2",
					!editMode && canEditField && "hover:bg-c_bg_03 cursor-pointer rounded-sm border transition-colors"
				)}
				onClick={() => {
					if (!editMode && canEditField) {
						updateEditMode(true);
					}
				}}
				disabled={editMode || !canEditField}
			>
				{children({ inlineRecordFieldProps, editMode, canEditField })}
			</AccessibleButtonDiv>
			{editMode && error ? <span className="text-c_danger_02 text-body">{error}</span> : null}
		</div>
	);
}
