import { useMemo } from "react";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

import { Icon } from "@salesdesk/daisy-ui";

import { getFirstName } from "../../../../../../../users";
import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";

interface LastChannelMessageTemplateProps {
	message?: ChannelMessage | null;
	icon?: string;
	messageText: string;
	isLoading?: boolean;
}

export function ChannelMessagePreviewTemplate({
	messageText,
	icon,
	message,
	isLoading,
}: LastChannelMessageTemplateProps) {
	const senderName = useMemo(() => (message ? getFirstName(message.Sender?.Name) : "Unknown"), [message]);

	if (isLoading || message === undefined) return <Skeleton className="h-4 w-full" />;

	return (
		<div className="flex items-center gap-1">
			{message === null ? null : `${senderName}:`} {icon ? <Icon size="sm" icon={icon} /> : null}
			<span className="truncate">{messageText}</span>
		</div>
	);
}
