import { asConst } from "json-schema-to-ts";
import { idSchema, updatableSchema } from "../components";
import { importMappingEntrySchema } from "./importMappingEntrySchema";

export const importMappingSchema = asConst({
	title: "ImportMappingSchema",
	description: "The list of mappings for an import",
	type: "object",
	additionalProperties: false,
	required: ["importId", "mappingEntries", ...updatableSchema.required],
	properties: {
		importId: idSchema,
		mappingEntries: {
			type: "array",
			items: importMappingEntrySchema,
		},
		...updatableSchema.properties,
	},
});
