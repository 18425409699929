import { forwardRef, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import { combineRefs } from "@salesdesk/daisy-ui";

import { debounce } from "../../../../../utils";
import { VideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { VideoControls } from "./VideoControls";
import { ExpandedVideoControls } from "./ExpandedVideoControls";
import { useFullscreenToggle } from "../../../../../hooks/ui/useFullscreenToggle";
import { FilePreviewVariant } from "../../../types";
import { VideoEvent } from "../types";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { videoIsCurrentlyPlaying } from "../utils";

interface VideoPlayerProps {
	videoSrc: string | undefined;
	posterSrc?: string;
	onError?: (event: SyntheticEvent<HTMLVideoElement, Event>) => string;
	onOpenPreviewClick?: () => void;
	updateVideoDuration?: (length: number) => void;
	variant?: FilePreviewVariant;
	onVideoEvent: (event: VideoEvent, timecode?: number) => void;
	shouldPause?: boolean;
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
	(
		{
			videoSrc,
			posterSrc,
			onError,
			onOpenPreviewClick,
			updateVideoDuration,
			variant = "md",
			shouldPause,
			onVideoEvent,
		},
		ref
	) => {
		const innerVideoRef = useRef<HTMLVideoElement>(null);

		const isLargerView = variant === "md" || variant === "expanded";

		const [isPlaying, setIsPlaying] = useState(false);

		const [isLoading, setIsLoading] = useState(true);
		const [errorMessage, setErrorMessage] = useState<string>();

		const [videoDuration, setVideoDuration] = useState(0);
		const [currentVideoTime, setCurrentTime] = useState(0);
		const [lastVolumeUsed, setLastVolumeUsed] = useState(1);
		const [volume, setVolume] = useState(!isLargerView ? 0 : 1); // TODO: store previous volume in local storage
		const [videoSpeed, setVideoSpeed] = useState(1);

		// Reset video if the src changes
		useEffect(() => {
			setIsLoading(true);
			setIsPlaying(false);
			setErrorMessage("");
			setVideoDuration(0);
			setCurrentTime(0);
		}, [videoSrc, setIsPlaying]);

		useEffect(() => {
			if (innerVideoRef.current && updateVideoDuration && !isNaN(innerVideoRef.current.duration)) {
				updateVideoDuration(innerVideoRef.current.duration);
			}
		}, [updateVideoDuration, innerVideoRef.current?.duration]);

		const onVideoLoaded = () => {
			setIsLoading(false);

			const videoEl = innerVideoRef.current;
			if (!videoEl) {
				return;
			}

			/*
          Fixes a chrome bug where the video initialises with a duration of
          Infinity. Updating the current time of the video to a large value
          causes the duration to update to the correct value.

          Source: https://stackoverflow.com/a/52375280
      */
			if (videoEl.duration === Infinity) {
				const resetVideoTime = () => {
					videoEl.currentTime = 0;
					setVideoDuration(videoEl.duration);
					videoEl.removeEventListener("timeupdate", resetVideoTime);
				};

				videoEl.currentTime = 50_000_000;
				videoEl.addEventListener("timeupdate", resetVideoTime);
				return;
			}

			videoEl.volume = volume;
			setVideoDuration(videoEl.duration);
		};

		const updateIsPlaying = useCallback(
			(newIsPlaying?: boolean) => {
				if (!innerVideoRef.current) {
					return;
				}

				const isCurrentlyPlaying = videoIsCurrentlyPlaying(innerVideoRef.current);
				if (newIsPlaying) {
					if (!isCurrentlyPlaying) {
						onVideoEvent(SdEventType.FILE_PLAYED, innerVideoRef.current.currentTime);
					}
					innerVideoRef.current.play();
				} else {
					if (isCurrentlyPlaying) {
						onVideoEvent(SdEventType.FILE_PAUSED, innerVideoRef.current.currentTime);
					}
					innerVideoRef.current.pause();
				}
			},
			[onVideoEvent]
		);

		useEffect(() => {
			if (shouldPause) {
				updateIsPlaying(false);
			}
		}, [shouldPause, updateIsPlaying]);

		const seekToVideoTime = useCallback(
			(newVideoTime: number, noEvent?: boolean) => {
				if (!innerVideoRef.current) {
					return;
				}

				innerVideoRef.current.currentTime = Math.max(Math.min(newVideoTime, videoDuration), 0);
				if (!noEvent) {
					onVideoEvent(SdEventType.FILE_SEEKED, innerVideoRef.current.currentTime);
				}
			},
			[onVideoEvent, videoDuration]
		);

		const updateVideoSpeed = useCallback((newVideoSpeed: number) => {
			if (!innerVideoRef.current) {
				return;
			}
			innerVideoRef.current.playbackRate = newVideoSpeed;
			setVideoSpeed(newVideoSpeed);
		}, []);

		const updateLastUsedVolume = useMemo(
			() =>
				debounce((value: number) => {
					if (value > 0) {
						setLastVolumeUsed(value);
					}
				}, 1000),
			[]
		);

		const updateVolume = useCallback(
			(value: number) => {
				if (!innerVideoRef.current) {
					return;
				}
				const newVolume = Math.max(Math.min(value, 1), 0);
				innerVideoRef.current.volume = newVolume;
				setVolume(newVolume);
				updateLastUsedVolume(newVolume);
			},
			[updateLastUsedVolume]
		);

		const { elementRef: videoWrapperRef, isFullscreen, toggleFullscreen } = useFullscreenToggle();
		const toggleFullScreen = useCallback(() => {
			if (!innerVideoRef.current) {
				return;
			}
			toggleFullscreen();
		}, [toggleFullscreen]);

		const toggleMute = useCallback(() => {
			updateVolume(volume === 0 ? lastVolumeUsed : 0);
		}, [updateVolume, volume, lastVolumeUsed]);

		return (
			<VideoPlayerContext.Provider
				value={{
					variant: isFullscreen ? "expanded" : variant,
					isPlaying,
					isLoading,
					errorMessage,
					currentVideoTime,
					videoDuration,
					updateIsPlaying,
					seekToVideoTime,
					onOpenPreviewClick,
					videoSpeed,
					updateVideoSpeed,
					toggleMute,
					volume,
					updateVolume,
					toggleFullScreen,
					isFullscreen,
					innerVideoRef,
				}}
			>
				<div
					className={clsx(
						"rounded-t-card relative mx-auto h-full",
						isLargerView ? "bg-c_brand_dark w-full" : "bg-c_bg_04"
					)}
				>
					<div
						ref={videoWrapperRef}
						className="text-c_icon_inverted text-h2 mx-auto flex h-full items-center justify-center"
					>
						<video
							ref={combineRefs([ref, innerVideoRef])}
							className={clsx(!isLargerView ? "object-cover" : "h-full", "pointer-events-none")}
							preload="metadata"
							src={videoSrc}
							poster={posterSrc}
							onTimeUpdate={() => {
								if (!innerVideoRef.current) {
									return;
								}
								setCurrentTime(innerVideoRef.current.currentTime);
							}}
							onPlay={() => setIsPlaying(true)}
							onPause={() => setIsPlaying(false)}
							onEnded={() => onVideoEvent(SdEventType.FILE_ENDED)}
							onError={(event) => {
								const errorMessage = onError ? onError(event) : "Preview not supported.";

								if (errorMessage) {
									setIsLoading(false);
								}

								setErrorMessage(errorMessage);
							}}
							onWaiting={() => setIsLoading(true)}
							onCanPlay={onVideoLoaded}
						/>
						{isLargerView ? <ExpandedVideoControls /> : <VideoControls />}
					</div>
				</div>
			</VideoPlayerContext.Provider>
		);
	}
);
