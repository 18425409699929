import { useState, useMemo, useEffect } from "react";

import { UploadProgressStatus } from "../../../../fields";

export function useUploadProgressPerField(updateUploadProgressStatus?: (status: UploadProgressStatus) => void) {
	const [uploadProgressStatusPerField, setUploadProgressStatusPerField] = useState<
		Record<string, UploadProgressStatus>
	>({});

	const uploadInProgress = useMemo(() => {
		return Object.values(uploadProgressStatusPerField).some((status) => status === "in_progress");
	}, [uploadProgressStatusPerField]);

	useEffect(() => {
		if (updateUploadProgressStatus) {
			updateUploadProgressStatus(uploadInProgress ? "in_progress" : "idle");
		}
	}, [uploadInProgress, updateUploadProgressStatus]);

	return { uploadInProgress, setUploadProgressStatusPerField };
}
