export function Branding() {
	return (
		<div className="bg-c_bg_disabled_03 login-sm:justify-center hidden max-h-full flex-col items-center overflow-hidden md:flex">
			<div className="login-sm:hidden -mb-3 flex h-full max-h-[190px] min-h-[90px] shrink justify-center"></div>
			<div className="flex max-w-[75%] flex-col gap-4">
				<img
					className="max-h-[50vh] min-h-[250px] min-w-[300px] object-contain"
					src="/static/images/graphics/invite-graphic.svg"
					alt="SalesDesk showcase"
				/>
				<div className="flex flex-col gap-6 text-center">
					<p className="lg:text-h2 text-h3 text-c_text_primary">Collaborate on SalesDesk!</p>
					<div className="gap lg:text-body text-body-sm text-c_text_secondary flex flex-col gap-4">
						<p>
							Dive into SalesDesk where you can{" "}
							<span className="text-label">securely share documents, complete tasks, join video huddles</span> and much
							more.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
