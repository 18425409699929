import { Fragment, ReactNode } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { SDRecord, SearchHighlight } from "@salesdesk/salesdesk-schemas";

import { tw } from "../../../../../utils/tailwind-helpers";
import { RecordSearchResult, RecordSearchResultSkeleton } from "./RecordSearchResult";
import clsx from "clsx";

interface GlobalSearchbarPopoverInterface {
	searchResults: SDRecord[];
	searchText: string;
	searchHighlightsMap: Record<number, SearchHighlight[]>;
	onRecordSelect: (urlPath: string, newSearchbarText?: string) => void;
	isLoading?: boolean;
	variant?: "large" | "collapsible";
}

export function GlobalSearchbarPopover({
	searchResults,
	searchText,
	onRecordSelect,
	searchHighlightsMap,
	isLoading,
	variant = "large",
}: GlobalSearchbarPopoverInterface) {
	return (
		<Transition
			as={Fragment}
			enter={tw`transition-all ease-out ${variant === "collapsible" ? "delay-75" : ""}`}
			enterFrom={tw`-translate-y-2 max-h-6 ${variant === "collapsible" ? "opacity-0" : ""}`}
			enterTo={tw`max-h-[377px]`}
			leave={variant === "collapsible" ? tw`opacity-0` : tw`duration-75 transition-all ease-in`}
			leaveFrom={tw`max-h-[377px]`}
			leaveTo={tw`-translate-y-2 max-h-6`}
			entered={tw`group/search-popover is-expanded`}
		>
			<Combobox.Options
				className={clsx(
					" bg-c_bg_01 border-c_action_01 text-c_text_primary -mt-[10px] flex overflow-hidden rounded-b-xl border border-t-0 pb-4",
					variant === "collapsible" ? "shadow-popover_bottom w-[316px]" : "shadow-popover mx-1 w-[460px]"
				)}
			>
				<div className="max-h-full w-full group-[.is-expanded]/search-popover:overflow-auto">
					{searchResults.length ? (
						searchResults.reduce((resultComponents, sdRecord) => {
							const highlights = searchHighlightsMap[sdRecord._id];

							const sharedProps = {
								record: sdRecord,
								searchText,
								onRecordSelect,
								trimThreshold: variant === "collapsible" ? 10 : undefined,
							};

							if (!highlights?.length) {
								resultComponents.push(<RecordSearchResult key={sdRecord._id} {...sharedProps} />);
								return resultComponents;
							}

							// Include each of the highlights for the record in the search results
							highlights.forEach((highlight) => {
								highlight.fragments.forEach((fragment, index) => {
									resultComponents.push(
										<RecordSearchResult
											key={`${sdRecord._id}-${highlight.matchType}-${fragment}-${index}`}
											{...sharedProps}
											highlight={{ matchType: highlight.matchType, fragment }}
										/>
									);
								});
							});

							return resultComponents;
						}, [] as ReactNode[])
					) : isLoading ? (
						[...Array(5)].map((_, index) => <RecordSearchResultSkeleton key={index} />)
					) : (
						<div className="text-c_text_placeholder px-5 py-2">Sorry, there are no results that match your search</div>
					)}
				</div>
			</Combobox.Options>
		</Transition>
	);
}
