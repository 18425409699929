import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { useGetObjectById } from "../../../hooks";
import { useMemo } from "react";
import { getSDObjectFieldByName, getSDRecordFieldValue, SDRecord } from "@salesdesk/salesdesk-schemas";

export function useIsInProgressMeeting(sdRecord: SDRecord | undefined) {
	const isMeeting = sdRecord?._objectDefId === mInteractionMeetingDef.ID;
	const { sdObject } = useGetObjectById(isMeeting ? sdRecord._objectDefId : undefined);

	return useMemo(() => {
		if (!isMeeting || !sdObject) {
			return false;
		}

		const statusField = getSDObjectFieldByName(sdObject, mInteractionMeetingDef.STATUS_FIELD_NAME);
		if (!statusField) {
			return false;
		}

		const statusValue = getSDRecordFieldValue(sdRecord, statusField._id);
		const inProgressId = statusField._optionValues?.find(
			(option) => option.name === mInteractionMeetingDef.STATUSES.IN_PROGRESS
		)?.id;

		if (inProgressId === undefined) {
			return false;
		}

		return statusValue?._value === inProgressId;
	}, [isMeeting, sdObject, sdRecord]);
}
