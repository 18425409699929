import React, { useState, useRef, useContext, PropsWithChildren, useEffect } from "react";
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useDismiss,
	useRole,
	useInteractions,
	Placement,
	useHover,
	arrow,
	FloatingArrowProps,
	safePolygon,
	useClientPoint,
	useClick,
	useFocus,
} from "@floating-ui/react";
import { Point } from "../../utils/types";

type FloatingArrowOptions = Omit<FloatingArrowProps, "context">;

interface CorePopoverOptions {
	isHoverPopover?: boolean;
	isInputPopover?: boolean;
	placement?: Placement;
	fallbackPlacements?: Placement[];
	modal?: boolean;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	arrowOptions?: FloatingArrowOptions;
	floatingStrategy?: "fixed" | "absolute";
	disableFlip?: boolean;
	clientPointAxis?: "both" | "x" | "y";
	clientPointDetails?: Point;
	enablePopoverHover?: boolean;
	useFloatingPortal?: boolean;
	keepPopoverMounted?: boolean;
	hideWhenClosedButMounted?: boolean;
	dismissOnAncestorScroll?: boolean;
	hoverDelay?: number;
}

type HoverPopoverOptions = Omit<CorePopoverOptions, "isHoverPopover" | "open" | "isInputPopover">;

export function HoverPopover({ children, ...HoverPopoverOptions }: PropsWithChildren<HoverPopoverOptions>) {
	return (
		<CorePopover isHoverPopover={true} {...HoverPopoverOptions}>
			{children}
		</CorePopover>
	);
}

type PopoverOptions = Omit<CorePopoverOptions, "isHoverPopover" | "clientPointAxis" | "enablePopoverHover">;

export function Popover({ children, ...PopoverOptions }: PropsWithChildren<PopoverOptions>) {
	return (
		<CorePopover isHoverPopover={false} {...PopoverOptions}>
			{children}
		</CorePopover>
	);
}

export function CorePopover({
	children,
	modal = false,
	...popoverOptions
}: {
	children: React.ReactNode;
} & CorePopoverOptions) {
	const popover = useCorePopover({ modal, ...popoverOptions });
	return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
}

export function useCorePopover({
	isHoverPopover = true,
	isInputPopover = false, // For popovers on text inputs, to prevent space key from being swallowed by the useClick handler
	placement = "bottom",
	fallbackPlacements,
	modal,
	open: controlledOpen,
	onOpenChange,
	arrowOptions = { fill: "transparent" },
	floatingStrategy = "fixed",
	disableFlip = false,
	clientPointAxis,
	clientPointDetails,
	enablePopoverHover = true,
	useFloatingPortal = true,
	keepPopoverMounted,
	hideWhenClosedButMounted = true,
	dismissOnAncestorScroll = true,
	hoverDelay = 0,
}: CorePopoverOptions = {}) {
	const isUncontrolledOpen = controlledOpen == null;

	const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = !isUncontrolledOpen && onOpenChange ? onOpenChange : setUncontrolledOpen;

	useEffect(() => {
		setOpen(open);
	}, [open, setOpen]);

	const arrowRef = useRef(null);

	const middleware = [offset(8), shift({ padding: 4 }), arrow({ element: arrowRef })];

	if (!disableFlip) {
		middleware.unshift(
			flip({
				fallbackAxisSideDirection: "end",
				padding: 5,
				fallbackPlacements,
			})
		);
	}

	const data = useFloating({
		placement,
		strategy: floatingStrategy,
		open,
		onOpenChange: (e) => {
			if (onOpenChange && isUncontrolledOpen) {
				onOpenChange(e);
			}

			setOpen(e);
		},
		whileElementsMounted: autoUpdate,
		middleware,
	});

	const context = data.context;

	const hover = useHover(context, {
		enabled: isHoverPopover,
		handleClose: enablePopoverHover ? safePolygon() : null,
		delay: { open: hoverDelay },
	});
	const click = useClick(context, {
		enabled: !isHoverPopover && !isInputPopover,
	});
	const focus = useFocus(context, {
		enabled: isInputPopover && !isHoverPopover,
	});
	const dismiss = useDismiss(context, { ancestorScroll: dismissOnAncestorScroll, referencePress: isHoverPopover });
	const role = useRole(context);
	const clientPoint = useClientPoint(context, {
		enabled: Boolean(clientPointAxis),
		axis: clientPointAxis,
		x: clientPointDetails?.x,
		y: clientPointDetails?.y,
	});

	const interactions = useInteractions([hover, focus, click, dismiss, role, clientPoint]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
			modal,
			arrowRef,
			arrowOptions,
			useFloatingPortal,
			keepPopoverMounted,
			hideWhenClosedButMounted,
		}),
		[
			open,
			setOpen,
			interactions,
			data,
			modal,
			arrowOptions,
			useFloatingPortal,
			keepPopoverMounted,
			hideWhenClosedButMounted,
		]
	);
}

type ContextType = ReturnType<typeof useCorePopover> | null;

const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverContext = () => {
	const context = useContext(PopoverContext);

	if (context == null) {
		throw new Error(
			"Popover components must be wrapped in <Popover /> or <PopoverHover />, e.g: <Popover> <PopoverTrigger /><PopoverContent /> </Popover>"
		);
	}

	return context;
};
