import { useParams } from "react-router-dom";

import { MeetingPage } from "./MeetingPage";
import { MeetingPageProvider } from "./MeetingPageProvider";

type Params = { meetingId: string };

export function MeetingPageContainer() {
	const { meetingId } = useParams<Params>() as Params;

	return (
		<MeetingPageProvider meetingId={parseInt(meetingId)}>
			<MeetingPage />
		</MeetingPageProvider>
	);
}
