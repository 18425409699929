import { PropsWithChildren, useCallback, useReducer, useState } from "react";
import { LocalVideoTrack } from "twilio-video";

import { initialSettings, settingsReducer } from "../settings";
import { useBackgroundSettings } from "../hooks";
import { useLocalAudioOutputDeviceId } from "../hooks/useLocalAudioOutputDeviceId";
import { useLocalTracks } from "../hooks/useLocalTracks";
import { useRestartAudioTrackOnDeviceChange } from "../hooks/useRestartAudioTrackOnDeviceChange";
import { VideoCallMediaContext } from "../hooks/useVideoCallMediaContext";

interface VideoCallMediaProviderProps {
	onError: ErrorCallback;
}

export function VideoCallMediaProvider({
	children,
	onError = () => {
		return;
	},
}: PropsWithChildren<VideoCallMediaProviderProps>) {
	const onErrorCallback: ErrorCallback = useCallback(
		(error) => {
			console.log(`ERROR: ${error.message}`, error);
			onError(error);
		},
		[onError]
	);

	const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

	const [isKrispEnabled, setIsKrispEnabled] = useState(false);
	const [isKrispInstalled, setIsKrispInstalled] = useState(false);

	const {
		localTracks,
		getLocalVideoTrack,
		getLocalAudioTrack,
		isAcquiringLocalVideoTrack,
		isAcquiringLocalAudioTrack,
		removeLocalAudioTrack,
		removeLocalVideoTrack,
	} = useLocalTracks(setIsKrispEnabled, setIsKrispInstalled);

	// Skips initialising the localAudioOutputDevice when there are no local tracks to prevent requesting user's microphone
	// before being in a video call or holding page. Note that this relies on the fact that a user cannot join a video call
	// without at least having a microphone.
	const [localAudioOutputDeviceId, setLocalAudioOutputDeviceId] = useLocalAudioOutputDeviceId(!localTracks.length);

	useRestartAudioTrackOnDeviceChange(localTracks);

	const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
	const videoTrack = localTracks.find((track) => !track.name.includes("screen") && track.kind === "video") as
		| LocalVideoTrack
		| undefined;

	const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack);

	return (
		<VideoCallMediaContext.Provider
			value={{
				localTracks,

				getLocalVideoTrack,
				getLocalAudioTrack,
				removeLocalVideoTrack,
				removeLocalAudioTrack,
				isAcquiringLocalVideoTrack,
				isAcquiringLocalAudioTrack,
				onError: onErrorCallback,

				isBackgroundSelectionOpen,
				setIsBackgroundSelectionOpen,
				backgroundSettings,
				setBackgroundSettings,

				localAudioOutputDeviceId,
				setLocalAudioOutputDeviceId,

				settings,
				dispatchSetting,

				isKrispEnabled,
				setIsKrispEnabled,
				isKrispInstalled,
				setIsKrispInstalled,
			}}
		>
			{children}
		</VideoCallMediaContext.Provider>
	);
}
