import { RefObject, useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { tw } from "@salesdesk/daisy-ui";
import { EventList } from "./EventList";
import { EventGraph } from "./EventGraph";
import { FilterEventType, TypeFilter, UserFilter } from "./";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "../../../components/Tabs";
import { EventTarget } from "../types";
import { useGetContextWorkspaceId, WorkspaceFilter } from "../../workspaces";

interface EventsProps {
	sdRecord?: SDRecord;
	externalScrollContainerRef?: RefObject<HTMLDivElement>;
	onUpdateCount?: (count: number) => void;
	showUsersActivity?: boolean;
	target?: EventTarget;
}

const EMPTY_USER_FILTER: number[] = [];

/**
 * externalScrollContainerRef is used in the right hand panel when the scroll container of
 * the events is the right hand panel itself as opposed to the component having a self contained
 * scroll container
 */
export function Events({
	sdRecord,
	externalScrollContainerRef,
	onUpdateCount,
	showUsersActivity = false,
	target = showUsersActivity ? "NOTIFICATION" : "ACTIVITY",
}: EventsProps) {
	const getUserFilter = useCallback(() => {
		return sdRecord && showUsersActivity ? [sdRecord._id] : EMPTY_USER_FILTER;
	}, [sdRecord, showUsersActivity]);

	const [typeFilter, setTypeFilter] = useState<FilterEventType>();
	const [userFilter, setUserFilter] = useState<number[]>(getUserFilter);
	const [workspaceFilter, setWorkspaceFilter] = useState<number>();

	useEffect(() => {
		setUserFilter(getUserFilter());
	}, [getUserFilter, sdRecord, showUsersActivity]);

	const hasExternalScroll = Boolean(externalScrollContainerRef);
	const panelClasses = tw`@2xl/events:!block w-full overflow-hidden`;

	const inWorkspaceContext = Boolean(useGetContextWorkspaceId());

	return (
		<div
			className={clsx(
				!hasExternalScroll && "h-full max-h-full overflow-hidden pl-0.5 pt-0.5",
				"@container/events flex h-full flex-col gap-4"
			)}
		>
			<Tabs>
				<div className="flex min-h-10 justify-between gap-2">
					<div className="flex gap-2">
						<TypeFilter value={typeFilter ?? "all"} onChange={setTypeFilter} />
						{!showUsersActivity ? (
							<UserFilter value={userFilter ?? EMPTY_USER_FILTER} onChange={setUserFilter} />
						) : null}
						{!inWorkspaceContext ? <WorkspaceFilter value={workspaceFilter} onChange={setWorkspaceFilter} /> : null}
					</div>
					<TabList className="@2xl/events:hidden" variant="icon">
						<Tab variant="icon">
							<Icon icon={ICONS.list} size="sm" />
						</Tab>
						<Tab variant="icon">
							<Icon icon={ICONS.reports} size="sm" />
						</Tab>
					</TabList>
				</div>
				<TabPanels className={clsx(!hasExternalScroll && "flex h-full max-h-full gap-5 overflow-hidden")}>
					<TabPanel className={panelClasses} unmount={false}>
						<EventList
							key={`${sdRecord?._id}-${userFilter}-${typeFilter}-${workspaceFilter}`} // fixes pagination bug when filters are changed from the graph tab
							sdRecord={sdRecord}
							externalScrollContainerRef={externalScrollContainerRef}
							userFilter={userFilter}
							typeFilter={typeFilter}
							workspaceFilter={workspaceFilter}
							onUpdateCount={onUpdateCount}
							showUsersActivity={showUsersActivity}
							target={target}
						/>
					</TabPanel>
					<TabPanel className={clsx(panelClasses, "max-w-[600px]")} unmount={false}>
						<EventGraph
							sdRecord={showUsersActivity ? undefined : sdRecord}
							userFilter={userFilter}
							typeFilter={typeFilter}
							workspaceFilter={workspaceFilter}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</div>
	);
}
