import { UserIdOrPlaceholderName } from "../types";

export interface Rect {
	top: number;
	right: number;
	bottom: number;
	left: number;
}

export function getBoundingRect(element: HTMLElement | undefined | null): Rect {
	if (!element) {
		return { top: 0, right: 0, bottom: 0, left: 0 };
	}
	const { top, right, bottom, left } = element.getBoundingClientRect();
	return { top, right, bottom, left };
}

export function isWithinRect(rect: Rect, x: number, y: number): boolean {
	return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
}

export function getKeyByUserIdOrPlaceholderName(
	keyToUserId: Record<string, UserIdOrPlaceholderName>,
	userId: UserIdOrPlaceholderName | undefined
): string | undefined {
	if (!userId) {
		return undefined;
	}
	return Object.entries(keyToUserId).find(([key, value]) => value === userId)?.[0];
}

export function keyToPlaceholderName(key: string) {
	// Special handling for initial recording assets created with a new tenant
	if (key.startsWith("placeholder")) {
		return key.split("_")[1];
	}
	const potentialSpeakerNumber = Number(key.split("_").pop());
	if (isNaN(potentialSpeakerNumber)) return "Speaker Unknown";

	return "Speaker " + (potentialSpeakerNumber + 1);
}
