import { TimeRange } from "@salesdesk/salesdesk-schemas";
import { TimeInput } from "./TimeInput";
import { mapLocalDateTimeToUtcTimestamp, mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";
import { add, sub } from "date-fns";

interface TimesProps {
	isTimeRange: boolean | undefined;
	value: TimeRange | number | null | undefined;
	onChange: (value: TimeRange | number) => void;
}

export function Times({ isTimeRange, value, onChange }: TimesProps) {
	if (value === undefined) {
		return null;
	}

	const start = typeof value !== "number" ? value?.start ?? null : value;
	const end = typeof value !== "number" ? value?.end ?? null : value;

	return (
		<div className="flex gap-6">
			<TimeInput
				label="Start"
				value={start}
				onChange={(newStart, fromOption) => {
					if (!isTimeRange) {
						onChange(newStart);
						return;
					}
					// Adjust end to be an hour after start time if end time is before start time
					if (fromOption && end && end < newStart) {
						const newStartDate = mapUtcTimeStampToLocalDateTime(newStart, false);
						const adjustedEnd = add(newStartDate, { hours: 1 });
						onChange({
							start: mapLocalDateTimeToUtcTimestamp(newStartDate, false),
							end: mapLocalDateTimeToUtcTimestamp(adjustedEnd, false),
						});
						return;
					}
					onChange({ start: newStart, end });
				}}
				disabled={!value}
			/>
			{isTimeRange ? (
				<TimeInput
					label="End"
					value={end}
					onChange={(newEnd, fromOption) => {
						// Adjust start to be an hour before end time if start time is after end time
						if (fromOption && start && start > newEnd) {
							const newEndDate = mapUtcTimeStampToLocalDateTime(newEnd, false);
							const adjustedStart = sub(newEndDate, { hours: 1 });
							onChange({
								start: mapLocalDateTimeToUtcTimestamp(adjustedStart, false),
								end: mapLocalDateTimeToUtcTimestamp(newEndDate, false),
							});
							return;
						}
						onChange({ start, end: newEnd });
					}}
					disabled={!value}
				/>
			) : null}
		</div>
	);
}
