import React from "react";
import clsx from "clsx";
import { Switch } from "@headlessui/react";

import { BooleanInputProps } from "../../types";

export const ToggleSwitch = React.forwardRef<HTMLButtonElement, BooleanInputProps>(
	({ id, value, onChange, onBlur, disabled, ariaAttributes }, ref) => {
		return (
			<Switch
				id={id}
				ref={ref}
				className={clsx(
					disabled ? "bg-c_bg_disabled_01 cursor-not-allowed" : value ? "bg-c_action_01" : "bg-c_action_02",
					"focus-visible:ring-c_action_focus relative inline-flex h-5 w-10 items-center rounded-full transition duration-300 focus-visible:ring focus-visible:ring-offset-1"
				)}
				checked={value}
				onChange={(value) => onChange(value)}
				disabled={disabled}
				onBlur={onBlur}
				{...ariaAttributes}
			>
				<span
					className={clsx(
						value ? "translate-x-[23px]" : "translate-x-[3px]",
						disabled ? "bg-c_bg_disabled_02" : "bg-c_bg_01",
						"inline-block h-3.5 w-3.5 transform rounded-full transition duration-200"
					)}
				/>
			</Switch>
		);
	}
);
