import { DatePickerStateProvider } from "@rehookify/datepicker";
import { DateTimePicker } from "../DateTimePicker";
import { useCallback, useMemo } from "react";
import { mapLocalDateTimeToUtcTimestamp, mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";
import { DateTimeFieldVariant } from "../../../fields";
import { DateTimePickerControllerProps } from "../../types";
import { setCurrentTimeIncrement, setSameTime } from "../TimeRangeInput/utils";

export const DateTimePickerController = ({
	value,
	variant,
	min,
	max,
	onChange = () => undefined,
	offsetDate,
	...pickerProps
}: DateTimePickerControllerProps<number>) => {
	const isDateOnly = variant === DateTimeFieldVariant.DATE;

	const selectedDates = useMemo(() => {
		return value == null ? [] : [mapUtcTimeStampToLocalDateTime(value, isDateOnly)];
	}, [isDateOnly, value]);

	const onDatesChange = useCallback(
		(dates: Date[]) => {
			if (dates.length === 0) onChange(null);
			else {
				let newDate;
				if (value != null) {
					const previousDate = mapUtcTimeStampToLocalDateTime(value, isDateOnly);
					newDate = setSameTime(dates[0], previousDate);
				} else {
					newDate = setCurrentTimeIncrement(dates[0]);
				}
				onChange(mapLocalDateTimeToUtcTimestamp(newDate, isDateOnly));
			}
		},
		[isDateOnly, onChange, value]
	);

	return (
		<DatePickerStateProvider
			config={{
				selectedDates,
				onDatesChange,
				dates: {
					mode: "single",
					minDate: min ? mapUtcTimeStampToLocalDateTime(min, isDateOnly) : undefined,
					maxDate: max ? mapUtcTimeStampToLocalDateTime(max, isDateOnly) : undefined,
				},
				calendar: {
					mode: "fluid",
				},
				locale: {
					options: {
						day: "2-digit",
						month: "long",
						year: "numeric",
						hour12: false,
						hour: "2-digit",
						minute: "2-digit",
					},
				},
				time: {
					interval: 15,
				},
				offsetDate,
			}}
		>
			<DateTimePicker
				variant={variant}
				{...pickerProps}
				value={value}
				isTimeRange={false}
				offsetDate={offsetDate}
				onChange={(value) => {
					if (typeof value === "number") {
						onChange(value);
					}
				}}
			/>
		</DatePickerStateProvider>
	);
};
