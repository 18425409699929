export enum SdEventSupertype {
	STATUS_UPDATE = "STATUS_UPDATE",
	RECORD_VIEW = "RECORD_VIEW",
	FIELD_UPDATES = "FIELD_UPDATES",
	NOTE_UPDATE = "NOTE_UPDATE",
	COMMENT_UPDATE = "COMMENT_UPDATE",
	RECORD_CONNECTIONS = "RECORD_CONNECTIONS",
	UI = "UI",
	VIDEO_CALL_UPDATES = "VIDEO_CALL_UPDATES",
	ACCESS = "ACCESS",
	MEDIA_UPDATE = "MEDIA_UPDATE",
	MESSAGING = "MESSAGING",
}
