import {
	mContactDef,
	mOpportunityDef,
	mTaskDef,
	mIssueDef,
	mUserDef,
	BOARD_VIEW,
	mDocDef,
	mAssetDef,
} from "@salesdesk/salesdesk-model";
import { getSDObjectFields, isAssetObject, Field, SDObject, BOOKMARK_ID_PARAM } from "@salesdesk/salesdesk-schemas";

import {
	ASSET_RECORD_COMBINED_NAME_FILE_ID,
	BoardPropOnChange,
	BoardState,
	DEFAULT_BOARD_STATE,
	NO_FIELD_ID,
	ObjectBoardDetails,
} from "../types";
import { CARD_BASED_VIEWS, GROUPED_VIEWS, METADATA_FIELDS, TABLE_VIEWS, getObjectNameFieldBoardIds } from ".";
import { AppState } from "../../users";
import { UNSELECT_BOOKMARK_ID } from "../../bookmarks/utils";
import { GroupableFields } from "../../objects/types";

interface ObjectBoardFields {
	[objectId: number]: {
		fields?: string[];
		metadata?: string[];
	};
}

/**
 * TODO: Currently as a basic implementation we're just using fixed names
 * for the fields to determine the fields for each data board view of a
 * system object type. In the future we should be using a 'view details api',
 * which would also better support custom objects: https://salesdesk101.atlassian.net/browse/SAL-1527
 */
const DEFAULT_BOARD_FIELDS: Record<BOARD_VIEW, ObjectBoardFields> = {
	[BOARD_VIEW.TABLE]: {
		[mOpportunityDef.ID]: {
			fields: [
				mOpportunityDef.NAME_FIELD_NAME,
				mOpportunityDef.VALUE_FIELD_NAME,
				mOpportunityDef.STAGE_FIELD_NAME,
				mOpportunityDef.CLOSE_DATE_FIELD_NAME,
				mOpportunityDef.ACCOUNT_MANAGER_FIELD_NAME,
			],
			metadata: [METADATA_FIELDS.owner._name],
		},
		[mTaskDef.ID]: {
			fields: [
				mTaskDef.NAME_FIELD_NAME,
				mTaskDef.STATUS_FIELD_NAME,
				mTaskDef.PRIORITY_FIELD_NAME,
				mTaskDef.DUE_DATE_FIELD_NAME,
				mTaskDef.ASSIGNEE_FIELD_NAME,
				mTaskDef.PROGRESS_FIELD_NAME,
			],
			metadata: [METADATA_FIELDS.lastModified._name, METADATA_FIELDS.lastModifiedUser._name],
		},
		[mIssueDef.ID]: {
			fields: [
				mIssueDef.NAME_FIELD_NAME,
				mIssueDef.STATUS_FIELD_NAME,
				mIssueDef.PRIORITY_FIELD_NAME,
				mIssueDef.DUE_DATE_FIELD_NAME,
				mIssueDef.ASSIGNEE_FIELD_NAME,
				mIssueDef.PROGRESS_FIELD_NAME,
			],
			metadata: [METADATA_FIELDS.lastModified._name, METADATA_FIELDS.lastModifiedUser._name],
		},
	},
	[BOARD_VIEW.CARD]: {
		// Record name is automatically added as the first field for all Card views so not necessary to add here
		[mContactDef.ID]: {
			fields: [mUserDef.TITLE_FIELD_NAME, mUserDef.EMAIL_FIELD_NAME, mUserDef.MOBILE_FIELD_NAME],
		},
		[mTaskDef.ID]: {
			fields: [
				mTaskDef.NAME_FIELD_NAME,
				mTaskDef.ASSIGNEE_FIELD_NAME,
				mTaskDef.STATUS_FIELD_NAME,
				mTaskDef.DUE_DATE_FIELD_NAME,
			],
			metadata: [METADATA_FIELDS.lastModified._name, METADATA_FIELDS.lastModifiedUser._name],
		},
		[mIssueDef.ID]: {
			fields: [
				mIssueDef.NAME_FIELD_NAME,
				mIssueDef.ASSIGNEE_FIELD_NAME,
				mIssueDef.STATUS_FIELD_NAME,
				mIssueDef.DUE_DATE_FIELD_NAME,
			],
			metadata: [METADATA_FIELDS.lastModified._name, METADATA_FIELDS.lastModifiedUser._name],
		},
		[mDocDef.ID]: {
			fields: [mDocDef.STATUS_FIELD_NAME],
		},
	},
	[BOARD_VIEW.LIST]: {},
	[BOARD_VIEW.KANBAN]: {},
	[BOARD_VIEW.CALENDAR]: {},
};

const DEFAULT_FIELD_NUMBER = 5;

export function getDefaultBoardFields(boardView: BOARD_VIEW | undefined, sdObject: SDObject) {
	// Calendar has no displayed fields since only the name of the record
	// and the time data of the 'groupBy' field are displayed
	if (!boardView || boardView === BOARD_VIEW.CALENDAR) {
		return [];
	}

	const defaultBoardDetails = DEFAULT_BOARD_FIELDS?.[boardView]?.[sdObject._id];

	const defaultFieldIds: string[] = CARD_BASED_VIEWS.includes(boardView) ? [] : getObjectNameFieldBoardIds(sdObject);
	const objectFields = getSDObjectFields(sdObject);

	// Returns the first DEFAULT_FIELD_NUMBER of fields in the object if there is no set boardFields
	if (!defaultBoardDetails) {
		for (const objectField of objectFields) {
			if (defaultFieldIds.length >= DEFAULT_FIELD_NUMBER) {
				break;
			}

			const fieldId = String(objectField._id);

			if (!defaultFieldIds.includes(fieldId)) {
				defaultFieldIds.push(String(objectField._id));
			}
		}
		return defaultFieldIds;
	}

	// Otherwise get the IDs for the default board fields & combine with the metadata 'fields'
	if (defaultBoardDetails.fields) {
		const fieldNameIdMap: Record<string, string> = {};
		objectFields.forEach((field) => {
			fieldNameIdMap[field._name] = String(field._id);
		});

		defaultBoardDetails.fields?.forEach((fieldName) => {
			defaultFieldIds.push(fieldNameIdMap[fieldName]);
		});
	}

	return defaultFieldIds.concat(defaultBoardDetails.metadata || []);
}

function updateBoardStateFromAppState(
	sdObject: SDObject,
	boardState: BoardState,
	appState: AppState,
	boardPropOnChange: BoardPropOnChange,
	updateBookmarkId: (bookmarkId: number) => void
): boolean {
	let updatedState = false;
	const params = new URLSearchParams(window.location.search);
	const isInitialLoad = ["view", "groupBy", "sort", "agg", "mediaField"].every((key) => !params.get(key));

	if (isInitialLoad) {
		const appStateBoardState = appState.boardState?.[sdObject._id];
		if (appStateBoardState) {
			if (!params.has(BOOKMARK_ID_PARAM) && appStateBoardState.bookmarkId) {
				updateBookmarkId(appStateBoardState.bookmarkId);
			}
			if (params.get(BOOKMARK_ID_PARAM) === String(UNSELECT_BOOKMARK_ID)) {
				setTimeout(() => {
					const params = new URLSearchParams(window.location.search);
					params.delete(BOOKMARK_ID_PARAM);
					const newUrl = `${window.location.pathname}?${params.toString()}`;
					window.history.replaceState({}, "", newUrl);
				});
			}

			if (!boardState.displayedFields && appStateBoardState.displayedFields) {
				boardPropOnChange("displayedFields", appStateBoardState.displayedFields);
				updatedState = true;
			}
			if (!boardState.view && appStateBoardState.view) {
				boardPropOnChange("view", appStateBoardState.view);
				updatedState = true;
			}
			if (!boardState.groupBy && appStateBoardState.groupBy) {
				boardPropOnChange("groupBy", appStateBoardState.groupBy);
				updatedState = true;
			}
			if (!boardState.sort && appStateBoardState.sort) {
				boardPropOnChange("sort", appStateBoardState.sort);
				updatedState = true;
			}
			if (!boardState.agg && appStateBoardState.agg) {
				boardPropOnChange("agg", appStateBoardState.agg);
				updatedState = true;
			}
			if (!boardState.mediaField && appStateBoardState.mediaField) {
				boardPropOnChange("mediaField", appStateBoardState.mediaField);
				updatedState = true;
			}
		}
	}
	return updatedState;
}

// Updates the board state to ensure that it's a valid board state
// (e.g. adjusting displayed fields based on the board view, etc.)
export function updateBoardState(
	objectBoardDetails: ObjectBoardDetails,
	boardState: BoardState,
	boardPropOnChange: BoardPropOnChange,
	appState: AppState,
	updateBookmarkId: (bookmarkId: number) => void
): boolean {
	const { sdObject, groupableFields, nameFieldIds, mediaFieldIds } = objectBoardDetails;

	if (!sdObject) {
		return false;
	}

	let updatedState = false;

	if (updateBoardStateFromAppState(sdObject, boardState, appState, boardPropOnChange, updateBookmarkId)) {
		return true;
	}

	let { displayedFields } = boardState;

	if (!boardState.view) {
		boardPropOnChange("view", sdObject._defaultView || DEFAULT_BOARD_STATE.view);
		updatedState = true;
	}

	if (boardState.view === BOARD_VIEW.CALENDAR) {
		if (displayedFields) {
			displayedFields = undefined;
			boardPropOnChange("displayedFields", displayedFields);
			updatedState = true;
		}
	}
	// If no displayedFields set, update the board state to the default fields for that object/data view
	else if (!displayedFields) {
		displayedFields = getDefaultBoardFields(boardState.view, sdObject);
		boardPropOnChange("displayedFields", displayedFields);
		updatedState = true;
	}

	const isCardBasedView = boardState.view && CARD_BASED_VIEWS.includes(boardState.view);

	if (isCardBasedView) {
		if (displayedFields) {
			let updatedDisplayFields = false;

			const isFileField = (fieldId: string) => mediaFieldIds.includes(fieldId);

			// Filter out any file fields from the display fields of a card based view
			// since these fields are displayed by the 'mediaField' prop
			if (displayedFields.some(isFileField)) {
				displayedFields = displayedFields.filter((fieldId) => !isFileField(fieldId));
				updatedDisplayFields = true;
			}

			for (let i = 0; i < nameFieldIds.length; i++) {
				// Ensure the name field IDs are added to the beginning of the displayed fields
				if (displayedFields.length <= i || nameFieldIds[i] !== displayedFields[i]) {
					displayedFields = [...nameFieldIds, ...displayedFields];
					updatedDisplayFields = true;
					break;
				}
			}

			if (updatedDisplayFields) {
				boardPropOnChange("displayedFields", displayedFields);
				updatedState = true;
			}
		}

		if (!boardState.mediaField) {
			boardPropOnChange("mediaField", mediaFieldIds.length ? mediaFieldIds[0] : NO_FIELD_ID);
			updatedState = true;
		}
	} else if (boardState.mediaField) {
		boardPropOnChange("mediaField", undefined);
		updatedState = true;
	}

	const idsToFilter = getFieldIdsToFilterForObjectType(sdObject, boardState.view).map((id) => String(id));

	if (idsToFilter.length && displayedFields) {
		const fieldsToFilter = (fieldId: string) => idsToFilter.includes(fieldId);

		if (displayedFields.some(fieldsToFilter)) {
			displayedFields = displayedFields.filter((fieldId) => !fieldsToFilter(fieldId));

			if (isAssetObject(sdObject)) {
				// Ensure the combined name and file field is displayed for asset object table boards when the name/file fields are filtered out
				boardPropOnChange("displayedFields", [ASSET_RECORD_COMBINED_NAME_FILE_ID, ...displayedFields]);
			} else {
				boardPropOnChange("displayedFields", displayedFields);
			}

			updatedState = true;
		}
	}

	if (boardState.view !== BOARD_VIEW.CALENDAR && (boardState.date || boardState.calendarView)) {
		boardPropOnChange("date", undefined);
		boardPropOnChange("calendarView", undefined);
		return true;
	}

	const groupableFieldsForView = getGroupableFieldsByForView(boardState.view, groupableFields);
	if (boardState.groupBy == null && groupableFieldsForView.length) {
		boardPropOnChange("groupBy", String(groupableFieldsForView[0]._id));
	}

	return updatedState;
}

export function getGroupableFieldsByForView(view: BOARD_VIEW | undefined, groupableFields: GroupableFields): Field[] {
	if (!view) return [];
	const requiresGroupBy = GROUPED_VIEWS.includes(view) || view === BOARD_VIEW.CALENDAR;
	if (!requiresGroupBy) return [];
	return view === BOARD_VIEW.CALENDAR ? groupableFields.timeFields : groupableFields.singleOptionFields;
}

export function getFieldIdsToFilterForObjectType(sdObject: SDObject, boardView?: BOARD_VIEW): Field["_id"][] {
	let fieldNames: string[] = [];

	if (sdObject._id === mDocDef.ID) {
		fieldNames = [mDocDef.DOCUMENT_FIELD_NAME];
	} else if (boardView && isAssetObject(sdObject) && TABLE_VIEWS.includes(boardView)) {
		fieldNames = [mAssetDef.NAME_FIELD_NAME, mAssetDef.FILE_FIELD_NAME];
	}

	return fieldNames.length
		? getSDObjectFields(sdObject)
				.filter((field) => fieldNames.includes(field._name))
				.map((field) => field._id)
		: [];
}
