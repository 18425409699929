import { AggregationResponse, RecordsSearchRequest, SDRecord } from "@salesdesk/salesdesk-schemas";

// Fixed reference to empty array/highlight map to avoid infinite re-renders
export const EMPTY_SEARCH_RESULTS: SDRecord[] = [];
export const EMPTY_HIGHLIGHT_MAP = {};
export const EMPTY_AGGREGATIONS: AggregationResponse[] = [];

export type InfiniteRecordSearchParams = Omit<RecordsSearchRequest, "size" | "from">;

export const RECORD_CHANGE_EVENT = "RECORD_CHANGE_EVENT";

export enum RecordChangeType {
	CREATE = "CREATE",
	UPDATE = "UPDATE",
	DELETE = "DELETE",
	ASSOCIATION_CHANGE = "ASSOCIATION_CHANGE",
}

export interface RecordChangeEvent {
	type: RecordChangeType;
	isOptimistic?: boolean;
	records: SDRecord[] | SDRecord | SDRecord["_id"][] | SDRecord["_id"];
}
