import { SDFileCreateRequest, GetFilePath, SDFile, StartFileUploadResponse } from "@salesdesk/salesdesk-schemas";
import { buildApiSingleTag, SDApi } from "../../api";

export const fileApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			postFile: builder.mutation<StartFileUploadResponse, SDFileCreateRequest>({
				query: (body) => ({
					url: "/files",
					method: "POST",
					body,
				}),
			}),
			getFile: builder.query<SDFile, GetFilePath>({
				query: ({ fileId }) => `/files/${fileId}`,
				providesTags: (result, error, { fileId }) => buildApiSingleTag(fileId, (fileId) => fileId, "File"),
			}),
		};
	},
});

export const { usePostFileMutation, useGetFileQuery } = fileApi;
