import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { uniqBy } from "lodash";
import clsx from "clsx";

import { SDEvent, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useInfiniteScrollContainer } from "../../../hooks/ui";
import { FilterEventType } from "./TypeFilter";
import { SingleEvent } from "./SingleEvent";
import { Divider } from "../../../components/Divider/Divider";
import { Spinner } from "@salesdesk/daisy-ui";
import { EventListLoading } from "./EventListLoading";
import { useGetEvents } from "../hooks/useGetEvents";
import { EventTarget } from "../types";

const PAGE_SIZE = 40;

interface EventListProps {
	sdRecord?: SDRecord;
	externalScrollContainerRef?: RefObject<HTMLDivElement>;
	onUpdateCount?: (count: number) => void;
	typeFilter?: FilterEventType;
	userFilter?: number[];
	workspaceFilter?: number;
	showUsersActivity?: boolean;
	target: EventTarget;
}

export function EventList({
	sdRecord,
	typeFilter,
	userFilter,
	workspaceFilter,
	externalScrollContainerRef,
	onUpdateCount,
	showUsersActivity = false,
	target,
}: EventListProps) {
	const [page, setPage] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const localContainerRef = useRef<HTMLDivElement>(null);

	const hasExternalScroll = Boolean(externalScrollContainerRef);
	const containerRef = externalScrollContainerRef || localContainerRef;

	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			const nextPage = page + 1;
			if (nextPage * PAGE_SIZE < hitsCount) {
				setPage(nextPage);
			}
		},
	});

	const [queryParams, setQueryParams] = useState(() =>
		generateEventQueryParams({ sdRecord, typeFilter, userFilter, page, showUsersActivity })
	);

	useEffect(() => {
		setQueryParams(generateEventQueryParams({ sdRecord, typeFilter, userFilter, page, showUsersActivity }));
	}, [sdRecord, typeFilter, userFilter, page, showUsersActivity]);

	const { currentData, isFetching } = useGetEvents(queryParams, workspaceFilter);

	const [allActivities, setAllActivities] = useState<SDEvent[]>([]);

	const [activityPage, hitsCount] = useMemo(
		() => [currentData?.hits ?? [], currentData?.hitCount ?? 0],
		[currentData]
	) satisfies [SDEvent[], number];

	const isFiltered = Boolean(typeFilter) || (Boolean(userFilter?.length) && !showUsersActivity);

	useEffect(() => {
		if (activityPage !== undefined && activityPage.length > 0) {
			setAllActivities((prev) => uniqBy([...prev, ...activityPage], "id"));
		}
	}, [activityPage]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (hitsCount !== undefined && onUpdateCount && !isFiltered) {
			onUpdateCount(hitsCount);
		}
	}, [hitsCount, onUpdateCount, isFiltered]);

	const orderedEvents = useMemo(() => allActivities?.sort((a, b) => b.tstamp - a.tstamp), [allActivities]);

	const isInitialLoad = page === 0 && isLoading;

	return (
		<div
			ref={localContainerRef}
			className={clsx(
				!hasExternalScroll && "relative h-full max-h-full",
				!hasExternalScroll && !isInitialLoad && "overflow-auto"
			)}
		>
			{isInitialLoad ? <EventListLoading numberOfSkeletonEvents={hasExternalScroll ? 4 : 20} /> : null}
			{!isLoading && orderedEvents?.length === 0 ? (
				<div className="flex items-center justify-center">
					<div className="text-body-sm text-c_text_placeholder my-4">
						{isFiltered ? "No activities matched the applied filters." : "No activities found."}
					</div>
				</div>
			) : null}
			{orderedEvents ? (
				<div className={clsx("flex max-w-full flex-col gap-3 overflow-hidden", !hasExternalScroll && "pb-3")}>
					{orderedEvents.flatMap((event, index) => {
						return [
							index === 0 ? null : <Divider key={`${event.id}_divider`} className="m-0" />,
							<SingleEvent key={`${event.id}_event`} event={event} target={target} />,
						];
					})}
				</div>
			) : null}
			{isInitialLoad ? null : isLoading ? (
				<div className="mb-3 flex items-center justify-center">
					<Spinner size="md" />
				</div>
			) : (
				<div key={orderedEvents?.length ?? 0} ref={containerBottomRef} />
			)}
		</div>
	);
}

function generateEventQueryParams({
	sdRecord,
	typeFilter,
	userFilter,
	page,
	showUsersActivity,
}: {
	sdRecord?: SDRecord;
	typeFilter?: FilterEventType;
	userFilter?: number[];
	page: number;
	showUsersActivity: boolean;
}) {
	return {
		recordId: showUsersActivity ? undefined : sdRecord?._id.toString(),
		eventSupertypes: typeFilter === "all" ? undefined : typeFilter,
		userIds: showUsersActivity ? sdRecord?._id.toString() : userFilter?.join(","),
		limit: String(PAGE_SIZE),
		offset: String(page * PAGE_SIZE),
	};
}
