import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { ColumnMappingRow } from "./ColumnMappingRow";

export function ColumnMappingSkeletonRow() {
	return (
		<ColumnMappingRow
			nameCell={<Skeleton className="h-4 w-full" />}
			mappingCell={<Skeleton className="rounded-minimal h-10" rounded={false} />}
			isLoading
		/>
	);
}
