import { useCallback, useMemo } from "react";

import { SDObject, getSDObjectNameFieldIds } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { BaseSelect, SelectOptionId } from "../../../inputs";
import { FixedSelectButton } from "../../../inputs/components/Select/components/ButtonSelect/FixedSelectButton";
import { SortingDetails, SortingOrder } from "../../../records";

interface AssociationSortingSelectProps {
	onChange: (value: SortingDetails[]) => void;
	sdObject: SDObject;
	iconOnly?: boolean;
}

export function AssociationSortingSelect({ onChange, sdObject, iconOnly }: AssociationSortingSelectProps) {
	const sortingOptions = useMemo(() => {
		const alphabeticalOrder = {
			label: "Alphabetical Order",
			sortingOption: getSDObjectNameFieldIds(sdObject).map((fieldNameId) => ({
				fieldId: String(fieldNameId),
				order: SortingOrder.asc,
			})),
		};

		/* 
            TODO: Currently it's impossible to sort by recently added associations 

            Finish this ticket within Ticket - https://salesdesk101.atlassian.net/browse/SAL-2142
            - uncomment code below
            - add sortingOption 
            - add to return value
        */

		// const recentlyAdded = {
		// 	label: "Recently added",
		// };

		return [alphabeticalOrder];
	}, [sdObject]);

	const selectOptions = useMemo(
		() => sortingOptions.map(({ label }) => ({ id: label, name: label })),
		[sortingOptions]
	);

	const onSortingSelectChange = useCallback(
		(sortingOptionId?: SelectOptionId) => {
			const filterValue = sortingOptions.find((sortingOption) => sortingOption.label === sortingOptionId);

			if (filterValue) {
				onChange(filterValue.sortingOption);
			}
		},
		[onChange, sortingOptions]
	);

	return (
		<BaseSelect
			optionsTitle="Sort by"
			selectPopoverOptions={{ placement: "bottom-start" }}
			options={selectOptions}
			onChange={onSortingSelectChange}
			selectButton={<FixedSelectButton startIcon={ICONS.sort}>{iconOnly ? undefined : "Sort"}</FixedSelectButton>}
		/>
	);
}
