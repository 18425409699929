import { NotificationsButton } from "../notifications";
import { NavbarLogoFull } from "../../components/Navbar";
import { UserMenu } from "../UserMenu";

export function SettingsHeader() {
	return (
		<div className="bg-c_bg_02 flex h-16 w-full shrink-0 items-center gap-6 pr-4">
			<div>
				<NavbarLogoFull />
			</div>
			<div className="ml-auto flex items-center gap-6">
				<NotificationsButton />
				<UserMenu />
			</div>
		</div>
	);
}
