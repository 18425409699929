import { DragEvent, useCallback, useRef, useState } from "react";
import { FileInputErrorCode, FileInputProps } from "../../../types";

interface UseFileInputControllerProps
	extends Pick<
		FileInputProps,
		"value" | "onChange" | "uploadProgress" | "disabled" | "supportedMimeTypes" | "maxFileSizeInBytes"
	> {
	canUpdateIfNotEmpty?: boolean;
}

export function useFileInputController({
	value,
	onChange,
	uploadProgress,
	disabled,
	supportedMimeTypes,
	maxFileSizeInBytes,
	canUpdateIfNotEmpty = false,
}: UseFileInputControllerProps) {
	const [draggingOver, setDraggingOver] = useState(false);
	const innerRef = useRef<HTMLInputElement>(null);
	const enterTarget = useRef<EventTarget>();

	const fileValue = value || null;

	const isUploading = uploadProgress != null;
	const canUpdateFile = (canUpdateIfNotEmpty || !fileValue) && !isUploading && !disabled;

	const clearFile = useCallback(() => {
		if (innerRef.current) {
			innerRef.current.value = "";
		}
		onChange(null);
	}, [onChange]);

	const updateFile = useCallback(
		(file: File | null | undefined) => {
			if (file === null) return;

			if (!file || !(file instanceof File)) {
				clearFile();
			} else {
				if (maxFileSizeInBytes !== undefined && file.size > maxFileSizeInBytes) {
					onChange(null, FileInputErrorCode.FILE_TOO_LARGE, [file.size]);
					return;
				}
				if (
					supportedMimeTypes !== undefined &&
					supportedMimeTypes !== "*" &&
					!supportedMimeTypes.split(",").includes(file.type)
				) {
					onChange(null, FileInputErrorCode.UNSUPPORTED_FILE_TYPE, [file.type]);
					return;
				}
				onChange(file);
			}
		},
		[clearFile, maxFileSizeInBytes, onChange, supportedMimeTypes]
	);

	const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
		enterTarget.current = event.target;
		setDraggingOver(true);
	};

	const handleDragExit = (event: DragEvent<HTMLDivElement>) => {
		if (enterTarget.current === event.target) {
			setDraggingOver(false);
		}
	};

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDraggingOver(false);
		if (canUpdateFile) {
			updateFile(event.dataTransfer.files?.item(0));
		}
	};

	return {
		fileValue,
		draggingOver,
		innerRef,
		canUpdateFile,
		clearFile,
		updateFile,
		handleDragEnter,
		handleDragExit,
		handleDrop,
	};
}
