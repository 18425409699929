import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { Participant, RemoteParticipant, TrackPublication } from "twilio-video";

import { asyncPause } from "@salesdesk/salesdesk-utils";

import { useVideoCallLogicContext } from "../../VideoCallProvider/components/VideoCallLogicProvider";
import { useTriggerVideoCallEvent } from "../hooks/useTriggerVideoCallEvent";
import { isScreenShareTrack } from "../../../utils";

import { useMessageReceivedVideoCallEvent } from "../../MeetingPage/components/VideoCallPage/VideoSidePanel/components/ChatPanel/hooks/useMessageReceivedVideoCallEvent";

const NAME_REQUEST_INTERVAL = 100;
const MAX_PARTICIPANT_TOAST_DELAY = 1000;

export function VideoCallEventTracker({ children }: PropsWithChildren) {
	const { room, callParticipantDetails, fileSharingParticipant } = useVideoCallLogicContext();

	const previousFileSharingParticipant = useRef(fileSharingParticipant);
	const callParticipantDetailsRef = useRef(callParticipantDetails);

	const triggerVideoCallEvent = useTriggerVideoCallEvent();

	useEffect(() => {
		callParticipantDetailsRef.current = callParticipantDetails;
	}, [callParticipantDetails]);

	// Async function which attempts to retrieve the participant's name each NAME_REQUEST_INTERVAL ms, within
	// the MAX_PARTICIPANT_TOAST_DELAY ms if the user record for that participant hasn't loaded yet to make the
	// toasts more seamless. Most useful for when a user has just joined a meeting
	// and their record is still being fetched.
	//
	// TODO: Investiage twillio to see if we can store the user's name on the participant object
	const getParticipantName = useCallback(async (participant: RemoteParticipant): Promise<string | undefined> => {
		let partipantDetails = callParticipantDetailsRef.current.find(
			(detail) => detail.participant.identity === participant.identity
		);

		if (!partipantDetails) {
			let attemptsLeft = Math.ceil(MAX_PARTICIPANT_TOAST_DELAY / NAME_REQUEST_INTERVAL);

			while (!partipantDetails && attemptsLeft > 0) {
				await asyncPause(MAX_PARTICIPANT_TOAST_DELAY);

				partipantDetails = callParticipantDetailsRef.current.find(
					(detail) => detail.participant.identity === participant.identity
				);

				attemptsLeft--;
			}
		}

		return partipantDetails?.name || participant.identity;
	}, []);

	useMessageReceivedVideoCallEvent();

	useEffect(() => {
		if (!room) {
			return;
		}

		const isLocalPartipant = (particpant: Participant) => {
			return particpant.identity === room.localParticipant.identity;
		};

		function triggerFileShareEvent(type: "start_fileshare" | "stop_fileshare", participant: Participant) {
			if (isLocalPartipant(participant)) {
				return;
			}

			getParticipantName(participant as RemoteParticipant).then((participantName) => {
				triggerVideoCallEvent(type, participant.identity);
			});
		}

		// A participant is starting to share a file
		if (!previousFileSharingParticipant.current && fileSharingParticipant) {
			triggerFileShareEvent("start_fileshare", fileSharingParticipant.participant);
		}
		// A participant is stopping file share
		else if (previousFileSharingParticipant.current && !fileSharingParticipant) {
			triggerFileShareEvent("stop_fileshare", previousFileSharingParticipant.current.participant);
		}
		// A participant is taking over from another participant who was file sharing beforehand
		else if (previousFileSharingParticipant.current && fileSharingParticipant) {
			const previousParticipant = previousFileSharingParticipant.current.participant;
			const currentParticipant = fileSharingParticipant.participant;

			if (previousParticipant.identity !== currentParticipant.identity) {
				triggerFileShareEvent("stop_fileshare", previousFileSharingParticipant.current.participant);
				triggerFileShareEvent("start_fileshare", fileSharingParticipant.participant);
			}
		}

		previousFileSharingParticipant.current = fileSharingParticipant;
	}, [fileSharingParticipant, room]);

	useEffect(() => {
		if (!room) {
			return;
		}

		triggerVideoCallEvent("joined_meeting");

		const onParticipantConnected = (participant: RemoteParticipant) => {
			getParticipantName(participant).then((participantName) => {
				triggerVideoCallEvent("joined_meeting", participantName);
			});
		};

		const onParticipantDisconnected = (participant: RemoteParticipant) => {
			getParticipantName(participant).then((participantName) => {
				triggerVideoCallEvent("left_meeting", participantName);
			});
		};

		const onTrackPublished = (publication: TrackPublication, participant: RemoteParticipant) => {
			if (!isScreenShareTrack(publication)) {
				return;
			}

			getParticipantName(participant).then((participantName) => {
				triggerVideoCallEvent("start_screenshare", participantName);
			});
		};

		function onTrackUnpublished(publication: TrackPublication, participant: RemoteParticipant): void {
			if (!isScreenShareTrack(publication)) {
				return;
			}

			getParticipantName(participant).then((participantName) => {
				triggerVideoCallEvent("stop_screenshare", participantName);
			});
		}

		const onRecordingStarted = () => {
			triggerVideoCallEvent("start_recording");
		};

		const onRecordingStopped = () => {
			triggerVideoCallEvent("stop_recording");
		};

		room.on("participantConnected", onParticipantConnected);
		room.on("participantDisconnected", onParticipantDisconnected);

		room.on("trackPublished", onTrackPublished);
		room.on("trackUnpublished", onTrackUnpublished);

		room.on("recordingStarted", onRecordingStarted);
		room.on("recordingStopped", onRecordingStopped);

		return () => {
			triggerVideoCallEvent("left_meeting");

			room.off("participantConnected", onParticipantConnected);
			room.off("participantDisconnected", onParticipantDisconnected);

			room.off("trackPublished", onTrackPublished);
			room.off("trackUnpublished", onTrackUnpublished);

			room.off("recordingStarted", onRecordingStarted);
			room.off("recordingStopped", onRecordingStopped);
		};
	}, [room, triggerVideoCallEvent, getParticipantName]);

	return children;
}
