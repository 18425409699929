import { useToastContext } from "../hooks/useToastContext";
import { ToastType } from "../types";
import { Link, LinkProps, LinkVariant } from "../../Link";

function toLinkVariant(type: ToastType, dark: boolean): LinkVariant {
	if (dark) return "inverted";
	if (type === "info") return "action";
	if (type === "error") return "danger";
	return type;
}

export function ToastLink(props: LinkProps) {
	const { type, dark } = useToastContext();

	return <Link inline {...props} variant={toLinkVariant(type, dark)} />;
}
