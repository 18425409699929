interface ProgressIndicatorProps {
	percentage: number;
}

export function ProgressIndicator({ percentage }: ProgressIndicatorProps) {
	return (
		<div className="bg-c_bg_05 h-1 w-full rounded-full">
			<div
				className="bg-c_brand_primary h-full rounded-full transition-all ease-in-out"
				style={{ width: `${percentage}%` }}
			/>
		</div>
	);
}
