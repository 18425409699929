import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { DATE_GROUPING, FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";
import { isEmpty } from "@salesdesk/salesdesk-utils";

export class mDateTimeFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.DATE_TIME.name];
	static defaultComponentType: FieldComponentName = mDateTimeFieldDef.supportedComponentTypes[0];

	_supportsPast = true;

	constructor(id: string | number) {
		super(id);
		this._componentType = mDateTimeFieldDef.defaultComponentType;

		// How the data is stored internally (ISO8601 - YYYY-MM-DDTHH:mm:ss.sssZ)
		this._format = "YYYY-MM-DDTHH:mm";

		// The format to be used by the user to input the date and time
		this._formatDescription = "dd/mm/yyyy, hh:mm";

		this._icon = FIELD_TYPES.DATE_TIME.icon;
		this._supportsDefaultValue = true;
		this._supportsUniqueValue = true;
	}

	override get type() {
		return FIELD_TYPES.DATE_TIME.name;
	}

	override get supportedComponentTypes() {
		return mDateTimeFieldDef.supportedComponentTypes;
	}

	get supportsPast() {
		return this._supportsPast;
	}

	set supportsPast(supportsPast) {
		this._supportsPast = supportsPast;
	}

	override supportsGrouping() {
		return !this.supportsMultiple() && !this.hidden && this.required;
	}

	supportsReGrouping() {
		return false;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value) && (Number.isNaN(value) || !Number.isInteger(value))) {
			return `'${this.displayName}' expects a valid date and time.`;
		}

		return super.validate(value, isTemplate);
	}

	get groupInfo() {
		return DATE_GROUPING;
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}
}
