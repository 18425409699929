import { useMemo, memo } from "react";
import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PopoverMenu, MenuItem } from "../../../menu";
import { SortingOrder } from "../../../records";

interface HeaderCellProps {
	columnId: string;
	columnName: string;
	sortingOrder?: SortingOrder;
	onSortingOrderChange?: (sortingOrder?: SortingOrder) => void;
}

function HeaderCellComponent({ columnName, sortingOrder, onSortingOrderChange }: HeaderCellProps) {
	const sortingMenuItems: MenuItem[] | null = useMemo(() => {
		if (!onSortingOrderChange) {
			return null;
		}

		const generateSortingMenuItemOnClick = (targetSortingOrder?: SortingOrder) => {
			return targetSortingOrder === sortingOrder ? undefined : () => onSortingOrderChange(targetSortingOrder);
		};

		return [
			{
				type: "button",
				icon: ICONS.sortAsc,
				text: "Ascending",
				onClick: generateSortingMenuItemOnClick(SortingOrder.asc),
			},
			{
				type: "button",
				icon: ICONS.sortDesc,
				text: "Descending",

				onClick: generateSortingMenuItemOnClick(SortingOrder.desc),
			},
			{
				type: "button",
				icon: ICONS.cross,
				text: "None",

				onClick: generateSortingMenuItemOnClick(undefined),
			},
		];
	}, [onSortingOrderChange, sortingOrder]);

	const headerContent = (
		<div className={clsx("flex items-center gap-4 px-4", sortingMenuItems && "cursor-pointer")}>
			<span className="truncate">{columnName}</span>
			{sortingOrder ? (
				<Icon
					className="text-c_icon_regular flex"
					icon={sortingOrder === SortingOrder.asc ? ICONS.sortAsc : ICONS.sortDesc}
				/>
			) : null}
		</div>
	);

	if (!sortingMenuItems) {
		return headerContent;
	}

	return (
		<PopoverMenu menuContents={sortingMenuItems} placement="bottom-start">
			{headerContent}
		</PopoverMenu>
	);
}

export const HeaderCell = memo(HeaderCellComponent);
