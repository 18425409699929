import { useCallback, useEffect, useState } from "react";

import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { AssociationLinkingModalProvider, DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";
import { useGetObjectMap } from "../../../../../../../objects/hooks";
import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../../../components/PanelTabs";
import { ResourcesList } from "./ResourcesList";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

interface ResourcesPanelTabsProps {
	associations: DirectedSDObjectAssociation[];
	onTotalAvailableRecordsChange: (count: number) => void;
	selectedRecords: SDRecord[];
	toggleRecordSelection: (record: SDRecord) => void;
	onResourceRecordsLoad?: (objectDefId: number, records: SDRecord[]) => void;
}

export function ResourcesPanelTabs({
	associations,
	onTotalAvailableRecordsChange,
	selectedRecords,
	toggleRecordSelection,
	onResourceRecordsLoad,
}: ResourcesPanelTabsProps) {
	const objectMap = useGetObjectMap();

	const [associationCounts, setAssociationCounts] = useState<Record<number, number>>({});

	const updateAssociationCount = useCallback((associationId: number, count: number) => {
		setAssociationCounts((prevCounts) => ({ ...prevCounts, [associationId]: count }));
	}, []);

	useEffect(() => {
		const totalAssociationCount = Object.values(associationCounts).reduce((acc, count) => acc + count, 0);

		onTotalAvailableRecordsChange(totalAssociationCount);
	}, [associationCounts, onTotalAvailableRecordsChange]);

	return (
		<AssociationLinkingModalProvider>
			<PanelTabs>
				<div className="flex h-full max-h-full flex-col overflow-hidden">
					<div className="px-6">
						<PanelTabList>
							{associations.map((associationDef) => {
								const count = associationCounts[associationDef.id] || 0;

								const { id, label } = associationDef.connectedObject;
								const associationObject = objectMap.get(id);

								return (
									<PanelTab icon={associationObject?._icon} key={associationDef.id} count={count}>
										{capitalizeString(label)}
									</PanelTab>
								);
							})}
						</PanelTabList>
					</div>
					<PanelTabPanels className="flex h-full max-h-full overflow-auto">
						{associations.map((associationDef) => {
							return (
								<PanelTabPanel key={associationDef.id} className="flex w-full flex-col px-px pt-4" unmount={false}>
									<ResourcesList
										objectAssociation={associationDef}
										onUpdateCount={updateAssociationCount}
										selectedRecords={selectedRecords}
										toggleRecordSelection={toggleRecordSelection}
										onResourceRecordsLoad={onResourceRecordsLoad}
									/>
								</PanelTabPanel>
							);
						})}
					</PanelTabPanels>
				</div>
			</PanelTabs>
		</AssociationLinkingModalProvider>
	);
}
