import { HTMLProps, forwardRef } from "react";
import { clsx } from "clsx";

import { tw } from "../../utils/tailwind-helpers";

interface PopoverContainerProps extends HTMLProps<HTMLDivElement> {
	heightClass?: string;
	variant?: "default" | "sm";
}

export const PopoverContainer = forwardRef<HTMLDivElement, PopoverContainerProps>(
	({ className, heightClass = tw`max-h-[600px]`, variant = "default", ...popoverProps }, ref) => {
		return (
			<div
				className={clsx(
					variant === "sm" ? "min-w-0 px-1 py-0.5" : "min-w-[144px] py-2",
					"bg-c_bg_01 border-c_border_regular shadow-popover text-c_text_primary text-label-nav flex flex-col overflow-auto rounded-sm border",
					heightClass,
					className
				)}
				ref={ref}
				{...popoverProps}
			/>
		);
	}
);
