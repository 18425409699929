import { PropsWithChildren, useEffect } from "react";

import {
	VideoCallMinimisedWindow,
	VideoCallProvider,
	VideoCallSoundEffectsHandler,
	useOnMeetingPage,
} from "../meetings";
import { useVideoCallLogicContext, useVideoCallMediaContext } from "../meetings/components/VideoCallProvider";

export function VideoCallContainer({ children }: PropsWithChildren) {
	return (
		<VideoCallProvider>
			{children}
			<VideoCallSoundEffectsHandler />
			<VideoCallMinimisedWindow />
			<VideoCallCleanup />
		</VideoCallProvider>
	);
}

/**
	Removes the audio and video tracks if the user is not on a meeting page or
	currently in a minimised video call
*/
export function VideoCallCleanup() {
	const { removeLocalAudioTrack, removeLocalVideoTrack } = useVideoCallMediaContext();
	const { currentMeetingRecord } = useVideoCallLogicContext();

	const onMeetingPage = useOnMeetingPage();

	useEffect(() => {
		if (!currentMeetingRecord && !onMeetingPage) {
			removeLocalAudioTrack();
			removeLocalVideoTrack();
		}
	}, [currentMeetingRecord, onMeetingPage, removeLocalAudioTrack, removeLocalVideoTrack]);

	return null;
}
