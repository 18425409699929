import { forwardRef, useMemo } from "react";

import { DateTimeInput } from "../../../../../inputs";
import { EditFieldProps } from "../../../../types";
import { DateTimeFieldVariant } from "../types";
import { DateTimeInputProps } from "../../../../../inputs/components/DateTimeInput/DateTimeInput";

interface EditDateTimeFieldProps extends DateTimeInputProps, Omit<EditFieldProps, "value" | "onChange"> {
	supportsPast?: boolean;
}

export const EditDateTimeField = forwardRef<HTMLInputElement, EditDateTimeFieldProps>(
	({ value = null, supportsPast, variant, ...dateTimeInputProps }, ref) => {
		const min = useMemo(() => {
			if (supportsPast !== false) {
				return undefined;
			}

			const now = new Date();
			return variant === DateTimeFieldVariant.DATE
				? Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
				: now.getTime();
		}, [supportsPast, variant]);

		return <DateTimeInput value={value} {...dateTimeInputProps} variant={variant} min={min} ref={ref} />;
	}
);
