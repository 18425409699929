import { CreateNotificationRuleFormDialog } from "./CreateNotificationRuleFormDialog";
import { RecordUpdateBadge } from "../common/RecordUpdateBadge";
import { Select, SelectOptionId } from "../../../../inputs";
import { RecordActionValueSelector } from "./ruleValueSelectors/record/RecordActionValueSelector";
import { FieldValueSelector } from "./ruleValueSelectors/FieldValueSelector";
import { UsersValueSelector } from "./ruleValueSelectors/record/UsersValueSelector";
import { RecordValueSelector } from "./ruleValueSelectors/RecordValueSelector";
import { ChannelValueSelector } from "./ruleValueSelectors/ChannelValueSelector";
import { useCallback } from "react";
import { ActionRuleElement } from "./ruleElements/ActionRuleElement";
import { RecordRuleElement } from "./ruleElements/RecordRuleElement";
import { UsersRuleElement } from "./ruleElements/record/UsersRuleElement";
import { ChannelsRuleElement } from "./ruleElements/ChannelsRuleElement";
import { useNotificationRuleDialogContext } from "../../hooks/useNotificationRuleDialogContext";
import { RuleElementType } from "../../types";
import { WorkspaceValueSelector } from "./ruleValueSelectors/WorkspaceValueSelector";
import { NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS, NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { SelectedPanel } from "./sections/SelectedPanel";
import { SelectorPanel } from "./sections/SelectorPanel";
import { SectionBreak } from "./sections/SectionBreak";
import { Section } from "./sections/Section";
import { useNotificationRuleObjectOptions } from "../../hooks/useNotificationRuleObjectOptions";
import { DownArrowIcon } from "./ruleElements/DownArrowIcon";

export function CreateRecordNotificationRuleDialog() {
	const {
		currentSelection,
		selectedObjectId,
		setSelectedObjectId,
		setSelectedWorkspaceId,
		setSelectedAction,
		setSelectedRecord,
		setSelectedField,
		setSelectedUsers,
	} = useNotificationRuleDialogContext();

	const onObjectIdChange = useCallback(
		(value: number) => {
			setSelectedAction(undefined);
			setSelectedRecord(undefined);
			setSelectedField(undefined);
			if (!NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS.includes(value)) setSelectedWorkspaceId(undefined);
			setSelectedObjectId(value);
		},
		[setSelectedAction, setSelectedField, setSelectedObjectId, setSelectedRecord, setSelectedWorkspaceId]
	);

	const onWorkspaceChange = useCallback(
		(value: SelectOptionId | undefined) => {
			if (value != null) {
				setSelectedUsers([]);
				setSelectedRecord(undefined);
			}
			setSelectedWorkspaceId(value ? Number(value) : undefined);
		},
		[setSelectedWorkspaceId, setSelectedUsers, setSelectedRecord]
	);

	const objectOptions = useNotificationRuleObjectOptions();

	let ruleValueSelector = undefined;
	if (currentSelection === "eventType") ruleValueSelector = <RecordActionValueSelector key={currentSelection} />;
	if (currentSelection === "field") ruleValueSelector = <FieldValueSelector key={currentSelection} />;
	if (currentSelection === "users") ruleValueSelector = <UsersValueSelector key={currentSelection} />;
	if (currentSelection === "record") ruleValueSelector = <RecordValueSelector key={currentSelection} />;
	if (currentSelection === "channels") ruleValueSelector = <ChannelValueSelector key={currentSelection} />;

	return (
		<CreateNotificationRuleFormDialog
			ruleType={NotificationRuleType.Record}
			badge={<RecordUpdateBadge />}
			headerSelect={
				<Select
					onChange={(value) => onObjectIdChange(Number(value))}
					options={objectOptions}
					value={selectedObjectId ? String(selectedObjectId) : undefined}
				/>
			}
		>
			{selectedObjectId && NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS.includes(selectedObjectId) ? (
				<>
					<Section>
						<WorkspaceValueSelector onChange={onWorkspaceChange} />
					</Section>
					<SectionBreak />
				</>
			) : null}
			<Section grow>
				<SelectedPanel>
					<ActionRuleElement type={RuleElementType.when} placeholder="this happens" />
					<UsersRuleElement />
					<RecordRuleElement />
					<DownArrowIcon />
					<ChannelsRuleElement />
				</SelectedPanel>
				<SelectorPanel noSelectionText="this happens">{ruleValueSelector}</SelectorPanel>
			</Section>
		</CreateNotificationRuleFormDialog>
	);
}
