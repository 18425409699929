import { OwnerType } from "../types";
import { OwnerTypeClaimConfig } from "../components/sections";
import { ICONS } from "@salesdesk/salesdesk-ui";

export const recordClaimsSectionProps: OwnerTypeClaimConfig[] = [
	{
		name: "objectRecordView",
		title: "View records",
		description: "Records are instances of objects, like particular Opportunities or Tasks",
		icon: ICONS.eye,
		options: [
			{ id: OwnerType.All, name: "All", description: "Can view all records" },
			{ id: OwnerType.Owner, name: "Owner", description: "Can view records they are owner of" },
			{ id: OwnerType.None, name: "None", description: "Cannot view any records" },
		],
	},
	{
		name: "objectRecordEdit",
		title: "Add and edit records",
		icon: ICONS.simplePencil,
		dependsOn: ["objectRecordView"],
		options: [
			{ id: OwnerType.All, name: "All", description: "Can add records and edit all records" },
			{
				id: OwnerType.Owner,
				name: "Owner",
				description: "Can add records and edit the records they are owner of",
			},
			{ id: OwnerType.None, name: "None", description: "Cannot add or edit any records" },
		],
	},
	{
		name: "objectRecordChangeOwner",
		title: "Change record owner",
		icon: ICONS.user,
		dependsOn: ["objectRecordView"],
		options: [
			{ id: OwnerType.All, name: "All", description: "Can change owner of all records" },
			{
				id: OwnerType.Owner,
				name: "Owner",
				description: "Can change owner of the records they are owner of",
			},
			{ id: OwnerType.None, name: "None", description: "Cannot change owner of any records" },
		],
	},
	{
		name: "objectRecordDelete",
		title: "Delete records",
		icon: ICONS.trash,
		dependsOn: ["objectRecordView"],
		options: [
			{ id: OwnerType.All, name: "All", description: "Can delete all records" },
			{ id: OwnerType.Owner, name: "Owner", description: "Can delete the records they are owner of" },
			{ id: OwnerType.None, name: "None", description: "Cannot delete any records" },
		],
	},
];
