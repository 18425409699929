import { useGetObjectById } from "../../../../hooks";
import { EventObjectFactory } from "./EventObject/EventObjectFactory";
import { EventAction } from "./EventAction";
import { RecordPreviewPopover } from "../../../records";

import { formatDateTime, formatSince } from "@salesdesk/salesdesk-utils";
import { SDEvent } from "@salesdesk/salesdesk-schemas";
import { User } from "../../../users";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";

interface EventMessageProps {
	user?: User;
	event: SDEvent;
	isSystemUser: boolean;
}

export function EventMessage({ user, event, isSystemUser }: EventMessageProps) {
	const { sdObject } = useGetObjectById(event.object_def_id);

	return (
		<div className="text-body-sm inline-block pt-[2px] align-middle">
			{isSystemUser ? null : user ? (
				<RecordPreviewPopover recordId={event.user_id}>
					<span>{user.fullName}</span>
				</RecordPreviewPopover>
			) : (
				<Skeleton className="w-22 inline-flex h-4 align-text-bottom" />
			)}

			{isSystemUser ? (
				<>
					{sdObject?._displayName} <EventAction isSystemUser={isSystemUser} />
				</>
			) : (
				<>
					{" "}
					<EventAction /> <EventObjectFactory />
				</>
			)}
			<span className="text-c_text_placeholder align-[-3px] text-[24px] font-black"> •&nbsp;</span>
			<div
				className="text-c_text_placeholder text-label-xs inline whitespace-normal"
				title={formatDateTime(new Date(event.tstamp))}
			>
				{formatSince(new Date(event.tstamp))}
			</div>
		</div>
	);
}
