import clsx from "clsx";
import { ReactionTooltip } from "./ReactionTooltip";

import { Emoji } from "../../EmojiPicker";
import { useWebPrincipal } from "../../../auth";
import { ReactionType } from "../types";

interface ReactionProps {
	reaction: ReactionType;
	onClick: () => void;
	canReact: boolean;
}

export function Reaction({ reaction, onClick, canReact }: ReactionProps) {
	const principal = useWebPrincipal();

	if (reaction.userRecordIds.length === 0) {
		return null;
	}

	const isActive = principal.UserRecordId != null && reaction.userRecordIds.includes(principal.UserRecordId);

	return (
		<ReactionTooltip reaction={reaction}>
			<button
				key={reaction.emoji}
				className={clsx(
					"flex h-6 items-center gap-1 rounded-full border px-1 py-px text-sm",
					canReact && "active:bg-c_bg_02 active:border-c_action_01",
					isActive
						? `bg-c_bg_03 ${canReact ? "hover:bg-c_bg_02" : ""} border-c_action_01`
						: `bg-c_bg_01 ${canReact ? "hover:bg-c_bg_03" : ""} border-c_border_regular`,
					"focus-visible:ring-c_action_focus focus-visible:bg-c_bg_03 focus-visible:ring",
					!canReact && "cursor-not-allowed"
				)}
				onClick={canReact ? onClick : undefined}
				disabled={!canReact}
			>
				<div className="sr-only">Add {reaction.emoji} reaction</div>
				<Emoji native={reaction.emoji} />
				<div className="text-body-sm px-1 leading-4">{reaction.userRecordIds.length}</div>
			</button>
		</ReactionTooltip>
	);
}
