import { KeyboardEvent } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { FIELD_CREATION_TYPE } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { IconPicker, ColorPicker, SelectOptionId } from "../../../../inputs";
import { OptionBuilderOption } from "../../../types";
import { OptionTextInput } from "./OptionTextInput";
import { EditFieldProps } from "../../../types";
import { Divider } from "../../../../../components/Divider/Divider";

interface OptionBuilderItemProps extends EditFieldProps {
	optionIndex: number;
	value: OptionBuilderOption;
	onChange: (newOptionDetails: OptionBuilderOption) => void;
	onEnterPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
	onRemoveOption: (optionId: SelectOptionId) => void;
	focusedOptionInputIndex: number;
	setFocusedOptionInputIndex: (index: number) => void;
}

export function OptionBuilderItem({
	value,
	onChange,
	optionIndex,
	disabled,
	onEnterPress,
	ariaAttributes,
	focusedOptionInputIndex,
	onRemoveOption,
	setFocusedOptionInputIndex,
}: OptionBuilderItemProps) {
	const isSystemType = value.creationType === FIELD_CREATION_TYPE.SYSTEM;
	const isOptionNameEmpty = !value.name.length;

	return (
		<div className="flex w-full flex-col gap-2">
			<div className="flex w-full gap-2">
				<OptionTextInput
					hasError={isOptionNameEmpty}
					onChange={(name) => onChange({ ...value, name })}
					value={value.name}
					disabled={isSystemType || disabled}
					placeholder="Enter new option name"
					{...ariaAttributes}
					onKeyDown={isOptionNameEmpty ? undefined : onEnterPress}
					isFocused={optionIndex === focusedOptionInputIndex}
					icon={isSystemType || disabled ? ICONS.lock : undefined}
					onFocus={() => setFocusedOptionInputIndex(optionIndex)}
				/>
				<Button
					type="button"
					onClick={() => onRemoveOption(value.id)}
					disabled={isSystemType || disabled}
					variant={isOptionNameEmpty ? "danger_text" : "text"}
					startIcon={ICONS.trash}
				/>
			</div>
			{isOptionNameEmpty && <span className="text-body-sm text-c_danger_01">Add name for this option</span>}
			<div className="flex gap-3 p-0.5">
				<IconPicker
					placeholder="Icon"
					onChange={(icon) => onChange({ ...value, icon: icon })}
					disabled={disabled}
					value={value.icon}
				/>
				<ColorPicker
					placeholder="Colour"
					onChange={(colorCode) => onChange({ ...value, color: colorCode })}
					disabled={disabled}
					value={value.color}
				/>
			</div>
			<Divider className="mt-2 w-full" />
		</div>
	);
}
