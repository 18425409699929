import { compareAsc, compareDesc } from "date-fns";
import { CalendarEntry } from "../types";

// TODO: Formalise how this is determined to enable responsive calendar sizing in future
export const DATE_COLUMN_TOP_PADDING = 39.5;

export const CURRENT_TIME_INDICATOR_ID = "current-time-indicator";

export function sortCalendarEntries(entries: CalendarEntry[]): CalendarEntry[] {
	return entries.sort((entryA, entryB) => {
		const orderByStart = compareAsc(entryA.start, entryB.start);

		// If equal we sort by entry end in descending order
		if (orderByStart === 0) {
			return compareDesc(entryA.end ?? entryA.start, entryB.end ?? entryB.start);
		}

		return orderByStart;
	});
}
