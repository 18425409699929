import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Combobox } from "@headlessui/react";

import { SelectOptionItem } from "../BaseSelect";
import { SelectOption, SelectOptionId } from "../../../../types";
import { Searchbar } from "../../../Searchbar";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { debounce } from "../../../../../../utils";
import { Button, Spinner } from "@salesdesk/daisy-ui";

import { StaticTypeaheadSelectProps } from "../../types";
import { isInteger } from "@salesdesk/salesdesk-utils";

const DEBOUNCE_DELAY_MS = 300;

export function StaticTypeaheadSelect({
	selectOptions: argSelectedOptions,
	getAsyncSelectOptions,
	value,
	onChange,
	optionDisplayVariant,
	placeholderOption,
	multiple,
	hideIcon,
	hideSearchbar,
}: StaticTypeaheadSelectProps) {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [selectOptions, setSelectOptions] = useState<SelectOption[] | undefined>(argSelectedOptions);
	const [isLoadingOptions, setIsLoadingOptions] = useState(!!getAsyncSelectOptions);

	const selectOptionCache = useRef(new Map<SelectOptionId, SelectOption>());

	const isAsync = Boolean(getAsyncSelectOptions);

	const onQueryChange = useMemo(
		() =>
			debounce((query: string) => {
				if (!getAsyncSelectOptions) return;
				setIsLoadingOptions(true);
				getAsyncSelectOptions(query).then((options) => {
					setSelectOptions(options);
					setIsLoadingOptions(false);
				});
			}, DEBOUNCE_DELAY_MS),
		[getAsyncSelectOptions]
	);

	useEffect(() => {
		onQueryChange("");
	}, [onQueryChange]);

	const filteredOptions = useMemo(() => {
		const options = [];
		if (placeholderOption) {
			options.push(placeholderOption);
		}
		for (const option of argSelectedOptions ?? selectOptions ?? []) {
			if (!isAsync && searchQuery && !option.name.toLowerCase().includes(searchQuery.toLowerCase())) {
				continue;
			}
			if (hideIcon) {
				delete option.icon;
			}
			options.push(option);
		}
		return options;
	}, [argSelectedOptions, hideIcon, isAsync, placeholderOption, searchQuery, selectOptions]);

	useEffect(() => {
		if (selectOptions) {
			if (placeholderOption) {
				selectOptionCache.current.set(placeholderOption.id, placeholderOption);
			}
			for (const option of selectOptions) {
				selectOptionCache.current.set(option.id, option);
			}
		}
	}, [placeholderOption, selectOptions]);

	const comboboxValue = useMemo(() => {
		if (multiple) {
			return value.map((option) => (isInteger(option.id) ? Number(option.id) : option.id));
		}
		return value ? [value.id] : [];
	}, [multiple, value]);

	return (
		<Combobox
			value={comboboxValue}
			onChange={(value) => {
				if (multiple) {
					onChange(
						value
							.map((id) => selectOptionCache.current.get(id))
							.filter((option) => option !== undefined) as SelectOption[]
					);
				} else {
					onChange(selectOptionCache.current.get(value[value.length - 1]));
				}
			}}
			multiple
		>
			{() => (
				<div className="flex h-full w-full flex-col justify-stretch">
					<div className={clsx("my-0.5 ml-4 flex justify-stretch gap-1", hideSearchbar ? "hidden" : "")}>
						<div className="grow">
							<Combobox.Input as={Fragment}>
								<Searchbar
									value={searchQuery}
									onChange={(query) => {
										setSearchQuery(query);
										if (isAsync) {
											onQueryChange(query);
										}
									}}
									isCollapsible={false}
									isClearable={true}
									autoFocus
									size={12}
									wrapperClass="w-full"
								/>
							</Combobox.Input>
						</div>
						<Button
							variant="text"
							size="lg"
							startIcon={ICONS.crossCircle}
							iconVariant="fill"
							onClick={() => {
								if (multiple) {
									onChange([]);
								} else {
									onChange(undefined);
								}
								setSearchQuery("");
							}}
						/>
					</div>
					{isLoadingOptions ? (
						<div className="flex w-full justify-center px-6 py-3">
							<Spinner />
						</div>
					) : filteredOptions?.length ? (
						<div className="grow cursor-pointer overflow-y-auto">
							<Combobox.Options static>
								{filteredOptions.map((option) => (
									<Combobox.Option key={option.id} value={option.id} disabled={option.disabled}>
										{({ selected, active }) => (
											<SelectOptionItem
												withinPopover
												selected={selected}
												active={active}
												option={option}
												variant={optionDisplayVariant}
												isMultiselect={multiple}
												disabled={option.disabled}
											/>
										)}
									</Combobox.Option>
								))}
							</Combobox.Options>
						</div>
					) : (
						<div className="text-c_text_placeholder text-body-sm flex w-full justify-center px-6 py-3">
							No results found.
						</div>
					)}
				</div>
			)}
		</Combobox>
	);
}
