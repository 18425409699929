import { isEmpty, sortByIds, stringContainsMatch } from "@salesdesk/salesdesk-utils";

import { formatOptionFieldValue, mFieldDataChangeEvent } from "../utils";
import { mFieldDef } from "../field_def";

export class mOptionFieldDef extends mFieldDef {
	// This field can either load options from a backing
	// store if one is provided or from its own internal
	// store of values (this._optionValues)

	_optionValues: any[];
	_backingStoreName: string | undefined;
	_backingStore: any;
	_backingStorePromise: any;

	constructor(id: string | number, backingStoreName?: string) {
		super(id);

		// Available options to choose from.
		// This only applies to the option fields that do not have a
		// backing store.

		this._optionValues = [];

		this._backingStoreName = backingStoreName;

		this._backingStore = null;
		this._backingStorePromise = null;

		this.initBackingStore();
	}

	get optionValues() {
		if (this.isBackingStore()) {
			throw Error(`This field uses a backing store to load values`);
		}

		return this._optionValues;
	}

	set optionValues(values) {
		if (this.isBackingStore()) {
			throw Error(`This field uses a backing store to load values`);
		}

		const before = this._optionValues;

		this._optionValues = [];

		// If we're not using a backing store, we use the
		// value as the id and the name

		values.forEach((value) => {
			this._optionValues.push(formatOptionFieldValue(value));
		});

		const after = this._optionValues;

		if (before != after) {
			this.fireEvent(mFieldDataChangeEvent.type.FIELD_OPTION_VALUES_UPDATED, { before: before, after: after }, this);
		}
	}

	get backingStore() {
		return this._backingStore;
	}

	set backingStore(backingStore) {
		this._backingStore = backingStore;
	}

	set backingStoreName(backingStoreName) {
		this._backingStoreName = backingStoreName;
		this.initBackingStore();
	}

	get backingStoreName() {
		return this._backingStoreName;
	}

	override isBackingStore() {
		return !isEmpty(this._backingStoreName);
	}

	override isOptionType() {
		return true;
	}

	// Whether the field links to an object type
	hasObjectDef() {
		return false;
	}

	// TODO: Dynamic import due to circular dependency caused by some backing stores requiring
	// the dataservice which imports the field factory.
	//
	// Ideally we should pass the backing store to the option field instead of generating it
	// from within the field itself. (Or we separate the data service logic which the backing store
	// relies on if possible)
	async initBackingStore() {
		if (this.isBackingStore()) {
			this._backingStore = null;

			const module = await import("./backing_store");
			this._backingStore = module.mBackingStoreFactory.newInstance(this._backingStoreName);
		}
	}

	// TODO: initialising the backingstore if unset for all backing store actions
	// is required due to a current issue with how fields are deep cloned by objects
	// when opened in a form to edit them, etc. Because the dynamic import above takes a
	// non-zero amount of time the field might get deep copied before its backing
	// store has been initialised.
	//
	// Ideally field definitions should not be deep copied like this and should actually be shallow
	// copied when opening forms, etc. We have discussed rewriting the copying logic for objects &
	// fields within them to be more intelligent/flexible than just a direct deep copy.
	async initBackingStoreIfUnset() {
		if (this._backingStore === null && !this._backingStorePromise) {
			this._backingStorePromise = this.initBackingStore();
		}

		await this._backingStorePromise;
		this._backingStorePromise = null;
	}

	async getOptionValueByIdFromBackingStore(id: any, callback: any, formatResults?: any) {
		await this.initBackingStoreIfUnset();
		this._backingStore.getOptionValueById(id, callback, formatResults);
	}

	async getOptionValuesByIdFromBackingStore(ids: any[], callback: any, formatResults?: any) {
		await this.initBackingStoreIfUnset();
		this._backingStore.getOptionValuesById(ids, callback, formatResults);
	}

	async getOptionValuesByQueryFromBackingStore(query: any, callback: any) {
		await this.initBackingStoreIfUnset();
		this._backingStore.getOptionValuesByQuery(query, this, callback);
	}

	override supportsDefaultValue() {
		return !this.isBackingStore();
	}

	/*
		Combination of original getOptionValueById and getOptionValuesById,
		can take a single ID or a list of IDs. Returns a single result if given a
		single ID Returns an array of results if given an array of IDs.

		id: SelectOptionId | SelectOptionId[]
		callback: (error: Error, results: any) => void

		(Note: In future results should be type SelectOption[] but currently uncertain of all possible return types)

		Note: `formatResults` param only used as old forms expect the backing store to return the whole object.
		This logic should be removed once we've transitioned to the new react forms.
	 */
	getOptionValueById(id: any, callback?: any, formatResults?: any) {
		const isIdArray = Array.isArray(id);

		if (this.isBackingStore()) {
			if (isIdArray) {
				this.getOptionValuesByIdFromBackingStore(id, (err: any, data: any) => callback(err, data), formatResults);
			} else {
				this.getOptionValueByIdFromBackingStore(id, callback, formatResults);
			}

			return;
		}

		const ids = new Set(isIdArray ? id : [id]);
		const totalIds = ids.size;
		const optionValues = [];

		for (let i = 0; this._optionValues.length > i; i++) {
			const currentOption = this._optionValues[i];

			if (ids.has(currentOption.id)) {
				optionValues.push(currentOption);
				ids.delete(currentOption.id);
			}

			if (ids.size === 0) {
				break;
			}
		}

		if (optionValues.length !== totalIds) {
			const errorMessage = isIdArray
				? `The option value with id: ${id} could not be found`
				: `Some or all of the option values could not be found`;

			if (callback) {
				callback(errorMessage, null);
			} else {
				throw new Error(errorMessage);
			}

			return;
		}

		const values = isIdArray ? sortByIds(optionValues, id) : optionValues[0];

		if (callback) {
			callback(null, values);
		} else {
			return values;
		}
	}

	// Gets the option value objects from the ids provided.

	getOptionValuesById(ids: any[], callback?: any) {
		// If we have a backing store, pass this request to it.
		if (this.isBackingStore()) {
			this.getOptionValuesByIdFromBackingStore(ids, (err: any, data: any) => callback(err, data));
		} else {
			const optionValues: any[] = [];

			if (ids) {
				for (let i = 0; ids.length > i; i++) {
					for (let j = 0; this._optionValues.length > j; j++) {
						if (this._optionValues[j].id === ids[i]) {
							optionValues.push(this._optionValues[j]);
						}
					}
				}

				if (ids.length === optionValues.length) {
					if (callback) {
						callback(null, optionValues);
					} else {
						return optionValues;
					}
				} else {
					if (callback) {
						callback(`Some or all of the option values could not be found`, null);
					} else {
						throw Error(`Some or all of the option values could not be found`);
					}
				}
			} else {
				if (callback) {
					callback(null, optionValues);
				} else {
					return optionValues;
				}
			}
		}
		return [];
	}

	// Gets the option value object from the names provided.

	getOptionValueByName(name: string, callback?: any) {
		// If we have a backing store, pass this request to it.
		if (this.isBackingStore()) {
			this.getOptionValuesByQueryFromBackingStore(name, (err: any, data: any) => {
				// Return the first one we find
				if (!isEmpty(data)) {
					callback(err, data[0]);
				} else {
					callback(err, []);
				}
			});
		} else {
			for (let i = 0; this._optionValues.length > i; i++) {
				const option = this._optionValues[i];

				if (option.name == name) {
					return option;
				}
			}
		}

		throw Error(`Option with name ${name} cannot be found in the list of options.`);
	}

	// Gets the option value object from the names provided.

	getOptionValuesByName(names: string[], callback: any) {
		// If we have a backing store, pass this request to it.
		if (this.isBackingStore()) {
			this.getOptionValuesByQueryFromBackingStore(names, (err: any, data: any) => {
				callback(err, data);
			});
		} else {
			const optionValues = [];

			for (let i = 0; names.length > i; i++) {
				for (let j = 0; this.optionValues.length > j; j++) {
					if (this.optionValues[j].name === names[i]) {
						optionValues.push(this.optionValues[j]);
						break;
					}
				}
			}

			if (names.length === optionValues.length) {
				return optionValues;
			} else {
				throw Error(`Some or all of the option values could not be found`);
			}
		}

		throw Error(`Options with names ${names} cannot be found in the list of options.`);
	}

	// Get option values corresponding to a filter (string).
	// The filter is used for typeahead field types.
	getOptionValuesByQuery(filter: any, callback: any) {
		// If we have a backing store, pass this request to it.
		if (this.isBackingStore()) {
			this.getOptionValuesByQueryFromBackingStore(filter, callback);
		} else {
			// If no match filter is provided, then return all option values.
			if (isEmpty(filter)) {
				callback(null, this.optionValues);
				return;
			}

			callback(
				null,
				this.optionValues.filter((option) => stringContainsMatch(option.name, filter))
			);
		}
	}

	override validateFields() {
		super.validateFields();

		if (!this.isBackingStore() && isEmpty(this._optionValues)) {
			throw Error(`Cannot validate as the option fields values are not set in field "${this.name}"`);
		}

		// Check the option values all have names

		for (let i = 0; this._optionValues.length > i; i++) {
			const optionValue = this._optionValues[i];
			if (!optionValue.name) {
				throw Error(`Not all of the option values have names in "${this.name}"`);
			}
		}
	}

	get groupInfo() {
		return this.optionValues;
	}

	override unmarshall(skipValidation = false) {
		const clone = super.unmarshall(skipValidation);
		delete clone._backingStoreName;
		delete clone._backingStore;
		delete clone._backingStorePromise;

		return clone;
	}

	override marshall(data: any, parent: any) {
		const boundedObj = super.marshall(data, parent);

		this.initBackingStore();

		return boundedObj;
	}
}
