import { Button, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface ErrorViewProps {
	message: string;
	onRetry?: () => void;
	retryButtonText?: string;
}

export function ChimeChannelErrorView({ message, onRetry, retryButtonText = "Retry" }: ErrorViewProps) {
	return (
		<div className="text-c_danger_02 text-label flex h-full w-full flex-col items-center justify-center gap-2">
			<div className="flex max-w-[280px] flex-col items-center justify-center gap-2 text-center">
				<Icon size="xl" icon={ICONS.warning} />
				{message}
			</div>
			{onRetry && (
				<Button variant="danger_text" startIcon={ICONS.arrowCounterClockwise} size="sm" onClick={onRetry}>
					{retryButtonText}
				</Button>
			)}
		</div>
	);
}
