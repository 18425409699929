import clsx from "clsx";

import { FileFieldType } from "../types";
import { DisplayFieldVariant } from "../../../../types";
import { ThemableSvgLoader, ThemableSvgName } from "../../../../../graphics/ThemableSvgLoader";

interface DisplayFileFieldPlaceholderProps {
	variant?: DisplayFieldVariant;
	placeholderFileFieldType?: FileFieldType;
}

export function DisplayFileFieldPlaceholder({ variant, placeholderFileFieldType }: DisplayFileFieldPlaceholderProps) {
	let placeholderSvgName: ThemableSvgName;
	if (placeholderFileFieldType === "image") {
		placeholderSvgName = "file_image_placeholder";
	} else if (placeholderFileFieldType === "video") {
		placeholderSvgName = "file_video_placeholder";
	} else {
		placeholderSvgName = "file_default_placeholder";
	}

	return (
		<div
			className={clsx(
				"bg-c_bg_04 flex aspect-video w-full flex-col items-center justify-center overflow-hidden border",
				variant === DisplayFieldVariant.workspaceOverview
					? "rounded-card border-transparent"
					: "border-c_border_regular"
			)}
		>
			<div className={variant === DisplayFieldVariant.workspaceOverview ? "size-24" : "h-[52px] w-11"}>
				<ThemableSvgLoader name={placeholderSvgName} />
			</div>
		</div>
	);
}
