import { useMemo } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetTenantSettingsQuery } from "../../../tenant";
import { SettingsContainer } from "../../SettingsContainer";
import { AccountForm } from "./AccountForm";
import { AccountFormSkeleton } from "./AccountFormSkeleton";

export function AccountPage() {
	const breadcrumbs = useMemo(
		() => [
			{
				node: "Settings",
				icon: ICONS.settings,
			},
			{
				node: "Account",
			},
		],
		[]
	);

	const { data: tenantSettings } = useGetTenantSettingsQuery();

	return (
		<SettingsContainer breadcrumbs={breadcrumbs} formPage>
			{tenantSettings ? <AccountForm tenantSettings={tenantSettings} /> : <AccountFormSkeleton />}
		</SettingsContainer>
	);
}
