import clsx from "clsx";

type DividerVariant = "horizontal" | "vertical";

interface DividerProps {
	variant?: DividerVariant;
	className?: string;
}

export function Divider({ variant = "horizontal", className }: DividerProps) {
	if (variant === "horizontal") {
		return <div className={clsx(className ?? "mx-6 my-1", "bg-c_border_regular h-px shrink-0")} />;
	} else if (variant === "vertical") {
		return <div className={clsx(className ?? "mx-1 h-6", "bg-c_border_regular w-px shrink-0")} />;
	}
}
