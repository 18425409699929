import clsx from "clsx";
import { ReactNode } from "react";

interface TextInputPreviewProps {
	value: string;
	end?: ReactNode;
}

export function TextInputPreview({ value, end }: TextInputPreviewProps) {
	return (
		<div
			className={clsx(
				"bg-c_bg_03 border-c_border_regular rounded-minimal flex w-full items-center border pl-4",
				!end && "py-3 pr-4"
			)}
		>
			<div className="text-c_text_disabled grow">{value}</div>
			{end ? <div className="m-1.5">{end}</div> : null}
		</div>
	);
}
