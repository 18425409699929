import { SDObject, SDRecord, rsr } from "@salesdesk/salesdesk-schemas";

import { InfiniteRecordSearchParams, RECORD_CHANGE_EVENT, RecordChangeEvent, RecordChangeType } from "../types";

// Combines two search results array, updating duplicates with the data from the new results
export function combineSearchResults(previousResults: SDRecord[], newResults: SDRecord[], addToBottom = true) {
	const combinedResults: SDRecord[] = [];
	const recordsToAdd = [...newResults];

	previousResults.forEach((existingRecord) => {
		const updatedRecordIndex = recordsToAdd.findIndex((newRecord) => newRecord._id === existingRecord._id);

		if (updatedRecordIndex === -1) {
			combinedResults.push(existingRecord);
			return;
		}

		combinedResults.push(recordsToAdd[updatedRecordIndex]);
		recordsToAdd.splice(updatedRecordIndex, 1);
	});

	if (addToBottom) {
		return combinedResults.concat(recordsToAdd);
	} else {
		return recordsToAdd.concat(combinedResults);
	}
}

export function filterOutSearchResults(results: SDRecord[], recordIds: SDRecord["_id"][]) {
	return results.filter((record) => !recordIds.includes(record._id));
}

export function updateSearchResultsInPlace(results: SDRecord[], updatedRecords: SDRecord[]) {
	const updatedResults: SDRecord[] = [];

	results.forEach((record) => {
		const updatedRecord = updatedRecords.find((updated) => updated._id === record._id);
		updatedResults.push(updatedRecord || record);
	});

	return updatedResults;
}

export function addSDObjectFilterToSearchParams(
	searchParams: InfiniteRecordSearchParams,
	sdObjectFilter?: SDObject["_id"] | SDObject["_id"][]
) {
	const isArray = Array.isArray(sdObjectFilter);
	if (sdObjectFilter == null || (isArray && !sdObjectFilter.length)) {
		return searchParams;
	}

	const objectIdArray = isArray ? sdObjectFilter : [sdObjectFilter];

	const objectIdFilter = rsr.subQuery();

	objectIdArray.forEach((sdObjectId) => {
		objectIdFilter.or(rsr.baseObjectId(sdObjectId)).or(rsr.equals("_objectDefId", sdObjectId));
	});

	return {
		...searchParams,
		query: { ...searchParams.query, and: [...(searchParams.query.and || []), objectIdFilter.buildSubQuery()] },
	};
}

export function addRecordIdFilterToSearchParams(
	searchParams: InfiniteRecordSearchParams,
	recordIds: SDRecord["_id"][]
) {
	if (!recordIds.length) {
		return searchParams;
	}

	const idFilter = rsr.subQuery().and(rsr.equalsAnyOf("id", recordIds)).buildSubQuery();

	return {
		...searchParams,
		query: { ...searchParams.query, and: [...(searchParams.query.and || []), idFilter] },
	};
}

export function dispatchRecordChangeEvent(
	type: RecordChangeType,
	records: RecordChangeEvent["records"],
	isOptimistic = false
) {
	const event: RecordChangeEvent = {
		type,
		isOptimistic,
		records,
	};

	document.dispatchEvent(new CustomEvent(RECORD_CHANGE_EVENT, { detail: event }));
}

export function getSDRecordsAndIdsFromChangeEvent(event: CustomEvent<RecordChangeEvent>) {
	const { records } = event.detail;

	const recordArray = Array.isArray(records) ? records : [records];

	const isIDArray = typeof recordArray[0] === "number";

	const sdRecords: SDRecord[] = isIDArray ? [] : (recordArray as SDRecord[]);
	const recordIds: SDRecord["_id"][] = isIDArray
		? (recordArray as SDRecord["_id"][])
		: sdRecords.map((record) => record._id);

	return { sdRecords, recordIds, isIDArray };
}
