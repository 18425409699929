import { Link } from "react-router-dom";

import { getTenantPrefix } from "../../../routes";
import { TenantPreview } from "./TenantPreview";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { forwardRef } from "react";
import clsx from "clsx";

interface TenantLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	tenant: string;
	accountLogoUrl?: string;
	accountName: string;
	isInternalUser: boolean;
	hoverStyleOnFocus?: boolean;
}

export const TenantLink = forwardRef<HTMLAnchorElement, TenantLinkProps>(
	({ tenant, accountLogoUrl, accountName, isInternalUser, hoverStyleOnFocus, ...props }, ref) => {
		return (
			<Link
				className={clsx(
					"rounded-card bg-c_bg_01 border-c_border_regular hover:bg-c_bg_03 shadow-card ring-c_action_focus flex w-full items-center border transition-all",
					hoverStyleOnFocus ? "focus-visible:bg-c_bg_03" : "focus-visible:ring"
				)}
				to={getTenantPrefix(tenant)}
				reloadDocument
				ref={ref}
				{...props}
			>
				<TenantPreview accountName={accountName} accountLogoUrl={accountLogoUrl} isInternalUser={isInternalUser} />
				<Icon className="ml-auto mr-3 flex" icon={ICONS.caretRight} size="sm" />
			</Link>
		);
	}
);
