import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { JSONContent } from "@tiptap/core";

import { Button, combineRefs } from "@salesdesk/daisy-ui";
import { Comment, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useToast } from "../../../../Toasts";
import { RichTextInput } from "../../../../inputs";
import { useGetContextWorkspaceId } from "../../../../workspaces";
import { useCreateCommentMutation } from "../api/documentCommentsApi";

interface CommentInputContents {
	jsonContent?: JSONContent;
	textContent?: string;
}

interface SubmitCommentInputProps {
	documentRecordId: SDRecord["_id"] | undefined;
	rootCommentId?: Comment["id"];
	onSubmitSuccess?: (createdComment: Comment) => void;
}

export const SubmitCommentInput = forwardRef<HTMLDivElement, SubmitCommentInputProps>(
	({ documentRecordId, rootCommentId, onSubmitSuccess }, ref) => {
		const toast = useToast();

		const [content, setContent] = useState<CommentInputContents>();
		const [hasError, setHasError] = useState(false);

		const workspaceContextId = useGetContextWorkspaceId();

		const [createComment, { isLoading: isCreating }] = useCreateCommentMutation();

		const isEmpty = !content?.textContent || content.textContent.trim() === "";
		const { jsonContent } = content ?? {};

		const submitComment = useCallback(() => {
			if (isEmpty || !jsonContent || documentRecordId == null) return;

			createComment({
				workspaceId: workspaceContextId,
				recordId: documentRecordId,
				comment: {
					message: JSON.stringify(jsonContent),
					rootCommentId,
				},
			})
				.unwrap()
				.then((createdComment) => {
					setHasError(false);
					onSubmitSuccess?.(createdComment);

					toast.triggerMessage({ type: "success", messageKey: "comment_created" });
				})
				.catch(() => {
					setHasError(true);
					toast.triggerMessage({ type: "error", messageKey: "comment_created" });
				});
		}, [
			createComment,
			documentRecordId,
			isEmpty,
			jsonContent,
			onSubmitSuccess,
			rootCommentId,
			toast,
			workspaceContextId,
		]);

		const innerRef = useRef<HTMLDivElement>(null);
		useEffect(() => {
			const inputElement = innerRef.current;
			if (!inputElement) return;
			const handleKeyDown = (e: KeyboardEvent) => {
				if (e.key === "Enter" && e.metaKey) {
					e.preventDefault();
					submitComment();
				}
			};
			inputElement.addEventListener("keydown", handleKeyDown);
			return () => {
				inputElement.removeEventListener("keydown", handleKeyDown);
			};
		}, [submitComment]);

		return (
			<RichTextInput
				ref={combineRefs([ref, innerRef])}
				onChange={(jsonContent, textContent) => {
					setContent({ jsonContent, textContent });
				}}
				hideToolbar
				placeholder={rootCommentId !== undefined ? "Add reply" : "Add comment"}
				hasError={hasError}
				disabled={isCreating}
				value={jsonContent}
				onKeyDown={(e) => {
					e.stopPropagation();
				}}
				disableModEnter
				enableMentions
				heightClassName="max-h-28"
				bottomPanelOptions={{
					showEmojis: true,
					showMentions: true,
					rightComponent: (
						<Button size="sm" onClick={submitComment} disabled={isEmpty} isLoading={isCreating}>
							Submit
						</Button>
					),
				}}
			/>
		);
	}
);
