import { addWeeks, subWeeks } from "date-fns";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Spinner } from "@salesdesk/daisy-ui";

import { Sticky } from "../../../../../../components/Sticky";
import { useBoardPropOnChangeContext } from "../../../../hooks/useBoardPropOnChangeContext";

interface CalendarTopBarProps {
	calendarDate: Date;
	heading: string;

	isLoading?: boolean;
}

export function CalendarTopBar({ calendarDate, heading, isLoading }: CalendarTopBarProps) {
	const boardPropOnChange = useBoardPropOnChangeContext();

	return (
		<Sticky priority={3} height={54} className="bg-c_bg_01 z-20 h-[54px] items-center">
			<div className="bg-c_bg_04 rounded-minimal mr-6 flex px-6 py-2">
				<div className="text-label flex items-center gap-2">
					<Button
						size="sm"
						variant="secondary"
						onClick={() => {
							boardPropOnChange("date", new Date().getTime());
						}}
					>
						Today
					</Button>
					<Button
						size="sm"
						startIcon={ICONS.caretLeft}
						variant="text"
						onClick={() => {
							boardPropOnChange("date", subWeeks(calendarDate, 1).getTime());
						}}
					/>
					<Button
						size="sm"
						startIcon={ICONS.caretRight}
						variant="text"
						onClick={() => {
							boardPropOnChange("date", addWeeks(calendarDate, 1).getTime());
						}}
					/>
					{heading}
					{isLoading ? (
						<div className="ml-2">
							<Spinner size="sm" />
						</div>
					) : null}
				</div>
				<div className="text-body-sm ml-auto flex items-center gap-2">
					View:
					<Button variant="outlined" size="sm" disabled endIcon={ICONS.caretDown}>
						Week
					</Button>
				</div>
			</div>
		</Sticky>
	);
}
