import { lazy, Suspense } from "react";

function lazyImportWithCatch(path: string) {
	return lazy(() =>
		import(/* @vite-ignore */ path).catch((error) => {
			console.error(`Failed to load module at ${path}:`, error);
			return { default: () => null };
		})
	);
}

const CustomSvgs: { [key: string]: React.FC } = {
	file_default_placeholder: lazyImportWithCatch("./FileIcons/FileDefaultPlaceholderSvg"),
	file_image_placeholder: lazyImportWithCatch("./FileIcons/FileImagePlaceholderSvg"),
	file_video_placeholder: lazyImportWithCatch("./FileIcons/FileVideoPlaceholderSvg"),
	file_audio: lazyImportWithCatch("./FileIcons/FileAudioSvg"),
	file_default: lazyImportWithCatch("./FileIcons/FileDefaultSvg"),
	file_image: lazyImportWithCatch("./FileIcons/FileImageSvg"),
	file_pdf: lazyImportWithCatch("./FileIcons/FilePdfSvg"),
	file_presentation: lazyImportWithCatch("./FileIcons/FilePresentationSvg"),
	file_spreadsheet: lazyImportWithCatch("./FileIcons/FileSpreadsheetSvg"),
	file_text: lazyImportWithCatch("./FileIcons/FileTextSvg"),
	file_video: lazyImportWithCatch("./FileIcons/FileVideoSvg"),
	file_zip: lazyImportWithCatch("./FileIcons/FileZipSvg"),

	products_graphic: lazyImportWithCatch("./Graphics/ProductsGraphicSvg"),
	chat_graphic: lazyImportWithCatch("./Graphics/ChatGraphicSvg"),
};

interface ThemableSvgLoaderProps {
	name: keyof typeof CustomSvgs;
}

export function ThemableSvgLoader({ name }: ThemableSvgLoaderProps) {
	const IconComponent = CustomSvgs[name];

	if (!IconComponent) {
		return null;
	}

	return (
		<Suspense fallback={null}>
			<IconComponent />
		</Suspense>
	);
}
