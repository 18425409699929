import { mSystemObjectDef } from "../system_object_def";
import { USER_IDS } from "../user";

export class mContactDef extends mSystemObjectDef {
	static CLASS_NAME = "mContactDef";
	static NAME = "Contact";
	static PLURAL_NAME = "Contacts";
	static ICON = "ph-users";

	static ID = USER_IDS.SALESDESK_CONTACT;
	
	constructor(ownerId) {
		super(mContactDef.ID, ownerId);
	}
}
