import { RECORD_SIDE_PANEL_RECORD_ID_PARAM } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

export interface RecordDetailViewState {
	[RECORD_SIDE_PANEL_RECORD_ID_PARAM]?: number;
	topLevelTab: number;
	tab: number;
	transcriptQuery?: string;
}

export enum RecordViewTopLevelTab {
	doc = "Doc",
	recordDetails = "Record details",
	analytics = "Analytics",
}

export const topLevelTabIcons: Record<RecordViewTopLevelTab, string> = {
	[RecordViewTopLevelTab.doc]: ICONS.file,
	[RecordViewTopLevelTab.recordDetails]: ICONS.listDashes,
	[RecordViewTopLevelTab.analytics]: ICONS.reports,
};
