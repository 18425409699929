import { asConst } from "json-schema-to-ts";
import { recordIdSchema } from "./properties";
import { sdRecordPatchRequestSchema } from "./sdRecordPatchRequestSchema";

export const sdRecordBatchPatchRequestSchema = asConst({
	title: "SDRecordBatchPatchRequest",
	description: "Defines a record schema used for batch patching requests",
	type: "object",
	additionalProperties: false,
	required: ["_id"],
	// TODO:  Add in when AWS supported schema supports it
	// minProperties: 2,
	properties: {
		_id: recordIdSchema,
		...sdRecordPatchRequestSchema.properties,
	},
});
