import clsx from "clsx";

import { DisplayRichTextField } from "../../../../fields";
import { isJsonContent } from "../../../../../utils";

interface TipTapContentProps {
	content: string;
	deleted?: boolean;
}

export function TipTapContent({ content, deleted = false }: TipTapContentProps) {
	return (
		<div className={clsx("text-body-sm", deleted && "text-c_text_disabled line-through")}>
			{isJsonContent(content) ? <DisplayRichTextField value={JSON.parse(content)} enableMentions /> : content}
		</div>
	);
}
