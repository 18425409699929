import { AlertDialog } from "./AlertDialog";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { ReactNode } from "react";
import { Button } from "@salesdesk/daisy-ui";

interface DeleteConfirmationDialogProps {
	title: string;
	description?: string;
	open: boolean;
	children: ReactNode;
	isDeleting?: boolean;
	onDismiss: () => void;
	onConfirm: () => void;
	buttonLabel?: string;
	destructivePrompt?: string;
	destructiveLabel?: string;
	width?: number;
}

export function DeleteConfirmationDialog({
	title,
	description,
	open,
	children,
	onConfirm,
	onDismiss,
	isDeleting = false,
	buttonLabel = "Delete",
	destructivePrompt,
	destructiveLabel,
	width,
}: DeleteConfirmationDialogProps) {
	return (
		<AlertDialog
			title={title}
			description={description}
			open={open}
			message={children}
			onOpenChange={(open) => {
				if (!open) {
					onDismiss();
				}
			}}
			destructivePrompt={destructivePrompt}
			destructiveLabel={destructiveLabel}
			width={width}
		>
			{({ isConfirmDisabled }) => (
				<div className="flex items-center justify-end gap-3">
					<Button
						isLoading={isDeleting}
						disabled={isConfirmDisabled}
						onClick={onConfirm}
						startIcon={ICONS.trash}
						variant="danger"
						size="lg"
					>
						{buttonLabel}
					</Button>
					<Button size="lg" onClick={() => onDismiss()}>
						Cancel
					</Button>
				</div>
			)}
		</AlertDialog>
	);
}
