import React, { HTMLProps } from "react";
import { FloatingArrow, FloatingFocusManager, FloatingPortal, useMergeRefs } from "@floating-ui/react";

import { usePopoverContext } from "./Popover";

interface PopoverContentProps extends HTMLProps<HTMLDivElement> {
	initialFocus?: number | React.MutableRefObject<HTMLElement | null>;
	returnFocus?: boolean;
}

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
	({ initialFocus, returnFocus, ...divProps }, propRef) => {
		const { context: floatingContext, ...context } = usePopoverContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		if (!floatingContext.open && !context.keepPopoverMounted) return null;

		const floatingContent = (
			<FloatingFocusManager
				context={floatingContext}
				modal={context.modal}
				returnFocus={returnFocus}
				initialFocus={initialFocus}
			>
				<div
					ref={ref}
					style={{
						...context.floatingStyles,
						...divProps.style,
					}}
					className={`z-[100] ${floatingContext.open || !context.hideWhenClosedButMounted ? "" : "hidden"}`}
					{...context.getFloatingProps(divProps)}
				>
					<FloatingArrow ref={context.arrowRef} context={floatingContext} {...context.arrowOptions} />
					{divProps.children}
				</div>
			</FloatingFocusManager>
		);

		if (context.useFloatingPortal) {
			return <FloatingPortal>{floatingContent}</FloatingPortal>;
		}

		return floatingContent;
	}
);
