import { Skeleton } from "../../../components/Skeleton/Skeleton";

interface EventListLoadingProps {
	numberOfSkeletonEvents?: number;
}

export function EventListLoading({ numberOfSkeletonEvents = 4 }: EventListLoadingProps) {
	return (
		<div className="mb-3 flex w-full shrink-0 flex-col gap-3">
			{[...Array(numberOfSkeletonEvents)].map((_, index) => (
				<div key={index} className="flex flex-col gap-3 pt-0.5">
					<div className="flex gap-2">
						<Skeleton className="size-6 shrink-0" />
						<div className="flex w-full flex-col gap-2">
							<Skeleton className="h-6 w-5/6" />
							<Skeleton className="h-6 w-1/3" />
						</div>
					</div>
					{index < numberOfSkeletonEvents - 1 ? <Skeleton className="h-px w-full" /> : null}
				</div>
			))}
		</div>
	);
}
