import { useMemo } from "react";
import clsx from "clsx";

import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { CreateNote, Note, NotesVariant, OnCreateNote, SingleNote } from "../";
import { EditNoteSubmit } from "../";
import { DisplayIf } from "../../../auth";
import { NotesLoading } from "./NotesLoading";

interface NotesProps {
	notes: Note[];
	onEditClick?: (note: Note) => void;
	onDeleteClick?: (note: Note) => void;
	onToggleReaction?: (note: Note, emoji: string) => void;
	onCreateNote: OnCreateNote;
	isCreating: boolean;
	variant?: NotesVariant;
	canReact?: boolean;
	editingNoteId?: string;
	onEditSubmit: EditNoteSubmit;
	isFiltered?: boolean;
	isLoading?: boolean;
}

export function Notes({
	notes,
	onEditClick,
	onDeleteClick,
	onCreateNote,
	onToggleReaction,
	isCreating,
	variant = "default",
	canReact = false,
	editingNoteId,
	onEditSubmit,
	isFiltered,
	isLoading,
}: NotesProps) {
	const orderedNotes = useMemo(() => notes.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()), [notes]);
	const inRightPanel = variant === "RightPanel";

	return (
		<div className="@container/notes h-full max-h-full overflow-hidden">
			<div
				className={clsx(
					"flex h-full w-full flex-col justify-stretch",
					inRightPanel ? "gap-4" : "@xl/notes:flex-row @xl/notes:gap-5 overflow-hidden"
				)}
			>
				<div className={clsx(!inRightPanel && "py-4", "flex grow flex-col gap-4 overflow-auto")}>
					{!isLoading && orderedNotes.length === 0 ? (
						<div className={clsx(inRightPanel ? "my-4" : "mb-4", "text-c_text_placeholder text-body-sm text-center")}>
							{isFiltered
								? "No notes match the applied filters."
								: `There are no notes yet, add one ${inRightPanel ? "below" : ""} using the text field.`}
						</div>
					) : null}
					{isLoading ? (
						<NotesLoading />
					) : (
						orderedNotes.map((note) => (
							<SingleNote
								key={note.id}
								note={note}
								onToggleReaction={onToggleReaction}
								onEditClick={onEditClick}
								onDeleteClick={onDeleteClick}
								// TODO: Dynamically determine size based on container width
								size={inRightPanel ? "md" : "sm"}
								canReact={canReact}
								onEditSubmit={onEditSubmit}
								isEditMode={note.id === editingNoteId}
							/>
						))
					)}
				</div>
				<DisplayIf can={[AbilityAction.Create, AbilitySubject.Note]}>
					<CreateNote onSubmit={onCreateNote} isCreating={isCreating} variant={variant} />
				</DisplayIf>
			</div>
		</div>
	);
}
