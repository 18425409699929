import { PropsWithChildren } from "react";
import clsx from "clsx";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { CompleteMark } from "../../../components/CompleteMark";
import { useToggleDoneCompletableRecord } from "../hooks/useToggleDoneCompletableRecord";

interface CompletableRecordCheckMarkWrapperProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
}

export function CompletableRecordCheckMarkWrapper({
	sdObject,
	sdRecord,
	children,
}: PropsWithChildren<CompletableRecordCheckMarkWrapperProps>) {
	const { markAsDone, markAsToDo, isCompletableRecordDone } = useToggleDoneCompletableRecord(sdRecord, sdObject);

	return (
		<div className="flex items-start gap-2 truncate">
			<div className="flex h-6 flex-col items-start justify-center">
				<CompleteMark value={isCompletableRecordDone} onChange={isCompletableRecordDone ? markAsToDo : markAsDone} />
			</div>
			<div className={clsx(isCompletableRecordDone && "text-c_text_disabled", "truncate")}>{children}</div>
		</div>
	);
}
