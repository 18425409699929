import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../utils";
import { mCountriesBackingStore } from "../backing_store";
import { mSingleOptionFieldDef } from "./single_option_field_def";

export class mCountryFieldDef extends mSingleOptionFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name];
	static override defaultComponentType: FieldComponentName = mCountryFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id, mCountriesBackingStore.TYPE);
		this._componentType = mCountryFieldDef.defaultComponentType;
		this._formatDescription = "Search countries";
		this._icon = FIELD_TYPES.COUNTRY.icon;
		this._supportsDefaultValue = true;
	}

	override get type(): string {
		return FIELD_TYPES.COUNTRY.name;
	}

	override get supportedComponentTypes() {
		return mCountryFieldDef.supportedComponentTypes;
	}

	override supportsMaxLength() {
		return false;
	}
}
