import { useCallback, useMemo, useState } from "react";

import { mAssetDef, mDocDef, mInteractionMeetingDef, mTaskDef, mUserDef } from "@salesdesk/salesdesk-model";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { useObjectAssociations } from "../../../../../../../recordAssociations";
import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";

import { PanelTab, PanelTabList, PanelTabPanels, PanelTabs } from "../../../../../../../../components/PanelTabs";
import { useShowUsersActivities } from "../../../../../../hooks";
import { getFirstName } from "../../../../../../../users";
import { SummaryPanel } from "./SummaryPanel";
import { LinkedRecordsPanel } from "./LinkedRecordsPanel";
import { SingleAssociationPanel } from "./SingleAssociationPanel";
import { ChatHistoryPanel } from "./ChatHistoryPanel";
import { ActivityPanel } from "./ActivityPanel";
import { NotesPanel } from "./NotesPanel";
import { getSDRecordFieldValueByFieldName, getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import clsx from "clsx";

const SINGLE_ASSOCIATION_TAB_IDS = [mTaskDef.ID, mInteractionMeetingDef.ID, mDocDef.ID, mAssetDef.ID];
interface RecordDetailTabsProps {
	currentTab: number;
	onTabChange: (index: number) => void;
	inWorkspace?: boolean;
}

export function RecordDetailTabs({ currentTab, onTabChange, inWorkspace }: RecordDetailTabsProps) {
	const { sdRecord, sdObject, isLoading } = useRecordDetailsContext();

	const {
		objectAssociations: {
			prioritizedAssociations: tabbedAssociations,
			unprioritizedAssociations: groupedAssociations,
			allAssociations,
		},
		isLoading: isLoadingAssociations,
	} = useObjectAssociations(sdRecord?._objectDefId, SINGLE_ASSOCIATION_TAB_IDS);

	const [associationCounts, setAssociationCounts] = useState<Record<number, number>>({});

	const updateAssociationCount = useCallback((associationId: number, count: number) => {
		setAssociationCounts((prevCounts) => ({ ...prevCounts, [associationId]: count }));
	}, []);

	const linkedRecordsCount = useMemo(() => {
		return groupedAssociations.reduce((total, objectAssociation) => {
			return total + (associationCounts[objectAssociation.id] || 0);
		}, 0);
	}, [groupedAssociations, associationCounts]);

	const [notesCount, setNotesCount] = useState(0);
	const [activityCount, setActivityCount] = useState(0);
	const [chatCount, setChatCount] = useState(0);

	const [userActivitiesCount, setUserActivitiesCount] = useState<number>(0);
	const { showUserActivities, showCustomerActivities } = useShowUsersActivities(sdObject, sdRecord);
	const showActivities = showUserActivities || showCustomerActivities;

	const isMeetingRecord = sdObject?._id === mInteractionMeetingDef.ID;

	const userActivityPanelTab = useMemo(
		() =>
			showActivities && sdRecord && sdObject ? (
				<PanelTab count={userActivitiesCount}>{getActivityPanelTitle(sdObject, sdRecord)}</PanelTab>
			) : null,
		[userActivitiesCount, showActivities, sdRecord, sdObject]
	);
	const userActivityPanel = useMemo(
		() =>
			showActivities && sdRecord && sdObject ? (
				<ActivityPanel
					title={getActivityPanelTitle(sdObject, sdRecord)}
					onUpdateCount={setUserActivitiesCount}
					showUsersActivity
				/>
			) : null,
		[showActivities, sdRecord, sdObject]
	);

	const showGroupedAssociations = groupedAssociations.length > 0 && !inWorkspace;

	return (
		<div
			className={clsx(
				"bg-c_bg_04 h-full max-h-full w-full max-w-full overflow-hidden p-5 pb-0 pr-0",
				inWorkspace ? "rounded-t-panel" : "rounded-tl-panel"
			)}
		>
			{sdRecord && !isLoading && !isLoadingAssociations ? (
				<PanelTabs key={sdRecord._id} selectedIndex={currentTab} onChange={onTabChange}>
					<div className="flex h-full max-h-full flex-col">
						<div className={clsx(inWorkspace ? "pr-5" : "pr-8")}>
							<PanelTabList>
								{showUserActivities ? userActivityPanelTab : <PanelTab>Summary</PanelTab>}
								{showGroupedAssociations ? <PanelTab count={linkedRecordsCount}>Linked records</PanelTab> : null}
								{showCustomerActivities ? userActivityPanelTab : null}
								{tabbedAssociations.map((associationDef) => {
									const count = associationCounts[associationDef.id] || 0;

									return (
										<PanelTab key={associationDef.id} count={count}>
											{capitalizeString(associationDef.connectedObject.label)}
										</PanelTab>
									);
								})}
								<PanelTab count={notesCount}>Notes</PanelTab>
								<PanelTab count={activityCount}>History</PanelTab>
								{isMeetingRecord ? <PanelTab count={chatCount}>Chat history</PanelTab> : null}
							</PanelTabList>
						</div>
						<PanelTabPanels className="flex h-full max-h-full overflow-hidden">
							{showUserActivities ? userActivityPanel : <SummaryPanel objectAssociations={allAssociations} />}
							{showGroupedAssociations ? (
								<LinkedRecordsPanel onUpdateCount={updateAssociationCount} objectAssociations={groupedAssociations} />
							) : null}
							{showCustomerActivities ? userActivityPanel : null}
							{tabbedAssociations.map((associationDef) => {
								return (
									<SingleAssociationPanel
										key={associationDef.id}
										objectAssociation={associationDef}
										onUpdateCount={updateAssociationCount}
									/>
								);
							})}
							<NotesPanel onUpdateCount={setNotesCount} />
							<ActivityPanel onUpdateCount={setActivityCount} />
							{isMeetingRecord ? <ChatHistoryPanel onUpdateCount={setChatCount} /> : null}
						</PanelTabPanels>
					</div>
				</PanelTabs>
			) : (
				<div className="border-c_border_regular mr-8 flex h-[39px] border-b">
					{[...Array(5)].map((_, index) => (
						<div key={index} className="px-3 py-1">
							<Skeleton className="w-22 h-6" />
						</div>
					))}
				</div>
			)}
		</div>
	);
}

function getActivityPanelTitle(sdObject: SDObject, sdRecord: SDRecord) {
	let firstName = getSDRecordFieldValueByFieldName(sdObject, sdRecord, mUserDef.FIRST_NAME_FIELD_NAME)?._value as
		| string
		| undefined;

	if (!firstName) {
		firstName = getFirstName(getSDRecordName(sdObject, sdRecord));
	}

	return `${firstName}'s activity`;
}
