import { PropsWithChildren } from "react";

import { VideoCallMediaProvider } from "./VideoCallMediaProvider";
import { VideoCallLogicProvider } from "./VideoCallLogicProvider";
import { VideoCallEventTracker } from "../../VideoCallEvents";

/**
 * TODO: When refactoring away from twilio we should move these contexts into a redux store which is much more appropriate for
 * this kind of state/logic. Decided to keep it as contexts for now to avoid having to do two major refactors (one when implementing
 * minimised video calls and one when moving away from Twilio)
 */
export function VideoCallProvider({ children }: PropsWithChildren) {
	return (
		<VideoCallMediaProvider
			onError={() => {
				/* TODO: Handle Errors*/
			}}
		>
			<VideoCallLogicProvider>
				<VideoCallEventTracker>{children}</VideoCallEventTracker>
			</VideoCallLogicProvider>
		</VideoCallMediaProvider>
	);
}
