import { useCallback } from "react";

import { mAssetDef, mDocDef, mPdfAssetDef } from "@salesdesk/salesdesk-model";
import {
	AbilityAction,
	AbilitySubject,
	getSDRecordName,
	SDObject,
	SDRecord,
	sdSubject,
} from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { MenuItem } from "../../../../../menu";
import { useToast } from "../../../../../Toasts";
import { useConvertDocToPdfAssetMutation } from "../../../../api/recordsApi";
import { PATHS } from "../../../../../../routes";
import { useGetObjectById } from "../../../../../../hooks/useGetObjectById";
import { useWebPrincipal } from "../../../../../../auth";
import { useGetContextWorkspaceId } from "../../../../../workspaces";

export function useDocRecordActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	const principal = useWebPrincipal();
	const [convertDocToPdfAsset, { isLoading }] = useConvertDocToPdfAssetMutation();
	const workspaceId = useGetContextWorkspaceId();

	const toast = useToast();

	const { sdObject: assetObject } = useGetObjectById(mAssetDef.ID);

	const convertToPDF = useCallback(() => {
		if (!sdRecord || !assetObject) return;

		convertDocToPdfAsset({ recordId: sdRecord._id, workspaceId })
			.unwrap()
			.then((newAssetRecord) => {
				const assetPath =
					workspaceId == null
						? PATHS.RECORD_DETAIL_VIEW(newAssetRecord._id, assetObject)
						: PATHS.WORKSPACE_RECORD_DETAILS(workspaceId, newAssetRecord._id, assetObject) || "";

				toast.triggerMessage({
					type: "success",
					messageKey: "doc_record_pdf_generated",
					messageParams: {
						link: {
							type: "link",
							to: assetPath,
							label: getSDRecordName(assetObject, newAssetRecord),
						},
					},
				});
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "doc_record_pdf_generated" });
			});
	}, [sdRecord, convertDocToPdfAsset, assetObject, toast, workspaceId]);

	if (
		mDocDef.ID !== sdObject?._id ||
		!principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: mPdfAssetDef.ID }))
	) {
		return [];
	}

	return [
		{
			onClick: convertToPDF,
			icon: ICONS.pdf,
			text: "Generate PDF",
			loading: isLoading,
			type: "button",
		},
	];
}
