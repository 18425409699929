import { useEffect, useMemo, useRef, useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import clsx from "clsx";

import { Button, combineRefs, Icon, Tooltip } from "@salesdesk/daisy-ui";
import { getSDRecordName, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../../../../auth";
import { EditMultiOptionField } from "../../../../../../fields";
import { UserWithDetails, UserWithDetailsLoading, useUserObjectsMap } from "../../../../../../users";
import { useTeamMemberFieldSettings } from "../../../hooks/useTeamMemberFieldSettings";

interface TeamMemberFieldProps<TFormData extends FieldValues> {
	control: Control<TFormData, unknown>;
	name: Path<TFormData>;
	memberRecords?: SDRecord[];
	canEdit: boolean;
	addingMembers: boolean;
	setAddingMembers: (value: boolean) => void;
	onRemoveUser: (id: SDRecord["_id"], name: string) => void;
}

const EMPTY_MEMBERS_VALUE: number[] = [];

export function TeamMemberField<TFormData extends FieldValues>({
	control,
	name,
	memberRecords,
	canEdit,
	addingMembers,
	setAddingMembers,
	onRemoveUser,
}: TeamMemberFieldProps<TFormData>) {
	const fieldRef = useRef<HTMLButtonElement>(null);
	const userObjectMap = useUserObjectsMap();

	const principal = useWebPrincipal();

	const [tooltipKey, setTooltipKey] = useState(0);

	useEffect(() => {
		if (addingMembers && fieldRef.current) {
			fieldRef.current.focus();
			fieldRef.current.scrollIntoView({ behavior: "instant", block: "center" });
		}
	}, [addingMembers]);

	const memberIds = useMemo(() => memberRecords?.map((record) => record._id) ?? [], [memberRecords]);
	const teamMemberFieldSettings = useTeamMemberFieldSettings(memberIds);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, ref, ...field } }) => {
				return (
					<div className={clsx("flex max-w-full flex-col gap-2 truncate", addingMembers && "pb-16")}>
						<div className="text-c_text_secondary text-label-sm flex items-center gap-1.5 truncate">
							<Icon icon={ICONS.users} size="sm" />
							Team members
						</div>
						<div className="flex w-full max-w-full flex-col gap-2 truncate">
							{memberRecords
								? memberRecords.map((sdRecord) => {
										const userObject = userObjectMap?.get(sdRecord._objectDefId);

										if (!userObject) {
											return <UserWithDetailsLoading key={sdRecord._id} />;
										}

										return (
											<div key={sdRecord._id} className="flex items-start gap-2 truncate">
												<UserWithDetails sdRecord={sdRecord} sdObject={userObject} getStatusFromRecord="POLL" />
												{sdRecord._id !== principal.UserRecordId && canEdit ? (
													<Tooltip key={tooltipKey} text="Remove from conversation">
														<Button
															variant="danger_text"
															size="xs"
															startIcon={ICONS.trash}
															onClick={() => {
																onRemoveUser(sdRecord._id, getSDRecordName(userObject, sdRecord));

																// Incremement the key so the tooltip doesn't open when the remove user modal is closed
																setTooltipKey((key) => key + 1);
															}}
														/>
													</Tooltip>
												) : null}
											</div>
										);
									})
								: [...Array(3)].map((_, index) => <UserWithDetailsLoading key={index} />)}
						</div>
						{memberRecords && canEdit ? (
							addingMembers ? (
								<EditMultiOptionField
									ref={combineRefs([ref, fieldRef])}
									value={Array.isArray(value) ? value : EMPTY_MEMBERS_VALUE}
									{...field}
									optionFieldSettings={teamMemberFieldSettings}
								/>
							) : (
								<Button startIcon={ICONS.plus} variant="primary_text" onClick={() => setAddingMembers(true)} size="sm">
									Add members
								</Button>
							)
						) : null}
					</div>
				);
			}}
		/>
	);
}
