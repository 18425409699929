import { useEffect, useMemo } from "react";
import { useQueryParams } from "use-query-params";

import { OBJECT_DESIGNER_TABS } from "../types";

const defaultTabParam = {
	tab: OBJECT_DESIGNER_TABS.settings,
};

const tabValues = Object.values(OBJECT_DESIGNER_TABS);

export function useObjectDesignerTabParam(isNewObject?: boolean, onTabChange?: (prevTab: string) => void) {
	const [queryParams, setQueryParams] = useQueryParams(defaultTabParam);

	const setCurrentTabIndex = (tabIndex: number) => {
		if (onTabChange) {
			onTabChange(queryParams.tab as string);
		}
		setQueryParams({ tab: tabValues[tabIndex] });
	};

	useEffect(() => {
		if (!queryParams.tab) {
			setQueryParams(defaultTabParam);
		}
	}, [queryParams, setQueryParams]);

	/**
	 * Redirect to 'Setting' tab if page was reloaded during creation
	 * */
	useEffect(() => {
		if (isNewObject) {
			setQueryParams(defaultTabParam);
		}
	}, [isNewObject, setQueryParams]);

	const currentTabIndex = useMemo(() => {
		const { tab } = queryParams;

		if (!tab) return 0;

		return tabValues.indexOf(tab as OBJECT_DESIGNER_TABS);
	}, [queryParams]);

	return { currentTabIndex, setCurrentTabIndex, currentTab: queryParams?.tab, tabValues };
}
