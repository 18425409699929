import { useMemo } from "react";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

import { MenuItem, MenuContents, PopoverMenu } from "../../../../menu";
import { AGGREGATIONS } from "../../../types";
import { GROUPED_VIEWS } from "../../../utils";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import { useBoardAggSelector, useBoardGroupBySelector, useBoardViewSelector } from "../../../store";
import { useBoardPropOnChangeContext } from "../../../hooks/useBoardPropOnChangeContext";
import { BaseBoardControlProps } from "../types";

export function BoardGroupByMenu({ iconOnly }: BaseBoardControlProps) {
	const boardPropOnChange = useBoardPropOnChangeContext();
	const { sdObject, groupableFields, aggregationFields } = useDataboardDetailsContext();

	const boardView = useBoardViewSelector();
	const groupBy = useBoardGroupBySelector();
	const agg = useBoardAggSelector();

	const isCalendarView = boardView === BOARD_VIEW.CALENDAR;
	const groupedView = boardView && GROUPED_VIEWS.includes(boardView);

	const supportsGroupingMenu = isCalendarView || groupedView;

	const menuContents = useMemo(() => {
		const fieldsToGroupBy = isCalendarView ? groupableFields.timeFields : groupableFields.singleOptionFields;
		const fieldsToAggregateBy = isCalendarView ? [] : aggregationFields;

		if (!fieldsToGroupBy.length) {
			return [];
		}

		const sections: MenuContents = [
			{
				sectionTitle: "Group by",
				items: fieldsToGroupBy.map((field) => {
					const fieldId = String(field._id);
					const isSelected = groupBy === fieldId;

					return {
						text: field._displayName,
						icon: field._icon,
						type: "button",
						onClick: () => boardPropOnChange("groupBy", fieldId),
						selected: isSelected,
					} satisfies MenuItem;
				}),
			},
		];

		if (fieldsToAggregateBy?.length) {
			const calculateSectionItems: MenuItem[] = [
				{
					icon: ICONS.cross,
					text: "None",
					type: "button",
					selected: !agg,
					onClick: () => {
						boardPropOnChange("agg", undefined);
					},
				},
			];

			fieldsToAggregateBy.forEach((field) => {
				const fieldId = String(field._id);
				const isFieldSelected = agg?.fieldId === fieldId;
				const selectedAgg = AGGREGATIONS.find((aggregationDetail) => aggregationDetail.key === agg?.agg);

				calculateSectionItems.push({
					text: field._displayName,
					icon: field._icon,
					type: "subMenu",
					selected: isFieldSelected,
					badge: isFieldSelected ? selectedAgg?.shortenedName || selectedAgg?.name : undefined,
					subMenu: AGGREGATIONS.map((aggregationDetails) => {
						return {
							text: aggregationDetails.name,
							type: "button",
							selected: isFieldSelected && selectedAgg?.key === aggregationDetails.key,
							onClick: () => {
								boardPropOnChange("agg", { agg: aggregationDetails.key, fieldId });
							},
						};
					}),
				});
			});

			sections.push({
				sectionTitle: "Calculate",
				items: calculateSectionItems,
			});
		}

		return sections;
	}, [isCalendarView, groupableFields, aggregationFields, boardPropOnChange, groupBy, agg]);

	const disabledTooltipText =
		supportsGroupingMenu && !menuContents.length
			? `${sdObject?._displayName} doesn't have ${
					boardView === BOARD_VIEW.CALENDAR ? "any date/time fields" : "fields which support grouping"
				}`
			: undefined;

	return (
		<Tooltip ignoreDisabled={true} text={disabledTooltipText}>
			<PopoverMenu tooltipText="Group by" placement="bottom-end" menuContents={menuContents}>
				<Button
					startIcon={ICONS.group}
					variant="secondary"
					size="sm"
					disabled={!supportsGroupingMenu || !menuContents.length}
				>
					{iconOnly ? null : "Group"}
				</Button>
			</PopoverMenu>
		</Tooltip>
	);
}
