import { PropsWithChildren } from "react";
import clsx from "clsx";
import { AccessibleButtonDiv } from "../../../../../../../../../components/AccessibleButtonDiv";

interface DisplayRecordTitleFieldProps {
	className?: string;
	onClick?: () => void;
}

export function DisplayRecordTitleField({
	children,
	className,
	onClick,
}: PropsWithChildren<DisplayRecordTitleFieldProps>) {
	return (
		<AccessibleButtonDiv
			onClick={onClick}
			disabled={!onClick}
			className={clsx(
				"text-h3 flex w-full flex-col justify-center p-2",
				className,
				onClick ? "cursor-pointer" : "cursor-default",
				onClick &&
					"focus-visible:border-c_action_01 focus-visible:ring-c_action_focus hover:bg-c_bg_03 rounded-sm focus-visible:ring"
			)}
		>
			{children}
		</AccessibleButtonDiv>
	);
}
