import clsx from "clsx";
import { forwardRef } from "react";

import { WorkspaceCompanyLogo } from "../../../WorkspaceCompanyLogo";
import { CurrentAccountLogo } from "../../../../../tenant";

interface WorkspaceLogoSectionProps {
	isCollapsed?: boolean;
}

export const WorkspaceLogoSection = forwardRef<HTMLDivElement, WorkspaceLogoSectionProps>(({ isCollapsed }, ref) => {
	return (
		<div
			ref={ref}
			className={clsx(
				"bottom-[60px] flex w-full items-center justify-between",
				isCollapsed ? "opacity-0" : "transition-opacity duration-75"
			)}
		>
			<WorkspaceCompanyLogo size="xl" />
			<CurrentAccountLogo size="xl" />
		</div>
	);
});
