import {
	AggregationProperty,
	AllowedTypes,
	EqualsAnyOfClause,
	EqualsClause,
	ExistsClause,
	IncludeAnyOfClause,
	IncludeClause,
	MatchAllClause,
	MatchAllPrefixClause,
	MatchClause,
	MatchPrefixClause,
	RangeClause,
	RangeClauseValue,
	SearchAfter,
	SortBy,
	SortOrder,
} from "../../../search";
import {
	BaseObjectIdClause,
	HasAssociationToRecordClause,
	HasAssociationToAnyRecordClause,
	HasAssociationsToAllRecordsClause,
	HasAssociationToRecordForObjectAssociationClause,
	HasAssociationsToAllRecordsForObjectAssociationClause,
	HasAssociationToAnyRecordForObjectAssociationClause,
	ExistsAssociationForObjectAssociationClause,
	RecordNotClause,
	RecordNotClauses,
	RecordQuery,
	RecordQueryClauses,
	RecordsSearchRequest,
	RecordSubQuery,
	RecordSubQueryClauses,
	RecordSubQueryClause,
	IsSharedWithWorkspaceClause,
	IsSharedWithAnyWorkspaceClause,
} from "../../../records";
import { AssociationSide } from "@salesdesk/salesdesk-model";

// Suppressing because it will all be used in the UI (i.e. so no unnecessary packaging) and it makes using it easier
// with intellisense
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace rsr {
	class RecordsSearchRequestBuilder {
		private readonly request: RecordsSearchRequest = {
			query: { and: [] },
			sort: [],
			aggregations: {},
			highlight: null,
		};

		/*
			The number of results retrieved.  Optional. Default: 10
		 */
		public size = (size: number) => {
			this.request.size = size;
			return this;
		};

		/*
			Used with size for paging.  You provide it with the next page you want results for
		 */
		public from = (from: number) => {
			this.request.from = from;
			return this;
		};

		/*
			Used with size for paging.  You provide it with the contents of searchAfter from the results of the previous query
		 */
		public searchAfter = (searchAfter: SearchAfter) => {
			this.request.searchAfter = searchAfter;
			return this;
		};

		/*
			 Either record property name or a fieldId for the record
		 */
		public sort = (sortBy: SortBy) => {
			this.request.sort?.push(sortBy);
			return this;
		};

		public associationCreationTimeSort = (associatedRecordId: number, order: SortOrder) => {
			// this.request.sortByAssociationCreationTime = sortByAssociation;
			this.request.sort?.push({
				[`allAssociations.${associatedRecordId}.createdAt`]: {
					order: order,
				},
			});
			return this;
		};

		/*
			 Adds an aggregation, with the given name, for the results of the query
		 */
		public aggregate = (aggregationName: string, aggregationProperty: AggregationProperty) => {
			if (this.request.aggregations == null) this.request.aggregations = { aggregationName: aggregationProperty };
			else this.request.aggregations[aggregationName] = aggregationProperty;
			return this;
		};

		/*
			 The query used to filter the results
		 */
		public query = (query: RecordQuery) => {
			this.request.query = query;
			return this;
		};

		/*
			Whether to return search highlights
		 */
		public highlight = (highlight: string) => {
			this.request.highlight = highlight;
			return this;
		};

		public buildRequest() {
			const request = { ...this.request };
			if (request.sort == null || request.sort.length === 0) delete request.sort;
			if (Object.keys(request.aggregations || {}).length === 0) delete request.aggregations;
			return request;
		}

		public buildRequestAsJson() {
			return JSON.stringify(this.buildRequest());
		}
	}

	class QueryBuilder {
		private readonly queryValue: RecordQuery = { and: [], not: [], or: [] };

		/*
			All and clauses will be matched
		 */
		public and = (clause: RecordQueryClauses) => {
			this.queryValue.and?.push(clause);
			return this;
		};

		/*
			Will 'not' the given clause
		 */
		public not = (clause: RecordQueryClauses) => {
			this.queryValue.not?.push(clause);
			return this;
		};

		/*
			A minimum of one will be matched
		 */
		public or = (clause: RecordQueryClauses) => {
			this.queryValue.or?.push(clause);
			return this;
		};

		public buildQuery(): RecordQuery {
			const query = { ...this.queryValue };
			if (query.and?.length === 0) delete query.and;
			if (query.not?.length === 0) delete query.not;
			if (query.or?.length === 0) delete query.or;
			return query;
		}
	}

	class SubQueryBuilder {
		private readonly subQueryValue: RecordSubQuery = { and: [], not: [], or: [] };

		public and = (clause: RecordSubQueryClauses) => {
			this.subQueryValue.and?.push(clause);
			return this;
		};
		public not = (clause: RecordSubQueryClauses) => {
			this.subQueryValue.not?.push(clause);
			return this;
		};
		public or = (clause: RecordSubQueryClauses) => {
			this.subQueryValue.or?.push(clause);
			return this;
		};

		public buildSubQuery(): RecordSubQueryClause {
			const subQuery = { query: this.subQueryValue };
			if (subQuery.query.and?.length === 0) delete subQuery.query.and;
			if (subQuery.query.not?.length === 0) delete subQuery.query.not;
			if (subQuery.query.or?.length === 0) delete subQuery.query.or;
			return subQuery;
		}
	}

	export const query = () => new QueryBuilder();
	export const subQuery = () => new SubQueryBuilder();

	export const not = (clause: RecordNotClauses): RecordNotClause => ({ not: clause });

	/*
	 	All fields search (i.e. for top level search)
		Returns documents that contain the words of the provided text,
		in the same order as provided. The last term of the provided
		text is treated as a prefix, matching any words that begin
		with that term.
	 */
	export const matchAllPrefix = (value: string): MatchAllPrefixClause => ({ matchAllPrefix: value });

	/*
	 	All fields search (i.e. for top level search)
		Returns documents that contain the words of the provided text
	 */
	export const matchAll = (value: string): MatchAllClause => ({ matchAll: value });

	/*
		Record property value or fieldValue that contain the words of
		the provided text, in the same order as provided. The last
		term of the provided text is treated as a prefix, matching
		any words that begin with that term.
	 */
	export const matchPrefix = (propertyName: string, value: string): MatchPrefixClause => ({
		matchPrefix: { [propertyName]: value },
	});

	/*
		Record property value or fieldValue that contain the words of the provided text
	 */
	export const match = (propertyName: string, value: string): MatchClause => ({ match: { [propertyName]: value } });

	/*
		Property value exists
	*/
	export const exists = (propertyName: string): ExistsClause => ({
		exists: propertyName,
	});

	/*
		Property value or fieldValue equals the provided value
	*/
	export const equals = (propertyName: string, value: AllowedTypes): EqualsClause => ({
		equals: { [propertyName]: value },
	});

	/*
		Property value or fieldValue equals any of the values in the provided array
	 */
	export const equalsAnyOf = (propertyName: string, value: AllowedTypes[]): EqualsAnyOfClause => ({
		equalsAnyOf: { [propertyName]: value },
	});

	/*
		Property value or fieldValue is in the range
	 */
	export const range = (propertyName: string, value: RangeClauseValue): RangeClause => ({
		range: { [propertyName]: value },
	});

	/*
		Property value array or fieldValue array contains the provided value
	 */
	export const include = (propertyName: string, value: AllowedTypes): IncludeClause => ({
		include: { [propertyName]: value },
	});

	/*
	 	Property value array or fieldValue array contains any of these values
	 */
	export const includeAnyOf = (propertyName: string, value: AllowedTypes[]): IncludeAnyOfClause => ({
		includeAnyOf: { [propertyName]: value },
	});

	/*
		Record has any association to the provided recordId
	 */
	export const hasAssociationToRecord = (recordId: number): HasAssociationToRecordClause => ({
		hasAssociationToRecord: recordId,
	});

	/*
		Record has any association to at least one of the provided recordIds
	 */
	export const hasAssociationToAnyRecord = (recordIds: number[]): HasAssociationToAnyRecordClause => ({
		hasAssociationToAnyRecord: recordIds,
	});

	/*
		Record has any association to all of the provided recordIds
	 */
	export const hasAssociationsToAllRecords = (recordIds: number[]): HasAssociationsToAllRecordsClause => ({
		hasAssociationsToAllRecords: recordIds,
	});

	/*
		Record has the given label association to the provided recordId
	 */
	export const hasAssociationToRecordForObjectAssociation = (
		objectAssociationId: number,
		recordId: number,
		associationSide: AssociationSide
	): HasAssociationToRecordForObjectAssociationClause => ({
		hasAssociationToRecordForObjectAssociation: {
			objectAssociationId,
			recordId,
			associationSide,
		},
	});

	/*
		Record has the given label association to any of the provided recordIds
	 */
	export const hasAssociationToAnyRecordForObjectAssociation = (
		objectAssociationId: number,
		recordIds: number[],
		associationSide: AssociationSide
	): HasAssociationToAnyRecordForObjectAssociationClause => ({
		hasAssociationToAnyRecordForObjectAssociation: recordIds.map((recordId) => {
			return {
				objectAssociationId,
				recordId,
				associationSide,
			};
		}),
	});

	/*
		Record has the given label association to all of the provided recordIds
	 */
	export const hasAssociationsToAllRecordsForObjectAssociation = (
		objectAssociationId: number,
		recordIds: number[],
		associationSide: AssociationSide
	): HasAssociationsToAllRecordsForObjectAssociationClause => ({
		hasAssociationsToAllRecordsForObjectAssociation: recordIds.map((recordId) => {
			return {
				objectAssociationId,
				recordId,
				associationSide,
			};
		}),
	});

	export const existsAssociationForObjectAssociation = (
		objectAssociationId: number,
		associationSide: AssociationSide
	): ExistsAssociationForObjectAssociationClause => {
		return {
			existsAssociationForObjectAssociation: {
				objectAssociationId,
				associationSide,
			},
		};
	};

	/*
		Record is shared with the give workspace
	 */
	export const isSharedWithWorkspace = (workspaceRecordId: number): IsSharedWithWorkspaceClause => ({
		isSharedWithWorkspace: workspaceRecordId,
	});

	export const isSharedWithAnyWorkspace = (): IsSharedWithAnyWorkspaceClause => ({
		isSharedWithAnyWorkspace: {},
	});

	/*
		Record's object has the given baseObjectId
	 */
	export const baseObjectId = (value: number): BaseObjectIdClause => ({ baseObjectId: value });

	/*
	 * Provides record search query builder
	 * @example
	 * const request = rsr.create()
        .size(10)
        .from(2)
        .sort({ _name: { order: "asc" } })
        .sort({ createdAt: { order: "desc" } })
        .aggregate("sumQuantityAggregationName", {sum: "quantityFieldName"})
        .aggregate("minQuantityAggregationName", {min: "quantityFieldName"})
        .aggregate("maxQuantityAggregationName", {max: "quantityFieldName"})
        .aggregate("averageQuantityAggregationName", {avg: "quantityFieldName"})
        .aggregate("countEmptyQuantityAggregationName", {countEmpty: "quantityFieldName"})
        .aggregate("countNotEmptyQuantityAggregationName", {countNotEmpty: "quantityFieldName"})
        .searchAfter(["The search after array from previous search"])
        .query(
          rsr
            .query()
            .and(rsr.subQuery()
              .or(rsr.not(rsr.matchAll("a")))
              .or(rsr.not(rsr.matchAll("b")))
              .buildSubQuery())
            .and(rsr.matchAll("Global search value"))
            .and(rsr.matchAllPrefix("Global search prefix value"))
            .and(rsr.match("_name", "Record Name value"))
            .and(rsr.matchPrefix("_name", "Record Name prefix value"))
            .and(rsr.existsAssociationForObjectAssociation(100, AssociationSide.RECORD_2))
            .and(rsr.hasAssociationToRecord(200))
            .and(rsr.hasAssociationToAnyRecord([300, 301]))
            .and(rsr.hasAssociationsToAllRecords([400, 401]))
            .and(rsr.hasAssociationToRecordForObjectAssociation(1002, 500, AssociationSide.RECORD_2))
            .and(rsr.hasAssociationToAnyRecordForObjectAssociation(1002, [600, 601], AssociationSide.RECORD_2))
            .and(rsr.hasAssociationsToAllRecordsForObjectAssociation(1002, [700, 801], AssociationSide.RECORD_2))
            .and(rsr.hasAssociationsToAllRecordsForObjectAssociation(1002, [700, 801], AssociationSide.RECORD_2))
            .and(rsr.isSharedWithWorkspace(123))
            .and(rsr.isSharedWithAnyWorkspace())
            .not(rsr.exists("fieldId"))
            .not(rsr.equals("_name", "Exact Record Name"))
            .not(rsr.equalsAnyOf("_name", ["rita", "bob", "sue"]))
            .not(rsr.range("id", { lt: 5, gte: 1 }))
            .not(rsr.include("fieldIdForArrayOfValues", 1))
            .not(rsr.includeAnyOf("fieldIdForArrayOfValues", [1, 2, 3, 4, 5]))
            .or(rsr.baseObjectId(1))
            .buildQuery()
  )
  .buildRequest();
	 */
	export const create = () => {
		return new RecordsSearchRequestBuilder();
	};
}
