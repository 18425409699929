import { mSystemObjectDef } from "../system_object_def";

export class mTenantDef extends mSystemObjectDef {
	static CLASS_NAME = "mTenantDef";
	static NAME = "Tenant";
	static PLURAL_NAME = "Tenants";

	static ID = 11;

	constructor(ownerId) {
		super(mTenantDef.ID, ownerId);
	}
}
