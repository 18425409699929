import { asConst } from "json-schema-to-ts";

export const topTopicsSchema = asConst({
	title: "Top topics",
	description: "Top topics",
	type: "array",
	items: {
		type: "string",
	},
});
