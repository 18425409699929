import { BaseSelect, BaseSelectButtonProps, SelectOption } from "../../../../inputs";
import { forwardRef, useMemo } from "react";
import { Listbox } from "@headlessui/react";
import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { ControlPopover } from "./ControlPopover";
import clsx from "clsx";

const VIDEO_SPEEDS: number[] = [0.5, 0.7, 1, 1.2, 1.5, 2];

function formatSpeed(speed: number) {
	return `${speed.toFixed(1)}x`;
}

export function VideoSpeedControl() {
	const { variant, videoSpeed, updateVideoSpeed } = useVideoPlayerContext();

	const speedOptions: SelectOption[] = useMemo(
		() =>
			VIDEO_SPEEDS.map((speed) => ({
				id: String(speed),
				name: formatSpeed(speed),
			})),
		[]
	);

	return (
		<BaseSelect
			onChange={(newValue) => {
				updateVideoSpeed(Number(newValue));
			}}
			options={speedOptions}
			value={String(videoSpeed)}
			selectPopover={
				<ControlPopover>
					{speedOptions.map((option) => (
						<Listbox.Option key={option.id} value={option.id} disabled={option.disabled}>
							{({ active }) => (
								<div
									className={clsx(
										"cursor-pointer",
										variant === "expanded" ? "text-label px-6 py-3" : "text-label-sm px-2 py-1.5",
										active && "bg-c_action_focus"
									)}
								>
									{option.name}
								</div>
							)}
						</Listbox.Option>
					))}
				</ControlPopover>
			}
			selectPopoverOptions={{
				placement: "top",
				useFloatingPortal: false,
			}}
			selectButton={<VideoSpeedButton />}
		/>
	);
}

const VideoSpeedButton = forwardRef<HTMLButtonElement, BaseSelectButtonProps>(
	({ selectedOption, ...restProps }, ref) => {
		const { variant } = useVideoPlayerContext();
		if (!selectedOption) return null;
		return (
			<button
				ref={ref}
				className={clsx(
					"hover:bg-c_bg_tooltip rounded-xs ring-c_action_focus px-2 text-center focus-visible:ring",
					variant === "expanded" ? "text-h4" : "text-h5"
				)}
				{...restProps}
			>
				{selectedOption.name}
			</button>
		);
	}
);
