import { asConst } from "json-schema-to-ts";
import { stringSchema } from "./stringSchema";

export const emailSchema = asConst({
	...stringSchema,
	title: "Email",
	description: "An email address",
	type: "string",
	nullable: false,
});
