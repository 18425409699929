import { useEffect } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { DisplayIf } from "../../../../../auth";
import { EditBar } from "../../../../../components/EditBar";
import { useBoardViewSelector } from "../../../store";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import { useBulkEditContext } from "../hooks/useBulkEditContext";

import { AdditionalBulkActions } from "./AdditionalBulkActions";
import { BulkChangeOwnerButton } from "./BulkChangeOwnerButton";
import { BulkCopyLinksButton } from "./BulkCopyLinksButton";
import { BulkEditFields } from "./BulkEditFields";
import { BulkFavoriteButton } from "./BulkFavoriteButton";
import { BulkMarkAsDone } from "./BulkMarkAsDone";
import { BulkWatchButton } from "./BulkWatchButton";
import { getIsCompletableRecord } from "../../../../completableRecord";

export function BulkEditBar() {
	const {
		inBulkEditMode,
		toggleBulkEditMode,
		setInBulkEditMode,
		selectedRecords,
		setSelectedRecords,
		handleSelectAll,
		allSelected,
	} = useBulkEditContext();

	const { sdObject } = useDataboardDetailsContext();
	const boardView = useBoardViewSelector();

	// Leaves bulk edit mode when the user changes board view or
	// navigates to a different object board
	useEffect(() => {
		setInBulkEditMode(false);
	}, [boardView, sdObject, setInBulkEditMode]);

	const selectedRecordsText = selectedRecords.length
		? `${selectedRecords.length} ${pluralizeWithS("record", selectedRecords.length)} selected`
		: "Select records";

	const isCompletableObject = getIsCompletableRecord(sdObject);

	return (
		<EditBar show={inBulkEditMode}>
			<Tooltip text="Exit bulk edit mode" placement="top">
				<Button variant="text_inverted" startIcon={ICONS.cross} size="sm" onClick={toggleBulkEditMode} />
			</Tooltip>
			<span className="min-w-[115px] text-center">{selectedRecordsText}</span>
			<Tooltip text={allSelected ? "Deselect all" : "Select all loaded records"} noWrap placement="top">
				<Button
					variant="text_inverted"
					startIcon={allSelected ? ICONS.square : ICONS.checkSquare}
					size="sm"
					onClick={() => (allSelected ? setSelectedRecords([]) : handleSelectAll())}
				/>
			</Tooltip>
			<DisplayIf
				canAll={[
					[AbilityAction.BulkEdit, AbilitySubject.Record],
					[AbilityAction.Edit, AbilitySubject.Record],
				]}
			>
				<BulkEditFields sdObject={sdObject} />
			</DisplayIf>
			{isCompletableObject && sdObject ? <BulkMarkAsDone sdObject={sdObject} /> : null}
			<DisplayIf
				canAll={[
					[AbilityAction.BulkEdit, AbilitySubject.Record],
					[AbilityAction.ChangeOwner, AbilitySubject.Record],
				]}
			>
				<BulkChangeOwnerButton />
			</DisplayIf>
			<BulkCopyLinksButton sdObject={sdObject} />
			<BulkFavoriteButton sdObject={sdObject} />
			<BulkWatchButton sdObject={sdObject} />
			<DisplayIf
				canAll={[
					[AbilityAction.BulkDelete, AbilitySubject.Record],
					[AbilityAction.Delete, AbilitySubject.Record],
				]}
			>
				<AdditionalBulkActions sdObject={sdObject} />
			</DisplayIf>
		</EditBar>
	);
}
