import { forwardRef } from "react";

import { EditFieldFactory, EditFieldFactoryProps, EditFieldVariant } from "./EditFieldFactory";
import { DisplayFieldFactory } from "./DisplayFieldFactory";
import { DisplayFieldVariant } from "../../types";

export interface InlineEditFieldFactoryProps extends EditFieldFactoryProps {
	edit?: boolean;
	displayFieldVariant?: DisplayFieldVariant;
	editFieldVariant?: EditFieldVariant;
}

export const InlineEditFieldFactory = forwardRef<HTMLElement, InlineEditFieldFactoryProps>(
	(
		{
			edit = false,
			componentProps,
			field,
			updateUploadProgressStatus,
			displayFieldVariant = DisplayFieldVariant.rightHandPanel,
			editFieldVariant = EditFieldVariant.default,
		},
		ref
	) => {
		if (edit) {
			return (
				<EditFieldFactory
					componentProps={{ ...componentProps, inputRef: ref }}
					field={field}
					updateUploadProgressStatus={updateUploadProgressStatus}
					variant={editFieldVariant}
				/>
			);
		}

		return <DisplayFieldFactory field={field} value={componentProps.value} variant={displayFieldVariant} />;
	}
);
