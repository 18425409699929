import { useMemo } from "react";
import { JSONContent } from "@tiptap/core";
import clsx from "clsx";

import { AbilityAction, AbilitySubject, Comment, sdSubject } from "@salesdesk/salesdesk-schemas";
import { formatDateTime, formatSince } from "@salesdesk/salesdesk-utils";
import { Button, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { isJsonContent } from "../../../../../../../../utils";
import { useWebPrincipal } from "../../../../../../../../auth";
import { FabContainer } from "../../../../../../../../components/FabContainer";
import { Reactions } from "../../../../../../../../components/Reactions";
import { EmojiPopover } from "../../../../../../../../components/EmojiPicker";
import { useGetContextWorkspaceId, WorkspaceName } from "../../../../../../../workspaces";
import { DisplayRichTextField } from "../../../../../../../fields";
import { InlineUserController } from "../../../../../../../users";
import { EditComment } from "./EditComment";

interface SingleCommentProps {
	comment: Comment;
	inDocumentText?: string;
	showControls?: boolean;
	isEditing?: boolean;
	onEdit?: (comment: Comment) => void;
	submitEdit?: (contents: JSONContent) => void;
	onDelete?: (comment: Comment, inDocumentText?: string) => void;
	onResolve?: (comment: Comment, inDocumentText?: string) => void;
	onToggleReaction?: (comment: Comment, emoji: string) => void;
}

export function SingleComment({
	comment,
	inDocumentText,
	isEditing,
	onEdit,
	submitEdit,
	onDelete,
	onResolve,
	onToggleReaction,
	showControls = true,
}: SingleCommentProps) {
	const workspaceId = useGetContextWorkspaceId();
	const isEditableInContext = workspaceId === comment.workspaceId;

	const principal = useWebPrincipal();

	const formattedContent = useMemo(() => {
		if (isJsonContent(comment.message)) {
			const jsonContent = JSON.parse(comment.message) as JSONContent;
			return <DisplayRichTextField value={jsonContent} enableMentions={true} />;
		}
		return comment.message;
	}, [comment.message]);

	const isResolved = comment.resolvedAt != null;
	const createdAt = new Date(comment.createdAt);

	const canReact =
		onToggleReaction && principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.CommentReaction, comment));

	const fabControls = useMemo(() => {
		if (!showControls || !isEditableInContext) return null;

		const controls = [];

		if (canReact) {
			controls.push(
				<EmojiPopover
					key={`emoji-${comment.id}`}
					onEmojiSelect={({ native }) => {
						onToggleReaction(comment, native);
					}}
				>
					<Button startIcon={ICONS.smiley} size="xs" variant="text" />
				</EmojiPopover>
			);
		}

		if (onResolve && principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Comment, comment))) {
			controls.push(
				<Button
					key={`resolve-${comment.id}`}
					startIcon={ICONS.check}
					size="xs"
					variant="text"
					onClick={() => onResolve(comment, inDocumentText)}
				/>
			);
		}

		if (onEdit && principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Comment, comment))) {
			controls.push(
				<Button
					key={`edit-${comment.id}`}
					startIcon={ICONS.simplePencil}
					size="xs"
					variant="text"
					onClick={() => onEdit(comment)}
				/>
			);
		}

		if (onDelete && principal.can(AbilityAction.Delete, sdSubject(AbilitySubject.Comment, comment))) {
			controls.push(
				<Button
					key={`delete-${comment.id}`}
					startIcon={ICONS.trash}
					size="xs"
					variant="danger_text"
					onClick={() => onDelete(comment, inDocumentText)}
				/>
			);
		}

		return controls.length > 0 ? controls : null;
	}, [
		showControls,
		isEditableInContext,
		canReact,
		onResolve,
		principal,
		comment,
		onEdit,
		onDelete,
		onToggleReaction,
		inDocumentText,
	]);

	return (
		<FabContainer positionClasses={tw`right-1 -top-0.5`} buttons={fabControls}>
			<div className="flex flex-col gap-1">
				<div className="flex items-center gap-2">
					<InlineUserController userId={comment.createdBy} />
					<div className="bg-c_text_placeholder h-1.5 w-1.5 rounded-full" />
					<div className="text-c_text_placeholder text-label-xs" title={formatDateTime(createdAt)}>
						{formatSince(createdAt)}
					</div>
					{comment.createdAt !== comment.updatedAt ? (
						<div className="text-c_text_disabled text-body-sm">Edited</div>
					) : null}
				</div>
				{!workspaceId && comment.workspaceId ? (
					<WorkspaceName workspaceId={comment.workspaceId} variant="note" />
				) : null}
				{inDocumentText ? (
					<div className="text-c_text_placeholder text-body-sm ml-8 flex h-5 max-w-full items-center gap-2 truncate">
						<div className={clsx(isResolved ? "bg-c_text_disabled" : "bg-c_warning_focus", "h-full w-0.5 shrink-0")} />
						<span className="truncate">{inDocumentText}</span>
					</div>
				) : null}
				<div className="mb-1 ml-8 mr-1">
					{isEditing ? (
						<EditComment onCancel={() => onEdit?.(comment)} comment={comment} onEditSubmit={submitEdit} />
					) : (
						formattedContent
					)}
				</div>
				{comment.reactions?.length ? (
					<Reactions
						reactions={comment.reactions}
						canReact={Boolean(canReact)}
						onReactionSelect={(emoji) => onToggleReaction?.(comment, emoji)}
					/>
				) : null}
			</div>
		</FabContainer>
	);
}
