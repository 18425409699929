import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChannelDetails } from "../types";
import { useChimeMessagingContext } from "../components/providers/hooks/useChimeMessagingContext";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";
import { useWebPrincipal } from "../../../auth";
import { getChimeUserId } from "@salesdesk/salesdesk-schemas";
import { updateReadMarkerForChannel } from "../store/thunks";
import { useAppDispatch } from "../../../store/store";
import { channelIsUnread } from "../utils";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { updateFavicon } from "../../../utils/favicons";

interface UseUnreadMessageHandlerProps {
	channels?: ChannelDetails[];
	currentOpenChannelArn?: string;
	isReadOnly?: boolean;
}

export function useUnreadMessageHandler({ channels, currentOpenChannelArn, isReadOnly }: UseUnreadMessageHandlerProps) {
	const principal = useWebPrincipal();
	const dispatch = useAppDispatch();
	const { chime, subscribeToIncomingMessages, unsubscribeFromIncomingMessages } = useChimeMessagingContext();

	const [newMessageKey, setNewMessageKey] = useState(0);

	const newMessageSound = useMemo(() => new Audio("/static/audio/new-message.mp3"), []);

	const [messagesReceivedWhileUnfocused, setMessagesReceivedWhileUnfocused] = useState(0);

	const currentOpenChannelArnRef = useRef<string | undefined>();
	currentOpenChannelArnRef.current = currentOpenChannelArn;

	const channelArnsRef = useRef<string[]>([]);
	useEffect(() => {
		channelArnsRef.current = channels?.map((channel) => channel.arn) || [];
	}, [channels]);

	const updateChannelReadMarker = useCallback(
		(channelArn: string) => {
			if (!chime || isReadOnly || !isPageFocused()) return;
			dispatch(updateReadMarkerForChannel({ channelArn, chime }));
		},
		[chime, dispatch, isReadOnly]
	);

	// Update read marker when the channel is opened
	useEffect(() => {
		if (!currentOpenChannelArn) return;
		updateChannelReadMarker(currentOpenChannelArn);
	}, [currentOpenChannelArn, updateChannelReadMarker]);

	// Update read marker for open channel when the page is focused
	useEffect(() => {
		const handleVisibilityChange = () => {
			if (!isPageFocused()) {
				return;
			}

			setMessagesReceivedWhileUnfocused(0);

			if (currentOpenChannelArnRef.current) {
				updateChannelReadMarker(currentOpenChannelArnRef.current);
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);
		window.addEventListener("focus", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("focus", handleVisibilityChange);
		};
	}, [updateChannelReadMarker]);

	useEffect(() => {
		if (!subscribeToIncomingMessages || !unsubscribeFromIncomingMessages || !chime) return;

		const onNewUnreadMessage = () => {
			newMessageSound.play();
			setNewMessageKey((prev) => prev + 1);
		};

		const onIncomingMessage = (message: ChannelMessage) => {
			// Ignore messages from channels that are not in the current list
			if (!channelArnsRef.current.find((channelArn) => channelArn === message.ChannelArn)) {
				return;
			}

			const isCurrentUser = getChimeUserId(message.Sender?.Arn) === principal.UserRecordId;

			const pageIsFocused = isPageFocused();

			// Play sound if the message is not from the current user and the message is not in the currently open channel
			// or the page is not currently focused.
			if (!isCurrentUser && (!pageIsFocused || message.ChannelArn !== currentOpenChannelArnRef.current)) {
				onNewUnreadMessage();

				if (!pageIsFocused) {
					setMessagesReceivedWhileUnfocused((prev) => prev + 1);
				}

				return;
			}

			// Update the read marker for the currently open & focused channel
			if (currentOpenChannelArnRef.current) {
				dispatch(updateReadMarkerForChannel({ channelArn: currentOpenChannelArnRef.current, chime }));
			}
		};

		subscribeToIncomingMessages(onIncomingMessage);

		return () => {
			unsubscribeFromIncomingMessages(onIncomingMessage);
		};
	}, [
		chime,
		dispatch,
		newMessageSound,
		principal.UserRecordId,
		subscribeToIncomingMessages,
		unsubscribeFromIncomingMessages,
	]);

	const hasUnreadMessages = useMemo(() => {
		if (!channels) {
			return false;
		}

		return channels.some(channelIsUnread);
	}, [channels]);

	useEffect(() => {
		if (hasUnreadMessages) {
			updateFavicon("alert");

			return () => {
				updateFavicon("default");
			};
		}
	}, [hasUnreadMessages]);

	useEffect(() => {
		const originalTitle = document.title.replace(/^\(\d+\) New message(!|s!) - /, "");

		if (messagesReceivedWhileUnfocused > 0) {
			document.title = `(${messagesReceivedWhileUnfocused}) New ${pluralizeWithS("message", messagesReceivedWhileUnfocused)}! - ${originalTitle}`;
		} else {
			document.title = originalTitle;
		}
	}, [messagesReceivedWhileUnfocused]);

	return { hasUnreadMessages, newMessageKey };
}

function isPageFocused() {
	return document.visibilityState === "visible" && document.hasFocus();
}
