import { useMemo } from "react";

import { Field, SDObject } from "@salesdesk/salesdesk-schemas";

import { DirectedSDObjectAssociation, useObjectAssociations } from "../../../../recordAssociations";
import { getSDObjectFieldMap } from "../../../../objects/utils/objects";
import { useGetObjectMap } from "../../../../objects/hooks";
import { METADATA_FIELDS_LIST } from "../../../../ObjectBoard/utils";
import { SelectOption, SelectOptionSection } from "../../../../inputs";
import { fieldFilterOptionsFactory, generateAssocitionFilterTargetId } from "../utils";
import { FilterTargetMap } from "../types";

export function useGetFilterTargets(sdObject: SDObject) {
	const { objectAssociations } = useObjectAssociations(sdObject._id);

	const objectMap = useGetObjectMap();

	const associationMap = useMemo(() => {
		const associationMap: Record<string, DirectedSDObjectAssociation> = {};

		for (const association of objectAssociations.allAssociations) {
			associationMap[generateAssocitionFilterTargetId(association)] = association;
		}
		return associationMap;
	}, [objectAssociations]);

	const fieldMap = useMemo(() => getSDObjectFieldMap(sdObject), [sdObject]);

	const metadataMap = useMemo(() => {
		const metadataMap: Record<string, Field> = {};
		for (const field of METADATA_FIELDS_LIST) {
			metadataMap[field._name] = field;
		}
		return metadataMap;
	}, []);

	return useMemo(() => {
		const filterTargetMap: FilterTargetMap = {
			...fieldMap,
			...metadataMap,
			...associationMap,
		};

		const filterTargetOptions: SelectOptionSection[] = [];

		const fieldOptions = Object.values(fieldMap).flatMap((field) => {
			// filter out fields that don't have any filters implemented
			if (!fieldFilterOptionsFactory(field._type).length) {
				return [];
			}
			return getFieldOption(field);
		});

		if (fieldOptions.length) {
			filterTargetOptions.push({ sectionTitle: "Fields", options: fieldOptions });
		}

		const metadataOptions = Object.values(metadataMap).map((field) => getFieldOption(field, true));

		if (metadataOptions.length) {
			filterTargetOptions.push({ sectionTitle: "Metadata", options: metadataOptions });
		}

		const associationOptions: SelectOption[] = Object.values(associationMap).map((association) => {
			const { connectedObject } = association;

			return {
				id: generateAssocitionFilterTargetId(association),
				name: connectedObject.label,
				icon: objectMap.get(connectedObject.id)?._icon || undefined,
			};
		});

		if (associationOptions.length) {
			filterTargetOptions.push({ sectionTitle: "Associations", options: associationOptions });
		}

		return {
			filterTargetMap,
			filterTargetOptions,
		};
	}, [fieldMap, metadataMap, associationMap, objectMap]);
}

function getFieldOption(field: Field, isMetadata?: boolean): SelectOption {
	return {
		id: isMetadata ? field._name : String(field._id),
		name: field._displayName,
		icon: field._icon,
	};
}
