import { inBrowser, isEmpty } from "@salesdesk/salesdesk-utils";
import { mDbBackingStore } from "./db_backing_store";
import { mUser } from "../../../../objects";

// TODO: Remove option field backing stores: https://salesdesk101.atlassian.net/browse/SAL-2269
export class mObjectBackingStore extends mDbBackingStore {
	static TYPE = "object";

	constructor() {
		super();
		this._type = mObjectBackingStore.TYPE;
	}

	get type() {
		return this._type;
	}

	// Moving towards return data in the form of the SelectOption type on the front-end
	getOptionValuesByQuery(filter, field, callback) {
		if (isEmpty(filter)) {
			return callback(null, []);
		}

		const baseType = this.getDbService().getObjectDef(field.objectDefId)?.baseType;

		if (!inBrowser) {
			return callback(null, []);
		}
	}

	getOptionValueById(id, callback, formatResults) {
		this.getDbService().loadObject(id, (err, data) => {
			callback(err, data && formatResults ? this.formatRecordToOptionValue(data) : data);
		});
	}

	getOptionValuesById(ids, callback, formatResults) {
		this.getDbService().loadObjects(ids, (err, results) => {
			callback(err, results && formatResults ? results.map((recordData) => this.formatRecordToOptionValue(recordData)) : results);
		});
	}

	formatRecordToOptionValue(recordData){
		const optionValue = {
			id: recordData.id,
			name: recordData.name,
			color: recordData.color,
			icon: recordData.icon,
			isRecord: true,
		}

		if(recordData.hasIconPhoto && recordData.hasIconPhoto()) {
			optionValue.imageIcon = recordData.iconPhotoUrl;
		} else if (recordData instanceof mUser){
			optionValue.icon = recordData.initials;
		}

		return optionValue;
	}
}
