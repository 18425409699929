import { PropsWithChildren } from "react";

interface FieldSetWrapperProps {
	withLabel?: boolean;
	className?: string;
	labelFor?: string;
}

export function FieldSetWrapper({ withLabel, labelFor, className, children }: PropsWithChildren<FieldSetWrapperProps>) {
	return withLabel ? (
		<label className={className} htmlFor={labelFor}>
			{children}
		</label>
	) : (
		<div className={className}>{children}</div>
	);
}
