import {
	FaceSentiment,
	FaceSentimentDataPoint,
	FaceSentimentType,
	faceSentimentTypes,
	ZOOM_FACTORS,
} from "../../../../types";

export function reduceDataPoints(fullData: FaceSentimentDataPoint[], zoomFactor: number): FaceSentimentDataPoint[] {
	// If the number of points is very large reduce it to a more manageable size.
	const baseReductionFactor = Math.ceil(fullData.length / 1500);

	// Reduce the number of data points inversely proportional to the zoom factor.
	const zoomFactorIndex = ZOOM_FACTORS.findIndex((factor) => factor === zoomFactor);
	const reductionFactor = ZOOM_FACTORS[ZOOM_FACTORS.length - 1 - zoomFactorIndex];

	// Combine the reduction factors
	const stepSize = reductionFactor * baseReductionFactor;

	// Average the data points based on the step size.
	const averagedData: FaceSentimentDataPoint[] = [];
	for (let i = 0; i < fullData.length; i += stepSize) {
		const dataPointsToAverage = fullData.slice(i, i + stepSize);
		const middlePoint = Math.floor(dataPointsToAverage.length / 2);
		const averagedDataPoint: FaceSentimentDataPoint = {
			timestamp: dataPointsToAverage[middlePoint].timestamp,
		};
		for (const sentimentType of faceSentimentTypes) {
			let totalConfidence = 0;
			for (const dataPoint of dataPointsToAverage) {
				totalConfidence += dataPoint[sentimentType];
			}
			averagedDataPoint[sentimentType] = totalConfidence / dataPointsToAverage.length;
		}
		averagedData.push(averagedDataPoint);
	}
	return averagedData;
}

export function getTopFaceSentiments(data: FaceSentimentDataPoint[], sentimentsReturned: number): FaceSentimentType[] {
	// Calculate the average confidence for each emotion
	const avgPerFaceSentimentType: Record<FaceSentimentType, number> = {};
	for (const faceSentimentType of faceSentimentTypes) {
		avgPerFaceSentimentType[faceSentimentType] = 0;
		for (const dataPoint of data) {
			avgPerFaceSentimentType[faceSentimentType] += dataPoint[faceSentimentType];
		}
	}

	// Sort the emotions by average confidence
	const sortedFaceSentimentTypes = [...faceSentimentTypes].sort(
		(a, b) => avgPerFaceSentimentType[b] - avgPerFaceSentimentType[a]
	);

	// Set the top 3 as the default selected emotions
	return sortedFaceSentimentTypes.slice(0, Math.min(sentimentsReturned, sortedFaceSentimentTypes.length));
}

export function getUserIdsFromFaceSentiments(
	faceSentimentsPerUser: Record<string, FaceSentiment[]> | undefined,
	userKeyToUserId: Record<string, string | number>
) {
	if (!faceSentimentsPerUser) {
		return undefined;
	}

	const userKeys = Object.keys(faceSentimentsPerUser);
	if (!userKeys.length) {
		return undefined;
	}

	const newUserIds: number[] = [];
	for (const userOrPlaceholderName of Object.values(userKeyToUserId)) {
		if (typeof userOrPlaceholderName === "number") {
			newUserIds.push(userOrPlaceholderName);
		}
	}

	return newUserIds;
}
