import { asConst } from "json-schema-to-ts";
import { UniqueFieldType } from "@salesdesk/salesdesk-model";

export const fieldUniqueTypeSchema = asConst({
	title: "UniqueFieldTypeSchema",
	description: "Indicates if the field should have any Unique Constraints",
	type: "string",
	enum: Object.values(UniqueFieldType),
	nullable: false,
});
