import { asConst } from "json-schema-to-ts";
import { recordIdPathParamSchema } from "./recordIdPathParamSchema";

export const getRecordPathSchema = asConst({
	title: "Get SDRecord Path Parameters",
	type: "object",
	additionalProperties: false,
	required: ["recordId"],
	properties: {
		recordId: recordIdPathParamSchema,
	},
});
