
import { deepClone, isEmpty } from "@salesdesk/salesdesk-utils";

import { mFieldDataChangeEvent } from "./utils";
import { mFieldDef } from "./field_def";

export class mField {
	constructor(id, field) {
		this._id = id;
		this._fieldType = null;
		this._fieldId = -1;
		this._parent = null;
		this._value = null;
		this._listeners = new Map();

		if (field) {
			this.field = field;

			// Set the default value if one exists

			if (field.hasDefaultValue()) {
				this.value = this.field.defaultValue;
			}
		}
	}

	addEventListener(type, listener) {
		if (!this._listeners.has(type)) {
			this._listeners.set(type, []);
		}
		this._listeners.get(type).push(listener);
	}

	removeEventListener(listener) {
		let listenerGroups = this._listeners.values();
		let result = listenerGroups.next();

		while (!result.done) {
			var offset = result.value.indexOf(listener);

			if (offset >= 0) {
				result.value.splice(offset, 1);
			}
			result = listenerGroups.next();
		}
	}

	setEventListeners(eventListeners) {
		this._eventListeners = eventListeners;
	}

	removeEventListeners() {
		this._listeners = new Map();
	}

	fireEventObject(eventObject) {
		if (this._listeners.has(eventObject.type)) {
			let listeners = this._listeners.get(eventObject.type);

			listeners.forEach(function (listener) {
				listener(eventObject);
			});
		}
		if (this._listeners.has(mFieldDataChangeEvent.type.ALL)) {
			let listeners = this._listeners.get(mFieldDataChangeEvent.type.ALL);

			listeners.forEach(function (listener) {
				listener(eventObject);
			});
		}
	}

	fireEvent(type, message, source) {
		this.fireEventObject(new mFieldDataChangeEvent(type, message, source));
	}

	get id() {
		return this._id;
	}

	get field() {
		return this._field;
	}

	set field(field) {
		this._field = field;
		this._fieldId = field._id;
		this._fieldType = field.type;
	}

	get fieldId() {
		return this._fieldId;
	}

	get name() {
		return this.field?.name;
	}

	set name(name) {
		this.field.name = name;
	}

	get displayName() {
		return this.field?.displayName;
	}

	set displayName(displayName) {
		this.field.displayName = displayName;
	}

	get icon() {
		return this.field?.icon;
	}

	get parent() {
		return this._parent;
	}

	set parent(parent) {
		this._parent = parent;
	}

	get type() {
		return this._field.type;
	}

	isType(type) {
		return type === this._field.type;
	}

	get userType() {
		return this._field.userType;
	}

	isUserType() {
		return this._field.isUserType();
	}

	isSystemType() {
		return this._field.isSystemType();
	}

	// Work out where this instance sits (index) within
	// The parent's list of children

	getParentIndex() {
		let index = -1;

		if (this.parent) {
			let children = this.parent.children;

			for (let i = 0; children.length > i; i++) {
				if (children[i].id === this._id) {
					index = i;
				}
			}
		}
		return index;
	}

	getInstanceOffset() {
		let instances = this.parent.getInstancesByField(this.field.id);
		return instances.indexOf(this);
	}

	getPrevious() {
		let parent = this.parent;

		if (parent) {
			let index = this.getParentIndex();

			if (index === 0) {
				return null;
			} else {
				return parent.children[index - 1];
			}
		}
		return null;
	}

	getNext() {
		let parent = this.parent;

		if (parent) {
			let index = this.getParentIndex();

			if (index === 0) {
				return null;
			} else {
				return parent.children[index + 1];
			}
		}
	}

	// Climb to root, counting each step

	numOfAncestors() {
		let i = 0;

		for (let instance = this.parent; instance != null; i++, instance = instance.parent) {}
		return i;
	}

	isContainer() {
		return false;
	}

	get value() {
		return this._value;
	}

	set value(value) {
		value = this._field.formatValue(value);
		this.validateValue(value);

		let before = this._value;
		let after = value;

		this._value = value;

		if (before !== after) {
			this.fireEvent(mFieldDataChangeEvent.type.FIELD_INSTANCE_UPDATED, { before: before, after: after }, this);
		}
	}

	hasValue() {
		return !isEmpty(this._value);
	}

	validate() {
		this.validateValue(this.value);
	}

	validateValue(value) {
		const validationError = this._field.validate(value);

		if (validationError === undefined) {
			this.fireEvent(mFieldDataChangeEvent.type.FIELD_INSTANCE_VALIDATION, { valid: true }, this);
		} else {
			const error = new Error(validationError);
			this.fireEvent(mFieldDataChangeEvent.type.FIELD_INSTANCE_VALIDATION, { valid: false, error }, this);
			throw error;
		}
	}

	// Sometimes we don't want an error to be thrown - for example inside a UI component.
	// In this case a validation message is still fired to the container parent instances.

	validateQuietly() {
		try {
			this.validate();
		} catch (error) {
			// Swallow error here
		}
	}

	isValid() {
		try {
			this.validate();
			return true;
		} catch (error) {
			return false;
		}
	}

	move(toIndex) {
		let children = this.parent.children;

		if (toIndex > children.length - 1) {
			throw Error(`Index out of range - must be equal to or less than size of the children array (${children.length})`);
		}

		if (toIndex < 0) {
			throw Error(`Index out of range - must be zero of greater`);
		}

		children.splice(toIndex, 0, children.splice(this.index, 1)[0]);
	}

	// This is not a container type so just returns itself.
	getlastDescendant() {
		return this;
	}

	displayValue() {
		return !isEmpty(this.value) ? this.value : "";
	}

	// We don't allow setting data unless the field definition
	// is associated with this instance. Without this, we cannot
	// carry out validation.

	isBound() {
		return this._field && this._field instanceof mFieldDef;
	}

	/** start interface methods **/

	// Allows polymorphism in various UI libraries.
	// JS doesn't support interfaces ;-(
	get definitionId() {
		return this._fieldId;
	}

	get index() {
		return this.getParentIndex();
	}

	getAllInstances() {
		return [this];
	}

	getParentObjectByIndex(index) {
		let children = this.parent.children;

		if (index > children.length - 1 || index < 0) {
			throw Error(
				`index out of range - must be greater than zero and less than size of the children array (${children.length})`
			);
		}

		return this.parent.children[index];
	}

	getParentObjectsByType(id) {
		return this.parent.getInstancesByField(id);
	}

	supportsActions() {
		if (this.field.editable) {
			if (this.field.isFileStore() && this.hasFile()) {
				return true;
			}

			if (this.field.supportsMultiple()) {
				return true;
			}
		}

		return false;
	}

	supportsAddControl() {
		//Only look within the immediate children

		if (!this.parent) {
			return false;
		}

		let numOfInstances = this.parent.getInstancesByField(this.field.id).length;

		if (this.field.supportsNoMoreThanOne() && numOfInstances > 0) {
			return false;
		}
		return true;
	}

	supportsRemoveControl() {
		if (!this.parent) {
			return false;
		}

		//Only look within the immediate children
		let numOfInstances = this.parent.getInstancesByField(this.field.id).length;

		if (this.field.supportsNoLessThanOne() && numOfInstances === 1) {
			return false;
		}
		return true;
	}

	// TODO: Remove in https://salesdesk101.atlassian.net/browse/SAL-2267
	getActions() {
		return [];
	}

	/** end interface methods **/

	marshall(fieldData, data, parent) {
		// fieldData represents the entire tree of fields -
		// root, brances and leaves. From this object
		// we can set the correct field to he correct
		// field instance as it is created.

		Object.assign(this, data);

		this.parent = parent;

		// We need to store the corresponding field data so we can
		// obtain information about the field that this instance represents

		if (fieldData) {
			this.field = fieldData.getFieldById(this._fieldId);
		}

		return this;
	}

	unmarshall() {
		// We must delete some fields before storing and that would render the original
		// field useless. Hence we make a copy and store data from that field.

		let clone = this.clone();

		// Remove parent field before storing. We don't want to store loads of repeat
		// data about the parent object in JSON when it can be resolved when we call marshall().

		delete clone._fieldType;
		delete clone._parent;
		delete clone._field;
		delete clone._listeners;
		delete clone._eventListeners;

		return clone;
	}

	clone() {
		return deepClone(this);
	}

	toJson() {
		return JSON.stringify(
			this,
			function replacer(key, value) {
				if (key === "_parent") {
					return undefined;
				}
				if (key === "_objectDef") {
					return undefined;
				}
				if (key === "_listeners") {
					return undefined;
				}
				return value;
			},
			2
		);
	}
}
