import { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "@tiptap/core";
import { Transition } from "@headlessui/react";

import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Comment } from "@salesdesk/salesdesk-schemas";

import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { SELECT_POPOVER_TRANSITION } from "../../../../inputs";
import { PLACEHOLDER_COMMENT_ID } from "../utils";
import { useDocumentCommentsContext } from "../hooks/useDocumentCommentsContext";
import { SubmitCommentInput } from "./SubmitCommentInput";

interface BubbleMenuCommentPopoverProps {
	editor: Editor;
}

// TODO: Update mentions to be in the same react node so that mentions can work in the comment popover
// (Otherwise clicking on a mention will close the popover)
// Part of https://salesdesk101.atlassian.net/browse/SAL-3278
export function BubbleMenuCommentPopover({ editor }: BubbleMenuCommentPopoverProps) {
	const [isOpen, setIsOpen] = useState(false);
	const { documentRecord, setCommentsOpen } = useDocumentCommentsContext();

	const onSubmitSuccess = useCallback(
		(createdComment: Comment) => {
			editor.commands.setComment(createdComment.id);
			setIsOpen(false);
			setCommentsOpen?.(true);
		},
		[editor.commands, setCommentsOpen]
	);

	const inputRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Wrapped in a requestAnimationFrame so we don't cause a flush sync while the useEffect is running
		requestAnimationFrame(() => {
			if (!isOpen) {
				editor.commands.removeComment(PLACEHOLDER_COMMENT_ID);
				return;
			}

			editor.commands.setComment(PLACEHOLDER_COMMENT_ID);

			// Have to delay focusing on the RTF input to prevent the popover from closing
			setTimeout(() => {
				inputRef.current?.click();
			}, 150);
		});
	}, [editor, isOpen]);

	return (
		<Popover placement="bottom" open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger>
				<Tooltip placement="top" text="Add a comment" preventFlip noWrap>
					<Button variant="text" startIcon={ICONS.chatText} size="xs" active={isOpen} />
				</Tooltip>
			</PopoverTrigger>
			<PopoverContent initialFocus={-1}>
				<Transition {...SELECT_POPOVER_TRANSITION} show={isOpen}>
					<PopoverContainer>
						<div className="flex h-full w-[344px] px-3 py-2">
							<SubmitCommentInput
								ref={inputRef}
								documentRecordId={documentRecord?._id}
								onSubmitSuccess={onSubmitSuccess}
							/>
						</div>
					</PopoverContainer>
				</Transition>
			</PopoverContent>
		</Popover>
	);
}
