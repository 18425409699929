import { createContext, PropsWithChildren, useState, useCallback } from "react";

interface PanelsContextProps {
	activePanelItemId: number;
	panelItemIds: number[];
	addPanelItemId: (panelItemId: number) => void;
}

const initialValues: PanelsContextProps = {
	activePanelItemId: -1,
	panelItemIds: [],
	addPanelItemId: () => null,
};

export const PanelsContext = createContext<PanelsContextProps>(initialValues);

export function Panels({
	children,
	activePanelItemId = -1,
}: PropsWithChildren<{ activePanelItemId: PanelsContextProps["activePanelItemId"] }>) {
	const [panelItemIds, setPanelItemIds] = useState<number[]>([]);

	const addPanelItemId = useCallback((panelItemId: number) => {
		setPanelItemIds((prevPanelItemIds) => {
			return [panelItemId, ...prevPanelItemIds];
		});
	}, []);

	return (
		<PanelsContext.Provider
			value={{
				activePanelItemId,
				panelItemIds,
				addPanelItemId,
			}}
		>
			{children}
		</PanelsContext.Provider>
	);
}
