import { useState, ReactNode, useEffect, useCallback } from "react";

import { StickyComponentCallback, StickyComponentDetails } from "../types";
import { StickyContainerContext } from "../hooks/useStickyContainerContext";

export function StickyContainer({ children }: { children: ReactNode }) {
	const [stickyComponents, setStickyComponents] = useState<{ [id: number]: StickyComponentDetails }>({});
	const [sortedIds, setSortedIds] = useState<number[]>([]);

	const registerComponent = useCallback((id: number, height: number, callback: StickyComponentCallback) => {
		setStickyComponents((prev) => ({ ...prev, [id]: { height, callback } }));
		setSortedIds((prev) => Array.from(new Set([...prev, id])).sort((a, b) => a - b));
	}, []);

	const unregisterComponent = useCallback((id: number) => {
		setStickyComponents((prev) => {
			const newStickyComponents = { ...prev };
			delete newStickyComponents[id];

			return newStickyComponents;
		});
		setSortedIds((prev) => prev.filter((sortedId) => sortedId !== id));
	}, []);

	useEffect(() => {
		let accumulatedStickyTop = 0;

		sortedIds.forEach((id) => {
			if (!stickyComponents[id]) {
				return;
			}

			const { callback, height } = stickyComponents[id];

			callback(accumulatedStickyTop);
			accumulatedStickyTop += height || 0;
		});
	}, [sortedIds, stickyComponents]);

	return (
		<StickyContainerContext.Provider value={{ registerComponent, unregisterComponent }}>
			{children}
		</StickyContainerContext.Provider>
	);
}
