import { Droppable, Draggable } from "@hello-pangea/dnd";
import { clsx } from "clsx";

import { mObjectDef } from "@salesdesk/salesdesk-model";
import { Field } from "@salesdesk/salesdesk-schemas";

import { isSystemField } from "../../../../../../fields";
import { ObjectPreviewFieldFactory } from "../../ObjectPreviewFieldFactory";
import { DropPlaceholder } from "./DropPlaceholder";

interface FieldBuilderProps {
	dropIndex: number | null;
	activeFieldId: number;
	draggingSourceId: string | null;
	onFieldMouseDown: (fieldId: number) => void;
	onFieldDelete: (fieldId: number) => void;
	fields: Field[];
	invalidObjectFieldIds: number[];
}

const FIELD_BUILDER_DROPPABLE_ID = "FieldBuilder";

export function FieldBuilder({
	dropIndex,
	activeFieldId,
	onFieldMouseDown,
	draggingSourceId,
	onFieldDelete,
	fields,
	invalidObjectFieldIds,
}: FieldBuilderProps) {
	return (
		<Droppable droppableId={FIELD_BUILDER_DROPPABLE_ID}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					className="mt-1 w-full max-w-[600px] overflow-auto px-4 pb-6"
					{...provided.droppableProps}
				>
					{fields.map((field, index, fieldsArr) => {
						const isLastElement = fieldsArr.length - 1 === index;
						const isDropInTheEnd = dropIndex && dropIndex > fieldsArr.length - 1;

						return (
							<Draggable key={field._id} draggableId={String(field._id)} index={index}>
								{(provided) => {
									/* 
										draggingStyles has to be different depending on droppable element 

										if we drop element from FieldPicker it must be dropped without animation

										if we dragging and dropping Field from FieldBuilder it must be animated
									*/
									const draggingStyles =
										draggingSourceId === FIELD_BUILDER_DROPPABLE_ID ? provided.draggableProps.style : {};
									const isFieldInvalid = invalidObjectFieldIds.includes(field._id);
									const isFieldDeletable = !isSystemField(field) && mObjectDef.NAME_FIELD_NAME !== field._name;
									const isActive = field._id === activeFieldId;

									return (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={draggingStyles}
											onMouseDown={() => onFieldMouseDown(field._id)}
										>
											<DropPlaceholder active={index === dropIndex} />
											<div
												className={clsx(
													isActive && (isFieldInvalid ? "wide-dashed-border-error" : "wide-dashed-border-active"),
													"bg-c_bg_01 flex items-center justify-center py-2",
													"pl-[46px] pr-[42px]"
												)}
											>
												<ObjectPreviewFieldFactory
													key={field._id}
													field={field}
													error={isFieldInvalid}
													padlock={isSystemField(field) ? "Default fields cannot be removed" : undefined}
													onRemove={isActive && isFieldDeletable ? () => onFieldDelete(field._id) : undefined}
													isDraggable={isActive}
												/>
											</div>
											{isLastElement ? <DropPlaceholder active={Boolean(isDropInTheEnd)} /> : null}
										</div>
									);
								}}
							</Draggable>
						);
					})}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
}
