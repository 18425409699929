import {
	SDRecord,
	SDObject,
	getSDRecordFieldValueByFieldName,
	URI_PATHS,
	TimeRange,
	getSDRecordName,
} from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";

import { convertRichTextJSONToText } from "../../../../../../../fields";
import { PATHS } from "../../../../../../../../routes";
import { APP_CONFIG } from "../../../../../../../../app/app_config";

export function getGoogleCalendarLink(sdObject: SDObject, sdRecord: SDRecord) {
	const { title, meetingLink, details, start, end } = getExternalCalendarInfo(sdObject, sdRecord);

	const text = title ? `&text=${title}` : "";
	const startDate = start ? convertGoogleDateFormat(start) : "";
	const endDate = end ? convertGoogleDateFormat(end) : "";
	const formattedDates = start && end ? `&dates=${startDate}%2F${endDate}` : "";
	const location = `&location=${meetingLink}`;
	const description = details.length > 0 ? `&details=${details}` : "";

	return `https://www.google.com/calendar/render?action=TEMPLATE${text}${formattedDates}${description}${location}`;
}

export function getOutlookCalendarLink(sdObject: SDObject, sdRecord: SDRecord): string {
	const { title, meetingLink, details, start, end } = getExternalCalendarInfo(sdObject, sdRecord);

	const subject = title ? `&subject=${title}` : "";
	const body = details.length > 0 ? `&body=${details}` : "";
	const location = `&location=${meetingLink}`;
	const startdt = start ? `&startdt=${formatTimeToISOString(start)}` : "";
	const enddt = end ? `&enddt=${formatTimeToISOString(end)}` : "";

	return `https://outlook.live.com/calendar/0/action/compose?allday=false${body}${subject}${location}${startdt}${enddt}`;
}

function formatTimeToISOString(milliseconds: number) {
	return new Date(milliseconds).toISOString();
}

function convertGoogleDateFormat(milliseconds: number) {
	return formatTimeToISOString(milliseconds).replace(/[-:.]/g, "");
}

function getExternalCalendarInfo(sdObject: SDObject, sdRecord: SDRecord) {
	const title = encodeURIComponent(getSDRecordName(sdObject, sdRecord));

	const meetingLink = `${URI_PATHS.STACK_ROOT(APP_CONFIG.stackName)}${PATHS.MEETING(sdRecord)}`;

	let details = convertRichTextJSONToText(
		getSDRecordFieldValueByFieldName(sdObject, sdRecord, mInteractionMeetingDef.AGENDA_FIELD_NAME)?._value ?? undefined
	);
	details = details.length > 0 ? encodeURIComponent(details) : "";

	const time = getSDRecordFieldValueByFieldName(sdObject, sdRecord, mInteractionMeetingDef.TIME_FIELD_NAME)
		?._value as TimeRange;
	const start = time?.start ? time.start : undefined;
	const end = time?.end ? time.end : undefined;

	return {
		title,
		meetingLink,
		details,
		start,
		end,
	};
}
