import { useParams } from "react-router-dom";

import { getSDObjectId } from "../features/objects/utils/objects";
import { useGetObjectsQuery } from "../features/objects/api/objectsApi";

import { SDObject } from "@salesdesk/salesdesk-schemas";
import { defaultObjectFilter } from "../utils/defaultObjectFilter";

export function useGetObjectFromURLPathById(filter: (object: SDObject) => boolean = defaultObjectFilter) {
	const { sdObjectId } = useParams<{ sdObjectId: string }>();

	const { sdObject, isLoading } = useGetObjectsQuery(undefined, {
		selectFromResult: ({ data, isLoading, isUninitialized }) => {
			return {
				sdObject: data?.find((object) => String(getSDObjectId(object)) === sdObjectId && filter(object)),
				isLoading: isLoading || isUninitialized, // isLoading is false before the request is initialised
			};
		},
	});

	return { sdObjectId, sdObject, isLoading };
}
