import { createBrowserRouter, Navigate } from "react-router-dom";

import { ERRORS } from "@salesdesk/salesdesk-ui";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { ErrorPage } from "../../pages";
import { DashboardPage } from "../../features/Dashboard";
import { InternalObjectBoardPage } from "../../features/ObjectBoard";
import { AppProvider, MainLayout } from "../../features/MainLayout";
import { MeetingPageContainer } from "../../features/meetings";
import { BookmarkRedirect } from "../../features/bookmarks";
import { RecordDetailViewPage } from "../../features/records";

import {
	NewObjectDesignerPage,
	ObjectDesignerPage,
	ObjectManagerPage,
	ProfileSettingsPage,
	RolesPage,
	SettingsLayout,
	AccountPage,
	UsersPage,
	NotificationRulesPage,
	NotificationsPage,
	WatchlistPage,
} from "../../features/settings";

import {
	WorkspaceLibraryPage,
	WorkspaceObjectBoardPage,
	WorkspaceOverviewPage,
	WorkspacePageContainer,
	WorkspaceResourcesPage,
	WorkspacesErrorPage,
	WorkspacesListPage,
	WorkspacesPageContainer,
	WorkspaceSettingsPage,
	WorkspaceRecordDetailsPage,
} from "../../features/workspaces";

import { ImportLayout, ImportUploadPage } from "../../features/import";
import { InvitationPage } from "../../features/landing/invitation";
import { StartPage } from "../../features/landing/start";
import { LoginPage } from "../../features/authorization";

import {
	viewObjectManagerPageActions,
	viewRolesPageActions,
	viewTenantSettingsPageActions,
	viewUsersPageActions,
} from "../../auth";
import { routeTo } from "../utils/routeTo";
import { StartPageRedirect } from "../../features/landing/start/components/StartPageRedirect";
import { AllowRouteIf, SalesDeskUserProtectedRoute } from "../components";
import { ImportMappingPage } from "../../features/import/components/ImportMappingPage/ImportMappingPage";
import { ImportExcutionPage } from "../../features/import/components/ImportExecutionPage/ImportExecutionPage";
import { DeveloperPage } from "../../features/settings/developer/components/DeveloperPage";

// WARNING! Make sure to update all relevant routes/paths in ROUTES.ts, URI_PATHS.ts and UI_PATHS in @salesdesk/salesdesk-schemas
// whenever any existing routes are changed in the app.
export const router = createBrowserRouter([
	{
		path: "invitation",
		element: <InvitationPage />,
	},
	{
		path: "start",
		element: <StartPage />,
	},
	{
		path: "login",
		element: <LoginPage />,
	},
	{
		path: "app",
		element: <AppProvider />,
		children: [
			{
				path: ":tenantId",
				children: [
					{
						element: <SalesDeskUserProtectedRoute />,
						children: [
							{
								element: <MainLayout />,
								children: [
									{ path: "", element: <DashboardPage /> },
									{
										path: "objects/:sdObjectPath",
										children: [
											{
												path: "records",
												children: [
													{ path: "", element: <InternalObjectBoardPage /> },
													{ path: ":recordId", element: <RecordDetailViewPage /> },
												],
											},
										],
									},
								],
							},
							{
								path: "objects/:sdObjectPath/import",
								element: <ImportLayout />,
								children: [
									{
										path: "",
										element: <ImportUploadPage />,
									},
									{
										path: "mapping",
										element: <ImportMappingPage />,
									},
									{
										path: "execution",
										element: <ImportExcutionPage />,
									},
								],
							},
							{
								path: "bookmarks/:bookmarkId",
								element: <BookmarkRedirect />,
							},
						],
					},
					{
						element: <SettingsLayout />,
						children: [
							{
								path: "settings",
								children: [
									{ path: "", element: <Navigate to="settings/profile" /> },
									{ path: "profile", element: <ProfileSettingsPage /> },
									{
										path: "notifications",
										children: [
											{
												path: "",
												element: <NotificationsPage />,
											},
											{
												path: "rules",
												element: <NotificationRulesPage />,
											},
										],
									},
									{ path: "watchlist", element: <WatchlistPage /> },
									{
										path: "object-manager",
										children: [
											{
												path: "",
												element: <AllowRouteIf canAny={viewObjectManagerPageActions} />,
												children: routeTo(<ObjectManagerPage />),
											},
											{
												path: "new",
												element: <AllowRouteIf can={[AbilityAction.Create, AbilitySubject.Object]} />,
												children: routeTo(<NewObjectDesignerPage />),
											},
											{
												path: ":sdObjectId",
												element: <AllowRouteIf can={[AbilityAction.Edit, AbilitySubject.Object]} />,
												children: routeTo(<ObjectDesignerPage />),
											},
										],
									},
									{
										path: "account",
										element: <AllowRouteIf canAny={viewTenantSettingsPageActions} />,
										children: routeTo(<AccountPage />),
									},
									{
										path: "developer",
										element: <AllowRouteIf canAny={viewTenantSettingsPageActions} />,
										children: routeTo(<DeveloperPage />),
									},
									{
										path: "access",
										children: [
											{
												path: "",
												element: <Navigate to="access/roles" />,
											},
											{
												path: "roles",
												children: [
													{
														path: "",
														element: <AllowRouteIf canAny={viewRolesPageActions} />,
														children: routeTo(<RolesPage />),
													},
													{
														path: ":roleId",
														element: <AllowRouteIf canAny={viewRolesPageActions} />,
														children: routeTo(<RolesPage />),
													},
												],
											},
											{
												path: "users",
												element: <AllowRouteIf canAny={viewUsersPageActions} />,
												children: routeTo(<UsersPage isCustomersPage={false} />),
											},
											{
												path: "customers",
												element: <AllowRouteIf canAny={viewUsersPageActions} />,
												children: routeTo(<UsersPage isCustomersPage={true} />),
											},
										],
									},
								],
							},
						],
					},
					{
						path: "meetings/:meetingId",
						element: <MeetingPageContainer />,
					},
					{
						path: "workspaces",
						children: [
							{
								path: "",
								element: <WorkspacesPageContainer />,
								children: [
									{ path: "", element: <WorkspacesListPage /> },
									{ path: "error", element: <WorkspacesErrorPage /> },
								],
							},
							{
								path: ":workspaceId",
								children: [
									{
										path: "",
										element: <WorkspacePageContainer />,
										children: [
											{ path: "", element: <WorkspaceOverviewPage /> },
											{
												path: "library",
												children: [
													{
														path: "",
														element: <WorkspaceLibraryPage />,
													},
													{
														path: ":sdObjectPath/records",
														children: [
															{ path: "", element: <WorkspaceObjectBoardPage /> },
															{ path: ":recordId", element: <WorkspaceRecordDetailsPage /> },
														],
													},
												],
											},
											{ path: "objects/:sdObjectPath/records", element: <WorkspaceObjectBoardPage /> },
											{
												path: "internal",
												element: <SalesDeskUserProtectedRoute />,
												children: [
													{
														path: "resources",
														element: <WorkspaceResourcesPage />,
													},
													{ path: "settings", element: <WorkspaceSettingsPage /> },
												],
											},
										],
									},
									{ path: "meetings/:meetingId", element: <MeetingPageContainer /> },
								],
							},
						],
					},
					{ path: "error/:errorCode", element: <ErrorPage /> },
					{ path: "*", element: <Navigate replace to={`error/${ERRORS.RESOURCE_NOT_FOUND.code.toLowerCase()}`} /> },
				],
			},
		],
	},
	{ path: "*", element: <StartPageRedirect /> },
]);
