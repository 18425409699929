import { SkeletonRecordCard } from "../../../../../records";

export function CardViewLoading() {
	return (
		<div className="bg-c_bg_01 absolute left-0 top-0 z-50 h-full w-full pr-7 pt-3.5">
			<div className="grid-cols-cards grid gap-6">
				{[...Array(6)].map((_, index) => (
					<SkeletonRecordCard key={`skeleton-card-${index}`} />
				))}
			</div>
		</div>
	);
}
