import { useCallback, useEffect, useRef, useState } from "react";
import { ControlMessage, SendControlMessage, SubscribeToControlMessages } from "./useControlTrack";

type ChatMessageType = "Chat.RefreshRequest";

export interface ChatRefreshRequest extends Omit<ControlMessage, "type"> {
	type: ChatMessageType;
	timestamp: number;
}

type UnsubscribeFromChatRefreshRequests = () => void;
type ChatRefreshRequestHandler = (message: ChatRefreshRequest) => void;
export type SubscribeToChatRefreshRequests = (
	onChatRefreshRequest: ChatRefreshRequestHandler
) => UnsubscribeFromChatRefreshRequests;

export function useChatControlTrack(
	sendControlMessage: SendControlMessage | null,
	subscribeToControlMessages: SubscribeToControlMessages
) {
	const [sendChatRefreshRequest, setSendChatRefreshRequest] = useState<(() => void) | null>(null);
	const subscribersRef = useRef<ChatRefreshRequestHandler[]>([]);
	const subscribeToChatRefreshRequests = useCallback<SubscribeToChatRefreshRequests>(
		(onChatRefreshRequest: ChatRefreshRequestHandler) => {
			subscribersRef.current.push(onChatRefreshRequest);

			return () => {
				subscribersRef.current.splice(subscribersRef.current.indexOf(onChatRefreshRequest), 1);
			};
		},
		[]
	);

	useEffect(() => {
		if (sendControlMessage != null)
			setSendChatRefreshRequest(() => () => {
				sendControlMessage({ type: "Chat.RefreshRequest", timestamp: new Date().getTime() } as ChatRefreshRequest);
			});
	}, [sendControlMessage]);

	useEffect(() => {
		return subscribeToControlMessages((_: string, message: ControlMessage) => {
			if (message.type === "Chat.RefreshRequest") {
				subscribersRef.current.forEach((subscriber) => {
					try {
						subscriber(message as ChatRefreshRequest);
					} catch (error) {
						console.error(`Chat message subscriber failed handling ${message.type} request`, error);
					}
				});
			}
		});
	}, [subscribeToControlMessages]);

	return { sendChatRefreshRequest, subscribeToChatRefreshRequests };
}
