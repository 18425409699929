import { Comment } from "@salesdesk/salesdesk-schemas";
import { SingleComment } from "./CommentThread/SingleComment";

interface CommentPreviewProps {
	comment: Comment;
	inDocumentText?: string;
}

export function CommentPreview({ comment, inDocumentText }: CommentPreviewProps) {
	return (
		<div className="border-c_border_regular rounded-minimal max-h-80 overflow-auto border px-1 py-3">
			<SingleComment comment={comment} inDocumentText={inDocumentText} showControls={false} />
		</div>
	);
}
