import { asConst } from "json-schema-to-ts";
import { importPathSchema } from "./importPathSchema";
import { importIdPathParamSchema } from "./importIdPathParamSchema";
import { jobIdPathParamSchema } from "./jobIdPathParamSchema";

export const getImportJobPathSchema = asConst({
	...importPathSchema,
	title: "Get Import Job Path Parameters",
	type: "object",
	additionalProperties: false,
	required: ["importId", "jobId"],
	properties: {
		importId: importIdPathParamSchema,
		jobId: jobIdPathParamSchema,
	},
});
