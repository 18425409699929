import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { DATE_GROUPING, FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";
import { getUnixTimestampNow, isEmpty } from "@salesdesk/salesdesk-utils";

export class mTimeRangeFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TIME_RANGE.name];
	static defaultComponentType: FieldComponentName = mTimeRangeFieldDef.supportedComponentTypes[0];

	static dateRangeFormat = "dd/mm/yyyy - dd/mm/yyyy";
	static dateTimeRangeFormat = "dd/mm/yyyy hh:mm - dd/mm/yyyy hh:mm";

	_supportsPast = true;
	_dateOnly = false;

	constructor(id: string | number) {
		super(id);
		this._componentType = mTimeRangeFieldDef.defaultComponentType;

		// How the data is stored internally (ISO8601 - YYYY-MM-DDTHH:mm:ss.sssZ)
		this._format = "YYYY-MM-DDTHH:mm";

		this._formatDescription = null;

		this._icon = FIELD_TYPES.TIME_RANGE.icon;
		this._supportsDefaultValue = false;
	}

	override get type() {
		return FIELD_TYPES.TIME_RANGE.name;
	}

	override get supportedComponentTypes() {
		return mTimeRangeFieldDef.supportedComponentTypes;
	}

	get supportsPast() {
		return this._supportsPast;
	}

	set supportsPast(supportsPast) {
		this._supportsPast = supportsPast;
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}

	override supportsDateOnly() {
		return true;
	}

	override supportsGrouping() {
		return !this.supportsMultiple() && !this.hidden && this.required;
	}

	supportsReGrouping() {
		return false;
	}

	validateDate(epochMs: number, name: string) {
		if (isNaN(epochMs) || epochMs < 0) {
			return `Enter a valid ${name} date${this._dateOnly ? "" : " and time"}.`;
		}

		const date = new Date(epochMs);
		if (
			this._dateOnly &&
			(date.getUTCHours() !== 0 ||
				date.getUTCMinutes() !== 0 ||
				date.getUTCSeconds() !== 0 ||
				date.getUTCMilliseconds() !== 0)
		) {
			return `Enter a valid ${name} date with no time.`;
		}

		return undefined;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		const validationError = super.validate(value, isTemplate);
		if (validationError != null) return validationError;

		if (value == null) return;

		const { start, end, ...rest } = value;

		if (Object.keys(rest).length > 0) return `Invalid additional properties`;

		const isStartNull = isEmpty(start);
		const isEndNull = isEmpty(end);

		if (!isTemplate && this._required && (isStartNull || isEndNull)) {
			return `Enter a valid start and end date${this._dateOnly ? "" : " and time"}.`;
		}
		if (!isTemplate && !this._required && ((!isStartNull && isEndNull) || (isStartNull && !isEndNull))) {
			return `Start and end date${this._dateOnly ? "" : " and time"} should both be populated if either is.`;
		}
		if (!isStartNull) {
			const startValidation = this.validateDate(start, "start");
			if (startValidation != null) return startValidation;
		}

		if (!isEndNull) {
			const endValidation = this.validateDate(end, "end");
			if (endValidation != null) return endValidation;
		}

		if (!isStartNull && !this._supportsPast && start < getUnixTimestampNow(this._dateOnly)) {
			return `Start date cannot be in the past`;
		}

		if (!isStartNull && !isEndNull && end < start) {
			return `End date should be after start date.`;
		}

		return undefined;
	}

	get groupInfo() {
		return DATE_GROUPING;
	}
}
