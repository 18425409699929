import { useEffect, PropsWithChildren, ReactElement } from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";

import { Field, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { UploadProgressStatus } from "../../../../fields";
import { BasicFormState, RECORD_FIELDS_KEY } from "../types";
import { RecordFormField } from "./RecordFormField";
import { getFullRecordFormFieldId } from "../utils";
import { useUploadProgressPerField } from "../hooks";
import { DirectedSDObjectAssociation } from "../../../../recordAssociations";

export interface RecordFormProps {
	id?: string;
	fields: Field[];
	onSubmit: (data: Record<string, any>, resetForm: (formValues?: Record<string, any>) => void) => void;
	onFormStateChange?: (formState: BasicFormState) => void;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
	columnWidth?: number;
	disabled?: boolean;
	associations?: DirectedSDObjectAssociation[];
	defaultValues?: Record<string, any>;
	beforeFieldsChildren?: ReactElement;

	// Used to enforce CASL/Edit rules for the fields, or perform unique field checks
	sdRecord?: SDRecord;
	sdObject?: SDObject;
}

export function RecordForm({
	id,
	fields,
	onSubmit,
	onFormStateChange,
	updateUploadProgressStatus,
	columnWidth = 355,
	disabled = false,
	defaultValues,
	children,
	beforeFieldsChildren,
	sdRecord,
	sdObject,
}: PropsWithChildren<RecordFormProps>) {
	const formMethods = useForm({
		mode: "onChange",
		defaultValues,
	});

	const { control, handleSubmit, reset, formState, setFocus } = formMethods;

	const { uploadInProgress, setUploadProgressStatusPerField } = useUploadProgressPerField(updateUploadProgressStatus);

	useEffect(() => {
		if (onFormStateChange) {
			onFormStateChange({ isValid: formState.isValid, isDirty: formState.isDirty });
		}
	}, [formState, onFormStateChange]);

	useEffect(() => {
		// Timeout to give the form time to initialise the fields before focusing on the first one
		if (fields.length) {
			const fieldId = String(fields[0]._id);
			const fullFieldId = getFullRecordFormFieldId(RECORD_FIELDS_KEY, fieldId);
			setTimeout(() => setFocus(fullFieldId), 10);
		}
	}, [fields, setFocus]);

	return (
		<form
			id={id}
			className="relative flex flex-col gap-8"
			onSubmit={(event) => {
				// Prevents a nested form (i.e. one in a dialog opened by a parent form)
				// from causing a submit event in its parent form.
				event.preventDefault();
				event.stopPropagation();

				if (disabled) {
					return;
				}

				if (uploadInProgress) {
					return;
				}

				return handleSubmit((data) => onSubmit(data, reset))(event);
			}}
		>
			<FormProvider {...formMethods}>
				{beforeFieldsChildren}
				<div className="columns-2 gap-10" style={{ columnWidth: `${columnWidth}px` }}>
					{/* Quick fix for chrome CSS bug regarding `columns/columnWidth` where wider dom elements increase the size
			 			of the columns even if they are contained within an `overlow-hidden` container of width 100%.

			 			This fix doesn't affect any other browsers and just ensures that file fields
			 			don't expand the form columns when an image/video file is uploaded. */}
					<div className="w-[1000px]" />
					{fields.map((field) => {
						const fieldName = getFullRecordFormFieldId(RECORD_FIELDS_KEY, String(field._id));
						return (
							<RecordFormField
								fieldName={fieldName}
								key={field._id}
								formControl={control}
								field={field}
								setUploadProgressStatusPerField={setUploadProgressStatusPerField}
								disabled={disabled}
								sdRecord={sdRecord}
								sdObject={sdObject}
							/>
						);
					})}
				</div>
				{children}
			</FormProvider>
		</form>
	);
}
