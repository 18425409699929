import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { mLeadDef, mContactDef, mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { FeatureInfo } from "./FeatureInfo";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";
import { useGetObjectMap } from "../../../objects/hooks";
import { useOnboardingContext } from "../../hooks";
import { OnboardingFlow } from "../../utils";

interface WelcomeDialogProps {
	onClose: () => void;
	handleStepByStepTour: () => void;
}

export function WelcomeDialog({ onClose, handleStepByStepTour }: WelcomeDialogProps) {
	const { startFlow } = useOnboardingContext();

	const objectMap = useGetObjectMap();

	const leadSDObject = objectMap.get(mLeadDef.ID);
	const contactSDObject = objectMap.get(mContactDef.ID);
	const meetingSDObject = objectMap.get(mInteractionMeetingDef.ID);

	const featureInfos =
		leadSDObject && contactSDObject && meetingSDObject
			? getFeaturesInfos(leadSDObject, contactSDObject, meetingSDObject)
			: [];

	const handleAddLead = () => startFlow(OnboardingFlow.ADD_FIRST_LEAD);

	return (
		<Dialog open>
			<DialogContent>
				<div className="flex w-[1000px] flex-col gap-9 pb-6 pt-2">
					<div className="flex flex-col gap-3 text-center">
						<h2 className="text-h2 text-c_brand_primary">Welcome to SalesDesk</h2>
						<p className="text-body">
							Ready to transform your sales processes with AI-driven collaborative selling? <br />
							Let's get you to that aha! moment as quickly as possible!
						</p>
					</div>
					<div className="grid grid-cols-3 gap-14">
						{featureInfos.map(({ imageUrl, description }, index) => (
							<FeatureInfo key={imageUrl} index={index + 1} imageUrl={imageUrl} description={description} />
						))}
					</div>
					<div className="flex items-center gap-6 self-center">
						<Button onClick={onClose} variant="secondary">
							Explore on my own
						</Button>
						<Button startIcon={ICONS.database} onClick={handleAddLead}>
							Add a {leadSDObject?._displayName}
						</Button>
						<Button onClick={handleStepByStepTour} startIcon={ICONS.sparkle}>
							Step-by-step tour
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

function getFeaturesInfos(leadObject: SDObject, contactObject: SDObject, meetingObject: SDObject) {
	return [
		{
			imageUrl: "/static/images/onboarding/record-a-lead.svg",
			description: `Record a ${capitalizeString(leadObject._displayName)} in your SalesDesk databoard`,
		},
		{
			imageUrl: "/static/images/onboarding/collaborate-with-lead.svg",
			description: `Collaborate with your ${capitalizeString(leadObject._pluralName)} and ${capitalizeString(
				contactObject._pluralName
			)} in a workspace`,
		},
		{
			imageUrl: "/static/images/onboarding/watch-meeting-recording.svg",
			description: `Watch ${meetingObject._displayName} recordings, see transcript & analysis`,
		},
	];
}
