import { getCurrentUserId, isEmpty } from "@salesdesk/salesdesk-utils";

import { mObjectInst } from "../object_inst";

export class mUser extends mObjectInst {
	static CLASS_NAME = "mUser";

	constructor(id, objectDef, ownerId) {
		super(id, objectDef, ownerId);
		super.className = mUser.CLASS_NAME;
	}

	get name() {
		if (this.hasName()) {
			return this.fullName;
		}
		return super.name;
	}

	set name(name) {
		return;
	}

	get displayName() {
		if (getCurrentUserId() === this.id) {
			return "You";
		} else {
			return this.name;
		}
	}

	get fullName() {
		let firstName = this.data.firstName;
		let surname = this.data.surname;

		return `${firstName} ${surname}`;
	}

	hasName() {
		return !isEmpty(this.data.firstName);
	}

	get firstName() {
		return this.data.firstName;
	}

	get surname() {
		return this.data.surname;
	}

	get email() {
		return this.data.email;
	}

	get mobile() {
		if (this.data.mobiles && this.data.mobiles[0]) {
			return this.data.mobiles[0].value;
		}
	}

	get title() {
		return this.data.title;
	}

	get roles() {
		return this.data.roles;
	}

	hasTitle() {
		return this.data.title !== null;
	}

	get company() {
		return this.data.company;
	}

	get notes() {
		return this.data.notes;
	}

	hasEmailAddress() {
		return !isEmpty(this.data.email);
	}

	get initials() {
		let initials = "";

		let firstName = this.data.firstName;

		if (firstName) {
			initials += firstName.charAt(0);
		}

		let surname = this.data.surname;

		if (surname) {
			initials += surname.charAt(0);
		}

		return isEmpty(initials) ? "?" : initials;
	}

	get photo() {
		return this.data.photo;
	}

	hasPhoto() {
		let photoInst = this.data.getInstancesByName("photo");
		return photoInst.isStored();
	}

	photoUrl() {
		return this.data.getInstancesByName("photo").getURL();
	}

	hasIconPhoto() {
		return this.hasPhoto();
	}

	get iconPhotoUrl() {
		return this.data.getInstancesByName("photo").getURL();
	}
}
