import { tw } from "../../utils/tailwind-helpers";
import CustomSvgIcons from "./CustomSvgIcons";

interface IconProps {
	icon?: string;
	variant?: IconVariant;
	size?: IconSize;
	className?: string;
	faVariant?: FaVariant;
	fontWeight?: "bold" | "normal";
}

export type IconVariant = "outline" | "fill";
const IconVariantPhosphorMap: Record<IconVariant, string> = { outline: "ph-bold", fill: "ph-fill" };

export type IconSize = "2xl" | "xl" | "lg" | "sm" | "base" | "xs" | "xm" | "unset";

const SizeClasses = {
	base: tw`text-[1.25rem]`,
	lg: tw`text-[1.5rem]`,
	sm: tw`text-[1rem]`,
	xs: tw`text-[0.75rem]`,
	xm: tw`text-[0.875rem]`,
	xl: tw`text-[2rem]`,
	"2xl": tw`text-[2.5rem]`,
	unset: "",
};

const SvgSizeClasses = {
	base: tw`h-[1.25rem] w-auto`,
	lg: tw`h-[1.5rem] w-auto`,
	sm: tw`h-[1rem] w-auto`,
	xs: tw`h-[0.75rem] w-auto`,
	xm: tw`h-[0.875rem] w-auto`,
	xl: tw`h-[2rem] w-auto`,
	"2xl": tw`h-[2.5rem] w-auto`,
	unset: "",
};

// TODO: Remove once we've removed font-awesome from the app
type FaVariant = "fal" | "fa-thin" | "fa-solid"; // exists to support legacy font-awesome icons

export function Icon({
	className,
	icon,
	variant = "outline",
	faVariant = "fal",
	size = "base",
	fontWeight = "normal",
}: IconProps) {
	if (!icon) {
		return null;
	}

	const fontWeightClass = fontWeight === "bold" ? tw`font-semibold` : tw`font-normal`;
	let iconClass = "";

	// font-awesome icon
	// TODO: Remove in future once fully switched to Daisy design system.
	if (icon.startsWith("fa-")) {
		iconClass = `${faVariant} ${icon}`;
	}
	// Phosphor icon
	else if (icon.startsWith("ph-")) {
		iconClass = `${IconVariantPhosphorMap[variant]} ${icon}`;
	}

	let iconElement;
	if (CustomSvgIcons[icon]) {
		const CustomSvgIcon = CustomSvgIcons[icon];
		iconElement = <CustomSvgIcon />;
	} else if (icon.endsWith(".svg")) {
		iconElement = <img src={`/static/images/icons/${icon}`} alt={icon} className={SvgSizeClasses[size]} />;
	} else {
		iconElement = (
			<i className={`${iconClass ? iconClass : "not-italic"} ${SizeClasses[size]} ${fontWeightClass}`}>
				{iconClass ? null : icon}
			</i>
		);
	}

	if (!className) {
		return iconElement;
	}

	return <span className={className}>{iconElement}</span>;
}
