import { useEffect, useMemo } from "react";
import { useGetImportPreviewMutation } from "../../../../api/importApi";
import { useImportContext } from "../../../../hooks/useImportContext";
import { generateColumns, generateRows, IMPORT_TYPE_COLUMN_ID } from "../utils";
import { ROW_IS_LOADING_PLACEHOLDER, Table } from "../../../../../Table";
import { Badge } from "../../../../../../components/Badge";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { ImportPreviewRow } from "../types";
import { getValidImportRowCount } from "../../../../utils";

export const MIN_SKELETON_ROWS = 3;
export const MAX_SKELETON_ROWS = 20;

export function ImportPreviewTable() {
	const { sdObject, sdImport, mappingEntries, previewImportJob } = useImportContext();

	const [getImportPreview, { data: previewRecords, isLoading }] = useGetImportPreviewMutation();

	const validImportRows = getValidImportRowCount(previewImportJob);

	useEffect(() => {
		if (!sdImport || validImportRows === 0) return;
		getImportPreview({ importId: sdImport.id, mappingEntries });
	}, [sdImport, mappingEntries, getImportPreview, validImportRows]);

	const columns = useMemo(() => generateColumns(sdObject), [sdObject]);

	const rows = useMemo(() => {
		if (!previewRecords) return [];
		return generateRows(sdObject, previewRecords);
	}, [sdObject, previewRecords]);

	const totalRowCount = sdImport?.rowCount || 0;
	const skeletonRows = useMemo(() => {
		return Array.from(
			{ length: Math.min(Math.max(totalRowCount, MIN_SKELETON_ROWS), MAX_SKELETON_ROWS) },
			() => ({ [ROW_IS_LOADING_PLACEHOLDER]: true }) satisfies ImportPreviewRow
		);
	}, [totalRowCount]);

	return (
		<div className="mt-8 flex h-0 w-full grow flex-col gap-8">
			<div className="flex w-full shrink-0 justify-center">
				{validImportRows > 0 ? (
					<Badge
						icon={ICONS.warning}
						text="Below is a sample of the records to be imported. Not all records are shown."
						textColor="c_text_placeholder"
						size="md"
					/>
				) : (
					<Badge icon={ICONS.warning} text="No records can be created or updated." color="c_warning_04" size="md" />
				)}
			</div>
			{validImportRows > 0 ? (
				<div className="overflow-hidden">
					<Table
						columns={columns}
						rows={isLoading ? skeletonRows : rows}
						defaultColumnWidthByColumnId={{
							[IMPORT_TYPE_COLUMN_ID]: 0,
						}}
					/>
				</div>
			) : null}
		</div>
	);
}
