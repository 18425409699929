import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";

export const hasAssociationToRecordClauseSchema = asConst({
	title: "Has Association To Record Clause",
	description: "Will match any record that is associated to the given record Id",
	type: "object",
	additionalProperties: false,
	required: ["hasAssociationToRecord"],
	properties: {
		hasAssociationToRecord: idSchema, // TODO: change to recordIdSchema when these get moved to /records
	},
	nullable: false,
});
