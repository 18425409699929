import { Dialog, DialogContent } from "../../../Dialog";
import { RecordPreviewContainer } from "./RecordPreviewContainer";

interface RecordPreviewDialogProps {
	recordId: number;
	onClose: () => void;
}

export function RecordPreviewDialog({ recordId, onClose }: RecordPreviewDialogProps) {
	return (
		<Dialog open onOpenChange={() => onClose()}>
			<DialogContent noPadding roundedSize="md">
				<RecordPreviewContainer recordId={recordId} onClose={onClose} />
			</DialogContent>
		</Dialog>
	);
}
