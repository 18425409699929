import { ChatMessage, ChatMessageCreateRequest, SDRecord } from "@salesdesk/salesdesk-schemas";

import { Auth } from "../../../auth";
import { SDApi } from "../../api";

interface PostChatMessageMutationArgs {
	meetingRecordId: SDRecord["_id"];
	chatMessageCreateRequest: ChatMessageCreateRequest;
}

export const chatApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			postChatMessage: builder.mutation<void, PostChatMessageMutationArgs>({
				query: ({ meetingRecordId, chatMessageCreateRequest }) => ({
					url: `/conferences/${meetingRecordId}/chat-messages`,
					method: "POST",
					body: chatMessageCreateRequest,
				}),
				onQueryStarted: async (arg: PostChatMessageMutationArgs, { dispatch, queryFulfilled }) => {
					const userRecord = Auth.getUser();

					const newChatMessage: ChatMessage = {
						message: arg.chatMessageCreateRequest.message,
						createdBy: userRecord._id,
						createdAt: Date.now(),
					};

					const cacheUpdate = dispatch(
						chatApi.util.updateQueryData("getChatMessages", arg.meetingRecordId, (currentMessages) => {
							return [...(currentMessages ?? []), newChatMessage];
						})
					);

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (result, error, arg) => [{ type: "MeetingChatMessages", id: arg.meetingRecordId }],
			}),
			getChatMessages: builder.query<ChatMessage[], SDRecord["_id"]>({
				query: (meetingRecordId) => `/conferences/${meetingRecordId}/chat-messages`,
				providesTags: (result, error, arg) => [{ type: "MeetingChatMessages", id: arg }],
			}),
		};
	},
});

export const { usePostChatMessageMutation, useGetChatMessagesQuery } = chatApi;
