import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Icon } from "@salesdesk/daisy-ui";
import { AssociationsSummaryData, RecordAssociationSummary } from "../../../../recordAssociations";
import { useGetRecordFollowingStatus } from "../../../hooks";
import { RecordBadges } from "../../RecordBadges";
import { RecordBadgeType } from "../../RecordBadges/types";

interface RecordCardFooterProps {
	sdRecord: SDRecord;
	associationsSummary?: AssociationsSummaryData;
	vertical: boolean;
	recordBadges: RecordBadgeType[];
}

export function RecordCardFooter({ associationsSummary, sdRecord, vertical, recordBadges }: RecordCardFooterProps) {
	const { isWatched, isFavorited } = useGetRecordFollowingStatus(sdRecord);

	const hasAssociations = Boolean(associationsSummary?.length);
	const hasFooter = isWatched || isFavorited || hasAssociations || (vertical && recordBadges.length > 0);

	if (!hasFooter) return null;

	return (
		<div className="border-c_brand_off_white flex flex-col gap-2 border-t pb-0 pt-3">
			{vertical ? <RecordBadges recordBadges={recordBadges} /> : null}
			{isWatched || isFavorited || hasAssociations ? (
				<div className="flex w-full items-center justify-between gap-2">
					{associationsSummary ? <RecordAssociationSummary summaryData={associationsSummary} groupSummaries /> : null}
					<div className="flex gap-2">
						{isWatched ? <Icon icon={ICONS.flag} variant="fill" className="text-c_action_01 flex" /> : null}
						{isFavorited ? <Icon icon={ICONS.star} variant="fill" className="text-c_action_01 flex" /> : null}
					</div>
				</div>
			) : null}
		</div>
	);
}
