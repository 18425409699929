import { useCallback, useEffect, useRef, useState } from "react";
import { Participant, Room, TrackPublication } from "twilio-video";

import { CallParticipantDetails } from "../../../types";
import { isScreenShareTrack } from "../../../../../utils";

/*
  Returns the participant that is sharing their screen (if any).
*/
export function useScreenSharingParticipants(room: Room | null, callParticipantDetails: CallParticipantDetails[]) {
	const [screenSharingParticipants, setScreenSharingParticipants] = useState<CallParticipantDetails[]>([]);
	const currentParticipantDetailsRef = useRef<CallParticipantDetails[]>(callParticipantDetails);

	const updateScreenShareParticipants = useCallback(() => {
		// Filters all of the participants who have a 'screen' track and sort them by most recent
		// (As screens are named `screen_{Date.now}` when created)
		const screenSharingParticipants = currentParticipantDetailsRef.current
			.reduce((filteredParticipants, participantDetails) => {
				if (getScreenShareTrack(participantDetails.participant)) {
					filteredParticipants.push({ ...participantDetails, variant: "screen_share" });
				}

				return filteredParticipants;
			}, [] as CallParticipantDetails[])
			.sort((a, b) => {
				const trackA = getScreenShareTrack(a.participant);
				const trackB = getScreenShareTrack(b.participant);

				return getTimestampFromScreenShareTrack(trackB) - getTimestampFromScreenShareTrack(trackA);
			});

		setScreenSharingParticipants(screenSharingParticipants);
	}, []);

	useEffect(() => {
		currentParticipantDetailsRef.current = callParticipantDetails;
		updateScreenShareParticipants();
	}, [callParticipantDetails, updateScreenShareParticipants]);

	useEffect(() => {
		if (!room) {
			setScreenSharingParticipants([]);
			return;
		}

		setTimeout(updateScreenShareParticipants, 0);

		room.on("trackPublished", updateScreenShareParticipants);
		room.on("trackUnpublished", updateScreenShareParticipants);
		room.on("trackSubscribed", updateScreenShareParticipants);
		room.on("trackUnsubscribed", updateScreenShareParticipants);
		room.on("participantDisconnected", updateScreenShareParticipants);

		// the room object does not emit 'trackPublished' events for the localParticipant,
		// so we need to listen for them here.
		room.localParticipant.on("trackPublished", updateScreenShareParticipants);
		room.localParticipant.on("trackUnpublished", updateScreenShareParticipants);

		return () => {
			room.off("trackPublished", updateScreenShareParticipants);
			room.off("trackUnpublished", updateScreenShareParticipants);
			room.off("trackSubscribed", updateScreenShareParticipants);
			room.off("trackUnsubscribed", updateScreenShareParticipants);
			room.off("participantDisconnected", updateScreenShareParticipants);

			room.localParticipant.off("trackPublished", updateScreenShareParticipants);
			room.localParticipant.off("trackUnpublished", updateScreenShareParticipants);
		};
	}, [room, updateScreenShareParticipants]);

	return screenSharingParticipants;
}

function getScreenShareTrack(participant: Participant) {
	return Array.from<TrackPublication>(participant.tracks.values()).find((track) => isScreenShareTrack(track));
}

function getTimestampFromScreenShareTrack(track?: TrackPublication) {
	return track ? Number(track.trackName.split("_")?.[1] || 0) : 0;
}
