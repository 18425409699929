import { asConst } from "json-schema-to-ts";

export const rangeClauseValueSchema = asConst({
	title: "Range Clause Value",
	type: "object",
	additionalProperties: false,
	not: {
		anyOf: [{ required: ["lt", "lte"] }, { required: ["gt", "gte"] }],
	},
	properties: {
		lt: { type: "number" },
		lte: { type: "number" },
		gt: { type: "number" },
		gte: { type: "number" },
	},
	minProperties: 1,
	maxProperties: 2,
});
