import { SdEventType } from "@salesdesk/salesdesk-model";
import { RecordUpdatedObject } from "./RecordUpdatedObject";
import { RecordAssociationObject } from "./RecordAssociationObject";
import { DefaultObject } from "./DefaultObject";
import { TaskAssignObject } from "./TaskAssignObject";
import { IssueAssignObject } from "./IssueAssignObject";
import { useEventContext } from "../../../hooks/useEventContext";
import { LeadObject } from "./LeadObject";
import { AssetObject } from "./AssetObject";
import { RecordingObject } from "./RecordingObject";
import { ChatMessageObject } from "./ChatMessageObject";
import { videoEvents } from "../../../../files/components/VideoPlayer/types";
import { FileObject } from "./FileObject";
import { MessagingChannelName } from "./MessagingChannelName";
import { ImportObject } from "./ImportObject";

export function EventObjectFactory() {
	const { event } = useEventContext();
	switch (event.event_type) {
		case SdEventType.RECORD_UPDATED:
			return <RecordUpdatedObject />;
		case SdEventType.RECORD_ASSOCIATION_CREATED:
		case SdEventType.RECORD_ASSOCIATION_DELETED:
			return <RecordAssociationObject />;
		case SdEventType.TASK_ASSIGNED:
		case SdEventType.TASK_REASSIGNED:
			return <TaskAssignObject />;
		case SdEventType.ISSUE_ASSIGNED:
		case SdEventType.ISSUE_REASSIGNED:
			return <IssueAssignObject />;
		case SdEventType.LEAD_CONVERTED_TO_CONTACT:
		case SdEventType.LEAD_CONVERTED_TO_OPPORTUNITY:
		case SdEventType.LEAD_CONVERTED_TO_ACCOUNT:
			return <LeadObject />;
		case SdEventType.FILE_PRESENTATION_STARTED:
		case SdEventType.FILE_PRESENTATION_STOPPED:
		case SdEventType.FILE_PRESENTATION_PAGE_CHANGED:
			return <AssetObject />;
		case SdEventType.RECORDING_STARTED:
		case SdEventType.RECORDING_STOPPED:
			return <RecordingObject />;
		case SdEventType.CHAT_MESSAGE_SENT:
			return <ChatMessageObject />;
		case SdEventType.MESSAGE_HUDDLE_STARTED:
		case SdEventType.MESSAGE_SENT:
		case SdEventType.MESSAGE_UPDATED:
		case SdEventType.MESSAGE_DELETED:
		case SdEventType.MESSAGE_MENTION:
		case SdEventType.MESSAGE_CHANNEL_CREATED:
		case SdEventType.MESSAGE_CHANNEL_DELETED:
		case SdEventType.MESSAGE_CHANNEL_MEMBER_CREATED:
		case SdEventType.MESSAGE_CHANNEL_MEMBER_DELETED:
			return <MessagingChannelName />;
		case SdEventType.IMPORT_JOB_STARTED:
		case SdEventType.IMPORT_JOB_COMPLETED:
		case SdEventType.IMPORT_JOB_FAILED:
			return <ImportObject />;
		case SdEventType.COPIED_TO_CLIPBOARD:
		case SdEventType.USER_LOGGED_IN:
		case SdEventType.USER_LOGGED_OUT:
			return null;
	}
	if ([...videoEvents, SdEventType.PAGE_CHANGED].includes(event.event_type)) {
		return <FileObject />;
	}

	return <DefaultObject />;
}
