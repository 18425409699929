import {
	Comment,
	CommentReaction,
	CreateCommentRequest,
	MyCommentReactionPath,
	UpdateCommentRequest,
} from "@salesdesk/salesdesk-schemas";

import { SDApi, withWorkspaceContext } from "../../../../api";
import { Auth } from "../../../../../auth";

interface BaseCommentsArg {
	recordId: number;
	workspaceId?: number;
}

interface GetCommentArg extends BaseCommentsArg {
	commentId: string;
}

type GetCommentsArg = BaseCommentsArg;

interface CreateCommentArg extends BaseCommentsArg {
	comment: CreateCommentRequest;
}

interface UpdateCommentArg extends BaseCommentsArg {
	commentId: string;
	comment: UpdateCommentRequest;
}

type ToggleReactionArg = Omit<MyCommentReactionPath, "recordId"> & BaseCommentsArg;

export const documentCommentsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getComment: builder.query<Comment, GetCommentArg>({
				query: ({ recordId, commentId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments/${commentId}`, workspaceId),
				}),
				providesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
			getComments: builder.query<Comment[], GetCommentsArg>({
				query: ({ recordId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments`, workspaceId),
				}),
				providesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
			createComment: builder.mutation<Comment, CreateCommentArg>({
				query: ({ recordId, workspaceId, comment }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments`, workspaceId),
					method: "POST",
					body: comment,
				}),
				onQueryStarted: async ({ recordId, workspaceId }: CreateCommentArg, { dispatch, queryFulfilled }) => {
					queryFulfilled.then(({ data: newComment }) => {
						const updateCachedComments = (currentComments: Comment[]) => {
							currentComments.push(newComment);
						};

						dispatch(documentCommentsApi.util.updateQueryData("getComments", { recordId }, updateCachedComments));

						if (workspaceId != null) {
							dispatch(
								documentCommentsApi.util.updateQueryData("getComments", { recordId, workspaceId }, updateCachedComments)
							);
						}
					});
				},
			}),
			deleteComment: builder.mutation<"OK", { recordId: number; commentId: string; workspaceId?: number }>({
				query: ({ recordId, commentId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments/${commentId}`, workspaceId),
					method: "DELETE",
				}),
				invalidatesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
			updateComment: builder.mutation<Comment, UpdateCommentArg>({
				query: ({ recordId, commentId, workspaceId, comment }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments/${commentId}`, workspaceId),
					method: "PUT",
					body: comment,
				}),
				onQueryStarted: async (
					{ recordId, commentId, workspaceId, comment: updatedComment },
					{ dispatch, queryFulfilled }
				) => {
					const updateCachedComments = (currentComments: Comment[]) => {
						const comment = currentComments.find((comment) => comment.id === commentId);

						if (comment) {
							comment.updatedAt = Date.now();
							comment.message = updatedComment.message;
						}
					};

					const cacheUpdate = dispatch(
						documentCommentsApi.util.updateQueryData("getComments", { recordId }, updateCachedComments)
					);

					let workspaceCacheUpdate;
					if (workspaceId != null) {
						workspaceCacheUpdate = dispatch(
							documentCommentsApi.util.updateQueryData("getComments", { recordId, workspaceId }, updateCachedComments)
						);
					}

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();

						if (workspaceCacheUpdate) {
							workspaceCacheUpdate.undo();
						}
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
			resolveComment: builder.mutation<Comment, { recordId: number; commentId: string; workspaceId?: number }>({
				query: ({ recordId, commentId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments/${commentId}/resolve`, workspaceId),
					method: "POST",
				}),
				onQueryStarted: async ({ recordId, commentId, workspaceId }, { dispatch, queryFulfilled }) => {
					const updateCachedComments = (currentComments: Comment[]) => {
						const comment = currentComments.find((comment) => comment.id === commentId);

						if (comment) {
							comment.resolvedAt = Date.now();
						}
					};

					const cacheUpdate = dispatch(
						documentCommentsApi.util.updateQueryData("getComments", { recordId }, updateCachedComments)
					);

					let workspaceCacheUpdate;
					if (workspaceId != null) {
						workspaceCacheUpdate = dispatch(
							documentCommentsApi.util.updateQueryData("getComments", { recordId, workspaceId }, updateCachedComments)
						);
					}

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();

						if (workspaceCacheUpdate) {
							workspaceCacheUpdate.undo();
						}
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
			addCommentReaction: builder.mutation<void, ToggleReactionArg>({
				query: ({ recordId, commentId, emoji, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments/${commentId}/reactions/me/${emoji}`, workspaceId),
					method: "PUT",
				}),
				onQueryStarted: async ({ recordId, commentId, emoji, workspaceId }, { dispatch, queryFulfilled }) => {
					const userRecord = Auth.getUser();

					if (!userRecord) {
						return;
					}

					const cacheUpdate = dispatch(
						documentCommentsApi.util.updateQueryData("getComments", { recordId, workspaceId }, (currentComments) => {
							const comment = currentComments?.find((comment: Comment) => comment.id === commentId);

							if (!comment) {
								return;
							}

							const reaction = comment.reactions?.find((reaction: CommentReaction) => reaction.emoji === emoji);

							if (reaction) {
								reaction.userRecordIds.push(userRecord._id);
							} else {
								comment.reactions?.push({ emoji, createdAt: Date.now(), userRecordIds: [userRecord._id] });
							}
						})
					);

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
			removeCommentReaction: builder.mutation<void, ToggleReactionArg>({
				query: ({ recordId, commentId, emoji, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/comments/${commentId}/reactions/me/${emoji}`, workspaceId),
					method: "DELETE",
				}),
				onQueryStarted: async ({ recordId, commentId, emoji, workspaceId }, { dispatch, queryFulfilled }) => {
					const userRecord = Auth.getUser();

					if (!userRecord) {
						return;
					}

					const cacheUpdate = dispatch(
						documentCommentsApi.util.updateQueryData("getComments", { recordId, workspaceId }, (currentComments) => {
							const comment = currentComments?.find((comment: Comment) => comment.id === commentId);

							if (!comment) {
								return;
							}

							const reaction = comment.reactions?.find((reaction: CommentReaction) => reaction.emoji === emoji);

							if (!reaction) {
								return;
							}

							reaction.userRecordIds = reaction.userRecordIds.filter((id: number) => id !== userRecord._id);

							if (reaction.userRecordIds.length === 0) {
								Object.assign(comment, {
									...comment,
									reactions: comment.reactions.filter((r: CommentReaction) => r.emoji !== emoji),
								});
							}
						})
					);

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Comments", id: arg.recordId }],
			}),
		};
	},
});

export const {
	useGetCommentQuery,
	useGetCommentsQuery,
	useCreateCommentMutation,
	useDeleteCommentMutation,
	useUpdateCommentMutation,
	useResolveCommentMutation,
	useAddCommentReactionMutation,
	useRemoveCommentReactionMutation,
} = documentCommentsApi;
