import { useCallback } from "react";

import { SDObject, SDRecord, SDRecordCreateRequest } from "@salesdesk/salesdesk-schemas";

import { AssociateRecordDetails } from "../../../../../recordAssociations/types";
import { getRecordAssociationPathData } from "../../../../../recordAssociations";
import { getRecordCreatedParams } from "../../../../../Toasts/utils";
import { useToast } from "../../../../../Toasts";
import { useCreateRecord, useGetRecordDefaultPath } from "../../../../hooks";
import { useCreateRecordAssociation } from "../../../../../recordAssociations";

export function useHandleRecordCreation(overrideWorkspaceId?: number) {
	const { createRecord, isLoading: creatingRecord } = useCreateRecord(overrideWorkspaceId);

	const toast = useToast();
	const { createAssociation, isLoading: isLinkingRecordLoading } = useCreateRecordAssociation();
	const getRecordDefaultPath = useGetRecordDefaultPath();

	const handleRecordCreation = useCallback(
		(
			recordCreateRequest: SDRecordCreateRequest,
			recordObject: SDObject,
			onCreationComplete: (newSDRecord: SDRecord) => void,
			associateRecordDetails?: AssociateRecordDetails
		) => {
			createRecord(recordCreateRequest)
				.then((newSDRecord: SDRecord) => {
					toast.triggerMessage({
						type: "success",
						messageKey: "record_created",
						messageParams: getRecordCreatedParams(recordObject, newSDRecord, getRecordDefaultPath),
					});

					if (!associateRecordDetails) {
						onCreationComplete(newSDRecord);
						return;
					}

					createAssociation(
						getRecordAssociationPathData(
							newSDRecord,
							associateRecordDetails.record._id,
							associateRecordDetails.association
						)
					)
						.then(() => {
							toast.triggerMessage({ type: "success", messageKey: "record_association_created" });
							onCreationComplete(newSDRecord);
						})
						.catch(() => {
							toast.triggerMessage({ type: "error", messageKey: "record_association_created" });
						});
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_created" });
				});
		},
		[createRecord, createAssociation, toast, getRecordDefaultPath]
	);

	return {
		handleRecordCreation,
		isLoading: creatingRecord || isLinkingRecordLoading,
	};
}
