import { useMemo } from "react";

import { tw } from "@salesdesk/daisy-ui";
import { Field, getSDObjectSystemFieldByName, getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import {
	InlineEditRecordField,
	InlineEditRecordFieldController,
} from "../../../../../../../records/components/RecordFields";

interface WorkspaceTitleProps {
	workspaceObject: SDObject;
	workspaceRecord: SDRecord;
}

export function WorkspaceTitle({ workspaceObject, workspaceRecord }: WorkspaceTitleProps) {
	const nameField = useMemo(() => {
		return getSDObjectSystemFieldByName(workspaceObject, mWorkspaceDef.NAME_FIELD_NAME) as Field;
	}, [workspaceObject]);

	const workspaceName = useMemo(
		() => getSDRecordName(workspaceObject, workspaceRecord),
		[workspaceObject, workspaceRecord]
	);

	return (
		<div className="mr-5 flex w-full">
			<InlineEditRecordFieldController
				sdObject={workspaceObject}
				sdRecord={workspaceRecord}
				field={nameField}
				wrapperClasses={tw`text-h2 max-w-[900px]`}
			>
				{({ inlineRecordFieldProps, editMode }) =>
					editMode ? <InlineEditRecordField {...inlineRecordFieldProps} /> : workspaceName
				}
			</InlineEditRecordFieldController>
		</div>
	);
}
