import { forwardRef } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { tw } from "../../../../../utils/tailwind-helpers";
import { Icon } from "@salesdesk/daisy-ui";

export interface UnsetPickerButtonProps {
	placeholder: string;
	hasError?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

export const UnsetPickerButton = forwardRef<HTMLButtonElement, UnsetPickerButtonProps>(
	({ placeholder, hasError, disabled, ...buttonProps }, ref) => {
		let colorClasses = "";
		if (disabled) {
			colorClasses = tw`bg-c_bg_disabled_02 text-c_text_disabled border-c_border_btn`;
		} else {
			colorClasses = clsx(
				tw`bg-c_bg_01 hover:bg-c_bg_02 active:bg-c_bg_02 focus-visible:ring`,
				hasError
					? tw`border-c_danger_02 focus-visible:border-c_danger_02 focus-visible:ring-c_danger_focus`
					: tw`border-c_border_btn focus-visible:border-c_action_01 focus-visible:ring-c_action_focus`
			);
		}

		return (
			<button
				type="button"
				ref={ref}
				{...buttonProps}
				className={clsx(
					disabled ? "cursor-not-allowed" : "transition",
					colorClasses,
					"relative flex w-fit items-center gap-2 rounded-full border px-3 py-2 "
				)}
			>
				<Icon
					className={clsx(disabled ? tw`text-c_icon_disabled` : tw`text-c_icon_regular`, "flex")}
					icon={ICONS.plus}
				/>
				<span className="text-label-sm flex items-center">{placeholder}</span>
			</button>
		);
	}
);
