import clsx from "clsx";
import { PropsWithChildren, forwardRef } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDebouncedActiveState } from "../../hooks";
import { Button } from "@salesdesk/daisy-ui";

interface PdfControlsProps {
	totalPages: number;
	currentPage: number;
	updatePageNumber: (newPageNumber: number) => void;
	onOpenPreviewClick?: () => void;
	disabled?: boolean;
}

export const PdfControlsWrapper = forwardRef<HTMLDivElement, PropsWithChildren<PdfControlsProps>>(
	({ totalPages, currentPage, updatePageNumber, children, onOpenPreviewClick, disabled }, ref) => {
		const { isActive, triggerActiveState } = useDebouncedActiveState(3000);

		const activeControls = isActive && totalPages > 1;

		const incrementPageNumber = (increment: number) => {
			triggerActiveState();
			updatePageNumber(currentPage + increment);
		};

		const interactiveElementFns = {
			onFocus: () => {
				triggerActiveState();
			},
		};

		return (
			<div
				ref={ref}
				tabIndex={disabled ? undefined : 0}
				onMouseMove={() => {
					if (!disabled) {
						triggerActiveState();
					}
				}}
				onKeyDown={(e) => {
					if (disabled) {
						return;
					}

					const code = e.code;
					if (["ArrowRight", "ArrowUp"].includes(code)) {
						incrementPageNumber(1);
					} else if (["ArrowLeft", "ArrowDown"].includes(code)) {
						incrementPageNumber(-1);
					} else {
						return;
					}

					e.preventDefault();
					e.stopPropagation();
				}}
				onClick={(e) => {
					e.stopPropagation();
					if (onOpenPreviewClick) {
						onOpenPreviewClick();
					}
				}}
				className="relative h-full w-full overflow-hidden"
			>
				{onOpenPreviewClick ? <button className="sr-only">Open expanded view</button> : null}
				{children}
				<PageNavButton
					isActive={activeControls}
					isPrev={true}
					disabled={disabled || currentPage <= 1}
					onClick={() => {
						incrementPageNumber(-1);
					}}
					{...interactiveElementFns}
				/>
				<PageNavButton
					isActive={activeControls}
					disabled={disabled || currentPage >= totalPages}
					onClick={() => incrementPageNumber(1)}
					{...interactiveElementFns}
				/>
				<div
					className={clsx(
						activeControls && !disabled ? "opacity-100" : "opacity-0",
						"bg-c_bg_01 text-c_text_secondary text-label-xs absolute bottom-0 flex h-6 w-full select-none items-center justify-center bg-opacity-50 transition"
					)}
				>
					{currentPage} / {totalPages}
				</div>
			</div>
		);
	}
);

interface PageNavButtonProps {
	isActive?: boolean;
	isPrev?: boolean;

	disabled?: boolean;
	onClick: () => void;
	onFocus: () => void;
}

function PageNavButton({ isActive, isPrev, ...buttonProps }: PageNavButtonProps) {
	return (
		<div
			className={clsx(
				isActive ? "opacity-100" : "opacity-0",
				isPrev ? "left-4" : "right-4",
				"absolute top-1/2 z-10 -translate-y-1/2 transition"
			)}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Button {...buttonProps} variant="outlined" size="sm" startIcon={isPrev ? ICONS.caretLeft : ICONS.caretRight} />
		</div>
	);
}
