import { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import clsx from "clsx";

import { tw } from "@salesdesk/daisy-ui";

import { RESOURCES_PANEL_PREVENT_CLOSE_CLASS } from "../../workspaces";
import { MessagingPanelHeader } from "./MessagingPanelHeader";
import { MessagingPanelProps } from "../types";

export function MessagingPanelContainer({ open, children, ...headerProps }: PropsWithChildren<MessagingPanelProps>) {
	return (
		<div className={clsx("absolute bottom-0 right-[9%] z-50 w-[325px]", RESOURCES_PANEL_PREVENT_CLOSE_CLASS)}>
			<MessagingPanelHeader open={open} {...headerProps} />
			<Transition
				show={open}
				className="transition-all"
				enterFrom={tw`max-h-0`}
				enterTo={tw`max-h-[460px]`}
				leaveFrom={tw`max-h-[460px]`}
				leaveTo={tw`ml-0 max-h-0`}
			>
				<div className="bg-c_bg_01 shadow-record_overlay flex h-[460px] max-h-[460px] w-full flex-col">{children}</div>
			</Transition>
		</div>
	);
}
