import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../../utils";
import { mOptionFieldDef } from "../option_field_def";

export class mMultiOptionFieldDef extends mOptionFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TYPEAHEAD_MULTI_OPTION.name];
	static defaultComponentType: FieldComponentName = mMultiOptionFieldDef.supportedComponentTypes[0];

	constructor(id: string | number, backingStoreName?: string) {
		super(id, backingStoreName);
		this._componentType = mMultiOptionFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.MULTI_OPTION.icon;
	}

	override get type(): string {
		return FIELD_TYPES.MULTI_OPTION.name;
	}

	override get supportedComponentTypes() {
		return mMultiOptionFieldDef.supportedComponentTypes;
	}

	override supportsTableCellView() {
		return false;
	}

	override supportsCardView() {
		return false;
	}

	override get formatDescription() {
		if (this._componentType === FIELD_COMPONENT_TYPES.TYPEAHEAD_MULTI_OPTION.name) {
			return `Search ${this._pluralName}`;
		}
		return super.formatDescription;
	}

	override set formatDescription(formatDescription) {
		this._formatDescription = formatDescription;
	}

	override get defaultValue() {
		return this._defaultValue;
	}

	override set defaultValue(values) {
		if (this.isBackingStore()) {
			throw new Error(`Field ${this.name} has a backing store, so does not support default options`);
		}

		if (isEmpty(values)) {
			return;
		}

		super.defaultValue = values;
	}

	// value is expected to be an array of option names/ids
	override formatValue(value: any) {
		if (isEmpty(value)) {
			return [];
		}

		const arrayValue = Array.isArray(value) ? value : [value];

		if (this.isBackingStore()) {
			return arrayValue;
		}

		const optionIds: string[] = [];

		arrayValue.forEach((optionId) => {
			let optionValue = null;

			try {
				optionValue = this.getOptionValueById(optionId);
			} catch (error) {
				// ignore
			}

			if (!optionValue && typeof value == "string") {
				try {
					optionValue = this.getOptionValueByName(optionId);
				} catch (error) {
					// ignore
				}
			}

			if (optionValue !== null && optionValue?.id) {
				optionIds.push(optionValue.id);
			}
		});

		return optionIds;
	}

	override validate(value: any, isTemplate: boolean | undefined): string | undefined {
		if (!isTemplate && this.required && isEmpty(value)) {
			return `At least one option must be selected in "${this.displayName}".`;
		}

		if (!isEmpty(value) && !this.isBackingStore()) {
			try {
				value?.forEach((optionId: string) => this.getOptionValueById(optionId));
			} catch (error) {
				return error instanceof Error ? error.message : "Invalid option selected";
			}
		}

		return super.validate(value, isTemplate);
	}

	override supportsMaxLength() {
		return false;
	}

	override supportsFormatDescription() {
		return false;
	}
}
