import { forwardRef } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { convertHHMMSSToMilliseconds, formatMillisecondsToHHMMSS } from "@salesdesk/salesdesk-utils";
import { InputValidationState } from "@salesdesk/daisy-ui";

import { DurationInputProps } from "../../types";
import { Icon, InputContainer } from "@salesdesk/daisy-ui";

// TODO: Placeholder Duration input until we implement our custom one, treat this as a legacy component
// Also note that this component doesn't allow for durations longer than 23h 59m since it's using the HTML
// time input as its implementation
export const DurationInput = forwardRef<HTMLInputElement, DurationInputProps>(
	({ id, value, onChange, onBlur, ariaAttributes, disabled, hasError }, ref) => {
		return (
			<InputContainer
				disabled={disabled}
				validationState={hasError ? InputValidationState.error : InputValidationState.initial}
				className="relative flex items-center"
			>
				<input
					id={id}
					ref={ref}
					className={clsx(disabled && "cursor-not-allowed", "max-h-10 w-full rounded-sm bg-transparent py-2 pl-4 pr-2")}
					type={"time"}
					step="1" // Step of 1 second
					value={formatMillisecondsToHHMMSS(value || 0)}
					onChange={(e) => {
						onChange(convertHHMMSSToMilliseconds(e.target.value));
					}}
					onBlur={onBlur}
					disabled={disabled}
					{...ariaAttributes}
				/>
				<Icon
					className={clsx(
						!hasError && !disabled && "text-c_icon_regular",
						hasError && "text-c_danger_02",
						disabled && "text-c_icon_disabled",
						"pointer-events-none absolute right-2 top-1/2 mr-2 flex -translate-y-1/2 items-center"
					)}
					icon={ICONS.clock}
				/>
			</InputContainer>
		);
	}
);
