import { useGetRecordWithObjectByRecordId } from "../../../../hooks";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { Icon } from "@salesdesk/daisy-ui";
import clsx from "clsx";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

interface WorkspaceNameProps {
	workspaceId: number;
	variant?: "event" | "note";
}

export function WorkspaceName({ workspaceId, variant = "event" }: WorkspaceNameProps) {
	const { sdRecord: workspace, sdObject: workspaceObject } = useGetRecordWithObjectByRecordId(workspaceId);

	if (!workspace || !workspaceObject) {
		return <Skeleton className={clsx("h-4 w-1/3", variant === "note" && "ml-8")} />;
	}
	return (
		<div className={clsx("flex items-center gap-1", variant === "note" && "ml-8")}>
			<Icon icon={workspaceObject._icon} size="sm" className="text-c_icon_disabled flex items-center" />
			<div className="text-label-xs text-c_text_disabled">{getSDRecordName(workspaceObject, workspace)}</div>
		</div>
	);
}
