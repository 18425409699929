import { useSearchRecordsQuery } from "../../../../records";
import { RecordsSearchRequest, SDObject } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";
import { getBaseFilterQuery } from "../utils";

export function useTotalUsers(
	userObjectMap: Map<number, SDObject>,
	objectIds: number[],
	isCustomersPage: boolean
): number | undefined {
	const searchRequest = useMemo(() => {
		return {
			query: getBaseFilterQuery(userObjectMap, isCustomersPage, objectIds).buildQuery(),
			size: 0,
		} satisfies RecordsSearchRequest;
	}, [userObjectMap, objectIds, isCustomersPage]);

	return useSearchRecordsQuery(searchRequest)?.data?.hitCount;
}
