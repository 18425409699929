import { asConst } from "json-schema-to-ts";
import { nonNegativeIntegerSchema, stringSchema, ulidSchema, updatableSchema } from "../components";
import { importJobStatusSchema } from "./importJobStatusSchema";
import { objectIdSchema } from "../object";
import { importSchema } from "./importSchema";
import { importJobCreateRequestSchema } from "./importJobCreateRequestSchema";

export const importJobSchema = asConst({
	title: "ImportJobSchema",
	description: "The details about an Import Job",
	type: "object",
	additionalProperties: false,
	required: [
		"id",
		"importId",
		"objectId",
		"dryRun",
		"mappingEntries",
		// TODO:  Should be this but then types don't get created correctly
		// ...importJobCreateRequestSchema.required,
		"status",
		"rowCount",
		"createdCount",
		"patchedCount",
		"errorCount",
		...updatableSchema.required,
	],
	properties: {
		id: ulidSchema,
		importId: importSchema.properties.id,
		objectId: objectIdSchema,
		...importJobCreateRequestSchema.properties,
		status: importJobStatusSchema,
		rowCount: nonNegativeIntegerSchema,
		createdCount: nonNegativeIntegerSchema,
		patchedCount: nonNegativeIntegerSchema,
		errorCount: nonNegativeIntegerSchema,
		errorFileSignedUrl: stringSchema,
		...updatableSchema.properties,
	},
});
