import { forwardRef } from "react";

import { FIELD_TYPES } from "@salesdesk/salesdesk-model";
import { Button, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { EditFieldFactoryProps, EditFieldVariant } from "../../../../fields/components/FieldFactory/EditFieldFactory";
import { DisplayFieldVariant, InlineEditFieldFactory, isFileField } from "../../../../fields";
import { RatingInput } from "../../../../inputs";

import { RichTextInputProps } from "../../../../inputs";

export interface InlineEditRecordFieldProps extends EditFieldFactoryProps {
	editMode?: boolean;
	canEditField?: boolean;
	updateFieldValue?: (fieldValue: any) => void;
	resetValue?: () => void;
	displayFieldVariant?: DisplayFieldVariant;
	editFieldVariant?: EditFieldVariant;
}

export const InlineEditRecordField = forwardRef<HTMLElement, InlineEditRecordFieldProps>(
	(
		{
			field,
			componentProps,
			editMode,
			canEditField,
			updateFieldValue,
			resetValue,
			displayFieldVariant,
			editFieldVariant = EditFieldVariant.default,
		},
		ref
	) => {
		const isFile = isFileField(field);

		if (field._type === FIELD_TYPES.RICH_TEXT.name) {
			return (
				<InlineEditFieldFactory
					ref={ref}
					edit={editMode}
					field={field}
					// RTF shouldn't update onBlur
					componentProps={
						{
							...componentProps,
							onBlur: undefined,
							bottomPanelOptions: {
								showEmojis: true,
								showMentions: true,
								rightComponent:
									editMode && canEditField ? (
										<div className="flex gap-2">
											<Button
												size="sm"
												variant="secondary"
												startIcon={ICONS.cross}
												onClick={() => {
													resetValue?.();
												}}
											/>
											<Button
												size="sm"
												variant="primary"
												startIcon={ICONS.check}
												onClick={() => updateFieldValue?.(componentProps.value)}
											/>
										</div>
									) : null,
							},

							heightClassName: tw`max-h-52`,
						} as RichTextInputProps
					}
				/>
			);
		} else if (field._type === FIELD_TYPES.RATING.name) {
			const { value, onChange, hasError } = componentProps;

			/*
				Vlad: We use RatingInput not EditRatingField returned by InlineEditFieldFactory because
				EditRatingField has difference in styling like: padding and hover background

				To avoid overcomplicated logic with rating styles we using RatingInput
				it work in InlineEditing until we don't have to show validation error for error field
			*/
			return <RatingInput ref={ref} value={value} onChange={onChange} hasError={hasError} readonly={!editMode} />;
		}

		return (
			<InlineEditFieldFactory
				ref={ref}
				edit={editMode}
				field={field}
				displayFieldVariant={
					displayFieldVariant ??
					(isFile && canEditField ? DisplayFieldVariant.card : DisplayFieldVariant.rightHandPanel)
				}
				editFieldVariant={editFieldVariant}
				componentProps={componentProps}
			/>
		);
	}
);
