import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { useGetObjectById } from "../../../../../../hooks";
import { useGetRecordDefaultPath, useUnwatchRecordMutation } from "../../../../../records";
import { useToast } from "../../../../../Toasts";
import { MenuContents, PopoverMenu } from "../../../../../menu";
import { useWebPrincipal } from "../../../../../../auth";

interface WatchlistContextMenuProps {
	sdRecord: SDRecord;
	objectId: number;
	canUnwatch: boolean;
}

export function WatchlistContextMenu({ sdRecord, objectId, canUnwatch }: WatchlistContextMenuProps) {
	const principal = useWebPrincipal();

	const getRecordPath = useGetRecordDefaultPath();

	const { sdObject } = useGetObjectById(objectId);

	const [unwatchRecord] = useUnwatchRecordMutation();
	const toast = useToast();

	if (!sdObject) {
		return null;
	}

	const recordId = sdRecord._id;

	const authorizedWorkspaceIds = principal.AuthorizedWorkspaceIds;

	const menuContents: MenuContents = [
		[
			{
				icon: ICONS.listDashes,
				text: principal.IsSalesDeskUser ? "Record detail" : "Go to record",
				link: getRecordPath(recordId, sdObject, {
					workspaceId: principal.IsSalesDeskUser
						? undefined
						: sdRecord.sharedWorkspaceIds?.find((id) => authorizedWorkspaceIds.includes(id)),
				}),
				type: "link",
			},
		],
	];

	if (canUnwatch) {
		menuContents.push([
			{
				icon: ICONS.flag,
				variant: "destructive",
				text: "Stop watching",
				onClick: () => {
					unwatchRecord(recordId)
						.unwrap()
						.then(() => {
							toast.triggerMessage({ messageKey: "record_unwatched", type: "success" });
						})
						.catch(() => {
							toast.triggerMessage({ messageKey: "record_unwatched", type: "error" });
						});
				},
				type: "button",
			},
		]);
	}

	return (
		<div className="ml-auto">
			<PopoverMenu menuContents={menuContents}>
				<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
			</PopoverMenu>
		</div>
	);
}
