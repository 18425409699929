import { store, useAppSelector } from "../../../store/store";
import { ToastsPosition } from "../types";
import { ToastController } from "./ToastController";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Tooltip, Button } from "@salesdesk/daisy-ui";
import { dismissToastById } from "../store/toastSlice";
import clsx from "clsx";
import { deleteToastAfterAnimation } from "../utils";

interface ToastsProps {
	position: ToastsPosition;
}

export const SIDE_PANEL_TOAST_WIDTH = 304;

export function Toasts({ position }: ToastsProps) {
	const toastEvents = useAppSelector((state) => state.toasts.toastEvents);

	const onDismissAll = () => {
		for (const toastEvent of toastEvents) {
			store.dispatch(dismissToastById(toastEvent.id));
			deleteToastAfterAnimation(toastEvent.id);
		}
	};

	return (
		<div
			className={clsx(
				"z-[200] flex flex-col gap-2",
				position === "side_panel" ? "absolute left-4 top-4 w-full" : "fixed left-1/2 top-10 w-fit -translate-x-1/2"
			)}
		>
			{toastEvents.length > 3 ? (
				<div className="ml-auto w-fit">
					<Tooltip text="Dismiss all">
						<Button startIcon={ICONS.cross} onClick={onDismissAll} variant="outlined" size="sm" />
					</Tooltip>
				</div>
			) : null}
			{[...toastEvents].reverse().map((toastEvent) => (
				<ToastController
					key={toastEvent.id}
					toastEvent={toastEvent}
					width={position === "side_panel" ? SIDE_PANEL_TOAST_WIDTH : undefined}
				/>
			))}
		</div>
	);
}
