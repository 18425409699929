import { Schema, NodeSpec, MarkSpec } from "prosemirror-model";
import { schema as basicSchema } from "prosemirror-schema-basic";

const mentionNodeSpec: NodeSpec = {
	inline: true,
	group: "inline",
	selectable: false,
	atom: true,
	attrs: {
		id: {},
		label: { default: null },
	},
};

const imageNodeSpec: NodeSpec = {
	inline: false,
	group: "block",
	attrs: {
		src: { default: null },
		alt: { default: null },
		title: { default: null },
	},
};

const bulletList: NodeSpec = {
	content: "listItem+",
	group: "block",
};

const orderedList: NodeSpec = {
	content: "listItem+",
	group: "block",
};

const listItem: NodeSpec = {
	content: "paragraph block*",
	defining: true,
	draggable: false,
};

const hardBreak: NodeSpec = {
	inline: true,
	group: "inline",
};

const marks: { [key: string]: MarkSpec } = {
	bold: {},
	italic: {},
	underline: {},
	strike: {},
	highlight: {
		attrs: { color: { default: "#FECACA" } },
	},
	textStyle: {
		attrs: { color: { default: null } },
	},
	comment: {},
	link: {},
};

const customSchema = new Schema({
	nodes: basicSchema.spec.nodes
		.addToEnd("mention", mentionNodeSpec)
		.addToEnd("image", imageNodeSpec)
		.addToEnd("bulletList", bulletList)
		.addToEnd("orderedList", orderedList)
		.addToEnd("listItem", listItem)
		.addToEnd("hardBreak", hardBreak),
	marks,
});

// Helper function to render text with mention support
function generateTextWithMentions(node: any, listType = "", listIndex = 0): string {
	if (node.isText) {
		return node.text || "";
	}
	if (node.type.name === "mention") {
		return `@${node.attrs["label"] ?? node.attrs["id"]}`;
	}
	if (node.type.name === "image") {
		return "";
	}

	let text = "";
	if (node.type.name === "bulletList") {
		node.forEach((child: any) => {
			text += `- ${generateTextWithMentions(child, "bulletList", 0).trim()}\n`;
		});
	} else if (node.type.name === "orderedList") {
		let index = 1;
		node.forEach((child: any) => {
			text += `${index}. ${generateTextWithMentions(child, "orderedList", index).trim()}\n`;
			index++;
		});
	} else if (node.type.name === "hardBreak") {
		text = "\n";
	} else if (node.type.name === "paragraph" || node.type.name === "heading") {
		node.forEach((child: any) => {
			text += generateTextWithMentions(child, listType, listIndex);
		});
		text = text.trimEnd() + "\n\n";
	} else {
		node.forEach((child: any) => {
			text += generateTextWithMentions(child, listType, listIndex);
		});
	}

	return text;
}

export function generateText(value?: any) {
	if (!value) return "";
	const doc = customSchema.nodeFromJSON(value);
	const text = generateTextWithMentions(doc);
	return text.trim().replace(/\n{3,}/g, "\n\n");
}
