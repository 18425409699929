import { useState, useId, useRef, useCallback, useMemo } from "react";

import { SDObject, Field, getSDObjectFields } from "@salesdesk/salesdesk-schemas";

import { UploadProgressStatus } from "../../../../fields";
import { BasicFormState } from "../types";
import { useObjectAssociations } from "../../../../recordAssociations";

interface UseRecordFormArgs {
	sdObject?: SDObject;
	withAssociations?: boolean;
	disabledFieldNames?: string[];
}

const EMPTY_ARRAY_STATIC_REF: Field[] = [];

export function useRecordForm({ sdObject, withAssociations, disabledFieldNames }: UseRecordFormArgs) {
	const formId = useId();

	const formStateRef = useRef<BasicFormState>();

	const [uploadProgressStatus, setUploadProgressStatus] = useState<UploadProgressStatus>("idle");

	const { objectAssociations, isLoading } = useObjectAssociations(
		withAssociations && sdObject ? sdObject._id : undefined
	);

	const formFields = useMemo(() => {
		if (!sdObject) {
			return [];
		}

		if (disabledFieldNames?.length) {
			return getSDObjectFields(sdObject).map((field) =>
				disabledFieldNames.includes(field._name) ? { ...field, _editable: false } : field
			);
		}

		return getSDObjectFields(sdObject);
	}, [sdObject, disabledFieldNames]);

	const onFormStateChange = useCallback((formState: BasicFormState) => (formStateRef.current = formState), []);

	return {
		formId,
		fields: formFields || EMPTY_ARRAY_STATIC_REF,
		onFormStateChange,
		formStateRef,
		objectAssociations,
		loadingObjectAssociations: isLoading && withAssociations,
		uploadProgressStatus,
		updateUploadProgressStatus: setUploadProgressStatus,
		disabledFieldNames,
		sdObject,
	};
}
