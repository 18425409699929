import { HasClaimConfig } from "../components/sections";

export const roleClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "roleView",
		label: "View",
		descriptions: {
			hasClaim: "Can view all roles",
			hasNotClaim: "Cannot view roles",
		},
	},
	{
		name: "roleEdit",
		label: "Add and Edit",
		dependsOn: ["roleView"],
		descriptions: {
			hasClaim: "Can add roles and edit all roles",
			hasNotClaim: "Cannot add or edit roles",
		},
	},
	{
		name: "roleDelete",
		label: "Delete",
		dependsOn: ["roleView"],
		descriptions: {
			hasClaim: "Can delete all roles",
			hasNotClaim: "Cannot delete roles",
		},
	},
];
