import { Logo, LogoSize, LogoSkeleton } from "../../../components/Logo";
import { useGetTenantSettingsQuery } from "../api/tenantApi";

interface AccountLogoProps {
	size?: LogoSize;
	displayCompanyName?: boolean;
}

export function CurrentAccountLogo({ size, displayCompanyName }: AccountLogoProps) {
	const { data: tenantSettings } = useGetTenantSettingsQuery();

	if (!tenantSettings) {
		return <LogoSkeleton size={size} />;
	}

	return (
		<Logo
			size={size}
			companyName={tenantSettings.accountName}
			image={tenantSettings.accountLogo}
			displayCompanyName={displayCompanyName}
		/>
	);
}
