import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDocumentCommentsContext } from "../../../hooks/useDocumentCommentsContext";
import { ButtonSelect, SelectOption } from "../../../../../../inputs";

type CommentType = "Open" | "Resolved";

interface CommentTypeFilterOption extends SelectOption {
	id: CommentType;
}

const COMMENT_TYPE_FILTER_OPTIONS: CommentTypeFilterOption[] = [
	{ id: "Open", name: "Open" },
	{ id: "Resolved", name: "Resolved" },
];

export function CommentTypeFilter() {
	const { showResolvedComments, setShowResolvedComments } = useDocumentCommentsContext();

	return (
		<ButtonSelect
			value={showResolvedComments ? "Resolved" : "Open"}
			selectPopoverOptions={{
				placement: "bottom-start",
			}}
			onChange={(id) => setShowResolvedComments?.((id as CommentType | undefined) === "Resolved")}
			options={COMMENT_TYPE_FILTER_OPTIONS}
			startIcon={ICONS.filter}
		/>
	);
}
