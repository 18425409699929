import { useGetObjectById } from "../../../../hooks/useGetObjectById";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { useGetRecordQuery, useGetRecordsQuery } from "../../../records";
import { skipToken } from "@reduxjs/toolkit/query";
import { NotificationRule, NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import {
	toActionOption,
	toChannelOptions,
	toFieldOption,
	toRecordOption,
	toUserOptions,
	toWorkspaceOption,
} from "../utils";
import { useMemo } from "react";
import { SelectOption } from "../../../inputs";
import { useGetRecordWithObjectByRecordId } from "../../../../hooks";
import { useNotificationRuleConfigs } from "./useNotificationRuleConfigs";

export interface NotificationRuleOptions {
	isLoading: boolean;
	actionOption?: SelectOption;
	workspaceOption?: SelectOption;
	recordOption?: SelectOption;
	fieldOption?: SelectOption | null;
	userOptions?: SelectOption[] | null;
	channelOptions?: SelectOption[];
}

type useNotificationRuleOptionsProps =
	| { rule: NotificationRule }
	| { ruleType: NotificationRuleType; workspaceId?: number | null };

export function useNotificationRuleOptions(props: useNotificationRuleOptionsProps): NotificationRuleOptions {
	const { ruleType, workspaceId, rule } =
		"rule" in props
			? { rule: props.rule, ruleType: props.rule.ruleType, workspaceId: props.rule.workspaceId }
			: { rule: undefined, ruleType: props.ruleType, workspaceId: props.workspaceId };

	const { data: recordData, isLoading: isLoadingRecord } = useGetRecordQuery(rule?.recordId ?? skipToken);
	// Cannot use recordData directly because skipToken will return previous result
	const record = rule?.recordId ? recordData : undefined;
	const { sdObject: objectData } = useGetObjectById(rule?.objectId ?? record?._objectDefId ?? undefined);
	const object = rule?.objectId || rule?.recordId ? objectData : undefined;

	const userIds = rule?.userIds || [];
	const { data: usersData, isLoading: isLoadingUsers } = useGetRecordsQuery(userIds.length > 0 ? userIds : skipToken);
	// Cannot use usersData directly because skipToken will return previous result
	const users = userIds.length > 0 ? usersData : undefined;
	const { sdObject: userObject } = useGetObjectById(mUserDef.ID);

	const initialWorkspaceId = workspaceId ?? undefined;
	const {
		sdRecord: workspaceData,
		sdObject: workspaceObject,
		isLoading: isLoadingWorkspace,
	} = useGetRecordWithObjectByRecordId(initialWorkspaceId);
	// Cannot use workspaceData directly because skipToken will return previous result
	const workspaceRecord = initialWorkspaceId ? workspaceData : undefined;

	const ruleConfigs = useNotificationRuleConfigs(ruleType, rule?.objectId || undefined);
	const ruleConfig = useMemo(() => {
		return ruleConfigs.find((config) => config.eventType === rule?.eventType);
	}, [rule, ruleConfigs]);

	return useMemo(() => {
		if (!rule) {
			return {
				isLoading: isLoadingWorkspace,
				workspaceOption: toWorkspaceOption(workspaceId, workspaceObject, workspaceRecord),
			};
		}

		if (!rule || (rule?.objectId && !object) || !userObject) {
			return {
				isLoading: true,
			};
		}

		return {
			isLoading: isLoadingRecord || isLoadingUsers || isLoadingWorkspace,
			actionOption: toActionOption(rule.eventType, ruleConfig, rule.ruleType),
			workspaceOption: toWorkspaceOption(workspaceId, workspaceObject, workspaceRecord),
			recordOption: toRecordOption(rule?.recordId, object, record),
			fieldOption: toFieldOption(object, rule.fieldId ?? null),
			userOptions: toUserOptions(rule?.userIds || [], userObject, users),
			channelOptions: toChannelOptions(rule.channels.map((channel) => channel.channelType)),
		};
	}, [
		rule,
		object,
		userObject,
		isLoadingRecord,
		isLoadingUsers,
		isLoadingWorkspace,
		ruleConfig,
		workspaceId,
		workspaceObject,
		workspaceRecord,
		record,
		users,
	]);
}
