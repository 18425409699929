import { ICONS } from "@salesdesk/salesdesk-ui";

import { CallParticipantDetails, useVideoCallLogicContext } from "../../../../../../VideoCallProvider";
import { Icon, Button } from "@salesdesk/daisy-ui";

interface MainPresenterBannerProps {
	mainPresenter: CallParticipantDetails;
}

export function MainPresenterBanner({ mainPresenter }: MainPresenterBannerProps) {
	const { room, stopSharingFile, isSharingScreen, toggleScreenShare } = useVideoCallLogicContext();

	const isPresenter = room?.localParticipant === mainPresenter.participant;

	// TODO: Update with user pill if user presenting is not the local participant
	return (
		<div className="text-c_text_inverted text-body-sm bg-c_bg_07 m-6 mt-0 flex h-[42px] items-center gap-1.5 rounded-full py-0.5 pl-3">
			<Icon icon={ICONS.callPresent} /> {mainPresenter.name} is presenting
			{isPresenter ? (
				<div className="ml-auto">
					<Button
						variant="primary"
						startIcon={ICONS.stopCallPresent}
						size="sm"
						onClick={() => {
							const isFileSharing = mainPresenter.variant === "file";

							if (isFileSharing && stopSharingFile) {
								stopSharingFile();
							} else if (isSharingScreen) {
								toggleScreenShare();
							}
						}}
					>
						Stop presenting
					</Button>
				</div>
			) : null}
		</div>
	);
}
