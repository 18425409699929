import { SDNotificationType } from "@salesdesk/salesdesk-schemas";

export interface NotificationTab {
	type: SDNotificationType;
	label: string;
	emptyMessage: string;
}

export const notificationTabs: NotificationTab[] = [
	{
		type: SDNotificationType.ALL,
		label: "All",
		emptyMessage: "Check this section for any updates",
	},
	{
		type: SDNotificationType.WATCHING,
		label: "Watching",
		emptyMessage: "Check this section for updates	on records you are watching",
	},
	{
		type: SDNotificationType.CUSTOM,
		label: "Custom",
		emptyMessage: "Check this section for updates on records according to your own notification rules",
	},
	{
		type: SDNotificationType.MENTION,
		label: "Mentions",
		emptyMessage: "Check this section to see when someone mentioned you",
	},
	{
		type: SDNotificationType.REACTION,
		label: "Reactions",
		emptyMessage: "Check this section to see reactions to your messages",
	},
];
