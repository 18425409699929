import { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";

import { MeetingPageContext, useGetInvitedMeetingParticipants } from "../hooks";
import { MeetingPageState } from "../types";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { useGetRecordQuery } from "../../../../records/api/recordsApi";
import { useVideoCallLogicContext } from "../../VideoCallProvider";

export function MeetingPageProvider({ meetingId, children }: PropsWithChildren<{ meetingId: number }>) {
	const {
		currentMeetingRecord,
		connectToMeeting,
		disconnect,
		connectToMeetingTimeStamp,
		setConnectToMeetingTimeStamp,
	} = useVideoCallLogicContext();

	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);
	const { data: meetingRecord, isLoading: isLoadingMeetingRecord } = useGetRecordQuery(meetingId);
	const { data: participantRecords } = useGetInvitedMeetingParticipants(meetingObject, meetingRecord);

	const [meetingPageState, setMeetingPageState] = useState<MeetingPageState>(() => {
		if (!currentMeetingRecord || meetingId !== currentMeetingRecord._id) {
			return "holding";
		}

		return "in_call";
	});

	useEffect(() => {
		if (meetingPageState === "holding" && currentMeetingRecord) {
			disconnect();
		}
	}, [currentMeetingRecord, disconnect, meetingPageState]);

	const joinMeeting = useCallback(() => {
		if (!meetingRecord) {
			return;
		}

		setMeetingPageState("in_call");
		connectToMeeting(meetingRecord);
		setConnectToMeetingTimeStamp(Date.now());
	}, [setConnectToMeetingTimeStamp, connectToMeeting, meetingRecord]);

	const leaveMeeting = useCallback(() => {
		disconnect();
		setMeetingPageState("call_ended");
	}, [disconnect]);

	const rejoinMeeting = useCallback(() => {
		disconnect();
		setMeetingPageState("holding");
	}, [disconnect]);

	return (
		<MeetingPageContext.Provider
			value={{
				meetingId,
				isLoadingMeetingRecord,
				meetingObject,
				meetingRecord,
				participantRecords,
				meetingPageState,
				joinMeetingTimeStamp: connectToMeetingTimeStamp,
				joinMeeting,
				leaveMeeting,
				rejoinMeeting,
			}}
		>
			{children}
		</MeetingPageContext.Provider>
	);
}
