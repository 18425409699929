import { useMemo, useRef } from "react";
import clsx from "clsx";

import { ChannelType } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { useGetObjectById } from "../../../../../hooks";
import { Pill } from "../../../../../components/Pill/Pill";
import { MenuContents, PopoverMenu } from "../../../../menu";
import { Searchbar } from "../../../../inputs";
import { CHANNEL_TYPE_FILTERS, getMessagingFilterOptions, MessagingFilterId } from "../types";
import { useChannelsSelector } from "../../../store/selectors/useChannelsSelector";
import { channelIsUnread } from "../../../utils";
import { ChannelPreview, ChannelPreviewSkeleton } from "./Channel";
import { useVirtualizer } from "@tanstack/react-virtual";

const CHANNEL_PREVIEW_HEIGHT = 74;

interface ChannelListViewProps {
	searchQuery: string;
	onSearchQueryChange: (searchQuery: string) => void;
	messagingFilters: MessagingFilterId[];
	onMessagingFilterChange: (filterIds: MessagingFilterId[]) => void;
	onChannelSelected: (channelArn: string) => void;
}

export function ChannelListView({
	searchQuery,
	onSearchQueryChange,
	messagingFilters,
	onMessagingFilterChange,
	onChannelSelected,
}: ChannelListViewProps) {
	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);
	const channels = useChannelsSelector();

	const channelFilterOptions = useMemo(() => {
		return getMessagingFilterOptions(workspaceObject?._displayName, workspaceObject?._icon);
	}, [workspaceObject?._displayName, workspaceObject?._icon]);

	const channelFilterMenuOptions: MenuContents = useMemo(() => {
		return channelFilterOptions.map((option) => {
			const isSelected = messagingFilters.includes(option.id);

			return {
				icon: option.icon,
				text: option.name,
				type: "button",
				selected: isSelected,
				onClick: () => {
					onMessagingFilterChange(
						isSelected ? messagingFilters.filter((filter) => filter !== option.id) : [...messagingFilters, option.id]
					);
				},
			};
		});
	}, [channelFilterOptions, messagingFilters, onMessagingFilterChange]);

	const channelListChannels = useMemo(
		() =>
			channels?.filter((channel) => {
				const { channelMetadata, channelSummary, displayName } = channel;

				// Don't show direct message channels with no messages
				if (channelMetadata.channelType === ChannelType.DirectMessage && !channelSummary.LastMessageTimestamp) {
					return false;
				}

				const hasChannelTypeFilter = messagingFilters.some((filter) => CHANNEL_TYPE_FILTERS.includes(filter));

				// If there's no channel type filter then all channel types are valid
				let validChannelType = hasChannelTypeFilter ? false : true;

				for (const filter of messagingFilters) {
					switch (filter) {
						case MessagingFilterId.Unread:
							if (!channelIsUnread(channel)) {
								return false;
							}
							break;
						case MessagingFilterId.Group:
							if (channelMetadata.channelType === ChannelType.Custom) {
								validChannelType = true;
							}
							break;
						case MessagingFilterId.DirectMessage:
							if (channelMetadata.channelType === ChannelType.DirectMessage) {
								validChannelType = true;
							}
							break;
						case MessagingFilterId.Workspace:
							if (channelMetadata.channelType === ChannelType.Workspace) {
								validChannelType = true;
							}
							break;
					}
				}

				if (!validChannelType) {
					return false;
				}

				// Check if the channel name matches the search query
				return !searchQuery || displayName?.toLowerCase().includes(searchQuery.toLowerCase());
			}),
		[channels, messagingFilters, searchQuery]
	);

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const channelPreviewVirtualizer = useVirtualizer({
		count: channelListChannels?.length ?? 0,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => CHANNEL_PREVIEW_HEIGHT,
		overscan: 5,
	});

	const virtualChannelPreviews = channelPreviewVirtualizer.getVirtualItems();
	const totalSize = channelPreviewVirtualizer.getTotalSize();
	const paddingTop = virtualChannelPreviews.length > 0 ? virtualChannelPreviews?.[0]?.start || 0 : 0;
	const paddingBottom =
		virtualChannelPreviews.length > 0
			? totalSize - (virtualChannelPreviews?.[virtualChannelPreviews.length - 1]?.end || 0)
			: 0;

	const isLoadingChannelList = channelListChannels == null;
	const noResults = !isLoadingChannelList && !channelListChannels.length && (searchQuery || messagingFilters.length);

	return (
		<div className="flex h-full max-h-full flex-col overflow-hidden">
			<div className={clsx("flex flex-col items-center gap-1 px-4", !noResults && "border-c_border_regular border-b")}>
				<div className="flex min-h-[52px] w-full items-center gap-1">
					<div className="w-full">
						{/* TODO: Currently search query is not debounced since the filtering is front-end only */}
						<Searchbar
							disabled={isLoadingChannelList}
							wrapperClass="w-full"
							isClearable
							isCollapsible={false}
							value={searchQuery}
							onChange={onSearchQueryChange}
						/>
					</div>
					<PopoverMenu menuContents={channelFilterMenuOptions}>
						<Button size="sm" variant="text" startIcon={ICONS.filter} disabled={isLoadingChannelList} />
					</PopoverMenu>
				</div>
				{messagingFilters.length > 0 && (
					<div className="flex w-full flex-wrap items-center justify-start gap-2 pb-2">
						{messagingFilters.map((filterId) => {
							const option = channelFilterOptions.find((option) => option.id === filterId);
							return (
								option && (
									<Pill
										key={filterId}
										icon={option.icon}
										onClose={() => onMessagingFilterChange(messagingFilters.filter((filter) => filter !== filterId))}
									>
										{option.name}
									</Pill>
								)
							);
						})}
					</div>
				)}
			</div>
			<div
				ref={scrollContainerRef}
				className={clsx("h-full", isLoadingChannelList ? "overflow-hidden" : "overflow-auto")}
			>
				{isLoadingChannelList ? (
					<>
						{Array.from({ length: 7 }).map((_, index) => (
							<ChannelPreviewSkeleton key={index} />
						))}
					</>
				) : (
					<>
						{paddingTop > 0 && <div style={{ height: `${paddingTop}px` }} />}
						{virtualChannelPreviews.map((virtualChannel) => {
							const channel = channelListChannels[virtualChannel.index];
							return (
								<ChannelPreview
									key={channel.arn}
									channelDetails={channel}
									onClick={() => onChannelSelected(channel?.arn)}
								/>
							);
						})}
						{paddingBottom > 0 && <div style={{ height: `${paddingBottom}px` }} />}
					</>
				)}
				{noResults ? (
					<div className="text-body-sm text-c_text_placeholder mt-2 px-3 text-center">
						Sorry, there are no results that match your search
					</div>
				) : null}
			</div>
		</div>
	);
}
