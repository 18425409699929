import { asConst } from "json-schema-to-ts";
import { fieldCreateRequestSchema } from "./fieldCreateRequestSchema";
import { fieldGroupTypeSchema } from "./properties";
import { nullableStringSchema } from "../../components";

export const fieldGroupCreateRequestSchema = asConst({
	title: "SDObject Field Group Create Request",
	description: "A schema for creating a group of SDObject Fields",
	type: "object",
	additionalProperties: false,
	required: [...fieldCreateRequestSchema.required, "_children"],
	properties: {
		...fieldCreateRequestSchema.properties,
		_type: fieldGroupTypeSchema,
		_componentType: nullableStringSchema, //TODO:  Looks like this should be removed
		_children: {
			type: "array",
			items: fieldCreateRequestSchema,
		},
	},
});
