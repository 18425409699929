import { useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { SettingsPopoverContent } from "./SettingsPopoverContent";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

export function SettingsPopover() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Popover open={isOpen} placement="bottom-end" onOpenChange={setIsOpen} keepPopoverMounted={true}>
			<Tooltip text={isOpen ? "" : "Settings"} placement="top">
				<PopoverTrigger>
					<Button startIcon={ICONS.settings} variant="text" active={isOpen} />
				</PopoverTrigger>
			</Tooltip>
			<PopoverContent initialFocus={-1}>
				<PopoverContainer>
					<SettingsPopoverContent />
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
