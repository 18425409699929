import { ReactNode } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

interface DialogHeaderProps {
	title: string;
	description?: string | ReactNode;
	variant?: "xs" | "sm" | "base";
	onClose: () => void;
}

export function DialogHeader({ title, description, variant = "base", onClose }: DialogHeaderProps) {
	return (
		<div className="flex w-full justify-between">
			<div className={clsx("flex flex-col items-baseline justify-between", variant === "base" ? "gap-4" : "gap-2")}>
				<div className="text-h3">{title}</div>
				{description ? <div className="text-body">{description}</div> : null}
			</div>
			<Button startIcon={ICONS.cross} onClick={onClose} variant="text" size="sm" />
		</div>
	);
}
