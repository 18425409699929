import { createSlice } from "@reduxjs/toolkit";

export const appStateSlice = createSlice({
	name: "appState",
	initialState: {
		isResizing: false,
		// Used to reset/sync up the components in the data board that have to manage data board
		// state separately from the data board itself (e.g. used to re-mount the Filters popover
		// when clearing the set filters)
		dataBoardStateSyncIteration: 0,
	},
	reducers: {
		updateIsResizing: (state, action) => {
			state.isResizing = action.payload;
		},
		triggerDataBoardStateSync: (state) => {
			state.dataBoardStateSyncIteration += 1;
		},
	},
});

export const { updateIsResizing, triggerDataBoardStateSync } = appStateSlice.actions;
export const appStateReducer = appStateSlice.reducer;
