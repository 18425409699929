import { useGetObjectById } from "../../../../../hooks";
import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";

export function DefaultObject() {
	const { event, target } = useEventContext();
	const { sdObject } = useGetObjectById(event.object_def_id);
	if (target === "NOTIFICATION") return <LinkObject />;
	if (!sdObject) return `this record`;
	return `this ${sdObject._displayName.toLowerCase()}`;
}
