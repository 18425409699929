import { mTaskDef, TaskAssignedParams } from "@salesdesk/salesdesk-model";
import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { useGetObjectById } from "../../../../../hooks";

export function TaskAssignObject() {
	const { event, target } = useEventContext();
	const newAssigneeId = (event.params as TaskAssignedParams)?.assigneeId;
	const { sdObject } = useGetObjectById(mTaskDef.ID);
	const objectName = sdObject?._displayName?.toLowerCase() ?? "task";
	return (
		<>
			{target === "NOTIFICATION" ? <LinkObject /> : `this ${objectName}`} to <LinkObject recordId={newAssigneeId} />
		</>
	);
}
