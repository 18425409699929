import { KeyboardEventHandler, useCallback, useMemo, useState } from "react";
import { JSONContent } from "@tiptap/core";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { RichTextInput } from "../../../../../../../../../inputs";
import { ChatMessageGroupContainer, MessageGroup } from "../../../../../../../../../chat";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ChatLoadingView } from "../../../../../../../../../chat/components/ChatLoadingView";

interface ChatPanelProps {
	messageGroups: MessageGroup[];
	postMessage: (message: JSONContent) => void;
	isChatLoading?: boolean;
}

export function ChatPanel({ messageGroups, postMessage, isChatLoading }: ChatPanelProps) {
	const [content, setContent] = useState<{ jsonContent: JSONContent; text: string | undefined }>();

	const isEmpty = useMemo(() => content?.text == null || content.text.trim() === "", [content]);
	const handlePostMessage = useCallback(() => {
		if (!isEmpty && content != null) {
			postMessage(content.jsonContent);
			setContent(undefined);
		}
	}, [isEmpty, content, postMessage]);

	const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
		(event) => {
			if (event.code === "Enter" && !event.shiftKey) {
				handlePostMessage();
			}
		},
		[handlePostMessage]
	);

	return (
		<div className="flex h-full w-full flex-col justify-between pb-4">
			<div className="flex flex-col gap-4">
				<div key="title" className="text-c_text_primary text-label">
					Chat
				</div>
				{messageGroups.length === 0 && !isChatLoading ? (
					<div key="startChat" className="text-c_text_placeholder text-body-sm">
						Start a chat by typing a message below
					</div>
				) : null}
			</div>

			<div className="flex h-full flex-col-reverse gap-4 overflow-hidden overflow-y-auto pr-2">
				{isChatLoading ? (
					<ChatLoadingView />
				) : (
					<div className="flex flex-col gap-4">
						{messageGroups.map((messageGroup) => (
							<ChatMessageGroupContainer
								key={`${messageGroup.userRecord?._id}-${messageGroup.messages[0].createdAt}`}
								messageGroup={messageGroup}
							/>
						))}
					</div>
				)}
			</div>
			<div className="flex items-end gap-2 pt-6">
				<div className="text-body-sm w-full min-w-0">
					<RichTextInput
						onChange={(jsonContent, text) => {
							setContent({ jsonContent, text });
						}}
						onKeyDown={handleOnKeyDown}
						hideToolbar={true}
						placeholder="Type a message..."
						value={content?.jsonContent}
						heightClassName="min-h-[36px] max-h-[68px]"
						disabled={isChatLoading}
						disableEnter={true}
						enableShiftEnterAsEnter={true}
						isCollapsible
						bottomPanelOptions={{
							rightComponent: (
								<Tooltip text="Send message">
									<Button
										startIcon={ICONS.paperPlaneTile}
										onClick={handlePostMessage}
										disabled={isEmpty}
										size="sm"
									></Button>
								</Tooltip>
							),
						}}
					/>
				</div>
			</div>
		</div>
	);
}
