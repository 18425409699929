import { PropsWithChildren, ReactNode } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { CameraSelect, MicrophoneSelect, SpeakerSelect } from "./DeviceSelect";
import { Icon } from "@salesdesk/daisy-ui";

export function SettingsPopoverContent() {
	return (
		<div className="flex w-[420px] flex-col">
			<div className="text-body-sm text-c_text_secondary mx-6 my-2">Settings</div>
			<SettingsSection icon={ICONS.microphoneOn} label="Microphone">
				<MicrophoneSelect />
			</SettingsSection>
			<SettingsSection icon={ICONS.videoCamera} label="Camera">
				<CameraSelect />
			</SettingsSection>
			<SettingsSection
				icon={ICONS.speakerHigh}
				label="Speaker"
				// headerControl={<SpeakerTestButton />}
			>
				<SpeakerSelect />
			</SettingsSection>
		</div>
	);
}

function SettingsSection({
	icon,
	label,
	headerControl,
	children,
}: PropsWithChildren<{ icon: string; label: string; headerControl?: ReactNode | undefined }>) {
	return (
		<div className={"mx-6 mb-4 mt-1 flex flex-col gap-2"}>
			<div className="flex items-center justify-between">
				<div className={"text-label-sm text-c_text_secondary flex items-center gap-1"}>
					<Icon icon={icon} /> {label}
				</div>
				<div>{headerControl}</div>
			</div>
			{children}
		</div>
	);
}
