import { useMemo } from "react";
import { useAppSelector } from "../../../../store/store";
import { LoadingStatus } from "../../types";
import { useChannelSelector } from "./useChannelSelector";

export function useChimeChannelStatusSelector(channelArn?: string) {
	const channelMessagesState = useAppSelector((state) => {
		if (!channelArn) {
			return undefined;
		}

		return state.messages[channelArn];
	});

	const channel = useChannelSelector(channelArn);
	const membershipStatus = channel?.memberships?.loadingStatus;

	const noMoreMessagesToLoad = channelMessagesState?.nextToken === null;
	const hasMessages = Boolean(channelMessagesState?.messages);
	const loadingStatus = channelMessagesState?.loadingStatus;

	return useMemo(() => {
		if (!channelArn) return {};

		let chatStatus = LoadingStatus.Loaded;

		// Set to loaded by default if no more messages to load
		let nextMessagesStatus = noMoreMessagesToLoad ? LoadingStatus.Loaded : LoadingStatus.Loading;

		// If no messages yet the loading status refers to the initial
		// message state (or the status of the chat as a whole)
		if (!hasMessages) {
			chatStatus = loadingStatus ?? LoadingStatus.Loading;
		}
		// If messages is set, then we have successfully loaded the initial
		// messages for the channel and the loading status refers to the
		// status of the next messages
		else {
			nextMessagesStatus = loadingStatus ?? nextMessagesStatus;
		}

		return {
			channelDetailsStatus: membershipStatus,
			chatStatus,
			nextMessagesStatus,
		};
	}, [channelArn, hasMessages, loadingStatus, membershipStatus, noMoreMessagesToLoad]);
}
