import { Button } from "@salesdesk/daisy-ui";

export function ChangePasswordContainer() {
	return (
		<div className="flex items-center justify-between">
			<div>
				<p className="text-label">Password</p>
				<p className="text-c_text_secondary text-body-sm">Set a password to login to your account</p>
			</div>
			<Button size="sm" variant="outlined" disabled>
				Change password
			</Button>
		</div>
	);
}
