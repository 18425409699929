import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { BoardRecordDetails, GetRecordLinkFn } from "../../../../types";

export enum CALENDAR_VIEW {
	DAY = "DAY",
	WEEK = "WEEK",
	MONTH = "MONTH",
}

export interface CalendarEntry {
	id: number;
	name: string;
	start: Date;
	end?: Date;
	color?: string;
	getRecordLink?: GetRecordLinkFn;
	recordDetails?: BoardRecordDetails;
}

export type CalendarCardDetails =
	| {
			id: number;
			name: string;
			start: Date;
			getRecordLink?: GetRecordLinkFn;
			zIndex?: number;
			top?: number;
			left?: string;
			width?: string;
			height?: number;
			overlapLevel?: number;
			sdRecord?: SDRecord;

			// Used to display indicators for when a calendar card starts or ends
			// outside of the current range
			startsOutsideRange?: boolean;
			endsOutsideRange?: boolean;
	  }
	| null // Represents a spot already taken by another calendar card
	| undefined; // Represents a free spot for a calendar card

export interface DateTimeCardGroup {
	startPosition: number;
	endPosition: number;
	rows: CalendarCardDetails[][];
}

export interface DateTimeCardPosition {
	overlapLevel: number;
	row: number;
	index: number;
}
