import { useMemo } from "react";
import { CallParticipantDetails, useVideoCallLogicContext } from "../../VideoCallProvider";

export function useGetDisplayedParticipants() {
	const {
		room,
		callParticipantDetails,
		screenSharingParticipantDetails,
		fileSharingParticipant,
		dominantSpeakerQueue,
	} = useVideoCallLogicContext();

	const hasPresenters = fileSharingParticipant || screenSharingParticipantDetails.length;

	// A mapping of participant identity to their screen share/file presentations.
	// Note that a user can share their screen and a file at the same time.
	const presenterMap = useMemo(() => {
		const presenterMap = new Map<string, CallParticipantDetails[]>();

		screenSharingParticipantDetails.forEach((screenShareDetails) => {
			const identity = screenShareDetails.participant.identity;
			presenterMap.set(identity, [screenShareDetails]);
		});

		if (fileSharingParticipant) {
			const filePresenterIdentity = fileSharingParticipant.participant.identity;

			presenterMap.set(filePresenterIdentity, [
				...(presenterMap.get(filePresenterIdentity) || []),
				fileSharingParticipant,
			]);
		}

		return presenterMap;
	}, [screenSharingParticipantDetails, fileSharingParticipant]);

	const displayedParticipantDetails: CallParticipantDetails[] = useMemo(() => {
		if (!room || !callParticipantDetails.length) {
			return [];
		}

		// Show current user below the person they're talking to (if only 2 people in the call
		// and none are sharing their screen)
		if (!hasPresenters && callParticipantDetails.length === 2) {
			const [first, second] = callParticipantDetails;
			return [second, first];
		}

		// Sort participants based on the dominant speaker queue
		const sortedParticipants = callParticipantDetails.sort((a, b) => {
			const aIndex = dominantSpeakerQueue.indexOf(a.participant.identity);
			const bIndex = dominantSpeakerQueue.indexOf(b.participant.identity);
			return (aIndex !== -1 ? aIndex : Infinity) - (bIndex !== -1 ? bIndex : Infinity);
		});

		// Find the presentations of the highest dominant speaker who is presenting something
		let topPresentation = dominantSpeakerQueue
			.map((identity) => presenterMap.get(identity))
			.find((details) => details !== undefined);

		// If there is no top presentation, it's possible that someone is presenting something
		// but they haven't spoken since the user has joined the call.
		// So we set the top presentation to the most recent person presenting.
		if (!topPresentation && hasPresenters) {
			let mostRecentPresenterIdentity;

			if (fileSharingParticipant) {
				mostRecentPresenterIdentity = fileSharingParticipant.participant.identity;
			} else {
				mostRecentPresenterIdentity = screenSharingParticipantDetails[0].participant.identity;
			}

			topPresentation = presenterMap.get(mostRecentPresenterIdentity);
		}

		// Insert screen shares and file shares immediately before the corresponding participant
		const participantsToDisplay = sortedParticipants.flatMap((participantDetails) => {
			const identity = participantDetails.participant.identity;

			const isTopPresentation = topPresentation?.length && topPresentation[0].participant.identity === identity;

			const shareDetails = isTopPresentation ? undefined : presenterMap.get(identity);

			return shareDetails ? [...shareDetails, participantDetails] : [participantDetails];
		});

		return [...(topPresentation || []), ...participantsToDisplay];
	}, [
		room,
		callParticipantDetails,
		dominantSpeakerQueue,
		presenterMap,
		hasPresenters,
		fileSharingParticipant,
		screenSharingParticipantDetails,
	]);

	return { hasPresenters, displayedParticipantDetails };
}
