import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";

export const aggregationPropertySchema = asConst({
	title: "Aggregation Property",
	description: "Defines aggregations to be calculated for the query",
	type: "object",
	nullable: false,
	additionalProperties: false,
	minProperties: 1,
	maxProperties: 1,
	properties: {
		sum: stringSchema,
		min: stringSchema,
		max: stringSchema,
		avg: stringSchema,
		countEmpty: stringSchema,
		countNotEmpty: stringSchema,
	},
});
