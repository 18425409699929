import { RefObject, useCallback, useEffect, useState } from "react";

export function useSelectCloseTrigger(selectButtonRef: RefObject<HTMLButtonElement>, isDisabled?: boolean) {
	const [selectKey, setSelectKey] = useState(0);

	// HeadlessUI listbox and combobox don't provide a way of changing its internal open state,
	// however there are instances where we want to close the select dropdown which
	// the listbox/combobox doesn't handle but our popover can, e.g. when the user scrolls away.
	//
	// The workaround is to change the component key to re-mount it and its children. Since this is a
	// (relatively) expensive operation, we only want to do this when absolutely necessary. The timeout
	// allows time for the listbox to close if a closing action supported by HeadlessUI has occured
	// (e.g. clicking outside, pressing escape).
	const triggerSelectClose = useCallback(() => {
		setTimeout(() => {
			const selectButton = selectButtonRef?.current;

			if (!selectButton) {
				return;
			}

			if (selectButton.dataset.headlessuiState === "open" || selectButton.dataset.headlessuiState === "open disabled") {
				setSelectKey(selectKey + 1);
			}
		}, 150);
	}, [selectKey, selectButtonRef]);

	useEffect(() => {
		if (isDisabled) {
			triggerSelectClose();
		}
	}, [triggerSelectClose, isDisabled]);

	return {
		selectKey,
		triggerSelectClose,
	};
}
