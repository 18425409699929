import { asConst } from "json-schema-to-ts";
import { nonNegativeIntegerSchema, stringSchema } from "../components";
import { importMappingEntryCreateRequestSchema } from "./importMappingEntryCreateRequestSchema";

export const importMappingEntrySchema = asConst({
	title: "ImportMappingEntrySchema",
	description: "The mapping details from a column in an import file to an object field",
	type: "object",
	additionalProperties: false,
	required: ["columnIndex", "columnName", "examples"],
	properties: {
		...importMappingEntryCreateRequestSchema.properties,
		columnIndex: nonNegativeIntegerSchema,
		examples: {
			type: "array",
			items: {
				anyOf: [
					{
						type: "string",
					},
				],
			},
		},
		fieldDisplayName: stringSchema,
	},
});
