import { isValidElement, useEffect, useState, useMemo, ReactNode } from "react";

import { Checkbox } from "../../inputs";
import { FieldSet } from "@salesdesk/daisy-ui";
import { Dialog, DialogContent, DialogOptions } from "../Dialog";
import clsx from "clsx";

type FunctionChildrenType = ({ isConfirmDisabled }: { isConfirmDisabled: boolean }) => ReactNode;

export interface AlertDialogProps extends DialogOptions {
	title: string;
	description?: string;
	message: string | ReactNode;
	destructivePrompt?: string;
	destructiveLabel?: string;
	children: FunctionChildrenType | ReactNode;
	width?: number;
}

export function AlertDialog({
	open,
	onOpenChange,
	title,
	description,
	message,
	destructivePrompt,
	destructiveLabel,
	children,
	width,
}: AlertDialogProps) {
	const [confirmed, setConfirmed] = useState(false);

	useEffect(() => {
		if (!open) {
			setConfirmed(false);
		}
	}, [open]);

	const resolvedChildren = useMemo(() => {
		const isConfirmDisabled = Boolean(destructiveLabel) && !confirmed;

		if (typeof children === "function") {
			return children({ isConfirmDisabled });
		}

		if (isValidElement(children)) {
			return children;
		}

		throw new Error(`Invalid type of children. Passed children - ${children}`);
	}, [children, destructiveLabel, confirmed]);

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent>
				<div className={clsx("mb-4 flex min-w-[235px] flex-col", width == null && "max-w-md")} style={{ width }}>
					<h3 className="text-h3">{title}</h3>
					{description ? <div className="text-body mt-4">{description}</div> : null}
					<div className="text-body mt-4">{message}</div>
					{destructiveLabel != null ? (
						<div className="mt-6 flex flex-col gap-3">
							{destructivePrompt != null ? <div>{destructivePrompt}</div> : null}
							<FieldSet label={destructiveLabel} isBoolean>
								<Checkbox value={confirmed} onChange={setConfirmed} />
							</FieldSet>
						</div>
					) : null}
					<div className="mt-10">{resolvedChildren}</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
