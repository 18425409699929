import { Skeleton } from "../../../../components/Skeleton/Skeleton";

interface UserFilterLoadingProps {
	primaryUserCount: number;
}

export function UserFilterLoading({ primaryUserCount }: UserFilterLoadingProps) {
	// Incremented by 1 to account for the '+X' button
	const numOfElements = primaryUserCount + 1;

	return (
		<div className="mr-2 flex items-center">
			{[...Array(numOfElements)].map((_, index) => (
				<div
					key={index}
					className="bg-c_bg_01 -mr-2 shrink-0 rounded-full p-0.5"
					style={{ zIndex: numOfElements - index }}
				>
					<Skeleton className="size-8" />
				</div>
			))}
		</div>
	);
}
