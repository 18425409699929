import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_CREATION_TYPE, mFieldDataChangeEvent } from "../utils";
import { FieldInstanceFactory } from "../field_inst_factory";
import { mNumberField } from "../number";
import { mField } from "../field_inst";

export class mContainerField extends mField {
	constructor(id, field) {
		super(id, field);
		this._children = [];

		if (field) {
			this.createFields();
			field.children?.forEach((childField) => {
				this.addChild(FieldInstanceFactory.newInstance(childField));
			});
		}
	}

	isContainer() {
		return true;
	}

	addChild(child, index) {
		child.parent = this;

		child.addEventListener(mFieldDataChangeEvent.type.ALL, (event) => {
			this.fireEventObject(event);
		});

		if (!index) {
			// See if there are already instances of this type in the children.
			// If there are, then add to the bottom.
			let instances = this.getInstancesByField(child.field.id);

			if (instances.length > 0) {
				index = instances[instances.length - 1].getParentIndex() + 1;
			} else {
				// If there aren't any instances, then assume
				// we are populating the children from scratch and
				// simply adding each child in the order in which
				// they are being loaded.

				this._children.push(child);

				return;
			}
		}

		if (index > -1 && this._children.length > index - 1) {
			this._children.splice(index, 0, child);
		} else {
			throw Error(`Cannot add child at index ${index} as it is out of range`);
		}

		this.fireEvent(
			mFieldDataChangeEvent.type.FIELD_INSTANCE_ADDED,
			{
				index: index,
				child: child,
			},
			this
		);
	}

	removeChild(id) {
		for (let i = 0; this._children.length > i; i++) {
			if (this._children[i].id === id) {
				let child = this._children[i];

				// child.removeEventLister(this);
				this._children.splice(i, 1);

				this.fireEvent(
					mFieldDataChangeEvent.type.FIELD_INSTANCE_REMOVED,
					{
						index: i,
						child: child,
					},
					this
				);
				break;
			}
		}
	}

	get children() {
		return this._children;
	}

	set children(children) {
		this._children = [];

		for (let i = 0; children.length > i; i++) {
			this.addChild(children[i]);
		}
	}

	hasChildren() {
		return !isEmpty(this._children) && this._children.length > 0;
	}

	numOfChildren() {
		return !isEmpty(this._children) ? this._children.length : 0;
	}

	// Need to validate that this method obeys the field multiplicity rule associated
	// with the instance field

	addNewInstanceOf(field, index) {
		if (!field.supportsMultiple()) {
			throw Error(`The field type ${field.name} does not support adding another instance`);
		}

		this.addChild(FieldInstanceFactory.newInstance(field), index);
	}

	removeInstance(id) {
		this.removeChild(id);
	}

	// These are fields that return primitive values
	// such as text, numbers and dates. To be rendered
	// in data cells in tables, etc.

	getTableCellFields() {
		return this.field.getTableCellFields();
	}

	// These are field instances that return primitive values
	// such as text, numbers and dates. To be rendered
	// in data cells in tables, etc.

	getTableCellInstances() {
		let instances = [];

		let children = this.children;

		for (let i = 0; children.length > i; i++) {
			let child = children[i];

			if (child.field.showTableView() && !child.field.hidden) {
				instances.push(children[i]);
			}
		}
		return instances;
	}

	// These are field instances that return primitive values
	// such as text, numbers and dates. To be rendered
	// in data cells in tables, etc.

	getCardInstances() {
		let instances = [];

		let children = this.children;

		for (let i = 0; children.length > i; i++) {
			let child = children[i];

			if (child.field.showCardView() && !child.field.hidden) {
				instances.push(children[i]);
			}
		}
		return instances;
	}

	// These are field instances that support presenting as
	// previews in a card view

	getPreviewInstances() {
		let instances = [];

		let children = this.children;

		for (let i = 0; children.length > i; i++) {
			let child = children[i];

			if (child.hasValue() && child.field.showPreview() && !child.field.hidden) {
				instances.push(children[i]);
			}
		}
		return instances;
	}

	// For getting all instances belonging to a field
	// with multiplicity enabled.
	getNumOfInstancesWithFieldId(id) {
		let count = 0;

		for (let i = 0; this.children.length > i; i++) {
			let thisFieldInstance = this.children[i];

			if (thisFieldInstance.field.id === id) {
				count++;
			}
		}

		return count;
	}

	getFieldInstanceById(id) {
		let fieldInstance = null;

		if (this.id === id) {
			fieldInstance = this;
		} else {
			if (this.children) {
				fieldInstance = this.doGetFieldInstanceById(id, this.children);
			}
		}
		if (!fieldInstance) {
			throw new Error(`Field instance with id ${id} not found!`);
		}
		return fieldInstance;
	}

	doGetFieldInstanceById(id, children) {
		let fieldInstance = null;

		for (let i = 0; children.length > i; i++) {
			let thisFieldInstance = children[i];

			if (thisFieldInstance.id === id) {
				return thisFieldInstance;
			} else if (thisFieldInstance.children) {
				fieldInstance = this.doGetFieldInstanceById(id, thisFieldInstance.children);
			}
			if (fieldInstance !== null) {
				return fieldInstance;
			}
		}

		return null;
	}

	getInstancesByField(id) {
		let instances = [];

		if (this.field.id == id) {
			instances.push(this);
		}

		if (this.children) {
			this.doGetInstancesByField(id, this.children, instances);
		}

		return instances;
	}

	doGetInstancesByField(id, children, instances) {
		for (let i = 0; children.length > i; i++) {
			let instance = children[i];

			if (instance.field.id == id) {
				instances.push(instance);
			}

			if (instance.children) {
				this.doGetInstancesByField(id, instance.children, instances);
			}
		}
	}

	getInstancesByFieldName(name) {
		let instances = [];

		if (this.field.name == name) {
			instances.push(this);
		}

		if (this.children) {
			this.doGetInstancesByFieldName(name, this.children, instances);
		}

		return instances;
	}

	doGetInstancesByFieldName(name, children, instances) {
		for (let i = 0; children.length > i; i++) {
			let instance = children[i];

			if (instance.field.name == name) {
				instances.push(instance);
			}

			if (instance.children) {
				this.doGetInstancesByFieldName(name, instance.children, instances);
			}
		}
	}

	getInstancesByType(type) {
		let instances = [];

		if (this instanceof type) {
			instances.push(this);
		}

		if (this.children) {
			this.doGetInstancesByType(type, this.children, instances);
		}

		return instances;
	}

	doGetInstancesByType(type, children, instances) {
		for (let i = 0; children.length > i; i++) {
			let thisInstance = children[i];

			if (thisInstance instanceof type) {
				instances.push(thisInstance);
			}

			if (thisInstance.children) {
				this.doGetInstancesByType(type, thisInstance.children, instances);
			}
		}
	}

	getFileStoreInstances(type) {
		let instances = [];

		if (this.field.isFileStore()) {
			instances.push(this);
		}

		if (this.children) {
			this.doGetFileStoreInstances(type, this.children, instances);
		}

		return instances;
	}

	doGetFileStoreInstances(type, children, instances) {
		for (let i = 0; children.length > i; i++) {
			let thisInstance = children[i];

			if (thisInstance.field.isFileStore()) {
				instances.push(thisInstance);
			}

			if (thisInstance.children) {
				this.doGetFileStoreInstances(type, thisInstance.children, instances);
			}
		}
	}

	getAllInstances() {
		let instances = [];

		instances.push(this);

		if (this.children) {
			this.doGetAllInstances(this.children, instances);
		}

		return instances;
	}

	doGetAllInstances(children, instances) {
		for (let i = 0; children.length > i; i++) {
			let thisField = children[i];

			instances.push(thisField);

			if (thisField.children) {
				this.doGetAllInstances(thisField.children, instances);
			}
		}
	}

	getPresentableInstances() {
		let allInstances = this.getAllInstances();
		let visibleInstances = [];

		for (let i = 0; allInstances.length > i; i++) {
			let fieldInstance = allInstances[i];

			if (!fieldInstance.field.hidden && !fieldInstance.field.isContainer()) {
				visibleInstances.push(fieldInstance);
			}
		}

		return visibleInstances;
	}

	hasUserFields() {
		return Object.keys(this.getAllInstancesMap(FIELD_CREATION_TYPE.USER)).length > 0;
	}

	getAllInstancesMap(type) {
		let instances = this.getAllInstances();

		let instancesMap = [];

		for (let i = 0; instances.length > i; i++) {
			let instance = instances[i];

			if (type && instance.field.creationType !== type) {
				continue;
			}

			let name = instance.field.name;

			if (instance.field.supportsMultiple()) {
				if (!instancesMap[name]) {
					instancesMap[name] = [];
				}
				instancesMap[name].push(instance);
			} else {
				instancesMap[name] = instance;
			}
		}
		return instancesMap;
	}

	getInstancesByName(name) {
		let instancesMap = this.getAllInstancesMap();
		if (instancesMap) {
			return instancesMap[name];
		}
	}

	getlastDescendant() {
		let descendants = this.getAllInstances();
		return descendants[descendants.length - 1];
	}

	/** start interface methods **/

	// Allows polymorphism in various UI libraries.
	// JS doesn't support interfaces ;-(

	getObjectInstanceById(id) {
		return this.getFieldInstanceById(id);
	}

	/** end interface methods **/

	// Dynamically create convenience fields for API access

	createFields() {
		let field = this.field;

		field.children.forEach((field) => {
			let fieldName = field.supportsMultiple() ? `${field.pluralName}` : `${field.name}`;

			if (!this[fieldName]) {
				Object.defineProperty(this, fieldName, {
					get: () => {
						let fieldInstances = this.getInstancesByField(field.id);

						// If this field supports multiplicity, then return a collection of all instances.
						if (field.supportsMultiple()) {
							return fieldInstances;
						}

						// If this field is a container, then return a collection.
						if (field.isContainer()) {
							if (fieldInstances && fieldInstances[0]) {
								return fieldInstances[0];
							}
						}

						// If not, then return the underlying raw value.
						else {
							if (fieldInstances && fieldInstances[0]) {
								const fieldInstance = fieldInstances[0];

								if (fieldInstance instanceof mNumberField) {
									return fieldInstance.numberValue;
								} else {
									return fieldInstance.value;
								}
							}
							return null;
						}
					},
					set: (value) => {
						if (field.isContainer()) {
							throw Error(`${field.name} is a container field and cannot be overwritten. Please access child fields.`);
						}
						if (field.supportsMultiple()) {
							throw Error(
								`${field.name} collection cannot be overwritten. Please access "${field.name}" fields individually`
							);
						}

						let fieldInstances = this.getInstancesByField(field.id);

						if (fieldInstances && fieldInstances[0]) {
							fieldInstances[0].value = value;
						}
					},
				});
			}

			// If the field supports multiple instances, then add some convenience methods
			// to add and remove instances from multiplicity fields.

			if (field.supportsMultiple()) {
				let fieldName = field.name[0].toUpperCase() + field.name.substring(1);

				this[`add${fieldName}`] = function (value, index) {
					let newInstance = FieldInstanceFactory.newInstance(field);
					newInstance.value = value;

					this.addChild(newInstance, index);

					return newInstance;
				};

				this[`remove${fieldName}`] = function (instance) {
					if (!instance) {
						return;
					}

					if (instance.field.name !== field.name) {
						throw Error(`${instance.name} is not of type "${field.name}".`);
					}

					if (!this.getFieldInstanceById(instance.id)) {
						throw Error(`${instance.name} does not exist in "${this.name}"`);
					}
					this.removeInstance(instance.id);
				};
			}
		});
	}

	marshall(fieldData, data, parent) {
		this._children = [];

		// fieldData represents the entire tree of fields -
		// root, branches and leaves. From this object
		// we can set the correct field to he correct
		// field instance as it is created.

		// Get all children
		let children = data._children;

		// Delete from the current set
		delete data._children;

		// Assign fields to current object
		super.marshall(fieldData, data, parent);

		if (children) {
			children.forEach((data) => {
				// TODO: Handle if field could be removed fro object which means fieldData.getFieldById(data._fieldId) returns null
				let fieldInstance = FieldInstanceFactory.newInstanceByType(fieldData.getFieldById(data._fieldId)._type);

				fieldInstance.marshall(fieldData, data, this);

				fieldInstance.addEventListener(mFieldDataChangeEvent.type.ALL, (event) => {
					this.fireEventObject(event);
				});

				this.children.push(fieldInstance);
			});
		}

		if (this.field.children) {
			this.createFields();
		}

		return this;
	}

	unmarshall() {
		let clone = super.unmarshall();
		if (clone.children) {
			let cloneChildren = [];

			clone.children.forEach((fieldInstance) => {
				cloneChildren.push(fieldInstance.unmarshall());
			});

			clone._children = cloneChildren;
		}
		return clone;
	}
}
