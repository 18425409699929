import { ChimeCredentials, Claim } from "@salesdesk/salesdesk-schemas";
import { SDApi } from "../../features/api";

export const authApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getMyClaims: builder.query<Claim[], void>({
				query: () => ({
					url: `/me/claims`,
					method: "GET",
				}),
			}),
			getMyChimeCredentials: builder.query<ChimeCredentials, void>({
				query: () => ({
					url: `/me/chime/credentials`,
					method: "GET",
				}),
			}),
			getMyChimeChannelReadonlyCredentials: builder.query<ChimeCredentials, string>({
				query: (channelId) => ({
					url: `/me/chime/credentials/readonly/channel/${channelId}`,
					method: "GET",
				}),
			}),
		};
	},
});

export const {
	useGetMyClaimsQuery,
	useGetMyChimeCredentialsQuery,
	useLazyGetMyChimeCredentialsQuery,
	useLazyGetMyChimeChannelReadonlyCredentialsQuery,
} = authApi;
