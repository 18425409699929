import { useCallback } from "react";

import { SDObject, SDRecord, getSDObjectNameFieldIds } from "@salesdesk/salesdesk-schemas";

import { PATHS, useStableNavigate } from "../../../../../../routes";
import { AssociateRecordDetails } from "../../../../../recordAssociations/types";
import { useGetContextWorkspaceId } from "../../../../../workspaces";
import { generateCreateRecordRequest } from "../../../RecordForm";
import { useHandleRecordCreation } from "./useHandleRecordCreation";

export function useDocumentCreateAction() {
	const workspaceId = useGetContextWorkspaceId();

	const { handleRecordCreation, isLoading } = useHandleRecordCreation();

	const navigate = useStableNavigate();

	const documentCreateAction = useCallback(
		(documentObject: SDObject, associateRecordDetails?: AssociateRecordDetails) => {
			const nameFieldIds = getSDObjectNameFieldIds(documentObject);

			const recordCreateRequest = generateCreateRecordRequest(
				{ [nameFieldIds[0]]: "Untitled document" },
				documentObject
			);

			const onCreationComplete = (newSDRecord: SDRecord) => {
				navigate(
					workspaceId == null
						? PATHS.RECORD_DETAIL_VIEW(newSDRecord._id, documentObject)
						: PATHS.WORKSPACE_RECORD_DETAILS(workspaceId, newSDRecord._id, documentObject) || ""
				);
			};

			handleRecordCreation(recordCreateRequest, documentObject, onCreationComplete, associateRecordDetails);
		},
		[handleRecordCreation, navigate, workspaceId]
	);

	return {
		documentCreateAction,
		isLoadingDocumentCreation: isLoading,
	};
}
