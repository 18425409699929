import { HTMLAttributes } from "react";

import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { SkeletonCard } from "../../../../../components/Skeleton/SkeletonCard";

export function SkeletonRecordCard({ ...props }: HTMLAttributes<HTMLDivElement>) {
	return (
		<SkeletonCard {...props}>
			<Skeleton className="h-6 w-11/12" />
			<Skeleton className="h-6 w-1/2" />
			<Skeleton className="h-6 w-3/4" />
			<Skeleton className="h-6 w-7/12" />
			<Skeleton className="h-6 w-5/6" />
			<Skeleton className="h-6 w-2/3" />
		</SkeletonCard>
	);
}
