import { getCurrentTenantPrefix } from "../../../routes";

function removeTrailingSlash(path: string) {
	return path.endsWith("/") ? path.slice(0, -1) : path;
}

export function isOnCurrentPath(currentPath: string, link?: string) {
	if (!link) {
		return false;
	} else {
		const tenantPrefix = removeTrailingSlash(getCurrentTenantPrefix());
		if (removeTrailingSlash(link) === tenantPrefix) {
			return removeTrailingSlash(currentPath) === tenantPrefix;
		}
	}

	return decodeURIComponent(currentPath).includes(link);
}
