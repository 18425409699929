import { ClaimSubjectType, RoleClaim, RoleClaimType, RoleCreateRequest } from "@salesdesk/salesdesk-schemas";
import { OwnerType, RoleFormData } from "../types";
import { mContactDef, mLeadDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

export function mapFormDataToRoleCreateRequest(roleFormData: RoleFormData): RoleCreateRequest {
	const claims: RoleClaim[] = [];

	const addClaim = (addClaim: boolean, type: RoleClaimType, value?: string) => {
		if (addClaim)
			claims.push({
				type: type,
				value: value ?? ClaimSubjectType.All,
			});
	};

	const addOwnerClaim = (ownerType: OwnerType, allClaim: RoleClaimType, ownerClaim: RoleClaimType) => {
		switch (ownerType) {
			case OwnerType.All:
				claims.push({ type: allClaim, value: ClaimSubjectType.All });
				break;
			case OwnerType.Owner:
				claims.push({ type: ownerClaim, value: ClaimSubjectType.All });
				break;
		}
	};

	addOwnerClaim(roleFormData.objectRecordView, RoleClaimType.ObjectRecordViewAll, RoleClaimType.ObjectRecordViewOwner);
	addOwnerClaim(roleFormData.objectRecordEdit, RoleClaimType.ObjectRecordCreate, RoleClaimType.ObjectRecordCreate);
	addOwnerClaim(roleFormData.objectRecordEdit, RoleClaimType.ObjectRecordEditAll, RoleClaimType.ObjectRecordEditOwner);
	addOwnerClaim(
		roleFormData.objectRecordChangeOwner,
		RoleClaimType.ObjectRecordChangeOwnerAll,
		RoleClaimType.ObjectRecordChangeOwnerOwner
	);
	addOwnerClaim(
		roleFormData.objectRecordDelete,
		RoleClaimType.ObjectRecordDeleteAll,
		RoleClaimType.ObjectRecordDeleteOwner
	);

	// TODO: https://salesdesk101.atlassian.net/browse/SAL-2551
	// addClaim(roleFormData.objectRecordAssociationView, RoleClaimType.ObjectRecordAssociationView);
	addClaim(roleFormData.objectRecordAssociationCreate, RoleClaimType.ObjectRecordAssociationCreate);
	addClaim(roleFormData.objectRecordAssociationDelete, RoleClaimType.ObjectRecordAssociationDelete);

	addClaim(roleFormData.leadRecordConvert, RoleClaimType.LeadRecordConvert);
	addClaim(roleFormData.customerLoginEdit, RoleClaimType.UserLoginEdit, mContactDef.ID.toString());
	addClaim(roleFormData.customerLoginEdit, RoleClaimType.UserLoginEdit, mLeadDef.ID.toString());

	addClaim(roleFormData.fileDownloadInUI, RoleClaimType.FileDownloadInUI);
	addClaim(roleFormData.sentimentAnalysisView, RoleClaimType.SentimentAnalysisView);
	addClaim(roleFormData.coachingAdviceView, RoleClaimType.CoachingAdviceView);
	addClaim(roleFormData.objectRecordBulkEdit, RoleClaimType.ObjectRecordBulkEdit);
	addClaim(roleFormData.objectRecordBulkEdit, RoleClaimType.ObjectRecordBulkDelete);

	addClaim(roleFormData.objectView, RoleClaimType.ObjectView);
	addClaim(roleFormData.objectEdit, RoleClaimType.ObjectCreate);
	addClaim(roleFormData.objectEdit, RoleClaimType.ObjectEdit);
	addClaim(roleFormData.objectDelete, RoleClaimType.ObjectDelete);

	addClaim(roleFormData.objectAssociationView, RoleClaimType.ObjectAssociationView);
	addClaim(roleFormData.objectAssociationEdit, RoleClaimType.ObjectAssociationCreate);
	addClaim(roleFormData.objectAssociationEdit, RoleClaimType.ObjectAssociationEdit);
	addClaim(roleFormData.objectAssociationDelete, RoleClaimType.ObjectAssociationDelete);

	addClaim(roleFormData.videoCallRecord, RoleClaimType.VideoCallRecord);
	addClaim(roleFormData.videoCallShareScreen, RoleClaimType.VideoCallShareScreen);
	addClaim(roleFormData.videoCallPresentFile, RoleClaimType.VideoCallPresentFile);
	addClaim(roleFormData.videoCallCreateTask, RoleClaimType.VideoCallCreateTask);

	addClaim(roleFormData.roleView, RoleClaimType.RoleView);
	addClaim(roleFormData.roleEdit, RoleClaimType.RoleCreate);
	addClaim(roleFormData.roleEdit, RoleClaimType.RoleEdit);
	addClaim(roleFormData.roleDelete, RoleClaimType.RoleDelete);

	addClaim(roleFormData.userView, RoleClaimType.UserView);
	addClaim(roleFormData.userEdit, RoleClaimType.UserCreate);
	addClaim(roleFormData.userEdit, RoleClaimType.UserEdit);
	addClaim(roleFormData.userDelete, RoleClaimType.UserDelete);
	addClaim(roleFormData.userLoginEdit, RoleClaimType.UserLoginEdit, mSalesDeskUserDef.ID.toString());

	return {
		name: roleFormData.name,
		claims: claims,
	};
}
