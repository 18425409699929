import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { mIssueDef } from "@salesdesk/salesdesk-model";
import { getSDObjectFieldByName, getSDObjectSystemSyncOptionFieldOptions, rsr } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetObjectById } from "../../../../../../../hooks/useGetObjectById";
import { useWebPrincipal } from "../../../../../../../auth";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";
import { useWorkspaceSharedRecordSearch } from "../../../../../hooks/useWorkspaceSharedRecordSearch";
import { FILTERS, InfiniteRecordSearchParams } from "../../../../../../records";
import { ButtonSelect, SelectOptionId } from "../../../../../../inputs";
import { AddRecordButton } from "../../../WorkspaceResources";
import { EmptySection } from "../EmptySection";
import { WorkspaceIssuesList } from "./WorkspaceIssuesList";

const ISSUE_FILTER_ALL = "all_issues";
const ISSUE_FILTER_MY = "my_issues";

export function WorkspaceIssues() {
	const { isLoading } = useWorkspaceContext();

	const principal = useWebPrincipal();
	const { sdObject: issueObject } = useGetObjectById(mIssueDef.ID);

	const [issueFilter, setIssueFilter] = useState<SelectOptionId | undefined>(ISSUE_FILTER_ALL);

	const issueFilterOptions = useMemo(() => {
		const issuePluralName = (issueObject?._pluralName || "issues").toLowerCase();

		return [
			{ id: ISSUE_FILTER_ALL, name: `All ${issuePluralName}` },
			{ id: ISSUE_FILTER_MY, name: `My ${issuePluralName}` },
		];
	}, [issueObject]);

	const [additionalSearchParams, setAdditionalSearchParams] = useState<InfiniteRecordSearchParams>();

	const objectIds = useMemo(() => [mIssueDef.ID], []);

	const { records, isLoadingRecords, isLoadingNextPage, loadNextPage } = useWorkspaceSharedRecordSearch({
		sdObjectFilter: objectIds,
		additionalSearchParams: additionalSearchParams ?? skipToken,
	});

	// Update search params to filter out all closed issues
	useEffect(() => {
		if (!issueObject) {
			return;
		}

		const query = rsr.query();

		const { field, options } = getSDObjectSystemSyncOptionFieldOptions(issueObject, mIssueDef.STATUS_FIELD_NAME);
		const completedStatusOption = options.find((option) => option.name === mIssueDef.CLOSED_STATUS_NAME);

		if (field && completedStatusOption?.id !== undefined) {
			query.and(FILTERS.ss_not_is.generateSearchQuery(String(field._id), [completedStatusOption.id]));
		}

		const userRecordId = principal.UserRecordId;

		if (issueFilter === ISSUE_FILTER_MY && userRecordId !== null) {
			const assigneeField = getSDObjectFieldByName(issueObject, mIssueDef.ASSIGNEE_FIELD_NAME);

			if (assigneeField) {
				query.and(FILTERS.ms_contains_any.generateSearchQuery(String(assigneeField._id), [userRecordId]));
			}
		}

		setAdditionalSearchParams({ query: query.buildQuery() });
	}, [issueObject, principal.UserRecordId, issueFilter]);

	const isFiltered = issueFilter !== ISSUE_FILTER_ALL;
	const showFilterButton = isFiltered || isLoadingRecords || (!isFiltered && records.length > 0);

	return (
		<div className="flex flex-col gap-4">
			{isLoading || !issueObject ? (
				<div className="flex w-full items-center justify-between">
					<Skeleton className="h-6 w-20" />
					<Skeleton className="h-[38px] w-28" />
				</div>
			) : (
				<div className="text-c_text_secondary text-h4 flex h-[38px] items-center justify-between">
					{issueObject?._pluralName}
					<div className="flex items-center gap-3">
						{showFilterButton ? (
							<ButtonSelect
								value={issueFilter}
								onChange={setIssueFilter}
								options={issueFilterOptions}
								startIcon={ICONS.filter}
							/>
						) : null}
						<AddRecordButton sdObjectIds={objectIds} />
					</div>
				</div>
			)}
			<div className="relative">
				{!isLoadingRecords && !records.length && issueObject ? (
					<div className="w-2/5 min-w-[440px]">
						{isFiltered ? (
							<p className="text-c_text_placeholder">No uncompleted {issueObject._pluralName} assigned to you.</p>
						) : (
							<EmptySection
								sdObjectIds={objectIds}
								messageText={`No uncompleted ${issueObject._pluralName} yet, add one using the button below`}
								buttonText={`Add ${issueObject._displayName}`}
							/>
						)}
					</div>
				) : (
					<WorkspaceIssuesList
						records={records}
						issueObject={issueObject}
						isLoadingRecords={isLoadingRecords}
						isLoadingNextPage={isLoadingNextPage}
						loadNextPage={loadNextPage}
					/>
				)}
			</div>
		</div>
	);
}
