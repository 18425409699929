import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { formatTime } from "@salesdesk/salesdesk-utils";

import { CalendarCardDetails } from "../../types";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import {
	RECORD_SIDE_PANEL_TRIGGER_CLASS,
	RecordContextMenu,
	generatePreviewLinkMenuItem,
} from "../../../../../../records";
import { useIsInProgressMeeting } from "../../../../../../records/hooks/useIsInProgressMeeting";
import { Tooltip } from "@salesdesk/daisy-ui";

interface CalendarCardProps {
	cardDetails: CalendarCardDetails;
	showTime?: boolean;
	startsOutsideRange?: boolean;
}

export function CalendarCard({ cardDetails, showTime }: CalendarCardProps) {
	const {
		name,
		sdRecord,
		start,
		getRecordLink,
		overlapLevel,
		top,
		width,
		left,
		height,
		zIndex,
		startsOutsideRange,
		endsOutsideRange,
	} = cardDetails ?? {};

	const isInProgressMeeting = useIsInProgressMeeting(sdRecord);

	if (!sdRecord) {
		return null;
	}

	const link = getRecordLink && sdRecord ? getRecordLink(sdRecord._id) : undefined;

	const heightStyle = height !== undefined ? `${height}px` : undefined;
	const style: CSSProperties = {
		top: top !== undefined ? `${top}px` : undefined,
		height: heightStyle,
		zIndex,
		left,
		width,
	};

	// If no record is set we display a loading placeholder instead
	if (!sdRecord) {
		return (
			<div className="group absolute flex items-center" style={style}>
				<Skeleton className="mt-1 h-6 w-full" />
			</div>
		);
	}

	const showStartsOutsideRangeIndicator = startsOutsideRange && !showTime;
	const showEndsOutsideRangeIndicator = endsOutsideRange && !showTime;

	const cardContents = (
		<div
			style={{ height: heightStyle }}
			className="group-focus-visible:ring-c_action_focus rounded-minimal flex group-hover:!z-[1000] group-focus-visible:!z-[1000] group-focus-visible:ring"
		>
			{showStartsOutsideRangeIndicator ? <OutsideRangeIndicator variant="start" height={heightStyle} /> : null}
			<div
				className={clsx(
					(overlapLevel || 0) > 0 && "shadow-record_overlay",
					!showTime && "items-center",
					"rounded-minimal border-c_border_btn text-label-sm bg-c_bg_01 flex w-full min-w-0 justify-between overflow-hidden border px-2 py-0.5",
					"group-hover:bg-c_bg_03 group-hover:shadow-card",
					showStartsOutsideRangeIndicator && "rounded-l-none border-l-0",
					showEndsOutsideRangeIndicator && "rounded-r-none border-r-0"
				)}
			>
				<div
					className={clsx(
						height && height < 40 ? "flex-row items-center" : "flex-col flex-wrap justify-center",
						"flex h-fit gap-x-1 overflow-hidden",
						RECORD_SIDE_PANEL_TRIGGER_CLASS
					)}
				>
					{showTime && start ? (
						<div className="text-label-xs text-c_text_secondary flex h-4 items-center">{formatTime(start)}</div>
					) : null}
					<div className="flex w-full items-center gap-2">
						{isInProgressMeeting ? (
							<Tooltip text="The meeting is in progress">
								<div className="bg-c_success_02 size-2 shrink-0 rounded-full" />
							</Tooltip>
						) : null}
						<div className="truncate">{name}</div>
					</div>
				</div>
				{/* TODO: Placeholder until we implement context menus through right click instead of button press */}
				{sdRecord ? (
					<div
						className={clsx(
							showTime ? "!-my-0.5" : "!-my-1.5",
							"ui-open-within:not-sr-only sr-only absolute !-mx-2 transition-opacity group-focus-within:not-sr-only group-hover:not-sr-only"
						)}
					>
						<RecordContextMenu
							sdRecord={sdRecord}
							getCustomContextMenuItems={() => ({
								primaryItems: generatePreviewLinkMenuItem(link || undefined),
							})}
						/>
					</div>
				) : null}
			</div>
			{showEndsOutsideRangeIndicator ? <OutsideRangeIndicator variant="end" height={heightStyle} /> : null}
		</div>
	);

	if (link) {
		return (
			<Link style={style} className="group absolute" to={link} onClick={(e) => e.stopPropagation()}>
				{cardContents}
			</Link>
		);
	}

	return (
		<div className="group absolute" style={style}>
			{cardContents}
		</div>
	);
}

interface OutsideRangeIndicatorProps {
	height?: string;
	variant: "start" | "end";
}

function OutsideRangeIndicator({ variant, height }: OutsideRangeIndicatorProps) {
	return (
		<div className="z-10" style={{ height }}>
			<svg
				className="stroke-c_border_btn fill-c_bg_01 group-hover:fill-c_bg_03 stroke-1"
				xmlns="http://www.w3.org/2000/svg"
				width="11"
				height="32"
				viewBox="0 0 11 32"
				fill="none"
			>
				{variant === "start" ? (
					<path d="M11 31.5L2.0209 31.5512C1.20096 31.5559 0.724179 30.626 1.20652 29.963L9.75181 18.2159C10.4998 17.1876 10.5183 15.7994 9.79791 14.7515L1.07699 2.06653C0.620829 1.40303 1.09585 0.500001 1.90103 0.500001L11 0.5" />
				) : (
					<path d="M0 0.499512V0.499512C0.623396 0.499512 1.20416 0.815973 1.54212 1.33981L9.95074 14.3735C10.5895 15.3637 10.5895 16.6361 9.9507 17.6263L1.54214 30.6592C1.20417 31.1831 0.623398 31.4995 0 31.4995V31.4995" />
				)}
			</svg>
		</div>
	);
}
