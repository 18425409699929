import { useCallback, useMemo } from "react";

import { store } from "../../../store";
import { ToastType } from "../../../components/Toast";
import { triggerToast as actionTriggerToast } from "../store/toastSlice";
import { ToastMessageProps } from "../types";
import { createUniqueId } from "@salesdesk/salesdesk-utils";
import { setDismissToastTimeout } from "../utils";

export function useToast() {
	const trigger = useCallback((type: ToastType, message: string) => {
		const toastId = createUniqueId();
		store.dispatch(
			actionTriggerToast({
				id: toastId,
				type,
				message,
				timeoutId: setDismissToastTimeout(toastId),
			})
		);
	}, []);

	const triggerMessage = useCallback((toastMessageProps: ToastMessageProps) => {
		const toastId = createUniqueId();
		store.dispatch(
			actionTriggerToast({
				id: toastId,
				type: toastMessageProps.type,
				toastMessageProps,
				timeoutId: setDismissToastTimeout(toastId),
			})
		);
	}, []);

	return useMemo(
		() => ({
			trigger,
			triggerMessage,
		}),
		[trigger, triggerMessage]
	);
}
