import {
	AssociationMultiplicity,
	FIELD_TYPES,
	FieldTypeName,
	MULTI_OPTION_FIELDS,
	NUMERIC_FIELDS,
	SINGLE_OPTION_FIELDS,
	TEXT_FIELDS,
} from "@salesdesk/salesdesk-model";
import { SelectOption } from "../../../../inputs";
import { FilterOperator, FilterOption } from "../types";

export function associationFilterOptionsFactory(associationMultiplicity: AssociationMultiplicity) {
	return AssociationMultiplicity.MANY === associationMultiplicity
		? MANY_ASSOCIATION_FILTER_OPTIONS
		: ONE_ASSOCIATION_FILTER_OPTIONS;
}

export function fieldFilterOptionsFactory(fieldType: FieldTypeName) {
	if (TEXT_FIELDS.some((type) => type === fieldType)) {
		return TEXT_FILTER_OPTIONS;
	}
	if (NUMERIC_FIELDS.some((type) => type === fieldType)) {
		return NUMERIC_FILTER_OPTIONS;
	}
	if (FIELD_TYPES.DATE.name === fieldType) {
		return DATE_FILTER_OPTIONS;
	}
	if (FIELD_TYPES.DATE_TIME.name === fieldType) {
		return DATETIME_FILTER_OPTIONS;
	}
	if (FIELD_TYPES.TIME_RANGE.name === fieldType) {
		return TIME_RANGE_FILTER_OPTIONS;
	}
	if (SINGLE_OPTION_FIELDS.some((type) => type === fieldType)) {
		return SINGLE_SELECT_FILTER_OPTIONS;
	}
	if (MULTI_OPTION_FIELDS.some((type) => type === fieldType)) {
		return MULTI_SELECT_FILTER_OPTIONS;
	}
	if (FIELD_TYPES.BOOLEAN.name === fieldType) {
		return BOOLEAN_FILTER_OPTIONS;
	}
	if (FIELD_TYPES.RATING.name === fieldType) {
		return RATINGS_FILTER_OPTIONS;
	}
	return [];
}

export const FILTER_OPERATOR_OPTIONS: (SelectOption & { id: FilterOperator })[] = [
	{ id: "AND", name: "And" },
	{ id: "OR", name: "Or" },
];

export type FilterFieldSize = "base" | "md" | "lg" | "xl";

export const EMPTY_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "is_empty",
		name: "Is empty",
		fieldType: "none",
	},
	{
		id: "is_not_empty",
		name: "Is not empty",
		fieldType: "none",
	},
];

export const TEXT_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "t_contains",
		name: "Contains",
		fieldType: "text",
	},
	{
		id: "t_not_contains",
		name: "Does not contain",
		fieldType: "text",
	},
	{
		id: "is",
		name: "Is",
		fieldType: "text",
	},
	{
		id: "is_not",
		name: "Is not",
		fieldType: "text",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const NUMERIC_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "n_equals",
		name: "Equals to",
		fieldType: "number",
	},
	{
		id: "n_not_equals",
		name: "Not equal to",
		fieldType: "number",
	},
	{
		id: "n_greater_than",
		name: "Greater than",
		fieldType: "number",
	},
	{
		id: "n_less_than",
		name: "Less than",
		fieldType: "number",
	},
	{
		id: "n_between",
		name: "Between",
		fieldType: "number",
		field2Type: "number",
		size: "md",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const DATE_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "d_is",
		name: "Is",
		fieldType: "date",
		size: "lg",
	},
	{
		id: "d_not_is",
		name: "Is not",
		fieldType: "date",
		size: "lg",
	},
	{
		id: "d_is_before",
		name: "Is before",
		fieldType: "date",
		size: "lg",
	},
	{
		id: "d_is_after",
		name: "Is after",
		fieldType: "date",
		size: "lg",
	},
	{
		id: "d_between",
		name: "Between",
		fieldType: "date_between",
		size: "md",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const DATETIME_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "dt_is",
		name: "Is",
		fieldType: "date_time",
		size: "lg",
	},
	{
		id: "dt_not_is",
		name: "Is not",
		fieldType: "date_time",
		size: "lg",
	},
	{
		id: "dt_is_before",
		name: "Is before",
		fieldType: "date_time",
		size: "lg",
	},
	{
		id: "dt_is_after",
		name: "Is after",
		fieldType: "date_time",
		size: "lg",
	},
	{
		id: "dt_between",
		name: "Between",
		fieldType: "date_time_between",
		size: "xl",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const TIME_RANGE_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "tr_is_before",
		name: "Is before",
		fieldType: "time_range",
		size: "lg",
	},
	{
		id: "tr_is_after",
		name: "Is after",
		fieldType: "time_range",
		size: "lg",
	},
	{
		id: "tr_between",
		name: "Between",
		fieldType: "time_range_between",
		size: "xl",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const SINGLE_SELECT_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "ss_is",
		name: "Is",
		fieldType: "multi_select",
		size: "md",
	},
	{
		id: "ss_not_is",
		name: "Is not",
		fieldType: "multi_select",
		size: "md",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const MULTI_SELECT_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "ms_contains_any",
		name: "Contains any",
		fieldType: "multi_select",
		size: "lg",
	},
	{
		id: "ms_not_contains_any",
		name: "Does not contain any",
		fieldType: "multi_select",
		size: "lg",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const BOOLEAN_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "b_is",
		name: "Is",
		fieldType: "boolean",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const RATINGS_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "n_equals",
		name: "Is",
		fieldType: "rating",
	},
	{
		id: "n_not_equals",
		name: "Is not",
		fieldType: "rating",
	},
	{
		id: "n_greater_than",
		name: "Greater than",
		fieldType: "rating",
	},
	{
		id: "n_less_than",
		name: "Less than",
		fieldType: "rating",
	},
	...EMPTY_FILTER_OPTIONS,
];

export const ONE_ASSOCIATION_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "a_contains_any",
		name: "Is",
		fieldType: "multi_select",
		size: "md",
	},
	{ id: "a_not_contains_any", name: "Is not", fieldType: "multi_select", size: "md" },
];

export const MANY_ASSOCIATION_FILTER_OPTIONS: FilterOption[] = [
	{
		id: "a_contains_any",
		name: "Contains any",
		fieldType: "multi_select",
		size: "lg",
	},
	{ id: "a_not_contains_any", name: "Does not contain any", fieldType: "multi_select", size: "xl" },
	{
		id: "a_contains_all",
		name: "Contains all",
		fieldType: "multi_select",
		size: "lg",
	},
	{ id: "a_not_contains_all", name: "Does not contain all", fieldType: "multi_select", size: "xl" },
];
