import { TabId } from "../types";

export const RECENT_RECORDS_LIMIT = 4;

export function getTabIndex(tabId: TabId | undefined): number {
	if (tabId === undefined || tabId === "recentlyAdded") return 0;
	return 1;
}

export function getTabId(tabIndex: number): TabId {
	if (tabIndex === 1) return "starred";
	return "recentlyAdded";
}
