import { SelectOption } from "../../../../../inputs";
import { Strong } from "./Strong";

export function Users({ userOptions }: { userOptions: SelectOption[] | null | undefined }) {
	return userOptions ? (
		<>
			{userOptions.flatMap((option, index) => [
				index > 0 ? " or " : [],
				<Strong key={option.id}>{option.name}</Strong>,
			])}{" "}
		</>
	) : null;
}
