import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useBoardState } from "../hooks/useBoardState";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { ObjectBoardDetails } from "../types";
import { generateObjectBoardFieldMap, getObjectMediaFieldBoardIds, getObjectNameFieldBoardIds } from "../utils";
import { getSDObjectAggregatableFields, getSDObjectGroupableFields } from "../../objects/utils/objects";
import { useGetObjectsQuery } from "../../objects/api/objectsApi";

import { BulkEditBar, BulkEditProvider } from "./BulkEdit";
import { updateBoardState } from "../utils/defaultBoardFields";
import { useAppStateContext } from "../../users/hooks/useAppStateContext";
import { getBookmarkRedirectUrl } from "../../bookmarks/utils";
import { useGetMyBookmarksQuery } from "../../bookmarks";
import { useStableNavigate } from "../../../routes";
import { useAppDispatch } from "../../../store/store";
import { clearBoardState, setBoardState } from "../store";
import { DataboardDetailsContext } from "../hooks/useDataboardDetailsContext";
import { BoardPropOnChangeContext } from "../hooks/useBoardPropOnChangeContext";

interface ObjectBoardProviderProps {
	sdObject: SDObject;
	workspaceId?: SDRecord["_id"];
}

export function ObjectBoardProvider({ sdObject, workspaceId, children }: PropsWithChildren<ObjectBoardProviderProps>) {
	const { boardState, boardPropOnChange, resetInitialPageLoad, initialPageLoad } = useBoardState(sdObject);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setBoardState(boardState));
	}, [dispatch, boardState]);

	useEffect(() => {
		return () => {
			dispatch(clearBoardState());
		};
	}, [dispatch]);

	const [objectBoardDetails, setObjectBoardDetails] = useState<ObjectBoardDetails>(() =>
		generateObjectBoardDetails(sdObject)
	);

	const isInitialMount = useRef(true);

	useEffect(() => {
		// Don't want to reset board state on initial load as its initialising
		if (isInitialMount.current) {
			isInitialMount.current = false;
			return;
		}

		resetInitialPageLoad();

		setObjectBoardDetails(generateObjectBoardDetails(sdObject));
	}, [sdObject, resetInitialPageLoad]);

	const { appStateRef } = useAppStateContext();

	const navigate = useStableNavigate();
	const { currentData: bookmarks } = useGetMyBookmarksQuery();
	const { data: sdObjects } = useGetObjectsQuery();

	useEffect(() => {
		const viewSDObject = objectBoardDetails?.sdObject;

		// Want to ensure we only react on the actual board state and not
		// the initial default board state before the URL query have been read
		if (initialPageLoad || !objectBoardDetails || !viewSDObject) {
			return;
		}

		updateBoardState(objectBoardDetails, boardState, boardPropOnChange, appStateRef.current, (bookmarkId) => {
			const bookmark = bookmarks?.find((bookmark) => bookmark.id === bookmarkId);
			if (bookmark && bookmark.objectId === sdObject._id && workspaceId == null) {
				navigate(getBookmarkRedirectUrl(sdObjects, bookmark));
			}
		});
	}, [
		objectBoardDetails,
		boardState,
		boardPropOnChange,
		initialPageLoad,
		navigate,
		bookmarks,
		sdObjects,
		sdObject,
		appStateRef,
		workspaceId,
	]);

	const databoardDetails = useMemo(() => {
		return { ...objectBoardDetails, workspaceId };
	}, [objectBoardDetails, workspaceId]);

	return (
		<DataboardDetailsContext.Provider value={databoardDetails}>
			<BoardPropOnChangeContext.Provider value={boardPropOnChange}>
				<BulkEditProvider>
					{children}
					<BulkEditBar />
				</BulkEditProvider>
			</BoardPropOnChangeContext.Provider>
		</DataboardDetailsContext.Provider>
	);
}

function generateObjectBoardDetails(sdObject: SDObject) {
	return {
		sdObject,
		boardFieldMap: generateObjectBoardFieldMap(sdObject),
		nameFieldIds: getObjectNameFieldBoardIds(sdObject),
		mediaFieldIds: getObjectMediaFieldBoardIds(sdObject),
		groupableFields: getSDObjectGroupableFields(sdObject),
		aggregationFields: getSDObjectAggregatableFields(sdObject),
	};
}
