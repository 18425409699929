import { useEffect, useState } from "react";

import { RecordQueryClauses, rsr } from "@salesdesk/salesdesk-schemas";

import { SortingDetails, useInfiniteRecordSearch } from "../../../../../../../records";
import { APP_CONFIG } from "../../../../../../../../app/app_config";
import { DirectedSDObjectAssociation, getAssociationSearchParams } from "../../../../../../../recordAssociations";
import { useInfiniteScrollContainer } from "../../../../../../../../hooks/ui";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";

interface UseResourcesPanelSearchArgs {
	objectAssociation: DirectedSDObjectAssociation;
	sorting: SortingDetails[];
	filters: RecordQueryClauses[];
	searchQuery?: string;
	scrollContainerRef: React.RefObject<HTMLDivElement>;
}

export function useResourcesPanelSearch({
	objectAssociation,
	sorting,
	filters,
	searchQuery,
	scrollContainerRef,
}: UseResourcesPanelSearchArgs) {
	const { workspaceRecord } = useWorkspaceContext();

	const [isFiltered, setIsFiltered] = useState(false);

	const [totalResourcesRecords, setTotalResourcesRecords] = useState<number>();

	const {
		sdRecords: resourcesRecords,
		updateSearchParams,
		resultHits,
		loadNextPage,
		isLoadingNextPage,
		isLoadingNewSearchParams,
	} = useInfiniteRecordSearch({
		limit: APP_CONFIG.maxLocalSearchResults,
	});

	const workspaceRecordId = workspaceRecord?._id;
	useEffect(() => {
		if (workspaceRecordId == null) {
			return;
		}

		const searchParams = getAssociationSearchParams(
			{
				sourceRecordId: workspaceRecordId,
				sortingDetails: sorting,
				searchQuery,
				orQueries: filters,
			},
			objectAssociation
		);

		searchParams.query.and.push(rsr.not(rsr.isSharedWithWorkspace(workspaceRecordId)));

		updateSearchParams(searchParams);

		setIsFiltered(Boolean(filters?.length || searchQuery));
	}, [sorting, searchQuery, updateSearchParams, filters, workspaceRecordId, objectAssociation]);

	const hitCount = resultHits?.hitCount;
	useEffect(() => {
		if (hitCount !== undefined && !isFiltered && !isLoadingNewSearchParams) {
			setTotalResourcesRecords(hitCount);
		}
	}, [hitCount, isFiltered, isLoadingNewSearchParams]);

	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			loadNextPage();
		},
	});

	return {
		scrollContainerRef,
		containerBottomRef,

		resourcesRecords,
		totalResourcesRecords,
		isLoadingNextPage,
		isLoadingNewSearchParams,

		isFiltered,
	};
}
