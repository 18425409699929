import { useEffect, useState } from "react";
import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";

import { SDObject, SDRecord, rsr } from "@salesdesk/salesdesk-schemas";
import { deepClone } from "@salesdesk/salesdesk-utils";

import { APP_CONFIG } from "../../../app/app_config";
import { InfiniteRecordSearchParams, useInfiniteRecordSearch } from "../../records";
import { useWorkspaceContext } from "./useWorkspaceContext";

interface WorkspaceSharedRecordSearchArgs {
	limit?: number;
	sdObjectFilter?: SDObject["_id"] | SDObject["_id"][];
	additionalSearchParams?: InfiniteRecordSearchParams | SkipToken;
}

export function useWorkspaceSharedRecordSearch({
	limit = APP_CONFIG.maxLocalSearchResults,
	sdObjectFilter,
	additionalSearchParams,
}: WorkspaceSharedRecordSearchArgs) {
	const { workspaceRecord } = useWorkspaceContext();

	const [records, setRecords] = useState<SDRecord[]>([]);

	const { updateSearchParams, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage, resultHits } =
		useInfiniteRecordSearch({
			limit: limit,
			sdRecords: records,
			onSDRecordsChange: setRecords,
			sdObjectFilter,
		});

	useEffect(() => {
		if (workspaceRecord?._id == null || additionalSearchParams === skipToken) {
			return;
		}

		const baseQuery = rsr
			.query()
			.and(rsr.equals("_deleted", false))
			.not(rsr.equals("isTemplate", true))
			.and(rsr.isSharedWithWorkspace(workspaceRecord._id))
			.buildQuery();

		if (!additionalSearchParams) {
			updateSearchParams({ query: baseQuery });
			return;
		}

		const searchParams = deepClone(additionalSearchParams);

		searchParams.query.and = [...(searchParams.query.and || []), ...(baseQuery.and || [])];
		searchParams.query.or = [...(searchParams.query.or || []), ...(baseQuery.or || [])];

		searchParams.sort = searchParams.sort ?? [];

		updateSearchParams(searchParams);
	}, [additionalSearchParams, updateSearchParams, workspaceRecord?._id]);

	return {
		records,
		loadNextPage,
		isLoadingRecords: isLoadingNewSearchParams || !workspaceRecord,
		isLoadingNextPage,
		hitCount: resultHits?.hitCount ?? 0,
	};
}
