import { CurrentAccountLogo } from "../../../tenant";
import { WorkspaceCompanyLogo } from "../../../workspaces";

export function WorkspaceMeetingLogos() {
	return (
		<div className="flex items-center gap-6">
			<WorkspaceCompanyLogo size="sm" displayCompanyName />
			<CurrentAccountLogo size="sm" displayCompanyName />
		</div>
	);
}
