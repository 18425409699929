import { asConst } from "json-schema-to-ts";
import { idPathParamSchema } from "../../components/idPathParamSchema";

export const patchObjectAssociationPathSchema = asConst({
	title: "Patch Association Definition Path Parameters",
	type: "object",
	additionalProperties: false,
	required: ["objectAssociationId"],
	properties: {
		objectAssociationId: {
			...idPathParamSchema,
			title: "Association Definition ID",
			description: "ID of association definition to patch",
		},
	},
});
