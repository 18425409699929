import { RECORD_SIDE_PANEL_RECORD_ID_PARAM } from "@salesdesk/salesdesk-schemas";

export function getCurrentPathWithRecordIdParam(
	recordId: string | number,
	recordIdSearchParam = RECORD_SIDE_PANEL_RECORD_ID_PARAM
) {
	const stringRecordId = String(recordId);
	const { pathname, search } = window.location;
	const searchParams = new URLSearchParams(search);
	const recordIdParam = searchParams.get(recordIdSearchParam);

	if (recordIdParam && recordIdParam === stringRecordId) {
		searchParams.delete(recordIdSearchParam);

		return `${pathname}?${searchParams.toString()}`;
	}

	searchParams.set(recordIdSearchParam, stringRecordId);

	return `${pathname}?${searchParams.toString()}`;
}
