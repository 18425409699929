import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { GeneralPreviewField } from "../../../GeneralPreviewField";
import { mTimeRangeFieldDef } from "@salesdesk/salesdesk-model";
import { Icon } from "@salesdesk/daisy-ui";

interface PreviewTimeRangeFieldProps {
	dateOnly: boolean;
	error?: boolean;
}

export function PreviewTimeRangeField({ dateOnly, error }: PreviewTimeRangeFieldProps) {
	return (
		<GeneralPreviewField
			placeholder={dateOnly ? mTimeRangeFieldDef.dateRangeFormat : mTimeRangeFieldDef.dateTimeRangeFormat}
			containedIcon={
				<Icon
					className={clsx(error ? "text-c_danger_01" : "text-c_icon_regular", "flex items-center")}
					icon={ICONS.calendar}
				/>
			}
		/>
	);
}
