import { ReactElement, ReactNode } from "react";
import { Placement } from "@floating-ui/react";
import { BadgeProps } from "../../../components/Badge/types";

export type MenuItemVariant = "default" | "destructive" | "primary" | "primary_icon";
export type MenuItemType = "link" | "button" | "subMenu" | "subPopover";

interface BaseMenuItem {
	icon?: string;
	iconVariant?: "outline" | "fill";
	text?: string;
	subText?: string;
	variant?: MenuItemVariant;
	type: MenuItemType;
	loading?: boolean;
	badge?: string | BadgeProps;
	selected?: boolean;
	tooltip?: string;
}

export interface LinkMenuItem extends BaseMenuItem {
	link?: string;
	type: "link";
	targetBlank?: boolean;
}

export interface ButtonMenuItem extends BaseMenuItem {
	onClick?: () => void;
	type: "button";
}

export interface SubPopoverMenuItem extends BaseMenuItem {
	subPopoverPlacement?: Placement;
	subPopover: ReactNode;
	type: "subPopover";
}

interface SubMenuPopoverOptions {
	widthVariant?: PopoverMenuWidthVariant;
	placement?: Placement;
	menuVariant?: PopoverMenuVariant;
}

export interface SubMenuMenuItem extends BaseMenuItem {
	subMenuPopoverOptions?: SubMenuPopoverOptions;
	subMenu: MenuContents;
	type: "subMenu";
}

export type MenuItem = LinkMenuItem | ButtonMenuItem | SubPopoverMenuItem | SubMenuMenuItem;
export type MenuElement = MenuItem | ReactElement;

export type MenuTitledSection = { sectionTitle: string; items: MenuElement[]; boldTitle?: boolean };
export type MenuSection = MenuElement[] | MenuTitledSection;

export type MenuContents = (MenuElement | MenuSection)[];

export type PopoverMenuWidthVariant = "sm" | "fit";
export type PopoverMenuVariant = "regular" | "compact" | "medium";

export const MENU_VARIANT_PADDING_CLASSES: { [key in PopoverMenuVariant]: string } = {
	regular: "px-6 py-3",
	compact: "px-3 py-1.5",
	medium: "px-4 py-2",
};
