import { asConst } from "json-schema-to-ts";
import { idSchema } from "../components";

export const userIdResponseSchema = asConst({
	title: "User ID Response",
	description: "User ID for a given username",
	type: "object",
	required: ["userId"],
	additionalProperties: false,
	properties: {
		userId: {
			...idSchema,
			title: "User ID",
			description: "The ID for the requested user",
		},
	},
});
