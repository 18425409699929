import { RECORD_BATCH_SIZE, SDRecord } from "@salesdesk/salesdesk-schemas";
import { APP_CONFIG } from "../../../../../app/app_config";
import { CanParameters, AbilityAction, sdSubject, AbilitySubject } from "@salesdesk/salesdesk-schemas";

export const MAX_BATCH_SIZE = APP_CONFIG.maxLocalSearchResults;
export const MAX_RECORD_BATCH_SIZE = RECORD_BATCH_SIZE;

export const getCanEditParameters = (record: SDRecord): CanParameters => [
	AbilityAction.Edit,
	sdSubject(AbilitySubject.Record, record),
];
