import { Draggable } from "@hello-pangea/dnd";
import { useId } from "react";

import { DropIndicator } from "./DropIndicator";
import { DraggableTableRowProps } from "../types";

import { DraggableTableRowContents } from "./DraggableTableRowContents";
import { genericMemo } from "../../../../../utils";

function DraggableTableRowComponent<T>({
	rowId,
	index,
	visibleCells,
	isDraggable,
	dropIndicatorPlacement = "none",
	dropPlaceholder,
	isLastRow,
	...rest
}: DraggableTableRowProps<T>) {
	const linkElementId = useId();

	const sharedRowProps = {
		linkElementId,
		isDraggable,
		visibleCells,
		...rest,
	};

	if (isDraggable) {
		const draggableId = rowId || linkElementId;

		return (
			<>
				<Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={false}>
					{(provided, snapshot) => {
						const isDraggingRow = snapshot.isDragging || false;

						return (
							<>
								{dropIndicatorPlacement === "top" ? <DropIndicator columns={visibleCells.length} /> : null}
								{isDraggingRow ? <DraggableTableRowContents {...sharedRowProps} isDragPlaceholder /> : null}
								<DraggableTableRowContents
									{...sharedRowProps}
									draggableProvided={provided}
									isDraggingRow={isDraggingRow}
								/>
								{dropIndicatorPlacement === "bottom" ? <DropIndicator columns={visibleCells.length} /> : null}
							</>
						);
					}}
				</Draggable>
				{isLastRow ? dropPlaceholder : null}
			</>
		);
	}

	return <DraggableTableRowContents {...sharedRowProps} />;
}

export const DraggableTableRow = genericMemo(DraggableTableRowComponent);
