import { useCallback } from "react";

import { Field, getSDRecordName, getUserRecordAvatarFileId, rsr, SDRecord } from "@salesdesk/salesdesk-schemas";
import { isUserObject, mUserDef } from "@salesdesk/salesdesk-model";
import { createHashId } from "@salesdesk/salesdesk-utils";

import { SortingOrder, useLazyGetRecordsQuery, useLazySearchRecordsQuery } from "../../../../../records";
import { generateUsersFilterRsrQuery } from "../../../../../users";
import { useGetObjectMap } from "../../../../../objects/hooks";
import { SDObjectMap } from "../../../../../objects/types";
import { SelectOption, SelectOptionId } from "../../../../../inputs";

export interface useGetRecordOptionsProps {
	id?: number;
	baseObjectId?: Field["_objectDefId"];
	fetchingRecordOptions?: boolean;
	excludeRecordIds?: SDRecord["_id"][];
	onlyIncludeRecordIds?: SDRecord["_id"][];
	sharedWithWorkspaceId?: number;
	/**
	 * For a user search typically you want to only include CRM users when in the CRM and customer users
	 * when in a particular workspace. This flag allows you to include customer users in a user search when no workspace is passed.
	 */
	includeCustomerUserRecordsWhenNoWorkspace?: boolean;

	// For a user search, can only include users that can log into the platform
	onlyAuthorizedToLogIn?: boolean;
}

export function useGetRecordOptions({
	id,
	baseObjectId,
	fetchingRecordOptions = false,
	excludeRecordIds,
	onlyIncludeRecordIds,
	sharedWithWorkspaceId,
	includeCustomerUserRecordsWhenNoWorkspace = false,
	onlyAuthorizedToLogIn = false,
}: useGetRecordOptionsProps) {
	const objectMap = useGetObjectMap(!fetchingRecordOptions);

	const [searchRecords] = useLazySearchRecordsQuery();
	const [getRecords] = useLazyGetRecordsQuery();

	const getOptions = useCallback(
		async (query: string) => {
			const searchParams = generateRecordOptionsSearchParams({
				id,
				matchAllPrefix: query,
				baseObjectId,
				excludeRecordIds,
				onlyIncludeRecordIds,
				sharedWithWorkspaceId,
				includeCustomerUserRecordsWhenNoWorkspace,
				onlyAuthorizedToLogIn,
			});
			const searchHits = await searchRecords(searchParams).unwrap();

			const recordIds = searchHits.hits.map((hit) => hit.source.id);

			if (!recordIds.length) {
				return [];
			}

			const records = await getRecords(recordIds, true).unwrap();

			return convertSDRecordsToSelectOptions(records, objectMap);
		},
		[
			id,
			baseObjectId,
			excludeRecordIds,
			onlyIncludeRecordIds,
			sharedWithWorkspaceId,
			includeCustomerUserRecordsWhenNoWorkspace,
			onlyAuthorizedToLogIn,
			searchRecords,
			getRecords,
			objectMap,
		]
	);

	const getOptionsFromIds = useCallback(
		async (ids: SelectOptionId[]) => {
			const records = await getRecords(
				ids.map((id) => Number(id)),
				true
			).unwrap();

			return convertSDRecordsToSelectOptions(records, objectMap);
		},
		[getRecords, objectMap]
	);

	return { getOptions, getOptionsFromIds };
}

function convertSDRecordsToSelectOptions(sdRecords: SDRecord[], sdObjectMap: SDObjectMap): SelectOption[] {
	const options: SelectOption[] = [];

	sdRecords.forEach((record) => {
		const sdObject = sdObjectMap.get(record._objectDefId);
		if (!sdObject) return;

		const recordName = getSDRecordName(sdObject, record);

		if (!isUserObject(sdObject._id)) {
			options.push({
				id: record._id,
				name: recordName,
				icon: sdObject?._icon,
				sdRecord: record,
			});

			return;
		}

		options.push({
			id: record._id,
			name: recordName,
			imageFileId: getUserRecordAvatarFileId(sdObject, record),
			sdRecord: record,
			label: {
				text: sdObject._displayName,
				icon: sdObject._icon,
			},
		});
	});

	return options;
}

interface RecordOptionsSearchParamsProps extends useGetRecordOptionsProps {
	matchAllPrefix: string;
}

function generateRecordOptionsSearchParams({
	id,
	matchAllPrefix,
	baseObjectId,
	excludeRecordIds,
	sharedWithWorkspaceId,
	onlyIncludeRecordIds,
	includeCustomerUserRecordsWhenNoWorkspace,
	onlyAuthorizedToLogIn,
}: RecordOptionsSearchParamsProps) {
	const query = rsr.query().and(rsr.equals("_deleted", false)).not(rsr.equals("isTemplate", true));

	// TODO: Should this search be limited to just the record name?
	if (matchAllPrefix != null && matchAllPrefix.length > 0) {
		query.and(rsr.matchAllPrefix(matchAllPrefix));
	}

	const isUserSearch = baseObjectId === mUserDef.ID;

	if (baseObjectId != null) {
		if (isUserSearch) {
			generateUsersFilterRsrQuery(
				query,
				sharedWithWorkspaceId == null && !includeCustomerUserRecordsWhenNoWorkspace,
				onlyAuthorizedToLogIn
			);
		} else {
			query.and(
				rsr.subQuery().or(rsr.equals("_objectDefId", baseObjectId)).or(rsr.baseObjectId(baseObjectId)).buildSubQuery()
			);
		}
	}

	if (excludeRecordIds?.length) query.not(rsr.equalsAnyOf("id", excludeRecordIds));
	if (onlyIncludeRecordIds?.length) query.and(rsr.equalsAnyOf("id", onlyIncludeRecordIds));
	if (sharedWithWorkspaceId) query.and(rsr.isSharedWithWorkspace(sharedWithWorkspaceId));
	if (id) query.and(rsr.equals("id", id));

	const rsrRequest = rsr.create().from(0).size(20).query(query.buildQuery());

	if (isUserSearch) {
		rsrRequest.sort({ [createHashId(mUserDef.LAST_ACTIVE_FIELD_NAME)]: { order: SortingOrder.desc } });
	}

	return rsrRequest.buildRequest();
}
