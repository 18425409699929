import { APP_CONFIG } from "../../../../../../../../app/app_config";
import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";
import { AssociationGroup } from "../../../common/AssociationGroup";
import { PanelTabPanel } from "../../../../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";

interface SingleAssociationPanelProps {
	onUpdateCount: (associationId: number, count: number) => void;
	objectAssociation: DirectedSDObjectAssociation;
}

export function SingleAssociationPanel({ onUpdateCount, objectAssociation }: SingleAssociationPanelProps) {
	const { sdRecord } = useRecordDetailsContext();

	return (
		<PanelTabPanel className="flex w-full flex-col pr-8 pt-4" unmount={false}>
			<AssociationGroup
				sourceRecord={sdRecord}
				objectAssociation={objectAssociation}
				onUpdateCount={onUpdateCount}
				isCollapsible={false}
				associationOpeningVariant="currentPageSidePanel"
				infiniteScroll={true}
				limit={APP_CONFIG.maxLocalSearchResults}
			/>
		</PanelTabPanel>
	);
}
