import { AccessibleButtonDiv } from "../../../../../../components/AccessibleButtonDiv";
import { forwardRef, HTMLAttributes } from "react";
import { Button, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";

interface ButtonTypeaheadSelectButtonProps extends Omit<HTMLAttributes<HTMLDivElement>, "onClick"> {
	startIcon: string;
	onClick?: () => void;
	onRemoveClick?: () => void;
}

export const ButtonTypeaheadSelectButton = forwardRef<HTMLDivElement, ButtonTypeaheadSelectButtonProps>(
	({ children, onClick, startIcon, onRemoveClick, className, ...divProps }: ButtonTypeaheadSelectButtonProps, ref) => {
		return (
			<AccessibleButtonDiv
				ref={ref}
				onClick={onClick}
				{...divProps}
				className={clsx(
					"rounded-full border px-3 py-2",
					"bg-c_bg_02 border-c_bg_02",
					"hover:border-c_bg_05 hover:bg-c_bg_05",
					"active:bg-c_bg_05 active:border-c_bg_05",
					`data-[state="open"]:bg-c_bg_05 data-[state="open"]:border-c_bg_05`,
					"focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 text-c_text_primary",
					className
				)}
			>
				<div className="flex items-center gap-1">
					<Icon icon={startIcon} className="text-c_icon_regular flex shrink-0 items-center" size="base" />
					<div className="text-label-sm truncate px-1">{children}</div>
					{onRemoveClick ? (
						<Button
							startIcon={ICONS.cross}
							size="xs"
							variant="text"
							onClick={(e) => {
								e.stopPropagation();
								onRemoveClick();
							}}
						/>
					) : null}
				</div>
			</AccessibleButtonDiv>
		);
	}
);
