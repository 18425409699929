import { Tab } from "@headlessui/react";
import { Accordion } from "../../../../../../components/Accordion";
import { coachingAdviceCategories } from "../types";
import { CoachingAdviceCategoryTab } from "./CoachingAdviceCategoryTab";
import { CoachingAdviceRating } from "./CoachingAdviceRating";
import { CoachingAdvice as CoachingAdviceType } from "@salesdesk/salesdesk-schemas";

export function CoachingAdvice({ coachingAdvice }: { coachingAdvice: CoachingAdviceType }) {
	return (
		<Accordion title="Coaching advice" variant="secondary" defaultOpen={true}>
			<Tab.Group as="div" className="mt-4">
				<Tab.List as="div" className="mb-3 flex w-full gap-4 overflow-x-auto pb-3 pl-1 pr-2 pt-1">
					{coachingAdviceCategories.map((category) => (
						<CoachingAdviceCategoryTab key={category.id} category={category} />
					))}
				</Tab.List>
				<Tab.Panels>
					{coachingAdviceCategories.map((category) => {
						if (!coachingAdvice) {
							return null;
						}
						return (
							<Tab.Panel key={category.id}>
								<CoachingAdviceRating ratingValue={coachingAdvice[category.id].rating} />
								<div className="mt-6 px-4 py-2">{coachingAdvice[category.id].explanation}</div>
							</Tab.Panel>
						);
					})}
				</Tab.Panels>
			</Tab.Group>
		</Accordion>
	);
}
