import { useMemo, useState, useEffect, useCallback } from "react";

import {
	mAccountDef,
	mLeadDef,
	mWorkspaceDef,
	mUserDef,
	getDefaultObjectAssociationId,
	AssociationSide,
	mOpportunityDef,
} from "@salesdesk/salesdesk-model";
import {
	rsr,
	SDRecord,
	getSDObjectSystemFieldByName,
	getSDRecordFieldValue,
	SDObject,
} from "@salesdesk/salesdesk-schemas";

import { OriginRecordDetails } from "../../../../types";
import { RECORD_FIELDS_KEY, useRecordForm, getRecordFormFieldId } from "../../../../../../../records";
import { useGetObjectMap } from "../../../../../../../objects/hooks";
import { BASIC_INFO_FIELD_NAMES_TO_FILTER } from "../../../../../../utils";
import { useSearchRecordsFn } from "../../../../../../../records/search/hooks/useSearchRecordsFn";

type BasicInfoDefaultValues = Record<string, unknown> | null;

export function useBasicInfoPanelForm(workspaceObject: SDObject, originRecordDetails: OriginRecordDetails) {
	const { fields, ...formProps } = useRecordForm({
		sdObject: workspaceObject,
	});

	const [defaultValues, setDefaultValues] = useState<BasicInfoDefaultValues>(null);
	const searchRecords = useSearchRecordsFn();

	const objectMap = useGetObjectMap();

	const basicInfoPanelFields = useMemo(
		() => fields.filter((workspaceField) => !BASIC_INFO_FIELD_NAMES_TO_FILTER.includes(workspaceField._name)),
		[fields]
	);

	const updateDefaultValues = useCallback((values: BasicInfoDefaultValues) => {
		setDefaultValues(values === null ? {} : values);
	}, []);

	const getDefaultValuesByFieldNamesMap = useCallback(
		(
			originalSDObject: SDObject,
			originalSDRecord: SDRecord,
			objectFieldToWorkspaceFieldMap: Record<string, string> // { [originalSDObjectFieldName]: workspaceFieldName }
		) => {
			const values: BasicInfoDefaultValues = {};

			Object.entries(objectFieldToWorkspaceFieldMap).forEach(([originalObjectFieldName, workspaceFieldName]) => {
				const originalSDObjectField = getSDObjectSystemFieldByName(originalSDObject, originalObjectFieldName);

				if (!originalSDObjectField) return null;

				const originalSDObjectFieldValue = getSDRecordFieldValue(originalSDRecord, originalSDObjectField._id)?._value;

				const workspaceField = getSDObjectSystemFieldByName(workspaceObject, workspaceFieldName);

				if (originalSDObjectFieldValue != null && workspaceField) {
					values[getRecordFormFieldId(workspaceField._id)] = originalSDObjectFieldValue;
				}
			});

			if (Object.keys(values).length === 0) return null;

			return {
				[RECORD_FIELDS_KEY]: values,
			};
		},
		[workspaceObject]
	);

	const defineOpportunityDefaultValues = useCallback(() => {
		searchRecords({
			query: rsr
				.query()
				.and(rsr.equals("_deleted", false))
				.not(rsr.equals("isTemplate", true))
				.and(
					/* 
						We don't fetch association object itself here
						as Account->Opportunity is a default association
						so we know the id for the association by calling 'getDefaultObjectAssociationId'
					*/
					rsr.hasAssociationToRecordForObjectAssociation(
						getDefaultObjectAssociationId(mAccountDef.ID, mOpportunityDef.ID),
						originRecordDetails.originRecord._id,
						AssociationSide.RECORD_1
					)
				)
				.buildQuery(),
			from: 0,
			size: 1,
		})
			.then(({ searchResults }) => {
				const accountSDObject = objectMap.get(mAccountDef.ID);
				const [accountRecord] = searchResults;

				if (!accountSDObject || !accountRecord) {
					updateDefaultValues(null);
					return;
				}

				updateDefaultValues(
					getDefaultValuesByFieldNamesMap(accountSDObject, accountRecord, {
						[mAccountDef.NAME_FIELD_NAME]: mWorkspaceDef.COMPANY_NAME_FIELD_NAME,
						[mAccountDef.LOGO_FIELD_NAME]: mWorkspaceDef.COMPANY_LOGO_FIELD_NAME,
					})
				);
			})
			.catch(() => {
				updateDefaultValues(null);
			});
	}, [searchRecords, originRecordDetails, objectMap, getDefaultValuesByFieldNamesMap, updateDefaultValues]);

	const defineAccountDefaultValues = useCallback(() => {
		const { originRecord, originRecordObject } = originRecordDetails;

		updateDefaultValues(
			getDefaultValuesByFieldNamesMap(originRecordObject, originRecord, {
				[mAccountDef.NAME_FIELD_NAME]: mWorkspaceDef.COMPANY_NAME_FIELD_NAME,
				[mAccountDef.LOGO_FIELD_NAME]: mWorkspaceDef.COMPANY_LOGO_FIELD_NAME,
			})
		);
	}, [getDefaultValuesByFieldNamesMap, updateDefaultValues, originRecordDetails]);

	const defineLeadDefaultValues = useCallback(() => {
		const { originRecord, originRecordObject } = originRecordDetails;

		updateDefaultValues(
			getDefaultValuesByFieldNamesMap(originRecordObject, originRecord, {
				[mUserDef.COMPANY_FIELD_NAME]: mWorkspaceDef.COMPANY_NAME_FIELD_NAME,
			})
		);
	}, [getDefaultValuesByFieldNamesMap, updateDefaultValues, originRecordDetails]);

	useEffect(() => {
		switch (originRecordDetails.originRecordObject._id) {
			case mOpportunityDef.ID:
				defineOpportunityDefaultValues();
				break;
			case mAccountDef.ID:
				defineAccountDefaultValues();
				break;
			case mLeadDef.ID:
				defineLeadDefaultValues();
				break;
		}
	}, [defineOpportunityDefaultValues, defineAccountDefaultValues, defineLeadDefaultValues, originRecordDetails]);

	return {
		...formProps,
		fields: basicInfoPanelFields,
		defaultValues,
	};
}
