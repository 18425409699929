import { useRecordActionRuleValues } from "../../../hooks/useRecordActionRuleValues";
import { RuleElement } from "./RuleElement";
import { RuleElementType } from "../../../types";

interface ActionRuleElementProps {
	type?: RuleElementType;
	placeholder: string;
}

export function ActionRuleElement({ type, placeholder }: ActionRuleElementProps) {
	const actionRuleValues = useRecordActionRuleValues(placeholder);

	return <RuleElement type={type} values={actionRuleValues} />;
}
