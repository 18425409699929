import { useMemo } from "react";

import { getSDRecordSystemFieldValueByFieldName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { isSalesDeskUserObject, isUserObject, mUserDef } from "@salesdesk/salesdesk-model";

export function useGetLoginAuthorizationDetails(sdObject?: SDObject, sdRecord?: SDRecord) {
	return useMemo(() => {
		const isUserRecord = sdObject ? isUserObject(sdObject?._id) : false;

		if (!sdObject || !sdRecord || !isUserRecord)
			return { isSalesDeskUser: false, isAuthorizedToLogIn: false, isUserRecord };

		return {
			isSalesDeskUser: isSalesDeskUserObject(sdRecord._objectDefId),
			isAuthorizedToLogIn: Boolean(
				getSDRecordSystemFieldValueByFieldName(sdObject, sdRecord, mUserDef.LOGIN_AUTHORIZED_FIELD_NAME)?._value
			),
			isUserRecord,
		};
	}, [sdObject, sdRecord]);
}
