import { useCallback, useMemo, memo } from "react";
import { Draggable } from "@hello-pangea/dnd";
import clsx from "clsx";

import { AbilityAction, AbilitySubject, sdSubject } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../../../../auth";
import { generatePreviewLinkMenuItem, getCurrentPathWithRecordIdParam, RecordCard } from "../../../../../../../records";
import { AssociationsSummaryData } from "../../../../../../../recordAssociations";

import { getFieldInfoArrayForBoardRecordDetails, getFieldInfoForBoardRecordDetails } from "../../../../../../utils";

import { BoardFieldId, BoardRecordDetails, FULL_RECORD_DATA_FIELD_ID } from "../../../../../../types";
import { useDataboardDetailsContext } from "../../../../../../hooks/useDataboardDetailsContext";
import { useBulkEditContext } from "../../../../../BulkEdit";

interface KanbanCardProps {
	index: number;
	recordDetails: BoardRecordDetails;
	fieldsToDisplay: BoardFieldId[];
	mediaFieldId?: BoardFieldId;
	associationsSummary?: AssociationsSummaryData;
}

function KanbanCardComponent({
	index,
	recordDetails,
	fieldsToDisplay,
	mediaFieldId,
	associationsSummary,
}: KanbanCardProps) {
	const principal = useWebPrincipal();

	const { boardFieldMap } = useDataboardDetailsContext();

	const { inBulkEditMode, selectedRecords, toggleRecordSelection } = useBulkEditContext();

	const sdRecord = recordDetails[FULL_RECORD_DATA_FIELD_ID];
	const recordId = sdRecord ? String(sdRecord._id) : null;

	const getRecordLink = getCurrentPathWithRecordIdParam;

	const recordLink = recordId ? getRecordLink(recordId) : "";
	const getCustomContextMenuItems = useCallback(() => {
		return { primaryItems: generatePreviewLinkMenuItem(recordLink) };
	}, [recordLink]);

	const fieldInfoToDisplay = useMemo(() => {
		if (!fieldsToDisplay || !recordId) return [];
		return getFieldInfoArrayForBoardRecordDetails(fieldsToDisplay, recordDetails, boardFieldMap);
	}, [fieldsToDisplay, recordId, recordDetails, boardFieldMap]);

	const mediaFieldInfo = useMemo(() => {
		return recordId && mediaFieldId
			? getFieldInfoForBoardRecordDetails(mediaFieldId, recordDetails, boardFieldMap)
			: undefined;
	}, [recordId, mediaFieldId, recordDetails, boardFieldMap]);

	if (!sdRecord || !recordId) {
		return null;
	}

	const isSelected = inBulkEditMode && selectedRecords.some((record) => record._id === sdRecord._id);

	return (
		<Draggable
			key={sdRecord._id}
			draggableId={recordId}
			index={index}
			isDragDisabled={!principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Record, sdRecord))}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					tabIndex={-1}
					className={clsx(snapshot.isDragging && "opacity-90", "mb-3")}
				>
					<RecordCard
						key={`card-${recordId}`}
						sdRecord={sdRecord}
						fieldInfoToDisplay={fieldInfoToDisplay}
						mediaFieldInfo={mediaFieldInfo}
						active={snapshot.isDragging}
						getRecordLink={getRecordLink}
						getCustomContextMenuItems={getCustomContextMenuItems}
						vertical
						associationsSummary={associationsSummary}
						selected={isSelected}
						onToggleSelect={inBulkEditMode ? () => toggleRecordSelection(sdRecord) : undefined}
					/>
				</div>
			)}
		</Draggable>
	);
}

export const KanbanCard = memo(KanbanCardComponent);
