import { useMemo } from "react";

import { Field, FieldInfo, SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectMap } from "../../../../objects/hooks";
import { isMediaField } from "../../../../fields";
import { getRecordFieldInfo, getSDRecordNameFieldInfo } from "../../../utils/records";
import { BaseRecordCardProps } from "../types";
import { RecordCard } from "./RecordCard";
import { mDocDef } from "@salesdesk/salesdesk-model";

interface FieldIdRecordCardProps extends BaseRecordCardProps {
	fieldIdsToDisplay: Field["_id"][];
	sdObject?: SDObject;
}

/**
 * Retrieves the field information for the given field IDs to display on the card. If no media field information is provided
 * it will use the first media field found in `fieldIdsToDisplay`. Note that the name field does not need to be included
 * as it will be automatically added.
 *
 * For efficiency, an SDObject can be passed to the card to retrieve the field information
 * instead of using the object map.
 */
export function FieldIdRecordCard({
	sdRecord,
	fieldIdsToDisplay,
	sdObject,
	mediaFieldInfo,
	...restProps
}: FieldIdRecordCardProps) {
	const objectMap = useGetObjectMap(Boolean(sdObject));

	const [fieldInfoToDisplay, mediaField] = useMemo(() => {
		const recordSDObject = sdObject || objectMap.get(sdRecord._objectDefId);

		// TODO: Better error handling if no object found
		if (!recordSDObject) {
			console.error("No object found for record", sdRecord._id);
			return [[], undefined];
		}

		const displayFields = [
			getSDRecordNameFieldInfo(recordSDObject, sdRecord),
			...getRecordFieldInfo(recordSDObject, sdRecord, fieldIdsToDisplay),
		];

		if (mediaFieldInfo) {
			return [displayFields, mediaFieldInfo];
		}

		let mediaFieldToDisplay: FieldInfo | undefined = undefined;
		const filteredDisplayFields = displayFields.filter((fieldInfo) => {
			const { field } = fieldInfo;

			if (isMediaField(field) || (recordSDObject._id === mDocDef.ID && field._name === mDocDef.DOCUMENT_FIELD_NAME)) {
				mediaFieldToDisplay = fieldInfo;
				return false;
			}

			return true;
		});

		return [filteredDisplayFields, mediaFieldToDisplay];
	}, [fieldIdsToDisplay, mediaFieldInfo, objectMap, sdObject, sdRecord]);

	return (
		<RecordCard
			{...restProps}
			sdRecord={sdRecord}
			fieldInfoToDisplay={fieldInfoToDisplay}
			mediaFieldInfo={mediaField}
		/>
	);
}
