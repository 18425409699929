import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { asObjectSet, chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { SDObject } from "@salesdesk/salesdesk-schemas";

import {
	useBatchFavoriteRecordsMutation,
	useBatchUnfavoriteRecordsMutation,
	useGetFavoritedRecordsQuery,
} from "../../../../records/api/favoritesApi";
import { useToast } from "../../../../Toasts";
import { PopoverMenu } from "../../../../menu";
import { MAX_BATCH_SIZE } from "../utils";
import { useBulkEditContext } from "../hooks/useBulkEditContext";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { countPromiseResults } from "../../../../../utils";

interface BulkFavoriteButtonProps {
	sdObject?: SDObject;
}

export function BulkFavoriteButton({ sdObject }: BulkFavoriteButtonProps) {
	const { selectedRecords } = useBulkEditContext();

	const { data: favorites } = useGetFavoritedRecordsQuery();
	const [batchFavoriteRecords, { isLoading: isFavoriting }] = useBatchFavoriteRecordsMutation();
	const [batchUnfavoriteRecords, { isLoading: isUnfavoriting }] = useBatchUnfavoriteRecordsMutation();
	const toast = useToast();

	const isSaving = isFavoriting || isUnfavoriting;

	const allFavoriteIds = useMemo(() => asObjectSet(favorites), [favorites]);

	const [selectedRecordToFavorite, selectedRecordToUnfavorite] = useMemo(() => {
		const selectedRecordToFavorite = [];
		const selectedRecordToUnfavorite = [];
		for (const record of selectedRecords) {
			if (allFavoriteIds[record._id]) {
				selectedRecordToUnfavorite.push(record._id);
			} else {
				selectedRecordToFavorite.push(record._id);
			}
		}
		return [selectedRecordToFavorite, selectedRecordToUnfavorite];
	}, [selectedRecords, allFavoriteIds]);

	const onActionClick = (isFavorite: boolean) => {
		const chunks = chunk(isFavorite ? selectedRecordToFavorite : selectedRecordToUnfavorite, MAX_BATCH_SIZE);
		Promise.allSettled(
			chunks.map((recordIds) =>
				(isFavorite ? batchFavoriteRecords(recordIds) : batchUnfavoriteRecords(recordIds)).unwrap()
			)
		).then((results) => {
			const [successes, fails] = countPromiseResults(results, chunks);
			toast.triggerMessage({
				type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
				messageKey: isFavorite ? "record_batch_favorite" : "record_batch_unfavorite",
				messageParams: {
					records: `${successes} ${pluralizeWithS("record", successes)}`,
					total: String(fails + successes),
				},
			});
		});
	};

	const [favoriteLabel, unfavoriteLabel] = useMemo(() => {
		const recordsLabel = pluralizeWithS("record", selectedRecords.length);
		return [`Favorite ${recordsLabel}`, `Unfavorite ${recordsLabel}`];
	}, [selectedRecords]);

	if (!sdObject) {
		return null;
	}

	if (selectedRecordToFavorite.length && selectedRecordToUnfavorite.length) {
		return (
			<PopoverMenu
				menuContents={[
					{
						icon: ICONS.star,
						iconVariant: "fill",
						text: favoriteLabel,
						variant: "primary_icon",
						onClick: () => {
							onActionClick(true);
						},
						type: "button",
					},
					{
						icon: ICONS.star,
						iconVariant: "outline",
						text: unfavoriteLabel,
						variant: "primary_icon",
						onClick: () => {
							onActionClick(false);
						},
						type: "button",
					},
				]}
				placement="top-start"
			>
				<Button
					variant="text_inverted"
					startIcon={ICONS.star}
					iconVariant="outline"
					size="sm"
					disabled={!selectedRecords.length || isSaving}
				/>
			</PopoverMenu>
		);
	}

	const isMainFavorite = selectedRecordToFavorite.length > 0;
	return (
		<Tooltip text={isMainFavorite ? favoriteLabel : unfavoriteLabel} placement="top">
			<Button
				variant="text_inverted"
				startIcon={ICONS.star}
				iconVariant={isMainFavorite ? "fill" : "outline"}
				size="sm"
				disabled={!selectedRecords.length || isSaving}
				onClick={() => onActionClick(isMainFavorite)}
			/>
		</Tooltip>
	);
}
