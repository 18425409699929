import { mSystemObjectDef } from "../system_object_def";

export class mAccountDef extends mSystemObjectDef {
	static CLASS_NAME = "mAccountDef";
	static NAME = "Account";
	static PLURAL_NAME = "Accounts";
	static ICON = "ph-buildings";

	static ID = 1;

	static LOGO_FIELD_NAME = "logo";
	static ACCOUNT_TYPE_FIELD_NAME = "accountType";
	static STATUS_FIELD_NAME = "status";
	static ACCOUNT_MANAGER_FIELD_NAME = "accountManager";
	static EMAIL_FIELD_NAME = "email";
	static WEBSITE_FIELD_NAME = "website";
	static COUNTRY_FIELD_NAME = "country";
	static REGION_FIELD_NAME = "region";
	static PHONE_FIELD_NAME = "phone";

	constructor(ownerId) {
		super(mAccountDef.ID, ownerId);
	}
}
