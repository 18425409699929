import { useGetRecordWithObjectByRecordId } from "../../../../../hooks";
import { AvatarStatus, User } from "../../../types";
import { UserWithDetails } from "./UserWithDetails";
import { UserWithDetailsLoading } from "./UserWithDetailsLoading";

interface UserWithDetailsControllerProps {
	recordId: number;
	user?: User;
	status?: AvatarStatus;
}

export function UserWithDetailsController({ recordId, user, status }: UserWithDetailsControllerProps) {
	const { sdObject, sdRecord } = useGetRecordWithObjectByRecordId(recordId);

	// Have a loading skeleton here
	if (!sdRecord || !sdObject) {
		return <UserWithDetailsLoading />;
	}

	return <UserWithDetails sdObject={sdObject} sdRecord={sdRecord} user={user} status={status} />;
}
