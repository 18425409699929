import { useCallback } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useToast } from "../../../../Toasts";
import { useGetRecordFollowingStatus } from "../../../hooks";
import { useFavoriteRecordMutation, useUnfavoriteRecordMutation } from "../../../api/favoritesApi";
import { useUnwatchRecordMutation, useWatchRecordMutation } from "../../../api/watchesApi";

interface RecordFollowingButtonsProps {
	sdRecord?: SDRecord;
}

export function RecordFollowingButtons({ sdRecord }: RecordFollowingButtonsProps) {
	const { isWatched, isFavorited } = useGetRecordFollowingStatus(sdRecord);

	const toast = useToast();

	const [favoriteRecord, { isLoading: isFavoriteReqLoading }] = useFavoriteRecordMutation();
	const [unfavoriteRecord, { isLoading: isUnfavoriteReqLoading }] = useUnfavoriteRecordMutation();

	const isFavoriteLoading = isFavoriteReqLoading || isUnfavoriteReqLoading;

	const toggleFavorite = useCallback(() => {
		if (!sdRecord) return;

		if (isFavorited) {
			unfavoriteRecord(sdRecord._id)
				.unwrap()
				.then(() => toast.triggerMessage({ type: "success", messageKey: "record_unfavorited" }))
				.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_unfavorited" }));
		} else {
			favoriteRecord(sdRecord._id)
				.unwrap()
				.then(() => toast.triggerMessage({ type: "success", messageKey: "record_favorited" }))
				.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_favorited" }));
		}
	}, [isFavorited, favoriteRecord, unfavoriteRecord, sdRecord, toast]);

	const [watchRecord, { isLoading: isWatchReqLoading }] = useWatchRecordMutation();
	const [unwatchRecord, { isLoading: isUnwatchReqLoading }] = useUnwatchRecordMutation();

	const isWatchLoading = isWatchReqLoading || isUnwatchReqLoading;

	const toggleWatch = useCallback(() => {
		if (!sdRecord) return;

		if (isWatched) {
			unwatchRecord(sdRecord._id)
				.unwrap()
				.then(() => toast.triggerMessage({ type: "success", messageKey: "record_unwatched" }))
				.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_unwatched" }));
		} else {
			watchRecord(sdRecord._id)
				.unwrap()
				.then(() => toast.triggerMessage({ type: "success", messageKey: "record_watched" }))
				.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_watched" }));
		}
	}, [isWatched, watchRecord, unwatchRecord, sdRecord, toast]);

	return (
		<>
			<Button
				startIcon={ICONS.flag}
				size="sm"
				variant="text"
				onClick={toggleWatch}
				disabled={!sdRecord}
				isLoading={isWatchLoading}
				iconVariant={getButtonIconVariant(Boolean(isWatched))}
			/>
			<Button
				startIcon={ICONS.star}
				size="sm"
				variant="text"
				onClick={toggleFavorite}
				disabled={!sdRecord}
				isLoading={isFavoriteLoading}
				iconVariant={getButtonIconVariant(isFavorited)}
			/>
		</>
	);
}

function getButtonIconVariant(active: boolean) {
	return active ? "action_fill" : "outline";
}
