import { ButtonHTMLAttributes, forwardRef } from "react";
import { NavItem } from "../../../../components/Navbar";

interface NavButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	icon: string;
	text: string;
	expanded?: boolean;
	isSelected: boolean;
}

export const NavButton = forwardRef<HTMLButtonElement, NavButtonProps>(
	({ icon, text, expanded, isSelected, ...buttonProps }: NavButtonProps, ref) => {
		return (
			<NavItem
				navItemComponent={<button ref={ref} {...buttonProps} />}
				isSelected={isSelected}
				icon={icon}
				expanded={expanded}
				text={text}
				hideTooltip
			/>
		);
	}
);
