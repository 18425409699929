import { RefObject, useCallback, useState } from "react";

import { AbilityAction, AbilitySubject, CanParameters, getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { NotesController } from "../../../../../../notes";
import { Events } from "../../../../../../events";
import { useShowUsersActivities } from "../../../../../hooks";
import { getFirstName } from "../../../../../../users";
import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation } from "../../../../../../recordAssociations";
import { RecordAssociationTabPanel } from "./RecordAssociationTabPanel";
import { DisplayIf } from "../../../../../../../auth";
import { useRecordDetailsContext } from "../../../hooks/useRecordDetailsContext";

interface RecordSidePanelTabsProps {
	externalScrollContainerRef: RefObject<HTMLDivElement>;
	objectAssociations: DirectedSDObjectAssociation[];
}

export function RecordSidePanelTabs({ externalScrollContainerRef, objectAssociations }: RecordSidePanelTabsProps) {
	const { sdObject, sdRecord } = useRecordDetailsContext();

	const [associationCounts, setAssociationCounts] = useState<Record<number, number>>({});

	const updateAssociationCount = useCallback((associationId: number, count: number) => {
		setAssociationCounts((prevCounts) => ({ ...prevCounts, [associationId]: count }));
	}, []);

	const { showUserActivities, showCustomerActivities } = useShowUsersActivities(sdObject, sdRecord);
	const showUserActivitiesTab = showUserActivities || showCustomerActivities;

	const canViewCommentParams: CanParameters = [AbilityAction.View, AbilitySubject.Note];

	const [notesCount, setNotesCount] = useState<number>(0);
	const [activitiesCount, setActivitiesCount] = useState<number>(0);
	const [userActivitiesCount, setUserActivitiesCount] = useState<number>(0);

	if (!sdRecord || !sdObject) {
		return null;
	}

	return (
		<PanelTabs>
			<div className="flex flex-col gap-4 pt-4">
				<PanelTabList>
					{showUserActivitiesTab ? (
						<PanelTab count={userActivitiesCount}>
							{getFirstName(getSDRecordName(sdObject, sdRecord))}'s activity
						</PanelTab>
					) : null}
					{objectAssociations.map((associationDef) => {
						const count = associationCounts[associationDef.id] || 0;

						return (
							<PanelTab key={associationDef.id} count={count}>
								{capitalizeString(associationDef.connectedObject.label)}
							</PanelTab>
						);
					})}
					<DisplayIf can={canViewCommentParams}>
						<PanelTab count={notesCount}>Notes</PanelTab>
					</DisplayIf>
					<PanelTab count={activitiesCount}>History</PanelTab>
				</PanelTabList>
				<PanelTabPanels>
					{showUserActivitiesTab ? (
						<PanelTabPanel unmount={false}>
							<Events
								sdRecord={sdRecord}
								onUpdateCount={setUserActivitiesCount}
								externalScrollContainerRef={externalScrollContainerRef}
								showUsersActivity
							/>
						</PanelTabPanel>
					) : null}
					{objectAssociations.map((associationDef) => (
						<RecordAssociationTabPanel
							key={associationDef.id}
							associationDef={associationDef}
							sdRecord={sdRecord}
							updateAssociationCount={updateAssociationCount}
						/>
					))}
					<DisplayIf can={canViewCommentParams}>
						<PanelTabPanel unmount={false}>
							<NotesController sdRecord={sdRecord} variant="RightPanel" onUpdateCount={setNotesCount} />
						</PanelTabPanel>
					</DisplayIf>
					<PanelTabPanel unmount={false}>
						<Events
							sdRecord={sdRecord}
							onUpdateCount={setActivitiesCount}
							externalScrollContainerRef={externalScrollContainerRef}
						/>
					</PanelTabPanel>
				</PanelTabPanels>
			</div>
		</PanelTabs>
	);
}
