import { createContext, PropsWithChildren, useState, useCallback, useEffect, useMemo } from "react";
import userflow from "userflow.js";

import { mLeadDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { PATHS, useStableNavigate, useTenantRouteMatch } from "../../../routes";
import { useGetObjectMap } from "../../objects/hooks";
import { ONBOARDING_FLOWS, OnboardingFlow } from "../utils";
import { useFirstTimeLoggedIn, useOnboardingFlowState } from "../hooks";
import { OnboardingContextProps } from "../types";
import { WelcomeDialog } from "./WelcomeDialog";
import { Confetti } from "../../../components/Confetti";
import { OnboardingAddLead } from "../utils";
import { OnboardingLeadCreation } from "./OnboardingLeadCreation";
import { ToggleUserLoginProvider } from "../../users";
import { CreateWorkspaceModalProvider } from "../../workspaces";
import { WorkspaceOnboarding } from "./WorkspaceOnboarding";
import { TENANT_ROUTES } from "@salesdesk/salesdesk-schemas";

const initialValues: OnboardingContextProps = {
	handleCreateWorkspace: () => null,
	startFlow: (onboardingFlowName: OnboardingFlow) => null,
	nextStep: () => null,
	stopFlow: () => null,
	showConfetti: () => null,
};

export const OnboardingContext = createContext<OnboardingContextProps>(initialValues);

export function OnboardingProvider({ children }: PropsWithChildren) {
	const [confettiOpen, setConfetti] = useState(false);

	const showConfetti = useCallback(() => setConfetti(true), []);

	const { currentOnboardingFlowStep, startFlow, nextStep, stopFlow } = useOnboardingFlowState(ONBOARDING_FLOWS);

	const isFirstTimeLoggedIn = useFirstTimeLoggedIn();
	const navigate = useStableNavigate();
	const objectMap = useGetObjectMap();

	const leadSDObject = objectMap.get(mLeadDef.ID);
	const workspaceSDObject = objectMap.get(mWorkspaceDef.ID);

	const [welcomeModalOpen, setWelcomeModalOpen] = useState(isFirstTimeLoggedIn);

	const isDashboardPage = useTenantRouteMatch(TENANT_ROUTES.DASHBOARD);
	const isObjectBoardPage = useTenantRouteMatch(TENANT_ROUTES.OBJECT_BOARD);

	const redirectToLeadPage = useCallback(() => {
		if (!leadSDObject) return;

		navigate(PATHS.OBJECT_RECORD_BOARD(leadSDObject));
	}, [leadSDObject, navigate]);

	const startStepByStepTour = useCallback(() => {
		redirectToLeadPage();
		setWelcomeModalOpen(false);
		userflow.track("step_by_step_tour_clicked");
	}, [redirectToLeadPage]);

	const handleCreateWorkspace = useCallback(() => {
		if (!workspaceSDObject) return;

		setWelcomeModalOpen(false);
		startFlow(OnboardingFlow.CREATE_A_WORKSPACE);
		navigate(PATHS.OBJECT_RECORD_BOARD(workspaceSDObject));
	}, [navigate, workspaceSDObject, startFlow]);

	useEffect(() => {
		if (currentOnboardingFlowStep === OnboardingAddLead.REDIRECT_TO_LEAD_AND_OPEN_MODAL_CREATION) {
			setWelcomeModalOpen(false);
		}
	}, [currentOnboardingFlowStep, setWelcomeModalOpen]);

	const contextValue = useMemo(
		() => ({
			handleCreateWorkspace,
			currentOnboardingFlowStep,
			startFlow,
			nextStep,
			stopFlow,
			showConfetti,
		}),
		[handleCreateWorkspace, currentOnboardingFlowStep, startFlow, nextStep, stopFlow, showConfetti]
	);

	return (
		<OnboardingContext.Provider value={contextValue}>
			{children}
			{welcomeModalOpen && (isDashboardPage || isObjectBoardPage) ? (
				<WelcomeDialog onClose={() => setWelcomeModalOpen(false)} handleStepByStepTour={startStepByStepTour} />
			) : null}
			<CreateWorkspaceModalProvider>
				<ToggleUserLoginProvider>
					<OnboardingLeadCreation />
				</ToggleUserLoginProvider>
			</CreateWorkspaceModalProvider>
			{confettiOpen ? <Confetti onConfettiFinish={() => setConfetti(false)} /> : null}
			<WorkspaceOnboarding />
		</OnboardingContext.Provider>
	);
}
