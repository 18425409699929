import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions/textAppenders";
import { nonNegativeIntegerSchema } from "../../components/nonNegativeIntegerSchema";

export const objectMultiplicitySchema = asConst({
	description: "An array containing min and max which indicates the number of values this field can have",
	type: "array",
	items: nonNegativeIntegerSchema,
	minItems: 2,
	maxItems: 2,
});
export const objectChildrenMultiplicitySchema = asConst({
	title: "SDObject Child Multiplicity",
	description: appendNotEditable("The multiplicity of each child SDObject for this SDObject"),
	type: "object",
	additionalProperties: objectMultiplicitySchema,
});
