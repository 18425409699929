import { useEffect, useState } from "react";
import { JSONContent } from "@tiptap/core";

import { convertRichTextJSONToText } from "../../utils";
import { DisplayTextField } from "../../../TextField";

interface DisplayRichTextCompactProps {
	value: JSONContent;
	placeholder?: string;
}

export function DisplayRichTextCompact({ value, placeholder }: DisplayRichTextCompactProps) {
	const [text, setText] = useState(() => convertRichTextJSONToText(value));

	useEffect(() => {
		setText(convertRichTextJSONToText(value));
	}, [value]);

	return <DisplayTextField value={text} placeholder={placeholder} />;
}
