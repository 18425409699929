import { useMemo } from "react";

import { MINUTE_IN_MS } from "@salesdesk/salesdesk-utils";
import { ChatMessage, SDRecord } from "@salesdesk/salesdesk-schemas";

import { MessageGroup, MessageGroupMessage } from "../types";
import { useChatUsersRecordMap } from "./useChatUsersRecordMap";

const TIMESTAMP_INTERVAL = 5 * MINUTE_IN_MS;

export function useChatMessageGroups(
	sortedChatMessages: ChatMessage[],
	preloadedUserRecords?: SDRecord[]
): MessageGroup[] {
	const chatUserRecords = useChatUsersRecordMap(sortedChatMessages, preloadedUserRecords);

	return useMemo(() => {
		let lastTimeSeen: number | undefined;
		const messageGroups: MessageGroup[] = [];
		if (sortedChatMessages.length === 0 || chatUserRecords == null) return messageGroups;

		let currentMessageGroup: MessageGroup | null = null;
		sortedChatMessages.forEach((message) => {
			// New group because of a new user messaging, or because enough time has passed since the last message
			const isNewUserGroup = currentMessageGroup == null || currentMessageGroup.userId !== message.createdBy;
			const isNewTimeGroup = lastTimeSeen == null || message.createdAt - lastTimeSeen > TIMESTAMP_INTERVAL;

			if (currentMessageGroup == null || isNewUserGroup || isNewTimeGroup) {
				currentMessageGroup = {
					userId: message.createdBy,
					userRecord: chatUserRecords.get(message.createdBy),
					messages: [],
					displayTimestamp: isNewTimeGroup,
				};
				messageGroups.push(currentMessageGroup);
			}

			const messageGroupMessage: MessageGroupMessage = { ...message, jsonContent: JSON.parse(message.message) };
			currentMessageGroup.messages.push(messageGroupMessage);

			lastTimeSeen = message.createdAt;
		});

		return messageGroups;
	}, [sortedChatMessages, chatUserRecords]);
}
