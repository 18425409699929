import React, { DetailedHTMLProps, ButtonHTMLAttributes, forwardRef, useContext, AnchorHTMLAttributes } from "react";
import clsx from "clsx";
import { Router } from "@remix-run/router";
import { Link, LinkProps as RouterLinkProps } from "react-router-dom";
import { Transition } from "@headlessui/react";

import { Icon, IconVariant } from "../Icon/Icon";
import { tw } from "../../utils";
import { AlertBubble } from "../AlertBubble";
import { PopoverTriggerContext } from "../PopoverTriggerContext";
import { Spinner } from "../Spinner/Spinner";
import { useHasMounted } from "../../hooks/useHasMounted";

type ButtonIconVariant = IconVariant | "action_fill";

type BaseProps = {
	size?: ButtonSize;
	variant?: keyof typeof ColorVariants;
	startIcon?: string;
	iconVariant?: ButtonIconVariant;
	endIcon?: string;
	alertCount?: number;
	active?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	fullWidth?: boolean;
	tabIndexOverride?: number;
	disablePopoverOpen?: boolean;
};

type WindowWithRouter = Window & { router?: Router };

export type ButtonAsButton = BaseProps &
	Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, keyof BaseProps | "className"> & {
		as?: "button";
	};

export type ButtonAsLink = BaseProps &
	Omit<RouterLinkProps, keyof BaseProps> & {
		as: "link";
	};

// Button as anchor tag allows the button to be used for links that exist outside of
// the react router context, e.g. the popovers within the RTF mentions which are mounted
// in their own React roots.
export type ButtonAsAnchor = BaseProps &
	Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps | "className"> & {
		as: "a";
	};

export type ButtonProps = ButtonAsButton | ButtonAsLink | ButtonAsAnchor;

export type ButtonSize = "xs" | "lg" | "sm";

export type ButtonVariant =
	| "primary"
	| "secondary"
	| "primary_dark"
	| "danger"
	| "inverted"
	| "text_inverted"
	| "outlined"
	| "text"
	| "primary_text"
	| "danger_text"
	| "outlined_danger";

type VariantClasses = {
	[key in ButtonVariant]: {
		default: (active?: boolean) => string;
		disabled: string;
		icon?: string;
		iconDisabled?: string;
	};
};

function generateTextButtonVariantClasses(textClass?: string): VariantClasses[ButtonVariant] {
	return {
		default: (active) =>
			clsx(
				active ? tw`bg-c_bg_02 border-c_bg_02` : tw`bg-transparent border-transparent`,
				textClass || tw`text-c_text_primary`,
				tw`hover:border-c_bg_02 hover:bg-c_bg_02`,
				tw`active:bg-c_bg_02 active:border-c_bg_02`,
				tw`data-[state="open"]:bg-c_bg_02 data-[state="open"]:border-c_bg_02`,
				tw`focus-visible:ring-c_action_focus`
			),
		disabled: tw`bg-transparent border-transparent text-c_text_disabled`,
		icon: textClass || undefined,
	};
}

function generateOutlinedButtonVariantClasses(iconClass?: string): VariantClasses[ButtonVariant] {
	return {
		default: (active) =>
			clsx(
				active ? tw`bg-c_bg_02` : tw`bg-c_bg_01`,
				tw`border-c_border_btn hover:bg-c_bg_02`,
				tw`active:bg-c_bg_02`,
				tw`data-[state="open"]:bg-c_bg_02`,
				tw`text-c_text_primary focus-visible:ring-c_action_focus focus-visible:border-c_action_01`
			),
		disabled: tw`bg-c_bg_disabled_02 border-c_border_btn text-c_text_disabled`,
		icon: iconClass,
	};
}

const loadingOverlayBackground: Partial<Record<ButtonVariant, string>> = {
	primary: tw`bg-c_action_01 border-c_action_01`,
	secondary: tw`bg-c_bg_02 border-c_bg_02`,
	danger: tw`bg-c_danger_focus border-c_danger_focus`,
	outlined: tw`bg-c_bg_01 border-c_border_btn`,
	inverted: tw`bg-c_bg_01 border-c_bg_01`,
	text_inverted: tw`bg-transparent border-transparent`,
	primary_text: tw`bg-c_bg_01 border-c_bg_01`, // TODO: needs to handle the case where the background isn't white by making it transparent instead
	primary_dark: tw`bg-c_bg_06 border-c_bg_06`,
};
const VARIANTS_WITH_LOADING_SPINNER = Object.keys(loadingOverlayBackground);

const ColorVariants: VariantClasses = {
	primary: {
		default: (active) =>
			clsx(
				active ? tw`bg-c_action_03 border-c_action_03` : tw`bg-c_action_01 border-c_action_01`,
				tw`hover:border-c_action_03 hover:bg-c_action_03`,
				tw`active:bg-c_action_03 active:border-c_action_03`,
				tw`data-[state="open"]:bg-c_action_03 data-[state="open"]:border-c_action_03`,
				tw`focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 text-c_text_inverted`
			),
		disabled: tw`bg-c_bg_disabled_01 border-c_bg_disabled_01 border text-c_text_inverted`,
		icon: tw`text-c_icon_inverted`,
		iconDisabled: tw`text-c_icon_inverted`,
	},
	secondary: {
		default: (active) =>
			clsx(
				active ? tw`bg-c_bg_05 border-c_bg_05` : tw`bg-c_bg_02 border-c_bg_02`,
				tw`hover:border-c_bg_05 hover:bg-c_bg_05`,
				tw`active:bg-c_bg_05 active:border-c_bg_05`,
				tw`data-[state="open"]:bg-c_bg_05 data-[state="open"]:border-c_bg_05`,
				tw`focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 text-c_text_primary`
			),
		disabled: tw`bg-c_bg_disabled_02 border-c_bg_disabled_02 text-c_text_disabled`,
	},
	primary_dark: {
		default: (active) =>
			clsx(
				active ? tw`bg-c_bg_08 border-c_bg_08` : tw`bg-c_bg_06 border-c_bg_06`,
				tw`hover:border-c_bg_08 hover:bg-c_bg_08`,
				tw`active:bg-c_bg_08 active:border-c_bg_08`,
				tw`data-[state="open"]:bg-c_bg_08 data-[state="open"]:border-c_bg_08`,
				tw`focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 text-c_text_inverted`
			),
		disabled: tw`bg-c_bg_disabled_01 border-c_bg_disabled_01 border text-c_text_inverted`,
		icon: tw`text-c_icon_inverted`,
		iconDisabled: tw`text-c_icon_inverted`,
	},
	danger: {
		default: (active) =>
			clsx(
				active ? tw`bg-c_danger_02 border-c_danger_02` : tw`bg-c_danger_focus border-c_danger_focus`,
				tw`hover:border-c_danger_02 hover:bg-c_danger_02`,
				tw`active:bg-c_danger_02 active:border-c_danger_02`,
				tw`data-[state="open"]:bg-c_danger_02 data-[state="open"]:border-c_danger_02`,
				tw`focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 text-c_text_inverted`
			),
		disabled: tw`bg-c_bg_disabled_01 border-c_bg_disabled_01 text-c_text_inverted`,
		icon: tw`text-c_icon_inverted`,
	},
	inverted: {
		default: (active) =>
			clsx(
				active ? tw`border-c_bg_04 bg-c_bg_04` : tw`border-c_bg_01 bg-c_bg_01`,
				tw`text-c_text_primary hover:border-c_bg_04 hover:bg-c_bg_04`,
				tw`active:border-c_bg_04 active:bg-c_bg_04`,
				tw`data-[state="open"]:border-c_bg_04 data-[state="open"]:bg-c_bg_04`,
				tw`focus-visible:ring-c_action_focus`
			),
		disabled: tw`bg-c_bg_disabled_01 border-c_bg_disabled_01 text-c_text_disabled`,
		icon: tw`text-c_action_01`,
	},
	text_inverted: {
		default: (active) =>
			clsx(
				active ? tw`border-transparent bg-c_bg_01/20` : tw`border-transparent bg-transparent`,
				tw`text-c_text_inverted hover:bg-c_bg_01/15 hover:border-transparent`,
				tw`active:border-transparent active:bg-c_bg_01/20`,
				tw`data-[state="open"]:border-transparent data-[state="open"]:bg-c_bg_01/20`,
				tw`focus-visible:ring-c_action_focus`
			),
		disabled: tw`border-transparent bg-transparent text-c_text_disabled`,
		icon: tw`text-c_icon_inverted`,
		iconDisabled: tw`text-c_icon_disabled`,
	},
	outlined: generateOutlinedButtonVariantClasses(),
	outlined_danger: generateOutlinedButtonVariantClasses(tw`text-c_danger_focus`),
	text: generateTextButtonVariantClasses(),
	primary_text: generateTextButtonVariantClasses(tw`text-c_action_01`),
	danger_text: generateTextButtonVariantClasses(tw`text-c_danger_focus`),
};

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
	(
		{
			as,
			variant = "primary",
			size = "lg",
			startIcon,
			iconVariant = "outline",
			endIcon,
			disabled,
			children,
			type = "button",
			alertCount,
			active = false,
			isLoading,
			fullWidth,
			tabIndexOverride,
			disablePopoverOpen = false,
			...restProps
		},
		ref
	) => {
		const hasMounted = useHasMounted();

		const innerDisabled = disabled || isLoading;
		const classes = ColorVariants[variant];

		let iconClasses = innerDisabled
			? classes.iconDisabled
			: iconVariant === "action_fill"
				? tw`text-c_action_01`
				: classes.icon;

		if (!iconClasses) {
			iconClasses = innerDisabled ? tw`text-c_icon_disabled` : tw`text-c_icon_regular`;
		}
		iconClasses = tw`${hasMounted ? "transition " : ""}flex ${iconClasses}`;

		// No children and startIcon XOR endIcon (can't have both to be a single icon button)
		const singleIconButton = !children && (startIcon ? !endIcon : endIcon);
		const iconOnly = !children && startIcon && endIcon;

		const alertBubbleVariant = variant === "primary" ? "primary" : "secondary";
		const isLoadingWithSpinner =
			VARIANTS_WITH_LOADING_SPINNER.includes(variant) && (variant !== "text_inverted" || singleIconButton);
		const loadingBg = loadingOverlayBackground[variant];
		const spinnerSize = size === "xs" ? "xs" : "sm";

		const hideContents = isLoading && variant === "text_inverted" && singleIconButton;

		const loadingOverlay = (
			<Transition
				show={Boolean(isLoading && isLoadingWithSpinner)}
				leave={tw`transition-opacity delay-75 duration-0`}
				leaveFrom={tw`opacity-100`}
				leaveTo={tw`opacity-0`}
				className={clsx("absolute -inset-px flex items-center justify-around rounded-full border", loadingBg)}
			>
				<Spinner
					darkMode={["primary", "primary_dark", "danger", "text_inverted"].includes(variant)}
					size={spinnerSize}
				/>
			</Transition>
		);

		const iconComponentVariant = iconVariant === "action_fill" ? "fill" : iconVariant;

		let componentContent = null;
		if (hideContents) {
			componentContent = loadingOverlay;
		} else {
			componentContent = (
				<>
					<Icon
						className={clsx(iconClasses, "flex items-center")}
						icon={startIcon}
						size={size === "xs" ? "sm" : "base"}
						variant={iconComponentVariant}
					/>
					{children ? (
						<div
							className={clsx(
								{
									"px-1": size === "xs" || size === "sm",
									"min-h-[24px] px-2": size === "lg",
								},
								"truncate"
							)}
						>
							{children}
						</div>
					) : null}
					<Icon
						className={clsx(iconClasses, "flex items-center")}
						icon={endIcon}
						size={size === "xs" ? "sm" : "base"}
						variant={iconComponentVariant}
					/>
					{alertCount ? (
						<AlertBubble
							alertCount={alertCount}
							inline={!singleIconButton || size === "sm"}
							disabled={innerDisabled}
							variant={alertBubbleVariant}
						/>
					) : null}
					{loadingOverlay}
				</>
			);
		}

		const { open: popoverOpen } = useContext(PopoverTriggerContext);
		const isActive = active || (Boolean(popoverOpen) && !disablePopoverOpen);

		const classNames = clsx(
			innerDisabled ? classes.disabled : classes.default(isActive),
			size === "xs" && iconOnly ? "gap-0.5" : "gap-1",
			size === "xs" &&
				(singleIconButton
					? tw`size-5 justify-center p-0`
					: iconOnly
						? tw`p-0 w-fit px-0.5`
						: tw`w-fit px-1 py-1 text-[11px]`),
			size === "sm" &&
				(singleIconButton
					? alertCount
						? tw`w-fit h-8 px-3 py-0`
						: tw`size-8 justify-center p-0`
					: tw`text-label-sm px-3 py-2 w-fit`),
			size === "lg" &&
				(singleIconButton
					? tw`p-[9px]`
					: tw`${
							variant === "primary_text" || variant === "text" || variant === "text_inverted"
								? "text-label-sm"
								: "text-label"
						} px-4 py-3 w-fit`),
			innerDisabled && tw`cursor-not-allowed`,
			tw`relative flex shrink-0 items-center rounded-full border focus-visible:ring max-w-full`,
			fullWidth && tw`!w-full justify-center`
		);

		if (as === "link") {
			return (
				<Link ref={ref as React.Ref<HTMLAnchorElement>} className={classNames} {...(restProps as ButtonAsLink)}>
					{componentContent}
				</Link>
			);
		}

		if (as === "a") {
			const anchorTagProps = restProps as ButtonAsAnchor;

			return (
				<a
					ref={ref as React.Ref<HTMLAnchorElement>}
					className={classNames}
					{...anchorTagProps}
					onClick={(e) => {
						const { href } = anchorTagProps;

						const router = (window as WindowWithRouter).router;

						if (!href || !router) {
							return;
						}

						// Hooks into the react router object on the global window object
						// to prevent a reloading of the page for app links using ButtonAsAnchor
						e.preventDefault();
						e.stopPropagation();
						router.navigate(href);
					}}
				>
					{componentContent}
				</a>
			);
		}

		return (
			<button
				{...(restProps as ButtonAsButton)}
				className={classNames}
				disabled={innerDisabled}
				type={type as HTMLButtonElement["type"]}
				ref={ref as React.Ref<HTMLButtonElement>}
				tabIndex={tabIndexOverride ?? undefined} // Ignoring the tabIndex prop is required otherwise the popover component might break
			>
				{componentContent}
			</button>
		);
	}
);
