import { useMemo } from "react";
import { RuleValue } from "../types";
import { useNotificationRuleDialogContext } from "./useNotificationRuleDialogContext";

export function useRecordActionRuleValues(placeholder: string): RuleValue[] {
	const { selectedRuleConfig, selectedAction, selectedField, currentSelection, setCurrentSelection } =
		useNotificationRuleDialogContext();

	return useMemo(() => {
		const values: RuleValue[] = [];
		if (selectedRuleConfig?.fieldIdRequired) {
			values.push({
				value: selectedField?.name,
				placeholder: "this (optional)",
				onClick: () => setCurrentSelection("field"),
				active: currentSelection === "field",
			});
		}
		values.push({
			value: selectedAction?.name.toLowerCase(),
			placeholder: placeholder,
			onClick: () => setCurrentSelection("eventType"),
			active: currentSelection === "eventType",
		});
		return values;
	}, [
		selectedRuleConfig?.fieldIdRequired,
		selectedAction?.name,
		placeholder,
		currentSelection,
		selectedField?.name,
		setCurrentSelection,
	]);
}
