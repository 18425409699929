import { useMemo } from "react";

import { SDObject } from "@salesdesk/salesdesk-schemas";
import { getDefaultDisplayFieldIds } from "../../records";

export function useGetAssociationFieldIds(sdObject?: SDObject) {
	return useMemo(() => {
		if (!sdObject) return [];
		return getDefaultDisplayFieldIds(sdObject, "ASSOCIATION");
	}, [sdObject]);
}
