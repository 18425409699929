import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavItem } from "./NavItem";
import { isOnCurrentPath } from "./utils";

interface NavLinkProps {
	elementId?: string;
	icon: string;
	text: string;
	expanded?: boolean;
	link?: string;
	hideSelectDrop?: boolean;
}

export function NavLink({ elementId, icon, link, text, expanded, hideSelectDrop }: NavLinkProps) {
	const currentLocation = useLocation();

	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		setIsSelected(isOnCurrentPath(currentLocation.pathname, link));
	}, [currentLocation, link]);

	const noSetLink = link === undefined;

	return (
		<NavItem
			navItemComponent={<Link to={noSetLink ? "#" : link} id={elementId} aria-label={expanded ? undefined : text} />}
			isSelected={isSelected}
			icon={icon}
			expanded={expanded}
			text={text}
			hideSelectDrop={hideSelectDrop}
		/>
	);
}
