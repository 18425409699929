import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useWorkspaceContext } from "../../../../workspaces";
import { useIsInProgressMeeting } from "../../../hooks/useIsInProgressMeeting";
import { RecordBadgeType } from "../types";

export function useRecordBadges(sdRecord: SDRecord | undefined) {
	const { workspaceRecord, workspaceObject, internalArea } = useWorkspaceContext();
	let isSharedToWorkspace = false;
	if (sdRecord) {
		if (workspaceRecord && internalArea) {
			isSharedToWorkspace = sdRecord.sharedWorkspaceIds.includes(workspaceRecord._id);
		} else if (!workspaceRecord) {
			isSharedToWorkspace = sdRecord.sharedWorkspaceIds.length > 0;
		}
	}
	const isInProgressMeeting = useIsInProgressMeeting(sdRecord);
	const badges: RecordBadgeType[] = [];
	if (isInProgressMeeting) {
		badges.push("MEETING_IN_PROGRESS");
	}
	if (isSharedToWorkspace && workspaceObject) {
		badges.push("SHARED_IN_WORKSPACE");
	}
	return badges;
}
