import { Tooltip } from "@salesdesk/daisy-ui";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { DisplayPlaceholderField } from "../../../../fields/components/Fields/DisplayPlaceholderField";

interface DataPreviewRowProps extends PropsWithChildren {
	isFirstRow: boolean;
	content?: string | number | boolean | null;
	children?: React.ReactNode;
}

export function DataPreviewRow({ isFirstRow, content, children }: DataPreviewRowProps) {
	return (
		<div className={clsx("w-full py-4", !isFirstRow && "border-c_border_regular border-t")}>
			{children ?? (
				<Tooltip text={formatDataPreviewContent(content)} showOnTruncated>
					<div className="truncate">{formatDataPreviewContent(content) || <DisplayPlaceholderField />}</div>
				</Tooltip>
			)}
		</div>
	);
}

function formatDataPreviewContent(content: string | number | boolean | null | undefined) {
	if (!content) return "";
	if (typeof content === "number") return content.toLocaleString();
	return String(content);
}
