import { PropsWithChildren } from "react";
import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";

interface CardLabelProps {
	icon: string;
	label: string;
	required?: boolean;
	vertical?: boolean;
}

export function CardLabel({ children, icon, label, required, vertical = false }: PropsWithChildren<CardLabelProps>) {
	return (
		<div className={clsx("flex w-full max-w-full gap-1", vertical ? "break-inside-avoid-column flex-col" : undefined)}>
			<div
				className={clsx(
					"text-c_text_disabled flex items-center gap-1",
					!vertical ? "w-[120px] flex-shrink-0 flex-grow-0" : undefined
				)}
			>
				<Icon icon={icon} size="xs" />
				<div className="text-label-xs truncate">
					{label}
					{required ? "*" : null}
				</div>
			</div>
			<div className="w-full truncate">{children}</div>
		</div>
	);
}
