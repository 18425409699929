import { useMemo } from "react";

import { Field } from "@salesdesk/salesdesk-schemas";
import { stringContainsMatch } from "@salesdesk/salesdesk-utils";

import {
	AsyncTypeaheadSelectProps,
	SelectOption,
	SelectOptionId,
	SelectOptionItemVariant,
} from "../../../../../inputs";
import { BaseOptionFieldSettings, OptionFieldComponentSettings } from "../types";
import {
	generateOptionMap,
	getCountryOptions,
	getCountryOptionsFromIds,
	getCurrencyOptions,
	getCurrencyOptionsFromIds,
	getOptionsFromMapByIds,
	optionFieldSettingsFromField,
} from "../utils";
import { isUserObject } from "@salesdesk/salesdesk-model";
import { useGetRoleOptionsFunctions } from "./useGetRoleOptionsFunctions";
import { useGetRecordOptionsInWorkspace } from "./useGetRecordOptionsInWorkspace";

interface OptionFieldDetails extends Pick<AsyncTypeaheadSelectProps, "getOptions" | "getOptionsFromIds"> {
	optionDisplayVariant?: SelectOptionItemVariant;
}

const EMPTY_ARRAY: SelectOption[] = [];

export function useGetOptionFieldDetails(optionFieldSettings: OptionFieldComponentSettings) {
	const isFieldVariant = optionFieldSettings.variant === "field";
	const fieldVariantField = isFieldVariant ? optionFieldSettings.field : undefined;

	/*
	 * OptionFieldComponentSettings allows for a 'field' variant so that a component can pass a field to
	 * a OptionField without having to worry about generating the option field settings for that field
	 * or having to keep them stored in state to prevent re-generating the option field details every render.
	 *
	 * This 'field' variant is converted into one of the base option field settings.
	 */
	const fieldSettings: BaseOptionFieldSettings | undefined = useMemo(() => {
		if (!isFieldVariant) {
			return;
		}

		// Determines the option field settings from the given field
		return optionFieldSettingsFromField(fieldVariantField as Field);
	}, [isFieldVariant, fieldVariantField]);

	const settings = isFieldVariant && fieldSettings ? fieldSettings : optionFieldSettings;

	const variant = settings.variant;
	const isRecordField = variant === "records";
	const isFixedOptionsField = variant === "fixed_options";

	const baseObjectId = isRecordField ? settings.baseObjectId : undefined;
	const options = isFixedOptionsField ? settings.options : EMPTY_ARRAY;

	const { getOptions: getRecordOptions, getOptionsFromIds: getRecordOptionsFromIds } = useGetRecordOptionsInWorkspace({
		baseObjectId,
		fetchingRecordOptions: isRecordField,
		excludeRecordIds: isRecordField ? settings.filteredIds : undefined,
		onlyAuthorizedToLogIn: isRecordField ? settings.onlyAuthorizedToLogIn : undefined,
	});

	const { getOptions: getRoleOptions, getOptionsFromIds: getRoleOptionsFromIds } = useGetRoleOptionsFunctions();

	const optionFieldDetails: OptionFieldDetails = useMemo(() => {
		if (variant === "records") {
			const optionDisplayVariant = isUserObject(baseObjectId) ? "user_pill" : "outline";

			return {
				getOptions: getRecordOptions,
				getOptionsFromIds: getRecordOptionsFromIds,
				optionDisplayVariant,
			};
		} else if (variant === "roles") {
			return {
				getOptions: getRoleOptions,
				getOptionsFromIds: getRoleOptionsFromIds,
				optionDisplayVariant: "pill",
			};
		} else if (variant === "country") {
			return {
				getOptions: getCountryOptions,
				getOptionsFromIds: getCountryOptionsFromIds,
				optionDisplayVariant: "pill",
			};
		} else if (variant === "currency") {
			return {
				getOptions: getCurrencyOptions,
				getOptionsFromIds: getCurrencyOptionsFromIds,
				optionDisplayVariant: "pill",
			};
		} else {
			const optionValueMap = generateOptionMap(options);

			return {
				getOptions: (query: string) =>
					query ? options.filter((option) => stringContainsMatch(option.name, query)) : options,
				getOptionsFromIds: (ids: SelectOptionId[]) => getOptionsFromMapByIds(optionValueMap, ids),
				optionDisplayVariant: "pill",
			};
		}
	}, [
		variant,
		baseObjectId,
		getRecordOptions,
		getRecordOptionsFromIds,
		getRoleOptions,
		getRoleOptionsFromIds,
		options,
	]);

	return optionFieldDetails;
}
