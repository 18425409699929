import { useId, useState } from "react";
import { useForm } from "react-hook-form";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { FormDialog } from "../../../Dialog";
import { FormFieldSet } from "../../../forms";
import { EditTextField } from "../../../fields";
import { useToast } from "../../../Toasts";
import { useCreateApiKeyMutation } from "../api/apiKeysApi";
import { Divider } from "../../../../components/Divider/Divider";
import { FieldSet, Icon, TextInputPreviewCopyable } from "@salesdesk/daisy-ui";
import { Alert } from "../../../../components/Alert";
import { Checkbox } from "../../../inputs";

type FormData = { name: string };

interface CreateRuleDialogProps {
	onClose: () => void;
}

const CONFIRMATION_CHEXBOX_ERROR = "Please tick the confirmation checkbox";

export function CreateApiKeyDialog({ onClose }: CreateRuleDialogProps) {
	const formId = useId();

	const toast = useToast();
	const [createApiKey, { isLoading: isCreating, data: apiKey }] = useCreateApiKeyMutation();

	const [confirmed, setConfirmed] = useState(false);
	const [formError, setFormError] = useState<string>();

	const { control, handleSubmit } = useForm<FormData>({
		mode: "onChange",
		defaultValues: { name: "" },
	});

	const onFormSubmit = (formData: FormData) => {
		if (apiKey) {
			if (confirmed) {
				onClose();
			} else {
				setFormError(CONFIRMATION_CHEXBOX_ERROR);
			}
			return;
		}
		createApiKey({
			name: formData.name,
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "api_key_created" });
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "api_key_created" });
			});
	};

	return (
		<FormDialog
			open
			onOpenChange={(open) => {
				if (!open) {
					if (apiKey && !confirmed) {
						setFormError(CONFIRMATION_CHEXBOX_ERROR);
						return;
					}
					onClose();
				}
			}}
			title="New API key"
			description="Give your new API key a name and save your new API key details securely "
			width={600}
			isPending={isCreating}
			formId={formId}
			submitButtonLabel={apiKey ? "Done" : "Create"}
			footerContent={
				formError ? (
					<div className="text-c_danger_01 text-label flex gap-4">
						<Icon icon={ICONS.warningCircle} className="flex items-center" />
						<div>{formError}</div>
					</div>
				) : null
			}
			hideCloseButton={Boolean(apiKey)}
		>
			<form
				id={formId}
				onSubmit={handleSubmit(onFormSubmit)}
				className="mb-3 flex max-h-full flex-col gap-3 overflow-y-auto"
			>
				<FormFieldSet
					control={control}
					name="name"
					label="Key name"
					labelIcon={ICONS.text}
					required
					rules={{ required: "Key name is required" }}
				>
					{({ field, fieldState: { error } }) => (
						<EditTextField
							title="Key name"
							{...field}
							hasError={Boolean(error?.message)}
							autoFocus
							disabled={Boolean(apiKey)}
						/>
					)}
				</FormFieldSet>
				{apiKey != null ? (
					<>
						<Divider className="my-4" />
						<FieldSet label="Key ID" labelIcon={ICONS.numberCircleFour} labelFor="noEffect">
							<TextInputPreviewCopyable
								value={apiKey.apiKeyId}
								onCopy={() => toast.trigger("success", "API key copied.")}
							/>
						</FieldSet>
						<FieldSet label="Secret" labelIcon={ICONS.password} labelFor="noEffect">
							<TextInputPreviewCopyable
								value={apiKey.apiSecret}
								onCopy={() => toast.trigger("success", "API secret copied.")}
							/>
						</FieldSet>
						<Alert
							variant="warning"
							title="Important"
							message="This secret above is only shown once. Please make note of it and store in a safe location"
						/>
						<div className="pt-5">
							<label className="flex items-center gap-2">
								<Checkbox value={confirmed} onChange={setConfirmed} />
								<div className="text-label">Got it! I have saved my API key ID and secret in a safe place.</div>
							</label>
						</div>
					</>
				) : null}
			</form>
		</FormDialog>
	);
}
