import { useEffect, useMemo, useState } from "react";
import { Searchbar } from "../../../../inputs";
import { debounce } from "../../../../../utils";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import { useBoardPropOnChangeContext } from "../../../hooks/useBoardPropOnChangeContext";
import { useBoardQuerySelector } from "../../../store";

export function BoardSearchbar() {
	const { sdObject } = useDataboardDetailsContext();
	const boardPropOnChange = useBoardPropOnChangeContext();

	const boardQuery = useBoardQuerySelector();

	const [searchInputText, setSearchInputText] = useState(boardQuery || "");

	useEffect(() => {
		setSearchInputText(boardQuery || "");
	}, [boardQuery]);

	const debounceSearchQuery = useMemo(
		() =>
			debounce((query: string) => {
				boardPropOnChange("query", query);
			}, 300),
		[boardPropOnChange]
	);

	const onSearchChange = (query: string) => {
		setSearchInputText(query);

		// If we have an empty query we want it to update instantly for responsiveness
		if (!query) {
			boardPropOnChange("query", query);
			return;
		}

		debounceSearchQuery(query);
	};

	return (
		<Searchbar
			value={searchInputText}
			onChange={onSearchChange}
			tooltip={`Search${sdObject ? ` ${sdObject._pluralName}` : ""}`}
		/>
	);
}
