import { useCallback } from "react";

import { SdEventType } from "@salesdesk/salesdesk-model";

import { usePostEvent } from "../features/events/hooks/usePostEvent";

export function useCopyToClipboard() {
	const postEvent = usePostEvent();

	return useCallback(
		(copyText: string) => {
			navigator.clipboard.writeText(copyText);
			postEvent({
				event_type: SdEventType.COPIED_TO_CLIPBOARD,
				params: {
					text: copyText,
				},
			});
		},
		[postEvent]
	);
}
