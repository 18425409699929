import { useRef } from "react";
import clsx from "clsx";

import { useInfiniteScrollContainer } from "../../../hooks/ui";
import { useNotificationsContext } from "../hooks";
import { Notification } from "./Notification";
import { Divider } from "../../../components/Divider/Divider";
import { Spinner } from "@salesdesk/daisy-ui";
import { NotificationsListLoading } from "./NotificationsListLoading";

export function NotificationsList() {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { isLoading, notifications, loadNext } = useNotificationsContext();
	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			loadNext();
		},
	});

	const isInitialLoad = isLoading && notifications == null;

	return (
		<div
			ref={scrollContainerRef}
			className={clsx(!isInitialLoad && "overflow-y-auto", "flex w-full flex-col items-stretch gap-3 px-1")}
		>
			{isInitialLoad ? <NotificationsListLoading /> : null}
			{notifications != null
				? notifications.map((notification, index) => (
						<div key={notification.messageId} className="flex flex-col gap-3">
							<Notification notification={notification} />
							{index !== notifications.length - 1 ? (
								<Divider key={`${notification.messageId}-divider`} className="w-full" />
							) : null}
						</div>
				  ))
				: null}
			{isInitialLoad ? null : isLoading ? (
				<div className="flex h-[3rem] items-center justify-center">
					<Spinner size="md" />
				</div>
			) : (
				<div key={notifications?.length ?? 0} ref={containerBottomRef} />
			)}
		</div>
	);
}
