import { Bookmark } from "@salesdesk/salesdesk-schemas";
import { getBookmarkParamsFromLocation, hasViewChanged } from "../../utils";
import { MenuContents, PopoverMenu } from "../../../menu";
import { useUpdateMyBookmarkMutation } from "../../api/bookmarksApi";
import { useState } from "react";
import { EditBookmarkDialog } from "../dialogs/EditBookmarkDialog";
import { Button } from "@salesdesk/daisy-ui";
import { useToast } from "../../../Toasts";

interface SaveViewButtonProps {
	bookmark: Bookmark;
}

export function SaveViewButton({ bookmark }: SaveViewButtonProps) {
	const [createNewBookmarkOpen, setCreateNewBookmarkOpen] = useState(false);
	const [updateBookmark] = useUpdateMyBookmarkMutation();
	const toast = useToast();

	const viewHasChanged = hasViewChanged(bookmark);
	if (!viewHasChanged) {
		return null;
	}

	const onUpdateBookmarkClick = () => {
		updateBookmark({
			bookmarkId: bookmark.id,
			body: {
				name: bookmark.name,
				objectId: bookmark.objectId,
				value: getBookmarkParamsFromLocation(),
			},
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "bookmark_updated" });
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "bookmark_updated" });
			});
	};

	const menuContents: MenuContents = [
		{
			type: "button",
			text: "Update this view",
			subText: "Update the current bookmark",
			onClick: () => {
				onUpdateBookmarkClick();
			},
		},
		{
			type: "button",
			text: "Save as new view",
			subText: "Create a new bookmark view",
			onClick: () => {
				setCreateNewBookmarkOpen(true);
			},
		},
	];

	return (
		<div className="whitespace-nowrap">
			<PopoverMenu menuContents={menuContents} placement="bottom-start" widthVariant="fit">
				<Button size="sm" variant="primary_text">
					Save view
				</Button>
			</PopoverMenu>
			{createNewBookmarkOpen ? <EditBookmarkDialog open onOpenChange={setCreateNewBookmarkOpen} /> : null}
		</div>
	);
}
