import { ICONS } from "@salesdesk/salesdesk-ui";
import { USER_IDS } from "../user_constants";
import { mUserDef } from "../user_def";

export class mSalesDeskUserDef extends mUserDef {
	static CLASS_NAME = "mSalesDeskUserDef";
	static NAME = "SalesDesk User";
	static PLURAL_NAME = "SalesDesk Users";
	static ICON = ICONS.user;

	static ROOT_ID = 0;

	static ID = USER_IDS.SALESDESK_USER;

	constructor(ownerId) {
		super(mSalesDeskUserDef.ID, ownerId);
	}
}
