import { PropsWithChildren, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connectWebSocket, disconnectWebSocket } from "../../../store";
import { getWebSocket } from "../utils";

// TODO: HMR reloading of this file can cause 'getWebSocket' to instantiate another 'SDWebSocket' which means api
//   subscriptions could be subscribed to the previous instance which is no longer connected
export const ConnectWebSocket = ({ children }: PropsWithChildren) => {
	const dispatch = useDispatch();
	const webSocket = getWebSocket();

	useEffect(() => {
		webSocket.open().then(() => dispatch(connectWebSocket(webSocket)));

		return () => {
			dispatch(disconnectWebSocket());
			webSocket.close();
		};
	}, [dispatch, webSocket]);

	return children;
};
