import { ICONS } from "@salesdesk/salesdesk-ui";
import { PopoverMenu } from "../../../../menu";
import { Button } from "@salesdesk/daisy-ui";
import { useState } from "react";
import { BulkDeleteModal, getCanDeleteRecordParameters } from "./BulkDeleteModal";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useBulkEditContext } from "../hooks/useBulkEditContext";
import { usePrincipalCanFilteredRecords } from "../hooks/usePrincipalCanFilteredRecords";

type AdditionalBulkActionsProps = {
	sdObject?: SDObject;
};

export function AdditionalBulkActions({ sdObject }: AdditionalBulkActionsProps) {
	const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
	const { selectedRecords } = useBulkEditContext();
	const [editableRecords] = usePrincipalCanFilteredRecords(selectedRecords, getCanDeleteRecordParameters);

	return (
		<>
			<PopoverMenu
				menuContents={[
					{
						icon: ICONS.trash,
						text: "Delete",
						variant: "destructive",
						onClick: editableRecords.length
							? () => {
									setShowBulkDeleteModal(true);
								}
							: undefined,
						type: "button",
						tooltip:
							selectedRecords.length && !editableRecords.length
								? "You don't have permission to delete selected records"
								: undefined,
					},
				]}
				placement="top-start"
			>
				<Button startIcon={ICONS.verticalDots} variant="text_inverted" size="sm" />
			</PopoverMenu>
			{showBulkDeleteModal && sdObject ? (
				<BulkDeleteModal sdObject={sdObject} onOpenChange={setShowBulkDeleteModal} />
			) : null}
		</>
	);
}
