import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	BatchChannelMemberships,
	Channel,
	ChannelMembership,
	ChannelMessage,
	ChimeSDKMessagingClient,
} from "@aws-sdk/client-chime-sdk-messaging";
import { Message } from "amazon-chime-sdk-js";

import { JSONContent } from "@tiptap/core";

import {
	ChannelMessageMetadata,
	ChannelMetadata,
	ChimeApi,
	ChimeCredentials,
	ChimeMessagingRegion,
	getChimeUserId,
} from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../auth";
import { useAppDispatch } from "../../../../../store/store";

import { addNewChannelFromArn, addNewMessage, loadInitialMessages } from "../../../store/thunks";
import {
	addChannelMembers,
	removeChannel,
	removeChannelMember,
	setChannels,
	updateChannelInfo,
	updateMessage,
} from "../../../store";
import { SerializableChannelDetails } from "../../../types";
import {
	convertChannelMembershipForUserSummaryToChannelDetails,
	convertJSONMessagePayloadToChannelMessage,
	serializeChannelDetails,
	serializeChannelMessage,
	sortChannelsByLastMessageTimestamp,
} from "../../../utils";
import { useChimeMessagingSubscriber } from "../hooks/useChimeMessagingSubscriber";
import { ChimeMessagingContext } from "../hooks/useChimeMessagingContext";
import { useToast } from "../../../../Toasts";

type ChimeMessagingProviderProps = {
	credentialsProvider: () => Promise<ChimeCredentials>;
	isReadOnly?: boolean;
};

export function ChimeMessagingProvider({
	credentialsProvider,
	isReadOnly,
	children,
}: PropsWithChildren<ChimeMessagingProviderProps>) {
	const dispatch = useAppDispatch();

	const principal = useWebPrincipal();
	const toast = useToast();

	const [initialisationFailed, setInitialisationFailed] = useState(false);

	const [appInstanceUserArn, setAppInstanceUserArn] = useState<string>();
	const chimeSDKMessagingClient = useMemo(
		() =>
			new ChimeSDKMessagingClient({
				region: ChimeMessagingRegion,
				credentials: async () => {
					const data = await credentialsProvider();
					setAppInstanceUserArn(data.appInstanceUserArn);
					return {
						accessKeyId: data.credentials.accessKeyId,
						secretAccessKey: data.credentials.secretAccessKey,
						sessionToken: data.credentials.sessionToken,
						expiration: new Date(data.credentials.expiration),
					};
				},
				maxAttempts: 1, // disabling Chime's retry logic in favor of our own
			}),
		[credentialsProvider]
	);

	const onInitialisationError = useCallback(() => {
		toast.trigger("error", "Failed to connect to messaging service.\nPlease refresh the page and try again.");
		setInitialisationFailed(true);
	}, [toast]);

	const chimeMessageSubscriber = useChimeMessagingSubscriber(
		appInstanceUserArn,
		chimeSDKMessagingClient,
		onInitialisationError
	);

	useEffect(() => {
		if (!chimeMessageSubscriber) return;
		chimeMessageSubscriber.connect();
		return () => chimeMessageSubscriber.close();
	}, [chimeMessageSubscriber]);

	const chimeApi = useMemo(() => {
		if (!appInstanceUserArn || !chimeMessageSubscriber) return undefined;
		const appInstanceArn = appInstanceUserArn.substring(0, appInstanceUserArn.indexOf("/user/"));
		return new ChimeApi(appInstanceArn, appInstanceUserArn, chimeSDKMessagingClient, chimeMessageSubscriber);
	}, [appInstanceUserArn, chimeMessageSubscriber, chimeSDKMessagingClient]);

	// TODO: Implement logic to add message to cache/redux immediately after sending with failure handling if a message fails to send
	const sendMessage = useCallback(
		(channelArn: string, message?: JSONContent, metadata?: ChannelMessageMetadata) => {
			if (!chimeApi) return;

			chimeApi
				.sendChannelMessage({
					ChannelArn: channelArn,
					Content: message ?? null,
					MetaData: metadata,
				})
				.catch((error) => {
					console.error("Failed to send message", error);
					toast.trigger("error", "Failed to send message.");
				});
		},
		[chimeApi, toast]
	);

	const incomingMessageSubscribers = useRef<((message: ChannelMessage) => void)[]>([]);

	const subscribeToIncomingMessages = useCallback((onMessage: (message: ChannelMessage) => void) => {
		incomingMessageSubscribers.current.push(onMessage);
	}, []);

	const unsubscribeFromIncomingMessages = useCallback((onMessage: (message: ChannelMessage) => void) => {
		incomingMessageSubscribers.current = incomingMessageSubscribers.current.filter(
			(subscriber) => subscriber !== onMessage
		);
	}, []);

	useEffect(() => {
		if (!chimeApi) {
			return;
		}

		const onMessage = (message: Message) => {
			const payload = JSON.parse(message.payload);

			switch (message.type) {
				case "CREATE_CHANNEL_MESSAGE": {
					const channelMessage = convertJSONMessagePayloadToChannelMessage(payload);
					incomingMessageSubscribers.current.forEach((subscriber) => subscriber(channelMessage));
					dispatch(addNewMessage({ newMessage: channelMessage }));
					break;
				}
				case "REDACT_CHANNEL_MESSAGE": {
					const channelMessage = convertJSONMessagePayloadToChannelMessage(payload);
					const { ChannelArn } = channelMessage;

					if (!ChannelArn) {
						return;
					}

					dispatch(updateMessage({ channelArn: ChannelArn, updatedMessage: serializeChannelMessage(channelMessage) }));
					break;
				}
				case "CREATE_CHANNEL_MEMBERSHIP": {
					const newChannelMembership = payload as ChannelMembership;
					const { Member, ChannelArn } = newChannelMembership;

					if (!ChannelArn) {
						return;
					}

					const newUserId = getChimeUserId(Member?.Arn);

					if (newUserId === principal.UserRecordId && !isReadOnly) {
						dispatch(addNewChannelFromArn({ channelArn: ChannelArn, chime: chimeApi }));
					} else {
						dispatch(addChannelMembers({ channelArn: ChannelArn, members: { Member } }));
					}

					break;
				}
				case "BATCH_CREATE_CHANNEL_MEMBERSHIP": {
					const newChannelMemberships = payload as BatchChannelMemberships;
					const { Members, ChannelArn } = newChannelMemberships;

					if (!ChannelArn || !Members) {
						return;
					}

					const includesCurrentUser = Members.some((member) => getChimeUserId(member.Arn) === principal.UserRecordId);

					// If current user is part of the batch we just load the new channel they've been added to from its ARN
					if (includesCurrentUser && !isReadOnly) {
						dispatch(addNewChannelFromArn({ channelArn: ChannelArn, chime: chimeApi }));
					} else {
						const channelMemberships = Members.map((member) => ({ Member: member }));
						dispatch(addChannelMembers({ channelArn: ChannelArn, members: channelMemberships }));
					}

					break;
				}
				case "DELETE_CHANNEL_MEMBERSHIP": {
					const deletedChannelMembership = payload as ChannelMembership;
					const { Member, ChannelArn } = deletedChannelMembership;

					if (!ChannelArn || Member?.Arn == null) {
						return;
					}

					const deletedUserId = getChimeUserId(Member.Arn);

					if (deletedUserId === principal.UserRecordId) {
						dispatch(removeChannel({ channelArn: ChannelArn }));
					} else {
						dispatch(removeChannelMember({ channelArn: ChannelArn, memberArn: Member.Arn }));
					}

					break;
				}
				case "DELETE_CHANNEL": {
					const deletedChannel = payload as Channel;
					const channelArn = deletedChannel.ChannelArn;

					if (!channelArn) {
						return;
					}

					dispatch(removeChannel({ channelArn }));
					break;
				}
				case "UPDATE_CHANNEL": {
					const updatedChannel = payload as Channel;
					const channelArn = updatedChannel.ChannelArn;

					if (!channelArn) {
						return;
					}

					dispatch(
						updateChannelInfo({
							channelArn,
							channelName: updatedChannel.Name,
							mode: updatedChannel.Mode,
							metadata: updatedChannel.Metadata ? (JSON.parse(updatedChannel.Metadata) as ChannelMetadata) : undefined,
						})
					);
				}
			}
		};

		chimeApi.subscribeToMessages(onMessage);

		return () => {
			chimeApi?.unsubscribeFromMessages(onMessage);
		};
	}, [chimeApi, dispatch, isReadOnly, principal.UserRecordId]);

	useEffect(() => {
		if (!chimeApi || isReadOnly) {
			return;
		}

		chimeApi
			.listChannelsForCurrentUser()
			.then((result) => {
				const channelDetails: SerializableChannelDetails[] = [];

				result.forEach((channel) => {
					const details = convertChannelMembershipForUserSummaryToChannelDetails(channel);

					if (!details) {
						return;
					}

					channelDetails.push(serializeChannelDetails(details));
				});

				sortChannelsByLastMessageTimestamp(channelDetails);
				dispatch(setChannels(channelDetails));

				// Load initial messages for first 10 channels with the most recent messages
				channelDetails.slice(0, 10).forEach((channel) => {
					dispatch(loadInitialMessages({ channelArn: channel.arn, chime: chimeApi }));
				});
			})
			.catch((error) => {
				console.error("Failed to list channels for current user", error);
				onInitialisationError();
			});
	}, [chimeApi, dispatch, isReadOnly, onInitialisationError]);

	return (
		<ChimeMessagingContext.Provider
			value={{
				appInstanceArn: appInstanceUserArn,
				chime: chimeApi,
				sendMessage,
				subscribeToIncomingMessages,
				unsubscribeFromIncomingMessages,
				initialisationFailed,
			}}
		>
			{children}
		</ChimeMessagingContext.Provider>
	);
}
