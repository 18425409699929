import { useCallback } from "react";
import { RecordsSearchRequest, SearchHighlight } from "@salesdesk/salesdesk-schemas";
import { useLazyGetRecordsQuery, useLazySearchRecordsQuery } from "../../api/recordsApi";
import { EMPTY_HIGHLIGHT_MAP, EMPTY_SEARCH_RESULTS } from "../types";

export function useSearchRecordsFn() {
	const [searchRecords] = useLazySearchRecordsQuery();
	const [getRecords] = useLazyGetRecordsQuery();

	return useCallback(
		async (params: RecordsSearchRequest) => {
			const NO_RESULTS = {
				searchHits: undefined,
				searchResults: EMPTY_SEARCH_RESULTS,
				highlightsMap: EMPTY_HIGHLIGHT_MAP,
			};

			try {
				const { data: searchHits, isError: searchHitsError } = await searchRecords(params);

				if (!searchHits || searchHitsError) {
					return NO_RESULTS;
				}

				const recordIds: number[] = [];
				const highlightsMap: Record<number, SearchHighlight[]> = {};

				searchHits.hits.forEach((hit) => {
					const { source, highlight } = hit;

					const id = source.id;
					recordIds.push(id);

					if (highlight) {
						highlightsMap[id] = highlight;
					}
				});

				if (!recordIds.length) {
					return { searchHits, highlightsMap, searchResults: EMPTY_SEARCH_RESULTS };
				}

				const { data: records = EMPTY_SEARCH_RESULTS } = await getRecords(recordIds);

				return { searchHits, searchResults: records, highlightsMap };
			} catch (error) {
				// TODO: Better error handling
				console.error("error", error);
				return NO_RESULTS;
			}
		},
		[getRecords, searchRecords]
	);
}
