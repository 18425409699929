import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { WorkspaceSettings } from "./WorkspaceSettings";

export function WorkspaceSettingsPage() {
	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<WorkspaceSettings />
		</QueryParamProvider>
	);
}
