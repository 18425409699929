import { getSDRecordFieldValueByFieldName, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Auth } from "../../../../../../auth";
import { useMemo, useState } from "react";
import { useGetObjectById } from "../../../../../../hooks";
import { useGetLoginAuthorizationDetails } from "../../../../../records";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Icon } from "@salesdesk/daisy-ui";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";
import { mUserDef } from "@salesdesk/salesdesk-model";

export function GuestBadge() {
	const [userRecord] = useState<SDRecord>(() => Auth.getUser());
	const { sdObject: userObject } = useGetObjectById(userRecord._objectDefId);
	const { isAuthorizedToLogIn } = useGetLoginAuthorizationDetails(userObject, userRecord);

	const hasAlreadyLoggedIn = useMemo(
		() =>
			userObject &&
			userRecord &&
			getSDRecordFieldValueByFieldName(userObject, userRecord, mUserDef.LAST_ACTIVE_FIELD_NAME)?._value != null,
		[userObject, userRecord]
	);

	return (
		<>
			<div className="text-label-sm text-c_warning_focus flex items-center gap-1">
				<Icon icon={ICONS.globeWest} className="flex items-center" />
				<div>Guest</div>
			</div>
			{isAuthorizedToLogIn && hasAlreadyLoggedIn ? (
				<Popover placement="bottom-start">
					<PopoverTrigger>
						<Button size="sm" variant="text_inverted" startIcon={ICONS.horizontalDots} />
					</PopoverTrigger>
					<PopoverContent>
						<PopoverContainer>
							<div className="flex w-[350px] flex-col items-center gap-3 p-3">
								<div className="flex gap-1">
									<Icon icon={ICONS.info} className="text-c_text_secondary text-label-sm flex items-center" />
									<div>You are currently a guest on this call.</div>
								</div>
								<Button
									variant="outlined"
									size="sm"
									startIcon={ICONS.signIn}
									onClick={() => Auth.redirectToLoginPage()}
								>
									Login for full access
								</Button>
							</div>
						</PopoverContainer>
					</PopoverContent>
				</Popover>
			) : null}
		</>
	);
}
