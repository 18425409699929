import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Icon } from "@salesdesk/daisy-ui";
import { useNotificationRuleDialogContext } from "../../../../hooks/useNotificationRuleDialogContext";
import { SelectOption, StaticTypeaheadSelect } from "../../../../../../inputs";
import { useRecordActionOptions } from "../../../../hooks/record/useRecordActionOptions";
import { useCallback } from "react";
import { NotificationRuleType, NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS, SDObject } from "@salesdesk/salesdesk-schemas";
import { ANY, AnyActionOptions, AnyObject } from "../../../../types/Any";
import { useNotificationRuleObjectOptions } from "../../../../hooks/useNotificationRuleObjectOptions";

type Display = "object" | "action";

export function RecordActionValueSelector() {
	const {
		selectedObjectId,
		selectedSDObject,
		setSelectedObjectId,
		selectedAction,
		setSelectedField,
		setSelectedRecord,
		setSelectedAction,
		selectedWorkspaceId,
	} = useNotificationRuleDialogContext();
	const { actionOptions } = useRecordActionOptions(NotificationRuleType.User);
	const display: Display = selectedObjectId !== undefined ? "action" : "object";

	const onBackButtonClick = useCallback(() => {
		setSelectedObjectId(undefined);
		setSelectedAction(undefined);
		setSelectedRecord(undefined);
		setSelectedField(undefined);
	}, [setSelectedObjectId, setSelectedAction, setSelectedField, setSelectedRecord]);

	const onObjectChange = useCallback(
		(value: SelectOption | undefined) => {
			if (!value) setSelectedObjectId(undefined);
			else if (value.id === ANY) setSelectedObjectId(null);
			else setSelectedObjectId(Number(value.id));
		},
		[setSelectedObjectId]
	);

	const objectsFilter = useCallback(
		(object: SDObject) => !selectedWorkspaceId || NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS.includes(object._id),
		[selectedWorkspaceId]
	);
	const objectOptions = useNotificationRuleObjectOptions(objectsFilter);

	return (
		<div className="flex h-full w-full flex-col justify-stretch">
			<div className="text-c_text_primary text-label-sm">Action Interactions</div>
			<div className="text-c_text_primary text-label-sm mx-4 flex grow items-center gap-2.5">
				{display === "action" && selectedObjectId !== undefined ? (
					<>
						<Button size="sm" variant="text" startIcon={ICONS.caretLeft} onClick={onBackButtonClick} />
						<Icon icon={selectedSDObject?._icon} size="sm" />
						<div className="truncate">{selectedSDObject?._displayName ?? "Any object"}</div>
					</>
				) : (
					<span className="text-c_text_secondary text-body-sm ml-auto">Select record type</span>
				)}
			</div>
			<div className="grow overflow-hidden">
				{display === "object" ? (
					<StaticTypeaheadSelect
						key="object"
						value={undefined}
						selectOptions={objectOptions}
						onChange={onObjectChange}
						multiple={false}
						hideSearchbar={true}
						placeholderOption={AnyObject}
					/>
				) : (
					<StaticTypeaheadSelect
						key="action"
						value={selectedAction}
						selectOptions={actionOptions}
						onChange={setSelectedAction}
						multiple={false}
						placeholderOption={AnyActionOptions.user}
					/>
				)}
			</div>
		</div>
	);
}
