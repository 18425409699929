import { asConst } from "json-schema-to-ts";
import { notificationRuleConfig } from "./config";
import { userIdSchema } from "../../components";
import { fieldIdSchema, objectIdSchema } from "../../object";
import { recordIdSchema } from "../../records";
import { notificationChannelSchema } from "./notificationChannelSchema";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { notificationRuleTypeSchema } from "./notificationRuleTypeSchema";

export const notificationRuleCreateRequestSchema = asConst({
	title: "Notification Rule Create Request",
	description: "A request to create a Notification Rule for a record event",
	type: "object",
	required: ["eventType", "ruleType", "channels", "enabled"],
	additionalProperties: false,
	properties: {
		eventType: {
			oneOf: [
				{
					title: "Event Type",
					description: "Only send notifications for this event type",
					type: "string",
					enum: notificationRuleConfig.map((config) => config.eventType),
				},
				{ type: "null" },
			],
		},
		ruleType: notificationRuleTypeSchema,
		objectId: {
			...objectIdSchema,
			description: "Only send notifications for this object",
			nullable: true,
		},
		workspaceId: {
			...recordIdSchema,
			description: "Only send notifications for this workspace",
			nullable: true,
		},
		recordId: {
			...recordIdSchema,
			description: "Only send notifications for this record",
			nullable: true,
		},
		fieldId: {
			...fieldIdSchema,
			description: `Only send notifications ${SdEventType.RECORD_UPDATED} events when this field has been updated.  objectId or recordId is required if this property is populated`,
			nullable: true,
		},
		userIds: {
			title: "UserIds",
			description: "Only send notifications if the event was created by any of these user Ids",
			type: "array",
			items: userIdSchema,
			minItems: 0,
			maxItems: 50,
		},
		channels: {
			title: "Channels",
			description: "The channels the notification should be sent on.  InApp notifications are sent by default.",
			type: "array",
			nullable: false,
			items: notificationChannelSchema,
			minItems: 0,
			maxItems: 3,
		},
		enabled: {
			title: "Enabled",
			description: "Only send the notification if this property is true",
			type: "boolean",
			nullable: false,
		},
	},

	dependencies: {
		eventType: {
			oneOf: [
				// EventType === null
				{
					properties: {
						eventType: { type: "null" },
					},
					required: ["objectId"],
					allOf: [{ not: { required: ["fieldId"] } }],
				},
				// Based on recordNotificationRuleConfig
				...notificationRuleConfig.map((config) => {
					const one: any = {
						properties: {
							eventType: {
								enum: [config.eventType],
							},
						},
					};

					if (config.allowedRuleTypes != null && config.allowedRuleTypes?.length > 0) {
						one.properties.ruleType = {
							enum: [...config.allowedRuleTypes],
						};
					}

					if (config.userIdsNotRequired) one.allOf = [...(one.allOf ?? []), { not: { required: ["userIds"] } }];
					else one.required = [...(one.required ?? []), "userIds"]; // but can be empty

					if (config.workspaceIdNotRequired) one.allOf = [...(one.allOf ?? []), { not: { required: ["workspaceId"] } }];

					if (config.allowedObjectIds != null && config.allowedObjectIds?.length > 0) {
						one.properties.objectId = {
							oneOf: [
								{
									enum: [...config.allowedObjectIds],
								},
								{
									type: "null",
								},
							],
						};
					}

					if (config.objectIdNotRequired) one.allOf = [...(one.allOf ?? []), { not: { required: ["objectId"] } }];
					else one.required = [...(one.required ?? []), "objectId"]; // but can be null

					if (config.recordIdNotRequired) one.allOf = [...(one.allOf ?? []), { not: { required: ["recordId"] } }];
					else one.required = [...(one.required ?? []), "recordId"]; // but can be null

					if (config.fieldIdRequired)
						one.required = [...(one.required ?? []), "fieldId"]; // but can be null
					else one.allOf = [...(one.allOf ?? []), { not: { required: ["fieldId"] } }];

					return one;
				}),
			],
		},
	},
	// TODO:  Should be able to remove the dependencies and use a oneOf at the highest level to get intellisense but
	//  I couldn't get it to work due to the enum restrictions on objectId and recordId
	// oneOf: [
	// 	...recordNotificationRuleConfig.map((config) => {
	// 		const objectIdEnum =
	// 			config.allowedObjectIds != null && config.allowedObjectIds.length > 0 ? { enum: config.allowedObjectIds } : {};
	//
	// 		const recordIdEnum = config.ignoreRecordId ? { enum: [nullSchema] } : { };
	//
	// 		return {
	// 			...recordNotificationRuleCreateRequestBaseSchema,
	// 			title: `Notification Rule Create Request for event: ${config.eventType}`,
	// 			properties: {
	// 				...recordNotificationRuleCreateRequestBaseSchema.properties,
	// 				eventType: {
	// 					...recordNotificationRuleCreateRequestBaseSchema.properties.eventType,
	// 					enum: [config.eventType],
	// 				},
	// 				objectId: {
	// 					...recordNotificationRuleCreateRequestBaseSchema.properties.objectId,
	// 					...objectIdEnum,
	// 				},
	// 				recordId: {
	// 					...recordNotificationRuleCreateRequestBaseSchema.properties.recordId,
	// 					...recordIdEnum,
	// 				},
	// 			},
	// 		};
	// 	}),
	// ],
});
