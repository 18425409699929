import { FilePreviewProps } from "../../../types";
import { FileIcon } from "../../FileIcon/FileIcon";
import clsx from "clsx";
import { Tooltip } from "@salesdesk/daisy-ui";

interface CompactFilePreviewProps extends FilePreviewProps {
	isPreviewOpen?: boolean;
	fileDisplayName?: string;
	variant?: "xs" | "sm";
}

export function CompactFilePreview({
	file,
	fileDisplayName,
	onOpenPreviewClick,
	isPreviewOpen,
	variant = "sm",
}: CompactFilePreviewProps) {
	return (
		<div className={clsx("text-label flex w-full items-center overflow-hidden", variant === "xs" ? "gap-2" : "gap-4")}>
			<Tooltip text={onOpenPreviewClick && !isPreviewOpen ? "Open preview" : undefined}>
				<button
					className={clsx(
						"rounded-xs px-0.5 py-0.5",
						onOpenPreviewClick && "hover:bg-c_bg_05 focus-visible:ring-c_action_focus cursor-pointer focus-visible:ring"
					)}
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();

						if (onOpenPreviewClick) {
							onOpenPreviewClick();
						}
					}}
				>
					<FileIcon file={file} size="sm" />
				</button>
			</Tooltip>
			<div className={clsx("truncate", variant === "xs" ? "text-label-xs" : "text-body-sm")}>
				{fileDisplayName ?? file.name}
			</div>
		</div>
	);
}
