import { mSystemObjectDef } from "../system_object_def";

export class mProductDef extends mSystemObjectDef {
	static CLASS_NAME = "mProductDef";
	static NAME = "Product";
	static PLURAL_NAME = "Products";
	static ICON = "ph-shopping-bag";

	static ID = 6;

	static PRICE_FIELD_NAME = "price";
	static STATUS_FIELD_NAME = "status";
	static IMAGE_FIELD_NAME = "image";
	static VIDEO_FIELD_NAME = "video";
	static PDF_FIELD_NAME = "pdf";
	static CODE_FIELD_NAME = "code";

	constructor(ownerId) {
		super(mProductDef.ID, ownerId);
	}
}
