import { useMemo, useState } from "react";
import { ROW_IS_LOADING_PLACEHOLDER, Table } from "../../../Table";
import { SkeletonTransition } from "../../../../components/Skeleton/SkeletonTransition";
import { DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID, generateColumns, generateRows, SD_API_KEY_COLUMN_ID } from "../utils";
import { useGetApiKeysQuery } from "../api/apiKeysApi";
import { isSdApiKey, RecordTableRow } from "../types";
import { Button } from "@salesdesk/daisy-ui";
import { CreateApiKeyDialog } from "./CreateApiKeyDialog";
import { EditApiKeyDialog } from "./EditApiKeyDialog";
import { DeleteApiKeyDialog } from "./DeleteApiKeyDialog";
import { SDApiKey } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

export function ApiKeys() {
	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const [apiKeyToEdit, setApiKeyToEdit] = useState<SDApiKey>();
	const [apiKeyToDelete, setApiKeyToDelete] = useState<SDApiKey>();

	const { data: apiKeys, isLoading } = useGetApiKeysQuery();

	const columns = useMemo(() => generateColumns(), []);
	const rows = useMemo(() => {
		if (apiKeys == null) return [];
		return generateRows(apiKeys);
	}, [apiKeys]);

	const skeletonPlaceholderRows = useMemo(
		() => Array.from({ length: 6 }, () => ({ [ROW_IS_LOADING_PLACEHOLDER]: true })),
		[]
	);

	const controlColumn = useMemo(
		() => ({
			width: 64,
			render: (row: RecordTableRow) => {
				const sdApiKey = row[SD_API_KEY_COLUMN_ID];
				if (!isSdApiKey(sdApiKey)) {
					return null;
				}
				return (
					<div className="ml-auto flex gap-1">
						<Button
							size="sm"
							startIcon={ICONS.simplePencil}
							variant="text"
							onClick={() => {
								setApiKeyToEdit(sdApiKey);
							}}
						/>
						<Button
							size="sm"
							startIcon={ICONS.trash}
							variant="text"
							onClick={() => {
								setApiKeyToDelete(sdApiKey);
							}}
						/>
					</div>
				);
			},
		}),
		[]
	);

	return (
		<>
			<div className="flex h-full flex-col">
				<div className="text-label py-2">API keys</div>
				<div className="flex w-full items-center justify-between gap-2 py-4">
					<div className="text-body-sm text-c_text_secondary">Manage all your API keys</div>
					<Button startIcon={ICONS.plus} variant="primary" size="sm" onClick={() => setCreateDialogOpen(true)}>
						New API key
					</Button>
				</div>
				<SkeletonTransition className="flex w-full flex-col overflow-hidden">
					<Table
						rows={isLoading ? skeletonPlaceholderRows : rows}
						columns={columns}
						bottomOffset={200}
						controlColumn={controlColumn}
						containerRightPadding={0}
						defaultColumnWidthByColumnId={DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID}
					/>
					{!isLoading && !rows.length ? (
						<span className="text-body text-c_text_secondary my-8 text-center">There are no API keys.</span>
					) : null}
				</SkeletonTransition>
			</div>
			{createDialogOpen ? <CreateApiKeyDialog onClose={() => setCreateDialogOpen(false)} /> : null}
			{apiKeyToEdit ? <EditApiKeyDialog apiKey={apiKeyToEdit} onClose={() => setApiKeyToEdit(undefined)} /> : null}
			{apiKeyToDelete ? (
				<DeleteApiKeyDialog apiKey={apiKeyToDelete} onClose={() => setApiKeyToDelete(undefined)} />
			) : null}
		</>
	);
}
