import { RefObject, useMemo, useRef } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { RecordFieldDisplayType } from "../../../utils/defaultDisplayFields";
import { ROW_IS_LOADING_PLACEHOLDER, Table } from "../../../../Table";
import { useRecordTableSelection } from "../../../hooks";
import { generateRecordTableColumns, generateRecordTableRows } from "../utils";
import { RECORD_FIELD_ID } from "../types";
import { Spinner } from "@salesdesk/daisy-ui";
import { MenuItem } from "../../../../menu";
import { RecordTableRow } from "../../../../settings/access/users/types";
import { RecordModalRowControls } from "./RecordModalRowControls";

interface RecordModalTableProps {
	records: SDRecord[];
	sdObject: SDObject;
	selectedRecords: SDRecord[];
	setSelectedRecords: (records: SDRecord[]) => void;
	displayType?: RecordFieldDisplayType;
	singleSelect?: boolean;
	onBottomReached?: () => void;
	isLoadingNextPage?: boolean;
	isLoadingRecords?: boolean;
	numOfPlaceholderRows?: number;
	outsideScrollContainerRef?: RefObject<HTMLDivElement>;
	getMenuItems?: (sdRecord: SDRecord) => MenuItem[];
	containerRightPadding?: number;
}

export function RecordModalTable({
	records,
	sdObject,
	displayType = "ASSOCIATION",
	selectedRecords,
	setSelectedRecords,
	onBottomReached,
	isLoadingNextPage,
	isLoadingRecords,
	singleSelect,
	numOfPlaceholderRows = 4,
	outsideScrollContainerRef,
	getMenuItems,
	containerRightPadding,
}: RecordModalTableProps) {
	const columns = useMemo(() => generateRecordTableColumns(sdObject, displayType), [sdObject, displayType]);
	const rows = useMemo(() => generateRecordTableRows(records, sdObject), [records, sdObject]);

	const { rowSelectionDetails, getRowId } = useRecordTableSelection({
		selectedRecords,
		onSelectedRecordsChange: setSelectedRecords,
		rows,
		sdRecordKeyInRow: RECORD_FIELD_ID,
		singleSelect,
		inSelectionMode: true,
	});

	const skeletonPlaceholderRows = useMemo(
		() => Array.from({ length: numOfPlaceholderRows }, () => ({ [ROW_IS_LOADING_PLACEHOLDER]: true })),
		[numOfPlaceholderRows]
	);

	const tableRef = useRef<HTMLDivElement>(null);

	const controlColumn = useMemo(() => {
		if (!getMenuItems) {
			return undefined;
		}
		return {
			width: 64,
			render: (row: RecordTableRow) => {
				const sdRecord = row[RECORD_FIELD_ID];
				if (row[ROW_IS_LOADING_PLACEHOLDER] || !sdRecord || typeof sdRecord !== "object" || !("_id" in sdRecord)) {
					return null;
				}
				return (
					<RecordModalRowControls
						tableRef={tableRef}
						scrollContainerRef={outsideScrollContainerRef}
						menuItems={getMenuItems(sdRecord)}
					/>
				);
			},
		};
	}, [getMenuItems, outsideScrollContainerRef]);

	return (
		<div className="overflow-hidden">
			<Table
				rows={isLoadingRecords ? skeletonPlaceholderRows : rows}
				hideHeader
				rowSelection={rowSelectionDetails}
				columns={columns}
				onBottomReached={onBottomReached}
				getRowId={getRowId}
				outsideScrollContainerRef={outsideScrollContainerRef}
				outsideRef={tableRef}
				controlColumn={controlColumn}
				containerRightPadding={containerRightPadding}
				tableFooter={
					isLoadingNextPage ? (
						<div className="my-6 flex w-full items-center justify-center">
							<Spinner size="md" />
						</div>
					) : undefined
				}
			/>
		</div>
	);
}
