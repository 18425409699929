import { useCallback, useState } from "react";

export function useVideoPanel() {
	const [panelOpen, setPanelOpen] = useState(false);
	const [currentPanelTab, setCurrentPanelTab] = useState(0);

	const updatePanelTab = useCallback((newTab: number) => {
		setPanelOpen(true);
		setCurrentPanelTab(newTab);
	}, []);

	return {
		panelOpen,
		setPanelOpen,
		currentPanelTab,
		updatePanelTab,
	};
}
