import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { SkeletonCard } from "../../../../../components/Skeleton/SkeletonCard";

export function NotificationRulesLoading() {
	return (
		<div className="grid-cols-cards mt-4 grid gap-x-6 gap-y-4">
			{[...Array(6)].map((_, index) => (
				<SkeletonCard key={index} className="flex flex-col gap-4">
					<Skeleton className="h-6 w-28" />
					<Skeleton className="h-6 w-11/12" />
					<Skeleton className="-mt-2 h-6 w-2/3" />
					<Skeleton className="ml-auto h-4 w-20" />
				</SkeletonCard>
			))}
		</div>
	);
}
