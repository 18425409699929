import {
	BASE_COLORS,
	BaseColorKey,
	ColorKey,
	THEMABLE_COLOR_VARIABLES,
	ThemableColorKey,
} from "@salesdesk/salesdesk-ui";
import { fromString, fromHsla } from "css-color-converter";

// CSS Variables are just the R, G, B values of the color to enable us to apply opacity through tailwind
export function getHexOrCssVariableForColorKey(colorKey: ColorKey) {
	if (colorKey in THEMABLE_COLOR_VARIABLES) {
		const themableColorVariable = THEMABLE_COLOR_VARIABLES[colorKey as ThemableColorKey];
		return `rgb(var(${themableColorVariable}))`;
	}

	return BASE_COLORS[colorKey as BaseColorKey];
}

export function resolveColorKeyToHex(colorKey: ColorKey): string {
	const colorValue = getHexOrCssVariableForColorKey(colorKey);

	// Return if it's not a CSS variable
	if (!colorValue?.startsWith("rgb(var")) {
		return colorValue;
	}

	const variableName = colorValue.slice(8, -2).trim();
	const variableValue = getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();

	return fromString(`rgb(${variableValue})`)?.toHexString() ?? fromString(variableValue)?.toHexString() ?? "";
}

export function getRgbaFromCssColor(cssColor: string) {
	const color = fromString(cssColor);

	if (!color) {
		return null;
	}

	const [r, g, b, a] = color.toRgbaArray();
	return { r, g, b, a };
}

/*
 * Decreases the lightness of a given color by 10% by converting the color
 * into HSV and adjusting the 'value' number. If the color has a lightness/value
 * of less than 10%, then the color is lightened by 10% instead.
 *
 * The updated color is returned as a hex string
 */
export function darkenOrLightenCssColor(cssColor: string) {
	const color = fromString(cssColor);

	if (!color) {
		return cssColor;
	}

	const [hslH, hslS, hslL, alpha] = color.toHslaArray();

	const [hsvH, hsvS, hsvV] = hslToHsv(hslH, hslS, hslL);

	const newHsvV = Math.min(Math.max(hsvV >= 10 ? hsvV - 10 : hsvV + 10, 0), 100);

	const updatedColor = fromHsla([...hsvToHsl(hsvH, hsvS, newHsvV), alpha]);
	return updatedColor.toHexString();
}

// SOURCE: https://jonneal.dev/convert-colors/hsl-hsv.js.html
export function hsvToHsl(hsvH: number, hsvS: number, hsvV: number) {
	const hslL = ((200 - hsvS) * hsvV) / 100;
	const [hslS, hslV] = [
		hslL === 0 || hslL === 200 ? 0 : ((hsvS * hsvV) / 100 / (hslL <= 100 ? hslL : 200 - hslL)) * 100,
		(hslL * 5) / 10,
	];
	return [hsvH, hslS, hslV];
}

// SOURCE: https://jonneal.dev/convert-colors/hsl-hsv.js.html
export function hslToHsv(hslH: number, hslS: number, hslL: number) {
	const hsv1 = (hslS * (hslL < 50 ? hslL : 100 - hslL)) / 100;
	const hsvS = hsv1 === 0 ? 0 : ((2 * hsv1) / (hslL + hsv1)) * 100;
	const hsvV = hslL + hsv1;
	return [hslH, hsvS, hsvV];
}

/*
 Calculates whether text should be dark or light based on background colour by
 calculating the luminance of the given CSS color. Luminance threshold is based
 on heuristic testing, could be tweaked in the future.

 Source: https://stackoverflow.com/a/3943023
*/
export function preferDarkTextOnBackgroundColor(cssColor: string) {
	const rgba = getRgbaFromCssColor(cssColor);

	if (!rgba) {
		return null;
	}

	const luminance = (rgba.r / 255) * 0.299 + (rgba.g / 255) * 0.587 + (rgba.b / 255) * 0.114;
	return luminance >= 0.56;
}
