import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../../utils";
import { mSingleOptionFieldDef } from "../single_option_field_def";

export class mPriorityFieldDef extends mSingleOptionFieldDef {
	static LOW = "Low";
	static MEDIUM = "Medium";
	static HIGH = "High";
	static CRITICAL = "Critical";

	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.SELECT.name];
	static override defaultComponentType: FieldComponentName = mPriorityFieldDef.supportedComponentTypes[0];

	static priorityIcons = {
		Low: "fa-long-arrow-down",
		Medium: "fa-circle-exclamation",
		High: "fa-triangle-exclamation",
		Critical: "fa-alarm-clock",
	};

	static priorityColors = {
		Low: "#037f4c",
		Medium: "#ffadad",
		High: "#e2445c",
		Critical: "#bb3354",
	};

	static priorityOrders = {
		Low: 0,
		Medium: 1,
		High: 2,
		Critical: 3,
	};

	constructor(id: string | number) {
		super(id);

		this._componentType = mPriorityFieldDef.defaultComponentType;

		super.optionValues = [
			{
				id: 0,
				icon: mPriorityFieldDef.priorityIcons.Low,
				name: mPriorityFieldDef.LOW,
				color: mPriorityFieldDef.priorityColors.Low,
			},
			{
				id: 1,
				icon: mPriorityFieldDef.priorityIcons.Medium,
				name: mPriorityFieldDef.MEDIUM,
				color: mPriorityFieldDef.priorityColors.Medium,
			},
			{
				id: 2,
				icon: mPriorityFieldDef.priorityIcons.High,
				name: mPriorityFieldDef.HIGH,
				color: mPriorityFieldDef.priorityColors.High,
			},
			{
				id: 3,
				icon: mPriorityFieldDef.priorityIcons.Critical,
				name: mPriorityFieldDef.CRITICAL,
				color: mPriorityFieldDef.priorityColors.Critical,
			},
		];

		this._icon = FIELD_TYPES.PRIORITY.icon;
		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.PRIORITY.name;
	}

	static getPriorityIcon(value: keyof typeof mPriorityFieldDef.priorityIcons) {
		return mPriorityFieldDef.priorityIcons[value];
	}

	static getPriorityColor(value: keyof typeof mPriorityFieldDef.priorityColors) {
		return mPriorityFieldDef.priorityColors[value];
	}

	static getOrder(value: keyof typeof mPriorityFieldDef.priorityOrders) {
		return mPriorityFieldDef.priorityOrders[value];
	}

	override get supportedComponentTypes() {
		return mPriorityFieldDef.supportedComponentTypes;
	}

	override supportsMaxLength() {
		return false;
	}

	override supportsFormatDescription() {
		return false;
	}
}
