export function isTextTruncated(element: HTMLElement) {
	// Ensure the element exists and is an HTMLElement
	if (!element || !(element instanceof HTMLElement)) {
		console.error("Invalid element provided");
		return false;
	}

	// Check if the scrollWidth is greater than the clientWidth
	// scrollWidth is the width of the content, including content not visible on the screen
	// clientWidth is the width of the element minus scrollbars, if any
	return element.scrollWidth > element.clientWidth;
}

export function truncateTextAndRemoveNewLines(rawText: string, length: number) {
	const text = rawText.replace(/\n/g, " ");
	if (text.length > length) {
		return text.slice(0, length) + "...";
	}
	return text;
}
