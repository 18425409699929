import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mPhoneNumberFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TEXT.name];
	static defaultComponentType: FieldComponentName = mPhoneNumberFieldDef.supportedComponentTypes[0];

	static FORMAT_RULE = `This phone number is not a valid format`;

	constructor(id: string | number) {
		super(id);
		this._componentType = mPhoneNumberFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.PHONE_NUMBER.icon;
		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.PHONE_NUMBER.name;
	}

	override get supportedComponentTypes() {
		return mPhoneNumberFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (value != null && typeof value !== "string") {
			return `Phone number "${value}" must be a string`;
		}

		if (!isEmpty(value) && !this.phoneNumberIsValid(value)) {
			return mPhoneNumberFieldDef.FORMAT_RULE;
		}

		return super.validate(value, isTemplate);
	}

	phoneNumberIsValid(phoneNumber: string) {
		return true;
	}
}
