import { useCallback, useRef, useState } from "react";

import { PickerInputProps } from "../../types";
import { CalendarClearButton } from "../DateTimePicker";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../components/Popover";
import { DateTimeFieldVariant } from "../../../fields";

export const POPOVER_WIDTH = 344;

export function PickerInput<TValue>({
	isPickerOpen,
	setIsPickerOpen,
	onChange,
	onBlur = () => undefined,
	mRef,
	textInput,
	picker,
	...props
}: PickerInputProps<TValue>) {
	const calendarClearDivRef = useRef<HTMLButtonElement>(null);
	const [onBlurEvent, setOnBlurEvent] = useState<React.FocusEvent | null>(null);

	const onTextInputFocus = useCallback(() => {
		setIsPickerOpen(true);
		setOnBlurEvent(null);
	}, [setIsPickerOpen]);

	const onTextInputBlur = useCallback(
		(event: React.FocusEvent) => {
			// !isPickerOpen so don't close picker when selecting a date when in-line editing
			if (document.activeElement !== calendarClearDivRef.current && !isPickerOpen) {
				onBlur(event);
			} else {
				// So we can use this event when dates are changed using the picker
				setOnBlurEvent(event);
			}
		},
		[isPickerOpen, onBlur]
	);

	const onClearButtonClicked = useCallback(() => {
		onChange(null);
		setIsPickerOpen(false);
	}, [setIsPickerOpen, onChange]);

	const onTextInputDateTimeChange = useCallback(
		(value: TValue | null) => {
			onChange(value);
		},
		[onChange]
	);

	const onPickerDateTimeChange = useCallback(
		(value: TValue | null) => {
			// Close date picker when a date is selected from the popover in single date mode
			if (props.variant === DateTimeFieldVariant.DATE && value && typeof value === "number") {
				setIsPickerOpen(false);
				// So don't lose tab index when using picker in create form
				calendarClearDivRef.current?.focus();
			}
			onChange(value);
		},
		[onChange, props.variant, setIsPickerOpen]
	);

	const onPickerOpenChange = useCallback(
		(open: boolean) => {
			// The isPickerOpen check allows the picker to open during in-line editing
			if (!open && isPickerOpen) {
				setIsPickerOpen(false);
			}

			// Calls onBlur when the picker is closed. Used in inline edit to save changes
			if (!open && onBlurEvent != null) {
				onBlur(onBlurEvent);

				// Don't lose tab index when using picker in create form
				calendarClearDivRef.current?.focus();
			}
		},
		[setIsPickerOpen, isPickerOpen, onBlur, onBlurEvent]
	);

	return (
		<Popover
			open={isPickerOpen}
			placement={"bottom-start"}
			onOpenChange={onPickerOpenChange}
			keepPopoverMounted={false}
		>
			<PopoverTrigger>
				<div
					className="w-full"
					tabIndex={-1}
					onClick={() => {
						setIsPickerOpen(true);
					}}
				>
					{textInput({
						ref: mRef,
						...props,
						onChange: onTextInputDateTimeChange,
						onFocus: onTextInputFocus,
						onBlur: onTextInputBlur,
						calenderIcon: (
							<CalendarClearButton
								ref={calendarClearDivRef}
								isPickerOpen={isPickerOpen}
								onClearButtonClicked={onClearButtonClicked}
								onTextInputBlur={onTextInputBlur}
								{...props}
							/>
						),
					})}
				</div>
			</PopoverTrigger>
			<PopoverContent initialFocus={-1} returnFocus={false}>
				<div className="shadow-popover bg-c_bg_01 rounded- flex rounded-sm px-6 py-8" style={{ width: POPOVER_WIDTH }}>
					{picker({
						...props,
						onChange: onPickerDateTimeChange,
					})}
				</div>
			</PopoverContent>
		</Popover>
	);
}
