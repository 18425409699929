import { forwardRef, useMemo } from "react";

import { EditFieldProps } from "../../../../types";
import { TimeRangeInput, TimeRangeInputProps } from "../../../../../inputs/components/TimeRangeInput";
import { DateTimeFieldVariant } from "../../DateTimeField";

interface EditTimeRangeFieldProps extends TimeRangeInputProps, Omit<EditFieldProps, "value" | "onChange"> {
	supportsPast?: boolean;
}

export const EditTimeRangeField = forwardRef<HTMLInputElement, EditTimeRangeFieldProps>(
	({ value = null, supportsPast, variant, ...timeRangeInputProps }, ref) => {
		const min = useMemo(() => {
			if (supportsPast !== false) {
				return undefined;
			}
			const now = new Date();
			return variant === DateTimeFieldVariant.DATE
				? Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
				: now.getTime();
		}, [supportsPast, variant]);

		return <TimeRangeInput value={value} {...timeRangeInputProps} variant={variant} min={min} ref={ref} />;
	}
);
