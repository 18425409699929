import { PropsWithChildren } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { AbilityAction, AbilitySubject, CanParameters, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { AllowIf } from "../../../auth";
import { ObjectBoardProvider } from "./ObjectBoardProvider";
import { NavigateNotFound } from "../../../routes";

interface ObjectBoardPageWrapperProps {
	sdObject?: SDObject;
	failedToLoad?: boolean;
	workspaceId?: SDRecord["_id"];
}

export function ObjectBoardPageWrapper({
	sdObject,
	workspaceId,
	failedToLoad,
	children,
}: PropsWithChildren<ObjectBoardPageWrapperProps>) {
	if (failedToLoad) {
		return <NavigateNotFound />;
	}

	// TODO: abilities to view record for specific object ids hasn't been implemented yet
	const viewObjectCanParameter: CanParameters = [AbilityAction.View, AbilitySubject.Record];

	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			{sdObject ? (
				<AllowIf can={viewObjectCanParameter}>
					<ObjectBoardProvider sdObject={sdObject} workspaceId={workspaceId}>
						{children}
					</ObjectBoardProvider>
				</AllowIf>
			) : null}
		</QueryParamProvider>
	);
}
