import { ICONS } from "@salesdesk/salesdesk-ui";

import { SelectOption } from "../../../../inputs";

export enum MessagingFilterId {
	Unread = "unread",
	Group = "group",
	DirectMessage = "dm",
	Workspace = "workspace",
}

export const CHANNEL_TYPE_FILTERS = [
	MessagingFilterId.Group,
	MessagingFilterId.DirectMessage,
	MessagingFilterId.Workspace,
];

interface MessagingSelectOption extends Omit<SelectOption, "id"> {
	id: MessagingFilterId;
}

export function getMessagingFilterOptions(workspaceName?: string, workspaceIcon?: string): MessagingSelectOption[] {
	return [
		{ id: MessagingFilterId.Unread, icon: ICONS.checks, name: "Unread" },
		{ id: MessagingFilterId.DirectMessage, icon: ICONS.user, name: "DM chats" },
		{ id: MessagingFilterId.Group, icon: ICONS.usersThree, name: "Group chats" },
		{
			id: MessagingFilterId.Workspace,
			icon: workspaceIcon ?? ICONS.circlesThreePlus,
			name: `${workspaceName ?? "Workspace"} chats`,
		},
	];
}
