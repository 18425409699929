import { useCallback } from "react";

import { useStableNavigate } from "./useStableNavigate";
import { getNotFoundPath } from "../utils";

export function useNavigateNotFound(navigateToWorkspaceError = false) {
	const navigate = useStableNavigate();

	return useCallback(() => {
		navigate(getNotFoundPath(navigateToWorkspaceError), { replace: true });
	}, [navigate, navigateToWorkspaceError]);
}
