export function applyTemplateParams(template: string, params: Record<string, unknown> | undefined): string {
	if (!params) {
		return template;
	}
	// Syntax is either {key} or {key|default value}
	const paramKeys = Object.keys(params);
	for (const key of paramKeys) {
		const paramValue = params[key];
		const regex = new RegExp(`{${key}(?:\\|([^}]+))?}`, "g");
		template = template.replace(regex, (_, defaultValue) => {
			return paramValue == null ? (defaultValue ?? `{${key}}`) : String(paramValue);
		});
	}
	// Look for all {key|default value} that were not replaced and apply the default value
	template = template.replace(/{[^}]+\|([^}]+)}/g, "$1");
	return template;
}
