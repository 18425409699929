import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";

import {
	FIELD_MULTIPLICITY,
	mBooleanFieldDef,
	mColorFieldDef,
	mCountriesBackingStore,
	mCountryFieldDef,
	mCurrencyFieldDef,
	mCurrencyValueFieldDef,
	mDateFieldDef,
	mDateTimeFieldDef,
	mDurationFieldDef,
	mEmailFieldDef,
	mFileFieldDef,
	mFloatFieldDef,
	mIconFieldDef,
	mImageFieldDef,
	mIntegerFieldDef,
	mOptionsBuilderFieldDef,
	mMultiOptionFieldDef,
	mObjectFieldDef,
	mObjectsFieldDef,
	mPasswordFieldDef,
	mPdfFieldDef,
	mPhoneNumberFieldDef,
	mPriorityFieldDef,
	mProbabilityFieldDef,
	mProgressFieldDef,
	mRatingFieldDef,
	mRichTextFieldDef,
	mSingleOptionFieldDef,
	mStringFieldDef,
	mUrlFieldDef,
	mVideoFieldDef,
} from "../../fields";

import { mSystemObjectDef } from "../system_object_def";
import { mMediaAssetDef } from "../asset";
import { mContactDef } from "../contact";

export class mTestDef extends mSystemObjectDef {
	static CLASS_NAME = "mTestDef";
	static NAME = "Test";
	static PLURAL_NAME = "Tests";
	static ICON = "fa-microscope";

	static ROOT_ID = 0;

	static ID = 100000;

	constructor(ownerId) {
		super(mTestDef.ID, ownerId);

		super.baseType = mTestDef.ID;
		super.supportsUserCreation = false;
		super.supportsUserView = false;
		super.className = mTestDef.CLASS_NAME;
		super.name = mTestDef.NAME;
		super.pluralName = mTestDef.PLURAL_NAME;
		super.displayName = "Test";

		this._editable = true;

		super.description = "test";
		super.icon = mTestDef.ICON;
		super.color = "#ff642e";

		super.commentsSupported = true;
		super.historySupported = true;
		super.activitySupported = true;

		super.data.name = "test";
		super.data.pluralName = "test";
		super.data.displayName = "test";

		this._supportsTableCardView = true;

		let paid = new mBooleanFieldDef("paid?");
		paid.displayName = "Paid?";
		paid.pluralName = "Paids";
		paid.componentType = FIELD_COMPONENT_TYPES.CHECKBOX.name;
		paid.defaultValue = mBooleanFieldDef.TRUE;
		paid.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;
		paid.editable = this._editable;

		let pets = new mMultiOptionFieldDef("pets");
		pets.displayName = "Pets";
		pets.pluralName = "Pets";
		pets.optionValues = [
			{
				icon: "fa-otter",
				name: "badger",
				color: "red",
			},
			{
				icon: "fa-dog",
				name: "dog",
				color: "orange",
			},
			{
				icon: "fa-monkey",
				name: "orangutan",
				color: "blue",
			},
		];
		pets.defaultValue = ["badger", "dog"];
		pets.editable = this._editable;
		pets.required = true;
		pets.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let boringPets = new mMultiOptionFieldDef("boringpets");
		boringPets.displayName = "Boring Pets";
		boringPets.pluralName = "Boring Pets";
		boringPets.optionValues = [
			{
				icon: "fa-otter",
				name: "badger",
			},
			{
				icon: "fa-dog",
				name: "dog",
			},
			{
				icon: "fa-monkey",
				name: "orangutan",
			},
		];
		boringPets.defaultValue = ["badger", "dog"];
		boringPets.editable = this._editable;
		boringPets.required = true;
		boringPets.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let color = new mColorFieldDef("color");
		color.displayName = "Color";
		color.pluralName = "Colors";
		color.editable = this._editable;
		color.required = true;
		color.defaultValue = "#03fc45";
		color.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let dateTime = new mDateTimeFieldDef("start date time");
		dateTime.displayName = "Start date time";
		dateTime.pluralName = "Start date times";
		dateTime.editable = this._editable;
		dateTime.required = true;
		dateTime.defaultValue = new Date().getTime();
		dateTime.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let simpleColor = new mColorFieldDef("simple color");
		simpleColor.displayName = "Simple Color";
		simpleColor.pluralName = "Colors";
		simpleColor.editable = this._editable;
		simpleColor.required = true;
		simpleColor.defaultValue = "#BD816E";
		simpleColor.componentType = FIELD_COMPONENT_TYPES.COLOR.name;
		simpleColor.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let date = new mDateFieldDef("start date");
		date.displayName = "Start date";
		date.pluralName = "Start dates";
		date.editable = this._editable;
		date.required = true;
		date.defaultValue = new Date().getTime();
		date.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let duration = new mDurationFieldDef("duration");
		duration.displayName = "Duration";
		duration.pluralName = "Durations";
		duration.editable = this._editable;
		duration.required = true;
		duration.defaultValue = 100000;
		duration.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let nameField = new mStringFieldDef("name");
		nameField.displayName = "Name";
		nameField.pluralName = "Names";
		nameField.editable = this._editable;
		nameField.required = true;
		nameField.defaultValue = "Jon";
		nameField.maxLength = 10;
		nameField.required = true;
		nameField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let emailField = new mEmailFieldDef("email");
		emailField.displayName = "Email";
		emailField.pluralName = "Emails";
		emailField.editable = this._editable;
		emailField.required = true;
		emailField.defaultValue = "jon.folland@nativ.tv";
		emailField.maxLength = 30;
		emailField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let urlField = new mUrlFieldDef("website");
		urlField.displayName = "Website";
		urlField.pluralName = "Websites";
		urlField.editable = this._editable;
		urlField.required = true;
		urlField.defaultValue = "http://nativ.tv";
		urlField.maxLength = 20;
		urlField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let numberField = new mIntegerFieldDef("count");
		numberField.displayName = "Count";
		numberField.pluralName = "Counts";
		numberField.editable = this._editable;
		numberField.required = true;
		numberField.defaultValue = 5;
		numberField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let floatField = new mFloatFieldDef("pi");
		floatField.displayName = "Pi";
		floatField.pluralName = "Pis";
		floatField.editable = this._editable;
		floatField.required = true;
		floatField.defaultValue = 3.1415;
		floatField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let probabilityField = new mProbabilityFieldDef("probability");
		probabilityField.displayName = "Probability";
		probabilityField.pluralName = "Probabilities";
		probabilityField.editable = this._editable;
		probabilityField.required = true;
		probabilityField.defaultValue = 20;
		probabilityField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let progressField = new mProgressFieldDef("progress");
		progressField.displayName = "Progress";
		progressField.pluralName = "Progresses";
		progressField.editable = this._editable;
		progressField.required = true;
		progressField.defaultValue = 25;
		progressField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let priceField = new mCurrencyValueFieldDef("price");
		priceField.displayName = "Price";
		priceField.pluralName = "Prices";
		priceField.editable = this._editable;
		priceField.required = true;
		priceField.defaultValue = 75;
		priceField.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let description = new mRichTextFieldDef("description");
		description.displayName = "Description";
		description.pluralName = "Descriptions";
		description.editable = this._editable;
		description.required = true;
		description.defaultValue = mRichTextFieldDef.encode("Jon is cool");
		description.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let password = new mPasswordFieldDef("password");
		password.displayName = "Password";
		password.pluralName = "Passwords";
		password.editable = this._editable;
		password.required = false;
		password.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let rating = new mRatingFieldDef("rating");
		rating.displayName = "Rating";
		rating.pluralName = "Ratings";
		rating.editable = this._editable;
		rating.required = true;
		rating.defaultValue = 1;
		rating.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let toggleSwitch = new mBooleanFieldDef("complete");
		toggleSwitch.displayName = "Completed";
		toggleSwitch.pluralName = "Completeds";
		toggleSwitch.editable = this._editable;
		toggleSwitch.required = true;
		toggleSwitch.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
		toggleSwitch.defaultValue = true;
		toggleSwitch.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let object = new mObjectFieldDef("contact");
		object.displayName = "Contact";
		object.pluralName = "Contact";
		object.editable = this._editable;
		object.objectDefId = mContactDef.ID;
		object.required = true;
		object.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let objects = new mObjectsFieldDef("contacts");
		objects.displayName = "Contacts";
		objects.pluralName = "Contacts";
		objects.editable = this._editable;
		objects.objectDefId = mContactDef.ID;
		objects.required = true;
		objects.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let country = new mCountryFieldDef("country");
		country.displayName = "Country";
		country.pluralName = "Countries";
		country.editable = this._editable;
		country.required = true;
		country.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let countries = new mMultiOptionFieldDef("countries", mCountriesBackingStore.TYPE);
		countries.displayName = "Countries";
		countries.pluralName = "Countries";
		countries.editable = this._editable;
		countries.required = true;
		countries.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;
		countries.componentType = FIELD_COMPONENT_TYPES.TYPEAHEAD_MULTI_OPTION.name;

		let currency = new mCurrencyFieldDef("currency");
		currency.displayName = "Currency";
		currency.pluralName = "Currencies";
		currency.editable = this._editable;
		currency.required = true;
		currency.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let cake = new mSingleOptionFieldDef("cake");
		cake.displayName = "Cake";
		cake.pluralName = "Cakes";
		cake.editable = this._editable;
		cake.required = true;
		cake.componentType = FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name;
		cake.optionValues = [
			{
				icon: "fa-cake-slice",
				name: "Sticky toffee pudding",
				color: "red",
			},
			{
				icon: "fa-pancakes",
				name: "Pancake",
				color: "orange",
			},
			{
				icon: "fa-cupcake",
				name: "Cupcake",
				color: "blue",
			},
		];
		cake.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let cakes = new mMultiOptionFieldDef("cakes");
		cakes.displayName = "Cakes";
		cakes.pluralName = "Cakes";
		cakes.editable = this._editable;
		cakes.required = true;
		cakes.componentType = FIELD_COMPONENT_TYPES.TYPEAHEAD_MULTI_OPTION.name;
		cakes.optionValues = [
			{
				icon: "fa-cake-slice",
				name: "Sticky toffee pudding",
				color: "red",
			},
			{
				icon: "fa-pancakes",
				name: "Pancake",
				color: "orange",
			},
			{
				icon: "fa-cupcake",
				name: "Cupcake",
				color: "blue",
			},
		];
		cakes.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let icon = new mIconFieldDef("icon");
		icon.displayName = "Icons";
		icon.pluralName = "Icons";
		icon.editable = this._editable;
		icon.required = true;
		icon.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let phone = new mPhoneNumberFieldDef("mobile");
		phone.displayName = "Mobile";
		phone.pluralName = "Mobiles";
		phone.editable = this._editable;
		phone.required = true;
		phone.defaultValue = "0797432426";
		phone.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let explanation = new mRichTextFieldDef("explanation");
		explanation.displayName = "Explanation";
		explanation.pluralName = "Explanations";
		explanation.editable = this._editable;
		explanation.required = true;
		explanation.componentType = FIELD_COMPONENT_TYPES.RICH_TEXT.name;
		explanation.defaultValue = "test";
		explanation.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let delivered = new mBooleanFieldDef("delivered");
		delivered.displayName = "Delivered";
		delivered.pluralName = "Delivered";
		delivered.editable = this._editable;
		delivered.required = true;
		delivered.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
		delivered.defaultValue = true;
		delivered.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let priority = new mPriorityFieldDef("importance");
		priority.displayName = "Importance";
		priority.pluralName = "Importances";
		priority.editable = this._editable;
		priority.required = true;
		priority.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		priority.defaultValue = mPriorityFieldDef.HIGH;
		priority.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let dessert = new mSingleOptionFieldDef("dessert");
		dessert.displayName = "Dessert";
		dessert.pluralName = "Desserts";
		dessert.editable = this._editable;
		dessert.required = true;
		dessert.optionValues = [
			{
				icon: "fa-cake-slice",
				name: "Sticky toffee pudding",
				color: "red",
			},
			{
				icon: "fa-pancakes",
				name: "Pancake",
				color: "orange",
			},
			{
				icon: "fa-cupcake",
				name: "Cupcake",
				color: "blue",
			},
		];
		dessert.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		dessert.defaultValue = "Cupcake";
		dessert.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let dispatched = new mBooleanFieldDef("dispatched");
		dispatched.displayName = "Dispatched";
		dispatched.pluralName = "Dispatcheds";
		dispatched.editable = this._editable;
		dispatched.required = true;
		dispatched.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		dispatched.defaultValue = true;
		dispatched.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let urgency = new mPriorityFieldDef("urgency");
		urgency.displayName = "Urgency";
		urgency.pluralName = "Urgencies";
		urgency.editable = this._editable;
		urgency.required = true;
		urgency.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		urgency.defaultValue = mPriorityFieldDef.HIGH;
		urgency.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let transport = new mSingleOptionFieldDef("transport");
		transport.displayName = "Transport";
		transport.pluralName = "Tranports";
		transport.editable = this._editable;
		transport.required = true;
		transport.optionValues = [
			{
				name: "Car",
				color: "red",
			},
			{
				name: "Bus",
				color: "orange",
			},
			{
				name: "Bike",
				color: "blue",
			},
		];
		transport.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		transport.defaultValue = "Car";
		transport.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let boringDessert = new mSingleOptionFieldDef("boring dessert");
		boringDessert.displayName = "Boring Dessert";
		boringDessert.pluralName = "Boring Desserts";
		boringDessert.editable = this._editable;
		boringDessert.required = true;
		boringDessert.optionValues = [
			{
				name: "Sticky toffee pudding",
			},
			{
				name: "Cheesecake",
			},
			{
				name: "Gateaux",
			},
		];
		boringDessert.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		boringDessert.defaultValue = "Gateaux";
		boringDessert.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let labels = new mOptionsBuilderFieldDef("options");
		labels.displayName = "Options";
		labels.pluralName = "Options";
		labels.editable = this._editable;
		labels.required = true;
		// labels.defaultValue = [
		// 	{
		// 		name: "Car",
		// 		icon: "fa-user",
		// 		color: "#ffadad"
		// 	},
		// 	{
		// 		name: "Bus",
		// 		icon: "fa-user",
		// 		color: "#ffadad"
		// 	},
		// 	{
		// 		name: "Bike",
		// 		icon: "fa-user",
		// 		color: "#ffadad"
		// 	}
		//
		// ];
		labels.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let file = new mFileFieldDef("file");
		file.displayName = "File";
		file.pluralName = "Files";
		file.editable = this._editable;
		// file.defaultValue = "https://salesdesk-test1-assetsbucket-1jq7nr5i9mcci.s3.eu-west-1.amazonaws.com/100123168097616_SalesDesk+Deck.pdf";
		file.required = false;
		file.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let file2 = new mFileFieldDef("file 2");
		file2.displayName = "File 2";
		file2.pluralName = "Files 2";
		file2.editable = this._editable;
		file2.required = false;
		file2.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let imageFile = new mImageFieldDef("image");
		imageFile.displayName = "Image";
		imageFile.pluralName = "Images";
		imageFile.editable = this._editable;
		// imageFile.defaultValue = "https://salesdesk-test1-assetsbucket-1jq7nr5i9mcci.s3.eu-west-1.amazonaws.com/10.jpg";
		imageFile.required = false;
		imageFile.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let pdfFile = new mPdfFieldDef("pdfFile");
		pdfFile.displayName = "PDF";
		pdfFile.pluralName = "PDFs";
		pdfFile.editable = this._editable;
		// pdfFile.defaultValue = "https://salesdesk-test1-assetsbucket-1jq7nr5i9mcci.s3.eu-west-1.amazonaws.com/103410947629293_50ideas.pdf";
		pdfFile.required = false;
		pdfFile.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let videoFile = new mVideoFieldDef("videoFile");
		videoFile.displayName = "Video";
		videoFile.pluralName = "Videos";
		videoFile.editable = this._editable;
		// videoFile.defaultValue = "https://salesdesk-test1-assetsbucket-1jq7nr5i9mcci.s3.eu-west-1.amazonaws.com/106810436881512_video.mp4";
		videoFile.required = false;
		videoFile.multiplicity = FIELD_MULTIPLICITY.SINGLE_MULTIPLE;

		let localVideoEssence = new mVideoFieldDef("localVideo");
		localVideoEssence.pluralName = "localVideos";
		localVideoEssence.displayName = "Local video";
		localVideoEssence.formatDescription = mMediaAssetDef.FORMAT_DESCRIPTION;
		localVideoEssence.hidden = true;

		let remoteVideoEssence = new mVideoFieldDef("remoteVideo");
		remoteVideoEssence.pluralName = "remoteVideos";
		remoteVideoEssence.displayName = "Remote video";
		remoteVideoEssence.formatDescription = mMediaAssetDef.FORMAT_DESCRIPTION;
		remoteVideoEssence.hidden = true;

		let keyframe = new mImageFieldDef("keyframe");
		keyframe.pluralName = "keyframes";
		keyframe.displayName = "Screenshot";

		let faceSentiment = new mFileFieldDef("faceSentiment");
		faceSentiment.pluralName = "faceSentiments";
		faceSentiment.displayName = "Face Sentiment";
		faceSentiment.hidden = true;

		let transcriptSentiment = new mFileFieldDef("transcriptSentiment");
		transcriptSentiment.pluralName = "transcriptSentiments";
		transcriptSentiment.displayName = "Transcript Sentiment";
		transcriptSentiment.hidden = true;

		let transcript = new mFileFieldDef("transcript");
		transcript.pluralName = "transcripts";
		transcript.displayName = "Transcript";
		transcript.editable = false;

		let indexed = new mBooleanFieldDef("indexed");
		indexed.pluralName = "indexed";
		indexed.displayName = "Indexed";
		indexed.hidden = true;

		super.data.addChild(paid);
		super.data.addChild(pets);
		super.data.addChild(boringPets);
		super.data.addChild(color);
		super.data.addChild(simpleColor);
		super.data.addChild(dateTime);
		super.data.addChild(date);
		super.data.addChild(duration);
		super.data.addChild(nameField);
		super.data.addChild(emailField);
		super.data.addChild(urlField);
		super.data.addChild(numberField);
		super.data.addChild(floatField);
		super.data.addChild(probabilityField);
		super.data.addChild(progressField);
		super.data.addChild(priceField);
		super.data.addChild(description);
		super.data.addChild(object);
		super.data.addChild(objects);
		super.data.addChild(country);
		super.data.addChild(countries);
		super.data.addChild(currency);
		super.data.addChild(cake);
		super.data.addChild(cakes);
		super.data.addChild(icon);
		super.data.addChild(password);
		super.data.addChild(rating);
		super.data.addChild(toggleSwitch);
		super.data.addChild(phone);
		super.data.addChild(explanation);
		super.data.addChild(delivered);
		super.data.addChild(priority);
		super.data.addChild(dessert);
		super.data.addChild(dispatched);
		super.data.addChild(urgency);
		super.data.addChild(transport);
		super.data.addChild(boringDessert);
		super.data.addChild(labels);
		super.data.addChild(file);
		super.data.addChild(file2);
		super.data.addChild(imageFile);
		super.data.addChild(pdfFile);
		super.data.addChild(videoFile);
		super.data.addChild(localVideoEssence);
		super.data.addChild(remoteVideoEssence);
		super.data.addChild(keyframe);
		super.data.addChild(faceSentiment);
		super.data.addChild(transcriptSentiment);
		super.data.addChild(transcript);
		super.data.addChild(indexed);
	}
}
