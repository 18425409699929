import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { DisplayIf } from "../../../../../auth";
import { useState } from "react";
import { CreateRoleDialog } from "./CreateRoleDialog";

export function CreateRoleButton() {
	const [openDialog, setOpenDialog] = useState(false);
	return (
		<>
			<DisplayIf can={[AbilityAction.Create, AbilitySubject.Role]}>
				<div className="shrink-0 p-6">
					<Button variant="outlined" startIcon={ICONS.plus} size="sm" fullWidth onClick={() => setOpenDialog(true)}>
						Add Role
					</Button>
				</div>
			</DisplayIf>
			{openDialog ? <CreateRoleDialog onClose={() => setOpenDialog(false)} /> : null}
		</>
	);
}
