import { useForm } from "react-hook-form";

import { SDObject } from "@salesdesk/salesdesk-schemas";

import { getDataSettingsValues, SETTINGS_FIELD_GROUPS, SETTINGS_FIELDS } from "../utils";

export function useObjectSettings(
	sdObject: SDObject,
	onObjectSettingsChange: (formValues: Record<string, any>) => void
) {
	const formMethods = useForm({
		mode: "onChange",
		defaultValues: getDataSettingsValues(sdObject, SETTINGS_FIELDS),
	});

	const isObjectSettingsValid = Object.keys(formMethods.formState.errors).length === 0;
	const isObjectSettingsChanged = formMethods.formState.isDirty;

	formMethods.watch((formValues) => {
		onObjectSettingsChange(formValues);
	});

	const onSubmitSuccess = (updatedSdObject: SDObject) => {
		const submittedData = getDataSettingsValues(updatedSdObject, SETTINGS_FIELDS);
		formMethods.reset(submittedData);
	};

	return {
		availableGroupedFields: SETTINGS_FIELD_GROUPS,
		formMethods,
		isObjectSettingsValid,
		isObjectSettingsChanged,
		onSubmitSuccess,
		reset: formMethods.reset,
		formFields: SETTINGS_FIELDS,
	};
}
