import { getSDRecordSystemFieldValueByFieldName, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";
import { useUserObjectsMap } from "../../../../users";
import { isUserObject, mUserDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

interface UserRecordDetailsButtonsProps {
	sdRecord?: SDRecord;
}

export function UserRecordDetailsButtons({ sdRecord }: UserRecordDetailsButtonsProps) {
	const userObjectMap = useUserObjectsMap();

	const userDetailsButtons = useMemo(() => {
		if (!sdRecord || !isUserObject(sdRecord._objectDefId)) {
			return [];
		}

		const userObject = userObjectMap?.get(sdRecord._objectDefId);
		if (!userObject) {
			return [];
		}

		const details = [];

		const userEmail = getSDRecordSystemFieldValueByFieldName(userObject, sdRecord, mUserDef.EMAIL_FIELD_NAME)?._value;
		if (userEmail) {
			details.push({ icon: ICONS.envelope, tooltip: "Send email", link: `mailto:${userEmail}` });
		}

		const userLinkedIn = getSDRecordSystemFieldValueByFieldName(
			userObject,
			sdRecord,
			mUserDef.LINKEDIN_FIELD_NAME
		)?._value;
		if (userLinkedIn) {
			details.push({ icon: ICONS.linkedIn, tooltip: "View LinkedIn", link: userLinkedIn });
		}

		const userPhone = getSDRecordSystemFieldValueByFieldName(userObject, sdRecord, mUserDef.MOBILE_FIELD_NAME)?._value;
		if (userPhone) {
			details.push({ icon: ICONS.phone, tooltip: "Call", link: `tel:${userPhone}` });
		}

		return details;
	}, [sdRecord, userObjectMap]);

	return (
		<>
			{userDetailsButtons.map((button, index) => (
				<Tooltip text={button.tooltip} key={button.tooltip}>
					<Button
						key={index}
						startIcon={button.icon}
						size="sm"
						variant="text"
						as="link"
						to={button.link}
						target="_blank"
						rel="noopener noreferrer"
					/>
				</Tooltip>
			))}
		</>
	);
}
