import {
	SDApiKey,
	SDApiKeyCreateRequest,
	SDApiKeyCreateResponse,
	SDApiKeyPatchRequest,
	SDApiKeyPath,
} from "@salesdesk/salesdesk-schemas";

import { buildApiInvalidatedTagList, buildApiTagsList, SDApi } from "../../../api";

export const apikeysApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getApiKeys: builder.query<SDApiKey[], void>({
				query: () => ({ url: `apiKeys`, method: "GET" }),
				providesTags: (result) => buildApiTagsList(result, (r) => r.apiKeyId, "ApiKeys"),
			}),
			createApiKey: builder.mutation<SDApiKeyCreateResponse, SDApiKeyCreateRequest>({
				query: (body) => ({
					url: `apiKeys`,
					method: "POST",
					body,
				}),
				invalidatesTags: (result) => buildApiInvalidatedTagList(result, (r) => r.apiKeyId, "ApiKeys"),
			}),
			updateApiKey: builder.mutation<SDApiKey, { id: SDApiKeyPath["apiKeyId"]; body: SDApiKeyPatchRequest }>({
				query: ({ id, body }) => ({
					url: `apiKeys/${id}`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList(arg, (arg) => arg.id, "ApiKeys"),
			}),
			deleteApiKey: builder.mutation<undefined, { id: SDApiKeyPath["apiKeyId"] }>({
				query: ({ id }) => ({
					url: `apiKeys/${id}`,
					method: "DELETE",
				}),
				invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList(arg, (arg) => arg.id, "ApiKeys"),
			}),
		};
	},
});

export const {
	useGetApiKeysQuery,
	useLazyGetApiKeysQuery,
	useCreateApiKeyMutation,
	useUpdateApiKeyMutation,
	useDeleteApiKeyMutation,
} = apikeysApi;
