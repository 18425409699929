import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { SelectOption } from "../../../../../../../inputs";

export interface MeetingRow {
	meetingRecord: SDRecord;
	meetingStartDate: Date;
	dateLabel?: string;
}

export const MEETING_DATE_ATTRIBUTE_NAME = "data-meeting-date";
export const MONTH_KEY_ATTRIBUTE_NAME = "data-month-key";

export type UpcomingMeetingFilter = "ALL_MEETINGS" | "MY_MEETINGS";
const workspaceUpcomingMeetingFilters: Record<UpcomingMeetingFilter, string> = {
	ALL_MEETINGS: "All meetings",
	MY_MEETINGS: "My meetings",
};

export const upcomingMeetingOptions: SelectOption[] = (
	["ALL_MEETINGS", "MY_MEETINGS"] satisfies UpcomingMeetingFilter[]
).map((id) => ({
	id,
	name: workspaceUpcomingMeetingFilters[id],
}));
