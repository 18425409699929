import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";

import { useTrack } from "../../VideoCallProvider";
import { VideoTrack } from "../VideoTrack";

interface PublicationProps {
	publication: LocalTrackPublication | RemoteTrackPublication;
	isLocal?: boolean;
}

export default function Publication({ publication, isLocal }: PublicationProps) {
	const track = useTrack(publication);

	// All participant audio tracks are mounted in ParticipantAudioTracks.tsx
	if (!track || track.kind !== "video") return null;

	return <VideoTrack track={track} isLocal={!track.name.includes("screen") && isLocal} />;
}
