import { useMemo } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { SDRecord, sdSubject, AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { useGetRecordFollowingStatus, useGetRecordDetailsPath } from "../../../hooks";
import { useFavoriteRecordMutation, useUnfavoriteRecordMutation } from "../../../api/favoritesApi";
import { useUnwatchRecordMutation, useWatchRecordMutation } from "../../../api/watchesApi";
import { useBulkChangeOwnerContext } from "../../BulkChangeOwnerModal";
import { useWebPrincipal } from "../../../../../auth";
import { useToast } from "../../../../Toasts";
import { MenuItem } from "../../../../menu";

export function usePrimaryRecordActions(sdRecord: SDRecord) {
	const principal = useWebPrincipal();
	const { isWatched, isFavorited } = useGetRecordFollowingStatus(sdRecord);

	const [favoriteRecord] = useFavoriteRecordMutation();
	const [unfavoriteRecord] = useUnfavoriteRecordMutation();

	const [watchRecord] = useWatchRecordMutation();
	const [unwatchRecord] = useUnwatchRecordMutation();

	const toast = useToast();
	const { setBulkChangeOwnerRecords } = useBulkChangeOwnerContext();

	const recordDetailPath = useGetRecordDetailsPath(sdRecord);

	return useMemo(() => {
		const primaryMenuItems: MenuItem[] = [];

		if (recordDetailPath) {
			primaryMenuItems.push({ icon: ICONS.listDashes, text: "Record detail", link: recordDetailPath, type: "link" });
		}

		if (isFavorited) {
			primaryMenuItems.push({
				icon: ICONS.star,
				iconVariant: "fill",
				variant: "primary_icon",
				text: "Remove favorite",
				onClick: () =>
					unfavoriteRecord(sdRecord._id)
						.unwrap()
						.then(() => toast.triggerMessage({ type: "success", messageKey: "record_unfavorited" }))
						.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_unfavorited" })),
				type: "button",
			});
		} else {
			primaryMenuItems.push({
				icon: ICONS.star,
				text: "Add to favorites",
				onClick: () =>
					favoriteRecord(sdRecord._id)
						.unwrap()
						.then(() => toast.triggerMessage({ type: "success", messageKey: "record_favorited" }))
						.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_favorited" })),
				type: "button",
			});
		}

		primaryMenuItems.push({
			icon: ICONS.link,
			text: "Copy link",
			onClick: recordDetailPath
				? () => navigator.clipboard.writeText(window.location.origin + recordDetailPath)
				: undefined,
			type: "button",
		});

		if (isWatched) {
			primaryMenuItems.push({
				icon: ICONS.flag,
				iconVariant: "fill",
				variant: "primary_icon",
				text: "Unwatch",
				onClick: () =>
					unwatchRecord(sdRecord._id)
						.unwrap()
						.then(() => toast.triggerMessage({ type: "success", messageKey: "record_unwatched" }))
						.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_unwatched" })),
				type: "button",
			});
		} else {
			primaryMenuItems.push({
				icon: ICONS.flag,
				text: "Watch",
				onClick: () =>
					watchRecord(sdRecord._id)
						.unwrap()
						.then(() => toast.triggerMessage({ type: "success", messageKey: "record_watched" }))
						.catch(() => toast.triggerMessage({ type: "error", messageKey: "record_watched" })),
				type: "button",
			});
		}

		if (principal.can(AbilityAction.ChangeOwner, sdSubject(AbilitySubject.Record, sdRecord))) {
			primaryMenuItems.push({
				icon: ICONS.user,
				text: "Change owner",
				onClick: () => setBulkChangeOwnerRecords([sdRecord]),
				type: "button",
			});
		}

		if (principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, sdRecord))) {
			primaryMenuItems.push({ icon: ICONS.copy, text: "Duplicate", type: "button" });
		}

		return primaryMenuItems;
	}, [
		recordDetailPath,
		isFavorited,
		isWatched,
		sdRecord,
		favoriteRecord,
		unfavoriteRecord,
		watchRecord,
		unwatchRecord,
		toast,
		principal,
		setBulkChangeOwnerRecords,
	]);
}
