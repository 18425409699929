import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { RecordAssociations } from "../../../../../recordAssociations";
import { Accordion } from "../../../../../../components/Accordion";
import { DirectedSDObjectAssociation, useAssociationDataboardUrl } from "../../../../../recordAssociations";
import { AssociationObjectLabel } from "./AssociationObjectLabel";
import { Link } from "../../../../../../components/Link";
import { RecordAssociationOpeningVariant } from "../../../../../recordAssociations/types";

interface AssociationGroupProps {
	sourceRecord?: SDRecord;
	objectAssociation: DirectedSDObjectAssociation;
	onUpdateCount?: (associationId: number, count: number) => void;
	isCollapsible?: boolean;
	limit?: number;
	infiniteScroll?: boolean;
	defaultOpen?: boolean;
	showTitleBar?: boolean;
	associationOpeningVariant?: RecordAssociationOpeningVariant;
}

export function AssociationGroup({
	sourceRecord,
	objectAssociation,
	onUpdateCount,
	limit,
	associationOpeningVariant,
	showTitleBar = true,
	isCollapsible = true,
	defaultOpen = true,
	infiniteScroll = false,
}: AssociationGroupProps) {
	const [count, setCount] = useState(0);
	const [associationObject, setAssociationObject] = useState<SDObject | undefined>(undefined);

	const { associationDataboardUrl, associationDataboardLinkText } = useAssociationDataboardUrl(
		associationObject,
		objectAssociation,
		sourceRecord?._id
	);

	const handleUpdateCount = useCallback(
		(count: number) => {
			setCount(count);

			if (onUpdateCount && sourceRecord) {
				onUpdateCount(objectAssociation.id, count);
			}
		},
		[sourceRecord, onUpdateCount, objectAssociation]
	);

	const onAssociationObjectLoad = useCallback((associationObject: SDObject) => {
		setAssociationObject(associationObject);
	}, []);

	const { associationLabel, associationObjectLabel } = useMemo(() => {
		const showingLabels = isCollapsible || showTitleBar;

		if (!showingLabels) {
			return { associationLabel: "", associationObjectLabel: "" };
		}

		return {
			associationLabel: capitalizeString(objectAssociation.connectedObject.label),
			associationObjectLabel: (
				<div className={clsx("ml-3 flex items-center justify-between", infiniteScroll && "w-full")}>
					<AssociationObjectLabel associationObject={associationObject} objectAssociation={objectAssociation} />
					{associationDataboardUrl && infiniteScroll ? (
						<Link size="sm" to={associationDataboardUrl} text={associationDataboardLinkText} />
					) : null}
				</div>
			),
		};
	}, [
		isCollapsible,
		showTitleBar,
		objectAssociation,
		infiniteScroll,
		associationObject,
		associationDataboardUrl,
		associationDataboardLinkText,
	]);

	if (!sourceRecord) return null;

	const associationsComponent = (
		<RecordAssociations
			key={sourceRecord._id}
			sourceRecord={sourceRecord}
			onUpdateCount={handleUpdateCount}
			objectAssociation={objectAssociation}
			onAssociationObjectLoad={onAssociationObjectLoad}
			limit={limit}
			infiniteScroll={infiniteScroll}
			openingVariant={associationOpeningVariant}
		/>
	);

	if (isCollapsible) {
		return (
			<Accordion
				title={associationLabel}
				helperText={associationObjectLabel}
				count={count}
				unmount={false}
				defaultOpen={defaultOpen}
			>
				<div className="mt-4">{associationsComponent}</div>
			</Accordion>
		);
	}

	return (
		<>
			{showTitleBar ? (
				<div className="text-label flex w-full justify-between pb-4">
					{associationLabel}
					{associationObjectLabel}
				</div>
			) : null}
			{associationsComponent}
		</>
	);
}
