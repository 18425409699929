import { useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";

import { Bookmark } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { EditTextField } from "../../../fields";
import { FormDialog } from "../../../Dialog";
import { FormFieldSet } from "../../../forms";
import { useCreateMyBookmarkMutation, useUpdateMyBookmarkMutation } from "../../api/bookmarksApi";
import { getBookmarkRedirectUrl, getBookmarkParamsFromLocation } from "../../utils";

import { useStableNavigate } from "../../../../routes";
import { useToast } from "../../../Toasts";
import { useSdObjectFromUrl } from "../hooks/useSdObjectFromUrl";

interface EditBookmarkDialogProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	bookmark?: Bookmark;
}

interface BookmarkFormData {
	name: string;
}

export function EditBookmarkDialog({ open, onOpenChange, bookmark }: EditBookmarkDialogProps) {
	const navigate = useStableNavigate();
	const [isSaving, setIsSaving] = useState(false);

	const { control, handleSubmit, reset, setFocus } = useForm<BookmarkFormData>({
		mode: "onChange",
		defaultValues: {
			name: bookmark?.name || "",
		},
	});

	const [editBookmark] = useUpdateMyBookmarkMutation();
	const [createBookmark] = useCreateMyBookmarkMutation();
	const toast = useToast();

	const formId = useId();

	const isCreate = !bookmark;

	const { sdObjects, sdObject } = useSdObjectFromUrl(window.location.pathname);

	const onFormSubmit = ({ name }: BookmarkFormData) => {
		if (!sdObject) {
			toast.triggerMessage({ type: "error", messageKey: "generic" });
			console.error("sdObject is not defined");
			return;
		}
		setIsSaving(true);
		if (bookmark) {
			editBookmark({
				bookmarkId: bookmark.id,
				body: {
					name: name,
					objectId: sdObject._id,
					value: bookmark.value,
				},
			})
				.unwrap()
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "bookmark_updated" });
					reset();
					onOpenChange(false);
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "bookmark_updated" });
				})
				.finally(() => {
					setIsSaving(false);
				});
		} else {
			const newBookmark = {
				name,
				objectId: sdObject._id,
				value: getBookmarkParamsFromLocation(),
			};
			createBookmark(newBookmark)
				.unwrap()
				.then((newBookmark) => {
					toast.triggerMessage({ type: "success", messageKey: "bookmark_created" });
					reset();
					navigate(getBookmarkRedirectUrl(sdObjects, newBookmark));
					onOpenChange(false);
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "bookmark_created" });
				})
				.finally(() => {
					setIsSaving(false);
				});
		}
	};

	useEffect(() => {
		setTimeout(() => setFocus("name"), 60);
	}, [setFocus]);

	return (
		<FormDialog
			open={open}
			title={isCreate ? "Create bookmark" : "Edit bookmark"}
			submitButtonLabel={isCreate ? "Create" : "Save"}
			onOpenChange={onOpenChange}
			isPending={isSaving}
			width={382}
			formId={formId}
		>
			<form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
				<FormFieldSet
					control={control}
					name="name"
					label="Name"
					labelIcon={ICONS.text}
					required
					rules={{ required: "Name is required" }}
				>
					{({ field, fieldState: { error } }) => (
						<EditTextField title="Name" {...field} hasError={Boolean(error?.message)} />
					)}
				</FormFieldSet>
			</form>
		</FormDialog>
	);
}
