import { useState, useCallback, useMemo } from "react";

import { OnboardingFlow } from "../utils";

export function useOnboardingFlowState(onboardingFlowValue: Record<string, string[]>) {
	const [onboardingFlowName, setOnboardingFlowName] = useState<string>();
	const [currentOnboardingFlowStep, setCurrentOnboardingFlowStep] = useState<number>();

	const startFlow = useCallback((onboardingFlowName: OnboardingFlow) => {
		setOnboardingFlowName(onboardingFlowName);
		setCurrentOnboardingFlowStep(0);
	}, []);

	const nextStep = useCallback(() => {
		if (!onboardingFlowName) return;

		const onboardingFlowByName = onboardingFlowValue[onboardingFlowName];

		setCurrentOnboardingFlowStep((prevOnboardingFlowStep) => {
			if (typeof prevOnboardingFlowStep === "undefined") {
				return 0;
			}

			const nextOnboardingFlowStep = prevOnboardingFlowStep + 1;

			if (nextOnboardingFlowStep > onboardingFlowByName.length) {
				return undefined;
			}

			return nextOnboardingFlowStep;
		});
	}, [onboardingFlowValue, onboardingFlowName]);

	const stopFlow = useCallback(() => {
		setCurrentOnboardingFlowStep(undefined);
	}, []);

	const currentOnboardingFlowStepValue = useMemo(() => {
		return onboardingFlowName && typeof currentOnboardingFlowStep === "number"
			? onboardingFlowValue[onboardingFlowName][currentOnboardingFlowStep]
			: undefined;
	}, [currentOnboardingFlowStep, onboardingFlowName, onboardingFlowValue]);

	return {
		currentOnboardingFlowStep: currentOnboardingFlowStepValue,
		startFlow,
		nextStep,
		stopFlow,
	};
}
