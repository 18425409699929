import { useCallback, useEffect, useState } from "react";
import userflow from "userflow.js";

import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Dialog, DialogContent } from "../../Dialog";
import { useOnboardingContext } from "../hooks";
import { WorkspaceOnboardingStep } from "../utils";
import { useAppStateContext } from "../../users/hooks/useAppStateContext";
import { usePatchMyAppStateMutation } from "../../users/api/userAppStateApi";

export function WorkspaceOnboarding() {
	const { currentOnboardingFlowStep } = useOnboardingContext();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { appStateRef } = useAppStateContext();
	const [patchMyAppState] = usePatchMyAppStateMutation();

	const closeModal = useCallback(() => {
		const onboardingAppState = appStateRef.current?.onboarding ?? {};

		patchMyAppState({ onboarding: { ...onboardingAppState, isFirstTimeInWorkspace: false } });
		setIsModalOpen(false);
	}, [appStateRef, patchMyAppState]);

	useEffect(() => {
		switch (currentOnboardingFlowStep) {
			case WorkspaceOnboardingStep.SHOW_WORKSPACE_WELCOME_MODAL:
				setIsModalOpen(true);
		}
	}, [currentOnboardingFlowStep]);

	const startStepByStepTour = () => {
		userflow.track("onboarding_workspace_step_by_step");
		closeModal();
	};

	return (
		<Dialog open={isModalOpen}>
			<DialogContent noPadding>
				<div className="bg-c_bg_01 flex w-[1000px] flex-col items-center gap-2.5 px-7 pb-8 pt-7">
					<img
						className="aspect-square w-full max-w-[506px]"
						src="/static/images/onboarding/workspace_onboarding.svg"
						alt="Welcome to SalesDesk Workspaces"
					/>
					<div className="flex justify-center gap-6">
						<Button variant="secondary" onClick={() => closeModal()}>
							Explore on my own
						</Button>
						<Button startIcon={ICONS.sparkle} onClick={startStepByStepTour}>
							Step-by-step tour
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
