import { asConst } from "json-schema-to-ts";
import { notClauseSchema } from "./notClauseSchema";
import { notClausesSchema } from "./notClausesSchema";

export const subQueryClausesSchema = asConst({
	title: "Allowed sub query clauses",
	type: "object",
	anyOf: [notClauseSchema, ...notClausesSchema.anyOf],
	nullable: false,
});
