import { SDObject } from "@salesdesk/salesdesk-schemas";
import { FilterData } from "../types";
import { useGetFilterTargets } from "../hooks/useGetFilterTargets";
import { FilteringPopover } from "./FilteringPopover";
import { generateNewFilter } from "../utils";
import { useMemo } from "react";

interface ObjectFilteringPopoverProps {
	value: FilterData;
	onChange: (value: FilterData) => void;
	sdObject: SDObject;
	disabled?: boolean;
	iconOnly?: boolean;
}

export function ObjectFilteringPopover({ value, onChange, sdObject, disabled, iconOnly }: ObjectFilteringPopoverProps) {
	const { filterTargetMap, filterTargetOptions } = useGetFilterTargets(sdObject);
	const defaultFilter = useMemo(() => generateNewFilter(sdObject), [sdObject]);
	return (
		<FilteringPopover
			value={value}
			onChange={onChange}
			filterTargetMap={filterTargetMap}
			filterTargetOptions={filterTargetOptions}
			defaultFilter={defaultFilter}
			disabled={disabled}
			iconOnly={iconOnly}
		/>
	);
}
