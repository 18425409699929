import { Claim, ClaimsPrincipal, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mContactDef, mLeadDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

export class WebPrincipal extends ClaimsPrincipal {
	private readonly userRecord: SDRecord;

	constructor(userRecord: SDRecord, claims: Claim[] = []) {
		super(claims);
		this.userRecord = userRecord;
	}

	public get IsUser() {
		return this.userRecord._objectDefId === mSalesDeskUserDef.ID;
	}

	public get IsContact() {
		return this.userRecord._objectDefId === mContactDef.ID;
	}

	public get IsLead() {
		return this.userRecord._objectDefId === mLeadDef.ID;
	}
}
