import {
	Field,
	getSDObjectSystemFieldByName,
	getSDRecordFieldValue,
	rsr,
	SDObject,
	SDRecord,
} from "@salesdesk/salesdesk-schemas";
import {
	formatFERecordFieldValue,
	getCurrentPathWithRecordIdParam,
	SortingDetails,
	SortingOrder,
	updateSortingDetailsForField,
} from "../../../../records";
import { RecordTableColumn, RecordTableRow, RowData } from "../types";
import { HeaderCell, ROW_IS_LOADING_PLACEHOLDER, ROW_LINK_COLUMN_ID } from "../../../../Table";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";
import { mContactDef, mLeadDef, mSalesDeskUserDef, mSingleOptionFieldDef, mUserDef } from "@salesdesk/salesdesk-model";
import { Pill } from "../../../../../components/Pill/Pill";
import { ReactNode } from "react";
import { DisplayPlaceholderField } from "../../../../fields/components/Fields/DisplayPlaceholderField";

export const SD_RECORD_COLUMN_ID = "sdRecord";

interface UserTableColumn {
	id: string;
	name: string;
	isSortable: boolean;
	isFilterable?: boolean;
	objectIds: number[];
	renderRowCell?: (cellContents: RowData, row: RecordTableRow) => ReactNode;
}

export const OBJECT_DEF_ID_FIELD_NAME = "_objectDefId";

export const USER_TABLE_COLUMNS: UserTableColumn[] = [
	{
		id: mUserDef.PHOTO_FIELD_NAME,
		name: "Profile Photo",
		isSortable: false,
		isFilterable: false,
		objectIds: [mSalesDeskUserDef.ID, mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.FIRST_NAME_FIELD_NAME,
		name: "First Name",
		isSortable: true,
		isFilterable: true,
		objectIds: [mSalesDeskUserDef.ID, mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.SURNAME_FIELD_NAME,
		name: "Surname",
		isSortable: true,
		isFilterable: true,
		objectIds: [mSalesDeskUserDef.ID, mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.COMPANY_FIELD_NAME,
		name: "Company",
		isSortable: true,
		isFilterable: true,
		objectIds: [mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.TITLE_FIELD_NAME,
		name: "Title",
		isSortable: true,
		isFilterable: true,
		objectIds: [mSalesDeskUserDef.ID, mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.ROLES_FIELD_NAME,
		name: "Roles",
		isSortable: true,
		isFilterable: true,
		objectIds: [mSalesDeskUserDef.ID],
	},
	{
		id: OBJECT_DEF_ID_FIELD_NAME,
		name: "Type",
		isSortable: false,
		isFilterable: true,
		objectIds: [mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.LAST_ACTIVE_FIELD_NAME,
		name: "Last Active",
		isSortable: true,
		isFilterable: true,
		objectIds: [mSalesDeskUserDef.ID, mContactDef.ID, mLeadDef.ID],
	},
	{
		id: mUserDef.LOGIN_AUTHORIZED_FIELD_NAME,
		name: "Login Status",
		isSortable: false,
		isFilterable: true,
		objectIds: [mSalesDeskUserDef.ID],
		renderRowCell: (cellContents: RowData, row: RecordTableRow) => {
			if (row[ROW_IS_LOADING_PLACEHOLDER]) {
				return <Skeleton className="my-2 h-6 w-full" />;
			}
			if (!cellContents || typeof cellContents !== "object" || !("value" in cellContents)) {
				return <DisplayPlaceholderField />;
			}
			const lastActiveFieldValue = row[mUserDef.LAST_ACTIVE_FIELD_NAME];
			const hasBeenActive =
				lastActiveFieldValue && typeof lastActiveFieldValue === "object" && "value" in lastActiveFieldValue
					? Boolean(lastActiveFieldValue.value)
					: false;

			if (!hasBeenActive) {
				return cellContents.value ? "Invited" : "Not Invited";
			}

			return cellContents.value ? "Active" : "Deactivated";
		},
	},
	{
		id: mUserDef.LOGIN_ENABLED_TIME_FIELD_NAME,
		name: "Login Enabled Time",
		isSortable: true,
		isFilterable: true,
		objectIds: [mContactDef.ID, mLeadDef.ID],
	},
];

export function getFilteredFilterFields(objectMap: Map<number, SDObject>, objectIds: number[]): Field[] {
	return getFilteredColumns(objectIds, true).flatMap((column) => {
		if (column.id === OBJECT_DEF_ID_FIELD_NAME) return createRecordTypeField(objectMap);
		const userObjectId = column.objectIds.find((id) => objectMap.has(id));
		if (!userObjectId) return [];
		const sdObject = objectMap.get(userObjectId);
		if (!sdObject) return [];
		return getSDObjectSystemFieldByName(sdObject, column.id) ?? [];
	});
}

function getFilteredColumns(objectIds: number[], onlyFilterable = false): UserTableColumn[] {
	return USER_TABLE_COLUMNS.filter(
		(column) => column.objectIds.some((id) => objectIds.includes(id)) && (!onlyFilterable || column.isFilterable)
	);
}

export function isMetadataFieldName(fieldName: string) {
	return fieldName.startsWith("_");
}

function getEffectiveColumnId(objectMap: Map<number, SDObject>, column: UserTableColumn): string {
	const sdObjectId = column.objectIds.find((id) => objectMap.has(id));
	const sdObject = sdObjectId ? objectMap.get(sdObjectId) : undefined;
	if (isMetadataFieldName(column.id) || !sdObject) {
		return column.id;
	}
	const field = getSDObjectSystemFieldByName(sdObject, column.id);
	if (field) {
		return String(field._id);
	}
	return column.id;
}

export function generateColumns(
	objectMap: Map<number, SDObject>,
	objectIds: number[],
	sorting: SortingDetails[] | undefined,
	setSorting: (sortingDetails: SortingDetails[]) => void
): RecordTableColumn[] {
	return getFilteredColumns(objectIds).map((column) => {
		const columnId = getEffectiveColumnId(objectMap, column);
		const sortingOrder = sorting?.find((sort) => sort.fieldId === getEffectiveColumnId(objectMap, column))?.order;

		const onSortingOrderChange = column.isSortable
			? (order?: SortingOrder) => {
					setSorting(updateSortingDetailsForField(sorting ?? [], columnId, order));
				}
			: undefined;

		return {
			id: column.id,
			displayName: () => {
				return (
					<HeaderCell
						columnId={getEffectiveColumnId(objectMap, column)}
						columnName={column.name}
						sortingOrder={sortingOrder}
						onSortingOrderChange={onSortingOrderChange}
					/>
				);
			},
			renderRowCell:
				column.renderRowCell ??
				((cellContents: RowData, row: RecordTableRow) => {
					if (
						row[ROW_IS_LOADING_PLACEHOLDER] ||
						typeof cellContents === "boolean" ||
						(typeof cellContents !== "number" && "_id" in cellContents)
					) {
						return <Skeleton className="my-2 h-6 w-full" />;
					}
					if (typeof cellContents === "number") {
						const sdObject = objectMap.get(cellContents);
						if (!sdObject) {
							return undefined;
						}
						return (
							<Pill variant="outlined" icon={sdObject._icon}>
								{sdObject._displayName}
							</Pill>
						);
					}
					return (
						<DisplayFieldFactory
							field={cellContents.field}
							value={cellContents.value}
							variant={DisplayFieldVariant.table}
						/>
					);
				}),
		};
	});
}

export function createRecordTypeField(objectMap: Map<number, SDObject>): Field {
	const objectTypeField = new mSingleOptionFieldDef(OBJECT_DEF_ID_FIELD_NAME);
	objectTypeField.pluralName = "Types";
	objectTypeField.displayName = "Type";
	objectTypeField.optionValues = [...objectMap.values()].map((sdObject) => ({
		id: sdObject._id,
		name: sdObject._displayName,
		icon: sdObject._icon,
	}));
	return objectTypeField.unmarshall() as Field;
}

export function generateRows(
	objectIds: number[],
	objectMap: Map<number, SDObject>,
	sdRecords: SDRecord[]
): RecordTableRow[] {
	return sdRecords.map((record) => {
		const sdObject = objectMap.get(record._objectDefId);

		const row: RecordTableRow = {};
		row[SD_RECORD_COLUMN_ID] = record;
		row[ROW_LINK_COLUMN_ID] = () => getCurrentPathWithRecordIdParam(record._id);
		for (const { id: columnId } of getFilteredColumns(objectIds)) {
			if (columnId === OBJECT_DEF_ID_FIELD_NAME) {
				row[columnId] = record[columnId];
				continue;
			}
			const field = sdObject ? getSDObjectSystemFieldByName(sdObject, columnId) : undefined;
			const value = field ? getSDRecordFieldValue(record, field._id)?._value : undefined;
			row[columnId] = field
				? { field, value: sdObject ? formatFERecordFieldValue(sdObject, record, field, value) : value }
				: false;
		}
		return row;
	});
}

export function getLoginAuthorizedFieldId(objectMap: Map<number, SDObject>): Field["_id"] | undefined {
	const sdObject = objectMap.values().next().value;
	if (!sdObject) return;
	const loginAuthorizedField = getSDObjectSystemFieldByName(sdObject, mUserDef.LOGIN_AUTHORIZED_FIELD_NAME);
	if (!loginAuthorizedField) return;
	return loginAuthorizedField._id;
}

export function getBaseFilterQuery(
	userObjectMap: Map<number, SDObject>,
	isCustomersPage: boolean,
	objectIds?: number[]
) {
	const query = rsr.query().and(rsr.equals("_deleted", false));
	if (isCustomersPage) {
		const loginAuthorizedFieldId = getLoginAuthorizedFieldId(userObjectMap);
		if (loginAuthorizedFieldId) {
			query.and(rsr.equals(String(loginAuthorizedFieldId), true));
		}
	}

	if (objectIds) {
		for (const objectId of objectIds) {
			query.or(rsr.equals("_objectDefId", objectId));
		}
	}

	return query;
}
