import { useCallback, useId, memo } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { tw } from "@salesdesk/daisy-ui";
import { FieldInfo } from "@salesdesk/salesdesk-schemas";

import { useAccessibleHTMLElementLink } from "../../../../../hooks/ui";
import { CardLabel } from "../../../../../components/CardLabel/CardLabel";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";
import { Checkbox } from "../../../../inputs";
import { isAnalysingVideo, VideoAnalysisStatus } from "../../../../inputs/components/FileInput";
import { RecordContextMenu } from "../../RecordActions";
import { RECORD_SIDE_PANEL_TRIGGER_CLASS } from "../../RecordDetails";
import { BaseRecordCardProps } from "../types";
import { RecordCardFooter } from "./RecordCardFooter";
import { useMediaFieldDetails } from "../hooks/useMediaFieldDetails";
import { PrimaryHeading } from "./PrimaryHeading";
import { useRecordBadges } from "../../RecordBadges/hooks/useRecordBadges";
import { isUserObject } from "@salesdesk/salesdesk-model";
import { UserLoginStatus } from "../../UserLastActive/UserLoginStatus";
import { useGetObjectById } from "../../../../../hooks";

interface RecordCardProps extends BaseRecordCardProps {
	fieldInfoToDisplay: FieldInfo[];
}

function RecordCardComponent({
	sdRecord,

	fieldInfoToDisplay,
	mediaFieldInfo,

	disabled,
	getRecordLink,
	allowDelete = true,
	getCustomContextMenuItems,
	hideContextMenu,

	vertical = false,
	associationsSummary,

	active,

	selected,
	onToggleSelect,
}: RecordCardProps) {
	const generateRecordLink = useCallback(() => {
		return getRecordLink?.(sdRecord._id);
	}, [getRecordLink, sdRecord._id]);

	const recordLink = generateRecordLink();
	const linkElementId = useId();
	const handleCardClickEvent = useAccessibleHTMLElementLink({ linkElementId, getLink: generateRecordLink });

	const [primaryHeading, ...remainingFields] = fieldInfoToDisplay;

	const { mediaFieldDisplay, mediaFieldFile } = useMediaFieldDetails(sdRecord, mediaFieldInfo);

	let cardStateClasses = "";
	if (disabled) {
		cardStateClasses = tw`bg-c_bg_disabled_02 shadow-card border-c_border_regular`;
	} else if (selected) {
		cardStateClasses = tw`bg-c_bg_03 border-c_action_01`;
	} else if (active) {
		cardStateClasses = tw`bg-c_bg_03 shadow-card_active border-c_action_01`;
	} else {
		cardStateClasses = tw`bg-c_bg_01 hover:bg-c_bg_03 border-c_border_regular shadow-card`;
	}

	const recordBadges = useRecordBadges(sdRecord);

	const { sdObject } = useGetObjectById(sdRecord._objectDefId);

	return (
		<div
			className={clsx(
				"text-body focus-visible-within:ring-c_action_focus rounded-card focus-visible-within:ring group relative flex h-full border p-4 transition-all",
				disabled ? "pointer-events-none select-none" : "cursor-pointer",
				cardStateClasses,
				RECORD_SIDE_PANEL_TRIGGER_CLASS
			)}
			onClick={onToggleSelect ?? handleCardClickEvent}
			onAuxClick={handleCardClickEvent}
		>
			<div className={clsx(disabled && "opacity-40", "flex w-full flex-col justify-between gap-2 overflow-hidden")}>
				<div className="flex flex-col items-start gap-2">
					{recordLink ? (
						<Link id={linkElementId} to={recordLink} className="sr-only">
							Go to record
						</Link>
					) : null}
					{/*
						TODO: Replace subheading with record's main association  (e.g. the account for an opportunity).
						{subheading ? <div className="text-body-sm text-c_text_secondary max-w-full truncate">{subheading}</div> : null}
					*/}
					<div className="items-between flex w-full">
						{mediaFieldDisplay}
						{sdObject && isUserObject(sdObject._id) ? (
							<UserLoginStatus userObject={sdObject} userRecord={sdRecord} usePolling={false} />
						) : null}
					</div>
					{mediaFieldFile && isAnalysingVideo(mediaFieldFile) ? <VideoAnalysisStatus /> : null}
					{primaryHeading && sdObject ? (
						<PrimaryHeading
							sdObject={sdObject}
							sdRecord={sdRecord}
							fieldInfo={primaryHeading}
							vertical={vertical}
							recordBadges={recordBadges}
						/>
					) : null}
					{remainingFields.map((fieldInfo) => {
						const { field, value } = fieldInfo;

						return (
							<CardLabel key={field._id} label={field._displayName} icon={field._icon} vertical={vertical}>
								<DisplayFieldFactory key={field._id} field={field} value={value} variant={DisplayFieldVariant.card} />
							</CardLabel>
						);
					})}
				</div>
				<RecordCardFooter
					sdRecord={sdRecord}
					associationsSummary={associationsSummary}
					vertical={vertical}
					recordBadges={recordBadges}
				/>
			</div>
			{onToggleSelect && selected !== undefined ? (
				<div
					className={clsx("absolute right-4", mediaFieldFile ? "top-2.5" : "top-4")}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Checkbox value={selected} onChange={onToggleSelect} />
				</div>
			) : !hideContextMenu ? (
				<div className="ui-open-within:opacity-100 absolute right-4 top-2.5 z-10 opacity-0 transition-opacity group-focus-within:opacity-100 group-hover:opacity-100">
					<RecordContextMenu
						sdRecord={sdRecord}
						getCustomContextMenuItems={getCustomContextMenuItems}
						allowDelete={allowDelete}
					/>
				</div>
			) : null}
		</div>
	);
}

export const RecordCard = memo(RecordCardComponent);
