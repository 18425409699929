import { getUserRecordAvatarFileId, getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../auth";
import { useUserObjectsMap } from "../../../../users";
import { ChannelMessageGroup } from "../../../types";
import { ChimeMessageGroup } from "./ChimeMessageGroup";

interface ChimeMessageGroupContainerProps {
	messageGroup: ChannelMessageGroup;
	onDeleteMessage?: (messageId: string) => void;
}

export function ChimeMessageGroupContainer({ messageGroup, onDeleteMessage }: ChimeMessageGroupContainerProps) {
	const principal = useWebPrincipal();
	const userObjectMap = useUserObjectsMap();

	const userObject = messageGroup.userRecord != null ? userObjectMap?.get(messageGroup.userRecord._objectDefId) : null;
	const user = messageGroup.userId
		? {
				id: messageGroup.userId,
				fullName:
					userObject && messageGroup.userRecord ? getSDRecordName(userObject, messageGroup.userRecord) || "" : "",
				avatarFileId: getUserRecordAvatarFileId(userObject, messageGroup.userRecord),
			}
		: undefined;

	return (
		<ChimeMessageGroup
			isLocalParticipant={principal.UserRecordId === user?.id}
			user={user}
			messages={messageGroup.messages}
			displayTimestamp={messageGroup.displayTimestamp}
			onDeleteMessage={onDeleteMessage}
		/>
	);
}
