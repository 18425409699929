import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import { InlineUserLoading } from "../../../../../../../users";

export function SkeletonComment() {
	return (
		<div className="flex flex-col gap-1 py-2">
			<InlineUserLoading />
			<div className="flex flex-col gap-2 pl-8">
				<Skeleton className="h-5 w-10/12" />
				<div className="mb-1 flex flex-col gap-1">
					<Skeleton className="h-6 w-11/12" />
					<Skeleton className="h-6 w-2/3" />
				</div>
			</div>
		</div>
	);
}
