import { useEffect, useState } from "react";
import { matchPath, useParams } from "react-router-dom";

import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { PATHS } from "../../../routes";
import { useGetObjectById } from "../../../hooks/useGetObjectById";
import { useWebPrincipal } from "../../../auth";
import { AbilityAction, AbilitySubject, sdSubject } from "@salesdesk/salesdesk-schemas";

/**
 * Redirects users once authorised based on user type and the workspace links they're accessing.
 * Done outside of the router to avoid unnecessary redirects and to keep the loading screen
 * consistent.
 *
 * SalesDesk users are redirected to the workspace databoard if they try and access
 * the '/workspaces' page as this is a customer only page to show which workspaces that particular
 * customer has access to.
 *
 * If a customer user only has access to a single workspace and are trying to access the root path or
 * '/workspaces' page, they are redirected directly to the workspace page they have access to.
 */
export function useUserWorkspaceRedirect(isAuthorised?: boolean) {
	const principal = useWebPrincipal();

	const isSalesDeskUser = isAuthorised ? principal.IsSalesDeskUser : false;
	const authorizedWorkspaceIds = isAuthorised ? principal.AuthorizedWorkspaceIds : null;

	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);

	const [redirectComplete, setRedirectComplete] = useState(false);

	const onWorkspacePath = matchPath(window.location.pathname, PATHS.WORKSPACES());
	const onRootPath = matchPath(window.location.pathname, PATHS.ROOT());
	const stringWorkspaceId = useParams<{ workspaceId: string }>().workspaceId;
	const workspaceId = stringWorkspaceId ? Number(stringWorkspaceId) : undefined;

	useEffect(() => {
		if (redirectComplete || !isAuthorised) {
			return;
		}

		// If a SalesDesk user tries to access the workspaces page, they are redirected to the workspace databoard
		if (isSalesDeskUser && onWorkspacePath && workspaceObject) {
			window.router.navigate(PATHS.OBJECT_RECORD_BOARD(workspaceObject));
			setRedirectComplete(true);
			return;
		}
		// Otherwise if the SalesDesk user is not accessing the workspaces page or the customer user is not
		// accessing the root/workspaces page, no redirect is needed
		else if (isSalesDeskUser) {
			setRedirectComplete(true);
			return;
		}

		if (
			workspaceId != null &&
			!principal.can(AbilityAction.View, sdSubject(AbilitySubject.Record, { _id: workspaceId }))
		) {
			window.router.navigate(PATHS.WORKSPACES_ERROR());
		}

		// Redirect to the workspace page if the user only has access to that one workspace
		else if (authorizedWorkspaceIds && authorizedWorkspaceIds.length === 1 && (onRootPath || onWorkspacePath)) {
			window.router.navigate(PATHS.WORKSPACE_OVERVIEW(authorizedWorkspaceIds[0]));
		}

		setRedirectComplete(true);
	}, [
		isAuthorised,
		redirectComplete,
		workspaceObject,
		isSalesDeskUser,
		onWorkspacePath,
		onRootPath,
		authorizedWorkspaceIds,
		workspaceId,
		principal,
	]);

	return redirectComplete;
}
