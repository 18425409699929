import { tw } from "../../../utils/tailwind-helpers";
import { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";

interface EditBarProps extends PropsWithChildren {
	show: boolean;
}

export function EditBar({ show, children }: EditBarProps) {
	return (
		<div className="pointer-events-none absolute bottom-0 left-0 right-0">
			<Transition
				as="div"
				show={show}
				enterFrom={tw`opacity-0 bottom-8`}
				enterTo={tw`opacity-100 bottom-16`}
				leaveFrom={tw`opacity-100 bottom-16`}
				leaveTo={tw`opacity-0 bottom-8`}
				className="bg-c_bg_06 text-c_text_inverted shadow-popover text-body-sm pointer-events-auto relative z-[100] mx-auto flex w-fit items-center justify-center gap-4 rounded-full px-4 py-1.5 transition-all"
			>
				{children}
			</Transition>
		</div>
	);
}
