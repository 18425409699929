import clsx from "clsx";

interface VolumeBarProps {
	volume: number;
	maxHeight?: number;
	dampening?: number;
}

const DEFAULT_MAX_HEIGHT = 18;

export function VolumeBar({ volume, dampening = 1, maxHeight = DEFAULT_MAX_HEIGHT }: VolumeBarProps) {
	const randomNoise = Math.random() * 0.8;
	const height = Math.max(3, Math.min(maxHeight, (1 - Math.pow(1 - volume, dampening)) * maxHeight + randomNoise));
	return (
		<div
			className={clsx(
				volume ? "duration-[20ms]" : "duration-0",
				"bg-c_icon_inverted h-[3px] w-[3px] rounded-full transition-all ease-out"
			)}
			style={{ height: `${height}px` }}
		/>
	);
}
