import { asConst } from "json-schema-to-ts";
import { idSchema } from "../components";

export const userSentimentSchema = asConst({
	title: "Sentiment Analysis Response",
	description: "ML analysis for a media record",
	type: "object",
	additionalProperties: false,
	properties: {
		faceSentiment: {
			title: "Face Sentiment",
			description: "Face-based sentiment",
			type: "string",
		},
		transcriptSentiment: {
			title: "Transcript Sentiment",
			description: "Transcript-based sentiment",
			type: "string",
		},
		userId: {
			...idSchema,
			title: "User ID",
			description: "ID of the user to whom the sentiment data corresponds",
		},
	},
});
