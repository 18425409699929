import { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useToggle } from "../../../../../hooks/useToggle";
import { useUnreadMessageHandler } from "../../../hooks/useUnreadMessageHandler";
import { useChannelsSelector } from "../../../store/selectors/useChannelsSelector";
import { ChatChannelViewContainer } from "../../ChatChannelView/components/ChatChannelViewContainer";
import { MessagingPanelContainer } from "../../MessagingPanelContainer";
import { ChannelBanner } from "./Channel";
import { CreateChannelView } from "./CreateChannelView";
import { ChannelListView } from "./ChannelListView";
import { MessagingFilterId } from "../types";
import { useMessagingUrlParams } from "../../../hooks";
import { useToast } from "../../../../Toasts";
import { ChannelInfoView } from "./ChannelInfoView";
import { useChimeMessagingContext } from "../../providers";
import { ChimeChannelErrorView } from "../../ChatChannelView";

export function MultiChannelMessagingPanel() {
	const toast = useToast();

	const { initialisationFailed } = useChimeMessagingContext();

	const [open, onToggleOpen, setOpen] = useToggle(false);
	const [autofocusInput, setAutofocusInput] = useState(false);
	const [creatingChannel, setCreatingChannel] = useState(false);
	const [viewingChannelInfo, setViewingChannelInfo] = useState(false);

	const onViewChannelInfo = useCallback(() => {
		setViewingChannelInfo(true);
	}, []);

	// Don't want to autofocus the input while the opening animation is playing as it
	// can cause scroll issues
	useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				setAutofocusInput(true);
			}, 250);

			return () => clearTimeout(timer);
		}

		setAutofocusInput(false);
	}, [open]);

	const channels = useChannelsSelector();
	const [currentChannelArn, setCurrentChannelArn] = useState<string>();

	const currentChannel = useMemo(
		() => channels?.find((channel) => channel.arn === currentChannelArn),
		[channels, currentChannelArn]
	);

	const { channelArn: urlChannelArn, clearParams } = useMessagingUrlParams();

	// Optimistically opens the messaging panel on the specific channel
	useEffect(() => {
		if (!urlChannelArn) {
			return;
		}

		setCreatingChannel(false);
		setViewingChannelInfo(false);
		setOpen(true);
		setCurrentChannelArn(urlChannelArn);
		clearParams();
	}, [clearParams, setOpen, urlChannelArn]);

	const hasLoadedChannels = Boolean(channels?.length);
	const hasCurrentChannel = Boolean(currentChannel);

	// Return to channel page if you can't load the current channel
	useEffect(() => {
		if (currentChannelArn && hasLoadedChannels && !hasCurrentChannel) {
			const timer = setTimeout(() => {
				toast.trigger("error", "Could not load chat channel");
				setCurrentChannelArn(undefined);
			}, 2000); // 2 seconds grace period

			return () => clearTimeout(timer);
		}
	}, [hasLoadedChannels, hasCurrentChannel, toast, currentChannelArn]);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [messagingFilters, setMessagingFilters] = useState<MessagingFilterId[]>([]);

	const { messagingPanelChildren, isChatChannelViewContainerVisible } = useMemo(() => {
		let isChatChannelViewContainerVisible = false;

		if (initialisationFailed) {
			return {
				messagingPanelChildren: (
					<ChimeChannelErrorView
						message="Failed to connect to messaging service"
						onRetry={() => window.location.reload()}
						retryButtonText="Refresh page"
					/>
				),
				isChatChannelViewContainerVisible,
			};
		}

		const messagingPanelChildren = (() => {
			if (creatingChannel) {
				return (
					<CreateChannelView
						onCancel={() => setCreatingChannel(false)}
						onChannelCreated={(channelArn) => {
							setCurrentChannelArn(channelArn);
							setCreatingChannel(false);
						}}
					/>
				);
			}

			if (!currentChannelArn) {
				return (
					<ChannelListView
						searchQuery={searchQuery}
						onSearchQueryChange={setSearchQuery}
						messagingFilters={messagingFilters}
						onMessagingFilterChange={setMessagingFilters}
						onChannelSelected={setCurrentChannelArn}
					/>
				);
			}

			if (viewingChannelInfo && currentChannel) {
				return (
					<ChannelInfoView
						channelDetails={currentChannel}
						onBack={() => {
							setViewingChannelInfo(false);
						}}
					/>
				);
			}

			isChatChannelViewContainerVisible = true;
			return (
				<>
					{currentChannel ? (
						<ChannelBanner
							channelDetails={currentChannel}
							onBack={() => {
								setCurrentChannelArn(undefined);
							}}
							onViewChannelInfo={onViewChannelInfo}
						/>
					) : null}
					<ChatChannelViewContainer channelDetails={currentChannel} autofocusInput={autofocusInput} />
				</>
			);
		})();

		return { messagingPanelChildren, isChatChannelViewContainerVisible };
	}, [
		initialisationFailed,
		creatingChannel,
		currentChannelArn,
		viewingChannelInfo,
		currentChannel,
		onViewChannelInfo,
		autofocusInput,
		searchQuery,
		messagingFilters,
	]);

	const { hasUnreadMessages, newMessageKey } = useUnreadMessageHandler({
		channels,
		currentOpenChannelArn: isChatChannelViewContainerVisible && open && currentChannel ? currentChannelArn : undefined,
	});

	return (
		<MessagingPanelContainer
			open={open}
			onToggleOpen={onToggleOpen}
			showUnreadDot={hasUnreadMessages}
			newMessageKey={newMessageKey}
			headerElements={
				<Tooltip text="New message">
					<Button
						variant="text_inverted"
						startIcon={ICONS.notePencil}
						size="sm"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							setOpen(true);
							setCreatingChannel(true);
						}}
					/>
				</Tooltip>
			}
		>
			{messagingPanelChildren}
		</MessagingPanelContainer>
	);
}
