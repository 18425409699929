import { useEffect, useRef } from "react";

import { useVideoCallLogicContext } from "../../../../../../../VideoCallProvider";
import { useTriggerVideoCallEvent } from "../../../../../../../VideoCallEvents";
import { useWebPrincipal } from "../../../../../../../../../../auth";

export function useMessageReceivedVideoCallEvent() {
	const { UserRecordId } = useWebPrincipal();
	const { sortedChatMessages } = useVideoCallLogicContext();
	const triggerVideoCallEvent = useTriggerVideoCallEvent();

	const lastReceivedMessageTimestamp = useRef(Date.now());

	useEffect(() => {
		if (!sortedChatMessages.length) return;

		const lastMessage = sortedChatMessages[sortedChatMessages.length - 1];

		if (
			lastMessage &&
			lastMessage.createdAt > lastReceivedMessageTimestamp.current &&
			lastMessage.createdBy !== UserRecordId
		) {
			triggerVideoCallEvent("received_message");
			lastReceivedMessageTimestamp.current = lastMessage.createdAt;
		}
	}, [sortedChatMessages, UserRecordId, triggerVideoCallEvent]);
}
