import { useMemo } from "react";

import { Tooltip, tw } from "@salesdesk/daisy-ui";
import { getSDRecordSystemFieldValueByFieldName, TimeRange } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { DateTimeFieldVariant, DisplayTimeRangeField } from "../../../../../../fields";
import { getHuddleMeetingDetails, HuddleStatus } from "../../../../../../meetings/utils";
import { BaseCompactRecordPreviewProps } from "../../types";
import { CompactRecordPreviewContainer, CompactRecordPreviewIcon } from "../common";

export function CompactMeetingPreview({ sdRecord, sdObject, variant }: BaseCompactRecordPreviewProps) {
	const meetingTimeFieldValue = useMemo(() => {
		return (getSDRecordSystemFieldValueByFieldName(sdObject, sdRecord, mInteractionMeetingDef.TIME_FIELD_NAME)
			?._value ?? null) as null | TimeRange;
	}, [sdObject, sdRecord]);

	const { icon, colorClasses, isHuddle, huddleStatus } = useMemo(() => {
		const huddleMeetingDetails = getHuddleMeetingDetails(sdObject, sdRecord);
		const { isHuddle, huddleStatus } = huddleMeetingDetails;

		let icon = sdObject._icon;
		let colorClasses;

		if (isHuddle) {
			icon = ICONS.videoCamera;

			if (huddleStatus === HuddleStatus.IN_PROGRESS) {
				colorClasses = tw`bg-c_success_04 text-c_success_03`;
			} else if (huddleStatus === HuddleStatus.INACTIVE) {
				colorClasses = tw`bg-c_bg_disabled_02 text-c_icon_disabled`;
			}
		}

		return { icon, colorClasses, isHuddle, huddleStatus };
	}, [sdObject, sdRecord]);

	return (
		<CompactRecordPreviewContainer
			icon={<CompactRecordPreviewIcon icon={icon} colorClasses={colorClasses} />}
			sdRecord={sdRecord}
			sdObject={sdObject}
			variant={variant}
		>
			<div className="text-c_text_placeholder flex flex-col truncate">
				{!isHuddle ? (
					<Tooltip text={sdObject._displayName} showOnTruncated>
						<span className="truncate">{sdObject._displayName}</span>
					</Tooltip>
				) : null}
				{isHuddle && huddleStatus !== HuddleStatus.INACTIVE ? (
					<span className="text-c_action_01 text-label-xs">Join now</span>
				) : (
					<DisplayTimeRangeField value={meetingTimeFieldValue} variant={DateTimeFieldVariant.DATE_TIME} />
				)}
			</div>
		</CompactRecordPreviewContainer>
	);
}
