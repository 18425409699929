import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useContext, createContext } from "react";

interface LeadToOpportunityContextProps {
	setLeadSDRecord?: (record?: SDRecord) => void;
}

const initialValues: LeadToOpportunityContextProps = {
	setLeadSDRecord: undefined,
};

export const LeadToOpportunityContext = createContext<LeadToOpportunityContextProps>(initialValues);

export function useLeadToOpportunityContext(): LeadToOpportunityContextProps {
	const context = useContext(LeadToOpportunityContext);

	if (context === undefined) {
		throw new Error("useLeadToOpportunityContext must be used within a LeadToOpportunityContext.Provider");
	}

	return context;
}
