import { Fragment, ReactNode, useMemo } from "react";
import { Transition } from "@headlessui/react";

import { Button, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Divider } from "../../../../../../../components/Divider/Divider";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { useDocumentCommentsContext } from "../../../hooks/useDocumentCommentsContext";
import { SkeletonComment } from "./CommentThread/SkeletonComment";
import { CommentThread } from "./CommentThread/CommentThread";
import { DeleteCommentDialog } from "./DeleteCommentDialog";
import { ResolveCommentDialog } from "./ResolveCommentDialog";
import { CommentTypeFilter } from "./CommentTypeFilter";
import { useDeleteComment, useEditComment, useResolveComment, useToggleCommentReaction } from "../hooks";

export function CommentPanel() {
	const workspaceId = useGetContextWorkspaceId();
	const inWorkspaceContext = workspaceId != null;

	const {
		documentCommentThreads,
		documentRecord,
		isLoadingComments,
		isCommentsOpen,
		setCommentsOpen,
		showResolvedComments,
	} = useDocumentCommentsContext();

	const displayedDocumentCommentThreads = useMemo(() => {
		return documentCommentThreads?.filter((thread) => {
			const isResolved = thread.comments[0]?.resolvedAt != null;
			return showResolvedComments ? isResolved : !isResolved;
		});
	}, [documentCommentThreads, showResolvedComments]);

	const documentRecordId = documentRecord?._id;

	const { commentToBeDeleted, handleDeleteCommentConfirmation, setCommentToBeDeleted, isDeletingComment } =
		useDeleteComment(documentRecordId);

	const { onEditComment, onEditSubmit, editingCommentId } = useEditComment(documentRecordId);

	const { handleResolveCommentConfirmation, commentToResolve, setCommentToResolve, isResolvingComment } =
		useResolveComment(documentRecordId);

	const onToggleCommentReaction = useToggleCommentReaction(documentRecordId);

	const openClasses = tw`translate-x-0 shrink-0 ${inWorkspaceContext ? "w-[344px]" : "w-[364px]"}`;
	const closeClasses = tw`translate-x-full w-0`;

	return (
		<Transition
			show={Boolean(isCommentsOpen)}
			className="border-c_border_regular flex max-h-full min-h-full transform flex-col overflow-clip border-l transition-all"
			enter={tw`ease-out`}
			enterFrom={closeClasses}
			enterTo={openClasses}
			leave={tw`ease-in`}
			leaveFrom={openClasses}
			leaveTo={closeClasses}
		>
			<div className="flex w-[340px] shrink-0 flex-col gap-4 pb-4 pl-2">
				<div className="text-label-sm flex items-center gap-3">
					<Button variant="text" size="xs" startIcon={ICONS.caretLeft} onClick={() => setCommentsOpen?.(false)} />
					Comments
				</div>
				<CommentTypeFilter />
			</div>
			<div className="flex h-full max-h-full w-[340px] flex-col gap-4 overflow-auto pb-14 pl-2">
				{isLoadingComments ? (
					renderWithDividers([...Array(3)], (_, index) => <SkeletonComment key={index} />)
				) : displayedDocumentCommentThreads?.length ? (
					renderWithDividers(displayedDocumentCommentThreads, (thread) => (
						<CommentThread
							key={thread.id}
							thread={thread}
							editingCommentId={editingCommentId}
							onEditComment={onEditComment}
							onEditSubmit={onEditSubmit}
							onDeleteComment={(comment, inDocumentText) => setCommentToBeDeleted({ comment, inDocumentText })}
							onResolveComment={(comment, documentText) => setCommentToResolve({ comment, documentText })}
							onToggleCommentReaction={onToggleCommentReaction}
						/>
					))
				) : (
					<div className="text-c_text_placeholder text-body-sm mt-3 text-center">
						{showResolvedComments
							? "There are no resolved comments yet."
							: "There are no comments yet, add one to the document by selecting the text you want to comment on."}
					</div>
				)}
			</div>
			<DeleteCommentDialog
				comment={commentToBeDeleted?.comment}
				inDocumentText={commentToBeDeleted?.inDocumentText}
				isDeleting={isDeletingComment}
				onConfirm={handleDeleteCommentConfirmation}
				onDismiss={() => setCommentToBeDeleted(undefined)}
			/>
			<ResolveCommentDialog
				comment={commentToResolve?.comment}
				inDocumentText={commentToResolve?.documentText}
				isResolving={isResolvingComment}
				onConfirm={handleResolveCommentConfirmation}
				onDismiss={() => setCommentToResolve(undefined)}
			/>
		</Transition>
	);
}

function renderWithDividers<T>(items: T[], renderItem: (item: T, index: number) => ReactNode) {
	return items.map((item, index) => (
		<Fragment key={index}>
			{renderItem(item, index)}
			{index < items.length - 1 && <Divider className="m-0" />}
		</Fragment>
	));
}
