export const UniqueFieldType = {
	None: "None",
	Object: "Object",
	Global: "Global",
} as const;

export type UniqueFieldType = (typeof UniqueFieldType)[keyof typeof UniqueFieldType];

export const FIELD_UNIQUE_INFO = [
	{
		id: UniqueFieldType.None,
		name: "None",
	},
	{
		id: UniqueFieldType.Object,
		name: "All records for this object",
	},
	{
		id: UniqueFieldType.Global,
		name: "All records with this field name",
	},
];
