import { useEffect, useMemo } from "react";

import { useChannelsSelector } from "../../store/selectors/useChannelsSelector";
import { useWebPrincipal } from "../../../../auth";
import { ReadOnlyCredentialsChimeMessagingProvider } from "../providers";
import { DefaultSingleChannelMessagingPanel } from "./DefaultSingleChannelMessagingPanel";
import { ReadOnlySingleChannelMessagingPanel } from "./ReadOnlySingleChannelMessagingPanel";
import { useMessagingUrlParams } from "../../hooks";

interface SingleChannelMessagingPanelProps {
	open: boolean;
	onToggleOpen: () => void;
	channelArn?: string;
}

export function SingleChannelMessagingPanel({ open, onToggleOpen, channelArn }: SingleChannelMessagingPanelProps) {
	const principal = useWebPrincipal();

	const channels = useChannelsSelector();
	const currentChannel = useMemo(() => channels?.find((channel) => channel.arn === channelArn), [channels, channelArn]);

	const { channelArn: urlChannelArn, clearParams } = useMessagingUrlParams();

	useEffect(() => {
		if (urlChannelArn !== channelArn) {
			return;
		}

		clearParams();

		if (!open) {
			onToggleOpen();
		}
	}, [channelArn, clearParams, onToggleOpen, open, urlChannelArn]);

	if (!channels || currentChannel || !channelArn) {
		return (
			<DefaultSingleChannelMessagingPanel open={open} onToggleOpen={onToggleOpen} currentChannel={currentChannel} />
		);
	} else if (principal.IsSalesDeskUser) {
		return (
			<ReadOnlyCredentialsChimeMessagingProvider channelArn={channelArn}>
				<ReadOnlySingleChannelMessagingPanel channelArn={channelArn} open={open} onToggleOpen={onToggleOpen} />
			</ReadOnlyCredentialsChimeMessagingProvider>
		);
	}

	return null;
}
