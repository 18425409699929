import { ReactNode } from "react";

import { getSDObjectFields, SDObject } from "@salesdesk/salesdesk-schemas";

import { ObjectPreviewFieldFactory } from "../ObjectPreviewFieldFactory";

interface ObjectPreviewProps {
	sdObject: SDObject;
}

export function ObjectPreview({ sdObject }: ObjectPreviewProps) {
	const objectFields = getSDObjectFields(sdObject);
	const columnSize = Math.floor(objectFields.length / 2);

	const previewFields = objectFields.reduce(
		(previewFieldsComponents, objectField, fieldIndex) => {
			previewFieldsComponents[fieldIndex <= columnSize ? 0 : 1].push(
				<ObjectPreviewFieldFactory key={objectField._id} field={objectField} />
			);
			return previewFieldsComponents;
		},
		[[], []] as [ReactNode[], ReactNode[]]
	);

	return (
		<div className="mx-6 mt-4 flex justify-center gap-16">
			{previewFields.map((previewField, index) => (
				<div key={index} className="flex w-full max-w-[480px] flex-1 flex-col gap-6">
					{previewField}
				</div>
			))}
		</div>
	);
}
