import { ReactNode, forwardRef } from "react";

import { InputValidationState } from "@salesdesk/daisy-ui";
import { combineRefs, InputContainer } from "@salesdesk/daisy-ui";

import { FileInputProps } from "../../../types";
import { FileInputUpload } from "./FileInputUpload";
import { FileInputEmpty } from "./FileInputEmpty";
import { FileInputPreview } from "./FileInputPreview";
import { useFileInputController } from "../hooks";
import { useSdFile } from "../../../../files/hooks/useSdFile";

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
	(
		{
			id,
			value,
			onChange,
			onBlur,
			ariaAttributes,
			hasError,
			supportedMimeTypes = "*",
			maxFileSizeInBytes,
			disabled,
			uploadProgress,
			showFileBorder = true,
			variant = "base",
			filePreviewOverride,
		},
		ref
	) => {
		const {
			fileValue,
			draggingOver,
			innerRef,
			canUpdateFile,
			clearFile,
			updateFile,
			handleDragEnter,
			handleDragExit,
			handleDrop,
		} = useFileInputController({
			value,
			onChange,
			disabled,
			uploadProgress,
			supportedMimeTypes,
			maxFileSizeInBytes,
		});

		const { sdFile, isLoading } = useSdFile(typeof fileValue === "number" ? fileValue : null);

		const file = value && typeof value !== "number" ? value : sdFile;

		const hasBorder = showFileBorder || !file;

		let fileInputContents: ReactNode;
		if (uploadProgress != null) {
			fileInputContents = <FileInputUpload uploadProgress={uploadProgress} variant={variant} />;
		} else if (file || isLoading) {
			if (filePreviewOverride) {
				fileInputContents = filePreviewOverride;
			} else {
				fileInputContents = (
					<FileInputPreview file={file} isLoadingSdFile={isLoading} onClear={clearFile} hasBorder={hasBorder} />
				);
			}
		} else {
			fileInputContents = <FileInputEmpty onBrowseFiles={() => innerRef.current?.click()} disabled={disabled} />;
		}

		return (
			<InputContainer
				active={draggingOver && canUpdateFile}
				validationState={hasError ? InputValidationState.error : InputValidationState.initial}
				disabled={disabled}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragExit}
				onDrop={handleDrop}
				onDragOver={(e) => e.preventDefault()}
				transparentBorder={!hasBorder}
				className={variant === "fullPage" ? "h-full" : undefined}
			>
				<input
					id={id}
					type="file"
					ref={combineRefs([innerRef, ref])}
					{...ariaAttributes}
					className="hidden"
					accept={supportedMimeTypes}
					disabled={!canUpdateFile}
					onChange={(e) => {
						updateFile(e.target.files?.item(0));
					}}
					onBlur={onBlur}
				/>
				{fileInputContents}
			</InputContainer>
		);
	}
);
