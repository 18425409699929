import { asConst } from "json-schema-to-ts";
import { noPropertiesSchema } from "../../../components";

export const isSharedWithAnyWorkspaceClauseSchema = asConst({
	title: "Is Shared with Any Workspace Clause",
	description: "Will match any record that is shared with any workspace record Id.",
	type: "object",
	additionalProperties: false,
	required: ["isSharedWithAnyWorkspace"],
	properties: {
		isSharedWithAnyWorkspace: noPropertiesSchema,
	},
	nullable: false,
});
