import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

import { tw } from "../../../../../utils/tailwind-helpers";
import { useGetVideoCallToastContents } from "../hooks/useGetVideoCallToastContents";
import { VideoCallEvent } from "../types";

interface VideoCallToastProps {
	event: VideoCallEvent;
}

export function VideoCallToast({ event }: VideoCallToastProps) {
	const [isShowing, setIsShowing] = useState(false);

	const toastContents = useGetVideoCallToastContents(event);

	useEffect(() => {
		setIsShowing(!event.dismissed);
	}, [event.dismissed]);

	return (
		<div className="relative select-none">
			<Transition show={isShowing && Boolean(toastContents)}>
				{/* Used to animate space taken up by toast to smoothly animate all the toasts above it */}
				<Transition.Child
					className={"text-label invisible flex w-full items-center gap-3 px-4 py-2 transition-all"}
					enter={tw`ease-out duration-150`}
					enterFrom={tw`h-0`}
					enterTo={tw`h-14`}
					leave={tw`ease-in duration-100 h-14`}
				>
					{toastContents}
				</Transition.Child>
				<Transition.Child
					className={
						"shadow-popover text-label bg-c_bg_tooltip text-c_text_inverted absolute bottom-0 left-0 flex h-12 w-full items-center gap-3 rounded-md px-4 py-2 transition-all"
					}
					enter={tw`ease-in duration-150`}
					enterFrom={tw`-translate-x-8 opacity-0`}
					enterTo={tw`translate-x-0 opacity-100`}
					leave={tw`ease-out duration-100`}
					leaveFrom={tw`opacity-100`}
					leaveTo={tw`opacity-0`}
				>
					{toastContents}
				</Transition.Child>
			</Transition>
		</div>
	);
}
