import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions/textAppenders";
import { userIdSchema } from "../../components/userIdSchema";

export const objectLastModifiedBySchema = asConst({
	...userIdSchema,
	title: "SDObject Last Modified By",
	description: appendNotEditable("The user that last modified the SDObject"),
	nullable: true,
});
