import { PropsWithChildren } from "react";

interface ControlTooltipProps {
	customOffset?: string;
}

export function ControlPopover({ children, customOffset = "-0.25rem" }: PropsWithChildren<ControlTooltipProps>) {
	return (
		<div className="pb-3" style={{ marginBottom: customOffset }}>
			{/* This parent div is required to avoid losing focus when hovering over the progress bar (the progress bar also triggers a popover). */}
			<div className="bg-c_bg_tooltip text-c_text_inverted flex flex-col rounded-sm bg-opacity-60 py-2">{children}</div>
		</div>
	);
}
