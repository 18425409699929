import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import {
	AbilityAction,
	AbilitySubject,
	RecordQueryClauses,
	SDRecord,
	sdSubject,
	TimeRange,
} from "@salesdesk/salesdesk-schemas";

import { CalendarEntry } from "../types";
import { WeekView } from "./views/WeekView";
import { CalendarTopBar } from "./CalendarTopBar";
import { isRangeField } from "../utils/calendarField";

import { CURRENT_TIME_INDICATOR_ID, sortCalendarEntries } from "../utils";
import { useWebPrincipal } from "../../../../../../auth";
import { getCurrentPathWithRecordIdParam, useInfiniteRecordSearch } from "../../../../../records";
import { APP_CONFIG } from "../../../../../../app/app_config";
import { generateBoardRecord } from "../../../../utils";
import { RECORD_COMBINED_NAME_ID } from "../../../../types";
import { useObjectBoardSearchParams } from "../../../../hooks/useObjectBoardSearchParams";
import { useCreateRecordDialogContext } from "../../../../../records/components/RecordCreate/hooks/useCreateRecordDialogContext";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";
import { useBoardCalendarDateSelector, useBoardGroupBySelector } from "../../../../store";

// Source: https://stackoverflow.com/a/12666128
const MAX_JAVASCRIPT_DATE_MILLISECONDS = 8_640_000_000_000_000;

export function CalendarView() {
	const principal = useWebPrincipal();

	const { sdObject, boardFieldMap, workspaceId } = useDataboardDetailsContext();
	const boardGroupBy = useBoardGroupBySelector();
	const calendarDate = useBoardCalendarDateSelector();

	const isWorkspaceBoard = workspaceId != null;

	const [topBarHeading, setTopBarHeading] = useState<string>();

	const [calendarRecords, setCalendarRecords] = useState<SDRecord[]>([]);
	const [calendarFilter, setCalendarFilter] = useState<RecordQueryClauses[]>();

	const searchParams = useObjectBoardSearchParams(calendarFilter);

	const [calendarEntries, setCalendarEntries] = useState<CalendarEntry[]>([]);

	const { openModal: openRecordCreateDialog } = useCreateRecordDialogContext();

	const handleEntryCreate = useCallback(
		(initialValueMap: Record<string, unknown>) => {
			if (
				!sdObject ||
				!principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id }))
			) {
				return;
			}
			openRecordCreateDialog({
				sdObject,
				initialValueMap,
			});
		},
		[principal, sdObject, openRecordCreateDialog]
	);

	const { updateSearchParams, loadNextPage, isLastPage, isLoadingNewSearchParams, isLoadingNextPage } =
		useInfiniteRecordSearch({
			limit: APP_CONFIG.maxLocalSearchResults,
			sdRecords: calendarRecords,
			onSDRecordsChange: setCalendarRecords,
			sdObjectFilter: sdObject?._id,
		});

	useEffect(() => {
		if (searchParams) {
			updateSearchParams(searchParams);
		}
	}, [searchParams, updateSearchParams]);

	// Fetches all filtered records for the current calendar view
	useEffect(() => {
		if (!isLastPage && !(isLoadingNewSearchParams || isLoadingNextPage)) {
			loadNextPage();
		}
	}, [isLastPage, isLoadingNewSearchParams, isLoadingNextPage, loadNextPage]);

	const hasLoadedAllData = isLastPage && !(isLoadingNewSearchParams || isLoadingNextPage);
	const groupByField = boardFieldMap.get(boardGroupBy || "");
	const hasGroupByField = Boolean(groupByField);

	// Scrolls to current time when first initialised
	useEffect(() => {
		if (!hasGroupByField) {
			return;
		}

		const currentTimeIndicator = document.getElementById(CURRENT_TIME_INDICATOR_ID);

		if (currentTimeIndicator) {
			currentTimeIndicator.scrollIntoView({ block: "center" });
		}
	}, [hasGroupByField]);

	useEffect(() => {
		if (!sdObject || !groupByField) {
			setCalendarEntries([]);
			return;
		}

		const isRange = isRangeField(groupByField);

		const unsortedEntries: CalendarEntry[] = [];
		for (const record of calendarRecords) {
			const recordDetails = generateBoardRecord(record, sdObject);

			const fieldValue = recordDetails[groupByField._id]?._value;

			// Skip the entry if no value set
			if (fieldValue == null) {
				continue;
			}

			const name = (recordDetails[RECORD_COMBINED_NAME_ID]?._value as string) || "";

			let start: Date;
			let end: Date | undefined = undefined;

			if (isRange) {
				const value = fieldValue as TimeRange;
				// If start is not set, use the earliest date possible in JavaScript which is 271,822 B.C
				start = value.start != null ? new Date(value.start) : new Date(-MAX_JAVASCRIPT_DATE_MILLISECONDS);
				// If end date is not set in range, use the latest date possible in JavaScript which is the year 275,760
				end = value.end != null ? new Date(value.end) : new Date(MAX_JAVASCRIPT_DATE_MILLISECONDS);
			} else {
				start = new Date(fieldValue as string);
			}

			unsortedEntries.push({
				id: record._id,
				name,
				start,
				end,
				getRecordLink: getCurrentPathWithRecordIdParam,
				recordDetails,
			});
		}

		setCalendarEntries(sortCalendarEntries(unsortedEntries));
	}, [calendarRecords, sdObject, hasLoadedAllData, groupByField]);

	const isLoading = !hasLoadedAllData;

	return (
		<div
			className={clsx(
				"flex h-full flex-col transition-opacity",
				groupByField ? "opacity-100" : "opacity-0",
				!isWorkspaceBoard && "overflow-hidden"
			)}
		>
			<CalendarTopBar heading={topBarHeading || ""} isLoading={isLoading} calendarDate={calendarDate} />
			<WeekView
				calendarDate={calendarDate}
				calendarEntries={calendarEntries}
				updateCalendarFilter={setCalendarFilter}
				setTopBarHeading={setTopBarHeading}
				groupByField={groupByField}
				isLoading={isLoading}
				onEntryCreate={handleEntryCreate}
				isWorkspaceBoard={isWorkspaceBoard}
			/>
		</div>
	);
}
