import { Disclosure } from "@headlessui/react";
import { PropsWithChildren, ReactNode } from "react";
import { Icon, AlertBubble } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { Droppable, DroppableProvided, DroppableStateSnapshot } from "@hello-pangea/dnd";

interface ListHeaderProps extends PropsWithChildren {
	title: string;
	defaultOpen?: boolean;
	unmount?: boolean;
	className?: string;
	count?: number;
	sum?: ReactNode;
	sumLabel?: string;
	showZero?: boolean;
	stickyHeader?: boolean;
	stickyHeaderClassName?: string;
	droppableId?: string;
}

export function CollapsibleList({
	count,
	unmount,
	className,
	defaultOpen = true,
	children,
	stickyHeader = false,
	droppableId,
	...rest
}: ListHeaderProps) {
	return (
		<Disclosure defaultOpen={defaultOpen}>
			{({ open }) => {
				const sharedProps = {
					...rest,
					open,
					count,
					stickyHeader,
				};

				return (
					<div className={clsx(stickyHeader && "contents")}>
						{droppableId && (!open || !count) ? (
							<Droppable droppableId={droppableId}>
								{(provided, snapshot) => (
									<CollapsibleListHeader {...sharedProps} provided={provided} snapshot={snapshot} />
								)}
							</Droppable>
						) : (
							<CollapsibleListHeader {...sharedProps} />
						)}
						<Disclosure.Panel unmount={unmount} className={className}>
							{children}
						</Disclosure.Panel>
					</div>
				);
			}}
		</Disclosure>
	);
}

interface CollapsibleListHeaderProps extends ListHeaderProps {
	open: boolean;
	provided?: DroppableProvided;
	snapshot?: DroppableStateSnapshot;
}

function CollapsibleListHeader({
	open,
	provided,
	snapshot,
	title,
	count,
	sum,
	sumLabel,
	showZero,
	stickyHeader,
	stickyHeaderClassName,
}: CollapsibleListHeaderProps) {
	return (
		<div className={clsx(stickyHeader && "sticky left-0", stickyHeaderClassName)}>
			<Disclosure.Button
				ref={provided?.innerRef}
				{...provided?.droppableProps}
				className={clsx(
					"bg-c_bg_02 rounded-minimal flex w-full items-center justify-between gap-2 border px-4 py-2",
					snapshot?.isDraggingOver ? "border-c_action_focus" : "border-transparent"
				)}
			>
				<div className="flex items-center gap-2">
					<Icon icon={open ? ICONS.caretUp : ICONS.caretDown} className="text-c_icon_regular flex items-center" />
					<div className="text-label">{title}</div>
					{count !== undefined ? (
						<AlertBubble alertCount={count} variant="tertiary" size="lg" showZero={showZero} />
					) : null}
				</div>
				{sum !== undefined && sumLabel ? (
					<div className="flex gap-2">
						<div className="text-label text-c_text_secondary">{sumLabel}:</div>
						<div className="text-label text-c_text_primary">{sum}</div>
					</div>
				) : null}
			</Disclosure.Button>
		</div>
	);
}
