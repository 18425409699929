import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ColumnSizingInfoState, ColumnSizingState, OnChangeFn } from "@tanstack/react-table";

import { throttle } from "../../../utils";
import { ControlColumnDetails, EndColumnDetails, TableColumnId } from "../types";
import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from "../utils";

/**
 * Hook for handling logic of resizing table columns.
 *
 * Each column has a set width that the table keeps track of which can be resized by the user.
 *
 * However, in order to ensure the table always fills up the entirety of its container the end column
 * also has a 'visual width' which is used to keep track of how wide the end column must be displayed
 * in order to fill up any remaining space in the table's container.
 *
 * If the table fills the entire container then the actual width of the end column is used when
 * displaying the end column.
 */
export function useResizableTableColumns<T>(
	initialContainerXPadding = 0,
	controlColumn?: ControlColumnDetails<T>,
	defaultColumnWidthByColumnId?: Record<string, number>
) {
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const [tableContainerXPadding] = useState(initialContainerXPadding);

	const [tableContainerWidth, setTableContainerWidth] = useState(0);
	const [endColumnDetails, setEndColumnDetails] = useState<EndColumnDetails>({ id: null, visualWidth: 0 });

	const updateEndColumnDetails = useCallback((id: TableColumnId, visualWidth: number) => {
		setEndColumnDetails({ id: String(id), visualWidth });
	}, []);

	const [columnSizingInfo, setColumnSizingInfo] = useState<ColumnSizingInfoState>({
		startOffset: null,
		startSize: null,
		deltaOffset: null,
		deltaPercentage: null,
		isResizingColumn: false,
		columnSizingStart: [],
	});

	const onColumnSizingInfoChange: OnChangeFn<ColumnSizingInfoState> = (updaterOrValue) => {
		const sizingInfo = typeof updaterOrValue === "function" ? updaterOrValue(columnSizingInfo) : updaterOrValue;

		if (endColumnDetails && sizingInfo.isResizingColumn === endColumnDetails.id) {
			const startSize = endColumnDetails.visualWidth;
			sizingInfo.startSize = startSize;
			sizingInfo.columnSizingStart = [[endColumnDetails.id, startSize]];
		}

		setColumnSizingInfo(sizingInfo);
	};

	const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});

	const minEndColumnWidth = MIN_COLUMN_WIDTH + (controlColumn?.width || 0);

	const onColumnSizingChange: OnChangeFn<ColumnSizingState> = useCallback(
		(updaterOrValue) => {
			const updatedSizing = typeof updaterOrValue === "function" ? updaterOrValue(columnSizing) : updaterOrValue;

			const endColumnId = endColumnDetails.id;
			const updateEndColumnWidth =
				endColumnId && updatedSizing[endColumnId] !== undefined && updatedSizing[endColumnId] < minEndColumnWidth;

			setColumnSizing(updateEndColumnWidth ? { ...updatedSizing, [endColumnId]: minEndColumnWidth } : updatedSizing);
		},
		[columnSizing, endColumnDetails.id, minEndColumnWidth]
	);

	useEffect(() => {
		const endColumnId = endColumnDetails.id;

		if (!endColumnId) return;

		const defaultWidth = defaultColumnWidthByColumnId?.[endColumnId] ?? DEFAULT_COLUMN_WIDTH;
		const endColumnWidth = Math.max(minEndColumnWidth, defaultWidth + (controlColumn?.width || 0));

		setColumnSizingInfo((prev) => {
			return { ...prev, [endColumnId]: endColumnWidth };
		});
	}, [endColumnDetails.id, controlColumn?.width, defaultColumnWidthByColumnId, minEndColumnWidth]);

	useLayoutEffect(() => {
		const tableContainer = tableContainerRef?.current;

		if (!tableContainer) return;

		const observer = new ResizeObserver(
			throttle((resizeEntry: ResizeObserverEntry[]) => {
				if (!resizeEntry.length) {
					return;
				}

				setTableContainerWidth(Math.max(resizeEntry[0].target.clientWidth - Math.max(tableContainerXPadding, 1), 0));
			}, 50)
		);
		observer.observe(tableContainer);

		return () => {
			observer.disconnect();
		};
	}, [tableContainerXPadding]);

	return {
		tableContainerRef,
		columnSizingInfo,
		onColumnSizingInfoChange,
		columnSizing,
		onColumnSizingChange,
		tableContainerWidth,
		endColumnDetails,
		updateEndColumnDetails,
	};
}
