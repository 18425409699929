import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { mLeadDef, mContactDef, mInteractionMeetingDef, mWorkspaceDef, mAssetDef } from "@salesdesk/salesdesk-model";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { ActionCard, ActionCardProps } from "./ActionCard";
import { useOnboardingContext } from "../../hooks";
import { useGetObjectMap } from "../../../objects/hooks";
import { PATHS, useStableNavigate } from "../../../../routes";
import { OnboardingFlow } from "../../utils";

export function DashboardOnboardingSection() {
	const navigate = useStableNavigate();

	const { handleCreateWorkspace, startFlow } = useOnboardingContext();

	const objectMap = useGetObjectMap();

	const actionCardsInfo: ActionCardProps[] = useMemo(() => {
		const leadSDObject = objectMap.get(mLeadDef.ID);
		const workspaceSDObject = objectMap.get(mWorkspaceDef.ID);
		const meetingSDObject = objectMap.get(mInteractionMeetingDef.ID);
		const assetSDObject = objectMap.get(mAssetDef.ID);
		const contactSDObject = objectMap.get(mContactDef.ID);

		if (!leadSDObject || !workspaceSDObject || !meetingSDObject || !assetSDObject || !contactSDObject) return [];

		return [
			{
				imageUrl: "/static/images/onboarding/collaborate-with-lead.svg",
				titleIcon: ICONS.database,
				title: capitalizeString(leadSDObject._pluralName),
				description: `Capture and organise your ${capitalizeString(
					leadSDObject._pluralName
				)} effortlessly in your SalesDesk dashboard`,
				onClick: () => startFlow(OnboardingFlow.ADD_LEAD_FROM_DASHBOARD),
				buttonIcon: ICONS.plus,
				buttonText: `Add a ${capitalizeString(leadSDObject._displayName)}`,
				secondaryButtonText: `Import ${capitalizeString(leadSDObject._pluralName)}`,
				secondaryButtonIcon: ICONS.download,
				secondaryButtonClick: () => navigate(PATHS.IMPORT(leadSDObject)),
			},
			{
				imageUrl: "/static/images/onboarding/record-a-lead.svg",
				titleIcon: workspaceSDObject._icon,
				title: capitalizeString(workspaceSDObject._pluralName),
				description: `Team up with your ${capitalizeString(leadSDObject._pluralName)} and ${capitalizeString(
					contactSDObject._pluralName
				)} in a collaborative ${capitalizeString(workspaceSDObject._displayName)}`,
				buttonIcon: ICONS.plus,
				buttonText: `Create a ${capitalizeString(workspaceSDObject._displayName)}`,
				onClick: handleCreateWorkspace,
			},
			{
				imageUrl: "/static/images/onboarding/watch-meeting-recording.svg",
				titleIcon: ICONS.videoCamera,
				title: `${capitalizeString(meetingSDObject._displayName)} recording & analysis`,
				description: `Watch ${meetingSDObject._displayName.toLowerCase()} recordings, explore transcripts, and dive into insightful analysis`,
				buttonIcon: ICONS.folders,
				buttonText: `Explore ${capitalizeString(assetSDObject._pluralName)}`,
				onClick: () => {
					navigate(PATHS.OBJECT_RECORD_BOARD(assetSDObject));
				},
			},
		];
	}, [objectMap, handleCreateWorkspace, navigate, startFlow]);

	return (
		<div className="border-c_border_regular rounded-panel grid grid-cols-3 gap-8 border p-6">
			{actionCardsInfo.map((actionCardInfo) => (
				<ActionCard key={actionCardInfo.title} {...actionCardInfo} />
			))}
		</div>
	);
}
