import { forwardRef } from "react";
import clsx from "clsx";

import { tw } from "../../../../../../utils/tailwind-helpers";
import { Icon } from "@salesdesk/daisy-ui";

interface PickerButtonProps {
	icon: string;
	hasError?: boolean;
	disabled?: boolean;
	active?: boolean;
}

export const IconSwatch = forwardRef<HTMLButtonElement, PickerButtonProps>(
	({ icon, hasError, disabled, active, ...buttonProps }: PickerButtonProps, ref) => {
		let colorClasses = "";
		if (disabled) {
			colorClasses = tw`bg-c_bg_disabled_02 text-c_text_disabled border-c_bg_disabled_02`;
		} else if (hasError) {
			colorClasses = tw`border-c_danger_02 focus-visible:border-c_danger_02 focus-visible:ring-c_danger_focus`;
		} else {
			colorClasses = clsx(
				tw`active:bg-c_bg_02 focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 hover:bg-c_bg_05`,
				active ? tw`ring-c_action_focus ring border-c_bg_01` : tw`border-c_bg_02`
			);
		}

		return (
			<button
				type="button"
				ref={ref}
				{...buttonProps}
				className={clsx(
					disabled ? "cursor-not-allowed" : "transition",
					colorClasses,
					"bg-c_bg_02 flex min-w-[40px] items-center justify-center rounded-full border p-2 focus-visible:ring"
				)}
				disabled={disabled}
			>
				<Icon className={clsx(disabled ? tw`text-c_icon_disabled` : tw`text-c_icon_regular`, "flex")} icon={icon} />
			</button>
		);
	}
);
