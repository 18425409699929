import { Logo } from "../../../components/Logo";

interface TenantPreviewProps {
	accountName: string;
	accountLogoUrl?: string;
	isInternalUser: boolean;
}

export function TenantPreview({ accountName, accountLogoUrl, isInternalUser }: TenantPreviewProps) {
	return (
		<div className="flex w-full items-center gap-2 overflow-hidden p-3">
			<Logo size="md" companyName={accountName} image={accountLogoUrl} />
			<div className="flex flex-col items-start overflow-hidden">
				<h3 className="text-label text-c_text_primary max-w-full truncate">{accountName}</h3>
				<p className="text-body-sm text-c_text_secondary max-w-full truncate">{isInternalUser ? "User" : "Customer"}</p>
			</div>
		</div>
	);
}
