import { NumberParam, withDefault } from "use-query-params";

import { WorkspaceResourcesState } from "../types";
import { useURLState } from "../../../../../../hooks";

export const DEFAULT_RESOURCES_STATE: WorkspaceResourcesState = {
	tab: 0,
	recordId: undefined,
};

const RESOURCES_STATE_VALUE_VALIDITY: Record<keyof WorkspaceResourcesState, (value: unknown) => boolean> = {
	tab: (value: unknown) => typeof value === "number",
	recordId: (value: unknown) => typeof value === "number",
};

export function useWorkspaceResourcesState() {
	const { urlState: workspaceResourcesState, propOnChange: workspaceResourcesPropOnChange } = useURLState({
		defaultState: DEFAULT_RESOURCES_STATE,
		valueValidityMap: RESOURCES_STATE_VALUE_VALIDITY,
		paramConfigMap: {
			tab: withDefault(NumberParam, DEFAULT_RESOURCES_STATE.tab),
			recordId: withDefault(NumberParam, DEFAULT_RESOURCES_STATE.recordId),
		},
	});

	return { workspaceResourcesState, workspaceResourcesPropOnChange };
}
