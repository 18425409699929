import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

import { RecordSidePanelContent } from "./RecordSidePanelContent";
import { useGetRecordWithObjectByRecordId } from "../../../../../../../hooks";
import { useOutsideClick } from "../../../../../../../hooks/useOutsideClick";
import { useRecordLoadedEvent } from "../../../../../hooks";
import { RecordDetailsProvider } from "../../RecordDetailsProvider";
import { RecordSidePanelLoading } from "./RecordSidePanelLoading";
import { useToast } from "../../../../../../Toasts";
import { useObjectAssociations } from "../../../../../../recordAssociations";
import { filterVisibleWorkspaceAssociations, useWorkspaceContext } from "../../../../../../workspaces";
import { useWebPrincipal } from "../../../../../../../auth";
import { throttle } from "../../../../../../../utils";

export const RECORD_SIDE_PANEL_TRIGGER_CLASS = "side-panel-record-instance";

interface RecordSidePanelProps {
	recordId?: number;
	onClose: (event?: MouseEvent) => void;
}

export function RecordSidePanel({ recordId, onClose }: RecordSidePanelProps) {
	const { workspaceId } = useWorkspaceContext();

	const principal = useWebPrincipal();

	const [currentRecordId, setStoredRecordId] = useState<number | undefined>(recordId);
	const toast = useToast();

	// Timeout so that the right hand panel contents remain displayed during
	// the closing animation.
	useEffect(() => {
		let updateTimeout: NodeJS.Timeout;

		if (recordId === undefined) {
			updateTimeout = setTimeout(() => {
				setStoredRecordId(recordId);
			}, 300);
		} else {
			setStoredRecordId(recordId);
		}
		return () => {
			if (updateTimeout) {
				clearTimeout(updateTimeout);
			}
		};
	}, [recordId]);

	const { sdRecord, sdObject, isLoading: isLoadingRecord } = useGetRecordWithObjectByRecordId(currentRecordId);

	const {
		objectAssociations: { prioritizedAssociations: tabbedAssociations, unprioritizedAssociations: groupedAssociations },
		isLoading: isLoadingAssociations,
		isError: isAssociationsError,
	} = useObjectAssociations(sdRecord?._objectDefId);

	const { filteredTabbedAssociations, filteredGroupedAssociations } = useMemo(() => {
		if (workspaceId == null) {
			return { filteredTabbedAssociations: tabbedAssociations, filteredGroupedAssociations: groupedAssociations };
		}

		const isSalesDeskUser = principal.IsSalesDeskUser;

		return {
			filteredTabbedAssociations: filterVisibleWorkspaceAssociations(tabbedAssociations, isSalesDeskUser),
			filteredGroupedAssociations: filterVisibleWorkspaceAssociations(groupedAssociations, isSalesDeskUser),
		};
	}, [workspaceId, tabbedAssociations, groupedAssociations, principal.IsSalesDeskUser]);
	const isLoading = isLoadingRecord || isLoadingAssociations;

	const closePanelOnError = useMemo(() => {
		return throttle(() => {
			toast.trigger("error", "Failed to load record");
			onClose();
		}, 200);
	}, [onClose, toast]);

	useEffect(() => {
		if ((!sdRecord && !isLoadingRecord && currentRecordId) || isAssociationsError) {
			closePanelOnError();
		}
	}, [isLoadingRecord, sdRecord, currentRecordId, isAssociationsError, closePanelOnError]);

	useRecordLoadedEvent(recordId);

	const outsideClickRef = useOutsideClick<HTMLDivElement>(onClose, RECORD_SIDE_PANEL_TRIGGER_CLASS);

	return (
		<div
			ref={outsideClickRef}
			className={clsx(
				clsx(
					recordId ? "translate-x-0 duration-150 ease-out" : "translate-x-full duration-200 ease-in-out",
					workspaceId != null ? "fixed top-[112px]" : "absolute top-4",
					"bg-c_bg_01 rounded-tl-panel shadow-panel_left bottom-0 right-0 z-[51] w-full max-w-[560px]",
					"border-t-c_border_regular border-l-c_border_regular border-l border-t transition-transform"
				)
			)}
		>
			<RecordDetailsProvider sdRecord={sdRecord} sdObject={sdObject} isLoading={isLoading}>
				{currentRecordId == null ? null : isLoading || isAssociationsError ? (
					<RecordSidePanelLoading />
				) : (
					<RecordSidePanelContent
						onClose={onClose}
						tabbedAssociations={filteredTabbedAssociations}
						groupedAssociations={filteredGroupedAssociations}
					/>
				)}
			</RecordDetailsProvider>
		</div>
	);
}
