import { useUsersRuleValues } from "../../../../hooks/record/useUsersRuleValues";
import { RuleElement } from "../RuleElement";
import { RuleElementType } from "../../../../types";
import { useNotificationRuleDialogContext } from "../../../../hooks/useNotificationRuleDialogContext";
import { DownArrowIcon } from "../DownArrowIcon";

export function UsersRuleElement() {
	const { selectedRuleConfig } = useNotificationRuleDialogContext();
	const userRuleValues = useUsersRuleValues();

	return !selectedRuleConfig || !selectedRuleConfig.userIdsNotRequired ? (
		<>
			<DownArrowIcon />
			<RuleElement type={RuleElementType.by} separatorText="or" values={userRuleValues} />
		</>
	) : null;
}
