import { useCallback, useEffect, useState } from "react";

import { SELECTED_AUDIO_OUTPUT_KEY } from "../utils";
import { useDevices } from "./useDevices";

export function useLocalAudioOutputDeviceId(skip: boolean) {
	const { audioOutputDevices } = useDevices(skip);
	const [localAudioOutputDeviceId, _setLocalAudioOutputDeviceId] = useState<string | null>("default");

	const setLocalAudioOutputDeviceId = useCallback((sinkId: string) => {
		window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
		_setLocalAudioOutputDeviceId(sinkId);
	}, []);

	useEffect(() => {
		const audioOutputDeviceId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
		const hasSelectedAudioOutputDevice = audioOutputDevices.some(
			(device) => audioOutputDeviceId && device.deviceId === audioOutputDeviceId
		);
		if (hasSelectedAudioOutputDevice && audioOutputDeviceId != null) {
			_setLocalAudioOutputDeviceId(audioOutputDeviceId);
		}
	}, [audioOutputDevices]);

	return [localAudioOutputDeviceId, setLocalAudioOutputDeviceId] as const;
}
