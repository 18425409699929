import { useCallback, useMemo } from "react";
import { LocalAudioTrack, Room } from "twilio-video";

import { SdEventType } from "@salesdesk/salesdesk-model";
import { getMeetingRecordIdFromRoomName } from "@salesdesk/salesdesk-utils";

import { debounce } from "../../../../../../../utils";
import { useTriggerVideoCallEvent } from "../../../../VideoCallEvents";
import { useIsTrackEnabled } from "./useIsTrackEnabled";
import { useVideoCallMediaContext } from "./useVideoCallMediaContext";
import { usePostEvent } from "../../../../../../events/hooks/usePostEvent";

export function useLocalAudioToggle(room: Room | null) {
	const { localTracks } = useVideoCallMediaContext();
	const audioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;
	const isEnabled = useIsTrackEnabled(audioTrack);
	const postEvent = usePostEvent();

	const triggerVideoCallEvent = useTriggerVideoCallEvent();

	const debounceAudioToggleEvent = useMemo(
		() =>
			debounce(
				(roomName: string, event_type: SdEventType) => {
					triggerVideoCallEvent(
						event_type === SdEventType.MICROPHONE_ENABLED ? "microphone_enabled" : "microphone_disabled"
					);

					postEvent({
						event_type,
						record_id: getMeetingRecordIdFromRoomName(roomName),
						params: {},
					});
				},
				200,
				true
			),
		[triggerVideoCallEvent, postEvent]
	);

	const toggleAudioEnabled = useCallback(() => {
		if (!audioTrack) {
			return;
		}

		let event_type: SdEventType;

		if (audioTrack.isEnabled) {
			event_type = SdEventType.MICROPHONE_DISABLED;
			audioTrack.disable();
		} else {
			event_type = SdEventType.MICROPHONE_ENABLED;
			audioTrack.enable();
		}

		if (room !== null) {
			debounceAudioToggleEvent(room.name, event_type);
		}
	}, [audioTrack, room, debounceAudioToggleEvent]);

	return [isEnabled, toggleAudioEnabled] as const;
}
