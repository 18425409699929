import { BOARD_VIEW, BOARD_VIEW_OPTIONS } from "@salesdesk/salesdesk-model";

import { ButtonSelect } from "../../../../inputs";
import { BoardViewSelectProps } from "../types";
import { useAppStateContext } from "../../../../users/hooks/useAppStateContext";
import { getGroupableFieldsByForView } from "../../../utils/defaultBoardFields";
import { useBoardViewSelector } from "../../../store";
import { useBoardPropOnChangeContext } from "../../../hooks/useBoardPropOnChangeContext";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";

export function BoardViewSelect({ iconOnly, sdObject }: BoardViewSelectProps) {
	const { appState } = useAppStateContext();

	const boardView = useBoardViewSelector();
	const boardPropOnChange = useBoardPropOnChangeContext();
	const { groupableFields } = useDataboardDetailsContext();

	return (
		<ButtonSelect
			iconOnly={iconOnly}
			value={boardView ?? appState.boardState?.[sdObject._id]?.view ?? sdObject._defaultView ?? BOARD_VIEW.TABLE}
			onChange={(view) => {
				const newView = view ? (view as BOARD_VIEW) : undefined;
				boardPropOnChange("view", newView, true);
				boardPropOnChange("displayedFields", undefined);

				const groupableFieldsForView = getGroupableFieldsByForView(newView, groupableFields);
				boardPropOnChange("groupBy", groupableFieldsForView.length ? String(groupableFieldsForView[0]._id) : undefined);
			}}
			options={BOARD_VIEW_OPTIONS}
			optionsTitle="Select view"
		/>
	);
}
