import { Button } from "@salesdesk/daisy-ui";

import { Auth } from "../../../auth";
import { AlertDialog } from "../../Dialog";

interface Props {
	open: boolean;
	onDismiss: () => void;
}

export function LogOutConfirmationDialog({ open, onDismiss }: Props) {
	return (
		<AlertDialog
			title="Log out"
			open={open}
			message={<p className="min-w-[433px]">Are you sure you wish to log out of SalesDesk?</p>}
			onOpenChange={(open) => {
				if (!open) {
					onDismiss();
				}
			}}
		>
			<div className="flex items-center justify-end gap-3">
				<Button size="lg" onClick={onDismiss} variant="secondary">
					Cancel
				</Button>
				<Button onClick={Auth.logout} size="lg">
					Log out
				</Button>
			</div>
		</AlertDialog>
	);
}
