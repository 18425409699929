import { createContext, useContext } from "react";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { MeetingPageState } from "../types";

export interface IMeetingPageContext {
	meetingId: SDRecord["_id"];
	isLoadingMeetingRecord: boolean;
	meetingPageState: MeetingPageState;
	meetingObject?: SDObject;
	meetingRecord?: SDRecord;
	participantRecords?: SDRecord[];
	joinMeetingTimeStamp?: number;
	joinMeeting: () => void;
	leaveMeeting: () => void;
	rejoinMeeting: () => void;
}

export const MeetingPageContext = createContext<IMeetingPageContext | null>(null);

export const useMeetingPageContext = () => {
	const context = useContext(MeetingPageContext);

	if (context == null) {
		throw new Error("Meeting Page Container components must be wrapped in <MeetingPage />");
	}

	return context;
};
