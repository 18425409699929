import {
	ImportJob,
	ImportJobStatus,
	ImportMappingEntryCreateRequest,
	SDError,
	SDErrorCode,
} from "@salesdesk/salesdesk-schemas";

export const IMPORT_STEPS = (isOnExecutionPage = false) => [
	"Step 1: Upload file",
	"Step 2: Map columns",
	isOnExecutionPage ? "Import" : "Preview",
];

export enum ImportStep {
	upload = 0,
	mapping = 1,
	execution = 2,
}

export function getTotalProcessedRows(importJob: ImportJob) {
	return importJob.createdCount + importJob.patchedCount + importJob.errorCount;
}

export function getTotalRows(importJob: ImportJob) {
	return importJob.rowCount;
}

export function getCompletionPercentage(importJob: ImportJob) {
	const totalProcessedRows = getTotalProcessedRows(importJob);
	const totalRows = getTotalRows(importJob);
	return Math.round((totalProcessedRows / totalRows) * 100);
}

export function getValidImportRowCount(importJob: ImportJob | undefined) {
	if (!importJob) return 0;
	return importJob.createdCount + importJob.patchedCount;
}

export function isCompleteOrFailed(importJob: ImportJob | undefined) {
	if (!importJob) return false;
	return importJob.status === ImportJobStatus.Completed || importJob.status === ImportJobStatus.Failed;
}

export function hasMappingDuplication(mappingEntries: ImportMappingEntryCreateRequest[]) {
	const fieldIds = mappingEntries.map((entry) => entry.fieldId);
	return new Set(fieldIds).size !== fieldIds.length;
}

export function getErrorMessageFromCode(errorCode?: SDErrorCode, defaultMessage?: string) {
	if (errorCode === SDErrorCode.ImportMappingMissingRequiredFields) return "Please map all required fields.";
	if (errorCode === SDErrorCode.ImportMappingFieldDuplication) return "Please map each field only once.";
	return defaultMessage ?? "Something went wrong. Please try again.";
}

export function getErrorMessage(sdError: SDError | undefined) {
	return getErrorMessageFromCode(sdError?.errorCode, sdError?.description);
}
