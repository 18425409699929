import { asConst } from "json-schema-to-ts";
import { FieldIdRegexp, TimeRangeFieldIdRegexp } from "./recordQueryClausePropertyValueSchema";
import { sdRecordSummarySchema } from "../../sdRecordSummarySchema";

export const recordQueryClausePropertyNameStringSchema = asConst({
	anyOf: [
		{
			type: "string",
			pattern: `^(${sdRecordSummarySchema.required.join(")|(")})$`,
		},
		{
			type: "string",
			pattern: FieldIdRegexp,
		},
		{
			type: "string",
			pattern: TimeRangeFieldIdRegexp,
		},
	],
});
