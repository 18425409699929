import { skipToken } from "@reduxjs/toolkit/query";

import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { useGetCommentQuery } from "../../../../../document";
import { useGetRecordQuery } from "../../../../../records";
import { useGetObjectById } from "../../../../../../hooks";
import { TipTapContentWrapper } from "../TipTapContentWrapper";

type LazyCommentPayloadParams = {
	recordId: number | undefined;
	commentId: string;
	createdById?: number;
	emoji?: string;
};

export function LazyCommentPayload({ recordId, commentId, createdById, emoji }: LazyCommentPayloadParams) {
	const { data: comment, isLoading: isLoadingComments } = useGetCommentQuery(
		recordId ? { recordId, commentId } : skipToken
	);
	const { data: user, isLoading: isLoadingUser } = useGetRecordQuery(createdById ?? comment?.createdBy ?? skipToken);
	const { sdObject: userObject } = useGetObjectById(user?._objectDefId);

	return (
		<TipTapContentWrapper
			isLoadingCreatedUserName={isLoadingUser}
			createdUserName={userObject && user ? getSDRecordName(userObject, user) : undefined}
			isLoadingContent={isLoadingComments}
			tipTapContent={comment?.message}
			emoji={emoji}
		/>
	);
}
