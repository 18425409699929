import { useCallback, useState, useMemo, useEffect } from "react";
import { DateParam, JsonParam, NumberParam, StringParam, withDefault } from "use-query-params";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";
import { getSDObjectFieldById, SDObject } from "@salesdesk/salesdesk-schemas";

import { DeduplicatedCommaDelimetedArrayParam } from "../../../utils/query-params";

import { AGGREGATIONS, BoardState, DEFAULT_BOARD_STATE } from "../types";
import { EMPTY_FILTER_IDS, FilterData, SortingDetails, SortingOrder } from "../../records";
import { CALENDAR_VIEW } from "../components/ObjectBoardView/CalendarView";
import { useURLState } from "../../../hooks";
import { isEqual, omit } from "lodash";
import { debounce, isIntegerString } from "../../../utils";
import { usePatchMyAppStateMutation } from "../../users/api/userAppStateApi";
import { useAppStateContext } from "../../users/hooks/useAppStateContext";
import { getBookmarkIdFromLocation } from "../../bookmarks/utils";
import { BoardStateValue } from "../../users";

export function isValidFilter(value: unknown): boolean {
	if (value === undefined) {
		return true;
	}

	const type = (value as any)?.type as unknown;
	const filters = (value as any)?.filters as unknown;

	if (typeof type !== "string" || !["AND", "OR"].includes(type)) {
		return false;
	}

	if (!Array.isArray(filters) || !filters.length) {
		return false;
	}

	for (const filter of filters) {
		if (EMPTY_FILTER_IDS.some((id) => id === filter.filterId)) {
			continue;
		}
		if (
			!filter ||
			typeof filter.filterId !== "string" ||
			typeof filter.filterTarget !== "string" ||
			(typeof filter.value !== "string" &&
				typeof filter.value !== "number" &&
				typeof filter.value !== "boolean" &&
				!Array.isArray(filter.value)) ||
			(filter.value2 !== undefined &&
				typeof filter.value2 !== "string" &&
				typeof filter.value2 !== "number" &&
				typeof filter.value2 !== "boolean" &&
				!Array.isArray(filter.value2))
		) {
			return false;
		}
	}

	return true;
}

export function isValidSort(value: unknown): boolean {
	if (value === undefined) {
		return true;
	}

	if (!Array.isArray(value) || !value.length) {
		return false;
	}

	for (const sortDetails of value) {
		if (
			!sortDetails ||
			typeof sortDetails.fieldId !== "string" ||
			![SortingOrder.asc, SortingOrder.desc].includes(sortDetails.order)
		) {
			return false;
		}
	}

	return true;
}

function isValidAgg(value: unknown): boolean {
	if (value == null) {
		return true;
	}

	const aggregationKeys = AGGREGATIONS.map((aggregationDetails) => aggregationDetails.key as string);

	if (
		typeof value !== "object" ||
		!("fieldId" in value && "agg" in value) ||
		!(typeof value.fieldId === "string" && typeof value.agg === "string") ||
		!aggregationKeys.includes(value.agg)
	) {
		return false;
	}

	return true;
}

// Functions to determine if a given value for a board state property is valid
const BOARD_STATE_VALUE_VALIDITY: Record<keyof BoardState, (value: any) => boolean> = {
	view: (value: unknown) => typeof value === "string" && value in BOARD_VIEW,
	query: (value: unknown) => typeof value === "string",
	displayedFields: (value: unknown) => value === undefined || (Array.isArray(value) && value?.length > 0),
	groupBy: (value: unknown) => typeof value === "string",
	mediaField: (value: unknown) => typeof value === "string",
	date: (value: unknown) => typeof value === "number",
	calendarView: (value: unknown) => value === undefined || (typeof value === "string" && value in CALENDAR_VIEW),
	filter: isValidFilter,
	sort: isValidSort,
	agg: isValidAgg,
	recordId: (value: unknown) => typeof value === "string",
};

export function useBoardState(sdObject: SDObject) {
	const [initialPageLoad, setInitialPageLoad] = useState(true);

	const { appStateRef } = useAppStateContext();
	const [patchMyAppState] = usePatchMyAppStateMutation();

	const { urlState: boardState, propOnChange: boardPropOnChange } = useURLState({
		defaultState: DEFAULT_BOARD_STATE,
		valueValidityMap: BOARD_STATE_VALUE_VALIDITY,
		paramConfigMap: {
			view: withDefault(StringParam, DEFAULT_BOARD_STATE.view),
			query: withDefault(StringParam, DEFAULT_BOARD_STATE.query),
			displayedFields: withDefault(DeduplicatedCommaDelimetedArrayParam, DEFAULT_BOARD_STATE.displayedFields),
			groupBy: withDefault(StringParam, DEFAULT_BOARD_STATE.groupBy),
			mediaField: withDefault(StringParam, DEFAULT_BOARD_STATE.mediaField),
			date: withDefault(NumberParam, DEFAULT_BOARD_STATE.date),
			calendarView: withDefault(StringParam, DEFAULT_BOARD_STATE.calendarView),
			filter: withDefault(JsonParam, DEFAULT_BOARD_STATE.filter),
			sort: withDefault(JsonParam, DEFAULT_BOARD_STATE.sort),
			agg: withDefault(JsonParam, DEFAULT_BOARD_STATE.agg),
			recordId: withDefault(StringParam, DEFAULT_BOARD_STATE.recordId),
		},
	});

	// Waits for 150ms as query params take some time to read on first page load.
	// This allows us to distinguish between actual board state based on the
	// page URL and the initial default board state before the query params
	// have been read from the URL.
	if (initialPageLoad) {
		setTimeout(() => setInitialPageLoad(false), 150);
	}

	const resetInitialPageLoad = useCallback(() => {
		setInitialPageLoad(true);
	}, []);

	const updateAppState = useMemo(
		() =>
			debounce((boardState: BoardState) => {
				const newBoardState: BoardStateValue = {
					...omit(boardState, "recordId", "filter"),
					bookmarkId: getBookmarkIdFromLocation(),
				};
				if (
					!appStateRef.current.boardState ||
					!appStateRef.current.boardState[sdObject._id] ||
					!isEqual(appStateRef.current.boardState[sdObject._id], newBoardState)
				) {
					patchMyAppState({ boardState: { ...appStateRef.current.boardState, [sdObject._id]: newBoardState } });
				}
			}, 500),
		[appStateRef, patchMyAppState, sdObject._id]
	);

	useEffect(() => {
		updateAppState(boardState);
	}, [updateAppState, boardState]);

	const filteredFilters = useMemo(() => {
		return filterOutMissingFieldsFromFilterData(boardState.filter, sdObject);
	}, [boardState.filter, sdObject]);

	const filteredSort = useMemo(() => {
		return filterOutMissingFieldsFromSortingDetails(boardState.sort, sdObject);
	}, [boardState.sort, sdObject]);

	const filteredBoardState = useMemo(() => {
		return {
			...boardState,
			filter: filteredFilters,
			sort: filteredSort,
		};
	}, [boardState, filteredFilters, filteredSort]);

	return {
		boardState: filteredBoardState,
		boardPropOnChange,
		resetInitialPageLoad,
		initialPageLoad,
	};
}

function filterOutMissingFieldsFromFilterData(
	filters: FilterData | undefined,
	sdObject: SDObject | undefined
): FilterData | undefined {
	if (!filters || !sdObject) {
		return filters;
	}
	return {
		type: filters.type,
		filters: filters.filters.filter((filter) => {
			if (!isIntegerString(filter.filterTarget)) {
				return true;
			}
			return Boolean(getSDObjectFieldById(sdObject, Number(filter.filterTarget)));
		}),
	};
}

function filterOutMissingFieldsFromSortingDetails(sort: SortingDetails[] | undefined, sdObject: SDObject | undefined) {
	if (!sort || !sdObject) {
		return sort;
	}
	return sort.filter((sortDetails) => {
		if (!isIntegerString(sortDetails.fieldId)) {
			return true;
		}
		const field = getSDObjectFieldById(sdObject, Number(sortDetails.fieldId));
		return Boolean(field);
	});
}
