import { useMemo } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { mUserDef } from "@salesdesk/salesdesk-model";

import { useWebPrincipal } from "../../../../../auth";
import { OptionFieldComponentSettings } from "../../../../fields/components/Fields/OptionField/types";

export function useTeamMemberFieldSettings(additionalIdsToFilter?: SDRecord["_id"][]): OptionFieldComponentSettings {
	const principal = useWebPrincipal();

	return useMemo(
		() => ({
			variant: "records",
			baseObjectId: mUserDef.ID,
			filteredIds: [...(additionalIdsToFilter ?? []), principal.UserRecordId],
			onlyAuthorizedToLogIn: true,
			includeCustomerUserRecordsWhenNoWorkspace: false,
		}),
		[additionalIdsToFilter, principal.UserRecordId]
	);
}
