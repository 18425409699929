import { PropsWithChildren, ReactNode, useRef } from "react";
import clsx from "clsx";

import { Disclosure } from "@headlessui/react";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { AlertBubble, Icon } from "@salesdesk/daisy-ui";

type AccordionVariant = "primary" | "secondary";

export interface AccordionProps {
	title: string;
	helperText?: ReactNode;
	defaultOpen?: boolean;
	onFocus?: () => void;
	variant?: AccordionVariant;
	unmount?: boolean;
	count?: number;
	panelClassName?: string;
	onOpenChange?: (open: boolean) => void;
	leftElement?: ReactNode;
}

export function Accordion({
	title,
	helperText,
	defaultOpen,
	children,
	onFocus,
	variant = "primary",
	unmount = true,
	count,
	panelClassName,
	onOpenChange,
	leftElement,
}: PropsWithChildren<AccordionProps>) {
	const isOpenRef = useRef(defaultOpen ?? false);

	const isPrimary = variant === "primary";
	const isSecondary = variant === "secondary";

	return (
		<div className="flex flex-col">
			<Disclosure defaultOpen={defaultOpen}>
				{({ open }) => {
					// The hack below exists because Headless UI doesn't provide a way to listen to open state changes
					if (isOpenRef.current !== open && onOpenChange) {
						isOpenRef.current = open;
						// setTimeout is used to avoid setting a state during the render phase
						setTimeout(() => onOpenChange(open));
					}
					return (
						<>
							<Disclosure.Button
								className={clsx(
									"group flex items-center justify-between",
									isPrimary && "text-label text-c_text_primary",
									isSecondary && "text-label-sm text-c_text_secondary"
								)}
								onFocus={onFocus}
							>
								<div className="flex max-w-full flex-shrink-0 items-center gap-2">
									<Icon
										className={clsx(
											"text-c_action_01 flex transform transition",
											"group-focus-visible:ring-c_action_focus rounded-full group-focus-visible:rounded-full group-focus-visible:ring",
											open ? "rotate-90" : "rotate-0"
										)}
										icon={ICONS.caretRight}
										size={(isPrimary && "base") || (isSecondary && "sm") || "base"}
									/>
									<div className="truncate">{title}</div>
									{count !== undefined ? (
										<AlertBubble
											alertCount={count}
											inline={true}
											size={(isPrimary && "lg") || (isSecondary && "md") || "lg"}
											variant="primary"
										/>
									) : null}
									{leftElement}
								</div>
								{typeof helperText === "string" ? (
									<div className="text-c_text_secondary ml-3 truncate">{helperText}</div>
								) : (
									helperText
								)}
							</Disclosure.Button>
							<Disclosure.Panel unmount={unmount} className={panelClassName}>
								{children}
							</Disclosure.Panel>
						</>
					);
				}}
			</Disclosure>
		</div>
	);
}
