import { EventPayloadFactory } from "./EventMessage/EventPayload/EventPayloadFactory";
import { EventMessage } from "./EventMessage/EventMessage";
import { SDEvent } from "@salesdesk/salesdesk-schemas";
import { EventContext } from "../hooks/useEventContext";
import { EventTarget } from "../types";

import { useGetUserByUserId, getInitials } from "../../users";
import { RecordPreviewPopover } from "../../records";
import { Avatar } from "../../users";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { useGetContextWorkspaceId, WorkspaceName } from "../../workspaces";

interface SingleEventProps {
	event: SDEvent;
	target?: EventTarget;
}

export function SingleEvent({ event, target = "ACTIVITY" }: SingleEventProps) {
	const { user, isSystemUser } = useGetUserByUserId(event.user_id as number);

	const inWorkspaceContext = Boolean(useGetContextWorkspaceId()) && target !== "NOTIFICATION";

	return (
		<EventContext.Provider value={{ event, target }}>
			<div className="mr-2 flex gap-2 overflow-hidden">
				{user ? (
					<RecordPreviewPopover recordId={!isSystemUser ? user.id : undefined}>
						<div className="flex-shrink-0">
							<Avatar
								initials={getInitials(user.fullName)}
								avatarFileId={user.avatarFileId}
								size="xs"
								isSystemUser={isSystemUser}
							/>
						</div>
					</RecordPreviewPopover>
				) : (
					<Skeleton className="size-6 flex-shrink-0" />
				)}
				<div className="flex flex-col gap-3 overflow-hidden">
					<div className="flex flex-col gap-1 overflow-hidden">
						<EventMessage event={event} user={user} isSystemUser={isSystemUser} />
						{!inWorkspaceContext && event.workspace_id ? <WorkspaceName workspaceId={event.workspace_id} /> : null}
					</div>
					<EventPayloadFactory />
				</div>
			</div>
		</EventContext.Provider>
	);
}
