import { AriaAttributes } from "react";

import { isNumericField, mRatingFieldDef } from "@salesdesk/salesdesk-model";
import { Field } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { FieldComponentProps } from "../../../forms";
import {
	EditBooleanField,
	EditColorPicker,
	EditDateTimeField,
	EditDurationField,
	EditFileField,
	EditIconPickerField,
	EditOptionBuilderField,
	EditRatingField,
	EditRichTextField,
	EditSingleOptionField,
	EditTextField,
	EditTimeRangeField,
	FileFieldType,
	getDateTimeFieldVariant,
	getTextInputVariant,
	getTimeRangeFieldVariant,
	UploadProgressStatus,
	EditProfilePhotoField,
	EditLogoField,
	BooleanFieldVariant,
} from "../Fields";
import { EditMultiOptionField } from "../Fields/OptionField/components/EditMultiOptionField";
import { isFileField } from "../../utils/fields";

export enum EditFieldVariant {
	default = "default",
	primary = "primary",
}

export interface EditFieldFactoryProps {
	field: Field;
	componentProps: FieldComponentProps;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
	variant?: EditFieldVariant;
}

const EMPTY_ARRAY: unknown[] = [];

export function EditFieldFactory({
	field,
	componentProps,
	updateUploadProgressStatus,
	variant = EditFieldVariant.default,
}: EditFieldFactoryProps) {
	const { _componentType: component, _type: type, _required: required } = field;

	const ariaAttributes: AriaAttributes = {
		"aria-required": (required && !componentProps.readOnly) || false,
		"aria-readonly": componentProps.readOnly,

		// Labelled by, described by
		// "aria-labelledby": generateFieldLabelId(componentProps.id),
	};

	const placeholderText = field._formatDescription;
	const { inputRef, ...fieldProps } = componentProps;
	const editFieldProps = { ...fieldProps, ariaAttributes, placeholder: placeholderText || undefined };

	if (component === "text") {
		const textFieldIcon = type === "probability" || type === "progress" ? ICONS.percent : undefined;
		return (
			<EditTextField
				ref={inputRef}
				{...editFieldProps}
				type={isNumericField(type) ? "number" : undefined}
				variant={getTextInputVariant(type)}
				icon={textFieldIcon}
			/>
		);
	}
	// TODO: Replace with a single 'select' and 'multi_select' component type
	else if (component === "select" || (component === "typeahead_single_option" && type !== "icon")) {
		return (
			<EditSingleOptionField ref={inputRef} {...editFieldProps} optionFieldSettings={{ variant: "field", field }} />
		);
	} else if (component === "typeahead_single_option" && type === "icon") {
		return <EditIconPickerField ref={inputRef} {...editFieldProps} value={editFieldProps.value || null} />;
	} else if (component === "typeahead_multi_option") {
		return (
			<EditMultiOptionField
				ref={inputRef}
				{...editFieldProps}
				value={componentProps.value ?? EMPTY_ARRAY}
				optionFieldSettings={{ variant: "field", field }}
			/>
		);
	} else if (component === "date" || component === "date_time") {
		return (
			<EditDateTimeField
				ref={inputRef}
				{...editFieldProps}
				supportsPast={field._supportsPast}
				variant={getDateTimeFieldVariant(type)}
			/>
		);
	} else if (component === "time_range") {
		return (
			<EditTimeRangeField
				ref={inputRef}
				{...editFieldProps}
				supportsPast={field._supportsPast}
				variant={getTimeRangeFieldVariant(field._dateOnly)}
			/>
		);
	} else if (component === "duration") {
		return <EditDurationField ref={inputRef} {...editFieldProps} />;
	} else if (component === "rating") {
		return <EditRatingField ref={inputRef} {...editFieldProps} maxRating={mRatingFieldDef.MAX_RATING} />;
	} else if (type === "boolean") {
		const { value, onChange } = componentProps;
		return (
			<EditBooleanField
				inputRef={inputRef}
				{...editFieldProps}
				value={value}
				onChange={onChange}
				variant={component === "toggle_switch" ? BooleanFieldVariant.TOGGLE_SWITCH : BooleanFieldVariant.CHECKBOX}
			/>
		);
	} else if (type === "rich_text") {
		return <EditRichTextField ref={inputRef} {...editFieldProps} />;
	} else if (isFileField(field)) {
		let editFieldType: FileFieldType = "any";

		if (type === "image") {
			editFieldType = "image";
		} else if (type === "pdf") {
			editFieldType = "pdf";
		} else if (type === "video") {
			editFieldType = "video";
		}

		return (
			<EditFileField
				ref={inputRef}
				{...editFieldProps}
				type={editFieldType}
				updateUploadProgressStatus={updateUploadProgressStatus}
				variant={variant}
			/>
		);
	} else if (component === "profile_photo") {
		return (
			<EditProfilePhotoField
				ref={inputRef}
				{...editFieldProps}
				updateUploadProgressStatus={updateUploadProgressStatus}
			/>
		);
	} else if (component === "logo") {
		return <EditLogoField ref={inputRef} {...editFieldProps} updateUploadProgressStatus={updateUploadProgressStatus} />;
	} else if (component === "options_builder") {
		return (
			<EditOptionBuilderField {...componentProps} ariaAttributes={ariaAttributes} outcomeIds={field._outcomeIds} />
		);
	} else if (component === "color") {
		return <EditColorPicker ref={inputRef} {...editFieldProps} value={editFieldProps.value || null} />;
	}

	return null;
}
