import { useMemo } from "react";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";

import { GROUPED_VIEWS } from "../../utils";
import { GroupedView } from "./GroupedView/components/GroupedView";
import { UngroupedBoardView } from "./UngroupedView/UngroupedView";
import { CalendarView } from "./CalendarView";
import { useDataboardDetailsContext } from "../../hooks/useDataboardDetailsContext";
import { useBoardViewSelector } from "../../store";

export function ObjectBoardView() {
	const { sdObject } = useDataboardDetailsContext();
	const boardView = useBoardViewSelector();

	const objectId = sdObject?._id;

	return useMemo(() => {
		if (boardView && GROUPED_VIEWS.includes(boardView)) {
			return <GroupedView key={objectId} />;
		} else if (boardView === BOARD_VIEW.CALENDAR) {
			return <CalendarView key={objectId} />;
		}

		return <UngroupedBoardView key={objectId} />;
	}, [boardView, objectId]);
}
