import { getChimeChannelIdFromChannelArn } from "./index";

export const WORKSPACE_CHANNEL_ID_PREFIX = "Workspace-";
export const CHANNEL_READONLY_USER_SUFFIX = "Readonly";

export const isWorkspaceChannelId = (channelId: string) => channelId.startsWith(WORKSPACE_CHANNEL_ID_PREFIX);
export const isWorkspaceChannelArn = (channelArn: string) =>
	isWorkspaceChannelId(getChimeChannelIdFromChannelArn(channelArn));

export const getWorkspaceChannelId = (workspaceRecordId: number) =>
	`${WORKSPACE_CHANNEL_ID_PREFIX}${workspaceRecordId}`;

export const getWorkspaceIdFromWorkspaceChannelId = (channelId: string) => {
	const workspaceId = parseInt(
		channelId.substring(channelId.indexOf(WORKSPACE_CHANNEL_ID_PREFIX) + WORKSPACE_CHANNEL_ID_PREFIX.length)
	);
	return isNaN(workspaceId) ? null : workspaceId;
};

export const getWorkspaceChannelReadonlyUser = (workspaceRecordId: number) => ({
	chimeUserId: `${WORKSPACE_CHANNEL_ID_PREFIX}${workspaceRecordId}-${CHANNEL_READONLY_USER_SUFFIX}`,
	displayName: CHANNEL_READONLY_USER_SUFFIX,
});
