import { useCallback, useEffect, useState } from "react";

import {
	AbilityAction,
	AbilitySubject,
	BASE_SYSTEM_OBJECTS_ORDER,
	SDObject,
	sdObjectIsBaseType,
	sdObjectIsSystemType,
	sdSubject,
} from "@salesdesk/salesdesk-schemas";
import { sortByIds } from "@salesdesk/salesdesk-utils";

import { useWebPrincipal } from "../../../../../auth";
import { useGetObjectsQuery } from "../../../../objects/api/objectsApi";
import { MenuItem, SubMenuMenuItem } from "../../../../menu";
import { useRecordCreateAction } from "./useRecordCreateAction";

// Fixed reference to an empty array, otherwise each render will create a new
// empty array while the query is loading, causing the useEffect to run more
// times than necessary.
const EMPTY_ARRAY: SDObject[] = [];

type SortableMenuItem = MenuItem & {
	id: number;
};

interface SubtypeMenuItems {
	[baseType: number]: SortableMenuItem[];
}

export function useRecordCreateMenu(defaultAction: (sdObject: SDObject) => void) {
	const principal = useWebPrincipal();
	const { data: sdObjects = EMPTY_ARRAY } = useGetObjectsQuery();
	const [menuItems, setMenuItems] = useState<MenuItem[] | MenuItem[][]>([]);

	const { recordCreateActionFn, actionIsLoading } = useRecordCreateAction(defaultAction);

	useEffect(() => {
		const systemItems: SortableMenuItem[] = [];
		const subtypeItems: SubtypeMenuItems = {};
		const customObjects: SortableMenuItem[] = [];

		sdObjects.forEach((sdObject) => {
			// Only include objects which support user creation and system
			// types that are included in the base system objects list.
			if (
				!sdObject._supportsUserCreation ||
				principal.cannot(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id })) ||
				(sdObjectIsSystemType(sdObject) &&
					!BASE_SYSTEM_OBJECTS_ORDER.includes(sdObject._baseType) &&
					!BASE_SYSTEM_OBJECTS_ORDER.includes(sdObject._id))
			)
				return;

			const menuItem = {
				id: sdObject._id,
				icon: sdObject._icon,
				text: sdObject._displayName,
				onClick: () => recordCreateActionFn(sdObject),

				type: "button",
			} satisfies SortableMenuItem;

			if (sdObjectIsBaseType(sdObject) || BASE_SYSTEM_OBJECTS_ORDER.includes(sdObject._id)) {
				const itemArray = sdObjectIsSystemType(sdObject) ? systemItems : customObjects;
				itemArray.push(menuItem);
				return;
			}

			const baseType = sdObject._baseType;

			if (!subtypeItems[baseType]) {
				subtypeItems[baseType] = [];
			}

			subtypeItems[baseType].push(menuItem);
		});

		addSystemItemSubmenus(systemItems, subtypeItems);
		sortByIds(systemItems, BASE_SYSTEM_OBJECTS_ORDER);

		setMenuItems(customObjects.length ? [systemItems, customObjects] : systemItems);
	}, [principal, recordCreateActionFn, sdObjects]);

	return { menuItems, actionIsLoading };
}

const addSystemItemSubmenus = (systemItems: SortableMenuItem[], subtypeItems: SubtypeMenuItems) => {
	systemItems.forEach((item) => {
		const type = item.id;
		const currentSubtypeItems = subtypeItems[type];

		if (!currentSubtypeItems) {
			return;
		}

		const baseName = item.text;
		item.type = "subMenu";

		// Removes base type name from subtypes to reduce repetition.
		currentSubtypeItems.forEach((item) => (item.text = item.text?.replace(` ${baseName}`, "")));

		// Sorts the subtype items alphabetically
		(item as SubMenuMenuItem).subMenu = currentSubtypeItems.sort((a, b) => (a.text || "").localeCompare(b.text || ""));
	});

	return systemItems;
};
