import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { EventsSummaryQuery } from "@salesdesk/salesdesk-schemas";
import { useGetEventSummaryQuery, useGetWorkspaceEventSummaryQuery } from "../api/eventsApi";
import { useGetContextWorkspaceId } from "../../workspaces";

export function useGetEventSummary(params: EventsSummaryQuery, workspaceFilter?: number) {
	const workspaceId = useGetContextWorkspaceId();
	const effectiveWorkspaceId = workspaceFilter ?? workspaceId;

	const queryParams = useMemo(
		() => (effectiveWorkspaceId === undefined ? params : { ...params, workspaceId: effectiveWorkspaceId }),
		[params, effectiveWorkspaceId]
	);

	const isWorkspaceEvents = "workspaceId" in queryParams;

	const getEventSummary = useGetEventSummaryQuery(isWorkspaceEvents ? skipToken : queryParams);
	const getWorkspaceEventSummary = useGetWorkspaceEventSummaryQuery(isWorkspaceEvents ? queryParams : skipToken);

	return isWorkspaceEvents ? getWorkspaceEventSummary : getEventSummary;
}
