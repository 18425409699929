import { createContext, useContext } from "react";

interface DropdownTabContextType {
	isInDropdown: boolean;
}

export const DropdownTabContext = createContext<DropdownTabContextType>({ isInDropdown: false });

export function useDropdownTabContext() {
	return useContext(DropdownTabContext);
}
