import { useMemo } from "react";

import { removeElementFromIndex, replaceElementAtIndex } from "../../../../../../../utils";

import { Filter } from "../../../../../../records";
import { UserFilter } from "../../../../../../users";

import { filterMatchesUserFilterDetails, getUserFilterDetailsForBoard } from "../utils";
import { useBoardFiltersSelector } from "../../../../../store";
import { useBoardPropOnChangeContext } from "../../../../../hooks/useBoardPropOnChangeContext";
import { useDataboardDetailsContext } from "../../../../../hooks/useDataboardDetailsContext";

export function BoardUsersFilter() {
	const { sdObject: boardObject } = useDataboardDetailsContext();
	const boardPropOnChange = useBoardPropOnChangeContext();

	const boardStateFilters = useBoardFiltersSelector();

	const userFilterDetails = useMemo(() => getUserFilterDetailsForBoard(boardObject), [boardObject]);

	// Generates array of filtered users from board state
	const userFilterValue = useMemo(() => {
		const uniqueUserIds = new Set<number>();

		for (const filter of boardStateFilters.filters) {
			if (!filterMatchesUserFilterDetails(filter, userFilterDetails)) {
				continue;
			}

			// If the filters are ANDed we just take the value of the first user filter
			// to avoid things getting too complex
			if (boardStateFilters.type === "AND") {
				return filter.value as number[];
			}

			// If the filters are ORed we can combine the unique values of all the user
			// filters that exist
			if (Array.isArray(filter.value)) {
				(filter.value as number[]).forEach((id) => uniqueUserIds.add(id));
			}
		}

		return Array.from(uniqueUserIds);
	}, [boardStateFilters.filters, boardStateFilters.type, userFilterDetails]);

	const handleUserFilterChange = (selectedUserIds: number[]) => {
		const { filters } = boardStateFilters;

		let updatedFilters: Filter[] = [];
		let userFilterIndex = -1;

		// Filters out all but the first filter that matches the user filter details
		// to prevent complexity and have the board filter state match the user filter state exactly
		filters.forEach((filter) => {
			if (filter.filterTarget !== userFilterDetails.filterTarget) {
				updatedFilters.push(filter);
				return;
			}

			if (userFilterIndex !== -1) {
				return;
			}

			if (filterMatchesUserFilterDetails(filter, userFilterDetails)) {
				updatedFilters.push(filter);
				userFilterIndex = updatedFilters.length - 1;
			}
		});

		const newUserFilter = {
			filterTarget: userFilterDetails.filterTarget,
			filterId: userFilterDetails.filterId,
			value: selectedUserIds,
			value2: undefined,
		} satisfies Filter;

		if (!selectedUserIds.length) {
			// Remove the owner_id filter if it's being cleared
			if (userFilterIndex > -1) {
				updatedFilters = removeElementFromIndex(updatedFilters, userFilterIndex);
			}
		} else if (userFilterIndex > -1) {
			updatedFilters = replaceElementAtIndex(updatedFilters, userFilterIndex, newUserFilter);
		} else {
			updatedFilters.push(newUserFilter);
		}

		boardPropOnChange("filter", { ...boardStateFilters, filters: updatedFilters });
	};

	return <UserFilter title={userFilterDetails.title} value={userFilterValue} onChange={handleUserFilterChange} />;
}
