import { HasClaimConfig } from "../components/sections";

export const objectClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "objectView",
		label: "View",
		descriptions: {
			hasClaim: "Can view all object definitions",
			hasNotClaim: "Cannot view object definitions",
		},
	},
	{
		name: "objectEdit",
		label: "Add and Edit",
		dependsOn: ["objectView"],
		descriptions: {
			hasClaim: "Can add object definitions and edit all object definitions",
			hasNotClaim: "Cannot add or edit object definitions",
		},
	},
	{
		name: "objectDelete",
		label: "Delete",
		dependsOn: ["objectView"],
		descriptions: {
			hasClaim: "Can delete all object definitions",
			hasNotClaim: "Cannot delete object definitions",
		},
	},
];
