import { PALETTE } from "@salesdesk/salesdesk-ui";
import TW_COLORS from "tailwindcss/colors";

export const transcriptSentimentTypes = ["POSITIVE", "NEGATIVE", "MIXED", "NEUTRAL"] as const;

export type TranscriptSentimentType = (typeof transcriptSentimentTypes)[number];

export const transcriptSentimentColors: Record<TranscriptSentimentType, string> = {
	POSITIVE: PALETTE["green-400"],
	NEGATIVE: PALETTE["red-400"],
	MIXED: PALETTE["yellow-400"],
	NEUTRAL: PALETTE["purple-100"],
};

export const transcriptSentimentFilterColors: Record<TranscriptSentimentType, string> = {
	POSITIVE: PALETTE["green-200"],
	NEGATIVE: PALETTE["red-200"],
	MIXED: PALETTE["yellow-200"],
	NEUTRAL: PALETTE["purple-100"],
};

export interface TranscriptSentiment {
	sentiment: {
		Sentiment: TranscriptSentimentType;
	};
	startTstamp: number;
	endTstamp: number;
}

export interface UserTranscriptSentiment {
	sentiment: TranscriptSentiment[];
	userId: number;
}

export const faceSentimentTypes = ["HAPPY", "CONFUSED", "CALM", "SAD", "ANGRY", "FEAR", "SURPRISED", "DISGUSTED"];

export type FaceSentimentType = (typeof faceSentimentTypes)[number];

export interface FaceEmotion {
	Confidence: number;
	Type: FaceSentimentType;
}

export interface Face {
	Emotions: FaceEmotion[];
}

export interface FaceSentiment {
	Face: Face;
	Timestamp: number; // ms
}

export interface UserFaceSentiment {
	userId: number;
	sentiment: FaceSentiment[];
}

export type FaceSentimentDataPoint = Record<FaceSentimentType, number> & { timestamp: number };

export const faceSentimentColors: Record<FaceSentimentType, string> = {
	HAPPY: PALETTE["green-600"],
	CONFUSED: PALETTE["red-400"],
	CALM: PALETTE["purple-300"],
	SAD: TW_COLORS.cyan[600],
	ANGRY: PALETTE["red-800"],
	FEAR: PALETTE["yellow-600"],
	SURPRISED: PALETTE["neutral-400"],
	DISGUSTED: PALETTE["neutral-400"],
};

export const ZOOM_FACTORS = [1, 2, 4, 8];

export type ActiveItems = Record<string, boolean>;

export type UserIdOrPlaceholderName = number | string;
