import { useBlocker } from "react-router-dom";
import { AlertDialog } from "../index";
import { Button } from "@salesdesk/daisy-ui";

interface NavigationBlockerDialogProps {
	title: string;
	message: string;
	shouldBlock: boolean;
}

/**
 *   This is derived from react-router unstable_usePrompt to provide a custom prompt
 *   https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L1804
 */
export function NavigationBlockerDialog({ title, message, shouldBlock }: NavigationBlockerDialogProps) {
	const blocker = useBlocker(shouldBlock);

	const handleCancelClick = () => {
		if (blocker.state === "blocked") {
			blocker.reset();
		}
	};

	const handleContinueClick = () => {
		if (blocker.state === "blocked") {
			// This timeout is needed to avoid a weird "race" on POP navigations
			// between the `window.history` revert navigation and the result of
			// `window.confirm`
			setTimeout(blocker.proceed, 0);
		}
	};

	return (
		<AlertDialog
			title={title}
			open={blocker.state === "blocked"}
			message={
				<div className="flex flex-col gap-2">
					<p>{message}</p>
				</div>
			}
			onOpenChange={handleCancelClick}
		>
			<div className="flex items-center justify-end gap-3">
				<Button variant={"secondary"} size="lg" onClick={handleCancelClick}>
					Cancel
				</Button>
				<Button size="lg" onClick={handleContinueClick}>
					Continue
				</Button>
			</div>
		</AlertDialog>
	);
}
