import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/query/react";
import { EventsQuery } from "@salesdesk/salesdesk-schemas";

import { useGetEventsQuery, useGetWorkspaceEventsQuery } from "../api/eventsApi";
import { useGetContextWorkspaceId } from "../../workspaces";

export function useGetEvents(params: EventsQuery, workspaceFilter?: number) {
	const workspaceId = useGetContextWorkspaceId();
	const effectiveWorkspaceId = workspaceFilter ?? workspaceId;

	const queryParams = useMemo(
		() => (effectiveWorkspaceId === undefined ? params : { ...params, workspaceId: effectiveWorkspaceId }),
		[effectiveWorkspaceId, params]
	);

	const isWorkspaceEvents = "workspaceId" in queryParams;
	const getEvents = useGetEventsQuery(isWorkspaceEvents ? skipToken : queryParams);
	const getWorkspaceEvents = useGetWorkspaceEventsQuery(isWorkspaceEvents ? queryParams : skipToken);

	return isWorkspaceEvents ? getWorkspaceEvents : getEvents;
}
