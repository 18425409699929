import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useEffect, useRef } from "react";
import { combineRefs } from "@salesdesk/daisy-ui";

interface TrackedInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	programaticOnChange: (newValue: string) => void;
}

/**
 * Input which enables programmatic changes of its value to be detected by
 * other react components by overriding the getter and setter functions of the
 * input's value property.
 *
 * Extremely useful to detect when a third party library (e.g. Headless UI) is
 * changing the value of the input programmatically, which doesn't trigger an
 * input event.
 *
 * Source: https://stackoverflow.com/a/58585971
 */
export const TrackedInput = forwardRef<HTMLInputElement, TrackedInputProps>(
	({ onChange, programaticOnChange, ...props }, ref) => {
		const innerInputRef = useRef<HTMLInputElement>(null);
		const programmaticOnChangeRef = useRef<(newValue: string) => void>();

		programmaticOnChangeRef.current = programaticOnChange;

		useEffect(() => {
			if (!innerInputRef.current) {
				return;
			}

			const inputElement = innerInputRef.current;

			const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(inputElement), "value");

			if (!descriptor) {
				return;
			}

			Object.defineProperty(inputElement, "value", {
				set(...args) {
					if (!descriptor.set) {
						return null;
					}

					if (programmaticOnChangeRef.current) {
						programmaticOnChangeRef.current(args[0]);
					}

					return descriptor.set.apply(this, args);
				},
				get() {
					if (!descriptor.get) {
						return null;
					}

					return descriptor.get.apply(this);
				},
			});
		}, []);

		return <input ref={combineRefs([innerInputRef, ref])} onChange={onChange} {...props} />;
	}
);
