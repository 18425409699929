import clsx from "clsx";
import CheckmarkSVG from "../../static/images/checkmark.svg?react";
import { tw } from "../../utils/tailwind-helpers";

export enum CheckmarkVariant {
	default = "default",
	display = "display",
}

interface CheckmarkProps {
	value?: boolean;
	isIndeterminate?: boolean;
	disabled?: boolean;
	variant?: CheckmarkVariant;
}

const CheckmarkVariantClasses = {
	[CheckmarkVariant.default]: {
		background: tw`bg-c_action_01`,
		classes: tw`peer-focus-visible:border-c_brand_primary peer-focus-visible:ring-c_action_focus border-c_action_01`,
	},
	[CheckmarkVariant.display]: {
		background: tw`bg-c_text_secondary`,
		classes: tw`border-c_text_secondary`,
	},
};

export function Checkmark({ value, isIndeterminate, disabled, variant = CheckmarkVariant.default }: CheckmarkProps) {
	const checkedOrIndeterminate = value || isIndeterminate;

	return (
		<div
			className={clsx(
				checkedOrIndeterminate
					? disabled
						? "bg-c_bg_disabled_01"
						: CheckmarkVariantClasses[variant].background
					: "bg-c_bg_01",
				disabled && "border-c_border_btn cursor-not-allowed",
				CheckmarkVariantClasses[variant].classes,
				"inline-flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-sm border-[3px] border-solid peer-focus-visible:outline-none peer-focus-visible:ring"
			)}
		>
			{isIndeterminate ? (
				<span className={clsx(disabled ? "bg-c_bg_disabled_02" : "bg-c_text_inverted", "h-[3px] w-9/12 rounded-sm")} />
			) : value ? (
				<CheckmarkSVG className={disabled ? "fill-c_bg_disabled_02" : "fill-c_text_inverted"} />
			) : null}
		</div>
	);
}
