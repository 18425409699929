import { Field } from "@salesdesk/salesdesk-schemas";
import { createUniqueId, stringContainsMatch } from "@salesdesk/salesdesk-utils";
import { CURRENCIES_DATA } from "@salesdesk/salesdesk-model";

import { SelectOption, SelectOptionId } from "../../../../../inputs";
import { COUNTRIES_DATA } from "./data";
import { BaseOptionFieldSettings } from "../types";

const CURRENCY_OPTIONS: SelectOption[] = CURRENCIES_DATA.map(({ icon, id, name }) => ({ icon, id, name }));

const CURRENCY_OPTIONS_ID_MAP = generateOptionMap(CURRENCY_OPTIONS);

const COUNTRIES_OPTIONS: SelectOption[] = COUNTRIES_DATA.map(({ icon, id, name }) => ({ icon, id, name }));

const COUNTRIES_OPTIONS_ID_MAP = generateOptionMap(COUNTRIES_OPTIONS);

export function getCurrencyOptions(query: string) {
	if (!query) {
		return CURRENCY_OPTIONS;
	}

	return CURRENCY_OPTIONS.filter(
		(option) => stringContainsMatch(option.name, query) || stringContainsMatch(String(option.id), query)
	);
}

export function getCurrencyOptionsFromIds(ids: SelectOptionId[]) {
	return getOptionsFromMapByIds(CURRENCY_OPTIONS_ID_MAP, ids);
}

export function getCountryOptions(query: string) {
	if (!query) {
		return COUNTRIES_OPTIONS;
	}

	return COUNTRIES_DATA.filter(
		(country) =>
			stringContainsMatch(country.native, query) ||
			stringContainsMatch(country.name, query) ||
			stringContainsMatch(String(country.id), query)
	).map(({ id, name, icon }) => ({ id, name, icon }));
}

export function getCountryOptionsFromIds(ids: SelectOptionId[]) {
	return getOptionsFromMapByIds(COUNTRIES_OPTIONS_ID_MAP, ids);
}

export function optionFieldSettingsFromField(field: Field): BaseOptionFieldSettings {
	if (field._type === "country") {
		return { variant: "country" };
	}
	if (field._type === "currency") {
		return { variant: "currency" };
	}
	if (field._type === "roles") {
		return { variant: "roles" };
	}
	if (["object", "objects"].includes(field._type)) {
		return { variant: "records", baseObjectId: field._objectDefId || undefined };
	}

	return {
		variant: "fixed_options",
		options: (field._optionValues || []).map(({ id, color, icon, name }) => ({
			id: !id && id !== 0 ? createUniqueId() : id,
			color: color || undefined,
			icon: icon || undefined,
			name,
		})),
	};
}

export function getOptionsFromMapByIds(optionMap: Record<SelectOptionId, SelectOption>, ids: SelectOptionId[]) {
	return ids.reduce((options, id) => {
		const option = optionMap[id];
		if (option) {
			options.push(option);
		}

		return options;
	}, [] as SelectOption[]);
}

export function generateOptionMap(options: SelectOption[]) {
	return options.reduce((optionMap, option) => {
		optionMap[option.id] = option;
		return optionMap;
	}, {} as Record<SelectOptionId, SelectOption>);
}
