import { Skeleton } from "../../../../../components/Skeleton/Skeleton";

// Temp skeleton loading for documents
export function DocumentEditorLoading() {
	return (
		<div className="mx-auto flex h-full max-h-full w-10/12 flex-col gap-6 overflow-hidden pt-14">
			<Skeleton className="mb-2 h-8 w-1/2 shrink-0" />
			<Skeleton className="h-6 w-full shrink-0" />
			{Array.from({ length: 3 }).flatMap((_, index) =>
				["w-3/4", "w-2/3", "w-1/2", "w-5/6"].map((width, subIndex) => (
					<Skeleton key={`skeleton-${index}-${subIndex}`} className={`h-6 ${width} shrink-0`} />
				))
			)}
		</div>
	);
}
