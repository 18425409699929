import { skipToken } from "@reduxjs/toolkit/dist/query";

import { ChannelType } from "@salesdesk/salesdesk-schemas";

import { useGetRecordQuery } from "../../records";
import { ChannelDetails } from "../types";
import { useGetChannelRelatedRecordId } from "./useGetChannelRelatedRecordId";

export function useGetDMChannelUserRecord(channelDetails?: ChannelDetails) {
	const isDMChannel = channelDetails?.channelMetadata?.channelType === ChannelType.DirectMessage;
	const dmChannelUserId = useGetChannelRelatedRecordId(channelDetails);

	const { currentData: userRecord, isLoading } = useGetRecordQuery(
		isDMChannel ? (dmChannelUserId ?? skipToken) : skipToken
	);

	return { userRecord, isLoading };
}
