import { useState } from "react";
import clsx from "clsx";

import { SDFile, AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { Tooltip, Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { DisplayIf } from "../../../../../../../auth";
import { tw } from "../../../../../../../utils/tailwind-helpers";
import { FabContainer } from "../../../../../../../components/FabContainer";
import { FilePreview } from "../../../../../../files";
import { useDownloadFile } from "../../../../../../files/hooks/useDownloadFile";

interface WorkspaceFileFieldPreviewProps {
	file: SDFile;
	variant?: "welcomeVideo" | "default";
	onRemoveClick?: () => void;
	isRemoveLoading?: boolean;
}

export function WorkspaceFileFieldPreview({
	file,
	variant = "default",
	onRemoveClick,
	isRemoveLoading,
}: WorkspaceFileFieldPreviewProps) {
	const [expanded, setExpanded] = useState(false);

	const downloadFile = useDownloadFile(file);

	return (
		<FabContainer
			positionClasses={tw`top-2 right-2`}
			buttons={
				<div className="flex gap-1">
					<Tooltip key={String(expanded)} text="Expand">
						<Button
							size="xs"
							variant="text"
							startIcon={ICONS.fullscreen}
							onClick={(e) => {
								e.preventDefault();
								setExpanded(true);
							}}
						/>
					</Tooltip>

					<DisplayIf can={[AbilityAction.DownloadInUI, AbilitySubject.File]}>
						<Tooltip text="Download">
							<Button
								size="xs"
								variant="text"
								startIcon={ICONS.download}
								onClick={(e) => {
									e.preventDefault();
									downloadFile();
								}}
							/>
						</Tooltip>
					</DisplayIf>
					{onRemoveClick ? (
						<Tooltip text="Clear">
							<Button
								size="xs"
								variant="text"
								startIcon={ICONS.trash}
								disabled={isRemoveLoading}
								onClick={(e) => {
									e.preventDefault();
									onRemoveClick();
								}}
							/>
						</Tooltip>
					) : null}
				</div>
			}
		>
			{
				<div
					className={clsx(
						"aspect-video w-full overflow-hidden",
						variant === "welcomeVideo" && "bg-c_brand_dark rounded-t-sm",
						variant === "default" && "bg-c_bg_04 rounded-card"
					)}
				>
					<FilePreview file={file} expanded={expanded} onExpandedChange={setExpanded} variant="md" />
				</div>
			}
		</FabContainer>
	);
}
