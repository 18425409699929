import { AssociationMultiplicity } from "../AssociationMultiplicity";
import { mAssetDef } from "../asset";
import { mDocDef } from "../doc";
import { mInteractionMeetingDef } from "../interaction";
import { mProductDef } from "../product";
import { mTaskDef } from "../task";
import { mWorkspaceDef } from "../workspace";
import { PartialObjectAssociationConfig } from "./ObjectAssociationConfig";
import { mContactDef } from "../contact";
import { mSalesDeskUserDef } from "../user";
import { mLeadDef } from "../lead";
import { mIssueDef } from "../issue";

export const getWorkspaceShareableAssociations = (): PartialObjectAssociationConfig[] => [
	{
		name: "workspace-task",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.ONE,
			label: "Workspace",
		},
		object2: {
			id: mTaskDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Tasks",
		},
	},
	{
		name: "workspace-issue",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.ONE,
			label: "Workspace",
		},
		object2: {
			id: mIssueDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Issues",
		},
	},
	{
		name: "workspace-product",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Workspaces",
		},
		object2: {
			id: mProductDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Products",
		},
	},
	{
		name: "workspace-meeting",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.ONE,
			label: "Workspace",
		},
		object2: {
			id: mInteractionMeetingDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Meetings",
		},
	},
	{
		name: "workspace-doc",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Workspaces",
		},
		object2: {
			id: mDocDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Docs",
		},
	},
	{
		name: "workspace-asset",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Workspaces",
		},
		object2: {
			id: mAssetDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Assets",
		},
	},
	{
		name: "workspace-internalUser",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Workspaces",
		},
		object2: {
			id: mSalesDeskUserDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Users",
		},
	},
	{
		name: "workspace-contact",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Workspaces",
		},
		object2: {
			id: mContactDef.ID,
			multiplicity: AssociationMultiplicity.MANY,
			label: "Contacts",
		},
	},
	{
		name: "workspace-lead",
		object1: {
			id: mWorkspaceDef.ID,
			multiplicity: AssociationMultiplicity.ONE,
			label: "Workspace",
		},
		object2: {
			id: mLeadDef.ID,
			multiplicity: AssociationMultiplicity.ONE,
			label: "Lead",
		},
	},
];
