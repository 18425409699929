import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";

export function ChatMessageObject() {
	const { event, target } = useEventContext();
	const { sdObject } = useGetObjectById(event.object_def_id);
	const objectName = sdObject?._displayName?.toLowerCase() ?? "meeting";
	return <>in {target === "NOTIFICATION" ? <LinkObject /> : `this ${objectName}`}</>;
}
