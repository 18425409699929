import { getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Link } from "../../../../components/Link";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { RecordPreviewPopover } from "../../../records";
import { useEventRecordPath } from "../../../../routes";

interface EventRecordLinkProps {
	sdObject?: SDObject;
	sdRecord?: SDRecord;
	workspaceId?: number;
	isLoading?: boolean;
}

export function EventRecordLink({ sdObject, sdRecord, workspaceId, isLoading }: EventRecordLinkProps) {
	const path = useEventRecordPath(workspaceId, sdObject, sdRecord);

	return isLoading || !sdObject || !sdRecord || !path ? (
		<Skeleton className="w-22 inline-flex h-4 align-text-bottom" />
	) : (
		<RecordPreviewPopover recordId={sdRecord._id}>
			<span className="w-fit leading-5">
				<Link
					to={path}
					size="xs"
					text={getSDRecordName(sdObject, sdRecord)}
					startIcon={sdObject._icon}
					variant="secondary"
					inline
				/>
			</span>
		</RecordPreviewPopover>
	);
}
