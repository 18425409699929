import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { tw } from "../../utils/tailwind-helpers";

interface ProgressStepperProps {
	steps: string[];
	currentStep: number;
}

export function ProgressStepper({ steps, currentStep }: ProgressStepperProps) {
	return (
		<div className="flex w-full items-center gap-2">
			{steps.map((step, index) => {
				const isCurrentStep = currentStep === index;
				const isPassedStep = currentStep > index;
				const isLastStep = index === steps.length - 1;

				return (
					<div
						key={step}
						className={clsx(
							"text-label-xs text-c_text_secondary flex flex-col gap-2",
							isLastStep ? "w-fit text-nowrap" : "w-full"
						)}
					>
						<div className="flex items-center gap-1">
							<div
								className={clsx(
									"box-border size-2 shrink-0 rounded-full transition-all",
									isPassedStep ? "bg-c_action_01" : "bg-c_bg_05"
								)}
							>
								<Transition
									className="transtion-opacity"
									enter={tw`delay-100`}
									enterFrom={tw`opacity-0`}
									enterTo={tw`opacity-100`}
									leave={tw`delay-[20ms]`}
									leaveFrom={tw`opacity-100`}
									leaveTo={tw`opacity-0`}
									show={isCurrentStep || isPassedStep}
								>
									<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect x="0.5" y="0.5" width="7" height="7" rx="3.5" stroke="#6904C1" strokeDasharray="2 1" />
										<rect x="2.5" y="2.5" width="3" height="3" rx="1.5" fill="#6904C1" stroke="#6904C1" />
									</svg>
								</Transition>
							</div>
							{!isLastStep ? (
								<div className={clsx("rounded-minimal bg-c_bg_05 h-1 w-full max-w-full")}>
									<div
										className={clsx(
											"rounded-minimal h-1 max-w-full transition-all duration-150",
											isPassedStep ? "bg-c_action_01 w-full opacity-100" : "w-0 opacity-0"
										)}
									/>
								</div>
							) : null}
						</div>
						{step}
					</div>
				);
			})}
		</div>
	);
}
