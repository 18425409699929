import { SelectOption } from "../../../inputs";
import { useNotificationRuleObjects } from "./useNotificationRuleObjects";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";

const defaultFilter = () => true;

export function useNotificationRuleObjectOptions(
	filter: (object: SDObject) => boolean = defaultFilter
): SelectOption[] {
	const objects = useNotificationRuleObjects();
	return useMemo(
		() =>
			objects.filter(filter).map(
				(object) =>
					({
						id: object._id,
						name: object._displayName,
						icon: object._icon,
					} satisfies SelectOption)
			),
		[objects, filter]
	);
}
