import { useCallback } from "react";

import { SDObject, RecordDetailViewParams, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useGetContextWorkspaceId } from "../../workspaces";
import { PATHS } from "../../../routes";
import { matchPath, useLocation } from "react-router-dom";
import { getCurrentPathWithRecordIdParam } from "../components";

interface RecordDefaultPathOptions {
	detailViewParams?: RecordDetailViewParams;
	workspaceId?: SDRecord["_id"];
}

export type GetRecordDefaultPathFn = (
	recordId: number,
	sdObject: SDObject,
	pathOptions?: RecordDefaultPathOptions
) => string;

export function useGetRecordDefaultPath() {
	const workspaceId = useGetContextWorkspaceId();
	const location = useLocation();

	return useCallback(
		(recordId: number, sdObject: SDObject, pathOptions?: RecordDefaultPathOptions) => {
			const pathWorkspaceId = pathOptions?.workspaceId ?? workspaceId;

			const { detailViewParams } = pathOptions ?? {};

			if (pathWorkspaceId == null) {
				return PATHS.RECORD_DETAIL_VIEW(recordId, sdObject, detailViewParams);
			}

			const workspaceDetailsPath = PATHS.WORKSPACE_RECORD_DETAILS(
				pathWorkspaceId,
				recordId,
				sdObject,
				detailViewParams
			);

			if (workspaceDetailsPath) {
				return workspaceDetailsPath;
			}

			const workspaceBoardPath = PATHS.WORKSPACE_RECORD_BOARD(pathWorkspaceId, sdObject, { recordId });

			const strippedQueryParamsWorkspaceBoardPath = workspaceBoardPath.split("?")[0];

			// If we're already on the object board we just add the recordId to the end of the path
			// to avoid causing a reload of the object board.
			if (matchPath({ path: strippedQueryParamsWorkspaceBoardPath }, location.pathname) && recordId) {
				return getCurrentPathWithRecordIdParam(recordId);
			}

			return workspaceBoardPath;
		},
		[location, workspaceId]
	);
}
