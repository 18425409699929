import { useEffect, useState } from "react";

import { RecordQueryClauses, SDObject, SDRecord, getSDRecordName, rsr } from "@salesdesk/salesdesk-schemas";

import { APP_CONFIG } from "../../../../../../../../app/app_config";
import {
	EMPTY_FILTERS,
	FilterData,
	mapToSearchQuery,
	SortingDetails,
	useInfiniteRecordSearch,
} from "../../../../../../../records";
import { OriginRecordDetails } from "../../../../types";
import { WorkspacePotentialAssociationGroup } from "../types";

interface UseWorkspaceAssociationsProps {
	associationObject?: SDObject;
	originRecordDetails: OriginRecordDetails;
}

export function usePotentialWorkspaceAssociationSearch({
	associationObject,
	originRecordDetails,
}: UseWorkspaceAssociationsProps) {
	const [filter, setFilter] = useState<FilterData>(EMPTY_FILTERS);
	const [sorting, setSorting] = useState<SortingDetails[]>([]);

	const [searchQuery, setSearchQuery] = useState<string>();

	const [isFiltered, setIsFiltered] = useState(false);

	useEffect(() => {
		setIsFiltered(filter.filters.length > 0 || Boolean(searchQuery));
	}, [filter.filters.length, searchQuery]);

	const { originRecord, originRecordObject } = originRecordDetails;

	const [linkedToOriginRecords, setLinkedToOriginRecords] = useState<SDRecord[]>([]);
	const [totalCountLinked, setTotalCountLinked] = useState<number>();
	const {
		updateSearchParams: updateSearchParamsLinked,
		loadNextPage: loadNextPageLinked,
		isLoadingNewSearchParams: isLoadingNewSearchParamsLinked,
		isLoadingNextPage: isLoadingNextPageLinked,
		resultHits: linkedResultHits,
	} = useInfiniteRecordSearch({
		limit: APP_CONFIG.maxLocalSearchResults,
		sdRecords: linkedToOriginRecords,
		onSDRecordsChange: setLinkedToOriginRecords,
		sdObjectFilter: associationObject?._id,
	});

	const hitCountLinked = linkedResultHits?.hitCount;
	useEffect(() => {
		if (hitCountLinked != null && !isFiltered) {
			setTotalCountLinked(hitCountLinked);
		}
	}, [hitCountLinked, isFiltered]);

	const resultsNameLinked = `${associationObject?._pluralName} linked to "${getSDRecordName(
		originRecordObject,
		originRecord
	)}"`;

	const [otherRecords, setOtherRecords] = useState<SDRecord[]>([]);
	const [totalCountOther, setTotalCountOther] = useState<number>();
	const {
		updateSearchParams: updateSearchParamsOther,
		loadNextPage: loadNextPageOther,
		isLoadingNewSearchParams: isLoadingNewSearchParamsOther,
		isLoadingNextPage: isLoadingNextPageOther,
		resultHits: otherResultHits,
	} = useInfiniteRecordSearch({
		limit: APP_CONFIG.maxLocalSearchResults,
		sdRecords: otherRecords,
		onSDRecordsChange: setOtherRecords,
		sdObjectFilter: associationObject?._id,
	});

	const hitCountOther = otherResultHits?.hitCount;
	useEffect(() => {
		if (hitCountOther != null && !isFiltered) {
			setTotalCountOther(hitCountOther);
		}
	}, [hitCountOther, isFiltered]);

	const resultsNameOther = `other ${associationObject?._pluralName}`;

	useEffect(() => {
		if (!associationObject) {
			return;
		}

		const sort = sorting.map((value) => ({ [value.fieldId]: { order: value.order } }));

		const queryLinked = generateBaseWorkspaceAssociationRsrQuery(searchQuery, filter);
		queryLinked.and(rsr.hasAssociationToRecord(originRecord._id));

		updateSearchParamsLinked({
			query: queryLinked.buildQuery(),
			sort,
		});

		const queryOther = generateBaseWorkspaceAssociationRsrQuery(searchQuery, filter);
		queryOther.not(rsr.hasAssociationToRecord(originRecord._id));

		updateSearchParamsOther({
			query: queryOther.buildQuery(),
			sort,
		});
	}, [
		updateSearchParamsLinked,
		updateSearchParamsOther,
		filter,
		sorting,
		searchQuery,
		originRecord._id,
		associationObject,
	]);

	return {
		potentialAssociationGroups: [
			{
				title: `Linked to "${getSDRecordName(originRecordObject, originRecord)}"`,
				records: linkedToOriginRecords,
				totalCount: totalCountLinked,
				isLoadingNewSearchParams: isLoadingNewSearchParamsLinked,
				isLoadingNextPage: isLoadingNextPageLinked,
				loadNextPage: loadNextPageLinked,
				resultsName: resultsNameLinked,
			},
			{
				title: `Other ${associationObject?._pluralName}`,
				records: otherRecords,
				totalCount: totalCountOther,
				isLoadingNewSearchParams: isLoadingNewSearchParamsOther,
				isLoadingNextPage: isLoadingNextPageOther,
				loadNextPage: loadNextPageOther,
				resultsName: resultsNameOther,
			},
		] satisfies WorkspacePotentialAssociationGroup[],
		filter,
		setFilter,
		sorting,
		setSorting,
		setSearchQuery,
		isFiltered,
	};
}

function generateBaseWorkspaceAssociationRsrQuery(searchQuery: string | undefined, filter: FilterData) {
	const query = rsr.query().and(rsr.equals("_deleted", false)).not(rsr.equals("isTemplate", true));

	if (searchQuery != null && searchQuery.length > 0) {
		query.and(rsr.matchAllPrefix(searchQuery));
	}

	const associationFilters: RecordQueryClauses[] = filter?.filters.flatMap(mapToSearchQuery);

	if (associationFilters.length) {
		const filterType = filter.type;

		associationFilters.forEach((filter) => {
			if (filterType === "AND") {
				query.and(filter);
			} else if (filterType === "OR") {
				query.or(filter);
			}
		});
	}

	return query;
}
