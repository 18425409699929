import { asConst } from "json-schema-to-ts";
import { notClauseSchema } from "../../../search";
import { recordNotClausesSchema } from "./recordNotClausesSchema";

export const recordNotClauseSchema = asConst({
	...notClauseSchema,
	properties: {
		not: recordNotClausesSchema,
	},
});
