import { useMemo } from "react";

import { useAppSelector } from "../../../../store/store";
import { unserializeChannelDetails } from "../../utils";

export function useChannelSelector(channelArn?: string, includeReadOnly = false) {
	const channels = useAppSelector((state) => state.channels.channels);

	return useMemo(() => {
		const channel = channels?.find((channel) => channel.arn === channelArn && (includeReadOnly || !channel.isReadOnly));
		return channel ? unserializeChannelDetails(channel) : undefined;
	}, [channels, channelArn, includeReadOnly]);
}
