import { useMemo } from "react";

import { MINUTE_IN_MS } from "@salesdesk/salesdesk-utils";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useChimeUserIdUserRecordMap } from "./useChimeUserIdUserRecordMap";
import { ChannelMessageArray, ChannelMessageGroup, ChannelMessageGroupMessage } from "../../../types";
import { getChimeMessageTime, getChimeUserId } from "@salesdesk/salesdesk-schemas";

const TIMESTAMP_INTERVAL = 5 * MINUTE_IN_MS;

export function useChimeMessageGroups(
	chatMessages?: ChannelMessageArray,
	memberRecords?: SDRecord[]
): ChannelMessageGroup[] {
	const messagingUserRecords = useChimeUserIdUserRecordMap(chatMessages, memberRecords);

	return useMemo(() => {
		let lastTimeSeen: number | undefined;
		const messageGroups: ChannelMessageGroup[] = [];
		if (!chatMessages || chatMessages.length === 0 || messagingUserRecords == null) return messageGroups;

		let currentMessageGroup: ChannelMessageGroup | null = null;
		chatMessages.forEach((message) => {
			// New group because of a new user messaging, or because enough time has passed since the last message
			const messageSenderUserId = getChimeUserId(message.Sender?.Arn);
			const messageCreatedAt = getChimeMessageTime(message.CreatedTimestamp);
			const isNewUserGroup = currentMessageGroup == null || currentMessageGroup.userId !== messageSenderUserId;
			const isNewTimeGroup =
				lastTimeSeen == null || messageCreatedAt == null || messageCreatedAt - lastTimeSeen > TIMESTAMP_INTERVAL;

			if (currentMessageGroup == null || isNewUserGroup || isNewTimeGroup) {
				currentMessageGroup = {
					userId: messageSenderUserId,
					userRecord: messageSenderUserId ? messagingUserRecords.get(messageSenderUserId) : undefined,
					messages: [],
					displayTimestamp: isNewTimeGroup,
				};
				messageGroups.push(currentMessageGroup);
			}

			const messageGroupMessage: ChannelMessageGroupMessage = {
				...message,
				jsonContent: JSON.parse(message.Content ?? "null") ?? undefined,
				metadata: message.Metadata ? JSON.parse(message.Metadata) : undefined,
			};
			currentMessageGroup.messages.push(messageGroupMessage);

			lastTimeSeen = messageCreatedAt;
		});

		return messageGroups;
	}, [chatMessages, messagingUserRecords]);
}
