import { asConst } from "json-schema-to-ts";
import { allowedTypesSchema } from "./allowedTypesSchema";

export const queryClausePropertyValuesSchema = asConst({
	title: "Field Values",
	type: "object",
	additionalProperties: {
		type: "array",
		items: allowedTypesSchema,
		minItems: 1,
	},
	minProperties: 1,
	maxProperties: 1,
});
