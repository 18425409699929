import { ObjectRowData, ObjectTableColumn, ObjectTableRow } from "../types";
import {
	BASE_SYSTEM_OBJECTS_ORDER,
	Field,
	SDObject,
	sdObjectIsSystemType,
	sdObjectIsUserType,
} from "@salesdesk/salesdesk-schemas";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";
import { METADATA_FIELDS } from "../../../../ObjectBoard/utils";
import { mDateTimeFieldDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";
import { Pill } from "../../../../../components/Pill/Pill";
import { HeaderCell } from "../../../../Table";
import { DisplayPlaceholderField } from "../../../../fields/components/Fields/DisplayPlaceholderField";

const OBJECT_MANAGER_OBJECTS_ORDER = [...BASE_SYSTEM_OBJECTS_ORDER, mWorkspaceDef.ID];

function renderNameColumn(name: ObjectRowData, row: ObjectTableRow) {
	const sdObject = row[SD_OBJECT_COLUMN_ID];
	if (
		name === null ||
		typeof name !== "string" ||
		sdObject === null ||
		typeof sdObject !== "object" ||
		!("_id" in sdObject)
	) {
		return <DisplayPlaceholderField />;
	}
	return (
		<div className="flex gap-3">
			<Icon icon={sdObject._icon} className="text-c_icon_regular flex items-center" />
			<span className="text-label">{name}</span>
			{sdObjectIsSystemType(sdObject) ? <Pill>Default</Pill> : undefined}
		</div>
	);
}

function renderOwnerColumn(ownerId: ObjectRowData) {
	if (typeof ownerId !== "number") {
		return <DisplayPlaceholderField />;
	}
	return <DisplayFieldFactory field={METADATA_FIELDS.owner} value={ownerId} variant={DisplayFieldVariant.table} />;
}

function createDateField(): Field {
	const dateField = new mDateTimeFieldDef("dateField");
	dateField.displayName = "Date field";
	dateField.pluralName = "Date field";
	dateField.icon = ICONS.calendar;
	return dateField.unmarshall() as Field;
}

const dateField = createDateField();

function renderDateColumn(dateValue: ObjectRowData) {
	if (dateValue === null || typeof dateValue !== "number") {
		return <DisplayPlaceholderField />;
	}
	return <DisplayFieldFactory field={dateField} value={dateValue} variant={DisplayFieldVariant.table} />;
}

export const SD_OBJECT_COLUMN_ID = "sdObject";
export const NAME_COLUMN_ID = "name";
export const OWNER_COLUMN_ID = "owner";
export const CREATED_DATE_COLUMN_ID = "createdDate";
export const LAST_CHANGED_COLUMN_ID = "lastChanged";

export const DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID = {
	[NAME_COLUMN_ID]: 300,
};

export function getColumns(): ObjectTableColumn[] {
	return [
		{
			id: NAME_COLUMN_ID,
			displayName: () => <HeaderCell columnId={NAME_COLUMN_ID} columnName="Name" />,
			renderRowCell: renderNameColumn,
		},
		{
			id: OWNER_COLUMN_ID,
			displayName: () => <HeaderCell columnId={OWNER_COLUMN_ID} columnName="Owner" />,
			renderRowCell: renderOwnerColumn,
		},
		{
			id: CREATED_DATE_COLUMN_ID,
			displayName: () => <HeaderCell columnId={CREATED_DATE_COLUMN_ID} columnName="Created Date" />,
			renderRowCell: renderDateColumn,
		},
		{
			id: LAST_CHANGED_COLUMN_ID,
			displayName: () => <HeaderCell columnId={LAST_CHANGED_COLUMN_ID} columnName="Last Changed" />,
			renderRowCell: renderDateColumn,
		},
	];
}

export function getRows(sdObjects: SDObject[] | undefined): ObjectTableRow[] {
	if (!sdObjects) return [];
	const systemObjects: SDObject[] = [];
	const customObjects: SDObject[] = [];

	for (const sdObject of sdObjects) {
		if (OBJECT_MANAGER_OBJECTS_ORDER.indexOf(sdObject._id) !== -1) {
			systemObjects.push(sdObject);
		} else if (sdObjectIsUserType(sdObject)) {
			customObjects.push(sdObject);
		}
	}

	systemObjects.sort((a, b) =>
		OBJECT_MANAGER_OBJECTS_ORDER.indexOf(a._id) > OBJECT_MANAGER_OBJECTS_ORDER.indexOf(b._id) ? 1 : -1
	);
	customObjects.sort((a, b) => (a._dateCreated > b._dateCreated ? 1 : -1));

	return [...systemObjects, ...customObjects].map((sdObject) => {
		return {
			[SD_OBJECT_COLUMN_ID]: sdObject,
			[NAME_COLUMN_ID]: sdObject._displayName,
			[OWNER_COLUMN_ID]: sdObject._ownerId,
			[CREATED_DATE_COLUMN_ID]: sdObject._dateCreated,
			[LAST_CHANGED_COLUMN_ID]: sdObject._lastModified,
		};
	});
}
