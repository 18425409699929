import { useMemo, useState } from "react";
import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { StatusIndicatorButton } from "./StatusIndicatorButton";
import { SelectOption, SelectOptionId } from "../../features/inputs";
import { BaseSelect } from "../../features/inputs";

interface StatusIndicatorProps {
	onChange: (value: SelectOptionId) => void;
	value: SelectOptionId;
	options: SelectOption[];
	outcomes: SelectOption[];
	outcomeTitle?: string;
}

export function StatusIndicator({
	value,
	onChange,
	options,
	outcomes,
	outcomeTitle = "Outcome",
}: StatusIndicatorProps) {
	const activeIndicatorIndex = useMemo(() => options.findIndex((option) => option.id === value), [value, options]);

	const [isOutcomeOpen, setIsOutcomeOpen] = useState(false);

	const { outcomeName, isOutcomeActive, outcomeColor, singleOutcomeId } = useMemo(() => {
		let outcomeName = outcomeTitle;
		let outcomeColor;
		let isOutcomeActive = false;
		const hasSingleOutcome = outcomes.length === 1;

		for (let i = 0; i < outcomes.length; i++) {
			const { id, name, color } = outcomes[i];

			isOutcomeActive = id === value;

			if (hasSingleOutcome || id === value) {
				outcomeName = name;
				outcomeColor = color;
				break;
			}
		}

		return {
			outcomeName,
			isOutcomeActive,
			outcomeColor,
			singleOutcomeId: hasSingleOutcome ? outcomes[0].id : null,
		};
	}, [outcomes, outcomeTitle, value]);

	const outcomeProps = {
		name: outcomeName,
		color: outcomeColor,
		isActive: isOutcomeActive,
		isCompleted: isOutcomeActive,
		last: true,
	};

	return (
		<div className="flex w-full">
			{options.map((option, index) => {
				const isCompleted = isOutcomeActive || index < activeIndicatorIndex;
				return (
					<StatusIndicatorButton
						key={option.name}
						onClick={() => onChange(option.id)}
						isCompleted={isCompleted}
						color={option.color}
						isActive={activeIndicatorIndex === index}
						first={index === 0}
						name={option.name}
					/>
				);
			})}
			{singleOutcomeId ? (
				<StatusIndicatorButton {...outcomeProps} onClick={() => onChange(singleOutcomeId)} />
			) : (
				<BaseSelect
					optionDisplayVariant="pill"
					options={outcomes}
					onChange={(selectedOptionId) => (selectedOptionId ? onChange(selectedOptionId) : null)}
					onOpenChange={(open) => setIsOutcomeOpen(open)}
					selectPopoverOptions={{
						placement: "bottom-start",
						width: "match_button",
					}}
					selectButton={
						<StatusIndicatorButton
							{...outcomeProps}
							endIcon={
								<Icon
									className={clsx(
										"text-c_icon_regular flex transform transition",
										"group-focus-visible:ring-c_action_focus rounded-full group-focus-visible:rounded-full group-focus-visible:ring",
										isOutcomeOpen ? "rotate-90" : "rotate-0"
									)}
									icon={ICONS.caretRight}
									size="sm"
								/>
							}
						/>
					}
				/>
			)}
		</div>
	);
}
