import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Navbar } from "./Navbar";
import { Header } from "./Header";
import { Toasts } from "../Toasts";
import { MultiChannelMessagingPanel } from "../messaging";

const TOGGLE_LEFT_VIEW_KEY = "sd_toggle_left_view";

export function MainLayout() {
	const [navbarIsOpen, setNavbarIsOpen] = useState(() => {
		return window.localStorage.getItem(TOGGLE_LEFT_VIEW_KEY) !== "false" || false;
	});

	const updateNavbarState = (isOpen: boolean) => {
		window.localStorage.setItem(TOGGLE_LEFT_VIEW_KEY, String(isOpen));
		setNavbarIsOpen(isOpen);
	};

	return (
		<>
			<Toasts position="main" />
			<Navbar isOpen={navbarIsOpen} onClose={() => updateNavbarState(false)} />
			<div className="bg-c_bg_02 flex w-full flex-col overflow-hidden">
				<Header navIsOpen={navbarIsOpen} onNavOpen={() => updateNavbarState(true)} />
				<div className="rounded-tl-panel bg-c_bg_01 relative h-full overflow-hidden">
					<Outlet />
				</div>
			</div>
			<MultiChannelMessagingPanel />
		</>
	);
}
