import { Outlet } from "react-router-dom";
import { useMemo } from "react";

import { getWorkspaceChannelId, PrincipalClaimType } from "@salesdesk/salesdesk-schemas";
import { mContactDef, UserType } from "@salesdesk/salesdesk-model";

import { MockPrincipalProvider, useWebPrincipal, WebPrincipal } from "../../../../auth";
import { useToggle } from "../../../../hooks/useToggle";
import { useGetCurrentUserRecord } from "../../../users";
import { Toasts } from "../../../Toasts";

import { useWorkspaceContext } from "../../hooks/useWorkspaceContext";
import { StickyContainer } from "../../../../components/Sticky";
import { useChimeMessagingContext } from "../../../messaging";

import { WorkspaceNav } from "./WorkspaceNav";
import { ResourcesPanel } from "./WorkspaceResources";
import { SingleChannelMessagingPanel } from "../../../messaging/components/SingleChannelMessaging/SingleChannelMessagingPanel";

export function WorkspacePageContainer() {
	const principal = useWebPrincipal();

	const { chime } = useChimeMessagingContext();

	const [isMessagingPanelOpen, setIsMessagingPanelOpen] = useToggle(false);
	const { scrollContainerRef, workspaceId, isPreviewMode } = useWorkspaceContext();

	const workspaceChannelArn = useMemo(() => {
		if (!chime || workspaceId == null) return undefined;
		return chime.convertChannelIdToArn(getWorkspaceChannelId(workspaceId));
	}, [chime, workspaceId]);

	const currentUser = useGetCurrentUserRecord();
	const customerUserPrincipal = useMemo(() => {
		if (!currentUser || workspaceId == null) {
			return undefined;
		}

		const claims = [
			...principal
				.GetClaims()
				.filter((claim) =>
					[
						PrincipalClaimType.UserRecordId,
						PrincipalClaimType.Username,
						PrincipalClaimType.FullName,
						PrincipalClaimType.Tenant,
					].includes(claim.type)
				),
			{ type: PrincipalClaimType.AuthorizedWorkspaceIds, value: `${workspaceId}` },
			{ type: PrincipalClaimType.UserType, value: UserType.SALESDESK_CUSTOMER },
		];

		return new WebPrincipal(
			{ ...currentUser, sharedWorkspaceIds: [workspaceId], _objectDefId: mContactDef.ID },
			claims
		);
	}, [currentUser, workspaceId, principal]);

	return (
		<StickyContainer>
			<Toasts position="main" />
			<div className="flex max-h-full w-full">
				<div className="relative flex max-h-full w-full min-w-0">
					<div ref={scrollContainerRef} className="flex max-h-full w-full flex-col overflow-y-auto">
						<WorkspaceNav />
						<MockPrincipalProvider principal={isPreviewMode ? customerUserPrincipal : undefined}>
							<Outlet />
						</MockPrincipalProvider>
					</div>
					<SingleChannelMessagingPanel
						channelArn={workspaceChannelArn}
						open={isMessagingPanelOpen}
						onToggleOpen={setIsMessagingPanelOpen}
					/>
				</div>
				{principal.IsSalesDeskUser ? <ResourcesPanel /> : null}
			</div>
		</StickyContainer>
	);
}
