import { asConst } from "json-schema-to-ts";
import { rolePatchRequestSchema } from "./rolePatchRequestSchema";
import { idSchema } from "../components";

export const roleBatchPatchRequestSchema = asConst({
	title: "RoleBatchPatchRequest",
	description: "Defines a record schema used for batch patching roles",
	type: "object",
	additionalProperties: false,
	required: ["id"],
	properties: {
		id: idSchema,
		...rolePatchRequestSchema.properties,
	},
});
