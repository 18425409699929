import { inBrowser } from "@salesdesk/salesdesk-utils";

import { DataAccessorProvider } from "./data_accessor_provider";

export class DataService {
	static instance = null;

	constructor(config, objectDefFactory, objectInstanceFactory, fieldInstanceFactory, tenantSettingsRecordId) {
		this.config = config || {};
		this.objectDefFactory = objectDefFactory;
		this.objectInstanceFactory = objectInstanceFactory;
		this.fieldInstanceFactory = fieldInstanceFactory;
		this.tenantSettingsRecordId = tenantSettingsRecordId;
		this.objectDefCache = {};
		this.objectDefNameCache = {};
		this.objectCache = {};
		this.pendingObjectIds = [];
		this.callbackTasks = [];
		this.lastLoadObjectsTime = -1;
		DataService.instance = this;
	}

	static getInstance(config, objectDefFactory, objectInstanceFactory, fieldInstanceFactory, tenantSettingsRecordId) {
		if (DataService.instance === null) {
			DataService.instance = new DataService(
				config,
				objectDefFactory,
				objectInstanceFactory,
				fieldInstanceFactory,
				tenantSettingsRecordId
			);
		}
		return DataService.instance;
	}

	// Get a previously loaded object from the cache.
	getObjectDef(id) {
		return this.objectDefCache[id];
	}

	getObjectDefs() {
		return Object.values(this.objectDefCache);
	}

	getObjectDefIds() {
		return Object.keys(this.objectDefCache);
	}

	getUserCreateDefIds() {
		const defs = this.getObjectDefs();

		const userCreateDefs = [];

		for (let i = 0; defs.length > i; i++) {
			const def = defs[i];

			if (def.isBaseType() && def.supportsUserCreation) {
				userCreateDefs.push(def.id);
			}
		}
		return userCreateDefs;
	}

	getUserSearchableDefIds() {
		const defs = this.getObjectDefs();

		const userSearchDefs = [];

		for (let i = 0; defs.length > i; i++) {
			const def = defs[i];

			if (def.isBaseType() && def.searchable) {
				userSearchDefs.push(def.id);
			}
		}
		return userSearchDefs;
	}

	getDefIdByPath(path) {
		const defs = this.getObjectDefs();

		for (let i = 0; defs.length > i; i++) {
			const def = defs[i];

			if (def.pathName == path && def.isBaseType()) {
				return def.id;
			}
		}
	}

	getDefByPath(path) {
		const defs = this.getObjectDefs();

		for (let i = 0; defs.length > i; i++) {
			const def = defs[i];
			if (def.pathName == path && def.isBaseType()) {
				return def;
			}
		}

		return null;
	}

	loadEditableObjectDefs(callback) {
		this.loadAllLiveDefinitions((err, data) => {
			if (err) {
				callback(err);
			} else {
				callback(
					null,
					data.filter((objectDef) => objectDef.editable)
				);
			}
		});
	}

	getObjectDefByName(name) {
		return this.objectDefNameCache[name];
	}

	isCacheingEnabled() {
		return this.config.cacheing;
	}

	isObjectCacheingEnabled() {
		return this.config.objectCacheing;
	}

	isObjectDefCacheingEnabled() {
		return this.config.objectDefCacheing !== false;
	}

	isObjectDefCached(id) {
		if (!this.isObjectDefCacheingEnabled()) {
			return false;
		}

		const hit = this.objectDefCache[id] !== undefined;
		return hit;
	}

	setObjectDef(objectDef) {
		if (!this.isObjectDefCacheingEnabled()) {
			return;
		}
		if (!this.isObjectDefCached(objectDef.id)) {
			this.objectDefCache[objectDef.id] = objectDef;
			this.objectDefNameCache[objectDef.name] = objectDef;
		}
	}

	// Get a previously loaded object def from the cache.
	getObject(id) {
		return this.objectCache[id];
	}

	isObjectCached(id) {
		if (!this.isObjectCacheingEnabled()) {
			false;
		}

		let hit = false; //this.objectCache[id] !== undefined;

		return hit;
	}

	setObject(object) {
		if (!this.isObjectCacheingEnabled()) {
			return;
		}

		this.objectCache[object.id] = object;
	}

	//*********** OBJECT DEFINITIONS **************//

	loadObjectDef(id, callback, noCache) {
		let ids = [parseInt(id)];

		this.loadObjectDefs(
			ids,
			function (err, data) {
				callback(err, data && data.length > 0 ? data[0] : null);
			},
			noCache
		);
	}

	saveNewDefVersion(newDefinition, callback) {
		const oldVersion = typeof newDefinition.version === "undefined" ? null : newDefinition.version;

		this.saveDef(oldVersion, newDefinition, callback);
	}

	saveDef(existingVersion, newDefinition, callback) {
		if (newDefinition.transient) {
			throw Error(`Object def with id ${newDefinition.id} is transient and cannot be stored`);
		}

		if (!newDefinition._version) {
			newDefinition._version = 1;
		}

		// We need to get the root object and then obtain all of the nested object defs.
		// Then store the defs and the data in a flat structure in the underlying tables.

		const sdObjectRequest = {};

		// Check for existence of unmarshall so that this function works if passed a bound or an unbound definition
		Object.assign(sdObjectRequest, newDefinition.unmarshall ? newDefinition.unmarshall() : newDefinition);

		DataAccessorProvider.getDataAccessorInstance().saveDefinition(
			{
				sdObjectRequest: sdObjectRequest,
				// If null assumes Post
				existingVersion: existingVersion,
				// If Post ignores this
				id: newDefinition.id,
			},
			(sdObject, err) => {
				if (err != null) {
					if (callback) {
						callback(err);
					}
				} else {
					// TODO:  This breaks stuff:
					// * You cannot edit the definition again because it picks up the old version
					// * You can't click on accounts after editing the Account because resource is not
					delete this.objectDefCache[sdObject._id];
					this.handleDefinitionTransformation([sdObject], [], new Map(), false, () => ({}));

					if (callback) {
						callback();
					}
				}
			}
		);
	}

	handleDefinitionTransformation(rawJsonDefinitions, missingIds, idObjectDefMap, noCache, callback) {
		const returnedIds = new Set();
		const returnedProcessedDefinitions = [];

		for (let j = 0; rawJsonDefinitions.length > j; j++) {
			let objectDefData = rawJsonDefinitions[j];

			if (objectDefData._deleted) {
				continue;
			}

			let objectDef = this.objectDefFactory.newDefinition(objectDefData._className).marshall(objectDefData);

			returnedProcessedDefinitions.push(objectDef);

			idObjectDefMap.set(objectDef.id, objectDef);

			returnedIds.add(objectDef.id);

			if (!noCache) {
				this.setObjectDef(objectDef);
			}
		}

		returnedProcessedDefinitions.forEach((objectDef) => {
			if (objectDef.hasChildren()) {
				objectDef.loadChildren(function (err, data) {
					if (err) {
						console.log(`Couldn't load children for ${objectDef.name}`);
					}
				});
			}
		});

		for (const missingId of missingIds) {
			if (!returnedIds.has(missingId)) {
				throw Error(`Cannot find object definition for id ${missingId} in the db`);
			}
		}

		let results = Array.from(idObjectDefMap.values());

		callback(null, results);
	}

	loadAllLiveDefinitions(callback, noCache) {
		DataAccessorProvider.getDataAccessorInstance().loadAllLiveDefinitions((err, data) => {
			if (err) {
				if (callback) {
					callback(err);
				}
			} else {
				const allDefs = data;
				const missingIds = [];
				const idObjectDefMap = new Map();
				this.handleDefinitionTransformation(allDefs, missingIds, idObjectDefMap, noCache, callback);
			}
		});
	}

	loadObjectDefs(ids, callback, noCache) {
		const missingIds = [];

		const idObjectDefMap = new Map();

		ids.forEach((rawId) => {
			const id = parseInt(rawId, 10);
			if (this.isObjectDefCached(id) && !noCache) {
				idObjectDefMap.set(id, this.getObjectDef(id));
			} else {
				missingIds.push(id);
			}
		});

		if (missingIds.length === 0) {
			// No need to query API if already all cached
			const results = Array.from(idObjectDefMap.values());
			callback(null, results);
			return;
		}

		DataAccessorProvider.getDataAccessorInstance().loadDefinitions(ids, (err, defs) => {
			if (err) {
				callback(err);
			} else {
				this.handleDefinitionTransformation(defs, missingIds, idObjectDefMap, noCache, callback);
			}
		});
	}

	deleteDefinition(definitionId, callback) {
		DataAccessorProvider.getDataAccessorInstance().deleteDefinition(definitionId, callback);
	}

	//*********** OBJECT INSTANCES **************//

	storeObject(objectInst, callback) {
		this.storeObjects([objectInst], callback);
	}

	storeObjects(objectInsts, callback) {
		this.callStoreObjectsApi(objectInsts, false, callback);
	}

	storeObjectForce(objectInst, callback) {
		this.storeObjectsForce([objectInst], callback);
	}

	storeObjectsForce(objectInsts, callback) {
		this.callStoreObjectsApi(objectInsts, true, callback);
	}

	callStoreObjectsApi(objectInsts, force, callback) {
		let objectsToWrite = [];

		objectInsts.forEach((objectInst) => {
			// We need to get the root object and then obtain all of the nested object defs.
			// Then store them in a flat structure in the underlying tables.

			if (objectInst.transient) {
				throw Error(`Object instance with id ${objectInst.id} is transient and cannot be stored`);
			}

			const clonedInst = objectInst.clone();
			clonedInst._version++;
			const Item = {};
			Object.assign(Item, clonedInst.unmarshall());

			objectsToWrite.push(Item);
		});

		const updateObjectsParams = {
			requestType: force ? "force_update_objects" : "update_objects",
			params: {
				items: objectsToWrite.map((objectToWrite) => {
					return {
						objectId: objectToWrite.id,
						newVersion: objectToWrite,
					};
				}),
			},
		};

		if (!inBrowser()) {
			DataAccessorProvider.getDataAccessorInstance().doStore(updateObjectsParams, callback);
			return;
		}

		DataAccessorProvider.getDataAccessorInstance().storeRecords(updateObjectsParams, (err, responseObj) => {
			if (err) {
				if (callback) {
					callback(err);
				}
			} else {
				for (let i = 0; i < responseObj.saved_object_versions.length; i++) {
					objectInsts[i]._version = responseObj.saved_object_versions[i];
				}
				if (callback) {
					callback(null, responseObj);
				}
			}
		});
	}

	loadTenantSettings(callback) {
		this.loadObject(this.tenantSettingsRecordId, callback);
	}

	loadObject(id, callback) {
		let ids = [parseInt(id)];

		this.loadObjects(ids, function (err, data) {
			callback(err, data && data.length > 0 ? data[0] : null);
		});
	}

	jsonToObjects(jsonArrayPromise, knownIdObjectMap, callback) {
		const objectInstances = {};

		const idObjectMap = knownIdObjectMap || new Map();

		jsonArrayPromise
			.then(
				(values) => {
					let loadDepPromises = [];
					let objectDefIds = [];

					// Iterate through all object inst results and
					// and create a query for each associated object def

					for (let j = 0; values.length > j; j++) {
						let objectInstData = values[j].Item;

						if (!objectInstData) {
							// TODO include something in the return value so the caller knows about failures
							console.warn(`Failed to load object`);
							continue;
						}

						// Store in object inst look up table for later...
						objectInstances[objectInstData._id] = objectInstData;

						let objectDefId = objectInstData._objectDefId;

						if (!objectDefIds.includes(objectDefId)) {
							objectDefIds.push(objectDefId);

							// Load object definition

							if (!this.isObjectDefCached(objectDefId)) {
								const loadDefinitionPromise = new Promise((resolve, reject) =>
									DataAccessorProvider.getDataAccessorInstance().loadDefinitions(
										[objectInstData._objectDefId],
										(err, data) => {
											if (err) {
												reject(err);
											} else {
												resolve(data[0]);
											}
										}
									)
								);
								loadDepPromises.push(loadDefinitionPromise);
							}
						}
					}

					return Promise.all(loadDepPromises);
				},
				function (reason) {
					callback(reason, []);
				}
			)
			.then(
				(values) => {
					// Now load all required object defs

					let objectDefs = [];

					// Store in object def look up table for later...
					for (let k = 0; values.length > k; k++) {
						let objectDefData = values[k];
						objectDefs[objectDefData._id] = objectDefData;
					}

					// Now map object defs to object instances
					for (let key in objectInstances) {
						let objectInstData = objectInstances[key];

						let objectDefId = objectInstData._objectDefId;
						let objectDef = null;

						if (!this.isObjectDefCached(objectDefId)) {
							let objectDefData = objectDefs[objectInstData._objectDefId];
							objectDef = this.objectDefFactory.newDefinition(objectDefData._className).marshall(objectDefData);
						} else {
							objectDef = this.getObjectDef(objectDefId);
						}

						// The process of marshalling the raw data to a new object instance will fail if we don't immediately remove deleted fields
						this.updateRawInstIfNecessary(objectInstData, objectDef);
						let objectInst = this.objectInstanceFactory.newInstance(objectDef).marshall(objectInstData, objectDef);

						objectInst.persisted = true;
						objectInst.dirty = false;

						this.updateInstIfNecessary(objectInst, objectDef);

						idObjectMap.set(objectInst.id, objectInst);

						this.setObject(objectInst);
					}

					callback(null, Array.from(idObjectMap.values()));
				},
				function (reason) {
					callback(reason, []);
				}
			);
	}

	loadLiveObjects(ids, callback) {
		this.loadObjects(ids, (err, data) => {
			window.mydata = data;
			if (err) {
				callback(err, data);
			} else {
				callback(
					null,
					data.filter((loadedObject) => !loadedObject.deleted)
				);
			}
		});
	}

	loadObjects(ids, callback) {
		const planningUpload = this.pendingObjectIds.length > 0;

		// Save current stack trace so that we can tie errors thrown inside setTimeout back to original invocation of this function
		const currentStackTrace = new Error().stack;
		this.callbackTasks.push({
			originalStackTrace: currentStackTrace,
			callback,
			ids,
		});
		this.pendingObjectIds.push(...ids);
		if (planningUpload) {
			// There is already a task scheduled to upload these objects
			return;
		}
		const minimumDelayMillis = 20;
		const now = new Date().getTime();
		const delay = this.lastLoadObjectsTime ? Math.max(this.lastLoadObjectsTime + minimumDelayMillis - now, 0) : 0;
		this.lastLoadObjectsTime = now;

		setTimeout(async () => {
			const callbackTasks = this.callbackTasks;
			this.executeLoadObjects(this.pendingObjectIds, (err, data) => {
				if (err) {
					for (const callbackTask of callbackTasks) {
						try {
							callbackTask.callback.call(null, err, null);
						} catch (callbackError) {
							console.error("Error in callback after failing to load objects", callbackError);
							console.error("Stack trace for loadObjects:", callbackTask.originalStackTrace);
						}
					}
				} else {
					const dataById = {};
					for (const datum of data) {
						if (datum.id) {
							dataById[datum.id] = datum;
						}
					}
					for (const callbackTask of callbackTasks) {
						const functionToApply = callbackTask.callback;
						const argumentObjects = [];
						for (const argumentId of callbackTask.ids) {
							argumentObjects.push(dataById[argumentId]);
						}
						try {
							functionToApply.call(null, null, argumentObjects);
						} catch (callbackError) {
							console.error(`Error in callback for objects ${JSON.stringify(callbackTask.ids)}`, callbackError);
							console.error("Stack trace for loadObjects:", callbackTask.originalStackTrace);
						}
					}
				}
			});
			this.pendingObjectIds = [];
			this.callbackTasks = [];
		}, delay);
	}

	async executeLoadObjects(ids, callback) {
		const deduplicatedIds = [...new Set(ids.map((x) => parseInt(x)))];

		/** @type {SDRecord[]} */
		const instancesResponse = await DataAccessorProvider.getDataAccessorInstance().loadInstances(deduplicatedIds);

		this.jsonToObjects(
			Promise.resolve(
				instancesResponse.map((r) => ({
					Item: r,
				}))
			),
			new Map(),
			callback
		);
	}

	getPermissionedUsers(recordId, matchAllPrefix, callback) {
		DataAccessorProvider.getDataAccessorInstance().getPermissionedUsers(
			recordId,
			matchAllPrefix,
			(err, userRecords) => {
				if (err) {
					if (callback) {
						callback(err);
					}
				} else {
					this.jsonToObjects(
						Promise.resolve(
							userRecords.map((ur) => {
								return {
									Item: ur,
								};
							})
						),
						null,
						callback
					);
				}
			}
		);
	}

	updateRawInstIfNecessary(rawInst, latestDefinition) {
		const rawInstDefinitionVersion = rawInst._definitionVersion || 0;
		const latestDefinitionVersion = latestDefinition.version || 0;
		if (latestDefinitionVersion > rawInstDefinitionVersion) {
			const nonDeletedFields = [];
			for (const rawChild of rawInst._dataInst._children) {
				if (latestDefinition._dataDef.fieldExistsById(rawChild._fieldId)) {
					nonDeletedFields.push(rawChild);
				}
			}
			rawInst._dataInst._children = nonDeletedFields;
		}

		const legitimateChildFields = latestDefinition._childrenIds;
		for (const [key, value] of Object.entries(rawInst)) {
			const regexResult = /^_field_([0-9]*)_ids$/.exec(key);
			if (regexResult) {
				const fieldNumber = regexResult[1];
				if (!legitimateChildFields.includes(parseInt(fieldNumber, 10))) {
					delete rawInst[key];
				}
			}
		}
	}

	updateInstIfNecessary(inst, latestDefinition) {
		if (!inst.definitionVersion) {
			inst.definitionVersion = 1;
		}
		if (!latestDefinition.version) {
			latestDefinition.version = 1;
		}

		const originalVersion = inst.definitionVersion;
		const latestVersion = latestDefinition.version;

		if (latestVersion > originalVersion) {
			const orderedFieldIds = latestDefinition._dataDef._children.map((x) => x._id);
			const definitionChildrenById = {};
			for (const definitionChild of latestDefinition._dataDef.children) {
				definitionChildrenById[definitionChild._id] = definitionChild;
			}

			const oldChildrenByField = {};
			for (const instChild of inst._dataInst._children) {
				oldChildrenByField[instChild._fieldId] = instChild;
			}
			const newChildren = [];
			for (const orderedFieldId of orderedFieldIds) {
				if (oldChildrenByField[orderedFieldId]) {
					newChildren.push(oldChildrenByField[orderedFieldId]);
				} else {
					newChildren.push(this.fieldInstanceFactory.newInstance(definitionChildrenById[orderedFieldId]));
				}
			}
			inst._dataInst._children = newChildren;
			inst._objectDef = latestDefinition;
			inst._dataInst._field = latestDefinition._dataDef;
			inst.definitionVersion = latestVersion;
			return inst;
		}
	}

	wrapObjects(data) {
		let valueObjects = [];

		if (data.Items && data.Items.length > 0) {
			for (let i = 0; data.Items.length > i; i++) {
				let dataVal = data.Items[i];

				valueObjects.push({
					id: dataVal._id,
					name: dataVal._name,
					objectDefId: dataVal._objectDefId,
				});
			}
		}

		return valueObjects;
	}

	deleteObject(objectId, callback) {
		DataAccessorProvider.getDataAccessorInstance().deleteObject(
			{
				objectId,
			},
			callback
		);
	}

	restoreObject(objectId, callback) {
		DataAccessorProvider.getDataAccessorInstance().restoreObject(
			{
				objectId,
			},
			callback
		);
	}
}
