import clsx from "clsx";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { UserWithDetailsSize } from "../types";

interface UserWithDetailsLoadingProps {
	size?: UserWithDetailsSize;
}

export function UserWithDetailsLoading({ size = "default" }: UserWithDetailsLoadingProps) {
	return (
		<div className="flex items-start gap-2 mix-blend-multiply">
			<Skeleton className={clsx(size === "lg" ? "size-10" : "size-8 ", "shrink-0")} />
			<div className="flex min-h-full flex-col justify-center gap-1 overflow-hidden">
				<Skeleton className={clsx(size === "lg" ? "h-6 w-28" : "h-5 w-24")} />
				{size === "lg" ? (
					<>
						<Skeleton className="w-22 h-5" />
						<Skeleton className="w-22 h-5" />
					</>
				) : (
					<Skeleton className="h-5 w-32" />
				)}
			</div>
		</div>
	);
}
