import { useMemo } from "react";
import { isEqual } from "lodash";

import { Field } from "@salesdesk/salesdesk-schemas";

import { SortingDetails, SortingOrder, SortingPopover } from "../../../../../records";
import { getFieldOption } from "../utils";

interface WatchlistSortingProps {
	fields: Field[];
	value: SortingDetails[];
	onChange: (value: SortingDetails[]) => void;
	disabled?: boolean;
}

export function WatchlistSorting({ fields, value, onChange, disabled = false }: WatchlistSortingProps) {
	const fieldOptions = useMemo(() => [fields.map((field) => getFieldOption(field))], [fields]);

	const defaultValues = useMemo(() => {
		if (!fields) return [];
		return [
			{
				fieldId: String(fields[0]._name),
				order: SortingOrder.asc,
			},
		] satisfies SortingDetails[];
	}, [fields]);

	return (
		<SortingPopover
			value={value}
			onChange={(newValue) => {
				if (!isEqual(value, newValue)) {
					onChange(newValue);
				}
			}}
			fieldOptions={fieldOptions}
			defaultValues={defaultValues}
			disabled={disabled}
		/>
	);
}
