import { useMemo } from "react";

import { mAssetDef } from "@salesdesk/salesdesk-model";
import { AbilityAction, AbilitySubject, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { MenuItem } from "../../../../../menu";
import { useWebPrincipal } from "../../../../../../auth";
import { useVideoCallLogicContext } from "../../../../../meetings/components/VideoCallProvider";
import { isPresentableFile } from "../../../../../files";
import { PATHS, useStableNavigate } from "../../../../../../routes";
import { useSdFileByFieldName } from "../../../../../files/hooks/useSdFileByFieldName";

export function useAssetRecordActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	const { shareFile, room, currentMeetingRecord, fileSharingParticipant } = useVideoCallLogicContext();
	const navigate = useStableNavigate();
	const principal = useWebPrincipal();

	const { sdFile: assetFile } = useSdFileByFieldName(sdObject, sdRecord, mAssetDef.FILE_FIELD_NAME);

	return useMemo(() => {
		if (
			!sdRecord ||
			!assetFile ||
			!isPresentableFile(assetFile) ||
			!currentMeetingRecord ||
			principal.cannot(AbilityAction.Present, AbilitySubject.VideoCallFile)
		) {
			return [];
		}

		const presentFileItem: MenuItem = { icon: ICONS.export, text: "Present file", type: "button" };

		// TODO: What to render if someone is already presenting a file on the call?
		if (!fileSharingParticipant || fileSharingParticipant.participant === room?.localParticipant) {
			presentFileItem.onClick = () => {
				//TODO: Override the parentRecordId to ensure file sharing events have the ID of the asset record
				shareFile({ ...assetFile, recordId: sdRecord._id });

				const maximisedVideoCallPath = PATHS.MEETING(currentMeetingRecord);

				// Return to maximised video call if user isn't already in it
				if (!window.location.pathname.startsWith(maximisedVideoCallPath)) {
					navigate(maximisedVideoCallPath);
				}
			};
		}

		return [presentFileItem];
	}, [
		sdRecord,
		assetFile,
		currentMeetingRecord,
		principal,
		fileSharingParticipant,
		room?.localParticipant,
		shareFile,
		navigate,
	]);
}
