import { CSSProperties, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

export function useProductBackgroundPositioning(
	productRecord: SDRecord | undefined,
	sectionHolderRef: RefObject<HTMLDivElement>
) {
	const imageHolderRef = useRef<HTMLDivElement>(null);
	const [backgroundStyle, setBackgroundStyle] = useState<CSSProperties>({
		display: "none",
	});

	const onResize = useCallback(() => {
		if (!imageHolderRef.current || !sectionHolderRef.current) {
			return undefined;
		}
		const sectionHolderRect = sectionHolderRef.current.getBoundingClientRect();
		const imageRect = imageHolderRef.current.getBoundingClientRect();
		const offset = imageRect.top - sectionHolderRect.top;
		setBackgroundStyle({
			display: "block",
			top: offset + imageRect.height / 2,
			height: sectionHolderRect.height - imageRect.height / 2 - offset,
		});
	}, [sectionHolderRef]);

	useEffect(() => {
		if (!sectionHolderRef.current || !imageHolderRef.current) {
			return;
		}
		const resizeObserver = new ResizeObserver(onResize);
		resizeObserver.observe(imageHolderRef.current);
		resizeObserver.observe(sectionHolderRef.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, [productRecord, onResize, sectionHolderRef]);

	return { imageHolderRef, backgroundStyle };
}
