import { LogoSkeleton } from "../../../components/Logo";
import { Skeleton } from "../../../components/Skeleton/Skeleton";

export function TenantPreviewSkeleton() {
	return (
		<div className="flex w-full items-center gap-2 overflow-hidden p-3">
			<LogoSkeleton size="md" />
			<div className="flex w-full flex-col items-start gap-1">
				<Skeleton className="h-4 w-full" />
				<Skeleton className="h-3.5 w-20" />
			</div>
		</div>
	);
}
