import { getSDRecordFieldValue, SDRecord } from "@salesdesk/salesdesk-schemas";
import { format, isToday, isTomorrow, parse, startOfMonth } from "date-fns";

export function getMeetingStartTimestamp(meetingRecord: SDRecord, fieldId: number): number | undefined {
	const fieldValue = getSDRecordFieldValue(meetingRecord, fieldId)?._value;
	if (
		!fieldValue ||
		typeof fieldValue !== "object" ||
		!("start" in fieldValue) ||
		!fieldValue.start ||
		typeof fieldValue.start !== "number"
	) {
		return undefined;
	}
	return fieldValue.start;
}

export function getMeetingStartDate(meetingRecord: SDRecord, fieldId: number): Date | undefined {
	const startTimestamp = getMeetingStartTimestamp(meetingRecord, fieldId);
	if (!startTimestamp) return undefined;
	return new Date(startTimestamp);
}

export function formatDateLabel(meetingStartDate: Date | undefined): string {
	if (!meetingStartDate) return "";
	if (isToday(meetingStartDate)) return "Today";
	if (isTomorrow(meetingStartDate)) return "Tomorrow";
	return format(meetingStartDate, "dd/MM");
}

export function toMonthKey(date: Date): string {
	return format(date, "yyyy-MM");
}

export function parseMonthKey(monthKey: string): Date {
	return startOfMonth(parse(monthKey, "yyyy-MM", new Date()));
}
