import { PropsWithChildren, useState, useCallback } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { ToggleUserLoginContext } from "../../hooks/useToggleUserLoginContext";
import { InviteUserModal } from "./InviteUserModal";
import { DeactivateLoginModal } from "./DeactivateLoginModal";

export function ToggleUserLoginProvider({ children }: PropsWithChildren) {
	const [userRecordToInvite, setUserRecordToInvite] = useState<SDRecord | undefined>();
	const [userRecordToDeactivateLogin, setUserRecordToDeactivateLogin] = useState<SDRecord | undefined>();
	const [isReinviteUser, setIsReinviteUser] = useState(false);

	const handleSetUserRecordToInvite = useCallback((record?: SDRecord, isReinvite?: boolean) => {
		setUserRecordToInvite(record);
		setIsReinviteUser(Boolean(isReinvite));
	}, []);

	return (
		<ToggleUserLoginContext.Provider
			value={{
				setUserRecordToInvite: handleSetUserRecordToInvite,
				setUserRecordToDeactivateLogin,
			}}
		>
			{children}
			{userRecordToInvite ? (
				<InviteUserModal
					userRecord={userRecordToInvite}
					isReinvite={isReinviteUser}
					onClose={() => setUserRecordToInvite(undefined)}
				/>
			) : null}
			{userRecordToDeactivateLogin ? (
				<DeactivateLoginModal
					userRecord={userRecordToDeactivateLogin}
					onClose={() => setUserRecordToDeactivateLogin(undefined)}
				/>
			) : null}
		</ToggleUserLoginContext.Provider>
	);
}
