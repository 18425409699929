import { createUniqueId } from "@salesdesk/salesdesk-utils";
import { Field, OptionValue, SDObject } from "../../../object";
import {
	FIELD_CREATION_TYPE,
	mAssetDef,
	mContactDef,
	mInteractionMeetingDef,
	mIssueDef,
	mLeadDef,
	mObjectDef,
	mOpportunityDef,
	mSalesDeskCustomerDef,
	mSalesDeskUserDef,
	mTaskDef,
	mUserDef,
} from "@salesdesk/salesdesk-model";

export type GetSDObjectFieldsOptions = {
	includeHidden?: boolean;
	includeReadOnly?: boolean;
	creationType?: Field["_creationType"];
};

const DEFAULT_GET_OBJECT_FIELDS: GetSDObjectFieldsOptions = {
	includeHidden: false,
	includeReadOnly: true,
	creationType: undefined,
};

export function getAllSDObjectFields(sdObject: SDObject) {
	return getSDObjectFields(sdObject, { includeHidden: true, includeReadOnly: true });
}

export function getSDObjectFields(sdObject: SDObject, options?: GetSDObjectFieldsOptions): Field[] {
	const filterOptions = { ...DEFAULT_GET_OBJECT_FIELDS, ...(options || {}) };

	const sdObjectFields = sdObject._dataDef._children;

	return sdObjectFields.filter((field) => {
		if (!filterOptions.includeHidden && field._hidden) return false;
		if (!filterOptions.includeReadOnly && !field._editable) return false;
		return !(filterOptions.creationType && field._creationType !== filterOptions.creationType);
	});
}

export function getSDObjectContainerFieldId(sdObject: SDObject): number {
	return sdObject._dataDef._id;
}

export function getSDObjectFieldById(sdObject: SDObject, fieldId: Field["_id"]): Field | undefined {
	return getSDObjectFields(sdObject, { includeHidden: true, includeReadOnly: true }).find(
		(field) => field._id === fieldId
	);
}

export function getSDObjectFieldByName(
	sdObject: SDObject,
	fieldName: string,
	options?: GetSDObjectFieldsOptions
): Field | undefined {
	const overriddenOptions = { includeHidden: true, includeReadOnly: true, ...options };
	return getSDObjectFields(sdObject, overriddenOptions).find((field) => field._name === fieldName);
}

export function getSDObjectSystemFieldByName(sdObject: SDObject, fieldName: string): Field | undefined {
	return getSDObjectFieldByName(sdObject, fieldName, { creationType: FIELD_CREATION_TYPE.SYSTEM });
}

export function getSDObjectFieldsByNameMap(sdObject: SDObject) {
	return getSDObjectFields(sdObject, { includeHidden: true, includeReadOnly: true }).reduce(
		(fieldsByNameMap, field) => {
			fieldsByNameMap[field._name] = field;

			return fieldsByNameMap;
		},
		{} as Record<string, Field>
	);
}

export function getFieldsByNames(sdObject: SDObject, fieldNames: string[]) {
	const fields = [];
	for (const fieldName of fieldNames) {
		const field = getSDObjectFieldByName(sdObject, fieldName);
		if (field) {
			fields.push(field);
		}
	}
	return fields;
}

export function getFieldIdsFromFieldNames(sdObject: SDObject, fieldNames: string[]) {
	return getFieldsByNames(sdObject, fieldNames).map((field) => field._id);
}

export function getSDObjectNameFieldNames(sdObject: SDObject): string[] {
	switch (sdObject._id) {
		case mLeadDef.ID:
		case mContactDef.ID:
		case mUserDef.ID:
		case mSalesDeskUserDef.ID:
		case mSalesDeskCustomerDef.ID:
			return [mUserDef.FIRST_NAME_FIELD_NAME, mUserDef.SURNAME_FIELD_NAME];
		case mInteractionMeetingDef.ID:
			return [mInteractionMeetingDef.TITLE_FIELD_NAME];
	}

	return [mObjectDef.NAME_FIELD_NAME];
}

export function getSDObjectNameFieldIds(sdObject: SDObject) {
	const nameFieldNames = getSDObjectNameFieldNames(sdObject);

	const fieldIds: Field["_id"][] = [];
	const fields = getSDObjectFields(sdObject);

	nameFieldNames.forEach((fieldName) => {
		const field = fields.find((field) => field._name === fieldName);

		if (field) {
			fieldIds.push(field._id);
		}
	});

	return fieldIds;
}

export function getSDObjectSyncOptionFieldOptions(
	object: SDObject,
	optionFieldName: string,
	options?: GetSDObjectFieldsOptions
) {
	const optionField = getSDObjectFieldByName(object, optionFieldName, options);

	return {
		field: optionField,
		options: (optionField?._optionValues || []).map((option) => ({
			id: option.id ?? createUniqueId(),
			name: option.name,
			value: optionField ? [{ match: { [optionField._id]: option.id } }] : [],
		})),
	};
}

export function getSDObjectFieldOptionByName(
	sdObject: SDObject,
	optionFieldName: string,
	optionName: string
): OptionValue | undefined {
	const optionField = getSDObjectFieldByName(sdObject, optionFieldName);

	return optionField?._optionValues?.find((option) => option.name === optionName);
}

export function getSDObjectFieldOptionIdByName(
	sdObject: SDObject,
	optionFieldName: string,
	optionName: string
): number | undefined {
	return getSDObjectFieldOptionByName(sdObject, optionFieldName, optionName)?.id;
}

export function getSDObjectSystemSyncOptionFieldOptions(object: SDObject, optionFieldName: string) {
	return getSDObjectSyncOptionFieldOptions(object, optionFieldName, { creationType: FIELD_CREATION_TYPE.SYSTEM });
}

export function sdObjectIsBaseType(sdObject: SDObject) {
	return sdObject._id === sdObject._baseType;
}

export function sdObjectIsSystemType(sdObject: SDObject) {
	return sdObject._type === mObjectDef.CREATION_TYPE.SYSTEM;
}

export function sdObjectIsUserType(sdObject: SDObject) {
	return sdObject._type === mObjectDef.CREATION_TYPE.USER;
}

export function sdObjectHasSubTypes(sdObject: SDObject) {
	return sdObject._subtypes.length > 0;
}

export function isAssetObject(sdObject: SDObject) {
	return [sdObject._id, sdObject._baseType].includes(mAssetDef.ID);
}

export function getStatusIndicatorFieldForObject(sdObjectId: SDObject["_id"]) {
	let fieldName: string | undefined;
	let outcomeNames: string[] | undefined;

	switch (sdObjectId) {
		case mOpportunityDef.ID:
			fieldName = mOpportunityDef.STAGE_FIELD_NAME;
			outcomeNames = [mOpportunityDef.CLOSED_WON_STAGE_NAME, mOpportunityDef.CLOSED_LOST_STAGE_NAME];
			break;
		case mTaskDef.ID:
			fieldName = mTaskDef.STATUS_FIELD_NAME;
			outcomeNames = [mTaskDef.COMPLETED_STATUS_NAME];
			break;
		case mIssueDef.ID:
			fieldName = mIssueDef.STATUS_FIELD_NAME;
			outcomeNames = [mIssueDef.CLOSED_STATUS_NAME];
			break;
		case mLeadDef.ID:
			fieldName = mLeadDef.STATUS_FIELD_NAME;
			outcomeNames = [mLeadDef.QUALIFIED_STATUS_NAME, mLeadDef.UNQUALIFIED_STATUS_NAME];
			break;
	}

	return {
		fieldName,
		outcomeNames,
	};
}
