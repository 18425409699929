import { PropsWithChildren } from "react";
import { Branding } from "./Branding";
import clsx from "clsx";

import { TermsAndConditionsLink, PrivacyPolicyLink } from "../../authorization/components";

interface BrandedLandingPageLayoutProps {
	footerVariant?: "signup" | "default";
	contentContainerClassName?: string;
}

export function BrandedLandingPageLayout({
	children,
	contentContainerClassName,
	footerVariant,
}: PropsWithChildren<BrandedLandingPageLayoutProps>) {
	return (
		<div className="grid h-screen w-full overflow-auto md:grid-cols-2">
			<div className={clsx("flex max-h-full flex-col justify-between px-4", contentContainerClassName)}>
				<div className="flex h-full flex-col overflow-hidden">
					<div className="-mb-3 flex h-[calc(100vh-692px)] max-h-[190px] min-h-[90px] shrink justify-center">
						<img className="max-w-[121px]" src="/static/images/sd_logo_full.svg" alt="SalesDesk logo" />
					</div>
					{children}
				</div>
				<div className="flex justify-center">
					<div className="text-c_text_disabled text-body-sm w-full max-w-[440px] py-12 text-center lg:pb-16">
						{footerVariant === "signup" ? (
							<>
								By signing up you accept our&nbsp;
								<TermsAndConditionsLink />
								&nbsp;and acknowledge you have read our&nbsp;
								<PrivacyPolicyLink />
							</>
						) : (
							<div className="flex justify-center gap-3">
								<TermsAndConditionsLink />
								<PrivacyPolicyLink />
							</div>
						)}
					</div>
				</div>
			</div>
			<Branding />
		</div>
	);
}
