import { asConst } from "json-schema-to-ts";
import { utcMillisecSchema } from "./utcMillisecSchema";
import { userIdSchema } from "./userIdSchema";

export const creatableSchema = asConst({
	title: "creatableSchema",
	description: "Provides consistent naming for created user and date fields",
	type: "object",
	additionalProperties: false,
	required: ["createdAt", "createdBy"],
	properties: {
		createdAt: utcMillisecSchema,
		createdBy: userIdSchema,
	},
});
