import { isLaterThanThisMonth, isThisMonth, isThisWeek, isBeforeNow } from "@salesdesk/salesdesk-utils";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { isToday } from "date-fns";

export const FIELD_TYPES = {
	STRING: {
		name: "string",
		displayName: "Text",
		icon: ICONS.text,
	},
	RICH_TEXT: {
		name: "rich_text",
		displayName: "Rich text",
		icon: ICONS.paragraph,
	},
	INTEGER: {
		name: "integer",
		displayName: "Integer",
		icon: ICONS.numberCircleFour,
	},
	FLOAT: {
		name: "float",
		displayName: "Float",
		icon: ICONS.table,
	},
	BOOLEAN: {
		name: "boolean",
		displayName: "Boolean",
		icon: ICONS.toggleRight,
	},
	DATE: {
		name: "date",
		displayName: "Date",
		icon: ICONS.calendar,
	},
	DATE_TIME: {
		name: "date_time",
		displayName: "Date & time",
		icon: ICONS.clock,
	},
	TIME_RANGE: {
		name: "time_range",
		displayName: "Time range",
		icon: ICONS.clock,
	},
	DURATION: {
		name: "duration",
		displayName: "Duration",
		icon: ICONS.hourglass,
	},
	CURRENCY_VALUE: {
		name: "currency_value",
		displayName: "Currency value",
		icon: ICONS.currencyCircleDollar,
	},
	PHONE_NUMBER: {
		name: "phone_number",
		displayName: "Phone number",
		icon: ICONS.phone,
	},
	EMAIL: {
		name: "email",
		displayName: "Email",
		icon: ICONS.envelope,
	},
	URL: {
		name: "url",
		displayName: "URL",
		icon: ICONS.link,
	},
	FILE: {
		name: "file",
		displayName: "File",
		icon: ICONS.file,
	},
	IMAGE: {
		name: "image",
		displayName: "Image",
		icon: ICONS.image,
	},
	VIDEO: {
		name: "video",
		displayName: "Video",
		icon: ICONS.video,
	},
	PDF: {
		name: "pdf",
		displayName: "PDF",
		icon: ICONS.pdf,
	},
	PRIORITY: {
		name: "priority",
		displayName: "Priority",
		icon: ICONS.warningCircle,
	},
	PROBABILITY: {
		name: "probability",
		displayName: "Probability",
		icon: ICONS.diceFive,
	},
	RATING: {
		name: "rating",
		displayName: "Rating",
		icon: ICONS.star,
	},
	PROGRESS: {
		name: "progress",
		displayName: "Progress",
		icon: ICONS.percent,
	},
	COLOR: {
		name: "color",
		displayName: "Color",
		icon: ICONS.palette,
	},
	COUNTRY: {
		name: "country",
		displayName: "Country",
		icon: ICONS.globeWest,
	},
	ICON: {
		name: "icon",
		displayName: "Icon",
		icon: ICONS.sticker,
	},
	ICON_CHOOSER: {
		name: "icon_chooser",
		displayName: "Icon Chooser",
		icon: ICONS.sticker,
	},
	CURRENCY: {
		name: "currency",
		displayName: "Currency",
		icon: ICONS.currencyDollar,
	},
	PASSWORD: {
		name: "password",
		displayName: "Password",
		icon: ICONS.password,
	},
	SINGLE_OPTION: {
		name: "single_option",
		displayName: "Single option",
		icon: ICONS.checkSquare,
	},
	MULTI_OPTION: {
		name: "multi_option",
		displayName: "Multiple option",
		icon: ICONS.listChecks,
	},
	OPTIONS_BUILDER: {
		name: "options_builder",
		displayName: "Options builder",
		icon: ICONS.tag,
	},
	// TODO: I don't think this is used.  Can it be deleted?
	CONTAINER: {
		name: "container",
		displayName: "Container",
		icon: "fa-brackets",
	},
	OBJECT: {
		name: "object",
		displayName: "Object",
		icon: ICONS.cube,
	},
	OBJECTS: {
		name: "objects",
		displayName: "Objects",
		icon: ICONS.shapes,
	},
	ROLES: {
		name: "roles",
		displayName: "Roles",
		icon: ICONS.lock,
	},
	DATA: {
		name: "data",
		displayName: "Data",
		icon: undefined,
	},
	PROFILE_PHOTO: {
		name: "profile_photo",
		displayName: "Profile photo",
		icon: ICONS.userCircle,
	},
	LOGO: {
		name: "logo",
		displayName: "Logo",
		icon: ICONS.image,
	},
} as const;

export type FieldTypeName = (typeof FIELD_TYPES)[keyof typeof FIELD_TYPES]["name"];

export function getFieldDisplayName(type: FieldTypeName) {
	const typeKey = type.toUpperCase() as keyof typeof FIELD_TYPES;
	return FIELD_TYPES[typeKey]?.displayName;
}

export const TEXT_FIELDS: FieldTypeName[] = [
	FIELD_TYPES.STRING.name,
	FIELD_TYPES.RICH_TEXT.name,
	FIELD_TYPES.PHONE_NUMBER.name,
	FIELD_TYPES.EMAIL.name,
	FIELD_TYPES.URL.name,
	FIELD_TYPES.PASSWORD.name,
];

export const NUMERIC_FIELDS: FieldTypeName[] = [
	FIELD_TYPES.INTEGER.name,
	FIELD_TYPES.FLOAT.name,
	FIELD_TYPES.DURATION.name,
	FIELD_TYPES.CURRENCY_VALUE.name,
	FIELD_TYPES.PROBABILITY.name,
	FIELD_TYPES.PROGRESS.name,
];

export const SINGLE_OPTION_FIELDS: FieldTypeName[] = [
	FIELD_TYPES.SINGLE_OPTION.name,
	FIELD_TYPES.PRIORITY.name,
	FIELD_TYPES.OBJECT.name,
	FIELD_TYPES.COUNTRY.name,
	FIELD_TYPES.CURRENCY.name,
];

export const MULTI_OPTION_FIELDS: FieldTypeName[] = [
	FIELD_TYPES.MULTI_OPTION.name,
	FIELD_TYPES.OBJECTS.name,
	FIELD_TYPES.ROLES.name,
];

export const FILE_FIELD_TYPES = [
	FIELD_TYPES.FILE,
	FIELD_TYPES.IMAGE,
	FIELD_TYPES.PDF,
	FIELD_TYPES.VIDEO,
	FIELD_TYPES.LOGO,
	FIELD_TYPES.PROFILE_PHOTO,
];

export const ANY_MIME_TYPE = "*";

export const FILE_FIELD_MIME_TYPES = {
	GENERAL: [ANY_MIME_TYPE],
	IMAGE: ["image/jpeg", "image/png", "image/gif"],
	PDF: ["application/pdf"],
	VIDEO: ["video/mp4", "video/quicktime"],
};

export const FILE_FIELDS: FieldTypeName[] = FILE_FIELD_TYPES.map((field) => field.name);

export function isNumericField(fieldTypeName: FieldTypeName): boolean {
	return NUMERIC_FIELDS.includes(fieldTypeName);
}

export const SORTABLE_FIELD_TYPES = [
	...TEXT_FIELDS,
	...NUMERIC_FIELDS,
	FIELD_TYPES.DATE.name,
	FIELD_TYPES.DATE_TIME.name,
	FIELD_TYPES.RATING.name,
];

export type FieldType = (typeof FIELD_TYPES)[keyof typeof FIELD_TYPES];

export type FieldTypeGroup = { label: string; fields: FieldType[] };

export const FIELD_TYPE_GROUPS: FieldTypeGroup[] = [
	{
		label: "Basic",
		fields: [
			FIELD_TYPES.STRING,
			FIELD_TYPES.RICH_TEXT,
			FIELD_TYPES.INTEGER,
			FIELD_TYPES.FLOAT,
			FIELD_TYPES.BOOLEAN,
			FIELD_TYPES.DATE,
			FIELD_TYPES.DATE_TIME,
			FIELD_TYPES.TIME_RANGE,
			FIELD_TYPES.DURATION,
			FIELD_TYPES.CURRENCY_VALUE,
			FIELD_TYPES.PROBABILITY,
			FIELD_TYPES.PROGRESS,
			FIELD_TYPES.RATING,
		],
	},
	{
		label: "Select",
		fields: [FIELD_TYPES.SINGLE_OPTION, FIELD_TYPES.MULTI_OPTION, FIELD_TYPES.PRIORITY],
	},
	{
		label: "Resource",
		fields: [FIELD_TYPES.EMAIL, FIELD_TYPES.PHONE_NUMBER, FIELD_TYPES.URL, FIELD_TYPES.COLOR, FIELD_TYPES.ICON],
	},
	{
		label: "Region",
		fields: [FIELD_TYPES.COUNTRY, FIELD_TYPES.CURRENCY],
	},
	{
		label: "File",
		fields: FILE_FIELD_TYPES,
	},
	{
		label: "Object",
		fields: [FIELD_TYPES.OBJECT, FIELD_TYPES.OBJECTS],
	},
	{
		label: "User",
		fields: [FIELD_TYPES.PROFILE_PHOTO],
	},
];

export const DATE_GROUPING = [
	{
		id: 0,
		name: "Today",
		icon: "fa-calendar-day",
		color: "#579bfc",
		contains: isToday,
	},
	{
		id: 1,
		name: "This Week",
		icon: "fa-calendar-week",
		color: "#4eccc6",
		contains: isThisWeek,
	},
	{
		id: 2,
		name: "This Month",
		icon: "fa-calendar-days",
		color: "#9aadbd",
		contains: isThisMonth,
	},
	{
		id: 3,
		name: "Later",
		icon: "fa-calendar-check",
		color: "#808080",
		contains: isLaterThanThisMonth,
	},
	{
		id: 4,
		name: "Missed",
		icon: "fa-alarm-exclamation",
		color: "#ff158a",
		contains: isBeforeNow,
	},
];
