import { PropsWithChildren, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { RecordDeleteContext } from "../hooks/useRecordDeleteContext";
import { RecordDeleteDialog } from "./RecordDeleteDialog";

export function RecordDeleteDialogProvider({ children }: PropsWithChildren) {
	const [recordToDelete, setRecordToDelete] = useState<SDRecord | undefined>();

	return (
		<RecordDeleteContext.Provider
			value={{
				setRecordToDelete,
			}}
		>
			{children}
			{recordToDelete ? (
				<RecordDeleteDialog sdRecord={recordToDelete} onClose={() => setRecordToDelete(undefined)} />
			) : null}
		</RecordDeleteContext.Provider>
	);
}
