import { useCallback, useRef } from "react";
import { Room } from "twilio-video";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDevices, useLocalVideoToggle } from "../VideoCallProvider";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

interface ToggleVideoButtonProps {
	disabled?: boolean;
	room?: Room | null;
}

export function ToggleVideoButton({ disabled = false, room }: ToggleVideoButtonProps) {
	const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(room || null);
	const lastClickTimeRef = useRef(0);
	const { hasVideoInputDevices } = useDevices();

	const toggleVideo = useCallback(() => {
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now();
			toggleVideoEnabled();
		}
	}, [toggleVideoEnabled]);

	return (
		<Tooltip text={isVideoEnabled ? "Turn off camera" : "Turn on camera"} placement="top">
			<Button
				size="lg"
				variant="secondary"
				active={!isVideoEnabled}
				onClick={toggleVideo}
				disabled={!hasVideoInputDevices || disabled}
				startIcon={isVideoEnabled ? ICONS.videoCamera : ICONS.videoCameraOff}
				data-cy-video-toggle
			/>
		</Tooltip>
	);
}
