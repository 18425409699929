import { forwardRef, useCallback, MouseEvent } from "react";
import { clsx } from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { AccessibleButtonDiv } from "../AccessibleButtonDiv";

interface CompleteMarkProps {
	value: boolean;
	onChange: (value: boolean) => void;
}

export const CompleteMark = forwardRef<HTMLDivElement, CompleteMarkProps>(({ value, onChange }, ref) => {
	const toggleCheckbox = useCallback(
		(event?: MouseEvent<HTMLDivElement>) => {
			event?.stopPropagation();
			onChange(!value);
		},
		[value, onChange],
	);

	return (
		<AccessibleButtonDiv
			ref={ref}
			onClick={toggleCheckbox}
			className={clsx(
				value
					? "bg-c_success_02 border-c_success_02 hover:bg-c_success_01 hover:border-c_success_01"
					: "bg-c_bg_01 border-c_icon_regular/50 hover:border-c_success_02",
				"group/check inline-flex size-5 flex-shrink-0 items-center justify-center rounded-full border-2 p-1",
				"focus-visible:ring-c_action_focus transition-colors focus-visible:ring focus-visible:ring-offset-2",
			)}
		>
			<Icon
				icon={ICONS.check}
				className={clsx(
					value ? "text-c_text_inverted" : "group-hover/check:text-c_success_02 text-transparent",
					"flex transition-colors",
				)}
				size="xm"
				fontWeight="bold"
			/>
		</AccessibleButtonDiv>
	);
});
