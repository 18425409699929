import { asConst } from "json-schema-to-ts";
import { noteIdSchema } from "./noteIdSchema";
import { noteRequestSchema } from "./noteRequestSchema";
import { noteReactionSchema } from "./reactions";
import { updatableSchema, creatableSchema, utcMillisecSchema, userIdSchema } from "../components";
import { recordIdSchema } from "../records";

export const noteSchema = asConst({
	title: "Note",
	description: "A note posted by a user on a SalesDesk record",
	type: "object",
	additionalProperties: false,
	required: ["id", "reactions", ...noteRequestSchema.required, ...updatableSchema.required],
	properties: {
		id: noteIdSchema,
		...noteRequestSchema.properties,
		workspaceId: {
			...recordIdSchema,
			description: "Only send notifications for this workspace",
		},
		reactions: { type: "array", items: noteReactionSchema },
		...creatableSchema.properties,
		updatedAt: utcMillisecSchema,
		updatedBy: userIdSchema,
	},
});
