import { useHasMounted } from "@salesdesk/daisy-ui";
import { SelectOption, SelectOptionId, SelectOptionItem } from "../../inputs";

interface MentionOptionProps {
	id: string;
	option: SelectOption;
	onClick: (option: SelectOption) => void;
	onMouseOver: (option: SelectOption) => void;
	activeOptionId?: SelectOptionId;
}

export function MentionOption({ id, option, onClick, onMouseOver, activeOptionId }: MentionOptionProps) {
	const hasMounted = useHasMounted();
	return (
		<div
			id={id}
			key={option.id}
			onMouseOver={() => {
				// For some reason the onMouseOver is called by the browser when the item first
				// mounts even though the mouse is not over it.
				if (hasMounted) {
					onMouseOver(option);
				}
			}}
			className="scroll-mt-10"
			onClick={() => onClick(option)}
		>
			<SelectOptionItem
				option={option}
				active={option.id === activeOptionId}
				variant={option.sdRecord ? "user_pill" : undefined}
				withinPopover={true}
			/>
		</div>
	);
}
