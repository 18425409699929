import { useMemo } from "react";
import { LogoFieldValue, LogoInfo } from "../types";

export function useLogoFieldValue(value?: LogoFieldValue): LogoInfo {
	return useMemo(() => {
		if (value && typeof value !== "number" && "companyName" in value) {
			return value;
		}

		return {
			fileId: value ?? null,
			companyName: "",
		};
	}, [value]);
}
