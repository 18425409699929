import { APP_CONFIG } from "../../../../app/app_config";

/* 
	TODO: Add not required 'redirectUrl?: string' param to ensure user is redirected to the correct page after 
	following specific url (e.g. link to record, link to meeting, etc.)
*/
export function googleAuthLink() {
	const search = new URLSearchParams();
	search.set("identity_provider", "Google");
	search.set("redirect_uri", window.location.origin);
	search.set("response_type", "CODE");
	search.set("client_id", APP_CONFIG.cognitoClientId);
	search.set("state", window.location.origin + "/start");
	search.set("scope", "aws.cognito.signin.user.admin email openid phone profile");
	return APP_CONFIG.authUrl + "?" + search.toString();
}

export function isGoogleSignInEnabled() {
	return APP_CONFIG.googleSignInEnabled as boolean;
}
