import { useEffect, useState } from "react";
import { useUserback } from "@userback/react";
import userflow from "userflow.js";
import { setDefaultOptions } from "date-fns";
import { enGB } from "date-fns/locale";

import { getSDRecordName, BASE_ROUTES } from "@salesdesk/salesdesk-schemas";

import { APP_CONFIG } from "../app/app_config";
import { App } from "../app/shared/app";

import { Auth, WebPrincipal } from "../auth";
import { useLazyGetRecordQuery } from "../features/records";
import { useUserObjectsMap } from "../features/users";
import { getTenantFromPath } from "../utils";
import { useStableNavigate } from "../routes";

export function useAppInitialiser(initialiseApp: boolean) {
	const [isInitialised, setIsInitialised] = useState(false);
	const [hasCredentials, setHasCredentials] = useState(false);
	const [hasClaimsPrincipal, setHasClaimsPrincipal] = useState(false);

	const { identify } = useUserback();

	const [getRecord] = useLazyGetRecordQuery();

	// Only fetches the user object map when the app has the user credentials
	// so the request doesn't fail
	const userObjectMap = useUserObjectsMap(!hasCredentials);

	const navigate = useStableNavigate();

	useEffect(() => {
		if (!hasClaimsPrincipal || !userObjectMap) {
			return;
		}

		// TODO: Uses `getSDRecordName` instead of the FullName on the webprincipal, since
		// the webprincipal is currently using the wrong 'name' (userRecord._name) instead of
		// getting the name from the record fields, so it won't update correctly if the user's
		// name is changed: https://salesdesk101.atlassian.net/browse/SAL-2277
		const userRecord = Auth.getUser();
		const prinicipal = Auth.getPrincipal();

		const userSDObject = userObjectMap ? userObjectMap.get(userRecord._objectDefId) : null;
		const name = userSDObject ? getSDRecordName(userSDObject, userRecord) : "";

		identifyUser(prinicipal, name, identify);
	}, [hasClaimsPrincipal, identify, userObjectMap]);

	useEffect(() => {
		if (!initialiseApp) {
			return;
		}

		// TODO: Update date/time locale based on user location/browser settings
		setDefaultOptions({ locale: enGB });

		App.init(async () => {
			// Initialises userflow
			userflow.init(APP_CONFIG.userflowToken);
			setHasCredentials(true);

			if (Auth.isAuthenticated()) {
				setIsInitialised(true);
				return;
			}

			try {
				const claimsPrincipal = await App.getClaimsPrincipal();
				const userRecord = Auth.getUser() ?? (await getRecord(claimsPrincipal.UserRecordId).unwrap());
				const webPrincipal = new WebPrincipal(userRecord, claimsPrincipal.GetClaims());
				Auth.setPrincipal(webPrincipal);
				Auth.setUser(userRecord);

				setHasClaimsPrincipal(true);
				setIsInitialised(true);
			} catch (error) {
				const errorMessage =
					(error && typeof error === "object" && "message" in error ? error.message : undefined) || "Unknown error";
				console.log("Error while initiating principal:", errorMessage);
				if (errorMessage === "TENANT_NOT_FOUND") {
					navigate(BASE_ROUTES.START);
				} else {
					Auth.logout();
				}
			}
		});
	}, [initialiseApp, getRecord, navigate]);

	return isInitialised;
}

function identifyUser(
	webPrincipal: WebPrincipal,
	name: string,
	userbackIdentify: (user_id: string, user_info: object) => void
) {
	// TODO: For some reason, tenant id is null on the webPrincipal:
	// https://salesdesk101.atlassian.net/browse/SAL-2277
	const userDetails = {
		tenantId: getTenantFromPath(),
		email: webPrincipal.Email,
		name: name || webPrincipal.FullName,
		isCustomerUser: webPrincipal.IsCustomerUser,
	};

	const userId = String(webPrincipal.UserRecordId);

	userbackIdentify(userId, userDetails);
	userflow.identify(userId, userDetails);
}
