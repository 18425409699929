import { ICONS } from "@salesdesk/salesdesk-ui";
import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { Button } from "@salesdesk/daisy-ui";

export function VideoFullScreenToggle() {
	const { variant, toggleFullScreen, isFullscreen } = useVideoPlayerContext();

	return (
		<Button
			startIcon={isFullscreen ? ICONS.cornersIn : ICONS.cornersOut}
			variant="text_inverted"
			size={variant === "expanded" ? "sm" : "xs"}
			onClick={(e) => {
				e.stopPropagation();
				toggleFullScreen();
			}}
		/>
	);
}
