import { PropsWithChildren, ReactNode, useId } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useAccessibleHTMLElementLink } from "../../hooks/ui";
import { tw } from "../../utils/tailwind-helpers";

type FabContainerProps = {
	buttons?: ReactNode;
	onClickLink?: string | undefined;
	onClick?: () => void;
	positionClasses?: string;
	className?: string;
	hide?: boolean;
};

export function FabContainer({
	hide,
	buttons,
	onClickLink,
	onClick,
	children,
	positionClasses = tw`right-1 top-1`,
	className,
}: PropsWithChildren<FabContainerProps>) {
	const linkElementId = useId();
	const handleFabContainerClicked = useAccessibleHTMLElementLink({ linkElementId, onClick });

	if (hide) {
		return children;
	}

	return (
		<div
			className={clsx("group/fab-container relative", onClickLink && "cursor-pointer", className)}
			onClick={handleFabContainerClicked}
			onAuxClick={handleFabContainerClicked}
		>
			{onClickLink ? (
				<Link id={linkElementId} to={onClickLink} className="sr-only">
					Go to record
				</Link>
			) : null}
			{buttons != null ? (
				<div
					className={clsx(
						"bg-c_bg_01 shadow-tooltip absolute box-content flex h-7 items-center gap-1 transition-opacity",
						"focus-visible-within:opacity-100 z-10 rounded-full px-2 leading-4 opacity-0 group-hover/fab-container:opacity-100",
						positionClasses
					)}
				>
					{buttons}
				</div>
			) : null}
			{children}
		</div>
	);
}
