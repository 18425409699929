import { useCallback, useState } from "react";
import { useDeleteCommentMutation } from "../../../api/documentCommentsApi";
import { useToast } from "../../../../../../Toasts";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { Comment, SDRecord } from "@salesdesk/salesdesk-schemas";

export function useDeleteComment(documentRecordId?: SDRecord["_id"]) {
	const workspaceId = useGetContextWorkspaceId();
	const toast = useToast();

	const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation();
	const [commentToBeDeleted, setCommentToBeDeleted] = useState<{
		comment: Comment;
		inDocumentText?: string;
	}>();

	const handleDeleteCommentConfirmation = useCallback(() => {
		if (!commentToBeDeleted?.comment || documentRecordId == null) return;

		deleteComment({ workspaceId, commentId: commentToBeDeleted.comment.id, recordId: documentRecordId })
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "comment_deleted" });
				setCommentToBeDeleted(undefined);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "comment_deleted" });
			});
	}, [commentToBeDeleted, documentRecordId, deleteComment, toast, workspaceId]);

	return {
		commentToBeDeleted,
		handleDeleteCommentConfirmation,
		setCommentToBeDeleted,
		isDeletingComment,
	};
}
