import { PropsWithChildren, useCallback, useMemo } from "react";

import { Button, ButtonSize, ButtonVariant } from "@salesdesk/daisy-ui";
import { AbilityAction, AbilitySubject, SDObject, SDRecord, sdSubject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../../auth";
import { MenuItem, PopoverMenu } from "../../../../menu";
import { useGetObjectMap } from "../../../../objects/hooks";
import { useWorkspaceContext } from "../../../hooks/useWorkspaceContext";
import { RESOURCES_PANEL_PREVENT_CLOSE_CLASS } from "../WorkspaceResources";
import { useRecordCreateAction } from "../../../../records";
import { AssociateRecordDetails } from "../../../../recordAssociations/types";
import { WORKSPACE_CREATABLE_RECORD_TYPES } from "../../../utils";
import { useCreateRecordDialogContext } from "../../../../records/components/RecordCreate/hooks/useCreateRecordDialogContext";

interface AddFromResourcesButtonProps {
	id?: string;
	variant?: ButtonVariant;
	size?: ButtonSize;
	resourcesTitle?: string;
	sdObjectIds: number[];
	associateWith?: SDRecord;
	resourcesOnly?: boolean;
}

export function AddRecordButton({
	id,
	resourcesTitle: resourcesTitleOverride,
	sdObjectIds,
	size = "sm",
	variant = "secondary",
	associateWith,
	resourcesOnly,
	children,
}: PropsWithChildren<AddFromResourcesButtonProps>) {
	const principal = useWebPrincipal();
	const objectMap = useGetObjectMap();

	const { openModal: openCreateRecordDialog } = useCreateRecordDialogContext();

	const defaultAction = useCallback(
		(sdObject: SDObject, associateRecordDetails?: AssociateRecordDetails) => {
			openCreateRecordDialog({
				sdObject,
				initialAssociationMap: associateRecordDetails
					? { [String(associateRecordDetails.association.id)]: [associateRecordDetails.record._id] }
					: undefined,
			});
		},
		[openCreateRecordDialog]
	);

	const { recordCreateActionFn, actionIsLoading } = useRecordCreateAction(defaultAction, associateWith);

	const { workspaceRecord, openResourcesPanel } = useWorkspaceContext();

	const resourcesTitle = useMemo(() => {
		if (resourcesTitleOverride) {
			return resourcesTitleOverride;
		}

		const objectPluralNames: string[] = sdObjectIds
			.map((id) => objectMap.get(id)?._pluralName)
			.filter((name): name is string => Boolean(name));

		let objectNamesString = "";

		if (objectPluralNames.length) {
			const lastObjectName = objectPluralNames.pop() as string;

			objectNamesString =
				objectPluralNames.length > 0 ? `${objectPluralNames.join(", ")} and ${lastObjectName}` : lastObjectName;
		}

		return `Add ${objectNamesString} from Resources`;
	}, [resourcesTitleOverride, sdObjectIds, objectMap]);

	const menuItems = useMemo(() => {
		if (!workspaceRecord) {
			return [];
		}
		const canAttachRecordFromResource = principal.can(AbilityAction.ShareWithWorkspace, AbilitySubject.Record);

		const items: MenuItem[] = [];
		for (const sdObjectId of sdObjectIds) {
			const sdObject = objectMap.get(sdObjectId);
			if (!sdObject) continue;
			const canCreateRecord =
				!resourcesOnly &&
				WORKSPACE_CREATABLE_RECORD_TYPES.includes(sdObject._id) &&
				(principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id })) ||
					sdObject._subtypes?.every((_objectDefId) =>
						principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId }))
					));

			if (!canCreateRecord) {
				continue;
			}

			if (sdObject._subtypes?.length) {
				items.push({
					variant: "default",
					type: "subMenu",
					icon: ICONS.plus,
					text: sdObject._displayName,
					subMenu: sdObject._subtypes.flatMap((subTypeObjectId) => {
						const subTypeObject = objectMap.get(subTypeObjectId);
						if (!subTypeObject) return [];
						return {
							icon: subTypeObject._icon,
							text: subTypeObject._displayName,
							onClick: () => recordCreateActionFn(subTypeObject),
							type: "button",
						};
					}),
				});
			} else {
				items.push({
					variant: "default",
					type: "button",
					icon: ICONS.plus,
					text: `Create new ${sdObject._displayName}`,
					onClick: () => recordCreateActionFn(sdObject),
				});
			}
		}

		if (canAttachRecordFromResource && openResourcesPanel) {
			items.push({
				variant: "default",
				type: "button",
				icon: ICONS.cube,
				text: resourcesTitle,
				onClick: () => {
					openResourcesPanel({
						title: resourcesTitle,
						sdObjectIds,
						associateSelectedRecordsWith: associateWith,
					});
				},
			});
		}
		return items;
	}, [
		objectMap,
		openResourcesPanel,
		principal,
		recordCreateActionFn,
		resourcesOnly,
		resourcesTitle,
		sdObjectIds,
		workspaceRecord,
		associateWith,
	]);

	if (!workspaceRecord || !menuItems.length) {
		return null;
	}

	const sharedButtonProps = {
		id,
		startIcon: ICONS.plus,
		size,
		variant,
		isLoading: actionIsLoading,
	};

	if (menuItems.length === 1 && "subMenu" in menuItems[0]) {
		return (
			<PopoverMenu menuContents={menuItems[0].subMenu}>
				<div className="w-fit">
					<Button {...sharedButtonProps}>{children}</Button>
				</div>
			</PopoverMenu>
		);
	}

	if (menuItems.length > 1) {
		return (
			<PopoverMenu menuContents={menuItems}>
				<div className="w-fit">
					<Button {...sharedButtonProps}>{children}</Button>
				</div>
			</PopoverMenu>
		);
	}

	if (menuItems.length === 1 && menuItems[0].type === "button") {
		return (
			<div className={RESOURCES_PANEL_PREVENT_CLOSE_CLASS}>
				<Button {...sharedButtonProps} onClick={menuItems[0].onClick}>
					{children}
				</Button>
			</div>
		);
	}
}
