import { useState, useLayoutEffect, useRef, useCallback, useMemo, useEffect } from "react";
import { isEqual } from "lodash";

import {
	Field,
	FieldValue,
	getSDRecordFieldValue,
	getSDRecordFieldValues,
	SDObject,
	SDRecord,
} from "@salesdesk/salesdesk-schemas";

import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";
import { useCanEditRecordField, useUpdateRecord } from "../../../hooks";
import { isInlineRecordFieldAlwaysEditable } from "../utils";
import { useToast } from "../../../../Toasts";
import { debounce } from "../../../../../utils";
import { convertFieldToFieldDef } from "../../../../fields";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { formatFERecordFieldValue } from "../../../utils/records";
import { useUniqueCheck } from "../../../hooks/useUniqueCheck";

export function useInlineEditField(sdObject: SDObject, sdRecord: SDRecord, field: Field) {
	const editableFieldRef = useRef<HTMLElement>(null);

	const canEditField = useCanEditRecordField(field, sdObject, sdRecord);
	const alwaysEditable = isInlineRecordFieldAlwaysEditable(field);
	const [editMode, setIsEditable] = useState(canEditField && Boolean(alwaysEditable));

	const updateEditMode = useCallback(
		(editable: boolean) => {
			if (alwaysEditable || !canEditField) return;
			setIsEditable(editable);
		},
		[alwaysEditable, canEditField]
	);

	// Focus on the field when toggling to edit mode
	useLayoutEffect(() => {
		const { current } = editableFieldRef;
		if (editMode && !alwaysEditable && current) {
			current.focus();
			current.click();
		}
	}, [editMode, alwaysEditable]);

	const initialValue = useMemo(() => getSDRecordFieldValue(sdRecord, field._id)?._value ?? null, [sdRecord, field]);

	const [fieldValue, setFieldValue] = useState<unknown>(initialValue);
	const [validationError, setValidationError] = useState<string>();

	const { updateRecord, isLoading } = useUpdateRecord();
	const toast = useToast();

	useEffect(() => {
		setFieldValue((prev: unknown) => {
			return isEqual(prev, initialValue) ? prev : initialValue;
		});
	}, [initialValue, field]);

	const fieldDef = useMemo(() => convertFieldToFieldDef(field), [field]);

	const patchFieldUpdate = useCallback(
		(updatedValue: any) => {
			if (!sdRecord) return;
			const errorMessage = fieldDef.validate(updatedValue, sdRecord.isTemplate);

			if (errorMessage) {
				setValidationError(errorMessage);
				return;
			}

			updateEditMode(false);

			if (isEqual(updatedValue, initialValue)) {
				return;
			}

			const { _id: fieldId } = field;

			const updatedFields = getSDRecordFieldValues(sdRecord).reduce((updatedRecordFields, recordField) => {
				if (recordField._fieldId === fieldId) {
					updatedRecordFields.push({ ...recordField, _value: updatedValue });
				}
				return updatedRecordFields;
			}, [] as FieldValue[]);

			const messageParams = {
				record: sdRecord._objectDefId === mWorkspaceDef.ID ? sdObject._displayName : "Record",
			};

			updateRecord({
				record: sdRecord,
				updatedFields,
			})
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "record_updated", messageParams });
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_updated", messageParams });
					setFieldValue(initialValue);
				});
		},
		[sdRecord, fieldDef, updateEditMode, initialValue, field, sdObject._displayName, updateRecord, toast]
	);

	// Debounces field updates
	const debouncedPatchFieldUpdate = useMemo(() => debounce(patchFieldUpdate, 500), [patchFieldUpdate]);

	const onChange = useCallback(
		(value: any) => {
			const errorMessage = fieldDef.validate(value, sdRecord.isTemplate);

			setValidationError(errorMessage);
			setFieldValue(value);

			if (errorMessage) {
				return;
			}

			if (alwaysEditable) {
				debouncedPatchFieldUpdate(value);
			}
		},
		[fieldDef, alwaysEditable, debouncedPatchFieldUpdate, sdRecord]
	);

	const onBlur = useCallback(() => {
		if (!alwaysEditable) {
			patchFieldUpdate(fieldValue);
		}
	}, [patchFieldUpdate, fieldValue, alwaysEditable]);

	const onCancel = () => {
		const { current } = editableFieldRef;

		if (!current) {
			throw new Error("'editableFieldRef' is not set");
		}

		switch (field._componentType) {
			case FIELD_COMPONENT_TYPES.RICH_TEXT.name:
			case FIELD_COMPONENT_TYPES.TEXT.name:
			case FIELD_COMPONENT_TYPES.TIME_RANGE.name:
			case FIELD_COMPONENT_TYPES.DATE.name:
			case FIELD_COMPONENT_TYPES.DATE_TIME.name:
				setFieldValue(initialValue);
				setIsEditable(false);
				setValidationError(undefined);
				break;
			case FIELD_COMPONENT_TYPES.SELECT.name:
				setIsEditable(false);
				patchFieldUpdate(fieldValue);
				break;
		}
	};

	const onApply = () => {
		const { current } = editableFieldRef;

		if (!current) {
			throw new Error("'editableFieldRef' is not set");
		}

		switch (field._componentType) {
			case FIELD_COMPONENT_TYPES.TEXT.name:
				current.blur();
				setIsEditable(false);
				break;
			case FIELD_COMPONENT_TYPES.TIME_RANGE.name:
			case FIELD_COMPONENT_TYPES.DATE.name:
			case FIELD_COMPONENT_TYPES.DATE_TIME.name:
				current.blur();
				setIsEditable(false);
				patchFieldUpdate(fieldValue);
				break;
			case FIELD_COMPONENT_TYPES.SELECT.name:
				current.click();
				setIsEditable(true);
				break;
		}
	};

	const resetValue = useCallback(() => {
		setFieldValue(initialValue);
		updateEditMode(false);
	}, [initialValue, updateEditMode]);

	// Formats the field value for front-end components as some some fields need extra information
	// to be displayed (e.g. the logo and profile photo fields)
	const feFieldValue = useMemo(() => {
		return formatFERecordFieldValue(sdObject, sdRecord, field, fieldValue);
	}, [field, sdObject, sdRecord, fieldValue]);

	const { uniqueError } = useUniqueCheck({ sdObject, sdRecord, field, fieldValue: feFieldValue });

	const fieldError = validationError || uniqueError;

	return {
		inlineRecordFieldProps: {
			ref: editableFieldRef,
			editMode: canEditField && editMode,
			canEditField,
			updateFieldValue: patchFieldUpdate,
			resetValue,
			field,
			componentProps: {
				value: feFieldValue,
				onChange,
				onBlur,
				onCancel,
				onApply,
				hasError: Boolean(fieldError),
			},
		},
		error: fieldError,
		isLoading,
		alwaysEditable,
		updateEditMode,
	};
}
