import { useMemo } from "react";
import { useUserback } from "@userback/react";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { getTenantFromPath } from "../../../utils";
import { TenantPreview, TenantPreviewSkeleton, useGetLoginChoicesQuery } from "../../tenant";
import { MenuContents, MenuElement } from "../../menu";
import { LoginChoiceMenuItem } from "../components/LoginChoiceMenuItem";
import { useUserSettingsMainMenuItems } from "./useUserSettingsMainMenuItems";

interface UseUserSettingsMenuContentsProps {
	onLogOutClick: () => void;
}

export function useUserSettingsMenuContents({ onLogOutClick }: UseUserSettingsMenuContentsProps): MenuContents {
	const { data: loginChoices } = useGetLoginChoicesQuery();
	const tenant = getTenantFromPath();

	const [currentTenantAccount, otherTenantAccounts] = useMemo(() => {
		if (!loginChoices || !tenant) return [null, []];

		let currentTenantAccount = null;
		const otherTenantAccounts = [];

		for (const choice of loginChoices) {
			if (choice.tenant === tenant) {
				currentTenantAccount = choice;
			} else {
				otherTenantAccounts.push(choice);
			}
		}

		otherTenantAccounts.sort((a, b) => a.accountName.localeCompare(b.accountName));

		return [currentTenantAccount, otherTenantAccounts];
	}, [loginChoices, tenant]);

	const mainSection = useUserSettingsMainMenuItems();
	const { open } = useUserback();

	return useMemo(() => {
		const contents: MenuContents = [mainSection, [{ icon: ICONS.trash, text: "Deleted items", type: "button" }]];

		const sectionItems: MenuElement[] = [
			<div className="min-w-[300px] px-3 pb-2">
				{currentTenantAccount ? (
					<TenantPreview
						accountName={currentTenantAccount.accountName}
						accountLogoUrl={currentTenantAccount.accountLogoUrl}
						isInternalUser={currentTenantAccount.isInternalUser}
					/>
				) : (
					<TenantPreviewSkeleton />
				)}
			</div>,
		];

		if (otherTenantAccounts.length) {
			sectionItems.push({
				text: "Other accounts",
				type: "subMenu",
				subMenu: [
					{
						sectionTitle: "Other accounts",
						boldTitle: true,
						items: otherTenantAccounts.map((choice) => <LoginChoiceMenuItem key={choice.tenant} {...choice} />),
					},
				],
				subMenuPopoverOptions: { widthVariant: "fit", menuVariant: "medium" },
			});
		}

		contents.push({
			sectionTitle: "Active account",
			items: sectionItems,
			boldTitle: true,
		});

		contents.push([
			{ text: "Share feedback", onClick: open, type: "button" },
			{ text: "Log out", onClick: onLogOutClick, type: "button" },
		]);

		return contents;
	}, [mainSection, open, onLogOutClick, currentTenantAccount, otherTenantAccounts]);
}
