import { TextInput } from "@salesdesk/daisy-ui";
import { ButtonSelect, RatingInput, SelectOptionId } from "../../../../inputs";
import { FilterFieldType, FilterValue } from "../types";
import { DateTimeFieldVariant, EditMultiOptionField, EditSingleOptionField } from "../../../../fields";
import { TimeRangeInput } from "../../../../inputs/components/TimeRangeInput";
import { parseTimeRangeParam, stringifyTimeRangeParam } from "../utils";
import { Field, TimeRange } from "@salesdesk/salesdesk-schemas";
import { OptionFieldComponentSettings } from "../../../../fields/components/Fields/OptionField/types";
import { DateFilterInput } from "./DateFilterInput";
import { DirectedSDObjectAssociation } from "../../../../recordAssociations";

export interface FilterInputFactoryProps {
	fieldType: FilterFieldType;
	filterTarget: Field | DirectedSDObjectAssociation;
	value: FilterValue;
	onChange: (newValue: FilterValue) => void;
}

export function FilterInputFactory({ fieldType, filterTarget, value, onChange }: FilterInputFactoryProps) {
	const isAssociationFilter = "connectedObject" in filterTarget;

	const field = isAssociationFilter ? null : filterTarget;

	switch (fieldType) {
		case "text":
		case "number": {
			return <TextInput value={(value as string) ?? ""} onChange={onChange} type={fieldType} />;
		}
		case "date":
		case "date_time":
		case "time_range": {
			if (value !== undefined && typeof value !== "string") {
				return null;
			}
			return <DateFilterInput key={field?._id} fieldType={fieldType} field={field} value={value} onChange={onChange} />;
		}
		case "date_between":
		case "date_time_between":
		case "time_range_between": {
			if (value !== undefined && typeof value !== "string") {
				return null;
			}
			return (
				<TimeRangeInput
					onChange={(newRange) => {
						onChange(stringifyTimeRangeParam(newRange as TimeRange));
					}}
					value={parseTimeRangeParam(value) ?? null}
					variant={
						fieldType === "date_between" || field?._dateOnly
							? DateTimeFieldVariant.DATE
							: DateTimeFieldVariant.DATE_TIME
					}
				/>
			);
		}
		case "single_select": {
			if (Array.isArray(value) || typeof value === "boolean" || !field) {
				return null;
			}

			return (
				<div className="min-w-64">
					<EditSingleOptionField
						value={value}
						onChange={onChange}
						optionFieldSettings={{
							variant: "field",
							field,
						}}
					/>
				</div>
			);
		}
		case "multi_select": {
			if (value !== undefined && !Array.isArray(value)) {
				return null;
			}

			const optionFieldSettings: OptionFieldComponentSettings = isAssociationFilter
				? { variant: "records", baseObjectId: filterTarget.connectedObject.id }
				: { variant: "field", field: filterTarget };

			return (
				<div className="min-w-64">
					<EditMultiOptionField
						value={value ?? []}
						onChange={onChange as (newValue: SelectOptionId[]) => void}
						optionFieldSettings={optionFieldSettings}
					/>
				</div>
			);
		}
		case "boolean":
			if (value === undefined) {
				onChange(true);
				return null;
			} else if (typeof value !== "boolean") {
				return null;
			}
			return (
				<ButtonSelect
					value={value ? "true" : "false"}
					buttonVariant="outlined"
					options={[
						{ id: "true", name: "True" },
						{ id: "false", name: "False" },
					]}
					onChange={(newValue) => {
						onChange(newValue === "true");
					}}
				/>
			);
		case "rating":
			if (value !== undefined && typeof value !== "number") {
				return null;
			}
			return <RatingInput value={value ?? 0} onChange={onChange} />;
		default:
			return null;
	}
}
