import { createContext, useContext } from "react";
import { PanelTabVariant } from "../types";

interface PanelTabListContextType {
	variant: PanelTabVariant;
}

export const PanelTabListContext = createContext<PanelTabListContextType>({ variant: "secondary" });

export function usePanelTabListContext() {
	return useContext(PanelTabListContext);
}
