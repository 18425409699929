import { Editor } from "@tiptap/core";

import { getHexOrCssVariableForColorKey } from "@salesdesk/daisy-ui";
import { BASE_COLORS, ICONS } from "@salesdesk/salesdesk-ui";

export const TOGGLE_ACTIONS = (controlKey: string) => [
	{
		id: "bold",
		icon: ICONS.textBold,
		action: (editor: Editor) => () => editor.chain().focus().toggleBold().run(),
		isActive: (editor: Editor) => editor.isActive("bold"),
		tooltip: `Bold (${controlKey} + B)`,
	},
	{
		id: "italic",
		icon: ICONS.textItalic,
		action: (editor: Editor) => () => editor.chain().focus().toggleItalic().run(),
		isActive: (editor: Editor) => editor.isActive("italic"),
		tooltip: `Italic (${controlKey} + I)`,
	},
	{
		id: "underline",
		icon: ICONS.textUnderline,
		action: (editor: Editor) => () => editor.chain().focus().toggleUnderline().run(),
		isActive: (editor: Editor) => editor.isActive("underline"),
		tooltip: `Underline (${controlKey} + U)`,
	},
	{
		id: "strike",
		icon: ICONS.textStrikethrough,
		action: (editor: Editor) => () => editor.chain().focus().toggleStrike().run(),
		isActive: (editor: Editor) => editor.isActive("strike"),
		tooltip: `Strikethrough (${controlKey} + Shift + S)`,
	},
];

export const LIST_ACTIONS = [
	{
		id: "bulletList",
		icon: ICONS.listBullet,
		action: (editor: Editor) => () => editor.chain().focus().toggleBulletList().run(),
		isActive: (editor: Editor) => editor.isActive("bulletList"),
		tooltip: "Bullet list",
	},
	{
		id: "numberList",
		icon: ICONS.listNumbers,
		action: (editor: Editor) => () => editor.chain().focus().toggleOrderedList().run(),
		isActive: (editor: Editor) => editor.isActive("orderedList"),
		tooltip: "Numbered list",
	},
];

export const TEXT_FORMAT_OPTIONS = [
	{
		id: "body",
		name: "Body text",
		icon: ICONS.text,
		action: (editor: Editor) => editor.chain().focus().setParagraph().run(),
		isActive: (editor: Editor) => editor.isActive("paragraph"),
	},
	{
		id: "heading1",
		name: "Heading 1",
		icon: ICONS.textHeading1,
		action: (editor: Editor) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
		isActive: (editor: Editor) => editor.isActive("heading", { level: 1 }),
	},
	{
		id: "heading2",
		name: "Heading 2",
		icon: ICONS.textHeading2,
		action: (editor: Editor) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
		isActive: (editor: Editor) => editor.isActive("heading", { level: 2 }),
	},
	{
		id: "heading3",
		name: "Heading 3",
		icon: ICONS.textHeading3,
		action: (editor: Editor) => editor.chain().focus().toggleHeading({ level: 3 }).run(),
		isActive: (editor: Editor) => editor.isActive("heading", { level: 3 }),
	},
];

type TextAlignmentTarget = "text" | "image";
export const TEXT_ALIGNMENT_OPTIONS = (controlKey: string, target: TextAlignmentTarget = "text") => {
	const options = [
		{
			id: "left",
			tooltip: `Align Left (${controlKey} + Shift + L)`,
			icon: ICONS.textAlignLeft,
			action: (editor: Editor) => editor.chain().focus().setTextAlign("left").run(),
			isActive: (editor: Editor) => editor.isActive({ textAlign: "left" }),
		},
		{
			id: "center",
			tooltip: `Align Center (${controlKey} + Shift + E)`,
			icon: ICONS.textAlignCenter,
			action: (editor: Editor) => editor.chain().focus().setTextAlign("center").run(),
			isActive: (editor: Editor) => editor.isActive({ textAlign: "center" }),
		},
		{
			id: "right",
			tooltip: `Align Right (${controlKey} + Shift + R)`,
			icon: ICONS.textAlignRight,
			action: (editor: Editor) => editor.chain().focus().setTextAlign("right").run(),
			isActive: (editor: Editor) => editor.isActive({ textAlign: "right" }),
		},
	];
	if (target === "text") {
		options.push({
			id: "justify",
			tooltip: `Justify (${controlKey} + Shift + J)`,
			icon: ICONS.textAlignJustify,
			action: (editor: Editor) => editor.chain().focus().setTextAlign("justify").run(),
			isActive: (editor: Editor) => editor.isActive({ textAlign: "justify" }),
		});
	}
	return options;
};

export const TEXT_HIGHLIGHT_COLORS = [
	{ color: getHexOrCssVariableForColorKey("c_highlight"), isDefault: true },
	{ color: getHexOrCssVariableForColorKey("c_bg_disabled_02") },
	{ color: getHexOrCssVariableForColorKey("c_danger_04") },
	{ color: getHexOrCssVariableForColorKey("c_success_04") },
	{ color: getHexOrCssVariableForColorKey("c_bg_04") },
	{ color: getHexOrCssVariableForColorKey("c_bg_02") },
	{ color: getHexOrCssVariableForColorKey("c_action_04") },
	{ color: getHexOrCssVariableForColorKey("c_action_focus") },
	{ color: getHexOrCssVariableForColorKey("c_action_02") },
];

export const TEXT_HIGHLIGHT_OPTIONS = [
	{
		color: null,
		action: (editor: Editor) => editor.chain().focus().unsetHighlight().run(),
		isActive: (editor: Editor) => !editor.isActive("highlight"),
	},
	...TEXT_HIGHLIGHT_COLORS.map(({ color, isDefault }) => ({
		color,
		action: (editor: Editor) => editor.chain().focus().setHighlight({ color }).run(),
		isActive: (editor: Editor) =>
			editor.isActive("highlight", { color }) || (isDefault && editor.isActive("highlight", { color: null })),
	})),
];

export const TEXT_COLORS = [
	null,
	BASE_COLORS.c_bg_disabled_01,
	BASE_COLORS.c_danger_02,
	"#EA580C",
	BASE_COLORS.c_warning_focus,
	"#65A30D",
	"#0891B2",
	"#2563EB",
	"#7C3AED",
	"#DB2777",
];

export const TEXT_COLOR_OPTIONS = TEXT_COLORS.map((color) => ({
	color,
	action: (editor: Editor) =>
		color ? editor.chain().focus().setColor(color).run() : editor.chain().focus().unsetColor().run(),
	isActive: (editor: Editor) => (color ? editor.isActive("textStyle", { color }) : !editor.isActive("textStyle")),
}));

export const DELETE_OPTION = {
	id: "delete",
	icon: ICONS.trash,
	action: (editor: Editor) => editor.chain().focus().deleteSelection().run(),
	tooltip: "Delete",
};
