import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mIntegerFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TEXT.name];
	static defaultComponentType: FieldComponentName = mIntegerFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mIntegerFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.INTEGER.icon;
		this._supportsDefaultValue = true;
		this._supportsUniqueValue = true;
	}

	override get type() {
		return FIELD_TYPES.INTEGER.name;
	}

	override get supportedComponentTypes() {
		return mIntegerFieldDef.supportedComponentTypes;
	}

	override supportsSummation() {
		return true;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value)) {
			if (!Number.isInteger(parseInt(value, 10))) return "Integer value required";
			else if (!Number.isSafeInteger(Number(value))) return `Integer value is out of range`;
		}

		return super.validate(value, isTemplate);
	}
}
