import { Message } from "amazon-chime-sdk-js";
import {
	BatchCreateChannelMembershipCommand,
	Channel,
	ChannelMembershipForAppInstanceUserSummary,
	ChannelMembershipSummary,
	ChannelMessagePersistenceType,
	ChannelMessageType,
	ChannelMode,
	ChannelPrivacy,
	ChimeSDKMessagingClient,
	CreateChannelCommand,
	DeleteChannelCommand,
	DeleteChannelCommandInput,
	DeleteChannelMembershipCommand,
	DescribeChannelCommand,
	DescribeChannelMembershipForAppInstanceUserCommand,
	DescribeChannelMembershipForAppInstanceUserCommandInput,
	ForbiddenException,
	GetChannelMessageCommand,
	GetChannelMessageCommandInput,
	ListChannelMembershipsCommand,
	ListChannelMembershipsCommandInput,
	ListChannelMembershipsForAppInstanceUserCommand,
	ListChannelMembershipsForAppInstanceUserCommandInput,
	ListChannelMessagesCommand,
	ListChannelMessagesCommandInput,
	RedactChannelMessageCommand,
	SendChannelMessageCommand,
	SendChannelMessageCommandInput,
	ThrottledClientException,
	UpdateChannelCommand,
	UpdateChannelMessageCommand,
	UpdateChannelMessageCommandInput,
	UpdateChannelReadMarkerCommand,
	UpdateChannelReadMarkerCommandInput,
} from "@aws-sdk/client-chime-sdk-messaging";
import { timeoutPromise } from "@salesdesk/salesdesk-utils";
import { ChimeMessageSubscriber } from "./ChimeMessageSubscriber";
import { generateCustomChannelId, getChimeChannelArn, getChimeUserArn, getDirectMessageChannelId } from "./index";
import {
	ChannelMessageMetadata,
	ChannelMetadata,
	ChannelNames,
	ChannelType,
	CustomMetadata,
	DirectMessageMetadata,
} from "../../chime";

const MAX_REQUESTS_PER_SECOND = 4;
const REQUEST_INTERVAL = 1000 / MAX_REQUESTS_PER_SECOND;
const MAX_RETRIES = 3;
const RETRY_DELAY = 4500;

export class ChimeApi {
	private readonly appInstanceArn: string;
	private readonly bearerArn: string;
	private readonly chimeSDKMessagingClient: ChimeSDKMessagingClient;
	private readonly chimeMessageSubscriber?: ChimeMessageSubscriber;

	private requestQueue: (() => Promise<unknown>)[] = [];
	private isProcessingQueue = false;

	constructor(
		appInstanceArn: string,
		bearerArn: string,
		chimeSDKMessagingClient: ChimeSDKMessagingClient,
		chimeMessageSubscriber?: ChimeMessageSubscriber
	) {
		this.appInstanceArn = appInstanceArn;
		this.bearerArn = bearerArn;
		this.chimeSDKMessagingClient = chimeSDKMessagingClient;
		this.chimeMessageSubscriber = chimeMessageSubscriber;
	}

	public subscribeToMessages = (onMessage: (message: Message) => void) =>
		this.chimeMessageSubscriber?.subscribeToMessages(onMessage);
	public unsubscribeFromMessages = (onMessage: (message: Message) => void) =>
		this.chimeMessageSubscriber?.unsubscribeFromMessages(onMessage);

	public convertChannelIdToArn(channelId: string) {
		return getChimeChannelArn(this.appInstanceArn, channelId);
	}

	public convertUserRecordIdToArn(userRecordId: number) {
		return getChimeUserArn(this.appInstanceArn, userRecordId);
	}

	private async processRequestQueue() {
		if (this.isProcessingQueue) return;
		this.isProcessingQueue = true;

		while (this.requestQueue.length) {
			const request = this.requestQueue.shift();

			if (request) {
				await request();
			}

			await timeoutPromise(REQUEST_INTERVAL);
		}

		this.isProcessingQueue = false;
	}

	private enqueueRequest<T>(requestFunc: () => Promise<T>, priority: "high" | "default" = "default"): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			const queueRequest = async () => {
				let attempts = 0;
				while (attempts < MAX_RETRIES) {
					try {
						const result = await requestFunc();
						resolve(result);
						break;
					} catch (err) {
						// Non-throttling errors are thrown and not retried
						if (!(err instanceof ThrottledClientException)) {
							reject(err);
							return;
						}

						attempts++;
						await timeoutPromise(RETRY_DELAY);
					}
				}

				if (attempts >= MAX_RETRIES) {
					reject(new Error("Max retries exceeded"));
				}
			};

			if (priority === "high") {
				this.requestQueue.unshift(queueRequest);
			} else {
				this.requestQueue.push(queueRequest);
			}

			this.processRequestQueue();
		});
	}

	public async listChannelsForCurrentUser() {
		return this.enqueueRequest(async () => {
			const input: ListChannelMembershipsForAppInstanceUserCommandInput = {
				AppInstanceUserArn: this.bearerArn,
				ChimeBearer: this.bearerArn,
			};
			const channels: ChannelMembershipForAppInstanceUserSummary[] = [];

			do {
				const response = await this.chimeSDKMessagingClient.send(
					new ListChannelMembershipsForAppInstanceUserCommand(input)
				);
				channels.push(...(response.ChannelMemberships ?? []));
				input.NextToken = response.NextToken;
			} while (input.NextToken);

			return channels;
		});
	}

	public async describeChannel(channelId: string) {
		return this.enqueueRequest(async () => {
			const input = {
				ChannelArn: getChimeChannelArn(this.appInstanceArn, channelId),
				ChimeBearer: this.bearerArn,
			};

			const response = await this.chimeSDKMessagingClient.send(new DescribeChannelCommand(input));
			return response.Channel;
		});
	}

	public async describeChannelMembershipForMe(channelArn: string) {
		return this.describeChannelMembershipForAppInstanceUserArn(channelArn, this.bearerArn);
	}

	public async describeChannelMembershipForUser(channelArn: string, userRecordId: number) {
		return this.describeChannelMembershipForAppInstanceUserArn(channelArn, this.convertUserRecordIdToArn(userRecordId));
	}

	public async describeChannelMembershipForAppInstanceUserArn(channelArn: string, userArn: string) {
		return this.enqueueRequest(async () => {
			const input: DescribeChannelMembershipForAppInstanceUserCommandInput = {
				ChannelArn: channelArn,
				AppInstanceUserArn: userArn,
				ChimeBearer: this.bearerArn,
			};

			const response = await this.chimeSDKMessagingClient.send(
				new DescribeChannelMembershipForAppInstanceUserCommand(input)
			);

			return response.ChannelMembership;
		});
	}

	public async listChannelMemberships(channelArn: string, subChannelId?: string) {
		return this.enqueueRequest(async () => {
			const input: ListChannelMembershipsCommandInput = {
				ChannelArn: channelArn,
				ChimeBearer: this.bearerArn,
				SubChannelId: subChannelId,
			};
			const channelMembershipSummary: ChannelMembershipSummary[] = [];

			do {
				const response = await this.chimeSDKMessagingClient.send(new ListChannelMembershipsCommand(input));
				channelMembershipSummary.push(...(response.ChannelMemberships ?? []));
				input.NextToken = response.NextToken;
			} while (input.NextToken);

			return channelMembershipSummary;
		}, "high");
	}

	public async updateChannelReadMarker(channelArn: string) {
		return this.enqueueRequest(async () => {
			const input: UpdateChannelReadMarkerCommandInput = {
				ChannelArn: channelArn,
				ChimeBearer: this.bearerArn,
			};
			await this.chimeSDKMessagingClient.send(new UpdateChannelReadMarkerCommand(input));
		}, "high");
	}

	public async listChannelMessages(
		channelArn: string,
		options?: { subChannelId?: string; nextToken?: string; maxResults?: number; isHighPriority?: boolean }
	) {
		return this.enqueueRequest(
			async () => {
				const input: ListChannelMessagesCommandInput = {
					ChannelArn: channelArn,
					ChimeBearer: this.bearerArn,
					SubChannelId: options?.subChannelId,
					NextToken: options?.nextToken,
					MaxResults: options?.maxResults,
				};

				const response = await this.chimeSDKMessagingClient.send(new ListChannelMessagesCommand(input));
				return { Messages: [...(response.ChannelMessages ?? [])], NextToken: response.NextToken };
			},
			options?.isHighPriority ? "high" : "default"
		);
	}

	public async sendChannelMessage(
		params: Pick<SendChannelMessageCommandInput, "ChannelArn"> &
			Partial<
				Pick<SendChannelMessageCommandInput, "Persistence" | "Type" | "SubChannelId"> & {
					Content: unknown | null;
					MetaData: ChannelMessageMetadata;
				}
			>
	) {
		return this.enqueueRequest(async () => {
			const input: SendChannelMessageCommandInput = {
				ChimeBearer: this.bearerArn,
				ChannelArn: params.ChannelArn,
				Content: JSON.stringify(params.Content ?? null),
				Persistence: params.Persistence ?? ChannelMessagePersistenceType.PERSISTENT, // Allowed types are PERSISTENT and NON_PERSISTENT
				Type: params.Type ?? ChannelMessageType.STANDARD, // Allowed types are STANDARD and CONTROL
				SubChannelId: params.SubChannelId,
				Metadata: JSON.stringify(params.MetaData ?? { messageType: "Standard", attachments: { recordIds: [] } }),
			};

			return await this.chimeSDKMessagingClient.send(new SendChannelMessageCommand(input));
		}, "high");
	}

	public async getChannelMessage(channelArn: string, messageId: string, subChannelId?: string) {
		return this.enqueueRequest(async () => {
			const input: GetChannelMessageCommandInput = {
				ChannelArn: channelArn,
				MessageId: messageId,
				ChimeBearer: this.bearerArn,
				SubChannelId: subChannelId,
			};

			const response = await this.chimeSDKMessagingClient.send(new GetChannelMessageCommand(input));
			return response.ChannelMessage;
		});
	}

	public async updateChannelMessage(
		params: Pick<UpdateChannelMessageCommandInput, "ChannelArn" | "MessageId"> &
			Partial<
				Pick<UpdateChannelMessageCommandInput, "SubChannelId"> & {
					Content: unknown | null;
					MetaData: ChannelMessageMetadata;
				}
			>
	) {
		return this.enqueueRequest(async () => {
			const input: UpdateChannelMessageCommandInput = {
				ChimeBearer: this.bearerArn,
				ChannelArn: params.ChannelArn,
				MessageId: params.MessageId,
				Content: JSON.stringify(params.Content ?? null),
				SubChannelId: params.SubChannelId,
				Metadata: JSON.stringify(params.MetaData ?? { messageType: "Standard", attachments: { recordIds: [] } }),
			};

			const response = await this.chimeSDKMessagingClient.send(new UpdateChannelMessageCommand(input));
			return response.Status;
		});
	}

	public async redactMessage(channelArn: string, messageId: string) {
		return this.enqueueRequest(async () => {
			const input = {
				ChannelArn: channelArn,
				MessageId: messageId,
				ChimeBearer: this.bearerArn,
			};

			return await this.chimeSDKMessagingClient.send(new RedactChannelMessageCommand(input));
		}, "high");
	}

	public async createChannel(tenantId: string, memberRecordIds: number[], channelName?: string) {
		if (memberRecordIds.length < 2) {
			throw new Error("At least two members are required to create a channel");
		}

		let metadata: ChannelMetadata;
		let channelId: string;
		let name: string;

		if (memberRecordIds.length === 2) {
			metadata = { channelType: ChannelType.DirectMessage, tenantId } satisfies DirectMessageMetadata;
			channelId = getDirectMessageChannelId(memberRecordIds[0], memberRecordIds[1]);
			name = ChannelNames.NotSet;
		} else {
			metadata = { channelType: ChannelType.Custom, tenantId } satisfies CustomMetadata;
			channelId = generateCustomChannelId();
			name = channelName ?? ChannelNames.NotSet;
		}
		metadata.createdTimestamp = Date.now();

		// This logic is outside the enequeue request to prevent a deadlock where both requests
		// are stuck waiting for each other
		if (metadata.channelType === ChannelType.DirectMessage) {
			let channel: Channel | undefined;

			try {
				channel = await this.describeChannel(channelId);
			} catch (error) {
				// A forbidden exception is expected if the channel does not exist
				if (!(error instanceof ForbiddenException)) {
					throw error;
				}
			}

			if (channel?.ChannelArn) {
				return channel.ChannelArn;
			}
		}

		return this.enqueueRequest(async () => {
			const response = await this.chimeSDKMessagingClient.send(
				new CreateChannelCommand({
					AppInstanceArn: this.appInstanceArn,
					Name: name,
					ChannelId: channelId,
					Mode: ChannelMode.UNRESTRICTED,
					Privacy: ChannelPrivacy.PRIVATE,
					ChimeBearer: this.bearerArn,
					Metadata: JSON.stringify(metadata),
					MemberArns: memberRecordIds.map((memberRecordId) => this.convertUserRecordIdToArn(memberRecordId)),
				})
			);

			if (!response.ChannelArn) {
				throw new Error("ChannelArn not found in response");
			}

			return response.ChannelArn;
		}, "high");
	}
	public async deleteChannel(channelArn: string) {
		return this.enqueueRequest(async () => {
			const input: DeleteChannelCommandInput = {
				ChannelArn: channelArn,
				ChimeBearer: this.bearerArn,
			};

			return await this.chimeSDKMessagingClient.send(new DeleteChannelCommand(input));
		});
	}

	/**
	 * Requires channelMetadata so that it doesn't get overwritten to undefined when updating the channel name
	 */
	public async updateChannelName(channelArn: string, newName: string, channelMetadata: string | undefined) {
		return this.enqueueRequest(async () => {
			const input = {
				ChannelArn: channelArn,
				Name: newName,
				ChimeBearer: this.bearerArn,
				Metadata: channelMetadata,
			};

			return await this.chimeSDKMessagingClient.send(new UpdateChannelCommand(input));
		}, "high");
	}

	public async addMembersToChannel(channelArn: string, memberUserRecordIds: number[]) {
		return this.enqueueRequest(async () => {
			const input = {
				ChannelArn: channelArn,
				MemberArns: memberUserRecordIds.map((userRecordId) => this.convertUserRecordIdToArn(userRecordId)),
				ChimeBearer: this.bearerArn,
			};

			return await this.chimeSDKMessagingClient.send(new BatchCreateChannelMembershipCommand(input));
		}, "high");
	}

	public async removeMemberFromChannel(channelArn: string, memberUserRecordId: number) {
		return this.enqueueRequest(async () => {
			const input = {
				ChannelArn: channelArn,
				MemberArn: this.convertUserRecordIdToArn(memberUserRecordId),
				ChimeBearer: this.bearerArn,
			};

			return await this.chimeSDKMessagingClient.send(new DeleteChannelMembershipCommand(input));
		}, "high");
	}
}
