import { useEventContext } from "../../../hooks/useEventContext";
import { useGetRecordWithObjectByRecordId } from "../../../../../hooks";
import { EventRecordLink } from "../EventRecordLink";

interface LinkObjectProps {
	recordId?: number;
}

export function LinkObject({ recordId }: LinkObjectProps) {
	const { event } = useEventContext();
	const { sdRecord, sdObject } = useGetRecordWithObjectByRecordId(recordId ?? event.record_id);

	return <EventRecordLink sdObject={sdObject} sdRecord={sdRecord} workspaceId={event.workspace_id} />;
}
