import { Button } from "@salesdesk/daisy-ui";
import { AlertDialog } from "../../../Dialog";
import { ImportJobStatus } from "@salesdesk/salesdesk-schemas";
import { useImportContext } from "../../hooks/useImportContext";

interface ExitConfirmDialogProps {
	onConfirm: () => void;
	onDismiss: () => void;
}

export function ExitConfirmDialog({ onConfirm, onDismiss }: ExitConfirmDialogProps) {
	const { actualImportJob } = useImportContext();
	const isActualJobRunning = actualImportJob?.status === ImportJobStatus.Running;

	return (
		<AlertDialog
			open
			title="Exit Import Process?"
			message={`Are you sure you want to exit? ${
				isActualJobRunning
					? "The job will continue in the background until it is completed."
					: "Any progress in the import process will be lost."
			}`}
		>
			{() => (
				<div className="flex items-center justify-end gap-3">
					<Button size="lg" onClick={onDismiss} variant="secondary">
						{isActualJobRunning ? "Stay" : "Continue importing"}
					</Button>
					<Button onClick={onConfirm} size="lg">
						Exit import
					</Button>
				</div>
			)}
		</AlertDialog>
	);
}
