import { useRef } from "react";

import { AssociationLinkingModalProvider, DirectedSDObjectAssociation } from "../../../../../../recordAssociations";
import { useRecordDetailsContext } from "../../../hooks/useRecordDetailsContext";
import { MeetingSummary, RecordDetailsFields, RecordDetailsFooter, RecordTitleField } from "../../common";
import { RecordSidePanelTabs } from "./RecordSidePanelTabs";
import { RecordTopBar } from "./RecordTopBar";
import { AssociationsGroups } from "./AssociationsGroups";

import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { RecordDocumentPreview } from "../../../../../../document";
import { RecordBadges } from "../../../../RecordBadges";
import { useRecordBadges } from "../../../../RecordBadges/hooks/useRecordBadges";
import { useRecordFieldsAndTitle } from "../../common/RecordDetailsFields/hooks/useRecordFieldsAndTitle";

interface RecordSidePanelContentProps {
	onClose: () => void;
	tabbedAssociations: DirectedSDObjectAssociation[];
	groupedAssociations: DirectedSDObjectAssociation[];
}

const INITIAL_FIELDS_DISPLAYED = 5;

export function RecordSidePanelContent({
	onClose,
	tabbedAssociations,
	groupedAssociations,
}: RecordSidePanelContentProps) {
	const workspaceId = useGetContextWorkspaceId();

	const scrollableContainerRef = useRef<HTMLDivElement>(null);

	const { sdObject, sdRecord } = useRecordDetailsContext();

	const { title, recordFields } = useRecordFieldsAndTitle(sdRecord, sdObject);

	const recordBadges = useRecordBadges(sdRecord);

	if (!sdObject || !sdRecord) {
		return null;
	}

	return (
		<div className="flex h-full flex-col overflow-hidden">
			<div className="flex h-full max-h-full flex-col overflow-hidden pl-5 pr-1 pt-5">
				<div className="mb-1 flex flex-col gap-2 pl-1 pr-4 pt-1">
					<RecordTopBar onClose={onClose} />
					<RecordBadges recordBadges={recordBadges} className="ml-auto" gap="base" />
					<RecordTitleField title={title} sdObject={sdObject} sdRecord={sdRecord} />
				</div>
				<div
					className="flex h-full flex-col gap-2 overflow-y-auto overflow-x-hidden pb-4 pl-1 pr-4"
					ref={scrollableContainerRef}
				>
					<RecordDocumentPreview sdRecord={sdRecord} sdObject={sdObject} />
					<RecordDetailsFields
						sdObject={sdObject}
						sdRecord={sdRecord}
						recordFields={recordFields}
						initialFieldsDisplayed={INITIAL_FIELDS_DISPLAYED}
					/>
					<MeetingSummary sdObject={sdObject} sdRecord={sdRecord} />
					<AssociationLinkingModalProvider workspaceId={workspaceId}>
						<AssociationsGroups objectAssociations={groupedAssociations} />
						<RecordSidePanelTabs
							externalScrollContainerRef={scrollableContainerRef}
							objectAssociations={tabbedAssociations}
						/>
					</AssociationLinkingModalProvider>
				</div>
			</div>
			<RecordDetailsFooter />
		</div>
	);
}
