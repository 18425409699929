import { mSystemObjectDef } from "../system_object_def";

export class mTransientDef extends mSystemObjectDef {
	constructor(id, ownerId, context) {
		super(id, ownerId);

		this._transient = true;

		this._contextObject = context?.object;
	}

	// In the case of a transient object def, there may be an
	// underlying object that the def refers to. If this is the case
	// then it can be stored here for access across the object and data def structure.

	get contextObject() {
		return this._contextObject;
	}

	set contextObject(contextObject) {
		this._contextObject = contextObject;
	}
}
