import { SDObject, SDObjectUpdateRequest, SDObjectCreateRequest } from "@salesdesk/salesdesk-schemas";
import { mAssetDef } from "@salesdesk/salesdesk-model";

import { SDApi, buildApiTagsList, buildApiInvalidatedTagList } from "../../api";

export const objectsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			/**
			 * Fetches all object defs
			 */
			getObjects: builder.query<SDObject[], void>({
				query: () => ({
					url: `/objects`,
				}),
				providesTags: (results) => buildApiTagsList(results, (result) => result._id, "Object"),
				// Temporary fix where the base asset object is always first to prevent asset sub type ordering from
				// breaking the current object slug logic
				transformResponse: (response: SDObject[]) => {
					return response.sort((a, b) => (a._id === mAssetDef.ID ? -1 : b._id === mAssetDef.ID ? 1 : 0));
				},
			}),
			/**
			 * Update the object with the given ID
			 */
			updateObject: builder.mutation<SDObject, { sdObject: SDObject; updateData: SDObjectUpdateRequest }>({
				query: ({ sdObject, updateData }) => ({
					url: `/objects/${sdObject._id}/versions/${sdObject._version}`,
					method: "PUT",
					body: updateData,
				}),
				invalidatesTags: (result) => buildApiInvalidatedTagList(result, (result) => result._id, "Object"),
			}),
			/**
			 * Create brand new Object
			 */
			createObject: builder.mutation<SDObject, SDObjectCreateRequest>({
				query: (createSDObjectData) => ({
					url: "/objects",
					method: "POST",
					body: createSDObjectData,
				}),
				invalidatesTags: (result) => buildApiInvalidatedTagList(result, (result) => result._id, "Object"),
			}),
			/**
			 *	Delete Object
			 */
			deleteObject: builder.mutation<void, SDObject["_id"]>({
				query: (recordId) => ({
					url: `/objects/${recordId}`,
					method: "DELETE",
				}),
				invalidatesTags: (result, error, arg) => buildApiInvalidatedTagList([arg], () => arg, "Object"),
			}),
		};
	},
});

export const { useGetObjectsQuery, useUpdateObjectMutation, useCreateObjectMutation, useDeleteObjectMutation } =
	objectsApi;
