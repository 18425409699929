import { mObjectFieldDef, mUserDef } from "@salesdesk/salesdesk-model";
import { Field, FieldInfo, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { getSDRecordNameFieldInfo } from "../../records";

export function getLeadContactDisplayedFields(leadSDObject: SDObject, leadSDRecord: SDRecord) {
	const owner = new mObjectFieldDef("_ownerId");
	owner.displayName = "Owner";
	owner.pluralName = "Owner";
	owner.objectDefId = mUserDef.ID;

	const ownerFieldInfo: FieldInfo = {
		field: owner.unmarshall() as Field,
		value: leadSDRecord._ownerId,
	};

	return [getSDRecordNameFieldInfo(leadSDObject, leadSDRecord), ownerFieldInfo];
}
