import { useGetObjectsQuery } from "../features/objects/api/objectsApi";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";

export function useGetObjectByIds(objectIds?: SDObject["_id"][]): SDObject[] {
	const { data, isLoading, isUninitialized } = useGetObjectsQuery();

	return useMemo(() => {
		if (!data || isLoading || isUninitialized) {
			return [];
		}
		return data.filter((object) => objectIds?.includes(object._id));
	}, [data, isLoading, isUninitialized, objectIds]);
}
