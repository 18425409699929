import { asConst } from "json-schema-to-ts";
import { sdFileCreateRequestSchema } from "./sdFileCreateRequestSchema";
import { idSchema, userIdSchema, utcMillisecSchema } from "../components";
import { recordIdSchema } from "../records";
import { VideoFileStatus } from "./VideoFileStatus";
import { fieldIdSchema } from "../object";
import { topTopicsSchema } from "./topTopicsSchema";
import { coachingAdviceSchema } from "./coachingAdviceSchema";

export const sdFileSchema = asConst({
	title: "File Data",
	description: "File Data Response",
	type: "object",
	additionalProperties: false,
	required: [...sdFileCreateRequestSchema.required, "fileId", "createdAt", "mimeType"],
	properties: {
		...sdFileCreateRequestSchema.properties,
		fileId: {
			...idSchema,
			title: "File ID",
			description: "ID of file",
		},
		recordId: {
			...recordIdSchema,
			title: "Record ID",
			description: "ID of record to which file is attached",
		},
		fieldId: {
			...fieldIdSchema,
			title: "Record ID",
			description: "ID of record to which file is attached",
		},
		createdAt: utcMillisecSchema,
		createdBy: userIdSchema,
		sizeInBytes: {
			title: "Size in Bytes",
			description: "Size of file in bytes",
			type: "number",
		},
		mimeType: {
			title: "Mime Type",
			description: "Mime type of file",
			type: "string",
		},
		signedUrl: {
			title: "Signed URL",
			description: "Signed URL of main file",
			type: "string",
		},
		posterSignedUrl: {
			title: "Signed poster URL",
			description: "Signed URL of video poster image",
			type: "string",
		},
		transcriptSignedUrl: {
			title: "Transcript signed URL",
			description: "Signed URL of transcript file containing an array of TranscriptItem schema contents",
			type: "string",
		},
		sentimentAnalysisSignedUrl: {
			title: "Sentiment Analysis Signed URL",
			description: "Signed URL of sentiment analysis containing SentimentMap schema contents",
			type: "string",
		},
		summary: {
			title: "Summary",
			description: "Summary of recording",
			type: "string",
		},
		coachingAdvice: coachingAdviceSchema,
		topTopics: topTopicsSchema,
		minutesRecordId: {
			...recordIdSchema,
			title: "Minutes Record ID",
			description: "ID of minutes record",
		},
		videoStatus: {
			title: "Video status",
			description: "Status of video file",
			type: "string",
			enum: Object.values(VideoFileStatus),
		},
	},
});
