import { Dispatch, SetStateAction, useCallback, useState } from "react";

import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";

import { useBulkEditContext } from "../../../hooks/useBulkEditContext";
import { DeleteConfirmationDialog } from "../../../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { useDeleteRecordsMutation } from "../../../../../../records";
import { useToast } from "../../../../../../Toasts";
import { MAX_RECORD_BATCH_SIZE } from "../../../utils";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { BulkChangeAlert } from "../../BulkChangeAlert";
import { usePrincipalCanFilteredRecords } from "../../../hooks/usePrincipalCanFilteredRecords";
import { getCanDeleteRecordParameters } from "../types";
import { countPromiseResults } from "../../../../../../../utils";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";

interface BulkDeleteModalProps {
	sdObject: SDObject;
	onOpenChange?: Dispatch<SetStateAction<boolean>>;
}

export function BulkDeleteModal({ sdObject, onOpenChange }: BulkDeleteModalProps) {
	const toast = useToast();
	const workspaceId = useGetContextWorkspaceId();
	const [deleteRecords] = useDeleteRecordsMutation();

	const [isDeleting, setIsDeleting] = useState(false);

	const { selectedRecords, setSelectedRecords } = useBulkEditContext();
	const [deletableRecords, nonDeletableRecords] = usePrincipalCanFilteredRecords(
		selectedRecords,
		getCanDeleteRecordParameters
	);

	const handleDelete = useCallback(() => {
		if (!deletableRecords.length) {
			toast.trigger("warning", "No records selected to bulk delete.");
			return;
		}

		setIsDeleting(true);

		const chunks = chunk(deletableRecords, MAX_RECORD_BATCH_SIZE);
		Promise.allSettled(chunks.map((records) => deleteRecords({ sdRecords: records, workspaceId }).unwrap())).then(
			(results) => {
				const [successes, fails] = countPromiseResults(results, chunks);
				toast.triggerMessage({
					type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
					messageKey: "record_bulk_delete",
					messageParams: {
						records: `${successes} ${pluralizeWithS("record", successes)}`,
						total: String(fails + successes),
					},
				});

				setSelectedRecords([]);
				onOpenChange?.(false);
			}
		);
	}, [deletableRecords, toast, deleteRecords, setSelectedRecords, onOpenChange, workspaceId]);

	const recordCount = deletableRecords.length;

	return (
		<DeleteConfirmationDialog
			title="Delete records"
			open={true}
			onConfirm={handleDelete}
			onDismiss={() => onOpenChange?.(false)}
			isDeleting={isDeleting}
			destructiveLabel={`Delete ${pluralizeWithS("record", recordCount)}`}
			buttonLabel="Delete"
		>
			<div className="flex flex-col gap-4">
				<div>
					Are you sure you want to delete{" "}
					<span className="text-label underline">
						{recordCount} {pluralizeWithS("record", recordCount)}
					</span>
					? Once deleted {recordCount === 1 ? "it" : "they"} will be stored in your deleted items
				</div>
				<BulkChangeAlert
					actionMessage="delete"
					totalRecordCount={selectedRecords.length}
					unEditableRecords={nonDeletableRecords}
					sdObject={sdObject}
				/>
			</div>
		</DeleteConfirmationDialog>
	);
}
