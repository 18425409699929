import { Button } from "@salesdesk/daisy-ui";
import { FileIcon } from "../../../files";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { SDFile } from "@salesdesk/salesdesk-schemas";
import { useImportContext } from "../../hooks/useImportContext";

interface FilePreviewCardProps {
	importFile: SDFile;
}

export function FilePreviewCard({ importFile }: FilePreviewCardProps) {
	const { sdImport, setSdImport } = useImportContext();

	if (!sdImport) {
		return null;
	}

	return (
		<div className="flex h-full w-full items-center justify-center py-2">
			<div className="rounded-card border-c_border_regular w-[380px] border px-2 pb-4 pt-2">
				<div className="flex w-full">
					<div className="flex grow items-center gap-4 overflow-auto p-2">
						<FileIcon file={importFile} size="sm" />
						<div className="text-label-sm truncate">{importFile.name}</div>
					</div>
					<Button variant="text" size="sm" startIcon={ICONS.trash} onClick={() => setSdImport(undefined)} />
				</div>
				<div className="text-c_text_secondary ml-[58px] flex items-center gap-4">
					<div className="text-label-sm">
						{sdImport.rowCount?.toLocaleString()} {pluralizeWithS("Row", sdImport.rowCount)}
					</div>
					<div className="bg-c_bg_disabled_01 size-1.5 rounded-full" />
					<div className="text-label-sm">
						{sdImport.columnCount?.toLocaleString()} {pluralizeWithS("Column", sdImport.columnCount)}
					</div>
				</div>
			</div>
		</div>
	);
}
