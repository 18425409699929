import { mSystemObjectDef } from "../system_object_def";

export class mOpportunityDef extends mSystemObjectDef {
	static CLASS_NAME = "mOpportunityDef";
	static NAME = "Opportunity";
	static PLURAL_NAME = "Opportunities";
	static ICON = "ph-crown";

	static VALUE_FIELD_NAME = "value";
	static STAGE_FIELD_NAME = "stage";
	static CLOSE_DATE_FIELD_NAME = "closeDate";
	static ACCOUNT_MANAGER_FIELD_NAME = "accountManager";
	static SOURCE_FIELD_NAME = "source";
	static PROBABILITY_FIELD_NAME = "probability";
	static PROSPECTING_STAGE_NAME = "Prospecting";
	static CLOSED_WON_STAGE_NAME = "Closed Won";
	static CLOSED_LOST_STAGE_NAME = "Closed Lost";

	static PRODUCTS_FIELD_NAME = "products";
	static DESCRIPTION_FIELD_NAME = "description";
	static RESELLERS_FIELD_NAME = "resellers";
	static PROPOSAL_FIELD_NAME = "proposal";

	static ID = 5;

	constructor(ownerId) {
		super(mOpportunityDef.ID, ownerId);
	}
}
