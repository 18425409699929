import { StaticTypeaheadSelect, StaticTypeaheadSelectProps } from "../../../../../inputs";

type RuleValueSelectProps = { title?: string; subtitle?: string } & StaticTypeaheadSelectProps;

export function RuleValueSelect({ title, subtitle, ...props }: RuleValueSelectProps) {
	return (
		<div className="flex h-full w-full flex-col justify-stretch">
			{title ? <div className="text-label mx-4 mb-1.5">{title}</div> : null}
			{subtitle ? <div className="text-body-sm mx-4 mb-1.5">{subtitle}</div> : null}
			<div className="grow overflow-hidden">
				<StaticTypeaheadSelect {...props} />
			</div>
		</div>
	);
}
