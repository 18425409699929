import { ImportJob, ImportJobStatus } from "@salesdesk/salesdesk-schemas";
import { useGetImportJobQuery } from "../api/importApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { Dispatch, useEffect, useState } from "react";
import { getCompletionPercentage, isCompleteOrFailed } from "../utils";

const POLLING_INTERVAL = 1000;
const DONE_TRANSITION_DELAY = 300;

export function usePollImportJob(
	importJob: ImportJob | undefined,
	setImportJob: Dispatch<React.SetStateAction<ImportJob | undefined>>
) {
	const [currentImportJob, setCurrentImportJob] = useState<ImportJob | undefined>(undefined);

	const shouldPoll = importJob && (!currentImportJob || !isCompleteOrFailed(currentImportJob));
	const { data } = useGetImportJobQuery(
		shouldPoll ? { importId: importJob.importId, jobId: importJob.id } : skipToken,
		{
			pollingInterval: POLLING_INTERVAL,
		}
	);

	useEffect(() => {
		if (!data) return;
		setCurrentImportJob(data);
	}, [data]);

	const [percentageDone, setPercentageDone] = useState(0);
	useEffect(() => {
		setPercentageDone(currentImportJob ? getCompletionPercentage(currentImportJob) : 0);
	}, [currentImportJob]);

	useEffect(() => {
		if (currentImportJob?.status === ImportJobStatus.Completed) {
			setPercentageDone(100);
			setTimeout(() => {
				setImportJob(currentImportJob);
			}, DONE_TRANSITION_DELAY);
		} else if (currentImportJob) {
			setImportJob(currentImportJob);
		}
	}, [currentImportJob, setImportJob]);

	return {
		percentageDone,
	};
}
