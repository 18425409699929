import { RefObject, useEffect, useRef } from "react";
import { useMediaAnalysisContext } from "./useMediaAnalisysContext";
import { useGraphDimensions } from "./useGraphDimentions";

export function useGraphVideoSeeking(holderRef: RefObject<HTMLDivElement>) {
	const {
		faceSentimentGraphHolderRef,
		transcriptSentimentGraphRef,
		videoRef,
		viewStartTime,
		videoDuration,
		zoomFactor,
	} = useMediaAnalysisContext();

	const { containerWidth } = useGraphDimensions(holderRef);

	const faceSentimentGraphHolderElement = faceSentimentGraphHolderRef.current;
	const transcriptSentimentGraphElement = transcriptSentimentGraphRef.current;
	const videoElement = videoRef.current;

	const viewStartTimeRef = useRef<number>();
	useEffect(() => {
		viewStartTimeRef.current = viewStartTime;
	}, [viewStartTime]);

	useEffect(() => {
		if (!faceSentimentGraphHolderElement || !transcriptSentimentGraphElement || !videoElement) {
			return;
		}
		let isPanning = false;
		let isMouseDown = false;
		const onMouseDown = () => {
			isMouseDown = true;
			isPanning = false;
		};
		const onMouseMove = () => {
			if (zoomFactor !== 1 && isMouseDown) {
				isPanning = true;
			}
		};
		const seekToTime = (e: MouseEvent) => {
			isMouseDown = false;
			if (isPanning) {
				isPanning = false;
				return;
			}
			const viewStartTime = viewStartTimeRef.current ?? 0;
			const leftX = e.clientX - faceSentimentGraphHolderElement.getBoundingClientRect().left;
			videoElement.currentTime = viewStartTime + (leftX / containerWidth) * (videoDuration / zoomFactor);
		};
		faceSentimentGraphHolderElement.addEventListener("mousemove", onMouseMove);
		faceSentimentGraphHolderElement.addEventListener("mousedown", onMouseDown);
		faceSentimentGraphHolderElement.addEventListener("click", seekToTime);
		transcriptSentimentGraphElement.addEventListener("click", seekToTime);
		return () => {
			faceSentimentGraphHolderElement.removeEventListener("mousemove", onMouseMove);
			faceSentimentGraphHolderElement.removeEventListener("mousedown", onMouseDown);
			faceSentimentGraphHolderElement.removeEventListener("click", seekToTime);
			transcriptSentimentGraphElement.removeEventListener("click", seekToTime);
		};
	}, [
		containerWidth,
		faceSentimentGraphHolderElement,
		transcriptSentimentGraphElement,
		videoDuration,
		videoElement,
		zoomFactor,
	]);
}
