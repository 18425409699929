import { useId, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@salesdesk/daisy-ui";
import { ChannelType, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { DeleteConfirmationDialog } from "../../../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { FormFieldSet } from "../../../../../../forms";
import { EditTextField } from "../../../../../../fields";
import { useToast } from "../../../../../../Toasts";
import { useChimeMessagingContext } from "../../../../providers";

import { useChannelMemberRecords } from "../../../../../hooks";
import { ChannelDetails } from "../../../../../types";
import { useChannelInfoViewForm } from "../hooks/useChannelInfoViewForm";
import { TeamMemberField } from "./TeamMemberField";

interface ChannelInfoViewProps {
	channelDetails: ChannelDetails;
	onBack: () => void;
}

export function ChannelInfoView({ channelDetails, onBack }: ChannelInfoViewProps) {
	const toast = useToast();
	const { chime } = useChimeMessagingContext();

	const { formMethods, onSubmit, isSaving, addingMembers, setAddingMembers } = useChannelInfoViewForm(channelDetails);

	const formId = useId();

	const memberRecords = useChannelMemberRecords(channelDetails.arn);

	const { control, handleSubmit, formState } = formMethods;

	const { channelMetadata } = channelDetails;
	const canEdit = Boolean(channelMetadata.channelType === ChannelType.Custom && chime);

	const [userToRemove, setUserToRemove] = useState<{ id: SDRecord["_id"]; name: string }>();
	const [isRemovingUser, setIsRemovingUser] = useState(false);

	const onRemoveUserConfirmation = () => {
		if (!userToRemove || !chime) {
			return;
		}

		setIsRemovingUser(true);
		chime
			.removeMemberFromChannel(channelDetails.arn, userToRemove.id)
			.then(() => {
				setUserToRemove(undefined);
				toast.triggerMessage({ type: "success", messageKey: "channel_member_removed" });
			})
			.catch((error) => {
				console.error("Failed to remove member from channel:", error);
				toast.triggerMessage({ type: "error", messageKey: "channel_member_removed" });
			})
			.finally(() => {
				setIsRemovingUser(false);
			});
	};

	return (
		<div className="flex max-h-full flex-col">
			<div className="border-c_border_regular text-label-sm flex h-12 shrink-0 items-center gap-2 border-b px-4">
				<Button size="xs" variant="text" startIcon={ICONS.caretLeft} onClick={onBack} />
				Conversation Info
			</div>
			<div className="relative h-full max-h-full overflow-auto px-4 pt-4">
				<form id={formId} onSubmit={handleSubmit(onSubmit)} className="relative flex min-h-full flex-col gap-6 pb-4">
					<FormProvider {...formMethods}>
						<FormFieldSet control={control} name="name" label="Conversation name" labelIcon={ICONS.text}>
							{({ field: { value, ...field }, fieldState: { error } }) => (
								<EditTextField
									disabled={!canEdit}
									value={String(value ?? "")}
									placeholder="Custom conversation name"
									{...field}
									hasError={Boolean(error)}
								/>
							)}
						</FormFieldSet>
						<TeamMemberField
							control={control}
							name="newMembers"
							memberRecords={memberRecords}
							canEdit={canEdit}
							addingMembers={addingMembers}
							setAddingMembers={setAddingMembers}
							onRemoveUser={(id, name) => setUserToRemove({ id, name })}
						/>
					</FormProvider>
				</form>
				{formState.isDirty ? (
					<div className="bg-c_bg_01 sticky bottom-0 z-10 mt-auto flex items-center justify-end gap-3 py-2">
						<Button
							variant="secondary"
							size="sm"
							onClick={() => {
								formMethods.reset();
								setAddingMembers(false);
							}}
							disabled={isSaving}
						>
							Cancel
						</Button>
						<Button form={formId} type="submit" size="sm" isLoading={isSaving}>
							Save
						</Button>
					</div>
				) : null}
			</div>
			<DeleteConfirmationDialog
				title="Remove user from conversation?"
				open={!!userToRemove}
				isDeleting={isRemovingUser}
				onConfirm={onRemoveUserConfirmation}
				onDismiss={() => {
					setUserToRemove(undefined);
				}}
				buttonLabel="Remove"
			>
				Are you sure you want to remove{" "}
				{userToRemove?.name ? <span className="text-label">{userToRemove.name}</span> : "this user"} from the
				conversation?
			</DeleteConfirmationDialog>
		</div>
	);
}
