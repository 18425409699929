import { useMemo } from "react";
import { SDRecord, SDObject, AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../auth";
import { DirectedSDObjectAssociation } from "../../../hooks";
import { LinkingModalTabs } from "../types";
import { useAssociationLinkingContext } from "./useAssociationLinkingContext";

export function useOpenLinkingModal(
	sourceRecord: SDRecord | undefined,
	associationObject: SDObject | undefined,
	objectAssociation: DirectedSDObjectAssociation
) {
	const principal = useWebPrincipal();
	const { setAssociationLinkingDetails, setInitialModalTab } = useAssociationLinkingContext();

	return useMemo(() => {
		if (
			!setAssociationLinkingDetails ||
			!sourceRecord ||
			!associationObject ||
			!objectAssociation ||
			principal.cannot(AbilityAction.Create, AbilitySubject.RecordAssociation)
		)
			return undefined;

		return (modalTab: LinkingModalTabs = LinkingModalTabs.linking) => {
			setInitialModalTab?.(modalTab);
			setAssociationLinkingDetails(sourceRecord, associationObject, objectAssociation);
		};
	}, [setAssociationLinkingDetails, sourceRecord, associationObject, objectAssociation, principal, setInitialModalTab]);
}
