import { asConst } from "json-schema-to-ts";
import { importCreateRequestSchema } from "./importCreateRequestSchema";
import { nonNegativeIntegerSchema, stringSchema, ulidSchema, updatableSchema } from "../components";

export const importSchema = asConst({
	title: "ImportSchema",
	description: "An Import created from a file providing summary information",
	type: "object",
	additionalProperties: false,
	required: [
		"id",
		"fileName",
		"fileKey",
		"rowCount",
		"columnCount",
		...importCreateRequestSchema.required,
		...updatableSchema.required,
	],
	properties: {
		id: ulidSchema,
		fileName: stringSchema,
		fileKey: stringSchema,
		rowCount: nonNegativeIntegerSchema,
		columnCount: nonNegativeIntegerSchema,
		...importCreateRequestSchema.properties,
		...updatableSchema.properties,
	},
});
