import { useMemo } from "react";
import { Bookmark, SDObject } from "@salesdesk/salesdesk-schemas";
import { useGetMyBookmarksQuery } from "../../api/bookmarksApi";

export function useObjectBookmarks(sdObject: SDObject | undefined): Bookmark[] | undefined {
	const { currentData: bookmarks } = useGetMyBookmarksQuery();

	return useMemo(() => {
		if (!bookmarks) return undefined;
		if (!sdObject) return bookmarks;
		return bookmarks.filter((bookmark) => bookmark.objectId === sdObject._id);
	}, [bookmarks, sdObject]);
}
