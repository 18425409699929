import { NavButton } from "../../../MainLayout/Navbar/components/NavButton";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { EditBookmarkDialog } from "../dialogs/EditBookmarkDialog";
import { useRef, useState } from "react";
import { Bookmark, SDObject } from "@salesdesk/salesdesk-schemas";
import { DeleteBookmarkDialog } from "../dialogs/DeleteBookmarkDialog";
import { BookmarkPopoverContainer } from "./BookmarkPopoverContainer";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../components/Popover";
import { Tooltip } from "@salesdesk/daisy-ui";
import { BookmarkSelectButton } from "../topBar/BookmarkSelectButton";

type BookmarkVariant = "leftNav" | "objectBoardNav";

const BOOKMARK_LABEL = "Bookmarks";

interface BookmarkNavPopoverCommonProps {
	expanded?: boolean;
	variant?: BookmarkVariant;
	sdObject?: SDObject;
}

interface BookmarkLeftNavPopoverProps extends BookmarkNavPopoverCommonProps {
	variant: "leftNav";
	expanded: boolean;
}

interface BookmarkObjectBoardNavPopoverProps extends BookmarkNavPopoverCommonProps {
	variant: "objectBoardNav";
	sdObject: SDObject;
}

type BookmarkNavPopoverProps = BookmarkLeftNavPopoverProps | BookmarkObjectBoardNavPopoverProps;

export function BookmarkNavPopover({ expanded, variant, sdObject }: BookmarkNavPopoverProps) {
	const [open, setOpen] = useState(false);
	const navButtonRef = useRef<HTMLButtonElement>(null);

	const [bookmarkToEdit, setBookmarkToEdit] = useState<Bookmark>();
	const [bookmarkToDelete, setBookmarkToDelete] = useState<Bookmark>();

	const bookmarkDialogOpen = Boolean(bookmarkToEdit) || Boolean(bookmarkToDelete);

	return (
		<>
			<Popover placement={variant === "leftNav" ? "right" : "bottom-start"} open={open} onOpenChange={setOpen}>
				{variant === "leftNav" ? (
					<Tooltip text={expanded && !bookmarkDialogOpen ? undefined : BOOKMARK_LABEL} placement="right">
						<PopoverTrigger>
							<NavButton
								ref={navButtonRef}
								expanded={expanded}
								icon={ICONS.bookmark}
								text={BOOKMARK_LABEL}
								isSelected={false}
							/>
						</PopoverTrigger>
					</Tooltip>
				) : (
					<PopoverTrigger>
						<BookmarkSelectButton sdObject={sdObject} />
					</PopoverTrigger>
				)}
				<PopoverContent>
					<PopoverContainer>
						<BookmarkPopoverContainer
							onBookmarkClick={() => {
								setOpen(false);
								// hack to prevent opening to tooltip again after navigating to a bookmark
								setTimeout(() => navButtonRef.current?.blur());
							}}
							onEditClick={setBookmarkToEdit}
							onDeleteClick={setBookmarkToDelete}
							sdObject={sdObject}
							onClose={() => setOpen(false)}
						/>
					</PopoverContainer>
				</PopoverContent>
			</Popover>
			{bookmarkToEdit && (
				<EditBookmarkDialog
					open
					onOpenChange={(open) => {
						if (!open) {
							setBookmarkToEdit(undefined);
						}
					}}
					bookmark={bookmarkToEdit}
				/>
			)}
			{bookmarkToDelete && (
				<DeleteBookmarkDialog
					open
					bookmark={bookmarkToDelete}
					onDismiss={() => setBookmarkToDelete(undefined)}
					onDeleteComplete={() => setBookmarkToDelete(undefined)}
				/>
			)}
		</>
	);
}
