import {
	SDObject,
	getSDObjectFields,
	getSDObjectNameFieldIds,
	getFieldIdsFromFieldNames,
} from "@salesdesk/salesdesk-schemas";

import {
	mInteractionMeetingDef,
	mAccountDef,
	mOpportunityDef,
	mLeadDef,
	mContactDef,
	mProductDef,
	mTaskDef,
	mUserDef,
	mSalesDeskUserDef,
	mDocDef,
	mWorkspaceDef,
	mAssetDef,
	mIssueDef,
} from "@salesdesk/salesdesk-model";

export type RecordFieldDisplayType = "PREVIEW" | "ASSOCIATION" | "WORKSPACE_LIBRARY";

const defaultFieldNumber = {
	ASSOCIATION: 2,
	PREVIEW: 4,
	WORKSPACE_LIBRARY: 2,
};

type ObjectFieldNames = Record<SDObject["_id"], string[]>;

const defaultRecordPreviewFieldsMap: Record<RecordFieldDisplayType, ObjectFieldNames> = {
	ASSOCIATION: {
		[mAccountDef.ID]: [mAccountDef.LOGO_FIELD_NAME, mAccountDef.ACCOUNT_MANAGER_FIELD_NAME],
		[mOpportunityDef.ID]: [mOpportunityDef.VALUE_FIELD_NAME, mOpportunityDef.STAGE_FIELD_NAME],
		[mLeadDef.ID]: [mUserDef.PHOTO_FIELD_NAME, mUserDef.TITLE_FIELD_NAME, mUserDef.EMAIL_FIELD_NAME],
		[mContactDef.ID]: [mUserDef.PHOTO_FIELD_NAME, mUserDef.TITLE_FIELD_NAME, mUserDef.EMAIL_FIELD_NAME],
		[mSalesDeskUserDef.ID]: [mUserDef.PHOTO_FIELD_NAME, mUserDef.TITLE_FIELD_NAME, mUserDef.EMAIL_FIELD_NAME],
		[mProductDef.ID]: [mProductDef.NAME_FIELD_NAME, mProductDef.PRICE_FIELD_NAME],
		[mInteractionMeetingDef.ID]: [
			mInteractionMeetingDef.TIME_FIELD_NAME,
			mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME,
			mInteractionMeetingDef.MEETING_TYPE_FIELD_NAME,
		],
		[mTaskDef.ID]: [mTaskDef.ASSIGNEE_FIELD_NAME, mTaskDef.STATUS_FIELD_NAME, mTaskDef.DUE_DATE_FIELD_NAME],
		[mIssueDef.ID]: [mIssueDef.ASSIGNEE_FIELD_NAME, mIssueDef.STATUS_FIELD_NAME, mIssueDef.DUE_DATE_FIELD_NAME],
		[mDocDef.ID]: [mDocDef.DOCUMENT_FIELD_NAME, mDocDef.STATUS_FIELD_NAME],
		[mWorkspaceDef.ID]: [mWorkspaceDef.COMPANY_LOGO_FIELD_NAME, mWorkspaceDef.INTRODUCTION_FIELD_NAME],
	},
	PREVIEW: {
		[mAccountDef.ID]: [
			mAccountDef.ACCOUNT_TYPE_FIELD_NAME,
			mAccountDef.ACCOUNT_MANAGER_FIELD_NAME,
			mAccountDef.PHONE_FIELD_NAME,
			mAccountDef.WEBSITE_FIELD_NAME,
		],
		[mOpportunityDef.ID]: [mOpportunityDef.VALUE_FIELD_NAME, mOpportunityDef.STAGE_FIELD_NAME],
		[mLeadDef.ID]: [mUserDef.TITLE_FIELD_NAME, mUserDef.EMAIL_FIELD_NAME, mUserDef.MOBILE_FIELD_NAME],
		[mContactDef.ID]: [mUserDef.TITLE_FIELD_NAME, mUserDef.EMAIL_FIELD_NAME, mUserDef.MOBILE_FIELD_NAME],
		[mProductDef.ID]: [mProductDef.NAME_FIELD_NAME, mProductDef.PRICE_FIELD_NAME],
		[mWorkspaceDef.ID]: [
			mWorkspaceDef.COMPANY_NAME_FIELD_NAME,
			mWorkspaceDef.INTRODUCTION_FIELD_NAME,
			mWorkspaceDef.THEME_FIELD_NAME,
		],
		[mInteractionMeetingDef.ID]: [
			mInteractionMeetingDef.TIME_FIELD_NAME,
			mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME,
			mInteractionMeetingDef.MEETING_TYPE_FIELD_NAME,
		],
		[mTaskDef.ID]: [mTaskDef.ASSIGNEE_FIELD_NAME, mTaskDef.STATUS_FIELD_NAME, mTaskDef.DUE_DATE_FIELD_NAME],
		[mIssueDef.ID]: [mIssueDef.ASSIGNEE_FIELD_NAME, mIssueDef.STATUS_FIELD_NAME, mIssueDef.DUE_DATE_FIELD_NAME],
		[mSalesDeskUserDef.ID]: [
			mSalesDeskUserDef.TITLE_FIELD_NAME,
			mSalesDeskUserDef.EMAIL_FIELD_NAME,
			mSalesDeskUserDef.MOBILE_FIELD_NAME,
			mSalesDeskUserDef.ROLES_FIELD_NAME,
		],
	},
	WORKSPACE_LIBRARY: {
		[mAssetDef.ID]: [mAssetDef.FILE_FIELD_NAME, mAssetDef.MEDIA_TYPE_FIELD_NAME],
		[mDocDef.ID]: [mDocDef.DOCUMENT_FIELD_NAME, mDocDef.STATUS_FIELD_NAME],
	},
};

export function getDefaultDisplayFieldIds(sdObject: SDObject, displayType: RecordFieldDisplayType) {
	const defaultFieldNamesByObjectId = defaultRecordPreviewFieldsMap[displayType];
	const defaultDisplayNames = defaultFieldNamesByObjectId ? defaultFieldNamesByObjectId[sdObject._id] : undefined;

	const defaultFieldIds: number[] = [];

	if (!defaultDisplayNames) {
		const objectFields = getSDObjectFields(sdObject);
		const nameIds = getSDObjectNameFieldIds(sdObject);
		for (const field of objectFields) {
			if (defaultFieldIds.length >= defaultFieldNumber[displayType]) {
				break;
			}
			if (!field._hidden && !nameIds.includes(field._id)) {
				defaultFieldIds.push(field._id);
			}
		}
		return defaultFieldIds;
	}

	return getFieldIdsFromFieldNames(sdObject, defaultDisplayNames);
}
