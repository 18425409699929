export enum ThemeName {
	Grey = "Grey",
	Purple = "Purple",
	Cyan = "Cyan",
	Coral = "Coral",
	Olive = "Olive",
	Tangerine = "Tangerine",
	Indigo = "Indigo",
	Amber = "Amber",
}
