import { useMemo } from "react";

import { Field, SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectsQuery } from "../api/objectsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const EMPTY_ARRAY: SDObject[] = [];

export function useGetObjectMap(skip = false, filterIds?: Field["_id"][]) {
	const { data: sdObjects = EMPTY_ARRAY } = useGetObjectsQuery(skip ? skipToken : undefined);

	return useMemo(() => {
		const filterSdObjects = filterIds ? sdObjects.filter((object) => filterIds.includes(object._id)) : sdObjects;
		return new Map(filterSdObjects.map((object) => [object._id, object]));
	}, [filterIds, sdObjects]);
}
