import { Switch } from "@headlessui/react";

import { InputValidationState, FieldSet } from "@salesdesk/daisy-ui";

import CheckmarkSVG from "../../../../../../static/images/checkmark.svg?react";
import { BooleanFieldVariant } from "../types";

interface PreviewBooleanFieldProps {
	label: string;
	helperText?: string;
	variant: BooleanFieldVariant;
	error?: boolean;
}

export function PreviewBooleanField({ label, helperText, variant, error }: PreviewBooleanFieldProps) {
	const validationState = error ? InputValidationState.error : InputValidationState.initial;

	switch (variant) {
		case BooleanFieldVariant.TOGGLE_SWITCH:
			return (
				<FieldSet label={label} helperText={helperText} validationState={validationState} isBoolean>
					<Switch
						className="bg-c_icon_regular relative inline-flex h-5 w-10 cursor-default items-center rounded-full"
						checked={true}
						disabled={true}
					>
						<span className="bg-c_bg_01 inline-block h-3.5 w-3.5 translate-x-[23px] rounded-full" />
					</Switch>
				</FieldSet>
			);
		case BooleanFieldVariant.CHECKBOX:
			return (
				<FieldSet label={label} helperText={helperText} validationState={validationState} isBoolean>
					<div className="bg-c_icon_regular border-c_icon_regular inline-flex h-5 w-5 items-center justify-center rounded-sm border-[3px] border-solid">
						<CheckmarkSVG className="fill-c_text_inverted" />
					</div>
				</FieldSet>
			);
		default:
			throw new Error("Invalid preview BooleanField 'variant' prop");
	}
}
