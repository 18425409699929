import { asConst } from "json-schema-to-ts";
import {
	fieldCardViewEnabledSchema,
	fieldComponentTypeSchema,
	fieldCreationTypeSchema,
	fieldCurrencySchema,
	fieldDateOnlySchema,
	fieldDefaultValueSchema,
	fieldDescriptionSchema,
	fieldDisplayNameSchema,
	fieldEditableSchema,
	fieldFormatDescriptionSchema,
	fieldHiddenSchema,
	fieldIconPhotoSchema,
	fieldIconSchema,
	fieldIdSchema,
	fieldMultiplicitySchema,
	fieldNameSchema,
	fieldNoDefaultValueSchema,
	fieldObjectDefIdSchema,
	fieldPluralNameSchema,
	fieldPreviewEnabledSchema,
	fieldRequiredSchema,
	fieldSearchableSchema,
	fieldSupportsPastSchema,
	fieldTableViewEnabledSchema,
	fieldToolTipSchema,
	fieldTypeSchema,
	fieldUniqueTypeSchema,
	fieldTemplateTypeSchema,
	optionValueArraySchema,
	outcomeIdsSchema,
} from "./properties";
import {
	FieldDefFactory,
	mCurrencyValueFieldDef,
	mDateFieldDef,
	mDateTimeFieldDef,
	mObjectFieldDef,
	mObjectsFieldDef,
	mOptionFieldDef,
	mTimeRangeFieldDef,
	UniqueFieldType,
} from "@salesdesk/salesdesk-model";
import { nullableNonNegativeIntegerSchema } from "../../components";

export const fieldCreateRequestSchema = asConst({
	title: "SDObject Field Create Request",
	description: "An SDObject Field schema for creating fields which describes a field on a record for the SDObject",
	type: "object",
	additionalProperties: false,
	required: [
		"_type",
		"_componentType",
		"_creationType",

		"_name",
		"_pluralName",
		"_displayName",
		"_description",

		"_icon",
		"_iconPhoto",
		"_toolTip",

		"_multiplicity",
		"_formatDescription",

		"_editable",
		"_hidden",
		"_required",
		"_unique",
		"_searchable",
		"_cardViewEnabled",
		"_previewEnabled",
		"_tableViewEnabled",

		"_defaultValue",
		"_maxLength",
	],
	properties: {
		_id: fieldIdSchema,
		_type: fieldTypeSchema,
		//TODO:  Even though dependencies are added to the RestAPI_bundled.oas.json they are not exported
		// in the API Gateway OpenApi schema, so I've left this in so at least all the options are visible
		_componentType: fieldComponentTypeSchema,
		_creationType: fieldCreationTypeSchema,

		_name: fieldNameSchema,
		_pluralName: fieldPluralNameSchema,
		_displayName: fieldDisplayNameSchema,
		_description: fieldDescriptionSchema,

		_icon: fieldIconSchema,
		_iconPhoto: fieldIconPhotoSchema,
		_toolTip: fieldToolTipSchema,

		_multiplicity: fieldMultiplicitySchema,
		_formatDescription: fieldFormatDescriptionSchema,

		_editable: fieldEditableSchema,
		_hidden: fieldHiddenSchema,
		_required: fieldRequiredSchema,
		_unique: fieldUniqueTypeSchema,
		_searchable: fieldSearchableSchema,
		_cardViewEnabled: fieldCardViewEnabledSchema,
		_previewEnabled: fieldPreviewEnabledSchema,
		_tableViewEnabled: fieldTableViewEnabledSchema,

		_defaultValue: fieldDefaultValueSchema,
		_maxLength: nullableNonNegativeIntegerSchema, // TODO: this should only be set based on _type but its on field_def

		// TODO: The dependencies defined below are bundled correctly but seem to be lost during SAM deployment and are
		//  not included in the API Gateway OpenApi document which is why they are added here as optional so they
		//  are still included in the API Gateway OpenApi.  This is less explicit than the dependencies.
		_objectDefId: fieldObjectDefIdSchema,
		_optionValues: optionValueArraySchema,
		_supportsPast: fieldSupportsPastSchema,
		_dateOnly: fieldDateOnlySchema,
		_currency: fieldCurrencySchema,
		_outcomeIds: outcomeIdsSchema,

		templateType: fieldTemplateTypeSchema,
	},
	// METHOD 1:  TODO: This resulted in a list of - type: "object".  This may work when the API Gateway supported OpenAPI version supports JSON schema 7
	// You might expect oneOf but the absence of 'else' equates to true, so you need allOf knowing that only one 'if' will equate to true
	// allOf: [
	// 	...fieldTypeSchema.enum.map((fieldType) => {
	// 		const fieldDef = FieldDefFactory.newInstance(fieldType);
	//
	// 		const one: any = {
	// 			if: {
	// 				properties: { _type: { const: fieldType } },
	// 			},
	// 			then: {
	// 				properties: {
	// 					_componentType: {
	// 						...fieldComponentTypeSchema,
	// 						enum: [...fieldDef.supportedComponentTypes],
	// 					},
	// 					_defaultValue: fieldDef.supportsDefaultValue() ? fieldDefaultValueSchema : fieldNoDefaultValueSchema,
	// 				},
	// 			},
	// 		};
	//
	// 		if (fieldDef instanceof mOptionFieldDef && !fieldDef.isBackingStore()) {
	// 			one.then.required = ["_optionValues"];
	// 		}
	// 		if (fieldDef instanceof mDateFieldDef || fieldDef instanceof mDateTimeFieldDef) {
	// 			one.then.required = ["_supportsPast"];
	// 		}
	// 		if (fieldDef instanceof mObjectFieldDef || fieldDef instanceof mObjectsFieldDef) {
	// 			one.then.required = ["_objectDefId"];
	// 		}
	//
	// 		return one;
	// 	}),
	// ],

	// METHOD 2:  Gets ignored by API Gateway generated OpenApi schema.  Not sure why because dependencies have existed since JSON Schema version 4
	// TODO: Change to a oneOf at the highest level (i.e. duplicating all props) which means it will get exported in the schema
	dependencies: {
		_type: {
			oneOf: [
				...fieldTypeSchema.enum.map((fieldType) => {
					const fieldDef = FieldDefFactory.newInstance(fieldType);
					// TODO: Can this be strongly typed?
					const one: any = {
						properties: {
							_type: {
								enum: [fieldType],
							},
							_componentType: {
								...fieldComponentTypeSchema,
								enum: [...fieldDef.supportedComponentTypes],
							},
							// TODO: Could make this explicit per field type
							_defaultValue: fieldDef.supportsDefaultValue() ? fieldDefaultValueSchema : fieldNoDefaultValueSchema,
							_unique: {
								...fieldUniqueTypeSchema,
								enum: fieldDef.supportsUniqueValue() ? fieldUniqueTypeSchema.enum : [UniqueFieldType.None],
							},
						},
					};

					// TODO:  As per the comment above, the duplicate definition of properties below are redundant
					// but the aim is to remove the definitions above so the ones below would still be needed
					if (fieldDef instanceof mOptionFieldDef && !fieldDef.isBackingStore()) {
						one.required = ["_optionValues"];
					}
					if (
						fieldDef instanceof mDateFieldDef ||
						fieldDef instanceof mDateTimeFieldDef ||
						fieldDef instanceof mTimeRangeFieldDef
					) {
						one.required = ["_supportsPast"];
					}
					if (fieldDef instanceof mTimeRangeFieldDef) {
						one.required = ["_dateOnly"];
					}
					if (fieldDef instanceof mObjectFieldDef || fieldDef instanceof mObjectsFieldDef) {
						one.required = ["_objectDefId"];
					}

					if (fieldDef instanceof mCurrencyValueFieldDef) {
						one.required = ["_currency"];
					}

					return one;
				}),
			],
		},
	},
});
