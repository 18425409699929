import { useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { mFieldDef } from "@salesdesk/salesdesk-model";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { FieldComponentFactory } from "../../../../../../forms";
import { FieldSettingsGroup } from "../../../types";
import { Accordion } from "../../../../../../../components/Accordion";

interface FieldSettingsProps {
	fieldSettingsGroups: FieldSettingsGroup[];
	activeFieldId: number;
}

export function FieldSettings({ activeFieldId, fieldSettingsGroups }: FieldSettingsProps) {
	const { control, formState } = useFormContext();

	const fieldSettingGroupIdWithError = useMemo(() => {
		const fieldNamesWithError = new Set<string>();
		Object.values(formState.errors).forEach((fieldError) => {
			if (!fieldError) return;

			Object.keys(fieldError).forEach((fieldErrorName) => {
				fieldNamesWithError.add(fieldErrorName);
			});
		});

		const idsWithError = new Set<number>();
		fieldSettingsGroups.forEach((fieldSettingsGroup) => {
			fieldSettingsGroup.fields.forEach((fieldSettingField) => {
				if (fieldNamesWithError.has(fieldSettingField._name!)) {
					idsWithError.add(fieldSettingsGroup.id);
				}
			});
		});

		return idsWithError;
	}, [formState, fieldSettingsGroups]);

	return (
		<div className="bg-c_bg_03 border-c_border_regular rounded-tl-panel shadow-panel_right flex h-full w-full overflow-hidden border py-6 pl-5 pr-6">
			<div className="w-full overflow-auto">
				{fieldSettingsGroups.map((fieldGroup, index, groups) => {
					const isLastFormGroup = groups.length - 1 === index;
					const { fields, groupName } = fieldGroup;

					const fieldComponents = fields.map((field) => {
						const fieldId = `${activeFieldId}.${field._name}`;

						return (
							<Controller
								key={fieldId}
								control={control}
								name={fieldId}
								render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
									<FieldComponentFactory
										fieldDef={field as mFieldDef}
										componentProps={{
											id: String(field._id),
											onBlur,
											onChange,
											inputRef: ref,
											value: value,
											hasError: Boolean(error?.message),
											disabled: !field.editable,
										}}
										errorMessage={error?.message}
									/>
								)}
								rules={{ validate: (value) => field.validate(value, undefined) }}
							/>
						);
					});
					return (
						<div key={index} className="pl-1">
							{groupName ? (
								<Accordion
									leftElement={
										fieldSettingGroupIdWithError.has(fieldGroup.id) ? (
											<Icon icon={ICONS.info} size="sm" className="text-c_danger_02 flex items-center" />
										) : undefined
									}
									title={groupName}
									unmount={false}
									defaultOpen
								>
									<div className="mt-4 flex flex-col gap-6">{fieldComponents}</div>
								</Accordion>
							) : (
								fieldComponents
							)}
							{!isLastFormGroup && <div className="bg-c_border_regular my-4 h-px w-full" />}
						</div>
					);
				})}
			</div>
		</div>
	);
}
