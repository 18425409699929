import { asConst } from "json-schema-to-ts";
import { appendEditableForCustom } from "../../../functions";
import { optionValueIdSchema } from "./optionValueIdSchema";
import { optionValueNameSchema } from "./optionValueNameSchema";
import { optionValueColorSchema } from "./optionValueColorSchema";
import { optionValueIconSchema } from "./optionValueIconSchema";
import { optionValueCreationTypeSchema } from "./optionValueCreationTypeSchema";

export const optionValueSchema = asConst({
	title: "SDObject Field Option Value",
	description: appendEditableForCustom("An option available for selection"),
	type: "object",
	additionalProperties: false,
	required: ["name", "color", "icon"],
	properties: {
		id: optionValueIdSchema,
		name: optionValueNameSchema,
		color: optionValueColorSchema,
		icon: optionValueIconSchema,
		creationType: optionValueCreationTypeSchema,
	},
});
