import { useRef, useState } from "react";
import { Editor } from "@tiptap/react";

import { COMMENT_PANEL_COMMENT_ID_PREFIX } from "../../../utils";
import { COMMENT_DATA_ATTRIBUTE } from "../../../../DocumentEditor";
import { getActiveCommentIdsInEditor } from "../../../../DocumentEditor/utils/CommentExtension";

interface UseOnActiveCommentChangeProps {
	unresolvedThreadIds: string[];
	viewingResolvedComments: boolean;
	resolvedThreadIds: string[];
	viewingUnresolvedComments: boolean;
	setCommentsOpen: (open: boolean) => void;
	setShowResolvedComments: (show: boolean) => void;
	editorRef: React.MutableRefObject<Editor | null>;
}

/**
    Handles scrolling to the active root comment in the document and the comment panel
 */
export function useOnActiveCommentChange({
	unresolvedThreadIds,
	viewingResolvedComments,
	resolvedThreadIds,
	viewingUnresolvedComments,
	setCommentsOpen,
	setShowResolvedComments,
	editorRef,
}: UseOnActiveCommentChangeProps) {
	const [activeCommentId, setActiveCommentId] = useState<string | null>(null);

	const onActiveCommentsChangeRef = useRef<(activeComments: string[]) => void>(() => {
		return;
	});
	onActiveCommentsChangeRef.current = (activeComments: string[]) => {
		let isUnresolved = false;
		const newActiveCommentId = activeComments.find((commentId) => {
			isUnresolved = unresolvedThreadIds.includes(commentId);

			// If the comment doesn't belong to an unresolved thread and the user is not looking at the resolved threads
			// or it doesn't belong to the resolved threads, we ignore it.
			if (!isUnresolved && (!viewingResolvedComments || !resolvedThreadIds.includes(commentId))) {
				return false;
			}

			return true;
		});

		setActiveCommentId(newActiveCommentId || null);

		if (!newActiveCommentId || activeCommentId === newActiveCommentId) {
			return;
		}

		const scrollToCommentPanelThread = () => {
			const commentPanelThread = document.getElementById(`${COMMENT_PANEL_COMMENT_ID_PREFIX}${newActiveCommentId}`);

			if (commentPanelThread) {
				commentPanelThread.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		};

		if (isUnresolved && !viewingUnresolvedComments) {
			setCommentsOpen(true);
			setShowResolvedComments(false);

			// If the comment panel is not already open on the unresolved comments
			// we need to wait for the comment threads to mount
			// before scrolling to the root comment in the comment panel
			setTimeout(scrollToCommentPanelThread, 100);
		} else {
			scrollToCommentPanelThread();
		}

		const editor = editorRef.current;

		if (!editor) {
			return;
		}

		const commentNode = editor.view.dom.querySelector(`[${COMMENT_DATA_ATTRIBUTE}="${newActiveCommentId}"]`);

		if (!commentNode) {
			return;
		}

		// Wrapped in a requestAnimationFrame so we don't cause a flush sync while the useEffect is running
		requestAnimationFrame(() => {
			commentNode.scrollIntoView({ behavior: "smooth", block: "center" });
		});
	};

	return { activeCommentId, onActiveCommentsChangeRef };
}
