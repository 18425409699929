export class DataAccessorProvider {
	static instance = null;

	static getDataAccessorInstance() {
		if (!DataAccessorProvider.instance) {
			throw new Error("Data accessor not configured");
		}
		return DataAccessorProvider.instance;
	}

	static setDataAccessorInstance(value) {
		DataAccessorProvider.instance = value;
	}
}
