import { useCallback } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { FormDialog } from "../../Dialog";
import { RecordForm } from "../../records";
import { useLeadToOpportunityForm } from "../hooks";
import { useLeadToOpportunityContext } from "../hooks";
import { LeadToOpportunitySubForm } from "./LeadToOpportunitySubForm";

interface LeadToOpportunityModalProps {
	leadSDObject: SDObject;
	leadSDRecord: SDRecord;
}

export function LeadToOpportunityModal({ leadSDObject, leadSDRecord }: LeadToOpportunityModalProps) {
	const { setLeadSDRecord } = useLeadToOpportunityContext();

	const closeModal = useCallback(() => {
		if (setLeadSDRecord) {
			setLeadSDRecord(undefined);
		}
	}, [setLeadSDRecord]);

	const onConvertLeadSuccess = useCallback(() => {
		closeModal();
	}, [closeModal]);

	const { formProps, isSubmitting, uploadProgressStatus } = useLeadToOpportunityForm({
		leadSDObject,
		leadSDRecord,
		onConvertLeadSuccess,
		navigateOnSuccess: true,
	});

	const { id, updateUploadProgressStatus, fields } = formProps;

	if (!fields.length) return null;

	return (
		<FormDialog
			title={"Convert to opportunity"}
			open={true}
			onOpenChange={() => {
				closeModal();
			}}
			isPending={isSubmitting || uploadProgressStatus === "in_progress"}
			formId={id}
			submitButtonLabel="Convert"
			width={768}
		>
			<div className="mb-4 flex-shrink overflow-auto">
				<RecordForm {...formProps}>
					<LeadToOpportunitySubForm
						leadSDObject={leadSDObject}
						leadSDRecord={leadSDRecord}
						updateUploadProgressStatus={updateUploadProgressStatus}
					/>
				</RecordForm>
			</div>
		</FormDialog>
	);
}
