import { createContext, useContext } from "react";
import { SDNotification, SDNotificationType } from "@salesdesk/salesdesk-schemas";

export interface INotificationsContext {
	unreadOnly: boolean;
	setUnreadOnly: (unreadOnly: boolean) => void;
	notificationType: SDNotificationType;
	setNotificationType: (notificationType: SDNotificationType) => void;
	isLoading: boolean;
	notifications: SDNotification[] | undefined;
	loadNext: () => void;
	markRead: (notifications: SDNotification[]) => Promise<void>;
	markUnread: (notification: SDNotification) => Promise<void>;
	onNotificationClicked: (notification: SDNotification) => void;
}

export const NotificationsContext = createContext<INotificationsContext | null>(null);

export const useNotificationsContext = () => {
	const context = useContext(NotificationsContext);

	if (context == null) {
		throw new Error("Notification components must be wrapped in <NotificationsProvider />");
	}

	return context;
};
