import { createUniqueId } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES, TemplateFieldType } from "./utils/index.js";

import { mContainerFieldDef, mDataFieldDef } from "./container/index.js";
import {
	mColorFieldDef,
	mEmailFieldDef,
	mIconChooserFieldDef,
	mPhoneNumberFieldDef,
	mProbabilityFieldDef,
	mProgressFieldDef,
	mRatingFieldDef,
	mRichTextFieldDef,
	mStringFieldDef,
	mUrlFieldDef,
} from "./default/index.js";
import { mCurrencyValueFieldDef, mFloatFieldDef, mIntegerFieldDef } from "./number/index.js";
import {
	mBooleanFieldDef,
	mCountryFieldDef,
	mCurrencyFieldDef,
	mIconFieldDef,
	mMultiOptionFieldDef,
	mObjectFieldDef,
	mObjectsFieldDef,
	mPriorityFieldDef,
	mSingleOptionFieldDef,
} from "./option/index.js";
import { mDateFieldDef } from "./date/index.js";
import { mDateTimeFieldDef } from "./date_time/index.js";
import { mDurationFieldDef } from "./duration/index.js";
import { mFileFieldDef, mImageFieldDef, mPdfFieldDef, mVideoFieldDef } from "./file/index.js";
import { mPasswordFieldDef } from "./password/index.js";
import { mOptionsBuilderFieldDef } from "./options_builder/index.js";
import { mRolesFieldDef } from "./option/multi_option/roles_field_def.js";
import { mLogoFieldDef, mProfilePhotoFieldDef, mTimeRangeFieldDef } from "@salesdesk/salesdesk-model";

export class FieldDefFactory {
	static newInstance(type: string, templateType?: TemplateFieldType) {
		let id = createUniqueId();

		let field;
		switch (type) {
			case FIELD_TYPES.STRING.name: {
				field = new mStringFieldDef(id);
				break;
			}
			case FIELD_TYPES.RICH_TEXT.name: {
				field = new mRichTextFieldDef(id);
				break;
			}
			case FIELD_TYPES.INTEGER.name: {
				field = new mIntegerFieldDef(id);
				break;
			}
			case FIELD_TYPES.FLOAT.name: {
				field = new mFloatFieldDef(id);
				break;
			}
			case FIELD_TYPES.BOOLEAN.name: {
				field = new mBooleanFieldDef(id);
				break;
			}
			case FIELD_TYPES.PRIORITY.name: {
				field = new mPriorityFieldDef(id);
				break;
			}
			case FIELD_TYPES.PROBABILITY.name: {
				field = new mProbabilityFieldDef(id);
				break;
			}
			case FIELD_TYPES.PROGRESS.name: {
				field = new mProgressFieldDef(id);
				break;
			}
			case FIELD_TYPES.RATING.name: {
				field = new mRatingFieldDef(id);
				break;
			}
			case FIELD_TYPES.DATE.name: {
				field = new mDateFieldDef(id);
				break;
			}
			case FIELD_TYPES.DATE_TIME.name: {
				field = new mDateTimeFieldDef(id);
				break;
			}
			case FIELD_TYPES.TIME_RANGE.name: {
				field = new mTimeRangeFieldDef(id);
				break;
			}
			case FIELD_TYPES.DURATION.name: {
				field = new mDurationFieldDef(id);
				break;
			}
			case FIELD_TYPES.CURRENCY_VALUE.name: {
				field = new mCurrencyValueFieldDef(id);
				break;
			}
			case FIELD_TYPES.EMAIL.name: {
				field = new mEmailFieldDef(id);
				break;
			}
			case FIELD_TYPES.PHONE_NUMBER.name: {
				field = new mPhoneNumberFieldDef(id);
				break;
			}
			case FIELD_TYPES.URL.name: {
				field = new mUrlFieldDef(id);
				break;
			}
			case FIELD_TYPES.FILE.name: {
				field = new mFileFieldDef(id);
				break;
			}
			case FIELD_TYPES.IMAGE.name: {
				field = new mImageFieldDef(id);
				break;
			}
			case FIELD_TYPES.PDF.name: {
				field = new mPdfFieldDef(id);
				break;
			}
			case FIELD_TYPES.VIDEO.name: {
				field = new mVideoFieldDef(id);
				break;
			}
			case FIELD_TYPES.COLOR.name: {
				field = new mColorFieldDef(id);
				break;
			}
			case FIELD_TYPES.COUNTRY.name: {
				field = new mCountryFieldDef(id);
				break;
			}
			case FIELD_TYPES.ICON.name: {
				field = new mIconFieldDef(id);
				break;
			}
			case FIELD_TYPES.ICON_CHOOSER.name: {
				field = new mIconChooserFieldDef(id);
				break;
			}
			case FIELD_TYPES.CURRENCY.name: {
				field = new mCurrencyFieldDef(id);
				break;
			}
			case FIELD_TYPES.PASSWORD.name: {
				field = new mPasswordFieldDef(id);
				break;
			}
			case FIELD_TYPES.SINGLE_OPTION.name: {
				field = new mSingleOptionFieldDef(id);
				break;
			}
			case FIELD_TYPES.MULTI_OPTION.name: {
				field = new mMultiOptionFieldDef(id);
				break;
			}
			case FIELD_TYPES.OPTIONS_BUILDER.name: {
				field = new mOptionsBuilderFieldDef(id);
				break;
			}
			case FIELD_TYPES.CONTAINER.name: {
				field = new mContainerFieldDef(id);
				break;
			}
			case FIELD_TYPES.OBJECT.name: {
				field = new mObjectFieldDef(id);
				break;
			}
			case FIELD_TYPES.OBJECTS.name: {
				field = new mObjectsFieldDef(id);
				break;
			}
			case FIELD_TYPES.ROLES.name: {
				field = new mRolesFieldDef(id);
				break;
			}
			case FIELD_TYPES.DATA.name: {
				field = new mDataFieldDef(id);
				break;
			}
			case FIELD_TYPES.PROFILE_PHOTO.name: {
				field = new mProfilePhotoFieldDef(id);
				break;
			}
			case FIELD_TYPES.LOGO.name: {
				field = new mLogoFieldDef(id);
				break;
			}
			default: {
				throw new Error(`Field type "${type}" not recognised!`);
			}
		}
		if (field) {
			field.templateType = templateType ?? TemplateFieldType.None;
		}
		return field;
	}
}
