import { ReactNode, Ref } from "react";
import { ErrorInputComponentProps, InputComponentProps } from "@salesdesk/daisy-ui";

import { SelectOption, SelectOptionId } from "../../inputs";

export interface GeneralPreviewFieldProps {
	placeholder: string;
	containedIcon?: ReactNode;
}

export interface EditFieldProps extends InputComponentProps, ErrorInputComponentProps {
	inputRef?: Ref<any>;
}

export interface OptionBuilderOption extends Omit<SelectOption, "icon" | "color"> {
	icon: string | null;
	color: string | null;
}

export interface OptionBuilderComponentProps extends EditFieldProps {
	value?: OptionBuilderOption[];
	onChange: (newOptions: OptionBuilderOption[]) => void;
	outcomeIds?: SelectOptionId[];
}

export interface AssetCombinedNameValue {
	fileId?: number;
	displayName?: string;
}

export enum DisplayFieldVariant {
	default = "default",
	table = "table",
	card = "card",
	cardTitle = "cardTitle",
	rightHandPanel = "rightHandPanel",
	workspaceOverview = "workspaceOverview",
	meeting = "meeting",
}
