import { useState, useMemo, useCallback } from "react";

import {
	PanelTabs,
	PanelTabList,
	PanelTabPanels,
	PanelTabPanel,
	PanelTab,
} from "../../../../../../../../../../components/PanelTabs";
import { useVideoCallLogicContext } from "../../../../../../../VideoCallProvider";
import { useGetObjectById } from "../../../../../../../../../../hooks/useGetObjectById";
import { CreateCompletableRecordPanelForm } from "./CreateRecordPanelForm";
import { useSyncParticipantMeetingTasks } from "../hooks/useSyncParticipantMeetingRecords";
import { useObjectAssociations, RecordAssociations } from "../../../../../../../../../recordAssociations";
import { DisplayIf } from "../../../../../../../../../../auth";
import { allowedRecordCreatePanelIdsMap, ableToCreateCompletableRecordMapById } from "../utils";
import { AllowedRecordCreatePanel } from "../types";

interface CreateRecordPanelProps {
	recordPanel: AllowedRecordCreatePanel;
}

export function CreateRecordPanel({ recordPanel }: CreateRecordPanelProps) {
	const { currentMeetingRecord } = useVideoCallLogicContext();
	const sdObjectId = allowedRecordCreatePanelIdsMap[recordPanel];
	const { sdObject } = useGetObjectById(sdObjectId);

	const {
		objectAssociations: { allAssociations },
	} = useObjectAssociations(currentMeetingRecord?._objectDefId || undefined);

	const meetingToTaskAssociation = useMemo(
		() => allAssociations.find((objectAssociation) => objectAssociation.connectedObject.id === sdObject?._id),
		[allAssociations, sdObject]
	);

	const [tasksCount, setTasksCount] = useState(0);
	const [allTasksCount, setAllTasksCount] = useState(0);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	const { triggerParticipantTaskSync, taskSearchKey } = useSyncParticipantMeetingTasks();

	const onCreateTaskSuccess = useCallback(() => {
		setCurrentTabIndex(1);
		triggerParticipantTaskSync();
	}, [triggerParticipantTaskSync]);

	if (!sdObject || !meetingToTaskAssociation || !currentMeetingRecord) return null;

	const isAbleToCreateRecord = ableToCreateCompletableRecordMapById[sdObjectId];

	return (
		<div className="flex h-full max-h-full flex-col gap-4 overflow-auto">
			<h2 className="text-label">{sdObject._pluralName}</h2>
			<PanelTabs selectedIndex={currentTabIndex} onChange={setCurrentTabIndex}>
				<div className="w-fit">
					<PanelTabList isResponsive={false}>
						<DisplayIf canAll={isAbleToCreateRecord}>
							<PanelTab>New</PanelTab>
						</DisplayIf>
						<PanelTab count={tasksCount}>Linked</PanelTab>
						<PanelTab count={allTasksCount}>All</PanelTab>
					</PanelTabList>
				</div>
				<PanelTabPanels>
					<DisplayIf canAll={isAbleToCreateRecord}>
						<PanelTabPanel unmount={false}>
							<CreateCompletableRecordPanelForm
								meetingToTaskAssociation={meetingToTaskAssociation}
								meetingRecord={currentMeetingRecord}
								sdObject={sdObject}
								onSuccess={onCreateTaskSuccess}
							/>
						</PanelTabPanel>
					</DisplayIf>
					<PanelTabPanel unmount={false}>
						<RecordAssociations
							key={taskSearchKey}
							sourceRecord={currentMeetingRecord}
							onUpdateCount={setTasksCount}
							objectAssociation={meetingToTaskAssociation}
							controlsVariant="sm"
						/>
					</PanelTabPanel>
					<PanelTabPanel unmount={false}>
						<RecordAssociations
							key={taskSearchKey}
							onUpdateCount={setAllTasksCount}
							objectAssociation={meetingToTaskAssociation}
							controlsVariant="sm"
						/>
					</PanelTabPanel>
				</PanelTabPanels>
			</PanelTabs>
		</div>
	);
}
