import { useMemo, useState } from "react";
import { add, endOfWeek, format, parse, startOfDay, startOfWeek, sub } from "date-fns";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { Button, getHexOrCssVariableForColorKey, Spinner } from "@salesdesk/daisy-ui";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { GraphDataItem } from "../types";
import { useGetEventSummary } from "../hooks/useGetEventSummary";
import { FilterEventType } from "./TypeFilter";
import { EventGraphTooltip } from "./EventGraphTooltip";

interface EventGraphProps {
	sdRecord?: SDRecord; // Fetches all events if undefined
	typeFilter?: FilterEventType;
	userFilter?: number[];
	workspaceFilter?: number;
	lastSevenDays?: boolean;
	height?: number;
}

const ISO_DATE_FORMAT = "yyyy-MM-dd";

export function EventGraph({
	sdRecord,
	typeFilter,
	userFilter,
	workspaceFilter,
	lastSevenDays = false,
	height = 250,
}: EventGraphProps) {
	const [start, setStart] = useState(lastSevenDays ? sub(new Date(), { days: 6 }) : startOfWeek(new Date()));
	const end = useMemo(() => (lastSevenDays ? new Date() : endOfWeek(start)), [start, lastSevenDays]);

	const { data, isLoading } = useGetEventSummary(
		{
			recordId: sdRecord ? String(sdRecord._id) : undefined,
			inclusiveStartDate: format(start, ISO_DATE_FORMAT),
			inclusiveEndDate: format(end, ISO_DATE_FORMAT),
			eventSupertypes: typeFilter !== "all" ? typeFilter : undefined,
			userIds: userFilter?.join(","),
		},
		workspaceFilter
	);

	const graphData: GraphDataItem[] = useMemo(() => {
		return (
			data?.buckets?.map((item) => {
				const date = parse(item.key, ISO_DATE_FORMAT, new Date());
				return {
					dayInitial: format(date, "E")[0],
					fullDate: format(date, "dd/MM/yy"),
					numberOfEvents: item.doc_count,
				};
			}) ?? []
		);
	}, [data]);

	const move = (direction: number) => () => {
		setStart(add(start, { days: direction * 7 }));
	};

	const lastDate = graphData[graphData.length - 1]?.fullDate ?? undefined;
	const maxValue = Math.max(...graphData.map((item) => item.numberOfEvents));
	const maxValueRemainder = maxValue % 4;
	const yAxisMax = Math.max(maxValueRemainder === 0 ? maxValue : maxValue + 4 - maxValueRemainder, 4);
	const stepSize = yAxisMax / 4;
	const ticks = Array.from({ length: 5 }, (_, i) => i * stepSize);

	const totalEvents = graphData.reduce((total, item) => total + item.numberOfEvents, 0);

	return (
		<>
			{!lastSevenDays ? (
				<div className="mb-4 flex items-center gap-4">
					<div className="text-h1 mr-auto">{totalEvents}</div>
					<Button
						startIcon={ICONS.caretLeft}
						variant="text"
						size="xs"
						onClick={move(-1)}
						disabled={sdRecord ? start <= startOfDay(new Date(sdRecord.createdAt)) : false}
					/>
					<div className="text-label">
						{format(start, "d MMM")} - {format(end, "d MMM yyyy")}
					</div>
					<Button startIcon={ICONS.caretRight} variant="text" size="xs" onClick={move(1)} disabled={end > new Date()} />
				</div>
			) : null}
			{isLoading ? (
				<div style={{ height }} className="flex items-center justify-center">
					<Spinner size="md" />
				</div>
			) : (
				<ResponsiveContainer width="100%" height={height}>
					<AreaChart
						width={500}
						height={height}
						data={graphData}
						margin={{
							top: 35,
							right: 5,
							left: -30,
							bottom: 0,
						}}
					>
						<defs>
							<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={getHexOrCssVariableForColorKey("c_brand_primary")} stopOpacity={0.2} />
								<stop offset="95%" stopColor={getHexOrCssVariableForColorKey("c_bg_02")} stopOpacity={0} />
							</linearGradient>
						</defs>
						<CartesianGrid vertical={false} stroke={getHexOrCssVariableForColorKey("c_bg_disabled_02")} />
						<XAxis
							dataKey="dayInitial"
							stroke={getHexOrCssVariableForColorKey("c_text_secondary")}
							fontSize="12px"
							fontWeight={600}
							axisLine={false}
							tickLine={false}
						/>
						<YAxis
							stroke={getHexOrCssVariableForColorKey("c_text_placeholder")}
							fontSize="12px"
							axisLine={false}
							tickLine={false}
							tick={{ dx: -20, textAnchor: "start" }}
							domain={[0, yAxisMax]}
							ticks={ticks}
						/>
						<Tooltip
							isAnimationActive={false}
							content={<EventGraphTooltip lastDate={lastDate} />}
							cursor={{ strokeDasharray: "3 3", stroke: getHexOrCssVariableForColorKey("c_bg_tooltip"), from: "top" }}
							allowEscapeViewBox={{ x: true }}
							position={{ y: 10 }}
						/>
						<Area
							type="monotone"
							dataKey="numberOfEvents"
							stroke={getHexOrCssVariableForColorKey("c_action_02")}
							strokeWidth={1.5}
							fill="url(#colorUv)"
							animationDuration={500}
						/>
					</AreaChart>
				</ResponsiveContainer>
			)}
		</>
	);
}
