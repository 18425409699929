import {
	SDObject,
	SDRecord,
	getSDObjectSystemFieldByName,
	getSDRecordSystemFieldValueByFieldName,
} from "@salesdesk/salesdesk-schemas";
import { mTaskDef, mIssueDef } from "@salesdesk/salesdesk-model";

export const completableRecordFieldNamesMap: Record<
	SDObject["_id"],
	{
		statusFieldName: string;
		progressFieldName: string;
		toDoStatusName: string;
		doneStatusName: string;
		dueDateFieldName: string;
	}
> = {
	[mTaskDef.ID]: {
		statusFieldName: mTaskDef.STATUS_FIELD_NAME,
		progressFieldName: mTaskDef.PROGRESS_FIELD_NAME,
		toDoStatusName: mTaskDef.TO_DO_STATUS_NAME,
		doneStatusName: mTaskDef.COMPLETED_STATUS_NAME,
		dueDateFieldName: mTaskDef.DUE_DATE_FIELD_NAME,
	},
	[mIssueDef.ID]: {
		statusFieldName: mIssueDef.STATUS_FIELD_NAME,
		progressFieldName: mIssueDef.PROGRESS_FIELD_NAME,
		toDoStatusName: mIssueDef.OPEN_STATUS_NAME,
		doneStatusName: mIssueDef.CLOSED_STATUS_NAME,
		dueDateFieldName: mIssueDef.DUE_DATE_FIELD_NAME,
	},
};

export function getCompletableRecordStatusOptionIds(sdObject: SDObject) {
	let doneStatusId: number | null = null;
	let toDoStatusId: number | null = null;

	if (!completableRecordFieldNamesMap[sdObject._id]) {
		return {
			doneStatusId,
			toDoStatusId,
		};
	}

	const { statusFieldName, toDoStatusName, doneStatusName } = completableRecordFieldNamesMap[sdObject._id];

	const statusFieldDef = getSDObjectSystemFieldByName(sdObject, statusFieldName);

	(statusFieldDef?._optionValues || []).forEach((option) => {
		if (option.name === doneStatusName) {
			doneStatusId = option?.id ?? null;
		} else if (option.name === toDoStatusName) {
			toDoStatusId = option?.id ?? null;
		}
	});

	return {
		doneStatusId,
		toDoStatusId,
	};
}

export function getIsCompletableRecordDone(sdRecord: SDRecord, sdObject: SDObject) {
	if (!completableRecordFieldNamesMap[sdObject._id]) return false;

	const { statusFieldName } = completableRecordFieldNamesMap[sdObject._id];
	const { doneStatusId } = getCompletableRecordStatusOptionIds(sdObject);
	const statusValue = getSDRecordSystemFieldValueByFieldName(sdObject, sdRecord, statusFieldName)?._value;
	return doneStatusId === statusValue;
}

export function getUpdatedFieldsForCompletableRecordStatusAndProgress(
	sdObject: SDObject,
	statusId?: number | null,
	progress?: number | null
) {
	if (!completableRecordFieldNamesMap[sdObject._id]) return [];

	const { statusFieldName, progressFieldName } = completableRecordFieldNamesMap[sdObject._id];
	const statusField = getSDObjectSystemFieldByName(sdObject, statusFieldName);
	const progressField = getSDObjectSystemFieldByName(sdObject, progressFieldName);

	if (!statusField || !progressField) {
		throw new Error("Field 'status' or 'progress' was not found");
	}

	return [
		{ _fieldId: statusField._id, _value: statusId },
		{ _fieldId: progressField._id, _value: progress },
	];
}

export function getIsCompletableRecord(sdObject?: SDObject) {
	return sdObject ? completableRecordFieldNamesMap[sdObject._id] !== undefined : undefined;
}
