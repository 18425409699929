import { HasClaimConfig } from "../components/sections";

export const customerClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "leadRecordConvert",
		label: "Convert Lead",
		descriptions: {
			hasClaim: "Can convert Leads",
			hasNotClaim: "Cannot convert Leads",
		},
	},
	{
		name: "customerLoginEdit",
		label: "Enable/Disable Login",
		descriptions: {
			hasClaim: "Can enable and disable customer login",
			hasNotClaim: "Cannot enable and disable customer login",
		},
	},
];
