import { NotificationRuleCard } from "./NotificationRuleCard";
import { CollapsibleList } from "../../../../../components/CollapsibleList";
import { NotificationRule } from "@salesdesk/salesdesk-schemas";
import { NotificationRulesLoading } from "./NotificationRulesLoading";

interface NotificationRulesProps {
	title: string;
	rules: NotificationRule[];
	defaultOpen: boolean;
	isLoading: boolean;
}

export function NotificationRules({ title, rules, defaultOpen, isLoading }: NotificationRulesProps) {
	return (
		<CollapsibleList title={title} count={rules.length} defaultOpen={defaultOpen} showZero={!isLoading}>
			{isLoading ? (
				<NotificationRulesLoading />
			) : rules.length === 0 ? (
				<div className="text-c_text_secondary my-8 w-full text-center text-sm">
					There are no {title.toLowerCase()} rules
				</div>
			) : (
				<div className="grid-cols-cards mt-4 grid gap-x-6 gap-y-4">
					{rules.map((rule) => (
						<NotificationRuleCard key={rule.id} rule={rule} />
					))}
				</div>
			)}
		</CollapsibleList>
	);
}
