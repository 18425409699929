import { asConst } from "json-schema-to-ts";
import { aggregationPropertySchema } from "./aggregationPropertySchema";

export const aggregationsSchema = asConst({
	title: "Aggregations",
	description: "Defines aggregations to be calculated for the query",
	type: "object",
	nullable: false,
	additionalProperties: aggregationPropertySchema,
	minProperties: 1,
});
