import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

export function ChannelMessagePreviewError() {
	return (
		<div className="text-c_danger_01 flex items-center gap-1">
			<Icon size="sm" icon={ICONS.warningCircle} />
			<span className="truncate">Failed to load messages</span>
		</div>
	);
}
