import { ElementType, forwardRef, HTMLAttributes, Ref, useEffect, useState } from "react";
import { useStickyContainerContext } from "../hooks/useStickyContainerContext";

interface StickyProps<T extends HTMLElement> extends HTMLAttributes<T> {
	as?: ElementType;
	priority: number;
	height: number;
	showCover?: boolean;
}

export const Sticky = forwardRef(
	<T extends HTMLElement>(
		{ as: Component = "div", priority, height, showCover, children, style, ...props }: StickyProps<T>,
		ref: Ref<T>
	) => {
		// Generates a unique id which is 5 digits long
		const [uniqueId] = useState(() => Math.floor(10000 + Math.random() * 90000));

		const { registerComponent, unregisterComponent } = useStickyContainerContext();

		const [stickyTop, setStickyTop] = useState(0);

		const prioritisedId = Number(`${priority}${uniqueId}`);

		useEffect(() => {
			registerComponent(prioritisedId, height, (stickyTop) => {
				setStickyTop(stickyTop);
			});

			return () => {
				unregisterComponent(prioritisedId);
			};
		}, [prioritisedId, height, registerComponent, unregisterComponent]);

		return (
			<>
				<Component ref={ref} style={{ ...style, position: "sticky", top: stickyTop }} {...props}>
					{children}
				</Component>
				{/* Cover goes from the top of the page to the sticky element.
				It prevents elements behind stacked sticky elements from being visible during height change
				animations like the workspace nav expanding when the header appears. */}
				{showCover ? <div className="bg-c_bg_01 fixed top-0 z-10 w-full" style={{ height: stickyTop + 5 }} /> : null}
			</>
		);
	}
);
