import { useEffect, useState } from "react";
import { App } from "../../../../app/shared/app";

export function useGlobalAppInitialiser() {
	const [isInitialised, setIsInitialised] = useState(false);

	useEffect(() => {
		App.init(async () => {
			setIsInitialised(true);
		});
	}, []);

	return { isInitialised };
}
