import { useEffect, useState } from "react";

import { VideoPreviewProps } from "../../../types";
import { convertFileToObjectURL } from "../../../utils";

import { VideoPlayer } from "../../VideoPlayer";
import { useVideoEvents } from "../../VideoPlayer/hooks/useVideoEvents";

export function VideoPreview({
	file,
	onOpenPreviewClick,
	variant,
	updateVideoDuration,
	videoRef,
	shouldPause,
}: VideoPreviewProps) {
	const isFileObject = file instanceof File;
	const sdFileMainSignedUrl = isFileObject ? undefined : file.signedUrl;

	const [videoSrc, setVideoSrc] = useState<string>();

	useEffect(() => {
		if (isFileObject) {
			setVideoSrc(convertFileToObjectURL(file));
		} else {
			setVideoSrc(sdFileMainSignedUrl || undefined);
		}
	}, [file, sdFileMainSignedUrl, isFileObject]);

	const signedPosterUrl = isFileObject ? undefined : file.posterSignedUrl;

	const sdFile = !isFileObject ? file : undefined;
	const postVideoEvent = useVideoEvents(sdFile?.recordId, sdFile?.fileId);

	return (
		<VideoPlayer
			ref={videoRef}
			videoSrc={videoSrc}
			posterSrc={signedPosterUrl}
			variant={variant}
			onOpenPreviewClick={onOpenPreviewClick}
			updateVideoDuration={updateVideoDuration}
			onVideoEvent={postVideoEvent}
			shouldPause={shouldPause}
		/>
	);
}
