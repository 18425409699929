import { FromSchema } from "json-schema-to-ts";
import { sdErrorSchema } from "./sdErrorSchema";

export enum SDErrorCode {
	Forbidden = "Forbidden",
	RecordNotSharedWithWorkspace = "RecordNotSharedWithWorkspace",
	RecordsNotSharedWithWorkspace = "RecordsNotSharedWithWorkspace",

	RoleIdAlreadyExists = "RoleIdAlreadyExists",
	RoleClaimTypeInvalid = "RoleClaimTypeInvalid",
	RoleClaimValueInvalid = "RoleClaimValueInvalid",
	RoleNotFound = "RoleNotFound",
	RoleDeleted = "RoleDeleted",
	RoleNotEditable = "RoleNotEditable",
	RolesDirtyWrite = "RolesDirtyWrite",

	UserNotFound = "UserNotFound",
	UserDeleted = "UserDeleted",

	ObjectNotFound = "ObjectNotFound",
	ObjectIdAlreadyExists = "ObjectIdAlreadyExists",
	ObjectDirtyWrite = "ObjectDirtyWrite",
	ObjectDeleted = "ObjectDeleted",
	SystemObjectCannotBeDeleted = "SystemObjectCannotBeDeleted",

	FieldNotFound = "FieldNotFound",
	FieldNotEditable = "FieldNotEditable",
	FieldUniqueCountExceeded = "FieldUniqueCountExceeded",
	FieldObjectNotFound = "FieldObjectNotFound",
	FieldDefaultValueInvalid = "FieldDefaultValueInvalid",
	FieldPropertyValueInvalid = "FieldPropertyValueInvalid",
	SystemFieldMissing = "SystemFieldMissing",
	SystemFieldOptionMissing = "SystemFieldOptionMissing",

	RecordNotFound = "RecordNotFound",
	RecordsNotFound = "RecordsNotFound",
	RecordObjectNotFound = "RecordObjectNotFound",
	RecordObjectInvalid = "RecordObjectInvalid",
	RecordOwnerInvalid = "RecordOwnerInvalid",
	RecordsConflicted = "RecordsConflicted",
	RecordsDirtyWrite = "RecordsDirtyWrite",
	RecordDeleted = "RecordDeleted",
	RecordCreationNotSupported = "RecordCreationNotSupported",
	RecordPatchingNotSupported = "RecordPatchingNotSupported",
	RecordDeletionNotSupported = "RecordDeletionNotSupported",
	RecordNotWorkspace = "RecordNotWorkspace",
	RecordNotAssociatedToWorkspace = "RecordNotAssociatedToWorkspace",
	RecordsCannotBeSharedWithWorkspaces = "RecordsCannotBeSharedWithWorkspaces",
	RecordSharedWithWorkspace = "RecordSharedWithWorkspace",

	WorkspaceIdInvalid = "WorkspaceIdInvalid",

	NoteReactionNotFound = "NoteReactionNotFound",
	CommentReactionNotFound = "CommentReactionNotFound",

	IllegalAggregationOrSortSearchArgument = "IllegalAggregationOrSortSearchArgument",

	DocNotFound = "DocNotFound",
	LeadNotFound = "LeadNotFound",
	ContactNotFound = "ContactNotFound",
	AccountNotFound = "AccountNotFound",
	ConflictingAccountFields = "ConflictingAccountFields",

	InvalidObjectAssociation = "InvalidObjectAssociation",
	AssociationLabelInvalid = "AssociationLabelInvalid",
	AssociationCreationNotSupported = "AssociationCreationNotSupported",
	AssociationMultiplicityViolation = "AssociationMultiplicityViolation",
	AssociationAlreadyExists = "AssociationAlreadyExists",
	AssociationNotFound = "AssociationNotFound",
	InvalidAssociation = "InvalidAssociation",
	ObjectAssociationNotFound = "ObjectAssociationNotFound",
	CannotDeleteSystemObjectAssociation = "CannotDeleteSystemObjectAssociation",

	FieldValueMissing = "FieldValueMissing",
	FieldValueInvalid = "FieldValueInvalid",
	FieldValueReferencedRecordsNotFound = "FieldValueReferencedRecordsNotFound",
	FieldValueReferencedRecordsDoNotBelongToCorrectObject = "FieldValueReferencedRecordsDoNotBelongToCorrectObject",
	FieldValueReferencedRolesNotFound = "FieldValueReferencedRolesNotFound",

	UsernameAlreadyExists = "UsernameAlreadyExists",

	FileNotFound = "FileNotFound",
	FileNotUploaded = "FileNotUploaded",
	FileNotOfType = "FileNotOfType",

	ImportNotFound = "ImportNotFound",
	ImportMappingColumnNamesNotFound = "ImportMappingColumnNamesNotFound",
	ImportMappingFieldNotFound = "ImportMappingFieldNotFound",
	ImportMappingFieldDuplication = "ImportMappingFieldDuplication",
	ImportMappingFieldNotSupported = "ImportMappingFieldNotSupported",
	ImportMappingMissingRequiredFields = "ImportMappingMissingRequiredFields",
	ImportJobNotFound = "ImportJobNotFound",
	ImportJobAlreadyRunning = "ImportJobAlreadyRunning",
	CSVFileInvalid = "CSVFileInvalid",
	CSVFileInvalidHeader = "CSVFileInvalidHeader",
	CSVFileHasNoRows = "CSVFileHasNoRows",
	CSVFileHasTooManyColumns = "CSVFileHasTooManyColumns",

	BookmarkNotFound = "BookmarkNotFound",

	NotificationsNotFound = "NotificationsNotFound",
	NotificationRuleNotFound = "NotificationRuleNotFound",
	NotificationRuleObjectNotFound = "NotificationRuleObjectNotFound",
	NotificationRuleObjectNotPermitted = "NotificationRuleObjectNotPermitted",
	NotificationRuleEventTypeNotPermitted = "NotificationRuleEventTypeNotPermitted",
	NotificationRuleWorkspaceRecordNotFound = "NotificationRuleWorkspaceRecordNotFound",
	NotificationRuleRecordInvalidForWorkspace = "NotificationRuleRecordInvalidForWorkspace",
	NotificationRuleObjectInvalidForWorkspaces = "NotificationRuleObjectInvalidForWorkspaces",
	NotificationRuleRecordNotFound = "NotificationRuleRecordNotFound",
	NotificationRuleUsersNotFound = "NotificationRuleUsersNotFound",
	NotificationRuleRecordInvalidForObject = "NotificationRuleRecordInvalidForObject",
	NotificationRuleFieldIdRequiresObjectIdOrRecordId = "NotificationRuleFieldIdRequiresObjectIdOrRecordId",
	NotificationRuleFieldNotFound = "NotificationRuleFieldNotFound",

	WebSocketConnectionAlreadyExists = "WebSocketConnectionAlreadyExists",
	WebSocketConnectionNotFound = "WebSocketConnectionNotFound",

	NoteNotFound = "NoteNotFound",
	NoteMustBeUpdatedInWorkspaceContext = "NoteMustBeUpdatedInWorkspaceContext",

	CommentNotFound = "CommentNotFound",
	CommentMustBeUpdatedInWorkspaceContext = "CommentMustBeUpdatedInWorkspaceContext",
	RootCommentCannotBeAReply = "RootCommentCannotBeAReply",
	RootCommentNotFound = "RootCommentNotFound",
	CommentReplyCannotBeResolved = "CommentReplyCannotBeResolved",
	CommentAlreadyResolved = "CommentAlreadyResolved",

	CustomerCannotBeMentionedOutsideWorkspace = "CustomerCannotBeMentionedOutsideWorkspace",
	UserCannotBeMentionedIfNotSharedInWorkspace = "UserCannotBeMentionedIfNotSharedInWorkspace",

	EventQueryRecordIdOrUserIdsMissing = "EventQueryRecordIdOrUserIdsMissing",
	EventTypeInvalid = "EventTypeInvalid",

	ApiKeyNotFound = "ApiKeyNotFound",

	ProvisionedThroughputExceeded = "ProvisionedThroughputExceeded",

	UnexpectedError = "UnexpectedError",
}

export type SDError = FromSchema<typeof sdErrorSchema>;
