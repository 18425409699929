import { AssociationDetailsSection } from "./AssociationDetailsSection";
import { useMeetingPageContext } from "../../../../hooks";
import { DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";

interface AssociationDetailsSectionContainerProps {
	objectAssociation: DirectedSDObjectAssociation;
}

export function AssociationDetailsSectionContainer({ objectAssociation }: AssociationDetailsSectionContainerProps) {
	const { meetingRecord } = useMeetingPageContext();

	if (meetingRecord == null) return null;

	return <AssociationDetailsSection objectAssociation={objectAssociation} meetingRecord={meetingRecord} />;
}
