import { transcriptSentimentFilterColors, TranscriptSentimentType, transcriptSentimentTypes } from "../../../types";
import { PillSelectOption, PillSelect } from "../../../../../components/PillSelect";
import { useMemo } from "react";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

interface SentimentFilterProps {
	value: TranscriptSentimentType[];
	onChange: (value: TranscriptSentimentType[]) => void;
}

export function SentimentFilter({ value, onChange }: SentimentFilterProps) {
	const filterOptions: PillSelectOption[] = useMemo(() => {
		return transcriptSentimentTypes.map((filter) => ({
			label: capitalizeString(filter.toLowerCase()),
			value: filter,
			color: transcriptSentimentFilterColors[filter],
		}));
	}, []);

	return (
		<PillSelect
			value={value}
			onChange={(value) => onChange(value as TranscriptSentimentType[])}
			options={filterOptions}
		/>
	);
}
