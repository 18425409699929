import { useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { FilePreview, getFileTypeDetails } from "../../../../files";
import { useDownloadFile } from "../../../../files/hooks/useDownloadFile";
import { DeleteConfirmationDialog } from "../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { FabContainer } from "../../../../../components/FabContainer";
import { Button, Spinner, Tooltip, tw } from "@salesdesk/daisy-ui";
import { RecordViewTopLevelTab } from "../../../../records/components/RecordDetails/components/RecordDetailView/types";
import { useRecordViewTopLevelTabsContext } from "../../../../records/components/RecordDetails/components/RecordDetailView/hooks";
import { AnalyticsFabButton } from "./AnalyticsFabButton";
import { AbilityAction, AbilitySubject, SDFile, VideoFileStatus } from "@salesdesk/salesdesk-schemas";
import clsx from "clsx";
import { VideoAnalysisStatus } from "./VideoAnalysisStatus";
import { DisplayIf } from "../../../../../auth";
import { isAnalysingVideo } from "../utils";

interface FileInputPreviewProps {
	file: SDFile | File | undefined;
	isLoadingSdFile?: boolean;
	onClear: () => void;
	hasBorder?: boolean;
}

export function FileInputPreview({ file, isLoadingSdFile, onClear, hasBorder = true }: FileInputPreviewProps) {
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const downloadFile = useDownloadFile(file instanceof File ? undefined : file);

	const { hasTab } = useRecordViewTopLevelTabsContext();
	const hasAnalyticsTab = hasTab(RecordViewTopLevelTab.analytics);

	if (isLoadingSdFile) {
		return (
			<div className="bg-c_bg_04 flex aspect-video items-center justify-center">
				<Spinner size="sm" />
			</div>
		);
	}

	if (!file) {
		return null;
	}

	const isSdFile = file && !(file instanceof File);
	const isGeneratingFile = isSdFile && file.videoStatus === VideoFileStatus.GENERATING_FILE;

	const isVideoFile = getFileTypeDetails(file).type === "video";

	return (
		<>
			<div className="bg-c_bg_01 relative -top-0.5 rounded-sm">
				<FabContainer
					hide={isGeneratingFile}
					positionClasses={tw`top-2 right-2`}
					buttons={
						<div className="flex gap-1">
							{hasAnalyticsTab ? (
								<AnalyticsFabButton />
							) : (
								<Tooltip text="Expand">
									<Button
										size="xs"
										variant="text"
										startIcon={ICONS.fullscreen}
										onClick={(e) => {
											e.preventDefault();
											setExpanded(true);
										}}
									/>
								</Tooltip>
							)}
							<DisplayIf can={[AbilityAction.DownloadInUI, AbilitySubject.File]}>
								<Tooltip text="Download">
									<Button
										size="xs"
										variant="text"
										startIcon={ICONS.download}
										onClick={(e) => {
											e.preventDefault();
											downloadFile();
										}}
									/>
								</Tooltip>
							</DisplayIf>
							{!hasAnalyticsTab ? (
								<Tooltip text="Clear">
									<Button
										size="xs"
										variant="text"
										startIcon={ICONS.trash}
										onClick={(e) => {
											e.preventDefault();
											setDeleteDialogOpen(true);
										}}
									/>
								</Tooltip>
							) : null}
						</div>
					}
				>
					<div
						className={clsx(
							"aspect-video w-full overflow-hidden rounded-t-sm",
							isVideoFile && !isGeneratingFile ? "bg-c_brand_dark" : "bg-c_bg_04"
						)}
					>
						<FilePreview file={file} expanded={expanded} onExpandedChange={setExpanded} variant="md" />
					</div>
				</FabContainer>
				{isSdFile && isAnalysingVideo(file) ? (
					<div className={clsx("mt-1 flex flex-col gap-3 py-2", hasBorder && "px-4")}>
						<VideoAnalysisStatus />
					</div>
				) : null}
			</div>
			{deleteDialogOpen ? (
				<DeleteConfirmationDialog
					title="Remove file"
					open
					onConfirm={() => {
						onClear();
						setDeleteDialogOpen(false);
					}}
					onDismiss={() => {
						setDeleteDialogOpen(false);
					}}
				>
					Are you sure you want to remove the reference to <b>“{file.name}”</b>?
				</DeleteConfirmationDialog>
			) : null}
		</>
	);
}
