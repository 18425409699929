import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS } from "../../../routes";
import {
	viewObjectManagerPageActions,
	viewRolesPageActions,
	viewTenantSettingsPageActions,
	viewUsersPageActions,
	useWebPrincipal,
} from "../../../auth";
import { LinkMenuItem, MenuItem } from "../../menu";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";

export function useUserSettingsMainMenuItems() {
	const principal = useWebPrincipal();

	return useMemo(() => {
		const mainSection: MenuItem[] = [
			{
				icon: ICONS.settings,
				text: "Settings",
				subMenu: [
					{ text: "Profile", link: PATHS.SETTINGS_PROFILE(), type: "link" },
					...addIf(principal.canAny(viewTenantSettingsPageActions), {
						text: "Account",
						link: PATHS.SETTINGS_ACCOUNT(),
						type: "link",
					}),
					{ text: "Notifications", link: PATHS.SETTINGS_NOTIFICATIONS(), type: "link" },
					...addIf(principal.canAny(viewObjectManagerPageActions), {
						text: "Object manager",
						link: PATHS.OBJECT_MANAGER(),
						type: "link",
					}),
					...addIf(principal.can(AbilityAction.Create, AbilitySubject.SDApiKey), {
						text: "Developer",
						link: PATHS.SETTINGS_DEVELOPER(),
						type: "link",
					}),
				],
				type: "subMenu",
			},
		];

		const canViewRolePage = principal.canAny(viewRolesPageActions);
		const canViewUsersPage = principal.canAny(viewUsersPageActions);

		if (canViewRolePage || canViewUsersPage)
			mainSection.push({
				icon: ICONS.lock,
				text: "Access",
				subMenu: [
					...addIf(canViewRolePage, { text: "Roles", link: PATHS.ACCESS_ROLES(), type: "link" }),
					...addIf(canViewUsersPage, { text: "Users", link: PATHS.ACCESS_USERS(), type: "link" }),
					...addIf(canViewUsersPage, { text: "Customers", link: PATHS.ACCESS_CUSTOMERS(), type: "link" }),
				],
				type: "subMenu",
			});

		return mainSection;
	}, [principal]);
}

function addIf(can: boolean, item: LinkMenuItem) {
	return can ? [item] : [];
}
