import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../../components";

export const matchAllClauseSchema = asConst({
	title: "Match All",
	description: "Will match any record where any field value contains the provided value",
	type: "object",
	additionalProperties: false,
	required: ["matchAll"],
	properties: {
		matchAll: stringSchema,
	},
	nullable: false,
});
