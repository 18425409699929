import { HasClaimConfig } from "../components/sections";

export const recordActionsClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "fileDownloadInUI",
		label: "Download File",
		descriptions: {
			hasClaim: "Can download files from file fields",
			hasNotClaim: "Cannot download files from file fields",
		},
	},
	{
		name: "sentimentAnalysisView",
		label: "View Sentiment Analysis",
		descriptions: {
			hasClaim: "Can view sentiment analysis",
			hasNotClaim: "Cannot view sentiment analysis",
		},
	},
	{
		name: "coachingAdviceView",
		label: "View Coaching Advice",
		descriptions: {
			hasClaim: "Can view coaching advice",
			hasNotClaim: "Cannot view coaching advice",
		},
	},
	{
		name: "objectRecordBulkEdit",
		label: "Bulk Edit",
		descriptions: {
			hasClaim: "Can bulk edit or delete records",
			hasNotClaim: "Cannot bulk edit or delete records",
		},
	},
];
