import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";

import { useTrack } from "../../VideoCallProvider";
import { AudioMeter } from "../Audio";

interface ParticipantAudioMeterProps {
	audioPublication?: LocalTrackPublication | RemoteTrackPublication;
}

export function ParticipantAudioMeter({ audioPublication }: ParticipantAudioMeterProps) {
	const audioTrack = useTrack(audioPublication);
	return <AudioMeter audioTrack={audioTrack && audioTrack.kind === "audio" ? audioTrack : undefined} />;
}
