import { useState } from "react";

import { ParticipantUserAndStatus } from "../types";
import { Participant } from "./Participant";
import { Button } from "@salesdesk/daisy-ui";

interface ParticipantDetailsProps {
	participantUsers: ParticipantUserAndStatus[];
	compact?: boolean;
}

const defaultDisplayCount = 3;

export function ParticipantDetails({ participantUsers, compact }: ParticipantDetailsProps) {
	const [showAll, setShowAll] = useState(false);

	const usersToDisplay = showAll ? participantUsers : participantUsers.slice(0, defaultDisplayCount);

	return (
		<div className="flex flex-col gap-4">
			{usersToDisplay.map((participant) => (
				<Participant key={participant.email} participant={participant} compact={compact} />
			))}
			{participantUsers.length > defaultDisplayCount ? (
				<div className="pl-1">
					<Button size="sm" variant="primary_text" onClick={() => setShowAll((showAll) => !showAll)}>
						{showAll ? "Show Less" : `Show All (${participantUsers.length})`}
					</Button>
				</div>
			) : null}
		</div>
	);
}
