import clsx from "clsx";
import { GraphDataItem } from "../types";

interface EventGraphTooltipProps {
	active?: boolean;
	payload?: {
		value: string;
		payload: GraphDataItem;
	}[];
	label?: string;
	lastDate?: string;
}

export function EventGraphTooltip({ active, payload, lastDate }: EventGraphTooltipProps) {
	if (!active || !payload || !payload.length) {
		return null;
	}

	const isLastDate = payload[0].payload.fullDate === lastDate;

	return (
		<div
			className={clsx(
				"bg-c_bg_tooltip text-c_text_inverted relative bottom-4 -ml-px w-20 rounded-sm px-4 py-1",
				isLastDate ? "-left-[90px]" : "-left-[10px]"
			)}
		>
			<div className="text-label-sm">{payload[0].value}</div>
			<div className="text-label-xs">{payload[0].payload.fullDate}</div>
		</div>
	);
}
