import { useImportContext } from "../../hooks/useImportContext";
import { ProgressIndicator } from "../../../../components/ProgressIndicator/ProgressIndicator";
import { usePollImportJob } from "../../hooks/usePollImportJob";

export function ImportPreviewProgress() {
	const { previewImportJob, setPreviewImportJob } = useImportContext();

	const { percentageDone } = usePollImportJob(previewImportJob, setPreviewImportJob);

	return (
		<div className="my-6 flex w-full grow items-start justify-center">
			<div className="flex w-full max-w-[620px] flex-col items-center justify-center gap-6 pt-10">
				<img src="/static/images/graphics/import-graphic.svg" alt="Import graphic" />
				<ProgressIndicator percentage={percentageDone} />
				<div className="flex flex-col items-center gap-3">
					<div className="text-label">Loading your import preview</div>
					<div className="text-body-sm">This may take a few moments, please wait.</div>
				</div>
			</div>
		</div>
	);
}
