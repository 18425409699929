import { Transition } from "@headlessui/react";
import { tw } from "../../../../../../utils/tailwind-helpers";
import { AddRecordButton } from "../../WorkspaceResources";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

interface EmptySectionProps {
	show?: boolean;
	withFiltering?: boolean;
	sdObjectIds: number[];
	associateWith?: SDRecord;
	resourcesTitle?: string;
	messageText: string;
	buttonText: string;
	resourcesOnly?: boolean;
}

export function EmptySection({ show = true, messageText, buttonText, ...addRecordButtonProps }: EmptySectionProps) {
	return (
		<Transition
			show={show}
			className="border-c_action_04 rounded-card flex h-full w-full flex-col justify-start gap-4 border border-dashed p-4 transition-opacity"
			enter={tw`delay-75 duration-75 sr-only opacity-0`}
			entered={tw`opacity-100`}
		>
			<p className="text-c_text_placeholder">{messageText}</p>
			<AddRecordButton variant="primary" size="sm" {...addRecordButtonProps}>
				{buttonText}
			</AddRecordButton>
		</Transition>
	);
}
