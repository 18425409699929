import { NotesController } from "../../../../../../../notes";
import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";
import { PanelTabPanel } from "../../../../../../../../components/PanelTabs";

interface NotesPanelProps {
	onUpdateCount?: (count: number) => void;
}

export function NotesPanel({ onUpdateCount }: NotesPanelProps) {
	const { sdRecord } = useRecordDetailsContext();

	return (
		<PanelTabPanel className="flex w-full max-w-6xl flex-col pr-8" unmount={false}>
			<div className="text-label pt-4">Notes</div>
			<div className="h-full overflow-hidden">
				{sdRecord ? <NotesController sdRecord={sdRecord} onUpdateCount={onUpdateCount} /> : null}
			</div>
		</PanelTabPanel>
	);
}
