import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions/textAppenders";
import { objectSummaryInfoSchema } from "./objectSummaryInfoSchema";

export const objectSummaryInfoArraySchema = asConst({
	title: "Array of ChildTypeInfo",
	type: "array",
	description: appendNotEditable("TODO:  Remove me"),
	items: objectSummaryInfoSchema,
});
