import { useMemo } from "react";
import { JSONContent } from "@tiptap/core";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { ChannelMessageMetadata } from "@salesdesk/salesdesk-schemas";

import { convertRichTextJSONToText } from "../../../../../../../fields";
import { ChannelMessagePreviewTemplate } from "./ChannelMessagePreviewTemplate";
import { ChannelMessagePreviewRecord } from "./ChannelMessagePreviewRecord";

interface ChannelMessagePreviewProps {
	message?: ChannelMessage | null;
}

export function ChannelMessagePreview({ message }: ChannelMessagePreviewProps) {
	const userMessageText = useMemo(() => {
		if (!message) return undefined;

		const messageContent = JSON.parse(message.Content ?? "null") as JSONContent | null;

		return messageContent ? convertRichTextJSONToText(messageContent) : undefined;
	}, [message]);

	const { metadata, attachedRecordIds } = useMemo(() => {
		const metadata = message?.Metadata ? (JSON.parse(message.Metadata) as ChannelMessageMetadata) : undefined;

		return {
			metadata,
			attachedRecordIds: metadata?.messageType === "Standard" ? metadata?.attachments?.recordIds || [] : [],
		};
	}, [message]);

	const singleSharedRecordId = useMemo(
		() => (attachedRecordIds?.length === 1 ? attachedRecordIds[0] : undefined),
		[attachedRecordIds]
	);

	const { text, icon } = useMemo(() => {
		if (metadata?.messageType === "Huddle") {
			return {
				text: "Started a huddle",
				icon: ICONS.videoCamera,
			};
		} else if (attachedRecordIds?.length > 1) {
			return {
				text: userMessageText ? userMessageText : `${attachedRecordIds.length} records`,
				icon: ICONS.file,
			};
		} else if (message?.Redacted) {
			return {
				text: "This message was deleted.",
				icon: ICONS.info,
			};
		} else if (userMessageText) {
			return {
				text: userMessageText,
				icon: undefined,
			};
		}

		return {
			text: "No messages yet",
			icon: undefined,
		};
	}, [metadata, attachedRecordIds, message, userMessageText]);

	if (singleSharedRecordId) {
		return (
			<ChannelMessagePreviewRecord
				message={message}
				recordId={singleSharedRecordId}
				userMessageText={userMessageText}
			/>
		);
	}

	return <ChannelMessagePreviewTemplate message={message} icon={icon} messageText={text} />;
}
