import { ICONS } from "@salesdesk/salesdesk-ui";

import { ParticipantDetails, useMeetingParticipants } from "../../../../Participants";
import { MeetingInfoSection } from "./MeetingInfoSection";

export function InfoPanelParticipantDetails() {
	const participantUsers = useMeetingParticipants();

	return participantUsers ? (
		<MeetingInfoSection icon={ICONS.users} title="Participants" alertCount={participantUsers.length}>
			<ParticipantDetails participantUsers={participantUsers} compact />
		</MeetingInfoSection>
	) : null;
}
