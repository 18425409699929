import clsx from "clsx";

import { Spinner } from "@salesdesk/daisy-ui";
import { useMediaAnalysisContext } from "../../hooks/useMediaAnalisysContext";
import { GraphControls } from "./GraphControls/GraphControls";
import { SentimentAnalysis } from "./SentimentAnalysis";
import { AiDisclaimer, CoachingAdvice, TopTopics } from "./llm";

export function AnalysisView() {
	const { isMediaAnalysisLoading, faceSentimentsPerUser, transcriptSentimentsPerUser, file } =
		useMediaAnalysisContext();

	return (
		<div
			className={clsx(
				"h-[60%] flex-grow overflow-y-auto",
				isMediaAnalysisLoading && "flex items-center justify-center"
			)}
		>
			{isMediaAnalysisLoading ? (
				<Spinner size="md" />
			) : (
				<div className="flex flex-col gap-4">
					{faceSentimentsPerUser && transcriptSentimentsPerUser ? (
						<div>
							<SentimentAnalysis />
							<GraphControls />
						</div>
					) : null}
					{file.topTopics || file.coachingAdvice ? (
						<AiDisclaimer className="flex flex-col gap-6">
							{file.topTopics ? <TopTopics topTopics={file.topTopics} /> : null}
							{file.coachingAdvice ? <CoachingAdvice coachingAdvice={file.coachingAdvice} /> : null}
						</AiDisclaimer>
					) : null}
				</div>
			)}
		</div>
	);
}
