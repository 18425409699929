import { ReactNode } from "react";
import { DPCalendar } from "@rehookify/datepicker";

interface CalendarProps {
	prevButton?: ReactNode;
	nextButton?: ReactNode;
	todayButton?: ReactNode;
	calendar: DPCalendar;
}

export const CalendarHeader = ({ prevButton, nextButton, todayButton, calendar }: CalendarProps) => {
	const { month, year } = calendar;
	return (
		<div className="flex gap-x-4">
			<div className="flex grow items-center justify-between">
				{prevButton || <div />}
				<p className="text-c_text_secondary text-label text-center">
					{month} {year}
				</p>
				{nextButton || <div />}
			</div>
			<div className="flex items-center justify-center">{todayButton || <div />}</div>
		</div>
	);
};
