import { useState, ChangeEvent, useRef } from "react";
import AvatarEditor, { ImageState } from "react-avatar-editor";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

import { Dialog, DialogContent } from "../../../Dialog";

const DEFAULT_RANGE_MIN = 1;
const RANGE_MAX = 2;
const AVATAR_SIZE = 240;
const CROPPED_IMAGE_MIME_TYPE = "image/png";

interface CroppingPhotoModalProps {
	open: boolean;
	photoFile?: File | null;
	onSave: (file: File) => void;
	onClose: () => void;
	isLoading: boolean;
	mimeTypes?: string[];
	variant?: "square" | "circle";
	backgroundColor?: string;
}

export function CroppingPhotoModal({
	open,
	photoFile,
	onSave,
	onClose,
	isLoading,
	variant = "circle",
	backgroundColor,
}: CroppingPhotoModalProps) {
	const cropRef = useRef<AvatarEditor>(null);

	const [scale, setScale] = useState(1);
	const [scaleMinRange, setScaleMinRange] = useState(DEFAULT_RANGE_MIN);

	const handleLoadSuccess = (imgInfo: ImageState) => {
		setScaleMinRange(AVATAR_SIZE / imgInfo.width);
	};

	const handleScale = (event: ChangeEvent<HTMLInputElement>) => {
		setScale(parseFloat(event.target.value));
	};

	const zoomIn = () => {
		setScale(Math.min(scale + 0.1, RANGE_MAX));
	};

	const zoomOut = () => {
		setScale(Math.max(scale - 0.1, scaleMinRange));
	};

	const handleSave = async () => {
		if (cropRef.current && photoFile) {
			const dataUrl = cropRef.current.getImage().toDataURL();
			const result = await fetch(dataUrl);
			const photoBlob = await result.blob();
			const newPhotoFile = new File([photoBlob], photoFile?.name, { type: CROPPED_IMAGE_MIME_TYPE });
			onSave(newPhotoFile);
		}
	};

	if (!photoFile) return;

	return (
		<Dialog open={open}>
			<DialogContent>
				<div className="flex min-w-[440px] flex-col gap-10 pb-4">
					<div className="flex flex-col gap-3">
						<div className="flex justify-end">
							<Button startIcon={ICONS.cross} variant="text" onClick={onClose} />
						</div>
						<div className="flex justify-center">
							<AvatarEditor
								backgroundColor={backgroundColor}
								ref={cropRef}
								className={clsx(
									variant === "circle" ? "rounded-full" : "rounded-lg",
									"border-c_border_regular border-2"
								)}
								image={photoFile}
								scale={scale}
								style={{ cursor: "move" }}
								width={AVATAR_SIZE}
								height={AVATAR_SIZE}
								border={0}
								onLoadSuccess={handleLoadSuccess}
							/>
						</div>

						<div className="flex items-center gap-4">
							<Button startIcon={ICONS.minus} variant="text" onClick={zoomOut} />
							<input
								className="focus-visible:ring-c_action_focus grow focus-visible:ring"
								type="range"
								value={scale}
								min={scaleMinRange}
								max={RANGE_MAX}
								step="0.01"
								onChange={handleScale}
							/>
							<Button startIcon={ICONS.plus} variant="text" onClick={zoomIn} />
						</div>
					</div>

					<div className="flex justify-end gap-3">
						<Button variant="secondary" onClick={onClose}>
							Cancel
						</Button>
						<Button onClick={handleSave} isLoading={isLoading}>
							Save
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
