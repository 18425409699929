import { asConst } from "json-schema-to-ts";
import { NotificationChannelType } from "./NotificationChannelType";

export const notificationChannelSchema = asConst({
	title: "NotificationChannelType",
	description: "The different channels that a notification can be sent on",
	type: "object",
	oneOf: [
		{
			additionalProperties: false,
			required: ["channelType"],
			properties: {
				channelType: {
					type: "string",
					enum: [NotificationChannelType.Browser],
				},
			},
		},
		{
			additionalProperties: false,
			required: ["channelType"],
			properties: {
				channelType: {
					type: "string",
					enum: [NotificationChannelType.Email],
				},
			},
		},
		{
			additionalProperties: false,
			required: ["channelType"],
			properties: {
				channelType: {
					type: "string",
					enum: [NotificationChannelType.Slack],
				},
			},
		},
		{
			additionalProperties: false,
			required: ["channelType"],
			properties: {
				channelType: {
					type: "string",
					enum: [NotificationChannelType.Whatsapp],
				},
			},
		},
		{
			additionalProperties: false,
			required: ["channelType", "webhookUrl"],
			properties: {
				channelType: {
					type: "string",
					enum: [NotificationChannelType.Zapier],
				},
				webhookUrl: {
					title: "Zapier Webhook URL",
					description: "URL of Zapier webhook",
					type: "string",
				},
			},
		},
	],
});
