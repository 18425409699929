import { Skeleton } from "../../../../components/Skeleton/Skeleton";

interface RecordPreviewLoadingProps {
	isUserRecord?: boolean;
}

export function RecordPreviewLoading({ isUserRecord }: RecordPreviewLoadingProps) {
	return (
		<div className="flex flex-col gap-3 pb-[46px]">
			<Skeleton className="w-22 h-5" />
			<div className="flex w-full gap-3">
				{isUserRecord ? <Skeleton className="size-[60px] shrink-0" /> : null}
				<div className="flex w-full flex-col gap-3">
					<Skeleton className="h-6 w-11/12" />
					<Skeleton className="h-6 w-2/3" />
					<Skeleton className="h-6 w-1/2" />
					<Skeleton className="h-6 w-2/3" />
				</div>
			</div>
		</div>
	);
}
