import { useEffect, useState } from "react";
import { Header } from "@tanstack/react-table";

import { COLUMN_TYPE, EndColumnDetails, ROW_LINK_COLUMN_ID, TableColumnId, TableRow } from "../types";
import { useLinkedTablesState } from "./useLinkedTables";

interface useColumnHeaderWidthProps<T> {
	header: Header<TableRow<T>, unknown>;
	updateEndColumnDetails: (columnId: TableColumnId, width: number) => void;
	isPrimaryLinkedTable?: boolean;
	columnType: COLUMN_TYPE;
	isResizing?: boolean;
	endColumnDetails?: EndColumnDetails;
}

export function useColumnHeaderWidth<T>({
	header,
	updateEndColumnDetails,
	columnType,
	isResizing,
	endColumnDetails,
	isPrimaryLinkedTable,
}: useColumnHeaderWidthProps<T>) {
	const [newEndColumnDetails, setNewEndColumnDetails] = useState<{ columnId: TableColumnId; width: number } | null>();

	// Updates the end column details through a useEffect to prevent triggering a re-render of the parent
	// table component while the table is rendering the column headers
	useEffect(() => {
		if (!newEndColumnDetails) {
			return;
		}

		const { columnId, width } = newEndColumnDetails;
		updateEndColumnDetails(columnId, width);

		setNewEndColumnDetails(null);
	}, [newEndColumnDetails, updateEndColumnDetails]);

	const { id: headerId, column } = header;
	const isRowLinkColumn = headerId === ROW_LINK_COLUMN_ID;

	const { widthByColumnId, updateColumnWidth } = useLinkedTablesState() || {};

	const usingLinkedTableWidth = widthByColumnId && widthByColumnId[headerId] != null && !isPrimaryLinkedTable;

	let width: string | number = header.getSize();

	if (!usingLinkedTableWidth) {
		if (isRowLinkColumn) {
			width = "auto";
		} else if (columnType === COLUMN_TYPE.END || columnType === COLUMN_TYPE.SINGLE) {
			const { id: endColumnId, visualWidth, widthOffset } = endColumnDetails || {};

			// If widthOffset is undefined it means the table has just initialised and doesn't know its width yet
			if (widthOffset === undefined) {
				width = "auto";
			} else {
				width += widthOffset || 0;

				const columnId = column.id;

				if (
					!isResizing &&
					!newEndColumnDetails &&
					(!endColumnDetails || endColumnId !== columnId || visualWidth !== width)
				) {
					setNewEndColumnDetails({ columnId, width });
				}
			}
		}
	}

	useEffect(() => {
		if (updateColumnWidth && isPrimaryLinkedTable) {
			updateColumnWidth(headerId, width);
		}
	}, [headerId, width, isPrimaryLinkedTable, updateColumnWidth]);

	if (widthByColumnId && widthByColumnId[headerId] != null && !isPrimaryLinkedTable) {
		return widthByColumnId[headerId];
	}

	return width;
}
