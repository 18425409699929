import { Accordion } from "../../../../../../components/Accordion";

export function TopTopics({ topTopics }: { topTopics: string[] }) {
	return (
		<Accordion title="Top topics" defaultOpen={true} variant="secondary" panelClassName="mt-4">
			<div className="ml-4">
				{topTopics.map((topic, index) => (
					<div key={topic} className="flex items-center gap-4">
						<div className="bg-c_action_04 text-label-sm text-c_text_inverted mx-0.5 flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
							{index + 1}
						</div>
						<div className="py-2">{topic}</div>
					</div>
				))}
			</div>
		</Accordion>
	);
}
