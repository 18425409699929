import { mSystemObjectDef } from "../system_object_def";

export class mLeadDef extends mSystemObjectDef {
	static CLASS_NAME = "mLeadDef";
	static NAME = "Lead";
	static PLURAL_NAME = "Leads";
	static ICON = "ph-database";

	static STATUS_FIELD_NAME = "status";
	static RATING_FIELD_NAME = "rating";
	static SOURCE_FIELD_NAME = "source";
	static NUMBER_OF_EMPLOYEES_FIELD_NAME = "numberOfEmployees";
	static ADDRESS_FIELD_NAME = "address";

	static QUALIFIED_STATUS_NAME = "Qualified";
	static UNQUALIFIED_STATUS_NAME = "Unqualified";

	static ID = 51;

	constructor(ownerId) {
		super(mLeadDef.ID, ownerId);
	}
}
