import { Combobox, Transition } from "@headlessui/react";

import { Fragment, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { SelectOptionItem } from "../BaseSelect";
import { SelectOption, SelectOptionId } from "../../../../types";
import { Searchbar } from "../../../Searchbar";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { debounce } from "../../../../../../utils";
import { SelectOptionItemVariant } from "../../types";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";
import { Button, Spinner } from "@salesdesk/daisy-ui";
import { Placement } from "@floating-ui/react";
import { SELECT_POPOVER_TRANSITION } from "../../utils";

interface ButtonTypeaheadSelectProps {
	selectOptions?: SelectOption[];
	getSelectOptions?: (searchQuery: string) => Promise<SelectOption[]>;
	value: SelectOptionId[];
	onChange: (newValue: SelectOptionId[]) => void;
	optionDisplayVariant?: SelectOptionItemVariant;
	placement?: Placement;
	isMultiSelect?: boolean;
}

const DEBOUNCE_DELAY_MS = 300;

export function ButtonTypeaheadSelect({
	selectOptions: argSelectedOptions,
	getSelectOptions,
	value,
	onChange,
	children,
	optionDisplayVariant,
	placement = "bottom-end",
	isMultiSelect = true,
}: PropsWithChildren<ButtonTypeaheadSelectProps>) {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [selectOptions, setSelectOptions] = useState<SelectOption[] | undefined>(argSelectedOptions);
	const [isLoadingOptions, setIsLoadingOptions] = useState(!!getSelectOptions);

	const onQueryChange = useMemo(
		() =>
			debounce((query: string) => {
				if (!getSelectOptions) return;
				setIsLoadingOptions(true);
				getSelectOptions(query).then((options) => {
					setSelectOptions(options);
					setIsLoadingOptions(false);
				});
			}, DEBOUNCE_DELAY_MS),
		[getSelectOptions]
	);

	useEffect(() => {
		onQueryChange("");
	}, [onQueryChange]);

	const handleUnselectAll = () => {
		onChange([]);
	};

	const componentFn = ({ open }: { open: boolean }) => (
		<Popover open={open} keepPopoverMounted hideWhenClosedButMounted={false} placement={placement}>
			<PopoverTrigger>
				<Combobox.Button as={Fragment}>{children}</Combobox.Button>
			</PopoverTrigger>
			<PopoverContent initialFocus={-1}>
				<Transition as={Fragment} {...SELECT_POPOVER_TRANSITION} appear>
					<PopoverContainer className="w-[256px]">
						<Combobox.Input as={Fragment}>
							<div className="my-0.5 ml-6 mr-4 flex gap-1 overflow-hidden">
								<Searchbar
									value={searchQuery}
									onChange={(query) => {
										setSearchQuery(query);
										onQueryChange(query);
									}}
									isCollapsible={false}
									isClearable={true}
									autoFocus
									size={12}
									wrapperClass="w-full"
								/>
								<Button
									variant="text"
									size="lg"
									startIcon={ICONS.crossCircle}
									iconVariant="fill"
									onClick={handleUnselectAll}
								/>
							</div>
						</Combobox.Input>
						<div className="h-[225px] w-full overflow-y-auto">
							{isLoadingOptions ? (
								<div className="flex w-full justify-center px-6 py-3">
									<Spinner />
								</div>
							) : selectOptions?.length ? (
								<Combobox.Options static>
									{selectOptions.map((option) => (
										<Combobox.Option key={option.id} value={option.id} as="li" className="overflow-hidden">
											{({ selected, active }) => (
												<SelectOptionItem
													withinPopover
													selected={selected}
													active={active}
													isMultiselect={isMultiSelect}
													option={option}
													variant={optionDisplayVariant}
												/>
											)}
										</Combobox.Option>
									))}
								</Combobox.Options>
							) : (
								<div className="text-c_text_placeholder text-body-sm flex w-full justify-center px-6 py-3">
									No results found.
								</div>
							)}
						</div>
					</PopoverContainer>
				</Transition>
			</PopoverContent>
		</Popover>
	);

	if (isMultiSelect) {
		return (
			<Combobox value={value} onChange={onChange} multiple>
				{componentFn}
			</Combobox>
		);
	}

	return (
		<Combobox value={value[0]} onChange={(newValue: SelectOptionId) => onChange([newValue])}>
			{componentFn}
		</Combobox>
	);
}
