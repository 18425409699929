import { store } from "../../../store";
import { deleteToastById, dismissToastById } from "../store/toastSlice";
import { ToastMessageProps } from "../types";
import { getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { GetRecordDefaultPathFn } from "../../records/hooks/useGetRecordDefaultPath";

export const TOAST_TIMER_MS = 3000;
export const TOAST_OUT_ANIMATION_MS = 100;

export function setDismissToastTimeout(toastId: number): NodeJS.Timeout {
	return setTimeout(() => {
		store.dispatch(dismissToastById(toastId));
		deleteToastAfterAnimation(toastId);
	}, TOAST_TIMER_MS);
}

export function deleteToastAfterAnimation(toastId: number): NodeJS.Timeout {
	return setTimeout(() => {
		store.dispatch(deleteToastById(toastId));
	}, TOAST_OUT_ANIMATION_MS);
}

export function getRecordCreatedParams(
	sdObject: SDObject,
	sdRecord: SDRecord,
	defaultRecordPath: string | GetRecordDefaultPathFn
): ToastMessageProps["messageParams"] {
	return {
		name: {
			type: "node",
			node: sdObject._displayName,
		},
		link: {
			type: "link",
			to: typeof defaultRecordPath === "function" ? defaultRecordPath(sdRecord._id, sdObject) : defaultRecordPath,
			label: getSDRecordName(sdObject, sdRecord),
		},
	};
}
