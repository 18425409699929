import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { PATHS } from "../../../routes";

export function NotificationsHeader({
	unreadOnly,
	toggleAllUnread,
}: {
	unreadOnly: boolean;
	toggleAllUnread: () => void;
}) {
	return (
		<div className="flex items-center justify-between">
			<div className="text-c_text_primary text-label flex items-center gap-2">
				Notifications
				{/* TODO: Not sure this makes sense if only notifications are being displayed because it will already be displayed in the <NotificationsButton> */}
				{/*{unreadCount != null && unreadCount > 0 ? <AlertBubble alertCount={unreadCount} /> : null}*/}
			</div>
			<div className="flex items-center gap-2 pt-1">
				<Tooltip text={unreadOnly ? "Show all notifications" : "Show unread only"}>
					<Button startIcon={ICONS.filter} variant={unreadOnly ? "primary_text" : "text"} onClick={toggleAllUnread} />
				</Tooltip>
				<Tooltip text="Notification settings">
					<Button startIcon={ICONS.settings} variant="text" as="link" to={PATHS.SETTINGS_NOTIFICATIONS()} />
				</Tooltip>
			</div>
		</div>
	);
}
