import { NotificationRule } from "@salesdesk/salesdesk-schemas";
import { Strong } from "./Strong";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

export function NotifyMe({ rule }: { rule: NotificationRule }) {
	return (
		<>
			{` notify me${rule.channels.length > 0 ? " via " : ""}`}
			{rule.channels.flatMap((channel, index) => [
				index > 0 ? " and " : [],
				<Strong key={channel.channelType}>{capitalizeString(channel.channelType)}</Strong>,
			])}
		</>
	);
}
