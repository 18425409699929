import { asConst } from "json-schema-to-ts";
import { appendEditableForCustom } from "../../../functions/textAppenders";

import { optionValueSchema } from "./optionValueSchema";

export const optionValueArraySchema = asConst({
	title: "Array of Field OptionValue",
	description: appendEditableForCustom(
		"Required by Option Fields and contains the options allowed for selection for this field"
	),
	type: "array",
	items: optionValueSchema,
});
