import { Field, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mObjectFieldDef, mSingleOptionFieldDef, mStringFieldDef, mUserDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";
import { Pill } from "../../../../../../components/Pill/Pill";
import { HeaderCell, ROW_IS_LOADING_PLACEHOLDER } from "../../../../../Table";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../../fields";
import {
	Filter,
	SortingDetails,
	SortingOrder,
	getSDRecordNameFieldInfo,
	updateSortingDetailsForField,
} from "../../../../../records";
import { METADATA_FIELDS } from "../../../../../ObjectBoard/utils";
import { SelectOption } from "../../../../../inputs";
import { RecordTableColumn, RecordTableRow, RowData } from "../types";

export const SD_RECORD_COLUMN_ID = "sdRecord";
export const NAME_COLUMN_ID = "_name";
export const RECORD_TYPE_COLUMN_ID = "_objectDefId";
export const OWNER_COLUMN_ID = "_ownerId";

export const DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID = {
	[NAME_COLUMN_ID]: 300,
};

export function generateColumns(
	objectMap: Map<number, SDObject>,
	sorting: SortingDetails[] | undefined,
	setSorting: (sortingDetails: SortingDetails[]) => void
): RecordTableColumn[] {
	const renderRowCell = (cellContents: RowData, row: RecordTableRow) => {
		if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof cellContents === "boolean") {
			return <Skeleton className="my-1 h-6 w-full" />;
		}
		const isSdRecord = typeof cellContents === "object" && "_id" in cellContents;
		if (typeof cellContents === "number" || isSdRecord) {
			return undefined;
		}
		return (
			<DisplayFieldFactory field={cellContents.field} value={cellContents.value} variant={DisplayFieldVariant.table} />
		);
	};

	const renderObjectRowCell = (cellContents: RowData, row: RecordTableRow) => {
		if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof cellContents === "boolean") {
			return <Skeleton className="my-1 h-6 w-full" />;
		}
		if (typeof cellContents !== "number") {
			return undefined;
		}
		const sdObject = objectMap.get(cellContents);
		if (!sdObject) {
			return undefined;
		}
		return (
			<Pill variant="outlined" icon={sdObject._icon}>
				{sdObject._displayName}
			</Pill>
		);
	};

	const getColumnIdBasedProps = (columnId: string) => {
		return {
			columnId,
			sortingOrder: sorting?.find((sort) => sort.fieldId === columnId)?.order,
			onSortingOrderChange: (order?: SortingOrder) => {
				setSorting(updateSortingDetailsForField(sorting ?? [], columnId, order));
			},
		};
	};

	return [
		{
			id: NAME_COLUMN_ID,
			displayName: () => <HeaderCell {...getColumnIdBasedProps(NAME_COLUMN_ID)} columnName="Name" />,
			renderRowCell,
		},
		{
			id: RECORD_TYPE_COLUMN_ID,
			displayName: () => <HeaderCell {...getColumnIdBasedProps(RECORD_TYPE_COLUMN_ID)} columnName="Record type" />,
			renderRowCell: renderObjectRowCell,
		},
		{
			id: OWNER_COLUMN_ID,
			displayName: () => <HeaderCell {...getColumnIdBasedProps(OWNER_COLUMN_ID)} columnName="Owner" />,
			renderRowCell,
		},
	];
}

export function generateRows(sdRecords: SDRecord[], objectMap: Map<number, SDObject>): RecordTableRow[] {
	return sdRecords.map((record) => {
		const sdObject = objectMap.get(record._objectDefId);
		return {
			[SD_RECORD_COLUMN_ID]: record,
			[NAME_COLUMN_ID]: sdObject ? getSDRecordNameFieldInfo(sdObject, record) : false,
			[RECORD_TYPE_COLUMN_ID]: sdObject?._id ?? false,
			[OWNER_COLUMN_ID]: {
				field: METADATA_FIELDS.owner,
				value: record._ownerId,
			},
		};
	});
}

export function createNameCustomField(): Field {
	const nameField = new mStringFieldDef(NAME_COLUMN_ID);
	nameField.pluralName = "Name";
	nameField.displayName = "Name";
	nameField.maxLength = 1000;
	nameField.required = true;
	return nameField.unmarshall() as Field;
}

export function createRecordTypeField(objectMap: Map<number, SDObject>): Field {
	const objectTypeField = new mSingleOptionFieldDef(RECORD_TYPE_COLUMN_ID);
	objectTypeField.pluralName = "Record type";
	objectTypeField.displayName = "Record type";
	objectTypeField.optionValues = [...objectMap.values()].map((sdObject) => ({
		id: sdObject._id,
		name: sdObject._displayName,
		icon: sdObject._icon,
	}));
	return objectTypeField.unmarshall() as Field;
}

export function createOwnerField(): Field {
	const ownerField = new mObjectFieldDef(OWNER_COLUMN_ID);
	ownerField.displayName = "Owned by";
	ownerField.pluralName = "Owned by";
	ownerField.icon = ICONS.user;
	ownerField.objectDefId = mUserDef.ID;
	return ownerField.unmarshall() as Field;
}

export function getFieldOption(field: Field): SelectOption {
	return {
		id: field._name,
		name: field._displayName,
		icon: field._icon,
	};
}

export function generateNewFilter(): Filter {
	return { filterId: "t_contains", filterTarget: NAME_COLUMN_ID, value: undefined };
}
