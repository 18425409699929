import { PATHS } from "../../../../routes";
import { SettingsContainer } from "../../SettingsContainer";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { NotificationForm } from "./NotificationForm";
import { useGetMyPreferencesQuery } from "../../../users/api/userPreferencesApi";
import { NotificationFormSkeleton } from "./NotificationFormSkeleton";

export function NotificationsPage() {
	const { data: userPreferences } = useGetMyPreferencesQuery();

	return (
		<SettingsContainer
			breadcrumbs={[
				{
					node: "Settings",
					icon: ICONS.settings,
				},
				{
					node: "Notifications",
					link: PATHS.SETTINGS_NOTIFICATIONS(),
				},
			]}
			formPage
		>
			{userPreferences ? <NotificationForm userPreferences={userPreferences} /> : <NotificationFormSkeleton />}
		</SettingsContainer>
	);
}
