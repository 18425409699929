import { useState, useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { rsr } from "@salesdesk/salesdesk-schemas";

import { useSearchRecordsQuery } from "../../records";
import { Note } from "../types";

export function useNotesFilterAndSearch(sdRecordId: number, notes: Note[]) {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [userFilterValues, setUserFilterValues] = useState<number[]>([]);

	const searchParams = useMemo(() => {
		if (!searchQuery) {
			return undefined;
		}

		return {
			size: 1,
			query: rsr
				.query()
				.and(rsr.equals("_deleted", false))
				.and(rsr.matchAllPrefix(searchQuery))
				.and(rsr.equals("id", sdRecordId))
				.buildQuery(),
			highlight: searchQuery,
		};
	}, [sdRecordId, searchQuery]);

	const { currentData: searchHits, isFetching } = useSearchRecordsQuery(searchParams || skipToken, {
		refetchOnMountOrArgChange: true,
	});

	const hasSearchQuery = Boolean(searchQuery.length);

	const noteIdsToShow = useMemo(
		() => (searchHits?.innerHits?.notes ?? []).map(({ id }) => id),
		[searchHits?.innerHits?.notes]
	);

	const filteredNotes = useMemo(() => {
		return notes.filter(
			(note) =>
				(!hasSearchQuery || noteIdsToShow.includes(note.id)) &&
				(!userFilterValues.length || (note.authorId && userFilterValues.includes(note.authorId)))
		);
	}, [notes, noteIdsToShow, userFilterValues, hasSearchQuery]);

	return {
		filteredNotes,
		searchQuery,
		setSearchQuery,
		userFilterValues,
		setUserFilterValues,
		isNotesFilterLoading: isFetching && hasSearchQuery,
		isFiltered: Boolean(searchQuery.length || userFilterValues.length),
	};
}
