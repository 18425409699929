import { useEffect } from "react";
import { useWorkspaceContext } from "./useWorkspaceContext";

export function useWorkspaceNavCollapse(showingSidePanel: boolean) {
	const { toggleNavCollapse } = useWorkspaceContext();

	useEffect(() => {
		toggleNavCollapse?.(showingSidePanel);

		// Fixes issue where floating-ui components focus and scroll when the record side panel unmounts
		// due to some weird emergent behaviour between the headless ui panel tabs and floating-ui.
		//
		// By unfocusing from the active headless ui panel after a record is clicked we fix the issue.
		if (showingSidePanel) {
			(document.activeElement as HTMLElement)?.blur();
		}
	}, [toggleNavCollapse, showingSidePanel]);
}
