import { Bookmark, BOOKMARK_ID_PARAM, RECORD_SIDE_PANEL_RECORD_ID_PARAM, SDObject } from "@salesdesk/salesdesk-schemas";
import { PATHS } from "../../../routes";
import { ERRORS } from "@salesdesk/salesdesk-ui";

export const UNSELECT_BOOKMARK_ID = -1;

export function getBookmarkParamsFromLocation() {
	const params = new URLSearchParams(window.location.search);
	params.delete(BOOKMARK_ID_PARAM);
	params.delete(RECORD_SIDE_PANEL_RECORD_ID_PARAM);
	return params.toString();
}

export function getBookmarkIdFromLocation(): number | undefined {
	const params = new URLSearchParams(window.location.search);
	const strBookmarkId = params.get(BOOKMARK_ID_PARAM);
	return strBookmarkId ? Number(strBookmarkId) : undefined;
}

export function getBookmarkParams(bookmark: Bookmark, withBookmarkId = true): string {
	const search = bookmark.value;
	const params = new URLSearchParams(search);
	if (withBookmarkId) {
		params.set(BOOKMARK_ID_PARAM, bookmark.id.toString());
		params.delete(RECORD_SIDE_PANEL_RECORD_ID_PARAM);
	}
	return params.toString();
}

export function getBookmarkRedirectUrl(
	sdObjects: SDObject[] | undefined,
	bookmark: Bookmark,
	withBookmarkId = true
): string {
	const sdObject = sdObjects?.find((sdObject) => sdObject._id === bookmark.objectId);
	if (!sdObject) {
		console.error("No object found for bookmark", bookmark);
		return `/error/${ERRORS.RESOURCE_NOT_FOUND.code.toLowerCase()}`;
	}
	return PATHS.OBJECT_RECORD_BOARD(sdObject) + "?" + getBookmarkParams(bookmark, withBookmarkId);
}

export function getSDObjectPathSlugFromUrl(url: string): string {
	const match = url.match(/^\/app\/[^/]*\/objects\/([^/]+)/);
	return match ? match[1] : "";
}

export function getObjectNameFromUrl(url: string): string {
	return decodeURIComponent(getSDObjectPathSlugFromUrl(url)).toLowerCase();
}

export function hasViewChanged(bookmark: Bookmark): boolean {
	const currentUrl = getBookmarkParamsFromLocation();
	const bookmarkValue = getBookmarkParams(bookmark, false);
	return currentUrl !== bookmarkValue;
}
