import { getCurrentUser } from "@salesdesk/salesdesk-utils";

export class mFieldDataChangeEvent {
	type: any;
	message: any;
	source: any;
	user: any;

	constructor(type: any, message: any, source: any) {
		this.type = type;
		this.message = message;
		this.source = source;
		this.user = getCurrentUser();
	}

	static type = {
		ALL: "all",
		FIELD_OPTION_VALUES_UPDATED: "field_option_values_updated",
		FIELD_COMPONENT_TYPE_UPDATED: "field_component_type_updated",
		FIELD_INSTANCE_ADDED: "field_instance_added",
		FIELD_INSTANCE_REMOVED: "field_instance_removed",
		FIELD_INSTANCE_UPDATED: "field_instance_updated",
		FIELD_INSTANCE_VALIDATION: "field_instance_validation",
	};
}
