import { Events } from "../../../../../../../events";
import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";
import { PanelTabPanel } from "../../../../../../../../components/PanelTabs";

interface ActivityPanelProps {
	title?: string;
	onUpdateCount?: (count: number) => void;
	showUsersActivity?: boolean;
}

export function ActivityPanel({ title = "History", onUpdateCount, showUsersActivity = false }: ActivityPanelProps) {
	const { sdRecord } = useRecordDetailsContext();

	return (
		<PanelTabPanel className="relative flex w-full flex-col pr-8" unmount={false}>
			<div className="text-label py-4">{title}</div>
			<div className="h-full overflow-hidden">
				{sdRecord ? (
					<Events sdRecord={sdRecord} onUpdateCount={onUpdateCount} showUsersActivity={showUsersActivity} />
				) : null}
			</div>
		</PanelTabPanel>
	);
}
