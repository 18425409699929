import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetRecordQuery } from "../features/records/api/recordsApi";
import { useGetObjectById } from "./useGetObjectById";

export function useGetRecordWithObjectByRecordId(
	recordId?: number,
	pollingInterval?: number,
	refetchOnMount?: boolean
) {
	const {
		currentData: sdRecord,
		isLoading: isRecordLoading,
		isFetching: isRecordFetching,
	} = useGetRecordQuery(recordId ?? skipToken, {
		pollingInterval: pollingInterval,
		refetchOnMountOrArgChange: refetchOnMount,
	});
	const { sdObject, isLoading: isSDObjectLoading } = useGetObjectById(sdRecord?._objectDefId);

	return {
		isLoading: isRecordLoading || (isRecordFetching && !sdRecord) || isSDObjectLoading,
		sdObject,
		sdRecord,
	};
}
