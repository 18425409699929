import { createContext, PropsWithChildren, useContext, useState } from "react";

import { RecordPreviewContainer } from "./RecordPreviewContainer";
import { HoverPopover, PopoverContent, PopoverTrigger } from "../../../../components/Popover";

const RecordPreviewContext = createContext<{ isPreview: boolean }>({ isPreview: false });

interface RecordPreviewPopoverProps {
	recordId?: number;
	isWithinDropdown?: boolean;
}

export function RecordPreviewPopover({
	recordId,
	isWithinDropdown = false,
	children,
}: PropsWithChildren<RecordPreviewPopoverProps>) {
	const { isPreview } = useContext(RecordPreviewContext);
	const [popoverKey, setPopoverKey] = useState(0);

	if (isPreview || !recordId) {
		return children;
	}

	const closePopover = () => {
		setPopoverKey((prevKey) => prevKey + 1); // Update key to force re-render and close popover
	};

	return (
		<RecordPreviewContext.Provider value={{ isPreview: true }}>
			<HoverPopover
				floatingStrategy="absolute"
				placement={isWithinDropdown ? "left" : "bottom"}
				hoverDelay={800}
				key={popoverKey} // React render key to ensure re-render when key changes
			>
				<PopoverTrigger>{children}</PopoverTrigger>
				<PopoverContent initialFocus={-1}>
					<div className="bg-c_bg_01 border-c_border_regular shadow-popover text-c_text_primary text-label-nav flex max-h-[600px] min-w-[144px] flex-col overflow-auto rounded-md border">
						<RecordPreviewContainer recordId={recordId} onClose={closePopover} />
					</div>
				</PopoverContent>
			</HoverPopover>
		</RecordPreviewContext.Provider>
	);
}
