import { distance2D } from "framer-motion";

const CORNER_SNAPPING_DISTANCE = 600;

// Gets the closest snapping target to snap the window to one of the corners of the
// container. If `snapToTop` is true it will also snap the window to the top of the container if it becomes
// out of bounds (e.g. if participants join or start sharing screen when the window is at the top of the container)
export function getClosestSnappingTarget(
	containerEl: HTMLDivElement,
	windowEl: HTMLDivElement,
	snapToTop = false,
	cornerSnappingDistance = CORNER_SNAPPING_DISTANCE
) {
	const containerRect = containerEl.getBoundingClientRect();
	const windowRect = windowEl.getBoundingClientRect();

	const windowCenter = {
		x: windowRect.left + windowRect.width / 2,
		y: windowRect.top + windowRect.height / 2,
	};

	const topY = -containerRect.height + windowRect.height;
	const rightX = containerRect.width - windowRect.width;

	/*
	 * The four corner points of the container element and the target coordinates to move
	 * the window to so that it is in that corner of the container.
	 *
	 * Note that the window is positioned with an origin point at the bottom left of the
	 * container (so bottom left is 0,0)
	 */
	const containerCorners = [
		{ point: { x: containerRect.right, y: containerRect.bottom }, target: { x: rightX, y: 0 } }, // bottom-right
		{ point: { x: containerRect.left, y: containerRect.bottom }, target: { x: 0, y: 0 } }, // bottom-left
		{ point: { x: containerRect.right, y: containerRect.top }, target: { x: rightX, y: topY } }, // top-right
		{ point: { x: containerRect.left, y: containerRect.top }, target: { x: 0, y: topY } }, // top-left
	];

	for (const corner of containerCorners) {
		const { point, target } = corner;
		const distance = distance2D(windowCenter, point);

		if (distance > cornerSnappingDistance) {
			continue;
		}

		return target;
	}

	// Check if the windowRect is above the containerRect and snap it to the top, keeping the current x position
	if (snapToTop && windowRect.top < containerRect.top) {
		return { x: rightX + windowRect.right, y: topY };
	}

	return null;
}
