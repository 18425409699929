import { DAY_IN_HOURS, HOUR_IN_MINUTES } from "@salesdesk/salesdesk-utils";
import { differenceInCalendarDays, getHours, getMinutes, isSameDay } from "date-fns";
import { DATE_COLUMN_TOP_PADDING } from ".";
import { CalendarCardDetails, CalendarEntry, DateTimeCardGroup, DateTimeCardPosition } from "../types";
import { BoardRecordDetails, FULL_RECORD_DATA_FIELD_ID } from "../../../../types";

// Generates an array of n items from 0 to (n - 1)
export const TIMELINE_HOURS = Array.from(Array(DAY_IN_HOURS + 1).keys());
// Hour cannot be smaller than 24px due to text height
export const MIN_HOUR_SIZE = 24;

export const HOUR_BOARD_SIZE = Math.max(44, MIN_HOUR_SIZE);

const DATE_TIME_CARD_MIN_HEIGHT = 20;
const DATE_TIME_CARD_X_MARGIN = 4;
const DATE_TIME_CARD_GAP = 2;

const DATE_TIME_CARD_OVERLAP_OFFSET = 4;

/**
 * Calculates the absolute y position of a specific time along a calendar date column
 */
export function getYPositionForTimeInDateColumn(dateTime: Date) {
	const currentHour = getHours(dateTime) + getMinutes(dateTime) / HOUR_IN_MINUTES;
	return HOUR_BOARD_SIZE * currentHour + (DATE_COLUMN_TOP_PADDING - 1);
}

/**
 * Calculates the time along a calendar date column based on the absolute y position
 */
export function getTimeForYPositionInDateColumn(yPosition: number) {
	const hoursFromTop = (yPosition - (DATE_COLUMN_TOP_PADDING - 1)) / HOUR_BOARD_SIZE;

	const hours = Math.floor(hoursFromTop);
	const minutes = Math.floor((hoursFromTop - hours) * HOUR_IN_MINUTES);

	return { hours, minutes };
}

/**
 * Note that this function requires the calendar entries to have been sorted by start time.
 */
export function generateDateTimeCalendarCards(calendarEntries: CalendarEntry[], dateRange: Date[]) {
	const dateTimeGroupsPerDay: DateTimeCardGroup[][] = Array.from(new Array(dateRange.length), () => []);

	calendarEntries.forEach((entry) => {
		const { id, name, start, end, recordDetails, getRecordLink } = entry;

		const startDateIndex = dateRange.findIndex((date) => isSameDay(date, start));

		if (startDateIndex === -1) {
			return;
		}

		// TODO: Implement handling for multi-day entries in a future item
		if (end && differenceInCalendarDays(end, start)) {
			console.error("MULTI-DAY ENTRIES NOT CURRENTLY HANDLED BY CALENDAR");
		}

		const entryStartPosition = getYPositionForTimeInDateColumn(start);

		// If no end we default to an hour long
		const entryEndPosition = end
			? Math.max(
					getYPositionForTimeInDateColumn(end),
					entryStartPosition + DATE_TIME_CARD_MIN_HEIGHT + DATE_TIME_CARD_GAP
			  )
			: entryStartPosition + HOUR_BOARD_SIZE;

		const entryCard: CalendarCardDetails = {
			id,
			name,
			getRecordLink,
			start,
			top: entryStartPosition,
			height: entryEndPosition - entryStartPosition - DATE_TIME_CARD_GAP,
			sdRecord: recordDetails?.[FULL_RECORD_DATA_FIELD_ID],
		};

		const dateTimeGroupsForDate = dateTimeGroupsPerDay[startDateIndex];

		let currentDateTimeGroup: DateTimeCardGroup | undefined;

		// Checks if the entry fits within the most recent date time group (no need to check all groups since we know
		// that the entries are sorted by start time)
		if (dateTimeGroupsForDate.length) {
			const previousGroup = dateTimeGroupsForDate[dateTimeGroupsForDate.length - 1];

			currentDateTimeGroup =
				entryStartPosition >= previousGroup.startPosition && entryStartPosition < previousGroup.endPosition
					? previousGroup
					: undefined;
		}

		// If no group exists, a new one is created
		if (!currentDateTimeGroup) {
			currentDateTimeGroup = {
				startPosition: entryStartPosition,
				endPosition: entryEndPosition,
				rows: [[entryCard]],
			};

			dateTimeGroupsForDate.push(currentDateTimeGroup);
			return;
		}

		// Updates the endPosition of the current date time group if the new entry has a later end time
		currentDateTimeGroup.endPosition = Math.max(currentDateTimeGroup.endPosition, entryEndPosition);

		const bestCardPositionInGroup = findBestCardPositionInDateTimeGroup(currentDateTimeGroup, entryCard);

		const { overlapLevel, row: rowIndex, index } = bestCardPositionInGroup;

		const totalRows = currentDateTimeGroup.rows.length;

		// If the best position row index is larger than the number of current rows, a new row must be added to the group
		if (rowIndex >= totalRows) {
			// In a time card group, each row must have at least the same number of cards/columns as the row above in order to
			// handle overlapping/free spaces correctly.
			currentDateTimeGroup.rows.push(new Array(currentDateTimeGroup.rows[rowIndex - 1].length).fill(undefined));
		}

		const rowToInsertCard = currentDateTimeGroup.rows[rowIndex];

		// If adding the card to the end of a row, we then have to ensure that all of the rows after
		// this row are the same length to properly handle/render the different columns of cards
		if (index >= rowToInsertCard.length) {
			rowToInsertCard.push(undefined);

			const totalRows = currentDateTimeGroup.rows.length;
			for (let i = rowIndex + 1; i < totalRows; i++) {
				while (currentDateTimeGroup.rows[i].length < rowToInsertCard.length) {
					currentDateTimeGroup.rows[i].push(undefined);
				}
			}
		}

		// Ensures all of the cards on a row are at least on the same overlap level to prevent strange offsetting issues
		if (index > 0) {
			entryCard.overlapLevel = Math.max(rowToInsertCard[index - 1]?.overlapLevel || 0, overlapLevel);
		} else {
			entryCard.overlapLevel = overlapLevel;
		}

		rowToInsertCard[index] = entryCard;
	});

	return convertDateTimeCardGroupsForCalendar(dateTimeGroupsPerDay);
}

/**
 * Finds the best position for a given calendar card within a given date time group.
 * The function does assume that the entries are being entered in chronological order (i.e. descending start date)
 * and that this new calendar cards has an equal or later start date than the last entries added.
 *
 * Returns an object containing the row number and the index on that row for where to insert the card.
 * Can return a row number/index greater than the current number of rows/columns in a row, indicating that a new
 * row or column should be created.
 *
 * Note: In a time card group, each row must have at least the same number of cards/columns as the row above
 * in order to correctly handle spaces taken up by cards in above rows.
 */
function findBestCardPositionInDateTimeGroup(dateTimeGroup: DateTimeCardGroup, newCard: CalendarCardDetails) {
	const currentRowIndex = dateTimeGroup.rows.length - 1;
	const currentRow = dateTimeGroup.rows[currentRowIndex];
	const previousRow = currentRowIndex > 0 ? dateTimeGroup.rows[currentRowIndex - 1] : null;

	let bestPosition: DateTimeCardPosition | null = null;

	// Used to determine the average start position of the current row to avoid the entry being
	// added to a new row when it fits within the same hour as the current row.
	let cardsSeen = 0;
	let totalStart = 0;

	for (let i = 0; i < currentRow.length; i++) {
		const rowCard = currentRow[i];

		if (rowCard) {
			cardsSeen++;
			totalStart += rowCard.top || 0;
		}

		let overlapLevel = 0;
		let insertionRow = currentRowIndex;

		const averageRowStartPosition = cardsSeen ? totalStart / cardsSeen : 0;
		const withinHourOfAverageRowStart =
			averageRowStartPosition === 0 ? true : (newCard?.top || 0) <= averageRowStartPosition + HOUR_BOARD_SIZE;

		// If there's a free space in the row and the card is within the start time range of the other cards on this
		// row we check if we can fit the card in this free space underneath the entry in the row above.
		if (!rowCard && previousRow && withinHourOfAverageRowStart) {
			overlapLevel = getOverlapBetweenCards(getClosestCardInRow(previousRow, i), newCard);
		}
		// Otherwise we check if we can fit underneath this current row card
		else {
			overlapLevel = getOverlapBetweenCards(rowCard, newCard);
			insertionRow++;
		}

		if (overlapLevel === -1) {
			continue;
		}

		bestPosition = updateCardBestPosition(bestPosition, { overlapLevel, row: insertionRow, index: i });

		if (bestPosition?.overlapLevel === 0) {
			return bestPosition;
		}
	}

	// If no best position found yet, this means that we need to add the card to the end of a row
	if (bestPosition !== null) {
		return bestPosition;
	}

	const currentRowEndIndex = currentRow.length;

	// Checks if the card can fit at the end of the current row without colliding with the entry in
	// the row above.
	const currentRowEndOverlapLevel = previousRow
		? getOverlapBetweenCards(getClosestCardInRow(previousRow, currentRowEndIndex), newCard)
		: 0;

	if (currentRowEndOverlapLevel !== -1) {
		return { overlapLevel: currentRowEndOverlapLevel, row: currentRowIndex, index: currentRowEndIndex };
	}

	// If no best position on this row, we add it to the end of the previous row as a last ditch effort.
	// This should be very rare.
	return { overlapLevel: 0, row: currentRowIndex - 1, index: currentRow.length };
}

/**
 * Returns the calendar card in a given row at a given index.
 * If the index is out of bounds, it just returns the last card in that row as this function
 * is used to check whether a card in a row below collides with a card in the row above and these
 * can sometimes have different numbers of cards/columns
 * (e.g. first row has a single card, second row has 3 cards)
 */
function getClosestCardInRow(row: CalendarCardDetails[], index: number) {
	if (index < row.length) {
		return row[index];
	}

	return row[row.length - 1];
}

// Prioritises the position with the least amount of overlapping
function updateCardBestPosition(currentPosition: DateTimeCardPosition | null, newPosition: DateTimeCardPosition) {
	if (!currentPosition || currentPosition.overlapLevel === -1) {
		return newPosition;
	}

	return currentPosition.overlapLevel <= newPosition.overlapLevel ? currentPosition : newPosition;
}

/**
 * Assumes that the newCard has a start time equal to or later than the card already in the calendar
 * as they are inserted in chronological order.
 *
 * Returns 0 if there is no overlap at all between the two cards.
 *
 * Returns -1 if the two cards collide and cannot fit above/below each other or overlap with
 * each other (as we have to account for the text of the cards to not be covered by each other).
 *
 * Returns the overlap level of the card in the calendar incremented by 1 if the new card can overlap
 * the card in the calendar. This overlap level keeps track of how many overlaps there are in a series of
 * rows as you can have multiple chained overlaps.
 */
function getOverlapBetweenCards(cardInCalendar: CalendarCardDetails, newCard: CalendarCardDetails) {
	if (!cardInCalendar || !newCard) {
		return 0;
	}

	const cardInCalendarTop = cardInCalendar?.top || 0;
	const cardInCalendarBottom = cardInCalendarTop + (cardInCalendar?.height || 0);

	const newCardTop = newCard?.top || 0;

	if (newCardTop > cardInCalendarBottom) {
		return 0;
	} else if (newCardTop - cardInCalendarTop >= HOUR_BOARD_SIZE) {
		return (cardInCalendar.overlapLevel || 0) + 1;
	}

	return -1;
}

/*
	Converts from date time card groups to arrays of calendar cards per day which can be rendered
	directly onto the calendar board based on the position of the cards in the rows within the
	date time card groups.
*/
function convertDateTimeCardGroupsForCalendar(dateTimeGroupsPerDay: DateTimeCardGroup[][]) {
	return dateTimeGroupsPerDay.map((dateTimeCardGroups) => {
		const cardsPerDay: CalendarCardDetails[] = [];

		dateTimeCardGroups.forEach((group) => {
			// All the rows in a group have an incrementing z-index so that newer cards always render
			// above older cards when overlapping
			let initialZIndex = 0;

			group.rows.forEach((row) => {
				const cardsInRow = row.length;
				const cardWidth = 100 / cardsInRow;

				// Have a single overlap level for the whole row
				let overlapLevelForRow = 0;

				row.forEach((card, index) => {
					if (!card) {
						return;
					}

					if (overlapLevelForRow) {
						card.overlapLevel = overlapLevelForRow;
					} else {
						overlapLevelForRow = card.overlapLevel || 0;
					}

					card.zIndex = initialZIndex++;

					// Increases the overlap offset based on the overlap level to allow for nested overlapping
					const overlapOffset = DATE_TIME_CARD_OVERLAP_OFFSET * (card.overlapLevel || 0);

					let widthOffset = 0;
					let left = `${DATE_TIME_CARD_X_MARGIN + overlapOffset}px`;

					// Single card fills up whole width accounting for margin between both day column edges
					if (cardsInRow === 1) {
						widthOffset += DATE_TIME_CARD_X_MARGIN * 2;
					}
					// Card on left edge accounting for margin on day column left
					// and gap between itself and the next card in the row
					else if (index === 0) {
						widthOffset += DATE_TIME_CARD_X_MARGIN + DATE_TIME_CARD_GAP + overlapOffset;
					}
					// Cards between other cards or on the right edge
					else {
						widthOffset += index < cardsInRow - 1 ? DATE_TIME_CARD_GAP : DATE_TIME_CARD_X_MARGIN;
						left = `calc(${cardWidth * index}%)`;
					}

					card.width = `calc(${cardWidth}% - ${widthOffset}px)`;
					card.left = left;

					cardsPerDay.push(card);
				});
			});
		});

		return cardsPerDay;
	});
}
