import { PropsWithChildren } from "react";
import { clsx } from "clsx";

import { SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectsQuery } from "../../../objects/api/objectsApi";
import { CustomObjectNavPopover } from "./CustomObjectNavPopover";

import { useNavbarOptions } from "../hooks/useNavbarOptions";
import { BookmarkNavPopover } from "../../../bookmarks";
import { FavoriteNavPopover } from "../../../records";
import { NavbarLogo, NavLink } from "../../../../components/Navbar";
import { Tooltip, TooltipDelayGroup, Button, useHasMounted } from "@salesdesk/daisy-ui";

interface NavbarProps {
	isOpen?: boolean;
	onClose: () => void;
}

// Fixed reference to an empty array, otherwise each render will create a new
// empty array while the query is loading, causing the useEffect to run more
// times than necessary.
const EMPTY_ARRAY: SDObject[] = [];

export function Navbar({ isOpen = true, onClose }: NavbarProps) {
	const { data: objectDefs = EMPTY_ARRAY, isLoading } = useGetObjectsQuery();
	const { systemNavOptions, customObjects } = useNavbarOptions(objectDefs);

	const hasMounted = useHasMounted();

	return (
		<div
			className={clsx(
				"bg-c_bg_02 flex h-full shrink-0 flex-col overflow-hidden",
				hasMounted && "transition-all",
				isOpen ? "w-56 px-4" : "w-21 pl-4"
			)}
		>
			<div className="mb-4 flex h-16 w-full shrink-0 items-center pl-4">
				<div className="z-10">
					<NavbarLogo isOpen={isOpen} />
				</div>
				<div
					className={clsx(
						"ml-auto transition-opacity",
						isOpen ? "opacity-100 delay-75 ease-out" : "opacity-0 duration-75"
					)}
				>
					{isOpen ? (
						<TooltipDelayGroup>
							<Tooltip text="Collapse navbar" placement="right">
								<Button startIcon="ph-arrow-line-left" variant="secondary" onClick={onClose} />
							</Tooltip>
						</TooltipDelayGroup>
					) : null}
				</div>
			</div>
			<div className="mb-12 shrink overflow-y-auto pt-8">
				<TooltipDelayGroup>
					{systemNavOptions.length > 0 ? (
						<>
							<NavbarItemsContainer isLoading={isLoading} isOpen={isOpen}>
								{systemNavOptions.map((navOption) => (
									<NavLink
										elementId={navOption.elementId}
										key={navOption.text}
										expanded={isOpen}
										icon={navOption.icon}
										text={navOption.text}
										link={navOption.link}
										hideSelectDrop={navOption.hideSelectDrop}
									/>
								))}
							</NavbarItemsContainer>
							<div
								className={clsx(
									"my-4 w-full py-1 transition-all",
									isOpen && "px-2",
									isLoading ? "opacity-0" : "opacity-100"
								)}
							>
								<div className="bg-c_bg_01 h-0.5 rounded-sm" />
							</div>
						</>
					) : null}
					<NavbarItemsContainer isLoading={isLoading} isOpen={isOpen}>
						{customObjects.length > 0 ? (
							<CustomObjectNavPopover
								menuItems={customObjects.map((navOption) => ({ ...navOption, type: "link" }))}
								expanded={isOpen}
							/>
						) : null}
						<BookmarkNavPopover variant="leftNav" expanded={isOpen} />
						<FavoriteNavPopover expanded={isOpen} />
					</NavbarItemsContainer>
				</TooltipDelayGroup>
			</div>
		</div>
	);
}

function NavbarItemsContainer({
	isOpen,
	isLoading,
	children,
}: PropsWithChildren<{ isOpen: boolean; isLoading: boolean }>) {
	return (
		<div
			className={clsx(
				"flex w-full flex-col gap-2 transition-all",
				!isOpen && "items-start pl-2",
				isLoading ? "opacity-0" : "opacity-100"
			)}
		>
			{children}
		</div>
	);
}
