import { useEffect, useMemo } from "react";

import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { unserializeChannelMessage } from "../../utils";
import { useChimeMessagingContext } from "../../components";
import { loadInitialMessages } from "../thunks";

interface UseChannelMessagesSelectorProps {
	channelArn?: string;
	maxMessages?: number;
	isHighPriority?: boolean;
}

export function useChannelMessagesSelector({
	channelArn,
	maxMessages,
	isHighPriority,
}: UseChannelMessagesSelectorProps): ChannelMessage[] | undefined {
	const { chime } = useChimeMessagingContext();
	const dispatch = useAppDispatch();

	const messages = useAppSelector((state) => {
		if (!channelArn) {
			return undefined;
		}

		return state.messages[channelArn]?.messages;
	});

	const hasMessages = Boolean(messages);
	// Load initial messages for the channel if they haven't been loaded yet
	useEffect(() => {
		if (!chime || !channelArn || hasMessages) {
			return;
		}

		dispatch(loadInitialMessages({ channelArn, chime, isHighPriority }));
	}, [channelArn, chime, dispatch, hasMessages, isHighPriority]);

	return useMemo(() => {
		if (!messages) return undefined;
		const limitedMessages = maxMessages ? messages.slice(0, maxMessages) : messages;
		return limitedMessages.map((message) => unserializeChannelMessage(message));
	}, [messages, maxMessages]);
}
