import { useContext } from "react";
import { MenuContext } from "./MenuContext";
import { useFloatingTree, useListItem } from "@floating-ui/react";

interface useMenuItemPropsProperties {
	className?: string;
	isActive?: boolean;
	label?: string;
	includeTabIndex?: boolean;
	disabled?: boolean;
	itemOnClick?: () => void;
	preventClickEvent?: boolean;
}

export function useMenuItemProps({
	className,
	isActive,
	label,
	includeTabIndex = true,
	disabled = false,
	itemOnClick,
	preventClickEvent = false,
}: useMenuItemPropsProperties) {
	const menu = useContext(MenuContext);

	const listItem = useListItem({ label: disabled ? null : label });
	const tree = useFloatingTree();

	const props: Record<string, unknown> = {
		ref: listItem.ref,
		role: "menuitem",
		disabled,
		...menu.getItemProps({
			onClick(e) {
				e.stopPropagation();

				if (disabled || preventClickEvent) {
					return;
				}

				tree?.events.emit("click");

				if (itemOnClick) {
					itemOnClick();
				}
			},
			onFocus() {
				menu.setHasFocusInside(true);
			},
		}),
	};

	if (className) {
		props.className = className;
	}

	if (includeTabIndex) {
		props.tabIndex = isActive ? 0 : -1;
	}

	return props;
}
