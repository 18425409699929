import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";
import { AssociationSide } from "@salesdesk/salesdesk-model";

export const existsAssociationForObjectAssociationClausePropertySchema = asConst({
	title: "Exists Association For Object Association Clause Property",
	description: "Will match any record which has any associations of the given type",
	type: "object",
	additionalProperties: false,
	required: ["objectAssociationId", "associationSide"],
	properties: {
		objectAssociationId: idSchema,
		associationSide: {
			title: "Association side",
			description: "Search will return records on this side of the association",
			type: "string",
			enum: Object.values(AssociationSide),
		},
	},
});
