import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../../functions";
import { stringSchema } from "../../../components";

export const fieldNameSchema = asConst({
	...stringSchema,
	title: "SDObject Field Name",
	description: appendNotEditable("The name of the Field"),
	pattern: "^[^|]+$",
});
