import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { Tooltip } from "@salesdesk/daisy-ui";
import { EmojiPopover } from "../../EmojiPicker";

interface AddReactionButtonProps {
	onReactionSelect?: (emoji: string) => void;
	canReact: boolean;
}

export function AddReactionButton({ onReactionSelect, canReact }: AddReactionButtonProps) {
	return (
		<EmojiPopover
			onEmojiSelect={({ native }) => {
				onReactionSelect?.(native);
			}}
		>
			<Tooltip text="Add reaction" placement="top">
				<button
					className={clsx(
						"bg-c_bg_03 border-c_action_01 flex h-6 w-9 items-center justify-center rounded-full border border-transparent leading-6",
						"focus-visible:ring-c_action_focus focus-visible:bg-c_bg_03 focus-visible:ring",
						canReact ? "hover:bg-c_bg_02" : "cursor-not-allowed"
					)}
					disabled={!canReact}
				>
					<Icon icon={ICONS.smiley} size="sm" className="text-c_text_secondary flex items-center" />
				</button>
			</Tooltip>
		</EmojiPopover>
	);
}
