import { useCallback, useState } from "react";
import { JSONContent } from "@tiptap/core";
import { Comment, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useToast } from "../../../../../../Toasts";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { useUpdateCommentMutation } from "../../../api/documentCommentsApi";

export function useEditComment(documentRecordId?: SDRecord["_id"]) {
	const workspaceId = useGetContextWorkspaceId();
	const toast = useToast();

	const [editingCommentId, setEditingCommentId] = useState<string | undefined>();
	const [updateComment] = useUpdateCommentMutation();

	const onEditComment = useCallback((comment: Comment) => {
		setEditingCommentId((prevCommentId) => (prevCommentId === comment.id ? undefined : comment.id));
	}, []);

	const onEditSubmit = useCallback(
		(editedComment: JSONContent) => {
			if (!editingCommentId || !documentRecordId) return;

			updateComment({
				workspaceId: workspaceId,
				recordId: documentRecordId,
				commentId: editingCommentId,
				comment: { message: JSON.stringify(editedComment) },
			})
				.unwrap()
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "comment_updated" });
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "comment_updated" });
				});

			setEditingCommentId(undefined);
		},
		[editingCommentId, documentRecordId, updateComment, toast, workspaceId]
	);

	return {
		onEditComment,
		onEditSubmit,
		editingCommentId,
	};
}
