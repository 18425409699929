import { useEffect, useState } from "react";
import { areEqualSets } from "@salesdesk/salesdesk-utils";
import { TranscriptItemProps } from "../components/TranscriptItem";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { ActiveItems } from "../../../types";

export function useActiveItems(filteredTranscriptItems: TranscriptItemProps[]): ActiveItems {
	const { videoRef } = useMediaAnalysisContext();
	const [activeItems, setActiveItems] = useState<Record<string, boolean>>({});

	useEffect(() => {
		if (!videoRef.current) {
			return;
		}
		const videoElement = videoRef.current;
		const onTimeUpdate = () => {
			const currentTimeMillis = videoElement.currentTime * 1000;
			const activeItems = {} as Record<string, boolean>;
			for (const transcriptItemProps of filteredTranscriptItems) {
				const { transcriptItem } = transcriptItemProps;
				if (
					currentTimeMillis >= transcriptItem.startTstampMillis &&
					currentTimeMillis < transcriptItem.endTstampMillis
				) {
					activeItems[transcriptItemProps.key] = true;
				}
			}
			setActiveItems((current) => {
				// Only cause re-render when the active items have changed
				const currentKeys = new Set(Object.keys(current));
				const newKeys = new Set(Object.keys(activeItems));
				if (areEqualSets(currentKeys, newKeys)) {
					return current;
				}
				return activeItems;
			});
		};
		onTimeUpdate();
		videoElement.addEventListener("timeupdate", onTimeUpdate);
		return () => {
			videoElement.removeEventListener("timeupdate", onTimeUpdate);
		};
	}, [filteredTranscriptItems, videoRef]);

	return activeItems;
}
