import { ComponentPropsWithoutRef } from "react";

import { useRecordDetailsContext } from "../../hooks/useRecordDetailsContext";
import { useRecordActions } from "../../../RecordActions";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

export function RecordActionButton() {
	const { sdRecord, sdObject } = useRecordDetailsContext();

	const recordAction = useRecordActions(sdRecord, sdObject, true)[0];

	// Doesn't support submenus and subpopovers (yet)
	if (!recordAction || (recordAction.type !== "link" && recordAction.type !== "button")) return null;

	const { text, subText, icon, type, loading } = recordAction;
	let buttonProps: ComponentPropsWithoutRef<typeof Button>;

	if (type === "link") {
		buttonProps = {
			as: "link",
			variant: "primary",
			to: recordAction.link || "",
			disabled: !recordAction.link || loading,
		};
	} else {
		buttonProps = {
			variant: "outlined",
			onClick: recordAction.onClick,
			disabled: !recordAction.onClick || loading,
		};
	}

	return (
		<Tooltip text={subText} placement="top">
			<Button size="sm" startIcon={icon} {...buttonProps} isLoading={loading}>
				{text}
			</Button>
		</Tooltip>
	);
}
