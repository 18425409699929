import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { AbilityAction, AbilitySubject, SDRecord, getSDObjectSystemFieldByName } from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { Button } from "@salesdesk/daisy-ui";

import { useWebPrincipal } from "../../../../../../auth";
import { AlertDialog, FormDialog } from "../../../../../Dialog";
import { Checkbox } from "../../../../../inputs";
import { useRecordCreateForm } from "../../hooks";
import { getCreateRecordTitleText, getCreateRecordVerb } from "../../utils";
import { RecordFormLoading, getRecordFormFieldId } from "../../../RecordForm";
import { RecordCreateForm } from "../RecordCreateForm";
import { RecordCreateDialogProps } from "../../types";

const ALERT_DIALOGS = {
	error: {
		title: "An error occured!",
	},
	unsaved: {
		title: "Unsaved changes!",
		message: "You have unsaved changes, are you sure you want to close this form?",
	},
};

export function RecordCreateDialog({
	sdObject,
	open,
	onOpenChange,
	onRecordCreated,
	canCreateMultiple = true,
	initialValueMap,
	initialAssociationMap,
	hideAssociationSection = false,
	description,
}: RecordCreateDialogProps) {
	const principal = useWebPrincipal();

	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	const [alertDialogTitle, setAlertDialogTitle] = useState("");
	const [alertDialogMessage, setAlertDialogMessage] = useState("");
	const [isUnsavedChangesAlert, setIsUnsavedChangesAlert] = useState(false);

	const [createAnotherRecord, setCreateAnotherRecord] = useState(false);

	const objectName = sdObject._displayName;
	const createVerb = getCreateRecordVerb(sdObject);

	const combinedInitialValueMap = useMemo(() => {
		const defaultValues: Record<string, any> = {};
		if (sdObject?._id === mInteractionMeetingDef.ID) {
			const participantsField = getSDObjectSystemFieldByName(sdObject, mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME);
			if (participantsField) {
				defaultValues[participantsField._id] = [principal.UserRecordId];
			}
		}
		return { ...defaultValues, ...initialValueMap };
	}, [sdObject, initialValueMap, principal.UserRecordId]);

	const associationValueMap = useMemo(() => {
		const defaultValues: Record<string, number[]> = {};

		for (const associationId in initialAssociationMap) {
			defaultValues[getRecordFormFieldId(associationId)] = initialAssociationMap[associationId];
		}

		return defaultValues;
	}, [initialAssociationMap]);

	useEffect(() => {
		if (open) {
			return;
		}

		// Resets the state when the dialog is closed
		setAlertDialogOpen(false);
		setIsUnsavedChangesAlert(false);
		setCreateAnotherRecord(false);
	}, [open]);

	const openAlertDialog = (title: string, message: string, unsavedChangesAlert?: boolean) => {
		setAlertDialogTitle(title);
		setAlertDialogMessage(message);
		setAlertDialogOpen(true);
		setIsUnsavedChangesAlert(unsavedChangesAlert || false);
	};

	const onRecordCreateSuccess = useCallback(
		(newSDRecord: SDRecord) => {
			if (!createAnotherRecord) onOpenChange?.(false);

			if (onRecordCreated) {
				onRecordCreated(newSDRecord);
			}
		},
		[createAnotherRecord, onOpenChange, onRecordCreated]
	);
	const { formProps, formStateRef, isSubmitting, uploadProgressStatus, initialLoading } = useRecordCreateForm({
		sdObject: open ? sdObject : undefined,
		createAnotherRecord,
		onRecordCreateSuccess,
		initialValueMap: { fieldValueMap: combinedInitialValueMap, associationValueMap },
		withAssociations: principal.can(AbilityAction.Create, AbilitySubject.RecordAssociation),
	});

	const { id, fields: formFields } = formProps;

	const closeDialog = () => {
		if (formStateRef.current?.isDirty) {
			openAlertDialog(ALERT_DIALOGS.unsaved.title, ALERT_DIALOGS.unsaved.message, true);
		} else {
			onOpenChange?.(false);
		}
	};

	if (!formFields.length) {
		return null;
	}

	return (
		<>
			<FormDialog
				title={getCreateRecordTitleText(sdObject)}
				description={description ?? `Please fill out the fields below to ${createVerb} a new ${objectName}.`}
				open={open}
				onOpenChange={(open) => {
					if (!open) {
						closeDialog();
					}
				}}
				formId={id}
				isPending={isSubmitting || uploadProgressStatus === "in_progress"}
				hideFooter={initialLoading}
				submitButtonLabel={`${capitalizeString(createVerb)} ${objectName}`}
				footerContent={
					canCreateMultiple ? (
						<label className="flex gap-2">
							<Checkbox value={createAnotherRecord} onChange={setCreateAnotherRecord} />
							{capitalizeString(createVerb)} another {objectName}
						</label>
					) : undefined
				}
				width={768}
			>
				<div className={clsx("relative mb-4 flex-shrink", !initialLoading && "overflow-auto")}>
					{initialLoading ? (
						<RecordFormLoading />
					) : (
						<RecordCreateForm
							formProps={formProps}
							objectName={objectName}
							hideAssociationSection={hideAssociationSection}
						/>
					)}
				</div>
			</FormDialog>
			<AlertDialog
				open={alertDialogOpen}
				onOpenChange={setAlertDialogOpen}
				title={alertDialogTitle}
				message={alertDialogMessage}
			>
				<div className="mt-8 flex justify-end gap-3">
					<Button variant="text" onClick={() => setAlertDialogOpen(false)}>
						{isUnsavedChangesAlert ? "Cancel" : "Dismiss"}
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							onOpenChange?.(false);
							setAlertDialogOpen(false);
						}}
					>
						Discard changes
					</Button>
				</div>
			</AlertDialog>
		</>
	);
}
