import { useMemo } from "react";
import { useGetObjectsQuery } from "../features/objects/api/objectsApi";
import { getSDObjectPathSlug, SDObject } from "@salesdesk/salesdesk-schemas";

export function useGetObjectFromSlug(
	objectPathSlug: string | undefined,
	filter: (object: SDObject) => boolean = () => true
) {
	const { data, isLoading } = useGetObjectsQuery();

	const sdObject = useMemo(
		() => data?.find((object) => getSDObjectPathSlug(object) === objectPathSlug && filter(object)),
		[data, objectPathSlug, filter]
	);

	return { sdObject, isLoading };
}
