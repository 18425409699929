import { ComponentProps, JSXElementConstructor, memo } from "react";

// Async debounce that can support async functions and return their results as a promise.
// Setting leading to true means that the function is called immediately if no debouncing
// is currently happening
export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
	func: F,
	delay: number,
	leading = false
) => {
	let timeout: NodeJS.Timeout | null;
	let leadingInvoked = false;

	return (...args: Parameters<F>) => {
		if (timeout) {
			clearTimeout(timeout);
		}

		return new Promise<ReturnType<F>>((resolve) => {
			const functionCall = async () => resolve(await func(...args));

			if (leading && !timeout) {
				leadingInvoked = true;
				functionCall();
			} else {
				leadingInvoked = false;
			}

			timeout = setTimeout(() => {
				if (!leadingInvoked) {
					functionCall();
				}

				timeout = null;
			}, delay);
		});
	};
};

export const throttle = <F extends (...args: Parameters<F>) => ReturnType<F>>(func: F, delay: number) => {
	let timeout: NodeJS.Timeout | null = null;

	return (...args: Parameters<F>) => {
		if (timeout === null) {
			timeout = setTimeout(() => {
				timeout = null;
				func(...args);
			}, delay);
		}
	};
};

// TODO: This is probably the best way to check the user's OS currently
// but far from ideal. We're in a weird middle ground where `navigator.platform` is
// deprecated: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
// but 'userAgentData' is experimental and not supported in all major browsers:
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
// (Currently not supported in Safari or Firefox)
//
// Browsers may be shifting away from even revealing a user's OS for privacy reasons
// so this is something to watch.
//
// SOURCE: https://stackoverflow.com/a/38241481
const IOS_PLATFORMS = ["iPhone", "iPad", "iPod"];
const MAC_OS_PLATFORMS = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"];
const WINDOWS_PLATFORMS = ["Win32", "Win64", "Windows", "WinCE"];

export const detectUserOS = () => {
	const userAgent = window.navigator.userAgent;
	const platform = (window.navigator as any)?.userAgentData?.platform || window.navigator.platform;

	if (MAC_OS_PLATFORMS.includes(platform)) {
		return "Mac OS";
	} else if (IOS_PLATFORMS.includes(platform)) {
		return "iOS";
	} else if (WINDOWS_PLATFORMS.includes(platform)) {
		return "Windows";
	} else if (/Android/.test(userAgent)) {
		return "Android";
	} else if (/Linux/.test(platform)) {
		return "Linux";
	}

	return null;
};

export const isMacOS = () => detectUserOS() === "Mac OS";

export function ensureValueIsArray<T>(value: T | T[] | undefined | null): T[] {
	if (value == null) {
		return [];
	} else if (!Array.isArray(value)) {
		return [value];
	}

	return value;
}

/*
	Returns a new copy of the array with the element at the given index without
	modifying the original array.
*/
export function removeElementFromIndex<T>(array: T[], elementIndex: number) {
	return array.slice(0, elementIndex).concat(array.slice(elementIndex + 1, array.length));
}

/*
	Returns a new copy of the array with the element insterted at the given index
	without modifying the original array.
*/
export function insertElementAtIndex<T>(array: T[], elementIndex: number, element: T) {
	return [...array.slice(0, elementIndex), element, ...array.slice(elementIndex)];
}

/*
	Returns a new copy of the array with the element at the given index
	replaced with the given new element without modifying the original array.
*/
export function replaceElementAtIndex<T>(array: T[], elementIndex: number, newElement: T) {
	return [...array.slice(0, elementIndex), newElement, ...array.slice(elementIndex + 1)];
}

/*
	Return a new copy of the given array
	with changed position for element at startIndex to position at endIndex
*/
export function moveElementToIndex<T>(list: T[], startIndex: number, endIndex: number) {
	const result = [...list];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

export function countPromiseResults(results: PromiseSettledResult<unknown>[], chunks: unknown[][]) {
	let fails = 0;
	let successes = 0;
	results.forEach((result, index) => {
		if (result.status === "rejected") {
			fails += chunks[index].length;
		} else {
			successes += chunks[index].length;
		}
	});
	return [successes, fails];
}

export function isIntegerString(value: string) {
	return /^\d+$/.test(value);
}

export function getTenantFromUrl(url: string): string | null {
	return /\/app\/([^/]+)/.exec(url)?.[1] ?? null;
}

export function getTenantFromPath(): string | null {
	return getTenantFromUrl(window.location.pathname);
}

export function isJsonContent(content?: string): boolean {
	return typeof content === "string" && content.trim().startsWith("{") && content.trim().endsWith("}");
}

export const genericMemo: <T extends JSXElementConstructor<any>>(
	component: T,
	propsAreEqual?: (prevProps: Readonly<ComponentProps<T>>, nextProps: Readonly<ComponentProps<T>>) => boolean
) => T = memo;
