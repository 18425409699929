import { PropsWithChildren } from "react";

import { tw } from "../../../../utils/tailwind-helpers";
import clsx from "clsx";

import { AvatarSize, AvatarStatus } from "../../types";
import { S3Image } from "../../../../components/S3Image/S3Image";

interface AvatarProps {
	initials?: string;
	avatarFileId?: number;
	size?: AvatarSize;
	isSystemUser?: boolean;
	status?: AvatarStatus;
	withBorder?: boolean;
}

export const Avatar = ({
	initials,
	avatarFileId,
	size = "sm",
	isSystemUser = false,
	status,
	withBorder,
}: AvatarProps) => {
	const baseClass = tw`text-c_text_inverted flex select-none items-center justify-center rounded-full subpixel-antialiased object-cover shrink-0`;

	let avatarSize;
	if (withBorder) {
		if (size === "xs") avatarSize = tw`size-5 text-[9px] font-semibold`;
		if (size === "sm") avatarSize = tw`size-7 text-label-xs`;
		if (size === "md") avatarSize = tw`size-9 text-label-nav`;
		if (size === "lg") avatarSize = tw`size-[56px] text-label-nav`;
		if (size === "xl") avatarSize = tw`size-[121px] text-[45px] font-medium`;
	} else {
		if (size === "xs") avatarSize = tw`size-6 text-[9px] font-semibold`;
		if (size === "sm") avatarSize = tw`size-8 text-label-xs`;
		if (size === "md") avatarSize = tw`size-10 text-label-nav`;
		if (size === "lg") avatarSize = tw`size-[60px] text-label-nav`;
		if (size === "xl") avatarSize = tw`size-[125px] text-[45px] font-medium`;
	}

	if (isSystemUser) {
		return (
			<div className={clsx("bg-c_bg_disabled_02", baseClass, avatarSize)}>
				<img src="/static/images/sd_logo.svg" alt="SalesDesk's logo" className="h-3 w-3" />
			</div>
		);
	}

	if (avatarFileId) {
		return (
			<AvatarWrapper status={status} withBorder={withBorder}>
				<S3Image
					className={clsx("bg-c_bg_disabled_02", baseClass, avatarSize)}
					file={avatarFileId}
					alt="User profile image"
				/>
			</AvatarWrapper>
		);
	}

	return (
		<AvatarWrapper status={status} withBorder={withBorder}>
			<div className={clsx(initials ? "bg-c_action_01" : "bg-c_bg_disabled_02", baseClass, avatarSize)}>
				{initials ?? null}
			</div>
		</AvatarWrapper>
	);
};

interface AvatarWrapperProps extends PropsWithChildren {
	status: AvatarStatus | undefined;
	withBorder?: boolean;
}

function AvatarWrapper({ status, children, withBorder }: AvatarWrapperProps) {
	return (
		<div className={clsx("relative shrink-0", withBorder && "bg-c_bg_01 w-fit rounded-full p-0.5")}>
			{status === "ACTIVE" ? <div className="bg-c_success_02 absolute left-px top-px h-2 w-2 rounded-full" /> : null}
			{children}
		</div>
	);
}
