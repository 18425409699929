import { memo } from "react";
import { Field } from "@salesdesk/salesdesk-schemas";

import {
	DisplayBooleanField,
	DisplayColorPickerField,
	DisplayDateTimeField,
	DisplayDurationField,
	DisplayFileField,
	DisplayIconPickerField,
	DisplayLogoField,
	DisplayOptionField,
	DisplayProfilePhotoField,
	DisplayRatingField,
	DisplayRichTextField,
	DisplayTextField,
	DisplayTimeRangeField,
	FileFieldType,
	getDateTimeFieldVariant,
	getRichTextFieldVariant,
	getTextInputVariant,
	getTimeRangeFieldVariant,
} from "../Fields";

import { isAssetCombinedNameValue, isFileField } from "../../utils/fields";
import { Icon, TextInputVariant } from "@salesdesk/daisy-ui";
import { CURRENCIES_DATA, mCurrencyValueFieldDef } from "@salesdesk/salesdesk-model";
import { DisplayFieldVariant } from "../../types";

interface DisplayFieldFactoryProps {
	field: Field;
	value: any;
	variant?: DisplayFieldVariant;
}

function DisplayFieldFactoryComponent({
	field,
	value,
	variant = DisplayFieldVariant.default,
}: DisplayFieldFactoryProps) {
	const { _componentType: component, _type: type } = field;

	if (component === "text") {
		const textFieldVariant = getTextInputVariant(type);
		let stringValue;

		if (typeof value === "string") {
			stringValue = value;
		} else {
			stringValue = value == null ? "" : String(value);
		}

		let currencySymbol = undefined;
		if (textFieldVariant === TextInputVariant.CURRENCY) {
			currencySymbol = CURRENCIES_DATA.find(
				(currencyOption) => currencyOption.id === (field as mCurrencyValueFieldDef)._currency
			)?.icon;
		}

		return (
			<DisplayTextField
				value={stringValue}
				variant={textFieldVariant}
				interactive={variant !== DisplayFieldVariant.rightHandPanel}
				truncate={variant !== DisplayFieldVariant.cardTitle}
				currencySymbol={currencySymbol}
			/>
		);
	} else if (
		component === "select" ||
		(component === "typeahead_single_option" && type !== "icon") ||
		component === "typeahead_multi_option"
	) {
		return <DisplayOptionField value={value} optionFieldSettings={{ variant: "field", field }} variant={variant} />;
	} else if (component === "typeahead_single_option" && type === "icon") {
		return <DisplayIconPickerField value={value} />;
	} else if (component === "date" || component === "date_time") {
		return <DisplayDateTimeField value={value} variant={getDateTimeFieldVariant(type)} />;
	} else if (component === "time_range") {
		return <DisplayTimeRangeField value={value} variant={getTimeRangeFieldVariant(field._dateOnly)} />;
	} else if (type === "rich_text") {
		return <DisplayRichTextField value={value} variant={getRichTextFieldVariant(variant)} />;
	} else if (component === "rating") {
		return <DisplayRatingField value={value} />;
	} else if (type === "boolean") {
		return <DisplayBooleanField value={value} />;
	} else if (type === "duration") {
		return <DisplayDurationField value={value} />;
	} else if (component === "color") {
		return <DisplayColorPickerField value={value} />;
	} else if (isFileField(field) && (!value || typeof value === "number" || isAssetCombinedNameValue(value))) {
		let placeholderFileFieldType: FileFieldType = "any";

		if (field._type === "image") {
			placeholderFileFieldType = "image";
		} else if (field._type === "video") {
			placeholderFileFieldType = "video";
		}

		if (!value || typeof value === "number") {
			return <DisplayFileField value={value} variant={variant} placeholderFileFieldType={placeholderFileFieldType} />;
		} else {
			return (
				<DisplayFileField
					value={value.fileId}
					fileDisplayName={value.displayName}
					variant={variant}
					placeholderFileFieldType={placeholderFileFieldType}
				/>
			);
		}
	} else if (type === "profile_photo") {
		return <DisplayProfilePhotoField value={value} variant={variant} />;
	} else if (type === "logo") {
		return <DisplayLogoField value={value} variant={variant} />;
	}

	return (
		<div className="text-label-sm text-c_danger_02 max-w-full truncate">
			<div className="flex select-none items-center gap-2">
				<Icon className="flex" icon="ph-warning" />
				<div className="truncate py-0.5">{component}</div>
			</div>
		</div>
	);
}

export const DisplayFieldFactory = memo(DisplayFieldFactoryComponent);
