import { asConst } from "json-schema-to-ts";
import { updatableSchema } from "../components";
import { sdAssociationCreateRequestSchema } from "./sdAssociationCreateRequestSchema";

export const sdRecordAssociationSchema = asConst({
	title: "SDRecord Association",
	description: "An association between two SDRecords",
	type: "object",
	additionalProperties: false,
	required: [...sdAssociationCreateRequestSchema.required, ...updatableSchema.required],
	properties: {
		...sdAssociationCreateRequestSchema.properties,
		...updatableSchema.properties,
	},
});
