import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { mFileFieldDef } from "./file_field_def";
import { FIELD_TYPES } from "../utils";

export class mImageFieldDef extends mFileFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.IMAGE_FILE.name];
	static override defaultComponentType: FieldComponentName = mImageFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mImageFieldDef.defaultComponentType;
		this._mimeTypesStr = "image/jpeg:jpeg,jpg;image/png:png;image/gif:gif;image/heic:heic;image/heif:heif";
		super.loadMimeTypes(this._mimeTypesStr);
		this._icon = FIELD_TYPES.IMAGE.icon;
	}

	override get type(): string {
		return FIELD_TYPES.IMAGE.name;
	}

	override get supportedComponentTypes() {
		return mImageFieldDef.supportedComponentTypes;
	}

	override supportsIconPhoto() {
		return true;
	}

	override set mimeTypes(mimeTypes) {
		throw Error(`This method is not supported in "${FIELD_TYPES.IMAGE}" type.`);
	}

	override get mimeTypes() {
		return this._mimeTypes;
	}

	override supportsCardView() {
		return false;
	}

	override supportsPreview() {
		return true;
	}

	override supportsFormatDescription() {
		return false;
	}
}
