import { memo, useCallback } from "react";

import { HeaderCell } from "../../../../../Table";
import { useBoardPropOnChangeContext } from "../../../../hooks/useBoardPropOnChangeContext";
import { SortingOrder, updateSortingDetailsForField } from "../../../../../records";
import { useBoardSortSelector } from "../../../../store";

interface ObjectBoardHeaderCellProps {
	columnId: string;
	columnName: string;
}

function ObjectBoardHeaderCellComponent({ columnId, columnName }: ObjectBoardHeaderCellProps) {
	const boardPropOnChange = useBoardPropOnChangeContext();
	const boardSortingDetails = useBoardSortSelector();

	const onSortingOrderChange = useCallback(
		(sortingOrder?: SortingOrder) => {
			boardPropOnChange("sort", updateSortingDetailsForField(boardSortingDetails, columnId, sortingOrder));
		},
		[boardSortingDetails, boardPropOnChange, columnId]
	);

	const sortingOrder = boardSortingDetails?.find((sort) => sort.fieldId === columnId)?.order;

	return (
		<HeaderCell
			columnId={columnId}
			columnName={columnName}
			sortingOrder={sortingOrder}
			onSortingOrderChange={onSortingOrderChange}
		/>
	);
}

export const ObjectBoardHeaderCell = memo(ObjectBoardHeaderCellComponent);
