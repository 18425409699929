import { asConst } from "json-schema-to-ts";
import { queryClausePropertyValueSchema } from "./queryClausePropertyValueSchema";

export const equalsClauseSchema = asConst({
	title: "Equals",
	description: "Will match any record where the given field value equals the provided value",
	type: "object",
	additionalProperties: false,
	required: ["equals"],
	properties: {
		equals: queryClausePropertyValueSchema,
	},
	nullable: false,
});
