import { ButtonHTMLAttributes, forwardRef } from "react";
import clsx from "clsx";

interface UserFilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isSelected?: boolean;
}

export const UserFilterAvatarButton = forwardRef<HTMLButtonElement, UserFilterButtonProps>(
	({ children, isSelected, ...restProps }, ref) => {
		return (
			<button
				{...restProps}
				ref={ref}
				className={clsx(
					"bg-c_bg_01 ring-c_action_focus -mr-2 shrink-0 rounded-full p-0.5 transition-all focus-visible:ring",
					isSelected && "ring"
				)}
				tabIndex={undefined}
			>
				{children}
			</button>
		);
	}
);
