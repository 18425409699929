import { FrontEndRichTextExtensionsOptions, getRichTextExtensions } from "@salesdesk/salesdesk-model";
import { Extension } from "@tiptap/core";

// When adding a new extension, make sure to add it to generateText as well. Otherwise, the backend will break
export function getFrontEndRichTextExtensions(options?: FrontEndRichTextExtensionsOptions) {
	const commonRichTextExtensions = getRichTextExtensions(options);

	if (options?.disableEnter) {
		const disableEnter = Extension.create({
			name: "disableEnter",
			addKeyboardShortcuts(this) {
				return {
					Enter: () => true,
				};
			},
		});

		commonRichTextExtensions.push(disableEnter);
	}

	if (options?.disableModEnter) {
		const disableModEnter = Extension.create({
			name: "disableModEnter",
			addKeyboardShortcuts(this) {
				return {
					"Mod-Enter": () => true,
				};
			},
		});

		commonRichTextExtensions.push(disableModEnter);
	}

	if (options?.enableShiftEnterAsEnter) {
		const enableShiftTabAsEnter = Extension.create({
			name: "enableShiftTabAsEnter",
			addKeyboardShortcuts(this) {
				return {
					"Shift-Enter": () => {
						return this.editor.commands.first(({ commands }) => [
							() => commands.newlineInCode(),
							// Normal shift-enter is missing this entry
							() => commands.splitListItem("listItem"),
							() => commands.createParagraphNear(),
							() => commands.liftEmptyBlock(),
							() => commands.splitBlock(),
						]);
					},
				};
			},
		});

		commonRichTextExtensions.push(enableShiftTabAsEnter);
	}

	return commonRichTextExtensions;
}
