import { ICONS } from "@salesdesk/salesdesk-ui";

import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { useMeetingPageContext } from "../../hooks";
import { useNavigateBack } from "../../../../../../routes";
import { GuestBadge } from "./GuestBadge";
import { useIsGuest } from "../../hooks/useIsGuest";

interface TopBarProps {
	isFullscreen: boolean;
	toggleFullscreen: () => void;
}

export function TopBar({ isFullscreen, toggleFullscreen }: TopBarProps) {
	const { meetingRecord, meetingObject } = useMeetingPageContext();
	const navigateBack = useNavigateBack();

	const isGuest = useIsGuest();

	return (
		<div className="flex items-center px-10 py-6">
			<div className="flex items-center gap-4">
				<h4 className="text-c_text_inverted text-h4">
					{meetingRecord && meetingObject ? getSDRecordName(meetingObject, meetingRecord) : "Loading..."}
				</h4>
				{isGuest ? <GuestBadge /> : null}
			</div>
			<div className="ml-auto flex gap-2">
				{!isGuest ? (
					<Tooltip text="Minimise video call">
						<Button
							id="userflow-minimise-call-button"
							size="sm"
							variant="text_inverted"
							startIcon={ICONS.pip}
							onClick={navigateBack}
						/>
					</Tooltip>
				) : null}
				<Tooltip text={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}>
					<Button size="sm" variant="text_inverted" startIcon={ICONS.fullscreen} onClick={toggleFullscreen} />
				</Tooltip>
			</div>
		</div>
	);
}
