import { asConst } from "json-schema-to-ts";

export const notificationsMarkRequestSchema = asConst({
	title: "Notifications Mark Request",
	description: "Request to mark a notification",
	type: "object",
	required: ["messageIds"],
	additionalProperties: false,
	properties: {
		messageIds: {
			type: "array",
			maxItems: 100,
			items: {
				title: "Notification ID",
				description: "ID of notification to mark",
				type: "string",
			},
		},
	},
});
