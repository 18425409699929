import { useCallback, useEffect, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { useRecordCreateForm, RecordForm } from "../../../../../../records";

import { CreateLinkedRecordTabProps } from "../types";
import { Button } from "@salesdesk/daisy-ui";
import { useToast } from "../../../../../../Toasts";
import { getRecordAssociationPathData } from "../../../../../utils";
import { useCreateRecordAssociation } from "../../../../../hooks";

export function CreateLinkedRecordTabContent({
	objectAssociation,
	sourceRecord,
	connectedAssociationObject,
	onCreateAssociationSuccess,
	closeModal,
}: CreateLinkedRecordTabProps) {
	const [internalLoading, setInternalLoading] = useState(false);

	const { createAssociation, isLoading: isLinkingRecordLoading } = useCreateRecordAssociation();
	const toast = useToast();

	const onRecordCreateSuccess = useCallback(
		(newRecord: SDRecord) => {
			createAssociation(getRecordAssociationPathData(sourceRecord, newRecord._id, objectAssociation))
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "record_association_created" });
					onCreateAssociationSuccess();
					setInternalLoading(false);
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_association_created" });
				});
		},
		[createAssociation, onCreateAssociationSuccess, sourceRecord, objectAssociation, toast]
	);

	const onRecordCreateFailed = () => {
		setInternalLoading(false);
	};

	const { formProps, isSubmitting } = useRecordCreateForm({
		sdObject: connectedAssociationObject,
		onRecordCreateSuccess,
		onRecordCreateFailed,
	});

	useEffect(() => {
		if (isSubmitting) {
			setInternalLoading(true);
		}
	}, [isSubmitting]);

	const { id, fields } = formProps;

	const isLoading = isLinkingRecordLoading || isSubmitting || internalLoading;

	if (!fields.length) return null;

	return (
		<div className="flex h-full w-full flex-col">
			<div className="-mr-3 flex h-full flex-col overflow-auto pr-3">
				<RecordForm {...formProps} columnWidth={345} />
			</div>
			<div className="flex justify-end gap-3 pt-4 align-middle">
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button form={id} type="submit" isLoading={isLoading}>
					Create
				</Button>
			</div>
		</div>
	);
}
