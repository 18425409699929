import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { Link } from "../Link";
import { BreadcrumbItem } from "./Breadcrumbs";

const MAX_BREADCRUMB_LENGTH = 24;

interface BreadcrumbProps {
	crumb: BreadcrumbItem;
	isCurrent?: boolean;
}

export function Breadcrumb({ crumb, isCurrent }: BreadcrumbProps) {
	const { node, icon, link } = crumb;

	const isLink = !isCurrent && link != null;

	const iconComponent = (
		<Icon
			icon={icon}
			className={`flex ${
				isLink
					? "text-c_text_link group-hover:text-c_action_03 group-focus-within:text-c_action_03"
					: "text-c_icon_regular"
			}`}
		/>
	);

	const truncateText = typeof node === "string" && node.length > MAX_BREADCRUMB_LENGTH + 3;

	const displayNode = truncateText ? `${node.slice(0, MAX_BREADCRUMB_LENGTH)}...` : node;

	const breadcrumbContents =
		isLink && typeof displayNode === "string" ? (
			<Link to={link} variant="action" size="sm" startIcon={icon} text={displayNode} invertUnderline={true} />
		) : (
			<span className="m-0.5 flex items-center gap-2 px-0.5" aria-current={isCurrent ? "page" : undefined}>
				{iconComponent}
				{displayNode}
			</span>
		);

	return (
		<li className="flex h-6">
			{truncateText ? <Tooltip text={node}>{breadcrumbContents}</Tooltip> : breadcrumbContents}
		</li>
	);
}
