import { ICONS } from "@salesdesk/salesdesk-ui";
import { useState } from "react";
import { EditBookmarkDialog } from "../dialogs/EditBookmarkDialog";
import { SaveViewButton } from "./SaveViewButton";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useCurrentObjectBookmark } from "../hooks/useCurrentObjectBookmark";

interface BookmarkButtonProps {
	sdObject: SDObject;
}

export function BookmarkButton({ sdObject }: BookmarkButtonProps) {
	const [openEditBookmarkDialog, setOpenEditBookmarkDialog] = useState(false);
	const currentBookmark = useCurrentObjectBookmark(sdObject);

	return (
		<>
			<div className="flex items-center">
				<Tooltip text={currentBookmark ? "Edit this bookmark" : "Bookmark this view"} placement="bottom">
					<Button
						startIcon={ICONS.bookmark}
						iconVariant={currentBookmark ? "fill" : "outline"}
						variant={currentBookmark ? "primary_text" : "text"}
						size="sm"
						onClick={() => setOpenEditBookmarkDialog(true)}
					/>
				</Tooltip>
				{currentBookmark ? <SaveViewButton bookmark={currentBookmark} /> : null}
			</div>
			{openEditBookmarkDialog ? (
				<EditBookmarkDialog open onOpenChange={setOpenEditBookmarkDialog} bookmark={currentBookmark} />
			) : null}
		</>
	);
}
