import { useMemo } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { ROW_IS_LOADING_PLACEHOLDER } from "../../../../../Table";

import { FULL_RECORD_DATA_FIELD_ID } from "../../../../types";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";
import { RecordTableColumn } from "../types";
import { TableRowCell } from "../components/TableRowCell";
import { ObjectBoardHeaderCell } from "../components/ObjectBoardHeaderCell";

export function useTableColumns(): RecordTableColumn[] {
	const { sdObject, boardFieldMap } = useDataboardDetailsContext();

	return useMemo(() => {
		const fieldColumns: RecordTableColumn[] = [];

		if (!sdObject) {
			return fieldColumns;
		}

		for (const [id, field] of boardFieldMap) {
			fieldColumns.push({
				id,
				displayName: () => <ObjectBoardHeaderCell columnId={id} columnName={field._displayName} />,
				renderRowCell: (recordField, row) => (
					<TableRowCell
						sdObject={sdObject}
						sdRecord={row[FULL_RECORD_DATA_FIELD_ID] as SDRecord}
						field={field}
						value={recordField}
						isLoading={row[ROW_IS_LOADING_PLACEHOLDER]}
					/>
				),
			});
		}

		return fieldColumns;
	}, [sdObject, boardFieldMap]);
}
