import { useState } from "react";

import { FilePreviewProps } from "../../types";
import { getFileTypeDetails } from "../../utils";
import { FileIcon } from "../FileIcon/FileIcon";
import { ExpandedFilePreview } from "../ExpandedFilePreview/ExpandedFilePreview";
import { CompactFilePreview } from "./CompactFilePreview/CompactFilePreview";
import { ImagePreview } from "./ImagePreview/ImagePreview";
import { PdfPreview } from "./PdfPreview/PdfPreview";
import { VideoPreview } from "./VideoPreview/VideoPreview";
import { VideoFileStatus } from "@salesdesk/salesdesk-schemas";

interface FilePreviewComponentProps extends FilePreviewProps {
	compact?: boolean;
	expanded?: boolean;
	onExpandedChange?: (expanded: boolean) => void;
	fileDisplayName?: string;
}

export function FilePreview({
	file,
	fileDisplayName,
	compact,
	variant = "sm",
	expanded: controlledExpanded,
	onExpandedChange,
}: FilePreviewComponentProps) {
	const isUncontrolledExpanded = typeof controlledExpanded === "undefined";

	const [uncontrolledExpanded, setUncontrolledExpanded] = useState(false);

	const expanded = controlledExpanded ?? uncontrolledExpanded;
	const setExpanded = !isUncontrolledExpanded && onExpandedChange ? onExpandedChange : setUncontrolledExpanded;

	let previewComponent;
	const fileDetails = getFileTypeDetails(file);

	const onOpenPreviewClick =
		isUncontrolledExpanded && (variant === "xs" || compact) ? () => setExpanded(true) : undefined;

	const isSdFile = file && !(file instanceof File);

	if (!compact && isSdFile && file.videoStatus === VideoFileStatus.GENERATING_FILE) {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center gap-1.5">
				<FileIcon file={file} />
				<div className="text-c_text_secondary text-body-sm">Preparing video...</div>
			</div>
		);
	}

	if (compact) {
		previewComponent = (
			<CompactFilePreview
				file={file}
				fileDisplayName={fileDisplayName}
				onOpenPreviewClick={onOpenPreviewClick}
				isPreviewOpen={expanded}
				variant={variant !== "xs" ? "sm" : "xs"}
			/>
		);
	} else if (fileDetails.type === "image") {
		previewComponent = <ImagePreview file={file} onOpenPreviewClick={onOpenPreviewClick} variant={variant} />;
	} else if (fileDetails.type === "video") {
		previewComponent = (
			<VideoPreview
				file={file}
				onOpenPreviewClick={onOpenPreviewClick}
				variant={variant}
				shouldPause={expanded || undefined}
			/>
		);
	} else if (fileDetails.type === "pdf") {
		previewComponent = <PdfPreview file={file} onOpenPreviewClick={onOpenPreviewClick} variant={variant} />;
	} else {
		previewComponent = (
			<div className="bg-c_bg_04 flex h-full w-full items-center justify-center">
				<FileIcon file={file} />
			</div>
		);
	}

	return (
		<>
			{previewComponent}
			{expanded && !(file instanceof File) ? (
				<ExpandedFilePreview file={file} onOpenChange={() => setExpanded(false)} />
			) : null}
		</>
	);
}
