import { useMemo } from "react";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { skipToken } from "@reduxjs/toolkit/query";
import { JSONContent } from "@tiptap/react";
import { getCommentsFromJSONContent } from "../utils";

import { DocumentCommentThread } from "../types";
import { useGetContextWorkspaceId } from "../../../../workspaces";
import { useGetCommentsQuery } from "../api/documentCommentsApi";

interface UseGetDocumentCommentsProps {
	documentRecord?: SDRecord;
	editorContents?: JSONContent | null;
}

export function useGetDocumentComments({ documentRecord, editorContents }: UseGetDocumentCommentsProps) {
	const inDocumentComments = useMemo(() => getCommentsFromJSONContent(editorContents), [editorContents]);
	const workspaceContextId = useGetContextWorkspaceId();

	const { data, isLoading: isLoadingComments } = useGetCommentsQuery(
		documentRecord
			? {
					recordId: documentRecord._id,
					workspaceId: workspaceContextId,
				}
			: skipToken
	);

	const commentThreads = useMemo<DocumentCommentThread[]>(() => {
		if (!data) return [];

		const threads: Record<string, DocumentCommentThread> = {};

		data.forEach((comment) => {
			const rootCommentId = comment.rootCommentId ?? comment.id;

			if (!threads[rootCommentId]) {
				threads[rootCommentId] = {
					id: rootCommentId,
					inDocumentText: inDocumentComments.find((inDocComment) => inDocComment.id === comment.id)?.text,
					comments: [],
				};
			}

			threads[rootCommentId].comments.push(comment);
		});

		// Remove orphaned threads (threads without their root comment).
		const validThreads = Object.values(threads).filter((thread) =>
			thread.comments.some((comment) => comment.id === thread.id)
		);

		// Sort comments within each thread in ascending order (oldest to newest).
		// Then sort the threads in descending order based on the root comment's creation date (newest to oldest).
		return validThreads
			.map((thread) => ({
				...thread,
				comments: thread.comments.sort((a, b) => a.createdAt - b.createdAt),
			}))
			.sort((a, b) => b.comments[0].createdAt - a.comments[0].createdAt);
	}, [data, inDocumentComments]);

	return { commentThreads, isLoadingComments };
}
