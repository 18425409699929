import { ICONS } from "@salesdesk/salesdesk-ui";
import { ToggleSwitch } from "../../../../inputs";
import { useAutoScrolling } from "../hooks/useAutoScrolling";
import { useState } from "react";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

interface ScrollControlsProps {
	activeItems: Record<string, boolean>;
}

export function ScrollControls({ activeItems }: ScrollControlsProps) {
	const [autoScroll, setAutoScroll] = useState<boolean>(true);

	const { scrollToFirstActiveItem } = useAutoScrolling(activeItems, autoScroll);

	return (
		<div className="flex items-center gap-2">
			<Tooltip text="Scroll to current position">
				<Button startIcon={ICONS.crosshairSimple} size="lg" variant="text" onClick={scrollToFirstActiveItem} />
			</Tooltip>
			<div className="flex items-center gap-4">
				<ToggleSwitch
					ariaAttributes={{ "aria-label": "Auto scroll" }}
					value={autoScroll}
					onChange={() => setAutoScroll(!autoScroll)}
				/>
				<div className="text-label cursor-pointer" onClick={() => setAutoScroll(!autoScroll)}>
					Auto scroll
				</div>
			</div>
		</div>
	);
}
