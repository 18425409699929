import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

interface DisplayRatingFieldProps {
	value?: number;
}

export function DisplayRatingField({ value }: DisplayRatingFieldProps) {
	return (
		<div className="flex items-center gap-2">
			<Icon icon={ICONS.star} variant="fill" size="lg" className="text-c_icon_regular flex" />
			<span>{value || 0}/5</span>
		</div>
	);
}
