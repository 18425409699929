import { FormDialog } from "../../../Dialog";
import { useCallback, useId, useMemo } from "react";
import { ToastMessageKey } from "../../../Toasts/types";
import { useGetLoginAuthorizationDetails } from "../../../records";
import { useToast } from "../../../Toasts";
import { useGrantUserAccessMutation } from "../../api/userAccessApi";
import {
	getSDObjectFieldByName,
	getSDRecordFieldValueByFieldName,
	getSDRecordName,
	SDRecord,
} from "@salesdesk/salesdesk-schemas";
import { useGetObjectById } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { EditRichTextField, EditTextField } from "../../../fields";
import { FormFieldSet } from "../../../forms";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { generateText, JSONContent } from "@tiptap/core";
import { GrantUserAccessParams } from "../../types";
import { getFrontEndRichTextExtensions } from "../../../../utils/getFrontEndRichTextExtensions";
import { useResendInviteMutation } from "../../api/userAccessApi";

interface CustomerUserToggleModalProps {
	userRecord: SDRecord;
	onClose: () => void;
	isReinvite?: boolean;
}

interface InviteUserFormData {
	email: string;
	welcomeMessage: JSONContent;
}

export function InviteUserModal({ userRecord, isReinvite, onClose }: CustomerUserToggleModalProps) {
	const { sdObject: userObject } = useGetObjectById(userRecord?._objectDefId);
	const { isSalesDeskUser } = useGetLoginAuthorizationDetails(userObject, userRecord);

	const toast = useToast();

	const [grantUserAccess, { isLoading }] = useGrantUserAccessMutation();
	const [postResendInvitation, { isLoading: isReinviteLoading }] = useResendInviteMutation();

	const { emailField, emailFieldValue } = useMemo(() => {
		if (!userObject) return {};
		const emailField = getSDObjectFieldByName(userObject, mUserDef.EMAIL_FIELD_NAME);
		if (!emailField) return {};
		const emailFieldValue = getSDRecordFieldValueByFieldName(userObject, userRecord, mUserDef.EMAIL_FIELD_NAME);
		return { emailField, emailFieldValue };
	}, [userObject, userRecord]);

	const { control, handleSubmit } = useForm<InviteUserFormData>({
		mode: "onChange",
		defaultValues: {
			email: String(emailFieldValue?._value) || "",
			welcomeMessage: { type: "doc", content: [{ type: "paragraph" }] },
		},
	});

	const inviteUser = useCallback(
		({ welcomeMessage }: InviteUserFormData) => {
			const toastMessageKey: ToastMessageKey = isReinvite
				? isSalesDeskUser
					? "user_resend_invitation"
					: "customer_resend_invitation"
				: isSalesDeskUser
					? "user_login_enabled"
					: "customer_access_granted";
			if (!userObject) {
				return;
			}

			const params: GrantUserAccessParams = {
				userRecord,
				userObject,
			};

			const welcomeMessageIsEmpty = !generateText(welcomeMessage, getFrontEndRichTextExtensions()).trim();
			if (!welcomeMessageIsEmpty) {
				params.welcomeMessage = JSON.stringify(welcomeMessage);
			}

			if (isReinvite && emailFieldValue) {
				postResendInvitation({
					username: emailFieldValue._value as string,
					welcomeMessage: params.welcomeMessage ?? undefined,
				})
					.unwrap()
					.then(() => {
						toast.triggerMessage({ type: "success", messageKey: toastMessageKey });
						onClose();
					})
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: toastMessageKey });
						onClose();
					});
				return;
			}

			grantUserAccess(params)
				.unwrap()
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: toastMessageKey });
					onClose();
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: toastMessageKey });
				});
		},
		[
			grantUserAccess,
			isSalesDeskUser,
			onClose,
			toast,
			userObject,
			userRecord,
			isReinvite,
			postResendInvitation,
			emailFieldValue,
		]
	);

	const recordName = useMemo(() => {
		if (!userObject) return "";
		return getSDRecordName(userObject, userRecord);
	}, [userObject, userRecord]);

	const userType = isSalesDeskUser ? "user" : "customer";

	const formId = useId();

	if (!emailField || !emailFieldValue) {
		return null;
	}

	return (
		<FormDialog
			open={true}
			onOpenChange={() => onClose()}
			title={isReinvite ? "Resend invitation" : `Invite ${recordName}`}
			submitButtonLabel={isReinvite ? "Resend invitation" : "Invite"}
			isPending={isLoading || isReinviteLoading}
			formId={formId}
		>
			<form id={formId} onSubmit={handleSubmit(inviteUser)}>
				<div className="flex max-h-[600px] flex-col gap-8 overflow-auto">
					<div className="flex shrink-0 flex-col gap-8">
						<div>
							Are you sure you want to {isReinvite ? "resend an invitation to " : "invite "}
							<span className="text-label">{recordName}</span>
							{!isReinvite && " as a user"}? The user will be invited via email.
						</div>
						<FormFieldSet
							control={control}
							name="email"
							label={emailField._displayName}
							labelIcon={emailField._icon}
							required
							rules={{ required: `${emailField._displayName} is required` }}
						>
							{({ field, fieldState: { error } }) => (
								<EditTextField title={emailField._displayName} {...field} disabled />
							)}
						</FormFieldSet>
						<div className="bg-c_border_regular h-px" />
					</div>
					<div className="flex max-h-full flex-col gap-8 overflow-hidden">
						<div className="text-h4">Add a personalised message</div>
						<FormFieldSet
							isOverflowHidden
							control={control}
							name="welcomeMessage"
							label="Message"
							labelIcon={ICONS.paragraph}
						>
							{({ field }) => (
								<EditRichTextField {...field} disabled={isLoading} heightClassName="min-h-[122px] max-h-[172px]" />
							)}
						</FormFieldSet>
					</div>
				</div>
			</form>
		</FormDialog>
	);
}
