import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { CHAT_CHANNEL_ARN_PARAM, CHAT_MESSAGE_ID_PARAM } from "@salesdesk/salesdesk-schemas";

import { useStableNavigate } from "../../../routes";

export function useMessagingUrlParams() {
	const location = useLocation();
	const navigate = useStableNavigate();

	const extractParams = (search: string) => {
		const searchParams = new URLSearchParams(search);
		return {
			channelArn: searchParams.get(CHAT_CHANNEL_ARN_PARAM),
			messageId: searchParams.get(CHAT_MESSAGE_ID_PARAM),
		};
	};

	const [params, setParams] = useState(() => extractParams(location.search));

	useEffect(() => {
		setParams(extractParams(location.search));
	}, [location.search]);

	const clearParams = useCallback(() => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.delete(CHAT_CHANNEL_ARN_PARAM);
		searchParams.delete(CHAT_MESSAGE_ID_PARAM);

		setParams({ channelArn: null, messageId: null });
		navigate({ search: searchParams.toString() }, { replace: true });
	}, [location, navigate]);

	return { ...params, clearParams };
}
