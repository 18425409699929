import { useCallback, useState } from "react";
import { JSONContent } from "@tiptap/core";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { findAllLinksWithinJSONContent, recordIdForLink } from "../utils";

export function useInputRecordAttachments() {
	const workspaceId = useGetContextWorkspaceId();

	const [, setRecordIdLinkCount] = useState<Record<string, number>>({});
	const [recordAttachmentIds, setRecordAttachmentIds] = useState<SDRecord["_id"][]>([]);

	const addRecordAttachments = useCallback((ids: SDRecord["_id"] | SDRecord["_id"][]) => {
		const idsArray = Array.isArray(ids) ? ids : [ids];

		setRecordAttachmentIds((prevAttachments) => {
			const newAttachments = new Set(prevAttachments);
			idsArray.forEach((id) => newAttachments.add(id));
			return Array.from(newAttachments);
		});
	}, []);

	const removeRecordAttachments = useCallback((ids: SDRecord["_id"] | SDRecord["_id"][]) => {
		const idsArray = Array.isArray(ids) ? ids : [ids];
		setRecordAttachmentIds((prevAttachments) => {
			const newAttachments = new Set(prevAttachments);
			idsArray.forEach((id) => newAttachments.delete(id));
			return Array.from(newAttachments);
		});
	}, []);

	const clearRecordAttachments = useCallback(() => {
		setRecordAttachmentIds([]);
	}, []);

	const updateRecordAttachments = useCallback(
		(jsonContent: JSONContent) => {
			// Currently we only support record attachments for records in the current workspace
			if (workspaceId == null) {
				return;
			}

			const links = findAllLinksWithinJSONContent(jsonContent);

			const recordIdCountMap: Record<string, number> = {};

			links.forEach((link) => {
				const recordId = recordIdForLink(link, String(workspaceId));

				if (recordId === null) {
					return;
				}

				if (recordIdCountMap[recordId]) {
					recordIdCountMap[recordId]++;
				} else {
					recordIdCountMap[recordId] = 1;
				}
			});

			setRecordIdLinkCount((prevCount) => {
				const recordsToAdd: SDRecord["_id"][] = [];

				Object.keys(recordIdCountMap).forEach((recordId) => {
					if (!prevCount[recordId] || prevCount[recordId] < recordIdCountMap[recordId]) {
						recordsToAdd.push(Number(recordId));
					}
				});

				if (recordsToAdd.length) {
					addRecordAttachments(recordsToAdd);
				}

				return recordIdCountMap;
			});
		},
		[addRecordAttachments, workspaceId]
	);

	return {
		recordAttachmentIds,
		updateRecordAttachments,
		removeRecordAttachments,
		addRecordAttachments,
		clearRecordAttachments,
	};
}
