import { useEventContext } from "../../../hooks/useEventContext";
import { NoteMentionParams } from "@salesdesk/salesdesk-schemas";
import { LazyNotePayload } from "./LazyNotePayload";

export function NoteMentionPayload() {
	const { event } = useEventContext();
	const { noteId, noteCreatedBy } = event.params as NoteMentionParams;

	return <LazyNotePayload recordId={event.record_id} noteId={noteId} createdById={noteCreatedBy} />;
}
