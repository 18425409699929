import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

import { useGetObjectMap } from "../../../../../../../objects/hooks";
import { useGetRecordQuery } from "../../../../../../../records";
import { ChannelMessagePreviewTemplate } from "./ChannelMessagePreviewTemplate";

interface ChannelMessagePreviewRecordProps {
	message?: ChannelMessage | null;
	userMessageText?: string;
	recordId: number;
}

export function ChannelMessagePreviewRecord({ userMessageText, recordId, message }: ChannelMessagePreviewRecordProps) {
	const objectsMap = useGetObjectMap();
	const { currentData: record, isLoading, isError } = useGetRecordQuery(recordId);

	const sharedRecordIsLoading = isLoading || (!record && !isError);

	const messagePreview = useMemo(() => {
		if (record) {
			const sharedSDObject = objectsMap.get(record._objectDefId);

			return {
				text: userMessageText ? userMessageText : sharedSDObject?._displayName || "Shared a record",
				icon: sharedSDObject?._icon || ICONS.file,
			};
		} else if (isError || !sharedRecordIsLoading) {
			return {
				text: userMessageText ? userMessageText : "Shared a record",
				icon: ICONS.file,
			};
		}

		return { text: "", icon: "" };
	}, [objectsMap, userMessageText, record, isError, sharedRecordIsLoading]);

	return (
		<ChannelMessagePreviewTemplate
			isLoading={sharedRecordIsLoading}
			message={message}
			icon={messagePreview.icon}
			messageText={messagePreview?.text}
		/>
	);
}
