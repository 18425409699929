import { asConst } from "json-schema-to-ts";
import { hasAssociationToRecordForObjectAssociationClausePropertySchema } from "./hasAssociationToRecordForObjectAssociationClausePropertySchema";

export const hasAssociationsToAllRecordsForObjectAssociationClauseSchema = asConst({
	title: "Has Associations To All Records For Object Association Clause",
	description: "Will match any record that is associated with the given labels to all the given record IDs",
	type: "object",
	additionalProperties: false,
	required: ["hasAssociationsToAllRecordsForObjectAssociation"],
	properties: {
		hasAssociationsToAllRecordsForObjectAssociation: {
			type: "array",
			items: hasAssociationToRecordForObjectAssociationClausePropertySchema,
		},
	},
	nullable: false,
});
