import { mUserDef } from "@salesdesk/salesdesk-model";
import { RuleValueSelect } from "../RuleValueSelect";
import { useNotificationRuleDialogContext } from "../../../../hooks/useNotificationRuleDialogContext";
import { SelectOption } from "../../../../../../inputs";
import { useCallback } from "react";
import { useGetRecordOptions } from "../../../../../../fields";

export function UserValueSelector() {
	const { selectedUsers, setSelectedUsers, selectedWorkspaceId } = useNotificationRuleDialogContext();
	const { getOptions: getUserOptions } = useGetRecordOptions({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
		sharedWithWorkspaceId: selectedWorkspaceId,
	});

	const onChange = useCallback(
		(value: SelectOption | undefined) => {
			setSelectedUsers(value ? [value] : []);
		},
		[setSelectedUsers]
	);

	return (
		<RuleValueSelect
			title="User selection"
			value={selectedUsers.length > 0 ? selectedUsers[0] : undefined}
			onChange={onChange}
			getAsyncSelectOptions={getUserOptions}
			multiple={false}
			optionDisplayVariant="user_pill"
		/>
	);
}
