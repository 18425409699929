import { asConst } from "json-schema-to-ts";
import { rolePatchRequestSchema } from "./rolePatchRequestSchema";

export const roleCreateRequestSchema = asConst({
	title: "RoleCreateRequestSchema",
	description: "The schema used for creating Roles",
	type: "object",
	additionalProperties: false,
	required: ["name", "claims"],
	properties: {
		...rolePatchRequestSchema.properties,
	},
});
