import { useEffect, useState } from "react";
import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";
import clsx from "clsx";

import { Avatar, getInitials } from "../../../../users";
import { CallParticipantDetails, usePublications } from "../../VideoCallProvider";
import Publication from "./Publication";
import { ParticipantAudioMeter } from "./ParticipantAudioMeter";
import { PresentedFileView } from "./PresentedFileView";
import { isScreenShareTrack } from "../../../utils";

interface CallParticipantProps {
	participantDetails: CallParticipantDetails;
	isLocal?: boolean;
	dominantSpeaker?: boolean;
	displayText?: string;
	isSmall?: boolean;
}

type ParticipantTrackPublication = LocalTrackPublication | RemoteTrackPublication | undefined;

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */
export function CallParticipant({
	participantDetails,
	isLocal,
	dominantSpeaker,
	displayText,
	isSmall,
}: CallParticipantProps) {
	const { name, variant, participant } = participantDetails;

	const publications = usePublications(participant);
	const [videoPublication, setVideoPublication] = useState<ParticipantTrackPublication>();
	const [audioPublication, setAudioPublication] = useState<ParticipantTrackPublication>();

	useEffect(() => {
		let video: ParticipantTrackPublication;
		let audio: ParticipantTrackPublication;

		for (const publication of publications) {
			if (!video && publication.kind === "video") {
				const screenShareTrack = isScreenShareTrack(publication);
				const isScreenShare = variant === "screen_share";

				if ((isScreenShare && screenShareTrack) || (!isScreenShare && !screenShareTrack)) {
					video = publication;
				}
			}

			if (!audio && publication.kind === "audio" && variant === "default") {
				audio = publication;
			}

			if (audio && video) {
				break;
			}
		}

		setVideoPublication(video);
		setAudioPublication(audio);
	}, [publications, variant]);

	const displayName = displayText === undefined ? name : displayText;

	return (
		<div
			key={participant.sid + variant}
			className={clsx(variant === "default" ? "bg-c_bg_tooltip" : "bg-c_bg_07", "relative h-full w-full rounded-md")}
		>
			<div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-md">
				{variant === "default" ? (
					<Avatar
						initials={getInitials(participantDetails.name)}
						avatarFileId={participantDetails.avatarFileId}
						size={isSmall ? "md" : "xl"}
					/>
				) : null}
				<div
					className={clsx(
						dominantSpeaker ? "border-c_action_focus" : "border-transparent",
						"pointer-events-none absolute top-0 z-10 box-border h-full w-full rounded-md border-[6px] transition-colors"
					)}
				/>
				{variant === "default" ? (
					<div className="absolute right-4 top-4 z-10">
						<ParticipantAudioMeter audioPublication={audioPublication} />
					</div>
				) : null}
				{displayName ? (
					<div className="from-c_brand_dark/40 absolute bottom-0 z-10 flex w-full justify-end bg-gradient-to-t p-4">
						<div className="text-body-sm text-c_text_inverted text-right drop-shadow-sm">{displayName}</div>
					</div>
				) : null}
				{videoPublication && variant !== "file" ? (
					<div className="absolute top-0 h-full w-full">
						<Publication key={videoPublication.trackName} publication={videoPublication} isLocal={isLocal} />
					</div>
				) : null}
				{variant === "file" && participantDetails.sharedFile ? (
					<PresentedFileView presentedFileDetails={participantDetails.sharedFile} isLocal={isLocal} />
				) : null}
			</div>
		</div>
	);
}
