import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { useWebPrincipal } from "../../../../../../auth";
import { useGetRecordOptions } from "../../../../../fields";
import { SelectOptionId, TypeaheadSelect } from "../../../../../inputs";
import { useNotificationRuleDialogContext } from "../../../hooks/useNotificationRuleDialogContext";

interface WorkspaceValueSelectorProps {
	onChange: (value: SelectOptionId | undefined) => void;
}

export function WorkspaceValueSelector({ onChange }: WorkspaceValueSelectorProps) {
	const principal = useWebPrincipal();
	const { selectedWorkspaceId } = useNotificationRuleDialogContext();
	const getOptionProps = useGetRecordOptions({
		baseObjectId: mWorkspaceDef.ID,
		fetchingRecordOptions: true,
	});

	return (
		<div className="flex w-full flex-col gap-3">
			<div className="text-label text-c_text_primary">Workspace</div>
			<div className="w-full">
				<TypeaheadSelect
					placeholder="Select workspace (optional)"
					value={selectedWorkspaceId}
					onChange={onChange}
					{...getOptionProps}
					isMultiselect={false}
					disabled={principal.IsCustomerWithSingleWorkspace}
				/>
			</div>
		</div>
	);
}
