import { asConst } from "json-schema-to-ts";
import { appendEditableForCustomAndEditableSystem } from "../../../functions";
import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";

export const fieldComponentTypeSchema = asConst({
	title: "SDObject Field Component Type",
	description: appendEditableForCustomAndEditableSystem(
		"Dependent on Field type and indicates which UI component should be used to display this field"
	),
	type: "string",
	enum: Object.entries(FIELD_COMPONENT_TYPES).map(([, v]) => v.name),
});
