import clsx from "clsx";

import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { formatVideoTimestamp } from "../../../../files/components/VideoPlayer/utils";
import { transcriptSentimentColors, TranscriptSentimentType } from "../../../types";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { UserPill, UserPillController } from "../../../../users";
import { HighlightedText } from "./HighligthedText";
import { Tooltip } from "@salesdesk/daisy-ui";
import { useVideoEvents } from "../../../../files/components/VideoPlayer/hooks/useVideoEvents";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { TranscriptItem as TranscriptItemType } from "@salesdesk/salesdesk-schemas";
import { keyToPlaceholderName } from "../../../utils";

export const ACTIVE_TRANSCRIPT_ITEM_ATTRIBUTE_NAME = "data-active-transcript-item";

export interface TranscriptItemProps {
	key: string;
	transcriptItem: TranscriptItemType;
	longestSentimentType: TranscriptSentimentType | undefined;
	search: string;
	active: boolean;
}

export function TranscriptItem({ transcriptItem, longestSentimentType, search, active }: TranscriptItemProps) {
	const { file, videoRef } = useMediaAnalysisContext();
	const { text, userId, speaker, startTstampMillis } = transcriptItem;

	const postVideoEvent = useVideoEvents(file.recordId, file.fileId);

	const onItemClicked = () => {
		if (!videoRef.current) {
			return;
		}
		videoRef.current.currentTime = startTstampMillis / 1000;
		postVideoEvent(SdEventType.FILE_SEEKED, videoRef.current.currentTime);
	};

	const userIdOrPlaceholderName = userId ?? keyToPlaceholderName(speaker);

	return (
		<div className="flex w-full justify-stretch" {...{ [ACTIVE_TRANSCRIPT_ITEM_ATTRIBUTE_NAME]: String(active) }}>
			<div className="text-body-sm text-c_text_placeholder mr-2 h-9 w-[70px] flex-shrink-0 text-right leading-9">
				{formatVideoTimestamp(startTstampMillis / 1000)}
			</div>
			<div className="relative w-2 flex-shrink-0">
				<div className="bg-c_bg_disabled_01 mx-auto h-full w-px" />
				<div className="absolute top-0 h-9 w-full">
					<Tooltip text={capitalizeString((longestSentimentType ?? "").toLowerCase())}>
						<div
							className="absolute left-1/2 top-1/2 -ml-1 -mt-1 h-2 w-2 rounded-full"
							style={{
								backgroundColor: transcriptSentimentColors[longestSentimentType ?? "NEUTRAL"],
							}}
						/>
					</Tooltip>
				</div>
			</div>
			<div className="ml-6 flex flex-grow flex-col gap-0.5 pb-6">
				<div>
					{typeof userIdOrPlaceholderName === "number" ? (
						<UserPillController userId={userIdOrPlaceholderName} />
					) : userIdOrPlaceholderName ? (
						<UserPill user={userIdOrPlaceholderName} isSystemUser={true} />
					) : undefined}
				</div>
				<button
					className={clsx(
						"text-body rounded-minimal hover:bg-c_bg_02 focus-visible:ring-c_action_focus p-2 text-left focus-visible:ring",
						active && "bg-c_bg_02"
					)}
					onClick={onItemClicked}
				>
					<HighlightedText search={search}>{text}</HighlightedText>
				</button>
			</div>
		</div>
	);
}
