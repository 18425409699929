import { forwardRef, useEffect, useState, KeyboardEvent, useRef } from "react";
import clsx from "clsx";
import { RadioGroup } from "@headlessui/react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { Icon, combineRefs, applyAndCancelKeyCapturing } from "@salesdesk/daisy-ui";
import { RatingInputProps } from "../../types";

export const RatingInput = forwardRef<HTMLElement, RatingInputProps>(
	({ disabled, hasError, value, onChange, onBlur, maxRating = 5, onApply, onCancel, readonly }, ref) => {
		const internalRef = useRef<HTMLElement>();
		const [ratingValues, setRatingValues] = useState(() => generateRatingValues(maxRating));

		const [hoveredRating, setHoveredRating] = useState(0);
		const [isKeyboardControlled, setIsKeyboardControlled] = useState(false);

		useEffect(() => {
			setRatingValues(generateRatingValues(maxRating));
		}, [maxRating]);

		const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
			setIsKeyboardControlled(true);
			applyAndCancelKeyCapturing<HTMLElement>(event, onApply, onCancel);
		};

		return (
			<RadioGroup
				className="flex items-center"
				value={value}
				disabled={disabled || readonly}
				onChange={onChange}
				onMouseOut={() => {
					setHoveredRating(0);
					setIsKeyboardControlled(false);
				}}
				onClick={() => setIsKeyboardControlled(true)}
				onKeyDown={handleKeyDown}
				onBlur={(event) => {
					const { current } = internalRef;

					if (current && !current.contains(event.relatedTarget) && onBlur) {
						onBlur(event);
					}
				}}
				ref={combineRefs([ref, internalRef])}
			>
				{ratingValues.map((ratingValue) => {
					const hoverMode = hoveredRating > 0 && !isKeyboardControlled;

					const isHovered = ratingValue <= hoveredRating && hoverMode;
					const isSelected = ratingValue <= (value || 0) && !hoverMode;

					const iconVariant = isHovered || isSelected ? "fill" : "outline";
					const isLastElement = ratingValue === maxRating;

					return (
						<RadioGroup.Option
							key={ratingValue}
							value={ratingValue}
							onMouseOver={() => {
								setHoveredRating(ratingValue);
								setIsKeyboardControlled(false);
							}}
						>
							<Icon
								key={ratingValue}
								icon={ICONS.star}
								size="lg"
								variant={iconVariant}
								className={clsx(
									isHovered && !disabled && "text-c_action_02 cursor-pointer",
									isSelected && !hasError && !disabled && "text-c_action_01",
									disabled && "text-c_bg_disabled_01",
									hasError && !disabled && "text-c_danger_02",
									!isHovered && !isSelected && !disabled && !hasError && "text-c_icon_regular",
									!isLastElement && "pr-2",
									"flex items-center"
								)}
							/>
						</RadioGroup.Option>
					);
				})}
				{/*
				Icon is rendered to ensure 'ph-fill' font is loaded by the browser
				to prevent flickering on rating hover/select
			*/}
				<Icon variant="fill" icon={ICONS.star} className="sr-only" />
			</RadioGroup>
		);
	}
);

function generateRatingValues(maxRating: number) {
	return Array.from({ length: maxRating }, (_, index) => index + 1);
}
