import { Bookmark, BookmarkCreateRequest, BookmarkUpdateRequest } from "@salesdesk/salesdesk-schemas";
import { buildApiInvalidatedTagList, buildApiSingleTag, buildApiTagsList, SDApi } from "../../api";

export const recordAssociationsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getMyBookmarks: builder.query<Bookmark[], void>({
				query: () => ({
					url: `/me/bookmarks`,
					method: "GET",
				}),
				providesTags: (bookmarks) => buildApiTagsList(bookmarks, (bookmark) => bookmark.id, "Bookmark"),
			}),
			getMyBookmark: builder.query<Bookmark, Bookmark["id"]>({
				query: (bookmarkId) => ({
					url: `/me/bookmarks/${bookmarkId}`,
					method: "GET",
				}),
				providesTags: (result, error, bookmarkId) => buildApiSingleTag(bookmarkId, () => bookmarkId, "Bookmark"),
			}),
			createMyBookmark: builder.mutation<Bookmark, BookmarkCreateRequest>({
				query: (body) => ({
					url: `/me/bookmarks`,
					method: "POST",
					body,
				}),
				invalidatesTags: (bookmark) => buildApiInvalidatedTagList(bookmark, (bookmark) => bookmark.id, "Bookmark"),
			}),
			updateMyBookmark: builder.mutation<void, { bookmarkId: Bookmark["id"]; body: BookmarkUpdateRequest }>({
				query: ({ bookmarkId, body }) => ({
					url: `/me/bookmarks/${bookmarkId}`,
					method: "PUT",
					body,
				}),
				invalidatesTags: (result, error, request) =>
					buildApiInvalidatedTagList(request, (r) => r.bookmarkId, "Bookmark"),
			}),
			deleteMyBookmark: builder.mutation<void, Bookmark["id"]>({
				query: (bookmarkId) => ({
					url: `/me/bookmarks/${bookmarkId}`,
					method: "DELETE",
				}),
				invalidatesTags: (result, error, bookmarkId) =>
					buildApiInvalidatedTagList(bookmarkId, (bookmarkId) => bookmarkId, "Bookmark"),
			}),
		};
	},
});

export const {
	useGetMyBookmarksQuery,
	useGetMyBookmarkQuery,
	useCreateMyBookmarkMutation,
	useUpdateMyBookmarkMutation,
	useDeleteMyBookmarkMutation,
} = recordAssociationsApi;
