import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";

import { Bookmark, SDObject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { AlertBubble, Icon } from "@salesdesk/daisy-ui";

import { DebouncedSearchbar } from "../../../inputs";
import { BookmarkPopoverItem } from "./BookmarkPopoverItem";
import { getBookmarkRedirectUrl, UNSELECT_BOOKMARK_ID } from "../../utils";
import { Divider } from "../../../../components/Divider/Divider";
import { PATHS, useStableNavigate } from "../../../../routes";
import { useGetObjectsQuery } from "../../../objects/api/objectsApi";
import { useObjectBookmarks } from "../hooks/useObjectBookmarks";
import { useCurrentObjectBookmark } from "../hooks/useCurrentObjectBookmark";
import { BookmarkPopoverLoading } from "./BookmarkPopoverLoading";

interface BookmarkPopoverContainerProps {
	onBookmarkClick: (bookmark: Bookmark) => void;
	onEditClick: (bookmark: Bookmark) => void;
	onDeleteClick: (bookmark: Bookmark) => void;
	onClose: () => void;
	sdObject?: SDObject;
}

const CONTAINER_WIDTH = 320;
const ALL = { id: "ALL" };

export function BookmarkPopoverContainer({
	onBookmarkClick,
	onEditClick,
	onDeleteClick,
	sdObject,
	onClose,
}: BookmarkPopoverContainerProps) {
	const [search, setSearch] = useState("");
	const navigate = useStableNavigate();

	const { data: sdObjects } = useGetObjectsQuery();
	const bookmarks = useObjectBookmarks(sdObject);
	const currentBookmark = useCurrentObjectBookmark(sdObject) ?? ALL;

	const filteredBookmarks = useMemo(() => {
		if (!bookmarks) return [];
		if (!search) return bookmarks;
		return bookmarks.filter((bookmark) => bookmark.name.toLowerCase().includes(search.toLowerCase()));
	}, [bookmarks, search]);

	const isLoading = bookmarks === undefined;

	return (
		<div className="flex flex-col justify-stretch" style={{ width: CONTAINER_WIDTH, maxHeight: 420 }}>
			<div className="mx-6 my-3 flex items-center gap-2">
				<Icon icon={ICONS.bookmark} className="text-c_icon_regular flex items-center" />
				<div className="label-sm">Bookmarks</div>
				{bookmarks?.length ? <AlertBubble alertCount={bookmarks.length} variant="primary" /> : null}
			</div>
			<Combobox
				value={currentBookmark}
				onChange={(value) => {
					if (value === ALL && sdObject) {
						navigate(PATHS.OBJECT_RECORD_BOARD(sdObject, { bookmarkId: UNSELECT_BOOKMARK_ID }));
					} else {
						navigate(getBookmarkRedirectUrl(sdObjects, value as Bookmark));
					}
					onClose();
				}}
			>
				<div className="mx-6 mb-3">
					<Combobox.Input as={Fragment}>
						<DebouncedSearchbar
							onChange={setSearch}
							isCollapsible={false}
							isClearable={false}
							wrapperClass="w-full"
							isLoading={isLoading}
						/>
					</Combobox.Input>
				</div>
				{isLoading ? (
					<BookmarkPopoverLoading />
				) : (
					<div className="flex-grow overflow-y-auto">
						<Combobox.Options static>
							{sdObject ? (
								<>
									<Combobox.Option value={ALL}>
										{({ active, selected }) => (
											<Link
												className={clsx(
													"focus:bg-c_bg_03 hover:bg-c_bg_03 group relative flex min-h-14 items-center gap-2 px-6 transition-colors",
													active ? "bg-c_bg_03" : selected ? "bg-c_bg_05" : undefined
												)}
												to={PATHS.OBJECT_RECORD_BOARD(sdObject, { bookmarkId: UNSELECT_BOOKMARK_ID })}
												onClick={(e) => {
													e.stopPropagation();
													onClose();
												}}
											>
												All {sdObject._pluralName?.toLowerCase()}
											</Link>
										)}
									</Combobox.Option>
									<Divider />
									<div className="text-body-sm text-c_text_secondary mx-6 py-2">Bookmarks</div>
								</>
							) : null}
							{filteredBookmarks.map((bookmark) => (
								<Combobox.Option value={bookmark} key={bookmark.id}>
									{({ active, selected }) => (
										<BookmarkPopoverItem
											key={bookmark.id}
											bookmark={bookmark}
											onBookmarkClick={onBookmarkClick}
											onEditClick={onEditClick}
											onDeleteClick={onDeleteClick}
											showObjectName={sdObject === undefined}
											active={active}
											selected={selected}
										/>
									)}
								</Combobox.Option>
							))}
							{filteredBookmarks.length === 0 ? (
								<div className="text-c_text_disabled text-body-sm my-3 text-center">
									{search ? "No bookmarks matching your search." : "There are no bookmarks yet."}
								</div>
							) : null}
						</Combobox.Options>
					</div>
				)}
			</Combobox>
		</div>
	);
}
