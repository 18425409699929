import { Alert } from "../../../../../components/Alert";
import { useState } from "react";
import { getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Accordion } from "../../../../../components/Accordion";

type BulkChangeAlertProps = {
	actionMessage: string;
	totalRecordCount: number;
	unEditableRecords: SDRecord[];
	sdObject: SDObject;
};

export function BulkChangeAlert({
	actionMessage,
	totalRecordCount,
	unEditableRecords,
	sdObject,
}: BulkChangeAlertProps) {
	const [showAlert, setShowAlert] = useState(true);

	return unEditableRecords.length > 0 && showAlert ? (
		<Alert
			variant="error"
			title="Permission Restriction"
			message={`You do not have permission to ${actionMessage} ${unEditableRecords.length} of the ${totalRecordCount} selected records.  These records will not be modified.`}
			onDismissClick={() => setShowAlert(false)}
		>
			<Accordion variant="secondary" title="View Restricted Records">
				<div className="text-c_text_primary border-t-c_border_regular mt-2 flex flex-col gap-4 border-t pt-1">
					{unEditableRecords.map((record) => {
						return (
							<div className="text-body-sm" key={record._id}>
								{getSDRecordName(sdObject, record)}
							</div>
						);
					})}
				</div>
			</Accordion>
		</Alert>
	) : null;
}
