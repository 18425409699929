import { useMemo } from "react";
import { differenceInMinutes } from "date-fns";

import { mUserDef } from "@salesdesk/salesdesk-model";
import { skipToken } from "@reduxjs/toolkit/query";
import {
	SDObject,
	SDRecord,
	getSDRecordSystemFieldValueByFieldName,
	getSDRecordFieldValueByFieldName,
} from "@salesdesk/salesdesk-schemas";
import { MINUTE_IN_MS } from "@salesdesk/salesdesk-utils";

import { useGetLastActiveQuery } from "../../../features/records/";
import { useGetLoginAuthorizationDetails } from "../../records/hooks/useGetLoginAuthorizationDetails";

const ACTIVE_NOW_THRESHOLD_MINUTES = 1;

export function useUserLoginStatus(userRecord?: SDRecord, userObject?: SDObject, usePolling = true) {
	const { isAuthorizedToLogIn, isSalesDeskUser } = useGetLoginAuthorizationDetails(userObject, userRecord);

	const username = useMemo(
		() =>
			isAuthorizedToLogIn && userObject && userRecord
				? (getSDRecordSystemFieldValueByFieldName(userObject, userRecord, mUserDef.EMAIL_FIELD_NAME)?._value as string)
				: null,
		[userObject, userRecord, isAuthorizedToLogIn]
	);

	const { currentData: data } = useGetLastActiveQuery(username && usePolling ? { username } : skipToken, {
		pollingInterval: MINUTE_IN_MS,
	});

	const lastActiveTimestamp = useMemo(() => {
		if (!userObject || !userRecord) return null;

		const lastActiveFieldValue = getSDRecordFieldValueByFieldName(
			userObject,
			userRecord,
			mUserDef.LAST_ACTIVE_FIELD_NAME
		);

		return (
			data?.lastActive ??
			(typeof lastActiveFieldValue?._value === "number" ? (lastActiveFieldValue?._value ?? null) : null)
		);
	}, [userObject, userRecord, data]);

	const isLoginDisabled = !isAuthorizedToLogIn && isSalesDeskUser;
	const lastActive = lastActiveTimestamp != null ? new Date(lastActiveTimestamp) : null;
	const isNowActive = lastActive ? differenceInMinutes(new Date(), lastActive) <= ACTIVE_NOW_THRESHOLD_MINUTES : false;

	const isDeactivated = Boolean(lastActive) && !isAuthorizedToLogIn;
	const isInvited = !lastActive && isAuthorizedToLogIn;

	return {
		canLogIn: Boolean(username),
		isLoginDisabled,
		isNowActive,
		lastActive,
		isDeactivated,
		isInvited,
	};
}
