import { WebSocketMessage } from "./WebSocketMessage";

export enum BrowserNotificationWSMessageType {
	New = "BrowserNotification.New",
}

export interface BrowserNotificationNewWSMessage extends Omit<WebSocketMessage, "type"> {
	type: BrowserNotificationWSMessageType.New;
	title: string;
	tag: string;
	body: string;
	path: string | null;
}
