import { DataService } from "@salesdesk/salesdesk-services";
import { mBackingStore } from "../backing_store";

export const NO_LEGACY_BACKING_STORE_TYPE = "NoLegacyBackingStoreType";

export class mDbBackingStore extends mBackingStore {
	getDbService() {
		return DataService.getInstance();
	}
}
