import {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	forwardRef,
	KeyboardEventHandler,
	MouseEventHandler,
	useCallback,
} from "react";
import clsx from "clsx";
import { Transition } from "@headlessui/react";

import { tw } from "../../../../../../../../utils/tailwind-helpers";
import { AlertBubble, Icon, Tooltip } from "@salesdesk/daisy-ui";
import { SelectDrop } from "../../../../../../../../components/SelectDrop/SelectDrop";

export type SidePanelButtonVariant = "light" | "dark";

export interface SidePanelButtonProps
	extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "className"> {
	tooltip?: string;
	icon: string;
	selected?: boolean;
	variant?: SidePanelButtonVariant;
	// Can't use onClick because Headless UI will overwrite it when used in a Tab
	onSelected?: () => void;
	alertCount?: number;
	userflowId?: string;
}

export const SidePanelButton = forwardRef<HTMLButtonElement, SidePanelButtonProps>(
	(
		{
			tooltip = "",
			icon,
			selected = false,
			variant = "light",
			onSelected = () => undefined,
			alertCount,
			userflowId,
			...buttonProps
		},
		ref
	) => {
		const { onClick, onKeyDown, ...headlessTabProps } = buttonProps;

		// This is for when mouse is used to open/close the panel when the tab is already selected
		const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
			(event) => {
				if (onClick) onClick(event);
				onSelected();
			},
			[onClick, onSelected]
		);

		// This is for when keyboard is used to open/close the panel when the tab is already selected
		const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
			(event) => {
				if (onKeyDown) onKeyDown(event);
				if (event.code === "Enter" || event.code === "Space") {
					onSelected();
				}
			},
			[onKeyDown, onSelected]
		);

		const isLightVariant = variant === "light";

		return (
			// Userflow id is added to the wrapping div, since headless ui hijacks the id of the button for the panel tab logic
			<div id={userflowId} className="relative pl-4 pr-2">
				{alertCount ? (
					<div className="absolute right-1.5 top-0 z-20 flex h-4 min-w-[16px] items-center justify-center">
						<AlertBubble
							alertCount={alertCount}
							inline={true}
							variant={isLightVariant ? "secondary" : "primary"}
							size={alertCount < 100 ? "md" : "sm"}
						/>
					</div>
				) : null}
				<Tooltip text={tooltip} placement="left">
					<button
						ref={ref}
						className={clsx(
							"focus-visible:ring-c_action_focus relative z-10 items-center rounded-full transition-all focus-visible:ring",
							isLightVariant &&
								(selected
									? `bg-c_bg_03 text-c_icon_regular`
									: `text-c_secondary_focus hover:bg-c_bg_03 hover:text-c_text_placeholder`),
							!isLightVariant &&
								(selected
									? `bg-c_bg_08 text-c_icon_inverted`
									: `text-c_icon_inverted hover:bg-c_bg_tooltip hover:text-c_icon_inverted`)
						)}
						onClick={handleOnClick}
						onKeyDown={handleOnKeyDown}
						{...headlessTabProps}
					>
						<Icon icon={icon} className="flex w-full items-center p-2" variant="outline" />
					</button>
				</Tooltip>
				<div className="pointer-events-none absolute -top-full left-2">
					<Transition
						show={selected}
						className="transition-all"
						enter={tw`delay-[55ms]`}
						enterFrom={tw`scale-y-0 opacity-0`}
						enterTo={tw`scale-y-100 opacity-100`}
						leave={tw`duration-0`}
						leaveFrom={tw`opacity-100`}
						leaveTo={tw`opacity-0`}
					>
						<SelectDrop colorKey={isLightVariant ? "c_bg_03" : "c_bg_08"} />
					</Transition>
				</div>
			</div>
		);
	}
);
