import { mContactDef, mLeadDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

import { UserFilters } from "./UserFilters";
import { PATHS } from "../../../../../routes";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { SettingsContainer } from "../../../SettingsContainer";
import { useCallback, useEffect, useMemo } from "react";
import { EMPTY_FILTERS, mapToSearchQuery, RecordContextMenu, useInfiniteRecordSearch } from "../../../../records";
import { APP_CONFIG } from "../../../../../app/app_config";
import { rsr } from "@salesdesk/salesdesk-schemas";
import { ROW_IS_LOADING_PLACEHOLDER, Table } from "../../../../Table";
import { DebouncedSearchbar } from "../../../../inputs";
import { SkeletonTransition } from "../../../../../components/Skeleton/SkeletonTransition";
import { Spinner } from "@salesdesk/daisy-ui";
import {
	getBaseFilterQuery,
	generateColumns,
	generateRows,
	getFilteredFilterFields,
	SD_RECORD_COLUMN_ID,
} from "../utils";
import { RecordTableRow } from "../types";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { useUsersStateContext } from "../hooks/useUsersStateContext";
import { useTotalUsers } from "../hooks/useTotalUsers";
import { useGetObjectMap } from "../../../../objects/hooks";
import { CreateUserButton } from "./CreateUserButton";

interface UsersProps {
	isCustomersPage: boolean;
}

export function Users({ isCustomersPage }: UsersProps) {
	const objectIds = useMemo(
		() => (isCustomersPage ? [mContactDef.ID, mLeadDef.ID] : [mSalesDeskUserDef.ID]),
		[isCustomersPage]
	);

	const userObjectMap = useGetObjectMap(false, objectIds);

	const { urlState, propOnChange } = useUsersStateContext();
	const filter = useMemo(() => urlState.filter ?? EMPTY_FILTERS, [urlState.filter]);
	const sort = useMemo(() => urlState.sort ?? [], [urlState.sort]);
	const searchQuery = urlState.search;

	const { updateSearchParams, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage, sdRecords } =
		useInfiniteRecordSearch({ limit: APP_CONFIG.maxLocalSearchResults, sdObjectFilter: objectIds });

	const totalUsers = useTotalUsers(userObjectMap, objectIds, isCustomersPage);

	useEffect(() => {
		const query = getBaseFilterQuery(userObjectMap, isCustomersPage);
		if (filter?.filters.length) {
			const filterClauses = filter.filters.flatMap(mapToSearchQuery);
			if (filter.type === "AND") {
				filterClauses.forEach((clause) => query.and(clause));
			} else {
				filterClauses.forEach((clause) => query.or(clause));
			}
		}
		if (searchQuery) {
			query.and(rsr.matchAllPrefix(searchQuery));
		}
		updateSearchParams({
			query: query.buildQuery(),
			sort: sort?.map((value) => ({ [value.fieldId]: { order: value.order } })) ?? [],
		});
	}, [userObjectMap, filter, sort, searchQuery, updateSearchParams, isCustomersPage]);

	const columns = useMemo(() => {
		if (userObjectMap.size === 0) return [];
		return generateColumns(userObjectMap, objectIds, sort, (newSort) => propOnChange("sort", newSort));
	}, [userObjectMap, objectIds, sort, propOnChange]);

	const rows = useMemo(() => {
		if (userObjectMap.size === 0 || !sdRecords) return [];
		return generateRows(objectIds, userObjectMap, sdRecords);
	}, [objectIds, userObjectMap, sdRecords]);

	const skeletonPlaceholderRows = useMemo(
		() => Array.from({ length: 6 }, () => ({ [ROW_IS_LOADING_PLACEHOLDER]: true })),
		[]
	);

	const controlColumn = useMemo(
		() => ({
			width: 64,
			render: (row: RecordTableRow) => {
				const sdRecord = row[SD_RECORD_COLUMN_ID];
				if (!sdRecord || typeof sdRecord !== "object" || !("_id" in sdRecord) || row[ROW_IS_LOADING_PLACEHOLDER]) {
					return null;
				}
				return (
					<div className="ml-auto">
						<RecordContextMenu sdRecord={sdRecord} />
					</div>
				);
			},
		}),
		[]
	);

	const isInitialLoading = userObjectMap.size === 0 || isLoadingNewSearchParams;

	const filterFields = useMemo(() => getFilteredFilterFields(userObjectMap, objectIds), [userObjectMap, objectIds]);

	const onBottomReached = useCallback(() => {
		if (!isLoadingNewSearchParams && !isLoadingNextPage) {
			loadNextPage();
		}
	}, [isLoadingNewSearchParams, isLoadingNextPage, loadNextPage]);

	const pluralLabel = isCustomersPage ? "customers" : "users";

	return (
		<SettingsContainer
			breadcrumbs={[
				{
					node: "Access",
					icon: ICONS.lock,
				},
				isCustomersPage
					? {
							node: "Customers",
							link: PATHS.ACCESS_CUSTOMERS(),
						}
					: {
							node: "Users",
							link: PATHS.ACCESS_USERS(),
						},
			]}
		>
			<div className="flex h-full flex-col">
				<div className="flex w-full items-center justify-between gap-2 px-8 py-6">
					{totalUsers === undefined ? (
						<Skeleton className="h-4 w-32" />
					) : (
						<div className="text-label text-c_text_secondary">
							Total {pluralLabel}: {totalUsers}
						</div>
					)}
					<div className="flex gap-4">
						<UserFilters
							fields={filterFields}
							value={filter}
							onChange={(newFilter) => propOnChange("filter", newFilter)}
						/>
						<DebouncedSearchbar
							placeholder="Search..."
							defaultValue={searchQuery}
							onChange={(newSearch) => propOnChange("search", newSearch)}
							isCollapsible={false}
						/>
						<CreateUserButton objectIds={objectIds} />
					</div>
				</div>
				<SkeletonTransition className="flex w-full flex-col overflow-hidden px-8">
					<Table
						rows={isInitialLoading ? skeletonPlaceholderRows : rows}
						columns={columns}
						onBottomReached={onBottomReached}
						bottomOffset={200}
						controlColumn={controlColumn}
						tableFooter={
							isLoadingNextPage ? (
								<div className="my-6 flex w-full items-center justify-center">
									<Spinner size="md" />
								</div>
							) : undefined
						}
						containerRightPadding={0}
					/>
					{!isInitialLoading && !rows.length ? (
						<span className="text-body text-c_text_secondary my-8 text-center">
							{filter.filters.length || searchQuery
								? `No ${pluralLabel} match the selected filters.`
								: `There are no ${pluralLabel}.`}
						</span>
					) : null}
				</SkeletonTransition>
			</div>
		</SettingsContainer>
	);
}
