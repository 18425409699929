import { useCallback, useState } from "react";
import { matchPath } from "react-router-dom";

import {
	CHAT_CHANNEL_ARN_PARAM,
	RECORD_SIDE_PANEL_RECORD_ID_PARAM,
	SDRecord,
	TENANT_ROUTES,
} from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../auth";
import { useWorkspaceContext } from "../../workspaces";
import { useChimeMessagingContext } from "../components";
import { PATHS, tenantMatchRoute, useStableNavigate } from "../../../routes";
import { useToast } from "../../Toasts";

export function useOpenDMChannel() {
	const { chime } = useChimeMessagingContext();
	const principal = useWebPrincipal();
	const { workspaceId, previousOutsideWorkspacePathRef } = useWorkspaceContext();
	const navigate = useStableNavigate();

	const toast = useToast();
	const [isLoading, setIsLoading] = useState(false);

	const openDMChannel = useCallback(
		(recipientId: SDRecord["_id"], onSuccess?: () => void) => {
			if (!chime) {
				return;
			}

			setIsLoading(true);

			chime
				.createChannel(principal.TenantId, [principal.UserRecordId, recipientId])
				.then((channelArn) => {
					if (onSuccess) {
						onSuccess();
					}

					const previousOutsideWorkspacePath = previousOutsideWorkspacePathRef?.current;

					// Can't DM users within the workspace, meetings or the settings area
					if (workspaceId || isOutsideCRMPath(window.location.pathname)) {
						const newPath =
							previousOutsideWorkspacePath && !isOutsideCRMPath(previousOutsideWorkspacePath)
								? previousOutsideWorkspacePath
								: PATHS.ROOT();

						const url = new URL(newPath, window.location.origin);

						url.searchParams.delete(RECORD_SIDE_PANEL_RECORD_ID_PARAM); // Close any record side panel that's open since the chat panel is displayed under it
						url.searchParams.set(CHAT_CHANNEL_ARN_PARAM, channelArn);

						navigate(url.pathname + url.search);
						return;
					}

					// Otherwise adds the channel ARN to the current URL to open the messaging panel on the current page
					const searchParams = new URLSearchParams(window.location.search);
					searchParams.set(CHAT_CHANNEL_ARN_PARAM, channelArn);
					searchParams.delete(RECORD_SIDE_PANEL_RECORD_ID_PARAM); // Close any record side panel that's open since the chat panel is displayed under it
					navigate({ search: searchParams.toString() }, { replace: true });
				})
				.catch((error) => {
					console.error("Failed to create DM Channel", error);
					toast.trigger("error", "Failed to open DM Channel for user");
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[chime, principal.TenantId, principal.UserRecordId, workspaceId, previousOutsideWorkspacePathRef, navigate, toast]
	);

	return { openDMChannel: chime ? openDMChannel : undefined, isLoading };
}

function isOutsideCRMPath(path: string) {
	return tenantMatchRoute(TENANT_ROUTES.MEETING_ROUTE, path) || matchPath({ path: PATHS.SETTINGS(), end: false }, path);
}
