import { RefObject, useEffect, useState } from "react";
import { parseMonthKey } from "../utils";

export function useCalendarOffsetWhenScrolling(
	upcomingMeetingsRef: RefObject<HTMLElement>,
	monthKeyAttributeName: string,
	monthKeys: string[]
) {
	const [calendarOffset, setCalendarOffset] = useState<Date>();

	useEffect(() => {
		if (!monthKeys.length) return;
		setCalendarOffset(parseMonthKey(monthKeys[0]));
	}, [monthKeys]);

	useEffect(() => {
		if (!upcomingMeetingsRef.current) return;
		const intersectionPerMonthKey: Record<string, boolean> = {};
		const observer = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					const monthKey = entry.target.getAttribute(monthKeyAttributeName);
					if (monthKey) {
						intersectionPerMonthKey[monthKey] = entry.isIntersecting;
					}
				}
				const firstIntersectingMonthKey = monthKeys.find((monthKey) => intersectionPerMonthKey[monthKey]);
				if (firstIntersectingMonthKey) {
					setCalendarOffset(parseMonthKey(firstIntersectingMonthKey));
				}
			},
			{
				root: upcomingMeetingsRef.current,
				rootMargin: "0px",
				threshold: 0.01,
			}
		);
		for (const monthKey of monthKeys) {
			const monthElement = upcomingMeetingsRef.current.querySelector(`[${monthKeyAttributeName}="${monthKey}"]`);
			if (monthElement) {
				observer.observe(monthElement);
			}
		}
		return () => {
			observer.disconnect();
		};
	}, [monthKeyAttributeName, monthKeys, upcomingMeetingsRef]);

	return { calendarOffset, setCalendarOffset };
}
