import clsx from "clsx";
import { useCallback } from "react";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";

import {
	ObjectBoardView,
	useBoardPropOnChangeContext,
	useBoardRecordIdSelector,
	useBoardViewSelector,
} from "../../../../../ObjectBoard";
import { RecordSidePanel } from "../../../../../records";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { WorkspaceBoardTopBar } from "./WorkspaceBoardTopBar/WorkspaceBoardTopBar";
import { useWorkspaceNavCollapse } from "../../../../hooks/useWorkspaceNavCollapse";

export function WorkspaceObjectBoard() {
	const { workspaceRecord } = useWorkspaceContext();

	const boardPropOnChange = useBoardPropOnChangeContext();
	const recordId = useBoardRecordIdSelector();
	const boardView = useBoardViewSelector();

	const isCalendarView = boardView === BOARD_VIEW.CALENDAR;

	const closeRecordPanel = useCallback(() => {
		boardPropOnChange("recordId", undefined);
	}, [boardPropOnChange]);

	const showingSidePanel = recordId != null;
	useWorkspaceNavCollapse(showingSidePanel);

	return (
		<>
			<WorkspaceBoardTopBar />
			<div className="flex h-full w-full flex-col items-center">
				<div
					className={clsx("relative flex w-10/12 flex-col pb-12", isCalendarView && "min-h-full")}
					style={{ minHeight: isCalendarView ? "100%" : undefined }}
				>
					<div className="-mr-6 h-full">{workspaceRecord ? <ObjectBoardView /> : null}</div>
				</div>
			</div>
			{workspaceRecord ? (
				<RecordSidePanel recordId={recordId ? Number(recordId) : undefined} onClose={closeRecordPanel} />
			) : null}
		</>
	);
}
