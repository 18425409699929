import { getFirstName } from "../utils";
import { useConvertUserSDRecordsToAvatarUsers } from "./useConvertUserSDRecordsToAvatarUsers";
import { useGetCurrentUserRecord } from "./useGetCurrentUserRecord";

export function useCurrentUserFirstName() {
	const userRecord = useGetCurrentUserRecord();
	const userDetails = useConvertUserSDRecordsToAvatarUsers(userRecord);

	return getFirstName(userDetails?.fullName || "");
}
