import { useMemo } from "react";
import { JSONContent } from "@tiptap/core";

import { formatDateTime, formatSince } from "@salesdesk/salesdesk-utils";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { InlineUserController, InlineUser } from "../../users";
import { DisplayRichTextField } from "../../fields";

import { FabContainer } from "../../../components/FabContainer";
import { Button } from "@salesdesk/daisy-ui";
import { EmojiPopover } from "../../../components/EmojiPicker";
import { Reactions } from "../../../components/Reactions";
import { EditNote } from "./EditNote";
import { EditNoteSubmit, Note } from "../";
import { useGetContextWorkspaceId, WorkspaceName } from "../../workspaces";
import { getIsEditableInContext } from "../utils";
import { isJsonContent } from "../../../utils";

interface SingleNoteProps {
	note: Note;
	onToggleReaction?: (note: Note, emoji: string) => void;
	onEditClick?: (note: Note) => void;
	onDeleteClick?: (note: Note) => void;
	size?: "sm" | "md";
	canReact?: boolean;
	isEditMode?: boolean;
	onEditSubmit: EditNoteSubmit;
}

export function SingleNote({
	note,
	onToggleReaction,
	onEditClick,
	onDeleteClick,
	canReact = false,
	isEditMode = false,
	onEditSubmit,
}: SingleNoteProps) {
	const workspaceId = useGetContextWorkspaceId();
	const isEditableInContext = getIsEditableInContext(workspaceId, note);
	const showControls = isEditableInContext && (!!onEditClick || !!onDeleteClick);

	const canReactToThisNote = canReact && isEditableInContext;

	const formattedContent = useMemo(() => {
		if (isJsonContent(note.content)) {
			const jsonContent = JSON.parse(note.content) as JSONContent;
			return <DisplayRichTextField value={jsonContent} enableMentions={true} />;
		}
		return note.content;
	}, [note.content]);

	return (
		<FabContainer
			buttons={
				showControls ? (
					<>
						{canReactToThisNote && onToggleReaction ? (
							<EmojiPopover
								onEmojiSelect={({ native }) => {
									onToggleReaction(note, native);
								}}
							>
								<Button startIcon={ICONS.smiley} size="xs" variant="text" />
							</EmojiPopover>
						) : null}
						{isEditableInContext && onEditClick && note.editable ? (
							<Button startIcon={ICONS.simplePencil} size="xs" variant="text" onClick={() => onEditClick(note)} />
						) : null}
						{isEditableInContext && onDeleteClick && note.removable ? (
							<Button startIcon={ICONS.trash} size="xs" variant="danger_text" onClick={() => onDeleteClick(note)} />
						) : null}
					</>
				) : null
			}
		>
			<div className="flex flex-col gap-1">
				<div className="flex items-center gap-3">
					{note.author ? <InlineUser user={note.author} /> : <InlineUserController userId={note.authorId} />}
					<div className="bg-c_text_placeholder d h-1.5 w-1.5 rounded-full" />
					<div className="text-c_text_placeholder text-label-xs" title={formatDateTime(note.createdAt)}>
						{formatSince(note.createdAt)}
					</div>
					{note.edited ? <div className="text-c_text_disabled text-body-sm">Edited</div> : null}
				</div>
				{!workspaceId && note.workspaceId ? <WorkspaceName workspaceId={note.workspaceId} variant="note" /> : null}
				{isEditMode ? (
					<EditNote onCancel={() => onEditClick && onEditClick(note)} note={note} onEditSubmit={onEditSubmit} />
				) : (
					<div className="mb-1 ml-8 mr-1">{formattedContent}</div>
				)}
				{note.reactions?.length ? (
					<Reactions
						reactions={note.reactions}
						canReact={canReactToThisNote}
						onReactionSelect={(emoji) => {
							onToggleReaction?.(note, emoji);
						}}
					/>
				) : null}
			</div>
		</FabContainer>
	);
}
