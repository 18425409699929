import { ReactElement, forwardRef } from "react";
import clsx from "clsx";

import { Tooltip, tw } from "@salesdesk/daisy-ui";

interface StatusIndicatorButtonProps {
	first?: boolean;
	last?: boolean;
	color?: string;
	name: string;
	endIcon?: ReactElement;
	isCompleted?: boolean;
	onClick?: () => void;
	isActive: boolean;
}

export const StatusIndicatorButton = forwardRef<HTMLButtonElement, StatusIndicatorButtonProps>(
	(
		{ first, last, color = "#C1C2C6", name, endIcon, isCompleted, isActive, ...restProps }: StatusIndicatorButtonProps,
		ref
	) => {
		const svgElementClasses = clsx(
			tw`${isActive ? "text-c_action_02" : "text-c_border_regular"}`,
			tw`${isCompleted ? (last ? "fill-c_bg_02" : "fill-c_bg_03") : "fill-c_bg_01"}`,
			tw`group-hover:fill-c_bg_03`
		);

		return (
			<button
				ref={ref}
				className={clsx("group relative flex h-9 min-h-9 min-w-0 flex-1 flex-grow transition-all", !last && "mr-2")}
				{...restProps}
			>
				<div
					className={clsx(
						"z-1 absolute inset-0",
						"group-focus-visible:ring-c_action_focus pointer-events-none flex group-focus-visible:!z-[1000] group-focus-visible:ring group-focus-visible:ring-offset-1",
						first && "rounded-l-full group-hover:left-0",
						last && "left-5 rounded-r-full",
						!first && !last && "-right-5 group-hover:left-5",
						"group-hover:shadow-tooltip group-hover:right-0"
					)}
				/>
				{first ? null : (
					<svg
						width="20"
						height="36"
						viewBox="-1 0 20 36"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={clsx("relative", svgElementClasses)}
					>
						<path
							d="M19 35.5H1.55765C0.647341 35.5 0.210471 34.3826 0.879371 33.7652L14.4046 21.2804C16.591 19.2622 16.5417 15.7928 14.2989 13.8375L1.01168 2.25377C0.314996 1.64641 0.744553 0.500002 1.66881 0.500002L19 0.5"
							stroke="currentColor"
						/>
					</svg>
				)}
				<div
					className={clsx(
						"group-hover:bg-c_bg_03 flex h-full min-w-0 flex-1 items-center justify-center border-b border-t",
						first && "rounded-l-full border-l",
						last && "rounded-r-full border-r",
						isActive ? "border-c_action_02" : "border-c_border_regular",
						isCompleted ? (last ? "bg-c_bg_02" : "bg-c_bg_03") : "bg-c_bg_01"
					)}
				>
					<div className="flex min-w-4 items-center gap-1 px-2">
						<div className="border-c_bg_01 h-3 w-3 min-w-3 rounded-full border" style={{ background: color }} />
						<Tooltip placement="top" text={name} showOnTruncated>
							<div
								className={clsx(
									"text-label-sm text-c_text_secondary truncate",
									isCompleted && !last && "line-through hover:no-underline group-hover:no-underline"
								)}
							>
								{name}
							</div>
						</Tooltip>
						{endIcon ? endIcon : null}
					</div>
				</div>
				{last ? null : (
					<svg
						width="22"
						height="36"
						viewBox="1 0 22 36"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={clsx("absolute", svgElementClasses)}
						style={{ right: "-22px" }}
					>
						<path
							d="M0.600098 0.5H1.47164C2.19906 0.5 2.90171 0.764303 3.4488 1.24372L19.4978 15.3074C20.8359 16.4799 20.8654 18.5527 19.5612 19.7628L3.4632 34.6992C2.9084 35.2139 2.17953 35.5 1.42271 35.5H0.600098"
							stroke="currentColor"
						/>
					</svg>
				)}
			</button>
		);
	}
);
