import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { AssociationLinkingModalProvider } from "../../../../../../recordAssociations";
import { RecordDetailsProvider } from "../../RecordDetailsProvider";
import { RecordDetailTopBar } from "./RecordDetailTopBar";
import { useRecordDetailViewState } from "../hooks";
import { RecordSidePanel } from "../../RecordSidePanel";
import { RecordDetailsMainTab } from "./RecordDetailsMainTab";
import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../../components/PanelTabs";
import { mAssetDef, mDocDef } from "@salesdesk/salesdesk-model";
import { hasVideoAnalysis } from "../../../../../../files";
import { VideoAnalysisPanel } from "../../../../../../mediaAnalysis";
import { RecordViewTopLevelTab, topLevelTabIcons } from "../types";
import { RecordViewTopLevelTabsContext } from "../hooks/useRecordViewTopLevelTabsContext";
import { useSdFileByFieldName } from "../../../../../../files/hooks/useSdFileByFieldName";
import { useRecordFieldsAndTitle } from "../../common/RecordDetailsFields/hooks/useRecordFieldsAndTitle";
import { DocumentEditorPanel } from "../../../../../../document";
import { useLoadRecordDetailViewRecord } from "../hooks/useLoadRecordDetailViewRecord";
import {
	MIN_WORKSPACE_NAV_HEIGHT,
	useGetContextWorkspaceId,
	useWorkspaceNavCollapse,
} from "../../../../../../workspaces";

interface RecordDetailViewProps {
	recordId: number;
	objectSlug: string;
}

export function RecordDetailView({ recordId, objectSlug }: RecordDetailViewProps) {
	const workspaceId = useGetContextWorkspaceId();
	const inWorkspaceContext = workspaceId != null;

	const { recordDetailViewState, recordDetailPropOnChange } = useRecordDetailViewState();
	const [currentTopLevelTab, setCurrentTopLevelTab] = useState(recordDetailViewState.topLevelTab || 0);

	useEffect(() => {
		setCurrentTopLevelTab(recordDetailViewState.topLevelTab || 0);
	}, [recordDetailViewState.topLevelTab]);

	const { sdRecord, sdObject, isLoading } = useLoadRecordDetailViewRecord(recordId, objectSlug);

	const { title, recordFields } = useRecordFieldsAndTitle(sdRecord, sdObject);

	const closeRecordPanel = useCallback(() => {
		recordDetailPropOnChange("recordId", undefined);
	}, [recordDetailPropOnChange]);

	const { sdFile: assetFile } = useSdFileByFieldName(sdObject, sdRecord, mAssetDef.FILE_FIELD_NAME);

	const topLevelTabs = useMemo(() => {
		const tabs = [RecordViewTopLevelTab.recordDetails];

		if (sdObject && sdObject._id === mDocDef.ID) {
			tabs.unshift(RecordViewTopLevelTab.doc);
		}

		if (hasVideoAnalysis(assetFile)) {
			tabs.push(RecordViewTopLevelTab.analytics);
		}

		return tabs;
	}, [assetFile, sdObject]);

	const onRecordDetailsTab = currentTopLevelTab === topLevelTabs.indexOf(RecordViewTopLevelTab.recordDetails);

	const onTranscriptQueryChange = useCallback(
		(query: string) => {
			recordDetailPropOnChange("transcriptQuery", query);
		},
		[recordDetailPropOnChange]
	);

	const showTopLevelTabs = topLevelTabs.length > 1;

	const recordDetailsMainTab = (
		<RecordDetailsMainTab
			sdRecord={sdRecord}
			sdObject={sdObject}
			title={title}
			recordFields={recordFields}
			isLoading={isLoading}
			inWorkspace={inWorkspaceContext}
		/>
	);

	const showingSidePanel = recordDetailViewState.recordId != null;
	useWorkspaceNavCollapse(showingSidePanel);

	return (
		<RecordViewTopLevelTabsContext.Provider value={{ tabs: topLevelTabs, currentTab: currentTopLevelTab }}>
			<RecordDetailsProvider sdRecord={sdRecord} sdObject={sdObject} isLoading={isLoading}>
				<AssociationLinkingModalProvider workspaceId={workspaceId}>
					<div
						className={clsx("flex h-full flex-col justify-stretch", inWorkspaceContext && "mx-auto w-10/12 shrink-0")}
						style={
							inWorkspaceContext
								? {
										height: `calc(100% - ${MIN_WORKSPACE_NAV_HEIGHT - 1}px)`,
									}
								: undefined
						}
					>
						<RecordDetailTopBar />
						{showTopLevelTabs ? (
							<PanelTabs
								key={recordId}
								selectedIndex={currentTopLevelTab}
								onChange={(newTopLevelTab) => {
									setCurrentTopLevelTab(newTopLevelTab);
									recordDetailPropOnChange("topLevelTab", newTopLevelTab);
								}}
								defaultIndex={currentTopLevelTab}
							>
								<div className={clsx(!inWorkspaceContext && "mx-6", "mb-6 mt-3")}>
									<PanelTabList variant="primary">
										{topLevelTabs.map((tab) => (
											<PanelTab key={tab} icon={topLevelTabIcons[tab]}>
												{tab}
											</PanelTab>
										))}
									</PanelTabList>
								</div>
								<PanelTabPanels className="grow overflow-hidden">
									{topLevelTabs.map((tab) => {
										if (tab === RecordViewTopLevelTab.recordDetails) {
											return (
												<PanelTabPanel key={tab} className="h-full">
													{recordDetailsMainTab}
												</PanelTabPanel>
											);
										}

										if (tab === RecordViewTopLevelTab.doc) {
											return (
												<PanelTabPanel key={tab} className="h-full" unmount={false}>
													<DocumentEditorPanel
														key={sdRecord?._id}
														documentRecord={sdRecord}
														documentObject={sdObject}
														inWorkspaceContext={inWorkspaceContext}
													/>
												</PanelTabPanel>
											);
										}

										const panelTabClasses = clsx(!inWorkspaceContext && "mx-6", "h-full");

										return (
											<PanelTabPanel key={tab} className={panelTabClasses}>
												{assetFile ? (
													<VideoAnalysisPanel
														file={assetFile}
														collapsibleTranscript={false}
														withoutCardLayout={true}
														transcriptSearch={recordDetailViewState.transcriptQuery || ""}
														onTranscriptSearchChange={onTranscriptQueryChange}
													/>
												) : null}
											</PanelTabPanel>
										);
									})}
								</PanelTabPanels>
							</PanelTabs>
						) : (
							recordDetailsMainTab
						)}
					</div>
				</AssociationLinkingModalProvider>
				{onRecordDetailsTab ? (
					<RecordSidePanel
						recordId={recordDetailViewState.recordId ? Number(recordDetailViewState.recordId) : undefined}
						onClose={closeRecordPanel}
					/>
				) : null}
			</RecordDetailsProvider>
		</RecordViewTopLevelTabsContext.Provider>
	);
}
