import { forwardRef } from "react";
import clsx from "clsx";

import { SelectOption, SelectOptionId } from "../../../../../types";
import { SelectOptionItemVariant } from "../../../types";
import { SelectOptionItem } from "../../BaseSelect";
import { BaseTypeaheadButton, BaseTypeaheadButtonProps } from "./BaseTypeaheadButton";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";

interface TypeaheadButtonProps extends BaseTypeaheadButtonProps {
	placeholder?: string;
	displayValues?: SelectOption[];
	optionDisplayVariant?: SelectOptionItemVariant;
	isMultiselect?: boolean;
	removeValue?: (value: SelectOptionId) => void;
	onClear?: () => void;
}

export const TypeaheadButton = forwardRef<HTMLButtonElement, TypeaheadButtonProps>(
	(
		{
			isMultiselect,
			placeholder,
			optionDisplayVariant,
			displayValues,
			removeValue,
			disabled,
			value: query,
			onClear,
			isLoading,
			...remainingProps
		},
		ref
	) => {
		const typeaheadButtonProps = { value: query, disabled, onClear, isLoading, ...remainingProps };
		const hasDisplayValues = displayValues?.length;

		const placeholderComponent = placeholder ? (
			<div className="text-c_text_placeholder -ml-[18px]">{placeholder}</div>
		) : null;

		// Overrides the default display variant for multiselect as it requires pills to display
		// multiple options that can be dismissed
		const multiSelectDisplayVariant = optionDisplayVariant === "default" ? "pill" : optionDisplayVariant;

		if (isMultiselect) {
			return (
				<BaseTypeaheadButton
					ref={ref}
					{...typeaheadButtonProps}
					onlyDisplayInputOnFocus={Boolean(hasDisplayValues)}
					beforeInputSlot={
						hasDisplayValues
							? displayValues.map((displayValue, index) => {
									const id = displayValue.id;

									if (isLoading) {
										return <Skeleton key={id} className={clsx("h-7", index % 2 === 0 ? "w-28" : "w-32")} />;
									}

									return (
										<SelectOptionItem
											key={id}
											disabled={disabled}
											option={displayValue}
											variant={multiSelectDisplayVariant}
											onClose={removeValue ? () => removeValue(id) : undefined}
										/>
									);
							  })
							: null
					}
					afterInputSlot={!hasDisplayValues && !query ? placeholderComponent : null}
				/>
			);
		}

		return (
			<BaseTypeaheadButton
				ref={ref}
				{...typeaheadButtonProps}
				afterInputSlot={
					query ? null : hasDisplayValues ? (
						<div className="-ml-[18px]">
							{isLoading ? (
								<Skeleton className="h-7 w-28" />
							) : (
								<SelectOptionItem disabled={disabled} option={displayValues[0]} variant={optionDisplayVariant} />
							)}
						</div>
					) : (
						placeholderComponent
					)
				}
			/>
		);
	}
);
