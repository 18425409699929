import { useMemo } from "react";

import { SDObject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { asObjectSet, chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";

import {
	useBatchWatchRecordsMutation,
	useBatchUnwatchRecordsMutation,
	useWatchedRecordsQuery,
} from "../../../../records";
import { useToast } from "../../../../Toasts";
import { PopoverMenu } from "../../../../menu";
import { MAX_BATCH_SIZE } from "../utils";
import { useBulkEditContext } from "../hooks/useBulkEditContext";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { countPromiseResults } from "../../../../../utils";

interface BulkWatchButtonProps {
	sdObject?: SDObject;
}

export function BulkWatchButton({ sdObject }: BulkWatchButtonProps) {
	const { selectedRecords } = useBulkEditContext();

	const { data: watchedRecordIds } = useWatchedRecordsQuery();
	const [batchWatchRecords, { isLoading: isWatching }] = useBatchWatchRecordsMutation();
	const [batchUnwatchRecords, { isLoading: isUnwatching }] = useBatchUnwatchRecordsMutation();
	const toast = useToast();

	const isSaving = isWatching || isUnwatching;

	const allWatchedRecordIds = useMemo(() => asObjectSet(watchedRecordIds), [watchedRecordIds]);

	const [selectedRecordToWatch, selectedRecordToUnwatch] = useMemo(() => {
		const selectedRecordToWatch = [];
		const selectedRecordToUnwatch = [];
		for (const record of selectedRecords) {
			if (allWatchedRecordIds[record._id]) {
				selectedRecordToUnwatch.push(record._id);
			} else {
				selectedRecordToWatch.push(record._id);
			}
		}
		return [selectedRecordToWatch, selectedRecordToUnwatch];
	}, [selectedRecords, allWatchedRecordIds]);

	const onActionClick = (isWatch: boolean) => {
		const chunks = chunk(isWatch ? selectedRecordToWatch : selectedRecordToUnwatch, MAX_BATCH_SIZE);
		Promise.allSettled(
			chunks.map((recordIds) => (isWatch ? batchWatchRecords(recordIds) : batchUnwatchRecords(recordIds)).unwrap())
		).then((results) => {
			const [successes, fails] = countPromiseResults(results, chunks);
			toast.triggerMessage({
				type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
				messageKey: isWatch ? "record_batch_watch" : "record_batch_unwatch",
				messageParams: {
					records: `${successes} ${pluralizeWithS("record", successes)}`,
					total: String(fails + successes),
				},
			});
		});
	};

	const [watchLabel, unwatchLabel] = useMemo(() => {
		const recordsLabel = pluralizeWithS("record", selectedRecords.length);
		return [`Watch ${recordsLabel}`, `Unwatch ${recordsLabel}`];
	}, [selectedRecords]);

	if (!sdObject) {
		return null;
	}

	if (selectedRecordToWatch.length && selectedRecordToUnwatch.length) {
		return (
			<PopoverMenu
				menuContents={[
					{
						icon: ICONS.flag,
						iconVariant: "fill",
						text: watchLabel,
						variant: "primary_icon",
						onClick: () => {
							onActionClick(true);
						},
						type: "button",
					},
					{
						icon: ICONS.flag,
						iconVariant: "outline",
						text: unwatchLabel,
						variant: "primary_icon",
						onClick: () => {
							onActionClick(false);
						},
						type: "button",
					},
				]}
				placement="top-start"
			>
				<Button
					variant="text_inverted"
					startIcon={ICONS.flag}
					iconVariant="outline"
					size="sm"
					disabled={!selectedRecords.length || isSaving}
				/>
			</PopoverMenu>
		);
	}

	const isMainWatch = selectedRecordToWatch.length > 0;
	return (
		<Tooltip text={isMainWatch ? watchLabel : unwatchLabel} placement="top">
			<Button
				variant="text_inverted"
				startIcon={ICONS.flag}
				iconVariant={isMainWatch ? "fill" : "outline"}
				size="sm"
				disabled={!selectedRecords.length || isSaving}
				onClick={() => onActionClick(isMainWatch)}
			/>
		</Tooltip>
	);
}
