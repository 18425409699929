import { useMemo, useState } from "react";

import { mContactDef, mLeadDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import { AddRecordButton } from "../../../../WorkspaceResources";
import { EmptySection } from "../../EmptySection";
import { WorkspaceTeamMembers } from "./WorkspaceTeamMembers";
import { useGetContextWorkspaceId } from "../../../../../../hooks/useGetContextWorkspaceId";
import { useWorkspaceHasSharedLead } from "../../../hooks/useWorkspaceHasSharedLead";

interface WorkspaceTeamProps {
	isLoading?: boolean;
}

export function WorkspaceTeam({ isLoading }: WorkspaceTeamProps) {
	const workspaceId = useGetContextWorkspaceId();

	const alreadyHasSharedLead = useWorkspaceHasSharedLead(workspaceId);

	const teamMemberObjectIds = useMemo(() => {
		const ids = [mSalesDeskUserDef.ID, mContactDef.ID];

		if (!alreadyHasSharedLead) {
			ids.push(mLeadDef.ID);
		}

		return ids;
	}, [alreadyHasSharedLead]);

	const [customerUsersVisible, setCustomerUsersVisible] = useState(true);
	const [internalUsersVisible, setInternalUsersVisible] = useState(true);

	const bothVisible = customerUsersVisible && internalUsersVisible;
	const neitherVisible = !(customerUsersVisible || internalUsersVisible);

	const teamResourcesTitle = "Add team members from Resources";

	return (
		<div className="flex w-full flex-col gap-4">
			{isLoading ? (
				<Skeleton className="w-22 my-1.5 h-6" />
			) : (
				<div className="text-c_text_secondary text-h4 flex h-9 items-center justify-between">
					Our teams
					<AddRecordButton
						id="userflow-add-team-members"
						resourcesTitle={teamResourcesTitle}
						sdObjectIds={teamMemberObjectIds}
						resourcesOnly
					/>
				</div>
			)}
			<div className="relative flex w-full">
				<EmptySection
					show={neitherVisible}
					messageText="No team members yet, add one using the button below"
					buttonText="Add team members"
					sdObjectIds={teamMemberObjectIds}
					resourcesTitle={teamResourcesTitle}
					resourcesOnly
				/>
				<WorkspaceTeamMembers
					variant="customerUsers"
					isVisible={customerUsersVisible}
					onVisibilityChange={setCustomerUsersVisible}
					overlapping={bothVisible}
				/>
				<WorkspaceTeamMembers
					variant="internalUsers"
					isVisible={internalUsersVisible}
					onVisibilityChange={setInternalUsersVisible}
					overlapping={bothVisible}
				/>
			</div>
		</div>
	);
}
