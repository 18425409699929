import { PropsWithChildren } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

interface AiDisclaimerProps extends PropsWithChildren {
	className?: string;
}

export function AiDisclaimer({ children, className }: AiDisclaimerProps) {
	return (
		<div className={className}>
			{children}
			<div className="border-t-c_bg_disabled_02 text-c_text_placeholder mt-6 flex items-start gap-3 border-t py-3">
				<Icon icon={ICONS.magicWand} className="flex items-center" />
				<div className="text-body-sm">
					Meeting summary and Meeting minutes were generated by AI and might contain errors.
				</div>
			</div>
		</div>
	);
}
