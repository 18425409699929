import {
	DARK_BG_COLORS,
	DARK_TEXT_COLORS,
	ICON_BY_TYPE,
	LIGHT_BG_COLORS_BY_TYPE,
	LIGHT_TEXT_COLORS_BY_TYPE,
	ToastType,
	ToastVariant,
} from "../types";
import clsx from "clsx";
import { Icon } from "@salesdesk/daisy-ui";
import { ToastContext } from "../hooks/useToastContext";
import { DismissButton } from "./DismissButton";
import { HTMLAttributes, ReactNode } from "react";

export interface ToastProps extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
	type?: ToastType;
	variant?: ToastVariant;
	width?: number;
	dark?: boolean;
	onDismissClick?: () => void;
	message: ReactNode;
}

export function Toast({
	type = "info",
	variant = "default",
	dark = false,
	onDismissClick,
	width,
	message,
	...divProps
}: ToastProps) {
	const bgColor = dark ? DARK_BG_COLORS : LIGHT_BG_COLORS_BY_TYPE[type];
	const bgText = dark ? DARK_TEXT_COLORS : LIGHT_TEXT_COLORS_BY_TYPE[type];

	return (
		<ToastContext.Provider value={{ type, dark }}>
			<div
				{...divProps}
				className={clsx("shadow-popover text-label flex w-fit gap-4 rounded-md px-4 py-2", bgColor, bgText)}
				style={
					width ? { width: width + "px" } : { minWidth: variant === "default" ? "400px" : undefined, maxWidth: "670px" }
				}
			>
				<div className="flex w-full justify-stretch gap-3">
					<Icon icon={ICON_BY_TYPE[type]} className="my-1 flex h-6 items-center" />
					<div className="flex grow items-center">
						<div className="whitespace-pre-line">{message}</div>
					</div>
				</div>
				<DismissButton type={type} dark={dark} onClick={onDismissClick} />
			</div>
		</ToastContext.Provider>
	);
}
