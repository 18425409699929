import { NoMessagesView } from "./NoMessagesView";
import { NotificationsHeader } from "./NotificationsHeader";
import { useNotificationsContext } from "../hooks";
import { NotificationsList } from "./NotificationsList";
import { useCallback, useMemo } from "react";
import { MarkAllAsReadButton } from "./MarkAllAsReadButton";
import { PanelTab, PanelTabList, PanelTabPanel, PanelTabPanels, PanelTabs } from "../../../components/PanelTabs";
import { notificationTabs } from "../utils";

export function Notifications() {
	const { isLoading, notifications, unreadOnly, setUnreadOnly, notificationType, setNotificationType } =
		useNotificationsContext();
	const toggleAllUnread = useCallback(() => setUnreadOnly(!unreadOnly), [setUnreadOnly, unreadOnly]);

	const currentTabIndex = useMemo(
		() => notificationTabs.findIndex((tab) => tab.type === notificationType),
		[notificationType]
	);

	return (
		<div className="flex flex-col gap-2 overflow-y-hidden px-6">
			<NotificationsHeader unreadOnly={unreadOnly} toggleAllUnread={toggleAllUnread} />

			<PanelTabs
				selectedIndex={currentTabIndex}
				onChange={(tabIndex) => {
					const notificationType = notificationTabs[tabIndex]?.type;
					if (notificationType) {
						setNotificationType(notificationType);
					}
				}}
			>
				<PanelTabList>
					{notificationTabs.map((tab) => (
						<PanelTab key={tab.type}>{tab.label}</PanelTab>
					))}
				</PanelTabList>
				<PanelTabPanels className="flex w-full overflow-y-hidden">
					{notificationTabs.map((tab) => (
						<PanelTabPanel key={tab.type} className="flex w-full">
							<div className="flex w-full flex-col gap-2">
								<div className="flex w-full justify-end pr-0.5 pt-0.5">
									<MarkAllAsReadButton />
								</div>
								{!isLoading && notifications != null && notifications.length === 0 ? (
									<NoMessagesView unreadOnly={unreadOnly} showAll={() => setUnreadOnly(false)} tab={tab} />
								) : (
									<NotificationsList />
								)}
							</div>
						</PanelTabPanel>
					))}
				</PanelTabPanels>
			</PanelTabs>
		</div>
	);
}
