import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";
import { sdApiKeySchema } from "./sdApiKeySchema";

export const sdApiKeyCreateResponseSchema = asConst({
	title: "sdApiKeyCreateResponseSchema",
	description: "API key creation request response",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	required: [...sdApiKeySchema.required, "apiSecret"],
	properties: {
		...sdApiKeySchema.properties,
		apiSecret: {
			...stringSchema,
			title: "API Key Secret",
			description: "API Key Secret",
			maxLength: 100,
		},
	},
});
