import { asConst } from "json-schema-to-ts";
import { idSchema, stringSchema } from "../components";
import { SdEventSupertype } from "@salesdesk/salesdesk-model";
import { augmentedEventCreateRequestSchema } from "./augmentedEventCreateRequestSchema";

export const sdEventSchema = asConst({
	title: "SalesDesk Event",
	description: "An event recorded by SalesDesk",
	type: "object",
	additionalProperties: false,
	required: [...augmentedEventCreateRequestSchema.required, "internal_id", "supertype", "username"],
	properties: {
		...augmentedEventCreateRequestSchema.properties,
		internal_id: {
			title: "Internal ID",
			description: "Internally generated v4 UUID for an event",
			type: "string",
			pattern: "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
		},
		supertype: {
			// TODO: should become an enum
			title: "Event Supertype",
			description: "Supertype of the event",
			type: "string",
			enum: Object.values(SdEventSupertype),
		},
		username: {
			title: "Username",
			description: "Username of the user who caused the event",
			type: "string",
		},
		base_type: {
			...idSchema,
			title: "Base Type",
			description: "ID of the base object type for the record that's the subject of the event",
		},
		// TODO: This used to be populated but no longer is because tenant_id is instead. Remove it when we write the script to rename properties.
		_tenant: stringSchema,
	},
});
