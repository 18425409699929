import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { mAssetDef } from "@salesdesk/salesdesk-model";
import { rsr } from "@salesdesk/salesdesk-schemas";

import { debounce } from "../../../../../utils";
import { APP_CONFIG } from "../../../../../app/app_config";
import { WORKSPACE_OBJECT_BOARDS, useGetContextWorkspaceId } from "../../../../workspaces";
import { useInfiniteRecordSearch } from "../../../search";

export function useGlobalSearchbar() {
	const workspaceId = useGetContextWorkspaceId();

	const [searchBarText, setSearchBarText] = useState("");

	const { sdRecords, isLoadingNewSearchParams, updateSearchParams, resultParams, resultHighlights } =
		useInfiniteRecordSearch({
			limit: APP_CONFIG.maxGlobalSearchResults,
		});

	const searchRef = useRef<HTMLInputElement>(null);
	const comboBtn = useRef<HTMLButtonElement>(null);

	// Debounces the search query to prevent a search on every key press
	const updateSearchQuery = useMemo(
		() =>
			debounce((searchQuery: string) => {
				const query = rsr.query().and(rsr.equals("_deleted", false)).not(rsr.equals("isTemplate", true));

				if (searchQuery) {
					query.and(rsr.matchAllPrefix(searchQuery));
				}

				if (workspaceId !== undefined) {
					query
						.and(rsr.isSharedWithWorkspace(workspaceId))
						.and(
							rsr
								.subQuery()
								.or(rsr.equalsAnyOf("_objectDefId", WORKSPACE_OBJECT_BOARDS))
								.or(rsr.baseObjectId(mAssetDef.ID))
								.buildSubQuery()
						);
				}

				updateSearchParams({
					query: query.buildQuery(),
					highlight: searchQuery || undefined,
				});
			}, 300),
		[updateSearchParams, workspaceId]
	);

	const updateComboboxPopoverState = useCallback((open: boolean) => {
		if (!comboBtn.current) {
			return;
		}

		const currentlyOpen = comboBtn.current.dataset.headlessuiState === "open";
		if (currentlyOpen !== open) {
			comboBtn.current.click();
		}
	}, []);

	useEffect(() => {
		updateSearchQuery(searchBarText);
	}, [searchBarText, updateSearchQuery]);

	return {
		searchRef,
		comboBtn,
		searchBarText,
		setSearchBarText,
		updateComboboxPopoverState,
		isLoading: isLoadingNewSearchParams,
		searchResults: sdRecords,
		currentSearchText: resultParams?.highlight || "",
		resultHighlights,
	};
}
