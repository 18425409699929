import { useEffect, useMemo, useState } from "react";
import {
	addDays,
	addHours,
	addWeeks,
	eachDayOfInterval,
	endOfWeek,
	format,
	getDay,
	isSameMonth,
	isSameYear,
	isWithinInterval,
	startOfWeek,
	subWeeks,
} from "date-fns";

import { Field, RecordQueryClauses, RecordSubQueryClause, rsr } from "@salesdesk/salesdesk-schemas";

import { Timeline } from "../shared/Timeline";
import { DateColumn } from "../shared/DateColumn";
import { DateColumnHeaders } from "../shared/DateColumnHeaders";
import { generateDateTimeCalendarCards } from "../../utils/dateTimeCalendar";
import { generateDateCalendarCards } from "../../utils/dateCalendar";
import { CalendarCardDetails, CalendarEntry } from "../../types";
import { getUnixTimestamp, mapLocalDateTimeToUtcTimestamp } from "@salesdesk/salesdesk-utils";
import { isDateOnlyField, isRangeField } from "../../utils/calendarField";
import { sortCalendarEntries } from "../../utils";
import clsx from "clsx";

interface WeekViewProps {
	calendarDate: Date;
	calendarEntries: CalendarEntry[];
	updateCalendarFilter: (filter: RecordQueryClauses[]) => void;
	setTopBarHeading: (heading: string) => void;
	isLoading?: boolean;
	groupByField?: Field;
	onEntryCreate?: (initialValueMap: Record<string, unknown>) => void;
	isWorkspaceBoard?: boolean;
}

export function WeekView({
	calendarDate,
	calendarEntries,
	groupByField,
	updateCalendarFilter,
	setTopBarHeading,
	isLoading,
	onEntryCreate,
	isWorkspaceBoard,
}: WeekViewProps) {
	const [dateRange, setDateRange] = useState<Date[]>([]);
	const [groupedCalendarCards, setGroupedCalendarCards] = useState<CalendarCardDetails[][]>([]);

	const isRange = isRangeField(groupByField);
	const showingTime = !isDateOnlyField(groupByField);

	const viewInterval = useMemo(() => {
		return {
			start: startOfWeek(calendarDate),
			end: endOfWeek(calendarDate),
		};
	}, [calendarDate]);

	// Show skeleton loading when there are no cached entries in the current view interval
	const showSkeletonLoading = useMemo(() => {
		if (!isLoading || !groupByField) return false;
		return !calendarEntries.find((entry) => isWithinInterval(entry.start, viewInterval));
	}, [isLoading, viewInterval, calendarEntries, groupByField]);

	useEffect(() => {
		const { start, end } = viewInterval;

		if (groupByField?._id) {
			const fieldId = String(groupByField._id);

			// Extends the filter start and end to span 1 week before and after
			// to improve loading speeds when moving through the calendar
			const filterStart = getUnixTimestamp(subWeeks(start, 1));
			const filterEnd = getUnixTimestamp(addWeeks(end, 1));

			let subQuery: RecordSubQueryClause;

			if (isRange) {
				subQuery = rsr
					.subQuery()
					.and(rsr.range(`${fieldId}.start`, { lte: filterEnd }))
					.and(rsr.range(`${fieldId}.end`, { gte: filterStart }))
					.buildSubQuery();
			} else {
				subQuery = rsr
					.subQuery()
					.and(rsr.range(fieldId, { gte: filterStart, lte: filterEnd }))
					.buildSubQuery();
			}

			updateCalendarFilter([subQuery]);
		}

		setDateRange(eachDayOfInterval({ start, end }));

		let topBarHeading = "";

		if (isSameMonth(start, end)) {
			topBarHeading = format(start, "LLLL yyyy");
		} else if (isSameYear(start, end)) {
			topBarHeading = `${format(start, "LLLL")} - ${format(end, "LLLL yyyy")}`;
		} else {
			topBarHeading = `${format(start, "LLLL yyyy")} - ${format(end, "LLLL yyyy")}`;
		}

		setTopBarHeading(topBarHeading);
	}, [viewInterval, updateCalendarFilter, groupByField?._id, isRange, setTopBarHeading]);

	useEffect(() => {
		const entries = showSkeletonLoading ? generateSkeletonCalendarEntries(dateRange, showingTime) : calendarEntries;

		const groupedCards = showingTime
			? generateDateTimeCalendarCards(entries, dateRange)
			: generateDateCalendarCards(entries, dateRange);

		setGroupedCalendarCards(groupedCards);
	}, [calendarEntries, dateRange, showSkeletonLoading, showingTime]);

	const onDateColumnClick = useMemo(() => {
		if (!onEntryCreate || !groupByField) return undefined;
		return (date: Date) => {
			const minutes = date.getMinutes();
			const roundedMinutes = minutes >= 30 ? 30 : 0;
			const roundedDate = new Date(date.setMinutes(roundedMinutes));

			let defaultValue;

			const entryStartTime = mapLocalDateTimeToUtcTimestamp(roundedDate, !showingTime);

			if (isRange) {
				const entryEndTime = mapLocalDateTimeToUtcTimestamp(
					showingTime ? addHours(roundedDate, 1) : addDays(roundedDate, 1),
					!showingTime
				);

				defaultValue = { start: entryStartTime, end: entryEndTime };
			} else {
				defaultValue = entryStartTime;
			}

			onEntryCreate({ [String(groupByField._id)]: defaultValue });
		};
	}, [groupByField, isRange, onEntryCreate, showingTime]);

	return (
		<div className={clsx("z-10 flex h-full flex-col pb-6 pr-6", !isWorkspaceBoard && "relative overflow-auto")}>
			<DateColumnHeaders dateRange={dateRange} showingTime={showingTime} />
			<div className="flex min-w-fit flex-1">
				{showingTime ? <Timeline /> : null}
				{dateRange.map((date, index) => {
					const dateString = date.toDateString();
					return (
						<DateColumn
							key={dateString}
							date={date}
							calendarCards={groupedCalendarCards[index] || []}
							showingTime={showingTime}
							onClick={onDateColumnClick}
						/>
					);
				})}
			</div>
		</div>
	);
}

// Index 0 is Sunday
const DATE_ONLY_WEEKDAY_SKELETON_ENTRIES = [0, 6, 3, 5, 2, 4, 0];
const DATE_TIME_WEEKDAY_SKELETON_ENTRIES = [0, 2, 2, 3, 4, 2, 0];

export function generateSkeletonCalendarEntries(dateRange: Date[], showingTime = true): CalendarEntry[] {
	const unsortedEntries = dateRange.flatMap((date) => {
		const weekday = getDay(date);

		const entries: CalendarEntry[] = [];

		const totalEntries = (showingTime ? DATE_TIME_WEEKDAY_SKELETON_ENTRIES : DATE_ONLY_WEEKDAY_SKELETON_ENTRIES)[
			weekday
		];

		for (let i = 0; i < totalEntries; i++) {
			// Generates a seeded pseudo-random number based on the weekday
			// This ensures that the 'random' skeleton entries are the same for every week
			// Source: https://stackoverflow.com/a/19303725
			let random = Math.sin(weekday * totalEntries * (i + 3)) * 10000;
			random = random - Math.floor(random);

			// Generates a start time between 5 am and 8 pm
			const startHour = Math.floor(random * 16) + 5;

			const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startHour, 0);

			entries.push({
				id: random,
				name: `Loading...`,
				start,
				end: addHours(start, 1),
			});
		}
		return entries;
	});

	return sortCalendarEntries(unsortedEntries);
}
