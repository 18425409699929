import { SubmitHandler, useForm } from "react-hook-form";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { HasClaimsSection, OwnerTypeClaimsSection, RoleNameSectionRow } from "./sections";
import { RoleFormContext } from "../hooks/useRoleFormContext";
import { RoleFormData } from "../types";
import { objectClaimsSectionProps } from "../config/objectClaimsSectionProps";
import { userClaimsSectionProps } from "../config/userClaimsSectionProps";
import { recordClaimsSectionProps } from "../config/recordClaimsSectionProps";
import { WorkspaceSection } from "./sections/WorkspaceSection";
import { Button } from "@salesdesk/daisy-ui";
import { roleClaimsSectionProps } from "../config/roleClaimsSectionProps";
import { recordActionsClaimsSectionProps } from "../config/recordActionsClaimsSectionProps";
import { objectAssociationClaimsSectionProps } from "../config/objectAssociationClaimsSectionProps";
import { recordAssociationClaimsSectionProps } from "../config/recordAssociationClaimsSectionProps";
import { videoCallClaimsSectionProps } from "../config/videoCallClaimsSectionProps";
import { customerClaimsSectionProps } from "../config/customerClaimsSectionProps";
import { RoleSection } from "./sections/RoleSection";
import { NavigationBlockerDialog } from "../../../../../../Dialog";
import clsx from "clsx";

interface RoleFormProps {
	roleFormData: RoleFormData;
	canEdit: boolean;
	canDelete: boolean;
	onSubmit: SubmitHandler<RoleFormData>;
	isSubmitting: boolean;
	onDelete: () => void;
}

export function RoleForm({ roleFormData, canEdit, canDelete, onSubmit, isSubmitting, onDelete }: RoleFormProps) {
	const form = useForm<RoleFormData>({
		values: roleFormData,
		mode: "onChange",
	});

	const disableButtons = !form.formState.isDirty || isSubmitting;
	const showSaveBar = canDelete || canEdit;

	return (
		<RoleFormContext.Provider value={{ readOnly: !canEdit || isSubmitting, ...form }}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<div className={clsx("flex flex-col gap-8", showSaveBar && "pb-16")}>
					<RoleSection>
						<RoleNameSectionRow />
					</RoleSection>
					<RoleSection>
						<OwnerTypeClaimsSection claimConfig={recordClaimsSectionProps} />
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Record Associations"
							description="Actions they can perform on record associations."
							claimConfig={recordAssociationClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Record Actions"
							description="Actions they can perform on object records."
							claimConfig={recordActionsClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Customer Users"
							description="Actions they can perform on customer records."
							claimConfig={customerClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Video Calls"
							description="Actions they can perform during video calls."
							claimConfig={videoCallClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Object definition"
							description="Actions they can perform in the object manager."
							claimConfig={objectClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Association definition"
							description="Actions they can perform in the association manager."
							claimConfig={objectAssociationClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection>
						<HasClaimsSection
							title="Roles"
							description="Actions they can perform in the roles administrator."
							claimConfig={roleClaimsSectionProps}
						/>
					</RoleSection>
					<RoleSection hasBorder={roleFormData.isAdmin}>
						<HasClaimsSection
							title="Users"
							description="Actions they can perform in the users administrator."
							claimConfig={userClaimsSectionProps}
						/>
					</RoleSection>
					{roleFormData.isAdmin ? (
						<RoleSection hasBorder={false}>
							<WorkspaceSection />
						</RoleSection>
					) : null}
				</div>
				{showSaveBar ? (
					<div className="bg-c_bg_01 fixed bottom-0 w-[572px]">
						<div className="flex gap-2 pb-6 pt-4">
							{canDelete ? (
								<Button
									variant="danger_text"
									size="sm"
									disabled={isSubmitting}
									startIcon={ICONS.trash}
									onClick={onDelete}
								>
									Delete this role
								</Button>
							) : null}
							<div className="flex-auto"></div>
							{canEdit ? (
								<>
									<Button
										variant="secondary"
										size="sm"
										disabled={disableButtons}
										onClick={() => form.reset(roleFormData)}
									>
										Cancel
									</Button>
									<Button size="sm" disabled={disableButtons} type="submit" isLoading={isSubmitting}>
										Save Changes
									</Button>
								</>
							) : null}
						</div>
					</div>
				) : null}
			</form>
			<NavigationBlockerDialog
				title="Unsaved Changes"
				message="You have some unsaved changes. Are you sure you want to continue without saving?"
				shouldBlock={form.formState.isDirty}
			/>
		</RoleFormContext.Provider>
	);
}
