import clsx from "clsx";
import { tw } from "../../utils/tailwind-helpers";

type AlertBubbleVariant = "primary" | "secondary" | "tertiary";
type AlertBubbleSize = "sm" | "md" | "lg";

type SizeClasses = {
	[key in AlertBubbleSize]: {
		default: string;
		oneChar: string;
		moreThanOneChar: string;
	};
};

const sizeStyles: SizeClasses = {
	sm: {
		default: tw`h-1.5 text-[0px]`,
		oneChar: tw`w-1.5`,
		moreThanOneChar: tw`w-1.5`,
	},
	md: {
		default: tw`h-4 text-[10px] font-semibold`,
		oneChar: tw`w-4`,
		moreThanOneChar: tw`px-1`,
	},
	lg: {
		default: tw`text-label-sm`,
		oneChar: tw`w-5`,
		moreThanOneChar: tw`px-2`,
	},
};

interface AlertBubbleProps {
	alertCount: number;
	inline?: boolean;
	variant?: AlertBubbleVariant;
	size?: AlertBubbleSize;
	disabled?: boolean;
	showZero?: boolean;
}
export function AlertBubble({
	alertCount,
	inline = true,
	variant = "primary",
	size = "lg",
	disabled = false,
	showZero = false,
}: AlertBubbleProps) {
	let displayAlertAmount = alertCount === 0 && !showZero ? "" : `${alertCount}`;

	if (inline && alertCount > 99) {
		displayAlertAmount = "99+";
	}

	if (!inline && alertCount > 9) {
		displayAlertAmount = "9+";
	}

	const isPrimary = variant === "primary";
	const isSecondary = variant === "secondary";
	const isTertiary = variant === "tertiary";
	const sizeStyle = sizeStyles[size];

	if (!displayAlertAmount) {
		return null;
	}

	return (
		<div
			className={clsx(
				"flex shrink-0 items-center justify-center rounded-full",
				!inline && "absolute -right-1.5 -top-1",
				isPrimary && (disabled ? "bg-c_bg_disabled_02" : "bg-c_bg_02 text-c_text_primary"),
				isSecondary && (disabled ? "bg-c_bg_disabled_01" : "bg-c_action_01 text-c_text_inverted"),
				isTertiary && (disabled ? "bg-c_bg_01" : "bg-c_bg_01 text-c_text_primary"),
				sizeStyle.default,
				displayAlertAmount.length === 1 ? sizeStyle.oneChar : sizeStyle.moreThanOneChar
			)}
		>
			{displayAlertAmount}
		</div>
	);
}
