import { useEffect, useState } from "react";
import { LocalParticipant, LocalTrack, LocalTrackPublication, RemoteTrackPublication } from "twilio-video";
import { RemoteParticipant, RemoteTrack } from "twilio-video";

type Participant = LocalParticipant | RemoteParticipant;
type ParticipantTracks<T extends Participant> = T extends LocalParticipant ? LocalTrack : RemoteTrack;

type ParticipantTrackPublications<T extends Participant> = T extends LocalParticipant
	? LocalTrackPublication
	: RemoteTrackPublication;

export function useTracks<T extends Participant>(participant: T) {
	const [tracks, setTracks] = useState<ParticipantTracks<T>[]>([]);

	useEffect(() => {
		const subscribedTracks = Array.from(
			participant.tracks.values() as IterableIterator<ParticipantTrackPublications<T>>
		)
			.filter((trackPublication) => trackPublication.track !== null)
			.map((trackPublication) => trackPublication.track as ParticipantTracks<T>);

		setTracks(subscribedTracks);

		const handleTrackSubscribed = (track: ParticipantTracks<T>) => setTracks((prevTracks) => [...prevTracks, track]);
		const handleTrackUnsubscribed = (track: ParticipantTracks<T>) =>
			setTracks((prevTracks) => prevTracks.filter((t) => t !== track));

		participant.on("trackSubscribed", handleTrackSubscribed);
		participant.on("trackUnsubscribed", handleTrackUnsubscribed);
		return () => {
			participant.off("trackSubscribed", handleTrackSubscribed);
			participant.off("trackUnsubscribed", handleTrackUnsubscribed);
		};
	}, [participant]);

	return tracks;
}
