import { mAssetDef } from "../asset_def";
import { ASSET_IDS } from "../asset_constants";

export class mGeneralAssetDef extends mAssetDef {
	static CLASS_NAME = "mGeneralAssetDef";
	static NAME = "General Asset";
	static PLURAL_NAME = "General Assets";
	static ICON = "ph-folders";

	static ID = ASSET_IDS.GENERAL;

	static FORMAT_DESCRIPTION = "Image file required";

	constructor(ownerId) {
		super(mGeneralAssetDef.ID, ownerId);
	}
}
