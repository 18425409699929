import { useEffect, useState } from "react";

import { formatDate, formatDateTime } from "@salesdesk/salesdesk-utils";

import { DisplayPlaceholderField } from "../../DisplayPlaceholderField";
import { DisplayTextField } from "../../TextField";
import { DateTimeFieldVariant } from "../types";

interface DisplayDateTimeFieldProps {
	value?: string | number;
	placeholder?: string;
	variant?: DateTimeFieldVariant;
}

export function DisplayDateTimeField({
	value,
	placeholder,
	variant = DateTimeFieldVariant.DATE,
}: DisplayDateTimeFieldProps) {
	const [stringValue, setStringValue] = useState(() => convertDateValueToString(value, variant));

	useEffect(() => {
		setStringValue(convertDateValueToString(value, variant));
	}, [value, variant]);

	if (!stringValue) {
		return <DisplayPlaceholderField placeholder={placeholder} />;
	}

	return <DisplayTextField value={stringValue} />;
}

function convertDateValueToString(value: string | number | undefined, variant: DateTimeFieldVariant) {
	if (!value) {
		return "";
	}

	const valueAsDate = new Date(value);
	return variant === DateTimeFieldVariant.DATE ? formatDate(valueAsDate) : formatDateTime(valueAsDate);
}
