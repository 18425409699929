import { useCallback, useEffect, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";

import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Icon } from "@salesdesk/daisy-ui";

import { useGetObjectById } from "../../../../../../../hooks/useGetObjectById";
import { useGetObjectByIds } from "../../../../../../../hooks/useGetObjectByIds";
import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../../components/PanelTabs";
import { tw } from "../../../../../../../utils/tailwind-helpers";
import { Dialog, DialogContent, DialogHeader } from "../../../../../../Dialog";
import { WORKSPACE_ORIGIN_OBJECTS } from "../../../../../utils";
import { OriginRecordDetails } from "../../../types";
import { OriginRecordTable } from "./OriginRecordTable";
import { CreateOriginRecord } from "./CreateOriginRecord";
interface SelectWorkspaceOriginModalProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	setOriginRecordDetails: (details: OriginRecordDetails) => void;
}

export function SelectWorkspaceOriginModal({
	open,
	onOpenChange,
	setOriginRecordDetails,
}: SelectWorkspaceOriginModalProps) {
	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);
	const workspaceOriginObjects = useGetObjectByIds(WORKSPACE_ORIGIN_OBJECTS);

	const [selectedRecord, setSelectedRecord] = useState<SDRecord | null>(null);

	const [recordTabCounts, setRecordTabCounts] = useState<Record<number, number>>({});

	const [showNoRecordSelectedError, setShowNoRecordSelectedError] = useState(false);

	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [recordCreationLoading, setRecordCreationLoading] = useState(false);
	const [recordCreationObjectIds, setRecordCreationObjectIds] = useState<number[]>([]);

	const activeFormId = useMemo(() => {
		const currentSDObjectIdTab = workspaceOriginObjects[activeTabIndex]?._id;

		return recordCreationObjectIds.includes(currentSDObjectIdTab) ? generateFormId(currentSDObjectIdTab) : null;
	}, [workspaceOriginObjects, recordCreationObjectIds, activeTabIndex]);

	const onRecordCreate = useCallback(
		(originRecord: SDRecord, originRecordObject: SDObject) => {
			setOriginRecordDetails({ originRecord, originRecordObject });
		},
		[setOriginRecordDetails]
	);

	const handleNoResult = useCallback((objectId: number) => {
		setRecordCreationObjectIds((prevObjectIds) => {
			return [...prevObjectIds, objectId];
		});
	}, []);

	const updateRecordTabCount = useCallback((objectId: number, count: number) => {
		setRecordTabCounts((prevCounts) => ({ ...prevCounts, [objectId]: count }));
	}, []);

	useEffect(() => {
		if (selectedRecord) {
			setShowNoRecordSelectedError(false);
		}
	}, [selectedRecord]);

	const internalOnOpenChange = useCallback(
		(open: boolean) => {
			if (!open) {
				setRecordCreationObjectIds([]);
			}

			if (onOpenChange) {
				onOpenChange(open);
			}
		},
		[onOpenChange, setRecordCreationObjectIds]
	);

	if (!open || !workspaceObject || !workspaceOriginObjects.length) {
		return null;
	}

	const onNext = () => {
		if (!selectedRecord) {
			setShowNoRecordSelectedError(true);
			return;
		}

		const object = workspaceOriginObjects.find((object) => object._id === selectedRecord._objectDefId);

		if (!object) return;

		setOriginRecordDetails({ originRecord: selectedRecord, originRecordObject: object });
	};

	return (
		<Dialog open={open} onOpenChange={internalOnOpenChange}>
			<DialogContent>
				<div className="flex">
					<div className="flex h-[800px] max-h-full w-[850px] max-w-full flex-col gap-8">
						<DialogHeader
							title={`Create ${workspaceObject._displayName} from a record`}
							description={`Select an existing ${getWorkspaceOriginObjectNames(
								workspaceOriginObjects
							)} to base your new workspace on. This workspace will serve as a dedicated area for collaborating on the selected record`}
							onClose={() => internalOnOpenChange(false)}
						/>
						<PanelTabs onChange={setActiveTabIndex}>
							<PanelTabList>
								{workspaceOriginObjects.map((sdObject) => {
									const { _id: id, _displayName: displayName } = sdObject;

									return (
										<PanelTab count={recordTabCounts[id]} key={id}>
											{displayName}
										</PanelTab>
									);
								})}
							</PanelTabList>
							<PanelTabPanels className="-mt-2 flex h-full overflow-hidden">
								{workspaceOriginObjects.map((sdObject) => (
									<PanelTabPanel key={sdObject._id} unmount={false} className="flex w-full overflow-auto">
										{recordCreationObjectIds.includes(sdObject._id) ? (
											<CreateOriginRecord
												onRecordCreate={onRecordCreate}
												formId={generateFormId(sdObject._id)}
												sdObject={sdObject}
												onLoading={setRecordCreationLoading}
											/>
										) : (
											<OriginRecordTable
												sdObject={sdObject}
												selectedRecord={selectedRecord}
												setSelectedRecord={setSelectedRecord}
												updateRecordCount={updateRecordTabCount}
												onNoResult={handleNoResult}
											/>
										)}
									</PanelTabPanel>
								))}
							</PanelTabPanels>
						</PanelTabs>
						<div className="flex items-center justify-end gap-3">
							<Transition
								show={showNoRecordSelectedError}
								className={`transition-opacity duration-75`}
								enterFrom={tw`opacity-0`}
								enterTo={tw`opacity-100`}
								leaveFrom={tw`opacity-100`}
								leaveTo={tw`opacity-0`}
							>
								<p className="text-c_danger_01 text-label flex items-center justify-center gap-2 align-middle">
									<Icon icon={ICONS.warningCircle} className="flex" />
									<span>Please select an origin record for the {workspaceObject._displayName}</span>
								</p>
							</Transition>
							<Button variant="secondary" onClick={() => internalOnOpenChange(false)}>
								Cancel
							</Button>
							<Button
								type={activeFormId ? "submit" : "button"}
								form={activeFormId ?? undefined}
								onClick={activeFormId ? undefined : onNext}
								isLoading={recordCreationLoading}
							>
								Next
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

// Gets the workspace origin object names in the format "object1, object2 or object3"
function getWorkspaceOriginObjectNames(objects: SDObject[]): string {
	const names = objects.map((object) => object._displayName);
	if (names.length === 0) return "";
	if (names.length === 1) return names[0];
	return `${names.slice(0, -1).join(", ")} or ${names[names.length - 1]}`;
}

function generateFormId(objectId: number) {
	return `FORM_ID_${objectId}`;
}
