import { AudioTrack } from "twilio-video";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useAudioTrackVolume } from "../../hooks/useAudioTrackVolume";
import { VolumeBar } from "./VolumeBar";
import { Icon } from "@salesdesk/daisy-ui";

interface AudioMeterProps {
	audioTrack?: AudioTrack;
}

export function AudioMeter({ audioTrack }: AudioMeterProps) {
	const { volume, isTrackEnabled } = useAudioTrackVolume(audioTrack);

	return (
		<div
			className={clsx(
				isTrackEnabled ? "bg-c_brand_primary text-c_text_inverted" : "text-c_icon_inverted bg-c_bg_tooltip",
				"flex h-7 w-7 items-center justify-center gap-[1px] rounded-full transition-colors"
			)}
		>
			{isTrackEnabled ? (
				<>
					<VolumeBar volume={volume} dampening={0.8} maxHeight={15} />
					<VolumeBar volume={volume} dampening={2.1} />
					<VolumeBar volume={volume} dampening={1.5} maxHeight={16} />
					<VolumeBar volume={volume} dampening={0.7} maxHeight={14} />
				</>
			) : (
				<Icon icon={ICONS.microphoneOff} size="sm" />
			)}
		</div>
	);
}
