import { useEffect, useRef } from "react";

import { ControlMessage, useVideoCallLogicContext } from "../../../VideoCallProvider";

enum RecordingControlMessageType {
	START_REQUEST = "Recording.StartRequest",
	START_RESPONSE = "Recording.StartResponse",
}

interface RecordingControlMessage extends Omit<ControlMessage, "type"> {
	type: RecordingControlMessageType;
	startTime?: number;
}

/**
 * Syncs the start time of a recording between the call participants.
 *
 * Participants that have the recording start time will send it through the
 * control track when requested by partipants which don't have the recording start
 * time.
 */
export function useSyncedRecordingStartTime(isRecording: boolean, isRecorder: boolean) {
	const recordingStartTimeRef = useRef<null | number>(null);
	const { sendControlMessage, subscribeToControlMessages } = useVideoCallLogicContext();

	useEffect(() => {
		return subscribeToControlMessages((_: string, message: ControlMessage) => {
			if (message.type === RecordingControlMessageType.START_RESPONSE && !recordingStartTimeRef.current) {
				recordingStartTimeRef.current = (message as RecordingControlMessage)?.startTime || null;
			} else if (
				message.type === RecordingControlMessageType.START_REQUEST &&
				recordingStartTimeRef.current &&
				sendControlMessage
			) {
				sendControlMessage({
					type: RecordingControlMessageType.START_RESPONSE,
					startTime: recordingStartTimeRef.current,
				} as RecordingControlMessage);
			}
		});
	}, [subscribeToControlMessages, sendControlMessage, recordingStartTimeRef]);

	// Retrieve recording start time from other participants in the call, makes the request
	// once a second through the control track until the recording start time is retrieved
	// (or the recording stops).
	useEffect(() => {
		let requestInterval: ReturnType<typeof setInterval> | undefined;

		if (!isRecording || recordingStartTimeRef.current) {
			return;
		}

		if (isRecorder) {
			recordingStartTimeRef.current = Date.now();
		} else if (sendControlMessage) {
			const requestRecordingStartTime = () => {
				if (recordingStartTimeRef.current) {
					clearInterval(requestInterval);
					return;
				}

				sendControlMessage({ type: RecordingControlMessageType.START_REQUEST });
			};

			requestRecordingStartTime();
			requestInterval = setInterval(requestRecordingStartTime, 1000);
		}

		return () => {
			clearInterval(requestInterval);
			recordingStartTimeRef.current = null;
		};
	}, [isRecording, isRecorder, sendControlMessage, recordingStartTimeRef]);

	return recordingStartTimeRef;
}
