import { createContext, RefObject, useContext } from "react";

import { FilePreviewVariant } from "../../../types";

export interface IVideoPlayerContext {
	variant: FilePreviewVariant;
	isPlaying?: boolean;
	isLoading?: boolean;
	errorMessage?: string;
	videoDuration: number;
	currentVideoTime: number;
	updateIsPlaying: (isPlaying?: boolean) => void;
	seekToVideoTime: (currentTime: number, noEvent?: boolean) => void;
	toggleMute: () => void;
	volume: number;
	updateVolume: (volume: number) => void;
	onOpenPreviewClick?: () => void;
	videoSpeed: number;
	updateVideoSpeed: (speed: number) => void;
	toggleFullScreen: () => void;
	isFullscreen: boolean;
	innerVideoRef: RefObject<HTMLVideoElement>;
}

export const VideoPlayerContext = createContext<IVideoPlayerContext | null>(null);

export const useVideoPlayerContext = () => {
	const context = useContext(VideoPlayerContext);

	if (context == null) {
		throw new Error("Video player components must be wrapped in <VideoPlayer />");
	}

	return context;
};
