import { Spinner, tw } from "@salesdesk/daisy-ui";
import { useImportContext } from "../../hooks/useImportContext";
import { ImportCount } from "./ImportCount";
import { ImportRecordTypeIcon } from "./ImportRecordTypeIcon";
import { ImportJob, ImportPreviewRecordType } from "@salesdesk/salesdesk-schemas";
import clsx from "clsx";
import { isCompleteOrFailed } from "../../utils";

interface ImportCountsProps {
	importJob: ImportJob | undefined;
}

export function ImportCounts({ importJob }: ImportCountsProps) {
	const { sdObject } = useImportContext();

	const baseClassName = tw`bg-c_bg_04 px-18 rounded-card flex w-full py-6`;

	if (!importJob) {
		return (
			<div className={clsx(baseClassName, "justify-center")}>
				<div className="flex items-center gap-1">
					<Spinner size="sm" />
					<div className="text-c_text_secondary">Calculating...</div>
				</div>
			</div>
		);
	}

	return (
		<div className={clsx(baseClassName, "justify-between")}>
			<ImportCount
				icon={<ImportRecordTypeIcon type={ImportPreviewRecordType.Create} />}
				count={importJob.createdCount}
				description={importJob.dryRun ? `${sdObject._pluralName} will be created` : `${sdObject._pluralName} created`}
			/>
			<ImportCount
				icon={<ImportRecordTypeIcon type={ImportPreviewRecordType.Patch} />}
				count={importJob.patchedCount}
				description={importJob.dryRun ? `${sdObject._pluralName} will be updated` : `${sdObject._pluralName} updated`}
			/>
			<ImportCount
				count={importJob.errorCount}
				description={
					importJob.dryRun ? `${sdObject._pluralName} contain errors` : `${sdObject._pluralName} failed import`
				}
				isError
				downloadLink={isCompleteOrFailed(importJob) ? importJob.errorFileSignedUrl : undefined}
			/>
		</div>
	);
}
