import { UserLastActiveResponse } from "@salesdesk/salesdesk-schemas";
import { SDApi } from "../../api";

export const usersApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getLastActive: builder.query<UserLastActiveResponse, { username: string }>({
				query: ({ username }) => ({
					url: `users/${username}/last-active`,
					method: "GET",
				}),
			}),
		};
	},
});

export const { useGetLastActiveQuery } = usersApi;
