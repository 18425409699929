import { FIELD_TYPES } from "../utils";
import { mFloatFieldDef } from "./float_field_def";
import { CURRENCIES_DATA } from "@salesdesk/salesdesk-model";

export class mCurrencyValueFieldDef extends mFloatFieldDef {
	static FORMAT_RULE = `This is not a valid currency format`;

	_currency: string;

	constructor(id: string | number) {
		super(id);
		this._icon = FIELD_TYPES.CURRENCY_VALUE.icon;
		this._currency = CURRENCIES_DATA[0].id;
	}

	override get type(): string {
		return FIELD_TYPES.CURRENCY_VALUE.name;
	}

	get currency() {
		return this._currency;
	}

	set currency(currency) {
		this._currency = currency;
	}

	override supportsSummation() {
		return true;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		const error = super.validate(value, isTemplate);
		if (error) return error;

		const currencyOption = CURRENCIES_DATA.find((currency) => currency.id === this.currency);
		if (!currencyOption) throw new Error(`Currency '${this.currency}' not found`);
		const [, decimalValue] = String(Number(value)).split(".");
		if (decimalValue && decimalValue.length > currencyOption.decimal_digits) {
			return `Currency '${currencyOption.name}' should have ${currencyOption.decimal_digits} decimal digits`;
		}
		return undefined;
	}

	override supportsFormatDescription() {
		return false;
	}
}
