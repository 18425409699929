import { useCallback } from "react";

import { RecordSidePanel } from "../../../../../../records";

import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";
import { useWorkspaceNavCollapse } from "../../../../../hooks/useWorkspaceNavCollapse";
import { InternalWorkspaceAreaContainer } from "../../../../InternalWorkspaceAreaContainer";
import { useWorkspaceResourcesState } from "../../hooks/useWorkspaceResourcesState";
import { InternalAreaHeader } from "../../../common/InternalAreaHeader";
import { WorkspaceResourcesTabs } from "./WorkspaceResourcesTabs";

export function WorkspaceResources() {
	const { workspaceRecord, workspaceObject } = useWorkspaceContext();
	const { workspaceResourcesState, workspaceResourcesPropOnChange } = useWorkspaceResourcesState();

	const onTabChange = useCallback(
		(newTab: number) => workspaceResourcesPropOnChange("tab", newTab),
		[workspaceResourcesPropOnChange]
	);

	const closeRecordPanel = useCallback(() => {
		workspaceResourcesPropOnChange("recordId", undefined);
	}, [workspaceResourcesPropOnChange]);

	const showingSidePanel = workspaceResourcesState.recordId != null;
	useWorkspaceNavCollapse(showingSidePanel);

	return (
		<InternalWorkspaceAreaContainer>
			<div className="mx-auto flex w-10/12 flex-col pb-12">
				<InternalAreaHeader height={166} pageName="Resources">
					Manage and share records linked to this {workspaceObject?._displayName ?? "workspace"} with customer users.
				</InternalAreaHeader>
				<WorkspaceResourcesTabs currentTab={workspaceResourcesState.tab || 0} onTabChange={onTabChange} />
				{workspaceRecord ? (
					<RecordSidePanel recordId={workspaceResourcesState.recordId ?? undefined} onClose={closeRecordPanel} />
				) : null}
			</div>
		</InternalWorkspaceAreaContainer>
	);
}
