import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";

import { tw } from "../../../utils/tailwind-helpers";
import { IconSize, LinkProps, LinkVariant } from "../types";

type VariantClasses = { [key in LinkVariant]: string };

const variantClasses: VariantClasses = {
	action: tw`text-c_action_01 hover:text-c_action_03 focus-visible:ring-c_action_focus`,
	success: tw`text-c_success_01 hover:text-c_success_03 focus-visible:ring-c_success_focus`,
	danger: tw`text-c_danger_01 hover:text-c_danger_03 focus-visible:ring-c_danger_focus`,
	warning: tw`text-c_warning_01 hover:text-c_warning_03 focus-visible:ring-c_warning_focus`,
	secondary: tw`text-c_text_secondary hover:text-c_text_secondary focus-visible:ring-c_secondary_focus`,
	inverted: tw`text-c_text_inverted hover:text-c_text_inverted focus-visible:ring-c_inverted_focus`,
};

type SizeClasses = { [key in IconSize]: string };
const sizeClasses: SizeClasses = {
	base: tw`text-link px-0.5 m-0.5 gap-2`,
	sm: tw`text-link-sm px-0.5 m-0.5 gap-2`,
	xs: tw`text-link-sm gap-1`,
};

export function Link({
	variant = "action",
	size = "base",
	text,
	startIcon,
	endIcon,
	disabled = false,
	invertUnderline = false,
	inline = false,
	...routerLinkProps
}: LinkProps) {
	const variantClass = variantClasses[variant];
	const sizeClass = sizeClasses[size];

	const startIconControl = (
		<Icon icon={startIcon} size={size === "xs" ? "sm" : "base"} className={clsx(inline && "align-middle")} />
	);
	const endIconControl = (
		<Icon icon={endIcon} size={size === "xs" ? "sm" : "base"} className={clsx(inline && "align-middle")} />
	);

	const defaultClass = !inline && tw`inline-flex items-center`;

	return !disabled ? (
		<RouterLink
			{...routerLinkProps}
			className={clsx(
				defaultClass,
				variantClass,
				sizeClass,
				"focus-visible:rounded-minimal group/link focus-visible:ring"
			)}
			rel={routerLinkProps.target === "_blank" ? "noopener noreferrer" : ""}
			onClick={(e) => {
				// In case its inside a Disclosure.Button
				e.stopPropagation();
			}}
			onKeyDown={(e) => {
				// In case its inside a Disclosure.Button
				e.stopPropagation();
			}}
		>
			{startIconControl}{" "}
			<span
				className={
					invertUnderline ? "no-underline group-hover/link:underline" : "underline group-hover/link:no-underline"
				}
			>
				{text}
			</span>
			{endIconControl}
		</RouterLink>
	) : (
		<span className={clsx(defaultClass, sizeClass, "text-c_text_disabled")} role="link" aria-disabled={true}>
			{startIconControl} <span>{text}</span>
			{endIconControl}
		</span>
	);
}
