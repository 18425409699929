import { SdEventType } from "@salesdesk/salesdesk-model";

export const videoEventsWithTimecode = [
	SdEventType.FILE_PLAYED,
	SdEventType.FILE_PAUSED,
	SdEventType.FILE_SEEKED,
] as const;

export const videoEvents = [...videoEventsWithTimecode, SdEventType.FILE_ENDED] as const;

export type VideoEvent = (typeof videoEvents)[number];
