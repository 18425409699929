import { useCallback, useState } from "react";
import { LocalVideoTrack } from "twilio-video";

import {
	DEFAULT_VIDEO_CONSTRAINTS,
	SELECTED_VIDEO_INPUT_KEY,
	useDevices,
	useMediaStreamTrack,
	useVideoCallMediaContext,
} from "../../../VideoCallProvider";
import { DeviceSelect } from "./DeviceSelect";

export function CameraSelect() {
	const { videoInputDevices } = useDevices();
	const { localTracks } = useVideoCallMediaContext();

	const localVideoTrack = localTracks.find((track) => track.kind === "video") as LocalVideoTrack | undefined;
	const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
	const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
		window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
	);
	const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

	const replaceTrack = useCallback(
		(newDeviceId: string) => {
			if (newDeviceId === localVideoInputDeviceId) return;

			// Here we store the device ID in the component state. This is so we can re-render this component display
			// to display the name of the selected device when it is changed while the users camera is off.
			setStoredLocalVideoDeviceId(newDeviceId);
			window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
			localVideoTrack?.restart({
				...(DEFAULT_VIDEO_CONSTRAINTS as NonNullable<unknown>),
				deviceId: { exact: newDeviceId },
			});
		},
		[localVideoInputDeviceId, localVideoTrack]
	);

	return (
		<DeviceSelect
			devices={videoInputDevices}
			currentDeviceId={localVideoInputDeviceId}
			onDeviceChange={replaceTrack}
			name={"camera"}
		/>
	);
}
