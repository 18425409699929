import { PropsWithChildren, useState, useCallback } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { AssociationLinkingContext } from "../hooks/useAssociationLinkingContext";
import { AssociationLinkingModal } from "./AssociationLinkingModal";
import { LinkingModalTabs } from "../types";
import { DirectedSDObjectAssociation } from "../../../hooks";

interface AssociationLinkingModalProviderProps {
	workspaceId?: number;
}

export function AssociationLinkingModalProvider({
	children,
	workspaceId,
}: PropsWithChildren<AssociationLinkingModalProviderProps>) {
	const [initialModalTab, setInitialModalTab] = useState<LinkingModalTabs>(LinkingModalTabs.linking);

	const [sourceLinkingRecord, setSourceLinkingRecord] = useState<SDRecord | undefined>();
	const [connectedAssociationObject, setConnectedAssociationObject] = useState<SDObject>();
	const [objectAssociation, setObjectAssociation] = useState<DirectedSDObjectAssociation>();

	const setAssociationLinkingDetails = useCallback(
		(sourceLinkingRecord?: SDRecord, associationObject?: SDObject, objectAssociation?: DirectedSDObjectAssociation) => {
			setSourceLinkingRecord(sourceLinkingRecord);
			setConnectedAssociationObject(associationObject);
			setObjectAssociation(objectAssociation);
		},
		[]
	);

	return (
		<AssociationLinkingContext.Provider
			value={{
				initialModalTab,
				setInitialModalTab,
				setAssociationLinkingDetails,
				workspaceId,
			}}
		>
			{children}
			<AssociationLinkingModal
				objectAssociation={objectAssociation}
				sourceLinkingRecord={sourceLinkingRecord}
				connectedAssociationObject={connectedAssociationObject}
				workspaceId={workspaceId}
				initialTab={initialModalTab}
			/>
		</AssociationLinkingContext.Provider>
	);
}
