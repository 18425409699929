import { useRoleFormContext } from "../../hooks/useRoleFormContext";
import { FormFieldSet } from "../../../../../../../forms";
import { EditTextField } from "../../../../../../../fields";
import { ICONS } from "@salesdesk/salesdesk-ui";

export function RoleNameSectionRow() {
	const { readOnly, control } = useRoleFormContext();

	return (
		<div className="w-full max-w-[285px]">
			<FormFieldSet
				control={control}
				name="name"
				label="Name"
				labelIcon={ICONS.text}
				required
				rules={{ required: "Name is required" }}
			>
				{({ field, fieldState: { error } }) => (
					<EditTextField
						title="Name"
						name={field.name}
						value={String(field.value)}
						onChange={field.onChange}
						maxLength={100}
						type="text"
						hasError={Boolean(error?.message)}
						disabled={readOnly}
						autoFocus
					/>
				)}
			</FormFieldSet>
		</div>
	);
}
