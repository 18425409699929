import { PropsWithChildren, useCallback } from "react";

import { getChimeChannelIdFromChannelArn } from "@salesdesk/salesdesk-schemas";

import { useLazyGetMyChimeChannelReadonlyCredentialsQuery } from "../../../../../auth/api/authApi";
import { ChimeMessagingProvider } from "./ChimeMessagingProvider";

interface ReadOnlyCredentialsChimeMessagingProviderProps {
	channelArn: string;
}

export function ReadOnlyCredentialsChimeMessagingProvider({
	channelArn,
	children,
}: PropsWithChildren<ReadOnlyCredentialsChimeMessagingProviderProps>) {
	const [getMyChimeChannelReadonlyCredentials] = useLazyGetMyChimeChannelReadonlyCredentialsQuery();

	const credentialsProvider = useCallback(() => {
		const channelId = getChimeChannelIdFromChannelArn(channelArn);
		return getMyChimeChannelReadonlyCredentials(channelId).unwrap();
	}, [channelArn, getMyChimeChannelReadonlyCredentials]);

	return (
		<ChimeMessagingProvider isReadOnly credentialsProvider={credentialsProvider}>
			{children}
		</ChimeMessagingProvider>
	);
}
