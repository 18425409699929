import { useRef, useState, useCallback, FormEvent, useEffect } from "react";
import { JSONContent } from "@tiptap/core";

import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Comment } from "@salesdesk/salesdesk-schemas";

import { RichTextInput } from "../../../../../../../inputs";

interface EditCommentProps {
	comment: Comment;
	onEditSubmit?: (editedComment: JSONContent) => void;
	onCancel?: () => void;
}

export function EditComment({ comment, onCancel, onEditSubmit }: EditCommentProps) {
	const initialCommentJSONContent = JSON.parse(comment.message);

	const [jsonContent, setJsonContent] = useState<JSONContent>(initialCommentJSONContent);

	const inputRef = useRef<HTMLDivElement>(null);
	const hasBeenChanged = initialCommentJSONContent === jsonContent;

	const handleSubmit = useCallback(
		(e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
			e.preventDefault();
			onEditSubmit?.(jsonContent);
		},
		[onEditSubmit, jsonContent]
	);

	useEffect(() => {
		const inputElement = inputRef.current;
		if (!inputElement) return;
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Enter" && e.metaKey) {
				handleSubmit(e);
			} else if (e.key === "Escape") {
				e.preventDefault();
				onCancel?.();
			}
		};
		inputElement.addEventListener("keydown", handleKeyDown);
		return () => {
			inputElement.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleSubmit, onCancel]);

	useEffect(() => {
		inputRef.current?.click();
	}, []);

	return (
		<form className="flex max-h-64" onSubmit={handleSubmit}>
			<RichTextInput
				ref={inputRef}
				onChange={setJsonContent}
				hideToolbar
				placeholder="Edit your comment"
				value={jsonContent}
				heightClassName="max-h-28"
				disableModEnter
				enableMentions
				onKeyDown={(e) => {
					e.stopPropagation();
				}}
				bottomPanelOptions={{
					showEmojis: true,
					showMentions: true,
					rightComponent: (
						<div className="flex gap-2">
							{onCancel ? <Button size="sm" variant="secondary" startIcon={ICONS.cross} onClick={onCancel} /> : null}
							<Button disabled={hasBeenChanged} size="sm" variant="primary" startIcon={ICONS.check} type="submit" />
						</div>
					),
				}}
			/>
		</form>
	);
}
