import clsx from "clsx";
import { truncateTextAndRemoveNewLines } from "../utils";

type TooltipContainerProps = {
	noWrap?: boolean;
	showOnTruncated?: boolean;
	text: string;
};

export function TooltipContainer({ text, showOnTruncated, noWrap = false }: TooltipContainerProps) {
	return (
		<div
			className={clsx(
				"text-c_text_inverted bg-c_bg_tooltip text-body-sm shadow-tooltip pointer-events-none overflow-hidden rounded-sm px-3 py-1 transition",
				noWrap ? "whitespace-nowrap" : "max-w-64 whitespace-pre-line break-words"
			)}
		>
			{showOnTruncated ? truncateTextAndRemoveNewLines(text, 200) : text}
		</div>
	);
}
