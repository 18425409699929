import { useMemo } from "react";

import { getChimeUserId } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../auth";
import { useChannelMembershipsSelector } from "../store/selectors/useChannelMembershipsSelector";

export function useChannelMemberRecordIds(channelArn?: string): number[] {
	const principal = useWebPrincipal();
	const memberships = useChannelMembershipsSelector(channelArn ?? "");

	return useMemo(() => {
		if (!memberships) {
			return [];
		}

		let hasCurrentUserId = false;
		const memberDetails: { id: number; name: string }[] = [];

		for (const membership of memberships) {
			const userId = getChimeUserId(membership.Member?.Arn);

			if (userId === principal.UserRecordId) {
				hasCurrentUserId = true;
			} else if (userId != null) {
				memberDetails.push({ id: userId, name: membership.Member?.Name ?? "" });
			}
		}

		const memberIds = memberDetails.sort((a, b) => a.name.localeCompare(b.name)).map((member) => member.id);

		if (hasCurrentUserId) {
			memberIds.push(principal.UserRecordId);
		}

		return memberIds;
	}, [memberships, principal]);
}
