import { mAssetDef, mGeneralAssetDef } from "@salesdesk/salesdesk-model";

import { useGetObjectById } from "../../../../../../../hooks/useGetObjectById";
import { CreateLinkedRecordTabProps } from "../types";
import { CreateLinkedRecordTabContent } from "./CreateLinkingRecordTabContent";

/*
	There are situations when we should't create records of provided 'connectedAssociationObject'
	and we need to override it e.g. mAsset

	every time when we want to create record of Asset we need to use instance of 'GeneralAsset'
*/

const sdObjectOverrideMap = {
	[mAssetDef.ID]: mGeneralAssetDef.ID,
};

export function CreateLinkedRecordTab({ connectedAssociationObject, ...remainingProps }: CreateLinkedRecordTabProps) {
	const overriddenSDObjectId = sdObjectOverrideMap[connectedAssociationObject._id];

	const { sdObject: overriddenSDObject } = useGetObjectById(overriddenSDObjectId);

	const associationSDObject = overriddenSDObjectId ? overriddenSDObject : connectedAssociationObject;

	return associationSDObject ? (
		<CreateLinkedRecordTabContent {...remainingProps} connectedAssociationObject={associationSDObject} />
	) : null;
}
