import { getSDObjectSystemFieldByName, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { useMeetingPageContext } from "../../../../hooks";
import { AgendaDetailsSection } from "./AgendaDetailsSection";

export function AgendaDetailsSectionContainer() {
	const { meetingObject, meetingRecord } = useMeetingPageContext();

	if (meetingObject == null || meetingRecord == null) return null;

	const agendaField = getSDObjectSystemFieldByName(meetingObject, mInteractionMeetingDef.AGENDA_FIELD_NAME);
	const agendaFieldValue = getSDRecordFieldValue(meetingRecord, agendaField?._id);

	if (agendaField == null || agendaFieldValue == null) return null;

	return <AgendaDetailsSection field={agendaField} fieldValue={agendaFieldValue} />;
}
