import { asConst } from "json-schema-to-ts";
import { sortOrderSchema } from "./sortOrderSchema";

export const sortByCriteriaSchema = asConst({
	title: "Search Sort By",
	description: "The criteria by which you want to sort by the property",
	type: "object",
	additionalProperties: false,
	required: ["order"],
	properties: {
		order: sortOrderSchema,
	},
	nullable: false,
});
