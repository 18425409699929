import { memo } from "react";

import { TooltipDelayGroup } from "@salesdesk/daisy-ui";
import { AbilityAction, AbilitySubject, sdSubject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useAppSelector } from "../../../../../store/store";
import { DisplayIf } from "../../../../../auth";
import { useObjectBookmarks } from "../../../../bookmarks/components/hooks/useObjectBookmarks";
import { BookmarkButton, BookmarkNavPopover } from "../../../../bookmarks";
import { RecordCreateButton, getCreateRecordTitleText } from "../../../../records";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import {
	BoardFilteringPopover,
	BoardGroupByMenu,
	BoardUsersFilter,
	BoardSearchbar,
	BoardSortingPopover,
	BoardViewSelect,
} from "../../ObjectBoardControls";
import { AdditionalBoardActions } from "./AdditionalBoardActions/AdditionalBoardActions";
import { ViewFieldSettings } from "./ViewFieldSettings";
import { Breadcrumbs } from "../../../../../components/Breadcrumbs/Breadcrumbs";

function InternalBoardTopBarComponent() {
	const { sdObject } = useDataboardDetailsContext();

	const currentDataBoardSyncState = useAppSelector((state) => state.appState.dataBoardStateSyncIteration);

	const objectBookmarks = useObjectBookmarks(sdObject);

	const crumbs = [];
	if (sdObject) {
		crumbs.push({ node: sdObject._pluralName, icon: sdObject._icon, link: "" });
		if (objectBookmarks?.length) {
			crumbs.push({
				node: <BookmarkNavPopover variant="objectBoardNav" sdObject={sdObject} />,
			});
		}
	}

	if (!sdObject) {
		return null;
	}

	return (
		<div className="flex items-center py-4 pr-6">
			<TooltipDelayGroup>
				<div className="flex items-center">
					<Breadcrumbs crumbs={crumbs} canCopyLink={true} />
					<BookmarkButton sdObject={sdObject} />
				</div>
				<div className="ml-auto flex gap-8">
					<div className="flex items-center gap-3">
						<BoardUsersFilter />
						<BoardViewSelect sdObject={sdObject} />
						<BoardGroupByMenu />
						<BoardFilteringPopover key={`${sdObject._id}-${currentDataBoardSyncState}-filter`} />
						<BoardSortingPopover key={`${sdObject._id}-${currentDataBoardSyncState}-sort`} />
					</div>
					<div className="flex items-center gap-2">
						<BoardSearchbar />
						<ViewFieldSettings />
						<AdditionalBoardActions />
						<DisplayIf can={[AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id })]}>
							<RecordCreateButton
								key={`${sdObject._id}-${currentDataBoardSyncState}-create`}
								sdObject={sdObject}
								tooltipText={getCreateRecordTitleText(sdObject)}
								startIcon={ICONS.plus}
								size="sm"
								variant="primary"
							>
								New
							</RecordCreateButton>
						</DisplayIf>
					</div>
				</div>
			</TooltipDelayGroup>
		</div>
	);
}

export const InternalBoardTopBar = memo(InternalBoardTopBarComponent);
