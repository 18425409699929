/* Provides single list of all ability actions when using ability.can(AbilityAction, ...) */
export const AbilityAction = Object.freeze({
	Manage: "manage", // This is a Casl in-build action indicating all actions
	Create: "Create",
	View: "View",
	Edit: "Edit",
	BulkEdit: "BulkEdit",
	BulkDelete: "BulkDelete",
	Delete: "Delete",
	ChangeOwner: "ChangeOwner",
	Convert: "Convert",
	Search: "Search",
	DownloadInUI: "DownloadInUI",

	// Video Calling
	Record: "Record",
	Share: "Share",
	Present: "Present",

	// Workspaces
	ShareWithWorkspace: "ShareWithWorkspace",
	UnshareWithWorkspace: "UnshareWithWorkspace",
});

export type AbilityAction = (typeof AbilityAction)[keyof typeof AbilityAction];
