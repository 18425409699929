import { Button, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Outlet } from "react-router-dom";
import { ImportProvider } from "./ImportProvider";
import { useImportContext } from "../../hooks/useImportContext";
import { SettingsHeader } from "../../../settings/SettingsHeader";
import { Toasts } from "../../../Toasts";
import { MultiChannelMessagingPanel } from "../../../messaging";
import { useExitImportScreen } from "../../hooks/useExitImportScreen";
import { useCheckRunningImportJobs } from "../../hooks/useCheckRunningImportJobs";
import { CheckingRunningJobs } from "./CheckingRunningJobs";

function ImportLayoutContents() {
	const { sdObject } = useImportContext();
	const exitImportScreen = useExitImportScreen();
	const isCheckingRunningJobs = useCheckRunningImportJobs();

	return (
		<div className="flex h-full w-full flex-col items-center">
			<div className="flex w-full grow-0 justify-between px-6 pb-6 pt-5">
				<div className="flex items-center gap-2">
					<Icon icon={ICONS.download} className="text-c_icon_regular flex items-center" />
					<div className="text-title">Import {sdObject._displayName}</div>
				</div>
				<Button variant="outlined" size="sm" startIcon={ICONS.cross} onClick={exitImportScreen}>
					Exit
				</Button>
			</div>
			{isCheckingRunningJobs ? <CheckingRunningJobs /> : <Outlet />}
		</div>
	);
}

export function ImportLayout() {
	return (
		<ImportProvider>
			<Toasts position="main" />
			<div className="bg-c_bg_02 flex h-screen w-full flex-col pl-8">
				<SettingsHeader />
				<div className="rounded-tl-panel bg-c_bg_01 h-full grow overflow-hidden">
					<ImportLayoutContents />
				</div>
			</div>
			<MultiChannelMessagingPanel />
		</ImportProvider>
	);
}
