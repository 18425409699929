import { useCallback, useState, useMemo } from "react";

import { getSDObjectFieldByName, SDObject, SDRecord, FieldValue } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef, mUserDef } from "@salesdesk/salesdesk-model";
import { Button, FieldSet } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../../../../../../auth";
import { useUpdateRecord } from "../../../../../../../../records";
import { useToast } from "../../../../../../../../Toasts";
import { EditMultiOptionField } from "../../../../../../../../fields";
import { OptionFieldComponentSettings } from "../../../../../../../../fields/components/Fields/OptionField/types";
import { SelectOptionId } from "../../../../../../../../inputs";
import { useMeetingParticipants } from "../../../../Participants";

interface InviteUserSectionProps {
	meetingObject: SDObject;
	meetingRecord: SDRecord;
	onClose: () => void;
}

export function InviteUserSection({ meetingObject, meetingRecord, onClose }: InviteUserSectionProps) {
	const toast = useToast();
	const principal = useWebPrincipal();
	const { updateRecord, isLoading } = useUpdateRecord();

	const participantsField = useMemo(
		() => getSDObjectFieldByName(meetingObject, mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME),
		[meetingObject]
	);

	const participants = useMeetingParticipants();
	const invitedParticipantIds = useMemo(
		() => (participants ?? []).map((participant) => participant.user.id),
		[participants]
	);
	const [participantIdsValue, setParticipantIdsValue] = useState<SelectOptionId[]>([]);
	const participantsOptionSettings: OptionFieldComponentSettings = useMemo(
		() => ({
			variant: "records",
			baseObjectId: mUserDef.ID,
			filteredIds: [...invitedParticipantIds, principal.UserRecordId],
		}),
		[principal.UserRecordId, invitedParticipantIds]
	);

	const patchFieldUpdate = useCallback(() => {
		if (!participantsField) return;

		const updatedFields = [
			{
				_fieldId: participantsField._id,
				_value: [...invitedParticipantIds, ...participantIdsValue],
			},
		] satisfies FieldValue[];

		const messageParams = {
			name: participantIdsValue.length > 1 ? participantsField._pluralName : participantsField._displayName,
		};

		updateRecord({
			record: meetingRecord,
			updatedFields,
		})
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "participant_added", messageParams });
				onClose();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "participant_added", messageParams });
			});
	}, [meetingRecord, participantsField, toast, updateRecord, participantIdsValue, invitedParticipantIds, onClose]);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center gap-4">
				<Button variant="text" size="xs" startIcon={ICONS.caretLeft} onClick={onClose} />
				<h2 className="text-label">Invite others</h2>
			</div>
			<FieldSet
				labelIcon={participantsField?._icon}
				label={participantsField?._displayName || "Participants"}
				helperText="By selecting participants, an email invitation will be automatically sent to their respective email addresses"
			>
				<EditMultiOptionField
					value={participantIdsValue}
					onChange={(newValue) => setParticipantIdsValue(newValue)}
					optionFieldSettings={participantsOptionSettings}
				/>
			</FieldSet>
			<div className="flex justify-end">
				<Button size="sm" onClick={patchFieldUpdate} disabled={participantIdsValue.length === 0} isLoading={isLoading}>
					Invite
				</Button>
			</div>
		</div>
	);
}
