import { FIELD_TYPES, FieldTypeName } from "@salesdesk/salesdesk-model";
import { Field } from "@salesdesk/salesdesk-schemas";

export const ALWAYS_EDITABLE_INLINE_FIELDS: FieldTypeName[] = [
	FIELD_TYPES.FILE.name,
	FIELD_TYPES.PDF.name,
	FIELD_TYPES.IMAGE.name,
	FIELD_TYPES.VIDEO.name,
	FIELD_TYPES.RATING.name,
	FIELD_TYPES.COLOR.name,
	FIELD_TYPES.ICON.name,
	FIELD_TYPES.BOOLEAN.name,
	FIELD_TYPES.PROFILE_PHOTO.name,
	FIELD_TYPES.LOGO.name,
];

export function isInlineRecordFieldAlwaysEditable(field: Field) {
	return ALWAYS_EDITABLE_INLINE_FIELDS.includes(field._type);
}
