import { useEffect } from "react";

import { ChannelType, getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { useAppDispatch } from "../../../../store/store";
import { useGetObjectMap } from "../../../objects/hooks";
import { useWebPrincipal } from "../../../../auth";
import { useChimeMessagingContext } from "../../components";
import { updateChannelDisplayName } from "../channelsSlice";
import { useChannelSelector } from "./useChannelSelector";
import { useChannelMembershipsSelector } from ".";
import { useGetDMChannelUserRecord } from "../../hooks";

const MAX_NAMES_TO_DISPLAY = 3;

// This selector is used to get the display name of a channel from the store and generates it if it doesn't exist.
export function useChannelDisplayNameSelector(channelArn?: string) {
	const objectMap = useGetObjectMap();

	const { chime } = useChimeMessagingContext();

	const channel = useChannelSelector(channelArn);
	const dispatch = useAppDispatch();

	const principal = useWebPrincipal();

	const channelMetadata = channel?.channelMetadata;

	const loadChannelMembers = channelMetadata?.channelType === ChannelType.Custom && !channel?.displayName;
	const channelMembership = useChannelMembershipsSelector(loadChannelMembers ? (channelArn ?? "") : "");

	const { userRecord: dmChannelUserRecord } = useGetDMChannelUserRecord(channel);

	useEffect(() => {
		if (!channelArn || !channel || channel?.displayName) {
			return;
		}

		const { channelMetadata } = channel;

		if (channelMetadata.channelType === ChannelType.DirectMessage) {
			const userRecordObject = dmChannelUserRecord ? objectMap.get(dmChannelUserRecord._objectDefId) : null;

			if (!userRecordObject || !dmChannelUserRecord) {
				return;
			}

			dispatch(
				updateChannelDisplayName({ channelArn, displayName: getSDRecordName(userRecordObject, dmChannelUserRecord) })
			);
		} else if (channelMetadata.channelType === ChannelType.Custom) {
			if (!channelMembership || !chime) {
				return;
			}

			const currentUserArn = chime.convertUserRecordIdToArn(principal.UserRecordId);

			const memberNames = channelMembership
				.filter(({ Member: member }) => member?.Arn && member?.Name && member.Arn !== currentUserArn)
				.map(({ Member: member }) => member?.Name ?? "")
				.sort((a, b) => a.localeCompare(b));

			// Filters out the current user and displays the names of all the members in the channel
			// as "Name 1, Name 2, Name 3 (+N)" in alphabetical order.
			const displayName =
				memberNames.length > MAX_NAMES_TO_DISPLAY
					? `${memberNames.slice(0, MAX_NAMES_TO_DISPLAY).join(", ")} (+${memberNames.length - MAX_NAMES_TO_DISPLAY})`
					: memberNames.join(", ");

			dispatch(updateChannelDisplayName({ channelArn, displayName }));
		}
	}, [channel, channelArn, channelMembership, chime, dispatch, dmChannelUserRecord, objectMap, principal.UserRecordId]);

	return channel?.displayName;
}
