import clsx from "clsx";

interface DropPlaceholderProps {
	active: boolean;
}

export function DropPlaceholder({ active }: DropPlaceholderProps) {
	return (
		<div className="flex justify-center">
			<div className={clsx(active && "bg-c_action_01", "h-1 w-full max-w-[480px]")} />
		</div>
	);
}
