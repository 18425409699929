import { Fragment } from "react";
import clsx from "clsx";

import { formatDateTime, formatTime } from "@salesdesk/salesdesk-utils";

import { MessageGroupMessage } from "../types";
import { User, InlineUser } from "../../users";
import { SpeechBubble } from "../../../components/SpeechBubble/SpeechBubble";

interface ChatMessageGroupProps {
	isLocalParticipant: boolean;
	user: User;
	messages: MessageGroupMessage[];
	displayTimestamp?: boolean;
}

export function ChatMessageGroup({ isLocalParticipant, user, messages, displayTimestamp }: ChatMessageGroupProps) {
	return (
		<>
			{displayTimestamp ? <MessageTimestamp time={messages[0].createdAt} /> : null}
			<div className="flex flex-col gap-1">
				{!isLocalParticipant ? (
					<div className="flex justify-start">
						<InlineUser user={user} />
					</div>
				) : null}
				<div className="flex flex-col gap-2">
					{messages.map((message) => (
						<Fragment key={`${message.createdBy}-${message.createdAt}`}>
							<div className={clsx("flex items-center pl-8", !isLocalParticipant ? "justify-start" : "justify-end")}>
								<div className="max-w-[80%]">
									<SpeechBubble jsonContent={message.jsonContent} variant={isLocalParticipant ? "dark" : "light"} />
								</div>
							</div>
						</Fragment>
					))}
				</div>
			</div>
		</>
	);
}

export function MessageTimestamp({ time }: { time: number }) {
	const date = new Date(time);
	const formattedTime = date.toDateString() === new Date().toDateString() ? formatTime(date) : formatDateTime(date);
	return <div className={clsx("text-label-xs text-c_text_placeholder flex justify-center")}>{formattedTime}</div>;
}
