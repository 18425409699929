import { ClaimsPrincipal, Role, RoleClaimType } from "@salesdesk/salesdesk-schemas";
import { OwnerType, RoleFormData } from "../types";
import { mContactDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

export function mapRoleToFormData(role: Pick<Role, "name" | "claims">): RoleFormData {
	const claimsPrincipal = new ClaimsPrincipal(role.claims);

	const hasClaim = (claimType: RoleClaimType, value?: string) =>
		claimsPrincipal.IsSystemAdmin || claimsPrincipal.HasClaim(claimType, value);

	const getOwnerType = (allClaimType: RoleClaimType, ownerClaimType: RoleClaimType) =>
		hasClaim(allClaimType)
			? OwnerType.All
			: claimsPrincipal.HasClaim(ownerClaimType)
			? OwnerType.Owner
			: OwnerType.None;

	return {
		name: role.name,
		objectRecordView: getOwnerType(RoleClaimType.ObjectRecordViewAll, RoleClaimType.ObjectRecordViewOwner),
		objectRecordEdit: getOwnerType(RoleClaimType.ObjectRecordEditAll, RoleClaimType.ObjectRecordEditOwner),
		objectRecordChangeOwner: getOwnerType(
			RoleClaimType.ObjectRecordChangeOwnerAll,
			RoleClaimType.ObjectRecordChangeOwnerOwner
		),
		objectRecordDelete: getOwnerType(RoleClaimType.ObjectRecordDeleteAll, RoleClaimType.ObjectRecordDeleteOwner),

		// TODO: https://salesdesk101.atlassian.net/browse/SAL-2551
		// objectRecordAssociationView: hasClaim(RoleClaimType.ObjectRecordAssociationView),
		objectRecordAssociationCreate: hasClaim(RoleClaimType.ObjectRecordAssociationCreate),
		objectRecordAssociationDelete: hasClaim(RoleClaimType.ObjectRecordAssociationDelete),

		leadRecordConvert: hasClaim(RoleClaimType.LeadRecordConvert),
		customerLoginEdit: hasClaim(RoleClaimType.UserLoginEdit, mContactDef.ID.toString()),

		fileDownloadInUI: hasClaim(RoleClaimType.FileDownloadInUI),
		sentimentAnalysisView: hasClaim(RoleClaimType.SentimentAnalysisView),
		coachingAdviceView: hasClaim(RoleClaimType.CoachingAdviceView),
		objectRecordBulkEdit: hasClaim(RoleClaimType.ObjectRecordBulkEdit),

		videoCallRecord: hasClaim(RoleClaimType.VideoCallRecord),
		videoCallShareScreen: hasClaim(RoleClaimType.VideoCallShareScreen),
		videoCallPresentFile: hasClaim(RoleClaimType.VideoCallPresentFile),
		videoCallCreateTask: hasClaim(RoleClaimType.VideoCallCreateTask),

		objectView: hasClaim(RoleClaimType.ObjectView),
		objectEdit: hasClaim(RoleClaimType.ObjectEdit),
		objectDelete: hasClaim(RoleClaimType.ObjectDelete),

		objectAssociationView: hasClaim(RoleClaimType.ObjectAssociationView),
		objectAssociationEdit: hasClaim(RoleClaimType.ObjectAssociationEdit),
		objectAssociationDelete: hasClaim(RoleClaimType.ObjectAssociationDelete),

		roleView: hasClaim(RoleClaimType.RoleView),
		roleEdit: hasClaim(RoleClaimType.RoleEdit),
		roleDelete: hasClaim(RoleClaimType.RoleDelete),

		userView: hasClaim(RoleClaimType.UserView),
		userEdit: hasClaim(RoleClaimType.UserEdit),
		userDelete: hasClaim(RoleClaimType.UserDelete),
		userLoginEdit: hasClaim(RoleClaimType.UserLoginEdit, mSalesDeskUserDef.ID.toString()),

		isAdmin: claimsPrincipal.IsSystemAdmin,
	};
}
