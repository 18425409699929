import { useVideoCallLogicContext } from "../../../../../VideoCallProvider";
import { GridView } from "./GridView";
import { PresenterView } from "./PresenterView";

export function CallView() {
	const { screenSharingParticipantDetails, fileSharingParticipant } = useVideoCallLogicContext();

	// TODO: Add support to both grid view and presenter view for handling screen-share/non-screen sharing
	// participants respectively. (E.g. using pins)
	if (screenSharingParticipantDetails.length || fileSharingParticipant) {
		return <PresenterView />;
	}

	return <GridView />;
}
