import { useCallback } from "react";

import { useGetContextWorkspaceId } from "../../workspaces";
import { UpdateRecordArgs, useUpdateRecordMutation } from "../api/recordsApi";

export function useUpdateRecord() {
	const workspaceId = useGetContextWorkspaceId();

	const [updateRecord, { isLoading }] = useUpdateRecordMutation();

	return {
		updateRecord: useCallback(
			(args: UpdateRecordArgs) => {
				return updateRecord({ ...args, workspaceId }).unwrap();
			},
			[updateRecord, workspaceId]
		),
		isLoading,
	};
}
