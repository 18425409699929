import { PanelTabPanel } from "../../../../../../../components/PanelTabs";
import { NotesController } from "../../../../../../notes";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";

interface ResourcesNotesPanelProps {
	onUpdateCount?: (count: number) => void;
}

export function ResourcesNotesPanel({ onUpdateCount }: ResourcesNotesPanelProps) {
	const { workspaceRecord } = useWorkspaceContext();

	return (
		<PanelTabPanel className="flex w-full justify-center" unmount={false}>
			<div className="h-full w-full max-w-6xl">
				{workspaceRecord ? <NotesController sdRecord={workspaceRecord} onUpdateCount={onUpdateCount} /> : null}
			</div>
		</PanelTabPanel>
	);
}
