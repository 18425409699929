export function formatBytes(bytes: number | null | undefined, decimals = 2) {
	if (bytes === null || bytes === undefined) {
		return "Size unknown";
	}

	if (bytes === 0) {
		return "0 Bytes";
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// Source: https://stackoverflow.com/a/11832950
export function roundToTwoDecimalPlaces(num: number) {
	return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function capitalizeString(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function pluralizeWithS(word: string, count: number) {
	return count === 1 ? word : `${word}s`;
}

const VOWELS = new Set(["a", "e", "i", "o", "u"]);

export function selectIndefiniteArticle(word: string): string {
	const firstLetter = word.charAt(0).toLowerCase();
	return VOWELS.has(firstLetter) ? "an" : "a";
}
