import { Field } from "@salesdesk/salesdesk-schemas";
import { InputValidationState, FieldSet } from "@salesdesk/daisy-ui";

import { PreviewFieldFactory } from "../../../../../fields";
import { ObjectPreviewFieldControls } from "./ObjectPreviewFieldControls";

interface ObjectPreviewFieldFactoryProps {
	field: Field;
	error?: boolean;
	padlock?: boolean | string;
	onRemove?: () => void;
	isDraggable?: boolean;
}

export function ObjectPreviewFieldFactory({
	field,
	error,
	padlock,
	onRemove,
	isDraggable,
}: ObjectPreviewFieldFactoryProps) {
	const currentValidationState = error ? InputValidationState.error : InputValidationState.initial;
	const { _required, _icon, _displayName, _toolTip } = field;

	const fieldSetProps = {
		label: _displayName,
		labelIcon: _icon,
		validationState: currentValidationState,
		required: _required,
		disabled: !field._editable,
		helperText: _toolTip || undefined,
		padlock,
		withLabel: false,
	};

	const previewField = PreviewFieldFactory({ field, error });
	const withFieldControls = onRemove || isDraggable;

	if (withFieldControls) {
		return (
			<FieldSet {...fieldSetProps}>
				<ObjectPreviewFieldControls onRemove={onRemove} isDraggable={isDraggable}>
					{previewField}
				</ObjectPreviewFieldControls>
			</FieldSet>
		);
	}

	return <FieldSet {...fieldSetProps}>{previewField}</FieldSet>;
}
