import { Icon, tw } from "@salesdesk/daisy-ui";
import clsx from "clsx";

interface CompactRecordPreviewIconProps {
	icon: string;
	colorClasses?: string;
}

export function CompactRecordPreviewIcon({
	icon,
	colorClasses = tw`bg-c_bg_02 text-c_action_01`,
}: CompactRecordPreviewIconProps) {
	return (
		<div className={clsx("flex size-7 shrink-0 items-center justify-center rounded-full", colorClasses)}>
			<Icon icon={icon} size="sm" />
		</div>
	);
}
