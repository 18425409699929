import { HTMLProps, forwardRef, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { useMergeRefs } from "@floating-ui/react";
import clsx from "clsx";

import { throttle } from "../../../../../../../utils";
import { tw } from "../../../../../../../utils/tailwind-helpers";

interface GradientScrollContainerProps extends HTMLProps<HTMLDivElement> {
	offset?: number;
}

export const GradientScrollContainer = forwardRef<HTMLDivElement, GradientScrollContainerProps>(
	({ offset = 0, children, ...divProps }, propRef) => {
		const [showStartGradient, setShowStartGradient] = useState(false);
		const [showEndGradient, setShowEndGradient] = useState(false);

		const scrollContainerRef = useRef<HTMLDivElement>(null);

		const ref = useMergeRefs([scrollContainerRef, propRef]);

		useEffect(() => {
			const scrollContainer = scrollContainerRef.current;

			if (!scrollContainer) {
				return;
			}

			const updateContainerEdgeGradients = () => {
				const atStartOfScroll = scrollContainer.scrollLeft <= offset;
				const atEndOfScroll =
					scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth - offset;

				setShowStartGradient(!atStartOfScroll);
				setShowEndGradient(!atEndOfScroll);
			};

			const resizeObserver = new ResizeObserver(
				throttle(() => {
					updateContainerEdgeGradients();
				}, 50)
			);

			resizeObserver.observe(scrollContainer);

			updateContainerEdgeGradients();
			scrollContainer.addEventListener("scroll", updateContainerEdgeGradients);

			return () => {
				scrollContainer.removeEventListener("scroll", updateContainerEdgeGradients);
				resizeObserver.disconnect();
			};
		}, [scrollContainerRef, offset]);

		return (
			<div ref={ref} {...divProps}>
				<ContainerEdgeGradient direction="left" show={showStartGradient} />
				{children}
				<ContainerEdgeGradient direction="right" show={showEndGradient} />
			</div>
		);
	}
);

interface ContainerEdgeGradientProps {
	show?: boolean;
	direction: "left" | "right";
}

export function ContainerEdgeGradient({ show = false, direction }: ContainerEdgeGradientProps) {
	const rotation = direction === "left" ? 90 : 270;

	return (
		<Transition
			show={show}
			className={clsx(
				"pointer-events-none absolute bottom-4 top-0 z-20 w-32 transition-opacity duration-100 ease-in",
				direction === "left" ? "left-0" : "right-0"
			)}
			enterFrom={tw`opacity-0`}
			enterTo={tw`opacity-100`}
			leaveFrom={tw`opacity-100`}
			leaveTo={tw`opacity-0`}
			style={{
				background: generateWhiteToTransparentGradient(rotation),
			}}
		/>
	);
}

// Creates an easing linear gradient from white to transparent with no color banding
// (which is still an unresolved issue with CSS linear gradients)
// SOURCE: https://larsenwork.com/easing-gradients/#editor
function generateWhiteToTransparentGradient(rotation = 270) {
	return `linear-gradient(${rotation}deg, hsl(0, 0%, 100%) 0%, hsla(0, 0%, 100%, 0.987) 8.1%, hsla(0, 0%, 100%, 0.951) 15.5%, hsla(0, 0%, 100%, 0.896) 22.5%, hsla(0, 0%, 100%, 0.825) 29%, hsla(0, 0%, 100%, 0.741) 35.3%, hsla(0, 0%, 100%, 0.648) 41.2%, hsla(0, 0%, 100%, 0.55) 47.1%, hsla(0, 0%, 100%, 0.45) 52.9%, hsla(0, 0%, 100%, 0.352) 58.8%, hsla(0, 0%, 100%, 0.259) 64.7%, hsla(0, 0%, 100%, 0.175) 71%, hsla(0, 0%, 100%, 0.104) 77.5%, hsla(0, 0%, 100%, 0.049) 84.5%, hsla(0, 0%, 100%, 0.013) 91.9%, hsla(0, 0%, 100%, 0) 100%)`;
}
