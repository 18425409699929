import { createContext, RefObject, useContext } from "react";
import { FaceSentiment, TranscriptSentiment } from "../types";
import { SDFile, TranscriptItem } from "@salesdesk/salesdesk-schemas";

export interface IMediaAnalysisContext {
	file: SDFile;
	isMediaAnalysisLoading: boolean;
	userKeyToUserId: Record<string, number | string>;
	faceSentimentsPerUser: Record<string, FaceSentiment[]> | undefined;
	transcriptSentimentsPerUser: Record<string, TranscriptSentiment[]> | undefined;
	transcriptItems: TranscriptItem[] | undefined;
	videoDuration: number;
	updateVideoDuration: (seconds: number) => void;
	setOnlyTotalTalkingTime: (showTalkingTime: boolean) => void;
	onlyTotalTalkingTime: boolean;
	faceSentimentGraphHolderRef: RefObject<HTMLDivElement>;
	faceSentimentGraphRef: RefObject<HTMLDivElement>;
	transcriptSentimentGraphRef: RefObject<HTMLDivElement>;
	setAnyPopoverSelectOpen: (selectorOpen: boolean) => void;
	anyPopoverSelectOpen: boolean;
	zoomIndex: number;
	zoomFactor: number;
	setZoomIndex: (zoomMultiplierIndex: number) => void;
	viewStartTime: number;
	setViewStartTime: (viewStartTime: number) => void;
	isPanning: boolean;
	setIsPanning: (isScrolling: boolean) => void;
	faceSentimentGraphOpen: boolean;
	setFaceSentimentGraphOpen: (isOpen: boolean) => void;
	transcriptSentimentGraphOpen: boolean;
	setTranscriptSentimentGraphOpen: (isOpen: boolean) => void;
	open: boolean;
	setOpen: (isOpen: boolean) => void;
	videoRef: RefObject<HTMLVideoElement>;
}

export const MediaAnalysisContext = createContext<IMediaAnalysisContext | null>(null);

export const useMediaAnalysisContext = () => {
	const context = useContext(MediaAnalysisContext);

	if (context == null) {
		throw new Error("Media analysis components must be wrapped in <MediaAnalysisProvider />");
	}

	return context;
};
