import { useMemo } from "react";
import { RuleValue } from "../types";
import { useNotificationRuleDialogContext } from "./useNotificationRuleDialogContext";

export function useChannelRuleValues() {
	const { actualSelectedChannels, currentSelection, setCurrentSelection } = useNotificationRuleDialogContext();

	return useMemo(() => {
		const values: RuleValue[] = [];
		if (actualSelectedChannels.length > 0) {
			for (const channel of actualSelectedChannels) {
				values.push({
					value: channel.name,
					onClick: () => setCurrentSelection("channels"),
					active: currentSelection === "channels",
				});
			}
		} else {
			values.push({
				placeholder: "this channel (optional)",
				onClick: () => setCurrentSelection("channels"),
				active: currentSelection === "channels",
			});
		}
		return values;
	}, [actualSelectedChannels, currentSelection, setCurrentSelection]);
}
