import { useGetNewNotificationsCountQuery, useResetNewNotificationsCountMutation } from "../api/notificationApi";
import { useCallback, useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MINUTE_IN_MS } from "@salesdesk/salesdesk-utils";

type useGetNewNotificationsCountQueryParameters = Parameters<typeof useGetNewNotificationsCountQuery>;
const POLL_QUERY_PARAMS: useGetNewNotificationsCountQueryParameters = [undefined, { pollingInterval: MINUTE_IN_MS }];
const PAUSE_QUERY_PARAMS: useGetNewNotificationsCountQueryParameters = [skipToken];

export function useNotificationsCounter() {
	const [count, setCount] = useState<number>(0);
	const [queryParameters, setQueryParameters] = useState<useGetNewNotificationsCountQueryParameters>(POLL_QUERY_PARAMS);

	const { data: newNotificationsCount = { count: 0 } } = useGetNewNotificationsCountQuery(...queryParameters);
	const [resetNewNotificationsCount] = useResetNewNotificationsCountMutation();

	const setPause = useCallback((pause: boolean) => {
		setQueryParameters(pause ? PAUSE_QUERY_PARAMS : POLL_QUERY_PARAMS);
	}, []);

	const reset = useCallback(() => {
		resetNewNotificationsCount()
			.then(() => {
				setCount(0);
			})
			.catch((error) => {
				// TODO: Handle error
				console.error(error);
			});
	}, [resetNewNotificationsCount]);

	useEffect(() => {
		setCount(newNotificationsCount.count);
	}, [newNotificationsCount.count]);

	return {
		count,
		setPause,
		reset,
	};
}
