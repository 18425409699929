import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";

export function RecordSearchResultSkeleton() {
	return (
		<div className="flex flex-col gap-2 px-6 py-3">
			<Skeleton className="h-6 w-11/12" />
			<Skeleton className="w-21 h-4" />
		</div>
	);
}
