import { useEffect, useState } from "react";

import { useVideoCallLogicContext } from "../../../../../../../VideoCallProvider";
import { CHAT_PANEL_INDEX } from "../../../utils";

export function useUnreadChatMessages() {
	const { sortedChatMessages, panelOpen, currentPanelTab } = useVideoCallLogicContext();

	const [lastReadTimestamp, setLastReadTimestamp] = useState<number>(0);
	const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);

	useEffect(() => {
		if (sortedChatMessages.length === 0) {
			setUnreadMessageCount(0);
			setLastReadTimestamp(0);
		} else if (panelOpen && currentPanelTab === CHAT_PANEL_INDEX) {
			setUnreadMessageCount(0);
			setLastReadTimestamp(sortedChatMessages[sortedChatMessages.length - 1].createdAt);
		} else {
			setUnreadMessageCount(sortedChatMessages.filter((message) => message.createdAt > lastReadTimestamp).length);
		}
	}, [currentPanelTab, lastReadTimestamp, panelOpen, sortedChatMessages]);

	return { unreadMessageCount };
}
