import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadingStatus, SerializableChannelMessage } from "../types";

interface MessagesState {
	[channelArn: string]: {
		loadingStatus?: LoadingStatus;
		messages?: SerializableChannelMessage[]; // Set to undefined if no messages have been loaded yet
		nextToken?: string | null;
	};
}

const initialState: MessagesState = {};

export const messagesSlice = createSlice({
	name: "messages",
	initialState,
	reducers: {
		setLoadingStatus(state, action: PayloadAction<{ channelArn: string; loadingStatus: LoadingStatus }>) {
			const { channelArn, loadingStatus } = action.payload;

			if (!state[channelArn]) {
				return;
			}

			state[channelArn].loadingStatus = loadingStatus;
		},
		setChannelMessagesToLoading(state, action: PayloadAction<{ channelArn: string }>) {
			const { channelArn } = action.payload;
			state[channelArn] = { messages: undefined, loadingStatus: LoadingStatus.Loading };
		},
		addMessages(
			state,
			action: PayloadAction<{ channelArn: string; messages: SerializableChannelMessage[]; nextToken?: string | null }>
		) {
			const { channelArn, messages: newMessages } = action.payload;

			const uniqueNewMessages = getUniqueMessages(newMessages);
			const channelMessages = state[channelArn]?.messages;

			if (channelMessages) {
				uniqueNewMessages.forEach((newMessage) => {
					const existingMessageIndex = channelMessages.findIndex(
						(message) => message.MessageId === newMessage.MessageId
					);

					if (existingMessageIndex !== -1) {
						channelMessages[existingMessageIndex] = newMessage;
					} else {
						channelMessages.push(newMessage);
					}
				});

				sortMessagesByTimestamp(channelMessages);
			} else {
				state[channelArn] = {
					messages: sortMessagesByTimestamp(uniqueNewMessages),
				};
			}

			state[action.payload.channelArn].nextToken = action.payload.nextToken;
		},
		updateMessage(state, action: PayloadAction<{ channelArn: string; updatedMessage: SerializableChannelMessage }>) {
			const { channelArn, updatedMessage } = action.payload;

			const channelMessages = state[channelArn]?.messages;
			if (!channelMessages) {
				return;
			}

			const existingMessageIndex = channelMessages.findIndex(
				(message) => message.MessageId === updatedMessage.MessageId
			);

			if (existingMessageIndex !== -1) {
				channelMessages[existingMessageIndex] = updatedMessage;
				sortMessagesByTimestamp(channelMessages);
			}
		},
	},
});

function getUniqueMessages(messages: SerializableChannelMessage[]) {
	return Array.from(new Map(messages.map((msg) => [msg.MessageId, msg])).values());
}

function sortMessagesByTimestamp(messages: SerializableChannelMessage[]) {
	return messages.sort((a, b) => {
		const aTimestamp = a.CreatedTimestamp ?? 0;
		const bTimestamp = b.CreatedTimestamp ?? 0;
		return aTimestamp - bTimestamp;
	});
}

export const { setChannelMessagesToLoading, addMessages, updateMessage, setLoadingStatus } = messagesSlice.actions;
export const messagesReducer = messagesSlice.reducer;
