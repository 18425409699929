import { forwardRef, useState, useMemo, useEffect } from "react";
import clsx from "clsx";

import { combineRefs, Icon, Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { getInitials } from "../../../users";
import { ProfilePhotoInputProps } from "../../types";

import { useFileInputController } from "../FileInput/hooks";
import { ImagePreview } from "../../../files/components/FilePreview/ImagePreview/ImagePreview";
import { tw } from "../../../../utils/tailwind-helpers";
import { CroppingPhotoModal } from "../CroppingPhotoModal";
import { useSdFile } from "../../../files/hooks/useSdFile";

export const PROFILE_PHOTO_MIME_TYPES = ["image/jpeg", "image/png"];

export const ProfilePhotoInput = forwardRef<HTMLInputElement, ProfilePhotoInputProps>(
	({ fullName, id, value, onChange, uploadProgress, disabled, ariaAttributes, hasError, onBlur }, ref) => {
		const [photoEditorOpen, setPhotoEditorOpen] = useState(false);
		const [isEditablePhotoApplying, setIsEditablePhotoApplying] = useState(false);
		const [editablePhotoFile, setEditablePhotoFile] = useState<File | null>(null);

		const { draggingOver, innerRef, clearFile, updateFile, handleDrop, handleDragEnter, handleDragExit } =
			useFileInputController({
				value: editablePhotoFile,
				onChange: setEditablePhotoFile,
				uploadProgress,
				disabled,
			});

		const onPhotoEditorSave = (newValue: File) => {
			onChange(newValue);
			setIsEditablePhotoApplying(true);
		};

		useEffect(() => {
			setPhotoEditorOpen(Boolean(editablePhotoFile));
		}, [editablePhotoFile]);

		useEffect(() => {
			if (value) {
				setIsEditablePhotoApplying(false);
				clearFile();
			}
		}, [value, clearFile]);

		const [isHovered, setIsHovered] = useState(false);

		const mouseEnter = () => {
			if (disabled) return;
			setIsHovered(true);
		};

		const mouseLeave = () => {
			if (disabled) return;
			setIsHovered(false);
		};

		const browseFiles = () => {
			if (innerRef.current) {
				innerRef.current.click();
			}
		};

		const emptyInputStyles = useMemo(() => {
			const isDefaultState = !disabled && !hasError;
			const classStyles = clsx(tw`hover:bg-c_action_03`);

			if (isDefaultState) {
				return clsx(
					classStyles,
					draggingOver ? tw`border-c_action_01 bg-c_action_03` : tw`border-c_brand_primary bg-c_brand_primary`
				);
			} else if (disabled) {
				return clsx(tw`bg-c_bg_disabled_01 border-c_bg_disabled_01`);
			} else if (hasError) {
				return clsx(classStyles, tw`border-c_danger_02 bg-c_action_03`);
			}

			return classStyles;
		}, [disabled, hasError, draggingOver]);

		const filledInputStyles = useMemo(() => {
			const isDefaultState = !disabled && !hasError;
			const classStyles = "";

			if (isDefaultState) {
				return clsx(classStyles, tw`border-transparent`);
			} else if (disabled) {
				return clsx(tw`bg-c_bg_disabled_01 border-c_bg_disabled_01 opacity-50`);
			} else if (hasError) {
				return clsx(classStyles, tw`border-c_danger_02`);
			}

			return classStyles;
		}, [disabled, hasError]);

		const initials = fullName ? getInitials(fullName) : null;
		const addPhotoIcon = <Icon icon={ICONS.plus} size="lg" className="text-c_bg_03 flex" />;

		const { sdFile } = useSdFile(typeof value === "number" ? value : null);

		return (
			<div className="flex flex-wrap items-center gap-6">
				<div
					className="relative h-20 w-20"
					onMouseEnter={mouseEnter}
					onMouseLeave={mouseLeave}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragExit}
					onDrop={handleDrop}
					onDragOver={(e) => e.preventDefault()}
				>
					{sdFile ? (
						<div className="absolute inset-0 z-10 overflow-hidden rounded-full border border-transparent">
							<ImagePreview file={sdFile} isIcon />
						</div>
					) : null}

					<label
						htmlFor={id}
						className={clsx(
							"absolute inset-0 z-20 overflow-hidden rounded-full",
							"flex flex-col items-center justify-center",
							"select-none border-2 transition-colors",
							!disabled && "hover:border-c_bg_02 active:border-c_action_01",
							"focus-visible-within:ring-c_action_focus focus-visible-within:border-c_bg_01 focus-visible-within:ring",
							value ? filledInputStyles : emptyInputStyles
						)}
					>
						<input
							id={id}
							type="file"
							ref={combineRefs([innerRef, ref])}
							{...ariaAttributes}
							className="absolute z-0 h-0 w-0 opacity-0"
							accept={PROFILE_PHOTO_MIME_TYPES.join(",")}
							disabled={disabled}
							onChange={(e) => {
								updateFile(e.target.files?.item(0));
							}}
							onBlur={onBlur}
						/>
						{!value ? (
							initials ? (
								isHovered ? (
									addPhotoIcon
								) : (
									<p className="text-c_bg_03 text-h2">{initials}</p>
								)
							) : (
								addPhotoIcon
							)
						) : null}
					</label>
				</div>
				{value ? (
					<div className="flex gap-3">
						<Button
							variant="outlined"
							size="sm"
							onClick={(event) => {
								event.stopPropagation();
								event.preventDefault();
								browseFiles();
							}}
							disabled={disabled}
						>
							Change
						</Button>
						<Button
							variant={disabled ? "text" : "inverted"}
							startIcon={ICONS.trash}
							size="sm"
							onClick={(event) => {
								event.stopPropagation();
								event.preventDefault();
								onChange(null);
							}}
							disabled={disabled}
						>
							Clear
						</Button>
					</div>
				) : null}
				{photoEditorOpen ? (
					<CroppingPhotoModal
						open={photoEditorOpen}
						photoFile={editablePhotoFile}
						onSave={onPhotoEditorSave}
						onClose={clearFile}
						isLoading={isEditablePhotoApplying}
						backgroundColor="#fff"
					/>
				) : null}
			</div>
		);
	}
);
