import { useEffect, useRef, useMemo } from "react";
import clsx from "clsx";
import { Transition } from "@headlessui/react";

import { mUserDef, mContactDef, mLeadDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";
import { createHashId } from "@salesdesk/salesdesk-utils";
import { Spinner } from "@salesdesk/daisy-ui";

import { useInfiniteScrollContainer } from "../../../../../../../../hooks/ui";
import { tw } from "../../../../../../../../utils/tailwind-helpers";
import { UserWithDetailsLoading, useUserObjectsMap, UserWithDetails } from "../../../../../../../users";
import { useWorkspaceSharedRecordSearch } from "../../../../../../hooks/useWorkspaceSharedRecordSearch";
import { SortingOrder } from "../../../../../../../records";

interface WorkspaceTeamMembersProps {
	variant: "customerUsers" | "internalUsers";
	overlapping?: boolean;
	isVisible: boolean;
	onVisibilityChange: (isVisible: boolean) => void;
}

const INTERNAL_USERS_RECORD_TYPES = [mSalesDeskUserDef.ID];
const CUSTOMER_USERS_RECORD_TYPES = [mContactDef.ID, mLeadDef.ID];

export function WorkspaceTeamMembers({
	variant,
	isVisible,
	onVisibilityChange,
	overlapping = false,
}: WorkspaceTeamMembersProps) {
	const isInternalUsers = variant === "internalUsers";
	const userObjectMap = useUserObjectsMap();

	const additionalSearchParams = useMemo(
		() => ({
			query: { and: [], or: [] },
			sort: [
				{ [createHashId(mUserDef.SURNAME_FIELD_NAME)]: { order: SortingOrder.asc } },
				{ [createHashId(mUserDef.FIRST_NAME_FIELD_NAME)]: { order: SortingOrder.asc } },
			],
		}),
		[]
	);

	const {
		records: workspaceMemberRecords,
		loadNextPage,
		isLoadingRecords: isLoadingTeamMembers,
		isLoadingNextPage,
		hitCount,
	} = useWorkspaceSharedRecordSearch({
		sdObjectFilter: isInternalUsers ? INTERNAL_USERS_RECORD_TYPES : CUSTOMER_USERS_RECORD_TYPES,
		additionalSearchParams,
	});

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			loadNextPage();
		},
	});

	useEffect(() => {
		if (!hitCount && !isLoadingTeamMembers) {
			onVisibilityChange(false);
			return;
		}

		onVisibilityChange(true);
	}, [isLoadingTeamMembers, onVisibilityChange, hitCount]);

	const isOverlapping = overlapping || !isVisible;

	return (
		<Transition
			show={isVisible}
			className={clsx(
				"flex h-full max-h-[280px] min-h-fit w-full min-w-0 grow overflow-hidden p-4 transition-all ease-out",
				isOverlapping ? "rounded-l-panel" : "rounded-panel",
				isInternalUsers ? "bg-c_bg_02" : "bg-c_bg_04",
				isOverlapping && (isInternalUsers ? "-ml-5" : "pr-10")
			)}
			leaveFrom={tw`opacity-100`}
			leaveTo={tw`opacity-0`}
		>
			<div ref={scrollContainerRef} className="flex max-h-full w-full flex-col gap-4 overflow-y-auto">
				{isLoadingTeamMembers || !workspaceMemberRecords.length ? (
					<>
						{[...Array(3)].map((_, index) => (
							<UserWithDetailsLoading key={index} size="lg" />
						))}
					</>
				) : (
					<>
						{workspaceMemberRecords.map((record, index) => {
							const sdObject = userObjectMap?.get(record._objectDefId);

							return sdObject ? (
								<UserWithDetails
									userMeetingsId={index === 0 && isInternalUsers ? "userflow-book-meeting-with-partner" : undefined}
									key={record._id}
									sdRecord={record}
									sdObject={sdObject}
									size="lg"
									withMeetings
									showInviteButton
									getStatusFromRecord="DEFAULT"
								/>
							) : (
								<UserWithDetailsLoading key={record._id} size="lg" />
							);
						})}
						{isLoadingNextPage ? (
							<div className="flex min-h-8 w-full items-center justify-center">
								<Spinner />
							</div>
						) : (
							// Negative margin to counter-act the flex gap on the parent container
							<div className="-mt-4" ref={containerBottomRef} key={workspaceMemberRecords.length} />
						)}
					</>
				)}
			</div>
		</Transition>
	);
}
