import { Editor, Mark, mergeAttributes, Range } from "@tiptap/core";
import { Mark as PMMark } from "@tiptap/pm/model";
import { PLACEHOLDER_COMMENT_ID } from "../../DocumentComments";

type CommentId = string;

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		comment: {
			// Add a comment for current selection
			setComment: (commentId: CommentId) => ReturnType;
			// Remove a comment from the document
			removeComment: (commentId: CommentId) => ReturnType;
		};
	}
}

export interface CommentOptions {
	HTMLAttributes: Record<string, any>;
	onActiveCommentsChange: (commentId: CommentId[]) => void;
}

export const COMMENT_DATA_ATTRIBUTE = "data-comment-id";

export const CommentExtension = Mark.create<CommentOptions, { activeCommentId: string | null }>({
	name: "comment",
	keepOnSplit: true,
	excludes: "",
	inclusive: false,
	addOptions() {
		return {
			HTMLAttributes: {},
			onActiveCommentsChange: () => {
				return null;
			},
		};
	},
	addAttributes() {
		return {
			commentId: {
				default: null,
				parseHTML: (el) => (el as HTMLSpanElement).getAttribute(COMMENT_DATA_ATTRIBUTE),
				renderHTML: (attrs) => ({ [COMMENT_DATA_ATTRIBUTE]: attrs["commentId"] }),
			},
		};
	},
	parseHTML() {
		return [
			{
				tag: `span[${COMMENT_DATA_ATTRIBUTE}]`,
				getAttrs: (el) => !!(el as HTMLSpanElement).getAttribute(COMMENT_DATA_ATTRIBUTE)?.trim() && null,
			},
		];
	},
	renderHTML({ HTMLAttributes }) {
		return ["span", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
	},
	onSelectionUpdate() {
		const { from, to } = this.editor.state.selection;

		// Ignore if the selection is not a cursor
		if (from !== to) {
			return;
		}

		const activeCommentIds = getActiveCommentIdsInEditor(this.editor);
		this.options.onActiveCommentsChange(activeCommentIds);
	},
	addCommands() {
		return {
			setComment: (commentId) => {
				return ({ commands }) => {
					if (!commentId) return false;
					return commands.setMark("comment", { commentId });
				};
			},
			removeComment: (commentId) => {
				return ({ tr, dispatch }) => {
					if (!commentId) return false;
					const commentsWithId: { mark: PMMark; range: Range }[] = [];

					tr.doc.descendants((node, pos) => {
						const commentMark = node.marks.find(
							(mark) => mark.type.name === "comment" && mark.attrs["commentId"] === commentId
						);

						if (!commentMark) return;

						commentsWithId.push({
							mark: commentMark,
							range: {
								from: pos,
								to: pos + node.nodeSize,
							},
						});
					});

					commentsWithId.forEach(({ mark, range }) => {
						tr.removeMark(range.from, range.to, mark);
					});

					return dispatch?.(tr);
				};
			},
		};
	},
});

/*
	Returns the ids of all the comment within the editor's current selection, sorted by depth.
*/
export function getActiveCommentIdsInEditor(editor: Editor): CommentId[] {
	const selection = editor.state.selection;

	const commentMarks: { id: string; depth: number }[] = [];

	let from = selection.from;
	const to = selection.to;

	if (from === to) {
		from = Math.max(0, from - 1);
	}

	editor.state.doc.nodesBetween(from, to, (node, pos) => {
		const depth = editor.state.doc.resolve(pos).depth;

		node.marks.forEach((mark, index) => {
			if (mark.type.name !== "comment") return;

			const commentId = mark.attrs["commentId"];

			if (commentId !== PLACEHOLDER_COMMENT_ID) {
				// Add a small offset based on the index to give higher priority to more recent comments if we have
				// overlapping comments with the same depth.
				commentMarks.push({ id: commentId, depth: depth + index * 0.1 });
			}
		});

		return true;
	});

	commentMarks.sort((a, b) => b.depth - a.depth);
	return Array.from(new Set(commentMarks.map((comment) => comment.id)));
}
