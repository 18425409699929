import { mergeAttributes } from "@tiptap/core";
import Color from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import { Mention } from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";

type Levels = 1 | 2 | 3;

const headingClasses: Record<Partial<Levels>, string> = {
	1: "text-h1",
	2: "text-h2",
	3: "text-h3",
};

export interface CommonRichTextExtensions {
	placeholder?: string;
	enableMentions?: boolean;
	enableParagraph?: boolean;
	enableImages?: boolean;
}

export interface FrontEndRichTextExtensionsOptions extends CommonRichTextExtensions {
	enableSdFileImages?: boolean;
	disableEnter?: boolean;
	disableModEnter?: boolean;
	enableShiftEnterAsEnter?: boolean;
}

// When adding a new extension, make sure to add it to generateText as well. Otherwise, the backend will break
export function getRichTextExtensions(options?: CommonRichTextExtensions) {
	const extensions: any[] = [
		StarterKit.configure({
			bulletList: {
				HTMLAttributes: {
					class: `list-disc pl-6`,
				},
				keepMarks: true,
				keepAttributes: false,
			},
			orderedList: {
				HTMLAttributes: {
					class: `list-decimal pl-6`,
				},
				keepMarks: true,
				keepAttributes: false,
			},
			heading: false,
			paragraph: options?.enableParagraph === false ? false : undefined,
		}),
		Placeholder.configure({
			placeholder: options?.placeholder || "Add text",
			emptyEditorClass:
				"before:content-[attr(data-placeholder)] before:text-c_text_placeholder before:h-0 before:float-left",
		}),
		Heading.configure({ levels: [1, 2, 3] }).extend({
			renderHTML({ node, HTMLAttributes }) {
				const hasLevel: boolean = this.options.levels.includes(node.attrs["level"]);
				const level: Levels = hasLevel ? node.attrs["level"] : this.options.levels[0];

				return [
					`h${level}`,
					mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
						class: headingClasses[level],
					}),
					0,
				];
			},
		}),
		Link.configure({
			HTMLAttributes: {
				class: "underline hover:no-underline cursor-pointer",
			},
		}),
		TextAlign.configure({
			types: ["heading", "paragraph", "image"],
		}),
		Highlight.configure({ multicolor: true }),
		TextStyle,
		Color,
		Underline,
	];

	// This extension is not used in the UI because the react mention extension is used instead
	// This is used when generating the Html embedded in mjml emails
	if (options?.enableMentions) {
		const mentionExtension = Mention.configure({
			renderHTML({ options, node }) {
				return [
					"span",
					{ style: "color: #6904C1;" },
					`${options.suggestion["char"]}${node.attrs["label"] ?? node.attrs["id"]} `,
				];
			},
			renderText({ options, node }) {
				return `${options.suggestion["char"]}${node.attrs["label"] ?? node.attrs["id"]}`;
			},
		});
		extensions.push(mentionExtension);
	}

	if (options?.enableImages) {
		extensions.push(Image);
	}

	return extensions;
}

export function getBackendRichTextExtensions() {
	return getRichTextExtensions({
		enableImages: true,
		enableMentions: true,
	});
}
