import { PropsWithChildren } from "react";
import { AbilityAction, AbilitySubject, SDObject, SDRecord, sdSubject } from "@salesdesk/salesdesk-schemas";

import { useGetRecordWithObjectByRecordId } from "../../../../../hooks";
import { useWebPrincipal } from "../../../../../auth";
import { RecordDetailsContext } from "../hooks/useRecordDetailsContext";

interface RecordDetailsProviderProps {
	sdRecord?: SDRecord;
	sdObject?: SDObject;
	isLoading?: boolean;
}

export function RecordDetailsProvider({
	sdRecord,
	sdObject,
	isLoading,
	children,
}: PropsWithChildren<RecordDetailsProviderProps>) {
	const principal = useWebPrincipal();
	const canEditRecord = sdRecord
		? principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Record, sdRecord))
		: false;

	const { sdObject: ownerObject, sdRecord: ownerRecord } = useGetRecordWithObjectByRecordId(
		sdRecord?._ownerId || undefined
	);

	return (
		<RecordDetailsContext.Provider value={{ sdRecord, sdObject, canEditRecord, isLoading, ownerObject, ownerRecord }}>
			{children}
		</RecordDetailsContext.Provider>
	);
}
