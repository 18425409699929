import { Skeleton } from "../../../../../components/Skeleton/Skeleton";

export function RecordFormLoading() {
	return (
		<div className="w-full columns-2 gap-10 pb-16 pt-8">
			{[...Array(2)].map((_, colIndex) => (
				<div key={colIndex} className="flex flex-col gap-8">
					{[...Array(6)].map((_, rowIndex) => (
						<Skeleton key={rowIndex} className="h-8 w-full" />
					))}
				</div>
			))}
		</div>
	);
}
