import { asConst } from "json-schema-to-ts";
import { ImportJobStatus } from "./ImportJobStatus";

export const importJobStatusSchema = asConst({
	title: "ImportJobStatus",
	description: "Indicates the state that the import job is in",
	type: "string",
	enum: Object.values(ImportJobStatus),
	nullable: false,
});
