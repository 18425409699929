import { useMemo } from "react";

import { useUserObjects } from "./useUserObjects";
import { SDObjectMap } from "../../objects/types";

export function useUserObjectsMap(skip = false): undefined | SDObjectMap {
	const userObjects = useUserObjects(skip);

	return useMemo(
		() => (userObjects ? new Map(userObjects.map((object) => [object._id, object])) : undefined),
		[userObjects]
	);
}
