import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../utils";
import { mMultiOptionFieldDef } from "./multi_option_field_def";
import { NO_LEGACY_BACKING_STORE_TYPE } from "@salesdesk/salesdesk-model";

export class mRolesFieldDef extends mMultiOptionFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TYPEAHEAD_MULTI_OPTION.name];
	static override defaultComponentType: FieldComponentName = mRolesFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id, NO_LEGACY_BACKING_STORE_TYPE);
		this._componentType = mRolesFieldDef.defaultComponentType;
		this._formatDescription = "Search roles";
		this._icon = FIELD_TYPES.ROLES.icon;
	}

	override get type(): string {
		return FIELD_TYPES.ROLES.name;
	}

	override get supportedComponentTypes() {
		return mRolesFieldDef.supportedComponentTypes;
	}
}
