import { CommentRespondedParams } from "@salesdesk/salesdesk-schemas";

import { useEventContext } from "../../../../hooks/useEventContext";
import { LazyCommentPayload } from "./LazyCommentPayload";
import { TipTapContentWrapper } from "../TipTapContentWrapper";

export function CommentRespondedPayload() {
	const { event } = useEventContext();
	const { rootCommentId, content } = event.params as CommentRespondedParams;

	return (
		<div className="flex flex-col gap-0.5">
			<LazyCommentPayload recordId={event.record_id} commentId={rootCommentId} />
			<div className="bg-c_border_regular h-4 w-px"></div>
			<TipTapContentWrapper createdUserName={event.username} tipTapContent={content} />
		</div>
	);
}
