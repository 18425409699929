import {
	FieldInfo,
	getSDRecordFieldValues,
	getSDRecordName,
	SDObject,
	SDRecord,
	getSDRecordFieldMap,
	getSDObjectFields,
	Field,
	getSDObjectNameFieldIds,
	getSDRecordFieldValue,
} from "@salesdesk/salesdesk-schemas";
import { mFieldDef, mStringFieldDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { FIELD_TYPES } from "@salesdesk/salesdesk-model";
import { FieldValueMap } from "../types";
import { convertFieldDefToField } from "../../fields";
import { ProfilePhotoInfo } from "../../fields/components/Fields/ProfilePhotoField/types";
import { LogoInfo } from "../../fields/components/Fields/LogoField";
import { createHashId } from "@salesdesk/salesdesk-utils";
import { getSDObjectFieldMap } from "../../objects/utils/objects";
import { PATHS } from "../../../routes";

export function getSDRecordFieldValueMap(sdRecord: SDRecord) {
	const fieldMap: FieldValueMap = {};

	getSDRecordFieldValues(sdRecord).forEach((field) => {
		fieldMap[field._fieldId] = field;
	});

	return fieldMap;
}

export function getSDRecordNameFieldInfo(sdObject: SDObject, sdRecord: SDRecord): FieldInfo {
	const nameFieldIds = getSDObjectNameFieldIds(sdObject);

	const nameFieldId = nameFieldIds.length === 1 ? nameFieldIds[0] : "name";

	const nameField = new mStringFieldDef(nameFieldId) as mFieldDef;
	nameField.name = "name";

	return {
		field: convertFieldDefToField(nameField, true),
		value: getSDRecordName(sdObject, sdRecord),
	};
}

export function getRecordSearchNoResultMessage(
	sdObject: SDObject,
	showSearchMessage: boolean,
	showFilterMessage: boolean,
	searchQuery?: string
) {
	let noResultsText = `No ${sdObject._pluralName.toLocaleLowerCase()} match the `;
	let clearButtonText = `Clear `;

	if (showSearchMessage && showFilterMessage) {
		noResultsText += `applied filters with the search "${searchQuery}"`;
		clearButtonText += `search & filters`;
	} else if (showSearchMessage) {
		noResultsText += `search "${searchQuery}"`;
		clearButtonText += `search`;
	} else {
		noResultsText += `applied filters`;
		clearButtonText += `filters`;
	}

	return {
		noResultsText,
		clearButtonText,
	};
}

export function getRecordFieldInfo(sdObject: SDObject, record: SDRecord, fieldIds?: number[]) {
	const fieldValueMap = getSDRecordFieldMap(record);
	const fieldsInfo: FieldInfo[] = [];

	if (fieldIds) {
		const objectFieldMap = getSDObjectFieldMap(sdObject);

		for (const fieldId of fieldIds) {
			const field = objectFieldMap[fieldId];

			if (!field) {
				continue;
			}

			fieldsInfo.push({
				field,
				value: formatFERecordFieldValue(sdObject, record, field, fieldValueMap[field._id]?._value),
			});
		}

		return fieldsInfo;
	}

	const objectFields = getSDObjectFields(sdObject);

	for (const field of objectFields) {
		fieldsInfo.push({
			field,
			value: formatFERecordFieldValue(sdObject, record, field, fieldValueMap[field._id]?._value),
		});
	}

	return fieldsInfo;
}

/**
 * Used to format the value of a record field stored in the backend for consumption by front-end components.
 *
 * Certain fields, such as the logo field and profile photo field, require additional details from other fields
 * to properly render their display components. E.G, if no image is provided for the logo field,
 * the company name is used to display the company initials.
 */
export function formatFERecordFieldValue(sdObject: SDObject, sdRecord: SDRecord, field: Field, fieldValue: unknown) {
	if (field._type === FIELD_TYPES.PROFILE_PHOTO.name) {
		const recordName = getSDRecordName(sdObject, sdRecord);

		return {
			fileId: typeof fieldValue === "number" ? fieldValue : null,
			fullName: recordName || "",
		} satisfies ProfilePhotoInfo;
	}

	if (field._type === FIELD_TYPES.LOGO.name) {
		const isWorkspaceRecord = sdRecord._objectDefId === mWorkspaceDef.ID;

		const companyNameFieldValue = isWorkspaceRecord
			? getSDRecordFieldValue(sdRecord, createHashId(mWorkspaceDef.COMPANY_NAME_FIELD_NAME))
			: null;
		const companyName = companyNameFieldValue ? companyNameFieldValue._value : null;

		return {
			fileId: typeof fieldValue === "number" ? fieldValue : null,
			companyName: companyName ? String(companyName) : getSDRecordName(sdObject, sdRecord),
		} satisfies LogoInfo;
	}

	return fieldValue;
}

export function addRecordIdsToIdList(recordIdList: SDRecord["_id"][], recordIds: SDRecord["_id"][]) {
	const combinedIds = new Set([...recordIdList, ...recordIds]);
	return Array.from(combinedIds);
}

export function filterRecordIdsFromIdList(recordIdList: SDRecord["_id"][], recordIds: SDRecord["_id"][]) {
	const filteredIds = new Set(recordIdList.filter((id) => !recordIds.includes(id)));
	return Array.from(filteredIds);
}
