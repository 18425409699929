import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../../../../fields";
import { AggregationResult } from "../../../../../../types";

interface ColumnAggregationResultProps {
	firstColumn?: boolean;
	fixedLabel?: boolean;
	aggregationResult: AggregationResult;
}

export function ColumnAggregationResult({ aggregationResult, firstColumn, fixedLabel }: ColumnAggregationResultProps) {
	const { field, aggregationName, value } = aggregationResult;
	return (
		<div className="text-c_text_secondary text-label-sm pt-1">
			<div className="h-6">
				{firstColumn ? (
					<div className={clsx("text-label-xs text-c_text_placeholder flex items-center gap-1", fixedLabel && "fixed")}>
						<Icon className="flex" size="xs" icon={field?._icon} />
						{field?._displayName}
					</div>
				) : null}
			</div>
			<div className="bg-c_bg_03 flex gap-2 rounded-sm px-4 py-1">
				<span>{aggregationName}:</span>
				<DisplayFieldFactory field={field} value={value} variant={DisplayFieldVariant.table} />
			</div>
		</div>
	);
}
