import { useMemo } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { SDObject, SDRecord, getSDObjectFieldsByNameMap } from "@salesdesk/salesdesk-schemas";
import { isSalesDeskUserObject, mUserDef } from "@salesdesk/salesdesk-model";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

import { RecordPreviewPopover, getSDRecordFieldValueMap } from "../../../../records";
import { useConvertUserSDRecordsToAvatarUsers, useUserLoginStatus } from "../../../hooks";
import { AvatarStatus, User } from "../../../types";
import { getInitials } from "../../../utils";
import { Avatar } from "../../Avatar";
import { UserWithDetailsLoading } from "./UserWithDetailsLoading";
import { UserWithDetailsSize } from "../types";
import { UserMeetings } from "./UserMeetings";
import { UserAuthorisedLoginIcon } from "../../UserAuthorisedLoginIcon";
import { useInviteUser } from "../../../hooks";
import { useWebPrincipal } from "../../../../../auth";
import { useOpenDMChannel } from "../../../../messaging";

export interface UserWithDetailsProps {
	userMeetingsId?: string;
	sdObject: SDObject;
	sdRecord: SDRecord;
	user?: User;
	status?: AvatarStatus;
	getStatusFromRecord?: "DEFAULT" | "POLL";
	size?: UserWithDetailsSize;
	withMeetings?: boolean;
	showInviteButton?: boolean;
}

export function UserWithDetails({
	userMeetingsId,
	sdRecord,
	sdObject,
	user,
	status,
	size = "default",
	withMeetings,
	getStatusFromRecord,
	showInviteButton,
}: UserWithDetailsProps) {
	const principal = useWebPrincipal();

	const { isNowActive, canLogIn } = useUserLoginStatus(sdRecord, sdObject, getStatusFromRecord === "POLL");

	let userInfo = useConvertUserSDRecordsToAvatarUsers(user ? undefined : sdRecord, sdObject);
	userInfo = user ?? userInfo;

	const { openDMChannel, isLoading: isLoadingDMChannel } = useOpenDMChannel();
	const showDMButton =
		isSalesDeskUserObject(sdRecord._objectDefId) &&
		principal.IsSalesDeskUser &&
		principal.UserRecordId !== sdRecord._id &&
		canLogIn;

	const { userTitle, userCompany, userDetails, linkedInUrl } = useMemo(() => {
		const sdObjectFieldsMap = getSDObjectFieldsByNameMap(sdObject);
		const sdRecordFieldsMap = getSDRecordFieldValueMap(sdRecord);

		const getFieldValueByName = (fieldName: string): string => {
			const fieldId = sdObjectFieldsMap[fieldName]?._id;

			return fieldId ? ((sdRecordFieldsMap[fieldId]?._value || "") as string) : "";
		};

		const userTitle = getFieldValueByName(mUserDef.TITLE_FIELD_NAME);
		const userCompany = getFieldValueByName(mUserDef.COMPANY_FIELD_NAME);

		return {
			userTitle,
			userCompany,
			userDetails: [userTitle, userCompany].filter(Boolean).join(", "),
			linkedInUrl: getFieldValueByName(mUserDef.LINKEDIN_FIELD_NAME),
		};
	}, [sdObject, sdRecord]);

	const setUserRecordToInvite = useInviteUser(sdObject, sdRecord);

	if (!userInfo) {
		return <UserWithDetailsLoading />;
	}

	const userDetailsContent =
		size === "lg" ? (
			<>
				<UserDetailsText text={userTitle} />
				<UserDetailsText text={userCompany} />
			</>
		) : (
			<UserDetailsText text={userDetails} />
		);

	return (
		<div className="flex w-full items-start gap-2">
			<RecordPreviewPopover recordId={userInfo.id}>
				<div className="shrink-0">
					<Avatar
						initials={getInitials(userInfo.fullName)}
						avatarFileId={userInfo.avatarFileId}
						size={size === "lg" ? "md" : "sm"}
						withBorder={size !== "lg"}
						status={getStatusFromRecord && isNowActive ? "ACTIVE" : status}
					/>
				</div>
			</RecordPreviewPopover>
			<div
				className={clsx(
					"flex min-h-full flex-col justify-center gap-0.5 overflow-hidden pb-0.5 pr-0.5",
					showInviteButton && "w-full"
				)}
			>
				<div className="flex justify-between">
					<div className={clsx(size === "lg" ? "text-label" : "text-label-sm", "flex items-center gap-1 truncate")}>
						<RecordPreviewPopover recordId={userInfo.id}>
							<span className="truncate">
								{userInfo.fullName}
								{principal.UserRecordId === userInfo.id ? " (You)" : ""}
							</span>
						</RecordPreviewPopover>
						<UserAuthorisedLoginIcon sdRecord={sdRecord} />
					</div>
					<div className="ml-2 flex items-center gap-2">
						{linkedInUrl ? (
							<Tooltip text="View LinkedIn">
								<Button
									as="link"
									startIcon={ICONS.linkedIn}
									to={linkedInUrl}
									size="xs"
									variant="text"
									target="_blank"
								/>
							</Tooltip>
						) : null}
						{withMeetings ? <UserMeetings id={userMeetingsId} userInfo={userInfo} /> : null}
						{showDMButton && openDMChannel ? (
							<Tooltip text="Send a message" placement="bottom">
								<Button
									startIcon={ICONS.chatsCircle}
									size="xs"
									variant="text"
									onClick={() => openDMChannel(sdRecord._id)}
									isLoading={isLoadingDMChannel}
								/>
							</Tooltip>
						) : null}
					</div>
				</div>
				{setUserRecordToInvite && showInviteButton ? (
					<div className="flex items-center justify-between">
						<div>{userDetailsContent}</div>
						<div>
							{setUserRecordToInvite ? (
								<Button
									variant="secondary"
									startIcon={ICONS.userCircle}
									size="sm"
									onClick={() => setUserRecordToInvite(sdRecord)}
								>
									Invite
								</Button>
							) : null}
						</div>
					</div>
				) : (
					userDetailsContent
				)}
			</div>
		</div>
	);
}

interface UserDetailsTextProps {
	text: string;
}

function UserDetailsText({ text }: UserDetailsTextProps) {
	if (!text) {
		return null;
	}

	return (
		<Tooltip text={text} showOnTruncated>
			<div className="text-body-sm text-c_text_secondary truncate">{text}</div>
		</Tooltip>
	);
}
