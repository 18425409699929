import { Dispatch, HTMLProps, SetStateAction, createContext } from "react";

export const MenuContext = createContext<{
	getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
	activeIndex: number | null;
	setActiveIndex: Dispatch<SetStateAction<number | null>>;
	setHasFocusInside: Dispatch<SetStateAction<boolean>>;
	isOpen: boolean;
}>({
	getItemProps: () => ({}),
	activeIndex: null,
	setActiveIndex: () => undefined,
	setHasFocusInside: () => undefined,
	isOpen: false,
});
