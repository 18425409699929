import { ReactNode } from "react";
import clsx from "clsx";

import { DisplayPlaceholderField } from "../../DisplayPlaceholderField";
import { TextInputVariant, Tooltip } from "@salesdesk/daisy-ui";
import { DisplayProgressField } from "./DisplayProgressField";
import { generateLinkForTextInputVariant } from "../utils";

interface DisplayTextFieldProps {
	value?: string;
	variant?: TextInputVariant;
	truncate?: boolean;
	placeholder?: string;
	interactive?: boolean;
	currencySymbol?: string;
}

export function DisplayTextField({
	value,
	variant = TextInputVariant.TEXT,
	truncate = true,
	interactive = true,
	placeholder,
	currencySymbol,
}: DisplayTextFieldProps) {
	let content: ReactNode = "";
	let containerClasses = "";

	if (!value) {
		return <DisplayPlaceholderField placeholder={placeholder} />;
	}

	switch (variant) {
		case TextInputVariant.TEXT: {
			content = value;
			break;
		}
		case TextInputVariant.EMAIL:
		case TextInputVariant.PHONE:
		case TextInputVariant.URL: {
			if (!interactive) {
				content = <span className="underline">{value}</span>;
			} else {
				content = (
					<a
						href={generateLinkForTextInputVariant(variant, value)}
						rel="noreferrer"
						target="_blank"
						onClick={(e) => e.stopPropagation()}
						onAuxClick={(e) => e.stopPropagation()}
						className="underline hover:no-underline"
					>
						{value}
					</a>
				);
			}

			break;
		}
		case TextInputVariant.PASSWORD: {
			content = "●".repeat(value.length);
			break;
		}
		case TextInputVariant.PROGRESS: {
			const { valueAsNumber } = convertValueToNumber(value);

			containerClasses = "w-full";
			content = <DisplayProgressField value={valueAsNumber} />;
			break;
		}
		case TextInputVariant.PROBABILITY: {
			const { formattedNumberString } = convertValueToNumber(value);
			content = `${formattedNumberString} %`;
			break;
		}
		case TextInputVariant.CURRENCY: {
			const { formattedNumberString } = convertValueToNumber(value);
			content = `${currencySymbol ?? ""}${formattedNumberString}`;
			break;
		}
		case TextInputVariant.NUMERIC: {
			const { formattedNumberString } = convertValueToNumber(value);
			content = formattedNumberString;
		}
	}

	return (
		<Tooltip showOnTruncated text={value}>
			<div className={clsx("max-w-full", truncate && "truncate", containerClasses)}>{content}</div>
		</Tooltip>
	);
}

function convertValueToNumber(value: string) {
	const valueAsNumber = parseFloat(value);
	return { valueAsNumber, formattedNumberString: isNaN(valueAsNumber) ? value : valueAsNumber.toLocaleString() };
}
