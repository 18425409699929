import { asConst } from "json-schema-to-ts";
import { fieldCreationTypeSchema } from "./fieldCreationTypeSchema";
import { appendNotEditable } from "../../../functions";

export const optionValueCreationTypeSchema = asConst({
	...fieldCreationTypeSchema,
	title: "SDObject Option Value Creation Type",
	description: appendNotEditable(
		"The creation type of the Option Value.  Defaulted to User=1 for user created Option Values"
	),
});
