import { useMemo } from "react";
import { RuleValue } from "../../types";
import { useNotificationRuleDialogContext } from "../useNotificationRuleDialogContext";

export function useUsersRuleValues() {
	const { selectedUsers, currentSelection, setCurrentSelection } = useNotificationRuleDialogContext();

	return useMemo(() => {
		const values: RuleValue[] = [];
		if (!selectedUsers?.length) {
			values.push({
				placeholder: "this person (optional)",
				onClick: () => setCurrentSelection("users"),
				active: currentSelection === "users",
			});
		} else {
			for (const option of selectedUsers) {
				values.push({
					value: option.name,
					onClick: () => setCurrentSelection("users"),
					active: currentSelection === "users",
				});
			}
		}
		return values;
	}, [selectedUsers, currentSelection, setCurrentSelection]);
}
