import { CSSProperties, MouseEvent, useEffect, useState } from "react";
import { isToday, isWeekend, minutesToMilliseconds } from "date-fns";
import clsx from "clsx";

import { CalendarCard } from "./CalendarCard";
import { CalendarCardDetails } from "../../types";
import { CURRENT_TIME_INDICATOR_ID, DATE_COLUMN_TOP_PADDING } from "../../utils";
import { getDateCardAbsolutePosition } from "../../utils/dateCalendar";
import {
	getTimeForYPositionInDateColumn,
	getYPositionForTimeInDateColumn,
	HOUR_BOARD_SIZE,
	TIMELINE_HOURS,
} from "../../utils/dateTimeCalendar";

interface DateColumnProps {
	date: Date;
	calendarCards: CalendarCardDetails[];
	showingTime?: boolean;
	onClick?: (date: Date) => void;
}

function getCurrentTimePositionOnColumn() {
	return `${getYPositionForTimeInDateColumn(new Date())}px`;
}

export function DateColumn({ date, calendarCards, showingTime, onClick }: DateColumnProps) {
	const [timeIndicatorPosition, setTimeIndicatorPosition] = useState<string>(getCurrentTimePositionOnColumn());

	const weekend = isWeekend(date);
	const isCurrentDate = isToday(date);

	const style: CSSProperties = { paddingTop: `${DATE_COLUMN_TOP_PADDING}px` };

	if (showingTime) {
		style.gap = `${HOUR_BOARD_SIZE}px`;
	}

	useEffect(() => {
		if (!(isCurrentDate || showingTime)) {
			return;
		}

		const updateTimeIndicator = () => {
			setTimeIndicatorPosition(getCurrentTimePositionOnColumn());
		};

		// Updates time indicator every minute
		const indicatorInterval = setInterval(updateTimeIndicator, minutesToMilliseconds(1));

		return () => {
			clearInterval(indicatorInterval);
		};
	}, [isCurrentDate, showingTime]);

	const onColumnClick = (e: MouseEvent<HTMLDivElement>) => {
		if (!onClick) {
			return;
		}
		const clickYPostion = e.clientY - e.currentTarget.getBoundingClientRect().top;
		const { hours, minutes } = getTimeForYPositionInDateColumn(clickYPostion);

		const dateTimeAtClick = new Date(date);
		dateTimeAtClick.setHours(hours, minutes, 0, 0);

		onClick(dateTimeAtClick);
	};

	return (
		<div
			className={clsx(
				weekend ? "bg-c_bg_04" : "bg-c_bg_01",
				showingTime ? "second:rounded-l-sm second:border-l" : "py-6 first:rounded-l-sm first:border-l",
				onClick && "cursor-pointer",
				"border-c_border_regular relative flex min-w-[140px] flex-1 flex-col overflow-visible border border-l-0 last:rounded-r-sm"
			)}
			onClick={onColumnClick}
			style={style}
		>
			{showingTime
				? TIMELINE_HOURS.map((hour) => {
						return <div key={hour} className="bg-c_border_regular -mt-px h-px w-full" />;
				  })
				: null}

			{calendarCards.map((cardDetails) => {
				if (!cardDetails) {
					return null;
				}

				return <CalendarCard key={cardDetails.id} showTime={showingTime} cardDetails={cardDetails} />;
			})}
			{/* Placeholder div with the height of the absolute positioned cards in the day column
			 to ensure correct scrolling behaviour and column length  */}
			{calendarCards.length && !showingTime ? (
				<div style={{ height: getDateCardAbsolutePosition(calendarCards.length - 1) }} />
			) : null}
			{isCurrentDate && showingTime ? (
				<div
					id={CURRENT_TIME_INDICATOR_ID}
					className="bg-c_brand_coral absolute z-[1000] h-px w-full"
					style={{ top: timeIndicatorPosition }}
				>
					<div className="bg-c_accent_01 -ml-[4.5px] -mt-[3.5px] h-2 w-2 rounded-full"></div>
				</div>
			) : null}
		</div>
	);
}
