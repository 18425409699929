import clsx from "clsx";

interface RoleSectionProps {
	hasBorder?: boolean;
}
export function RoleSection({ children, hasBorder = true }: React.PropsWithChildren<RoleSectionProps>) {
	return (
		<div className={clsx("border-b-c_border_regular flex flex-col gap-4 pb-8", hasBorder ? "border-b" : "border-b-0")}>
			{children}
		</div>
	);
}
