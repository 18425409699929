import { asConst } from "json-schema-to-ts";
import { FileType } from "./FileType";

export const fileTypeSchema = asConst({
	title: "File Type",
	description: "The type of file",
	type: "string",
	enum: Object.values(FileType),
	nullable: false,
});
