import {
	Import,
	ImportJob,
	ImportMappingEntry,
	ImportMappingEntryCreateRequest,
	SDObject,
} from "@salesdesk/salesdesk-schemas";
import { useContext, createContext, Dispatch } from "react";

export interface ImportState {
	sdObject: SDObject;

	sdImport: Import | undefined;
	setSdImport: Dispatch<React.SetStateAction<Import | undefined>>;

	importMappings: ImportMappingEntry[] | undefined;
	importMappingsIsLoading: boolean;

	mappingEntries: ImportMappingEntryCreateRequest[];
	updateMappingEntry: (columnName: string, fieldId: number | undefined) => void;
	clearMappingEntries: () => void;
	selectedMappingEntry: ImportMappingEntry | undefined;
	setSelectedMappingEntry: Dispatch<React.SetStateAction<ImportMappingEntry | undefined>>;

	previewImportJob: ImportJob | undefined;
	setPreviewImportJob: Dispatch<React.SetStateAction<ImportJob | undefined>>;

	actualImportJob: ImportJob | undefined;
	setActualImportJob: Dispatch<React.SetStateAction<ImportJob | undefined>>;

	setExitDialogOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const ImportContext = createContext<ImportState | undefined>(undefined);

export function useImportContext() {
	const context = useContext(ImportContext);
	if (!context) {
		throw new Error("useImportContext must be used within an ImportProvider");
	}
	return context;
}
