import clsx from "clsx";
import { format, isToday } from "date-fns";
import { Sticky } from "../../../../../../../components/Sticky";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";

interface DateColumnHeadersProps {
	dateRange: Date[];
	showingTime?: boolean;
}

export function DateColumnHeaders({ dateRange, showingTime }: DateColumnHeadersProps) {
	const workspaceId = useGetContextWorkspaceId();

	return (
		<Sticky
			priority={4}
			height={40}
			className="z-[2000] flex min-h-[40px] min-w-fit overflow-hidden"
			showCover={workspaceId != null}
		>
			{showingTime ? <div className="bg-c_bg_01 left-0 h-full w-[85px]" /> : null}
			{dateRange.map((date) => {
				const isCurrentDate = isToday(date);

				return (
					<div
						key={date.toDateString()}
						className="bg-c_bg_01 text-body-sm flex h-10 min-w-[140px] flex-1 items-center justify-center gap-2 px-4"
					>
						<div
							className={clsx(
								isCurrentDate && "bg-c_brand_primary text-c_text_inverted",
								"flex h-8 w-8 items-center justify-center rounded-full"
							)}
						>
							{format(date, "d")}
						</div>
						<div>{format(date, "EEE")}</div>
					</div>
				);
			})}
		</Sticky>
	);
}
