import { useCallback } from "react";

import { Comment, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../../../auth";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { useToast } from "../../../../../../Toasts";
import { useAddCommentReactionMutation, useRemoveCommentReactionMutation } from "../../../api/documentCommentsApi";

export function useToggleCommentReaction(documentRecordId?: SDRecord["_id"]) {
	const toast = useToast();

	const workspaceId = useGetContextWorkspaceId();

	const [addCommentReaction, { isLoading: isAddingReaction }] = useAddCommentReactionMutation();
	const [removeCommentReaction, { isLoading: isRemovingReaction }] = useRemoveCommentReactionMutation();

	const principal = useWebPrincipal();
	const userRecordId = principal.UserRecordId;

	return useCallback(
		(comment: Comment, emoji: string) => {
			if (isAddingReaction || isRemovingReaction || !documentRecordId) {
				return;
			}

			const existingReaction = comment.reactions?.find((reaction) => reaction.emoji === emoji);
			const hasMyReaction = existingReaction?.userRecordIds.includes(userRecordId);

			if (hasMyReaction) {
				removeCommentReaction({
					workspaceId,
					recordId: documentRecordId,
					commentId: comment.id,
					emoji,
				})
					.unwrap()
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: "reaction_deleted" });
					});
			} else {
				addCommentReaction({
					workspaceId,
					recordId: documentRecordId,
					commentId: comment.id,
					emoji,
				})
					.unwrap()
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: "reaction_added" });
					});
			}
		},
		[
			addCommentReaction,
			documentRecordId,
			isAddingReaction,
			isRemovingReaction,
			removeCommentReaction,
			toast,
			userRecordId,
			workspaceId,
		]
	);
}
