import { useEffect, useMemo, useRef } from "react";

import { mTaskDef } from "@salesdesk/salesdesk-model";
import {
	SDRecord,
	getSDRecordFieldValues,
	getSDObjectFields,
	FieldValue,
	rsr,
	getFieldIdsFromFieldNames,
	SDObject,
} from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../auth";
import { useGetObjectById } from "../../../../hooks";
import { SortingOrder, useInfiniteRecordSearch } from "../../../records";
import { Table, HeaderCell, ROW_IS_LOADING_PLACEHOLDER, TableVariant, ROW_LINK_COLUMN_ID } from "../../../Table";
import { FULL_RECORD_DATA_FIELD_ID } from "../../../ObjectBoard/types";
import { RecordRowControls, RECORD_ROW_CONTROLS_WIDTH } from "../../../RecordRowControls";
import { ControlColumnDetails, TableRow, TableColumn } from "../../../Table";
import { PATHS } from "../../../../routes";
import { TableRowCell } from "../../../ObjectBoard/components/ObjectBoardView/common/components/TableRowCell";

type DashboardTaskTableRowData = FieldValue | SDRecord | boolean;
type DashboardTaskTableRow = TableRow<DashboardTaskTableRowData>;
type DashboardTaskTableColumn = TableColumn<DashboardTaskTableRowData>;

export function DashboardTasks() {
	const tableRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const principal = useWebPrincipal();

	const { sdObject, isLoading: isSDObjectLoading } = useGetObjectById(mTaskDef.ID);

	const {
		updateSearchParams,
		sdRecords,
		isLoadingNewSearchParams: isLoading,
	} = useInfiniteRecordSearch({ limit: 6, sdObjectFilter: mTaskDef.ID });

	const skeletonPlaceholderRows = useMemo(
		() => Array.from({ length: 6 }, () => ({ [ROW_IS_LOADING_PLACEHOLDER]: true })),
		[]
	);

	const columns = useMemo(() => (sdObject ? generateColumns(sdObject) : []), [sdObject]);
	const rows = useMemo(
		() => (sdObject ? sdRecords.map((sdRecord) => generateRecordRowFromSDRecord(sdObject, sdRecord)) : []),
		[sdObject, sdRecords]
	);

	const columnWidthById = useMemo(() => {
		if (!columns[0]) return undefined;

		return { [columns[0].id]: 450 };
	}, [columns]);

	useEffect(() => {
		if (!sdObject || !principal.UserRecordId) return;

		const [nameFieldId, assigneeFieldId] = getFieldIdsFromFieldNames(sdObject, [
			mTaskDef.NAME_FIELD_NAME,
			mTaskDef.ASSIGNEE_FIELD_NAME,
		]);

		const rsrRequest = rsr
			.create()
			.query(
				rsr
					.query()
					.and(rsr.equals("_deleted", false))
					.and(rsr.equalsAnyOf(String(assigneeFieldId), [principal.UserRecordId]))
					.buildQuery()
			)
			.sort({ createdAt: { order: SortingOrder.asc } })
			.sort({ [nameFieldId]: { order: SortingOrder.asc } });

		updateSearchParams(rsrRequest.buildRequest());
	}, [updateSearchParams, sdObject, principal]);

	const controlColumn: ControlColumnDetails<DashboardTaskTableRowData> = useMemo(
		() => ({
			width: RECORD_ROW_CONTROLS_WIDTH,
			render: (row: DashboardTaskTableRow, index?: number) =>
				row[ROW_IS_LOADING_PLACEHOLDER] ? null : (
					<RecordRowControls
						sdRecord={row[FULL_RECORD_DATA_FIELD_ID] as SDRecord}
						scrollContainerRef={containerRef}
						tableRef={tableRef}
					/>
				),
		}),
		[]
	);

	return (
		<div ref={containerRef} className="flex h-full basis-full overflow-auto">
			<Table
				variant={TableVariant.secondary}
				columns={columns}
				rows={isLoading || isSDObjectLoading ? skeletonPlaceholderRows : rows}
				containerRightPadding={0}
				defaultColumnWidthByColumnId={columnWidthById}
				outsideRef={tableRef}
				controlColumn={controlColumn}
				outsideScrollContainerRef={containerRef}
			/>
		</div>
	);
}

function generateColumns(sdObject: SDObject) {
	const displayedFieldNames = [mTaskDef.NAME_FIELD_NAME, mTaskDef.STATUS_FIELD_NAME, mTaskDef.ASSIGNEE_FIELD_NAME];
	const displayedFields = getSDObjectFields(sdObject).filter((sdObjectField) =>
		displayedFieldNames.includes(sdObjectField._name)
	);
	const fieldColumns: DashboardTaskTableColumn[] = [];

	for (const field of displayedFields) {
		const id = String(field._id);

		fieldColumns.push({
			id,
			displayName: () => <HeaderCell columnId={id} columnName={field._displayName} />,
			renderRowCell: (recordField, row) => (
				<TableRowCell
					sdObject={sdObject}
					field={field}
					value={recordField}
					sdRecord={row[FULL_RECORD_DATA_FIELD_ID] as SDRecord}
					isLoading={row[ROW_IS_LOADING_PLACEHOLDER]}
				/>
			),
		});
	}

	return fieldColumns;
}

function generateRecordRowFromSDRecord(sdObject: SDObject, sdRecord: SDRecord) {
	const recordRow: DashboardTaskTableRow = {};

	getSDRecordFieldValues(sdRecord).forEach((field) => {
		if (!field._fieldId) {
			return;
		}

		recordRow[String(field._fieldId)] = field;
	});
	recordRow[FULL_RECORD_DATA_FIELD_ID] = sdRecord;
	recordRow[ROW_LINK_COLUMN_ID] = () => PATHS.OBJECT_RECORD_BOARD(sdObject, { recordId: sdRecord._id });

	return recordRow;
}
