import { asConst } from "json-schema-to-ts";

const coachingAdviceForCategorySchema = asConst({
	title: "Coaching advice for category",
	description: "Coaching advice for category",
	type: "object",
	required: ["rating", "explanation"],
	additionalProperties: false,
	properties: {
		rating: {
			type: "string",
		},
		explanation: {
			type: "string",
		},
	},
});

export const coachingAdviceSchema = asConst({
	title: "Coaching advice",
	description: "Coaching advice",
	type: "object",
	required: [
		"activeListening",
		"questioningTechniques",
		"handlingObjections",
		"valuePropositionCommunication",
		"closingTechniques",
	],
	additionalProperties: false,
	properties: {
		activeListening: coachingAdviceForCategorySchema,
		questioningTechniques: coachingAdviceForCategorySchema,
		handlingObjections: coachingAdviceForCategorySchema,
		valuePropositionCommunication: coachingAdviceForCategorySchema,
		closingTechniques: coachingAdviceForCategorySchema,
	},
});
