import { asConst } from "json-schema-to-ts";
import {
	objectColorSchema,
	objectDescriptionSchema,
	objectDisplayNameSchema,
	objectIconSchema,
	objectPluralNameSchema,
	objectSearchableSchema,
	objectSupportsActivitySchema,
	objectSupportsCommentsSchema,
	objectSupportsHistorySchema,
	objectDefaultViewScheme,
} from "./properties";
import { fieldGroupUpdateRequestSchema } from "./field";
import { booleanSchema } from "../components";

export const sdObjectUpdateRequestSchema = asConst({
	title: "SDObjectUpdateRequest",
	description: "Defines an object schema used for update requests",
	type: "object",
	additionalProperties: false,
	required: [
		"_displayName",
		"_pluralName",
		"_description",
		"_color",
		"_icon",

		"_commentsSupported",
		"_historySupported",
		"_activitySupported",
		"_searchable",
		"_defaultView",

		"_dataDef",
	],
	properties: {
		//TODO: Remove all underscores
		_displayName: objectDisplayNameSchema,
		_pluralName: objectPluralNameSchema,
		_description: objectDescriptionSchema,
		_color: objectColorSchema,
		_icon: objectIconSchema,

		// TODO:  Consistent "supports" flags naming
		_commentsSupported: objectSupportsCommentsSchema,
		_historySupported: objectSupportsHistorySchema,
		_activitySupported: objectSupportsActivitySchema,
		_searchable: objectSearchableSchema,
		_defaultView: objectDefaultViewScheme,

		// TODO:  Change this so its just a list of field and add any other properties directly on the object
		// If you need grouping then allow one level of grouping called fieldGroup
		_dataDef: fieldGroupUpdateRequestSchema,

		isTemplatable: booleanSchema,
	},
});
