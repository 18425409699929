import { FIELD_TYPES } from "../utils";
import { mProbabilityFieldDef } from "./probability_field_def";

export class mProgressFieldDef extends mProbabilityFieldDef {
	constructor(id: string | number) {
		super(id);
		this._icon = FIELD_TYPES.PROGRESS.icon;
	}

	override get type(): string {
		return FIELD_TYPES.PROGRESS.name;
	}

	override supportsMaxLength() {
		return false;
	}
}
