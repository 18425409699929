import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { ChannelType } from "@salesdesk/salesdesk-schemas";

import { UserLoginStatus } from "../../../../../../records";
import { useUserObjectsMap } from "../../../../../../users";
import { useChannelMemberRecordIds, useGetDMChannelUserRecord } from "../../../../../hooks";
import { ChannelDetails, LoadingStatus } from "../../../../../types";

import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { useChimeChannelStatusSelector } from "../../../../../store";

interface ChannelActiveStateProps {
	channelDetails: ChannelDetails;
}

export function ChannelActiveState({ channelDetails }: ChannelActiveStateProps) {
	const { arn, channelMetadata } = channelDetails;

	const isDMChannel = channelMetadata?.channelType === ChannelType.DirectMessage;

	const { userRecord } = useGetDMChannelUserRecord(channelDetails);
	const userObjectMap = useUserObjectsMap(!isDMChannel);
	const userObject = userRecord ? userObjectMap?.get(userRecord._objectDefId) : undefined;

	const { channelDetailsStatus } = useChimeChannelStatusSelector(channelDetails.arn);

	const members = useChannelMemberRecordIds(arn);
	const memberCount = members ? members.length : 0;

	return (
		<div className="text-label-xs text-c_text_secondary min-h-4">
			{isDMChannel ? (
				<UserLoginStatus userRecord={userRecord} userObject={userObject} size="xs" />
			) : memberCount ? (
				/* TODO: Show **active** member count */
				`${memberCount} ${pluralizeWithS("member", memberCount)}`
			) : channelDetailsStatus === LoadingStatus.Error ? null : (
				<Skeleton className="w-18 mt-0.5 h-3" />
			)}
		</div>
	);
}
