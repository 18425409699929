import { useCallback, useEffect, useState } from "react";

import { FileType } from "@salesdesk/salesdesk-schemas";
import { formatBytes } from "@salesdesk/salesdesk-utils";

import { PATHS, useStableNavigate } from "../../../../routes";
import { isSdErrorResponse } from "../../../../utils/validator";
import { useCreateImportMutation } from "../../api/importApi";
import { useImportContext } from "../../hooks/useImportContext";
import { useExitImportScreen } from "../../hooks/useExitImportScreen";
import { IMPORT_STEPS, ImportStep } from "../../utils";
import { useStoreFile } from "../../../files";
import { useSdFile } from "../../../files/hooks/useSdFile";
import { FileInput, FileInputErrorCode } from "../../../inputs";
import { ProgressStepper } from "../../../../components/ProgressStepper/ProgressStepper";
import { StepControls } from "../common/StepControls";
import { FilePreviewCard } from "./FilePreviewCard";

const MEGABYTE = 1024 * 1024;
const MAX_FILE_SIZE = 100 * MEGABYTE;

export function ImportUploadPage() {
	const navigate = useStableNavigate();
	const { sdObject, sdImport, setSdImport, clearMappingEntries } = useImportContext();

	const exitImportScreen = useExitImportScreen();

	const storeFile = useStoreFile(false, FileType.Import);
	const [uploadProgress, setUploadProgress] = useState<number | undefined>();
	const [createImport] = useCreateImportMutation();
	const [error, setError] = useState<string>();

	const { sdFile: importFile } = useSdFile(sdImport?.fileId);

	const onFileInputChange = useCallback(
		async (newFile: File | null, errorCode?: FileInputErrorCode, errorParams?: (string | number)[]) => {
			// 1. Handle errors
			if (!sdObject) return;
			if (errorCode === FileInputErrorCode.FILE_TOO_LARGE) {
				const bytes = errorParams?.[0];
				const formattedBytes = typeof bytes === "number" ? formatBytes(bytes) : "";
				setError(
					`File is too large ${formattedBytes ? `(${formattedBytes})` : ""}. Please upload a file smaller than ${formatBytes(MAX_FILE_SIZE)}.`
				);
				return;
			}
			if (errorCode === FileInputErrorCode.UNSUPPORTED_FILE_TYPE) {
				setError(`Invalid file type. Please upload a CSV file.`);
				return;
			}
			if (!newFile) return;

			// 2. Store file
			let fileId;
			try {
				fileId = await storeFile(newFile, (uploadProgress) => {
					setError(undefined);
					setUploadProgress(uploadProgress);
				});
			} catch (error) {
				if (!isSdErrorResponse(error) || !error.data.description) {
					setError("Something went wrong. Please try again.");
				} else {
					setError(error.data.description);
				}
				setUploadProgress(undefined);
				return;
			}
			if (!fileId) {
				setError("Something went wrong. Please try again.");
				return;
			}

			// 3. Create import
			try {
				const sdImport = await createImport({ objectId: sdObject._id, fileId }).unwrap();
				if (!sdImport) {
					throw new Error("Failed to create import");
				}
				if (sdImport.rowCount === 0 || sdImport.columnCount === 0) {
					throw new Error("The CSV file is empty. Please check the file and try again.");
				}
				clearMappingEntries();
				setSdImport(sdImport);
			} catch (error) {
				if (isSdErrorResponse(error)) {
					setError(error.data.description);
				} else {
					if (error instanceof Error) {
						setError(error.message);
					} else {
						setError("Something went wrong. Please try again.");
					}
				}
				setUploadProgress(undefined);
			}
		},
		[sdObject, storeFile, createImport, clearMappingEntries, setSdImport]
	);

	useEffect(() => {
		if (sdImport && importFile) {
			setUploadProgress(undefined);
		}
	}, [sdImport, importFile]);

	return (
		<div className="flex h-full w-full flex-col items-stretch overflow-auto">
			<div className="flex grow flex-col items-center px-6">
				<div className="flex h-full max-h-[460px] w-full max-w-[1130px] flex-col gap-4">
					<ProgressStepper currentStep={ImportStep.upload} steps={IMPORT_STEPS()} />
					<div className="text-label">Upload a CSV file</div>
					<div className="grow">
						<FileInput
							onChange={onFileInputChange}
							supportedMimeTypes="text/csv"
							maxFileSizeInBytes={MAX_FILE_SIZE}
							uploadProgress={uploadProgress}
							variant="fullPage"
							hasError={Boolean(error)}
							value={sdImport?.fileId}
							filePreviewOverride={sdImport && importFile ? <FilePreviewCard importFile={importFile} /> : undefined}
						/>
					</div>
				</div>
				{error ? <div className="text-c_danger_01 text-body-sm pt-2">{error}</div> : null}
				<div className="text-c_text_secondary text-body-sm py-6 text-center">
					Max size: {formatBytes(MAX_FILE_SIZE)}
				</div>
			</div>
			<div className="flex items-center px-6">
				<StepControls
					onSecondaryClick={exitImportScreen}
					primaryDisabled={!sdImport || !importFile}
					onPrimaryClick={() => navigate(PATHS.IMPORT_MAPPING(sdObject))}
				/>
			</div>
		</div>
	);
}
