import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { useGetFavoritedRecordsQuery } from "../api/favoritesApi";
import { useWatchedRecordsQuery } from "../api/watchesApi";
import { useMemo } from "react";

export function useGetRecordFollowingStatus(sdRecord?: SDRecord) {
	const { currentData: watchedRecordIds } = useWatchedRecordsQuery();
	const { currentData: favoritedRecordIds } = useGetFavoritedRecordsQuery();

	return {
		isWatched: useMemo(
			() => (sdRecord ? watchedRecordIds?.includes(sdRecord._id) ?? false : false),
			[sdRecord, watchedRecordIds]
		),
		isFavorited: useMemo(
			() => (sdRecord ? favoritedRecordIds?.includes(sdRecord._id) ?? false : false),
			[favoritedRecordIds, sdRecord]
		),
	};
}
