import { ERRORS, ErrorDetails } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { useParams } from "react-router-dom";

import { PATHS } from "../routes";
import { Icon } from "@salesdesk/daisy-ui";
import { Link } from "../components/Link";

// TODO: Placeholder Error Page. Replace in: https://salesdesk101.atlassian.net/browse/SAL-2280
export function ErrorPage() {
	const params = useParams<{ errorCode: string }>();

	let errorDetails: undefined | ErrorDetails;
	if (params.errorCode) {
		errorDetails = ERRORS[params.errorCode.toUpperCase()];
	}

	if (!errorDetails) {
		errorDetails = ERRORS.UNKNOWN_ERROR;
	}

	return (
		<div className="bg-c_bg_01 flex h-full w-full flex-col items-center justify-center gap-6">
			<div
				className={clsx(
					"shadow-popover text-label bg-c_danger_04 text-c_danger_01 flex w-[600px] rounded-md px-4 py-2"
				)}
			>
				<div className="flex w-full justify-stretch gap-3">
					<Icon icon={errorDetails.icon} className="my-1 flex h-6 items-center" />
					<div className="flex grow items-center">
						{errorDetails.body} <Link text="Return to SalesDesk" variant="danger" to={PATHS.DASHBOARD()} />
					</div>
				</div>
			</div>
		</div>
	);
}
