import { useMemo } from "react";
import { Button } from "@salesdesk/daisy-ui";
import { AssociationRecordsToLinkMap, CreateWorkspaceStepProps } from "../../../../types";
import { FieldInfo, getSDObjectFields, getSDObjectNameFieldIds } from "@salesdesk/salesdesk-schemas";
import { updateRecordFieldValues } from "../../../../../../../records";
import { DisplayFieldFactory } from "../../../../../../../fields";

import { Divider } from "../../../../../../../../components/Divider/Divider";
import { CardLabel } from "../../../../../../../../components/CardLabel/CardLabel";
import { RecordAssociationSummary } from "../../../../../../../recordAssociations";
import { createCustomerField, createInternalTeamField, getAssociationInfo } from "../utils";

interface ConfirmationPanelProps extends CreateWorkspaceStepProps {
	workspaceRecordData?: Record<string, any>;
	associationRecordsToLinkMap: AssociationRecordsToLinkMap;
	isCreating: boolean;
}

export function ConfirmationPanel({
	isCreating,
	workspaceRecordData,
	associationRecordsToLinkMap,
	workspaceObject,
	isVisible,
	nextStep,
	previousStep,
}: ConfirmationPanelProps) {
	const { associationsSummary, customerRecordIds, internalTeamMemberRecordIds } = useMemo(
		() => getAssociationInfo(associationRecordsToLinkMap),
		[associationRecordsToLinkMap]
	);

	const [workspaceName, workspaceDisplayFieldInfo] = useMemo(() => {
		if (!workspaceRecordData) {
			return ["", []];
		}

		const nameFieldIds = getSDObjectNameFieldIds(workspaceObject);
		const fields = getSDObjectFields(workspaceObject);

		const workspaceFieldValuesMap = new Map(
			updateRecordFieldValues(fields, workspaceRecordData).map((field) => [field._fieldId, field._value])
		);

		const workspaceDisplayFieldInfo: FieldInfo[] = [];

		fields.forEach((field) => {
			if (nameFieldIds.includes(field._id)) {
				return;
			}

			workspaceDisplayFieldInfo.push({ field, value: workspaceFieldValuesMap.get(field._id) ?? null });
		});

		const workspaceName = nameFieldIds
			.map((nameFieldId) => workspaceFieldValuesMap.get(nameFieldId) || "")
			.filter(Boolean)
			.join(" ");

		workspaceDisplayFieldInfo.push({
			field: createCustomerField(),
			value: customerRecordIds,
		});

		workspaceDisplayFieldInfo.push({
			field: createInternalTeamField(),
			value: internalTeamMemberRecordIds,
		});

		return [workspaceName, workspaceDisplayFieldInfo];
	}, [workspaceObject, workspaceRecordData, customerRecordIds, internalTeamMemberRecordIds]);

	if (!isVisible) {
		return null;
	}

	return (
		<div className="flex h-full flex-col overflow-hidden">
			<div className="mb-8 flex flex-col gap-4">
				<h4 className="text-h4">Confirm and create</h4>
			</div>
			<div className="flex h-full max-h-full overflow-hidden">
				<div className="rounded-card bg-c_bg_04 flex h-fit max-h-full w-full flex-col gap-6 overflow-auto px-6 py-4">
					<h4 className="text-h4">{workspaceName}</h4>
					<div className="columns-2 gap-10" style={{ columnWidth: 355 }}>
						{workspaceDisplayFieldInfo.map(({ field, value }) => {
							return (
								<div key={field._id} className="mb-6">
									<CardLabel label={field._displayName} icon={field._icon} required={field._required} vertical>
										<DisplayFieldFactory field={field} value={value} />
									</CardLabel>
								</div>
							);
						})}
						{associationsSummary.length ? (
							<div className="flex w-full flex-col gap-3">
								<Divider className="m-0" />
								<RecordAssociationSummary summaryData={associationsSummary} />
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className="mt-4 flex justify-end gap-3">
				<Button disabled={isCreating} onClick={previousStep} variant="secondary" size="lg">
					Previous
				</Button>
				<Button isLoading={isCreating} onClick={nextStep} size="lg" variant="primary">
					Confirm
				</Button>
			</div>
		</div>
	);
}
