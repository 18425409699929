import { FieldInfo, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { EditFieldFactory } from "../../../../../../../../fields";
import { useInlineEditField } from "../../../../../../RecordFields";
import { DisplayRecordTitleField } from "./DisplayRecordTitleField";

interface EditableRecordTitleFieldProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
	titleFieldInfo: FieldInfo;
}

export function EditableRecordTitleField({ sdObject, sdRecord, titleFieldInfo }: EditableRecordTitleFieldProps) {
	const { field, value } = titleFieldInfo;

	const { inlineRecordFieldProps, error, updateEditMode } = useInlineEditField(sdObject, sdRecord, field);
	const { ref, editMode, componentProps, canEditField } = inlineRecordFieldProps;

	const turnEditModeOn = () => updateEditMode(true);

	if (editMode) {
		/*
        Currently Title in edit mode use input which not allow user display/type text in multiple lines

        TODO: Create TextField with textarea extends with not supporting pressing 'enter'
        */

		return (
			<>
				<div className="text-h3 flex min-h-[48px]" onClick={turnEditModeOn}>
					<EditFieldFactory componentProps={{ ...componentProps, inputRef: ref }} field={field} />
				</div>
				{error ? <p className="text-body text-c_danger_02 col-span-7 col-start-4">{error}</p> : null}
			</>
		);
	}

	const handleClick = canEditField ? turnEditModeOn : undefined;

	return <DisplayRecordTitleField onClick={handleClick}>{value as string}</DisplayRecordTitleField>;
}
