import clsx from "clsx";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { Link } from "../../../components/Link";
import { DirectedSDObjectAssociation, useAssociationDataboardUrl } from "../hooks";
import { RecordAssociationOpeningVariant } from "../types";

interface RecordAssociationListFooterProps {
	isLastPage: boolean;
	loadNextPage: () => void;
	associationObject: SDObject;
	objectAssociation: DirectedSDObjectAssociation;
	sourceRecord?: SDRecord;
	openingVariant?: RecordAssociationOpeningVariant;
}

export function RecordAssociationListFooter({
	isLastPage,
	loadNextPage,
	associationObject,
	objectAssociation,
	sourceRecord,
	openingVariant,
}: RecordAssociationListFooterProps) {
	const { associationDataboardUrl, associationDataboardLinkText } = useAssociationDataboardUrl(
		associationObject,
		objectAssociation,
		sourceRecord?._id,
		openingVariant === "workspace"
	);

	return (
		<div className={clsx(isLastPage ? "justify-end" : "justify-between", "mt-4 flex")}>
			{!isLastPage && (
				<Button size="sm" variant="primary_text" onClick={() => loadNextPage()}>
					Show more
				</Button>
			)}
			{associationDataboardUrl ? (
				<Link size="sm" to={associationDataboardUrl} text={associationDataboardLinkText} />
			) : null}
		</div>
	);
}
