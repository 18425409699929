import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MediaAnalysisContext } from "../hooks/useMediaAnalisysContext";
import { FaceSentiment, TranscriptSentiment, UserIdOrPlaceholderName, ZOOM_FACTORS } from "../types";
import { SDFile, SentimentMap, TranscriptItem } from "@salesdesk/salesdesk-schemas";
import { keyToPlaceholderName } from "../utils";

interface MediaAnalysisProviderProps {
	file: SDFile;
	updatePreventModalDismiss?: (preventModalDismiss: boolean) => void;
}

export function MediaAnalysisProvider({
	children,
	file,
	updatePreventModalDismiss,
}: PropsWithChildren<MediaAnalysisProviderProps>) {
	const [videoDuration, setVideoDuration] = useState<number>(0);
	const [onlyTotalTalkingTime, setOnlyTotalTalkingTime] = useState<boolean>(false);
	const [anyPopoverSelectOpen, setAnyPopoverSelectOpen] = useState<boolean>(false);
	const faceSentimentGraphHolderRef = useRef<HTMLDivElement>(null);
	const faceSentimentGraphRef = useRef<HTMLDivElement>(null);
	const transcriptSentimentGraphRef = useRef<HTMLDivElement>(null);
	const [zoomIndex, setZoomIndex] = useState<number>(0);
	const [viewStartTime, setViewStartTime] = useState<number>(0);
	const [isPanning, setIsPanning] = useState<boolean>(false);
	const [faceSentimentGraphOpen, setFaceSentimentGraphOpen] = useState<boolean>(true);
	const [transcriptSentimentGraphOpen, setTranscriptSentimentGraphOpen] = useState<boolean>(true);
	const [open, setOpen] = useState<boolean>(true);
	const videoRef = useRef<HTMLVideoElement>(null);

	const [sentimentAnalysis, setSentimentAnalysis] = useState<SentimentMap>();
	const [isFetchingSentimentAnalysis, setIsFetchingSentimentAnalysis] = useState<boolean>(true);

	const [transcriptItems, setTranscriptItems] = useState<TranscriptItem[]>();
	const [isFetchingTranscript, setIsFetchingTranscript] = useState<boolean>(true);

	useEffect(() => {
		if (!file.sentimentAnalysisSignedUrl) {
			setIsFetchingSentimentAnalysis(false);
			return;
		}

		fetch(file.sentimentAnalysisSignedUrl)
			.then((response) => response.json())
			.then((data) => {
				setSentimentAnalysis(data);
				setIsFetchingSentimentAnalysis(false);
			})
			.catch((data) => {
				console.error(data);
				setIsFetchingSentimentAnalysis(false);
			});
	}, [file]);

	useEffect(() => {
		if (!file.transcriptSignedUrl) {
			setIsFetchingTranscript(false);
			return;
		}

		fetch(file.transcriptSignedUrl)
			.then((response) => response.json())
			.then((data) => {
				setTranscriptItems(data);
				setIsFetchingTranscript(false);
			})
			.catch((data) => {
				console.error(data);
				setIsFetchingSentimentAnalysis(false);
			});
	}, [file.transcriptSignedUrl]);

	const isMediaAnalysisLoading = isFetchingTranscript || isFetchingSentimentAnalysis;

	const updateIsPanning = useCallback(
		(isPanning: boolean) => {
			setIsPanning(isPanning);
			setTimeout(() => {
				if (updatePreventModalDismiss) {
					updatePreventModalDismiss(isPanning);
				}
			});
		},
		[updatePreventModalDismiss]
	);

	const [faceSentimentsPerUser, transcriptSentimentsPerUser, userKeyToUserId] = useMemo(() => {
		if (!sentimentAnalysis) {
			return [undefined, undefined, {}];
		}
		const keyToUserId: Record<string, UserIdOrPlaceholderName> = {};
		const faceSentimentsPerUser: Record<string, FaceSentiment[]> = {};
		const transcriptSentimentsPerUser: Record<string, TranscriptSentiment[]> = {};
		for (const [key, value] of Object.entries(sentimentAnalysis)) {
			keyToUserId[key] = value.userId ?? keyToPlaceholderName(key);
			if (value.faceSentiment) faceSentimentsPerUser[key] = JSON.parse(value.faceSentiment) as FaceSentiment[];
			if (value.transcriptSentiment)
				transcriptSentimentsPerUser[key] = JSON.parse(value.transcriptSentiment) as TranscriptSentiment[];
		}
		return [faceSentimentsPerUser, transcriptSentimentsPerUser, keyToUserId];
	}, [sentimentAnalysis]);

	return (
		<MediaAnalysisContext.Provider
			value={{
				file,
				isMediaAnalysisLoading,
				userKeyToUserId,
				faceSentimentsPerUser,
				transcriptSentimentsPerUser,
				transcriptItems,
				videoDuration,
				updateVideoDuration: setVideoDuration,
				onlyTotalTalkingTime,
				setOnlyTotalTalkingTime,
				faceSentimentGraphHolderRef,
				faceSentimentGraphRef,
				transcriptSentimentGraphRef,
				anyPopoverSelectOpen,
				setAnyPopoverSelectOpen,
				zoomIndex,
				zoomFactor: ZOOM_FACTORS[zoomIndex],
				setZoomIndex,
				viewStartTime,
				setViewStartTime,
				isPanning,
				setIsPanning: updateIsPanning,
				faceSentimentGraphOpen,
				setFaceSentimentGraphOpen,
				transcriptSentimentGraphOpen,
				setTranscriptSentimentGraphOpen,
				open,
				setOpen,
				videoRef,
			}}
		>
			{children}
		</MediaAnalysisContext.Provider>
	);
}
