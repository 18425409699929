import { HeaderGroup } from "@tanstack/react-table";

import { Sticky } from "../../../components/Sticky";
import {
	COLUMN_TYPE,
	EndColumnDetails,
	ROW_SELECTION_COLUMN_ID,
	TableColumnId,
	TableRow,
	TableVariant,
} from "../types";
import { ColumnHeader } from "./ColumnHeader/ColumnHeader";

interface TableHeaderProps<T> {
	headerGroups: HeaderGroup<TableRow<T>>[];
	isPrimaryLinkedTable?: boolean;
	refreshKey?: number;
	endColumnDetails?: EndColumnDetails;
	updateEndColumnDetails: (columnId: TableColumnId, width: number) => void;
	hideHeader?: boolean;
	inSelectionMode?: boolean;
	variant?: TableVariant;
	sticky: boolean;
}

export function TableHeader<T>({
	headerGroups,
	refreshKey,
	isPrimaryLinkedTable,
	endColumnDetails,
	updateEndColumnDetails,
	hideHeader,
	inSelectionMode,
	variant,
	sticky,
}: TableHeaderProps<T>) {
	const columnHeaders = headerGroups.map((headerGroup) => (
		<tr key={headerGroup.id}>
			{headerGroup.headers.map((header, index) => {
				let columnType = COLUMN_TYPE.CENTER;

				const numOfColumns = headerGroup.headers.length;

				// Due to invisible row link column, leftmost/start column is at index 1
				if (numOfColumns <= 2) {
					columnType = COLUMN_TYPE.SINGLE;
				} else if (index === numOfColumns - 1) {
					columnType = COLUMN_TYPE.END;
				} else if (index === 1) {
					columnType = COLUMN_TYPE.START;
				}

				const mightNeedRefresh = isPrimaryLinkedTable && header.id === ROW_SELECTION_COLUMN_ID;
				return (
					<ColumnHeader
						key={header.id + (mightNeedRefresh ? refreshKey : "")}
						header={header}
						columnType={columnType}
						endColumnDetails={endColumnDetails}
						updateEndColumnDetails={updateEndColumnDetails}
						hideHeader={hideHeader}
						inSelectionMode={inSelectionMode}
						variant={variant}
						isPrimaryLinkedTable={isPrimaryLinkedTable}
					/>
				);
			})}
		</tr>
	));

	if (sticky) {
		return (
			<Sticky as="thead" priority={3} height={40} className="z-10">
				{columnHeaders}
			</Sticky>
		);
	}
	return <thead>{columnHeaders}</thead>;
}
