import { useMemo } from "react";

import { mDocDef } from "@salesdesk/salesdesk-model";
import { SDObject, SDRecord, getSDObjectSystemFieldByName, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";

import { useGetRecordDetailsPath } from "../../../records";
import { DocumentPreview } from "./DocumentPreview";

interface RecordDocumentPreviewProps {
	sdRecord?: SDRecord;
	sdObject?: SDObject;
}

export function RecordDocumentPreview({ sdRecord, sdObject }: RecordDocumentPreviewProps) {
	const documentLink = useGetRecordDetailsPath(sdRecord, sdObject);

	const documentsContentsRecordField = useMemo(() => {
		if (!sdObject || sdObject._id !== mDocDef.ID) {
			return null;
		}

		const contentsField = getSDObjectSystemFieldByName(sdObject, mDocDef.DOCUMENT_FIELD_NAME);
		if (!sdRecord || !contentsField) {
			return null;
		}

		return getSDRecordFieldValue(sdRecord, contentsField._id) ?? null;
	}, [sdObject, sdRecord]);

	if (!documentsContentsRecordField || !sdRecord || !sdObject) {
		return null;
	}

	return (
		<DocumentPreview documentContents={documentsContentsRecordField._value ?? undefined} documentLink={documentLink} />
	);
}
