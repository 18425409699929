import { useCallback } from "react";

import { PostEventArgs, usePostEventMutation } from "../api/eventsApi";
import { useGetContextWorkspaceId } from "../../workspaces";

export function usePostEvent() {
	const workspaceId = useGetContextWorkspaceId();

	const [postEvent] = usePostEventMutation();

	return useCallback(
		(args: PostEventArgs) => {
			return postEvent({ ...args, workspaceId }).unwrap();
		},
		[postEvent, workspaceId]
	);
}
