import { useEffect } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { ERRORS, ICONS } from "@salesdesk/salesdesk-ui";

import { ObjectDesigner } from "./ObjectDesigner";
import { useGetObjectFromURLPathById } from "../../../../../hooks";
import { PATHS, useStableNavigate } from "../../../../../routes";
import { SettingsContainer } from "../../../SettingsContainer";

export function ObjectDesignerPage() {
	const { isLoading, sdObject } = useGetObjectFromURLPathById();

	const navigate = useStableNavigate();

	useEffect(() => {
		if (!sdObject && !isLoading) {
			navigate(PATHS.ERROR(ERRORS.RESOURCE_NOT_FOUND.code.toLowerCase()), { replace: true });
		}
	}, [sdObject, isLoading, navigate]);

	if (isLoading || !sdObject) {
		return null;
	}

	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<SettingsContainer
				breadcrumbs={[
					{
						icon: ICONS.settings,
						node: "Settings",
					},
					{
						node: "Object manager",
						link: PATHS.OBJECT_MANAGER(),
					},
					{
						icon: sdObject._icon,
						node: sdObject._pluralName,
						link: PATHS.OBJECT_DESIGNER(sdObject),
					},
				]}
			>
				<ObjectDesigner sdObject={sdObject} />
			</SettingsContainer>
		</QueryParamProvider>
	);
}
