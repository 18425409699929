import { RecordUpdateFieldDiff } from "@salesdesk/salesdesk-model";
import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";

export function RecordUpdatedObject() {
	const { event, target } = useEventContext();
	const fieldUpdatesDiff: RecordUpdateFieldDiff[] = event.params?.diff
		? JSON.parse(event.params.diff as string)
		: undefined;

	const notificationLink =
		target === "NOTIFICATION" ? (
			<>
				in <LinkObject />
			</>
		) : null;

	if (fieldUpdatesDiff.length === 1) {
		return (
			<>
				the '{fieldUpdatesDiff[0].displayName}' field {notificationLink}
			</>
		);
	}
	return <>multiple fields {notificationLink}</>;
}
