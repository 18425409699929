import { forwardRef, useState } from "react";
import { DateTimePickerInputProps } from "../../types";
import { DateTimePickerController } from "./DateTimePickerController";
import { DateTimeTextInput } from "./DateTimeTextInput";
import { PickerInput } from "../DateTimePicker/PickerInput";

export const DateTimePickerInput = forwardRef<
	HTMLInputElement,
	Omit<DateTimePickerInputProps, "isPickerOpen" | "setIsPickerOpen">
>(({ ...props }, ref) => {
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	return (
		<PickerInput
			mRef={ref}
			isPickerOpen={isPickerOpen}
			setIsPickerOpen={setIsPickerOpen}
			textInput={(props) => <DateTimeTextInput {...props} />}
			picker={(props) => <DateTimePickerController {...props} />}
			{...props}
		/>
	);
});
