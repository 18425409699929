import { Field } from "@salesdesk/salesdesk-schemas";

import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";

interface UpdatedFieldProps {
	field: Field;
	value: any;
}

export function UpdatedField({ field, value }: UpdatedFieldProps) {
	if (!value) {
		return <div className="text-c_text_placeholder text-body-sm flex items-center p-1">Empty</div>;
	}
	return (
		<div className="p-1">
			<DisplayFieldFactory field={field} value={value} variant={DisplayFieldVariant.table} />
		</div>
	);
}
