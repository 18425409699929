import clsx from "clsx";

interface PreviewColorPickerFieldProps {
	error?: boolean;
}

export function PreviewColorPickerField({ error }: PreviewColorPickerFieldProps) {
	return (
		<div
			className={clsx(error ? "border-c_danger_02" : "border-c_border_btn", "bg-c_bg_02 h-8 w-8 rounded-full border-2")}
		/>
	);
}
