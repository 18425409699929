import { useCallback, useState } from "react";
import { LocalVideoTrack, Room } from "twilio-video";

import { SdEventType } from "@salesdesk/salesdesk-model";
import { getMeetingRecordIdFromRoomName } from "@salesdesk/salesdesk-utils";

import { useTriggerVideoCallEvent } from "../../../../VideoCallEvents";
import { useVideoCallMediaContext } from "./useVideoCallMediaContext";
import { usePostEvent } from "../../../../../../events/hooks/usePostEvent";

export function useLocalVideoToggle(room: Room | null) {
	const { localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError } = useVideoCallMediaContext();
	const postEvent = usePostEvent();

	const localParticipant = room?.localParticipant;
	const videoTrack = localTracks.find(
		(track) => !track.name.includes("screen") && track.kind === "video"
	) as LocalVideoTrack;
	const [isPublishing, setIspublishing] = useState(false);

	const triggerVideoCallEvent = useTriggerVideoCallEvent();

	const toggleVideoEnabled = useCallback(() => {
		if (isPublishing) {
			return;
		}

		let event_type: SdEventType;
		if (videoTrack) {
			const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
			// TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
			localParticipant?.emit("trackUnpublished", localTrackPublication);
			removeLocalVideoTrack();

			event_type = SdEventType.CAMERA_DISABLED;
		} else {
			setIspublishing(true);
			getLocalVideoTrack()
				.then((track?: LocalVideoTrack) => {
					// TODO: Better error handling for when the video track can't be retrieved
					if (!track) {
						throw new Error(`Can't retrieve local video track`);
					}

					localParticipant?.publishTrack(track, { priority: "low" });
				})
				.catch(onError)
				.finally(() => {
					setIspublishing(false);
				});

			event_type = SdEventType.CAMERA_ENABLED;
		}

		if (room !== null) {
			triggerVideoCallEvent(event_type === SdEventType.CAMERA_DISABLED ? "video_disabled" : "video_enabled");

			postEvent({
				event_type,
				record_id: getMeetingRecordIdFromRoomName(room.name),
				params: {},
			});
		}
	}, [
		videoTrack,
		localParticipant,
		getLocalVideoTrack,
		isPublishing,
		onError,
		removeLocalVideoTrack,
		postEvent,
		room,
		triggerVideoCallEvent,
	]);

	return [!!videoTrack, toggleVideoEnabled] as const;
}
