import { forwardRef } from "react";
import clsx from "clsx";

import { Icon, InputComponentProps, ErrorInputComponentProps } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface CalendarButtonProps
	extends Pick<InputComponentProps, "disabled">,
		Pick<ErrorInputComponentProps, "hasError"> {
	isPickerOpen: boolean;
	onClearButtonClicked: () => void;
	onTextInputBlur: (event: React.FocusEvent) => void;
}

export const CalendarClearButton = forwardRef<HTMLButtonElement, CalendarButtonProps>(
	({ isPickerOpen, onClearButtonClicked, onTextInputBlur, hasError, disabled }, ref) => {
		return (
			<button
				ref={ref}
				className={clsx("focus-visible:ring-c_action_focus mr-2 focus-visible:ring", isPickerOpen && "rounded-full")}
				onClick={(e) => {
					e.preventDefault();

					if (isPickerOpen) {
						e.stopPropagation();
						onClearButtonClicked();
					}
				}}
				tabIndex={!isPickerOpen ? -1 : undefined}
			>
				<Icon
					className={clsx(
						"flex items-center",
						!hasError && !disabled && "text-c_icon_regular",
						hasError && "text-c_danger_02",
						disabled && "text-c_icon_disabled"
					)}
					icon={isPickerOpen ? ICONS.crossCircle : ICONS.calendar}
					variant={isPickerOpen ? "fill" : "outline"}
				/>
			</button>
		);
	}
);
