import { PropsWithChildren } from "react";
import clsx from "clsx";

export function SettingGroup({ children, title }: PropsWithChildren<{ title?: string }>) {
	return (
		<div className={clsx("flex flex-col gap-6", title && "border-c_border_regular border-t pt-6")}>
			{title ? <span className="text-c_text_secondary text-label">{title}</span> : null}
			{children}
		</div>
	);
}
