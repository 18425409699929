import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES, getFieldDisplayName } from "../utils";
import { FieldDefFactory } from "../field_def_factory";
import { mFieldDef } from "../field_def";

export class mContainerFieldDef extends mFieldDef {
	_children: any[];

	constructor(id: string | number) {
		super(id);
		this._children = []; // A container has multiple children
		this._icon = FIELD_TYPES.CONTAINER.icon;
	}

	override get type(): string {
		return FIELD_TYPES.CONTAINER.name;
	}

	override supportsTableCellView() {
		return false;
	}

	override supportsCardView() {
		return false;
	}

	getTableCellFields() {
		const fields = [];

		const children = this.children;

		for (let i = 0; children.length > i; i++) {
			const field = children[i];

			if (field.showTableView() && !field.hidden) {
				fields.push(field);
			}
		}
		return fields;
	}

	getCardFields() {
		const fields = [];

		const children = this.children;

		for (let i = 0; children.length > i; i++) {
			const field = children[i];

			if (field.showCardView() && !field.hidden) {
				fields.push(field);
			}
		}
		return fields;
	}

	getPreviewFields() {
		const fields = [];

		const children = this.children;

		for (let i = 0; children.length > i; i++) {
			const field = children[i];

			if (field.showPreview() && !field.hidden) {
				fields.push(field);
			}
		}
		return fields;
	}

	createName(type: any) {
		while (true) {
			const variableName = `New ${getFieldDisplayName(type)}`;
			if (!this.getRoot().containsVariable(variableName)) {
				return variableName;
			}
		}
	}

	addChild(child: mFieldDef, index?: number) {
		if (!child.hasName()) {
			child.name = this.createName(child.type);
		}

		if (child.type === FIELD_TYPES.DATA.name) {
			throw new Error(`"${FIELD_TYPES.DATA.name}" cannot be a child of another element.`);
		}

		// Check no other fields of this type name already exist...
		// We want the name to be unique

		let childExists = false;

		try {
			this.getRoot().getFieldByName(child.name);
			childExists = true;
		} catch (err) {
			// ignore
		}

		if (childExists) {
			throw Error(`A field with the name "${child.name}" already exists.`);
		}

		child.parent = this;

		if (!index) {
			this._children.push(child);
		} else {
			this._children.splice(index, 0, child);
		}
	}

	removeChild(child: mFieldDef) {
		this._children.splice(this._children.indexOf(child), 1);
	}

	get children() {
		return this._children;
	}

	set children(children) {
		this._children = [];

		for (let i = 0; children.length > i; i++) {
			this.addChild(children[i]);
		}
	}

	getChildren() {
		return this._children;
	}

	override hasChildren() {
		return !isEmpty(this._children) && this._children.length > 0;
	}

	override supportsChildren() {
		return true;
	}

	fieldsLoaded() {
		return true;
	}

	fieldExistsById(id: string | number) {
		if (this._id === id) {
			return true;
		} else {
			const field = this.doGetFieldById(id, this.children);
			return !!field;
		}
	}

	getFieldById(id: string | number) {
		let field = null;

		if (this._id === id) {
			return this;
		} else {
			field = this.doGetFieldById(id, this.children);
		}

		if (!field) {
			throw new Error(`Field with id ${id} not found!`);
		}
		return field;
	}

	doGetFieldById(id: string | number, children: any[]): any {
		let field = null;

		for (let i = 0; children.length > i; i++) {
			const thisField = children[i];

			if (thisField._id === id) {
				return thisField;
			} else if (thisField.children) {
				field = this.doGetFieldById(id, thisField.children);
			}
			if (field !== null) {
				return field;
			}
		}
		return null;
	}

	containsFieldType(type: any) {
		return this.getFieldsByType(type).length > 0;
	}

	getFieldsByType(type: any) {
		const instances: any[] = [];

		if (this instanceof type) {
			instances.push(this);
		}

		if (this.hasChildren()) {
			this.doGetFieldsByType(this.children, instances, type);
		}

		return instances;
	}

	doGetFieldsByType(children: any[], instances: any[], type: any) {
		for (let i = 0; children.length > i; i++) {
			const child = children[i];

			if (child instanceof type) {
				instances.push(this);
			}

			if (child.hasChildren() && child.childrenLoaded()) {
				this.doGetFieldsByType(child.children, instances, type);
			}
		}
	}

	// getAllDescendants() {
	// 	return this.getAllInstances();
	// }
	//
	// doGetAllDescendants(children, instances) {
	// 	for (let i = 0; children.length > i; i++) {
	// 		let child = children[i];
	//
	// 		instances.push(child);
	//
	// 		if (child.hasChildren() && child.childrenLoaded()) {
	// 			this.doGetAllDescendants(child.children, instances);
	// 		}
	// 	}
	// }
	//
	// getAllDescendants() {
	// 	let instances = [];
	//
	// 	if (this.hasChildren()) {
	// 		this.doGetAllDescendants(this.children, instances);
	// 	}
	//
	// 	return instances;
	// }
	//
	// doGetAllDescendants(children, instances) {
	// 	for (let i = 0; children.length > i; i++) {
	// 		let child = children[i];
	//
	// 		if (child.hasChildren() && child.childrenLoaded()) {
	// 			this.doGetAllDescendants(child.children, instances);
	// 		}
	// 	}
	// }

	override getAllInstances() {
		const instances = [];

		instances.push(this);

		if (this.hasChildren()) {
			this.doGetAllInstances(this.children, instances);
		}

		return instances;
	}

	doGetAllInstances(children: any[], instances: any[]) {
		for (let i = 0; children.length > i; i++) {
			const child = children[i];

			instances.push(child);

			if (child.hasChildren() && child.childrenLoaded()) {
				this.doGetAllInstances(child.children, instances);
			}
		}
	}

	containsVariable(name: string) {
		try {
			this.getFieldByName(name);
			return true;
		} catch (err) {
			return false;
		}
	}

	getFieldByName(name: string) {
		let field = null;

		if (this._name === name) {
			return this;
		} else {
			field = this.doGetFieldByName(name, this.children);
		}
		if (!field) {
			throw new Error(`Field with name "${name}"  not found!`);
		}
		return field;
	}

	doGetFieldByName(name: string, children: any[]) {
		let field = null;

		for (let i = 0; children.length > i; i++) {
			const thisField = children[i];

			if (thisField._name === name) {
				return thisField;
			} else if (thisField.children) {
				field = this.doGetFieldById(name, thisField.children);
			}
			if (field !== null) {
				return field;
			}
		}
		return null;
	}

	override isContainer() {
		return true;
	}

	override validateFields() {
		super.validateFields();
		this.children?.forEach((childField) => childField.validateFields());
	}

	override isValid() {
		try {
			this.validateFields();
			return true;
		} catch (error) {
			return false;
		}
	}

	override marshall(data: any, parent: any): any {
		// Get all children
		const children = data._children;

		// We don't want to store the children data against this field as this data
		// will be stored against the children anyway.

		delete data._children;

		// Assign fields to current object
		super.marshall(data, parent);

		if (children) {
			children.forEach((data: any) => {
				const field = FieldDefFactory.newInstance(data._type);
				this.children.push(field.marshall(data, this));
			});
		}
		return this;
	}

	override unmarshall(skipValidation?: boolean) {
		const clone = super.unmarshall(skipValidation);
		if (clone.children) {
			const cloneChildren: any[] = [];

			clone.children.forEach((fieldInstance: any) => {
				cloneChildren.push(fieldInstance.unmarshall(skipValidation));
			});

			clone._children = cloneChildren;
		}
		return clone;
	}
}
