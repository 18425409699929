import { useContext, createContext } from "react";
import { SetBulkChangeOwnerModalRecords } from "../types";

interface ChangeOwnerContextProps {
	setBulkChangeOwnerRecords: SetBulkChangeOwnerModalRecords;
}

const initialValues: ChangeOwnerContextProps = {
	setBulkChangeOwnerRecords: () => undefined,
};

export const BulkChangeOwnerContext = createContext<ChangeOwnerContextProps>(initialValues);

export function useBulkChangeOwnerContext(): ChangeOwnerContextProps {
	const context = useContext(BulkChangeOwnerContext);

	if (context === undefined) {
		throw new Error("useChangeOwnerContext must be used within a ChangeOwnerContext.Provider");
	}

	return context;
}
