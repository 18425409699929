import { FIELD_TYPES } from "@salesdesk/salesdesk-model";
import { Field } from "@salesdesk/salesdesk-schemas";

export function isRangeField(field?: Field): boolean {
	return field?._type === FIELD_TYPES.TIME_RANGE.name;
}

export function isDateOnlyField(field?: Field): boolean {
	return isRangeField(field) ? Boolean(field?._dateOnly) : field?._type !== FIELD_TYPES.DATE_TIME.name;
}
