import { mAssetDef } from "../asset_def";
import { ASSET_IDS } from "../asset_constants";

export class mPdfAssetDef extends mAssetDef {
	static CLASS_NAME = "mPdfAssetDef";
	static NAME = "PDF Asset";
	static PLURAL_NAME = "Pdf Assets";
	static ICON = "ph-file-pdf";

	static ID = ASSET_IDS.PDF;

	static FORMAT_DESCRIPTION = "PDF file required";

	constructor(ownerId) {
		super(mPdfAssetDef.ID, ownerId);
	}
}
