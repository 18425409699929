import { createContext, useContext } from "react";
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { BackgroundSettings } from "./useBackgroundSettings";
import { Settings, SettingsAction } from "../settings";

export interface IVideoCallMediaContext {
	localTracks: (LocalAudioTrack | LocalVideoTrack)[];
	onError: ErrorCallback;

	getLocalVideoTrack: () => Promise<LocalVideoTrack | undefined>;
	removeLocalVideoTrack: () => void;
	isAcquiringLocalVideoTrack: boolean;

	getLocalAudioTrack: () => Promise<LocalAudioTrack | undefined>;
	removeLocalAudioTrack: () => void;
	isAcquiringLocalAudioTrack: boolean;

	isBackgroundSelectionOpen: boolean;
	setIsBackgroundSelectionOpen: (value: boolean) => void;
	backgroundSettings: BackgroundSettings;
	setBackgroundSettings: (settings: BackgroundSettings) => void;

	localAudioOutputDeviceId: string | null;
	setLocalAudioOutputDeviceId(sinkId: string): void;

	settings: Settings;
	dispatchSetting: React.Dispatch<SettingsAction>;

	isKrispEnabled: boolean;
	setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	isKrispInstalled: boolean;
	setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VideoCallMediaContext = createContext<IVideoCallMediaContext | null>(null);

export function useVideoCallMediaContext() {
	const context = useContext(VideoCallMediaContext);
	if (context == null) {
		throw new Error("useVideoCallMediaContext must be used within a VideoCallMediaProvider");
	}
	return context;
}
