import { Role, RoleCreateRequest, RolePatchRequest } from "@salesdesk/salesdesk-schemas";

import { buildApiInvalidatedTagList, buildApiTagsList, SDApi } from "../../../../api";

export const rolesApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getRoles: builder.query<Role[], void>({
				query: () => ({ url: `roles`, method: "GET" }),
				providesTags: (result) => buildApiTagsList(result, (r) => r.id, "Roles"),
			}),
			createRole: builder.mutation<Role, { roleCreateRequest: RoleCreateRequest }>({
				query: ({ roleCreateRequest }) => ({
					url: `roles`,
					method: "POST",
					body: roleCreateRequest,
				}),
				invalidatesTags: (result) => buildApiInvalidatedTagList<Role>(result, (r) => r.id, "Roles"),
			}),
			updateRole: builder.mutation<Role, { id: number; rolePatchRequest: RolePatchRequest }>({
				query: ({ id, rolePatchRequest }) => ({
					url: `roles/${id}`,
					method: "PATCH",
					body: rolePatchRequest,
				}),
				invalidatesTags: (result) => buildApiInvalidatedTagList<Role>(result, (r) => r.id, "Roles"),
			}),
			deleteRole: builder.mutation<undefined, { id: number }>({
				query: ({ id }) => ({
					url: `roles/${id}`,
					method: "DELETE",
				}),
				invalidatesTags: (result, error, arg) =>
					buildApiInvalidatedTagList<number>(arg?.id, (roleId) => roleId, "Roles"),
			}),
		};
	},
});

export const {
	useGetRolesQuery,
	useLazyGetRolesQuery,
	useCreateRoleMutation,
	useUpdateRoleMutation,
	useDeleteRoleMutation,
} = rolesApi;
