import { ICONS } from "@salesdesk/salesdesk-ui";

import { useNotificationsCounterContext } from "../hooks/useNotificationsCounterContext";
import { NotificationsController } from "./NotificationsController";
import { useCallback, useState } from "react";
import { SDNotification } from "@salesdesk/salesdesk-schemas";
import { useMarkNotificationsReadMutation } from "../api/notificationApi";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../components/Popover";
import { Button, ButtonVariant, Tooltip } from "@salesdesk/daisy-ui";

interface NotificationsButtonProps {
	buttonVariant?: ButtonVariant;
}

export function NotificationsButton({ buttonVariant = "secondary" }: NotificationsButtonProps) {
	const [isOpen, setIsOpen] = useState(false);
	const { count, setPause } = useNotificationsCounterContext();

	const handleOpenChange = useCallback(
		(open: boolean) => {
			setPause(open);
			setIsOpen(open);
		},
		[setPause]
	);

	const [markNotificationsRead] = useMarkNotificationsReadMutation();
	const handleNotificationClicked = useCallback(
		(notification: SDNotification) => {
			// Don't await because popover will close
			if (!notification.read) markNotificationsRead({ messageIds: [notification.messageId] });
			handleOpenChange(false);
		},
		[markNotificationsRead, handleOpenChange]
	);

	return (
		<Popover placement="bottom-end" open={isOpen} onOpenChange={handleOpenChange}>
			<Tooltip text="Notifications">
				<PopoverTrigger>
					<Button startIcon={ICONS.notification} variant={buttonVariant} alertCount={count} />
				</PopoverTrigger>
			</Tooltip>
			<PopoverContent initialFocus={-1}>
				<PopoverContainer className="max-h-[393px] min-h-[260px] w-[540px]">
					<NotificationsController onNotificationClicked={handleNotificationClicked} />
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
