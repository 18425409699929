import { Icon, Button } from "@salesdesk/daisy-ui";

export interface ActionCardProps {
	imageUrl: string;
	titleIcon: string;
	title: string;
	description: string;
	onClick?: () => void;
	buttonIcon: string;
	buttonText: string;
	secondaryButtonText?: string;
	secondaryButtonIcon?: string;
	secondaryButtonClick?: () => void;
}

export function ActionCard({
	imageUrl,
	titleIcon,
	title,
	description,
	onClick,
	buttonIcon,
	buttonText,
	secondaryButtonText,
	secondaryButtonIcon,
	secondaryButtonClick,
}: ActionCardProps) {
	return (
		<div className="grid gap-3">
			<img className="aspect-[34/27] h-auto w-full" src={imageUrl} alt={title} />
			<div className="text-c_action_02 text-label-sm flex items-center gap-2">
				<Icon icon={titleIcon} />
				<p>{title}</p>
			</div>
			<p className="text-body-sm w-full max-w-[340px] text-pretty">{description}</p>
			<div className="flex gap-3">
				<Button size="sm" startIcon={buttonIcon} onClick={onClick} disabled={Boolean(!onClick)}>
					{buttonText}
				</Button>
				{secondaryButtonText ? (
					<Button size="sm" startIcon={secondaryButtonIcon} variant="outlined" onClick={secondaryButtonClick}>
						{secondaryButtonText}
					</Button>
				) : null}
			</div>
		</div>
	);
}
