import { useCallback, useMemo, useState } from "react";

import { getFieldIdsFromFieldNames } from "@salesdesk/salesdesk-schemas";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { UserMeetingsDialog } from "./UserMeetingsDialog";
import { PopoverMenu, MenuItem } from "../../../../../menu";
import { useGetObjectById } from "../../../../../../hooks";
import { User } from "../../../../types";
import { useUserHasMeetings } from "../../../../hooks/useUserHasMeetings";
import { useWebPrincipal } from "../../../../../../auth";
import { useCreateRecordDialogContext } from "../../../../../records/components/RecordCreate/hooks/useCreateRecordDialogContext";

enum MeetingModalVariant {
	bookAMeeting,
	allMeetings,
}

interface BookMeetingWithUserProps {
	id?: string;
	userInfo: User;
}

export function UserMeetings({ id, userInfo }: BookMeetingWithUserProps) {
	const [currentMeetingModal, setCurrentMeetingModal] = useState<MeetingModalVariant | undefined>();

	const userHasMeetings = useUserHasMeetings(userInfo.id);

	const principal = useWebPrincipal();
	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const displayFieldIds = useMemo(() => {
		if (!meetingObject) return [];

		return getFieldIdsFromFieldNames(meetingObject, [
			mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME,
			mInteractionMeetingDef.TIME_FIELD_NAME,
		]);
	}, [meetingObject]);

	const currentUserId = principal.UserRecordId;

	const { openModal: openRecordCreateDialog } = useCreateRecordDialogContext();

	const initialValueMap: Record<string, any> = useMemo(() => {
		if (displayFieldIds.length === 0) return {};

		const [meetingParticipantsFieldId] = displayFieldIds;
		const meetingParticipantsValue = [userInfo.id];

		if (currentUserId != null && !meetingParticipantsValue.includes(currentUserId)) {
			meetingParticipantsValue.push(currentUserId);
		}

		return { [meetingParticipantsFieldId]: meetingParticipantsValue };
	}, [userInfo, currentUserId, displayFieldIds]);

	const bookAMeeting = useCallback(() => {
		if (!meetingObject) return;

		openRecordCreateDialog({
			sdObject: meetingObject,
			initialValueMap,
			onOpenChange: () => setCurrentMeetingModal(undefined),
		});
		setCurrentMeetingModal(MeetingModalVariant.bookAMeeting);
	}, [initialValueMap, meetingObject, openRecordCreateDialog]);

	const menuItems: MenuItem[] = useMemo(() => {
		if (!meetingObject) return [];

		return [
			{
				variant: "default",
				type: "button",
				icon: ICONS.plus,
				text: `Book a ${meetingObject._displayName}`,
				onClick: () => bookAMeeting(),
			},
			{
				variant: "default",
				type: "button",
				icon: ICONS.calendar,
				text: `All ${meetingObject._pluralName}`,
				onClick: () => setCurrentMeetingModal(MeetingModalVariant.allMeetings),
			},
		];
	}, [bookAMeeting, meetingObject]);

	const meetingButton = useMemo(
		() => (
			<div>
				<Button
					id={id}
					startIcon={ICONS.calendar}
					size="xs"
					variant="text"
					onClick={userHasMeetings ? undefined : () => bookAMeeting()}
				/>
			</div>
		),
		[id, userHasMeetings, bookAMeeting]
	);

	const isBookMeetingDialogOpen = currentMeetingModal === MeetingModalVariant.bookAMeeting && meetingObject;

	return (
		<>
			{userHasMeetings ? (
				<PopoverMenu tooltipText="Meeting details" menuContents={menuItems}>
					{meetingButton}
				</PopoverMenu>
			) : (
				<Tooltip text={isBookMeetingDialogOpen ? undefined : "Book meeting"}>{meetingButton}</Tooltip>
			)}
			{currentMeetingModal === MeetingModalVariant.allMeetings ? (
				<UserMeetingsDialog
					userInfo={userInfo}
					onClose={() => setCurrentMeetingModal(undefined)}
					displayFieldIds={displayFieldIds}
				/>
			) : null}
		</>
	);
}
