import { useFormContext, useWatch } from "react-hook-form";
import { UniqueCheckParams, useUniqueCheck } from "./useUniqueCheck";
import { useEffect } from "react";
import { UniqueFieldType } from "@salesdesk/salesdesk-model";

export function useUniqueCheckWithReactHookForm({
	sdObject,
	sdRecord,
	field,
	fieldName,
}: Omit<UniqueCheckParams, "fieldValue"> & { fieldName: string }) {
	const { control, setError, clearErrors, trigger } = useFormContext();

	const fieldValue = useWatch({ control, name: fieldName });

	const result = useUniqueCheck({ sdObject, sdRecord, field, fieldValue });
	const { uniqueError } = result;

	useEffect(() => {
		if (!field || field._unique === UniqueFieldType.None) return;
		if (uniqueError) {
			setError(fieldName, { message: uniqueError });
		} else {
			clearErrors(fieldName);
		}

		// Trigger revalidation, so the error can be cleared
		trigger(fieldName);
	}, [uniqueError, setError, fieldName, clearErrors, field, trigger]);

	return result;
}
