import { asConst } from "json-schema-to-ts";
import { searchRequestSchema } from "../../search";
import { recordQuerySchema } from "./recordQuerySchema";

export const recordsSearchRequestSchema = asConst({
	...searchRequestSchema,
	title: "Record Search Request Schema",
	properties: {
		...searchRequestSchema.properties,
		query: recordQuerySchema,
	},
});
