import { isObject, isEqual } from "lodash";
import { UseFormReturn } from "react-hook-form";

import { Field, SDObject } from "@salesdesk/salesdesk-schemas";
import { mObjectDef } from "@salesdesk/salesdesk-model";

import { getFieldsSettingsByFieldState } from "../../utils/fieldSettingsFields";
import { FieldSettingsField, FieldSettingsGroup, SettingFieldFieldIdMap } from "../../types";

import { SelectOption } from "../../../../../inputs";
import { isFileField } from "../../../../../fields";

export function createSettingFieldsByField(
	sdObjectId: SDObject["_id"],
	field: Field,
	isNew: boolean,
	sdObjectOptions: SelectOption[]
) {
	/* 
		isNewField - currently overwrite isNew value to make
		fields 'name' and 'plural name' not editable for brand new object

		Field 'Name' is a default field that must exist in every SDObject
	*/
	const isNewField = field._name !== mObjectDef.NAME_FIELD_NAME && isNew;
	const settingsFieldGroups = getFieldsSettingsByFieldState(sdObjectId, field, isNewField, sdObjectOptions);
	const settingsFields = settingsFieldGroups.flatMap(({ fields }) => fields);

	return {
		settingsFieldGroups,
		settingsFields,
	};
}

export function createSettingFieldsByFieldIdMap(
	sdObjectId: SDObject["_id"],
	sdObjectFields: Field[],
	isNewObject: boolean,
	sdObjectOptions: SelectOption[]
) {
	return sdObjectFields.reduce((settingsFieldMap, field) => {
		settingsFieldMap[field._id] = createSettingFieldsByField(sdObjectId, field, isNewObject, sdObjectOptions);

		return settingsFieldMap;
	}, {} as SettingFieldFieldIdMap);
}

export function getSettingsFieldValuesByField(field: Field, settingFields: FieldSettingsField[]) {
	const settingsFieldValues: Record<string, any> = {};

	settingFields.forEach((settingFieldDef) => {
		let fieldValue = field[settingFieldDef._name as keyof Field];

		fieldValue = fieldValue == null ? null : settingFieldDef.formatValue(fieldValue);

		settingsFieldValues[settingFieldDef._name!] = fieldValue;
	});

	return settingsFieldValues;
}

export function getFieldSettingsDefaultValues(sdObjectFields: Field[], settingFieldsMap: SettingFieldFieldIdMap) {
	return sdObjectFields.reduce(
		(fieldValuesFieldIdMap, sdOjectField) => {
			const fieldId = String(sdOjectField._id);
			const fieldValueMap = getSettingsFieldValuesByField(sdOjectField, settingFieldsMap[fieldId].settingsFields);

			fieldValuesFieldIdMap[fieldId] = fieldValueMap;

			return fieldValuesFieldIdMap;
		},
		{} as Record<string, any>
	);
}

export function getFieldSettingFieldByFieldName(
	objectFieldId: string,
	fieldName: string,
	settingFieldsByObjectFieldId: SettingFieldFieldIdMap
) {
	return settingFieldsByObjectFieldId[objectFieldId].settingsFields.find((field) => field.name === fieldName);
}

export function updateSettingFieldsByObjectFieldIdAndField(
	objectFieldId: string,
	field: FieldSettingsField,
	settingFieldsByObjectFieldId: SettingFieldFieldIdMap
) {
	settingFieldsByObjectFieldId[objectFieldId].settingsFields = settingFieldsByObjectFieldId[
		objectFieldId
	].settingsFields.map((fieldSettingsField) => {
		if (fieldSettingsField.name === field.name) {
			return field;
		}

		return fieldSettingsField;
	});

	settingFieldsByObjectFieldId[objectFieldId].settingsFieldGroups = settingFieldsByObjectFieldId[
		objectFieldId
	].settingsFieldGroups.reduce((groups, group) => {
		const fieldByName = group.fields.find((originalField) => originalField.name === field.name);

		if (fieldByName) {
			group.fields = group.fields.map((originalField) => (originalField.name === field.name ? field : originalField));
		}

		groups.push(group);

		return groups;
	}, [] as FieldSettingsGroup[]);

	return settingFieldsByObjectFieldId;
}

export function getObjectDifference(obj1: Record<string, any>, obj2: Record<string, any>) {
	function getChanges(obj1: Record<string, any>, obj2: Record<string, any>) {
		const obj1Keys = Object.keys(obj1);

		return obj1Keys.reduce((result: Record<string, any>, key) => {
			const value = obj1[key];

			if (!isEqual(value, obj2[key])) {
				result[key] = isObject(value) && isObject(obj2[key]) ? getChanges(value, obj2[key]) : value;
			}

			return result;
		}, {});
	}

	return getChanges(obj1, obj2);
}

/* 
	This function handles fieldSettings updates for SingleOption and MultiOption fields.

	Every time we create new optionValue in fieldSettings we update a default values and provide 
	just created optionValue to the _defaultValue fieldSettings field.
*/
export function onOptionValueFieldSettingsChange(
	formMethods: UseFormReturn<Record<string, any>, any>,
	formValues: Record<string, any>,
	objectFieldId: string,
	settingFieldsByObjectFieldId: SettingFieldFieldIdMap
) {
	const objectField = formValues[objectFieldId];
	const objectFieldOptionValues: SelectOption[] = formValues[objectFieldId]._optionValues;
	const fieldToUpdate = getFieldSettingFieldByFieldName(objectFieldId, "_defaultValue", settingFieldsByObjectFieldId);

	if (!objectFieldOptionValues || objectFieldOptionValues.some((option) => !option.name))
		return {
			fieldToUpdate: null,
			propertiesToUpdate: null,
		};

	const objectFieldOptionValuesIds = objectFieldOptionValues.map((option) => option.id);

	if (typeof objectField._defaultValue === "number") {
		formMethods.setValue(
			`${objectFieldId}._defaultValue`,
			objectFieldOptionValuesIds.includes(objectField._defaultValue) ? objectField._defaultValue : null
		);
	}

	if (Array.isArray(objectField._defaultValue)) {
		formMethods.setValue(
			`${objectFieldId}._defaultValue`,
			objectField._defaultValue.filter((value: number) => objectFieldOptionValuesIds.includes(value))
		);
	}

	return {
		fieldToUpdate,
		propertiesToUpdate: {
			_optionValues: [...objectFieldOptionValues],
		},
	};
}

/*
	This function handles fieldSetting _defaultValue fieldSettings updates for MaxLength field.

	Every time we update maxLength in fieldSettings we update a _defaultValue  maxLength property to make
	validation work correctly.

	also we do extra validation for negative maxLength values.

*/
export function onMaxLengthFieldSettingsChange(
	formMethods: UseFormReturn<Record<string, any>, any>,
	formValues: Record<string, any>,
	objectFieldId: string,
	settingFieldsByObjectFieldId: SettingFieldFieldIdMap
) {
	const maxLengthValue = formValues[objectFieldId]._maxLength;
	const emptyResult = {
		fieldToUpdate: null,
		propertiesToUpdate: null,
	};

	if (maxLengthValue < 0) {
		formMethods.setError(
			`${objectFieldId}._maxLength`,
			{ message: "Max. length must be greater than 0" },
			{ shouldFocus: true }
		);
		return emptyResult;
	}

	const fieldToUpdate = getFieldSettingFieldByFieldName(objectFieldId, "_defaultValue", settingFieldsByObjectFieldId);

	/* 
		We don't update maxLength for file fields. Currently validation for maximum file size is not supported.
		Condition for checking if it's file field should be removed after ticket will be done.
		https://salesdesk101.atlassian.net/browse/SAL-3016
	*/
	if (!fieldToUpdate || (fieldToUpdate && isFileField(fieldToUpdate as Field))) {
		return emptyResult;
	}

	return {
		fieldToUpdate,
		propertiesToUpdate: {
			_maxLength: maxLengthValue,
		},
	};
}

export function setDisabledToFieldSettingFieldByName(
	fieldName: string,
	disabled: boolean,
	objectFieldId: string,
	settingFieldsByObjectFieldId: SettingFieldFieldIdMap
) {
	const fieldToUpdate = getFieldSettingFieldByFieldName(objectFieldId, fieldName, settingFieldsByObjectFieldId);

	return {
		fieldToUpdate,
		propertiesToUpdate: {
			editable: !disabled,
		},
	};
}
