import { Editor, Node, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export interface SdFileImageAttributes {
	blobUrl?: string;
	sdFileId?: number;
	src?: string;
	fileName?: string;
	fileType?: string;
	displayWidth?: number;
	displayHeight?: number;
	textAlign?: "left" | "center" | "right";
}

interface ImageOptions {
	HTMLAttributes: Record<string, any>;
	addNodeView?: () => any;
	allowImageDrop?: boolean;
	isReadOnly?: boolean;
}

export const SD_FILE_IMAGE_EXTENSION_NAME = "image";
export const PAGE_WIDTH = 740;

export async function addImagesToEditorOptimistically(editor: Editor, files: FileList): Promise<boolean> {
	if (!files.length) {
		return false;
	}
	const imageNodes = [];
	for (let i = 0; i < files?.length; i++) {
		const file = files.item(i);
		if (file && file.type.includes(SD_FILE_IMAGE_EXTENSION_NAME)) {
			const blobUrl = URL.createObjectURL(file);
			const { width, height } = await getImageDisplaySize(file);
			imageNodes.push({
				type: SD_FILE_IMAGE_EXTENSION_NAME,
				attrs: {
					blobUrl,
					sdFileId: null,
					fileName: file.name,
					fileType: file.type,
					displayWidth: width,
					displayHeight: height,
				},
			});
		}
	}
	if (imageNodes.length) {
		editor.chain().focus().insertContent(imageNodes).run();
		return true;
	}
	return false;
}

function getImageDisplaySize(file: File): Promise<{ width: number; height: number }> {
	return new Promise((resolve) => {
		const image = new Image();
		image.src = URL.createObjectURL(file);
		image.onload = () => {
			const ratio = image.width / image.height;
			const newWidth = Math.min(PAGE_WIDTH, image.width);
			const newHeight = newWidth / ratio;
			resolve({ width: newWidth, height: newHeight });
		};
	});
}

export const SdFileImageExtension = Node.create<ImageOptions>({
	name: SD_FILE_IMAGE_EXTENSION_NAME,

	addOptions() {
		return {
			inline: false,
			allowBase64: false,
			HTMLAttributes: {},
			addNodeView: undefined,
			allowImageDrop: false,
		};
	},

	inline: false,
	group: "block",

	draggable: true,

	addAttributes() {
		return {
			src: {
				default: null,
			},
			blobUrl: {
				default: null,
			},
			sdFileId: {
				default: null,
			},
			displayWidth: {
				default: null,
			},
			displayHeight: {
				default: null,
			},
			textAlign: {
				default: "left",
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: 'img[src]:not([src^="data:"]):not([src^="blob:"])',
				attrs: {
					blobUrl: { default: null },
					sdFileId: { default: null },
					src: { default: null },
					fileName: { default: null },
					fileType: { default: null },
					displayWidth: { default: null },
					displayHeight: { default: null },
					textAlign: { default: "left" },
				},
			},
		];
	},

	renderHTML({ HTMLAttributes }) {
		return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
	},

	addCommands() {
		return {
			setImage:
				(options) =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: options,
					});
				},
		};
	},

	addProseMirrorPlugins() {
		if (!this.options.allowImageDrop) {
			return [];
		}
		return [
			new Plugin({
				key: new PluginKey("imageDrop"),
				props: {
					handleDOMEvents: {
						paste: (view, event) => {
							if (event?.clipboardData?.files?.length) {
								event.preventDefault();
								const fileList = event.clipboardData?.files;
								if (fileList) {
									addImagesToEditorOptimistically(this.editor, fileList);
								}
							}
						},
						drop: (view, event) => {
							event.preventDefault();
							const fileList = event.dataTransfer?.files;
							if (fileList) {
								addImagesToEditorOptimistically(this.editor, fileList);
							}
						},
					},
				},
			}),
		];
	},
});
