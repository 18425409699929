import { NotificationsCounterProvider } from "../notifications";

import { ConnectWebSocket } from "../WebSocket";
import { BrowserNotifications } from "../BrowserNotifications";
import { useUserWorkspaceRedirect, WorkspaceProvider } from "../workspaces";
import { NavTrack } from "./NavTrack";
import { VideoCallContainer } from "./VideoCallContainer";
import { OnboardingProvider } from "../onboarding";
import { MyCredentialsChimeMessagingProvider } from "../messaging";
import { RecordActionsProvider } from "../records";
import { AppThemeHandler } from "./AppThemeHandler";
import { useAppInitialiser } from "../../hooks";
import { useGetMyAppStateQuery } from "../users/api/userAppStateApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../../store/store";
import { LoadingPage } from "../../pages";
import clsx from "clsx";
import { AppStateContext } from "../users/hooks/useAppStateContext";
import { useGetTenantSettingsQuery } from "../tenant";
import { RecordCreateDialogProvider } from "../records/components/RecordCreate/components/RecordCreateDialog/RecordCreateDialogProvider";
import { useRestrictApiKeyRouteAccess } from "../../hooks/useRestrictApiKeyRouteAccess";

export function AppProvider() {
	const isUserAuthorised = useAppInitialiser(true);
	const isRedirectingComplete = useUserWorkspaceRedirect(isUserAuthorised);

	const { data: appState } = useGetMyAppStateQuery(!isUserAuthorised ? skipToken : undefined);
	const { data: tenantSettings } = useGetTenantSettingsQuery(!isUserAuthorised ? skipToken : undefined);

	const isResizing = useAppSelector((state) => state.appState.isResizing);

	const { isAllowedAccess } = useRestrictApiKeyRouteAccess();

	const isLoading =
		!isUserAuthorised ||
		!isRedirectingComplete ||
		appState === undefined ||
		tenantSettings === undefined ||
		!isAllowedAccess;

	return isLoading ? (
		<LoadingPage />
	) : (
		<div id="container" className={clsx("flex h-screen w-full", isResizing && "resizing-column")}>
			<AppStateContext.Provider value={appState}>
				<MyCredentialsChimeMessagingProvider>
					<WorkspaceProvider>
						<RecordCreateDialogProvider>
							<OnboardingProvider>
								<AppThemeHandler tenantSettings={tenantSettings}>
									<ConnectWebSocket>
										<BrowserNotifications />
										<VideoCallContainer>
											<NotificationsCounterProvider>
												<RecordActionsProvider>
													<NavTrack />
												</RecordActionsProvider>
											</NotificationsCounterProvider>
										</VideoCallContainer>
									</ConnectWebSocket>
								</AppThemeHandler>
							</OnboardingProvider>
						</RecordCreateDialogProvider>
					</WorkspaceProvider>
				</MyCredentialsChimeMessagingProvider>
			</AppStateContext.Provider>
		</div>
	);
}
