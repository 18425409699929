import { Navigate } from "react-router-dom";
import { ERRORS } from "@salesdesk/salesdesk-ui";
import { DisplayIf, DisplayIfProps } from "../index";
import { PropsWithChildren } from "react";

export function AllowIf({ children, ...displayIfProps }: PropsWithChildren<Omit<DisplayIfProps, "children">>) {
	return (
		<DisplayIf {...displayIfProps}>
			{(can) => (can ? children : <Navigate replace to={`/error/${ERRORS.ACCESS_DENIED.code.toLowerCase()}`} />)}
		</DisplayIf>
	);
}
