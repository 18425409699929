import { PropsWithChildren, MouseEvent, KeyboardEvent, forwardRef } from "react";

interface AccessibleButtonDivProps {
	id?: string;
	className?: string;
	onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
	disabled?: boolean;
}

export const AccessibleButtonDiv = forwardRef<HTMLDivElement, PropsWithChildren<AccessibleButtonDivProps>>(
	({ id, children, className, onClick, disabled }, ref) => {
		const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
			const code = event.code;

			if ((code === "Enter" || code === "Space") && onClick) {
				event.preventDefault();
				onClick();

				return;
			}
		};

		const accessibleButtonProps: React.HTMLAttributes<HTMLDivElement> = !disabled
			? {
					role: "button",
					tabIndex: 0,
					onClick,
					onKeyDown,
				}
			: {};

		return (
			<div id={id} ref={ref} className={className} {...accessibleButtonProps}>
				{children}
			</div>
		);
	}
);
