import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { startOfDay } from "date-fns";

import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { getFieldIdsFromFieldNames, rsr, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../../../../auth";
import { useGetObjectById } from "../../../../../../../../hooks/useGetObjectById";
import { useWorkspaceSharedRecordSearch } from "../../../../../../hooks/useWorkspaceSharedRecordSearch";
import { FILTERS, InfiniteRecordSearchParams } from "../../../../../../../records";
import { formatDateLabel, getMeetingStartDate, getMeetingStartTimestamp, toMonthKey } from "../utils";
import { MeetingRow, UpcomingMeetingFilter } from "../types";

export function useUpcomingMeetingRecords(filter: UpcomingMeetingFilter) {
	const [additionalSearchParams, setAdditionalSearchParams] = useState<InfiniteRecordSearchParams>();

	const { records: meetings, isLoadingRecords } = useWorkspaceSharedRecordSearch({
		sdObjectFilter: mInteractionMeetingDef.ID,
		additionalSearchParams: additionalSearchParams ?? skipToken,
	});
	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const { UserRecordId: userRecordId } = useWebPrincipal();

	const [meetingStartDateFieldId, meetingParticipantsFieldId] = useMemo(() => {
		if (!meetingObject) return [];
		return getFieldIdsFromFieldNames(meetingObject, [
			mInteractionMeetingDef.TIME_FIELD_NAME,
			mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME,
		]);
	}, [meetingObject]);

	const sortedMeetings: SDRecord[] = useMemo(() => {
		return [...meetings].sort((a, b) => {
			const aStartDate = getMeetingStartTimestamp(a, meetingStartDateFieldId) || 0;
			const bStartDate = getMeetingStartTimestamp(b, meetingStartDateFieldId) || 0;
			return aStartDate - bStartDate;
		});
	}, [meetings, meetingStartDateFieldId]);

	useEffect(() => {
		const query = rsr.query();
		const futureDateFilter = FILTERS.tr_is_after.generateSearchQuery(
			String(meetingStartDateFieldId),
			startOfDay(new Date()).getTime()
		);
		if (!futureDateFilter) return;
		query.and(futureDateFilter);
		if (filter === "MY_MEETINGS" && userRecordId !== null) {
			query.and(FILTERS.ms_contains_any.generateSearchQuery(String(meetingParticipantsFieldId), [userRecordId]));
		}
		setAdditionalSearchParams({ query: query.buildQuery() });
	}, [userRecordId, filter, meetingStartDateFieldId, meetingParticipantsFieldId]);

	const [sortedMeetingRowsByMonth, monthKeys, meetingDates] = useMemo(() => {
		const monthKeys: string[] = [];
		const meetingDates: Date[] = [];
		const rowsByMonth: Record<string, MeetingRow[]> = {};
		const dateLabels = new Set<string>();
		for (const meetingRecord of sortedMeetings) {
			const meetingStartDate = getMeetingStartDate(meetingRecord, meetingStartDateFieldId);
			if (!meetingStartDate) continue;
			const monthKey = toMonthKey(meetingStartDate);
			if (!rowsByMonth[monthKey]) {
				rowsByMonth[monthKey] = [];
				monthKeys.push(monthKey);
			}
			const dateLabel = formatDateLabel(meetingStartDate);
			const meetingRow: MeetingRow = { meetingRecord, meetingStartDate };
			if (!dateLabels.has(dateLabel)) {
				dateLabels.add(dateLabel);
				meetingRow.dateLabel = dateLabel;
				meetingDates.push(meetingStartDate);
			}
			rowsByMonth[monthKey].push(meetingRow);
		}
		return [rowsByMonth, monthKeys, meetingDates];
	}, [sortedMeetings, meetingStartDateFieldId]);

	return {
		sortedMeetingRowsByMonth,
		monthKeys,
		meetingObject,
		meetingStartDateFieldId,
		meetingParticipantsFieldId,
		meetingDates,
		isLoadingRecords,
	};
}
