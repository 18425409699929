import { useMemo } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mTaskDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useObjectAssociations } from "../../../../../recordAssociations";
import { MenuItem } from "../../../../../menu";
import { useCreateRecordDialogContext } from "../../../RecordCreate/hooks/useCreateRecordDialogContext";
import { useGetObjectById } from "../../../../../../hooks";

const EMPTY_ARRAY: MenuItem[] = [];

export function useRecordsWithTasksActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	const {
		objectAssociations: { prioritizedAssociations, unprioritizedAssociations },
		isLoading: isLoadingAssociations,
	} = useObjectAssociations(sdRecord?._objectDefId);

	const { openModal } = useCreateRecordDialogContext();

	const { sdObject: taskObject } = useGetObjectById(mTaskDef.ID);

	const objectAssociations = useMemo(
		() => [...prioritizedAssociations, ...unprioritizedAssociations],
		[prioritizedAssociations, unprioritizedAssociations]
	);

	return useMemo(() => {
		if (!sdRecord || !sdObject || isLoadingAssociations || !taskObject || sdObject._id === taskObject._id) {
			return EMPTY_ARRAY;
		}

		const taskObjectAssociation = objectAssociations.find(
			(association) =>
				association.connectedObject.id === taskObject._id || association.sourceObject.id === taskObject._id
		);
		if (!taskObjectAssociation) {
			return EMPTY_ARRAY;
		}

		return [
			{
				icon: ICONS.task,
				text: "Create task",
				type: "button",
				onClick: () => {
					const initialAssociationMap = {
						[taskObjectAssociation.id]: [sdRecord._id],
					};
					openModal({
						sdObject: taskObject,
						initialAssociationMap,
						hideAssociationSection: true,
						description: `Please fill out the fields below to create a ${taskObject._displayName} for this ${sdObject._displayName}.`,
					});
				},
			},
		];
	}, [openModal, sdObject, sdRecord, taskObject, objectAssociations, isLoadingAssociations]);
}
