import { useCallback, useEffect, useRef, useState } from "react";
import { LazyMotion, m, useAnimationControls } from "framer-motion";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Tooltip, Button } from "@salesdesk/daisy-ui";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { lazyLoadFramerMotionFeatures } from "../../../../../utils/framer-motion";
import { PATHS, useStableNavigate } from "../../../../../routes";
import { VIDEO_CALL_CONTAINER_ID } from "../../../utils";
import { useVideoCallLogicContext } from "../../VideoCallProvider";
import { useOnMeetingPage } from "../../MeetingPage";
import { CallControls } from "../../common";
import { getClosestSnappingTarget } from "../utils";
import { MinimisedCallView } from "./MinimisedCallView";

const DECELERATION_DURATION = 70;

export function VideoCallMinimisedWindow() {
	const onMeetingPage = useOnMeetingPage();

	const navigate = useStableNavigate();

	const appRootContainerRef = useRef<HTMLDivElement | null>(null);
	const minimisedCallWindowRef = useRef<HTMLDivElement>(null);

	const { currentMeetingRecord, meetingObject, disconnect } = useVideoCallLogicContext();
	const [isDragging, setIsDragging] = useState(false);

	const animationControls = useAnimationControls();

	useEffect(() => {
		const root = document.getElementById("root");

		if (!root) {
			return;
		}

		appRootContainerRef.current = root as HTMLDivElement;
	}, []);

	const snapWindowToClosestSnappingTarget = useCallback(
		(snapToTop?: boolean) => {
			const containerEl = appRootContainerRef.current;
			const windowEl = minimisedCallWindowRef.current;

			if (!containerEl || !windowEl) {
				return false;
			}

			const target = getClosestSnappingTarget(containerEl, windowEl, snapToTop);

			if (target) {
				animationControls.start({
					...target,
					transition: { type: "spring", stiffness: 1600, bounce: 10, damping: 100 },
				});
			}

			// Returns true if the window snapped to a corner
			return Boolean(target);
		},
		[animationControls]
	);

	const onHeightUpdate = useCallback(() => {
		snapWindowToClosestSnappingTarget(true);
	}, [snapWindowToClosestSnappingTarget]);

	if (!currentMeetingRecord || onMeetingPage) {
		return null;
	}

	return (
		<LazyMotion features={lazyLoadFramerMotionFeatures}>
			<m.div
				id={VIDEO_CALL_CONTAINER_ID}
				ref={minimisedCallWindowRef}
				data-floating-ui-portal
				style={{ bottom: 0, left: 0 }}
				animate={animationControls}
				drag
				onDrag={() => setIsDragging(true)}
				dragConstraints={appRootContainerRef}
				dragElastic={0.06}
				dragTransition={{
					power: 0.1,
					timeConstant: DECELERATION_DURATION,
					min: 0,
					max: 100,
					modifyTarget: (target) => {
						// Round the minimised window positions to the nearest pixel to prevent rendering issues
						// where the window is positioned at increments smaller than a single pixel.
						return Math.round(target);
					},
				}}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
				onDragEnd={(event, info) => {
					setIsDragging(false);

					const snappedToCorner = snapWindowToClosestSnappingTarget();

					// Snaps window to corner if the user has 'thrown' it with a lot of momentum
					// so the window wasn't near a corner when the drag ended.
					if (!snappedToCorner) {
						setTimeout(() => snapWindowToClosestSnappingTarget(), DECELERATION_DURATION);
					}
				}}
				className={clsx("absolute z-[1000]", isDragging ? "cursor-grabbing" : "cursor-grab")}
			>
				<div className="shadow-dialog bg-c_bg_tooltip rounded-card text-c_text_inverted m-5 flex h-full w-[325px] flex-col gap-4 overflow-hidden p-3">
					<div className="flex items-center justify-between">
						<div className="text-label-xs mr-3 truncate">
							{meetingObject ? getSDRecordName(meetingObject, currentMeetingRecord) : ""}
						</div>
						<Tooltip text="Maximise call">
							<Button
								size="xs"
								variant="text_inverted"
								startIcon={ICONS.floatBack}
								onClick={() => navigate(PATHS.MEETING(currentMeetingRecord))}
							/>
						</Tooltip>
					</div>
					<MinimisedCallView onHeightUpdate={onHeightUpdate} />
					<CallControls leaveMeeting={disconnect} variant="minimised" />
				</div>
			</m.div>
		</LazyMotion>
	);
}
