import { useMemo } from "react";

import { useAppSelector } from "../../../../store/store";
import { EMPTY_FILTERS } from "../../../records";
import { useDataboardDetailsContext } from "../../hooks/useDataboardDetailsContext";
import { CARD_BASED_VIEWS, TABLE_VIEWS } from "../../utils";
import { RECORD_COMBINED_NAME_ID } from "../../types";

export const EMPTY_ARRAY = [];

export function useBoardAggSelector() {
	return useAppSelector((state) => state.boardState.agg);
}

export function useBoardCalendarDateSelector() {
	const date = useAppSelector((state) => state.boardState.date);
	return useMemo(() => (date != null ? new Date(date) : new Date()), [date]);
}

export function useBoardDisplayedFieldsSelector() {
	return useAppSelector((state) => state.boardState.displayedFields);
}

export function useBoardFiltersSelector() {
	return useAppSelector((state) => state.boardState.filter ?? EMPTY_FILTERS);
}

export function useBoardGroupBySelector() {
	return useAppSelector((state) => state.boardState.groupBy);
}

export function useBoardMediaFieldSelector() {
	return useAppSelector((state) => state.boardState.mediaField);
}

export function useBoardQuerySelector() {
	return useAppSelector((state) => state.boardState.query);
}

export function useBoardRecordIdSelector() {
	return useAppSelector((state) => state.boardState.recordId);
}

export function useBoardSortSelector() {
	return useAppSelector((state) => state.boardState.sort ?? EMPTY_ARRAY);
}

export function useBoardStateSelector() {
	return useAppSelector((state) => state.boardState);
}

export function useBoardViewSelector() {
	return useAppSelector((state) => state.boardState.view);
}

/**
 * Retrieves the fields that the board should display based on the current board view and
 * displayed fields in the board state/url params.
 *
 * Different from `useBoardDisplayedFieldsSelector` in that it will replace individual name fields
 * with the combined name field if the board view is card-based or table-based
 */
export function useBoardFieldsToDisplaySelector() {
	const displayedFields = useBoardDisplayedFieldsSelector();
	const boardView = useBoardViewSelector();

	const { sdObject, nameFieldIds } = useDataboardDetailsContext();

	return useMemo(() => {
		if (!sdObject) return EMPTY_ARRAY;

		let fieldsToDisplay = displayedFields;

		if (
			fieldsToDisplay &&
			boardView &&
			(CARD_BASED_VIEWS.includes(boardView) || TABLE_VIEWS.includes(boardView)) &&
			nameFieldIds.length > 1
		) {
			// Filters out all name fields used in the combined name
			fieldsToDisplay = fieldsToDisplay.filter((fieldId) => !nameFieldIds.includes(fieldId));
			fieldsToDisplay.unshift(RECORD_COMBINED_NAME_ID);
		}

		return fieldsToDisplay || EMPTY_ARRAY;
	}, [displayedFields, boardView, nameFieldIds, sdObject]);
}
