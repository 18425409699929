import { asConst } from "json-schema-to-ts";
import { rangeClauseValueSchema } from "./rangeClauseValueSchema";

export const rangeClausePropertyValueSchema = asConst({
	title: "Range Clause Property Value",
	type: "object",
	additionalProperties: rangeClauseValueSchema,
	minProperties: 1,
	maxProperties: 1,
});
