import { asConst } from "json-schema-to-ts";
import { ThemeName } from "@salesdesk/salesdesk-model";

const themeFieldSchema = asConst({
	oneOf: [
		{
			type: "string",
			enum: Object.values(ThemeName),
		},
		{
			type: "null",
		},
	],
});

export const tenantSettingsSchema = asConst({
	type: "object",
	title: "Tenant Settings",
	description: "Settings for a tenant",
	required: ["accountName", "accountLogo", "workspaceListWelcomeText", "crmTheme", "defaultWorkspaceTheme"],
	properties: {
		accountName: {
			type: "string",
			title: "Account Name",
			description: "The name of the account",
		},
		chips: {
			type: "string",
		},
		accountLogo: {
			title: "Account Logo",
			description: "Account logo file ID",
			type: "number",
			nullable: true,
		},
		workspaceListWelcomeText: {
			type: "string",
			title: "Workspace List Welcome Text",
			description: "Welcome text for the workspace list page",
			nullable: true,
		},
		crmTheme: {
			...themeFieldSchema,
			title: "CRM Theme",
			description: "CRM theme ID",
		},
		defaultWorkspaceTheme: {
			...themeFieldSchema,
			title: "Default Workspace Theme",
			description: "Default theme for new workspaces",
		},
	},
});
