import { useMemo } from "react";
import { isAssetObject, SDObject } from "@salesdesk/salesdesk-schemas";

import { DirectedSDObjectAssociation } from "./useObjectAssociations";
import { generateAssocitionFilterTargetId } from "../../records";
import { reverseObjectAssociationDirection } from "../utils";
import { PATHS } from "../../../routes";
import { useWorkspaceContext, WORKSPACE_OBJECT_BOARDS } from "../../workspaces";
import { useGetObjectById } from "../../../hooks";
import { useWebPrincipal } from "../../../auth";

export function useAssociationDataboardUrl(
	associationObject?: SDObject,
	objectAssociation?: DirectedSDObjectAssociation,
	sourceRecordId?: number,
	openInWorkspaceBoard?: boolean
) {
	const principal = useWebPrincipal();
	const { workspaceRecord } = useWorkspaceContext();

	const isAsset = associationObject && isAssetObject(associationObject);

	const { sdObject: assetObject } = useGetObjectById(isAsset ? associationObject?._baseType : undefined);

	const linkText = useMemo(() => {
		return `View in ${isAsset && assetObject ? assetObject._pluralName : associationObject?._pluralName}`;
	}, [assetObject, associationObject?._pluralName, isAsset]);

	const databoardUrl = useMemo(() => {
		if (!associationObject || !objectAssociation) {
			return "";
		}

		const filterData =
			sourceRecordId !== undefined
				? {
						type: "AND",
						filters: [
							{
								filterId: "a_contains_all",
								// Have to reverse the association definition since the databoard view will be from
								// the perspective of the connected object.
								filterTarget: generateAssocitionFilterTargetId(reverseObjectAssociationDirection(objectAssociation)),
								value: [sourceRecordId],
							},
						],
					}
				: undefined;

		if (openInWorkspaceBoard && workspaceRecord && WORKSPACE_OBJECT_BOARDS.includes(associationObject._id)) {
			return PATHS.WORKSPACE_RECORD_BOARD(workspaceRecord._id, associationObject, {
				filter: JSON.stringify(filterData),
			});
		}

		return principal.IsSalesDeskUser
			? PATHS.OBJECT_RECORD_BOARD(associationObject, { filter: JSON.stringify(filterData) })
			: "";
	}, [
		associationObject,
		objectAssociation,
		sourceRecordId,
		openInWorkspaceBoard,
		workspaceRecord,
		principal.IsSalesDeskUser,
	]);

	return {
		associationDataboardUrl: databoardUrl,
		associationDataboardLinkText: linkText,
	};
}
