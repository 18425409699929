import { useGetContextWorkspaceId } from "../../workspaces";
import { useCreateRecordMutation } from "../api/recordsApi";
import { useCallback } from "react";
import { SDRecordCreateRequest } from "@salesdesk/salesdesk-schemas";

export function useCreateRecord(overrideWorkspaceId?: number) {
	const workspaceId = useGetContextWorkspaceId() ?? overrideWorkspaceId;

	const [createRecord, { isLoading }] = useCreateRecordMutation();

	return {
		createRecord: useCallback(
			(recordCreateRequest: SDRecordCreateRequest) => {
				return createRecord({ recordCreateRequest, workspaceId }).unwrap();
			},
			[createRecord, workspaceId]
		),
		isLoading,
	};
}
