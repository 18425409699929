import { useEffect, useState } from "react";
import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";

export function useTrack(publication?: LocalTrackPublication | RemoteTrackPublication) {
	const [track, setTrack] = useState(publication?.track);

	useEffect(() => {
		// Reset the track when the 'publication' variable changes.
		setTrack(publication?.track);

		if (!publication) {
			return;
		}

		const removeTrack = () => setTrack(null);

		publication.on("subscribed", setTrack);
		publication.on("unsubscribed", removeTrack);

		return () => {
			publication.off("subscribed", setTrack);
			publication.off("unsubscribed", removeTrack);
		};
	}, [publication]);

	return track;
}
