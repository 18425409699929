// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { DefaultMessagingSession, Message, MessagingSessionObserver } from "amazon-chime-sdk-js";

export class ChimeMessageSubscriber {
	private session: DefaultMessagingSession;
	private messageUpdateCallbacks: ((message: Message) => void)[] = [];
	constructor(session: DefaultMessagingSession) {
		this.session = session;
	}

	messageObserver: MessagingSessionObserver = {
		messagingSessionDidStart: () => {
			console.debug("Chime Messaging Connection started");
		},
		messagingSessionDidStartConnecting: (reconnecting) => {
			console.debug(`Chime Messaging Connection ${reconnecting ? "re-" : ""}connecting`);
		},
		messagingSessionDidStop: (event) => {
			console.debug("Chime Messaging Connection received DidStop event");
		},
		messagingSessionDidReceiveMessage: (message) => {
			console.debug("Chime Messaging Connection received message");
			this.distributeMessageToSubscribers(message);
		},
	};

	distributeMessageToSubscribers(message: Message) {
		console.debug(`Sending Chime message to ${this.messageUpdateCallbacks.length} subscribers`);
		for (let i = 0; i < this.messageUpdateCallbacks.length; i += 1) {
			const callback = this.messageUpdateCallbacks[i];
			callback(message);
		}
	}

	async connect() {
		try {
			this.session.addObserver(this.messageObserver);
			await this.session.start();
		} catch (err) {
			console.error("Failed to start Chime Messaging Session", err);
		}
	}

	close() {
		try {
			this.session.stop();
		} catch (err) {
			console.error("Failed to stop Chime Messaging Session", err);
		}
	}

	subscribeToMessages(callback: (message: Message) => void) {
		console.debug("Chime message subscriber added");
		this.messageUpdateCallbacks.push(callback);
	}

	unsubscribeFromMessages(callback: (message: Message) => void) {
		console.debug("Chime message subscriber removed");
		const index = this.messageUpdateCallbacks.indexOf(callback);
		if (index !== -1) {
			this.messageUpdateCallbacks.splice(index, 1);
		}
	}
}
