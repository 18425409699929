import { Users } from "./Users";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import { UsersStateProvider } from "./UsersStateProvider";

interface UsersPageProps {
	isCustomersPage: boolean;
}

export function UsersPage({ isCustomersPage = false }: UsersPageProps) {
	return (
		<QueryParamProvider
			key={String(isCustomersPage)}
			adapter={ReactRouter6Adapter}
			options={{ removeDefaultsFromUrl: true }}
		>
			<UsersStateProvider>
				<Users isCustomersPage={isCustomersPage} />
			</UsersStateProvider>
		</QueryParamProvider>
	);
}
