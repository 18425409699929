export const TemplateFieldType = {
	Required: "Required",
	Optional: "Optional",
	None: "None",
} as const;

export type TemplateFieldType = (typeof TemplateFieldType)[keyof typeof TemplateFieldType];

export const FIELD_TEMPLATE_INFO = [
	{
		id: TemplateFieldType.None,
		name: "None",
	},
	{
		id: TemplateFieldType.Optional,
		name: "Optional",
	},
	{
		id: TemplateFieldType.Required,
		name: "Required",
	},
];
