import { Spinner } from "@salesdesk/daisy-ui";

export function VideoAnalysisStatus() {
	return (
		<div className="mb-2 flex items-center gap-2">
			<div className="flex items-center">
				<Spinner />
			</div>
			<div className="text-label-sm">Analysing content</div>
		</div>
	);
}
