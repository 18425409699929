import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Icon, AlertBubble } from "@salesdesk/daisy-ui";

interface MeetingInfoSectionProps {
	title: string;
	icon?: string;
	alertCount?: number;
	variant?: "primary" | "secondary";
}

export function MeetingInfoSection({
	title,
	icon,
	alertCount,
	children,
	variant = "secondary",
}: PropsWithChildren<MeetingInfoSectionProps>) {
	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-center gap-2">
				<div className="flex items-center gap-1.5 py-2">
					<Icon className="text-c_icon_regular flex" size="sm" icon={icon} />
					<h3
						className={clsx("text-label-sm", variant === "primary" ? "text-c_text_primary" : "text-c_text_secondary")}
					>
						{title}
					</h3>
				</div>
				{alertCount !== undefined && <AlertBubble alertCount={alertCount} variant="primary" />}
			</div>
			{children}
		</div>
	);
}
