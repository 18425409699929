import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";

import { useVideoCallMediaContext } from "../../../../../VideoCallProvider";
import { LocalVideo } from "./LocalVideo";

interface PreviewLocalVideoProps {
	initialising?: boolean;
	error: Error | null;
}

export function PreviewLocalVideo({ initialising, error }: PreviewLocalVideoProps) {
	const { localTracks, isAcquiringLocalVideoTrack } = useVideoCallMediaContext();

	const videoTrack = localTracks.find(
		(track) => !track.name.includes("screen") && track.kind === "video"
	) as LocalVideoTrack;

	const audioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;

	return (
		<div className="bg-c_text_secondary relative aspect-[4/3] w-full overflow-hidden rounded-md">
			{videoTrack != null && error == null ? (
				<LocalVideo videoTrack={videoTrack} audioTrack={audioTrack} />
			) : (
				<div className="absolute left-0 top-0 z-10 flex h-full w-full">
					<div className="bg-c_text_secondary flex h-full w-full items-center justify-center">
						<div className="text-body text-c_text_inverted flex h-full w-full items-center justify-center p-4 text-center">
							{error != null
								? `Media Error: ${error?.message}`
								: isAcquiringLocalVideoTrack || initialising
								? "Camera starting..."
								: "Camera off"}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
