import { DataService } from "@salesdesk/salesdesk-services";
import { createUniqueId, deepClone, isEmpty } from "@salesdesk/salesdesk-utils";

import { FieldDefFactory, mVideoFieldDef } from "../fields/index.js";
import { ASSET_IDS } from "./asset/asset_constants.js";
import { mDataFieldDef } from "../fields/container/index.js";
import { BOARD_VIEW } from "./boardViewType.js";

// TODO:  Should this be mRecordDef because the field will contain a reference to a Record and not an Object
export class mObjectDef {
	static NAME_FIELD_NAME = "name";
	static DESCRIPTION_FIELD_NAME = "description";
	static CLASS_NAME = "mObjectDef";

	// TODO: This should be moved to an enum in the schema and referenced from there
	static CREATION_TYPE = Object.freeze({
		USER: 0,
		SYSTEM: 1, // System types cannot be edited by a user
	});

	static DIRECTION = Object.freeze({ PARENTS: 0, CHILDREN: 1 });
	static HIERARCHY_MODE = Object.freeze({ PARENT_MODE: 0, ALL: 1 });

	_id: number;
	_baseType: number;
	_subtypes: number[];
	_searchable: boolean;
	_supportsUserCreation: boolean;
	_supportsUserView: boolean;
	_owner?: any | null;
	_ownerId: number;
	_version: number;
	_deleted: boolean;
	_className: string;
	_type: number;
	_color: string;
	_transient: boolean;
	_editable: boolean;
	_form: string;
	_supportsTableCardView: boolean;
	_supportsExplorerView: boolean;
	_list: string;

	_name?: string | null;
	_pluralName?: string | null;
	_displayName?: string | null;
	_description?: any | null;
	_icon?: string | null;
	_defaultView: string;
	_dateCreated: number;
	_lastModified: number;
	_lastModifiedUser: number;
	_commentsSupported: boolean;
	_historySupported: boolean;
	_activitySupported: boolean;
	_transcriptSupported: boolean;
	_dataDef: mDataFieldDef;
	_parents: mObjectDef[];
	_parentIds: number[];
	_supportsOrphan: boolean;
	_parentContext: mObjectDef | null;
	_children: mObjectDef[];
	_childrenIds: number[];
	_childrenTypeInfo: any[];
	_childrenMultiplicity: any;
	isTemplatable: boolean;

	constructor(id: number, ownerId: number) {
		if (!id) {
			this._id = -1;
		} else {
			this._id = id;
		}

		this._baseType = id;
		this._subtypes = [];
		this._searchable = false;
		this._supportsUserCreation = false;
		this._supportsUserView = false;

		this._owner = null;
		this._ownerId = ownerId;

		this._version = 1;
		this._deleted = false;

		this._className = mObjectDef.CLASS_NAME;

		// TODO: This should probably change to user because all System types are seeded
		this._type = mObjectDef.CREATION_TYPE.SYSTEM;

		this._color = "#9D99B9";

		// A transient definition (and associated objects) is never stored to the underlying
		// database. It is instantiated at runtime and is used to gather together
		// fields from various other persistence objects such that they can be presented in forms
		// and used to drive various application workflows.

		this._transient = false;

		// Whether a definition can be edited
		this._editable = false;

		// TODO: Remove in https://salesdesk101.atlassian.net/browse/SAL-2267
		this._form = "basic_form";

		this._supportsTableCardView = false;
		this._supportsExplorerView = true;

		// TODO: https://salesdesk101.atlassian.net/browse/SAL-2267
		this._list = "object_list_item";

		// Name should be immutable
		this._name = null;

		// Plural name should be mandatory
		this._pluralName = null;

		// Display name should be mandatory
		this._displayName = null;

		// Not currently used
		this._description = {};

		// Currently using Font Awesome fonts. https://fontawesome.com/
		this._icon = null;

		// Default view on Object Board
		this._defaultView = BOARD_VIEW.TABLE;

		this._dateCreated = new Date().getTime();
		this._lastModified = new Date().getTime();
		this._lastModifiedUser = this._ownerId;

		this._commentsSupported = false;
		this._historySupported = false;
		this._activitySupported = false;
		this._transcriptSupported = true;

		// Associated data fields
		this._dataDef = new mDataFieldDef(createUniqueId());
		this._dataDef.parent = this;

		// Parent (lazy load)
		this._parents = [];
		this._parentIds = [];
		this._supportsOrphan = false;

		this._parentContext = null;

		// Children (lazy load)
		this._children = [];
		this._childrenIds = [];

		// We need the name info of the children also, so we don't have
		// to load all the children if we simply want their ids and names
		this._childrenTypeInfo = [];

		// Stores multiplicity rules per child
		// e.g. how many instance of an object instance
		// can exist inside an outer object ?
		this._childrenMultiplicity = {};

		this.isTemplatable = false;
	}

	get id() {
		return this._id;
	}

	set id(id) {
		this._id = id;
	}

	isAny() {
		return this.id === -1;
	}

	get baseType() {
		return this._baseType;
	}

	set baseType(baseType) {
		this._baseType = baseType;
	}

	isBaseType() {
		return this._id == this._baseType;
	}

	isConcreteType() {
		return !this.hasSubTypes();
	}

	get subTypes() {
		return this._subtypes;
	}

	set subTypes(subTypes) {
		this._subtypes = subTypes;
	}

	hasSubTypes() {
		return this._subtypes.length > 0;
	}

	get groupByBaseType() {
		return true;
	}

	get searchable() {
		return this._searchable;
	}

	set searchable(searchable) {
		this._searchable = searchable;
	}

	get supportsUserCreation() {
		return this._supportsUserCreation;
	}

	set supportsUserCreation(supportsUserCreation) {
		this._supportsUserCreation = supportsUserCreation;
	}

	get supportsUserView() {
		return this._supportsUserView;
	}

	set supportsUserView(supportsUserView) {
		this._supportsUserView = supportsUserView;
	}

	get owner() {
		if (this.ownerLoaded()) {
			return this._owner;
		}
		throw Error(`Owner isn't loaded`);
	}

	set owner(owner: any) {
		this._owner = null;
		this._ownerId = -1;

		if (owner) {
			this._owner = owner;
			this._ownerId = owner.id;
		}
	}

	loadOwner(callback: (error: Error | null, owner: any) => void) {
		if (!this.hasOwner()) {
			callback(Error(`This object does not have an owner`), null);
		}

		if (this.ownerLoaded()) {
			callback(null, this.owner);
			return;
		}

		(DataService as any).getInstance().loadObject(this._ownerId, (err: Error | null, owner: any) => {
			this.owner = owner;

			callback(err, owner);
		});
	}

	hasOwner() {
		return this._ownerId !== -1;
	}

	ownerLoaded() {
		return this._ownerId !== -1 && !isEmpty(this._owner);
	}

	get version() {
		return this._version;
	}

	set version(value) {
		this._version = value;
	}

	get name() {
		return this._name;
	}

	set name(name) {
		this._name = name;
	}

	get className() {
		return this._className;
	}

	set className(className) {
		this._className = className;
	}

	get form() {
		return this._form;
	}

	set form(form) {
		this._form = form;
	}

	get list() {
		return this._list;
	}

	set list(list) {
		this._list = list;
	}

	get pluralName() {
		return this._pluralName;
	}

	set pluralName(pluralName) {
		this._pluralName = pluralName;
	}

	// Name must be unique. Display name does not need to be
	get displayName() {
		return !isEmpty(this._displayName) ? this._displayName : this._name;
	}

	set displayName(displayName) {
		this._displayName = displayName;
	}

	get description() {
		return this._description;
	}

	set description(description) {
		this._description = description;
	}

	get pathName() {
		return this.pluralName?.toLowerCase();
	}

	get icon() {
		return this._icon;
	}

	set icon(icon) {
		this._icon = icon;
	}

	hasIconPhoto() {
		return false;
	}

	get dateCreated() {
		return this._dateCreated;
	}

	set dateCreated(dateCreated) {
		this._dateCreated = dateCreated;
	}

	get lastModified() {
		return this._lastModified;
	}

	set lastModified(lastModified) {
		this._lastModified = lastModified;
	}

	get lastModifiedUser() {
		return this._lastModifiedUser;
	}

	set lastModifiedUser(lastModifiedUser) {
		this._lastModifiedUser = lastModifiedUser;
	}

	get commentsSupported() {
		return this._commentsSupported;
	}

	set commentsSupported(commentsSupported) {
		this._commentsSupported = commentsSupported;
	}

	get attachmentsSupported() {
		return this.supportsChildType(ASSET_IDS.ASSET);
	}

	get shareSupported() {
		return false;
	}

	isAsset() {
		return false;
	}

	get historySupported() {
		return this._historySupported;
	}

	set historySupported(historySupported) {
		this._historySupported = historySupported;
	}

	get activitySupported() {
		return this._activitySupported;
	}

	set activitySupported(activitySupported) {
		this._activitySupported = activitySupported;
	}

	// JF - We check whether the data container
	// contains and video field types. Only these
	// field types support transcript.

	get transcriptSupported() {
		return this._transcriptSupported && this.data.containsFieldType(mVideoFieldDef);
	}

	set transcriptSupported(transcriptSupported) {
		this._transcriptSupported = transcriptSupported;
	}

	get chatSupported() {
		return false;
	}

	get extensionsSupported() {
		return (
			this.commentsSupported ||
			this.attachmentsSupported ||
			this.historySupported ||
			this.activitySupported ||
			this.transcriptSupported
		);
	}

	get numOfExtensionsSupported() {
		let numOfExtensionsSupported = 0;

		if (this.commentsSupported) {
			numOfExtensionsSupported++;
		}
		if (this.attachmentsSupported) {
			numOfExtensionsSupported++;
		}
		if (this.historySupported) {
			numOfExtensionsSupported++;
		}
		if (this.activitySupported) {
			numOfExtensionsSupported++;
		}
		if (this.transcriptSupported) {
			numOfExtensionsSupported++;
		}

		return numOfExtensionsSupported;
	}

	supportsGrouping() {
		const fields = this._dataDef.children;

		for (let i = 0; fields.length > i; i++) {
			const field = fields[i];

			if (field.supportsGrouping() && !field.supportsMultiple() && !field.hidde) {
				return true;
			}
		}
		return false;
	}

	get groupingFields() {
		const groupingFields = [];

		const fields = this._dataDef.children;

		for (let i = 0; fields.length > i; i++) {
			const field = fields[i];

			if (field.supportsGrouping()) {
				groupingFields.push(field);
			}
		}

		return groupingFields;
	}

	supportsSummation() {
		const fields = this._dataDef.children;

		for (let i = 0; fields.length > i; i++) {
			const field = fields[i];

			if (field.supportsSummation() && !field.supportsMultiple() && !field.hidden) {
				return true;
			}
		}
		return false;
	}

	get summationFields() {
		const summationFields = [];

		const fields = this._dataDef.children;

		for (let i = 0; fields.length > i; i++) {
			const field = fields[i];

			if (field.supportsSummation() && !field.supportsMultiple() && !field.hidden) {
				summationFields.push(field);
			}
		}

		return summationFields;
	}

	getTableCellFields() {
		return this._dataDef.getTableCellFields();
	}

	hasSearchableFields() {
		return this.getSearchableFields().length > 0;
	}

	getSearchableFields() {
		const fields = [];

		const children = this._dataDef.children;

		for (let i = 0; children.length > i; i++) {
			const field = children[i];

			if (field.searchable && field.supportsSearch() && field.editable) {
				fields.push(field);
			}
		}

		return fields;
	}

	getIconPhotoField() {
		const children = this._dataDef.children;

		for (let i = 0; children.length > i; i++) {
			const field = children[i];

			if (field.iconPhoto && field.supportsIconPhoto()) {
				return field;
			}
		}
	}

	get type() {
		return this._type;
	}

	set type(type) {
		this._type = type;
	}

	get color() {
		return this._color;
	}

	set color(color) {
		this._color = color;
	}

	get transient() {
		return this._transient;
	}

	get editable() {
		return this._editable;
	}

	isUserType() {
		return this._type === mObjectDef.CREATION_TYPE.USER;
	}

	isSystemType() {
		return this._type === mObjectDef.CREATION_TYPE.SYSTEM;
	}

	supportsTableCardView() {
		return this._supportsTableCardView;
	}

	supportsExplorerView() {
		return this.supportsGrouping() && this._supportsExplorerView;
	}

	supportsTableKanbanView() {
		return this.supportsGrouping() && this.supportsTableCardView();
	}

	get data() {
		return this._dataDef;
	}

	set data(dataDef) {
		this._dataDef = dataDef;
		this._dataDef.parent = this;
	}

	hasData() {
		return !isEmpty(this._dataDef);
	}

	set parentContext(parentContext) {
		this._parentContext = parentContext;
	}

	get parentContext() {
		return this._parentContext;
	}

	hasParentContext() {
		return !isEmpty(this._parentContext);
	}

	numOfAncestors() {
		let i = 0;

		for (let objectDef = this.parentContext; objectDef != null; i++, objectDef = objectDef.parentContext) {
			//
		}
		return i;
	}

	getRoot() {
		let objectDef = this as any;

		while (objectDef.parentContext !== null) {
			objectDef = objectDef.parentContext;
		}
		return objectDef;
	}

	isRoot() {
		return !this.hasParents();
	}

	get parents() {
		if (this.parentsLoaded()) {
			return this._parents;
		}
		throw Error(`Parents aren't loaded`);
	}

	set parents(parents) {
		this._parents = [];
		this._parentIds = [];

		if (parents) {
			for (let i = 0; parents.length > i; i++) {
				this.addParent(parents[i]);
			}
		}
	}

	addParentOneWay(parent: mObjectDef) {
		if (!parent) {
			return;
		}
		if (!this._parentIds.includes(parent.id)) {
			this._parentIds.push(parent.id);
		}
		if (!this._parents.includes(parent)) {
			this._parents.push(parent);
		}
	}

	addParent(parent: mObjectDef) {
		this.addParentOneWay(parent);
		parent.addChild(this);
	}

	loadParents(callback: (error: Error | null, parents: mObjectDef[] | null) => void) {
		if (this.hasParents()) {
			if (this.parentsLoaded()) {
				callback(null, this._parents);
			} else {
				(DataService as any)
					.getInstance()
					.loadObjectDefs(this._parentIds, (err: Error | null, parents: mObjectDef[]) => {
						// Add this as a child to each parent
						for (let i = 0; parents.length > i; i++) {
							parents[i].addChild(this);
						}

						this._parents = parents;

						callback(err, this._parents);
					});
			}
		} else {
			callback(Error("This object definition does not have parents"), null);
		}
	}

	hasParents() {
		return !isEmpty(this._parentIds) && this._parentIds.length > 0;
	}

	hasParentType(objectDef: mObjectDef) {
		return this.hasParents() && this._parentIds.includes(objectDef.id);
	}

	parentsLoaded() {
		return this._parentIds.length === this._parents.length;
	}

	// For performance reasons we store the names (as well as ids) of
	// the children in this object. This means that an object of this type
	// does not also need to look up the children in order to know which
	// children types it supports. This saves the object from loading the
	// definition (this) and it's children.

	get childInfo() {
		const childInfo = [];

		for (let i = 0; this._childrenIds.length > i; i++) {
			childInfo.push({
				id: this._childrenIds[i],
				baseType: this._childrenTypeInfo[i].baseType,
				subTypes: this._childrenTypeInfo[i].subTypes,
				name: this._childrenTypeInfo[i].name,
				pluralName: this._childrenTypeInfo[i].pluralName,
				icon: this._childrenTypeInfo[i].icon,
			});
		}
		return childInfo;
	}

	get childSubTypeInfo() {
		return this.childInfo;
	}

	get children(): mObjectDef[] {
		if (this.childrenLoaded()) {
			return this._children;
		}
		throw Error(`Children aren't loaded`);
	}

	supportsChildType(type: number) {
		const childInfo = this.childInfo;

		for (let i = 0; childInfo.length > i; i++) {
			if (childInfo[i].baseType === type) {
				return true;
			}
		}

		return false;
	}

	addChild(child: mObjectDef, min?: number, max?: number) {
		if (!child) {
			return;
		}

		if (!this._childrenIds.includes(child.id)) {
			this._childrenIds.push(child.id);

			this._childrenTypeInfo.push({
				id: child.id,
				baseType: child.baseType,
				subTypes: child.subTypes,
				name: child.name,
				pluralName: child.pluralName,
				icon: child.icon,
			});

			if (!min || min < 0) {
				min = 0;
			}
			if (!max || min >= max) {
				max = 1000000;
			}
			this._childrenMultiplicity[child.baseType] = [min, max];
		}

		if (!this._children.includes(child)) {
			child.parentContext = this;
			this._children.push(child);
		}

		if (!child._parents.includes(this)) {
			child.addParent(this);
		}
	}

	removeChild(child: mObjectDef) {
		this._children.splice(this._children.indexOf(child), 1);
		this._childrenIds.splice(this._childrenIds.indexOf(child.id), 1);
	}

	getMinInstances(child: mObjectDef) {
		return this._childrenMultiplicity[child.baseType][0];
	}

	getMaxInstances(child: mObjectDef) {
		return this._childrenMultiplicity[child.baseType][1];
	}

	loadChildren(callback: (error: Error | null, children: mObjectDef[] | null) => void) {
		if (this.hasChildren()) {
			if (this.childrenLoaded()) {
				callback(null, this._children);
			} else {
				(DataService as any)
					.getInstance()
					.loadObjectDefs(this._childrenIds, (err: Error | null, children: mObjectDef[]) => {
						// Set the parent of each child to this
						for (let i = 0; children.length > i; i++) {
							this.addChild(children[i]);
						}
						callback(err, this._children);
					});
			}
		} else {
			callback(Error(`This object definition does not have any children`), null);
		}
	}

	hasChildren() {
		return !isEmpty(this._childrenIds) && this._childrenIds.length > 0;
	}

	childrenLoaded() {
		if (!isEmpty(this._children)) {
			return this._childrenIds.length === this._children.length;
		}
		return false;
	}

	fieldsLoaded() {
		return this.childrenLoaded();
	}

	supportsChildren() {
		return this.hasChildren();
	}

	// Loads all of the children, following grouping rules specified by groupByBaseType
	// groupByBaseType = true - indicates that the base types should be returned rather than
	// the subtypes. This field is set per base type def type.

	loadChildTypes(callback: (error: Error | null, children: mObjectDef[] | null) => void) {
		let childTypeIds: number[] = [];

		this.loadChildren((error: Error | null, children: mObjectDef[] | null) => {
			if (error) {
				callback(error, null);
			}

			for (let i = 0; (children || []).length > i; i++) {
				const child = (children || [])[i];

				if (child.isConcreteType() || child.groupByBaseType) {
					childTypeIds.push(child.id);
				} else {
					childTypeIds = childTypeIds.concat(child.subTypes);
				}
			}

			(DataService as any).getInstance().loadObjectDefs(childTypeIds, callback);
		});
	}

	getChildById(childId: number) {
		if (!this.hasChildren()) {
			throw Error(`This object does not have any children`);
		}

		if (this.childrenLoaded()) {
			for (let i = 0; this._children.length > i; i++) {
				if (this._children[i].id === childId) {
					return this._children[i];
				}
			}
			throw Error(`Child with id ${childId} doesn't exist`);
		}
		throw Error(`Children aren't loaded`);
	}

	remove() {
		if (!this.hasParents()) {
			throw Error(`Cannot remove from parent as it isn't loaded`);
		}

		this.parentContext!.removeChild(this);
	}

	getObjectDefById(id: number) {
		let objectDef = null;

		if (this.id === id) {
			return this;
		} else if (this.hasChildren() && this.childrenLoaded()) {
			objectDef = this.doGetObjectDefById(id, this.children);
		}

		if (!objectDef) {
			throw new Error(`ObjectDef with id ${id} not found!`);
		}
		return objectDef;
	}

	doGetObjectDefById(id: number, children: mObjectDef[]): mObjectDef | null {
		let objectDef = null;

		for (let i = 0; children.length > i; i++) {
			const thisObjectDef = children[i];

			if (thisObjectDef.id === id) {
				return thisObjectDef;
			} else if (thisObjectDef.hasChildren() && thisObjectDef.childrenLoaded()) {
				objectDef = this.doGetObjectDefById(id, thisObjectDef.children);
			}
			if (objectDef !== null) {
				return objectDef;
			}
		}
		return null;
	}

	getChildByName(childName: string) {
		if (!this.hasChildren()) {
			throw Error(`This object does not have any children`);
		}

		if (this.childrenLoaded()) {
			for (let i = 0; this._children.length > i; i++) {
				if (this._children[i].name === childName) {
					return this._children[i];
				}
			}
			throw Error(`Child of name ${childName} doesn't exist`);
		}
		throw Error(`Children aren't loaded`);
	}

	// Uses a breadth-first search (BFS) algorithm to obtain all
	// loaded parents and children in the graph model.

	getAllLoadedRelatives() {
		const queue = [];
		const relatives = [];

		// Initially enqueue the starting node
		queue.push(this);

		// Set it to visited
		relatives.push(this);

		// While there are still remaining nodes in the queue...
		while (queue.length > 0) {
			const currentNode = queue.shift();

			let directRels: mObjectDef[] = [];

			if (currentNode?.childrenLoaded()) {
				directRels = directRels.concat(currentNode.children);
			}
			if (currentNode?.parentsLoaded()) {
				directRels = directRels.concat(currentNode.parents);
			}

			for (const key in directRels) {
				const target = directRels[key];

				//If it has not been visited yet
				if (!relatives.includes(target)) {
					//Mark it as visited
					relatives.push(target);

					//Add it to queue
					queue.push(target);
				}
			}
		}
		return relatives;
	}

	move(toIndex: number) {
		const children = this.parentContext?.children || [];

		if (toIndex > children.length - 1) {
			throw Error(`Index out of range - must be equal to or less than size of the children array (${children.length})`);
		}

		if (toIndex < 0) {
			throw Error(`Index out of range - must be zero of greater`);
		}

		children.splice(toIndex, 0, children.splice(this.index!, 1)[0]);
	}

	// Validates the values in this class
	validateFields() {
		if (isEmpty(this.id)) {
			throw new Error(`Id is not set in field "${this.name}"`);
		}
		if (isEmpty(this.type)) {
			throw new Error(`Field type is not set in field "${this.name}"`);
		}
		if (isEmpty(this._ownerId)) {
			throw new Error(`Owner is not set in field "${this.name}"`);
		}
		if (isEmpty(this.name)) {
			throw new Error(`Name is not set in field "${this.name}"`);
		}
		if (isEmpty(this.pluralName)) {
			throw new Error(`Plural name is not set in field "${this.name}"`);
		}
		if (isEmpty(this.displayName)) {
			throw new Error(`Display name is not set in field "${this.name}"`);
		}
		if (isEmpty(this.icon)) {
			throw new Error(`Icon is not set in field "${this.name}"`);
		}
		this.data.validateFields();
	}

	isValid() {
		try {
			this.validateFields();

			if (this.hasChildren() && this.childrenLoaded()) {
				for (let i = 0; this.children.length > i; i++) {
					this.children[i].validateFields();
				}
			}

			return true;
		} catch (error) {
			return false;
		}
	}

	// TODO: Remove in https://salesdesk101.atlassian.net/browse/SAL-2267
	getActions() {
		return [];
	}

	/** start interface methods **/

	// Allows polymorphism in various UI libraries.
	// JS doesn't support interfaces ;-(

	get index() {
		if (!this.hasParents()) {
			return;
		}

		const children = this.parentContext?.children || [];

		for (let i = 0; children.length > i; i++) {
			if (this.id === children[i].id) {
				return i;
			}
		}

		return -1;
	}

	getAllInstances() {
		const instances = [];

		instances.push(this);

		if (this.hasChildren() && this.childrenLoaded()) {
			this.doGetAllInstances(this.children, instances);
		}

		return instances;
	}

	doGetAllInstances(children: mObjectDef[], instances: mObjectDef[]) {
		for (let i = 0; children.length > i; i++) {
			const child = children[i];

			instances.push(child);

			if (child.hasChildren() && child.childrenLoaded()) {
				this.doGetAllInstances(child.children, instances);
			}
		}
	}

	getParentObjectsByType() {
		return this.parentContext?.children || [];
	}

	getObjectInstanceById(id: number) {
		return this.getObjectDefById(id);
	}

	getParentObjectByIndex(index: number) {
		const children = this.parentContext?.children || [];

		if (index > children.length - 1 || index < 0) {
			throw Error(
				`index out of range - must be greater than zero and less than size of the children array (${children.length})`
			);
		}

		return children[index];
	}

	/** end interface methods **/

	marshall(objectDefData: any) {
		// Clone this data as some of the binding process is destructive
		const clonedObjectDefData = JSON.parse(JSON.stringify(objectDefData));

		// Take the raw data object (from storage) and bind it to the fields in this JS class instance
		Object.assign(this, clonedObjectDefData);

		// Set the data definition data objects

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.data = FieldDefFactory.newInstance(this.data._type).marshall(this.data);

		return this;
	}

	unmarshall() {
		// Remove parent, children and data fields before storing. We don't want to store loads of repeat
		// data when it can be resolved when we call marshall(), or when it can be loaded lazily using
		// the data, parent,, children ids, etc.
		//
		// We create a clone and store that as we don't want to remove field data form an object
		// that we might continue to use after storing it.

		const clone = this.clone() as any;

		delete clone._id;
		delete clone._baseType;
		delete clone._className;
		delete clone._type;
		delete clone._version;
		delete clone._deleted;

		delete clone._editable;
		delete clone._subtypes;
		delete clone._parentIds;
		delete clone._childrenIds;
		delete clone._childrenMultiplicity;
		delete clone._childrenTypeInfo;

		delete clone._supportsUserCreation;
		delete clone._supportsUserView;
		delete clone._supportsExplorerView;
		delete clone._supportsTableCardView;

		delete clone._ownerId;
		delete clone._dateCreated;
		delete clone._lastModified;
		delete clone._lastModifiedUser;

		// UI Fields
		delete clone._parents;
		delete clone._children;
		delete clone._transient;
		delete clone._transcriptSupported;
		delete clone._supportsOrphan;
		delete clone._list;
		delete clone._owner;
		delete clone._parentContext;
		delete clone._form;
		delete clone._card;
		delete clone._validation, (clone._dataDef = clone.data.unmarshall());

		// Remove fields that can only be set on creation
		// TODO: Confirm this is valid based on 43 of creat_object_definition.js
		if (this._version != null) {
			delete clone._name;
			delete clone._pluralName;
		}

		return clone;
	}

	clone() {
		const clone = deepClone(this);
		clone.parentContext = null;
		return clone;
	}

	equals(object: mObjectDef) {
		return this.id === object.id;
	}

	toJson() {
		return JSON.stringify(
			this,
			function replacer(key, value) {
				// We don't try to show all of _dataDef at it creates a recursion issue.

				if (key === "_dataDef") {
					if (value === null || value === undefined) {
						return null;
					} else {
						return {
							_id: value._id,
							_name: value._name,
							_type: value._type,
						};
					}
				}
				if (key == "_parents") {
					if (value === null || value === undefined) {
						return null;
					} else {
						return {
							_ids: value._parentIds,
						};
					}
				}
				return value;
			},
			2
		);
	}
}
