import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";
import clsx from "clsx";

import { InputValidationState } from "../../types";

interface InputContainerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	disabled?: boolean;
	active?: boolean;
	validationState?: InputValidationState;
	transparentBorder?: boolean;
}

export const InputContainer = React.forwardRef<HTMLDivElement, PropsWithChildren<InputContainerProps>>(
	(
		{
			disabled = false,
			active = false,
			transparentBorder = false,
			validationState = InputValidationState.initial,
			className,
			children,
			...divProps
		},
		ref
	) => {
		const isError = validationState === InputValidationState.error && !disabled;
		const isSuccess = validationState === InputValidationState.success && !disabled;
		const isDefault = !isError && !isSuccess && !disabled;

		return (
			<div
				{...divProps}
				ref={ref}
				className={clsx(
					"max-h-full overflow-y-auto",
					disabled
						? "bg-c_bg_disabled_02 text-c_text_disabled"
						: "bg-c_bg_01 group-focus-within:bg-c_bg_04 focus-visible-within:bg-c_bg_04",
					isError && "border-c_danger_02 group-focus-within:border-c_danger_01 focus-visible-within:border-c_danger_01",
					isSuccess &&
						"border-c_success_02 group-focus-within:border-c_success_01 focus-visible-within:border-c_success_01",
					isDefault && "group-focus-within:border-c_brand_primary focus-visible-within:border-c_brand_primary",
					transparentBorder ? "border-transparent" : "border-c_border_regular",
					"w-full rounded-sm border transition-colors focus:outline-none",
					active && !disabled && "border-c_brand_primary bg-c_bg_04",
					className
				)}
			>
				{children}
			</div>
		);
	}
);
