import { getSDObjectFieldByName, getSDRecordName, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useGetObjectById } from "../../../../hooks";
import { useGetLoginAuthorizationDetails, useUpdateRecord } from "../../../records";
import { useToast } from "../../../Toasts";
import { useCallback, useMemo } from "react";
import { ToastMessageKey } from "../../../Toasts/types";
import { AlertDialog } from "../../../Dialog";
import { Button } from "@salesdesk/daisy-ui";
import { mUserDef } from "@salesdesk/salesdesk-model";

interface DeactivateLoginModalProps {
	userRecord: SDRecord;
	onClose: () => void;
}

export function DeactivateLoginModal({ userRecord, onClose }: DeactivateLoginModalProps) {
	const { sdObject: userObject } = useGetObjectById(userRecord?._objectDefId);
	const { updateRecord, isLoading } = useUpdateRecord();
	const { isSalesDeskUser } = useGetLoginAuthorizationDetails(userObject, userRecord);
	const toast = useToast();

	const disableLogin = useCallback(() => {
		if (!userObject) return;
		const loginAuthorizedField = getSDObjectFieldByName(userObject, mUserDef.LOGIN_AUTHORIZED_FIELD_NAME);
		if (!loginAuthorizedField) return;

		const toastMessageKey: ToastMessageKey = isSalesDeskUser ? "user_login_disabled" : "customer_access_revoked";
		updateRecord({
			record: userRecord,
			updatedFields: [
				{
					_fieldId: loginAuthorizedField._id,
					_value: false,
				},
			],
		})
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: toastMessageKey });
				onClose();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: toastMessageKey });
			});
	}, [isSalesDeskUser, onClose, toast, updateRecord, userObject, userRecord]);

	const recordName = useMemo(() => {
		if (!userObject) return "";
		return getSDRecordName(userObject, userRecord);
	}, [userObject, userRecord]);

	return (
		<AlertDialog
			open={true}
			onOpenChange={() => onClose()}
			title="Deactivate login"
			message={
				<div className="flex flex-col gap-4">
					<div>
						Are you sure you want to deactivate the login status for <span className="text-label">{recordName}</span>?
					</div>
				</div>
			}
		>
			<div className="flex justify-end gap-3">
				<Button variant="secondary" onClick={() => onClose()}>
					Cancel
				</Button>
				<Button onClick={() => disableLogin()} isLoading={isLoading} variant="danger">
					Deactivate login
				</Button>
			</div>
		</AlertDialog>
	);
}
