import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";

import { Spinner } from "@salesdesk/daisy-ui";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { User } from "../../../../types";
import { Dialog, DialogContent, DialogHeader } from "../../../../../Dialog";
import { FieldIdRecordCard, useInfiniteRecordSearch } from "../../../../../records";
import { PATHS } from "../../../../../../routes";
import { useInfiniteScrollContainer } from "../../../../../../hooks/ui";
import { useGetObjectById } from "../../../../../../hooks";
import { getUserMeetingsSearchParams } from "../../../../utils";
import { SkeletonRecordAssociationCard } from "../../../../../recordAssociations";

import { useGetContextWorkspaceId } from "../../../../../workspaces";

interface UserMeetingsDialogProps {
	onClose: () => void;
	userInfo: User;
	displayFieldIds: number[];
}

export function UserMeetingsDialog({ onClose, userInfo, displayFieldIds }: UserMeetingsDialogProps) {
	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const workspaceId = useGetContextWorkspaceId();

	const [userMeetings, setUserMeetings] = useState<SDRecord[]>([]);

	const { updateSearchParams, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage } = useInfiniteRecordSearch({
		onSDRecordsChange: setUserMeetings,
		sdRecords: userMeetings,
	});

	useEffect(() => {
		if (meetingObject && userInfo) {
			updateSearchParams(getUserMeetingsSearchParams(meetingObject, userInfo.id, workspaceId));
		}
	}, [meetingObject, workspaceId, userInfo, updateSearchParams]);

	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			loadNextPage();
		},
	});
	return (
		<Dialog
			open
			onOpenChange={(open) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogContent>
				<div className="flex w-full min-w-[440px] max-w-[440px] flex-col gap-4">
					<DialogHeader title={`All meetings with ${userInfo.fullName}`} onClose={onClose} />
					<div
						className={clsx(
							"flex max-h-[420px] min-h-[420px] flex-col gap-4 pb-4",
							isLoadingNewSearchParams ? "overflow-hidden" : "overflow-auto"
						)}
					>
						{isLoadingNewSearchParams
							? [...Array(3)].map((_, index) => (
									<div className="h-auto w-full" key={index}>
										<SkeletonRecordAssociationCard rows={2} />
									</div>
								))
							: userMeetings.map((userMeeting) => (
									<div key={userMeeting._id} className="h-auto w-full">
										<FieldIdRecordCard
											fieldIdsToDisplay={displayFieldIds}
											sdRecord={userMeeting}
											getRecordLink={(recordId) =>
												workspaceId && meetingObject
													? PATHS.WORKSPACE_RECORD_BOARD(workspaceId, meetingObject, { recordId })
													: null
											}
										/>
									</div>
								))}
						{isLoadingNextPage ? (
							<div className="my-6 flex items-center justify-center">
								<Spinner size="md" />
							</div>
						) : null}
						<div key={userMeetings?.length ?? 0} ref={containerBottomRef} />
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
