import { Fragment, ReactNode, forwardRef, KeyboardEvent, useRef } from "react";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import {
	Icon,
	Spinner,
	InputValidationState,
	InputComponentProps,
	applyAndCancelKeyCapturing,
	InputContainer,
} from "@salesdesk/daisy-ui";

import { TrackedInput } from "../../../../TrackedInput/TrackedInput";

export interface BaseTypeaheadButtonProps extends InputComponentProps {
	validationState?: InputValidationState;
	value: string;
	onChange: (newQuery: string) => void;
	isLoading?: boolean;
	onlyDisplayInputOnFocus?: boolean;
	beforeInputSlot?: ReactNode;
	afterInputSlot?: ReactNode;
	onCancel?: (event: KeyboardEvent) => void;
	onApply?: (event: KeyboardEvent) => void;
	onClear?: () => void;
}

export const BaseTypeaheadButton = forwardRef<HTMLButtonElement, BaseTypeaheadButtonProps>(
	(
		{
			id,
			disabled,
			validationState,
			value: query,
			onChange: onQueryChange,
			onBlur,
			ariaAttributes,
			isLoading,
			onlyDisplayInputOnFocus,
			beforeInputSlot,
			afterInputSlot,
			onApply,
			onCancel,
			onClear,
		},
		ref
	) => {
		const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
			applyAndCancelKeyCapturing<HTMLDivElement>(event, onApply, onCancel);
		};

		const inputRef = useRef<HTMLInputElement>(null);

		const handleFocus = () => {
			inputRef.current?.focus();
		};

		return (
			<Combobox.Button
				id={id}
				ref={ref}
				as="div"
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				className={clsx(
					disabled ? "cursor-not-allowed" : "cursor-pointer",
					"group relative w-full max-w-full overflow-hidden"
				)}
			>
				<InputContainer disabled={disabled} validationState={validationState}>
					<div className="flex min-h-[40px] w-full items-center justify-between overflow-hidden py-1.5 pl-4 pr-2">
						<div className="flex min-w-0 flex-wrap gap-1 pr-3">
							{beforeInputSlot}
							<div
								className={clsx(
									"ResizableInputContainer z-10",
									onlyDisplayInputOnFocus && !query && "sr-only group-focus-within:not-sr-only"
								)}
								data-value={query}
							>
								<Combobox.Input
									as={Fragment}
									ref={inputRef}
									displayValue={() => ""}
									onChange={(e) => {
										onQueryChange(e.currentTarget.value);
									}}
								>
									<TrackedInput
										className="bg-transparent"
										autoComplete="off"
										size={1}
										onBlur={onBlur}
										value={query}
										programaticOnChange={onQueryChange}
										onClick={(e) => e.stopPropagation()}
										{...ariaAttributes}
									/>
								</Combobox.Input>
							</div>
							{afterInputSlot}
						</div>
						{isLoading ? (
							<div className="mr-2">
								<Spinner />
							</div>
						) : (
							<>
								<Icon
									className={clsx(
										disabled ? "text-c_icon_disabled" : "text-c_icon_regular",
										"mr-2 flex items-center",
										onClear && !disabled && "group-focus-within:hidden"
									)}
									icon={ICONS.caretUpDown}
								/>
								{onClear && !disabled ? (
									<button
										className="focus-visible:ring-c_action_focus mr-2 hidden rounded-full focus-visible:ring group-focus-within:block"
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											onClear();
										}}
									>
										<Icon className="text-c_icon_regular flex items-center" icon={ICONS.crossCircle} variant="fill" />
									</button>
								) : null}{" "}
							</>
						)}
					</div>
				</InputContainer>
			</Combobox.Button>
		);
	}
);
