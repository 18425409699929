import { asConst } from "json-schema-to-ts";
import { sdObjectCreateRequestSchema } from "./sdObjectCreateRequestSchema";
import {
	objectBaseTypeSchema,
	objectChildIdsSchema,
	objectChildrenMultiplicitySchema,
	objectClassNameSchema,
	objectCreatedDateSchema,
	objectCreationTypeSchema,
	objectDeletedSchema,
	objectEditableSchema,
	objectLastModifiedBySchema,
	objectLastModifiedDateSchema,
	objectOwnerSchema,
	objectParentIdsSchema,
	objectSubTypesSchema,
	objectSummaryInfoArraySchema,
	objectSupportsExplorerViewSchema,
	objectSupportsTableCardViewSchema,
	objectSupportsUserCreationSchema,
	objectSupportsUserViewSchema,
	objectIdSchema,
	objectVersionSchema,
} from "./properties";
import { fieldGroupSchema } from "./field";
import { booleanSchema } from "../components";

export const sdObjectSchema = asConst({
	title: "SDObject",
	description: "An SDObject schema",
	type: "object",
	additionalProperties: false,
	required: [
		...sdObjectCreateRequestSchema.required,
		"_id",
		"_baseType",
		"_className",
		"_type",
		"_version",
		"_deleted",

		"_editable",
		"_subtypes",
		"_parentIds",
		"_childrenIds",
		"_childrenMultiplicity",
		"_childrenTypeInfo",

		"_supportsUserCreation",
		"_supportsUserView",
		"_supportsTableCardView",
		"_supportsExplorerView",

		"_dateCreated",
		"_ownerId",
		"_lastModified",
		"_lastModifiedUser",
	],
	properties: {
		...sdObjectCreateRequestSchema.properties,
		_id: objectIdSchema,
		_baseType: objectBaseTypeSchema,
		_className: objectClassNameSchema,
		_type: objectCreationTypeSchema,
		_version: objectVersionSchema,
		_deleted: objectDeletedSchema,

		_editable: objectEditableSchema,
		_subtypes: objectSubTypesSchema, // TODO: Rename to subTypes
		_parentIds: objectParentIdsSchema,
		_childrenIds: objectChildIdsSchema, // TODO: Rename childIds
		_childrenMultiplicity: objectChildrenMultiplicitySchema, //TODO: Rename childMultiplicity
		_childrenTypeInfo: objectSummaryInfoArraySchema, // TODO: To be deleted

		// TODO:  Consistent "supports" flags naming
		_supportsUserCreation: objectSupportsUserCreationSchema,
		_supportsUserView: objectSupportsUserViewSchema,
		_supportsTableCardView: objectSupportsTableCardViewSchema,
		_supportsExplorerView: objectSupportsExplorerViewSchema,

		// TODO:  Change this so its just a list of field and add any other properties directly on the object
		// If you need grouping then allow one level of grouping called fieldGroup
		_dataDef: fieldGroupSchema,

		isTemplatable: booleanSchema,

		// TODO:  Rename consistently and do we need a createdBy
		_dateCreated: objectCreatedDateSchema,
		_ownerId: objectOwnerSchema,
		_lastModified: objectLastModifiedDateSchema,
		_lastModifiedUser: objectLastModifiedBySchema,
	},
});
