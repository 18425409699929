import { prefixClaimsNS } from "./prefixClaimsNS";

/*
 * Claims that can allocated to User Role
 */
export const RoleClaimType = Object.freeze({
	// Role
	Role: prefixClaimsNS("role"),

	// Role
	RoleView: prefixClaimsNS("permitted.role.view"),
	RoleCreate: prefixClaimsNS("permitted.role.create"),
	RoleEdit: prefixClaimsNS("permitted.role.edit"),
	RoleDelete: prefixClaimsNS("permitted.role.delete"),

	// User
	UserView: prefixClaimsNS("permitted.user.view"),
	UserCreate: prefixClaimsNS("permitted.user.create"),
	UserEdit: prefixClaimsNS("permitted.user.edit"),
	UserDelete: prefixClaimsNS("permitted.user.delete"),
	UserLoginEdit: prefixClaimsNS("permitted.user.login.edit"),

	// Object
	ObjectView: prefixClaimsNS("permitted.object.view"),
	ObjectCreate: prefixClaimsNS("permitted.object.create"),
	ObjectEdit: prefixClaimsNS("permitted.object.edit"),
	ObjectDelete: prefixClaimsNS("permitted.object.delete"),

	// Object Associations
	ObjectAssociationView: prefixClaimsNS("permitted.object.association.view"),
	ObjectAssociationCreate: prefixClaimsNS("permitted.object.association.create"),
	ObjectAssociationEdit: prefixClaimsNS("permitted.object.association.edit"),
	ObjectAssociationDelete: prefixClaimsNS("permitted.object.association.delete"),

	// Record
	RecordView: prefixClaimsNS("permitted.record.view"),
	ObjectRecordViewAll: prefixClaimsNS("permitted.object.record.view.all"),
	ObjectRecordViewOwner: prefixClaimsNS("permitted.object.record.view.owner"),
	ObjectRecordCreate: prefixClaimsNS("permitted.object.record.create"),
	ObjectRecordEditAll: prefixClaimsNS("permitted.object.record.edit.all"),
	ObjectRecordEditOwner: prefixClaimsNS("permitted.object.record.edit.owner"),
	ObjectRecordChangeOwnerAll: prefixClaimsNS("permitted.object.record.change-owner.all"),
	ObjectRecordChangeOwnerOwner: prefixClaimsNS("permitted.object.record.change-owner.owner"),
	ObjectRecordDeleteAll: prefixClaimsNS("permitted.object.record.delete.all"),
	ObjectRecordDeleteOwner: prefixClaimsNS("permitted.object.record.delete.owner"),

	// Record Actions
	LeadRecordConvert: prefixClaimsNS("permitted.lead.record.convert"),
	ObjectRecordBulkEdit: prefixClaimsNS("permitted.object.record.bulk-edit"),
	ObjectRecordBulkDelete: prefixClaimsNS("permitted.object.record.bulk-delete"),

	// Record Associations
	// TODO: https://salesdesk101.atlassian.net/browse/SAL-2551
	// ObjectRecordAssociationView: prefixClaimsNS("permitted.object.record.association.view"),
	ObjectRecordAssociationCreate: prefixClaimsNS("permitted.object.record.association.create"),
	ObjectRecordAssociationDelete: prefixClaimsNS("permitted.object.record.association.delete"),

	// File
	FileDownloadInUI: prefixClaimsNS("permitted.file.download-in-ui"),
	SentimentAnalysisView: prefixClaimsNS("permitted.file.sentiment-analysis.view"),
	CoachingAdviceView: prefixClaimsNS("permitted.file.coaching-advice.view"),

	// Video Calling
	VideoCallRecord: prefixClaimsNS("permitted.video-call.record"),
	VideoCallShareScreen: prefixClaimsNS("permitted.video-call.share-screen"),
	VideoCallPresentFile: prefixClaimsNS("permitted.video-call.present-file"),
	VideoCallCreateTask: prefixClaimsNS("permitted.video-call.create-task"),
});

export type RoleClaimType = (typeof RoleClaimType)[keyof typeof RoleClaimType];
