import { PropsWithChildren } from "react";
import { LeadToOpportunityModalProvider } from "../../../../leads";
import { ToggleUserLoginProvider } from "../../../../users";
import { BulkChangeOwnerProvider } from "../../BulkChangeOwnerModal";
import { CreateWorkspaceModalProvider } from "../../../../workspaces";
import { RecordDeleteDialogProvider } from "../../DeleteRecord";

export function RecordActionsProvider({ children }: PropsWithChildren) {
	return (
		<RecordDeleteDialogProvider>
			<ToggleUserLoginProvider>
				<LeadToOpportunityModalProvider>
					<CreateWorkspaceModalProvider>
						<BulkChangeOwnerProvider>{children}</BulkChangeOwnerProvider>
					</CreateWorkspaceModalProvider>
				</LeadToOpportunityModalProvider>
			</ToggleUserLoginProvider>
		</RecordDeleteDialogProvider>
	);
}
