import clsx from "clsx";
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";

export function WorkspaceNavLoading() {
	return (
		<div className="mt-auto flex gap-4">
			{[...Array(4)].map((_, index) => (
				<Skeleton variant="tertiary" key={index} className={clsx("h-9", index % 2 === 0 ? "w-24" : "w-20")} />
			))}
		</div>
	);
}
