import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SelectOption, SelectOptionId } from "../../../types";
import { ensureValueIsArray } from "../../../../../utils";

export function useTypeaheadSelectDisplayValues(
	value: SelectOptionId[] | SelectOptionId | undefined,
	selectOptions: SelectOption[],
	getOptionsFromIds: ((ids: SelectOptionId[]) => SelectOption[] | Promise<SelectOption[]>) | undefined
) {
	const displayValueCache = useRef<Record<SelectOptionId, SelectOption>>({});

	// Initialise typeahead display values if they exist in the selectOptions
	// so the values are displayed on mount for synchronous typeaheads
	const [displayValues, setDisplayValues] = useState<SelectOption[]>(() => {
		const valueArray = ensureValueIsArray(value);

		return selectOptions.reduce((initialDisplayValues, option) => {
			displayValueCache.current[option.id] = option;

			if (valueArray.includes(option.id)) {
				initialDisplayValues.push(option);
			}
			return initialDisplayValues;
		}, [] as SelectOption[]);
	});

	const [isLoadingValue, setIsLoadingValue] = useState(false);

	const resetDisplayValueCache = useCallback(() => {
		displayValueCache.current = {};
	}, []);

	useEffect(() => {
		const updateDisplayValue = async () => {
			const valueArray: SelectOptionId[] = ensureValueIsArray(value);

			if (!valueArray.length) {
				setDisplayValues([]);
				return;
			}

			setIsLoadingValue(true);

			const displayValuesMap: Record<SelectOptionId, SelectOption> = {};
			const missingValues: SelectOptionId[] = [];

			// Checks cache for values first
			valueArray.forEach((value) => {
				const cachedDisplayValue = displayValueCache.current[value];

				if (!cachedDisplayValue && !missingValues.includes(value)) {
					missingValues.push(value);
				} else {
					displayValuesMap[value] = cachedDisplayValue;
				}
			});

			// If some values were not in cache, see if we can find them in the current select options
			if (missingValues.length) {
				for (const option of selectOptions) {
					const valueIdindex = missingValues.indexOf(option.id);

					if (valueIdindex === -1) {
						continue;
					}

					displayValuesMap[option.id] = option;

					// Removes the value id from the missing value index
					missingValues.splice(valueIdindex, 1);
				}
			}

			// Finally if it's an async typeahead, any values that we couldn't find we retrieve through the
			// async function
			if (missingValues.length && getOptionsFromIds) {
				const fetchedDisplayValues = await getOptionsFromIds(missingValues);

				fetchedDisplayValues.forEach((displayValue) => {
					displayValuesMap[displayValue.id] = displayValue;
				});
			}

			// Updates cache
			if (displayValuesMap) {
				displayValueCache.current = { ...displayValueCache.current, ...displayValuesMap };
			}

			const displayValues = valueArray.reduce((displayValues, valueId) => {
				if (displayValuesMap[valueId]) {
					displayValues.push(displayValuesMap[valueId]);
				}

				return displayValues;
			}, [] as SelectOption[]);

			setDisplayValues(displayValues);
			setIsLoadingValue(false);
		};

		updateDisplayValue();
	}, [value, selectOptions, getOptionsFromIds]);

	const loadingPlaceholders = useMemo(() => {
		if (!isLoadingValue) return null;

		const valueArray = ensureValueIsArray(value);
		return valueArray.map((valueId) => ({ id: valueId, name: "Loading..." }));
	}, [value, isLoadingValue]);

	return {
		displayValues: loadingPlaceholders ?? displayValues,
		isLoadingValue,
		resetDisplayValueCache,
	};
}
