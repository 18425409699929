import clsx from "clsx";
import { Transition } from "@headlessui/react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { Pill } from "../../../../../../../../components/Pill/Pill";
import { Button } from "@salesdesk/daisy-ui";
import { tw } from "../../../../../../../../utils/tailwind-helpers";
import { GroupedData } from "../../../types";
import { KANBAN_COLUMN_WIDTH, KANBAN_CONTAINER_PADDING_RIGHT, KANBAN_GAP_WIDTH } from "../utils";
import { ColumnAggregationResult } from "./ColumnAggregationResult";
import { Sticky } from "../../../../../../../../components/Sticky";

interface KanbanColumnHeadersProps {
	recordGroupIds: number[];
	kanbanData: GroupedData;
	fixedAggregationLabel?: boolean;
}

export function KanbanColumnHeaders({ recordGroupIds, kanbanData, fixedAggregationLabel }: KanbanColumnHeadersProps) {
	return (
		<Sticky
			priority={3}
			height={40}
			className="z-20 flex w-fit"
			style={{ gap: `${KANBAN_GAP_WIDTH}px`, paddingRight: `${KANBAN_CONTAINER_PADDING_RIGHT}px` }}
		>
			{recordGroupIds.map((groupId, index) => {
				const firstColumn = index === 0;
				const groupDetails = kanbanData[groupId]?.groupDetails;

				if (!groupDetails) {
					return null;
				}

				const { color, icon, name, isLoading, hitCount, aggregationResult } = kanbanData[groupId].groupDetails;

				return (
					<div key={groupId} style={{ width: `${KANBAN_COLUMN_WIDTH}px` }}>
						<div className={clsx("bg-c_bg_01 sticky top-0 w-full gap-2 p-3", firstColumn ? "z-30" : "z-20")}>
							<div className="flex w-full items-center gap-2">
								<Pill color={color} icon={icon}>
									{name}
								</Pill>
								<Transition
									show={!isLoading}
									enter={tw`transition-opacity duration-75`}
									enterFrom={tw`opacity-0`}
									enterTo={tw`opacity-100`}
								>
									<div className="text-c_text_secondary text-body-sm bg-c_bg_02 rounded-md px-2">{hitCount || 0}</div>
								</Transition>
								<div className="ml-auto">
									<Button disabled startIcon={ICONS.verticalDots} variant="text" size="sm" />
								</div>
							</div>
							{aggregationResult && !isLoading ? (
								<ColumnAggregationResult
									firstColumn={firstColumn}
									aggregationResult={aggregationResult}
									fixedLabel={fixedAggregationLabel}
								/>
							) : null}
						</div>
					</div>
				);
			})}
		</Sticky>
	);
}
