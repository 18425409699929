import { useState, useEffect, useCallback } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../auth";
import { useChimeMessagingContext } from "../../components";
import { useToast } from "../../../Toasts";
import { useCreateHuddle } from "./useCreateHuddle";

export function useOpenDMHuddle() {
	const { chime } = useChimeMessagingContext();

	const toast = useToast();
	const principal = useWebPrincipal();

	const [channelArn, setChannelArn] = useState<string>();

	const { createHuddle, isCreatingHuddle } = useCreateHuddle(channelArn);
	const [isLoadingDMArn, setIsLoadingDMArn] = useState(false);

	const openDMHuddle = useCallback(
		(recipientId: SDRecord["_id"]) => {
			if (!chime) {
				return;
			}

			setIsLoadingDMArn(true);

			chime
				.createChannel(principal.TenantId, [principal.UserRecordId, recipientId])
				.then((newChannelArn) => {
					setChannelArn(newChannelArn);
				})
				.catch((error) => {
					console.error("Failed to create DM Huddle Channel", error);
					toast.trigger("error", "Failed to open DM Huddle for user");
					setIsLoadingDMArn(false);
				});
		},
		[chime, principal.TenantId, principal.UserRecordId, toast]
	);

	// Want to debounce the createHuddle call to ensure that the channelArn is properly set
	// within the createHuddle hook before calling it
	useEffect(() => {
		if (!channelArn) return;

		const timeoutId = setTimeout(() => {
			createHuddle();
			setIsLoadingDMArn(false);
		}, 200);

		return () => clearTimeout(timeoutId);
	}, [channelArn, createHuddle]);

	return { openDMHuddle: chime ? openDMHuddle : undefined, isLoading: isLoadingDMArn || isCreatingHuddle };
}
