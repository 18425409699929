import { mAssetDef } from "../asset_def";
import { ASSET_IDS } from "../asset_constants";

export class mMediaAssetDef extends mAssetDef {
	static CLASS_NAME = "mMediaAssetDef";
	static NAME = "Media Asset";
	static PLURAL_NAME = "Media Assets";
	static ICON = "ph-video";

	static ID = ASSET_IDS.MEDIA;

	static FORMAT_DESCRIPTION = "Video required";

	constructor(ownerId) {
		super(mMediaAssetDef.ID, ownerId);
	}

	get shareSupported() {
		return true;
	}
}
