import { SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";
import {
	useAssetRecordActions,
	useDocRecordActions,
	useLeadRecordActions,
	useMeetingRecordActions,
	useTaskRecordActions,
	useUsersRecordActions,
	useWorkspaceOriginActions,
	useWorkspaceRecordActions,
	useIssueRecordActions,
} from "./recordSpecificActions";
import { useRecordsWithTasksActions } from "./recordSpecificActions/useRecordsWithTasksActions";

export function useRecordActions(sdRecord?: SDRecord, sdObject?: SDObject, isActionButton?: boolean) {
	const taskActions = useTaskRecordActions(sdRecord, sdObject);
	const issueActions = useIssueRecordActions(sdRecord, sdObject);
	const meetingActions = useMeetingRecordActions(sdRecord, sdObject);
	const leadsActions = useLeadRecordActions(sdRecord, sdObject);
	const assetActions = useAssetRecordActions(sdRecord, sdObject);
	const usersActions = useUsersRecordActions(sdRecord, sdObject, isActionButton);
	const docActions = useDocRecordActions(sdRecord, sdObject);
	const workspaceActions = useWorkspaceRecordActions(sdRecord, sdObject);
	const workspaceOriginActions = useWorkspaceOriginActions(sdRecord, sdObject);
	const recordsWithTasksActions = useRecordsWithTasksActions(sdRecord, sdObject);

	return [
		taskActions,
		issueActions,
		meetingActions,
		leadsActions,
		assetActions,
		docActions,
		workspaceActions,
		workspaceOriginActions,
		usersActions,
		recordsWithTasksActions,
	]
		.filter((recordActions) => recordActions.length)
		.flat();
}
