export const DIRECT_MESSAGE_CHANNEL_ID_PREFIX = "DM-";

export const getDirectMessageChannelId = (userRecordId1: number, userRecordId2: number) => {
	const [sortedId1, sortedId2] = [userRecordId1, userRecordId2].sort((a, b) => a - b);
	return `${DIRECT_MESSAGE_CHANNEL_ID_PREFIX}${sortedId1}-${sortedId2}`;
};

export const isDirectMessageChannelId = (channelId: string) => channelId.startsWith(DIRECT_MESSAGE_CHANNEL_ID_PREFIX);

export const getUserIdsFromDirectMessageChannelId = (channelId: string) => {
	const ids = channelId.substring(DIRECT_MESSAGE_CHANNEL_ID_PREFIX.length).split("-");
	return ids.map((id) => parseInt(id));
};
