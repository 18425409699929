import { useState, useEffect } from "react";

import { SDRecord, RecordQueryClauses, SDObject, isAssetObject } from "@salesdesk/salesdesk-schemas";
import { Spinner } from "@salesdesk/daisy-ui";

import { useGetObjectById } from "../../../../hooks/useGetObjectById";
import { useGetAssociationFieldIds, DirectedSDObjectAssociation } from "../../hooks";
import { getAssociationSearchParams } from "../../utils";
import { useInfiniteRecordSearch } from "../../../records";
import { useGetContextWorkspaceId } from "../../../workspaces";
import { RecordAssociationCard, SkeletonRecordAssociationCard } from "../RecordAssociationCard";
import { NoAssociationsView } from "../NoAssociationsView";
import { useOpenLinkingModal } from "../AssociationLinkingModal";
import { CarouselControls } from "./CarouselControls";

interface AssociationCarouselProps {
	titlePrefix: string;
	carouselObjectId: number;
	objectAssociations: DirectedSDObjectAssociation[];
	sourceRecord?: SDRecord;
	generateFilters?: (associationObject: SDObject) => RecordQueryClauses[];
}

const DEFAULT_LIMIT = 10;

// Note: The association carousel is a special case where it can combine
// multiple association definitions for an object to show new information (e.g.
// all of the tasks associated to this record that are pending.).
export function AssociationCarousel({
	titlePrefix,
	carouselObjectId,
	objectAssociations,
	sourceRecord,
	generateFilters,
}: AssociationCarouselProps) {
	const { sdObject: associationObject } = useGetObjectById(carouselObjectId);
	const workspaceId = useGetContextWorkspaceId();

	const openLinkingModal = useOpenLinkingModal(sourceRecord ?? undefined, associationObject, objectAssociations[0]);

	const [associationRecords, setAssociationRecords] = useState<SDRecord[]>([]);
	const { updateSearchParams, resultHits, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage, isLastPage } =
		useInfiniteRecordSearch({
			limit: DEFAULT_LIMIT,
			onSDRecordsChange: setAssociationRecords,
			sdRecords: associationRecords,
			sdObjectFilter: carouselObjectId,
		});

	const hitCount = resultHits?.hitCount || 0;

	const [currentIndex, setCurrentIndex] = useState(0);

	// Load the next page if user is within 3 elements from the end of the loaded carousel items
	const isLoading = isLoadingNextPage || isLoadingNewSearchParams;

	useEffect(() => {
		if (!isLoading && currentIndex >= associationRecords.length - 3 && !isLastPage) {
			loadNextPage();
		}
	}, [currentIndex, associationRecords.length, isLoading, isLastPage, loadNextPage]);

	useEffect(() => {
		if (currentIndex >= hitCount) {
			setCurrentIndex(Math.max(0, hitCount - 1));
		}
	}, [hitCount, currentIndex]);

	const sourceRecordId = sourceRecord?._id;
	useEffect(() => {
		if (!associationObject) {
			return;
		}

		const orQueries = generateFilters ? generateFilters(associationObject) : undefined;
		updateSearchParams(
			getAssociationSearchParams(
				{
					sourceRecordId,
					orQueries,
					workspaceId,
				},
				objectAssociations
			)
		);

		setCurrentIndex(0);
	}, [associationObject, updateSearchParams, generateFilters, objectAssociations, workspaceId, sourceRecordId]);

	const displayFieldIds = useGetAssociationFieldIds(associationObject);

	const noResults = !isLoading && hitCount === 0;
	const showAssociation = currentIndex < associationRecords.length && associationObject && sourceRecord;

	const title = `${titlePrefix} ${associationObject?._pluralName || ""}`;

	return (
		<div className="flex min-h-[144px] shrink-0 flex-col">
			<CarouselControls
				title={title}
				currentIndex={currentIndex}
				total={hitCount}
				onIndexChange={setCurrentIndex}
				initialLoad={isLoadingNewSearchParams}
			/>
			{noResults && associationObject ? (
				<NoAssociationsView
					resultsName={title}
					associationObject={associationObject}
					onLinkAssociationClick={openLinkingModal}
				/>
			) : showAssociation ? (
				<RecordAssociationCard
					sdObject={associationObject}
					sdRecord={associationRecords[currentIndex]}
					displayFieldIds={displayFieldIds}
					openingVariant="currentPageSidePanel"
				/>
			) : isLoadingNewSearchParams && associationObject ? (
				<SkeletonRecordAssociationCard isAssetAssociation={isAssetObject(associationObject)} />
			) : (
				<div className="flex items-center justify-center">
					<Spinner size="md" />
				</div>
			)}
		</div>
	);
}
