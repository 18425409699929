import { createUniqueId } from "@salesdesk/salesdesk-utils";
import {
	mStringFieldDef,
	mDataFieldDef,
	FIELD_CREATION_TYPE,
	mObjectDef,
	BOARD_VIEW,
} from "@salesdesk/salesdesk-model";

import { convertFieldDefToField } from "../../../../fields";
import { Field, SDObject } from "@salesdesk/salesdesk-schemas";

export function createEmptyCustomSDObject() {
	const name = new mStringFieldDef(createUniqueId());
	name.name = mObjectDef.NAME_FIELD_NAME;
	name.pluralName = "names";
	name.displayName = "Name";
	name.toolTip = "The name the system uses for this object type.";
	name.required = true;

	return {
		_displayName: "",
		_pluralName: "",
		_description: {},
		_color: "",
		_icon: "",

		_commentsSupported: true,
		_historySupported: true,
		_activitySupported: true,
		_searchable: true,
		_defaultView: BOARD_VIEW.TABLE,
		_dataDef: {
			...convertFieldDefToField(new mDataFieldDef(createUniqueId()), true),
			_creationType: FIELD_CREATION_TYPE.USER,
			_children: [name.unmarshall(true) as Field],
		},
	} as SDObject;
}
