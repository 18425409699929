import { CommentReactionParams } from "@salesdesk/salesdesk-schemas";

import { useEventContext } from "../../../../hooks/useEventContext";
import { LazyCommentPayload } from "./LazyCommentPayload";

export function CommentReactionPayload() {
	const { event } = useEventContext();
	const { emoji, commentId, commentOwnedBy } = event.params as CommentReactionParams;

	return (
		<LazyCommentPayload recordId={event.record_id} commentId={commentId} createdById={commentOwnedBy} emoji={emoji} />
	);
}
