import { getSDRecordSystemFieldValueByFieldName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { useGetRecordsQuery } from "../../../../records";
import { skipToken } from "@reduxjs/toolkit/query";

export function useGetInvitedMeetingParticipants(
	meetingObject: SDObject | undefined,
	meetingRecord: SDRecord | undefined
) {
	const participantIds =
		meetingObject == null || meetingRecord == null
			? undefined
			: (getSDRecordSystemFieldValueByFieldName(
					meetingObject,
					meetingRecord,
					mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME
			  )?._value as number[]);

	return useGetRecordsQuery(participantIds || skipToken);
}
