import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { ReactNode } from "react";

interface ColumnMappingRowProps {
	nameCell: ReactNode;
	mappingCell: ReactNode;
	isLoading?: boolean;
	isActive?: boolean;
	onMouseEnter?: () => void;
}

export function ColumnMappingRow({
	nameCell,
	mappingCell,
	isLoading = false,
	isActive,
	onMouseEnter,
}: ColumnMappingRowProps) {
	return (
		<div
			className={clsx(
				"border-c_border_regular flex items-center justify-between border-b px-2 py-4",
				isActive && "bg-c_bg_04"
			)}
			onMouseEnter={onMouseEnter}
		>
			<div className="flex w-2/5 items-center gap-2">{nameCell}</div>
			<Icon
				icon={ICONS.arrowRight}
				className={clsx("flex items-center", isLoading ? "text-c_icon_disabled" : "text-c_icon_regular")}
			/>
			<div className="w-[45%] pl-4">{mappingCell}</div>
		</div>
	);
}
