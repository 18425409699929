import { Editor } from "@tiptap/core";
import { useState } from "react";
import { DELETE_OPTION, TEXT_ALIGNMENT_OPTIONS } from "../utils";
import { isMacOS } from "../../../../../../../utils";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { BubbleMenuDivider } from "./BubbleMenuDivider";

interface ImageBubbleMenuProps {
	editor: Editor;
}

export function ImageBubbleMenu({ editor }: ImageBubbleMenuProps) {
	const [textAlignmentOptions] = useState(() => TEXT_ALIGNMENT_OPTIONS(isMacOS() ? "⌘" : "Ctrl", "image"));

	return (
		<>
			{textAlignmentOptions.map((option) => (
				<Tooltip key={option.id} placement="bottom" text={option.tooltip} noWrap preventFlip>
					<Button
						variant="text"
						startIcon={option.icon}
						size="xs"
						active={option.isActive(editor)}
						onClick={() => option.action(editor)}
					/>
				</Tooltip>
			))}
			<BubbleMenuDivider />
			<Tooltip placement="bottom" text={DELETE_OPTION.tooltip} noWrap preventFlip>
				<Button variant="text" startIcon={DELETE_OPTION.icon} size="xs" onClick={() => DELETE_OPTION.action(editor)} />
			</Tooltip>
		</>
	);
}
