import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS, useStableNavigate } from "../../../../../routes";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { useToast } from "../../../../Toasts";
import { SettingsContainer } from "../../../SettingsContainer";
import { useRoles } from "../hooks/useRoles";
import { RoleMenu } from "./RoleMenu";
import { EditRoleFormContainer } from "./RoleForm/components/EditRoleFormContainer";

export function RolesPage() {
	const { roles, isLoading: isLoadingRoles } = useRoles();

	const { roleId: strRoleId } = useParams();
	const roleId = strRoleId ? Number(strRoleId) : undefined;

	const role = useMemo(() => {
		if (!roles) return undefined;
		return roles.find((r) => r.id === roleId);
	}, [roleId, roles]);

	const toast = useToast();
	const navigate = useStableNavigate();
	const navigateToFirstRole = useCallback(() => {
		if (!roles?.length) return;
		navigate(PATHS.ACCESS_ROLE(roles[0].id), { replace: true });
	}, [navigate, roles]);

	useEffect(() => {
		if (roleId !== undefined && roles && !role) {
			toast.trigger("error", "Failed to load the role");
			navigateToFirstRole();
		}
		if (!roleId && roles) {
			navigateToFirstRole();
		}
	}, [navigate, roleId, roles, role, toast, navigateToFirstRole]);

	const breadcrumbs = useMemo(() => {
		const crumbs = [
			{
				node: "Access",
				icon: ICONS.lock,
			},
			{
				node: "Roles",
			},
		];
		if (role) {
			crumbs.push({
				node: role.name,
			});
		}
		return crumbs;
	}, [role]);

	return (
		<SettingsContainer breadcrumbs={breadcrumbs}>
			<div className="flex h-full flex-col">
				<div className="flex h-full flex-col items-center overflow-y-scroll">
					<div className="flex h-full w-full gap-5">
						<RoleMenu roles={roles} isLoading={isLoadingRoles} />
						<div className="grow overflow-y-auto py-8">
							<div className="w-full max-w-[694px] pr-5">
								<div className="ml-auto w-full min-w-[400px] max-w-[574px]">
									{!isLoadingRoles && role ? (
										<EditRoleFormContainer role={role} />
									) : (
										<div className="flex flex-col gap-8">
											{Array.from({ length: 6 }).map((_, index) => (
												<Skeleton key={index} className="h-8 w-full" />
											))}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</SettingsContainer>
	);
}
