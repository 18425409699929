export function TenantLogo() {
	/*  
        TODO: Implement the logic to fetch the tenant logo from the server
        - Component should shows company logo otherwise SalesDesk Logo
    */
	return (
		<div className="flex w-full justify-center">
			<img className="max-w-[121px]" src="/static/images/sd_logo_full.svg" alt="Company logo" />
		</div>
	);
}
