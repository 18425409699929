import { mSystemObjectDef } from "../system_object_def";

export class mIssueDef extends mSystemObjectDef {
	static CLASS_NAME = "mIssueDef";
	static NAME = "Issue";
	static PLURAL_NAME = "Issues";
	static ICON = "ph-wrench";

	static ID = 10;

	static STATUS_FIELD_NAME = "status";
	static PRIORITY_FIELD_NAME = "priority";
	static DUE_DATE_FIELD_NAME = "dueDate";
	static ASSIGNEE_FIELD_NAME = "assignee";
	static PROGRESS_FIELD_NAME = "progress";
	static TYPE_FIELD_NAME = "type";

	static CLOSED_STATUS_NAME = "Closed";
	static OPEN_STATUS_NAME = "Open";

	constructor(ownerId) {
		super(mIssueDef.ID, ownerId);
	}
}
