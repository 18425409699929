import { Breadcrumbs } from "../../../../../../components/Breadcrumbs/Breadcrumbs";
import { useMemo } from "react";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { useGetWorkspaceBaseBreadcrumbs } from "../../../../hooks/useGetWorkspaceBaseBreadcrumbs";
import { LibraryItemCard } from "./LibraryItemCard";
import { useGetObjectById } from "../../../../../../hooks/useGetObjectById";
import { mAssetDef, mDocDef } from "@salesdesk/salesdesk-model";

import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../components/PanelTabs";
import { RecentlyAddedRecords } from "./RecentlyAddedRecords";
import { FavoritedRecords } from "./FavoritedRecords";
import { useWorkspaceSharedRecordSearch } from "../../../../hooks/useWorkspaceSharedRecordSearch";
import { getTabId, getTabIndex, RECENT_RECORDS_LIMIT } from "../utils";
import { useWorkspaceLibraryStateContext } from "../hooks/useWorkspaceLibraryStateContext";
import { InfiniteRecordSearchParams } from "../../../../../records";

export function WorkspaceLibrary() {
	const { workspaceRecord, workspaceObject, isLoading } = useWorkspaceContext();
	const { sdObject: assetObject } = useGetObjectById(mAssetDef.ID);
	const { sdObject: docObject } = useGetObjectById(mDocDef.ID);

	const { urlState, propOnChange } = useWorkspaceLibraryStateContext();

	const baseBreadcrumbs = useGetWorkspaceBaseBreadcrumbs(isLoading);

	const mostRecentSearchParams = useMemo(
		() =>
			({
				query: {},
				sort: [{ createdAt: { order: "desc" } }],
			}) satisfies InfiniteRecordSearchParams,
		[]
	);

	const {
		hitCount: assetCount,
		records: recentAssets,
		isLoadingRecords: isLoadingAssets,
	} = useWorkspaceSharedRecordSearch({
		sdObjectFilter: mAssetDef.ID,
		limit: RECENT_RECORDS_LIMIT,
		additionalSearchParams: mostRecentSearchParams,
	});

	const {
		hitCount: docCount,
		records: recentDocs,
		isLoadingRecords: isLoadingDocs,
	} = useWorkspaceSharedRecordSearch({
		sdObjectFilter: mDocDef.ID,
		limit: RECENT_RECORDS_LIMIT,
		additionalSearchParams: mostRecentSearchParams,
	});

	const crumbs = useMemo(() => {
		if (!workspaceRecord || !workspaceObject) {
			return baseBreadcrumbs;
		}
		return [...baseBreadcrumbs, { node: "Library" }];
	}, [baseBreadcrumbs, workspaceObject, workspaceRecord]);

	if (!assetObject || !docObject) {
		return null;
	}

	return (
		<div className="mx-auto my-4 mb-16 w-10/12">
			<div className="py-4">
				<Breadcrumbs crumbs={crumbs} canCopyLink={true} />
			</div>
			<div className="mb-10 flex gap-6 pt-4">
				<LibraryItemCard
					sdObject={assetObject}
					count={assetCount}
					isLoading={isLoadingAssets}
					description="Store and manage media files; images, videos, and PDFs"
				/>
				<LibraryItemCard
					sdObject={docObject}
					count={docCount}
					isLoading={isLoadingDocs}
					description="Access, create, and manage your docs"
				/>
			</div>
			<div>
				<PanelTabs
					selectedIndex={getTabIndex(urlState.tabId)}
					onChange={(tabIndex) => propOnChange("tabId", getTabId(tabIndex))}
				>
					<PanelTabList>
						<PanelTab>Recently added</PanelTab>
						<PanelTab>Starred</PanelTab>
					</PanelTabList>
					<PanelTabPanels>
						<PanelTabPanel>
							<RecentlyAddedRecords
								assetObject={assetObject}
								docObject={docObject}
								recentAssets={recentAssets}
								recentDocs={recentDocs}
								isLoadingAssets={isLoadingAssets}
								isLoadingDocs={isLoadingDocs}
							/>
						</PanelTabPanel>
						<PanelTabPanel>
							<FavoritedRecords />
						</PanelTabPanel>
					</PanelTabPanels>
				</PanelTabs>
			</div>
		</div>
	);
}
