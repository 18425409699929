// Replace with TypeScript enum
export const FIELD_MULTIPLICITY = Object.freeze({
	SINGLE_MULTIPLE: 0,
	SINGLE: 1,
	ZERO_SINGLE: 2,
	ZERO_MULTIPLE: 3,
});

export const FIELD_MULTIPLICITY_INFO = [
	{
		id: FIELD_MULTIPLICITY.SINGLE_MULTIPLE,
		name: "One or more (1..*)",
	},
	{
		id: FIELD_MULTIPLICITY.SINGLE,
		name: "Only one instance (1)",
	},
	{
		id: FIELD_MULTIPLICITY.ZERO_SINGLE,
		name: "None or one (0..1)",
	},
	{
		id: FIELD_MULTIPLICITY.ZERO_MULTIPLE,
		name: "None or multiple (0..*)",
	},
];
