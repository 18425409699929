import { mAssetDef, mDocDef } from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { Link } from "react-router-dom";
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";
import clsx from "clsx";
import { PATHS } from "../../../../../../routes";
import { useGetContextWorkspaceId } from "../../../../hooks/useGetContextWorkspaceId";

const iconFilePathByObjectId: Record<number, string> = {
	[mAssetDef.ID]: "/static/images/icons/workspace_library/assets.svg",
	[mDocDef.ID]: "/static/images/icons/workspace_library/docs.svg",
};

interface LibraryItemCardProps {
	sdObject: SDObject;
	description: string;
	count: number;
	isLoading: boolean;
}

export function LibraryItemCard({ sdObject, description, count, isLoading }: LibraryItemCardProps) {
	const workspaceId = useGetContextWorkspaceId();
	const iconFilePath = iconFilePathByObjectId[sdObject._id];

	if (workspaceId == null) {
		return null;
	}

	return (
		<Link
			className={clsx(
				"flex flex-1 cursor-pointer items-center gap-4 border px-6 py-4",
				"rounded-card border-c_border_regular shadow-card",
				"focus-visible:ring-c_action_focus focus-visible:ring",
				"bg-c_bg_01 hover:bg-c_bg_03"
			)}
			to={PATHS.WORKSPACE_RECORD_BOARD(workspaceId, sdObject)}
		>
			{iconFilePath ? <img src={iconFilePath} alt={`${sdObject._pluralName} icon`} /> : null}
			<div className="flex grow flex-col gap-1">
				<div className="flex justify-between">
					<div className="text-h4">{sdObject._pluralName}</div>
					{isLoading ? (
						<Skeleton className="h-4 w-16" />
					) : (
						<div className="text-label-sm text-c_text_secondary">
							{count} {pluralizeWithS("record", count)}
						</div>
					)}
				</div>
				<div className="text-c_text_secondary">{description}</div>
			</div>
		</Link>
	);
}
