import { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";

import { IconInfo } from "../utils";
import { Button } from "@salesdesk/daisy-ui";

const ICON_ROW_HEIGHT = 40;
const LAST_ICON_ROW_HEIGHT = 32;
interface IconPickerVirtualList {
	value: string | null;
	iconRows: IconInfo[][];
	onIconClick: (iconName: string) => void;
}

export function IconPickerVirtualList({ iconRows, value, onIconClick }: IconPickerVirtualList) {
	const iconContainerRef = useRef<HTMLDivElement>(null);

	const lastRowIndex = iconRows.length - 1;
	const virtualizer = useVirtualizer({
		count: iconRows.length,
		getScrollElement: () => iconContainerRef.current,
		estimateSize: (index) => (index === lastRowIndex ? LAST_ICON_ROW_HEIGHT : ICON_ROW_HEIGHT),
		overscan: 15,
	});

	const items = virtualizer.getVirtualItems();

	return (
		<div ref={iconContainerRef} className="w-full overflow-auto p-0.5">
			<div
				className="relative w-full"
				style={{
					height: virtualizer.getTotalSize(),
				}}
			>
				<div
					className="absolute left-0 top-0"
					style={{
						transform: `translateY(${items[0]?.start ?? 0}px)`,
					}}
				>
					{items.map((virtualRow) => {
						const isLastElement = virtualRow.index === lastRowIndex;
						return (
							<div
								key={virtualRow.key}
								data-index={virtualRow.index}
								ref={virtualizer.measureElement}
								className={clsx("grid grid-cols-6 grid-rows-1 gap-x-3", !isLastElement && "pb-2")}
							>
								{iconRows[virtualRow.index].map(({ name }) => {
									const phName = `ph-${name}`;
									return (
										<Button
											key={name}
											variant="text"
											size="sm"
											startIcon={phName}
											active={value === phName}
											onClick={() => onIconClick(phName)}
										/>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
