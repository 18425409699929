import { useMemo } from "react";

import { CanParameters, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useWebPrincipal } from "../../../../../auth";

export function usePrincipalCanFilteredRecords(
	records: SDRecord[],
	getCanParameters: (record: SDRecord) => CanParameters
) {
	const principal = useWebPrincipal();

	return useMemo(() => {
		const [canRecords, cannotRecords]: SDRecord[][] = [[], []];
		records.forEach((record) => {
			if (principal.can(...getCanParameters(record))) canRecords.push(record);
			else cannotRecords.push(record);
		});

		return [canRecords, cannotRecords];
	}, [records, principal, getCanParameters]);
}
