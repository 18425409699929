import { UI_THEMES } from "@salesdesk/salesdesk-ui";
import { capitalizeString, createHashId } from "@salesdesk/salesdesk-utils";
import { mSingleOptionFieldDef } from "../../option/single_option";

export class mThemeFieldDef extends mSingleOptionFieldDef {
	constructor(id: string | number) {
		super(id);
		this._componentType = mThemeFieldDef.defaultComponentType;
		this._icon = "ph-palette";
		this.optionValues = Object.entries(UI_THEMES).map(([themeName, theme]) => {
			return {
				id: createHashId(themeName),
				name: capitalizeString(themeName),
				color: theme.c_brand_primary,
			};
		});
	}
}
