import { useCallback } from "react";

import { ObjectSortingPopover, SortingDetails } from "../../../../records";
import { useBoardSortSelector } from "../../../store";
import { useBoardPropOnChangeContext } from "../../../hooks/useBoardPropOnChangeContext";
import { useDataboardDetailsContext } from "../../../hooks/useDataboardDetailsContext";
import { BaseBoardControlProps } from "../types";

export const EMPTY_ARRAY = [];

export function BoardSortingPopover({ iconOnly }: BaseBoardControlProps) {
	const boardPropOnChange = useBoardPropOnChangeContext();
	const { sdObject } = useDataboardDetailsContext();

	const boardStateSorts = useBoardSortSelector();

	const onChange = useCallback(
		(newSorts: SortingDetails[]) => {
			boardPropOnChange("sort", newSorts.length ? newSorts : undefined);
		},
		[boardPropOnChange]
	);

	if (!sdObject) {
		return null;
	}

	return <ObjectSortingPopover value={boardStateSorts} onChange={onChange} sdObject={sdObject} iconOnly={iconOnly} />;
}
