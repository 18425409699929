import { useState } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { Dialog, DialogContent, DialogHeader } from "../../../../../../Dialog";
import { SelectableRecordsList } from "./SelectableRecordsList";
import { TypeaheadSelect } from "../../../../../../inputs";
import { useSelectableSDObjectTypesOptions } from "../hooks/useSelectableSDObjectTypesOptions";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { Panels, PanelItem } from "../../../../../../../components/Panels";

export interface AttachmentSelectRecordModalProps {
	onClose: () => void;
	onSelectRecords: (selectedRecords: SDRecord[]) => void;
	channelWorkspaceId?: number;
}

export function AttachmentSelectRecordsModal({
	onClose,
	onSelectRecords,
	channelWorkspaceId,
}: AttachmentSelectRecordModalProps) {
	const {
		selectedSDObjectId,
		onSDObjectOptionChange,
		selectableSDObjectsMap,
		selectableSDObjectTypeOptions,
		isLoading,
	} = useSelectableSDObjectTypesOptions(channelWorkspaceId != null);
	const [selectedRecords, setSelectedRecords] = useState<SDRecord[]>([]);

	const selectedRecordsCount = selectedRecords.length;

	return (
		<Dialog open={true} onOpenChange={() => onClose()}>
			<DialogContent>
				<div className="min-w-[749px] max-w-[749px]">
					<DialogHeader title="Select records" description="Add records to send to a chat" onClose={onClose} />
					<div className="mt-8 flex flex-col gap-6">
						{selectedSDObjectId != null && !isLoading ? (
							<div className="flex flex-col gap-2">
								<span className="text-label-sm text-c_text_secondary">Record type</span>
								<TypeaheadSelect
									value={selectedSDObjectId}
									onChange={onSDObjectOptionChange}
									options={selectableSDObjectTypeOptions}
									isClearable={false}
								/>
							</div>
						) : (
							<div className="flex flex-col gap-2">
								<Skeleton className="h-10 w-5" />
								<Skeleton className="h-10 w-full" />
							</div>
						)}

						<div className="min-h-[382px]">
							<Panels activePanelItemId={selectedSDObjectId}>
								{selectableSDObjectTypeOptions.map(({ id }) => (
									<PanelItem key={id} panelItemId={Number(id)}>
										<SelectableRecordsList
											sdObject={selectableSDObjectsMap[id]}
											selectedRecords={selectedRecords}
											setSelectedRecords={setSelectedRecords}
											channelWorkspaceId={channelWorkspaceId}
										/>
									</PanelItem>
								))}
							</Panels>
						</div>
					</div>
					<div className="flex justify-end gap-3 pt-4 align-middle">
						{selectedRecordsCount ? (
							<p className="flex flex-col justify-center">
								<span className="text-body-sm text-c_text_secondary">
									<span className="text-label-sm">{selectedRecordsCount}</span> Selected
								</span>
							</p>
						) : null}
						<Button
							variant="secondary"
							onClick={() => {
								onSelectRecords([]);
								onClose();
							}}
						>
							Cancel
						</Button>
						<Button onClick={() => onSelectRecords(selectedRecords)} disabled={!selectedRecordsCount}>
							Send
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
