let deploymentConfig = {};

try {
	deploymentConfig = __DEPLOYMENT_CONFIG__;
} catch (err) {
	// TODO: delete this try/catch once this file is no longer loaded by server-side code
}

const enrichedDeploymentConfig = {
	assetsBucket: deploymentConfig.assetsBucket,
	rootAuthUri: `https://${deploymentConfig.stackName}-salesdesk.auth.eu-west-1.amazoncognito.com`,
	rootApiUri: deploymentConfig.rootApiUri,
	rootWebSocketApiUri: deploymentConfig.rootWebSocketApiUri,
	ingestBucket: deploymentConfig.ingestBucket,
	userPoolId: deploymentConfig.cognitoUserPoolId,
	appClientId: deploymentConfig.appClientId,
	centralBucket: deploymentConfig.centralBucket,
	assetsFolder: deploymentConfig.assetsFolder,
	ingestFolder: deploymentConfig.ingestFolder,
};

export const APP_CONFIG = {
	//Auth
	baseAuthRedirectUrl: `${enrichedDeploymentConfig.rootAuthUri}/login?client_id=${enrichedDeploymentConfig.appClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile`,
	// TODO: Remove 'baseAuthLogoutUrl' when new Auth pages will be ready
	baseAuthLogoutUrl: `${enrichedDeploymentConfig.rootAuthUri}/logout?client_id=${enrichedDeploymentConfig.appClientId}&response_type=code`,
	userPoolId: enrichedDeploymentConfig.userPoolId,
	cognitoClientId: enrichedDeploymentConfig.appClientId,
	authTokenUrl: `${enrichedDeploymentConfig.rootAuthUri}/oauth2/token`,
	authUrl: `${enrichedDeploymentConfig.rootAuthUri}/oauth2/authorize`,
	sdApiHost: `${enrichedDeploymentConfig.rootApiUri}`,
	rootWebSocketApiUri: `${enrichedDeploymentConfig.rootWebSocketApiUri}`,
	stackName: deploymentConfig.stackName,
	googleSignInEnabled: true,

	//Storage and I/O;
	centralBucket: enrichedDeploymentConfig.centralBucket,
	storageFolder: enrichedDeploymentConfig.assetsFolder,
	processFolder: enrichedDeploymentConfig.ingestFolder,

	// Userback and Userflow
	userbackToken: "42592|87590|ZbJweIFt4eAiFVvC7wkPNohx3",
	userflowToken: "ct_xpfjcuuydfgplngamyfaxgyr2y",

	//Search
	maxGlobalSearchResults: 10,
	maxLocalSearchResults: 40,
};

export const sentryConst = {
	//General
	dsnUI: deploymentConfig.sentryUiDSN,
	sentryEnv: deploymentConfig.environment,
};
