import { asConst } from "json-schema-to-ts";
import { recordIdSchema } from "../../properties";

export const associationsSummaryRequestSchema = asConst({
	title: "Associations Summary Request Schema",
	description: "The schema used for summarizing records' associations",
	type: "object",
	additionalProperties: false,
	required: ["recordIds"],
	properties: {
		recordIds: {
			title: "Record IDs",
			type: "array",
			items: {
				...recordIdSchema,
				description: "ID of record whose associations summary to query",
			},
		},
	},
});
