import { getUserRecordAvatarFileId, getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../auth";
import { useUserObjectsMap } from "../../users";
import { MessageGroup } from "../types";
import { ChatMessageGroup } from "./ChatMessageGroup";

export function ChatMessageGroupContainer({ messageGroup }: { messageGroup: MessageGroup }) {
	const principal = useWebPrincipal();
	const userObjectMap = useUserObjectsMap();

	const userObject = messageGroup.userRecord != null ? userObjectMap?.get(messageGroup.userRecord._objectDefId) : null;
	const user = {
		id: messageGroup.userId,
		fullName: userObject && messageGroup.userRecord ? getSDRecordName(userObject, messageGroup.userRecord) || "" : "",
		avatarFileId: getUserRecordAvatarFileId(userObject, messageGroup.userRecord),
	};

	return (
		<ChatMessageGroup
			isLocalParticipant={principal.UserRecordId === user.id}
			user={user}
			messages={messageGroup.messages}
			displayTimestamp={messageGroup.displayTimestamp}
		/>
	);
}
