import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";
import { FieldInfo, SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";
import { mTaskDef } from "@salesdesk/salesdesk-model";
import { UserAuthorisedLoginIcon } from "../../../../users/components/UserAuthorisedLoginIcon";
import { RecordBadges } from "../../RecordBadges";
import { RecordBadgeType } from "../../RecordBadges/types";
import clsx from "clsx";
import { CompletableRecordCheckMarkWrapper, getIsCompletableRecord } from "../../../../completableRecord";
interface PrimaryHeadingProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
	fieldInfo: FieldInfo;
	vertical: boolean;
	recordBadges: RecordBadgeType[];
}

export function PrimaryHeading({ sdObject, sdRecord, fieldInfo, vertical, recordBadges }: PrimaryHeadingProps) {
	// We're changing the variant in order to truncate the text in horizontal mode
	const variant = vertical ? DisplayFieldVariant.cardTitle : DisplayFieldVariant.card;

	let displayHeadingComponent = (
		<DisplayFieldFactory field={fieldInfo.field} value={fieldInfo.value} variant={variant} />
	);

	if (getIsCompletableRecord(sdObject)) {
		displayHeadingComponent = (
			<CompletableRecordCheckMarkWrapper sdObject={sdObject} sdRecord={sdRecord}>
				{displayHeadingComponent}
			</CompletableRecordCheckMarkWrapper>
		);
	}
	const contents = (
		<>
			{displayHeadingComponent}
			<UserAuthorisedLoginIcon sdRecord={sdRecord} />
		</>
	);

	return (
		<div className={clsx("text-label flex w-full gap-2", vertical ? "text-wrap text-start" : "")}>
			{!vertical ? <div className="flex w-full items-center gap-2 overflow-hidden">{contents}</div> : contents}
			{!vertical ? <RecordBadges recordBadges={recordBadges} className="ml-auto shrink-0 pl-2 pt-0.5" /> : null}
		</div>
	);
}
