import { MeetingSummary, RecordDetailsFields, RecordDetailsFooter, RecordTitleField } from "../../common";
import { RecordDetailTabs } from "./RecordDetailTabs/RecordDetailTabs";
import { FieldInfo, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useRecordDetailViewState } from "../hooks";
import { RecordDetailsFieldsLoading } from "./RecordDetailsFieldsLoading";
import clsx from "clsx";

interface RecordDetailsMainTabProps {
	sdObject?: SDObject;
	sdRecord?: SDRecord;
	isLoading?: boolean;
	title: string | FieldInfo;
	recordFields: FieldInfo[];
	inWorkspace?: boolean;
}

export function RecordDetailsMainTab({
	sdRecord,
	sdObject,
	isLoading,
	title,
	recordFields,
	inWorkspace,
}: RecordDetailsMainTabProps) {
	const { recordDetailViewState, recordDetailPropOnChange } = useRecordDetailViewState();

	const currentTab = recordDetailViewState.tab || 0;

	return (
		<div className="text-body flex h-full gap-0 overflow-hidden">
			<div className="mt-1 flex h-full max-h-full w-1/3 min-w-[440px] max-w-[580px] shrink-0 flex-col overflow-hidden">
				<div className={clsx("flex h-full max-h-full flex-col overflow-hidden", inWorkspace ? "pl-0.5" : "pl-6")}>
					{isLoading || !sdObject || !sdRecord ? (
						<RecordDetailsFieldsLoading />
					) : (
						<>
							<div className="mb-2 mt-1 pr-5">
								<RecordTitleField title={title} sdObject={sdObject} sdRecord={sdRecord} />
							</div>
							<div className="h-full max-h-full overflow-auto pb-4 pr-5">
								<RecordDetailsFields sdObject={sdObject} sdRecord={sdRecord} recordFields={recordFields} />
								<MeetingSummary sdObject={sdObject} sdRecord={sdRecord} />
							</div>
						</>
					)}
				</div>
				{isLoading ? null : <RecordDetailsFooter alignment="start" />}
			</div>
			<RecordDetailTabs
				currentTab={currentTab}
				onTabChange={(tabIndex) => recordDetailPropOnChange("tab", tabIndex)}
				inWorkspace={inWorkspace}
			/>
		</div>
	);
}
