import { useMemo } from "react";

import { AbilityAction, AbilitySubject, SDRecord, sdSubject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

import { useWebPrincipal } from "../../../../../auth";
import { useGetObjectById } from "../../../../../hooks";

import { MenuContents, MenuItem, PopoverMenu } from "../../../../menu";
import { CustomRecordMenuItemsFunc } from "../../../types";
import { useRecordDeleteContext } from "../../DeleteRecord";
import { useRecordActions } from "../hooks/useRecordActions";
import { usePrimaryRecordActions } from "../hooks/usePrimaryRecordActions";
import { useWorkspaceSharingActions } from "../hooks/useWorkspaceSharingActions";

interface RecordContextMenuProps {
	sdRecord: SDRecord;
	allowDelete?: boolean;
	getCustomContextMenuItems?: CustomRecordMenuItemsFunc;
}

export function RecordContextMenu({ sdRecord, allowDelete = true, getCustomContextMenuItems }: RecordContextMenuProps) {
	const principal = useWebPrincipal();

	const { setRecordToDelete } = useRecordDeleteContext();

	const { sdObject, isLoading: loadingSDObject } = useGetObjectById(sdRecord._objectDefId);

	const recordSpecificContextMenuItems = useRecordActions(sdRecord, sdObject, false);
	const { workspaceSharingAction, showingShareAction } = useWorkspaceSharingActions(sdRecord);

	const hasSdObject = !loadingSDObject && sdObject;

	const primaryMenuItems: MenuItem[] = usePrimaryRecordActions(sdRecord);

	const menuContents: MenuContents = useMemo(() => {
		const contents: MenuContents = [[...primaryMenuItems]];

		if (workspaceSharingAction) {
			if (showingShareAction) {
				contents.unshift([workspaceSharingAction]);
			} else {
				contents.push([workspaceSharingAction]);
			}
		}

		if (recordSpecificContextMenuItems?.length) {
			contents.push(recordSpecificContextMenuItems.map((menuItem) => ({ ...menuItem, subText: undefined })));
		}

		if (getCustomContextMenuItems) {
			const { primaryItems, appendedItems } = getCustomContextMenuItems(sdRecord, hasSdObject ? sdObject : undefined);

			if (primaryItems) {
				// We know that the first element of contents are the primary menu items
				// (which must be set as an array of MenuItem in the hook above)
				(contents[0] as MenuItem[]).unshift(...primaryItems);
			}

			if (appendedItems?.length) {
				contents.push(appendedItems);
			}
		}

		if (
			setRecordToDelete &&
			allowDelete &&
			principal.can(AbilityAction.Delete, sdSubject(AbilitySubject.Record, sdRecord))
		) {
			contents.push([
				{
					icon: ICONS.trash,
					text: "Delete",
					onClick: () => setRecordToDelete(sdRecord),
					variant: "destructive",
					type: "button",
				},
			]);
		}

		return contents;
	}, [
		primaryMenuItems,
		workspaceSharingAction,
		recordSpecificContextMenuItems,
		getCustomContextMenuItems,
		setRecordToDelete,
		allowDelete,
		principal,
		sdRecord,
		showingShareAction,
		hasSdObject,
		sdObject,
	]);

	return (
		<PopoverMenu menuContents={menuContents} widthVariant="fit">
			<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
		</PopoverMenu>
	);
}
