import { JSONContent } from "@tiptap/core";
import { APP_CONFIG } from "../../../../../../../app/app_config";
import { RECORD_SIDE_PANEL_RECORD_ID_PARAM, TENANT_ROUTES } from "@salesdesk/salesdesk-schemas";
import { tenantMatchRoute } from "../../../../../../../routes";

export function findAllLinksWithinJSONContent(jsonContent: JSONContent): string[] {
	const links: string[] = [];

	const findLinks = (node: JSONContent): void => {
		if (node.type === "link" && node.attrs?.href) {
			links.push(node.attrs.href);
		}

		if (node.marks) {
			node.marks.forEach((mark) => {
				if (mark.type === "link" && mark.attrs?.href) {
					links.push(mark.attrs.href);
				}
			});
		}

		if (node.content) {
			node.content.forEach(findLinks);
		}
	};

	findLinks(jsonContent);
	return links;
}

export function recordIdForLink(link: string, currentWorkspaceId: string) {
	const url = new URL(link);

	if (url.host !== window.location.host && url.host !== `${APP_CONFIG.stackName}.salesdesk.app`) {
		return null;
	}

	// Workspace Meeting URL
	const workspaceMeetingMatch = tenantMatchRoute(TENANT_ROUTES.WORKSPACE_MEETING_ROUTE, url.pathname);
	if (workspaceMeetingMatch) {
		const { workspaceId, meetingId } = workspaceMeetingMatch.params;

		const recordId = Number(meetingId);
		return workspaceId === currentWorkspaceId && !isNaN(recordId) ? recordId : null;
	}

	// Workspace object/library boards
	const workspaceObjectBoardMatch = tenantMatchRoute(TENANT_ROUTES.WORKSPACE_OBJECT_BOARD, url.pathname);
	const workspaceLibraryBoardMatch = tenantMatchRoute(TENANT_ROUTES.WORKSPACE_LIBRARY_BOARD, url.pathname);
	const workspaceBoardMatch = workspaceObjectBoardMatch || workspaceLibraryBoardMatch || null;

	if (workspaceBoardMatch) {
		const { workspaceId } = workspaceBoardMatch.params;
		const recordId = Number(url.searchParams.get(RECORD_SIDE_PANEL_RECORD_ID_PARAM));

		return workspaceId === currentWorkspaceId && !isNaN(recordId) ? recordId : null;
	}

	// Workspace library details
	const workspaceLibraryDetailsMatch = tenantMatchRoute(TENANT_ROUTES.WORKSPACE_LIBRARY_DETAILS, url.pathname);
	if (workspaceLibraryDetailsMatch) {
		const { workspaceId, recordId: recordIdParam } = workspaceLibraryDetailsMatch.params;

		const recordId = Number(recordIdParam);
		return workspaceId === currentWorkspaceId && !isNaN(recordId) ? recordId : null;
	}

	return null;
}
