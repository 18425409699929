import { RuleElement } from "../ruleElements/RuleElement";
import { RuleElementType } from "../../../types";

export function SelectorPlaceholder({ placeHolderText }: { placeHolderText: string }) {
	return (
		<div className="flex h-full items-center justify-center">
			<div className="gap flex flex-col items-center gap-3">
				<div className="text-body-sm text-c_text_secondary text-center">
					Click on each element to setup a notification rule
				</div>
				<div className="relative opacity-50">
					<RuleElement type={RuleElementType.when} values={[{ placeholder: placeHolderText, disabled: true }]} />
					<img src={"/static/images/mouse_pointer.svg"} alt="mousePointer" className="absolute -bottom-6 left-16" />
				</div>
			</div>
		</div>
	);
}
