import {
	GetImportJobPath,
	Import,
	ImportCreateRequest,
	ImportJob,
	ImportJobCreateRequest,
	ImportJobsResponse,
	ImportJobStatus,
	ImportMappingEntry,
	ImportMappingEntryCreateRequest,
	ImportPreviewRecord,
} from "@salesdesk/salesdesk-schemas";
import { SDApi } from "../../api";

interface CreateImportJobParams {
	importId: string;
	dryRun: boolean;
	mappingEntries: ImportMappingEntryCreateRequest[];
}

interface GetImportPreviewParams {
	importId: string;
	mappingEntries: ImportMappingEntryCreateRequest[];
}

interface GetMyImportJobsPathParams {
	objectId: number;
	status: ImportJobStatus;
}

export const importApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			createImport: builder.mutation<Import, ImportCreateRequest>({
				query: (body) => ({
					url: "/imports",
					method: "POST",
					body,
				}),
			}),
			importMappings: builder.query<ImportMappingEntry[], string>({
				query: (importId) => ({
					url: `/imports/${importId}/mappings`,
				}),
			}),
			createImportJob: builder.mutation<ImportJob, CreateImportJobParams>({
				query: ({ importId, dryRun, mappingEntries }) => ({
					url: `/imports/${importId}/jobs`,
					method: "POST",
					body: { dryRun, mappingEntries } satisfies ImportJobCreateRequest,
				}),
			}),
			getImportJob: builder.query<ImportJob, GetImportJobPath>({
				query: ({ importId, jobId }) => ({
					url: `/imports/${importId}/jobs/${jobId}`,
				}),
			}),
			getImportPreview: builder.mutation<ImportPreviewRecord[], GetImportPreviewParams>({
				query: ({ importId, mappingEntries }) => ({
					url: `/imports/${importId}/preview`,
					method: "POST",
					body: mappingEntries,
				}),
			}),
			getMyImportJobs: builder.query<ImportJobsResponse, GetMyImportJobsPathParams>({
				query: ({ objectId, status }) => ({
					url: `/me/imports/jobs/${objectId}/status/${status}`,
				}),
			}),
		};
	},
});

export const {
	useCreateImportMutation,
	useImportMappingsQuery,
	useCreateImportJobMutation,
	useGetImportJobQuery,
	useGetImportPreviewMutation,
	useGetMyImportJobsQuery,
} = importApi;
