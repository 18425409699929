import { useMemo } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { FilterOperator, Filter, FilterId, FilterTargetMap } from "../types";
import { ButtonSelect, SelectOptions } from "../../../../inputs";
import { FILTER_OPERATOR_OPTIONS, FilterFieldSize, getFilterOptions } from "../utils";
import { FilterInputFactory } from "./FilterInputFactory";
import { tw } from "../../../../../utils/tailwind-helpers";
import { Button } from "@salesdesk/daisy-ui";

const filterRowWidth: Record<FilterFieldSize, string> = {
	base: tw`max-w-[300px]`,
	md: tw`max-w-[350px]`,
	lg: tw`max-w-[400px]`,
	xl: tw`max-w-[480px]`,
};

interface FilterRowProps {
	index: number;
	value: Filter;
	onChange: (newDetails: Filter) => void;
	filterTargetMap: FilterTargetMap;
	filterTargetOptions: SelectOptions;
	onDelete: () => void;
	operatorType: FilterOperator;
	onOperatorTypeChange: (newOperator: FilterOperator) => void;
}

export function FilterRow({
	index,
	value,
	onChange,
	filterTargetMap,
	filterTargetOptions,
	onDelete,
	operatorType,
	onOperatorTypeChange,
}: FilterRowProps) {
	const { filterId, filterTarget, value: filterValue, value2: filterValue2 } = value;

	const filterOptions = useMemo(() => {
		return getFilterOptions(value.filterTarget, filterTargetMap);
	}, [value.filterTarget, filterTargetMap]);

	const selectedFilterOption = useMemo(() => {
		return filterOptions ? filterOptions.find((option) => option.id === filterId) ?? filterOptions[0] : undefined;
	}, [filterOptions, filterId]);

	return (
		<div className="flex w-full items-center justify-stretch gap-2 py-2">
			{index > 0 ? (
				<ButtonSelect
					buttonVariant="outlined"
					selectPopoverOptions={{ placement: "bottom-start" }}
					value={operatorType}
					disabled={index > 1}
					options={FILTER_OPERATOR_OPTIONS}
					onChange={(newOperator) => onOperatorTypeChange(newOperator as FilterOperator)}
				/>
			) : null}
			<ButtonSelect
				buttonVariant="outlined"
				selectPopoverOptions={{ placement: "bottom-start" }}
				value={filterTarget}
				options={filterTargetOptions}
				onChange={(newFieldId) => {
					const filterTarget = String(newFieldId);
					const newFilterOptions = getFilterOptions(filterTarget, filterTargetMap);
					if (!newFilterOptions.length) return;
					onChange({
						filterId: newFilterOptions[0].id,
						value: undefined,
						value2: undefined,
						filterTarget,
					});
				}}
			/>
			{filterOptions.length ? (
				<div
					className={clsx("flex flex-grow items-center gap-2", filterRowWidth[selectedFilterOption?.size ?? "base"])}
				>
					<ButtonSelect
						buttonVariant="outlined"
						selectPopoverOptions={{ placement: "bottom-start" }}
						value={selectedFilterOption?.id}
						options={filterOptions}
						disabled={filterOptions.length === 1}
						onChange={(newFilterId) => {
							const newSelectedFilterOption = filterOptions.find((option) => option.id === newFilterId);
							const keepValue = newSelectedFilterOption?.fieldType === selectedFilterOption?.fieldType;
							const keepValue2 = newSelectedFilterOption?.field2Type === selectedFilterOption?.field2Type;
							const newFilter = {
								filterTarget: value.filterTarget,
								value: keepValue ? value.value : undefined,
								value2: keepValue2 ? value.value2 : undefined,
								filterId: newFilterId as FilterId,
							};
							onChange(newFilter);
						}}
					/>
					{selectedFilterOption?.fieldType ? (
						<FilterInputFactory
							filterTarget={filterTargetMap[value.filterTarget]}
							fieldType={selectedFilterOption?.fieldType}
							value={filterValue}
							onChange={(newFilterValue) => onChange({ ...value, value: newFilterValue })}
						/>
					) : null}
					{selectedFilterOption?.field2Type ? (
						<>
							<div>-</div>
							<FilterInputFactory
								filterTarget={filterTargetMap[value.filterTarget]}
								fieldType={selectedFilterOption?.field2Type}
								value={filterValue2}
								onChange={(newFilterValue) => onChange({ ...value, value2: newFilterValue })}
							/>
						</>
					) : null}
				</div>
			) : (
				<div className="text-body-sm text-c_text_secondary w-full">Filtering for field type isn't implemented yet</div>
			)}
			<Button size="sm" variant="text" startIcon={ICONS.trash} onClick={() => onDelete()} />
		</div>
	);
}
