import { Controller, useWatch } from "react-hook-form";
import { useRoleFormContext } from "../../hooks/useRoleFormContext";
import { Checkbox } from "../../../../../../../inputs";
import { GetKeysOfPropertyType } from "../../../../../../../../utils/typescript-utils";
import { RoleFormData } from "../../types";
import { useEffect, useRef } from "react";

export type HasClaimsSectionRowProps = {
	name: GetKeysOfPropertyType<RoleFormData, boolean>;
	label: string;
	descriptions: {
		hasClaim: string;
		hasNotClaim: string;
	};
	onChange?: (name: GetKeysOfPropertyType<RoleFormData, boolean>, hasClaim: boolean) => void;
};

export function HasClaimSectionRow({ name, label, onChange }: HasClaimsSectionRowProps) {
	const { readOnly, control } = useRoleFormContext();
	const value = useWatch({ control, name });
	const firstUpdate = useRef(true);

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (onChange != null) onChange(name, value);
	}, [name, firstUpdate, value, onChange]);

	return (
		<label className="flex gap-2">
			<Controller
				key={name}
				control={control}
				name={name}
				render={({ field: { onChange, onBlur, value, ref } }) => (
					<Checkbox id={name} onChange={onChange} onBlur={onBlur} ref={ref} value={value} disabled={readOnly} />
				)}
			/>
			<div className="text-label">{label}</div>
		</label>
	);
}
