import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { useImportContext } from "../../hooks/useImportContext";
import { ICONS } from "@salesdesk/salesdesk-ui";

export function CurrentFileName() {
	const { sdImport } = useImportContext();

	if (!sdImport) {
		return null;
	}

	return (
		<div className="flex w-full shrink items-center gap-2 overflow-hidden">
			<Icon icon={ICONS.csv} className="text-c_icon_regular flex shrink-0 items-center" />
			<Tooltip text={sdImport.fileName} showOnTruncated>
				<div className="text-label-sm text-c_text_secondary truncate">{sdImport.fileName}</div>
			</Tooltip>
		</div>
	);
}
