import { lazy, Suspense } from "react";

const ThemableSvgs = {
	file_default_placeholder: lazy(() => import("./FileIcons/FileDefaultPlaceholderSvg")),
	file_image_placeholder: lazy(() => import("./FileIcons/FileImagePlaceholderSvg")),
	file_video_placeholder: lazy(() => import("./FileIcons/FileVideoPlaceholderSvg")),
	file_audio: lazy(() => import("./FileIcons/FileAudioSvg")),
	file_default: lazy(() => import("./FileIcons/FileDefaultSvg")),
	file_image: lazy(() => import("./FileIcons/FileImageSvg")),
	file_pdf: lazy(() => import("./FileIcons/FilePdfSvg")),
	file_presentation: lazy(() => import("./FileIcons/FilePresentationSvg")),
	file_spreadsheet: lazy(() => import("./FileIcons/FileSpreadsheetSvg")),
	file_text: lazy(() => import("./FileIcons/FileTextSvg")),
	file_video: lazy(() => import("./FileIcons/FileVideoSvg")),
	file_zip: lazy(() => import("./FileIcons/FileZipSvg")),

	products_graphic: lazy(() => import("./Graphics/ProductsGraphicSvg")),
	chat_graphic: lazy(() => import("./Graphics/ChatGraphicSvg")),
};

export type ThemableSvgName = keyof typeof ThemableSvgs;

interface ThemableSvgLoaderProps {
	name: ThemableSvgName;
}

export function ThemableSvgLoader({ name }: ThemableSvgLoaderProps) {
	const IconComponent = ThemableSvgs[name];

	if (!IconComponent) {
		return null;
	}

	return (
		<Suspense fallback={null}>
			<IconComponent />
		</Suspense>
	);
}
