import { Bookmark } from "@salesdesk/salesdesk-schemas";
import { useGetObjectsQuery } from "../../../objects/api/objectsApi";
import { useGetObjectById } from "../../../../hooks/useGetObjectById";

export function useBookmarkSdObject(bookmark: Bookmark) {
	const { data: sdObjects } = useGetObjectsQuery();
	const { sdObject } = useGetObjectById(bookmark.objectId);

	return { sdObjects, sdObject };
}
