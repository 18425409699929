import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PopoverMenu } from "../../../../../menu";
import { useRecordCreateMenu } from "../../hooks/useRecordCreateMenu";
import { useCreateRecordDialogContext } from "../../hooks/useCreateRecordDialogContext";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { useCallback } from "react";

export function RecordCreateMenu() {
	const { openModal } = useCreateRecordDialogContext();

	const defaultAction = useCallback(
		(sdObject: SDObject) => {
			openModal({ sdObject });
		},
		[openModal]
	);

	const { menuItems, actionIsLoading } = useRecordCreateMenu(defaultAction);

	if (!menuItems.length) {
		return null;
	}

	return (
		<PopoverMenu tooltipText="Create a record" menuContents={menuItems}>
			<Button startIcon={ICONS.plus} variant="inverted" isLoading={actionIsLoading} />
		</PopoverMenu>
	);
}
