import { useMemo } from "react";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

import { PATHS } from "../../../../../../routes";
import { MenuContents, PopoverMenu } from "../../../../../menu";
import { useBulkEditContext } from "../../../BulkEdit";
import { useBoardViewSelector } from "../../../../store";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";

export function AdditionalBoardActions() {
	const { sdObject } = useDataboardDetailsContext();
	const boardView = useBoardViewSelector();

	const { inBulkEditMode, toggleBulkEditMode } = useBulkEditContext();

	const menuContents = useMemo(() => {
		if (!sdObject) return [];
		const elements: MenuContents = [
			{
				icon: ICONS.notePencil,
				text: inBulkEditMode ? "Exit bulk edit" : "Bulk edit",
				type: "button",
				onClick: toggleBulkEditMode,
			},
		];
		elements.push({
			icon: ICONS.download,
			text: "Import CSV file",
			type: "link",
			link: PATHS.IMPORT(sdObject),
		});
		return elements;
	}, [sdObject, inBulkEditMode, toggleBulkEditMode]);

	return (
		<PopoverMenu placement="bottom-end" menuContents={menuContents}>
			{/* No bulk editing for calendar view */}
			<Button startIcon={ICONS.verticalDots} variant="text" size="sm" disabled={boardView === BOARD_VIEW.CALENDAR} />
		</PopoverMenu>
	);
}
