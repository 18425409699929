import clsx from "clsx";
import { useMemo } from "react";
import { NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";

import { CHIME_CHANNEL_EVERYONE_MENTION_ID } from "@salesdesk/salesdesk-schemas";
import { RecordPreviewPopover } from "../../records";

export function MentionNode({ node, extension }: NodeViewProps) {
	const theme = extension.options.theme ?? "light";

	const mentionContent = useMemo(() => {
		return (
			<NodeViewWrapper className="inline w-fit">
				<span
					className={clsx(
						theme === "light" ? "bg-c_bg_02 text-c_text_placeholder" : "bg-c_bg_01/25 text-c_text_inverted",
						"inline-block rounded-full px-2"
					)}
				>
					@<span className={clsx(theme === "light" && "text-c_text_link")}>{node.attrs.label ?? node.attrs.id}</span>
				</span>
			</NodeViewWrapper>
		);
	}, [node.attrs.label, node.attrs.id, theme]);

	if (node.attrs.id === CHIME_CHANNEL_EVERYONE_MENTION_ID) {
		return mentionContent;
	}

	return (
		<RecordPreviewPopover recordId={node.attrs.id} isWithinDropdown={false}>
			{mentionContent}
		</RecordPreviewPopover>
	);
}
