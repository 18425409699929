import { useMemo } from "react";
import { useNotificationRuleDialogContext } from "../useNotificationRuleDialogContext";
import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";

export function useUserActivityActionOptions(ruleType: NotificationRuleType) {
	const { ruleConfigs } = useNotificationRuleDialogContext();

	const actionOptions = useMemo(() => {
		return ruleConfigs
			.filter((config) => config.objectIdNotRequired)
			.map((config) => ({ id: config.eventType, name: config.titles[ruleType] }))
			.sort((a, b) => a.name.localeCompare(b.name));
	}, [ruleConfigs, ruleType]);

	return { actionOptions };
}
