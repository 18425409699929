import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mRatingFieldDef extends mFieldDef {
	static MAX_RATING = 5;

	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.RATING.name];
	static defaultComponentType: FieldComponentName = mRatingFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mRatingFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.RATING.icon;
		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.RATING.name;
	}

	override get supportedComponentTypes() {
		return mRatingFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value)) {
			if (Number.isNaN(value) || !Number.isInteger(value) || value < 0 || value > mRatingFieldDef.MAX_RATING) {
				return `Must be a value between 0 and ${mRatingFieldDef.MAX_RATING}`;
			}
		}

		return super.validate(value, isTemplate);
	}
}
