import { Field, SDApiKey } from "@salesdesk/salesdesk-schemas";
import { TableColumn, TableRow } from "../../../Table";

export type FieldAndValue = { field: Field; value: unknown };

export type RowData = SDApiKey | FieldAndValue | boolean;
export type RecordTableColumn = TableColumn<RowData>;
export type RecordTableRow = TableRow<RowData>;

export function isFieldAndValue(data: unknown): data is FieldAndValue {
	return data != null && typeof data === "object" && "field" in data && "value" in data;
}

export function isSdApiKey(data: unknown): data is SDApiKey {
	return data != null && typeof data === "object" && "apiKeyId" in data;
}
