import {
	FieldDefFactory,
	mSingleOptionFieldDef,
	mStringFieldDef,
	mBooleanFieldDef,
	mIntegerFieldDef,
	mFieldDef,
	mOptionFieldDef,
	mOptionsBuilderFieldDef,
	FIELD_MULTIPLICITY,
	FIELD_MULTIPLICITY_INFO,
	FIELD_TYPES,
	mObjectDef,
	mInteractionDef,
	mInteractionShareDef,
	mTenantSettingsDef,
	mTenantDef,
	mAssetDef,
	mSalesDeskCustomerDef,
	mCurrencyFieldDef,
	FIELD_UNIQUE_INFO,
	UniqueFieldType,
} from "@salesdesk/salesdesk-model";
import { FIELD_COMPONENT_TYPES, FieldComponentName, getFieldComponentDisplayName } from "@salesdesk/salesdesk-ui";
import { SDObject, Field, getStatusIndicatorFieldForObject } from "@salesdesk/salesdesk-schemas";

import { convertFieldToFieldDef, isSystemField } from "../../../../fields";
import { FieldSettingsField } from "../types";
import { SelectOption } from "../../../../inputs";

export function getFieldsSettingsByFieldState(
	sdObjectId: SDObject["_id"],
	field: Field,
	isNew: boolean,
	sdObjectOptions: SelectOption[]
) {
	const isSystemType = isSystemField(field);
	const fieldDef = convertFieldToFieldDef(field);

	/*
		Basic group fields
	*/
	const name = new mStringFieldDef("_name");
	name.name = "_name";
	name.pluralName = "names";
	name.displayName = "Name";
	name.formatDescription = "Name";
	name.maxLength = 30;
	name.required = true;
	name.toolTip = "Name used by the system. Cannot be changed later.";
	name.editable = isNew;

	const displayName = new mStringFieldDef("_displayName");
	displayName.name = "_displayName";
	displayName.pluralName = "displayNames";
	displayName.displayName = "Display name";
	displayName.formatDescription = "Display name";
	displayName.maxLength = 30;
	displayName.required = true;
	displayName.toolTip = "Name shown to users";

	const pluralName = new mStringFieldDef("_pluralName");
	pluralName.name = "_pluralName";
	pluralName.pluralName = "pluralNames";
	pluralName.displayName = "Plural Name";
	pluralName.formatDescription = "Plural Name";
	pluralName.toolTip = 'Name shown to users in plural form. For example "User" is "Users" in plural form.';
	pluralName.maxLength = 100;
	pluralName.required = true;
	pluralName.editable = false;
	pluralName.editable = isNew;

	const description = new mStringFieldDef("_description");
	description.name = "_description";
	description.pluralName = "descriptions";
	description.displayName = "Description";
	description.formatDescription = "Description of field";
	description.toolTip = "Description of the field for future reference.";
	description.maxLength = 100000;

	let dateOnly = null;
	if (fieldDef.supportsDateOnly()) {
		dateOnly = new mBooleanFieldDef("_dateOnly");
		dateOnly.name = "_dateOnly";
		dateOnly.pluralName = "date only";
		dateOnly.displayName = "Date Only";
		dateOnly.toolTip = "If time should be excluded from the range";
		dateOnly.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
		dateOnly.icon = "";
	}

	/*
		Presentation group fields
	*/
	const hidden = new mBooleanFieldDef("_hidden");
	hidden.name = "_hidden";
	hidden.pluralName = "hidden";
	hidden.displayName = "Hidden";
	hidden.toolTip = "Whether the field is visible to users.";
	hidden.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
	hidden.defaultValue = false;
	hidden.icon = "";
	hidden.editable = !isSystemType && field._name !== mObjectDef.NAME_FIELD_NAME;

	/*
		Formatting group fields
	*/

	let objectType = null;

	if (fieldDef.isObjectType()) {
		objectType = new mSingleOptionFieldDef("_objectDefId");
		objectType.name = "_objectDefId";
		objectType.pluralName = "objectTypes";
		objectType.displayName = "Object Type";
		objectType.formatDescription = "Type of object";
		objectType.toolTip = "The type of the objects you want to search and select.";
		objectType.optionValues = sdObjectOptions;
		objectType.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		objectType.required = true;
		objectType.editable = isNew;
	}

	let componentType = null;
	if (fieldDef.supportedComponentTypes.length > 1) {
		componentType = new mSingleOptionFieldDef("_componentType");
		componentType.name = "_componentType";
		componentType.pluralName = "componentType";
		componentType.displayName = "Component Type";
		componentType.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		componentType.required = true;
		componentType.toolTip = "The input component that you wish to use with this field. For example, checkbox.";
		componentType.optionValues = fieldDef.supportedComponentTypes.map((componentType: FieldComponentName) => {
			return {
				id: componentType,
				name: getFieldComponentDisplayName(componentType),
			};
		});
	}

	let options = null;

	if (
		fieldDef.isOptionType() &&
		!fieldDef.isBackingStore() &&
		fieldDef.type !== FIELD_TYPES.PRIORITY.name &&
		fieldDef.type !== FIELD_TYPES.BOOLEAN.name
	) {
		options = new mOptionsBuilderFieldDef("_optionValues");
		options.name = "_optionValues";
		options.pluralName = "options";
		options.displayName = "Options";
		options.formatDescription = "Options available";
		options.toolTip = "The set of options that a user can choose from in this option field type.";
		options.required = true;

		const { fieldName, outcomeNames } = getStatusIndicatorFieldForObject(sdObjectId);
		if (outcomeNames && field._name === fieldName) {
			options.outcomeIds = field._optionValues
				?.filter((option) => outcomeNames.includes(option.name))
				.map((option) => option.id) as string[] | undefined;
		}
	}

	const editable = new mBooleanFieldDef("_editable");
	editable.name = "_editable";
	editable.pluralName = "editable";
	editable.displayName = "Editable";
	editable.componentType = FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name;
	editable.defaultValue = false;
	editable.icon = "";
	editable.editable = isNew;

	/*
		Validation Field group
	*/
	const multiplicity = new mSingleOptionFieldDef("_multiplicity");
	multiplicity.name = "_multiplicity";
	multiplicity.pluralName = "multiplicities";
	multiplicity.displayName = "Multiplicity";
	multiplicity.required = true;
	multiplicity.optionValues = FIELD_MULTIPLICITY_INFO;
	multiplicity.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
	multiplicity.defaultValue = FIELD_MULTIPLICITY.SINGLE;
	multiplicity.editable = isNew;

	let formatDescription = null;
	if (fieldDef.supportsFormatDescription()) {
		formatDescription = new mStringFieldDef("_formatDescription");
		formatDescription.name = "_formatDescription";
		formatDescription.pluralName = "formatDescriptions";
		formatDescription.displayName = "Format Description";
		formatDescription.formatDescription = "Formatting explanation";
		formatDescription.toolTip = "Formatting rules for value of this field";
		formatDescription.maxLength = 100;
	}

	const tooltip = new mStringFieldDef("_toolTip");
	tooltip.name = "_toolTip";
	tooltip.pluralName = "tooltips";
	tooltip.displayName = "Tool tip";
	tooltip.toolTip = "Help shown to users";
	tooltip.maxLength = 10000;

	let maxLength = null;
	if (fieldDef.supportsMaxLength()) {
		maxLength = new mIntegerFieldDef("_maxLength");
		maxLength.name = "_maxLength";
		maxLength.pluralName = "maxLengths";
		maxLength.displayName = "Max. Length";
		maxLength.toolTip = "Maximum Length of field value";
		maxLength.maxLength = 100;
		maxLength.editable = false;
		maxLength.editable = !isSystemType;
	}

	let required = null;
	if (fieldDef.type !== FIELD_TYPES.BOOLEAN.name) {
		required = new mBooleanFieldDef("_required");
		required.name = "_required";
		required.pluralName = "required";
		required.displayName = "Required";
		required.componentType = FIELD_COMPONENT_TYPES.CHECKBOX.name;
		required.defaultValue = false;
		required.icon = "";
		required.editable = field._name === mObjectDef.NAME_FIELD_NAME ? false : !isSystemType;
	}

	let unique = null;
	if (fieldDef.supportsUniqueValue()) {
		unique = new mSingleOptionFieldDef("_unique");
		unique.name = "_unique";
		unique.pluralName = "unique";
		unique.displayName = "Unique";
		unique.required = true;
		unique.optionValues = FIELD_UNIQUE_INFO;
		unique.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		unique.defaultValue = UniqueFieldType.None;
		unique.icon = "";
		unique.editable = isNew;
	}

	let defaultValue = null;

	if (fieldDef.supportsDefaultValue()) {
		defaultValue = FieldDefFactory.newInstance(field._type);
		defaultValue.name = "_defaultValue";
		defaultValue.pluralName = "defaultValues";
		defaultValue.displayName = "Default Value";
		defaultValue.maxLength = 100;
		defaultValue.componentType = field._componentType;
		defaultValue.icon = "";

		if (fieldDef.isOptionType()) {
			(defaultValue as mOptionFieldDef).optionValues = (field as mOptionFieldDef)._optionValues;
		}
	}

	let currency = null;
	if (fieldDef.type === FIELD_TYPES.CURRENCY_VALUE.name) {
		currency = new mCurrencyFieldDef("_currency");
		currency.name = "_currency";
		currency.pluralName = "currencies";
		currency.displayName = "Currency";
		currency.required = true;
	}

	const basicField: FieldSettingsField[] = [name, displayName, pluralName, description];
	if (dateOnly) basicField.push(dateOnly);

	const presentationField: FieldSettingsField[] = [hidden];

	const formattingField: FieldSettingsField[] = [editable];

	if (currency) {
		formattingField.unshift(currency);
	}

	if (componentType) {
		formattingField.push(componentType);
	}
	if (defaultValue) {
		formattingField.push(defaultValue as mFieldDef);
	}
	if (options) {
		formattingField.push(options);
	}
	if (objectType) {
		formattingField.push(objectType);
	}

	const validationFields: FieldSettingsField[] = [multiplicity];
	if (formatDescription) validationFields.push(formatDescription);
	validationFields.push(tooltip);
	if (maxLength) validationFields.push(maxLength);
	if (required) validationFields.push(required);
	if (unique) validationFields.push(unique);

	return [
		{
			id: 1,
			groupName: "Basic",
			fields: basicField,
		},
		{
			id: 2,
			groupName: "Presentation",
			fields: presentationField,
		},
		{
			id: 3,
			groupName: "Formatting",
			fields: formattingField,
		},
		{
			id: 4,
			groupName: "Validation",
			fields: validationFields,
		},
	];
}

export const NULLABLE_FIELD_SETTINGS_FIELD_NAMES: string[] = [
	"_defaultValue",
	"_formatDescription",
	"_maxLength",
	"_toolTip",
	"_maxLength",
];

//TODO: Temporary fix until data model is refactored
export const EXCLUDED_SD_OBJECT_ID_OPTIONS: number[] = [
	mInteractionDef.ID,
	mInteractionShareDef.ID,
	mTenantSettingsDef.ID,
	mTenantDef.ID,
	mAssetDef.ID,
	mSalesDeskCustomerDef.ID,
];
