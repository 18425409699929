import { WebSocketMessage } from "@salesdesk/salesdesk-schemas";
import { WebSocketMessageHandler } from "./WebSocketMessageHandler";
import { createUniqueId } from "@salesdesk/salesdesk-utils";
import { getTenantFromPath } from "../../../utils";

export interface SDWebSocketOptions {
	/** Ms delay before attempting to reconnect. */
	reconnectInterval: number;
	/** Max ms to delay a reconnection attempt. */
	maxReconnectInterval: number;
	/** The rate of backing off the reconnect delay*/
	reconnectDecay: number;
}

export class SDWebSocket {
	private readonly uri: string;
	private readonly getAuthHeader: () => Promise<string>;
	private options: SDWebSocketOptions;
	private forcedClose = false;
	private reconnectAttempts = 0;
	private webSocket: WebSocket | undefined;
	private messageHandlers: WebSocketMessageHandler<any>[] = [];

	constructor(uri: string, getAuthHeader: () => Promise<string>, options: Partial<SDWebSocketOptions> = {}) {
		this.uri = uri;
		this.getAuthHeader = getAuthHeader;
		this.options = {
			reconnectInterval: 1000,
			maxReconnectInterval: 3000,
			reconnectDecay: 1.5,
			...options,
		};
	}

	private openWithReconnect = async (reconnectAttempt: boolean) => {
		console.debug("WebSocket:  Connecting...");
		if (reconnectAttempt) this.reconnectAttempts++;
		else this.reconnectAttempts = 0;

		this.webSocket = new WebSocket(
			`${this.uri}?Tenant=${getTenantFromPath()}&Authorization=${await this.getAuthHeader()}`,
			["websocket"]
		);
		const id = createUniqueId();

		this.webSocket.onopen = (event) => console.debug(`WebSocket (${id}):  Connected`);

		this.webSocket.onclose = (event) => {
			console.debug(`WebSocket (${id}):  Closed ${this.forcedClose ? "by user" : "unintentionally"}`);
			this.webSocket = undefined;
			if (this.forcedClose) return;

			console.debug(`WebSocket (${id}):  Reconnecting...`);
			const timeout = this.options.reconnectInterval * Math.pow(this.options.reconnectDecay, this.reconnectAttempts);
			setTimeout(
				() => {
					this.reconnectAttempts++;
					this.openWithReconnect(true);
				},
				Math.min(timeout, this.options.maxReconnectInterval)
			);
		};

		this.webSocket.onmessage = (event) => {
			const message = JSON.parse(event.data);
			console.debug(`WebSocket (${id}):  Received message  ${message.type}`);
			this.messageHandlers.forEach((s) => s(message));
		};

		this.webSocket.onerror = (event) => console.warn(`WebSocket  (${id}): Error`, event);
	};

	public open = async () => {
		await this.openWithReconnect(false);
	};

	public send = (message: WebSocketMessage) => {
		console.debug("WebSocket:  Sending message");
		if (this.webSocket) this.webSocket.send(JSON.stringify(message));
		else throw new Error("WebSocket not connected");
	};

	public close = () => {
		console.debug("WebSocket:  Closing...");
		this.forcedClose = true;
		if (this.webSocket) this.webSocket.close();
	};

	public reset = () => {
		console.debug("WebSocket:  Resetting...");
		if (this.webSocket) this.webSocket.close();
	};

	public subscribeToMessages = <T extends WebSocketMessage>(messageHandler: WebSocketMessageHandler<T>) =>
		this.messageHandlers.push(messageHandler);

	/** @returns unsubscribe function */
	public subscribeToMessageType = <T extends WebSocketMessage>(
		messageType: string,
		subscriber: WebSocketMessageHandler<T>
	) => {
		const typedSubscriber: WebSocketMessageHandler<T> = (message) => {
			if (message.type === messageType) subscriber(message);
		};
		this.subscribeToMessages(typedSubscriber);
		return () => this.unSubscribeFromMessages(typedSubscriber);
	};

	public unSubscribeFromMessages = <T extends WebSocketMessage>(messageHandler: WebSocketMessageHandler<T>) => {
		const indexOfSubscriber = this.messageHandlers.indexOf(messageHandler);
		if (indexOfSubscriber > -1) this.messageHandlers.splice(indexOfSubscriber, 1);
	};
}
