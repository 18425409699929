import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { isAssetObject, RecordQueryClauses, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Spinner } from "@salesdesk/daisy-ui";
import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";

import { useInfiniteScrollContainer } from "../../../hooks/ui";
import { useGetContextWorkspaceId } from "../../workspaces";
import { getRecordSearchNoResultMessage, SortingDetails, useInfiniteRecordSearch } from "../../records";

import { getAssociationSearchParams } from "../utils";
import { DirectedSDObjectAssociation, useGetAssociationFieldIds } from "../hooks";
import { RecordAssociationCard } from "./RecordAssociationCard";
import { RecordAssociationListFooter } from "./RecordAssociationListFooter";
import { NoAssociationsView } from "./NoAssociationsView";
import { LinkingModalTabs } from "./AssociationLinkingModal";
import { RecordAssociationListLoading } from "./RecordAssociationListLoading";
import { RecordAssociationOpeningVariant } from "../types";
import { AssociationActionsProvider } from "./AssociationActions";

interface RecordAssociationListProps {
	sourceRecord?: SDRecord;
	associationObject: SDObject;
	objectAssociation: DirectedSDObjectAssociation;
	onUpdateCount: (count: number) => void;
	extraFilters?: RecordQueryClauses[];
	sorting?: SortingDetails[];
	searchQuery?: string;
	onLinkAssociationClick?: (modalTab?: LinkingModalTabs) => void;
	limit?: number;
	infiniteScroll?: boolean;
	openingVariant?: RecordAssociationOpeningVariant;
}

const DEFAULT_LIMIT = 5;

export function RecordAssociationList({
	sourceRecord,
	associationObject,
	objectAssociation,
	onUpdateCount,
	extraFilters,
	sorting,
	searchQuery,
	onLinkAssociationClick,
	limit = DEFAULT_LIMIT,
	infiniteScroll = false,
	openingVariant,
}: RecordAssociationListProps) {
	const workspaceId = useGetContextWorkspaceId();
	const [hitCount, setHitCount] = useState<number>();

	const [associationRecords, setAssociationRecords] = useState<SDRecord[]>([]);
	const { updateSearchParams, resultHits, loadNextPage, isLoadingNextPage, isLoadingNewSearchParams, isLastPage } =
		useInfiniteRecordSearch({
			limit,
			sdRecords: associationRecords,
			onSDRecordsChange: setAssociationRecords,
			sdObjectFilter: associationObject._id,
		});

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			loadNextPage();
		},
	});

	const [isFiltered, setIsFiltered] = useState(false);

	const sourceRecordId = sourceRecord?._id;
	useEffect(() => {
		updateSearchParams(
			getAssociationSearchParams(
				{
					sourceRecordId,
					sortingDetails: sorting,
					searchQuery,
					orQueries: extraFilters,
					workspaceId,
				},
				objectAssociation
			)
		);
		setIsFiltered(Boolean(extraFilters?.length || searchQuery));
	}, [
		sourceRecordId,
		associationObject,
		extraFilters,
		sorting,
		searchQuery,
		updateSearchParams,
		objectAssociation,
		workspaceId,
	]);

	useEffect(() => {
		const newHitCount = resultHits?.hitCount;

		if (newHitCount !== undefined && !isFiltered && !isLoadingNewSearchParams) {
			onUpdateCount(newHitCount);
			setHitCount(newHitCount);
		}
	}, [onUpdateCount, isFiltered, isLoadingNewSearchParams, resultHits?.hitCount]);

	const displayFieldIds = useGetAssociationFieldIds(associationObject);

	let customNoResultsText = undefined;
	const showSearchMessage = Boolean(searchQuery?.length);
	const showFilterMessage = Boolean(extraFilters?.length);

	const noResults = !isLoadingNewSearchParams && !isLoadingNextPage && !hitCount;

	if (noResults && (showSearchMessage || showFilterMessage)) {
		customNoResultsText = getRecordSearchNoResultMessage(
			associationObject,
			showSearchMessage,
			showFilterMessage,
			searchQuery
		).noResultsText;
	}

	const multiplicity = objectAssociation.connectedObject.multiplicity;

	const isAssetAssociation = isAssetObject(associationObject);
	const numberOfSkeletonCards =
		multiplicity === AssociationMultiplicity.ONE ? 1 : infiniteScroll ? 10 : isAssetAssociation ? 4 : 2;

	return (
		<AssociationActionsProvider sourceRecord={sourceRecord} objectAssociation={objectAssociation}>
			<div
				className={clsx(
					"@container/association-list flex h-full flex-col",
					infiniteScroll && "pb-4",
					infiniteScroll && !isLoadingNewSearchParams && "overflow-auto"
				)}
			>
				<div className="grid-cols-cards grid items-center gap-4">
					{isLoadingNewSearchParams ? (
						<RecordAssociationListLoading
							numberOfSkeletonCards={numberOfSkeletonCards}
							isAssetAssociation={isAssetAssociation}
						/>
					) : (
						<>
							{noResults && associationObject ? (
								<div className="col-span-full">
									<NoAssociationsView
										customNoResultsText={customNoResultsText}
										onLinkAssociationClick={onLinkAssociationClick}
										associationObject={associationObject}
										multiplicity={multiplicity}
									/>
								</div>
							) : null}
							{associationRecords?.map((associationRecord) => (
								<RecordAssociationCard
									key={associationRecord._id}
									sdObject={associationObject}
									sdRecord={associationRecord}
									displayFieldIds={displayFieldIds}
									openingVariant={openingVariant}
								/>
							))}
						</>
					)}
				</div>
				{isLoadingNewSearchParams ? null : (
					<>
						{isLoadingNextPage ? (
							<div className="my-6 flex items-center justify-center">
								<Spinner size="md" />
							</div>
						) : null}
						{associationRecords.length > 0 && !infiniteScroll ? (
							<RecordAssociationListFooter
								loadNextPage={loadNextPage}
								isLastPage={isLastPage}
								associationObject={associationObject}
								objectAssociation={objectAssociation}
								sourceRecord={sourceRecord}
								openingVariant={openingVariant}
							/>
						) : infiniteScroll ? (
							<div key={associationRecords?.length ?? 0} ref={containerBottomRef} />
						) : null}
					</>
				)}
			</div>
		</AssociationActionsProvider>
	);
}
