import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mColorFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.COLOR.name];
	static defaultComponentType: FieldComponentName = mColorFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mColorFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.COLOR.icon;
		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.COLOR.name;
	}

	override get supportedComponentTypes() {
		return mColorFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value) && !this.colorIsValid(value)) {
			return `Color type "${value}" is not valid`;
		}

		return super.validate(value, isTemplate);
	}

	colorIsValid(value: any) {
		//Currently only valid for hex types.
		const longTypeValid = /^#[0-9A-F]{6}$/i.test(value); // #A02727
		const shortTypeValid = /^#([0-9A-F]{3}){1,2}$/i.test(value); // #FFF

		return longTypeValid || shortTypeValid;
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}
}
