import { createContext, useContext } from "react";
import { StickyComponentCallback } from "../types";

interface IStickyContainerContext {
	registerComponent: (id: number, height: number, callback: StickyComponentCallback) => void;
	unregisterComponent: (id: number) => void;
}

export const StickyContainerContext = createContext<IStickyContainerContext | null>(null);

const defaultRegisterComponent = () => undefined;
const defaultUnregisterComponent = () => undefined;

export const useStickyContainerContext = () => {
	const context = useContext(StickyContainerContext);

	if (context == null) {
		return {
			registerComponent: defaultRegisterComponent,
			unregisterComponent: defaultUnregisterComponent,
		};
	}

	return context;
};
