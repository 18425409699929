import { CoachingAdvice } from "@salesdesk/salesdesk-schemas";
import { ColorKey, ICONS } from "@salesdesk/salesdesk-ui";
import { tw } from "../../../../../../utils/tailwind-helpers";

export interface CoachingAdviceCategory {
	id: keyof CoachingAdvice;
	label: string;
	icon: keyof typeof ICONS;
}

export const coachingAdviceCategories: CoachingAdviceCategory[] = [
	{
		id: "activeListening",
		label: "Active listening",
		icon: "ear",
	},
	{
		id: "questioningTechniques",
		label: "Questioning techniques",
		icon: "sealQuestion",
	},
	{
		id: "handlingObjections",
		label: "Handling objections",
		icon: "hand",
	},
	{
		id: "valuePropositionCommunication",
		label: "Value proposition communication",
		icon: "coins",
	},
	{
		id: "closingTechniques",
		label: "Closing techniques",
		icon: "handshake",
	},
];

export interface CoachingAdviceRating {
	label: string;
	barColorClass: string;
	badgeColor: ColorKey;
}

export const ratings: CoachingAdviceRating[] = [
	{
		label: "Needs improving",
		barColorClass: tw`bg-c_danger_focus`,
		badgeColor: "c_danger_04",
	},
	{
		label: "Fair",
		barColorClass: tw`bg-c_action_04`,
		badgeColor: "c_bg_02",
	},
	{
		label: "Great",
		barColorClass: tw`bg-c_success_focus`,
		badgeColor: "c_success_04",
	},
];
