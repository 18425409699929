import { useSdFile } from "../../../../files/hooks/useSdFile";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { Icon } from "@salesdesk/daisy-ui";
import { FilePreview, SkeletonCompactFilePreview } from "../../../../files";
import { useFileField } from "../../../hooks/useFileField";

export interface FileFieldPreviewProps {
	fileId: number;
	objectId: number;
}

export function FileFieldPreview({ fileId, objectId }: FileFieldPreviewProps) {
	const { sdFile, isLoading } = useSdFile(fileId);
	const { sdObject } = useGetObjectById(sdFile ? objectId : undefined);
	const field = useFileField(sdObject, sdFile);

	if (!field || (!sdFile && !isLoading)) {
		return null;
	}

	return (
		<div className="flex w-full max-w-full flex-col gap-3">
			<div className="text-c_text_secondary flex items-center gap-1">
				<Icon icon={field._icon} size="sm" />
				<div className="text-label-xs truncate">{field._displayName}:</div>
			</div>
			<div className="w-full truncate">
				{isLoading || !sdFile ? <SkeletonCompactFilePreview /> : <FilePreview file={sdFile} compact />}
			</div>
		</div>
	);
}
