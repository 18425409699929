import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BoardState, DEFAULT_BOARD_STATE } from "../types";

const initialState: BoardState = DEFAULT_BOARD_STATE;

export const boardStateSlice = createSlice({
	name: "boardState",
	initialState,
	reducers: {
		setBoardState: (state, action: PayloadAction<BoardState>) => {
			return action.payload;
		},
		clearBoardState: () => {
			return DEFAULT_BOARD_STATE;
		},
	},
});

export const { setBoardState, clearBoardState } = boardStateSlice.actions;
export const boardStateReducer = boardStateSlice.reducer;
