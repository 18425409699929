import { Claim, Role } from "../../roles";
import { ClaimTypes } from "./ClaimTypes";

export const filterClaimByPredicate = (claims: Claim[], predicate: (claims: Claim) => boolean) =>
	claims.filter(predicate);

export const findClaimByPredicate = (claims: Claim[], predicate: (claims: Claim) => boolean) => claims.find(predicate);

export const findClaim = (claims: Claim[], type: ClaimTypes, value: string) =>
	findClaimByPredicate(claims, (c) => c.type === type && c.value === value);

export const hasClaimByPredicate = (claims: Claim[], predicate: (claims: Claim) => boolean) =>
	findClaimByPredicate(claims, predicate) != null;

export const hasClaim = (claims: Claim[], type: ClaimTypes, value: string) =>
	hasClaimByPredicate(claims, (c) => c.type === type && c.value === value);

export const filterRolesByClaimPredicate = (roles: Role[], predicate: (claims: Claim) => boolean) =>
	roles.filter((role) => hasClaimByPredicate(role.claims, predicate));

export const filterRolesByClaim = (roles: Role[], type: ClaimTypes, value: string) =>
	roles.filter((role) => hasClaim(role.claims, type, value));
