import { createContext, useContext } from "react";
import { UsersState } from "../types";
import { URLStateHookReturnType } from "../../../../../hooks/useURLState";

export const UsersUrlStateContext = createContext<URLStateHookReturnType<UsersState> | undefined>(undefined);

export function useUsersStateContext() {
	const context = useContext(UsersUrlStateContext);
	if (!context) {
		throw new Error("useUsersUrlStateContext must be used within UsersUrlStateContext.Provider");
	}
	return context;
}
