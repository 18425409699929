import { asConst } from "json-schema-to-ts";
import { claimSchema } from "./claimSchema";
import { roleClaimTypeSchema } from "./roleClaimTypeSchema";

export const roleClaimSchema = asConst({
	...claimSchema,
	properties: {
		...claimSchema.properties,
		type: roleClaimTypeSchema,
	},
});
