import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { useGetObjectById } from "../../../../../hooks";

export function WorkspacesErrorPage() {
	const { sdObject: workspaceObject } = useGetObjectById(mWorkspaceDef.ID);

	return (
		<>
			<h1 className="text-h3 text-c_action_01">Uh-oh!</h1>
			<p className="text-c_text_secondary">
				Your {workspaceObject?._displayName ?? "Workspace"} is playing hide and seek, and we can't find it. Try again
				later and let the fun begin! 🎉 🔍
			</p>
		</>
	);
}
