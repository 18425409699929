import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { GeneralPreviewFieldProps } from "../../../../types";
import { GeneralPreviewField } from "../../../GeneralPreviewField";

interface PreviewDurationFieldProps extends GeneralPreviewFieldProps {
	error?: boolean;
}

export function PreviewDurationField({ placeholder, error }: PreviewDurationFieldProps) {
	return (
		<GeneralPreviewField
			placeholder={placeholder}
			containedIcon={
				<Icon
					className={clsx(error ? "text-c_danger_01" : "text-c_icon_regular", "flex items-center")}
					icon={ICONS.clock}
				/>
			}
		/>
	);
}
