import { asConst } from "json-schema-to-ts";
import { objectIdSchema } from "../../object";
import { recordRelationshipTypeSchema } from "./recordRelationshipTypeSchema";
import { recordIdSchema } from "./recordIdSchema";

export const recordRelationshipSchema = asConst({
	title: "SDRecord Relationship",
	description: "A relationship to another record",
	type: "object",
	additionalProperties: false,
	properties: {
		type: recordRelationshipTypeSchema,
		objectId: {
			...objectIdSchema,
			description: "The SDObject Id of the related SDRecord",
		},
		recordId: {
			...recordIdSchema,
			description: "The SDRecord that is related to the owner of this relationship",
		},
	},
});
