import { Editor } from "@tiptap/core";
import { DefaultBubbleMenu } from "./DefaultBubbleMenu";
import { ImageBubbleMenu } from "./ImageBubbleMenu";
import { BubbleMenu } from "@tiptap/react";

interface DocumentBubbleMenuProps {
	editor: Editor;
}

export function DocumentBubbleMenu({ editor }: DocumentBubbleMenuProps) {
	return (
		<BubbleMenu
			className="bg-c_bg_01 shadow-tooltip rounded-minimal flex h-7 items-center gap-2 px-3"
			editor={editor}
			updateDelay={100}
			tippyOptions={{ duration: 150, maxWidth: "100%", zIndex: 99 }}
		>
			{editor.isActive("image") ? <ImageBubbleMenu editor={editor} /> : <DefaultBubbleMenu editor={editor} />}
		</BubbleMenu>
	);
}
