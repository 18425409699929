import { asConst } from "json-schema-to-ts";
import { importMappingEntryCreateRequestSchema } from "./importMappingEntryCreateRequestSchema";
import { booleanSchema } from "../components";

export const importJobCreateRequestSchema = asConst({
	title: "ImportJobCreateRequestSchema",
	description: "The details required to start an Import Job",
	type: "object",
	additionalProperties: false,
	required: ["dryRun", "mappingEntries"],
	properties: {
		dryRun: {
			...booleanSchema,
			description:
				"If true then does not create or update any records.  Used to check validity of the ImportJob request",
		},
		mappingEntries: { type: "array", items: importMappingEntryCreateRequestSchema },
	},
});
