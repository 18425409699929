import { SDFile, SDRecord } from "@salesdesk/salesdesk-schemas";
import { LocalParticipant, RemoteParticipant, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

declare module "twilio-video" {
	// These help to create union types between Local and Remote VideoTracks
	interface LocalVideoTrack {
		isSwitchedOff: undefined;
		setPriority: undefined;
	}
}

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export const FILE_TRACK_NAME = "file";

export interface CallParticipantDetails {
	name: string;
	avatarFileId?: number;
	participant: LocalParticipant | RemoteParticipant;
	sdRecord: SDRecord | null;
	variant: "default" | "screen_share" | "file";
	sharedFile?: FilePresentationDetails | null;
}

export interface PresentedFileSettings {
	pageNumber?: number;
}

export interface FilePresentationDetails {
	file: SDFile;
	settings?: PresentedFileSettings;
}
