import { useEffect } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { pdfjs } from "react-pdf";
import { Router } from "@remix-run/router";
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
	RouterProvider,
} from "react-router-dom";
import { UserbackProvider } from "@userback/react";
import * as Sentry from "@sentry/react";

import { ThemeProvider } from "@salesdesk/daisy-ui";

import { APP_CONFIG, sentryConst } from "../src/app/app_config";
import { store } from "./store";
import { router } from "./routes";

import "./index.scss";

declare global {
	interface Window {
		router: Router;
	}
}

window.router = router;

pdfjs.GlobalWorkerOptions.workerSrc = "/static/lib/pdf/pdf.worker.min.js";

if (sentryConst.sentryEnv === "development" || sentryConst.sentryEnv === "beta") {
	Sentry.init({
		dsn: sentryConst.dsnUI,
		release: process.env.SENTRY_RELEASE,
		environment: sentryConst.sentryEnv,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
			new Sentry.Replay(),
		],
		tracesSampleRate: 1.0,
		// Session Replay
		replaysSessionSampleRate: sentryConst.sentryEnv === "production" || sentryConst.sentryEnv === "beta" ? 0.5 : 0.1, // This sets the sample rate at 10% in case of dev/qa and 50% in case of beta /prod.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
	/* 
		TODO: Currently strict mode breaks the app as old code JS components
		don't expect to be rendered twice.
	*/
	// <StrictMode>
	<ThemeProvider>
		<UserbackProvider token={APP_CONFIG.userbackToken}>
			<Provider store={store}>
				<RouterProvider router={router} />
			</Provider>
		</UserbackProvider>
	</ThemeProvider>
	// </StrictMode>
);
