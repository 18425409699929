import { ReactNode, useMemo } from "react";

import { Tooltip } from "@salesdesk/daisy-ui";
import {
	getSDRecordSingleOptionSystemFieldValueByFieldName,
	getSDRecordSystemFieldValueByFieldName,
} from "@salesdesk/salesdesk-schemas";
import { formatDate } from "@salesdesk/salesdesk-utils";

import { BaseCompactRecordPreviewProps } from "../../types";
import { CompactRecordPreviewContainer, CompactRecordPreviewIcon } from "../common";
import { completableRecordFieldNamesMap } from "../../../../../../completableRecord/utils";

export function CompletableRecordPreview({ sdRecord, sdObject, variant }: BaseCompactRecordPreviewProps) {
	const taskDetails = useMemo(() => {
		const details = [<Detail key="object-name" text={sdObject._displayName} />];

		const { statusFieldName, dueDateFieldName } = completableRecordFieldNamesMap[sdObject._id];

		const status = getSDRecordSingleOptionSystemFieldValueByFieldName(sdObject, sdRecord, statusFieldName);
		if (status) {
			details.push(<Detail key="status" text={status} />);
		}

		const dueDate = (getSDRecordSystemFieldValueByFieldName(sdObject, sdRecord, dueDateFieldName)?._value ?? null) as
			| null
			| number;

		if (dueDate != null) {
			const formattedDate = formatDate(new Date(dueDate));
			details.push(<Detail key="dueDate" text={formattedDate} />);
		}

		return details.reduce((acc, detail, index) => {
			if (index > 0) {
				acc.push(
					<span key={`separator-${index}`} className="mx-1">
						|
					</span>
				);
			}
			acc.push(detail);
			return acc;
		}, [] as ReactNode[]);
	}, [sdRecord, sdObject]);

	return (
		<CompactRecordPreviewContainer
			icon={<CompactRecordPreviewIcon icon={sdObject._icon} />}
			sdRecord={sdRecord}
			sdObject={sdObject}
			variant={variant}
		>
			<div className="text-c_text_placeholder flex truncate">{taskDetails}</div>
		</CompactRecordPreviewContainer>
	);
}

function Detail({ text }: { text: string }) {
	return (
		<Tooltip text={text} showOnTruncated floatingPortal>
			<div className="last:truncate">{text}</div>
		</Tooltip>
	);
}
