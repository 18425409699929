import { useCallback, useState } from "react";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { PanelTabPanel } from "../../../../../../../components/PanelTabs";
import { DirectedSDObjectAssociation, RecordAssociations } from "../../../../../../recordAssociations";
import { AssociationObjectLabel } from "../../common/AssociationObjectLabel";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";

interface RecordAssociationTabPanelProps {
	associationDef: DirectedSDObjectAssociation;
	sdRecord: SDRecord;
	updateAssociationCount: (associationId: number, count: number) => void;
}

export function RecordAssociationTabPanel({
	associationDef,
	sdRecord,
	updateAssociationCount,
}: RecordAssociationTabPanelProps) {
	const workspaceId = useGetContextWorkspaceId();

	const [associationObject, setAssociationObject] = useState<SDObject | undefined>(undefined);

	const onUpdateCount = useCallback(
		(count: number) => {
			updateAssociationCount(associationDef.id, count);
		},
		[associationDef.id, updateAssociationCount]
	);

	return (
		<PanelTabPanel key={associationDef.id} unmount={false}>
			<div className="mb-4 flex w-full justify-end">
				<AssociationObjectLabel associationObject={associationObject} objectAssociation={associationDef} />
			</div>
			<RecordAssociations
				sourceRecord={sdRecord}
				onUpdateCount={onUpdateCount}
				objectAssociation={associationDef}
				onAssociationObjectLoad={setAssociationObject}
				openingVariant={workspaceId != null ? "workspace" : "recordDetailView"}
			/>
		</PanelTabPanel>
	);
}
