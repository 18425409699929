import { PropsWithChildren } from "react";
import { FloatingDelayGroup } from "@floating-ui/react";

export function TooltipDelayGroup({ children }: PropsWithChildren) {
	return (
		<FloatingDelayGroup delay={{ open: 300, close: 100 }} timeoutMs={500}>
			{children}
		</FloatingDelayGroup>
	);
}
