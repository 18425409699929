import { useEffect, useState } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDebouncedActiveState } from "../../../hooks";
import { useVideoPlayerContext } from "../hooks/useVideoPlayerContext";
import { formatVideoTimestamp } from "../utils";
import { VideoProgressBar } from "./VideoProgressBar";
import { VideoSpeedControl } from "./VideoSpeedControl";
import { VideoVolumeControl } from "./VideoVolumeControl";
import { VideoFullScreenToggle } from "./VideoFullScreenToggle";
import { JUMP_TIME_SECONDS, VideoJumpControl } from "./VideoJumpControl";
import { Icon, Button, Spinner } from "@salesdesk/daisy-ui";

export function ExpandedVideoControls() {
	const [initialLoad, setInitialLoad] = useState(true);
	const { isActive, triggerActiveState } = useDebouncedActiveState();
	const [isHoveringControls, setIsHoveringControls] = useState(false);

	const {
		variant,
		isPlaying,
		updateIsPlaying,
		currentVideoTime,
		videoDuration,
		seekToVideoTime,
		isLoading,
		errorMessage,
		onOpenPreviewClick,
		volume,
		updateVolume,
		toggleFullScreen,
		toggleMute,
	} = useVideoPlayerContext();

	useEffect(() => {
		if (isPlaying) {
			setInitialLoad(false);
		}
	}, [isPlaying]);

	const activeControls = isActive || !isPlaying || isLoading || isHoveringControls;

	if (errorMessage) {
		return (
			<div className="text-c_text_inverted text-h4 absolute left-0 top-0 flex h-full w-full items-center justify-center">
				{errorMessage}
			</div>
		);
	}

	const isExpanded = variant === "expanded";

	return (
		<div
			tabIndex={0}
			className={clsx(
				"absolute left-0 top-0 h-full w-full transition-opacity ease-out",
				activeControls ? "opacity-100" : "opacity-0"
			)}
			onMouseMove={() => {
				triggerActiveState();
			}}
			onClick={(e) => {
				e.stopPropagation();
				if (onOpenPreviewClick) {
					onOpenPreviewClick();
				}
			}}
			onKeyDown={(e) => {
				const code = e.code;
				if (code === "Space") {
					updateIsPlaying(!isPlaying);
				} else if (code === "ArrowLeft") {
					seekToVideoTime(currentVideoTime - JUMP_TIME_SECONDS);
				} else if (code === "ArrowRight") {
					seekToVideoTime(currentVideoTime + JUMP_TIME_SECONDS);
				} else if (code === "ArrowUp") {
					updateVolume(volume + 0.1);
				} else if (code === "ArrowDown") {
					updateVolume(volume - 0.1);
				} else if (code === "KeyF") {
					toggleFullScreen();
				} else if (code === "KeyM") {
					toggleMute();
				} else {
					return;
				}
				triggerActiveState();
				e.stopPropagation();
			}}
		>
			{onOpenPreviewClick ? <button className="sr-only">Open expanded view</button> : null}
			<div
				className={clsx(activeControls ? "cursor-pointer" : "cursor-none", "flex h-full items-center justify-center")}
				onMouseDown={(e) => {
					if (!isLoading) {
						e.stopPropagation();
						updateIsPlaying(!isPlaying);
					}
				}}
			>
				{isLoading ? (
					<Spinner size={isExpanded ? "lg" : "md"} darkMode />
				) : (
					<button
						onFocus={() => triggerActiveState()}
						onMouseDown={(e) => {
							e.stopPropagation();
							updateIsPlaying(!isPlaying);
						}}
						className={clsx(
							"bg-c_bg_07 text-c_text_inverted flex items-center justify-center rounded-full bg-opacity-60",
							isExpanded ? "h-20 w-20" : "h-[52px] w-[52px]"
						)}
					>
						<Icon
							icon={isPlaying ? ICONS.pause : ICONS.play}
							size={isExpanded ? "xl" : "lg"}
							className="text-c_icon_inverted flex"
						/>
					</button>
				)}
			</div>
			{initialLoad ? null : (
				<div
					className="absolute bottom-0 w-full"
					onFocus={() => triggerActiveState()}
					onKeyDown={(e) => {
						// Prevent start/stopping the video when pressing Space on any of the controls
						if (e.code === "Space") {
							e.stopPropagation();
						}
					}}
				>
					<div className="relative z-10">
						<VideoProgressBar expandedView />
					</div>
					<div
						className={clsx(
							"text-c_text_inverted bg-c_bg_07 box-content flex items-center justify-between bg-opacity-60 px-3 py-0.5",
							isExpanded ? "text-body h-8 leading-8" : "text-body-sm h-7 leading-7"
						)}
						onMouseEnter={() => setIsHoveringControls(true)}
						onMouseLeave={() => setIsHoveringControls(false)}
					>
						<div className="flex items-center gap-2">
							<Button
								startIcon={isPlaying ? ICONS.pause : ICONS.play}
								variant="text_inverted"
								size={isExpanded ? "sm" : "xs"}
								onClick={(e) => {
									updateIsPlaying(!isPlaying);
									e.stopPropagation();
								}}
							/>
							<VideoJumpControl />
							<VideoVolumeControl />
							<div>
								{formatVideoTimestamp(currentVideoTime)} / {formatVideoTimestamp(videoDuration)}
							</div>
						</div>
						<div className="flex items-center gap-2">
							<VideoSpeedControl />
							<VideoFullScreenToggle />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
