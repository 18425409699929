import { Tooltip } from "@salesdesk/daisy-ui";

import { useWebPrincipal } from "../../../../../../../../auth";
import { useHoldingPageContext } from "../../hooks";

const displayCount = 2;
const separator = ", ";

export function TitleMembers() {
	const principal = useWebPrincipal();
	const { participants } = useHoldingPageContext();

	if (participants == null) return null;

	const liveParticipants = participants.filter((participant) => participant.onCall);
	const displayNames = liveParticipants.map((participant) => participant.user.fullName);
	const isLonely = liveParticipants.filter((participant) => participant.email !== principal.Username).length === 0;

	const othersCount = displayNames.length - displayCount;

	return (
		<Tooltip text={displayNames.join(separator)} placement="top-end">
			<span>
				{isLonely
					? "No one else is on the call"
					: displayNames.length === 1
					? `${displayNames[0]} is on the call`
					: displayNames.length === 2
					? `${displayNames.join(" and ")} are on the call`
					: displayNames.length - displayCount === 1
					? `${displayNames.slice(0, displayCount).join(separator)} and 1 other are on the call`
					: `${displayNames.slice(0, displayCount).join(separator)} and ${othersCount} others are on the call`}
			</span>
		</Tooltip>
	);
}
