import { CollapsibleList } from "../../../../../../../../components/CollapsibleList";
import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";
import { PATHS } from "../../../../../../../../routes";
import { FieldIdRecordCard, getDefaultDisplayFieldIds, SkeletonRecordCard } from "../../../../../../../records";
import { useGetRecordAssociationsSummaryMap } from "../../../../../../../recordAssociations";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { RECENT_RECORDS_LIMIT } from "../../../utils";
import { useWorkspaceLibraryStateContext } from "../../../hooks/useWorkspaceLibraryStateContext";

interface RecentlyAddedListProps {
	sdObject: SDObject;
	sdRecords: SDRecord[];
	isLoading: boolean;
}

export function RecentlyAddedRecordList({ sdObject, sdRecords, isLoading }: RecentlyAddedListProps) {
	const recordIds = useMemo(() => sdRecords.map((record) => record._id), [sdRecords]);
	const { recordAssociationsSummaryMap } = useGetRecordAssociationsSummaryMap(recordIds);
	const { workspaceRecord } = useWorkspaceContext();

	const { urlState } = useWorkspaceLibraryStateContext();

	const displayFieldIds = useMemo(() => {
		return sdObject ? getDefaultDisplayFieldIds(sdObject, "WORKSPACE_LIBRARY") : [];
	}, [sdObject]);

	const getRecordLink = (recordId: number) => {
		// Clicking the currently open record closes the record panel
		const linkRecordId = urlState?.recordId === recordId ? undefined : recordId;
		return workspaceRecord
			? PATHS.WORKSPACE_LIBRARY(workspaceRecord._id, { ...urlState, recordId: linkRecordId })
			: null;
	};

	return (
		<CollapsibleList title={sdObject._pluralName}>
			{isLoading ? (
				<div className="grid-cols-cards mt-4 grid gap-x-6 gap-y-4">
					{[...Array(RECENT_RECORDS_LIMIT)].map((_, index) => (
						<div key={`skeleton-card-${index}`}>
							<SkeletonRecordCard />
						</div>
					))}
				</div>
			) : sdRecords.length === 0 ? (
				<div className="mx-auto my-8 flex flex-col items-center gap-3">
					<img src="/static/images/icons/workspace_library/empty.svg" alt="Empty library icon" />
					<div className="text-label-sm">No files yet</div>
					<div className="text-body-sm">
						Check this section for recently added {sdObject._pluralName.toLowerCase()}.
					</div>
				</div>
			) : (
				<div className="grid-cols-cards mt-4 grid gap-x-6 gap-y-4">
					{sdRecords.map((record) => (
						<FieldIdRecordCard
							key={record._id}
							vertical
							sdRecord={record}
							sdObject={sdObject}
							fieldIdsToDisplay={displayFieldIds}
							associationsSummary={recordAssociationsSummaryMap[record._id]}
							getRecordLink={getRecordLink}
						/>
					))}
				</div>
			)}
		</CollapsibleList>
	);
}
