import { ChimeSDKMessagingClient } from "@aws-sdk/client-chime-sdk-messaging";
import { useEffect, useState } from "react";
import { ConsoleLogger, DefaultMessagingSession, LogLevel, MessagingSessionConfiguration } from "amazon-chime-sdk-js";
import { v4 as uuid } from "uuid";
import { useChimeMessagingSessionEndpoint } from "./useChimeMessagingSessionEndpoint";
import { ChimeMessageSubscriber } from "@salesdesk/salesdesk-schemas";

export function useChimeMessagingSubscriber(
	appInstanceUserArn: string | undefined,
	chimeSDKMessagingClient: ChimeSDKMessagingClient,
	onError?: () => void
) {
	const [messagingService, setMessagingService] = useState<ChimeMessageSubscriber>();
	const messagingSessionEndpoint = useChimeMessagingSessionEndpoint(chimeSDKMessagingClient, onError);

	useEffect(() => {
		if (!appInstanceUserArn || !messagingSessionEndpoint) return undefined;

		try {
			const sessionConfig = new MessagingSessionConfiguration(
				appInstanceUserArn,
				uuid(),
				messagingSessionEndpoint,
				chimeSDKMessagingClient
			);

			setMessagingService(
				new ChimeMessageSubscriber(
					new DefaultMessagingSession(sessionConfig, new ConsoleLogger("DefaultMessagingSession", LogLevel.DEBUG))
				)
			);
		} catch (err) {
			console.error("Failed to create MessagingService", err);
			onError?.();
		}
	}, [appInstanceUserArn, messagingSessionEndpoint, chimeSDKMessagingClient, onError]);

	return messagingService;
}
