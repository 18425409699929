import { useEffect, useState } from "react";
import clsx from "clsx";
import { EditorContent } from "@tiptap/react";
import { JSONContent } from "@tiptap/core";

import { Button, tw } from "@salesdesk/daisy-ui";
import { getMentionExtensionForReact } from "../../../../../../mentions";
import { DisplayPlaceholderField } from "../../../DisplayPlaceholderField";
import { useSDEditor } from "../../../../../../inputs";
import { MentionOptions } from "../../../../../../mentions/types";
import { getFrontEndRichTextExtensions } from "../../../../../../../utils/getFrontEndRichTextExtensions";

interface DisplayRichTextFieldProps {
	value: JSONContent;
	placeholder?: string;
	collapsible?: boolean;
	enableMentions?: boolean | MentionOptions;
	isDarkVariant?: boolean;
}

const MAX_RTF_HEIGHT = 240;

export function DisplayRichTextDefault({
	value,
	placeholder,
	collapsible = true,
	enableMentions = false,
	isDarkVariant = false,
}: DisplayRichTextFieldProps) {
	const [isCollapsed, setIsCollapsed] = useState(false);

	const editor = useSDEditor(
		{
			extensions: [
				...getFrontEndRichTextExtensions({ placeholder, enableMentions: false }),
				...(enableMentions
					? [getMentionExtensionForReact(typeof enableMentions === "object" ? enableMentions : undefined)]
					: []),
			],
			content: value,
			editable: false,
			editorProps: { attributes: { class: tw`h-full ${isDarkVariant ? "tiptap-dark" : ""}` } },
		},
		[placeholder]
	);

	useEffect(() => {
		if (editor && (!value || !value.content?.length || !value.content)) {
			editor.commands.setContent(null);
		}

		if (editor && value && value.content?.length && value.content) {
			editor.commands.setContent(value);
		}
	}, [editor, value]);

	useEffect(() => {
		const element = editor?.options.element;

		if (!element || !collapsible) {
			setIsCollapsed(false);
			return;
		}

		setIsCollapsed(element.clientHeight > MAX_RTF_HEIGHT);
	}, [editor?.options.element, value, collapsible]);

	return (
		<div className="w-full">
			<div
				className={clsx(isCollapsed && `overflow-hidden`)}
				style={{ maxHeight: isCollapsed ? `${MAX_RTF_HEIGHT}px` : undefined }}
			>
				{value == null ? (
					<DisplayPlaceholderField />
				) : (
					<EditorContent className="h-full overflow-hidden" editor={editor} />
				)}
			</div>
			{isCollapsed ? (
				<div className="mt-2">
					<Button
						variant="primary_text"
						size="sm"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();

							setIsCollapsed(false);
						}}
						onKeyDown={(e) => e.stopPropagation()}
					>
						Show more
					</Button>
				</div>
			) : null}
		</div>
	);
}
