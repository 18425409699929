import clsx from "clsx";

interface SidePanelDividerProps {
	isOpen?: boolean;
}

export function SidePanelDivider({ isOpen }: SidePanelDividerProps) {
	return (
		<div className="my-2 ml-auto mr-4 w-5">
			<div
				className={clsx(isOpen ? "bg-c_border_regular" : "bg-c_text_secondary", "h-0.5 rounded-sm transition-colors")}
			/>
		</div>
	);
}
