import { Field } from "@salesdesk/salesdesk-schemas";
import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";

import { InputContainer, InputValidationState } from "@salesdesk/daisy-ui";
import {
	BooleanFieldVariant,
	PreviewBooleanField,
	PreviewColorPickerField,
	PreviewDateTimeField,
	PreviewDurationField,
	PreviewFileField,
	PreviewIconPickerField,
	PreviewOptionField,
	PreviewRatingField,
	PreviewRichTextField,
	PreviewTextField,
	PreviewTimeRangeField,
	PreviewProfilePhotoField,
	PreviewLogoField,
} from "../Fields";

interface PreviewFieldFactoryProps {
	field: Field;
	error?: boolean;
}

export function PreviewFieldFactory({ field, error }: PreviewFieldFactoryProps) {
	const currentValidationState = error ? InputValidationState.error : InputValidationState.initial;
	const { _displayName, _formatDescription, _componentType, _type } = field;
	const placeholder = _formatDescription || "";

	let component = null;

	switch (_componentType) {
		case FIELD_COMPONENT_TYPES.TEXT.name:
			component = <PreviewTextField placeholder={placeholder} error={error} />;
			break;
		case FIELD_COMPONENT_TYPES.RICH_TEXT.name:
			component = <PreviewRichTextField placeholder={placeholder} />;
			break;
		case FIELD_COMPONENT_TYPES.TYPEAHEAD_SINGLE_OPTION.name:
		case FIELD_COMPONENT_TYPES.TYPEAHEAD_MULTI_OPTION.name:
		case FIELD_COMPONENT_TYPES.SELECT.name:
			if (_type === "icon") {
				return <PreviewIconPickerField error={error} />;
			} else {
				component = <PreviewOptionField placeholder={placeholder} />;
			}
			break;
		case FIELD_COMPONENT_TYPES.DATE.name:
		case FIELD_COMPONENT_TYPES.DATE_TIME.name:
			component = <PreviewDateTimeField placeholder={placeholder} error={error} />;
			break;
		case FIELD_COMPONENT_TYPES.TIME_RANGE.name:
			component = <PreviewTimeRangeField dateOnly={Boolean(field._dateOnly)} error={error} />;
			break;
		case FIELD_COMPONENT_TYPES.DURATION.name:
			component = <PreviewDurationField placeholder={placeholder} error={error} />;
			break;
		case FIELD_COMPONENT_TYPES.FILE.name:
		case FIELD_COMPONENT_TYPES.PDF_FILE.name:
		case FIELD_COMPONENT_TYPES.IMAGE_FILE.name:
		case FIELD_COMPONENT_TYPES.VIDEO_FILE.name:
			component = <PreviewFileField />;
			break;
		case FIELD_COMPONENT_TYPES.PROFILE_PHOTO_FILE.name:
			return <PreviewProfilePhotoField error={error} />;
		case FIELD_COMPONENT_TYPES.LOGO.name:
			return <PreviewLogoField error={error} />;
		case FIELD_COMPONENT_TYPES.RATING.name:
			return <PreviewRatingField error={error} />;
		case FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name:
			return <PreviewBooleanField label={_displayName} variant={BooleanFieldVariant.TOGGLE_SWITCH} error={error} />;
		case FIELD_COMPONENT_TYPES.CHECKBOX.name:
			return <PreviewBooleanField label={_displayName} variant={BooleanFieldVariant.CHECKBOX} error={error} />;
		case FIELD_COMPONENT_TYPES.COLOR.name:
			return <PreviewColorPickerField error={error} />;

		default:
			component = (
				<div className="text-c_danger_02 p-2 text-center">Field is not implemented field type - '{_componentType}'</div>
			);
	}

	return <InputContainer validationState={currentValidationState}>{component}</InputContainer>;
}
