import { Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { ButtonTypeaheadSelect, SelectOption, SelectOptionId } from "../../../inputs";
import { useGetRecordOptions } from "../../../fields";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { useCallback, useMemo } from "react";
import { Placement } from "@floating-ui/react";
import { ButtonTypeaheadSelectButton } from "../../../inputs/components/Select/components/ButtonTypeaheadSelect/ButtonTypeaheadSelectButton";

interface WorkspaceFilterProps {
	value: number | undefined;
	onChange: (newValue: number | undefined) => void;
	placement?: Placement;
}

export function WorkspaceFilter({ value, onChange, placement }: WorkspaceFilterProps) {
	const values: SelectOptionId[] = useMemo(() => (value ? [value] : []), [value]);

	const { getOptions } = useGetRecordOptions({
		baseObjectId: mWorkspaceDef.ID,
		fetchingRecordOptions: true,
	});

	const cachedOptions: Record<SelectOptionId, SelectOption> = useMemo(() => ({}), []);

	const getCachedOptions = useCallback(
		async (query: string) => {
			const options = await getOptions(query);
			for (const option of options) {
				cachedOptions[option.id] = option;
			}
			return options;
		},
		[cachedOptions, getOptions]
	);

	const handleOnChange = useCallback(
		(selectedValues: SelectOptionId[]) => {
			const newValue = selectedValues.length > 0 ? Number(selectedValues[0]) : undefined;
			onChange(newValue);
		},
		[onChange]
	);

	const buttonLabel = (value ? cachedOptions[value]?.name : undefined) ?? "Workspace";

	return (
		<ButtonTypeaheadSelect
			getSelectOptions={getCachedOptions}
			value={values}
			onChange={handleOnChange}
			optionDisplayVariant="default"
			placement={placement}
			isMultiSelect={false}
		>
			<div className="max-w-40 overflow-hidden">
				<Tooltip text={value ? buttonLabel : undefined} placement="top">
					<ButtonTypeaheadSelectButton
						startIcon={ICONS.filter}
						onRemoveClick={value ? () => onChange(undefined) : undefined}
						className="h-full"
					>
						{buttonLabel}
					</ButtonTypeaheadSelectButton>
				</Tooltip>
			</div>
		</ButtonTypeaheadSelect>
	);
}
