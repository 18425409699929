import { useMemo } from "react";

import { useGetObjectsQuery } from "../../../../objects/api/objectsApi";
import { SelectOption } from "../../../../inputs";

export function useGetSDObjectOptions(excludedSDObjectIds?: number[]): SelectOption[] {
	const { data: objects, isLoading, isError } = useGetObjectsQuery();

	return useMemo(() => {
		if (isLoading || isError || !objects) {
			return [] satisfies SelectOption[];
		}

		const sdObjectOptions: SelectOption[] = [];

		objects.forEach((object) => {
			if (!excludedSDObjectIds?.includes(object._id)) {
				sdObjectOptions.push({
					id: object._id,
					name: object._displayName,
					icon: object._icon,
				});
			}
		});

		return sdObjectOptions;
	}, [objects, isError, isLoading, excludedSDObjectIds]);
}
