import { SdEventSupertype } from "./SdEventSupertype";
import { SdEventType } from "./SdEventType";

const supertypeEventMap: Map<SdEventSupertype, SdEventType[]> = new Map();
supertypeEventMap.set(SdEventSupertype.STATUS_UPDATE, [
	SdEventType.RECORD_CREATED,
	SdEventType.RECORD_OWNER_UPDATED,
	SdEventType.RECORD_DELETED,
	SdEventType.RECORD_STARTED_WATCHING,
	SdEventType.RECORD_STOPPED_WATCHING,
	SdEventType.RECORD_SET_STARRED,
	SdEventType.RECORD_SET_UNSTARRED,
	SdEventType.RECORD_SHARED_IN_WORKSPACE,
	SdEventType.RECORD_UNSHARED_IN_WORKSPACE,
	SdEventType.USER_ADDED_TO_WORKSPACE,
	SdEventType.USER_REMOVED_FROM_WORKSPACE,
	SdEventType.WORKSPACE_ENTERED,
	SdEventType.WORKSPACE_RECORD_SHARED,
	SdEventType.WORKSPACE_RECORD_UNSHARED,
	SdEventType.WORKSPACE_USER_ADDED,
	SdEventType.WORKSPACE_USER_REMOVED,
	SdEventType.LEAD_CONVERTED_TO_CONTACT,
	SdEventType.LEAD_CONVERTED_TO_OPPORTUNITY,
	SdEventType.LEAD_CONVERTED_TO_ACCOUNT,
	SdEventType.TASK_COMPLETED,
	SdEventType.TASK_REOPENED,
	SdEventType.TASK_ASSIGNED,
	SdEventType.TASK_REASSIGNED,
	SdEventType.ISSUE_CLOSED,
	SdEventType.ISSUE_REOPENED,
	SdEventType.ISSUE_ASSIGNED,
	SdEventType.ISSUE_REASSIGNED,
	SdEventType.OPPORTUNITY_WON,
	SdEventType.OPPORTUNITY_LOST,
	SdEventType.MEETING_ORGANISED,
	SdEventType.MEETING_UPDATED,
	SdEventType.IMPORT_JOB_STARTED,
	SdEventType.IMPORT_JOB_COMPLETED,
	SdEventType.IMPORT_JOB_FAILED,
]);
supertypeEventMap.set(SdEventSupertype.RECORD_VIEW, [SdEventType.RECORD_LOADED]);
supertypeEventMap.set(SdEventSupertype.FIELD_UPDATES, [SdEventType.RECORD_UPDATED]);
supertypeEventMap.set(SdEventSupertype.NOTE_UPDATE, [
	SdEventType.RECORD_NOTE_ADDED,
	SdEventType.RECORD_NOTE_UPDATED,
	SdEventType.RECORD_NOTE_DELETED,
	SdEventType.NOTE_MENTION,
	SdEventType.NOTE_REACTION_ADDED,
	SdEventType.NOTE_REACTION_REMOVED,
]);
supertypeEventMap.set(SdEventSupertype.COMMENT_UPDATE, [
	SdEventType.RECORD_COMMENT_ADDED,
	SdEventType.RECORD_COMMENT_RESPONDED,
	SdEventType.RECORD_COMMENT_UPDATED,
	SdEventType.RECORD_COMMENT_RESOLVED,
	SdEventType.RECORD_COMMENT_DELETED,
	SdEventType.COMMENT_MENTION,
	SdEventType.COMMENT_REACTION_ADDED,
	SdEventType.COMMENT_REACTION_REMOVED,
]);
supertypeEventMap.set(SdEventSupertype.RECORD_CONNECTIONS, [
	SdEventType.RECORD_ASSOCIATION_CREATED,
	SdEventType.RECORD_ASSOCIATION_DELETED,
]);
supertypeEventMap.set(SdEventSupertype.UI, [SdEventType.COPIED_TO_CLIPBOARD, SdEventType.PAGE_LOAD]);

supertypeEventMap.set(SdEventSupertype.VIDEO_CALL_UPDATES, [
	SdEventType.RECORDING_STARTED,
	SdEventType.RECORDING_STOPPED,
	SdEventType.RECORDING_ANALYSIS_COMPLETE,
	SdEventType.MEETING_STARTED,
	SdEventType.MEETING_ENDED,
	SdEventType.MEETING_ENTERED,
	SdEventType.MEETING_EXITED,
	SdEventType.CHAT_MESSAGE_SENT,
	SdEventType.CAMERA_ENABLED,
	SdEventType.CAMERA_DISABLED,
	SdEventType.MICROPHONE_ENABLED,
	SdEventType.MICROPHONE_DISABLED,
	SdEventType.FILE_PRESENTATION_STARTED,
	SdEventType.FILE_PRESENTATION_STOPPED,
	SdEventType.FILE_PRESENTATION_PAGE_CHANGED,
	SdEventType.SCREEN_SHARE_STARTED,
	SdEventType.SCREEN_SHARE_ENDED,
]);

supertypeEventMap.set(SdEventSupertype.ACCESS, [
	SdEventType.USER_CREATED,
	SdEventType.CUSTOMER_USER_CREATED,
	SdEventType.USER_REVOKED,
	SdEventType.USER_LOGGED_IN,
	SdEventType.USER_LOGGED_OUT,
]);

supertypeEventMap.set(SdEventSupertype.MEDIA_UPDATE, [
	SdEventType.FILE_UPLOADED,
	SdEventType.FILE_DELETED,
	SdEventType.FILE_DOWNLOADED,
	SdEventType.FILE_VIEWED,
	SdEventType.PAGE_CHANGED,
	SdEventType.FILE_PLAYED,
	SdEventType.FILE_PAUSED,
	SdEventType.FILE_SEEKED,
	SdEventType.FILE_ENDED,
	SdEventType.FILE_PROCESSING_STARTED,
	SdEventType.FILE_PROCESSING_ENDED,
	SdEventType.FILE_PROCESSING_FAILED,
	SdEventType.FILE_TRANSCODE_STARTED,
	SdEventType.FILE_TRANSCODE_ENDED,
	SdEventType.FILE_TRANSCRIPTION_STARTED,
	SdEventType.FILE_TRANSCRIPTION_ENDED,
	SdEventType.FILE_TRANSCRIPTION_ANALYSIS_STARTED,
	SdEventType.FILE_TRANSCRIPTION_ANALYSIS_ENDED,
	SdEventType.FILE_TRANSCRIPTION_ANALYSIS_MINUTES_EXTRACTED,
	SdEventType.FILE_COMPOSITING_STARTED,
	SdEventType.FILE_COMPOSITING_ENDED,
]);

supertypeEventMap.set(SdEventSupertype.MESSAGING, [
	SdEventType.MESSAGE_SENT,
	SdEventType.MESSAGE_UPDATED,
	SdEventType.MESSAGE_DELETED,
	SdEventType.MESSAGE_RECORD_SHARED,
	SdEventType.MESSAGE_HUDDLE_STARTED,
	SdEventType.MESSAGE_MENTION,
	SdEventType.MESSAGE_CHANNEL_CREATED,
	SdEventType.MESSAGE_CHANNEL_DELETED,
	SdEventType.MESSAGE_CHANNEL_MEMBER_CREATED,
	SdEventType.MESSAGE_CHANNEL_MEMBER_DELETED,
]);

export const eventSupertypeMap: Map<SdEventType, SdEventSupertype> = new Map();
for (const [supertype, types] of supertypeEventMap.entries()) {
	for (const type of types) {
		eventSupertypeMap.set(type, supertype);
	}
}

export const getEventSupertype = (eventType: SdEventType) => eventSupertypeMap.get(eventType);
