import { FileIconType, FileType, FileTypeDetails } from "../types";
import { SDFile, VideoFileStatus } from "@salesdesk/salesdesk-schemas";
import { formatBytes } from "@salesdesk/salesdesk-utils";
import { ThemableSvgName } from "../../graphics/ThemableSvgLoader";
import { FILE_FIELD_MIME_TYPES } from "@salesdesk/salesdesk-model";

export const PRESENTABLE_FILE_TYPES: FileType[] = ["pdf", "image"];

export const FILE_TYPE_SVG_ID: { [key in FileIconType]: ThemableSvgName } = {
	audio: "file_audio",
	default: "file_default",
	image: "file_image",
	pdf: "file_pdf",
	presentation: "file_presentation",
	spreadsheet: "file_spreadsheet",
	text: "file_text",
	video: "file_video",
	zip: "file_zip",
};

export const FILE_TYPE_DETAILS = {
	AUDIO_FILE: {
		iconId: FILE_TYPE_SVG_ID.audio,
		mimeTypes: ["audio/mpeg", "audio/mp4", "audio/vnd.wav"],
		type: "file",
	},
	PDF_FILE: {
		iconId: FILE_TYPE_SVG_ID.pdf,
		mimeTypes: FILE_FIELD_MIME_TYPES.PDF,
		type: "pdf",
	},
	MS_WORD_FILE: {
		iconId: FILE_TYPE_SVG_ID.text,
		mimeTypes: ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
		type: "file",
	},
	MS_EXCEL_FILE: {
		iconId: FILE_TYPE_SVG_ID.spreadsheet,
		mimeTypes: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
		type: "file",
	},
	MS_POWERPOINT_FILE: {
		iconId: FILE_TYPE_SVG_ID.presentation,
		mimeTypes: [
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		],
		type: "file",
	},
	IMAGE_FILE: {
		iconId: FILE_TYPE_SVG_ID.image,
		mimeTypes: FILE_FIELD_MIME_TYPES.IMAGE,
		type: "image",
	},
	ZIP_FILE: {
		iconId: FILE_TYPE_SVG_ID.zip,
		mimeTypes: ["application/zip"],
		type: "file",
	},
	VIDEO_FILE: {
		iconId: FILE_TYPE_SVG_ID.video,
		mimeTypes: FILE_FIELD_MIME_TYPES.VIDEO,
		type: "video",
		requiresProcessing: true,
	},
	TEXT_FILE: {
		iconId: FILE_TYPE_SVG_ID.text,
		mimeTypes: ["text/plain"],
		type: "file",
	},
	CSV_FILE: {
		iconId: FILE_TYPE_SVG_ID.spreadsheet,
		mimeTypes: ["text/csv"],
		type: "file",
	},
	GENERAL_FILE: {
		iconId: FILE_TYPE_SVG_ID.default,
		mimeTypes: FILE_FIELD_MIME_TYPES.GENERAL,
		type: "file",
	},
} satisfies { [key: string]: FileTypeDetails };

export const EXT_PLACEHOLDER = "???";

export function getFileExtension(file: File | SDFile) {
	if (!file || !file.name) {
		return EXT_PLACEHOLDER;
	}
	const ext = file.name.lastIndexOf(".");
	if (ext === -1) {
		return file instanceof File ? EXT_PLACEHOLDER : getExtensionFromMimeType(file.mimeType);
	}
	return file.name.slice(ext + 1) || EXT_PLACEHOLDER;
}

export function getExtensionFromMimeType(mimeType: string) {
	return mimeType.split("/")[1] ?? EXT_PLACEHOLDER;
}

export function getFormattedFileSize(file: File | SDFile) {
	if (file instanceof File) {
		return formatBytes(file.size, 0);
	}
	return formatBytes(file.sizeInBytes, 0);
}

function supportsMimeType(typeInfo: FileTypeDetails, mimeType: string) {
	return typeInfo.mimeTypes.find((type) => type === mimeType || type === "*");
}

export function convertFileToObjectURL(file: File) {
	return URL.createObjectURL(file);
}

export function getFileTypeDetails(file: File | SDFile): FileTypeDetails {
	const fileType = file instanceof File ? file.type : (file.mimeType ?? "");

	for (const key in FILE_TYPE_DETAILS) {
		const fileTypeDetails = FILE_TYPE_DETAILS[key as keyof typeof FILE_TYPE_DETAILS];

		if (supportsMimeType(fileTypeDetails, fileType)) {
			return fileTypeDetails;
		}
	}

	return FILE_TYPE_DETAILS.GENERAL_FILE;
}

export function isPresentableFile(file: File | SDFile) {
	return Boolean(PRESENTABLE_FILE_TYPES.includes(getFileTypeDetails(file).type));
}

export function getFileDisplayName(file: File | SDFile | undefined) {
	return file?.name;
}

export function hasVideoAnalysis(file: File | SDFile | null | undefined): boolean {
	if (!file || file instanceof File) {
		return false;
	}
	return file.videoStatus === VideoFileStatus.ANALYSIS_COMPLETE;
}
