import { createContext, useCallback, useContext } from "react";
import { RecordViewTopLevelTab } from "../types";

interface RecordViewTopLevelTabsContext {
	tabs: RecordViewTopLevelTab[];
	currentTab: number;
}

export const RecordViewTopLevelTabsContext = createContext<RecordViewTopLevelTabsContext>({
	currentTab: 0,
	tabs: [RecordViewTopLevelTab.recordDetails],
});

export function useRecordViewTopLevelTabsContext() {
	const context = useContext(RecordViewTopLevelTabsContext);

	const hasTab = useCallback(
		(tab: RecordViewTopLevelTab) => {
			return context.tabs.includes(tab);
		},
		[context.tabs]
	);

	return { ...context, hasTab };
}
