import clsx from "clsx";
import { forwardRef } from "react";

import { tw } from "../../../../../utils/tailwind-helpers";

const sizeClassesMap = {
	xs: "h-6 w-6",
	sm: "h-8 w-8",
	lg: "h-[38px] w-[38px]",
};

interface ColorSwatchProps {
	disabled?: boolean;
	value?: string;
	hasError?: boolean;
	size?: "xs" | "sm" | "lg";
	onClick?: (colorValue?: string) => void;
	hasBorder?: boolean;
	active?: boolean;
}

export const ColorSwatch = forwardRef<HTMLButtonElement, ColorSwatchProps>(
	({ value, disabled, hasError, size = "sm", onClick, hasBorder = false, active, ...buttonProps }, ref) => {
		const inlineStyles = disabled ? {} : { background: value };
		const disabledOrUnset = disabled || !value;

		let colorClasses = "";
		if (disabledOrUnset) {
			colorClasses = tw`bg-c_bg_disabled_01 border-c_bg_disabled_01`;
		} else if (hasError) {
			colorClasses = tw`focus-visible:ring-c_danger_focus border-c_danger_02 border-2`;
		} else {
			const borderClasses = hasBorder ? tw`border-c_border_btn hover:border-c_action_01` : tw`border-transparent`;
			colorClasses = clsx(
				tw`focus-visible:ring-c_action_focus focus-visible:border-c_bg_01 border`,
				active ? tw`ring-c_action_01 ring border-c_bg_01` : borderClasses
			);
		}

		const sizeClasses = sizeClassesMap[size];

		return (
			<button
				type="button"
				ref={ref}
				{...buttonProps}
				style={inlineStyles}
				disabled={disabled}
				className={clsx("rounded-full focus-visible:ring", colorClasses, sizeClasses, disabled && "cursor-not-allowed")}
				onClick={() => onClick && onClick(value)}
			>
				{disabledOrUnset ? <div className="bg-c_bg_01 h-0.5 rotate-45" /> : null}
			</button>
		);
	}
);
