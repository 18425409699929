import { useMemo } from "react";
import { JSONContent } from "@tiptap/core";
import clsx from "clsx";

import { getSDObjectFieldByName, getSDRecordFieldValue, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { tw } from "@salesdesk/daisy-ui";

import { useWebPrincipal } from "../../../../../../../../auth";
import { convertRichTextJSONToText } from "../../../../../../../fields";
import {
	InlineEditRecordField,
	InlineEditRecordFieldController,
} from "../../../../../../../records/components/RecordFields";

interface WorkspaceIntroductionProps {
	workspaceObject: SDObject;
	workspaceRecord: SDRecord;
}

export function WorkspaceIntroduction({ workspaceObject, workspaceRecord }: WorkspaceIntroductionProps) {
	const principal = useWebPrincipal();

	const introductionField = useMemo(() => {
		return getSDObjectFieldByName(workspaceObject, mWorkspaceDef.INTRODUCTION_FIELD_NAME);
	}, [workspaceObject]);

	const isIntroductionEmpty = useMemo(() => {
		const introductionValue = introductionField
			? getSDRecordFieldValue(workspaceRecord, introductionField._id)?._value
			: null;

		return !convertRichTextJSONToText((introductionValue ?? undefined) as JSONContent | undefined);
	}, [workspaceRecord, introductionField]);

	if (!introductionField) {
		return null;
	}

	return (
		<InlineEditRecordFieldController
			sdObject={workspaceObject}
			sdRecord={workspaceRecord}
			field={introductionField}
			wrapperClasses={tw`text-boy-lg`}
		>
			{({ inlineRecordFieldProps, editMode }) =>
				!editMode && principal.IsSalesDeskUser && isIntroductionEmpty ? (
					<p className="text-c_text_placeholder">
						Welcome your customers into this collaboration space with an opening message...
					</p>
				) : (
					<div className={clsx(editMode && "ml-4")}>
						<InlineEditRecordField {...inlineRecordFieldProps} />
					</div>
				)
			}
		</InlineEditRecordFieldController>
	);
}
