import { useParams } from "react-router-dom";

import { useGetObjectFromSlug } from "../../../../hooks";
import { ObjectBoardPageWrapper } from "../ObjectBoardPageWrapper";
import { InternalObjectBoard } from "./InternalObjectBoard";

export function InternalObjectBoardPage() {
	const { sdObjectPath: objectPathSlug } = useParams<{ sdObjectPath: string }>();
	const { sdObject, isLoading } = useGetObjectFromSlug(objectPathSlug);

	return (
		<ObjectBoardPageWrapper sdObject={sdObject} failedToLoad={objectPathSlug == null || (!sdObject && !isLoading)}>
			<InternalObjectBoard />
		</ObjectBoardPageWrapper>
	);
}
