import { ReactNode, RefObject, useEffect, useMemo } from "react";
import { getCoreRowModel, RowData, Table as TableType, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useMergeRefs } from "@floating-ui/react";
import clsx from "clsx";

import { useAppDispatch } from "../../../store/store";
import { updateIsResizing } from "../../../store/appStateSlice";
import { useInfiniteScrollContainer } from "../../../hooks/ui/useInfiniteScrollContainer";
import { useResizableTableColumns } from "../hooks/useResizableTableColumns";
import {
	ControlColumnDetails,
	RowSelectionDetails,
	TableColumn,
	TableRow,
	getRowIdFn,
	TableVariant,
	TableColumnId,
	ROW_LINK_COLUMN_ID,
} from "../types";
import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH, generateColumnDefs } from "../utils";

import { ColumnResizeTracker } from "./ColumnResizeTracker/ColumnResizeTracker";
import { TableHeader } from "./TableHeader";
import { useLinkedTablesState } from "../hooks/useLinkedTables";
import { DraggableTableRow } from "./TableRow/components";

interface TableProps<T> {
	id?: string;
	rows: TableRow<T>[];
	columns: TableColumn<T>[];
	visibleColumns?: TableColumnId[];
	rowScreenReaderName?: string;
	controlColumn?: ControlColumnDetails<T>;
	tableFooter?: ReactNode;
	estimatedRowHeight?: number;
	bottomOffset?: number;
	onBottomReached?: () => void;
	hideHeader?: boolean;
	rowSelection?: RowSelectionDetails;
	getRowId?: getRowIdFn<T>;
	outsideRef?: RefObject<HTMLDivElement>;
	defaultColumnWidthByColumnId?: Record<string, number>;
	outsideScrollContainerRef?: RefObject<HTMLDivElement>;
	containerRightPadding?: number;
	variant?: TableVariant;
	stickyHeader?: boolean;
	isPrimaryLinkedTable?: boolean;
	toggleAllRowsSelected?: (checked: boolean) => void;
	hasDragAndDrop?: boolean;
	dropIndex?: number;
	isDroppable?: boolean;
	dropPlaceholder?: ReactNode;
}

const DEFAULT_CONTAINER_RIGHT_PADDING = 24;

export function Table<T>({
	id,
	rows,
	columns,
	visibleColumns,
	rowScreenReaderName,
	controlColumn,
	tableFooter,
	estimatedRowHeight = 73,
	bottomOffset = 1200,
	onBottomReached,
	hideHeader = false,
	rowSelection,
	getRowId,
	outsideRef,
	outsideScrollContainerRef,
	defaultColumnWidthByColumnId,
	containerRightPadding = DEFAULT_CONTAINER_RIGHT_PADDING,
	variant = TableVariant.primary,
	stickyHeader = true,
	isPrimaryLinkedTable = false,
	hasDragAndDrop = false,
	dropIndex,
	isDroppable,
	dropPlaceholder,
}: TableProps<T>) {
	const hasRowSelection = Boolean(rowSelection);
	const isSingleSelect = rowSelection?.singleSelect;

	const { setHasHorizontalOverflow, registerTable, refreshKey } = useLinkedTablesState() || {};

	const columnDefs = useMemo(
		() =>
			generateColumnDefs({
				columns,
				hasRowSelection,
				isSingleSelect,
				defaultColumnWidthByColumnId,
				isPrimaryLinkedTable,
			}),
		[columns, hasRowSelection, isSingleSelect, defaultColumnWidthByColumnId, isPrimaryLinkedTable]
	);

	const dispatch = useAppDispatch();

	const {
		tableContainerRef,
		columnSizingInfo,
		onColumnSizingInfoChange,
		columnSizing,
		onColumnSizingChange,
		tableContainerWidth,
		endColumnDetails,
		updateEndColumnDetails,
	} = useResizableTableColumns(containerRightPadding, controlColumn, defaultColumnWidthByColumnId);

	const ref = useMergeRefs([tableContainerRef, outsideRef ?? null]);

	const columnVisibility = useMemo(() => {
		if (!visibleColumns) {
			return undefined;
		}

		return columns.reduce(
			(acc, column) => {
				acc[column.id] = visibleColumns.includes(column.id);
				return acc;
			},
			{} as Record<TableColumnId, boolean>
		);
	}, [columns, visibleColumns]);

	const table = useReactTable({
		data: rows,
		columns: columnDefs,
		columnResizeMode: "onChange",
		state: {
			rowSelection: rowSelection?.value ?? {},
			columnSizingInfo,
			columnSizing,
			columnVisibility,
		},
		enableRowSelection: Boolean(rowSelection),
		onRowSelectionChange: rowSelection?.onChange,
		getCoreRowModel: getCoreRowModel(),
		onColumnSizingInfoChange,
		onColumnSizingChange,
		defaultColumn: {
			minSize: MIN_COLUMN_WIDTH,
			size: DEFAULT_COLUMN_WIDTH,
		},
		getRowId,
	});

	useEffect(() => {
		dispatch(updateIsResizing(Boolean(columnSizingInfo.isResizingColumn)));
	}, [dispatch, columnSizingInfo.isResizingColumn]);

	endColumnDetails.widthOffset = tableContainerWidth
		? Math.max(tableContainerWidth - table.getTotalSize(), 0)
		: undefined;

	const isOverflowing = endColumnDetails?.widthOffset === 0;
	useEffect(() => {
		if (isPrimaryLinkedTable) {
			setHasHorizontalOverflow?.(isOverflowing);
		}
	}, [isOverflowing, setHasHorizontalOverflow, isPrimaryLinkedTable]);

	useEffect(() => {
		if (!isPrimaryLinkedTable && registerTable && id) {
			registerTable(id, table as TableType<RowData>);
		}
	}, [table, registerTable, isPrimaryLinkedTable, id]);

	const scrollContainerRef = outsideScrollContainerRef ?? tableContainerRef;

	// The virtualizer
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => estimatedRowHeight,
		overscan: 50,
	});

	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: bottomOffset,
		onBottomReached,
	});

	const { rows: tableRows } = table.getRowModel();
	const virtualRows = rowVirtualizer.getVirtualItems();
	const totalSize = rowVirtualizer.getTotalSize();

	const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
	const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

	const inSelectionMode = rowSelection?.inSelectionMode;

	return (
		<div className={clsx("flex h-full basis-full", !outsideScrollContainerRef && "overflow-hidden")}>
			<div
				ref={ref}
				className={clsx(
					!outsideScrollContainerRef && "overflow-auto",
					"relative max-h-full w-full transition-opacity duration-75 ease-in"
				)}
				style={{ paddingRight: containerRightPadding }}
			>
				<ColumnResizeTracker
					columnHeaders={table.getFlatHeaders()}
					columnSizingInfo={columnSizingInfo}
					controlColumnWidth={controlColumn?.width}
				/>
				<table className={clsx(hasDragAndDrop && "has-drag-and-drop")}>
					<TableHeader
						headerGroups={table.getHeaderGroups()}
						sticky={stickyHeader}
						isPrimaryLinkedTable={isPrimaryLinkedTable}
						refreshKey={refreshKey}
						endColumnDetails={endColumnDetails}
						updateEndColumnDetails={updateEndColumnDetails}
						hideHeader={hideHeader}
						inSelectionMode={inSelectionMode}
						variant={variant}
					/>
					<tbody className="text-body text-c_text_primary">
						{paddingTop > 0 && (
							<tr>
								{/* Cell with no size to account for row link column */}
								<td className="sr-only" />
								<td style={{ height: `${paddingTop}px` }} />
							</tr>
						)}
						{virtualRows.map((virtualRow) => {
							const row = tableRows[virtualRow.index];
							const isLastRow = virtualRow.index === tableRows.length - 1;

							return (
								<DraggableTableRow
									key={`tablerow-${row.id}`}
									// Instead of passing the row directly, we pass each row property individually since the tanstack row object reference
									// always remains stable. Otherwise the row would not re-render when the data changes due to our memoization logic.
									index={row.index}
									rowId={getRowId?.(row.original, row.index)}
									visibleCells={row.getVisibleCells()}
									originalData={row.original}
									isSelected={row.getIsSelected()}
									toggleSelected={row.toggleSelected}
									rowLinkCell={row.getValue(ROW_LINK_COLUMN_ID)}
									rowScreenReaderName={rowScreenReaderName}
									inSelectionMode={inSelectionMode}
									isDraggable={hasDragAndDrop}
									dropIndicatorPlacement={
										row.index === dropIndex ? "top" : isLastRow && row.index + 1 === dropIndex ? "bottom" : "none"
									}
									isDroppable={isDroppable}
									dropPlaceholder={dropPlaceholder}
									controlColumn={controlColumn}
									isLastRow={isLastRow}
								/>
							);
						})}
						{paddingBottom > 0 && (
							<tr>
								{/* Cell with no size to account for row link column */}
								<td className="sr-only" />
								<td style={{ height: `${paddingBottom}px` }} />
							</tr>
						)}
					</tbody>
				</table>
				<div key={rows.length} ref={containerBottomRef} />
				{tableFooter}
			</div>
		</div>
	);
}
