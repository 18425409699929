import { PropsWithChildren } from "react";

const RULES_WIDTH = 390;

export function SelectedPanel({ children }: PropsWithChildren) {
	return (
		<div
			className="border-c_border_regular rounded-minimal flex shrink-0 flex-col gap-1 border p-4"
			style={{ width: RULES_WIDTH }}
		>
			{children}
		</div>
	);
}
