import { mArrayBackingStore } from "./array_backing_store";

export class mCountriesBackingStore extends mArrayBackingStore {
	static TYPE = "countries";

	constructor() {
		super();
		this._type = mCountriesBackingStore.TYPE;
	}

	getData() {
		return mCountriesBackingStore.countries;
	}

	getSearchableFields() {
		return ["id", "name", "native"];
	}

	static countries = [
		{
			id: "AD",
			name: "Andorra",
			native: "Andorra",
			phone: "376",
			continent: "EU",
			capital: "Andorra la Vella",
			currency: "EUR",
			languages: ["ca"],
			icon: "🇦🇩",
			iconU: "U+1F1E6 U+1F1E9",
		},
		{
			id: "AE",
			name: "United Arab Emirates",
			native: "دولة الإمارات العربية المتحدة",
			phone: "971",
			continent: "AS",
			capital: "Abu Dhabi",
			currency: "AED",
			languages: ["ar"],
			icon: "🇦🇪",
			iconU: "U+1F1E6 U+1F1EA",
		},
		{
			id: "AF",
			name: "Afghanistan",
			native: "افغانستان",
			phone: "93",
			continent: "AS",
			capital: "Kabul",
			currency: "AFN",
			languages: ["ps", "uz", "tk"],
			icon: "🇦🇫",
			iconU: "U+1F1E6 U+1F1EB",
		},
		{
			id: "AG",
			name: "Antigua and Barbuda",
			native: "Antigua and Barbuda",
			phone: "1268",
			continent: "NA",
			capital: "Saint John's",
			currency: "XCD",
			languages: ["en"],
			icon: "🇦🇬",
			iconU: "U+1F1E6 U+1F1EC",
		},
		{
			id: "AI",
			name: "Anguilla",
			native: "Anguilla",
			phone: "1264",
			continent: "NA",
			capital: "The Valley",
			currency: "XCD",
			languages: ["en"],
			icon: "🇦🇮",
			iconU: "U+1F1E6 U+1F1EE",
		},
		{
			id: "AL",
			name: "Albania",
			native: "Shqipëria",
			phone: "355",
			continent: "EU",
			capital: "Tirana",
			currency: "ALL",
			languages: ["sq"],
			icon: "🇦🇱",
			iconU: "U+1F1E6 U+1F1F1",
		},
		{
			id: "AM",
			name: "Armenia",
			native: "Հայաստան",
			phone: "374",
			continent: "AS",
			capital: "Yerevan",
			currency: "AMD",
			languages: ["hy", "ru"],
			icon: "🇦🇲",
			iconU: "U+1F1E6 U+1F1F2",
		},
		{
			id: "AO",
			name: "Angola",
			native: "Angola",
			phone: "244",
			continent: "AF",
			capital: "Luanda",
			currency: "AOA",
			languages: ["pt"],
			icon: "🇦🇴",
			iconU: "U+1F1E6 U+1F1F4",
		},
		{
			id: "AQ",
			name: "Antarctica",
			native: "Antarctica",
			phone: "672",
			continent: "AN",
			capital: "",
			currency: "",
			languages: [],
			icon: "🇦🇶",
			iconU: "U+1F1E6 U+1F1F6",
		},
		{
			id: "AR",
			name: "Argentina",
			native: "Argentina",
			phone: "54",
			continent: "SA",
			capital: "Buenos Aires",
			currency: "ARS",
			languages: ["es", "gn"],
			icon: "🇦🇷",
			iconU: "U+1F1E6 U+1F1F7",
		},
		{
			id: "AS",
			name: "American Samoa",
			native: "American Samoa",
			phone: "1684",
			continent: "OC",
			capital: "Pago Pago",
			currency: "USD",
			languages: ["en", "sm"],
			icon: "🇦🇸",
			iconU: "U+1F1E6 U+1F1F8",
		},
		{
			id: "AT",
			name: "Austria",
			native: "Österreich",
			phone: "43",
			continent: "EU",
			capital: "Vienna",
			currency: "EUR",
			languages: ["de"],
			icon: "🇦🇹",
			iconU: "U+1F1E6 U+1F1F9",
		},
		{
			id: "AU",
			name: "Australia",
			native: "Australia",
			phone: "61",
			continent: "OC",
			capital: "Canberra",
			currency: "AUD",
			languages: ["en"],
			icon: "🇦🇺",
			iconU: "U+1F1E6 U+1F1FA",
		},
		{
			id: "AW",
			name: "Aruba",
			native: "Aruba",
			phone: "297",
			continent: "NA",
			capital: "Oranjestad",
			currency: "AWG",
			languages: ["nl", "pa"],
			icon: "🇦🇼",
			iconU: "U+1F1E6 U+1F1FC",
		},
		{
			id: "AX",
			name: "Åland",
			native: "Åland",
			phone: "358",
			continent: "EU",
			capital: "Mariehamn",
			currency: "EUR",
			languages: ["sv"],
			icon: "🇦🇽",
			iconU: "U+1F1E6 U+1F1FD",
		},
		{
			id: "AZ",
			name: "Azerbaijan",
			native: "Azərbaycan",
			phone: "994",
			continent: "AS",
			capital: "Baku",
			currency: "AZN",
			languages: ["az"],
			icon: "🇦🇿",
			iconU: "U+1F1E6 U+1F1FF",
		},
		{
			id: "BA",
			name: "Bosnia and Herzegovina",
			native: "Bosna i Hercegovina",
			phone: "387",
			continent: "EU",
			capital: "Sarajevo",
			currency: "BAM",
			languages: ["bs", "hr", "sr"],
			icon: "🇧🇦",
			iconU: "U+1F1E7 U+1F1E6",
		},
		{
			id: "BB",
			name: "Barbados",
			native: "Barbados",
			phone: "1246",
			continent: "NA",
			capital: "Bridgetown",
			currency: "BBD",
			languages: ["en"],
			icon: "🇧🇧",
			iconU: "U+1F1E7 U+1F1E7",
		},
		{
			id: "BD",
			name: "Bangladesh",
			native: "Bangladesh",
			phone: "880",
			continent: "AS",
			capital: "Dhaka",
			currency: "BDT",
			languages: ["bn"],
			icon: "🇧🇩",
			iconU: "U+1F1E7 U+1F1E9",
		},
		{
			id: "BE",
			name: "Belgium",
			native: "België",
			phone: "32",
			continent: "EU",
			capital: "Brussels",
			currency: "EUR",
			languages: ["nl", "fr", "de"],
			icon: "🇧🇪",
			iconU: "U+1F1E7 U+1F1EA",
		},
		{
			id: "BF",
			name: "Burkina Faso",
			native: "Burkina Faso",
			phone: "226",
			continent: "AF",
			capital: "Ouagadougou",
			currency: "XOF",
			languages: ["fr", "ff"],
			icon: "🇧🇫",
			iconU: "U+1F1E7 U+1F1EB",
		},
		{
			id: "BG",
			name: "Bulgaria",
			native: "България",
			phone: "359",
			continent: "EU",
			capital: "Sofia",
			currency: "BGN",
			languages: ["bg"],
			icon: "🇧🇬",
			iconU: "U+1F1E7 U+1F1EC",
		},
		{
			id: "BH",
			name: "Bahrain",
			native: "‏البحرين",
			phone: "973",
			continent: "AS",
			capital: "Manama",
			currency: "BHD",
			languages: ["ar"],
			icon: "🇧🇭",
			iconU: "U+1F1E7 U+1F1ED",
		},
		{
			id: "BI",
			name: "Burundi",
			native: "Burundi",
			phone: "257",
			continent: "AF",
			capital: "Bujumbura",
			currency: "BIF",
			languages: ["fr", "rn"],
			icon: "🇧🇮",
			iconU: "U+1F1E7 U+1F1EE",
		},
		{
			id: "BJ",
			name: "Benin",
			native: "Bénin",
			phone: "229",
			continent: "AF",
			capital: "Porto-Novo",
			currency: "XOF",
			languages: ["fr"],
			icon: "🇧🇯",
			iconU: "U+1F1E7 U+1F1EF",
		},
		{
			id: "BL",
			name: "Saint Barthélemy",
			native: "Saint-Barthélemy",
			phone: "590",
			continent: "NA",
			capital: "Gustavia",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇧🇱",
			iconU: "U+1F1E7 U+1F1F1",
		},
		{
			id: "BM",
			name: "Bermuda",
			native: "Bermuda",
			phone: "1441",
			continent: "NA",
			capital: "Hamilton",
			currency: "BMD",
			languages: ["en"],
			icon: "🇧🇲",
			iconU: "U+1F1E7 U+1F1F2",
		},
		{
			id: "BN",
			name: "Brunei",
			native: "Negara Brunei Darussalam",
			phone: "673",
			continent: "AS",
			capital: "Bandar Seri Begawan",
			currency: "BND",
			languages: ["ms"],
			icon: "🇧🇳",
			iconU: "U+1F1E7 U+1F1F3",
		},
		{
			id: "BO",
			name: "Bolivia",
			native: "Bolivia",
			phone: "591",
			continent: "SA",
			capital: "Sucre",
			currency: "BOB,BOV",
			languages: ["es", "ay", "qu"],
			icon: "🇧🇴",
			iconU: "U+1F1E7 U+1F1F4",
		},
		{
			id: "BQ",
			name: "Bonaire",
			native: "Bonaire",
			phone: "5997",
			continent: "NA",
			capital: "Kralendijk",
			currency: "USD",
			languages: ["nl"],
			icon: "🇧🇶",
			iconU: "U+1F1E7 U+1F1F6",
		},
		{
			id: "BR",
			name: "Brazil",
			native: "Brasil",
			phone: "55",
			continent: "SA",
			capital: "Brasília",
			currency: "BRL",
			languages: ["pt"],
			icon: "🇧🇷",
			iconU: "U+1F1E7 U+1F1F7",
		},
		{
			id: "BS",
			name: "Bahamas",
			native: "Bahamas",
			phone: "1242",
			continent: "NA",
			capital: "Nassau",
			currency: "BSD",
			languages: ["en"],
			icon: "🇧🇸",
			iconU: "U+1F1E7 U+1F1F8",
		},
		{
			id: "BT",
			name: "Bhutan",
			native: "ʼbrug-yul",
			phone: "975",
			continent: "AS",
			capital: "Thimphu",
			currency: "BTN,INR",
			languages: ["dz"],
			icon: "🇧🇹",
			iconU: "U+1F1E7 U+1F1F9",
		},
		{
			id: "BV",
			name: "Bouvet Island",
			native: "Bouvetøya",
			phone: "47",
			continent: "AN",
			capital: "",
			currency: "NOK",
			languages: ["no", "nb", "nn"],
			icon: "🇧🇻",
			iconU: "U+1F1E7 U+1F1FB",
		},
		{
			id: "BW",
			name: "Botswana",
			native: "Botswana",
			phone: "267",
			continent: "AF",
			capital: "Gaborone",
			currency: "BWP",
			languages: ["en", "tn"],
			icon: "🇧🇼",
			iconU: "U+1F1E7 U+1F1FC",
		},
		{
			id: "BY",
			name: "Belarus",
			native: "Белару́сь",
			phone: "375",
			continent: "EU",
			capital: "Minsk",
			currency: "BYN",
			languages: ["be", "ru"],
			icon: "🇧🇾",
			iconU: "U+1F1E7 U+1F1FE",
		},
		{
			id: "BZ",
			name: "Belize",
			native: "Belize",
			phone: "501",
			continent: "NA",
			capital: "Belmopan",
			currency: "BZD",
			languages: ["en", "es"],
			icon: "🇧🇿",
			iconU: "U+1F1E7 U+1F1FF",
		},
		{
			id: "CA",
			name: "Canada",
			native: "Canada",
			phone: "1",
			continent: "NA",
			capital: "Ottawa",
			currency: "CAD",
			languages: ["en", "fr"],
			icon: "🇨🇦",
			iconU: "U+1F1E8 U+1F1E6",
		},
		{
			id: "CC",
			name: "Cocos [Keeling] Islands",
			native: "Cocos (Keeling) Islands",
			phone: "61",
			continent: "AS",
			capital: "West Island",
			currency: "AUD",
			languages: ["en"],
			icon: "🇨🇨",
			iconU: "U+1F1E8 U+1F1E8",
		},
		{
			id: "CD",
			name: "Democratic Republic of the Congo",
			native: "République démocratique du Congo",
			phone: "243",
			continent: "AF",
			capital: "Kinshasa",
			currency: "CDF",
			languages: ["fr", "ln", "kg", "sw", "lu"],
			icon: "🇨🇩",
			iconU: "U+1F1E8 U+1F1E9",
		},
		{
			id: "CF",
			name: "Central African Republic",
			native: "Ködörösêse tî Bêafrîka",
			phone: "236",
			continent: "AF",
			capital: "Bangui",
			currency: "XAF",
			languages: ["fr", "sg"],
			icon: "🇨🇫",
			iconU: "U+1F1E8 U+1F1EB",
		},
		{
			id: "CG",
			name: "Republic of the Congo",
			native: "République du Congo",
			phone: "242",
			continent: "AF",
			capital: "Brazzaville",
			currency: "XAF",
			languages: ["fr", "ln"],
			icon: "🇨🇬",
			iconU: "U+1F1E8 U+1F1EC",
		},
		{
			id: "CH",
			name: "Switzerland",
			native: "Schweiz",
			phone: "41",
			continent: "EU",
			capital: "Bern",
			currency: "CHE,CHF,CHW",
			languages: ["de", "fr", "it"],
			icon: "🇨🇭",
			iconU: "U+1F1E8 U+1F1ED",
		},
		{
			id: "CI",
			name: "Ivory Coast",
			native: "Côte d'Ivoire",
			phone: "225",
			continent: "AF",
			capital: "Yamoussoukro",
			currency: "XOF",
			languages: ["fr"],
			icon: "🇨🇮",
			iconU: "U+1F1E8 U+1F1EE",
		},
		{
			id: "CK",
			name: "Cook Islands",
			native: "Cook Islands",
			phone: "682",
			continent: "OC",
			capital: "Avarua",
			currency: "NZD",
			languages: ["en"],
			icon: "🇨🇰",
			iconU: "U+1F1E8 U+1F1F0",
		},
		{
			id: "CL",
			name: "Chile",
			native: "Chile",
			phone: "56",
			continent: "SA",
			capital: "Santiago",
			currency: "CLF,CLP",
			languages: ["es"],
			icon: "🇨🇱",
			iconU: "U+1F1E8 U+1F1F1",
		},
		{
			id: "CM",
			name: "Cameroon",
			native: "Cameroon",
			phone: "237",
			continent: "AF",
			capital: "Yaoundé",
			currency: "XAF",
			languages: ["en", "fr"],
			icon: "🇨🇲",
			iconU: "U+1F1E8 U+1F1F2",
		},
		{
			id: "CN",
			name: "China",
			native: "中国",
			phone: "86",
			continent: "AS",
			capital: "Beijing",
			currency: "CNY",
			languages: ["zh"],
			icon: "🇨🇳",
			iconU: "U+1F1E8 U+1F1F3",
		},
		{
			id: "CO",
			name: "Colombia",
			native: "Colombia",
			phone: "57",
			continent: "SA",
			capital: "Bogotá",
			currency: "COP",
			languages: ["es"],
			icon: "🇨🇴",
			iconU: "U+1F1E8 U+1F1F4",
		},
		{
			id: "CR",
			name: "Costa Rica",
			native: "Costa Rica",
			phone: "506",
			continent: "NA",
			capital: "San José",
			currency: "CRC",
			languages: ["es"],
			icon: "🇨🇷",
			iconU: "U+1F1E8 U+1F1F7",
		},
		{
			id: "CU",
			name: "Cuba",
			native: "Cuba",
			phone: "53",
			continent: "NA",
			capital: "Havana",
			currency: "CUC,CUP",
			languages: ["es"],
			icon: "🇨🇺",
			iconU: "U+1F1E8 U+1F1FA",
		},
		{
			id: "CV",
			name: "Cape Verde",
			native: "Cabo Verde",
			phone: "238",
			continent: "AF",
			capital: "Praia",
			currency: "CVE",
			languages: ["pt"],
			icon: "🇨🇻",
			iconU: "U+1F1E8 U+1F1FB",
		},
		{
			id: "CW",
			name: "Curacao",
			native: "Curaçao",
			phone: "5999",
			continent: "NA",
			capital: "Willemstad",
			currency: "ANG",
			languages: ["nl", "pa", "en"],
			icon: "🇨🇼",
			iconU: "U+1F1E8 U+1F1FC",
		},
		{
			id: "CX",
			name: "Christmas Island",
			native: "Christmas Island",
			phone: "61",
			continent: "AS",
			capital: "Flying Fish Cove",
			currency: "AUD",
			languages: ["en"],
			icon: "🇨🇽",
			iconU: "U+1F1E8 U+1F1FD",
		},
		{
			id: "CY",
			name: "Cyprus",
			native: "Κύπρος",
			phone: "357",
			continent: "EU",
			capital: "Nicosia",
			currency: "EUR",
			languages: ["el", "tr", "hy"],
			icon: "🇨🇾",
			iconU: "U+1F1E8 U+1F1FE",
		},
		{
			id: "CZ",
			name: "Czech Republic",
			native: "Česká republika",
			phone: "420",
			continent: "EU",
			capital: "Prague",
			currency: "CZK",
			languages: ["cs", "sk"],
			icon: "🇨🇿",
			iconU: "U+1F1E8 U+1F1FF",
		},
		{
			id: "DE",
			name: "Germany",
			native: "Deutschland",
			phone: "49",
			continent: "EU",
			capital: "Berlin",
			currency: "EUR",
			languages: ["de"],
			icon: "🇩🇪",
			iconU: "U+1F1E9 U+1F1EA",
		},
		{
			id: "DJ",
			name: "Djibouti",
			native: "Djibouti",
			phone: "253",
			continent: "AF",
			capital: "Djibouti",
			currency: "DJF",
			languages: ["fr", "ar"],
			icon: "🇩🇯",
			iconU: "U+1F1E9 U+1F1EF",
		},
		{
			id: "DK",
			name: "Denmark",
			native: "Danmark",
			phone: "45",
			continent: "EU",
			capital: "Copenhagen",
			currency: "DKK",
			languages: ["da"],
			icon: "🇩🇰",
			iconU: "U+1F1E9 U+1F1F0",
		},
		{
			id: "DM",
			name: "Dominica",
			native: "Dominica",
			phone: "1767",
			continent: "NA",
			capital: "Roseau",
			currency: "XCD",
			languages: ["en"],
			icon: "🇩🇲",
			iconU: "U+1F1E9 U+1F1F2",
		},
		{
			id: "DO",
			name: "Dominican Republic",
			native: "República Dominicana",
			phone: "1809,1829,1849",
			continent: "NA",
			capital: "Santo Domingo",
			currency: "DOP",
			languages: ["es"],
			icon: "🇩🇴",
			iconU: "U+1F1E9 U+1F1F4",
		},
		{
			id: "DZ",
			name: "Algeria",
			native: "الجزائر",
			phone: "213",
			continent: "AF",
			capital: "Algiers",
			currency: "DZD",
			languages: ["ar"],
			icon: "🇩🇿",
			iconU: "U+1F1E9 U+1F1FF",
		},
		{
			id: "EC",
			name: "Ecuador",
			native: "Ecuador",
			phone: "593",
			continent: "SA",
			capital: "Quito",
			currency: "USD",
			languages: ["es"],
			icon: "🇪🇨",
			iconU: "U+1F1EA U+1F1E8",
		},
		{
			id: "EE",
			name: "Estonia",
			native: "Eesti",
			phone: "372",
			continent: "EU",
			capital: "Tallinn",
			currency: "EUR",
			languages: ["et"],
			icon: "🇪🇪",
			iconU: "U+1F1EA U+1F1EA",
		},
		{
			id: "EG",
			name: "Egypt",
			native: "مصر‎",
			phone: "20",
			continent: "AF",
			capital: "Cairo",
			currency: "EGP",
			languages: ["ar"],
			icon: "🇪🇬",
			iconU: "U+1F1EA U+1F1EC",
		},
		{
			id: "EH",
			name: "Western Sahara",
			native: "الصحراء الغربية",
			phone: "212",
			continent: "AF",
			capital: "El Aaiún",
			currency: "MAD,DZD,MRU",
			languages: ["es"],
			icon: "🇪🇭",
			iconU: "U+1F1EA U+1F1ED",
		},
		{
			id: "ER",
			name: "Eritrea",
			native: "ኤርትራ",
			phone: "291",
			continent: "AF",
			capital: "Asmara",
			currency: "ERN",
			languages: ["ti", "ar", "en"],
			icon: "🇪🇷",
			iconU: "U+1F1EA U+1F1F7",
		},
		{
			id: "ES",
			name: "Spain",
			native: "España",
			phone: "34",
			continent: "EU",
			capital: "Madrid",
			currency: "EUR",
			languages: ["es", "eu", "ca", "gl", "oc"],
			icon: "🇪🇸",
			iconU: "U+1F1EA U+1F1F8",
		},
		{
			id: "ET",
			name: "Ethiopia",
			native: "ኢትዮጵያ",
			phone: "251",
			continent: "AF",
			capital: "Addis Ababa",
			currency: "ETB",
			languages: ["am"],
			icon: "🇪🇹",
			iconU: "U+1F1EA U+1F1F9",
		},
		{
			id: "FI",
			name: "Finland",
			native: "Suomi",
			phone: "358",
			continent: "EU",
			capital: "Helsinki",
			currency: "EUR",
			languages: ["fi", "sv"],
			icon: "🇫🇮",
			iconU: "U+1F1EB U+1F1EE",
		},
		{
			id: "FJ",
			name: "Fiji",
			native: "Fiji",
			phone: "679",
			continent: "OC",
			capital: "Suva",
			currency: "FJD",
			languages: ["en", "fj", "hi", "ur"],
			icon: "🇫🇯",
			iconU: "U+1F1EB U+1F1EF",
		},
		{
			id: "FK",
			name: "Falkland Islands",
			native: "Falkland Islands",
			phone: "500",
			continent: "SA",
			capital: "Stanley",
			currency: "FKP",
			languages: ["en"],
			icon: "🇫🇰",
			iconU: "U+1F1EB U+1F1F0",
		},
		{
			id: "FM",
			name: "Micronesia",
			native: "Micronesia",
			phone: "691",
			continent: "OC",
			capital: "Palikir",
			currency: "USD",
			languages: ["en"],
			icon: "🇫🇲",
			iconU: "U+1F1EB U+1F1F2",
		},
		{
			id: "FO",
			name: "Faroe Islands",
			native: "Føroyar",
			phone: "298",
			continent: "EU",
			capital: "Tórshavn",
			currency: "DKK",
			languages: ["fo"],
			icon: "🇫🇴",
			iconU: "U+1F1EB U+1F1F4",
		},
		{
			id: "FR",
			name: "France",
			native: "France",
			phone: "33",
			continent: "EU",
			capital: "Paris",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇫🇷",
			iconU: "U+1F1EB U+1F1F7",
		},
		{
			id: "GA",
			name: "Gabon",
			native: "Gabon",
			phone: "241",
			continent: "AF",
			capital: "Libreville",
			currency: "XAF",
			languages: ["fr"],
			icon: "🇬🇦",
			iconU: "U+1F1EC U+1F1E6",
		},
		{
			id: "GB",
			name: "United Kingdom",
			native: "United Kingdom",
			phone: "44",
			continent: "EU",
			capital: "London",
			currency: "GBP",
			languages: ["en"],
			icon: "🇬🇧",
			iconU: "U+1F1EC U+1F1E7",
		},
		{
			id: "GD",
			name: "Grenada",
			native: "Grenada",
			phone: "1473",
			continent: "NA",
			capital: "St. George's",
			currency: "XCD",
			languages: ["en"],
			icon: "🇬🇩",
			iconU: "U+1F1EC U+1F1E9",
		},
		{
			id: "GE",
			name: "Georgia",
			native: "საქართველო",
			phone: "995",
			continent: "AS",
			capital: "Tbilisi",
			currency: "GEL",
			languages: ["ka"],
			icon: "🇬🇪",
			iconU: "U+1F1EC U+1F1EA",
		},
		{
			id: "GF",
			name: "French Guiana",
			native: "Guyane française",
			phone: "594",
			continent: "SA",
			capital: "Cayenne",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇬🇫",
			iconU: "U+1F1EC U+1F1EB",
		},
		{
			id: "GG",
			name: "Guernsey",
			native: "Guernsey",
			phone: "44",
			continent: "EU",
			capital: "St. Peter Port",
			currency: "GBP",
			languages: ["en", "fr"],
			icon: "🇬🇬",
			iconU: "U+1F1EC U+1F1EC",
		},
		{
			id: "GH",
			name: "Ghana",
			native: "Ghana",
			phone: "233",
			continent: "AF",
			capital: "Accra",
			currency: "GHS",
			languages: ["en"],
			icon: "🇬🇭",
			iconU: "U+1F1EC U+1F1ED",
		},
		{
			id: "GI",
			name: "Gibraltar",
			native: "Gibraltar",
			phone: "350",
			continent: "EU",
			capital: "Gibraltar",
			currency: "GIP",
			languages: ["en"],
			icon: "🇬🇮",
			iconU: "U+1F1EC U+1F1EE",
		},
		{
			id: "GL",
			name: "Greenland",
			native: "Kalaallit Nunaat",
			phone: "299",
			continent: "NA",
			capital: "Nuuk",
			currency: "DKK",
			languages: ["kl"],
			icon: "🇬🇱",
			iconU: "U+1F1EC U+1F1F1",
		},
		{
			id: "GM",
			name: "Gambia",
			native: "Gambia",
			phone: "220",
			continent: "AF",
			capital: "Banjul",
			currency: "GMD",
			languages: ["en"],
			icon: "🇬🇲",
			iconU: "U+1F1EC U+1F1F2",
		},
		{
			id: "GN",
			name: "Guinea",
			native: "Guinée",
			phone: "224",
			continent: "AF",
			capital: "Conakry",
			currency: "GNF",
			languages: ["fr", "ff"],
			icon: "🇬🇳",
			iconU: "U+1F1EC U+1F1F3",
		},
		{
			id: "GP",
			name: "Guadeloupe",
			native: "Guadeloupe",
			phone: "590",
			continent: "NA",
			capital: "Basse-Terre",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇬🇵",
			iconU: "U+1F1EC U+1F1F5",
		},
		{
			id: "GQ",
			name: "Equatorial Guinea",
			native: "Guinea Ecuatorial",
			phone: "240",
			continent: "AF",
			capital: "Malabo",
			currency: "XAF",
			languages: ["es", "fr"],
			icon: "🇬🇶",
			iconU: "U+1F1EC U+1F1F6",
		},
		{
			id: "GR",
			name: "Greece",
			native: "Ελλάδα",
			phone: "30",
			continent: "EU",
			capital: "Athens",
			currency: "EUR",
			languages: ["el"],
			icon: "🇬🇷",
			iconU: "U+1F1EC U+1F1F7",
		},
		{
			id: "GS",
			name: "South Georgia and the South Sandwich Islands",
			native: "South Georgia",
			phone: "500",
			continent: "AN",
			capital: "King Edward Point",
			currency: "GBP",
			languages: ["en"],
			icon: "🇬🇸",
			iconU: "U+1F1EC U+1F1F8",
		},
		{
			id: "GT",
			name: "Guatemala",
			native: "Guatemala",
			phone: "502",
			continent: "NA",
			capital: "Guatemala City",
			currency: "GTQ",
			languages: ["es"],
			icon: "🇬🇹",
			iconU: "U+1F1EC U+1F1F9",
		},
		{
			id: "GU",
			name: "Guam",
			native: "Guam",
			phone: "1671",
			continent: "OC",
			capital: "Hagåtña",
			currency: "USD",
			languages: ["en", "ch", "es"],
			icon: "🇬🇺",
			iconU: "U+1F1EC U+1F1FA",
		},
		{
			id: "GW",
			name: "Guinea-Bissau",
			native: "Guiné-Bissau",
			phone: "245",
			continent: "AF",
			capital: "Bissau",
			currency: "XOF",
			languages: ["pt"],
			icon: "🇬🇼",
			iconU: "U+1F1EC U+1F1FC",
		},
		{
			id: "GY",
			name: "Guyana",
			native: "Guyana",
			phone: "592",
			continent: "SA",
			capital: "Georgetown",
			currency: "GYD",
			languages: ["en"],
			icon: "🇬🇾",
			iconU: "U+1F1EC U+1F1FE",
		},
		{
			id: "HK",
			name: "Hong Kong",
			native: "香港",
			phone: "852",
			continent: "AS",
			capital: "City of Victoria",
			currency: "HKD",
			languages: ["zh", "en"],
			icon: "🇭🇰",
			iconU: "U+1F1ED U+1F1F0",
		},
		{
			id: "HM",
			name: "Heard Island and McDonald Islands",
			native: "Heard Island and McDonald Islands",
			phone: "61",
			continent: "AN",
			capital: "",
			currency: "AUD",
			languages: ["en"],
			icon: "🇭🇲",
			iconU: "U+1F1ED U+1F1F2",
		},
		{
			id: "HN",
			name: "Honduras",
			native: "Honduras",
			phone: "504",
			continent: "NA",
			capital: "Tegucigalpa",
			currency: "HNL",
			languages: ["es"],
			icon: "🇭🇳",
			iconU: "U+1F1ED U+1F1F3",
		},
		{
			id: "HR",
			name: "Croatia",
			native: "Hrvatska",
			phone: "385",
			continent: "EU",
			capital: "Zagreb",
			currency: "HRK",
			languages: ["hr"],
			icon: "🇭🇷",
			iconU: "U+1F1ED U+1F1F7",
		},
		{
			id: "HT",
			name: "Haiti",
			native: "Haïti",
			phone: "509",
			continent: "NA",
			capital: "Port-au-Prince",
			currency: "HTG,USD",
			languages: ["fr", "ht"],
			icon: "🇭🇹",
			iconU: "U+1F1ED U+1F1F9",
		},
		{
			id: "HU",
			name: "Hungary",
			native: "Magyarország",
			phone: "36",
			continent: "EU",
			capital: "Budapest",
			currency: "HUF",
			languages: ["hu"],
			icon: "🇭🇺",
			iconU: "U+1F1ED U+1F1FA",
		},
		{
			id: "ID",
			name: "Indonesia",
			native: "Indonesia",
			phone: "62",
			continent: "AS",
			capital: "Jakarta",
			currency: "IDR",
			languages: ["id"],
			icon: "🇮🇩",
			iconU: "U+1F1EE U+1F1E9",
		},
		{
			id: "IE",
			name: "Ireland",
			native: "Éire",
			phone: "353",
			continent: "EU",
			capital: "Dublin",
			currency: "EUR",
			languages: ["ga", "en"],
			icon: "🇮🇪",
			iconU: "U+1F1EE U+1F1EA",
		},
		{
			id: "IL",
			name: "Israel",
			native: "יִשְׂרָאֵל",
			phone: "972",
			continent: "AS",
			capital: "Jerusalem",
			currency: "ILS",
			languages: ["he", "ar"],
			icon: "🇮🇱",
			iconU: "U+1F1EE U+1F1F1",
		},
		{
			id: "IM",
			name: "Isle of Man",
			native: "Isle of Man",
			phone: "44",
			continent: "EU",
			capital: "Douglas",
			currency: "GBP",
			languages: ["en", "gv"],
			icon: "🇮🇲",
			iconU: "U+1F1EE U+1F1F2",
		},
		{
			id: "IN",
			name: "India",
			native: "भारत",
			phone: "91",
			continent: "AS",
			capital: "New Delhi",
			currency: "INR",
			languages: ["hi", "en"],
			icon: "🇮🇳",
			iconU: "U+1F1EE U+1F1F3",
		},
		{
			id: "IO",
			name: "British Indian Ocean Territory",
			native: "British Indian Ocean Territory",
			phone: "246",
			continent: "AS",
			capital: "Diego Garcia",
			currency: "USD",
			languages: ["en"],
			icon: "🇮🇴",
			iconU: "U+1F1EE U+1F1F4",
		},
		{
			id: "IQ",
			name: "Iraq",
			native: "العراق",
			phone: "964",
			continent: "AS",
			capital: "Baghdad",
			currency: "IQD",
			languages: ["ar", "ku"],
			icon: "🇮🇶",
			iconU: "U+1F1EE U+1F1F6",
		},
		{
			id: "IR",
			name: "Iran",
			native: "ایران",
			phone: "98",
			continent: "AS",
			capital: "Tehran",
			currency: "IRR",
			languages: ["fa"],
			icon: "🇮🇷",
			iconU: "U+1F1EE U+1F1F7",
		},
		{
			id: "IS",
			name: "Iceland",
			native: "Ísland",
			phone: "354",
			continent: "EU",
			capital: "Reykjavik",
			currency: "ISK",
			languages: ["is"],
			icon: "🇮🇸",
			iconU: "U+1F1EE U+1F1F8",
		},
		{
			id: "IT",
			name: "Italy",
			native: "Italia",
			phone: "39",
			continent: "EU",
			capital: "Rome",
			currency: "EUR",
			languages: ["it"],
			icon: "🇮🇹",
			iconU: "U+1F1EE U+1F1F9",
		},
		{
			id: "JE",
			name: "Jersey",
			native: "Jersey",
			phone: "44",
			continent: "EU",
			capital: "Saint Helier",
			currency: "GBP",
			languages: ["en", "fr"],
			icon: "🇯🇪",
			iconU: "U+1F1EF U+1F1EA",
		},
		{
			id: "JM",
			name: "Jamaica",
			native: "Jamaica",
			phone: "1876",
			continent: "NA",
			capital: "Kingston",
			currency: "JMD",
			languages: ["en"],
			icon: "🇯🇲",
			iconU: "U+1F1EF U+1F1F2",
		},
		{
			id: "JO",
			name: "Jordan",
			native: "الأردن",
			phone: "962",
			continent: "AS",
			capital: "Amman",
			currency: "JOD",
			languages: ["ar"],
			icon: "🇯🇴",
			iconU: "U+1F1EF U+1F1F4",
		},
		{
			id: "JP",
			name: "Japan",
			native: "日本",
			phone: "81",
			continent: "AS",
			capital: "Tokyo",
			currency: "JPY",
			languages: ["ja"],
			icon: "🇯🇵",
			iconU: "U+1F1EF U+1F1F5",
		},
		{
			id: "KE",
			name: "Kenya",
			native: "Kenya",
			phone: "254",
			continent: "AF",
			capital: "Nairobi",
			currency: "KES",
			languages: ["en", "sw"],
			icon: "🇰🇪",
			iconU: "U+1F1F0 U+1F1EA",
		},
		{
			id: "KG",
			name: "Kyrgyzstan",
			native: "Кыргызстан",
			phone: "996",
			continent: "AS",
			capital: "Bishkek",
			currency: "KGS",
			languages: ["ky", "ru"],
			icon: "🇰🇬",
			iconU: "U+1F1F0 U+1F1EC",
		},
		{
			id: "KH",
			name: "Cambodia",
			native: "Kâmpŭchéa",
			phone: "855",
			continent: "AS",
			capital: "Phnom Penh",
			currency: "KHR",
			languages: ["km"],
			icon: "🇰🇭",
			iconU: "U+1F1F0 U+1F1ED",
		},
		{
			id: "KI",
			name: "Kiribati",
			native: "Kiribati",
			phone: "686",
			continent: "OC",
			capital: "South Tarawa",
			currency: "AUD",
			languages: ["en"],
			icon: "🇰🇮",
			iconU: "U+1F1F0 U+1F1EE",
		},
		{
			id: "KM",
			name: "Comoros",
			native: "Komori",
			phone: "269",
			continent: "AF",
			capital: "Moroni",
			currency: "KMF",
			languages: ["ar", "fr"],
			icon: "🇰🇲",
			iconU: "U+1F1F0 U+1F1F2",
		},
		{
			id: "KN",
			name: "Saint Kitts and Nevis",
			native: "Saint Kitts and Nevis",
			phone: "1869",
			continent: "NA",
			capital: "Basseterre",
			currency: "XCD",
			languages: ["en"],
			icon: "🇰🇳",
			iconU: "U+1F1F0 U+1F1F3",
		},
		{
			id: "KP",
			name: "North Korea",
			native: "북한",
			phone: "850",
			continent: "AS",
			capital: "Pyongyang",
			currency: "KPW",
			languages: ["ko"],
			icon: "🇰🇵",
			iconU: "U+1F1F0 U+1F1F5",
		},
		{
			id: "KR",
			name: "South Korea",
			native: "대한민국",
			phone: "82",
			continent: "AS",
			capital: "Seoul",
			currency: "KRW",
			languages: ["ko"],
			icon: "🇰🇷",
			iconU: "U+1F1F0 U+1F1F7",
		},
		{
			id: "KW",
			name: "Kuwait",
			native: "الكويت",
			phone: "965",
			continent: "AS",
			capital: "Kuwait City",
			currency: "KWD",
			languages: ["ar"],
			icon: "🇰🇼",
			iconU: "U+1F1F0 U+1F1FC",
		},
		{
			id: "KY",
			name: "Cayman Islands",
			native: "Cayman Islands",
			phone: "1345",
			continent: "NA",
			capital: "George Town",
			currency: "KYD",
			languages: ["en"],
			icon: "🇰🇾",
			iconU: "U+1F1F0 U+1F1FE",
		},
		{
			id: "KZ",
			name: "Kazakhstan",
			native: "Қазақстан",
			phone: "76,77",
			continent: "AS",
			capital: "Astana",
			currency: "KZT",
			languages: ["kk", "ru"],
			icon: "🇰🇿",
			iconU: "U+1F1F0 U+1F1FF",
		},
		{
			id: "LA",
			name: "Laos",
			native: "ສປປລາວ",
			phone: "856",
			continent: "AS",
			capital: "Vientiane",
			currency: "LAK",
			languages: ["lo"],
			icon: "🇱🇦",
			iconU: "U+1F1F1 U+1F1E6",
		},
		{
			id: "LB",
			name: "Lebanon",
			native: "لبنان",
			phone: "961",
			continent: "AS",
			capital: "Beirut",
			currency: "LBP",
			languages: ["ar", "fr"],
			icon: "🇱🇧",
			iconU: "U+1F1F1 U+1F1E7",
		},
		{
			id: "LC",
			name: "Saint Lucia",
			native: "Saint Lucia",
			phone: "1758",
			continent: "NA",
			capital: "Castries",
			currency: "XCD",
			languages: ["en"],
			icon: "🇱🇨",
			iconU: "U+1F1F1 U+1F1E8",
		},
		{
			id: "LI",
			name: "Liechtenstein",
			native: "Liechtenstein",
			phone: "423",
			continent: "EU",
			capital: "Vaduz",
			currency: "CHF",
			languages: ["de"],
			icon: "🇱🇮",
			iconU: "U+1F1F1 U+1F1EE",
		},
		{
			id: "LK",
			name: "Sri Lanka",
			native: "śrī laṃkāva",
			phone: "94",
			continent: "AS",
			capital: "Colombo",
			currency: "LKR",
			languages: ["si", "ta"],
			icon: "🇱🇰",
			iconU: "U+1F1F1 U+1F1F0",
		},
		{
			id: "LR",
			name: "Liberia",
			native: "Liberia",
			phone: "231",
			continent: "AF",
			capital: "Monrovia",
			currency: "LRD",
			languages: ["en"],
			icon: "🇱🇷",
			iconU: "U+1F1F1 U+1F1F7",
		},
		{
			id: "LS",
			name: "Lesotho",
			native: "Lesotho",
			phone: "266",
			continent: "AF",
			capital: "Maseru",
			currency: "LSL,ZAR",
			languages: ["en", "st"],
			icon: "🇱🇸",
			iconU: "U+1F1F1 U+1F1F8",
		},
		{
			id: "LT",
			name: "Lithuania",
			native: "Lietuva",
			phone: "370",
			continent: "EU",
			capital: "Vilnius",
			currency: "EUR",
			languages: ["lt"],
			icon: "🇱🇹",
			iconU: "U+1F1F1 U+1F1F9",
		},
		{
			id: "LU",
			name: "Luxembourg",
			native: "Luxembourg",
			phone: "352",
			continent: "EU",
			capital: "Luxembourg",
			currency: "EUR",
			languages: ["fr", "de", "lb"],
			icon: "🇱🇺",
			iconU: "U+1F1F1 U+1F1FA",
		},
		{
			id: "LV",
			name: "Latvia",
			native: "Latvija",
			phone: "371",
			continent: "EU",
			capital: "Riga",
			currency: "EUR",
			languages: ["lv"],
			icon: "🇱🇻",
			iconU: "U+1F1F1 U+1F1FB",
		},
		{
			id: "LY",
			name: "Libya",
			native: "‏ليبيا",
			phone: "218",
			continent: "AF",
			capital: "Tripoli",
			currency: "LYD",
			languages: ["ar"],
			icon: "🇱🇾",
			iconU: "U+1F1F1 U+1F1FE",
		},
		{
			id: "MA",
			name: "Morocco",
			native: "المغرب",
			phone: "212",
			continent: "AF",
			capital: "Rabat",
			currency: "MAD",
			languages: ["ar"],
			icon: "🇲🇦",
			iconU: "U+1F1F2 U+1F1E6",
		},
		{
			id: "MC",
			name: "Monaco",
			native: "Monaco",
			phone: "377",
			continent: "EU",
			capital: "Monaco",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇲🇨",
			iconU: "U+1F1F2 U+1F1E8",
		},
		{
			id: "MD",
			name: "Moldova",
			native: "Moldova",
			phone: "373",
			continent: "EU",
			capital: "Chișinău",
			currency: "MDL",
			languages: ["ro"],
			icon: "🇲🇩",
			iconU: "U+1F1F2 U+1F1E9",
		},
		{
			id: "ME",
			name: "Montenegro",
			native: "Црна Гора",
			phone: "382",
			continent: "EU",
			capital: "Podgorica",
			currency: "EUR",
			languages: ["sr", "bs", "sq", "hr"],
			icon: "🇲🇪",
			iconU: "U+1F1F2 U+1F1EA",
		},
		{
			id: "MF",
			name: "Saint Martin",
			native: "Saint-Martin",
			phone: "590",
			continent: "NA",
			capital: "Marigot",
			currency: "EUR",
			languages: ["en", "fr", "nl"],
			icon: "🇲🇫",
			iconU: "U+1F1F2 U+1F1EB",
		},
		{
			id: "MG",
			name: "Madagascar",
			native: "Madagasikara",
			phone: "261",
			continent: "AF",
			capital: "Antananarivo",
			currency: "MGA",
			languages: ["fr", "mg"],
			icon: "🇲🇬",
			iconU: "U+1F1F2 U+1F1EC",
		},
		{
			id: "MH",
			name: "Marshall Islands",
			native: "M̧ajeļ",
			phone: "692",
			continent: "OC",
			capital: "Majuro",
			currency: "USD",
			languages: ["en", "mh"],
			icon: "🇲🇭",
			iconU: "U+1F1F2 U+1F1ED",
		},
		{
			id: "MK",
			name: "North Macedonia",
			native: "Северна Македонија",
			phone: "389",
			continent: "EU",
			capital: "Skopje",
			currency: "MKD",
			languages: ["mk"],
			icon: "🇲🇰",
			iconU: "U+1F1F2 U+1F1F0",
		},
		{
			id: "ML",
			name: "Mali",
			native: "Mali",
			phone: "223",
			continent: "AF",
			capital: "Bamako",
			currency: "XOF",
			languages: ["fr"],
			icon: "🇲🇱",
			iconU: "U+1F1F2 U+1F1F1",
		},
		{
			id: "MM",
			name: "Myanmar [Burma]",
			native: "မြန်မာ",
			phone: "95",
			continent: "AS",
			capital: "Naypyidaw",
			currency: "MMK",
			languages: ["my"],
			icon: "🇲🇲",
			iconU: "U+1F1F2 U+1F1F2",
		},
		{
			id: "MN",
			name: "Mongolia",
			native: "Монгол улс",
			phone: "976",
			continent: "AS",
			capital: "Ulan Bator",
			currency: "MNT",
			languages: ["mn"],
			icon: "🇲🇳",
			iconU: "U+1F1F2 U+1F1F3",
		},
		{
			id: "MO",
			name: "Macao",
			native: "澳門",
			phone: "853",
			continent: "AS",
			capital: "",
			currency: "MOP",
			languages: ["zh", "pt"],
			icon: "🇲🇴",
			iconU: "U+1F1F2 U+1F1F4",
		},
		{
			id: "MP",
			name: "Northern Mariana Islands",
			native: "Northern Mariana Islands",
			phone: "1670",
			continent: "OC",
			capital: "Saipan",
			currency: "USD",
			languages: ["en", "ch"],
			icon: "🇲🇵",
			iconU: "U+1F1F2 U+1F1F5",
		},
		{
			id: "MQ",
			name: "Martinique",
			native: "Martinique",
			phone: "596",
			continent: "NA",
			capital: "Fort-de-France",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇲🇶",
			iconU: "U+1F1F2 U+1F1F6",
		},
		{
			id: "MR",
			name: "Mauritania",
			native: "موريتانيا",
			phone: "222",
			continent: "AF",
			capital: "Nouakchott",
			currency: "MRU",
			languages: ["ar"],
			icon: "🇲🇷",
			iconU: "U+1F1F2 U+1F1F7",
		},
		{
			id: "MS",
			name: "Montserrat",
			native: "Montserrat",
			phone: "1664",
			continent: "NA",
			capital: "Plymouth",
			currency: "XCD",
			languages: ["en"],
			icon: "🇲🇸",
			iconU: "U+1F1F2 U+1F1F8",
		},
		{
			id: "MT",
			name: "Malta",
			native: "Malta",
			phone: "356",
			continent: "EU",
			capital: "Valletta",
			currency: "EUR",
			languages: ["mt", "en"],
			icon: "🇲🇹",
			iconU: "U+1F1F2 U+1F1F9",
		},
		{
			id: "MU",
			name: "Mauritius",
			native: "Maurice",
			phone: "230",
			continent: "AF",
			capital: "Port Louis",
			currency: "MUR",
			languages: ["en"],
			icon: "🇲🇺",
			iconU: "U+1F1F2 U+1F1FA",
		},
		{
			id: "MV",
			name: "Maldives",
			native: "Maldives",
			phone: "960",
			continent: "AS",
			capital: "Malé",
			currency: "MVR",
			languages: ["dv"],
			icon: "🇲🇻",
			iconU: "U+1F1F2 U+1F1FB",
		},
		{
			id: "MW",
			name: "Malawi",
			native: "Malawi",
			phone: "265",
			continent: "AF",
			capital: "Lilongwe",
			currency: "MWK",
			languages: ["en", "ny"],
			icon: "🇲🇼",
			iconU: "U+1F1F2 U+1F1FC",
		},
		{
			id: "MX",
			name: "Mexico",
			native: "México",
			phone: "52",
			continent: "NA",
			capital: "Mexico City",
			currency: "MXN",
			languages: ["es"],
			icon: "🇲🇽",
			iconU: "U+1F1F2 U+1F1FD",
		},
		{
			id: "MY",
			name: "Malaysia",
			native: "Malaysia",
			phone: "60",
			continent: "AS",
			capital: "Kuala Lumpur",
			currency: "MYR",
			languages: ["ms"],
			icon: "🇲🇾",
			iconU: "U+1F1F2 U+1F1FE",
		},
		{
			id: "MZ",
			name: "Mozambique",
			native: "Moçambique",
			phone: "258",
			continent: "AF",
			capital: "Maputo",
			currency: "MZN",
			languages: ["pt"],
			icon: "🇲🇿",
			iconU: "U+1F1F2 U+1F1FF",
		},
		{
			id: "NA",
			name: "Namibia",
			native: "Namibia",
			phone: "264",
			continent: "AF",
			capital: "Windhoek",
			currency: "NAD,ZAR",
			languages: ["en", "af"],
			icon: "🇳🇦",
			iconU: "U+1F1F3 U+1F1E6",
		},
		{
			id: "NC",
			name: "New Caledonia",
			native: "Nouvelle-Calédonie",
			phone: "687",
			continent: "OC",
			capital: "Nouméa",
			currency: "XPF",
			languages: ["fr"],
			icon: "🇳🇨",
			iconU: "U+1F1F3 U+1F1E8",
		},
		{
			id: "NE",
			name: "Niger",
			native: "Niger",
			phone: "227",
			continent: "AF",
			capital: "Niamey",
			currency: "XOF",
			languages: ["fr"],
			icon: "🇳🇪",
			iconU: "U+1F1F3 U+1F1EA",
		},
		{
			id: "NF",
			name: "Norfolk Island",
			native: "Norfolk Island",
			phone: "672",
			continent: "OC",
			capital: "Kingston",
			currency: "AUD",
			languages: ["en"],
			icon: "🇳🇫",
			iconU: "U+1F1F3 U+1F1EB",
		},
		{
			id: "NG",
			name: "Nigeria",
			native: "Nigeria",
			phone: "234",
			continent: "AF",
			capital: "Abuja",
			currency: "NGN",
			languages: ["en"],
			icon: "🇳🇬",
			iconU: "U+1F1F3 U+1F1EC",
		},
		{
			id: "NI",
			name: "Nicaragua",
			native: "Nicaragua",
			phone: "505",
			continent: "NA",
			capital: "Managua",
			currency: "NIO",
			languages: ["es"],
			icon: "🇳🇮",
			iconU: "U+1F1F3 U+1F1EE",
		},
		{
			id: "NL",
			name: "Netherlands",
			native: "Nederland",
			phone: "31",
			continent: "EU",
			capital: "Amsterdam",
			currency: "EUR",
			languages: ["nl"],
			icon: "🇳🇱",
			iconU: "U+1F1F3 U+1F1F1",
		},
		{
			id: "NO",
			name: "Norway",
			native: "Norge",
			phone: "47",
			continent: "EU",
			capital: "Oslo",
			currency: "NOK",
			languages: ["no", "nb", "nn"],
			icon: "🇳🇴",
			iconU: "U+1F1F3 U+1F1F4",
		},
		{
			id: "NP",
			name: "Nepal",
			native: "नपल",
			phone: "977",
			continent: "AS",
			capital: "Kathmandu",
			currency: "NPR",
			languages: ["ne"],
			icon: "🇳🇵",
			iconU: "U+1F1F3 U+1F1F5",
		},
		{
			id: "NR",
			name: "Nauru",
			native: "Nauru",
			phone: "674",
			continent: "OC",
			capital: "Yaren",
			currency: "AUD",
			languages: ["en", "na"],
			icon: "🇳🇷",
			iconU: "U+1F1F3 U+1F1F7",
		},
		{
			id: "NU",
			name: "Niue",
			native: "Niuē",
			phone: "683",
			continent: "OC",
			capital: "Alofi",
			currency: "NZD",
			languages: ["en"],
			icon: "🇳🇺",
			iconU: "U+1F1F3 U+1F1FA",
		},
		{
			id: "NZ",
			name: "New Zealand",
			native: "New Zealand",
			phone: "64",
			continent: "OC",
			capital: "Wellington",
			currency: "NZD",
			languages: ["en", "mi"],
			icon: "🇳🇿",
			iconU: "U+1F1F3 U+1F1FF",
		},
		{
			id: "OM",
			name: "Oman",
			native: "عمان",
			phone: "968",
			continent: "AS",
			capital: "Muscat",
			currency: "OMR",
			languages: ["ar"],
			icon: "🇴🇲",
			iconU: "U+1F1F4 U+1F1F2",
		},
		{
			id: "PA",
			name: "Panama",
			native: "Panamá",
			phone: "507",
			continent: "NA",
			capital: "Panama City",
			currency: "PAB,USD",
			languages: ["es"],
			icon: "🇵🇦",
			iconU: "U+1F1F5 U+1F1E6",
		},
		{
			id: "PE",
			name: "Peru",
			native: "Perú",
			phone: "51",
			continent: "SA",
			capital: "Lima",
			currency: "PEN",
			languages: ["es"],
			icon: "🇵🇪",
			iconU: "U+1F1F5 U+1F1EA",
		},
		{
			id: "PF",
			name: "French Polynesia",
			native: "Polynésie française",
			phone: "689",
			continent: "OC",
			capital: "Papeetē",
			currency: "XPF",
			languages: ["fr"],
			icon: "🇵🇫",
			iconU: "U+1F1F5 U+1F1EB",
		},
		{
			id: "PG",
			name: "Papua New Guinea",
			native: "Papua Niugini",
			phone: "675",
			continent: "OC",
			capital: "Port Moresby",
			currency: "PGK",
			languages: ["en"],
			icon: "🇵🇬",
			iconU: "U+1F1F5 U+1F1EC",
		},
		{
			id: "PH",
			name: "Philippines",
			native: "Pilipinas",
			phone: "63",
			continent: "AS",
			capital: "Manila",
			currency: "PHP",
			languages: ["en"],
			icon: "🇵🇭",
			iconU: "U+1F1F5 U+1F1ED",
		},
		{
			id: "PK",
			name: "Pakistan",
			native: "Pakistan",
			phone: "92",
			continent: "AS",
			capital: "Islamabad",
			currency: "PKR",
			languages: ["en", "ur"],
			icon: "🇵🇰",
			iconU: "U+1F1F5 U+1F1F0",
		},
		{
			id: "PL",
			name: "Poland",
			native: "Polska",
			phone: "48",
			continent: "EU",
			capital: "Warsaw",
			currency: "PLN",
			languages: ["pl"],
			icon: "🇵🇱",
			iconU: "U+1F1F5 U+1F1F1",
		},
		{
			id: "PM",
			name: "Saint Pierre and Miquelon",
			native: "Saint-Pierre-et-Miquelon",
			phone: "508",
			continent: "NA",
			capital: "Saint-Pierre",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇵🇲",
			iconU: "U+1F1F5 U+1F1F2",
		},
		{
			id: "PN",
			name: "Pitcairn Islands",
			native: "Pitcairn Islands",
			phone: "64",
			continent: "OC",
			capital: "Adamstown",
			currency: "NZD",
			languages: ["en"],
			icon: "🇵🇳",
			iconU: "U+1F1F5 U+1F1F3",
		},
		{
			id: "PR",
			name: "Puerto Rico",
			native: "Puerto Rico",
			phone: "1787,1939",
			continent: "NA",
			capital: "San Juan",
			currency: "USD",
			languages: ["es", "en"],
			icon: "🇵🇷",
			iconU: "U+1F1F5 U+1F1F7",
		},
		{
			id: "PS",
			name: "Palestine",
			native: "فلسطين",
			phone: "970",
			continent: "AS",
			capital: "Ramallah",
			currency: "ILS",
			languages: ["ar"],
			icon: "🇵🇸",
			iconU: "U+1F1F5 U+1F1F8",
		},
		{
			id: "PT",
			name: "Portugal",
			native: "Portugal",
			phone: "351",
			continent: "EU",
			capital: "Lisbon",
			currency: "EUR",
			languages: ["pt"],
			icon: "🇵🇹",
			iconU: "U+1F1F5 U+1F1F9",
		},
		{
			id: "PW",
			name: "Palau",
			native: "Palau",
			phone: "680",
			continent: "OC",
			capital: "Ngerulmud",
			currency: "USD",
			languages: ["en"],
			icon: "🇵🇼",
			iconU: "U+1F1F5 U+1F1FC",
		},
		{
			id: "PY",
			name: "Paraguay",
			native: "Paraguay",
			phone: "595",
			continent: "SA",
			capital: "Asunción",
			currency: "PYG",
			languages: ["es", "gn"],
			icon: "🇵🇾",
			iconU: "U+1F1F5 U+1F1FE",
		},
		{
			id: "QA",
			name: "Qatar",
			native: "قطر",
			phone: "974",
			continent: "AS",
			capital: "Doha",
			currency: "QAR",
			languages: ["ar"],
			icon: "🇶🇦",
			iconU: "U+1F1F6 U+1F1E6",
		},
		{
			id: "RE",
			name: "Réunion",
			native: "La Réunion",
			phone: "262",
			continent: "AF",
			capital: "Saint-Denis",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇷🇪",
			iconU: "U+1F1F7 U+1F1EA",
		},
		{
			id: "RO",
			name: "Romania",
			native: "România",
			phone: "40",
			continent: "EU",
			capital: "Bucharest",
			currency: "RON",
			languages: ["ro"],
			icon: "🇷🇴",
			iconU: "U+1F1F7 U+1F1F4",
		},
		{
			id: "RS",
			name: "Serbia",
			native: "Србија",
			phone: "381",
			continent: "EU",
			capital: "Belgrade",
			currency: "RSD",
			languages: ["sr"],
			icon: "🇷🇸",
			iconU: "U+1F1F7 U+1F1F8",
		},
		{
			id: "RU",
			name: "Russia",
			native: "Россия",
			phone: "7",
			continent: "EU",
			capital: "Moscow",
			currency: "RUB",
			languages: ["ru"],
			icon: "🇷🇺",
			iconU: "U+1F1F7 U+1F1FA",
		},
		{
			id: "RW",
			name: "Rwanda",
			native: "Rwanda",
			phone: "250",
			continent: "AF",
			capital: "Kigali",
			currency: "RWF",
			languages: ["rw", "en", "fr"],
			icon: "🇷🇼",
			iconU: "U+1F1F7 U+1F1FC",
		},
		{
			id: "SA",
			name: "Saudi Arabia",
			native: "العربية السعودية",
			phone: "966",
			continent: "AS",
			capital: "Riyadh",
			currency: "SAR",
			languages: ["ar"],
			icon: "🇸🇦",
			iconU: "U+1F1F8 U+1F1E6",
		},
		{
			id: "SB",
			name: "Solomon Islands",
			native: "Solomon Islands",
			phone: "677",
			continent: "OC",
			capital: "Honiara",
			currency: "SBD",
			languages: ["en"],
			icon: "🇸🇧",
			iconU: "U+1F1F8 U+1F1E7",
		},
		{
			id: "SC",
			name: "Seychelles",
			native: "Seychelles",
			phone: "248",
			continent: "AF",
			capital: "Victoria",
			currency: "SCR",
			languages: ["fr", "en"],
			icon: "🇸🇨",
			iconU: "U+1F1F8 U+1F1E8",
		},
		{
			id: "SD",
			name: "Sudan",
			native: "السودان",
			phone: "249",
			continent: "AF",
			capital: "Khartoum",
			currency: "SDG",
			languages: ["ar", "en"],
			icon: "🇸🇩",
			iconU: "U+1F1F8 U+1F1E9",
		},
		{
			id: "SE",
			name: "Sweden",
			native: "Sverige",
			phone: "46",
			continent: "EU",
			capital: "Stockholm",
			currency: "SEK",
			languages: ["sv"],
			icon: "🇸🇪",
			iconU: "U+1F1F8 U+1F1EA",
		},
		{
			id: "SG",
			name: "Singapore",
			native: "Singapore",
			phone: "65",
			continent: "AS",
			capital: "Singapore",
			currency: "SGD",
			languages: ["en", "ms", "ta", "zh"],
			icon: "🇸🇬",
			iconU: "U+1F1F8 U+1F1EC",
		},
		{
			id: "SH",
			name: "Saint Helena",
			native: "Saint Helena",
			phone: "290",
			continent: "AF",
			capital: "Jamestown",
			currency: "SHP",
			languages: ["en"],
			icon: "🇸🇭",
			iconU: "U+1F1F8 U+1F1ED",
		},
		{
			id: "SI",
			name: "Slovenia",
			native: "Slovenija",
			phone: "386",
			continent: "EU",
			capital: "Ljubljana",
			currency: "EUR",
			languages: ["sl"],
			icon: "🇸🇮",
			iconU: "U+1F1F8 U+1F1EE",
		},
		{
			id: "SJ",
			name: "Svalbard and Jan Mayen",
			native: "Svalbard og Jan Mayen",
			phone: "4779",
			continent: "EU",
			capital: "Longyearbyen",
			currency: "NOK",
			languages: ["no"],
			icon: "🇸🇯",
			iconU: "U+1F1F8 U+1F1EF",
		},
		{
			id: "SK",
			name: "Slovakia",
			native: "Slovensko",
			phone: "421",
			continent: "EU",
			capital: "Bratislava",
			currency: "EUR",
			languages: ["sk"],
			icon: "🇸🇰",
			iconU: "U+1F1F8 U+1F1F0",
		},
		{
			id: "SL",
			name: "Sierra Leone",
			native: "Sierra Leone",
			phone: "232",
			continent: "AF",
			capital: "Freetown",
			currency: "SLL",
			languages: ["en"],
			icon: "🇸🇱",
			iconU: "U+1F1F8 U+1F1F1",
		},
		{
			id: "SM",
			name: "San Marino",
			native: "San Marino",
			phone: "378",
			continent: "EU",
			capital: "City of San Marino",
			currency: "EUR",
			languages: ["it"],
			icon: "🇸🇲",
			iconU: "U+1F1F8 U+1F1F2",
		},
		{
			id: "SN",
			name: "Senegal",
			native: "Sénégal",
			phone: "221",
			continent: "AF",
			capital: "Dakar",
			currency: "XOF",
			languages: ["fr"],
			icon: "🇸🇳",
			iconU: "U+1F1F8 U+1F1F3",
		},
		{
			id: "SO",
			name: "Somalia",
			native: "Soomaaliya",
			phone: "252",
			continent: "AF",
			capital: "Mogadishu",
			currency: "SOS",
			languages: ["so", "ar"],
			icon: "🇸🇴",
			iconU: "U+1F1F8 U+1F1F4",
		},
		{
			id: "SR",
			name: "Suriname",
			native: "Suriname",
			phone: "597",
			continent: "SA",
			capital: "Paramaribo",
			currency: "SRD",
			languages: ["nl"],
			icon: "🇸🇷",
			iconU: "U+1F1F8 U+1F1F7",
		},
		{
			id: "SS",
			name: "South Sudan",
			native: "South Sudan",
			phone: "211",
			continent: "AF",
			capital: "Juba",
			currency: "SSP",
			languages: ["en"],
			icon: "🇸🇸",
			iconU: "U+1F1F8 U+1F1F8",
		},
		{
			id: "ST",
			name: "São Tomé and Príncipe",
			native: "São Tomé e Príncipe",
			phone: "239",
			continent: "AF",
			capital: "São Tomé",
			currency: "STN",
			languages: ["pt"],
			icon: "🇸🇹",
			iconU: "U+1F1F8 U+1F1F9",
		},
		{
			id: "SV",
			name: "El Salvador",
			native: "El Salvador",
			phone: "503",
			continent: "NA",
			capital: "San Salvador",
			currency: "SVC,USD",
			languages: ["es"],
			icon: "🇸🇻",
			iconU: "U+1F1F8 U+1F1FB",
		},
		{
			id: "SX",
			name: "Sint Maarten",
			native: "Sint Maarten",
			phone: "1721",
			continent: "NA",
			capital: "Philipsburg",
			currency: "ANG",
			languages: ["nl", "en"],
			icon: "🇸🇽",
			iconU: "U+1F1F8 U+1F1FD",
		},
		{
			id: "SY",
			name: "Syria",
			native: "سوريا",
			phone: "963",
			continent: "AS",
			capital: "Damascus",
			currency: "SYP",
			languages: ["ar"],
			icon: "🇸🇾",
			iconU: "U+1F1F8 U+1F1FE",
		},
		{
			id: "SZ",
			name: "Swaziland",
			native: "Swaziland",
			phone: "268",
			continent: "AF",
			capital: "Lobamba",
			currency: "SZL",
			languages: ["en", "ss"],
			icon: "🇸🇿",
			iconU: "U+1F1F8 U+1F1FF",
		},
		{
			id: "TC",
			name: "Turks and Caicos Islands",
			native: "Turks and Caicos Islands",
			phone: "1649",
			continent: "NA",
			capital: "Cockburn Town",
			currency: "USD",
			languages: ["en"],
			icon: "🇹🇨",
			iconU: "U+1F1F9 U+1F1E8",
		},
		{
			id: "TD",
			name: "Chad",
			native: "Tchad",
			phone: "235",
			continent: "AF",
			capital: "N'Djamena",
			currency: "XAF",
			languages: ["fr", "ar"],
			icon: "🇹🇩",
			iconU: "U+1F1F9 U+1F1E9",
		},
		{
			id: "TF",
			name: "French Southern Territories",
			native: "Territoire des Terres australes et antarctiques fr",
			phone: "262",
			continent: "AN",
			capital: "Port-aux-Français",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇹🇫",
			iconU: "U+1F1F9 U+1F1EB",
		},
		{
			id: "TG",
			name: "Togo",
			native: "Togo",
			phone: "228",
			continent: "AF",
			capital: "Lomé",
			currency: "XOF",
			languages: ["fr"],
			icon: "🇹🇬",
			iconU: "U+1F1F9 U+1F1EC",
		},
		{
			id: "TH",
			name: "Thailand",
			native: "ประเทศไทย",
			phone: "66",
			continent: "AS",
			capital: "Bangkok",
			currency: "THB",
			languages: ["th"],
			icon: "🇹🇭",
			iconU: "U+1F1F9 U+1F1ED",
		},
		{
			id: "TJ",
			name: "Tajikistan",
			native: "Тоҷикистон",
			phone: "992",
			continent: "AS",
			capital: "Dushanbe",
			currency: "TJS",
			languages: ["tg", "ru"],
			icon: "🇹🇯",
			iconU: "U+1F1F9 U+1F1EF",
		},
		{
			id: "TK",
			name: "Tokelau",
			native: "Tokelau",
			phone: "690",
			continent: "OC",
			capital: "Fakaofo",
			currency: "NZD",
			languages: ["en"],
			icon: "🇹🇰",
			iconU: "U+1F1F9 U+1F1F0",
		},
		{
			id: "TL",
			name: "East Timor",
			native: "Timor-Leste",
			phone: "670",
			continent: "OC",
			capital: "Dili",
			currency: "USD",
			languages: ["pt"],
			icon: "🇹🇱",
			iconU: "U+1F1F9 U+1F1F1",
		},
		{
			id: "TM",
			name: "Turkmenistan",
			native: "Türkmenistan",
			phone: "993",
			continent: "AS",
			capital: "Ashgabat",
			currency: "TMT",
			languages: ["tk", "ru"],
			icon: "🇹🇲",
			iconU: "U+1F1F9 U+1F1F2",
		},
		{
			id: "TN",
			name: "Tunisia",
			native: "تونس",
			phone: "216",
			continent: "AF",
			capital: "Tunis",
			currency: "TND",
			languages: ["ar"],
			icon: "🇹🇳",
			iconU: "U+1F1F9 U+1F1F3",
		},
		{
			id: "TO",
			name: "Tonga",
			native: "Tonga",
			phone: "676",
			continent: "OC",
			capital: "Nuku'alofa",
			currency: "TOP",
			languages: ["en", "to"],
			icon: "🇹🇴",
			iconU: "U+1F1F9 U+1F1F4",
		},
		{
			id: "TR",
			name: "Turkey",
			native: "Türkiye",
			phone: "90",
			continent: "AS",
			capital: "Ankara",
			currency: "TRY",
			languages: ["tr"],
			icon: "🇹🇷",
			iconU: "U+1F1F9 U+1F1F7",
		},
		{
			id: "TT",
			name: "Trinidad and Tobago",
			native: "Trinidad and Tobago",
			phone: "1868",
			continent: "NA",
			capital: "Port of Spain",
			currency: "TTD",
			languages: ["en"],
			icon: "🇹🇹",
			iconU: "U+1F1F9 U+1F1F9",
		},
		{
			id: "TV",
			name: "Tuvalu",
			native: "Tuvalu",
			phone: "688",
			continent: "OC",
			capital: "Funafuti",
			currency: "AUD",
			languages: ["en"],
			icon: "🇹🇻",
			iconU: "U+1F1F9 U+1F1FB",
		},
		{
			id: "TW",
			name: "Taiwan",
			native: "臺灣",
			phone: "886",
			continent: "AS",
			capital: "Taipei",
			currency: "TWD",
			languages: ["zh"],
			icon: "🇹🇼",
			iconU: "U+1F1F9 U+1F1FC",
		},
		{
			id: "TZ",
			name: "Tanzania",
			native: "Tanzania",
			phone: "255",
			continent: "AF",
			capital: "Dodoma",
			currency: "TZS",
			languages: ["sw", "en"],
			icon: "🇹🇿",
			iconU: "U+1F1F9 U+1F1FF",
		},
		{
			id: "UA",
			name: "Ukraine",
			native: "Україна",
			phone: "380",
			continent: "EU",
			capital: "Kyiv",
			currency: "UAH",
			languages: ["uk"],
			icon: "🇺🇦",
			iconU: "U+1F1FA U+1F1E6",
		},
		{
			id: "UG",
			name: "Uganda",
			native: "Uganda",
			phone: "256",
			continent: "AF",
			capital: "Kampala",
			currency: "UGX",
			languages: ["en", "sw"],
			icon: "🇺🇬",
			iconU: "U+1F1FA U+1F1EC",
		},
		{
			id: "UM",
			name: "U.S. Minor Outlying Islands",
			native: "United States Minor Outlying Islands",
			phone: "1",
			continent: "OC",
			capital: "",
			currency: "USD",
			languages: ["en"],
			icon: "🇺🇲",
			iconU: "U+1F1FA U+1F1F2",
		},
		{
			id: "US",
			name: "United States",
			native: "United States",
			phone: "1",
			continent: "NA",
			capital: "Washington D.C.",
			currency: "USD,USN,USS",
			languages: ["en"],
			icon: "🇺🇸",
			iconU: "U+1F1FA U+1F1F8",
		},
		{
			id: "UY",
			name: "Uruguay",
			native: "Uruguay",
			phone: "598",
			continent: "SA",
			capital: "Montevideo",
			currency: "UYI,UYU",
			languages: ["es"],
			icon: "🇺🇾",
			iconU: "U+1F1FA U+1F1FE",
		},
		{
			id: "UZ",
			name: "Uzbekistan",
			native: "O‘zbekiston",
			phone: "998",
			continent: "AS",
			capital: "Tashkent",
			currency: "UZS",
			languages: ["uz", "ru"],
			icon: "🇺🇿",
			iconU: "U+1F1FA U+1F1FF",
		},
		{
			id: "VA",
			name: "Vatican City",
			native: "Vaticano",
			phone: "379",
			continent: "EU",
			capital: "Vatican City",
			currency: "EUR",
			languages: ["it", "la"],
			icon: "🇻🇦",
			iconU: "U+1F1FB U+1F1E6",
		},
		{
			id: "VC",
			name: "Saint Vincent and the Grenadines",
			native: "Saint Vincent and the Grenadines",
			phone: "1784",
			continent: "NA",
			capital: "Kingstown",
			currency: "XCD",
			languages: ["en"],
			icon: "🇻🇨",
			iconU: "U+1F1FB U+1F1E8",
		},
		{
			id: "VE",
			name: "Venezuela",
			native: "Venezuela",
			phone: "58",
			continent: "SA",
			capital: "Caracas",
			currency: "VES",
			languages: ["es"],
			icon: "🇻🇪",
			iconU: "U+1F1FB U+1F1EA",
		},
		{
			id: "VG",
			name: "British Virgin Islands",
			native: "British Virgin Islands",
			phone: "1284",
			continent: "NA",
			capital: "Road Town",
			currency: "USD",
			languages: ["en"],
			icon: "🇻🇬",
			iconU: "U+1F1FB U+1F1EC",
		},
		{
			id: "VI",
			name: "U.S. Virgin Islands",
			native: "United States Virgin Islands",
			phone: "1340",
			continent: "NA",
			capital: "Charlotte Amalie",
			currency: "USD",
			languages: ["en"],
			icon: "🇻🇮",
			iconU: "U+1F1FB U+1F1EE",
		},
		{
			id: "VN",
			name: "Vietnam",
			native: "Việt Nam",
			phone: "84",
			continent: "AS",
			capital: "Hanoi",
			currency: "VND",
			languages: ["vi"],
			icon: "🇻🇳",
			iconU: "U+1F1FB U+1F1F3",
		},
		{
			id: "VU",
			name: "Vanuatu",
			native: "Vanuatu",
			phone: "678",
			continent: "OC",
			capital: "Port Vila",
			currency: "VUV",
			languages: ["bi", "en", "fr"],
			icon: "🇻🇺",
			iconU: "U+1F1FB U+1F1FA",
		},
		{
			id: "WF",
			name: "Wallis and Futuna",
			native: "Wallis et Futuna",
			phone: "681",
			continent: "OC",
			capital: "Mata-Utu",
			currency: "XPF",
			languages: ["fr"],
			icon: "🇼🇫",
			iconU: "U+1F1FC U+1F1EB",
		},
		{
			id: "WS",
			name: "Samoa",
			native: "Samoa",
			phone: "685",
			continent: "OC",
			capital: "Apia",
			currency: "WST",
			languages: ["sm", "en"],
			icon: "🇼🇸",
			iconU: "U+1F1FC U+1F1F8",
		},
		{
			id: "XK",
			name: "Kosovo",
			native: "Republika e Kosovës",
			phone: "377,381,383,386",
			continent: "EU",
			capital: "Pristina",
			currency: "EUR",
			languages: ["sq", "sr"],
			icon: "🇽🇰",
			iconU: "U+1F1FD U+1F1F0",
		},
		{
			id: "YE",
			name: "Yemen",
			native: "اليَمَن",
			phone: "967",
			continent: "AS",
			capital: "Sana'a",
			currency: "YER",
			languages: ["ar"],
			icon: "🇾🇪",
			iconU: "U+1F1FE U+1F1EA",
		},
		{
			id: "YT",
			name: "Mayotte",
			native: "Mayotte",
			phone: "262",
			continent: "AF",
			capital: "Mamoudzou",
			currency: "EUR",
			languages: ["fr"],
			icon: "🇾🇹",
			iconU: "U+1F1FE U+1F1F9",
		},
		{
			id: "ZA",
			name: "South Africa",
			native: "South Africa",
			phone: "27",
			continent: "AF",
			capital: "Pretoria",
			currency: "ZAR",
			languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"],
			icon: "🇿🇦",
			iconU: "U+1F1FF U+1F1E6",
		},
		{
			id: "ZM",
			name: "Zambia",
			native: "Zambia",
			phone: "260",
			continent: "AF",
			capital: "Lusaka",
			currency: "ZMW",
			languages: ["en"],
			icon: "🇿🇲",
			iconU: "U+1F1FF U+1F1F2",
		},
		{
			id: "ZW",
			name: "Zimbabwe",
			native: "Zimbabwe",
			phone: "263",
			continent: "AF",
			capital: "Harare",
			currency: "USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY",
			languages: ["en", "sn", "nd"],
			icon: "🇿🇼",
			iconU: "U+1F1FF U+1F1FC",
		},
	];
}
