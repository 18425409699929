import { useEventContext } from "../../../hooks/useEventContext";
import { FileFieldPreview } from "./FileFieldPreview";
import { PageChangedParams } from "@salesdesk/salesdesk-model";

export function PageChangedPayload() {
	const { event } = useEventContext();
	const fileId = (event.params as PageChangedParams)?.fileId;
	if (!fileId || !event.object_def_id) {
		return null;
	}
	return <FileFieldPreview fileId={fileId} objectId={event.object_def_id} />;
}
