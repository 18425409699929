import { DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";

import { AgendaDetailsSectionContainer } from "./AgendaDetailsSectionContainer";
import { AssociationDetailsSectionContainer } from "./AssociationDetailsSectionContainer";
import { HoldingPageParticipants } from "./HoldingPageParticipants";

interface HoldingPageDetailsProps {
	meetingInfoAssociationDefs: DirectedSDObjectAssociation[];
}

export function HoldingPageDetails({ meetingInfoAssociationDefs }: HoldingPageDetailsProps) {
	return (
		<div className="flex flex-col gap-6">
			<HoldingPageParticipants />
			<AgendaDetailsSectionContainer />
			{meetingInfoAssociationDefs.map((objectAssociation) => (
				<AssociationDetailsSectionContainer key={objectAssociation.id} objectAssociation={objectAssociation} />
			))}
		</div>
	);
}
