import { ICONS } from "@salesdesk/salesdesk-ui";
import { MenuItem } from "../../../../menu";

export function generatePreviewLinkMenuItem(link?: string): MenuItem[] {
	if (!link) {
		return [];
	}

	return [
		{
			icon: ICONS.eye,
			text: "Preview",
			link,
			type: "link",
		},
	];
}
