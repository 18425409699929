import {
	FieldValue,
	RecordsSearchRequest,
	RecordsSearchResponse,
	SDRecord,
	SDRecordBatchPatchRequest,
	SDRecordCreateRequest,
	SDRecordPatchRequest,
} from "@salesdesk/salesdesk-schemas";

import {
	SDApi,
	buildApiInvalidatedTagList,
	buildApiInvalidatedTagsList,
	buildApiSingleTag,
	buildApiTagsList,
	withWorkspaceContext,
} from "../../api";
import { dispatchRecordChangeEvent } from "../search/utils";
import { RecordChangeType } from "../search/types";

export interface UpdateRecordArgs {
	record: SDRecord;
	updatedFields: FieldValue[];
	workspaceId?: number;
	preventOptimisticUpdate?: boolean;
}

export interface BulkUpdateRecordsArgs {
	records: SDRecord[];
	updatedFields?: FieldValue[];
	ownerId?: SDRecord["_id"];
	workspaceId?: number;
}

export const recordsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			searchRecords: builder.query<RecordsSearchResponse, RecordsSearchRequest>({
				query: (searchRequestBody) => ({ url: `/records/search`, method: "POST", body: searchRequestBody }),
			}),
			getRecords: builder.query<SDRecord[], SDRecord["_id"][]>({
				query: (recordIds) => `/records?ids=${recordIds.join(",")}`,
				providesTags: (result) => buildApiTagsList(result, (record) => record._id, "Record"),
			}),
			getRecord: builder.query<SDRecord, SDRecord["_id"]>({
				query: (recordId) => `/records/${recordId}`,
				providesTags: (result) => buildApiSingleTag(result, (record) => record._id, "Record"),
			}),
			deleteRecord: builder.mutation<
				void,
				{
					sdRecord: SDRecord;
					workspaceId?: number;
				}
			>({
				query: ({ sdRecord, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${sdRecord._id}`, workspaceId),
					method: "DELETE",
				}),
				onQueryStarted: async ({ sdRecord }, { queryFulfilled }) => {
					queryFulfilled.then(() => {
						dispatchRecordChangeEvent(RecordChangeType.DELETE, sdRecord);
					});
				},
				invalidatesTags: (result, error, arg) =>
					buildApiInvalidatedTagList(arg.sdRecord, (record) => record._id, "Record"),
			}),
			// TODO: Because the record IDs are stored in the URL params this is currently limited to roughly 200 records
			// before the URL becomes too long
			deleteRecords: builder.mutation<
				void,
				{
					sdRecords: SDRecord[];
					workspaceId?: number;
				}
			>({
				query: ({ sdRecords, workspaceId }) => {
					const recordIds = sdRecords.map((record) => record._id).join(",");

					return {
						url: withWorkspaceContext(`/records/?ids=${recordIds}`, workspaceId),
						method: "DELETE",
					};
				},
				onQueryStarted: async ({ sdRecords }, { queryFulfilled }) => {
					queryFulfilled.then(() => {
						dispatchRecordChangeEvent(RecordChangeType.DELETE, sdRecords);
					});
				},
				invalidatesTags: (result, error, arg) =>
					buildApiInvalidatedTagsList(arg.sdRecords, (record) => record._id, "Record"),
			}),
			updateRecord: builder.mutation<SDRecord, UpdateRecordArgs>({
				query: ({ record, updatedFields, workspaceId }) => {
					const body: SDRecordPatchRequest = { _dataInst: { ...record._dataInst, _children: updatedFields } };

					return {
						url: withWorkspaceContext(`/records/${record._id}`, workspaceId),
						method: "PATCH",
						body,
					};
				},
				onQueryStarted: async ({ record, updatedFields, preventOptimisticUpdate }, { dispatch, queryFulfilled }) => {
					let cacheUpdate = null;

					if (!preventOptimisticUpdate) {
						const optimisticUpdatedRecord = generateOptimisticSDRecord(record, updatedFields);

						dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecord, true);

						// Updates the cache entry for the individual record
						cacheUpdate = dispatch(
							recordsApi.util.updateQueryData("getRecord", record._id, (draft) => {
								Object.assign(draft, optimisticUpdatedRecord);
							})
						);
					}
					// Undo the cache update if the query fails
					try {
						const { data: updatedRecord } = await queryFulfilled;

						dispatchRecordChangeEvent(RecordChangeType.UPDATE, updatedRecord);

						// Update the RTK query cache for getRecord with the new updated record from the API
						dispatch(recordsApi.util.upsertQueryData("getRecord", updatedRecord._id, updatedRecord));
					} catch {
						dispatchRecordChangeEvent(RecordChangeType.UPDATE, record, true);

						if (cacheUpdate) {
							cacheUpdate.undo();
						}
					}
				},
			}),
			bulkUpdateRecords: builder.mutation<SDRecord[], BulkUpdateRecordsArgs>({
				query: ({ records, updatedFields, ownerId, workspaceId }) => {
					const body: SDRecordBatchPatchRequest[] = records.map((record) => ({
						_id: record._id,
						...(updatedFields ? { _dataInst: { ...record._dataInst, _children: updatedFields } } : {}),
						...(ownerId ? { _ownerId: ownerId } : {}),
					}));

					return {
						url: withWorkspaceContext(`/records/batch`, workspaceId),
						method: "PATCH",
						body,
					};
				},
				onQueryStarted: async ({ records, ownerId, updatedFields }, { dispatch, queryFulfilled }) => {
					const optimisticUpdatedRecords = records.map((record) =>
						generateOptimisticSDRecord(record, updatedFields, ownerId)
					);

					dispatchRecordChangeEvent(RecordChangeType.UPDATE, optimisticUpdatedRecords, true);

					const cacheUpdates = optimisticUpdatedRecords.map((updatedRecord) =>
						dispatch(
							recordsApi.util.updateQueryData("getRecord", updatedRecord._id, (draft) => {
								Object.assign(draft, updatedRecord);
							})
						)
					);

					try {
						const { data: updatedRecords } = await queryFulfilled;
						dispatchRecordChangeEvent(RecordChangeType.UPDATE, updatedRecords);

						// Update the RTK query cache for getRecord with the new updated record from the API
						updatedRecords.forEach((updatedRecord) => {
							dispatch(recordsApi.util.upsertQueryData("getRecord", updatedRecord._id, updatedRecord));
						});
					} catch {
						dispatchRecordChangeEvent(RecordChangeType.UPDATE, records, true);
						cacheUpdates.forEach((cacheUpdate) => cacheUpdate.undo());
					}
				},
				invalidatesTags: (result) => buildApiInvalidatedTagsList(result, (record) => record._id, "Record"),
			}),
			createRecord: builder.mutation<SDRecord, { recordCreateRequest: SDRecordCreateRequest; workspaceId?: number }>({
				query: ({ recordCreateRequest, workspaceId }) => {
					return {
						url: withWorkspaceContext(`/records`, workspaceId),
						method: "POST",
						body: recordCreateRequest,
					};
				},
				onQueryStarted: async (arg, { queryFulfilled }) => {
					queryFulfilled.then(({ data: newRecord }) => {
						dispatchRecordChangeEvent(RecordChangeType.CREATE, newRecord);
					});
				},
				invalidatesTags: [{ type: "Record", id: "LIST" }],
			}),
			convertDocToPdfAsset: builder.mutation<SDRecord, { recordId: SDRecord["_id"]; workspaceId?: number }>({
				query: ({ recordId, workspaceId }) => ({
					url: withWorkspaceContext(`/docs/${recordId}/convert/to/assets/pdf`, workspaceId),
					method: "POST",
				}),
				onQueryStarted: async (arg, { queryFulfilled }) => {
					queryFulfilled.then(({ data: newAssetRecord }) => {
						dispatchRecordChangeEvent(RecordChangeType.CREATE, newAssetRecord);
					});
				},
				invalidatesTags: (result) => buildApiInvalidatedTagList(result, (record) => record._id, "Record"),
			}),
		};
	},
});

function generateOptimisticSDRecord(record: SDRecord, updatedFields?: FieldValue[], ownerId?: number): SDRecord {
	let updatedChildrenFields: FieldValue[] = [];

	if (updatedFields?.length) {
		updatedChildrenFields = record._dataInst._children.map((childField) => {
			const updatedField = updatedFields.find((field) => field._fieldId === childField._fieldId);

			if (updatedField) {
				return {
					...childField,
					_value: updatedField._value,
				};
			}

			return childField;
		});
	} else {
		updatedChildrenFields = record._dataInst._children;
	}

	return {
		...record,
		updatedAt: new Date().getTime(),
		_dataInst: {
			...record._dataInst,
			_children: updatedChildrenFields,
		},
		_ownerId: ownerId != null ? ownerId : record._ownerId,
	};
}

export const {
	useSearchRecordsQuery,
	useLazySearchRecordsQuery,
	useGetRecordsQuery,
	useLazyGetRecordsQuery,
	useGetRecordQuery,
	useLazyGetRecordQuery,
	useDeleteRecordMutation,
	useDeleteRecordsMutation,
	useUpdateRecordMutation,
	useBulkUpdateRecordsMutation,
	useCreateRecordMutation,
	useConvertDocToPdfAssetMutation,
} = recordsApi;
