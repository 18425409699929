import { useMemo } from "react";

import { getSDRecordName, getUserRecordAvatarFileId, SDRecord } from "@salesdesk/salesdesk-schemas";

import { AvatarSize } from "../../types";
import { getInitials } from "../../utils";
import { useUserLoginStatus, useUserObjectsMap } from "../../hooks";
import { Avatar } from "./Avatar";

interface UserRecordAvatarProps {
	userRecord?: SDRecord;
	showStatus?: boolean;
	withBorder?: boolean;
	size?: AvatarSize;
}

export function UserRecordAvatar({ userRecord, showStatus, ...rest }: UserRecordAvatarProps) {
	const userObjectMap = useUserObjectsMap();
	const userObject = userRecord ? userObjectMap?.get(userRecord._objectDefId) : undefined;

	const { isNowActive } = useUserLoginStatus(userRecord, userObject, showStatus);

	const { initials, avatarFileId } = useMemo(() => {
		if (!userRecord || !userObject) {
			return {};
		}

		return {
			initials: getInitials(getSDRecordName(userObject, userRecord)),
			avatarFileId: getUserRecordAvatarFileId(userObject, userRecord),
		};
	}, [userObject, userRecord]);

	if (!userRecord || !userObject) {
		return <Avatar {...rest} />;
	}

	return (
		<Avatar
			initials={initials}
			avatarFileId={avatarFileId}
			status={isNowActive && showStatus ? "ACTIVE" : undefined}
			{...rest}
		/>
	);
}
