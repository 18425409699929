import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";

export function getCreateRecordTitleText(sdObject: SDObject) {
	const objectName = sdObject._displayName;

	if (sdObject._id === mInteractionMeetingDef.ID) {
		return `Book ${objectName}`;
	}

	return `Create ${objectName}`;
}

export function getCreateRecordVerb(sdObject: SDObject) {
	if (sdObject._id === mInteractionMeetingDef.ID) {
		return "book";
	}

	return "create";
}
