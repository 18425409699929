import { asConst } from "json-schema-to-ts";
import { idSchema } from "../components";
import { recordIdSchema } from "./properties";

export const sdAssociationCreateRequestSchema = asConst({
	title: "SDAssociationCreateRequest",
	description: "Defines a schema for creating record associations",
	type: "object",
	additionalProperties: false,
	required: ["record1Id", "record2Id", "objectAssociationId"],
	properties: {
		record1Id: {
			...recordIdSchema,
			title: "Record 1 ID",
			description: "First record to be associated",
		},
		record2Id: {
			...recordIdSchema,
			title: "Record 2 ID",
			description: "Second record to be associated",
		},
		objectAssociationId: {
			...idSchema,
			title: "Association definition ID",
			description: "Type of association",
		},
	},
});
