import { useGetNoteQuery } from "../../../../notes/api/notesApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetRecordQuery } from "../../../../records";
import { useGetObjectById } from "../../../../../hooks";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { TipTapContentWrapper } from "./TipTapContentWrapper";

type LazyNotePayloadParams = {
	recordId: number | undefined;
	noteId: string;
	createdById: number;
	emoji?: string;
};

export function LazyNotePayload({ recordId, noteId, createdById, emoji }: LazyNotePayloadParams) {
	const { data: comment, isLoading: isLoadingComments } = useGetNoteQuery(recordId ? { recordId, noteId } : skipToken);
	const { data: user, isLoading: isLoadingUser } = useGetRecordQuery(createdById ?? skipToken);
	const { sdObject: userObject } = useGetObjectById(user?._objectDefId);

	return (
		<TipTapContentWrapper
			isLoadingCreatedUserName={isLoadingUser}
			createdUserName={userObject && user ? getSDRecordName(userObject, user) : undefined}
			isLoadingContent={isLoadingComments}
			tipTapContent={comment?.message}
			emoji={emoji}
		/>
	);
}
