import { Claim, ClaimSubjectType, PrincipalClaimType, RoleClaimType, RoleType } from "../../roles";
import { AbilityAction } from "./AbilityAction";
import { AbilitySubject } from "./AbilitySubject";
import { AppAbility } from "./AppAbility";
import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { createHashId, nameof } from "@salesdesk/salesdesk-utils";
import { mUserDef, USER_IDS, UserType } from "@salesdesk/salesdesk-model";
import { SDRecord } from "../../records";
import { Note } from "../../notes";
import {
	CUSTOMER_NOT_OWNED_EDITABLE_OBJECT_IDS,
	CUSTOMER_OWNED_OBJECT_IDS,
	USER_OBJECT_DEF_IDS,
} from "../../MOVE_TO_MODEL";
import { Creatable } from "../../components";
import { sdSubjectField } from "./sdSubjectField";

const _idFieldName = nameof<SDRecord>("_id");
const _objectDefIdFieldName = nameof<SDRecord>("_objectDefId");
const createdByFieldName = nameof<Creatable>("createdBy");
const _ownerIdFieldName = nameof<SDRecord>("_ownerId");
const sharedWorkspaceIdsFieldName = nameof<SDRecord>("sharedWorkspaceIds");
const workspaceIdFieldName = nameof<Note>("workspaceId");

export const defineAbilityFor = (claims: Claim[]): AppAbility => {
	const userRecordIdClaim = claims.find((c) => c.type === PrincipalClaimType.UserRecordId);
	const userRecordId = userRecordIdClaim != null ? parseInt(userRecordIdClaim.value, 10) : null;
	const userTypeClaim = claims.find((c) => c.type === PrincipalClaimType.UserType);
	const isUser = userTypeClaim?.value === UserType.SALESDESK_USER;
	const isCustomer = userTypeClaim?.value === UserType.SALESDESK_CUSTOMER;

	const { can, cannot, build } = new AbilityBuilder<AppAbility>(createMongoAbility);
	const ifUserRecordIdNotNull = (then: () => void) => {
		if (userRecordId != null) then();
	};
	const claimsContains = (type: RoleClaimType, subject: string) =>
		claims.findIndex((claim) => claim.type === type && claim.value === subject) > -1;

	claims.forEach((claim) => {
		const ifClaimValueEquals = (value: string, then: () => void) => {
			if (claim.value === value) then();
		};

		const ifClaimValueIsNumber = (then: () => void) => {
			if (!isNaN(parseInt(claim.value, 10))) then();
		};

		switch (claim.type) {
			// Roles
			case RoleClaimType.Role:
				if (claim.value === RoleType.Admin) can(AbilityAction.Manage, AbilitySubject.All);
				break;
			case RoleClaimType.RoleView:
				ifClaimValueEquals(ClaimSubjectType.All, () => {
					// Controls visibility of the Role admin screen
					can(AbilityAction.View, AbilitySubject.RoleAdmin);
					can(AbilityAction.View, AbilitySubject.Role);
				});
				break;
			case RoleClaimType.RoleCreate:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Create, AbilitySubject.Role));
				break;
			case RoleClaimType.RoleEdit:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Edit, AbilitySubject.Role));
				break;
			case RoleClaimType.RoleDelete:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Delete, AbilitySubject.Role));
				break;

			// Users
			case RoleClaimType.UserView:
				ifClaimValueEquals(ClaimSubjectType.All, () => {
					// Controls visibility of the User admin screen
					can(AbilityAction.View, AbilitySubject.UserAdmin);
					can(AbilityAction.View, AbilitySubject.Record, { [_objectDefIdFieldName]: USER_IDS.SALESDESK_USER });
				});
				break;
			case RoleClaimType.UserCreate:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Create, AbilitySubject.Record, { [_objectDefIdFieldName]: USER_IDS.SALESDESK_USER })
				);
				break;
			case RoleClaimType.UserEdit:
				ifClaimValueEquals(ClaimSubjectType.All, () => {
					can(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField("*")], {
						[_objectDefIdFieldName]: USER_IDS.SALESDESK_USER,
					});
					can(AbilityAction.ChangeOwner, AbilitySubject.Record, { [_objectDefIdFieldName]: USER_IDS.SALESDESK_USER });
				});
				break;
			case RoleClaimType.UserDelete:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Delete, AbilitySubject.Record, { [_objectDefIdFieldName]: USER_IDS.SALESDESK_USER })
				);
				break;
			case RoleClaimType.UserLoginEdit:
				can(AbilityAction.Edit, AbilitySubject.Record, [mUserDef.LOGIN_AUTHORIZED_FIELD_NAME], {
					[_objectDefIdFieldName]: parseInt(claim.value, 10),
				});
				break;

			// Objects
			case RoleClaimType.ObjectView:
				ifClaimValueEquals(ClaimSubjectType.All, () => {
					// Controls visibility of the User admin screen
					can(AbilityAction.View, AbilitySubject.ObjectAdmin);
					can(AbilityAction.View, AbilitySubject.Object);
				});
				ifClaimValueIsNumber(() => {
					can(AbilityAction.View, AbilitySubject.Object, { _id: parseInt(claim.value, 10) });
				});
				break;
			case RoleClaimType.ObjectCreate:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Create, AbilitySubject.Object));
				break;
			case RoleClaimType.ObjectEdit:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Edit, AbilitySubject.Object));
				break;
			case RoleClaimType.ObjectDelete:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Delete, AbilitySubject.Object));
				break;

			// Object Associations
			case RoleClaimType.ObjectAssociationView:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.View, AbilitySubject.ObjectAssociationAdmin));
				break;
			case RoleClaimType.ObjectAssociationCreate:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Create, AbilitySubject.ObjectAssociation));
				break;
			case RoleClaimType.ObjectAssociationEdit:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Edit, AbilitySubject.ObjectAssociation));
				break;
			case RoleClaimType.ObjectAssociationDelete:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Delete, AbilitySubject.ObjectAssociation));
				break;

			// Records
			case RoleClaimType.ObjectRecordViewAll:
				ifClaimValueEquals(ClaimSubjectType.All, () => {
					can(AbilityAction.View, AbilitySubject.Record);
				});
				break;
			case RoleClaimType.RecordView:
				can(AbilityAction.View, AbilitySubject.Record, { _id: parseInt(claim.value, 10) });
				break;
			case RoleClaimType.ObjectRecordCreate:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Create, AbilitySubject.Record, {
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;
			case RoleClaimType.ObjectRecordEditAll:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField("*")], {
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;
			case RoleClaimType.ObjectRecordChangeOwnerAll:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.ChangeOwner, AbilitySubject.Record, {
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;
			case RoleClaimType.ObjectRecordDeleteAll:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Delete, AbilitySubject.Record, {
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;
			case RoleClaimType.ObjectRecordViewOwner:
				ifUserRecordIdNotNull(() =>
					ifClaimValueEquals(ClaimSubjectType.All, () => {
						can(AbilityAction.View, AbilitySubject.Record, {
							[_ownerIdFieldName]: userRecordId,
							[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
						});
					})
				);
				break;
			case RoleClaimType.ObjectRecordEditOwner:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField("*")], {
						[_ownerIdFieldName]: userRecordId,
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;
			case RoleClaimType.ObjectRecordChangeOwnerOwner:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.ChangeOwner, AbilitySubject.Record, {
						[_ownerIdFieldName]: userRecordId,
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;
			case RoleClaimType.ObjectRecordDeleteOwner:
				ifClaimValueEquals(ClaimSubjectType.All, () =>
					can(AbilityAction.Delete, AbilitySubject.Record, {
						[_ownerIdFieldName]: userRecordId,
						[_objectDefIdFieldName]: { $ne: USER_IDS.SALESDESK_USER },
					})
				);
				break;

			// Record Actions
			case RoleClaimType.ObjectRecordBulkEdit:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.BulkEdit, AbilitySubject.Record));
				break;

			case RoleClaimType.ObjectRecordBulkDelete:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.BulkDelete, AbilitySubject.Record));
				break;

			// Customer Users
			case RoleClaimType.LeadRecordConvert:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Convert, AbilitySubject.Lead));
				break;

			// Record Associations
			case RoleClaimType.ObjectRecordAssociationCreate:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Create, AbilitySubject.RecordAssociation));
				break;

			case RoleClaimType.ObjectRecordAssociationDelete:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Delete, AbilitySubject.RecordAssociation));
				break;

			// File
			case RoleClaimType.SentimentAnalysisView:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.View, AbilitySubject.SentimentAnalysis));
				break;
			case RoleClaimType.CoachingAdviceView:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.View, AbilitySubject.CoachingAdvice));
				break;

			// Video Calling
			case RoleClaimType.VideoCallRecord:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Record, AbilitySubject.VideoCall));
				break;
			case RoleClaimType.VideoCallShareScreen:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Share, AbilitySubject.VideoCallScreen));
				break;
			case RoleClaimType.VideoCallCreateTask:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Create, AbilitySubject.VideoCallTask));
				break;
			case RoleClaimType.VideoCallPresentFile:
				ifClaimValueEquals(ClaimSubjectType.All, () => can(AbilityAction.Present, AbilitySubject.VideoCallFile));
				break;

			// Authorized Workspaces
			case PrincipalClaimType.AuthorizedWorkspaceIds: {
				if (isCustomer) {
					const claimWorkspaceIds = claim.value
						.split(",")
						.filter((str) => str.length > 0)
						.map((workspaceId) => parseInt(workspaceId, 10));

					can(AbilityAction.Create, AbilitySubject.Record, {
						[_objectDefIdFieldName]: { $in: CUSTOMER_OWNED_OBJECT_IDS },
					});
					// The workspace record itself is in the user's authorized workspaces
					can(AbilityAction.View, AbilitySubject.Record, { [_idFieldName]: { $in: claimWorkspaceIds } });
					// Records shared with the user's authorized workspaces
					can(AbilityAction.View, AbilitySubject.Record, { [sharedWorkspaceIdsFieldName]: { $in: claimWorkspaceIds } });
					can(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField("*")], {
						[sharedWorkspaceIdsFieldName]: { $in: claimWorkspaceIds },
						[_objectDefIdFieldName]: { $in: CUSTOMER_OWNED_OBJECT_IDS },
						[_ownerIdFieldName]: { $eq: userRecordId },
					});
					can(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField("*")], {
						[sharedWorkspaceIdsFieldName]: { $in: claimWorkspaceIds },
						[_objectDefIdFieldName]: { $in: CUSTOMER_NOT_OWNED_EDITABLE_OBJECT_IDS },
					});
					can(AbilityAction.Delete, AbilitySubject.Record, {
						[sharedWorkspaceIdsFieldName]: { $in: claimWorkspaceIds },
						[_ownerIdFieldName]: userRecordId,
					});

					cannot([AbilityAction.Edit, AbilityAction.Delete], AbilitySubject.Note, {
						[workspaceIdFieldName]: { $nin: claimWorkspaceIds },
					});
				}
				break;
			}
		}
	});

	// Can for everybody
	can(AbilityAction.View, AbilitySubject.TenantSettings);

	// Can View their own User/Customer profile fields
	ifUserRecordIdNotNull(() => {
		can(AbilityAction.View, AbilitySubject.Record, { [_idFieldName]: userRecordId });
	});

	// Any user can create/delete a WebSocketConnection
	can([AbilityAction.Create, AbilityAction.Delete], AbilitySubject.WebSocketConnection);

	// Can edit their own User/Customer profile fields
	ifUserRecordIdNotNull(() => {
		can(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField("*")], { [_idFieldName]: userRecordId });
	});

	ifUserRecordIdNotNull(() => {
		can(AbilityAction.View, AbilitySubject.File, { [createdByFieldName]: userRecordId });
	});

	if (isUser) {
		can(AbilityAction.ShareWithWorkspace, AbilitySubject.Record);
		can(AbilityAction.UnshareWithWorkspace, AbilitySubject.Record);

		// Imports - User has CRUD on their own imports
		can(AbilityAction.Create, AbilitySubject.Import);
		can(AbilityAction.View, AbilitySubject.Import, {
			[createdByFieldName]: userRecordId,
		});
		can(AbilityAction.View, AbilitySubject.ImportJob, {
			[createdByFieldName]: userRecordId,
		});

		can(AbilityAction.Create, AbilitySubject.SDApiKey);
	}

	if (isCustomer) {
		can(AbilityAction.Share, AbilitySubject.VideoCallScreen);
		// TODO:  Can't do this because the UI associates the task to the meeting and Customers can't associate
		// can(AbilityAction.Create, AbilitySubject.VideoCallTask);
		can(AbilityAction.Present, AbilitySubject.VideoCallFile);
		can(AbilityAction.View, AbilitySubject.SentimentAnalysis);
	}

	if (isUser || isCustomer) {
		// Can for all Users/Customers
		can(AbilityAction.View, AbilitySubject.Object); // TODO:  Some fields should probably be hidden from customers
		can(AbilityAction.View, AbilitySubject.ObjectAssociation);
		can(AbilityAction.View, AbilitySubject.Record, { [_objectDefIdFieldName]: USER_IDS.SALESDESK_USER }); // All Users
		can(AbilityAction.View, AbilitySubject.Role); // User Pill popover
		can(AbilityAction.View, AbilitySubject.RecordAssociation);
		can(AbilityAction.DownloadInUI, AbilitySubject.File);
		can(AbilityAction.Search, AbilitySubject.Record);
		can(AbilityAction.Search, AbilitySubject.Event);
		can(AbilityAction.View, AbilitySubject.FullMeetingUI);

		// Notification Rule - Actions restricted to your own rules
		can(AbilityAction.Create, AbilitySubject.NotificationRule);
		can([AbilityAction.View, AbilityAction.Edit, AbilityAction.Delete], AbilitySubject.NotificationRule, {
			[createdByFieldName]: userRecordId,
		});

		// Note - Anyone has CRUD on their own note
		can([AbilityAction.View, AbilityAction.Create], AbilitySubject.Note);
		can([AbilityAction.Delete], AbilitySubject.Note, {
			[createdByFieldName]: userRecordId,
		});

		can([AbilityAction.Edit], AbilitySubject.Note);
		// Explicitly disallow editing/deleting note not created by the user to prevent admins being being allowed to do it
		cannot([AbilityAction.Edit], AbilitySubject.Note, {
			[createdByFieldName]: { $ne: userRecordId },
		});
		can([AbilityAction.Edit], AbilitySubject.NoteReaction);

		// Comment - Anyone has CRUD on their own comment
		can([AbilityAction.View, AbilityAction.Create], AbilitySubject.Comment);
		can([AbilityAction.Delete], AbilitySubject.Comment, {
			[createdByFieldName]: userRecordId,
		});

		can([AbilityAction.Edit], AbilitySubject.Comment);
		// Explicitly disallow editing/deleting comment not created by the user to prevent admins being being allowed to do it
		cannot([AbilityAction.Edit], AbilitySubject.Comment, {
			[createdByFieldName]: { $ne: userRecordId },
		});

		// Bookmarks - Anyone has CRUD on their own bookmarks
		can(AbilityAction.View, AbilitySubject.Bookmark);
		can(AbilityAction.Create, AbilitySubject.Bookmark);
		can(AbilityAction.Edit, AbilitySubject.Bookmark);
		can(AbilityAction.Delete, AbilitySubject.Bookmark);

		// Favorites - Anyone has CRUD on their own favorites
		can(AbilityAction.View, AbilitySubject.Favorite);
		can(AbilityAction.Edit, AbilitySubject.Favorite);
		can(AbilityAction.Delete, AbilitySubject.Favorite);

		// Watched - Anyone has CRUD on their own watches
		can(AbilityAction.View, AbilitySubject.Watch);
		can(AbilityAction.Edit, AbilitySubject.Watch);
		can(AbilityAction.Delete, AbilitySubject.Watch);

		// Any authenticated user can view/create files
		can(AbilityAction.Create, AbilitySubject.File);
	}

	// ************************************************************************************* //
	// *** CANNOT 'cannot' need to go last because they get overwritten by later 'can's *** //
	// *** EXCEPTION: cannot do not apply to users with can(Manage, All) (i.e. Admins)  *** //
	// ************************************************************************************* //

	if (!claimsContains(RoleClaimType.Role, RoleType.Admin)) {
		if (!claimsContains(RoleClaimType.UserEdit, ClaimSubjectType.All)) {
			cannot(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField(mUserDef.ROLES_FIELD_NAME)], {
				[_objectDefIdFieldName]: USER_IDS.SALESDESK_USER,
			});
		}

		USER_OBJECT_DEF_IDS.forEach((userObjectId) => {
			if (!claimsContains(RoleClaimType.UserLoginEdit, userObjectId.toString()))
				cannot(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField(mUserDef.LOGIN_AUTHORIZED_FIELD_NAME)], {
					[_objectDefIdFieldName]: userObjectId,
				});
		});
	}

	// No User can edit a user's email field if that user can login
	USER_OBJECT_DEF_IDS.forEach((userObjectId) => {
		cannot(AbilityAction.Edit, AbilitySubject.Record, [sdSubjectField(mUserDef.EMAIL_FIELD_NAME)], {
			[_objectDefIdFieldName]: userObjectId,
			"_dataInst._children": {
				$elemMatch: { _fieldId: createHashId(mUserDef.LOGIN_AUTHORIZED_FIELD_NAME), _value: true },
			},
		});
	});

	return build();
};
