import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { SDObject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectFromSlug } from "../../../../../../hooks";
import { ObjectBoardPageWrapper } from "../../../../../ObjectBoard";
import { WORKSPACE_OBJECT_BOARDS } from "../../../../utils";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { WorkspaceObjectBoard } from "./WorkspaceObjectBoard";

export function WorkspaceObjectBoardPage() {
	const { sdObjectPath: objectPathSlug } = useParams<{ sdObjectPath: string }>();

	const workspaceObjectBoardFilter = useCallback((sdObject: SDObject) => {
		return WORKSPACE_OBJECT_BOARDS.includes(sdObject._id);
	}, []);

	const { sdObject, isLoading } = useGetObjectFromSlug(objectPathSlug, workspaceObjectBoardFilter);

	const { workspaceRecord } = useWorkspaceContext();

	return (
		<ObjectBoardPageWrapper
			sdObject={sdObject}
			failedToLoad={objectPathSlug == null || (!sdObject && !isLoading)}
			workspaceId={workspaceRecord?._id}
		>
			<WorkspaceObjectBoard />
		</ObjectBoardPageWrapper>
	);
}
