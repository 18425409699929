import { forwardRef, useState, useEffect } from "react";

import { ProfilePhotoInputProps, ProfilePhotoInput } from "../../../../../inputs";
import { useStoreFile } from "../../../../../files";
import { UploadProgressStatus } from "../../FileField";
import { useProfilePhotoInfo } from "../hooks";
import { ProfilePhotoFieldValue } from "../types";

interface EditProfilePhotoFieldProps extends Omit<ProfilePhotoInputProps, "onChange" | "value"> {
	value?: ProfilePhotoFieldValue;
	onChange: (newValue: number | null) => void;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
}

export const EditProfilePhotoField = forwardRef<HTMLInputElement, EditProfilePhotoFieldProps>(
	({ value, onChange, updateUploadProgressStatus, disabled, ...fileInputProps }, ref) => {
		const { fileId, fullName } = useProfilePhotoInfo(value);
		const storeFile = useStoreFile();

		const [uploadProgress, setUploadProgress] = useState<number | undefined>();

		const uploadProgressStatus = uploadProgress ? "in_progress" : "idle";

		useEffect(() => {
			if (updateUploadProgressStatus) {
				updateUploadProgressStatus(uploadProgressStatus);
			}
		}, [uploadProgressStatus, updateUploadProgressStatus]);

		return (
			<ProfilePhotoInput
				ref={ref}
				value={fileId}
				fullName={fullName}
				onChange={(newFile) => {
					if (!newFile) {
						onChange(null);
						return;
					}

					// TODO: How do we handle file uploads failing?
					storeFile(newFile, (uploadProgress) => {
						setUploadProgress(uploadProgress);
					})
						.then((sdFileId) => {
							onChange(sdFileId);
						})
						.finally(() => {
							setUploadProgress(undefined);
						});
				}}
				{...fileInputProps}
				disabled={uploadProgressStatus === "in_progress" || disabled}
				uploadProgress={uploadProgress}
			/>
		);
	}
);
