import { PALETTE, ThemableColorKey } from "./ui_colors";

export const UI_THEMES = {
	purple: {
		c_brand_primary: PALETTE["purple-500"],
		c_brand_off_white: PALETTE["off-white-500"],
		c_text_link: PALETTE["purple-500"],
		c_bg_02: PALETTE["off-white-500"],
		c_bg_03: PALETTE["off-white-200"],
		c_bg_04: PALETTE["off-white-100"],
		c_bg_05: PALETTE["off-white-600"],
		c_action_01: PALETTE["purple-500"],
		c_action_02: PALETTE["purple-300"],
		c_action_03: PALETTE["purple-700"],
		c_action_focus: PALETTE["purple-200"],
		c_action_04: PALETTE["purple-100"],
		c_icon_inverted: PALETTE["off-white-200"],

		c_workspace_nav_circle1_start: "#6100FF",
		c_workspace_nav_circle1_end: "#A157FF",
		c_workspace_nav_circle2_start: "#267DFF",
		c_workspace_nav_circle2_end: "#0052F0",
		c_workspace_nav_circle3_start: "#6100FF",
		c_workspace_nav_circle3_end: "#A157FF",
	},
	cyan: {
		c_brand_primary: PALETTE["cyan-500"],
		c_brand_off_white: PALETTE["cyan-off-white-500"],
		c_text_link: PALETTE["cyan-500"],
		c_bg_02: PALETTE["cyan-off-white-500"],
		c_bg_03: PALETTE["cyan-off-white-200"],
		c_bg_04: PALETTE["cyan-off-white-100"],
		c_bg_05: PALETTE["cyan-off-white-600"],
		c_action_01: PALETTE["cyan-600"],
		c_action_02: PALETTE["cyan-300"],
		c_action_03: PALETTE["cyan-700"],
		c_action_focus: PALETTE["cyan-200"],
		c_action_04: PALETTE["cyan-100"],
		c_icon_inverted: PALETTE["cyan-off-white-200"],

		c_workspace_nav_circle1_start: "#00A3FF",
		c_workspace_nav_circle1_end: "#57D7FF",
		c_workspace_nav_circle2_start: "#FF8E26",
		c_workspace_nav_circle2_end: "#FAB245",
		c_workspace_nav_circle3_start: "#00A3FF",
		c_workspace_nav_circle3_end: "#57D7FF",
	},
	coral: {
		c_brand_primary: PALETTE["coral-500"],
		c_brand_off_white: PALETTE["coral-off-white-500"],
		c_text_link: PALETTE["coral-500"],
		c_bg_02: PALETTE["coral-off-white-500"],
		c_bg_03: PALETTE["coral-off-white-200"],
		c_bg_04: PALETTE["coral-off-white-100"],
		c_bg_05: PALETTE["coral-off-white-600"],
		c_action_01: PALETTE["coral-500"],
		c_action_02: PALETTE["coral-300"],
		c_action_03: PALETTE["coral-700"],
		c_action_focus: PALETTE["coral-200"],
		c_action_04: PALETTE["coral-100"],
		c_icon_inverted: PALETTE["coral-off-white-200"],

		c_workspace_nav_circle1_start: "#FF9D9C",
		c_workspace_nav_circle1_end: "#E17B7A",
		c_workspace_nav_circle2_start: "#FF6B00",
		c_workspace_nav_circle2_end: "#D16415",
		c_workspace_nav_circle3_start: "#FF9D9C",
		c_workspace_nav_circle3_end: "#E17B7A",
	},
	olive: {
		c_brand_primary: PALETTE["olive-500"],
		c_brand_off_white: PALETTE["olive-off-white-500"],
		c_text_link: PALETTE["olive-500"],
		c_bg_02: PALETTE["olive-off-white-500"],
		c_bg_03: PALETTE["olive-off-white-200"],
		c_bg_04: PALETTE["olive-off-white-100"],
		c_bg_05: PALETTE["olive-off-white-600"],
		c_action_01: PALETTE["olive-500"],
		c_action_02: PALETTE["olive-300"],
		c_action_03: PALETTE["olive-700"],
		c_action_focus: PALETTE["olive-200"],
		c_action_04: PALETTE["olive-100"],
		c_icon_inverted: PALETTE["olive-off-white-200"],

		c_workspace_nav_circle1_start: "#507916",
		c_workspace_nav_circle1_end: "#5A662B",
		c_workspace_nav_circle2_start: "#FFA361",
		c_workspace_nav_circle2_end: "#E6B171",
		c_workspace_nav_circle3_start: "#507916",
		c_workspace_nav_circle3_end: "#5A662B",
	},
	tangerine: {
		c_brand_primary: PALETTE["tangerine-500"],
		c_brand_off_white: PALETTE["tangerine-off-white-500"],
		c_text_link: PALETTE["tangerine-500"],
		c_bg_02: PALETTE["tangerine-off-white-500"],
		c_bg_03: PALETTE["tangerine-off-white-200"],
		c_bg_04: PALETTE["tangerine-off-white-100"],
		c_bg_05: PALETTE["tangerine-off-white-600"],
		c_action_01: PALETTE["tangerine-500"],
		c_action_02: PALETTE["tangerine-300"],
		c_action_03: PALETTE["tangerine-700"],
		c_action_focus: PALETTE["tangerine-200"],
		c_action_04: PALETTE["tangerine-100"],
		c_icon_inverted: PALETTE["tangerine-off-white-200"],

		c_workspace_nav_circle1_start: "#F5510B",
		c_workspace_nav_circle1_end: "#FFECDF",
		c_workspace_nav_circle2_start: "#FFF626",
		c_workspace_nav_circle2_end: "#F0D800",
		c_workspace_nav_circle3_start: "#F5510B",
		c_workspace_nav_circle3_end: "#FFECDF",
	},
	indigo: {
		c_brand_primary: PALETTE["indigo-500"],
		c_brand_off_white: PALETTE["off-white-500"],
		c_text_link: PALETTE["indigo-500"],
		c_bg_02: PALETTE["off-white-500"],
		c_bg_03: PALETTE["off-white-200"],
		c_bg_04: PALETTE["off-white-100"],
		c_bg_05: PALETTE["off-white-600"],
		c_action_01: PALETTE["indigo-500"],
		c_action_02: PALETTE["indigo-300"],
		c_action_03: PALETTE["indigo-700"],
		c_action_focus: PALETTE["indigo-200"],
		c_action_04: PALETTE["indigo-100"],
		c_icon_inverted: PALETTE["off-white-200"],

		c_workspace_nav_circle1_start: "#1F2F80",
		c_workspace_nav_circle1_end: "#E8E8FF",
		c_workspace_nav_circle2_start: "#FFE661",
		c_workspace_nav_circle2_end: "#E6CD71",
		c_workspace_nav_circle3_start: "#1F2F80",
		c_workspace_nav_circle3_end: "#E8E8FF",
	},
	amber: {
		c_brand_primary: PALETTE["yellow-500"],
		c_brand_off_white: PALETTE["amber-off-white-500"],
		c_text_link: PALETTE["yellow-500"],
		c_bg_02: PALETTE["amber-off-white-500"],
		c_bg_03: PALETTE["amber-off-white-200"],
		c_bg_04: PALETTE["amber-off-white-100"],
		c_bg_05: PALETTE["amber-off-white-600"],
		c_action_01: PALETTE["yellow-500"],
		c_action_02: PALETTE["yellow-300"],
		c_action_03: PALETTE["yellow-700"],
		c_action_focus: PALETTE["yellow-200"],
		c_action_04: PALETTE["yellow-100"],
		c_icon_inverted: PALETTE["amber-off-white-200"],

		c_workspace_nav_circle1_start: "#F59E0B",
		c_workspace_nav_circle1_end: "#DBBD54",
		c_workspace_nav_circle2_start: "#FF8E26",
		c_workspace_nav_circle2_end: "#F05600",
		c_workspace_nav_circle3_start: "#F59E0B",
		c_workspace_nav_circle3_end: "#DBBD54",
	},
	grey: {
		c_brand_primary: PALETTE["neutral-500"],
		c_brand_off_white: PALETTE["neutral-20"],
		c_text_link: PALETTE["neutral-500"],
		c_bg_02: PALETTE["neutral-20"],
		c_bg_03: PALETTE["neutral-10"],
		c_bg_04: PALETTE["neutral-5"],
		c_bg_05: PALETTE["neutral-50"],
		c_action_01: PALETTE["neutral-500"],
		c_action_02: PALETTE["neutral-300"],
		c_action_03: PALETTE["neutral-700"],
		c_action_focus: PALETTE["neutral-200"],
		c_action_04: PALETTE["neutral-100"],
		c_icon_inverted: PALETTE["neutral-30"],

		c_workspace_nav_circle1_start: "#393939",
		c_workspace_nav_circle1_end: "#878787",
		c_workspace_nav_circle2_start: "#FFFFFF",
		c_workspace_nav_circle2_end: "#FFFFFF",
		c_workspace_nav_circle3_start: "#393939",
		c_workspace_nav_circle3_end: "#878787",
	},
} as const;

export type ThemeName = keyof typeof UI_THEMES;
export type ThemeConfig = Record<ThemableColorKey, (typeof PALETTE)[keyof typeof PALETTE]>;
