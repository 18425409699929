import { useEventContext } from "../../../hooks/useEventContext";
import { NoteReactionParams } from "@salesdesk/salesdesk-schemas";
import { LazyNotePayload } from "./LazyNotePayload";

export function NoteReactionPayload() {
	const { event } = useEventContext();
	const { emoji, noteId, noteOwnedBy } = event.params as NoteReactionParams;

	return <LazyNotePayload recordId={event.record_id} noteId={noteId} createdById={noteOwnedBy} emoji={emoji} />;
}
