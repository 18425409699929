import { useMemo } from "react";
import clsx from "clsx";

import { FilePreviewProps } from "../../../types";
import { convertFileToObjectURL } from "../../../utils";
import { tw } from "../../../../../utils/tailwind-helpers";
import { S3Image } from "../../../../../components/S3Image/S3Image";

interface ImagePreviewProps extends FilePreviewProps {
	isIcon?: boolean;
}

export function ImagePreview({ file, onOpenPreviewClick, isIcon, variant }: ImagePreviewProps) {
	const fileAsURL = useMemo(() => {
		if (!(file instanceof File)) {
			return null;
		}
		return convertFileToObjectURL(file);
	}, [file]);

	const cover = isIcon || variant === "xs";

	const classes = clsx(tw`h-full w-full`, cover ? tw`object-cover` : tw`object-contain`, isIcon && tw`rounded-xs`);

	if (file instanceof File) {
		return <img className={classes} alt={file.name} src={fileAsURL || ""} />;
	}

	const image = <S3Image className={classes} file={file.fileId} />;

	if (isIcon || !onOpenPreviewClick) {
		return image;
	}

	return (
		<button
			className="h-full w-full"
			onClick={(e) => {
				if (onOpenPreviewClick) {
					e.stopPropagation();
					onOpenPreviewClick();
				}
			}}
			aria-label="Open expanded image view"
		>
			{image}
		</button>
	);
}
