import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { isAssetObject, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useSdFileByFieldName } from "../../../../../files/hooks/useSdFileByFieldName";
import { mAssetDef } from "@salesdesk/salesdesk-model";
import { AiDisclaimer } from "../../../../../mediaAnalysis/components/AnalysisView/llm";

interface MeetingSummaryProps {
	sdObject?: SDObject;
	sdRecord?: SDRecord;
}

export function MeetingSummary({ sdObject, sdRecord }: MeetingSummaryProps) {
	const isAsset = sdRecord && sdObject && isAssetObject(sdObject);

	const { sdFile } = useSdFileByFieldName(
		isAsset ? sdObject : undefined,
		isAsset ? sdRecord : undefined,
		mAssetDef.FILE_FIELD_NAME
	);

	if (!isAsset || !sdFile || !sdFile.summary) {
		return null;
	}

	return (
		<AiDisclaimer className="border-t-c_border_regular mt-6 border-t pt-6">
			<div className="flex flex-col gap-3">
				<div className="text-label-sm text-c_text_secondary flex gap-2">
					<Icon icon={ICONS.clipboardText} className="flex items-center" size="sm" />
					<div className="text-label-sm">Meeting summary</div>
				</div>
				<div className="mx-4 my-2">{sdFile.summary}</div>
			</div>
		</AiDisclaimer>
	);
}
