import { useEffect, useState } from "react";

import { ThemeName } from "@salesdesk/salesdesk-ui";

import { Divider } from "../../../../../../components/Divider/Divider";
import { RecordForm, StickyFormControls } from "../../../../../records";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { InternalAreaHeader } from "../../common/InternalAreaHeader";
import { InternalWorkspaceAreaContainer } from "../../../InternalWorkspaceAreaContainer";
import { useWorkspaceSettingsForm } from "../hooks/useWorkspaceSettingsForm";
import { useThemeProviderContext } from "@salesdesk/daisy-ui";
import { ThemeSelectorField } from "../../../../../fields";

export function WorkspaceSettings() {
	const { setTheme } = useThemeProviderContext();

	const { workspaceObject, isLoading } = useWorkspaceContext();
	const { formProps, themeField, isLoading: isLoadingSettings, uploadProgressStatus } = useWorkspaceSettingsForm();

	const [themeToPreview, setThemeToPreview] = useState<ThemeName>();

	// Updates the current theme to the one selected in the workspace theme selector.
	// Note that we don't have to worry about reseting the theme ourselves if the user
	// leaves the page without saving, as this is handled by the AppThemeHandler.
	useEffect(() => {
		if (themeToPreview) {
			setTheme(themeToPreview);
		}
	}, [themeToPreview, setTheme]);

	if (!formProps.fields.length || !themeField || isLoading) return null;

	const workspaceDisplayName = workspaceObject?._displayName ?? "Workspace";
	return (
		<InternalWorkspaceAreaContainer>
			<div className="mx-auto flex w-10/12 flex-col pb-12">
				<InternalAreaHeader height={88} pageName="Settings" />
				<div className="mx-auto mt-6 flex max-w-[440px] flex-col gap-4">
					<span className="text-label-sm">Basic info</span>
					<RecordForm {...formProps}>
						<div className="-mt-4 flex flex-col gap-4">
							<Divider className="mb-4" />
							<span className="text-label-sm">{workspaceDisplayName} theme</span>
							<ThemeSelectorField themeField={themeField} setThemeToPreview={setThemeToPreview} />
						</div>
						<StickyFormControls
							formId={formProps.id}
							isLoading={isLoadingSettings}
							defaultValues={formProps.defaultValues}
							submitDisabled={uploadProgressStatus === "in_progress"}
						/>
					</RecordForm>
				</div>
			</div>
		</InternalWorkspaceAreaContainer>
	);
}
