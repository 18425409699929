import { Dispatch, MutableRefObject, SetStateAction, useCallback, useRef } from "react";

import { RecordsSearchResponse, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useLazyGetRecordsQuery, useLazySearchRecordsQuery } from "../../api/recordsApi";
import {
	EMPTY_SEARCH_RESULTS,
	InfiniteRecordSearchParams,
	RECORD_CHANGE_EVENT,
	RecordChangeEvent,
	RecordChangeType,
} from "../types";
import {
	addRecordIdFilterToSearchParams,
	combineSearchResults,
	filterOutSearchResults,
	updateSearchResultsInPlace,
} from "../utils";
import { useGetRelevantRecordsFromChangeEvent } from "./useGetRelevantRecordsFromChangeEvent";

export function useRecordChangeEventListener(
	setSDRecordsRef: MutableRefObject<Dispatch<SetStateAction<SDRecord[]>> | undefined>,
	setResultHits: Dispatch<SetStateAction<RecordsSearchResponse | undefined>>,
	sdObjectFilter?: SDObject["_id"] | SDObject["_id"][],
	addRecordsOnUpdateEvent = true
) {
	const currentSearchId = useRef<number>(0);

	const [searchRecords] = useLazySearchRecordsQuery();
	const [getRecords] = useLazyGetRecordsQuery();

	const getRelevantRecordsFromChangeEvent = useGetRelevantRecordsFromChangeEvent(sdObjectFilter);

	return useCallback(
		(searchId: number, searchParams: InfiniteRecordSearchParams) => {
			currentSearchId.current = searchId;

			const handlerSearchId = searchId;

			const onRecordChangeEvent = (event: CustomEvent<RecordChangeEvent>) => {
				const { type, isOptimistic } = event.detail;

				const { sdRecords, recordIds, isIDArray } = getRelevantRecordsFromChangeEvent(event);

				if (handlerSearchId !== currentSearchId.current || !recordIds.length) {
					return;
				}

				if (type === RecordChangeType.UPDATE && isOptimistic && sdRecords.length) {
					setSDRecordsRef.current?.((prevRecords) => updateSearchResultsInPlace(prevRecords, sdRecords));
					return;
				}

				// Update the result hits now that the hit count may have changed
				searchRecords({ ...searchParams, from: 0, size: 1 }).then(({ data: resultHits, isError }) => {
					if (isError || !resultHits || handlerSearchId !== currentSearchId.current) {
						return;
					}
					setResultHits(resultHits);
				});

				if (type === RecordChangeType.DELETE) {
					setSDRecordsRef.current?.((prevRecords) => filterOutSearchResults(prevRecords, recordIds));
					return;
				}

				// Checks which records should be updated/removed/added based on the search's params
				searchRecords({
					...addRecordIdFilterToSearchParams(searchParams, recordIds),
					from: 0,
					size: recordIds.length,
				}).then(async ({ data: resultHits, isError }) => {
					if (isError || !resultHits || handlerSearchId !== currentSearchId.current) {
						return;
					}

					const recordIdsToUpdate: SDRecord["_id"][] = [];
					const recordIdsToRemove: SDRecord["_id"][] = [];

					recordIds.forEach((recordId) => {
						if (resultHits.hits.some(({ source }) => source.id === recordId)) {
							recordIdsToUpdate.push(recordId);
						} else {
							recordIdsToRemove.push(recordId);
						}
					});

					// If there are records to update on the front-end and we have an ID array
					// we need to fetch the new state of those records from the API
					if (isIDArray && recordIdsToUpdate.length) {
						const { data: fetchedUpdatedRecords = EMPTY_SEARCH_RESULTS } = await getRecords(recordIdsToUpdate);
						sdRecords.push(...fetchedUpdatedRecords);
					}

					const recordsToUpdate: SDRecord[] = sdRecords.filter((record) => recordIdsToUpdate.includes(record._id));

					if (type === RecordChangeType.CREATE && addRecordsOnUpdateEvent) {
						setSDRecordsRef.current?.((prevRecords) => combineSearchResults(prevRecords, recordsToUpdate, false));

						return;
					}

					if (type === RecordChangeType.UPDATE || type === RecordChangeType.ASSOCIATION_CHANGE) {
						setSDRecordsRef.current?.((prevRecords) => {
							// Filters out all the ids to remove and then updates the new records in place or adds them to the bottom
							const filteredResults = filterOutSearchResults(prevRecords, recordIdsToRemove);

							if (addRecordsOnUpdateEvent) {
								return combineSearchResults(filteredResults, recordsToUpdate, false);
							} else {
								return updateSearchResultsInPlace(filteredResults, recordsToUpdate);
							}
						});
					}
				});
			};

			document.addEventListener(RECORD_CHANGE_EVENT, onRecordChangeEvent as EventListener);

			return () => {
				document.removeEventListener(RECORD_CHANGE_EVENT, onRecordChangeEvent as EventListener);
			};
		},
		[
			addRecordsOnUpdateEvent,
			getRecords,
			getRelevantRecordsFromChangeEvent,
			searchRecords,
			setResultHits,
			setSDRecordsRef,
		]
	);
}
