import { useEffect, useState } from "react";
import { RemoteParticipant, Room } from "twilio-video";

export function useRemoteParticipants(
	room: Room | null,
	updateParticipantVolume: (identity: string, volume?: number | undefined) => void
) {
	const [remoteParticipants, setRemoteParticipants] = useState(Array.from(room?.participants.values() ?? []));

	useEffect(() => {
		if (!room) {
			return;
		}

		setRemoteParticipants(Array.from(room.participants.values() ?? []));

		const participantConnected = (participant: RemoteParticipant) =>
			setRemoteParticipants((prevParticipants) => [...prevParticipants, participant]);

		const participantDisconnected = (participant: RemoteParticipant) => {
			setRemoteParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));

			// Clears the volume for a participant when they leave the call
			setTimeout(() => updateParticipantVolume(participant.identity, undefined), 100);
		};

		room.on("participantConnected", participantConnected);
		room.on("participantDisconnected", participantDisconnected);

		return () => {
			room.off("participantConnected", participantConnected);
			room.off("participantDisconnected", participantDisconnected);
		};
	}, [room, updateParticipantVolume]);

	return remoteParticipants;
}
