import { useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { AlertDialog } from "../../../../../Dialog";
import { useRecordCall } from "../hooks/useRecordCall";
import { Icon, Button, Tooltip } from "@salesdesk/daisy-ui";

export function RecordingControls() {
	const { toggleRoomRecording, canRecord, isRecording, isLoading, recordingDuration } = useRecordCall();

	const [isDialogOpen, setIsDialogOpen] = useState(false);

	let recordingControls = null;

	if (isRecording) {
		recordingControls = (
			<div className="bg-c_bg_02 border-c_bg_02 text-label-sm text-c_danger_02 flex items-center gap-2 rounded-full border px-3 py-2">
				<Icon icon={ICONS.record} variant="fill" className="flex animate-pulse" />
				Rec
				{canRecord ? (
					<>
						{recordingDuration ? (
							<div className="text-c_text_secondary ml-2 w-[56px] text-left">{recordingDuration}</div>
						) : null}
						<Tooltip text="Stop recording">
							<Button
								isLoading={isLoading}
								variant="text"
								size="xs"
								startIcon={ICONS.stop}
								onClick={() => setIsDialogOpen(true)}
								iconVariant="fill"
							/>
						</Tooltip>
					</>
				) : null}
			</div>
		);
	} else if (canRecord) {
		// ID used for identifying the start recording button in the userflow `Video Call - On Boarding` flow.
		recordingControls = (
			<Button
				id="userflow-start-recording-button"
				variant="secondary"
				disabled={isLoading}
				onClick={() => setIsDialogOpen(true)}
				size="sm"
				startIcon={ICONS.record}
			>
				Start recording
			</Button>
		);
	}

	return (
		<>
			{recordingControls}
			<AlertDialog
				open={isDialogOpen}
				onOpenChange={setIsDialogOpen}
				title={isRecording ? "Stop recording?" : "Is everyone ready?"}
				message={
					isRecording
						? "The recording and transcript will be saved to your Assets on SalesDesk."
						: "Remember to let all participants know that you are starting recording, including partipants who join late. The meeting will be recorded and transcribed."
				}
			>
				<div className="flex items-center justify-end gap-3">
					<Button variant="secondary" size="lg" onClick={() => setIsDialogOpen(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							toggleRoomRecording(!isRecording);
							setIsDialogOpen(false);
						}}
						variant="primary"
						size="lg"
						disabled={!canRecord}
						isLoading={isLoading}
					>
						{isRecording ? "Stop" : "Start"}
					</Button>
				</div>
			</AlertDialog>
		</>
	);
}
