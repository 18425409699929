import { mAssetDef, RecordEventSummary } from "@salesdesk/salesdesk-model";
import { useEventContext } from "../../../hooks/useEventContext";
import { FilePreview, SkeletonCompactFilePreview } from "../../../../files";
import { useGetRecordWithObjectByRecordId } from "../../../../../hooks";
import { useSdFileByFieldName } from "../../../../files/hooks/useSdFileByFieldName";
import { EventRecordLink } from "../EventRecordLink";

export function FilePresentationPayload() {
	const { event } = useEventContext();
	const params = event.params || {};
	const assetRecord = params.assetRecord as RecordEventSummary;

	const { sdObject, sdRecord, isLoading: isLoadingRecord } = useGetRecordWithObjectByRecordId(assetRecord?.id);
	const { sdFile, isLoading: isLoadingFile } = useSdFileByFieldName(sdObject, sdRecord, mAssetDef.FILE_FIELD_NAME);

	if ((!sdFile && !isLoadingFile) || (!sdRecord && !isLoadingRecord) || !sdObject) {
		return null;
	}

	return (
		<div className="flex flex-col gap-2">
			<EventRecordLink
				sdObject={sdObject}
				sdRecord={sdRecord}
				workspaceId={event.workspace_id}
				isLoading={isLoadingRecord}
			/>
			{isLoadingFile || !sdFile ? <SkeletonCompactFilePreview /> : <FilePreview file={sdFile} compact />}
		</div>
	);
}
