import { useMemo } from "react";

import { mTaskDef, mAssetDef, mLeadDef, mOpportunityDef, mIssueDef } from "@salesdesk/salesdesk-model";
import { SDObject, getSDObjectSystemSyncOptionFieldOptions } from "@salesdesk/salesdesk-schemas";

export function useAssociationFilters(associationObject: SDObject) {
	return useMemo(() => {
		const getFieldOptions = (fieldName: string) =>
			getSDObjectSystemSyncOptionFieldOptions(associationObject, fieldName).options;

		switch (associationObject._id) {
			case mTaskDef.ID:
				return {
					default: "all",
					title: "Filter by status",
					isMultiple: true,
					options: getFieldOptions(mTaskDef.STATUS_FIELD_NAME),
				};
			case mIssueDef.ID:
				return {
					default: "all",
					title: "Filter by status",
					isMultiple: true,
					options: getFieldOptions(mIssueDef.STATUS_FIELD_NAME),
				};
			case mLeadDef.ID:
				return {
					title: "Filter by status",
					isMultiple: true,
					options: getFieldOptions(mLeadDef.STATUS_FIELD_NAME),
				};
			case mOpportunityDef.ID:
				return {
					title: "Filter by stage",
					isMultiple: true,
					options: getFieldOptions(mOpportunityDef.STAGE_FIELD_NAME),
				};
			case mAssetDef.ID:
				return {
					title: "Filter by type",
					isMultiple: true,
					options: getFieldOptions(mAssetDef.MEDIA_TYPE_FIELD_NAME),
				};
			default:
				return null;
		}
	}, [associationObject]);
}
