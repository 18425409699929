import { asConst } from "json-schema-to-ts";
import { mObjectDef } from "@salesdesk/salesdesk-model";

// TODO: SAL-1254  This should be consistent with fieldCreationTypeSchema.  They are opposite!
// 1.  Add an enum and corresponding schema to components
// 2.  Reference the enum from client code
// 3.  Use the schema for all creation types (i.e. object, field, record)
export const creationTypeSchema = asConst({
	type: "integer",
	enum: Object.values(mObjectDef.CREATION_TYPE),
});
