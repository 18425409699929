import { useState, useCallback, useMemo } from "react";
import clone from "clone";
import { isEqual } from "lodash";

import { SDObject, Field, getAllSDObjectFields } from "@salesdesk/salesdesk-schemas";

import { moveElementToIndex, insertElementAtIndex } from "../../../../../utils";

export function useSDObjectState(sdObject: SDObject) {
	const [initialSdObject, setInitialSdObject] = useState<SDObject>(() => clone(sdObject));
	const [sdObjectState, setSDObjectState] = useState<SDObject>(() => clone(sdObject));
	const [newObjectFieldIds, setNewObjectFieldIds] = useState<number[]>([]);

	const wasOrderChanged = useMemo(() => {
		const initialOrder = getAllSDObjectFields(initialSdObject).map(({ _id }) => _id);
		const actualOrder = getAllSDObjectFields(sdObjectState).map(({ _id }) => _id);
		return !isEqual(initialOrder, actualOrder);
	}, [initialSdObject, sdObjectState]);

	const updateSDObjectFields = useCallback(
		(updatedSdObjectFields: Field[]) => {
			setSDObjectState((prevSdObjectState) => ({
				...prevSdObjectState,
				_dataDef: {
					...prevSdObjectState._dataDef,
					_children: updatedSdObjectFields,
				},
			}));
		},
		[setSDObjectState]
	);

	const onObjectFieldsFormUpdate = useCallback(
		(formValues: Record<string, any>) => {
			const sDObjectFields = getAllSDObjectFields(sdObjectState);

			const updatedObjectFields = sDObjectFields.map((objectField) => ({
				...objectField,
				...formValues[objectField._id],
			}));
			updateSDObjectFields(updatedObjectFields);
		},
		[sdObjectState, updateSDObjectFields]
	);

	const onObjectSettingsFormUpdate = useCallback(
		(formValue: Record<string, any>) => {
			setSDObjectState((prevSDObjectState) => ({ ...prevSDObjectState, ...formValue }));
		},
		[setSDObjectState]
	);

	const reorderSDObjectFields = useCallback(
		(startIndex: number, endIndex: number) => {
			const objectFields = getAllSDObjectFields(sdObjectState);

			updateSDObjectFields(moveElementToIndex(objectFields, startIndex, endIndex));
		},
		[sdObjectState, updateSDObjectFields]
	);

	const insertNewSDObjectField = useCallback(
		(field: Field, destinationIndex: number) => {
			const objectFields = getAllSDObjectFields(sdObjectState);
			setNewObjectFieldIds((prevFieldIds) => [...prevFieldIds, field._id]);

			updateSDObjectFields(insertElementAtIndex(objectFields, destinationIndex, field));
		},
		[sdObjectState, updateSDObjectFields]
	);

	const deleteSDObjectFieldById = useCallback(
		(fieldId: number) => {
			setNewObjectFieldIds((prevFieldIds) => prevFieldIds.filter((id) => id !== fieldId));
			updateSDObjectFields(getAllSDObjectFields(sdObjectState).filter(({ _id }) => _id !== fieldId));
		},
		[sdObjectState, updateSDObjectFields]
	);

	const resetSDObject = useCallback(
		(resetSDObjectValue?: SDObject) => {
			const newSdObject = resetSDObjectValue ? resetSDObjectValue : initialSdObject;
			setSDObjectState(newSdObject);
			setInitialSdObject(newSdObject);
			setNewObjectFieldIds([]);
		},
		[initialSdObject]
	);

	return {
		sdObjectState,
		hasChangedFields: wasOrderChanged || Boolean(newObjectFieldIds.length),
		onObjectFieldsFormUpdate,
		onObjectSettingsFormUpdate,
		reorderSDObjectFields,
		insertNewSDObjectField,
		deleteSDObjectFieldById,
		resetSDObject,
	};
}
