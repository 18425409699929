import { useEffect } from "react";
import userflow from "userflow.js";

import { useFullscreenToggle } from "../../../../../../hooks/ui/useFullscreenToggle";
import { VIDEO_CALL_CONTAINER_ID } from "../../../../utils";
import { CallControls, ParticipantAudioTracks } from "../../../common";
import { useMeetingPageContext } from "../../hooks";

import { CallView } from "./CallView";
import { TopBar } from "./TopBar";
import { VideoSidePanel } from "./VideoSidePanel";
import { useVideoCallLogicContext } from "../../../VideoCallProvider";
import { VideoCallToastHandler } from "../../../VideoCallEvents/components/VideoCallToastHandler";
import { VideoCallLoading } from "./VideoCallLoading";

export function VideoCallPage() {
	const { leaveMeeting } = useMeetingPageContext();
	const { room } = useVideoCallLogicContext();
	const { elementRef, isFullscreen, toggleFullscreen } = useFullscreenToggle();

	useEffect(() => {
		userflow.setResourceCenterLauncherHidden(true);

		return () => {
			userflow.setResourceCenterLauncherHidden(false);
		};
	}, []);

	// Triggers `join_meeting` event when the user has joined a meeting in the
	// video call page to start the `Video Call - On Boarding flow`.
	useEffect(() => {
		if (room) {
			userflow.track("join_meeting");
		}
	}, [room]);

	return (
		<div
			ref={elementRef}
			id={VIDEO_CALL_CONTAINER_ID}
			className="bg-c_bg_06 relative h-full max-h-full w-full overflow-hidden"
		>
			{!room ? <VideoCallLoading /> : null}
			<div className="flex h-full w-full">
				<div className="flex max-h-full w-full flex-col overflow-hidden">
					<TopBar isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
					<div className="h-full overflow-hidden">
						{/*
							Mounts the audio track for all participants in the room.
							Separate component so that the audio tracks will always be mounted, and will never be
							unnecessarily unmounted/mounted as the user switches between different views
						*/}
						<ParticipantAudioTracks />
						<CallView />
					</div>
					<CallControls leaveMeeting={leaveMeeting} />
					<VideoCallToastHandler />
				</div>
				<VideoSidePanel />
			</div>
		</div>
	);
}
