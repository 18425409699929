import { useMemo, useRef } from "react";
import { Editor } from "@tiptap/react";
import clsx from "clsx";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { formatDateTime } from "@salesdesk/salesdesk-utils";
import { addImagesToEditorOptimistically } from "../utils/SdFileImageExtension";

import { useDocumentEditorState } from "../hooks/useDocumentEditorState";
import { CommentPanel, DocumentCommentsProvider } from "../../DocumentComments";
import { DocumentEditor } from "./DocumentEditor";
import { DocumentEditorLoading } from "./DocumentEditorLoading";
import { DocumentDetailsBar } from "./DocumentDetailsBar";

interface DocumentEditorPanelProps {
	documentObject?: SDObject;
	documentRecord?: SDRecord;
	inWorkspaceContext?: boolean;
}

export function DocumentEditorPanel({ documentObject, documentRecord, inWorkspaceContext }: DocumentEditorPanelProps) {
	const editorRef = useRef<Editor | null>(null);
	const { editorContents, onEditorContentsChange, isSaving } = useDocumentEditorState(documentObject, documentRecord);

	const lastModified = useMemo(() => {
		if (documentRecord) {
			return formatDateTime(new Date(documentRecord.updatedAt));
		}
	}, [documentRecord]);

	if (!documentRecord || !documentObject || editorContents === null) {
		return <DocumentEditorLoading />;
	}

	const documentEditorContents = (
		<>
			<DocumentDetailsBar lastModified={lastModified} isSaving={isSaving} inWorkspaceContext={inWorkspaceContext} />
			<div className="flex h-full max-h-full w-full overflow-hidden">
				<div className={clsx("h-full max-h-full w-full overflow-auto pb-8 pt-5", !inWorkspaceContext && "pl-6")}>
					<div className="mx-auto min-h-full w-10/12">
						<DocumentEditor
							key={documentRecord._id}
							value={editorContents}
							onChange={onEditorContentsChange}
							editorRef={editorRef}
						/>
					</div>
				</div>
				<CommentPanel />
			</div>
		</>
	);

	return (
		<div
			className="flex h-full w-full flex-col gap-3"
			onDragOver={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			onDrop={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (editorRef.current) {
					addImagesToEditorOptimistically(editorRef.current, e.dataTransfer.files);
				}
			}}
		>
			<DocumentCommentsProvider editorRef={editorRef} documentRecord={documentRecord} editorContents={editorContents}>
				{documentEditorContents}
			</DocumentCommentsProvider>
		</div>
	);
}
