import { asConst } from "json-schema-to-ts";
import { idSchema } from "../components";
import { sdObjectAssociationCreateRequestSchema } from "./sdObjectAssociationCreateRequestSchema";
import { updatableSchema } from "../components";
import { AssociationType } from "@salesdesk/salesdesk-model";

export const sdObjectAssociationSchema = asConst({
	title: "Association Definition",
	description: "Definition of an association",
	type: "object",
	additionalProperties: false,
	required: [...sdObjectAssociationCreateRequestSchema.required, ...updatableSchema.required, "id", "type"],
	properties: {
		...sdObjectAssociationCreateRequestSchema.properties,
		...updatableSchema.properties,
		id: idSchema,
		type: {
			title: "Is System Association Definition",
			description: "Whether this is a system association definition",
			type: "string",
			enum: Object.values(AssociationType),
		},
	},
});
