import { isAssetObject, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mDocDef, mInteractionMeetingDef } from "@salesdesk/salesdesk-model";

import {
	CompactAssetPreview,
	CompactDocPreview,
	CompletableRecordPreview,
	CompactMeetingPreview,
} from "./recordSpecificPreviews";
import { CompactRecordPreviewContainer, CompactRecordPreviewIcon } from "./common";
import { getIsCompletableRecord } from "../../../../../completableRecord";

interface CompactRecordPreviewProps {
	sdRecord: SDRecord;
	sdObject: SDObject;
	variant?: "sm" | "xs";
}

export function CompactRecordPreview(props: CompactRecordPreviewProps) {
	const { sdObject } = props;

	if (isAssetObject(sdObject)) {
		return <CompactAssetPreview {...props} />;
	} else if (sdObject._id === mDocDef.ID) {
		return <CompactDocPreview {...props} />;
	} else if (getIsCompletableRecord(sdObject)) {
		return <CompletableRecordPreview {...props} />;
	} else if (sdObject._id === mInteractionMeetingDef.ID) {
		return <CompactMeetingPreview {...props} />;
	}

	return <CompactRecordPreviewContainer {...props} icon={<CompactRecordPreviewIcon icon={sdObject._icon} />} />;
}
