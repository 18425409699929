import { useCallback } from "react";
import { usePostFileMutation } from "../api/filesApi";
import { uploadFileToSignedURL } from "../utils/uploadFileToSignedURL";
import { FileType } from "@salesdesk/salesdesk-schemas";

export function useStoreFile(isPublic = false, fileType: FileType = FileType.UserAsset) {
	const [postFile] = usePostFileMutation();

	return useCallback(
		async (file: File, onProgress?: (uploadProgress: number) => void): Promise<number> => {
			if (onProgress) {
				onProgress(0);
			}
			const { fileId, signedUrl } = await postFile({ name: file.name, type: fileType, public: isPublic }).unwrap();
			await uploadFileToSignedURL(file, signedUrl, onProgress);
			return fileId;
		},
		[fileType, isPublic, postFile]
	);
}
