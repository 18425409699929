import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import {
	RecordsSearchRequest,
	RecordsSearchResponse,
	SDObject,
	SDRecord,
	SearchHighlight,
} from "@salesdesk/salesdesk-schemas";
import { createUniqueId } from "@salesdesk/salesdesk-utils";

import { EMPTY_HIGHLIGHT_MAP, EMPTY_SEARCH_RESULTS, InfiniteRecordSearchParams } from "../types";
import { addSDObjectFilterToSearchParams, combineSearchResults } from "../utils";
import { useSearchRecordsFn } from "./useSearchRecordsFn";
import { useRecordChangeEventListener } from "./useRecordChangeEventListener";

interface InfiniteRecordSearchOptions {
	limit?: number;
	sdRecords?: SDRecord[];
	onSDRecordsChange?: Dispatch<SetStateAction<SDRecord[]>>;
	sdObjectFilter?: SDObject["_id"] | SDObject["_id"][];
	startLoadingOnMount?: boolean;
	addRecordsOnUpdateEvent?: boolean;
}

export function useInfiniteRecordSearch({
	limit: initialLimit = 25,
	sdRecords: controlledSDRecords,
	onSDRecordsChange,
	sdObjectFilter,
	startLoadingOnMount = true,
	addRecordsOnUpdateEvent = true,
}: InfiniteRecordSearchOptions) {
	const [searchParams, setSearchParams] = useState<InfiniteRecordSearchParams>();

	const currentSearchId = useRef<number>(0);

	const [isLoadingNewSearchParams, setIsLoadingNewSearchParams] = useState(false);
	const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

	const [limit] = useState(initialLimit);
	const [offset, setOffset] = useState(0);

	const isUncontrolled = controlledSDRecords == null;
	const [uncontrolledSDRecords, setUncontrolledSDRecords] = useState<SDRecord[]>(EMPTY_SEARCH_RESULTS);

	const setSDRecordsRef = useRef<Dispatch<SetStateAction<SDRecord[]>>>();
	const sdRecords = isUncontrolled ? uncontrolledSDRecords : controlledSDRecords;
	setSDRecordsRef.current = !isUncontrolled && onSDRecordsChange ? onSDRecordsChange : setUncontrolledSDRecords;

	const [resultHits, setResultHits] = useState<RecordsSearchResponse>();
	const [resultHighlights, setResultHighlights] = useState<Record<number, SearchHighlight[]>>(EMPTY_HIGHLIGHT_MAP);
	const [resultParams, setResultParams] = useState<RecordsSearchRequest>();

	const initialiseRecordChangeEventListener = useRecordChangeEventListener(
		setSDRecordsRef,
		setResultHits,
		sdObjectFilter,
		addRecordsOnUpdateEvent
	);
	const searchRecords = useSearchRecordsFn();

	const [initialisingSearch, setInitialisingSearch] = useState(startLoadingOnMount);

	useEffect(() => {
		if (isLoadingNewSearchParams) {
			setInitialisingSearch(false);
		}
	}, [isLoadingNewSearchParams]);

	// Want to set loading the moment search params are updated
	const updateSearchParams = useCallback(
		(newSearchParams: InfiniteRecordSearchParams) => {
			setSearchParams(addSDObjectFilterToSearchParams(newSearchParams, sdObjectFilter));
			setIsLoadingNewSearchParams(true);
		},
		[sdObjectFilter]
	);

	useEffect(() => {
		let active = true;
		let recordChangeEventListenerCleanup: () => void | undefined;

		if (!searchParams) {
			return;
		}

		setIsLoadingNewSearchParams(true);
		setIsLoadingNextPage(false);

		setOffset(0);
		currentSearchId.current = createUniqueId();

		searchRecords({ ...searchParams, from: 0, size: limit }).then(({ searchHits, searchResults, highlightsMap }) => {
			if (!active) {
				return;
			}

			setIsLoadingNewSearchParams(false);

			setResultHits(searchHits);
			setSDRecordsRef.current?.(searchResults);
			setResultHighlights(highlightsMap);
			setResultParams(searchParams);

			recordChangeEventListenerCleanup = initialiseRecordChangeEventListener(currentSearchId.current, searchParams);
		});

		// Used to prevent race conditions
		return () => {
			active = false;

			if (recordChangeEventListenerCleanup) {
				recordChangeEventListenerCleanup();
			}
		};
	}, [initialiseRecordChangeEventListener, limit, searchParams, searchRecords]);

	const hitCount = resultHits?.hitCount || 0;
	const isLastPage = hitCount <= offset + limit;

	const loadNextPage = useCallback(() => {
		if (isLastPage || searchParams == null) {
			return;
		}

		const newOffset = offset + limit;
		setOffset(newOffset);
		setIsLoadingNextPage(true);

		const currentSearchParamsId = currentSearchId.current;

		searchRecords({ ...searchParams, from: newOffset, size: limit }).then(
			({ searchHits, searchResults, highlightsMap }) => {
				// Ignore if the new page is not for the current search params
				if (currentSearchParamsId !== currentSearchId.current) {
					return;
				}

				setIsLoadingNextPage(false);

				setSDRecordsRef.current?.((previousResults) => {
					return combineSearchResults(previousResults, searchResults);
				});

				setResultHits(searchHits);
				setResultHighlights(highlightsMap);
				setResultParams(searchParams);
			}
		);
	}, [isLastPage, limit, offset, searchParams, searchRecords]);

	return {
		sdRecords,
		updateSearchParams,
		loadNextPage,
		isLastPage,
		isLoadingNewSearchParams: initialisingSearch || isLoadingNewSearchParams,
		isLoadingNextPage,

		resultHits,
		resultHighlights,
		resultParams,
	};
}
