import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { ImportJobStatus } from "@salesdesk/salesdesk-schemas";

import { PATHS, useStableNavigate } from "../../../routes";
import { useGetMyImportJobsQuery } from "../api/importApi";
import { useImportContext } from "./useImportContext";

export function useCheckRunningImportJobs() {
	const { sdObject, setActualImportJob } = useImportContext();
	const navigate = useStableNavigate();

	const { data: runningImportJobs, isLoading: isLoadingRunningImportJobs } = useGetMyImportJobsQuery(
		sdObject ? { objectId: sdObject._id, status: ImportJobStatus.Running } : skipToken,
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		if (sdObject && runningImportJobs && runningImportJobs.importJobs.length > 0) {
			setActualImportJob(runningImportJobs.importJobs[0]);
			navigate(PATHS.IMPORT_EXECUTION(sdObject));
		}
	}, [sdObject, runningImportJobs, navigate, setActualImportJob]);

	return isLoadingRunningImportJobs;
}
