import { useMemo } from "react";

import {
	AbilityAction,
	AbilitySubject,
	Field,
	SDObject,
	SDRecord,
	sdSubject,
	sdSubjectField,
} from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../auth";

export function useCanEditRecordField(field: Field, sdObject?: SDObject, sdRecord?: SDRecord) {
	const principal = useWebPrincipal();

	return useMemo(() => {
		const canEditRecord = sdRecord
			? principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Record, sdRecord))
			: false;

		if (!canEditRecord || !sdRecord || !sdObject) {
			return false;
		}

		return (
			field._editable &&
			principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Record, sdRecord), sdSubjectField(field._name))
		);
	}, [sdRecord, principal, sdObject, field]);
}
