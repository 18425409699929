import { RecordNotClauses, RecordSubQueryClauses, rsr, TimeRange } from "@salesdesk/salesdesk-schemas";
import {
	EmptyFilterDetails,
	TextFilterDetails,
	NumericFilterDetails,
	NumericRangeFilterDetails,
	DateFilterDetails,
	DateBetweenFilterDetails,
	DateTimeFilterDetails,
	DateTimeBetweenFilterDetails,
	SingleSelectFilterDetails,
	MultiSelectFilterDetails,
	BooleanFilterDetails,
	TimeRangeFilterDetails,
	TimeRangeBetweenFilterDetails,
	AssociationFilterDetails,
} from "../types";
import { endOfDay, endOfMinute, startOfDay, startOfMinute } from "date-fns";
import { getAssociationDetailsFromFilterTargetId, parseTimeRangeParam } from "./index";
import { getUnixTimestamp } from "@salesdesk/salesdesk-utils";
import {
	getRelativeDateFromDateFilterValue,
	parseDateFilterValueAndGetDateValue,
	parseDateFilterValue,
} from "./pseudoDates";

const is_not_empty: EmptyFilterDetails = {
	id: "is_not_empty",
	generateSearchQuery: (fieldID: string) => rsr.exists(fieldID),
};

const is_empty: EmptyFilterDetails = {
	id: "is_empty",
	generateSearchQuery: (fieldID: string) => negateSubQuery(is_not_empty.generateSearchQuery(fieldID)),
};

const t_contains: TextFilterDetails = {
	id: "t_contains",
	generateSearchQuery: (fieldID: string, value: string) => rsr.match(fieldID, value),
};

const t_not_contains: TextFilterDetails = {
	id: "t_not_contains",
	generateSearchQuery: (fieldID: string, value: string) =>
		negateSubQuery(t_contains.generateSearchQuery(fieldID, value)),
};

const is: TextFilterDetails = {
	id: "is",
	generateSearchQuery: (fieldID: string, value: string) => rsr.equals(fieldID, value),
};

const is_not: TextFilterDetails = {
	id: "is_not",
	generateSearchQuery: (fieldID: string, value: string) => negateSubQuery(is.generateSearchQuery(fieldID, value)),
};

const n_equals: NumericFilterDetails = {
	id: "n_equals",
	generateSearchQuery: (fieldID: string, value: number) => rsr.equals(fieldID, value),
};

const n_not_equals: NumericFilterDetails = {
	id: "n_not_equals",
	generateSearchQuery: (fieldID: string, value: number) => negateSubQuery(n_equals.generateSearchQuery(fieldID, value)),
};

const n_greater_than: NumericFilterDetails = {
	id: "n_greater_than",
	generateSearchQuery: (fieldID: string, value: number) => rsr.range(fieldID, { gt: value }),
};

const n_less_than: NumericFilterDetails = {
	id: "n_less_than",
	generateSearchQuery: (fieldID: string, value: number) => rsr.range(fieldID, { lt: value }),
};

const n_between: NumericRangeFilterDetails = {
	id: "n_between",
	generateSearchQuery: (fieldID: string, value1: number | undefined, value2: number | undefined) =>
		rsr.range(fieldID, { gte: value1, lte: value2 }),
};

const d_is: DateFilterDetails = {
	id: "d_is",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const dateValue = parseDateFilterValueAndGetDateValue(value);
		if (!dateValue) return;
		return rsr.range(fieldID, {
			gte: getUnixTimestamp(startOfDay(dateValue)),
			lte: getUnixTimestamp(endOfDay(dateValue)),
		});
	},
};

const d_not_is: DateFilterDetails = {
	id: "d_not_is",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const clause = d_is.generateSearchQuery(fieldID, value);
		if (!clause) return;
		return negateSubQuery(clause);
	},
};

const d_is_before: DateFilterDetails = {
	id: "d_is_before",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const dateValue = parseDateFilterValueAndGetDateValue(value);
		if (!dateValue) return;
		return rsr.range(fieldID, { lt: getUnixTimestamp(startOfDay(dateValue)) });
	},
};

const d_is_after: DateFilterDetails = {
	id: "d_is_after",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const dateValue = parseDateFilterValueAndGetDateValue(value);
		if (!dateValue) return;
		return rsr.range(fieldID, { gt: getUnixTimestamp(startOfDay(dateValue)) });
	},
};

const d_between: DateBetweenFilterDetails = {
	id: "d_between",
	generateSearchQuery: (fieldID: string, tsRangeValue: string) => {
		const dateRange = parseTimeRangeParam(tsRangeValue);
		return rsr.range(fieldID, {
			gte: dateRange?.start ? getUnixTimestamp(startOfDay(new Date(dateRange?.start))) : undefined,
			lte: dateRange?.end ? getUnixTimestamp(endOfDay(new Date(dateRange?.end))) : undefined,
		});
	},
};

const dt_is: DateTimeFilterDetails = {
	id: "dt_is",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const parsedValue = parseDateFilterValue(value);
		if (!parsedValue) return;
		const dateValue = getRelativeDateFromDateFilterValue(parsedValue);
		if (!dateValue) return;
		const isExactDate = parsedValue.option === "EXACT_DATE";
		return rsr.range(fieldID, {
			gte: getUnixTimestamp(isExactDate ? startOfMinute(dateValue) : startOfDay(dateValue)),
			lte: getUnixTimestamp(isExactDate ? endOfMinute(dateValue) : endOfDay(dateValue)),
		});
	},
};

const dt_not_is: DateTimeFilterDetails = {
	id: "dt_not_is",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const clause = dt_is.generateSearchQuery(fieldID, value);
		if (!clause) return;
		return negateSubQuery(clause);
	},
};

const dt_is_before: DateTimeFilterDetails = {
	id: "dt_is_before",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const parsedValue = parseDateFilterValue(value);
		if (!parsedValue) return;
		const dateValue = getRelativeDateFromDateFilterValue(parsedValue);
		if (!dateValue) return;
		return rsr.range(fieldID, {
			lt: getUnixTimestamp(parsedValue.option === "EXACT_DATE" ? startOfMinute(dateValue) : startOfDay(dateValue)),
		});
	},
};

const dt_is_after: DateTimeFilterDetails = {
	id: "dt_is_after",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const parsedValue = parseDateFilterValue(value);
		if (!parsedValue) return;
		const relativeDate = getRelativeDateFromDateFilterValue(parsedValue);
		if (!relativeDate) return;
		return rsr.range(fieldID, {
			gt: getUnixTimestamp(parsedValue.option === "EXACT_DATE" ? endOfMinute(relativeDate) : endOfDay(relativeDate)),
		});
	},
};

const dt_between: DateTimeBetweenFilterDetails = {
	id: "dt_between",
	generateSearchQuery: (fieldID: string, tsRangeValue: string) => {
		const timeRange = parseTimeRangeParam(tsRangeValue);
		return rsr.range(fieldID, {
			gte: timeRange?.start ? getUnixTimestamp(startOfMinute(new Date(timeRange.start))) : undefined,
			lte: timeRange?.end ? getUnixTimestamp(endOfMinute(new Date(timeRange.end))) : undefined,
		});
	},
};

const tr_is_before: TimeRangeFilterDetails = {
	id: "tr_is_before",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const dateValue = parseDateFilterValueAndGetDateValue(value);
		if (!dateValue) return;
		return rsr.range(`${fieldID}.${"start" satisfies keyof TimeRange}`, {
			lt: getUnixTimestamp(startOfMinute(dateValue)),
		});
	},
};

const tr_is_after: TimeRangeFilterDetails = {
	id: "tr_is_after",
	generateSearchQuery: (fieldID: string, value: number | string) => {
		const dateValue = parseDateFilterValueAndGetDateValue(value);
		if (!dateValue) return;
		return rsr.range(`${fieldID}.${"end" satisfies keyof TimeRange}`, {
			gt: getUnixTimestamp(endOfMinute(dateValue)),
		});
	},
};

const tr_between: TimeRangeBetweenFilterDetails = {
	id: "tr_between",
	generateSearchQuery: (fieldID: string, tsRangeValue: string) => {
		const timeRange = parseTimeRangeParam(tsRangeValue);
		if (!timeRange?.start || !timeRange?.end) {
			return [];
		}
		const filterStart = getUnixTimestamp(startOfMinute(new Date(timeRange.start ?? 0)));
		const filterEnd = getUnixTimestamp(endOfMinute(new Date(timeRange.end ?? 0)));
		return [
			rsr.range(`${fieldID}.${"start" satisfies keyof TimeRange}`, {
				lte: filterEnd,
			}),
			rsr.range(`${fieldID}.${"end" satisfies keyof TimeRange}`, {
				gte: filterStart,
			}),
		];
	},
};

const ss_is: SingleSelectFilterDetails = {
	id: "ss_is",
	generateSearchQuery: (fieldID: string, value: string[] | number[]) => rsr.equalsAnyOf(fieldID, value),
};

const ss_not_is: SingleSelectFilterDetails = {
	id: "ss_not_is",
	generateSearchQuery: (fieldID: string, value: string[] | number[]) =>
		negateSubQuery(ss_is.generateSearchQuery(fieldID, value)),
};

const ms_contains_any: MultiSelectFilterDetails = {
	id: "ms_contains_any",
	generateSearchQuery: (fieldID: string, value: string[] | number[]) => rsr.includeAnyOf(fieldID, value),
};

const ms_not_contains_any: MultiSelectFilterDetails = {
	id: "ms_not_contains_any",
	generateSearchQuery: (fieldID: string, value: string[] | number[]) =>
		negateSubQuery(ms_contains_any.generateSearchQuery(fieldID, value)),
};

const b_is: BooleanFilterDetails = {
	id: "b_is",
	generateSearchQuery: (fieldID: string, value: boolean) => {
		return rsr.equals(fieldID, value);
	},
};

const a_contains_any: AssociationFilterDetails = {
	id: "a_contains_any",
	generateSearchQuery: (filterTargetId: string, value: string[] | number[]) => {
		const { associationId, associationSide } = getAssociationDetailsFromFilterTargetId(filterTargetId);
		return rsr.hasAssociationToAnyRecordForObjectAssociation(associationId, value.map(Number), associationSide);
	},
};

const a_not_contains_any: AssociationFilterDetails = {
	id: "a_not_contains_any",
	generateSearchQuery: (filterTargetId: string, value: string[] | number[]) => {
		return negateSubQuery(a_contains_any.generateSearchQuery(filterTargetId, value));
	},
};

const a_contains_all: AssociationFilterDetails = {
	id: "a_contains_all",
	generateSearchQuery: (filterTargetId: string, value: string[] | number[]) => {
		const { associationId, associationSide } = getAssociationDetailsFromFilterTargetId(filterTargetId);
		return rsr.hasAssociationsToAllRecordsForObjectAssociation(associationId, value.map(Number), associationSide);
	},
};

const a_not_contains_all: AssociationFilterDetails = {
	id: "a_not_contains_all",
	generateSearchQuery: (filterTargetId: string, value: string[] | number[]) => {
		return negateSubQuery(a_contains_all.generateSearchQuery(filterTargetId, value));
	},
};

export const FILTERS = {
	is,
	is_not,
	t_contains,
	t_not_contains,
	n_equals,
	n_not_equals,
	n_greater_than,
	n_less_than,
	n_between,
	d_is,
	d_not_is,
	d_is_before,
	d_is_after,
	d_between,
	dt_is,
	dt_not_is,
	dt_is_before,
	dt_is_after,
	dt_between,
	tr_is_before,
	tr_is_after,
	tr_between,
	ss_is,
	ss_not_is,
	ms_contains_any,
	ms_not_contains_any,
	b_is,
	a_contains_any,
	a_not_contains_any,
	a_contains_all,
	a_not_contains_all,
	is_empty,
	is_not_empty,
};

function negateSubQuery(subQuery: RecordSubQueryClauses): RecordSubQueryClauses {
	return rsr.not(subQuery as RecordNotClauses);
}
