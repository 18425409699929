import { HasClaimConfig } from "../components/sections";

export const videoCallClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "videoCallRecord",
		label: "Record",
		descriptions: {
			hasClaim: "Can record a video call",
			hasNotClaim: "Cannot record a video call",
		},
	},
	{
		name: "videoCallShareScreen",
		label: "Share screen",
		descriptions: {
			hasClaim: "Can share screen during video call",
			hasNotClaim: "Cannot share screen during video call",
		},
	},
	{
		name: "videoCallPresentFile",
		label: "Present File",
		descriptions: {
			hasClaim: "Can present a file during a video call",
			hasNotClaim: "Cannot present a file during a video call",
		},
	},
	{
		name: "videoCallCreateTask",
		label: "Create Task",
		descriptions: {
			hasClaim: "Can create a task during a video call",
			hasNotClaim: "Cannot create a task during a video call",
		},
	},
];
