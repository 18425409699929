import { useState, useMemo, useCallback } from "react";

import {
	mAssetDef,
	mDocDef,
	mTaskDef,
	mIssueDef,
	mInteractionMeetingDef,
	mOpportunityDef,
	mProductDef,
	mContactDef,
	mAccountDef,
	mLeadDef,
	mObjectDef,
} from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";
import { sortByIds } from "@salesdesk/salesdesk-utils";

import { useWorkspaceContext } from "../../../../../../workspaces";
import { useGetObjectsQuery } from "../../../../../../objects/api/objectsApi";
import { SelectOption, SelectOptionId } from "../../../../../../inputs";

const EMPTY_ARRAY: SDObject[] = [];

const CRM_SELECTABLE_OBJECT_IDS = [
	mTaskDef.ID,
	mIssueDef.ID,
	mOpportunityDef.ID,
	mProductDef.ID,
	mContactDef.ID,
	mAccountDef.ID,
	mLeadDef.ID,
	mAssetDef.ID,
	mDocDef.ID,
	mInteractionMeetingDef.ID,
];

const WORKSPACE_SELECTABLE_OBJECT_IDS = [
	mAssetDef.ID,
	mDocDef.ID,
	mTaskDef.ID,
	mIssueDef.ID,
	mInteractionMeetingDef.ID,
];

export function useSelectableSDObjectTypesOptions(isWorkspaceChannel?: boolean) {
	const { workspaceId } = useWorkspaceContext();
	const inWorkspace = workspaceId != null;

	const { data: sdObjects = EMPTY_ARRAY, isLoading } = useGetObjectsQuery();

	const [selectedSDObjectId, setSelectedSDObjectId] = useState<number>(-1);

	const { selectableSDObjectsMap, selectableSDObjectTypeOptions } = useMemo(() => {
		const selectableObjects =
			inWorkspace || isWorkspaceChannel
				? sdObjects.filter(({ _id }) => WORKSPACE_SELECTABLE_OBJECT_IDS.includes(_id))
				: sdObjects;

		const selectableSDObjectsMap: Record<SelectOptionId, SDObject> = {};
		const systemSDObjectOptions: SelectOption[] = [];
		const customSDObjectOptions: SelectOption[] = [];

		selectableObjects.forEach((selectableObject) => {
			const selectableOption = {
				id: selectableObject._id,
				icon: selectableObject._icon,
				name: selectableObject._name,
			};

			if (
				selectableObject._type === mObjectDef.CREATION_TYPE.SYSTEM &&
				CRM_SELECTABLE_OBJECT_IDS.includes(selectableObject._id)
			) {
				systemSDObjectOptions.push(selectableOption);
			}

			if (selectableObject._type === mObjectDef.CREATION_TYPE.USER) {
				customSDObjectOptions.push(selectableOption);
			}

			selectableSDObjectsMap[selectableObject._id] = selectableObject;
		});

		const selectableSDObjectTypeOptions = [
			...sortByIds(systemSDObjectOptions, CRM_SELECTABLE_OBJECT_IDS),
			...customSDObjectOptions,
		];

		setSelectedSDObjectId(Number(selectableSDObjectTypeOptions[0].id));

		return {
			selectableSDObjectsMap,
			selectableSDObjectTypeOptions,
		};
	}, [sdObjects, inWorkspace, isWorkspaceChannel]);

	const onChange = useCallback((selectedOptionId?: SelectOptionId) => {
		if (!selectedOptionId) return;
		setSelectedSDObjectId(Number(selectedOptionId));
	}, []);

	return {
		selectedSDObjectId,
		onSDObjectOptionChange: onChange,
		selectableSDObjectsMap,
		selectableSDObjectTypeOptions,
		isLoading: isLoading,
	};
}
