import { DeleteFavoritesQuery, FavoritesBatchRequest, SDRecord } from "@salesdesk/salesdesk-schemas";

import { SDApi } from "../../api";
import { addRecordIdsToIdList, filterRecordIdsFromIdList } from "../utils/records";

export const favoritesApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getFavoritedRecords: builder.query<SDRecord["_id"][], void>({
				query: () => `/me/records/favorites`,
				providesTags: ["Favorites"],
			}),
			favoriteRecord: builder.mutation<void, SDRecord["_id"]>({
				query: (recordID) => ({
					url: `/me/records/favorites/${recordID}`,
					method: "PUT",
				}),
				onQueryStarted: async (recordID, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						favoritesApi.util.updateQueryData("getFavoritedRecords", undefined, (draft) => {
							Object.assign(draft, addRecordIdsToIdList(draft, [recordID]));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: ["Favorites"],
			}),
			unfavoriteRecord: builder.mutation<void, SDRecord["_id"]>({
				query: (recordID) => ({
					url: `/me/records/favorites/${recordID}`,
					method: "DELETE",
				}),
				onQueryStarted: async (recordID, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						favoritesApi.util.updateQueryData("getFavoritedRecords", undefined, (draft) => {
							Object.assign(draft, filterRecordIdsFromIdList(draft, [recordID]));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: ["Favorites"],
			}),
			batchFavoriteRecords: builder.mutation<void, SDRecord["_id"][]>({
				query: (recordIDs) => ({
					url: `/me/records/favorites/batch`,
					method: "PUT",
					body: recordIDs satisfies FavoritesBatchRequest, // Max 40 IDs
				}),
				onQueryStarted: async (recordIDs, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						favoritesApi.util.updateQueryData("getFavoritedRecords", undefined, (draft) => {
							Object.assign(draft, addRecordIdsToIdList(draft, recordIDs));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: ["Favorites"],
			}),
			batchUnfavoriteRecords: builder.mutation<void, SDRecord["_id"][]>({
				query: (recordIDs) => ({
					url: `/me/records/favorites`,
					method: "DELETE",
					params: { ids: recordIDs.join(",") } satisfies DeleteFavoritesQuery, // Max 40 IDs
				}),
				onQueryStarted: async (recordIDs, { dispatch, queryFulfilled }) => {
					const cacheUpdate = dispatch(
						favoritesApi.util.updateQueryData("getFavoritedRecords", undefined, (draft) => {
							Object.assign(draft, filterRecordIdsFromIdList(draft, recordIDs));
						})
					);

					// Undo the cache update if the query fails
					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: ["Favorites"],
			}),
		};
	},
});

export const {
	useGetFavoritedRecordsQuery,
	useLazyGetFavoritedRecordsQuery,
	useFavoriteRecordMutation,
	useUnfavoriteRecordMutation,
	useBatchFavoriteRecordsMutation,
	useBatchUnfavoriteRecordsMutation,
} = favoritesApi;
