import { useState } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { Role } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDeleteRoleMutation } from "../../../api/rolesApi";
import { Checkbox } from "../../../../../../inputs";
import { AlertDialog } from "../../../../../../Dialog";
import { useToast } from "../../../../../../Toasts";
import { PATHS, useStableNavigate } from "../../../../../../../routes";

interface DeleteRoleDialogProps {
	role: Role;
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onDeleteComplete?: () => void;
}

export function DeleteRoleDialog({ role, open, onOpenChange, onDeleteComplete }: DeleteRoleDialogProps) {
	const [isDeletingRecord, setIsDeletingRecord] = useState(false);
	const [deleteConfirmed, setDeleteConfirmed] = useState(false);
	const [deleteRole] = useDeleteRoleMutation();
	const toast = useToast();
	const navigate = useStableNavigate();

	const onCancel = () => {
		setDeleteConfirmed(false);
		onOpenChange(false);
	};

	const onDelete = () => {
		setIsDeletingRecord(true);

		deleteRole({ id: role.id })
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "role_deleted" });
				onOpenChange(false);
				navigate(PATHS.ACCESS_ROLES());
				if (onDeleteComplete) onDeleteComplete();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "role_deleted" });
			})
			.finally(() => {
				setIsDeletingRecord(false);
			});
	};

	return (
		<AlertDialog
			title={`Delete ${role.name} role`}
			open={open}
			message={
				<div className="flex flex-col gap-2">
					<p>
						Are you sure you want to delete this role? Users who only have this role will no longer have access. This
						action cannot be undone.
					</p>
					<label className="text-label flex gap-2 pt-6">
						<Checkbox
							id={`${role.id}`}
							onChange={() => setDeleteConfirmed((b) => !b)}
							value={deleteConfirmed}
							disabled={isDeletingRecord}
						/>
						Delete {role.name} role?
					</label>
				</div>
			}
			onOpenChange={onOpenChange}
		>
			<div className="flex items-center justify-end gap-3">
				<Button size="lg" variant="secondary" onClick={onCancel}>
					Cancel
				</Button>
				<Button
					isLoading={isDeletingRecord}
					disabled={!deleteConfirmed}
					onClick={onDelete}
					startIcon={ICONS.trash}
					variant="danger"
					size="lg"
				>
					Delete
				</Button>
			</div>
		</AlertDialog>
	);
}
