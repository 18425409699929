import { createContext, useContext } from "react";
import { BoardPropOnChange } from "../types";

export const BoardPropOnChangeContext = createContext<BoardPropOnChange | undefined>(undefined);

export function useBoardPropOnChangeContext(): BoardPropOnChange {
	const context = useContext(BoardPropOnChangeContext);
	if (!context) {
		throw new Error("useBoardPropOnChangeContext must be used within a BoardPropOnChangeProvider");
	}
	return context;
}
