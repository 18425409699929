import { asConst } from "json-schema-to-ts";
import { claimTypeSchema } from "./claimTypeSchema";

export const claimSchema = asConst({
	title: "Claim",
	description:
		"Provides information about the owner of the Claim.  The type of the Claim tells you what the value " +
		"of the Claim means.  For example, the type could be 'username' indicating the value is a username or it could be " +
		"a specific-permission' and the value would be the resource that the owner of the Claim has that " +
		"'specific-permission' on",
	type: "object",
	additionalProperties: false,
	required: ["type", "value"],
	properties: {
		type: claimTypeSchema,
		value: {
			title: "Value",
			description: "The value of the Claim.  The value only has any meaning in the context of the Claim Type",
			type: "string",
			nullable: false,
		},
	},
});
