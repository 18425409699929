import {
	Children,
	Fragment,
	ReactNode,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { throttle } from "../../../utils";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../Popover";
import { PanelTabListContext } from "../hooks/usePanelTabListContext";
import { PanelTabVariant } from "../types";
import { DropdownTabContext } from "../hooks/useDropdownTabContext";

interface PanelTabListProps {
	isResponsive?: boolean;
	children: ReactNode;
	variant?: PanelTabVariant;
}

const DROPDOWN_WIDTH = 100;

export function PanelTabList({ isResponsive = true, children, variant = "secondary" }: PanelTabListProps) {
	const tabListRef = useRef<HTMLDivElement>(null);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const [numberOfVisibleTabs, setNumberOfVisibleTabs] = useState<number>(() => Children.toArray(children).length);

	// Tracks whether the popover is currently displayed to the user, factoring in the CSS opacity
	// transition. Used to ensure tabs within the dropdown use the visible tab styling when closed
	// so that the width calculations for how many tabs can be visible are accurate.
	const [delayedPopoverVisible, setDelayedPopoverVisible] = useState(false);

	const dropdownButtonRef = useRef<HTMLButtonElement>(null);

	const previousVisibleWidthRef = useRef<number>(0);

	const calculateVisibleTabs = useCallback(() => {
		const tabListElement = tabListRef.current;
		if (!tabListElement || !isResponsive) {
			return;
		}

		let tabListWidth = tabListElement.offsetWidth;

		// If the tab list width is 0, it's most likely hidden (e.g. in a non visible mounted tab panel)
		// Use the last known visible width so that there is no layout shift when it is made visible again.
		if (!tabListWidth) {
			tabListWidth = previousVisibleWidthRef.current;
		} else {
			previousVisibleWidthRef.current = tabListWidth;
		}

		const tabElements = Array.from(tabListElement.querySelectorAll("button[role='tab']")) as HTMLElement[];

		let visibleTabs = tabElements.length;

		let widthOfAllTabs = tabElements.reduce((totalWidth, tab) => totalWidth + tab.offsetWidth, 0);

		// If all tabs fit within the tab list with no overflow then we display all tabs
		if (widthOfAllTabs < tabListWidth) {
			setNumberOfVisibleTabs(visibleTabs);
			return;
		}

		// Accounts for width of dropdown
		widthOfAllTabs += dropdownButtonRef.current?.offsetWidth || DROPDOWN_WIDTH;

		// Subtracts each visible tab until there is no more overflow
		for (let i = tabElements.length - 1; i >= 0; i--) {
			widthOfAllTabs -= tabElements[i].offsetWidth;
			visibleTabs--;

			if (widthOfAllTabs < tabListElement.offsetWidth) {
				break;
			}
		}

		setNumberOfVisibleTabs(visibleTabs);
	}, [isResponsive]);

	useLayoutEffect(() => {
		const tabListElement = tabListRef.current;
		if (!tabListElement) return;

		const observer = new ResizeObserver(throttle(calculateVisibleTabs, 100));
		observer.observe(tabListElement);
		return () => {
			// Cleans up the observer by unobserving all elements
			observer.disconnect();
		};
	}, [calculateVisibleTabs, children]);

	useEffect(() => {
		if (!delayedPopoverVisible) {
			calculateVisibleTabs();
		}
	}, [delayedPopoverVisible, calculateVisibleTabs]);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		if (isPopoverOpen) {
			setDelayedPopoverVisible(true);
		} else {
			timeoutId = setTimeout(() => {
				setDelayedPopoverVisible(false);
			}, 100);
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [isPopoverOpen]);

	const [visibleTabs, dropdownTabs] = useMemo(() => {
		const childrenArray = Children.toArray(children);

		if (!isResponsive) {
			return [childrenArray, []];
		}

		return [childrenArray.slice(0, numberOfVisibleTabs), childrenArray.slice(numberOfVisibleTabs)];
	}, [children, isResponsive, numberOfVisibleTabs]);

	return (
		<PanelTabListContext.Provider value={{ variant }}>
			<Tab.List
				ref={tabListRef}
				className="border-b-c_border_regular -m-0.5 mb-0 flex flex-shrink-0 overflow-clip border-b p-0.5 pb-0"
			>
				{visibleTabs.map((tab, index) => (
					<Fragment key={index}>{tab}</Fragment>
				))}
				{dropdownTabs.length ? (
					<Popover
						open={isPopoverOpen}
						onOpenChange={setIsPopoverOpen}
						keepPopoverMounted={true}
						hideWhenClosedButMounted={false}
						useFloatingPortal={false}
						placement="bottom-end"
					>
						<PopoverTrigger>
							<button
								ref={dropdownButtonRef}
								className={clsx(
									"text-label-sm focus-visible:ring-c_action_focus hover:bg-c_bg_03 active:bg-c_bg_03",
									"relative flex items-center gap-2 px-4 py-2 focus-visible:z-10 focus-visible:ring",
									variant === "primary"
										? "border-c_border_regular focus-visible:rounded=t-md rounded-t-md border-l border-r border-t hover:border-transparent"
										: "rounded-t-minimal focus-visible:rounded-minimal"
								)}
							>
								More
								<Icon className="text-c_action_01 flex" size="base" icon={ICONS.caretDown} />
							</button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverContainer
								className={clsx(isPopoverOpen ? "opacity-100" : "sr-only opacity-0", "transition-opacity")}
								onClick={() => setIsPopoverOpen(false)}
								onFocus={() => setIsPopoverOpen(true)}
							>
								<DropdownTabContext.Provider value={{ isInDropdown: isPopoverOpen || delayedPopoverVisible }}>
									{dropdownTabs.map((tab, index) => (
										<Fragment key={index}>{tab}</Fragment>
									))}
								</DropdownTabContext.Provider>
							</PopoverContainer>
						</PopoverContent>
					</Popover>
				) : null}
			</Tab.List>
		</PanelTabListContext.Provider>
	);
}
