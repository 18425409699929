import { asConst } from "json-schema-to-ts";
import { rangeClausePropertyValueSchema, rangeClauseValueSchema } from "../../../search";
import { SDRecordSummary } from "../../SDRecordSummary";
import { nameof } from "@salesdesk/salesdesk-utils";
import { FieldIdRegexp, TimeRangeFieldIdRegexp } from "./recordQueryClausePropertyValueSchema";

export const recordRangeClausePropertyValueSchema = asConst({
	...rangeClausePropertyValueSchema,
	additionalProperties: false,
	properties: {
		[nameof<SDRecordSummary>("id")]: rangeClauseValueSchema,
		[nameof<SDRecordSummary>("version")]: rangeClauseValueSchema,
		[nameof<SDRecordSummary>("_definitionVersion")]: rangeClauseValueSchema,
		[nameof<SDRecordSummary>("createdAt")]: rangeClauseValueSchema,
		[nameof<SDRecordSummary>("updatedAt")]: rangeClauseValueSchema,
	},
	patternProperties: {
		[FieldIdRegexp]: rangeClauseValueSchema,
		[TimeRangeFieldIdRegexp]: rangeClauseValueSchema,
	},
});
