import { Column } from "@tanstack/react-table";
import { TableRow } from "../../types";
import { Checkbox } from "../../../inputs";
import { useLinkedTablesState } from "../../hooks/useLinkedTables";

interface LinkedTableSelectAllHeaderCellProps<T> {
	column: Column<TableRow<T>>;
}

export function LinkedTableSelectAllHeaderCell<T>({ column }: LinkedTableSelectAllHeaderCellProps<T>) {
	const { tableById, toggleAllRowsSelected } = useLinkedTablesState() || {};
	if (!tableById || !toggleAllRowsSelected?.current) {
		return null;
	}

	const allTableRowsSelected = Object.values(tableById.current || {}).every((table) => {
		return table.getRowModel().rows.length === 0 || table.getIsAllRowsSelected();
	});

	const isIndeterminate =
		!allTableRowsSelected &&
		Object.values(tableById.current || {}).some(
			(table) => table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()
		);

	return (
		<div key={String(column.id)} className="flex">
			<Checkbox
				value={allTableRowsSelected}
				isIndeterminate={isIndeterminate}
				onChange={toggleAllRowsSelected.current}
			/>
		</div>
	);
}
