export const PALETTE = {
	"purple-100": "#D1B1EC",
	"purple-200": "#BA8CE2",
	"purple-300": "#9B57D5",
	"purple-500": "#6904C1",
	"purple-700": "#4B0389",
	"violet-500": "#9923A1",
	"cyan-100": "#B2EBF2",
	"cyan-200": "#80DEEA",
	"cyan-300": "#4DD0E1",
	"cyan-500": "#19AFD3",
	"cyan-600": "#0891B2",
	"cyan-700": "#137A92",
	"cyan-off-white-100": "#F8FEFF",
	"cyan-off-white-200": "#F0FDFF",
	"cyan-off-white-500": "#C8EFF5",
	"cyan-off-white-600": "#D3E5E8",
	"coral-100": "#FFCFCE",
	"coral-200": "#FFB8B6",
	"coral-300": "#FF9795",
	"coral-500": "#FF6461",
	"coral-700": "#B54745",
	"coral-off-white-100": "#FFF8F8",
	"coral-off-white-200": "#FFF5F4",
	"coral-off-white-500": "#F9DEDC",
	"coral-off-white-600": "#E8D4D3",
	"olive-100": "#D2E5B3",
	"olive-200": "#B8D981",
	"olive-300": "#9ABD4F",
	"olive-500": "#507916",
	"olive-700": "#344F10",
	"olive-off-white-100": "#FDFFF8",
	"olive-off-white-200": "#FCFFF4",
	"olive-off-white-500": "#F0F9DC",
	"olive-off-white-600": "#E3E8D3",
	"indigo-100": "#BEC3E0",
	"indigo-200": "#949BCE",
	"indigo-300": "#6972BB",
	"indigo-500": "#1F2F80",
	"indigo-700": "#0E1959",
	"green-200": "#BBF7D0",
	"green-400": "#4ADE80",
	"green-600": "#16A34A",
	"green-800": "#166534",
	"green-900": "#14532D",
	"yellow-100": "#FEF3C7",
	"yellow-200": "#FDE68A",
	"yellow-300": "#FCD34D",
	"yellow-400": "#FBBF24",
	"yellow-500": "#F59E0B",
	"yellow-600": "#D97706",
	"yellow-700": "#B45309",
	"yellow-800": "#92400E",
	"yellow-900": "#78350F",
	"amber-off-white-100": "#FFFDF8",
	"amber-off-white-200": "#FFFCF0",
	"amber-off-white-500": "#FFF8DF",
	"amber-off-white-600": "#E8E2D3",
	"tangerine-100": "#FEDBC7",
	"tangerine-200": "#FDBA8A",
	"tangerine-300": "#FC8C4D",
	"tangerine-500": "#F5510B",
	"tangerine-700": "#B43209",
	"tangerine-off-white-100": "#FFFBF8",
	"tangerine-off-white-200": "#FFF7F0",
	"tangerine-off-white-500": "#FFECDF",
	"tangerine-off-white-600": "#E8DBD3",
	"red-200": "#FECACA",
	"red-400": "#F87171",
	"red-600": "#DC2626",
	"red-800": "#991B1B",
	"red-900": "#7F1D1D",
	"off-white-100": "#F8F8FF",
	"off-white-200": "#F4F4FF",
	"off-white-500": "#E8E8FF",
	"off-white-600": "#D3D3E8",
	"neutral-0": "#FFFFFF",
	"neutral-5": "#FCFCFC",
	"neutral-10": "#FAFAFA",
	"neutral-20": "#F5F5F6",
	"neutral-30": "#EBEBED",
	"neutral-40": "#DFDFE1",
	"neutral-50": "#C1C2C6",
	"neutral-60": "#B2B3B8",
	"neutral-90": "#888992",
	"neutral-100": "#7A7B84",
	"neutral-200": "#6B6C76",
	"neutral-300": "#5C5D69",
	"neutral-400": "#50515D",
	"neutral-500": "#41424F",
	"neutral-600": "#343644",
	"neutral-700": "#232534",
	"neutral-900": "#080A1B",
};

// Note: Any changes to the colors while developing requires the app to be re-served
// in order for Tailwind to pick up the changes
export const BASE_COLORS = {
	c_brand_violet: PALETTE["violet-500"],
	c_brand_coral: PALETTE["coral-500"],
	c_brand_dark: PALETTE["neutral-900"],
	c_accent_01: PALETTE["coral-500"],
	c_danger_01: PALETTE["red-800"],
	c_danger_02: PALETTE["red-600"],
	c_danger_03: PALETTE["red-900"],
	c_danger_04: PALETTE["red-200"],
	c_danger_focus: PALETTE["red-400"],
	c_success_01: PALETTE["green-800"],
	c_success_02: PALETTE["green-600"],
	c_success_03: PALETTE["green-900"],
	c_success_04: PALETTE["green-200"],
	c_success_focus: PALETTE["green-400"],
	c_warning_01: PALETTE["yellow-800"],
	c_warning_02: PALETTE["yellow-600"],
	c_warning_03: PALETTE["yellow-900"],
	c_warning_04: PALETTE["yellow-200"],
	c_warning_focus: PALETTE["yellow-400"],
	c_bg_01: PALETTE["neutral-0"],
	c_bg_06: PALETTE["neutral-900"],
	c_bg_07: PALETTE["neutral-700"],
	c_bg_08: PALETTE["neutral-600"],
	c_bg_disabled_01: PALETTE["neutral-50"],
	c_bg_disabled_02: PALETTE["neutral-30"],
	c_bg_disabled_03: PALETTE["neutral-20"],
	c_bg_tooltip: PALETTE["neutral-500"],
	c_text_primary: PALETTE["neutral-900"],
	c_text_secondary: PALETTE["neutral-400"],
	c_secondary_focus: PALETTE["neutral-50"],
	c_text_placeholder: PALETTE["neutral-90"],
	c_text_inverted: PALETTE["neutral-0"],
	c_text_disabled: PALETTE["neutral-90"],
	c_icon_regular: PALETTE["neutral-400"],
	c_icon_emphasised: PALETTE["neutral-900"],
	c_icon_disabled: PALETTE["neutral-90"],
	c_border_regular: PALETTE["neutral-40"],
	c_border_btn: PALETTE["neutral-50"],
	c_highlight: PALETTE["yellow-200"],
	transparent: "transparent",
};

export const THEMABLE_COLOR_VARIABLES = {
	c_brand_primary: "--sd_c_brand_primary",
	c_brand_off_white: "--sd_c_brand_off_white",
	c_text_link: "--sd_c_text_link",
	c_bg_02: "--sd_c_bg_02",
	c_bg_03: "--sd_c_bg_03",
	c_bg_04: "--sd_c_bg_04",
	c_bg_05: "--sd_c_bg_05",
	c_action_01: "--sd_c_action_01",
	c_action_02: "--sd_c_action_02",
	c_action_03: "--sd_c_action_03",
	c_action_04: "--sd_c_action_04",
	c_action_focus: "--sd_c_action_focus",
	c_icon_inverted: "--sd_c_icon_inverted",

	// From left to right
	c_workspace_nav_circle1_start: "--sd_c_workspace_nav_circle1_start",
	c_workspace_nav_circle1_end: "--sd_c_workspace_nav_circle1_end",
	c_workspace_nav_circle2_start: "--sd_c_workspace_nav_circle2_start",
	c_workspace_nav_circle2_end: "--sd_c_workspace_nav_circle2_end",
	c_workspace_nav_circle3_start: "--sd_c_workspace_nav_circle3_start",
	c_workspace_nav_circle3_end: "--sd_c_workspace_nav_circle3_end",
};

export type BaseColorKey = keyof typeof BASE_COLORS;
export type ThemableColorKey = keyof typeof THEMABLE_COLOR_VARIABLES;
export type ColorKey = BaseColorKey | ThemableColorKey;

export const TAILWIND_COLORS = {
	...BASE_COLORS,
	...Object.entries(THEMABLE_COLOR_VARIABLES).reduce((acc, [key, value]) => {
		acc[key] = `rgba(var(${value}), <alpha-value>)`;
		return acc;
	}, {} as any),
};
