const DROPPABLE_HEADER_NAME = "header";
const DROPPABLE_BODY_NAME = "body";

export function getHeaderDroppableId(recordGroupId: number) {
	return `${DROPPABLE_HEADER_NAME}-${recordGroupId}`;
}

export function getBodyDroppableId(recordGroupId: number) {
	return `${DROPPABLE_BODY_NAME}-${recordGroupId}`;
}

export function isHeaderDroppable(droppableId: string) {
	return droppableId.startsWith(DROPPABLE_HEADER_NAME + "-");
}

export function isBodyDroppable(droppableId: string) {
	return droppableId.startsWith(DROPPABLE_BODY_NAME + "-");
}

export function getDropIndex(recordGroupId: number, dropDestinationDroppableId?: string, dropIndex?: number) {
	return dropDestinationDroppableId === getBodyDroppableId(recordGroupId) ? dropIndex : undefined;
}
