import { asConst } from "json-schema-to-ts";
import { tiptapJSONStringSchema } from "../components";

export const noteRequestSchema = asConst({
	title: "Note Request",
	description: "Defines the body of a request to create a note",
	type: "object",
	additionalProperties: false,
	required: ["message"],
	properties: {
		message: tiptapJSONStringSchema,
	},
});
