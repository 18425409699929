import { useEffect, useState } from "react";

import { RecordQueryClauses } from "@salesdesk/salesdesk-schemas";

import { InfiniteRecordSearchParams } from "../../records";
import { generateBoardSearchParams } from "../utils";
import { useBoardAggSelector, useBoardFiltersSelector, useBoardQuerySelector, useBoardSortSelector } from "../store";
import { useDataboardDetailsContext } from "./useDataboardDetailsContext";

export function useObjectBoardSearchParams(additionalFilters?: RecordQueryClauses[]) {
	// Seperate selectors to prevent unnecessary re-renders when non-relevant parts of
	// the board state update
	const query = useBoardQuerySelector();
	const filter = useBoardFiltersSelector();
	const sort = useBoardSortSelector();
	const agg = useBoardAggSelector();

	const { sdObject, workspaceId } = useDataboardDetailsContext();

	const [searchParams, setSearchParams] = useState<InfiniteRecordSearchParams>();

	useEffect(() => {
		if (!sdObject) {
			return;
		}

		const newSearchParams = generateBoardSearchParams({ query, filter, sort, agg, workspaceId, additionalFilters });

		setSearchParams((prevSearchParams) => {
			if (JSON.stringify(newSearchParams) === JSON.stringify(prevSearchParams)) {
				return prevSearchParams;
			}

			return newSearchParams;
		});
	}, [sdObject, workspaceId, additionalFilters, query, sort, filter, agg]);

	return searchParams;
}
