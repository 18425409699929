import { Fragment, forwardRef, useMemo, isValidElement, cloneElement, ReactElement, Ref, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { MultiOptionInputProps, SelectOption, SelectOptionId, SingleOptionInputProps } from "../../../../types";
import { BaseSelectDefaultProps } from "../../types";
import { SelectPopover } from "./SelectPopover";
import { SelectButton } from "./SelectButton";
import { useSelectCloseTrigger, useSelectPopoverWidth } from "../../hooks";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";
import { Tooltip } from "@salesdesk/daisy-ui";
import { SELECT_POPOVER_TRANSITION } from "../../utils";

interface BaseSelectComponentDefaultProps extends BaseSelectDefaultProps {
	selectButton?: ReactElement;
}

interface SingleOptionBaseSelect extends BaseSelectComponentDefaultProps, SingleOptionInputProps {
	isMultiselect?: false;
}

interface MultiOptionBaseSelect extends BaseSelectComponentDefaultProps, MultiOptionInputProps {
	isMultiselect: true;
}

// If isMultiselect is true, uses MultiOptionInputProps otherwise uses SingleOptionInputProps
type BaseSelectComponentProps = SingleOptionBaseSelect | MultiOptionBaseSelect;

export const BaseSelect = forwardRef<HTMLElement, BaseSelectComponentProps>(
	(
		{
			onChange,
			value,
			disabled,
			ariaAttributes,
			options,
			optionsTitle,
			tooltip,
			placeholder = "Select an option",
			selectPopoverOptions,
			selectButton,
			selectPopover,
			isMultiselect,
			onOpenChange,
			optionDisplayVariant,
		},
		ref
	) => {
		const selectButtonRef = useRef<HTMLButtonElement>(null);

		const { selectKey, triggerSelectClose } = useSelectCloseTrigger(selectButtonRef, disabled);
		const selectWidth = useSelectPopoverWidth(selectButtonRef, selectPopoverOptions?.width, selectKey);

		const optionsMappedById = useMemo(() => {
			return options.flat().reduce(
				(optionMap, optionSection) => {
					("sectionTitle" in optionSection ? optionSection.options : [optionSection]).forEach((option) => {
						optionMap[option.id] = option;
					});

					return optionMap;
				},
				{} as Record<SelectOptionId, SelectOption>
			);
		}, [options]);

		const selectedOption = useMemo(() => {
			if (isMultiselect) {
				return value?.length ? optionsMappedById[value[0]] : undefined;
			}
			return value ? optionsMappedById[value] : undefined;
		}, [value, optionsMappedById, isMultiselect]);

		const SelectButtonComponent = useMemo(() => {
			const childProps = { selectedOption, placeholder, disabled, ref: selectButtonRef };

			if (selectButton && !isValidElement(selectButton)) {
				throw new Error("Select Button must be a valid React element");
			}

			return selectButton ? cloneElement(selectButton, childProps) : <SelectButton {...childProps} />;
		}, [selectButton, selectButtonRef, selectedOption, placeholder, disabled]);

		const SelectPopoverComponent = useMemo(() => {
			const childProps = { optionsTitle, options, isMultiselect, optionDisplayVariant };

			if (selectPopover && !isValidElement(selectPopover)) {
				throw new Error("Select Popover must be a valid React element");
			}

			return selectPopover ? cloneElement(selectPopover, childProps) : <SelectPopover {...childProps} />;
		}, [optionsTitle, options, isMultiselect, optionDisplayVariant, selectPopover]);

		const tooltipText = tooltip ?? (typeof optionsTitle === "string" ? optionsTitle : "");

		return (
			<Listbox
				key={selectKey}
				value={value ?? -1}
				onChange={onChange}
				disabled={disabled}
				multiple={isMultiselect}
				{...ariaAttributes}
			>
				{({ open }) => (
					<Popover
						keepPopoverMounted={true}
						hideWhenClosedButMounted={false}
						open={open}
						placement={selectPopoverOptions?.placement || "bottom-end"}
						useFloatingPortal={selectPopoverOptions?.useFloatingPortal}
						onOpenChange={(popoverOpen) => {
							if (!popoverOpen && open) {
								triggerSelectClose();
							}
							if (onOpenChange) {
								onOpenChange(popoverOpen);
							}
						}}
					>
						<Tooltip text={open ? undefined : tooltipText} {...(selectPopoverOptions?.tooltipProps ?? {})}>
							<PopoverTrigger disabled={disabled}>
								<Listbox.Button as={Fragment} {...{ ref: open ? undefined : (ref as Ref<HTMLButtonElement>) }}>
									{SelectButtonComponent}
								</Listbox.Button>
							</PopoverTrigger>
						</Tooltip>
						<PopoverContent initialFocus={-1}>
							<Transition as={Fragment} {...SELECT_POPOVER_TRANSITION}>
								<Listbox.Options {...{ ref: open ? ref : undefined }} style={{ width: selectWidth }}>
									{SelectPopoverComponent}
								</Listbox.Options>
							</Transition>
						</PopoverContent>
					</Popover>
				)}
			</Listbox>
		);
	}
);
