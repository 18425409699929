import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { EmojiSize } from "../types";

init({ data });

interface EmojiProps {
	native: string;
	size?: EmojiSize;
}

export function Emoji({ native, size = "sm" }: EmojiProps) {
	const sizePx = size === "sm" ? 16 : 20;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	return <em-emoji native={native} size={sizePx + "px"} />;
}
