import { useGetObjectById } from "../../../../../hooks";
import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { useSdFile } from "../../../../files/hooks/useSdFile";
import { PageChangedParams, VideoEventParams } from "@salesdesk/salesdesk-model";
import { useFileField } from "../../../hooks/useFileField";

export function FileObject() {
	const { event, target } = useEventContext();
	const { sdObject } = useGetObjectById(event.object_def_id);
	const fileParams = event.params as VideoEventParams | PageChangedParams | undefined;
	const { sdFile } = useSdFile(fileParams?.fileId);
	const field = useFileField(sdObject, sdFile);

	const notificationLink = target === "NOTIFICATION" ? <LinkObject /> : null;

	if (field) {
		return (
			<>
				the '{field._displayName}' field {notificationLink}
			</>
		);
	}

	if (notificationLink) {
		return notificationLink;
	}

	if (!sdObject) {
		return "this record";
	}

	return `${sdObject._displayName.toLowerCase()}`;
}
