import { SdEventType } from "@salesdesk/salesdesk-model";
import { nameof } from "@salesdesk/salesdesk-utils";
import { RecordSharedWithWorkspaceParams, WorkspaceRecordSharedParams } from "../../../events";

export const eventActions: Record<SdEventType, string> = {
	[SdEventType.RECORD_CREATED]: "created",
	[SdEventType.RECORD_UPDATED]: "updated",
	[SdEventType.RECORD_OWNER_UPDATED]: "updated the owner of",
	[SdEventType.RECORD_DELETED]: "deleted",
	[SdEventType.RECORD_LOADED]: "viewed",
	[SdEventType.RECORD_NOTE_ADDED]: "added a note to",
	[SdEventType.RECORD_NOTE_DELETED]: "deleted a note from",
	[SdEventType.RECORD_NOTE_UPDATED]: "updated a note in",
	[SdEventType.RECORD_ASSOCIATION_CREATED]: "linked",
	[SdEventType.RECORD_ASSOCIATION_DELETED]: "unlinked",
	[SdEventType.RECORD_STARTED_WATCHING]: "started watching",
	[SdEventType.RECORD_STOPPED_WATCHING]: "stopped watching",
	[SdEventType.RECORD_COMMENT_ADDED]: "added a comment to",
	[SdEventType.RECORD_COMMENT_RESPONDED]: "responded to a comment in",
	[SdEventType.RECORD_COMMENT_DELETED]: "deleted a comment from",
	[SdEventType.RECORD_COMMENT_UPDATED]: "updated a comment in",
	[SdEventType.RECORD_COMMENT_RESOLVED]: "resolved a comment in",
	[SdEventType.RECORD_SET_STARRED]: "starred",
	[SdEventType.RECORD_SET_UNSTARRED]: "unstarred",
	[SdEventType.RECORD_SHARED_IN_WORKSPACE]: `shared in workspace {${nameof<RecordSharedWithWorkspaceParams>(
		"workspaceName"
	)}}`,
	[SdEventType.RECORD_UNSHARED_IN_WORKSPACE]: `unshared in workspace {${nameof<RecordSharedWithWorkspaceParams>(
		"workspaceName"
	)}}`,
	[SdEventType.USER_ADDED_TO_WORKSPACE]: `added to workspace {${nameof<RecordSharedWithWorkspaceParams>(
		"workspaceName"
	)}}`,
	[SdEventType.USER_REMOVED_FROM_WORKSPACE]: `removed from workspace {${nameof<RecordSharedWithWorkspaceParams>(
		"workspaceName"
	)}}`,
	[SdEventType.WORKSPACE_ENTERED]: "entered",
	[SdEventType.WORKSPACE_RECORD_SHARED]: `shared record {${nameof<WorkspaceRecordSharedParams>(
		"sharedRecordName"
	)}} in workspace`,
	[SdEventType.WORKSPACE_RECORD_UNSHARED]: `unshared record {${nameof<WorkspaceRecordSharedParams>(
		"sharedRecordName"
	)}} in workspace`,
	[SdEventType.WORKSPACE_USER_ADDED]: `added user {${nameof<WorkspaceRecordSharedParams>(
		"sharedRecordName"
	)}} in workspace`,
	[SdEventType.WORKSPACE_USER_REMOVED]: `removed user {${nameof<WorkspaceRecordSharedParams>(
		"sharedRecordName"
	)}} in workspace`,
	[SdEventType.LEAD_CONVERTED_TO_CONTACT]: "converted",
	[SdEventType.LEAD_CONVERTED_TO_OPPORTUNITY]: "converted",
	[SdEventType.LEAD_CONVERTED_TO_ACCOUNT]: "converted",
	[SdEventType.USER_CREATED]: "created a user profile for",
	[SdEventType.CUSTOMER_USER_CREATED]: "granted customer user access to",
	[SdEventType.USER_REVOKED]: "revoked customer user access from",
	[SdEventType.TASK_COMPLETED]: "completed",
	[SdEventType.TASK_REOPENED]: "reopened",
	[SdEventType.TASK_ASSIGNED]: "assigned",
	[SdEventType.TASK_REASSIGNED]: "reassigned",
	[SdEventType.OPPORTUNITY_WON]: "marked as won",
	[SdEventType.OPPORTUNITY_LOST]: "marked as lost",
	[SdEventType.ISSUE_CLOSED]: "closed",
	[SdEventType.ISSUE_REOPENED]: "reopened",
	[SdEventType.ISSUE_ASSIGNED]: "assigned",
	[SdEventType.ISSUE_REASSIGNED]: "reassigned",
	[SdEventType.MEETING_ORGANISED]: "organised",
	[SdEventType.MEETING_UPDATED]: "updated",
	[SdEventType.MEETING_STARTED]: "started",
	[SdEventType.MEETING_ENDED]: "ended",
	[SdEventType.MEETING_ENTERED]: "joined",
	[SdEventType.MEETING_EXITED]: "left",
	[SdEventType.CHAT_MESSAGE_SENT]: "sent a message in",
	[SdEventType.RECORDING_STARTED]: "started recording",
	[SdEventType.RECORDING_STOPPED]: "stopped recording",
	[SdEventType.RECORDING_ANALYSIS_COMPLETE]: "completed recording analysis",
	[SdEventType.CAMERA_ENABLED]: "turned on camera in",
	[SdEventType.CAMERA_DISABLED]: "turned off camera in",
	[SdEventType.MICROPHONE_ENABLED]: "turned on microphone in",
	[SdEventType.MICROPHONE_DISABLED]: "turned off microphone in",
	[SdEventType.FILE_PRESENTATION_STARTED]: "started presenting",
	[SdEventType.FILE_PRESENTATION_STOPPED]: "stopped presenting",
	[SdEventType.FILE_PRESENTATION_PAGE_CHANGED]: `moved to page {pageNumber}`,
	[SdEventType.SCREEN_SHARE_STARTED]: "started sharing their screen in",
	[SdEventType.SCREEN_SHARE_ENDED]: "stopped sharing their screen in",
	[SdEventType.COPIED_TO_CLIPBOARD]: "copied to clipboard",
	[SdEventType.PAGE_LOAD]: "loaded page",
	[SdEventType.USER_LOGGED_IN]: "logged in",
	[SdEventType.USER_LOGGED_OUT]: "logged out",
	[SdEventType.NOTE_REACTION_ADDED]: "reacted to a note in",
	[SdEventType.NOTE_REACTION_REMOVED]: "removed a reaction from a note in",
	[SdEventType.NOTE_MENTION]: "mentioned {who|someone} in a note in",
	[SdEventType.COMMENT_MENTION]: "mentioned {who|someone} in a comment in",
	[SdEventType.COMMENT_REACTION_ADDED]: "reacted to a comment",
	[SdEventType.COMMENT_REACTION_REMOVED]: "removed a reaction from a comment",
	[SdEventType.FILE_UPLOADED]: "uploaded a file to",
	[SdEventType.FILE_DELETED]: "deleted a file from",
	[SdEventType.FILE_DOWNLOADED]: "downloaded the file in",
	[SdEventType.FILE_VIEWED]: "viewed the file in",
	[SdEventType.PAGE_CHANGED]: "moved to page {pageNumber} in",
	[SdEventType.FILE_PLAYED]: "played",
	[SdEventType.FILE_PAUSED]: "paused",
	[SdEventType.FILE_SEEKED]: "seeked in",
	[SdEventType.FILE_ENDED]: "played to the end of",

	[SdEventType.FILE_PROCESSING_STARTED]: "started processing",
	[SdEventType.FILE_PROCESSING_ENDED]: "finished processing",
	[SdEventType.FILE_PROCESSING_FAILED]: "failed processing",
	[SdEventType.FILE_TRANSCODE_STARTED]: "started transcoding",
	[SdEventType.FILE_TRANSCODE_ENDED]: "finished transcoding",
	[SdEventType.FILE_TRANSCRIPTION_STARTED]: "started transcribing",
	[SdEventType.FILE_TRANSCRIPTION_ENDED]: "finished transcribing",
	[SdEventType.FILE_TRANSCRIPTION_ANALYSIS_STARTED]: "started analyzing transcript",
	[SdEventType.FILE_TRANSCRIPTION_ANALYSIS_ENDED]: "finished analyzing transcript",
	[SdEventType.FILE_TRANSCRIPTION_ANALYSIS_MINUTES_EXTRACTED]: "extracted minutes",
	[SdEventType.FILE_COMPOSITING_STARTED]: "started compositing",
	[SdEventType.FILE_COMPOSITING_ENDED]: "finished compositing",

	[SdEventType.MESSAGE_SENT]: "sent a message in",
	[SdEventType.MESSAGE_UPDATED]: "updated a message in",
	[SdEventType.MESSAGE_DELETED]: "deleted a message in",
	[SdEventType.MESSAGE_RECORD_SHARED]: "shared in {channelName| } channel",
	[SdEventType.MESSAGE_HUDDLE_STARTED]: "started",
	[SdEventType.MESSAGE_MENTION]: "mentioned {who|someone} in a message in",
	[SdEventType.MESSAGE_CHANNEL_CREATED]: "created",
	[SdEventType.MESSAGE_CHANNEL_DELETED]: "deleted",
	[SdEventType.MESSAGE_CHANNEL_MEMBER_CREATED]: "added {who|someone} to",
	[SdEventType.MESSAGE_CHANNEL_MEMBER_DELETED]: "removed {who|someone} from",

	[SdEventType.IMPORT_JOB_STARTED]: "started an import job",
	[SdEventType.IMPORT_JOB_COMPLETED]: "completed an import job",
	[SdEventType.IMPORT_JOB_FAILED]: "had an import job fail",
};
