import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";

import { Button } from "@salesdesk/daisy-ui";
import { Dialog } from "./Dialog";
import { DialogContent } from "./DialogContent";
import { DialogHeader } from "./DialogHeader";

interface FormDialogProps {
	title: string;
	description?: string | ReactNode;
	variant?: "xs" | "sm" | "base";
	open: boolean;
	onOpenChange: (open: boolean) => void;
	isPending?: boolean;
	formId?: string;
	disableSubmitButton?: boolean;
	hideFooter?: boolean;
	submitButtonLabel?: string;
	closeButtonLabel?: string;
	width?: number;
	footerContent?: ReactNode;
	footerContentAlignment?: "left" | "right";
	hideCloseButton?: boolean;
}

export function FormDialog({
	open,
	title,
	children,
	onOpenChange,
	isPending,
	formId,
	submitButtonLabel = "Save",
	closeButtonLabel = "Cancel",
	disableSubmitButton = false,
	hideFooter = false,
	width = 500,
	footerContent,
	footerContentAlignment = "left",
	description,
	variant = "base",
	hideCloseButton = false,
}: PropsWithChildren<FormDialogProps>) {
	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent roundedSize="md">
				<div
					className={clsx("flex flex-col", {
						"gap-8": variant === "base",
						"gap-4": variant === "sm",
						"gap-2": variant === "xs",
					})}
					style={{
						width,
					}}
				>
					<DialogHeader title={title} description={description} variant={variant} onClose={() => onOpenChange(false)} />
					{children}
					<div
						className={clsx(
							"flex items-center gap-4",
							footerContentAlignment === "right" ? "justify-end" : "justify-between"
						)}
					>
						{hideFooter ? null : (
							<>
								<div>{footerContent}</div>
								<div className="flex justify-end gap-3">
									{!hideCloseButton ? (
										<Button
											disabled={isPending}
											onClick={() => {
												onOpenChange(false);
											}}
											variant="secondary"
											size="lg"
										>
											{closeButtonLabel}
										</Button>
									) : null}
									<Button
										disabled={disableSubmitButton}
										isLoading={isPending}
										size="lg"
										variant="primary"
										type="submit"
										form={formId}
									>
										{submitButtonLabel}
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
