const FAVICON_PATH = "/static/images/sd_favicon.ico";
const ALERT_FAVICON_PATH = "/static/images/sd_alert_favicon.ico";

export function updateFavicon(type: "default" | "alert" = "default") {
	const faviconElement = document.getElementById("app-favicon") as HTMLLinkElement | null;

	if (!faviconElement) {
		return;
	}

	faviconElement.href = type === "alert" ? ALERT_FAVICON_PATH : FAVICON_PATH;
}
