import { Header, flexRender } from "@tanstack/react-table";
import clsx from "clsx";
import { tw } from "../../../../utils/tailwind-helpers";

import {
	COLUMN_TYPE,
	EndColumnDetails,
	ROW_LINK_COLUMN_ID,
	ROW_SELECTION_COLUMN_ID,
	TableColumnId,
	TableRow,
	TableVariant,
} from "../../types";
import { useColumnHeaderWidth } from "../../hooks/useColumnHeaderWidth";

interface ColumnHeaderProps<T> {
	header: Header<TableRow<T>, unknown>;
	columnType: COLUMN_TYPE;
	endColumnDetails?: EndColumnDetails;
	updateEndColumnDetails: (columnId: TableColumnId, width: number) => void;
	hideHeader?: boolean;
	inSelectionMode?: boolean;
	variant?: TableVariant;
	isPrimaryLinkedTable?: boolean;
}

export function ColumnHeader<T>({
	header,
	columnType,
	endColumnDetails,
	updateEndColumnDetails,
	hideHeader = false,
	inSelectionMode,
	variant,
	isPrimaryLinkedTable,
}: ColumnHeaderProps<T>) {
	const { id: headerId, column } = header;
	const width = useColumnHeaderWidth({
		header,
		updateEndColumnDetails,
		columnType,
		endColumnDetails,
		isPrimaryLinkedTable,
	});

	// Row link column exists only for screen readers so doesn't need any padding
	const isRowLinkColumn = headerId === ROW_LINK_COLUMN_ID;

	const isRowSelectionColumn = headerId === ROW_SELECTION_COLUMN_ID;

	const isResizable = !isRowSelectionColumn;

	const isResizing = column.getIsResizing();

	if (isRowLinkColumn) {
		return <th key={headerId} className="sr-only w-0" />;
	}

	const headerBackground = variant === TableVariant.primary ? tw`bg-c_bg_02` : tw`bg-c_bg_03`;

	return (
		<th
			key={headerId}
			className={clsx(
				columnType === COLUMN_TYPE.SINGLE && "rounded-l-minimal rounded-r-minimal",
				columnType === COLUMN_TYPE.START && "rounded-l-minimal",
				columnType === COLUMN_TYPE.END && "rounded-r-minimal",
				// Take up all available space if last column has auto width
				columnType === COLUMN_TYPE.END && width === "auto" ? "w-full" : "w-auto",
				isResizing ? "bg-c_bg_05" : headerBackground,
				isResizable && "hover:bg-c_bg_05",
				!isRowSelectionColumn && "relative",
				"overflow-visible p-0 text-left transition-colors",
				hideHeader ? "h-0" : "h-10"
			)}
		>
			<div style={{ width }} className="overflow-visible">
				{isResizable && !hideHeader ? (
					<div
						className={clsx(
							isResizing ? "opacity-100" : "opacity-0 hover:opacity-100",
							"bg-c_brand_primary absolute -right-px top-0 z-10 h-full w-[3px] cursor-col-resize touch-none select-none transition-opacity"
						)}
						{...{
							onMouseDown: header.getResizeHandler(),
							onTouchStart: header.getResizeHandler(),
						}}
					/>
				) : null}
			</div>
			<div
				className={clsx(
					isRowSelectionColumn && !inSelectionMode && "opacity-0",
					isRowSelectionColumn && (hideHeader ? "h-0" : "h-10"),
					isRowSelectionColumn
						? "from-c_bg_02 focus-visible-within:opacity-100 rounded-l-minimal fixed z-20 flex items-center justify-center bg-gradient-to-r from-90% to-transparent transition-opacity hover:opacity-100"
						: "absolute inset-x-0 top-1/2",
					"pointer-events-auto -translate-y-1/2 truncate"
				)}
				style={{ width: isRowSelectionColumn ? width : undefined }}
			>
				{hideHeader ? null : flexRender(header.column.columnDef.header, header.getContext())}
			</div>
		</th>
	);
}
