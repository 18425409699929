import { PropsWithChildren, useCallback, useMemo, useRef, useState } from "react";
import { LinkedTablesContext, LinkedTableState } from "../hooks/useLinkedTables";
import { RowData, Table } from "@tanstack/react-table";

export function LinkedTablesProvider({ children }: PropsWithChildren) {
	const [widthByColumnId, setWidthByColumnId] = useState<Record<string, number | string>>({});
	const [trackerPosition, setTrackerPosition] = useState<number | undefined>();
	const [hasHorizontalOverflow, setHasHorizontalOverflow] = useState<boolean>(false);
	const [refreshKey, setRefreshKey] = useState<number>(0);

	const toggleAllRowsSelected = useRef<(checked: boolean) => void>(() => undefined);

	const tableById = useRef<Record<string, Table<RowData>>>({});
	const registerTable = useCallback((id: string, table: Table<RowData>) => {
		tableById.current[id] = table;
	}, []);

	const updateColumnWidth = useCallback((columnId: string, width: number | string) => {
		setWidthByColumnId((prev) => ({ ...prev, [columnId]: width }));
	}, []);

	const refresh = useCallback(() => {
		setRefreshKey((prev) => prev + 1);
	}, []);

	const state: LinkedTableState = useMemo(
		() => ({
			widthByColumnId,
			updateColumnWidth,
			trackerPosition,
			setTrackerPosition,
			hasHorizontalOverflow,
			setHasHorizontalOverflow,
			registerTable,
			tableById,
			refresh,
			refreshKey,
			toggleAllRowsSelected,
		}),
		[
			widthByColumnId,
			updateColumnWidth,
			trackerPosition,
			hasHorizontalOverflow,
			registerTable,
			tableById,
			refresh,
			refreshKey,
			toggleAllRowsSelected,
		]
	);

	return <LinkedTablesContext.Provider value={state}>{children}</LinkedTablesContext.Provider>;
}
