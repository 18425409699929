import { useMediaAnalysisContext } from "./useMediaAnalisysContext";
import { useEffect, useState } from "react";

export function useCurrentVideoTime() {
	const { videoRef } = useMediaAnalysisContext();
	const [currentTime, setCurrentTime] = useState<number>();

	useEffect(() => {
		const videoElement = videoRef.current;
		if (!videoElement) {
			return;
		}
		const onTimeUpdate = () => {
			setCurrentTime(videoRef.current?.currentTime);
		};
		videoElement.addEventListener("timeupdate", onTimeUpdate);
		return () => {
			videoElement.removeEventListener("timeupdate", onTimeUpdate);
		};
	}, [videoRef]);

	return currentTime;
}
