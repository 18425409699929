import { asConst } from "json-schema-to-ts";
import { matchAllPrefixClauseSchema } from "./matchAllPrefixClauseSchema";
import { matchAllClauseSchema } from "./matchAllClauseSchema";
import { matchPrefixClauseSchema } from "./matchPrefixClauseSchema";
import { matchClauseSchema } from "./matchClauseSchema";
import { equalsClauseSchema } from "./equalsClauseSchema";
import { rangeClauseSchema } from "./rangeClauseSchema";
import { includeClauseSchema } from "./includeClauseSchema";
import { includeAnyOfClauseSchema } from "./includeAnyOfClauseSchema";
import { equalsAnyOfClauseSchema } from "./equalsAnyOfClauseSchema";
import { existsClauseSchema } from "./existsClauseSchema";

export const notClausesSchema = asConst({
	title: "Allowed not clauses",
	type: "object",
	anyOf: [
		matchAllPrefixClauseSchema,
		matchAllClauseSchema,
		matchPrefixClauseSchema,
		matchClauseSchema,
		existsClauseSchema,
		equalsClauseSchema,
		equalsAnyOfClauseSchema,
		rangeClauseSchema,
		includeClauseSchema,
		includeAnyOfClauseSchema,
	],
	nullable: false,
});
