import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, tw, FieldSet } from "@salesdesk/daisy-ui";

import { useObjectAssociations } from "../../../../../../../recordAssociations";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { useShareSelectedRecords } from "../hooks/useShareSelectedRecords";
import { ResourcesPanelTabs } from "./ResourcesPanelTabs";
import { useOutsideClick } from "../../../../../../../../hooks/useOutsideClick";
import { InternalWorkspaceAreaContainer } from "../../../../../InternalWorkspaceAreaContainer";
import { RESOURCES_PANEL_PREVENT_CLOSE_CLASS } from "../constants";
import { Checkbox } from "../../../../../../../inputs";

const EMPTY_OBJECT_ID_ARRAY: number[] = [];

export function ResourcesPanel() {
	const { workspaceObject, resourcesPanelDetails, clearResourcesPanelDetails } = useWorkspaceContext();
	const [isOpen, setIsOpen] = useState(false);

	const [resourceRecordMap, setResourceRecordMap] = useState<Record<number, SDRecord[]>>({});
	const [selectedRecords, setSelectedRecords] = useState<SDRecord[]>([]);
	const [totalAvailableRecords, setTotalAvailableRecords] = useState(0);

	const allResourceRecords = useMemo(() => Object.values(resourceRecordMap).flat(), [resourceRecordMap]);

	const objectIds = resourcesPanelDetails?.sdObjectIds ?? EMPTY_OBJECT_ID_ARRAY;
	const title = resourcesPanelDetails?.title ?? null;
	const associateSelectedRecordsWith = resourcesPanelDetails?.associateSelectedRecordsWith;

	const closeResourcesPanel = useCallback(() => {
		setIsOpen(false);
		setTimeout(() => clearResourcesPanelDetails?.(), 200);
	}, [clearResourcesPanelDetails]);

	const outsideClickRef = useOutsideClick<HTMLDivElement>(closeResourcesPanel, RESOURCES_PANEL_PREVENT_CLOSE_CLASS);

	const { shareSelectedRecords, isLoading } = useShareSelectedRecords(
		objectIds,
		closeResourcesPanel,
		associateSelectedRecordsWith
	);

	const toggleRecordSelection = useCallback((record: SDRecord) => {
		setSelectedRecords((prevSelectedRecords) => {
			const selectedRecords = [...prevSelectedRecords];
			const recordIndex = selectedRecords.findIndex((selectedRecord) => selectedRecord._id === record._id);

			if (recordIndex === -1) {
				selectedRecords.push(record);
			} else {
				selectedRecords.splice(recordIndex, 1);
			}

			return selectedRecords;
		});
	}, []);

	const selectAllRecords = useCallback(
		(isChecked: boolean) => {
			setSelectedRecords(isChecked ? allResourceRecords : []);
		},
		[allResourceRecords]
	);

	const areAllRecordsSelected = useMemo(
		() => selectedRecords.length > 0 && selectedRecords.length === allResourceRecords.length,
		[selectedRecords, allResourceRecords]
	);

	const {
		objectAssociations: { prioritizedAssociations: tabbedAssociations },
	} = useObjectAssociations(workspaceObject?._id, objectIds);

	useEffect(() => {
		setIsOpen(Boolean(resourcesPanelDetails));
		setSelectedRecords([]);
	}, [resourcesPanelDetails]);

	const selectAllShown = allResourceRecords.length > 0;

	const onResourceRecordsLoad = useCallback((objectDefId: number, records: SDRecord[]) => {
		setResourceRecordMap((prevResourceRecords) => ({
			...prevResourceRecords,
			...{ [objectDefId]: records },
		}));
	}, []);

	useEffect(() => {
		if (!isOpen) {
			setResourceRecordMap({});
		}
	}, [isOpen]);

	return (
		<InternalWorkspaceAreaContainer>
			<Transition
				show={isOpen}
				ref={outsideClickRef}
				className="border-c_border_regular z-10 h-full max-w-full shrink-0 border-l transition-all duration-200 ease-in-out"
				enterFrom={tw`w-0`}
				enterTo={tw`w-[480px]`}
				entered={tw`w-[480px]`}
				leaveFrom={tw`w-[480px]`}
				leaveTo={tw`w-0`}
			>
				<div className="flex h-full w-full min-w-[480px] flex-col gap-6 py-6 pb-4">
					<div className="text-h4 flex w-full items-center justify-between px-6">
						{title}
						<Button size="sm" variant="text" startIcon={ICONS.cross} onClick={closeResourcesPanel} />
					</div>
					<div className="text-c_text_secondary px-6">
						Records can only be available for this {workspaceObject?._displayName || "Workspace"} once you've added them
						to the Resources area first.
					</div>
					<div className="flex h-full max-h-full w-full flex-col gap-4 overflow-hidden">
						<ResourcesPanelTabs
							associations={tabbedAssociations}
							onTotalAvailableRecordsChange={setTotalAvailableRecords}
							toggleRecordSelection={toggleRecordSelection}
							selectedRecords={selectedRecords}
							onResourceRecordsLoad={onResourceRecordsLoad}
						/>
						<div
							className={clsx(
								"mt-auto flex items-center px-6 align-middle",
								selectAllShown ? "justify-between" : "justify-end"
							)}
						>
							{selectAllShown ? (
								<FieldSet label="Select all" isBoolean>
									<Checkbox
										value={areAllRecordsSelected}
										onChange={selectAllRecords}
										isIndeterminate={Boolean(selectedRecords.length && !areAllRecordsSelected)}
									/>
								</FieldSet>
							) : null}

							<div className="flex gap-3">
								{totalAvailableRecords ? (
									<p className="flex flex-col justify-center">
										<span className="text-body-sm text-c_text_secondary">
											Selected{" "}
											<span className={clsx(selectedRecords.length && "text-label-sm")}>{selectedRecords.length}</span>/
											{totalAvailableRecords || 0}
										</span>
									</p>
								) : null}
								<Button variant="secondary" onClick={closeResourcesPanel}>
									Cancel
								</Button>
								<Button
									disabled={!totalAvailableRecords}
									onClick={() => shareSelectedRecords(selectedRecords)}
									isLoading={isLoading}
								>
									Add
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</InternalWorkspaceAreaContainer>
	);
}
