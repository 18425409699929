import { ObjectAssociationEndpointType, AssociationSide } from "@salesdesk/salesdesk-model";
import { Field, getSDObjectNameFieldIds, RecordQueryClauses, SDObject, TimeRange } from "@salesdesk/salesdesk-schemas";

import {
	Filter,
	TEXT_FILTER_IDS,
	TextFilterDetails,
	NumericFilterDetails,
	NUMERIC_RANGE_FILTER_IDS,
	NumericRangeFilterDetails,
	DATE_FILTER_IDS,
	DateFilterDetails,
	DATE_BETWEEN_FILTER_IDS,
	DateBetweenFilterDetails,
	DATETIME_FILTER_IDS,
	DATETIME_BETWEEN_FILTER_IDS,
	TIME_RANGE_FILTER_IDS,
	TIME_RANGE_BETWEEN_FILTER_IDS,
	TimeRangeBetweenFilterDetails,
	TimeRangeFilterDetails,
	SINGLE_SELECT_FILTER_IDS,
	SingleSelectFilterDetails,
	MULTI_SELECT_FILTER_IDS,
	MultiSelectFilterDetails,
	BOOLEAN_FILTER_IDS,
	BooleanFilterDetails,
	RATINGS_FILTER_IDS,
	NUMERIC_FILTER_IDS,
	ASSOCIATION_FILTER_IDS,
	AssociationFilterDetails,
	FilterData,
	DateTimeFilterDetails,
	FilterTargetId,
	FilterTargetMap,
	FilterOption,
} from "../types";
import { DirectedSDObjectAssociation } from "../../../../recordAssociations";
import { FILTERS } from "./filters";
import { associationFilterOptionsFactory, fieldFilterOptionsFactory } from "./filterOptions";

export * from "./filters";
export * from "./filterOptions";

export function generateNewFilter(sdObject: SDObject): Filter {
	const nameFieldIds = getSDObjectNameFieldIds(sdObject);
	return { filterId: "t_contains", filterTarget: String(nameFieldIds[0]), value: "", value2: undefined };
}

export const EMPTY_FILTERS: FilterData = {
	type: "AND",
	filters: [],
};

export function parseTimeRangeParam(timeRangeParam: string | undefined): TimeRange | undefined {
	if (!timeRangeParam) {
		return undefined;
	}
	const [start, end] = timeRangeParam.includes(",") ? timeRangeParam.split(",") : [timeRangeParam];
	return { start: start && start !== "null" ? Number(start) : null, end: end && end !== "null" ? Number(end) : null };
}

export function stringifyTimeRangeParam(timeRange: TimeRange | undefined): string | undefined {
	if (!timeRange) return undefined;
	return `${timeRange.start},${timeRange.end}`;
}

export const ASSOCIATION_ID_PREFIX = "association";

export function generateAssocitionFilterTargetId(association: DirectedSDObjectAssociation) {
	const associationSide =
		association.originObject === ObjectAssociationEndpointType.OBJECT_ONE
			? AssociationSide.RECORD_1
			: AssociationSide.RECORD_2;

	return `${ASSOCIATION_ID_PREFIX}|${association.id}|${associationSide}`;
}

export function getAssociationDetailsFromFilterTargetId(filterTargetId: string) {
	const parts = filterTargetId.split("|");

	if (!isAssociationFilterTargetId(filterTargetId) || parts.length !== 3) {
		return { associationId: 0, associationSide: AssociationSide.RECORD_1 };
	}

	return { associationId: Number(parts[1]), associationSide: parts[2] as AssociationSide };
}

export function isAssociationFilterTargetId(filterTargetId: string) {
	return filterTargetId.startsWith(ASSOCIATION_ID_PREFIX);
}

export function getFilterOptions(filterTargetId: FilterTargetId, filterTargetMap: FilterTargetMap): FilterOption[] {
	const filterTarget = filterTargetMap[filterTargetId];
	if (!filterTarget) return [];
	if (isAssociationFilterTargetId(filterTargetId)) {
		return associationFilterOptionsFactory((filterTarget as DirectedSDObjectAssociation).connectedObject.multiplicity);
	}
	return fieldFilterOptionsFactory((filterTarget as Field)._type);
}

export function mapToSearchQuery(filter: Filter): RecordQueryClauses | RecordQueryClauses[] | [] {
	const filterDetails = FILTERS[filter.filterId];
	if (!filterDetails) {
		return [];
	}
	if (typeof filter.value === "string" && TEXT_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as TextFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}
	if (typeof filter.value === "number" && NUMERIC_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as NumericFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}
	if (
		(filter.value === undefined || typeof filter.value === "number") &&
		(filter.value2 === undefined || typeof filter.value2 === "number") &&
		NUMERIC_RANGE_FILTER_IDS.some((id) => id === filter.filterId)
	) {
		return (filterDetails as NumericRangeFilterDetails).generateSearchQuery(
			filter.filterTarget,
			filter.value,
			filter.value2
		);
	}
	if (
		(typeof filter.value === "string" || typeof filter.value === "number") &&
		DATE_FILTER_IDS.some((id) => id === filter.filterId)
	) {
		return (filterDetails as DateFilterDetails).generateSearchQuery(filter.filterTarget, filter.value) ?? [];
	}
	if (typeof filter.value === "string" && DATE_BETWEEN_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as DateBetweenFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}
	if (
		(typeof filter.value === "string" || typeof filter.value === "number") &&
		DATETIME_FILTER_IDS.some((id) => id === filter.filterId)
	) {
		return (filterDetails as DateTimeFilterDetails).generateSearchQuery(filter.filterTarget, filter.value) ?? [];
	}
	if (typeof filter.value === "string" && DATETIME_BETWEEN_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as DateBetweenFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}
	if (
		(typeof filter.value === "string" || typeof filter.value === "number") &&
		TIME_RANGE_FILTER_IDS.some((id) => id === filter.filterId)
	) {
		return (filterDetails as TimeRangeFilterDetails).generateSearchQuery(filter.filterTarget, filter.value) ?? [];
	}
	if (typeof filter.value === "string" && TIME_RANGE_BETWEEN_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as TimeRangeBetweenFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}
	if (typeof filter.value === "boolean" && BOOLEAN_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as BooleanFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}
	if (typeof filter.value === "number" && RATINGS_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as NumericFilterDetails).generateSearchQuery(filter.filterTarget, filter.value);
	}

	const filterValueAsArray = filter.value !== undefined && Array.isArray(filter.value) ? filter.value : undefined;

	if (filterValueAsArray && SINGLE_SELECT_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as SingleSelectFilterDetails).generateSearchQuery(filter.filterTarget, filterValueAsArray);
	}
	if (filterValueAsArray && MULTI_SELECT_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as MultiSelectFilterDetails).generateSearchQuery(filter.filterTarget, filterValueAsArray);
	}
	if (filterValueAsArray && ASSOCIATION_FILTER_IDS.some((id) => id === filter.filterId)) {
		return (filterDetails as AssociationFilterDetails).generateSearchQuery(filter.filterTarget, filterValueAsArray);
	}

	return [];
}
