import { LeadConvertRequest, LeadConvertResponse } from "@salesdesk/salesdesk-schemas";

import { SDApi } from "../../api";

export const leadsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			convertLeadToOpportunity: builder.mutation<
				LeadConvertResponse,
				{ leadRecordId: number; convertLeadBody: LeadConvertRequest }
			>({
				query: ({ leadRecordId, convertLeadBody }) => ({
					url: `/leads/${leadRecordId}/convert`,
					method: "POST",
					body: convertLeadBody,
				}),
				invalidatesTags: (result, error, arg) => [
					{ type: "Record", id: "LIST" },
					{ type: "Record", id: arg.leadRecordId },
				],
			}),
		};
	},
});

export const { useConvertLeadToOpportunityMutation } = leadsApi;
