import { useState, useCallback, useMemo } from "react";

import { SDObject, SDRecord, Field, SDAssociationCreateRequest } from "@salesdesk/salesdesk-schemas";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";

import {
	MAX_BATCH_ASSOCIATION_SIZE,
	getRecordAssociationRequestData,
	useBatchCreateRecordAssociationsMutation,
} from "../../../../recordAssociations";
import { RECORD_ASSOCIATION_KEY } from "../components/RecordCreateForm/RecordCreateForm";
import {
	useRecordForm,
	getFormValuesByPrefix,
	RECORD_FIELDS_KEY,
	getRecordFormFieldId,
	generateCreateRecordRequest,
	RecordFormProps,
} from "../../RecordForm";
import { useToast } from "../../../../Toasts";
import { getRecordCreatedParams } from "../../../../Toasts/utils";
import { DirectedSDObjectAssociation } from "../../../../recordAssociations";
import { countPromiseResults } from "../../../../../utils";
import { useCreateRecord, useGetRecordDefaultPath } from "../../../hooks";

interface UseRecordCreateForm {
	sdObject?: SDObject;
	createAnotherRecord?: boolean;
	onRecordCreateSuccess?: (newSDRecord: SDRecord) => void;
	onRecordCreateFailed?: () => void;
	initialValueMap?: {
		fieldValueMap: Record<string, any>;
		associationValueMap: Record<string, number[]>;
	};
	withAssociations?: boolean;
	showSuccessToast?: boolean;
}

export function useRecordCreateForm({
	sdObject,
	createAnotherRecord,
	onRecordCreateSuccess,
	onRecordCreateFailed,
	initialValueMap,
	withAssociations = true,
	showSuccessToast = true,
}: UseRecordCreateForm) {
	const {
		formId,
		fields,
		onFormStateChange,
		formStateRef,
		objectAssociations,
		loadingObjectAssociations,
		uploadProgressStatus,
		updateUploadProgressStatus,
	} = useRecordForm({ sdObject, withAssociations });

	const [isSubmitting, setIsSubmitting] = useState(false);
	const { createRecord } = useCreateRecord();
	const [batchLinkAssociations] = useBatchCreateRecordAssociationsMutation();

	const getRecordDefaultPath = useGetRecordDefaultPath();

	const toast = useToast();

	const objectAssociationMapById = useMemo(() => {
		return objectAssociations.allAssociations.reduce(
			(objectAssociationMap, objectAssociation) => {
				objectAssociationMap[String(objectAssociation.id)] = objectAssociation;

				return objectAssociationMap;
			},
			{} as Record<string, DirectedSDObjectAssociation>
		);
	}, [objectAssociations]);

	const linkAssociations = async (formData: Record<string, unknown>, createdRecord: SDRecord) => {
		const associationEntries = Object.entries(formData);

		const linkingAssociationData: SDAssociationCreateRequest[] = [];
		associationEntries.forEach((associationEntry) => {
			const [associationId, targetRecordIds = []] = associationEntry as [string, number[]];
			const associationDef = objectAssociationMapById[associationId];

			if (!associationDef) {
				throw new Error(`Association with id ${associationId} was not found.`);
			}

			targetRecordIds.forEach((targetRecordId) => {
				linkingAssociationData.push(getRecordAssociationRequestData(createdRecord, targetRecordId, associationDef));
			});
		});

		if (!linkingAssociationData.length) {
			return;
		}

		const chunks = chunk(linkingAssociationData, MAX_BATCH_ASSOCIATION_SIZE);

		return Promise.allSettled(chunks.map((chunk) => batchLinkAssociations({ associations: chunk }).unwrap())).then(
			(results) => {
				const [successes, fails] = countPromiseResults(results, chunks);
				toast.triggerMessage({
					type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
					messageKey: "batch_record_associations_created",
					messageParams: {
						associations: `${successes} ${pluralizeWithS("association", successes)}`,
						total: String(fails + successes),
					},
				});
			}
		);
	};

	const afterRecordCreationActions = useCallback(
		(newSDRecord: SDRecord, resetForm?: () => void) => {
			if (!sdObject) return;

			if (showSuccessToast) {
				toast.triggerMessage({
					type: "success",
					messageKey: "record_created",
					messageParams: getRecordCreatedParams(sdObject, newSDRecord, getRecordDefaultPath),
				});
			}

			if (createAnotherRecord && resetForm) {
				resetForm();
			}

			if (onRecordCreateSuccess) {
				onRecordCreateSuccess(newSDRecord);
			}

			setIsSubmitting(false);
		},
		[sdObject, showSuccessToast, createAnotherRecord, onRecordCreateSuccess, toast, getRecordDefaultPath]
	);

	const onSubmit = (data: Record<string, any>, resetForm?: () => void) => {
		if (!sdObject) return;

		setIsSubmitting(true);

		const recordData = getFormValuesByPrefix(data, RECORD_FIELDS_KEY);

		const recordCreateRequest = generateCreateRecordRequest(recordData, sdObject);

		createRecord(recordCreateRequest)
			.then((newSDRecord: SDRecord) => {
				const recordAssociationData = getFormValuesByPrefix(data, RECORD_ASSOCIATION_KEY);

				linkAssociations(recordAssociationData, newSDRecord).then(() => {
					afterRecordCreationActions(newSDRecord, resetForm);
				});
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "record_created" });
				if (onRecordCreateFailed) {
					onRecordCreateFailed();
				}
				setIsSubmitting(false);
			});
	};

	return {
		formProps: {
			id: formId,
			fields,
			onSubmit,
			onFormStateChange,
			associations: objectAssociations.allAssociations,
			updateUploadProgressStatus,
			defaultValues: generateDefaultValues(
				fields,
				initialValueMap?.fieldValueMap,
				initialValueMap?.associationValueMap
			),
			sdObject,
		} satisfies RecordFormProps,
		uploadProgressStatus,
		formStateRef,
		isSubmitting,
		onSubmit,
		initialLoading: loadingObjectAssociations,
	};
}

function generateDefaultValues(
	fields: Field[],
	fieldValueMap: Record<string, any> = {},
	associationValueMap: Record<string, number[]> = {}
) {
	const defaultValueMap = {} as Record<string, any>;

	fields.forEach((field) => {
		const fieldDefId = String(field._id);
		defaultValueMap[getRecordFormFieldId(fieldDefId)] = fieldValueMap?.[fieldDefId] ?? field._defaultValue ?? undefined;
	});

	return { [RECORD_FIELDS_KEY]: defaultValueMap, [RECORD_ASSOCIATION_KEY]: associationValueMap };
}
