import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { TranscriptItem, TranscriptItemProps } from "./TranscriptItem";
import { TranscriptFilters } from "./TranscriptFilters";
import { getLongestSentimentType, getTranscriptItemHash } from "../utils";
import { TranscriptSentimentType, UserIdOrPlaceholderName } from "../../../types";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { useActiveItems } from "../hooks/useActiveItems";
import { TranscriptViewLoading } from "./TranscriptViewLoading";
import { keyToPlaceholderName } from "../../../utils";
import { useGetContextWorkspaceId } from "../../../../workspaces";
import clsx from "clsx";

const MINIMUM_SEARCH_LENGTH = 2;

interface TranscriptViewProps {
	search?: string;
	onSearchChange?: (search: string) => void;
	collapsible?: boolean;
}

export function TranscriptView({ search: controlledSearch, onSearchChange, collapsible = true }: TranscriptViewProps) {
	const workspaceId = useGetContextWorkspaceId();

	const { isMediaAnalysisLoading, transcriptItems, transcriptSentimentsPerUser, setOpen } = useMediaAnalysisContext();
	const [selectedTranscriptSentiments, setSelectedTranscriptSentiments] = useState<TranscriptSentimentType[]>([]);
	const [transcriptItemHashes, setTranscriptItemHashes] = useState<string[]>();
	const [selectedUsers, setSelectedUsers] = useState<UserIdOrPlaceholderName[]>([]);

	const [uncontrolledSearch, setUncontrolledSearch] = useState("");

	const isControlledSearch = controlledSearch !== undefined;

	const search = isControlledSearch ? controlledSearch : uncontrolledSearch;
	const setSearch = useCallback(
		(searchValue: string) => {
			if (onSearchChange) {
				onSearchChange(searchValue);
			}

			if (!isControlledSearch) {
				setUncontrolledSearch(searchValue);
			}
		},
		[onSearchChange, isControlledSearch]
	);

	useEffect(() => {
		if (isMediaAnalysisLoading) return;

		if (!transcriptItems) {
			setTranscriptItemHashes([]);
			return;
		}

		const hashPromises = [];
		for (const transcriptItem of transcriptItems) {
			hashPromises.push(getTranscriptItemHash(transcriptItem));
		}
		Promise.all(hashPromises).then(setTranscriptItemHashes);
	}, [transcriptItems, isMediaAnalysisLoading]);

	const allTranscriptItemProps: TranscriptItemProps[] = useMemo(() => {
		if (!transcriptItems || !transcriptItemHashes) {
			return [];
		}
		return transcriptItems.map((transcriptItem, index) => ({
			key: transcriptItemHashes[index],
			transcriptItem,
			longestSentimentType: !transcriptSentimentsPerUser
				? undefined
				: getLongestSentimentType(transcriptItem, transcriptSentimentsPerUser[transcriptItem.speaker]),
			search: "",
			active: false,
		}));
	}, [transcriptItems, transcriptSentimentsPerUser, transcriptItemHashes]);

	const filteredTranscriptItems: TranscriptItemProps[] = useMemo(() => {
		if (!allTranscriptItemProps) {
			return [];
		}
		return allTranscriptItemProps.filter((transcriptItemProps) => {
			const { transcriptItem, longestSentimentType } = transcriptItemProps;
			if (
				longestSentimentType &&
				selectedTranscriptSentiments.length > 0 &&
				!selectedTranscriptSentiments.includes(longestSentimentType)
			) {
				return false;
			}
			if (search.length >= MINIMUM_SEARCH_LENGTH && !transcriptItem.text.toLowerCase().includes(search.toLowerCase())) {
				return false;
			}
			const userIdOrPlaceholder = transcriptItem.userId ?? keyToPlaceholderName(transcriptItem.speaker);
			return selectedUsers.length === 0 || selectedUsers.includes(userIdOrPlaceholder);
		});
	}, [allTranscriptItemProps, search, selectedTranscriptSentiments, selectedUsers]);

	const activeItems = useActiveItems(filteredTranscriptItems);

	const inWorkspaceContext = workspaceId != null;

	if (isMediaAnalysisLoading || !transcriptItemHashes) {
		return <TranscriptViewLoading inWorkspace={inWorkspaceContext} />;
	}

	if (!transcriptItems) {
		return <div className="text-c_text_placeholder flex w-full text-center">Transcript unavailable</div>;
	}

	const rightPadding = inWorkspaceContext ? "" : "pr-6";

	return (
		<div className="relative flex h-full w-full flex-col justify-stretch gap-4">
			<div className={clsx("flex-shrink-0", rightPadding)}>
				{collapsible && (
					<div className="flex h-8 w-full items-center justify-stretch gap-2">
						<Icon icon={ICONS.fileText} className="text-c_icon_regular h-5 leading-5" />
						<div className="text-label-sm text-c_text_primary flex-grow">Transcript</div>
						<Tooltip text="Hide transcript & sentiment">
							<Button variant="text" size="sm" startIcon={ICONS.arrowLineRight} onClick={() => setOpen(false)} />
						</Tooltip>
					</div>
				)}
				<TranscriptFilters
					sentimentTypes={selectedTranscriptSentiments}
					onSentimentTypesChange={setSelectedTranscriptSentiments}
					search={search}
					onSearchChange={setSearch}
					selectedUsers={selectedUsers}
					onSelectedUsersChange={setSelectedUsers}
					activeItems={activeItems}
				/>
			</div>
			<div className={clsx("flex-grow overflow-y-auto", rightPadding)}>
				{filteredTranscriptItems.map((transcriptItemProps) => (
					<TranscriptItem
						{...transcriptItemProps}
						search={search.length >= MINIMUM_SEARCH_LENGTH ? search : ""}
						active={activeItems[transcriptItemProps.key] ?? false}
					/>
				))}
				{filteredTranscriptItems.length === 0 && (
					<div className="text-c_text_placeholder text-center">No transcript found with the current filters.</div>
				)}
			</div>
		</div>
	);
}
