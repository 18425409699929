import { useCallback, useEffect, useMemo, useState } from "react";
import { JSONContent } from "@tiptap/core";
import clsx from "clsx";

import { AbilityAction, AbilitySubject, SDRecord, sdSubject } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../auth";
import { useCreateNoteMutation, useDeleteNoteMutation, useEditNoteMutation, useGetNotesQuery } from "../api/notesApi";
import { Note, NotesVariant } from "../types";
import { Notes } from "./Notes";
import { DeleteConfirmationDialog } from "../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { OnCreateNote } from "./CreateNote";
import { useToast } from "../../Toasts";
import { useNoteReactions } from "../hooks/useNoteReactions";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { NotesLoading } from "./NotesLoading";
import { UserFilter } from "../../events";
import { useNotesFilterAndSearch } from "../hooks/useNotesFilterAndSearch";
import { DebouncedSearchbar } from "../../inputs";
import { useGetContextWorkspaceId, WorkspaceFilter } from "../../workspaces";

interface NotesControllerProps {
	sdRecord: SDRecord;
	onUpdateCount?: (count: number) => void;
	variant?: NotesVariant;
}

export function NotesController({ sdRecord, onUpdateCount, variant = "default" }: NotesControllerProps) {
	const principal = useWebPrincipal();

	const [workspaceFilter, setWorkspaceFilter] = useState<number | undefined>();

	const workspaceContextId = useGetContextWorkspaceId();
	const { data, isLoading } = useGetNotesQuery({
		recordId: sdRecord._id,
		workspaceId: workspaceFilter ?? workspaceContextId,
	});

	const { canReact, toggleReaction } = useNoteReactions(sdRecord);

	const notes = useMemo(() => {
		if (!data) return [];

		return data.map((note) => {
			return {
				id: note.id,
				content: note.message,
				authorId: note.createdBy,
				createdAt: new Date(note.createdAt),
				reactions: note.reactions,
				edited: note.createdAt !== note.updatedAt,
				workspaceId: note.workspaceId,
				editable: principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Note, note)),
				removable: principal.can(AbilityAction.Delete, sdSubject(AbilitySubject.Note, note)),
			} satisfies Note;
		});
	}, [data, principal]);

	const { filteredNotes, setSearchQuery, userFilterValues, setUserFilterValues, isNotesFilterLoading, isFiltered } =
		useNotesFilterAndSearch(sdRecord._id, notes || []);

	const toast = useToast();
	const [createNote, { isLoading: isCreating }] = useCreateNoteMutation();
	const handleCreateNote: OnCreateNote = useCallback(
		(jsonContent, textContent, onSuccess) => {
			createNote({
				workspaceId: workspaceContextId,
				recordId: sdRecord._id,
				note: {
					message: JSON.stringify(jsonContent),
				},
			})
				.unwrap()
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "note_created" });
					onSuccess();
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "note_created" });
				});
		},
		[createNote, workspaceContextId, sdRecord._id, toast]
	);

	useEffect(() => {
		if (onUpdateCount && notes) {
			onUpdateCount(notes.length);
		}
	}, [onUpdateCount, notes]);

	const [noteToBeDeleted, setNoteToBeDeleted] = useState<Note | undefined>();
	const [deleteNote, { isLoading: isDeletingNote }] = useDeleteNoteMutation();
	const handleDeleteNoteClick = (note: Note) => {
		setNoteToBeDeleted(note);
	};

	const handleDeleteNoteConfirmation = () => {
		if (noteToBeDeleted) {
			deleteNote({
				workspaceId: workspaceContextId,
				recordId: sdRecord._id,
				noteId: noteToBeDeleted.id,
			})
				.unwrap()
				.then(() => {
					toast.triggerMessage({ type: "success", messageKey: "note_deleted" });
					setNoteToBeDeleted(undefined);
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "note_deleted" });
				});
		}
	};

	const [editingNoteId, setEditingNoteId] = useState<string | undefined>();
	const [editNote] = useEditNoteMutation();
	const handleEditNote = (note: Note) => {
		setEditingNoteId((prevNoteId) => (prevNoteId === note.id ? undefined : note.id));
	};

	const onEditSubmit = (editedNote: JSONContent) => {
		if (!editingNoteId) return;

		editNote({
			workspaceId: workspaceContextId,
			recordId: sdRecord._id,
			noteId: editingNoteId,
			note: { message: JSON.stringify(editedNote) },
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "note_updated" });
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "note_updated" });
			});
		setEditingNoteId(undefined);
	};

	if (isLoading || data === undefined) {
		return (
			<div className={clsx(variant !== "RightPanel" && "py-4")}>
				<div className="flex w-full justify-between pb-4">
					<Skeleton className="w-22 h-[38px]" />
					<Skeleton className="size-8" />
				</div>
				<NotesLoading />
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col">
			{(notes && notes?.length) || workspaceFilter ? (
				<div className={clsx("flex w-full justify-between", variant === "RightPanel" ? "pb-4" : "pt-4")}>
					<div className="flex gap-4">
						<UserFilter value={userFilterValues} onChange={setUserFilterValues} placement="bottom-start" />
						{workspaceContextId === undefined ? (
							<WorkspaceFilter onChange={setWorkspaceFilter} value={workspaceFilter} />
						) : null}
					</div>
					<DebouncedSearchbar onChange={setSearchQuery} />
				</div>
			) : null}
			<DeleteConfirmationDialog
				title="Delete note for everyone?"
				open={!!noteToBeDeleted}
				isDeleting={isDeletingNote}
				onConfirm={handleDeleteNoteConfirmation}
				onDismiss={() => {
					setNoteToBeDeleted(undefined);
				}}
			>
				The note will be deleted from the record.
			</DeleteConfirmationDialog>
			<Notes
				notes={filteredNotes}
				onCreateNote={handleCreateNote}
				onDeleteClick={handleDeleteNoteClick}
				onEditClick={handleEditNote}
				onToggleReaction={toggleReaction}
				isCreating={isCreating}
				variant={variant}
				canReact={canReact}
				onEditSubmit={onEditSubmit}
				editingNoteId={editingNoteId}
				isFiltered={isFiltered}
				isLoading={isNotesFilterLoading}
			/>
		</div>
	);
}
