import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES, generateText } from "../utils";
import { mStringFieldDef } from "./string_field_def";

export class mRichTextFieldDef extends mStringFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.RICH_TEXT.name];
	static override defaultComponentType: FieldComponentName = mRichTextFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mRichTextFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.RICH_TEXT.icon;
		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.RICH_TEXT.name;
	}

	override get supportedComponentTypes() {
		return mRichTextFieldDef.supportedComponentTypes;
	}

	override supportsTableCellView() {
		return false;
	}

	override supportsCardView() {
		return false;
	}

	static encode(value: string) {
		return {
			type: "doc",
			content: [
				{
					type: "paragraph",
					content: [{ type: "text", text: value }],
				},
			],
		};
	}

	override formatValue(value: any) {
		return typeof value === "string" ? mRichTextFieldDef.encode(value) : value;
	}

	override validate(value: unknown, isTemplate: boolean | undefined) {
		if (typeof value === "boolean" || typeof value === "number") return "Value cannot be boolean or number";
		const isJsonValue = value != null && typeof value === "object" && "type" in value && value?.type === "doc";
		const valueStr = isJsonValue ? generateText(value) : value;
		const error = super.validate(valueStr, isTemplate);
		if (error) return error;
		return undefined;
	}

	override supportsMaxLength() {
		return false;
	}
}
