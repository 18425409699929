import { ICONS } from "@salesdesk/salesdesk-ui";

import { GeneralRecordActions, RecordActionButton } from "../../../common";
import { Divider } from "../../../../../../../../components/Divider/Divider";
import { Button } from "@salesdesk/daisy-ui";

interface RecordTopBarProps {
	onClose: () => void;
}

export function RecordTopBar({ onClose }: RecordTopBarProps) {
	return (
		<div className="flex min-h-[38px] max-w-full justify-between truncate">
			<div className="flex shrink truncate">
				<RecordActionButton />
			</div>
			<div className="flex shrink-0 items-center gap-2 pl-2">
				<GeneralRecordActions inRightHandPanel={true} />
				<Divider variant="vertical" />
				{/* TODO: fix types and set onClick={onClose} */}
				<Button startIcon={ICONS.cross} variant="text" size="sm" onClick={onClose} />
			</div>
		</div>
	);
}
