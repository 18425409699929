import { CreateRuleButton } from "./CreateRuleButton";
import { useGetNotificationRulesQuery } from "../../api/notificationRuleApi";
import { useMemo } from "react";
import { NotificationRule } from "@salesdesk/salesdesk-schemas";
import { NotificationRules } from "./NotificationRules";
import { PATHS } from "../../../../../routes";
import { SettingsContainer } from "../../../SettingsContainer";
import { ICONS } from "@salesdesk/salesdesk-ui";

export function NotificationRulesPage() {
	const { data: notificationRules, isLoading } = useGetNotificationRulesQuery();

	const sortedNotificationRules = useMemo(() => {
		if (!notificationRules) return [];
		return [...notificationRules].sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
	}, [notificationRules]);

	const [activeNotificationRules, inactiveNotificationRules] = useMemo(() => {
		const activeRules: NotificationRule[] = [];
		const inactiveRules: NotificationRule[] = [];
		for (const rule of sortedNotificationRules || []) {
			if (rule.enabled) {
				activeRules.push(rule);
			} else {
				inactiveRules.push(rule);
			}
		}
		return [activeRules, inactiveRules];
	}, [sortedNotificationRules]);

	return (
		<SettingsContainer
			breadcrumbs={[
				{
					node: "Settings",
					icon: ICONS.settings,
				},
				{
					node: "Notifications",
					link: PATHS.SETTINGS_NOTIFICATIONS(),
				},
				{
					node: "Notification rules",
					link: PATHS.SETTINGS_NOTIFICATION_RULES(),
				},
			]}
		>
			<div className="flex h-full flex-col">
				<div className="flex w-full justify-end px-8 py-4">
					<CreateRuleButton />
				</div>
				<div className="flex w-full grow flex-col gap-8 overflow-y-auto px-8 pb-8">
					<NotificationRules
						rules={activeNotificationRules}
						title="Active rules"
						defaultOpen={true}
						isLoading={isLoading}
					/>
					<NotificationRules
						rules={inactiveNotificationRules}
						title="Inactive rules"
						defaultOpen={false}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</SettingsContainer>
	);
}
