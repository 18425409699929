import { forwardRef } from "react";
import clsx from "clsx";

import { BooleanInputProps } from "../../types";

export const RadioButton = forwardRef<HTMLButtonElement, BooleanInputProps>(
	({ id, value, onChange, onBlur, ariaAttributes, disabled }, ref) => (
		<button
			id={id}
			ref={ref}
			{...ariaAttributes}
			role="radio"
			aria-checked={value ? "true" : "false"}
			onClick={() => onChange(!value)}
			onBlur={onBlur}
			className={clsx(
				disabled ? "border-c_bg_disabled_01" : "border-c_action_01",
				value ? "border-[6px]" : "border-[3px]",
				"focus-visible:border-c_brand_primary focus-visible:ring-c_action_focus focus-visible:outline-none focus-visible:ring",
				"inline-flex h-5 w-5 items-center justify-center rounded-full border-solid transition-all duration-100"
			)}
		/>
	)
);
