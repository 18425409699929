import { MenuItem } from "../../../features/menu";
import { Icon } from "@salesdesk/daisy-ui";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

interface AdminNavItemProps {
	item: MenuItem;
	variant?: "primary" | "secondary";
}

export function NavButton({ item, variant = "primary" }: AdminNavItemProps) {
	const isPrimary = variant === "primary";

	if (item.type === "subMenu") {
		return (
			<div className="flex items-center gap-4 py-3">
				<div className="flex w-5 items-center">{item.icon ? <Icon icon={item.icon} /> : null}</div>
				<div className="text-label-nav">{item.text}</div>
			</div>
		);
	}

	if (item.type === "link" && item.link) {
		return (
			<NavLink to={item.link} className="flex items-center gap-4 py-1 pl-5">
				{({ isActive }) => (
					<div
						className={clsx(
							"text-label-nav hover:bg-c_bg_03 w-full truncate rounded-full px-4 py-2",
							isActive && (isPrimary ? "bg-c_bg_01" : "bg-c_bg_02")
						)}
					>
						{item.text}
					</div>
				)}
			</NavLink>
		);
	}
}
