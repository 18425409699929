import { forwardRef, useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { ColorPickerInputProps } from "../../../types";

import { UnsetPickerButton } from "../UnsetPickerButton";
import { ColorSwatch } from "./ColorSwatch";
import { ColorPalette } from "./ColorPalette";
import { ColorInspector } from "./ColorInspector";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { Divider } from "../../../../../components/Divider/Divider";
import { Button } from "@salesdesk/daisy-ui";

const paletteColors = [
	"#dc2626",
	"#ea580c",
	"#65a30d",
	"#0d9488",
	"#0891b2",
	"#2563eb",
	"#7c3aed",
	"#c026d3",
	"#db2777",
];

export const ColorPicker = forwardRef<HTMLInputElement, ColorPickerInputProps>(
	({ value = null, onChange, placeholder, disabled, hasError, ...inputProps }, ref) => {
		const [isPickerOpen, setIsPickerOpen] = useState(false);
		const [isPopOverOpen, setIsPopOverOpen] = useState(false);

		const handleChange = (colorValue: string | null) => {
			// this if condition fixes #NaNNaN error
			// steps to reproduce: open picker with no set value. adjust bottom picker
			if (typeof colorValue === "string" && colorValue.includes("NaN")) {
				onChange("#ffffff");

				return;
			}

			if (typeof colorValue === "string" && colorValue[0] !== "#") {
				// TODO: add more strict mask for hex value
				onChange(`#${colorValue}`);

				return;
			}

			onChange(colorValue);
		};

		return (
			<Popover placement="bottom-start" open={isPopOverOpen} onOpenChange={setIsPopOverOpen}>
				<PopoverTrigger>
					{value ? (
						<ColorSwatch
							active={isPopOverOpen}
							hasBorder
							size="lg"
							value={value}
							disabled={disabled}
							hasError={hasError}
						/>
					) : (
						<UnsetPickerButton placeholder={placeholder || "Add color"} disabled={disabled} hasError={hasError} />
					)}
				</PopoverTrigger>
				<PopoverContent>
					<PopoverContainer>
						<div className="px-6 py-4">
							<ColorPalette value={value} paletteColors={paletteColors} onClick={handleChange} />
							<Divider className="my-4 w-full" />
							{isPickerOpen ? (
								<ColorInspector
									colorPalette={paletteColors}
									ref={ref}
									value={value}
									onChange={handleChange}
									{...inputProps}
								/>
							) : (
								<div className="flex w-full justify-center">
									<Button
										size="lg"
										variant="primary_text"
										startIcon={ICONS.simplePencil}
										onClick={() => setIsPickerOpen(true)}
									>
										More colors
									</Button>
								</div>
							)}
						</div>
					</PopoverContainer>
				</PopoverContent>
			</Popover>
		);
	}
);
