import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { SkeletonCard } from "../../../../components/Skeleton/SkeletonCard";
import { SkeletonCompactFilePreview } from "../../../files";

interface SkeletonRecordAssociationCardProps {
	isAssetAssociation?: boolean;
	rows?: number;
}

export function SkeletonRecordAssociationCard({ isAssetAssociation, rows = 3 }: SkeletonRecordAssociationCardProps) {
	return isAssetAssociation ? (
		<div className="h-full w-full truncate">
			<div className="@[576px]/association-list:hidden w-full truncate p-2">
				<SkeletonCompactFilePreview />
			</div>
			<div className="@[576px]/association-list:block hidden h-full w-full truncate">
				<SkeletonCard>
					<div className="flex flex-col gap-5">
						<Skeleton className="rounded-card aspect-video w-full" rounded={false} />
						<Skeleton className="h-6 w-full" />
						<Skeleton className="h-6 w-1/2" />
					</div>
				</SkeletonCard>
			</div>
		</div>
	) : (
		<SkeletonCard>
			<div className="flex max-w-80 flex-col gap-5">
				<Skeleton className="h-6 w-full" />
				{Array.from({ length: rows }).map((_, index) => (
					<Skeleton key={index} className={index % 2 === 0 ? "h-6 w-2/3" : "h-6 w-1/2"} />
				))}
			</div>
		</SkeletonCard>
	);
}
