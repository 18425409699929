import { useMemo } from "react";

import { FieldInfo, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mDocDef } from "@salesdesk/salesdesk-model";

import { DocumentPreview } from "../../../../document";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../fields";
import { useSdFile } from "../../../../files/hooks/useSdFile";

export function useMediaFieldDetails(sdRecord: SDRecord, mediaFieldInfo?: FieldInfo) {
	const mediaFieldDisplay = useMemo(() => {
		if (!mediaFieldInfo) {
			return null;
		}

		const { field, value } = mediaFieldInfo;

		if (sdRecord._objectDefId === mDocDef.ID && mediaFieldInfo.field._name === mDocDef.DOCUMENT_FIELD_NAME) {
			return <DocumentPreview key={field._id} documentContents={value ?? undefined} variant="sm" />;
		}

		return (
			<div className="pointer-events-none w-full">
				<DisplayFieldFactory key={field._id} field={field} value={value} variant={DisplayFieldVariant.card} />
			</div>
		);
	}, [mediaFieldInfo, sdRecord._objectDefId]);

	const mediaFieldFileId =
		mediaFieldDisplay && mediaFieldInfo?.value && typeof mediaFieldInfo.value === "number"
			? mediaFieldInfo.value
			: null;
	const { sdFile: mediaFieldFile } = useSdFile(mediaFieldFileId);

	return { mediaFieldDisplay, mediaFieldFile };
}
