import { useState, useEffect, useRef, FocusEvent } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Droppable, Draggable, DragDropContext, DropResult } from "@hello-pangea/dnd";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { SortingRow } from "./SortingRow";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { SelectOption } from "../../../../inputs";
import { SortingDetails } from "../types";

const SORTING_FIELD_ARRAY_NAME = "sorting";
const SORTING_POPOVER_DROPPABLE_ID = "sortingPopoverDroppableId";

type SortingFormValue = { [SORTING_FIELD_ARRAY_NAME]: SortingDetails[] };

interface SortingPopoverProps {
	value: SortingDetails[];
	onChange: (value: SortingDetails[]) => void;
	disabled?: boolean;
	fieldOptions: SelectOption[][];
	defaultValues: SortingDetails[];
	iconOnly?: boolean;
}

export function SortingPopover({
	value,
	onChange,
	disabled,
	fieldOptions,
	defaultValues,
	iconOnly,
}: SortingPopoverProps) {
	const popoverContainerRef = useRef(null);

	const [isOpen, setIsOpen] = useState(false);
	const [draggableElementTopPosition, setDraggableElementTopPosition] = useState<number>(0);
	const [draggableElementIndex, setDraggableElementIndex] = useState<number | null>(null);

	const [keyBoardDraggableRowSort, setKeyBoardDraggableRowSort] = useState<HTMLDivElement | null>(null);

	const { control, watch, reset } = useForm<SortingFormValue>({
		mode: "onChange",
		defaultValues: { [SORTING_FIELD_ARRAY_NAME]: defaultValues },
	});

	const {
		fields: sortingFields,
		remove,
		append,
		move,
	} = useFieldArray<SortingFormValue>({
		control,
		name: SORTING_FIELD_ARRAY_NAME,
	});

	// Keeps the sort form state in sync with the data board when the popover is closed,
	// then the form keeps track of its own state
	useEffect(() => {
		if (isOpen) {
			return;
		}

		reset({
			[SORTING_FIELD_ARRAY_NAME]: value.length ? value : defaultValues,
		});
	}, [value, reset, isOpen, defaultValues]);

	const sortValues = watch();

	useEffect(() => {
		if (!isOpen) {
			return;
		}

		const newSorts = sortValues[SORTING_FIELD_ARRAY_NAME] || [];
		onChange([...newSorts]);

		if (!newSorts.length) {
			defaultValues.forEach((defaultValue) => append(defaultValue));
			setIsOpen(false);
		}
	}, [isOpen, sortValues, defaultValues, append, onChange]);

	const computeDraggableElementTopPosition = (topPosition: number, index: number) => {
		if (!popoverContainerRef.current) return;

		const { top: popoverContainerTopPosition } = (
			popoverContainerRef.current as HTMLDivElement
		).getBoundingClientRect();

		setDraggableElementTopPosition(topPosition - popoverContainerTopPosition);
		setDraggableElementIndex(index);
	};

	const onDragEnd = (dropResult: DropResult) => {
		const { source, destination } = dropResult;

		if (!destination) {
			return;
		}

		move(source.index, destination.index);

		if (keyBoardDraggableRowSort) {
			/**
			 * Recalculate top position on next tick to be sure that draggable element in correct position
			 *  */
			setTimeout(() => {
				const { top } = (keyBoardDraggableRowSort as HTMLDivElement).getBoundingClientRect();
				computeDraggableElementTopPosition(top, destination.index);
			}, 0);
		}
	};

	const onSortRowFocus = (index: number) => (event: FocusEvent<HTMLDivElement>) => {
		const { target } = event;

		if (!target) return;

		const { top } = (target as HTMLDivElement).getBoundingClientRect();

		setKeyBoardDraggableRowSort(target);
		computeDraggableElementTopPosition(top, index);
	};

	const buttonText = "Sort";

	return (
		<Popover open={isOpen} placement="bottom-end" onOpenChange={setIsOpen} dismissOnAncestorScroll={false}>
			<PopoverTrigger>
				<Tooltip text={iconOnly && !isOpen ? buttonText : undefined}>
					<Button disabled={disabled} startIcon={ICONS.sort} variant="secondary" size="sm" alertCount={value.length}>
						{iconOnly ? null : buttonText}
					</Button>
				</Tooltip>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverContainer ref={popoverContainerRef} className="overflow-visible">
					<div className="relative flex min-w-[480px] flex-col py-2">
						<div className="text-body-sm text-c_text_secondary flex items-center justify-between px-6 py-1">
							Sort using conditions
							<Button size="sm" variant="primary_text" onClick={() => remove()}>
								Clear all
							</Button>
						</div>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId={SORTING_POPOVER_DROPPABLE_ID}>
								{(providedDroppable) => (
									<div
										className="flex max-h-[400px] min-h-[54px] flex-col overflow-auto px-6 pb-2 pt-0.5"
										ref={providedDroppable.innerRef}
										{...providedDroppable.droppableProps}
									>
										{sortingFields.map((field, index) => (
											<Draggable key={field.id} draggableId={String(field.id)} index={index}>
												{(providedDraggable) => {
													const extraStyles: Record<string, string> = {
														left: "auto !important",
													};

													if (draggableElementIndex === index) {
														extraStyles.top = `${draggableElementTopPosition}px`;
													}

													return (
														<div
															className="focus-visible:ring-c_action_focus rounded-sm focus-visible:ring"
															ref={providedDraggable.innerRef}
															{...providedDraggable.draggableProps}
															{...providedDraggable.dragHandleProps}
															style={{
																...providedDraggable.draggableProps.style,
																...extraStyles,
															}}
															onFocus={onSortRowFocus(index)}
														>
															<Controller
																key={field.id}
																name={`${SORTING_FIELD_ARRAY_NAME}.${index}`}
																control={control}
																render={({ field: { onChange, value } }) => (
																	<SortingRow
																		index={index}
																		key={field.id}
																		value={value}
																		onChange={onChange}
																		fieldOptions={fieldOptions}
																		onDelete={() => remove(index)}
																		onRowMouseDown={computeDraggableElementTopPosition}
																	/>
																)}
															/>
														</div>
													);
												}}
											</Draggable>
										))}
										{providedDroppable.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						<div className="border-t-c_border_regular mx-6 border-t pb-2 pt-4">
							<Button
								variant="secondary"
								startIcon={ICONS.plus}
								size="sm"
								onClick={() => {
									defaultValues.forEach((defaultValue) => append(defaultValue));
								}}
							>
								New sort
							</Button>
						</div>
					</div>
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
