import { useEffect, useRef } from "react";
import clsx from "clsx";

import { tw } from "../../../../../utils/tailwind-helpers";
import { useVideoCallLogicContext } from "../../VideoCallProvider";
import { CallParticipant, ParticipantAudioTracks } from "../../common";

import { useGetDisplayedParticipants } from "../hooks/useGetDisplayedParticipants";
import { useGetDivHeight } from "../hooks/useGetDivHeight";

interface MinimisedCallViewProps {
	onHeightUpdate: () => void;
}

export function MinimisedCallView({ onHeightUpdate }: MinimisedCallViewProps) {
	const { room, currentDominantSpeakerIdentity } = useVideoCallLogicContext();
	const { displayedParticipantDetails, hasPresenters } = useGetDisplayedParticipants();

	const containerRef = useRef<HTMLDivElement>(null);

	const height = useGetDivHeight(containerRef);

	let maxHeight: string | undefined;
	if (hasPresenters && displayedParticipantDetails.length > 4) {
		maxHeight = tw`max-h-[563px]`;
	} else if (!hasPresenters && displayedParticipantDetails.length > 3) {
		maxHeight = tw`max-h-[349px]`;
	}

	// Scroll to top whenever the max height changes to prevent weird scroll positions
	// as people join/leave the call or start/stop presenting
	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scroll({ top: 0, behavior: "smooth" });
		}
	}, [maxHeight]);

	const isTwoPersonView = !hasPresenters && displayedParticipantDetails.length === 2;

	useEffect(() => {
		onHeightUpdate();
	}, [height, onHeightUpdate]);

	return (
		// TODO: A loading state while the user is joining the call
		<div ref={containerRef} className={clsx("flex flex-row flex-wrap gap-4 overflow-y-auto", maxHeight)}>
			{displayedParticipantDetails.map((participantDetails, index) => {
				const isSmall = index > (hasPresenters || isTwoPersonView ? 1 : 0);

				const { variant, participant, name } = participantDetails;

				let displayText;

				// If it's a presentation
				if (variant === "screen_share" || variant === "file") {
					displayText = `Presentation (${name})`;
				}

				const key = participant.sid + variant;
				return (
					<div
						key={key}
						className={clsx("aspect-4/3 w-full shrink-0")}
						style={{
							maxWidth: isSmall ? "calc(50% - 0.5rem)" : undefined,
						}}
					>
						<CallParticipant
							participantDetails={participantDetails}
							dominantSpeaker={
								participantDetails.variant === "default"
									? currentDominantSpeakerIdentity === participantDetails.participant.identity
									: false
							}
							isLocal={participantDetails.participant === room?.localParticipant}
							isSmall={isSmall}
							displayText={displayText}
						/>
					</div>
				);
			})}
			<ParticipantAudioTracks />
		</div>
	);
}
