import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";

import { Import, ImportJob, ImportMappingEntry, ImportMappingEntryCreateRequest } from "@salesdesk/salesdesk-schemas";

import { useGetObjectFromSlug } from "../../../../hooks";
import { PATHS, useStableNavigate } from "../../../../routes";
import { ImportContext, ImportState } from "../../hooks/useImportContext";
import { useImportMappingsQuery } from "../../api/importApi";
import { ExitConfirmDialog } from "./ExitConfirmDialog";

export function ImportProvider({ children }: PropsWithChildren) {
	const { sdObjectPath: objectPathSlug } = useParams<{ sdObjectPath: string }>();
	const { sdObject } = useGetObjectFromSlug(objectPathSlug);

	const [sdImport, setSdImport] = useState<Import>();
	const [previewImportJob, setPreviewImportJob] = useState<ImportJob | undefined>();
	const [actualImportJob, setActualImportJob] = useState<ImportJob | undefined>();

	const { data: importMappings, isLoading: importMappingsIsLoading } = useImportMappingsQuery(
		sdImport?.id ?? actualImportJob?.importId ?? skipToken
	);

	const [selectedMappingEntry, setSelectedMappingEntry] = useState<ImportMappingEntry | undefined>();
	useEffect(() => {
		setSelectedMappingEntry(importMappings?.[0]);
	}, [importMappings]);

	const [mappingEntries, setMappingEntries] = useState<ImportMappingEntryCreateRequest[]>([]);

	const updateMappingEntry = useCallback((columnName: string, fieldId: number | undefined) => {
		setMappingEntries((previousEntries) => {
			const index = previousEntries.findIndex((e) => e.columnName === columnName);
			const newEntries = [...previousEntries];
			if (index === -1) {
				if (fieldId) newEntries.push({ columnName, fieldId });
			} else {
				if (fieldId) newEntries[index] = { columnName, fieldId };
				else newEntries.splice(index, 1);
			}
			return newEntries;
		});
	}, []);

	const clearMappingEntries = useCallback(() => {
		setMappingEntries([]);
	}, []);

	const [exitDialogOpen, setExitDialogOpen] = useState(false);
	const navigate = useStableNavigate();

	const state: ImportState | undefined = useMemo(() => {
		if (!sdObject) return undefined;
		return {
			sdObject,
			sdImport,
			setSdImport,
			importMappings,
			importMappingsIsLoading,
			mappingEntries,
			updateMappingEntry,
			clearMappingEntries,
			selectedMappingEntry,
			setSelectedMappingEntry,
			previewImportJob,
			setPreviewImportJob,
			actualImportJob,
			setActualImportJob,
			setExitDialogOpen,
		};
	}, [
		sdObject,
		sdImport,
		importMappings,
		importMappingsIsLoading,
		mappingEntries,
		updateMappingEntry,
		clearMappingEntries,
		selectedMappingEntry,
		setSelectedMappingEntry,
		previewImportJob,
		setPreviewImportJob,
		actualImportJob,
		setActualImportJob,
		setExitDialogOpen,
	]);

	if (!state) return null;

	return (
		<ImportContext.Provider value={state}>
			{children}
			{exitDialogOpen ? (
				<ExitConfirmDialog
					onDismiss={() => setExitDialogOpen(false)}
					onConfirm={() => {
						if (sdObject) {
							navigate(PATHS.OBJECT_RECORD_BOARD(sdObject));
						}
					}}
				/>
			) : null}
		</ImportContext.Provider>
	);
}
