import { useImportContext } from "../../../hooks/useImportContext";

interface ColumnMappingHeaderRowProps {
	requiredFieldCount: number;
	selectedRequiredFieldCount: number;
}

export function ColumnMappingHeaderRow({
	requiredFieldCount,
	selectedRequiredFieldCount,
}: ColumnMappingHeaderRowProps) {
	const { sdObject } = useImportContext();

	return (
		<div className="bg-c_bg_03 gutter-stable flex min-h-10 w-full shrink-0 items-center overflow-y-hidden">
			<div className="text-label grow px-4 py-2">Columns in your file</div>
			<div className="w-[45%] px-4 py-2">
				<div className="flex w-full items-center justify-between gap-2">
					<div className="text-label">{sdObject._pluralName} fields</div>
					<div className="text-c_text_secondary text-label-xs">
						Required: {selectedRequiredFieldCount}/{requiredFieldCount}
					</div>
				</div>
			</div>
		</div>
	);
}
