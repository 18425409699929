import { forwardRef, useEffect, useState } from "react";

import { mUserDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetRecordOptionsInWorkspace } from "../../../fields";
import { BaseSelect, BaseSelectButtonProps, SelectOption } from "../../../inputs";
import { useMediaAnalysisContext } from "../../hooks/useMediaAnalisysContext";
import { Avatar, getInitials } from "../../../users";
import { Icon } from "@salesdesk/daisy-ui";

interface UserSelectProps {
	userIds: number[];
	value: number;
	onChange: (userId: number) => void;
}

export function UserSelect({ userIds, value, onChange }: UserSelectProps) {
	const { setAnyPopoverSelectOpen } = useMediaAnalysisContext();
	const { getOptionsFromIds } = useGetRecordOptionsInWorkspace({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
	});
	const [userOptions, setUserOptions] = useState<SelectOption[]>();

	useEffect(() => {
		getOptionsFromIds(userIds).then(setUserOptions);
	}, [getOptionsFromIds, userIds]);

	if (!userOptions) {
		return null;
	}

	return (
		<BaseSelect
			options={userOptions}
			optionsTitle="User"
			onChange={(value) => onChange(Number(value))}
			value={value}
			selectButton={<AvatarButton />}
			optionDisplayVariant="user_pill"
			onOpenChange={(isOpen) => {
				setAnyPopoverSelectOpen(isOpen);
			}}
		/>
	);
}

const AvatarButton = forwardRef<HTMLButtonElement, BaseSelectButtonProps>(({ selectedOption, ...restProps }, ref) => {
	if (!selectedOption) return null;

	return (
		<button ref={ref} className="flex items-center gap-2" {...restProps}>
			<div className="border-c_action_focus rounded-full border-2 p-0.5">
				<Avatar initials={getInitials(selectedOption.name)} size="xs" avatarFileId={selectedOption.imageFileId} />
			</div>
			<Icon icon={ICONS.caretDown} size="sm" />
		</button>
	);
});
