import { useGetRecordOptions } from "../../fields";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import { debounce, removeElementFromIndex } from "../../../utils";
import { MentionPopoverWrapper } from "./MentionPopoverWrapper";
import { SelectOption, SelectOptionId } from "../../inputs";
import { Point } from "../../../utils/types";
import { Spinner } from "@salesdesk/daisy-ui";
import { useWebPrincipal } from "../../../auth";
import { Divider } from "../../../components/Divider/Divider";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { MentionOption } from "./MentionOption";
import { CHIME_CHANNEL_EVERYONE_MENTION_ID } from "@salesdesk/salesdesk-schemas";

const EVERYONE_OPTION = {
	id: CHIME_CHANNEL_EVERYONE_MENTION_ID,
	name: "everyone",
	icon: ICONS.at,
	description: "Mention all group members",
};

interface MentionListProps {
	query: string;
	setOnArrowUp: (func: () => undefined) => void;
	setOnArrowDown: (func: () => undefined) => void;
	setOnEnter: (func: () => undefined) => void;
	onSelect: (option: SelectOption) => void;
	clientPointDetails: Point;
	workspaceId?: number;
	chatChannelDetails?: {
		chatChannelArn: string;
		channelMembers: number[];
	};
}

export function MentionPopover({
	query,
	setOnArrowUp,
	setOnArrowDown,
	setOnEnter,
	onSelect,
	clientPointDetails,
	workspaceId,
	chatChannelDetails,
}: MentionListProps) {
	const principal = useWebPrincipal();

	const id = useId();

	const mentionIdPrefix = `mention-option-${id}-`;

	const [open, setOpen] = useState(true);

	useEffect(() => {
		setOpen(true);
	}, [query]);

	const inChatChannel = Boolean(chatChannelDetails);
	const supportsEveryoneOption = inChatChannel && (chatChannelDetails?.channelMembers?.length ?? 0) > 2;
	const inWorkspace = workspaceId != null;

	const { getOptions: primaryGetOptions } = useGetRecordOptions({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
		sharedWithWorkspaceId: workspaceId,
		includeCustomerUserRecordsWhenNoWorkspace: inChatChannel,
		onlyIncludeRecordIds: chatChannelDetails?.channelMembers,
		onlyAuthorizedToLogIn: true,
	});

	// For custom and DM channels you can mention users that are not in the channel, this
	// is fetched by secondaryGetOptions
	const { getOptions: secondaryGetOptions } = useGetRecordOptions({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
		sharedWithWorkspaceId: workspaceId,
		includeCustomerUserRecordsWhenNoWorkspace: inChatChannel,
		excludeRecordIds: chatChannelDetails?.channelMembers,
		onlyAuthorizedToLogIn: true,
	});

	const [isLoading, setIsLoading] = useState(true);
	const [primaryOptions, setPrimaryOptions] = useState<SelectOption[]>([]);
	const [secondaryOptions, setSecondaryOptions] = useState<SelectOption[]>([]);
	const [showEveryoneOption, setShowEveryoneOption] = useState(false);

	const combinedOptions = useMemo(() => {
		const options = [...primaryOptions, ...secondaryOptions];

		if (showEveryoneOption) {
			return [EVERYONE_OPTION, ...options];
		}

		return options;
	}, [primaryOptions, secondaryOptions, showEveryoneOption]);

	const [activeOptionId, setActiveOptionId] = useState<SelectOptionId>();

	const debouncedGetOptions = useMemo(() => {
		return debounce(async (query: string) => {
			if (inChatChannel && !inWorkspace) {
				const [primaryOptions, secondaryOptions] = await Promise.all([
					primaryGetOptions(query),
					secondaryGetOptions(query),
				]);

				return { primaryOptions, secondaryOptions };
			} else {
				const primaryOptions = await primaryGetOptions(query);
				return { primaryOptions, secondaryOptions: [] };
			}
		}, 200);
	}, [inChatChannel, inWorkspace, primaryGetOptions, secondaryGetOptions]);

	useEffect(() => {
		let active = true;
		debouncedGetOptions(query)
			.then((debouncePromise) => debouncePromise)
			.then(({ primaryOptions, secondaryOptions }) => {
				if (!active) {
					return;
				}

				primaryOptions = updateCurrentUserInOptions(primaryOptions, principal.UserRecordId);
				secondaryOptions = updateCurrentUserInOptions(secondaryOptions, principal.UserRecordId);

				setIsLoading(false);
				setPrimaryOptions(primaryOptions);
				setSecondaryOptions(secondaryOptions);

				const showEveryoneOption = supportsEveryoneOption && EVERYONE_OPTION.name.includes(query.toLowerCase());
				setShowEveryoneOption(showEveryoneOption);

				setActiveOptionId(showEveryoneOption ? EVERYONE_OPTION.id : (primaryOptions[0]?.id ?? secondaryOptions[0]?.id));
			});

		// Clean-up used to prevent race condition, we only want the results of
		// the most recent call of the `debouncedGetOptions` function.
		// https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect#fixing-the-useeffect-race-condition
		return () => {
			active = false;
		};
	}, [debouncedGetOptions, query, principal.UserRecordId, supportsEveryoneOption]);

	const handleSelect = useCallback(
		(option: SelectOption) => {
			// Removes the ' (You)' from the option name when selecting yourself
			if (option.id === principal.UserRecordId) {
				option = { ...option, name: option.name.replace(/ \(You\)$/, "") };
			}
			onSelect(option);
		},
		[onSelect, principal.UserRecordId]
	);

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const move = (direction: 1 | -1) => {
			const activeOptionIndex = combinedOptions.findIndex((option) => option.id === activeOptionId);
			if (activeOptionIndex === -1) return;
			if (direction === -1 && activeOptionIndex === 0) return;
			if (direction === 1 && activeOptionIndex === combinedOptions.length - 1) return;

			const newActiveOptionId = combinedOptions[activeOptionIndex + direction].id;
			setActiveOptionId(newActiveOptionId);

			if (!scrollContainerRef.current) return;

			// We only want to scroll when the active option is set by the arrow keys
			const activeElement = scrollContainerRef.current?.querySelector(
				`#${CSS.escape(`${mentionIdPrefix}${newActiveOptionId}`)}`
			);
			if (activeElement) {
				activeElement.scrollIntoView({ block: "nearest", behavior: "instant" });
			}
		};
		setOnArrowDown(() => {
			move(1);
		});
		setOnArrowUp(() => {
			move(-1);
		});
		setOnEnter(() => {
			const activeOption = combinedOptions.find((option) => option.id === activeOptionId);
			if (activeOption) {
				handleSelect(activeOption);
			}
		});
	}, [activeOptionId, handleSelect, mentionIdPrefix, combinedOptions, setOnArrowDown, setOnArrowUp, setOnEnter]);

	if (combinedOptions.length === 0 && !isLoading) {
		return null;
	}

	const sharedOptionProps = {
		onClick: handleSelect,
		onMouseOver: (option: SelectOption) => {
			setActiveOptionId(option.id);
		},
		activeOptionId,
	};

	return (
		<MentionPopoverWrapper open={open} onOpenChange={setOpen} clientPointDetails={clientPointDetails}>
			<div ref={scrollContainerRef} className="relative max-h-[290px] overflow-y-auto">
				{isLoading ? (
					<div className="my-8 flex justify-center">
						<Spinner />
					</div>
				) : (
					<>
						{showEveryoneOption ? (
							<MentionOption
								id={`${mentionIdPrefix}${CHIME_CHANNEL_EVERYONE_MENTION_ID}`}
								option={EVERYONE_OPTION}
								{...sharedOptionProps}
							/>
						) : null}
						{showEveryoneOption && (secondaryOptions.length > 0 || primaryOptions.length > 0) ? <Divider /> : null}
						{primaryOptions.map((option) => (
							<MentionOption
								id={`${mentionIdPrefix}${option.id}`}
								key={option.id}
								option={option}
								{...sharedOptionProps}
							/>
						))}
						{secondaryOptions.length > 0 && primaryOptions.length > 0 ? <Divider /> : null}
						{secondaryOptions.length > 0 ? (
							<div className="bg-c_bg_01 text-body-sm text-c_text_secondary sticky top-0 z-10 w-full px-6 py-2">
								Users not in this chat
							</div>
						) : null}
						{secondaryOptions.map((option) => (
							<MentionOption
								id={`${mentionIdPrefix}${option.id}`}
								key={option.id}
								option={option}
								{...sharedOptionProps}
							/>
						))}
					</>
				)}
			</div>
		</MentionPopoverWrapper>
	);
}

function updateCurrentUserInOptions(options: SelectOption[], currentUserId: number) {
	const currentUserIndex = options.findIndex((option) => option.id === currentUserId);

	if (currentUserIndex !== -1) {
		const currentUser = options[currentUserIndex];
		return [
			...removeElementFromIndex(options, currentUserIndex),
			{ ...currentUser, name: `${currentUser.name} (You)` },
		];
	}

	return options;
}
