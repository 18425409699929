import { ChatPanel } from "./ChatPanel";
import { useMeetingPageContext } from "../../../../../../hooks";
import { useVideoCallLogicContext } from "../../../../../../../VideoCallProvider";
import { useChatMessageGroups } from "../../../../../../../../../chat";

export function ChatPanelContainer() {
	const { participantRecords } = useMeetingPageContext();

	const { sortedChatMessages, postChatMessage, isChatLoading } = useVideoCallLogicContext();
	const messageGroups = useChatMessageGroups(sortedChatMessages, participantRecords);

	return <ChatPanel messageGroups={messageGroups} postMessage={postChatMessage} isChatLoading={isChatLoading} />;
}
