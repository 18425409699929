import {
	mAccountDef,
	mAssetDef,
	mContactDef,
	mDocDef,
	mGeneralAssetDef,
	mImageAssetDef,
	mInteractionMeetingDef,
	mLeadDef,
	mMediaAssetDef,
	mOpportunityDef,
	mPdfAssetDef,
	mProductDef,
	mSalesDeskUserDef,
	mTaskDef,
	mIssueDef,
} from "@salesdesk/salesdesk-model";

export const ASSET_OBJECT_IDS = [mGeneralAssetDef.ID, mMediaAssetDef.ID, mPdfAssetDef.ID, mImageAssetDef.ID];

export const BASE_SYSTEM_OBJECTS_ORDER = [
	mTaskDef.ID,
	mOpportunityDef.ID,
	mProductDef.ID,
	mContactDef.ID,
	mAccountDef.ID,
	mLeadDef.ID,
	mAssetDef.ID,
	mDocDef.ID,
	mIssueDef.ID,
	mInteractionMeetingDef.ID,
];

export const USER_OBJECT_DEF_IDS = [mSalesDeskUserDef.ID, mContactDef.ID, mLeadDef.ID];

export const CUSTOMER_NOT_OWNED_EDITABLE_OBJECT_IDS = [mTaskDef.ID, mDocDef.ID];
export const CUSTOMER_NOT_OWNED_NOT_EDITABLE_OBJECT_IDS = [mInteractionMeetingDef.ID, ...ASSET_OBJECT_IDS];
export const CUSTOMER_OWNED_OBJECT_IDS = [
	...CUSTOMER_NOT_OWNED_EDITABLE_OBJECT_IDS,
	...CUSTOMER_NOT_OWNED_NOT_EDITABLE_OBJECT_IDS,
];
