// TODO: Rename to ViewName or similar
import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions";
import {
	mAccountDef,
	mAssetDef,
	mConferenceOrganiseDef,
	mContactDef,
	mCustomObjectDef,
	mDocDef,
	mGeneralAssetDef,
	mImageAssetDef,
	mInteractionDef,
	mInteractionMeetingDef,
	mInteractionShareDef,
	mLeadDef,
	mMediaAssetDef,
	mMediaShareDef,
	mObjectDefinitionDef,
	mOpportunityDef,
	mPdfAssetDef,
	mProductDef,
	mSalesDeskCustomerDef,
	mSalesDeskUserDef,
	mTaskDef,
	mTenantDef,
	mTenantSettingsDef,
	mTestDef,
	mUserDef,
	mWorkspaceDef,
	mIssueDef,
} from "@salesdesk/salesdesk-model";

export const objectClassNameSchema = asConst({
	title: "SDObject Classname",
	description: appendNotEditable("The view that is used to display the SDObject"),
	type: "string",
	enum: [
		// TODO:  These should probably go in an enum
		mAccountDef.CLASS_NAME,
		mContactDef.CLASS_NAME,
		mLeadDef.CLASS_NAME,
		mInteractionDef.CLASS_NAME,
		mInteractionMeetingDef.CLASS_NAME,
		mInteractionShareDef.CLASS_NAME,
		mAssetDef.CLASS_NAME,
		mGeneralAssetDef.CLASS_NAME,
		mMediaAssetDef.CLASS_NAME,
		mPdfAssetDef.CLASS_NAME,
		mImageAssetDef.CLASS_NAME,
		mOpportunityDef.CLASS_NAME,
		mTaskDef.CLASS_NAME,
		mIssueDef.CLASS_NAME,
		mTenantDef.CLASS_NAME,
		mTenantSettingsDef.CLASS_NAME,
		mUserDef.CLASS_NAME,
		mSalesDeskUserDef.CLASS_NAME,
		mSalesDeskCustomerDef.CLASS_NAME,
		mProductDef.CLASS_NAME,
		mCustomObjectDef.CLASS_NAME,
		mMediaShareDef.CLASS_NAME,
		mConferenceOrganiseDef.CLASS_NAME,
		mDocDef.CLASS_NAME,
		mWorkspaceDef.CLASS_NAME,
		mObjectDefinitionDef.CLASS_NAME,
		mTestDef.CLASS_NAME,
	],
});
