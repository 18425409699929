import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useBulkEditContext } from "../hooks/useBulkEditContext";
import { useBulkChangeOwnerContext, getCanChangeRecordOwnerParameters } from "../../../../records";
import { usePrincipalCanFilteredRecords } from "../hooks/usePrincipalCanFilteredRecords";

export function BulkChangeOwnerButton() {
	const { setBulkChangeOwnerRecords } = useBulkChangeOwnerContext();
	const { selectedRecords, setSelectedRecords } = useBulkEditContext();
	const [editableRecords] = usePrincipalCanFilteredRecords(selectedRecords, getCanChangeRecordOwnerParameters);

	const cannotEditAnyOfSelectedRecords = editableRecords.length === 0;

	return (
		<Tooltip
			text={
				selectedRecords.length && cannotEditAnyOfSelectedRecords
					? "You don't have permission to change the owner of selected records"
					: undefined
			}
			ignoreDisabled={true}
			placement="top"
		>
			<Button
				variant="text_inverted"
				startIcon={ICONS.user}
				onClick={() => {
					setBulkChangeOwnerRecords(selectedRecords, setSelectedRecords);
				}}
				size="sm"
				disabled={!selectedRecords.length || cannotEditAnyOfSelectedRecords}
			>
				Change owner
			</Button>
		</Tooltip>
	);
}
