import { ICONS } from "@salesdesk/salesdesk-ui";
import { TextInputPreview } from "./TextInputPreview";
import { Button } from "../../../Button/Button";

interface TextInputPreviewProps {
	value: string;
	onCopy?: () => void;
}

export function TextInputPreviewCopyable({ value, onCopy }: TextInputPreviewProps) {
	return (
		<TextInputPreview
			value={value}
			end={
				<Button
					size="sm"
					startIcon={ICONS.copy}
					variant="primary_text"
					onClick={() => {
						navigator.clipboard.writeText(value);
						onCopy?.();
					}}
				>
					Copy
				</Button>
			}
		/>
	);
}
