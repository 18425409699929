import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../../functions";
import { FIELD_TYPES } from "@salesdesk/salesdesk-model";

export const fieldTypeSchema = asConst({
	title: "SDObject Field Type",
	description: appendNotEditable("The type of data used for the field (e.g. int, float, date, selection etc)"),
	type: "string",
	enum: Object.entries(FIELD_TYPES)
		.filter(([, v]) => v !== FIELD_TYPES.DATA && v !== FIELD_TYPES.CONTAINER)
		.map(([, v]) => v.name),
});
