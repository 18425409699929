import { CSSProperties } from "react";
import clsx from "clsx";
import { Draggable, DraggingStyle, NotDraggingStyle, DraggableStateSnapshot } from "@hello-pangea/dnd";

import { FieldType } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

type FieldChipComponentProps = FieldType & {
	index: number;
};

const getStyle = (
	style: DraggingStyle | NotDraggingStyle | undefined,
	snapshot: DraggableStateSnapshot
): CSSProperties => {
	const dropping = snapshot.dropAnimation;
	if (!dropping) {
		return style || {};
	}
	return {
		...style,
		/*
			transitionDuration value is "0.0000001ms" to drop field immediately and
			without flickering on chrome/safari/firefox

			any other value still produce flickering

			more details here:
			https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/drop-animation.md#skipping-the-drop-animation
		*/
		transitionDuration: "0.0000001ms",
		opacity: "0",
	};
};

export function FieldChipComponent({ displayName, icon, index }: FieldChipComponentProps) {
	return (
		<div className="mb-4 flex w-full items-center gap-1.5">
			<Draggable draggableId={`${displayName}-${index}`} index={index}>
				{(provided, snapshot) => {
					const { isDragging } = snapshot;
					const draggingStyles = isDragging ? getStyle(provided.draggableProps.style, snapshot) : {};

					return (
						<>
							{isDragging ? (
								<div className="wide-dashed-border-primary text-label text-c_text_primary bg-c_bg_01 flex w-full items-center gap-3 rounded-sm px-4 py-2">
									<Icon icon={ICONS.grabbable} className="text-c_icon_regular flex items-center" />
									<Icon icon={icon} className="text-c_icon_emphasised flex" />
									{displayName}
								</div>
							) : null}
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={draggingStyles}
								className={clsx(
									isDragging ? "wide-dashed-border-active" : "wide-dashed-border-primary",
									"text-label text-c_text_primary bg-c_bg_03 flex w-full gap-3 px-4 py-2"
								)}
							>
								<Icon icon={ICONS.grabbable} className="text-c_icon_regular flex items-center" />
								<Icon icon={icon} className="text-c_icon_emphasised flex items-center" />
								{displayName}
							</div>
						</>
					);
				}}
			</Draggable>
		</div>
	);
}
