import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Tooltip } from "../Tooltip";
import { Icon, InputValidationState } from "../";
import { FieldSetWrapper } from "./FieldSetWrapper";

export interface FieldSetProps {
	label: string;
	labelIcon?: string;
	helperText?: ReactNode;
	validationState?: InputValidationState;
	required?: boolean;
	padlock?: boolean | string;
	isBoolean?: boolean;
	description?: string;
	disabled?: boolean;
	withLabel?: boolean;
	labelFor?: string;
	isOverflowHidden?: boolean;
}

export function FieldSet({
	label,
	labelIcon,
	helperText,
	validationState,
	children,
	required,
	padlock = false,
	isBoolean = false,
	description,
	disabled,
	withLabel = true,
	labelFor,
	isOverflowHidden = false,
}: PropsWithChildren<FieldSetProps>) {
	const helperTextContent = helperText ? (
		<div
			className={clsx(
				validationState === InputValidationState.error ? "text-c_danger_01" : "text-c_text_secondary",
				"text-body-sm"
			)}
		>
			{helperText}
		</div>
	) : null;

	if (isBoolean) {
		return (
			<FieldSetWrapper withLabel={withLabel} className="flex w-fit items-center justify-start gap-4">
				<div className={clsx(disabled && "h-6 cursor-not-allowed opacity-50", "flex")}>{children}</div>
				<div className="flex flex-col gap-1">
					<p className="text-label flex items-center gap-1.5">
						<Icon icon={labelIcon} />
						{label}
						{required ? "*" : null}
					</p>
					{helperTextContent}
				</div>
			</FieldSetWrapper>
		);
	}

	return (
		<FieldSetWrapper
			withLabel={withLabel}
			labelFor={labelFor}
			className={clsx(
				"flex w-full break-inside-avoid-column flex-col items-start gap-2",
				isOverflowHidden && "overflow-hidden"
			)}
		>
			<div className="text-label-sm text-c_text_secondary flex w-full items-center justify-between">
				<div className="flex items-center gap-1.5">
					<Icon icon={labelIcon} size="sm" />
					<p>
						{label}
						{required ? "*" : null}
					</p>
				</div>
				{padlock ? (
					typeof padlock === "string" ? (
						<Tooltip placement="top" text={padlock}>
							<div className="flex items-center">
								<Icon icon={ICONS.lock} size="sm" />
							</div>
						</Tooltip>
					) : (
						<Icon icon={ICONS.lock} size="sm" />
					)
				) : description ? (
					<Tooltip text={description}>
						<div className="flex items-center">
							<Icon icon={ICONS.info} size="sm" />
						</div>
					</Tooltip>
				) : null}
			</div>
			<div
				className={clsx(
					"flex w-full",
					isOverflowHidden && "overflow-hidden",
					disabled && "cursor-not-allowed opacity-50"
				)}
			>
				{children}
			</div>
			{helperTextContent}
		</FieldSetWrapper>
	);
}
