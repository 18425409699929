import { Skeleton } from "../../../../components/Skeleton/Skeleton";

export function FavoritePopoverLoading() {
	return (
		<div className="mx-6 flex flex-col">
			{[...Array(3)].map((_, index) => (
				<div key={index} className="flex flex-col gap-2 py-3">
					<Skeleton className="h-5 w-48" />
					<Skeleton className="h-4 w-28" />
				</div>
			))}
		</div>
	);
}
