import { SdEventType } from "@salesdesk/salesdesk-model";
import { UpdatedRecordPayload } from "./UpdatedRecordPayload";
import { NotePayload } from "./NotePayload";
import { RecordAssociationPayload } from "./RecordAssociationPayload";
import { FilePresentationPayload } from "./FilePresentationPayload";
import { ChatMessagePayload } from "./ChatMessagePayload";
import { useEventContext } from "../../../hooks/useEventContext";
import { videoEventsWithTimecode } from "../../../../files/components/VideoPlayer/types";
import { VideoEventPayload } from "./VideoEventPayload";
import { NoteReactionPayload } from "./NoteReactionPayload";
import { PageChangedPayload } from "./PageChangedPayload";
import { NoteMentionPayload } from "./NoteMentionPayload";
import { LazyChannelMessagePayload } from "./LazyChannelMessagePayload";

import {
	CommentPayload,
	CommentMentionPayload,
	CommentReactionPayload,
	CommentResolvedPayload,
	CommentRespondedPayload,
} from "./CommentEventPayload";
import { RecordOwnerUpdatedPayload } from "./RecordOwnerUpdatedPayload";

export function EventPayloadFactory() {
	const { event } = useEventContext();
	switch (event.event_type) {
		case SdEventType.RECORD_UPDATED:
			return <UpdatedRecordPayload />;
		case SdEventType.RECORD_NOTE_ADDED:
		case SdEventType.RECORD_NOTE_DELETED:
			return <NotePayload />;
		case SdEventType.RECORD_COMMENT_ADDED:
		case SdEventType.RECORD_COMMENT_DELETED:
			return <CommentPayload />;
		case SdEventType.RECORD_OWNER_UPDATED:
			return <RecordOwnerUpdatedPayload />;
		case SdEventType.CHAT_MESSAGE_SENT:
			return <ChatMessagePayload />;
		case SdEventType.RECORD_ASSOCIATION_CREATED:
		case SdEventType.RECORD_ASSOCIATION_DELETED:
			return <RecordAssociationPayload />;
		case SdEventType.FILE_PRESENTATION_STARTED:
		case SdEventType.FILE_PRESENTATION_STOPPED:
		case SdEventType.FILE_PRESENTATION_PAGE_CHANGED:
			return <FilePresentationPayload />;
		case SdEventType.NOTE_MENTION:
			return <NoteMentionPayload />;
		case SdEventType.COMMENT_MENTION:
			return <CommentMentionPayload />;
		case SdEventType.NOTE_REACTION_ADDED:
		case SdEventType.NOTE_REACTION_REMOVED:
			return <NoteReactionPayload />;
		case SdEventType.COMMENT_REACTION_ADDED:
		case SdEventType.COMMENT_REACTION_REMOVED:
			return <CommentReactionPayload />;
		case SdEventType.RECORD_COMMENT_RESOLVED:
			return <CommentResolvedPayload />;
		case SdEventType.RECORD_COMMENT_RESPONDED:
			return <CommentRespondedPayload />;
		case SdEventType.MESSAGE_SENT:
		case SdEventType.MESSAGE_UPDATED:
		case SdEventType.MESSAGE_MENTION:
			return <LazyChannelMessagePayload />;
	}
	if (videoEventsWithTimecode.some((eventType) => eventType === event.event_type)) {
		return <VideoEventPayload />;
	}
	if (event.event_type === SdEventType.PAGE_CHANGED) {
		return <PageChangedPayload />;
	}
	return null;
}
