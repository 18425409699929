import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions";
import { userIdSchema } from "../../components";

export const recordOwnerSchema = asConst({
	...userIdSchema,
	title: "SDRecord Owner",
	description: appendNotEditable("The owner of this SDRecord"),
	nullable: true,
});
