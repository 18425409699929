import { MouseEventHandler, useCallback, useState } from "react";

import { SDNotification } from "@salesdesk/salesdesk-schemas";
import { Button, AlertBubble, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { FabContainer } from "../../../components/FabContainer";
import { useEventRecordPathById } from "../../../routes";
import { SingleEvent } from "../../events";
import { useNotificationsContext } from "../hooks";

interface NotificationProps {
	notification: SDNotification;
}

export function Notification({ notification }: NotificationProps) {
	const { markRead, markUnread, onNotificationClicked } = useNotificationsContext();
	const [showMarkButton, setShowMarkButton] = useState(true);

	const eventRecordPath = useEventRecordPathById(notification.event?.workspace_id, notification.event?.record_id);

	const onMark = useCallback<MouseEventHandler<HTMLButtonElement>>(
		async (event) => {
			event.stopPropagation();
			setShowMarkButton(false);
			await (notification.read ? markUnread(notification) : markRead([notification]));
			setShowMarkButton(true);
		},
		[markRead, markUnread, notification]
	);

	return (
		<FabContainer
			buttons={
				showMarkButton ? (
					<Tooltip text={`Mark as ${notification.read ? "unread" : "read"}`}>
						<Button
							startIcon={ICONS.checks}
							size="xs"
							variant={notification.read ? "text" : "primary_text"}
							onClick={onMark}
						/>
					</Tooltip>
				) : null
			}
			onClickLink={eventRecordPath}
			onClick={() => onNotificationClicked(notification)}
		>
			<div className="flex max-w-full items-start justify-start gap-3 overflow-hidden">
				<div className="flex min-h-7 min-w-3 items-center justify-center pb-[5px]">
					{!notification.read ? <AlertBubble size="sm" alertCount={0} showZero={true} variant="secondary" /> : null}
				</div>
				{notification.event ? <SingleEvent target="NOTIFICATION" event={notification.event} /> : null}
			</div>
		</FabContainer>
	);
}
