import { asConst } from "json-schema-to-ts";
import { idSchema } from "../components";
import { eventTypeSchema } from "./eventTypeSchema";

export const eventCreateRequestSchema = asConst({
	title: "Event Create Request",
	type: "object",
	additionalProperties: false,
	required: ["id", "event_type", "tstamp"],
	properties: {
		id: {
			title: "Client Event ID",
			description: "Client-generated ID for event",
			type: "number",
		},
		event_type: eventTypeSchema,
		tstamp: {
			title: "Event timestamp",
			description: "Unix timestamp (in milliseconds) of the event",
			type: "number",
		},
		record_id: {
			...idSchema,
			title: "Record Id",
			description: "Id of the record that's the subject of the event",
		},
		object_def_id: {
			...idSchema,
			title: "Object Type",
			description:
				"Id of the object that's the subject of the event.  Note. When a record_id is set this field is not required because it will be inferred from the record_id",
		},
		params: {
			// TODO: set up params schema here for each possible event with params
			title: "Event Parameters",
			description: "Special parameters for the event",
			type: "object",
		},
	},
	dependencies: {
		record_id: {
			allOf: [
				{
					not: { required: ["object_def_id"] },
				},
			],
		},
		object_def_id: {
			allOf: [
				{
					not: { required: ["record_id"] },
				},
			],
		},
	},
});
