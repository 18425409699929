import { DisplayFieldFactory } from "../../../../../../../../fields";
import { MeetingInfoSection } from "./MeetingInfoSection";
import { FieldInfo } from "@salesdesk/salesdesk-schemas";

interface FieldInfoSectionProps {
	fieldInfo: FieldInfo;
}

export function FieldInfoSection({ fieldInfo }: FieldInfoSectionProps) {
	const { field, value } = fieldInfo;

	const { _displayName: displayName, _icon: icon, _required: required } = field;
	const title = `${displayName}${required ? "*" : ""}`;

	return (
		<MeetingInfoSection title={title} icon={icon}>
			<DisplayFieldFactory field={field} value={value} />
		</MeetingInfoSection>
	);
}
