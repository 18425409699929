import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { isAssetObject, RecordQueryClauses, SDRecord } from "@salesdesk/salesdesk-schemas";
import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";

import { useGetObjectById } from "../../../../../../../../hooks/useGetObjectById";
import { Spinner } from "@salesdesk/daisy-ui";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { useResourcesPanelSearch } from "../hooks/useResourcesPanelSearch";
import { NoResourcesView } from "./NoResourcesView";
import { SortingDetails } from "../../../../../../../records";

import {
	AssociationActionsProvider,
	DirectedSDObjectAssociation,
	RecordAssociationCard,
	RecordAssociationsControls,
	RecordAssociationListLoading,
	useOpenLinkingModal,
	useGetAssociationFieldIds,
} from "../../../../../../../recordAssociations";

interface ResourcesListProps {
	objectAssociation: DirectedSDObjectAssociation;
	onUpdateCount: (associationId: number, count: number) => void;
	selectedRecords: SDRecord[];
	toggleRecordSelection: (record: SDRecord) => void;
	onResourceRecordsLoad?: (objectDefId: number, records: SDRecord[]) => void;
}

export function ResourcesList({
	objectAssociation,
	onUpdateCount,
	selectedRecords,
	toggleRecordSelection,
	onResourceRecordsLoad,
}: ResourcesListProps) {
	const { workspaceRecord } = useWorkspaceContext();

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const [sorting, setSorting] = useState<SortingDetails[]>([]);
	const [filters, setFilters] = useState<RecordQueryClauses[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>();

	const {
		totalResourcesRecords,
		resourcesRecords,
		isLoadingNewSearchParams,
		isLoadingNextPage,
		containerBottomRef,
		isFiltered,
	} = useResourcesPanelSearch({ objectAssociation, sorting, filters, searchQuery, scrollContainerRef });

	const { sdObject: associationObject } = useGetObjectById(objectAssociation.connectedObject.id);
	const multiplictyOne = objectAssociation.connectedObject.multiplicity === AssociationMultiplicity.ONE;
	const openLinkingModal = useOpenLinkingModal(workspaceRecord ?? undefined, associationObject, objectAssociation);

	const showFilterAndSortingControls = Boolean(
		sorting.length || searchQuery?.length || (totalResourcesRecords || 0) >= 2
	);
	const showLinkingControls = (totalResourcesRecords || 0) > 0 && !multiplictyOne && Boolean(openLinkingModal);
	const initialLoading = totalResourcesRecords === undefined;

	useEffect(() => {
		if (totalResourcesRecords !== undefined) {
			onUpdateCount(objectAssociation.id, totalResourcesRecords);
		}
	}, [objectAssociation.id, onUpdateCount, totalResourcesRecords]);

	useEffect(() => {
		if (onResourceRecordsLoad && associationObject) {
			onResourceRecordsLoad(associationObject._id, resourcesRecords);
		}
	}, [onResourceRecordsLoad, resourcesRecords, associationObject]);

	const displayFieldIds = useGetAssociationFieldIds(associationObject);

	if (!associationObject) {
		return null;
	}

	const isAssetAssociation = isAssetObject(associationObject);

	return (
		<AssociationActionsProvider sourceRecord={workspaceRecord} objectAssociation={objectAssociation}>
			<div className="flex max-h-full flex-col gap-4 overflow-hidden">
				<div className="px-6">
					<RecordAssociationsControls
						associationObject={associationObject}
						onFiltersChange={setFilters}
						onSortingChange={setSorting}
						onQueryChange={setSearchQuery}
						openLinkingModal={openLinkingModal}
						showFilterAndSortingControls={showFilterAndSortingControls}
						showLinkingControls={showLinkingControls}
						initialLoading={initialLoading}
					/>
				</div>

				<div className={clsx("flex h-full flex-col px-6 pb-4", !isLoadingNewSearchParams && "overflow-auto")}>
					<div className={clsx("grid-cols-cards grid items-center", isAssetAssociation ? "gap-2" : "gap-4")}>
						{isLoadingNewSearchParams ? (
							<RecordAssociationListLoading numberOfSkeletonCards={4} isAssetAssociation={isAssetAssociation} />
						) : resourcesRecords.length || isLoadingNextPage ? (
							resourcesRecords?.map((resourceRecord) => (
								<div
									key={resourceRecord._id}
									className={clsx("w-full", isAssetAssociation && "border-c_border_regular border-b pb-2")}
								>
									<RecordAssociationCard
										sdObject={associationObject}
										sdRecord={resourceRecord}
										displayFieldIds={displayFieldIds}
										selected={selectedRecords.some((record) => record._id === resourceRecord._id) || undefined}
										onToggleSelect={() => toggleRecordSelection(resourceRecord)}
									/>
								</div>
							))
						) : (
							<NoResourcesView
								associationObject={associationObject}
								isFiltered={isFiltered}
								onLinkAssociationClick={openLinkingModal}
							/>
						)}
					</div>
					{isLoadingNewSearchParams ? null : isLoadingNextPage ? (
						<div className="my-6 flex items-center justify-center">
							<Spinner size="md" />
						</div>
					) : (
						<div key={resourcesRecords?.length ?? 0} ref={containerBottomRef} />
					)}
				</div>
			</div>
		</AssociationActionsProvider>
	);
}
