import { SDRecord } from "../records";

export const ChannelType = {
	Workspace: "Workspace",
	Internal: "Internal",
	Custom: "Custom",
	DirectMessage: "DirectMessage",
} as const;

export type ChannelType = (typeof ChannelType)[keyof typeof ChannelType];

interface ChannelMetadataBase {
	channelType: ChannelType;
	tenantId: string;
	createdTimestamp?: number;
}

export interface WorkspaceMetadata extends ChannelMetadataBase {
	channelType: "Workspace";
	workspaceId: SDRecord["_id"];
}

export interface InternalMetadata extends ChannelMetadataBase {
	channelType: "Internal";
}

export interface CustomMetadata extends ChannelMetadataBase {
	channelType: "Custom";
}

export interface DirectMessageMetadata extends ChannelMetadataBase {
	channelType: "DirectMessage";
}

export type ChannelMetadata = WorkspaceMetadata | InternalMetadata | CustomMetadata | DirectMessageMetadata;
