import { SYSTEM_USER_NAME } from "@salesdesk/salesdesk-model";
import { useGetRecordQuery } from "../../records";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useConvertUserSDRecordsToAvatarUsers } from "./useConvertUserSDRecordsToAvatarUsers";
import { SYSTEM_USER_ID } from "@salesdesk/salesdesk-schemas";

export function useGetUserByUserId(userId: number) {
	const isSystemUser = userId === SYSTEM_USER_ID;
	const { data: sdRecord } = useGetRecordQuery(isSystemUser ? skipToken : userId);

	const user = useConvertUserSDRecordsToAvatarUsers(sdRecord);

	return {
		user: isSystemUser ? { id: userId, fullName: SYSTEM_USER_NAME } : user,
		isSystemUser,
	};
}
