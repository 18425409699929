import { Link } from "react-router-dom";
import clsx from "clsx";

import { Bookmark } from "@salesdesk/salesdesk-schemas";
import { Icon } from "@salesdesk/daisy-ui";

import { BookmarkPopoverItemMenu } from "./BookmarkPopoverItemMenu";
import { useBookmarkSdObject } from "../hooks/useBookmarkSdObject";
import { getBookmarkRedirectUrl } from "../../utils";
interface BookmarkNavPopoverItemProps {
	bookmark: Bookmark;
	onBookmarkClick: (bookmark: Bookmark) => void;
	onEditClick: (bookmark: Bookmark) => void;
	onDeleteClick: (bookmark: Bookmark) => void;
	showObjectName?: boolean;
	active?: boolean;
	selected?: boolean;
}

export function BookmarkPopoverItem({
	bookmark,
	onBookmarkClick,
	onEditClick,
	onDeleteClick,
	showObjectName,
	active,
	selected,
}: BookmarkNavPopoverItemProps) {
	const { sdObjects, sdObject } = useBookmarkSdObject(bookmark);

	return (
		<Link
			className={clsx(
				"focus:bg-c_bg_03 focus-visible-within:bg-c_bg_03 hover:bg-c_bg_03 group flex w-full items-center justify-stretch gap-1 px-6 py-3",
				active ? "bg-c_bg_03" : selected ? "bg-c_bg_05" : 'has-[[data-state="open"]]:bg-c_bg_03'
			)}
			to={getBookmarkRedirectUrl(sdObjects, bookmark)}
			onClick={(e) => {
				e.stopPropagation();
				onBookmarkClick(bookmark);
			}}
		>
			<div className="flex grow flex-col gap-2 overflow-hidden">
				<div className="text-label-nav truncate">{bookmark.name}</div>
				{sdObject && showObjectName ? (
					<div className="text-label-xs text-c_text_disabled flex items-center gap-2">
						<Icon icon={sdObject._icon} size="sm" />
						<div>{sdObject._pluralName}</div>
					</div>
				) : null}
			</div>
			<div
				className={clsx(
					!active && 'invisible has-[[data-state="open"]]:visible',
					"flex h-full items-center group-focus-within:visible group-hover:visible"
				)}
			>
				<BookmarkPopoverItemMenu
					onEditClick={() => onEditClick(bookmark)}
					onDeleteClick={() => onDeleteClick(bookmark)}
				/>
			</div>
		</Link>
	);
}
