import { Fragment, ReactNode } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { Breadcrumb } from "./Breadcrumb";
export interface BreadcrumbItem {
	icon?: string;
	node: ReactNode;
	link?: string;
}

interface BreadcrumbsProps {
	crumbs: BreadcrumbItem[];
	canCopyLink?: boolean;
}

export function Breadcrumbs({ crumbs, canCopyLink }: BreadcrumbsProps) {
	const copyToClipboard = useCopyToClipboard();
	return (
		<div className="flex items-center gap-3">
			<nav aria-label="Breadcrumb">
				<ol className="text-link-sm flex items-center gap-2">
					{crumbs.map((crumb, index) => {
						const isLast = index === crumbs.length - 1;

						return (
							<Fragment key={`crumb-${typeof crumb.node === "string" ? crumb.node : index}`}>
								<Breadcrumb crumb={crumb} isCurrent={isLast} />
								{isLast ? null : <li className="text-c_text_disabled select-none">/</li>}
							</Fragment>
						);
					})}
				</ol>
			</nav>
			{canCopyLink ? (
				<Tooltip text="Copy current link">
					<Button
						onClick={() => {
							copyToClipboard(window.location.href);
						}}
						startIcon={ICONS.link}
						variant="text"
						size="sm"
					/>
				</Tooltip>
			) : null}
		</div>
	);
}
