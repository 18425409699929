import {
	SDObject,
	SDRecord,
	AbilityAction,
	AbilitySubject,
	sdSubject,
	sdSubjectField,
} from "@salesdesk/salesdesk-schemas";
import { mUserDef } from "@salesdesk/salesdesk-model";

import { useGetLoginAuthorizationDetails } from "../../records";
import { useWebPrincipal } from "../../../auth";
import { useToggleUserLoginContext } from "./useToggleUserLoginContext";

export function useInviteUser(userSDObject?: SDObject, userSDRecord?: SDRecord) {
	const principal = useWebPrincipal();

	const {
		isAuthorizedToLogIn: isRecordAuthorizedToLogin,
		isSalesDeskUser: isRecordSalesDeskUser,
		isUserRecord,
	} = useGetLoginAuthorizationDetails(userSDObject, userSDRecord);
	const { setUserRecordToInvite } = useToggleUserLoginContext();

	const canGrantLogin =
		userSDRecord &&
		isUserRecord &&
		!isRecordAuthorizedToLogin &&
		!isRecordSalesDeskUser &&
		principal.can(
			AbilityAction.Edit,
			sdSubject(AbilitySubject.Record, userSDRecord),
			sdSubjectField(mUserDef.LOGIN_AUTHORIZED_FIELD_NAME)
		);

	return Boolean(setUserRecordToInvite) && canGrantLogin ? setUserRecordToInvite : undefined;
}
