import { Link } from "react-router-dom";
import { PATHS } from "../../../../../../../routes";

export function HoldingPageLogo() {
	return (
		<Link to={PATHS.DASHBOARD()}>
			<img src={"/static/images/sd_logo_holdingpage.svg"} alt="SalesDesk logo" />
		</Link>
	);
}
