import { useMemo } from "react";

import { RecordsSearchRequest, rsr } from "@salesdesk/salesdesk-schemas";
import { mLeadDef } from "@salesdesk/salesdesk-model";

import { useSearchRecordsQuery } from "../../../../../records";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export function useWorkspaceHasSharedLead(workspaceId?: number): boolean {
	const searchRequest = useMemo(() => {
		if (workspaceId == null) {
			return null;
		}

		const query = rsr.query().and(rsr.isSharedWithWorkspace(workspaceId)).and(rsr.baseObjectId(mLeadDef.ID));

		return {
			query: query.buildQuery(),
			size: 0,
		} satisfies RecordsSearchRequest;
	}, [workspaceId]);

	return (useSearchRecordsQuery(searchRequest ?? skipToken)?.currentData?.hitCount ?? 0) > 0;
}
