import { Tooltip, Button, tw } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { FabContainer } from "../../../components/FabContainer";
import { useDownloadFile } from "../../files/hooks/useDownloadFile";
import { useMediaAnalysisContext } from "../hooks/useMediaAnalisysContext";
import { PropsWithChildren } from "react";
import { useRecordViewTopLevelTabsContext } from "../../records/components/RecordDetails/components/RecordDetailView/hooks";
import { RecordViewTopLevelTab } from "../../records/components/RecordDetails/components/RecordDetailView/types";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { useWebPrincipal } from "../../../auth";

interface VideoAnalyticsFabProps extends PropsWithChildren {
	className?: string;
}

export function VideoAnalyticsFab({ className, children }: VideoAnalyticsFabProps) {
	const principal = useWebPrincipal();
	const { file } = useMediaAnalysisContext();

	const downloadFile = useDownloadFile(file);

	const { hasTab } = useRecordViewTopLevelTabsContext();

	if (!hasTab(RecordViewTopLevelTab.analytics)) {
		return <div className={className}>{children}</div>;
	}

	return (
		<FabContainer
			positionClasses={tw`top-2 right-2`}
			className={className}
			buttons={
				principal.can(AbilityAction.DownloadInUI, AbilitySubject.File) ? (
					<div className="flex gap-1">
						<Tooltip text="Download">
							<Button
								size="xs"
								variant="text"
								startIcon={ICONS.download}
								onClick={(e) => {
									e.preventDefault();
									downloadFile();
								}}
							/>
						</Tooltip>
					</div>
				) : null
			}
		>
			{children}
		</FabContainer>
	);
}
