import { Editor, JSONContent } from "@tiptap/react";
import { useId, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useAccessibleHTMLElementLink } from "../../../../hooks/ui";
import { DocumentEditor } from "../DocumentEditor/components/DocumentEditor";

interface DocumentPreviewProps {
	documentContents?: JSONContent;
	documentLink?: string;
	variant?: "base" | "sm";
}

const MAX_PREVIEW_LINES = 30;

export function DocumentPreview({ documentContents, documentLink, variant }: DocumentPreviewProps) {
	const trimmedContents = useMemo(() => {
		if (!documentContents || !documentContents.content) {
			return documentContents ?? undefined;
		}

		let estimatedLines = 0;
		const trimmedContents = [];

		for (const content of documentContents.content) {
			if (estimatedLines >= MAX_PREVIEW_LINES) {
				break;
			}

			estimatedLines += content.content?.length || 1;
			trimmedContents.push(content);
		}

		return { ...documentContents, content: trimmedContents };
	}, [documentContents]);

	const linkElementId = useId();
	const handleCardClickEvent = useAccessibleHTMLElementLink({ linkElementId });

	const editorRef = useRef<Editor | null>(null);

	return (
		<div
			className={clsx(
				"rounded-card bg-c_bg_01 border-c_border_regular group relative mb-1 w-full shrink-0 cursor-pointer overflow-hidden border",
				variant === "sm" ? "aspect-video p-4 pb-1" : "h-[500px] p-6"
			)}
			onClick={handleCardClickEvent}
			onAuxClick={handleCardClickEvent}
		>
			{variant === "sm" ? null : (
				<div className="bg-c_bg_01 border-c_border_btn text-c_text_primary text-label-sm ring-c_action_focus group-focus-within:border-c_action_01 absolute right-2 top-2 z-10 flex h-9 items-center gap-2 rounded-full border px-3 leading-4 opacity-0 transition-opacity group-focus-within:opacity-100 group-focus-within:ring group-hover:opacity-100">
					<Icon icon={ICONS.simplePencil} className="text-c_icon_regular flex" />
					Edit Doc
				</div>
			)}
			<div className="pointer-events-none max-h-full overflow-hidden">
				<DocumentEditor value={trimmedContents} isSmallPreview={variant === "sm"} editorRef={editorRef} />
			</div>
			{documentLink ? (
				<Link id={linkElementId} to={documentLink} className="sr-only">
					Go to Doc
				</Link>
			) : null}
		</div>
	);
}
