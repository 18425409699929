import { useMemo } from "react";

import { useUnreadMessageHandler } from "../../hooks/useUnreadMessageHandler";
import { ChannelDetails } from "../../types";
import { ChatChannelViewContainer } from "../ChatChannelView";
import { MessagingPanelContainer } from "../MessagingPanelContainer";

import { HuddleButton } from "../HuddleButton";
import { useCreateHuddle, useGetActiveHuddle } from "../../hooks";

interface DefaultSingleChannelMessagingPanelProps {
	open: boolean;
	onToggleOpen: () => void;
	currentChannel?: ChannelDetails;
}

export function DefaultSingleChannelMessagingPanel({
	open,
	onToggleOpen,
	currentChannel,
}: DefaultSingleChannelMessagingPanelProps) {
	const currentChannelArray = useMemo(() => {
		return currentChannel ? [currentChannel] : undefined;
	}, [currentChannel]);

	const currentChannelArn = currentChannel?.arn;

	const { hasUnreadMessages, newMessageKey } = useUnreadMessageHandler({
		channels: currentChannelArray,
		currentOpenChannelArn: open && currentChannel ? currentChannelArn : undefined,
	});

	const { createHuddle, isCreatingHuddle } = useCreateHuddle(currentChannelArn);
	const { activeHuddle, initialLoad } = useGetActiveHuddle(currentChannelArn);

	return (
		<MessagingPanelContainer
			open={open}
			onToggleOpen={onToggleOpen}
			showUnreadDot={hasUnreadMessages}
			newMessageKey={newMessageKey}
			headerElements={
				<HuddleButton
					activeHuddle={activeHuddle}
					createHuddle={createHuddle}
					isLoadingHuddle={isCreatingHuddle || initialLoad}
				/>
			}
		>
			<ChatChannelViewContainer channelDetails={currentChannel} />
		</MessagingPanelContainer>
	);
}
