import {
	AriaAttributes,
	DetailedHTMLProps,
	InputHTMLAttributes,
	KeyboardEventHandler,
	ReactNode,
	Ref,
	KeyboardEvent,
} from "react";
import { Editor, JSONContent } from "@tiptap/core";

import { FIELD_CREATION_TYPE } from "@salesdesk/salesdesk-model";
import { InputComponentProps, ErrorInputComponentProps } from "@salesdesk/daisy-ui";
import { SDFile, SDRecord, TimeRange } from "@salesdesk/salesdesk-schemas";

import { DateTimeFieldVariant } from "../../fields";
import { BottomPanelOptions } from "../components/RichText/types";
import { MentionOptions } from "../../mentions/types";

export interface DateTimePickerProps<TValue> {
	variant?: DateTimeFieldVariant;
	onToday?: () => void;
	value?: TValue | null;
	onChange?: (value: TValue) => void;
	isTimeRange?: boolean;
	markedDates?: Date[];
	offsetDate?: Date;
	setOffsetDate?: (offset: Date) => void;
}

export interface DateTimePickerControllerProps<TValue> extends Omit<DateTimePickerProps<TValue>, "onToday"> {
	value?: TValue | null;
	onChange?: (value: TValue | null) => void;
	min?: number;
	max?: number;
	onComplete?: () => void;
}

export interface PickerTextInputProps<TValue> extends InputComponentProps, ErrorInputComponentProps {
	variant?: DateTimeFieldVariant;
	value?: TValue | null;
	onChange: (value: TValue | null) => void;
	min?: number;
	max?: number;
	onFocus?: () => void;
	calenderIcon?: ReactNode;
}

export interface PickerInputProps<TValue> extends Omit<PickerTextInputProps<TValue>, "calenderIcon"> {
	mRef?: Ref<HTMLInputElement> | null;
	textInput: (props: PickerTextInputProps<TValue> & { ref: Ref<HTMLInputElement> | undefined }) => ReactNode;
	picker: (props: DateTimePickerControllerProps<TValue>) => ReactNode;
	isPickerOpen: boolean;
	setIsPickerOpen: (isOpen: boolean) => void;
}

export type DateTimePickerInputProps = Omit<
	PickerInputProps<number>,
	"mRef" | "button" | "closePickerWhenValueSelected" | "textInput" | "picker" | "isPickerOpen" | "setIsPickerOpen"
>;

export type TimeRangePickerInputProps = Omit<
	PickerInputProps<TimeRange>,
	"mRef" | "button" | "closePickerWhenValueSelected" | "textInput" | "picker" | "isPickerOpen" | "setIsPickerOpen"
>;

export interface DurationInputProps extends InputComponentProps, ErrorInputComponentProps {
	onChange: (milliseconds: number) => void;
	value?: number;
}

export type FileInputVariant = "base" | "fullPage";

export enum FileInputErrorCode {
	UNSUPPORTED_FILE_TYPE = "UNSUPPORTED_FILE_TYPE",
	FILE_TOO_LARGE = "FILE_TOO_LARGE",
}

export interface FileInputProps extends InputComponentProps, ErrorInputComponentProps {
	onChange: (newValue: File | null, errorCode?: FileInputErrorCode, errorParams?: (string | number)[]) => void;
	value?: File | number | null;
	supportedMimeTypes?: string;
	maxFileSizeInBytes?: number;
	uploadProgress?: number;
	showFileBorder?: boolean;
	variant?: FileInputVariant;
	filePreviewOverride?: ReactNode;
}

export interface LogoInputProps extends Omit<FileInputProps, "supportedMimeTypes" | "showFileBorder"> {
	companyName?: string;
}

export interface ProfilePhotoInputProps extends Omit<FileInputProps, "supportedMimeTypes"> {
	fullName?: string;
}

export interface BooleanInputProps extends InputComponentProps {
	onChange: (newValue: boolean) => void;
	value?: boolean;
}

export interface RatingInputProps extends InputComponentProps, ErrorInputComponentProps {
	onChange: (rating: number) => void;
	value?: number;
	maxRating?: number;
	readonly?: boolean;
}

export interface RichTextInputProps extends InputComponentProps, ErrorInputComponentProps {
	onChange: (jsonValue: JSONContent, textValue?: string, editor?: Editor) => void;
	onFocus?: (event: React.FocusEvent) => void;
	onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
	value?: JSONContent;
	placeholder?: string;
	hideToolbar?: boolean;
	heightClassName?: string;
	disableEnter?: boolean;
	disableModEnter?: boolean;
	enableShiftEnterAsEnter?: boolean;
	enableMentions?: boolean | MentionOptions;
	bottomPanelOptions?: BottomPanelOptions;
	isCollapsible?: boolean;
}

export interface ColorPickerInputProps extends InputComponentProps, ErrorInputComponentProps {
	onChange: (colourValue: string | null) => void;
	value: string | null;
	placeholder?: string;
}
export interface IconPickerInputProps extends InputComponentProps, ErrorInputComponentProps {
	onChange: (iconValue: string | null) => void;
	value: string | null;
	placeholder?: string;
}

export type SelectOptionId = string | number;

export interface SelectOption {
	id: SelectOptionId;
	icon?: string;
	imageFileId?: number;
	name: string;
	description?: string;
	descriptionVariant?: "default" | "inline_button";
	disabled?: boolean;
	color?: string;
	textColor?: string;
	sdRecord?: SDRecord;
	label?: { text: string; icon?: string } | string;

	creationType?: (typeof FIELD_CREATION_TYPE)[keyof typeof FIELD_CREATION_TYPE];
}

export type SelectOptionTitledSection = { sectionTitle: string; options: SelectOption[] };
export type SelectOptionSection = SelectOption[] | SelectOptionTitledSection;

export type SelectOptions = SelectOption[] | SelectOptionSection[];

export interface OptionInputProps extends InputComponentProps {
	options: SelectOptions;
}

export interface SingleOptionInputProps extends OptionInputProps {
	onChange: (newValue?: SelectOptionId) => void;
	value?: SelectOptionId;
}

export interface MultiOptionInputProps extends OptionInputProps {
	onChange: (newValue: SelectOptionId[]) => void;
	value?: SelectOptionId[];
}
