import { Control } from "react-hook-form";

export const RECORD_FIELDS_KEY = "recordFields";

export interface BasicFormState {
	isValid?: boolean;
	isDirty?: boolean;
}

export type RecordFormControl = Control<Record<string, any>, any>;
