import { RefObject, SetStateAction, useEffect, useMemo, useRef } from "react";
import { Droppable } from "@hello-pangea/dnd";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { roundToTwoDecimalPlaces } from "@salesdesk/salesdesk-utils";
import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { APP_CONFIG } from "../../../../../../../../app/app_config";
import { CollapsibleList } from "../../../../../../../../components/CollapsibleList";

import { useInfiniteRecordSearch } from "../../../../../../../records";
import { RowSelectionDetails, Table } from "../../../../../../../Table";
import { DisplayFieldFactory } from "../../../../../../../fields";
import { useLinkedTablesState } from "../../../../../../../Table/hooks/useLinkedTables";
import { useCreateRecordDialogContext } from "../../../../../../../records/components/RecordCreate/hooks/useCreateRecordDialogContext";

import { useObjectBoardSearchParams } from "../../../../../../hooks/useObjectBoardSearchParams";
import { MAIN_BOARD_AGGREGATION, RecordGroupDetails } from "../../../../../../types";
import { useDataboardDetailsContext } from "../../../../../../hooks/useDataboardDetailsContext";
import { useBoardFieldsToDisplaySelector, useBoardGroupBySelector } from "../../../../../../store";

import { RecordTableColumn, RecordTableRow } from "../../../../common/types";
import { useControlColumn } from "../../../../common/hooks/useControlColumn";
import { useSkeletonPlaceholderRows } from "../../../../common/hooks/useSkeletonPlaceholderRows";

import { EMPTY_ARRAY } from "../../../../../ObjectBoardControls/components/BoardSortingPopover";

import { getBodyDroppableId, getHeaderDroppableId } from "../utils";
import { useMemoizedBoardRecords } from "../../../../../../hooks/useMemoizedBoardRecords";

interface ListGroupProps {
	columns: RecordTableColumn[];
	recordsPerGroupId: Record<number, SDRecord[]>;
	recordGroup: RecordGroupDetails;
	defaultColumnWidthByColumnId?: Record<string, number>;
	scrollableContainerRef?: RefObject<HTMLDivElement>;
	updateRowsByGroupId: (groupId: number, rows: RecordTableRow[]) => void;
	onTableDataChange: (groupId: number, updatedData: SetStateAction<SDRecord[]>) => void;
	rowSelectionDetails: RowSelectionDetails;
	getRowId: (row: RecordTableRow, index: number) => string;
	dropIndex?: number;
	isDroppable?: boolean;
}

export function ListGroup({
	columns,
	recordGroup,
	recordsPerGroupId,
	onTableDataChange,
	defaultColumnWidthByColumnId,
	scrollableContainerRef,
	updateRowsByGroupId,
	rowSelectionDetails,
	getRowId,
	dropIndex,
	isDroppable,
}: ListGroupProps) {
	const { openModal: openCreateRecordModal } = useCreateRecordDialogContext();

	const { sdObject } = useDataboardDetailsContext();
	const fieldId = useBoardGroupBySelector();
	const fieldsToDisplay = useBoardFieldsToDisplaySelector();

	const sdRecords = recordsPerGroupId[recordGroup.id] || EMPTY_ARRAY;

	const {
		updateSearchParams,
		isLoadingNewSearchParams: isLoading,
		resultHits,
		isLastPage,
		loadNextPage,
		isLoadingNextPage,
	} = useInfiniteRecordSearch({
		limit: APP_CONFIG.maxLocalSearchResults,
		sdRecords,
		onSDRecordsChange: (updatedData) => onTableDataChange(recordGroup.id, updatedData),
		sdObjectFilter: sdObject?._id,
	});

	const hitCount = resultHits?.hitCount || 0;
	const aggregations = resultHits?.aggregations;

	const aggregationValue = useMemo(() => {
		if (!recordGroup.aggregationResult) return;
		const objectBoardAggregation = aggregations?.find((aggregation) => aggregation.name === MAIN_BOARD_AGGREGATION);
		if (!objectBoardAggregation) return;
		return (
			<DisplayFieldFactory
				field={recordGroup.aggregationResult.field}
				value={roundToTwoDecimalPlaces(objectBoardAggregation?.value || 0)}
			/>
		);
	}, [aggregations, recordGroup.aggregationResult]);

	const searchParams = useObjectBoardSearchParams(recordGroup.filterParams);
	useEffect(() => {
		if (searchParams) {
			updateSearchParams(searchParams);
		}
	}, [searchParams, updateSearchParams]);

	const tableRef = useRef<HTMLDivElement | null>(null);
	const controlColumn = useControlColumn(tableRef, scrollableContainerRef);
	const skeletonRows = useSkeletonPlaceholderRows(1);
	const rows = useMemoizedBoardRecords(sdRecords, sdObject);

	useEffect(() => {
		updateRowsByGroupId(recordGroup.id, rows);
	}, [rows, updateRowsByGroupId, recordGroup.id]);

	const { hasHorizontalOverflow } = useLinkedTablesState() || {};

	const tableFooter = useMemo(() => {
		return (
			<>
				{!isLastPage || isLoadingNextPage ? (
					<div className="border-b-c_border_regular flex h-16 items-center border-b px-8">
						<Button
							variant="primary_text"
							startIcon={ICONS.customArrowDown}
							size="lg"
							onClick={loadNextPage}
							isLoading={isLoadingNextPage}
						>
							Load more
						</Button>
					</div>
				) : undefined}
				<div className="flex h-16 items-center px-8">
					<Button
						variant="primary_text"
						startIcon={ICONS.plus}
						size="sm"
						onClick={() => {
							if (!sdObject || fieldId == null) return;

							openCreateRecordModal({
								sdObject,
								initialValueMap: {
									[fieldId]: recordGroup.id,
								},
							});
						}}
					>
						Add a new {sdObject?._displayName || "record"}
					</Button>
				</div>
			</>
		);
	}, [fieldId, isLastPage, isLoadingNextPage, loadNextPage, openCreateRecordModal, recordGroup.id, sdObject]);

	if (!sdObject || !fieldId) {
		return;
	}

	return (
		<>
			<div className="h-3" />
			<CollapsibleList
				title={recordGroup.name}
				stickyHeader
				stickyHeaderClassName={`transition-all ${hasHorizontalOverflow ? "w-full" : "w-[calc(100%-24px)]"}`}
				count={hitCount}
				sumLabel={!isLoading ? recordGroup.aggregationResult?.aggregationName : undefined}
				sum={!isLoading ? aggregationValue : undefined}
				unmount={false}
				droppableId={getHeaderDroppableId(recordGroup.id)}
			>
				<Droppable key={recordGroup.id} droppableId={getBodyDroppableId(recordGroup.id)}>
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{columns.length ? (
								<Table
									id={String(recordGroup.id)}
									outsideRef={tableRef}
									outsideScrollContainerRef={scrollableContainerRef}
									columns={columns}
									visibleColumns={fieldsToDisplay}
									controlColumn={controlColumn}
									rows={isLoading ? skeletonRows : rows}
									rowSelection={rowSelectionDetails}
									getRowId={getRowId}
									hasDragAndDrop
									dropIndex={dropIndex}
									isDroppable={isDroppable}
									dropPlaceholder={provided.placeholder}
									defaultColumnWidthByColumnId={defaultColumnWidthByColumnId}
									hideHeader
									stickyHeader={false}
									tableFooter={tableFooter}
								/>
							) : null}
						</div>
					)}
				</Droppable>
			</CollapsibleList>
		</>
	);
}
