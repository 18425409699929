import { useCallback } from "react";
import { useAssociationActionsContext } from "./useAssociationActionsContext";

import { AbilityAction, AbilitySubject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../../auth";
import { MenuItem } from "../../../../menu";

export function useGetAssociationContextMenuItems() {
	const principal = useWebPrincipal();

	const { sourceRecord, setAssociationRecordToPreview, setAssociationRecordToRemove, objectAssociation } =
		useAssociationActionsContext();

	const getAssociationContextMenuItems = useCallback(
		(record: SDRecord) => {
			const isShared =
				sourceRecord &&
				(record.sharedWorkspaceIds.includes(sourceRecord._id) || sourceRecord.sharedWorkspaceIds.includes(record._id));

			return {
				primaryItems: [
					{
						icon: ICONS.eye,
						text: "Preview",
						onClick: () => {
							setAssociationRecordToPreview?.(record);
						},
						type: "button",
					},
				] satisfies MenuItem[],
				appendedItems:
					sourceRecord && principal.can(AbilityAction.Delete, AbilitySubject.RecordAssociation)
						? ([
								{
									icon: ICONS.trash,
									text: "Remove association",
									onClick:
										setAssociationRecordToRemove && !isShared ? () => setAssociationRecordToRemove(record) : undefined,
									tooltip: isShared
										? "Records shown in a workspace cannot be removed, please hide it first."
										: undefined,
									variant: "destructive",
									type: "button",
								},
						  ] satisfies MenuItem[])
						: undefined,
			};
		},
		[sourceRecord, principal, setAssociationRecordToPreview, setAssociationRecordToRemove]
	);

	return objectAssociation ? getAssociationContextMenuItems : undefined;
}
