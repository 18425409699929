import { mSystemObjectDef } from "../system_object_def";

export class mTaskDef extends mSystemObjectDef {
	static CLASS_NAME = "mTaskDef";
	static NAME = "Task";
	static PLURAL_NAME = "Tasks";
	static ICON = "ph-check-square-offset";

	static ID = 9;

	static STATUS_FIELD_NAME = "status";
	static PRIORITY_FIELD_NAME = "priority";
	static DUE_DATE_FIELD_NAME = "dueDate";
	static ASSIGNEE_FIELD_NAME = "assignee";
	static PROGRESS_FIELD_NAME = "progress";
	static TYPE_FIELD_NAME = "type";

	static COMPLETED_STATUS_NAME = "Completed";
	static TO_DO_STATUS_NAME = "To Do";

	constructor(ownerId) {
		super(mTaskDef.ID, ownerId);
	}
}
