import { HasClaimConfig } from "../components/sections";

export const userClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "userView",
		label: "View",
		descriptions: {
			hasClaim: "Can view all users",
			hasNotClaim: "Cannot view users",
		},
	},
	{
		name: "userEdit",
		label: "Add and Edit",
		dependsOn: ["userView"],
		descriptions: {
			hasClaim: "Can add users and edit all users",
			hasNotClaim: "Cannot add or edit users",
		},
	},
	{
		name: "userDelete",
		label: "Delete",
		dependsOn: ["userView"],
		descriptions: {
			hasClaim: "Can delete all users",
			hasNotClaim: "Cannot delete users",
		},
	},
	{
		name: "userLoginEdit",
		label: "Enable/Disable Login",
		dependsOn: ["userView"],
		descriptions: {
			hasClaim: "Can enable and disable user login",
			hasNotClaim: "Cannot enable and disable user login",
		},
	},
];
