import { rsr, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import {
	getSDRecordNameFieldInfo,
	InfiniteRecordSearchParams,
	SortingDetails,
	SortingOrder,
	updateSortingDetailsForField,
} from "../../../../../../../records";
import { WorkspaceLibraryState } from "../../../types";
import { HeaderCell, ROW_IS_LOADING_PLACEHOLDER, ROW_LINK_COLUMN_ID } from "../../../../../../../Table";
import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../../../../fields";
import { Pill } from "../../../../../../../../components/Pill/Pill";
import { PATHS } from "../../../../../../../../routes";
import { FavoritedRecordRowData, FavoritedRecordTableColumn, FavoritedRecordTableRow } from "../types";

export const SD_RECORD_COLUMN_ID = "sdRecord";
export const NAME_COLUMN_ID = "_name";
export const RECORD_TYPE_COLUMN_ID = "_objectDefId";

export const DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID = {
	[NAME_COLUMN_ID]: 400,
};

export function generateColumns(
	objectMap: Map<number, SDObject>,
	sorting: SortingDetails[] | undefined,
	setSorting: (sortingDetails: SortingDetails[]) => void
): FavoritedRecordTableColumn[] {
	const renderNameRowCell = (cellContents: FavoritedRecordRowData, row: FavoritedRecordTableRow) => {
		if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof cellContents === "boolean") {
			return <Skeleton className="my-1 h-6 w-full" />;
		}
		const isSdRecord = typeof cellContents === "object" && "_id" in cellContents;
		if (typeof cellContents === "number" || isSdRecord) {
			return undefined;
		}
		return (
			<DisplayFieldFactory field={cellContents.field} value={cellContents.value} variant={DisplayFieldVariant.table} />
		);
	};

	const renderObjectRowCell = (cellContents: FavoritedRecordRowData, row: FavoritedRecordTableRow) => {
		if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof cellContents === "boolean") {
			return <Skeleton className="my-1 h-6 w-full" />;
		}
		if (typeof cellContents !== "number") {
			return undefined;
		}
		const sdObject = objectMap.get(cellContents);
		if (!sdObject) {
			return undefined;
		}
		return (
			<Pill variant="outlined" icon={sdObject._icon}>
				{sdObject._displayName}
			</Pill>
		);
	};

	const getColumnIdBasedProps = (columnId: string) => {
		return {
			columnId,
			sortingOrder: sorting?.find((sort) => sort.fieldId === columnId)?.order,
			onSortingOrderChange: (order?: SortingOrder) => {
				setSorting(updateSortingDetailsForField(sorting ?? [], columnId, order));
			},
		};
	};

	return [
		{
			id: NAME_COLUMN_ID,
			displayName: () => <HeaderCell {...getColumnIdBasedProps(NAME_COLUMN_ID)} columnName="Name" />,
			renderRowCell: renderNameRowCell,
		},
		{
			id: RECORD_TYPE_COLUMN_ID,
			displayName: () => <HeaderCell {...getColumnIdBasedProps(RECORD_TYPE_COLUMN_ID)} columnName="Record type" />,
			renderRowCell: renderObjectRowCell,
		},
	];
}

export function generateRows(
	sdRecords: SDRecord[],
	objectMap: Map<number, SDObject>,
	workspaceId: number,
	workspaceLibraryState: WorkspaceLibraryState
): FavoritedRecordTableRow[] {
	return sdRecords.map((record) => {
		const sdObject = objectMap.get(record._objectDefId);
		const row: FavoritedRecordTableRow = {
			[SD_RECORD_COLUMN_ID]: record,
			[NAME_COLUMN_ID]: sdObject ? getSDRecordNameFieldInfo(sdObject, record) : false,
			[RECORD_TYPE_COLUMN_ID]: sdObject?._id ?? false,
		};
		row[ROW_LINK_COLUMN_ID] = () =>
			PATHS.WORKSPACE_LIBRARY(workspaceId, { ...workspaceLibraryState, recordId: record._id });
		return row;
	});
}

export function getAdditionalRecordSearchParams(
	favoritedRecordIds: number[] | undefined,
	sorting: SortingDetails[]
): InfiniteRecordSearchParams | undefined {
	if (!favoritedRecordIds) {
		return undefined;
	}

	const sortedFavoriteRecordIds = [...new Set(favoritedRecordIds)].sort((a, b) => a - b);

	return {
		query: rsr
			.query()
			.and(rsr.equalsAnyOf("id", sortedFavoriteRecordIds.length ? sortedFavoriteRecordIds : [-1]))
			.buildQuery(),
		sort: sorting.map((value) => ({ [value.fieldId]: { order: value.order } })),
	};
}
