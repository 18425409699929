import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, Icon, Tooltip } from "@salesdesk/daisy-ui";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { PATHS, useStableNavigate } from "../../../../../../routes";
import { useWebPrincipal } from "../../../../../../auth";

import { MenuItem, PopoverMenu } from "../../../../../menu";
import { useVideoCallLogicContext } from "../../../VideoCallProvider";
import { ASSET_PANEL_INDEX } from "../../../MeetingPage/components/VideoCallPage/VideoSidePanel/utils";
import { VIDEO_CALL_CONTAINER_ID } from "../../../../utils";

export function TogglePresentButton() {
	const navigate = useStableNavigate();
	const principal = useWebPrincipal();
	const {
		room,
		currentMeetingRecord,
		fileSharingParticipant,
		stopSharingFile,
		isSharingScreen,
		toggleScreenShare,
		updatePanelTab,
	} = useVideoCallLogicContext();

	const canShareScreen = principal.can(AbilityAction.Share, AbilitySubject.VideoCallScreen);
	const canPresentFile = principal.can(AbilityAction.Present, AbilitySubject.VideoCallFile);
	const isFileSharing = fileSharingParticipant && room?.localParticipant === fileSharingParticipant.participant;
	const isPresenting = isSharingScreen || isFileSharing;

	const menuItems: MenuItem[] = useMemo(() => {
		const menuItems: MenuItem[] = [];

		let presentFileItem: MenuItem = {
			text: "Present file",
			icon: "ph-export",
			type: "button",
		};

		// If no one is sharing file, the user can share from Assets or local drive
		if (!fileSharingParticipant || fileSharingParticipant.participant === room?.localParticipant) {
			// TODO: Clicking assets option should open up assets tab in side panel https://salesdesk101.atlassian.net/browse/SAL-1944
			const filePresentingOptions = [
				{
					text: "Assets",
					onClick: () => {
						updatePanelTab(ASSET_PANEL_INDEX);

						if (currentMeetingRecord) {
							navigate(PATHS.MEETING(currentMeetingRecord));
						}
					},
					type: "button",
				},
				{
					text: "Local Drive",
					type: "button",
				},
			] satisfies MenuItem[];

			presentFileItem = {
				...presentFileItem,
				type: "subMenu",
				subMenu: filePresentingOptions,
				subMenuPopoverOptions: { placement: "right-end" },
			};
		}

		if (canShareScreen)
			menuItems.push({ icon: ICONS.screen, text: "Share screen", onClick: toggleScreenShare, type: "button" });

		if (canPresentFile) menuItems.push(presentFileItem);

		return menuItems;
	}, [
		fileSharingParticipant,
		room?.localParticipant,
		canShareScreen,
		toggleScreenShare,
		canPresentFile,
		updatePanelTab,
		currentMeetingRecord,
		navigate,
	]);

	if (isPresenting) {
		return (
			<Tooltip text={`Stop ${isSharingScreen ? "sharing" : "presenting"}`} placement="top">
				<Button
					key={"share"}
					variant="primary"
					startIcon={ICONS.stopCallPresent}
					size="lg"
					onClick={() => {
						if (isFileSharing && stopSharingFile) {
							stopSharingFile();
						} else if (isSharingScreen) {
							toggleScreenShare();
						}
					}}
				/>
			</Tooltip>
		);
	}

	if (menuItems.length === 1) {
		const item = menuItems[0];
		if ("onClick" in item) {
			return (
				<Tooltip text={item.text} placement="top">
					<Button key={"share"} variant="primary" startIcon={item.icon} size="lg" onClick={item.onClick} />
				</Tooltip>
			);
		}
	}

	return menuItems.length > 0 ? (
		<div>
			{/* Loads in svg icon to stop presenting call for smooth transition between the two */}
			<Icon className="sr-only" icon={ICONS.stopCallPresent} />
			<PopoverMenu
				tooltipText={capitalizeString(
					`${canShareScreen ? "share screen" : ""}${canShareScreen && canPresentFile ? " or " : ""}${
						canPresentFile ? "present file" : ""
					}`
				)}
				placement="top-start"
				rootElementId={VIDEO_CALL_CONTAINER_ID}
				menuContents={menuItems}
			>
				<Button
					key={"share"}
					variant="primary"
					size="lg"
					startIcon={ICONS.callPresent}
					onClick={toggleScreenShare}
					disabled={!room}
				/>
			</PopoverMenu>
		</div>
	) : null;
}
