import { useCurrentVideoTime } from "../../hooks/useCurrentVideoTime";
import { RefObject } from "react";
import { useGraphDimensions } from "../../hooks/useGraphDimentions";
import { useMediaAnalysisContext } from "../../hooks/useMediaAnalisysContext";
import { useGraphVideoSeeking } from "../../hooks/useGraphVideoSeeking";

interface VideoCursorProps {
	holderRef: RefObject<HTMLDivElement>;
}

export function VideoTimeCursor({ holderRef }: VideoCursorProps) {
	const { viewStartTime, videoDuration, zoomFactor } = useMediaAnalysisContext();
	const currentVideoTime = useCurrentVideoTime();
	const { containerWidth, cursorTop, cursorHeight } = useGraphDimensions(holderRef);

	useGraphVideoSeeking(holderRef);

	if (currentVideoTime === undefined) {
		return null;
	}

	const viewStartX = (viewStartTime / videoDuration) * containerWidth * zoomFactor;
	const positionX = (currentVideoTime / videoDuration) * containerWidth * zoomFactor - viewStartX;

	return (
		<div
			className="bg-c_action_focus absolute z-10 w-px"
			style={{
				top: cursorTop,
				height: cursorHeight,
				left: positionX,
			}}
		/>
	);
}
