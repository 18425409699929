import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../auth";
import { useGetRecordWithObjectByRecordId } from "../../../../hooks";
import { PATHS } from "../../../../routes";
import { SettingsContainer } from "../../SettingsContainer";
import { UserProfileForm } from "./UserProfileForm";

export function ProfileSettingsPage() {
	const principal = useWebPrincipal();

	const { sdObject, sdRecord } = useGetRecordWithObjectByRecordId(principal.UserRecordId ?? undefined);

	return (
		<SettingsContainer
			breadcrumbs={[
				{
					node: "Settings",
					icon: ICONS.settings,
				},
				{
					node: "Profile",
					link: PATHS.SETTINGS_PROFILE(),
				},
			]}
			formPage
		>
			{sdObject && sdRecord ? <UserProfileForm userSDObject={sdObject} userSDRecord={sdRecord} /> : null}
		</SettingsContainer>
	);
}
