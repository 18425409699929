import { useCallback } from "react";

import { PutRecordAssociationPath } from "@salesdesk/salesdesk-schemas";

import { useCreateRecordAssociationMutation } from "../api/recordAssociationsApi";
import { useGetContextWorkspaceId } from "../../workspaces";

export function useCreateRecordAssociation() {
	const workspaceId = useGetContextWorkspaceId();

	const [createRecordAssociation, { isLoading }] = useCreateRecordAssociationMutation();

	return {
		createAssociation: useCallback(
			(association: PutRecordAssociationPath) => {
				return createRecordAssociation({ association, workspaceId }).unwrap();
			},
			[createRecordAssociation, workspaceId]
		),
		isLoading,
	};
}
