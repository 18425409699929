import { useEventContext } from "../../../hooks/useEventContext";
import { ChannelParams } from "@salesdesk/salesdesk-schemas";

export function MessagingChannelName() {
	const { event } = useEventContext();

	const params = event.params as ChannelParams;

	return `${params.channelName} channel`;
}
