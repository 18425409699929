import { PropsWithChildren } from "react";
import { BreadcrumbItem, Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

interface AdminContainerProps extends PropsWithChildren {
	breadcrumbs: BreadcrumbItem[];
	formPage?: boolean;
}

export function SettingsContainer({ breadcrumbs, formPage, children }: AdminContainerProps) {
	return (
		<div className="flex h-full flex-col">
			<div className="bg-c_bg_04 flex w-full px-6 py-3">
				<Breadcrumbs crumbs={breadcrumbs} />
			</div>
			<div className="grow overflow-y-auto">
				{formPage ? <div className="ml-[123px] mr-8 h-full max-w-[574px] pb-6 pt-8">{children}</div> : children}
			</div>
		</div>
	);
}
