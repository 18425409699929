import { PropsWithChildren } from "react";
import clsx from "clsx";

import { Tab as HeadlessTab } from "@headlessui/react";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { TabVariant } from "./TabList";

interface TabProps {
	error?: boolean;
	disabled?: boolean;
	variant?: TabVariant;
}

export function Tab({ children, error, disabled, variant = "text" }: PropsWithChildren<TabProps>) {
	return (
		<HeadlessTab
			disabled={disabled}
			className={({ selected }) =>
				clsx(
					!disabled
						? selected
							? "hover:bg-c_action_03 hover:border-c_action_03 border-c_action_01 bg-c_action_01 text-c_text_inverted"
							: "border-c_bg_02 bg-c_bg_02 hover:border-c_bg_05 hover:bg-c_bg_05"
						: "bg-c_bg_disabled_02 border-c_bg_disabled_02 text-c_text_disabled cursor-not-allowed",
					"ui-focus-visible:ring ui-focus-visible:border-c_bg_01 text-label-sm rounded-full border",
					variant === "text" ? "px-4 py-2" : "h-8 w-8"
				)
			}
		>
			<div
				className={clsx(
					"flex flex-1 items-center justify-center",
					variant === "text" ? (error ? "gap-2" : "mx-3") : undefined
				)}
			>
				{children}
				<Icon icon={error && !disabled ? ICONS.warningCircle : undefined} className="text-c_danger_02 flex" size="sm" />
			</div>
		</HeadlessTab>
	);
}
