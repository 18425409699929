import { NumberParam, withDefault } from "use-query-params";
import { WorkspaceOverviewState } from "../types";
import { useURLState } from "../../../../../../hooks";

export const DEFAULT_WORKSPACE_OVERVIEW_STATE: WorkspaceOverviewState = {
	recordId: undefined,
};

const WORKSPACE_OVERVIEW_STATE_VALUE_VALIDITY: Record<keyof WorkspaceOverviewState, (value: any) => boolean> = {
	recordId: (value: unknown) => typeof value === "number",
};

export function useWorkspaceOverviewState() {
	const {
		urlState: workspaceOverviewState,
		propOnChange: workspaceOverviewPropOnChange,
		resetState: resetWorkspaceOverviewState,
	} = useURLState({
		defaultState: DEFAULT_WORKSPACE_OVERVIEW_STATE,
		valueValidityMap: WORKSPACE_OVERVIEW_STATE_VALUE_VALIDITY,
		paramConfigMap: {
			recordId: withDefault(NumberParam, DEFAULT_WORKSPACE_OVERVIEW_STATE.recordId),
		},
	});

	return { workspaceOverviewState, workspaceOverviewPropOnChange, resetWorkspaceOverviewState };
}
