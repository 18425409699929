import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import { Divider } from "../../../../../../../../components/Divider/Divider";
import { SkeletonTransition } from "../../../../../../../../components/Skeleton/SkeletonTransition";

export function HoldingPageDetailsSkeleton() {
	return (
		<SkeletonTransition>
			<Skeleton variant="secondary" className="h-6 w-full" />
			<Skeleton className="mt-4 h-5 w-1/3" />
			<Skeleton className="mt-4 h-5 w-2/3" />
			<Skeleton className="mt-4 h-5 w-1/3" />
			<Skeleton className="mt-4 h-5 w-2/3" />
			<Divider className="mx-0 mt-6" />
			<Skeleton variant="secondary" className="mt-6 h-6 w-full" />
			<div className="mt-4 flex w-2/3 gap-4">
				{[...Array(3)].map((_, index) => (
					<Skeleton variant="tertiary" key={index} className="h-5 flex-1" />
				))}
			</div>
			<Skeleton className="mt-4 h-5 w-2/3" />
			<Skeleton className="mt-4 h-5 w-2/3" />
			<Skeleton className="mt-4 h-5 w-2/3" />
			<Skeleton className="mt-4 h-5 w-1/4" />
		</SkeletonTransition>
	);
}
