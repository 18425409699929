import { mTaskDef, mIssueDef } from "@salesdesk/salesdesk-model";
import { AbilityAction, AbilitySubject, sdSubject, CanParameters } from "@salesdesk/salesdesk-schemas";

import { AllowedRecordCreatePanel } from "../types";

export const allowedRecordCreatePanelIdsMap = {
	[AllowedRecordCreatePanel.Task]: mTaskDef.ID,
	[AllowedRecordCreatePanel.Issue]: mIssueDef.ID,
};

export const ableToCreateCompletableRecordMapById: Record<number, CanParameters[]> = {
	[mTaskDef.ID]: [
		[AbilityAction.Create, AbilitySubject.VideoCallTask],
		[AbilityAction.Create, AbilitySubject.RecordAssociation],
		[AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: mTaskDef.ID })],
	],
	[mIssueDef.ID]: [
		[AbilityAction.Create, AbilitySubject.VideoCallIssue],
		[AbilityAction.Create, AbilitySubject.RecordAssociation],
		[AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: mIssueDef.ID })],
	],
};
