import { useMemo } from "react";

import { BASE_COLORS } from "@salesdesk/salesdesk-ui";
import { BOARD_VIEW } from "@salesdesk/salesdesk-model";

import { AGGREGATIONS, AggregationResult, RecordGroupDetails } from "../../../../types";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";
import { useBoardAggSelector, useBoardGroupBySelector, useBoardViewSelector } from "../../../../store";
import { KanbanView } from "./KanbanView/components/KanbanView";
import { ListView } from "./ListView";

export function GroupedView() {
	const { groupableFields, boardFieldMap } = useDataboardDetailsContext();

	const boardAgg = useBoardAggSelector();
	const boardGroupBy = useBoardGroupBySelector();
	const boardView = useBoardViewSelector();

	const currentAggregation: AggregationResult | undefined = useMemo(() => {
		if (!boardAgg) {
			return undefined;
		}

		const { fieldId, agg: currentAgg } = boardAgg;

		const aggregationName = AGGREGATIONS.find((agg) => agg.key === currentAgg)?.name || "";
		const field = boardFieldMap.get(fieldId);

		if (!field) {
			return undefined;
		}

		return {
			field,
			aggregationName,
			value: 0,
		};
	}, [boardAgg, boardFieldMap]);

	const viewGroups: RecordGroupDetails[] = useMemo(() => {
		const ungroupedGroup: RecordGroupDetails = {
			id: 0,
			name: "Ungrouped",
			color: BASE_COLORS.c_bg_disabled_02,
			filterParams: [],
			isLoading: true,
			aggregationResult: currentAggregation,
		};

		const singleOptionGroups = groupableFields.singleOptionFields;
		if (!boardGroupBy || !singleOptionGroups) {
			return [ungroupedGroup];
		}

		const groupByField = singleOptionGroups.find((field) => String(field._id) === boardGroupBy);

		//TODO: Currently only support grouping by fields with fixed option values. In future this can be updated
		if (!groupByField || !groupByField._optionValues?.length) {
			return [ungroupedGroup];
		}

		return groupByField._optionValues.map((optionValue) => {
			const id = optionValue.id || 0;

			return {
				id,
				name: optionValue.name,
				icon: optionValue.icon || undefined,
				color: optionValue.color || undefined,
				filterParams: [{ equals: { [String(groupByField._id)]: id } }],
				isLoading: true,
				aggregationResult: currentAggregation,
			};
		});
	}, [boardGroupBy, currentAggregation, groupableFields.singleOptionFields]);

	if (boardView === BOARD_VIEW.LIST) {
		return <ListView recordGroups={viewGroups} />;
	}

	if (boardView === BOARD_VIEW.KANBAN) {
		return <KanbanView recordGroups={viewGroups} />;
	}
}
