import { asConst } from "json-schema-to-ts";
import { idSchema } from "../components";

export const startFileUploadResponseSchema = asConst({
	title: "Start File Upload Response",
	description: "Contains the SignedUrl to upload the content of the file to",
	type: "object",
	additionalProperties: false,
	required: ["signedUrl", "fileId"],
	properties: {
		signedUrl: {
			tile: "Signed URL",
			description: "URL to which to upload file",
			type: "string",
		},
		fileId: {
			...idSchema,
			title: "File ID",
			description: "ID created for new file",
		},
	},
});
