import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mDurationFieldDef extends mFieldDef {
	static supportedComponentTypes = [FIELD_COMPONENT_TYPES.DURATION.name];
	static defaultComponentType = mDurationFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);

		this._componentType = mDurationFieldDef.defaultComponentType;

		// How the data is stored internally (HH:mm)
		this._format = "HH:mm";

		// The format to be used by the user to input the date and time
		this._formatDescription = "hh:mm";

		this._icon = FIELD_TYPES.DURATION.icon;

		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.DURATION.name;
	}

	override get supportedComponentTypes() {
		return mDurationFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value) && (isNaN(value) || !Number.isInteger(value))) {
			return `Field ${this.name} expects an integer.`;
		}

		return super.validate(value, isTemplate);
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}
}
