import { asConst } from "json-schema-to-ts";
import { roleClaimSchema } from "./properties";
import { stringSchema } from "../components";

export const rolePatchRequestSchema = asConst({
	title: "RolePatchRequestSchema",
	description: "The schema used for patching Roles",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	required: [],
	properties: {
		// TODO: Make Specific Properties with better titles/descriptions etc
		name: {
			...stringSchema,
			maxLength: 100,
		},
		claims: {
			type: "array",
			items: roleClaimSchema,
		},
	},
});
