import { useMemo } from "react";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { getSDObjectFieldByName, getSDRecordFieldValue } from "@salesdesk/salesdesk-schemas";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { JSONContent } from "@tiptap/core";
import { convertRichTextJSONToText } from "../../../../../../../fields";

export function useGetProjectOverview() {
	const { workspaceObject, workspaceRecord } = useWorkspaceContext();

	return useMemo(() => {
		if (!workspaceObject || !workspaceRecord) {
			return {};
		}

		const projectOverviewField = getSDObjectFieldByName(workspaceObject, mWorkspaceDef.PROJECT_OVERVIEW_FIELD_NAME);
		if (!projectOverviewField) {
			return {};
		}

		const projectOverviewValue =
			(getSDRecordFieldValue(workspaceRecord, projectOverviewField._id)?._value as JSONContent) ?? undefined;

		const hasText = Boolean(convertRichTextJSONToText(projectOverviewValue));

		return {
			projectOverviewFieldInfo: { field: projectOverviewField, value: projectOverviewValue },
			isEmpty: !hasText,
		};
	}, [workspaceObject, workspaceRecord]);
}
