import { SkeletonRecordAssociationCard } from "./RecordAssociationCard";

interface RecordAssociationListLoadingProps {
	isAssetAssociation?: boolean;
	numberOfSkeletonCards?: number;
}

export function RecordAssociationListLoading({
	isAssetAssociation = false,
	numberOfSkeletonCards = 2,
}: RecordAssociationListLoadingProps) {
	return [...Array(numberOfSkeletonCards)].map((_, index) => (
		<div key={index} className="size-full">
			<SkeletonRecordAssociationCard isAssetAssociation={isAssetAssociation} />
		</div>
	));
}
