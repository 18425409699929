import { forwardRef, useState, useEffect, useCallback } from "react";
import clsx from "clsx";

import { Button, combineRefs, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useFileInputController } from "../FileInput/hooks";
import { LogoInputProps } from "../../types";
import { Logo, LogoSkeleton } from "../../../../components/Logo";

import { CroppingPhotoModal } from "../CroppingPhotoModal";

export const LOGO_MIME_TYPES = ["image/jpeg", "image/png"];

export const LogoInput = forwardRef<HTMLInputElement, LogoInputProps>(
	({ id, value, companyName, onChange, uploadProgress, disabled, ariaAttributes, hasError, onBlur }, ref) => {
		const [editablePhotoFile, setEditablePhotoFile] = useState<File | null>(null);
		const [isCroppingModalOpen, setCroppingModalOpen] = useState(false);
		const [isLogoApplying, setIsLogoApplying] = useState(false);

		const { draggingOver, innerRef, clearFile, updateFile, handleDrop, handleDragEnter, handleDragExit } =
			useFileInputController({
				value: editablePhotoFile,
				onChange: setEditablePhotoFile,
				uploadProgress,
				disabled,
				canUpdateIfNotEmpty: true,
			});

		const noFileSet = value == null && uploadProgress == null;

		const browseFiles = () => {
			innerRef.current?.click();
		};

		const onPhotoEditorSave = useCallback(
			(newValue: File) => {
				onChange(newValue);
				setIsLogoApplying(true);
			},
			[onChange]
		);

		useEffect(() => {
			if (value) {
				setIsLogoApplying(false);
				clearFile();
			}
		}, [value, clearFile]);

		useEffect(() => {
			setCroppingModalOpen(Boolean(editablePhotoFile));
		}, [editablePhotoFile]);

		return (
			<>
				<div
					className="relative flex flex-wrap items-center gap-6"
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragExit}
					onDrop={handleDrop}
					onDragOver={(e) => e.preventDefault()}
				>
					<label
						htmlFor={id}
						className={clsx(
							hasError ? "border-c_danger_02 border-2" : "border-c_bg_02",
							"transition-color group/logo-container relative z-50 size-20 max-w-20 shrink-0 overflow-hidden rounded-md",
							"focus-visible-within:border-2 focus-visible-within:ring ring-c_action_focus hover:border-2",
							draggingOver && "border-2 ring",
							disabled ? "cursor-not-allowed" : "cursor-pointer"
						)}
					>
						<input
							id={id}
							type="file"
							ref={combineRefs([innerRef, ref])}
							{...ariaAttributes}
							className="absolute z-0 h-0 w-0 opacity-0"
							accept={LOGO_MIME_TYPES.join(",")}
							onChange={(e) => {
								updateFile(e.target.files?.item(0));
							}}
							disabled={disabled}
							onBlur={onBlur}
						/>
						{/* Shows the add logo placeholder below if no file is set and the user hovers/focuses on the input */}
						<div
							className={clsx(
								"absolute left-1/2 top-1/2 z-10 size-20 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-75",
								noFileSet && "group-focus-within/logo-container:opacity-0 group-hover/logo-container:opacity-0",
								draggingOver && noFileSet ? "opacity-0" : "opacity-100"
							)}
						>
							{uploadProgress != null ? (
								<LogoSkeleton size="xl" />
							) : (
								<Logo companyName={companyName ?? ""} image={value} size="xl" />
							)}
						</div>
						{noFileSet ? (
							<div className="bg-c_action_03 text-c_icon_inverted absolute z-0 flex size-full items-center justify-center">
								<Icon size="lg" icon={ICONS.plus} />
							</div>
						) : null}
					</label>
					{value ? (
						<div className="flex gap-3">
							<Button
								variant="outlined"
								size="sm"
								onClick={(event) => {
									event.stopPropagation();
									event.preventDefault();
									browseFiles();
								}}
								disabled={disabled}
							>
								Change
							</Button>
							<Button
								variant={disabled ? "text" : "inverted"}
								startIcon={ICONS.trash}
								size="sm"
								onClick={(event) => {
									event.stopPropagation();
									event.preventDefault();
									clearFile();
								}}
								disabled={disabled}
							>
								Clear
							</Button>
						</div>
					) : null}
				</div>
				{isCroppingModalOpen ? (
					<CroppingPhotoModal
						open={isCroppingModalOpen}
						photoFile={editablePhotoFile}
						onSave={onPhotoEditorSave}
						onClose={clearFile}
						variant="square"
						isLoading={isLogoApplying}
					/>
				) : null}
			</>
		);
	}
);
