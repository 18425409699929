import { Field, getSDObjectFields, rsr, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useRecordSearch } from "./useRecordSearch";
import { useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { UniqueFieldType } from "@salesdesk/salesdesk-model";
import { useGetObjectsQuery } from "../../objects/api/objectsApi";

export interface UniqueCheckParams {
	sdObject?: SDObject;
	sdRecord?: SDRecord;
	field?: Field;
	fieldValue?: unknown;
}

export function useUniqueCheck({ sdObject, sdRecord, field, fieldValue }: UniqueCheckParams) {
	const { searchHits, updateSearchParams, isLoading } = useRecordSearch();
	const { data: sdObjects } = useGetObjectsQuery();

	const globalUniqueFieldNameObjectFieldsMap = useMemo(() => {
		const map = new Map<string, [SDObject, Field][]>();
		sdObjects?.forEach((object) => {
			getSDObjectFields(object, { includeHidden: true, includeReadOnly: true }).forEach((field) => {
				if (field._unique === UniqueFieldType.Global) {
					let objects = map.get(field._name);
					if (objects == null) {
						objects = [];
						map.set(field._name, objects);
					}
					objects.push([object, field]);
				}
			});
		});
		return map;
	}, [sdObjects]);

	const uniqueCheck = useMemo(
		() =>
			debounce((value) => {
				if (!sdObject || !field?._id || !value) return;

				const query = rsr.query().and(rsr.equals("_deleted", false)).not(rsr.equals("isTemplate", true));

				if (field._unique === UniqueFieldType.Object) {
					query.and(rsr.equals("_objectDefId", sdObject._id)).and(rsr.equals(String(field._id), value));
				} else if (field._unique === UniqueFieldType.Global) {
					const objectFields = globalUniqueFieldNameObjectFieldsMap.get(field._name);
					objectFields?.forEach(([object, globalUniqueField]) =>
						query.or(
							rsr
								.subQuery()
								.and(rsr.equals("_objectDefId", object._id))
								.and(rsr.equals(String(globalUniqueField._id), value))
								.buildSubQuery()
						)
					);
				} else {
					return;
				}

				if (sdRecord) {
					query.and(rsr.not(rsr.equals("id", sdRecord._id)));
				}
				updateSearchParams(rsr.create().query(query.buildQuery()).size(1).buildRequest());
			}, 300),
		[field, sdObject, sdRecord, updateSearchParams, globalUniqueFieldNameObjectFieldsMap]
	);

	useEffect(() => {
		if (field == null || field._unique === UniqueFieldType.None) return;
		uniqueCheck(fieldValue);
	}, [field, fieldValue, uniqueCheck]);

	const alreadyExists = fieldValue != null && searchHits && searchHits.hitCount > 0;

	return {
		uniqueError: alreadyExists ? "This value already exists" : undefined,
		isUniqueChecking: isLoading,
	};
}
