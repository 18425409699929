import {
	EventSearchResults,
	EventsQuery,
	EventsSummary,
	EventsSummaryQuery,
	EventCreateRequest,
} from "@salesdesk/salesdesk-schemas";

import { SDApi, withWorkspaceContext } from "../../api";
import { createUniqueId } from "@salesdesk/salesdesk-utils";

export type PostEventArgs = Omit<EventCreateRequest, "id" | "tstamp"> & { workspaceId?: number };

type WithWorkspaceId<T> = T & { workspaceId: number };

type WorkspaceEventsQuery = WithWorkspaceId<EventsQuery>;
type WorkspaceEventsSummaryQuery = WithWorkspaceId<EventsSummaryQuery>;

const NON_RECORD_EVENT = "NON_RECORD";

export const eventsApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getEventSummary: builder.query<EventsSummary, EventsSummaryQuery>({
				query: (arg) => ({
					url: `/events/summary`,
					params: arg,
				}),
				providesTags: (tag, res, arg) => [{ type: "Events", id: arg.recordId || NON_RECORD_EVENT }],
			}),
			getWorkspaceEventSummary: builder.query<EventsSummary, WorkspaceEventsSummaryQuery>({
				query: ({ workspaceId, ...arg }) => ({
					url: `/workspaces/${workspaceId}/events/summary`,
					params: arg,
				}),
				providesTags: (tag, res, arg) => [{ type: "Events", id: arg.recordId || NON_RECORD_EVENT }],
			}),
			getEvents: builder.query<EventSearchResults, EventsQuery>({
				query: (arg) => ({
					url: `/events`,
					params: arg,
				}),
				providesTags: (tag, res, arg) => [{ type: "Events", id: arg.recordId || NON_RECORD_EVENT }],
			}),
			getWorkspaceEvents: builder.query<EventSearchResults, WorkspaceEventsQuery>({
				query: ({ workspaceId, ...arg }) => ({
					url: `/workspaces/${workspaceId}/events`,
					params: arg,
				}),
				providesTags: (tag, res, arg) => [{ type: "Events", id: arg.recordId || NON_RECORD_EVENT }],
			}),
			postEvent: builder.mutation<"OK", PostEventArgs>({
				query: ({ workspaceId, ...arg }) => ({
					url: withWorkspaceContext(`/events`, workspaceId),
					method: "POST",
					body: { ...arg, tstamp: new Date().getTime(), id: createUniqueId() },
				}),
				invalidatesTags: (tag, res, arg) => [{ type: "Events", id: arg.record_id || NON_RECORD_EVENT }],
			}),
		};
	},
});

export const {
	useGetEventsQuery,
	useGetWorkspaceEventsQuery,
	useGetEventSummaryQuery,
	useGetWorkspaceEventSummaryQuery,
	usePostEventMutation,
} = eventsApi;
