import { asConst } from "json-schema-to-ts";
import { allowedTypesSchema, queryClausePropertyValuesSchema } from "../../../search";
import { nonNegativeIntegerSchema } from "../../../components";
import { FieldIdRegexp, TimeRangeFieldIdRegexp } from "./recordQueryClausePropertyValueSchema";

export const recordQueryClauseFieldOnlyValuesSchema = asConst({
	...queryClausePropertyValuesSchema,
	additionalProperties: false,
	patternProperties: {
		[FieldIdRegexp]: {
			type: "array",
			items: allowedTypesSchema,
			minItems: 1,
		},
		[TimeRangeFieldIdRegexp]: {
			type: "array",
			items: nonNegativeIntegerSchema,
			minItems: 1,
		},
	},
});
