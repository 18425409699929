import { Button } from "@salesdesk/daisy-ui";
import { Comment } from "@salesdesk/salesdesk-schemas";

import { AlertDialog } from "../../../../../../Dialog/AlertDialog/AlertDialog";
import { CommentPreview } from "./CommentPreview";

interface ResolveCommentDialogProps {
	comment?: Comment;
	inDocumentText?: string;
	isResolving?: boolean;
	onConfirm: () => void;
	onDismiss: () => void;
}

export function ResolveCommentDialog({
	comment,
	inDocumentText,
	isResolving,
	onConfirm,
	onDismiss,
}: ResolveCommentDialogProps) {
	return (
		<AlertDialog
			title="Resolve comment"
			message={
				<div className="flex flex-col gap-4">
					Are you sure you want to mark this comment and all of its replies as resolved?
					{comment ? <CommentPreview comment={comment} inDocumentText={inDocumentText} /> : null}
				</div>
			}
			open={!!comment}
			onOpenChange={(open) => {
				if (!open) {
					onDismiss();
				}
			}}
		>
			<div className="flex items-center justify-end gap-3">
				<Button size="lg" variant="secondary" onClick={() => onDismiss()}>
					Cancel
				</Button>
				<Button isLoading={isResolving} onClick={onConfirm} variant="primary" size="lg">
					Resolve
				</Button>
			</div>
		</AlertDialog>
	);
}
