import { HoldingPageDetailsSection } from "./HoldingPageDetailsSection";
import { Field, FieldValue } from "@salesdesk/salesdesk-schemas";
import { DisplayFieldFactory } from "../../../../../../../fields";

interface AgendaDetailsSectionProps {
	field: Field;
	fieldValue: FieldValue;
}

export function AgendaDetailsSection({ field, fieldValue }: AgendaDetailsSectionProps) {
	return (
		<HoldingPageDetailsSection title="Agenda">
			<div className="bg-c_bg_01 rounded-md px-4 py-2">
				<DisplayFieldFactory field={field} value={fieldValue._value} />
			</div>
		</HoldingPageDetailsSection>
	);
}
