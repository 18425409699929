import { asConst } from "json-schema-to-ts";
import { recordObjectIdSchema } from "./properties";
import { sdRecordPatchRequestSchema } from "./sdRecordPatchRequestSchema";
import { booleanSchema } from "../components";

const { _ownerId, ...createProperties } = sdRecordPatchRequestSchema.properties;

export const sdRecordCreateRequestSchema = asConst({
	title: "SDRecordCreateRequest",
	description: "Defines a record schema used for create requests",
	type: "object",
	additionalProperties: false,
	required: ["_objectDefId", "_name", "_dataInst"],
	properties: {
		_objectDefId: recordObjectIdSchema, // TODO: Rename objectId
		...createProperties,
		isTemplate: booleanSchema,
	},
});
