import { useMemo } from "react";

import { isUserObject, mUserDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import {
	SDRecord,
	SDObject,
	AbilitySubject,
	AbilityAction,
	sdSubject,
	sdSubjectField,
} from "@salesdesk/salesdesk-schemas";

import { MenuItem } from "../../../../../menu";

import { useWebPrincipal } from "../../../../../../auth";
import { useToggleUserLoginContext } from "../../../../../users";
import { useGetLoginAuthorizationDetails } from "../../../../hooks";
import { useOpenDMChannel } from "../../../../../messaging";
import { useUserLoginStatus } from "../../../../../users";

export function useUsersRecordActions(sdRecord?: SDRecord, sdObject?: SDObject, isActionButton?: boolean): MenuItem[] {
	const principal = useWebPrincipal();
	const { setUserRecordToInvite, setUserRecordToDeactivateLogin } = useToggleUserLoginContext();
	const { isSalesDeskUser, isAuthorizedToLogIn } = useGetLoginAuthorizationDetails(sdObject, sdRecord);
	const { openDMChannel, isLoading: isLoadingDMChannel } = useOpenDMChannel();
	const { isInvited } = useUserLoginStatus(sdRecord, sdObject, false);

	const hasUserRecordActions =
		isUserObject(sdRecord?._objectDefId) && sdRecord && sdObject && (isSalesDeskUser || !isActionButton);

	return useMemo(() => {
		if (!hasUserRecordActions) return [];

		const items: MenuItem[] = [];

		// Currently can't DM yourself or users who can't log in
		if (isSalesDeskUser && sdRecord._id !== principal.UserRecordId && openDMChannel && isAuthorizedToLogIn) {
			items.push({
				icon: ICONS.chatsCircle,
				text: "Send message",
				type: "button",
				onClick: () => openDMChannel(sdRecord._id),
				loading: isLoadingDMChannel,
			});
		}

		if (isInvited) {
			items.push({
				icon: ICONS.envelope,
				text: "Resend invitation",
				type: "button",
				onClick: () => setUserRecordToInvite(sdRecord, true),
			});
		}

		let text: string;
		let subText = "";
		let icon: string;

		if (!isSalesDeskUser) {
			text = isAuthorizedToLogIn ? "Deactivate login" : "Invite customer";
			subText = isAuthorizedToLogIn ? "Revoke customer access" : "Allow customer to login";
			icon = ICONS.userCircle;
		} else {
			text = `${isAuthorizedToLogIn ? "Deactivate login" : "Invite user"}`;
			icon = isAuthorizedToLogIn ? ICONS.lock : ICONS.lockOpen;
		}

		if (
			sdRecord &&
			principal.can(
				AbilityAction.Edit,
				sdSubject(AbilitySubject.Record, sdRecord),
				sdSubjectField(mUserDef.LOGIN_AUTHORIZED_FIELD_NAME)
			)
		)
			items.push({
				icon,
				text,
				subText,
				variant: isAuthorizedToLogIn ? "default" : "primary",
				type: "button",
				onClick: setUserRecordToInvite
					? () => {
							if (isAuthorizedToLogIn) {
								setUserRecordToDeactivateLogin(sdRecord);
							} else {
								setUserRecordToInvite(sdRecord);
							}
						}
					: undefined,
			});

		return items;
	}, [
		hasUserRecordActions,
		isSalesDeskUser,
		sdRecord,
		principal,
		openDMChannel,
		isLoadingDMChannel,
		isAuthorizedToLogIn,
		setUserRecordToInvite,
		setUserRecordToDeactivateLogin,
		isInvited,
	]);
}
