import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../../fields";
import { Field, SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";
import { RecordTableRowData } from "../types";
import { UserAuthorisedLoginIcon } from "../../../../../users/components/UserAuthorisedLoginIcon";
import { RecordBadges } from "../../../../../records/components/RecordBadges";
import { useRecordBadges } from "../../../../../records/components/RecordBadges/hooks/useRecordBadges";
import { isUserObject, mTaskDef } from "@salesdesk/salesdesk-model";
import { CompletableRecordCheckMarkWrapper, getIsCompletableRecord } from "../../../../../completableRecord";

interface TableRowCombinedNameCellProps {
	field: Field;
	value: RecordTableRowData;
	sdRecord: SDRecord;
	sdObject: SDObject;
}

export function TableRowNameCell({ field, value, sdRecord, sdObject }: TableRowCombinedNameCellProps) {
	const recordBadges = useRecordBadges(sdRecord);

	if (!value || typeof value === "boolean" || !("_value" in value)) {
		return "-";
	}

	let displayFieldValue = (
		<DisplayFieldFactory field={field} value={value._value} variant={DisplayFieldVariant.table} />
	);

	if (getIsCompletableRecord(sdObject))
		displayFieldValue = (
			<CompletableRecordCheckMarkWrapper sdObject={sdObject} sdRecord={sdRecord}>
				<DisplayFieldFactory field={field} value={value._value} variant={DisplayFieldVariant.table} />
			</CompletableRecordCheckMarkWrapper>
		);

	return (
		<div className="flex w-full items-center justify-between gap-2">
			<div className="flex w-full items-center gap-2 overflow-hidden">
				{displayFieldValue}
				{isUserObject(sdRecord._objectDefId) ? <UserAuthorisedLoginIcon sdRecord={sdRecord} /> : null}
			</div>
			<RecordBadges recordBadges={recordBadges} className="shrink-0" />
		</div>
	);
}
