import { SelectOption } from "../../../inputs";
import { useMemo } from "react";
import { getSDObjectFields, SDObject } from "@salesdesk/salesdesk-schemas";

export function useObjectFieldOptions(sdObject: SDObject | undefined) {
	const fieldOptions: SelectOption[] = useMemo(() => {
		if (!sdObject) {
			return [];
		}
		return getSDObjectFields(sdObject).map((field) => ({
			id: field._id,
			name: field._displayName,
		}));
	}, [sdObject]);

	return { fieldOptions };
}
