import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ERRORS } from "@salesdesk/salesdesk-ui";

import { getBookmarkRedirectUrl } from "../utils";
import { useGetMyBookmarkQuery } from "../api/bookmarksApi";
import { useGetObjectsQuery } from "../../objects/api/objectsApi";
import { useStableNavigate } from "../../../routes";

export function BookmarkRedirect() {
	const navigate = useStableNavigate();
	const params = useParams<{ bookmarkId: string }>();
	const bookmarkId = Number(params.bookmarkId);

	const { data: bookmark, isLoading } = useGetMyBookmarkQuery(bookmarkId);
	const { data: sdObjects } = useGetObjectsQuery();

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (bookmark) {
			navigate(getBookmarkRedirectUrl(sdObjects, bookmark));
		} else {
			navigate(`/error/${ERRORS.RESOURCE_NOT_FOUND.code.toLowerCase()}`);
		}
	}, [bookmark, isLoading, navigate, sdObjects]);

	return null;
}
