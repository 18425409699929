import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mIconChooserFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.ICON.name];
	static defaultComponentType: FieldComponentName = mIconChooserFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mIconChooserFieldDef.defaultComponentType;
		this._icon = FIELD_TYPES.ICON_CHOOSER.icon;
		this._supportsDefaultValue = true;
	}

	override get type() {
		return FIELD_TYPES.ICON_CHOOSER.name;
	}

	override get supportedComponentTypes() {
		return mIconChooserFieldDef.supportedComponentTypes;
	}
}
