import { useParams } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { RecordDetailView } from "../../../../records";
import { NavigateNotFound } from "../../../../../routes";

export function WorkspaceRecordDetailsPage() {
	const { sdObjectPath, recordId } = useParams<{ sdObjectPath: string; recordId: string }>();

	const recordIdAsNumber = Number(recordId);

	if (!sdObjectPath || isNaN(recordIdAsNumber)) {
		return <NavigateNotFound />;
	}

	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<RecordDetailView recordId={recordIdAsNumber} objectSlug={sdObjectPath} />
		</QueryParamProvider>
	);
}
