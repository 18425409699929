import { useState, useMemo } from "react";

import { mAssetDef } from "@salesdesk/salesdesk-model";

import { RecordAssociations } from "../../../../../../../recordAssociations";
import { useVideoCallLogicContext } from "../../../../../VideoCallProvider";
import {
	PanelTab,
	PanelTabList,
	PanelTabPanel,
	PanelTabPanels,
	PanelTabs,
} from "../../../../../../../../components/PanelTabs";
import { useObjectAssociations } from "../../../../../../../recordAssociations";
import { useGetContextWorkspaceId } from "../../../../../../../workspaces";

export function AssetPanel() {
	const workspaceId = useGetContextWorkspaceId();
	const associationOpeningVariant = workspaceId != null ? "workspace" : "recordDetailView";

	const { currentMeetingRecord } = useVideoCallLogicContext();

	const {
		objectAssociations: { allAssociations },
	} = useObjectAssociations(currentMeetingRecord?._objectDefId ?? undefined);

	const assetObjectAssociation = useMemo(
		() => allAssociations.find((objectAssociation) => objectAssociation.connectedObject.id === mAssetDef.ID),
		[allAssociations]
	);

	const [linkedAssetsCount, setLinkedAssetsCount] = useState(0);
	const [allAssetsCount, setAllAssetsCount] = useState(0);

	return (
		<div className="flex max-h-full flex-col gap-4 pb-4">
			<h2 className="text-label">{mAssetDef.PLURAL_NAME}</h2>
			<PanelTabs>
				<div className="w-fit">
					<PanelTabList isResponsive={false}>
						{currentMeetingRecord ? <PanelTab count={linkedAssetsCount}>Linked</PanelTab> : null}
						<PanelTab count={allAssetsCount}>All</PanelTab>
					</PanelTabList>
				</div>
				<div className="flex flex-col gap-4 overflow-y-auto">
					<PanelTabPanels>
						{currentMeetingRecord && assetObjectAssociation ? (
							<PanelTabPanel unmount={false}>
								<RecordAssociations
									sourceRecord={currentMeetingRecord}
									onUpdateCount={setLinkedAssetsCount}
									objectAssociation={assetObjectAssociation}
									controlsVariant="sm"
									openingVariant={associationOpeningVariant}
								/>
							</PanelTabPanel>
						) : null}
						{assetObjectAssociation ? (
							<PanelTabPanel unmount={false}>
								<RecordAssociations
									onUpdateCount={setAllAssetsCount}
									objectAssociation={assetObjectAssociation}
									controlsVariant="sm"
									openingVariant={associationOpeningVariant}
								/>
							</PanelTabPanel>
						) : null}
					</PanelTabPanels>
				</div>
			</PanelTabs>
		</div>
	);
}
