import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";
import { fieldIdSchema } from "../object";

export const importMappingEntryCreateRequestSchema = asConst({
	title: "ImportMappingEntryCreateRequestSchema",
	description: "The mapping details from a column in an import file to an object field",
	type: "object",
	additionalProperties: false,
	required: ["columnName", "fieldId"],
	properties: {
		columnName: stringSchema,
		fieldId: fieldIdSchema,
	},
});
