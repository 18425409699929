import { Filter, FilterData } from "../../../../../records";
import { Field } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";
import { generateNewFilter, getFieldOption } from "../utils";
import { SelectOptionSection } from "../../../../../inputs";
import { FilteringPopover } from "../../../../../records/components/Filtering/components/FilteringPopover";

interface WatchlistFiltersProps {
	fields: Field[];
	value: FilterData;
	onChange: (newFilters: FilterData) => void;
	disabled?: boolean;
}

export function WatchlistFilters({
	fields,
	value: filters,
	onChange: onChangeFilters,
	disabled = false,
}: WatchlistFiltersProps) {
	const filterTargetMap = useMemo(
		() => fields.reduce((obj, field) => ({ ...obj, ...{ [field._name]: field } }), {}),
		[fields]
	);

	const filterTargetOptions: SelectOptionSection[] = useMemo(() => {
		return [fields.flatMap((field) => getFieldOption(field))];
	}, [fields]);

	const defaultFilter: Filter = useMemo(() => generateNewFilter(), []);

	return (
		<FilteringPopover
			disabled={disabled}
			value={filters}
			onChange={onChangeFilters}
			filterTargetMap={filterTargetMap}
			filterTargetOptions={filterTargetOptions}
			defaultFilter={defaultFilter}
		/>
	);
}
