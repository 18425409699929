import { asConst } from "json-schema-to-ts";
import { allowedTypesSchema } from "./allowedTypesSchema";

export const queryClausePropertyValueSchema = asConst({
	title: "Property Value",
	type: "object",
	additionalProperties: allowedTypesSchema,
	minProperties: 1,
	maxProperties: 1,
});
