import { useEffect } from "react";
import userflow from "userflow.js";

import { useWebPrincipal } from "../../../auth";
import { OnboardingFlow } from "../utils";
import { useAppStateContext } from "../../users/hooks/useAppStateContext";
import { useOnboardingContext } from "./useOnboardingContext";

export function useWorkspaceOnboarding() {
	const { appStateRef } = useAppStateContext();

	const principal = useWebPrincipal();

	const { startFlow } = useOnboardingContext();

	useEffect(() => {
		const onboardingAppState = appStateRef.current?.onboarding ?? {};
		const { isFirstTimeInWorkspace = true } = onboardingAppState;

		const isWorkspaceOnboardingPassed = !isFirstTimeInWorkspace;

		if (principal.IsCustomerUser) {
			userflow.track("workspace_customer_user_step_by_step");
		}

		if (isWorkspaceOnboardingPassed || !principal.IsSalesDeskUser) return;

		startFlow(OnboardingFlow.START_WORKSPACE_ONBOARDING);
	}, [startFlow, principal, appStateRef]);
}
