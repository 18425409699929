import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../../components";

export const matchAllPrefixClauseSchema = asConst({
	title: "Match All Prefix",
	description: "Will match any record where any field value starts with the provided value",
	type: "object",
	additionalProperties: false,
	required: ["matchAllPrefix"],
	properties: {
		matchAllPrefix: stringSchema,
	},
	nullable: false,
});
