import { ReactElement, cloneElement } from "react";
import { Transition } from "@headlessui/react";
import { clsx } from "clsx";

import { Tooltip, useHasMounted } from "@salesdesk/daisy-ui";

import { tw } from "../../../utils/tailwind-helpers";
import { NavItemContent } from "./NavItemContent";

interface NavItemProps {
	navItemComponent: ReactElement;
	isSelected: boolean;
	expanded?: boolean;
	icon: string;
	text: string;
	hideTooltip?: boolean;
	hideSelectDrop?: boolean;
}

export function NavItem({
	isSelected,
	expanded,
	icon,
	text,
	navItemComponent,
	hideTooltip = false,
	hideSelectDrop,
}: NavItemProps) {
	const hasMounted = useHasMounted();

	const navOption = cloneElement(
		navItemComponent,
		{
			className: clsx(
				tw`text-c_text_primary text-label-nav focus-visible:ring-c_action_focus group relative z-10 flex w-full max-w-full items-center truncate rounded-full py-2 focus-visible:ring`,
				isSelected ? tw`bg-c_bg_01` : tw`bg-c_brand_off_white hover:bg-c_bg_05`,
				expanded ? tw`px-4` : tw`px-2`,
				hasMounted && tw`transition-all`
			),
			"aria-current": isSelected ? "page" : undefined,
		},
		<NavItemContent isSelected={isSelected} icon={icon} expanded={expanded} text={text} />
	);

	return (
		<div className="relative max-w-full select-none">
			<Tooltip text={!hideTooltip && !expanded ? text : undefined} placement="right">
				{navOption}
			</Tooltip>
			{hideSelectDrop ? null : (
				<div className="pointer-events-none absolute -left-2 -top-full">
					<Transition
						show={isSelected && !expanded}
						className="transition-all"
						enter={tw`delay-[55ms]`}
						enterFrom={tw`scale-y-0 opacity-0`}
						enterTo={tw`scale-y-100 opacity-100`}
						leave={tw`duration-0`}
						leaveFrom={tw`opacity-100`}
						leaveTo={tw`opacity-0`}
					>
						<SelectDrop />
					</Transition>
				</div>
			)}
		</div>
	);
}

function SelectDrop() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="68" height="108" viewBox="0 0 68 108" fill="none">
			<path
				d="M68 0C68 17.6731 53.6731 32 36 32H22C9.84973 32 0 41.8497 0 54C0 66.1503 9.84973 76 22 76H36C53.6731 76 68 90.3269 68 108V0Z"
				fill="white"
			/>
		</svg>
	);
}
