import { Icon } from "@salesdesk/daisy-ui";
import { ImportPreviewRecordType } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";

interface ImportRecordTypeIconProps {
	type: ImportPreviewRecordType;
}

export function ImportRecordTypeIcon({ type }: ImportRecordTypeIconProps) {
	const baseClass = "flex size-6 shrink-0 items-center justify-center rounded-full";
	if (type === ImportPreviewRecordType.Create) {
		return <Icon icon={ICONS.plus} className={clsx("bg-c_success_04 text-c_success_03", baseClass)} size="sm" />;
	}
	if (type === ImportPreviewRecordType.Patch) {
		return (
			<Icon
				icon={ICONS.arrowCounterClockwise}
				className={clsx("bg-c_warning_04 text-c_warning_03", baseClass)}
				size="sm"
			/>
		);
	}
}
