import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";

import { SDObject, Field, getSDObjectNameFieldIds } from "@salesdesk/salesdesk-schemas";
import { mFieldDef } from "@salesdesk/salesdesk-model";

import { FieldComponentFactory } from "../../../forms";
import { convertFieldToFieldDef, UploadProgressStatus } from "../../../fields";
import { getFullRecordFormFieldId, getFormValuesByPrefix, RECORD_FIELDS_KEY } from "../../../records";
import { ProfilePhotoInfo } from "../../../fields/components/Fields/ProfilePhotoField/types";

interface ProfileAvatarFieldProps {
	field: Field;
	userSDObject: SDObject;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
}

export function ProfileAvatarField({ field, userSDObject, updateUploadProgressStatus }: ProfileAvatarFieldProps) {
	const { control, watch } = useFormContext();
	const fieldValues = watch();

	const { fieldDefId, fieldDef } = useMemo(
		() => ({
			fieldDefId: String(field._id),
			fieldDef: convertFieldToFieldDef(field) as mFieldDef,
		}),
		[field]
	);

	const fullName = useMemo(() => {
		const currentFormData = getFormValuesByPrefix(fieldValues, RECORD_FIELDS_KEY);
		return getSDObjectNameFieldIds(userSDObject)
			.map((recordNameFieldId) => String(currentFormData[recordNameFieldId]))
			.join(" ");
	}, [fieldValues, userSDObject]);

	const fieldName = getFullRecordFormFieldId(RECORD_FIELDS_KEY, String(field._id));

	return (
		<div key={fieldName} className="mb-5">
			<Controller
				name={fieldName}
				control={control}
				render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
					<FieldComponentFactory
						fieldDef={fieldDef}
						componentProps={{
							id: fieldDefId,
							onBlur,
							onChange,
							inputRef: ref,
							value: { fileId: value, fullName } satisfies ProfilePhotoInfo,
							hasError: Boolean(error?.message),
							disabled: !field._editable,
						}}
						errorMessage={error?.message}
						updateUploadProgressStatus={updateUploadProgressStatus}
					/>
				)}
				rules={{ validate: (value) => fieldDef.validate(value, undefined) }}
			/>
		</div>
	);
}
