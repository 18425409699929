import { forwardRef, useEffect, useState } from "react";

import { mUserDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useGetRecordOptionsInWorkspace } from "../../../fields";
import { BaseSelect, BaseSelectButtonProps, SelectOption } from "../../../inputs";
import { useMediaAnalysisContext } from "../../hooks/useMediaAnalisysContext";
import { Button } from "@salesdesk/daisy-ui";
import { UserIdOrPlaceholderName } from "../../types";

interface UsersSelectProps {
	userIdsOrPlaceholderNames: UserIdOrPlaceholderName[];
	value: UserIdOrPlaceholderName[];
	onChange: (userIds: UserIdOrPlaceholderName[]) => void;
}

function placeholderNameToSelectOption(placeholderName: string): SelectOption {
	return {
		id: placeholderName,
		name: placeholderName,
	};
}

export function UsersSelect({ userIdsOrPlaceholderNames, value, onChange }: UsersSelectProps) {
	const { setAnyPopoverSelectOpen } = useMediaAnalysisContext();
	const { getOptionsFromIds } = useGetRecordOptionsInWorkspace({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
	});

	const [userOptions, setUserOptions] = useState<SelectOption[]>([]);

	useEffect(() => {
		if (!userIdsOrPlaceholderNames.length) {
			return;
		}

		const userIds: number[] = [];
		const placeholderNames: string[] = [];
		for (const userOrPlaceholderName of userIdsOrPlaceholderNames) {
			if (typeof userOrPlaceholderName === "number") {
				userIds.push(userOrPlaceholderName);
			} else {
				placeholderNames.push(userOrPlaceholderName);
			}
		}

		if (!userIds.length) {
			setUserOptions(placeholderNames.map(placeholderNameToSelectOption));
			return;
		}

		getOptionsFromIds(userIds).then((userOptions) => {
			const mixedUserOptions: SelectOption[] = [];
			for (const userIdOrPlaceholderName of userIdsOrPlaceholderNames) {
				if (typeof userIdOrPlaceholderName === "number") {
					const userOption = userOptions.find((option) => option.id === userIdOrPlaceholderName);
					if (userOption) {
						mixedUserOptions.push(userOption);
					}
				} else {
					mixedUserOptions.push(placeholderNameToSelectOption(userIdOrPlaceholderName));
				}
			}
			setUserOptions(mixedUserOptions);
		});
	}, [getOptionsFromIds, userIdsOrPlaceholderNames]);

	return (
		<BaseSelect
			isMultiselect
			options={userOptions}
			optionsTitle="Users"
			onChange={onChange}
			value={value}
			selectButton={<FilterButton />}
			optionDisplayVariant="user_pill"
			onOpenChange={(isOpen) => {
				setAnyPopoverSelectOpen(isOpen);
			}}
			disabled={!userOptions.length}
		/>
	);
}

const FilterButton = forwardRef<HTMLButtonElement, BaseSelectButtonProps>(({ selectedOption, ...restProps }, ref) => {
	return <Button {...restProps} ref={ref} size="xs" variant="text" startIcon={ICONS.filter} />;
});
