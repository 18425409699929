import { useEventContext } from "../../../hooks/useEventContext";
import { formatVideoTimestamp } from "../../../../files/components/VideoPlayer/utils";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { SdEventType, VideoEventParams } from "@salesdesk/salesdesk-model";
import { FileFieldPreview } from "./FileFieldPreview";

export function VideoEventPayload() {
	const { event } = useEventContext();
	const videoParams = event.params as VideoEventParams | undefined;
	const fileId = videoParams?.fileId;
	const timecode = videoParams?.timecode;
	if (timecode === undefined) {
		return null;
	}
	let icon;
	if (event.event_type === SdEventType.FILE_SEEKED) {
		icon = ICONS.skipForward;
	} else if (event.event_type === SdEventType.FILE_PLAYED) {
		icon = ICONS.play;
	} else {
		icon = ICONS.pause;
	}
	return (
		<div className="flex flex-col gap-3">
			{fileId && event.object_def_id ? <FileFieldPreview fileId={fileId} objectId={event.object_def_id} /> : null}
			<div className="text-label-sm text-c_text_secondary flex items-center gap-1">
				<Icon icon={icon} className="flex items-center" size="sm" />
				{formatVideoTimestamp(timecode)}
			</div>
		</div>
	);
}
