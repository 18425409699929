import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { RecordForm, StickyFormControls } from "../../../records";
import { useUpdateUserProfile } from "../hooks";
import { ProfileAvatarField } from "./ProfileAvatarField";
import { Divider } from "../../../../components/Divider/Divider";
import { ChangePasswordContainer } from "./ChangePasswordContainer";
import { DevSettingsContainer } from "./DevSettingsContainer";

interface UserProfileFormProps {
	userSDObject: SDObject;
	userSDRecord: SDRecord;
}

export function UserProfileForm({ userSDObject, userSDRecord }: UserProfileFormProps) {
	const { formProps, isLoading, avatarField, uploadProgressStatus } = useUpdateUserProfile(userSDObject, userSDRecord);
	const { id, fields, defaultValues, updateUploadProgressStatus } = formProps;

	if (!fields.length || !avatarField) return null;

	return (
		<RecordForm
			{...formProps}
			columnWidth={400}
			beforeFieldsChildren={
				<>
					<ProfileAvatarField
						userSDObject={userSDObject}
						field={avatarField}
						updateUploadProgressStatus={updateUploadProgressStatus}
					/>
					<Divider className="my-0" />
				</>
			}
		>
			<>
				<Divider className="my-0" />
				<ChangePasswordContainer />
				{import.meta.env.MODE === "development" ? <DevSettingsContainer /> : null}
				<StickyFormControls
					formId={id}
					isLoading={isLoading}
					defaultValues={defaultValues}
					submitDisabled={uploadProgressStatus === "in_progress"}
				/>
			</>
		</RecordForm>
	);
}
