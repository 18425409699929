import clsx from "clsx";

import { mRatingFieldDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

export function PreviewRatingField({ error = false }: { error?: boolean }) {
	const stars = getStars(error);

	return <div className="flex items-center gap-2 py-2">{stars}</div>;
}

function getStars(error?: boolean) {
	const stars = [];
	for (let key = 0; key < mRatingFieldDef.MAX_RATING; key++) {
		stars.push(
			<Icon
				key={key}
				icon={ICONS.star}
				variant="fill"
				size="lg"
				className={clsx(error ? "text-c_danger_02" : "text-c_icon_regular", "flex")}
			/>
		);
	}

	return stars;
}
