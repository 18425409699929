import { mAssetDef } from "../asset_def";
import { ASSET_IDS } from "../asset_constants";

export class mImageAssetDef extends mAssetDef {
	static CLASS_NAME = "mImageAssetDef";
	static NAME = "Image Asset";
	static PLURAL_NAME = "Image Assets";
	static ICON = "ph-image";

	static ID = ASSET_IDS.IMAGE;

	static FORMAT_DESCRIPTION = "Image file required";

	constructor(ownerId) {
		super(mImageAssetDef.ID, ownerId);
	}
}
