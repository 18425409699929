import { useGetUserByUserId } from "../../hooks";
import { UserPill } from "./UserPill";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";

interface UserPillControllerProps {
	userId: number;
}

export function UserPillController({ userId }: UserPillControllerProps) {
	const { user, isSystemUser } = useGetUserByUserId(userId);

	if (!user) {
		return <Skeleton className="h-7 w-28" />;
	}

	return <UserPill user={user} isSystemUser={isSystemUser} />;
}
