import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../../functions";
import { CURRENCIES_DATA } from "@salesdesk/salesdesk-model";

export const fieldCurrencySchema = asConst({
	type: "string",
	enum: CURRENCIES_DATA.map((currency) => currency.id),
	title: "SDObject Field Currency",
	description: appendNotEditable("The currency of the currency value Field"),
});
