import { PropsWithChildren, useCallback, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { useChatMessages } from "../../../../../../chat";
import { useVideoCallMediaContext } from "../../VideoCallMediaProvider";
import { useHandleDisconnection } from "../../VideoCallMediaProvider/hooks/useHandleDisconnection";

import { useConnectToRoom } from "../hooks/useConnectToRoom";
import { VideoCallLogicContext } from "../hooks/useVideoCallLogicContext";
import { useCallParticipantDetails } from "../hooks/useCallParticipantDetails";
import { useRemoteParticipants } from "../hooks/useRemoteParticipants";
import { useControlTrack } from "../hooks";
import { useChatControlTrack } from "../hooks/useChatControlTrack";
import { useVideoPanel } from "../hooks/useVideoPanel";
import { useScreenShareToggle } from "../hooks/useScreenShareToggle";
import { useFileShare } from "../hooks/useFileShare";
import { useDominantSpeaker } from "../hooks/useDominantSpeaker";
import { useScreenSharingParticipants } from "../hooks/useScreenSharingParticipants";
import { useFileSharingParticipant } from "../hooks/useFileSharingParticipant";
import { useGetObjectById } from "../../../../../../../hooks/useGetObjectById";

export function VideoCallLogicProvider({ children }: PropsWithChildren) {
	const { localTracks, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoCallMediaContext();
	const [currentMeetingRecord, setCurrentMeetingRecord] = useState<SDRecord | null>(null);
	const [connectToMeetingTimeStamp, setConnectToMeetingTimeStamp] = useState(0);

	const { sdObject: meetingObject } = useGetObjectById(currentMeetingRecord?._objectDefId);

	const meetingId = currentMeetingRecord?._id || undefined;
	const { room, isConnecting } = useConnectToRoom(localTracks, meetingId);
	const { panelOpen, setPanelOpen, currentPanelTab, updatePanelTab } = useVideoPanel();

	const connectToMeeting = useCallback((meetingRecord: SDRecord) => {
		setCurrentMeetingRecord(meetingRecord);
	}, []);

	const disconnect = useCallback(() => {
		setCurrentMeetingRecord(null);
		setPanelOpen(false);
	}, [setPanelOpen]);

	// Register callback functions to be called when no longer using video and audio tracks
	useHandleDisconnection(removeLocalAudioTrack, removeLocalVideoTrack, Boolean(meetingId != null));

	const { isSharingScreen, toggleScreenShare } = useScreenShareToggle(room);
	const {
		sharedFileDetails: localSharedFileDetails,
		shareFile,
		stopSharingFile,
		updateFileSettings,
	} = useFileShare(room);

	const { sendControlMessage, subscribeToControlMessages } = useControlTrack(room);
	const { sendChatRefreshRequest, subscribeToChatRefreshRequests } = useChatControlTrack(
		sendControlMessage,
		subscribeToControlMessages
	);
	const {
		sortedChatMessages,
		postChatMessage,
		isLoading: isChatLoading,
	} = useChatMessages(meetingId, sendChatRefreshRequest, subscribeToChatRefreshRequests);

	const [participantVolumeMap, setParticipantVolumeMap] = useState<Record<string, number | undefined>>({});
	const updateParticipantVolume = useCallback((identity: string, volume?: number) => {
		setParticipantVolumeMap((prev) => ({ ...prev, [identity]: volume }));
	}, []);

	const { currentDominantSpeakerIdentity, dominantSpeakerQueue } = useDominantSpeaker(participantVolumeMap);
	const remoteParticipants = useRemoteParticipants(room, updateParticipantVolume);

	const callParticipantDetails = useCallParticipantDetails(room, remoteParticipants);
	const screenSharingParticipantDetails = useScreenSharingParticipants(room, callParticipantDetails);
	const fileSharingParticipant = useFileSharingParticipant(room, callParticipantDetails, localSharedFileDetails);

	// Stop sharing file if someone else on the call is
	if (fileSharingParticipant?.participant !== room?.localParticipant && stopSharingFile) {
		stopSharingFile();
	}

	return (
		<VideoCallLogicContext.Provider
			value={{
				currentMeetingRecord,
				meetingObject,
				connectToMeeting,
				disconnect,

				room,
				remoteParticipants,
				isConnecting,

				panelOpen,
				setPanelOpen,
				currentPanelTab,
				updatePanelTab,

				participantVolumeMap,
				updateParticipantVolume,
				currentDominantSpeakerIdentity,
				dominantSpeakerQueue,

				isSharingScreen,
				toggleScreenShare,

				shareFile,
				stopSharingFile,
				updateFileSettings,

				callParticipantDetails,
				screenSharingParticipantDetails,
				fileSharingParticipant,

				sortedChatMessages,
				postChatMessage,
				isChatLoading,

				sendControlMessage,
				subscribeToControlMessages,

				connectToMeetingTimeStamp,
				setConnectToMeetingTimeStamp,
			}}
		>
			{children}
		</VideoCallLogicContext.Provider>
	);
}
