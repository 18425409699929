import { createSlice } from "@reduxjs/toolkit";
import { VideoCallEvent } from "../types";

export const videoCallEventsSlice = createSlice({
	name: "videoCallEvents",
	initialState: {
		events: [] as VideoCallEvent[],
	},
	reducers: {
		triggerEvent: (state, { payload }: { payload: VideoCallEvent }) => {
			state.events.push({
				...payload,
				createdAt: Date.now(),
				dismissed: false,
			});
		},
		dismissEventById: (state, { payload: eventId }: { payload: number }) => {
			const event = state.events.find((event) => event.id === eventId);
			if (!event) {
				return;
			}
			if (event.timeoutId) {
				clearTimeout(event.timeoutId);
				event.timeoutId = undefined;
			}
			event.dismissed = true;
		},
		markEventAsPlayed: (state, { payload: eventId }: { payload: number }) => {
			state.events = state.events.map((event) => (event.id === eventId ? { ...event, played: true } : event));
		},
		deleteEventById: (state, { payload: eventId }: { payload: number }) => {
			const index = state.events.findIndex((event) => event.id === eventId);
			if (index !== -1) {
				state.events.splice(index, 1);
			}
		},
		setDismissTimer: (state, { payload }: { payload: { eventId: number; timeoutId: NodeJS.Timeout } }) => {
			const event = state.events.find((event) => event.id === payload.eventId);
			if (event) {
				event.timeoutId = payload.timeoutId;
				event.dismissed = false;
			}
		},
		cancelDismissTimer: (state, { payload: eventId }: { payload: number }) => {
			const index = state.events.findIndex((event) => event.id === eventId);
			if (index !== -1) {
				clearTimeout(state.events[index].timeoutId);
				state.events[index].timeoutId = undefined;
			}
		},
	},
});

export const {
	triggerEvent,
	dismissEventById,
	deleteEventById,
	setDismissTimer,
	cancelDismissTimer,
	markEventAsPlayed,
} = videoCallEventsSlice.actions;
export const videoCallEventsReducer = videoCallEventsSlice.reducer;
