import { asConst } from "json-schema-to-ts";
import {
	recordCreationTypeSchema,
	recordDeletedSchema,
	recordIdSchema,
	recordObjectVersionSchema,
	recordOwnerSchema,
	recordVersionSchema,
} from "./properties";
import { updatableSchema } from "../components";
import { sdRecordCreateRequestSchema } from "./sdRecordCreateRequestSchema";

export const sdRecordSchema = asConst({
	title: "SDRecord",
	description: "An SDRecord schema",
	type: "object",
	additionalProperties: false,
	required: [
		...sdRecordCreateRequestSchema.required,
		"_id",
		"_type",
		"_version",
		"_definitionVersion",
		"_deleted",
		"sharedWorkspaceIds",

		"_ownerId",
		...updatableSchema.required,
	],
	properties: {
		...sdRecordCreateRequestSchema.properties,
		// TODO: Remove all _
		_id: recordIdSchema,
		_type: recordCreationTypeSchema, //TODO: Rename creationType
		_version: recordVersionSchema,
		_definitionVersion: recordObjectVersionSchema, // TODO: rename objectVersion
		_deleted: recordDeletedSchema,
		sharedWorkspaceIds: { type: "array", items: recordIdSchema },
		_ownerId: recordOwnerSchema,

		...updatableSchema.properties,
	},
});
