import { ReactElement, useCallback, useMemo } from "react";
import { Combobox, Listbox } from "@headlessui/react";

import { BaseSelectPopoverProps } from "../../types";
import { SelectOptionItem } from "./SelectOptionItem";
import { SelectOption, SelectOptionSection, SelectOptions } from "../../../../types";
import { Divider } from "../../../../../../components/Divider/Divider";
import { PopoverContainer } from "../../../../../../components/Popover";
import { tw } from "../../../../../../utils/tailwind-helpers";
import { Spinner } from "@salesdesk/daisy-ui";

export function SelectPopover({
	optionsTitle,
	options,
	isTypeahead,
	isLoading,
	optionDisplayVariant,
	isMultiselect,
}: BaseSelectPopoverProps) {
	const generateOptionItemFn = useCallback(
		(option: SelectOption) => {
			return ({ selected, active }: { selected: boolean; active: boolean }) => (
				<SelectOptionItem
					option={option}
					disabled={option.disabled}
					active={active}
					selected={selected}
					variant={optionDisplayVariant}
					withinPopover={true}
					isMultiselect={isMultiselect}
				/>
			);
		},
		[optionDisplayVariant, isMultiselect]
	);

	const getOptionElement = useCallback(
		(option: SelectOption) => {
			const optionProps = { value: option.id, disabled: option.disabled };
			const optionItemFn = generateOptionItemFn(option);
			if (isTypeahead) {
				return (
					<Combobox.Option key={option.id} {...optionProps}>
						{optionItemFn}
					</Combobox.Option>
				);
			}
			return (
				<Listbox.Option key={option.id} value={option.id} disabled={option.disabled}>
					{optionItemFn}
				</Listbox.Option>
			);
		},
		[generateOptionItemFn, isTypeahead]
	);

	const popoverOptions = useMemo(() => {
		if (!options?.length && isTypeahead && !isLoading) {
			return <div className="text-body-sm text-c_text_placeholder select-none px-6 py-3">No results</div>;
		}
		const elements: ReactElement[] = [];

		const optionSections = convertToSelectOptionSections(options);

		optionSections.forEach((section, sectionIndex) => {
			const { sectionTitle, options } =
				"sectionTitle" in section ? section : { sectionTitle: undefined, options: section };

			if (sectionTitle) {
				elements.push(
					<div
						key={`title-${sectionIndex}`}
						className="text-body-sm text-c_text_secondary w-full select-none px-6 py-2"
					>
						{sectionTitle}
					</div>
				);
			}

			for (const option of options) {
				elements.push(getOptionElement(option));
			}

			if (sectionIndex < optionSections.length - 1) {
				elements.push(<Divider key={`divider-${sectionIndex}`} />);
			}
		});

		return elements;
	}, [options, isTypeahead, isLoading, getOptionElement]);

	return (
		<PopoverContainer heightClass={tw`max-h-[300px]`}>
			{isLoading ? (
				<div className="flex w-full justify-center px-6 py-11">
					<Spinner />
				</div>
			) : (
				<ul>
					{optionsTitle ? (
						<div className="text-body-sm text-c_text_secondary w-full select-none px-6 py-2">{optionsTitle}</div>
					) : null}
					{popoverOptions}
				</ul>
			)}
		</PopoverContainer>
	);
}

function convertToSelectOptionSections(selectOptions?: SelectOptions): SelectOptionSection[] {
	if (!selectOptions || !selectOptions.length) {
		return [];
	}

	const firstEl = selectOptions[0];

	if (Array.isArray(firstEl) || "sectionTitle" in firstEl) {
		return selectOptions as SelectOptionSection[];
	}

	// Wraps SelectOption[] in an array to form a SelectOptionSection
	return [selectOptions] as SelectOptionSection[];
}
