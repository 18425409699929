import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mFileField } from "../file_field_inst";

export class mVideoField extends mFileField {

	hasFaceSentiment(){
		return !isEmpty(this._value?.sentimentMap) && !isEmpty(Object.values(this._value?.sentimentMap).filter(value => value.faceSentiment));
	}

	get faceSentimentFile(){	
		return Object.values(this._value["sentimentMap"])[0]["faceSentiment"];
	}

	set faceSentimentFile(file){
		this._value["faceSentiment"] = {
			name: file.name,
			location: file.location
		}
	}

	hasTranscriptSentiment(){
		return !isEmpty(this._value?.sentimentMap) && !isEmpty(Object.values(this._value?.sentimentMap).filter(value => value.transcriptSentiment));
	}

	get transcriptSentimentFile(){
		return Object.values(this._value["sentimentMap"])[0]["transcriptSentiment"];
	}

	set transcriptSentimentFile(file){
		this._value["transcriptSentiment"] = {
			name: file.name,
			location: file.location
		}
	}

	hasTranscript(){
		return !isEmpty(this._value?.transcript);
	}

	get transcriptFile(){
		return this._value["transcript"];
	}

	set transcriptFile(file){

		this._value["transcript"] = {
			name: file.name,
			location: file.location
		}
	}

	get location(){
		return super.location;
	}
	
	set location(location){
		
		super.location = location;
		
		if(!isEmpty(location)){
			//Work out the location of the poster image
			this._value["poster"] = location.replace(/\.[^.]*$/, ".0000001.jpg");
		}
	}
}