import clsx from "clsx";

import { tw } from "../../utils/tailwind-helpers";

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
	variant?: "primary" | "secondary" | "tertiary" | "header";
	rounded?: boolean;
}

const BgClass = {
	primary: tw`bg-c_bg_disabled_02`,
	secondary: tw`bg-c_bg_02`,
	tertiary: tw`bg-c_bg_05`,
	header: tw`bg-c_bg_04`,
};

export function Skeleton({ className, variant = "primary", rounded = true, ...props }: SkeletonProps) {
	return <div {...props} className={clsx(BgClass[variant], "animate-pulse", rounded && "rounded-full", className)} />;
}
