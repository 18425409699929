import { useEffect } from "react";

import { useGetObjectFromSlug } from "../../../../../../../hooks";
import { useGetRecordQuery } from "../../../../../api/recordsApi";
import { useRecordLoadedEvent } from "../../../../../hooks";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { useNavigateNotFound } from "../../../../../../../routes";

export function useLoadRecordDetailViewRecord(recordId: number, objectSlug: string) {
	const navigateNotFound = useNavigateNotFound();

	const workspaceId = useGetContextWorkspaceId();

	const { sdObject, isLoading: isLoadingObject } = useGetObjectFromSlug(objectSlug);
	const { currentData: sdRecord, isLoading: isLoadingRecord, isError: recordFailed } = useGetRecordQuery(recordId);

	useRecordLoadedEvent(recordId);

	const invalidObjectType =
		sdObject &&
		sdRecord &&
		!(sdObject._id === sdRecord._objectDefId || sdObject._subtypes.includes(sdRecord._objectDefId));
	const isLoading = isLoadingObject || isLoadingRecord || invalidObjectType;

	useEffect(() => {
		const failedToLoad = (!isLoadingObject && !sdObject) || (!isLoadingRecord && !sdRecord && recordFailed);

		if (
			failedToLoad ||
			invalidObjectType ||
			(workspaceId !== undefined && sdRecord?.sharedWorkspaceIds?.includes(workspaceId) === false)
		) {
			navigateNotFound();
		}
	}, [
		sdObject,
		sdRecord,
		isLoadingObject,
		isLoadingRecord,
		recordFailed,
		invalidObjectType,
		navigateNotFound,
		workspaceId,
	]);

	return { sdObject, sdRecord, isLoading };
}
