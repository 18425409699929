import { createContext, useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { RoleFormData } from "../types";

interface IRoleFormContext extends UseFormReturn<RoleFormData> {
	readOnly: boolean;
}

export const RoleFormContext = createContext<IRoleFormContext | null>(null);

export const useRoleFormContext = () => {
	const context = useContext(RoleFormContext);

	if (context == null) {
		throw new Error("Role components must be wrapped in <RoleForm />");
	}

	return context;
};
