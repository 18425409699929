import { memo, RefObject } from "react";
import clsx from "clsx";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { RecordContextMenu, useGetRecordFollowingStatus, CustomRecordMenuItemsFunc } from "../records";
import { useShowFloatingMenu } from "./useShowFloatingMenu";

interface RecordRowControlsProps {
	sdRecord: SDRecord;
	tableRef: RefObject<HTMLDivElement>;
	scrollContainerRef?: RefObject<HTMLDivElement>;
	getCustomContextMenuItems?: CustomRecordMenuItemsFunc;
}

export const RECORD_ROW_CONTROLS_WIDTH = 128;

export function RecordRowControlsComponent({
	sdRecord,
	tableRef,
	scrollContainerRef,
	getCustomContextMenuItems,
}: RecordRowControlsProps) {
	const { isWatched, isFavorited } = useGetRecordFollowingStatus(sdRecord);

	const { showFloatingMenu, floatingMenuRef, rowEndRef } = useShowFloatingMenu(tableRef, scrollContainerRef);

	return (
		<div className="ml-auto flex items-center justify-end gap-1 pl-4" style={{ width: RECORD_ROW_CONTROLS_WIDTH }}>
			{isWatched ? <Icon icon={ICONS.flag} variant="fill" className="text-c_action_01 flex p-2" /> : null}
			{isFavorited ? <Icon icon={ICONS.star} variant="fill" className="text-c_action_01 flex p-2" /> : null}
			<div ref={rowEndRef} className={clsx(showFloatingMenu ? "size-8" : "-ml-1")} />
			<div
				ref={floatingMenuRef}
				key={`row-menu-${showFloatingMenu ? "floating" : "default"}`}
				className={clsx(
					showFloatingMenu &&
						`shadow-tooltip bg-c_bg_01 border-c_border_regular absolute rounded-full border opacity-0 transition-opacity group-focus-within/table-row:opacity-95 group-hover/table-row:opacity-95 has-[[data-state="open"]]:opacity-95`
				)}
			>
				<RecordContextMenu sdRecord={sdRecord} getCustomContextMenuItems={getCustomContextMenuItems} />
			</div>
		</div>
	);
}

export const RecordRowControls = memo(RecordRowControlsComponent);
