import { asConst } from "json-schema-to-ts";
import {
	equalsAnyOfClauseSchema,
	equalsClauseSchema,
	existsClauseSchema,
	includeAnyOfClauseSchema,
	includeClauseSchema,
	matchAllClauseSchema,
	matchAllPrefixClauseSchema,
	matchClauseSchema,
	matchPrefixClauseSchema,
	notClausesSchema,
	rangeClauseSchema,
} from "../../../search";
import {
	baseObjectIdClauseSchema,
	existsAssociationForObjectAssociationClauseSchema,
	hasAssociationsToAllRecordsClauseSchema,
	hasAssociationsToAllRecordsForObjectAssociationClauseSchema,
	hasAssociationToAnyRecordClauseSchema,
	hasAssociationToAnyRecordForObjectAssociationClauseSchema,
	hasAssociationToRecordClauseSchema,
	hasAssociationToRecordForObjectAssociationClauseSchema,
	isSharedWithAnyWorkspaceClauseSchema,
	isSharedWithWorkspaceClauseSchema,
	recordQueryClauseFieldOnlyValuesSchema,
	recordQueryClausePropertyValueSchema,
	recordQueryClausePropertyValuesSchema,
	recordRangeClausePropertyValueSchema,
} from "./";
import { recordQueryClausePropertyNameStringSchema } from "./recordQueryClausePropertyNameStringSchema";

export const recordNotClausesSchema = asConst({
	...notClausesSchema,
	anyOf: [
		matchAllPrefixClauseSchema,
		matchAllClauseSchema,

		// Updated to restrict property names
		{
			...matchPrefixClauseSchema,
			properties: { [matchPrefixClauseSchema.required[0]]: recordQueryClausePropertyValueSchema },
		},
		{ ...matchClauseSchema, properties: { [matchClauseSchema.required[0]]: recordQueryClausePropertyValueSchema } },
		{
			...existsClauseSchema,
			properties: { [existsClauseSchema.required[0]]: recordQueryClausePropertyNameStringSchema },
		},
		{
			...equalsClauseSchema,
			properties: {
				[equalsClauseSchema.required[0]]: {
					...recordQueryClausePropertyValueSchema,
				},
			},
		},
		{
			...equalsAnyOfClauseSchema,
			properties: { [equalsAnyOfClauseSchema.required[0]]: recordQueryClausePropertyValuesSchema },
		},
		{ ...rangeClauseSchema, properties: { [rangeClauseSchema.required[0]]: recordRangeClausePropertyValueSchema } },
		{ ...includeClauseSchema, properties: { [includeClauseSchema.required[0]]: recordQueryClausePropertyValueSchema } },
		{
			...includeAnyOfClauseSchema,
			properties: { [includeAnyOfClauseSchema.required[0]]: recordQueryClauseFieldOnlyValuesSchema },
		},

		// Record Specific
		hasAssociationToRecordClauseSchema,
		hasAssociationToAnyRecordClauseSchema,
		hasAssociationsToAllRecordsClauseSchema,
		hasAssociationToRecordForObjectAssociationClauseSchema,
		hasAssociationToAnyRecordForObjectAssociationClauseSchema,
		hasAssociationsToAllRecordsForObjectAssociationClauseSchema,
		existsAssociationForObjectAssociationClauseSchema,
		isSharedWithWorkspaceClauseSchema,
		isSharedWithAnyWorkspaceClauseSchema,
		baseObjectIdClauseSchema,
	],
});
