import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { omit } from "lodash";
import clsx from "clsx";

import { tw } from "@salesdesk/daisy-ui";

import { useAccessibleHTMLElementLink } from "../../../../../hooks/ui";
import { genericMemo } from "../../../../../utils";
import { RECORD_SIDE_PANEL_TRIGGER_CLASS } from "../../../../records";
import { DraggableTableRowContentsProps } from "../types";
import { ROW_IS_LOADING_PLACEHOLDER, ROW_LINK_COLUMN_ID } from "../../../types";
import { TableCell } from "./TableCell";

function DraggableTableRowContentsComponent<T>({
	visibleCells,
	originalData,
	isSelected,
	toggleSelected,
	rowScreenReaderName = "row",
	linkElementId,
	rowLinkCell,
	controlColumn,
	isDraggingRow,
	draggableProvided,
	inSelectionMode,
	isDraggable,
	isDroppable,
	isDragPlaceholder,
}: DraggableTableRowContentsProps<T>) {
	const rowLinkValue = originalData[ROW_LINK_COLUMN_ID];
	const isLoadingPlaceholder = originalData[ROW_IS_LOADING_PLACEHOLDER];

	const generateRecordLink = useCallback(() => {
		return typeof rowLinkValue === "function" ? rowLinkValue() : rowLinkValue;
	}, [rowLinkValue]);

	const rowLink = generateRecordLink();

	const rowLinkElement = useMemo(() => {
		if (!rowLink) {
			return null;
		}

		// Link is used for accessibility as it allows screen readers to see & use the row link
		// as well as being used to process click events with modifier keys performed on the row itself
		return (
			<Link
				id={linkElementId}
				to={rowLink}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{`Go to ${rowScreenReaderName}}`}
			</Link>
		);
	}, [linkElementId, rowLink, rowScreenReaderName]);

	const handleRowClickEvent = useAccessibleHTMLElementLink({ linkElementId, getLink: generateRecordLink });

	const trDraggingRowDependentClasses = isDraggingRow
		? clsx(tw`bg-c_bg_03/90 border`, isDroppable ? tw`border-c_action_01` : tw`border-c_border_regular border-0`)
		: clsx(
				tw`border-c_border_regular border-b`,
				isSelected ? tw`bg-c_bg_03` : tw`bg-c_bg_01 focus-visible:bg-c_bg_03 focus-visible-within:bg-c_bg_03`,
				!isLoadingPlaceholder && !isDroppable && tw`hover:bg-c_bg_03`
			);

	return (
		<tr
			className={clsx(
				trDraggingRowDependentClasses,
				isDragPlaceholder && "!bg-c_bg_disabled_02",
				(rowLink || inSelectionMode) && !isLoadingPlaceholder && "cursor-pointer",
				"group/table-row relative",
				RECORD_SIDE_PANEL_TRIGGER_CLASS,
				"group"
			)}
			onClick={inSelectionMode ? () => toggleSelected() : handleRowClickEvent}
			onAuxClick={handleRowClickEvent}
			ref={draggableProvided?.innerRef}
			{...omit(draggableProvided?.draggableProps, "style")}
			style={{ ...omit(draggableProvided?.draggableProps.style, !isDraggingRow ? "transform" : "") }}
		>
			{rowLinkCell}
			{visibleCells.map((cell, index) => {
				const isLastColumn = index === visibleCells.length - 1;
				const isRowLinkCell = cell.column.id === ROW_LINK_COLUMN_ID;

				return (
					<TableCell
						key={cell.column.id}
						cell={cell}
						rowLinkElement={isRowLinkCell ? rowLinkElement : undefined}
						rowIsSelected={isSelected}
						inSelectionMode={inSelectionMode}
						isLoadingPlaceholder={isLoadingPlaceholder}
						controlColumnToRender={isLastColumn ? controlColumn : undefined}
						showDragHandle={isDraggable && index === 1}
						isDroppable={isDroppable}
						isDragPlaceholder={isDragPlaceholder}
						isDraggingRow={isDraggingRow}
						dragHandleProps={draggableProvided?.dragHandleProps}
					/>
				);
			})}
		</tr>
	);
}

export const DraggableTableRowContents = genericMemo(DraggableTableRowContentsComponent);
