import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { mAssetDef, mDocDef, mTaskDef, ObjectAssociationEndpointType } from "@salesdesk/salesdesk-model";
import { AssociationEndpoint, SDObjectAssociations } from "@salesdesk/salesdesk-schemas";

import { useGetObjectAssociationsQuery } from "../api/recordAssociationsApi";

type SDObjectAssociation = SDObjectAssociations[number];

/**
 * Extends SDObjectAssociation and adds:
 * - sourceObject: the object from which the association was fetched for
 * - connectedObject - the object in the definition with the association to 'sourceObject'
 */
export interface DirectedSDObjectAssociation extends SDObjectAssociation {
	connectedObject: AssociationEndpoint;
	sourceObject: AssociationEndpoint;
}

const DEFAULT_TABBED_ASSOCIATION_OBJECT_IDS = [mTaskDef.ID, mDocDef.ID, mAssetDef.ID];

export function useObjectAssociations(
	sourceObjectId?: number,
	prioritizedAssociationObjectIds = DEFAULT_TABBED_ASSOCIATION_OBJECT_IDS
) {
	const { currentData, isFetching, isError } = useGetObjectAssociationsQuery(sourceObjectId ?? skipToken);

	const objectAssociations = useMemo(
		() => reduceObjectAssociation(currentData || [], prioritizedAssociationObjectIds, sourceObjectId),
		[currentData, sourceObjectId, prioritizedAssociationObjectIds]
	);

	return { isLoading: isFetching, objectAssociations, isError };
}

function reduceObjectAssociation(
	objectAssociations: SDObjectAssociations,
	prioritizedAssociationObjectIds: number[],
	sourceObjectId?: number
) {
	const prioritizedAssociations: DirectedSDObjectAssociation[] = [];
	const unprioritizedAssociations: DirectedSDObjectAssociation[] = [];
	const allAssociations: DirectedSDObjectAssociation[] = [];

	if (sourceObjectId === undefined) {
		return {
			prioritizedAssociations,
			unprioritizedAssociations,
			allAssociations,
		};
	}

	objectAssociations.forEach((objectAssociation) => {
		const isSourceObject1 = objectAssociation.originObject === ObjectAssociationEndpointType.OBJECT_ONE;

		const sourceObject = isSourceObject1 ? objectAssociation.object1 : objectAssociation.object2;
		const connectedObject = isSourceObject1 ? objectAssociation.object2 : objectAssociation.object1;

		const directedObjectAssociation = {
			...objectAssociation,
			connectedObject,
			sourceObject,
		};

		if (prioritizedAssociationObjectIds.includes(connectedObject.id)) {
			prioritizedAssociations.push(directedObjectAssociation);
		} else {
			unprioritizedAssociations.push(directedObjectAssociation);
		}

		allAssociations.push(directedObjectAssociation);
	});

	return {
		// Sorts the prioritized associations to match the prioritizedAssociationObjectIds order
		prioritizedAssociations: prioritizedAssociations.sort(
			(a, b) =>
				prioritizedAssociationObjectIds.indexOf(a.connectedObject.id) -
				prioritizedAssociationObjectIds.indexOf(b.connectedObject.id)
		),
		unprioritizedAssociations,
		allAssociations,
	};
}
