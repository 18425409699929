import { useMemo } from "react";

import { getSDRecordName, SDRecord, getUserRecordAvatarFileId, SDObject } from "@salesdesk/salesdesk-schemas";

import { User } from "../types";
import { useUserObjectsMap } from "./useUserObjectsMap";

type ConvertUserSDRecordsReturn<T> = T extends SDRecord[] ? Array<User | undefined> : User | undefined;

export function useConvertUserSDRecordsToAvatarUsers<T extends SDRecord | SDRecord[]>(
	userSdRecords?: T,
	sdObject?: SDObject
): ConvertUserSDRecordsReturn<T> {
	const userObjectMap = useUserObjectsMap(Boolean(sdObject));

	return useMemo(() => {
		if (!userSdRecords) {
			return undefined;
		}

		const convertToAvatarUser = (sdRecord: SDRecord) => {
			const userObject = sdObject ?? userObjectMap?.get(sdRecord._objectDefId);
			if (!userObject) {
				return undefined;
			}

			return {
				id: sdRecord._id,
				fullName: getSDRecordName(userObject, sdRecord),
				avatarFileId: getUserRecordAvatarFileId(userObject, sdRecord),
			};
		};

		return Array.isArray(userSdRecords) ? userSdRecords.map(convertToAvatarUser) : convertToAvatarUser(userSdRecords);
	}, [userSdRecords, sdObject, userObjectMap]) as ConvertUserSDRecordsReturn<T>;
}
