import { ICONS } from "@salesdesk/salesdesk-ui";

export enum BOARD_VIEW {
	TABLE = "TABLE",
	CARD = "CARD",
	LIST = "LIST",
	KANBAN = "KANBAN",
	CALENDAR = "CALENDAR",
}

export const BOARD_VIEW_OPTIONS = [
	{ id: BOARD_VIEW.TABLE, name: "Table", icon: ICONS.table },
	{ id: BOARD_VIEW.LIST, name: "List", icon: ICONS.list },
	{ id: BOARD_VIEW.KANBAN, name: "Kanban", icon: ICONS.kanban },
	{ id: BOARD_VIEW.CARD, name: "Cards", icon: ICONS.cards },
	{ id: BOARD_VIEW.CALENDAR, name: "Calendar", icon: ICONS.calendar },
];
