import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mOptionsBuilderField extends mField {	
	addValue(value){

		if(!this.containsValue(value)){		
			
			if(isEmpty(this._value)){
				this._value = [];
			}

			this._value.push(value);

			super.value = this._value;
		}
	}
	
	removeValue(id){

		let index = -1;

		for(let i = 0; this._value.length > i; i++){

			if(this._value[i].id === id){
				index = i;
			}
		}

		if (index > -1) {
			this._value.splice(index, 1);
		}
	}	
	
	hasValue(){		
		return !isEmpty(this._value);
	}	

	containsValue(value){

		if(isEmpty(this._value)){
			return false;
		}

		for(let i = 0; this._value.length > i; i++){

			if((this._value[i].name == value) || (this._value[i].id == value)){
				return true;
			}

		}

		return false
	}		

	getValue(id){
		
		for(let i = 0; this._value.length > i; i++){

			if(this._value[i].id == id){
				return this._value[i];
			}
		}

		return null;
	}

	displayValue(){	

		if(this.hasValue()){

			let valueStrs = [];

			for(let i = 0; this._value.length > i; i++){
				valueStrs.push(this._value[i].name);
			}
			
			return valueStrs.toString().replace(/,/g, ", ");
		}

		return "";
	}
}