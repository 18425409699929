import clsx from "clsx";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

interface PreviewIconPickerFieldProps {
	error?: boolean;
}

export function PreviewIconPickerField({ error }: PreviewIconPickerFieldProps) {
	return (
		<Icon
			icon={ICONS.sticker}
			className={clsx(
				error ? "border-c_danger_02" : "border-transparent",
				"bg-c_bg_02 text-c_text_disabled flex h-10 w-10 flex-col items-center justify-center rounded-full border-2"
			)}
		/>
	);
}
