import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { useGetContextWorkspaceId } from "../../features/workspaces";
import { useOnMeetingPage } from "../../features/meetings";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useWebPrincipal } from "../../auth";
import { PATHS } from "../utils";
import { useStableNavigate } from "./useStableNavigate";

/**
 * Navigates the user to the previous page they were on in the app.
 *
 * If the previous page is not within the current app, redirects the user to
 * the appropriate fallback page based on the current context.
 */
export function useNavigateBack(meetingPageRecord?: SDRecord, meetingObject?: SDObject) {
	const principal = useWebPrincipal();

	const onMeetingPage = useOnMeetingPage();

	const workspaceId = useGetContextWorkspaceId();

	const navigate = useStableNavigate();
	const location = useLocation();

	return useCallback(() => {
		// Source: https://github.com/remix-run/history/blob/main/docs/api-reference.md#location.key
		if (location.key !== "default") {
			navigate(-1);
			return;
		}

		if (onMeetingPage && meetingPageRecord && meetingObject) {
			if (workspaceId != null) {
				navigate(PATHS.WORKSPACE_RECORD_BOARD(workspaceId, meetingObject, { recordId: meetingPageRecord._id }));
				return;
			}

			if (principal.IsSalesDeskUser) {
				navigate(PATHS.OBJECT_RECORD_BOARD(meetingObject, { recordId: meetingPageRecord._id }));
				return;
			}
		}

		if (workspaceId != null) {
			navigate(PATHS.WORKSPACE_OVERVIEW(workspaceId));
			return;
		}

		navigate(PATHS.ROOT());
	}, [meetingPageRecord, location.key, meetingObject, navigate, onMeetingPage, principal.IsSalesDeskUser, workspaceId]);
}
