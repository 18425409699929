import { useCallback } from "react";

import { useDeleteRecordAssociationMutation } from "../api/recordAssociationsApi";
import { useGetContextWorkspaceId } from "../../workspaces";
import { DeleteRecordAssociationPath } from "@salesdesk/salesdesk-schemas";

export function useDeleteRecordAssociations() {
	const workspaceId = useGetContextWorkspaceId();

	const [deleteRecordAssociation, { isLoading }] = useDeleteRecordAssociationMutation();

	return {
		deleteRecordAssociation: useCallback(
			(association: DeleteRecordAssociationPath) => {
				return deleteRecordAssociation({ association, workspaceId }).unwrap();
			},
			[deleteRecordAssociation, workspaceId]
		),
		isLoading,
	};
}
