import { Button } from "@salesdesk/daisy-ui";
import { Accordion } from "../../../../components/Accordion";

interface NotificationCollapsibleLinkSectionProps {
	title: string;
	description: string;
	linkLabel: string;
	linkTo: string;
}

export function NotificationCollapsibleLinkSection({
	title,
	description,
	linkLabel,
	linkTo,
}: NotificationCollapsibleLinkSectionProps) {
	return (
		<Accordion title={title} defaultOpen={true}>
			<div className="mt-4 flex items-center justify-stretch gap-1">
				<div className="text-c_text_secondary text-body-sm grow">{description}</div>
				<div>
					<Button as="link" variant="secondary" size="sm" to={linkTo}>
						<span className="whitespace-nowrap">{linkLabel}</span>
					</Button>
				</div>
			</div>
		</Accordion>
	);
}
