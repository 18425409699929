import { useGetRecordOptions } from "../../../../../../fields";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { RuleValueSelect } from "../RuleValueSelect";
import { useNotificationRuleDialogContext } from "../../../../hooks/useNotificationRuleDialogContext";

export function UsersValueSelector() {
	const { selectedUsers, setSelectedUsers, selectedWorkspaceId } = useNotificationRuleDialogContext();
	const { getOptions: getUserOptions } = useGetRecordOptions({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
		sharedWithWorkspaceId: selectedWorkspaceId,
	});

	return (
		<RuleValueSelect
			title="Users selection"
			value={selectedUsers}
			onChange={setSelectedUsers}
			getAsyncSelectOptions={getUserOptions}
			multiple={true}
			optionDisplayVariant="user_pill"
		/>
	);
}
