import { useEffect, useState, useCallback } from "react";

import { mLeadDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { SDRecord, AbilityAction, sdSubject, AbilitySubject } from "@salesdesk/salesdesk-schemas";

import { useGetObjectById } from "../../../../hooks";
import { PATHS, useStableNavigate } from "../../../../routes";
import { useOnboardingContext } from "../../hooks";
import { OnboardingAddLead, AddLeadFromDashboard } from "../../utils";
import { LeadCreationSuccessModal } from "./LeadCreationSuccessModal";
import { useToggleUserLoginContext } from "../../../users";
import { useCreateWorkspaceContext } from "../../../workspaces";
import { useWebPrincipal } from "../../../../auth";
import { useCreateRecordDialogContext } from "../../../records/components/RecordCreate/hooks/useCreateRecordDialogContext";

export function OnboardingLeadCreation() {
	const navigate = useStableNavigate();

	const { openModal: openCreateWorkspaceModal } = useCreateWorkspaceContext();
	const { currentOnboardingFlowStep, nextStep, stopFlow, showConfetti } = useOnboardingContext();
	const { setUserRecordToInvite } = useToggleUserLoginContext();
	const principal = useWebPrincipal();

	const { openModal: openRecordCreateDialog } = useCreateRecordDialogContext();

	const [isCongratsModalOpen, setIsCongratsModalOpen] = useState(false);
	const [newCreatedRecord, setNewCreatedRecord] = useState<SDRecord>();

	const { sdObject: leadSDObject } = useGetObjectById(mLeadDef.ID);

	const handleGrantAccess = useCallback(() => {
		if (setUserRecordToInvite && newCreatedRecord) {
			setUserRecordToInvite(newCreatedRecord);
		}
		setIsCongratsModalOpen(false);
	}, [setUserRecordToInvite, newCreatedRecord]);

	const handleCreateWorkspace = useCallback(() => {
		if (
			newCreatedRecord &&
			leadSDObject &&
			openCreateWorkspaceModal &&
			principal.can(AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: mWorkspaceDef.ID }))
		) {
			openCreateWorkspaceModal({ originRecord: newCreatedRecord, originRecordObject: leadSDObject });
		}
		setIsCongratsModalOpen(false);
	}, [openCreateWorkspaceModal, newCreatedRecord, leadSDObject, principal]);

	useEffect(() => {
		if (!leadSDObject) return;

		switch (currentOnboardingFlowStep) {
			case AddLeadFromDashboard.ADD_LEAD_DASHBOARD_PAGE:
			case OnboardingAddLead.REDIRECT_TO_LEAD_AND_OPEN_MODAL_CREATION:
				navigate(PATHS.OBJECT_RECORD_BOARD(leadSDObject));
				openRecordCreateDialog({
					sdObject: leadSDObject,
					onRecordCreated: (newSDRecord) => {
						setNewCreatedRecord(newSDRecord);
						navigate(PATHS.OBJECT_RECORD_BOARD(leadSDObject, { recordId: newSDRecord._id }));
						setTimeout(() => {
							nextStep();
						}, 1000);
					},
				});
				nextStep();
				break;
			case OnboardingAddLead.LEAD_SHOW_CONFETTI:
				showConfetti();
				nextStep();
				break;
			case OnboardingAddLead.LEAD_CREATED_SUCCESS:
				setTimeout(() => {
					setIsCongratsModalOpen(true);
				}, 4000);

				stopFlow();
				break;
		}
	}, [leadSDObject, currentOnboardingFlowStep, showConfetti, stopFlow, navigate, nextStep, openRecordCreateDialog]);

	if (isCongratsModalOpen && newCreatedRecord) {
		return (
			<LeadCreationSuccessModal
				open
				onGrantAccessClick={handleGrantAccess}
				onCreateWorkspaceClick={handleCreateWorkspace}
				onClose={() => {
					setIsCongratsModalOpen(false);
				}}
			/>
		);
	}
}
