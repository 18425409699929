import { asConst } from "json-schema-to-ts";

export const searchAfterSchema = asConst({
	title: "Search After",
	description: "Add the searchAfter result from a previous query to an identical query to get the next set of results",
	type: "array",
	items: {
		anyOf: [{ type: "string" }, { type: "boolean" }, { type: "integer" }, { type: "number" }],
	},
	minItems: 1,
	nullable: false,
});
