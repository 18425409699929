import { Fragment, useMemo } from "react";

import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { AbilityAction, AbilitySubject, FieldInfo, sdSubject } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useVideoCallLogicContext } from "../../../../../../VideoCallProvider";
import { useMeetingPageContext } from "../../../../../hooks";
import { isSystemField } from "../../../../../../../../fields";
import { InfoPanelParticipantDetails } from "./InfoPanelParticipantDetails";
import { FieldInfoSection } from "./FieldInfoSection";
import { Divider } from "../../../../../../../../../components/Divider/Divider";
import { getRecordFieldInfo } from "../../../../../../../../records";
import { useWebPrincipal } from "../../../../../../../../../auth";

interface InfoPanelMeetingFieldsProps {
	onInviteOtherUserClick?: () => void;
}

export function InfoPanelMeetingFields({ onInviteOtherUserClick }: InfoPanelMeetingFieldsProps) {
	const { meetingObject, meetingRecord } = useMeetingPageContext();
	const { currentMeetingRecord } = useVideoCallLogicContext();
	const principal = useWebPrincipal();
	const canInviteOthers =
		meetingRecord && principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Record, meetingRecord));

	const [timeFieldInfo, otherFieldInfoToDisplay]: [FieldInfo | null, FieldInfo[]] = useMemo(() => {
		if (!meetingObject || !currentMeetingRecord) return [null, []];

		let agendaFieldInfo: FieldInfo | null = null;
		let timeFieldInfo: FieldInfo | null = null;
		const customFields: FieldInfo[] = [];

		const recordFieldInfo = getRecordFieldInfo(meetingObject, currentMeetingRecord);
		recordFieldInfo.forEach((fieldInfo) => {
			const { field } = fieldInfo;

			if (field._name === mInteractionMeetingDef.AGENDA_FIELD_NAME) {
				agendaFieldInfo = fieldInfo;
			} else if (field._name === mInteractionMeetingDef.TIME_FIELD_NAME) {
				timeFieldInfo = fieldInfo;
			} else if (!isSystemField(field)) {
				customFields.push(fieldInfo);
			}
		});

		return [timeFieldInfo, agendaFieldInfo ? [agendaFieldInfo, ...customFields] : [...customFields]];
	}, [meetingObject, currentMeetingRecord]);

	return (
		<>
			{timeFieldInfo && (
				<>
					<FieldInfoSection fieldInfo={timeFieldInfo} />
					<Divider className="mx-0" />
				</>
			)}
			{canInviteOthers ? (
				<Button size="sm" startIcon={ICONS.plus} variant="secondary" onClick={onInviteOtherUserClick}>
					Invite others
				</Button>
			) : null}
			<InfoPanelParticipantDetails />
			{otherFieldInfoToDisplay.map((fieldInfo) => (
				<Fragment key={fieldInfo.field._id}>
					<Divider className="mx-0" />
					<FieldInfoSection fieldInfo={fieldInfo} />
				</Fragment>
			))}
		</>
	);
}
