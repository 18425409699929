import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import { useMemo, useState } from "react";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { TranscriptSentimentRow } from "./TranscriptSentimentRow";
import { UsersSelect } from "../../common/UsersSelect";
import { getKeyByUserIdOrPlaceholderName } from "../../../utils";
import { Accordion } from "../../../../../components/Accordion";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { UserIdOrPlaceholderName } from "../../../types";

export function TranscriptSentimentGraph() {
	const [selectedUserIdsOrPlaceholderNames, setSelectedUserIdsOrPlaceholderNames] = useState<UserIdOrPlaceholderName[]>(
		[]
	);
	const {
		userKeyToUserId,
		transcriptSentimentsPerUser,
		onlyTotalTalkingTime,
		setOnlyTotalTalkingTime,
		transcriptSentimentGraphRef,
		setTranscriptSentimentGraphOpen,
	} = useMediaAnalysisContext();

	const allUserIdsOrPlaceholderNames = useMemo(() => {
		if (!transcriptSentimentsPerUser) {
			return undefined;
		}
		return Object.values(userKeyToUserId);
	}, [userKeyToUserId, transcriptSentimentsPerUser]);

	if (!transcriptSentimentsPerUser || !allUserIdsOrPlaceholderNames) {
		return null;
	}

	const filteredUserIdOrPlaceholderNames =
		selectedUserIdsOrPlaceholderNames.length > 0 ? selectedUserIdsOrPlaceholderNames : allUserIdsOrPlaceholderNames;

	return (
		<div className="relative">
			<Accordion
				title="Talking time"
				defaultOpen
				unmount={false}
				onOpenChange={setTranscriptSentimentGraphOpen}
				variant="secondary"
			>
				<div className="absolute right-1 top-0 flex gap-2">
					<Tooltip text={onlyTotalTalkingTime ? "Show sentiment analysis" : "Show total talking time"}>
						<Button
							startIcon={ICONS.reports}
							onClick={(e) => {
								e.stopPropagation();
								setOnlyTotalTalkingTime(!onlyTotalTalkingTime);
							}}
							active={onlyTotalTalkingTime}
							size="xs"
							variant="text"
						/>
					</Tooltip>
					<UsersSelect
						userIdsOrPlaceholderNames={allUserIdsOrPlaceholderNames}
						value={selectedUserIdsOrPlaceholderNames}
						onChange={setSelectedUserIdsOrPlaceholderNames}
					/>
				</div>
				<div ref={transcriptSentimentGraphRef} className="flex flex-col gap-4 pt-4">
					{filteredUserIdOrPlaceholderNames.map((userIdOrPlaceholderName) => {
						const key = getKeyByUserIdOrPlaceholderName(userKeyToUserId, userIdOrPlaceholderName);
						if (!key) {
							return null;
						}
						const transcriptSentiments = transcriptSentimentsPerUser[key];
						if (!transcriptSentiments) {
							return null;
						}
						return (
							<TranscriptSentimentRow
								key={userIdOrPlaceholderName}
								userIdOrPlaceholderName={userIdOrPlaceholderName}
								transcriptSentiments={transcriptSentiments}
							/>
						);
					})}
					{filteredUserIdOrPlaceholderNames.length === 0 ? (
						<div className="text-c_text_placeholder flex w-full items-center justify-center">No users selected</div>
					) : null}
				</div>
			</Accordion>
		</div>
	);
}
