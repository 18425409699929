import { asConst } from "json-schema-to-ts";
import { nullableAnythingSchema } from "../../../components";
import { appendEditableForCustom } from "../../../functions";

export const fieldValueValueSchema = asConst({
	// TODO: Add all options to ./values and reference them here for explicitness in the documentation
	...nullableAnythingSchema,
	title: "SDRecord Field Value Value",
	description: appendEditableForCustom("A value for the Field Value"),
});
