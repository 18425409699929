import { formatVideoTimestamp } from "../../../files/components/VideoPlayer/utils";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useMediaAnalysisContext } from "../../hooks/useMediaAnalisysContext";
import clsx from "clsx";
import { getBoundingRect, isWithinRect } from "../../utils";
import { useGraphDimensions } from "../../hooks/useGraphDimentions";
import { throttle } from "../../../../utils";

const TOOLTIP_WIDTH = 70;

interface VideoCursorProps {
	holderRef: RefObject<HTMLDivElement>;
}

export function VideoCursor({ holderRef }: VideoCursorProps) {
	const {
		videoDuration,
		onlyTotalTalkingTime,
		faceSentimentGraphHolderRef,
		transcriptSentimentGraphRef,
		faceSentimentGraphRef,
		anyPopoverSelectOpen,
		zoomFactor,
		viewStartTime,
	} = useMediaAnalysisContext();

	const { holderRect, containerWidth, cursorTop, cursorHeight } = useGraphDimensions(holderRef);

	const [cursorTime, setCursorTime] = useState<number>();
	const [viewX, setViewX] = useState<number>();
	const [showOnLeft, setShowOnLeft] = useState<boolean>(false);

	const updateCursor = useMemo(() => {
		return throttle((x: number | undefined) => {
			if (!faceSentimentGraphHolderRef.current || !faceSentimentGraphRef.current) {
				return;
			}
			if (x === undefined) {
				setViewX(undefined);
				setCursorTime(undefined);
			} else {
				setViewX(x);
				const videoStartTime = viewStartTime || 0;
				setCursorTime(videoStartTime + (x / containerWidth) * (videoDuration / zoomFactor));
				setShowOnLeft(x > containerWidth - TOOLTIP_WIDTH);
			}
		}, 10);
	}, [containerWidth, faceSentimentGraphHolderRef, faceSentimentGraphRef, videoDuration, viewStartTime, zoomFactor]);

	useEffect(() => {
		const onMouseMove = (e: MouseEvent) => {
			const faceRect = getBoundingRect(faceSentimentGraphHolderRef.current);
			const transcriptRect = getBoundingRect(transcriptSentimentGraphRef.current);
			// Adjustments so it doesn't show the tooltip without the cursor line being visible when pointing right on the edge
			faceRect.right -= 1;
			transcriptRect.right -= 1;
			updateCursor(
				isWithinRect(faceRect, e.clientX, e.clientY) ||
					(isWithinRect(transcriptRect, e.clientX, e.clientY) && !onlyTotalTalkingTime)
					? e.clientX - holderRect.left
					: undefined
			);
		};
		window.addEventListener("mousemove", onMouseMove);
		return () => {
			window.removeEventListener("mousemove", onMouseMove);
		};
	}, [faceSentimentGraphHolderRef, holderRect.left, onlyTotalTalkingTime, transcriptSentimentGraphRef, updateCursor]);

	if (viewX === undefined || cursorTime === undefined || anyPopoverSelectOpen) {
		return null;
	}

	return (
		<div
			className="border-l-c_bg_tooltip pointer-events-none absolute z-20 border-l border-dashed"
			style={{
				top: cursorTop,
				left: viewX,
				height: cursorHeight,
			}}
		>
			<div
				className={clsx(
					"bg-c_bg_tooltip text-c_text_inverted text-label-xs shadow-tooltip absolute top-4 px-3 py-1",
					showOnLeft ? "right-0 rounded-l-sm" : "left-0 rounded-r-sm"
				)}
			>
				{formatVideoTimestamp(cursorTime)}
			</div>
		</div>
	);
}
