import React, { useState } from "react";
import { Dialog } from "../../../Dialog";
import { FileDialogContent } from "./FileDialogContent";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { getFileExtension, getFormattedFileSize, hasVideoAnalysis } from "../../utils";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { VideoAnalysisPanel } from "../../../mediaAnalysis";
import { FilePreview } from "../FilePreview/FilePreview";
import { Button } from "@salesdesk/daisy-ui";
import { AbilityAction, AbilitySubject, SDFile } from "@salesdesk/salesdesk-schemas";
import { DisplayIf } from "../../../../auth";

interface ExpandedFilePreviewProps {
	file: SDFile;
	onOpenChange: (open: boolean) => void;
}

export function ExpandedFilePreview({ file, onOpenChange }: ExpandedFilePreviewProps) {
	const downloadFile = useDownloadFile(file);
	const [preventModalDismiss, setPreventModalDismiss] = useState(false);

	const onDownloadClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		downloadFile();
	};

	const onCloseClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (!preventModalDismiss) {
			onOpenChange(false);
		}
	};

	return (
		<Dialog open onOpenChange={onOpenChange}>
			<FileDialogContent>
				<div className="flex h-screen flex-col justify-stretch gap-2" onClick={onCloseClick}>
					<div onClick={(e) => e.stopPropagation()}>
						<div className="text-c_text_inverted mx-10 my-2 flex h-12 items-center justify-between">
							<div className="text-h4">
								{file.name} ({getFileExtension(file).toUpperCase()} | {getFormattedFileSize(file)})
							</div>
							<div className="flex gap-3">
								<DisplayIf can={[AbilityAction.DownloadInUI, AbilitySubject.File]}>
									<Button startIcon={ICONS.download} size="sm" variant="text_inverted" onClick={onDownloadClick} />
								</DisplayIf>
								<Button startIcon={ICONS.cross} size="sm" variant="text_inverted" onClick={onCloseClick} />
							</div>
						</div>
					</div>
					<div className="mx-10 mb-10 flex flex-grow items-center overflow-hidden">
						{hasVideoAnalysis(file) ? (
							<VideoAnalysisPanel file={file} updatePreventModalDismiss={setPreventModalDismiss} />
						) : (
							<FilePreview file={file} variant="expanded" />
						)}
					</div>
				</div>
			</FileDialogContent>
		</Dialog>
	);
}
