import { Fragment, useCallback, useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { isMacOS } from "@tiptap/core";

import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Spinner } from "@salesdesk/daisy-ui";

import { Popover, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { useStableNavigate } from "../../../../../routes";
import { GlobalSearchbarPopover } from "./GlobalSearchbarPopover";
import { useGlobalSearchbar } from "../hooks/useGlobalSearchbar";

/*
    TODO: Add filtering (e.g by object, etc.) and other advanced global search functionalities
    once UX team has determined how this will look/work.
*/
export function InternalGlobalSearchbar() {
	const {
		searchRef,
		comboBtn,
		searchBarText,
		setSearchBarText,
		updateComboboxPopoverState,
		isLoading,
		searchResults,
		resultHighlights,
		currentSearchText,
	} = useGlobalSearchbar();

	const navigate = useStableNavigate();

	const [isMac] = useState(() => isMacOS());

	// Focuses searchbar on 'CMD + K' or 'Ctrl + K'
	useEffect(() => {
		const onKeydown = (e: KeyboardEvent) => {
			if (!(e.key.toLowerCase() === "k" && (e.metaKey || e.ctrlKey))) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();
			searchRef.current?.focus();
			updateComboboxPopoverState(true);
		};

		document.addEventListener("keydown", onKeydown);
		return () => document.removeEventListener("keydown", onKeydown);
	}, [searchRef, updateComboboxPopoverState]);

	const onRecordSelect = useCallback(
		(urlPath: string, newSearchbarText?: string) => {
			navigate(urlPath);

			if (newSearchbarText) {
				setSearchBarText(newSearchbarText);
			}

			updateComboboxPopoverState(false);
		},
		[navigate, setSearchBarText, updateComboboxPopoverState]
	);

	return (
		// Value of combobox is set to a fixed null to prevent re-render issues on combobox close
		<Combobox
			value={null as unknown}
			onChange={({ urlPath, newSearchbarText }: { urlPath: string; newSearchbarText?: string }) =>
				onRecordSelect(urlPath, newSearchbarText)
			}
		>
			{({ open }) => (
				<Popover
					keepPopoverMounted={true}
					hideWhenClosedButMounted={false}
					useFloatingPortal={false}
					open={open}
					isInputPopover
				>
					<PopoverTrigger>
						<div
							tabIndex={-1}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								searchRef.current?.focus();
								updateComboboxPopoverState(true);
							}}
							className={clsx(
								"bg-c_bg_01 text-c_icon_regular focus-within:border-c_action_01 text-body relative z-[150] flex h-10 w-[460px] items-center gap-4 border pl-4 pr-6",
								open
									? "border-c_action_01 rounded-t-xl border-b-0"
									: "border-c_bg_01 rounded-full transition-all delay-[100ms] duration-0"
							)}
						>
							{isLoading && open ? (
								<div>
									<Spinner />
								</div>
							) : (
								<Icon icon={ICONS.search} />
							)}
							<Combobox.Input
								ref={searchRef}
								autoComplete="off"
								as="input"
								displayValue={() => searchBarText}
								value={searchBarText}
								className="placeholder:text-c_text_placeholder text-c_text_primary flex w-full"
								placeholder="Search records and assets..."
								onChange={(event) => setSearchBarText(event.target.value)}
								onFocus={(e) => {
									// Ensures cursor is at the end of the text on re-focus
									const value = e.target.value;
									e.target.value = "";
									e.target.value = value;
								}}
							/>
							<div className="text-label whitespace-nowrap">{isMac ? "⌘ K" : "Ctrl+K"}</div>
							<Combobox.Button as={Fragment}>
								<button ref={comboBtn} className="sr-only" />
							</Combobox.Button>
						</div>
					</PopoverTrigger>
					<PopoverContent initialFocus={-1}>
						<GlobalSearchbarPopover
							searchResults={searchResults}
							searchHighlightsMap={resultHighlights}
							searchText={currentSearchText}
							onRecordSelect={onRecordSelect}
							isLoading={isLoading}
						/>
					</PopoverContent>
				</Popover>
			)}
		</Combobox>
	);
}
