import { asConst } from "json-schema-to-ts";
import { UserPreferenceType } from "./UserPreferenceType";
import { booleanSchema } from "../components";

export const userPreferencesSchema = asConst({
	title: "User Preferences",
	description: "A user's personal preferences",
	type: "object",
	additionalProperties: false,
	required: [
		UserPreferenceType.notification_channel_enabled_email,
		UserPreferenceType.notification_channel_enabled_browser,
	],
	properties: {
		[UserPreferenceType.notification_channel_enabled_email]: booleanSchema,
		[UserPreferenceType.notification_channel_enabled_browser]: booleanSchema,
	},
});
