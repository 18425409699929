import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../../utils";
import { mFieldDef } from "../../../field_def";

export class mBooleanFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [
		FIELD_COMPONENT_TYPES.CHECKBOX.name,
		FIELD_COMPONENT_TYPES.TOGGLE_SWITCH.name,
	];
	static defaultComponentType: FieldComponentName = mBooleanFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);

		this._componentType = mBooleanFieldDef.defaultComponentType;

		this._icon = FIELD_TYPES.BOOLEAN.icon;
		this._defaultValue = false;
	}

	override get type() {
		return FIELD_TYPES.BOOLEAN.name;
	}

	override get supportedComponentTypes() {
		return mBooleanFieldDef.supportedComponentTypes;
	}

	override supportsDefaultValue() {
		return true;
	}

	override supportsFormatDescription() {
		return false;
	}

	override supportsMaxLength() {
		return false;
	}

	override formatValue(value: any) {
		return super.formatValue(value);
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (typeof value !== "boolean") {
			return "Invalid value type";
		}

		return super.validate(value, isTemplate);
	}
}
