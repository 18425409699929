import { FieldValue, SDRecord, SDRecordCreateRequest, SDRecordPatchRequest } from "../../../records";
import { Field, SDObject } from "../../../object";
import { GetSDObjectFieldsOptions, getSDObjectNameFieldIds, getSDObjectFieldByName } from "../../objects";
import { FIELD_CREATION_TYPE, mUserDef } from "@salesdesk/salesdesk-model";

export function getSDRecordFieldValues(
	sdRecord: SDRecord | SDRecordCreateRequest | SDRecordPatchRequest
): FieldValue[] {
	return sdRecord._dataInst?._children ?? [];
}

export function getSDRecordFieldMap(sdRecord: SDRecord): Record<string, FieldValue> {
	const recordFieldMap: Record<string, FieldValue> = {};

	getSDRecordFieldValues(sdRecord).forEach((recordField) => {
		recordFieldMap[recordField._fieldId] = recordField;
	});

	return recordFieldMap;
}

export function getSDRecordFieldValue(
	sdRecord: SDRecord | SDRecordCreateRequest | SDRecordPatchRequest,
	fieldId: number | undefined
): FieldValue | undefined {
	if (!fieldId) return undefined;
	return getSDRecordFieldValues(sdRecord).find((fieldValue) => fieldValue._fieldId === fieldId);
}

export const getSDRecordFieldValueByFieldName = (
	sdObject: SDObject,
	sdRecord: SDRecord | SDRecordCreateRequest | SDRecordPatchRequest,
	fieldName: string,
	options?: GetSDObjectFieldsOptions
) => {
	const fieldId = getSDObjectFieldByName(sdObject, fieldName, options)?._id;
	return getSDRecordFieldValue(sdRecord, fieldId);
};

export const getSDRecordSystemFieldValueByFieldName = (
	sdObject: SDObject,
	sdRecord: SDRecord | SDRecordCreateRequest | SDRecordPatchRequest,
	fieldName: string
) => getSDRecordFieldValueByFieldName(sdObject, sdRecord, fieldName, { creationType: FIELD_CREATION_TYPE.SYSTEM });

export const getSDRecordFieldValueValueByFieldName = <TValue>(
	sdObject: SDObject,
	sdRecord: SDRecord,
	fieldName: string,
	options?: GetSDObjectFieldsOptions
) => {
	return getSDRecordFieldValueByFieldName(sdObject, sdRecord, fieldName, options)?._value as TValue;
};

export const getSDRecordSystemFieldValueValueByFieldName = <TValue>(
	sdObject: SDObject,
	sdRecord: SDRecord,
	fieldName: string
) =>
	getSDRecordFieldValueValueByFieldName<TValue>(sdObject, sdRecord, fieldName, {
		creationType: FIELD_CREATION_TYPE.SYSTEM,
	});

export const getSDRecordSingleOptionFieldValueByFieldName = (
	sdObject: SDObject,
	sdRecord: SDRecord,
	fieldName: string,
	options?: GetSDObjectFieldsOptions
) => {
	const objectField = getSDObjectFieldByName(sdObject, fieldName, options);
	const fieldValue = getSDRecordSystemFieldValueByFieldName(sdObject, sdRecord, fieldName)?._value;
	return objectField?._optionValues?.find((optionValue) => optionValue.id === fieldValue)?.name;
};

export const getSDRecordSingleOptionSystemFieldValueByFieldName = (
	sdObject: SDObject,
	sdRecord: SDRecord,
	fieldName: string
) =>
	getSDRecordSingleOptionFieldValueByFieldName(sdObject, sdRecord, fieldName, {
		creationType: FIELD_CREATION_TYPE.SYSTEM,
	});

export const setSDRecordFieldValueByFieldName = (
	sdObject: SDObject,
	sdRecord: SDRecord,
	fieldName: string,
	value: any,
	options?: GetSDObjectFieldsOptions
) => {
	const fieldValue = getSDRecordFieldValueByFieldName(sdObject, sdRecord, fieldName, options);
	if (fieldValue) {
		fieldValue._value = value;
	}
};

export const setSDRecordSystemFieldValueByFieldName = (
	sdObject: SDObject,
	sdRecord: SDRecord,
	fieldName: string,
	value: any
) =>
	setSDRecordFieldValueByFieldName(sdObject, sdRecord, fieldName, value, {
		creationType: FIELD_CREATION_TYPE.SYSTEM,
	});

export const getSDFieldDisplayName = (sdObject: SDObject, fieldId: number) =>
	sdObject._dataDef._children.find((child) => child._id === fieldId)?._displayName;

export const getUserRecordAvatarFileId = (
	userObject: SDObject | null | undefined,
	userRecord: SDRecord | null | undefined
) => {
	if (userObject == null || userRecord == null) return undefined;
	const photoFileFieldValue = getSDRecordSystemFieldValueByFieldName(
		userObject,
		userRecord,
		mUserDef.PHOTO_FIELD_NAME
	)?._value;
	return typeof photoFileFieldValue === "number" ? photoFileFieldValue : undefined;
};

export function getSDRecordNameFromRecordFields(sdObject: SDObject, recordFieldValues: FieldValue[]): string {
	const nameIds = getSDObjectNameFieldIds(sdObject);
	const recordFieldsMap = new Map<number, FieldValue>(
		recordFieldValues.map((fieldValue) => [fieldValue._fieldId, fieldValue])
	);

	return nameIds
		.map((nameFieldId) => recordFieldsMap.get(nameFieldId)?._value || "")
		.filter(Boolean)
		.join(" ");
}

export function getSDRecordName(sdObject: SDObject, sdRecord: SDRecord): string {
	return getSDRecordNameFromRecordFields(sdObject, sdRecord._dataInst._children);
}
