import { asConst } from "json-schema-to-ts";
import { booleanSchema, idSchema, stringSchema } from "../components";
import { userBaseSchema } from "./userBaseSchema";

export const userCreateRequestSchema = asConst({
	title: "UserCreateRequestSchema",
	description: "The schema used for creating Users",
	type: "object",
	additionalProperties: false,
	required: [...userBaseSchema.required, "firstName", "surname", "title", "roles"],
	properties: {
		...userBaseSchema.properties,
		// TODO: Make Specific Properties with better titles/descriptions etc
		firstName: stringSchema,
		surname: stringSchema,
		title: stringSchema,
		loginAuthorized: booleanSchema,
		roles: {
			type: "array",
			items: idSchema,
		},
		linkedIn: stringSchema,
		website: stringSchema,
		company: stringSchema,
		photo: stringSchema,
	},
});
