import { asConst } from "json-schema-to-ts";
import { idSchema, updatableSchema, userIdSchema } from "../../components";
import { notificationRuleCreateRequestSchema } from "./notificationRuleCreateRequestSchema";

export const notificationRuleSchema = asConst({
	title: "Notification Rule",
	description:
		"A Notification Rule that is used to determine if a user should receive a notification for a record event",
	type: "object",
	required: [...notificationRuleCreateRequestSchema.required, "id", ...updatableSchema.required],
	additionalProperties: false,
	properties: {
		...notificationRuleCreateRequestSchema.properties,
		id: idSchema,
		ownedBy: userIdSchema,
		...updatableSchema.properties,
	},
});
