import { useMemo } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useGetLoginAuthorizationDetails } from "./useGetLoginAuthorizationDetails";

export function useShowUsersActivities(sdObject: SDObject | undefined, sdRecord: SDRecord | undefined) {
	const { isSalesDeskUser, isUserRecord } = useGetLoginAuthorizationDetails(sdObject, sdRecord);

	return useMemo(() => {
		if (!isUserRecord) return { showUserActivities: false, showCustomerActivities: false };
		return { showUserActivities: isSalesDeskUser, showCustomerActivities: !isSalesDeskUser };
	}, [isSalesDeskUser, isUserRecord]);
}
