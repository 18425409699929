import { useCallback } from "react";
import { LocalAudioTrack } from "twilio-video";

import {
	SELECTED_AUDIO_INPUT_KEY,
	useDevices,
	useMediaStreamTrack,
	useVideoCallMediaContext,
} from "../../../VideoCallProvider";
import { DeviceSelect } from "./DeviceSelect";

export function MicrophoneSelect() {
	const { audioInputDevices } = useDevices();
	const { localTracks } = useVideoCallMediaContext();

	const localAudioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;
	const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
	const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
	const localAudioInputDeviceId =
		srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;

	const replaceTrack = useCallback(
		(newDeviceId: string) => {
			window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
			localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
		},
		[localAudioTrack]
	);

	return (
		<DeviceSelect
			devices={audioInputDevices}
			currentDeviceId={localAudioInputDeviceId}
			onDeviceChange={replaceTrack}
			name={"microphone"}
		/>
	);
}
