import { useRecordDetailsContext } from "../../../../hooks/useRecordDetailsContext";
import { AssociationGroup } from "../../../common";
import { Divider } from "../../../../../../../../components/Divider/Divider";
import { DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";
import { useGetContextWorkspaceId } from "../../../../../../../workspaces";

interface AssociationsGroupsProps {
	objectAssociations: DirectedSDObjectAssociation[];
}

export function AssociationsGroups({ objectAssociations }: AssociationsGroupsProps) {
	const workspaceId = useGetContextWorkspaceId();
	const { sdRecord } = useRecordDetailsContext();

	if (!objectAssociations.length) return null;

	return (
		<div className="flex flex-col gap-6 py-6">
			{objectAssociations.map((objectAssociation) => (
				<div key={objectAssociation.id} className="flex flex-col gap-6">
					<AssociationGroup
						sourceRecord={sdRecord}
						objectAssociation={objectAssociation}
						defaultOpen={false}
						associationOpeningVariant={workspaceId != null ? "workspace" : "recordDetailView"}
					/>
					<Divider className="w-full" />
				</div>
			))}
		</div>
	);
}
