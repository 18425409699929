import { useRef } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { ButtonSelect, SelectOption } from "../../../../inputs";
import { Icon, Button } from "@salesdesk/daisy-ui";
import { SortingDetails, SortingOrder } from "../types";
import { SORTING_ORDER_OPTIONS } from "../utils";

interface SortingRowProps {
	index: number;
	value: SortingDetails;
	onChange: (newDetails: SortingDetails) => void;
	fieldOptions: SelectOption[][];
	onDelete: () => void;
	onRowMouseDown: (topPosition: number, index: number) => void;
}

export function SortingRow({ value, onChange, fieldOptions, onDelete, onRowMouseDown, index }: SortingRowProps) {
	const rowContainer = useRef(null);

	const { fieldId, order } = value;

	const handleMouseDown = () => {
		if (!rowContainer.current) return;

		const { top } = (rowContainer.current as HTMLDivElement).getBoundingClientRect();
		onRowMouseDown(top, index);
	};

	return (
		<div ref={rowContainer} className="flex items-center justify-between py-2" onMouseDown={handleMouseDown}>
			<div className="flex items-center gap-2">
				<Icon icon={ICONS.grabbable} />
				<ButtonSelect
					buttonVariant="outlined"
					value={fieldId}
					options={fieldOptions}
					onChange={(newFieldId) => onChange({ ...value, fieldId: String(newFieldId) })}
				/>
				<ButtonSelect
					buttonVariant="outlined"
					value={order}
					options={SORTING_ORDER_OPTIONS}
					onChange={(newOrder) => onChange({ ...value, order: newOrder as SortingOrder })}
				/>
			</div>
			<Button size="sm" variant="text" startIcon={ICONS.trash} onClick={() => onDelete()} />
		</div>
	);
}
