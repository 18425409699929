import { Link } from "react-router-dom";

import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { AbilityAction, AbilitySubject, SDObject, sdObjectIsSystemType, sdSubject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useWebPrincipal } from "../../../../../auth";
import { PATHS } from "../../../../../routes";
import { OBJECT_DESIGNER_TABS } from "../../ObjectDesigner/types";

interface ControlColumnProps {
	sdObject: SDObject;
	onRemoveClick: (sdObject: SDObject) => void;
}

export function ObjectManagerControlColumn({ sdObject, onRemoveClick }: ControlColumnProps) {
	const principal = useWebPrincipal();

	const canEdit = principal.can(AbilityAction.Edit, sdSubject(AbilitySubject.Object, sdObject));
	const canDeleteObject = principal.can(AbilityAction.Delete, sdSubject(AbilitySubject.Object, sdObject));
	const editObjectLink = PATHS.OBJECT_DESIGNER(sdObject, { tab: OBJECT_DESIGNER_TABS.fields });
	const isSystemObject = sdObjectIsSystemType(sdObject);

	return (
		<div className="ml-auto flex gap-4 px-2">
			<Tooltip text={!canEdit ? "You are not allowed to edit this object." : undefined} ignoreDisabled>
				<Link tabIndex={-1} to={String(editObjectLink)}>
					<Button variant="text" startIcon={ICONS.simplePencil} size="sm" disabled={!canEdit} />
				</Link>
			</Tooltip>

			<Tooltip
				text={
					isSystemObject
						? "You cannot delete the default objects."
						: !canDeleteObject
							? "You are not allowed to delete this object."
							: undefined
				}
				ignoreDisabled
			>
				<Button
					variant="text"
					startIcon={ICONS.trash}
					size="sm"
					disabled={Boolean(isSystemObject) || !canDeleteObject}
					onClick={() => onRemoveClick(sdObject)}
				/>
			</Tooltip>
		</div>
	);
}
