import { useEffect, useRef } from "react";

export function useHandleDisconnection(
	removeLocalAudioTrack: () => void,
	removeLocalVideoTrack: () => void,
	inMeeting: boolean
) {
	const removeLocalAudioTrackRef = useRef(removeLocalAudioTrack);
	const removeLocalVideoTrackRef = useRef(removeLocalVideoTrack);

	removeLocalAudioTrackRef.current = removeLocalAudioTrack;
	removeLocalVideoTrackRef.current = removeLocalVideoTrack;

	useEffect(() => {
		return () => {
			if (inMeeting) {
				removeLocalAudioTrackRef.current();
				removeLocalVideoTrackRef.current();
			}
		};
	}, [inMeeting]);
}
