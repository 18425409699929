import { getHexOrCssVariableForColorKey } from "@salesdesk/daisy-ui";
import { ColorKey } from "@salesdesk/salesdesk-ui";

interface SelectDropProps {
	colorKey: ColorKey;
}

export function SelectDrop({ colorKey }: SelectDropProps) {
	return (
		<svg width="52" height="108" viewBox="0 0 52 108" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M52 0V107.92C52 90.7478 38.4741 76.7351 21.4951 75.9543C9.57812 75.6858 0 65.9414 0 53.96C0 41.9785 9.57812 32.2341 21.4951 31.9656C38.4741 31.1848 52 17.1721 52 0Z"
				fill={getHexOrCssVariableForColorKey(colorKey)}
				className="transition-colors"
			/>
		</svg>
	);
}
