import { createContext, useContext } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { LinkingModalTabs } from "../types";
import { DirectedSDObjectAssociation } from "../../../hooks";

interface AssociationLinkingContextProps {
	initialModalTab?: LinkingModalTabs;
	setInitialModalTab?: (tab: LinkingModalTabs) => void;
	setAssociationLinkingDetails?: (
		sourceLinkingRecord?: SDRecord,
		associationObject?: SDObject,
		objectAssociation?: DirectedSDObjectAssociation
	) => void;
	workspaceId?: number;
}

const initialValues: AssociationLinkingContextProps = {
	initialModalTab: undefined,
	setInitialModalTab: undefined,
	setAssociationLinkingDetails: undefined,
};

export const AssociationLinkingContext = createContext<AssociationLinkingContextProps>(initialValues);

export function useAssociationLinkingContext() {
	const context = useContext(AssociationLinkingContext);

	if (context == null) {
		return initialValues;
	}

	return context;
}
