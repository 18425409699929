import { useState } from "react";
import { useForm } from "react-hook-form";

import { ChannelNames, ChannelType } from "@salesdesk/salesdesk-schemas";

import { useToast } from "../../../../../../Toasts";
import { ChannelDetails } from "../../../../../types";
import { useChimeMessagingContext } from "../../../../providers";

interface ChannelInfoFormData {
	name?: string;
	newMembers?: number[];
}

export function useChannelInfoViewForm(channelDetails: ChannelDetails) {
	const toast = useToast();
	const { chime } = useChimeMessagingContext();

	const [isSaving, setIsSaving] = useState(false);
	const [addingMembers, setAddingMembers] = useState(false);

	const { channelMetadata } = channelDetails;
	const isCustomChannel = channelMetadata.channelType === ChannelType.Custom;

	const currentChannelName =
		isCustomChannel && channelDetails.channelSummary.Name === ChannelNames.NotSet
			? undefined
			: channelDetails.displayName;

	const formMethods = useForm<ChannelInfoFormData>({
		mode: "onChange",
		defaultValues: {
			name: currentChannelName,
		},
	});

	const onSubmit = (data: ChannelInfoFormData) => {
		if (!chime) {
			toast.trigger("error", "Error updating the channel");
			return;
		}

		const newChannelName = data.name && data.name.trim() !== "" ? data.name : ChannelNames.NotSet;
		const updateChannelNamePromise =
			newChannelName !== channelDetails.channelSummary.Name
				? chime.updateChannelName(channelDetails.arn, newChannelName, channelDetails.channelSummary.Metadata)
				: Promise.resolve();

		const addMembersPromise = data.newMembers
			? chime.addMembersToChannel(channelDetails.arn, data.newMembers)
			: Promise.resolve();

		setIsSaving(true);

		Promise.allSettled([updateChannelNamePromise, addMembersPromise])
			.then((results) => {
				const [updateChannelNameResult, addMembersResult] = results;

				if (updateChannelNameResult.status === "fulfilled" && addMembersResult.status === "fulfilled") {
					toast.triggerMessage({ type: "success", messageKey: "channel_updated" });

					formMethods.reset({ name: data.name });
					setAddingMembers(false);

					return;
				}

				if (updateChannelNameResult.status === "rejected" && addMembersResult.status === "rejected") {
					toast.triggerMessage({ type: "error", messageKey: "channel_updated" });
				} else {
					if (updateChannelNameResult.status === "rejected") {
						console.error("Error updating channel", updateChannelNameResult.reason);
						toast.trigger("error", "Failed to update conversation name");
					}
					if (addMembersResult.status === "rejected") {
						console.error("Error adding members", addMembersResult.reason);
						toast.trigger("error", "Failed to add members to the conversation");
					}
				}
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	return {
		formMethods,
		onSubmit,
		isSaving,
		addingMembers,
		setAddingMembers,
	};
}
