/**
 * Pass-through Tailwind template tag. This is a no-op, but helps IDE & extensions
 * understand that a string represents a series of TW classes in order to provide intellisense,
 * syntax highlighting and generating the TW classes.
 *
 * @param strings - Template string parts
 * @param keys - Template string keys
 * @returns The same string as the input
 *
 * Source: https://github.com/tailwindlabs/tailwindcss/discussions/7558#discussioncomment-4676228
 */
export const tw = (strings: TemplateStringsArray, ...keys: unknown[]): string => {
	const lastIndex = strings.length - 1;
	return (
		strings.slice(0, lastIndex).reduce((acc, str, i) => {
			return acc + str + keys[i];
		}, "") + strings[lastIndex]
	);
};
