import { subject } from "@casl/ability";
import { ForcedSubject } from "@casl/ability/dist/types/types";

/*
 * This replaces the use of casl subject() method which adds a "__caslSubjectType__" property to the object which
 * will fail on RTKQuery entities
 */
export const sdSubject = <T extends string, U extends Record<PropertyKey, any>>(
	type: T,
	object: U
): U & ForcedSubject<T> => subject(type, { ...object });
