import { useCallback } from "react";

import { Divider } from "../../../../../../components/Divider/Divider";
import { RecordSidePanel } from "../../../../../records";
import { useWorkspaceNavCollapse } from "../../../../hooks/useWorkspaceNavCollapse";
import { useWorkspaceOverviewState } from "../hooks/useWorspaceOverviewState";
import { useWorkspaceOnboarding } from "../../../../../onboarding/hooks/useWorkspaceOnboarding";

import { WorkspaceTasks } from "./WorkspaceTasks/WorkspaceTasks";
import { WorkspaceIssues } from "./WorkspaceIssues/WorkspaceIssues";
import { WorkspaceUpcomingMeetings } from "./WorkspaceUpcomingMeetings";
import { WorkspaceProductsAndProjectSection } from "./WorkspaceProductsAndProjectSection";
import { WorkspaceIntroSection } from "./WorkspaceIntroSection/components";

export function WorkspaceOverview() {
	useWorkspaceOnboarding();

	const { workspaceOverviewState, workspaceOverviewPropOnChange } = useWorkspaceOverviewState();

	const closeRecordPanel = useCallback(() => {
		workspaceOverviewPropOnChange("recordId", undefined);
	}, [workspaceOverviewPropOnChange]);

	const showingSidePanel = workspaceOverviewState.recordId != null;
	useWorkspaceNavCollapse(showingSidePanel);

	return (
		<>
			<div id="userflow-workspace-preview" className="mx-auto flex w-10/12 flex-col gap-14 py-6 pb-12">
				<WorkspaceIntroSection />
				<Divider className="m-0" />
				<WorkspaceProductsAndProjectSection />
				<WorkspaceUpcomingMeetings />
				<Divider className="m-0" />
				<WorkspaceTasks />
				{/* 
					WorkspaceIssues is duplicated from WorkspaceTasks as a temporary solution.
					This component must be updated according to the design.

					https://salesdesk101.atlassian.net/browse/SAL-3440
				*/}
				<Divider className="m-0" />

				<WorkspaceIssues />
			</div>
			{workspaceOverviewState ? (
				<RecordSidePanel
					recordId={workspaceOverviewState.recordId ? Number(workspaceOverviewState.recordId) : undefined}
					onClose={closeRecordPanel}
				/>
			) : null}
		</>
	);
}
