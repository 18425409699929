import { asConst } from "json-schema-to-ts";
import { nullableNonNegativeIntegerSchema } from "../components";

export const searchFromSchema = asConst({
	...nullableNonNegativeIntegerSchema,
	title: "Search From",
	description: "Where to start the search from",
	type: "integer",
	minimum: 0,
	nullable: false,
});
