import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

import { SYSTEM_USER_NAME } from "@salesdesk/salesdesk-model";

import { RootState } from "../../../store/store";
import {
	convertChannelMembershipForUserSummaryToChannelDetails,
	serializeChannelDetails,
	serializeChannelMessage,
} from "../utils";

import {
	updateChannelLastMessageTimestamp,
	setChannelMemberships,
	updateChannelReadMarkerTimestamp,
	addChannel,
} from "./channelsSlice";
import { addMessages, setChannelMessagesToLoading, setLoadingStatus } from "./messagesSlice";
import { LoadingStatus } from "../types";
import { ChimeApi } from "@salesdesk/salesdesk-schemas";

export const addNewMessage = createAsyncThunk(
	"messages/addNewMessage",
	async ({ newMessage }: { newMessage: ChannelMessage }, { dispatch }) => {
		const channelArn = newMessage.ChannelArn;

		if (!channelArn) return;

		// Append the new message to the messages slice
		dispatch(addMessages({ channelArn, messages: [serializeChannelMessage(newMessage)] }));

		// Update the last message timestamp in the channels slice
		dispatch(
			updateChannelLastMessageTimestamp({
				channelArn,
				timestamp: newMessage.CreatedTimestamp ? newMessage.CreatedTimestamp.getTime() : new Date().getTime(),
			})
		);
	}
);

export const loadInitialMessages = createAsyncThunk(
	"messages/loadInitialMessages",
	async (
		{ channelArn, chime, isHighPriority }: { channelArn: string; chime: ChimeApi; isHighPriority?: boolean },
		{ dispatch }
	) => {
		dispatch(setChannelMessagesToLoading({ channelArn }));

		try {
			const { Messages, NextToken } = await chime.listChannelMessages(channelArn, { maxResults: 12, isHighPriority });

			// Set next token to null if it is undefined since that means there are no more messages to load
			dispatch(
				addMessages({ channelArn, messages: Messages.map(serializeChannelMessage), nextToken: NextToken ?? null })
			);

			if (Messages.length > 0) {
				dispatch(updateChannelLastMessageTimestamp({ channelArn, timestamp: Messages[0].CreatedTimestamp?.getTime() }));
			}
		} catch (error) {
			console.error(`Failed to load initial messages for channel ${channelArn}`, error);
			dispatch(setLoadingStatus({ channelArn, loadingStatus: LoadingStatus.Error }));
		}
	},
	{
		condition: ({ channelArn, isHighPriority }, { getState }) => {
			const state = getState() as RootState;

			// If the initial messages for the channel have never been loaded, allow request to go through
			if (!state.messages[channelArn]) {
				return true;
			}

			const hasLoadedMessages = Boolean(state.messages[channelArn].messages);
			const loadingStatus = state.messages[channelArn].loadingStatus ?? LoadingStatus.Loading;

			// If the messages have failed to load initially due to a request error, allow request to go through
			if (!hasLoadedMessages && loadingStatus === LoadingStatus.Error) {
				return true;
			}

			// If the messages are still loading only allow the request to go through if it is a high priority request
			// since the request may be stuck in the queue
			return Boolean(isHighPriority && !hasLoadedMessages);
		},
	}
);

export const loadPreviousMessages = createAsyncThunk(
	"messages/loadPreviousMessages",
	async ({ channelArn, chime }: { channelArn: string; chime: ChimeApi }, { dispatch, getState }) => {
		const state = getState() as RootState;
		const nextToken = state.messages[channelArn]?.nextToken;

		// If nextToken is null, there are no more messages to load
		if (nextToken === null) return;

		try {
			dispatch(setLoadingStatus({ channelArn, loadingStatus: LoadingStatus.Loading }));

			const result = await chime.listChannelMessages(channelArn, { nextToken });

			dispatch(
				addMessages({
					channelArn,
					messages: result.Messages.map(serializeChannelMessage),
					nextToken: result.NextToken ?? null,
				})
			);

			dispatch(setLoadingStatus({ channelArn, loadingStatus: LoadingStatus.Loaded }));
		} catch (error) {
			console.error(`Failed to load previous messages for channel ${channelArn}`, error);
			dispatch(setLoadingStatus({ channelArn, loadingStatus: LoadingStatus.Error }));
		}
	}
);

export const fetchInitialChannelMemberships = createAsyncThunk(
	"channels/fetchInitialChannelMemberships",
	async ({ channelArn, chime }: { channelArn: string; chime: ChimeApi }, { dispatch }) => {
		dispatch(setChannelMemberships({ channelArn, loadingStatus: LoadingStatus.Loading }));

		try {
			const memberships = await chime.listChannelMemberships(channelArn);
			const filteredMemberships = memberships.filter((membership) => membership.Member?.Name !== SYSTEM_USER_NAME);

			dispatch(
				setChannelMemberships({ channelArn, members: filteredMemberships, loadingStatus: LoadingStatus.Loaded })
			);
		} catch (err) {
			console.error("Failed to fetch channel memberships", err);
			dispatch(setChannelMemberships({ channelArn, loadingStatus: LoadingStatus.Error }));
		}
	},
	{
		condition: ({ channelArn }, { getState }) => {
			const state = getState() as RootState;
			const channel = state.channels.channels?.find((channel) => channel.arn === channelArn);

			// Prevent the thunk from running if memberships are already being fetched or already exist
			if (!channel || (channel?.memberships && channel?.memberships.loadingStatus !== LoadingStatus.Error)) {
				return false;
			}
		},
	}
);

export const updateReadMarkerForChannel = createAsyncThunk(
	"channels/updateReadMarkerForChannel",
	async ({ channelArn, chime }: { channelArn: string; chime: ChimeApi }, { dispatch }) => {
		// Adds 500ms to the current time to add lee-way for the read marker update
		dispatch(updateChannelReadMarkerTimestamp({ channelArn, timestamp: new Date().getTime() + 500 }));

		try {
			await chime.updateChannelReadMarker(channelArn);
		} catch (error) {
			console.error(`Failed to update read marker for channel ${channelArn}`, error);
		}
	}
);

export const addNewChannelFromArn = createAsyncThunk(
	"channels/addNewChannelFromArn",
	async ({ channelArn, chime }: { channelArn: string; chime: ChimeApi }, { dispatch }) => {
		try {
			// Fetch channel details
			const response = await chime.describeChannelMembershipForMe(channelArn);

			if (!response) {
				throw new Error(`Channel not found: ${channelArn}`);
			}

			const channelDetails = convertChannelMembershipForUserSummaryToChannelDetails(response);

			if (!channelDetails) {
				throw new Error(`Failed to fetch channel details for ${channelArn}`);
			}

			// Add the new channel to the store
			dispatch(addChannel(serializeChannelDetails(channelDetails)));

			// Fetch initial messages for the new channel
			dispatch(loadInitialMessages({ channelArn, chime }));

			// Fetch initial memberships for the new channel
			dispatch(fetchInitialChannelMemberships({ channelArn, chime }));
		} catch (error) {
			console.error(`Failed to add new channel ${channelArn}`, error);
		}
	}
);
