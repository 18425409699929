import clsx from "clsx";

import { SDFile } from "@salesdesk/salesdesk-schemas";

import { FILE_TYPE_DETAILS, getFileExtension, getFileTypeDetails } from "../../utils";
import { ImagePreview } from "../FilePreview/ImagePreview/ImagePreview";
import { ThemableSvgLoader } from "../../../graphics/ThemableSvgLoader";

type FileIconSize = "sm" | "md";

interface FileIconProps {
	file?: SDFile | File;
	customExtension?: string;
	withDot?: boolean;
	size?: FileIconSize;
}

export function FileIcon({ file, customExtension, withDot = true, size = "md" }: FileIconProps) {
	const fileDetails = file ? getFileTypeDetails(file) : undefined;
	const fileExtension = file ? getFileExtension(file) : customExtension;

	let iconContents;

	if (file && fileDetails?.type === "image") {
		iconContents = <ImagePreview file={file} isIcon />;
	} else {
		const fileExtensionLength = fileExtension?.length || 0;

		iconContents = (
			<div className="relative flex h-full w-full">
				{/* TODO: Current approach will suffer from truncation for atypical file extensions (e.g. longer than
					4 characters or using wide characters as this is not using a monospace font (e.g. .WWW takes up more
					horizontal space than .III). Might need to tweak this depending on common file formats used by users
					or even make this dynamically size the text based on available space (difficult)*/}
				<div
					className={clsx(
						size === "sm" &&
							` left-[9.2px] ${fileExtensionLength > 3 ? "bottom-[1.6px] text-[6px]" : "bottom-[1.3px] text-[8px]"}`,
						size === "md" && `bottom-[3px] left-[17px] ${fileExtensionLength > 3 ? "text-[11px]" : "text-[13px]"}`,
						"text-c_text_inverted absolute select-none font-black uppercase leading-none subpixel-antialiased"
					)}
				>
					{withDot ? "." : null}
					{fileExtension}
				</div>
				<ThemableSvgLoader name={fileDetails?.iconId ?? FILE_TYPE_DETAILS.GENERAL_FILE.iconId} />
			</div>
		);
	}

	return (
		<div
			className={clsx(
				size === "md" && "text-label h-[67px] w-14",
				size === "sm" && "h-9 w-[30px] text-[11px] leading-none",
				"flex shrink-0 items-center justify-center overflow-hidden"
			)}
		>
			{iconContents}
		</div>
	);
}
