import clsx from "clsx";

import { SDFile } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { VideoPreview } from "../../files/components/FilePreview/VideoPreview/VideoPreview";
import { MediaAnalysisProvider } from "./MediaAnalysisProvider";
import { TranscriptView } from "./TranscriptView/components/TranscriptView";
import { useMediaAnalysisContext } from "../hooks/useMediaAnalisysContext";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { VideoAnalyticsFab } from "./VideoAnalyticsFab";
import { AnalysisView } from "./AnalysisView/AnalysisView";

interface BaseVideoAnalysisProps {
	transcriptSearch?: string;
	onTranscriptSearchChange?: (search: string) => void;
	collapsibleTranscript?: boolean;
	withoutCardLayout?: boolean;
}

interface VideoAnalysisPanelProps extends BaseVideoAnalysisProps {
	file: SDFile;
	updatePreventModalDismiss?: (preventModalDismiss: boolean) => void;
}

export function VideoAnalysisPanel({
	file,
	updatePreventModalDismiss,
	transcriptSearch,
	onTranscriptSearchChange,
	collapsibleTranscript,
	withoutCardLayout,
}: VideoAnalysisPanelProps) {
	return (
		<MediaAnalysisProvider file={file} updatePreventModalDismiss={updatePreventModalDismiss}>
			<VideoAnalysisPanelContent
				transcriptSearch={transcriptSearch}
				onTranscriptSearchChange={onTranscriptSearchChange}
				collapsibleTranscript={collapsibleTranscript}
				withoutCardLayout={withoutCardLayout}
			/>
		</MediaAnalysisProvider>
	);
}

function VideoAnalysisPanelContent({
	transcriptSearch,
	onTranscriptSearchChange,
	collapsibleTranscript,
	withoutCardLayout,
}: BaseVideoAnalysisProps) {
	const { file, updateVideoDuration, open, setOpen, videoRef } = useMediaAnalysisContext();

	return (
		<div
			className={clsx(
				"flex h-full w-full justify-stretch",
				!withoutCardLayout && open ? "bg-c_bg_04 rounded-panel mx-auto gap-5 p-3" : "gap-4"
			)}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<div className={clsx(open ? "flex h-full w-1/2 flex-shrink-0 flex-col justify-stretch gap-3" : "flex-grow")}>
				<VideoAnalyticsFab className={open ? "h-[40%]" : "h-full"}>
					<VideoPreview
						file={file}
						variant={open ? "md" : "expanded"}
						updateVideoDuration={updateVideoDuration}
						videoRef={videoRef}
					/>
				</VideoAnalyticsFab>
				{open ? <AnalysisView /> : null}
			</div>
			{open ? (
				<TranscriptView
					search={transcriptSearch}
					onSearchChange={onTranscriptSearchChange}
					collapsible={collapsibleTranscript}
				/>
			) : (
				<div className="flex-grow-9 flex-shrink-0">
					<Tooltip text="Show transcript & sentiment">
						<Button
							startIcon={ICONS.textAlignLeft}
							size="lg"
							variant="secondary"
							onClick={(e) => {
								e.stopPropagation();
								setOpen(true);
							}}
						/>
					</Tooltip>
				</div>
			)}
		</div>
	);
}
