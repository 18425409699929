export interface PasswordValidateRule {
	title: string;
	validate: (value: string) => boolean;
}

export const passwordRules: PasswordValidateRule[] = [
	{
		title: "A lower case",
		validate: (value: string) => /[a-z]/.test(value),
	},
	{
		title: "A least 8 characters",
		validate: (value: string) => value.length >= 8,
	},
	{
		title: "An upper case letter",
		validate: (value: string) => /[A-Z]/.test(value),
	},
	{
		title: "A special character or a space",
		validate: (value: string) => /[^a-zA-Z0-9]/.test(value),
	},
	{
		title: "A number",
		validate: (value: string) => /[0-9]/.test(value),
	},
	{
		title: "No leading or trailing spaces",
		validate: (value: string) => value.length > 0 && value.trim() === value,
	},
];

export function passwordPassesAllRules(value: string) {
	return !value || passwordRules.every((rule) => rule.validate(value));
}
