import { TENANT_ROUTES } from "@salesdesk/salesdesk-schemas";

import { useTenantRouteMatch } from "../../../../../routes";

export function useOnMeetingPage() {
	const inCrmMeeting = useTenantRouteMatch(TENANT_ROUTES.MEETING_ROUTE);
	const inWorkspaceMeeting = useTenantRouteMatch(TENANT_ROUTES.WORKSPACE_MEETING_ROUTE);

	return Boolean(inCrmMeeting || inWorkspaceMeeting);
}
