import { mProductDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface ProductMediaFieldTab {
	fieldName: string;
	icon: keyof typeof ICONS;
}

export const PRODUCT_MEDIA_FIELD_TABS: ProductMediaFieldTab[] = [
	{ fieldName: mProductDef.IMAGE_FIELD_NAME, icon: "image" },
	{ fieldName: mProductDef.VIDEO_FIELD_NAME, icon: "video" },
	{ fieldName: mProductDef.PDF_FIELD_NAME, icon: "pdf" },
];
