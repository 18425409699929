import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { RecentlyAddedRecordList } from "./RecentlyAddedRecordList";

interface RecentlyAddedProps {
	assetObject: SDObject;
	docObject: SDObject;
	recentAssets: SDRecord[];
	recentDocs: SDRecord[];
	isLoadingAssets: boolean;
	isLoadingDocs: boolean;
}

export function RecentlyAddedRecords({
	assetObject,
	docObject,
	recentAssets,
	recentDocs,
	isLoadingAssets,
	isLoadingDocs,
}: RecentlyAddedProps) {
	return (
		<div className="mt-4 flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<div>Quickly access the latest assets and docs added.</div>
			</div>
			<RecentlyAddedRecordList sdObject={assetObject} sdRecords={recentAssets} isLoading={isLoadingAssets} />
			<RecentlyAddedRecordList sdObject={docObject} sdRecords={recentDocs} isLoading={isLoadingDocs} />
		</div>
	);
}
