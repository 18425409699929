import { Spinner } from "@salesdesk/daisy-ui";
import { FileInputVariant } from "../../../types";
import clsx from "clsx";

interface FileInputUploadProps {
	uploadProgress: number;
	variant?: FileInputVariant;
}

export function FileInputUpload({ uploadProgress, variant = "base" }: FileInputUploadProps) {
	return (
		<div
			className={clsx(
				"bg-c_bg_03 flex w-full flex-col items-center justify-center gap-3",
				variant === "fullPage" ? "h-full" : "h-[200px]"
			)}
		>
			<Spinner />
			<span className="text-body-sm text-c_text_secondary">
				Uploading file{uploadProgress ? `: ${uploadProgress}%` : "..."}
			</span>
		</div>
	);
}
