import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mField } from "../field_inst";

export class mDateField extends mField {
	setNow() {
		this.value = new Date().getTime();
	}

	getDate() {
		if (this.hasValue()) {
			return new Date(this.value);
		} else {
			throw Error(
				`No date value has been set in field instance with field ${this.field.name}, so cannot generate date object`
			);
		}
	}

	get group(){

		if(this.hasValue()){

			const groupInfo = this.field.groupInfo;

			for(let i = 0; groupInfo.length > i; i++){
				const group = groupInfo[i];
				if(group.contains(new Date(this.value))){
					return group;
				}
			}

			console.warn(this.value+" does not correspond to a date group!");

			return null;
		}
		throw Error(`This field instance does not belong to a group as it has no value set`);
	}

	displayValue() {
		if (isEmpty(this.value)) {
			return "";
		}

		return new Date(this.value).toLocaleDateString();
	}
}
