import { useMemo, useState } from "react";

import { BOARD_VIEW } from "@salesdesk/salesdesk-model";
import { getSDObjectFields, isAssetObject } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";
import { Select, SelectOption } from "../../../../../inputs";
import { isMediaField } from "../../../../../fields";
import { CARD_BASED_VIEWS, METADATA_FIELDS_LIST, TABLE_VIEWS } from "../../../../utils";
import { getFieldIdsToFilterForObjectType } from "../../../../utils/defaultBoardFields";
import {
	ASSET_RECORD_COMBINED_NAME_FILE_ID,
	ASSET_RECORD_COMBINED_NAME_FILE_NAME,
	NO_FIELD_ID,
} from "../../../../types";
import { useBoardPropOnChangeContext } from "../../../../hooks/useBoardPropOnChangeContext";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";
import { useBoardDisplayedFieldsSelector, useBoardMediaFieldSelector, useBoardViewSelector } from "../../../../store";
import { DisplayedFieldsSettings } from "./DisplayedFieldsSettings";

export function ViewFieldSettings() {
	const boardPropOnChange = useBoardPropOnChangeContext();
	const { sdObject, nameFieldIds, mediaFieldIds, boardFieldMap } = useDataboardDetailsContext();

	const boardView = useBoardViewSelector();
	const displayedFields = useBoardDisplayedFieldsSelector();
	const mediaField = useBoardMediaFieldSelector();

	const [metadataOptions] = useState(() =>
		METADATA_FIELDS_LIST.map((field) => ({ id: field._name, name: field._displayName }))
	);

	const isCardBasedView = boardView && CARD_BASED_VIEWS.includes(boardView);
	const isTableBoard = boardView && TABLE_VIEWS.includes(boardView);

	const mediaFieldOptions = useMemo(() => {
		if (!isCardBasedView) {
			return [];
		}

		const options = mediaFieldIds.reduce((mediaFieldOptions, fieldId) => {
			const field = boardFieldMap.get(fieldId);

			if (field) {
				mediaFieldOptions.push({ id: fieldId, name: field._displayName, icon: field._icon });
			}

			return mediaFieldOptions;
		}, [] as SelectOption[]);

		options.unshift({ id: NO_FIELD_ID, name: "None", icon: ICONS.cross });

		return options;
	}, [isCardBasedView, mediaFieldIds, boardFieldMap]);

	const fieldOptions = useMemo(() => {
		if (!sdObject) {
			return [];
		}

		const isAssetTableBoard = boardView && TABLE_VIEWS.includes(boardView) && isAssetObject(sdObject);
		const fieldIdsToFilter = getFieldIdsToFilterForObjectType(sdObject, boardView);

		const fieldOptions: SelectOption[] = [];

		if (isAssetTableBoard) {
			fieldOptions.push({
				id: ASSET_RECORD_COMBINED_NAME_FILE_ID,
				name: ASSET_RECORD_COMBINED_NAME_FILE_NAME,
			});
		}

		return getSDObjectFields(sdObject).reduce((fieldOptions, field) => {
			if (!fieldIdsToFilter.includes(field._id) && (!isCardBasedView || !isMediaField(field))) {
				fieldOptions.push({
					id: String(field._id),
					name: field._displayName,
				});
			}

			return fieldOptions;
		}, fieldOptions);
	}, [sdObject, boardView, isCardBasedView]);

	const requiredFields = isCardBasedView || isTableBoard ? nameFieldIds : undefined;
	const disabledOptions = requiredFields || (displayedFields?.length === 1 ? displayedFields : undefined);

	const disabled = boardView === BOARD_VIEW.CALENDAR;

	return (
		<Popover placement="bottom-end">
			<PopoverTrigger disabled={disabled}>
				<Button startIcon={ICONS.settings} variant="text" size="sm" disabled={disabled} />
			</PopoverTrigger>
			<PopoverContent>
				<PopoverContainer>
					<div className="text-body-sm text-c_text_secondary w-full select-none px-6 py-2">{`Customise ${
						boardView?.toLocaleLowerCase() ?? ""
					} view`}</div>
					<div className="w-[320px] overflow-x-hidden">
						{isCardBasedView ? (
							<div className="flex flex-col gap-2 px-6 pb-4 pt-1">
								<div className="text-label-sm text-c_text_secondary">Card cover</div>
								<Select
									disabled={!mediaFieldIds.length}
									value={mediaField}
									onChange={(newMediaField) => boardPropOnChange("mediaField", String(newMediaField))}
									options={mediaFieldOptions}
									placeholder="Media field"
								/>
							</div>
						) : null}
						<DisplayedFieldsSettings
							value={displayedFields}
							onChange={(newValue) => {
								const newDisplayedFields = newValue.map((value) => (typeof value === "string" ? value : String(value)));
								boardPropOnChange("displayedFields", newDisplayedFields);
							}}
							fieldOptions={fieldOptions}
							metadataOptions={metadataOptions}
							disabledOptions={disabledOptions}
						/>
					</div>
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
