import { useMemo } from "react";
import { JSONContent } from "@tiptap/core";

import { ChannelMessageMetadata } from "@salesdesk/salesdesk-schemas";

import { useAppDispatch } from "../../../../../store/store";
import { useChannelMessagesSelector, useChimeChannelStatusSelector } from "../../../store/selectors";
import { fetchInitialChannelMemberships, loadInitialMessages, loadPreviousMessages } from "../../../store/thunks";
import { ChannelDetails, LoadingStatus } from "../../../types";
import { useChimeMessagingContext } from "../../providers";
import { useChannelMemberRecords } from "../../../hooks";
import { useChimeMessageGroups } from "../hooks/useChimeMessageGroups";
import { ChatChannelView } from "./ChatChannelView";
import { useToast } from "../../../../Toasts";

interface ChatChannelViewContainerProps {
	channelDetails?: ChannelDetails;
	autofocusInput?: boolean;
}

export function ChatChannelViewContainer({ channelDetails, autofocusInput }: ChatChannelViewContainerProps) {
	const dispatch = useAppDispatch();
	const toast = useToast();

	const { channelDetailsStatus, chatStatus, nextMessagesStatus } = useChimeChannelStatusSelector(channelDetails?.arn);

	const { chime, sendMessage } = useChimeMessagingContext();

	// Set is high priortity to true to ensure that the messages are loaded as fast as possible in this view
	// as the request for the messages may be loading but be stuck in the queue
	const messages = useChannelMessagesSelector({ channelArn: channelDetails?.arn, isHighPriority: true });
	const memberRecords = useChannelMemberRecords(channelDetails?.arn);

	const messageGroups = useChimeMessageGroups(messages, memberRecords);

	const onDeleteMessage = channelDetails?.isReadOnly
		? undefined
		: (messageId: string) => {
				if (!channelDetails || !chime) {
					return;
				}

				chime.redactMessage(channelDetails.arn, messageId).catch(() => {
					toast.trigger("error", "Failed to delete message");
				});
			};

	const onRetry = () => {
		if (!chime || !channelDetails) return;

		if (chatStatus === LoadingStatus.Error) {
			dispatch(loadInitialMessages({ channelArn: channelDetails.arn, chime, isHighPriority: true }));
		}

		if (channelDetailsStatus === LoadingStatus.Error) {
			dispatch(fetchInitialChannelMemberships({ channelArn: channelDetails.arn, chime }));
		}
	};

	// Combines chat details status and chat status for a more seamless UX in case of either erroring
	const viewChatStatus = useMemo(() => {
		if (channelDetailsStatus === LoadingStatus.Error || chatStatus === LoadingStatus.Error) {
			return LoadingStatus.Error;
		}

		if (channelDetailsStatus === LoadingStatus.Loading || chatStatus === LoadingStatus.Loading) {
			return LoadingStatus.Loading;
		}

		return chatStatus;
	}, [channelDetailsStatus, chatStatus]);

	return (
		<ChatChannelView
			channelArn={channelDetails?.arn}
			messageGroups={messageGroups}
			sendMessage={(message?: JSONContent, metadata?: ChannelMessageMetadata) => {
				if (channelDetails && sendMessage) {
					sendMessage(channelDetails.arn, message, metadata);
				}
			}}
			onDeleteMessage={onDeleteMessage}
			onRetry={onRetry}
			loadNext={() => {
				if (!chime || !channelDetails) return;
				dispatch(loadPreviousMessages({ channelArn: channelDetails.arn, chime }));
			}}
			readonly={channelDetails?.isReadOnly}
			autofocusInput={autofocusInput}
			chatStatus={viewChatStatus}
			nextMessagesStatus={nextMessagesStatus}
		/>
	);
}
