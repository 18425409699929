import { useState, useRef, useEffect, forwardRef, useCallback, ChangeEvent, FocusEvent } from "react";
import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Tooltip, tw, Button, Icon, Spinner, combineRefs } from "@salesdesk/daisy-ui";
import { SearchbarProps } from "../types";

export const Searchbar = forwardRef<HTMLInputElement, SearchbarProps>(
	(
		{
			id,
			value,
			onChange,
			onBlur,
			placeholder = "Search...",
			hasError,
			disabled,
			tooltip,
			isCollapsible = true,
			isClearable = true,
			onCollapsedChange,
			collapsesOnBlur = true,
			wrapperClass,
			maxWidthClass = tw`max-w-[240px]`,
			isLoading,
			comboboxOptions,
			forceCollapse,
			...inputProps
		},
		ref
	) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const openedByUser = useRef(false);

		const [collapsed, setCollapsed] = useState(isCollapsible ? !value : false);

		const updateCollapsed = useCallback(
			(collapsed: boolean) => {
				if (isCollapsible) {
					setCollapsed(collapsed);

					if (onCollapsedChange) {
						onCollapsedChange(collapsed);
					}
				}
			},
			[isCollapsible, onCollapsedChange]
		);

		useEffect(() => {
			if (forceCollapse) {
				updateCollapsed(true);
			}
		}, [forceCollapse, updateCollapsed]);

		useEffect(() => {
			const isSearchInputFocused = inputRef.current === document.activeElement;
			if (value) {
				updateCollapsed(false);
			} else if (!isSearchInputFocused && !value && collapsesOnBlur) {
				updateCollapsed(true);
			}
		}, [value, updateCollapsed, collapsesOnBlur]);

		useEffect(() => {
			if (collapsed || !openedByUser?.current) {
				return;
			}

			openedByUser.current = false;
			setTimeout(() => {
				inputRef.current?.focus();
			}, 60);
		}, [collapsed]);

		const expandSearchbar = () => {
			openedByUser.current = true;
			updateCollapsed(false);
		};

		const clearInput = () => {
			onChange("");
			updateCollapsed(true);
		};

		const sharedProps = {
			id,
			ref: combineRefs([inputRef, ref]),
			className: clsx(
				"placeholder:text-c_text_placeholder grow bg-transparent leading-none",
				disabled ? "text-c_text_disabled select-none" : "text-c_text_primary"
			),
			onBlur: (e: FocusEvent<HTMLInputElement, Element>) => {
				if (!value && collapsesOnBlur) {
					updateCollapsed(true);
				}

				if (onBlur) {
					onBlur(e);
				}
			},
			value,
			onChange: (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
			disabled,
			placeholder,
			...(collapsed ? {} : inputProps),
		};

		const useComboboxInput = comboboxOptions?.useComboboxInput || false;
		const isComboboxOpen = comboboxOptions?.isOpen || false;

		return (
			<div className="relative flex min-h-[38px] min-w-[32px] items-center">
				<Tooltip key={`button-tooltip-${collapsed}`} text={tooltip}>
					<Transition
						key={"button"}
						show={collapsed}
						className={clsx(collapsed && "absolute", "rounded-full transition-transform")}
						enter={openedByUser.current ? tw`duration-100` : tw`duration-0`}
						enterFrom={tw`absolute translate-x-2 scale-75`}
						enterTo={tw`absolute translate-x-0 scale-100`}
					>
						<Button
							id={id}
							startIcon={ICONS.search}
							onClick={expandSearchbar}
							variant="text"
							size="sm"
							disabled={disabled}
						/>
					</Transition>
				</Tooltip>
				<Transition
					key={"searchbar"}
					show={!collapsed}
					className={wrapperClass}
					enter={tw`${openedByUser.current ? "transition-all overflow-hidden duration-75" : "duration-0"}`}
					enterFrom={tw`max-w-0`}
					enterTo={maxWidthClass}
					leave={tw`transition-all ease-out overflow-hidden`}
					leaveFrom={tw`opacity-100 ` + maxWidthClass}
					leaveTo={tw`max-w-0 opacity-0`}
				>
					<div
						className={clsx(
							"text-body-sm focus-within:border-c_action_01 flex w-full min-w-[80px] items-center rounded-full border px-3 py-2",
							useComboboxInput ? "relative z-[150]" : "border-c_border_regular",
							useComboboxInput &&
								(isComboboxOpen
									? "border-c_action_01 rounded-b-none rounded-t-xl border-b-0"
									: "border-c_bg_01 rounded-full transition-all delay-[10ms] duration-0"),
							disabled ? "text-c_icon_disabled bg-c_bg_disabled_02" : "text-c_icon_regular bg-c_bg_01"
						)}
					>
						{isLoading && !collapsed ? (
							<div className="mr-3">
								<Spinner size="xs" />
							</div>
						) : (
							<Icon
								icon={ICONS.search}
								size="sm"
								className={`mr-3 flex transition-opacity duration-[15ms] ease-in ${
									collapsed ? "opacity-0" : "opacity-100"
								}`}
							/>
						)}
						{useComboboxInput && !disabled ? <Combobox.Input {...sharedProps} /> : <input {...sharedProps} />}
						{isClearable && value && (
							<Tooltip text="Clear search" floatingPortal>
								<button
									className="focus:ring-c_action_focus ml-3 rounded-full focus-visible:ring"
									onClick={clearInput}
									tabIndex={value ? undefined : -1}
									disabled={disabled}
									type="button"
								>
									<Icon icon={ICONS.crossCircle} size="sm" className="flex" variant="fill" />
								</button>
							</Tooltip>
						)}
					</div>
				</Transition>
			</div>
		);
	}
);
