import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions";
import { idSchema } from "../../components";

export const objectBaseTypeSchema = asConst({
	...idSchema,
	title: "SDObject BaseType",
	description: appendNotEditable(
		"The id of an SDObject that this one is derived from.  The same as id if it is not derived from another."
	),
});
