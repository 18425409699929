import { useEffect, useState } from "react";

import { useVideoCallLogicContext } from "../../../../../../../VideoCallProvider";
import { CallParticipant } from "../../../../../../../common";
import { useResizableVideoGrid } from "../hooks/useResizableVideoGrid";

const GRID_SPACING = 12;

export function GridView() {
	const { room, callParticipantDetails, currentDominantSpeakerIdentity } = useVideoCallLogicContext();

	// Key used to trigger a the grid to recalculate if state changes (e.g. number of participants changes)
	const [gridResizeTrigger, setGridResizeTrigger] = useState(0);

	const gridContainerRef = useResizableVideoGrid(GRID_SPACING, gridResizeTrigger);

	useEffect(() => {
		setGridResizeTrigger((prev) => prev + 1);
	}, [callParticipantDetails.length]);

	return (
		<div ref={gridContainerRef} className="h-full max-h-full max-w-full px-6">
			<div className="flex h-full max-h-full flex-wrap content-center items-center justify-center align-middle">
				{room && callParticipantDetails.length ? (
					<>
						{callParticipantDetails.map((participantDetails) => {
							return (
								<div key={participantDetails.participant.sid} data-video-grid-element>
									<CallParticipant
										participantDetails={participantDetails}
										dominantSpeaker={currentDominantSpeakerIdentity === participantDetails.participant.identity}
										isLocal={participantDetails.participant === room.localParticipant}
									/>
								</div>
							);
						})}
					</>
				) : null}
			</div>
		</div>
	);
}
