import { HasClaimConfig } from "../components/sections";

export const objectAssociationClaimsSectionProps: HasClaimConfig[] = [
	{
		name: "objectAssociationView",
		label: "View",
		descriptions: {
			hasClaim: "Can view all association definitions",
			hasNotClaim: "Cannot view association definitions",
		},
	},
	{
		name: "objectAssociationEdit",
		label: "Add and Edit",
		dependsOn: ["objectAssociationView"],
		descriptions: {
			hasClaim: "Can add association definitions and edit all association definitions",
			hasNotClaim: "Cannot add or edit association definitions",
		},
	},
	{
		name: "objectAssociationDelete",
		label: "Delete",
		dependsOn: ["objectAssociationView"],
		descriptions: {
			hasClaim: "Can delete all association definitions",
			hasNotClaim: "Cannot delete association definitions",
		},
	},
];
