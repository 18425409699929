import { SDObject, UI_PATHS } from "@salesdesk/salesdesk-schemas";

import { getSDObjectId } from "../../features/objects/utils/objects";
import { OBJECT_DESIGNER_TABS } from "../../features/settings/objectManager/ObjectDesigner/types";
import { WorkspaceLibraryState } from "../../features/workspaces/components/WorkspacePages/WorkspaceLibrary/types";
import { getTenantFromPath } from "../../utils";
import { ERRORS } from "@salesdesk/salesdesk-ui";

export const SALESDESK_MAIN_SITE = "https://www.salesdesk.app/";
export const SALESDESK_GET_STARTED_SITE = "https://start.salesdesk.app/";

/**
 * Generates the appropriate URL path for a route
 */
const DEFAULT_PATHS = {
	...UI_PATHS,
	OBJECT_DESIGNER: generateObjectDesignerPath,
	WORKSPACE_LIBRARY: generateWorkspaceLibraryPath,
};

export function getTenantPrefix(tenantName: string | null): string {
	return tenantName ? `/app/${tenantName}` : "";
}

export function getCurrentTenantPrefix(): string {
	return getTenantPrefix(getTenantFromPath());
}

export function addTenantPrefixToDefaultPaths(): typeof DEFAULT_PATHS {
	const newPaths = {} as typeof DEFAULT_PATHS;
	for (const key in DEFAULT_PATHS) {
		// @ts-expect-error-next-line
		newPaths[key] = (...args) => {
			// @ts-expect-error-next-line
			const path = DEFAULT_PATHS[key](...args);
			return path !== undefined ? `${getCurrentTenantPrefix()}${path}` : undefined;
		};
	}
	return newPaths;
}

export const PATHS = addTenantPrefixToDefaultPaths();

interface ObjectManagerPage {
	tab?: OBJECT_DESIGNER_TABS;
}

function generateObjectDesignerPath(sdObject?: SDObject, params: ObjectManagerPage = {}) {
	let baseURL = `${UI_PATHS.OBJECT_MANAGER()}/${sdObject ? getSDObjectId(sdObject) : "new"}`;

	const { tab = OBJECT_DESIGNER_TABS.settings } = params;
	if (tab) {
		baseURL += `?tab=${tab}`;
	}

	return baseURL;
}

function generateWorkspaceLibraryPath(workspaceId: number, state?: WorkspaceLibraryState) {
	const params = new URLSearchParams();
	for (const [key, value] of Object.entries(state ?? {})) {
		if (value !== undefined) {
			params.set(key, String(value));
		}
	}
	const paramsString = params.toString();
	return `${UI_PATHS.WORKSPACE(workspaceId)}/library${paramsString ? "?" + paramsString : ""}`;
}

export function getNotFoundPath(navigateToWorkspaceError?: boolean) {
	return navigateToWorkspaceError
		? PATHS.WORKSPACES_ERROR()
		: PATHS.ERROR(ERRORS.RESOURCE_NOT_FOUND.code.toLowerCase());
}
