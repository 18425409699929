import { AudioTrack } from "twilio-video";

import { IVideoTrack } from "../../../../../VideoCallProvider";
import { AudioMeter, VideoTrack } from "../../../../../common";
import { useWebPrincipal } from "../../../../../../../../auth";

interface LocalVideoProps {
	videoTrack: IVideoTrack;
	audioTrack: AudioTrack;
}
export function LocalVideo({ videoTrack, audioTrack }: LocalVideoProps) {
	const principal = useWebPrincipal();
	// TODO: Raise toast when audio toggled
	// const [isLocalAudioEnabled] = useLocalAudioToggle();

	return (
		<>
			<VideoTrack track={videoTrack} isLocal={true} />
			<div className="absolute left-0 top-0 z-10 flex h-full w-full  flex-col justify-between">
				<div className="flex items-center justify-end p-4">
					<AudioMeter audioTrack={audioTrack} />
				</div>
				<div className="from-c_brand_dark/40 flex w-full items-center justify-end bg-gradient-to-t to-transparent p-4">
					<div className="text-body-sm text-c_text_inverted drop-shadow-sm">{principal.FullName}</div>
				</div>
			</div>
		</>
	);
}
