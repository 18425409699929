import { asConst } from "json-schema-to-ts";
import { existsAssociationForObjectAssociationClausePropertySchema } from "./existsAssociationForObjectAssociationClausePropertySchema";

export const existsAssociationForObjectAssociationClauseSchema = asConst({
	title: "Exists Association For Object Association",
	description: "Will match any record which has any associations of the given type",
	type: "object",
	additionalProperties: false,
	required: ["existsAssociationForObjectAssociation"],
	properties: {
		existsAssociationForObjectAssociation: existsAssociationForObjectAssociationClausePropertySchema,
	},
	nullable: false,
});
