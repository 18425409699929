export * from "./getWorkspaceChannelId";
export * from "./getTenantUsersDefaultChannelId";
export * from "./getCustomChannelId";
export * from "./getDirectMessageChannelId";
export * from "./ChimeMessageSubscriber";
export * from "./ChimeApi";

export const ChimeMessagingRegion = "eu-central-1";
export const CHIME_CHANNEL_EVERYONE_MENTION_ID = -1;

export const getChimeAppInstanceArnFromChannelArn = (channelArn: string) =>
	channelArn.substring(0, channelArn.indexOf("/channel/"));

export const getChimeAppInstanceArnFromUserArn = (channelArn: string) =>
	channelArn.substring(0, channelArn.indexOf("/user/"));

export const getChimeChannelArn = (appInstanceArn: string, channelId: string) => {
	return `${appInstanceArn}/channel/${channelId}`;
};

export const getChimeChannelIdFromChannelArn = (channelArn: string) =>
	channelArn?.substring(channelArn.indexOf("/channel/") + "/channel/".length);

export const getChimeUserArn = (appInstanceArn: string, userRecordId: number) => {
	return `${appInstanceArn}/user/${userRecordId}`;
};

export const getChimeUserId = (chimeUserArn: string | undefined) => {
	if (chimeUserArn == null) return undefined;
	const userIdString = chimeUserArn.split("/").pop();
	if (userIdString == null) return undefined;
	const userId = parseInt(userIdString);
	if (isNaN(userId)) return undefined;

	return userId;
};

// Chime message dates are typed as dates but are really ISO strings or epoch timestamps
export const getChimeMessageTime = (date?: Date) => (date ? new Date(date).getTime() : undefined);
