import { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import companyEmailValidator from "company-email-validator";
import { fetchAuthSession, signIn, SignInOutput } from "aws-amplify/auth";

import { Button } from "@salesdesk/daisy-ui";

import { EditPasswordField, EditTextField } from "../../fields";
import { FormFieldSet } from "../../forms";
import { LOGIN_EVENT_PENDING_MARKER } from "../../../auth/utils/auth";
import { PATHS, useStableNavigate } from "../../../routes";

const LAST_LOGIN_EMAIL_KEY = "last_login_email";

interface LoginValues {
	username: string;
	password: string;
}

interface LoginFormProps {
	onLoadFinish: () => void;
}

export function LoginForm({ onLoadFinish }: LoginFormProps) {
	const [isLoginLoading, setLoginLoading] = useState(false);
	const [showServerError, setShowServerError] = useState(false);

	const formMethods = useForm<LoginValues>({
		mode: "onChange",
		defaultValues: {
			username: localStorage.getItem(LAST_LOGIN_EMAIL_KEY) ?? undefined,
		},
	});
	const { control, handleSubmit } = formMethods;

	const navigate = useStableNavigate();

	const initializeSession = useCallback(
		async (onLoginResolve?: () => void) => {
			const authSession = await fetchAuthSession();

			if (!authSession.tokens) {
				onLoadFinish();

				if (onLoginResolve) onLoginResolve();

				return;
			}

			const accessToken = authSession.tokens.accessToken.toString();
			localStorage.setItem("jwt", JSON.stringify({ access_token: accessToken }));
			localStorage.setItem(LOGIN_EVENT_PENDING_MARKER, "true"); // To trigger a USER_LOGGED_IN event on the next page load

			if (onLoginResolve) onLoginResolve();

			navigate(PATHS.ROOT());
			onLoadFinish();
		},
		[navigate, onLoadFinish]
	);

	const submitLogin = useCallback<SubmitHandler<LoginValues>>(
		async (signInParams) => {
			setLoginLoading(true);

			let response: SignInOutput;
			try {
				response = await signIn(signInParams);
			} catch (error) {
				setShowServerError(true);
				setLoginLoading(false);
				throw error;
			}

			if (response?.nextStep?.signInStep === "DONE") {
				initializeSession(() => {
					localStorage.setItem(LAST_LOGIN_EMAIL_KEY, signInParams.username);
					setLoginLoading(false);
				});
			}
		},
		[initializeSession]
	);

	useEffect(() => {
		initializeSession();
	}, [initializeSession]);

	return (
		<form onSubmit={handleSubmit(submitLogin)} className="flex flex-col gap-4">
			<FormProvider {...formMethods}>
				<FormFieldSet
					control={control}
					name="username"
					label="Email"
					required
					rules={{
						required: "Email is required",
						validate: (value) => {
							const re =
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

							if (!re.test(String(value).toLowerCase())) return "Use the following format: jd@example.com";

							if (!companyEmailValidator.isCompanyEmail(value)) return "Use an organisation email";
						},
					}}
				>
					{({ field: { value, ...field }, fieldState: { error } }) => (
						<EditTextField
							value={String(value ?? "")}
							placeholder="name@company.com"
							{...field}
							hasError={Boolean(error)}
						/>
					)}
				</FormFieldSet>
				<FormFieldSet
					control={control}
					name="password"
					label="Password"
					required
					rules={{ required: "Password is required" }}
				>
					{({ field: { value, ...field }, fieldState: { error } }) => (
						<EditPasswordField
							value={String(value ?? "")}
							placeholder="Enter password"
							{...field}
							hasError={Boolean(error)}
						/>
					)}
				</FormFieldSet>
				<a href="http://localhost:9081/login" className="text-link-sm underline">
					Forgot your password?
				</a>
				<Button type="submit" variant="primary_dark" fullWidth isLoading={isLoginLoading}>
					Login
				</Button>
				{showServerError ? (
					<span className="text-c_danger_02 text-body-sm text-center">
						Oops! Login failed. Please check your email and password and try again.
					</span>
				) : null}
			</FormProvider>
		</form>
	);
}
