import { asConst } from "json-schema-to-ts";
import { fieldValueSchema } from "./fieldValueSchema";

export const fieldValueGroupSchema = asConst({
	title: "SDRecord Field Value Group",
	description: "A group of SDRecord Field Values",
	type: "object",
	additionalProperties: false,
	required: [...fieldValueSchema.required, "_children"],
	properties: {
		...fieldValueSchema.properties,
		_children: {
			type: "array",
			items: fieldValueSchema,
		},
	},
});
