import { useCallback, useEffect, useState } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { RecordCreateForm, useGetRecordDefaultPath, useRecordCreateForm } from "../../../../../../../../../records";
import {
	DirectedSDObjectAssociation,
	getRecordAssociationPathData,
} from "../../../../../../../../../recordAssociations";
import { Button } from "@salesdesk/daisy-ui";
import { useToast } from "../../../../../../../../../Toasts";
import { getRecordCreatedParams } from "../../../../../../../../../Toasts/utils";
import { useCreateRecordAssociation } from "../../../../../../../../../recordAssociations";

interface CreateCompletableRecordPanelFormProps {
	meetingToTaskAssociation: DirectedSDObjectAssociation;
	meetingRecord: SDRecord;
	sdObject: SDObject;
	onSuccess?: () => void;
}

export function CreateCompletableRecordPanelForm({
	meetingToTaskAssociation,
	meetingRecord,
	sdObject,
	onSuccess,
}: CreateCompletableRecordPanelFormProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [formKey, setFormKey] = useState<number>(0);

	const toast = useToast();
	const { createAssociation } = useCreateRecordAssociation();
	const getRecordDefaultPath = useGetRecordDefaultPath();

	const onRecordCreateSuccess = useCallback(
		(newTask: SDRecord) => {
			createAssociation(getRecordAssociationPathData(meetingRecord, newTask._id, meetingToTaskAssociation))
				.then(() => {
					toast.triggerMessage({
						type: "success",
						messageKey: "record_created",
						messageParams: getRecordCreatedParams(sdObject, newTask, getRecordDefaultPath),
					});

					setIsLoading(false);

					if (onSuccess) {
						onSuccess();
					}
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "record_created" });
				});
		},
		[createAssociation, meetingRecord, meetingToTaskAssociation, toast, sdObject, getRecordDefaultPath, onSuccess]
	);

	const { formProps, isSubmitting } = useRecordCreateForm({
		sdObject,
		createAnotherRecord: true,
		withAssociations: false,
		onRecordCreateSuccess,
		showSuccessToast: false,
	});

	useEffect(() => {
		if (isSubmitting) {
			setIsLoading(isSubmitting);
		}
	}, [isSubmitting]);

	const { id } = formProps;
	const recordFormProps = { ...formProps, disabled: isLoading };

	return (
		<div className="relative flex flex-col gap-4">
			<h2 className="text-label">New {sdObject._displayName}</h2>
			<span className="text-body-sm text-c_text_secondary">Please fill in all required fields below</span>
			<div className="-mb-16">
				<RecordCreateForm key={formKey} formProps={recordFormProps} objectName={sdObject._displayName} />
			</div>
			<div className="bg-c_bg_01 sticky bottom-0 mt-16 flex justify-end gap-3 pb-4 pt-4 align-middle">
				<Button variant="secondary" disabled={isLoading} onClick={() => setFormKey(formKey + 1)}>
					Cancel
				</Button>
				<Button form={id} type="submit" isLoading={isLoading}>
					Create
				</Button>
			</div>
		</div>
	);
}
