import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mEmailFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TEXT.name];
	static defaultComponentType: FieldComponentName = mEmailFieldDef.supportedComponentTypes[0];

	static FORMAT_RULE = `jd@example.com`;
	static FORMAT_RULE_LONG = `Use the following format: ${mEmailFieldDef.FORMAT_RULE}.`;

	constructor(id: string | number) {
		super(id);
		this._componentType = mEmailFieldDef.defaultComponentType;
		this._toolTip = mEmailFieldDef.FORMAT_RULE_LONG;
		this._formatDescription = mEmailFieldDef.FORMAT_RULE;
		this._icon = FIELD_TYPES.EMAIL.icon;
		this._supportsDefaultValue = true;
		this._supportsUniqueValue = true;
	}

	override get type() {
		return FIELD_TYPES.EMAIL.name;
	}

	override get supportedComponentTypes() {
		return mEmailFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value) && !this.emailIsValid(value)) {
			return mEmailFieldDef.FORMAT_RULE_LONG;
		}

		return super.validate(value, isTemplate);
	}

	emailIsValid(email: string) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
}
