import { forwardRef } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { BaseSelectButtonProps } from "../../Select";
import { Icon } from "@salesdesk/daisy-ui";

export const TextOptionSelectButton = forwardRef<HTMLButtonElement, BaseSelectButtonProps>(
	({ selectedOption, disabled, ...restProps }, ref) => {
		if (!selectedOption) return null;
		return (
			<button
				ref={ref}
				className={clsx(
					disabled && "cursor-not-allowed",
					"focus-visible:ring-c_action_focus flex select-none items-center gap-1 rounded-sm p-1 focus-visible:ring"
				)}
				{...restProps}
			>
				<span className="text-label-sm">{selectedOption.name}</span>
				<Icon
					className={clsx("flex", disabled ? "text-c_icon_disabled" : "text-c_icon_regular")}
					size="sm"
					icon={ICONS.caretUpDown}
				/>
			</button>
		);
	}
);
