import { mObjectDef } from "../object_def";

export class mCustomObjectDef extends mObjectDef {
	
	static CLASS_NAME = "mCustomObjectDef";

	constructor(id, ownerId) {
		super(id, ownerId);

		super.type = mObjectDef.CREATION_TYPE.USER;
		super.className = mCustomObjectDef.CLASS_NAME;
		super.searchable = true;
		super.supportsUserCreation = true;
		super.supportsUserView = true;

		super.commentsSupported = true;
		super.historySupported = true;

		super.name = "New Object";
		super.pluralName = "New Objects";

		super.icon = "fa-cube";
		this.color = "#66ccff";

		super._editable = true;
	}
}
