import { differenceInMinutes } from "date-fns";
import { TrackPublication } from "twilio-video";

import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { getSDRecordSingleOptionSystemFieldValueByFieldName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

export const VIDEO_CALL_CONTAINER_ID = "video-call-container";

export function isScreenShareTrack(trackPublication: TrackPublication) {
	return trackPublication.trackName.includes("screen");
}

export function isHuddleMeeting(meetingObject: SDObject, meetingRecord: SDRecord) {
	return (
		meetingObject._id === mInteractionMeetingDef.ID &&
		getSDRecordSingleOptionSystemFieldValueByFieldName(
			meetingObject,
			meetingRecord,
			mInteractionMeetingDef.MEETING_TYPE_FIELD_NAME
		) === mInteractionMeetingDef.MEETING_TYPES.HUDDLE
	);
}

export enum HuddleStatus {
	ACTIVE = "active",
	IN_PROGRESS = "in-progress",
	INACTIVE = "inactive",
}

export const SCHEDULED_HUDDLE_EXPIRY_MINUTES = 10;

export function getHuddleMeetingDetails(meetingObject: SDObject, meetingRecord: SDRecord) {
	const isHuddle = isHuddleMeeting(meetingObject, meetingRecord);

	if (!isHuddle) {
		return { isHuddle };
	}

	if (isHuddle) {
		const meetingStatus = getSDRecordSingleOptionSystemFieldValueByFieldName(
			meetingObject,
			meetingRecord,
			mInteractionMeetingDef.STATUS_FIELD_NAME
		);

		const minutesSinceCreated = differenceInMinutes(new Date(), new Date(meetingRecord.createdAt));

		// If huddle is scheduled and created within the past 10 mins, it's considered scheduled.
		if (
			meetingStatus === mInteractionMeetingDef.STATUSES.SCHEDULED &&
			minutesSinceCreated <= SCHEDULED_HUDDLE_EXPIRY_MINUTES
		) {
			return {
				isHuddle,
				huddleStatus: HuddleStatus.ACTIVE,
			};
		} else if (meetingStatus === mInteractionMeetingDef.STATUSES.IN_PROGRESS) {
			return {
				isHuddle,
				huddleStatus: HuddleStatus.IN_PROGRESS,
			};
		}

		return {
			isHuddle,
			huddleStatus: HuddleStatus.INACTIVE,
		};
	}

	return { isHuddle };
}
