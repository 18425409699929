import { SDApiKey } from "@salesdesk/salesdesk-schemas";
import { DeleteConfirmationDialog } from "../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { useDeleteApiKeyMutation } from "../api/apiKeysApi";
import { useToast } from "../../../Toasts";

interface DeleteApiKeyProps {
	apiKey: SDApiKey;
	onClose: () => void;
}

export function DeleteApiKeyDialog({ apiKey, onClose }: DeleteApiKeyProps) {
	const [deleteApiKey, { isLoading: isDeleting }] = useDeleteApiKeyMutation();
	const toast = useToast();

	const onConfirm = () => {
		deleteApiKey({
			id: apiKey.apiKeyId,
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "api_key_deleted" });
				onClose();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "api_key_deleted" });
			});
	};

	return (
		<DeleteConfirmationDialog
			open
			title="Delete API key"
			description="Any applications or integrations using this key will lose access. This action cannot be undone"
			onConfirm={onConfirm}
			isDeleting={isDeleting}
			onDismiss={onClose}
			destructivePrompt="Please confirm that you want to permanently delete this key."
			destructiveLabel="Delete permanently"
			width={540}
		>
			<div className="text-c_text_secondary border-c_border_regular flex flex-col gap-3 border p-3">
				<div className="text-label">API key:</div>
				<div>{apiKey.name}</div>
				<div>{apiKey.apiKeyId}</div>
			</div>
		</DeleteConfirmationDialog>
	);
}
