import { ParticipantDetails } from "../../../Participants";

import { useHoldingPageContext } from "../../hooks";
import { HoldingPageDetailsSection } from "./HoldingPageDetailsSection";
import { AlertBubble } from "@salesdesk/daisy-ui";

export function HoldingPageParticipants() {
	const { participants } = useHoldingPageContext();

	if (!participants) {
		return null;
	}

	return (
		<HoldingPageDetailsSection
			title={
				<div className="flex items-center gap-2">
					Participants <AlertBubble alertCount={participants.length} variant="primary" />
				</div>
			}
			initialSection={true}
		>
			<ParticipantDetails participantUsers={participants} />
		</HoldingPageDetailsSection>
	);
}
