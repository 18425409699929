import { asConst } from "json-schema-to-ts";
import { queryClausesSchema } from "./queryClauses";

export const querySchema = asConst({
	title: "Search Query",
	description: "A bool logic container for all query clauses",
	type: "object",
	additionalProperties: false,
	minProperties: 1,
	properties: {
		and: { type: "array", items: queryClausesSchema },
		not: { type: "array", items: queryClausesSchema },
		or: { type: "array", items: queryClausesSchema },
	},
	nullable: false,
});
