import { FIELD_TYPES, FieldTypeName, isNumericField } from "@salesdesk/salesdesk-model";
import { TextInputVariant } from "@salesdesk/daisy-ui";

export function getTextInputVariant(fieldType: FieldTypeName) {
	switch (fieldType) {
		case FIELD_TYPES.PASSWORD.name:
			return TextInputVariant.PASSWORD;
		case FIELD_TYPES.EMAIL.name:
			return TextInputVariant.EMAIL;
		case FIELD_TYPES.URL.name:
			return TextInputVariant.URL;
		case FIELD_TYPES.PHONE_NUMBER.name:
			return TextInputVariant.PHONE;
		case FIELD_TYPES.PROBABILITY.name:
			return TextInputVariant.PROBABILITY;
		case FIELD_TYPES.PROGRESS.name:
			return TextInputVariant.PROGRESS;
		case FIELD_TYPES.CURRENCY_VALUE.name:
			return TextInputVariant.CURRENCY;
		default:
			return isNumericField(fieldType) ? TextInputVariant.NUMERIC : TextInputVariant.TEXT;
	}
}

export function generateLinkForTextInputVariant(variant: TextInputVariant, value: string) {
	switch (variant) {
		case TextInputVariant.EMAIL:
			return `mailto:${value}`;
		case TextInputVariant.PHONE:
			return `tel:${value}`;
		default:
			return value;
	}
}
