import {
	FieldDefFactory,
	FIELD_CREATION_TYPE,
	FIELD_TYPES,
	mFieldDef,
	SORTABLE_FIELD_TYPES,
} from "@salesdesk/salesdesk-model";
import { Field, FieldValue } from "@salesdesk/salesdesk-schemas";
import { RECORD_COMBINED_NAME_ID } from "../../ObjectBoard/types";

export function isSingleOptionField(field: Field) {
	return field._optionValues?.length;
}

export function isTimeField(field: Field) {
	return (
		field._type === FIELD_TYPES.DATE.name ||
		field._type === FIELD_TYPES.DATE_TIME.name ||
		field._type === FIELD_TYPES.TIME_RANGE.name
	);
}

export function isFileField(field: Field) {
	const fieldType = field._type;
	return ["file", "image", "video", "pdf"].includes(fieldType);
}

export function isMediaField(field: Field) {
	return isFileField(field) || field._type === FIELD_TYPES.PROFILE_PHOTO.name || field._type === FIELD_TYPES.LOGO.name;
}

export function isSystemField(field: Field) {
	return field._creationType === FIELD_CREATION_TYPE.SYSTEM;
}

export function isFieldSortable(field: Field) {
	return SORTABLE_FIELD_TYPES.some((type) => type === field._type) && field._name !== RECORD_COMBINED_NAME_ID;
}

export function convertFieldToFieldDef(field: Field) {
	return FieldDefFactory.newInstance(field._type).marshall(field, null);
}

export function convertFieldDefToField(fieldDef: mFieldDef, skipValidation?: boolean) {
	return fieldDef.unmarshall(skipValidation) as Field;
}

export function isAssetCombinedNameValue(value: FieldValue) {
	return (
		typeof value === "object" &&
		value !== null &&
		(!("fileId" in value) || typeof value.fileId === "number" || value.fileId === undefined) &&
		(!("displayName" in value) || typeof value.displayName === "string" || value.displayName === undefined)
	);
}
