import React, { HTMLProps } from "react";
import { useMergeRefs } from "@floating-ui/react";
import { isForwardRef } from "react-is";

import { usePopoverContext } from "./Popover";

import { PopoverTriggerContext } from "@salesdesk/daisy-ui";

interface PopoverTriggerProps {
	children?: React.ReactNode;
}

export const PopoverTrigger = React.forwardRef<HTMLElement, HTMLProps<HTMLElement> & PopoverTriggerProps>(
	({ children, ...props }, propRef) => {
		const context = usePopoverContext();
		const childrenRef = children ? (children as any)?.ref : null;
		const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

		// Forward the popover open state to the PopoverTriggerContext
		const { open } = usePopoverContext();

		let triggerChild;

		// Checks if children is a valid React element and if it can support a ref. If the type of the children is a string,
		// we know it's a DOM element. Otherwise we check if its a react component with a forwarded ref.
		//
		// Using 'isForwardRef' from the official 'react-is' library to check for forwarded refs since it requires inspecting
		// internal React values which aren't guaranteed to remain consistent between React versions.
		if (React.isValidElement(children) && (typeof children.type === "string" || isForwardRef(children))) {
			triggerChild = React.cloneElement(
				children,
				context.getReferenceProps({
					ref,
					...props,
					...children.props,
					"data-state": context.open ? "open" : "closed",
				})
			);
		} else {
			triggerChild = (
				<div
					{...context.getReferenceProps({
						ref,
						...props,
					})}
					data-state={context.open ? "open" : "closed"}
				>
					{children}
				</div>
			);
		}

		return <PopoverTriggerContext.Provider value={{ open }}>{triggerChild}</PopoverTriggerContext.Provider>;
	}
);
