import { CommentResolvedParams } from "@salesdesk/salesdesk-schemas";

import { useEventContext } from "../../../../hooks/useEventContext";
import { LazyCommentPayload } from "./LazyCommentPayload";

export function CommentResolvedPayload() {
	const { event } = useEventContext();
	const { commentId } = event.params as CommentResolvedParams;

	return <LazyCommentPayload recordId={event.record_id} commentId={commentId} />;
}
