import { asConst } from "json-schema-to-ts";
import { objectIdSchema } from "./properties";
import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";

export const associationEndpointSchema = asConst({
	type: "object",
	additionalProperties: false,
	required: ["id", "label", "multiplicity"],
	properties: {
		id: objectIdSchema,
		label: {
			type: "string",
		},
		multiplicity: {
			type: "string",
			enum: Object.values(AssociationMultiplicity),
		},
		child: {
			type: "boolean",
		},
	},
});
