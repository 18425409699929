import clsx from "clsx";

import { Button, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { useDocumentCommentsContext } from "../../DocumentComments";

interface DocumentDetailsBarProps {
	lastModified?: string;
	isSaving?: boolean;
	inWorkspaceContext?: boolean;
}

export function DocumentDetailsBar({ lastModified, isSaving, inWorkspaceContext }: DocumentDetailsBarProps) {
	const { isCommentsOpen, setCommentsOpen } = useDocumentCommentsContext();

	return (
		<div
			className={clsx(
				"text-body-sm text-c_text_placeholder flex items-center justify-end gap-2",
				!inWorkspaceContext && "mx-6"
			)}
		>
			<Icon className="flex" icon={ICONS.clock} size="sm" />
			<span className="pt-px">Last edited {lastModified}</span>
			<div className="flex w-20 items-center justify-center gap-2">
				<Icon
					icon={isSaving ? ICONS.progress : ICONS.checkCircle}
					size="sm"
					className={clsx(isSaving && "rotate-45", "flex")}
				/>
				<span className="pt-px">{isSaving ? "Saving..." : "Saved"}</span>
			</div>
			{setCommentsOpen ? (
				<Button
					startIcon={ICONS.chatText}
					size="sm"
					variant={isCommentsOpen ? "secondary" : "text"}
					onClick={() => setCommentsOpen((prev) => !prev)}
				/>
			) : null}
		</div>
	);
}
