import { PropsWithChildren, ReactNode, useRef } from "react";
import clsx from "clsx";

import { Disclosure } from "@headlessui/react";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

export interface MenuAccordionProps {
	title: string;
	helperText?: ReactNode;
	defaultOpen?: boolean;
	onFocus?: () => void;
	unmount?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function MenuAccordion({
	title,
	helperText,
	defaultOpen,
	children,
	onFocus,
	unmount = true,
	onOpenChange,
}: PropsWithChildren<MenuAccordionProps>) {
	const isOpenRef = useRef(defaultOpen ?? false);

	return (
		<div className="flex flex-col">
			<Disclosure defaultOpen={defaultOpen}>
				{({ open }) => {
					// The hack below exists because Headless UI doesn't provide a way to listen to open state changes
					if (isOpenRef.current !== open && onOpenChange) {
						isOpenRef.current = open;
						// setTimeout is used to avoid setting a state during the render phase
						setTimeout(() => onOpenChange(open));
					}
					return (
						<>
							<Disclosure.Button className="group m-0.5 flex items-center justify-between px-5 py-3" onFocus={onFocus}>
								<div className="flex flex-shrink-0 items-center gap-2 truncate ">
									<Icon
										className={clsx(
											"text-c_action_01 flex transform transition",
											"group-focus-visible:ring-c_action_focus group-focus-visible:rounded-full group-focus-visible:ring",
											open ? "rotate-90" : "rotate-0"
										)}
										icon={ICONS.caretRight}
									/>
									<div className="text-c_action_01 text-label-nav truncate">{title}</div>
								</div>
								{typeof helperText === "string" ? (
									<div className="text-c_text_secondary ml-3 truncate">{helperText}</div>
								) : (
									helperText
								)}
							</Disclosure.Button>
							<Disclosure.Panel unmount={unmount} className="pl-5">
								{children}
							</Disclosure.Panel>
						</>
					);
				}}
			</Disclosure>
		</div>
	);
}
