export const videoCallEventTypes = [
	"joined_meeting",
	"left_meeting",
	"chat_received",
	"microphone_enabled",
	"microphone_disabled",
	"video_enabled",
	"video_disabled",
	"start_recording",
	"stop_recording",
	"start_screenshare",
	"stop_screenshare",
	"start_fileshare",
	"stop_fileshare",
	"received_message",
] as const;
export type VideoCallEventType = (typeof videoCallEventTypes)[number];

export interface VideoCallEvent {
	id: number;
	createdAt?: number;
	type: VideoCallEventType;
	timeoutId?: NodeJS.Timeout;
	participantName?: string;
	dismissed?: boolean;
	played?: boolean;
}

export const VIDEO_CALL_EVENT_TIMER = 2400;
export const VIDEO_CALL_EVENT_TOAST_OUT_ANIMATION_DELAY = 150;
