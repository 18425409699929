import { ColorSwatch } from "./ColorSwatch";

interface ColorPaletteProps {
	value: string | null;
	paletteColors: string[];
	onClick: (colorValue: string | null) => void;
}

export function ColorPalette({ value, paletteColors, onClick }: ColorPaletteProps) {
	return (
		<div className="grid grid-cols-5 grid-rows-2 gap-3">
			<ColorSwatch onClick={() => onClick(null)} />
			{paletteColors.map((color) => (
				<ColorSwatch active={value === color} key={color} value={color} onClick={() => onClick(color)} />
			))}
		</div>
	);
}
