import { forwardRef } from "react";

import { RichTextInput, RichTextInputProps } from "../../../../../inputs";
import { EditFieldProps } from "../../../../types";

type EditRichTextFieldProps = RichTextInputProps & Omit<EditFieldProps, "onChange" | "value">;

export const EditRichTextField = forwardRef<HTMLDivElement, EditRichTextFieldProps>(
	({ inputRef, ...richTextInputProps }, ref) => {
		return <RichTextInput ref={ref} {...richTextInputProps} />;
	}
);
