import { Button } from "@salesdesk/daisy-ui";
import { MenuItem, PopoverMenu } from "../../../../menu";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useShowFloatingMenu } from "../../../../RecordRowControls/useShowFloatingMenu";
import { RefObject } from "react";
import clsx from "clsx";

interface RecordModalRowControlsProps {
	tableRef: RefObject<HTMLDivElement>;
	scrollContainerRef?: RefObject<HTMLDivElement>;
	menuItems: MenuItem[];
}

export function RecordModalRowControls({ tableRef, scrollContainerRef, menuItems }: RecordModalRowControlsProps) {
	const { showFloatingMenu, floatingMenuRef, rowEndRef } = useShowFloatingMenu(tableRef, scrollContainerRef);

	if (menuItems.length === 0) {
		return null;
	}
	let contents;
	if (menuItems.length === 1 && menuItems[0].type === "button") {
		contents = <Button variant="text" startIcon={menuItems[0].icon} onClick={menuItems[0].onClick} size="sm" />;
	} else {
		contents = (
			<PopoverMenu menuContents={menuItems}>
				<Button startIcon={ICONS.verticalDots} variant="text" size="sm" />
			</PopoverMenu>
		);
	}

	return (
		<div className="ml-auto w-8" onClick={(e) => e.stopPropagation()}>
			<div ref={rowEndRef} className={clsx(showFloatingMenu ? "size-8" : "-ml-1")} />
			<div
				ref={floatingMenuRef}
				key={`row-menu-${showFloatingMenu ? "floating" : "default"}`}
				className={showFloatingMenu ? "absolute top-0 flex h-full items-center" : ""}
			>
				<div
					className={
						showFloatingMenu
							? clsx(
									"shadow-tooltip bg-c_bg_01 border-c_border_regular rounded-full border opacity-0 transition-opacity",
									"group-focus-within/table-row:opacity-95 group-hover/table-row:opacity-95",
									`has-[[data-state="open"]]:opacity-95`
								)
							: ""
					}
				>
					{contents}
				</div>
			</div>
		</div>
	);
}
