import { useEffect } from "react";

import { deleteBrowserNotification } from "../store/browserNotificationsSlice";
import { store, useAppSelector } from "../../../store/store";
import { useStableNavigate } from "../../../routes";

export function BrowserNotifications() {
	const browserNotifications = useAppSelector((state) => state.browserNotifications.notifications);
	const navigate = useStableNavigate();

	useEffect(() => {
		const sendNotifications = async () => {
			if (!browserNotifications.length) return;

			if ("Notification" in window && Notification.permission !== "denied") {
				if (Notification.permission !== "granted") await Notification.requestPermission();
				if (Notification.permission === "granted") {
					for (const browserNotification of browserNotifications) {
						const message = browserNotification.message;
						const notification = new Notification(message.title, {
							// To prevent multiple notifications if multiple browser tabs open
							tag: message.tag,
							body: message.body,
							icon: "/static/images/sd-favicon.ico",
						});
						notification.onclick = () => {
							// Removes the notification from the list
							notification.close();
							window.parent.focus();
							if (message.path != null) navigate(message.path);
						};
					}
				}
			}
			store.dispatch(deleteBrowserNotification(browserNotifications.map((notification) => notification.id)));
		};
		sendNotifications();
	}, [browserNotifications, navigate]);
	return null;
}
