import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";

export function SkeletonCompactRecordPreview() {
	return (
		<div className="flex w-full items-center gap-2">
			<Skeleton className="flex size-7 shrink-0 rounded-full" />
			<div className="flex w-full flex-col gap-2">
				<Skeleton className="h-3 w-full max-w-[300px]" />
				<Skeleton className="h-3 w-24" />
			</div>
		</div>
	);
}
