import clsx from "clsx";
import { RuleElementType, RuleValue } from "../../../types";
import { Tooltip } from "@salesdesk/daisy-ui";

interface RuleElementProps {
	type?: RuleElementType;
	values: RuleValue[];
	separatorText?: string;
}

export function RuleElement({ type, values, separatorText }: RuleElementProps) {
	return (
		<div className="text-label py-1">
			{type ? <span className="mr-4">{type}</span> : null}
			<div className="inline">
				{values.flatMap((action, index) => {
					const text = action.value ?? action.placeholder;
					return [
						separatorText && index !== 0 ? (
							<span key={text + "_separator"} className="mr-4">
								{separatorText}
							</span>
						) : (
							[]
						),
						<Tooltip key={text} text={text} showOnTruncated>
							<button
								onClick={action.onClick}
								type="button"
								className={clsx(
									"text-link mr-4 truncate underline",
									!action.disabled && "hover:text-c_action_03",
									action.value && "max-w-65",
									action.active ? "text-c_text_link" : action.value ? "text-c_text_primary" : "text-c_text_placeholder",
									!action.onClick && "cursor-default"
								)}
							>
								{text}
							</button>
						</Tooltip>,
					];
				})}
			</div>
		</div>
	);
}
