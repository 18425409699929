import { CreateRecordDialogContext } from "../../hooks/useCreateRecordDialogContext";
import { RecordCreateDialog } from "./RecordCreateDialog";
import { RecordCreateDialogProps } from "../../types";
import { PropsWithChildren, useState } from "react";

export function RecordCreateDialogProvider({ children }: PropsWithChildren) {
	const [modalProps, setModalProps] = useState<Omit<RecordCreateDialogProps, "open"> | undefined>();
	const { onOpenChange, ...restProps } = modalProps ?? ({} as RecordCreateDialogProps);

	return (
		<CreateRecordDialogContext.Provider
			value={{
				openModal: setModalProps,
			}}
		>
			{children}
			{modalProps?.sdObject ? (
				<RecordCreateDialog
					open
					onOpenChange={(isOpen) => {
						if (!isOpen) setModalProps(undefined);
						onOpenChange?.(isOpen);
					}}
					{...restProps}
				/>
			) : null}
		</CreateRecordDialogContext.Provider>
	);
}
