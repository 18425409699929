import { Icon } from "@salesdesk/daisy-ui";
import { DisplayPlaceholderField } from "../DisplayPlaceholderField";

interface DisplayIconPickerFieldProps {
	value: string;
}

export function DisplayIconPickerField({ value }: DisplayIconPickerFieldProps) {
	if (!value) {
		return <DisplayPlaceholderField />;
	}

	return (
		<Icon
			size="sm"
			icon={value}
			className="bg-c_bg_02 text-c_icon_regular flex h-10 w-10 flex-col items-center justify-center rounded-full"
		/>
	);
}
