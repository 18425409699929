import { useCallback, useEffect, useState } from "react";
import { useQueryParams, QueryParamConfig, DecodedValueMap } from "use-query-params";

export interface UseURLStateParams<T extends object> {
	defaultState: T;
	paramConfigMap: {
		[P in keyof T]: QueryParamConfig<any, any>;
	};
	valueValidityMap: Record<keyof T, (value: unknown) => boolean>;
}
export interface URLStateHookReturnType<T> {
	urlState: T;
	propOnChange: (statePropName: keyof T, value: T[keyof T]) => void;
	resetState: () => void;
}

export function useURLState<T extends object>({
	defaultState,
	paramConfigMap,
	valueValidityMap,
}: UseURLStateParams<T>): URLStateHookReturnType<T> {
	const [urlState, setUrlState] = useState(defaultState);
	const [queryParams, setQueryParams] = useQueryParams(paramConfigMap);

	useEffect(() => {
		let newState: Partial<T> | null = null;

		// Loops through all the keys in the default url state
		Object.keys(defaultState).forEach((key) => {
			const stateProp = key as keyof T;

			const queryValue = queryParams[stateProp];

			// If the property is the same in the URL and record detail view state, no need to update
			if (JSON.stringify(queryValue) === JSON.stringify(urlState[stateProp])) {
				return;
			}

			// Ensures the value is valid for the url state prop, if not use the default value
			const validQueryValue = valueValidityMap[stateProp](queryValue);
			const newValueState = {
				[stateProp]: validQueryValue ? queryValue : defaultState[stateProp],
			} as Partial<T>;

			// Update the url query params if it was invalid to the default value set above
			if (!validQueryValue) {
				setQueryParams(newValueState as Partial<DecodedValueMap<typeof paramConfigMap>>);
			}

			newState = newState ? { ...newState, ...newValueState } : newValueState;
		});

		if (newState) {
			setUrlState({ ...urlState, ...(newState as Partial<T>) });
		}
	}, [queryParams, setQueryParams, defaultState, valueValidityMap, urlState]);

	const propOnChange = useCallback(
		(statePropName: keyof T, value: T[keyof T], isPushIn = false) => {
			if (!(statePropName in defaultState)) {
				return;
			}
			setQueryParams(
				{ [statePropName]: value } as Partial<DecodedValueMap<typeof paramConfigMap>>,
				isPushIn ? "pushIn" : "replaceIn"
			);
		},
		[setQueryParams, defaultState]
	);

	const resetState = useCallback(() => {
		setUrlState(defaultState);
	}, [defaultState]);

	return { urlState, propOnChange, resetState };
}
