import { asConst } from "json-schema-to-ts";
import { booleanSchema } from "../../../components";
import { appendEditableForCustom } from "../../../functions";

export const fieldDateOnlySchema = asConst({
	...booleanSchema,
	title: "SDObject TimeRange Field Includes Time",
	description: appendEditableForCustom(
		"Required byt the Time Range field to indicate if time should be included as well as date"
	),
});
