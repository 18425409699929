import { useState } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { generateCreateRecordRequest, useCreateRecordMutation } from "../../../../records";
import {
	getRecordAssociationRequestData,
	MAX_BATCH_ASSOCIATION_SIZE,
	useBatchCreateRecordAssociationsMutation,
} from "../../../../recordAssociations";
import { useToast } from "../../../../Toasts";
import { AssociationRecordsToLinkMap } from "../types";
import { countPromiseResults } from "../../../../../utils";
import { PATHS } from "../../../../../routes";
import { mSalesDeskUserDef } from "@salesdesk/salesdesk-model";
import { useBatchShareRecordsMutation } from "../../../api/workspacesApi";

export function useCreateWorkspace(onSuccess: () => void) {
	const [isCreating, setIsCreating] = useState(false);

	const toast = useToast();

	const [createRecord] = useCreateRecordMutation();
	const [batchLinkAssociations] = useBatchCreateRecordAssociationsMutation();
	const [batchShareRecords] = useBatchShareRecordsMutation();

	const onWorkspaceCreation = (newWorkspace: SDRecord, workspaceObject: SDObject) => {
		toast.triggerMessage({
			type: "success",
			messageKey: "workspace_created",
			messageParams: {
				link: {
					type: "link",
					to: PATHS.WORKSPACE_OVERVIEW(newWorkspace._id),
					label: `Enter ${workspaceObject._displayName}`,
				},
			},
		});

		setIsCreating(false);
		onSuccess();
	};

	const createWorkspace = (
		associationRecordsToLinkMap: AssociationRecordsToLinkMap,
		workspaceData?: Record<string, any>,
		workspaceObject?: SDObject
	) => {
		if (!workspaceData || !workspaceObject) {
			return;
		}

		setIsCreating(true);

		return createRecord({ recordCreateRequest: generateCreateRecordRequest(workspaceData, workspaceObject) })
			.unwrap()
			.then((newWorkspace: SDRecord) => {
				const internalUsers: SDRecord[] = [];
				const associations = Object.values(associationRecordsToLinkMap).flatMap(({ association, records }) =>
					records.map((record: SDRecord) => {
						if (association.connectedObject.id === mSalesDeskUserDef.ID) {
							internalUsers.push(record);
						}

						return getRecordAssociationRequestData(newWorkspace, record._id, association);
					})
				);

				const chunks = chunk(associations, MAX_BATCH_ASSOCIATION_SIZE);

				Promise.allSettled(chunks.map((chunk) => batchLinkAssociations({ associations: chunk }).unwrap())).then(
					(linkedAssociationsResults) => {
						const [successes, fails] = countPromiseResults(linkedAssociationsResults, chunks);

						toast.triggerMessage({
							type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
							messageKey: "batch_record_associations_created",
							messageParams: {
								associations: `${successes} ${pluralizeWithS("association", successes)}`,
								total: String(fails + successes),
							},
						});

						batchShareRecords({ workspace: newWorkspace, records: internalUsers }).then(() => {
							onWorkspaceCreation(newWorkspace, workspaceObject);
						});
					}
				);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "workspace_created" });
				setIsCreating(false);
			});
	};

	return { createWorkspace, isCreating };
}
