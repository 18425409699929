import { useState } from "react";
import { Editor } from "@tiptap/core";

import { Button, Tooltip } from "@salesdesk/daisy-ui";

import { BubbleMenuCommentPopover, useDocumentCommentsContext } from "../../../../DocumentComments";
import { isMacOS } from "../../../../../../../utils";
import { LIST_ACTIONS, TOGGLE_ACTIONS } from "../utils";

import { TextStyleSelect } from "./TextStyleSelect";
import { TextAlignmentSelect } from "./TextAlignmentSelect";
import { TextColorPopover } from "./TextColorPopover";
import { BubbleMenuDivider } from "./BubbleMenuDivider";

interface ParagraphBubbleMenuProps {
	editor: Editor;
}

export function DefaultBubbleMenu({ editor }: ParagraphBubbleMenuProps) {
	const [toggleActions] = useState(() => TOGGLE_ACTIONS(isMacOS() ? "⌘" : "Ctrl"));
	const { documentRecord } = useDocumentCommentsContext();

	return (
		<>
			<TextStyleSelect editor={editor} />
			<BubbleMenuDivider />
			{toggleActions.map((action) => (
				<Tooltip key={action.id} placement="top" text={action.tooltip} noWrap preventFlip>
					<Button
						size="xs"
						variant="text"
						active={action.isActive(editor)}
						onClick={action.action(editor)}
						startIcon={action.icon}
					/>
				</Tooltip>
			))}
			<TextAlignmentSelect editor={editor} />
			{LIST_ACTIONS.map((action) => (
				<Tooltip key={action.id} placement="top" text={action.tooltip} noWrap preventFlip>
					<Button
						size="xs"
						variant="text"
						active={action.isActive(editor)}
						onClick={action.action(editor)}
						startIcon={action.icon}
					/>
				</Tooltip>
			))}
			<BubbleMenuDivider />
			<TextColorPopover editor={editor} />
			{documentRecord ? (
				<>
					<BubbleMenuDivider />
					<BubbleMenuCommentPopover editor={editor} />
				</>
			) : null}
		</>
	);
}
