import { OwnerTypeClaimSectionRow, OwnerTypeClaimSectionRowProps } from "./OwnerTypeClaimSectionRow";
import { useRoleFormContext } from "../../hooks/useRoleFormContext";
import { GetKeysOfPropertyType } from "../../../../../../../../utils/typescript-utils";
import { OwnerType, RoleFormData } from "../../types";

export interface OwnerTypeClaimConfig extends OwnerTypeClaimSectionRowProps {
	dependsOn?: OwnerTypeClaimSectionRowProps["name"][];
}

interface OwnerTypeClaimsSectionProps {
	claimConfig: OwnerTypeClaimConfig[];
}

export function OwnerTypeClaimsSection({ claimConfig }: OwnerTypeClaimsSectionProps) {
	const { getValues, setValue } = useRoleFormContext();

	const handleHasClaimChange = (name: GetKeysOfPropertyType<RoleFormData, OwnerType>, changedValue: OwnerType) => {
		const changedProp = claimConfig.find((p) => p.name === name);
		changedProp?.dependsOn?.forEach((dependsOnName) => {
			const dependsOnValue = getValues(dependsOnName);
			if (
				(changedValue === OwnerType.All && dependsOnValue !== OwnerType.All) ||
				(changedValue === OwnerType.Owner && dependsOnValue === OwnerType.None)
			)
				setValue(dependsOnName, changedValue);
		});

		const dependentProps = claimConfig.filter((p) => p.dependsOn?.includes(name));

		dependentProps?.forEach((dependentProp) => {
			const dependentValue = getValues(dependentProp.name);
			if (
				(changedValue === OwnerType.Owner && dependentValue === OwnerType.All) ||
				(changedValue === OwnerType.None && dependentValue !== OwnerType.None)
			)
				setValue(dependentProp.name, changedValue);
		});
	};

	return claimConfig.map((props) => (
		<OwnerTypeClaimSectionRow key={props.name} {...props} onChange={handleHasClaimChange} />
	));
}
