import { useRef, useState, useCallback, FormEvent, useEffect } from "react";
import { JSONContent } from "@tiptap/core";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { RichTextInput } from "../../inputs";
import { Button } from "@salesdesk/daisy-ui";
import { Note, EditNoteSubmit } from "../types";

interface EditNoteProps {
	note: Note;
	onEditSubmit: EditNoteSubmit;
	onCancel?: () => void;
}

export function EditNote({ note, onCancel, onEditSubmit }: EditNoteProps) {
	const initialNoteJSONContent = JSON.parse(note.content);

	const [jsonContent, setJsonContent] = useState<JSONContent>(initialNoteJSONContent);

	const inputRef = useRef<HTMLDivElement>(null);
	const hasBeenChanged = initialNoteJSONContent === jsonContent;

	const handleSubmit = useCallback(
		(e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
			e.preventDefault();
			onEditSubmit(jsonContent);
		},
		[onEditSubmit, jsonContent]
	);

	useEffect(() => {
		const inputElement = inputRef.current;
		if (!inputElement) return;
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Enter" && e.metaKey) {
				handleSubmit(e);
			}
		};
		inputElement.addEventListener("keydown", handleKeyDown);
		return () => {
			inputElement.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleSubmit]);

	return (
		<form className="mb-1 ml-8 mr-1 flex max-h-64 flex-col gap-4" onSubmit={handleSubmit}>
			<RichTextInput
				ref={inputRef}
				onChange={setJsonContent}
				hideToolbar
				placeholder="Add a note"
				value={jsonContent}
				heightClassName="max-h-28"
				disableModEnter
				enableMentions
				bottomPanelOptions={{
					showEmojis: true,
					showMentions: true,
					rightComponent: (
						<div className="flex gap-2">
							{onCancel ? <Button size="sm" variant="secondary" startIcon={ICONS.cross} onClick={onCancel} /> : null}
							<Button disabled={hasBeenChanged} size="sm" variant="primary" startIcon={ICONS.check} type="submit" />
						</div>
					),
				}}
			/>
		</form>
	);
}
