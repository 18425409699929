import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { SidePanelButton, SidePanelButtonProps } from "../SidePanelButton";

interface SidePanelTabProps
	extends Pick<SidePanelButtonProps, "tooltip" | "icon" | "variant" | "disabled" | "alertCount"> {
	onSelected?: (alreadySelected: boolean) => void;
	userflowId?: string;
}

export function SidePanelTab({ onSelected = () => undefined, ...rest }: SidePanelTabProps) {
	return (
		<Tab as={Fragment}>
			{({ selected }) => <SidePanelButton selected={selected} onSelected={() => onSelected(selected)} {...rest} />}
		</Tab>
	);
}
