import { asConst } from "json-schema-to-ts";
import { objectIdPathParamSchema } from "./objectIdPathParamSchema";

export const getObjectPathSchema = asConst({
	title: "Get SDObject Path Parameters",
	type: "object",
	additionalProperties: false,
	required: ["objectId"],
	properties: {
		objectId: objectIdPathParamSchema,
	},
});
