import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";

import { Icon } from "@salesdesk/daisy-ui";
import { DirectedSDObjectAssociation } from "../../../../../recordAssociations";
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";

interface AssociationObjectLabelProps {
	associationObject?: SDObject;
	objectAssociation: DirectedSDObjectAssociation;
}

export function AssociationObjectLabel({ associationObject, objectAssociation }: AssociationObjectLabelProps) {
	if (!associationObject) {
		return <Skeleton className="w-22 h-6" />;
	}

	const multiplictyOne = objectAssociation.connectedObject.multiplicity === AssociationMultiplicity.ONE;

	return (
		<div className="text-c_text_disabled text-label-xs flex items-center gap-2">
			<Icon icon={associationObject._icon} className="text-c_icon_disabled flex" size="sm" />
			{multiplictyOne ? associationObject._displayName : associationObject._pluralName}
		</div>
	);
}
