import { mSystemObjectDef } from "../system_object_def";

export class mInteractionDef extends mSystemObjectDef {
	static CLASS_NAME = "mInteractionDef";
	static NAME = "Interaction";
	static PLURAL_NAME = "Interactions";

	static ID = 3;

	constructor(ownerId) {
		super(mInteractionDef.ID, ownerId);
	}

	get groupByBaseType() {
		return false;
	}
}
