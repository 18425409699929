import clsx from "clsx";
import { JSONContent } from "@tiptap/core";

import { FieldInfo } from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { DisplayRichTextField } from "../../../../../../../fields";
import {
	InlineEditRecordField,
	InlineEditRecordFieldController,
} from "../../../../../../../records/components/RecordFields";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";

interface WorkspaceProjectOverviewProps {
	projectOverviewFieldInfo?: FieldInfo;
	isEmpty?: boolean;
}

export function WorkspaceProjectOverview({ projectOverviewFieldInfo, isEmpty }: WorkspaceProjectOverviewProps) {
	const { field, value } = projectOverviewFieldInfo ?? {};
	const { workspaceObject, workspaceRecord } = useWorkspaceContext();

	if (!field || !workspaceObject || !workspaceRecord) {
		return null;
	}

	return (
		<div className="flex h-full w-full flex-col justify-stretch gap-2 overflow-hidden">
			<div className="flex h-8 items-center">
				<div className="text-h4 text-c_text_secondary ml-4 shrink-0">
					{capitalizeString(field._displayName.toLowerCase())}
				</div>
			</div>
			<InlineEditRecordFieldController
				sdObject={workspaceObject}
				sdRecord={workspaceRecord}
				field={field}
				wrapperClasses="shrink grow overflow-y-auto"
			>
				{({ inlineRecordFieldProps, editMode }) =>
					!editMode ? (
						isEmpty ? (
							<div className="text-c_text_placeholder">
								Write a few sentences to introduce your understanding of your customer’s project, the challenges they
								are encountering, and how you can help them overcome obstacles and achieve their objectives...
							</div>
						) : (
							<DisplayRichTextField value={value as JSONContent} collapsible={false} />
						)
					) : (
						<div className={clsx(editMode && "ml-4 flex max-h-full")}>
							<InlineEditRecordField {...inlineRecordFieldProps} />
						</div>
					)
				}
			</InlineEditRecordFieldController>
		</div>
	);
}
