import { createSlice } from "@reduxjs/toolkit";
import { ToastEvent } from "../types";

export const toastsSlice = createSlice({
	name: "toasts",
	initialState: {
		toastEvents: [] as ToastEvent[],
	},
	reducers: {
		triggerToast: (state, { payload }: { payload: ToastEvent }) => {
			state.toastEvents.push({
				...payload,
				createdAt: Date.now(),
				dismissed: false,
			});
		},
		dismissToastById: (state, { payload: toastId }: { payload: number }) => {
			const toast = state.toastEvents.find((toast) => toast.id === toastId);
			if (!toast) {
				return;
			}
			if (toast.timeoutId) {
				clearTimeout(toast.timeoutId);
				toast.timeoutId = undefined;
			}
			toast.dismissed = true;
		},
		deleteToastById: (state, { payload: toastId }: { payload: number }) => {
			const index = state.toastEvents.findIndex((toast) => toast.id === toastId);
			if (index !== -1) {
				state.toastEvents.splice(index, 1);
			}
		},
		setDismissTimer: (state, { payload }: { payload: { toastId: number; timeoutId: NodeJS.Timeout } }) => {
			const toastEvent = state.toastEvents.find((toast) => toast.id === payload.toastId);
			if (toastEvent) {
				toastEvent.timeoutId = payload.timeoutId;
				toastEvent.dismissed = false;
			}
		},
		cancelDismissTimer: (state, { payload: toastId }: { payload: number }) => {
			const index = state.toastEvents.findIndex((toast) => toast.id === toastId);
			if (index !== -1) {
				clearTimeout(state.toastEvents[index].timeoutId);
				state.toastEvents[index].timeoutId = undefined;
			}
		},
	},
});

export const { triggerToast, dismissToastById, deleteToastById, setDismissTimer, cancelDismissTimer } =
	toastsSlice.actions;
export const toastsReducer = toastsSlice.reducer;
