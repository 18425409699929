import { useCallback, useId } from "react";
import { Link } from "react-router-dom";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useAccessibleHTMLElementLink } from "../../../../hooks/ui";
import { RecordContextMenu, CustomRecordMenuItemsFunc, CompactRecordPreview } from "../../../records";
import { GetRecordLinkFn } from "../../../ObjectBoard/types";
import { Checkbox } from "../../../inputs";
import { RecordBadges } from "../../../records/components/RecordBadges";
import { useRecordBadges } from "../../../records/components/RecordBadges/hooks/useRecordBadges";

interface CompactCardProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
	getCustomContextMenuItems?: CustomRecordMenuItemsFunc;
	getRecordLink: GetRecordLinkFn;
	selected?: boolean;
	onToggleSelect?: () => void;
	allowDelete?: boolean;
	hideContextMenu?: boolean;
}

export function CompactCard({
	sdObject,
	sdRecord,
	getCustomContextMenuItems,
	getRecordLink,
	selected,
	onToggleSelect,
	allowDelete,
	hideContextMenu,
}: CompactCardProps) {
	const generateRecordLink = useCallback(() => {
		return getRecordLink(sdRecord._id);
	}, [getRecordLink, sdRecord._id]);

	const linkElementId = useId();
	const handleCardClickEvent = useAccessibleHTMLElementLink({ linkElementId, getLink: generateRecordLink });
	const recordLink = generateRecordLink();
	const recordBadges = useRecordBadges(sdRecord);

	return (
		<div
			className="hover:bg-c_bg_03 rounded-minimal focus-visible-within:ring focus-visible-within:ring-c_action_focus group flex cursor-pointer p-2"
			onClick={onToggleSelect ?? handleCardClickEvent}
			onAuxClick={handleCardClickEvent}
		>
			{recordLink ? (
				<Link id={linkElementId} to={recordLink} className="sr-only">
					Go to record
				</Link>
			) : null}
			{onToggleSelect ? (
				<div
					className="flex items-center pr-4"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Checkbox value={selected} onChange={onToggleSelect} />
				</div>
			) : null}
			<div className="flex flex-grow items-center gap-2 overflow-hidden">
				<CompactRecordPreview sdRecord={sdRecord} sdObject={sdObject} />
				<RecordBadges recordBadges={recordBadges} className="shrink-0" />
			</div>
			{!hideContextMenu ? (
				<div className="ui-open-within:opacity-100 flex w-8 items-center opacity-0 transition-opacity group-focus-within:opacity-100 group-hover:opacity-100">
					<RecordContextMenu
						sdRecord={sdRecord}
						getCustomContextMenuItems={getCustomContextMenuItems}
						allowDelete={allowDelete}
					/>
				</div>
			) : null}
		</div>
	);
}
