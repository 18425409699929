import { Link } from "react-router-dom";
import { getSDRecordName, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { PATHS } from "../../../../routes";
import { useGetObjectById } from "../../../../hooks/useGetObjectById";
import { MouseEvent, useCallback, useRef, useState } from "react";
import { useToast } from "../../../Toasts";
import { Button, Icon, Tooltip } from "@salesdesk/daisy-ui";
import { useFavoriteRecordMutation, useUnfavoriteRecordMutation } from "../../api/favoritesApi";

const GRACE_PERIOD_MS = 2000;

interface FavoritePopoverItemProps {
	favoriteRecord: SDRecord;
	onFavoriteToggleClick: (favorite: SDRecord) => void;
}

export function FavoritePopoverItem({ favoriteRecord, onFavoriteToggleClick }: FavoritePopoverItemProps) {
	const [gracePeriod, setGracePeriod] = useState(false);

	const { sdObject } = useGetObjectById(favoriteRecord._objectDefId);

	const [addFavorite] = useFavoriteRecordMutation();
	const [deleteFavorite, { isLoading: isDeleting }] = useUnfavoriteRecordMutation();
	const toast = useToast();

	const timeoutId = useRef<NodeJS.Timeout>();

	const handleFavoriteButtonClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (gracePeriod) {
				if (timeoutId.current !== undefined) {
					setGracePeriod(false);
					clearTimeout(timeoutId.current);
				}
				addFavorite(favoriteRecord._id)
					.unwrap()
					.then(() => {
						toast.triggerMessage({ type: "success", messageKey: "record_favorited" });
					})
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: "record_favorited" });
					});
			} else {
				deleteFavorite(favoriteRecord._id)
					.unwrap()
					.then(() => {
						toast.triggerMessage({ type: "success", messageKey: "record_unfavorited" });
						setGracePeriod(true);
						timeoutId.current = setTimeout(() => {
							timeoutId.current = undefined;
							onFavoriteToggleClick(favoriteRecord);
						}, GRACE_PERIOD_MS);
					})
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: "record_unfavorited" });
					});
			}
		},
		[gracePeriod, favoriteRecord, addFavorite, deleteFavorite, onFavoriteToggleClick, toast]
	);

	if (sdObject === undefined) {
		return null;
	}

	return (
		<Link
			className="focus:bg-c_bg_03 focus-visible-within:bg-c_bg_03 hover:bg-c_bg_03 group flex w-full items-stretch justify-stretch gap-2 px-6 py-3"
			to={PATHS.RECORD_DETAIL_VIEW(favoriteRecord._id, sdObject)}
		>
			<div className="flex w-px flex-grow flex-col gap-2">
				<div className="text-label-nav truncate">{getSDRecordName(sdObject, favoriteRecord)}</div>
				<div className="text-label-xs text-c_text_disabled flex items-center gap-2">
					<Icon icon={sdObject._icon} size="sm" />
					<div>{sdObject._pluralName}</div>
				</div>
			</div>
			<div className="flex w-8 flex-shrink-0 items-center group-focus-within:visible">
				<Tooltip text={gracePeriod ? "Add favorite again" : "Remove favorite"} noWrap>
					<Button
						startIcon={ICONS.star}
						size="sm"
						onClick={handleFavoriteButtonClick}
						variant={gracePeriod ? "text" : "primary_text"}
						iconVariant={gracePeriod ? "outline" : "fill"}
						disabled={isDeleting}
					/>
				</Tooltip>
			</div>
		</Link>
	);
}
