import { useEffect, useId, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Role } from "@salesdesk/salesdesk-schemas";

import { PATHS, useStableNavigate } from "../../../../../routes";
import { FormDialog } from "../../../../Dialog";
import { FormFieldSet } from "../../../../forms";
import { EditTextField } from "../../../../fields";
import { useToast } from "../../../../Toasts";
import { useCreateRoleMutation } from "../api/rolesApi";
import { mapFormDataToRoleCreateRequest } from "./RoleForm/mappers/mapFormDataToRoleCreateRequest";
import { mapRoleToFormData } from "./RoleForm/mappers/mapRoleToFormData";
import { RoleFormData } from "./RoleForm/types";

const NAME_FIELD = "name";

type FormData = Pick<RoleFormData, "name">;

interface CreateRuleDialogProps {
	onClose: () => void;
}

export function CreateRoleDialog({ onClose }: CreateRuleDialogProps) {
	const formId = useId();

	const toast = useToast();
	const [createRole] = useCreateRoleMutation();

	const initialRoleFormData = useMemo(() => mapRoleToFormData({ name: "", claims: [] }), []);

	const { control, handleSubmit, reset, setFocus } = useForm<FormData>({
		mode: "onChange",
		defaultValues: initialRoleFormData,
	});

	useEffect(() => {
		setTimeout(() => setFocus(NAME_FIELD), 60);
	}, [setFocus]);

	const navigate = useStableNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const onFormSubmit = (formData: FormData) => {
		setIsSubmitting(true);
		const roleCreateRequest = mapFormDataToRoleCreateRequest({ ...initialRoleFormData, ...formData });
		createRole({ roleCreateRequest })
			.unwrap()
			.then((newRole: Role) => {
				toast.triggerMessage({ type: "success", messageKey: "role_created" });
				reset();
				onClose();
				navigate(PATHS.ACCESS_ROLE(newRole.id));
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "role_created" });
				setIsSubmitting(false);
			});
	};

	return (
		<FormDialog
			open
			onOpenChange={(open) => {
				if (!open) {
					onClose();
				}
			}}
			title="New role"
			description="Give your new role a name"
			isPending={isSubmitting}
			formId={formId}
			submitButtonLabel="Create"
		>
			<form id={formId} onSubmit={handleSubmit(onFormSubmit)} className="mb-12">
				<FormFieldSet
					control={control}
					name={NAME_FIELD}
					label="Name"
					labelIcon={ICONS.text}
					required
					rules={{ required: "Name is required" }}
				>
					{({ field, fieldState: { error } }) => (
						<EditTextField title="Name" {...field} hasError={Boolean(error?.message)} />
					)}
				</FormFieldSet>
			</form>
		</FormDialog>
	);
}
