import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

/*
    Useful for interactive controls that fade away if the user doesn't interact with
    them. Can also be locked to being active when a user is focused on an interactive
    element.
*/
export function useDebouncedActiveState(delay = 1000) {
	const [isActive, setIsActive] = useState(false);

	const clearActiveState = useMemo(
		() =>
			debounce(() => {
				setIsActive(false);
			}, delay),
		[delay]
	);

	const triggerActiveState = useCallback(() => {
		setIsActive(true);
		clearActiveState();
	}, [clearActiveState]);

	return {
		isActive,
		triggerActiveState,
	};
}
