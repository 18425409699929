import { useEffect, useMemo, useState } from "react";

import { AbilityAction, AbilitySubject, rsr, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Spinner } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS } from "../../../../../../routes";
import { useWebPrincipal } from "../../../../../../auth";
import { APP_CONFIG } from "../../../../../../app/app_config";
import { SkeletonTransition } from "../../../../../../components/Skeleton/SkeletonTransition";
import { SettingsContainer } from "../../../../SettingsContainer";
import { ROW_IS_LOADING_PLACEHOLDER, Table } from "../../../../../Table";
import { useGetObjectMap } from "../../../../../objects/hooks";
import { DebouncedSearchbar } from "../../../../../inputs";
import {
	EMPTY_FILTERS,
	FilterData,
	mapToSearchQuery,
	SortingDetails,
	useInfiniteRecordSearch,
	useRecordTableSelection,
	useWatchedRecordsQuery,
} from "../../../../../records";
import {
	createNameCustomField,
	createOwnerField,
	createRecordTypeField,
	DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID,
	generateColumns,
	generateRows,
	RECORD_TYPE_COLUMN_ID,
	SD_RECORD_COLUMN_ID,
} from "../utils";
import { RecordTableRow } from "../types";
import { WatchlistContextMenu } from "./WatchlistContextMenu";
import { WatchlistFilters } from "./WatchlistFilters";
import { WatchlistSorting } from "./WatchlistSorting";
import { WatchlistBulkEditBar } from "./WatchlistBulkEditBar";

export function WatchlistPage() {
	const objectMap = useGetObjectMap();

	const principal = useWebPrincipal();
	const canUnwatch = principal.can(AbilityAction.Delete, AbilitySubject.Watch);

	const { currentData: watchedRecordIds, isLoading: isLoadingWatchedRecords } = useWatchedRecordsQuery();
	const [sortedWatchedRecordIds, setSortedWatchedRecordIds] = useState<number[]>();

	// Storing the watched record ids in a sorted state to prevent unnecessary re-fetches when the watched record ids
	// are updated but the ids don't actually change.
	useEffect(() => {
		setSortedWatchedRecordIds((previous) => {
			const sortedUpdatedIds = [...new Set(watchedRecordIds)].sort((a, b) => a - b);
			if (JSON.stringify(sortedUpdatedIds) !== JSON.stringify(previous)) {
				return sortedUpdatedIds;
			}

			return previous;
		});
	}, [watchedRecordIds, sortedWatchedRecordIds]);

	const [filter, setFilter] = useState<FilterData>(EMPTY_FILTERS);
	const [sorting, setSorting] = useState<SortingDetails[]>([]);

	const [searchQuery, setSearchQuery] = useState("");

	const { updateSearchParams, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage, sdRecords } =
		useInfiniteRecordSearch({ limit: APP_CONFIG.maxLocalSearchResults });

	const isLoading = isLoadingNewSearchParams || isLoadingWatchedRecords;

	// Don't want to show skeleton loading when the watched records are updated (e.g. if a record is unwatched)
	// Only shown for initial load and when the filter/query is updated
	const [showSkeletonLoading, setShowSkeletonLoading] = useState(true);

	useEffect(() => {
		setShowSkeletonLoading(true);
	}, [filter.filters, filter.type, sorting, searchQuery]);

	useEffect(() => {
		if (!isLoading) {
			setShowSkeletonLoading(false);
		}
	}, [isLoading]);

	useEffect(() => {
		if (!sortedWatchedRecordIds) {
			return;
		}

		const query = rsr
			.query()
			.and(rsr.equals("_deleted", false))
			.and(rsr.equalsAnyOf("id", sortedWatchedRecordIds.length ? sortedWatchedRecordIds : [-1]));
		if (filter.filters.length) {
			const filterClauses = filter.filters.flatMap(mapToSearchQuery);
			if (filter.type === "AND") {
				filterClauses.forEach((clause) => query.and(clause));
			} else {
				filterClauses.forEach((clause) => query.or(clause));
			}
		}
		if (searchQuery) {
			query.and(rsr.matchAllPrefix(searchQuery));
		}
		updateSearchParams({
			query: query.buildQuery(),
			sort: sorting.map((value) => ({ [value.fieldId]: { order: value.order } })),
		});
	}, [filter.filters, filter.type, sorting, searchQuery, updateSearchParams, sortedWatchedRecordIds]);

	const columns = useMemo(() => generateColumns(objectMap, sorting, setSorting), [objectMap, sorting]);
	const rows = useMemo(() => generateRows(sdRecords, objectMap), [sdRecords, objectMap]);

	const skeletonPlaceholderRows = useMemo(
		() => Array.from({ length: 6 }, () => ({ [ROW_IS_LOADING_PLACEHOLDER]: true })),
		[]
	);

	const controlColumn = useMemo(
		() => ({
			width: 64,
			render: (row: RecordTableRow) => {
				const sdRecord = row[SD_RECORD_COLUMN_ID];
				const sdRecordId = typeof sdRecord === "object" && "_id" in sdRecord ? sdRecord._id : undefined;
				const objectId = row[RECORD_TYPE_COLUMN_ID];

				if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof sdRecordId !== "number" || typeof objectId !== "number") {
					return null;
				}

				return <WatchlistContextMenu sdRecord={sdRecord as SDRecord} objectId={objectId} canUnwatch={canUnwatch} />;
			},
		}),
		[canUnwatch]
	);

	const fields = useMemo(
		() => [createNameCustomField(), createRecordTypeField(objectMap), createOwnerField()],
		[objectMap]
	);

	const [selectedRecords, setSelectedRecords] = useState<SDRecord[]>([]);
	const { rowSelectionDetails, getRowId } = useRecordTableSelection({
		selectedRecords,
		onSelectedRecordsChange: setSelectedRecords,
		rows,
		sdRecordKeyInRow: SD_RECORD_COLUMN_ID,
		inSelectionMode: selectedRecords?.length > 0,
	});

	return (
		<SettingsContainer
			breadcrumbs={[
				{
					node: "Settings",
					icon: ICONS.settings,
				},
				{
					node: "Notifications",
					link: PATHS.SETTINGS_NOTIFICATIONS(),
				},
				{
					node: "Watchlist",
					link: PATHS.SETTINGS_WATCHLIST(),
				},
			]}
		>
			<div className="flex h-full flex-col">
				<div className="flex w-full justify-end gap-2 p-6">
					<WatchlistFilters fields={fields} value={filter} onChange={setFilter} />
					<WatchlistSorting fields={fields} value={sorting} onChange={setSorting} />
					<DebouncedSearchbar placeholder="Search..." onChange={setSearchQuery} />
				</div>
				<SkeletonTransition className="flex w-full flex-col overflow-hidden px-8">
					<Table
						rows={isLoading && showSkeletonLoading ? skeletonPlaceholderRows : rows}
						rowSelection={canUnwatch ? rowSelectionDetails : undefined}
						getRowId={canUnwatch ? getRowId : undefined}
						columns={columns}
						onBottomReached={loadNextPage}
						bottomOffset={200}
						controlColumn={controlColumn}
						tableFooter={
							isLoadingNextPage ? (
								<div className="my-6 flex w-full items-center justify-center">
									<Spinner size="md" />
								</div>
							) : undefined
						}
						defaultColumnWidthByColumnId={DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID}
						containerRightPadding={0}
					/>
					{(!isLoading || !showSkeletonLoading) && !rows.length ? (
						<span className="text-body text-c_text_secondary my-8 text-center">
							{filter.filters.length || searchQuery
								? "No watched records match the selected filters."
								: "There are no watched records."}
						</span>
					) : null}
					{canUnwatch ? (
						<WatchlistBulkEditBar selectedRecords={selectedRecords} onClose={() => setSelectedRecords([])} />
					) : null}
				</SkeletonTransition>
			</div>
		</SettingsContainer>
	);
}
