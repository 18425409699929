import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../components";
import { userUpdateRequestSchema } from "./userUpdateRequestSchema";

export const userBaseSchema = asConst({
	type: "object",
	additionalProperties: false,
	required: ["username", ...userUpdateRequestSchema.required], //, "userType"
	properties: {
		username: stringSchema,
		// TODO: This may be redundant
		// userType: userTypeSchema,
		...userUpdateRequestSchema.properties,
	},
});
