import { useCallback, useEffect } from "react";

import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";

import { RecordCreateForm, useRecordCreateForm } from "../../../../../../records";

interface CreateOriginRecordProps {
	formId: string;
	sdObject: SDObject;
	onRecordCreate: (originRecord: SDRecord, originRecordObject: SDObject) => void;
	onLoading?: (isLoading: boolean) => void;
}

export function CreateOriginRecord({ formId, sdObject, onRecordCreate, onLoading }: CreateOriginRecordProps) {
	const onRecordCreateSuccess = useCallback(
		(newSDRecord: SDRecord) => {
			onRecordCreate(newSDRecord, sdObject);
		},
		[sdObject, onRecordCreate]
	);

	const { formProps, isSubmitting } = useRecordCreateForm({
		sdObject,
		onRecordCreateSuccess,
		withAssociations: false,
	});

	useEffect(() => {
		if (onLoading) {
			onLoading(isSubmitting);

			return () => {
				onLoading(false);
			};
		}
	}, [onLoading, isSubmitting]);

	return <RecordCreateForm formProps={{ ...formProps, id: formId }} objectName={sdObject._displayName} />;
}
