import { SDObject } from "../../object";
import {
	mAssetDef,
	mDocDef,
	mGeneralAssetDef,
	mImageAssetDef,
	mInteractionMeetingDef,
	mMediaAssetDef,
	mPdfAssetDef,
	mTaskDef,
	mIssueDef,
} from "@salesdesk/salesdesk-model";
import { isAssetObject } from "../objects";
import { SDRecord } from "../../records";
import { RECORD_SIDE_PANEL_RECORD_ID_PARAM } from "./UI_PARAMS";

export const UI_PATHS = {
	ROOT: generateRootPath,
	DASHBOARD: generateRootPath,
	ERROR: generateErrorPath,

	OBJECT_MANAGER: generateObjectManagerPath,

	OBJECT_RECORD_BOARD: generateInternalObjectRecordBoardPath,
	RECORD_DETAIL_VIEW: generateRecordDetailViewPath,

	IMPORT: generateImportPath,
	IMPORT_MAPPING: generateImportMappingPath,
	IMPORT_EXECUTION: generateImportExecutionPath,

	MEETINGS: generateMeetingsPath,
	MEETING: generateMeetingPath,

	EVENT_RECORD: getEventRecordPath,

	WORKSPACES: generateWorkspacesPath,
	WORKSPACE: generateWorkspacePath,
	WORKSPACE_RECORD_BOARD: generateWorkspaceObjectRecordBoardPath,
	WORKSPACE_RECORD_DETAILS: generateWorkspaceRecordDetailsPath,
	WORKSPACE_OVERVIEW: generateWorkspaceOverviewPath,
	WORKSPACE_RESOURCES: generateWorkspaceResourcesPath,
	WORKSPACE_SETTINGS: generateWorkspaceSettingsPath,
	WORKSPACES_ERROR: () => `${generateWorkspacesPath()}/error`,

	SETTINGS: generateSettings,
	SETTINGS_PROFILE: () => `${generateSettings()}/profile`,
	SETTINGS_ACCOUNT: () => `${generateSettings()}/account`,
	SETTINGS_DEVELOPER: () => `${generateSettings()}/developer`,
	SETTINGS_NOTIFICATIONS: generateSettingsNotifications,
	SETTINGS_NOTIFICATION_RULES: () => `${generateSettingsNotifications()}/rules`,
	SETTINGS_WATCHLIST: () => `${generateSettings()}/watchlist`,

	ACCESS: generateSettingsAccessPath,
	ACCESS_ROLES: generateRolesPath,
	ACCESS_ROLE: generateRolePath,
	ACCESS_USERS: generateUsersPath,
	ACCESS_CUSTOMERS: (recordId?: number) => generateUsersPath(recordId, "customers"),
};

function generateRootPath() {
	return "/";
}

function generateErrorPath(errorCode: number | string) {
	return `/error/${errorCode}`;
}

function generateObjectManagerPath() {
	return "/settings/object-manager";
}

export function generateObjectRecordBoardPath(sdObject: SDObject, params: ObjectRecordBoard = {}): string {
	const { query, recordId, filter, bookmarkId } = params;
	const urlSearchParams = new URLSearchParams();
	if (query) urlSearchParams.set("query", query);
	if (recordId) urlSearchParams.set(RECORD_SIDE_PANEL_RECORD_ID_PARAM, String(recordId));
	if (filter) urlSearchParams.set("filter", filter);
	if (bookmarkId) urlSearchParams.set("bookmarkId", String(bookmarkId));

	const stringParams = urlSearchParams.toString();
	return `${getSDObjectPathSlug(sdObject)}/records${stringParams ? `?${stringParams}` : ""}`;
}

export function generateInternalObjectRecordBoardPath(sdObject: SDObject, params: ObjectRecordBoard = {}): string {
	return `/objects/${generateObjectRecordBoardPath(sdObject, params)}`;
}

export function generateImportPath(sdObject: SDObject) {
	return `/objects/${getSDObjectPathSlug(sdObject)}/import`;
}

export function generateImportMappingPath(sdObject: SDObject) {
	return `/objects/${getSDObjectPathSlug(sdObject)}/import/mapping`;
}

export function generateImportExecutionPath(sdObject: SDObject) {
	return `/objects/${getSDObjectPathSlug(sdObject)}/import/execution`;
}

export interface RecordDetailViewParams {
	topLevelTab?: number;
	transcriptQuery?: string;
}

function generateRecordDetailViewParams(params: RecordDetailViewParams) {
	const { transcriptQuery, topLevelTab } = params;
	const urlSearchParams = new URLSearchParams();
	if (transcriptQuery) urlSearchParams.set("transcriptQuery", transcriptQuery);
	if (topLevelTab !== undefined) urlSearchParams.set("topLevelTab", String(topLevelTab));

	const stringParams = urlSearchParams.toString();
	return stringParams ? `?${stringParams}` : "";
}

export function generateRecordDetailViewPath(
	recordId: number,
	sdObject: SDObject,
	params: RecordDetailViewParams = {}
) {
	return `/objects/${getSDObjectPathSlug(sdObject)}/records/${recordId}${generateRecordDetailViewParams(params)}`;
}

export function generateMeetingsPath() {
	return "/meetings";
}

function generateMeetingPath(meetingRecord: SDRecord) {
	const meetingPath = `${generateMeetingsPath()}/${meetingRecord._id}`;
	const workspaceId = meetingRecord.sharedWorkspaceIds.length > 0 ? meetingRecord.sharedWorkspaceIds[0] : undefined;

	return workspaceId ? `${generateWorkspacePath(workspaceId)}${meetingPath}` : meetingPath;
}

function getEventRecordPath(
	workspaceId: number | undefined,
	sdObject: SDObject,
	sdRecord: SDRecord,
	isSalesDeskUser: boolean
) {
	if (
		!workspaceId ||
		// "Record/User unshared/removed from Workspace"
		(workspaceId &&
			sdRecord._id !== workspaceId &&
			!sdRecord?.sharedWorkspaceIds.includes(workspaceId) &&
			isSalesDeskUser)
	)
		return UI_PATHS.RECORD_DETAIL_VIEW(sdRecord._id, sdObject);

	if (workspaceId && sdRecord?.sharedWorkspaceIds.includes(workspaceId))
		return UI_PATHS.WORKSPACE_RECORD_BOARD(workspaceId, sdObject, { recordId: sdRecord._id });

	return UI_PATHS.WORKSPACE(workspaceId);
}

function generateWorkspacesPath() {
	return "/workspaces";
}

export function generateWorkspacePath(workspaceId: number) {
	return `${generateWorkspacesPath()}/${workspaceId}`;
}

interface ObjectRecordBoard {
	query?: string;
	recordId?: number;
	filter?: string;
	bookmarkId?: number;
}

export function generateWorkspaceRecordsBasePath(workspaceId: number, sdObject: SDObject) {
	let recordsBasePath: string | undefined;

	switch (sdObject._id) {
		case mAssetDef.ID:
		case mGeneralAssetDef.ID:
		case mImageAssetDef.ID:
		case mPdfAssetDef.ID:
		case mMediaAssetDef.ID:
		case mDocDef.ID:
			recordsBasePath = `${generateWorkspacePath(workspaceId)}/library`;
			break;
		case mTaskDef.ID:
		case mIssueDef.ID:
		case mInteractionMeetingDef.ID:
			recordsBasePath = `${generateWorkspacePath(workspaceId)}/objects`;
			break;
		default:
	}

	return recordsBasePath;
}

export function generateWorkspaceObjectRecordBoardPath(
	workspaceId: number,
	sdObject: SDObject,
	params: ObjectRecordBoard = {}
): string {
	const recordsBasePath = generateWorkspaceRecordsBasePath(workspaceId, sdObject);

	return recordsBasePath
		? `${recordsBasePath}/${generateObjectRecordBoardPath(sdObject, params)}`
		: generateWorkspacePath(workspaceId);
}

export function generateWorkspaceRecordDetailsPath(
	workspaceId: number,
	recordId: number,
	sdObject: SDObject,
	params: RecordDetailViewParams = {}
) {
	if (isAssetObject(sdObject) || sdObject._id === mDocDef.ID) {
		return `${generateWorkspaceRecordsBasePath(workspaceId, sdObject)}/${getSDObjectPathSlug(
			sdObject
		)}/records/${recordId}${generateRecordDetailViewParams(params)}`;
	}

	return undefined;
}

export function getSDObjectPathSlug(sdObject: SDObject) {
	// TODO: Hard code all asset object slugs to the assets object plural name until we
	// rework how slugs work
	if (isAssetObject(sdObject)) {
		return mAssetDef.PLURAL_NAME.toLowerCase();
	}

	return sdObject._pluralName.toLowerCase();
}

function generateWorkspaceOverviewPath(workspaceId: number, recordId?: number) {
	const params = new URLSearchParams();
	if (recordId) params.set(RECORD_SIDE_PANEL_RECORD_ID_PARAM, String(recordId));
	const paramsString = params.toString();
	return `${generateWorkspacePath(workspaceId)}${paramsString ? "?" + paramsString : ""}`;
}

function generateInternalWorkspacePath(workspaceId: number) {
	return `${generateWorkspacePath(workspaceId)}/internal`;
}

function generateWorkspaceResourcesPath(workspaceId: number) {
	return `${generateInternalWorkspacePath(workspaceId)}/resources`;
}

function generateWorkspaceSettingsPath(workspaceId: number) {
	return `${generateInternalWorkspacePath(workspaceId)}/settings`;
}

function generateRolesPath() {
	return `${generateSettingsAccessPath()}/roles`;
}
function generateRolePath(roleId: number) {
	return `${generateRolesPath()}/${roleId}`;
}

function generateUsersPath(recordId?: number, page: "users" | "customers" = "users") {
	const params = new URLSearchParams();
	if (recordId) params.set(RECORD_SIDE_PANEL_RECORD_ID_PARAM, String(recordId));
	return `${generateSettingsAccessPath()}/${page}?` + params.toString();
}

function generateSettings() {
	return "/settings";
}

function generateSettingsNotifications() {
	return `${generateSettings()}/notifications`;
}

function generateSettingsAccessPath() {
	return `${generateSettings()}/access`;
}
