import { getSDObjectFieldById } from "@salesdesk/salesdesk-schemas";
import { RecordUpdateFieldDiff } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { UpdatedField } from "./UpdatedField";
import { useMemo } from "react";
import { useGetObjectById } from "../../../../../hooks";
import { useEventContext } from "../../../hooks/useEventContext";
import { Icon } from "@salesdesk/daisy-ui";

export function UpdatedRecordPayload() {
	const { event } = useEventContext();
	const { sdObject } = useGetObjectById(event.object_def_id);

	const fieldUpdatesDiff: RecordUpdateFieldDiff[] = useMemo(
		() => (event.params?.diff ? JSON.parse(event.params.diff as string) : undefined),
		[event]
	);

	if (!fieldUpdatesDiff.length || !sdObject) {
		return null;
	}

	return fieldUpdatesDiff.map((diff) => {
		const field = getSDObjectFieldById(sdObject, diff.fieldId);
		if (!field) return null;
		return (
			<div key={diff.fieldId} className="-mb-1 flex flex-col">
				<div className="text-label-xs text-c_text_secondary flex h-7 items-center gap-1" key={diff.fieldId}>
					<Icon icon={field._icon} size="xs" className="flex items-center" />
					<div>{field._displayName}:</div>
				</div>
				<div className="flex items-center gap-1.5">
					<div className="max-w-[70%] truncate">
						<UpdatedField field={field} value={diff.originalValue} />
					</div>
					<Icon icon={ICONS.caretRight} size="xs" className="flex items-center" />
					<div className="max-w-[70%] truncate">
						<UpdatedField field={field} value={diff.newValue} />
					</div>
				</div>
			</div>
		);
	});
}
