import {
	DARK_BUTTON_BG_COLORS,
	DARK_TEXT_COLORS,
	LIGHT_BUTTON_BG_COLORS_BY_TYPE,
	LIGHT_TEXT_COLORS_BY_TYPE,
	ToastType,
} from "../types";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";

interface DismissButtonProps {
	type: ToastType;
	dark: boolean;
	onClick?: () => void;
}

export function DismissButton({ type, dark, onClick }: DismissButtonProps) {
	const bgColor = dark ? DARK_BUTTON_BG_COLORS : LIGHT_BUTTON_BG_COLORS_BY_TYPE[type];
	const textColor = dark ? DARK_TEXT_COLORS : LIGHT_TEXT_COLORS_BY_TYPE[type];
	return (
		<button
			className={clsx(
				"flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-opacity-0 hover:bg-opacity-5 focus-visible:bg-opacity-5 focus-visible:ring",
				textColor,
				bgColor
			)}
			onClick={onClick}
		>
			<span className="sr-only">Dismiss</span>
			<Icon icon={ICONS.cross} size="base" />
		</button>
	);
}
