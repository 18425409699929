import { useRef } from "react";
import clsx from "clsx";

import { useBoardFieldsToDisplaySelector } from "../../../../store";
import { BoardViewProps, FULL_RECORD_DATA_FIELD_ID } from "../../../../types";
import { useDataboardDetailsContext } from "../../../../hooks/useDataboardDetailsContext";

import { useRecordTableSelection } from "../../../../../records";
import { useWorkspaceContext } from "../../../../../workspaces";
import { Table } from "../../../../../Table";
import { useBulkEditContext } from "../../../BulkEdit";

import { useControlColumn } from "../../common/hooks/useControlColumn";
import { useDefaultColumnWidths } from "../../common/hooks/useDefaultColumnWidths";
import { useTableColumns } from "../../common/hooks/useTableColumns";
import { useSkeletonPlaceholderRows } from "../../common/hooks/useSkeletonPlaceholderRows";
import { RecordTableRow } from "../../common/types";

export function TableView({ boardRecords, footer, onBottomReached, isLoading }: BoardViewProps) {
	const { scrollContainerRef: workspaceScrollContainerRef } = useWorkspaceContext();

	const { sdObject, workspaceId } = useDataboardDetailsContext();
	const fieldsToDisplay = useBoardFieldsToDisplaySelector();

	const isWorkspaceBoard = workspaceId != null;

	const { inBulkEditMode, selectedRecords, setSelectedRecords } = useBulkEditContext();

	const columns = useTableColumns();
	const rows: RecordTableRow[] = boardRecords satisfies RecordTableRow[];

	const skeletonPlaceholderRows = useSkeletonPlaceholderRows();

	const { rowSelectionDetails, getRowId } = useRecordTableSelection({
		selectedRecords,
		onSelectedRecordsChange: setSelectedRecords,
		rows,
		sdRecordKeyInRow: FULL_RECORD_DATA_FIELD_ID,
		inSelectionMode: inBulkEditMode,
	});

	const tableRef = useRef<HTMLDivElement>(null);
	const controlColumn = useControlColumn(tableRef, isWorkspaceBoard ? workspaceScrollContainerRef : undefined);
	const defaultColumnWidthByColumnId = useDefaultColumnWidths(sdObject);

	return (
		<div
			className={clsx(
				"mt-2.5 flex",
				!isWorkspaceBoard && !isLoading && "overflow-hidden",
				fieldsToDisplay?.length ? "opacity-100" : "opacity-0"
			)}
		>
			<Table
				columns={columns}
				visibleColumns={fieldsToDisplay}
				rows={isLoading ? skeletonPlaceholderRows : rows}
				rowSelection={rowSelectionDetails}
				getRowId={getRowId}
				controlColumn={controlColumn}
				tableFooter={footer}
				onBottomReached={onBottomReached}
				outsideRef={tableRef}
				outsideScrollContainerRef={isWorkspaceBoard ? workspaceScrollContainerRef : undefined}
				defaultColumnWidthByColumnId={defaultColumnWidthByColumnId}
			/>
		</div>
	);
}
