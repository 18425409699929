import { useCallback } from "react";
import { addMinutes } from "date-fns";

import {
	ChannelMessageType,
	ChannelType,
	getSDObjectFieldsByNameMap,
	getSDObjectNameFieldIds,
	SDRecord,
	TimeRange,
} from "@salesdesk/salesdesk-schemas";
import { formatDateTime, mapLocalDateTimeToUtcTimestamp } from "@salesdesk/salesdesk-utils";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";

import { useToast } from "../../../Toasts";
import { generateCreateRecordRequest, useHandleRecordCreation } from "../../../records";
import { useGetObjectById } from "../../../../hooks";
import { PATHS, useStableNavigate } from "../../../../routes";
import { useAppDispatch } from "../../../../store/store";
import { useChannelSelector } from "../../store";
import { updateReadMarkerForChannel } from "../../store/thunks";
import { useChimeMessagingContext } from "../../components";
import { useChannelMemberRecordIds } from "../useChannelMemberRecordIds";
import { useChannelHuddleName } from "./useChannelHuddleName";

// A huddle is just a specific type of Meeting record
export function useCreateHuddle(channelArn?: string) {
	const toast = useToast();
	const navigate = useStableNavigate();

	const dispatch = useAppDispatch();

	const channel = useChannelSelector(channelArn, true);
	const channelWorkspaceId =
		channel?.channelMetadata?.channelType === ChannelType.Workspace ? channel.channelMetadata.workspaceId : undefined;

	const { handleRecordCreation, isLoading: isCreatingHuddle } = useHandleRecordCreation(channelWorkspaceId);

	const { chime, sendMessage } = useChimeMessagingContext();

	const channelHuddleName = useChannelHuddleName(channelArn);
	const memberRecordIds = useChannelMemberRecordIds(channelArn);

	const { sdObject: meetingObject } = useGetObjectById(mInteractionMeetingDef.ID);

	const createHuddle = useCallback(async () => {
		if (meetingObject == null || !channelArn || !sendMessage) return;

		const nameFieldIds = getSDObjectNameFieldIds(meetingObject);

		const currentTime = new Date();
		currentTime.setSeconds(0, 0);

		// Add the current time to the huddle name
		const huddleName = `${channelHuddleName} - ${formatDateTime(currentTime)}`;

		const fieldMap = getSDObjectFieldsByNameMap(meetingObject);

		const typeField = fieldMap[mInteractionMeetingDef.MEETING_TYPE_FIELD_NAME];
		const timeField = fieldMap[mInteractionMeetingDef.TIME_FIELD_NAME];
		const participantsField = fieldMap[mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME];
		const channelArnField = fieldMap[mInteractionMeetingDef.CHAT_CHANNEL_ARN_FIELD_NAME];

		if (!typeField || !timeField || !participantsField || !channelArnField) {
			toast.trigger("error", `An error occurred when trying to create a huddle`);
			return;
		}

		const huddleTypeId =
			typeField._optionValues?.find((option) => option.name === mInteractionMeetingDef.MEETING_TYPES.HUDDLE)?.id ??
			null;

		const startTime = mapLocalDateTimeToUtcTimestamp(currentTime);
		const endTime = mapLocalDateTimeToUtcTimestamp(addMinutes(currentTime, 15));
		const meetingTime: TimeRange = { start: startTime, end: endTime };

		const recordCreateRequest = generateCreateRecordRequest(
			{
				[nameFieldIds[0]]: huddleName,
				[typeField._id]: huddleTypeId,
				[timeField._id]: meetingTime,
				[participantsField._id]: memberRecordIds,
				[channelArnField._id]: channelArn,
			},
			meetingObject
		);

		const onCreationComplete = (newSDRecord: SDRecord) => {
			sendMessage(channelArn, undefined, {
				messageType: ChannelMessageType.Huddle,
				meetingRecordId: newSDRecord._id,
			});

			if (chime) {
				// Updates read marker so you don't get a notification for the huddle you just created
				dispatch(updateReadMarkerForChannel({ channelArn, chime }));
			}

			navigate(PATHS.MEETING(newSDRecord));
		};

		handleRecordCreation(recordCreateRequest, meetingObject, onCreationComplete);
	}, [
		channelArn,
		channelHuddleName,
		chime,
		dispatch,
		handleRecordCreation,
		meetingObject,
		memberRecordIds,
		navigate,
		sendMessage,
		toast,
	]);

	return {
		createHuddle,
		isCreatingHuddle,
	};
}
