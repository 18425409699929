import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { SelectOption } from "../../../inputs";
import { ICONS } from "@salesdesk/salesdesk-ui";

export const ANY = "ANY";
export const ERROR = "ERROR";

export type AnyActionOptionsType = {
	[Property in keyof typeof NotificationRuleType as `${Lowercase<string & Property>}`]: SelectOption;
};

export const AnyActionOptions: AnyActionOptionsType = {
	record: { id: ANY, name: "Anything happens" },
	user: { id: ANY, name: "Does anything" },
};

export const AnyObject: SelectOption = { id: ANY, name: "Any object" };

export const AnyWorkspace: SelectOption = { id: ANY, name: "Any workspace" };

export const ErrorOption: SelectOption = { id: ERROR, name: "Error", icon: ICONS.warning, color: "red" };
