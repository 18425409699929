import { asConst } from "json-schema-to-ts";

export const userUpdateRequestSchema = asConst({
	title: "UserUpdateRequestSchema",
	description: "The schema used for updating Users",
	type: "object",
	additionalProperties: false,
	required: [], //["roles"],
	properties: {
		// roles: {
		// 	type: "array",
		// 	items: idSchema,
		// },
	},
});
