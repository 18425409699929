import { useMemo } from "react";

import {
	NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS,
	SDObject,
	sdObjectIsUserType,
	NOTIFICATION_RULE_OBJECT_IDS,
} from "@salesdesk/salesdesk-schemas";

import { useGetObjectsQuery } from "../../../objects/api/objectsApi";
import { useWebPrincipal } from "../../../../auth";

export function useNotificationRuleObjects(): SDObject[] {
	const principal = useWebPrincipal();
	const { data: objects } = useGetObjectsQuery();

	return useMemo(() => {
		if (!objects) {
			return [];
		}
		const systemObjectOrder = principal.IsCustomerUser
			? NOTIFICATION_RULE_WORKSPACE_OBJECT_IDS
			: NOTIFICATION_RULE_OBJECT_IDS;
		const systemObjects = objects
			.filter((object) => systemObjectOrder.includes(object._id))
			.sort((a, b) => (systemObjectOrder.indexOf(a._id) > systemObjectOrder.indexOf(b._id) ? 1 : -1));

		if (principal.IsCustomerUser) return systemObjects;

		const customObjects = objects
			.filter(sdObjectIsUserType)
			.sort((a, b) => a._displayName.toLowerCase().localeCompare(b._displayName.toLowerCase()));

		return [...systemObjects, ...customObjects];
	}, [objects, principal.IsCustomerUser]);
}
