import { tw } from "@salesdesk/daisy-ui";
import { SDFile } from "@salesdesk/salesdesk-schemas";

export type LogoSize = "xs" | "sm" | "md" | "lg" | "xl";

export interface LogoProps {
	image?: SDFile | number | string | File | null;
	companyName: string;
	size?: LogoSize;
	displayCompanyName?: boolean;
}

export const SIZE_CLASSES: Record<LogoSize, string> = {
	xs: tw`size-5 text-[10px] font-bold rounded-sm`,
	sm: tw`size-8 text-label-xs rounded-sm`,
	md: tw`size-10 text-label rounded-sm`,
	lg: tw`size-[60px] text-h3 rounded-md`,
	xl: tw`size-20 text-h2 rounded-md`,
};
