import { useMemo } from "react";

import { useChannelMessagesSelector } from "./useChannelMessagesSelector";

/**
 * Return undefined if the messages are still loading, null if there are no messages,
 * or the last message in the channel.
 */
export function useChannelLastMessageSelector(channelArn: string) {
	const messages = useChannelMessagesSelector({ channelArn });

	return useMemo(() => {
		if (!messages) {
			return undefined;
		}

		return messages[messages.length - 1] ?? null;
	}, [messages]);
}
