import { DashboardOnboardingSection } from "../../onboarding";
import { DashboardTasks } from "./DashboardTasks";

export function DashboardPage() {
	return (
		<div className="bg-c_bg_01 flex size-full justify-start overflow-auto px-10 pt-10">
			<div className="flex w-full max-w-[1440px] flex-col gap-8">
				<DashboardOnboardingSection />
				<div className="pb-8">
					<DashboardTasks />
				</div>
			</div>
		</div>
	);
}
