import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useLocation, useParams } from "react-router-dom";

import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

import { useGetRecordQuery } from "../../records";
import { WorkspaceContext } from "../hooks/useWorkspaceContext";
import { ResourcesPanelDetails } from "../types";

import { useWebPrincipal } from "../../../auth";
import { useGetObjectById } from "../../../hooks";
import { NavigateNotFound, PATHS } from "../../../routes";
import { useGetWorkspaceWelcomeVideo } from "../hooks/useGetWorkspaceWelcomeVideo";
import { AbilityAction, AbilitySubject, sdSubject } from "@salesdesk/salesdesk-schemas";
import { useSendWorkspaceEnteredEvent } from "../hooks/useSendWorkspaceEnteredEvent";

// TODO: Should be changed into a redux store for performance
export function WorkspaceProvider({ children }: PropsWithChildren) {
	const principal = useWebPrincipal();
	const canViewFullMeetingUI = principal.can(AbilityAction.View, AbilitySubject.FullMeetingUI);

	const location = useLocation();

	const inWorkspacesArea = location.pathname.startsWith(PATHS.WORKSPACES());
	const { workspaceId } = useParams<{ workspaceId: string }>();

	const workspaceRecordId = inWorkspacesArea && workspaceId ? Number(workspaceId) : undefined;
	useSendWorkspaceEnteredEvent(canViewFullMeetingUI ? workspaceRecordId : undefined);

	// Ensure that the workspace id is valid and that the user has access to the workspace
	const invalidWorkspaceId =
		workspaceRecordId != null &&
		(isNaN(workspaceRecordId) ||
			(principal.IsCustomerUser &&
				!principal.can(AbilityAction.View, sdSubject(AbilitySubject.Record, { _id: workspaceRecordId }))));

	const [isPreviewMode, setIsPreviewMode] = useState(false);

	const [navCollapsed, setNavCollapsed] = useState(false);

	const [resourcesPanelDetails, setResourcesPanelDetails] = useState<ResourcesPanelDetails | null>(null);
	const { sdObject: workspaceObject, isLoading: isLoadingWorkspaceObject } = useGetObjectById(mWorkspaceDef.ID);
	const {
		currentData: workspaceRecord,
		isLoading: isLoadingWorkspace,
		isError: workspaceFailedToLoad,
	} = useGetRecordQuery(workspaceRecordId ?? skipToken);

	const isLoading = isLoadingWorkspaceObject || isLoadingWorkspace;

	const {
		welcomeVideo,
		welcomeVideoRecord,
		isLoading: isLoadingWelcomeVideo,
	} = useGetWorkspaceWelcomeVideo(canViewFullMeetingUI ? workspaceRecord : undefined);

	// Currently a ref as using a simple string causes a large amount of re-renders on URL change
	// as render heavy components use the workspace provider (e.g. the databoards). Should be changed
	// back to a string when this provider is changed from a context to a store
	const previousOutsideWorkspacePathRef = useRef<string>();

	useEffect(() => {
		if (!inWorkspacesArea) {
			previousOutsideWorkspacePathRef.current = location.pathname + location.search + location.hash;
		}
	}, [inWorkspacesArea, location]);

	const openResourcesPanel = useCallback((newPanelDetails: ResourcesPanelDetails) => {
		setResourcesPanelDetails(newPanelDetails);
	}, []);

	const clearResourcesPanelDetails = useCallback(() => {
		setResourcesPanelDetails(null);
	}, []);

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const invalidRecordType = workspaceObject && workspaceRecord && workspaceObject._id !== workspaceRecord._objectDefId;
	const failedToLoad = !isLoading && (!workspaceObject || (!workspaceRecord && workspaceFailedToLoad));

	const contextWorkspaceRecord = inWorkspacesArea ? workspaceRecord : undefined;
	const contextWorkspaceId = inWorkspacesArea && !invalidWorkspaceId ? workspaceRecordId : undefined;

	const contextValue = useMemo(
		() => ({
			workspaceRecord: contextWorkspaceRecord,
			workspaceId: contextWorkspaceId,
			workspaceObject,
			isLoading,
			scrollContainerRef,
			navCollapsed,
			toggleNavCollapse: setNavCollapsed,
			resourcesPanelDetails,
			openResourcesPanel,
			clearResourcesPanelDetails,
			previousOutsideWorkspacePathRef,
			isPreviewMode,
			setIsPreviewMode,
			welcomeVideo,
			welcomeVideoRecord,
			isLoadingWelcomeVideo: isLoadingWelcomeVideo || isLoading,
		}),
		[
			contextWorkspaceRecord,
			contextWorkspaceId,
			workspaceObject,
			isLoading,
			navCollapsed,
			resourcesPanelDetails,
			openResourcesPanel,
			clearResourcesPanelDetails,
			isPreviewMode,
			welcomeVideo,
			welcomeVideoRecord,
			isLoadingWelcomeVideo,
		]
	);

	const isErrorPage = location.pathname === PATHS.WORKSPACES_ERROR();
	if (!isErrorPage && (invalidWorkspaceId || invalidRecordType || failedToLoad)) {
		return <NavigateNotFound navigateToWorkspaceError={principal.IsCustomerUser} />;
	}

	return <WorkspaceContext.Provider value={contextValue}>{children}</WorkspaceContext.Provider>;
}
