import { forwardRef, useEffect, useMemo, useState } from "react";

import { FileInput } from "../../../../../inputs";
import { EditFieldProps } from "../../../../types";
import { FILE_TYPE_DETAILS, FileTypeDetails, useStoreFile } from "../../../../../files";
import { FileFieldType, UploadProgressStatus } from "../types";
import { EditFieldVariant } from "../../../FieldFactory/EditFieldFactory";

interface EditFileFieldProps extends Omit<EditFieldProps, "onChange" | "value"> {
	value?: File | number | null;
	onChange: (newValue: number | null) => void;
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
	type?: FileFieldType;
	variant?: EditFieldVariant;
}

export const EditFileField = forwardRef<HTMLInputElement, EditFileFieldProps>(
	(
		{
			inputRef,
			value = null,
			onChange,
			type = "any",
			updateUploadProgressStatus,
			variant = EditFieldVariant.default,
			...fileInputProps
		},
		ref
	) => {
		const storeFile = useStoreFile();
		const [uploadProgress, setUploadProgress] = useState<number | undefined>();
		const supportedMimeTypes = useMemo(() => {
			let fileDetails: FileTypeDetails = FILE_TYPE_DETAILS.GENERAL_FILE;
			if (type === "image") {
				fileDetails = FILE_TYPE_DETAILS.IMAGE_FILE;
			} else if (type === "pdf") {
				fileDetails = FILE_TYPE_DETAILS.PDF_FILE;
			} else if (type === "video") {
				fileDetails = FILE_TYPE_DETAILS.VIDEO_FILE;
			}

			return fileDetails.mimeTypes.join(",");
		}, [type]);

		const uploadProgressStatus = uploadProgress ? "in_progress" : "idle";

		useEffect(() => {
			if (updateUploadProgressStatus) {
				updateUploadProgressStatus(uploadProgressStatus);
			}
		}, [uploadProgressStatus, updateUploadProgressStatus]);

		return (
			<FileInput
				ref={ref}
				value={value}
				showFileBorder={variant === EditFieldVariant.default}
				onChange={(newFile) => {
					if (!newFile) {
						onChange(null);
						return;
					}

					// TODO: How do we handle file uploads failing?
					storeFile(newFile, (uploadProgress) => {
						setUploadProgress(uploadProgress);
					})
						.then((fileId) => {
							onChange(fileId);
						})
						.finally(() => {
							setUploadProgress(undefined);
						});
				}}
				{...fileInputProps}
				supportedMimeTypes={supportedMimeTypes}
				uploadProgress={uploadProgress}
			/>
		);
	}
);
