import { useContext, createContext, Dispatch, SetStateAction, MutableRefObject } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { DocumentCommentThread } from "../types";

export interface DocumentCommentsState {
	documentRecord?: SDRecord;
	documentCommentThreads?: DocumentCommentThread[];
	isLoadingComments?: boolean;

	activeCommentId?: string | null;
	onActiveCommentsChangeRef?: MutableRefObject<(activeComments: string[]) => void>;

	isCommentsOpen?: boolean;
	setCommentsOpen?: Dispatch<SetStateAction<boolean>>;

	showResolvedComments?: boolean;
	setShowResolvedComments?: Dispatch<SetStateAction<boolean>>;
}

export const DocumentCommentsContext = createContext<DocumentCommentsState>({} satisfies DocumentCommentsState);

export function useDocumentCommentsContext() {
	return useContext(DocumentCommentsContext);
}
