import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Tooltip } from "@salesdesk/daisy-ui";
import { RecordViewTopLevelTab } from "../../../../records/components/RecordDetails/components/RecordDetailView/types";
import {
	useRecordViewTopLevelTabsContext,
	useRecordDetailViewState,
} from "../../../../records/components/RecordDetails/components/RecordDetailView/hooks";

export function AnalyticsFabButton() {
	const { recordDetailPropOnChange } = useRecordDetailViewState();
	const { tabs } = useRecordViewTopLevelTabsContext();
	const analyticsTabIndex = tabs.indexOf(RecordViewTopLevelTab.analytics);

	return (
		<Tooltip text="Analytics">
			<Button
				size="xs"
				variant="text"
				startIcon={ICONS.reports}
				onClick={(e) => {
					e.preventDefault();
					recordDetailPropOnChange("topLevelTab", analyticsTabIndex);
				}}
			/>
		</Tooltip>
	);
}
