import { useCallback, useEffect, useState } from "react";
import { store } from "../../../store";
import { cancelDismissTimer, dismissToastById, setDismissTimer } from "../store/toastSlice";
import { Transition } from "@headlessui/react";
import { tw } from "../../../utils/tailwind-helpers";
import { ToastMessage } from "./ToastMessage";
import { Toast } from "../../../components/Toast";
import { setDismissToastTimeout } from "../utils";
import { ToastEvent } from "../types";

interface ToastControllerProps {
	toastEvent: ToastEvent;
	width?: number;
}

export function ToastController({ toastEvent, width }: ToastControllerProps) {
	const [isShowing, setIsShowing] = useState(false);

	useEffect(() => {
		setIsShowing(!toastEvent.dismissed);
	}, [toastEvent.dismissed]);

	const onDismissClick = useCallback(() => {
		store.dispatch(dismissToastById(toastEvent.id));
	}, [toastEvent.id]);

	const onMouseEnter = useCallback(() => {
		store.dispatch(cancelDismissTimer(toastEvent.id));
	}, [toastEvent.id]);

	const onMouseLeave = useCallback(() => {
		store.dispatch(setDismissTimer({ toastId: toastEvent.id, timeoutId: setDismissToastTimeout(toastEvent.id) }));
	}, [toastEvent.id]);

	let messageElement;
	if (toastEvent.message) {
		messageElement = toastEvent.message;
	} else if (toastEvent.toastMessageProps) {
		const props = toastEvent.toastMessageProps;
		messageElement = <ToastMessage {...props} />;
	} else {
		return null;
	}

	return (
		<Transition
			as="div"
			enter={tw`transition ease-in duration-200`}
			enterFrom={tw`-translate-y-5 opacity-0`}
			enterTo={tw`translate-y-0 opacity-100`}
			leave={tw`transition ease-out duration-100`}
			leaveFrom={tw`opacity-100`}
			leaveTo={tw`opacity-0`}
			show={isShowing}
		>
			<Toast
				type={toastEvent.type}
				onDismissClick={onDismissClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				message={messageElement}
				width={width}
			/>
		</Transition>
	);
}
