import {
	FieldInfo,
	getSDObjectFieldByName,
	getSDRecordFieldValue,
	SDObject,
	SDRecord,
} from "@salesdesk/salesdesk-schemas";
import { Tab } from "@headlessui/react";
import { ProductMediaFieldTab } from "./ProductMediaFieldTab";
import { useMemo } from "react";
import { PRODUCT_MEDIA_FIELD_TABS } from "../utils";
import { FIELD_TYPES } from "@salesdesk/salesdesk-model";
import { ProductFileFieldPreview } from "./ProductFileFieldPreview";

interface MediaFieldInfo extends FieldInfo {
	value: number;
}
interface ProductMediaFieldsProps {
	productObject: SDObject;
	productRecord: SDRecord;
}

export function ProductMediaFields({ productObject, productRecord }: ProductMediaFieldsProps) {
	const productMediaFieldAndValues = useMemo(() => {
		return PRODUCT_MEDIA_FIELD_TABS.map((tab) => {
			const field = getSDObjectFieldByName(productObject, tab.fieldName);
			if (!field) return undefined;
			return {
				field,
				value: getSDRecordFieldValue(productRecord, field?._id)?._value,
			};
		});
	}, [productObject, productRecord]);

	const filteredProductMediaFieldAndValues = useMemo(
		() => productMediaFieldAndValues.filter((fieldAndValue) => Boolean(fieldAndValue?.value)) as MediaFieldInfo[],
		[productMediaFieldAndValues]
	);

	const availableMediaFieldTabs = useMemo(
		() =>
			PRODUCT_MEDIA_FIELD_TABS.filter((tab) =>
				filteredProductMediaFieldAndValues.some((fieldAndValue) => fieldAndValue?.field?._name === tab.fieldName)
			),
		[filteredProductMediaFieldAndValues]
	);

	if (filteredProductMediaFieldAndValues.length > 1) {
		return (
			<div className="flex w-full">
				<Tab.Group>
					<Tab.List className="flex w-[60px] shrink-0 flex-col pt-8">
						{availableMediaFieldTabs.map((tab) => {
							const { field } =
								filteredProductMediaFieldAndValues.find(
									(fieldAndValue) => fieldAndValue?.field?._name === tab.fieldName
								) ?? {};
							return (
								<ProductMediaFieldTab
									key={tab.fieldName}
									icon={tab.icon}
									label={`${productObject._displayName} ${field?._displayName ?? ""}`}
								/>
							);
						})}
					</Tab.List>
					<Tab.Panels className="grow">
						{filteredProductMediaFieldAndValues.map(({ field, value }) => (
							<Tab.Panel key={field._id} unmount={field._type === FIELD_TYPES.PDF.name}>
								<ProductFileFieldPreview field={field} fileId={value} />
							</Tab.Panel>
						))}
					</Tab.Panels>
				</Tab.Group>
			</div>
		);
	}

	if (filteredProductMediaFieldAndValues.length === 1) {
		return (
			<ProductFileFieldPreview
				field={filteredProductMediaFieldAndValues[0].field}
				fileId={filteredProductMediaFieldAndValues[0].value}
			/>
		);
	}

	const productDefaultField = productMediaFieldAndValues[0]?.field;
	if (!productDefaultField) {
		return null;
	}

	return <ProductFileFieldPreview field={productDefaultField} fileId={undefined} />;
}
