import { PropsWithChildren } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import { AlertBubble, Icon } from "@salesdesk/daisy-ui";
import { tw } from "../../../utils/tailwind-helpers";
import { useDropdownTabContext } from "../hooks/useDropdownTabContext";
import { usePanelTabListContext } from "../hooks/usePanelTabListContext";

interface PanelTabProps {
	disabled?: boolean;
	count?: number;
	icon?: string;
}

export function PanelTab({ children, disabled, count, icon }: PropsWithChildren<PanelTabProps>) {
	const { isInDropdown } = useDropdownTabContext();
	const { variant } = usePanelTabListContext();

	return (
		<Tab
			disabled={disabled}
			className={({ selected }) => {
				let inDropdownStyles = undefined;
				let commonStyles = undefined;
				let variantStyles = undefined;
				if (isInDropdown) {
					inDropdownStyles = clsx(
						tw`text-label-nav gap-3 px-6 py-3`,
						!disabled && tw`hover:bg-c_bg_02`,
						selected && "bg-c_bg_02"
					);
				} else {
					commonStyles = clsx(
						tw`text-label-sm w-fit gap-2`,
						tw`focus-visible:ring-c_action_focus focus-visible:z-10 focus-visible:ring`,
						!disabled && tw`hover:bg-c_bg_03`
					);
					if (variant === "primary") {
						variantStyles = clsx(
							tw`rounded-t-md border-l border-r border-t px-4 py-3 focus-visible:rounded-t-md`,
							selected && tw`bg-c_bg_02 border-transparent`,
							!selected && tw`border-c_border_regular`
						);
					} else {
						variantStyles = clsx(
							tw`focus-visible:rounded-t-minimal rounded-t-minimal border-transparent px-4 py-2`,
							selected &&
								tw`border-b-c_action_01 after:bg-c_brand_primary after:absolute after:-bottom-px after:left-0 after:right-0 after:h-[3px]`,
							!selected && tw`border-b-c_border_regular`
						);
					}
				}
				return clsx(
					tw`relative flex items-center whitespace-nowrap transition-colors`,
					inDropdownStyles,
					commonStyles,
					variantStyles
				);
			}}
		>
			<Icon
				size={variant === "primary" ? "base" : "sm"}
				className={clsx(variant === "primary" ? "text-c_icon_regular" : "text-c_text_secondary", "flex items-center")}
				icon={icon}
			/>
			<div>{children}</div>
			{count ? <AlertBubble alertCount={count} variant="primary" /> : null}
		</Tab>
	);
}
