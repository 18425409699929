import { RefObject, useEffect, useRef, useState } from "react";
import { throttle } from "../../utils";

const ROW_END_OFFSET = 16;

const STICKY_MENU_WIDTH = 32;
const STICKY_MENU_OFFSET = 24;

export function useShowFloatingMenu(
	tableRef: RefObject<HTMLDivElement>,
	scrollContainerRef?: RefObject<HTMLDivElement>,
) {
	const [showFloatingMenu, setShowFloatingMenu] = useState(true);

	const floatingMenuRef = useRef<HTMLDivElement>(null);
	const rowEndRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const scrollContainer = scrollContainerRef?.current ?? tableRef.current;

		if (!scrollContainer) {
			return;
		}

		const updateShowStickyMenu = () => {
			const scrollContainerEl = scrollContainerRef?.current ?? tableRef.current;
			const tableEl = tableRef.current;

			const floatingMenuEl = floatingMenuRef.current;
			const rowEndEl = rowEndRef.current;

			if (!tableEl || !scrollContainerEl || !floatingMenuEl || !rowEndEl) {
				return;
			}

			const isRowEndInView =
				rowEndEl.getBoundingClientRect().left <= scrollContainerEl.getBoundingClientRect().right - ROW_END_OFFSET;

			setShowFloatingMenu(!isRowEndInView);

			if (isRowEndInView) {
				floatingMenuEl.style.left = "0px";
				return;
			}

			// Difference between the scroll container start and the table element start
			const tableScrollContainerDifference =
				tableEl.getBoundingClientRect().left -
				scrollContainerEl.getBoundingClientRect().left +
				scrollContainerEl.scrollLeft;

			// Updates style directly to avoid delay from re-rendering the react component
			floatingMenuEl.style.left = `${
				scrollContainerEl.scrollLeft +
				scrollContainerEl.clientWidth -
				tableScrollContainerDifference -
				STICKY_MENU_WIDTH -
				STICKY_MENU_OFFSET
			}px`;
		};

		updateShowStickyMenu();

		const throttledUpdateShowStickyMenu = throttle(updateShowStickyMenu, 100);

		const tableColumnObserver = new MutationObserver(throttledUpdateShowStickyMenu);
		const observer = new ResizeObserver(throttledUpdateShowStickyMenu);

		const tableColumns = tableRef.current?.querySelector("thead");

		if (tableColumns) {
			tableColumnObserver.observe(tableColumns, { childList: true, subtree: true, attributes: true });
		}

		scrollContainer.addEventListener("scroll", updateShowStickyMenu);
		observer.observe(scrollContainer);

		return () => {
			observer.disconnect();
			tableColumnObserver.disconnect();
			scrollContainer.removeEventListener("scroll", updateShowStickyMenu);
		};
	}, [tableRef, scrollContainerRef]);

	return { showFloatingMenu, floatingMenuRef, rowEndRef };
}
