import { GeneralPreviewFieldProps } from "../types";

export function GeneralPreviewField({ placeholder, containedIcon }: GeneralPreviewFieldProps) {
	return (
		<div className="bg-c_bg_03 flex min-h-[40px] select-none rounded-sm py-2 pl-4 pr-2">
			<p className="text-c_text_placeholder w-full">{placeholder}</p>
			{containedIcon}
		</div>
	);
}
