import { asConst } from "json-schema-to-ts";
import { participantIdentitySchema } from "./participantIdentitySchema";
import { sdRecordSchema } from "../records";

export const participantIdentityUserSchema = asConst({
	title: "ParticipantIdentityUser",
	description: "A ParticipantIdentityUser schema",
	type: "object",
	additionalProperties: false,
	required: ["identity", "userRecord"],
	properties: {
		identity: participantIdentitySchema,
		userRecord: { ...sdRecordSchema, nullable: true },
	},
});
