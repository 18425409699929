import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import { formatVideoTimestamp } from "../../../../files/components/VideoPlayer/utils";
import { UserPill, UserPillController } from "../../../../users";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import {
	TranscriptSentiment,
	transcriptSentimentColors,
	TranscriptSentimentType,
	UserIdOrPlaceholderName,
} from "../../../types";
import { TranscriptSentimentTooltip } from "./TranscriptSentimentTooltip";

interface TranscriptSentimentRowProps {
	userIdOrPlaceholderName: UserIdOrPlaceholderName;
	transcriptSentiments: TranscriptSentiment[];
}

export function TranscriptSentimentRow({ userIdOrPlaceholderName, transcriptSentiments }: TranscriptSentimentRowProps) {
	const { videoDuration, zoomFactor, viewStartTime, onlyTotalTalkingTime } = useMediaAnalysisContext();
	const [activeSentiment, setActiveSentiment] = useState<TranscriptSentimentType | null>(null);
	const [activeSentimentElement, setActiveSentimentElement] = useState<HTMLDivElement | null>(null);

	const graphContainerRef = useRef<HTMLDivElement>(null);
	const graphContainerElement = graphContainerRef.current;
	const graphContainerElementWidth = graphContainerElement?.getBoundingClientRect().width ?? 0;

	useEffect(() => {
		if (!graphContainerElement) {
			return;
		}
		graphContainerElement.scrollLeft = (viewStartTime / videoDuration) * graphContainerElementWidth * zoomFactor;
	}, [graphContainerElement, graphContainerElementWidth, videoDuration, viewStartTime, zoomFactor]);

	const talkingTime = useMemo(() => {
		return transcriptSentiments.reduce((acc, sentiment) => acc + (sentiment.endTstamp - sentiment.startTstamp), 0);
	}, [transcriptSentiments]);

	return (
		<div className="relative flex flex-col gap-2">
			{activeSentiment && activeSentimentElement ? (
				<TranscriptSentimentTooltip activeSentiment={activeSentiment} transcriptBarElement={activeSentimentElement} />
			) : null}
			<div className="flex w-full items-center justify-stretch">
				<div className={onlyTotalTalkingTime ? "w-1/4" : undefined}>
					{typeof userIdOrPlaceholderName === "number" ? (
						<UserPillController userId={userIdOrPlaceholderName} />
					) : (
						<UserPill user={userIdOrPlaceholderName} isSystemUser={true} />
					)}
				</div>
				<div className="flex-grow">
					{onlyTotalTalkingTime ? (
						<div
							className="bg-c_bg_05 relative h-2 min-w-2 rounded-full"
							style={{
								width: `${(talkingTime / videoDuration) * 100}%`,
							}}
						/>
					) : null}
				</div>
				<div
					className={clsx(
						"text-label-sm text-c_text_placeholder text-right",
						onlyTotalTalkingTime ? "whitespace-nowrap" : undefined
					)}
				>
					{!onlyTotalTalkingTime ? "Total " : ""}
					{formatVideoTimestamp(talkingTime)}
				</div>
			</div>
			{!onlyTotalTalkingTime ? (
				<div ref={graphContainerRef} className="bg-c_bg_01 h-1 overflow-hidden rounded-full">
					<div
						className="relative h-full"
						style={{
							width: `${zoomFactor * 100}%`,
						}}
					>
						{transcriptSentiments.map((sentiment, index) => {
							return (
								<div
									key={sentiment.startTstamp ?? index}
									className="absolute top-0 h-full rounded-full"
									style={{
										left: `${(sentiment.startTstamp / videoDuration) * 100}%`,
										width: `${((sentiment.endTstamp - sentiment.startTstamp) / videoDuration) * 100}%`,
										backgroundColor: transcriptSentimentColors[sentiment.sentiment.Sentiment],
									}}
									onMouseMove={(e) => {
										setActiveSentiment(sentiment.sentiment.Sentiment);
										setActiveSentimentElement(e.target as HTMLDivElement);
									}}
									onMouseLeave={() => {
										setActiveSentiment(null);
									}}
								/>
							);
						})}
					</div>
				</div>
			) : null}
		</div>
	);
}
