import { asConst } from "json-schema-to-ts";
import { searchSizeQuerySchema } from "./searchSizeQuerySchema";
import { searchAfterSchema } from "../searchAfterSchema";
import { SDNotificationType } from "./SDNotificationType";

export const notificationsQuerySchema = asConst({
	title: "Notifications Query",
	description: "Notifications query string parameters",
	type: "object",
	additionalProperties: false,
	properties: {
		notificationType: {
			title: "Notification Type",
			description: "The type of notification",
			type: "string",
			enum: Object.values(SDNotificationType),
		},
		size: searchSizeQuerySchema,
		searchAfter: searchAfterSchema,
	},
});
