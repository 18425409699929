import { useCallback, useEffect, useRef } from "react";

const DEFAULT_GRID_SPACING = 12;
const MAX_GRID_ELEMENT_SIZE = 3000;

// Key is used to retrigger the grid calculation logic
export function useResizableVideoGrid(gridSpacing = DEFAULT_GRID_SPACING, key = 0) {
	const gridContainerRef = useRef<HTMLDivElement>(null);

	const recalculateGridElements = useCallback(() => {
		const gridContainer = gridContainerRef?.current;

		if (!gridContainer) return;

		const styles = getComputedStyle(gridContainer);
		const clientRect = gridContainer.getBoundingClientRect();

		const width = clientRect.width - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
		const height = clientRect.height - parseFloat(styles.paddingTop) - parseFloat(styles.paddingBottom);

		const videos = document.querySelectorAll("[data-video-grid-element]");

		const marginOffset = gridSpacing * 2;

		// If only one video it is displayed with 16:9 ratio
		if (videos.length === 1) {
			const video = videos[0] as HTMLElement;

			let videoHeight = height - marginOffset;
			let videoWidth = videoHeight * (16 / 9);

			if (videoWidth > width - marginOffset) {
				videoWidth = width - marginOffset;
				videoHeight = videoWidth * (9 / 16);
			}

			video.style.width = `${Math.round(videoWidth)}px`;
			video.style.height = `${Math.round(videoHeight)}px`;

			video.style.margin = `${gridSpacing}px`;
			return;
		}

		let maxGridElementSize = MAX_GRID_ELEMENT_SIZE;
		for (let gridElementSize = 0; gridElementSize < MAX_GRID_ELEMENT_SIZE; gridElementSize++) {
			const canFit = canGridElementsFit(width, height, gridElementSize, videos.length);

			if (canFit === false) {
				maxGridElementSize = gridElementSize - 1;
				break;
			}
		}

		const elementSize = maxGridElementSize - gridSpacing * 2;

		videos.forEach((video) => {
			if (!(video instanceof HTMLElement)) {
				return;
			}

			video.style.width = `${elementSize}px`;
			video.style.height = `${elementSize}px`;
			video.style.margin = `${gridSpacing}px`;
		});
	}, [gridSpacing]);

	useEffect(() => {
		const gridContainer = gridContainerRef?.current;

		if (!gridContainer) return;

		const observer = new ResizeObserver(() => {
			recalculateGridElements();
		});

		observer.observe(gridContainer);
		return () => {
			// Cleans up the observer by unobserving all elements
			observer.disconnect();
		};
	}, [key, recalculateGridElements]);

	recalculateGridElements();

	return gridContainerRef;
}

/*
    Returns the given card width if they can all fit within the container
    otherwise returns false
*/
function canGridElementsFit(
	containerWidth: number,
	containerHeight: number,
	gridElementWidth: number,
	totalGridElements: number
) {
	if (gridElementWidth > containerWidth) {
		return false;
	}

	// Ratio of 1:1 so cards have the same width and height
	let totalHeight = gridElementWidth;

	if (totalHeight > containerHeight) {
		return false;
	}

	let currentRowWidth = 0;

	for (let i = 0; i < totalGridElements; i++) {
		// Moves card to the next row
		if (currentRowWidth + gridElementWidth > containerWidth) {
			currentRowWidth = 0;

			totalHeight += gridElementWidth;

			if (totalHeight > containerHeight) {
				return false;
			}
		}

		currentRowWidth += gridElementWidth;
	}

	return gridElementWidth;
}
