import { Emoji } from "../../../../../components/EmojiPicker";
import { Skeleton } from "../../../../../components/Skeleton/Skeleton";
import { TipTapContent } from "./TipTapContent";

type TipTapContentWrapperProps = {
	isLoadingCreatedUserName?: boolean;
	createdUserName: string | undefined;
	isLoadingContent?: boolean;
	tipTapContent: string | undefined;
	emoji?: string;
};

export function TipTapContentWrapper({
	isLoadingCreatedUserName,
	createdUserName,
	isLoadingContent,
	tipTapContent,
	emoji,
}: TipTapContentWrapperProps) {
	if ((!isLoadingContent && !tipTapContent) || (!isLoadingCreatedUserName && !createdUserName)) {
		return null;
	}

	return (
		<div className="flex items-center gap-2">
			{emoji ? <Emoji native={emoji} size="md" /> : null}
			<div className="w-full truncate">
				{isLoadingCreatedUserName ? (
					<Skeleton className="my-1.5 h-4 w-24" />
				) : (
					<div className="text-label-xs text-c_text_secondary py-1.5">{createdUserName}</div>
				)}
				{isLoadingContent || !tipTapContent ? (
					<div className="flex flex-col gap-1.5">
						<Skeleton className="h-4 w-full" />
						<Skeleton className="h-4 w-11/12" />
						<Skeleton className="h-4 w-2/3" />
					</div>
				) : (
					<TipTapContent content={tipTapContent} />
				)}
			</div>
		</div>
	);
}
