import { ReactNode, useEffect, useState } from "react";

import { useVideoCallLogicContext } from "../../VideoCallProvider";
import { VideoCallEvent } from "../types";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

export function useGetVideoCallToastContents(event: VideoCallEvent): ReactNode | null {
	const { callParticipantDetails } = useVideoCallLogicContext();
	const [persistedParticipantName, setPersistedParticipantName] = useState<string | null>(null);

	useEffect(() => {
		if (!event.participantName) {
			return;
		}

		const participantDetails = callParticipantDetails.find(
			(participantDetails) => participantDetails.participant.identity === event.participantName
		);

		if (participantDetails) {
			setPersistedParticipantName(participantDetails.name);
		}
	}, [callParticipantDetails, event.participantName]);

	const participantName = persistedParticipantName || event.participantName;

	if (event.type === "joined_meeting" && participantName) {
		return `${participantName} has joined`;
	}

	if (event.type === "left_meeting" && participantName) {
		return `${participantName} has left`;
	}

	if (event.type === "start_screenshare" && participantName) {
		return `${participantName} has started screen sharing`;
	}

	if (event.type === "stop_screenshare" && participantName) {
		return `${participantName} has stopped screen sharing`;
	}

	if (event.type === "start_fileshare" && participantName) {
		return `${participantName} has started presenting`;
	}

	if (event.type === "stop_fileshare" && participantName) {
		return `${participantName} has stopped presenting`;
	}

	if (event.type === "start_recording") {
		return `Meeting recording has started`;
	}

	if (event.type === "stop_recording") {
		return `Meeting recording has stopped`;
	}

	if (event.type === "microphone_disabled") {
		return toastWithIcon(ICONS.microphoneOff, "Microphone off");
	}

	if (event.type === "microphone_enabled") {
		return toastWithIcon(ICONS.microphoneOn, "Microphone on");
	}

	if (event.type === "video_disabled") {
		return toastWithIcon(ICONS.videoCameraOff, "Camera off");
	}

	if (event.type === "video_enabled") {
		return toastWithIcon(ICONS.videoCamera, "Camera on");
	}

	return null;
}

function toastWithIcon(icon: string, text: string) {
	return (
		<>
			<Icon icon={icon} className="text-c_icon_inverted flex" /> {text}
		</>
	);
}
