import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../../components";

export const existsClauseSchema = asConst({
	title: "Exists",
	description: "Will match any record where the given field has a value",
	type: "object",
	additionalProperties: false,
	required: ["exists"],
	properties: {
		exists: stringSchema,
	},
	nullable: false,
});
