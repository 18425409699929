import { useCallback, useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import clsx from "clsx";

import { mContactDef, mSalesDeskUserDef, mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { Button } from "@salesdesk/daisy-ui";

import { Divider } from "../../../../../../../components/Divider/Divider";
import { useGetObjectById } from "../../../../../../../hooks/useGetObjectById";

import { CreateWorkspaceStepProps, OriginRecordDetails, UpdateAssociationRecordsToLinkFn } from "../../../types";
import { useObjectAssociations } from "../../../../../../recordAssociations";
import { WorkspaceMemberSection } from "./WorkspaceMemberSection";
import { useGetCurrentUserRecord } from "../../../../../../users";

interface AddTeamMembersPanelProps extends CreateWorkspaceStepProps {
	originRecordDetails: OriginRecordDetails;
	updateAssociationRecordsToLink: UpdateAssociationRecordsToLinkFn;
}

const WORKSPACE_MEMBERS_RECORD_TYPES = [mContactDef.ID, mSalesDeskUserDef.ID];

export function AddTeamMembersPanel({
	workspaceObject,
	updateAssociationRecordsToLink,
	isVisible,
	nextStep,
	previousStep,
}: AddTeamMembersPanelProps) {
	const {
		objectAssociations: { prioritizedAssociations },
	} = useObjectAssociations(mWorkspaceDef.ID, WORKSPACE_MEMBERS_RECORD_TYPES);

	const userRecord = useGetCurrentUserRecord();

	const { sdObject: contactObject } = useGetObjectById(mContactDef.ID);

	const contactPluralName = contactObject?._pluralName ?? "Contacts";

	const onMembersChange = useCallback(
		(memberObjectId: number, selectedRecords: SDRecord[]) => {
			const association = prioritizedAssociations.find(
				(association) => association.connectedObject.id === memberObjectId
			);

			if (!association) {
				return;
			}

			updateAssociationRecordsToLink(association, selectedRecords);
		},
		[prioritizedAssociations, updateAssociationRecordsToLink]
	);

	const [hasRendered, setHasRendered] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setHasRendered(true);
		}
	}, [isVisible]);

	// Only return null if it hasn't been initially rendered (i.e. the step hasn't been reached)
	// Allows for more responsive behaviour when switching between steps and allows the typeaheads
	// to keep track of their own state without needing to be controlled
	if (!hasRendered) {
		return null;
	}

	return (
		<div className={clsx("flex h-full flex-col overflow-hidden", !isVisible && "hidden")}>
			<div className="mb-8 flex flex-col gap-4">
				<h4 className="text-h4">Team members</h4>
				<p>Add {contactPluralName} or internal team members to collaborate.</p>
			</div>
			<div className="flex h-full max-h-full flex-col gap-8 overflow-auto">
				<WorkspaceMemberSection
					title={contactPluralName}
					description={`Add ${contactPluralName} who you want to collaborate with in this ${workspaceObject._displayName}.`}
					memberObjectId={mContactDef.ID}
					onMembersChange={onMembersChange}
				/>

				<Divider className="m-0" />
				<WorkspaceMemberSection
					title={"Internal Team"}
					description={`Add internal team members who you want to collaborate with in this ${workspaceObject._displayName}.`}
					memberObjectId={mSalesDeskUserDef.ID}
					initialValue={userRecord ? [userRecord] : undefined}
					onMembersChange={onMembersChange}
				/>
			</div>
			<div className="mt-8 flex justify-end gap-3">
				<Button onClick={previousStep} variant="secondary" size="lg">
					Previous
				</Button>
				<Button onClick={nextStep} size="lg" variant="primary">
					Next
				</Button>
			</div>
		</div>
	);
}
