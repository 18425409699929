import { ColumnSizingInfoState, Header } from "@tanstack/react-table";
import { ROW_LINK_COLUMN_ID, TableRow } from "../../types";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { tw } from "../../../../utils/tailwind-helpers";
import { useLinkedTablesState } from "../../hooks/useLinkedTables";

interface ColumnResizeTracker<T> {
	columnHeaders: Header<TableRow<T>, unknown>[];
	columnSizingInfo: ColumnSizingInfoState;
	controlColumnWidth?: number;
}

const TRACKER_OFFSET = 1;

export function ColumnResizeTracker<T>({
	columnHeaders,
	columnSizingInfo,
	controlColumnWidth,
}: ColumnResizeTracker<T>) {
	const [trackerPosition, setTrackerPosition] = useState(0);

	const { isResizingColumn: resizingColumnId, startSize, deltaOffset } = columnSizingInfo;

	if (resizingColumnId) {
		let totalWidth = 0;

		for (const header of columnHeaders) {
			if (header.id === ROW_LINK_COLUMN_ID) {
				continue;
			}

			if (header.id === resizingColumnId) {
				const isLastHeader = header.id === columnHeaders[columnHeaders.length - 1].id;
				const minWidth = (header.column.columnDef.minSize || 0) + (isLastHeader ? controlColumnWidth || 0 : 0);

				totalWidth += Math.max((startSize || 0) + (deltaOffset || 0), minWidth);
				break;
			}

			totalWidth += header.getSize();
		}

		const newTrackerPosition = Math.max(totalWidth - TRACKER_OFFSET, 0);

		if (trackerPosition !== newTrackerPosition) {
			setTrackerPosition(newTrackerPosition);
		}
	}

	const linkedTableState = useLinkedTablesState();
	const linkedTableTrackerPosition = linkedTableState?.trackerPosition;
	const setLinkedTableTrackerPosition = linkedTableState?.setTrackerPosition;

	useEffect(() => {
		if (!setLinkedTableTrackerPosition) return;
		setLinkedTableTrackerPosition(resizingColumnId ? trackerPosition : undefined);
	}, [resizingColumnId, trackerPosition, setLinkedTableTrackerPosition]);

	return (
		<Transition
			show={Boolean(resizingColumnId) || Boolean(linkedTableTrackerPosition)}
			enter={tw`transition-opacity`}
			enterFrom={tw`opacity-0`}
			enterTo={tw`opacity-100`}
		>
			<div
				className="bg-c_action_04 absolute bottom-0 top-0 z-10 w-px"
				style={{ left: linkedTableTrackerPosition ?? trackerPosition }}
			/>
		</Transition>
	);
}
