import { useEffect, useId, useMemo } from "react";
import { generateText, JSONContent } from "@tiptap/core";
import { FormProvider, useForm } from "react-hook-form";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button, useThemeProviderContext } from "@salesdesk/daisy-ui";
import { TenantSettings } from "@salesdesk/salesdesk-schemas";
import { createHashId } from "@salesdesk/salesdesk-utils";

import { getFullRecordFormFieldId, RECORD_FIELDS_KEY, StickyFormControls } from "../../../records";
import { EditLogoField, EditRichTextField, EditTextField, ThemeSelectorField } from "../../../fields";
import { FormFieldSet } from "../../../forms";
import { Divider } from "../../../../components/Divider/Divider";
import { useToast } from "../../../Toasts";
import { usePatchTenantSettingsMutation } from "../../../tenant";
import { getFrontendThemeNameFromId, getCrmThemeId, getSelectedBackendThemeName, getThemeField } from "../utils";
import { DEFAULT_CRM_THEME } from "../constants";
import { getFrontEndRichTextExtensions } from "../../../../utils/getFrontEndRichTextExtensions";
import { PATHS } from "../../../../routes";
import { Link } from "../../../../components/Link";

interface AccountFormProps {
	tenantSettings: TenantSettings;
}

interface AccountFormData extends Record<string, any> {
	accountName: string;
	accountLogo: number | null;
	workspaceListWelcomeText: JSONContent;
}
export function AccountForm({ tenantSettings }: AccountFormProps) {
	const formId = useId();

	const { setTheme } = useThemeProviderContext();
	const themeField = useMemo(() => getThemeField(), []);
	const themeFieldName = getFullRecordFormFieldId(RECORD_FIELDS_KEY, String(themeField._id));

	const defaultValues = {
		accountName: tenantSettings.accountName,
		accountLogo: tenantSettings.accountLogo,
		[themeFieldName]: getCrmThemeId(tenantSettings) ?? createHashId(DEFAULT_CRM_THEME),
		workspaceListWelcomeText: tenantSettings.workspaceListWelcomeText
			? JSON.parse(tenantSettings.workspaceListWelcomeText)
			: ({ type: "doc", content: [{ type: "paragraph" }] } as JSONContent),
	};

	const formMethods = useForm<AccountFormData>({
		mode: "onChange",
		defaultValues,
	});
	const { control, handleSubmit, watch, reset } = formMethods;

	const themeValue = watch(themeFieldName);
	useEffect(() => {
		if (typeof themeValue === "number") {
			setTheme(getFrontendThemeNameFromId(themeValue) ?? DEFAULT_CRM_THEME);
		} else {
			setTheme(DEFAULT_CRM_THEME);
		}
	}, [themeField, themeValue, setTheme, themeFieldName]);

	const [patchTenantSettings, { isLoading }] = usePatchTenantSettingsMutation();

	const toast = useToast();

	const onSubmit = (data: Record<string, any>) => {
		const welcomeMessageIsEmpty =
			!data.workspaceListWelcomeText ||
			!generateText(data.workspaceListWelcomeText, getFrontEndRichTextExtensions()).trim();
		patchTenantSettings({
			accountName: data.accountName,
			accountLogo: data.accountLogo,
			crmTheme: getSelectedBackendThemeName(themeField, data),
			workspaceListWelcomeText:
				data.workspaceListWelcomeText && !welcomeMessageIsEmpty ? JSON.stringify(data.workspaceListWelcomeText) : null,
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "account_settings_updated" });
				reset(data);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "account_settings_updated" });
			});
	};

	const workspacesUrl = `${window.location.origin}${PATHS.WORKSPACES()}`;

	return (
		<form id={formId} onSubmit={handleSubmit(onSubmit)} className="relative flex flex-col gap-8">
			<FormProvider {...formMethods}>
				<FormFieldSet
					control={control}
					name="accountName"
					label="Account name"
					labelIcon={ICONS.text}
					required
					rules={{ required: "Account name is required" }}
				>
					{({ field: { value, ...field } }) => <EditTextField title="Account name" value={String(value)} {...field} />}
				</FormFieldSet>
				<FormFieldSet control={control} name="accountLogo" label="Account logo" labelIcon={ICONS.shapes}>
					{({ field }) => (
						<EditLogoField
							value={{ fileId: field.value ? Number(field.value) : null, companyName: watch("accountName") }}
							onChange={field.onChange}
							isPublic
						/>
					)}
				</FormFieldSet>
				<Divider className="" />
				<div className="flex flex-col gap-4">
					<div className="text-label">Themes</div>
					<div className="text-c_text_secondary">
						Choose a colour theme for the internal area of your SalesDesk account.
					</div>
					<ThemeSelectorField themeField={themeField} />
				</div>
				<Divider className="" />
				<div className="flex flex-col gap-6">
					<div className="text-label">Workspaces</div>
					<div className="text-label text-c_text_secondary">Customer access</div>
					<div className="text-c_text_secondary">
						Use this URL as a login link so that your customers can access their {watch("accountName")} workspaces
						directly from your website.
					</div>
					<div className="flex w-full items-center justify-between pb-4">
						<Link to={workspacesUrl} text={workspacesUrl} inline size="xs" />
						<Button
							variant="primary_text"
							startIcon={ICONS.copy}
							size="sm"
							onClick={() => {
								navigator.clipboard.writeText(workspacesUrl);
								toast.trigger("success", "Link copied.");
							}}
						>
							Copy link
						</Button>
					</div>
					<div className="text-label text-c_text_secondary">Welcome message</div>
					<div className="text-c_text_secondary">
						Customise the welcome message that your customers will see when they access their list of workspaces.
					</div>
					<div>
						<img
							src="/static/images/graphics/workspace-welcome-graphic.png"
							alt="Welcome message list of workspaces page"
						/>
					</div>
					<FormFieldSet control={control} name="workspaceListWelcomeText" label="Message" labelIcon={ICONS.paragraph}>
						{({ field }) => <EditRichTextField {...field} />}
					</FormFieldSet>
				</div>
				<StickyFormControls formId={formId} isLoading={isLoading} defaultValues={defaultValues} />
			</FormProvider>
		</form>
	);
}
