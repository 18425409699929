import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useWebPrincipal } from "../../../auth";
import { useGetRecordQuery } from "../../records";

export function useGetCurrentUserRecord() {
	const principal = useWebPrincipal();
	const { currentData: userRecord } = useGetRecordQuery(principal.UserRecordId ?? skipToken);

	return userRecord;
}
