import { SDApiKey } from "@salesdesk/salesdesk-schemas";
import { useUpdateApiKeyMutation } from "../api/apiKeysApi";
import { useToast } from "../../../Toasts";
import { useId } from "react";
import { useForm } from "react-hook-form";
import { FormDialog } from "../../../Dialog";
import { FormFieldSet } from "../../../forms";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { EditTextField } from "../../../fields";
import { FieldSet } from "@salesdesk/daisy-ui";

type FormData = { name: string };

interface EditApiKeyDialogProps {
	apiKey: SDApiKey;
	onClose: () => void;
}

export function EditApiKeyDialog({ apiKey, onClose }: EditApiKeyDialogProps) {
	const formId = useId();

	const toast = useToast();
	const [updateApiKey, { isLoading: isSaving }] = useUpdateApiKeyMutation();

	const { control, handleSubmit } = useForm<FormData>({
		mode: "onChange",
		defaultValues: { name: apiKey.name },
	});

	const onFormSubmit = (formData: FormData) => {
		updateApiKey({
			id: apiKey.apiKeyId,
			body: { name: formData.name },
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "api_key_updated" });
				onClose();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "api_key_updated" });
			});
	};

	return (
		<FormDialog
			open
			onOpenChange={(open) => {
				if (!open) {
					onClose();
				}
			}}
			title="Edit API key"
			width={600}
			isPending={isSaving}
			formId={formId}
			submitButtonLabel="Save"
		>
			<form id={formId} onSubmit={handleSubmit(onFormSubmit)} className="mb-3 flex flex-col gap-6">
				<FieldSet label="Key ID">
					<div className="text-c_text_secondary">{apiKey.apiKeyId}</div>
				</FieldSet>
				<FormFieldSet
					control={control}
					name="name"
					label="Key name"
					labelIcon={ICONS.text}
					required
					rules={{ required: "Key name is required" }}
				>
					{({ field, fieldState: { error } }) => (
						<EditTextField title="Key name" {...field} hasError={Boolean(error?.message)} autoFocus />
					)}
				</FormFieldSet>
			</form>
		</FormDialog>
	);
}
