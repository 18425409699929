import { PropsWithChildren, useState } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

import { AssociationActionsContext } from "../hooks/useAssociationActionsContext";
import { DeleteRecordAssociationDialog } from "./DeleteRecordAssociationDialog";
import { RecordPreviewDialog } from "../../../../records";
import { DirectedSDObjectAssociation } from "../../../hooks";

interface AssociationActionsProviderProps {
	sourceRecord?: SDRecord;
	objectAssociation: DirectedSDObjectAssociation;
}

export function AssociationActionsProvider({
	sourceRecord,
	objectAssociation,
	children,
}: PropsWithChildren<AssociationActionsProviderProps>) {
	const [associationRecordToRemove, setAssociationRecordToRemove] = useState<SDRecord>();
	const [associationRecordToPreview, setAssociationRecordToPreview] = useState<SDRecord>();

	return (
		<AssociationActionsContext.Provider
			value={{
				sourceRecord,
				objectAssociation,
				setAssociationRecordToPreview,
				setAssociationRecordToRemove,
			}}
		>
			{children}
			{associationRecordToPreview ? (
				<RecordPreviewDialog
					recordId={associationRecordToPreview._id}
					onClose={() => setAssociationRecordToPreview(undefined)}
				/>
			) : null}
			{sourceRecord && associationRecordToRemove ? (
				<DeleteRecordAssociationDialog
					associationRecord={associationRecordToRemove}
					onClose={() => setAssociationRecordToRemove(undefined)}
				/>
			) : null}
		</AssociationActionsContext.Provider>
	);
}
