import { useEffect, useState, useMemo } from "react";

import { useAppSelector, useAppDispatch } from "../../../../../store/store";
import { markEventAsPlayed } from "../store/videoCallEventsSlice";
import { VideoCallEvent } from "../types";
import { useVideoCallLogicContext } from "../../VideoCallProvider";

type SoundEffectRule = (videoCallEvent: VideoCallEvent) => boolean;
type SoundEffectInfo = { path: string; rule: SoundEffectRule };
type SoundEffectValue = { soundEffect: HTMLAudioElement; rule: SoundEffectRule };

const videoCallSoundEffectsInfo: SoundEffectInfo[] = [
	{
		path: "/static/audio/video-call-effects/call-starts.mp3",
		rule: (videoCallEvent: VideoCallEvent) =>
			videoCallEvent.type === "joined_meeting" && !videoCallEvent.participantName,
	},
	{
		path: "/static/audio/video-call-effects/other-user-join.mp3",
		rule: (videoCallEvent: VideoCallEvent) =>
			videoCallEvent.type === "joined_meeting" && Boolean(videoCallEvent.participantName),
	},
	{
		path: "/static/audio/video-call-effects/call-ends.mp3",
		rule: (videoCallEvent: VideoCallEvent) => videoCallEvent.type === "left_meeting" && !videoCallEvent.participantName,
	},
	{
		path: "/static/audio/video-call-effects/other-user-leave.mp3",
		rule: (videoCallEvent: VideoCallEvent) =>
			videoCallEvent.type === "left_meeting" && Boolean(videoCallEvent.participantName),
	},
	{
		path: "/static/audio/video-call-effects/new-message.mp3",
		rule: (videoCallEvent: VideoCallEvent) => videoCallEvent.type === "received_message",
	},
	{
		path: "/static/audio/video-call-effects/start-recording.mp3",
		rule: (videoCallEvent: VideoCallEvent) => videoCallEvent.type === "start_recording",
	},
	{
		path: "/static/audio/video-call-effects/stop-recording.mp3",
		rule: (videoCallEvent: VideoCallEvent) => videoCallEvent.type === "stop_recording",
	},
];

export function VideoCallSoundEffectsHandler() {
	const dispatch = useAppDispatch();
	const { currentMeetingRecord } = useVideoCallLogicContext();
	const videoCallEvents = useAppSelector((state) => state.videoCallEvents.events);

	const [loadAudioFiles, setLoadAudioFiles] = useState(false);

	useEffect(() => {
		if (currentMeetingRecord) {
			setLoadAudioFiles(true);
		}
	}, [currentMeetingRecord, videoCallEvents]);

	const videoCallSoundEffects: SoundEffectValue[] = useMemo(
		() =>
			loadAudioFiles
				? videoCallSoundEffectsInfo.map((videoCallSoundEffectInfo) => ({
						soundEffect: new Audio(videoCallSoundEffectInfo.path),
						rule: videoCallSoundEffectInfo.rule,
				  }))
				: [],
		[loadAudioFiles]
	);

	useEffect(() => {
		videoCallEvents.forEach((videoCallEvent) => {
			const { soundEffect } =
				videoCallSoundEffects.find((videoCallSoundEffect) => videoCallSoundEffect.rule(videoCallEvent)) || {};

			if (soundEffect && !videoCallEvent.dismissed && !videoCallEvent.played) {
				soundEffect.play();
				dispatch(markEventAsPlayed(videoCallEvent.id));
			}
		});
	}, [dispatch, videoCallEvents, videoCallSoundEffects]);

	return null;
}
