import { createContext, useContext } from "react";

import { OriginRecordDetails } from "../types";

interface CreateWorkspaceContextProps {
	openModal?: (originRecordDetails?: OriginRecordDetails) => void;
	isOpen?: boolean;
}

const initialValues: CreateWorkspaceContextProps = {
	openModal: undefined,
};

export const CreateWorkspaceContext = createContext<CreateWorkspaceContextProps>(initialValues);

export function useCreateWorkspaceContext(): CreateWorkspaceContextProps {
	const context = useContext(CreateWorkspaceContext);

	if (context === undefined) {
		throw new Error("useCreateWorkspaceContext must be used within the CreateWorkspaceModalProvider");
	}

	return context;
}
