import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";
import {
	mBooleanFieldDef,
	mRichTextFieldDef,
	mStringFieldDef,
	mColorFieldDef,
	mIconFieldDef,
	mSingleOptionFieldDef,
	BOARD_VIEW_OPTIONS,
} from "@salesdesk/salesdesk-model";
import { SDObject } from "@salesdesk/salesdesk-schemas";

export type DataSettingsFieldDefs =
	| mStringFieldDef
	| mRichTextFieldDef
	| mColorFieldDef
	| mIconFieldDef
	| mBooleanFieldDef
	| mSingleOptionFieldDef;

export interface DataSettingsGroupedFields {
	groupName?: string;
	fields: DataSettingsFieldDefs[];
}

const getDataSettingsFields = (): DataSettingsGroupedFields[] => {
	const displayName = new mStringFieldDef("_displayName");
	displayName.name = "_displayName";
	displayName.pluralName = "displayNames";
	displayName.displayName = "Display Name";
	displayName.required = true;

	const pluralName = new mStringFieldDef("_pluralName");
	pluralName.name = "_pluralName";
	pluralName.pluralName = "Plural Names";
	pluralName.displayName = "Plural display name";
	pluralName.required = true;

	const defaultView = new mSingleOptionFieldDef("_defaultView");
	defaultView.name = "_defaultView";
	defaultView.displayName = "Default View";
	defaultView.optionValues = BOARD_VIEW_OPTIONS;

	const description = new mRichTextFieldDef("_description");
	description.name = "_description";
	description.pluralName = "_descriptions";
	description.displayName = "Description";

	const color = new mColorFieldDef("_color");
	color.name = "_color";
	color.pluralName = "colors";
	color.displayName = "Color";
	color.componentType = FIELD_COMPONENT_TYPES.COLOR.name;
	color.required = true;

	const iconChooser = new mIconFieldDef("_icon");
	iconChooser.name = "_icon";
	iconChooser.pluralName = "icons";
	iconChooser.displayName = "Icon";
	iconChooser.required = true;

	return [
		{
			fields: [displayName, pluralName, defaultView, color, iconChooser, description],
		},
	];
};

export const getDataSettingsValues = (sdObject: SDObject, fieldDefs: DataSettingsFieldDefs[]) => {
	const values = {} as Record<string, any>;

	fieldDefs.forEach((fieldDef) => {
		const fieldName = fieldDef._name as keyof SDObject;

		const value = sdObject[fieldName];

		values[fieldName] = fieldDef.formatValue(value);
	});

	return values;
};

export const SETTINGS_FIELD_GROUPS = getDataSettingsFields();

export const SETTINGS_FIELDS = SETTINGS_FIELD_GROUPS.map(({ fields }) => fields).flat();
