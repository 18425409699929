import { asConst } from "json-schema-to-ts";
import { commentIdSchema } from "./commentIdSchema";
import {
	updatableSchema,
	creatableSchema,
	utcMillisecSchema,
	userIdSchema,
	tiptapJSONStringSchema,
} from "../components";
import { recordIdSchema } from "../records";
import { commentReactionSchema } from "./reactions";

export const commentSchema = asConst({
	title: "Comment",
	description: "A comment posted by a user on a SalesDesk record",
	type: "object",
	additionalProperties: false,
	required: ["id", "message", "reactions", ...updatableSchema.required],
	properties: {
		id: commentIdSchema,
		rootCommentId: commentIdSchema,
		message: tiptapJSONStringSchema,
		resolvedAt: utcMillisecSchema,
		resolvedBy: userIdSchema,
		reactions: { type: "array", items: commentReactionSchema },
		workspaceId: {
			...recordIdSchema,
			description: "Only send notifications for this workspace",
		},
		...creatableSchema.properties,
		updatedAt: utcMillisecSchema,
		updatedBy: userIdSchema,
	},
});
