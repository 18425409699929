import { asConst } from "json-schema-to-ts";
import { objectIdSchema } from "../object";
import { sdFileSchema } from "../files";

export const importCreateRequestSchema = asConst({
	title: "ImportCreateRequestSchema",
	description: "A request to create an Import",
	type: "object",
	additionalProperties: false,
	required: ["objectId", "fileId"],
	properties: {
		objectId: objectIdSchema,
		fileId: sdFileSchema.properties.fileId,
	},
});
