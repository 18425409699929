import { DisplayIf } from "../../../../../auth";
import { AbilityAction, AbilitySubject, sdSubject } from "@salesdesk/salesdesk-schemas";
import { RecordCreateButton } from "../../../../records";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useGetObjectById } from "../../../../../hooks";
import { mSalesDeskUserDef } from "@salesdesk/salesdesk-model";

interface CreateUserButtonProps {
	objectIds: number[];
}

export function CreateUserButton({ objectIds }: CreateUserButtonProps) {
	const { sdObject } = useGetObjectById(objectIds.length === 1 ? objectIds[0] : undefined);
	if (!sdObject || sdObject._id !== mSalesDeskUserDef.ID) {
		return null;
	}

	return (
		<DisplayIf can={[AbilityAction.Create, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id })]}>
			<RecordCreateButton
				key={`${sdObject._id}-create`}
				sdObject={sdObject}
				startIcon={ICONS.plus}
				size="sm"
				variant="primary"
			>
				New user
			</RecordCreateButton>
		</DisplayIf>
	);
}
