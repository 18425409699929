import { asConst } from "json-schema-to-ts";
import { subQuerySchema } from "../../../search";
import { recordSubQueryClausesSchema } from "./recordSubQueryClausesSchema";

export const recordSubQuerySchema = asConst({
	...subQuerySchema,
	properties: {
		and: { ...subQuerySchema.properties.and, items: recordSubQueryClausesSchema },
		not: { ...subQuerySchema.properties.not, items: recordSubQueryClausesSchema },
		or: { ...subQuerySchema.properties.or, items: recordSubQueryClausesSchema },
	},
});
