import { useCallback, useEffect, useMemo, useRef } from "react";

import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { AssociationMultiplicity } from "@salesdesk/salesdesk-model";

import { useGetObjectById } from "../../../../../../../../hooks/useGetObjectById";
import { SkeletonTransition } from "../../../../../../../../components/Skeleton/SkeletonTransition";
import { CollapsibleList } from "../../../../../../../../components/CollapsibleList";
import { DirectedSDObjectAssociation } from "../../../../../../../recordAssociations";
import { ObjectFilteringPopover, ObjectSortingPopover, RecordModalTable } from "../../../../../../../records";
import { DebouncedSearchbar } from "../../../../../../../inputs";
import { OriginRecordDetails } from "../../../../types";
import { usePotentialWorkspaceAssociationSearch } from "../hooks/usePotentialWorkspaceAssociationSearch";

interface AddWorkspaceAssociationTableProps {
	objectAssociation: DirectedSDObjectAssociation;
	originRecordDetails: OriginRecordDetails;
	updateRecordCount: (associationId: number, count: number) => void;
	selectedRecords: SDRecord[];
	updateSelectedRecords: (objectAssociation: DirectedSDObjectAssociation, records: SDRecord[]) => void;
}

export function AddWorkspaceAssociationTable({
	objectAssociation,
	originRecordDetails,
	updateRecordCount,
	selectedRecords,
	updateSelectedRecords,
}: AddWorkspaceAssociationTableProps) {
	const { sdObject: associationObject } = useGetObjectById(objectAssociation.connectedObject.id);

	const { potentialAssociationGroups, filter, setFilter, sorting, setSorting, setSearchQuery, isFiltered } =
		usePotentialWorkspaceAssociationSearch({ associationObject, originRecordDetails });

	const totalCount = potentialAssociationGroups.reduce((sum, group) => sum + (group.totalCount || 0), 0);
	useEffect(() => {
		updateRecordCount(objectAssociation.id, totalCount);
	}, [objectAssociation.id, totalCount, updateRecordCount]);

	const scrollRef = useRef<HTMLDivElement>(null);

	const selectedTableRecords = useMemo(() => {
		return selectedRecords.filter((record) => typeof record !== "number") as SDRecord[];
	}, [selectedRecords]);

	const setSelectedRecords = useCallback(
		(records: SDRecord[]) => {
			updateSelectedRecords(objectAssociation, records);
		},
		[objectAssociation, updateSelectedRecords]
	);

	if (!associationObject) {
		return;
	}

	return (
		<div className="flex h-full max-h-full w-full flex-col overflow-hidden">
			<div className="mb-6 flex justify-between">
				<div className="flex w-full flex-row gap-2">
					<ObjectFilteringPopover value={filter} onChange={setFilter} sdObject={associationObject} />
					<ObjectSortingPopover value={sorting} onChange={setSorting} sdObject={associationObject} />
					<div className="ml-auto">
						<DebouncedSearchbar onChange={setSearchQuery} isCollapsible={false} />
					</div>
				</div>
			</div>
			<div ref={scrollRef} className="flex max-h-full flex-grow flex-col gap-3 overflow-auto">
				{potentialAssociationGroups.map((potentialAssociations) => {
					const { title, totalCount, records, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage, resultsName } =
						potentialAssociations;

					const noResults = !isLoadingNewSearchParams && records.length === 0;

					return (
						<CollapsibleList key={potentialAssociations.title} title={title} count={totalCount} showZero>
							<SkeletonTransition className="mt-3 flex flex-col overflow-hidden">
								<RecordModalTable
									records={records}
									sdObject={associationObject}
									selectedRecords={selectedTableRecords}
									setSelectedRecords={setSelectedRecords}
									singleSelect={objectAssociation.connectedObject.multiplicity === AssociationMultiplicity.ONE}
									onBottomReached={loadNextPage}
									isLoadingRecords={isLoadingNewSearchParams}
									isLoadingNextPage={isLoadingNextPage}
									numOfPlaceholderRows={2}
									outsideScrollContainerRef={scrollRef}
								/>
								{noResults ? (
									<span className="text-body text-c_text_secondary my-6 text-center">
										{isFiltered
											? `No ${resultsName} match the selected filters`
											: `There are no ${resultsName} available`}
									</span>
								) : null}
							</SkeletonTransition>
						</CollapsibleList>
					);
				})}
			</div>
		</div>
	);
}
