import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { Spinner } from "@salesdesk/daisy-ui";
import { PageChangedParams } from "@salesdesk/salesdesk-model";

import { FilePreviewProps, PdfEvent } from "../../../types";
import { usePostEvent } from "../../../../events/hooks/usePostEvent";
import { PdfViewer } from "../../PdfViewer";

interface PdfPreviewProps extends FilePreviewProps {
	currentPage?: number;
	onPageChange?: (newPageNumber: number) => void;
	disabled?: boolean;
}

export function PdfPreview({
	file,
	onOpenPreviewClick,
	variant,
	disabled,
	currentPage,
	onPageChange,
}: PdfPreviewProps) {
	const isFileObject = file instanceof File;
	const signedUrl = isFileObject ? undefined : file.signedUrl;

	const [pdfFile, setPdfFile] = useState<File>();

	useEffect(() => {
		let active = true;

		if (!signedUrl || isFileObject || pdfFile) {
			return;
		}

		// TODO: better error handling for errors
		fetch(signedUrl)
			.then((r) => r.blob())
			.then((blob) => {
				if (!active) {
					return;
				}

				setPdfFile(new File([blob], file.name));
			});

		// Used to prevent race conditions
		return () => {
			active = false;
		};
	}, [signedUrl, isFileObject, file.name, pdfFile]);

	useEffect(() => {
		if (isFileObject) {
			setPdfFile(file);
		} else {
			setPdfFile(undefined);
		}
	}, [file, isFileObject]);

	const isExpandedView = variant === "expanded";

	const postEvent = usePostEvent();
	const onPdfEvent = useCallback(
		(eventType: PdfEvent, pageNumber: number) => {
			if (file instanceof File) {
				return;
			}
			postEvent({
				event_type: eventType,
				record_id: file.recordId,
				params: {
					fileId: file.fileId,
					pageNumber,
				} satisfies PageChangedParams,
			});
		},
		[file, postEvent]
	);

	return (
		<div
			className={clsx(
				"relative flex h-full w-full items-center justify-center",
				isExpandedView ? "bg-c_brand_dark" : undefined
			)}
		>
			<div className="absolute z-0">
				<Spinner darkMode={isExpandedView} size={isExpandedView ? "lg" : "sm"} />
			</div>
			{pdfFile && (
				<div className="z-[1] flex h-full w-full items-center justify-center">
					<PdfViewer
						pdfFile={pdfFile}
						renderTextLayer={isExpandedView || false}
						disabled={disabled}
						onOpenPreviewClick={onOpenPreviewClick}
						currentPage={currentPage}
						onPageChange={onPageChange}
						onPdfEvent={onPdfEvent}
					/>
				</div>
			)}
		</div>
	);
}
