import { ToastType } from "../../../components/Toast";
import { ReactNode } from "react";

export type ToastsPosition = "main" | "side_panel";

export const toastMessageKeys = [
	"generic",
	"record_created",
	"record_updated",
	"record_owner_changed",
	"record_bulk_owner_change",
	"record_bulk_update",
	"record_bulk_update_no_changes",
	"record_bulk_delete",
	"record_deleted",
	"record_favorited",
	"record_unfavorited",
	"record_batch_favorite",
	"record_batch_unfavorite",
	"user_login_enabled",
	"user_login_disabled",
	"user_resend_invitation",
	"customer_access_granted",
	"customer_access_revoked",
	"customer_resend_invitation",
	"record_watched",
	"record_unwatched",
	"record_batch_watch",
	"record_batch_unwatch",
	"role_created",
	"role_updated",
	"role_deleted",
	"bookmark_created",
	"bookmark_updated",
	"bookmark_deleted",
	"lead_converted_to_opportunity",
	"file_shared",
	"screen_shared",
	"object_created",
	"object_updated",
	"object_deleted",
	"record_association_created",
	"record_association_deleted",
	"record_associations_created",
	"batch_record_associations_created",
	"file_downloaded",
	"note_created",
	"note_deleted",
	"note_updated",
	"reaction_added",
	"reaction_deleted",
	"comment_created",
	"comment_deleted",
	"comment_updated",
	"comment_resolved",
	"comment_reaction_added",
	"comment_reaction_deleted",
	"notification_rule_created",
	"notification_rule_updated",
	"notification_rule_disabled",
	"notification_rule_enabled",
	"notification_rule_deleted",
	"doc_record_pdf_generated",
	"chat_message_send",
	"workspace_created",
	"workspace_share_record",
	"workspace_unshare_record",
	"batch_workspace_share_record",
	"workspace_settings_updated",
	"account_settings_updated",
	"notification_preferences_updated",
	"channel_updated",
	"channel_member_removed",
	"participant_added",
	"api_key_created",
	"api_key_updated",
	"api_key_deleted",
] as const;
export type ToastMessageKey = (typeof toastMessageKeys)[number];

export interface NodeParam {
	type: "node";
	node: ReactNode;
}

export interface LinkParam {
	type: "link";
	to: string;
	label?: string;
}

export type MessageTemplateParam = NodeParam | LinkParam | string | undefined;

export interface ToastMessageProps {
	type: ToastType;
	messageKey: ToastMessageKey;
	messageParams?: Record<string, MessageTemplateParam>;
}

export const toastMessages: Partial<Record<ToastType, Partial<Record<ToastMessageKey, string>>>> = {
	success: {
		generic: "Changes successfully saved.",
		record_created: "New {name} created. {link}",
		record_updated: "{record|Record} updated.",
		record_owner_changed: "Owner changed.",
		record_bulk_owner_change: "Owner changed for {records}.",
		record_bulk_update: "{records} updated.",
		record_bulk_delete: "{records} deleted.",
		record_deleted: "Record deleted.",
		record_favorited: "Record marked as favorite.",
		record_unfavorited: "Record unmarked as favorite.",
		record_batch_favorite: "{records} marked as favorite.",
		record_batch_unfavorite: "{records} unmarked as favorite.",
		record_watched: "Started watching record.",
		record_unwatched: "Stopped watching record.",
		record_batch_watch: "Started watching {records}.",
		record_batch_unwatch: "Stopped watching {records}.",
		record_association_created: "Association created.",
		record_association_deleted: "Association removed.",
		record_associations_created: "Associations created.",
		batch_record_associations_created: "{associations} created.",
		role_created: "Role created.",
		role_updated: "Role updated.",
		role_deleted: "Role deleted.",
		bookmark_created: "Bookmark created.",
		bookmark_updated: "Bookmark updated.",
		bookmark_deleted: "Bookmark deleted.",
		lead_converted_to_opportunity: "Lead converted. {link|View opportunity}",
		object_created: "New object added.",
		object_updated: "‘{name}’ has been updated",
		object_deleted: "‘{name}’ has been deleted",
		user_login_enabled: "User invited",
		user_login_disabled: "User deactivated",
		user_resend_invitation: "User was re-invited",
		customer_access_granted: "Customer invited",
		customer_access_revoked: "Customer deactivated",
		customer_resend_invitation: "Customer was re-invited",
		note_created: "Note created.",
		note_deleted: "Note deleted.",
		note_updated: "Note edited.",
		reaction_added: "Reaction added.",
		reaction_deleted: "Reaction deleted.",
		comment_created: "Comment created.",
		comment_deleted: "Comment deleted.",
		comment_updated: "Comment updated.",
		comment_resolved: "Comment resolved.",
		comment_reaction_added: "Comment reaction added.",
		comment_reaction_deleted: "Comment reaction deleted.",
		notification_rule_created: "Notification rule created.",
		notification_rule_updated: "Notification rule updated.",
		notification_rule_disabled: "Notification rule made inactive.",
		notification_rule_enabled: "Notification rule made active.",
		notification_rule_deleted: "Notification rule deleted.",
		doc_record_pdf_generated: "Generated PDF asset: {link}",
		workspace_created: "Workspace created, {link}",
		workspace_share_record: "Record shared in {workspaceObject}",
		workspace_unshare_record: "Record unshared from {workspaceObject}",
		batch_workspace_share_record: "{records} are now being shared in the {workspaceObject}.",
		workspace_settings_updated: "Settings updated.",
		account_settings_updated: "Account settings updated.",
		notification_preferences_updated: "Notification preferences updated.",
		channel_updated: "Conversation updated.",
		channel_member_removed: "User removed from conversation.",
		participant_added: "{name} invited",
		api_key_created: "API key created.",
		api_key_updated: "API key updated.",
		api_key_deleted: "API key deleted.",
	},
	error: {
		generic: "An error occurred. Please try again later.",
		record_created: "Record couldn't be created.",
		record_updated: "{record|Record} couldn't be updated.",
		record_owner_changed: "Owner couldn't be changed.",
		record_bulk_owner_change: "Bulk owner change was unsuccessful.",
		record_deleted: "Record couldn't be deleted.",
		record_bulk_update: "Bulk edit was unsuccessful.",
		record_bulk_delete: "Bulk delete was unsuccessful.",
		record_favorited: "Record couldn't be marked as favorite.",
		record_unfavorited: "Record couldn't be unmarked as favorite.",
		record_batch_favorite: "Records couldn't be marked as favorite.",
		record_batch_unfavorite: "Records couldn't be unmarked as favorite.",
		record_watched: "Could not start watching record.",
		record_unwatched: "Could not stop watching record.",
		record_batch_watch: "Could not watch records.",
		record_batch_unwatch: "Could not unwatch records.",
		record_association_created: "Association couldn't be created.",
		record_association_deleted: "Association couldn't be removed.",
		record_associations_created: "Associations couldn't be created.",
		batch_record_associations_created: "Associations couldn't be created.",
		role_created: "Role couldn't be created",
		role_updated: "Role couldn't be updated",
		role_deleted: "Role couldn't be deleted",
		bookmark_created: "Bookmark couldn't be created",
		bookmark_updated: "Bookmark couldn't be updated",
		bookmark_deleted: "Bookmark couldn't be deleted",
		lead_converted_to_opportunity: "Lead couldn't be converted to opportunity.",
		file_shared: "File could no tbe shared",
		screen_shared: "Screen couldn't be shared",
		object_created: "Object couldn't be added.",
		object_updated: "Object couldn't be updated",
		object_deleted: "Object couldn't be deleted",
		file_downloaded: "File couldn't be downloaded",
		user_login_enabled: "User couldn't be invited",
		user_login_disabled: "User login couldn't be disabled",
		user_resend_invitation: "User couldn't be re-invited",
		customer_access_granted: "Customer access couldn't be granted.",
		customer_access_revoked: "Customer access couldn't be revoked.",
		customer_resend_invitation: "Customer couldn't be re-invited",
		note_created: "Note couldn't be created",
		note_deleted: "Note couldn't be deleted",
		note_updated: "Note couldn't be edited.",
		reaction_added: "Reaction couldn't be added",
		reaction_deleted: "Reaction couldn't be deleted",
		comment_created: "Comment couldn't be created",
		comment_deleted: "Comment couldn't be deleted",
		comment_updated: "Comment couldn't be updated.",
		comment_resolved: "Comment couldn't be resolved.",
		comment_reaction_added: "Comment reaction couldn't be added",
		comment_reaction_deleted: "Comment reaction couldn't be deleted",
		notification_rule_created: "Notification rule couldn't be created",
		notification_rule_updated: "Notification rule couldn't be updated",
		notification_rule_disabled: "Notification rule couldn't be made inactive",
		notification_rule_enabled: "Notification rule couldn't be made active",
		notification_rule_deleted: "Notification rule couldn't be deleted",
		doc_record_pdf_generated: "PDF asset couldn't be generated",
		chat_message_send: "Message couldn't be sent",
		workspace_created: "Workspace couldn't be created",
		workspace_share_record: "Failed to show record in {workspaceObject}",
		workspace_unshare_record: "Failed to hide record from {workspaceObject}",
		batch_workspace_share_record: "Failed to show selected records in the {workspaceObject}",
		workspace_settings_updated: "Failed to update settings.",
		account_settings_updated: "Failed to update account settings.",
		notification_preferences_updated: "Failed to update notification preferences.",
		channel_updated: "Failed to update Conversation.",
		channel_member_removed: "Failed to remove user from conversation.",
		participant_added: "Failed to invite {name}",
		api_key_created: "API key couldn't be created",
		api_key_updated: "API key couldn't be updated",
		api_key_deleted: "API key couldn't be deleted",
	},
	warning: {
		record_bulk_update: "{records} updated out of {total}.",
		record_bulk_owner_change: "Owner changed for {records} out of {total}.",
		record_batch_favorite: "{records} marked as favorite out of {total}.",
		record_batch_unfavorite: "{records} unmarked as favorite out of {total}.",
		record_batch_watch: "Started watching {records} out of {total}.",
		record_batch_unwatch: "Stopped watching {records} out of {total}.",
		record_bulk_delete: "{records} deleted out of {total}.",
		batch_record_associations_created: "{associations} created out of {total}.",
		batch_workspace_share_record: "{records} out of {total} are now being shown in the {workspaceObject}.",
	},
	info: {
		record_bulk_update_no_changes: "No records needed to be updated.",
	},
};

export interface ToastEvent {
	id: number;
	createdAt?: number;
	type: ToastType;
	message?: string;
	toastMessageProps?: ToastMessageProps;
	timeoutId?: NodeJS.Timeout;
	dismissed?: boolean;
}
