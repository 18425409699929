import { useMemo } from "react";

import { DisplayedFieldOption } from "./DisplayedFieldOption";
import { SelectOption, SelectOptionId } from "../../../../../../inputs";
import { MenuAccordion } from "../../../../../../../components/MenuAccordion";
import { Divider } from "../../../../../../../components/Divider/Divider";

interface DisplayedFieldsSettingsProps {
	value?: SelectOptionId[];
	onChange: (newValue: SelectOptionId[]) => void;
	fieldOptions: SelectOption[];
	metadataOptions: SelectOption[];
	disabledOptions?: SelectOptionId[];
}

export function DisplayedFieldsSettings({
	value,
	onChange,
	fieldOptions,
	metadataOptions,
	disabledOptions,
}: DisplayedFieldsSettingsProps) {
	const selectedValueMap = useMemo(
		() =>
			(value || []).reduce((valueMap, optionId) => {
				valueMap[optionId] = true;

				return valueMap;
			}, {} as Record<SelectOptionId, boolean>),
		[value]
	);

	return (
		<MenuAccordion
			title="Fields in view"
			helperText={`${value?.length || 0}/${fieldOptions.length + metadataOptions.length}`}
			defaultOpen={true}
		>
			<div className="px-6">
				{fieldOptions?.map((option) =>
					generateDisplayedFieldOption(option, onChange, selectedValueMap, value, disabledOptions)
				)}
				<Divider className="my-3" />
				{metadataOptions?.map((option) =>
					generateDisplayedFieldOption(option, onChange, selectedValueMap, value, disabledOptions)
				)}
			</div>
		</MenuAccordion>
	);
}

function generateDisplayedFieldOption(
	option: SelectOption,
	onChange: (newValue: SelectOptionId[]) => void,
	selectedValueMap: Record<SelectOptionId, boolean>,
	value?: SelectOptionId[],
	disabledOptions?: SelectOptionId[]
) {
	const disabled = disabledOptions ? disabledOptions.includes(option.id) : false;
	const selected = selectedValueMap[option.id];

	return (
		<DisplayedFieldOption
			key={option.id}
			option={option}
			onChange={(isSelected) => {
				const updatedSelectedFields = (value || []).filter((id) => id !== option.id);

				if (isSelected) {
					updatedSelectedFields.push(option.id);
				}

				onChange(updatedSelectedFields);
			}}
			disabled={disabled}
			selected={selected}
		/>
	);
}
