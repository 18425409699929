import { useState, useCallback, useRef, useEffect } from "react";
import { Room, LocalDataTrack } from "twilio-video";

import {
	FilePresentationPageChangedBaseParams,
	FilePresentationStartedBaseParams,
	FilePresentationStoppedBaseParams,
	SdEventType,
} from "@salesdesk/salesdesk-model";
import { SDFile } from "@salesdesk/salesdesk-schemas";
import { getMeetingRecordIdFromRoomName } from "@salesdesk/salesdesk-utils";

import { getFileTypeDetails } from "../../../../../../files";
import { usePostEvent } from "../../../../../../events/hooks/usePostEvent";
import { useToast } from "../../../../../../Toasts";
import { FILE_TRACK_NAME, FilePresentationDetails, PresentedFileSettings } from "../../../types";

const HEARTBEAT_INTERVAL = 2000;

export function useFileShare(room: Room | null) {
	const [sharedFileDetails, setSharedFileDetails] = useState<FilePresentationDetails | null>(null);
	const stopFileShareRef = useRef<(() => void) | null>(null);
	const updateFileSettings = useRef<((settings: PresentedFileSettings) => void) | null>(null);
	const prevPageNumber = useRef<number>(0);

	const postEvent = usePostEvent();
	const toast = useToast();

	// Stops sharing file if the user leaves the room
	useEffect(() => {
		return () => {
			if (stopFileShareRef.current) {
				stopFileShareRef.current();
			}
		};
	}, [room]);

	const shareFile = useCallback(
		async (file: SDFile) => {
			if (!room) {
				toast.triggerMessage({ type: "error", messageKey: "file_shared" });
				return;
			}

			const fileDetails = { file };

			if (stopFileShareRef.current) {
				stopFileShareRef.current();
				setSharedFileDetails(fileDetails);

				// Give time for twilio to unpublish old file track before replacing with new file track
				await new Promise((f) => setTimeout(f, 100));
			}

			try {
				const dataTrack = new LocalDataTrack({ name: FILE_TRACK_NAME });
				const sendFileDetails = (fileDetails: FilePresentationDetails) => dataTrack.send(JSON.stringify(fileDetails));

				let fileShareInterval = setInterval(() => sendFileDetails(fileDetails), HEARTBEAT_INTERVAL);

				const trackPublication = room.localParticipant
					.publishTrack(dataTrack)
					.then(() => setTimeout(() => sendFileDetails(fileDetails), 1000));

				updateFileSettings.current = (settings: PresentedFileSettings) => {
					clearInterval(fileShareInterval);

					const updatedFileDetails = { ...fileDetails, settings };
					sendFileDetails(updatedFileDetails);
					setSharedFileDetails(updatedFileDetails);

					const hasPageNumberChanged = prevPageNumber.current !== updatedFileDetails.settings.pageNumber;
					if (getFileTypeDetails(fileDetails.file).type === "pdf" && hasPageNumberChanged) {
						postEvent({
							event_type: SdEventType.FILE_PRESENTATION_PAGE_CHANGED,
							record_id: getMeetingRecordIdFromRoomName(room.name),
							params: {
								assetId: file.recordId as number,
								pageNumber: settings.pageNumber as number,
							} satisfies FilePresentationPageChangedBaseParams,
						});
						prevPageNumber.current = settings.pageNumber || 0;
					}

					fileShareInterval = setInterval(() => sendFileDetails(updatedFileDetails), HEARTBEAT_INTERVAL);
				};

				postEvent({
					event_type: SdEventType.FILE_PRESENTATION_STARTED,
					record_id: getMeetingRecordIdFromRoomName(room.name),
					params: {
						assetId: file.recordId as number,
					} satisfies FilePresentationStartedBaseParams,
				});

				stopFileShareRef.current = () => {
					clearInterval(fileShareInterval);
					room.localParticipant.unpublishTrack(dataTrack);
					room.localParticipant.emit("trackUnpublished", trackPublication);
					setSharedFileDetails(null);

					updateFileSettings.current = null;
					stopFileShareRef.current = null;
					prevPageNumber.current = 0;

					postEvent({
						event_type: SdEventType.FILE_PRESENTATION_STOPPED,
						record_id: getMeetingRecordIdFromRoomName(room.name),
						params: {
							assetId: file.recordId as number,
						} satisfies FilePresentationStoppedBaseParams,
					});
				};

				setSharedFileDetails(fileDetails);
			} catch (error) {
				toast.triggerMessage({ type: "error", messageKey: "file_shared" });
				console.error("Error occurred when sharing file", error);
			}
		},
		[room, postEvent, toast]
	);

	return {
		sharedFileDetails,
		shareFile,
		updateFileSettings: updateFileSettings.current,
		stopSharingFile: stopFileShareRef.current,
	};
}
