import { mThemeFieldDef, ThemeName as BackendThemeName } from "@salesdesk/salesdesk-model";
import { FIELD_COMPONENT_TYPES, UI_THEMES, ThemeName as FrontendThemeName } from "@salesdesk/salesdesk-ui";
import { Field, TenantSettings } from "@salesdesk/salesdesk-schemas";
import { getRecordFormFieldId, RECORD_FIELDS_KEY } from "../../../records";
import { capitalizeString, createHashId } from "@salesdesk/salesdesk-utils";

export function getThemeField(): Field {
	const themeField = new mThemeFieldDef("theme");
	themeField.pluralName = "themes";
	themeField.displayName = "Theme";
	themeField.defaultValue = createHashId("purple");
	themeField.required = true;
	themeField.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
	return themeField.unmarshall() as Field;
}

export function getFrontendThemeNameFromId(id: number): FrontendThemeName | undefined {
	const themeNames = Object.keys(UI_THEMES) as (keyof typeof UI_THEMES)[];
	return themeNames.find((themeName) => createHashId(themeName) === id);
}

export function getSelectedBackendThemeName(
	themeField: Field,
	data: Record<string, any>
): BackendThemeName | undefined {
	const selectedThemeId = data[RECORD_FIELDS_KEY]?.[getRecordFormFieldId(themeField._id)];
	if (typeof selectedThemeId !== "number") {
		return undefined;
	}
	const frontendThemeName = getFrontendThemeNameFromId(selectedThemeId);
	if (!frontendThemeName) {
		return undefined;
	}
	return capitalizeString(frontendThemeName) as BackendThemeName;
}

export function getCrmThemeId(tenantSettings: TenantSettings): number | undefined {
	if (!tenantSettings.crmTheme) {
		return undefined;
	}
	return createHashId(tenantSettings.crmTheme.toLowerCase());
}

export function getFrontendCrmThemeName(tenantSettings: TenantSettings): FrontendThemeName | undefined {
	if (!tenantSettings.crmTheme) {
		return undefined;
	}
	return tenantSettings.crmTheme.toLowerCase() as FrontendThemeName;
}
