import { useMemo } from "react";

import {
	sdObjectIsUserType,
	SDObject,
	AbilityAction,
	sdSubject,
	AbilitySubject,
	BASE_SYSTEM_OBJECTS_ORDER,
} from "@salesdesk/salesdesk-schemas";
import { sortByIds } from "@salesdesk/salesdesk-utils";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { mLeadDef, mAssetDef } from "@salesdesk/salesdesk-model";

import { PATHS } from "../../../../routes";
import { MenuItem } from "../../../menu";
import { useWebPrincipal } from "../../../../auth";

interface NavOption {
	id: number;
	text: string;
	icon: string;
	link?: string;
	hideSelectDrop?: boolean;
	elementId?: string;
}

type NavOptionsMap = { systemNavOptions: NavOption[]; customObjects: MenuItem[] };

const DASHBOARD_NAV_ID = 600;
const REPORT_NAV_ID = 602;
const NAV_OPTIONS_ORDER = [DASHBOARD_NAV_ID, ...BASE_SYSTEM_OBJECTS_ORDER, REPORT_NAV_ID];

const elementIdSDObjectIdMap = {
	[mLeadDef.ID]: "userflow-lead-object-link",
	[mAssetDef.ID]: "userflow-asset-object-link",
};

export function useNavbarOptions(sdObjects: SDObject[]) {
	const principal = useWebPrincipal();
	return useMemo(() => {
		const { systemNavOptions, customObjects } = sdObjects.reduce(
			(result: NavOptionsMap, sdObject: SDObject) => {
				if (
					NAV_OPTIONS_ORDER.indexOf(sdObject._id) !== -1 &&
					principal.can(AbilityAction.View, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id }))
				) {
					result.systemNavOptions.push({
						id: sdObject._id,
						text: sdObject._pluralName,
						icon: sdObject._icon,
						link: PATHS.OBJECT_RECORD_BOARD(sdObject),
						elementId: elementIdSDObjectIdMap[sdObject._id],
					});
				}

				if (
					sdObjectIsUserType(sdObject) &&
					principal.can(AbilityAction.View, sdSubject(AbilitySubject.Record, { _objectDefId: sdObject._id }))
				) {
					result.customObjects.push({
						text: sdObject._pluralName,
						icon: sdObject._icon,
						link: PATHS.OBJECT_RECORD_BOARD(sdObject),
						type: "link",
					});
				}
				return result;
			},
			{ systemNavOptions: [], customObjects: [] }
		);

		systemNavOptions.push(
			...[
				{
					id: DASHBOARD_NAV_ID,
					text: "Dashboard",
					icon: ICONS.dashboard,
					link: PATHS.DASHBOARD(),
					hideSelectDrop: true,
				},
				{ id: REPORT_NAV_ID, text: "Reports", icon: ICONS.reports },
			]
		);

		return { systemNavOptions: sortByIds(systemNavOptions, NAV_OPTIONS_ORDER), customObjects };
	}, [principal, sdObjects]);
}
