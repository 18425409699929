import { PropsWithChildren } from "react";

import { Tab } from "@headlessui/react";
import { TooltipDelayGroup } from "@salesdesk/daisy-ui";

export function SidePanelTabList({ children }: PropsWithChildren) {
	return (
		<TooltipDelayGroup>
			<Tab.List className="flex flex-col items-center gap-2">{children}</Tab.List>
		</TooltipDelayGroup>
	);
}
