import { useState, useMemo, useEffect } from "react";

import { debounce } from "../../../../../utils";
import { SearchbarProps } from "../types";
import { Searchbar } from "./Searchbar";

interface DebouncedSearchbarProps extends Omit<SearchbarProps, "value"> {
	defaultValue?: string;
}

const SEARCH_DEBOUNCE_MS = 300;

/*
 * Debounced uncontrolled version of the Searchbar component where the
 * onChange is debounced by 300ms.
 */
export function DebouncedSearchbar({ onChange, defaultValue, ...searchbarProps }: DebouncedSearchbarProps) {
	const [searchInput, setSearchInput] = useState(defaultValue);

	useEffect(() => {
		if (defaultValue !== undefined && searchInput === undefined) {
			setSearchInput(defaultValue ?? "");
		}
	}, [defaultValue, searchInput]);

	const debounceOnChange = useMemo(
		() =>
			debounce((query: string) => {
				onChange(query);
			}, SEARCH_DEBOUNCE_MS),
		[onChange]
	);

	const onSearchChange = (query: string) => {
		setSearchInput(query);

		// Doesn't debounce if the query is empty to make clearing the input more responsive
		if (!query) {
			onChange(query);
			return;
		}

		debounceOnChange(query);
	};

	return <Searchbar value={searchInput ?? ""} onChange={onSearchChange} {...searchbarProps} />;
}
