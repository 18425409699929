import { NavigateNotFound } from "../../../../../routes";
import { useMeetingPageContext } from "../hooks";
import { HoldingPage } from "./HoldingPage";
import { VideoCallPage } from "./VideoCallPage";
import { EndCallPage } from "./EndCallPage";

export function MeetingPage() {
	const { meetingPageState, isLoadingMeetingRecord, meetingRecord } = useMeetingPageContext();

	if (!isLoadingMeetingRecord && meetingRecord == null) {
		return <NavigateNotFound />;
	}

	if (meetingPageState === "holding") {
		return <HoldingPage />;
	}

	if (meetingPageState === "in_call") {
		return <VideoCallPage />;
	}
	return <EndCallPage />;
}
