import { SDEvent } from "../../events";
import { eventActions } from "../../notifications";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { applyTemplateParams } from "./applyTemplateParams";

const systemActions: Partial<Record<SdEventType, string>> = {
	[SdEventType.USER_CREATED]: "created",
};

export function getEventActionText(
	event: SDEvent,
	isSystemUser: boolean,
	additionalParams: Record<string, string> = {}
): string {
	let actionText;
	if (isSystemUser) {
		actionText = systemActions[event.event_type];
	}
	if (!actionText) {
		actionText = eventActions[event.event_type] ?? "unknown action";
	}
	return applyTemplateParams(actionText, { ...event.params, ...additionalParams });
}
