import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { SdEventType } from "@salesdesk/salesdesk-model";

import { usePostEventMutation } from "../events/api/eventsApi";

export function NavTrack() {
	const [postEvent] = usePostEventMutation();
	const location = useLocation();

	useEffect(() => {
		postEvent({
			event_type: SdEventType.PAGE_LOAD,
			params: {
				url: window.location.href,
			},
		});
	}, [location, postEvent]);

	return <Outlet />;
}
