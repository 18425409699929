interface FeatureInfoProps {
	imageUrl: string;
	index: number;
	description: string;
}

export function FeatureInfo({ imageUrl, index, description }: FeatureInfoProps) {
	return (
		<div className="flex flex-col items-center gap-2">
			<img className="aspect-[34/27] h-auto w-full" src={imageUrl} alt={`Feature ${index} graphic`} />
			<div className="bg-c_action_focus text-c_text_inverted flex size-8 flex-col items-center justify-center rounded-full">
				<div>{index}</div>
			</div>
			<p className="text-body-sm max-w-[184px] text-pretty text-center">{description}</p>
		</div>
	);
}
