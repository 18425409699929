import { useEffect, useMemo, useState } from "react";
import { SubmitHandler } from "react-hook-form";

import { AbilityAction, AbilitySubject, Role } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../../../auth";
import { useToast } from "../../../../../../Toasts";
import { useUpdateRoleMutation } from "../../../api/rolesApi";
import { mapRoleToFormData } from "../mappers/mapRoleToFormData";
import { mapFormDataToRolePatchRequest } from "../mappers/mapFormDataToRolePatchRequest";
import { RoleFormData } from "../types";
import { RoleForm } from "./RoleForm";
import { DeleteRoleDialog } from "./DeleteRoleDialog";

export function EditRoleFormContainer({ role }: { role: Role }) {
	const principal = useWebPrincipal();
	const [updateRole] = useUpdateRoleMutation();
	const toast = useToast();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [displayDeleteRoleDialog, setDisplayDeleteRoleDialog] = useState(false);
	const initialRoleFormData = useMemo(() => mapRoleToFormData(role), [role]);

	useEffect(() => {
		// This will be triggered by RTK reloading all Roles
		setIsSubmitting(false);
	}, [role]);

	const userCanDelete = principal.can(AbilityAction.Delete, AbilitySubject.Role) && role.editable;
	const userCanEdit = principal.can(AbilityAction.Edit, AbilitySubject.Role) && role.editable;

	const onSubmit: SubmitHandler<RoleFormData> = (roleFormData) => {
		setIsSubmitting(true);
		const rolePatchRequest = mapFormDataToRolePatchRequest(roleFormData);
		updateRole({ id: role.id, rolePatchRequest })
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "role_updated" });
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "role_updated" });
				setIsSubmitting(false);
			});
	};

	return (
		<>
			<RoleForm
				roleFormData={initialRoleFormData}
				canEdit={userCanEdit}
				canDelete={userCanDelete}
				onSubmit={onSubmit}
				isSubmitting={isSubmitting}
				onDelete={() => setDisplayDeleteRoleDialog(true)}
			/>
			<DeleteRoleDialog role={role} open={displayDeleteRoleDialog} onOpenChange={setDisplayDeleteRoleDialog} />
		</>
	);
}
