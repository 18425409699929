import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { SDObject } from "@salesdesk/salesdesk-schemas";

import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { PATHS } from "../../../../../routes";
import { useBulkEditContext } from "../hooks/useBulkEditContext";
interface BulkCopyLinksButtonProps {
	sdObject?: SDObject;
}

export function BulkCopyLinksButton({ sdObject }: BulkCopyLinksButtonProps) {
	const { selectedRecords } = useBulkEditContext();

	return (
		<Tooltip text={`Copy record ${pluralizeWithS("link", selectedRecords.length)}`} placement="top">
			<Button
				variant="text_inverted"
				startIcon={ICONS.link}
				size="sm"
				disabled={!selectedRecords.length}
				onClick={() => {
					if (!sdObject) return;
					const links = selectedRecords
						.map((record) => window.location.origin + PATHS.RECORD_DETAIL_VIEW(record._id, sdObject))
						.join("\n");
					navigator.clipboard.writeText(links);
				}}
			/>
		</Tooltip>
	);
}
