import { createContext, useContext } from "react";
import { ParticipantUserAndStatus } from "../../Participants";

export interface IHoldingPageContext {
	participants: ParticipantUserAndStatus[] | undefined;
}

export const HoldingPageContext = createContext<IHoldingPageContext | null>(null);

export const useHoldingPageContext = () => {
	const context = useContext(HoldingPageContext);

	if (context == null) {
		throw new Error("Holding Page components must be wrapped in <HoldingPageProvider />");
	}

	return context;
};
