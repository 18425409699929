import { Field } from "@salesdesk/salesdesk-schemas";

import { useSdFile } from "../../../../../../../../../files/hooks/useSdFile";
import { WorkspaceFileFieldPreview } from "../../../../WorkspaceFileFieldPreview";
import { DisplayFieldVariant, DisplayFileFieldPlaceholder } from "../../../../../../../../../fields";
import { FileFieldType } from "../../../../../../../../../fields";

interface ProductFileFieldPreviewProps {
	field: Field;
	fileId?: number;
}

export function ProductFileFieldPreview({ field, fileId }: ProductFileFieldPreviewProps) {
	const { sdFile, isLoading } = useSdFile(fileId);

	let placeholderFileFieldType: FileFieldType = "any";

	switch (field._type) {
		case "image":
			placeholderFileFieldType = "image";
			break;
		case "video":
			placeholderFileFieldType = "video";
			break;
		case "pdf":
			placeholderFileFieldType = "pdf";
			break;
		default:
			placeholderFileFieldType = "any";
			break;
	}

	if (!sdFile || isLoading || !fileId) {
		return (
			<DisplayFileFieldPlaceholder
				variant={DisplayFieldVariant.workspaceOverview}
				placeholderFileFieldType={placeholderFileFieldType}
			/>
		);
	}

	return <WorkspaceFileFieldPreview file={sdFile} />;
}
