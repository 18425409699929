import { useId, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";

import { useWebPrincipal } from "../../../../../auth";
import { FormFieldSet } from "../../../../forms";
import { EditMultiOptionField, EditTextField } from "../../../../fields";
import { useToast } from "../../../../Toasts";
import { useChimeMessagingContext } from "../../providers";
import { useTeamMemberFieldSettings } from "../hooks/useTeamMemberFieldSettings";

interface CreateChannelViewProps {
	onChannelCreated: (channelArn: string) => void;
	onCancel: () => void;
}

interface CreateChannelFormData {
	members: number[];
	name?: string;
}

const EMPTY_MEMBERS_VALUE: number[] = [];

export function CreateChannelView({ onChannelCreated, onCancel }: CreateChannelViewProps) {
	const principal = useWebPrincipal();

	const toast = useToast();

	const { chime } = useChimeMessagingContext();

	const [isCreatingChannel, setIsCreatingChannel] = useState(false);

	const teamMemberFieldSettings = useTeamMemberFieldSettings();

	const formId = useId();
	const formMethods = useForm<CreateChannelFormData>({
		mode: "onChange",
		defaultValues: {
			members: EMPTY_MEMBERS_VALUE,
		},
	});
	const { control, handleSubmit, watch } = formMethods;

	const onSubmit = (data: Record<string, any>) => {
		if (!chime) {
			toast.trigger("error", "Error creating a messaging channel");
			return;
		}

		setIsCreatingChannel(true);

		chime
			.createChannel(principal.TenantId, [...data.members, principal.UserRecordId], data.name)
			.then((channelArn) => {
				onChannelCreated(channelArn);
			})
			.catch((error) => {
				// TODO: Better error handling
				console.error("Error creating channel", error);
				toast.trigger("error", "Error creating a messaging channel");
				setIsCreatingChannel(false);
			});
	};

	return (
		<div className="h-full overflow-auto px-4 py-3">
			<form id={formId} onSubmit={handleSubmit(onSubmit)} className="relative flex min-h-full flex-col gap-8">
				<FormProvider {...formMethods}>
					<FormFieldSet
						control={control}
						name="members"
						label="Add team member"
						labelIcon={ICONS.users}
						required
						rules={{ required: "Team members are required" }}
					>
						{({ field: { value, name, ...field }, fieldState: { error } }) => (
							<EditMultiOptionField
								value={Array.isArray(value) ? value : EMPTY_MEMBERS_VALUE}
								{...field}
								optionFieldSettings={teamMemberFieldSettings}
								hasError={Boolean(error)}
							/>
						)}
					</FormFieldSet>
					{watch("members").length > 1 && (
						<FormFieldSet control={control} name="name" label="Conversation name (optional)" labelIcon={ICONS.text}>
							{({ field: { value, ...field }, fieldState: { error } }) => (
								<EditTextField value={String(value ?? "")} {...field} hasError={Boolean(error)} />
							)}
						</FormFieldSet>
					)}
				</FormProvider>
				<div className="bg-c_bg_01 sticky bottom-0 z-10 mt-auto flex items-center justify-end gap-3 pt-3">
					<Button variant="secondary" onClick={onCancel} disabled={isCreatingChannel} size="sm">
						Cancel
					</Button>
					<Button form={formId} type="submit" isLoading={isCreatingChannel} size="sm">
						Create
					</Button>
				</div>
			</form>
		</div>
	);
}
