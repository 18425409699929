import { SDRecord } from "@salesdesk/salesdesk-schemas";
import { Button, Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { useBatchUnwatchRecordsMutation } from "../../../../../records";
import { useToast } from "../../../../../Toasts";
import { MAX_BATCH_SIZE } from "../../../../../ObjectBoard/components/BulkEdit/utils";
import { EditBar } from "../../../../../../components/EditBar";
import { countPromiseResults } from "../../../../../../utils";

interface WatchlistBulkEditBarProps {
	selectedRecords: SDRecord[];
	onClose: () => void;
}

export function WatchlistBulkEditBar({ selectedRecords, onClose }: WatchlistBulkEditBarProps) {
	const [batchUnwatchRecords, { isLoading: isUnwatching }] = useBatchUnwatchRecordsMutation();
	const toast = useToast();

	const onUnwatchClick = () => {
		const chunks = chunk(
			selectedRecords.map((sdRecord) => sdRecord._id),
			MAX_BATCH_SIZE
		);
		Promise.allSettled(chunks.map((recordIds) => batchUnwatchRecords(recordIds).unwrap())).then((results) => {
			const [successes, fails] = countPromiseResults(results, chunks);
			toast.triggerMessage({
				type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
				messageKey: "record_batch_unwatch",
				messageParams: {
					records: `${successes} ${pluralizeWithS("record", successes)}`,
					total: String(fails + successes),
				},
			});
			onClose();
		});
	};

	return (
		<EditBar show={selectedRecords.length > 0}>
			<Tooltip text="Exit bulk edit mode" placement="top">
				<Button variant="text_inverted" startIcon={ICONS.cross} size="sm" onClick={onClose} />
			</Tooltip>
			<span className="min-w-[115px] text-center">
				{selectedRecords.length} {pluralizeWithS("record", selectedRecords.length)} selected
			</span>
			<Button
				startIcon={ICONS.flag}
				variant="text_inverted"
				size="sm"
				isLoading={isUnwatching}
				onClick={onUnwatchClick}
			>
				Stop watching
			</Button>
		</EditBar>
	);
}
