import { useMemo } from "react";
import clsx from "clsx";

import { useVideoCallLogicContext } from "../../../../../../VideoCallProvider";
import { CallParticipant } from "../../../../../../common";
import { MainPresenterBanner } from "./MainPresenterBanner";

export function PresenterView() {
	const {
		room,
		callParticipantDetails,
		screenSharingParticipantDetails,
		fileSharingParticipant,
		currentDominantSpeakerIdentity,
	} = useVideoCallLogicContext();

	const { mainPresenter, remainingParticipants } = useMemo(() => {
		const [mainPresenter, ...remainingParticipants] = [
			...(fileSharingParticipant ? [fileSharingParticipant] : []),
			...(screenSharingParticipantDetails || []),
			...callParticipantDetails,
		];

		return { mainPresenter, remainingParticipants };
	}, [callParticipantDetails, screenSharingParticipantDetails, fileSharingParticipant]);

	let mainPresenterComponent = null;
	if (mainPresenter && room) {
		const { variant, participant } = mainPresenter;

		mainPresenterComponent = (
			<div className="flex h-full w-full items-center justify-center">
				<div className={clsx("h-full w-full flex-grow items-center justify-center")}>
					<CallParticipant
						participantDetails={mainPresenter}
						isLocal={participant === room.localParticipant}
						displayText={variant !== "default" ? "" : undefined}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col">
			{mainPresenter ? <MainPresenterBanner mainPresenter={mainPresenter} /> : null}
			<div className="flex h-full max-h-full w-full max-w-full overflow-hidden px-6">
				{mainPresenterComponent}
				{room && remainingParticipants.length ? (
					<div className="ml-6 flex h-full max-h-full w-[194px] flex-shrink-0 overflow-auto">
						<div className="flex h-max w-full flex-col gap-4">
							{remainingParticipants.map((participantDetails) => {
								const { variant, participant, name } = participantDetails;

								let displayText;

								// If it's a screen share view
								if (variant === "screen_share") {
									displayText = `Presentation (${name})`;
								}
								// Else if they are a screen sharer
								else if (
									screenSharingParticipantDetails?.find(
										(screenSharer) => screenSharer.participant.identity === participant.identity
									)
								) {
									displayText = `${name} (Presenting)`;
								}

								const key = participant.sid + variant;

								return (
									<div key={key} className="aspect-square w-full">
										<CallParticipant
											participantDetails={participantDetails}
											dominantSpeaker={variant === "default" && currentDominantSpeakerIdentity === participant.identity}
											isLocal={participant === room.localParticipant}
											displayText={displayText}
										/>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
