import { mFieldDataChangeEvent } from "../utils";
import { mField } from "../field_inst";

export class mOptionField extends mField {
	
	get field(){
		return this._field;
	}

	set field(field){
		super.field = field;

		// If the field.optionValues in the field object have been updated, we need to 
		// update the option field instance value(s) to ensure it is only referencing
		// options that exist in the option field.

		field.addEventListener(mFieldDataChangeEvent.type.FIELD_OPTION_VALUES_UPDATED, (event) => {
			this.update(event);
		});
	}

	marshall(fieldData, data, parent){

		let object = super.marshall(fieldData, data, parent);

		// We call this function to kick off the process of removing all option values that
		// correspond to option values in the option field that no longer exist

		if(this.isBound()){
			object.value = this._value;
		}

		return object;
	}
} 