import { useContext } from "react";

import { OnboardingContext } from "../components";
import { OnboardingContextProps } from "../types";

export function useOnboardingContext(): OnboardingContextProps {
	const context = useContext(OnboardingContext);

	if (context === undefined) {
		throw new Error("useOnboardingContext must be used within an OnboardingContext.Provider");
	}

	return context;
}
