import { getSDRecordName, SDRecord } from "@salesdesk/salesdesk-schemas";

import { DeleteConfirmationDialog } from "../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { useToast } from "../../../../Toasts";
import { getRecordAssociationPathData } from "../../../utils";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { useAssociationActionsContext } from "../hooks/useAssociationActionsContext";
import { useDeleteRecordAssociations } from "../../../hooks";

interface DeleteRecordAssociationDialogProps {
	associationRecord?: SDRecord;
	onClose: () => void;
}

export function DeleteRecordAssociationDialog({ associationRecord, onClose }: DeleteRecordAssociationDialogProps) {
	const { sourceRecord, objectAssociation, setAssociationRecordToRemove } = useAssociationActionsContext();
	const { sdObject: associationRecordObject } = useGetObjectById(associationRecord?._objectDefId);

	const { deleteRecordAssociation, isLoading: isDeleting } = useDeleteRecordAssociations();
	const toast = useToast();

	const onRemoveAssociationRecordConfirm = () => {
		if (!associationRecord || !sourceRecord || !objectAssociation) return;

		deleteRecordAssociation(getRecordAssociationPathData(sourceRecord, associationRecord._id, objectAssociation))
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "record_association_deleted" });
				setAssociationRecordToRemove?.(undefined);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "record_association_deleted" });
			})
			.finally(onClose);
	};

	return (
		<DeleteConfirmationDialog
			open={Boolean(associationRecord && sourceRecord)}
			title="Remove association"
			buttonLabel="Remove"
			onConfirm={onRemoveAssociationRecordConfirm}
			onDismiss={() => onClose()}
			isDeleting={isDeleting}
		>
			<p>
				Are you sure you want to remove "
				<b>
					<i>
						{associationRecord && associationRecordObject
							? getSDRecordName(associationRecordObject, associationRecord)
							: associationRecord?._name || ""}
					</i>
				</b>
				" from this record?
			</p>
		</DeleteConfirmationDialog>
	);
}
