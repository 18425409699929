import { RefObject } from "react";
import { useInView } from "react-intersection-observer";

interface InfiniteScrollContainerArgs {
	containerRef: RefObject<HTMLElement>;
	horizontalOffset?: number;
	verticalOffset?: number;
	onBottomReached?: () => void;
}

export function useInfiniteScrollContainer({
	containerRef,
	horizontalOffset = 0,
	verticalOffset = 0,
	onBottomReached,
}: InfiniteScrollContainerArgs) {
	const { ref: containerBottomRef } = useInView({
		rootMargin: `${verticalOffset}px ${horizontalOffset}px`,
		root: containerRef.current,
		onChange(inView) {
			if (inView && onBottomReached) {
				onBottomReached();
			}
		},
	});

	return {
		containerBottomRef,
	};
}
