import { asConst } from "json-schema-to-ts";
import { nullableNonNegativeIntegerSchema } from "../components";

export const searchSizeSchema = asConst({
	...nullableNonNegativeIntegerSchema,
	title: "Search Size",
	description: "The number of records to return in the search",
	type: "integer",
	minimum: 0,
	maximum: 100,
});
