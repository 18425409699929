import { useGetRecordOptions, useGetRecordOptionsProps } from "../../../../../fields";
import { RuleValueSelect } from "./RuleValueSelect";
import { useNotificationRuleDialogContext } from "../../../hooks/useNotificationRuleDialogContext";
import { ANY } from "../../../types/Any";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

export function RecordValueSelector() {
	const { selectedObjectId, selectedSDObject, selectedRecord, setSelectedRecord, selectedWorkspaceId } =
		useNotificationRuleDialogContext();

	const params: useGetRecordOptionsProps = {
		baseObjectId: selectedSDObject?._id,
		fetchingRecordOptions: true,
	};
	if (selectedObjectId === mWorkspaceDef.ID && selectedWorkspaceId) params.id = selectedWorkspaceId;
	else if (selectedWorkspaceId) params.sharedWithWorkspaceId = selectedWorkspaceId;

	const { getOptions } = useGetRecordOptions(params);

	return (
		<RuleValueSelect
			title="Record selection"
			value={selectedRecord}
			onChange={setSelectedRecord}
			getAsyncSelectOptions={getOptions}
			placeholderOption={{ id: ANY, name: `Any ${selectedSDObject?._displayName.toLowerCase() ?? "record"}` }}
			multiple={false}
			hideIcon
		/>
	);
}
