import { JSONContent } from "@tiptap/core";

export const PLACEHOLDER_COMMENT_ID = "PLACEHOLDER_COMMENT";

export const COMMENT_PANEL_COMMENT_ID_PREFIX = "comment-panel-";

const MAX_COMMENT_LENGTH = 100;

/**
 * Returns a list of all the comment ids and their text from a JSONContent object
 * with a limit of MAX_COMMENT_LENGTH per comment
 */
export function getCommentsFromJSONContent(data: JSONContent | undefined | null) {
	if (!data) return [];

	const comments: { id: string; text: string }[] = (data.content ?? []).flatMap(getCommentsFromJSONContent);

	data?.marks?.forEach((mark) => {
		if (mark.type === "comment" && mark.attrs?.commentId != null && data.text) {
			comments.push({ id: mark.attrs.commentId, text: data.text });
		}
	});

	// Since comments can be split across multiple nodes, we merge them all together
	const mergedComments = comments.reduce(
		(acc, comment) => {
			if (!acc[comment.id]) {
				acc[comment.id] = { id: comment.id, text: "" };
			}

			if (acc[comment.id].text.length < MAX_COMMENT_LENGTH) {
				const combinedText = acc[comment.id].text ? `${acc[comment.id].text} ${comment.text}` : comment.text;
				acc[comment.id].text = combinedText.slice(0, MAX_COMMENT_LENGTH);
			}

			return acc;
		},
		{} as Record<string, { id: string; text: string }>
	);

	return Object.values(mergedComments);
}
