import { FIELD_COMPONENT_TYPES } from "@salesdesk/salesdesk-ui";
import { createUniqueId } from "@salesdesk/salesdesk-utils";

import {
	mBooleanFieldDef,
	mDateTimeFieldDef,
	mDurationFieldDef,
	mRichTextFieldDef,
	mSingleOptionFieldDef,
	mStringFieldDef,
} from "../../../fields";

import { mTransientDef } from "../transient_def";

export class mConferenceOrganiseDef extends mTransientDef {
	static CLASS_NAME = "mConferenceOrganiseDef";
	static NAME = "Book a Meeting";
	static PLURAL_NAME = "Book Meetings";
	static ICON = "fa-calendar-lines-pen";

	static ID = 100;

	constructor(ownerId, context) {
		super(mConferenceOrganiseDef.ID, ownerId, context);

		super.className = mConferenceOrganiseDef.CLASS_NAME;
		super.name = mConferenceOrganiseDef.NAME;
		super.pluralName = mConferenceOrganiseDef.PLURAL_NAME;
		super.displayName = "Organise Conference";

		super.description = "Organise conference data";
		super.icon = mConferenceOrganiseDef.ICON;

		super.commentsSupported = false;

		super.data.name = "organiseConferenceDetails";
		super.data.pluralName = "organiseConferenceDetails";
		super.data.displayName = "Organise Conference Details";

		this._supportsTableCardView = false;

		let title = new mStringFieldDef(createUniqueId());
		title.name = "title";
		title.pluralName = "title";
		title.displayName = "Title";
		title.required = true;

		let subject = new mSingleOptionFieldDef(createUniqueId());
		subject.name = "subject";
		subject.pluralName = "subjects";
		subject.displayName = "Subject";
		subject.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		subject.required = true;
		subject.optionValues = [
			{
				id: 1,
				name: "Training",
				icon: "fa-chalkboard-user",
				color: "#037f4c",
			},
			{
				id: 2,
				name: "Product Demo",
				icon: "fa-laptop",
				color: "#cab641",
			},
			{
				id: 3,
				name: "Product Overview",
				icon: "fa-book",
				color: "#ffadad",
			},
			{
				id: 4,
				name: "Presentation",
				icon: "fa-presentation-screen",
				color: "#ff5ac4",
			},
			{
				id: 5,
				name: "Commercial",
				icon: "fa-money-check-dollar",
				color: "#784bd1",
			},
			{
				id: 6,
				name: "Legal",
				icon: "fa-file-contract",
				color: "#579bfc",
			},
		];
		subject.defaultValue = 4;

		let meetingType = new mSingleOptionFieldDef(createUniqueId());
		meetingType.name = "meetingType";
		meetingType.pluralName = "meetingTypes";
		meetingType.displayName = "Type";
		meetingType.optionValues = [
			{
				id: 1,
				name: "Face to face",
				icon: "fa-buildings",
				color: "#ff5ac4",
			},
			{
				id: 2,
				name: "Remote",
				icon: "fa-desktop",
				color: "#784bd1",
			},
			{
				id: 3,
				name: "Hybrid",
				icon: "fa-person-chalkboard",
				color: "#579bfc",
			},
		];
		meetingType.defaultValue = 2;
		meetingType.componentType = FIELD_COMPONENT_TYPES.SELECT.name;
		meetingType.required = true;

		let scheduleNow = new mBooleanFieldDef(createUniqueId());
		scheduleNow.name = "scheduleNow";
		scheduleNow.pluralName = "scheduleNow";
		scheduleNow.displayName = "Schedule Now";
		scheduleNow.componentType = FIELD_COMPONENT_TYPES.CHECKBOX.name;
		scheduleNow.formatDescription = "Schedule the conference for now";

		let scheduledDate = new mDateTimeFieldDef(createUniqueId());
		scheduledDate.name = "scheduledDate";
		scheduledDate.pluralName = "scheduledDates";
		scheduledDate.displayName = "Scheduled Date";
		scheduledDate.format = "YYYY-MM-DDTHH:mm";
		scheduledDate.formatDescription = "YYYY-MM-DDTHH:mm";
		scheduledDate.required = true;

		let duration = new mDurationFieldDef(createUniqueId());
		duration.name = "duration";
		duration.pluralName = "durations";
		duration.displayName = "Duration";
		duration.required = true;
		duration.defaultValue = 1800000;

		let agenda = new mRichTextFieldDef(createUniqueId());
		agenda.name = "agenda";
		agenda.pluralName = "agenda";
		agenda.displayName = "Agenda";
		agenda.formatDescription = "Agenda for recipients";
		agenda.componentType = FIELD_COMPONENT_TYPES.RICH_TEXT.name;
		agenda.maxLength = 1000;
		agenda.required = true;

		super.data.addChild(title);
		super.data.addChild(subject);
		super.data.addChild(meetingType);
		super.data.addChild(scheduleNow);
		super.data.addChild(duration);
		super.data.addChild(scheduledDate);

		super.data.addChild(agenda);
	}
}
