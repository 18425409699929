import { useNotificationsContext } from "../hooks";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useCallback, useMemo, useState } from "react";
import { Button, Tooltip } from "@salesdesk/daisy-ui";

export function MarkAllAsReadButton() {
	const { notifications, markRead } = useNotificationsContext();
	const [disabled, setDisabled] = useState(false);

	const unreadNotifications = useMemo(
		() => notifications?.filter((notification) => !notification.read) || [],
		[notifications]
	);

	const onMarkAllRead = useCallback(async () => {
		setDisabled(true);
		if (unreadNotifications.length > 0) await markRead(unreadNotifications);
		setDisabled(false);
	}, [markRead, unreadNotifications]);

	const noUnreadNotifications = unreadNotifications.length === 0;

	return (
		<Tooltip ignoreDisabled={true} text={noUnreadNotifications ? "All notifications have been marked read" : undefined}>
			<Button
				startIcon={ICONS.checks}
				size="sm"
				variant="primary_text"
				onClick={onMarkAllRead}
				disabled={noUnreadNotifications || disabled}
			>
				Mark all as read
			</Button>
		</Tooltip>
	);
}
