import { useCallback, useState } from "react";

import { SDObject, RecordQueryClauses } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { BaseSelectPopoverOptions, BaseSelect, FixedSelectButton, SelectOptionId } from "../../../../../inputs";
import { useAssociationFilters } from "../hooks";
import { Button } from "@salesdesk/daisy-ui";

interface AssociationFilteringSelectProps {
	associationSDObject: SDObject;
	onChange: (filters: RecordQueryClauses[]) => void;
	iconOnly?: boolean;
}

export function AssociationFilteringSelect({
	associationSDObject,
	onChange,
	iconOnly,
}: AssociationFilteringSelectProps) {
	const [multipleSelectValue, setMultipleSelectValue] = useState<SelectOptionId[]>([]);
	const [singleSelectValue, setSingleSelectValue] = useState<SelectOptionId | undefined>("");
	const filterOptions = useAssociationFilters(associationSDObject);

	const onFilterSelectChange = useCallback(
		(value?: SelectOptionId | SelectOptionId[]) => {
			if (!filterOptions) return;

			const isMultiSelect = Array.isArray(value);
			const selectedValues: SelectOptionId[] = isMultiSelect ? value : value ? [value] : [];

			const filterValues = filterOptions.options.reduce((filterValues, option) => {
				if (selectedValues.includes(option.id)) {
					return [...filterValues, ...option.value];
				}

				return filterValues;
			}, [] as RecordQueryClauses[]);

			if (isMultiSelect) {
				setMultipleSelectValue(value);
			} else {
				setSingleSelectValue(value);
			}

			onChange(filterValues);
		},
		[filterOptions, onChange]
	);

	const clearFilters = useCallback(() => {
		onFilterSelectChange(filterOptions?.isMultiple ? [] : "");
	}, [filterOptions, onFilterSelectChange]);

	if (!filterOptions) return null;

	const { title, options, isMultiple } = filterOptions;

	const selectProps = {
		optionsTitle: (
			<div className="flex min-w-[228px] items-center justify-between">
				<span>{title}</span>
				<Button size="sm" variant="primary_text" onClick={clearFilters}>
					Clear
				</Button>
			</div>
		),
		tooltip: title,
		selectPopoverOptions: { placement: "bottom-start" } satisfies BaseSelectPopoverOptions,
		options,
		onChange: onFilterSelectChange,
		selectButton: (
			<FixedSelectButton startIcon={ICONS.filter} alertCount={isMultiple ? multipleSelectValue.length : undefined}>
				{iconOnly ? undefined : "Filter"}
			</FixedSelectButton>
		),
	};

	if (isMultiple) {
		return <BaseSelect isMultiselect value={multipleSelectValue} {...selectProps} />;
	}

	return <BaseSelect value={singleSelectValue} {...selectProps} />;
}
