import { useFormContext, Controller } from "react-hook-form";

import { mFieldDef } from "@salesdesk/salesdesk-model";

import { FieldComponentFactory } from "../../../../../forms";
import { DataSettingsGroupedFields } from "../../utils";
import { SettingGroup } from "./SettingGroup";

interface ObjectSettingsProps {
	fieldGroups: DataSettingsGroupedFields[];
}

export function ObjectSettings({ fieldGroups }: ObjectSettingsProps) {
	const { control } = useFormContext();

	return (
		<div className="m-6 mt-4 flex justify-center">
			<div className="flex w-full max-w-[480px] flex-col gap-6">
				{fieldGroups.map((fieldGroup, index) => (
					<SettingGroup title={fieldGroup.groupName} key={index}>
						{fieldGroup.fields.map((field) => {
							const fieldDefId = String(field._id);
							return (
								<Controller
									key={field._name}
									control={control}
									name={field._name!}
									render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
										<FieldComponentFactory
											fieldDef={field as mFieldDef}
											componentProps={{
												id: fieldDefId,
												onBlur,
												onChange,
												inputRef: ref,
												value: value,
												hasError: Boolean(error?.message),
												disabled: !field.editable,
											}}
											errorMessage={error?.message}
										/>
									)}
									rules={{ validate: (value) => field.validate(value, undefined) }}
								/>
							);
						})}
					</SettingGroup>
				))}
			</div>
		</div>
	);
}
