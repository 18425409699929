import { Navigate } from "react-router-dom";
import { getNotFoundPath } from "../utils";

interface NavigateNotFoundProps {
	navigateToWorkspaceError?: boolean;
}

export function NavigateNotFound({ navigateToWorkspaceError }: NavigateNotFoundProps) {
	return <Navigate to={getNotFoundPath(navigateToWorkspaceError)} replace={true} />;
}
