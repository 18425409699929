import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { mAssetDef, mDocDef } from "@salesdesk/salesdesk-model";
import { useWorkspaceSharedRecordSearch } from "../../../../../../hooks/useWorkspaceSharedRecordSearch";
import { Skeleton } from "../../../../../../../../components/Skeleton/Skeleton";
import { pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { ROW_IS_LOADING_PLACEHOLDER, Table } from "../../../../../../../Table";
import {
	DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID,
	generateColumns,
	generateRows,
	getAdditionalRecordSearchParams,
	RECORD_TYPE_COLUMN_ID,
	SD_RECORD_COLUMN_ID,
} from "../utils";
import { useGetObjectMap } from "../../../../../../../objects/hooks";
import { InfiniteRecordSearchParams, SortingDetails } from "../../../../../../../records";
import { SkeletonTransition } from "../../../../../../../../components/Skeleton/SkeletonTransition";
import { useWorkspaceContext } from "../../../../../../hooks/useWorkspaceContext";
import { FavoritedRecordsContextMenu } from "./FavoritedRecordsContextMenu";
import { useGetFavoritedRecordsQuery } from "../../../../../../../records/api/favoritesApi";
import { Spinner } from "@salesdesk/daisy-ui";
import { FavoritedRecordTableRow } from "../types";
import { useWorkspaceLibraryStateContext } from "../../../hooks/useWorkspaceLibraryStateContext";

export function FavoritedRecords() {
	const objectIds = useMemo(() => [mAssetDef.ID, mDocDef.ID], []);

	const { currentData: favoritedRecordIds } = useGetFavoritedRecordsQuery();

	const [sorting, setSorting] = useState<SortingDetails[]>([]);
	const [additionalSearchParams, setAdditionalSearchParams] = useState<InfiniteRecordSearchParams | undefined>();

	useEffect(() => {
		const newParams = getAdditionalRecordSearchParams(favoritedRecordIds, sorting);

		setAdditionalSearchParams((previousParams) => {
			if (JSON.stringify(newParams) === JSON.stringify(previousParams)) {
				return previousParams;
			}

			return newParams;
		});
	}, [favoritedRecordIds, sorting]);

	const { workspaceRecord, scrollContainerRef } = useWorkspaceContext();
	const { urlState } = useWorkspaceLibraryStateContext();
	const { records, hitCount, isLoadingRecords, isLoadingNextPage, loadNextPage } = useWorkspaceSharedRecordSearch({
		sdObjectFilter: objectIds,
		additionalSearchParams: additionalSearchParams ?? skipToken,
	});

	const objectMap = useGetObjectMap();

	const columns = useMemo(() => generateColumns(objectMap, sorting, setSorting), [objectMap, setSorting, sorting]);
	const rows = useMemo(() => {
		if (!workspaceRecord) {
			return [];
		}
		return generateRows(records, objectMap, workspaceRecord._id, urlState);
	}, [workspaceRecord, records, objectMap, urlState]);

	const skeletonPlaceholderRows = useMemo(
		() => Array.from({ length: 6 }, () => ({ [ROW_IS_LOADING_PLACEHOLDER]: true })),
		[]
	);

	const controlColumn = useMemo(
		() => ({
			width: 64,
			render: (row: FavoritedRecordTableRow) => {
				const sdRecord = row[SD_RECORD_COLUMN_ID];
				const sdRecordId = typeof sdRecord === "object" && "_id" in sdRecord ? sdRecord._id : undefined;
				const objectId = row[RECORD_TYPE_COLUMN_ID];
				if (row[ROW_IS_LOADING_PLACEHOLDER] || typeof sdRecordId !== "number" || typeof objectId !== "number") {
					return null;
				}
				return <FavoritedRecordsContextMenu recordId={sdRecordId} objectId={objectId} />;
			},
		}),
		[]
	);

	return (
		<div className="mt-6 flex flex-col gap-6">
			<div className="text-label text-c_text_secondary my-1.5">
				<div className="flex items-center gap-1">
					{isLoadingRecords ? (
						<Skeleton className="h-6 w-32" />
					) : (
						<div>
							Total: {hitCount} {pluralizeWithS("record", hitCount)}
						</div>
					)}
				</div>
			</div>
			<SkeletonTransition className="flex h-full w-full flex-col">
				<Table
					outsideScrollContainerRef={scrollContainerRef}
					rows={isLoadingRecords ? skeletonPlaceholderRows : rows}
					columns={columns}
					onBottomReached={loadNextPage}
					bottomOffset={200}
					controlColumn={controlColumn}
					tableFooter={
						isLoadingNextPage ? (
							<div className="my-6 flex w-full items-center justify-center">
								<Spinner size="md" />
							</div>
						) : undefined
					}
					defaultColumnWidthByColumnId={DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID}
					containerRightPadding={0}
				/>
			</SkeletonTransition>
		</div>
	);
}
