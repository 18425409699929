import { Droppable } from "@hello-pangea/dnd";

import { FieldType } from "@salesdesk/salesdesk-model";

import { FieldChipComponent } from "./FieldChipComponent";
import { Accordion } from "../../../../../../../../components/Accordion";

interface FieldDisclosureProps {
	label: string;
	fields: FieldType[];
}

export function FieldDisclosure({ label, fields }: FieldDisclosureProps) {
	return (
		<Accordion title={label} defaultOpen>
			<Droppable droppableId={label} isDropDisabled={true}>
				{(provided) => (
					<div ref={provided.innerRef} className="mt-4 flex flex-col pl-6">
						{fields.map((field, index) => (
							<FieldChipComponent key={field.displayName} {...field} index={index} />
						))}
						<div className="sr-only">{provided.placeholder}</div>
					</div>
				)}
			</Droppable>
		</Accordion>
	);
}
