import { ReactNode } from "react";

import { OnChangeFn, Row, RowSelectionState } from "@tanstack/react-table";

export const ROW_LINK_COLUMN_ID = "TABLE_ROW_LINK_COLUMN_ID";
export const ROW_IS_LOADING_PLACEHOLDER = "TABLE_ROW_LOADING_PLACEHOLDER_ID";
export const ROW_RECORD_DATA_ID = "CONTROL_ROW_RECORD_DATA_COLUMN_ID";
export const ROW_SELECTION_COLUMN_ID = "CONTROL_ROW_SELECTION_COLUMN_ID";

export type TableColumnId = string;

interface TableRowWithoutLink<T> {
	[id: TableColumnId]: T;
}

export type TableRow<T> = TableRowWithoutLink<T> & {
	[ROW_LINK_COLUMN_ID]?: (() => string | null) | string;
	[ROW_IS_LOADING_PLACEHOLDER]?: boolean;
};

export type getRowIdFn<T> = (row: TableRow<T>, index: number, parent?: Row<TableRow<T>> | undefined) => string;

type RenderDisplayName = () => ReactNode;

export interface TableColumn<T> {
	id: TableColumnId;
	displayName: string | RenderDisplayName;
	renderRowCell?: (cellData: T, row: TableRow<T>) => ReactNode;
}

export enum COLUMN_TYPE {
	START,
	CENTER,
	END,
	SINGLE,
}

export interface EndColumnDetails {
	id: string | null;
	visualWidth: number;
	widthOffset?: number;
}

export interface ControlColumnDetails<T> {
	width: number;
	render: (row: TableRow<T>, index?: number) => ReactNode;
}

export interface RowSelectionDetails {
	value?: RowSelectionState;
	onChange: OnChangeFn<RowSelectionState>;
	inSelectionMode?: boolean;
	singleSelect?: boolean;
}

export enum TableVariant {
	primary = "primary",
	secondary = "secondary",
}
