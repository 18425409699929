import { useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon } from "@salesdesk/daisy-ui";

import { PopoverMenu } from "../../menu";
import { Avatar, getInitials, useConvertUserSDRecordsToAvatarUsers, useGetCurrentUserRecord } from "../../users";
import { useUserSettingsMenuContents } from "../hooks/useUserSettingsMenuContents";
import { LogOutConfirmationDialog } from "./LogOutConfirmationDialog";
import { CurrentAccountLogo } from "../../tenant";

export const UserMenu = () => {
	const userRecord = useGetCurrentUserRecord();

	const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);

	const toggleLogOutConfirmationDialog = () => {
		setLogoutConfirmationOpen((prevLogoutConfirmationOpen) => !prevLogoutConfirmationOpen);
	};

	const menuItems = useUserSettingsMenuContents({
		onLogOutClick: toggleLogOutConfirmationDialog,
	});

	const user = useConvertUserSDRecordsToAvatarUsers(userRecord);

	return (
		<>
			<PopoverMenu tooltipText="Your profile and settings" menuContents={menuItems} widthVariant="fit">
				<button className="text-c_icon_regular group flex items-center gap-2">
					<div className="group-hover:outline-c_border_btn group-focus-visible:ring-c_action_focus relative h-10 w-10 rounded-full outline outline-transparent group-hover:outline-4 group-focus-visible:ring group-focus-visible:ring-offset-1">
						{user ? <Avatar initials={getInitials(user.fullName)} avatarFileId={user.avatarFileId} size="md" /> : null}
						<div className="bg-c_bg_01 absolute -bottom-0.5 -right-1.5 rounded-sm">
							<CurrentAccountLogo size="xs" />
						</div>
					</div>
					<Icon icon={ICONS.caretDown} className="ui-open:rotate-180 flex size-5" />
				</button>
			</PopoverMenu>
			{logoutConfirmationOpen ? (
				<LogOutConfirmationDialog open={logoutConfirmationOpen} onDismiss={toggleLogOutConfirmationDialog} />
			) : null}
		</>
	);
};
