import { useGetUserByUserId } from "../../hooks";
import { InlineUser } from "./InlineUser";
import { InlineUserLoading } from "./InlineUserLoading";

interface InlineUserControllerProps {
	userId: number;
}

export function InlineUserController({ userId }: InlineUserControllerProps) {
	const { user, isSystemUser } = useGetUserByUserId(userId);

	if (!user) {
		return <InlineUserLoading />;
	}

	return <InlineUser user={user} isSystemUser={isSystemUser} />;
}
