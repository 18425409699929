import { asConst } from "json-schema-to-ts";
import { queryClausePropertyValueSchema } from "./queryClausePropertyValueSchema";

export const matchPrefixClauseSchema = asConst({
	title: "Match Prefix",
	description: "Will match any record where the given field value starts with the provided value",
	type: "object",
	additionalProperties: false,
	required: ["matchPrefix"],
	properties: {
		matchPrefix: queryClausePropertyValueSchema,
	},
	nullable: false,
});
