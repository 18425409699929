import { Icon, InputValidationState, Tooltip } from "@salesdesk/daisy-ui";
import { Field, getSDObjectFields, ImportMappingEntry } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { ColumnMappingRow } from "./ColumnMappingRow";
import { Select, SelectOption, SelectOptionId, SelectOptions, SelectOptionSection } from "../../../../inputs";
import { useImportContext } from "../../../hooks/useImportContext";
import { useCallback, useMemo } from "react";
import { UniqueFieldType } from "@salesdesk/salesdesk-model";

interface ColumnMappingRowControllerProps {
	mapping: ImportMappingEntry;
}

export function ColumnMappingRowController({ mapping }: ColumnMappingRowControllerProps) {
	const { sdObject, mappingEntries, updateMappingEntry, selectedMappingEntry, setSelectedMappingEntry } =
		useImportContext();

	const mappingEntryValue = useMemo(() => {
		const mappingEntry = mappingEntries.find((e) => e.columnName === mapping.columnName);
		return mappingEntry?.fieldId;
	}, [mapping.columnName, mappingEntries]);

	const onMappingFieldChange = useCallback(
		(value: SelectOptionId | undefined) => {
			updateMappingEntry(mapping.columnName, value ? Number(value) : undefined);
		},
		[mapping.columnName, updateMappingEntry]
	);

	const getFieldOption = useCallback((field: Field): SelectOption => {
		return {
			id: field._id,
			name: field._displayName,
			icon: field._icon,
			description:
				field._unique === UniqueFieldType.Object
					? "Unique"
					: field._unique === UniqueFieldType.Global
						? "Global Unique"
						: undefined,
			descriptionVariant: "inline_button",
		};
	}, []);

	const fieldOptions: SelectOptions = useMemo(() => {
		if (!sdObject) return [];
		const fields = getSDObjectFields(sdObject);
		const requiredFields = [];
		const optionalFields = [];
		for (const field of fields) {
			if (field._required) {
				requiredFields.push(field);
			} else {
				optionalFields.push(field);
			}
		}
		return [
			{
				sectionTitle: "Required",
				options: requiredFields.map(getFieldOption),
			} satisfies SelectOptionSection,
			optionalFields.map(getFieldOption),
		];
	}, [sdObject, getFieldOption]);

	const entriesWithSameFieldCount = useMemo(
		() => mappingEntries.reduce((acc, entry) => (entry.fieldId === mappingEntryValue ? acc + 1 : acc), 0),
		[mappingEntries, mappingEntryValue]
	);

	return (
		<ColumnMappingRow
			key={mapping.columnIndex}
			isActive={selectedMappingEntry?.columnName === mapping.columnName}
			onMouseEnter={() => setSelectedMappingEntry(mapping)}
			nameCell={
				<>
					<Icon icon={ICONS.table} className="text-c_icon_regular flex items-center" />
					<Tooltip showOnTruncated text={mapping.columnName}>
						<div className="truncate">{mapping.columnName}</div>
					</Tooltip>
				</>
			}
			mappingCell={
				<div className="flex flex-col gap-2">
					<Select
						options={fieldOptions}
						onChange={onMappingFieldChange}
						value={mappingEntryValue}
						placeholder="Unmapped"
						validationState={entriesWithSameFieldCount > 1 ? InputValidationState.error : undefined}
					/>
					{entriesWithSameFieldCount > 1 && (
						<div className="text-c_danger_01 text-body-sm">Multiple mappings detected</div>
					)}
				</div>
			}
		/>
	);
}
