import { SettingsHeader } from "./SettingsHeader";
import { Toasts } from "../Toasts";
import { Outlet } from "react-router-dom";
import { useUserSettingsMainMenuItems } from "../UserMenu";
import { NavButton } from "../../components/NavButton";
import { Fragment } from "react";

export function SettingsLayout() {
	const mainMenuItems = useUserSettingsMainMenuItems();
	return (
		<>
			<Toasts position="main" />
			<div className="bg-c_bg_02 flex h-screen w-full flex-col pl-8">
				<SettingsHeader />
				<div className="flex h-full w-full gap-4 overflow-hidden">
					<div className="flex w-[177px] shrink-0 flex-col pt-12">
						{mainMenuItems.map((menuItem) => (
							<Fragment key={menuItem.text}>
								<NavButton item={menuItem} />
								{"subMenu" in menuItem
									? menuItem.subMenu.map((subItem) =>
											"text" in subItem ? <NavButton key={subItem.text} item={subItem} /> : null
									  )
									: null}
							</Fragment>
						))}
					</div>
					<div className="rounded-tl-panel bg-c_bg_01 h-full grow overflow-hidden">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
