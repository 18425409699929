import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { SD_API_HOST } from "../../app_config";
import { Auth } from "../../auth";
import { getTenantFromPath } from "../../utils";

export const ApiTagTypes = [
	"Roles",
	"Users",
	"Object",
	"Record",
	"Favorites",
	"Watches",
	"Notes",
	"Comments",
	"LiveParticipants",
	"Events",
	"MeetingChatMessages",
	"Bookmark",
	"Notifications",
	"NotificationsCounter",
	"NotificationRules",
	"File",
	"AssociationsSummary",
	"UserAppState",
	"Tenant",
	"UserPreferences",
	"ApiKeys",
] as const;

export type ApiTagType = (typeof ApiTagTypes)[number];

export type ApiTag = { type: ApiTagType; id: string | number } | ApiTagType;

export const SDApi = createApi({
	reducerPath: "SDApi",
	baseQuery: fetchBaseQuery({
		baseUrl: SD_API_HOST,
		prepareHeaders: async (headers) => {
			headers.set("Authorization", await Auth.getAuthHeader());
			const tenant = getTenantFromPath();
			if (tenant != null) {
				headers.set("X-Sd-Tenant", tenant);
			}
		},
	}),
	tagTypes: ApiTagTypes,
	// Endpoints are injected from feature APIs (e.g. objectsApi.ts, recordsApi.ts) to
	// keep relevant endpoints grouped together and prevent the API slice from becoming too large
	endpoints: (builder) => {
		return {};
	},
});
