import { useEffect, useState } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import { useStoreFile } from "../../../../../files";
import { useSdFile } from "../../../../../files/hooks/useSdFile";
import { SdFileImageAttributes } from "../../utils/SdFileImageExtension";
import clsx from "clsx";
import { DisplayProgressField } from "../../../../../fields/components/Fields/TextField/components/DisplayProgressField";
import { Spinner } from "@salesdesk/daisy-ui";
import { NodeViewProps } from "@tiptap/core";
import { ImageResizeBox } from "./ImageResizeBox";

type DocumentImageProps = NodeViewProps & {
	node: {
		attrs: SdFileImageAttributes;
	};
	isReadonly?: boolean;
};

export function SdFileImageNode({ node, updateAttributes, selected, isReadonly }: DocumentImageProps) {
	const storeFile = useStoreFile();

	const { sdFile } = useSdFile(node.attrs.sdFileId);

	const [src, setSrc] = useState<string | undefined>(node.attrs.blobUrl);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	useEffect(() => {
		// Switch back to blobUrl when the image gets replaced
		if (node.attrs.blobUrl && !node.attrs.sdFileId) {
			setSrc(node.attrs.blobUrl);
		}
	}, [node.attrs.blobUrl, node.attrs.sdFileId]);

	useEffect(() => {
		if (sdFile?.signedUrl) {
			setSrc(sdFile.signedUrl);
			setIsUploading(false);
			setUploadProgress(0);
		}
	}, [sdFile]);

	useEffect(() => {
		const upload = async () => {
			if (node.attrs.blobUrl) {
				setIsUploading(true);

				// 1. Convert blob url as a file
				const response = await fetch(node.attrs.blobUrl);
				const blob = await response.blob();
				const file = new File([blob], node.attrs.fileName ?? "image.png", { type: node.attrs.fileType ?? "image/png" });

				// 2. Upload File
				const fileId = await storeFile(file, (newProgress) => {
					setUploadProgress((currentProgress) => (newProgress > currentProgress ? newProgress : currentProgress));
				});

				// 3. Update Node Attributes
				updateAttributes({
					...node.attrs,
					blobUrl: undefined,
					sdFileId: fileId,
				});
			}
		};
		upload();
	}, [node.attrs, storeFile, updateAttributes]);

	const handleResize = (width: number, height: number) => {
		updateAttributes({
			...node.attrs,
			displayWidth: width,
			displayHeight: height,
		});
	};

	return (
		<NodeViewWrapper
			className={clsx("flex w-full", {
				"justify-center": node.attrs.textAlign === "center",
				"justify-end": node.attrs.textAlign === "right",
			})}
		>
			<div className="relative w-fit" style={{ width: node.attrs.displayWidth, height: node.attrs.displayHeight }}>
				{src ? <img src={src} alt="" className={clsx("w-full", isUploading && "opacity-50")} /> : null}
				{selected && !isReadonly ? (
					<ImageResizeBox
						initialWidth={node.attrs.displayWidth || 0}
						initialHeight={node.attrs.displayHeight || 0}
						onResize={handleResize}
					/>
				) : null}
				{isUploading ? (
					<div className="@container/image absolute left-0 top-0 flex h-full w-full items-center justify-center">
						<div className="@[120px]/image:block hidden w-full max-w-[224px] px-4">
							<DisplayProgressField value={uploadProgress ?? 0} />
						</div>
						<div className="@[120px]/image:hidden inline-block">
							{/* TODO: Implement circular progress indicator */}
							<Spinner />
						</div>
					</div>
				) : null}
			</div>
		</NodeViewWrapper>
	);
}
