import { useCallback, useMemo, useRef } from "react";
import clsx from "clsx";

import { Tooltip } from "@salesdesk/daisy-ui";
import { formatTimeIfToday } from "@salesdesk/salesdesk-utils";

import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { ChannelDetails, LoadingStatus } from "../../../../../types";
import {
	useChannelDisplayNameSelector,
	useChannelLastMessageSelector,
	useChimeChannelStatusSelector,
} from "../../../../../store";
import { channelIsUnread } from "../../../../../utils";
import { ChannelMessagePreview, ChannelMessagePreviewError } from "./ChannelMessagePreview";
import { ChannelIcon } from "./ChannelIcon";
import { useAppDispatch } from "../../../../../../../store/store";
import { fetchInitialChannelMemberships } from "../../../../../store/thunks";
import { useChimeMessagingContext } from "../../../../providers";
import { throttle } from "../../../../../../../utils";

interface ChannelPreviewProps {
	channelDetails: ChannelDetails;
	onClick: (channelArn: string) => void;
}

export function ChannelPreview({ channelDetails, onClick }: ChannelPreviewProps) {
	const { channelSummary, arn } = channelDetails;

	const { chatStatus, channelDetailsStatus } = useChimeChannelStatusSelector(arn);
	const lastChannelMessage = useChannelLastMessageSelector(arn);
	const displayName = useChannelDisplayNameSelector(arn);

	const isUnread = useMemo(() => channelIsUnread(channelDetails), [channelDetails]);

	const lastMessageTimestamp = useMemo(() => {
		return channelSummary?.LastMessageTimestamp
			? formatTimeIfToday(new Date(channelSummary.LastMessageTimestamp))
			: null;
	}, [channelSummary?.LastMessageTimestamp]);

	const dispatch = useAppDispatch();
	const { chime } = useChimeMessagingContext();
	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const channelDetailsHaveErrored = channelDetailsStatus === LoadingStatus.Error;

	// Pre-emptively fetch the channel members when hovering over the channel for a faster experience.
	// Will only fetch the channel members if they haven't been fetched yet
	const handleMouseEnter = useCallback(() => {
		if (hoverTimeoutRef.current != null || !chime || channelDetailsHaveErrored) {
			return;
		}

		hoverTimeoutRef.current = setTimeout(() => {
			dispatch(fetchInitialChannelMemberships({ chime, channelArn: arn }));
			hoverTimeoutRef.current = null; // Clear the reference after execution
		}, 300);
	}, [chime, channelDetailsHaveErrored, dispatch, arn]);

	const handleMouseOut = useCallback(() => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
	}, []);

	return (
		<button
			className="border-c_border_regular hover:bg-c_bg_03 flex h-[74px] w-full items-start gap-1.5 border-b p-4 transition"
			onClick={() => {
				onClick(arn);
			}}
			onMouseOver={handleMouseEnter}
			onMouseOut={handleMouseOut}
		>
			<ChannelIcon channelDetails={channelDetails} />
			<div className="flex w-full max-w-full flex-col items-start gap-0.5 overflow-hidden">
				<Tooltip placement="top" text={displayName} showOnTruncated noWrap>
					<div className="text-label-sm w-full truncate text-start">
						{displayName ??
							(channelDetailsStatus === LoadingStatus.Error ? (
								<span className="text-c_danger_01">Failed to load channel</span>
							) : (
								<Skeleton className="h-5 w-full" />
							))}
					</div>
				</Tooltip>
				<div
					className={clsx(
						isUnread ? "text-label-sm" : "text-body-sm",
						"text-c_text_secondary w-full truncate text-start"
					)}
				>
					{chatStatus === LoadingStatus.Error ? (
						<ChannelMessagePreviewError />
					) : (
						<ChannelMessagePreview message={lastChannelMessage} />
					)}
				</div>
			</div>
			<div className="text-body-sm text-c_text_secondary ml-1.5 flex h-full min-w-9 shrink-0 flex-col items-end gap-2.5">
				<span>{lastMessageTimestamp}</span>
				{isUnread ? <div className="bg-c_action_01 size-2 rounded-full" /> : null}
			</div>
		</button>
	);
}
