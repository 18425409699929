import {
	NotificationsCountResponse,
	NotificationsQuery,
	NotificationsReadRequest,
	NotificationsResponse,
	NotificationsUnreadRequest,
	SDNotificationType,
} from "@salesdesk/salesdesk-schemas";

import { SDApi } from "../../api";
import { InAppNotificationNewCountWSMessage, InAppNotificationWSMessageType } from "@salesdesk/salesdesk-schemas";
import { getWebSocket } from "../../WebSocket";

export type GetNotificationsQuery = { notificationType: SDNotificationType; unreadOnly: boolean; size: number } & Omit<
	NotificationsQuery,
	"size"
>;

export const notificationApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getNewNotificationsCount: builder.query<NotificationsCountResponse, void>({
				query: () => `/me/notifications/new/count`,
				providesTags: () => [{ type: "NotificationsCounter" }],
				async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
					console.debug(`Subscribing to WebSocketMessage: ${InAppNotificationWSMessageType.NewCount}`);
					const ws = getWebSocket();
					let unsubscribe;
					try {
						await cacheDataLoaded;
						unsubscribe = ws.subscribeToMessageType(
							InAppNotificationWSMessageType.NewCount,
							(message: InAppNotificationNewCountWSMessage) => {
								updateCachedData((draft) => {
									draft.count = message.count;
								});
							}
						);
					} catch {
						// no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded` and `cacheDataLoaded` throws ,
					}
					await cacheEntryRemoved;
					console.debug(`Unsubscribing from WebSocketMessage: ${InAppNotificationWSMessageType.NewCount}`);
					unsubscribe?.();
				},
			}),
			resetNewNotificationsCount: builder.mutation<void, void>({
				query: () => ({
					url: `/me/notifications/new/count/reset`,
					method: "PUT",
				}),
				invalidatesTags: () => [{ type: "NotificationsCounter" }],
			}),

			getNotifications: builder.query<NotificationsResponse, GetNotificationsQuery>({
				query: ({ unreadOnly, ...params }) => ({
					url: `/me/notifications${unreadOnly ? "/unread" : ""}`,
					params: params,
				}),
				// No invalidatesTags because using lazy
			}),
			markNotificationsRead: builder.mutation<void, NotificationsReadRequest>({
				query: (notificationsReadRequest) => ({
					url: `/me/notifications/read`,
					method: "PUT",
					body: notificationsReadRequest,
				}),
				// No invalidatesTags because Read Notifications are removed in code rather than reloaded
			}),
			markNotificationsUnread: builder.mutation<void, NotificationsUnreadRequest>({
				query: (notificationsUnreadRequest) => ({
					url: `/me/notifications/unread`,
					method: "PUT",
					body: notificationsUnreadRequest,
				}),
				// No invalidatesTags because Unread Notifications are removed in code rather than reloaded
			}),
		};
	},
});

export const {
	useLazyGetNotificationsQuery,
	useGetNewNotificationsCountQuery,
	useResetNewNotificationsCountMutation,
	useMarkNotificationsReadMutation,
	useMarkNotificationsUnreadMutation,
} = notificationApi;
