import { SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS } from "../../../../../../../routes";
import { MenuItem } from "../../../../../../menu";
import { getGoogleCalendarLink, getOutlookCalendarLink } from "./utils";

export function useMeetingRecordActions(sdRecord?: SDRecord, sdObject?: SDObject): MenuItem[] {
	if (mInteractionMeetingDef.ID !== sdObject?._id || !sdRecord || !sdObject) return [];

	return [
		{
			icon: ICONS.signIn,
			text: "Join meeting",
			link: PATHS.MEETING(sdRecord),
			type: "link",
		},
		{
			icon: ICONS.plus,
			text: "Add to calendar",
			subMenu: [
				{
					icon: "google-calendar.svg",
					text: "Google calendar",
					link: getGoogleCalendarLink(sdObject, sdRecord),
					targetBlank: true,
					type: "link",
				},
				{
					icon: "outlook.svg",
					text: "Outlook",
					link: getOutlookCalendarLink(sdObject, sdRecord),
					targetBlank: true,
					type: "link",
				},
			],
			type: "subMenu",
		},
	];
}
