import { encodeDelimitedArray, decodeDelimitedArray, QueryParamConfig } from "use-query-params";

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const DeduplicatedCommaDelimetedArrayParam: QueryParamConfig<
	(string | null)[] | null | undefined,
	(string | null)[] | null | undefined
> = {
	encode: (array) => encodeDelimitedArray(array ? [...new Set(array)] : array, ","),
	decode: (arrayStr) => {
		const decodedArray = decodeDelimitedArray(arrayStr, ",");
		return decodedArray ? [...new Set(decodedArray)] : decodedArray;
	},
};
