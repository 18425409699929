import clsx from "clsx";

import { useMemo } from "react";

import { useSdFile } from "../../../features/files/hooks/useSdFile";
import { convertFileToObjectURL } from "../../../features/files";
import { LogoProps, SIZE_CLASSES } from "../types";

export function Logo({ image, companyName, displayCompanyName = false, size = "md" }: LogoProps) {
	const sizeClass = SIZE_CLASSES[size];

	const { sdFile, isLoading } = useSdFile(typeof image === "number" ? image : null);

	const imageUrl = useMemo(() => {
		if (typeof image === "string") {
			return image;
		} else if (typeof image === "number") {
			return sdFile?.signedUrl;
		} else if (image instanceof File) {
			return convertFileToObjectURL(image);
		}

		return image?.signedUrl;
	}, [image, sdFile]);

	const logo = useMemo(() => {
		return (
			<div
				className={clsx(
					sizeClass,
					size === "xs" && "border-c_bg_01 border",
					isLoading ? "bg-c_bg_disabled_02 animate-pulse" : imageUrl ? "bg-transparent" : "bg-c_brand_primary",
					"flex shrink-0 items-center justify-center overflow-hidden"
				)}
			>
				{imageUrl ? (
					<img className={clsx(sizeClass, "object-cover object-center")} src={imageUrl} alt={`${companyName} logo`} />
				) : isLoading ? null : (
					<span className="text-c_text_inverted">{companyName.charAt(0).toUpperCase()}</span>
				)}
			</div>
		);
	}, [companyName, imageUrl, isLoading, size, sizeClass]);

	if (displayCompanyName) {
		return (
			<div className="flex items-center gap-2">
				{logo}
				<span className="text-h5 text-c_text_secondary">{companyName}</span>
			</div>
		);
	}

	return logo;
}
