import { useEffect, useMemo, useState } from "react";

import { NotificationRule, NotificationRuleType } from "@salesdesk/salesdesk-schemas";

import { useWebPrincipal } from "../../../../../auth";
import { inAppDisabledChannelOption, SelectionType } from "../../types";
import { SelectOption } from "../../../../inputs";
import { NotificationRuleDialogContext } from "../../hooks/useNotificationRuleDialogContext";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";
import { useNotificationRuleOptions } from "../../hooks/useNotificationRuleOptions";
import { CreateRecordNotificationRuleDialog } from "./CreateRecordNotificationRuleDialog";
import { CreateUserNotificationRuleDialog } from "./CreateUserNotificationRuleDialog";
import { useNotificationRuleConfigs } from "../../hooks/useNotificationRuleConfigs";

export interface RuleDialogPropsBase {
	onOpenChange: (open: boolean) => void;
}

export interface CreateRuleDialogProps extends RuleDialogPropsBase {
	type: NotificationRuleType;
}

export interface CreateRecordRuleDialogProps extends CreateRuleDialogProps {
	type: "record";
	objectId: number;
}

export interface CreateUserRuleDialogProps extends CreateRuleDialogProps {
	type: "user";
}

export interface EditRuleDialogProps extends RuleDialogPropsBase {
	rule: NotificationRule;
}

export type NotificationRuleDialogProps = CreateRecordRuleDialogProps | CreateUserRuleDialogProps | EditRuleDialogProps;

export function NotificationRuleDialogContainer(props: NotificationRuleDialogProps) {
	const principal = useWebPrincipal();

	const { onOpenChange, ruleType, objectId, workspaceId, rule } =
		"rule" in props
			? {
					onOpenChange: props.onOpenChange,
					rule: props.rule,
					ruleType: props.rule.ruleType,
					objectId: props.rule.objectId,
					workspaceId: props.rule.workspaceId,
			  }
			: {
					onOpenChange: props.onOpenChange,
					rule: undefined,
					ruleType: props.type,
					objectId: props.type === "record" ? props.objectId : undefined,
					workspaceId: principal.IsCustomerWithSingleWorkspace ? principal.AuthorizedWorkspaceIds[0] : undefined,
			  };

	const [currentSelection, setCurrentSelection] = useState<SelectionType | undefined>(undefined);

	// Working State
	const [selectedObjectId, setSelectedObjectId] = useState<number | undefined | null>(objectId);
	const { sdObject: selectedSDObject } = useGetObjectById(selectedObjectId ?? undefined);
	const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | undefined>(workspaceId ?? undefined);
	const [selectedAction, setSelectedAction] = useState<SelectOption>();
	const [selectedRecord, setSelectedRecord] = useState<SelectOption>();
	const [selectedField, setSelectedField] = useState<SelectOption>();
	const [selectedUsers, setSelectedUsers] = useState<SelectOption[]>([]);
	const [selectedChannels, setSelectedChannels] = useState<SelectOption[]>([inAppDisabledChannelOption]);
	const actualSelectedChannels = useMemo(
		() => selectedChannels.filter((channel) => channel.id !== inAppDisabledChannelOption.id),
		[selectedChannels]
	);

	// Map Rule values to SelectOption(s)
	const ruleOptions = useNotificationRuleOptions(rule ? { rule } : { ruleType, workspaceId });

	// Initialise working state
	useEffect(() => {
		setCurrentSelection(rule ? "eventType" : undefined);
		setSelectedAction(ruleOptions?.actionOption);
		setSelectedRecord(ruleOptions?.recordOption);
		setSelectedField(ruleOptions?.fieldOption ?? undefined);
		setSelectedUsers(ruleOptions?.userOptions ?? []);
		setSelectedChannels([...(ruleOptions.channelOptions ?? []), inAppDisabledChannelOption]);
	}, [
		rule,
		ruleOptions,
		setCurrentSelection,
		setSelectedAction,
		setSelectedRecord,
		setSelectedField,
		setSelectedUsers,
		setSelectedChannels,
	]);

	const ruleConfigs = useNotificationRuleConfigs(ruleType, selectedObjectId);
	const selectedRuleConfig = useMemo(() => {
		if (!selectedAction) return undefined;
		return ruleConfigs.find((config) => config.eventType === selectedAction.id);
	}, [ruleConfigs, selectedAction]);

	return (
		<NotificationRuleDialogContext.Provider
			value={{
				rule,
				currentSelection,
				setCurrentSelection,
				selectedObjectId,
				setSelectedObjectId,
				selectedSDObject,
				ruleConfigs,
				selectedAction,
				setSelectedAction,
				selectedWorkspaceId,
				setSelectedWorkspaceId,
				selectedField,
				setSelectedField,
				selectedUsers,
				setSelectedUsers,
				selectedRecord,
				setSelectedRecord,
				selectedChannels,
				setSelectedChannels,
				actualSelectedChannels,
				selectedRuleConfig,

				onOpenChange,
			}}
		>
			{ruleType === NotificationRuleType.Record ? (
				<CreateRecordNotificationRuleDialog />
			) : (
				<CreateUserNotificationRuleDialog />
			)}
		</NotificationRuleDialogContext.Provider>
	);
}
