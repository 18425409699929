import { PropsWithChildren, useState, useEffect, useCallback } from "react";

import { useOnboardingContext, OnboardingFlowStep } from "../../../../onboarding";
import { CreateWorkspaceFromOriginModal } from "./CreateWorkspaceFromOrigin/CreateWorkspaceFromOriginModal";
import { CreateWorkspaceContext } from "../hooks/useCreateWorkspaceContext";
import { SelectWorkspaceOriginModal } from "./SelectWorkspaceOrigin";
import { OriginRecordDetails } from "../types";

export function CreateWorkspaceModalProvider({ children }: PropsWithChildren) {
	const [originRecordDetails, setOriginRecordDetails] = useState<OriginRecordDetails | undefined>();
	const [isOpen, setIsOpen] = useState(false);

	const { currentOnboardingFlowStep, nextStep } = useOnboardingContext();

	const openModal = useCallback((originRecordDetails?: OriginRecordDetails) => {
		setOriginRecordDetails(originRecordDetails);
		setIsOpen(true);
	}, []);

	useEffect(() => {
		if (currentOnboardingFlowStep === OnboardingFlowStep.OPEN_WORKSPACE_CREATION_MODAL) {
			openModal();
			nextStep();
		}
	}, [currentOnboardingFlowStep, openModal, nextStep]);

	return (
		<CreateWorkspaceContext.Provider
			value={{
				openModal,
				isOpen,
			}}
		>
			{children}
			{originRecordDetails ? (
				<CreateWorkspaceFromOriginModal
					open={isOpen}
					onOpenChange={setIsOpen}
					originRecordDetails={originRecordDetails}
				/>
			) : (
				<SelectWorkspaceOriginModal
					open={isOpen}
					onOpenChange={setIsOpen}
					setOriginRecordDetails={setOriginRecordDetails}
				/>
			)}
		</CreateWorkspaceContext.Provider>
	);
}
