import { isEmpty } from "@salesdesk/salesdesk-utils";

import { mFieldDataChangeEvent } from "../../utils";
import { mOptionField } from "../option_field_inst";

export class mMultiOptionField extends mOptionField {

	// If the option field associated with this option instance has 
	// had its option values updated, we need to check the value that 
	// references the option values to ensure that we are no longer referencing
	// an option that no longer exists. 

	update(event){

		if(this._value){

			for(let i = 0; this._value.length > i; i++){

				let value = this._value[i];

				try{
					this.field.getOptionValueById(value)
				}
				catch(error){

					// We have found an option id in the values
					// that no longer corresponds to an option
					// in the field, so we must remove it

					this.removeValue(id);
				}
			}
		}

		this.fireEvent(mFieldDataChangeEvent.type.FIELD_OPTION_VALUES_UPDATED, event.message, this);
	}

	addValue(value){

		if(!value){
			return;
		}

		if(this.containsValue(value)){
			return;
		}

		if(!this._value){
			this._value = [];
		}

		let values = this._value;
		values.push(value.id);

		this.value = values;

		this.validateQuietly();
	}
	
	removeValue(id){

		if(this._value){

			let values = this._value;

			let index = -1;

			// Safer here to do this type of matching than includes()
			// There is too much opportunity to end up with values as strings
			// rather than ints and thus causing includes() to always fail.

			for(let i = 0; this._value.length > i; i++){

				if(this._value[i] == id){
					index = i;
				}
			}

			if(index !== -1) {
				values.splice(index, 1);

				this._value = values;
			}
		}

		try{
			this.validateQuietly();
		}
		catch(error){}
	}	
	
	// Check whether this field value is selected.
	containsValue(value){
		
		if(isEmpty(this._value)){
			return false;
		}

		if(isEmpty(value)){
			return false;
		}

		// Safer here to do this type of matching than includes()
		// There is too much opportunity to end up with values as strings
		// rather than ints and thus causing includes() to always fail.
 
		for(let i = 0; this._value.length > i; i++){
			if(this._value[i] == value.id){
				return true;
			}
		}

		return false;
	}		

	// Get the values in the form of option values, rather than 
	// simply ids.
	getOptionValues(callback){	
		return this.field.getOptionValuesById(this._value, callback);
	}

	setValuesByOptionId(ids){

		// If we are not using a backingstore, we check the options exists

		if(!this.field.isBackingStore()){
			this.field.getOptionValuesById(ids);
		}
		this._value = ids;
	}

	setValuesByOptionName(name){

		// If we are not using a backingstore, we check the option exists

		if(!this.field.isBackingStore()){
			this.setValuesByOption(this.field.getOptionValuesByName(name));
		}
		else{
			throw Error(`This function is not supported for fields with backing stores`);
		}	
	}

	setValuesByOption(options){

		// If we are not using a backingstore, we check the option exists

		const ids = []

		for(let i = 0; options.length > i; i++){
			ids.push(options[i].id)
		}

		if(!this.field.isBackingStore()){
			this.field.getOptionValuseById(ids);
		}

		this._value = ids;
	}

	displayValue(){		

		if(!isEmpty(this._value)){

			let values = this.field.getOptionValuesById(this._value);

			let valueStrs = [];

			for(let i = 0; values.length > i; i++){
				valueStrs.push(values[i].name);
			}
			
			return valueStrs.toString().replace(/,/g, ", ");
		}
		return "";
	}
}