import { getFieldIdsFromFieldNames, rsr, SDObject, USER_OBJECT_DEF_IDS } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef, mSalesDeskUserDef } from "@salesdesk/salesdesk-model";
import { createHashId } from "@salesdesk/salesdesk-utils";

import { FILTERS, InfiniteRecordSearchParams } from "../../records";

export function generateUsersFilterRsrQuery(
	query = rsr.query().and(rsr.equals("_deleted", false)).not(rsr.equals("isTemplate", true)),
	systemUsersOnly = false,
	onlyAuthorizedToLogIn = false
) {
	query.and(rsr.equalsAnyOf("_objectDefId", systemUsersOnly ? [mSalesDeskUserDef.ID] : USER_OBJECT_DEF_IDS));

	if (onlyAuthorizedToLogIn) {
		query.and(rsr.equals(String(createHashId(mSalesDeskUserDef.LOGIN_AUTHORIZED_FIELD_NAME)), true));
		// .and(rsr.exists(String(createHashId(mUserDef.LAST_ACTIVE_FIELD_NAME)))); - Should we be checking for active users instead? Needs requirements work
	}

	return query;
}

export function getInitials(fullName: string) {
	const surnameIndex = fullName.lastIndexOf(" ") + 1;
	const first = fullName.substring(0, 1);
	const last = surnameIndex > 0 ? fullName.substring(surnameIndex, surnameIndex + 1) : "";
	return `${first}${last}`.toUpperCase();
}

export function getFirstName(fullName: string | undefined): string {
	if (!fullName) return "";
	return fullName.split(" ")[0] ?? "";
}

export function getUserMeetingsSearchParams(
	meetingObject: SDObject,
	userRecordId: number,
	workspaceId?: number
): InfiniteRecordSearchParams {
	const [meetingParticipantsFieldId] = getFieldIdsFromFieldNames(meetingObject, [
		mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME,
	]);

	const query = rsr
		.query()
		.and(rsr.equals("_deleted", false))
		.and(rsr.not(rsr.equals("isTemplate", true)))
		.and(rsr.equals("_objectDefId", mInteractionMeetingDef.ID));

	if (workspaceId != null) {
		query.and(rsr.isSharedWithWorkspace(workspaceId));
	}

	query.and(FILTERS.ms_contains_any.generateSearchQuery(String(meetingParticipantsFieldId), [userRecordId]));

	return { query: query.buildQuery() };
}
