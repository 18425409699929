import { useEffect, useState } from "react";

import { formatDate, formatDateTime, formatTime, mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";

import { DisplayPlaceholderField } from "../../DisplayPlaceholderField";
import { DisplayTextField } from "../../TextField";
import { TimeRange } from "@salesdesk/salesdesk-schemas";
import { DateTimeFieldVariant } from "../../DateTimeField";

interface DisplayTimeRangeFieldProps {
	value?: TimeRange | null;
	placeholder?: string;
	variant?: DateTimeFieldVariant;
}

export function DisplayTimeRangeField({
	value,
	placeholder,
	variant = DateTimeFieldVariant.DATE,
}: DisplayTimeRangeFieldProps) {
	const [stringValue, setStringValue] = useState(() => formatTimeRange(value, variant));

	useEffect(() => {
		setStringValue(formatTimeRange(value, variant));
	}, [value, variant]);

	if (!stringValue) {
		return <DisplayPlaceholderField placeholder={placeholder} />;
	}

	return <DisplayTextField value={stringValue} />;
}

function formatTimeRange(value: TimeRange | null | undefined, variant: DateTimeFieldVariant) {
	if (value == null) return "";

	const dateOnly = variant === DateTimeFieldVariant.DATE;
	const startDate = value.start == null ? null : mapUtcTimeStampToLocalDateTime(value.start, dateOnly);
	const endDate = value.end == null ? null : mapUtcTimeStampToLocalDateTime(value.end, dateOnly);

	if (dateOnly || startDate == null || endDate == null || startDate.toDateString() !== endDate.toDateString()) {
		return `${formatTimeRangeDate(startDate, dateOnly)} - ${formatTimeRangeDate(endDate, dateOnly)}`;
	}

	return `${formatDate(startDate)}, ${formatTime(startDate)} - ${formatTime(endDate)}`;
}

function formatTimeRangeDate(date: Date | null, dateOnly: boolean) {
	if (date == null) return "_";

	return dateOnly ? formatDate(date) : formatDateTime(date);
}
