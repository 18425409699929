import { asConst } from "json-schema-to-ts";
import { sortByCriteriaSchema } from "./sortByCriteriaSchema";

export const sortBySchema = asConst({
	title: "Search Sort By",
	description: "The property and criteria you wish to sort by",
	type: "object",
	additionalProperties: sortByCriteriaSchema,
	minProperties: 1,
	maxProperties: 1,
	nullable: false,
});
