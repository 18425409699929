import { ChimeSDKMessagingClient, GetMessagingSessionEndpointCommand } from "@aws-sdk/client-chime-sdk-messaging";
import { useEffect, useState } from "react";

export function useChimeMessagingSessionEndpoint(
	chimeSDKMessagingClient: ChimeSDKMessagingClient,
	onError?: () => void
) {
	const [messagingClientEndpoint, setMessagingClientEndpoint] = useState<string>();

	useEffect(() => {
		chimeSDKMessagingClient
			.send(new GetMessagingSessionEndpointCommand())
			.then((output) => {
				console.debug("Got Chime GetMessagingSessionEndpoint", output.Endpoint);
				setMessagingClientEndpoint(output.Endpoint?.Url);
			})
			.catch((error) => {
				console.error("Failed to get Chime GetMessagingSessionEndpoint", error);
				onError?.();
			});
	}, [chimeSDKMessagingClient, onError]);

	return messagingClientEndpoint;
}
