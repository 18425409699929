import clsx from "clsx";
import { Tab } from "@headlessui/react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { Icon } from "@salesdesk/daisy-ui";
import { CoachingAdviceCategory } from "../types";

const BUTTON_WIDTH = 178;

interface CoachingAdviceItemProps {
	category: CoachingAdviceCategory;
}

export function CoachingAdviceCategoryTab({ category }: CoachingAdviceItemProps) {
	return (
		<Tab
			className={({ selected }) =>
				clsx(
					"rounded-card flex shrink-0 flex-col items-center justify-center gap-2 border p-3",
					selected
						? "bg-c_bg_01 shadow-tooltip text-label-sm border-transparent"
						: "border-c_border_regular text-c_text_placeholder text-body-sm",
					"focus-visible:ring-c_action_focus hover:bg-c_bg_03 focus-visible:ring"
				)
			}
			style={{ width: BUTTON_WIDTH }}
		>
			{({ selected }) => (
				<>
					<Icon
						icon={ICONS[category.icon]}
						className={clsx("flex items-center", selected ? "text-c_action_02" : "text-c_action_04")}
						size="xl"
					/>
					<div>{category.label}</div>
				</>
			)}
		</Tab>
	);
}
