import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "../auth";
import { useEffect } from "react";
import { PATHS } from "../routes";

const API_KEY_ALLOWED_PATHS = ["/app/:tenantId/workspaces/:workspaceId/meetings/:meetingId", PATHS.WORKSPACES_ERROR()];

export function useRestrictApiKeyRouteAccess() {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const isAllowedAccess =
		!Auth.isAuthenticatedViaApiKey() ||
		API_KEY_ALLOWED_PATHS.some((path) => matchPath({ path: path, end: true }, pathname));

	useEffect(() => {
		if (!isAllowedAccess) {
			navigate(PATHS.WORKSPACES_ERROR());
		}
	}, [isAllowedAccess, navigate]);

	return { isAllowedAccess };
}
