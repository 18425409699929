import { asConst } from "json-schema-to-ts";
import { RecordRelationshipType } from "@salesdesk/salesdesk-model";

export const recordRelationshipTypeSchema = asConst({
	title: "SDRecord Relationship Type",
	description:
		"The type of the relationship from the owner of this relationship to the record indicated in the relationship",
	type: "string",
	enum: [...Object.values(RecordRelationshipType)],
});
