import { forwardRef } from "react";

import { TextInput, TextInputComponentProps } from "@salesdesk/daisy-ui";
import { EditFieldProps } from "../../../../types";

type EditTextFieldProps = TextInputComponentProps & Omit<EditFieldProps, "onChange" | "value">;

export const EditTextField = forwardRef<HTMLInputElement, EditTextFieldProps>(
	({ inputRef, value, ...textInputProps }, ref) => {
		return <TextInput value={value == null ? "" : value} {...textInputProps} ref={ref} />;
	}
);
