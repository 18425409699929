import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Button } from "@salesdesk/daisy-ui";

interface FileInputEmptyProps {
	disabled?: boolean;
	onBrowseFiles: () => void;
}

export function FileInputEmpty({ disabled, onBrowseFiles }: FileInputEmptyProps) {
	return (
		<div
			className={clsx(
				disabled ? "text-c_text_disabled" : "text-c_action_01",
				"flex h-full w-full select-none flex-col items-center justify-center gap-3 px-10 py-8"
			)}
		>
			<div className="flex flex-col items-center gap-2">
				<Icon icon={ICONS.upload} className={clsx(disabled && "text-c_icon_disabled", "flex")} />
				<span className="text-label-sm">Drag & drop a file here</span>
			</div>

			<div className="text-c_text_placeholder mt-1 flex w-full items-center justify-center gap-2">
				<div className="bg-c_border_regular h-px w-full max-w-[80px]" />
				or
				<div className="bg-c_border_regular h-px w-full max-w-[80px]" />
			</div>
			<Button variant="outlined" size="sm" disabled={disabled} onClick={onBrowseFiles}>
				Browse files
			</Button>
		</div>
	);
}
