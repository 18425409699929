import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { BASE_COLORS, ICONS } from "@salesdesk/salesdesk-ui";

interface TextColorButtonProps {
	tooltip?: string;
	currentTextColor?: string | null;
	currentHighlightColor?: string | null;
}

export function TextColorButton({ currentTextColor, currentHighlightColor, tooltip }: TextColorButtonProps) {
	return (
		<Tooltip placement="top" text={tooltip} preventFlip noWrap>
			<button className="hover:bg-c_bg_02 ring-c_action_focus flex items-center rounded-full px-0.5 py-px transition-all focus-visible:ring">
				<div
					className="text-label-sm rounded-card flex size-[18px] shrink-0 items-center justify-center transition-all"
					style={{
						color: currentTextColor ?? BASE_COLORS.c_text_primary,
						backgroundColor: currentHighlightColor ?? "transparent",
					}}
				>
					A
				</div>
				<Icon icon={ICONS.caretDown} size="sm" className="text-c_icon_regular flex" />
			</button>
		</Tooltip>
	);
}
