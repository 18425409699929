import { Badge } from "../../../../../components/Badge";
import { Tooltip } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { RecordBadgeType } from "../types";
import { useGetObjectById } from "../../../../../hooks";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";

interface RecordBadgesProps {
	recordBadges: RecordBadgeType[];
	className?: string;
	gap?: "sm" | "base";
}

export function RecordBadges({ recordBadges, className, gap = "sm" }: RecordBadgesProps) {
	const isSharedInWorkspace = recordBadges.includes("SHARED_IN_WORKSPACE");
	const { sdObject: workspaceObject } = useGetObjectById(isSharedInWorkspace ? mWorkspaceDef.ID : undefined);

	const badges = [];

	if (recordBadges.includes("MEETING_IN_PROGRESS")) {
		badges.push(
			<Tooltip key="meeting" text="This meeting is in progress">
				<Badge icon={ICONS.clock} text="In progress" color="c_success_04" textColor="c_success_03" size="sm" />
			</Tooltip>
		);
	}

	if (isSharedInWorkspace && workspaceObject) {
		badges.push(
			<Tooltip key="workspace" text="This record is shared with customers">
				<Badge icon={workspaceObject._icon} text="Shared" color="c_bg_02" textColor="c_action_01" size="sm" />
			</Tooltip>
		);
	}

	if (badges.length === 0) {
		return null;
	}

	return <div className={clsx("flex", gap === "sm" ? "gap-1" : "gap-2", className)}>{badges}</div>;
}
