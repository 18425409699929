import {
	forwardRef,
	useCallback,
	useEffect,
	useState,
	KeyboardEvent,
	PropsWithChildren,
	FocusEvent,
	MouseEvent,
} from "react";
import clsx from "clsx";

import { EditorContent } from "@tiptap/react";
import { InputValidationState, applyAndCancelKeyCapturing, InputContainer, Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { RichTextInputProps } from "../../../types";
import { Toolbar } from "./ToolBar";
import { getMentionExtensionForReact } from "../../../../mentions";
import { useSDEditor } from "../hooks/useSDEditor";
import { useGetContextWorkspaceId } from "../../../../workspaces";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { EmojiPopover, EmojiObject } from "../../../../../components/EmojiPicker";
import { tw } from "../../../../../utils/tailwind-helpers";
import { getFrontEndRichTextExtensions } from "../../../../../utils/getFrontEndRichTextExtensions";

export const RichTextInput = forwardRef<HTMLDivElement, PropsWithChildren<RichTextInputProps>>(
	(
		{
			id,
			value,
			onChange,
			onFocus,
			onBlur,
			onKeyDown = () => undefined,
			disabled = false,
			placeholder,
			hasError,
			ariaAttributes,
			hideToolbar,
			heightClassName,
			disableEnter = false,
			disableModEnter = false,
			enableShiftEnterAsEnter = false,
			onApply,
			onCancel,
			enableMentions = false,
			bottomPanelOptions,
			isCollapsible = false,
			children,
		},
		ref
	) => {
		const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

		const workspaceId = useGetContextWorkspaceId();

		// TODO: Update attributes correctly - by comparing keys and values in prev and next 'ariaAttributes' value
		const [formattedAriaAttributes] = useState(() =>
			Object.entries(ariaAttributes || {}).reduce(
				(formattedAttributes, [key, value]) => {
					formattedAttributes[key] = String(value);

					return formattedAttributes;
				},
				{} as Record<string, string>
			)
		);

		const editor = useSDEditor(
			{
				extensions: [
					...getFrontEndRichTextExtensions({
						placeholder,
						disableEnter,
						disableModEnter,
						enableShiftEnterAsEnter,
						enableMentions: false,
					}),
					...(enableMentions
						? [
								getMentionExtensionForReact(
									typeof enableMentions === "object" ? { workspaceId, ...enableMentions } : { workspaceId }
								),
							]
						: []),
				],
				content: value,
				onUpdate: ({ editor }) => {
					// isEmpty will return false for " " so it's better to check length of getText()
					onChange(editor.getJSON(), editor.getText());
				},
				editorProps: {
					attributes: {
						...formattedAriaAttributes,
						class: tw`w-full`,
					},
				},
			},
			[placeholder, formattedAriaAttributes]
		);

		useEffect(() => {
			if (editor && JSON.stringify(value) !== JSON.stringify(editor.getJSON())) {
				editor?.commands.setContent(value && value.content ? value : null);
			}
		}, [editor, value]);

		useEffect(() => {
			editor?.setOptions({ editable: !disabled });
		}, [disabled, editor]);

		const handleKeyDown = useCallback(
			(event: KeyboardEvent<HTMLDivElement>) => {
				if (onKeyDown) {
					onKeyDown(event);
				}

				applyAndCancelKeyCapturing<HTMLDivElement>(event, onApply, onCancel);
			},
			[onKeyDown, onApply, onCancel]
		);

		const currentValidationState = hasError ? InputValidationState.error : InputValidationState.initial;

		const onWrapperFocus = (e: FocusEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
			e.preventDefault();
			editor?.commands.focus();
		};

		const updateIsCollapsed = useCallback(
			(collapsedState: boolean) => {
				if (!isCollapsible || (!editor?.isEmpty && !children)) return;

				if (!collapsedState) {
					editor.commands.focus();
				}

				setIsCollapsed(collapsedState);
			},
			[isCollapsible, editor, children]
		);

		useEffect(() => {
			if (children) {
				updateIsCollapsed(false);
			}
		}, [children, updateIsCollapsed]);

		const onMentionsClick = useCallback(() => {
			updateIsCollapsed(false);

			let mentionChar = "@";
			const { nodeBefore } = editor.state.doc.resolve(editor.state.selection.from);
			const leftChar = nodeBefore && nodeBefore.isText ? nodeBefore.text?.slice(-1).trim() : null;
			if (leftChar?.length) {
				mentionChar = " @";
			}

			editor.view.dom.focus();
			editor.commands.insertContent(mentionChar, { updateSelection: true });
		}, [editor, updateIsCollapsed]);

		const handlePickEmoji = useCallback(
			(emoji: EmojiObject) => {
				updateIsCollapsed(false);
				editor.commands.insertContent(emoji.native);
				editor.view.dom.focus();
			},
			[editor, updateIsCollapsed]
		);

		const { showMentions, showEmojis, rightComponent, leftComponent } = bottomPanelOptions || {};
		const isBottomPanelShown = showMentions || showEmojis || rightComponent || leftComponent;

		const outsideClickRef = useOutsideClick<HTMLDivElement>(() => updateIsCollapsed(true));

		const handleInteraction = () => {
			if (!disabled) {
				updateIsCollapsed(false);
			}
		};

		return (
			<InputContainer
				ref={outsideClickRef}
				className={clsx("flex h-full flex-col", disabled && "cursor-not-allowed")}
				disabled={disabled}
				validationState={currentValidationState}
				onClick={handleInteraction}
				onFocus={handleInteraction}
			>
				<div
					className={clsx(
						"group relative w-full rounded-sm",
						"flex h-full flex-grow flex-col overflow-hidden",
						disabled && "cursor-not-allowed",
						!isCollapsed && heightClassName
					)}
					onClick={(event) => {
						/**
						 * This preventDefault fix issue with opening select
						 * everytime rich text get click event
						 */
						event.preventDefault();
					}}
				>
					{/* Empty div stops rich text input from jumping in height when it first loads in */}
					{hideToolbar ? null : editor ? <Toolbar disabled={disabled} editor={editor} /> : <div className="h-[45px]" />}
					{/* Wrapper that allows outside components to focus on the editor through the ref */}
					<div
						ref={ref}
						onClick={onWrapperFocus}
						onFocus={onWrapperFocus}
						className={clsx(
							"w-full",
							isCollapsed
								? "h-0 overflow-hidden"
								: clsx("flex-grow overflow-y-auto", !hideToolbar && editor ? "px-4 pb-2 pt-3" : "p-2")
						)}
					>
						<EditorContent
							id={id}
							onFocus={(e) => {
								onFocus?.(e);
							}}
							onBlur={onBlur}
							className={clsx("flex", !disabled && "cursor-text")}
							editor={editor}
							onKeyDown={handleKeyDown}
						/>
					</div>
					{children}
				</div>
				{isBottomPanelShown ? (
					<div
						className={clsx(
							"flex w-full flex-shrink-0 items-center justify-between",
							isCollapsed && "pt-2",
							"px-2 pb-2"
						)}
					>
						<div className="flex items-center gap-1">
							{leftComponent ? <div>{leftComponent}</div> : null}
							<div className="flex items-center gap-1">
								{showMentions && enableMentions ? (
									<Button size="sm" variant="text" startIcon={ICONS.at} onClick={onMentionsClick} disabled={disabled} />
								) : null}
								{showEmojis ? (
									<EmojiPopover onEmojiSelect={handlePickEmoji}>
										<Button
											size="sm"
											variant="text"
											startIcon={ICONS.smiley}
											onClick={(event) => {
												event.stopPropagation();
											}}
											disabled={disabled}
										/>
									</EmojiPopover>
								) : null}
								{isCollapsed ? (
									<span className="text-c_text_placeholder text-body-sm select-none px-1">{placeholder}</span>
								) : null}
							</div>
						</div>
						{rightComponent ? <div>{rightComponent}</div> : null}
					</div>
				) : null}
			</InputContainer>
		);
	}
);
