import { TimeRangeTextInput } from "./TimeRangeTextInput";
import { TimeRangePickerInputProps } from "../../types";
import { forwardRef, useState } from "react";
import { TimeRangePickerController } from "./TimeRangePickerController";
import { PickerInput } from "../DateTimePicker/PickerInput";

export const TimeRangePickerInput = forwardRef<
	HTMLInputElement,
	Omit<TimeRangePickerInputProps, "isPickerOpen" | "setIsPickerOpen">
>(({ ...timeRangePickerInputProps }, ref) => {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	return (
		<PickerInput
			mRef={ref}
			isPickerOpen={isPickerOpen}
			setIsPickerOpen={setIsPickerOpen}
			textInput={(props) => <TimeRangeTextInput {...props} />}
			picker={(props) => (
				<TimeRangePickerController
					{...props}
					onComplete={() => {
						setIsPickerOpen(false);
					}}
				/>
			)}
			{...timeRangePickerInputProps}
		/>
	);
});
