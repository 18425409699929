import { asConst } from "json-schema-to-ts";
import { getUserPathSchema } from "./getUserPathSchema";

export const putUserPathSchema = asConst({
	title: "Put SDObject Path Parameters",
	type: "object",
	additionalProperties: false,
	required: [...getUserPathSchema.required],
	properties: {
		...getUserPathSchema.properties,
	},
});
