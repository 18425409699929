import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import { BulkEditContext } from "../hooks/useBulkEditContext";

import { SDRecord } from "@salesdesk/salesdesk-schemas";

export function BulkEditProvider({ children }: PropsWithChildren) {
	const [inBulkEditMode, setInBulkEditMode] = useState(false);
	const [selectedRecords, setSelectedRecords] = useState<SDRecord[]>([]);

	const [allSelected, setAllSelected] = useState(false);
	const [onSelectAll, setOnSelectAll] = useState<(() => SDRecord[]) | null>(null);

	const toggleRecordSelection = useCallback((record: SDRecord) => {
		setSelectedRecords((prevSelectedRecords) => {
			let hadRecord = false;

			const filteredRecords = prevSelectedRecords.reduce((newRecords, currentRecord) => {
				if (currentRecord._id === record._id) {
					hadRecord = true;
				} else {
					newRecords.push(currentRecord);
				}

				return newRecords;
			}, [] as SDRecord[]);

			if (!hadRecord) {
				return [...prevSelectedRecords, record];
			}

			return filteredRecords;
		});
	}, []);

	useEffect(() => {
		if (!inBulkEditMode) {
			setSelectedRecords([]);
		}
	}, [inBulkEditMode]);

	useEffect(() => {
		if (selectedRecords.length) {
			setInBulkEditMode(true);
		}
	}, [selectedRecords.length]);

	const toggleBulkEditMode = useCallback(() => {
		setInBulkEditMode((currentMode) => !currentMode);
	}, []);

	const handleSelectAll = useCallback(() => {
		if (onSelectAll) {
			setSelectedRecords(onSelectAll());
		}
	}, [onSelectAll]);

	const contextValue = useMemo(
		() => ({
			inBulkEditMode,
			setInBulkEditMode,
			toggleBulkEditMode,
			toggleRecordSelection,
			selectedRecords,
			setSelectedRecords,
			handleSelectAll,
			setOnSelectAll,
			allSelected,
			setAllSelected,
		}),
		[inBulkEditMode, toggleBulkEditMode, toggleRecordSelection, selectedRecords, handleSelectAll, allSelected]
	);

	return <BulkEditContext.Provider value={contextValue}>{children}</BulkEditContext.Provider>;
}
