import { useCallback, useEffect, useMemo, useState } from "react";

import {
	Field,
	FieldValue,
	getSDObjectContainerFieldId,
	getSDObjectFields,
	getSDObjectFieldsByNameMap,
	getSDObjectNameFieldIds,
	getSDRecordFieldMap,
	getSDRecordName,
	SDObject,
	SDRecord,
	getSDObjectSystemFieldByName,
	getSDRecordFieldValue,
} from "@salesdesk/salesdesk-schemas";
import { mAccountDef, mUserDef, mOpportunityDef, mContactDef, mLeadDef } from "@salesdesk/salesdesk-model";

import {
	generateCreateRecordRequest,
	getFormValuesByPrefix,
	getRecordFormFieldId,
	RECORD_FIELDS_KEY,
	useRecordForm,
} from "../../records";
import { ACCOUNT_FIELD_KEY, EXISTING_ACCOUNT_ID, LINK_CONTACT_IDS_FIELD_KEY } from "../types";
import { useConvertLeadToOpportunityMutation } from "../api/leadsApi";
import { useGetObjectByIds } from "../../../hooks/useGetObjectByIds";
import { PATHS, useStableNavigate } from "../../../routes";
import { useToast } from "../../Toasts";

const NAME_OPPORTUNITY_POSTFIX = "New opportunity";
const EMPTY_CONTACT_FIELDS = [mUserDef.DETAILS_FIELD_NAME, mUserDef.WEBSITE_FIELD_NAME];
const EXCLUDE_OPPORTUNITY_FIELDS = [mOpportunityDef.SOURCE_FIELD_NAME];

type ConvertToOpportunityData = Record<string, any>;

interface UseLeadToOpportunityFormArgs {
	leadSDRecord: SDRecord;
	leadSDObject: SDObject;
	onConvertLeadSuccess: () => void;
	navigateOnSuccess?: boolean;
}

const LEAD_TO_OPPORTUNITY_OBJECTS = [mOpportunityDef.ID, mAccountDef.ID, mContactDef.ID];

export function useLeadToOpportunityForm({
	leadSDObject,
	leadSDRecord,
	onConvertLeadSuccess,
	navigateOnSuccess,
}: UseLeadToOpportunityFormArgs) {
	const navigate = useStableNavigate();

	const [opportunitySDObject, setOpportunitySDObject] = useState<SDObject>();
	const [accountSDObject, setAccountSDObject] = useState<SDObject>();
	const [contactSDObject, setContactSDObject] = useState<SDObject>();

	const sdObjects = useGetObjectByIds(LEAD_TO_OPPORTUNITY_OBJECTS);

	const { formId, fields, onFormStateChange, formStateRef, uploadProgressStatus, updateUploadProgressStatus } =
		useRecordForm({ sdObject: opportunitySDObject });
	const [isSubmitting, setIsSubmitting] = useState(false);
	const leadToOpportunityFields = useMemo(
		() => fields.filter((field) => !EXCLUDE_OPPORTUNITY_FIELDS.includes(field._name)),
		[fields]
	);

	useEffect(() => {
		sdObjects.forEach((sdObject) => {
			switch (sdObject._id) {
				case mOpportunityDef.ID:
					setOpportunitySDObject(sdObject);
					break;
				case mAccountDef.ID:
					setAccountSDObject(sdObject);
					break;
				case mContactDef.ID:
					setContactSDObject(sdObject);
					break;
			}
		});
	}, [sdObjects]);

	const [convertLeadToOpportunity, { isLoading }] = useConvertLeadToOpportunityMutation();
	const toast = useToast();

	const getOpportunityRequestData = useCallback(
		(data: ConvertToOpportunityData, opportunitySDObject: SDObject) => {
			const opportunityData = getFormValuesByPrefix(data, RECORD_FIELDS_KEY);
			const [sourceFieldId, sourceValue] = getOpportunitySourceValue(leadSDRecord, leadSDObject, opportunitySDObject);

			if (sourceFieldId !== null) {
				opportunityData[sourceFieldId] = sourceValue;
			}

			return generateCreateRecordRequest(opportunityData, opportunitySDObject);
		},
		[leadSDObject, leadSDRecord]
	);

	const getAccountRequestData = useCallback((data: ConvertToOpportunityData, accountSDObject: SDObject) => {
		const existingAccountId = data[EXISTING_ACCOUNT_ID];

		if (existingAccountId != null) return { [EXISTING_ACCOUNT_ID]: existingAccountId };

		const accountData = getFormValuesByPrefix(data, ACCOUNT_FIELD_KEY);
		const accountNameFieldIds = getSDObjectNameFieldIds(accountSDObject);
		const newAccountName = accountNameFieldIds.reduce((accountName, nameFieldId) => {
			accountName += (accountName ? " " : "") + accountData[nameFieldId];

			return accountName;
		}, "");

		const fieldValues: FieldValue[] = getFieldValues(accountSDObject, accountData);

		return {
			[ACCOUNT_FIELD_KEY]: {
				_objectDefId: accountSDObject._id,
				_name: newAccountName,
				_dataInst: {
					_fieldId: getSDObjectContainerFieldId(accountSDObject) || 0,
					_value: null,
					_children: fieldValues,
				},
			},
		};
	}, []);

	const getLeadLinkedContacts = useCallback((data: ConvertToOpportunityData) => {
		const linkingContactData = data[LINK_CONTACT_IDS_FIELD_KEY];

		return linkingContactData ? { [LINK_CONTACT_IDS_FIELD_KEY]: linkingContactData } : {};
	}, []);

	// TODO: This makes the dangerous assumption that fields with the same name on the lead and contact
	// records are the exact same fields with the same validation rules/value type.
	const getLeadContactData = useCallback(
		(contactSDObject: SDObject) => {
			const contactFields = getSDObjectFields(contactSDObject, { includeHidden: true, includeReadOnly: true });
			const contactObjectFieldMapByName = getSDObjectFieldsByNameMap(contactSDObject);
			const leadFieldMapByFieldId = getSDRecordFieldMap(leadSDRecord);

			const fieldValues: FieldValue[] = [];
			contactFields.forEach((contactField) => {
				const defaultValue = contactField._defaultValue ?? null;
				const leadFieldValue = leadFieldMapByFieldId[contactObjectFieldMapByName[contactField._name]._id];

				// Assign the value from the lead field if present. Otherwise, use the default value for the contact field
				let value = leadFieldValue ? (leadFieldValue._value ?? null) : defaultValue;

				// If no value is set on the lead and the contact field is required, use the default value for the contact field
				if (value === null && contactField._required) {
					value = defaultValue;
				}

				if (EMPTY_CONTACT_FIELDS.includes(contactField._name)) {
					value = null;
				}

				fieldValues.push({
					_fieldId: contactField._id,
					_value: value,
				});
			});

			return {
				_objectDefId: contactSDObject._id,
				_name: getSDRecordName(leadSDObject, leadSDRecord),
				_dataInst: {
					_fieldId: getSDObjectContainerFieldId(contactSDObject) || 0,
					_value: null,
					_children: fieldValues,
				},
			};
		},
		[leadSDObject, leadSDRecord]
	);

	const onSubmit = useCallback(
		(data: ConvertToOpportunityData) => {
			if (!(opportunitySDObject && accountSDObject && contactSDObject)) return;

			setIsSubmitting(true);

			convertLeadToOpportunity({
				leadRecordId: leadSDRecord?._id,
				convertLeadBody: {
					opportunity: getOpportunityRequestData(data, opportunitySDObject),
					...getAccountRequestData(data, accountSDObject),
					...getLeadLinkedContacts(data),
					contact: getLeadContactData(contactSDObject),
				},
			})
				.unwrap()
				.then(({ opportunityId }) => {
					toast.triggerMessage({
						type: "success",
						messageKey: "lead_converted_to_opportunity",
						messageParams: {
							link: {
								type: "link",
								to: PATHS.RECORD_DETAIL_VIEW(opportunityId, opportunitySDObject),
							},
						},
					});

					if (navigateOnSuccess) {
						navigate(PATHS.RECORD_DETAIL_VIEW(opportunityId, opportunitySDObject));
					}

					if (onConvertLeadSuccess) {
						onConvertLeadSuccess();
					}

					setIsSubmitting(false);
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "lead_converted_to_opportunity" });
					setIsSubmitting(false);
				});
		},
		[
			opportunitySDObject,
			accountSDObject,
			contactSDObject,
			convertLeadToOpportunity,
			leadSDRecord?._id,
			getOpportunityRequestData,
			getAccountRequestData,
			getLeadLinkedContacts,
			getLeadContactData,
			toast,
			navigateOnSuccess,
			onConvertLeadSuccess,
			navigate,
		]
	);

	return {
		formProps: {
			id: formId,
			fields: leadToOpportunityFields,
			onSubmit,
			onFormStateChange,
			updateUploadProgressStatus,
			defaultValues: generateDefaultValues(fields, opportunitySDObject, leadSDObject, leadSDRecord),
		},
		uploadProgressStatus,
		formStateRef,
		isSubmitting: isLoading || isSubmitting,
		onSubmit,
	};
}

function getFieldValues(sdObject: SDObject, data: Record<string, any>) {
	const sdObjectFields = getSDObjectFields(sdObject);

	return sdObjectFields.map((field) => {
		const value = data[field._id];

		return {
			_fieldId: field._id,
			_value: value === undefined || value === "" ? null : (value as unknown),
		};
	});
}

function getOpportunityName(leadSDObject: SDObject, leadSDRecord: SDRecord) {
	return `${getSDRecordName(leadSDObject, leadSDRecord)} - ${NAME_OPPORTUNITY_POSTFIX}`;
}

function generateDefaultValues(fields: Field[], sdObject?: SDObject, leadSDObject?: SDObject, leadSDRecord?: SDRecord) {
	let nameFieldIds: number[] = [];
	let opportunityName = "";
	if (sdObject && leadSDObject && leadSDRecord) {
		nameFieldIds = getSDObjectNameFieldIds(sdObject);
		opportunityName = getOpportunityName(leadSDObject, leadSDRecord);
	}

	const values = {} as Record<string, any>;
	fields.forEach((field) => {
		const fieldDefId = String(field._id);
		values[getRecordFormFieldId(fieldDefId)] = field._defaultValue ?? undefined;

		if (nameFieldIds.includes(field._id)) {
			values[getRecordFormFieldId(fieldDefId)] = opportunityName;
		}
	});

	return { [RECORD_FIELDS_KEY]: values };
}

function getOpportunitySourceValue(
	leadSDRecord: SDRecord,
	leadSDObject: SDObject,
	opportunitySDObject: SDObject
): [number | null, number | null] {
	const opportunitySourceField = getSDObjectSystemFieldByName(opportunitySDObject, mOpportunityDef.SOURCE_FIELD_NAME);

	if (!opportunitySourceField) {
		return [null, null];
	}

	const leadSourceField = getSDObjectSystemFieldByName(leadSDObject, mLeadDef.SOURCE_FIELD_NAME);

	if (!leadSourceField) {
		return [opportunitySourceField._id, null];
	}

	const leadSourceValue = getSDRecordFieldValue(leadSDRecord, leadSourceField._id);
	const leadSourceValueOption = leadSourceField._optionValues?.find(
		(sourceOption) => sourceOption.id === leadSourceValue?._value
	);

	const opportunitySourceValue = leadSourceValueOption
		? (opportunitySourceField._optionValues?.find((option) => option.name === leadSourceValueOption.name)?.id ?? null)
		: null;

	return [opportunitySourceField._id, opportunitySourceValue];
}
