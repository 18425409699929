import { asConst } from "json-schema-to-ts";
import { userIdSchema } from "../../components";
import { channelMessageParamsSchema } from "./channelMessageParamsSchema";

export const channelMessageMentionParamsSchema = asConst({
	title: "Channel Message Params",
	description: "When user sends a message",
	type: "object",
	additionalProperties: false,
	required: [...channelMessageParamsSchema.required, "mentionedUserRecordId"],
	properties: {
		...channelMessageParamsSchema.properties,
		mentionedUserRecordId: userIdSchema,
	},
});
