import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";
import { isEmpty } from "@salesdesk/salesdesk-utils";

import { FIELD_TYPES } from "../utils";
import { mFieldDef } from "../field_def";

export class mUrlFieldDef extends mFieldDef {
	static supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.TEXT.name];
	static defaultComponentType: FieldComponentName = mUrlFieldDef.supportedComponentTypes[0];

	static FORMAT_RULE = `http://www.example.com`;
	static FORMAT_RULE_LONG = `Use the following format: ${mUrlFieldDef.FORMAT_RULE}.`;

	constructor(id: string | number) {
		super(id);
		this._componentType = mUrlFieldDef.defaultComponentType;
		this._toolTip = mUrlFieldDef.FORMAT_RULE_LONG;
		this._formatDescription = mUrlFieldDef.FORMAT_RULE;
		this._icon = FIELD_TYPES.URL.icon;
		this._supportsDefaultValue = true;
		this._supportsUniqueValue = true;
	}

	override get type() {
		return FIELD_TYPES.URL.name;
	}

	override get supportedComponentTypes() {
		return mUrlFieldDef.supportedComponentTypes;
	}

	override validate(value: any, isTemplate: boolean | undefined) {
		if (!isEmpty(value) && !this.urlIsValid(value)) {
			return mUrlFieldDef.FORMAT_RULE_LONG;
		}

		return super.validate(value, isTemplate);
	}

	urlIsValid(email: string) {
		const pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // fragment locator
		return !!pattern.test(email);
	}
}
