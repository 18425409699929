import { useMemo, useState } from "react";
import { SDRecord, getSDRecordName } from "@salesdesk/salesdesk-schemas";
import { useDeleteRecordMutation } from "../../../api/recordsApi";
import { DeleteConfirmationDialog } from "../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { useToast } from "../../../../Toasts";
import { useGetObjectMap } from "../../../../objects/hooks";
import { useGetContextWorkspaceId } from "../../../../workspaces";

interface RecordDeleteDialogProps {
	sdRecord?: SDRecord;
	onClose: () => void;
}

export function RecordDeleteDialog({ onClose, sdRecord }: RecordDeleteDialogProps) {
	const workspaceId = useGetContextWorkspaceId();
	const objectMap = useGetObjectMap();

	const recordName = useMemo(() => {
		if (!sdRecord) {
			return "";
		}

		const sdObject = objectMap.get(sdRecord._objectDefId);
		return sdObject ? getSDRecordName(sdObject, sdRecord) : sdRecord._id;
	}, [objectMap, sdRecord]);

	const [isDeletingRecord, setIsDeletingRecord] = useState(false);

	const [deleteRecord] = useDeleteRecordMutation();
	const toast = useToast();

	const onDelete = () => {
		if (!sdRecord) {
			return;
		}

		setIsDeletingRecord(true);

		deleteRecord({ sdRecord, workspaceId })
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "record_deleted" });
				onClose();
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "record_deleted" });
			})
			.finally(() => {
				setIsDeletingRecord(false);
			});
	};

	return (
		<DeleteConfirmationDialog
			open={Boolean(sdRecord)}
			title="Delete Record"
			onDismiss={() => onClose()}
			onConfirm={onDelete}
			isDeleting={isDeletingRecord}
		>
			<p>
				Are you sure you want to delete "
				<b>
					<i>{recordName}</i>
				</b>
				"? <br /> It will be moved to "Deleted items"
			</p>
		</DeleteConfirmationDialog>
	);
}
