import { createContext, useContext } from "react";

import { SDRecord, SDObject } from "@salesdesk/salesdesk-schemas";

interface RecordDetailsContextProps {
	sdRecord?: SDRecord;
	sdObject?: SDObject;
	isLoading?: boolean;
	canEditRecord?: boolean;
	ownerObject?: SDObject;
	ownerRecord?: SDRecord;
}

export const RecordDetailsContext = createContext<RecordDetailsContextProps>({});

export function useRecordDetailsContext() {
	const context = useContext(RecordDetailsContext);

	if (context == null) {
		throw new Error("RecordDetailsContext components must be wrapped with RecordDetailsContext provider");
	}

	return context;
}
