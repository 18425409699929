import { PropsWithChildren, useCallback, useMemo } from "react";
import { NumberParam, StringParam, withDefault } from "use-query-params";

import { useURLState } from "../../../../../../hooks";
import { RecordSidePanel } from "../../../../../records";
import { useWorkspaceNavCollapse } from "../../../../hooks/useWorkspaceNavCollapse";
import { WorkspaceLibraryUrlStateContext } from "../hooks/useWorkspaceLibraryStateContext";
import { WorkspaceLibraryState } from "../types";

const DEFAULT_WORKSPACE_LIBRARY_STATE: WorkspaceLibraryState = {
	recordId: undefined,
	tabId: undefined,
};

const WORKSPACE_LIBRARY_STATE_VALUE_VALIDITY: Record<keyof WorkspaceLibraryState, (value: any) => boolean> = {
	recordId: (value: unknown) => typeof value === "number",
	tabId: (value: unknown) => typeof value === "string",
};

export function WorkspaceLibraryStateProvider({ children }: PropsWithChildren) {
	const { urlState, propOnChange, resetState } = useURLState({
		defaultState: DEFAULT_WORKSPACE_LIBRARY_STATE,
		valueValidityMap: WORKSPACE_LIBRARY_STATE_VALUE_VALIDITY,
		paramConfigMap: {
			recordId: withDefault(NumberParam, DEFAULT_WORKSPACE_LIBRARY_STATE.recordId),
			tabId: withDefault(StringParam, DEFAULT_WORKSPACE_LIBRARY_STATE.tabId),
		},
	});

	const closeRecordPanel = useCallback(() => {
		propOnChange("recordId", undefined);
	}, [propOnChange]);

	const showingSidePanel = urlState.recordId != null;
	useWorkspaceNavCollapse(showingSidePanel);

	const state = useMemo(
		() => ({
			urlState,
			propOnChange,
			resetState,
		}),
		[urlState, propOnChange, resetState]
	);

	return (
		<WorkspaceLibraryUrlStateContext.Provider value={state}>
			{children}
			{urlState ? <RecordSidePanel recordId={urlState.recordId ?? undefined} onClose={closeRecordPanel} /> : null}
		</WorkspaceLibraryUrlStateContext.Provider>
	);
}
