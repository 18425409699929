import { useCallback } from "react";

import { Role } from "@salesdesk/salesdesk-schemas";
import { SelectOption, SelectOptionId } from "../../../../../inputs";
import { useLazyGetRolesQuery } from "../../../../../settings/access/roles/api/rolesApi";
import { stringContainsMatch } from "@salesdesk/salesdesk-utils";

export function useGetRoleOptionsFunctions() {
	const [getRoles] = useLazyGetRolesQuery();

	const getOptions = useCallback(
		async (query: string) => {
			const roles = await getRoles(undefined, true).unwrap();
			const filteredRoles = query ? roles.filter((role) => stringContainsMatch(role.name, query)) : roles;
			return mapRolesToSelectOptions(filteredRoles);
		},
		[getRoles]
	);

	const getOptionsFromIds = useCallback(
		async (ids: SelectOptionId[]) => {
			const roles = await getRoles(undefined, true).unwrap();
			const selectedRoles = roles.filter((role) => ids.findIndex((id) => id === role.id) > -1);
			return mapRolesToSelectOptions(selectedRoles);
		},
		[getRoles]
	);

	return { getOptions, getOptionsFromIds };
}

function mapRolesToSelectOptions(roles: Role[]): SelectOption[] {
	return roles.map((role) => ({
		id: role.id,
		name: role.name,
	}));
}
