import { Editor } from "@tiptap/react";
import { EditorOptions } from "@tiptap/core";
import { DependencyList, useEffect, useState } from "react";

function useForceUpdate() {
	const [, setValue] = useState(0);
	return () => setValue((value) => value + 1);
}

const EMPTY_DEPENDENCY_LIST: DependencyList = [];

// Custom `useEditor` hook to ensure that the contents of the editor are rendered on
// first mount, otherwise a flickering effect occurs
// Sources: https://github.com/ueberdosis/tiptap/issues/2040, https://gist.github.com/ryanto/4a431d822a98770c4ca7905d9b7b07da,
export const useSDEditor = (options: Partial<EditorOptions> = {}, deps: DependencyList = EMPTY_DEPENDENCY_LIST) => {
	const [editor, setEditor] = useState<Editor>(() => new Editor(options));
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		let instance: Editor;

		if (editor.isDestroyed) {
			instance = new Editor(options);
			setEditor(instance);
		} else {
			instance = editor;
		}

		instance.on("transaction", () => {
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					forceUpdate();
				});
			});
		});

		return () => {
			instance.destroy();
		};
	}, deps);

	return editor;
};
