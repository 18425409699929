import { getRandomBetweenMinMax } from "@salesdesk/salesdesk-utils";

const CONFETTI_COLORS = [
	"#f44336",
	"#e91e63",
	"#9c27b0",
	"#673ab7",
	"#3f51b5",
	"#2196f3",
	"#03a9f4",
	"#00bcd4",
	"#009688",
	"#4caf50",
	"#8bc34a",
	"#cddc39",
	"#ffeb3b",
	"#ffc107",
	"#ff9800",
	"#ff5722",
];

const CONFETTI_SHAPES = [
	"circle",
	"square",
	"triangle",
	"heart",
	"quadrilateralStar",
	"star",
	"hexagonalStar",
	"pentagon",
	"hexagon",
	"rectangle",
];

export class ConfettiPiece {
	color: string;
	shape: string;
	size: number;
	x: number;
	y: number;
	velocityX: number;
	velocityY: number;
	rotation: number;
	rotationSpeed: number;
	isOutside: boolean;

	constructor(canvasWidth: number) {
		this.color = CONFETTI_COLORS[Math.floor(Math.random() * CONFETTI_COLORS.length)];
		this.shape = CONFETTI_SHAPES[Math.floor(Math.random() * CONFETTI_SHAPES.length)];

		this.size = Math.random() * 20 + 10;
		this.x = Math.random() * canvasWidth;
		this.y = -this.size - Math.random() * 800 + 8;
		this.velocityX = Math.random() * 4 - 2;
		this.velocityY = Math.random() * (getRandomBetweenMinMax(0, 10) * 2) + 8;
		this.rotation = Math.random() * 360;
		this.rotationSpeed = Math.random() * 4 - 2;

		this.isOutside = false;
	}

	update(canvasWidth: number, canvasHeight: number): void {
		this.x += this.velocityX;
		this.y += this.velocityY;
		this.rotation += this.rotationSpeed;

		if (this.x > canvasWidth + this.size) {
			this.x = -this.size;
		} else if (this.x < -this.size) {
			this.x = canvasWidth + this.size;
		}

		if (this.y > canvasHeight + this.size) {
			this.isOutside = true;
			this.size = 0;
			this.y = 0;
			this.x = 0;
		}
	}

	draw(ctx: CanvasRenderingContext2D): void {
		ctx.save();
		ctx.fillStyle = this.color;
		ctx.translate(this.x + this.size / 2, this.y + this.size / 2);
		ctx.rotate((this.rotation * Math.PI) / 180);
		switch (this.shape) {
			case "circle":
				ctx.beginPath();
				ctx.arc(0, 0, this.size / 2, 0, 2 * Math.PI);
				ctx.fill();
				break;
			case "square":
				ctx.fillRect(-this.size / 2, -this.size / 2, this.size, this.size);
				break;
			case "triangle":
				ctx.beginPath();
				ctx.moveTo(0, -this.size / 2);
				ctx.lineTo(this.size / 2, this.size / 2);
				ctx.lineTo(-this.size / 2, this.size / 2);
				ctx.fill();
				break;
			case "heart":
				ctx.beginPath();
				ctx.moveTo(0, -this.size / 4);
				ctx.quadraticCurveTo(this.size / 2, -this.size / 2, 0, this.size / 2);
				ctx.quadraticCurveTo(-this.size / 2, -this.size / 2, 0, -this.size / 4);
				ctx.fill();
				break;
			case "quadrilateralStar":
				this.drawStar(ctx, 4);
				break;
			case "star":
				this.drawStar(ctx, 5);
				break;
			case "hexagonalStar":
				this.drawStar(ctx, 6);
				break;
			case "pentagon":
				this.drawPolygon(ctx, 5);
				break;
			case "hexagon":
				this.drawPolygon(ctx, 6);
				break;
			case "rectangle":
				ctx.fillRect(-this.size / 2, -this.size / 4, this.size, this.size / 2);
				break;
		}
		ctx.restore();
	}

	drawStar(ctx: CanvasRenderingContext2D, points: number): void {
		ctx.beginPath();
		const outerRadius = this.size / 2;
		const innerRadius = outerRadius / 2.5;
		for (let i = 0; i < points * 2; i++) {
			const angle = (i * Math.PI) / points;
			const radius = i % 2 === 0 ? outerRadius : innerRadius;
			ctx.lineTo(Math.cos(angle) * radius, Math.sin(angle) * radius);
		}
		ctx.closePath();
		ctx.fill();
	}

	drawPolygon(ctx: CanvasRenderingContext2D, sides: number): void {
		ctx.beginPath();
		const radius = this.size / 2;
		for (let i = 0; i < sides; i++) {
			const angle = (i * 2 * Math.PI) / sides;
			ctx.lineTo(Math.cos(angle) * radius, Math.sin(angle) * radius);
		}
		ctx.closePath();
		ctx.fill();
	}
}
