import { useEffect, useMemo } from "react";
import { useAppStateContext } from "../../users/hooks/useAppStateContext";
import { usePatchMyAppStateMutation } from "../../users/api/userAppStateApi";

export function useFirstTimeLoggedIn() {
	const { appStateRef } = useAppStateContext();
	const [patchMyAppState] = usePatchMyAppStateMutation();

	const onboardingAppState = useMemo(() => appStateRef.current?.onboarding ?? {}, [appStateRef]);

	useEffect(() => {
		const { isFirstTimeLoggedIn = true } = onboardingAppState;
		if (!isFirstTimeLoggedIn) return;
		patchMyAppState({ onboarding: { ...onboardingAppState, isFirstTimeLoggedIn: false } });
	}, [patchMyAppState, onboardingAppState]);

	return "isFirstTimeLoggedIn" in onboardingAppState ? onboardingAppState.isFirstTimeLoggedIn : true;
}
