import { LoginChoice } from "@salesdesk/salesdesk-schemas";

import { TenantLink } from "../../tenant";
import { useMenuItemProps } from "../../menu/hooks";

export function LoginChoiceMenuItem({ tenant, accountName, accountLogoUrl, isInternalUser }: LoginChoice) {
	const menuItemProps = useMenuItemProps({
		includeTabIndex: false,
		label: accountName,
	});

	return (
		<div className="min-w-[300px] px-4 py-2 first:pt-1">
			<TenantLink
				{...menuItemProps}
				tenant={tenant}
				accountLogoUrl={accountLogoUrl}
				accountName={accountName}
				isInternalUser={isInternalUser}
				hoverStyleOnFocus
			/>
		</div>
	);
}
