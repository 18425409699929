import { ProductAssetsAndDocsRow } from "./ProductAssetsAndDocsRow";
import { SkeletonCompactFilePreview } from "../../../../../../../../files";
import { ProductAssetsAndDocsList } from "./ProductAssetsAndDocsList";

export function ProductAssetsOrDocsSkeleton() {
	return (
		<ProductAssetsAndDocsList>
			{[...Array(3)].map((_, index) => (
				<ProductAssetsAndDocsRow key={index}>
					<SkeletonCompactFilePreview />
				</ProductAssetsAndDocsRow>
			))}
		</ProductAssetsAndDocsList>
	);
}
