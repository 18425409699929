import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { LeadConvertedToOpportunityBaseParams, SdEventType } from "@salesdesk/salesdesk-model";
import { useGetObjectById } from "../../../../../hooks/useGetObjectById";

export function LeadObject() {
	const { event, target } = useEventContext();

	let leadId;
	if (
		event.event_type === SdEventType.LEAD_CONVERTED_TO_OPPORTUNITY ||
		event.event_type === SdEventType.LEAD_CONVERTED_TO_ACCOUNT
	) {
		const params = event.params as LeadConvertedToOpportunityBaseParams;
		leadId = params.leadId;
	}

	const { sdObject } = useGetObjectById(leadId);
	const objectName = sdObject?._displayName?.toLowerCase() ?? "lead";

	return target === "NOTIFICATION" && leadId ? <LinkObject recordId={leadId} /> : `this ${objectName}`;
}
