import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../../store/store";

import { useUnreadMessageHandler } from "../../hooks/useUnreadMessageHandler";
import { addNewChannelFromArn } from "../../store/thunks";
import { removeChannel, useChannelSelector } from "../../store";

import { useChimeMessagingContext } from "../providers";
import { MessagingPanelContainer } from "../MessagingPanelContainer";
import { ChatChannelViewContainer } from "../ChatChannelView";
import { useGetActiveHuddle } from "../../hooks";
import { HuddleButton } from "../HuddleButton";

interface ReadOnlySingleChannelMessagingPanelProps {
	open: boolean;
	onToggleOpen: () => void;
	channelArn: string;
}

export function ReadOnlySingleChannelMessagingPanel({
	channelArn,
	open,
	onToggleOpen,
}: ReadOnlySingleChannelMessagingPanelProps) {
	const { chime } = useChimeMessagingContext();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!chime) return;

		dispatch(addNewChannelFromArn({ channelArn, chime }));

		return () => {
			dispatch(removeChannel({ channelArn, onlyRemoveIfReadOnly: true }));
		};
	}, [channelArn, chime, dispatch]);

	const readOnlyChannel = useChannelSelector(channelArn, true);

	const readOnlyChannelArray = useMemo(() => {
		return readOnlyChannel ? [readOnlyChannel] : undefined;
	}, [readOnlyChannel]);

	useUnreadMessageHandler({
		channels: readOnlyChannelArray,
		isReadOnly: true,
		currentOpenChannelArn: open && readOnlyChannel ? channelArn : undefined,
	});

	const { activeHuddle, initialLoad } = useGetActiveHuddle(channelArn);

	return (
		<MessagingPanelContainer
			open={open}
			onToggleOpen={onToggleOpen}
			headerElements={<HuddleButton activeHuddle={activeHuddle} isLoadingHuddle={initialLoad} />}
		>
			<ChatChannelViewContainer channelDetails={readOnlyChannel} />
		</MessagingPanelContainer>
	);
}
