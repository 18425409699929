import { useCallback, useState } from "react";

import { AbilityAction, AbilitySubject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useDeleteReactionMutation, useAddReactionMutation } from "../api/notesApi";
import { Note } from "../types";
import { useToast } from "../../Toasts";
import { useGetContextWorkspaceId } from "../../workspaces";
import { Auth, useWebPrincipal } from "../../../auth";

export function useNoteReactions(sdRecord: SDRecord) {
	const toast = useToast();

	const principal = useWebPrincipal();
	const canReact = principal.can(AbilityAction.Edit, AbilitySubject.NoteReaction);

	const workspaceId = useGetContextWorkspaceId();
	const [addReaction, { isLoading: isAddingReaction }] = useAddReactionMutation();
	const [deleteReaction, { isLoading: isDeletingReaction }] = useDeleteReactionMutation();
	const [userRecord] = useState<SDRecord>(() => Auth.getUser());

	const toggleReaction = useCallback(
		(note: Note, emoji: string) => {
			if (isAddingReaction || isDeletingReaction || !canReact) {
				return;
			}
			const existingReaction = note.reactions?.find((reaction) => reaction.emoji === emoji);
			const hasMyReaction = existingReaction?.userRecordIds.includes(userRecord._id);
			if (hasMyReaction) {
				deleteReaction({
					workspaceId,
					recordId: String(sdRecord._id),
					noteId: note.id,
					emoji: emoji,
				})
					.unwrap()
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: "reaction_deleted" });
					});
			} else {
				addReaction({
					workspaceId,
					recordId: String(sdRecord._id),
					noteId: note.id,
					emoji: emoji,
				})
					.unwrap()
					.catch(() => {
						toast.triggerMessage({ type: "error", messageKey: "reaction_added" });
					});
			}
		},
		[
			isAddingReaction,
			isDeletingReaction,
			canReact,
			userRecord._id,
			deleteReaction,
			workspaceId,
			sdRecord._id,
			toast,
			addReaction,
		]
	);

	return {
		toggleReaction,
		canReact,
	};
}
