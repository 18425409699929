import clsx from "clsx";
import { tw } from "../../utils/tailwind-helpers";

export type SpinnerSize = "xs" | "sm" | "md" | "lg";

export interface SpinnerProps {
	size?: SpinnerSize;
	darkMode?: boolean;
}

const SizeClasses = {
	xs: tw`size-4`,
	sm: tw`size-5`,
	md: tw`size-8`,
	lg: tw`size-[86px]`,
};

export function Spinner({ size = "sm", darkMode = false }: SpinnerProps) {
	return (
		<div className={clsx("animate-spin-slow", darkMode ? "text-c_bg_04" : "text-c_action_01", SizeClasses[size])}>
			<svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path
						d="M30.1643 2.38283C30.3579 1.28498 31.4076 0.544145 32.4872 0.822287C36.3778 1.8247 39.9926 3.71784 43.0409 6.36307C46.0891 9.00831 48.4725 12.3205 50.013 16.0311C50.4404 17.0607 49.8548 18.2043 48.7951 18.5506C47.7355 18.8969 46.6043 18.3138 46.1622 17.2904C44.8587 14.2727 42.8906 11.5778 40.3949 9.41209C37.8993 7.24638 34.954 5.67748 31.7828 4.81198C30.7074 4.51846 29.9708 3.48069 30.1643 2.38283Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M30.986 2.54256C31.2178 1.45213 32.2928 0.748387 33.362 1.06402C38.3928 2.54921 42.8737 5.52745 46.1968 9.62656C49.5199 13.7257 51.507 18.7256 51.9195 23.9549C52.0071 25.0662 51.0963 25.9724 49.9815 25.9736C48.8667 25.9748 47.9716 25.0702 47.8681 23.9602C47.4667 19.6563 45.8018 15.5498 43.0609 12.1689C40.3199 8.78788 36.6466 6.30961 32.5188 5.02669C31.4543 4.69583 30.7543 3.63298 30.986 2.54256Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M31.8017 2.73086C32.0713 1.64918 33.1701 0.983361 34.2276 1.33613C36.9593 2.24739 39.5252 3.6077 41.818 5.36532C44.6458 7.53302 46.9978 10.2588 48.7282 13.3734C50.4585 16.4881 51.5302 19.9251 51.8767 23.4713C52.1577 26.3466 51.9571 29.2438 51.2876 32.0446C51.0284 33.1288 49.8826 33.71 48.8218 33.3675C47.7609 33.025 47.1882 31.8885 47.4319 30.8007C47.9406 28.5299 48.086 26.1884 47.8589 23.8639C47.5661 20.8684 46.6608 17.965 45.1992 15.334C43.7375 12.7029 41.7507 10.4004 39.362 8.56926C37.5084 7.14832 35.4434 6.03479 33.2467 5.26697C32.1944 4.89915 31.532 3.81253 31.8017 2.73086Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M32.6102 2.94751C32.9175 1.8759 34.0388 1.24884 35.0834 1.6383C38.3471 2.85518 41.3397 4.71807 43.8786 7.12265C46.9071 9.99087 49.201 13.5456 50.5668 17.4868C51.9325 21.428 52.33 25.6399 51.7257 29.767C51.219 33.227 50.0207 36.5422 48.2098 39.5176C47.6302 40.4698 46.3613 40.6711 45.4568 40.0194C44.5524 39.3677 44.3564 38.1103 44.9223 37.1498C46.3622 34.7062 47.3185 32.0011 47.7313 29.1821C48.2418 25.6958 47.906 22.1379 46.7523 18.8086C45.5986 15.4794 43.6609 12.4766 41.1026 10.0537C39.0341 8.09464 36.609 6.56121 33.9659 5.53251C32.927 5.12819 32.3029 4.01911 32.6102 2.94751Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M33.4107 3.19224C33.7552 2.13202 34.8978 1.54448 35.9281 1.97016C39.7106 3.53292 43.0803 5.97076 45.7537 9.09462C48.8657 12.7309 50.9136 17.1557 51.6717 21.8814C52.4299 26.6071 51.8689 31.4505 50.0506 35.8778C48.4885 39.6811 46.0506 43.0507 42.9467 45.7183C42.1013 46.4449 40.8322 46.2442 40.1734 45.345C39.5145 44.4457 39.7166 43.1892 40.5516 42.4506C43.0612 40.2308 45.0366 37.4597 46.3163 34.344C47.8522 30.6042 48.3261 26.5129 47.6857 22.5209C47.0452 18.5289 45.3153 14.7912 42.6865 11.7195C40.4965 9.16046 37.7533 7.1464 34.6754 5.82299C33.6512 5.38265 33.0662 4.25247 33.4107 3.19224Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M34.2022 3.46476C34.5834 2.41721 35.7458 1.86989 36.7606 2.33125C41.0512 4.28184 44.7434 7.36839 47.4273 11.2734C50.4933 15.7346 52.0905 21.0415 51.996 26.4538C51.9016 31.8661 50.1201 37.114 46.9003 41.4654C44.0817 45.2744 40.284 48.2302 35.928 50.0299C34.8977 50.4556 33.7551 49.868 33.4106 48.8078C33.0662 47.7475 33.6512 46.6174 34.6753 46.177C38.234 44.647 41.3367 42.1972 43.6551 39.0641C46.375 35.3884 47.8798 30.9553 47.9597 26.3833C48.0395 21.8114 46.6903 17.3285 44.1003 13.56C41.8927 10.3479 38.8774 7.79139 35.3743 6.13805C34.3661 5.66224 33.8209 4.51232 34.2022 3.46476Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M34.9836 3.76474C35.4012 2.73113 36.5821 2.22475 37.5802 2.72125C42.3467 5.09227 46.2865 8.87857 48.8441 13.5845C51.7241 18.8835 52.6723 25.0189 51.5264 30.9401C50.3805 36.8612 47.2117 42.1999 42.5628 46.0418C38.4341 49.4538 33.3661 51.4968 28.0592 51.9184C26.9479 52.0066 26.0413 51.0963 26.0394 49.9815C26.0376 48.8667 26.9418 47.9711 28.0517 47.867C32.421 47.4571 36.5845 45.7451 39.9911 42.93C43.9182 39.6845 46.5949 35.1748 47.5629 30.173C48.5309 25.1712 47.7299 19.9885 45.2971 15.5122C43.1869 11.6294 39.9626 8.48768 36.0618 6.4773C35.0708 5.96661 34.566 4.79836 34.9836 3.76474Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M35.7542 4.09181C36.2076 3.0734 37.4054 2.60854 38.3856 3.13956C43.6253 5.97817 47.751 10.5438 50.0377 16.091C52.5818 22.2624 52.6547 29.176 50.2414 35.3998C47.8281 41.6236 43.1134 46.6807 37.0738 49.5238C31.6453 52.0793 25.5201 52.6701 19.7365 51.2343C18.6545 50.9657 18.0832 49.8149 18.4349 48.757C18.7866 47.6991 19.928 47.1364 21.0137 47.3895C25.8093 48.5076 30.8639 47.9852 35.3544 45.8713C40.4562 43.4696 44.4388 39.1977 46.4774 33.9403C48.516 28.6829 48.4544 22.8428 46.3054 17.6295C44.4138 13.041 41.0327 9.24746 36.737 6.84033C35.7645 6.29538 35.3007 5.11022 35.7542 4.09181Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M36.5128 4.44558C37.0015 3.44361 38.2147 3.02079 39.1757 3.58572C41.6946 5.06635 43.9501 6.96354 45.8441 9.20089C48.1737 11.9527 49.9054 15.1589 50.9294 18.6159C51.9534 22.0728 52.2471 25.705 51.7921 29.2816C51.337 32.8582 50.1431 36.3011 48.2861 39.3915C46.4291 42.4819 43.9496 45.1523 41.0052 47.2331C38.0608 49.3139 34.7158 50.7595 31.1827 51.4782C27.6497 52.1969 24.0057 52.1729 20.4824 51.4078C17.6178 50.7857 14.8838 49.6847 12.394 48.1558C11.4441 47.5724 11.2479 46.3027 11.9032 45.4008C12.5585 44.499 13.8167 44.308 14.7749 44.8777C16.8056 46.0853 19.0217 46.9595 21.3391 47.4627C24.3154 48.1091 27.3935 48.1293 30.378 47.5222C33.3625 46.9151 36.1881 45.6939 38.6753 43.9362C41.1626 42.1785 43.257 39.9228 44.8257 37.3122C46.3944 34.7016 47.403 31.7933 47.7874 28.7721C48.1718 25.7508 47.9236 22.6826 47.0586 19.7624C46.1937 16.8422 44.7308 14.1338 42.763 11.8093C41.2307 9.99934 39.4185 8.45293 37.399 7.22672C36.4461 6.64813 36.0241 5.44754 36.5128 4.44558Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M37.2586 4.8256C37.782 3.8413 39.0092 3.46108 39.95 4.05921C42.6723 5.79006 45.0539 8.01579 46.9683 10.6269C49.2812 13.7817 50.8525 17.4178 51.5651 21.2642C52.2776 25.1107 52.113 29.0683 51.0835 32.8423C50.0541 36.6163 48.1863 40.1093 45.6193 43.0611C43.0523 46.013 39.8524 48.3476 36.2578 49.8909C32.6633 51.4343 28.7668 52.1467 24.8587 51.9749C20.9506 51.8032 17.1316 50.7519 13.6863 48.8992C10.8347 47.3658 8.29996 45.3162 6.20799 42.8605C5.48507 42.0119 5.69125 40.7438 6.59338 40.0888C7.4955 39.4339 8.75107 39.6415 9.48602 40.4797C11.2128 42.4491 13.2818 44.098 15.5983 45.3437C18.5086 46.9087 21.7346 47.7968 25.0359 47.9418C28.3372 48.0869 31.6287 47.4851 34.6651 46.1814C37.7015 44.8777 40.4046 42.9056 42.573 40.4121C44.7414 37.9185 46.3192 34.9679 47.1888 31.7799C48.0584 28.5919 48.1975 25.2487 47.5956 21.9995C46.9937 18.7504 45.6664 15.6789 43.7125 13.0139C42.1574 10.8928 40.2373 9.07272 38.0472 7.63598C37.1151 7.02449 36.7353 5.80989 37.2586 4.8256Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M37.9907 5.23141C38.5481 4.26598 39.7879 3.92882 40.7072 4.55941C43.6193 6.55695 46.1 9.13112 47.9914 12.1299C50.2425 15.6991 51.591 19.7625 51.9206 23.9693C52.2502 28.1761 51.5509 32.3999 49.8831 36.2761C48.2153 40.1522 45.6292 43.5642 42.348 46.2174C39.0667 48.8706 35.189 50.6854 31.0497 51.5049C26.9103 52.3245 22.6337 52.1242 18.589 50.9214C14.5443 49.7186 10.8532 47.5495 7.83428 44.6012C5.29775 42.1241 3.29983 39.1595 1.95604 35.8939C1.53183 34.8629 2.121 33.7212 3.18172 33.3782C4.24243 33.0353 5.37178 33.6218 5.81066 34.6466C6.94328 37.2912 8.58724 39.6938 10.6549 41.713C13.205 44.2035 16.3231 46.0359 19.7397 47.0519C23.1564 48.0679 26.769 48.2371 30.2656 47.5448C33.7623 46.8525 37.0379 45.3195 39.8096 43.0783C42.5814 40.837 44.766 37.9548 46.1748 34.6805C47.5836 31.4062 48.1743 27.8382 47.8959 24.2846C47.6175 20.731 46.4783 17.2985 44.5768 14.2835C43.0351 11.8391 41.0298 9.72865 38.6808 8.0676C37.7706 7.42395 37.4334 6.19685 37.9907 5.23141Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M38.7083 5.66254C39.299 4.71715 40.5498 4.42347 41.4465 5.08576C44.531 7.36381 47.0804 10.3014 48.9032 13.6938C51.0452 17.6803 52.1096 22.1569 51.9911 26.6809C51.8726 31.2049 50.5753 35.6196 48.2275 39.4885C45.8797 43.3574 42.5627 46.5465 38.6045 48.7404C34.6464 50.9343 30.1841 52.0571 25.659 51.9978C21.1338 51.9384 16.7025 50.6989 12.8033 48.4019C8.90401 46.1049 5.67183 42.8299 3.42634 38.9008C1.51547 35.5571 0.37544 31.8383 0.0782211 28.0154C-0.00818809 26.904 0.903715 25.9989 2.0185 25.9989C3.13329 25.999 4.02734 26.9047 4.1296 28.0148C4.41697 31.1342 5.36924 34.1643 6.93133 36.8977C8.82817 40.2167 11.5585 42.9832 14.8523 44.9236C18.1461 46.8639 21.8894 47.911 25.7119 47.9611C29.5344 48.0112 33.3039 47.0628 36.6474 45.2095C39.991 43.3562 42.793 40.6623 44.7762 37.3941C46.7595 34.1259 47.8554 30.3967 47.9555 26.5752C48.0556 22.7536 47.1565 18.9721 45.3471 15.6046C43.857 12.8314 41.792 10.418 39.2989 8.52109C38.4117 7.84607 38.1175 6.60793 38.7083 5.66254Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M39.4103 6.11844C40.0337 5.19424 41.2939 4.9444 42.167 5.63758C45.4114 8.21347 48.0014 11.5341 49.7095 15.3295C51.6945 19.74 52.4098 24.6168 51.7752 29.4115C51.1406 34.2063 49.1811 38.729 46.1171 42.4712C43.0531 46.2135 39.006 49.0269 34.4307 50.5952C29.8555 52.1635 24.9334 52.4245 20.218 51.3489C15.5025 50.2733 11.1805 47.9038 7.73788 44.5066C4.29523 41.1095 1.8684 36.8194 0.730204 32.1186C-0.249257 28.0734 -0.241046 23.8622 0.740829 19.8376C1.00505 18.7546 2.15358 18.1787 3.21285 18.5262C4.27212 18.8736 4.83944 20.0128 4.5907 21.0994C3.83371 24.4065 3.85116 27.8536 4.65383 31.1686C5.6153 35.1394 7.66532 38.7634 10.5734 41.6331C13.4815 44.5028 17.1324 46.5044 21.1157 47.413C25.0991 48.3216 29.2568 48.1011 33.1217 46.7763C36.9865 45.4515 40.4053 43.0749 42.9935 39.9138C45.5818 36.7526 47.237 32.9321 47.7731 28.8818C48.3092 24.8315 47.7049 20.712 46.0281 16.9863C44.6283 13.876 42.5274 11.1431 39.9008 8.99586C39.0377 8.2903 38.7869 7.04264 39.4103 6.11844Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M40.096 6.59856C40.7512 5.69668 42.0195 5.49098 42.8678 6.2142C46.2532 9.10027 48.8506 12.8151 50.3965 17.0104C52.1756 21.8388 52.482 27.0865 51.2769 32.0891C50.0718 37.0918 47.4093 41.6243 43.6267 45.1129C39.844 48.6014 35.1112 50.8891 30.0276 51.6862C24.9439 52.4833 19.7381 51.754 15.0692 49.5906C10.4003 47.4273 6.47834 43.9272 3.79989 39.5335C1.12144 35.1398 -0.193037 30.0501 0.0229037 24.9089C0.210533 20.4418 1.54625 16.1103 3.88581 12.3265C4.47209 11.3784 5.74242 11.186 6.64227 11.8441C7.54212 12.5021 7.72925 13.7609 7.15656 14.7173C5.28117 17.8494 4.21044 21.4097 4.05635 25.0783C3.87394 29.4213 4.98432 33.7207 7.24689 37.4321C9.50946 41.1436 12.8225 44.1003 16.7664 45.9277C20.7104 47.7552 25.1079 48.3712 29.4022 47.6979C33.6965 47.0245 37.6944 45.0921 40.8898 42.1452C44.0851 39.1983 46.3342 35.3696 47.3522 31.1437C48.3702 26.9178 48.1113 22.4849 46.6084 18.4062C45.3389 14.9608 43.2298 11.8991 40.4858 9.49134C39.6478 8.75608 39.4407 7.50045 40.096 6.59856Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M40.7645 7.10232C41.4508 6.22386 42.7254 6.06253 43.548 6.81492C47.0836 10.0487 49.6672 14.207 50.9942 18.8382C52.5071 24.1181 52.3091 29.7415 50.4286 34.9019C48.5481 40.0623 45.0817 44.4947 40.5266 47.5634C35.9714 50.632 30.5615 52.1794 25.0726 51.9835C19.5837 51.7875 14.2979 49.8585 9.9731 46.4729C5.6483 43.0873 2.50674 38.4191 0.998938 33.1377C-0.508868 27.8564 -0.305459 22.2332 1.57999 17.0746C3.2338 12.5497 6.10722 8.58624 9.86436 5.6127C10.7385 4.92087 11.9984 5.17266 12.6203 6.09782C13.2423 7.02298 12.9895 8.27026 12.1253 8.9745C9.07128 11.4633 6.73262 14.7369 5.37167 18.4604C3.77897 22.8181 3.60715 27.5681 4.88084 32.0294C6.15453 36.4908 8.8083 40.4341 12.4616 43.2941C16.1149 46.154 20.58 47.7835 25.2166 47.949C29.8532 48.1145 34.4232 46.8074 38.271 44.2152C42.1189 41.623 45.0471 37.8789 46.6356 33.5197C48.2241 29.1606 48.3913 24.4103 47.1133 19.9502C46.0213 16.1391 43.922 12.7072 41.0531 10.007C40.2414 9.24292 40.0782 7.98079 40.7645 7.10232Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M41.4012 7.61754C42.1171 6.76303 43.3966 6.64536 44.193 7.4254C47.8636 11.0205 50.3983 15.6351 51.4529 20.6943C52.6452 26.4143 51.8761 32.3692 49.2696 37.5985C46.6631 42.8279 42.3711 47.0268 37.0859 49.5181C31.8007 52.0094 25.8305 52.6479 20.138 51.3305C14.4455 50.0132 9.36265 46.8168 5.70927 42.2569C2.05589 37.697 0.0449338 32.0394 0.000743601 26.1966C-0.0434466 20.3539 1.8817 14.6665 5.46569 10.0519C8.63568 5.9703 12.94 2.93837 17.8167 1.32135C18.8749 0.970502 19.9725 1.63828 20.2403 2.72044C20.508 3.8026 19.8436 4.88803 18.7906 5.25395C14.7932 6.64301 11.2673 9.16333 8.65404 12.5281C5.62654 16.4263 4.0003 21.2305 4.03763 26.1661C4.07496 31.1017 5.77367 35.8808 8.8598 39.7327C11.9459 43.5846 16.2395 46.2847 21.0482 47.3975C25.8568 48.5103 30.9001 47.971 35.3646 45.8665C39.8292 43.762 43.4547 40.215 45.6565 35.7976C47.8583 31.3802 48.508 26.35 47.5008 21.5181C46.6314 17.3474 44.5713 13.5343 41.5905 10.5304C40.8053 9.73904 40.6853 8.47206 41.4012 7.61754Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M26 2.0185C26 0.903715 25.0948 -0.00815203 23.9834 0.0782947C18.8638 0.476495 13.9598 2.38404 9.90356 5.58176C5.31723 9.19732 2.08173 14.2517 0.718382 19.9304C-0.64496 25.6091 -0.0567343 31.5815 2.38828 36.8852C4.83329 42.1888 8.99268 46.5148 14.1962 49.1662C19.3998 51.8175 25.3445 52.6398 31.0723 51.5004C36.8002 50.3611 41.9777 47.3265 45.7706 42.8857C49.5634 38.4448 51.7507 32.8563 51.98 27.0208C52.1827 21.8596 50.8435 16.771 48.1564 12.3951C47.5731 11.4451 46.3033 11.2488 45.4014 11.904C44.4996 12.5593 44.3086 13.8175 44.8783 14.7757C47.0398 18.4111 48.1132 22.6075 47.9461 26.8623C47.7524 31.7918 45.9047 36.5125 42.7008 40.2638C39.4969 44.0151 35.1233 46.5785 30.2848 47.541C25.4463 48.5034 20.4246 47.8088 16.029 45.5692C11.6334 43.3295 8.11983 39.6752 6.05445 35.195C3.98908 30.7149 3.49219 25.6698 4.64384 20.8728C5.7955 16.0758 8.52864 11.8063 12.4028 8.75209C15.7467 6.11597 19.7726 4.5178 23.9842 4.12968C25.0943 4.02737 26 3.13329 26 2.0185Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M27.5058 2.06582C27.5758 0.953236 26.7297 -0.0136635 25.6151 0.00282753C20.7547 0.074735 15.9967 1.50867 11.8911 4.16104C7.21874 7.1796 3.63762 11.6171 1.67393 16.8216C-0.289762 22.0262 -0.532497 27.7233 0.981387 33.0759C2.49527 38.4286 5.68596 43.1547 10.0847 46.5597C14.4834 49.9648 19.8582 51.8693 25.4195 51.9935C30.9807 52.1177 36.4352 50.455 40.9816 47.2498C45.5279 44.0445 48.9264 39.4656 50.6778 34.1859C52.2166 29.5466 52.4125 24.5811 51.2641 19.8578C51.0007 18.7746 49.8527 18.1978 48.7931 18.5444C47.7336 18.8909 47.1653 20.0297 47.4132 21.1166C48.3023 25.0148 48.1129 29.0957 46.8461 32.9148C45.3666 37.3748 42.4958 41.2427 38.6554 43.9503C34.8149 46.6579 30.2074 48.0624 25.5096 47.9575C20.8118 47.8526 16.2716 46.2438 12.5558 43.3674C8.84011 40.4911 6.14484 36.4988 4.86601 31.9773C3.58719 27.4557 3.79223 22.6432 5.45102 18.2468C7.10982 13.8503 10.1349 10.1018 14.0818 7.55196C17.4616 5.36846 21.3649 4.16247 25.3615 4.04627C26.4758 4.01387 27.4358 3.17841 27.5058 2.06582Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M29.0057 2.2076C29.1454 1.10161 28.3617 0.0834922 27.2482 0.0299631C22.6707 -0.190093 18.103 0.803576 14.0149 2.92711C9.32809 5.36166 5.49381 9.16582 3.02237 13.8333C0.550927 18.5008 -0.440471 23.8103 0.180116 29.0551C0.800703 34.2999 3.00384 39.2314 6.49633 43.1932C9.98882 47.155 14.605 49.9593 19.7306 51.2328C24.8562 52.5063 30.2481 52.1887 35.1887 50.3222C40.1293 48.4557 44.3843 45.1288 47.3875 40.7843C50.007 36.9948 51.5657 32.5879 51.9215 28.0189C52.0081 26.9075 51.0963 26.0023 49.9815 26.0022C48.8667 26.0021 47.9725 26.9076 47.8701 28.0177C47.5244 31.7656 46.2197 35.3741 44.0667 38.4888C41.5298 42.1587 37.9355 44.969 33.762 46.5457C29.5885 48.1224 25.0338 48.3907 20.7041 47.3149C16.3743 46.2392 12.4749 43.8703 9.52466 40.5236C6.57444 37.177 4.71338 33.0112 4.18915 28.5807C3.66493 24.1503 4.50239 19.6652 6.59009 15.7224C8.6778 11.7796 11.9167 8.56616 15.8758 6.50963C19.236 4.76424 22.9796 3.92245 26.7413 4.0495C27.8554 4.08713 28.866 3.3136 29.0057 2.2076Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M30.4937 2.44328C30.7026 1.34824 29.9844 0.282924 28.8764 0.159587C24.5975 -0.316743 20.2558 0.276047 16.2455 1.89919C11.6098 3.77543 7.61359 6.94876 4.73588 11.0388C1.85818 15.1288 0.221083 19.962 0.0208491 24.959C-0.179385 29.9559 1.06574 34.9046 3.60696 39.2118C6.14818 43.519 9.87766 47.0019 14.3483 49.243C18.819 51.4842 23.8412 52.3884 28.8128 51.8474C33.7844 51.3064 38.4945 49.343 42.3785 46.1927C45.7385 43.4674 48.3569 39.9537 50.0091 35.978C50.437 34.9486 49.8518 33.8048 48.7923 33.4581C47.7328 33.1114 46.6014 33.6941 46.1589 34.7173C44.7579 37.9572 42.5922 40.8214 39.8354 43.0574C36.5545 45.7186 32.5757 47.3771 28.376 47.8341C24.1764 48.2911 19.934 47.5273 16.1575 45.6341C12.381 43.7409 9.23056 40.7988 7.08391 37.1604C4.93726 33.522 3.88547 29.3417 4.05462 25.1206C4.22376 20.8995 5.60666 16.8168 8.03755 13.3618C10.4684 9.90684 13.8442 7.22622 17.76 5.64131C21.0504 4.30959 24.6047 3.79894 28.1181 4.13936C29.2277 4.24688 30.2848 3.53832 30.4937 2.44328Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M31.964 2.77193C32.2412 1.69216 31.5913 0.583854 30.4933 0.391193C26.5391 -0.302625 22.4681 -0.0727807 18.6019 1.07475C14.0891 2.41419 10.0247 4.95368 6.84216 8.42226C3.65966 11.8908 1.47855 16.1584 0.531555 20.7695C-0.415444 25.3806 -0.0928014 30.1623 1.46506 34.6045C3.02292 39.0466 5.75754 42.9824 9.37714 45.992C12.9968 49.0016 17.3655 50.9721 22.0173 51.6932C26.6692 52.4142 31.4295 51.8589 35.7904 50.0863C39.5264 48.5677 42.8492 46.2045 45.5039 43.1929C46.2411 42.3567 46.0564 41.0852 45.1655 40.4151C44.2746 39.745 43.0157 39.9314 42.2666 40.757C40.0597 43.1897 37.3289 45.1031 34.2702 46.3464C30.5864 47.8438 26.5653 48.3129 22.6357 47.7038C18.7062 47.0947 15.0158 45.4302 11.9582 42.8879C8.90057 40.3456 6.59055 37.0208 5.27458 33.2684C3.95861 29.5161 3.68607 25.4768 4.48603 21.5816C5.28598 17.6865 7.12843 14.0816 9.81678 11.1515C12.5051 8.22153 15.9385 6.07634 19.7506 4.94487C22.9159 4.0054 26.2436 3.79407 29.4865 4.3155C30.5872 4.49247 31.6867 3.85169 31.964 2.77193Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M33.4107 3.19224C33.7552 2.13202 33.1762 0.985095 32.0924 0.723871C28.4398 -0.156562 24.6313 -0.236694 20.9305 0.499025C16.5682 1.36623 12.5013 3.33798 9.1187 6.22573C5.73607 9.11349 3.15077 12.8207 1.60999 16.9929C0.0692205 21.1651 -0.375513 25.6628 0.318317 30.056C1.01215 34.4492 2.82134 38.5909 5.57292 42.0852C8.32449 45.5795 11.9264 48.3096 16.0344 50.0143C20.1423 51.719 24.6189 52.3415 29.0361 51.8221C32.7835 51.3815 36.3819 50.1316 39.5855 48.1683C40.536 47.5858 40.7334 46.3163 40.0789 45.4138C39.4244 44.5114 38.1664 44.3192 37.2077 44.8881C34.5696 46.4535 31.626 47.4528 28.5647 47.8127C24.8334 48.2515 21.0519 47.7257 17.5817 46.2856C14.1116 44.8456 11.069 42.5394 8.74462 39.5877C6.42028 36.6359 4.892 33.1373 4.3059 29.4262C3.7198 25.7152 4.09548 21.9158 5.39702 18.3914C6.69855 14.867 8.88243 11.7354 11.7398 9.29607C14.5973 6.8567 18.0327 5.1911 21.7176 4.45855C24.7409 3.85753 27.8492 3.90104 30.8413 4.57721C31.9286 4.82295 33.0662 4.25247 33.4107 3.19224Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M34.8282 3.70257C35.2386 2.66607 34.7327 1.48505 33.6675 1.1563C30.339 0.12901 26.831 -0.220974 23.352 0.135193C19.2023 0.56003 15.2157 1.97709 11.7289 4.26673C8.24201 6.55637 5.35725 9.65137 3.31814 13.2904C1.27903 16.9295 0.145448 21.0057 0.0130912 25.175C-0.119265 29.3443 0.753494 33.4843 2.55768 37.2453C4.36187 41.0064 7.04452 44.2781 10.3791 46.7843C13.7137 49.2905 17.6024 50.9576 21.7168 51.6448C25.1662 52.2209 28.6893 52.0941 32.0763 51.28C33.1602 51.0195 33.74 49.8729 33.3961 48.8125C33.0523 47.752 31.9151 47.1808 30.8276 47.4259C28.0605 48.0493 25.1922 48.1323 22.3819 47.6629C18.9063 47.0824 15.6214 45.6742 12.8046 43.5572C9.98773 41.4401 7.72161 38.6764 6.19756 35.4993C4.6735 32.3222 3.93626 28.8251 4.04806 25.3031C4.15987 21.7812 5.11744 18.3378 6.83994 15.2638C8.56244 12.1898 10.9993 9.57537 13.9447 7.64124C16.8902 5.70711 20.2578 4.51008 23.7632 4.15121C26.5976 3.86103 29.4548 4.12574 32.1769 4.92348C33.2467 5.237 34.4178 4.73907 34.8282 3.70257Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M36.2108 4.30089C36.6855 3.2922 36.2548 2.08176 35.2123 1.68677C32.2102 0.549241 29.0176 -0.0245287 25.7956 0.00080358C21.8995 0.0314361 18.0601 0.937314 14.5612 2.65147C11.0623 4.36563 7.99341 6.84424 5.58136 9.90407C3.16931 12.9639 1.47578 16.5267 0.625975 20.3291C-0.223833 24.1315 -0.208193 28.0763 0.671738 31.8719C1.55167 35.6674 3.27339 39.2167 5.70963 42.2573C8.14586 45.2979 11.2343 47.7521 14.7467 49.4385C17.6513 50.8331 20.7844 51.673 23.9852 51.9218C25.0967 52.0082 26.0017 51.0963 26.0016 49.9815C26.0015 48.8667 25.0959 47.9727 23.9858 47.8704C21.3906 47.6314 18.8531 46.9319 16.494 45.7992C13.527 44.3747 10.9181 42.3016 8.8601 39.7331C6.80214 37.1646 5.34775 34.1664 4.60444 30.9602C3.86114 27.7539 3.84793 24.4217 4.56578 21.2096C5.28364 17.9976 6.71421 14.988 8.75175 12.4033C10.7893 9.81854 13.3817 7.72479 16.3373 6.27678C19.2929 4.82878 22.5362 4.06356 25.8273 4.03768C28.4441 4.01711 31.038 4.46437 33.4881 5.35292C34.5361 5.73299 35.7362 5.30958 36.2108 4.30089Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M37.5532 4.98486C38.0902 4.00796 37.7364 2.77287 36.7208 2.3132C34.0424 1.10095 31.1732 0.349686 28.2344 0.0961911C24.6241 -0.215231 20.9886 0.231475 17.561 1.40765C14.1335 2.58382 10.9897 4.46349 8.33125 6.92606C5.67284 9.38862 3.55855 12.3797 2.12402 15.7074C0.689501 19.0351 -0.0335715 22.6259 0.00119677 26.2495C0.035965 29.873 0.827806 33.4493 2.32592 36.7489C3.82403 40.0484 5.99533 42.9984 8.7005 45.4095C10.9025 47.3721 13.4186 48.9424 16.1389 50.0574C17.1704 50.4802 18.3113 49.8895 18.6529 48.8283C18.9944 47.7671 18.4063 46.6386 17.3809 46.2011C15.1937 45.2679 13.1684 43.9839 11.3866 42.3958C9.10144 40.359 7.26728 37.8671 6.00178 35.0799C4.73628 32.2927 4.06739 29.2717 4.03802 26.2107C4.00865 23.1498 4.61945 20.1165 5.83123 17.3055C7.04302 14.4945 8.82902 11.9679 11.0747 9.88766C13.3203 7.80745 15.976 6.21964 18.8713 5.22609C21.7667 4.23254 24.8377 3.85519 27.8875 4.11826C30.2655 4.32338 32.5896 4.91435 34.7698 5.86385C35.7918 6.30898 37.0161 5.96175 37.5532 4.98486Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M38.8499 5.75175C39.4473 4.81051 39.1716 3.55557 38.1869 3.033C32.8433 0.197302 26.6551 -0.696014 20.6919 0.547612C14.0676 1.92911 8.2429 5.83877 4.45507 11.4461C0.667245 17.0534 -0.78524 23.9166 0.406157 30.5777C1.47865 36.574 4.61758 41.9813 9.24317 45.8799C10.0956 46.5984 11.3627 46.3856 12.0129 45.4801C12.6631 44.5745 12.4489 43.32 11.6068 42.5895C7.82987 39.3128 5.26754 34.8286 4.3801 29.8669C3.37369 24.2401 4.60065 18.4426 7.80034 13.7059C11 8.96919 15.9203 5.66658 21.5161 4.49959C26.4503 3.47055 31.5669 4.17355 36.0167 6.45414C37.0088 6.96259 38.2526 6.693 38.8499 5.75175Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M40.096 6.59856C40.7512 5.69668 40.5549 4.4269 39.6049 3.84355C34.94 0.978949 29.4723 -0.349332 23.9804 0.0785567C17.8154 0.558885 12.0235 3.22075 7.64409 7.5864C3.26472 11.952 0.584692 17.7356 0.0850217 23.8991C-0.360099 29.3896 0.95102 34.8614 3.80097 39.5353C4.38134 40.4871 5.65049 40.6875 6.55443 40.035C7.45836 39.3826 7.6533 38.125 7.08658 37.165C4.79106 33.2766 3.74132 28.7585 4.10882 24.2253C4.53091 19.0188 6.79482 14.1333 10.4942 10.4455C14.1936 6.75767 19.0862 4.50911 24.294 4.10336C28.8283 3.75008 33.3431 4.814 37.2243 7.12171C38.1825 7.69144 39.4407 7.50045 40.096 6.59856Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M41.2864 7.52194C41.997 6.66298 41.8808 5.38343 40.9693 4.74159C36.9412 1.90504 32.1769 0.26303 27.2281 0.0290193C21.6052 -0.236866 16.0479 1.32931 11.3913 4.49219C6.73472 7.65507 3.23041 12.2438 1.40499 17.5688C-0.201587 22.2554 -0.431303 27.2895 0.720753 32.0795C0.981436 33.1634 2.12808 33.7431 3.18848 33.3991C4.24888 33.0552 4.81991 31.9178 4.57473 30.8303C3.68315 26.8758 3.9008 22.7374 5.22384 18.8779C6.76583 14.3797 9.72603 10.5035 13.6596 7.8317C17.5931 5.15991 22.2876 3.83692 27.0374 4.06152C31.1128 4.25423 35.0401 5.57733 38.3875 7.86374C39.308 8.49251 40.5758 8.3809 41.2864 7.52194Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M42.4165 8.51825C43.1796 7.7056 43.144 6.42129 42.2746 5.72348C38.8617 2.98413 34.803 1.14342 30.4705 0.387217C25.4734 -0.484986 20.3303 0.123637 15.6748 2.13811C11.0193 4.15258 7.05474 7.4849 4.26957 11.7246C1.8548 15.4004 0.41803 19.619 0.0784101 23.9821C-0.00810261 25.0935 0.903715 25.9987 2.0185 25.9988C3.13329 25.9988 4.02742 25.0932 4.12979 23.9832C4.4594 20.4087 5.66147 16.9584 7.64364 13.9411C9.99636 10.3597 13.3453 7.54482 17.278 5.84313C21.2106 4.14144 25.5552 3.62732 29.7764 4.3641C33.3327 4.98483 36.6707 6.47062 39.5019 8.67739C40.3811 9.36272 41.6533 9.33089 42.4165 8.51825Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M43.4818 9.58354C44.2944 8.82042 44.3396 7.53642 43.5157 6.7854C40.6831 4.20326 37.308 2.27346 33.6278 1.1441C29.303 -0.183119 24.7067 -0.358929 20.2932 0.63404C15.8796 1.62701 11.8015 3.75439 8.46178 6.80597C5.61995 9.40264 3.3965 12.592 1.94275 16.1385C1.51993 17.17 2.11063 18.3109 3.17181 18.6525C4.23298 18.994 5.36154 18.4059 5.79904 17.3806C7.02793 14.5005 8.86184 11.9089 11.1849 9.78622C14.0061 7.20845 17.451 5.41139 21.1793 4.5726C24.9075 3.73381 28.7902 3.88232 32.4435 5.00346C35.4518 5.92667 38.219 7.4831 40.5629 9.55936C41.3974 10.2985 42.6691 10.3467 43.4818 9.58354Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M44.4781 10.7136C45.337 10.003 45.4627 8.72441 44.6876 7.92315C42.4097 5.56829 39.7028 3.66065 36.7061 2.30656C33.0951 0.674871 29.1588 -0.109844 25.1981 0.0123686C21.2374 0.134581 17.357 1.16049 13.8535 3.01171C10.946 4.54798 8.36178 6.61888 6.23341 9.1097C5.50922 9.95722 5.71349 11.2257 6.61462 11.8819C7.51576 12.5382 8.77164 12.3325 9.50785 11.4954C11.2657 9.49672 13.3761 7.82982 15.7394 6.58109C18.699 5.0173 21.9769 4.15069 25.3226 4.04745C28.6684 3.94422 31.9934 4.60709 35.0438 5.98543C37.4796 7.08608 39.6888 8.61969 41.5665 10.5062C42.3529 11.2963 43.6191 11.4242 44.4781 10.7136Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;none;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>

				<g>
					<path
						d="M45.4014 11.904C46.3033 11.2488 46.509 9.98057 45.7858 9.13223C43.5762 6.54037 40.8743 4.4027 37.8298 2.8471C34.7852 1.2915 31.4697 0.354678 28.0747 0.0829052C26.9635 -0.00604955 26.0564 0.903778 26.0538 2.01856C26.0513 3.13335 26.9549 4.02947 28.0648 4.13427C30.8236 4.39479 33.5145 5.1757 35.993 6.44204C38.4714 7.70837 40.681 9.43133 42.5087 11.5142C43.2439 12.3522 44.4996 12.5593 45.4014 11.904Z"
						fill="currentColor"
					/>
					<animate
						attributeName="display"
						values="none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;none;inline;"
						dur="800ms"
						repeatCount="indefinite"
					/>
				</g>
			</svg>
		</div>
	);
}
