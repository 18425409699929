import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { PropsWithChildren, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";
import { EmojiObject } from "../types";

interface EmojiPickerProps extends PropsWithChildren {
	onEmojiSelect: (emoji: EmojiObject) => void;
}

export function EmojiPopover({ onEmojiSelect, children }: EmojiPickerProps) {
	const [open, setOpen] = useState(false);

	const handleEmojiSelect = (emoji: EmojiObject) => {
		onEmojiSelect(emoji);
		setOpen(false);
	};

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent>
				<Picker data={data} onEmojiSelect={handleEmojiSelect} theme="light" previewPosition="none" />
			</PopoverContent>
		</Popover>
	);
}
