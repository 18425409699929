import { useCallback } from "react";

import { useGetContextWorkspaceId } from "../../workspaces";
import { useBulkUpdateRecordsMutation, BulkUpdateRecordsArgs } from "../api/recordsApi";

export function useBulkUpdateRecords() {
	const workspaceId = useGetContextWorkspaceId();

	const [bulkUpdateRecords, { isLoading }] = useBulkUpdateRecordsMutation();

	return {
		bulkUpdateRecords: useCallback(
			(args: BulkUpdateRecordsArgs) => {
				return bulkUpdateRecords({ ...args, workspaceId }).unwrap();
			},
			[bulkUpdateRecords, workspaceId]
		),
		isLoading,
	};
}
