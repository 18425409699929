import { useMemo } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { MenuContents, PopoverMenu } from "../../../../../menu";
import { VIDEO_CALL_CONTAINER_ID } from "../../../../utils";
import { useVideoCallMediaContext } from "../../../VideoCallProvider";
import { SettingsPopoverContent } from "../../SettingsPopover";
import { Button } from "@salesdesk/daisy-ui";

export function CallSettings() {
	const { backgroundSettings, setBackgroundSettings } = useVideoCallMediaContext();
	const hasBackgroundImage = backgroundSettings.type === "image";
	const isBlurEnabled = backgroundSettings.type === "blur";

	const menuContents: MenuContents = useMemo(() => {
		return [
			[
				{
					icon: ICONS.userBlur,
					text: isBlurEnabled ? "Background blur off" : "Background blur",
					onClick: hasBackgroundImage
						? undefined
						: () => setTimeout(() => setBackgroundSettings({ type: isBlurEnabled ? "none" : "blur", index: 0 }), 200),
					type: "button",
				},
			],
			[
				{
					icon: ICONS.settings,
					text: "Settings",
					subPopover: <SettingsPopoverContent />,
					subPopoverPlacement: "right-end",
					type: "subPopover",
				},
			],
		] satisfies MenuContents;
	}, [isBlurEnabled, hasBackgroundImage, setBackgroundSettings]);

	return (
		<PopoverMenu
			tooltipText="Settings"
			placement="top-start"
			rootElementId={VIDEO_CALL_CONTAINER_ID}
			menuContents={menuContents}
		>
			<Button variant="text_inverted" size="lg" startIcon={ICONS.verticalDots} />
		</PopoverMenu>
	);
}
