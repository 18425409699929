import { getApproxTime, getFormattedTime, isValidFormattedTime, setFormattedTime } from "../../TimeRangeInput/utils";
import { TextInput, FieldSet } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { mapUtcTimeStampToLocalDateTime } from "@salesdesk/salesdesk-utils";

interface TimeInputProps {
	label: string;
	value: number | null;
	onChange: (value: number, fromOption: boolean) => void;
	disabled?: boolean;
}

export function TimeInput({ label, value, onChange, disabled }: TimeInputProps) {
	const formattedValue = value ? getFormattedTime(value) : "";

	const [currentValue, setCurrentValue] = useState(formattedValue);
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	const currentTimeRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		setCurrentValue(formattedValue);
	}, [formattedValue]);

	const timeOptions = useMemo(() => {
		const times: string[] = [];
		for (let i = 0; i < 24; i++) {
			for (let j = 0; j < 60; j += 15) {
				const hours = i < 10 ? "0" + i : i;
				const minutes = j < 10 ? "0" + j : j;
				times.push(`${hours}:${minutes}`);
			}
		}
		return times;
	}, []);

	const currentApproxTime = useMemo(() => {
		if (value === null) return "";
		return getApproxTime(mapUtcTimeStampToLocalDateTime(value, false));
	}, [value]);

	useEffect(() => {
		if (!isPickerOpen) {
			return;
		}

		const scrollToCurrentTime = () => currentTimeRef.current?.scrollIntoView({ block: "center" });

		if (!currentTimeRef.current) {
			// Timeout in case the popover is going from closed to open and the
			// ref hasn't been set yet
			setTimeout(scrollToCurrentTime);
		} else {
			scrollToCurrentTime();
		}
	}, [isPickerOpen, currentApproxTime]);

	return (
		<FieldSet label={label} labelIcon={ICONS.clock}>
			<Popover
				open={isPickerOpen}
				onOpenChange={setIsPickerOpen}
				placement="top-start"
				useFloatingPortal={true}
				isInputPopover={true}
			>
				<PopoverTrigger>
					<TextInput
						type="time"
						data-hide-picker-icon="true"
						value={currentValue}
						onFocus={() => setIsPickerOpen(true)}
						onChange={(formattedTime) => {
							setCurrentValue(formattedTime);
							if (value && isValidFormattedTime(formattedTime)) {
								onChange(setFormattedTime(value, formattedTime), false);
							}
						}}
						icon={ICONS.caretUpDown}
						disabled={disabled}
					/>
				</PopoverTrigger>
				<PopoverContent initialFocus={-1}>
					<div
						className="bg-c_bg_01 border-c_border_regular shadow-popover text-c_text_primary text-label-nav flex w-full flex-col overflow-y-auto rounded-sm border py-2"
						style={{ width: 128, height: 234 }}
					>
						{timeOptions.map((time) => (
							<button
								tabIndex={-1}
								key={time}
								ref={time === currentApproxTime ? currentTimeRef : undefined}
								className={clsx(
									"hover:bg-c_bg_02 focus-visible:ring-c_action_focus text-label-nav flex min-h-10 items-center pl-6 focus-visible:ring",
									time === formattedValue && "bg-c_bg_05"
								)}
								onClick={() => {
									if (value) {
										onChange(setFormattedTime(value, time), true);
									}
								}}
							>
								{time}
							</button>
						))}
					</div>
				</PopoverContent>
			</Popover>
		</FieldSet>
	);
}
