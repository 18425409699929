import { asConst } from "json-schema-to-ts";
import { fieldSchema } from "./fieldSchema";
import { fieldGroupCreateRequestSchema } from "./fieldGroupCreateRequestSchema";
import { fieldCreateRequestSchema } from "./fieldCreateRequestSchema";

export const fieldGroupUpdateRequestSchema = asConst({
	title: "SDObject Field Group Update Request",
	description: "A schema for creating/updating a group of SDObject Fields",
	type: "object",
	additionalProperties: false,
	required: [...fieldSchema.required, "_children"],
	properties: {
		...fieldGroupCreateRequestSchema.properties,
		_children: {
			type: "array",
			items: { anyOf: [fieldCreateRequestSchema, fieldSchema] },
		},
	},
});
