import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ConfirmSignInOutput } from "@aws-amplify/auth/src/providers/cognito/types";
import { AuthError, confirmSignIn, fetchAuthSession, signIn, SignInOutput, signOut } from "aws-amplify/auth";
import { unescape } from "lodash";

import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { PATHS, useStableNavigate } from "../../../../routes";
import { LOGIN_EVENT_PENDING_MARKER } from "../../../../auth/utils/auth";
import { googleAuthLink, isGoogleSignInEnabled } from "../utils";
import { BrandedLandingPageLayout } from "../../common/BrandedLandingPageLayout";
import { SetPasswordForm } from "./SetPasswordForm";

export function InvitationPage() {
	const navigate = useStableNavigate();

	const [params] = useSearchParams();
	const email = params.get("user");
	const firstName = params.get("user_first_name");
	const inviteCode = params.get("invite");

	const [signInResponse, setSignInResponse] = useState<ConfirmSignInOutput>();
	const [showError, setShowError] = useState(false);

	const initialSignInCall = useCallback(async () => {
		if (!email || !inviteCode) {
			console.error("Missing invitation parameters");
			navigate(PATHS.ROOT());
			return;
		}
		try {
			// Cognito HTML-escapes reserved characters like < (&lt;) and > (&gt;) in your user's temporary password.
			const unescapedInviteCode = unescape(inviteCode);
			const signInParams = {
				username: email,
				password: unescapedInviteCode,
			};
			let response: SignInOutput;
			try {
				response = await signIn(signInParams);
			} catch (err) {
				if (err instanceof AuthError && err.name === "UserAlreadyAuthenticatedException") {
					// This can happen if you have previously signed in as another user in the same browser
					await signOut();
					response = await signIn(signInParams);
				} else {
					throw err;
				}
			}
			if (response?.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
				// It should never happen
				console.error("Invalid sign in response", response?.nextStep?.signInStep);
				navigate(PATHS.ROOT());
				return;
			}
			setSignInResponse(response);
		} catch (e) {
			console.error("Error while signing in", e);
			navigate(PATHS.ROOT());
		}
	}, [inviteCode, navigate, email]);

	useEffect(() => {
		initialSignInCall();
	}, [initialSignInCall]);

	const onSubmit = useCallback(
		async (password: string) => {
			try {
				await confirmSignIn({
					challengeResponse: password,
				});
				const authSession = await fetchAuthSession();
				if (!authSession.tokens) {
					console.error("No tokens in the auth session", authSession);
					setShowError(true);
					return;
				}
				const accessToken = authSession.tokens.accessToken.toString();
				localStorage.setItem("jwt", JSON.stringify({ access_token: accessToken }));
				localStorage.setItem(LOGIN_EVENT_PENDING_MARKER, "true"); // To trigger a USER_LOGGED_IN event on the next page load
				navigate(PATHS.ROOT());
			} catch (e) {
				console.error("Error while setting the password", e);
				setShowError(true);
			}
		},
		[navigate]
	);

	if ((!signInResponse || !email) && !showError) {
		return null;
	}

	return (
		<BrandedLandingPageLayout>
			<div className="mx-auto flex max-h-full max-w-[440px] shrink-0 flex-col items-center gap-8 overflow-hidden text-center">
				{showError ? (
					<>
						<div className="text-h3">Sorry, something went wrong</div>
						<img src="/static/images/something_went_wrong.svg" alt="Somthing went wrong graphic" width={250} />
						<div className="text-center">
							There was a problem processing your request.
							<br />
							Please try again later
						</div>
						<Button variant="outlined" fullWidth onClick={() => window.location.reload()}>
							Try again
						</Button>
					</>
				) : (
					<>
						<SetPasswordForm email={email} firstName={firstName} onNewPasswordSubmit={onSubmit} />
						{isGoogleSignInEnabled() ? (
							<>
								<div className="flex h-5 w-full items-center gap-8">
									<div className="bg-c_text_placeholder h-px grow" />
									<div className="text-body-sm text-c_text_placeholder">Or</div>
									<div className="bg-c_text_placeholder h-px grow" />
								</div>
								<Button variant="outlined" fullWidth startIcon={ICONS.googleLogo} as="link" to={googleAuthLink()}>
									Continue with Google
								</Button>
							</>
						) : null}
					</>
				)}
			</div>
		</BrandedLandingPageLayout>
	);
}
