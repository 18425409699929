import { Fragment } from "react";

import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { InlineUserLoading } from "../../users";

export function ChatLoadingView() {
	return (
		<div className="flex w-full flex-col gap-4">
			<Skeleton className="mx-auto h-4 w-28" />
			{[...Array(2)].map((_, index) => (
				<Fragment key={`chat-${index}`}>
					<div className="flex flex-col gap-1">
						<InlineUserLoading />
						<div className="flex w-1/2 max-w-[450px] flex-col gap-2 pl-8">
							<Skeleton className="h-6 w-full" />
							<Skeleton className="h-6 w-2/3" />
						</div>
					</div>
					<div className="ml-auto flex w-1/2 max-w-[450px] flex-col items-end gap-2">
						<Skeleton className="h-6 w-full" />
						<Skeleton className="h-6 w-2/3" />
					</div>
				</Fragment>
			))}
		</div>
	);
}
