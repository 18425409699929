import { useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "../../../../../../../utils";
import { MUTED_VOLUME } from "../../../../common";

const VOLUME_THRESHOLD = 0.05;
const MIN_DOMINANT_SPEAKER_TIME = 2000; // Minimum time in ms someone can remain as dominant speaker if they are talking

/**
 * Returns the current Dominant speaker identity in the call based on who is the loudest
 * and talked in the last second. Returns null if no one is currently the dominant speaker.
 *
 * Also returns a FIFO queue of dominant speakers with the identity of participants ordered by
 * who was the most recent dominant speaker.
 *
 * Note that this custom approach is used over Twilio's `dominantSpeaker` since Twilio doesn't
 * track if the local participant is the dominant speaker and instead only keeps track of the
 * remote participants: https://github.com/twilio/twilio-video.js/issues/604#issuecomment-629790920,
 * which means it won't work if there aren't multiple users in the call and has issues when there
 * are only two users.
 */
export function useDominantSpeaker(participantVolumeMap: Record<string, number | undefined>) {
	const [currentDominantSpeakerIdentity, setCurrentDominantSpeakerIdentity] = useState<string | null>(null);
	const participantVolumeMapRef = useRef<Record<string, number | undefined>>();
	const lastDominantSpeakerTimeRef = useRef<number>(Date.now());

	participantVolumeMapRef.current = participantVolumeMap;

	// FIFO queue of dominant speakers
	const [dominantSpeakerQueue, setDominantSpeakerQueue] = useState<string[]>([]);

	useEffect(() => {
		setDominantSpeakerQueue((prevQueue) => {
			if (!currentDominantSpeakerIdentity) {
				return prevQueue;
			}

			// Moves the new dominant speaker to the beginning of the queue
			return [
				currentDominantSpeakerIdentity,
				...prevQueue.filter((identity) => identity !== currentDominantSpeakerIdentity),
			];
		});
	}, [currentDominantSpeakerIdentity]);

	const clearCurrentSpeaker = useMemo(
		() =>
			debounce((identityToClear: string) => {
				setCurrentDominantSpeakerIdentity((current) => clearSpecifiedIdentity(current, identityToClear));
			}, 1000),
		[]
	);

	useEffect(() => {
		const interval = setInterval(() => {
			const volumeMap = participantVolumeMapRef.current;

			if (!volumeMap) {
				return;
			}

			let currentDominantSpeakerIdentity: string | null = null;
			let maxVolume = 0;

			for (const [identity, volume] of Object.entries(volumeMap)) {
				if (!volume) {
					continue;
				}

				if (volume >= VOLUME_THRESHOLD && volume > maxVolume) {
					maxVolume = volume;
					currentDominantSpeakerIdentity = identity;
				}
				// If the current dominant speaker mutes themselves, they are immediatly unset as dominant speaker
				else if (volume === MUTED_VOLUME) {
					setCurrentDominantSpeakerIdentity((current) => clearSpecifiedIdentity(current, identity));
				}
			}

			if (currentDominantSpeakerIdentity) {
				setCurrentDominantSpeakerIdentity((current) => {
					const timeSinceLastDominant = Date.now() - lastDominantSpeakerTimeRef.current;

					let newDominantSpeaker = current;

					// Doesn't change dominant speaker if not enough time has passed since the last dominant
					// speaker was set. This is done to prevent the dominant speaker from changing to rapidly
					// when two or more people talk at once
					if (!current || timeSinceLastDominant >= MIN_DOMINANT_SPEAKER_TIME) {
						lastDominantSpeakerTimeRef.current = Date.now();
						newDominantSpeaker = currentDominantSpeakerIdentity;
					}

					// Debounced clear to prevent dominant speaker from rapidly changing as a user speaks
					// (e.g. due to short pauses between words)
					if (newDominantSpeaker) {
						clearCurrentSpeaker(newDominantSpeaker);
					}

					return newDominantSpeaker;
				});
			}
		}, 100);

		return () => clearInterval(interval);
	}, [clearCurrentSpeaker]);

	return { currentDominantSpeakerIdentity, dominantSpeakerQueue };
}

function clearSpecifiedIdentity(currentIdentity: string | null, identityToClear: string) {
	if (currentIdentity === identityToClear) {
		return null;
	}

	return currentIdentity;
}
