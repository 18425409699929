import { Fragment, useCallback } from "react";
import { Combobox } from "@headlessui/react";

import { tw } from "../../../../../utils/tailwind-helpers";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../components/Popover";
import { useStableNavigate } from "../../../../../routes";
import { Searchbar } from "../../../../inputs";
import { useGlobalSearchbar } from "../hooks/useGlobalSearchbar";
import { GlobalSearchbarPopover } from "./GlobalSearchbarPopover";

interface CollapsibleGlobalSearchbarProps {
	placeholder?: string;
}

export function CollapsibleGlobalSearchbar({ placeholder }: CollapsibleGlobalSearchbarProps) {
	const {
		searchRef,
		comboBtn,
		searchBarText,
		setSearchBarText,
		updateComboboxPopoverState,
		isLoading,
		searchResults,
		resultHighlights,
		currentSearchText,
	} = useGlobalSearchbar();

	const navigate = useStableNavigate();

	const onCollapsedChange = useCallback(
		(collapsed: boolean) => {
			updateComboboxPopoverState(!collapsed);
		},
		[updateComboboxPopoverState]
	);

	const onRecordSelect = useCallback(
		(urlPath: string) => {
			// For some reason with the databoard, navigating straight on enter press can cause the record id
			// to get cleared from the URL. This is a temporary fix to prevent that from happening, needs to be
			// investigated further.
			setTimeout(() => navigate(urlPath), 100);
			updateComboboxPopoverState(false);
			setSearchBarText("");
		},
		[navigate, setSearchBarText, updateComboboxPopoverState]
	);

	return (
		// Value of combobox is set to a fixed null to prevent re-render issues on combobox close
		<Combobox value={null as unknown} onChange={({ urlPath }: { urlPath: string }) => onRecordSelect(urlPath)}>
			{({ open }) => (
				<Popover
					keepPopoverMounted={true}
					hideWhenClosedButMounted={false}
					useFloatingPortal={true}
					open={open}
					isInputPopover
				>
					<PopoverTrigger>
						<Searchbar
							ref={searchRef}
							isLoading={isLoading}
							value={searchBarText}
							onChange={setSearchBarText}
							comboboxOptions={{ useComboboxInput: true, isOpen: open }}
							onCollapsedChange={onCollapsedChange}
							placeholder={placeholder}
							tooltip="Search"
							maxWidthClass={tw`max-w-[316px]`}
							collapsesOnBlur={false}
							size={32}
							forceCollapse={!open}
							wrapperClass={tw`w-[316px]`}
						/>
						<Combobox.Button as={Fragment}>
							<button ref={comboBtn} className="sr-only" />
						</Combobox.Button>
					</PopoverTrigger>
					<PopoverContent initialFocus={-1}>
						<GlobalSearchbarPopover
							searchResults={searchResults}
							searchHighlightsMap={resultHighlights}
							searchText={currentSearchText}
							onRecordSelect={onRecordSelect}
							isLoading={isLoading}
							variant="collapsible"
						/>
					</PopoverContent>
				</Popover>
			)}
		</Combobox>
	);
}
