import { asConst } from "json-schema-to-ts";
import { sdObjectUpdateRequestSchema } from "./sdObjectUpdateRequestSchema";
import { objectNameSchema } from "./properties";
import { fieldGroupCreateRequestSchema } from "./field";

export const sdObjectCreateRequestSchema = asConst({
	title: "SDObjectCreateRequest",
	description: "Defines an object schema used for create requests",
	type: "object",
	additionalProperties: false,
	required: [...sdObjectUpdateRequestSchema.required, "_name"],
	properties: {
		...sdObjectUpdateRequestSchema.properties,
		_name: objectNameSchema,

		// TODO:  Change this so its just a list of field and add any other properties directly on the object
		// If you need grouping then allow one level of grouping called fieldGroup
		_dataDef: fieldGroupCreateRequestSchema,
	},
});
