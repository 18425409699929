import { useCallback, useState } from "react";

import { capitalizeString } from "@salesdesk/salesdesk-utils";

import { PanelTab, PanelTabList, PanelTabPanels, PanelTabs } from "../../../../../../../components/PanelTabs";
import { Sticky } from "../../../../../../../components/Sticky";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";

import { AssociationLinkingModalProvider, useObjectAssociations } from "../../../../../../recordAssociations";
import { useGetObjectMap } from "../../../../../../objects/hooks";
import { useWorkspaceContext } from "../../../../../hooks/useWorkspaceContext";
import { WORKSPACE_SHARABLE_RECORD_TYPES } from "../../../../../utils";

import { ResourcesAssociationPanel } from "./ResourcesAssociationPanel";
import { ResourcesNotesPanel } from "./ResourcesNotesPanel";

interface WorkspaceResourcesTabsProps {
	currentTab: number;
	onTabChange: (index: number) => void;
}

export function WorkspaceResourcesTabs({ currentTab, onTabChange }: WorkspaceResourcesTabsProps) {
	const objectMap = useGetObjectMap();

	const { workspaceRecord, workspaceObject } = useWorkspaceContext();

	const {
		objectAssociations: { prioritizedAssociations: tabbedAssociations },
		isLoading: isLoadingAssociations,
	} = useObjectAssociations(workspaceObject?._id, WORKSPACE_SHARABLE_RECORD_TYPES);

	const [associationCounts, setAssociationCounts] = useState<Record<number, number>>({});

	const updateAssociationCount = useCallback((associationId: number, count: number) => {
		setAssociationCounts((prevCounts) => ({ ...prevCounts, [associationId]: count }));
	}, []);

	const [notesCount, setNotesCount] = useState(0);

	return workspaceRecord && !isLoadingAssociations ? (
		<AssociationLinkingModalProvider>
			<PanelTabs selectedIndex={currentTab} onChange={onTabChange}>
				<div className="flex h-full max-h-full flex-col">
					<Sticky priority={3} height={39} className="bg-c_bg_01 z-20">
						<PanelTabList>
							{tabbedAssociations.map((associationDef) => {
								const count = associationCounts[associationDef.id] || 0;

								const { id, label } = associationDef.connectedObject;
								const associationObject = objectMap.get(id);

								return (
									<PanelTab icon={associationObject?._icon} key={associationDef.id} count={count}>
										{capitalizeString(label)}
									</PanelTab>
								);
							})}
							<PanelTab count={notesCount}>Notes</PanelTab>
						</PanelTabList>
					</Sticky>

					<PanelTabPanels className="flex h-full max-h-full overflow-hidden">
						{tabbedAssociations.map((associationDef) => {
							return (
								<ResourcesAssociationPanel
									key={associationDef.id}
									objectAssociation={associationDef}
									onUpdateCount={updateAssociationCount}
								/>
							);
						})}
						<ResourcesNotesPanel onUpdateCount={setNotesCount} />
					</PanelTabPanels>
				</div>
			</PanelTabs>
		</AssociationLinkingModalProvider>
	) : (
		<div className="border-c_border_regular mr-8 flex h-[39px] border-b">
			{[...Array(5)].map((_, index) => (
				<div key={index} className="px-3 py-1">
					<Skeleton className="w-22 h-6" />
				</div>
			))}
		</div>
	);
}
