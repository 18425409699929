import { skipToken } from "@reduxjs/toolkit/query";

import { useGetRecordsQuery } from "../../records";
import { useChannelMemberRecordIds } from "./useChannelMemberRecordIds";

export function useChannelMemberRecords(channelArn?: string) {
	const memberRecordIds = useChannelMemberRecordIds(channelArn);

	const { data: memberRecords } = useGetRecordsQuery(memberRecordIds.length > 0 ? memberRecordIds : skipToken);
	return memberRecords;
}
