import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { tw } from "@salesdesk/daisy-ui";

interface CornerDetails {
	stickyCornerClass: string;
	xDirection: number;
	yDirection: number;
}

interface Corners {
	topLeft: CornerDetails;
	topRight: CornerDetails;
	bottomLeft: CornerDetails;
	bottomRight: CornerDetails;
}

const corners: Corners = {
	topLeft: {
		stickyCornerClass: tw`right-0 bottom-0`,
		xDirection: -1,
		yDirection: -1,
	},
	topRight: {
		stickyCornerClass: tw`left-0 bottom-0`,
		xDirection: 1,
		yDirection: -1,
	},
	bottomLeft: {
		stickyCornerClass: tw`right-0 top-0`,
		xDirection: -1,
		yDirection: 1,
	},
	bottomRight: {
		stickyCornerClass: tw`left-0 top-0`,
		xDirection: 1,
		yDirection: 1,
	},
};

interface ResizeBoxProps {
	initialWidth: number;
	initialHeight: number;
	onResize: (width: number, height: number) => void;
}

export function ImageResizeBox({ initialWidth, initialHeight, onResize }: ResizeBoxProps) {
	const [width, setWidth] = useState(initialWidth);
	const [height, setHeight] = useState(initialHeight);
	const [currentStickyCornerClass, setCurrentStickyCornerClass] = useState(tw`top-0 left-0`);

	const widthRef = useRef(width);
	const heightRef = useRef(height);

	useEffect(() => {
		widthRef.current = initialWidth;
		heightRef.current = initialHeight;
		setWidth(initialWidth);
		setHeight(initialHeight);
	}, [initialWidth, initialHeight]);

	const handleMouseDown = (cornerKey: keyof Corners) => (e: React.MouseEvent) => {
		const cornerDetails = corners[cornerKey];
		setCurrentStickyCornerClass(cornerDetails.stickyCornerClass);
		e.preventDefault();
		const startX = e.clientX;
		const startY = e.clientY;
		const handleMouseMove = (e: MouseEvent) => {
			let dx = e.clientX - startX;
			let dy = e.clientY - startY;
			dx *= cornerDetails.xDirection;
			dy *= cornerDetails.yDirection;
			const ratio = initialWidth / initialHeight;
			if (ratio > 1) {
				dy = dx / ratio;
			} else {
				dx = dy * ratio;
			}
			widthRef.current = Math.max(initialWidth + dx, 0);
			heightRef.current = Math.max(initialHeight + dy, 0);
			setWidth(widthRef.current);
			setHeight(heightRef.current);
		};
		const handleMouseUp = () => {
			onResize(Math.round(widthRef.current), Math.round(heightRef.current));
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	const baseHandleClass = tw`bg-c_bg_01 border-c_action_01 absolute h-2 w-2 rounded-full border z-10`;
	return (
		<div
			className={clsx("border-c_action_01 absolute box-border border bg-transparent", currentStickyCornerClass)}
			style={{ width, height }}
		>
			<div
				className={clsx(baseHandleClass, "left-0 top-0 -ml-1 -mt-1 cursor-nwse-resize")}
				onMouseDown={handleMouseDown("topLeft")}
			/>
			<div
				className={clsx(baseHandleClass, "right-0 top-0 -mr-1 -mt-1 cursor-nesw-resize")}
				onMouseDown={handleMouseDown("topRight")}
			/>
			<div
				className={clsx(baseHandleClass, "bottom-0 left-0 -mb-1 -ml-1 cursor-nesw-resize")}
				onMouseDown={handleMouseDown("bottomLeft")}
			/>
			<div
				className={clsx(baseHandleClass, "bottom-0 right-0 -mb-1 -mr-1 cursor-nwse-resize")}
				onMouseDown={handleMouseDown("bottomRight")}
			/>
		</div>
	);
}
