import { useEffect } from "react";
import { useImportContext } from "./useImportContext";
import { PATHS, useStableNavigate } from "../../../routes";

export function useRedirectIfNoImportMappings() {
	const navigate = useStableNavigate();
	const { sdObject, importMappings, importMappingsIsLoading } = useImportContext();

	const needsRedirect = importMappings === undefined && !importMappingsIsLoading;
	useEffect(() => {
		if (needsRedirect) {
			navigate(PATHS.IMPORT(sdObject), { replace: true });
		}
	}, [sdObject, navigate, needsRedirect]);

	return needsRedirect;
}
