import { SDApi } from "../../api";
import { UserPreferences } from "@salesdesk/salesdesk-schemas";

export const userPreferencesApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getMyPreferences: builder.query<UserPreferences, void>({
				query: () => ({
					url: `/me/preferences`,
					method: "GET",
				}),
				providesTags: () => ["UserPreferences"],
			}),
			patchMyPreferences: builder.mutation<void, Partial<UserPreferences>>({
				query: (body) => ({
					url: `/me/preferences`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: () => ["UserPreferences"],
			}),
		};
	},
});

export const { useGetMyPreferencesQuery, usePatchMyPreferencesMutation } = userPreferencesApi;
