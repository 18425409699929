import { useMemo, useState } from "react";

import { SDObject } from "@salesdesk/salesdesk-schemas";
import { Table } from "../../../../Table";
import { useGetObjectsQuery } from "../../../../objects/api/objectsApi";
import { ObjectTableRow } from "../types";
import { ObjectManagerControlColumn } from "./ObjectManagerControlColumn";
import { DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID, getColumns, getRows, SD_OBJECT_COLUMN_ID } from "../utils";
import { DeleteObjectConfirmDialog } from "./DeleteObjectConfirmDialog";
import { SkeletonTransition } from "../../../../../components/Skeleton/SkeletonTransition";

export function ObjectManagerTable() {
	const [objectToDelete, setObjectToDelete] = useState<SDObject>();
	const { data: sdObjects } = useGetObjectsQuery();

	const columns = useMemo(() => getColumns(), []);
	const rows = useMemo(() => getRows(sdObjects), [sdObjects]);

	const controlColumn = useMemo(
		() => ({
			width: 96,
			render: (row: ObjectTableRow) => {
				const sdObjectColumn = row[SD_OBJECT_COLUMN_ID];
				if (sdObjectColumn === null || typeof sdObjectColumn !== "object" || !("_id" in sdObjectColumn)) {
					return undefined;
				}
				return (
					<ObjectManagerControlColumn
						sdObject={sdObjectColumn}
						onRemoveClick={(sdObject: SDObject) => setObjectToDelete(sdObject)}
					/>
				);
			},
		}),
		[]
	);

	return (
		<>
			<SkeletonTransition className="h-full overflow-hidden pb-8">
				<Table
					columns={columns}
					rows={rows}
					controlColumn={controlColumn}
					defaultColumnWidthByColumnId={DEFAULT_COLUMN_WIDTH_BY_COLUMN_ID}
				/>
			</SkeletonTransition>
			<DeleteObjectConfirmDialog objectToDelete={objectToDelete} onClose={() => setObjectToDelete(undefined)} />
		</>
	);
}
