import { useEffect, useMemo, useRef, useState } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { rsr } from "@salesdesk/salesdesk-schemas";
import { Icon, AlertBubble } from "@salesdesk/daisy-ui";

import { DebouncedSearchbar } from "../../../inputs";
import { useInfiniteScrollContainer } from "../../../../hooks/ui";
import { useInfiniteRecordSearch } from "../..";
import { FavoritePopoverItem } from "./FavoritePopoverItem";
import { FavoritePopoverLoading } from "./FavoritePopoverLoading";

const CONTAINER_WIDTH = 328;
const CONTAINER_HEIGHT = 450;
const PAGE_LIMIT = 100;

interface FavoritePopoverContainerProps {
	favoriteRecordIds?: number[];
}

export function FavoritePopoverContainer({ favoriteRecordIds }: FavoritePopoverContainerProps) {
	const [deletedFavoriteRecordIds, setDeletedFavoriteRecordIds] = useState<number[]>([]);
	const [initialLoad, setInitialLoad] = useState(true);

	const totalFavorites = (favoriteRecordIds?.length ?? 0) - deletedFavoriteRecordIds.length;

	const {
		updateSearchParams,
		sdRecords: favoriteRecords,
		loadNextPage,
		isLoadingNewSearchParams,
	} = useInfiniteRecordSearch({
		limit: PAGE_LIMIT,
	});

	useEffect(() => {
		if (!isLoadingNewSearchParams) {
			setInitialLoad(false);
		}
	}, [isLoadingNewSearchParams]);

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { containerBottomRef } = useInfiniteScrollContainer({
		containerRef: scrollContainerRef,
		verticalOffset: 50,
		onBottomReached: () => {
			loadNextPage();
		},
	});

	const [search, setSearch] = useState("");

	useEffect(() => {
		if (favoriteRecordIds !== undefined) {
			updateSearchParams(getFavoriteRecordsSearchParams(favoriteRecordIds, search));
		}
	}, [favoriteRecordIds, search, updateSearchParams]);

	const sortedFavoriteRecords = useMemo(() => {
		if (favoriteRecordIds === undefined) {
			return undefined;
		}
		if (totalFavorites > PAGE_LIMIT) {
			// The pagination won't work correctly if the sorting is done on the client side, so let's disable the sorting if
			// there are more records that one page can contain.
			return favoriteRecords;
		}
		const indexById = new Map<number, number>();
		for (let index = 0; index < favoriteRecordIds.length; index++) {
			indexById.set(favoriteRecordIds[index], index);
		}
		return [...favoriteRecords].sort((a, b) => (indexById.get(b._id) ?? 0) - (indexById.get(a._id) ?? 0));
	}, [favoriteRecordIds, favoriteRecords, totalFavorites]);

	const filteredFavoriteRecords = useMemo(() => {
		return sortedFavoriteRecords?.filter((favoriteRecord) => !deletedFavoriteRecordIds.includes(favoriteRecord._id));
	}, [sortedFavoriteRecords, deletedFavoriteRecordIds]);

	return (
		<div className="flex flex-col justify-stretch" style={{ width: CONTAINER_WIDTH, maxHeight: CONTAINER_HEIGHT }}>
			<div className="mx-6 my-3 flex items-center gap-2">
				<Icon icon={ICONS.star} className="text-c_icon_regular flex items-center" variant="fill" />
				<div className="label-sm">Favorites</div>
				{totalFavorites ? <AlertBubble alertCount={totalFavorites} variant="primary" /> : null}
			</div>
			<div className="mx-6 mb-3">
				<DebouncedSearchbar
					onChange={setSearch}
					isCollapsible={false}
					isClearable={false}
					isLoading={isLoadingNewSearchParams}
					wrapperClass="w-full"
				/>
			</div>

			<div ref={scrollContainerRef} className="flex-grow overflow-y-auto">
				{!initialLoad && filteredFavoriteRecords?.length === 0 ? (
					<div className="text-c_text_disabled text-body-sm my-3 text-center">
						{search || isLoadingNewSearchParams ? "No favorites matching your search." : "There are no favorites yet."}
					</div>
				) : null}
				{!initialLoad &&
					filteredFavoriteRecords?.map((favoriteRecord) => (
						<FavoritePopoverItem
							key={favoriteRecord._id}
							favoriteRecord={favoriteRecord}
							onFavoriteToggleClick={(removedFavorite) => {
								setDeletedFavoriteRecordIds([...deletedFavoriteRecordIds, removedFavorite._id]);
							}}
						/>
					))}
				{initialLoad ? <FavoritePopoverLoading /> : null}
				<div ref={containerBottomRef} />
			</div>
		</div>
	);
}

export function getFavoriteRecordsSearchParams(favoriteRecordIds: number[], searchQuery: string) {
	const query = rsr
		.query()
		.and(rsr.equals("_deleted", false))
		.and(rsr.equalsAnyOf("id", favoriteRecordIds.length ? favoriteRecordIds : [-1]));

	if (searchQuery != null && searchQuery.length > 0) {
		query.and(rsr.matchAllPrefix(searchQuery));
	}

	return {
		query: query.buildQuery(),
	};
}
