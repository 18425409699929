import { FIELD_COMPONENT_TYPES, FieldComponentName } from "@salesdesk/salesdesk-ui";

import { FIELD_TYPES } from "../../utils";
import { mFileFieldDef } from "../file_field_def";

export class mVideoFieldDef extends mFileFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.VIDEO_FILE.name];
	static override defaultComponentType: FieldComponentName = mVideoFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mVideoFieldDef.defaultComponentType;
		this._mimeTypesStr = "video/mp4:mp4,m4v; video/webm:webm; video/m3u8:m3u8";
		super.loadMimeTypes(this._mimeTypesStr);
		this._icon = FIELD_TYPES.VIDEO.icon;
	}

	override get type() {
		return FIELD_TYPES.VIDEO.name;
	}

	override requiresProcessing() {
		return true;
	}

	override get supportedComponentTypes() {
		return mVideoFieldDef.supportedComponentTypes;
	}

	override set mimeTypes(mimeTypes) {
		throw Error(`This method is not supported in "${FIELD_TYPES.VIDEO}" type.`);
	}

	override get mimeTypes() {
		return this._mimeTypes;
	}

	override supportsCardView() {
		return false;
	}

	override supportsPreview() {
		return true;
	}

	override supportsFormatDescription() {
		return false;
	}
}
