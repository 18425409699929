import { useEffect, useRef } from "react";
import { AudioTrack as IAudioTrack } from "twilio-video";

import { useVideoCallLogicContext, useVideoCallMediaContext } from "../../../../VideoCallProvider";
import { MUTED_VOLUME } from "../..";
import { useAudioTrackVolume } from "../../hooks/useAudioTrackVolume";

interface ParticipantAudioTracksProps {
	track: IAudioTrack;
	participantIdentity?: string;
	mount?: boolean;
}

export function ParticipantAudioTrack({ track, participantIdentity, mount = true }: ParticipantAudioTracksProps) {
	const { updateParticipantVolume } = useVideoCallLogicContext();
	const { localAudioOutputDeviceId } = useVideoCallMediaContext();
	const audioEl = useRef<HTMLAudioElement>();

	const { volume, isTrackEnabled } = useAudioTrackVolume(track);

	useEffect(() => {
		if (!participantIdentity) {
			return;
		}

		updateParticipantVolume(participantIdentity, isTrackEnabled ? volume : MUTED_VOLUME);
	}, [participantIdentity, volume, isTrackEnabled, updateParticipantVolume]);

	useEffect(() => {
		if (!mount) {
			return;
		}

		audioEl.current = track.attach();
		audioEl.current.setAttribute("data-audio-track-name", track.name);
		document.body.appendChild(audioEl.current);

		return () =>
			track.detach().forEach((el) => {
				el.remove();

				// This addresses a Chrome issue where the number of WebMediaPlayers is limited.
				// See: https://github.com/twilio/twilio-video.js/issues/1528
				el.srcObject = null;
			});
	}, [track, mount]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		audioEl.current?.setSinkId?.(localAudioOutputDeviceId);
	}, [localAudioOutputDeviceId]);

	return null;
}
