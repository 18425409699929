import { ICONS } from "@salesdesk/salesdesk-ui";
import { PATHS } from "../../../../../routes";
import { DisplayIf } from "../../../../../auth";
import { AbilityAction, AbilitySubject } from "@salesdesk/salesdesk-schemas";
import { Link } from "react-router-dom";
import { OBJECT_DESIGNER_TABS } from "../../ObjectDesigner/types";
import { Button } from "@salesdesk/daisy-ui";
import { ObjectManagerTable } from "./ObjectManagerTable";
import { SettingsContainer } from "../../../SettingsContainer";

export function ObjectManagerPage() {
	return (
		<SettingsContainer
			breadcrumbs={[
				{
					icon: ICONS.settings,
					node: "Settings",
				},
				{
					node: "Object manager",
					link: PATHS.OBJECT_MANAGER(),
				},
			]}
		>
			<div className="flex h-full flex-col">
				<div className="flex w-full justify-end px-6 py-4">
					<DisplayIf can={[AbilityAction.Create, AbilitySubject.Object]}>
						<Link to={PATHS.OBJECT_DESIGNER(undefined, { tab: OBJECT_DESIGNER_TABS.settings })}>
							<Button variant="primary" startIcon={ICONS.plus} size="sm">
								New object
							</Button>
						</Link>
					</DisplayIf>
				</div>
				<div className="flex w-full grow flex-col justify-end gap-5 overflow-y-auto pl-6">
					<ObjectManagerTable />
				</div>
			</div>
		</SettingsContainer>
	);
}
