import { useEffect, useMemo, useRef } from "react";
import clsx from "clsx";

import { TooltipDelayGroup } from "@salesdesk/daisy-ui";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { PATHS } from "../../../../../../../routes";
import { Breadcrumbs } from "../../../../../../../components/Breadcrumbs/Breadcrumbs";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { useRecordDetailsContext } from "../../../hooks/useRecordDetailsContext";
import { GeneralRecordActions, RecordActionButton } from "../../common";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { useGetWorkspaceBaseBreadcrumbs } from "../../../../../../workspaces/hooks/useGetWorkspaceBaseBreadcrumbs";

export function RecordDetailTopBar() {
	const workspaceId = useGetContextWorkspaceId();

	const { sdObject, sdRecord, isLoading } = useRecordDetailsContext();

	const recordName = sdObject && sdRecord ? getSDRecordName(sdObject, sdRecord) : "";

	const topBarRef = useRef<HTMLDivElement>(null);
	const hasAutoFocused = useRef(true);

	/*
		Ensures initial focus on the top bar to fix react router bug where
		a navbar item is focused instead causing an unecessary tooltip to open.

		This also improves keyboard navigation by starting at the
		top of the record detail page on navigation.
	*/
	useEffect(() => {
		setTimeout(() => {
			topBarRef.current?.focus();
			hasAutoFocused.current = false;
		}, 0);
	}, []);

	const baseWorkspaceBreadcrumbs = useGetWorkspaceBaseBreadcrumbs(isLoading);

	const crumbs = useMemo(() => {
		const inWorkspace = workspaceId != null;

		const breadcrumbs = [
			(!isLoading || !inWorkspace) && sdObject
				? {
						node: sdObject._pluralName,
						icon: sdObject._icon,
						link: inWorkspace
							? PATHS.WORKSPACE_RECORD_BOARD(workspaceId, sdObject)
							: PATHS.OBJECT_RECORD_BOARD(sdObject),
					}
				: { node: <Skeleton className="h-5 w-28" /> },
			{ node: isLoading || !recordName ? <Skeleton className="h-5 w-48" /> : recordName },
		];

		if (inWorkspace) {
			return [...baseWorkspaceBreadcrumbs, ...breadcrumbs];
		}

		return breadcrumbs;
	}, [baseWorkspaceBreadcrumbs, isLoading, recordName, sdObject, workspaceId]);

	return (
		<div
			className={clsx("flex min-h-[70px] items-center py-4", workspaceId == null ? "px-6" : "pt-8")}
			ref={topBarRef}
			tabIndex={hasAutoFocused.current ? 0 : -1}
		>
			<TooltipDelayGroup>
				<div className="flex items-center">
					<Breadcrumbs crumbs={crumbs} canCopyLink={!isLoading} />
				</div>
				<div className="ml-auto flex items-center gap-2">
					{isLoading ? (
						<Skeleton className="h-6 w-40" />
					) : (
						<>
							<RecordActionButton />
							<GeneralRecordActions />
						</>
					)}
				</div>
			</TooltipDelayGroup>
		</div>
	);
}
