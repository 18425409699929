/* Provides single list of all ability subjects when using ability.can(..., AbilitySubject, ....) */
export const AbilitySubject = Object.freeze({
	All: "all", // This is a Casl inbuilt subject indicating all subjects
	User: "User",
	UserAdmin: "UserAdmin",
	Role: "Role",
	RoleAdmin: "RoleAdmin",
	Object: "Object",
	ObjectAdmin: "ObjectAdmin",
	ObjectAssociation: "ObjectAssociation",
	ObjectAssociationAdmin: "ObjectAssociationAdmin",
	Record: "Record",
	RecordAssociation: "RecordAssociation",
	Comment: "Comment",
	CommentReaction: "CommentReaction",
	Note: "Note",
	NoteReaction: "NoteReaction",
	NotificationRule: "NotificationRule",
	WebSocketConnection: "WebSocketConnection",
	Favorite: "Favorite",
	Watch: "Watch",
	Bookmark: "Bookmark",
	Lead: "Lead",
	File: "File",
	SentimentAnalysis: "SentimentAnalysis",
	CoachingAdvice: "CoachingAdvice",
	TenantSettings: "TenantSettings",
	Import: "Import",
	ImportJob: "ImportJob",
	Event: "Event",
	FullMeetingUI: "FullMeetingUI",
	SDApiKey: "SDApiKey",
	MessageChannel: "MessageChannel",

	// Video Calling
	VideoCall: "VideoCall",
	VideoCallScreen: "VideoCallScreen",
	VideoCallTask: "VideoCallTask",
	VideoCallIssue: "VideoCallIssue",
	VideoCallFile: "VideoCallFile",
});

export type AbilitySubject = (typeof AbilitySubject)[keyof typeof AbilitySubject];
