import { TranscriptSentiment, TranscriptSentimentType } from "../../../types";
import { TranscriptItem } from "@salesdesk/salesdesk-schemas";

const DEFAULT_SENTIMENT_TYPE: TranscriptSentimentType = "NEUTRAL";

export function getLongestSentimentType(
	transcriptItem: TranscriptItem,
	userTranscriptSentiments: TranscriptSentiment[]
): TranscriptSentimentType {
	if (!userTranscriptSentiments) {
		return DEFAULT_SENTIMENT_TYPE;
	}
	const { startTstampMillis, endTstampMillis } = transcriptItem;
	const startTstamp = startTstampMillis / 1000;
	const endTstamp = endTstampMillis / 1000;
	// Get all the sentiments that overlaps with the current transcript item
	const currentTranscriptSentiments = userTranscriptSentiments.filter(
		(sentiment) => sentiment.startTstamp < startTstamp && sentiment.endTstamp > endTstamp
	);
	// Calculate the total duration of the overlap for each sentiment
	const sentimentDurationPerType = new Map<TranscriptSentimentType, number>();
	for (const sentiment of currentTranscriptSentiments) {
		const type = sentiment.sentiment.Sentiment;
		if (!type) {
			continue;
		}
		const start = Math.max(sentiment.startTstamp, endTstamp);
		const end = Math.min(sentiment.endTstamp, startTstamp);
		const duration = start - end;
		const currentDuration = sentimentDurationPerType.get(type) ?? 0;
		sentimentDurationPerType.set(type, currentDuration + duration);
	}
	// Order them by duration and take the first one
	const entries = [...sentimentDurationPerType.entries()];
	if (!entries.length) {
		return DEFAULT_SENTIMENT_TYPE;
	}
	entries.sort((a, b) => b[1] - a[1]);
	return entries[0][0] ?? DEFAULT_SENTIMENT_TYPE;
}

export async function getTranscriptItemHash(transcriptItem: TranscriptItem): Promise<string> {
	const encoder = new TextEncoder();
	const data = encoder.encode(transcriptItem.text + transcriptItem.startTstampMillis + transcriptItem.endTstampMillis);
	const hash = await window.crypto.subtle.digest("SHA-256", data);
	return Array.from(new Uint8Array(hash))
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");
}
