import { PATHS } from "../../../../routes";
import { Divider } from "../../../../components/Divider/Divider";
import { NotificationCollapsibleLinkSection } from "./NotificationCollapsibleLinkSection";
import { UserPreferences } from "@salesdesk/salesdesk-schemas";
import { BooleanFieldVariant, EditBooleanField } from "../../../fields";
import { FormProvider, useForm } from "react-hook-form";
import { FormFieldSet } from "../../../forms";
import { useWebPrincipal } from "../../../../auth";
import { useToast } from "../../../Toasts";
import { usePatchMyPreferencesMutation } from "../../../users/api/userPreferencesApi";
import { StickyFormControls } from "../../../records";
import { useId } from "react";

interface NotificationFormProps {
	userPreferences: UserPreferences;
}

interface NotificationFormData extends Record<string, any> {
	emailNotifications: boolean;
	browserNotifications: boolean;
}

export function NotificationForm({ userPreferences }: NotificationFormProps) {
	const [patchMyPreferences, { isLoading }] = usePatchMyPreferencesMutation();

	const principal = useWebPrincipal();
	const formId = useId();

	const defaultValues: NotificationFormData = {
		emailNotifications: userPreferences.notification_channel_enabled_email,
		browserNotifications: userPreferences.notification_channel_enabled_browser,
	};
	const formMethods = useForm<NotificationFormData>({
		mode: "onChange",
		defaultValues,
	});
	const { control, reset, handleSubmit } = formMethods;

	const toast = useToast();

	const onSubmit = async (data: NotificationFormData) => {
		patchMyPreferences({
			notification_channel_enabled_email: data.emailNotifications,
			notification_channel_enabled_browser: data.browserNotifications,
		})
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "notification_preferences_updated" });
				reset(data);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "notification_preferences_updated" });
			});
	};

	return (
		<form id={formId} className="flex flex-col gap-4 py-6" onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<div className="text-h4">General</div>
				<div className="text-body-sm text-c_text_secondary">
					Manage your preferences for receiving notifications across various platforms. Note that you will still receive
					in-app notifications.
				</div>
				<FormFieldSet
					control={control}
					name="emailNotifications"
					label="Email notifications"
					helperText={
						<>
							Receive notifications to your email <span className="text-label-sm">{principal.Email}</span>
						</>
					}
					isBoolean
				>
					{({ field: { ref, ...restField } }) => (
						<EditBooleanField inputRef={ref} {...restField} variant={BooleanFieldVariant.TOGGLE_SWITCH} />
					)}
				</FormFieldSet>
				<FormFieldSet
					control={control}
					name="browserNotifications"
					label="Browser notifications"
					helperText="Get notifications directly in your browser."
					isBoolean
				>
					{({ field: { ref, ...restField } }) => (
						<EditBooleanField inputRef={ref} {...restField} variant={BooleanFieldVariant.TOGGLE_SWITCH} />
					)}
				</FormFieldSet>
				<Divider className="my-2" />
				<NotificationCollapsibleLinkSection
					title="Watching"
					description="Manage and review the records you've chosen to watch"
					linkLabel="Open watchlist"
					linkTo={PATHS.SETTINGS_WATCHLIST()}
				/>
				<Divider className="my-2" />
				<NotificationCollapsibleLinkSection
					title="Custom notifications"
					description="Create custom notification rules to receive alerts based on specific triggers"
					linkLabel="Open notification rules"
					linkTo={PATHS.SETTINGS_NOTIFICATION_RULES()}
				/>
				<StickyFormControls formId={formId} isLoading={isLoading} defaultValues={defaultValues} />
			</FormProvider>
		</form>
	);
}
