import { RecordAssociationCreatedParams } from "@salesdesk/salesdesk-model";

import { useEventContext } from "../../../hooks/useEventContext";
import { useGetRecordWithObjectByRecordId } from "../../../../../hooks";
import { EventRecordLink } from "../EventRecordLink";

export function RecordAssociationPayload() {
	const { event } = useEventContext();

	const otherRecordId = (event.params as Partial<RecordAssociationCreatedParams>)?.otherRecord?.id;

	const { sdObject, sdRecord, isLoading } = useGetRecordWithObjectByRecordId(otherRecordId);

	if (otherRecordId === undefined) {
		return null;
	}

	return (
		<EventRecordLink sdRecord={sdRecord} sdObject={sdObject} workspaceId={event.workspace_id} isLoading={isLoading} />
	);
}
