import { Filter } from "../../../../../../records";

import { SDObject, getSDObjectFieldByName } from "@salesdesk/salesdesk-schemas";
import { mInteractionMeetingDef, mTaskDef } from "@salesdesk/salesdesk-model";

import { OWNER_ID_METADATA_FIELD_ID } from "../../../../../utils";
import { UserFilterDetails } from "../types";

const DEFAULT_USER_FILTER: UserFilterDetails = {
	title: "Owner",
	filterTarget: OWNER_ID_METADATA_FIELD_ID,
	filterId: "ss_is",
};

export function getUserFilterDetailsForBoard(sdObject?: SDObject): UserFilterDetails {
	switch (sdObject?._id) {
		case mTaskDef.ID: {
			const assigneeField = getSDObjectFieldByName(sdObject, mTaskDef.ASSIGNEE_FIELD_NAME);

			if (!assigneeField) {
				return DEFAULT_USER_FILTER;
			}

			return {
				filterTarget: String(assigneeField._id),
				filterId: "ms_contains_any",
				title: assigneeField._displayName,
			};
		}
		case mInteractionMeetingDef.ID: {
			const participantsField = getSDObjectFieldByName(sdObject, mInteractionMeetingDef.PARTICIPANTS_FIELD_NAME);

			if (!participantsField) {
				return DEFAULT_USER_FILTER;
			}

			return {
				filterTarget: String(participantsField._id),
				filterId: "ms_contains_any",
				title: participantsField._displayName,
			};
		}
		default: {
			return DEFAULT_USER_FILTER;
		}
	}
}

export function filterMatchesUserFilterDetails(filter: Filter, userFilterDetails: UserFilterDetails): boolean {
	return filter.filterTarget === userFilterDetails.filterTarget && filter.filterId === userFilterDetails.filterId;
}
