import { useDevices, useVideoCallMediaContext } from "../../../VideoCallProvider";
import { DeviceSelect } from "./DeviceSelect";

export function SpeakerSelect() {
	const { audioOutputDevices } = useDevices();
	const { localAudioOutputDeviceId, setLocalAudioOutputDeviceId } = useVideoCallMediaContext();

	return (
		<DeviceSelect
			devices={audioOutputDevices}
			currentDeviceId={localAudioOutputDeviceId}
			onDeviceChange={setLocalAudioOutputDeviceId}
			name={"speaker"}
		/>
	);
}
