import { useCallback, useEffect, useState } from "react";

import { rsr, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { SkeletonTransition } from "../../../../../../../components/Skeleton/SkeletonTransition";
import { APP_CONFIG } from "../../../../../../../app/app_config";
import {
	EMPTY_FILTERS,
	FilterData,
	mapToSearchQuery,
	ObjectFilteringPopover,
	ObjectSortingPopover,
	RecordModalTable,
	RecordPreviewDialog,
	SortingDetails,
	useInfiniteRecordSearch,
} from "../../../../../../records";
import { DebouncedSearchbar } from "../../../../../../inputs";
import { MenuItem } from "../../../../../../menu";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";

interface SelectableRecordsListProps {
	sdObject: SDObject;
	selectedRecords: SDRecord[];
	setSelectedRecords: (records: SDRecord[]) => void;
	channelWorkspaceId?: number;
}

export function SelectableRecordsList({
	sdObject,
	selectedRecords,
	setSelectedRecords,
	channelWorkspaceId,
}: SelectableRecordsListProps) {
	const [records, setRecords] = useState<SDRecord[]>([]);

	const [filter, setFilter] = useState<FilterData>(EMPTY_FILTERS);
	const [sorting, setSorting] = useState<SortingDetails[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>();

	const currentWorkspaceId = useGetContextWorkspaceId();

	const workspaceId = currentWorkspaceId ?? channelWorkspaceId;

	const { updateSearchParams, loadNextPage, isLoadingNewSearchParams, isLoadingNextPage } = useInfiniteRecordSearch({
		limit: APP_CONFIG.maxLocalSearchResults,
		sdRecords: records,
		onSDRecordsChange: setRecords,
		sdObjectFilter: sdObject._id,
	});

	useEffect(() => {
		const query = rsr.query().and(rsr.equals("_deleted", false));

		if (searchQuery) {
			query.and(rsr.matchAllPrefix(searchQuery));
		}

		if (workspaceId !== undefined) {
			query.and(rsr.isSharedWithWorkspace(workspaceId));
		}

		if (filter.filters.length) {
			const filterClauses = filter.filters.flatMap(mapToSearchQuery);
			if (filter.type === "AND") {
				filterClauses.forEach((clause) => query.and(clause));
			} else {
				filterClauses.forEach((clause) => query.or(clause));
			}
		}

		updateSearchParams({
			query: query.buildQuery(),
			sort: sorting.map((value) => ({ [value.fieldId]: { order: value.order } })),
		});
	}, [updateSearchParams, filter, sorting, searchQuery, workspaceId]);

	const isFilterAndSortingDisabled =
		records.length === 0 && !isLoadingNewSearchParams && !filter.filters.length && !sorting.length;

	const [recordIdToPreview, setRecordIdToPreview] = useState<number>();

	const getMenuItems = useCallback(
		(record: SDRecord): MenuItem[] => [
			{
				icon: ICONS.eye,
				type: "button",
				onClick: () => {
					setRecordIdToPreview(record._id);
				},
				text: "Preview record",
			},
		],
		[]
	);

	return (
		<>
			<div className="flex h-full w-full flex-col gap-6">
				<div className="flex justify-between">
					<div className="flex gap-2">
						<ObjectFilteringPopover
							disabled={isFilterAndSortingDisabled}
							value={filter}
							onChange={setFilter}
							sdObject={sdObject}
						/>
						<ObjectSortingPopover
							disabled={isFilterAndSortingDisabled}
							value={sorting}
							onChange={setSorting}
							sdObject={sdObject}
						/>
					</div>
					<DebouncedSearchbar onChange={setSearchQuery} />
				</div>
				<div>
					<SkeletonTransition>
						<div className="flex h-[320px] w-full flex-col overflow-hidden">
							{!isLoadingNewSearchParams && records.length === 0 ? (
								<div className="text-body text-c_text_secondary my-8 text-center">
									{filter.filters.length || searchQuery
										? `No ${sdObject._pluralName} match the selected filters.`
										: `There are no ${sdObject._pluralName}.`}
								</div>
							) : (
								<RecordModalTable
									records={records}
									sdObject={sdObject}
									selectedRecords={selectedRecords}
									setSelectedRecords={setSelectedRecords}
									onBottomReached={loadNextPage}
									isLoadingNextPage={isLoadingNextPage}
									isLoadingRecords={isLoadingNewSearchParams}
									getMenuItems={getMenuItems}
									containerRightPadding={0}
								/>
							)}
						</div>
					</SkeletonTransition>
				</div>
			</div>
			{recordIdToPreview !== undefined ? (
				<RecordPreviewDialog recordId={recordIdToPreview} onClose={() => setRecordIdToPreview(undefined)} />
			) : undefined}
		</>
	);
}
