import clsx from "clsx";
import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { tw } from "../../../../../../utils/tailwind-helpers";

interface WorkspaceNavLinkProps {
	id?: string;
	link?: string;
	end?: boolean;
	variant?: "primary" | "secondary";
}

export function WorkspaceNavLink({
	id,
	link,
	end,
	children,
	variant = "primary",
}: PropsWithChildren<WorkspaceNavLinkProps>) {
	const baseClasses = clsx(
		variant === "primary" ? tw`text-label-nav` : tw`text-label-sm text-c_text_secondary`,
		tw`text-label-nav rounded-full px-4 py-2 transition-all`
	);

	if (!link) {
		return (
			<div id={id} className={clsx(baseClasses, "cursor-not-allowed opacity-50")}>
				{children}
			</div>
		);
	}

	return (
		<NavLink
			id={id}
			to={link}
			end={end}
			className={({ isActive }) =>
				clsx(
					baseClasses,
					"focus-visible:ring-c_action_focus",
					isActive ? "bg-c_bg_01" : "hover:bg-c_bg_05 bg-transparent"
				)
			}
		>
			{children}
		</NavLink>
	);
}
