import { forwardRef, KeyboardEventHandler, useCallback } from "react";
import { PickerTextInputProps } from "../../types";
import clsx from "clsx";
import {
	mapDateTimeLocalIsoStringToUnixTimestampOrNull,
	mapUnixTimestampToDateTimeLocalIsoString,
} from "@salesdesk/salesdesk-utils";
import { DateTimeFieldVariant } from "../../../fields";
import { applyAndCancelKeyCapturing } from "@salesdesk/daisy-ui";

const DATE_INPUT_WIDTH = 112;
const DATETIME_INPUT_WIDTH = 170;

export const DateTimeTextInput = forwardRef<HTMLInputElement, PickerTextInputProps<number>>(
	(
		{
			id,
			variant = DateTimeFieldVariant.DATE,
			value,
			onChange,
			onFocus = () => undefined,
			onBlur = () => undefined,
			onCancel,
			onApply,
			calenderIcon = null,
			min,
			max,
			disabled,
			ariaAttributes,
		},
		ref
	) => {
		const isDateOnly = variant === DateTimeFieldVariant.DATE;

		const onSpaceBarPreventDefaultPicker = useCallback<KeyboardEventHandler<HTMLDivElement>>((event) => {
			if (event.key === " ") event.preventDefault();
		}, []);

		const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
			(event) => {
				onSpaceBarPreventDefaultPicker(event);
				applyAndCancelKeyCapturing<HTMLDivElement>(event, onApply, onCancel);
			},
			[onApply, onCancel, onSpaceBarPreventDefaultPicker]
		);

		const defaultMax = isDateOnly ? "9999-12-31" : "9999-12-31T23:59";

		return (
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<input
						data-hide-picker-icon="true"
						id={id}
						ref={ref}
						style={{ width: isDateOnly ? DATE_INPUT_WIDTH : DATETIME_INPUT_WIDTH }}
						className={clsx(
							"text-body-sm max-h-10 bg-transparent py-2 pl-4 pr-1.5 font-mono",
							disabled && "cursor-not-allowed"
						)}
						type={isDateOnly ? "date" : "datetime-local"}
						min={mapUnixTimestampToDateTimeLocalIsoString(min, isDateOnly)}
						max={max ? mapUnixTimestampToDateTimeLocalIsoString(max, isDateOnly) : defaultMax}
						value={mapUnixTimestampToDateTimeLocalIsoString(value, isDateOnly)}
						onChange={(e) => {
							onChange(mapDateTimeLocalIsoStringToUnixTimestampOrNull(e.target.value, isDateOnly));
						}}
						onBlur={onBlur}
						onFocus={onFocus}
						onKeyDown={handleKeyDown}
						disabled={disabled}
						{...ariaAttributes}
					/>
				</div>
				{calenderIcon}
			</div>
		);
	}
);
