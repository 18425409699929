import { formatMilliseconds } from "@salesdesk/salesdesk-utils";

import { DisplayTextField } from "../../TextField";

interface DisplayDurationFieldProps {
	value?: number;
	placeholder?: string;
}

export function DisplayDurationField({ value, placeholder }: DisplayDurationFieldProps) {
	return <DisplayTextField value={formatMilliseconds(value || 0)} placeholder={placeholder} />;
}
