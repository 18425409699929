import { Room } from "twilio-video";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { useLocalAudioToggle, useVideoCallMediaContext } from "../VideoCallProvider";
import { Tooltip, Button } from "@salesdesk/daisy-ui";

interface ToggleAudioButtonProps {
	disabled?: boolean;
	room?: Room | null;
}

export function ToggleAudioButton({ disabled = false, room }: ToggleAudioButtonProps) {
	const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(room || null);
	const { localTracks } = useVideoCallMediaContext();
	const hasAudioTrack = localTracks.some((track) => track.kind === "audio");

	return (
		<Tooltip text={isAudioEnabled ? "Turn off microphone" : "Turn on microphone"} placement="top">
			<Button
				size="lg"
				variant="secondary"
				active={!isAudioEnabled}
				onClick={toggleAudioEnabled}
				disabled={!hasAudioTrack || disabled}
				startIcon={isAudioEnabled ? ICONS.microphoneOn : ICONS.microphoneOff}
				data-cy-audio-toggle
			/>
		</Tooltip>
	);
}
