import { useMemo } from "react";

import { useWebPrincipal } from "../../../../../../auth";
import { Divider } from "../../../../../../components/Divider/Divider";

import { filterVisibleWorkspaceAssociations, useGetContextWorkspaceId } from "../../../../../workspaces";
import { RecordForm, RecordFormProps } from "../../../RecordForm";
import { AssociationField } from "./AssociationField";

export const RECORD_ASSOCIATION_KEY = "RecordAssociation";

interface RecordCreateFormProps {
	formProps: RecordFormProps;
	objectName: string;
	hideAssociationSection?: boolean;
}

export function RecordCreateForm({ formProps, objectName, hideAssociationSection = false }: RecordCreateFormProps) {
	const { associations } = formProps;

	const principal = useWebPrincipal();
	const workspaceId = useGetContextWorkspaceId();
	const filteredObjectAssociations = useMemo(
		() =>
			workspaceId && associations
				? filterVisibleWorkspaceAssociations(associations, principal.IsSalesDeskUser, true)
				: associations,
		[associations, principal.IsSalesDeskUser, workspaceId]
	);

	const associationsAmount = filteredObjectAssociations?.length || 0;

	return (
		<RecordForm {...formProps}>
			{!hideAssociationSection && associationsAmount > 0 ? (
				<>
					<Divider className="my-0" />
					<p className="text-h4">Associate {objectName} with</p>
					{filteredObjectAssociations?.map((associationDef, index) => (
						<div key={associationDef.id} className="flex flex-col gap-8">
							<AssociationField association={associationDef} associationKey={RECORD_ASSOCIATION_KEY} />
							{index + 1 !== associationsAmount ? <Divider className="my-0" /> : null}
						</div>
					))}
				</>
			) : null}
		</RecordForm>
	);
}
