import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, Tooltip, tw } from "@salesdesk/daisy-ui";
import { Tab, Transition } from "@headlessui/react";
import clsx from "clsx";

import { SelectDrop } from "../../../../../../../../../../components/SelectDrop/SelectDrop";

interface MediaFieldTabProps {
	icon: keyof typeof ICONS;
	label: string;
}

export function ProductMediaFieldTab({ icon, label }: MediaFieldTabProps) {
	return (
		<Tab className="group relative my-1 items-center rounded-full pl-4 pr-2 transition-all">
			{({ selected }) => (
				<>
					<Tooltip text={label} placement="left">
						<span>
							<Icon
								icon={ICONS[icon]}
								className={clsx(
									"text-c_icon_regular relative z-20 flex h-9 items-center justify-center rounded-full",
									"group-focus-visible:ring-c_action_focus group-active:bg-c_bg_04 group-hover:bg-c_bg_04 group-focus-visible:ring"
								)}
							/>
						</span>
					</Tooltip>
					<div className="pointer-events-none absolute -top-full left-2 z-10">
						<Transition
							show={selected}
							className="transition-all"
							enter={tw`delay-[55ms]`}
							enterFrom={tw`scale-y-0 opacity-0`}
							enterTo={tw`scale-y-100 opacity-100`}
							leave={tw`duration-0`}
							leaveFrom={tw`opacity-100`}
							leaveTo={tw`opacity-0`}
						>
							<SelectDrop colorKey="c_bg_04" />
						</Transition>
					</div>
				</>
			)}
		</Tab>
	);
}
