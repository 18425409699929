import { mSystemObjectDef } from "../system_object_def";

export class mWorkspaceDef extends mSystemObjectDef {
	static CLASS_NAME = "mWorkspaceDef";
	static NAME = "Workspace";
	static PLURAL_NAME = "Workspaces";
	static ICON = "ph-circles-three-plus";

	static COMPANY_NAME_FIELD_NAME = "companyName";
	static COMPANY_LOGO_FIELD_NAME = "companyLogo";
	static THEME_FIELD_NAME = "workspaceTheme";
	static DESCRIPTION_FIELD_NAME = "description";
	static INTRODUCTION_FIELD_NAME = "introductionMessage";
	static PROJECT_OVERVIEW_FIELD_NAME = "projectOverview";

	static ID = 8;

	constructor(ownerId) {
		super(mWorkspaceDef.ID, ownerId);
	}
}
