import { useFormContext } from "react-hook-form";

import { Button } from "@salesdesk/daisy-ui";

import { Divider } from "../../../../../components/Divider/Divider";

interface StickyFormControlsProps {
	formId: string;
	isLoading: boolean;
	defaultValues: Record<string, unknown>;
	submitDisabled?: boolean;
}

export function StickyFormControls({ formId, isLoading, defaultValues, submitDisabled }: StickyFormControlsProps) {
	const {
		reset,
		formState: { isDirty },
	} = useFormContext();

	return (
		<div className="bg-c_bg_01 sticky bottom-0 z-10 mt-2 flex flex-col gap-8 pb-6">
			<Divider className="my-0" />
			<div className="flex justify-end gap-2">
				<Button variant="secondary" onClick={() => reset(defaultValues)} size="sm">
					Cancel
				</Button>
				<Button form={formId} type="submit" disabled={submitDisabled || !isDirty} isLoading={isLoading} size="sm">
					Save changes
				</Button>
			</div>
		</div>
	);
}
