import { Button, Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import clsx from "clsx";
import { PropsWithChildren } from "react";

interface StepControlsProps extends PropsWithChildren {
	error?: string;
	primaryIsLoading?: boolean;
	primaryDisabled?: boolean;
	primaryButtonText?: string;
	onPrimaryClick: () => void;
	secondaryDisabled?: boolean;
	secondaryButtonText?: string;
	onSecondaryClick: () => void;
	hideControls?: boolean;
}

export function StepControls({
	error,
	primaryIsLoading,
	primaryDisabled,
	primaryButtonText = "Next",
	onPrimaryClick,
	secondaryDisabled,
	secondaryButtonText = "Back",
	onSecondaryClick,
	hideControls,
	children,
}: StepControlsProps) {
	return (
		<div className={clsx("bg-c_bg_01 mx-auto flex w-full max-w-[1130px] justify-stretch gap-2 pb-14 pt-4")}>
			{children ?? <div className="grow" />}
			<div className="flex max-w-[75%] shrink-0 grow items-center justify-end gap-3 text-pretty">
				{error ? (
					<div className="flex gap-4">
						<Icon icon={ICONS.warningCircle} className="text-c_danger_01 flex items-center" />
						<div className="text-c_danger_01 text-label">{error}</div>
					</div>
				) : null}
				{!hideControls ? (
					<>
						<Button variant="secondary" size="sm" onClick={onSecondaryClick} disabled={secondaryDisabled}>
							{secondaryButtonText}
						</Button>
						<Button
							variant="primary"
							size="sm"
							onClick={onPrimaryClick}
							disabled={primaryDisabled}
							isLoading={primaryIsLoading}
						>
							{primaryButtonText}
						</Button>
					</>
				) : null}
			</div>
		</div>
	);
}
