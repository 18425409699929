import { DisplayFieldVariant } from "../../../../types";
import { Logo } from "../../../../../../components/Logo";
import { LogoFieldValue } from "../types";
import { useLogoFieldValue } from "../hooks/useLogoFieldValue";

interface DisplayLogoFieldProps {
	value: LogoFieldValue;
	variant?: DisplayFieldVariant;
}

export function DisplayLogoField({ value, variant }: DisplayLogoFieldProps) {
	const { fileId, companyName } = useLogoFieldValue(value);

	return (
		<Logo
			size={variant === DisplayFieldVariant.table ? "md" : "xl"}
			companyName={companyName ?? ""}
			image={fileId ?? undefined}
		/>
	);
}
