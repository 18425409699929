import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebSocketMessage } from "@salesdesk/salesdesk-schemas";
import { SDWebSocket } from "../../features/WebSocket";

type ConnectWebSocketAction = PayloadAction<SDWebSocket>;
type WebSocketMessageAction = PayloadAction<WebSocketMessage>;

const webSocketSlice = createSlice({
	name: "webSocket",
	initialState: {},
	reducers: {
		connectWebSocket: (state, action: ConnectWebSocketAction) => {
			// Handled by webSocketMiddleware middleware
		},
		sendWebSocketMessage: (state, action: WebSocketMessageAction) => {
			// Handled by webSocketMiddleware middleware
		},
		disconnectWebSocket: (state) => {
			// Handled by webSocketMiddleware middleware
		},
	},
});

export const { connectWebSocket, sendWebSocketMessage, disconnectWebSocket } = webSocketSlice.actions;

export const webSocketReducer = webSocketSlice.reducer;
