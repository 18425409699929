import { asConst } from "json-schema-to-ts";
import { idSchema } from "../../../components";
import { appendNotEditable } from "../../../functions";

export const optionValueIdSchema = asConst({
	...idSchema,
	description: appendNotEditable(
		"When creating a new Option Value this will be created if not defined or updated if it does"
	),
});
