import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChannelMembershipSummary, ChannelMode } from "@aws-sdk/client-chime-sdk-messaging";

import { ChannelMetadata, ChannelNames } from "@salesdesk/salesdesk-schemas";

import { LoadingStatus, SerializableChannelDetails } from "../types";
import { sortChannelsByLastMessageTimestamp } from "../utils";

interface ChannelsState {
	channels?: SerializableChannelDetails[];
}

const initialState: ChannelsState = {};

export const channelSlice = createSlice({
	name: "channels",
	initialState,
	reducers: {
		setChannels: (state, action: PayloadAction<SerializableChannelDetails[]>) => {
			state.channels = action.payload;
			sortChannelsByLastMessageTimestamp(state.channels);
		},
		addChannel: (state, action: PayloadAction<SerializableChannelDetails>) => {
			if (!state.channels) {
				state.channels = [];
			}

			const existingChannelIndex = state.channels.findIndex((channel) => channel.arn === action.payload.arn);

			if (existingChannelIndex === -1) {
				state.channels.push(action.payload);
			} else {
				state.channels[existingChannelIndex] = action.payload;
			}

			sortChannelsByLastMessageTimestamp(state.channels);
		},
		updateChannelInfo: (
			state,
			action: PayloadAction<{
				channelArn: string;
				channelName?: string;
				metadata?: ChannelMetadata;
				mode?: ChannelMode;
			}>
		) => {
			if (!state.channels) {
				return;
			}

			const index = state.channels.findIndex((channel) => channel.arn === action.payload.channelArn);

			if (index === -1) {
				return;
			}

			const { channelName, metadata, mode } = action.payload;
			if (channelName) {
				state.channels[index].channelSummary.Name = channelName;
				state.channels[index].displayName = channelName === ChannelNames.NotSet ? undefined : channelName;
			}

			if (metadata) {
				state.channels[index].channelMetadata = metadata;
				state.channels[index].channelSummary.Metadata = JSON.stringify(metadata);
			}

			if (mode) {
				state.channels[index].channelSummary.Mode = mode;
			}
		},
		removeChannel: (state, action: PayloadAction<{ channelArn: string; onlyRemoveIfReadOnly?: boolean }>) => {
			if (!state.channels) {
				return;
			}
			state.channels = state.channels.filter((channel) => {
				if (action.payload.onlyRemoveIfReadOnly) {
					return channel.arn !== action.payload.channelArn || !channel.isReadOnly;
				}

				return channel.arn !== action.payload.channelArn;
			});
			sortChannelsByLastMessageTimestamp(state.channels);
		},
		updateChannelLastMessageTimestamp(state, action: PayloadAction<{ channelArn: string; timestamp?: number }>) {
			if (!state.channels) {
				return;
			}

			const index = state.channels.findIndex((channel) => channel.arn === action.payload.channelArn);

			if (index === -1) {
				return;
			}

			const timestamp = action.payload.timestamp == null ? new Date() : new Date(action.payload.timestamp);
			state.channels[index].channelSummary.LastMessageTimestamp = timestamp.getTime();

			sortChannelsByLastMessageTimestamp(state.channels);
		},
		updateChannelReadMarkerTimestamp(state, action: PayloadAction<{ channelArn: string; timestamp?: number }>) {
			if (!state.channels) {
				return;
			}

			const index = state.channels.findIndex((channel) => channel.arn === action.payload.channelArn);

			if (index === -1) {
				return;
			}

			const timestamp = action.payload.timestamp == null ? new Date() : new Date(action.payload.timestamp);
			state.channels[index].appInstanceUserMembershipSummary.ReadMarkerTimestamp = timestamp.getTime();
		},
		updateChannelDisplayName(state, action: PayloadAction<{ channelArn: string; displayName: string }>) {
			if (!state.channels) {
				return;
			}

			const index = state.channels.findIndex((channel) => channel.arn === action.payload.channelArn);

			if (index === -1) {
				return;
			}

			state.channels[index].displayName = action.payload.displayName;
		},
		setChannelMemberships(
			state,
			action: PayloadAction<{ channelArn: string; members?: ChannelMembershipSummary[]; loadingStatus: LoadingStatus }>
		) {
			if (!state.channels) {
				return;
			}

			const { channelArn, members, loadingStatus } = action.payload;

			const index = state.channels.findIndex((channel) => channel.arn === channelArn);

			if (index === -1) {
				return;
			}

			state.channels[index].memberships = {
				members,
				loadingStatus,
			};
		},
		addChannelMembers(
			state,
			action: PayloadAction<{ channelArn: string; members: ChannelMembershipSummary | ChannelMembershipSummary[] }>
		) {
			if (!state.channels) {
				return;
			}

			const { channelArn, members } = action.payload;

			const index = state.channels.findIndex((channel) => channel.arn === channelArn);

			if (index === -1) {
				return;
			}

			const channelMembers = state.channels[index].memberships?.members;

			if (!channelMembers) {
				return;
			}

			(Array.isArray(members) ? members : [members]).forEach((member) => {
				const memberIndex = channelMembers.findIndex(
					(existingMember) => existingMember.Member?.Arn === member.Member?.Arn
				);

				if (memberIndex === -1) {
					channelMembers.push(member);
				} else {
					channelMembers[memberIndex] = member;
				}
			});

			// Have to reset the channel display name if the name is generated from the members
			if (state.channels[index].channelSummary.Name === ChannelNames.NotSet) {
				state.channels[index].displayName = undefined;
			}
		},
		removeChannelMember(state, action: PayloadAction<{ channelArn: string; memberArn: string }>) {
			if (!state.channels) {
				return;
			}

			const { channelArn, memberArn } = action.payload;

			const index = state.channels.findIndex((channel) => channel.arn === channelArn);

			if (index === -1) {
				return;
			}

			const channelMemberships = state.channels[index].memberships;

			if (!channelMemberships || !channelMemberships?.members) {
				return;
			}

			channelMemberships.members = channelMemberships.members.filter((member) => member.Member?.Arn !== memberArn);

			// Have to reset the channel display name if the name is generated from the members
			if (state.channels[index].channelSummary.Name === ChannelNames.NotSet) {
				state.channels[index].displayName = undefined;
			}
		},
	},
});

export const {
	setChannels,
	addChannel,
	removeChannel,
	updateChannelInfo,
	updateChannelLastMessageTimestamp,
	updateChannelReadMarkerTimestamp,
	updateChannelDisplayName,
	setChannelMemberships,
	addChannelMembers,
	removeChannelMember,
} = channelSlice.actions;
export const channelsReducer = channelSlice.reducer;
