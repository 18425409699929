import { Ref } from "react";

export function combineRefs(refs: Ref<any>[]) {
	return (value: any) =>
		refs.forEach((ref) => {
			if (typeof ref === "function") {
				ref(value);
			} else if (ref != null) {
				(ref as any).current = value;
			}
		});
}
