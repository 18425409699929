export enum SdEventType {
	RECORD_CREATED = "RECORD_CREATED",
	RECORD_UPDATED = "RECORD_UPDATED",
	RECORD_DELETED = "RECORD_DELETED",
	RECORD_OWNER_UPDATED = "RECORD_OWNER_UPDATED",
	RECORD_NOTE_ADDED = "RECORD_NOTE_ADDED",
	RECORD_NOTE_DELETED = "RECORD_NOTE_DELETED",
	RECORD_NOTE_UPDATED = "RECORD_NOTE_UPDATED",
	RECORD_COMMENT_ADDED = "RECORD_COMMENT_ADDED",
	RECORD_COMMENT_RESPONDED = "RECORD_COMMENT_RESPONDED",
	RECORD_COMMENT_RESOLVED = "RECORD_COMMENT_RESOLVED",
	RECORD_COMMENT_DELETED = "RECORD_COMMENT_DELETED",
	RECORD_COMMENT_UPDATED = "RECORD_COMMENT_UPDATED",
	RECORD_LOADED = "RECORD_LOADED",
	RECORD_STARTED_WATCHING = "RECORD_STARTED_WATCHING",
	RECORD_STOPPED_WATCHING = "RECORD_STOPPED_WATCHING",
	RECORD_SET_STARRED = "RECORD_SET_STARRED",
	RECORD_SET_UNSTARRED = "RECORD_SET_UNSTARRED",
	RECORD_SHARED_IN_WORKSPACE = "RECORD_SHARED_IN_WORKSPACE",
	RECORD_UNSHARED_IN_WORKSPACE = "RECORD_UNSHARED_IN_WORKSPACE",
	USER_ADDED_TO_WORKSPACE = "USER_ADDED_TO_WORKSPACE",
	USER_REMOVED_FROM_WORKSPACE = "USER_REMOVED_FROM_WORKSPACE",
	WORKSPACE_ENTERED = "WORKSPACE_ENTERED",
	WORKSPACE_RECORD_SHARED = "WORKSPACE_RECORD_SHARED",
	WORKSPACE_RECORD_UNSHARED = "WORKSPACE_RECORD_UNSHARED",
	WORKSPACE_USER_ADDED = "WORKSPACE_USER_ADDED",
	WORKSPACE_USER_REMOVED = "WORKSPACE_USER_REMOVED",

	LEAD_CONVERTED_TO_CONTACT = "LEAD_CONVERTED_TO_CONTACT",
	LEAD_CONVERTED_TO_OPPORTUNITY = "LEAD_CONVERTED_TO_OPPORTUNITY",
	LEAD_CONVERTED_TO_ACCOUNT = "LEAD_CONVERTED_TO_ACCOUNT",

	RECORD_ASSOCIATION_CREATED = "RECORD_ASSOCIATION_CREATED",
	RECORD_ASSOCIATION_DELETED = "RECORD_ASSOCIATION_DELETED",

	COPIED_TO_CLIPBOARD = "COPIED_TO_CLIPBOARD",
	PAGE_LOAD = "PAGE_LOAD",

	USER_CREATED = "USER_CREATED",
	CUSTOMER_USER_CREATED = "CUSTOMER_USER_CREATED",
	USER_REVOKED = "USER_REVOKED",
	USER_LOGGED_IN = "USER_LOGGED_IN",
	USER_LOGGED_OUT = "USER_LOGGED_OUT",

	NOTE_REACTION_ADDED = "NOTE_REACTION_ADDED",
	NOTE_REACTION_REMOVED = "NOTE_REACTION_REMOVED",
	NOTE_MENTION = "NOTE_MENTION",

	COMMENT_REACTION_ADDED = "COMMENT_REACTION_ADDED",
	COMMENT_REACTION_REMOVED = "COMMENT_REACTION_REMOVED",
	COMMENT_MENTION = "COMMENT_MENTION",

	TASK_COMPLETED = "TASK_COMPLETED",
	TASK_REOPENED = "TASK_REOPENED",
	TASK_ASSIGNED = "TASK_ASSIGNED",
	TASK_REASSIGNED = "TASK_REASSIGNED",

	ISSUE_CLOSED = "ISSUE_CLOSED",
	ISSUE_REOPENED = "ISSUE_REOPENED",
	ISSUE_ASSIGNED = "ISSUE_ASSIGNED",
	ISSUE_REASSIGNED = "ISSUE_REASSIGNED",

	OPPORTUNITY_WON = "OPPORTUNITY_WON",
	OPPORTUNITY_LOST = "OPPORTUNITY_LOST",

	MEETING_ORGANISED = "MEETING_ORGANISED",
	MEETING_UPDATED = "MEETING_UPDATED",
	MEETING_STARTED = "MEETING_STARTED",
	MEETING_ENDED = "MEETING_ENDED",
	MEETING_ENTERED = "MEETING_ENTERED",
	MEETING_EXITED = "MEETING_EXITED",
	CHAT_MESSAGE_SENT = "CHAT_MESSAGE_SENT",
	RECORDING_STARTED = "RECORDING_STARTED",
	RECORDING_STOPPED = "RECORDING_STOPPED",
	RECORDING_ANALYSIS_COMPLETE = "RECORDING_ANALYSIS_COMPLETE",
	CAMERA_ENABLED = "CAMERA_ENABLED",
	CAMERA_DISABLED = "CAMERA_DISABLED",
	MICROPHONE_ENABLED = "MICROPHONE_ENABLED",
	MICROPHONE_DISABLED = "MICROPHONE_DISABLED",
	FILE_PRESENTATION_STARTED = "FILE_PRESENTATION_STARTED",
	FILE_PRESENTATION_STOPPED = "FILE_PRESENTATION_STOPPED",
	FILE_PRESENTATION_PAGE_CHANGED = "FILE_PRESENTATION_PAGE_CHANGED",
	SCREEN_SHARE_STARTED = "SCREEN_SHARE_STARTED",
	SCREEN_SHARE_ENDED = "SCREEN_SHARE_ENDED",

	FILE_UPLOADED = "FILE_UPLOADED",
	FILE_DELETED = "FILE_DELETED",
	FILE_DOWNLOADED = "FILE_DOWNLOADED",
	FILE_VIEWED = "FILE_VIEWED",
	PAGE_CHANGED = "PAGE_CHANGED",
	FILE_PLAYED = "FILE_PLAYED",
	FILE_PAUSED = "FILE_PAUSED",
	FILE_SEEKED = "FILE_SEEKED",
	FILE_ENDED = "FILE_ENDED",

	FILE_PROCESSING_STARTED = "FILE_PROCESSING_STARTED",
	FILE_PROCESSING_ENDED = "FILE_PROCESSING_ENDED",
	FILE_PROCESSING_FAILED = "FILE_PROCESSING_FAILED",
	FILE_TRANSCODE_STARTED = "FILE_TRANSCODE_STARTED",
	FILE_TRANSCODE_ENDED = "FILE_TRANSCODE_ENDED",
	FILE_TRANSCRIPTION_STARTED = "FILE_TRANSCRIPTION_STARTED",
	FILE_TRANSCRIPTION_ENDED = "FILE_TRANSCRIPTION_ENDED",
	FILE_TRANSCRIPTION_ANALYSIS_STARTED = "FILE_TRANSCRIPTION_ANALYSIS_STARTED",
	FILE_TRANSCRIPTION_ANALYSIS_ENDED = "FILE_TRANSCRIPTION_ANALYSIS_ENDED",
	FILE_TRANSCRIPTION_ANALYSIS_MINUTES_EXTRACTED = "FILE_TRANSCRIPTION_ANALYSIS_MINUTES_EXTRACTED",
	FILE_COMPOSITING_STARTED = "FILE_COMPOSITING_STARTED",
	FILE_COMPOSITING_ENDED = "FILE_COMPOSITING_ENDED",

	MESSAGE_SENT = "MESSAGE_SENT",
	MESSAGE_UPDATED = "MESSAGE_UPDATED",
	MESSAGE_DELETED = "MESSAGE_DELETED",
	MESSAGE_RECORD_SHARED = "MESSAGE_RECORD_SHARED",
	MESSAGE_HUDDLE_STARTED = "MESSAGE_HUDDLE_STARTED",
	MESSAGE_MENTION = "MESSAGE_MENTION",
	MESSAGE_CHANNEL_CREATED = "MESSAGE_CHANNEL_CREATED",
	MESSAGE_CHANNEL_DELETED = "MESSAGE_CHANNEL_DELETED",
	MESSAGE_CHANNEL_MEMBER_CREATED = "MESSAGE_CHANNEL_MEMBER_CREATED",
	MESSAGE_CHANNEL_MEMBER_DELETED = "MESSAGE_CHANNEL_MEMBER_DELETED",

	IMPORT_JOB_STARTED = "IMPORT_JOB_STARTED",
	IMPORT_JOB_COMPLETED = "IMPORT_JOB_COMPLETED",
	IMPORT_JOB_FAILED = "IMPORT_JOB_FAILED",
}
