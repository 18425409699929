import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import clsx from "clsx";

import { AlertBubble, Button, Icon, useHasMounted } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

import { AccessibleButtonDiv } from "../../../components/AccessibleButtonDiv";
import { MessagingPanelProps } from "../types";

type MessagingPanelHeaderProps = MessagingPanelProps;

const BOUNCE_DURATION_MS = 3000;

export function MessagingPanelHeader({
	open,
	onToggleOpen,
	unreadCount,
	showUnreadDot,
	headerElements,
	newMessageKey = 0,
}: MessagingPanelHeaderProps) {
	const hasMounted = useHasMounted();

	const [bounce, setBounce] = useState(false);

	useEffect(() => {
		if (newMessageKey) {
			setBounce(true);
			setTimeout(() => setBounce(false), BOUNCE_DURATION_MS);
		}
	}, [newMessageKey]);

	return (
		<AccessibleButtonDiv
			className={clsx(
				hasMounted && "transition-all",
				"rounded-t-card text-label-sm group/panel-header shadow-record_overlay text-c_text_inverted bg-c_action_focus hover:bg-c_action_02 focus:ring-c_action_focus flex h-[44px] w-full cursor-pointer items-center gap-1 px-4 py-3"
			)}
			onClick={() => onToggleOpen()}
		>
			<div className="flex grow items-center justify-start gap-2">
				Messaging{" "}
				<Transition
					className={clsx("relative", bounce && "animate-alert-bounce")}
					show={Boolean(showUnreadDot || unreadCount)}
					enter="transition-opacity duration-100"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{showUnreadDot ? (
						<>
							<Icon icon={ICONS.envelope} className="flex" />
							<div className="bg-c_bg_01 border-c_action_focus group-hover/panel-header:border-c_action_02 absolute -right-[3px] -top-px size-2.5 rounded-full border-2 transition-all"></div>
						</>
					) : (
						<AlertBubble size="md" variant="tertiary" showZero={false} alertCount={unreadCount ?? 0} />
					)}
				</Transition>
			</div>
			<div className="flex items-center gap-1">
				{headerElements}
				<Button variant="text_inverted" startIcon={open ? ICONS.caretDown : ICONS.caretUp} size="sm" />
			</div>
		</AccessibleButtonDiv>
	);
}
