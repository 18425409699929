import { asConst } from "json-schema-to-ts";
import { chatMessageCreateRequestSchema } from "./chatMessageCreateRequestSchema";
import { creatableSchema } from "../components";

export const chatMessageSchema = asConst({
	title: "Chat Message",
	description: "A chat message posted by a user",
	type: "object",
	additionalProperties: false,
	required: [...chatMessageCreateRequestSchema.required, ...creatableSchema.required],
	properties: {
		...chatMessageCreateRequestSchema.properties,
		...creatableSchema.properties,
	},
});
