import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { throttle } from "../../../../utils";
import { OptionalDimension } from "../../../../utils/types";
import { PdfControlsWrapper } from "./PdfControlsWrapper";
import { SdEventType } from "@salesdesk/salesdesk-model";
import { PdfEvent } from "../../types";
import clsx from "clsx";

interface PdfViewerProps {
	pdfFile: File;
	renderTextLayer?: boolean;
	onOpenPreviewClick?: () => void;
	currentPage?: number;
	onPageChange?: (newPageNumber: number) => void;
	onPdfEvent?: (eventType: PdfEvent, pageNumber: number) => void;
	disabled?: boolean;
}

export const PdfViewer = forwardRef<HTMLDivElement, PdfViewerProps>(
	(
		{
			pdfFile,
			onPdfEvent,
			renderTextLayer = true,
			disabled,
			onOpenPreviewClick,
			currentPage: controlledCurrentPage,
			onPageChange,
		},
		ref
	) => {
		const currentPageIsUncontrolled = controlledCurrentPage == null;

		const [uncontrolledCurrentPage, setUncontrolledCurrentPage] = useState(1);
		const currentPage = currentPageIsUncontrolled ? uncontrolledCurrentPage : controlledCurrentPage;
		const setCurrentPage = !currentPageIsUncontrolled && onPageChange ? onPageChange : setUncontrolledCurrentPage;

		const [totalPages, setTotalPages] = useState(0);

		const [renderedPage, setRenderedPage] = useState<number | null>(null);
		const [pageAspectRatio, setPageAspectRatio] = useState<number | null>(null);

		const viewContainerRef = useRef<HTMLDivElement>(null);
		const [dimensions, setDimensions] = useState<OptionalDimension>({ height: 250 });

		const isInitialMount = useRef(true);
		const [displayPdf, setDisplayPdf] = useState(false);

		// Hides the pdf every time it is resized to avoid flickering issue where the empty
		// white canvas is displayed before the pdf contents upon initial render and subsequent
		// resizes.
		useEffect(() => {
			// Skip initial render as the dimensions of the pdf view are updated when it is
			// first displayed.
			if (isInitialMount.current) {
				isInitialMount.current = false;
				return;
			}

			setDisplayPdf(false);
			const timeoutId = setTimeout(() => setDisplayPdf(true), 200);
			return () => clearTimeout(timeoutId);
		}, [dimensions]);

		useEffect(() => {
			if (currentPageIsUncontrolled) {
				setCurrentPage(1);
			}

			setTotalPages(0);
		}, [currentPageIsUncontrolled, pdfFile, setCurrentPage]);

		useEffect(() => {
			const element = viewContainerRef?.current;

			if (!element || !pageAspectRatio) return;

			const observer = new ResizeObserver(
				throttle((resizeEntry: ResizeObserverEntry[]) => {
					if (!resizeEntry.length) {
						return;
					}

					const { width, height } = resizeEntry[0].contentRect;
					const containerRatio = width / height;

					if (containerRatio > (pageAspectRatio || 0)) {
						setDimensions({ height });
					} else {
						setDimensions({ width });
					}
				}, 50)
			);

			observer.observe(element);
			return () => {
				// Cleans up the observer by unobserving all elements
				observer.disconnect();
			};
		}, [pageAspectRatio]);

		const updatePageNumber = useCallback(
			(newPageNumber: number) => {
				setCurrentPage(Math.min(Math.max(newPageNumber, 1), totalPages));
			},
			[totalPages, setCurrentPage]
		);

		const initialLoad = useRef(true);
		useEffect(() => {
			if (disabled || !onPdfEvent) {
				return;
			}
			if (initialLoad.current) {
				initialLoad.current = false;
				return;
			}
			onPdfEvent(SdEventType.PAGE_CHANGED, currentPage);
		}, [currentPage, disabled, onPdfEvent]);

		const isLoading = renderedPage !== currentPage;

		// Canvas background must be set to white since a lot of PDFs have transparent backgrounds
		// with the expectation that the PDF viewer will correctly display it as white
		const sharedPageProps = {
			...dimensions,
			renderTextLayer,
			canvasBackground: "white",
			loading: null,
		};

		return (
			<PdfControlsWrapper
				ref={viewContainerRef}
				totalPages={totalPages}
				currentPage={currentPage}
				updatePageNumber={updatePageNumber}
				onOpenPreviewClick={onOpenPreviewClick}
				disabled={disabled}
			>
				<Document
					inputRef={ref}
					className={clsx("flex h-full w-full select-text items-center justify-center", !displayPdf && "opacity-0")}
					file={pdfFile}
					onLoadSuccess={({ numPages }) => {
						setTotalPages(numPages);

						if (currentPageIsUncontrolled && numPages > 0) {
							setCurrentPage(1);
						}
					}}
					loading={null}
				>
					{/* Renders the previous page as the new page is being loaded to prevent flickering */}
					{isLoading && renderedPage ? (
						<Page key={renderedPage} pageNumber={renderedPage} {...sharedPageProps} />
					) : null}
					<Page
						key={currentPage}
						className={`${isLoading ? "hidden" : ""}`}
						pageNumber={currentPage}
						onRenderSuccess={(page) => {
							const { width, height } = page.getViewport({ scale: 1 });
							setPageAspectRatio(width / height);
							setRenderedPage(currentPage);
						}}
						{...sharedPageProps}
					/>
				</Document>
			</PdfControlsWrapper>
		);
	}
);
