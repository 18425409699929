import { useCallback } from "react";
import { mUserDef } from "@salesdesk/salesdesk-model";

import { useGetRecordOptionsInWorkspace } from "../../fields";
import { ButtonTypeaheadSelect, SelectOptionId } from "../../inputs";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Button } from "@salesdesk/daisy-ui";
import { Placement } from "@floating-ui/react";

interface UserFilterProps {
	value: number[];
	onChange: (newValue: number[]) => void;
	placement?: Placement;
}

export function UserFilter({ value, placement, onChange }: UserFilterProps) {
	const { getOptions } = useGetRecordOptionsInWorkspace({ baseObjectId: mUserDef.ID, fetchingRecordOptions: true });

	const handleOnChange = useCallback(
		(selectedValues: SelectOptionId[]) => {
			onChange(selectedValues.map(Number));
		},
		[onChange]
	);

	return (
		<ButtonTypeaheadSelect
			getSelectOptions={getOptions}
			value={value}
			onChange={handleOnChange}
			optionDisplayVariant="user_pill"
			placement={placement}
		>
			<Button size="sm" variant="secondary" alertCount={value?.length} startIcon={ICONS.filter}>
				User
			</Button>
		</ButtonTypeaheadSelect>
	);
}
