import { getSDRecordName, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";
import { SelectOptionId, TypeaheadSelect } from "../../../../inputs";
import { useGetRecordOptionsInWorkspace } from "../../../../fields";
import { mUserDef } from "@salesdesk/salesdesk-model";
import { FormEvent, useEffect, useId, useState } from "react";
import { useBulkUpdateRecords } from "../../../hooks";
import { useToast } from "../../../../Toasts";
import { MAX_RECORD_BATCH_SIZE } from "../../../../ObjectBoard/components/BulkEdit/utils";
import { BulkChangeAlert } from "../../../../ObjectBoard/components/BulkEdit/components/BulkChangeAlert";
import { usePrincipalCanFilteredRecords } from "../../../../ObjectBoard/components/BulkEdit/hooks/usePrincipalCanFilteredRecords";
import { getCanChangeRecordOwnerParameters } from "../types";
import { countPromiseResults } from "../../../../../utils";
import { FormDialog } from "../../../../Dialog";

interface BulkChangeOwnerModalProps {
	sdObject: SDObject | undefined;
	records: SDRecord[];
	onUpdateRecords?: (updatedRecords: SDRecord[]) => void;
	onClose: () => void;
}

export function BulkChangeOwnerModal({ sdObject, records, onUpdateRecords, onClose }: BulkChangeOwnerModalProps) {
	const { getOptions, getOptionsFromIds } = useGetRecordOptionsInWorkspace({
		baseObjectId: mUserDef.ID,
		fetchingRecordOptions: true,
	});

	const { bulkUpdateRecords, isLoading: isSaving } = useBulkUpdateRecords();

	const toast = useToast();
	const [selectedOwnerId, setSelectedOwnerId] = useState<SDRecord["_id"]>();

	const [editableRecords, nonEditableRecords] = usePrincipalCanFilteredRecords(
		records,
		getCanChangeRecordOwnerParameters
	);

	useEffect(() => {
		// If all the editable records have the same owner, preselect it
		if (editableRecords[0] && editableRecords.every((r) => r._ownerId === editableRecords[0]._ownerId)) {
			setSelectedOwnerId(editableRecords[0]._ownerId ?? undefined);
		}
	}, [editableRecords]);

	const formId = useId();

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (!selectedOwnerId) {
			return;
		}

		const recordsToChange: SDRecord[] = [];
		const unchangedRecords: SDRecord[] = [];

		editableRecords.forEach((record) => {
			if (record._ownerId !== selectedOwnerId) {
				recordsToChange.push(record);
			} else {
				unchangedRecords.push(record);
			}
		});

		if (recordsToChange.length === 0) {
			toast.triggerMessage({
				type: "info",
				messageKey: "record_bulk_update_no_changes",
			});
			onClose();
			return;
		}
		const chunks = chunk(recordsToChange, MAX_RECORD_BATCH_SIZE);
		Promise.allSettled(
			chunks.map((records) =>
				bulkUpdateRecords({
					records,
					ownerId: selectedOwnerId,
				})
			)
		).then((results) => {
			const [successes, fails] = countPromiseResults(results, chunks);
			toast.triggerMessage({
				type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
				messageKey: recordsToChange.length > 1 ? "record_bulk_owner_change" : "record_owner_changed",
				messageParams: {
					records: `${successes} ${pluralizeWithS("record", successes)}`,
					total: String(fails + successes),
				},
			});
			const updatedRecords = results.flatMap((result) => (result.status === "fulfilled" ? result.value : []));
			onUpdateRecords?.([...unchangedRecords, ...updatedRecords]);
			onClose();
		});
	};

	if (!sdObject) {
		return null;
	}

	return (
		<FormDialog
			open
			title="Change owner"
			onOpenChange={(open) => {
				if (!open) {
					onClose();
				}
			}}
			isPending={isSaving}
			disableSubmitButton={selectedOwnerId === undefined}
			formId={formId}
		>
			<form id={formId} onSubmit={onSubmit} className="flex flex-col gap-4">
				<div>
					Select a new owner for{" "}
					{editableRecords.length === 1 ? (
						<span className="text-label">"{getSDRecordName(sdObject, editableRecords[0])}"</span>
					) : (
						<span className="text-label underline">
							{editableRecords.length} {pluralizeWithS("record", editableRecords.length)}
						</span>
					)}
					. The new owner will be notified.
				</div>
				<BulkChangeAlert
					actionMessage="change the owner for"
					totalRecordCount={records.length}
					unEditableRecords={nonEditableRecords}
					sdObject={sdObject}
				/>
				<TypeaheadSelect
					value={selectedOwnerId ? String(selectedOwnerId) : undefined}
					onChange={(id?: SelectOptionId) => {
						setSelectedOwnerId(Number(id));
					}}
					getOptions={getOptions}
					getOptionsFromIds={getOptionsFromIds}
					optionDisplayVariant="user_pill"
					isMultiselect={false}
				/>
			</form>
		</FormDialog>
	);
}
