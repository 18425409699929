import { createContext, useContext } from "react";
import { ToastType } from "../types";

interface ToastContextState {
	type: ToastType;
	dark: boolean;
}

export const ToastContext = createContext<ToastContextState | undefined>(undefined);

export function useToastContext() {
	return useContext(ToastContext) ?? { type: "info", dark: false };
}
