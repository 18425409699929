import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useId, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { FIELD_TYPES } from "@salesdesk/salesdesk-model";
import {
	AbilityAction,
	AbilitySubject,
	CanParameters,
	FieldValue,
	getSDObjectFieldById,
	getSDRecordFieldValue,
	SDObject,
	SDRecord,
	sdSubject,
	sdSubjectField,
} from "@salesdesk/salesdesk-schemas";
import { chunk, pluralizeWithS } from "@salesdesk/salesdesk-utils";

import { SelectOption, SelectOptionId, TypeaheadSelect } from "../../../../../../inputs";
import { useToast } from "../../../../../../Toasts";
import { useBulkEditContext } from "../../../hooks/useBulkEditContext";
import { EditFieldFactory } from "../../../../../../fields";
import { FormDialog } from "../../../../../../Dialog";
import { FormFieldDetails, FormFieldDetailsMap } from "../types";
import { Divider } from "../../../../../../../components/Divider/Divider";
import { FormFieldSet } from "../../../../../../forms";
import { useBulkUpdateRecords } from "../../../../../../records";
import { MAX_RECORD_BATCH_SIZE } from "../../../utils";
import { BulkChangeAlert } from "../../BulkChangeAlert";
import { usePrincipalCanFilteredRecords } from "../../../hooks/usePrincipalCanFilteredRecords";
import { countPromiseResults } from "../../../../../../../utils";
import { useUniqueCheckWithReactHookForm } from "../../../../../../records/hooks/useUniqueCheckWithReactHookForm";

interface BulkEditFieldsModalProps {
	sdObject: SDObject;
	fieldOptions: SelectOption[];
	fieldDetailsMap: FormFieldDetailsMap;
	onOpenChange?: Dispatch<SetStateAction<boolean>>;
}

const FIELD_VALUE_FORM_NAME = "FieldValue";

export function BulkEditFieldsModal({
	sdObject,
	fieldOptions,
	fieldDetailsMap,
	onOpenChange,
}: BulkEditFieldsModalProps) {
	const toast = useToast();
	const { bulkUpdateRecords } = useBulkUpdateRecords();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [selectedFieldId, setSelectedFieldId] = useState<SelectOptionId | undefined>(
		() => fieldOptions.find((option) => !option.disabled)?.id
	);
	const [selectedFieldDetails, setSelectedFieldDetails] = useState<FormFieldDetails | undefined>(undefined);

	const { selectedRecords, setSelectedRecords } = useBulkEditContext();
	const getCanEditFieldParameters = useCallback(
		(record: SDRecord): CanParameters => [
			AbilityAction.Edit,
			sdSubject(AbilitySubject.Record, record),
			sdSubjectField(getSDObjectFieldById(sdObject, Number(selectedFieldId))?._name ?? ""),
		],
		[sdObject, selectedFieldId]
	);
	const [editableRecords, nonEditableRecords] = usePrincipalCanFilteredRecords(
		selectedRecords,
		getCanEditFieldParameters
	);

	const formId = useId();

	const form = useForm({
		mode: "onChange",
	});
	const { control, handleSubmit, reset } = form;

	// Finds the default value for the currently selected field. If all selected records
	// have the same value for that field (e.g. 10 tasks that all have status set to Done)
	// that is used as the default value in the form, otherwise it's just set to null.
	useEffect(() => {
		let defaultValue: unknown = null;

		if (!selectedFieldId) {
			reset({ [FIELD_VALUE_FORM_NAME]: defaultValue });
			setSelectedFieldDetails(undefined);
			return;
		}

		setSelectedFieldDetails(fieldDetailsMap[selectedFieldId]);

		for (let i = 0; i < editableRecords.length; i++) {
			const record = editableRecords[i];
			const fieldValue = getSDRecordFieldValue(record, Number(selectedFieldId))?._value;

			if (i === 0) {
				defaultValue = fieldValue;
				continue;
			}

			if (!isEqual(fieldValue, defaultValue)) {
				defaultValue = null;
				break;
			}
		}

		reset({ [FIELD_VALUE_FORM_NAME]: defaultValue ?? null });
	}, [selectedFieldId, fieldDetailsMap, editableRecords, reset]);

	const field = selectedFieldDetails?.field;

	const numOfRecords = editableRecords.length;
	const recordNumberText = `${numOfRecords} ${pluralizeWithS("record", numOfRecords)}`;

	const onSubmit = (formData: FieldValues) => {
		if (!field) {
			toast.trigger("warning", "No field selected to bulk update.");
			return;
		}

		if (numOfRecords === 0) {
			toast.trigger("error", "No records selected to bulk update.");
			return;
		}

		setIsSubmitting(true);

		const updatedField: FieldValue = { _fieldId: field._id, _value: formData[FIELD_VALUE_FORM_NAME] };
		const chunks = chunk(editableRecords, MAX_RECORD_BATCH_SIZE);
		Promise.allSettled(
			chunks.map((records) =>
				bulkUpdateRecords({
					records,
					updatedFields: [updatedField],
				})
			)
		).then((results) => {
			const [successes, fails] = countPromiseResults(results, chunks);
			toast.triggerMessage({
				type: fails === 0 ? "success" : successes === 0 ? "error" : "warning",
				messageKey: "record_bulk_update",
				messageParams: {
					records: `${successes} ${pluralizeWithS("record", successes)}`,
					total: String(fails + successes),
				},
			});
			const updatedRecords = results.flatMap((result) => (result.status === "fulfilled" ? result.value : []));

			setSelectedRecords(updatedRecords);
			onOpenChange?.(false);
			setIsSubmitting(false);
		});
	};

	const hasLoadedFieldDetails = Boolean((selectedFieldId && selectedFieldDetails) || !selectedFieldId);

	// This wrapper is necessary to pass the form context to useUniqueCheckWithReactHookForm
	const UniqueCheckWrapper = ({ children }: { children: (uniqueError: string | undefined) => ReactNode }) => {
		const { uniqueError } = useUniqueCheckWithReactHookForm({
			sdObject,
			sdRecord: editableRecords[0],
			field,
			fieldName: FIELD_VALUE_FORM_NAME,
		});
		return children(uniqueError);
	};

	return (
		<FormDialog
			variant="sm"
			open={hasLoadedFieldDetails}
			onOpenChange={(open) => onOpenChange?.(open)}
			title="Edit field"
			description={
				<div>
					Update a field value for <span className="text-label underline">{recordNumberText}</span>.
				</div>
			}
			isPending={isSubmitting}
			formId={formId}
		>
			<div className="mb-4 flex flex-col gap-6">
				<BulkChangeAlert
					actionMessage="edit"
					totalRecordCount={selectedRecords.length}
					unEditableRecords={nonEditableRecords}
					sdObject={sdObject}
				/>
				<div>
					<div className="text-label mb-4">Field selection</div>
					<TypeaheadSelect value={selectedFieldId} onChange={setSelectedFieldId} options={fieldOptions} />
					<div className="text-body-sm text-c_text_secondary mt-2">
						Choose the field to edit for all selected records
					</div>
				</div>
				{selectedFieldDetails && field ? (
					<div>
						<Divider className="m-0" />
						<div className="text-label mb-4 mt-6">New field value</div>
						<form id={formId} onSubmit={handleSubmit(onSubmit)}>
							<FormProvider {...form}>
								<UniqueCheckWrapper>
									{(uniqueError) => (
										<FormFieldSet
											name={FIELD_VALUE_FORM_NAME}
											control={control}
											label={field._displayName}
											labelIcon={field._icon}
											required={field._required}
											isBoolean={field._type === FIELD_TYPES.BOOLEAN.name}
											helperText="Enter value to apply to all selected records"
											rules={{
												validate: (value) => uniqueError || selectedFieldDetails.fieldDef.validate(value, undefined),
											}}
										>
											{({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
												<EditFieldFactory
													key={field._id}
													field={field}
													componentProps={{
														id: String(field._id),
														onBlur,
														onChange,
														inputRef: ref,
														value,
														hasError: Boolean(error?.message),
													}}
												/>
											)}
										</FormFieldSet>
									)}
								</UniqueCheckWrapper>
							</FormProvider>
						</form>
					</div>
				) : null}
			</div>
		</FormDialog>
	);
}
