import { FIELD_COMPONENT_TYPES, FieldComponentName, ICONS } from "@salesdesk/salesdesk-ui";

import { mImageFieldDef } from "./image_field_def";
import { FIELD_TYPES } from "../utils";

export class mLogoFieldDef extends mImageFieldDef {
	static override supportedComponentTypes: FieldComponentName[] = [FIELD_COMPONENT_TYPES.LOGO.name];
	static override defaultComponentType: FieldComponentName = mLogoFieldDef.supportedComponentTypes[0];

	constructor(id: string | number) {
		super(id);
		this._componentType = mLogoFieldDef.defaultComponentType;
		this._mimeTypesStr = "image/jpeg:jpeg,jpg;image/png:png";
		super.loadMimeTypes(this._mimeTypesStr);
		this._icon = ICONS.image;
	}

	override get type() {
		return FIELD_TYPES.LOGO.name;
	}

	override get supportedComponentTypes() {
		return mLogoFieldDef.supportedComponentTypes;
	}

	override supportsFormatDescription() {
		return false;
	}
}
