import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions";
import { idArraySchema } from "../../components";

export const objectChildIdsSchema = asConst({
	...idArraySchema,
	title: "SDObject Child SDObject Ids",
	description: appendNotEditable(
		"The ids of SDObjects whose records are allowed to be children of this SDObject's records"
	),
});
