import { UI_PATHS } from "./UI_PATHS";

export const BASE_ROUTES = {
	START: "/start",
	LOGIN: "/login",
};

export const TENANT_ROUTES = {
	DASHBOARD: "/",
	OBJECT_BOARD: "/objects/:sdObjectPath/records",
	MEETING_ROUTE: `${UI_PATHS.MEETINGS()}/:meetingId`,
	WORKSPACE_MEETING_ROUTE: `${UI_PATHS.WORKSPACES()}/:workspaceId/meetings/:meetingId`,

	WORKSPACE_OBJECT_BOARD: `${UI_PATHS.WORKSPACES()}/:workspaceId/objects/:sdObjectPath/records`,
	WORKSPACE_LIBRARY_BOARD: `${UI_PATHS.WORKSPACES()}/:workspaceId/library/:sdObjectPath/records`,
	WORKSPACE_LIBRARY_DETAILS: `${UI_PATHS.WORKSPACES()}/:workspaceId/library/:sdObjectPath/records/:recordId`,

	WORKSPACE_INTERNAL_PAGE: `${UI_PATHS.WORKSPACES()}/:workspaceId/internal/*`,
};
