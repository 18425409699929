import { asConst } from "json-schema-to-ts";
import { stringSchema } from "../../components";
import { channelParamsSchema } from "./channelParamsSchema";

export const channelMessageParamsSchema = asConst({
	title: "Channel Message Params",
	description: "For message actions",
	type: "object",
	additionalProperties: false,
	required: [...channelParamsSchema.required, "messageId"],
	properties: {
		...channelParamsSchema.properties,
		messageId: stringSchema,
	},
});
