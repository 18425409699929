import { useState, useEffect, useRef } from "react";
import { getDeviceInfo, isPermissionDenied, useVideoCallMediaContext } from "../../..";

export function useInitialiseDevices() {
	const { getLocalAudioTrack, getLocalVideoTrack } = useVideoCallMediaContext();
	const [mediaError, setMediaError] = useState<Error | null>(null);
	const [isInitialisingDevices, setIsInitialisingDevices] = useState(true);
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (!isInitialMount.current) {
			return;
		}

		const initialiseDevices = async () => {
			let stream: MediaStream | undefined;

			try {
				// getUserMedia due to https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice/device-audio#deviceaudioavailableinputdevices
				// Also creates a user prompt for both the webcam and the microphone instead of doing them individually.
				stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

				await Promise.all([getLocalAudioTrack(), getLocalVideoTrack()]);
			} catch (error) {
				const isCameraPermissionDenied = await isPermissionDenied("camera");
				const isMicrophonePermissionDenied = await isPermissionDenied("microphone");

				if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
					setMediaError(
						new Error("Camera and microphone permissions denied. Please enable access in your browser settings.")
					);
				} else if (isCameraPermissionDenied) {
					setMediaError(new Error("Camera permission denied. Please enable camera access in your browser settings."));
				} else if (isMicrophonePermissionDenied) {
					setMediaError(
						new Error("Microphone permission denied. Please enable microphone access in your browser settings.")
					);
				} else {
					const { hasVideoInputDevices, hasAudioInputDevices } = await getDeviceInfo();

					if (!hasAudioInputDevices && !hasVideoInputDevices) {
						setMediaError(new Error("No camera or microphone found. Please connect them and refresh the browser."));
					} else if (!hasAudioInputDevices) {
						setMediaError(new Error("No microphone found. Please connect a microphone and refresh the browser."));
					} else if (!hasVideoInputDevices) {
						setMediaError(new Error("No camera found. Please connect a camera and refresh the browser."));
					} else {
						setMediaError(error as Error);
					}
				}
			} finally {
				setIsInitialisingDevices(false);

				// Calling `getUserMedia` creates a new media stream which accesses
				// the user's webcam/microphone. Twilio has its own getUserMedia calls that it
				// manages when it generates the local tracks, so we proactively stop all tracks
				// from this stream to ensure that in the future when we stop the twilio local
				// video/audio tracks there are no streams accessing the user's webcam/microphone.
				//
				// Without doing this this stream's tracks will continue to run until they are garbage
				// collected, causing a delay in when the camera/microphone is released (3 - 10 seconds).
				// Reference: https://stackoverflow.com/a/61842715
				if (stream) {
					stream.getTracks().forEach((track) => track.stop());
				}
			}
		};

		isInitialMount.current = false;
		initialiseDevices();
	}, [getLocalAudioTrack, getLocalVideoTrack]);

	return { mediaError, isInitialisingDevices };
}
