import React, { HTMLProps } from "react";
import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs } from "@floating-ui/react";
import { useDialogContext } from "../../../Dialog";

export const FileDialogContent = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(function DialogContent(
	props,
	propRef
) {
	const { context: floatingContext, ...context } = useDialogContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);

	if (!floatingContext.open) return null;

	return (
		<FloatingPortal>
			<FloatingOverlay className="bg-c_bg_06 z-[100] flex h-full overflow-hidden bg-opacity-90" lockScroll>
				<FloatingFocusManager context={floatingContext} initialFocus={-1}>
					<div ref={ref} className="h-full w-full" {...context.getFloatingProps(props)}>
						{props.children}
					</div>
				</FloatingFocusManager>
			</FloatingOverlay>
		</FloatingPortal>
	);
});
