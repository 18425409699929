import { useContext } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFloatingTree, useListItem } from "@floating-ui/react";
import { MENU_VARIANT_PADDING_CLASSES, MenuItem, PopoverMenuVariant } from "../../types";
import { MenuContext, useMenuItemProps } from "../../hooks";
import { PopoverMenu } from "./PopoverMenu";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { Icon, TooltipContainer, tw } from "@salesdesk/daisy-ui";
import {
	HoverPopover,
	Popover,
	PopoverContainer,
	PopoverContent,
	PopoverTrigger,
} from "../../../../components/Popover";
import { Badge } from "../../../../components/Badge";

interface PopoverMenuItemProps {
	item: MenuItem;
	isActive?: boolean;
	variant?: PopoverMenuVariant;
}

export const PopoverMenuItem = ({ item, isActive, variant: menuItemVariant = "regular" }: PopoverMenuItemProps) => {
	const { icon, iconVariant, text, type, variant, subText, badge } = item;

	const isButton = type === "button" && item.onClick;
	const isLink = type === "link" && item.link;
	const isSubMenu = type === "subMenu" && item.subMenu?.length;
	const isSubPopover = type === "subPopover" && item.subPopover;

	const disabled = !(isLink || isButton || isSubMenu || isSubPopover) || item.loading;

	let textColor;
	if (variant === "destructive") {
		textColor = tw`text-c_danger_02`;
	} else if (variant === "primary") {
		textColor = tw`text-c_action_01`;
	} else {
		textColor = tw`text-c_text_primary`;
	}

	let iconColor;
	if (variant === "destructive") {
		iconColor = tw`text-c_danger_02`;
	} else if (variant === "primary" || variant === "primary_icon") {
		iconColor = tw`text-c_action_01`;
	} else {
		iconColor = tw`text-c_icon_regular`;
	}

	const itemClasses = clsx(
		tw`flex select-none transition-colors items-center gap-1.5`,
		MENU_VARIANT_PADDING_CLASSES[menuItemVariant],
		isActive && !disabled ? tw`bg-c_bg_02` : item.selected ? tw`bg-c_bg_05` : "",
		disabled ? tw`text-c_text_disabled cursor-not-allowed` : textColor
	);

	const wrapperContents = (
		<>
			<div className={clsx(!subText && "items-center", "flex gap-3 overflow-hidden")}>
				<Icon icon={icon} variant={iconVariant} className={`flex ${disabled ? "text-c_icon_disabled" : iconColor}`} />
				<div className={clsx(subText && "flex flex-col items-start gap-1.5 text-start", "truncate")}>
					{subText ? (
						<>
							<span className="w-full truncate">{text}</span>
							<span
								className={clsx(
									"text-body-sm w-full truncate",
									disabled ? "text-c_text_disabled" : "text-c_text_secondary"
								)}
							>
								{subText}
							</span>
						</>
					) : (
						text
					)}
				</div>
			</div>
			{typeof badge === "string" ? <Badge text={badge} /> : null}
			{typeof badge === "object" ? <Badge {...badge} /> : null}
			<Icon
				icon={isSubMenu ? ICONS.caretRight : undefined}
				size="sm"
				className="text-c_icon_regular ml-auto flex pl-1.5"
			/>
		</>
	);

	const sharedProps = useMenuItemProps({
		className: itemClasses,
		label: text,
		isActive,
		disabled,
		itemOnClick: isButton && item.onClick ? item.onClick : undefined,
		preventClickEvent: Boolean(isSubPopover),
	});

	if (isSubPopover) {
		sharedProps.onPointerLeave = undefined;

		return (
			<Popover placement={item.subPopoverPlacement} keepPopoverMounted={true}>
				<PopoverTrigger>
					<button {...sharedProps}>{wrapperContents}</button>
				</PopoverTrigger>
				<PopoverContent>
					<PopoverContainer className="-m-4">{item.subPopover}</PopoverContainer>
				</PopoverContent>
			</Popover>
		);
	}

	let itemContents;

	if (isLink) {
		const link = item.link as string;

		sharedProps.target = item.targetBlank ? "_blank" : undefined;
		sharedProps.rel = item.targetBlank ? "noopener noreferrer" : "";

		if (link.startsWith("mailto:") || link.startsWith("tel:")) {
			itemContents = (
				<a href={link} {...sharedProps}>
					{wrapperContents}
				</a>
			);
		} else {
			itemContents = (
				<Link to={link} {...sharedProps}>
					{wrapperContents}
				</Link>
			);
		}
	} else if (isButton) {
		itemContents = <button {...sharedProps}>{wrapperContents}</button>;
	} else {
		itemContents = <div {...sharedProps}>{wrapperContents}</div>;
	}

	if (isSubMenu) {
		const { placement, widthVariant, menuVariant } = item.subMenuPopoverOptions || {};

		return (
			<PopoverMenu menuContents={item.subMenu} placement={placement} widthVariant={widthVariant} variant={menuVariant}>
				{itemContents}
			</PopoverMenu>
		);
	}

	return item.tooltip ? (
		// Tooltip component doesn't work as it is displayed outside the boundaries of the popover menu container and is
		// hidden due to overflow styling
		<HoverPopover floatingStrategy="absolute" placement="right-start">
			<PopoverTrigger>{itemContents}</PopoverTrigger>
			<PopoverContent initialFocus={-1}>
				<TooltipContainer text={item.tooltip} />
			</PopoverContent>
		</HoverPopover>
	) : (
		itemContents
	);
};
