import clsx from "clsx";

import { LogoProps, SIZE_CLASSES } from "../types";

export function LogoSkeleton({ size = "md" }: Pick<LogoProps, "size">) {
	const sizeClass = SIZE_CLASSES[size];

	return (
		<div
			className={clsx(
				sizeClass,
				size === "xs" && "border-c_bg_01 border",
				"bg-c_bg_disabled_02 shrink-0 animate-pulse"
			)}
		/>
	);
}
