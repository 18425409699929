import { Note, NoteRequest, MyNoteReactionPath, NoteReaction } from "@salesdesk/salesdesk-schemas";
import { Auth } from "../../../auth";
import { SDApi, withWorkspaceContext } from "../../api";

interface GetNoteArg {
	recordId: number;
	noteId: string;
	workspaceId?: number;
}

interface GetNotesArg {
	recordId: number;
	workspaceId?: number;
}

interface CreateNoteArg {
	recordId: number;
	note: NoteRequest;
	workspaceId?: number;
}

interface DeleteNoteArg {
	recordId: number;
	noteId: string;
	workspaceId?: number;
}

interface PutNoteArg {
	recordId: number;
	noteId: string;
	note: NoteRequest;
	workspaceId?: number;
}

export const notesApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getNote: builder.query<Note, GetNoteArg>({
				query: ({ recordId, noteId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/notes/${noteId}`, workspaceId),
				}),
				providesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
			getNotes: builder.query<Note[], GetNotesArg>({
				query: ({ recordId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/notes`, workspaceId),
				}),
				providesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
			createNote: builder.mutation<Note, CreateNoteArg>({
				query: ({ recordId, workspaceId, note }) => ({
					url: withWorkspaceContext(`/records/${recordId}/notes`, workspaceId),
					method: "POST",
					body: note,
				}),
				invalidatesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
			editNote: builder.mutation<"OK", PutNoteArg>({
				query: ({ recordId, noteId, workspaceId, note }) => ({
					url: withWorkspaceContext(`/records/${recordId}/notes/${noteId}`, workspaceId),
					method: "PUT",
					body: note,
				}),
				onQueryStarted: async ({ recordId, noteId, note: updatedNote }: PutNoteArg, { dispatch, queryFulfilled }) => {
					const userRecord = Auth.getUser();

					const cacheUpdate = dispatch(
						notesApi.util.updateQueryData("getNotes", { recordId: Number(recordId) }, (currentNotes) => {
							const note = currentNotes?.find((note: Note) => note.id === noteId);

							if (note && userRecord) {
								note.updatedAt = Date.now();
								note.message = updatedNote.message;
							}
						})
					);

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
			deleteNote: builder.mutation<"OK", DeleteNoteArg>({
				query: ({ recordId, noteId, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/notes/${noteId}`, workspaceId),
					method: "DELETE",
				}),
				invalidatesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
			addReaction: builder.mutation<void, MyNoteReactionPath & { workspaceId?: number }>({
				query: ({ recordId, noteId, emoji, workspaceId }) => {
					return {
						url: withWorkspaceContext(`/records/${recordId}/notes/${noteId}/reactions/me/${emoji}`, workspaceId),
						method: "PUT",
					};
				},
				onQueryStarted: async ({ recordId, noteId, emoji, workspaceId }, { dispatch, queryFulfilled }) => {
					const userRecord = Auth.getUser();

					if (!userRecord) {
						return;
					}

					const cacheUpdate = dispatch(
						notesApi.util.updateQueryData("getNotes", { recordId: Number(recordId), workspaceId }, (currentNotes) => {
							const note = currentNotes?.find((note: Note) => note.id === noteId);

							if (!note) {
								return;
							}

							const reaction = note.reactions?.find((reaction: NoteReaction) => reaction.emoji === emoji);

							if (reaction) {
								reaction.userRecordIds.push(userRecord._id);
							} else {
								note.reactions?.push({ emoji, createdAt: Date.now(), userRecordIds: [userRecord._id] });
							}
						})
					);

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
			deleteReaction: builder.mutation<void, MyNoteReactionPath & { workspaceId?: number }>({
				query: ({ recordId, noteId, emoji, workspaceId }) => ({
					url: withWorkspaceContext(`/records/${recordId}/notes/${noteId}/reactions/me/${emoji}`, workspaceId),
					method: "DELETE",
				}),
				onQueryStarted: async ({ recordId, noteId, emoji, workspaceId }, { dispatch, queryFulfilled }) => {
					const userRecord = Auth.getUser();

					if (!userRecord) {
						return;
					}

					const cacheUpdate = dispatch(
						notesApi.util.updateQueryData("getNotes", { recordId: Number(recordId), workspaceId }, (currentNotes) => {
							const note = currentNotes?.find((note: Note) => note.id === noteId);

							if (!note) {
								return;
							}

							const reaction = note.reactions?.find((reaction: NoteReaction) => reaction.emoji === emoji);

							if (!reaction) {
								return;
							}

							reaction.userRecordIds = reaction.userRecordIds.filter((id: number) => id !== userRecord._id);

							if (reaction.userRecordIds.length === 0) {
								Object.assign(note, {
									...note,
									reactions: note.reactions.filter((r: NoteReaction) => r.emoji !== emoji),
								});
							}

							console.log(JSON.stringify(note.reactions[0]));
						})
					);

					try {
						await queryFulfilled;
					} catch {
						cacheUpdate.undo();
					}
				},
				invalidatesTags: (tag, res, arg) => [{ type: "Notes", id: arg.recordId }],
			}),
		};
	},
});

export const {
	useGetNoteQuery,
	useGetNotesQuery,
	useCreateNoteMutation,
	useEditNoteMutation,
	useDeleteNoteMutation,
	useAddReactionMutation,
	useDeleteReactionMutation,
} = notesApi;
