import {
	Control,
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
	FieldValues,
	Path,
	RegisterOptions,
} from "react-hook-form";
import { InputValidationState, FieldSet } from "@salesdesk/daisy-ui";

import { ReactNode } from "react";
import { UseFormStateReturn } from "react-hook-form/dist/types";

interface FormFieldSetProps<TFormData extends FieldValues> {
	control: Control<TFormData, unknown>;
	name: Path<TFormData>;
	label: string;
	labelIcon?: string;
	description?: string;
	required?: boolean;
	helperText?: ReactNode;
	isBoolean?: boolean;
	rules?: RegisterOptions;
	isOverflowHidden?: boolean;
	children: (props: {
		field: ControllerRenderProps<TFormData, Path<TFormData>>;
		fieldState: ControllerFieldState;
		formState: UseFormStateReturn<TFormData>;
	}) => ReactNode;
}

export function FormFieldSet<TFormData extends FieldValues>({
	control,
	name,
	label,
	labelIcon,
	description,
	required,
	helperText,
	isBoolean,
	rules,
	children,
	isOverflowHidden = false,
}: FormFieldSetProps<TFormData>) {
	return (
		<Controller
			control={control}
			name={name}
			render={(renderProps) => {
				const { fieldState } = renderProps;
				const validationState = fieldState.error ? InputValidationState.error : InputValidationState.initial;

				return (
					<FieldSet
						label={label}
						labelIcon={labelIcon}
						description={description}
						validationState={validationState}
						helperText={fieldState.error?.message || helperText}
						required={required}
						isBoolean={isBoolean}
						isOverflowHidden={isOverflowHidden}
					>
						{children(renderProps)}
					</FieldSet>
				);
			}}
			rules={rules}
		/>
	);
}
