import { NavButton } from "../../../MainLayout/Navbar/components/NavButton";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useEffect, useRef, useState } from "react";
import { FavoritePopoverContainer } from "./FavoritePopoverContainer";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../components/Popover";
import { Tooltip } from "@salesdesk/daisy-ui";
import { useLazyGetFavoritedRecordsQuery } from "../../api/favoritesApi";

const FAVORITE_LABEL = "Favorites";

interface FavoriteNavPopoverProps {
	expanded: boolean;
}

export function FavoriteNavPopover({ expanded }: FavoriteNavPopoverProps) {
	const [open, setOpen] = useState(false);
	const navButtonRef = useRef<HTMLButtonElement>(null);

	const initialMount = useRef(true);

	//  Store favorite record ids outside of popover for faster load on open
	const [favoriteRecordIds, setFavoriteRecordIds] = useState<number[] | undefined>(undefined);
	const [getFavoriteRecordIds] = useLazyGetFavoritedRecordsQuery();

	useEffect(() => {
		if (!open && !initialMount.current) {
			initialMount.current = false;
			return;
		}
		// We're using a lazy query to avoid automatic re-fetching when removing a favorite within the popover.
		// We don't want that because of the grace period for the undo action.
		getFavoriteRecordIds()
			.unwrap()
			.then((data) => {
				setFavoriteRecordIds(data);
			})
			.catch(() => {
				console.error("Failed to get favorite record ids");
			});
	}, [getFavoriteRecordIds, open]);

	return (
		<Popover placement="right" open={open} onOpenChange={setOpen}>
			<Tooltip text={expanded ? undefined : FAVORITE_LABEL} placement="right">
				<PopoverTrigger>
					<NavButton
						ref={navButtonRef}
						expanded={expanded}
						icon={ICONS.star}
						text={FAVORITE_LABEL}
						isSelected={false}
					/>
				</PopoverTrigger>
			</Tooltip>
			<PopoverContent>
				<PopoverContainer>
					<FavoritePopoverContainer favoriteRecordIds={favoriteRecordIds} />
				</PopoverContainer>
			</PopoverContent>
		</Popover>
	);
}
