import { useMemo } from "react";

import { ASSET_IDS, mAssetDef, mInteractionMeetingDef, mTaskDef } from "@salesdesk/salesdesk-model";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { SDObject } from "@salesdesk/salesdesk-schemas";

import { Icon, Tooltip } from "@salesdesk/daisy-ui";
import { useGetObjectMap } from "../../../../objects/hooks";
import { AssociationsSummaryData } from "../types";

interface AssociationSummaryProps {
	summaryData: AssociationsSummaryData;
	groupSummaries?: boolean;
}

export function RecordAssociationSummary({ summaryData, groupSummaries }: AssociationSummaryProps) {
	const objectMap = useGetObjectMap();

	const summaryDetails: AssociationSummaryDetail[] = useMemo(() => {
		return generateRecordAssociationSummaryDetails(summaryData, objectMap, groupSummaries);
	}, [summaryData, objectMap, groupSummaries]);

	return (
		<div className="flex items-center gap-3">
			{summaryDetails.map((details) => {
				return (
					<Tooltip key={details.id} text={details.label}>
						<div className="text-c_text_secondary text-label-sm flex items-center gap-1">
							<Icon size="sm" icon={details.icon} className="text-c_icon_regular flex" />
							{details.count}
						</div>
					</Tooltip>
				);
			})}
		</div>
	);
}

interface AssociationSummaryDetail {
	id: number | string;
	count: number;
	label: string;
	icon?: string;
}

const ASSET_OBJECT_IDS = Object.values(ASSET_IDS);
const UNGROUPED_ASSOCIATIONS = [mAssetDef.ID, mTaskDef.ID, mInteractionMeetingDef.ID];
const GROUPED_SUMMARY_ID = "grouped";

/**
 * Generates the detailed summary information for a record's associations.
 * If `groupSummaries` is true, all associations not in `UNGROUPED_ASSOCIATIONS` will be
 * grouped together in a single entry.
 */
function generateRecordAssociationSummaryDetails(
	summaryData: AssociationsSummaryData,
	objectMap: Map<number, SDObject>,
	groupSummaries?: boolean
): AssociationSummaryDetail[] {
	const detailsMap: { [key: string | number]: AssociationSummaryDetail } = {};

	summaryData.forEach((summary) => {
		const { count } = summary;
		let { objectId } = summary;

		if (ASSET_OBJECT_IDS.includes(objectId)) {
			objectId = mAssetDef.ID;
		}

		const sdObject = objectMap.get(objectId);

		if (!sdObject) return;

		const isGroupedSummary = groupSummaries && !UNGROUPED_ASSOCIATIONS.includes(objectId);

		const detailsId = isGroupedSummary ? GROUPED_SUMMARY_ID : objectId;

		const totalCount = (detailsMap[detailsId]?.count ?? 0) + count;

		if (!isGroupedSummary) {
			detailsMap[detailsId] = {
				id: detailsId,
				count: totalCount,
				icon: sdObject._icon,
				label: `${totalCount} ${totalCount === 1 ? sdObject._displayName : sdObject._pluralName}`,
			};

			return;
		}

		const groupedSummary = detailsMap[detailsId] ?? {
			id: detailsId,
			count: totalCount,
			label: "",
			icon: ICONS.linkFile,
		};

		groupedSummary.count = totalCount;
		groupedSummary.label += `${groupedSummary.label ? "\n" : ""}${count} ${
			count === 1 ? sdObject._displayName : sdObject._pluralName
		}, `;

		detailsMap[detailsId] = groupedSummary;
	});

	if (detailsMap[GROUPED_SUMMARY_ID]) {
		detailsMap[GROUPED_SUMMARY_ID].label = detailsMap[GROUPED_SUMMARY_ID].label.slice(0, -2); // Remove trailing comma and space
	}

	// Sorted so that the grouped summary is first
	return Object.values(detailsMap).sort((a, b) =>
		a.id === GROUPED_SUMMARY_ID ? -1 : b.id === GROUPED_SUMMARY_ID ? 1 : 0
	);
}
