import { useCallback } from "react";

import { RecordSidePanel } from "../../../records";
import { useBoardPropOnChangeContext } from "../../hooks/useBoardPropOnChangeContext";
import { useDataboardDetailsContext } from "../../hooks/useDataboardDetailsContext";
import { useBoardRecordIdSelector } from "../../store";
import { ObjectBoardView } from "../ObjectBoardView/ObjectBoardView";
import { InternalBoardTopBar } from "./InternalBoardTopBar/InternalBoardTopBar";

import { memo } from "react";

function InternalObjectBoardComponent() {
	const { sdObject } = useDataboardDetailsContext();
	const boardPropOnChange = useBoardPropOnChangeContext();

	const recordId = useBoardRecordIdSelector();

	const closeRecordPanel = useCallback(() => {
		boardPropOnChange("recordId", undefined);
	}, [boardPropOnChange]);

	if (!sdObject) {
		return null;
	}

	return (
		<>
			<div className="flex h-full flex-col overflow-hidden pl-6">
				<InternalBoardTopBar />
				<ObjectBoardView />
			</div>
			<RecordSidePanel recordId={recordId ? Number(recordId) : undefined} onClose={closeRecordPanel} />
		</>
	);
}

export const InternalObjectBoard = memo(InternalObjectBoardComponent);
