import { useEffect, useMemo } from "react";
import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";

import { ThemeName, UI_THEMES } from "@salesdesk/salesdesk-ui";

import { SingleOptionInputProps } from "../../../../../inputs";

export function ThemeSelector({ id, value, onChange, options }: SingleOptionInputProps) {
	// Ensures the options are flattened & valid theme options
	const themeOptions = useMemo(() => {
		return options
			.flatMap((optionSection) =>
				Array.isArray(optionSection)
					? optionSection
					: "options" in optionSection
					? optionSection.options
					: optionSection
			)
			.filter((option) => option.name.toLowerCase() in UI_THEMES);
	}, [options]);

	// Tries to default to the grey theme if no theme or an invalid theme is selected
	useEffect(() => {
		const selectedOption = themeOptions.find((option) => option.id === value);

		if (selectedOption) {
			return;
		}

		const greyTheme = themeOptions.find((option) => option.name.toLowerCase() === "grey");

		if (greyTheme) {
			onChange(greyTheme.id);
		}
	}, [value, themeOptions, onChange]);

	return (
		<RadioGroup value={value} onChange={onChange}>
			<div className="flex flex-wrap gap-x-14 gap-y-8 px-8 pt-6">
				{themeOptions.map((option) => (
					<RadioGroup.Option
						key={option.id}
						value={option.id}
						className="relative flex h-[75px] w-12 cursor-pointer items-start"
					>
						{({ active, checked }) => {
							const theme = UI_THEMES[option.name.toLowerCase() as ThemeName];

							const brandOffWhite = theme.c_brand_off_white;
							const brandPrimary = theme.c_brand_primary;

							const gradient = `linear-gradient(45deg, ${brandOffWhite} 49%, ${brandOffWhite} 50%, ${brandPrimary} 51%, ${brandPrimary} 100%)`;

							return (
								<>
									<div
										className={clsx(
											"size-12 rounded-full",
											(active || checked) && "ring-c_action_focus ring ring-offset-2 transition-all"
										)}
										style={{
											background: gradient,
										}}
									/>
									<RadioGroup.Label className="text-c_text_secondary text-label-sm pointer-events-none absolute bottom-0 left-1/2 -translate-x-1/2 transform">
										{option.name}
									</RadioGroup.Label>
								</>
							);
						}}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
}
