import { asConst } from "json-schema-to-ts";
import { emojiSchema, userIdSchema, utcMillisecSchema } from "../../components";

export const commentReactionSchema = asConst({
	title: "Comment Reaction",
	description: "An emoji and the user record ids that have raised it",
	type: "object",
	additionalProperties: false,
	required: ["emoji", "createdAt", "userRecordIds"],
	properties: {
		emoji: emojiSchema,
		createdAt: utcMillisecSchema,
		userRecordIds: { type: "array", items: userIdSchema },
	},
});
