import { asConst } from "json-schema-to-ts";
import { fieldValueFieldIdSchema, fieldValueIdSchema, fieldValueValueSchema } from "./properties";

export const fieldValueSchema = asConst({
	title: "SDRecord Field Value",
	description: "The SDRecord Field value that corresponds to an SDObject Field",
	type: "object",
	additionalProperties: false,
	required: ["_fieldId", "_value"],
	properties: {
		_id: fieldValueIdSchema, //TODO: Is this required??
		_fieldId: fieldValueFieldIdSchema,
		_value: fieldValueValueSchema,
	},
});
