import { getSDRecordSystemFieldValueByFieldName, SDFile, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { useMemo } from "react";
import { useSdFile } from "./useSdFile";

interface SdFileHookResult {
	sdFile: SDFile | undefined;
	isLoading: boolean;
}

export function useSdFileByFieldName(
	sdObject: SDObject | undefined,
	sdRecord: SDRecord | undefined,
	fieldName: string,
	fileDisplayName?: string
): SdFileHookResult {
	const fileId = useMemo(() => {
		if (!sdObject || !sdRecord) {
			return null;
		}
		const value = getSDRecordSystemFieldValueByFieldName(sdObject, sdRecord, fieldName)?._value;
		if (!value || typeof value !== "number") {
			return null;
		}
		return value;
	}, [fieldName, sdObject, sdRecord]);

	const { sdFile, isLoading } = useSdFile(fileId);

	return {
		sdFile: sdFile && fileDisplayName ? { ...sdFile, name: fileDisplayName } : sdFile,
		isLoading: isLoading || fileId === null,
	};
}
