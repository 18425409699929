import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { NavButton } from "./NavButton";
import { PopoverMenu, MenuItem } from "../../../menu";
import { isOnCurrentPath } from "../../../../components/Navbar";

interface CustomObjectNavPopoverProps {
	menuItems: MenuItem[];
	expanded: boolean;
}

const CUSTOM_OBJECTS_LABEL = "Custom Objects";

export function CustomObjectNavPopover({ menuItems, expanded }: CustomObjectNavPopoverProps) {
	const currentLocation = useLocation();

	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		setIsSelected(
			menuItems.some((menuItem) =>
				menuItem.type === "link" ? isOnCurrentPath(currentLocation.pathname, menuItem.link) : false
			)
		);
	}, [currentLocation, menuItems]);

	return (
		<PopoverMenu
			menuContents={menuItems}
			placement="right"
			tooltipText={expanded ? undefined : CUSTOM_OBJECTS_LABEL}
			tooltipPlacement="right"
		>
			<NavButton expanded={expanded} icon={ICONS.cube} text={CUSTOM_OBJECTS_LABEL} isSelected={isSelected} />
		</PopoverMenu>
	);
}
