import { PopoverContainer, PopoverContent } from "../../../components/Popover";
import { PropsWithChildren } from "react";
import { CorePopover } from "../../../components/Popover/Popover";
import { Point } from "../../../utils/types";

interface MentionSuggestionPopoverProps {
	clientPointDetails: Point;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function MentionPopoverWrapper({
	clientPointDetails,
	open,
	onOpenChange,
	children,
}: PropsWithChildren<MentionSuggestionPopoverProps>) {
	return (
		<CorePopover
			isHoverPopover={false}
			useFloatingPortal={true}
			open={open}
			onOpenChange={onOpenChange}
			placement="top-start"
			fallbackPlacements={["top", "top-end"]}
			clientPointAxis="both"
			clientPointDetails={clientPointDetails}
		>
			<PopoverContent initialFocus={-1}>
				<PopoverContainer className="min-w-[280px] max-w-[280px]">{children}</PopoverContainer>
			</PopoverContent>
		</CorePopover>
	);
}
