import { asConst } from "json-schema-to-ts";
import { appendEditableForCustom } from "../../../functions";
import { FIELD_MULTIPLICITY } from "@salesdesk/salesdesk-model";

export const fieldMultiplicitySchema = asConst({
	title: "SDObject Field Multiplicity",
	description: appendEditableForCustom("Indicates whether more than one value can be added"),
	type: "integer",
	enum: Object.values(FIELD_MULTIPLICITY),
});
