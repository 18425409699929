import { asConst } from "json-schema-to-ts";
import { recordQueryClausesSchema } from "./queryClauses";
import { querySchema } from "../../search";

export const recordQuerySchema = asConst({
	...querySchema,
	properties: {
		and: {
			...querySchema.properties.and,
			items: recordQueryClausesSchema,
		},
		not: {
			...querySchema.properties.not,
			items: recordQueryClausesSchema,
		},
		or: {
			...querySchema.properties.or,
			items: recordQueryClausesSchema,
		},
	},
});
