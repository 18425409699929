import { asConst } from "json-schema-to-ts";
import { appendNotEditable } from "../../functions";
import { userIdSchema } from "../../components";

export const recordLastModifiedBySchema = asConst({
	...userIdSchema,
	title: "SDRecord Last Modified By",
	description: appendNotEditable("The user that last modified the SDRecord"),
	nullable: true,
});
