import { SelectOption } from "../../../inputs";
import { SdEventType } from "@salesdesk/salesdesk-model";
import {
	getSDObjectFieldById,
	getSDRecordName,
	NotificationChannel,
	NotificationChannelType,
	NotificationRule,
	NotificationRuleConfig,
	NotificationRuleCreateRequest,
	NotificationRuleType,
	SDObject,
	SDRecord,
} from "@salesdesk/salesdesk-schemas";
import { capitalizeString } from "@salesdesk/salesdesk-utils";
import { ANY, AnyActionOptions, ERROR } from "../types/Any";

export function toEventType(option: SelectOption): SdEventType | null {
	if (!option || option.id === ANY || option.id === ERROR) return null;
	if (!Object.values(SdEventType).includes(option?.id as SdEventType)) return null;
	return option.id as SdEventType;
}

export function toFieldId(option: SelectOption | undefined): number | null {
	if (!option || option.id === ERROR) return null;
	return Number(option.id);
}

export function toUserIds(options: SelectOption[] | undefined): number[] {
	if (!options) return [];
	return options.filter((option) => option.id !== ERROR).map((option) => Number(option.id));
}

export function toRecordId(option: SelectOption | undefined): number | null {
	if (!option || option.id === ANY || option.id === ERROR) return null;
	return Number(option.id);
}

export function toChannels(options: SelectOption[] | undefined): NotificationChannel[] {
	if (!options) return [];
	return options
		.filter((option) => option.id !== ERROR)
		.map((option) => {
			const channelType = option.id as NotificationChannelType;
			if (channelType === NotificationChannelType.Zapier) {
				throw new Error("Zapier channel is not supported");
			}
			return {
				channelType: channelType,
			};
		});
}

export function toActionOption(
	eventType: SdEventType | null,
	ruleConfig: NotificationRuleConfig | undefined,
	ruleType: NotificationRuleType
): SelectOption {
	const anyOption = AnyActionOptions[ruleType];
	if (eventType == null) return anyOption;
	if (!ruleConfig) return anyOption; // TODO: Review after UX decision on error handling: ErrorOption;
	return { id: ruleConfig.eventType, name: ruleConfig.titles[ruleType].toLowerCase() };
}

export function toFieldOption(object: SDObject | undefined, fieldId: number | null): SelectOption | null {
	if (!object || !fieldId) return null;
	const field = getSDObjectFieldById(object, fieldId);
	if (!field) return null; // TODO: Review after UX decision on error handling: ErrorOption;
	return {
		id: field._id,
		name: field._displayName,
	};
}

export function toUserOptions(
	userIds: number[],
	userObject: SDObject,
	users: SDRecord[] | undefined
): SelectOption[] | null {
	if (!userIds.length || !users) return null;
	const userIdUserMap = new Map<number, SDRecord>(users.map((user) => [user._id, user]));
	const userOptions = userIds
		.map((userId) => {
			const user = userIdUserMap.get(userId);
			if (!user) return null; // TODO: Review after UX decision on error handling: ErrorOption;
			return {
				id: String(user._id),
				name: getSDRecordName(userObject, user),
			};
		})
		.filter((option) => option != null) as SelectOption[];
	if (userOptions.length === 0) return null;
	return userOptions;
}

export function toWorkspaceOption(
	workspaceId: number | null | undefined,
	object: SDObject | undefined,
	record: SDRecord | undefined
): SelectOption | undefined {
	if (!workspaceId) return undefined;
	if (!record) return undefined; // TODO: Review after UX decision on error handling: ErrorOption;
	if (!object) return undefined;
	return {
		id: String(record._id),
		name: getSDRecordName(object, record),
	};
}

export function toRecordOption(
	recordId: number | undefined | null,
	object: SDObject | undefined,
	record: SDRecord | undefined
): SelectOption | undefined {
	if (!recordId)
		return {
			id: ANY,
			name: `Any ${object?._displayName.toLowerCase() ?? "record"}`,
		};
	if (!record) return undefined; // TODO: Review after UX decision on error handling: ErrorOption;
	if (!object) return undefined;
	return {
		id: String(record._id),
		name: getSDRecordName(object, record),
	};
}

export function toChannelOptions(channels: NotificationChannelType[]): SelectOption[] {
	return channels.map((channel) => ({ id: channel, name: capitalizeString(channel) }));
}

export function toCreateRequest(rule: NotificationRule): NotificationRuleCreateRequest {
	return {
		objectId: rule.objectId,
		eventType: rule.eventType,
		ruleType: rule.ruleType,
		fieldId: rule.fieldId,
		userIds: rule.userIds,
		recordId: rule.recordId,
		channels: rule.channels,
		enabled: rule.enabled,
	};
}
