import clsx from "clsx";

import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";

interface PreviewProfilePhotoFieldProps {
	error?: boolean;
}

export function PreviewProfilePhotoField({ error }: PreviewProfilePhotoFieldProps) {
	return (
		<div
			className={clsx(
				"bg-c_action_04 flex size-20 flex-col items-center justify-center rounded-full border-2",
				error ? "border-c_danger_02" : "border-c_border_regular"
			)}
		>
			<Icon icon={ICONS.plus} size="lg" className="text-c_icon_inverted flex" />
		</div>
	);
}
