import { useState, memo } from "react";
import clsx from "clsx";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import { NodeViewProps } from "@tiptap/core";

import { Button } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { addImagesToEditorOptimistically } from "../../utils/SdFileImageExtension";

import { PopoverMenu } from "../../../../../menu";
import { HeadlessFileInput } from "./HeadlessFileInput";

function CustomParagraphNodeComponent({ editor, node, getPos }: NodeViewProps) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleImageFileSelect = (files: FileList) => {
		editor.commands.setTextSelection(getPos() + node.nodeSize);
		addImagesToEditorOptimistically(editor, files);
	};

	return (
		<NodeViewWrapper>
			<HeadlessFileInput fileType="image" onFileSelect={handleImageFileSelect} multiple>
				{({ openFileSelect: openImageFileSelect }) => (
					<div className="group relative">
						<div
							className={clsx(
								"absolute -left-8 top-0 my-0.5 w-8 transition-opacity",
								!isMenuOpen && "opacity-0 group-hover:opacity-100"
							)}
							contentEditable={false}
						>
							<PopoverMenu
								onOpenChange={(isOpen) => setIsMenuOpen(isOpen)}
								placement="bottom-start"
								variant="compact"
								menuContents={[
									{
										sectionTitle: "Common",
										items: [
											{
												text: "Media",
												icon: ICONS.image,
												type: "subMenu",
												subMenu: [
													{
														text: "Image",
														icon: ICONS.image,
														onClick: () => {
															openImageFileSelect();
															setIsMenuOpen(false);
														},
														type: "button",
													},
												],
											},
										],
									},
								]}
							>
								<Button startIcon={ICONS.plus} variant="secondary" size="xs" />
							</PopoverMenu>
						</div>
						<div
							className={clsx({
								"text-right": node.attrs.textAlign === "right",
								"text-center": node.attrs.textAlign === "center",
								"text-justify": node.attrs.textAlign === "justify",
							})}
						>
							<NodeViewContent />
						</div>
					</div>
				)}
			</HeadlessFileInput>
		</NodeViewWrapper>
	);
}

export const CustomParagraphNode = memo(CustomParagraphNodeComponent);
