import { useState, useMemo } from "react";

import { FieldInfo, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { RecordDetailsInlineField } from "./RecordDetailsInlineField";
import { Button } from "@salesdesk/daisy-ui";

interface RecordDetailsFieldsProps {
	sdObject: SDObject;
	sdRecord: SDRecord;
	recordFields: FieldInfo[];
	initialFieldsDisplayed?: number;
}

export function RecordDetailsFields({
	sdObject,
	sdRecord,
	recordFields,
	initialFieldsDisplayed,
}: RecordDetailsFieldsProps) {
	const fieldsAmount = recordFields.length;

	const [isAllFieldsShown, setAllFieldsShown] = useState(
		initialFieldsDisplayed == null ? true : fieldsAmount <= initialFieldsDisplayed
	);

	const displayedRecordFields = useMemo(
		() => (isAllFieldsShown ? recordFields : recordFields.slice(0, initialFieldsDisplayed)),
		[isAllFieldsShown, recordFields, initialFieldsDisplayed]
	);

	return (
		/*
			mt-0.5 - small space to not cut off focused visible styles for the first field
		*/
		<div className="mt-0.5 flex flex-col gap-1">
			{displayedRecordFields.map((recordField) => (
				<RecordDetailsInlineField
					key={recordField.field._id}
					sdObject={sdObject}
					sdRecord={sdRecord}
					field={recordField.field}
				/>
			))}
			{!isAllFieldsShown ? (
				<Button size="sm" variant="primary_text" onClick={() => setAllFieldsShown(true)}>
					Show all ({fieldsAmount})
				</Button>
			) : null}
		</div>
	);
}
