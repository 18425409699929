import { ReactNodeViewRenderer } from "@tiptap/react";
import Paragraph from "@tiptap/extension-paragraph";

import { tw } from "@salesdesk/daisy-ui";

import { getFrontEndRichTextExtensions } from "../../../../../utils/getFrontEndRichTextExtensions";
import { SdFileImageNode } from "../components/ImageNode/SdFileImageNode";
import { CustomParagraphNode } from "../components/CustomParagraphNode/CustomParagraphNode";
import { CommentExtension } from "./CommentExtension";
import { SdFileImageExtension } from "./SdFileImageExtension";

export function getDocumentExtensions(
	isEditable: boolean,
	onActiveCommentsChange?: (activeComments: string[]) => void
) {
	const extensions = [
		...getFrontEndRichTextExtensions({ enableParagraph: !isEditable }),
		SdFileImageExtension.extend({
			addNodeView: () => ReactNodeViewRenderer((props: any) => <SdFileImageNode {...props} isReadonly={!isEditable} />),
		}).configure({
			allowImageDrop: isEditable,
		}),
		CommentExtension.configure({
			HTMLAttributes: {
				class: tw`tiptap-comment`,
			},
			onActiveCommentsChange: (activeComments) => {
				onActiveCommentsChange?.(activeComments);
			},
		}),
	];
	if (isEditable) {
		extensions.push(
			Paragraph.extend({
				addNodeView: () => ReactNodeViewRenderer(CustomParagraphNode),
			})
		);
	}
	return extensions;
}
