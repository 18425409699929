import { asConst } from "json-schema-to-ts";
import { recordIdStringSchema } from "./eventsQuerySchema";
import { commaDelimitedIdsSchema } from "../components";

const isoDatePattern = "[0-9]{4}-[0-9]{2}-[0-9]{2}";

export const eventsSummaryQuerySchema = asConst({
	title: "Events Summary Request",
	description: "Querystring parameters of a request to retrieve aggregated event data for a record",
	type: "object",
	additionalProperties: false,
	properties: {
		recordId: {
			...recordIdStringSchema,
			title: "Record ID",
			description: "ID of the record to query events for",
		},
		userIds: {
			...commaDelimitedIdsSchema,
			title: "User IDs",
			description: "Comma-delimited user IDs to search on",
		},
		eventTypes: {
			title: "Event type",
			description: "Comma-delimited event types to search on",
			// TODO: once events are enumerated in TypeScript, only allow valid event names
			type: "string",
		},
		eventSupertypes: {
			title: "Event type",
			description: "Comma-delimited event supertypes to search on",
			type: "string",
		},
		inclusiveStartDate: {
			title: "Start Date",
			description: "First aggregation bucket will start at midnight at the start of this day",
			type: "string",
			pattern: isoDatePattern,
		},
		inclusiveEndDate: {
			title: "Inclusive Start Date",
			description: "Last aggregation bucket will end at midnight at the end of this day",
			type: "string",
			pattern: isoDatePattern,
		},
	},
});
