import { RefObject, useEffect, useState } from "react";
import { BaseSelectPopoverWidth } from "../types";
import { debounce } from "../../../../../utils";

const MINIMUM_POPOVER_WIDTH = 144;

const SELECT_POPOVER_WIDTH_STYLES: { [key in BaseSelectPopoverWidth]: string | undefined } = {
	match_button: undefined,
	sm: "144px",
	md: "244px",
	full: "100%",
};

export function useSelectPopoverWidth(
	selectButtonRef: RefObject<HTMLButtonElement>,
	popoverWidth: BaseSelectPopoverWidth = "full",
	selectKey?: number | string
) {
	const [selectWidth, setSelectWidth] = useState(SELECT_POPOVER_WIDTH_STYLES[popoverWidth]);

	useEffect(() => {
		const selectButton = selectButtonRef?.current;

		if (!selectButton || popoverWidth !== "match_button") {
			return;
		}

		const observer = new ResizeObserver(
			debounce((resizeEntry: ResizeObserverEntry[]) => {
				if (!resizeEntry.length) {
					return;
				}

				let { width } = resizeEntry[0].target.getBoundingClientRect();

				if (width < MINIMUM_POPOVER_WIDTH) {
					width = MINIMUM_POPOVER_WIDTH;
				}

				setSelectWidth(`${width}px`);
			}, 50)
		);
		observer.observe(selectButton);
		return () => {
			// Cleans up the observer by unobserving all elements
			observer.disconnect();
		};
	}, [selectKey, popoverWidth, selectButtonRef]);

	return selectWidth;
}
