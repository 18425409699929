import { isEmpty, sortByIds, stringContainsMatch } from "@salesdesk/salesdesk-utils";
import { mBackingStore } from "../backing_store";

export class mArrayBackingStore extends mBackingStore {
	// Must be implemented by subclasses...
	getData() {
		return [];
	}

	getOptionValuesByQuery(filter, field, callback) {
		// If no match filter is provided, then return all option values.
		if(isEmpty(filter)){
			callback(null, this.getData());
			return;
		}

		const values = [];

		this.getData().forEach((value) => {
			for (const searchableField of this.getSearchableFields()) {
				if (stringContainsMatch(value[searchableField], filter)) {
					values.push(value);
					break;
				}
			}
		});

		callback(null, values);
	}

	getOptionValueById(id, callback) {
		const data = this.getData();

		for (let i = 0; i < data.length; i++) {
			if (data[i].id === id) {
				callback(null, data[i]);
				return;
			}
		}
	}

	getOptionValuesById(ids, callback) {
		const values = [];

		const options = this.getData();
		const idSet = new Set(ids);

		for (let i = 0; options.length > i; i++) {
			const currentOption = options[i];

			if (idSet.has(currentOption.id)) {
				values.push(currentOption);
				idSet.delete(currentOption.id);
			}

			if(idSet.size === 0) {
				break;
			}
		}

		callback(null, sortByIds(values, ids));
	}
}
