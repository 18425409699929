import { ZoomButtons } from "./ZoomButtons";
import { useCurrentVideoTime } from "../../../hooks/useCurrentVideoTime";
import { formatVideoTimestamp } from "../../../../files/components/VideoPlayer/utils";
import { useMediaAnalysisContext } from "../../../hooks/useMediaAnalisysContext";
import clsx from "clsx";

export function GraphControls() {
	const { videoDuration, faceSentimentsPerUser } = useMediaAnalysisContext();
	const currentVideoTime = useCurrentVideoTime() ?? 0;

	// If the face sentiment graph isn't displayed we can't scroll when zoomed in, so let's hide the zoom buttons
	const hideZoomButtons = !faceSentimentsPerUser || Object.entries(faceSentimentsPerUser).length === 0;

	return (
		<div
			className={clsx(
				"bg-c_bg_04 my-2 flex h-10 flex-shrink-0 items-center justify-end gap-3 rounded-sm",
				hideZoomButtons ? "pr-4" : ""
			)}
		>
			<div className="text-body-sm text-c_text_secondary">
				{formatVideoTimestamp(currentVideoTime)} / {formatVideoTimestamp(videoDuration)}
			</div>
			{!hideZoomButtons ? (
				<div>
					<ZoomButtons />
				</div>
			) : null}
		</div>
	);
}
