import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import { WorkspaceOverview } from "./WorkspaceOverview";

export function WorkspaceOverviewPage() {
	return (
		<QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
			<WorkspaceOverview />
		</QueryParamProvider>
	);
}
