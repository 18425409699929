import { useRecordActionOptions } from "../../../../hooks/record/useRecordActionOptions";
import { RuleValueSelect } from "../RuleValueSelect";
import { useNotificationRuleDialogContext } from "../../../../hooks/useNotificationRuleDialogContext";
import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { AnyActionOptions } from "../../../../types/Any";

export function RecordActionValueSelector() {
	const { selectedObjectId, selectedAction, setSelectedAction } = useNotificationRuleDialogContext();
	const { actionOptions } = useRecordActionOptions(NotificationRuleType.Record);

	return (
		<RuleValueSelect
			// This key is required so that the underlying StaticTypeaheadSelect is remounted when the selectedObjectId changes
			// so the same cache isn't used
			key={selectedObjectId}
			title="Action selection"
			value={selectedAction}
			onChange={setSelectedAction}
			selectOptions={actionOptions}
			multiple={false}
			placeholderOption={AnyActionOptions.record}
		/>
	);
}
