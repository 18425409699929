import { ReactNode } from "react";
import {
	AppInstanceUserMembershipSummary,
	ChannelMembershipSummary,
	ChannelMessage,
	ChannelMessageSummary,
	ChannelSummary,
} from "@aws-sdk/client-chime-sdk-messaging";
import { JSONContent } from "@tiptap/core";

import { ChannelMessageMetadata, ChannelMetadata, SDRecord } from "@salesdesk/salesdesk-schemas";

export type ChannelMessageArray = (ChannelMessage | ChannelMessageSummary)[];

export type ChannelMessageGroupMessage = Omit<ChannelMessageSummary | ChannelMessage, "message"> & {
	jsonContent: JSONContent;
	metadata: ChannelMessageMetadata;
};

export type ChannelMessageGroup = {
	userId?: number;
	userRecord: SDRecord | undefined;
	messages: ChannelMessageGroupMessage[];
	displayTimestamp?: boolean;
};

export interface MessagingPanelProps {
	open: boolean;
	onToggleOpen: () => void;
	unreadCount?: number;
	showUnreadDot?: boolean;
	headerElements?: ReactNode;
	newMessageKey?: number;
}

export enum LoadingStatus {
	Loading = "loading",
	Loaded = "loaded",
	Error = "error",
}

export interface ChannelDetails {
	arn: string;
	displayName?: string;
	memberships?: { members?: ChannelMembershipSummary[]; loadingStatus: LoadingStatus };
	channelSummary: ChannelSummary;
	appInstanceUserMembershipSummary: AppInstanceUserMembershipSummary;
	channelMetadata: ChannelMetadata;
	isReadOnly?: boolean;
}

export interface SerializableChannelSummary extends Omit<ChannelSummary, "LastMessageTimestamp"> {
	LastMessageTimestamp?: number;
}

export interface SerializableAppInstanceUserMembershipSummary
	extends Omit<AppInstanceUserMembershipSummary, "ReadMarkerTimestamp"> {
	ReadMarkerTimestamp?: number;
}

export interface SerializableChannelDetails
	extends Omit<ChannelDetails, "channelSummary" | "appInstanceUserMembershipSummary"> {
	channelSummary: SerializableChannelSummary;
	appInstanceUserMembershipSummary: SerializableAppInstanceUserMembershipSummary;
}

export interface SerializableChannelMessage
	extends Omit<ChannelMessage, "CreatedTimestamp" | "LastEditedTimestamp" | "LastUpdatedTimestamp"> {
	CreatedTimestamp?: number;
	LastEditedTimestamp?: number;
	LastUpdatedTimestamp?: number;
}
