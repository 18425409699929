import { FilterFieldType, FilterValue } from "../types";
import { ButtonSelect, DateTimeInput } from "../../../../inputs";
import { DateTimeFieldVariant } from "../../../../fields";
import { Field } from "@salesdesk/salesdesk-schemas";
import {
	exactDateOption,
	parseDateFilterValue,
	PseudoDateRangeByDayOption,
	PseudoDateRangeOption,
	pseudoDateRangeOptions,
	pseudoDateRangesByDay,
	stringifyDateFilterValue,
} from "../utils/pseudoDates";
import { useMemo } from "react";
import { TextInput } from "@salesdesk/daisy-ui";

export interface FilterInputFactoryProps {
	fieldType: FilterFieldType;
	field: Field | null;
	value: number | string | undefined;
	onChange: (newValue: FilterValue) => void;
}

export function DateFilterInput({ fieldType, field, value, onChange }: FilterInputFactoryProps) {
	const pseudoDateSelectOptions = useMemo(
		() => [[exactDateOption], [...pseudoDateRangeOptions], [...pseudoDateRangesByDay]],
		[]
	);

	if (!field) {
		return null;
	}
	const parsedValue = parseDateFilterValue(value);
	return (
		<>
			<ButtonSelect
				value={parsedValue?.option}
				buttonVariant="outlined"
				options={pseudoDateSelectOptions}
				onChange={(newValue) => {
					if (typeof newValue !== "string") {
						return;
					}
					onChange(
						stringifyDateFilterValue({
							option: newValue as PseudoDateRangeOption | PseudoDateRangeByDayOption,
						})
					);
				}}
			/>
			{parsedValue && pseudoDateRangesByDay.some((option) => option.id === parsedValue.option) ? (
				<TextInput
					type="number"
					min={1}
					max={9999}
					value={parsedValue.value?.toString() ?? ""}
					onChange={(newValue) => {
						onChange(
							stringifyDateFilterValue({
								...parsedValue,
								value: newValue ? Number(newValue) : undefined,
							})
						);
					}}
				/>
			) : null}
			{parsedValue?.option === "EXACT_DATE" ? (
				<DateTimeInput
					onChange={(newDate) => {
						onChange(
							stringifyDateFilterValue({
								option: "EXACT_DATE",
								value: newDate || undefined,
							})
						);
					}}
					value={parsedValue.value}
					variant={fieldType === "date" || field._dateOnly ? DateTimeFieldVariant.DATE : DateTimeFieldVariant.DATE_TIME}
				/>
			) : null}
		</>
	);
}
