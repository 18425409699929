import { Navigate, Outlet } from "react-router-dom";
import { ERRORS } from "@salesdesk/salesdesk-ui";

import { DisplayIf, DisplayIfProps } from "../../auth";

export function AllowRouteIf({ ...displayIfProps }: Omit<DisplayIfProps, "children">) {
	return (
		<DisplayIf {...displayIfProps}>
			{(can) => (can ? <Outlet /> : <Navigate replace to={`/error/${ERRORS.ACCESS_DENIED.code.toLowerCase()}`} />)}
		</DisplayIf>
	);
}
