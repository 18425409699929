import { memo } from "react";

import { Field, getSDObjectNameFieldNames, isAssetObject, SDObject, SDRecord } from "@salesdesk/salesdesk-schemas";
import { mAssetDef } from "@salesdesk/salesdesk-model";

import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";
import { DisplayFieldFactory, DisplayFieldVariant } from "../../../../../fields";
import { RECORD_COMBINED_NAME_ID } from "../../../../types";
import { RecordTableRowData } from "../types";
import { TableRowNameCell } from "./TableRowNameCell";

interface TableRowCellProps {
	sdObject: SDObject;
	field: Field;
	value: RecordTableRowData;
	sdRecord: SDRecord;
	isLoading?: boolean;
}

function TableRowCellComponent({ sdObject, field, value, sdRecord, isLoading }: TableRowCellProps) {
	if (isLoading || typeof value === "boolean") {
		return <Skeleton className="my-2 h-6 w-full" />;
	}

	if (
		field._name === RECORD_COMBINED_NAME_ID ||
		(isAssetObject(sdObject) && field._name === mAssetDef.FILE_FIELD_NAME) ||
		getSDObjectNameFieldNames(sdObject).includes(field._name)
	) {
		return <TableRowNameCell sdObject={sdObject} sdRecord={sdRecord} field={field} value={value} />;
	}

	if (!value || !("_value" in value)) {
		return "-";
	}

	return <DisplayFieldFactory field={field} value={value._value} variant={DisplayFieldVariant.table} />;
}

export const TableRowCell = memo(TableRowCellComponent);
