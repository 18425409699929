import { FormEventHandler, PropsWithChildren, ReactNode, useCallback, useId, useState } from "react";
import { FormDialog } from "../../../../Dialog";
import { Icon } from "@salesdesk/daisy-ui";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useNotificationRuleDialogContext } from "../../hooks/useNotificationRuleDialogContext";
import { ANY } from "../../types/Any";
import { NotificationRuleType } from "@salesdesk/salesdesk-schemas";
import { toRecordId } from "../../utils";
import { useGetRecordQuery } from "../../../../records";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNotificationRuleOnFormSubmit } from "../../hooks/useNotificationRuleOnFormSubmit";

const DIALOG_WIDTH = 744;
const TYPE_SELECT_MIN_WIDTH = 210;
const TYPE_SELECT_MAX_WIDTH = 280;
const CHILDREN_HEIGHT = 450;

interface CreateNotificationRuleFormDialogProps {
	ruleType: NotificationRuleType;
	getError?: () => string | undefined;
	badge: ReactNode;
	headerSelect?: ReactNode;
}

export const CreateNotificationRuleFormDialog = ({
	ruleType,
	getError = () => undefined,
	badge,
	headerSelect,
	children,
}: PropsWithChildren<CreateNotificationRuleFormDialogProps>) => {
	const formId = useId();
	const {
		rule,
		onOpenChange,
		selectedObjectId,
		selectedWorkspaceId,
		selectedRuleConfig,
		selectedAction,
		selectedRecord,
		selectedUsers,
		selectedField,
		actualSelectedChannels,
	} = useNotificationRuleDialogContext();

	const { onFormSubmit, isCreating, isUpdating } = useNotificationRuleOnFormSubmit(onOpenChange);
	const [formError, setFormError] = useState<string | undefined>();

	const selectedRecordId = selectedRecord ? toRecordId(selectedRecord) : undefined;
	const { data: record, isLoading: isRecordLoading } = useGetRecordQuery(selectedRecordId ?? skipToken);
	const { data: workspace, isLoading: isWorkspaceLoading } = useGetRecordQuery(selectedWorkspaceId ?? skipToken);

	const onRecordNotificationRuleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(e) => {
			e.preventDefault();
			const customError = getError();
			if (customError) {
				setFormError(customError);
				return;
			}
			if (!selectedAction || (!selectedRuleConfig && selectedAction.id !== ANY)) {
				setFormError("Please select an action");
				return;
			}
			// TODO: To be reviewed after UX have considered error handling
			// if (selectedAction && selectedAction.id === ERROR) {
			// 	setFormError("Please select a valid action");
			// 	return;
			// }
			// if (selectedUsers.length > 0 && selectedUsers.some((selectedUser) => selectedUser.id === ERROR)) {
			// 	setFormError("Please select a valid user");
			// 	return;
			// }
			// if (selectedWorkspace && selectedWorkspace.id === ERROR) {
			// 	setFormError("Please select a valid workspace");
			// 	return;
			// }
			// if (selectedField && selectedField.id === ERROR) {
			// 	setFormError("Please select a valid field");
			// 	return;
			// }
			// if (selectedRecord && selectedRecord.id === ERROR) {
			// 	setFormError("Please select a valid record");
			// 	return;
			// }
			if (!selectedRecord && !selectedRuleConfig?.recordIdNotRequired) {
				setFormError("Please select a record");
				return;
			}
			if (
				selectedRecordId &&
				selectedWorkspaceId &&
				(record == null ||
					workspace == null ||
					!(record.sharedWorkspaceIds.includes(workspace._id) || record._id === workspace._id))
			) {
				setFormError("Please select a valid record for the workspace");
				return;
			}
			setFormError(undefined);
			onFormSubmit(
				ruleType,
				rule,
				selectedObjectId,
				selectedWorkspaceId,
				selectedRuleConfig,
				selectedAction,
				selectedUsers,
				selectedRecord,
				selectedField,
				actualSelectedChannels
			);
		},
		[
			getError,
			selectedAction,
			selectedRuleConfig,
			selectedRecord,
			selectedRecordId,
			selectedWorkspaceId,
			record,
			workspace,
			onFormSubmit,
			ruleType,
			rule,
			selectedObjectId,
			selectedUsers,
			selectedField,
			actualSelectedChannels,
		]
	);

	return (
		<FormDialog
			title={`${rule ? "Edit" : "New"} notification rule`}
			open
			onOpenChange={onOpenChange}
			isPending={isCreating || isUpdating}
			disableSubmitButton={isRecordLoading || isWorkspaceLoading}
			width={DIALOG_WIDTH}
			variant="xs"
			formId={formId}
			footerContent={
				formError && (
					<div className="text-c_danger_01 text-label flex items-center gap-4">
						<Icon icon={ICONS.warningCircle} className="flex items-center" />
						<div>{formError}</div>
					</div>
				)
			}
			footerContentAlignment="right"
		>
			<form id={formId} onSubmit={onRecordNotificationRuleFormSubmit}>
				<div className="flex flex-col gap-6">
					<div className="flex justify-between">
						<div className="flex items-center gap-2">Set up a custom notification rule on {badge}</div>
						{headerSelect ? (
							<div className="flex items-center gap-2">
								<div>Type:</div>
								<div style={{ minWidth: TYPE_SELECT_MIN_WIDTH, maxWidth: TYPE_SELECT_MAX_WIDTH }}>{headerSelect}</div>
							</div>
						) : null}
					</div>
					<div className="gap mb-8 flex w-full flex-col gap-6" style={{ height: CHILDREN_HEIGHT }}>
						{children}
					</div>
				</div>
			</form>
		</FormDialog>
	);
};
