import { ComponentProps } from "react";
import { Mention } from "@tiptap/extension-mention";
import { SuggestionProps } from "@tiptap/suggestion/src/suggestion";
import { ReactNodeViewRenderer, ReactRenderer } from "@tiptap/react";

import { MentionOptions } from "../types";
import { MentionNode } from "../components/MentionNode";
import { MentionPopover } from "../components/MentionPopover";

export function getMentionExtensionForReact(mentionOptions?: MentionOptions) {
	return Mention.extend({
		addNodeView() {
			return ReactNodeViewRenderer(MentionNode);
		},
		addOptions() {
			return {
				...this.parent?.(),
				...mentionOptions,
			};
		},
	}).configure({
		renderText({ node }) {
			return `@${node.attrs.label ?? node.attrs.id}`;
		},
		suggestion: {
			render: () => {
				let component: ReactRenderer<typeof MentionPopover> | undefined;

				let onArrowDown = () => undefined;
				let onArrowUp = () => undefined;
				let onEnter = () => undefined;

				const convertSuggestionProps = (props: SuggestionProps, isInitial = false) => {
					const { clientRect, query, command } = props;

					if (!clientRect) return null;

					const rect = clientRect();
					if (!rect) return null;

					const baseProps: Partial<ComponentProps<typeof MentionPopover>> = {
						query,
						workspaceId: mentionOptions?.workspaceId,
						chatChannelDetails: mentionOptions?.chatChannelDetails,
						clientPointDetails: { x: rect.x, y: rect.y },
						onSelect: (option) => {
							command({
								id: Number(option.id),
								label: option.name,
							});
						},
					};

					if (isInitial) {
						return {
							...baseProps,
							setOnArrowDown: (func) => {
								onArrowDown = func;
							},
							setOnArrowUp: (func) => {
								onArrowUp = func;
							},
							setOnEnter: (func) => {
								onEnter = func;
							},
						} satisfies Partial<ComponentProps<typeof MentionPopover>>;
					}

					return baseProps;
				};

				return {
					onStart: (props) => {
						const componentProps = convertSuggestionProps(props, true);

						if (!componentProps) return;

						component = new ReactRenderer(MentionPopover, {
							props: componentProps,
							editor: props.editor,
						});
					},
					onUpdate: (props) => {
						const componentProps = convertSuggestionProps(props);

						if (!componentProps) return;

						component?.updateProps(componentProps);
					},
					onExit: () => {
						component?.destroy();
						component = undefined;
					},
					onKeyDown: (props) => {
						const { event } = props;

						const handlers: { [key: string]: () => void } = {
							ArrowDown: onArrowDown,
							ArrowUp: onArrowUp,
							Enter: onEnter,
						};

						if (handlers[event.key]) {
							handlers[event.key]();

							event.preventDefault();
							event.stopPropagation();
							return true;
						}

						return false;
					},
				};
			},
			allowSpaces: true,
			allow: ({ state, range }) => {
				const { from, to } = range;
				const query = state.doc.textBetween(from, to, " ");
				const spaceCount = query.match(/ /g)?.length ?? 0;

				// If more than 2 spaces we close the suggestion
				return spaceCount < 2;
			},
		},
	});
}
