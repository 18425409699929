import { asConst } from "json-schema-to-ts";
import { queryClausePropertyValueSchema } from "./queryClausePropertyValueSchema";

export const includeClauseSchema = asConst({
	title: "Include",
	description: "Will match any record where the given field value is an array that includes the provided value",
	type: "object",
	additionalProperties: false,
	required: ["include"],
	properties: {
		include: queryClausePropertyValueSchema,
	},
	nullable: false,
});
