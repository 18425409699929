import { useRef, KeyboardEvent, forwardRef } from "react";
import clsx from "clsx";

import { ICONS } from "@salesdesk/salesdesk-ui";
import { TextInputVariant } from "../../types";

import { Icon } from "../../../Icon/Icon";
import { InputValidationState, TextInputComponentProps } from "../../types";
import { combineRefs, applyAndCancelKeyCapturing } from "../../../../utils";
import { InputContainer } from "../InputContainer";
import { Button } from "../../../Button/Button";

export const TextInput = forwardRef<HTMLInputElement, TextInputComponentProps>(
	(
		{
			id,
			value,
			onChange,
			onBlur,
			ariaAttributes,
			placeholder,
			disabled,
			variant,
			hasError,
			validationState = InputValidationState.initial,
			icon,
			onIconClick,
			type,
			textCenter,
			size = "lg",
			onCancel,
			onApply,
			autoComplete,
			suffixButton,
			...InputProps
		},
		ref
	) => {
		const innerRef = useRef<HTMLInputElement>(null);
		const inputType = type ?? (variant === TextInputVariant.PASSWORD ? "password" : "text");
		const currentValidationState = hasError ? InputValidationState.error : validationState;
		const isError = currentValidationState === InputValidationState.error;
		const isSuccess = currentValidationState === InputValidationState.success;
		const inputIcon = isError ? ICONS.warningCircle : isSuccess ? ICONS.check : icon;
		const sizeClasses = size === "lg" ? "py-2 pl-4 pr-2" : "py-1 px-2 text-body-sm";

		const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
			applyAndCancelKeyCapturing<HTMLInputElement>(event, onApply, onCancel);
		};

		return (
			<InputContainer
				disabled={disabled}
				validationState={currentValidationState}
				className="relative flex items-center"
			>
				<input
					id={id}
					type={inputType}
					className={clsx(
						disabled && "cursor-not-allowed",
						sizeClasses,
						"w-full rounded-sm bg-transparent",
						textCenter && "text-center"
					)}
					ref={combineRefs([ref, innerRef])}
					value={value}
					placeholder={placeholder}
					onChange={(e) => {
						const value = e.target.value;
						if (type === "number") {
							onChange(value !== "" ? Number(value) : undefined);
						} else {
							onChange(value);
						}
					}}
					onBlur={onBlur}
					disabled={disabled}
					autoComplete={autoComplete ?? "off"}
					aria-invalid={isError}
					onKeyDown={handleKeyDown}
					{...ariaAttributes}
					{...InputProps}
				/>
				{suffixButton}
				{!onIconClick && inputIcon ? (
					<Icon
						className={clsx(
							disabled
								? "text-c_bg_disabled_01"
								: isError
									? "text-c_danger_01"
									: isSuccess
										? "text-c_success_01"
										: "text-c_icon_regular",
							"mr-2 flex items-center"
						)}
						icon={inputIcon}
					/>
				) : null}
				{onIconClick && inputIcon ? (
					<div className="mr-1">
						<Button
							variant="text"
							size="sm"
							disabled={disabled}
							onClick={(e) => {
								e.stopPropagation();
								onIconClick();
							}}
							startIcon={inputIcon}
						/>
					</div>
				) : null}
			</InputContainer>
		);
	}
);
