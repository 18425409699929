import { ReactNode } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { ROW_LINK_COLUMN_ID, ROW_SELECTION_COLUMN_ID, TableColumn, TableRow } from "../types";
import { SelectAllHeaderCell } from "../components/ColumnHeader/SelectAllHeaderCell";
import { SelectCell } from "../components/TableRow/components";

export const DEFAULT_COLUMN_WIDTH = 180;
export const MIN_COLUMN_WIDTH = 80;

const DEFAULT_SELECTION_WIDTH = 44;

interface GenerateColumnDefsArgs<T> {
	columns: TableColumn<T>[];
	hasRowSelection?: boolean;
	isSingleSelect?: boolean;
	defaultColumnWidthByColumnId?: Record<string, number>;
	isPrimaryLinkedTable?: boolean;
}

export function generateColumnDefs<T>({
	columns,
	hasRowSelection,
	isSingleSelect,
	defaultColumnWidthByColumnId,
	isPrimaryLinkedTable,
}: GenerateColumnDefsArgs<T>) {
	const columnHelper = createColumnHelper<TableRow<T>>();

	const columnDefs: ColumnDef<TableRow<T>, T>[] = [
		columnHelper.display({
			id: ROW_LINK_COLUMN_ID,
			cell: () => null,
			size: 0,
			minSize: 0,
		}),
	];

	if (hasRowSelection) {
		columnDefs.push(
			columnHelper.display({
				id: ROW_SELECTION_COLUMN_ID,
				header: ({ table, column }) => {
					return (
						<SelectAllHeaderCell
							isPrimaryLinkedTable={isPrimaryLinkedTable}
							isSingleSelect={Boolean(isSingleSelect)}
							table={table}
							column={column}
						/>
					);
				},
				cell: ({ row }) => {
					return <SelectCell isSingleSelect={Boolean(isSingleSelect)} row={row} />;
				},
				size: DEFAULT_SELECTION_WIDTH,
				minSize: DEFAULT_SELECTION_WIDTH,
			})
		);
	}

	columns.forEach((column) => {
		const { id, displayName, renderRowCell } = column;

		columnDefs.push(
			columnHelper.accessor((row) => row[id], {
				id: String(id),
				header: displayName,
				cell: (info) => {
					return (
						<div className="flex max-w-full items-center truncate">
							{renderRowCell ? (
								renderRowCell(info.cell.getValue(), info.row.original)
							) : (
								<div className="text-body text-c_text_primary truncate">{info.cell.getValue() as ReactNode}</div>
							)}
						</div>
					);
				},
				size: defaultColumnWidthByColumnId?.[String(id)] ?? DEFAULT_COLUMN_WIDTH,
				minSize: MIN_COLUMN_WIDTH,
			})
		);
	});

	return columnDefs;
}
