import { LoginChoice, TenantSettings, TenantSettingsPatchRequest } from "@salesdesk/salesdesk-schemas";

import { SDApi } from "../../api";

export const tenantApi = SDApi.injectEndpoints({
	endpoints: (builder) => {
		return {
			getTenantSettings: builder.query<TenantSettings, void>({
				query: () => ({
					url: "/settings",
					method: "GET",
				}),
				providesTags: ["Tenant"],
			}),
			patchTenantSettings: builder.mutation<void, Partial<TenantSettingsPatchRequest>>({
				query: (tenantSettings) => ({
					url: "/settings",
					method: "PATCH",
					body: tenantSettings,
				}),
				invalidatesTags: ["Tenant"],
			}),
			getLoginChoices: builder.query<LoginChoice[], void>({
				query: () => ({
					url: "/me/login-choice",
					method: "GET",
				}),
				providesTags: ["Tenant"],
			}),
		};
	},
});

export const { useGetTenantSettingsQuery, usePatchTenantSettingsMutation, useGetLoginChoicesQuery } = tenantApi;
