import { Spinner } from "@salesdesk/daisy-ui";

export function VideoCallLoading() {
	return (
		<div className="bg-c_brand_dark absolute inset-0 z-50 flex items-center justify-center">
			<div className="text-body-lg text-c_text_inverted flex flex-col items-center gap-3">
				<Spinner darkMode size="lg" />
				Joining meeting...
			</div>
		</div>
	);
}
