import { useCallback, useEffect, useMemo } from "react";
import { JSONContent } from "@tiptap/core";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetChatMessagesQuery, usePostChatMessageMutation } from "../../meetings/api/chatApi";
import { CONTROL_TRACK_MAX_PACKET_LIFETIME } from "../../meetings/components/VideoCallProvider/components/VideoCallLogicProvider/hooks/useControlTrack";
import {
	ChatRefreshRequest,
	SubscribeToChatRefreshRequests,
} from "../../meetings/components/VideoCallProvider/components/VideoCallLogicProvider/hooks/useChatControlTrack";
import { useToast } from "../../Toasts";
import { useWebPrincipal } from "../../../auth";

// MaxPacketLifetime is use for retries for both the inbound and outbound connection to server
const POLLING_INTERVAL = CONTROL_TRACK_MAX_PACKET_LIFETIME * 2;

export function useChatMessages(
	meetingId: number | undefined,
	sendChatRefreshRequest: (() => void) | null,
	subscribeToChatRefreshRequests: SubscribeToChatRefreshRequests | null,
	pollingInterval = POLLING_INTERVAL
) {
	const principal = useWebPrincipal();
	const [postChatMessage] = usePostChatMessageMutation();

	const toast = useToast();

	const {
		currentData: messages,
		refetch: refetchMessages,
		isLoading,
	} = useGetChatMessagesQuery(meetingId != null ? meetingId : skipToken, {
		pollingInterval,
	});

	const postMessage = useCallback(
		(message: JSONContent) => {
			if (principal.UserRecordId == null || meetingId == null) return;
			postChatMessage({
				meetingRecordId: meetingId,
				chatMessageCreateRequest: { message: JSON.stringify(message) },
			})
				.unwrap()
				.then(() => {
					if (sendChatRefreshRequest != null) sendChatRefreshRequest();
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "chat_message_send" });
				});
		},
		[meetingId, postChatMessage, principal.UserRecordId, sendChatRefreshRequest, toast]
	);
	const sortedMessages = useMemo(() => {
		if (messages == null) return [];
		return messages.slice().sort((m1, m2) => m1.createdAt - m2.createdAt);
	}, [messages]);

	useEffect(() => {
		if (!subscribeToChatRefreshRequests) {
			return;
		}

		// Not checking time stamp.  RTK will prevent multiple requests being made
		return subscribeToChatRefreshRequests((_: ChatRefreshRequest) => refetchMessages());
	}, [refetchMessages, subscribeToChatRefreshRequests]);

	return { sortedChatMessages: sortedMessages, postChatMessage: postMessage, isLoading };
}
