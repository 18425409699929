import { useCallback, useMemo } from "react";

import {
	getFormValuesByPrefix,
	getFormValuesFromRecord,
	RECORD_FIELDS_KEY,
	useRecordForm,
	useUpdateRecordMutation,
} from "../../../../../records";
import { useToast } from "../../../../../Toasts";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { mWorkspaceDef } from "@salesdesk/salesdesk-model";
import { Field } from "@salesdesk/salesdesk-schemas";
import { BASIC_INFO_FIELD_NAMES_TO_FILTER } from "../../../../utils";

export function useWorkspaceSettingsForm() {
	const { workspaceRecord, workspaceObject } = useWorkspaceContext();

	const [updateRecord, { isLoading }] = useUpdateRecordMutation();
	const toast = useToast();

	const { formId, fields, onFormStateChange, uploadProgressStatus, updateUploadProgressStatus } = useRecordForm({
		sdObject: workspaceObject,
	});

	const defaultValues = useMemo(
		() => (workspaceRecord ? getFormValuesFromRecord(fields, workspaceRecord) : {}),
		[fields, workspaceRecord]
	);

	const [basicInfoFields, themeField] = useMemo(() => {
		let themeField: Field | null = null;

		const basicInfoFields: Field[] = [];

		for (const field of fields) {
			if (field._name === mWorkspaceDef.THEME_FIELD_NAME) {
				themeField = field;
			} else if (!BASIC_INFO_FIELD_NAMES_TO_FILTER.includes(field._name)) {
				basicInfoFields.push(field);
			}
		}

		return [basicInfoFields, themeField];
	}, [fields]);

	const submitWorkspaceUpdate = useCallback(
		(workspaceData: Record<string, any>, resetForm: (formValues?: Record<string, any>) => void) => {
			if (!workspaceRecord) return;

			const recordData = getFormValuesByPrefix(workspaceData, RECORD_FIELDS_KEY);

			updateRecord({
				record: workspaceRecord,
				updatedFields: fields.map((field) => {
					const value = recordData[field._id];
					return { _fieldId: field._id, _value: value === undefined || value === "" ? null : (value as unknown) };
				}),
			})
				.unwrap()
				.then((updatedData) => {
					toast.triggerMessage({ type: "success", messageKey: "workspace_settings_updated" });
					resetForm(getFormValuesFromRecord(fields, updatedData));
				})
				.catch(() => {
					toast.triggerMessage({ type: "error", messageKey: "workspace_settings_updated" });
				});
		},
		[updateRecord, workspaceRecord, fields, toast]
	);

	return {
		formProps: {
			id: formId,
			fields: basicInfoFields,
			onSubmit: submitWorkspaceUpdate,
			onFormStateChange,
			updateUploadProgressStatus,
			defaultValues,
			sdRecord: workspaceRecord,
			sdObject: workspaceObject,
		},
		uploadProgressStatus,
		themeField,
		isLoading,
	};
}
