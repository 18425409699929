import { Skeleton } from "../../../../components/Skeleton/Skeleton";

export function AccountFormSkeleton() {
	return (
		<div className="flex flex-col gap-8">
			{Array.from({ length: 6 }).map((_, index) => (
				<Skeleton key={index} className="h-8 w-full" />
			))}
		</div>
	);
}
