import { useEffect, useMemo, useState } from "react";

import { SDRecord, getSDRecordFieldValueByFieldName, rsr } from "@salesdesk/salesdesk-schemas";
import { AssociationSide, WORKSPACE_INTRO_VIDEO_ID, mAssetDef, mMediaAssetDef } from "@salesdesk/salesdesk-model";

import { useGetObjectById } from "../../../hooks";
import { useSdFile } from "../../files/hooks/useSdFile";
import { useInfiniteRecordSearch } from "../../records";

export function useGetWorkspaceWelcomeVideo(workspaceRecord?: SDRecord) {
	const { sdObject: mediaAssetObject } = useGetObjectById(mMediaAssetDef.ID);

	const [sdRecords, setSDRecords] = useState<SDRecord[]>([]);
	const [hasWorkspaceRecord, setHasWorkspaceRecord] = useState(Boolean(workspaceRecord));

	const { updateSearchParams, isLoadingNewSearchParams } = useInfiniteRecordSearch({
		limit: 1,
		sdRecords: sdRecords,
		onSDRecordsChange: setSDRecords,
		startLoadingOnMount: true,
	});

	useEffect(() => {
		if (workspaceRecord?._id == null) {
			setSDRecords([]);
			setHasWorkspaceRecord(false);
			return;
		}

		setHasWorkspaceRecord(true);

		const searchParams = {
			query: rsr
				.query()
				.and(rsr.equals("_deleted", false))
				.not(rsr.equals("isTemplate", true))
				.and(rsr.isSharedWithWorkspace(workspaceRecord._id))
				.and(
					rsr.hasAssociationToRecordForObjectAssociation(
						WORKSPACE_INTRO_VIDEO_ID,
						workspaceRecord._id,
						AssociationSide.RECORD_2
					)
				)
				.and(rsr.equals("_objectDefId", mMediaAssetDef.ID))
				.buildQuery(),
		};

		updateSearchParams(searchParams);
	}, [workspaceRecord?._id, updateSearchParams]);

	const welcomeVideoRecord = sdRecords?.[0];

	const welcomeVideoFileId = useMemo(() => {
		if (!mediaAssetObject || !welcomeVideoRecord) {
			return undefined;
		}

		const fileId = getSDRecordFieldValueByFieldName(
			mediaAssetObject,
			welcomeVideoRecord,
			mAssetDef.FILE_FIELD_NAME
		)?._value;

		return fileId as number | undefined;
	}, [mediaAssetObject, welcomeVideoRecord]);

	const { sdFile: welcomeVideo, isLoading: isLoadingVideoFile } = useSdFile(welcomeVideoFileId);

	return {
		welcomeVideo,
		welcomeVideoRecord,
		isLoading: isLoadingNewSearchParams || isLoadingVideoFile || !hasWorkspaceRecord,
	};
}
