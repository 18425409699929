import React, { useState } from "react";

import { useFloating, useClick, useDismiss, useRole, useInteractions } from "@floating-ui/react";
import { VIDEO_CALL_CONTAINER_ID } from "../../meetings/utils";

export interface DialogOptions {
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function Dialog({ children, ...options }: React.PropsWithChildren<DialogOptions>) {
	const dialog = useDialog(options);
	return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
}

function useDialog({ open: controlledOpen, onOpenChange: setControlledOpen }: DialogOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		open,
		onOpenChange: setOpen,
	});

	const context = data.context;

	const click = useClick(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context, {
		outsidePressEvent: "mousedown",
		// Doesn't close the dialog if the user is interacting with the Minimised video call window
		outsidePress: (event) => {
			return !(event.target as Element)?.closest(`#${VIDEO_CALL_CONTAINER_ID}`);
		},
	});
	const role = useRole(context);

	const interactions = useInteractions([click, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
		}),
		[open, setOpen, interactions, data]
	);
}

type ContextType = ReturnType<typeof useDialog> | null;

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
	const context = React.useContext(DialogContext);

	if (context == null) {
		throw new Error("Dialog components must be wrapped in <Dialog />");
	}

	return context;
};
