import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { SDApi } from "../features/api";
import { appStateReducer } from "./appStateSlice";
import { toastsReducer } from "../features/Toasts/store/toastSlice";
import { connectWebSocket, webSocketMiddleware, webSocketReducer } from "./websocket";
import { browserNotificationsReducer } from "../features/BrowserNotifications/store/browserNotificationsSlice";
import { videoCallEventsReducer } from "../features/meetings";
import { channelsReducer, messagesReducer } from "../features/messaging";
import { boardStateReducer } from "../features/ObjectBoard/store/boardStateSlice";

export const store = configureStore({
	reducer: {
		[SDApi.reducerPath]: SDApi.reducer,
		appState: appStateReducer,
		toasts: toastsReducer,
		videoCallEvents: videoCallEventsReducer,
		webSocket: webSocketReducer,
		browserNotifications: browserNotificationsReducer,
		boardState: boardStateReducer,
		channels: channelsReducer,
		messages: messagesReducer,
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: {
				// Ignoring non-serializable error because the state (i.e. websocket) is used in middleware and not a reducer
				ignoredActions: [connectWebSocket.type],
			},
		})
			.concat([SDApi.middleware])
			.concat(webSocketMiddleware);
	},
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
