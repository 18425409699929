import { useCallback, useState } from "react";

import { Comment, SDRecord } from "@salesdesk/salesdesk-schemas";

import { useToast } from "../../../../../../Toasts";
import { useGetContextWorkspaceId } from "../../../../../../workspaces";
import { useResolveCommentMutation } from "../../../api/documentCommentsApi";

export function useResolveComment(documentRecordId?: SDRecord["_id"]) {
	const workspaceId = useGetContextWorkspaceId();
	const toast = useToast();

	const [resolveComment, { isLoading: isResolvingComment }] = useResolveCommentMutation();
	const [commentToResolve, setCommentToResolve] = useState<{ comment: Comment; documentText?: string }>();

	const handleResolveCommentConfirmation = useCallback(() => {
		if (!commentToResolve || !documentRecordId) return;

		resolveComment({ workspaceId, commentId: commentToResolve.comment.id, recordId: documentRecordId })
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "comment_resolved" });
				setCommentToResolve(undefined);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "comment_resolved" });
			});
	}, [commentToResolve, documentRecordId, resolveComment, toast, workspaceId]);

	return {
		commentToResolve,
		handleResolveCommentConfirmation,
		setCommentToResolve,
		isResolvingComment,
	};
}
