import { useMemo, useState, forwardRef } from "react";

import { ICONS } from "@salesdesk/salesdesk-ui";

import { IconPickerInputProps } from "../../../../types";
import { Searchbar } from "../../../Searchbar";
import { UnsetPickerButton } from "../../UnsetPickerButton";
import { getMatchedIconRows } from "../utils";
import { IconPickerVirtualList } from "./IconPickerVirtualList";
import { IconSwatch } from "./IconSwatch";
import { Popover, PopoverContainer, PopoverContent, PopoverTrigger } from "../../../../../../components/Popover";
import { Tooltip } from "@salesdesk/daisy-ui";
import { Button } from "@salesdesk/daisy-ui";

export const IconPicker = forwardRef<HTMLInputElement, IconPickerInputProps>(
	({ value = null, onChange, placeholder, disabled, hasError, ariaAttributes, ...inputProps }, ref) => {
		const [query, setQuery] = useState("");
		const [isPopoverOpen, setIsPopoverOpen] = useState(false);

		const matchedIcons = useMemo(() => getMatchedIconRows(query), [query]);
		const isNotFound = matchedIcons.length === 0;

		return (
			<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} placement="bottom-start">
				<PopoverTrigger>
					{value ? (
						<IconSwatch
							active={isPopoverOpen}
							icon={value}
							disabled={disabled}
							hasError={hasError}
							{...ariaAttributes}
						/>
					) : (
						<UnsetPickerButton
							placeholder={placeholder || "Add icon"}
							disabled={disabled}
							hasError={hasError}
							{...ariaAttributes}
						/>
					)}
				</PopoverTrigger>
				<PopoverContent>
					<PopoverContainer>
						<div className="flex max-h-[295px] max-w-[298px] flex-col items-center gap-4 overflow-hidden p-5">
							<div className="flex items-center gap-2 px-1 pt-1">
								<Searchbar ref={ref} isCollapsible={false} value={query} onChange={setQuery} {...inputProps} />
								<Tooltip placement="right" text="Unselect">
									<Button
										variant="text"
										size="sm"
										startIcon={ICONS.crossCircle}
										iconVariant="fill"
										onClick={() => onChange(null)}
									/>
								</Tooltip>
							</div>
							{isNotFound ? (
								<p className="text-c_text_placeholder pt-2">Icon '{query}' not found</p>
							) : (
								<IconPickerVirtualList value={value} iconRows={matchedIcons} onIconClick={onChange} />
							)}
						</div>
					</PopoverContainer>
				</PopoverContent>
			</Popover>
		);
	}
);
