import { DateTimePickerController } from "../../../../../../../inputs/components/DateTimeInput/DateTimePickerController";
import { useCallback, useRef, useState } from "react";
import { WorkspaceUpcomingMeeting } from "./WorkspaceUpcomingMeeting";
import { getIsoDay } from "@salesdesk/salesdesk-utils";
import {
	MEETING_DATE_ATTRIBUTE_NAME,
	MONTH_KEY_ATTRIBUTE_NAME,
	upcomingMeetingOptions,
	UpcomingMeetingFilter,
} from "../types";
import { useCalendarOffsetWhenScrolling } from "../hooks/useCalendarOffsetWhenScrolling";
import { UpcomingMeetingSkeleton } from "./UpcomingMeetingSkeleton";
import { EmptySection } from "../../EmptySection";
import { TimelineItem } from "./TimelineItem";
import { ButtonSelect } from "../../../../../../../inputs";
import { ICONS } from "@salesdesk/salesdesk-ui";
import { useUpcomingMeetingRecords } from "../hooks/useUpcomingMeetingRecords";
import { AddRecordButton } from "../../../../WorkspaceResources";
import { mInteractionMeetingDef } from "@salesdesk/salesdesk-model";

const CALENDAR_WIDTH = 344;
const CALENDAR_HEIGHT = 444;
const ROW_MAX_WIDTH = 784;

const MEETING_OBJECT_IDS = [mInteractionMeetingDef.ID];

export function WorkspaceUpcomingMeetings() {
	const upcomingMeetingsRef = useRef<HTMLDivElement>(null);
	const [filter, setFilter] = useState<UpcomingMeetingFilter>("ALL_MEETINGS");

	const {
		meetingObject,
		sortedMeetingRowsByMonth,
		monthKeys,
		meetingDates,
		meetingStartDateFieldId,
		meetingParticipantsFieldId,
		isLoadingRecords,
	} = useUpcomingMeetingRecords(filter);

	const onDateSelected = useCallback((dateTs: number | null) => {
		if (dateTs === null || !upcomingMeetingsRef.current) return;
		const selector = `[${MEETING_DATE_ATTRIBUTE_NAME}='${getIsoDay(new Date(dateTs))}']`;
		const upcomingMeetingRowElement = upcomingMeetingsRef.current.querySelector(selector);
		if (upcomingMeetingRowElement) {
			upcomingMeetingRowElement.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}, []);

	const { calendarOffset, setCalendarOffset } = useCalendarOffsetWhenScrolling(
		upcomingMeetingsRef,
		MONTH_KEY_ATTRIBUTE_NAME,
		monthKeys
	);

	const isLoading = isLoadingRecords || !meetingObject;
	const showFilterButton = isLoading || monthKeys.length !== 0 || filter !== "ALL_MEETINGS";

	const meetingObjectPluralName = meetingObject?._pluralName.toLowerCase() ?? "meetings";

	return (
		<div className="flex flex-col gap-4">
			<div className="text-c_text_secondary text-h4 flex h-[38px] items-center justify-between">
				<div>Upcoming {meetingObjectPluralName}</div>
				<div className="flex items-center gap-3">
					{showFilterButton ? (
						<ButtonSelect
							startIcon={ICONS.filter}
							options={upcomingMeetingOptions}
							value={filter}
							onChange={(value) => {
								if (value) {
									setFilter(value as UpcomingMeetingFilter);
								}
							}}
						/>
					) : null}
					<AddRecordButton sdObjectIds={MEETING_OBJECT_IDS} />
				</div>
			</div>
			<div className="flex items-start gap-4">
				<div
					className="shadow-popover bg-c_bg_01 border-c_border_regular flex rounded rounded-sm border px-8 py-8"
					style={{ width: CALENDAR_WIDTH }}
				>
					<DateTimePickerController
						markedDates={meetingDates}
						onChange={onDateSelected}
						offsetDate={calendarOffset}
						setOffsetDate={setCalendarOffset}
					/>
				</div>
				<div
					ref={upcomingMeetingsRef}
					id="upcomingMeetings"
					className="flex h-full w-full snap-y snap-mandatory flex-col overflow-auto pr-1"
					style={{ height: CALENDAR_HEIGHT }}
				>
					<div style={{ maxWidth: ROW_MAX_WIDTH }}>
						{isLoading ? (
							<>
								<UpcomingMeetingSkeleton />
								<UpcomingMeetingSkeleton isLast />
							</>
						) : !monthKeys.length ? (
							<div className="flex w-full">
								<TimelineItem dateLabel="" />
								<EmptySection
									sdObjectIds={MEETING_OBJECT_IDS}
									messageText={
										filter !== "ALL_MEETINGS"
											? `No upcoming ${meetingObjectPluralName} matching the selected filter, add one using the button below`
											: `No upcoming ${meetingObjectPluralName} yet, add one using the button below`
									}
									buttonText="Add meetings"
								/>
							</div>
						) : (
							monthKeys.map((monthKey, monthIndex) => (
								<div key={monthKey} {...{ [MONTH_KEY_ATTRIBUTE_NAME]: monthKey }}>
									{sortedMeetingRowsByMonth[monthKey].map(
										({ meetingRecord, meetingStartDate, dateLabel }, recordIndex) => (
											<WorkspaceUpcomingMeeting
												key={meetingRecord._id}
												meetingRecord={meetingRecord}
												meetingStartDateFieldId={meetingStartDateFieldId}
												meetingParticipantsFieldId={meetingParticipantsFieldId}
												meetingStartDate={meetingStartDate}
												dateLabel={dateLabel}
												isLast={
													monthIndex === monthKeys.length - 1 &&
													recordIndex === sortedMeetingRowsByMonth[monthKey].length - 1
												}
											/>
										)
									)}
								</div>
							))
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
