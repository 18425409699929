import { useRef } from "react";

import { mInteractionMeetingDef, mTaskDef, mIssueDef } from "@salesdesk/salesdesk-model";

import { useWebPrincipal } from "../../../../../../auth";
import { PATHS } from "../../../../../../routes";
import { useGetObjectMap } from "../../../../../objects/hooks";
import { CollapsibleGlobalSearchbar } from "../../../../../records";
import { useWorkspaceContext } from "../../../../hooks/useWorkspaceContext";
import { WorkspaceNavContainer } from "./WorkspaceNavContainer";
import { WorkspaceNavLoading } from "./WorkspaceNavLoading";
import { WorkspaceNavLink } from "./WorkspaceNavLink";
import { WorkspaceActivityButton } from "./WorkspaceActivityButton";
import { WorkspaceLogoSection } from "./WorkspaceLogoSection";
import { WorkspacePreviewToggle } from "./WorkspacePreviewToggle";

export function WorkspaceNav() {
	const collapsibleSectionRef = useRef<HTMLDivElement>(null);

	const principal = useWebPrincipal();

	const objectMap = useGetObjectMap();
	const { workspaceRecord, workspaceObject, isLoading, isPreviewMode, navCollapsed } = useWorkspaceContext();

	const isLoadingWorkspace = !workspaceRecord || isLoading;

	const taskObject = objectMap.get(mTaskDef.ID);
	const meetingObject = objectMap.get(mInteractionMeetingDef.ID);
	const issueObject = objectMap.get(mIssueDef.ID);

	return (
		<WorkspaceNavContainer collapsibleSectionRef={collapsibleSectionRef}>
			{isLoadingWorkspace ? (
				<WorkspaceNavLoading />
			) : (
				<>
					<div className="mb-2 flex items-center">
						<WorkspacePreviewToggle />
						<div className="ml-auto flex items-center gap-3">
							{principal.IsSalesDeskUser && !isPreviewMode ? (
								<div className="flex items-center gap-2">
									<WorkspaceNavLink link={PATHS.WORKSPACE_SETTINGS(workspaceRecord._id)} variant="secondary">
										Settings
									</WorkspaceNavLink>
									<WorkspaceNavLink
										id="userflow-workspace-resources"
										link={PATHS.WORKSPACE_RESOURCES(workspaceRecord._id)}
										variant="secondary"
									>
										Resources
									</WorkspaceNavLink>
								</div>
							) : null}
							<div className="flex items-center gap-2">
								<CollapsibleGlobalSearchbar placeholder={`Search ${workspaceObject?._displayName || "Workspace"}...`} />
								<WorkspaceActivityButton />
							</div>
						</div>
					</div>
					<div className="mt-auto flex flex-col gap-3">
						<WorkspaceLogoSection ref={collapsibleSectionRef} isCollapsed={navCollapsed} />
						<div className="flex gap-4">
							<WorkspaceNavLink link={PATHS.WORKSPACE_OVERVIEW(workspaceRecord._id)} end>
								Overview
							</WorkspaceNavLink>
							<div id="userflow-workspace-others" className="flex gap-4 rounded-full">
								<WorkspaceNavLink link={PATHS.WORKSPACE_LIBRARY(workspaceRecord._id)}>Library</WorkspaceNavLink>
								{taskObject ? (
									<WorkspaceNavLink link={PATHS.WORKSPACE_RECORD_BOARD(workspaceRecord._id, taskObject)}>
										{taskObject._pluralName}
									</WorkspaceNavLink>
								) : null}
								{issueObject ? (
									<WorkspaceNavLink link={PATHS.WORKSPACE_RECORD_BOARD(workspaceRecord._id, issueObject)}>
										{issueObject._pluralName}
									</WorkspaceNavLink>
								) : null}
								{meetingObject ? (
									<WorkspaceNavLink link={PATHS.WORKSPACE_RECORD_BOARD(workspaceRecord._id, meetingObject)}>
										{meetingObject._pluralName}
									</WorkspaceNavLink>
								) : null}
							</div>
						</div>
					</div>
				</>
			)}
		</WorkspaceNavContainer>
	);
}
