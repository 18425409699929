import { Divider } from "../../../../../../../components/Divider/Divider";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { SkeletonTransition } from "../../../../../../../components/Skeleton/SkeletonTransition";

export function RecordSidePanelLoading() {
	return (
		<div className="w-full p-6 pr-5">
			<SkeletonTransition>
				<div className="mt-2 flex items-center justify-between">
					<Skeleton className="w-22 h-6" />
					<Skeleton className="h-6 w-40" />
				</div>
				<Skeleton className="mt-7 h-6 w-full" />
				{[...Array(5)].map((_, index) => (
					<div key={index} className="-mb-0.5 mt-6 flex w-5/6 gap-6">
						<Skeleton className="h-6 basis-1/4" />
						<Skeleton className="h-6 basis-3/4" />
					</div>
				))}
				<Skeleton className="mt-6 h-6 w-32" />
				<Skeleton className="-mb-0.5 mt-10 h-6 w-5/6" />
				<Divider className="mx-0 mt-6" />
				<Skeleton className="mt-6 h-6 w-32" />
				<Skeleton className="mt-6 h-6 w-5/6" />
			</SkeletonTransition>
		</div>
	);
}
