import { createContext, useContext } from "react";
import { JSONContent } from "@tiptap/core";
import { ChannelMessageMetadata, ChimeApi } from "@salesdesk/salesdesk-schemas";
import { ChannelMessage } from "@aws-sdk/client-chime-sdk-messaging";

type IncomingMessageCallback = (message: ChannelMessage) => void;

export interface IChimeMessagingContext {
	appInstanceArn?: string;
	chime?: ChimeApi;
	sendMessage?: (channelArn: string, message?: JSONContent, metadata?: ChannelMessageMetadata) => void;
	subscribeToIncomingMessages?: (onMessage: IncomingMessageCallback) => void;
	unsubscribeFromIncomingMessages?: (onMessage: IncomingMessageCallback) => void;
	initialisationFailed?: boolean;
}

export const ChimeMessagingContext = createContext<IChimeMessagingContext | null>(null);

export function useChimeMessagingContext(): IChimeMessagingContext {
	const context = useContext(ChimeMessagingContext);

	if (context == null) {
		throw new Error("useChimeMessagingContext must be wrapped in a <ChimeMessagingProvider />");
	}

	return context;
}
