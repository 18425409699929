import { CommentMentionParams } from "@salesdesk/salesdesk-schemas";

import { useEventContext } from "../../../../hooks/useEventContext";
import { LazyCommentPayload } from "./LazyCommentPayload";

export function CommentMentionPayload() {
	const { event } = useEventContext();
	const { commentId, commentCreatedBy } = event.params as CommentMentionParams;

	return <LazyCommentPayload recordId={event.record_id} commentId={commentId} createdById={commentCreatedBy} />;
}
