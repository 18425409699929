import { NotificationRule } from "@salesdesk/salesdesk-schemas";
import { useToast } from "../../../../Toasts";
import { DeleteConfirmationDialog } from "../../../../Dialog/AlertDialog/DeleteConfirmationDialog";
import { useDeleteNotificationRuleMutation } from "../../api/notificationRuleApi";
import { NotificationRuleDescription } from "./descriptions/NotificationRuleDescription";

interface DeleteNotificationRuleDialogProps {
	rule: NotificationRule;
	onOpenChange: (open: boolean) => void;
}

export function DeleteNotificationRuleDialog({ rule, onOpenChange }: DeleteNotificationRuleDialogProps) {
	const [deleteNotificationRule, { isLoading: isDeleting }] = useDeleteNotificationRuleMutation();
	const toast = useToast();

	const onDelete = () => {
		deleteNotificationRule(rule.id)
			.unwrap()
			.then(() => {
				toast.triggerMessage({ type: "success", messageKey: "notification_rule_deleted" });
				onOpenChange(false);
			})
			.catch(() => {
				toast.triggerMessage({ type: "error", messageKey: "notification_rule_deleted" });
			});
	};

	return (
		<DeleteConfirmationDialog
			title="Delete notification rule"
			open={true}
			onDismiss={() => onOpenChange(false)}
			onConfirm={onDelete}
			isDeleting={isDeleting}
		>
			<div className="flex flex-col gap-6">
				<div>Are you sure you want to delete this notification rule?</div>
				<div>
					"<NotificationRuleDescription rule={rule} />"
				</div>
				<div>Once deleted you will stop receiving this notification</div>
			</div>
		</DeleteConfirmationDialog>
	);
}
