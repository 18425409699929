import { asConst } from "json-schema-to-ts";
import { querySchema, searchAfterSchema, searchFromSchema, searchSizeSchema, sortBySchema } from "./index";
import { aggregationsSchema } from "./aggregationsSchema";
import { nullableStringSchema } from "../components";

export const searchRequestSchema = asConst({
	title: "Search Request Body",
	type: "object",
	additionalProperties: false,
	nullable: false,
	required: ["query"],
	// Enforces that "from" and "searchAfter" can't both be present
	not: { required: ["from", "searchAfter"] },
	properties: {
		size: searchSizeSchema,
		from: searchFromSchema,
		searchAfter: searchAfterSchema,
		sort: {
			type: "array",
			items: sortBySchema,
			nullable: false,
		},
		aggregations: aggregationsSchema,
		query: querySchema,
		highlight: {
			...nullableStringSchema,
			title: "Highlight",
			description: "Prefix search for highlight application",
		},
	},
});
