import { useEventContext } from "../../../hooks/useEventContext";
import { LinkObject } from "./LinkObject";
import { useGetObjectById } from "../../../../../hooks";

export function RecordingObject() {
	const { event, target } = useEventContext();
	const { sdObject } = useGetObjectById(event.object_def_id);
	const objectName = sdObject?._displayName?.toLowerCase() ?? "meeting";
	if (target === "NOTIFICATION") {
		return (
			<>
				in <LinkObject />
			</>
		);
	}
	return <>this {objectName}</>;
}
