import { useEffect } from "react";
import clsx from "clsx";
import userflow from "userflow.js";

import { Button, tw } from "@salesdesk/daisy-ui";
import { getSDRecordName } from "@salesdesk/salesdesk-schemas";

import { useNavigateBack } from "../../../../../../../routes";
import { Skeleton } from "../../../../../../../components/Skeleton/Skeleton";
import { useWorkspaceContext, WorkspaceHeader } from "../../../../../../workspaces";
import { useInitialiseDevices, useVideoCallMediaContext } from "../../../../VideoCallProvider";
import { useGetMeetingInfoAssociations, useMeetingPageContext } from "../../../hooks";

import { HoldingPageProvider } from "./HoldingPageProvider";
import { HoldingPageDetails } from "./details/HoldingPageDetails";
import { HoldingPageDetailsSkeleton } from "./details/HoldingPageDetailsSkeleton";
import { TitleMembers } from "./title/TitleMembers";
import { Title } from "./title/Title";
import { VideoControls } from "./video/VideoControls";
import { HoldingPageLogo } from "./HoldingPageLogo";
import { PreviewLocalVideo } from "./video/PreviewLocalVideo";
import { WorkspaceMeetingLogos } from "../../../../common/WorkspaceMeetingLogos";
import { useIsGuest } from "../../../hooks/useIsGuest";

export function HoldingPage() {
	const { workspaceId, workspaceRecord, workspaceObject } = useWorkspaceContext();
	const inWorkspace = workspaceId != null;

	const navigateBack = useNavigateBack();

	const { meetingRecord, meetingObject, joinMeeting } = useMeetingPageContext();
	const { localTracks } = useVideoCallMediaContext();
	const { mediaError, isInitialisingDevices } = useInitialiseDevices();

	const meetingInfoAssociationDefs = useGetMeetingInfoAssociations();

	const isLoading = !(meetingRecord != null && meetingObject != null && localTracks.length > 0);

	const hasLocalAudioIn = localTracks.find((track) => track.kind === "audio") != null;

	const topBarClasses = tw`flex shrink-0 items-center h-[65px]`;

	const isGuest = useIsGuest();

	useEffect(() => {
		if (hasLocalAudioIn) {
			userflow.track("enter_meeting_holding_page");
		}
	}, [hasLocalAudioIn]);

	return (
		<HoldingPageProvider>
			<div className="flex w-full flex-col overflow-hidden">
				{inWorkspace && !isGuest ? <WorkspaceHeader isBackButtonExit={false} /> : null}
				<div className="flex h-full w-full overflow-hidden">
					<div className={clsx("bg-c_bg_01 flex basis-[55%] flex-col overflow-hidden", isGuest && "pt-6")}>
						<div className={clsx(topBarClasses, "justify-start")}>
							{inWorkspace ? (
								<div className="ml-[15%]">
									<WorkspaceMeetingLogos />
								</div>
							) : (
								<div className="ml-8">
									<HoldingPageLogo />
								</div>
							)}
						</div>
						<div className={clsx("ml-[15%] mr-[7.5%] mt-7 flex flex-col gap-6")}>
							<div className="mb-2 flex min-h-20 justify-between">
								<div className="flex flex-col gap-2">
									<div className="text-h4">
										{isLoading ? (
											"Getting Ready"
										) : (
											<Title meetingObject={meetingObject} meetingRecord={meetingRecord} />
										)}
									</div>
									<div className="text-body-sm text-c_text_secondary">
										{isLoading ? "You will be able to join in a moment" : <TitleMembers />}
									</div>
								</div>
								<div>
									<Button
										id="userflow-join-the-meeting"
										variant="primary"
										size="lg"
										disabled={!meetingRecord}
										onClick={joinMeeting}
										isLoading={isLoading || !hasLocalAudioIn}
									>
										Join Now
									</Button>
								</div>
							</div>
							<PreviewLocalVideo error={mediaError} initialising={isInitialisingDevices} />
							{localTracks.length ? <VideoControls /> : null}
						</div>
					</div>
					<div className={clsx("bg-c_bg_04 flex basis-[45%] flex-col overflow-hidden", isGuest && "pt-6")}>
						<div className={clsx(topBarClasses, "justify-end")}>
							{inWorkspace ? (
								<div className="text-label text-c_text_placeholder ml-[9.2%] mr-[18%] line-clamp-2 max-h-full text-right">
									{workspaceObject && workspaceRecord ? (
										getSDRecordName(workspaceObject, workspaceRecord)
									) : (
										<Skeleton className="ml-auto h-6 w-40" />
									)}
								</div>
							) : (
								<div className="mr-8">
									<Button variant="secondary" size="sm" onClick={navigateBack}>
										Back
									</Button>
								</div>
							)}
						</div>
						<div className="mt-7 overflow-y-auto pl-[9.2%] pr-[18%]">
							<div className="col-span-3">
								{isLoading ? (
									<HoldingPageDetailsSkeleton />
								) : (
									<HoldingPageDetails meetingInfoAssociationDefs={meetingInfoAssociationDefs} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</HoldingPageProvider>
	);
}
