import { HTMLAttributes } from "react";
import clsx from "clsx";

export function SkeletonCard({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) {
	return (
		<div {...props} className={clsx("bg-c_bg_disabled_03 rounded-card flex h-full flex-col gap-5 p-4", className)}>
			{children}
		</div>
	);
}
