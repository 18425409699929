import { useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { mAccountDef } from "@salesdesk/salesdesk-model";

import { UploadProgressStatus } from "../../../fields";
import { useGetObjectById } from "../../../../hooks/useGetObjectById";
import { useRecordForm, RecordFormField, getFullRecordFormFieldId, useUploadProgressPerField } from "../../../records";
import { ACCOUNT_FIELD_KEY } from "../../types";

interface CreateAccountFieldsProps {
	updateUploadProgressStatus?: (status: UploadProgressStatus) => void;
}

export function CreateAccountFields({ updateUploadProgressStatus }: CreateAccountFieldsProps) {
	const { control, unregister } = useFormContext();
	const { sdObject } = useGetObjectById(mAccountDef.ID);

	const { fields } = useRecordForm({ sdObject });

	const requiredFields = useMemo(() => fields.filter((field) => field._required), [fields]);

	const { setUploadProgressStatusPerField } = useUploadProgressPerField(updateUploadProgressStatus);

	useEffect(() => {
		return () => {
			requiredFields.forEach((field) => {
				unregister(getFullRecordFormFieldId(ACCOUNT_FIELD_KEY, String(field._id)));
			});
		};
	}, [requiredFields, unregister]);

	return (
		<>
			{requiredFields.map((field) => (
				<RecordFormField
					fieldName={getFullRecordFormFieldId(ACCOUNT_FIELD_KEY, String(field._id))}
					key={field._id}
					formControl={control}
					field={field}
					setUploadProgressStatusPerField={setUploadProgressStatusPerField}
					disabled={!field._editable}
				/>
			))}
		</>
	);
}
