import { DisplayRichTextField } from "../../../../../../../../../fields";
import { getSDObjectFieldByName, getSDRecordFieldValue, getSDRecordName, SDRecord } from "@salesdesk/salesdesk-schemas";
import { Skeleton } from "../../../../../../../../../../components/Skeleton/Skeleton";
import { ProductAssetsAndDocs } from "../../ProductAssetsAndDocs/ProductAssetsAndDocs";
import { ProductAssetsOrDocsSkeleton } from "../../ProductAssetsAndDocs/ProductAssetsOrDocsSkeleton";
import { useProductBackgroundPositioning } from "../../../hooks/useProductBackgroundPositioning";
import { RefObject, useMemo } from "react";
import { mProductDef } from "@salesdesk/salesdesk-model";
import { useGetObjectById } from "../../../../../../../../../../hooks";
import { JSONContent } from "@tiptap/core";
import { ProductMediaFields } from "./ProductMediaFields";
import { ProductImageSkeleton } from "./ProductImageSkeleton";

export interface CurrentProductProps {
	productRecord: SDRecord | undefined;
	sectionHolderRef: RefObject<HTMLDivElement>;
}

export function Product({ productRecord, sectionHolderRef }: CurrentProductProps) {
	const { sdObject: productObject } = useGetObjectById(mProductDef.ID);

	const { productDescriptionField, productDescriptionValue } = useMemo(() => {
		if (!productObject || !productRecord) return {};
		const productDescriptionField = getSDObjectFieldByName(productObject, mProductDef.DESCRIPTION_FIELD_NAME);
		if (!productDescriptionField) return {};
		return {
			productDescriptionField,
			productDescriptionValue: getSDRecordFieldValue(productRecord, productDescriptionField?._id),
		};
	}, [productObject, productRecord]);

	const { imageHolderRef, backgroundStyle } = useProductBackgroundPositioning(productRecord, sectionHolderRef);

	if (!productObject) {
		return null;
	}

	return (
		<div className="flex w-full flex-col gap-4 pb-8">
			<div className="bg-c_bg_03 rounded-r-panel absolute right-0 -z-10 w-[60vw]" style={backgroundStyle} />
			<div ref={imageHolderRef}>
				{productObject && productRecord ? (
					<ProductMediaFields key={productRecord._id} productObject={productObject} productRecord={productRecord} />
				) : (
					<ProductImageSkeleton />
				)}
			</div>
			<div className="text-h2">
				{productRecord ? getSDRecordName(productObject, productRecord) : <Skeleton className="h-5 w-48" />}
			</div>
			{productDescriptionField && productDescriptionValue ? (
				<div className="text-body-lg max-h-[500px] overflow-y-auto">
					<DisplayRichTextField value={productDescriptionValue._value as JSONContent} collapsible={false} />
				</div>
			) : (
				<div className="flex flex-col gap-2 py-3">
					<Skeleton className="h-5 w-48" />
					<Skeleton className="h-4 w-28" />
					<Skeleton className="h-4 w-32" />
					<Skeleton className="h-4 w-28" />
					<Skeleton className="h-4 w-32" />
				</div>
			)}
			{productRecord ? <ProductAssetsAndDocs productRecord={productRecord} /> : <ProductAssetsOrDocsSkeleton />}
		</div>
	);
}
