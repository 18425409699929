import { useNotificationRuleDialogContext } from "../../../hooks/useNotificationRuleDialogContext";
import { useRecordRuleValues } from "../../../hooks/useRecordRuleValues";
import { RuleElement } from "./RuleElement";
import { RuleElementType } from "../../../types";
import { DownArrowIcon } from "./DownArrowIcon";

export function RecordRuleElement({ requiresSelectedAction = false }: { requiresSelectedAction?: boolean }) {
	const { selectedObjectId, selectedAction, selectedRuleConfig } = useNotificationRuleDialogContext();
	const recordRuleValues = useRecordRuleValues();

	return selectedObjectId !== undefined &&
		(selectedAction || !requiresSelectedAction) &&
		(!selectedRuleConfig || !selectedRuleConfig.recordIdNotRequired) ? (
		<>
			<DownArrowIcon />
			<RuleElement type={RuleElementType.in} values={recordRuleValues} />
		</>
	) : null;
}
